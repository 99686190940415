import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { ia } from 'lib/helpers/utility';

import Avatar from '../Avatar/Avatar';

import AllAvatars from './components/AllAvatars';
import Hover from './components/Hover';
import { getClasses } from './lib/getClasses';
import { getFirstName, getLastName } from './lib/getName';

/**
 * @param {import('./lib/propTypes').AvatarGroupProps} AvatarGroupProps
 */

const AvatarGroup = ({
  items = [],
  itemsToShow = 4,
  size = 'medium',
  isCompact = true,
  isBorder = true,
  label = '',
  className,
  singleHoverPlacement = 'bottom',
  allHoverPlacement = 'bottom',
  itemsGroupBy = '',
  itemsGroupLabelClassName
}) => {
  const classes = getClasses(size, isBorder, isCompact);
  const displayedItems = items.slice(0, itemsToShow);

  if (!ia(items)) return null;

  return (
    <div
      className={cs(
        'relative flex items-center p-[2px] bg-white rounded-full select-none',
        classes.wrapper,
        className
      )}>
      {isCompact && label && (
        <label
          className={cs(
            'font-600 text-neutral-600 leading-none capitalize',
            size === 'small' || size === 'medium' ? 'mr-1' : 'mr-[6px]',
            classes.label,
            classes.labelMargin
          )}>
          {label}
        </label>
      )}
      <div className="flex">
        {displayedItems.map((item, index) => (
          <div
            key={item.id}
            className="relative"
            style={{
              marginLeft: index > 0 && -classes.size / 4,
              zIndex: index + 1
            }}>
            <Tippy
              className="custom-tooltip py-2 w-max max-w-[300px]"
              animation="fade"
              placement={singleHoverPlacement}
              arrow={false}
              content={
                <Hover
                  {...item}
                  itemsGroupBy={itemsGroupBy}
                  fontSize={classes.itemFontSize}
                  firstName={getFirstName(item)}
                  lastName={getLastName(item)}
                />
              }>
              <div>
                <Avatar
                  size={size}
                  fontSize={classes.itemFontSize}
                  firstName={getFirstName(item)}
                  lastName={getLastName(item)}
                  image={item.image}
                  isSquare={false}
                  id={item.id}
                  className={cs(
                    'outline-white outline',
                    (size === 'large' || size === 'xl') && 'outline-2'
                  )}
                />
              </div>
            </Tippy>
          </div>
        ))}
      </div>
      <AllAvatars
        items={items}
        size={size}
        itemSize={classes.size}
        itemsToShow={itemsToShow}
        fontSize={classes.label}
        itemFontSize={classes.itemFontSize}
        isCompact={isCompact}
        placement={allHoverPlacement}
        itemsGroupBy={itemsGroupBy}
        itemsGroupLabelClassName={itemsGroupLabelClassName}
      />
    </div>
  );
};

export default AvatarGroup;
