const { getOrdinalSuffix, ia, inchToCm } = require('../../../../../lib/helpers/utility');
const moment = require('moment-timezone');

exports.chartOptions = ({
  measurementType = null,
  chartType = null,
  patient = null,
  currentUnits = null,
  minMaxValues = [],
  vitals = {}
}) => {
  const min = ia(minMaxValues) ? Math.min(...minMaxValues) : null;
  const max = ia(minMaxValues) ? Math.max(...minMaxValues) : null;

  return {
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: `${measurementType} ${currentUnits?.x}`
        },
        type: 'linear',
        min,
        max
      },
      y: {
        display: true,
        title: {
          display: true,
          text: `${chartType} ${currentUnits?.y}`
        },
        type: 'linear'
      }
    },
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        callbacks: {
          beforeTitle: (context) => {
            const { currentPatient } = context?.[0]?.raw;

            if (!currentPatient) return;

            return patient?.fullName ? patient.fullName : 'Current Patient';
          },
          title: (data) => {
            const { raw } = data[0];
            return `${chartType} ${raw.y} ${currentUnits?.y}`;
          },
          afterTitle: (data) => {
            if (chartType !== 'HEAD' || !data[0].raw.currentPatient) return;

            return `HEAD ${vitals.head_circumference_unit === 'cm' ? vitals.head_circumference : inchToCm(vitals.head_circumference)} (cm)`

          },
          beforeLabel: (data) => {
            const { raw } = data;
            return `${measurementType} ${raw.x} ${currentUnits?.x}`;
          },
          label: (context) => {
            const { percentile } = context.raw;
            return `${getOrdinalSuffix({ number: percentile.split('p')[1] })} percentile`;
          },
          labelColor: (context) => {
            const { borderColor, backgroundColor } = context.dataset;

            return {
              borderColor,
              backgroundColor
            };
          }
        }
      }
    }
  };
};

exports.groupBy = (array) => {
  if (!ia(array)) return [];

  const groupedObject = {};

  array.forEach((obj) => {
    const key = Object.keys(obj)[0];

    if (!groupedObject[key]) {
      groupedObject[key] = [];
    }

    groupedObject[key].push(obj);
  });

  return groupedObject;
};

exports.cmToInch = (cm) => parseFloat((cm / 2.54).toFixed(1));
exports.kgToLbs = (kg) => parseFloat((kg * 2.20462).toFixed(1));

exports.conversionFactors = {
  length_for_age: (cm) => this.cmToInch(cm),
  head_circumference_for_age: (cm) => this.cmToInch(cm),
  weight_for_age: (kg) => this.kgToLbs(kg),
  weight_for_length: {
    value: (kg) => this.kgToLbs(kg),
    measurement_value: (cm) => this.cmToInch(cm)
  },
  weight_for_height: {
    value: (kg) => this.kgToLbs(kg),
    measurement_value: (cm) => this.cmToInch(cm)
  }
};

exports.convertValue = (value, conversion) => {
  return conversion(value);
};

exports.convertData = (chartData, chartType) => {
  const conversion = this.conversionFactors[chartType];

  if (!conversion) {
    return chartData;
  }

  return chartData.map((data) => ({
    ...data,
    measurement_value: conversion.measurement_value
      ? conversion.measurement_value(data.measurement_value)
      : data.measurement_value,
    ...Object.fromEntries(
      Object.entries(data)
        .filter(([key]) => key.startsWith('p'))
        .map(([key, value]) => [key, this.convertValue(value, conversion.value || conversion)])
    )
  }));
};

exports.typeOfUnits = ({ unit }) => {
  switch (unit) {
    case 'weight_for_age':
      return { y: '(lbs)', x: '' };
    case 'head_circumference_for_age':
      return { y: '(in)', x: '' };
    case 'length_for_age':
      return { y: '(in)', x: '' };
    case 'weight_for_height':
      return { y: '(lbs)', x: '(in)' };
    case 'weight_for_length':
      return { y: '(lbs)', x: '(in)' };
    default:
      return { y: '', x: '' };
  }
};

exports.calculateAgeInMonths = (birthdate) => {
  const currentDate = moment.utc().format('YYYY-MM-DD');
  const ageInMonths = moment
    .utc(currentDate, 'YYYY-MM-DD')
    .diff(moment.utc(birthdate, 'YYYY-MM-DD'), 'months');

  return ageInMonths;
};

exports.vitalChart = ({ chartType, vitals, patient }) => {
  switch (chartType) {
    case 'weight_for_age':
      return {
        unitValue: parseFloat(vitals?.weight),
        measurementValue: this.calculateAgeInMonths(patient.dob)
      };
    case 'head_circumference_for_age':
      const unit = vitals?.head_circumference_unit === 'cm';
      return {
        unitValue: unit ? this.cmToInch(vitals?.head_circumference) : vitals?.head_circumference,
        measurementValue: this.calculateAgeInMonths(patient.dob)
      };
    case 'length_for_age':
      return {
        unitValue: parseFloat((vitals?.height / 2.54).toFixed(1)),
        measurementValue: this.calculateAgeInMonths(patient.dob)
      };
    case 'weight_for_height':
      return {
        unitValue: parseFloat(vitals?.weight),
        measurementValue: parseFloat((vitals?.height / 2.54).toFixed(1))
      };
    case 'weight_for_length':
      return {
        unitValue: parseFloat(vitals?.weight),
        measurementValue: parseFloat((vitals?.height / 2.54).toFixed(1))
      };
    default:
      return {};
  }
};

exports.generateRandomColor = (idx) => {
  const predefinedColors = [
    'rgba(128, 128, 128, 0.5)', // Gray
    'rgba(0, 0, 0, 0.5)', // Black
    'rgba(255, 192, 203, 0.5)', // Pink
    'rgba(255, 165, 0, 0.5)', // Orange
    'rgba(0, 128, 0, 0.5)', // Green
    'rgba(128, 0, 128, 0.5)', // Purple
    'rgba(0, 0, 255, 0.5)' // Blue
  ];

  return predefinedColors[idx % predefinedColors.length];
};

exports.formatChart = ({ growthData = [], patientDetails = {} }) => {
  const labels = [
    // 'p01',
    // 'p1',
    // 'p3',
    'p5',
    'p10',
    // 'p15',
    'p25',
    'p50',
    'p75',
    // 'p85',
    'p90',
    'p95'
    // 'p97',
    // 'p99',
    // 'p999'
  ];
  const finalData = [];

  for (const percentile of labels) {
    growthData.map((data) => {
      data[percentile] &&
        finalData.push({
          [percentile]: data[percentile],
          value: data.measurement_value
        });
    });
  }

  const groupedData = this.groupBy(finalData);

  const datasets = Object.entries(groupedData).map(([label, data], idx) => ({
    label: label,
    data: data.map((d) => ({ x: d.value, y: d[label], percentile: label })),
    backgroundColor: this.generateRandomColor(idx),
    borderColor: this.generateRandomColor(idx),
    borderWidth: 1,
    fill: false,
    tension: 0.4
  }));

  return [
    {
      data: [patientDetails],
      borderColor: 'red',
      backgroundColor: 'red',
      borderWidth: 10,
      fill: false,
      tension: 0.4
    },
    ...datasets
  ];
};

exports.findClosestPercentile = ({ measurementValue, unitValue, data }) => {
  const result = ia(data)
    ? data?.reduce((prev, curr) =>
      Math.abs(curr.measurement_value - measurementValue) <
        Math.abs(prev.measurement_value - measurementValue)
        ? curr
        : prev
    )
    : null;

  if (!result) return null;

  const percentiles = [
    { label: 'p01', value: result.p01 },
    { label: 'p1', value: result.p1 },
    { label: 'p3', value: result.p3 },
    { label: 'p5', value: result.p5 },
    { label: 'p10', value: result.p10 },
    { label: 'p15', value: result.p15 },
    { label: 'p25', value: result.p25 },
    { label: 'p50', value: result.p50 },
    { label: 'p75', value: result.p75 },
    { label: 'p85', value: result.p85 },
    { label: 'p90', value: result.p90 },
    { label: 'p95', value: result.p95 },
    { label: 'p97', value: result.p97 },
    { label: 'p99', value: result.p99 },
    { label: 'p999', value: result.p999 }
  ];

  const closestPercentile = percentiles.reduce((prev, curr) =>
    Math.abs(curr.value - unitValue) < Math.abs(prev.value - unitValue) ? curr : prev
  );

  return {
    x: `${measurementValue}`,
    y: `${unitValue}`,
    percentile: closestPercentile.label,
    currentPatient: true
  };
};

exports.defaultGender = ({ gender }) => {
  switch (gender) {
    case 'male':
      return ['boys'];
    case 'female':
      return ['girls'];
    default:
      return ['boys', 'girls'];
  }
};
