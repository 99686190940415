import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const Item = ({ icon, text, price, className, priceClassName }) => {
  return (
    <div className={cs('flex items-center justify-between gap-3 py-1 pr-[6px]', className)}>
      <div className="flex items-center gap-[6px]">
        <Icon icon={icon} size={20} />
        <span className="flex w-full justify-between text-sm font-400 leading-[22px]">{text}</span>
      </div>
      <span className={cs('text-sm font-500', priceClassName)}>{price}</span>
    </div>
  );
};

export default Item;
