import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { updateDocument } from 'api/Documents';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { imagePath } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';

import Modal from '../../../shared/Modal/Modal';

import EditDocument from './components/EditDocument';
import PreviewDocument from './components/PreviewDocument';
import formatFilters from './lib/formatFilters';

export default function View({
  viewDocument,
  setViewDocument,
  handleCloseModal,
  action,
  patientId,
  handle,
  type,
  exportRef,
  print
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { filters, page, limit, sort } = useTableContext();

  const submitFormButton = useRef();

  const [edit, setEdit] = useState({ name: viewDocument?.title, category: viewDocument?.category });

  const handleEdit = async () => {
    const data = await updateDocument(navigate, {
      handle,
      name: edit.name,
      category: edit.category.value
    });

    if (data?.update > 0) {
      queryClient.setQueryData(
        ['documents', formatFilters({ filters, page, limit, sort, patientId }), patientId],
        (old) => {
          return {
            ...old,
            documents: old?.documents?.map((item) => {
              if (item?.document_handle === handle) {
                return {
                  ...item,
                  name: edit.name,
                  category: edit?.category?.value?.toString()
                };
              } else {
                return item;
              }
            })
          };
        }
      );
      showAlert({ title: 'Document edited successfully', color: 'success' });
      handleCloseModal();
    } else {
      showAlert({ title: 'There was a problem while editing document.', color: 'success' });
    }
  };

  const handleFormSubmit = async () => {
    if (submitFormButton.current) {
      submitFormButton.current.click();
    }
  };

  const [loading, setLoading] = useState({
    personal_id_front: false,
    personal_id_back: false,
    insurance_front: false,
    insurance_back: false,
    filestack: false
  });
  const [url, setUrl] = useState();
  const [hipaaData, setHipaaData] = useState();

  useEffect(() => {
    if (handle && type !== 'form') getUrl();
  }, [handle]);

  const getUrl = async () => {
    setLoading((prev) => ({ ...prev, filestack: true }));

    if (
      [
        'image',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'pdf',
        'application/pdf',
        'rtf',
        'application/rtf',
        'eml',
        'application/eml',
        'custom/html',
        'message/rfc822'
      ].includes(type)
    ) {
      setUrl(imagePath(handle));
    } else if (type === 'hipaa') {
      if (handle?.url) {
        setUrl(imagePath(handle));
      } else if (handle?.data) {
        if (handle?.data) {
          let hipaaJSON = JSON.parse(handle?.data);
          setHipaaData(decodeURIComponent(hipaaJSON?.htmlData));
        } else {
          setHipaaData(<div>No HIPAA Data</div>);
        }
      }
    }

    setLoading((prev) => ({ ...prev, filestack: false }));
  };

  return (
    <Modal
      customHeader={
        <div className="flex w-full mr-4 justify-between">
          <p className="text-ellipsis text-lg">{viewDocument?.title}</p>
          {viewDocument?.type === 'hipaa' && (
            <Button
              size="small"
              text="Print"
              iconColor="white"
              icon="new-printer"
              onClick={() => print()}
            />
          )}
        </div>
      }
      handleOpen={!!viewDocument}
      handleClose={handleCloseModal}
      isLarge
      className={
        ['hipaa', 'insurance'].includes(viewDocument.type) ? 'max-w-[1280px]' : 'max-w-[640px]'
      }
      slideFromRight
      footer={
        viewDocument?.action === 'Edit' && (
          <div className="flex flex-end gap-2">
            <Button
              className="mr-auto"
              text="Cancel"
              outlined
              color="neutral"
              onClick={handleCloseModal}
            />

            <Button
              text="Update"
              primary
              onClick={viewDocument?.type === 'form' ? handleFormSubmit : handleEdit}
            />
          </div>
        )
      }>
      {action === 'Edit' ? (
        <EditDocument
          {...{
            edit,
            setEdit,
            handle,
            type,
            action,
            loading,
            patientId,
            submitFormButton,
            handleCloseModal
          }}
        />
      ) : (
        <PreviewDocument
          {...{
            url,
            hipaaData,
            setLoading,
            exportRef,
            handle,
            type,
            patientId,
            setViewDocument,
            loading
          }}
        />
      )}
    </Modal>
  );
}
