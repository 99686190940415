import React, { Fragment, useState } from 'react';
import { Tab } from '@headlessui/react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import Skeleton from 'react-loading-skeleton';
import PatientClaims from 'components/practice/charts/billing/claims/PatientClaims';
import TransactionsTable from 'components/practice/charts/billing/transactions/TransactionsTable';
import Superbills from 'components/practice/charts/billing/superbill/Superbill';
import Ledger from 'components/practice/charts/billing/ledger/Ledger';
import PatientInvoices from 'components/practice/charts/billing/Invoices/PatientInvoices';
import Allowed from 'components/shared/Permissions/Allowed';

const BillingPatientModal = ({
  patient_id,
  modalVisible,
  setModalUnVisible,
  selectedTab,
  dataId
}) => {
  const activeTabOption = {
    Superbills: 1,
    Claims: 2
  };

  const [activeTab, setActiveTab] = useState(activeTabOption[selectedTab] || 0);

  const { data, isLoading, isFetching } = usePatient({
    params: { id: patient_id },
    dependencies: [patient_id]
  });

  const patient = data?.patient || {};

  const tabData = [
    {
      label: 'Transactions',
      content: (
        <Allowed requiredPermissions="billing.read" showMessage showIllustration>
          <TransactionsTable patient_id={patient?.id} />
        </Allowed>
      )
    },
    {
      label: 'Superbills',
      content: (
        <Allowed requiredPermissions="superbill.read" showMessage showIllustration>
          <Superbills patient={patient} superbill_id={selectedTab === 'Superbills' && dataId} />
        </Allowed>
      )
    },
    {
      label: 'Claims',
      content: (
        <Allowed requiredPermissions="billing.read" showMessage showIllustration>
          <PatientClaims patient={patient} claim_id={selectedTab === 'Claims' && dataId} />
        </Allowed>
      )
    },
    {
      label: 'Ledger',
      content: (
        <Allowed requiredPermissions="payments.read" showMessage showIllustration>
          <Ledger patient={patient} />
        </Allowed>
      )
    },
    {
      label: 'Invoices',
      content: (
        <Allowed requiredPermissions="invoicing.read" showMessage showIllustration>
          <PatientInvoices patient_id={patient?.id} />
        </Allowed>
      )
    }
  ];

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Modal
      handleOpen={modalVisible}
      handleClose={setModalUnVisible}
      isLarge
      title="Billing"
      slideFromRight
      subModal
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Close" onClick={setModalUnVisible} outlined color="neutral" />
        </div>
      }>
      {isLoading || isFetching ? (
        <Skeleton count={5} />
      ) : (
        <Tab.Group selectedIndex={activeTab} onChange={handleTabChange}>
          <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
            {tabData.map((tab, i) => {
              return (
                <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                  {({ selected }) => (
                    <button
                      className={cs(
                        'flex items-center gap-1 border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                        selected
                          ? ' !border-primary-500 font-600 text-primary-500'
                          : 'font-500 text-neutral-800'
                      )}>
                      {tab.label}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {tabData.map((tab, i) => (
              <Tab.Panel key={i} className="h-[calc(100vh-250px)]" unmount={false}>
                {({ selected }) => {
                  return selected ? tab.content : selectedTab === tab.label ? tab.content : null;
                }}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </Modal>
  );
};

export default BillingPatientModal;
