import { iaRa, mString, optionify } from 'lib/helpers/utility';

import Actions from '../components/Actions';

export const DEFAULT_COLUMN_DEFS = { resizable: true };

const ZERO_VALUE = 0;
const DEFAULT_TIME_LENGTH = 60;
const DEFAULT_COLOR = '#000000';
const DEFAULT_CHARGE_TYPE = {
  value: 'cash',
  label: 'Self Pay'
};

export const SCHEDULE_OF_FEES_TITLE = 'Schedule of Fees';
export const CONFIRM_TITLE = 'Adding Services to your Practice';
export const CONFIRM_BTN_TEXT = 'Confirm';
export const CANCEL_BTN_TEXT = 'Cancel';

export const CREATE_SERVICES_QUERY_KEY = 'services';
export const CREATE_SERVICES_SUCCESS_MESSAGE = 'Services created successfully!';
export const CREATE_SERVICES_ERROR_MESSAGE = 'Something went wrong creating the services!';

export const GENERATE_MESSAGE = (count = 0) =>
  `${count} Services will be added to your practice. Do you want to continue?`;

export const GRID_OPTIONS = {
  cellSelection: true,
  animateRows: true,
  rowGroupPanelShow: 'never',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  rowSelection: 'multiple',
  suppressRowClickSelection: true
};

export const COLUMN_DEFS = [
  {
    headerName: '',
    field: '',
    width: 40,
    headerCheckboxSelection: true,
    checkboxSelection: (params) => !params.node.group
  },
  {
    field: 'procedure_code',
    headerName: 'Procedure Code',
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false
  },
  {
    field: 'professionGroups',
    headerName: 'Specialty',
    sortable: false,
    cellRenderer: ({ value }) =>
      iaRa(value)
        .map((v) => v.name)
        .join(', ')
  },
  {
    field: 'total_cost_cents',
    headerName: 'Self Pay',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  },
  {
    field: 'par_amount',
    headerName: 'Allowable Amount',
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'standard_charge',
    headerName: 'Standard',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  }
];

export const defaultFilters = (professionGroups = []) => ({
  professionGroup: {
    type: 'search',
    values: null,
    title: 'Specialty',
    options: optionify(professionGroups, 'name', 'id'),
    multiple: true,
    placeholder: 'Select Specialty'
  }
});

export const restructureScheduleOfFees = (data) =>
  data.map((item) => {
    const parAmount = item.par_amount || ZERO_VALUE;
    return {
      name: item.name || '',
      description: '',
      image: '',
      procedure_code: item.code || '',
      par_amount: parAmount,
      total_cost_cents: parAmount,
      time_length: DEFAULT_TIME_LENGTH,
      self_scheduling: false,
      charge_type: DEFAULT_CHARGE_TYPE.value,
      standard_charge: parAmount,
      cpt: null,
      paySchedule: [],
      serviceId: null,
      practitioner_ids: [],
      color: DEFAULT_COLOR,
      category_id: null,
      state_tax_rate: ZERO_VALUE,
      local_tax_rate: ZERO_VALUE,
      ndc_codes: [],
      professionGroups: item.professionGroups || []
    };
  });

export const SPECIALTY_COLUMN_DEFS = [
  {
    field: 'name',
    headerName: 'Name',
    sortable: false
  },
  {
    field: 'professionGroups',
    headerName: 'Specialty',
    sortable: false,
    cellRenderer: ({ value }) =>
      iaRa(value)
        .map((v) => v.name)
        .join(', ')
  },
  {
    field: 'total_cost_cents',
    headerName: 'Self Pay',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  },
  {
    field: 'par_amount',
    headerName: 'Allowable Amount',
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'standard_charge',
    headerName: 'Standard',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  }
];

export const REVIEW_COLUMN_DEFS = [
  {
    field: 'procedure_code',
    headerName: 'Procedure Code',
    sortable: false
  },
  {
    field: 'name',
    headerName: 'Name',
    sortable: false
  },
  {
    field: 'professionGroups',
    headerName: 'Specialty',
    sortable: false,
    cellRenderer: ({ value }) =>
      iaRa(value)
        .map((v) => v.name)
        .join(', ')
  },
  {
    field: 'total_cost_cents',
    headerName: 'Self Pay',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  },
  {
    field: 'par_amount',
    headerName: 'Allowable Amount',
    cellRenderer: ({ value }) => mString(value)
  },
  {
    field: 'standard_charge',
    headerName: 'Standard',
    cellRenderer: ({ value }) => mString(value),
    sortable: false
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
