import React, { useState } from 'react';

import { PopoverContext } from './PopoverContext';

export const PopoverContextProvider = ({ children }) => {
  const [context, setContext] = useState(null);

  return (
    <PopoverContext.Provider value={{ context, setContext }}>{children}</PopoverContext.Provider>
  );
};
