import Tippy from '@tippyjs/react';
import {
  appointmentBalanceState,
  appointmentCCState,
  appointmentCheckinState,
  appointmentConfirmState,
  claimState,
  clinicalNoteStatus,
  superbillStatus
} from 'constants';
import { capitalize } from 'lodash';

import { requestApi } from 'api/Api';
import {
  cancelMultipleAppointments,
  creationActionAppointmentMultiple,
  remindAppointmentMultiple
} from 'api/Appointment';
import { getPatients } from 'api/Patients';
import { getPractitioners } from 'api/Practitioner';
import { getResourcesAsOptions } from 'api/Resource';
import { getServices } from 'api/Service';
import { getTags } from 'api/Tags';

import {
  findAppointmentStatusColor,
  findAppointmentStatusLabel,
  formatDate,
  formatDateAndTime,
  formatPhoneNumber,
  mString
} from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Status from 'components/shared/Status/Status';

import TableCheckbox from '../../TableCheckbox';
import TableHeaderCheckbox from '../../TableHeaderCheckbox';
import Actions from '../components/Actions';

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    label: p?.fullName,
    value: p?.id
  }));
};

export const defaultColumns = (timezone, appointmentStatuses) => [
  {
    field: 'checkbox',
    headerName: '',
    cellRenderer: TableCheckbox,
    headerComponent: TableHeaderCheckbox,
    maxWidth: 60,
    cellClass: 'no-border',
    resizable: false,
    sortable: false
  },
  {
    field: 'id',
    headerName: 'ID',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? capitalize(value) : '-')
  },
  {
    field: 'starts_at',
    headerName: 'Date',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? formatDateAndTime(value, timezone) : '-')
  },
  {
    field: 'patient.fullName',
    headerName: 'Patient',
    cellClass: 'no-border'
  },
  {
    field: 'practitioner.fullName',
    headerName: 'Provider',
    cellClass: 'no-border'
  },
  {
    field: 'patient.email',
    headerName: 'Patient Email',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value : '-')
  },
  {
    field: 'patient.phone',
    headerName: 'Patient Phone',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (formatPhoneNumber(value) ? value : '-')
  },
  {
    field: 'invoice.procedures_label',
    headerName: 'Services',
    cellClass: 'no-border',
    sortable: false
  },
  {
    field: 'invoice.balance',
    headerName: 'Balance',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? mString(value) : '-'),
    sortable: false
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (
      <Tippy
        arrow={true}
        placement="left-start"
        className="tippy-black w-fit"
        content={findAppointmentStatusLabel(appointmentStatuses, value)}>
        <div className="flex h-full items-center">
          <Status
            status={findAppointmentStatusLabel(appointmentStatuses, value)}
            color={findAppointmentStatusColor(appointmentStatuses, value)}
            className="flex min-w-[80px] items-center"
          />
        </div>
      </Tippy>
    ),
    maxWidth: 200,
    minWidth: 50,
    resizable: true
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = {
  appointment_date: {
    values: null,
    type: 'date-range',
    title: 'Appointment Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  provider: {
    type: 'infinite-scroll',
    multiple: true,
    values: [],
    title: 'Providers',
    placeholder: 'Select providers',
    icon: false,
    queryKey: 'practitioners',
    queryFn: getPractitioners
  },
  resources: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Resources',
    placeholder: 'Select resources',
    icon: false,
    queryKey: 'resources',
    queryFn: getResourcesAsOptions
  },
  service: {
    type: 'infinite-scroll',
    multiple: true,
    title: 'Service',
    placeholder: 'Search services',
    values: [],
    icon: false,
    queryKey: 'services',
    queryFn: getServices,
    params: { limit: 25, withCount: true }
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  appointmentTags: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Tags',
    placeholder: 'Select appointment tags',
    icon: false,
    queryKey: 'tags',
    queryFn: getTags,
    params: { kind: 'appointment' }
  },
  confirmState: {
    type: 'checkbox',
    values: null,
    options: appointmentConfirmState,
    title: 'Confirmation'
  },
  checkinState: {
    type: 'checkbox',
    values: null,
    options: appointmentCheckinState,
    title: 'Checkin'
  },
  balanceState: {
    type: 'checkbox',
    values: null,
    options: appointmentBalanceState,
    title: 'Balance'
  },
  CCState: {
    type: 'checkbox',
    values: null,
    options: appointmentCCState,
    title: 'Card on file'
  },
  arrivalStatus: {
    type: 'checkbox',
    values: null,
    options: [],
    title: 'Arrival status'
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: null,
    options: clinicalNoteStatus,
    title: 'Clinical note status'
  },

  superbillStatus: {
    type: 'checkbox',
    values: null,
    options: superbillStatus,
    title: 'Superbill'
  },
  claimState: {
    type: 'checkbox',
    values: null,
    options: claimState,
    title: 'Claim'
  }
};

export const handleRemind = async ({
  navigate,
  type = '',
  appointments = [],
  setSelectedRows,
  setMultiActionsModal
}) => {
  const params = { appointment_ids: appointments, type };
  await remindAppointmentMultiple(navigate, params);

  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentReminder: false
  }));
  showAlert({ message: 'Appointment reminder sent successfully', type: 'success' });
};

export const handleCreationAction = async ({
  navigate,
  type = '',
  appointments = [],
  setSelectedRows,
  setMultiActionsModal
}) => {
  const params = { appointment_ids: appointments, type };
  await creationActionAppointmentMultiple(navigate, params);

  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentCreation: false
  }));
  showAlert({ message: 'Appointment creation sent successfully', type: 'success' });
};

export const cancelAppointment = async ({
  setLoading,
  navigate,
  selectedRows,
  queryClient,
  setMultiActionsModal,
  setSelectedRows
}) => {
  setLoading(true);

  const params = { ids: selectedRows };
  await cancelMultipleAppointments(navigate, params);

  queryClient.invalidateQueries(['appointments']);
  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentCancel: false
  }));
  showAlert({ message: 'Appointments are canceled', color: 'success' });
  setLoading(false);
};

export const updateAppointments = async ({
  queryClient,
  navigate,
  setMultiActionsModal,
  status = '',
  appointments = [],
  setSelectedRows
}) => {
  try {
    const params = {
      update_appointment: true,
      appointments: appointments.map((appointment) => ({
        ...appointment?.data,
        status,
        resource_id: appointment?.resources,
        products: appointment?.products || [],
        procedures: appointment?.data?.invoice?.procedures
      }))
    };
    await requestApi({
      url: '/api/appointment/update_multiple',
      navigate,
      params
    });
    queryClient.invalidateQueries(['appointments']);
    showAlert({ message: 'Appointments updated successfully', color: 'success' });
  } catch (error) {
    showAlert({ message: 'Failed to update', color: 'danger' });
  }
  setSelectedRows([]);
  setMultiActionsModal((prevState) => ({
    ...prevState,
    appointmentStatus: false
  }));
};
