import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';
import { ActiveSymptoms as initialValue } from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from '../../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from '../../../../../../../shared/Icon/Icon';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { userState, clinicalNote as clinicalNoteState } from '../../../../../../../state';

const ActiveSymptoms = ({ sectionRef = null }) => {
  const { cnDisplaySettings } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote = () => {}] = useRecoilState(clinicalNoteState);
  const [activeSymptoms, setActiveSymptoms] = useState([]);
  const depressionNoteRef = useRef();
  const anxietyNoteRef = useRef();
  const obsessionsNoteRef = useRef();
  const continuedSubstanceAbuseAlcoholNoteRef = useRef();
  const continuedSubstanceAbuseDrugsNoteRef = useRef();
  const relationshipIssuesMarriedNoteRef = useRef();
  const relationshipIssuesOtherNoteRef = useRef();
  const overwhelmedFeelingsNoteRef = useRef();
  const difficultyConcentratingNoteRef = useRef();
  const difficultyPlanningNoteRef = useRef();
  const difficultyCompletingTasksNoteRef = useRef();
  const observationsAttitudeAppearanceOtherNoteRef = useRef();
  const otherNoteRef = useRef();

  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm = cnDisplaySettings?.sections?.progress?.activeSymptoms?.advancedForm;
  const user = useRecoilValue(userState);
  const textAreaRef = useRef();

  useEffect(() => {
    setActiveSymptoms(clinicalNote?.epn?.activeSymptoms || initialValue);
  }, [clinicalNote]);

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { activeSymptoms } }
  }));

  const syncActiveSymptomsAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ activeSymptomsObject: updatedObject });
    } else {
      setActiveSymptoms(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleChange = (e, key) => {
    const { name, value } = e.target;

    const updatedObject = {
      ...activeSymptoms,
      [key ?? name]: key ? value : !activeSymptoms[name]
    };

    syncActiveSymptomsAndClinicalNotes(updatedObject);
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      epn: {
        ...prevState.epn,
        activeSymptoms: updatedObject
      }
    }));
  };

  const handleNarrative = ({
    activeSymptomsObject = activeSymptoms,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...activeSymptomsObject, narrative: event };

    setActiveSymptoms(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    activeSymptomsObject = activeSymptoms,
    scrollable = false
  } = {}) => {
    const narrativeOptions = {
      data: activeSymptomsObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'active symptoms'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      activeSymptomsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <React.Fragment>
      {isAdvancedForm && (
        <div>
          <p
            className="!pb-3 text-sm font-500
         text-neutral-800">
            Symptoms
          </p>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(326px,1fr))] gap-4">
            <div className="grid gap-y-2">
              <Checkbox
                label="Depression"
                name="depression"
                isChecked={activeSymptoms.depression}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.depression && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  value={activeSymptoms.depressionNote}
                  transcribing
                  forwardedRef={depressionNoteRef}
                  onChange={(event) => handleChange(event, 'depressionNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Anxiety"
                name="anxiety"
                isChecked={activeSymptoms.anxiety}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.anxiety && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={anxietyNoteRef}
                  value={activeSymptoms.anxietyNote}
                  onChange={(event) => handleChange(event, 'anxietyNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Obsessions"
                name="obsessions"
                isChecked={activeSymptoms.obsessions}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.obsessions && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={obsessionsNoteRef}
                  value={activeSymptoms.obsessionsNote}
                  onChange={(event) => handleChange(event, 'obsessionsNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Continued substance abuse - Alcohol"
                name="continuedSubstanceAbuseAlcohol"
                isChecked={activeSymptoms.continuedSubstanceAbuseAlcohol}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.continuedSubstanceAbuseAlcohol && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={continuedSubstanceAbuseAlcoholNoteRef}
                  value={activeSymptoms.continuedSubstanceAbuseAlcoholNote}
                  onChange={(event) => handleChange(event, 'continuedSubstanceAbuseAlcoholNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Continued substance abuse - Drugs"
                name="continuedSubstanceAbuseDrugs"
                isChecked={activeSymptoms.continuedSubstanceAbuseDrugs}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.continuedSubstanceAbuseDrugs && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={continuedSubstanceAbuseDrugsNoteRef}
                  value={activeSymptoms.continuedSubstanceAbuseDrugsNote}
                  onChange={(event) => handleChange(event, 'continuedSubstanceAbuseDrugsNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Relationship issues (married)"
                name="relationshipIssuesMarried"
                isChecked={activeSymptoms.relationshipIssuesMarried}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.relationshipIssuesMarried && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={relationshipIssuesMarriedNoteRef}
                  value={activeSymptoms.relationshipIssuesMarriedNote}
                  onChange={(event) => handleChange(event, 'relationshipIssuesMarriedNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Relationship issues (other)"
                name="relationshipIssuesOther"
                isChecked={activeSymptoms.relationshipIssuesOther}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.relationshipIssuesOther && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={relationshipIssuesOtherNoteRef}
                  value={activeSymptoms.relationshipIssuesOtherNote}
                  onChange={(event) => handleChange(event, 'relationshipIssuesOtherNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Overwhelmed feelings"
                name="overwhelmedFeelings"
                isChecked={activeSymptoms.overwhelmedFeelings}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.overwhelmedFeelings && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={overwhelmedFeelingsNoteRef}
                  value={activeSymptoms.overwhelmedFeelingsNote}
                  onChange={(event) => handleChange(event, 'overwhelmedFeelingsNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Difficulty concentrating"
                name="difficultyConcentrating"
                isChecked={activeSymptoms.difficultyConcentrating}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.difficultyConcentrating && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={difficultyConcentratingNoteRef}
                  value={activeSymptoms.difficultyConcentratingNote}
                  onChange={(event) => handleChange(event, 'difficultyConcentratingNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Difficulty planning"
                name="difficultyPlanning"
                isChecked={activeSymptoms.difficultyPlanning}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.difficultyPlanning && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={difficultyPlanningNoteRef}
                  value={activeSymptoms.difficultyPlanningNote}
                  onChange={(event) => handleChange(event, 'difficultyPlanningNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Difficulty completing tasks"
                name="difficultyCompletingTasks"
                isChecked={activeSymptoms.difficultyCompletingTasks}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.difficultyCompletingTasks && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={difficultyCompletingTasksNoteRef}
                  value={activeSymptoms.difficultyCompletingTasksNote}
                  onChange={(event) => handleChange(event, 'difficultyCompletingTasksNote')}
                />
              )}
            </div>
            <div className="grid gap-y-2">
              <Checkbox
                label="Observations (Attitude / Appearance, other)"
                name="observationsAttitudeAppearanceOther"
                isChecked={activeSymptoms.observationsAttitudeAppearanceOther}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.observationsAttitudeAppearanceOther && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={observationsAttitudeAppearanceOtherNoteRef}
                  value={activeSymptoms.observationsAttitudeAppearanceOtherNote}
                  onChange={(event) =>
                    handleChange(event, 'observationsAttitudeAppearanceOtherNote')
                  }
                />
              )}
            </div>
            <div className="col-span-full grid gap-y-2">
              <Checkbox
                label="Other"
                name="other"
                isChecked={activeSymptoms.other}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {activeSymptoms.other && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  transcribing
                  forwardedRef={otherNoteRef}
                  value={activeSymptoms.otherNote}
                  onChange={(event) => handleChange(event, 'otherNote')}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="dashed-top !mt-4 !pt-3">
        <NarrativeForm
          syncNarrative={syncNarrative}
          setSyncNarrative={setSyncNarrative}
          onChange={(event) => handleNarrative({ event, sync: false })}
          setCurrentForm={setActiveSymptoms}
          restData={{
            className: 'w-full',
            label: 'Active Symptoms Narrative',
            placeholder: 'Add narrative here',
            id: 'Narrative-active-symptoms',
            'data-qa': 'narrative-active-symptoms',
            name: 'Narrative-active-symptoms',
            value: activeSymptoms?.narrative,
            forwardedRef: textAreaRef,
            formName: 'activeSymptoms'
          }}
          narrativeOptions={isAdvancedForm ? narrativeOptions : []}
        />
      </div>
    </React.Fragment>
  );
};

export default ActiveSymptoms;
