import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getContacts } from '../../../../api/Contacts';

export const useContacts = (params, options) => {
  const navigate = useNavigate();
  const { search, page, limit } = params;
  return useQuery(['contacts', search, page, limit], () => getContacts(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    ...options
  });
};
