import React, { useEffect, useRef, useState } from 'react';

import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { useSetRecoilState } from 'recoil';

import { socket } from '../../../api/Socket';
import state from '../../state';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';

// let mediaRecorder, socket, silenceTimeout;
let mediaRecorder, silenceTimeout;

const Transcribe = ({
  id,
  name,
  value,
  setValue,
  setDisable,
  disabled,
  iconSize,
  withLabel = true
}) => {
  const setSocketHasConnection = useSetRecoilState(state.socketHasConnection);
  const [recording, setRecording] = useState(false);
  const [, setTranscribing] = useState(false);
  const text = useRef('');
  const textFutures = useRef({ solidText: '', softText: '' });
  const startRecording = (e) => {
    e?.preventDefault();
    setRecording(true);
    setDisable(true);
  };

  useEffect(() => {
    return () => {
      stopMediaRecorder();
      stopDeepgramListening();
      clearTimeout(silenceTimeout);
      setDisable(false);
      socket?.off('transcript');
    };
  }, []);

  useEffect(() => {
    if (recording) {
      deepGramAudio2text();
    }
    if (!recording) {
      setTranscribing(false);
      clearTimeout(silenceTimeout);
      socket?.off('transcript');
    }
  }, [recording]);

  useEffect(() => {
    text.current = value;
    textFutures.current.solidText = value || '';
    textFutures.current.softText = '';
  }, []);

  useEffect(() => {
    setSocketHasConnection(!!socket?.connected);
    if (!socket?.connected) {
      setTranscribing(false);
    }
  }, [socket?.connected]);

  useEffect(() => {
    text.current = value;
    if (!recording) {
      textFutures.current.solidText = value || '';
      textFutures.current.softText = '';
    }
  }, [value]);

  const stopRecording = async (e) => {
    e?.preventDefault();
    setTranscribing(false);

    if (recording && mediaRecorder?.state !== 'inactive') {
      stopMediaRecorder();
    }

    if (socket && socket.connected) {
      stopDeepgramListening();
    }
    setRecording(false);
    setDisable(false);
  };

  const stopMediaRecorder = () => {
    mediaRecorder?.stop();
    mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
    mediaRecorder = null;
    setRecording(false);
  };

  const stopDeepgramListening = () => {
    socket.emit('deepgram-stop');
    setTranscribing(false);
    // socket?.close();
  };

  const notSupported = () => {
    alert('Browser not supported');
    setRecording(false);
  };

  const deepGramAudio2text = () => {
    function stopRecording(dontSendStop) {
      setTranscribing(false);

      if (silenceTimeout) {
        clearTimeout(silenceTimeout);
      }
      if (!socket?.connected && !recording) return;

      if (mediaRecorder && mediaRecorder?.state === 'recording') {
        mediaRecorder?.stop();
        mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
        mediaRecorder = null;
      }
      if (silenceTimeout) {
        clearTimeout(silenceTimeout);
      }
      if (socket && socket.connected && !dontSendStop) {
        socket.emit('deepgram-stop');
        // socket?.close();
      }

      setRecording(false);
      setDisable(false);
    }
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        if (!MediaRecorder.isTypeSupported('audio/webm')) {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          if (isSafari) {
            mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
          } else {
            return notSupported();
          }
        } else {
          mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
        }

        if (!socket.connected) {
          socket.connect();
          socket.on('connect', () => {
            socket.emit('deepgram-connect');
            mediaRecorder?.addEventListener('dataavailable', async (event) => {
              if (event.data.size > 0 && socket.connected) {
                setTranscribing(true);
                socket.emit('deepgram-packet-sent', event.data);
              }
            });
          });
        } else {
          socket.emit('deepgram-connect');
          mediaRecorder?.addEventListener('dataavailable', async (event) => {
            if (event.data.size > 0 && socket.connected) {
              setTranscribing(true);
              socket.emit('deepgram-packet-sent', event.data);
            }
          });
        }

        mediaRecorder?.start(1100);

        socket.on('deepgram-disconnect', async () => {
          stopRecording(true);
        });

        socket.on('transcript', async (m) => {
          if (m) {
            textFutures.current.solidText += ` ${m}`;
            textFutures.current.softText = '';

            const currentText = `${textFutures.current.solidText} ${textFutures.current.softText}`;

            setValue(currentText);
          }

          !!m && silenceTimeout && clearTimeout(silenceTimeout);
          silenceTimeout = setTimeout(() => {
            stopRecording();
          }, 10000);
        });
      })
      .catch((error) => {
        Honeybadger.notify(
          `There's been an error trying to record. Please try again later. Make sure to use Chrome for best experience. ${error}`
        );
      });
  };

  return (
    <div className="flex items-center gap-1">
      <Button
        id={id}
        type="button"
        name={name}
        onClick={recording ? stopRecording : startRecording}
        className={cs(
          'Microphone flex !h-6 !w-6 items-center justify-center rounded-[3px] bg-transparent p-0 hover:!bg-neutral-100',
          recording && 'Microphone--recording !bg-primary-700 hover:!bg-primary-700',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer'
        )}
        // disabled={(!!socket?.connected && !recording) || disabled}>
        disabled={disabled}>
        <Icon
          size={iconSize}
          icon="new-microphone"
          className={cs(disabled ? '!cursor-not-allowed' : 'cursor-pointer')}
          color={recording && 'white'}
        />
      </Button>
      {recording && withLabel && (
        <label className="m-0 text-xs italic text-neutral-500">transcribing</label>
      )}
    </div>
  );
};

export default Transcribe;
