import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';
import _ from 'lodash';

import { ia } from 'lib/helpers/utility';

import AddNdcSearchModal from '../Modal/AddNdcSearchModal';
import DynamicViewNdc from '../View/DynamicViewNdc';

const NdcSelect = ({
  currentNdcCodes = [],
  disabled = false,
  setNewNdcCodes = () => {},
  emptyStateText = 'No NDC Code',
  outlined = false
}) => {
  const parentRef = useRef(null);
  const [selectedNdcCodes, setSelectedNdcCodes] = useState(currentNdcCodes || []);

  const scrollPositionRef = useRef(0);
  const panelRef = useRef(null);
  const [showCreateConfirm, setShowCreateConfirm] = useState(false);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [selectedNdcCodes]);

  useEffect(() => {
    if (ia(currentNdcCodes)) setSelectedNdcCodes(currentNdcCodes);
  }, [currentNdcCodes]);

  const handleUpdateNdcCodes = (ndcCode) => {
    let updatedNdcCodes = [];
    if (selectedNdcCodes.some((selectedNdc) => selectedNdc === ndcCode)) {
      updatedNdcCodes = selectedNdcCodes.filter((selectedNdc) => selectedNdc !== ndcCode);
    } else {
      updatedNdcCodes = [...selectedNdcCodes, ndcCode];
    }
    setSelectedNdcCodes(updatedNdcCodes);

    setNewNdcCodes(updatedNdcCodes);
  };
  const handleAddNewNdcCodes = (newNdcCodes) => {
    let updatedNdcCodes = [...selectedNdcCodes, ...newNdcCodes];
    const filteredNdcCodes = _.uniq(updatedNdcCodes);
    setSelectedNdcCodes(filteredNdcCodes);
    setNewNdcCodes(filteredNdcCodes);
  };

  return (
    <div
      ref={parentRef}
      className={cs(
        'flex h-[40px] items-center justify-between gap-1',
        disabled && 'pointer-events-none',
        outlined
          ? 'rounded-md border border-solid border-neutral-200 bg-white'
          : 'rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] '
      )}>
      {ia(selectedNdcCodes) ? (
        <DynamicViewNdc
          parentRef={parentRef}
          ndcCodes={selectedNdcCodes}
          handleRemove={handleUpdateNdcCodes}
        />
      ) : (
        <p
          className={cs(
            'pl-3 text-sm font-400',
            outlined ? 'text-neutral-500' : 'text-neutral-600'
          )}>
          {emptyStateText}
        </p>
      )}
      <p
        className="text-sm text-neutral-600 font-400 pr-3 cursor-pointer"
        onClick={() => setShowCreateConfirm(!showCreateConfirm)}>
        + Add NDC Code
      </p>
      {showCreateConfirm && (
        <AddNdcSearchModal
          showCreateConfirm={showCreateConfirm}
          setShowCreateConfirm={setShowCreateConfirm}
          setNewNdcCodes={setNewNdcCodes}
          handleAddNewNdcCodes={handleAddNewNdcCodes}
        />
      )}
    </div>
  );
};

export default NdcSelect;
