import React, { useLayoutEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import StaticRanges from '../components/StaticRanges';

export const useStaticRanges = ({
  calendarRef,
  isOpen,
  device,
  isRange,
  isTimeRange,
  value,
  handleRangeSelect
}) => {
  const rootRef = useRef(null);
  const staticRangesRef = useRef(null);

  const cleanup = () => {
    setTimeout(() => {
      if (rootRef.current) {
        rootRef.current.unmount();
        rootRef.current = null;
      }
      if (staticRangesRef.current?.parentNode) {
        staticRangesRef.current.parentNode.removeChild(staticRangesRef.current);
        staticRangesRef.current = null;
      }
    }, 0);
  };

  useLayoutEffect(() => {
    const container = calendarRef?.current?.parentNode;
    let mounted = true;

    if (container && isOpen && device !== 'mobile' && (isRange || isTimeRange)) {
      cleanup();
      setTimeout(() => {
        if (!mounted) return;
        const staticRangesDiv = document.createElement('div');
        staticRangesDiv.className = 'static-ranges';
        container.appendChild(staticRangesDiv);
        staticRangesRef.current = staticRangesDiv;
        rootRef.current = createRoot(staticRangesDiv);
        rootRef.current.render(<StaticRanges onRangeSelect={handleRangeSelect} value={value} />);
      }, 0);
    }

    return () => {
      mounted = false;
      if (!isOpen) cleanup();
    };
  }, [isOpen, value, device, isRange, isTimeRange]);

  return cleanup;
};
