import React from 'react';
import logo from '../../../assets/Icons/logo-symbol.svg';
import cs from 'classnames';
import './Logo.scss';

const Logo = ({ className }) => {
  return (
    <div className={cs('w-[24px] h-[24px]', className)}>
      <img className="w-full h-full object-contain" src={logo} alt="Myriad health" />
    </div>
  );
};

export default Logo;
