const atom = require('recoil').atom;

module.exports = {
  appointmentData: atom({
    key: 'appointment_data',
    default: {
      practice_id: null,
      practice_name: null,
      practice_timezone: null,
      practice_phone: null,
      practitioner_name: null,
      practitioner_userId: null,
      service_id: null,
      service_name: null,
      service_length: null,
      starts_at: null,
      ends_at: null,
      stepsCompleted: null
    }
  })
};
