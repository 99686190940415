import React from 'react';
import { withErrorBoundary } from '../Error/Boundary';
import checkinBackgroundPNG from 'assets/Images/checkin-background.png';
import Advertisement from './Advertisement';

const AdsWrapper = ({ children, bordered = false, adsClassName = '', childClassName = '' }) => {
  return (
    <div className={`h-[100svh] bg-primary-600 overflow-hidden ${bordered && 'p-[1.2rem]'}`}>
      <img
        src={checkinBackgroundPNG}
        className="absolute top-0 left-0 z-10 h-full w-full object-cover"
      />
      <div
        className={`flex h-full w-full flex-row z-20 ${
          bordered && 'overflow-hidden rounded-xl border-1 border-solid border-white'
        }`}>
        <div
          className={`z-20 flex h-full w-2/5 flex-col justify-between overflow-y-auto bg-neutral-50 lg:w-1/2 sm:w-screen md:w-screen ${childClassName}`}>
          {children}
        </div>
        <Advertisement
          wrapperClassName={`!bg-transparent z-20 sm:hidden md:hidden lg:w-1/2 w-3/5 ${adsClassName}`}
        />
      </div>
    </div>
  );
};

export default withErrorBoundary(AdsWrapper);
