import React from 'react';

import { useFormikContext } from 'formik';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia, mString } from 'lib/helpers/utility';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

import Allocation from './components/Allocation';
import DeferredPayment from './components/DeferredPayment';
import Discount from './components/Discount';
import Item from './components/Item';

const Balance = ({ insuranceProfiles, isAllocationOpen, toggleAllocation }) => {
  const { device } = useUIContext();
  const { values, setFieldValue } = useFormikContext();
  const { tax: invoiceTax, total_amount, amount_paid } = values?.invoice || {};

  const currentDiscount =
    values?.discount?.amount_cents ?? values?.invoice?.discount?.amount_cents ?? 0;

  const currentBalance = total_amount - (amount_paid ?? 0) - currentDiscount + invoiceTax;

  return (
    <div className="flex flex-col overflow-hidden xs:pt-4">
      <Item
        icon="new-wallet-add"
        price={mString(currentBalance)}
        text={device === 'desktop' ? 'Appointment balance' : 'Balance'}
        className="min-h-[38px] gap-2 border-0 border-b border-solid border-neutral-200 !px-4"
        priceClassName="font-600 !text-base"
      />
      <div className="flex h-full max-h-[266px] flex-col gap-x-3 gap-y-[6px] overflow-auto border-0 border-l border-solid border-neutral-200 px-4 py-2 xs:border-0">
        {values?.no_payment_selected && !ia(values?.payments) && <DeferredPayment />}
        <Item icon="new-dollar-circle" text="Est. Patient Portion" price={mString(total_amount)} />
        <Item icon="new-wallet-add" text="Payments" price={mString(amount_paid)} />
        <Item text="Discount" icon="new-discount-shape" price={<Discount />} />
        <Item
          icon="new-receipt-discount"
          text="Tax"
          price={
            <CurrencyInput
              value={invoiceTax ?? 0}
              variant="secondary"
              textRight
              onValueChange={(v) => {
                setFieldValue('invoice.tax', +v);
                setFieldValue('tax', +v);
              }}
              wrapperClassName="h-[26px] w-[85px]"
            />
          }
        />
        <Allocation
          insuranceProfiles={insuranceProfiles}
          isOpen={isAllocationOpen}
          toggleAllocation={toggleAllocation}
        />
      </div>
    </div>
  );
};

export default Balance;
