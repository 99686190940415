const { ia, isEmpty, io } = require('../../../../../lib/helpers/utility');
const { convertToNormalText } = require('./customFormsHelper');
const { textItalic, textBoldAndUnderline } = require('./markdownHelpers');

const deleteUnnecessaryKeys = (object) => {
  // if (!object?.noDetails) delete object.noDetails;
  delete object?.narrative;
  delete object?.id;
};

const formatValue = (value) => {
  if (!isEmpty(value?.value)) return value.value;

  return value;
};

exports.formatSections = ({ data = {}, sectionData = [], sectionName = '', isProvider = true }) => {
  if (!io(data) || data?.noDetails) {
    return textItalic({ text: `No notable ${sectionName?.toLowerCase()}\n\n` });
  }

  deleteUnnecessaryKeys(data);

  let formattedData = '';

  if (!isEmpty(data.initialWeight)) {
    formattedData += `${textBoldAndUnderline({ text: 'Initial Weight:' })} ${data.initialWeight}\n`;
  }

  if (!isEmpty(data.goalWeight)) {
    formattedData += `${textBoldAndUnderline({ text: 'Goal Weight:' })} ${data.goalWeight}\n`;
  }

  if (ia(data[sectionData])) {
    data[sectionData].forEach((item) => {
      const unwantedKeys = ['id', 'calendarPopover'];

      for (const key in item) {
        const unwantedKeysCheck = unwantedKeys.includes(key);
        if (!unwantedKeysCheck && !isEmpty(item[key])) {
          if (ia(item[key])) {
            formattedData += `${textBoldAndUnderline({
              text: `${convertToNormalText(key)}:`
            })} ${item[key].map((item) => formatValue(item)).join(', ')}\n`;
          } else if (typeof item[key] === 'boolean') {
            if (item[key]) formattedData += `${convertToNormalText(key)}\n`;
          } else {
            formattedData += `${textBoldAndUnderline({
              text: `${convertToNormalText(key)}:`
            })} ${formatValue(item[key])}\n`;
          }
        }
      }

      formattedData += '\n';
    });
  }

  if (!isEmpty(data.generalNote)) {
    formattedData += textBoldAndUnderline({ text: 'General Note: \n' });
    formattedData += data.generalNote;
  }

  if (!isEmpty(formattedData?.trim())) {
    formattedData = isProvider
      ? `${textItalic({ text: `Provider reports: \n\n` })} ${formattedData}`
      : `${textItalic({ text: `Patient reports: \n\n` })} ${formattedData}`;

    return formattedData.trim();
  }

  return `No notable ${sectionName?.toLowerCase()}\n\n`;
};
