import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import numOrDefault from 'lib/helpers/numOrDefault';
import { iaRa } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popup from 'components/shared/Popup/Popup';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import Switch from 'components/shared/Switch/Switch';

export const Actions = ({ data }) => {
  const { id, status, amount_paid_self, patient_id, practice_id, surcharge_amount, isPatient } =
    data || {};
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { setForceRefresh } = useTableContext();

  const [showReceipt, setShowReceipt] = useState(false);
  const [refundTransactionModal, setRefundTransactionModal] = useState(false);
  const [includeSurcharge, setIncludeSurcharge] = useState(true);
  const [refundAmount, setRefundAmount] = useState(amount_paid_self);

  useEffect(() => {
    const parsedRefundAmount = numOrDefault(refundAmount);
    const parsedSurchargeAmount = numOrDefault(surcharge_amount);
    let amount = 1;

    if (includeSurcharge) {
      const withSurcharge = parsedRefundAmount + parsedSurchargeAmount;

      if (withSurcharge > amount_paid_self) amount = amount_paid_self;
      else amount = withSurcharge;
    } else {
      const withoutSurcharge = parsedRefundAmount - parsedSurchargeAmount;

      if (withoutSurcharge < 1) amount = 1;
      else amount = withoutSurcharge;
    }

    setRefundAmount(amount);
  }, [includeSurcharge, surcharge_amount]);

  const onRefund = async () => {
    try {
      const res = await requestApi({
        url: '/api/transactions/online/refund',
        params: {
          transactionId: id,
          amount: Number(refundAmount),
          includeSurcharge
        },
        navigate
      });

      const { code, error, transaction, patient_balance } = res;
      switch (code) {
        case 0:
          showAlert({ title: 'Successfully refunded transaction!', color: 'success' });
          queryClient.setQueriesData({ queryKey: ['transactions'], type: 'active' }, (oldData) => {
            try {
              const newTransactionsData = oldData.transactions.map((v) => {
                if (v.practice_id !== practice_id || v?.id !== id) {
                  return v;
                }
                const { status, amount_refunded, amount_paid_self } = transaction;
                return {
                  ...v,
                  status,
                  amount_refunded,
                  amount_paid_self
                };
              });
              const updatedStatusPanel = iaRa(oldData?.statusPanelData).map((data) => {
                if (data.field === 'amount_paid_self') {
                  return { ...data, value: data.value - (refundAmount || 0) };
                }
                return data;
              });

              return {
                ...oldData,
                statusPanelData: updatedStatusPanel,
                transactions: newTransactionsData
              };
            } catch (e) {
              console.error('Error updating transaction data:', e);
              return oldData;
            }
          });
          setForceRefresh((prev) => !prev);
          queryClient.setQueriesData(['patientBalance', patient_id], (oldData) => {
            try {
              return { ...oldData, patientBalance: patient_balance };
            } catch (e) {
              console.error(e);
            }
          });
          break;
        case 6:
          break;
        case 7:
          showAlert({ title: 'Successfully voided transaction!', color: 'success' });
          break;
        default:
          if (error) {
            showAlert({ title: error, color: 'danger' });
            Honeybadger.notify(`handleTransactionRefund: ${error}`);
          } else {
            Honeybadger.notify('handleTransactionRefund: unexpected error');
          }
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`handleTransactionRefund: unexpected error ${error}`);
    }
  };

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={180}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        {(close) => (
          <div className=" !py-[6px]">
            <div
              data-qa="view-receipt"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => {
                close();
                setShowReceipt(true);
              }}>
              <div className="ml-1 text-sm text-primary-900">View Receipt</div>
            </div>
            {['completed', 'partially_refunded'].includes(status?.toLowerCase()) && !isPatient && (
              <div
                data-qa="void-transaction"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => setRefundTransactionModal(true)}>
                <div className="ml-1 text-sm text-primary-900">Refund</div>
              </div>
            )}
          </div>
        )}
      </Popup>
      {showReceipt && (
        <ReceiptModal
          transactionId={id}
          isOpen={showReceipt}
          onClose={() => setShowReceipt(false)}
        />
      )}
      {!!refundTransactionModal && (
        <Confirm
          icon="new-info"
          iconSize={42}
          variant="warning"
          primaryBtnTxt="Refund"
          title="Refund Transaction"
          message="Are you sure you want to refund this Transaction?"
          handleContinue={() => {
            onRefund();
            setRefundTransactionModal(false);
          }}
          handleOpen={!!refundTransactionModal}
          handleClose={() => setRefundTransactionModal(false)}>
          <div className="mt-4">
            <CurrencyInput
              id="refund_amount"
              label="Refund Amount"
              value={refundAmount}
              className="!h-10 !rounded-md border border-neutral-100"
              onValueChange={(v) => setRefundAmount(v)}
              maxValue={
                includeSurcharge && surcharge_amount
                  ? amount_paid_self
                  : amount_paid_self - numOrDefault(surcharge_amount)
              }
              minValue={1}
            />

            {surcharge_amount ? (
              <div className="w-100 mt-[12px] flex items-center justify-between">
                <label className="mr-3">Refund Technology Fee?</label>
                <Switch
                  onChange={() => setIncludeSurcharge((prev) => !prev)}
                  checked={includeSurcharge}
                />
              </div>
            ) : null}
          </div>
        </Confirm>
      )}
    </div>
  );
};

export default Actions;
