import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useDynamicAr } from 'lib/hooks/queries/dynamicAR/useDynamicAr';

import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import ToggleSwitch from 'components/shared/ToggleSwitch/ToggleSwitch';

import { formatDataset, options } from './lib/graphHelpers';
import { DYNAMIC_AR_SWITCH_ITEMS } from './lib/helpers';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const DynamicARGraph = () => {
  const navigate = useNavigate();

  const { data, isLoading } = useDynamicAr();

  const patientAmounts = data?.patientAmounts || {};
  const payerAmounts = data?.payerAmounts || {};

  const datasets = {
    labels: ['0-30 days', '31-60 days', '61-90 days', '91-120 days', '121+ days', 'Total'],
    datasets: [
      formatDataset(patientAmounts, 'Patients', 'rgb(75, 192, 192)'),
      formatDataset(payerAmounts, 'Payers', 'rgb(53, 162, 235)')
    ]
  };

  return (
    <div className="flex flex-col flex-1 bg-white">
      <Header title="Dynamic AR" childrenClassName="flex !justify-start">
        <ToggleSwitch
          items={DYNAMIC_AR_SWITCH_ITEMS}
          onClick={(value) => navigate(value.navigate)}
          active="graph"
        />
      </Header>

      <div className="grid overflow-hidden justify-center flex-1 !p-4" data-dd-privacy="allow">
        {isLoading ? (
          <Icon icon="new-loader" className="animate-spin" size={42} />
        ) : (
          <Bar options={options} data={datasets} />
        )}
      </div>
    </div>
  );
};

export default DynamicARGraph;
