import { useEffect, useMemo, useRef } from 'react';

import cs from 'classnames';

import { useFileStack } from 'lib/hooks/useFileStack';
import useLoading from 'lib/hooks/useLoading';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

import { showAlert } from '../Alert/Alert';
import File from '../File/File';

import Placeholder from './components/Placeholder';
import { DEFAULT_FORMATS, DEFAULT_TITLE } from './lib/constants';
import { generateDescription, getAcceptAttribute, isFileAllowed } from './lib/helpers';

/**
 * @param {import('./lib/propTypes').FileUploaderProps} props
 */

const FileUploader = ({
  handlers = [],
  onUpload = (_file) => {},
  onDelete = (_file) => {},
  isMultiple = false,
  title = DEFAULT_TITLE,
  formats = DEFAULT_FORMATS,
  fileStackPicker = false,
  className = ''
}) => {
  const fileInputRef = useRef(null);
  const { files, addFile, readFile, deleteFile, retryUpload, openFilePicker } = useFileStack();

  const { loading, startLoading, stopLoading } = useLoading();

  const showUploader = (files.length === 0 && !isMultiple) || isMultiple;

  const handleOpenPicker = () => {
    if (fileStackPicker) {
      openFilePicker(onUpload);
      return;
    }

    fileInputRef.current.click();
  };

  const handleDelete = async (handle) => deleteFile(handle, onDelete);

  const handleRetry = async (name) => retryUpload(name, onUpload);

  const handleDownload = async () => {
    if (handlers && handlers.length > 0) {
      startLoading();
      await Promise.all(handlers.map(async (handle) => readFile(handle))).finally(() => {
        stopLoading();
      });
    }
  };

  const handleUpload = (event) => {
    if (event.target.files.length > 0) {
      Array.from(event.target.files).forEach((file) => addFile(file, onUpload));
    }
  };

  useEffect(() => {
    handleDownload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlers]);

  const description = useMemo(() => generateDescription(formats), []);
  const allowedFormats = useMemo(() => getAcceptAttribute(formats), []);

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (files && files.length > 0) {
      files.forEach((file) => {
        if (isFileAllowed(file, formats)) {
          addFile(file, onUpload);
        } else {
          showAlert({
            color: 'danger',
            message: 'Invalid file format. Please upload files in the following formats: ' + formats
          });
        }
      });
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  if (loading && handlers && handlers.length > 0)
    return (
      <div className="flex w-full flex-col items-center gap-4 p-0">
        {handlers.map((handler) => (
          <Placeholder key={handler} />
        ))}
      </div>
    );

  return (
    <div className={cs('flex w-full flex-col items-center gap-4 p-0', className)}>
      {showUploader && !loading && (
        <>
          <div
            className={cs(
              'flex h-[200px] w-full cursor-pointer flex-col items-center gap-5 rounded-lg border border-dashed border-gray-400 p-8',
              'hover:border-primary-500 hover:bg-primary-25',
              'disabled:cursor-not-allowed disabled:opacity-50'
            )}
            onClick={handleOpenPicker}
            onDrop={handleDrop}
            onDragOver={handleDragOver}>
            <Icon icon="cloud-upload" />
            <div className="flex flex-col items-center justify-center gap-1.5 p-0">
              <span className="text-sm font-medium text-gray-900">{title}</span>
              <span className="text-xs font-normal text-gray-600">{description}</span>
            </div>
            <Button outlined icon="new-export-bulk" text="Upload" color="neutral" />
          </div>
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            multiple={isMultiple}
            onChange={handleUpload}
            accept={allowedFormats}
          />
        </>
      )}
      {files.map((file) => (
        <File
          key={file.fileId}
          file={file}
          onDelete={handleDelete}
          status={file.status}
          onRetry={handleRetry}
        />
      ))}
    </div>
  );
};

export default FileUploader;
