export default function getPlaceOfService(superbill, practice) {
  const aptLocation = superbill?.encounter?.appointment?.location;
  const practiceDefaultPOS = practice?.display_settings?.billing?.superbill?.default_pos;
  const place_of_service = practiceDefaultPOS
    ? practiceDefaultPOS
    : aptLocation === 'virtual'
      ? '02'
      : '11';

  return place_of_service;
}
