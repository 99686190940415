import React from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { ia, iaRa } from 'lib/helpers/utility';

import { clinicalNote as clinicalNoteState } from 'components/state';

const CPTCodes = ({ customClinicalNote = null, customStyling = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const cptCodes = clinicalNote?.cpt_codes;

  if (customClinicalNote && !ia(cptCodes)) {
    return null;
  }

  return (
    <div className={cs('bg-white px-6 py-4', customStyling)}>
      <h3 className="!pb-4 text-base font-500 text-[#003A4B] print:text-xl">CPT® codes</h3>
      <div className="grid !gap-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 first-of-type:!border-t-0">
        {iaRa(cptCodes).map((code, index) => (
          <div key={index} className="grid gap-1">
            <p className="text-sm text-neutral-700 first-letter:capitalize">
              {`${index + 1}. CPT® code:`}
            </p>
            <p className="text-sm font-500 text-[#003A4B] first-letter:capitalize">
              {code?.procedure_code || code?.cpt || code?.code?.label || 'N/A'}
            </p>
            {code?.ndc_code && (
              <div className="grid gap-1 !pl-6 pt-[2px]">
                <p className="text-base text-neutral-700 first-letter:capitalize">NDC Code:</p>
                <ul className="pl-3">
                  <li className="text-sm font-500 text-[#003A4B] first-letter:capitalize">
                    {code?.ndc_code}
                  </li>
                </ul>
              </div>
            )}
            {ia(code?.modifiers) && (
              <div className="grid gap-1 !pl-6 pt-[2px]">
                <p className="text-base text-neutral-700 first-letter:capitalize">Modifiers:</p>
                <ul className="pl-3">
                  {code?.modifiers?.map((modifier, modifierIndex) => (
                    <li
                      key={modifierIndex}
                      className="text-sm font-500 text-[#003A4B] first-letter:capitalize">
                      {modifier?.label}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CPTCodes;
