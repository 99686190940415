import React from 'react';
import { formatDateAndTime, pm } from 'lib/helpers/utility';
import Actions from '../components/Actions';
import { EditRoleContextProvider } from 'lib/context/Role/EditRoleContext/EditRoleContextProvider';

export const defaultColumns = (permissions, practiceTimezone, isAdmin, forAdmin) => [
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'numberOfPermissions',
    headerName: 'Permissions',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value,
    flex: 1
  },
  {
    field: 'usersAssigned',
    headerName: 'Users Assigned',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => value,
    flex: 1
  },
  {
    field: 'description',
    headerName: 'Description',
    cellClass: 'no-border',
    flex: 2
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => formatDateAndTime(value, practiceTimezone),
    flex: 1
  },
  ...(pm(
    permissions,
    forAdmin ? ['admin_role.update', 'admin_role.delete'] : ['role.update', 'role.delete'],
    'or'
  )
    ? [
        {
          field: 'actions',
          headerName: 'Actions',
          cellClass: 'no-border',
          cellRenderer: (params) => {
            return (
              <EditRoleContextProvider forAdmin={forAdmin}>
                <Actions data={params?.data} isAdmin={isAdmin} forAdmin={forAdmin} />
              </EditRoleContextProvider>
            );
          },
          maxWidth: 100,
          minWidth: 36,
          resizable: false,
          sortable: false,
          pinned: 'right'
        }
      ]
    : [])
];
