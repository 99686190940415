import React, { useState, useRef, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../practice/practiceState';
import ActionButton from '../shared/Button/ActionButton/ActionButton';
import cs from 'classnames';
import Skeleton from '../shared/Skeleton/Skeleton';
import useOutsideClick from '../../lib/hooks/useOutsideClick';
import NewNotification from './components/NewNotification';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getNotifications, updateNotifications } from '../../api/Notifications';
import { ia, isEmpty } from '../../lib/helpers/utility';
import { socket } from '../../api/Socket';
import { showAlert } from '../shared/Alert/Alert';

const NewNotifications = ({ className, unreadNotificationsCount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const practice = useRecoilValue(currentPractice);
  const scrollableDivRef = useRef(null);
  const popoverRef = useRef(null);
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  useEffect(() => {
    socket.on('new_fax', () => {
      showAlert({
        color: 'success',
        message: 'New fax received.',
        buttons: [
          {
            text: 'View Fax',
            onClick: () => navigate('/portal/comms-center/fax/inbound'),
            color: 'success'
          }
        ]
      });
    });

    socket.on('new_notification', (data) => {
      queryClient.invalidateQueries(['unreadNotificationsCount']);
      queryClient.invalidateQueries(['notifications']);
    });

    return () => {
      socket.off('new_notification');
      socket.off('new_fax');
    };
  }, []);

  useOutsideClick(popoverRef, () => setIsOpen(false));

  let notificationParams = {
    userPracticeNotifs: true,
    withal: { notifications: true, receiver: true, sender: true },
    limit: 5,
    offset: 0
  };

  const { data, fetchNextPage, isFetching, isLoading } = useInfiniteQuery(
    ['notifications'],
    ({ pageParam = { navigate, notificationParams } }) =>
      getNotifications(pageParam.navigate, pageParam.notificationParams),
    {
      onSuccess: (data) => {
        const dataCheck = ia(data.pages[data.pages.length - 1]?.data?.notifications);
        setHasMore(dataCheck);
        setScrollLoading(false);
      },
      enabled: isOpen
    }
  );

  const allNotifs = ia(data?.pages)
    ? data?.pages
        ?.map((entry) => entry.data.notifications)
        .filter((notifs) => ia(notifs))
        .flat()
    : [];

  const handleScroll = () => {
    const div = scrollableDivRef.current;
    if (div) {
      if (div.scrollTop + div.clientHeight >= div.scrollHeight - 5 && !isFetching) {
        if (hasMore) fetchNextPageWithCustomOffset(allNotifs?.length);

        setScrollLoading(true);
      }
    }
  };

  const fetchNextPageWithCustomOffset = (offset) => {
    fetchNextPage({
      pageParam: {
        navigate,
        notificationParams: {
          ...notificationParams,
          offset
        }
      }
    });
  };

  const updateNotificationMutation = useMutation((data) => updateNotifications(navigate, data), {
    onSuccess: (data, variables) => {
      const { readAllNotifications = false } = variables;

      queryClient.invalidateQueries(['unreadNotificationsCount']);
      readAllNotifications && queryClient.invalidateQueries(['notifications']);
    }
  });

  const readAllNotifications = async () =>
    await updateNotificationMutation.mutateAsync({
      readAllNotifications: true
    });

  const onNavigate = async ({
    link,
    linkState = {},
    notificationId,
    closePopover = () => null
  }) => {
    if (notificationId) {
      await updateNotificationMutation.mutateAsync({
        notificationId,
        dataToBeUpdated: { is_read: true }
      });
    }

    setIsOpen(false);

    if (isEmpty(link)) {
      showAlert({
        color: 'warning',
        message: 'This notification does not contain a link!'
      });

      return;
    }

    closePopover();

    navigate(link, {
      state: linkState
    });
  };

  const toggleIsOpen = () => setIsOpen((prevState) => !prevState);

  return (
    <div ref={popoverRef} className={cs('flex items-center', className)}>
      <Popover className="relative !ml-2">
        {({ open, close }) => (
          <>
            <Popover.Button className="relative p-0" onClick={toggleIsOpen}>
              <div className="relative">
                <ActionButton icon="new-notifications" active={open} />
                {unreadNotificationsCount > 0 && (
                  <div className="absolute right-[6px] top-[5px] flex h-[6px] min-w-[6px] items-center justify-center rounded-full bg-danger-400 p-[2px]" />
                )}
              </div>
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-[10px] w-80 !rounded-lg bg-white !p-2 shadow-medium">
              <div className="flex items-start justify-between gap-1 !p-1">
                <span className="flex items-center !gap-x-2">
                  <em className="text-sm font-500 not-italic text-primary-900">Notifications</em>
                  {unreadNotificationsCount > 0 && (
                    <div className="flex h-[20px] min-w-[20px] items-center justify-center rounded-full bg-primary-500 p-[2px]">
                      <span className="text-[10px] font-500 text-white">
                        {unreadNotificationsCount}
                      </span>
                    </div>
                  )}
                </span>
                <span onClick={() => readAllNotifications()}>
                  <em className="cursor-pointer text-sm font-400 not-italic text-neutral-500">
                    Mark as read
                  </em>
                </span>
              </div>
              <hr className="m-1" />
              <div
                ref={scrollableDivRef}
                onScroll={handleScroll}
                className="!m-0 h-64 overflow-auto !p-0">
                {isLoading ? (
                  <Skeleton count={5} height={50} width="100%" />
                ) : ia(allNotifs) ? (
                  <NewNotification
                    onNavigate={onNavigate}
                    practice={practice}
                    hasMore={hasMore}
                    scrollLoading={scrollLoading}
                    newNotifications={allNotifs}
                    close={close}
                  />
                ) : (
                  <div className="!my-4 text-center text-lg leading-5 text-neutral-600">
                    No notifications found.
                  </div>
                )}
              </div>
              <div className="text-center">
                <span onClick={() => navigate('/portal/practice-i-queue/notifications')}>
                  <em className="cursor-pointer text-sm font-400 not-italic text-primary-900 hover:text-primary-500">
                    See all notifications
                  </em>
                </span>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default NewNotifications;
