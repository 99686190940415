import React, { useEffect, useMemo, useState } from 'react';

import { useRecoilState } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useAdminUsersList } from 'lib/hooks/queries/admin/useAdminList';
import { useRoleList } from 'lib/hooks/queries/roles/useRoleList';
import usePageTitle from 'lib/hooks/usePageTitle';

import AddEditAdminModal from 'components/admin/components/AddEditAdminModal';
import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import Status from 'components/shared/Status/Status';
import state from 'components/state';

import AdminActions from './components/AdminActions';
import BulkAdminActions from './components/BulkAdminActions';
import { adminsStatuses, defaultColumns, defaultFilters } from './lib/helper';

const isFirstColumn = (params) => {
  var displayedColumns = params.api.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

export default function AdminUsersTable() {
  const [editAdminData, setAdminData] = useState(undefined);

  const handleEditAdmin = (data) => {
    setAdminData({
      ...data,
      role: {
        label: data.role,
        value: data.role_id
      }
    });
  };

  return (
    <TableContextProvider
      cols={[
        ...defaultColumns,
        {
          field: 'status',
          headerName: 'Status',
          resizable: false,
          cellRenderer: (params) => (
            <td className="flex h-full w-full items-center">
              <Status
                status={params.data.status}
                className="h-fit"
                textClassName="block"
                color="primary"
              />
            </td>
          )
        },
        {
          field: 'actions',
          headerName: '',
          maxWidth: 100,
          minWidth: 100,
          resizable: false,
          cellRenderer: (params) => (
            <AdminActions
              paramsData={params.data}
              onHandleEdit={(data) => {
                handleEditAdmin(data);
              }}
            />
          )
        }
      ]}
      defaultFilters={defaultFilters}
      pagination
      name="admin_users">
      <Table editAdminData={editAdminData} setAdminData={setAdminData} />
    </TableContextProvider>
  );
}

function Table({ editAdminData, setAdminData }) {
  const [permissions] = useRecoilState(state.permissions);
  const [isModalOpen, setIsModalOpen] = useState(false);
  usePageTitle('Admins');
  const category = 'admin_users';
  const { limit, page, setPage, sort, setTableLoading, selectedRows, filters, setFilters } =
    useTableContext();

  const {
    data = {},
    isLoading,
    isFetching
  } = useAdminUsersList({
    params: {
      searchTerm: filters?.searchTerm?.values ?? '',
      userStatus: filters?.status?.values ?? '',
      page,
      sort,
      limit
    },
    dependencies: [mapValues(filters), page, sort, limit]
    // sort
  });
  const admins = data.users;

  const count = data?.total?.[0]?.total || 0;
  const pendingCount = data?.pending?.[0]?.total || 0;

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const onCellClicked = (e) => {
    if ((permissions && !permissions?.admin_users?.update) || e?.column?.colId === 'actions') {
      return;
    }
    setAdminData({
      ...e.data,
      role: {
        label: e.data.role,
        value: e.data.role_id
      }
    });
  };

  const closeAdminForm = () => {
    setAdminData(undefined);
    setIsModalOpen(false);
  };

  const { data: rolesData } = useRoleList({
    params: {
      limit: 30,
      offset: 0,
      forAdmin: true
    }
  });

  const rolesOptions = useMemo(
    () =>
      rolesData?.role_list.map((role) => ({
        value: role.id,
        label: role.name
      })),
    [rolesData]
  );

  useEffect(() => {
    if (!!rolesOptions) {
      setFilters({
        ...filters,
        role: {
          type: 'search',
          multiple: true,
          values: '',
          options: rolesOptions,
          title: 'Role',
          loadOptions: () => rolesOptions
        }
      });
    }
  }, [rolesOptions]);

  const onBadgeClick = () => {
    setFilters({
      ...filters,
      status: {
        ...filters.status,
        values: [adminsStatuses?.[0].value]
      }
    });
  };

  return (
    <Allowed requiredPermissions="admin_users.read" showIllustration showMessage>
      <Header
        onBadgeClick={onBadgeClick}
        title="Admin Users"
        {...(pendingCount && pendingCount > 0 && { subtitle: `Requests (${pendingCount})` })}>
        <div className="flex items-center gap-2" data-dd-privacy="allow">
          <BulkAdminActions selectedRows={selectedRows} />
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Allowed requiredPermissions="admin_users.create">
            <Button
              text="New Admin"
              icon="user-add"
              size="small"
              onClick={() => setIsModalOpen(true)}
              type="primary"
              data-qa="new-patient-btn"
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex h-full  flex-col overflow-hidden" data-dd-privacy="allow">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={admins}
            defaultColDef={defaultColDef}
            rowSelection="multiple"
            customClassName="ag-grid-interactive"
            suppressRowClickSelection={true}
            loading={isLoading || isFetching}
            onCellClicked={onCellClicked}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          <CustomStatusBarCount count={count} page={page} limit={limit} data={admins} />
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count}
            perPage={limit}
            page={page}
          />
        </div>
      </div>
      <AddEditAdminModal
        adminData={editAdminData}
        isModalOpen={!!editAdminData || isModalOpen}
        handleClose={closeAdminForm}
      />
    </Allowed>
  );
}
