import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPatient } from 'api/Patients';

export const usePatient = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['patient', ...dependencies], () => getPatient(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
