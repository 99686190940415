import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { createPayer, updatePayer } from 'api/Payer';

import { iaRa, optionify } from 'lib/helpers/utility';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import { useStates } from 'lib/hooks/queries/states/useStates';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';
import { userState } from 'components/state';

import { PayerInitialValues, PayerValidationSchema } from '../lib/helper';

import PayerForm from './PayerForm';

const PayerModal = forwardRef(({ payer, isOpen, handleClose, selectInputRef }) => {
  const navigate = useNavigate();
  const user = useRecoilValue(userState);

  const { data: statesData } = useStates();
  const { data: addressData } = usePayerAddress({
    params: { payerId: payer?.id, withCount: true },
    dependencies: [payer?.id]
  });

  const isRequested = !payer;
  const states = optionify(iaRa(statesData?.states), 'name', 'id');

  const formik = useFormik({
    initialValues: PayerInitialValues(
      { ...payer, ...(isRequested && { name: selectInputRef?.current?.value }) },
      addressData
    ),
    validationSchema: PayerValidationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      const newPayer = {
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        name: values.name,
        phone: values.phone,
        type: values.type,
        virtual_modifiers: values.virtual_modifiers,
        kind: values.kind
      };

      const payerHandlerParams = payer
        ? { payer: newPayer, payerId: payer.id }
        : { payer: newPayer, userId: user?.user_id };

      payerHandler(payerHandlerParams);
    }
  });

  const { mutateAsync: payerHandler, isLoading } = useCustomMutation({
    mutationFn: (params) => (payer ? updatePayer(navigate, params) : createPayer(navigate, params)),
    successMessage: `Payer ${payer ? 'updated' : 'requested'} successfully!`,
    errorMessage: "You're not authorized to create payer!",
    invalidateQueryKey: ['getPayers', 'payers', 'payerAddress'],
    onSuccess: () => {
      formik.resetForm();
      handleClose();
    }
  });

  return (
    <Modal
      slideFromRight
      key={`payer-modal-${isOpen ? 'opened' : 'closed'}`}
      handleOpen={isOpen}
      className="w-[700px]"
      handleClose={() => handleClose()}
      bodyClassName="!p-4 !max-h-[unset] !h-full"
      title={isRequested ? 'Request New Payer' : 'Update Payer'}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={handleClose}
            id="cancelPayerModalBtn"
            data-qa="cancel-payer-btn"
          />
          <Button
            onClick={formik.handleSubmit}
            id="savePayerModalBtn"
            data-qa="create-payer-btn"
            text={isRequested ? 'Request payer' : 'Update'}
            loading={isLoading}
          />
        </div>
      }>
      <PayerForm states={states} formik={formik} isRequested={isRequested} />
    </Modal>
  );
});

export default withErrorBoundary(PayerModal);
