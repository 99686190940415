export const transitionTypes = {
  fade: {
    enter: 'transition-opacity duration-200 ease-out',
    enterFrom: 'opacity-0',
    enterTo: 'opacity-100',
    leave: 'transition-opacity duration-100 ease-out',
    leaveFrom: 'opacity-100',
    leaveTo: 'opacity-0'
  },
  scale: {
    enter: 'transition-all duration-200 ease-out',
    enterFrom: 'transform scale-0 opacity-0',
    enterTo: 'transform scale-100 opacity-100',
    leave: 'transition-all duration-100 ease-out',
    leaveFrom: 'transform scale-100 opacity-100',
    leaveTo: 'transform scale-0 opacity-0'
  },
  slideDown: {
    enter: 'transition-all duration-200 ease-out',
    enterFrom: 'transform -translate-y-2 opacity-0',
    enterTo: 'transform translate-y-0 opacity-100',
    leave: 'transition-all duration-100 ease-out',
    leaveFrom: 'transform translate-y-0 opacity-100',
    leaveTo: 'transform -translate-y-2 opacity-0'
  }
};
