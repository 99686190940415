import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import { useRecoilValue } from 'recoil';

import { updateAppointmentV2 } from 'api/Appointment';
import { socket } from 'api/Socket';

import { deleteKeys, getDirtyValues } from 'lib/helpers/utility';
import { useAppointmentv2 } from 'lib/hooks/queries/appointment/useAppointmentv2';

import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import state from 'components/state';

import AppointmentContent from './AppointmentContent';
import { UpdateAppointmentValidationSchema } from './lib/validationSchemas';

const Appointment = ({ isOpen, handleClose, appointment: propsAppointment }) => {
  const [formValues, setFormValues] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const currentUser = useRecoilValue(state.userState);

  const { data: appointmentData, isLoading } = useAppointmentv2({
    params: {
      id: propsAppointment.id,
      withal: {
        medicalHistory: true,
        payments: true,
        patient: true,
        practitioner: true,
        allergies: true,
        practitionerPractice: true,
        tag: true,
        resources: true
      }
    }
  });
  const appointment = appointmentData?.appointment;

  useEffect(() => {
    if (appointment) {
      setFormValues(appointment);
    }
  }, [appointment]);

  useEffect(() => {
    socket.on('appointment-rescheduled', (data) => {
      if (data.user.id === currentUser?.user_id || data.appointment_id !== propsAppointment.id)
        return;

      showAlert({
        title: 'This Appointment was Rescheduled',
        message: `This appointment rescheduled by ${data.user?.fullName}, no changes can be made!`,
        color: 'warning',
        buttons: [
          {
            text: 'View Rescheduled Appointment',
            onClick: () => {
              handleClose();
              setTimeout(() => navigate(`/portal/appointments/${data.newAppointmentId}`), 1000);
            },
            color: 'warning'
          }
        ]
      });
    });
  }, []);

  const { mutateAsync: mutateAppointment } = useMutation({
    mutationFn: (data) => {
      const dirtyValues = getDirtyValues(data, appointment);
      // delete data to avoid submitting in backend
      deleteKeys({
        object: dirtyValues,
        keys: [
          'tags',
          'calculate',
          'timeline',
          'timeline_bar',
          'isPaymentActive',
          'arrived_data',
          'statusPercentage',
          'patient_balance'
        ]
      });

      socket.emit('check-rooms', { appointmentId: data.id });

      return updateAppointmentV2(
        navigate,
        {
          ...dirtyValues,
          id: data.id
        },
        data
      );
    },
    onSuccess: async (data) => {
      const { code, updatedAppointment, error, rescheduledAppointmentId } = data;

      if (rescheduledAppointmentId) {
        showAlert({
          title: 'Appointment Rescheduled',
          color: 'warning',
          message: 'This appointment was rescheduled by another user, no changes can be made!',
          buttons: [
            {
              text: 'View Rescheduled Appointment',
              onClick: () => {
                handleClose();
                setTimeout(
                  () => navigate(`/portal/appointments/${rescheduledAppointmentId}`),
                  1000
                );
              },
              color: 'warning'
            }
          ]
        });
        return;
      }

      if (code === 0) {
        if (appointment?.id === updatedAppointment?.id) {
          queryClient.setQueryData(['appointmentv2', updatedAppointment?.id], (oldData) => {
            return {
              ...oldData,
              appointment: updatedAppointment
            };
          });
          // Appointment multiple
          queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
            ...oldData,
            appointment: oldData.appointment.map((apt) =>
              apt.id === updatedAppointment.id ? updatedAppointment : apt
            )
          }));
          if (updatedAppointment.location) {
            queryClient.invalidateQueries({ queryKey: ['claim'], refetchType: 'all' });
          }
          showAlert({ color: 'success', message: 'Appointment updated successfully!' });
        } else {
          queryClient.invalidateQueries(['appointment-events']);
          // When we rescheduling an appointment
          handleClose();
          showAlert({
            message: 'This appointment rescheduled successfully!',
            type: 'success',
            buttons: [
              {
                text: 'View',
                onClick: () => navigate(`/portal/appointments/${updatedAppointment?.id}`)
              }
            ]
          });
        }
        await queryClient.invalidateQueries(['apt-timeline-details']);
      } else if (code === 4) {
        error?.map((errorMessage) => showAlert({ message: errorMessage, color: 'danger' }));
      }
    },
    onError: (error) => Honeybadger.notify(`Appointment update error: ${error}`)
  });

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={(values) => mutateAppointment(values)}
      validationSchema={UpdateAppointmentValidationSchema}>
      {(formikProps) => (
        <AppointmentContent
          {...{
            appointment,
            handleClose,
            isOpen,
            setFormValues,
            isLoading: isLoading || !formValues || !formikProps.values
          }}
        />
      )}
    </Formik>
  );
};
export default withErrorBoundary(Appointment);
