import React from 'react'
import cs from 'classnames';
import { iaRa, isEmpty } from '../../../../../../lib/helpers/utility';
import { sanitize } from '../../../../../../lib/helpers/sanitize';

export const beautifyAppendedString = (appendedString) => {
  if (isEmpty(appendedString)) return null;

  const lines = appendedString?.split('\n\n');

  return iaRa(lines).map((paragraph, index) => (
    <div key={index}>
      {paragraph.split('\n').map((line, innerIndex) => (
        <div
          key={innerIndex}
          className={cs(
            'text-neutral-800',
            line.includes(':') || line.includes('?')
              ? 'font-400 text-sm'
              : 'font-500 text-base break-words'
          )} dangerouslySetInnerHTML={sanitize(line)}>
        </div>
      ))}
      {index < lines.length - 1 && <hr className="!my-2 border-dashed border-neutral-200" />}
    </div>
  ));
};