import React, { useMemo, useRef, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { documentCategories } from 'constants';
import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useDocumentsCategories } from 'lib/hooks/queries/useDocuments';

import { currentPractice } from 'components/practice/practiceState';

import { userState } from '../../../state';

import InstantPacket from './InstantPacket/InstantPacket';
import List from './List';
import View from './View';
import getDocumentFilters from './lib/defaultFilters';
import documentTableCols from './lib/documentTableCols';

export default function Documents({ fromPatient, appointmentId }) {
  const currentUser = useRecoilValue(userState);

  const { myPortalPractice } = useParams();

  const { patient } = useOutletContext() || {};
  const practice = useRecoilValue(currentPractice);

  const patientId = patient?.id || currentUser?.id;

  const exportRef = useRef();

  const print = useReactToPrint({
    content: () => exportRef.current,
    documentTitle: `hipaa-${patient ? patient?.f_name + ' ' + patient?.l_name : 'Patient'}.pdf`
  });

  const [viewDocument, setViewDocument] = useState(null);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showInstantPacket, setShowInstantPacket] = useState(false);

  const handleCloseModal = () => {
    setViewDocument(null);
    setIsEditOpen(false);
  };

  const { data: practitionersData } = usePractitioners(
    myPortalPractice ? { practice_id: myPortalPractice } : null,
    {},
    [myPortalPractice]
  );

  const practitioners = fromPatient
    ? [
        {
          f_name: 'Staff',
          l_name: 'Members',
          id: practitionersData?.practitioners?.map((p) => p.id)
        }
      ]
    : practitionersData?.practitioners || [];

  const { data: categoriesData } = useDocumentsCategories({
    params: {
      patientId,
      staticCategories: documentCategories,
      ...(myPortalPractice && { myPortalPractice })
    },
    dependencies: [myPortalPractice, patientId]
  });
  const categories = useMemo(() => categoriesData?.categories || [], [categoriesData]);

  return (
    <div className="flex h-full flex-col">
      <TableContextProvider
        defaultFilters={getDocumentFilters(practitioners, patient, categories)}
        cols={documentTableCols({
          setViewDocument,
          isEditOpen,
          setIsEditOpen,
          fromPatient,
          patientId,
          practice,
          categories,
          appointmentId
        })}
        dependencies={[categories]}
        name="patient_documents"
        pagination>
        <List
          patientId={patientId}
          patient={
            patient || {
              id: currentUser.user_id,
              fullName: currentUser.f_name + ' ' + currentUser.l_name,
              ...currentUser
            }
          }
          appointmentId={appointmentId}
          fromPatient={fromPatient}
          setViewDocument={setViewDocument}
          practitioners={practitioners}
          categories={categories}
          setShowInstantPacket={setShowInstantPacket}
        />

        {viewDocument && (
          <View
            exportRef={exportRef}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
            handleCloseModal={handleCloseModal}
            action={viewDocument.action}
            patientId={patientId}
            handle={viewDocument.handle}
            type={viewDocument.type}
            print={print}
            categories={categories}
            fromPatient={fromPatient}
            appointmentId={appointmentId}
          />
        )}
      </TableContextProvider>
      {showInstantPacket && <InstantPacket setShowInstantPacket={setShowInstantPacket} />}
    </div>
  );
}
