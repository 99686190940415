import { useLocation, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateTreatmentPlan } from 'api/TreatmentPlan';

import { showAlert } from 'components/shared/Alert/Alert';

const useUpdateTP = ({ navigate, selectedTreatmentPlan, outsideCN = false }) => {
  const queryClient = useQueryClient();
  const { id } = useParams() || {};
  const params = { patientId: id, withRelations: { createdBy: true } };
  const location = useLocation();
  const fromPracticeIQueue = location?.pathname?.includes('practice-i-queue/treatment-plans');

  const mutation = useMutation(
    (data) =>
      updateTreatmentPlan(navigate, {
        treatmentId: selectedTreatmentPlan.id,
        objToBeUpdated: data
      }),
    {
      onSuccess: ({ code }) => {
        if (code === 0) {
          showAlert({
            title: 'Treatment plan',
            message: 'Treatment plan updated successfully!',
            color: 'success'
          });
          if (outsideCN && !fromPracticeIQueue) {
            queryClient.invalidateQueries(['treatmentPlan', [params]]);
          } else {
            queryClient.invalidateQueries('treatmentPlan');
          }
        }
      }
    }
  );

  return mutation;
};

export default useUpdateTP;
