import { Popover } from '@headlessui/react';
import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Allowed from 'components/shared/Permissions/Allowed';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { pm } from 'lib/helpers/utility';

const ModalTitle = ({
  appointment,
  oldAppointment,
  setOldAppointment,
  setRefetchAppointments,
  status
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const permissions = useRecoilValue(state.permissions);

  const confirmAppointment = async (confirmed) => {
    setLoading(true);
    const params = {
      id: appointment.id,
      confirmed
    };
    const onSuccess = (data) => {
      if (data.affectedRows) {
        setRefetchAppointments();
        setOldAppointment((oldState) => ({ ...oldState, confirmed }));
        showAlert({
          message: `Appointment was ${confirmed ? 'confirmed' : 'unconfirmed'}`,
          color: 'success'
        });
      } else {
        Honeybadger.notify(`Appointment confirmation ID: ${appointment?.id}`);
      }
    };

    const onError = (error, code) => {
      if (code === 5) {
        setRefetchAppointments();
        setOldAppointment((oldState) => ({ ...oldState, confirmed }));
        showAlert({
          message: `Appointment was ${confirmed ? 'confirmed' : 'unconfirmed'}`,
          color: 'success'
        });
      }
    };

    await requestApi({ url: '/api/appointment/confirm', onSuccess, navigate, params, onError });
    setLoading(false);
  };

  return (
    <div data-public data-dd-privacy="allow" className="flex w-full items-center !gap-3">
      <h3 className="text-base font-500 text-neutral-800">Appointment #{appointment.id}</h3>
      {status !== 'cancelled' ? (
        <>
          <Popover className="relative ">
            {({ close, open }) => (
              <>
                <Popover.Button className="w-full p-0">
                  <Button
                    data-qa="confirmed-unconfirmed-btn"
                    disabled={!pm(permissions, 'appointments.update')}
                    outlined
                    type="secondary"
                    size="small"
                    className={` ${oldAppointment.confirmed ? '!bg-success-50' : '!bg-danger-50'}`}
                    color={oldAppointment.confirmed ? 'success' : 'danger'}
                    text={oldAppointment.confirmed ? 'confirmed' : 'unconfirmed'}
                    iconRight={open ? 'chevron-up' : 'chevron-down'}
                    loading={loading}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute z-10 mt-[8px] bg-white shadow-md">
                  <div className="w-[145px] py-4" data-dd-privacy="allow">
                    <div
                      data-dd-privacy="allow"
                      className={`!mb-2 flex h-8 cursor-pointer items-center !px-4 py-[6px] ${
                        !oldAppointment.confirmed && '!bg-danger-50'
                      }`}
                      onClick={async () => {
                        if (oldAppointment.confirmed)
                          await confirmAppointment(!oldAppointment.confirmed);
                        close();
                      }}>
                      <Icon icon="new-close-circle" />
                      <p
                        className="!ml-2 text-sm font-400 text-danger-600"
                        data-qa="unconfirmed-click">
                        Unconfirmed
                      </p>
                    </div>
                    <div
                      data-dd-privacy="allow"
                      className={`flex h-8 cursor-pointer items-center !px-4 py-[6px] ${
                        oldAppointment.confirmed && '!bg-success-50'
                      }`}
                      onClick={() => {
                        !oldAppointment.confirmed && confirmAppointment(!oldAppointment.confirmed);
                        close();
                      }}>
                      <Icon icon="tick-circle" />
                      <p
                        className="!ml-2 text-sm font-400 text-success-600"
                        data-qa="confirmed-click">
                        Confirmed
                      </p>
                    </div>
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>
          <Allowed requiredPermissions={['clinical_note.read', 'patients.read']}>
            <Button
              data-qa="start-visit-btn"
              outlined
              type="secondary"
              size="small"
              className=" !bg-primary-50"
              text="Start visit"
              onClick={() => {
                navigate(`/portal/charts/${appointment.user_id}/clinical-notes/${appointment.id}`);
              }}
            />
          </Allowed>
        </>
      ) : null}
    </div>
  );
};

export default ModalTitle;
