import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getServiceCategories } from '../../../../api/ServiceCategory';

export const useServiceCategories = ({ options, params } = {}) => {
  const navigate = useNavigate();

  return useQuery(
    ['serviceCategories', params?.practiceId],
    () => getServiceCategories(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
