import { Tab } from '@headlessui/react';
import cs from 'classnames';
import React from 'react';

const SearchTabs = ({ data, activeTab, setActiveTab, onTabChange, count }) => {
  return (
    <Tab.Group>
      <div>
        <div className="tabs sticky top-0 z-10 flex w-full justify-between bg-white p-0">
          {Object.keys(data).map((tab) => (
            <button
              data-qa={`${tab}`}
              key={tab}
              className={`!mt-1 flex flex-grow items-center justify-center border-x-0 border-b-2 border-solid border-transparent pb-2 text-sm leading-5 ${
                activeTab === tab
                  ? '!border-neutral-900 font-600 text-neutral-900'
                  : 'font-500 text-neutral-500'
              }`}
              onClick={() => {
                setActiveTab(tab);
                onTabChange(tab);
              }}
              style={{ borderBottom: '2px solid #d1d5db' }}>
              <span className="cursor-pointer">{tab}</span>

              <label
                className={cs(
                  '!ml-1 mb-0 grid !h-5 !w-5 place-content-center rounded-md text-xxs',
                  activeTab === tab
                    ? 'bg-primary-50 text-primary-500'
                    : 'bg-neutral-100 text-neutral-700'
                )}>
                {count?.[tab] > 99 ? '99+' : count?.[tab]}
              </label>
            </button>
          ))}
        </div>
      </div>
    </Tab.Group>
  );
};

export default SearchTabs;
