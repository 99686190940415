import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from 'api/Api';
import { interimApi } from 'api/InterimApi';
import { iaRa, optionify } from 'lib/helpers/utility';
import { permissions } from '../../../state';
import moment from 'moment-timezone';
import toast from 'react-hot-toast';
import Box from 'components/shared/Box/Box';
import Switch from 'components/shared/Switch/Switch';
import Select from 'components/shared/Select/Select';
import Loading from 'components/shared/Loading/Loading';
import { showAlert } from 'components/shared/Alert/Alert';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import AppointmentLocation from './AppointmentLocation';
import AppointmentTypes from './AppointmentTypes';
import ServiceComponent from './ServiceComponent';
import ViewsSettings from './ViewsSettings/ViewsSettings';
import PractitionerComponent from './PractitionerComponent';
import AvailableTimeComponent from './AvailableTime/AvailableTimeComponent';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Button from 'components/shared/Buttons/Button';

const CalendarSettings = () => {
  const [show, setShow] = useState({ loading: false, show_more: true });
  const [practice, setPractice] = useState();
  const [emailBatch, setEmailBatch] = useState(null);
  const [practitioners, setPractitioners] = useState([]);
  const timeZoneNames = moment.tz.names();
  const userPermissions = useRecoilValue(permissions);
  const navigate = useNavigate();

  useEffect(() => {
    getPractice();
    getPractitioners();
  }, []);

  const { data } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  const getPractice = async () => {
    setShow({ ...show, loading: true });
    try {
      const res = await interimApi('/api/practice/current_practice', {}, navigate);
      const { redirect, practice: loadedPractice } = res.data;
      if (loadedPractice) {
        setPractice(loadedPractice);
        setEmailBatch(loadedPractice.batch_email);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
    }
    setShow({ ...show, loading: false });
  };

  const getPractitioners = async () => {
    try {
      const res = await interimApi('/api/practice/member/get_practitioners', {}, navigate);
      const { redirect, practitioners: loadedPractitioners } = res.data;
      if (res.data.practitioners) {
        setPractitioners(loadedPractitioners);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  const updateTimezone = async (value) => {
    try {
      const res = await interimApi(
        '/api/practice/update',
        { fields: { timezone: value.value } },
        navigate
      );
      if (res.data.updatedItems > 0) {
        toast.success('Timezone was updated successfully');
        setPractice({ ...practice, timezone: value.value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateEmailBatchSettings = async (val) => {
    try {
      const res = await interimApi(
        '/api/practice/update',
        {
          fields: { batch_email: val }
        },
        navigate
      );
      if (res.status == 200) {
        toast.success('Email batch settings was updated successfully');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateAppointmentCancellation = async (value) => {
    try {
      const res = await interimApi(
        '/api/practice/update',
        {
          fields: { cancellation: value.value }
        },
        navigate
      );

      if (res.data.updatedItems > 0) {
        toast.success('Cancellation Policy was updated successfully');
        setPractice({ ...practice, cancellation: value.value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateInstantBookingSettings = async (value) => {
    try {
      const res = await interimApi(
        '/api/practice/update',
        {
          fields: { instant_booking: value }
        },
        navigate
      );
      if (res.status == 200) {
        toast.success('Instant booking was updated successfully');
        setPractice({ ...practice, instant_booking: value });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateAppointmentNotifsSettings = async (value) => {
    try {
      const display_settings = {
        ...practice.display_settings,
        appointment: {
          ...practice?.display_settings?.appointment,
          default_notifications: value
        }
      };

      await requestApi({
        url: '/api/practice/update',
        params: { fields: { display_settings } },
        navigate,
        onSuccess: (data) => {
          if (data?.updatedItems > 0) {
            setPractice({ ...practice, display_settings });
            showAlert({
              color: 'success',
              title: 'Appointment Notifications',
              message: `Notifications are turned ${value ? 'on' : 'off'} successfully`
            });
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const appointmentCancellationHours = [
    { value: 0, label: '0' },
    { value: 6, label: '6' },
    { value: 12, label: '12' },
    { value: 24, label: '24' },
    { value: 48, label: '48' },
    { value: 72, label: '72' }
  ];

  const handleUpdateFee = async () => {
    try {
      const res = await requestApi({
        url: '/api/practice/update',
        params: {
          fields: { no_show_fee: practice.no_show_fee }
        },
        navigate
      });
      if (res.code == 0) {
        showAlert({
          color: 'success',
          title: 'No Show Fee',
          message: 'No show fee was updated successfully'
        });
      } else {
        showAlert({
          color: 'danger',
          title: 'No Show Fee',
          message: 'No show fee was not updated'
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (show.loading) return <Skeleton count={4} height="200px" />;

  return (
    <>
      <div className="flex flex-col gap-6">
        <Box className="flex flex-col gap-1">
          <p className="!pb-4 text-base font-500">Practitioners</p>
          <div className="grid grid-cols-3 items-center !pb-2">
            <div className="text-sm font-500">Name</div>
            <div className="text-sm font-500">Color</div>
            {userPermissions?.member?.read && <p className="text-sm font-500">Available Times</p>}
          </div>
          {iaRa(practitioners).map((practitioner) => {
            return (
              <PractitionerComponent
                key={practitioner.id}
                practitioner={{
                  id: practitioner.id,
                  name: `${practitioner.f_name} ${practitioner.l_name}`,
                  color: practitioner.color,
                  available_times: practitioner?.available_times
                }}
              />
            );
          })}
        </Box>
        <Box className="flex flex-col gap-1">
          <p className="!pb-4 text-base font-500">Services</p>
          <div className="grid grid-cols-3 items-center !pb-2">
            <p className="text-sm font-500">Name</p>
            <p className="text-sm font-500">Color</p>
          </div>
          {iaRa(data?.services).map((service) => (
            <ServiceComponent key={service.id} service={service} />
          ))}
        </Box>

        {userPermissions?.practice?.read && (
          <Box>
            <AppointmentTypes practice={practice} setPractice={setPractice} />
          </Box>
        )}

        <Box>
          <p className="!pb-2 text-base font-500">Timezone</p>
          {practice && (
            <Select
              placeholder="Type and select timezone"
              value={{ label: practice.timezone }}
              onChange={(value) => updateTimezone(value)}
              options={optionify(timeZoneNames)}
            />
          )}
        </Box>

        <Box>
          <p className="!pb-2 text-base font-500">Appointment Cancellation Policy</p>
          {practice && (
            <div className="flex items-center gap-1">
              <p>Patient can cancel appointments up to</p>
              <Select
                placeholder="Type and select"
                value={practice?.cancellation && { label: practice?.cancellation }}
                onChange={(v) => updateAppointmentCancellation(v)}
                options={appointmentCancellationHours}
              />
              <p>hours before the appointment starts.</p>
            </div>
          )}
        </Box>

        <Box>
          <p className="!pb-2 text-base font-500">Appointment No-Show Fee</p>
          {practice && (
            <div className="flex flex-col gap-3">
              <p>Fee for Missed Appointments Without Prior Cancellation</p>
              <CurrencyInput
                name="charge"
                value={practice?.no_show_fee || 0}
                onValueChange={(value) => setPractice({ ...practice, no_show_fee: value })}
                prefix="$"
                allowDecimals={true}
                decimalSeparator="."
              />
              <Button text="Update fee" onClick={handleUpdateFee} className="w-36" />
            </div>
          )}
        </Box>
        {userPermissions?.practice?.read && (
          <Box>
            <AppointmentLocation practice={practice} setPractice={setPractice} />
          </Box>
        )}
        <Box>
          <p className="!pb-2 text-base font-500">Instant Booking</p>
          {practice && (
            <div className="flex items-center gap-2">
              <Switch
                checked={practice?.instant_booking}
                onChange={(value) => updateInstantBookingSettings(value)}
              />
              <p className="text-sm">
                Instant booking is {practice?.instant_booking ? 'enabled' : 'disabled'}.
              </p>
            </div>
          )}
        </Box>
        <Box>
          <p className="!pb-2 text-base font-500">Email & Text Reminders</p>
          {emailBatch && (
            <div className="flex flex-col gap-3">
              <div className="flex flex-row gap-x-4 gap-y-3">
                <RadioButton
                  key="calendarEmail"
                  id="calendarEmailFalse"
                  value={false}
                  label="Send email immediately"
                  isSelected={emailBatch.enabled === false}
                  onChange={async () => {
                    setEmailBatch({ enabled: false, time: null });
                    await updateEmailBatchSettings({ enabled: false, time: null });
                  }}
                />
                <RadioButton
                  key="calendarEmail"
                  id="calendarEmailTrue"
                  value={true}
                  label="Schedule email (5pm)"
                  isSelected={emailBatch.enabled === true}
                  onChange={async () => {
                    setEmailBatch({ enabled: true, time: 17 });
                    await updateEmailBatchSettings({ enabled: true, time: 17 });
                  }}
                />
              </div>
              <div className="!pt-3">
                <p className="!pb-2 text-base font-500">Default notifications</p>
                <div className="flex items-center gap-2">
                  <Switch
                    checked={practice?.display_settings?.appointment?.default_notifications}
                    onChange={(value) => updateAppointmentNotifsSettings(value)}
                  />
                  <p className="text-sm">
                    Appointment notifications are{' '}
                    {practice?.display_settings?.appointment?.default_notifications === false
                      ? 'OFF'
                      : 'ON'}{' '}
                    by default.
                  </p>
                </div>
              </div>
            </div>
          )}
        </Box>
        {userPermissions?.practice?.read && (
          <>
            <ViewsSettings />
            <Box>
              <AvailableTimeComponent data={practice} />
            </Box>
          </>
        )}
      </div>
    </>
  );
};

export default CalendarSettings;
