import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { EraContextProvider } from 'lib/context/EraContext/EraContextProvider';
import { spaceToUppercase } from 'lib/helpers/utility';
import useModal from 'lib/hooks/useModal';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import PreviewFile from 'components/shared/PreviewFile/PreviewFile';
import Typography from 'components/shared/Typography/Typography';

import AllocateEOB from './components/AllocateEOB';
import AssignUnmatchedClaim from './components/AssignUnmatchedClaim';
import Claim from './components/Claim';
import Footer from './components/Footer';

const ViewEra = () => (
  <EraContextProvider>
    <ViewEraContent />
  </EraContextProvider>
);

const RenderContent = () => {
  const { selectedClaim, allocateEob } = useEraContext();
  if (selectedClaim) return <AssignUnmatchedClaim />;
  if (allocateEob) return <AllocateEOB />;
  return <Claim />;
};

const ViewEraContent = () => {
  const navigate = useNavigate();
  const {
    era,
    setSelectedClaim,
    selectedClaim,
    allocateEob,
    setAllocateEob,
    setSelectedUnmatchedClaim
  } = useEraContext();
  const { isOpen, openModal, closeModal } = useModal();

  const handleClose = () => navigate(-1);

  const handleBackClick = () => {
    setSelectedClaim(null);
    setAllocateEob(false);
    setSelectedUnmatchedClaim(null);
  };

  const modalTitle = (
    <Typography variant="h6" className="text-primary-900 text-nowrap">
      {spaceToUppercase(era?.type)} #{era?.id}
      {selectedClaim ? ' > Post Claim' : allocateEob ? ' > Allocate Amount' : ''}
    </Typography>
  );

  return (
    <Modal
      isOpen={true}
      slideFromRight
      handleClose={handleClose}
      title={modalTitle}
      prevButton={
        (selectedClaim || allocateEob) && (
          <Button
            icon="back-large"
            color="primary-900"
            onClick={handleBackClick}
            transparent
            data-qa="back-btn"
          />
        )
      }
      isLarge
      headButton={
        era?.type === 'eob' && (
          <div className="flex w-full justify-end">
            <Button text="Preview EOB" transparent onClick={openModal} />
          </div>
        )
      }
      footer={<Footer onClose={handleClose} />}>
      <div className="px-4 h-full flex flex-col">
        <RenderContent />
      </div>
      {isOpen && (
        <PreviewFile
          isOpen={isOpen}
          title="Uploaded EOB"
          handle={era?.handle}
          onClose={closeModal}
          slideFromRight
          isLarge
        />
      )}
    </Modal>
  );
};

export default ViewEra;
