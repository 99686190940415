import React from 'react';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import { Doughnut } from 'react-chartjs-2';
import { capitalize } from 'lodash';
import Icon from '../../../../shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';

export default function Superbills(props) {
  const { overview } = props;
  const navigate = useNavigate();
  const count = overview?.count?.superbills;
  const labels = ['Not scrubbed', 'Scrubbed'];
  const colors = ['#526268', '#22C55E'];
  const data = [count?.draft || 0, count?.complete || 0];
  const all = data.reduce((a, b) => a + b, 0);

  const percentage = (data) => {
    return (Math.floor((data / all) * 100) || 0) + '%';
  };

  const chartData = {
    labels: labels.map((label) => capitalize(label)),
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderWidth: 1
      }
    ]
  };

  const options = {
    cutout: 110,
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        display: false
      }
    },
    tooltip: {}
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <WidgetHeader icon="chart" hasPlus={false} {...props}>
        <div
          onClick={() => {
            navigate(`../superbills`);
          }}
          className="cursor-pointer !px-2 mx-1 rounded-lg !py-2 bg-neutral-50">
          <Icon className={'cursor-pointer'} icon={'new-link'}></Icon>
        </div>
      </WidgetHeader>

      <div className="flex overflow-hidden justify-between items-center flex-1">
        <div className="flex flex-1 justify-center flex-col gap-2 !pl-10">
          <table className="inline-block text-left">
            {labels.map((label, index) => (
              <tr key={index}>
                <td className="!pr-2 !py-[5px]">
                  <div
                    style={{ backgroundColor: colors[index] }}
                    className={`w-4 rounded-full h-4 `}></div>
                </td>
                <td className="!pr-3">
                  <p className="font-medium">{capitalize(label)}</p>
                </td>
                <td className="!pr-3">
                  <p className="text-neutral-600">{percentage(data[index])}</p>
                </td>
                <td>
                  <p className="text-neutral-900 font-semibold">{data[index]}</p>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="!max-w-[360px] !max-h-[360px] h-full !aspect-1 relative">
          <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] flex flex-col text-center justify-center">
            <p className="font-semibold text-primary-900 text-2xl">{all}</p>
            <p className="text-neutral-600 -mt-1">Superbills</p>
          </div>
          <Doughnut className="p-[36px]" options={options} data={chartData} />
        </div>
      </div>
    </div>
  );
}
