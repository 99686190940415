import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Textarea from 'components/shared/Textarea/Textarea';
import React from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upsertCustomFormType } from 'api/CustomForms';
import { showAlert } from 'components/shared/Alert/Alert';
import { useFormik } from 'formik';
import { optionify } from 'lib/helpers/utility';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { FormFunctionValidationSchema, getInitialData } from '../lib/handleFormik';

const CreateFormFunction = ({
  modalProps = {},
  hideModal = () => null,
  setSelectedForm = () => null
}) => {
  const { practice } = useOutletContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const upsertFormFunctionMutation = useMutation((data) => upsertCustomFormType(navigate, data), {
    onSettled: (data) => {
      const { formFunction = null, code, error = null } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Custom Note',
          message: error ?? 'Custom note creation failed! Please contact support.',
          color: 'danger'
        });

        hideModal();
        return;
      }

      const optionifyCustomNote = optionify([formFunction], 'name', 'id', (e) => e)?.[0];
      setSelectedForm(optionifyCustomNote);

      queryClient.invalidateQueries(['customFormTypes']);

      hideModal();
    }
  });

  const formik = useFormik({
    initialValues: getInitialData({ name: modalProps?.name }),
    validationSchema: FormFunctionValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      await upsertFormFunctionMutation.mutateAsync({
        upsertData: { ...values, practice_id: practice?.id }
      });
    }
  });

  return (
    <CreateFormFunctionModal
      handleOpen={modalProps?.open}
      hideModal={hideModal}
      isLoading={upsertFormFunctionMutation?.isLoading}
      onSubmit={() => formik.submitForm()}>
      <div className="flex flex-col !gap-3">
        <Input
          data-qa="form-function-name"
          id="form-function-name"
          name="name"
          label="Name"
          required={true}
          error={formik?.errors?.name}
          value={formik?.values?.name}
          onChange={formik && formik?.handleChange}
          disabled={upsertFormFunctionMutation?.isLoading}
        />
        <Textarea
          isEditor
          label="Description"
          placeholder="Description of the form function"
          id="description"
          name="description"
          className="min-h-[200px]"
          value={formik?.values?.description}
          error={formik?.errors?.description}
          onChange={(e) => formik.setFieldValue('description', e)}
          transcribing
          disabled={upsertFormFunctionMutation?.isLoading}
        />
      </div>
    </CreateFormFunctionModal>
  );
};

const CreateFormFunctionModal = ({
  children,
  handleOpen = false,
  hideModal = () => null,
  isLoading = true,
  onSubmit = () => null
}) => {
  return (
    <Modal
      modalCenterAnimation={true}
      handleOpen={handleOpen}
      handleClose={() => hideModal()}
      title="Create custom note type"
      className="max-w-[1100px]"
      footer={
        <div className="flex w-full justify-between">
          <Button
            data-qa="close-btn"
            color="neutral"
            size="small"
            outlined
            disabled={isLoading}
            loading={isLoading}
            text="Close"
            onClick={() => hideModal()}
          />
          <Button
            data-qa="create-btn"
            size="small"
            disabled={isLoading}
            loading={isLoading}
            text="Create"
            onClick={onSubmit}
          />
        </div>
      }>
      {children}
    </Modal>
  );
};

export default CreateFormFunction;
