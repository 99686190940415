import cs from 'classnames';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './shared/Buttons/Button';
import Icon from './shared/Icon/Icon';

const NotFound = ({ portal = 'portal', className }) => {
  const navigate = useNavigate();

  const handleNavigate = () => navigate(`/${portal}`);

  return (
    <div
      className={cs(
        'relative z-10 flex h-full flex-col content-center items-center justify-center gap-11',
        className
      )}>
      <Icon icon="404-not-found" />
      <div className="flex flex-col items-center">
        <h1 className="text-2xl font-500 leading-[30px] text-primary-900">404: Page not found!</h1>
        <p className="leading-6s !pt-2 font-400 text-neutral-600">
          This page could not be found, please go back to dashboard.
        </p>
        <Button
          text="Back to Dashboard"
          outlined
          transparent
          icon="new-dashboard"
          iconSize={18}
          size="small"
          className="!mt-6 !py-2 !pl-4 !pr-4"
          onClick={handleNavigate}
        />
      </div>
    </div>
  );
};

export default NotFound;
