import AGChart from 'components/shared/AGChart/AGChart';
import React from 'react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const StepsChart = ({
  chartData,
  title,
  description,
  roundedBottom,
  innerText,
  showButton,
  buttonText,
  handleClick
}) => {
  const options = {
    width: 100,
    height: 100,
    background: {
      fill: 'transparent'
    },
    series: [
      {
        data: chartData,
        type: 'donut',
        angleKey: 'value',
        fills: chartData.map((item) => item.fills),
        innerRadiusRatio: 0.7,
        cornerRadius: 5,
        innerLabels: [{ text: innerText, color: '#004F6B' }],
        innerCircle: {
          fill: 'transparent'
        },
        tooltip: {
          enabled: false
        }
      }
    ]
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div
        className={cs(
          'grid w-full grid-cols-6 items-center justify-between !gap-2 bg-gradient-to-t from-[#CCF1FF] to-[#E6F8FF] ',
          roundedBottom && 'rounded-b-[16px]',
          !roundedBottom ? 'py-0 pl-2 pr-1' : 'py-2 pr-2'
        )}>
        <div
          className={cs(
            'col-span-5 flex items-center !gap-2 pr-4',
            roundedBottom || (!showButton && 'col-span-6')
          )}>
          <AGChart options={options} />
          <div className="grid gap-1">
            <p className="text-sm font-500 text-primary-900">{title}</p>
            <p className="text-[12px] font-400 text-primary-900">{description}</p>
          </div>
        </div>
        {showButton && (
          <div className="col-span-1">
            <Button text={buttonText} onClick={handleClick} size="medium" />
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(StepsChart);
