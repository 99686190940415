import { ia, iaRa } from 'lib/helpers/utility';
import React from 'react';
import Popover from 'components/shared/Popovers/Popover/NewPopover';

const SendPatientFormActions = ({
  formModalVisible,
  setFormModalVisible,
  sendFormsToKiosk,
  data,
  kiosks = [],
  sendLink
}) => {
  const fromIds = iaRa(data?.patient_forms).map((form) => form.form_id || form.kind);

  return (
    <Popover
      isFixed
      panelClassName="z-[200]"
      icon="three-dots"
      iconClassName="rotate-90"
      buttonClassName="!min-h-[20px] !min-w-[20px] !rounded-[3px]"
      options={[
        {
          label: 'Edit forms',
          onClick: () => setFormModalVisible({ ...formModalVisible, formPacketModal: true }),
          icon: 'new-edit',
          color: 'primary',
          size: 20
        },
        {
          label: 'Send Form to',
          onClick: () => {},
          icon: 'new-clinical-narrative',
          color: 'primary',
          size: 20,
          children: [
            ia(kiosks) && kiosks.length > 1
              ? {
                  label: 'Kiosk',
                  icon: 'new-kiosk',
                  color: 'primary',
                  children: kiosks.map((kiosk) => ({
                    label: kiosk.name,
                    onClick: () => sendFormsToKiosk(fromIds, kiosk.id),
                    icon: 'kiosk-nav',
                    color: 'primary'
                  }))
                }
              : ia(kiosks) && {
                  label: 'Kiosk',
                  onClick: () => sendFormsToKiosk(fromIds, kiosks[0].id),
                  icon: 'kiosk-nav',
                  color: 'primary'
                },
            {
              label: 'Email',
              icon: 'new-email-blue',
              color: 'primary',
              onClick: () => sendLink('email', fromIds)
            },
            {
              label: 'Text Message',
              icon: 'text-message',
              color: 'primary',
              onClick: () => sendLink('text', fromIds)
            }
          ].filter(Boolean)
        }
      ]}
      optionIconSize={18}
    />
  );
};

export default SendPatientFormActions;
