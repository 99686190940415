import { useField } from 'formik';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import Header from 'components/shared/shared/Header';

const DrawAtServiceCenterCheckbox = ({ className }) => {
  const [field] = useField({
    name: 'drawAtServiceCenter'
  });

  return (
    <div className={className}>
      <Header label="Draw at service center" name="draw-at-service-center" />
      <Checkbox {...field} id="draw-at-service-center" color="blue" checked={field.value} />
    </div>
  );
};

export default DrawAtServiceCenterCheckbox;
