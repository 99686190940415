import React from 'react';
import * as Yup from 'yup';
import cs from 'classnames';
import { useFormik } from 'formik';

import Input from 'components/shared/Input/Input';
import Textarea from 'components/shared/Textarea/Textarea';
import { Capitalize } from 'lib/helpers/utility';

const EditMacros = ({ macroTitle, macros, textAreaClassName, footer }) => {
  const macroValidationSchema = Yup.object().shape({
    inputValue: Yup.string().required('Name is required'),
    textareaValue: Yup.string().required('Text area is required')
  });

  const initialValues = {};

  macros.map((macro) => {
    if (!macro) return;
    initialValues[macro.id] = {
      ...macro,
      name: macro?.name || '',
      body: macro?.body.replace(/["\\/]/g, '') || ''
    };
  });

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: macroValidationSchema
  });

  return (
    <>
      {macros.map((macro) => (
        <>
          <Input
            id={`${macro.name}-input-name`}
            label={`${Capitalize(macroTitle)} Label`}
            data-qa="enter-appointment-template-label"
            placeholder={`Enter ${macroTitle} label`}
            name="name"
            autoComplete="off"
            value={formik?.values?.[macro.id]?.name}
            onChange={(event) => {
              formik.setFieldValue(`${macro.id}.name`, event.target.value);
              //   formik.setFieldValue(`${macro.id}.updatedAt`, new Date());
            }}
            required={true}
            error={formik?.errors?.[macro.id]?.name}
          />

          <div className="!mt-3">
            <Textarea
              className={textAreaClassName}
              id={`${macroTitle}-textarea`}
              label={
                <p className="flex">
                  {Capitalize(macroTitle)} Area<span className="text-danger-500">*</span>
                </p>
              }
              name="body"
              data-qa={`enter-${macroTitle}-label`}
              placeholder={`Enter ${macroTitle} area`}
              value={formik.values?.[macro.id]?.body}
              onChange={(value) => {
                formik.setFieldValue(`${macro.id}.body`, value);
              }}
              error={formik?.errors?.[macro.id]?.body}
              isEditor={true}
            />
          </div>
        </>
      ))}
      <div
        className={cs(
          'Modal__footer',
          'flex justify-center border-0 bg-primary-10 !p-0 shadow-none'
        )}>
        {footer ? (
          <div className="flex justify-center">
            {footer({
              submit: formik.handleSubmit,
              isSubmitting: formik.isSubmitting,
              values: formik.values
            })}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default EditMacros;
