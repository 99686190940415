import { requestApi } from 'api/Api';
import CheckinPaymentKiosk from 'components/Patient/checkin/payment/Kiosk';
import PayFailure from 'components/Patient/checkin/payment/payFailure';
import PaySuccess from 'components/Patient/checkin/payment/paySuccess';
import state from 'components/Patient/checkin/state';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';
import SavedCardSale from 'components/Payments/lightbox/SavedCardSale';
import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';
import { ia } from 'lib/helpers/utility';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';

const SuccessScreen = (props) => {
  const navigate = useNavigate();

  const logout = async () => {
    await requestApi({
      url: '/api/user/logout',
      navigate,
      params: {},
      onError: (error, code, redirect) => {
        navigate(redirect);
      },
      onSuccess: (error, code, redirect) => {
        navigate(redirect);
      }
    });
  };

  return <PaySuccess {...props} onCancel={logout} continueText="Exit" />;
};

const PayPrompt = ({
  amount,
  invoiceToken,
  saveCard,
  billingAddress,
  setReceipt,
  onApproval,
  onCancel,
  onDeclined
}) => {
  const [balance, setBalance] = useState(0);
  const [kioskAuthorization, setKioskAuthorization] = useState('');
  const [show, setShow] = useState({
    loading: true,
    startNewTransaction: false,
    saveCard: false,
    transactionStartText: 'Pay',
    processingKind: '',
    kiosk: false,
    promptPayment: false,
    success: false,
    failure: false,
    transactionId: null
  });
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const user = useRecoilState(state.userState);
  const navigate = useNavigate();
  const timeoutRef = useRef(null);

  useEffect(() => {
    promptPayment();
    loadSavedCards();
  }, []);

  const logout = async () => {
    await requestApi({
      url: '/api/user/logout',
      navigate,
      params: {},
      onError: (error, code, redirect) => {
        navigate(redirect);
      },
      onSuccess: (error, code, redirect) => {
        navigate(redirect);
      }
    });
  };

  const loadSavedCards = async () => {
    try {
      let params = { patientId: user.id };

      const res = await requestApi({ url: '/api/transactions/token/list', params, navigate });
      const { code, redirect, error, cards: loadedCards } = res;

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedCards)) {
            setCards(loadedCards);
          }
          break;
        default:
          showAlert({
            message: error || `An unexpected error has occurred. Please try again later.`
          });

          break;
      }
    } catch (error) {
      showAlert({ message: `There's been an unexpected error. Please try again later.` });
    }
  };

  const promptPayment = async () => {
    try {
      const resData = await requestApi({
        url: '/api/public/invoice/pay',
        params: { token: invoiceToken, saveCard },
        navigate
      });

      setBalance(resData?.balance);
      setKioskAuthorization(resData?.kioskAuthorization);

      setShow((ps) => ({
        ...ps,
        kiosk: !!resData?.kiosk,
        loading: false,
        promptPayment: true,
        processingKind: resData?.processingKind
      }));

      // if (!!resData?.kiosk) {
      //   // this is a kiosk, a payment should've been prompted
      //   let newTimeoutLink = setTimeout(() => {
      //     // if a transaction hasn't been
      //     payWithAlternative();
      //   }, 5000);
      //
      //   timeoutRef.current = newTimeoutLink
      // }
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentConfirmationReceived = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const onLightboxSuccess = async () => {
    onApproval();
  };

  const payWithAlternative = () => setShow((ps) => ({ ...ps, processingKind: 'elavonLightbox' }));

  if (show.loading) {
    return (
      <div className="!p-4">
        <div className="flex flex-row justify-center self-center">Loading...</div>
      </div>
    );
  }

  if (show.success) {
    return (
      <Modal
        isOpen={true}
        disableHeader={true}
        footer={null}
        isFooter={false}
        handleClose={onCancel}
        className="h-full w-full">
        <PaySuccess onCancel={logout} transactionId={show?.transactionId} continueText="Exit" />
      </Modal>
    );
  }

  if (show.failure) {
    return (
      <Modal
        isOpen={true}
        disableHeader={true}
        footer={null}
        isFooter={false}
        handleClose={onCancel}
        className="h-full w-full">
        <PayFailure
          payWithAlternative={payWithAlternative}
          promptPayment={() => {
            promptPayment();
            setShow((ps) => ({
              ...ps,
              message: '',
              messageTitle: '',
              messageKind: ''
            }));
          }}
          onCancel={logout}
          errorMessage={show.message}
        />
      </Modal>
    );
  }

  if (show.promptPayment) {
    switch (show.processingKind) {
      case 'elavonKioskTerminal':
        return (
          <CheckinPaymentKiosk
            payWithAlternative={payWithAlternative}
            promptPayment={promptPayment}
            amount={balance}
            kioskAuthorization={kioskAuthorization}
            invoiceToken={invoiceToken}
            onSuccess={onApproval}
            onCancel={logout}
            handlePaymentConfirmationReceived={handlePaymentConfirmationReceived}
            cards={cards}
            setCards={setCards}
            setSelectedCard={setSelectedCard}
            showOverrides={show}
            setShowOverrides={setShow}
            setReceipt={setReceipt}
          />
        );
      case 'elavonLightbox':
        return (
          <Modal
            isOpen={true}
            disableHeader={true}
            footer={null}
            isFooter={false}
            handleClose={onCancel}
            className="h-full w-full">
            <LightboxWrapper
              amount={balance}
              saveCard={show.saveCard}
              billingAddress={billingAddress}
              invoiceToken={invoiceToken}
              onApproval={onApproval}
              onFail={onCancel}
              onCancel={onCancel}
              onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
              updateAmount={(amountPaid) => setBalance((prevBalance) => prevBalance - amountPaid)}
              SuccessScreen={SuccessScreen}
            />
          </Modal>
        );
      case 'elavonSavedCard':
        return (
          <Modal
            isOpen={true}
            disableHeader={true}
            footer={null}
            isFooter={false}
            handleClose={onCancel}
            className="h-full w-full">
            <SavedCardSale
              card={selectedCard}
              amount={balance}
              invoiceToken={invoiceToken}
              onSuccess={(tid) => setShow({ ...show, success: true, transactionId: tid })}
              onFail={() => setShow({ ...show, failure: true })}
            />
          </Modal>
        );
    }
  }

  return (
    <Modal
      isOpen={true}
      disableHeader={true}
      footer={null}
      isFooter={false}
      handleClose={onCancel}
      className="w-full">
      <LightboxWrapper
        amount={balance}
        saveCard={show.saveCard}
        billingAddress={billingAddress}
        onApproval={onLightboxSuccess}
        invoiceToken={invoiceToken}
        onFail={() => onDeclined()}
        onCancel={() => onCancel()}
        onDeclined={() => setShow({ ...show, transactionStartText: 'Retry' })}
        updateAmount={(amountPaid) => setBalance((prevBalance) => prevBalance - amountPaid)}
      />
    </Modal>
  );
};

export default withErrorBoundary(PayPrompt);
