import { useQuery } from '@tanstack/react-query';
import { getOverview } from 'components/practice/charts/ClinicalNote/lib/getOverview';
import { useNavigate } from 'react-router-dom';

export const useOverview = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['overview', ...dependencies], () => getOverview(params, navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
