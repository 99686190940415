import React, { useState } from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { Outlet } from 'react-router-dom';
import NavLinkTabs from 'components/shared/NavLinkTabs/Tabs';
import Header from 'components/shared/Header/Header';
import Loader from 'components/shared/Loader/Loader';

const AdminIntelligence = () => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="flex h-full flex-col">
      <Header
        subtitle={
          <NavLinkTabs
            list={[
              { name: 'AI Tasks', path: 'ai_task' },
              { name: 'Prompt Template', path: 'prompts' },
              { name: 'Models', path: 'model' }
            ]}
          />
        }>
        {isLoading && (
          <div className="flex flex-row gap-2">
            <Loader outlined color="primary" />
            <div>Loading...</div>
          </div>
        )}
      </Header>
      <Outlet
        context={{
          setLoading: setIsLoading
        }}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligence);
