import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { interimApi } from '../../../../../../api/InterimApi';
import { ia } from '../../../../../../lib/helpers/utility';
import LogPopover from './LogPopover';

export default function LogStatus({ status, id, type, medium, fromPatient, messageId }) {
  const { patient } = useOutletContext();
  const navigate = useNavigate();

  const resendEmail = async ({ id, type }) => {
    try {
      const res = await interimApi('/api/send_grid/resend', { id, type, messageId }, navigate);
      if (res.data.sent) {
        toast.success('Email Resent!');
      } else {
        toast.error('Something went wrong. Please try again later!');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const resendText = async ({ id, type }) => {
    try {
      const res = await interimApi('/api/twilio/resend', { id, type }, navigate);
      if (res.data) {
        toast.success('Text Resent!');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getFailedStatusMessages = ({ medium, status }) => {
    const emailStatuses = {
      bounce: 'Email address does not exist',
      default: 'Failed sending email'
    };

    const textStatuses = {
      text_disabled:
        'Text sending not enabled for this patient. Check SMS consent under account settings.',
      region_permission_not_enabled:
        "Text sending not allowed for the region of the patient's number.",
      undeliverable:
        'Text sending failed. Please double-check the patient phone number and the Twilio practice phone number.',
      undelivered: 'Text could not be delivered',
      unsubscribed: 'Text delivery failed. Patient unsubscribed from texts.',
      default: 'Text message failed'
    };

    return medium === 'email'
      ? emailStatuses[status] || emailStatuses['default']
      : textStatuses[status] || textStatuses['default'];
  };

  const getStatusMessage = () => {
    switch (status) {
      case 'queued':
        return {
          className: 'text-amber-400',
          text: 'Queued',
          description: `${medium === 'email' ? 'Email' : 'Text'} is scheduled to be delivered`
        };
      case 'processed':
        return {
          className: 'text-amber-600',
          text: 'Processed',
          description: `${
            medium === 'email' ? 'Email' : 'Text'
          } has been received and is ready to be delivered`
        };
      case 'sent':
        return {
          className: 'text-primary-900',
          text: 'Sent',
          description: `${
            medium === 'email'
              ? ''
              : 'Text has been sent. The nearest upstream carrier accepted the outbound message'
          }`
        };
      case 'delivered':
        return {
          className: 'text-success-600',
          text: 'Delivered',
          description: null,
          options: [
            {
              icon: 'new-refresh-arrows',
              label: 'Send again',
              method:
                medium === 'email'
                  ? () => resendEmail({ id, type })
                  : () => resendText({ id, type })
            }
          ]
        };
      case 'bounce':
      case 'undelivered':
      case 'undeliverable':
      case 'unsubscribed':
      case 'region_permission_not_enabled':
      case 'failed':
      case 'text_disabled':
        return {
          className: 'text-danger-400',
          text: 'Failed',
          description: getFailedStatusMessages({ medium, status }),
          options: [
            {
              icon: 'new-refresh-arrows',
              label: 'Try again',
              method:
                medium === 'email'
                  ? () => resendEmail({ id, type })
                  : () => resendText({ id, type })
            },
            {
              icon: 'new-log-settings',
              label: `Update  ${medium === 'email' ? 'email' : 'phone number'}`,
              method: () => navigate(`/portal/charts/${patient.id}`)
            }
          ]
        };
      default:
        return { text: 'N/A' };
    }
  };

  const statusMessage = getStatusMessage();

  if (!statusMessage) {
    return null;
  }

  return (
    <div className={cs('flex flex-wrap gap-x-1 text-xs', statusMessage.className)}>
      <em className="font-bold not-italic">{statusMessage.text}</em>
      {statusMessage.description && <em className="not-italic">({statusMessage.description})</em>}
      {ia(statusMessage.options) && type && !fromPatient && (
        <LogPopover data={statusMessage.options} />
      )}
    </div>
  );
}
