import { requestApi } from 'api/Api';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

const ClaimReasonModal = ({ modalVisible, setModalUnVisible, claimId }) => {
  const [claimStateHistory, setClaimStateHistory] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const claimState = {
    R: 'Rejected',
    A: 'Acknowledged'
  };

  useEffect(() => {
    handleStatus();
  }, []);

  const handleStatus = async () => {
    setLoading(true);
    try {
      const res = await requestApi({
        url: '/api/practice/billing/claim/response_request',
        params: { claimId },
        navigate
      });
      if (res) {
        setClaimStateHistory(res);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
    setLoading(false);
  };

  return (
    <Modal
      slideFromRight
      isOpen={modalVisible}
      handleClose={setModalUnVisible}
      buttonText="Done"
      title="Claim status history"
      className="w-[1320px]"
      footer={
        <Button onClick={setModalUnVisible} text="Close" color="neutral" data-qa="close-btn" />
      }>
      {loading ? (
        <Skeleton count={5} height="100px" />
      ) : (
        <VerticalTimeline>
          {claimStateHistory?.history?.claim?.map((state, index) => {
            return (
              <VerticalTimelineElement
                key={index}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: state.status === 'R' ? 'rgb(255,236,240)' : 'rgb(239,250,244)',
                  color: state.status === 'R' ? 'rgb(215,62,91)' : 'rgb(59,133,97)'
                }}
                contentArrowStyle={{
                  borderRight:
                    state.status === 'R'
                      ? '7px solid  rgb(255,236,240)'
                      : '7px solid  rgb(239,250,244)'
                }}
                date={state.response_time}
                iconStyle={{
                  background: state.status === 'R' ? 'rgb(241,71,103)' : 'rgb(74,198,141)',
                  color: '#fff'
                }}>
                <h4
                  className="vertical-timeline-element-title"
                  style={{
                    color: state.status === 'R' ? 'rgb(215,62,91)' : 'rgb(59,133,97)'
                  }}>
                  Status: {claimState[state.status]}
                </h4>
                {state?.messages?.map(({ message }, index) => {
                  return <ul key={index}>{message}</ul>;
                })}
              </VerticalTimelineElement>
            );
          })}
        </VerticalTimeline>
      )}
    </Modal>
  );
};

export default ClaimReasonModal;
