import React, { useEffect, useState } from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { camelCaseToReadableFormat } from 'lib/helpers/utility';
import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';

import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filters/Filter';
import Input from 'components/shared/Input/Input';
import Typography from 'components/shared/Typography/Typography';

import { defaultFilters } from '../lib/helpers';

const Header = ({ category, onOpenModal, data, hasNew, onUpdateData = () => {} }) => {
  const { filters, setFilters, gridApi } = useTableContext();
  const categoryName = camelCaseToReadableFormat(category);
  const [medicarePercentage, setMedicarePercentage] = useState(100);

  const { data: payersResponse } = usePayers();
  const { data: professionGroupOptions } = useProfessionGroups();

  const payers = payersResponse?.payers;
  const specialties = professionGroupOptions?.profession_groups;

  const updateStandardCharge = (value) => {
    const percentage = parseFloat(value || 100) / 100;

    if (!gridApi || isNaN(percentage)) {
      return;
    }

    const updatedRows = data.map((row) => ({
      ...row,
      standard_charge: row.par_amount * percentage,
      total_cost_cents: row.par_amount * percentage
    }));

    gridApi.setRowData(updatedRows);
    onUpdateData(updatedRows);
  };

  useEffect(() => {
    const initialFilters = defaultFilters(specialties, payers);
    setFilters(initialFilters);
  }, [payers, specialties]);

  const onMedicarePercentageChange = (e) => {
    updateStandardCharge(e.target.value);
    setMedicarePercentage(e.target.value);
  };

  return (
    <div className="flex w-full flex-col gap-6 p-2">
      <div className="flex items-start gap-3">
        <div className="flex w-full flex-col items-start gap-0.5">
          <Typography variant="body" weight="font-medium">
            Select Your Practice Services
          </Typography>
          <Typography variant="small" className="text-neutral-500">
            Add or manage billing providers associated with this practice for efficient claim
            processing.
          </Typography>
        </div>
        {hasNew && (
          <Button
            text={`Add New ${categoryName}`}
            icon="shade-plus"
            textClassName="text-nowrap"
            size="medium"
            className="h-[40px] w-fit"
            iconColor="white"
            onClick={onOpenModal}
          />
        )}
      </div>
      <div className="flex flex-row justify-between gap-4">
        <div className="flex items-center gap-3">
          <Typography
            variant="small"
            className="text-neutral-900"
            iconRight="new-info-circle"
            iconColor="bg-primary-900">
            Fees Multiplier
          </Typography>
          <Input
            className="w-[120px]"
            value={medicarePercentage}
            actionButtonText="%"
            name="medicarePercentage"
            onChange={onMedicarePercentageChange}
          />
        </div>
        <div className="flex flex-row items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
