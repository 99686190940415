import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Capitalize, iaRa } from 'lib/helpers/utility';
import { currentPractice } from '../../../practiceState';
import { requestApi } from 'api/Api';
import Select from 'components/shared/Select/Select';

export const views = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
  AGENDA: 'agenda',
  PROVIDER: 'provider',
  RESOURCE: 'resource'
};

const options = iaRa(Object.keys(views)).map((view) => ({
  label: Capitalize(view),
  value: view
}));

const DefaultView = () => {
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [defaultView, setDefaultView] = useState({
    label: 'Day',
    value: 'day'
  });
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultView(practice?.display_settings?.calendar?.defaultView);
  }, [practice]);

  const onChangeDefaultView = async (event) => {
    setDefaultView(event);
    try {
      const display_settings = {
        ...practice.display_settings,
        calendar: {
          ...practice.display_settings.calendar,
          defaultView: { value: event.value, label: event.label }
        }
      };
      const data = await requestApi({
        url: '/api/practice/update',
        params: { fields: { display_settings } },
        navigate
      });

      if (data?.updatedItems > 0) {
        toast.success('Default view of calendar was updated successfully');
        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Select
      width={200}
      label="Default view"
      value={defaultView}
      options={options}
      onChange={(event) => onChangeDefaultView(event)}
    />
  );
};

export default DefaultView;
