import React, { useState, useMemo } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';
import Icon from 'components/shared/Icon/Icon';
import { userState as user_state } from 'components/state';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const Section = ({
  target = '',
  title = '',
  headerChildren = '',
  children = '',
  className,
  isMobile = false
}) => {
  const userState = useRecoilValue(user_state);
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const appointmentSettings = userState?.display_settings?.appointment || {};
  const currentSection = appointmentSettings[target] || {};
  const isOpen = currentSection.isOpen ?? true;
  const isPined = currentSection.isPined ?? false;
  const isConfirmationPins = appointmentSettings.isConfirmationPins ?? true;

  const isPinnedSectionExists = useMemo(() => {
    return Object.values(appointmentSettings).some(
      (section) => typeof section === 'object' && section.isPined === true
    );
  }, [appointmentSettings]);

  const updateAppointmentSettings = (updater) => {
    const newSettings = updater(appointmentSettings);
    saveDisplaySettings({
      ...userState.display_settings,
      appointment: newSettings
    });
  };

  const resetAllPins = (settings) => {
    return Object.entries(settings).reduce((acc, [key, value]) => {
      if (typeof value === 'object') {
        acc[key] = { ...value, isPined: false };
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});
  };

  const handleOpen = () => {
    if (isPinnedSectionExists && isConfirmationPins) return;

    updateAppointmentSettings((prev) => {
      let updated = isConfirmationPins ? prev : resetAllPins(prev);
      return {
        ...updated,
        [target]: { ...updated[target], isOpen: !isOpen }
      };
    });
  };

  const handleFull = () => {
    updateAppointmentSettings((prev) => {
      if (prev[target].isPined) {
        return { ...prev, [target]: { ...prev[target], isPined: false } };
      }

      return Object.entries(prev).reduce((acc, [key, value]) => {
        if (typeof value === 'object') {
          acc[key] = {
            ...value,
            isOpen: key === target,
            isPined: key === target
          };
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});
    });
  };

  const handleExpandAndResetView = () => {
    updateAppointmentSettings((prev) => {
      const updated = resetAllPins(prev);
      return {
        ...updated,
        isConfirmationPins: !dontShowAgain,
        [target]: { ...updated[target], isOpen: !updated[target].isOpen }
      };
    });
  };

  return (
    <div className="relative border-0 border-b border-dashed border-primary-200 last-of-type:z-10 last-of-type:border-0">
      <div className="flex flex-wrap items-center justify-between gap-x-1 gap-y-2 bg-primary-50 px-4 py-2">
        <div className="flex select-none items-center gap-[6px]">
          <Popover className="relative -mt-[3px]">
            {({ open, close }) => (
              <>
                <PopoverButton
                  className={cs(
                    'flex size-[28px] cursor-pointer items-center justify-center rounded-[4px] p-0',
                    open && isPinnedSectionExists && isConfirmationPins && 'bg-primary-700'
                  )}
                  onClick={handleOpen}>
                  <div className="flex size-[17px] items-center justify-center rounded-[4px] bg-primary-100">
                    <Icon
                      className={cs(
                        'cursor-pointer duration-150',
                        !isOpen && 'mt-[1px] rotate-180'
                      )}
                      icon="chevron-up"
                      size={9}
                      color="primary"
                    />
                  </div>
                </PopoverButton>
                {isConfirmationPins && isPinnedSectionExists && (
                  <PopoverPanel className="absolute top-[34px] z-[100] flex w-[512px] flex-col rounded-[6px] bg-white p-6 shadow-medium">
                    <div className="flex items-start justify-between pb-5">
                      <div className="flex size-12 place-content-center rounded-full bg-primary-50">
                        <Icon icon="new-pin-filled-v2" color="primary" size={24} />
                      </div>
                      <Icon icon="new-close-square" onClick={close} />
                    </div>
                    <div className="flex flex-col pb-8">
                      <p className="pb-2 text-lg font-500 leading-7 text-neutral-900">{`Expand ${title}?`}</p>
                      <p className="pb-4 text-sm leading-5 text-neutral-700">
                        You have currently set as pinned Pricing Protocol. By clicking this action
                        you will reset visibility of sections to default view.
                      </p>
                      <Checkbox
                        label="Don't show this message again"
                        labelClassName="cursor-pointer"
                        checked={dontShowAgain}
                        onChange={() => setDontShowAgain(!dontShowAgain)}
                      />
                    </div>
                    <div className="ml-auto flex gap-3">
                      <Button
                        text="Cancel"
                        color="neutral"
                        outlined
                        className="bg-neutral-50"
                        onClick={close}
                      />
                      <Button
                        text="Expand & reset view"
                        onClick={() => {
                          handleExpandAndResetView();
                          close();
                        }}
                      />
                    </div>
                  </PopoverPanel>
                )}
              </>
            )}
          </Popover>
          <span className="text-base font-500 leading-[18px] text-primary-600">{title}</span>
          {!isMobile && (
            <Tippy
              className={cs('tippy-dark', !isPined && 'w-[200px]')}
              content={isPined ? 'Unpin section' : `Pin ${title} and minimize other sections!`}>
              <div
                className="flex size-[22px] cursor-pointer items-center justify-center rounded-[4px] hover:bg-neutral-50"
                onClick={handleFull}>
                <Icon
                  className="cursor-pointer"
                  icon={isPined ? 'new-pin-filled' : 'new-pin'}
                  color="primary"
                />
              </div>
            </Tippy>
          )}
        </div>
        {headerChildren}
      </div>
      <div
        className={cs(
          'bg-white duration-200',
          isOpen ? 'px-4 pb-3 pt-2' : 'h-0 overflow-hidden p-0 opacity-0',
          className
        )}>
        {children}
      </div>
    </div>
  );
};

export default Section;
