import * as Yup from 'yup';

const NewAndUpdateValidation = Yup.object().shape({
  measurement_type: Yup.string().required('Measurement type required'),
  measurement_value: Yup.string().required('Measurement value required'),
  chart_type: Yup.string().required('Chart type required'),
  gender: Yup.string().required('Gender required')
});

export default NewAndUpdateValidation;
