import { requestApi } from './Api';

export const getDashboardWidget = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/dashboard/dashboard',
    params
  });

export const getReportsWidget = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/admin_reports/admin_reports',
    params
  });

export const getAppointments = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/appointments',
    params
  });

export const getClinicalNotes = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/clinical_notes',
    params
  });

export const getLedger = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/ledger',
    params
  });

export const getMedications = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/medications',
    params
  });

export const getICD10 = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/icd10',
    params
  });

export const getClaims = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/claims',
    params
  });

export const getVitals = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/overview/vitals',
    params
  });

export const getAllergies = async (navigate, patientId) =>
  await requestApi({
    navigate,
    url: '/api/custom_form_response/read',
    params: {
      patientId,
      allVersions: true,
      type: [
        'medicationHistory',
        'allergies',
        'medicalHistory',
        'vaccines',
        'conditions',
        'familyHistory',
        'socialHistory',
        'surgicalHistory',
        'weightLossAdministration'
      ]
    }
  });

export const getBillingOverview = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/widgets/billing/overview',
    params
  });

export const getPracticeIQueueOverview = async (navigate, params) =>
  await requestApi({
    navigate,
    params,
    url: '/api/widgets/practice_i_queue/overview'
  });
