export const currencyWrapperStyles = {
  primary: {
    label: 'flex items-center gap-x-1',
    wrapper:
      'relative m-0 flex h-10 items-center justify-between gap-x-2 rounded-md border bg-white px-3 py-[10px] outline-2 focus-within:outline',
    error: 'border-danger-300 focus-within:outline-danger-100',
    default:
      'border border-solid border-neutral-200 focus-within:border-primary-300 focus-within:outline-[#CBF0FD]',
    hideBorder: 'border-transparent focus-within:border-primary-300 focus-within:outline-[#CBF0FD]',
    disabled: '!bg-neutral-50 cursor-not-allowed'
  },
  secondary: {
    wrapper:
      'relative m-0 flex h-10 items-center justify-between gap-x-2 bg-transparent border-0 border-b border-solid rounded-none',
    disabled: 'cursor-not-allowed',
    error: 'border-b-danger-300 focus-within:outline-danger-100',
    default:
      'p-2 border-b-neutral-200 focus-within:border-b-primary-300 focus-within:outline-[#CBF0FD]',
    hideBorder:
      'p-2 border-b-transparent focus-within:border-b-primary-300 focus-within:outline-[#CBF0FD]'
  }
};

export const currencyInputStyles = {
  primary: {
    input:
      'w-full text-sm leading-5 border-none bg-transparent p-0 outline-none text-neutral-900 placeholder:text-neutral-500',
    inputError: 'caret-danger-500',
    inputDefault: 'caret-primary-500',
    disabled: '!text-neutral-500'
  },
  secondary: {
    input:
      'w-full text-sm leading-5 border-none bg-transparent p-0 outline-none text-neutral-900 placeholder:text-neutral-500',
    inputError: 'caret-danger-500',
    inputDefault: 'caret-primary-500',
    disabled: '!text-neutral-500'
  }
};

export const currencyLabelStyles = {
  label: 'flex items-center gap-x-1'
};
