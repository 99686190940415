import React, { useState } from 'react';
import Modal from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/Buttons/Button';
import ContactsTable from './ContactsTable';

const ContactsModal = ({
  contactsModalVisible,
  setContactsModalVisible,
  handleSelectContacts,
  selectedContacts: prevSelectedContacts = [],
  selectOne = false
}) => {
  const [selectedContacts, setSelectedContacts] = useState(prevSelectedContacts);
  const [loading, setLoading] = useState(false);
  return (
    <Modal
      handleOpen={!!contactsModalVisible}
      handleClose={() => setContactsModalVisible(false)}
      title={'Search from contacts'}
      customStyling={{ width: '924px' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setContactsModalVisible(false)}
            id="cancelProductModalBtn"
            data-qa="cancel-product-modal-btn"
          />
          <Button
            onClick={() => {
              handleSelectContacts(selectedContacts);
              setContactsModalVisible(false);
            }}
            text={`Select contact${selectOne ? '' : 's'}`}
            disabled={!selectedContacts.length}
            id="createContactModalBtn"
            data-qa="select-contacts-btn"
            loading={loading}
          />
        </div>
      }>
      <ContactsTable {...{ selectedContacts, setSelectedContacts, selectOne, loading, setLoading }} />
    </Modal>
  );
};

export default ContactsModal;
