import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { ia, verifyPassword } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import './PasswordReset.scss';

const AdminPasswordSet = () => {
  const [passwordA, setPasswordA] = useState('');
  const [passwordB, setPasswordB] = useState('');
  const [state, setState] = useState({
    password_reset: false,
    passwords_match: 0,
    password_ok: false,
    strength: '',
    messages: []
  });
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    document.title = `Myriad - Set New Password`;
    checkResetToken(token);
  }, []);

  const checkResetToken = async (reset_token) => {
    try {
      const resData = await requestApi({
        url: '/api/admin/password/verify',
        params: { token: reset_token },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (redirect) navigate(redirect);
          break;
        default:
          if (error) {
            Honeybadger.notify(`checkResetToken: ${error}`);
          } else {
            Honeybadger.notify(`checkResetToken unexpected error`);
          }
          if (redirect) {
            setTimeout(() => {
              navigate(redirect);
            }, 5000);
          }
          break;
      }
    } catch (error) {
      Honeybadger.notify(`checkResetToken: ${error}`);
      setTimeout(() => {
        navigate('/password/request');
      }, 5000);
    }
  };

  const requestSetNewPassword = async () => {
    try {
      const password_check = verifyPassword(passwordA);
      if (!password_check.password_ok) {
        setState({ ...state, ...password_check });
        throw new Error(`Password doesn't meet requirements`);
      }
      if (passwordA !== passwordB) {
        throw new Error(`Passwords don't match`);
      }

      const resData = await requestApi({
        url: '/api/admin/password/set',
        params: {
          token: token,
          password: passwordA
        },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          toast.success(`Successfully reset password`);
          setState({ ...state, password_reset: true });
          setTimeout(() => {
            navigate(redirect);
          }, 3000);
          break;
        case 1:
          toast.success(`Password was not reset.`);
          Honeybadger.notify(`requestSetNewPassword: ${error}`);
          break;
        default:
          Honeybadger.notify(`requestSetNewPassword: ${error}`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`requestSetNewPassword: ${error}`);
    }
  };

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'passwordA':
        const passwordResetMessagesA = verifyPassword(value);
        setPasswordA(value);
        setState({ ...state, ...passwordResetMessagesA });
        break;
      case 'passwordB':
        const passwordResetMessagesB = Object.assign({}, state);
        let p_m = passwordResetMessagesB.messages;
        if (passwordA !== value) {
          let warning_index = p_m.findIndex((v) => v.id === 1);
          if (warning_index === -1) {
            p_m.push({ type: 'warning', id: 1, message: 'Passwords must match.' });
          }
        } else {
          p_m = p_m.filter((elem) => elem.id !== 1);
        }
        setPasswordB(value);
        setState({ ...state, ...passwordResetMessagesB, messages: p_m });
        break;
      default:
        this.setState({
          [name]: value
        });
        break;
    }
  };

  if (state.password_reset) {
    return (
      <>
        <h3>Set New Password</h3>
        <p>
          New password has been set! If you're not automatically redirected to login please navigate
          to login.
        </p>

        <Button text="Login" className="mx-auto mt-5" onClick={() => navigate('/admin/login')} />
      </>
    );
  }

  return (
    <div className="PasswordResetComponentWrapper">
      <h3 className="!pb-4">Set new password</h3>
      <h6 className="text-center">Please enter your new desired password.</h6>

      {state.error && <div className="error text-center text-sm">{state.error}</div>}

      <div>
        <div className="flex flex-col !gap-2">
          <div>{state.strength}</div>

          <Input
            type="password"
            name="passwordA"
            value={passwordA}
            onChange={handleUpdate}
            placeholder="Please enter new password"
          />

          <Input
            type="password"
            name="passwordB"
            value={passwordB}
            onChange={handleUpdate}
            placeholder="Please confirm your password"
          />
        </div>

        {ia(state.messages) && (
          <ul>
            {ia(state.messages) &&
              state.messages.map((v, i) => (
                <li className={cs('text-xs', v.type)} key={`password-reset-messages-${i}`}>
                  {v.message}
                </li>
              ))}
          </ul>
        )}
      </div>

      <Button
        className="!mt-4"
        text="Reset Password"
        disabled={!state.password_ok}
        onClick={requestSetNewPassword}
      />
    </div>
  );
};

export default AdminPasswordSet;
