import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { archiveClaims, deleteClaims, markClaimPending } from 'api/Claim';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { claimCSV, downloadCSV } from 'lib/helpers/utility';
import { useClaim } from 'lib/hooks/queries/claims/useClaim';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';

import { generatePDF } from '../CMS1500/lib/generatePDF';

import ClaimMdHistory from './ClaimMdHistory';

export const ClaimActions = ({ data }) => {
  const [printPopover, setPrintPopover] = useState(false);
  const [pdfGenerationMode, setPDFGenerationMode] = useState();
  const [csvGenerationMode, setCSVGenerationMode] = useState();
  const [pdfGenerationInProgress, setPDFGenerationInProgress] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const { isOpen, closeModal, openModal } = useModal();

  const {
    data: claimData,
    isFetching,
    isLoading
  } = useClaim({
    params: { id: data?.id },
    dependencies: [data?.id, pdfGenerationMode, csvGenerationMode],
    options: { enabled: !!pdfGenerationMode || !!csvGenerationMode }
  });

  const navigate = useNavigate();
  const { setSelectedRows } = useTableContext();
  const { data: addressData } = usePayerAddress({
    params: { payerId: claim?.payerid, withCount: true },
    dependencies: [claim?.payerid],
    options: { enabled: !!claim && printPopover && !!pdfGenerationMode }
  });

  const address = addressData?.address;
  const claim = claimData?.claim;
  const claimLoading = isLoading || isFetching;

  const onSuccess = () => setSelectedRows([]);

  const { mutate: handleArchive } = useCustomMutation({
    mutationFn: () => archiveClaims(navigate, [data?.id]),
    invalidateQueryKey: ['claims'],
    successMessage: 'Claim archived successfully!',
    errorMessage: 'Failed to update claim!',
    onSuccess
  });

  const { mutate: handleDelete, isLoading: isDeleteLoading } = useCustomMutation({
    mutationFn: () => deleteClaims(navigate, [data.id]),
    invalidateQueryKey: ['claims'],
    successMessage: 'Claim deleted successfully!',
    errorMessage: 'Failed to delete claim!',
    onSuccess
  });

  const { mutate: handleMarkPending } = useCustomMutation({
    mutationFn: () => markClaimPending(navigate, [data.id]),
    invalidateQueryKey: ['claims'],
    successMessage: 'Claim marked pending successfully!',
    errorMessage: 'Failed to mark claim pending!',
    onSuccess
  });

  const handlePDFGeneration = async (options) => {
    if (pdfGenerationInProgress) return;

    setPDFGenerationInProgress(true);
    try {
      await generatePDF(options);
      setPDFGenerationMode(null);
    } catch {
      showAlert({
        color: 'danger',
        message: 'Failed to generate PDF. Please try again.'
      });
    } finally {
      setPDFGenerationInProgress(false);
    }
  };

  useEffect(() => {
    if (pdfGenerationMode && claim) {
      const pdfOptions = {
        claim,
        address,
        withPayerNameAddress: !pdfGenerationMode.includes('WITHOUT_PAYER'),
        withBackground: !pdfGenerationMode.includes('TEXT')
      };
      handlePDFGeneration(pdfOptions);
    }
  }, [address, claim, pdfGenerationMode]);

  useEffect(() => {
    if (csvGenerationMode && claim) {
      const csv = claimCSV(claim);
      downloadCSV(csv, `claim_${claim?.id}.csv`);
      setCSVGenerationMode(false);
    }
  }, [claim, csvGenerationMode]);

  if (!data) return null;

  const handleCMSPrint = async (mode) => {
    const isPrintPopover = mode.includes('WITH_PAYER');
    setPrintPopover(isPrintPopover);
    setPDFGenerationMode(mode);
  };

  const handleDownloadCSV = () => setCSVGenerationMode(true);

  const onDeleteClick = () => setShowDeleteConfirmationModal(true);

  const handleClaimView = () => {
    setSelectedRows([]);
    if (data.id) navigate(`${data?.id}`);
  };

  const options = [
    { label: 'View Claim', onClick: handleClaimView, icon: 'new-eye' },
    data?.state !== 'ready' ? { label: 'Status', onClick: openModal, icon: 'status' } : null,
    {
      label: 'Print',
      icon: 'new-printer',
      children: [
        {
          label: 'CMS1500',
          children: [
            {
              label: 'With payer name and address',
              onClick: () => handleCMSPrint('WITH_PAYER'),
              loading: pdfGenerationMode === 'WITH_PAYER' && claimLoading
            },
            {
              label: 'Without payer name and address',
              onClick: () => handleCMSPrint('WITHOUT_PAYER'),
              loading: pdfGenerationMode === 'WITHOUT_PAYER' && claimLoading
            }
          ]
        },
        {
          label: 'Only Text',
          children: [
            {
              label: 'With payer name and address',
              onClick: () => handleCMSPrint('TEXT_WITH_PAYER'),
              loading: pdfGenerationMode === 'TEXT_WITH_PAYER' && claimLoading
            },
            {
              label: 'Without payer name and address',
              onClick: () => handleCMSPrint('TEXT_WITHOUT_PAYER'),
              loading: pdfGenerationMode === 'TEXT_WITHOUT_PAYER' && claimLoading
            }
          ]
        }
      ]
    },
    {
      label: 'Download CSV',
      onClick: handleDownloadCSV,
      icon: 'new-import',
      loading: csvGenerationMode && claimLoading
    },
    data?.state !== 'pending'
      ? {
          label: 'Mark Pending',
          onClick: handleMarkPending,
          icon: 'new-task'
        }
      : null,
    data?.state !== 'archived'
      ? {
          label: 'Archive',
          onClick: handleArchive,
          icon: 'archive-small'
        }
      : null,
    {
      label: 'Delete',
      onClick: onDeleteClick,
      icon: 'trash'
    }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        icon="new-context-menu-horizontal"
        options={options}
      />
      {showDeleteConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Claim"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this claim?"
          loading={isDeleteLoading}
          handleContinue={handleDelete}
          handleOpen={showDeleteConfirmationModal}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {isOpen && <ClaimMdHistory isOpen={isOpen} onClose={closeModal} claimId={data?.id} />}
    </>
  );
};

export default ClaimActions;
