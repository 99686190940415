import PrintViewHeader from 'components/Patient/quote/components/PrintViewHeader';
import React from 'react';

const InvoicePrintView = ({ practice, patient, content, createdAt }) => {
  return (
    <div className="h-full grow overflow-hidden bg-neutral-200 flex justify-center print:!p-0 print:bg-white">
      <div className="print:!page-break-before flex h-full flex-col bg-white w-full print:w-full ">
        <PrintViewHeader practice={practice} patient={patient} createdAt={createdAt} />

        <div className="flex flex-col gap-[6px] !outline-none print:!mb-0 print:break-inside-auto whitespace-pre-line bg-white w-full">
          {content}
        </div>
      </div>
    </div>
  );
};

export default InvoicePrintView;
