import React, { useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { FormBuilderContextProvider } from 'lib/context/FormBuilder/FormBuilderContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useCustomFormTypes } from 'lib/hooks/queries/customForms/useCustomFormTypes';
import { useFormPackets } from 'lib/hooks/queries/forms/useFormPackets';
import { useForms } from 'lib/hooks/queries/forms/useForms';
import { useMember } from 'lib/hooks/queries/member/useMember';
import usePageTitle from 'lib/hooks/usePageTitle';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TableCounter from 'components/shared/Table/TableCounter';
import Wizard from 'components/shared/Wizard/Wizard';

import AssignToPacket from './components/AssignToPacket/AssignToPacket';
import Builder from './components/Builder/Builder';
import FooterButtons from './components/FooterButtons';
import NameAndType from './components/NameAndType/NameAndType';
import Review from './components/Review/Review';
import ViewForm from './components/ViewForm';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helpers';

const FormBuilder = () => {
  const practice = useRecoilValue(currentPractice);

  return (
    <FormBuilderContextProvider>
      <TableContextProvider
        defaultFilters={DEFAULT_FILTERS}
        cols={defaultColumns(practice?.timezone)}
        name="forms">
        <FormBuilderProcess />
      </TableContextProvider>
    </FormBuilderContextProvider>
  );
};

const FormBuilderProcess = () => {
  usePageTitle('Forms');
  const category = 'forms';
  const [formToView, setFormToView] = useState({});
  const [viewFormModal, setViewFormModal] = useState(false);
  const practice = useRecoilValue(currentPractice);

  const {
    autoSave,
    saveButtonText,
    breadCrumbs,
    setBreadCrumbs,
    formik,
    openModal,
    setOpenModal,
    handleClose,
    debouncedSearchTerm,
    handleSave,
    isFormSavingLoading,
    isEdit,
    practiceId,
    showNavigationButtons,
    handleAutoSave,
    packetId
  } = useFormBuilderContext();

  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const {
    data: formData = {},
    isLoading,
    isFetching
  } = useForms({
    params: {
      withGraphFetched: {
        packets: true
      },
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const { data: membersList } = useMember({});

  const { data: formTypesList } = useCustomFormTypes({});

  const { data: formPackets } = useFormPackets({
    params: {
      practice_id: practiceId,
      withGraphFetched: {
        providers: true,
        services: true,
        allForms: true
      },
      filters: { name: debouncedSearchTerm }
    },
    dependencies: [debouncedSearchTerm]
  });

  const membersOptions = useMemo(() => {
    if (membersList?.members?.length > 0) {
      return membersList.members.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.user_id
      }));
    }
    return [];
  }, [membersList]);

  filters.providers.options = membersOptions;

  const typeOptions = useMemo(() => {
    if (formTypesList?.form_functions?.length > 0) {
      return formTypesList.form_functions.map((f) => ({
        label: f.name,
        value: f.id
      }));
    }
    return [];
  }, [formTypesList]);

  filters.type.options = typeOptions;

  const actionViews = {
    name_and_type: {
      component: <NameAndType formik={formik} currentBreadcrumb="name_and_type" />,
      footer: showNavigationButtons ? (
        <FooterButtons
          buttonText="Go to builder"
          nextTo="builder"
          setBreadCrumbs={setBreadCrumbs}
        />
      ) : (
        <FooterButtons />
      )
    },
    builder: {
      component: <Builder formik={formik} currentBreadcrumb="builder" formTypes={typeOptions} />,
      footer: !isEdit ? (
        <FooterButtons
          buttonText="Complete & Review"
          nextTo="review"
          backTo="name_and_type"
          setBreadCrumbs={setBreadCrumbs}
        />
      ) : (
        <FooterButtons buttonText="Submit" isLastStep setBreadCrumbs={setBreadCrumbs} />
      )
    },
    review: {
      component: <Review currentBreadcrumb="review" />,
      footer: (
        <FooterButtons
          buttonText="Add to a form packet"
          nextTo="assign_to_packet"
          buttonPermissions="form_packets.update"
          backTo="builder"
          showSaveAndExit
          setBreadCrumbs={setBreadCrumbs}
        />
      )
    },
    assign_to_packet: {
      component: (
        <AssignToPacket
          currentBreadcrumb="assign_to_packet"
          formPackets={formPackets}
          packetId={packetId}
        />
      ),
      footer: (
        <FooterButtons
          buttonText="Submit"
          backTo="review"
          isLastStep
          setBreadCrumbs={setBreadCrumbs}
          showSkip
        />
      )
    }
  };

  const getCurrentView = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].component;
  };
  const getCurrentFooter = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].footer;
  };

  const onCellClicked = async (e) => {
    if (e.column.getColId() === 'actions') return;
    if (['actions', '0'].includes(e?.column?.colId)) return;
    setFormToView(e?.data);
    setViewFormModal(true);
  };

  const forms = formData?.forms || [];
  const count = formData?.count || 0;
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title="My Forms">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Button
            text="New Form"
            onClick={() => setOpenModal(true)}
            data-qa="new-form-btn"
            className="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex  h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={forms}
              rowSelection="multiple"
              columnDef={defaultColumns(practice.timezone)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
              onCellClicked={onCellClicked}
              reactiveCustomComponents
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {openModal && (
        <Wizard
          isOpen={openModal}
          handleClose={handleClose}
          handleSaveProgress={handleSave}
          saveProgressLoading={isFormSavingLoading}
          handleAutoSave={handleAutoSave}
          showAutoSave={true}
          showSaveProgress={true}
          title="Form Builder"
          autoSave={autoSave}
          saveButtonText={saveButtonText}
          breadcrumbs={breadCrumbs}
          setBreadCrumbs={setBreadCrumbs}
          footerButtons={getCurrentFooter()}>
          {getCurrentView()}
        </Wizard>
      )}
      {viewFormModal && (
        <ViewForm
          form={formToView}
          setViewFormModal={setViewFormModal}
          viewFormModal={viewFormModal}
        />
      )}
    </ErrorBoundary>
  );
};

export default FormBuilder;
