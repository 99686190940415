import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import { ia, iaRa, keyChanges, optionify } from '/lib/helpers/utility';
import { updatePractice } from '/lib/hooks/queries/practice/usePractice';
import { Honeybadger } from '@honeybadger-io/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { pick } from 'lodash';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';

import { requestApi } from 'api/Api';
import { interimApi } from 'api/InterimApi';

import { useStates } from 'lib/hooks/queries/states/useStates';

import { practiceAddressKeys, practiceKeys } from 'components/admin/pages/Practices/constants';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ErrorRequirements from 'components/shared/Error/Requirements';
import state from 'components/state';

import EditPractice from './edit';

const validator = require('validator');

const getChangedLimits = (formik, limits_usage) =>
  limits_usage
    .filter((limit, index) => {
      const initialValues = formik.initialValues.limits_usage[index];
      return limit.usage_limit !== initialValues.usage_limit || limit.limit !== initialValues.limit;
    })
    .map((limit, index) => {
      const initialValues = formik.initialValues.limits_usage[index];

      limit.usage_limit == initialValues.usage_limit && delete limit.usage_limit;
      limit.limit == initialValues.limit && delete limit.limit;
      return limit;
    });

// Kind of practice
const kind = [
  {
    value: 'medical',
    label: 'Medical'
  },
  {
    value: 'dental',
    label: 'Dental'
  }
];

// Status of practice
const status = [
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'inactive',
    label: 'Inactive'
  }
];

const UpdatePracticeValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value && !validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  }),
  fax: Yup.string().test('fax', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value && !validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct fax number.' });
    }

    return true;
  }),
  twilio_phone: Yup.string().test('twilio_phone', '', (value, validationContext) => {
    const { createError } = validationContext;
    if (value && !validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct twilio phone number.' });
    }

    if (value && !value.startsWith('+1')) {
      return createError({ message: 'Twilio number needs to be in a +1xxxxxxxxxxx format.' });
    }

    return true;
  })
});

const EditPracticeContainer = forwardRef(({ practice, handleClose = () => {} }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [address, setAddress] = useState(practice.practiceAddress);
  const [specialtyTypes, setSpecialtyTypes] = useState([]);
  const [professionGroupOptions, setProfessionGroupOptions] = useRecoilState(
    state.professionGroupOptions
  );
  const [requirements, setRequirements] = useState([]);
  const [allowedToUpdate, setAllowedToUpdate] = useState(true);
  const [enroll, setEnroll] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { data: statesData } = useStates();
  const states = statesData?.states;
  const formRef = useRef();

  const initialChecks = async () => {
    try {
      const res = await requestApi({
        url: '/api/faxUser/check_existance',
        params: { practiceId: practice.id },
        navigate
      });

      if (res.code === 0) {
        setEnroll(false);
        if (practice?.e_fax && practice?.e_fax !== '') {
          setAllowedToUpdate(false);
        } else if (!practice?.e_fax) {
          setButtonDisabled(true);
          setAllowedToUpdate(true);
        }
      } else {
        setEnroll(true);
        setAllowedToUpdate(false);
      }
      getSpecialtyTypes();
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: admin/practices/edit_container, method: checkEnrollment - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  useEffect(() => {
    initialChecks();
    loadProfessionGroups();
  }, []);

  const formik = useFormik({
    initialValues: {
      ...practice,
      id: practice?.id,
      name: practice?.name,
      kind: practice?.kind,
      status: practice?.status,
      npi: practice?.npi,
      taxonomy: practice?.taxonomy,
      tax_id: practice?.tax_id,
      tax_id_type: practice?.tax_id_type,
      claim_md_account_key: practice?.claim_md_account_key,
      eligibility_counter: practice?.eligibility_counter,
      email: practice?.email,
      phone: practice?.phone || '',
      fax: practice?.fax || '',
      e_fax: practice?.e_fax,
      twilio_phone: practice?.twilio_phone || '',
      veradigm_license_id: practice?.veradigm_license_id,
      veradigm_office_id: practice?.veradigm_office_id,
      address_ln_1: address?.address_ln_1,
      address_ln_2: address?.address_ln_2,
      city: address?.city,
      state: address?.state,
      zip: address?.zip,
      address_id: address?.id,
      specialty_code: practice.specialty_code,
      monthly_fax_usage_limit: practice?.e_fax_limit?.usage_limit,
      monthly_fax_usage_counter: practice?.e_fax_limit?.usage_counter,
      next_month_fax_limit: practice?.e_fax_limit?.limit,
      monthly_eligibility_usage_limit: practice?.eligibility_limit?.usage_limit,
      monthly_eligibility_usage_counter: practice?.eligibility_limit?.usage_counter,
      next_month_eligibility_limit: practice?.eligibility_limit?.limit,
      usage_limit: practice?.usage_limit,
      profession_group_id: practice?.profession_group_id,
      labsoft_cust_id: practice?.labsoft_cust_id
    },
    enableReinitialize: true,
    validationSchema: UpdatePracticeValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const practiceToBeUpdated = {
        practice: {
          id: values?.id,
          ...keyChanges(pick(practice, practiceKeys), pick(values, practiceKeys))
        },
        address: {
          id: values.address_id,
          ...keyChanges(
            {
              ...pick(address, practiceAddressKeys),
              full_street_address: [
                address?.address_ln_1,
                address?.address_ln_2,
                address?.city,
                address?.state,
                address?.country,
                address?.zip
              ].join(' ')
            },
            {
              ...pick(values, practiceAddressKeys),
              full_street_address: [
                values?.address_ln_1,
                values?.address_ln_2,
                values?.city,
                values?.state,
                values?.country,
                values?.zip
              ].join(' ')
            }
          )
        },
        limits: getChangedLimits(formik, iaRa(values.limits_usage))
      };

      // return;
      setSubmitting(true);
      await handleUpdate(practiceToBeUpdated.practice);
      await submitChanges(
        values?.e_fax,
        practiceToBeUpdated.practice,
        practiceToBeUpdated.address,
        practiceToBeUpdated.limits
      );
      setSubmitting(false);
    }
  });

  const getSpecialtyTypes = async () => {
    try {
      const res = await requestApi({
        url: '/api/codes/benefit_type_code/get',
        params: {},
        navigate
      });

      if (res) {
        setSpecialtyTypes(res.benefit_type_code);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: admin/practices/edit_container, method: getSpecialtyTypes - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
      console.error(error);
    }
  };

  const loadProfessionGroups = async () => {
    try {
      if (professionGroupOptions?.length > 0) {
        return;
      }
      const res = await interimApi('/api/practitioner/profession/list', {}, navigate);
      const { code, redirect, error, professions } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setProfessionGroupOptions(optionify(professions));
          break;
        default:
          if (error) {
            showAlert({ title: error, color: 'danger' });
            Honeybadger.notify(`loadRoles: ${error}`);
          } else Honeybadger.notify(`loadRoles: unexpected error`);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`loadRoles: unexpected error ${error}`);
    }
  };

  const createVeradigmCreds = async () => {
    try {
      const res = await requestApi({
        url: '/api/prescribe/practiceEnroll',
        params: { practiceId: practice.id },
        navigate
      });

      const {
        code,
        redirect,
        error,
        veradigm_license_id,
        veradigm_office_id,
        requirements: loadedRequirements
      } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
          const updatedObject = {
            ...practice,
            veradigm_license_id,
            veradigm_office_id
          };
          await updatePracticeObject(updatedObject);
          break;
        case 3:
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
          break;
        default:
          Honeybadger.notify(
            `file: admin/practices/edit_container, method: createVeradigmCreds - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: admin/practices/edit_container, method: createVeradigmCreds - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const {
    mutateAsync: updatePracticeObject,
    isLoading,
    isError
  } = useMutation(
    (updatedPractice) =>
      updatePractice(navigate, {
        practice_id: practice.id,
        changes: updatedPractice
      }),
    {
      onSuccess: () => {
        showAlert({ title: 'Practice updated successfully', color: 'success' });
        handleClose();
        queryClient.invalidateQueries(['practice']);
        queryClient.invalidateQueries(['practices']);
      }
    }
  );

  const { mutate: updateEFaxNumber } = useMutation(async (newEFaxNumber) => {
    try {
      if (!allowedToUpdate) {
        return;
      }
      const response = await requestApi({
        url: '/api/faxUser/update',
        params: {
          practiceId: practice.id,
          faxNumber: newEFaxNumber
        }
      });

      if (response.code === 0) {
        queryClient.invalidateQueries(['practice']);
      } else {
        throw new Error('Failed to update e-fax number');
      }
    } catch (error) {
      console.error('Error updating e-fax number:', error);
      toast.error(`An unexpected error occurred while updating the e-fax number.`);
    }
  });

  const { mutate: updateAddressObject } = useMutation(
    (updatedAddress) =>
      requestApi({
        url: '/api/address/patch',
        params: { loadedAddress: updatedAddress },
        navigate
      }),
    {
      onSuccess: (data) => {
        setAddress(data.address);
      },
      onError: (error) => {
        Honeybadger.notify(
          `file: admin/practices/edit_container, method: updateAddressObject - onError, error: ${
            error ?? 'An unexpected error has occurred.'
          }`
        );
      }
    }
  );

  const handleUpdate = async (updatedPractice) => {
    await updatePracticeObject(updatedPractice);
  };

  const submitChanges = async (e_fax, updatedPractice, updatedAddress, updatedLimits) => {
    try {
      updatedPractice = {
        ...updatedPractice,
        kind: updatedPractice.kind,
        status: updatedPractice.status
      };

      if (ia(updatedLimits)) {
        await requestApi({
          url: 'api/limits/update',
          params: { limits: updatedLimits, practice_id: practice.id },
          navigate
        });
      }
      if (e_fax !== practice.e_fax) {
        if (!allowedToUpdate) {
          showAlert({
            title:
              'You should release your current e-fax number before updating it. Or enroll the practice to e-fax for the first time.',
            color: 'danger'
          });
        } else {
          await updateEFaxNumber(e_fax);
        }
      }
      if (updatedAddress) {
        updateAddressObject(updatedAddress);
      }

      handleClose();
    } catch (error) {
      toast.error(`An unexpected error has occurred. Please try again later.`);
      Honeybadger.notify(
        `file: admin/practices/edit_container, method: submitChanges - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };

  useImperativeHandle(ref, () => ({
    onSubmit: () => handleSubmit(),
    isLoading
  }));

  return (
    <div>
      {states && (
        <EditPractice
          formRef={formRef}
          practiceId={practice.id}
          enroll={enroll}
          setEnrroll={setEnroll}
          buttonDisabled={buttonDisabled}
          setButtonDisabled={setButtonDisabled}
          setAllowedToUpdate={setAllowedToUpdate}
          formik={formik}
          formikHandle={formik.handleChange}
          kind={kind}
          status={status}
          stateList={states}
          specialtyTypes={optionify(specialtyTypes, 'name', 'code')}
        />
      )}
      {!practice.veradigm_office_id && (
        <div className="mt-[1rem]">
          <Button
            color="primary"
            onClick={createVeradigmCreds}
            text="Create Veradigm License & Office"
          />
          <ErrorRequirements
            requirements={requirements}
            title="Note: ePrescribe Requirements not met, please fulfill the requirements to enroll this practice."
            className="w-full"
          />
        </div>
      )}
    </div>
  );
});

export default EditPracticeContainer;
