const { io, iaRa, ia, isEmpty, stripHtmlTags } = require('lib/helpers/utility');
const { cleanAndSetHTML } = require('./aggregatedDataHelper');

exports.createFamilyHistoryNarrative = (familyHistory) => {
  let narrative = '';

  if (!io(familyHistory) || familyHistory?.noDetails) {
    return 'No notable family medical history.';
  }

  iaRa(familyHistory?.row).forEach((item) => {
    if (!isEmpty(narrative)) {
      narrative += '\n\n';
    }

    const medicalCondition = item.medicalCondition?.label;

    if (medicalCondition) {
      let familyMembers = [];
      if (item?.motherSide) familyMembers.push("mother's side");
      if (item?.fatherSide) familyMembers.push("father's side");
      if (item?.sibling) familyMembers.push('sibling');

      let familyString = '';
      if (ia(familyMembers)) {
        familyString = familyMembers.join(', ')?.replace(/,(?=[^,]*$)/, ' and');
        familyString = familyString?.replace(/^([^,]*) and ([^,]*)$/, '$1 and $2');
        narrative += `${medicalCondition} is present in the ${familyString}.`;
      } else {
        narrative += `${medicalCondition} is present in the family history.`;
      }

      if (!isEmpty(stripHtmlTags(item?.note))) {
        narrative += `\n`;
        narrative += `${cleanAndSetHTML(item.note)}`;
      }
    }
  });

  return narrative.trim() || 'No family medical history provided.';
};

exports.createFamilyHistoryNarrativeV2 = (familyHistory) => {
  if (!ia(familyHistory)) {
    return '<p>No family medical history recorded.</p>';
  }

  let narrative = [];

  iaRa(familyHistory).forEach((item) => {
    const medicalCondition = item.medicalCondition?.label;

    if (medicalCondition) {
      let currentNarrative = '<p>';

      let familyMembers = [];
      if (item?.motherSide) familyMembers.push("mother's side");
      if (item?.fatherSide) familyMembers.push("father's side");
      if (item?.sibling) familyMembers.push('sibling');

      let familyString = '';
      if (ia(familyMembers)) {
        familyString = familyMembers.join(', ')?.replace(/,(?=[^,]*$)/, ' and');
        familyString = familyString?.replace(/^([^,]*) and ([^,]*)$/, '$1 and $2');
        currentNarrative += `${medicalCondition} is present in the ${familyString}.`;
      } else {
        currentNarrative += `${medicalCondition} is present in the family history.`;
      }

      currentNarrative += '</p>'; // Close the main condition paragraph

      if (!isEmpty(stripHtmlTags(item?.note))) {
        currentNarrative += `<p>Note: ${cleanAndSetHTML(item.note)}</p>`;
      }

      narrative.push(currentNarrative);
    }
  });

  return narrative.length > 0
    ? narrative.join('<br>')
    : '<p>No family medical history provided.</p>';
};

// exports.createFamilyHistoryNarrativeV2 = (familyHistory) => {
//   if (!ia(familyHistory)) {
//     return 'No family medical history recorded.';
//   }

//   let narrative = '';

//   iaRa(familyHistory).forEach((item) => {
//     if (!isEmpty(narrative)) {
//       narrative += '\n\n';
//     }

//     const medicalCondition = item.medicalCondition?.label;

//     if (medicalCondition) {
//       let familyMembers = [];
//       if (item?.motherSide) familyMembers.push("mother's side");
//       if (item?.fatherSide) familyMembers.push("father's side");
//       if (item?.sibling) familyMembers.push('sibling');

//       let familyString = '';
//       if (ia(familyMembers)) {
//         familyString = familyMembers.join(', ')?.replace(/,(?=[^,]*$)/, ' and');
//         familyString = familyString?.replace(/^([^,]*) and ([^,]*)$/, '$1 and $2');
//         narrative += `${medicalCondition} is present in the ${familyString}.`;
//       } else {
//         narrative += `${medicalCondition} is present in the family history.`;
//       }

//       if (!isEmpty(stripHtmlTags(item?.note))) {
//         narrative += `\n`;
//         narrative += `${cleanAndSetHTML(item.note)}`;
//       }
//     }
//   });

//   return narrative.trim() || 'No family medical history provided.';
// };
