import React from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';
import { io, isEmpty } from 'lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const Progress = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const progress = clinicalNote?.epn?.progress;
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(progress)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Progress</h3>
      {isTemplateChecked && (
        <div className="grid grid-cols-3 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-4 first-of-type:!border-t-0">
          {io(progress?.progress) &&
            (progress?.progress.value === 'Other' ? (
              <div className="grid auto-rows-min">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Progress</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {progress?.progressOther}
                </p>
              </div>
            ) : (
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {progress?.progress.value}
              </p>
            ))}
          {io(progress?.frequency) &&
            (progress?.frequency.value === 'Other' ? (
              <div className="grid auto-rows-min">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Frequency</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {progress?.frequencyOther}
                </p>
              </div>
            ) : (
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {progress?.frequency.value}
              </p>
            ))}
          {(progress?.changeTreatmentGoalsOrObjectives ||
            progress?.continueCurrentTherapeuticFocus) && (
            <div className="grid auto-rows-min">
              <p className="text-xs font-500 text-neutral-700 print:text-base">
                Treatment recommendation
              </p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {progress?.changeTreatmentGoalsOrObjectives
                  ? 'Change treatment goals or objectives'
                  : 'Continue current therapeutic focus'}
              </p>
            </div>
          )}
        </div>
      )}

      {isNarrativeChecked && !isEmpty(progress?.narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={progress?.narrative} />
        </div>
      )}
    </div>
  );
};

export default Progress;
