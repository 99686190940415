/* eslint-disable max-lines */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState
} from 'react';

import { Popover as HeadlessPopover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { cn, groupAndSort, ia, pm, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';
import { usePopoverContext } from 'components/shared/Popovers/Popover/context/PopoverContext';
import Header from 'components/shared/shared/Header';
import state from 'components/state';

import {
  getButtonClasses,
  getDropdownIconColor,
  getIconColor,
  getSeparatorClasses,
  getTextClasses
} from './lib/getClasses';
import { calculatePopoverPosition } from './lib/position';

/**
 * @type {import('react').ForwardRefExoticComponent<import('./lib/propTypes').PopoverProps & import('react').RefAttributes<HTMLElement>>}
 */

const Popover = forwardRef((props, ref) => {
  const {
    position = 'right', // by default is 'right', means in the right position where the button is
    isFixed = false,
    options = [],
    icon = 'new-context-menu-dots',
    iconSize = 16,
    iconClassName = '',
    iconRight = '',
    iconRightColor,
    iconRightSize = 16,
    iconsShade = 500,
    iconRightClassName = '',
    optionIconSize = 16,
    optionIconColor = 'primary',
    optionActiveIcon = 'new-check', // if options is nested the icon will not be shown
    optionsGroupBy,
    optionGroupLabelClassName = '',
    text = '',
    loading = false,
    disabled,
    className = '',
    buttonClassName = '',
    buttonTheme = null,
    buttonThemeType = 'secondary',
    textClassName = '',
    panelClassName = '',
    innerWrapperClassName = '',
    activeClassNames = {},
    isDropdown = false,
    isSeparator = false,
    isBorder = false,
    rerender = '',
    dataQa = 'popover-button',
    id = null,
    onScroll = () => {},
    panelRef: outsidePanelRef = null,
    children,
    tooltip,
    labelTooltip,
    isTooltipDark = true,
    tooltipPlacement = 'bottom',
    customButton,
    customButtonTrigger,
    ...headerProps
  } = props;

  let panelRef = useRef(null);
  const buttonRef = useRef(null);
  const timeoutRef = useRef(null);
  const activeOptionRef = useRef(null);

  const popoverContext = usePopoverContext();
  const permissions = useRecoilValue(state.permissions);

  const [isOpen, setIsOpen] = useState(false);
  const [hoveredIndexes, setHoveredIndexes] = useState({});
  const [panelFixedPosition, setPanelFixedPosition] = useState({});

  if (outsidePanelRef) panelRef = outsidePanelRef;

  // calculate popover position
  const updatePopoverPosition = useCallback(() => {
    if (!isFixed || !buttonRef.current || !panelRef?.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const panelRect = panelRef.current.getBoundingClientRect();
    const panelWidth = panelRef.current.getBoundingClientRect()?.width || 140;
    const newPosition = calculatePopoverPosition({
      buttonRect,
      panelRect,
      panelWidth,
      position
    });

    setPanelFixedPosition(newPosition);
  }, [isOpen, panelRef?.current, rerender]);

  // update the popover position when the panelRef is updated or rerender is triggered
  useLayoutEffect(() => {
    updatePopoverPosition();
  }, [rerender, panelRef?.current]);

  // update the popover position when the window is resized or scrolled
  useLayoutEffect(() => {
    if (isFixed && panelRef?.current) {
      const handleResizeOrScroll = (event) => {
        // Check if the scroll event is from within the popover

        if (
          panelRef?.current &&
          event?.target instanceof Node &&
          (panelRef?.current?.contains(event.target) ||
            event.target.classList.contains('select__menu-list')) // react select menu
        )
          return;

        updatePopoverPosition();
      };

      window.addEventListener('resize', handleResizeOrScroll);
      window.addEventListener('scroll', handleResizeOrScroll, true);

      return () => {
        window.removeEventListener('resize', handleResizeOrScroll);
        window.removeEventListener('scroll', handleResizeOrScroll, true);
      };
    }
  }, [updatePopoverPosition]);

  // scroll to the active option when the popover is open
  useLayoutEffect(() => {
    if (isOpen && activeOptionRef.current && panelRef?.current) {
      setTimeout(() => {
        const activeOption = activeOptionRef.current;

        if (activeOption) {
          activeOption.scrollIntoView({
            block: 'center',
            behavior: 'smooth'
          });
        }
      }, 300);
    }
  }, [isOpen]);

  // expose the buttonRef to the parent component
  useImperativeHandle(ref, () => ({
    buttonRef
  }));

  const handleMouseEnter = (level, index) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setHoveredIndexes((prev) => {
      if (prev[level] !== index) {
        return { ...prev, [level]: index };
      }
      return prev;
    });
  };

  const handleMouseLeave = (level) => {
    timeoutRef.current = setTimeout(() => {
      setHoveredIndexes((prev) => ({
        ...prev,
        [level]: null
      }));
    }, 500);
  };

  const handleOptionClick = (option, handleClose, event) => {
    if (option.onClick) {
      event.stopPropagation();
      option.onClick(popoverContext?.context);
      handleClose();
      setIsOpen(false);
    }
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
    setHoveredIndexes({});
    // Wrap updatePopoverPosition in a timeout to ensure it runs after state updates
    setTimeout(() => {
      if (panelRef?.current) {
        updatePopoverPosition();
      }
    }, 0);
  };

  const onPopoverClose = () => {
    setIsOpen(false);
    setHoveredIndexes({});
  };

  const renderOptions = (options, level = 1, handleClose) => {
    if (level === 1 && optionsGroupBy) {
      const groupedOptions = groupAndSort(options, optionsGroupBy);

      return groupedOptions.map(([groupName, groupOptions], groupIndex) => (
        <div className="flex flex-col" key={groupIndex}>
          <span
            className={cs(
              'px-4 py-1 text-xs font-500 leading-[14px] text-primary-500',
              optionGroupLabelClassName
            )}>
            {snakeToTitleCase(groupName)}
          </span>
          <div className="flex flex-col gap-1">
            {groupOptions.map((option, index) => renderOption(option, index, level, handleClose))}
          </div>
        </div>
      ));
    } else {
      return options.map((option, index) => renderOption(option, index, level, handleClose));
    }
  };

  const renderOption = (option, index, level, handleClose) => {
    if (option?.permission) {
      if (
        option?.permission?.isAny &&
        !pm(permissions, option?.permission?.requiredPermissions, 'or')
      ) {
        return null;
      }
      if (!pm(permissions, option?.permission?.requiredPermissions)) {
        return null;
      }
    }
    const label = option.label || option.title; //title is used for the old popover options
    const isActive =
      label === text || (customButton && option.value === customButton?.props?.value);

    return (
      <div
        key={index}
        ref={isActive ? activeOptionRef : null}
        tabIndex={isActive ? 0 : -1}
        className={cs(
          'relative flex items-center justify-between gap-x-2 py-[6px] !pl-4 hover:bg-primary-50',
          option.onClick && !ia(option.children) ? 'cursor-pointer pr-4' : 'cursor-default pr-2',
          isActive && 'bg-primary-50 !pr-2'
        )}
        data-qa={`popover-option-${spaceToKebabCase(label)}`}
        onMouseEnter={() => option.children && !option.disabled && handleMouseEnter(level, index)}
        onMouseLeave={() => option.children && !option.disabled && handleMouseLeave(level)}
        onClick={(event) => !option.disabled && handleOptionClick(option, handleClose, event)}>
        {option.disabled && (
          <div className="cursor-not-allowed absolute inset-0 z-[100] bg-white opacity-40"></div>
        )}
        {(option.loading || option.icon || label) && (
          <div className={cs('flex grow items-center gap-x-2', option.className)}>
            {option.loading ? (
              <Loader
                outlined
                className={`text-${optionIconColor}-500`}
                type={hoveredIndexes[level] === index ? 'secondary' : 'primary'}
              />
            ) : typeof option.icon === 'object' ? (
              option.icon
            ) : (
              option.icon && (
                <Icon
                  icon={option.icon}
                  size={optionIconSize}
                  color={option.color || optionIconColor}
                  shade={iconsShade}
                  stroke={option.stroke}
                />
              )
            )}
            <span className="grow select-none whitespace-nowrap text-sm font-400 text-primary-900">
              {label}
            </span>
            {option.rightIcon && (
              <Icon
                icon={option.rightIcon}
                size={optionIconSize}
                color={option.color || optionIconColor}
                shade={iconsShade}
                stroke={option.stroke}
              />
            )}
          </div>
        )}
        {typeof option.component === 'object' ? (
          option.component
        ) : option.children ? (
          <Icon
            icon="new-chevron-right"
            size={optionIconSize}
            className="ml-auto"
            color={optionIconColor}
            shade={iconsShade}
            stroke
          />
        ) : (
          optionActiveIcon &&
          isActive && (
            <Icon
              icon={optionActiveIcon}
              size={19}
              className="ml-auto"
              color={optionIconColor}
              shade={iconsShade}
              stroke
            />
          )
        )}
        {option.children && hoveredIndexes[level] === index && (
          <div
            className={cs(
              'absolute -top-[14px] z-50 mt-[6px] grid min-w-[120px] rounded-md bg-white py-2 shadow-[0px_2px_16px_0px_rgba(0,79,107,0.2)]',
              option.position === 'left'
                ? 'right-full'
                : option.position === 'right'
                  ? 'left-full'
                  : position === 'left'
                    ? 'right-full'
                    : 'left-full'
            )}>
            {renderOptions(option.children, level + 1, handleClose)}
          </div>
        )}
      </div>
    );
  };

  const renderCustomButton = (open, setIsOpen) => {
    if (typeof customButton === 'function') {
      return customButton({ open, setIsOpen });
    }
    return customButton;
  };

  const renderCustomButtonTrigger = (open, setIsOpen) => {
    if (typeof customButtonTrigger === 'function') {
      return customButtonTrigger({ open, setIsOpen });
    }
    return customButtonTrigger;
  };

  const renderChildren = (close) => {
    if (ia(options)) {
      return renderOptions(options, 1, close);
    }
    if (typeof children === 'function') {
      return (
        <div className="flex flex-col overflow-hidden">{children({ closePopover: close })}</div>
      );
    }
    return <div className="flex flex-col overflow-hidden">{children}</div>;
  };

  return (
    <HeadlessPopover id={id} className={cs('w-fit', className)} onClose={onPopoverClose}>
      {({ open, close }) => (
        <>
          <Header {...headerProps} transcribing={false} tooltip={labelTooltip} />
          <div className={cs('relative', innerWrapperClassName)}>
            <Tippy
              disabled={!tooltip}
              content={tooltip}
              placement={tooltipPlacement}
              className={isTooltipDark && 'tippy-dark'}>
              {customButton ? (
                <div className="flex items-center gap-x-2">
                  {renderCustomButton(open, setIsOpen)}
                  <PopoverButton
                    ref={buttonRef}
                    onClick={handleButtonClick}
                    data-qa={dataQa}
                    className="p-0">
                    {renderCustomButtonTrigger(open, setIsOpen)}
                  </PopoverButton>
                </div>
              ) : (
                <PopoverButton
                  ref={buttonRef}
                  onClick={handleButtonClick}
                  data-qa={dataQa}
                  className={getButtonClasses({
                    open,
                    buttonTheme,
                    text,
                    isSeparator,
                    isBorder,
                    buttonClassName,
                    activeClassNames,
                    disabled,
                    buttonThemeType
                  })}
                  disabled={disabled}>
                  {loading ? (
                    <Loader color={buttonTheme} shade={open ? 200 : 50} className="mr-1" />
                  ) : typeof icon === 'object' ? (
                    <div className="mr-[6px]">{icon}</div>
                  ) : (
                    icon && (
                      <Icon
                        icon={icon}
                        size={iconSize}
                        color={getIconColor({ disabled, open, buttonTheme, buttonThemeType })}
                        shade={iconsShade}
                        className={cs(
                          iconClassName,
                          icon !== 'new-context-menu-dots' && text && 'mr-[6px]',
                          disabled && '!cursor-not-allowed'
                        )}
                      />
                    )
                  )}
                  {typeof text === 'string' ? (
                    <span
                      className={getTextClasses({
                        open,
                        buttonTheme,
                        textClassName,
                        activeClassNames
                      })}
                      title={text}>
                      {text}
                    </span>
                  ) : (
                    text && text
                  )}
                  {iconRight && !isDropdown && (
                    <Icon
                      icon={iconRight}
                      size={iconRightSize}
                      color={
                        buttonTheme ??
                        (activeClassNames?.icon && open
                          ? activeClassNames?.icon
                          : open
                            ? 'white'
                            : '')
                      }
                      shade={iconsShade}
                      className={iconRightClassName}
                    />
                  )}
                  {isSeparator && (
                    <hr className={getSeparatorClasses({ buttonTheme, buttonThemeType })} />
                  )}
                  {isDropdown && (
                    <Icon
                      icon="new-chevron-down"
                      size={iconRightSize}
                      color={getDropdownIconColor({
                        open,
                        buttonTheme,
                        buttonThemeType,
                        iconRightColor,
                        activeClassNames
                      })}
                      shade={iconsShade}
                      className={cs('ml-1 duration-150', open && 'rotate-180')}
                    />
                  )}
                </PopoverButton>
              )}
            </Tippy>
            <PopoverPanel
              ref={panelRef}
              portal={isFixed}
              onScroll={onScroll}
              className={cn(
                'min-w-[120px] max-w-[712px] max-h-[95dvh] flex flex-col gap-y-2 py-2 z-[100] rounded-lg bg-white shadow-[0px_2px_16px_0px_rgba(0,79,107,0.2)]',
                panelClassName,
                !isFixed && 'absolute mt-[6px]',
                !isFixed && position === 'left' ? 'right-0' : 'left-0',
                !ia(options) && 'overflow-hidden'
              )}
              style={isFixed ? panelFixedPosition : {}}>
              {renderChildren(close)}
            </PopoverPanel>
          </div>
        </>
      )}
    </HeadlessPopover>
  );
});

export default Popover;
