import cs from 'classnames';

import Icon from '../../Icon/Icon';

const SelectWrapper = ({
  icon,
  rightIcon,
  rightIconSize,
  rightIconColor,
  rightIconShade,
  rightIconClick,
  id,
  disabled,
  children
}) => {
  const rightIconFn = () => (
    <Icon
      icon={rightIcon}
      size={rightIconSize}
      data-qa="new-tags-btn"
      color={rightIconColor}
      shade={rightIconShade}
      onClick={(e) => rightIconClick(e)}
      className={cs(
        'absolute right-[10px] cursor-pointer',
        !rightIconClick && 'pointer-events-none'
      )}
    />
  );

  return (
    <div className={cs('relative flex items-center', disabled && 'cursor-not-allowed')}>
      {typeof icon === 'object'
        ? icon
        : icon && (
            <Icon
              icon={icon}
              size="20px"
              htmlFor={id}
              className={cs(
                'absolute left-3 top-1/2 z-10 -translate-y-1/2',
                disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
              )}
            />
          )}
      {children}
      {!!rightIcon && rightIconFn()}
    </div>
  );
};

export default SelectWrapper;
