import React from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const Answers = ({ answer, isChecked, handleClick, index }) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div
        key={answer?.value}
        onClick={() => handleClick(answer?.value)}
        className="grid cursor-pointer select-none grid-cols-[34px,auto] items-center gap-2 rounded-[10px] border-solid border-neutral-100 bg-white !p-4">
        <Checkbox id={index} key={answer?.id} isChecked={isChecked} />
        <div>
          <p className="pb-1 text-sm font-500 text-primary-900">{answer?.title}</p>
          <p className="text-[12px] font-400 text-neutral-600">{answer?.description}</p>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(Answers);
