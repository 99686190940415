import React, { useEffect } from 'react';
import MultiStepForm from './form/MultiStepForm';
import { useOrganizationContext } from 'lib/context/OrganizationContext/OrganizationContext';
import Select from 'components/shared/Select/Select';
import { useQueryClient } from '@tanstack/react-query';
import { ia } from 'lib/helpers/utility';

const DataSelector = ({ passedLocations }) => {
  const {
    setSelectedOrganization,
    setSelectedOwner,
    selectedOrganization,
    selectedLocation,
    setSelectedLocation,
    dispatch
  } = useOrganizationContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (selectedOrganization?.locations?.length === 1) {
      handleOnChange({
        value: selectedOrganization.locations[0].id,
        label: selectedOrganization.locations[0].name
      });
    }
  }, [selectedOrganization]);

  useEffect(() => { }, []);

  const handleOnChange = (e) => {
    setSelectedLocation(e);
    dispatch({ type: 'RESET_All_DATA' });
    queryClient.invalidateQueries(['locationImportData']);
  };

  const locationOpt = ia(passedLocations)
    ? passedLocations
    : selectedOrganization?.locations?.map((loc) => ({ value: loc.id, label: loc.name }));

  return (
    <div className="w-full !py-2 px-4">
      <div
        className="!mb-2 cursor-pointer text-primary-900"
        onClick={() => {
          setSelectedOrganization();
          setSelectedOwner();
        }}>
        Go Back
      </div>
      <div className="flex w-full items-center gap-2 ">
        <h3 className="!text-primary-700">Select Location</h3>
        <Select
          options={locationOpt}
          onChange={handleOnChange}
          value={selectedLocation}
          parentClassName="w-1/2"
          placeholder="Type and select Location"
        />
      </div>
      {!selectedLocation ? (
        <div className="text-primary-900">Please select a location to proceed</div>
      ) : (
        <MultiStepForm key={selectedLocation?.id} />
      )}
    </div>
  );
};

export default DataSelector;
