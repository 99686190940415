import { formatDate } from 'lib/helpers/utility';
import moment from 'moment';

export default {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of service',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  superbill_id: {
    type: 'search',
    values: '',
    title: 'Superbill ID',
    placeholder: 'Search by superbill ID'
  },
  patient_searchTerm: {
    type: 'search',
    values: '',
    title: 'Patient',
    placeholder: 'Search by patient name'
  },
  provider_searchTerm: {
    type: 'search',
    values: '',
    title: 'Rendering Provider',
    placeholder: 'Search by provider name'
  },
  billing_provider_searchTerm: {
    type: 'search',
    values: '',
    title: 'Billing Provider',
    placeholder: 'Search by billing provider'
  },
  payer_searchTerm: {
    type: 'search',
    values: '',
    title: 'Payer',
    placeholder: 'Search by payer name'
  }
};
