import React from 'react';
import { ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import { nameFormatOptions, dateFormats, currencyFormats } from '../lib/constants';
import selectedHeaders from '../lib/selectedHeader';
import cs from 'classnames';
import { AlertContent } from 'components/shared/Alert/Alert';
import Select from 'components/shared/Select/Select';

const SubmitFile = ({
  file,
  show,
  headers,
  headerValue,
  patientDetailsFormat,
  setPatientDetailsFormat,
  columnHeaderOptions,
  setShow,
  setHeaderValue,
  setColumnHeaderOptions,
  handleUploadFile,
  dobFormatRef,
  nameFormatRef,
  policyStartDateFormatRef,
  policyEndDateFormatRef,
  providerNameFormatRef,
  transactionAmountFormatRef,
  transactionAmountSelfFormatRef
}) => {
  const handleHeaderSelect = async (e, index) => {
    await selectedHeaders({
      e,
      index,
      headerValue,
      show,
      setHeaderValue,
      setColumnHeaderOptions,
      setShow,
      dobFormatRef,
      nameFormatRef,
      policyStartDateFormatRef,
      policyEndDateFormatRef,
      providerNameFormatRef,
      transactionAmountFormatRef,
      transactionAmountSelfFormatRef
    });
  };

  const onChangeHandler = (event, key, selectRef, index) => {
    setPatientDetailsFormat((prevState) => ({
      ...prevState,
      [key]: event
    }));
    selectRef.current = event;
    setTimeout(() => handleHeaderSelect(headerValue[index], index), 100);
  };

  return (
    <div className="border-none rounded-lg p-2 flex flex-col mx-auto my-auto justify-center h-fit w-fit bg-white overflow-auto transition duration-300">
      <span className="text-xl font-bold text-center py-1">Patient Import</span>
      <span className="text-l font-bold text-center py-1">Step 3/4</span>
      {ia(show.messages) && (
        <div className="flex flex-col self-center">
          <div>
            {show.messages.map((v, index) => (
              <AlertContent
                key={index}
                message={v.message}
                color={cs(
                  v.className === 'warning'
                    ? 'warning'
                    : v.className === 'success'
                      ? 'success'
                      : v.className === 'error'
                        ? 'danger'
                        : 'primary'
                )}
              />
            ))}
          </div>
        </div>
      )}
      <div className="flex flex-col min-h-[20rem] h-60vh min-w-[20rem] w-50vw overflow-auto">
        <table>
          <thead>
            <tr>
              <td>Name</td>
              <td>Match</td>
              <td>Example 1</td>
              <td>Example 2</td>
              <td>Example 3</td>
            </tr>
          </thead>
          <tbody>
            {ia(headers) &&
              headers.map((v, i) => {
                let hint = null;
                switch (headerValue[i]?.value) {
                  case 'dob':
                    if (!patientDetailsFormat?.dobFormat?.value) {
                      hint = 'Please select a DOB format';
                    }
                    break;
                  case 'name':
                    if (!patientDetailsFormat?.patientName?.value) {
                      hint = 'Please select Name format';
                    }
                    break;
                  case 'insurance_policy_start_date':
                    if (!patientDetailsFormat?.policyStartDateFormat?.value) {
                      hint = 'Please select PSD format';
                    }
                    break;
                  case 'insurance_policy_end_date':
                    if (!patientDetailsFormat?.policyEndDateFormat?.value) {
                      hint = 'Please select PED format';
                    }
                    break;
                  case 'appointment_provider':
                    if (!patientDetailsFormat?.providerName?.value) {
                      hint = 'Please select Name format';
                    }
                    break;
                  case 'transaction_amount':
                    if (!patientDetailsFormat?.transactionAmountFormat?.value) {
                      hint = 'Please select a currency format';
                    }
                    break;
                  case 'transaction_amount_paid_self':
                    if (!patientDetailsFormat?.transactionAmountSelfFormat?.value) {
                      hint = 'Please select a currency format';
                    }
                    break;
                  default:
                    hint = headerValue[i]?.message || '';
                    break;
                }

                return (
                  <tr key={`patient-import-header-entry-${i}`}>
                    <td>{v}</td>
                    <td>
                      <div style={{ minWidth: '12rem' }}>
                        {hint && (
                          <span className="border-none rounded-md m-1 p-1.5 text-black bg-red-300">
                            {hint}
                          </span>
                        )}
                        <Select
                          isClearable={true}
                          options={columnHeaderOptions}
                          onChange={(e) => handleHeaderSelect(e, i)}
                          value={headerValue[i] || null}
                        />
                        {headerValue[i]?.value === 'dob' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.dobFormat}
                            options={dateFormats}
                            onChange={(e) => onChangeHandler(e, 'dobFormat', dobFormatRef, i)}
                            placeholder={`Type how birthday appears in the table`}
                            required={true}
                          />
                        )}
                        {headerValue[i]?.value === 'name' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.patientName}
                            options={nameFormatOptions}
                            onChange={(e) => onChangeHandler(e, 'patientName', nameFormatRef, i)}
                            placeholder={`Please enter order of name in the field`}
                            required={true}
                          />
                        )}
                        {headerValue[i]?.value === 'insurance_policy_start_date' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.policyStartDateFormat}
                            options={dateFormats}
                            onChange={(e) =>
                              onChangeHandler(
                                e,
                                'policyStartDateFormat',
                                policyStartDateFormatRef,
                                i
                              )
                            }
                            placeholder={`Type how policy start date appears in the table`}
                            required={true}
                          />
                        )}

                        {headerValue[i]?.value === 'insurance_policy_end_date' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.policyEndDateFormat}
                            options={dateFormats}
                            onChange={(e) =>
                              onChangeHandler(e, 'policyEndDateFormat', policyEndDateFormatRef, i)
                            }
                            placeholder={`Type how policy end date appears in the table`}
                            required={true}
                          />
                        )}

                        {headerValue[i]?.value === 'appointment_provider' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.providerName}
                            options={nameFormatOptions}
                            onChange={(e) =>
                              onChangeHandler(e, 'providerName', providerNameFormatRef, i)
                            }
                            placeholder={`Please enter order of name in the field`}
                            required={true}
                          />
                        )}

                        {headerValue[i]?.value === 'transaction_amount' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.transactionAmountFormat}
                            options={currencyFormats}
                            onChange={(e) =>
                              onChangeHandler(
                                e,
                                'transactionAmountFormat',
                                transactionAmountFormatRef,
                                i
                              )
                            }
                            placeholder={`Please enter currency format`}
                            required={true}
                          />
                        )}
                        {headerValue[i]?.value === 'transaction_amount_paid_self' && (
                          <Select
                            isClearable={true}
                            value={patientDetailsFormat?.transactionAmountSelfFormat}
                            options={currencyFormats}
                            onChange={(e) =>
                              onChangeHandler(
                                e,
                                'transactionAmountSelfFormat',
                                transactionAmountSelfFormatRef,
                                i
                              )
                            }
                            placeholder={`Please enter currency format`}
                            required={true}
                          />
                        )}
                      </div>
                    </td>
                    <td>{file.sampleEntries[0][i]}</td>
                    <td>{file.sampleEntries[1][i]}</td>
                    <td>{file.sampleEntries[2][i]}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="h-20 p-1 flex flex-row justify-around">
        <Button
          text="Back"
          onClick={() => setShow({ ...show, stage: 'uploadFile' })}
          color="primary"
        />

        <Button
          text="Upload"
          onClick={handleUploadFile}
          loading={show?.loading}
          disabled={show?.holds}
          color="success"
        />
      </div>
    </div>
  );
};

export default SubmitFile;
