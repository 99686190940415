import React from 'react';
import { getActionDefinition } from './lib/helper';

const Dependents = ({ permissions, dependents }) => {
  return (
    <div className="mt-3 grid border first:rounded-t-md last:rounded-b-md">
      {dependents.length > 0 &&
        dependents?.map((dependencyField, index) => {
          const dependency = getActionDefinition(permissions, dependencyField);
          return (
            <div
              key={index}
              className={`flex w-full grid-cols-2 items-center justify-between border-[1px] border-solid border-danger-400 bg-danger-50 px-4 py-3 ${
                index === 0 ? 'rounded-t-md' : ''
              } ${index === dependents?.length - 1 ? 'rounded-b-md' : ''}`}>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-600 leading-snug text-primary-900">
                  {dependency.title}
                </div>
                <div className="text-xs font-normal leading-tight text-neutral-700">
                  {dependency.description}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Dependents;
