import React from 'react';

import moment from 'moment-timezone';

import { formatLargeNumber, ia, snakeToTitleCase } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';

import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';

const AllTransactions = ({ data }) => {
  const { dashboardData, item, layout } = data;

  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const formattedData = dashboardData?.transactionGraphData
    ? Object.entries(dashboardData?.transactionGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const localDate = new Date(momentTime.valueOf());
        return {
          timestamp: localDate,
          COF: parseFloat(data.paymentType.COF),
          check: parseFloat(data.paymentType.check),
          cash: parseFloat(data.paymentType.cash),
          CARD_PRESENT: parseFloat(data.paymentType.CARD_PRESENT),
          SWIPE: parseFloat(data.paymentType.SWIPE),
          EMV_CONTACT: parseFloat(data.paymentType.EMV_CONTACT),
          care_credit: parseFloat(data.paymentType.care_credit),
          UNKNOWN: parseFloat(data.paymentType.UNKNOWN),
          ONLINE: parseFloat(data.paymentType.ONLINE),
          IN_PROGRESS: parseFloat(data.paymentType.IN_PROGRESS),
          EMV_PROXIMITY: parseFloat(data.paymentType.EMV_PROXIMITY)
        };
      })
    : [];
  function getColorForStatus(status) {
    switch (status) {
      case 'COF':
        return '#00ABE8';
      case 'check':
        return '#AA336A';
      case 'cash':
        return '#A52A2A';
      case 'CARD_PRESENT':
        return '#50C878';
      case 'SWIPE':
        return '#702963';
      case 'EMV_CONTACT':
        return '#FFBF00';
      case 'care_credit':
        return '#00CED1';
      case 'UNKNOWN':
        return '#F59E0B';
      case 'ONLINE':
        return '#FF4500';
      case 'IN_PROGRESS':
        return '#F59E0B';
      case 'EMV_PROXIMITY':
        return '#FFA07A ';
      default:
        return '#000000';
    }
  }
  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },
    series:
      Object.entries(dashboardData?.count?.transactions || {}).map(([status, count]) => ({
        type: 'line',
        xKey: 'timestamp',
        yKey: status,
        yName: `${snakeToTitleCase(status)} (${formatLargeNumber(count)}$)`,
        stroke: getColorForStatus(status),
        marker: {
          size: 6,
          fill: getColorForStatus(status),
          stroke: getColorForStatus(status)
        }
      })) || [],
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };
  return (
    <div className="mt-5">
      {ia(options?.data) ? (
        <AGChart options={options} />
      ) : (
        <>
          <NotFound
            icon="new-illustration-6"
            title="You don't have transactions by this payment type yet!"
          />
        </>
      )}
    </div>
  );
};

export default AllTransactions;
