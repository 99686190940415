import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import paymentPattern from 'assets/Patterns/payment_pattern.svg';
import { showAlert } from 'components/shared/Alert/Alert';
import ImagePicker from 'components/shared/Picker/ImagePicker';

import { detectDocument, processDocument } from 'api/Document';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import CheckinLoginWarning from './LoginWarning';
import { propertyPathMap } from './constants';
import state from './state';
import { useQueryClient } from '@tanstack/react-query';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const PersonalID = () => {
  const { updateSteps, checkinButton, appointmentId, kiosk, user } = useOutletContext();
  const [valid, setValid] = useState(false);
  const [patient, setPatient] = useState({
    personal_id_image: {
      front: user?.personal_id_image?.front || '',
      back: user?.personal_id_image?.back || ''
    }
  });

  const [show, setShow] = useState({ optionSelected: false, modified: false });
  const selections = useRecoilValue(state.selectionState);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [imageEdit, setImageEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState({
    front: patient?.personal_id_image && patient?.personal_id_image?.front,
    back: patient?.personal_id_image && patient?.personal_id_image?.back
  });

  const [preHandle, setPreHandle] = useState(null);

  const queryClient = useQueryClient();

  const { device } = useUIContext();

  useEffect(() => {
    queryClient.invalidateQueries(['user']);
  }, [user]);

  useEffect(() => {
    if (appointmentId) {
      if (selections?.personal_iden !== null) {
        if (!user.authenticated && !user.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
        }
        setShow((ps) => ({ ...ps, optionSelected: true }));
      }
      validateAppointment();
      getPatient();
    } else {
      setShow((ps) => ({ ...ps, optionSelected: true }));
    }
  }, [appointmentId, selections]);

  const checkPhotoIdsExist = () => {
    patient.personal_id_image?.back && patient.personal_id_image?.front
      ? updateSteps({ formName: propertyPathMap.personalIdImageComplete.name() })
      : updateSteps({
          formName: propertyPathMap.personalIdImageComplete.name(),
          isCompleted: false
        });
  };

  const validateAppointment = async () => {
    try {
      let res = await interimApi(
        '/api/patient/checkin/validate',
        {
          appointmentId
        },
        navigate
      );
      if (res.data) {
        setValid(res.data.isValid);
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getPatient = async () => {
    try {
      let res = await interimApi(
        '/api/patient/checkin/get_patient',
        {
          appointmentId
        },
        navigate
      );
      const { patient: loadedPatient } = res.data;
      if (res.data.patient) {
        setPatient(loadedPatient);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/personal_id, method: getPatient - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  // const saveImage = async (imgHandle, type) => {
  //   try {
  //     setLoading(true);
  //     let newHandle = {
  //       front: patient?.personal_id_image && patient?.personal_id_image?.front,
  //       back: patient?.personal_id_image && patient?.personal_id_image?.back
  //     };
  //     if (type == 'front') {
  //       const document = await detectDocument(navigate, { handle: imgHandle, detection: true });
  //       newHandle.front = document?.handle || imgHandle;
  //     } else {
  //       const document = await detectDocument(navigate, { handle: imgHandle, detection: true });
  //       newHandle.back = document?.handle || imgHandle;
  //     }

  //     await setHandle(newHandle);
  //     setPreHandle(imgHandle);

  //     setLoading(false);
  //   } catch (error) {
  //     Honeybadger.notify(`Error saving ${type} image: ${error}`);
  //     showAlert({
  //       message: 'Uploading image failed. Please try again',
  //       color: 'danger',
  //       position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
  //     });
  //   }
  // };

  const handleContinue = async (handle) => {
    setLoading(true);

    let res = await interimApi(
      `/api/filestack/personal_id_${imageEdit}`,
      {
        handle,
        userId: patient.id,
        appointmentId
      },
      navigate
    );
    if (res.data[imageEdit] > 0) {
      const newObj = { ...patient, personal_id_image: handle };
      setPatient(newObj);
    } else {
      Honeybadger.notify(`There was an error uploading the image.`);
      showAlert({
        message: 'Uploading image failed. Please try again',
        color: 'danger',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
    setImageEdit(null);
    setLoading(false);
  };

  const handleDelete = async (type) => {
    await requestApi({
      url: `/api/filestack/personal_id_${type}`,
      params: {
        handle: { ...patient?.personal_id_image, [type]: null },
        userId: patient?.id
      },
      navigate
    });

    setPatient((prevState) => ({
      ...prevState,
      personal_id_image: {
        ...prevState.personal_id_image,
        [type]: null
      }
    }));
  };

  // if (!show.optionSelected) {
  //   return <PatientCheckinEditingSelector />;
  // }

  const [coords, setCoords] = useState();
  const [processing, setProcessing] = useState({ open: false });

  const handleSave = async (handle, type) => {
    setLoading(true);
 
    let newHandle = {
      front: patient?.personal_id_image && patient?.personal_id_image?.front,
      back: patient?.personal_id_image && patient?.personal_id_image?.back
    };

    newHandle[type] = handle;

    setHandle(newHandle);

    const document = await detectDocument(navigate, {
      handle
    });

    setCoords({ ...coords, [type]: document?.coords });

    setLoading(false);
  };

  const handleProcess = async (image, type) => {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('ocr', false);
    formData.append('handle', handle?.[type]);

    setProcessing({ open: true });

    const processedDocument = await processDocument(navigate, formData);

    setProcessing({ open: false });

    let newHandle = {
      front: patient?.personal_id_image && patient?.personal_id_image?.front,
      back: patient?.personal_id_image && patient?.personal_id_image?.back
    };

    newHandle[type] = processedDocument?.handle;

    handleContinue(newHandle);
    setHandle(newHandle);
  };

  return (
    <>
      <div className="relative rounded-lg bg-neutral-50 !p-8">
        {/* <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 z-[-1] h-full w-full">
          <img src={paymentPattern} className="h-full w-full" />
        </div> */}
        <label className="text-base font-600 text-neutral-800">Identification document</label>
        {!user?.authenticated && !user?.isOnboarding && <CheckinLoginWarning />}
        <p className="pt-1 text-sm text-neutral-700">
          Please provide a valid and clear copy of your identification document. Ensure that all
          personal information is visible and legible, including your full name, date of birth,
          photo, and any relevant identification numbers!
        </p>
        <div className="flex gap-4 !pt-4 sm:flex-col">
          <div className="mb-4 grid w-full grid-cols-2 gap-2 sm:grid-cols-1">
            <div>
              <p className="mb-2 text-sm font-500">Front of ID Card</p>
              <div
                onClick={() => setImageEdit('front')}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {patient?.personal_id_image?.front ? (
                  <img
                    className="aspect-[5/3] w-full  overflow-hidden rounded-xl object-cover"
                    src={`https://cdn.filestackcontent.com/${patient?.personal_id_image?.front}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <p className="mb-2 text-sm font-500">Back of ID Card</p>
              <div
                onClick={() => setImageEdit('back')}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {patient?.personal_id_image?.back ? (
                  <img
                    className="aspect-[5/3] w-full overflow-hidden rounded-xl bg-black object-cover"
                    src={`https://cdn.filestackcontent.com/${patient?.personal_id_image?.back}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div hidden ref={checkinButton} onClick={() => checkPhotoIdsExist()}>
          Continue
        </div>
      </div>
      <Modal
        handleOpen={!!imageEdit}
        handleClose={() => setImageEdit(null)}
        className="h-full w-full !rounded-none bg-neutral-800"
        title="Add Image"
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {patient?.personal_id_image?.[imageEdit] && !loading
              ? 'Looks good?'
              : 'Start with the front of the card'}
          </p>
          <p className="mt-2 px-16 text-white">
            {patient?.personal_id_image?.[imageEdit] && !loading
              ? 'Make sure the picture is clear'
              : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
          </p>

          <ImagePicker
            label={imageEdit === 'front' ? 'Front of card' : 'Back of card'}
            handle={handle?.[imageEdit]}
            handleDelete={handleDelete}
            name={imageEdit}
            kiosk={kiosk}
            coords={coords?.[imageEdit]}
            processing={processing?.open}
            loading={loading}
            resetHandle={() => setHandle({ ...handle, [imageEdit]: null })}
            handleContinue={async (image) => {
              await handleProcess(image, imageEdit);
            }}
            onCapture={handleSave}
          />
        </div>
      </Modal>
    </>
  );
};

export default withErrorBoundary(PersonalID);
