import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { getPatientDocuments } from '/api/Documents';
import { getPatients } from '/api/Patients';
import { ia } from '/lib/helpers/utility';
import { usePatients } from '/lib/hooks/queries/patients/usePatients';
import { useQueryClient } from '@tanstack/react-query';

import Avatar from 'components/shared/Avatar/Avatar';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';

import PatientDoc from './components/PatientDoc';

const Patients = (props) => {
  const { filters, setFilters, tabState, setTabState, setSearchTerm } = props;
  const divRef = useRef(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const patientParams = {
    limit: filters.limit,
    searchTerm: filters.patientSearchTerm,
    page: filters.patientsPage
  };

  const {
    data = {},
    isFetching,
    isPreviousData,
    status
  } = usePatients({ params: { ...patientParams }, dependencies: [patientParams] });
  useEffect(() => {
    if (!isPreviousData) {
      queryClient.prefetchQuery(
        ['patients', filters.patientSearchTerm, filters.patientsPage + 1],
        () => getPatients(navigate, { ...patientParams, page: filters.patientsPage + 1 })
      );
    }
  }, [data, isPreviousData, filters.patientsPage, queryClient]);

  useEffect(() => {
    if (status === 'success' && data?.patients) {
      if (filters.patientsPage == 1) {
        setTabState((prev) => ({ ...prev, allPatients: data.patients }));
      } else {
        setTabState((prev) => ({ ...prev, allPatients: [...prev.allPatients, ...data.patients] }));
      }
    }
  }, [status, data]);

  const getDocsPerPatient = async (page) => {
    const params = {
      searchTerm: filters.docSearchTerm,
      limit: filters.limit,
      patientId: tabState.selectedPatient.id,
      page: filters.docsPage
    };
    const { documents } = await getPatientDocuments(navigate, params);
    setTabState((prev) => ({
      ...prev,
      patientDocuments: page > 1 ? [...prev.patientDocuments, ...documents] : documents
    }));
  };

  useEffect(() => {
    if (tabState.selectedPatient) {
      getDocsPerPatient(filters.docsPage);
    }
  }, [tabState.selectedPatient, filters.docSearchTerm, filters.docsPage]);

  const handleScroll = () => {
    const div = divRef.current;
    if (div) {
      if (div.scrollTop + div.clientHeight >= div.scrollHeight && !isFetching) {
        if (data?.count == tabState.allPatients.length && !tabState.selectedPatient) return;
        setFilters((prev) => ({
          ...prev,
          [tabState.selectedPatient ? 'docsPage' : 'patientsPage']:
            prev[tabState.selectedPatient ? 'docsPage' : 'patientsPage'] + 1
        }));
      }
    }
  };

  return (
    <div
      className="bg-secondary-10 flex max-h-[calc(100vh-240px)] flex-col overflow-y-auto"
      ref={divRef}
      onScroll={handleScroll}>
      {!tabState.selectedPatient ? (
        ia(tabState.allPatients) &&
        tabState.allPatients.map((patient) => (
          <div
            key={patient?.id}
            className="flex cursor-pointer items-center gap-3 !p-2 hover:!bg-primary-50"
            onClick={() => {
              setTabState((prev) => ({ ...prev, selectedPatient: patient, patientDocuments: [] }));
              setFilters((prev) => ({ ...prev, docsPage: 1, patientSearchTerm: '' }));
              setSearchTerm('');
            }}>
            <Avatar
              firstName={patient?.f_name}
              lastName={patient?.l_name}
              id={patient?.id}
              size="xl"
            />
            <div className="flex flex-col">
              <h3 className="text-base font-500 text-neutral-900">{patient?.fullName}</h3>
              {patient?.dob && (
                <span className="text-xs text-neutral-500">{formatDate(patient?.dob)}</span>
              )}
            </div>
          </div>
        ))
      ) : (
        <div className="flex flex-col gap-4">
          {ia(tabState.patientDocuments)
            ? tabState.patientDocuments.map((doc) => (
                <PatientDoc
                  key={doc.id}
                  {...{
                    doc,
                    tabState,
                    setTabState,
                    isChecked: tabState.selectedFiles.some((file) => file.id === doc.id)
                  }}
                />
              ))
            : null}
        </div>
      )}
    </div>
  );
};

export default Patients;
