import React from 'react';
import Input from '../../../shared/Input/Input';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';
import Header from 'components/shared/Header/Header';

const HeaderTable = ({ setShowNewService, filters, setFilters }) => {
  return (
    <Header title="Services">
      <div className="flex">
        <Input
          id="searchService"
          type="string"
          placeholder="Search service..."
          rounded="full"
          icon="new-search"
          value={filters.searchTerm}
          className="!mr-5 !rounded-2xl !border-secondary-400"
          onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          rightText={
            filters.searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => setFilters({ ...filters, searchTerm: '', page: 1 })}
              />
            )
          }
        />
        <Button
          type="primary"
          text="New Service"
          onClick={() => setShowNewService(true)}
          id="newServiceModalBtn"
          data-qa="new-service-btn"
        />
      </div>
    </Header>
  );
};

export default HeaderTable;
