import { useRef, useState } from 'react';

import { debounce } from 'lodash';

import { ia, iaRa } from 'lib/helpers/utility';
import { useInfiniteFetchData } from 'lib/hooks/useInfiniteFetchData';

import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';

const FilterInfiniteScroll = ({
  data,
  index,
  setFilter,
  queryParams = {},
  placeholder = 'Type for search',
  icon = true
}) => {
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const {
    data: optionsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteFetchData({
    queryKey: data?.queryKey,
    queryFn: data?.queryFn || data?.fetchData,
    formatData: data?.formatData,
    isOptions: !data?.formatData,
    params: { searchTerm, page: 1, ...queryParams },
    dependencies: [searchTerm],
    options: {
      staleTime: Infinity
    }
  });

  const options = ia(optionsData?.options) || optionsData;

  const debouncedSetSearchTerm = useRef(debounce((value) => setSearchTerm(value), 300)).current;

  const handleInputChange = (value) => {
    setSearchTerm('');
    setLocalSearchTerm(value);
    debouncedSetSearchTerm(value || '');
  };

  const handleChange = (selectedOptions) => {
    if (ia(selectedOptions)) {
      const values = selectedOptions.map((option) => ({
        ...option,
        displayLabel: option.displayLabel || option.label
      }));
      setFilter(index, { ...data, values });
    }

    setFilter(index, { ...data, values: selectedOptions });
  };

  const loadOptions = (inputValue, callback) => callback(options?.[data?.queryKey]);

  const values = iaRa(data?.values).map((option) => ({
    ...option,
    label: option.displayLabel || option.label
  }));

  return (
    <div className="flex cursor-pointer flex-col">
      <p className="text-sm font-medium pb-2">{data?.title || 'Search'}</p>
      <div className="relative flex justify-between pl-[10px] mr-3">
        <Select
          isAsync={data.loadOptions}
          options={options}
          parentClassName="w-full"
          id={`search-${data.title?.toLowerCase() || 'search'}`}
          inputValue={localSearchTerm}
          noDropdown
          loadOptions={loadOptions}
          value={values}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          isClearable={false}
          styles={{
            control: {
              outline: '0',
              border: 'none',
              padding: '0',
              minHeight: '1.5rem',
              boxShadow: '0'
            },
            valueContainer: {
              padding: '0'
            }
          }}
          onChange={handleChange}
          isMulti={data?.multiple}
          menuPortalTarget={document.body}
          onMenuScrollToBottom={() => {
            if (hasNextPage && !isFetchingNextPage) {
              fetchNextPage();
            }
          }}
          isLoading={isFetchingNextPage}
        />
        {icon && (
          <div className="z-100 absolute bottom-0 right-0 top-0 mr-[2px] flex">
            <Icon color="neutral" shade={600} stroke icon="new-search" />
          </div>
        )}
      </div>
      <div className="mt-1 border !border-t-0 border-solid border-neutral-200"></div>
    </div>
  );
};

export default FilterInfiniteScroll;
