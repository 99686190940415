import Popover from 'components/shared/Popovers/Popover/NewPopover';
import React from 'react';

const Actions = ({ item, formik, setShowFormModal, setItemToDelete }) => {
  const options = [
    {
      icon: 'new-edit-v3',
      stroke: true,
      label: 'Edit',
      onClick: () => {
        formik.setValues(item);
        setShowFormModal(true);
      }
    },
    {
      icon: 'trash',
      label: 'Delete',
      onClick: () => setItemToDelete(item?.cfr_id)
    }
  ];

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Popover
        options={options}
        optionIconColor="primary"
        position="left"
        isFixed
        icon="new-context-menu-dots"
      />
    </div>
  );
};

export default Actions;
