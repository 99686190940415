import React, { useEffect, useRef, useState } from 'react';
import insuranceVerified from './icons/insurance_verified.svg';
import insuranceFailed from './icons/insurance_failed.svg';
import paymentPattern from 'assets/Patterns/payment_pattern.svg';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';

const InsuranceVerify = ({ success = true, setShow, show }) => {
  const [timer, setTimer] = useState(10);
  const timerInterval = useRef();
  const onClose = () => {
    setTimer(0);
  };

  useEffect(() => {
    timerInterval.current = setInterval(() => {
      setTimer((timer) => timer - 1);
    }, 1000);

    if (timer <= 0) {
      onClose();
      setShow({ ...show, insuranceValid: null });
    }
    return () => clearInterval(timerInterval.current);
  }, [timer]);

  return (
    <div className="relative z-[99] flex h-full w-full flex-col items-center overflow-hidden bg-white !p-2 !pt-[88px]">
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full">
        <img src={paymentPattern} className="h-full w-full"></img>
      </div>

      <img
        alt={success ? 'Insurance Verified' : 'Insurance Failed'}
        className="w-64"
        src={success ? insuranceVerified : insuranceFailed}></img>
      <p
        className={cs(
          'mt-3 text-center text-2xl font-medium',
          success ? 'text-primary-600' : 'text-red-600'
        )}>
        {success ? (
          'Your insurance has been verified!'
        ) : (
          <span>
            We were unable to verify or are<br className="sm:hidden"></br> out-of-network with your
            insurance!
          </span>
        )}
      </p>
      <p className={success ? 'text-center text-primary-500' : 'mt-2 text-center text-red-500'}>
        Using the most recent live available eligibility data.
      </p>
      <div className="flex flex-col items-center">
        <p className="!mt-6 text-neutral-600">
          This page will close automatically in <span className="font-medium">{timer} seconds</span>
        </p>

        <div className="!mt-2 flex !cursor-pointer items-center gap-1" onClick={onClose}>
          <Icon icon="new-close-sm" className="!cursor-pointer" color="primary" stroke size={10} />
          <p className="text-base text-primary-600">Close</p>
        </div>
      </div>
    </div>
  );
};

export default InsuranceVerify;
