import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';

import { getClinicalNote } from 'api/ClinicalNote';
import { getMyScribe } from 'api/MyScribe';
import { getOverview } from 'components/practice/charts/ClinicalNote/lib/getOverview';
import { processCustomFormTypes } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import { selectedInitialState } from 'components/practice/charts/ClinicalNote/PreviewNote/lib/initials';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { assign } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa, io, spaceToKebabCase } from '../../../../../../../../lib/helpers/utility';
import {
  prepareSelection,
  processAdvancedCustomForms,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../../../../PreviewNote/lib/selectedHelper';

const DemographicsAndSummary = ({ formik }) => {
  const {
    currentHpOverviewData,
    overviewData,
    cnDisplaySettings,
    selected = {},
    setSelected = () => {}
  } = useClinicalNoteContext() || {};
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getSelected();
  }, [cnDisplaySettings, currentHpOverviewData, overviewData, formik?.values?.clinical_notes]);

  useEffect(() => {
    if (!selected) return;
    formik?.setFieldValue('clinical_summary', selected);
  }, [selected, formik?.values?.from_name]);

  const getClinicalNoteRead = async (appointmentId) => {
    const res = await getClinicalNote(navigate, {
      appointmentId,
      exporting: true
    });

    const {
      clinicalNote: fetchedClinicalNote,
      advancedHP,
      advancedSOAP,
      serviceCPT,
      customFormTypes
    } = res;

    let response = {};

    if (fetchedClinicalNote?.id) {
      response = {
        ...response,
        clinicalNote: { ...fetchedClinicalNote, cpt_codes: iaRa(serviceCPT) }
      };
    }

    if (ia(advancedHP)) {
      response = {
        ...response,
        advancedHP: advancedHP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }))
      };
    }

    if (ia(advancedSOAP)) {
      response = {
        ...response,
        advancedSOAP: advancedSOAP.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }))
      };
    }

    if (customFormTypes) {
      const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });

      response = { ...response, customFormTypes: processedCustomFormTypes };
    }

    return response;
  };

  const getSelected = async () => {
    setLoading(true);
    try {
      const advHP = [];
      const advSOAP = [];
      const processedAdvFroms = [];
      const cn = [];
      const ambientListening = [];
      const currentOverviewData = [];

      for (const item of formik.values.clinical_notes) {
        const { clinicalNote, advancedHP, advancedSOAP, customFormTypes } =
          await getClinicalNoteRead(item);

        const currOverviewData = await getOverview({
          patientId: clinicalNote?.user_id,
          appointmentId: clinicalNote?.appointment_id,
          navigate,
          isKebab: false
        });

        const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
        const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);
        const getAdvancedFroms = Object.entries(customFormTypes || {}).map(([, object]) => {
          return processAdvancedCustomForms(object, cnDisplaySettings);
        });

        const processedAdvancedForms = assign({}, ...getAdvancedFroms);

        if (!ia(ambientListening)) {
          const result = await getMyScribe(navigate, { appointmentId: item });
          if (ia(result?.data?.myscribe)) {
            ambientListening.push(result?.data?.myscribe);
          }
        }

        cn.push(clinicalNote);
        currentOverviewData.push(currOverviewData);
        advHP.push(getAdvancedHP);
        advSOAP.push(getAdvancedSOAP);
        processedAdvFroms.push(processedAdvancedForms);
      }

      let clinicalNote = Object.assign({}, ...cn);
      let currentHpOverviewData = Object.assign({}, ...currentOverviewData);
      let getAdvancedHP = Object.assign({}, ...advHP);
      let getAdvancedSOAP = Object.assign({}, ...advSOAP);
      let processedAdvancedForms = Object.assign({}, ...processedAdvFroms);

      let selectedObj = {
        ...selectedInitialState(
          clinicalNote,
          cnDisplaySettings,
          currentHpOverviewData,
          null,
          ambientListening,
          null,
          null,
          null,
          overviewData
        ),
        ...getAdvancedHP,
        ...getAdvancedSOAP,
        ...processedAdvancedForms
      };

      const clinicalSummary = formik?.values?.clinical_summary;

      let formattedSelect = prepareSelection({ initialSelection: selectedObj });

      if (io(clinicalSummary)) {
        formattedSelect = updateSelectedObject(clinicalSummary, formattedSelect);
      }
      setSelected(formattedSelect);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const updateSelectedObject = (clinicalSummary, selectedObj) => {
    if (!clinicalSummary || !selectedObj) return selectedObj;

    const updatedSelectedObj = { ...selectedObj };

    Object.keys(clinicalSummary).forEach((key) => {
      if (updatedSelectedObj[key]) {
        updatedSelectedObj[key] = {
          ...updatedSelectedObj[key],
          checked: clinicalSummary[key].checked
        };

        if (ia(updatedSelectedObj[key].formType)) {
          updatedSelectedObj[key].formType = updatedSelectedObj[key].formType.map((type) =>
            type.title === 'Narrative' ? { ...type, checked: true } : { ...type }
          );
        }
      }
    });

    return updatedSelectedObj;
  };

  const isCheckAllBoxes = () => {
    return Object.keys(selected).every((key) => {
      const copyState = selected[key];

      return copyState?.checked;
    });
  };

  const handleSelectAll = (event) => {
    const checkboxValue = event.target.checked;
    let updateSelected = { ...selected };

    Object.keys(updateSelected).forEach((key) => {
      const copyState = updateSelected[key];

      const findIndexOfItem = ia(copyState?.formType)
        ? copyState?.formType?.findIndex((item) => item.title === 'Narrative')
        : -1;

      if (findIndexOfItem >= 0) copyState.formType[findIndexOfItem].checked = checkboxValue;
      copyState.checked = checkboxValue;
    });

    setSelected(updateSelected);
  };

  const onSelect = ({ event, findIndexOfItem, key }) => {
    setSelected((prevState) => {
      let copyState = { ...prevState };
      const value = event.target.checked;

      if (findIndexOfItem >= 0) copyState[key].formType[findIndexOfItem].checked = value;
      copyState[key].checked = value;

      return copyState;
    });
  };

  return (
    <div className="flex flex-col rounded-xl bg-white px-4 py-3 shadow-sm">
      <p className="text-lg font-500 text-primary-900">Demographics & Summary</p>

      <div className="!mt-4">
        <Checkbox
          label="Demographics"
          name="demographics"
          isChecked={formik?.values?.demographics}
          onChange={formik?.handleChange}
        />
        <Checkbox
          className="!mt-4"
          label="Insurance Information"
          name="insurance_info"
          isChecked={formik?.values?.insurance_info}
          onChange={formik?.handleChange}
        />
      </div>

      <div className="!my-4 border border-solid !border-primary-100"></div>

      {loading ? (
        <Skeleton count={10} height="30px" />
      ) : (
        <div>
          <p className="text-xs font-400 text-neutral-900">
            Choose which data you want to include in clinical summary
          </p>

          <div className="!my-4">
            <div className="!py-2">
              <Checkbox
                label="Clinical Summary"
                name="clinical_summary"
                isChecked={isCheckAllBoxes()}
                onChange={(event) => handleSelectAll(event)}
              />
            </div>

            <div className="grid grid-cols-[repeat(auto-fit,minmax(320px,1fr))]">
              {io(selected) &&
                Object.keys(selected).map((key, index) => {
                  const row = selected[key];

                  let isChecked = row?.checked;

                  const findIndexOfItem = ia(row?.formType)
                    ? row?.formType?.findIndex((item) => item.title === 'Narrative')
                    : -1;

                  return (
                    <div key={index} className="flex flex-wrap items-center !px-8 !py-2">
                      <Checkbox
                        label={row?.title}
                        name={row?.title}
                        isChecked={isChecked}
                        onChange={(event) => onSelect({ event, findIndexOfItem, key })}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DemographicsAndSummary;
