import React, { useState, useRef, useEffect } from 'react';
import { Popover } from '@headlessui/react';
import { ia } from '../../../lib/helpers/utility';
import { useNavigate } from 'react-router-dom';

import ActionButton from '../../shared/Button/ActionButton/ActionButton';
import cs from 'classnames';

import Skeleton from '../../shared/Skeleton/Skeleton';

import useOutsideClick from '../../../lib/hooks/useOutsideClick';
import Notification from './Notification';
import { requestApi } from '../../../api/Api';
import { useQuery } from '@tanstack/react-query';

const Notifications = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);

  const isLoading = false;

  const [notifications, setNotifications] = useState([]);

  const popoverRef = useRef(null);
  const navigate = useNavigate();

  useOutsideClick(popoverRef, () => handleClose());

  const { data } =
    useQuery({
      queryKey: ['getRequestsCount'],
      queryFn: getRequestsCount,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getRequestsCount() {
    const response = await requestApi({
      url: '/api/requests/count',
      params: {
        withCount: true
      },
      navigate
    });

    return response;
  }

  useEffect(() => {
    setNotifications([]);
    if (data?.requests?.all > 0) {
      setNotifications([
        ...notifications,
        {
          title: 'New Requests',
          body: `You have ${data?.requests?.all} requests to respond to!`,
          type: 'requests'
        }
      ]);
    }
  }, [data]);

  const onNavigate = (type, closePopover) => {
    switch (type) {
      case 'requests':
        navigate(`/admin/requests/payers`);
    }
    closePopover();
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div ref={popoverRef} className={cs('flex items-center', className)}>
      <Popover close className="relative !ml-2">
        {({ open, close }) => (
          <>
            <Popover.Button className="relative p-0" onClick={isOpen ? handleClose : handleOpen}>
              <ActionButton icon="new-notifications" active={open} />
              {notifications?.length > 0 && (
                <div className="absolute top-[-4px] right-[-1px] flex h-[14px] min-w-[14px] items-center justify-center rounded-full bg-danger-400 p-[2px]">
                  <span className="text-[10px] font-500 text-white">{notifications?.length}</span>
                </div>
              )}
            </Popover.Button>
            <Popover.Panel
              open={open}
              className="absolute right-0 z-10 mt-[10px] w-96 !rounded-lg bg-white !p-2 shadow-medium">
              <div className={`flex items-center justify-between gap-1 !p-1`}>
                <span className="flex items-center !gap-x-2">
                  <em className="text-sm font-500 not-italic text-primary-900">Notifications</em>
                </span>
              </div>
              <hr className="m-1" />
              <div className="!m-0 h-64 overflow-auto !p-0">
                {isLoading ? (
                  <Skeleton count={5} height={50} width="100%" />
                ) : ia(notifications) ? (
                  <Notification
                    onNavigate={onNavigate}
                    notifications={notifications}
                    close={close}
                  />
                ) : (
                  <div className="!my-4 text-center text-lg leading-5 text-neutral-600">
                    No notifications found.
                  </div>
                )}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default Notifications;
