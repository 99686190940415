export const PAYER_KINDS = [
  {
    label: 'In-Network',
    value: 0
  },
  {
    label: 'Out-Of-Network',
    value: 1
  },
  {
    label: 'Unknown',
    value: 2
  }
];
