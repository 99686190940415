import { addDays, endOfDay, startOfDay } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ReactToPrint from 'react-to-print';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { transaction_type } from '../../../../constants';
import { ia } from '../../../../lib/helpers/utility';
import useOutsideClick from '../../../../lib/hooks/useOutsideClick';
import MultipleSearch from '../../../Search/MultipleSearch/MultipleSearch';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';
import Popover from '../../../shared/Popovers/Popover/Popover';
import practiceState from '../../practiceState';
import '../BeyondBilling.scss';
import { defaultStaticRanges } from './StaticFilteringRanges';

export default function FinancialReportingFilters({ exportRef }) {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useRecoilState(practiceState.reportingFilters);
  const [selectedValue, setSelectedValue] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [hasCareCredit, setHasCareCredit] = useState(false);
  const [transactionType, setTransactionType] = useState(transaction_type);
  const navigate = useNavigate();

  const ref = useRef();
  const calendarRef = useRef();

  useEffect(() => {
    getPractice();
  }, []);

  const customStyles = {
    control: (_, { selectProps: { width } }) => ({
      minWidth: 265,
      height: '38px !important',
      display: 'flex',
      padding: '2px 2px 2px 50px',
      fontSize: 14,
      backgroundColor: '#fff',
      borderRadius: '6px'
    })
  };

  const getPractice = async () => {
    try {
      let res = await interimApi('/api/practice/current_practice', {}, navigate);
      const { practice } = res.data;
      if (!practice?.care_credit) {
        setTransactionType(transactionType?.filter((row) => row?.value != 'care_credit'));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    setFilters({ ...filters, transactionFilter: Array.isArray(e) ? e.map((x) => x?.value) : [] });
  };

  const handlePopover = () => {
    setShowPopover(!showPopover);
    setShowDateRangePicker(false);
  };

  useOutsideClick(ref, () => {
    setShowPopover(false);
  });
  useOutsideClick(calendarRef, () => {
    setShowDateRangePicker(false);
  });

  const onClearAllFilters = () => {
    setFilters({
      selectedRange: [
        {
          startDate: startOfDay(new Date()),
          endDate: endOfDay(new Date()),
          key: 'selection'
        }
      ],
      id: '',
      payer: '',
      patient: null,
      transactionFilter: []
    });
  };

  const checkFilters = () => {
    const start = filters?.selectedRange[0]?.startDate - startOfDay(new Date()) !== 0;
    const end = filters?.selectedRange[0]?.endDate - endOfDay(new Date()) !== 0;
    if (start || end || filters?.patient != null || ia(filters?.transactionFilter)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div className="flex flex-wrap gap-2.5">
          <div ref={calendarRef} className="relative">
            <Button
              onClick={() => {
                setShowFilters(false);
                setShowDateRangePicker(!showDateRangePicker);
              }}
              text={`${moment(filters.selectedRange[0].startDate).format('MMM Do, YYYY')} - ${moment(filters.selectedRange[0].endDate).format('MMM Do, YYYY')}`}
            />
            <Popover handleOpen={showDateRangePicker} className="PopoverNavBarFilters">
              <DateRangePicker
                onChange={(item) => {
                  setFilters({ ...filters, selectedRange: [item.selection] });
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                minDate={addDays(new Date(), -300)}
                maxDate={addDays(new Date(), 900)}
                direction="horizontal"
                scroll={{ enabled: true }}
                ranges={filters.selectedRange}
                staticRanges={defaultStaticRanges}
              />
            </Popover>
          </div>
          <div className="!w-80 print:hidden">
            <MultipleSearch
              handleClick={(e) => {
                if (ia(e)) {
                  setFilters({ ...filters, patient: e });
                } else {
                  setFilters({ ...filters, patient: e ? { id: e.value, type: e.type } : null });
                }
              }}
              passedCustomStyling={customStyles}
            />
          </div>
          <div ref={ref} className="relative print:hidden">
            <div className="filterInput" onClick={() => handlePopover()}>
              {checkFilters() ? (
                <div onClick={() => onClearAllFilters()}>
                  <Icon icon="filter-reset" />
                </div>
              ) : (
                <div>
                  <Icon icon="filter" />
                </div>
              )}
              <p className="ml-3 mr-3">Filter</p>
              <Icon icon="chevron-down" />
            </div>

            <Popover handleOpen={showPopover} className={'PopoverNavBarFilters'}>
              <div className="flex">
                <div className="filterTitleDiv">
                  <p className="filterTitle">Transaction:</p>
                </div>
                <div className="ml-1">
                  <Select
                    className="dropdown"
                    placeholder="Filter By Transaction Type"
                    value={transactionType.filter((obj) =>
                      filters?.transactionFilter?.includes(obj?.value)
                    )}
                    options={transactionType}
                    onChange={handleChange}
                    isMulti
                    isClearable
                    styles={{
                      control: (_, { selectProps: { width } }) => ({
                        backgroundColor: '#f8f8f8',
                        border: '1px solid #dcdcdc',
                        borderRadius: '8px',
                        width: 250,
                        hieght: 50,
                        display: 'flex',
                        padding: '2px 2px 2px 2px',
                        fontSize: 14
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        color: state.selectProps.menuColor,
                        opacity: 1,
                        zIndex: 1,
                        padding: 0
                      }),
                      menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
                    }}
                  />
                </div>
              </div>
            </Popover>
          </div>
        </div>

        {exportRef && (
          <div className="print:hidden">
            <ReactToPrint
              trigger={() => <Button text="Export" icon="export" />}
              content={() => exportRef.current}
            />
          </div>
        )}
      </div>
    </div>
  );
}
