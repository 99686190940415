import React, { Fragment } from 'react';
import ReactModal from 'react-modal';

import cs from 'classnames';

import Button from '../Buttons/Button';
import Icon from '../Icon/Icon';

import './lib/Modal.scss';

/**
 * @param {import('./lib/propTypes').ModalProps}
 */

const Modal = ({
  isOpen = false,
  handleOpen = false, // use isOpen instead of handleOpen
  handleClose,
  title,
  children,
  subModal,
  slideFromRight,
  isLarge = false,
  disableHeader = false,
  headButton,
  headerClassName,
  headerCloseIcon = true,
  customHeader,
  prevButton,
  footer,
  isFooter = true,
  isFooterSticky = false,
  stickyFooterClassName,
  className,
  portalClassName,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  bodyClassName,
  footerClassName,
  customStyling,
  modalCenterAnimation = false,
  onScroll,
  ...rest
}) => {
  if (!(isOpen || handleOpen)) return;

  return (
    <ReactModal
      isOpen={isOpen || handleOpen}
      onRequestClose={handleClose}
      contentLabel={title}
      className={cs(
        'Modal',
        className,
        !slideFromRight && 'm-6 max-h-[calc(100vh-48px)] sm:m-4 sm:max-h-[calc(100vh-32px)]',
        isLarge && 'w-[91%] sm:w-[96%] md:w-[96%] lg:w-[96%] xxl:max-w-[1920px]'
      )}
      overlayClassName={cs(
        'ModalOverlay',
        slideFromRight && 'ModalRightOverlay',
        modalCenterAnimation && 'ModalCenterAnimation',
        overlayClassName
      )}
      portalClassName={cs(portalClassName, subModal && 'SubModal')}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      style={{ content: customStyling }}
      {...rest}>
      {!disableHeader && (
        <div className={cs('Modal__head', headerClassName)} data-dd-privacy="allow">
          {customHeader ? (
            customHeader
          ) : (
            <>
              <div className="Modal__head__left w-full">
                {prevButton}
                <h6 className="text-primary-900 capitalize whitespace-nowrap">{title}</h6>
                {headButton}
              </div>
            </>
          )}
          {headerCloseIcon && (
            <Icon
              data-qa="new-close-square"
              icon="new-close-square"
              onClick={handleClose}
              color={headerClassName?.includes('bg-') ? 'white' : null}
              className="flex h-6 w-6 items-center justify-center"
            />
          )}
        </div>
      )}
      <div
        onScroll={onScroll}
        className={cs(
          'Modal__body',
          isFooterSticky && 'pb-[112px] pt-6 sm:pb-[94px]',
          bodyClassName
        )}>
        {children}
      </div>
      {footer ? (
        <div
          className={cs(
            'Modal__footer',
            isFooterSticky &&
              'absolute bottom-0 h-[112px] w-[calc(100%-6px)] border-none bg-white/60 pb-[20px] pt-[30px] shadow-none backdrop-blur-[1.5px] sm:h-auto sm:p-4',
            footerClassName
          )}
          data-dd-privacy="allow">
          {isFooterSticky ? (
            <div
              className={cs(
                'z-10 mx-auto flex w-full items-center justify-between rounded-lg border border-solid border-neutral-100 bg-white px-6 py-3 shadow-[0px_-2px_16px_rgba(0,0,0,0.06)] sm:p-4',
                stickyFooterClassName
              )}>
              {footer}
            </div>
          ) : (
            footer
          )}
        </div>
      ) : isFooter ? (
        <div className={cs('Modal__footer', footerClassName)} data-dd-privacy="allow">
          <Button text="Close" onClick={handleClose} color="neutral" outlined data-qa="close-btn" />
        </div>
      ) : null}
    </ReactModal>
  );
};

export default Modal;
