import React, { useEffect, useRef, useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import Receipt from 'components/shared/Receipt/Receipt';
import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/shared/Loading/Loading';
import ReactToPrint from 'react-to-print';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { showAlert } from 'components/shared/Alert/Alert';

const ReceiptModal = ({ isOpen, onClose, transactionId, viewDefaultReceipt = true }) => {
  const [receipt, setReceipt] = useState();
  const [show, setShow] = useState({ loading: false, receipt: false });
  const receiptRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    if (transactionId && viewDefaultReceipt) {
      viewReceipt();
    } else {
      setShow((ps) => ({ ...ps, receipt: false }));
    }
  }, [transactionId]);

  const viewReceipt = async () => {
    try {
      const onSuccess = (partData) => {
        if (partData?.receipt?.data) {
          setReceipt(partData?.receipt?.data);
        } else {
          setReceipt(partData?.receipt);
        }
        setShow((ps) => ({ ...ps, loading: false, receipt: true }));
      };

      await requestApi({
        url: '/api/transactions/receipt/get',
        params: {
          transactionId
        },
        onSuccess,
        navigate
      });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`viewReceipt: unexpected error ${error}`);
    }
  };

  const sendEmailReceipt = async () => {
    try {
      const onSuccess = () => {
        showAlert({
          title: 'Receipt Email Sent!'
        });
      };

      await requestApi({
        url: '/api/transactions/receipt/email',
        params: {
          transactionId
        },
        onSuccess,
        navigate
      });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`There was an error emailing the receipt. Please try again. ${error}`);
    }
  };

  const closeReceipt = () => {
    setShow({ ...show, receipt: false });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      slideFromRight
      title="Receipt"
      handleOpen={show.receipt}
      className="w-[1200px]"
      handleClose={closeReceipt}
      handleClick={closeReceipt}
      headButton={
        <div className="flex w-full items-center justify-end">
          <ReactToPrint
            trigger={() => (
              <Button
                color=""
                size="small"
                type="link"
                text="Print"
                icon="new-printer-bulk"
                className="text-primary-900"
              />
            )}
            content={() => receiptRef.current}
          />

          <Button
            color=""
            type="link"
            size="small"
            text="Email"
            icon="new-sms-bulk"
            data-qa="email-to-patient"
            className="text-primary-900"
            onClick={sendEmailReceipt}
          />
        </div>
      }
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            color="neutral"
            onClick={closeReceipt}
            text="close"
            data-qa="close-btn"
          />
        </div>
      }>
      {show.loading && (
        <div>
          <Loading />
        </div>
      )}
      {receipt && <Receipt receipt={receipt} parentRef={receiptRef} />}
    </Modal>
  );
};

export default withErrorBoundary(ReceiptModal);
