import { memo } from 'react';

import { claimsStatusCodes } from 'constants';

import { useEraContext } from 'lib/context/EraContext/EraContext';

import Select from 'components/shared/Select/Select';

const DateCellRenderer = memo(({ data }) => {
  const { statusCode, claim, setStatusCode } = useEraContext();

  if (data.isStatusRow) {
    return (
      <Select
        label="Status"
        isClearable={false}
        options={claimsStatusCodes}
        menuPortalTarget={document.body}
        parentClassName="!py-2 font-400 w-[300px]"
        onChange={(e) => setStatusCode(e.value)}
        value={
          !!(statusCode || claim?.status_code) &&
          claimsStatusCodes.find((o) => o?.value === (claim?.statusCode || statusCode))
        }
      />
    );
  }

  return data?.date || '';
});

export default DateCellRenderer;
