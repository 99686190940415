import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useUIContext } from '../../../../../lib/context/UIContext/UIContext';
import { ia } from '../../../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import ArrowToggle from '../shared/ArrowToggle';
import GeneralOverview from './GeneralOverview/GeneralOverview';
import Macros from './Macros/Macros';
import MyScribeAISummary from './MyScribeAISummary/MyScribeAISummary';
import PatientResponse from './PatientResponse/PatientResponse';
import PreviousFormResponses from './PreviousFormResponses/PreviousFormResponses';
import PreviousNotes from './PreviousNotes/PreviousNotes';
import SidebarPopover from './SidebarPopover';
import TreatmentPlan from './TreatmentPlan/TreatmentPlan';

const OPTIONS = [
  { component: 'patient_general_overview', title: 'Patient History' },
  { component: 'macros', title: 'Macros' },
  { component: 'patient_response', title: 'Patient Response' },
  { component: 'previous_note_preview', title: 'Previous Note Preview' },
  { component: 'previous_form_responses', title: 'Previous Form Responses' },
  { component: 'myscribe_ai_narrative', title: 'MyScribe AI Narrative', note: 'myscribe' }
];

const Sidebar = ({ handleSidebar }) => {
  const {
    patientResponseSidebar,
    setPatientResponseSidebar,
    cnDisplaySettings,
    setCNDisplaySettings
  } = useClinicalNoteContext();
  const viewIndex = patientResponseSidebar?.view
    ? OPTIONS.findIndex((opt) => opt.component === patientResponseSidebar?.view)
    : 0;
  const [selectedView, setSelectedView] = useState(OPTIONS[viewIndex]);
  const { openSidebar, setOpenSidebar } = handleSidebar;
  const { id, type } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const { device } = useUIContext();
  const [shouldRender, setShouldRender] = useState(true);

  useEffect(() => {
    let timeoutId;

    if (!openSidebar) {
      timeoutId = setTimeout(() => {
        setShouldRender(false);
      }, 200);
    } else {
      setShouldRender(true);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [openSidebar]);

  useEffect(() => {
    openSideBarBasedOnType();
  }, [type]);

  const openSideBarBasedOnType = () => {
    if (!type) return;

    const hasWantedView = OPTIONS?.some(({ note }) => note === type);

    if (hasWantedView) {
      const view = OPTIONS.find(({ note }) => note === type);
      setSelectedView(view);
      setPatientResponseSidebar((prev) => ({ ...prev, view: view.component }));
      setOpenSidebar(true);
    }
  };

  const treatmentPlanExists = OPTIONS.some((option) => option.component === 'treatment_plan');
  if (ia(treatmentPlan) && !treatmentPlanExists) {
    OPTIONS.splice(2, 0, { component: 'treatment_plan', title: 'Treatment Plan' });
  }

  const showPatientResponse = () => {
    if (patientResponseSidebar?.open) {
      const view = OPTIONS.find(
        ({ component }) => component === (patientResponseSidebar?.view ?? 'patient_response')
      );

      setPatientResponseSidebar((prevState) => ({
        ...prevState,
        open: false,
        view: view.component
      }));
      setSelectedView(view);
      setOpenSidebar(true);
      return;
    }
  };

  useEffect(() => {
    showPatientResponse();
  }, [patientResponseSidebar.open, patientResponseSidebar?.view]);

  const currentView = (wantedComponent) => {
    const view = {
      patient_general_overview: <GeneralOverview />,
      previous_note_preview: <PreviousNotes />,
      previous_form_responses: <PreviousFormResponses />,
      macros: <Macros />,
      treatment_plan: <TreatmentPlan />,
      patient_response: <PatientResponse />,
      myscribe_ai_narrative: <MyScribeAISummary />
    };

    return view[wantedComponent] ?? null;
  };

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
    const updatedSettings = {
      ...cnDisplaySettings,
      toggleRSidebar: {
        ...cnDisplaySettings?.toggleRSidebar,
        enabled: !cnDisplaySettings?.toggleRSidebar?.enabled
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  return (
    <div
      className={cs(
        'z-30 flex h-full shrink-0 flex-col border-0 !border-l-[1px] !border-solid !border-neutral-100 bg-white shadow-[-4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        device === 'desktop' || device === 'laptop'
          ? 'sticky top-0'
          : 'fixed right-0 top-[52px] h-[calc(100vh-117px)] sm:top-[81.5px] xs:top-[81.5px]',
        openSidebar ? 'w-[356px]' : 'w-[0]'
      )}>
      {openSidebar && (
        <ArrowToggle defaultArrow="right" open={openSidebar} setOpen={toggleSidebar} />
      )}
      <SidebarPopover
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        options={OPTIONS}
        open={openSidebar}
      />
      {shouldRender ? currentView(selectedView.component) : null}
    </div>
  );
};

export default Sidebar;
