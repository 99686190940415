import Honeybadger from '@honeybadger-io/js';
import { interimApi } from 'api/InterimApi';
import reshapeBillingProvideData from './reshapeBillingProvideData';

export default async function getBillingProviders(superbill) {
  try {
    let res = await interimApi('/api/practice/billing/get_providers', {
      practitionerId: superbill?.practitioner?.id
    });
    if (res?.data?.providers) {
      let billingProvider = res.data.providers[0];
      billingProvider = {
        ...billingProvider,
        label: billingProvider.bill_name,
        value: billingProvider.id
      };

      let billingProviderList = [];
      if (res.data?.providers?.length > 1) {
        billingProviderList = reshapeBillingProvideData(res?.data?.providers);
      }
      return { billingProvider, billingProviderList };
    } else {
      Honeybadger.notify(`There's been an unexpected error, please try again later.`);
    }
  } catch (err) {
    Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
  }
}
