import { useQuery } from '@tanstack/react-query';
import { getDocumentSign } from '../../../../api/DocumentSign';
import { useNavigate } from 'react-router-dom';

export const useDocumentSign = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['documentSign', ...dependencies], () => getDocumentSign(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
