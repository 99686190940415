import React from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import Select from 'components/shared/Select/Select';
import { useFormikContext } from 'formik';

const Practitioner = ({ practitioners }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const currPractice = useRecoilValue(currentPractice);
  const patientLink = values?.patient?.practicePatientLink?.virtual_link;
  const practiceLink = currPractice?.display_settings?.appointment?.virtual_link;

  return (
    <Select
      label="Practitioner"
      parentClassName="grow"
      required
      placeholder="Type and select a practitioner"
      value={practitioners.find((practitioner) => practitioner?.value === values?.practitioner_id)}
      onChange={(value) => {
        setFieldValue('practitioner_id', value?.value);
        setFieldValue('virtual_link', patientLink || value?.virtual_link || practiceLink);
      }}
      options={practitioners}
      inputId="practitionerSelect"
      error={errors.practitioner_id}
      isImage
    />
  );
};

export default Practitioner;
