import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getUserMostClickedSearches } from '../../../../api/User';

export const useUserMostClickedSearches = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['userMostClickedSearches', ...dependencies],
    () => getUserMostClickedSearches(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
