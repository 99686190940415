import cs from 'classnames';

import { imagePath, initials, io } from 'lib/helpers/utility';
import useRandomGradient from 'lib/hooks/useRandomGradient';

import { getClasses } from './lib/getClasses';

/**
 * @param {import('./lib/propTypes').AvatarProps} AvatarProps
 */

const Avatar = ({
  fullName,
  firstName,
  lastName,
  id,
  image,
  size = 'medium',
  fontSize,
  className,
  onClick,
  background = 'linear-gradient(98deg, #00ABE8 0%, #33C9FF 100%)',
  autoBackground = true,
  isSquare = true,
  ...rest
}) => {
  const gradientBackground = useRandomGradient(id);
  const backgroundStyle = id && autoBackground ? gradientBackground : background;
  const classes = getClasses(size);

  if (image && io(JSON.parse(image))) {
    return (
      <div
        className={cs(
          'flex min-w-[40px] items-center justify-center overflow-hidden',
          className,
          isSquare ? 'rounded-smooth' : 'rounded-full'
        )}
        style={{
          width: classes.size,
          height: classes.size,
          minWidth: classes.size,
          minHeight: classes.size
        }}
        onClick={onClick}
        data-private
        {...rest}>
        <img className="w-full object-contain" src={imagePath(JSON.parse(image).jpg)} />
      </div>
    );
  }

  return (
    <div
      className={cs(
        'flex items-center justify-center overflow-hidden',
        className,
        isSquare ? 'rounded-smooth' : 'rounded-full'
      )}
      style={{
        width: classes.size,
        height: classes.size,
        minWidth: classes.size,
        minHeight: classes.size,
        background: backgroundStyle
      }}
      onClick={onClick}
      {...rest}>
      <span
        className={cs(
          'font-600 uppercase !text-white',
          size === 'small' && 'leading-[14px]',
          fontSize ?? classes.fontSize
        )}>
        {initials(fullName ? { fullName } : { firstName, lastName })}
      </span>
    </div>
  );
};

export default Avatar;
