import { iaRa } from 'lib/helpers/utility';
import { generateCustomNoteTypes } from './processCustomNoteTypes';

export const generateEnabledNoteTypes = ({ cnDisplaySettings = null, customFormTypes = null }) => {
  const generatedCustomNoteTypes = generateCustomNoteTypes({ customFormTypes, cnDisplaySettings });

  const enabledNotes = [
    cnDisplaySettings?.hp?.enabled && {
      label: 'H&P',
      path: `hp`
    },
    cnDisplaySettings?.mha?.enabled && {
      label: 'Intake',
      path: `intake`
    },
    cnDisplaySettings?.soap?.enabled && {
      label: 'SOAP',
      path: `soap`
    },
    cnDisplaySettings?.epn?.enabled && {
      label: 'Progress',
      path: `progress`
    },
    cnDisplaySettings?.chart3d?.enabled && {
      label: '3D Charting',
      path: `3d`
    },
    cnDisplaySettings?.myscribe?.enabled && {
      label: 'MyScribe AI',
      path: `myscribe`
    },
    ...iaRa(generatedCustomNoteTypes)
  ].filter(Boolean);

  return enabledNotes;
};
