import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { deleteMyscribeAIPrompt, getMyScribeAIPrompt } from 'api/MyScribeAi';

import { useMyScribeTemplateContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';

import {
  activePromptConversationsState,
  intelligenceChatIdState,
  isAIGeneratingState,
  myScribeAutoMapActivePromptIdState,
  myScribeAutoMapSelectedNoteTypeState,
  promptListState
} from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { clinicalNote as clinicalNoteState } from 'components/state';

import { stopResponseGeneration } from '../lib/helpers';

const PromptsSidebar = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [hoverPromptId, setHoverPromptId] = useState(null);

  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [isGenerating, setIsGenerating] = useRecoilState(isAIGeneratingState);
  const [activePromptId, setActivePromptId] = useRecoilState(myScribeAutoMapActivePromptIdState);
  const setActivePromptConversations = useSetRecoilState(activePromptConversationsState);
  const { isGetPromptsLoading } = useMyScribeTemplateContextData();
  const localPrompts = useRecoilValue(promptListState);
  const [intelligenceChatId, setIntelligenceChatId] = useRecoilState(intelligenceChatIdState);
  const setSelectedNoteType = useSetRecoilState(myScribeAutoMapSelectedNoteTypeState);

  const onPromptClick = async (prompt) => {
    const { id, noteType = null } = prompt;
    try {
      setSelectedNoteType(noteType);
      if (!!isGenerating && id !== intelligenceChatId) {
        stopResponseGeneration({ intelligenceChatId });
        setIsGenerating(false);
      }
      const { chat } = await getMyScribeAIPrompt(navigate, { id });
      setActivePromptId(id);
      const newConversations = chat.map((item) =>
        item?.agent === 'ai' ? { ...item, message: item.message } : item
      );
      setIntelligenceChatId(chat[0]?.intelligence_chat_id);
      setActivePromptConversations(newConversations);
    } catch (error) {
      console.error({ error });
    }
  };

  const addNewPrompt = () => {
    if (!!isGenerating) {
      stopResponseGeneration({ intelligenceChatId });
    }
    setActivePromptId(null);
    setActivePromptConversations(null);
    setIntelligenceChatId(null);
    setSelectedNoteType(null);
  };

  const onDeletePrompt = async (id) => {
    try {
      const { status, message } = await deleteMyscribeAIPrompt(navigate, { id });
      if (status === 'deleted') {
        showAlert({ title: 'Prompt Deleted', message, color: 'success' });
        queryClient.setQueryData(
          ['prompts', clinicalNote?.id],
          ({ conversations = [], ...rest }) => {
            return { conversations: conversations.filter((item) => item.id !== id), ...rest };
          }
        );
        setActivePromptConversations(null);
        setActivePromptId(null);
        setIntelligenceChatId(null);
        setSelectedNoteType(null);
      }
    } catch (error) {
      console.error('Error deleting prompt: ', error);
    }
  };

  const getPopoverActions = (id) => [
    { label: 'Delete', icon: 'trash', onClick: () => onDeletePrompt(id) }
  ];

  const onMouseEnter = (id) => setHoverPromptId(id);
  const onMouseLeave = () => setHoverPromptId(null);

  return (
    <>
      <div className="flex items-center justify-between bg-white h-[56px] px-4">
        <div className="p-2 pl-0">History</div>
        <Button
          icon="comment-plus"
          text="New Note"
          transparent
          size="small"
          onClick={addNewPrompt}
        />
      </div>
      {isGetPromptsLoading ? (
        <div className="m-3">
          <Skeleton height="20px" width="100px" />
          <Skeleton height="20px" />
        </div>
      ) : (
        <div className="overflow-y-auto h-[calc(100%-110px)]">
          {localPrompts?.map((prompt) => (
            <div
              key={prompt.id}
              onClick={() => onPromptClick(prompt)}
              onMouseEnter={() => onMouseEnter(prompt.id)}
              onMouseLeave={() => onMouseLeave(prompt.id)}
              className={cs(
                'm-3 mb-4 cursor-pointer rounded-lg p-2 max-h-[108px] hover:bg-primary-50',
                activePromptId === prompt?.id && 'bg-primary-50'
              )}>
              <div className="flex justify-between h-[26px]">
                <div className="mb-2 flex items-center">
                  <Icon icon="new-messages" className="mr-2" />
                  <span
                    className={cs(
                      'text-sm text-neutral-800 font-semibold max-w-[180px] overflow-hidden overflow-ellipsis max-h-[20px]',
                      activePromptId === prompt?.id && 'text-primary-800'
                    )}>
                    {prompt?.name?.replace(/"/g, '')}
                  </span>
                </div>
                {hoverPromptId === prompt?.id && (
                  <Popover
                    icon="new-context-menu-horizontal"
                    isFixed
                    options={getPopoverActions(prompt.id)}
                  />
                )}
              </div>
              <p className="text-xs font-thin text-neutral-700 text-ellipsis max-h-[44px] overflow-y-hidden leading-[20px]">
                {prompt.description}
              </p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PromptsSidebar;
