import { useState } from 'react';

import { ia } from 'lib/helpers/utility';

import EditInsuranceProfile from 'components/practice/charts/InsuranceProfile/EditInsuranceProfile';

const InsuranceProfileEdit = ({ insuranceProfiles, closeHandler }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const updateProfile = () => {
    if (currentIndex + 1 >= insuranceProfiles?.length) closeHandler();
    setCurrentIndex((prev) => prev + 1);
  };

  if (!ia(insuranceProfiles) || !insuranceProfiles?.[currentIndex]) return null;

  return (
    <EditInsuranceProfile
      key={`ip-${currentIndex}`}
      insuranceProfile={insuranceProfiles[currentIndex]}
      updateProfile={updateProfile}
      index={currentIndex}
      hideEditInsuranceProfile={() => updateProfile()}
    />
  );
};

export default InsuranceProfileEdit;
