import React, { useState } from 'react';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { DEFAULT_FILTERS } from 'components/practice/PracticeIQueue/TreatmentPlan/lib/helpers';

import { TreatmentPlanMainTable } from './components/TreatmentPlan/TreatmentPlanMainTable';
import { treatmentPlanCols } from './components/TreatmentPlan/lib/helpers';

function TreatmentPlanWidgetContent({ props }) {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [treatmentPlanToDelete, setTreatmentPlanToDelete] = useState(null);
  const [showTPModal, setShowTPModal] = useState({
    loadView: 'list',
    open: false
  });
  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={treatmentPlanCols({
        setSelectedTreatmentPlan,
        setTreatmentPlanToDelete,
        setShowTPModal
      })}
      name="patient_treatment_plans">
      <TreatmentPlanMainTable
        props={props}
        showTPModal={showTPModal}
        setShowTPModal={setShowTPModal}
        treatmentPlanToDelete={treatmentPlanToDelete}
        setTreatmentPlanToDelete={setTreatmentPlanToDelete}
      />
    </TableContextProvider>
  );
}

export default function TreatmentPlanWidget(props) {
  return (
    <ClinicalNoteContextProvider>
      <TreatmentPlanWidgetContent props={props} />
    </ClinicalNoteContextProvider>
  );
}
