import Loader from 'components/shared/Loader/Loader';

const TestListItem = ({ test, clickHandler, index, loading }) => {
  return (
    <div
      data-qa={`test-list-${index}`}
      className="flex justify-between items-center gap-2 !mb-1 cursor-pointer rounded-lg !p-2 text-sm text-primary-900 transition-all hover:bg-primary-50"
      onClick={() => clickHandler(test)}>
      {test.testName}
      {loading && <Loader outlined />}
    </div>
  );
};

export default TestListItem;
