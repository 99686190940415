import React, { useEffect, useState } from 'react';
import { iaRa } from 'lib/helpers/utility';
import UserItem from './UserItem';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { useOutletContext } from 'react-router-dom';

const PracticeUsers = ({ setSelectedOwner, selectedOwner }) => {
  const { practice } = useOutletContext();
  const [members, setMembers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  useEffect(() => {
    const filteredMembers = iaRa(practice?.members).filter((member) => {
      const fullName = `${member.f_name} ${member.l_name}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setMembers(filteredMembers);
  }, [searchTerm, practice?.members]);

  return (
    <div className="flex flex-1 flex-col gap-[14px] overflow-auto bg-neutral-50 px-[24px] py-4">
      <Input
        id="search"
        type="string"
        placeholder="Search practice user..."
        className="!mt-4"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        rightText={searchTerm && <Icon icon="new-close" onClick={() => setSearchTerm('')} />}
      />
      {iaRa(members).map((member) => {
        const isChecked = member.id === selectedOwner?.id;
        return (
          <UserItem
            key={member.id}
            {...{
              isChecked,
              item: member,
              setSelectedUser: setSelectedOwner,
              f_name: member.f_name,
              l_name: member.l_name
            }}
          />
        );
      })}
    </div>
  );
};

export default PracticeUsers;
