const atom = require('recoil').atom;

module.exports = {
  socketState: atom({
    key: 'socketState',
    default: {
      connected: false
    }
  })
};
