import React from 'react';
import { useNavigate } from 'react-router-dom';

import { calculateBalance } from 'api/Transaction';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Popover from 'components/shared/Popovers/Popover/Popover';

const options = (calculateBalance) => [
  {
    icon: 'new-update',
    label: 'Calculate Balance',
    onClick: calculateBalance
  }
];

const CalculateBalance = ({ practiceId, patientId }) => {
  const navigate = useNavigate();

  const { mutate: calculateBalanceMutate, isLoading } = useCustomMutation({
    invalidateQueryKey: ['patientBalance'],
    errorMessage: 'Failed to calculate patient balance!',
    successMessage: 'Patient balance calculated successfully!',
    mutationFn: () => calculateBalance(navigate, { practiceId, patientId })
  });

  return (
    <Popover
      loading={isLoading}
      disabled={isLoading}
      position="left"
      buttonTheme="neutral"
      optionIconColor="neutral"
      icon="new-context-menu-horizontal"
      options={options(calculateBalanceMutate)}
      activeClassNames={{ button: 'border-neutral-500 bg-white', icon: 'neutral-700' }}
      buttonClassName="border border-solid border-neutral-200 hover:bg-neutral-50 hover:border-neutral-300 cursor-pointer"
    />
  );
};

export default CalculateBalance;
