import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router';

import _ from 'lodash';

import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { FormBuilderContextProvider } from 'lib/context/FormBuilder/FormBuilderContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useCustomForm } from 'lib/hooks/queries/customForms/useCustomForm';
import { useCustomFormTypes } from 'lib/hooks/queries/customForms/useCustomFormTypes';
import { useFormPackets } from 'lib/hooks/queries/forms/useFormPackets';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import TableCounter from 'components/shared/Table/TableCounter';
import Wizard from 'components/shared/Wizard/Wizard';

import AssignToPacket from './components/AssignToPacket/AssignToPacket';
import Builder from './components/Builder/Builder';
import FooterButtons from './components/FooterButtons';
import NameAndType from './components/NameAndType/NameAndType';
import Review from './components/Review/Review';
import ViewForm from './components/ViewForm';
import { defaultColumns } from './lib/helpers';

const AdminFormBuilder = () => {
  return (
    <Allowed requiredPermissions="form.read">
      <FormBuilderContextProvider>
        <TableContextProvider cols={defaultColumns} name="forms">
          <FormBuilderProcess />
        </TableContextProvider>
      </FormBuilderContextProvider>
    </Allowed>
  );
};

const FormBuilderProcess = () => {
  usePageTitle('Forms');
  const [formSearchTerm, setFormSearchTerm] = useState('');
  const [debouncedFormSearchTerm, setDebouncedFormSearchTerm] = useState(formSearchTerm);
  const [formToView, setFormToView] = useState({});
  const [viewFormModal, setViewFormModal] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedFormSearchTerm(formSearchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [formSearchTerm]);

  const {
    autoSave,
    saveButtonText,
    breadCrumbs,
    setBreadCrumbs,
    formik,
    openModal,
    setOpenModal,
    handleClose,
    debouncedSearchTerm: debouncedPacketSearchTerm,
    handleSave,
    isFormSavingLoading,
    isEdit,
    practiceId,
    showNavigationButtons,
    handleAutoSave
  } = useFormBuilderContext();

  const { limit, page, setPage, sort } = useTableContext();

  const {
    data: formData = {},
    isLoading,
    isFetching
  } = useCustomForm({
    params: {
      practice_id: id,
      searchTerm: formSearchTerm,
      page,
      sort,
      limit,
      withCount: true
    },
    dependencies: [debouncedFormSearchTerm, page, sort, limit, id],
    page,
    sort
  });

  const { data: formTypesList } = useCustomFormTypes({});

  const { data: formPackets } = useFormPackets({
    params: {
      practice_id: practiceId,
      withGraphFetched: {
        providers: true,
        services: true,
        allForms: true
      },
      filters: { name: debouncedPacketSearchTerm }
    },
    dependencies: [debouncedPacketSearchTerm]
  });

  const typeOptions = useMemo(() => {
    if (formTypesList?.form_functions?.length > 0) {
      return formTypesList.form_functions.map((f) => ({
        label: f.name,
        value: f.id
      }));
    }
    return [];
  }, [formTypesList]);

  const actionViews = {
    name_and_type: {
      component: <NameAndType formik={formik} currentBreadcrumb="name_and_type" />,
      footer: showNavigationButtons ? (
        <FooterButtons
          buttonText="Go to builder"
          nextTo="builder"
          setBreadCrumbs={setBreadCrumbs}
        />
      ) : (
        <FooterButtons />
      )
    },
    builder: {
      component: <Builder formik={formik} currentBreadcrumb="builder" formTypes={typeOptions} />,
      footer: !isEdit ? (
        <FooterButtons
          buttonText="Complete & Review"
          nextTo="review"
          backTo="name_and_type"
          setBreadCrumbs={setBreadCrumbs}
        />
      ) : (
        <FooterButtons buttonText="Submit" isLastStep setBreadCrumbs={setBreadCrumbs} />
      )
    },
    review: {
      component: <Review currentBreadcrumb="review" />,
      footer: (
        <FooterButtons
          buttonText="Add to a form packet"
          nextTo="assign_to_packet"
          backTo="builder"
          showSaveAndExit
          setBreadCrumbs={setBreadCrumbs}
        />
      )
    },
    assign_to_packet: {
      component: <AssignToPacket currentBreadcrumb="assign_to_packet" formPackets={formPackets} />,
      footer: (
        <FooterButtons
          buttonText="Submit"
          backTo="review"
          isLastStep
          setBreadCrumbs={setBreadCrumbs}
          showSkip
        />
      )
    }
  };

  const getCurrentView = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].component;
  };
  const getCurrentFooter = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].footer;
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onCellClicked = async (e) => {
    if (e.column.getColId() === 'actions') return;
    if (['actions', '0'].includes(e?.column?.colId)) return;
    setFormToView(e?.data);
    setViewFormModal(true);
  };

  const forms = formData.forms || [];
  const count = formData.formsCount || 0;
  return (
    <>
      <Header>
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Input
            type="string"
            placeholder="Search Forms..."
            value={formSearchTerm}
            icon="new-search"
            inputWrapperClassName="h-[30px]"
            className="md:w-[200px]"
            onChange={(e) => {
              setFormSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              formSearchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setFormSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
          <Allowed requiredPermissions="form.create">
            <Button
              text="New Form"
              size="small"
              onClick={() => setOpenModal(true)}
              data-qa="new-form-btn"
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex  h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={forms}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              defaultColDef={defaultColDef}
              columnDef={defaultColumns}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {openModal && (
        <Wizard
          isOpen={openModal}
          handleClose={handleClose}
          handleSaveProgress={handleSave}
          saveProgressLoading={isFormSavingLoading}
          handleAutoSave={handleAutoSave}
          showAutoSave={true}
          showSaveProgress={true}
          title="Form Builder"
          autoSave={autoSave}
          saveButtonText={saveButtonText}
          breadcrumbs={breadCrumbs}
          footerButtons={getCurrentFooter()}>
          {getCurrentView()}
        </Wizard>
      )}
      {viewFormModal && (
        <ViewForm
          form={formToView}
          setViewFormModal={setViewFormModal}
          viewFormModal={viewFormModal}
        />
      )}
    </>
  );
};

export default AdminFormBuilder;
