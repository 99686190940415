import { iaRa } from 'lib/helpers/utility';

export const shouldDisableProcedureProduct = ({
  isClinicalNoteLocked = false,
  originalProceduresProducts,
  productId = null,
  procedureCode = null,
  type = null
}) => {
  if (!type) return;

  return (
    isClinicalNoteLocked &&
    iaRa(originalProceduresProducts?.[type])?.some((row) => {
      if (type === 'procedures') {
        return row?.procedure_code === procedureCode;
      }

      if (type === 'products') {
        return row?.id === productId;
      }
    })
  );
};
