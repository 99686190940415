/**
 * Calculates horizontal position for the popover panel
 * @param {DOMRect} buttonRect - Bounding rectangle of the button element
 * @param {number} panelWidth - Width of the panel
 * @param {string} position - Position preference ('right' or 'left')
 * @returns {Object} Horizontal position properties
 */
const getHorizontalPosition = (buttonRect, panelWidth, position) => {
  if (position === 'right') {
    return {
      left:
        buttonRect.left + panelWidth > window.innerWidth
          ? `${window.innerWidth - panelWidth}px`
          : `${buttonRect.left}px`,
      right: 'unset'
    };
  }
  return {
    right:
      window.innerWidth - buttonRect.right + panelWidth > window.innerWidth
        ? `${window.innerWidth - panelWidth}px`
        : `${window.innerWidth - buttonRect.right}px`,
    left: 'unset'
  };
};

/**
 * Calculates the final position for the popover panel
 * @param {Object} params - Position calculation parameters
 * @param {DOMRect} params.buttonRect - Bounding rectangle of the button element
 * @param {DOMRect} params.panelRect - Bounding rectangle of the panel element
 * @param {number} params.panelWidth - Width of the panel
 * @param {string} params.position - Position preference ('right' or 'left')
 * @returns {Object} Complete position properties
 */
export const calculatePopoverPosition = ({ buttonRect, panelRect, panelWidth, position }) => {
  // Calculate space constraints
  const hasSpaceRight = buttonRect.right + panelWidth <= window.innerWidth;
  const hasSpaceLeft = buttonRect.left - panelWidth >= 0;
  const hasHorizontalSpace = hasSpaceRight || hasSpaceLeft;

  const hasSpaceBottom = buttonRect.bottom + panelRect.height <= window.innerHeight;
  const hasSpaceTop = buttonRect.top - panelRect.height >= 0;
  const hasVerticalSpace = hasSpaceBottom || hasSpaceTop;

  // Case 1: No space top or bottom - show in center right
  if (!hasSpaceTop && !hasSpaceBottom && hasHorizontalSpace) {
    return {
      position: 'fixed',
      top: `${Math.max(0, (window.innerHeight - panelRect.height) / 2)}px`,
      left: hasSpaceRight ? `${buttonRect.right + 6}px` : `${buttonRect.left - panelWidth - 6}px`,
      right: 'unset'
    };
  }

  // Case 2: No space bottom - show above
  if (!hasSpaceBottom && hasHorizontalSpace) {
    return {
      position: 'fixed',
      top: `${buttonRect.top - panelRect.height - 6}px`,
      ...getHorizontalPosition(buttonRect, panelWidth, position)
    };
  }

  // Case 3: No space on the right and left and has vertical space
  if (!hasHorizontalSpace && hasVerticalSpace) {
    return {
      position: 'fixed',
      top: `${buttonRect.bottom + 6}px`,
      ...getHorizontalPosition(buttonRect, panelWidth, position)
    };
  }

  // Case 4: No space on the right and left and no vertical space
  if (!hasHorizontalSpace && !hasVerticalSpace) {
    return {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    };
  }

  // Case 5: Default - show below
  return {
    position: 'fixed',
    top: `${buttonRect.bottom + 6}px`,
    ...getHorizontalPosition(buttonRect, panelWidth, position)
  };
};
