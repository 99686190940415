const { camelToKebabCase, iaRa, io, ia } = require('lib/helpers/utility');

const formsThatNeedKebabCase = [
  'riskFactors',
  'sessionQuotesAndContents',
  'activeSymptoms',
  'functionalImpairment',
  'cptCodes',
  'diagnosisCodes',
  'patientNeeds',
  'problemManifestationDefinition',
  'timeFrames',
  'riskAndCoMorbidities'
];

export const returnFormName = ({ formName = null }) => {
  if (!formName) return null;

  if (formsThatNeedKebabCase.includes(formName)) {
    return camelToKebabCase(formName);
  }

  return formName;
};

export const getAdvancedForms = ({
  type = null,
  advancedHP = [],
  advancedSOAP = [],
  customFormTypes = [],
  cnDisplaySettings = null
}) => {
  const filteredAdvancedForms = getFilteredAdvancedForms({
    type,
    advancedHP,
    advancedSOAP,
    customFormTypes
  });

  if (typeof filteredAdvancedForms === 'string') return filteredAdvancedForms;
  if (!ia(filteredAdvancedForms)) return {};

  return Object.fromEntries(
    iaRa(filteredAdvancedForms).map((item, index) => {
      const title = item?.custom_title;
      return [
        title,
        {
          title: item?.form?.name || item?.name,
          enabled: true,
          position: Object.keys(cnDisplaySettings?.sections?.[type] || {})?.length + index + 1
        }
      ];
    })
  );
};

const getFilteredAdvancedForms = ({
  type = null,
  advancedHP = [],
  advancedSOAP = [],
  customFormTypes = []
}) => {
  if (['hp', 'intake'].includes(type)) {
    return iaRa(advancedHP).filter((value) => io(value));
  }

  if (['soap', 'progress'].includes(type)) {
    return iaRa(advancedSOAP).filter((value) => io(value));
  }

  const currentCustomFormType = customFormTypes?.[type];
  if (ia(currentCustomFormType)) {
    return currentCustomFormType;
  }

  return advancedFormStatus.NOT_SUPPORTED;
};

export const advancedFormStatus = {
  NOT_SUPPORTED: 'NOT_SUPPORTED'
};
