import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { checkMedicalNecessity } from 'api/LabSoftOrder';

export const useMedicalNecessityCheck = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(
    ['medicalNecessityCheck', ...dependencies],
    () => checkMedicalNecessity(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60,
      staleTime: 1000 * 60 * 60,
      ...options
    }
  );
};
