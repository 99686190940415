import React, { useRef } from 'react';
import Accordion from '../../../../shared/Accordion';
import Subjective from './Subjective';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';
import ClinicalNoteCurrentNoteTypeNotesMyScribeWarning from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeWarning';

const SubjectiveSection = ({ components, sectionRef, options, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const subjectiveRef = useRef(null);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.subjective?.title}
      id={components?.subjective?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Subjective sectionRef={sectionRef} subjectiveRef={subjectiveRef} />
      <ClinicalNoteCurrentNoteTypeNotesMyScribeWarning />
    </Accordion>
  );
};

export default SubjectiveSection;
