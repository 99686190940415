import React, { useState } from 'react';
import Moment from 'moment';
import Box from 'components/shared/Box/Box';

export default function Medication({ medication, handleRemove, handleRefill }) {
  return (
    <div>
      <div className="items-stretch block basis-0 grow shrink min-h-min -mx-3 -mt-3">
        <div className="items-stretch block basis-0 grow shrink min-h-min p-3">
          <Box className={`tile m-0 notification`}>
            <div>
              <div>{medication.medication.name}</div>
              <div>{medication.medication.description}</div>
              <div>
                <div>{medication.instructions}</div>
                <hr className="my-1" />
                <div>Prescription Details</div>
                <div className="flex-wrap -ml-3 -mr-3 -mt-3 pt-2 w-1/3">
                  <div className="block p-3 w-1/2">
                    <div>{`Prescribed: ${Moment(medication.created_at).format(
                      'MMM DD, YYYY'
                    )}`}</div>
                    <div>{`Approved by: ${medication.practitioner.fullName}`}</div>
                    <div>{`Practice: ${medication.practice.name}`}</div>
                  </div>
                  <div className="block p-3 w-1/2">
                    <div>{`Dose: ${medication.dose} mg`}</div>
                    <div>{`Frequency: ${medication.frequency}`}</div>
                    <div>{`Length: ${medication.length}`}</div>
                    <div>{`Route: ${medication.route}`}</div>
                  </div>
                </div>
              </div>
            </div>
            {medication.status === 'active' && (
              <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                <div className="block p-3 mx-2">
                  <div
                    onClick={() => handleRemove(medication.id, medication.patient_id)}
                    className={`button `}>
                    Remove
                  </div>
                </div>
              </div>
            )}
          </Box>
        </div>
      </div>
    </div>
  );
}
