import { createAllergiesNarrativeHelper } from 'components/shared/Forms/Custom/lib/allergiesNarrativeHelper';
import { createFamilyHistoryNarrative } from 'components/shared/Forms/Custom/lib/familyHistoryNarrativeHelper';
import formatMedicationsNarrative from 'components/shared/Forms/Custom/lib/formatMedicationsNarrative';
import { formatSections as formatSectionsV1 } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV1';
import { createPastMedicalHistoryNarrative } from 'components/shared/Forms/Custom/lib/pastMedicalHistoryNarrativeHelper';
import { createSocialHistoryNarrative } from 'components/shared/Forms/Custom/lib/socialHistoryNarrativeHelper';
import { createSurgicalHistoryNarrative } from 'components/shared/Forms/Custom/lib/surgicalHistoryNarrativeHelper';
import { createVaccineNarrative } from 'components/shared/Forms/Custom/lib/vaccineNarrativeHelper';
import { isEmpty } from 'lib/helpers/utility';

const FORMAT_SECTION_V1_FORMS = ['weightLossAdministration'];
const FORMAT_MEDICATIONS_FORMS = ['medicationHistory'];
const FORMAT_SOCIAL_HISTORY_FORMS = ['socialHistory'];
const FORMAT_MEDICAL_HISTORY_FORMS = ['medicalHistory'];
const FORMAT_FAMILY_HISTORY_FORMS = ['familyHistory'];
const FORMAT_VACCINE_FORMS = ['vaccines'];
const FORMAT_ALLERGIES_FORMS = ['allergies'];
const FORMAT_SURGICAL_HISTORY_FORMS = ['surgicalHistory'];

export const generateNarrativeIfEmpty = ({ form }) => {
  let result = null;

  const formType = form?.type;

  const formTypeNarrativeOptions = {
    allergies: {
      sectionData: 'allergies',
      sectionName: 'Allergy'
    },
    familyHistory: {
      sectionData: 'row',
      sectionName: 'Family History'
    },
    surgicalHistory: {
      sectionData: 'row',
      sectionName: 'Surgical History'
    },
    vaccines: {
      sectionData: 'vaccines',
      sectionName: 'Vaccine'
    },
    weightLossAdministration: {
      sectionData: 'weightLossAdministration',
      sectionName: 'Weight Loss Administration'
    },
    medicalHistory: {
      sectionName: 'Past Medical History'
    },
    socialHistory: {
      sectionName: 'Social History'
    }
  };

  const narrativeOptions = formTypeNarrativeOptions?.[formType] ?? formType;

  if (!narrativeOptions) return null;

  const completedNarrativeOptions = {
    data: form?.response,
    ...narrativeOptions
  };

  if (FORMAT_SECTION_V1_FORMS.includes(formType)) {
    result = formatSectionsV1(completedNarrativeOptions);
  } else if (FORMAT_MEDICATIONS_FORMS.includes(formType)) {
    result = formatMedicationsNarrative(form?.response?.medications);
  } else if (FORMAT_SOCIAL_HISTORY_FORMS.includes(formType)) {
    result = createSocialHistoryNarrative(form?.response);
  } else if (FORMAT_MEDICAL_HISTORY_FORMS.includes(formType)) {
    result = createPastMedicalHistoryNarrative(form?.response);
  } else if (FORMAT_FAMILY_HISTORY_FORMS.includes(formType)) {
    result = createFamilyHistoryNarrative(form?.response);
  } else if (FORMAT_VACCINE_FORMS.includes(formType)) {
    result = createVaccineNarrative(form?.response);
  } else if (FORMAT_ALLERGIES_FORMS.includes(formType)) {
    result = createAllergiesNarrativeHelper(form?.response);
  } else if (FORMAT_SURGICAL_HISTORY_FORMS.includes(formType)) {
    result = createSurgicalHistoryNarrative(form?.response);
  }

  return !isEmpty(result?.trim()) ? result?.trim() : 'No known details';
};
