import React from 'react';
import { Link } from 'react-router-dom';
import useCopy from 'lib/hooks/useCopy';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { calculateAge, formatDate, formatPhoneNumber } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import ProfilePicture from 'components/Profile/ProfilePicture';

const Header = ({ appointment }) => {
  const { device } = useUIContext();
  const { copyToClipboard } = useCopy();
  const { userState, saveDisplaySettings } = useDisplaySettings();
  const phoneNumber = formatPhoneNumber(appointment.patient.phone);

  const handleOldVersion = () => {
    saveDisplaySettings({
      ...userState.display_settings,
      appointment: {
        ...userState.display_settings?.appointment,
        isNew: false
      }
    });
  };

  const copyPhoneNumber = () => {
    copyToClipboard(phoneNumber);
    showAlert({
      title: 'Phone number copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  const copyEmail = () => {
    copyToClipboard(appointment.patient.email);
    showAlert({
      title: 'Email copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  return (
    <div className="flex w-full items-center justify-between gap-4 pr-4">
      <div className="flex gap-2">
        <Link
          data-qa="patient-overview"
          to={`/portal/charts/${appointment?.patient?.id}`}
          target="_blank"
          className="flex cursor-pointer items-center gap-2">
          <ProfilePicture
            size={device === 'desktop' || device === 'laptop' || device === 'tablet' ? 24 : 48}
            fontSize="text-sm sm:text-2xl"
            firstName={appointment.patient.f_name}
            lastName={appointment.patient.l_name}
            image={appointment.patient.header_photo}
            id={appointment.patient.id}
          />
        </Link>
        <div className="flex flex-wrap items-center gap-x-2">
          <Link
            className="flex items-center gap-[2px]"
            target="_blank"
            to={`/portal/charts/${appointment?.patient?.id}`}>
            <span className="whitespace-nowrap text-base font-500 leading-6 text-primary-900">
              {appointment.patient.fullName}
            </span>
            <div className="flex size-[24px] items-center justify-center">
              <Icon icon="new-link" size={11} className="cursor-pointer" />
            </div>
            <span className="text-sm text-primary-600">{`#${appointment.patient.id}`}</span>
          </Link>
          <div className="flex flex-wrap items-center gap-2">
            {appointment.patient.dob && (
              <div className="border-0 border-l border-solid border-neutral-300 pl-2 sm:hidden md:hidden">
                <span className="text-sm text-primary-900">{`${formatDate(appointment.patient.dob)} (${calculateAge({ dateOfBirth: appointment.patient.dob })} years old)`}</span>
              </div>
            )}
            {appointment.patient.phone && (
              <div
                className="cursor-pointer border-0 border-l border-solid border-neutral-300 pl-2 sm:border-none sm:pl-0 md:border-none md:pl-0"
                onClick={copyPhoneNumber}>
                <span className="text-sm text-neutral-700">{phoneNumber}</span>
              </div>
            )}
            {appointment.patient.email && (
              <div
                className="cursor-pointer border-0 border-l border-solid border-neutral-300 pl-2"
                onClick={copyEmail}>
                <span className="text-sm text-neutral-600">{appointment.patient.email}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        {/* Button is Temporary */}
        <Button
          text="Old version"
          size="small"
          transparent
          onClick={handleOldVersion}
          textClassName="whitespace-nowrap"
        />
        <span className="whitespace-nowrap text-sm font-500 text-neutral-600">{`Appointment #${appointment.id}`}</span>
      </div>
    </div>
  );
};

export default Header;
