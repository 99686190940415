/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import ProductForm from './ProductForm';
import { useFormik } from 'formik';
import { requestApi } from '../../../../../api/Api';
import { getFinalProductObjShape } from '../lib/helpers';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Skeleton from 'react-loading-skeleton';
import validationSchema from '../lib/validationSchema';
import { initialValues } from '../lib/initials';
import { showAlert } from '../../../../shared/Alert/Alert';

const NewProduct = forwardRef(({ onClose, product, filters }, ref) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({ loading: false });
  const formRef = useRef();
  const queryClient = useQueryClient();

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const formik = useFormik({
    initialValues: initialValues(product),
    validationSchema,
    // enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const productToBeCreated = getFinalProductObjShape(values);

      setSubmitting(true);
      product
        ? await updateProduct(productToBeCreated, resetForm)
        : await createProduct(productToBeCreated, resetForm);
      setSubmitting(false);
    }
  });

  const { data: categories } = useQuery({
    queryKey: ['getProductCategories'],
    queryFn: getCategories,
    refetchOnMount: true,
    refetchOnWindowFocus: true
  });

  async function getCategories() {
    const response = await requestApi({
      url: '/api/product/category/get',
      navigate
    });

    return response;
  }

  const createProduct = async (newProduct, resetForm) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);

      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create products!",
          color: 'danger'
        });
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getProducts', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            products: [...prevData.products, data.product]
          };
        }
      );
      resetForm();
      onClose();
      toast.success('New product added');
    };

    requestApi({
      url: 'api/product/create',
      navigate,
      onError,
      onSuccess,
      params: {
        product: {
          ...newProduct,
          category_id: newProduct.category_id ? newProduct.category_id : null
        }
      }
    });
  };

  const updateProduct = async (productToBeUpdated, resetForm) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);
      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to update products!",
          color: 'danger'
        });
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getProducts', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            products: prevData.products.map((prod) => {
              if (prod.id === data.product.id) {
                return data.product;
              }
              return prod;
            })
          };
        }
      );

      toast.success('Product updated');
      resetForm();
      onClose();
    };
    requestApi({
      url: `/api/product/update`,
      navigate,
      onError,
      onSuccess,
      params: { product: { ...productToBeUpdated, id: product.id } }
    });
  };

  return (
    <div>
      <ProductForm
        formik={formik}
        categories={categories?.categories}
        formRef={formRef}
        updating={!!product}
      />
      {show.loading && <Skeleton count={1} />}
    </div>
  );
});

export default NewProduct;
