import React from 'react';
import { useGLTF } from '@react-three/drei';

export function FaceMale(props) {
  const { nodes, materials } = useGLTF(
    `${process.env.ENV !== 'local' ? '/public' : ''}/models/face_male.glb`
  );
  return (
    <group {...props} dispose={null}>
      <group position={[0.028, 0.245, 0.199]} rotation={[Math.PI / 2, 0, 0]} scale={0.094}>
        <mesh geometry={nodes.Mesh002.geometry} material={materials['body_skin.003']} />
        <mesh geometry={nodes.Mesh002_1.geometry} material={materials['head_skin.003']} />
        <mesh geometry={nodes.Mesh002_2.geometry} material={materials['eye2.004']} />
      </group>
    </group>
  );
}