import Tippy from '@tippyjs/react';
import cs from 'classnames';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { clinicalNoteAbbreviations } from '../../../../../../constants';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from '../../../../../../lib/helpers/utility';
import RadioButton from '../../../../../shared/RadioButton/RadioButton';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';

const List = ({ open }) => {
  const [initials, setInitials] = useState([]);
  const { type, formName } = useParams();
  const {
    components,
    saveNoteRef,
    loading: clinicalNoteLoading,
    saveButtonText
  } = useClinicalNoteContext();

  useEffect(() => {
    const getAbbreviation = (title) => {
      const found = clinicalNoteAbbreviations.find((item) => item.name === title);
      if (found) return { abbreviation: found.abbreviation, name: found.name };

      const words = title?.split(' ');
      if (words?.length === 1) {
        return { abbreviation: words[0].substring(0, 2), name: title };
      } else if (words?.length > 1) {
        return {
          abbreviation: words[0].substring(0, 1) + '/' + words[1].substring(0, 1),
          name: title
        };
      }
      return { abbreviation: '', name: '' };
    };

    const updateInitials = Object.entries(components)
      ?.map(([key, value]) => {
        if (value) {
          const { abbreviation, name } = getAbbreviation(value?.title);
          return { title: abbreviation, name, path: value.path };
        }
        return null;
      })
      .filter(Boolean);

    if (open) {
      setInitials(components);
    } else {
      setInitials(updateInitials);
    }
  }, [components, open]);

  if (clinicalNoteLoading) {
    return <Skeleton height="40px" count={15} />;
  }

  if (components?.length === 0 || components === null || !io(initials)) {
    const propsForNotFoundNote = {
      icon: 'new-notev1',
      iconSize: 32,
      title: 'No data',
      titleClassName: '!mt-3',
      description: 'No data in display settings'
    };

    return <NotFound {...propsForNotFoundNote} />;
  }

  const cnSaving = saveButtonText === 'Saving';

  const onNavLinkClick = (event) => {
    if (clinicalNoteLoading || cnSaving) {
      event?.preventDefault();
      return;
    }

    saveNoteRef?.current?.onSaveNote();
  };

  return (
    <div className="flex h-[calc(100vh-147px)] flex-col gap-y-2 overflow-auto py-[6px] !pr-2 pl-[10px]">
      {io(initials) &&
        Object.values(initials)?.map((item, index) => (
          <Tippy
            content={
              cnSaving
                ? 'Please wait until the note is saved'
                : Object.values(components)[index]?.title
            }
            placement="right"
            className="tippy-dark"
            key={index}>
            <NavLink
              to={`${type}/${item.path}`}
              className={({ isActive }) =>
                cs(
                  'flex h-[46px] w-full shrink-0 cursor-pointer select-none items-center overflow-hidden !rounded-lg border border-solid p-[14px] !pl-3 text-center text-base font-500 text-neutral-800',
                  (!formName && index === 0) || isActive
                    ? '!border-primary-500 bg-primary-50 '
                    : '!border-transparent'
                )
              }
              onClick={onNavLinkClick}>
              {({ isActive }) => (
                <RadioButton
                  label={item?.title}
                  disabled={cnSaving}
                  value={item.title}
                  checked={isActive || (!formName && index === 0)}
                  data-qa={`left-sidebar-checkbox-${item.title}`}
                  className={cs(
                    'w-full text-base',
                    open && 'w-auto overflow-hidden text-ellipsis whitespace-nowrap',
                    (!formName && index === 0) || isActive
                      ? 'font-600 text-primary-500'
                      : 'text-primary-800'
                  )}
                />
              )}
            </NavLink>
          </Tippy>
        ))}
    </div>
  );
};

export default List;
