import _ from 'lodash';
import { requestApi } from './Api';

export const getCustomForms = async (navigate) => {
  const allForms = [];
  const { forms: questionnaires } = await requestApi({
    navigate,
    url: '/api/practice/settings/forms/questionnaires/get',
    onError: () => {}
  });
  if (questionnaires) allForms.push(...questionnaires);

  const { forms: checkin } = await requestApi({
    navigate,
    url: '/api/practice/settings/forms/checkin/get',
    onError: () => {}
  });
  if (checkin) allForms.push(...checkin);

  const { forms: onboarding } = await requestApi({
    navigate,
    url: '/api/practice/settings/forms/onboarding/get',
    onError: () => {}
  });
  if (onboarding) allForms.push(...onboarding);

  const { forms: advanced_soap } = await requestApi({
    navigate,
    url: '/api/practice/settings/forms/advanced_soap/get',
    onError: () => {}
  });
  if (advanced_soap) allForms.push(...advanced_soap);

  return _.orderBy(allForms, 'position');
};

export const getCustomFormsByIds = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/get_responses_by_ids',
    params,
    onError: () => {}
  });

export const getCustomForm = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/get',
    params,
    onError: () => {}
  });

export const updateCustomForm = async (navigate, options) =>
  requestApi({
    url: '/api/form/update',
    params: options,
    navigate
  });

export const createCustomForm = async (navigate, options) =>
  requestApi({
    url: '/api/form/create',
    params: options,
    navigate
  });

export const deleteCustomForm = async (navigate, options) =>
  requestApi({
    url: '/api/form/delete',
    params: options,
    navigate
  });

export const duplicateCustomForm = async (navigate, options) =>
  requestApi({
    url: '/api/form/duplicate',
    params: options,
    navigate
  });

export const getCustomFormTypes = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/functions/get',
    params,
    onError: () => {}
  });
export const getOnlyCustomForm = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/functions/get_only_custom_form',
    params,
    onError: () => {}
  });

export const upsertCustomFormType = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/functions/create',
    params,
    onError: () => {}
  });
export const deleteCustomFormType = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/functions/delete',
    params,
    onError: () => {}
  });
export const upsertCustomFormResponse = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/custom_form_response/create',
    params,
    onError: () => {}
  });

export const getCustomFormResponse = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/custom_form_response/read',
    params,
    onError: () => {}
  });

export const deleteCustomFormResponse = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/custom_form_response/delete',
    params,
    onError: () => {}
  });

export const upsertResponse = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/upsert_response',
    params,
    onError: () => {}
  });

export const deleteResponse = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/delete_response',
    params,
    onError: () => {}
  });

export const linkAppointment = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/form/link_appointment',
    params,
    onError: () => {}
  });

export const getFormReview = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/patient/checkin/form/getReview',
    params
  });
export const upsertMultiCustomFormResponse = async (navigate, params, onError, onSuccess) => {
  await requestApi({
    navigate,
    url: '/api/custom_form_response/multi_create',
    params,
    onError,
    onSuccess
  });
};
