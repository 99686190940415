import React, { useEffect, useState } from 'react';

import { useFormikContext } from 'formik';

import { getDirtyValues } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import getAppointmentCategoryChanges from '../lib/getAppointmentCategoryChanges';

const UnsavedChanges = ({ isUnsaved, setIsUnsaved, handleClose }) => {
  const [viewChanges, setViewChanges] = useState(false);
  const [changes, setChanges] = useState({});
  const { values, initialValues, handleSubmit } = useFormikContext();

  useEffect(() => {
    if (viewChanges) {
      const dirtyValues = getDirtyValues(values, initialValues);

      setChanges(getAppointmentCategoryChanges(dirtyValues));
    }

    if (!isUnsaved) {
      setChanges({});
      setViewChanges(false);
    }
  }, [viewChanges, isUnsaved]);

  return (
    <Confirm
      handleOpen={isUnsaved}
      handleClose={() => setIsUnsaved(false)}
      handleContinue={handleClose}
      title="Unsaved changes"
      message="You have unsaved changes. Do you want to close this modal?"
      primaryBtnTxt="Yes"
      secondaryBtnTxt="No"
      isFooter={false}
      icon="new-calendar-red"
      variant="danger">
      <div
        className="mt-2 flex cursor-pointer items-center gap-1"
        onClick={() => setViewChanges(!viewChanges)}>
        <span className="text-sm text-neutral-600">{viewChanges ? 'Hide' : 'View Changes'}</span>
        <Icon
          icon={viewChanges ? 'chevron-up' : 'chevron-down'}
          className="mt-[2px]"
          size={12}
          onClick={() => setViewChanges(!viewChanges)}
        />
      </div>
      {viewChanges && (
        <div className="mt-1 max-w-[512px] overflow-hidden px-2">
          <span className="text-sm text-primary-500">Changes on following categories:</span>
          <div className="mt-1 flex flex-wrap items-center gap-2">
            {Object.values(changes).map((it) =>
              it.value ? (
                <span
                  key={it.label}
                  className="rounded-full border-2 border-solid border-primary-300 px-2 py-1 text-sm text-primary-800">
                  {it.label}
                </span>
              ) : null
            )}
          </div>
        </div>
      )}
      <div className="mt-4 flex items-center justify-end gap-2">
        <Button text="Yes" color="danger" onClick={handleClose} />
        <Button text="No" color="neutral" outlined onClick={() => setIsUnsaved(false)} />
        <Button
          text="Save & Close"
          outlined
          onClick={() => {
            handleSubmit();
            handleClose();
          }}
        />
      </div>
    </Confirm>
  );
};

export default UnsavedChanges;
