import React, { useEffect, useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from '../../api/InterimApi';
import { ia } from '../../lib/helpers/utility';
import useWebSocket from '../../lib/hooks/useMessageWebSocket';
import useOutsideClick from '../../lib/hooks/useOutsideClick';
import { currentPractice } from '../practice/practiceState';
import ActionButton from '../shared/Button/ActionButton/ActionButton';
import Skeleton from '../shared/Skeleton/Skeleton';
import NewMessage from './components/NewMessage';
import { notificationState } from './notificationState';

const NewMessages = ({ className, unreadMessagesCount }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newMessages, setNewMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(0);
  const [notificationsState, setNotificationsState] = useRecoilState(notificationState);
  const practice = useRecoilValue(currentPractice);
  const messageUnreadCount = useRef(null);
  const scrollableDivRef = useRef(null);
  const popoverRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useOutsideClick(popoverRef, () => handleClose());

  useEffect(() => {
    if (notificationsState.userId) {
      messageUnreadCount.current = notificationState.userId;
    }
  }, [notificationsState]);

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    const { userId, userBody } = notificationsState;
    if (userId) {
      setNewMessages(
        newMessages.map((message) => {
          const { user, userUnreadMessages } = message;
          return user.id === userId
            ? {
              ...message,
              userUnreadMessages: Number(userUnreadMessages) + 1,
              userMessages: { body: userBody }
            }
            : message;
        })
      );
      setNotificationsState((prev) => ({ ...prev, userId: null, userBody: null }));
    }
  }, [notificationsState]);

  useEffect(() => {
    if (!scrollableDivRef.current) return;

    const container = scrollableDivRef.current;
    container.addEventListener('scroll', onScrollToTop);
    return () => {
      container.removeEventListener('scroll', onScrollToTop);
    };
  }, [scrollableDivRef.current]);

  useEffect(() => {
    getMessages({ scrollable: true });
  }, [page]);

  const onScrollToTop = async () => {
    const container = scrollableDivRef.current;
    if (Math.round(container.scrollTop + container.clientHeight) === container.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  };

  const getMessages = async ({ scrollable = false } = {}) => {
    try {
      !scrollable && setLoading(true);
      scrollable && setScrollLoading(true);
      let params = { withal: { userMessages: true }, limit: 6 };
      if (scrollable) {
        params = {
          ...params,
          offset: newMessages?.length ?? 0
        };
      }

      const res = await interimApi('/api/practice/messages/get_multiple', params, navigate);
      const { messages = [] } = res.data;

      if (messages?.length === 0) {
        setHasMore(false);
        scrollable && setScrollLoading(false);
        !scrollable && setLoading(false);
        return;
      }

      !scrollable && setLoading(false);
      scrollable && setScrollLoading(false);

      setNewMessages(scrollable ? [...(newMessages ?? []), ...messages] : [...messages]);
    } catch (error) {
      setNewMessages([]);
      Honeybadger.notify(
        `file: Navbar/NewMessages, method: getMessages - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  const notificationCount = (event) => {
    const { patchedMessages, type } = JSON.parse(event.data);

    if (type !== 'messageCount' || Number(messageUnreadCount) < 1) return;

    setNotificationsState((prev) => ({
      ...prev,
      messageUnreadCount: Number(prev.messageUnreadCount) - patchedMessages,
      userId: null,
      body: null
    }));

    setNewMessages((prev) =>
      prev.map((message) => {
        return { ...message, userUnreadMessages: 0 };
      })
    );
  };

  const { ws } = useWebSocket({
    handleMessage: notificationCount
  });

  const readAllMessages = async () => {
    let params = {
      practiceId: practice.id,
      kind: 'message',
      markRead: true
    };
    try {
      const res = await interimApi('/api/practice/messages/update', params, navigate);
      const { messages: patchedMessages } = res.data;
      if (patchedMessages > 0) {
        ws.send(JSON.stringify({ practiceId: practice.id, patchedMessages, type: 'messageCount' }));
      }
    } catch (error) {
      Honeybadger.notify(
        `file: Navbar/NewMessages, method: readAllMessages - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  const onNavigate = (userId, closePopover) => {
    navigate(`/portal/charts/${userId}/communications`);
    closePopover();
  };

  useEffect(() => {
    if (isOpen) getMessages();
  }, [isOpen]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
    setNewMessages([]);
  };

  return (
    <div ref={popoverRef} className={cs('flex items-center', className)}>
      <Popover className="relative !ml-2">
        {({ open, close }) => (
          <>
            <Popover.Button className="relative p-0" onClick={isOpen ? handleClose : handleOpen}>
              <div className="relative">
                <ActionButton icon="new-messages" active={open} />
                {unreadMessagesCount > 0 && (
                  <div className="absolute top-[5px] right-[6px] flex h-[6px] min-w-[6px] items-center justify-center rounded-full bg-danger-400 p-[2px]" />
                )}
              </div>
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-[10px] w-80 !rounded-lg bg-white !p-2 shadow-medium">
              <div className={`flex items-center justify-between gap-1 !p-1`}>
                <span className="flex items-center !gap-x-2">
                  <em className="text-sm font-500 not-italic text-primary-900">Messages</em>
                  {unreadMessagesCount > 0 && (
                    <div className="flex h-[20px] min-w-[20px] items-center justify-center rounded-full bg-primary-500 p-[2px]">
                      <span className="text-[10px] font-500 text-white">{unreadMessagesCount}</span>
                    </div>
                  )}
                </span>
                <span onClick={() => readAllMessages()}>
                  <em className="cursor-pointer text-sm font-400 not-italic text-neutral-500">
                    Mark as read
                  </em>
                </span>
              </div>
              <hr className="m-1" />
              <div ref={scrollableDivRef} className="!m-0 h-64 overflow-auto !p-0">
                {loading ? (
                  <Skeleton count={5} height={50} width="100%" />
                ) : ia(newMessages) ? (
                  <NewMessage
                    onNavigate={onNavigate}
                    practice={practice}
                    hasMore={hasMore}
                    scrollLoading={scrollLoading}
                    newMessages={newMessages}
                    close={close}
                  />
                ) : (
                  <div className="!my-4 text-center text-lg leading-5 text-neutral-600">
                    No messages found.
                  </div>
                )}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </div>
  );
};

export default NewMessages;
