import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import { createPromptTemplate, getPromptTemplates } from 'api/Admin/intelligence/prompts';

import AdminIntelligencePromptForm from 'components/admin/pages/Intelligence/Prompt/form';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Loader from 'components/shared/Loader/Loader';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

import { initialValues, validate } from './helpers';

const AdminIntelligencePromptDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [prompt, setPrompt] = useState();
  const [promptId, setPromptId] = useState();
  const [show, setShow] = useState({ loading: false });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: initialValues(prompt),
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: async (values) => {
      const newRevision = {
        prompt: {
          id: values?.id,
          version: parseInt(values?.version, 10) + 1,
          template_name: values?.template_name,
          body: values?.body,
          options: values?.options,
          ai_model_id: values?.ai_model_id?.value ?? values?.ai_model_id
        }
      };

      if (typeof newRevision?.prompt?.ai_model_id !== 'number') {
        showAlert({ icon: 'new-warning-v2', color: 'danger', title: 'AI Model Required!' });
        return;
      }

      const res = await createPromptTemplate(navigate, newRevision);

      searchParams.set('promptId', `${res?.promptTemplate?.id}v${res?.promptTemplate?.version}`);
      // setSearchParams((sp) =>
      //   sp.set('promptId', `${res?.promptTemplate?.id}v${res?.promptTemplate?.version}`)
      // );
      // getPrompt();
      await queryClient.invalidateQueries({ queryKey: [`ai_prompt_template_${promptId?.id}`] });
      setSearchParams({
        promptId: res?.promptTemplate?.id,
        version: res?.promptTemplate?.version
      });
    }
  });

  useEffect(() => {
    if (searchParams.get('promptId')) {
      getPrompt();
    }
  }, [searchParams]);

  const getPrompt = async () => {
    const paramPromptId = parseInt(searchParams?.get('promptId'), 10);
    const paramVersion =
      searchParams?.get('version') !== '0' ? parseInt(searchParams?.get('version'), 10) : 0;
    if (isNaN(paramVersion)) {
      return;
    }
    setShow((ps) => ({ ...ps, loading: true }));
    setPromptId({ id: paramPromptId, version: paramVersion });
    const res = await getPromptTemplates(navigate, {
      id: paramPromptId,
      version: paramVersion
    });
    setPrompt({
      ...res?.result,
      ai_model_id: {
        value: res?.result?.ai_model_id,
        label: res?.result?.ai_model_name || res?.result?.ai_model_id
      },
      ai_model_name: undefined
    });
    Object.keys(res?.result).map((v) => {
      switch (v) {
        case 'ai_model_id':
          formik.setValues(v, {
            value: res?.result?.ai_model_id,
            label: res?.result?.ai_model_name
          });
          break;
        case 'ai_model_name':
          break;
        default:
          formik.setValues(v, res?.result?.[v]);
          break;
      }
    });
    setShow((ps) => ({ ...ps, loading: false }));
  };

  return (
    <Modal
      slideFromRight
      isOpen={typeof promptId?.version === 'number'}
      handleClose={() =>
        setSearchParams((sp) => {
          setPromptId(null);
          setPrompt(null);
          sp.delete('version');
        })
      }
      className="w-1/2"
      title={`Prompt ${prompt?.template_name}:${prompt?.id}:${prompt?.version}`}
      footer={
        <Allowed requiredPermissions="prompts.create">
          <Button text="Save Revision" color="primary" onClick={() => formik.submitForm()} />
        </Allowed>
      }>
      {show.loading && (
        <div className="flex flex-row gap-2">
          <Loader outlined color="primary" />
          <div>Loading...</div>
        </div>
      )}
      {!show.loading && (
        <AdminIntelligencePromptForm
          formik={formik}
          value={prompt}
          onChange={(e) => {
            formik.setFieldValue(e?.name, e?.value);
          }}
        />
      )}
    </Modal>
  );
};

export default withErrorBoundary(AdminIntelligencePromptDetails);
