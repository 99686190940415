import React from 'react';
import cs from 'classnames';

const Loader = ({
  color = 'primary',
  shade = '500',
  type = 'primary',
  className,
  outlined = false
}) => (
  <div
    className={cs(
      'relative flex h-[18px] w-[18px] animate-spin items-center justify-center rounded-full bg-gradient-to-t from-current to-transparent p-[2px]',
      className
    )}>
    <div
      className={cs(
        'loader-circle h-full w-full rounded-full',
        type === 'primary' ? `text-${color}-${shade}` : `!bg-${color}-50`,
        outlined ? 'bg-white' : `bg-${color}-${shade}`,
        color === 'success' && 'bg-success-500',
        color === 'secondary' && 'bg-secondary-500'
      )}></div>
  </div>
);

export default Loader;
