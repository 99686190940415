const { appointmentPreview } = require('../constants');
const { socket } = require('api/Socket');

const atom = require('recoil').atom;

module.exports = {
  userState: atom({
    key: 'userState',
    default: {
      user_id: '',
      f_name: '',
      l_name: '',
      email: '',
      state: '',
      kind: '',
      authenticated: false,
      logged_in: false,
      profile_photo: null,
      created_at: null,
      display_settings: null,
      phone: null
    }
  }),
  kioskState: atom({
    key: 'kioskState',
    default: {
      isKiosk: false,
      name: '',
      id: '',
      color: ''
    }
  }),
  claimState: atom({
    key: 'claimState',
    default: {}
  }),
  claimPatientState: atom({
    key: 'claimPatientState',
    default: {}
  }),
  permissions: atom({
    key: 'permissions',
    default: { loaded: false }
  }),
  hasBeenAuthChecked: atom({
    key: 'hasBeenAuthChecked',
    default: {
      checked: false,
      isImpersonate: false
    }
  }),
  procedureCodeOptionsState: atom({ key: 'procedureCodeOptionsState', default: [] }),
  procedureCodeMapState: atom({ key: 'procedureCodeMapState', default: new Map() }),
  sidebarExpand: atom({ key: 'sidebarExpand', default: false }),
  roles: atom({ key: 'roles', default: { practiceId: 0, data: [] } }),
  roleOptions: atom({ key: 'roleOptionsState', default: [] }),
  professionGroupOptions: atom({ key: 'professionGroupOptionsState', default: [] }),
  resizableModalWidth: atom({ key: 'resizableModalWidth', default: 0 }),
  settings: atom({
    key: 'settings',
    default: {}
  }),
  getCodeStates: atom({
    key: 'codeStates',
    default: {
      cptCodes: [],
      modifiers: [],
      icdCodes: []
    }
  }),
  issuerState: atom({
    key: 'issuerState',
    default: {
      options: [],
      map: {}
    }
  }),
  geographicalStatesState: atom({
    key: 'geographicalStatesState',
    default: []
  }),
  socketState: atom({
    key: 'socketState',
    default: null
  }),
  socketHasConnection: atom({
    key: 'socketHasConnection',
    default: false
  }),
  externalInvoiceModalState: atom({
    key: 'externalInvoiceModalState',
    default: {
      isOpen: false,
      id: null
    }
  }),
  clinicalNote: atom({
    key: 'clinicalNoteState',
    default: {}
  }),
  intelligenceAiState: atom({
    key: 'intelligenceAiState',
    default: { loading: false, timeoutTimeout: null, response: '', path: '' }
  }),
  socketSessionTokenState: atom({
    key: 'socketSessionTokenState',
    default: null
  }),
  costShareState: atom({
    key: 'costShareState',
    default: {
      deductible: { checked: false, value: 0 },
      coPayment: { checked: false, value: 0 },
      coInsurance: { checked: false, value: 0 }
    }
  })
};
