import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCptCodes } from 'api/CptCodes';
export const useCPTCodes = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['cptCodes', ...dependencies], () => getCptCodes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
