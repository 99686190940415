import React, { useEffect, useState } from 'react';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../../shared/Icon/Icon';

import { useParams } from 'react-router-dom';
import { io } from '../../../../../../lib/helpers/utility';
import { useCustomPtResponseById } from '../../../../../../lib/hooks/queries/customForms/useCustomPtResponseById';
import { formatSections } from '../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import { beautifyAppendedString } from '../helpers/sidebarHelpers';

const PatientResponse = ({}) => {
  const { patientResponseSidebar } = useClinicalNoteContext();
  const { formId } = patientResponseSidebar;
  const [response, setResponse] = useState();
  const { id, appointmentId } = useParams();

  const { data, isFetching } = useCustomPtResponseById({
    dependencies: [formId, appointmentId, id],
    params: {
      formId,
      appointmentId,
      patientId: id,
      patientResponses: true
    },
    options: {
      keepPreviousData: true,
      enabled: !!formId
    }
  });

  useEffect(() => {
    !isFetching && processResponseData();
  }, [isFetching]);

  const processResponseData = () => {
    if (!io(data?.data)) return;

    const formResponse = data?.data?.formResponse;
    if (formResponse) {
      setResponse(
        formResponse?.form
          ? formatSections({
              fieldData: formResponse?.form?.json?.fields,
              answerData: JSON.parse(formResponse?.json?.fields)
            })
          : null
      );

      return;
    }

    setResponse(null);
  };

  const result = beautifyAppendedString(response);

  if (isFetching) {
    return (
      <div className="h-full overflow-y-scroll !p-3">
        <Skeleton count={6} height="50px" />
      </div>
    );
  }

  if (!result) {
    return (
      <div className="!mt-12 flex flex-col items-center justify-center !p-3">
        <div className="relative flex h-[72px] w-[72px] items-center justify-center rounded-full bg-primary-50">
          <Icon icon="new-user-arrow-right" size="32px" />
          <Icon className="absolute bottom-0 right-0" icon="new-search-basic" size="24px" />
        </div>
        <p className="!mt-3 text-center text-lg font-600 text-primary-900">No patient response</p>
        <p className="!mt-2 text-center text-sm text-primary-900">
          Ensure patients complete their form sections to access their responses.
        </p>
      </div>
    );
  }

  return <div className="h-full overflow-y-scroll !p-3">{result}</div>;
};

export default PatientResponse;
