import React, { useCallback, useEffect, useMemo } from 'react';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import AGTable from 'components/shared/AGTable/AGTable';
import { useAiModel } from 'lib/hooks/queries/admin/intelligence/useAiModel';
import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS
} from 'components/admin/pages/Intelligence/Prompt/helpers';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import { useOutletContext } from 'react-router-dom';

const AdminIntelligenceModelTable = () => {
  const { limit, page, setPage, sort, filters, setFilters, colDefs } = useTableContext();
  const { isLoading, isFetching, data } = useAiModel({
    params: {
      limit: limit || 10,
      page: page || 0,
      sort,
      filters: mapValues(filters)
    },
    options: {},
    dependencies: [limit, page, mapValues(filters)],
    // dependencies: [limit, page],
    name: 'ai_models'
  });

  const { setLoading } = useOutletContext();

  useEffect(() => {
    setLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: data?.models?.results,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: data?.models?.results,
            page,
            limit,
            onPageChange,
            pageCountOverride: Math.ceil((data?.models?.results?.length || 0) / limit) + 1
          },
          align: 'right'
        }
      ]
    }),
    [data?.models?.results, page, limit, onPageChange]
  );

  return (
    <div className="flex h-full flex-col overflow-hidden !pb-0">
      <AGTable
        loading={isLoading || isFetching}
        data={data?.models?.results}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        animateRows={true}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        enableRangeSelection={true}
        statusBar={statusBar}
        reactiveCustomComponents
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligenceModelTable);
