import Tippy from '@tippyjs/react';
import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { camelToKebabKeys } from '../../../../../../lib/helpers/utility';
import Button from '../../../../../shared/Buttons/Button';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const Footer = ({ open, forwardedRef }) => {
  const { components, saveNoteRef, showConfirmSignLock, saveButtonText, documentSignInfo } =
    useClinicalNoteContext();
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const navigate = useNavigate();
  const { id, type, formName } = useParams();
  const [currCompIndex, setCurrCompIndex] = useState(0);
  const updatedComponents = camelToKebabKeys(components);
  const componentsArray = Object.keys(updatedComponents);
  const totalComponents = componentsArray.length;

  const pathArray = Object.values(updatedComponents).map((component) => component.path);

  useEffect(() => {
    setCurrCompIndex(0);
  }, [type]);

  useEffect(() => {
    if (formName) {
      const formIndex = pathArray.indexOf(formName);
      if (formIndex !== -1) {
        setCurrCompIndex(formIndex);
      } else {
        setCurrCompIndex(0);
      }
    }
  }, [formName, componentsArray]);

  const goToNextComponent = async () => {
    await saveNoteRef.current.onSaveNote();

    const nextIndex = currCompIndex + 1;
    if (nextIndex < totalComponents) {
      const nextComponentKey = componentsArray[nextIndex];
      const nextComponentPath = updatedComponents[nextComponentKey]?.path;
      navigate(`${type}/${nextComponentPath}`);
      setCurrCompIndex(nextIndex);
    }
    if (forwardedRef?.current) {
      forwardedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    if (currCompIndex + 1 === totalComponents) {
      showConfirmSignLock(true);
    }
  };

  const {
    isUserAssignedToDocument = null,
    signLockButtonInfo = null,
    userSign
  } = documentSignInfo || {};

  const buttonText =
    currCompIndex + 1 === totalComponents && userSign
      ? 'Unlock'
      : currCompIndex + 1 === totalComponents
        ? 'Sign & lock'
        : 'Save & Next';

  const checkIfSignAndLockDisabled =
    currCompIndex + 1 === totalComponents &&
    (saveButtonText === 'Saving' || !clinicalNote?.id || !isUserAssignedToDocument?.id);

  return (
    <div
      className={cs(
        'fixed bottom-0 z-20 flex items-center justify-between border-0 !border-t-[1px] !border-solid !border-neutral-100 bg-white !px-6 !py-3 shadow-[px_-2px_16px_0_rgba(0,79,107,0.06)] duration-200',
        open ? 'w-[calc(100%-264px)]' : 'w-[calc(100%-128px)]'
      )}>
      <Button
        data-qa="cancel-btn"
        text="Cancel"
        color="neutral"
        outlined
        className="!bg-neutral-50"
        onClick={() => navigate(`/portal/charts/${id}/clinical-notes`)}
      />

      <Tippy
        placement="bottom"
        className="tippy-dark"
        content={signLockButtonInfo}
        disabled={!signLockButtonInfo}>
        <div>
          <Button
            text={buttonText}
            onClick={goToNextComponent}
            disabled={checkIfSignAndLockDisabled || saveButtonText === 'Saving'}
            loadingIcon={saveButtonText === 'Saving'}
            data-qa="next-btn"
          />
        </div>
      </Tippy>
    </div>
  );
};

export default Footer;
