import * as Yup from 'yup';

export const getInitialData = (data = null) => {
  return {
    ...(data?.id && { id: data?.id }),
    provider_id: data?.provider_id ?? null,

    from_name: data?.from_name ?? null,
    from_phone: data?.from_phone ?? null,
    from_email: data?.from_email ?? null,
    from_address: data?.from_address ?? null,

    to_name: data?.to_name ?? null,
    to_phone: data?.to_phone ?? null,
    to_email: data?.to_email ?? null,
    to_address: data?.to_address ?? null,
    npi: data?.npi ?? null,

    instructions: data?.instructions ?? null,
    demographics: data?.demographics ?? true,
    insurance_info: data?.insurance_info ?? true,
    clinical_summary: data?.clinical_summary ?? {},
    clinical_notes: data?.clinical_notes ?? data?.defaultCurrentClinicalNote ?? null
  };
};

export const OrderReferralValidationSchema = Yup.object().shape({
  from_name: Yup.string().required('Name is required'),
  to_name: Yup.string().required('Name is required'),
  clinical_notes: Yup.array().min(1, 'At least one clinical note is required')
});

export const getInitialMedicalPrescriptionData = (data = null) => {
  return {
    ...(data?.id && { id: data?.id }),
    provider_id: data?.provider_id ?? null,
    appointment_id: data?.appointment_id ?? null,

    patient_id: data?.patient_id ?? null,
    patient_f_name: data?.patient_f_name ?? null,
    patient_l_name: data?.patient_l_name ?? null,
    patient_age: data?.patient_age ?? null,

    non_erx_prescription_and_frequency: data?.non_erx_prescription_and_frequency ?? null,
    medication_ids: data?.medication_ids ?? null
  };
};

export const MedicalPrescriptionlValidationSchema = Yup.object().shape({
  patient_f_name: Yup.string().required('First name is required'),
  patient_l_name: Yup.string().required('Last name is required'),
  patient_age: Yup.string().required('Age is required')
});

export const getLabOrderData = (data = null) => {
  return {
    ...(data?.id && { id: data?.id }),
    provider_id: data?.provider_id ?? null,
    appointment_id: data?.appointment_id ?? null,
    practice_id: data?.practice_id ?? null,
    labsoft_cust_id: data?.labsoft_cust_id ?? null,
    labsoft_emr_key: data?.labsoft_emr_key ?? null,
    labsoft_user_name: data?.labsoft_user_name ?? null,
    pat_id: data?.pat_id ?? null,
    pat_l_name: data?.pat_l_name ?? null,
    pat_f_name: data?.pat_f_name ?? null,
    pat_mi: data?.pat_mu ?? null,
    pat_sex: data?.pat_sex ?? null,
    pat_dob: data?.pat_dob ?? null,
    pat_ssn: data?.pat_ssn ?? null,
    pat_addr: data?.pat_addr ?? null,
    pat_city: data?.pat_city ?? null,
    pat_st: data?.pat_st ?? null,
    pat_zip: data?.pat_zip ?? null,
    pat_home_phone: data?.pat_home_phone ?? null,
    pat_work_phone: data?.pat_work_phone ?? null,
    diag: data?.diag ?? null,
    diag_ids: data?.diag_ids ?? null,
    ins_code: data?.ins_code ?? null,
    ins_name: data?.ins_name ?? null,
    ins_addr: data?.ins_addr ?? null,
    ins_city: data?.ins_city ?? null,
    ins_st: data?.ins_st ?? null,
    ins_zip: data?.ins_zip ?? null,
    ins_contact_last_name: data?.ins_contact_last_name ?? null,
    ins_contact_first_name: data?.ins_contact_first_name ?? null,
    ins_contact_phone: data?.ins_contact_phone ?? null,
    ins_group_num: data?.ins_group_num ?? null,
    ins_group_name: data?.ins_group_name ?? null,
    ins_plan_eff_dt: data?.ins_plan_eff_dt ?? null,
    ins_plan_exp_dt: data?.ins_plan_exp_dt ?? null,
    ins_plan_type: data?.ins_plan_type ?? null,
    ins_subscriber_last: data?.ins_subscriber_last ?? null,
    ins_subscriber_first: data?.ins_subscriber_first ?? null,
    ins_subscriber_middle_init: data?.ins_subscriber_middle_init ?? null,
    ins_relationship: data?.ins_relationship ?? null,
    ins_subscriber_dob: data?.ins_subscriber_dob ?? null,
    ins_subscriber_addr: data?.ins_subscriber_addr ?? null,
    ins_subscriber_city: data?.ins_subscriber_city ?? null,
    ins_subscriber_st: data?.ins_subscriber_st ?? null,
    ins_subscriber_zip: data?.ins_subscriber_zip ?? null,
    ins_wc_flag: data?.ins_wc_flag ?? null,
    ins_policy_num: data?.ins_policy_num ?? null,
    ins_bill_to: data?.ins_bill_to ?? null,
    guarantor_last_name: data?.guarantor_last_name ?? null,
    guarantor_first_name: data?.guarantor_first_name ?? null,
    guarantor_mi: data?.guarantor_mi ?? null,
    guarantor_sex: data?.guarantor_sex ?? null,
    guarantor_dob: data?.guarantor_dob ?? null,
    guarantor_ssn: data?.guarantor_ssn ?? null,
    guarantor_addr: data?.guarantor_addr ?? null,
    guarantor_city: data?.guarantor_city ?? null,
    guarantor_st: data?.guarantor_st ?? null,
    guarantor_zip: data?.guarantor_zip ?? null,
    guarantor_home_phone: data?.guarantor_home_phone ?? null,
    guarantor_work_phone: data?.guarantor_work_phone ?? null,
    guarantor_relationship: data?.guarantor_relationship ?? null,
    order_phys_last: data?.order_phys_last ?? null,
    order_phys_first: data?.order_phys_first ?? null,
    order_phys_middle: data?.order_phys_middle ?? null,
    order_phys_suffix: data?.order_phys_suffix ?? null,
    order_location: data?.order_location ?? null,
    order_accno: data?.order_accno ?? null,
    order_collector: data?.order_collector ?? null,
    order_rcv_dt: data?.order_rcv_dt ?? null,
    order_sample_type: data?.order_sample_type ?? null,
    order_comments: data?.order_comments ?? null,
    draw_date_time: data?.draw_date_time ?? null,
    draw_at_service_center: data?.draw_at_service_center ?? null,
    order_physician_upin: data?.order_physician_upin ?? null,
    order_physician_last_name: data?.order_physician_last_name ?? null,
    order_physician_first_name: data?.order_physician_first_name ?? null,
    order_physician_middle_name: data?.order_physician_middle_name ?? null,
    order_physician_suffix: data?.order_physician_suffix ?? null,
    order_physician_npi: data?.order_physician_npi ?? null,
    location_name: data?.location_name ?? null,
    diags: data?.diags ?? null,
    diags_ids: data?.diags_ids ?? null,
    lab_order_test_ids: data?.lab_order_test_ids ?? null

    // TESTS
    // lab_order_id: data?.lab_order_id ?? null,
    // test_id: data?.test_id ?? null,
    // test_order_code: data?.test_order_code ?? null,
    // test_cpt: data?.test_cpt ?? null,
    // test_name: data?.test_name ?? null,
    // test_ref_lab: data?.test_ref_lab ?? null,
    // test_status: data?.test_status ?? null,
    // test_rbo_result: data?.test_rbo_result ?? null,
    // qa: data?.qa ?? null,
    // sample_type: data?.sample_type ?? null,
    // container: data?.container ?? null,
    // draw_req: data?.draw_req ?? null,
    // transport_mode: data?.transport_mode ?? null,
    // transport_group: data?.transport_group ?? null,
  };
};

export const LabSoftOrderValidationSchema = Yup.object().shape({
  patientId: Yup.string().required(),
  practitionerId: Yup.string().required(),
  tests: Yup.array().min(1).required(),
  diags: Yup.array().min(1, 'Select at least one ICD-10/Diagnosis').required(),
  orderProvider: Yup.object().required(),
  orderLocation: Yup.object().required(),
  orderCollector: Yup.object().optional(),
  orderSampleType: Yup.string().optional(),
  orderComments: Yup.string(),
  drawDateTime: Yup.string().required('Draw date and time is required'),
  drawAtServiceCenter: Yup.boolean().required()
});

export const routine_options = [
  {
    label: 'Routine',
    value: 'routine'
  },
  {
    label: 'STAT',
    value: 'stat'
  },
  {
    label: 'ASAP',
    value: 'asap'
  },
  {
    label: 'Pre-Op',
    value: 'pre-op'
  }
];
