import { camelCaseToReadableFormat, io, isEmpty } from 'lib/helpers/utility';
import React from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const FunctionalImpairment = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const functionalImpairment = clinicalNote?.epn?.functionalImpairment;
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(functionalImpairment)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Function impairment</h3>
      {isTemplateChecked && (
        <div className="grid gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-3 first-of-type:!border-t-0">
          <div className="grid">
            <ul className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
              {io(functionalImpairment) &&
                Object.keys(functionalImpairment).map((key) => {
                  const symptom = camelCaseToReadableFormat(key);
                  const hasNote = functionalImpairment[key + 'Note']?.length > 0;

                  if (key === 'other') return null;
                  if (key === 'otherNote' && functionalImpairment[key]) {
                    return (
                      <li className="list-inside list-disc !pt-2 first-letter:capitalize" key={key}>
                        {functionalImpairment[key]}
                      </li>
                    );
                  }

                  if (functionalImpairment[key] === true || hasNote) {
                    return (
                      <li
                        className="list-inside list-disc !pt-2 first-letter:capitalize first-of-type:!pt-0"
                        key={key}>
                        {symptom}
                        {hasNote && (
                          <ul className="pl-3">
                            <li
                              className="!list-inside first-letter:capitalize"
                              style={{ listStyle: 'circle' }}>
                              {functionalImpairment[key + 'Note']}
                            </li>
                          </ul>
                        )}
                      </li>
                    );
                  }
                  return null;
                })}
            </ul>
          </div>
        </div>
      )}

      {isNarrativeChecked && !isEmpty(functionalImpairment?.narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={functionalImpairment?.narrative} />
        </div>
      )}
    </div>
  );
};

export default FunctionalImpairment;
