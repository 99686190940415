import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getArchivedFaxes } from '../../../../api/Fax';

export const useArchivedFaxes = ({ dependencies = [], params = {}, options = {} }) => {
  const navigate = useNavigate();
  return useQuery(['archivedFaxes', ...dependencies], () => getArchivedFaxes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
