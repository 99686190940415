import React from 'react';
import { iaRa } from '../../../../lib/helpers/utility';
import { Popover } from '@headlessui/react';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';

const FilterComponent = ({ categories = [], filters, setFilters }) => {
  const handleCategoryChange = (category) => {
    if (category.id === filters?.category?.id) {
      setFilters((prev) => ({ ...prev, category: null }));
      return;
    }
    setFilters((prev) => ({ ...prev, category }));
  };
  return (
    <div className="flex justify-end items-center space-x-2 !pb-2">
      <span className="font-600"> by Category:</span>
      <div className="relative text-left">
        <Popover className="relative">
          {({ open, close }) => (
            <div>
              <Popover.Button>
                <Button
                  text={filters?.category?.name || 'Select Category'}
                  type="secondary"
                  className="min-w-[165px]"
                  iconRight={open ? 'chevron-up' : 'chevron-down'}
                />
              </Popover.Button>

              <Popover.Panel className="bg-white absolute z-50 w-[165px] !py-2 shadow-lg mt-1 rounded-md !ml-1">
                {iaRa(categories).map((category) => (
                  <div
                    key={category.id}
                    data-qa="edit-package-btn"
                    className="flex items-center min-h-[10px] !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      handleCategoryChange(category);
                      close();
                    }}>
                    {filters?.category?.id === category.id && (
                      <Icon icon="checkmark-circle" color="primary" />
                    )}
                    <div className="ml-1 text-sm flex break-all text-primary-900  overflow-hidden">
                      {category.name}
                    </div>
                  </div>
                ))}
              </Popover.Panel>
            </div>
          )}
        </Popover>
      </div>
    </div>
  );
};

export default FilterComponent;
