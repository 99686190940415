import React from 'react';
import cs from 'classnames';

import Button from '../../../../shared/Buttons/Button';
import SearchPatients from '../../../../Search/SearchPatients/SearchPatients';
import { useNewAppointmentContext } from '../../../../../lib/context/Appointment/NewAppointmentContext';

const AppointmentPatient = ({ getPackages }) => {
  const { formik, selectedPatient, setNewPatientModal } = useNewAppointmentContext();
  const { setFieldValue, touched, errors } = formik;

  return (
    <SearchPatients
      isImage
      required
      width="100%"
      label="Patient"
      preselectedPatient={selectedPatient}
      handleClick={(e) => {
        if (e?.value) {
          getPackages(e?.value);
          setFieldValue('patientId', e?.value);
          setFieldValue('patient', e);
          e?.virtual_link && setFieldValue('virtual_link', e?.virtual_link);
        } else {
          setFieldValue('patientId', '');
        }
      }}
      error={touched.patientId && errors.patientId}
      className={cs(
        'rounded-md border-neutral-100 bg-white shadow-[0px_2px_4px_0px_rgba(27,33,35,0.04)] focus-within:outline focus-within:!outline-2',
        touched.patientId && errors.patientId
          ? '!border-danger-300 focus-within:!outline-danger-100'
          : 'focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]'
      )}
      rightButton={
        <Button
          type="link"
          text="New Patient"
          className="!px-[0px] !py-0 text-primary-500"
          onClick={() => setNewPatientModal(true)}
        />
      }
    />
  );
};

export default AppointmentPatient;
