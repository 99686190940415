import { formatName, ia } from 'lib/helpers/utility';
import moment from 'moment';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import pdfPath from '../../../../../../assets/PDF/cms1500.pdf';

export const generatePDF = async ({
  claim,
  address,
  withBackground = true,
  withPayerNameAddress = true
}) => {
  const patName = formatName(claim?.pat_name_l, claim?.pat_name_f, claim?.pat_name_m);

  //3
  const patDob = claim?.pat_dob ?? '';
  const dateParts = patDob.split('-');
  const patYear = parseInt(dateParts[0]);
  const patMonth = dateParts[1]?.padStart(2, '0');
  const patDay = dateParts[2]?.padStart(2, '0');
  const patSex = claim?.pat_sex ?? '';
  //4
  const insName =
    claim?.ins_name_l && claim?.ins_name_f
      ? `${claim?.ins_name_l} ${claim?.ins_name_f} ${claim?.ins_name_m ? claim?.ins_name_m[0] : ''}`
      : `${claim?.ins_name_l || ''} ${claim?.ins_name_f || ''}`;
  //5
  const patPhone = claim?.pat_phone ?? '';
  const patPhoneArea = patPhone?.slice(0, 3);
  const patPhoneNumber = patPhone?.slice(3);
  //6
  const patRel = claim?.pat_rel ?? '';
  //7
  const insPhone = claim?.ins_phone;
  const insPhoneArea = insPhone ? insPhone?.slice(0, 3) : '';
  const insPhoneNumber = insPhone ? insPhone?.slice(3) : '';
  //9
  const otherInsName =
    claim?.other_ins_name_l && claim?.other_ins_name_f
      ? `${claim?.other_ins_name_l} ${claim?.other_ins_name_f} ${
          claim?.other_ins_name_m ? claim?.other_ins_name_m[0] : ''
        }`
      : `${claim?.other_ins_name_l || ''} ${claim?.other_ins_name_f || ''}`;
  const otherInsNumber = claim?.other_ins_number ?? '';
  //11
  const insGroup = claim?.ins_group ? claim?.ins_group : '';
  const insDob = claim?.ins_dob ? moment(claim?.ins_dob).format('MM DD YYYY') : '';
  //14
  const dateOfCurrentIllness = claim?.cond_date
    ? moment(claim?.cond_date).format('MM DD YYYY')
    : '';
  const insSex = claim?.ins_sex ?? '';
  //17
  const refName =
    claim?.ref_name_l && claim?.ref_name_f
      ? `${claim?.ref_name_l} ${claim?.ref_name_f} ${claim?.ref_name_m ? claim?.ref_name_m[0] : ''}`
      : `${claim?.ref_name_l || ''} ${claim?.ref_name_f || ''}`;
  const refId = claim?.ref_id ?? '';
  const refNpi = claim?.ref_npi ?? '';
  // 18
  const hospFromDate = claim?.hosp_from_date
    ? moment(claim?.hosp_from_date).format('MM DD YYYY')
    : '';
  const hospThruDate = claim?.hosp_thru_date
    ? moment(claim?.hosp_thru_date).format('MM DD YYYY')
    : '';
  //22
  const frequencyCode = claim?.frequency_code ? JSON.stringify(claim?.frequency_code) : '';
  //28
  const totalCharge = String(claim?.total_charge) || '';
  let totalBeforeDecimal = '';
  let totalAfterDecimal = '';
  if (totalCharge?.includes?.('.') || totalCharge?.includes?.(',')) {
    const parts = totalCharge?.replace?.(',', '.')?.split?.('.');
    totalBeforeDecimal = parts[0];
    totalAfterDecimal = parts[1];
  } else {
    totalBeforeDecimal = totalCharge;
  }
  //29
  const amountPaid = claim?.amount_paid;
  let apBeforeDecimal = '';
  let apAfterDecimal = '';
  if (
    (typeof amountPaid === 'string' || typeof amountPaid === 'number') &&
    (amountPaid.includes('.') || amountPaid.includes(','))
  ) {
    const parts = amountPaid.replace(',', '.').split('.');
    apBeforeDecimal = parts[0];
    apAfterDecimal = parts[1];
  } else {
    apBeforeDecimal = amountPaid;
  }
  //31
  const provName =
    claim?.prov_name_l && claim?.prov_name_f
      ? `${claim?.prov_name_l} ${claim?.prov_name_f} ${
          claim?.prov_name_m ? claim?.prov_name_m[0] : ''
        }`
      : `${claim?.prov_name_l || ''} ${claim?.prov_name_f || ''}`;
  const provSigDate = claim?.created_at ? moment(claim?.created_at).format('MM/DD/YY') : '';
  const provTaxonomy = claim?.prov_taxonomy ?? '';
  //32
  const facilityLocation =
    claim?.facility_city || claim?.facility_state || claim?.facility_zip
      ? `${claim?.facility_city ?? ''} ${claim?.facility_state ?? ''} ${claim?.facility_zip ?? ''}`
      : '';
  //33
  const billingLocation =
    claim?.bill_city || claim?.bill_state || claim?.bill_zip
      ? `${claim?.bill_city ?? ''} ${claim?.bill_state ?? ''} ${claim?.bill_zip ?? ''}`
      : '';
  const billPhone = claim?.bill_phone;
  const billPhoneArea = billPhone ? billPhone?.slice(0, 3) : '';
  const billPhoneNumber = billPhone ? billPhone?.slice(3) : '';

  try {
    const response = await fetch(pdfPath);
    if (!response.ok) throw new Error('Failed to fetch PDF');
    const pdfBytes = await response.arrayBuffer();
    const pdfDoc = withBackground ? await PDFDocument.load(pdfBytes) : await PDFDocument.create();

    const courier = await pdfDoc.embedFont(StandardFonts.Courier);

    const cptPerPage = 6;
    const totalCpt = claim?.procedures?.length;
    const totalPages = Math.ceil(totalCpt / cptPerPage);

    if (!withBackground) {
      pdfDoc.addPage();
    }

    const [originalPage] = pdfDoc.getPages();
    // pdf settings
    originalPage.setFont(courier);
    originalPage.setFontSize(12);
    pdfDoc.setAuthor('Myriad Health');
    pdfDoc.setTitle('CMS1500 by Myriad Health');
    pdfDoc.setSubject('CMS1500');

    // draw in all pages
    if (withPayerNameAddress) {
      originalPage.drawText(claim?.payer_name ?? '', {
        x: 372,
        y: 744
      });
      originalPage.drawText(
        claim?.payer_addr_1
          ? claim?.payer_addr_1
          : address?.address_ln_1
            ? address?.address_ln_1
            : '',
        {
          x: 372,
          y: 732
        }
      );
      originalPage.drawText(
        claim?.payer_addr_2
          ? claim?.payer_addr_2
          : address?.address_ln_2
            ? address?.address_ln_2
            : '',
        {
          x: 372,
          y: 720
        }
      );
      originalPage.drawText(
        `${claim?.payer_city ?? (address?.city || '')} ${claim?.payer_zip ?? (address?.zip || '')}`,
        {
          x: 372,
          y: 708
        }
      );
    }

    originalPage.drawText('x', {
      x:
        claim?.payer?.payer_type === 'medicare'
          ? 22
          : claim?.payer?.payer_type === 'medicaid'
            ? 70
            : 338,
      y: 677
    });
    originalPage.drawText(patName, {
      x: 28,
      y: 652
    });
    originalPage.drawText(`${patMonth} ${patDay} ${patYear}`, {
      x: 236,
      y: 650
    });
    originalPage.drawText('x', {
      x:
        patSex === 'M' || patSex.toLowerCase() === 'male'
          ? 316
          : patSex === 'F' || patSex.toLowerCase() === 'female'
            ? 352
            : -20,
      y: 652
    });
    originalPage.drawText(claim?.ins_number ?? '', {
      x: 380,
      y: 675
    });
    originalPage.drawText(insName, {
      x: 380,
      y: 652
    });
    originalPage.drawText(
      claim?.pat_addr_1 ? claim?.pat_addr_1 : claim?.pat_addr_2 ? claim?.pat_addr_2 : '',
      {
        x: 28,
        y: 628
      }
    );
    originalPage.drawText(claim?.pat_city ?? '', {
      x: 28,
      y: 606
    });
    originalPage.drawText(claim?.pat_state ?? '', {
      x: 204,
      y: 606
    });
    originalPage.drawText(claim?.pat_zip ?? '', {
      x: 28,
      y: 580
    });
    originalPage.drawText(patPhoneArea, {
      x: 123,
      y: 580
    });
    originalPage.drawText(patPhoneNumber, {
      x: 150,
      y: 580
    });
    originalPage.drawText('x', {
      x:
        patRel === '18' || patRel === 'Self'
          ? 252
          : patRel === '01' || patRel === 'Spouse'
            ? 288
            : patRel === '19' || patRel === 'Child'
              ? 316
              : 352,
      y: 628
    });
    originalPage.drawText(
      claim?.ins_addr_1 ? claim?.ins_addr_1 : claim?.ins_addr_2 ? claim?.ins_addr_2 : '',
      {
        x: 380,
        y: 628
      }
    );
    originalPage.drawText(claim?.ins_city ?? '', {
      x: 380,
      y: 606
    });
    originalPage.drawText(claim?.ins_state ?? '', {
      x: 550,
      y: 606
    });
    originalPage.drawText(claim?.ins_zip ?? '', {
      x: 380,
      y: 580
    });
    originalPage.drawText(insPhoneArea, {
      x: 483,
      y: 580
    });
    originalPage.drawText(insPhoneNumber, {
      x: 512,
      y: 580
    });
    originalPage.drawText(otherInsName, {
      x: 28,
      y: 556
    });
    originalPage.drawText(otherInsNumber, {
      x: 28,
      y: 533
    });
    originalPage.drawText(claim?.ins_plan ?? '', {
      x: 28,
      y: 460
    });
    originalPage.drawText(claim?.ins_plan ?? '', {
      x: 380,
      y: 483
    });
    originalPage.drawText('x', {
      x:
        claim?.pat_employment === 'Y' || claim?.pat_employment === 'Employed'
          ? 266
          : claim?.pat_employment === 'N' || claim?.pat_employment === 'Unemployed'
            ? 310
            : -20,
      y: 532
    });
    originalPage.drawText('x', {
      x:
        claim?.employment_related === 'Y' || claim?.employment_related === 'Yes'
          ? 267
          : claim?.employment_related === 'N' || claim?.employment_related === 'No'
            ? 310
            : -20,
      y: 532
    });
    originalPage.drawText('x', {
      x:
        claim?.auto_accident === 'Y' || claim?.auto_accident === 'Yes'
          ? 267
          : claim?.auto_accident === 'N' || claim?.auto_accident === 'No'
            ? 310
            : -20,
      y: 508
    });
    originalPage.drawText(claim?.auto_accident_state ?? '', {
      x: 338,
      y: 508
    });
    originalPage.drawText('x', {
      x:
        claim?.other_accident === 'Y' || claim?.other_accident === 'Yes'
          ? 267
          : claim?.other_accident === 'N' || claim?.other_accident === 'No'
            ? 310
            : -20,
      y: 484
    });
    originalPage.drawText(insGroup, {
      x: 380,
      y: 556
    });
    originalPage.drawText(insDob, {
      x: 396,
      y: 530
    });
    originalPage.drawText('x', {
      x: insSex.toLowerCase() === 'male' ? 504 : insSex.toLowerCase() === 'female' ? 554 : -20,
      y: 532
    });
    originalPage.drawText('x', {
      x:
        claim?.other_ins_name_l ||
        claim?.other_ins_name_f ||
        claim?.other_ins_name_m ||
        claim?.other_ins_number ||
        claim?.other_payer_name
          ? 388
          : 424,
      y: 460
    });
    originalPage.drawText('SIGNATURE ON FILE', {
      x: 62,
      y: 418
    });
    originalPage.drawText(
      claim?.created_at ? moment.utc(claim?.created_at).format('MM-DD-YYYY') : '',
      {
        x: 284,
        y: 418
      }
    );
    originalPage.drawText('SIGNATURE ON FILE', {
      x: 420,
      y: 418
    });
    originalPage.drawText(dateOfCurrentIllness, {
      x: 28,
      y: 386
    });
    originalPage.drawText(refName, {
      x: 44,
      y: 364
    });
    originalPage.drawText(refId, {
      x: 250,
      y: 375,
      size: 10
    });
    originalPage.drawText(refNpi, {
      x: 250,
      y: 363,
      size: 10
    });
    originalPage.drawText(hospFromDate, {
      x: 404,
      y: 363
    });
    originalPage.drawText(hospThruDate, {
      x: 504,
      y: 363
    });
    originalPage.drawText(claim?.narrative ?? '', {
      x: 28,
      y: 340
    });
    originalPage.drawText(claim?.diag_1 ?? '', {
      x: 36,
      y: 316,
      size: 10
    });
    originalPage.drawText(claim?.diag_2 ?? '', {
      x: 130,
      y: 316,
      size: 10
    });
    originalPage.drawText(claim?.diag_3 ?? '', {
      x: 224,
      y: 316,
      size: 10
    });
    originalPage.drawText(claim?.diag_4 ?? '', {
      x: 318,
      y: 316,
      size: 10
    });
    originalPage.drawText(claim?.diag_5 ?? '', {
      x: 36,
      y: 304,
      size: 10
    });
    originalPage.drawText(claim?.diag_6 ?? '', {
      x: 130,
      y: 304,
      size: 10
    });
    originalPage.drawText(claim?.diag_7 ?? '', {
      x: 224,
      y: 304,
      size: 10
    });
    originalPage.drawText(claim?.diag_8 ?? '', {
      x: 318,
      y: 304,
      size: 10
    });
    originalPage.drawText(claim?.diag_9 ?? '', {
      x: 36,
      y: 292,
      size: 10
    });
    originalPage.drawText(claim?.diag_10 ?? '', {
      x: 130,
      y: 292,
      size: 10
    });
    originalPage.drawText(claim?.diag_11 ?? '', {
      x: 224,
      y: 292,
      size: 10
    });
    originalPage.drawText(claim?.diag_12 ?? '', {
      x: 318,
      y: 292,
      size: 10
    });
    originalPage.drawText(frequencyCode, {
      x: 390,
      y: 314
    });
    originalPage.drawText(claim?.icn_dcn_1 ?? '', {
      x: 466,
      y: 314
    });
    originalPage.drawText(claim?.prior_auth ?? '', {
      x: 384,
      y: 290
    });
    originalPage.drawText(claim?.bill_taxid ?? '', {
      x: 21,
      y: 100
    });
    originalPage.drawText('x', {
      x:
        claim?.bill_taxid_type === 'S' || claim?.bill_taxid_type === 'SSN'
          ? 138
          : claim?.bill_taxid_type === 'E' || claim?.bill_taxid_type === 'EIN'
            ? 152
            : -20,
      y: 100
    });
    originalPage.drawText(claim?.pcn ?? '', {
      x: 180,
      y: 100
    });
    originalPage.drawText('x', {
      x:
        claim?.accept_assign === 'Y' || claim?.accept_assign === 'Yes'
          ? 289
          : claim?.accept_assign === 'N' || claim?.accept_assign === 'No'
            ? 324
            : -20,
      y: 100
    });
    originalPage.drawText(provName, {
      x: 28,
      y: 54
    });
    originalPage.drawText(provSigDate, {
      x: 114,
      y: 40
    });
    originalPage.drawText(claim?.facility_name ? claim?.facility_name.substring(0, 26) : '', {
      x: 180,
      y: 78
    });
    originalPage.drawText(
      claim?.facility_addr_1
        ? claim?.facility_addr_1
        : claim?.facility_addr_2
          ? claim?.facility_addr_2
          : '',
      {
        x: 180,
        y: 66
      }
    );
    originalPage.drawText(facilityLocation, {
      x: 180,
      y: 54
    });
    originalPage.drawText(claim?.facility_npi ?? '', {
      x: 184,
      y: 39
    });
    originalPage.drawText(claim?.facility_id ?? '', {
      x: 268,
      y: 39
    });
    originalPage.drawText(claim?.bill_name ? claim?.bill_name.substring(0, 30) : '', {
      x: 375,
      y: 78
    });
    originalPage.drawText(
      claim?.bill_addr_1 ? claim?.bill_addr_1 : claim?.bill_addr_2 ? claim?.bill_addr_2 : '',
      {
        x: 375,
        y: 66
      }
    );
    originalPage.drawText(billingLocation, {
      x: 375,
      y: 54
    });
    originalPage.drawText(billPhoneArea, {
      x: 490,
      y: 86
    });
    originalPage.drawText(billPhoneNumber, {
      x: 516,
      y: 86
    });
    originalPage.drawText(claim?.bill_npi ?? '', {
      x: 380,
      y: 39
    });
    originalPage.drawText(claim?.bill_taxonomy ?? '', {
      x: 464,
      y: 39
    });

    for (let i = 0; i < totalPages; i++) {
      const copiedPages = await pdfDoc.copyPages(pdfDoc, [0]);
      const newPage = copiedPages[0];
      pdfDoc.addPage(newPage);

      newPage.setFont(courier);
      newPage.setFontSize(12);

      // Add page count if total pages are higher then one
      if (totalPages > 1) {
        newPage.drawText(`Page ${i + 1} of ${totalPages}`, { x: 144, y: 744 });
      }

      // Draw in the last page
      if (i === totalPages - 1) {
        newPage.drawText(totalBeforeDecimal ?? '', { x: 386, y: 100 });
        newPage.drawText(totalAfterDecimal ?? '', { x: 434, y: 100 });
        newPage.drawText(apBeforeDecimal ?? '', { x: 464, y: 100 });
        newPage.drawText(apAfterDecimal ?? '', { x: 506, y: 100 });
      }

      const startIndex = i * cptPerPage;
      const endIndex = Math.min(startIndex + cptPerPage, totalCpt);

      // Draw cpt codes
      for (let j = startIndex; j < endIndex; j++) {
        if (j < totalCpt) {
          const cpt = claim?.procedures[j];

          const cptCharge = String(cpt?.charge / 100 || '');

          const diagnosis = ia(cpt?.diagnosis)
            ? cpt?.diagnosis
            : cpt?.diagnosis?.length === 0
              ? ''
              : cpt?.diagnosis;
          let chargeBeforeDecimal = '0';
          let chargeAfterDecimal = '00';

          if (cptCharge.includes('.') || cptCharge.includes(',')) {
            const parts = cptCharge.replace(',', '.').split('.');
            chargeBeforeDecimal = parts[0];
            chargeAfterDecimal = parts[1];
          } else {
            chargeBeforeDecimal = cptCharge;
          }

          const charWidth = 10;
          const textLength = chargeBeforeDecimal.length;
          const startingX = 420 - textLength * charWidth + textLength;

          newPage.drawText(moment(cpt?.from_date).format('MM DD YY'), {
            x: 21,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(moment(cpt?.thru_date).format('MM DD YY'), {
            x: 84,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(
            typeof cpt?.place_of_service === 'string'
              ? cpt?.place_of_service
              : typeof cpt?.place_of_service === 'number'
                ? JSON.stringify(cpt?.place_of_service)
                : '',
            {
              x: 150,
              y: 242 - (j - startIndex) * 24
            }
          );
          newPage.drawText((cpt?.procedure_code || cpt?.code) ?? '', {
            x: 198,
            y: 242 - (j - startIndex) * 24
          });

          newPage.drawText(
            (Array.isArray(cpt?.modifiers) ? cpt?.modifiers.join(' ') : cpt?.modifiers) ?? '',
            {
              x: 250,
              y: 242 - (j - startIndex) * 24
            }
          );
          newPage.drawText(diagnosis ?? '', {
            x: 338,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(chargeBeforeDecimal, {
            x: startingX,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(chargeAfterDecimal, {
            x: 418,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(cpt?.units ?? '', {
            x: 446,
            y: 242 - (j - startIndex) * 24
          });
          newPage.drawText(provTaxonomy ?? '', {
            x: 504,
            y: 255 - (j - startIndex) * 24
          });
          newPage.drawText(claim?.prov_id ?? '', {
            x: 504,
            y: 254 - (j - startIndex) * 24
          });
          newPage.drawText(claim?.prov_npi ?? '', {
            x: 504,
            y: 242 - (j - startIndex) * 24
          });
        }
      }
    }

    if (totalPages > 0) {
      pdfDoc.removePage(0);
    }

    const modifiedPdfBytes = await pdfDoc.save();
    const blob = new Blob([modifiedPdfBytes], {
      type: 'application/pdf'
    });
    const url = URL.createObjectURL(blob);
    window.open(url);
  } catch (error) {
    console.error('PDF catch error: ', error);
  }
};
