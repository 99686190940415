const SIZES = {
  small: {
    size: 16,
    fontSize: 'text-[9px]'
  },
  medium: {
    size: 24,
    fontSize: 'text-[12px]'
  },
  large: {
    size: 32,
    fontSize: 'text-[16px]'
  },
  xl: {
    size: 40,
    fontSize: 'text-[18px]'
  },
  '2xl': {
    size: 48,
    fontSize: 'text-[20px]'
  },
  '3xl': {
    size: 56,
    fontSize: 'text-[24px]'
  },
  '4xl': {
    size: 64,
    fontSize: 'text-[28px]'
  },
  '5xl': {
    size: 72,
    fontSize: 'text-[28px]'
  }
};

export const getClasses = (size) => {
  const classes = { ...SIZES[size] };
  return classes;
};
