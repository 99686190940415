export const getTotal = (data) => {
  let sum = Object.values(data)
    .map((value) => parseInt(value.replace(',', ''), 10))
    .reduce((acc, value) => acc + (isNaN(value) ? 0 : value), 0);
  return sum;
};

export const getPercentage = (constantType, data, displayName, total) => {
  const type = constantType?.find((c) => c?.value === displayName);

  const value = data[type?.name];
  const percentage = (value / total) * 100;
  return isNaN(percentage) ? 0 : percentage;
};

export const getClassNamesByPercentage = (constantType, label, data, total) => {
  const percentage = getPercentage(constantType, data, label, total);
  if (percentage === 0) {
    return 'border-solid border-neutral-200  bg-neutral-50 text-neutral-600';
  } else if (percentage < 0) {
    return 'border-solid border-red-200 bg-red-50 text-danger-500';
  } else {
    return 'border-solid border-success-200 bg-success-50 text-success-600';
  }
};
