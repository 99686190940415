import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';
import Popup from 'components/shared/Popup/Popup';

import ActionsButton from './components/ActionsButton';
import NewFilterContent from './components/NewFilterContent';
import FiltersList from './components/SavedFilters/FiltersList';

const Actions = ({
  saveButton,
  setSaveButton,
  saveInput,
  setSaveInput,
  save,
  appliedFilter,
  filtersData,
  applyFilter,
  makeDefault,
  deleteFilter
}) => {
  if (saveButton) {
    return (
      <Popup
        onClose={() => setSaveButton(false)}
        trigger={
          <div>
            <ActionsButton appliedFilter={appliedFilter} />
          </div>
        }
        width={230}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['top right', 'top center']}>
        <NewFilterContent
          saveInput={saveInput}
          setSaveInput={setSaveInput}
          setSaveButton={setSaveButton}
          save={save}
        />
      </Popup>
    );
  }

  return (
    <Popup
      trigger={
        <div>
          <ActionsButton appliedFilter={appliedFilter} />
        </div>
      }
      width={160}
      on={['hover', 'focus']}
      contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
      position={['top right', 'top center']}>
      <div className="flex !w-[160px] flex-col py-[6px]">
        <div
          onClick={() => setSaveButton(true)}
          className={cs(
            'flex cursor-pointer justify-between !px-[12px] py-[6px] hover:bg-primary-50'
          )}>
          <div className="flex gap-[6px] text-sm text-primary-900">
            <Icon
              color="primary"
              icon="new-diskette"
              className="cursor-pointer"
              data-qa="save-btn"
            />
            Save
          </div>
        </div>
        <FiltersList
          filtersData={filtersData}
          applyFilter={applyFilter}
          makeDefault={makeDefault}
          deleteFilter={deleteFilter}
        />
        <div
          onClick={() => makeDefault(filtersData[0]?.id, !filtersData[0]?.is_default)}
          className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
          <Icon icon="rotate-ccw-filled" className="cursor-pointer" />
          Reset & Save
        </div>
      </div>
    </Popup>
  );
};

export default Actions;
