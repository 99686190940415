import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { requestApi } from 'api/Api';
import { optionify, pm } from 'lib/helpers/utility';
import state from 'components/state';
import Button from 'components/shared/Buttons/Button';
import License from './License';
import Modal from 'components/shared/Modal/Modal';
import LicenseEdit from './License/Edit';
import { showAlert } from 'components/shared/Alert/Alert';

const PractitionerLicenseManager = ({ practitionerId = 0 }) => {
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState('add');
  const [currentLicense, setCurrentLicense] = useState(null);
  const [licenses, setLicenses] = useState([]);
  const [issuers, setIssuers] = useRecoilState(state.issuerState);
  const p = useRecoilValue(state.permissions);
  const navigate = useNavigate();

  useEffect(() => {
    if (pm(p, ['license.read']) && practitionerId) {
      loadLicenses();
      if (Array.isArray(issuers.options) && issuers.options.length === 0) {
        loadIssuers();
      }
    }
  }, [p, practitionerId, issuers.options]);

  const loadLicenses = async () => {
    try {
      const onSuccess = (data) => {
        setLicenses(data.licenses);
      };
      const onError = (error) => {
        showAlert({
          title: error || 'An unexpected error has occurred. Please try again later.',
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/practitioner/license/load',
        params: { practitionerId },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      showAlert({
        title: 'There has been an unexpected error. Please try again later.',
        color: 'danger'
      });
    }
  };

  const loadIssuers = async () => {
    try {
      const onSuccess = (data) => {
        const newIssuers = { options: optionify(data.issuers), map: {} };
        data.issuers.forEach((issuer) => {
          newIssuers.map[issuer.id] = issuer;
        });
        setIssuers(newIssuers);
      };
      const onError = (error) => {
        showAlert({
          title: error || 'An unexpected error has occurred. Please try again later.',
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/practitioner/license/issuer/load',
        params: {},
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      showAlert({
        title: 'There has been an unexpected error. Please try again later.',
        color: 'danger'
      });
    }
  };

  const handleEditLicense = (license) => {
    setCurrentLicense({ ...license });
    setMode('edit');
    setShowModal(true);
  };

  const handleAddNewLicense = () => {
    setCurrentLicense({
      edit: true,
      newLicense: true,
      issuer_id: 0,
      license_number: '',
      expired_on: moment()
    });
    setMode('add');
    setShowModal(true);
  };

  if (!pm(p, ['license.read'])) {
    return null;
  }

  return (
    <div>
      <h3 className="mb-3 text-lg font-semibold">License Manager</h3>
      <table className="primary-table">
        <thead>
          <tr>
            <td>Issuer</td>
            <td>Number</td>
            <td>Expiration Date</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          {licenses.length > 0 ? (
            licenses.map((license, index) => (
              <License key={index} license={license} onEdit={handleEditLicense} />
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <div className="w-full p-3 text-center">No Licenses Found</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {pm(p, ['license.create']) && (
        <div className="mt-4 flex justify-end">
          <Button text="Add New License" onClick={handleAddNewLicense} />
        </div>
      )}
      <Modal
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        title={`${mode === 'edit' ? 'Edit' : 'Add New'} License`}
        slideFromRight
        footer={
          <div className="flex w-full justify-end">
            <Button outlined text="Cancel" color="neutral" onClick={() => setShowModal(false)} />
          </div>
        }>
        <LicenseEdit
          license={currentLicense}
          setLicense={(updatedLicense) => {
            const existingLicense = licenses.find((l) => l.id === updatedLicense.id);

            const updatedLicenses = existingLicense
              ? licenses.map((l) => (l.id === updatedLicense.id ? updatedLicense : l))
              : [...licenses, updatedLicense];

            setLicenses(updatedLicenses);
            setShowModal(false);
          }}
          onCancel={() => setShowModal(false)}
          practitionerId={practitionerId}
        />
      </Modal>
    </div>
  );
};

export default PractitionerLicenseManager;
