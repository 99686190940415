import { socket } from 'api/Socket';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import SSOAction from 'components/shared/ePrescribe/SSOAction';
import { patient as patientSSOKind } from 'components/shared/ePrescribe/SSOKinds';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { userState } from 'components/state';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

const MedicationSelectionModal = ({
  isOpen = false,
  handleClose = () => null,
  onPastMedicationSelect = () => null,
  onExistingMedicationSelect = () => null,
  fromWeightLoss = false
}) => {
  const { id: patientId, appointmentId } = useParams();

  const practice = useRecoilValue(currentPractice);
  const user = useRecoilValue(userState);

  const handleStartVeradigmSessionSocket = () => {
    const data = {
      appointmentId: appointmentId || null,
      patientId,
      practice_id: practice?.id,
      user_id: user?.id
    };

    socket.emit('veradigm-prescribe', { ...data });
  };

  return (
    <div>
      <Confirm
        handleOpen={!!isOpen}
        handleClose={handleClose}
        showFooter={false}
        icon="new-info"
        iconColor="primary"
        title="Add Medication"
        message={
          fromWeightLoss ? (
            <p>
              You can either{' '}
              <span className="font-500 text-primary-800">Create a new medication</span> or{' '}
              <span className="font-500 text-primary-800">Select from patient medications</span>.
            </p>
          ) : (
            <p>
              You can either{' '}
              <span className="font-500 text-primary-800">e-prescribe a new medication</span> via
              Veradigm or <span className="font-500 text-primary-800">create a new entry</span> for
              a previously prescribed medication.
            </p>
          )
        }
        bodyClassName="!pb-[0px]">
        <div className="mt-5 flex gap-2">
          <Button
            size="small"
            text={fromWeightLoss ? 'New Medication' : 'Past Medication'}
            onClick={onPastMedicationSelect}
          />
          {fromWeightLoss ? (
            <Button
              size="small"
              text="Select from Patient Medications"
              onClick={onExistingMedicationSelect}
            />
          ) : (
            <SSOAction
              enableEnrollPatient
              patientId={patientId}
              kind={patientSSOKind}
              handleStartVeradigmSessionSocket={handleStartVeradigmSessionSocket}
              isButton
              onSuccess={handleClose}
            />
          )}
        </div>
      </Confirm>
    </div>
  );
};

export default MedicationSelectionModal;
