import React, { useEffect, useRef, useState } from 'react';

import Button from 'components/shared/Buttons/Button';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import SendFaxModal from 'components/practice/comms_center/fax/components/SendFaxModal/SendFaxModal';
import SignModal from 'components/practice/charts/ClinicalNote/shared/Sign/SignModal';
import LabOrderCreate from 'components/practice/charts/ClinicalNote/Orders/views/LabOrderCreate/LabOrderCreate';
import LabOrderPreview from 'components/practice/charts/ClinicalNote/Orders/views/LabOrderPreview/LabOrderPreview';
import NonERXPrescriptionCreate from 'components/practice/charts/ClinicalNote/Orders/views/NonERXPrescriptionCreate/NonERXPrescriptionCreate';
import NonERXPrescriptionPreview from 'components/practice/charts/ClinicalNote/Orders/views/NonERXPrescriptionPreview/NonERXPrescriptionPreview';
import OrderReferralCreate from 'components/practice/charts/ClinicalNote/Orders/views/OrderReferralCreate/OrderReferralCreate';
import OrderReferralPreview from 'components/practice/charts/ClinicalNote/Orders/views/OrderReferralPreview/OrderReferralPreview';
import OrderTypeSelection from 'components/practice/charts/ClinicalNote/Orders/views/OrderTypeSelection';

import { ErrorBoundary } from 'react-error-boundary';
import { useOutletContext } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { Capitalize, iaRa } from 'lib/helpers/utility';
import { useDocumentSign } from 'lib/hooks/queries/documentSign/useDocumentSign';
import { userState } from 'components/state';
import { handleAction } from 'components/practice/charts/ClinicalNote/Orders/lib/handleActionMethods';
import { convertFormToPdf } from 'components/practice/charts/ClinicalNote/Orders/lib/handleSendReferralAsFax';

const OrdersModal = ({
  modalOpen = false,
  handleClose = () => null,
  navigateToParams = null,
  setNavigateToParams = () => null
}) => {
  const { patient } = useOutletContext();
  const actionRef = useRef();
  const printRef = useRef();
  const [sendFaxModalVisible, setSendFaxModalVisible] = useState(false);
  const [faxLoading, setFaxLoading] = useState(false);
  const [faxData, setFaxData] = useState({});
  const user = useRecoilValue(userState);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    navigateToParams && navigateToEditData();
  }, [navigateToParams]);

  const [activeView, setActiveView] = useState({
    type: 'initial',
    component: 'list',
    loading: false
  });

  const handleActionProps = {
    ...activeView,
    setActiveView,
    actionRef
  };

  const navigateToEditData = () => {
    setActiveView((prevState) => ({
      ...prevState,
      ...navigateToParams,
      component: 'preview',
      loading: false
    }));

    setNavigateToParams(null);
  };

  const onSelectActionType = (object) => {
    onNavigateViews({
      overrideObject: { ...activeView, type: object?.type, component: 'create' }
    });
  };

  const onNavigateViews = ({ view = '', overrideObject = {} }) => {
    setActiveView((prevState) =>
      overrideObject ? overrideObject : { ...prevState, component: view }
    );
  };

  const actionViews = {
    initial: {
      components: {
        list: <OrderTypeSelection onSelect={onSelectActionType} />
      }
    },
    referral_order: {
      components: {
        create: (
          <OrderReferralCreate {...handleActionProps} onSave={() => onNavigateViews('create')} />
        ),
        preview: (
          <OrderReferralPreview {...handleActionProps} onEdit={() => onNavigateViews('preview')} />
        )
      }
    },
    non_erx_prescription: {
      components: {
        create: (
          <NonERXPrescriptionCreate
            {...handleActionProps}
            onSave={() => onNavigateViews('create')}
          />
        ),
        preview: (
          <NonERXPrescriptionPreview
            {...handleActionProps}
            onEdit={() => onNavigateViews('preview')}
          />
        )
      }
    },
    lab_order: {
      components: {
        create: <LabOrderCreate {...handleActionProps} onSave={() => onNavigateViews('create')} />,
        preview: (
          <LabOrderPreview {...handleActionProps} onEdit={() => onNavigateViews('preview')} />
        )
      }
    }
  };

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${activeView?.type}_for_${patient?.f_name}_${patient?.l_name}`
  });

  const itemPropsId = activeView?.itemProps?.id ?? activeView?.itemProps?.labOrder?.id;

  let { data: documentData } = useDocumentSign({
    params: {
      documentIds: [itemPropsId],
      documentTypes: [activeView?.type === 'referral' ? 'referral_order' : activeView?.type],
      withRelations: { user: true }
    },
    dependencies: [itemPropsId],
    options: {
      refetchOnMount: false,
      enabled: activeView.component === 'preview' && !!itemPropsId,
      select: (response) => {
        const currentUserDocument = iaRa(response?.data).find((row) => row.user_id == user.id);

        return {
          hasSigned: currentUserDocument?.status === 'signed',
          isUserAssigned: !!currentUserDocument
        };
      }
    }
  });

  const RenderHeadButton = () => {
    return activeView.component === 'preview' ? (
      <div className="flex w-full justify-between">
        <div className="!ml-3">
          {!documentData?.hasSigned && documentData?.isUserAssigned && (
            <Button
              text="Sign"
              icon="new-lock-2-opened"
              iconIsStroke
              type="secondary"
              className="!ml-4"
              onClick={() => setShowConfirmationModal(true)}
            />
          )}
        </div>
        <div className="!mr-4 flex !gap-1">
          <Button
            type="secondary"
            color="neutral"
            icon="new-printer"
            text="Print"
            onClick={print}
            data-qa="print-btn"
          />
          <Button
            type="secondary"
            color="neutral"
            icon="new-e-fax"
            text="Fax"
            onClick={() =>
              convertFormToPdf({
                setFaxData,
                setFaxLoading,
                setSendFaxModalVisible,
                itemProps: activeView?.itemProps,
                patientName: `${patient?.f_name} ${patient?.l_name}`
              })
            }
            loadingIcon={faxLoading}
            data-qa="print-btn"
          />
        </div>
      </div>
    ) : null;
  };

  const RenderFooterButtons = () => {
    return (
      <div className="flex w-full items-center justify-between">
        <Button outlined color="neutral" text="Close" onClick={handleClose} data-qa="close-btn" />
        {activeView.component === 'create' && (
          <div className="flex gap-1.5">
            <Button
              data-qa="save-btn"
              loadingIcon={activeView?.loading}
              text="Save"
              onClick={() => handleAction({ ...handleActionProps, method: 'save' })}
            />
          </div>
        )}
        {activeView.component === 'preview' && (
          <div className="flex gap-1.5">
            <Button
              data-qa="edit-btn"
              text="Edit"
              onClick={() => handleAction({ ...handleActionProps, method: 'edit' })}
            />
          </div>
        )}
      </div>
    );
  };

  const currentView = () => {
    const type = activeView?.type || '';
    const view = activeView?.component || '';

    return actionViews[type]?.components[view];
  };

  const BackButton = () => {
    if (activeView.component === 'list') return null;

    return (
      <div
        onClick={() => handleAction({ ...handleActionProps, method: 'back' })}
        className="!mr-3 cursor-pointer items-center rounded-lg !p-1 transition-all hover:bg-neutral-100">
        <Icon color="black" stroke icon="new-arrow-left" className="cursor-pointer" />
      </div>
    );
  };

  const title = () => {
    switch (activeView.component) {
      case 'list':
        return 'Select Action';
      case 'create':
        if (activeView.type === 'lab_order') return 'Create Lab/Imaging Order';
      default:
        return Capitalize(`${activeView.component} ${activeView.type?.replace(/_/g, ' ')}`);
    }
  };

  return (
    <Modal
      isOpen={modalOpen}
      handleClose={handleClose}
      title={title()}
      prevButton={<BackButton />}
      slideFromRight
      className="w-[924px] !bg-white sm:w-full"
      headButton={<RenderHeadButton />}
      footer={<RenderFooterButtons />}>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <div
          id="referral_order"
          className="break-normal print:!bg-white print:!pt-10"
          ref={printRef}>
          {currentView()}
        </div>
      </ErrorBoundary>

      {sendFaxModalVisible && (
        <SendFaxModal
          sendFaxModalVisible={sendFaxModalVisible}
          setSendFaxModalVisible={setSendFaxModalVisible}
          faxData={faxData}
        />
      )}

      {itemPropsId && (
        <SignModal
          documentId={itemPropsId}
          documentTypes={[actionViews?.type]}
          setShowConfirmationModal={setShowConfirmationModal}
          showConfirmationModal={showConfirmationModal}
          confirmModalProps={{
            title: 'Sign Referral Order',
            message: 'Are you sure you want to sign this referral order',
            primaryBtnTxt: 'Sign referral order'
          }}
        />
      )}
    </Modal>
  );
};

export default OrdersModal;
