import React, { useEffect, useState } from 'react';

import Honeybadger from '@honeybadger-io/js';

import { ia, search } from 'lib/helpers/utility';

import Select from 'components/shared/Select/Select';

import {
  getBillingProviders,
  getFacilityAddress,
  getFacilityProviders,
  getRenderingProviders
} from '../lib';

export const FacilityInformation = ({ formik, superbill, states }) => {
  const [facilityProviderList, setFacilityProviderList] = useState([]);
  const [renderingProviderList, setRenderingProviderList] = useState([]);
  const [billingProviderList, setBillingProviderList] = useState([]);
  const [facilityProvider, setFacilityProvider] = useState();
  const [renderingProvider, setRenderingProvider] = useState();
  const [billingProvider, setBillingProvider] = useState();

  const { values: claim, setFieldValue } = formik;

  useEffect(() => {
    if (superbill) {
      handleGetProvidersData();
    }
  }, []);

  const handleGetProvidersData = async () => {
    try {
      const { billingProvider, billingProviderList } = await getBillingProviders(superbill);
      selectBillingProvider(billingProvider);
      setBillingProviderList(billingProviderList);
    } catch (error) {
      Honeybadger.notify('Error getting billing providers.');
    }

    try {
      const { facilityProvider, facilityProviderList } = await getFacilityProviders(superbill);
      selectFacilityProvider(facilityProvider);
      setFacilityProviderList(facilityProviderList);
    } catch (error) {
      Honeybadger.notify('Error getting facility providers.');
    }

    try {
      const { renderingProvider, mappedProviders } = await getRenderingProviders(superbill);
      selectRenderingProvider(renderingProvider);
      setRenderingProviderList(mappedProviders);
    } catch (error) {
      Honeybadger.notify('Error getting rendering providers.');
    }
  };
  const selectRenderingProvider = (e) => {
    const fieldsToUpdate = {
      prov_name_l: e.prov_name_l,
      prov_name_f: e.prov_name_f,
      prov_name_m: e.prov_name_m,
      prov_npi: e.prov_npi,
      prov_id: e.prov_id,
      prov_taxonomy: e.prov_taxonomy,
      prov_taxid: e.prov_taxid,
      prov_taxid_type: e.prov_taxid_type
    };

    setRenderingProvider({
      label: e.label,
      value: e.value
    });
    Object.entries(fieldsToUpdate).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });
  };
  const selectFacilityProvider = (e) => {
    const fieldsToUpdate = {
      facility_name: e?.facility_name,
      facility_addr_1: e?.facility_addr_1,
      facility_addr_2: e?.facility_addr_2,
      facility_city: e?.facility_city,
      facility_state: search(states, e?.facility_state, 'id'),
      facility_zip: e?.facility_zip,
      facility_npi: e?.facility_npi,
      facility_id: e?.facility_id
    };
    setFacilityProvider({
      label: e.label,
      value: e.value
    });

    Object.entries(fieldsToUpdate).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });
  };
  const selectBillingProvider = (e) => {
    const fieldsToUpdate = {
      bill_name: e?.bill_name,
      bill_addr_1: e?.bill_addr_1,
      bill_addr_2: e?.bill_addr_2,
      bill_city: e?.bill_city,
      bill_state: search(states, e?.bill_state, 'id'),
      bill_zip: e?.bill_zip,
      bill_npi: e?.bill_npi,
      bill_id: e?.bill_id,
      bill_phone: e?.bill_phone,
      bill_taxid: e?.bill_taxid,
      bill_taxid_type: e?.bill_taxid_type,
      bill_taxonomy: e?.bill_taxonomy
    };
    setBillingProvider({
      label: e.label,
      value: e.value
    });
    Object.entries(fieldsToUpdate).forEach(([fieldName, value]) => {
      setFieldValue(fieldName, value);
    });
  };

  return (
    <div className="block rounded-md bg-white p-[1.25rem] shadow">
      <div className="flex sm:flex-col md:items-center">
        <div className="block flex-shrink flex-grow p-3">
          <label className="font-500 text-lg pb-4 block">Rendering provider</label>
          {ia(renderingProviderList) && renderingProviderList.length > 1 && (
            <Select
              className="print:hidden"
              options={renderingProviderList}
              onChange={(e) => selectRenderingProvider(e)}
              value={renderingProvider}
              isClearable={false}
            />
          )}
          <div className="flex pb-1 gap-1">
            <label className="font-500">Name:</label>
            <div>{`${claim?.prov_name_f} ${claim?.prov_name_m} ${claim?.prov_name_l}`}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">NPI: </label>
            <div>{claim?.prov_npi}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Tax ID: </label>
            <div>{claim?.prov_taxid}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">ID: </label>
            <div>{claim?.prov_id}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Taxonomy: </label>
            <div>{claim?.prov_taxonomy}</div>
          </div>
        </div>
        <div className="block flex-shrink flex-grow p-3">
          <label className="text-xl font-500 pb-4 block">Facility information</label>
          {ia(facilityProviderList) && (
            <Select
              className="print:hidden"
              options={facilityProviderList}
              onChange={(e) => selectFacilityProvider(e)}
              value={facilityProvider}
              isClearable={false}
            />
          )}
          <div className="flex pb-1 gap-1">
            <label className="font-500">Name:</label>
            <div>{claim?.facility_name}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">NPI: </label>
            <div>{claim?.facility_npi}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">ID: </label>
            <div>{claim?.facility_id}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Address:</label>

            <div>{getFacilityAddress(claim)}</div>
          </div>
        </div>

        <div className="block flex-shrink flex-grow p-3">
          <label className="font-500 text-lg block pb-4">Billing provider</label>
          {ia(billingProviderList) && (
            <Select
              className="print:hidden"
              options={billingProviderList}
              onChange={(e) => selectBillingProvider(e)}
              value={billingProvider}
              isClearable={false}
            />
          )}
          <div className="flex pb-1 gap-1">
            <label className="font-500">Name:</label>
            <div>{claim?.bill_name}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">NPI: </label>
            <div>{claim?.bill_npi}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Tax ID: </label>
            <div>{claim?.bill_taxid}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">ID: </label>
            <div>{claim?.bill_id}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Taxonomy: </label>
            <div>{claim?.bill_taxonomy}</div>
          </div>
          <div className="flex pb-1 gap-1">
            <label className="font-500">Address:</label>
            <div>{`${claim?.bill_addr_1} ${claim?.bill_addr_2} ${claim?.bill_city} ${claim?.bill_state} ${claim?.bill_zip}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
