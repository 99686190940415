import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import React, { useEffect, useMemo } from 'react';
import './FormsTable.scss';

const FormsTable = ({ items, getGridApi = () => null }) => {
  const { gridApi } = useTableContext();

  useEffect(() => {
    if (gridApi) getGridApi(gridApi);
  }, [gridApi]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={items}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            // onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default FormsTable;
