import cs from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import Icon from '../shared/Icon/Icon';
import ActionButton from '../shared/Button/ActionButton/ActionButton';
import { useKioskRequests, useKiosks } from '../../lib/hooks/queries/useKiosks';
import Kiosk from './components/Kiosk';
import Modal from '../shared/Modal/Modal';
import KioskRequests, { formatKioskRequest } from './components/KioskRequests';
import KioskPatientSelect from './components/KioskPatientSelect';
import Popup from '../shared/Filters/Popup';
import Button from '../shared/Buttons/Button';
import KioskSendForm from './components/KioskSendForm';
import Configurations from '../Payments/Configurations';
// import { socket } from 'api/Socket';
import { useRecoilValue } from 'recoil';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import state from '../state';
import { ia } from 'lib/helpers/utility';
import { set } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import Honeybadger from '@honeybadger-io/js';
import { requestApi } from 'api/Api';

const KioskActions = ({ className }) => {
  const { data } = useKiosks({ params: {} });
  const socket = useRecoilValue(state.socketState);
  const kiosks = data?.kiosks;
  const kiosksExist = ia(kiosks);
  const [showRequests, setShowRequests] = useState(false);
  const [showPatients, setShowPatients] = useState(null);
  const [showForm, setShowForm] = useState(null);
  const [showPayment, setShowPayment] = useState(null);
  const [selected, setSelected] = useState(null);
  const [kioskUpdates, setKioskUpdates] = useState({});
  const { data: requests } = useKioskRequests({});
  const requestCount = requests?.count;
  const user = useRecoilValue(state.userState);
  const queryClient = useQueryClient();
  const [show, setShow] = useState({ receipt: false, transactionId: null });
  const navigate = useNavigate();

  const connectToKiosks = (k, user_id) => {
    let newKioskUpdates = {};
    k?.forEach((kiosk) => {
      if (kioskUpdates[kiosk?.id]) {
        return;
      }
      socket.emit('kiosk-join', { token: kiosk?.token, user_id });
      newKioskUpdates[kiosk?.id] = {
        id: kiosk?.id,
        token: kiosk?.token,
        updates: {}
      };
    });
    setKioskUpdates((ps) => ({ ...ps, ...newKioskUpdates }));
  };

  const handleKioskChanged = useCallback(
    (data) => {
      queryClient.setQueryData(
        ['kiosks'],
        (oldData) => {
          try {
            let newKiosksData = oldData.kiosks.map((v) => {
              if (v?.id !== data?.kioskId) {
                return v;
              }
              return { ...v, ...data };
            });
            return {
              ...oldData,
              kiosks: newKiosksData
            };
          } catch (e) {
            console.error(e);
          }
        },
        { shouldRefetch: false }
      );
    },
    [queryClient]
  );

  const handleKioskPaymentUpdate = useCallback(
    (data) => {
      Honeybadger.notify(
        `kioskPaymentUpdate: ${typeof data === 'string' ? data : JSON.stringify(data)}`
      );
      let color = data?.kind === 'success' ? 'success' : 'warning';
      let title = data?.kind === 'success' ? 'Payment Received!' : 'Payment NOT Received!';
      let message = (
        <div>
          A payment has been completed by{' '}
          <Link to={`portal/charts/${data?.patientId}`} target="_blank" rel="noopener noreferrer">
            {data?.patientName}
          </Link>{' '}
          from kiosk{data?.kioskName && <strong> {data?.kioskName}</strong>}.
        </div>
      );
      let buttons = [];

      if (data?.invoiceId) {
        if (kioskUpdates[data?.kioskId]?.updates?.[data?.invoiceId]) {
          return;
        }
        if (data?.success) {
          color = 'success';
          setKioskUpdates((ps) => set(ps, `${data?.kioskId}.updates.${data?.invoiceId}`, data));
          if (data?.transactionId) {
            buttons = [
              {
                text: 'View Receipt',
                onClick: () => {
                  setShow((ps) => ({ ...ps, receipt: true, transactionId: data?.transactionId }));
                }
              }
            ];
          }
        } else {
          color = 'warning';
          message = (
            <div>
              A payment has NOT been completed by{' '}
              <Link
                to={`portal/charts/${data?.patientId}`}
                target="_blank"
                rel="noopener noreferrer">
                {data?.patientName}
              </Link>{' '}
              from kiosk{data?.kioskName && <strong> {data?.kioskName}</strong>}.
              {data?.message && <br />}
              {data?.message && data?.message}
            </div>
          );
        }
        showAlert({
          color,
          icon: 'new-money-receive',
          title,
          message,
          buttons
        });
      } else {
        if (data?.kind !== 'success') {
          message = (
            <div>
              A payment has NOT been completed by{' '}
              <Link
                to={`portal/charts/${data?.patientId}`}
                target="_blank"
                rel="noopener noreferrer">
                {data?.patientName}
              </Link>{' '}
              from kiosk{data?.kioskName && <strong> {data?.kioskName}</strong>}.
              {data?.message && <br />}
              {data?.message && data?.message}
            </div>
          );
        } else {
          buttons = [
            {
              text: 'View Receipt',
              onClick: () => {
                setShow((ps) => ({ ...ps, receipt: true, transactionId: data?.transactionId }));
              }
            }
          ];
        }
        showAlert({
          color,
          icon: 'new-money-receive',
          title,
          message,
          buttons
        });
      }
    },
    [kioskUpdates]
  );

  const handleKioskNotification = useCallback(
    (data) => {
      switch (data?.type) {
        case 'idle-status':
          return showAlert({
            title:
              data?.status == 'idle'
                ? `Kiosk "${data?.name}" has been idle for one minute`
                : `Kiosk "${data?.name}" has become active!`,
            message: `${data.user?.f_name + ' ' + data.user?.l_name} is ${data.status}!`,
            color: data?.status == 'idle' ? 'warning' : 'success',
            customButtons:
              data?.status == 'idle'
                ? [
                    <Button
                      key="open-requests-tab"
                      color="warning"
                      onClick={() => {
                        requestApi({
                          url: '/api/kiosk/send_idle',
                          params: { kioskId: data?.id },
                          navigate
                        });
                      }}
                      text="Send to Idle Screen"
                    />
                  ]
                : []
          });

        case 'new-request': {
          queryClient.invalidateQueries(['kiosk-requests']);
          const request = formatKioskRequest(data);
          return showAlert({
            title: request.title,
            message: `New request from ${request.kiosk?.name} at ${request.type}`,
            color: 'warning',
            customButtons: [
              <Button
                key="open-requests-tab"
                color="warning"
                onClick={() => setShowRequests(true)}
                text="Open Requests Tab"
              />
            ]
          });
        }
      }
    },
    [queryClient, user?.id]
  );

  useEffect(() => {
    if (kiosksExist && socket?.connected) {
      connectToKiosks(kiosks, user?.id);
      socket.on('kiosk-changed', handleKioskChanged);
      socket.on('kiosk-payment-update', handleKioskPaymentUpdate);
      socket.on('kiosk-notification', handleKioskNotification);
    }

    return () => {
      if (socket?.connected) {
        socket.off('kiosk-changed', handleKioskChanged);
        socket.off('kiosk-payment-update', handleKioskPaymentUpdate);
        socket.off('kiosk-notification', handleKioskNotification);
      }
    };
    // Depend on functions and `kiosks` to avoid unnecessary re-renders
  }, [kiosksExist, socket?.connected]);

  if (!kiosks || kiosks?.length === 0) return null;

  return (
    <>
      <div className={cs('flex items-center', className)}>
        <Popup
          width={400}
          position={['right center']}
          trigger={
            <div className="relative">
              <ActionButton color="#ff00000" icon="kiosk-nav" />
              {requestCount > 0 && (
                <div className="absolute right-[6px] top-[5px] flex h-[6px] min-w-[6px] items-center justify-center rounded-full bg-danger-400 p-[2px]" />
              )}
            </div>
          }>
          {(close) => (
            <div className="flex-col !py-2">
              <div
                onClick={() => {
                  setShowRequests(true);
                  close();
                }}
                className="group flex !w-full cursor-pointer items-center justify-between gap-6 !px-4 py-3 hover:bg-primary-50"
                data-qa="quick-actions-new-patient">
                <div className="flex items-center">
                  <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:!bg-white">
                    <Icon icon="task" />
                  </div>

                  <span className="text-base font-500 text-primary-900">Requests</span>
                </div>

                <div className="flex flex-col items-end justify-center">
                  <div className="!mr-2 rounded-full bg-primary-900 !px-2 py-[2px] text-sm font-medium text-white">
                    {requestCount}
                  </div>
                </div>
              </div>
              {kiosks?.map((kiosk, idx) => (
                <Kiosk
                  key={idx}
                  kiosk={kiosk}
                  idx={idx}
                  setShowPatients={setShowPatients}
                  close={close}></Kiosk>
              ))}
            </div>
          )}
        </Popup>

        <Modal
          slideFromRight
          isOpen={showRequests}
          title="Kiosk requests"
          handleClose={() => setShowRequests(false)}>
          <KioskRequests requests={requests?.requests} />
        </Modal>

        <Modal
          slideFromRight
          isOpen={!!showPatients}
          title="Select patient"
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                text="Cancel"
                outlined
                color="neutral"
                onClick={() => setShowPatients(null)}
              />
            </div>
          }
          handleClose={() => setShowPatients(null)}>
          <KioskPatientSelect
            selected={selected}
            setSelected={setSelected}
            setShowForm={setShowForm}
            showPatients={showPatients}
            setShowPatients={setShowPatients}
            setShowPayment={setShowPayment}
          />
        </Modal>

        <KioskSendForm showForm={showForm} setShowForm={setShowForm} />

        <Modal
          isOpen={!!showPayment}
          handleClose={() => setShowPayment(null)}
          slideFromRight
          handleOpen={!!showPayment}
          title="New payment">
          <Configurations
            kioskId={showPayment?.kiosk?.id}
            patientId={showPayment?.id}
            onClose={() => setShowPayment(null)}
            onSuccess={() => setShowPayment(null)}
          />
        </Modal>

        <ReceiptModal
          isOpen={show.receipt && show.transactionId}
          onClose={() => setShow((ps) => ({ ...ps, receipt: false, transactionId: null }))}
          transactionId={show.transactionId}
        />
      </div>
    </>
  );
};

export default KioskActions;
