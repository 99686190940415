import { getDocumentHistory } from 'api/DocumentHistory';

import { hexToRGBObject } from 'lib/helpers/utility';

const applyChangesToImage = async ({
  image,
  navigate,
  id,
  kind,
  historyData,
  myPortalPractice
}) => {
  let blob = null;
  let scale = 1;
  let changes = {};

  try {
    // Load the base image
    const baseImage = await loadImage(image);
    const canvas = document.createElement('canvas');
    canvas.width = baseImage.width;
    canvas.height = baseImage.height;
    const ctx = canvas.getContext('2d');

    // Draw the base image on the canvas
    ctx.drawImage(baseImage, 0, 0);

    // Retrieve history data (if not passed in)
    const data =
      historyData ||
      (await getDocumentHistory(navigate, {
        kind,
        id,
        myPortalPractice
      }));

    // Retrieve scale (defaulting to 1.5 if not provided)
    scale = data?.record?.changes?.scale || 1.5;

    // For this example we assume the image changes are stored on "page 1"
    const canvasJson = data?.record?.changes?.[1];
    if (!canvasJson) {
      blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
      return { blob, scale, changes, image };
    }

    // Loop through each change object
    for (const obj of canvasJson.objects) {
      const {
        left,
        top,
        width,
        height,
        scaleX,
        scaleY,
        radius,
        text,
        src,
        fontWeight,
        fontStyle,
        fontFamily,
        fontSize
      } = obj;

      // Determine a fill/stroke color (assuming hexToRGBObject returns r, g, b as values between 0 and 1)
      const { r, g, b } = (obj.fill && hexToRGBObject(obj.fill)) ||
        (obj.stroke && hexToRGBObject(obj.stroke)) || { r: 0, g: 0, b: 0 };
      // Convert to a CSS rgb() string
      const fillColor = `rgb(${Math.round(r * 255)}, ${Math.round(
        g * 255
      )}, ${Math.round(b * 255)})`;

      if (obj.type === 'image') {
        // Load the overlay image and draw it
        const overlayImage = await loadImage(src);
        ctx.drawImage(
          overlayImage,
          left / scale,
          top / scale,
          (width * scaleX) / scale,
          (height * scaleY) / scale
        );
      } else if (obj.type === 'i-text') {
        // Split text into lines and draw each line
        const lines = text.split('\n');
        const effectiveFontSize = ((fontSize || 12) * scaleX) / scale;
        ctx.font = `${fontWeight || 'normal'} ${fontStyle || 'normal'} ${effectiveFontSize}px ${
          fontFamily || 'Times New Roman'
        }`;
        ctx.fillStyle = fillColor;
        let y = top / scale;
        for (const line of lines) {
          ctx.fillText(line, left / scale, y);
          y += effectiveFontSize;
        }
      } else if (obj.type === 'rect') {
        // Draw a filled rectangle
        ctx.fillStyle = fillColor;
        ctx.fillRect(
          left / scale,
          top / scale,
          (width * scaleX) / scale,
          (height * scaleY) / scale
        );
      } else if (obj.type === 'circle') {
        // Draw an ellipse (or circle) using the canvas ellipse API
        ctx.fillStyle = fillColor;
        ctx.beginPath();
        ctx.ellipse(
          (left + radius * scaleX) / scale,
          (top + radius * scaleY) / scale,
          (radius * scaleX) / scale,
          (radius * scaleY) / scale,
          0,
          0,
          2 * Math.PI
        );
        ctx.fill();
      } else if (obj.type === 'path') {
        // Build an SVG path string by transforming each coordinate:
        // For x coordinates: newX = (left + c) / scale
        // For y coordinates: newY = canvas.height - ((top + c) / scale)
        const svgPath = obj.path
          .map((command) => {
            const type = command[0];
            const coords = command.slice(1).map((c, i) => {
              if (i % 2 === 0) {
                // x coordinate
                return c / scale;
              } else {
                // y coordinate
                return c / scale;
              }
            });
            return `${type} ${coords.join(' ')}`;
          })
          .join(' ');

        const path2d = new Path2D(svgPath);

        if (obj.stroke) {
          ctx.strokeStyle = fillColor;
          ctx.lineWidth = obj.strokeWidth * 2 || 2;
          ctx.lineCap = 'round';
          ctx.stroke(path2d);
        } else {
          ctx.fillStyle = fillColor;
          ctx.fill(path2d);
        }
      }
    }

    // Convert the canvas drawing to a blob
    blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/png'));
    changes = data?.record?.changes;
  } catch (error) {
    console.error('Error applying changes to image:', error);
  }

  return { blob, scale, changes, image };
};

// Helper function to load an image and return a Promise
function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

export default applyChangesToImage;
