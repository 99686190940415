import React, { useState } from 'react';
import { AlertContent } from '../../shared/Alert/Alert';
import { Link } from 'react-router-dom';

/**
 * @name Checkin.LoginWarning
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const CheckinLoginWarning = ({ data = '', note = '' }) => {
  const [show, setShow] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <AlertContent
      color={'warning'}
      title={'Login to Review'}
      handleClose={() => setShow(false)}
      message={
        <div>
          <div>
            In order to view and modify {data ? data : 'existing data'}, please{' '}
            <Link to={'/login'}>login</Link>.
          </div>
          {note && <div className={'italic'}>{note}</div>}
        </div>
      }
    />
  );
};

export default CheckinLoginWarning;
