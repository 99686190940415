import React from 'react';
import { useFormikContext } from 'formik';

import Item from './components/Item';
import Discount from './components/Discount';
import Allocation from './components/Allocation';
import { ia, mString } from 'lib/helpers/utility';
import DeferredPayment from './components/DeferredPayment';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const Balance = ({ insuranceProfiles }) => {
  const { device } = useUIContext();
  const { values, setFieldValue } = useFormikContext();

  const { balance, tax, total_amount, amount_paid } = values?.invoice || {};

  return (
    <div className="flex flex-col overflow-hidden xs:pt-4">
      <Item
        icon="new-wallet-add"
        price={mString(balance)}
        text={device === 'desktop' ? 'Appointment balance' : 'Balance'}
        className="min-h-[38px] gap-2 border-0 border-b border-solid border-neutral-200 !px-4"
        priceClassName="font-600 !text-base"
      />
      <div className="flex h-full max-h-[266px] flex-col gap-x-3 gap-y-[6px] overflow-auto border-0 border-l border-solid border-neutral-200 px-4 py-2 xs:border-0">
        {values?.no_payment_selected && !ia(values?.payments) && <DeferredPayment />}
        <Item icon="new-dollar-circle" text="Est. Patient Portion" price={mString(total_amount)} />
        <Item icon="new-wallet-add" text="Payments" price={mString(amount_paid)} />
        <Item text="Discount" icon="new-discount-shape" price={<Discount />} />
        <Item
          icon="new-receipt-discount"
          text="Tax"
          price={
            <CurrencyInput
              value={tax ?? 0}
              className="text-right"
              onValueChange={(v) => setFieldValue('tax', +v)}
              wrapperClassName="!pl-1 !pr-0 h-[26px] w-[85px] !px-[4px] !bg-transparent border-none border-bottom [border-bottom-style:solid] rounded-none focus-within:!outline-[0px] !outline-0"
            />
          }
        />
        <Allocation insuranceProfiles={insuranceProfiles} />
      </div>
    </div>
  );
};

export default Balance;
