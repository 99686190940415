import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import SimpleTable from '../../../../../../shared/Forms/Custom/ProviderMedications/components/SimpleTable';
import { formatDate, stripHtmlTags } from 'lib/helpers/utility';

const cols = [
  {
    field: 'surgery.value',
    headerName: 'Surgery',
    valueFormatter: ({ data }) => (data?.surgery?.value ? data?.surgery?.value : 'N/A')
  },
  {
    field: 'date',
    className: 'min-w-20',
    headerName: 'Date',
    valueFormatter: ({ value }) => (value ? formatDate(value) : 'N/A')
  },
  {
    field: 'note',
    headerName: 'Note',
    cellRenderer: ({ value }) => (value ? stripHtmlTags(value) : 'N/A')
  },
  {
    field: 'surgeon.value',
    headerName: 'Surgeon',
    valueFormatter: ({ data }) => (data?.surgeon?.value ? data.surgeon.value : 'N/A')
  }
];

const SurgicalHistorySimpleTable = ({ data, emptyState = 'No surgical history' }) => {
  return (
    <TableContextProvider name="surgical_history_simple_table" defaultFilters={[]} cols={cols}>
      <SimpleTable data={data} emptyState={emptyState} />
    </TableContextProvider>
  );
};

export default SurgicalHistorySimpleTable;
