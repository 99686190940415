import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';

import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';

import { requestApi } from 'api/Api';
import { addTerminal, updateTerminal } from 'api/Terminal';

import { useTerminalGroups } from 'lib/hooks/queries/admin/useTerminalGroups';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Modal from 'components/shared/Modal/Modal';

import { TerminalValidationSchema } from '../lib/TerminalValidationSchema';

import EditTerminalFormikInput from './EditTerminalFormikInput';

import '../lib/react-select-patch.scss';

const TerminalModal = ({ item: terminal, isOpen, handleClose, type = 'new' }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [createdNewGroups, setCreatedNewGroups] = useState(false);
  const [terminalGroups, setTerminalGroups] = useState([]);
  const [show, setShow] = useState({ groupLoading: false, newTerminalOpen: false });
  const [selectedTerminalGroup, setSelectedTerminalGroup] = useState(null);

  const result = useTerminalGroups({
    params: { practiceId: id },
    options: { enabled: isOpen }, // Only fetch when isOpen is true
    dependencies: [],
    name: 'terminal-groups-modal'
  });

  const { mutate } = useCustomMutation({
    mutationFn: (params) =>
      terminal?.id && type == 'edit'
        ? updateTerminal(navigate, params)
        : addTerminal(navigate, params),
    successMessage:
      type == 'edit' ? 'Successfully edited terminal!' : 'Successfully added a new terminal!',
    invalidateQueryKey: 'terminal_admin',
    onSuccess: () => {
      formik.resetForm();
      handleClose();
    }
  });

  const formik = useFormik({
    initialValues: {
      name: terminal?.name || '',
      pin: terminal?.pin || '',
      merchant_id: terminal?.merchant_id || '',
      vendor_id: terminal?.vendor_id || '',
      user_id: terminal?.user_id || '',
      brand: terminal?.brand || '',
      kind: terminal?.kind || '',
      note: terminal?.note || '',
      online: terminal?.online || false
    },
    enableReinitialize: true,
    validationSchema: TerminalValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      mutate({
        changes: { ...values, ...(terminal?.id ? { id: terminal?.id } : {}), practice_id: id }
      });
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (terminal?.group_id && isOpen && !createdNewGroups) {
      const foundTerminalGroup = terminalGroups.find((t_g) => t_g.id == terminal.group_id);
      setSelectedTerminalGroup(foundTerminalGroup);
    }
  }, [terminalGroups, terminal?.group_id, isOpen, createdNewGroups]);

  useEffect(() => {
    if (result?.data?.groups) {
      setTerminalGroups(
        result.data.groups.map((g) => ({
          ...g,
          label: g.name // Add a new 'label' for groups select component below
        }))
      );
    }
  }, [result?.data?.groups]);

  const onCreateGroup = async (newGroupName) => {
    const newShow = Object.assign({}, show);
    const onSuccess = async ({ group }) => {
      formik.setFieldValue('group_id', group.value);
      setCreatedNewGroups(true);
      setSelectedTerminalGroup(group);
      groupHandleChange({ target: { name: 'group_id', value: group?.value || null } });
      setTerminalGroups([...terminalGroups, group]);
    };
    const onError = (error) => {
      showAlert({ title: error, color: 'danger' });
    };
    try {
      await requestApi({
        url: '/api/transactions/group/add',
        params: {
          id,
          groupName: newGroupName
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/newTerminal, method: onCreateGroup - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
    setShow(newShow);
  };

  const groupHandleChange = (e) => {
    const { name, value } = e.target;

    formik.setFieldValue(name, value);
  };

  const handleToggle = (target, value) => {
    formik.setFieldValue(target, value);
  };

  return (
    <Modal
      slideFromRight
      handleOpen={isOpen}
      handleClose={() => {
        if (createdNewGroups) {
          setSelectedTerminalGroup(null);
          setCreatedNewGroups(null);
        }
        handleClose();
      }}
      title={type == 'edit' ? `Edit Terminal` : 'New Terminal'}
      className="min-w-[750px]"
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={handleClose} />
          <Button text="Save" onClick={formik.submitForm} green data-qa="terminal-save-btn" />
        </div>
      }>
      <div className="grid grid-cols-2 gap-2">
        <EditTerminalFormikInput formik={formik} target="name" label="Name" />
        <EditTerminalFormikInput formik={formik} target="pin" label="PIN" />
        <EditTerminalFormikInput formik={formik} target="merchant_id" label="Merchant ID" />
        <EditTerminalFormikInput formik={formik} target="vendor_id" label="Vendor ID" />
        <EditTerminalFormikInput formik={formik} target="user_id" label="User ID" />
        <EditTerminalFormikInput formik={formik} target="brand" label="Brand" />
        <EditTerminalFormikInput formik={formik} target="kind" label="Kind" />
        <EditTerminalFormikInput formik={formik} target="note" label="Notes" />
        <div className="flex w-full flex-col items-start justify-center gap-2">
          <label className="text-sm font-medium leading-snug text-neutral-800">Groups</label>
          <CreatableSelect
            className="w-full"
            isClearable
            onChange={(e) => {
              groupHandleChange({ target: { name: 'group_id', value: e?.id || null } });
              setSelectedTerminalGroup(e);
            }}
            onCreateOption={onCreateGroup}
            options={terminalGroups}
            value={selectedTerminalGroup}
          />
        </div>
        <div className="flex w-full justify-start items-center pt-7">
          <Checkbox
            id="select-all"
            label="Online"
            color="blue"
            isChecked={formik?.values?.online}
            handleClick={() => handleToggle('online', !formik?.values?.online)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default TerminalModal;
