import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../../../api/User';

export const useUser = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['user', ...dependencies], () => getUser(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
