import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getOccupations } from 'api/Occupations';

export const useOccupations = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['occupations', ...dependencies], () => getOccupations(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
