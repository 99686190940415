import ProfilePicture from 'components/Profile/ProfilePicture';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonalInfoContainer from './PersonalInfoContainer';
import { formatDate, formatPhoneNumber } from '/lib/helpers/utility';

const AdminPatientsOverview = ({ patient }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const childComponentRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      if (childComponentRef.current?.isLoading !== isLoading) {
        setIsLoading(childComponentRef.current?.isLoading);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isLoading]);

  const handleOnSave = (e) => {
    e.preventDefault();
    childComponentRef.current.onSubmit();
  };
  const ModalFooter = () => (
    <div className="flex w-full items-center justify-between">
      <Button
        onClick={() => setShowModal(false)}
        data-qa="close-btn"
        color="neutral"
        text="Close"
        outlined
      />
      <Button onClick={handleOnSave} loading={isLoading} data-qa="save-btn" text="Save" />
    </div>
  );

  if (!patient)
    return (
      <div className="!p-4">
        <Skeleton height="500px" />
      </div>
    );

  return (
    <div className="shadow-default relative flex flex-wrap items-center justify-between gap-20 !rounded-xl bg-white !py-2 !pl-14 !pr-4">
      <div
        className="absolute -left-3 top-1/2 ml-3 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-neutral-300 bg-white shadow-small hover:!bg-neutral-50"
        onClick={() => navigate('/admin/patients')}>
        <Icon icon="new-arrow-left" className="flex cursor-pointer" />
      </div>
      <div className="flex flex-wrap items-center gap-x-20 gap-y-4">
        <div className="flex items-center !gap-4">
          <div>
            <ProfilePicture
              firstName={patient?.f_name}
              id={patient?.id}
              image={patient?.header_photo}
              size={56}
            />
          </div>
          <div className="flex flex-col">
            <p
              className="w-max text-lg font-500 text-primary-900"
              data-dd-privacy="allow"
              data-public>
              {patient?.f_name + ' ' + patient?.l_name}
            </p>
            <div className="flex gap-1">
              <p className="text-xs text-neutral-800">Phone:</p>
              <p className="text-xs text-neutral-800"> {formatPhoneNumber(patient?.phone)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-9">
          <div className="flex flex-col gap-[2px]" data-dd-privacy="allow" data-public>
            <p className="text-sm font-500 text-primary-800">ID</p>
            <p className="text-xs text-neutral-800">{patient?.id}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Address</p>
            <p className="text-xs text-neutral-800">{patient?.userAddress?.fullAddress}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Email</p>
            <p className="text-xs text-neutral-800">{patient?.email}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Joined</p>
            <p className="text-xs text-neutral-800">{formatDate(patient?.created_at)}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <Button
          data-qa="edit-profile-btn"
          text="Edit profile"
          type="secondary"
          size="small"
          icon="new-edit"
          onClick={() => setShowModal(true)}
        />
      </div>
      <Modal
        slideFromRight
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        title="Patient information"
        footer={<ModalFooter />}>
        <PersonalInfoContainer
          ref={childComponentRef}
          patient={patient}
          setShowModal={setShowModal}
        />
      </Modal>
    </div>
  );
};
export default withErrorBoundary(AdminPatientsOverview);
