import React, { useEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Icon from '../../../../../shared/Icon/Icon';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import ConfirmSignLock from '../../shared/ConfirmSignLock';
import TPModal from '../../TreatmentPlan/TPModal';
import { userState } from '../../../../../state';
import { useRecoilValue } from 'recoil';
import { useDocumentSign } from '../../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { ia } from '../../../../../../lib/helpers/utility';

const NavbarPopover = () => {
  const {
    cnDisplaySettings,
    clinicalNote,
    showConfirmSignLock,
    setSelectedTreatmentPlan,
    saveButtonText
  } = useClinicalNoteContext();
  const [showTPModal, setShowTPModal] = useState(false);

  const user = useRecoilValue(userState);
  const [userSign, setUserSign] = useState(false);

  const { data: documentSignatures } = useDocumentSign({
    params: {
      documentIds: [clinicalNote?.id]
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id,
      select: (response) => response.data
    }
  });

  useEffect(() => {
    const hasUserSigned =
      documentSignatures?.find((row) => row.user_id == (user?.user_id ?? user.id))?.status ===
      'signed';

    setUserSign(!ia(documentSignatures) && clinicalNote?.locked ? true : hasUserSigned);
  }, [documentSignatures, clinicalNote?.id]);

  const openTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(true);
  };

  const closeTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(false);
  };

  const onSignLockClick = () => {
    if (saveButtonText === 'Saving' || !clinicalNote?.id) return;

    showConfirmSignLock(true);
  };

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <div>
            <Popover.Button className="p-0" data-qa="clinical-notes-menu">
              <Icon
                shade={100}
                color="primary"
                stroke
                icon="chevrons-down"
                className={cs(
                  'flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md bg-primary-800',
                  open && '!bg-primary-700'
                )}
              />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-max min-w-[160px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
              <div
                disabled={saveButtonText === 'Saving' || !clinicalNote?.id}
                data-qa="sign-lock"
                className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                onClick={onSignLockClick}>
                <Icon icon={userSign ? 'new-unlockv2' : 'new-lockv2'} color="primary" />
                <span className="text-sm font-400 text-primary-900">
                  {userSign ? 'Unlock' : 'Sign & Lock'}
                </span>
              </div>
              {cnDisplaySettings?.treatment_plan?.enabled && (
                <div
                  data-qa="treatment-plan-btn"
                  className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                  onClick={openTPModal}>
                  <Icon icon="new-task-square" />
                  <span className="text-sm font-400 text-primary-900">Treatment plan</span>
                </div>
              )}
            </Popover.Panel>
          </div>
        )}
      </Popover>
      <ConfirmSignLock documentSignatures={documentSignatures} hasUserSigned={userSign} />
      <TPModal isOpen={showTPModal} handleClose={closeTPModal} />
    </>
  );
};

export default NavbarPopover;
