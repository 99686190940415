import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Goals from './Goals';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const GoalsSection = ({ components, sectionRef }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Goals', parentKey: 'progress', childKey: 'goals' }}
      title={components?.goals?.title}
      id={components?.goals?.id}
      disabled={clinicalNote?.locked}>
      <Goals sectionRef={sectionRef} section="progress" />
    </Accordion>
  );
};

export default GoalsSection;
