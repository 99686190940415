import React from 'react';
import Accordion from '../../../../shared/Accordion';
import GeneralNote from '../../../GeneralNote';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';
import ClinicalNoteCurrentNoteTypeNotesMyScribeWarning from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeWarning';

const GeneralNoteSection = ({ components, sectionRef, options }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.notes?.title}
      id={components?.notes?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <GeneralNote sectionRef={sectionRef} />
      <ClinicalNoteCurrentNoteTypeNotesMyScribeWarning />
    </Accordion>
  );
};

export default GeneralNoteSection;
