import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getTreatmentPlan } from '../../../../api/TreatmentPlan';

export const useTreatmentPlan = (params, options) => {
  const navigate = useNavigate();
  return useQuery(['treatmentPlan', [params]], () => getTreatmentPlan(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
