import formatDate from 'lib/helpers/utility';

import Logs from 'components/shared/Logs';
import Allowed from 'components/shared/Permissions/Allowed';

const AdminPractitionersLogs = () => {
  const defaultLogFilters = () => ({
    order: { label: 'Newest', value: 'desc' },
    date: {
      values: null,
      type: 'date-range',
      title: 'Date',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    },
    id: {
      type: 'search',
      values: '',
      title: 'ID'
    },
    url: {
      type: 'search',
      values: '',
      title: 'URL'
    },
    data: {
      type: 'search',
      values: '',
      title: 'Data'
    }
  });

  const filters = defaultLogFilters();

  return (
    <Allowed requiredPermissions="user_events.read" showMessage showIllustration>
      <Logs defaultLogsFilters={filters} />
    </Allowed>
  );
};

export default AdminPractitionersLogs;
