import React from 'react';
import cs from 'classnames';

export default function SelectableButton({ isChecked, label, onChange, className, ...props }) {
  return (
    <div
      className={cs(
        'flex cursor-pointer items-center justify-center rounded-[0.5rem] !p-[12px]',
        isChecked
          ? 'bg-primary-500 !text-white'
          : 'border-[1px] border-solid border-primary-200 bg-white !text-primary-500',
        className
      )}
      onClick={onChange}
      {...props}>
      <p className="!mb-0 text-sm">{label}</p>
    </div>
  );
}
