const validations = {
  pt_portion: {
    max: (max) => `Please enter a valid amount less than or equal to Allowed Amount (${max}).`
  },
  refund_amount: {
    max: (max) => `Please enter a valid amount less than or equal to Amount Paid (${max}).`,
    min: (min) => `Please enter a valid amount greater than or equal to Minimum Refund (${min}).`
  }
};

export const getValidationMessages = (key) => validations[key];
