export const TYPE_LABELS = {
  IN_PROGRESS: 'In Progress',
  cash: 'Cash',
  card: 'Card',
  check: 'Check',
  care_credit: 'Outside Financing',
  ONLINE: 'Online',
  COF: 'COF',
  SWIPE: 'Swipe',
  TOKEN: 'Token',
  CARD_PRESENT: 'Card Present',
  EMV_CONTACT: 'Emv Contact',
  EMV_PROXIMITY: 'Emv Proximity',
  'Kiosk Card Present': 'Kiosk Card Present',
  writeoff: 'Write Off',
  billing_cost: 'Billing Cost',
  potential_ar: 'Potential AR',
  UNKNOWN: 'Unknown',
  declined: 'Declined',
  failed: 'Failed'
};
