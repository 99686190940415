import React from 'react';

import { mString } from 'lib/helpers/utility';
import { MemoComponent } from '../lib/external/configs';

const NewInvoiceView = ({ invoiceDetails, hasClaims }) => {
  const {
    tax = 0,
    tax_amount_cents = 0,
    prevDiscount = 0,
    charge = 0,
    discount = 0,
    amount_paid = 0,
    ins_payment = 0,
    ins_adjustment = 0,
    surcharge_amount = 0,
    adjustment = 0,
    memo = null,
    aeob: isAEOB = null,
    ptBalance = 0,
    totalAmount = 0
  } = invoiceDetails || {};

  let total = charge - ins_payment - ins_adjustment - prevDiscount - amount_paid + tax - adjustment;

  if (!isAEOB) total = ptBalance;

  const subTotal = total - discount;

  let amountDue = subTotal + surcharge_amount;

  if (subTotal > 0) amountDue += tax_amount_cents;

  return (
    <>
      {memo && (
        <div className="pt- m-3 items-center rounded bg-gradient-to-r from-primary-25 to-[#004f6b0d] p-5">
          <div className="mb-3 text-lg font-semibold">Memo</div>
          <MemoComponent value={memo} />
        </div>
      )}
      <div className="relative z-20 m-[16px_-16px_-16px_-16px] flex min-h-[40px] items-center !gap-2 bg-gradient-to-r from-[#004F6B3D] to-[#004f6b0d] !px-4 py-3 uppercase text-primary-900">
        <div className="my-4 w-full px-8">
          <div className="mx-3">
            <div className="mb-4">
              {isAEOB ? (
                <>
                  <div className="mt-3 flex justify-between">
                    <span>Billed</span>
                    {mString(charge)}
                  </div>

                  {hasClaims && (
                    <>
                      <div className="mt-3 flex justify-between">
                        <span>Insurance Covered</span>
                        <span>
                          {ins_payment > 0 ? '-' : ''}
                          {mString(ins_payment)}
                        </span>
                      </div>

                      <div className="mt-3 flex justify-between">
                        <span>Insurance Adjusted</span>
                        <span>
                          {ins_adjustment > 0 ? '-' : ''}
                          {mString(ins_adjustment)}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="mt-3 flex justify-between">
                  <span>Billed</span>
                  {mString(totalAmount)}
                </div>
              )}

              {amount_paid > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Patient Paid</span>
                  <span>-{mString(amount_paid)}</span>
                </div>
              )}

              {prevDiscount > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Discount</span>
                  <span>-{mString(prevDiscount)}</span>
                </div>
              )}

              {adjustment > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Adjustment</span>
                  <span>-{mString(adjustment)}</span>
                </div>
              )}

              {tax > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Tax</span>
                  <span>{mString(tax)}</span>
                </div>
              )}

              <hr className="my-8 h-px border-0 bg-gray-300" />

              <div className="flex justify-between border-t pt-3">
                <span className="font-bold">Subtotal</span>
                <span className="text-lg font-bold">{mString(total)}</span>
              </div>

              <div className="mt-3 flex justify-between">
                <span>Additional Discount</span>
                <span>{mString(discount)}</span>
              </div>

              {surcharge_amount > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Technology Fee</span>
                  <span>{mString(surcharge_amount)}</span>
                </div>
              )}
            </div>
            <div className="mt-3 flex justify-between border-t pt-3">
              <span className="font-bold">Amount Due</span>
              <span className="text-2xl font-bold">{mString(amountDue)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewInvoiceView;
