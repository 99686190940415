import React from 'react';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { reShapePractitioners, reShapeProcedures } from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import NewAppointment from 'components/practice/appointment/NewAppointment/NewAppointment';
import { NewAppointmentContextProvider } from 'lib/context/Appointment/NewAppointmentContextProvider';

const NewAppointmentModal = ({ showModal, setShowModal }) => {
  const { data: practitionersData } = usePractitioners(null);

  if (!showModal) return null;

  return (
    <NewAppointmentContextProvider>
      <NewAppointment
        isOpen={showModal}
        hideNewAppointment={() => setShowModal(false)}
        practitioners={reShapePractitioners(practitionersData?.practitioners)}
      />
    </NewAppointmentContextProvider>
  );
};

export default NewAppointmentModal;
