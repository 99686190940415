import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { tagColorOptions } from '../../../lib/tagColorOptions';

export const TagMultiValue = ({ data, handleRemove }) => {
  const { color, label, important } = data;
  const tagColorOption = tagColorOptions.find((option) => option.color === color);
  const borderColor = tagColorOption?.border;

  return (
    <div
      className="flex h-[24px] cursor-default items-center gap-[6px] rounded px-[10px] py-[2px]"
      style={{
        backgroundColor: color || '#13B9FF',
        border: `1px solid ${borderColor || 'transparent'}`
      }}>
      <span
        className={cs(
          'flex items-center gap-[6px] leading-5',
          tagColorOption ? 'text-neutral-900' : 'text-white'
        )}>
        {important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
        {label}
      </span>
      <Icon
        icon="new-close-sm"
        size={8}
        color={tagColorOption ? 'black' : 'white'}
        stroke
        className="cursor-pointer"
        onClick={handleRemove}
        data-qa={`tag-remove-${label}`}
      />
    </div>
  );
};
