import { useEffect, useRef } from 'react';

export const useSequentialDisplay = (keys, autoMapFormRef, setVisibleIndexes) => {
  const timeoutIds = useRef([]); // Ref to store timeout IDs

  useEffect(() => {
    // Display keys with staggered delay
    keys.forEach((_, index) => {
      const timeoutId = setTimeout(() => {
        setVisibleIndexes((prev) => (!prev.includes(index) ? [...prev, index] : prev));
      }, index * 100); // Staggered delay
      timeoutIds.current.push(timeoutId); // Store the timeout ID
    });

    // Display "notes" at the end if it exists
    if (autoMapFormRef.current['notes']?.length > 0) {
      const notesTimeoutId = setTimeout(() => {
        setVisibleIndexes((prev) => (!prev.includes('notes') ? [...prev, 'notes'] : prev));
      }, keys.length * 100); // Delay after all keys are displayed
      timeoutIds.current.push(notesTimeoutId); // Store the timeout ID
    }

    // Cleanup function to clear all timeouts
    return () => {
      timeoutIds.current.forEach((timeoutId) => clearTimeout(timeoutId));
      timeoutIds.current = []; // Reset the timeout IDs array
    };
  }, [keys, autoMapFormRef, setVisibleIndexes]);
};
