import React from 'react';

import { formatLargeNumber } from 'lib/helpers/utility';

export const CustomStatusBarCount = ({
  data = [],
  count = 0,
  page = 1,
  limit = 25,
  pagination
}) => {
  if (data.length === 0) return null;

  const totalPages = Math.ceil(count / limit); // Found a bug where we can filter if we are based on a page where the items fetched cannot reach that fill out of pages
  // therefore we return items total to it's extend point i.e TotalPages | CONSULT BEFORE REMOVING!

  const start = page > totalPages ? totalPages : (page - 1) * (pagination ? limit : count) + 1;
  const end = Math.min(page * (pagination ? limit : count), count);

  return (
    <div className="flex h-full items-center justify-end gap-[3px] p-0">
      {count > 0 && (
        <p className="text-xs text-neutral-900">
          <span className="font-bold">{formatLargeNumber(start)}</span>
          <span className="mx-1">to</span>
          <span className="font-bold">{formatLargeNumber(end)}</span>
          <span className="mx-1">of</span>
          <span className="font-bold">{formatLargeNumber(count)}</span>
        </p>
      )}
    </div>
  );
};
