import React from 'react';
import './BreadCrumbs.scss';
import cs from 'classnames';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const BreadCrumbs = ({ breadcrumbs, onNavigate }) => {
  let filteredBreadcrumbs = breadcrumbs.filter((crumbs) => !crumbs?.hidden);

  const handleNavigation = (view) => {
    const originalIndex = breadcrumbs.findIndex((crumb) => crumb.view === view);
    if (onNavigate && originalIndex !== -1) {
      onNavigate(view, originalIndex);
    }
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="breadcrumbs">
        {filteredBreadcrumbs.map((item, index) => (
          <div
            key={item.title}
            className={cs(
              'breadcrumbs__item cursor-pointer',
              item.active && 'active',
              item.isFinished && 'finished',
              index === filteredBreadcrumbs.length - 1 && 'last',
              index === 0 && 'first',
              filteredBreadcrumbs.length === 1 &&
                index === filteredBreadcrumbs.length - 1 &&
                'single'
            )}
            onClick={() => handleNavigation(item.view)}>
            <span className="text-[14px] font-500">{item.title}</span>
          </div>
        ))}
      </div>
    </ErrorBoundary>
  );
};

export default BreadCrumbs;
