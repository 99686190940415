import Resizer from 'react-image-file-resizer';
import 'react-image-crop/dist/ReactCrop.css';

export const captureHighResScreenshot = (webcamRef) => {
  const videoElement = webcamRef?.current?.video;
  const originalWidth = videoElement.videoWidth;
  const originalHeight = videoElement.videoHeight;

  videoElement.style.width = `${originalWidth}px`;
  videoElement.style.height = `${originalHeight}px`;

  const screenshot = webcamRef.current.getScreenshot();

  videoElement.style.width = '';
  videoElement.style.height = '';

  return screenshot;
};

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1400,
      1400,
      'JPEG',
      90,
      0,
      (uri) => {
        fetch(uri)
          .then((res) => res.blob())
          .then((blob) => {
            resolve(blob);
          });
      },
      'base64'
    );
  });

export const getCroppedImage = async (crop, imageRef, handleContinue, setUrl) => {
  if (!crop || !imageRef.current) {
    console.error('Image or crop not available');
    return;
  }

  const canvas = document.createElement('canvas');
  const scaleX = imageRef.current.naturalWidth / imageRef.current.width;
  const scaleY = imageRef.current.naturalHeight / imageRef.current.height;

  // Set the canvas width and height based on the natural resolution of the crop
  canvas.width = crop.width * scaleX;
  canvas.height = crop.height * scaleY;
  const ctx = canvas.getContext('2d');

  // Clear the canvas before drawing
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Draw the cropped portion at natural resolution
  ctx.drawImage(
    imageRef.current,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    canvas.width,
    canvas.height
  );

  // Convert canvas to a blob
  const croppedImageBlob = await new Promise((resolve) => {
    canvas.toBlob((blob) => resolve(blob), 'image/jpeg', 0.9); // Quality set to 1.0
  });

  handleContinue(croppedImageBlob);

  const url = canvas.toDataURL('image/jpeg');
  setUrl && setUrl(url);
};
