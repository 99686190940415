import React, { useEffect, useMemo, useState } from 'react';

import { camelCase } from 'lodash';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { convertMarkdownToHTML, iaRa } from 'lib/helpers/utility';

import NotFound from 'components/NotFound';
import { getTypeOfNotes } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';

import CustomFormsAI from '../../../../components/forms/CUSTOM/CustomFormsAI';

import AutoMapTextArea from './AutoMapTextArea';
import { useSequentialDisplay } from './hooks/useSequentialDisplay';
import { replaceStrongWithDiv } from './lib/helpers';

const ResponseMapView = ({ note = {}, disabled = false }) => {
  const jsonMessage = JSON.parse(note);

  const { cnDisplaySettings, advancedHP, advancedSOAP, customFormTypes, autoMapFormRef } =
    useClinicalNoteContext();

  const allFormTypes = getTypeOfNotes({
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    customFormTypes
  });
  const DynamicForm = AutoMapTextArea || CustomFormsAI || NotFound;

  const components = Object.values(allFormTypes).reduce((acc, note) => {
    Object.entries(note.components || {}).forEach(([key, value]) => {
      acc[key] = value; // Ensures only unique keys are stored
    });
    return acc;
  }, {});

  const filteredFinalClinicalNote = useMemo(() => {
    return Object.fromEntries(
      Object.entries(jsonMessage).filter(([key]) => components?.[camelCase(key)])
    );
  }, [Object.keys(jsonMessage).length]);

  const keys = Object.keys(filteredFinalClinicalNote);

  const [visibleIndexes, setVisibleIndexes] = useState([]);

  useSequentialDisplay(keys, autoMapFormRef, setVisibleIndexes);

  useEffect(() => {
    Object.entries(jsonMessage).map(([key, value]) => {
      if (Object.keys(components).includes(key)) {
        autoMapFormRef.current[key] =
          `<div dangerouslySetInnerHTML={{ __html: ${replaceStrongWithDiv(convertMarkdownToHTML(value))}`;
      }
    });
  }, []);

  return (
    <div className="flex flex-col space-y-3">
      {iaRa(keys)
        .filter((key) => key?.toLowerCase() !== 'notes')
        .map((key, index) => (
          <div
            key={key}
            className={`transition-all duration-700 ease-out transform ${
              visibleIndexes.includes(index)
                ? 'opacity-100 blur-0 translate-y-0'
                : 'opacity-0 blur-md translate-y-3'
            }`}>
            <DynamicForm
              inputName={camelCase(key)}
              textRef={autoMapFormRef}
              title={key}
              showDisclaimer={false}
              components={components}
              disabled={disabled}
            />
          </div>
        ))}
      {keys.includes('notes') && <div dangerouslySetInnerHTML={{ __html: jsonMessage['notes'] }} />}
    </div>
  );
};

export default ResponseMapView;
