import React from 'react';

import cs from 'classnames';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Input from 'components/shared/Input/Input';
import Allowed from 'components/shared/Permissions/Allowed';

import Wrapper from '../Wrapper/Wrapper';

const PricingSection = ({ formik }) => {
  return (
    <Wrapper isColumn>
      <Allowed requiredPermissions="payments.read">
        <div className="grid grid-cols-2 gap-4">
          <CurrencyInput
            label="Self Pay"
            name="selfPay"
            parentClassName="flex w-full flex-col text-nowrap"
            id="selfPayInput"
            data-qa="selfPay-input"
            value={formik.values.total_cost_cents}
            onValueChange={(value) => formik.setFieldValue('total_cost_cents', value)}
            error={formik.errors.total_cost_cents}
            required
          />
          <div className="grid grid-cols-2 gap-4">
            <Input
              actionButtonText="%"
              placeholder="0"
              label="State Tax"
              id="productStateTax"
              className="w-full"
              error={formik?.errors.state_tax_rate}
              value={formik?.values?.state_tax_rate}
              onChange={(event) => formik?.setFieldValue('state_tax_rate', +event.target.value)}
            />
            <Input
              actionButtonText="%"
              label="Local Tax"
              id="productLocalTax"
              className="w-full"
              error={formik?.errors.local_tax_rate}
              value={formik?.values?.local_tax_rate}
              onChange={(event) => {
                formik?.setFieldValue('local_tax_rate', +event.target.value);
              }}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <CurrencyInput
            label="Standard"
            name="standardCharge"
            parentClassName="flex w-full flex-col text-nowrap"
            id="standardChargeInput"
            data-qa="standardCharge-input"
            value={formik.values.standard_charge}
            onValueChange={(value) => formik.setFieldValue('standard_charge', value)}
            error={formik.errors.standard_charge}
            required
          />
          <CurrencyInput
            label="Medicare Allowable"
            name="medicareAllowable"
            parentClassName={cs(
              'flex w-full flex-col text-nowrap',
              formik.values.procedure_code ? 'visible' : 'invisible'
            )}
            id="medicareAllowableInput"
            data-qa="medicareAllowable-input"
            value={formik.values.par_amount}
            onChange={formik.handleChange}
            disabled
          />
        </div>
      </Allowed>
    </Wrapper>
  );
};

export default PricingSection;
