import { requestApi } from './Api';

export const getTasks = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/read' });
};

export const createTask = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/create' });
};

export const updateTask = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/update' });
};

export const deleteTask = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/delete' });
};

export const getWidgetTasks = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/widget' });
};
export const getTask = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/get_one' });
};

export const updateMultipleTask = async (navigate, params) => {
  return await requestApi({ navigate, params, url: '/api/tasks/update_multiple' });
};
