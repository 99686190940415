import React, { useState, useCallback, useRef, useEffect, memo } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const VirtualizedList = ({ data, renderItem, scrollToIndex }) => {
  const listRef = useRef(null);
  const rowHeights = useRef(new Map());
  const [inputValues, setInputValues] = useState({});

  const setRowHeight = useCallback((index, size) => {
    if (rowHeights.current.get(index) !== size) {
      rowHeights.current.set(index, size);
      listRef.current?.resetAfterIndex(index, true);
    }
  }, []);

  const getItemSize = useCallback((index) => rowHeights.current.get(index) || 50, []);

  const handleInputChange = useCallback(
    (index, value) => {
      setInputValues((prev) => ({ ...prev, [index]: value }));
      const rowElement = listRef.current?._outerRef?.children[index];
      if (rowElement) {
        const newHeight = rowElement?.getBoundingClientRect().height;
        setRowHeight(index, newHeight);
      }
    },
    [setRowHeight]
  );

  useEffect(() => {
    if (scrollToIndex != null) {
      listRef.current?.scrollToItem(scrollToIndex, 'start');
    }
  }, [scrollToIndex]);

  const renderList = useCallback(
    ({ height, width }) => (
      <List
        ref={listRef}
        height={height}
        width={width}
        itemCount={data.length}
        itemSize={getItemSize}
        estimatedItemSize={100}>
        {({ index, style }) => (
          <div style={style}>
            <MeasuredRow index={index} setRowHeight={setRowHeight}>
              {renderItem(data[index], index, handleInputChange, inputValues)}
            </MeasuredRow>
          </div>
        )}
      </List>
    ),
    [data, getItemSize, inputValues, renderItem, handleInputChange, setRowHeight]
  );

  return (
    <AutoSizer disableWidth>{({ height }) => renderList({ height, width: '100%' })}</AutoSizer>
  );
};

const MeasuredRow = memo(({ index, setRowHeight, children }) => {
  const rowRef = useRef(null);

  useEffect(() => {
    if (rowRef.current) {
      const observer = new ResizeObserver(() => {
        const height = rowRef.current?.getBoundingClientRect().height;
        setRowHeight(index, height);
      });
      observer.observe(rowRef.current);

      return () => observer.disconnect();
    }
  }, [index, setRowHeight]);

  return <div ref={rowRef}>{children}</div>;
});

export default memo(VirtualizedList);
