/* eslint-disable eqeqeq */
import moment from 'moment';
import { formatDateAndTime } from '../../../../../lib/helpers/utility';
import repeatEventOptions from './repeatEventOptions';
import getWeekOfMonth from './getWeekOfMonth';

export default function humanizeEvent(event, timezone) {
  const repeatLabel = repeatEventOptions
    .find((option) => option.value == event?.repeat_event)
    .label.toLowerCase();
  let string = 'Occurs ';

  if (repeatLabel == 'daily') {
    if (event?.repeat_every == 1 && event?.weekdays.length == 0) {
      string += `every day`;
    }

    if (event?.repeat_every == 1 && event?.weekdays.length != 0 && event?.weekdays.length != 7) {
      const weekdays = event?.weekdays
        .map((day) => day.charAt(0).toUpperCase() + day.slice(1))
        .join(', ');
      string += 'on ' + weekdays;
    }

    if (event?.repeat_every == 1 && event?.weekdays.length === 7) {
      string += 'on every day of week';
    }

    if (event?.repeat_every != 1) {
      string += `every ${event?.repeat_every} days`;
    }
  }
  if (repeatLabel == 'weekly') {
    if (event?.weekdays.length == 7) {
      string += 'every day';
    }

    if (event?.weekdays.length !== 7) {
      const weekdays = event?.weekdays
        .map((day) => day.charAt(0).toUpperCase() + day.slice(1))
        .join(', ');
      string += 'every ' + weekdays;
    }

    if (event?.repeat_every > 1) {
      string += `, every ${event?.repeat_every} weeks`;
    }
    if (event?.repeat_event == 1) {
      string += `, every week`;
    }
  }

  if (repeatLabel == 'monthly') {
    moment.locale('en');
    if (event?.repeat_on == 'day') {
      string += `every month on the ${formatDateAndTime(event?.start_date, timezone, '', 'Do')} `;
    }

    if (event?.repeat_on === 'week') {
      string += `on the ${getWeekOfMonth(new Date(event?.start_date))} ${moment(
        event?.start_date
      ).format('dddd')}`;
    }
    if (!event?.repeat_on) {
      string += `every month on the ${formatDateAndTime(event?.start_date, timezone, '', 'Do')} `;
    }
  }

  if (repeatLabel == 'yearly') {
    if (event?.repeat_on == 'day') {
      string += `every month on the ${formatDateAndTime(
        event?.start_date,
        timezone,
        '',
        'Do'
      )}th of ${formatDateAndTime(event?.start_date, timezone, '', 'MMM D, YYYY')}`;
    }
    if (event?.repeat_on === 'week') {
      string += `on the ${getWeekOfMonth(new Date(event?.start_date))} ${moment(
        event?.start_date
      ).format('dddd')} ${'of ' + moment(event?.start_date).format('MMMM')}`;
    }
  }

  if (event?.ends_type == 'on_date') {
    string += ' until ' + formatDateAndTime(event?.end_date, timezone, '', 'MMM D, YYYY');
  }

  if (event?.ends_type == 'after') {
    string += `, for ${event?.events} ${event?.events == 1 ? 'event' : 'events'}`;
  }

  return string;
}
