import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import SimpleTable from '../../../../../../shared/Forms/Custom/ProviderMedications/components/SimpleTable';
import { stripHtmlTags } from 'lib/helpers/utility';

const cols = [
  {
    field: 'medicalCondition.value',
    headerName: 'Medical condition',
    valueFormatter: ({ data }) =>
      data?.medicalCondition?.value ? data.medicalCondition.value : 'N/A'
  },
  {
    headerName: 'Inheritance',
    cellRenderer: ({ data }) => {
      if (data.fatherSide) return 'Father side';
      if (data.motherSide) return 'Mother side';
      return 'N/A';
    }
  },
  {
    field: 'note',
    headerName: 'Note',
    cellRenderer: ({ value }) => (value ? stripHtmlTags(value) : 'N/A')
  }
];

const FamilyHistorySimpleTable = ({ data, emptyState = 'No family history' }) => {
  return (
    <TableContextProvider name="family_history_simple_table" defaultFilters={[]} cols={cols}>
      <SimpleTable data={data} emptyState={emptyState} />
    </TableContextProvider>
  );
};

export default FamilyHistorySimpleTable;
