import cs from 'classnames';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { ia } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { tagColorOptions } from '../lib/tagColorOptions';
import Tippy from '@tippyjs/react';
import _ from 'lodash';

const getUniqueTags = (tags, selectedTags) => {
  const filteredSelectedTags = selectedTags.filter((selectedTag) =>
    tags.some((tag) => tag.id === selectedTag.id)
  );
  const allTags = [...tags, ...filteredSelectedTags];
  const uniqueTags = allTags.reduce((acc, tag) => {
    if (!acc.some((existingTag) => existingTag.id === tag.id)) {
      acc.push(tag);
    }
    return acc;
  }, []);
  return _.orderBy(uniqueTags, [(tag) => tag.name.toLowerCase()], ['asc']);
};

const DropdownPanel = ({
  tags = [],
  selectedTags = [],
  actions = [],
  handleUpdateTags = () => {},
  panelRef,
  onScroll,
  scrollPositionRef,
  closePopover = () => {},
  tagsKind = null
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredTags, setFilteredTags] = useState(getUniqueTags(tags, selectedTags) || []);

  if (panelRef?.current) {
    panelRef.current.scrollTop = scrollPositionRef.current;
  }

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    const combinedTags = getUniqueTags(tags, selectedTags);
    const filtered = combinedTags.filter((tag) =>
      tag?.name?.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredTags(filtered);
  };

  const renderOptions = (tag, index) => {
    const isSelected = selectedTags?.some((selectedTag) => selectedTag?.id === tag?.id);
    const isNew = tagColorOptions.find((option) => option.color === tag?.color);
    let currBorderColor = tagColorOptions.find((option) => option.color === tag?.color)?.border;
    return (
      <div
        key={index}
        className={cs(
          'rounded-md px-2 py-[6px] hover:bg-primary-50',
          isSelected && 'bg-primary-25'
        )}
        onClick={() => {
          handleUpdateTags(tag);
        }}>
        <div className="flex justify-between rounded-md">
          <div
            className="flex h-[24px] cursor-default items-center gap-[6px] overflow-hidden rounded px-[6px] leading-5"
            style={{
              backgroundColor: tag?.color,
              border: `1px solid ${currBorderColor || 'transparent'}`
            }}>
            {tag?.important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
            <span
              className={cs(
                'select-none truncate text-sm font-400 leading-5',
                isNew ? 'text-neutral-900' : 'text-white'
              )}>
              {tag?.name}
            </span>
          </div>
          {isSelected && <Icon icon="new-check" color="neutral" stroke />}
        </div>
      </div>
    );
  };

  const handleActionClick = (action, event) => {
    if (action.onClick) {
      event.stopPropagation();
      action.onClick();
      closePopover();
    }
  };

  const renderActions = (action, index) => {
    if (action?.tippyText) {
      return (
        <Tippy content={action?.tippyText} className="tippy-dark" placement="bottom">
          <div
            key={index}
            className={cs(
              'rounded-md px-2 py-[6px]',
              action?.disabled
                ? 'cursor-not-allowed hover:bg-neutral-100'
                : 'cursor-pointer hover:bg-primary-50'
            )}
            onClick={!action?.disabled ? () => handleActionClick(action, event) : null}>
            <div className="flex items-center justify-start gap-3">
              <Icon
                className={cs(
                  'rounded border border-solid border-neutral-300 p-1',
                  action?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
                )}
                icon={action?.icon}
                size={28}
                color="primary"
                shade={900}
              />
              <span className="text-sm">{action?.label}</span>
            </div>
          </div>
        </Tippy>
      );
    }
    return (
      <div
        key={index}
        className={cs(
          'rounded-md px-2 py-[6px]',
          action?.disabled
            ? 'cursor-not-allowed hover:bg-neutral-100'
            : 'cursor-pointer hover:bg-primary-50'
        )}
        onClick={!action?.disabled ? () => handleActionClick(action, event) : null}>
        <div className="flex items-center justify-start gap-3">
          <Icon
            className={cs(
              'rounded border border-solid border-neutral-300 p-1',
              action?.disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            )}
            icon={action?.icon}
            size={28}
            color="primary"
            shade={900}
          />
          <span className="text-sm">{action?.label}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={cs('flex max-h-[400px] w-[360px] flex-col rounded-md bg-white')}>
      <Input
        placeholder="Search"
        value={searchTerm}
        onChange={(e) => handleSearch(e)}
        icon="new-search"
        inputWrapperClassName="border-none !outline-none"
      />
      <div
        className="flex flex-col gap-2 overflow-y-scroll border border-l-0 border-r-0 border-solid border-neutral-200 py-2"
        ref={panelRef}
        onScroll={onScroll}>
        {!ia(filteredTags) && !searchTerm ? (
          <>
            <NotFound
              icon="new-no-cpt-codes-illustration"
              title={`No ${tagsKind} tags created yet`}
              description={`Create new ${tagsKind} tags by clicking the action button below`}
              className="!p-0"
              iconSize={60}
              titleClassName="text-md"
            />
          </>
        ) : !ia(filteredTags) && searchTerm ? (
          <>
            <p className="pl-4 text-xs">No results found</p>
            <NotFound icon="new-no-cpt-codes-illustration" className="!px-10 !py-8" iconSize={80} />
          </>
        ) : (
          <>
            <p className="pl-4 text-xs">All Tags</p>
            <div className="flex flex-col px-2">
              {filteredTags.map((option, index) => renderOptions(option, index))}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col px-2 py-[6px]">
        <p className="pl-2 text-xs">Actions</p>
        <div className="grid grid-cols-2 gap-2">
          {actions?.map((action, index) => renderActions(action, index))}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(DropdownPanel);
