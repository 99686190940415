import React, { useEffect, useState } from 'react';

import cs from 'classnames';

import { useSignatures } from 'lib/hooks/queries/document/useSignatures';

import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';

import useEditorSelections from '../lib/useEditorSelections';

const EditorSelections = ({
  fabricCanvasRef,
  historyMode,
  isBold,
  setIsBold,
  isItalic,
  setIsItalic,
  fontFamily,
  setFontFamily,
  selectedAction,
  setSelectedAction,
  setSignature,
  objectSelected,
  setObjectSelected,
  isPdfLoaded
}) => {
  const signs = useSignatures({});
  const signatures = signs?.data?.signatures || [];

  const [drawProps, setDrawProps] = useState({
    color: '#000000',
    width: 1
  });

  const {
    fonts,
    changeFontFamily,
    deleteSelected,
    setDrawProp,
    toggleItalic,
    toggleBold,
    setColor
  } = useEditorSelections({
    fabricCanvasRef,
    setDrawProps,
    setObjectSelected,
    setIsBold,
    setIsItalic,
    setFontFamily
  });

  useEffect(() => {
    if (!fabricCanvasRef?.current || !isPdfLoaded) return;

    const onKeyDown = (event) => {
      if (event.key === 'Backspace' || event.keyCode === 8) {
        event.preventDefault();
        deleteSelected();
      }
      if (event.key === 'Escape' || event.keyCode === 27) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedAction(null);
        const fabricCanvas = fabricCanvasRef.current;

        if (
          fabricCanvas &&
          (fabricCanvas?.getActiveObject() ||
            (fabricCanvas?.getActiveObjects() && fabricCanvas?.getActiveObjects().length))
        ) {
          fabricCanvas?.discardActiveObject();
          fabricCanvas?.requestRenderAll();
        }
      }
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        event.stopPropagation();
        setSelectedAction(null);
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [isPdfLoaded]);

  if (
    (!objectSelected || historyMode) &&
    selectedAction !== 'signature_show' &&
    selectedAction !== 'draw'
  )
    return null;

  return (
    <>
      <div className="absolute top-0 z-10 flex w-full justify-between bg-primary-50">
        <div className="flex items-center gap-3 px-4">
          <p className="text-xs text-primary-900">Color:</p>
          {['#1B2123', '#ffffff', '#13B9FF', '#c23522', '#F59E0B', '#22C55E'].map((color) => (
            <div
              key={color}
              onClick={() => setColor(color)}
              className={cs('h-5 w-5 cursor-pointer rounded-full')}
              style={{ backgroundColor: color }}></div>
          ))}

          {objectSelected === 'i-text' && (
            <div className="ml-4 flex items-center gap-2">
              <p className="text-xs text-primary-900">Font:</p>
              <Select
                value={{
                  value: fontFamily,
                  label: fonts.find((f) => f.value === fontFamily)?.label
                }}
                onChange={({ value }) => changeFontFamily(value || 'Times New Roman')}
                options={fonts}
                isClearable={false}
                className="!w-36"
              />
              <div
                onClick={toggleBold}
                className={cs(
                  'flex aspect-square h-8 cursor-pointer items-center justify-center rounded ',
                  isBold
                    ? 'bg-primary-800 text-white hover:bg-primary-900'
                    : 'bg-primary-100 text-primary-900 hover:bg-primary-200'
                )}>
                <b className="text-sm ">B</b>
              </div>

              <div
                onClick={toggleItalic}
                className={cs(
                  'flex aspect-square h-8 cursor-pointer items-center justify-center rounded ',
                  isItalic
                    ? 'bg-primary-800 text-white hover:bg-primary-900'
                    : 'bg-primary-100 text-primary-900 hover:bg-primary-200'
                )}>
                <b className="text-sm ">I</b>
              </div>
            </div>
          )}
        </div>

        <div
          onClick={deleteSelected}
          disabled={!!objectSelected}
          className={cs(
            'flex !h-[52px] cursor-pointer items-center justify-center bg-red-100 px-5 hover:bg-red-200 active:bg-white'
          )}>
          <Icon className="cursor-pointer" icon="trash" shade={900} color="red" />
        </div>
      </div>

      {selectedAction == 'signature_show' && !historyMode && (
        <div className="absolute top-0 z-10 flex w-full justify-between bg-primary-50">
          <div className="flex max-h-[380px] w-full gap-3 overflow-y-auto p-4">
            {signatures.map((s, i) => (
              <div key={i}>
                <p className="mb-2 text-sm">{s?.p_name || s?.f_name + ' ' + s?.l_name}</p>
                <div
                  onClick={() => {
                    setSelectedAction('signature');
                    setSignature(s?.signature);
                  }}
                  className="h-24 rounded-lg !border border-dashed !border-neutral-100 bg-white px-6 py-2 hover:bg-primary-100">
                  <img className="h-full" src={s?.signature} alt="signature" />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedAction == 'draw' && !historyMode && (
        <div className="absolute py-4 top-0 z-10 flex w-full justify-between bg-primary-50">
          <div className="flex items-center gap-3 px-4">
            <p className="text-xs text-primary-900">Color:</p>
            {['#1B2123', '#ffffff', '#13B9FF', '#c23522', '#F59E0B', '#22C55E'].map((color) => (
              <div
                key={'draw' + color}
                onClick={() => setDrawProp('color', color)}
                className={cs(
                  'cursor-pointer rounded-full',
                  drawProps?.color == color
                    ? '!h-6 !w-6 !border-2 !border-solid border-primary-300'
                    : 'h-5 w-5'
                )}
                style={{ backgroundColor: color }}></div>
            ))}
            <div className="ml-5 flex gap-2">
              {[1, 2, 4, 8].map((width) => (
                <div
                  key={'width' + width}
                  onClick={() => setDrawProp('width', width)}
                  className={cs(
                    'h-7 w-7 cursor-pointer items-center justify-center flex rounded-full bg-white',
                    drawProps.width == width && 'border-2 border-solid border-primary-300'
                  )}>
                  <div style={{ backgroundColor: 'black', height: width, width: 10 }}></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EditorSelections;
