import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

import { ia, imagePath, mString } from 'lib/helpers/utility';

export const isFirstColumn = (params) => {
  const displayedColumns = params.api.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

export const defaultColDef = {
  minWidth: 100,
  headerCheckboxSelection: isFirstColumn,
  checkboxSelection: isFirstColumn
};

export const rowClassRules = {
  'ag-row-error': (params) => ia(params.data.error)
};

export const rowClassRulesGroupSelected = {
  'selected-group-row': (params) => params.data.isSelected
};

export const exportTableToCSV = (gridApi, categoryName) => {
  if (!gridApi) return;

  gridApi.exportDataAsCsv({
    fileName: `${categoryName || 'export'}.csv`,
    onlySelected: false,
    columnSeparator: ','
  });
};

const sumByColumn = (data) => {
  if (!data.length) return [];

  return data[0].map((_, colIndex) => {
    const allNonNumbers = data.every((row) => typeof row[colIndex] !== 'number');
    if (allNonNumbers) return '';

    return mString(
      data.reduce((sum, row) => {
        const value = typeof row[colIndex] === 'number' ? row[colIndex] : 0;
        return sum + value;
      }, 0)
    );
  });
};

export const printTable = ({ gridApi, categoryName, withTotals = true, practice }) => {
  if (!gridApi) return;

  const columns = gridApi
    .getColumnDefs()
    .filter((col) => col.field !== 'actions' && !col.hide)
    .map((col) => {
      return { field: col.field, headerName: col.headerName };
    });

  const rows = [];
  const totals = [];
  gridApi.forEachNode((node) => {
    rows.push(
      columns.map((col) => {
        const column = gridApi.getColumnDef(col.field);
        if (column?.valueFormatter) {
          return column.valueFormatter({
            value: col.field.includes('.')
              ? col.field.split('.').reduce((obj, key) => obj?.[key], node.data)
              : node.data[col.field],
            data: node.data
          });
        }
        if (column?.valueGetter) {
          return column.valueGetter({
            value: col.field.includes('.')
              ? col.field.split('.').reduce((obj, key) => obj?.[key], node.data)
              : node.data[col.field],
            data: node.data
          });
        }
        return col.field.includes('.')
          ? col.field.split('.').reduce((obj, key) => obj?.[key], node.data)
          : node.data[col.field];
      })
    );
    if (withTotals) {
      totals.push(
        columns.map((col) => {
          const column = gridApi.getColumnDef(col.field);
          const value = col.field.includes('.')
            ? col.field.split('.').reduce((obj, key) => obj?.[key], node.data)
            : node.data[col.field];

          if (!isNaN(Number(value)) && (column?.valueFormatter || column?.valueGetter)) {
            return value;
          }

          return '';
        })
      );
    }
  });

  const columnHeaders = columns.map((col) => col.headerName);

  const totalsSum = withTotals ? sumByColumn(totals) : null;

  const doc = new jsPDF();
  const pageHeight = doc.internal.pageSize.height;
  if (practice?.header_photo) {
    const headerPhotoData = JSON.parse(practice.header_photo);
    doc.addImage(imagePath(headerPhotoData.jpg), 'JPEG', 14, 10, 50, 30);
  } else {
    doc.text(`${categoryName || 'Printed'} Table Report`, 14, 10);
  }
  autoTable(doc, {
    head: [columnHeaders],
    body: rows,
    foot: withTotals ? [totalsSum] : undefined,
    startY: 20,
    headStyles: { fillColor: '#004F6B' },
    footStyles: { fillColor: '#004F6B' },
    showFoot: withTotals ? 'lastPage' : false,
    didDrawPage: () => {
      const printDate = new Date().toLocaleString();
      const pageWidth = doc.internal.pageSize.width;
      doc.setFontSize(8);

      const dateText = `Print date: ${printDate}`;
      const dateTextWidth = doc.getStringUnitWidth(dateText) * 3;
      doc.text(dateText, pageWidth - dateTextWidth - 10, pageHeight - 10);
    }
  });

  const totalPages = doc.internal.getNumberOfPages();

  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    const pageWidth = doc.internal.pageSize.width;
    doc.setFontSize(8);
    const pageText = `${i} of ${totalPages}`;
    const pageTextWidth = doc.getStringUnitWidth(pageText) * 3;
    doc.text(pageText, (pageWidth - pageTextWidth) / 2, pageHeight - 10);
  }

  const pdfBlob = doc.output('blob');
  const pdfURL = URL.createObjectURL(pdfBlob);
  const printWindow = window.open(pdfURL);
  if (printWindow) {
    printWindow.onload = () => printWindow.print();
  }
};
