import React from 'react';
import cs from 'classnames';
import { isEqual } from 'lodash';
import { CALENDAR_STATIC_RANGES } from '../lib/staticRanges';

const StaticRanges = ({ onRangeSelect, value }) => (
  <div className="static-ranges__list">
    {CALENDAR_STATIC_RANGES.map((staticRange, index) => {
      const isActive = value && isEqual(value, staticRange.getValue());
      return (
        <button
          key={index}
          className={cs(
            'static-ranges__list__item',
            isActive && 'static-ranges__list__item--active'
          )}
          onClick={() => onRangeSelect(staticRange.getValue())}>
          {staticRange.label}
        </button>
      );
    })}
  </div>
);

export default StaticRanges;
