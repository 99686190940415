import React, { useState } from 'react';
import FormsAI from '../FormsAI';
import PatientNeedsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/PatientNeedsSection';
import ProblemManifestationDefinitionSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/ProblemManifestationDefinitionSection';
import ICDCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import GoalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GoalsSection';
import VitalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import TimeFramesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/TimeFramesSection';
import RiskAndCoMorbiditiesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/RiskAndCoMorbiditiesSection';
import CPTCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import GeneralNoteSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import OrdersSection from 'components/practice/charts/ClinicalNote/Orders/OrdersSection';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import AdvancedHP from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AdvancedHP';

const getFormsList = (advancedHP, cnDisplaySettings) => {
  const formsList = {
    'patient-needs': (props) => <PatientNeedsSection {...props} withStyle={false} />,
    'problem-manifestation-definition': (props) => (
      <ProblemManifestationDefinitionSection {...props} withStyle={false} />
    ),
    'diagnosis-codes': (props) => (
      <ICDCodesSection {...props} withStyle={false} withScroll={false} />
    ),
    goals: (props) => <GoalsSection {...props} withStyle={false} />,
    vitals: (props) => <VitalsSection {...props} withStyle={false} />,
    'time-frames': (props) => <TimeFramesSection {...props} withStyle={false} />,
    'risk-and-co-morbidities': (props) => (
      <RiskAndCoMorbiditiesSection {...props} withStyle={false} />
    ),
    'cpt-codes': (props) => <CPTCodesSection {...props} withStyle={false} />,
    notes: (props) => <GeneralNoteSection {...props} withStyle={false} />,
    orders: (props) => <OrdersSection {...props} withStyle={false} />,
    ...assignAdvTitlesToComponent(
      advancedHP,
      (props) => <AdvancedHP {...props} withStyle={false} />,
      cnDisplaySettings
    )
  };

  return formsList;
};

const INTAKEFormsAI = ({ inputName, components }) => {
  const { advancedHP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedHP, cnDisplaySettings));

  return <FormsAI formsList={formsList} inputName={inputName} components={components} />;
};

export default INTAKEFormsAI;
