import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../../api/InterimApi';
import Axios from '../../../../configuredAxios';
import Button from '../../../shared/Buttons/Button';
import Input from '../../../shared/Input/Input';
import Loading from '../../../shared/Loading/Loading';
import { currentPractice } from '../../practiceState';
import { useNavigate } from 'react-router-dom';
import Select from 'components/shared/Select/Select';

const DisplaySettingsBillingValidationSchema = Yup.object().shape({
  medicarePercentage: Yup.number().typeError('Please enter a number, no special characters')
});

const setPOSValue = (default_pos, posOptions) => {
  const { code, name } =
    (posOptions && posOptions.filter((option) => option?.code === default_pos)[0]) || {};

  if (!default_pos) return { label: 'Select' };

  return {
    label: `${code} - ${name}`,
    value: code
  };
};

const BillingSettings = () => {
  const [loading, setLoading] = useState(false);
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [posOptions, setPosOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getPOS();
  }, []);

  const formik = useFormik({
    initialValues: {
      medicarePercentage: practice?.display_settings?.billing?.feeSchedule?.medicarePercentage,
      pos: setPOSValue(practice?.display_settings?.billing?.superbill?.default_pos, posOptions)
    },
    validationSchema: DisplaySettingsBillingValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      await updateBillingSettings();
      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (formik)
      formik.setFieldValue(
        'pos',
        setPOSValue(practice?.display_settings?.billing?.superbill?.default_pos, posOptions)
      );
    formik.setFieldValue(
      'medicarePercentage',
      practice?.display_settings?.billing?.feeSchedule?.medicarePercentage
    );
  }, [posOptions, practice?.display_settings?.billing]);

  const getPOS = async () => {
    setLoading(true);

    try {
      let { data } = await interimApi('/api/codes/place_of_service/get', {}, navigate);

      if (data) setPosOptions(data?.place_of_service);
    } catch (error) {
      console.error(error);
    }

    setLoading(false);
  };

  const updateBillingSettings = async () => {
    try {
      const display_settings = {
        ...practice?.display_settings,
        billing: {
          ...practice?.display_settings?.billing,
          superbill: {
            ...practice?.display_settings?.billing?.superbill,
            default_pos: formik.values.pos.value
          },
          feeSchedule: {
            ...practice?.display_settings?.billing?.feeSchedule,
            medicarePercentage: formik.values.medicarePercentage
          }
        }
      };

      const { data } = await interimApi(
        '/api/practice/update',
        { fields: { display_settings } },
        navigate
      );

      if (data?.updatedItems > 0) {
        toast.success('Billing settings updated successfully.');

        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='p-4'>
      {loading ? (
        <Loading />
      ) : (
        practice && (
          <div className='grid gap-4 w-1/2 sm:w-full'>
            <div className="flex flex-col gap-4">
              <p className='font-500 text-lg'>Superbill</p>
              <div>
                <Select
                  placeholder="Type and select"
                  value={formik?.values?.pos}
                  onChange={(e) =>
                    formik.setFieldValue('pos', { label: `${e.label}`, value: e.value })
                  }
                  options={posOptions?.map((item) => {
                    return { label: `${item.code} - ${item.name}`, value: item.code };
                  })}
                />
                <p className="text-xs tracking-wide text-neutral-500 pt-1">
                  Select the default place of service
                </p>
              </div>
            </div>
            <div className='flex flex-col gap-4'>
              <div>
                <p className='font-500 text-lg'>Schedule of fees</p>
                <Input
                  label="Please enter your desired multiple of the Medicare schedule of fees as your practice’s standard billed amount. (If blank this will default to 100%) "
                  value={formik?.values?.medicarePercentage}
                  tooltip="For example, if 125% is entered, your claims will reflect 125% of the Medicare schedule for that code "
                  rightText="%"
                  name="medicarePercentage"
                  onChange={(e) => formik.setFieldValue('medicarePercentage', e.target.value)}
                  error={formik.errors.medicarePercentage}
                />
              </div>
            </div>
            <Button text="Save" onClick={() => formik.submitForm()} />
          </div>
        )
      )}
    </div>
  );
};

export default BillingSettings;
