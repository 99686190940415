import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { descriptionCols } from 'components/admin/pages/Intelligence/Prompt/helpers';
import AdminIntelligencePromptDetails from 'components/admin/pages/Intelligence/Prompt/details';
import AdminIntelligencePromptDescriptionTable from 'components/admin/pages/Intelligence/Prompt/promptDescriptionsTable';
import AdminIntelligencePromptDescription from 'components/admin/pages/Intelligence/Prompt/promptDescription';
import Allowed from 'components/shared/Permissions/Allowed';

const AdminIntelligencePrompts = () => {
  return (
    <Allowed requiredPermissions="prompts.read" showMessage showIllustration>
      <TableContextProvider
        name="admin_intelligence_ai_prompt_template_table"
        defaultFilters={[]}
        cols={descriptionCols}>
        <AdminIntelligencePromptDescriptionTable />
      </TableContextProvider>
      <AdminIntelligencePromptDescription />
      <AdminIntelligencePromptDetails />
    </Allowed>
  );
};

export default withErrorBoundary(AdminIntelligencePrompts);
