import { useMemo } from 'react';

import { arrayToObject, iaRa } from 'lib/helpers/utility';
import { useCodes } from 'lib/hooks/queries/codes/useCodes';

import { handleAdjustmentCodes } from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA&EOBHelpers';

const AdjustmentCodes = ({ data }) => {
  const { data: codesData } = useCodes({
    params: { services: ['adjustment_reason', 'claim_adjustment_reason'] }
  });

  const codes = useMemo(() => {
    return arrayToObject(iaRa(codesData?.codes), 'code', 'description');
  }, [codesData?.codes]);

  return handleAdjustmentCodes(iaRa(data.adjustment), codes);
};

export default AdjustmentCodes;
