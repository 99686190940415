import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../shared/Buttons/Button';
import Modal from '../../../../shared/Modal/Modal';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import ProfilePicture from '../../../../Profile/ProfilePicture';
import Icon from '../../../../shared/Icon/Icon';
import Input from '../../../../shared/Input/Input';
import RadioButton from '../../../../shared/RadioButton/RadioButton';
import { ia, formatDate as utilityFormatDate } from 'lib/helpers/utility';
import { assignFaxesToPatient } from 'api/Fax';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../../shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { useTableContext } from 'lib/context/TableContext/TableContext';

const AssignPatientModal = ({
  assignPatientModalVisible,
  setAssignPatientModalVisible,
  faxes,
  faxQueryKey
}) => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    page: 1,
    limit: 25,
    search: ''
  });
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [allPatients, setAllPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const { data = {} } = usePatients({ params: { ...filters }, dependencies: [filters] });

  const divRef = useRef();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { setSelectedRows } = useTableContext();

  useEffect(() => {
    if (data) {
      if (filters.page === 1) {
        setAllPatients(data.patients);
      } else {
        setAllPatients((prev) => [...prev, ...data.patients]);
      }
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, searchTerm: filters.search, page: 1 }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.search]);

  const handleScroll = () => {
    const div = divRef.current;
    if (div) {
      if (div.scrollTop + div.clientHeight >= div.scrollHeight && ia(data.patients)) {
        setFilters((prev) => ({ ...prev, page: prev.page + 1 }));
      }
    }
  };

  const assignFaxes = async () => {
    const params = { patientId: selectedPatient.id, faxes };
    setLoading(true);
    const data = await assignFaxesToPatient(navigate, params);

    if (data?.code == 0) {
      setSelectedRows([]);
      await queryClient.invalidateQueries([faxQueryKey]);
      showAlert({ color: 'success', message: 'Faxes assigned successfully!' });
    } else {
      showAlert({ color: 'danger', message: 'Faxes failed to assign!' });
    }

    // data?.code == 0
    //   ? showAlert({ color: 'success', message: 'Faxes assigned successfully!' })
    //   : showAlert({ color: 'danger', message: 'Faxes failed to assign!' });

    data.code == 0 && setAssignPatientModalVisible(false);

    setLoading(false);
  };

  const formatDate = (date) => utilityFormatDate(date, undefined, false);

  return (
    <Modal
      handleOpen={!!assignPatientModalVisible}
      handleClose={() => setAssignPatientModalVisible(false)}
      title="Assign fax to a patient"
      slideFromRight
      customStyling={{ width: '540px' }}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => { }}
            data-qa="cancel-select-files-btn"
          />
          <Button
            disabled={!selectedPatient}
            onClick={assignFaxes}
            text="Assign to patient"
            id="createContactModalBtn"
            data-qa="select-files-btn"
            loading={loading}
          />
        </div>
      }>
      <div className="!mt-1 rounded-lg bg-white !p-2 shadow-sm">
        <Input
          id="searchPatient"
          type="string"
          placeholder="Search patient..."
          value={filters.search}
          rounded="full"
          icon="new-search"
          className="!mr-5 !rounded-2xl !border-secondary-400 "
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
          rightText={
            filters.search && (
              <Icon
                icon="new-close"
                onClick={() => setFilters((prev) => ({ ...prev, search: '' }))}
              />
            )
          }
        />
        <div
          className="!mt-4 flex max-h-[calc(100vh-290px)] flex-col !gap-4 overflow-y-auto"
          ref={divRef}
          onScroll={handleScroll}>
          {allPatients?.map((patient) => (
            <div key={patient?.id} className="flex cursor-pointer items-center gap-3 ">
              <div className="flex w-[25px] ml-[2px]">
                <RadioButton
                  value={true}
                  id={patient.id}
                  name="triedRest"
                  isSelected={selectedPatient?.id === patient.id}
                  onChange={() => {
                    setSelectedPatient(patient);
                  }}
                />
              </div>
              <ProfilePicture
                firstName={patient?.f_name}
                lastName={patient?.l_name}
                id={patient?.id}
                size={36}
              />
              <div className="flex flex-col">
                <h3 className="text-base font-500 text-neutral-900">{patient?.fullName}</h3>
                {patient?.dob && (
                  <div className="text-xs text-neutral-500">{formatDate(patient.dob)}</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default AssignPatientModal;
