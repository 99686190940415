import React from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { Capitalize, ia, iaRa } from 'lib/helpers/utility';
import { useNdcCodesOptions } from 'lib/hooks/queries/ndc/useNdcCodesOptions';

import { clinicalNote as clinicalNoteState } from 'components/state';

import Icon from '../../../../../../../../shared/Icon/Icon';
import Select from '../../../../../../../../shared/Select/Select';
import Box from '../../../../../shared/Box';

import { handleCPTSearch, handleModifierSearch } from './CPTCodes';

const CPTCodesRow = ({
  procedure_code,
  name,
  modifiers,
  uuid,
  onChange,
  onDelete,
  length,
  ndc_code,
  className
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const disabled = !Boolean(procedure_code);

  const procedure_name = name ? `${procedure_code} - ${name}` : procedure_code;
  const { data } = useNdcCodesOptions({
    options: { enabled: !!procedure_code },
    params: { procedure_code },
    dependencies: [procedure_code]
  });
  const ndcOptions = iaRa(data?.ndcOptions);

  return (
    <div className={cs('flex items-center gap-x-[10px]', className)}>
      <Box className="w-full">
        <Select
          label="CPT® code"
          placeholder="Type and select CPT®code"
          name="code"
          id="code"
          disabled={clinicalNote?.locked}
          isAsync
          isClearable
          value={{ value: procedure_code, label: procedure_name }}
          loadOptions={handleCPTSearch}
          menuPortalTarget={document.body}
          onChange={(event) =>
            onChange({
              event,
              key: 'code',
              uuid
            })
          }
        />
        {ia(ndcOptions) && (
          <div>
            <Select
              isClearable={false}
              label="NDC Code"
              placeholder="Select NDC code"
              name="ndc_code"
              value={{ value: ndc_code, label: ndc_code }}
              menuPortalTarget={document.body}
              options={
                ia(ndcOptions)
                  ? ndcOptions.map((item) => ({
                      value: item?.package_ndc,
                      label: `[ ${Capitalize(item?.generic_name)} ]\n ${item?.packaging_description}`
                    }))
                  : []
              }
              onChange={(event) => {
                onChange({
                  event,
                  key: 'ndc_code',
                  uuid
                });
              }}
            />
          </div>
        )}

        <div className={cs(disabled && 'cursor-not-allowed')}>
          <Select
            label={procedure_name ? `Modifiers - ${procedure_name}` : `Modifiers`}
            placeholder="Type and select CPT®code first"
            name="modifiers"
            id="modifiers"
            disabled={disabled || clinicalNote?.locked}
            isAsync
            isMulti
            isClearable
            value={modifiers}
            loadOptions={handleModifierSearch}
            menuPortalTarget={document.body}
            onChange={(event) =>
              onChange({
                event,
                key: 'modifiers',
                uuid
              })
            }
          />
        </div>
      </Box>
      {length > 1 && <Icon icon="trash" onClick={() => onDelete(uuid)} data-qa="delete-cpt-code" />}
    </div>
  );
};

export default CPTCodesRow;
