import { useQuery } from '@tanstack/react-query';
import { getAdminMostClickedSearches } from 'api/Admin/Search';
import { useNavigate } from 'react-router-dom';

export const useAdminMostClickedSearches = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  const query = useQuery(
    ['adminMostClickedSearches', ...dependencies],
    () => getAdminMostClickedSearches(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  return {
    ...query,
    refetch: query.refetch
  };
};
