import React from 'react';
import Header from 'components/shared/Header/Header';
import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { usePracticeIQueueOverview } from 'lib/hooks/queries/widgets/usePracticeIQueue';
import Board from 'components/shared/CustomizableBoard/Board';
import Tasks from './widgets/Tasks/Tasks';
import Notifications from './widgets/Notifications/Notifications';
import ClinicalNotes from './widgets/ClinicalNotes/ClinicalNotes';
import BeyondBilling from './widgets/BeyondBilling/BeyondBilling';
import HeaderButton from 'components/shared/CustomizableBoard/HeaderButton';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const widgets = {
  tasks: Tasks,
  notifications: Notifications,
  clinical_notes: ClinicalNotes,
  beyond_billing: BeyondBilling
};

const Overview = () => {
  return (
    <BoardContextProvider cols={{ lg: 4, md: 2, sm: 2 }} board="practice_i_queue">
      <OverviewBoard />
    </BoardContextProvider>
  );
};

function OverviewBoard() {
  const { data } = usePracticeIQueueOverview({});
  return (
    <div className="h-full overflow-y-auto overflow-x-hidden bg-neutral-50 !pb-8">
      <HeaderButton header={() => <Header title="Overview" className="!border-b-0" />} />
      <Board overview={data} widgets={widgets} />
    </div>
  );
}

export default withErrorBoundary(Overview);
