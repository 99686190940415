import React from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';

import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { useCustomFormResponse } from 'lib/hooks/queries/widgets/useOverview';

import Board from 'components/shared/CustomizableBoard/Board';
import StickyButton from 'components/shared/CustomizableBoard/StickyButton';

import filterCustomData from './helpers/filterCustomData';
import Allergies from './widgets/Allergies';
import Appointments from './widgets/Appointments';
import Charts from './widgets/Charts/Charts';
import Claims from './widgets/Claims';
import ClinicalNotes from './widgets/ClinicalNotes';
import FamilyHistory from './widgets/FamilyHistory';
import ICD10 from './widgets/ICD10';
import Ledger from './widgets/Ledger';
import MedicalHistory from './widgets/MedicalHistory';
import Medications from './widgets/Medications';
import Orders from './widgets/Orders';
import SocialHistory from './widgets/SocialHistory';
import SurgicalHistory from './widgets/SurgicalHistory';
import Vaccines from './widgets/Vaccines';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const widgets = {
  appointments: Appointments,
  allergies: Allergies,
  vaccines: Vaccines,
  orders: Orders,
  social_history: SocialHistory,
  medications: Medications,
  clinical_notes: ClinicalNotes,
  medical_history: MedicalHistory,
  family_history: FamilyHistory,
  surgical_history: SurgicalHistory,
  icd10: ICD10,
  ledger: Ledger,
  claims: Claims,
  charts: Charts
};

export default function Overview({ patient, showNewAppointmentModal }) {
  const { data } = useCustomFormResponse({
    params: { patientId: patient?.id },
    dependencies: [patient?.id],
    options: {
      select: (data) => filterCustomData(data),
      staleTime: 4 * (60 * 1000), // 4 mins
      cacheTime: 6 * (60 * 1000), // 6 mins
      keepPreviousData: true
    }
  });

  return (
    <BoardContextProvider board="patient_overview" cols={{ lg: 17, md: 4, sm: 2 }}>
      <Board
        widgets={widgets}
        showNewAppointmentModal={showNewAppointmentModal}
        customData={data}
        patientId={patient?.id}>
        <StickyButton />
      </Board>
    </BoardContextProvider>
  );
}
