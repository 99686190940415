import React, { useMemo } from 'react';
import NotFound from '../../components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber } from 'lib/helpers/utility';
import { isNumber } from 'lodash';
import useChartSize from 'lib/hooks/useChartSize';

const Superbills = (props) => {
  const data = props?.dashboardData?.count?.encounters?.superbills;
  const { item, layout } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];
  const { height, width, proportion } = useChartSize(container);

  const chartData = [
    { name: 'Draft', value: data?.draft || 0, fill: '#5090DC' },
    { name: 'Completed', value: data?.completed || 0, fill: '#FFA03A' }
  ];

  const options = {
    data: chartData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'name',
        angleKey: 'value',
        innerRadiusRatio: 0.7,
        calloutLabel: {
          enabled: false
        }
      }
    ],
    legend: {
      enabled: false
    }
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  if (!(data && parseInt(data.all) > 0))
    return (
      <NotFound
        icon="new-illustration-8"
        iconClassName="!pb-3"
        title="No Superbills found!"
        description="You can start creating new superbills on chart details."
      />
    );

  return (
    <div className="flex items-center gap-4 !p-6">
      <AGChart options={options} key={chartKey} />
      <div className="!grid w-full auto-rows-max grid-cols-[repeat(auto-fill,minmax(100px,max-content))] !gap-4">
        {options?.data?.map((item, index) => (
          <div className="flex h-max flex-col gap-1" key={index}>
            <div className="flex items-center gap-2">
              <div className="h-3 w-1 rounded-lg" style={{ backgroundColor: item.fill }}></div>
              <p className="font-medium text-neutral-500">{item.name}</p>
            </div>

            {isNumber(item.value) && (
              <p className="!ml-2 text-xl font-semibold text-primary-900">
                {formatLargeNumber(item.value)}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Superbills;
