import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import uniqBy from 'lodash/uniqBy';

import { ia } from 'lib/helpers/utility';

const NavigateToERACell = ({ data, node }) => {
  const ref = useRef();

  if (!!node?.group) return;

  const getERAsAndEOBs = (claims) => {
    const eras = claims?.map((claim) => claim?.insurance_payment?.era).flat() || [];
    const eobs = claims?.map((claim) => claim?.insurance_payment?.eob).flat() || [];
    return { eras, eobs };
  };

  const { eras, eobs } = data?.claims ? getERAsAndEOBs(data.claims) : getERAsAndEOBs([data]);

  const uniqueERAs = uniqBy(eras, (era) => Number(era?.era_id));
  const uniqueEOBs = uniqBy(eobs, (eob) => Number(eob?.eob_id));

  if (!ia(uniqueERAs) && !ia(uniqueEOBs)) return null;

  return (
    <div className="flex flex-row">
      <div ref={ref} className="text-primary-800">
        {ia(uniqueERAs) && <span>ERA: {uniqueERAs.length}</span>}
        {ia(uniqueERAs) && ia(uniqueEOBs) && <span> · </span>}
        {ia(uniqueEOBs) && <span>EOB: {uniqueEOBs.length}</span>}
      </div>

      <Tippy
        allowHTML
        interactive
        theme="light"
        reference={ref}
        placement="right"
        offset={[0, 10]}
        appendTo={() => document.body}
        className="custom-tooltip !rounded-md"
        content={
          <div className="flex-column flex flex-col p-2">
            {ia(uniqueERAs) && (
              <>
                <div className="m-1 font-500 text-primary-800">ERA</div>
                {uniqueERAs?.map((era) => (
                  <div key={era?.era_id} className="m-1 flex flex-row flex-wrap rounded-md">
                    <Link
                      target="_blank"
                      to={`/portal/beyond-billing/era-eob/era/${era?.era_id}`}
                      className="-ml-1 rounded-md p-1 hover:bg-primary-50">
                      {era?.era_id}
                    </Link>
                  </div>
                ))}
              </>
            )}

            {ia(uniqueEOBs) && (
              <>
                <div className="m-1 font-500 text-primary-800">EOB</div>
                {uniqueEOBs?.map((eob) => (
                  <div key={eob?.eob_id} className="m-1 flex flex-row flex-wrap rounded-md">
                    <Link
                      target="_blank"
                      to={`/portal/beyond-billing/era-eob/eob/${eob?.eob_id}`}
                      className="-ml-1 rounded-md p-1 hover:bg-primary-50">
                      {eob?.eob_id}
                    </Link>
                  </div>
                ))}
              </>
            )}
          </div>
        }
      />
    </div>
  );
};

export default NavigateToERACell;
