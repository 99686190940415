import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteDataBank } from 'api/DataBank';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { camelCaseToReadableFormat, snakeToTitleCase } from 'lib/helpers/utility';
import { useDataBanks } from 'lib/hooks/queries/databank/useDataBanks';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import Pagination from 'components/shared/Pagination/Pagination';
import Popup from 'components/shared/Popup/Popup';

import NewDatapoint from './NewDatapoint';
import { datapoints_object } from './constants';

export default function BaseDatapointPage() {
  const [newDatapointModal, setNewDatapointModal] = useState(false);
  const [selectedDataBank, setSelectedDataBank] = useState(null);
  const [deleteDataBankModal, setDeleteDataBankModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { type } = useParams();
  const dataPointRef = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      if (dataPointRef.current?.isLoading !== isLoading) {
        setIsLoading(dataPointRef.current?.isLoading);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isLoading]);

  const Actions = ({ data }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <div
                data-qa="edit-form-btn"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  close();
                  setSelectedDataBank(data);
                  setDeleteDataBankModal(true);
                }}>
                <Icon icon="trash" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Delete</div>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  };
  const cols = [
    { field: 'id', headerName: 'ID', maxWidth: 100 },
    { field: 'name', headerName: 'Name', width: 300 },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      cellRenderer: Actions,
      maxWidth: 100,
      minWidth: 36,
      resizable: false
    }
  ];

  const handleSubmit = () => {
    dataPointRef.current.validateForm();
  };

  return (
    <>
      <TableContextProvider name="data_banks" pagination cols={cols}>
        <Table
          type={datapoints_object[type].type}
          deleteDataBankModal={deleteDataBankModal}
          setDeleteDataBankModal={setDeleteDataBankModal}
          selectedDataBank={selectedDataBank}
          newDatapointModal={newDatapointModal}
          setNewDatapointModal={setNewDatapointModal}
        />
      </TableContextProvider>
      <Modal
        isOpen={newDatapointModal}
        handleClose={() => setNewDatapointModal(false)}
        title={`New ${datapoints_object[type].name}`}
        footer={
          <div className="flex w-full justify-between">
            <Button
              outlined
              text="Cancel"
              color="neutral"
              onClick={() => setNewDatapointModal(false)}
            />
            <Button
              text="Submit"
              loading={isLoading}
              onClick={handleSubmit}
              data-qa="datapoint-submit-btn"
            />
          </div>
        }>
        <NewDatapoint
          ref={dataPointRef}
          type={datapoints_object[type].type}
          handleClose={() => setNewDatapointModal(false)}
        />
      </Modal>
    </>
  );
}
function Table({
  selectedDataBank,
  setDeleteDataBankModal,
  deleteDataBankModal,
  type,
  setNewDatapointModal
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const {
    data = {},
    isLoading,
    isFetching
  } = useDataBanks({
    params: {
      loadedDatapoint: type,
      searchTerm: debouncedSearchTerm,
      page,
      sort,
      limit,
      withCount: true
    },
    dependencies: [debouncedSearchTerm, page, sort, limit, type],
    page,
    sort
  });
  const dataBanks = data?.datapoint || [];
  const mutateDeleteDataBank = useMutation({
    mutationFn: () =>
      deleteDataBank(navigate, {
        id: selectedDataBank.id,
        datapointType: type
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['databanks']);
      showAlert({
        title: `${type} deleted successfully`,
        color: 'success'
      });
    }
  });

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div className="m-3 ml-auto mr-3 flex items-center gap-2">
        <DisplayButton />
        <Input
          data-qa="search-data-bank"
          type="string"
          placeholder="Search databanks..."
          value={searchTerm}
          icon="new-search"
          inputWrapperClassName="h-[34px]"
          className="md:w-[200px]"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          rightText={
            searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearchTerm('');
                  setPage(1);
                }}
              />
            )
          }
        />
        <Button
          data-qa="new-data-bank-type-btn"
          type="primary"
          size="small"
          text={`New ${camelCaseToReadableFormat(type)}`}
          className="!w-max capitalize"
          onClick={() => setNewDatapointModal(true)}
        />
      </div>
      <div className="flex h-[94%] flex-col  overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable data={dataBanks} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
          {!!deleteDataBankModal && (
            <Confirm
              handleOpen={!!deleteDataBankModal}
              handleClose={() => setDeleteDataBankModal(false)}
              handleContinue={() => {
                mutateDeleteDataBank.mutate();
                setDeleteDataBankModal(false);
              }}
              title={`Delete ${type}`}
              icon="new-document-remove-red"
              message={`Are you sure you want to delete this ${type}?`}
              variant="danger"
            />
          )}
        </div>
      </div>
    </div>
  );
}
