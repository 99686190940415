import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { ia, removeUnderscores } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';

import { handleAccept, handleCancel } from './lib/helper';

const AdjustmentsFields = ({ change = {}, breadcrumb = {} }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <>
      {ia(change?.data) &&
        change?.data?.map((proc) => {
          const adjustments = proc.adjustments || [];

          const formattedAdjustments = adjustments
            ?.map((adjustment) =>
              Object.entries(adjustment)
                ?.filter(([key]) => key !== 'position')
                ?.map(([key, value]) => `${removeUnderscores(key).toUpperCase()}: ${value}`)
                .join(', ')
            )
            .join('; ');

          return (
            <div
              key={proc?.procedure_code}
              className="mb-2 rounded-md bg-white px-1 py-2 hover:bg-primary-50">
              <p className="font-600">Adjustment for procedure {proc.procedure_code}:</p>
              <div className="ml-4">
                {Object.entries(proc)
                  ?.filter(
                    ([key]) =>
                      key !== 'adjustments' && key !== 'position' && key !== 'procedure_code'
                  )
                  ?.map(([key, value]) => (
                    <p key={key}>
                      -{removeUnderscores(key).toUpperCase()}: {value}
                    </p>
                  ))}
              </div>
              {formattedAdjustments && <p className="ml-4">-ADJUSTMENTS: {formattedAdjustments}</p>}
              <div className="mt-4 flex justify-end space-x-2">
                <Button
                  text="Cancel"
                  type="secondary"
                  color="danger"
                  size="small"
                  outlined
                  onClick={() =>
                    handleCancel({
                      id: change?.claim_id,
                      changeId: change?.id,
                      key: change?.data?.procedure_code,
                      updatedField: proc,
                      kind: 'adjustment',
                      navigate,
                      queryClient,
                      breadcrumb
                    })
                  }
                />
                <Button
                  text="Accept"
                  type="secondary"
                  color="success"
                  size="small"
                  outlined
                  onClick={() =>
                    handleAccept({
                      id: change?.claim_id,
                      changeId: change?.id,
                      key: change?.data?.procedure_code,
                      updatedField: proc,
                      kind: 'adjustment',
                      navigate,
                      queryClient,
                      breadcrumb
                    })
                  }
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default AdjustmentsFields;
