export const groupComponentsByType = (components) => {
  return components.reduce((acc, component) => {
    const { label, title, forms, type } = component;
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push({ title, forms, type });
    return acc;
  }, {});
};

export function filterCheckedForms(data) {
  const result = {};

  for (const key in data) {
    const section = data[key];

    // Filter components with checked: true
    const filteredComponents = {};
    for (const componentKey in section.components) {
      if (section.components[componentKey].checked === true) {
        filteredComponents[componentKey] = { ...section.components[componentKey] };
      }
    }

    // Copy the section if there are any checked components
    if (Object.keys(filteredComponents).length > 0) {
      result[key] = {
        ...section,
        components: filteredComponents
      };
    }
  }

  return result;
}
