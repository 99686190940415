import React from 'react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

// TODO create a global shared one
const NotFound = ({
  title = 'Not found!',
  description = "You don't have any item.",
  buttonText = '',
  icon = 'new-search-services-illustration',
  textWrapperClassName = '',
  className = '',
  onClick = () => {}
}) => {
  return (
    <div className={cs('grid justify-items-center pb-5 pt-7', className)}>
      <Icon icon={icon} />
      <div className={cs('mb-2 mt-6 grid gap-1', textWrapperClassName)}>
        <span className="text-center text-base font-500 leading-5 text-primary-900">{title}</span>
        <span className="text-center text-sm font-400 leading-6 text-neutral-600">
          {description}
        </span>
      </div>
      {buttonText && (
        <Button
          icon="new-add-square"
          iconSize={18}
          transparent
          text={buttonText}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export default NotFound;
