import React from 'react';

import { discountTypes } from 'constants.js';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { permissions } from 'components/state';

const Discount = () => {
  const p = useRecoilValue(permissions);

  const { values, setFieldValue } = useFormikContext();

  const { tax, total_amount, amount_paid } = values?.invoice || {};
  const discount = values?.discount || values?.invoice.discount || {};

  const isPercent = discount?.type === 'percent';
  const currentBalance = total_amount - (amount_paid ?? 0) + (tax ?? 0);
  const calcPercentage = Math.round((discount?.amount_cents / total_amount) * 10000);

  const handleDiscountChange = ({ newBalance, discountType }) => {
    const discountValue = +(newBalance ?? discount?.value ?? discount?.amount_cents ?? 0);

    const newDiscountType = discountType || discount?.type || 'amount';
    const discountObj = { amount_cents: discountValue, type: newDiscountType };

    if (newDiscountType === 'percent') {
      discountObj.value = newBalance;
      discountObj.amount_cents = Math.round((currentBalance * newBalance) / 100 / 100);

      if (!newBalance) {
        discountObj.amount_cents = Math.round(discountValue);
        discountObj.value = ((discountValue * 100) / currentBalance) * 100;
      }
    } else if (newDiscountType === 'amount') {
      discountObj.value = newBalance;

      if (!newBalance) {
        const amountCents = (currentBalance * discountValue) / 100 / 100;
        discountObj.value = amountCents;
        discountObj.amount_cents = Math.round(amountCents);
      }
    }

    setFieldValue('discount', discountObj);
  };

  const handleUnitChange = (discountType) => {
    if (discountType === discount?.type) return;
    handleDiscountChange({ discountType });
  };

  const handleValueChange = (newBalance) => {
    handleDiscountChange({ newBalance });
  };

  const discountValue =
    !discount?.value && isPercent ? calcPercentage : (discount?.value ?? discount?.amount_cents);

  return (
    <CurrencyInput
      variant="secondary"
      units={discountTypes}
      value={discountValue}
      textRight
      maxValue={isPercent ? 10000 : currentBalance}
      disabled={!p?.billing?.discount || values?.invoice?.balance + tax <= 0}
      onUnitChange={handleUnitChange}
      currentUnit={discountTypes.find((t) => t?.value === (discount?.type || 'amount'))}
      onValueChange={handleValueChange}
      wrapperClassName="w-[130px] h-[26px]"
    />
  );
};

export default Discount;
