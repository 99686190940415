import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Plan from './Plan';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const PlanSection = ({ components, options, sectionRef, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.plan?.title}
      id={components?.plan?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Plan sectionRef={sectionRef} />
    </Accordion>
  );
};

export default PlanSection;
