import React from 'react';
import HIPAA from './HIPAA';
import PersonalInfo from './PersonalInfo';
import PersonalId from './PersonalId';
import Forms from './Forms';
import Insurance from './Insurance';
import stepName from './stepName';
import CheckinStepsPreAuth from './PreAuth';
import Payment from './Payment';

const CheckinStepsStep = (
  props = {
    step: {},
    index: 0,
    appointment: {},
    setShowHipaaModal: () => {},
    setSelectedForm: () => {},
    setShowFormPacketModal: () => {},
    setShowPreAuthModal: () => {},
    kiosks: []
  }
) => {
  switch (props?.step?.kind) {
    case 'hipaa':
      return <HIPAA {...props} />;
    case 'personal-info':
      return <PersonalInfo {...props} />;
    case 'personal-id':
      return <PersonalId {...props} />;
    case 'form':
      return <Forms {...props} />;
    case 'insurance':
      return (
        <Insurance
          {...props}
          insuranceProfiles={props.insuranceProfiles}
          setAppointment={props.setAppointment}
        />
      );
    case 'pre-auth':
      return <CheckinStepsPreAuth {...props} />;
    case 'payment':
      return <Payment {...props} />;
    default:
      return <div>Unsupported step kind {stepName(props?.step)}</div>;
  }
};

export default CheckinStepsStep;
