import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const ClearIcon = ({ isClearable, onResetDate, color }) => {
  if (!isClearable) return null;

  return <Icon icon="new-close" size={10} color={color} shade={800} stroke onClick={onResetDate} />;
};

export default ClearIcon;
