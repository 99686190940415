import React from 'react';
import cs from 'classnames';
import Icon from '../../shared/Icon/Icon';

const KioskBox = ({ kiosk, selectKiosk, selectedKioskId }) => {
  return (
    <div
      data-qa={`terminal-${kiosk?.id}`}
      // style={{ backgroundColor: kiosk?.color + '25' }}
      className={cs(
        'flex cursor-pointer grid-cols-2 items-center !rounded-lg border border-solid border-neutral-300 bg-white !p-4',
        kiosk?.id === selectedKioskId && '!border-primary-500 !bg-primary-50'
      )}
      onClick={() => selectKiosk(kiosk?.id)}>
      <Icon
        customColor={kiosk?.color}
        icon={'kiosk'}
        className="!mr-8 cursor-pointer rounded-[4px] border border-solid border-neutral-100 !p-2"
      />
      <label className="cursor-pointer p-0 text-sm text-primary-900">{kiosk?.name}</label>
    </div>
  );
};

export default KioskBox;
