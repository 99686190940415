import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { formatDateAndTimeZ } from 'lib/helpers/utility';

import useUpdateTP from 'components/practice/charts/ClinicalNote/TreatmentPlan/hooks/useUpdateTP';
import TPEdit from 'components/practice/charts/ClinicalNote/TreatmentPlan/views/TPEdit';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';

const EditView = ({ breadcrumb }) => {
  const { setStep, setSteps, step, steps } = breadcrumb;
  const navigate = useNavigate();
  const practice = useRecoilValue(currentPractice);
  const { selectedTreatmentPlan } = useClinicalNoteContext();
  const { mutate: updateTP } = useUpdateTP({ navigate, selectedTreatmentPlan, outsideCN: false });

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  const onEditTP = () => {
    updateTP(selectedTreatmentPlan);
    setSteps((prev) => [
      ...prev.slice(0, steps.length - 1),
      {
        title: formatDateAndTimeZ(
          selectedTreatmentPlan.date,
          practice.practiceTimezone
        ).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          timeZoneName: 'short'
        }),
        name: 'preview'
      }
    ]);
  };

  return (
    <div className="z-50">
      <div className="mb-[112px] gap-[24px] px-[230px]">
        <TPEdit view="edit" />
      </div>
      <div className="flex justify-center px-[230px]">
        <ModalFooter
          rightButtons={
            <Button
              text="Save"
              icon="save-changes"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onEditTP}
            />
          }
          leftButtons={
            <Button
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onGoBack}
            />
          }
        />
      </div>
    </div>
  );
};

export default EditView;
