import { ia } from 'lib/helpers/utility';

export const claimWithAdjustments = ({ procedures = [], claimAdjustments = {}, payerId = '' }) => {
  if (!ia(procedures)) return claimAdjustments;

  const procedureOrderMap = new Map(
    procedures?.map((proc, index) => [proc?.procedure_code, index])
  );

  const existingAdjustments = ia(claimAdjustments.adjustments)
    ? [...claimAdjustments.adjustments]
    : [];

  const adjustments = procedures?.map((proc) => {
    const idx = existingAdjustments?.findIndex((adj) => adj.procedure_code === proc.procedure_code);

    if (idx !== -1) {
      return existingAdjustments?.splice(idx, 1)[0];
    } else {
      return {
        procedure_code: proc.procedure_code,
        paid: '0.00',
        payer_id: payerId,
        paid_date: '',
        adjustments: []
      };
    }
  });

  const sortedAdjustments = [...adjustments].sort((a, b) => {
    return (
      (procedureOrderMap.get(a?.procedure_code) ?? Infinity) -
      (procedureOrderMap.get(b?.procedure_code) ?? Infinity)
    );
  });

  return {
    ...claimAdjustments,
    adjustments: sortedAdjustments
  };
};
