import React, { useRef } from 'react';
import Search from '../Search/AdminSearch/Search';
import Notifications from '../navbars/components/Notifications';

const NavbarAdmin = () => {
  return (
    <nav className="absolute z-30 flex h-[54px] w-full items-center justify-end bg-white !py-3 px-6 shadow-small">
      <Search className="rounded-full border-neutral-200 bg-neutral-50" />
      <Notifications />
    </nav>
  );
};

export default NavbarAdmin;
