import React from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';

import { refreshERA } from 'api/ERA';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';

const RefreshERA = ({ data }) => {
  const navigate = useNavigate();

  const { mutate: refreshERAs, isLoading } = useCustomMutation({
    mutationFn: () => refreshERA(navigate, { practice_id: data.id }),
    successMessage: "ERA's refreshed successfully!",
    errorMessage: "Failed to refresh ERA's!"
  });

  if (!data?.display_settings?.claims) return null;

  return (
    <div className="flex items-center h-full">
      <Button
        outlined
        shade="700"
        text="Refresh"
        color="neutral"
        icon="new-update"
        disabled={isLoading}
        onClick={refreshERAs}
        loadingIcon={isLoading}
        textClassName="text-sm leading-[114%] text-neutral-700"
        className={cs(
          'h-[32px] px-3 py-0 border-neutral-200 z-50',
          'hover:bg-neutral-50 hover:border-neutral-300 cursor-pointer'
        )}
      />
    </div>
  );
};

export default RefreshERA;
