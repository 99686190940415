import React, { useState, useRef, useLayoutEffect, useCallback } from 'react';
import cs from 'classnames';
import { groupBy } from 'lodash';
import { createPortal } from 'react-dom';
import { Popover as HeadlessPopover } from '@headlessui/react';
import { ia, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';

const Popover = ({
  position = 'right', // by default is 'right', means in the right position where the button is
  isFixed = false,
  options = [],
  icon = 'new-context-menu-dots',
  iconSize = 16,
  iconClassName = '',
  iconRight = '',
  iconRightSize = 16,
  iconsShade = 500,
  iconRightClassName = '',
  optionIconSize = 16,
  optionIconColor = 'primary',
  optionsGroupBy,
  optionActiveIcon = 'new-check', // if options is nested the icon will not be shown
  text = '',
  disabled,
  className = '',
  buttonClassName = '',
  buttonTheme = null,
  textClassName = '',
  panelClassName = '',
  activeClassNames = {},
  isDropdown = false,
  isSeparator = false,
  isBorder = false,
  rerender = ''
}) => {
  const buttonRef = useRef(null);
  const panelRef = useRef(null);
  const panElement = useRef(document.createElement('popover'));
  const [panelFixedPosition, setPanelFixedPosition] = useState({});
  const [hoveredIndexes, setHoveredIndexes] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [panelWidth, setPanelWidth] = useState(140); // default width, this is used only to calculate the position of panel

  const updatePopoverPosition = useCallback(() => {
    if (isFixed && buttonRef.current && panelRef.current) {
      const buttonRect = buttonRef?.current?.getBoundingClientRect();
      const panelRect = panelRef?.current?.getBoundingClientRect();

      let left, right, top;

      if (position === 'right') {
        left =
          buttonRect.left + panelWidth > window.innerWidth
            ? `${window.innerWidth - panelWidth}px`
            : `${buttonRect.left}px`;
        right = 'unset';
      } else {
        right =
          window.innerWidth - buttonRect.right + panelWidth > window.innerWidth
            ? `${window.innerWidth - panelWidth}px`
            : `${window.innerWidth - buttonRect.right}px`;
        left = 'unset';
      }

      const overflowBottom = buttonRect.bottom + panelRect.height;
      if (overflowBottom > window.innerHeight) {
        top = `${buttonRect.top - panelRect.height - 10}px`; // Move popover to the top
      } else {
        top = `${buttonRect.bottom}px`; // Default position
      }

      setPanelFixedPosition({
        position: 'fixed',
        top,
        left: left !== 'unset' ? left : 'unset',
        right: right !== 'unset' ? right : 'unset'
      });
    }
  }, [isFixed, position, panelWidth, rerender]); // these dependencies are added only for developers in cases where they change props to update position immediately

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    let panContainer = document.getElementById('popovers');

    if (!panContainer && root) {
      panContainer = document.createElement('div');
      panContainer.id = 'popovers';
      root.appendChild(panContainer);
    }

    if (isFixed && panContainer) {
      panContainer.appendChild(panElement.current);

      const handleResizeOrScroll = (event) => {
        // Check if the scroll event is from within the popover
        if (
          panelRef?.current &&
          event?.target instanceof Node &&
          panelRef?.current.contains(event.target)
        )
          return;

        updatePopoverPosition();
      };

      window.addEventListener('resize', handleResizeOrScroll);
      window.addEventListener('scroll', handleResizeOrScroll, true);

      return () => {
        window.removeEventListener('resize', handleResizeOrScroll);
        window.removeEventListener('scroll', handleResizeOrScroll, true);

        if (panElement.current.parentNode === panContainer) {
          panContainer.removeChild(panElement.current);
        }
      };
    }
  }, [isFixed, updatePopoverPosition]);

  useLayoutEffect(() => {
    if (panelRef.current) {
      const width = panelRef.current.getBoundingClientRect().width;
      setPanelWidth(width);
      updatePopoverPosition(); // Ensure position is recalculated when the popover opens
    }
  }, [updatePopoverPosition, isOpen]);

  const handleMouseEnter = (level, index) => {
    setHoveredIndexes((prev) => {
      if (prev[level] !== index) {
        return { ...prev, [level]: index };
      }
      return prev;
    });
  };

  const handleMouseLeave = (level) => {
    setHoveredIndexes((prev) => ({
      ...prev,
      [level]: null
    }));
  };

  const handleOnClick = (option, handleClose, event) => {
    if (option.onClick) {
      event.stopPropagation();
      option.onClick();
      handleClose();
    }
  };

  const handlePopoverClick = () => {
    setIsOpen(!isOpen);
  };

  const renderOptions = (options, level = 1, handleClose) => {
    if (optionsGroupBy) {
      const groupedOptions = groupBy(options, (option) => option[optionsGroupBy] || 'others');

      return Object.entries(groupedOptions).map(([groupName, groupOptions], groupIndex) => (
        <div className="flex flex-col" key={groupIndex}>
          <span className="px-3 pb-[2px] pt-1 text-xs font-500 text-primary-500">
            {snakeToTitleCase(groupName)}
          </span>
          {groupOptions.map((option, index) => renderOption(option, index, level, handleClose))}
        </div>
      ));
    } else {
      return options.map((option, index) => renderOption(option, index, level, handleClose));
    }
  };

  const renderOption = (option, index, level, handleClose) => {
    const label = option.label || option.title; //title is used for the old popover options
    return (
      <div
        key={index}
        className={cs(
          'relative flex items-center justify-between gap-x-2 py-[6px] !pl-3 hover:bg-primary-50',
          option.onClick && !ia(option.children) ? 'cursor-pointer !pr-3' : 'cursor-default pr-2',
          label === text && 'bg-primary-50'
        )}
        data-qa={`popover-option-${spaceToKebabCase(label)}`}
        onMouseEnter={() => option.children && handleMouseEnter(level, index)}
        onMouseLeave={() => option.children && handleMouseLeave(level)}
        onClick={(event) => handleOnClick(option, handleClose, event)}>
        <div className="flex gap-x-2">
          {option.loading ? (
            <Loader
              outlined
              className={`text-${optionIconColor}-500`}
              type={hoveredIndexes[level] === index ? 'secondary' : 'primary'}
            />
          ) : (
            option.icon && (
              <Icon
                icon={option.icon}
                size={optionIconSize}
                color={option.color || optionIconColor}
                shade={iconsShade}
                stroke={option.stroke}
              />
            )
          )}
          <span className="select-none whitespace-nowrap text-sm font-400 text-primary-900">
            {label}
          </span>
        </div>
        {typeof option.component === 'object' ? (
          option.component
        ) : option.children ? (
          <Icon
            icon="new-chevron-right"
            size={optionIconSize}
            className="ml-auto"
            color={optionIconColor}
            shade={iconsShade}
            stroke
          />
        ) : (
          optionActiveIcon &&
          label === text && (
            <Icon
              icon={optionActiveIcon}
              size={optionIconSize}
              className="ml-auto"
              color={optionIconColor}
              shade={iconsShade}
              stroke
            />
          )
        )}
        {option.children && hoveredIndexes[level] === index && (
          <div
            className={cs(
              'absolute -top-[14px] z-50 mt-[6px] grid min-w-[120px] gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium',
              option.position === 'left'
                ? 'right-full'
                : option.position === 'right'
                  ? 'left-full'
                  : position === 'left'
                    ? 'right-full'
                    : 'left-full'
            )}>
            {renderOptions(option.children, level + 1, handleClose)}
          </div>
        )}
      </div>
    );
  };

  const Panel = ({ handleClose }) => (
    <HeadlessPopover.Panel
      ref={panelRef}
      style={isFixed ? panelFixedPosition : {}}
      className={cs(
        'z-50 mt-[6px] grid min-w-[120px] gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium',
        panelClassName,
        isFixed ? '' : `absolute ${position === 'left' ? 'right-0' : 'left-0'}`
      )}>
      {renderOptions(options, 1, handleClose)}
    </HeadlessPopover.Panel>
  );

  return (
    <HeadlessPopover
      className={cs('relative w-fit', className)}
      open={isOpen}
      onClose={() => setIsOpen(false)}>
      {({ open, close }) => (
        <>
          <HeadlessPopover.Button
            ref={buttonRef}
            onClick={handlePopoverClick}
            data-qa="popover-button"
            className={cs(
              'flex min-h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-md p-0 font-500',
              (open && !buttonTheme) || (open && buttonTheme === 'white')
                ? 'bg-primary-700 text-white'
                : `bg-${buttonTheme}-200 text-${buttonTheme}-600`,
              !open && !buttonTheme && 'bg-transparent text-neutral-800 hover:!bg-neutral-50',
              !open && buttonTheme !== 'white' && `text-${buttonTheme}-500 bg-${buttonTheme}-50`,
              !open && buttonTheme === 'white' && 'bg-white',
              text && !isSeparator && 'pl-2 pr-4',
              (iconRight || isDropdown) && 'pl-4',
              isSeparator && 'grid grid-flow-col pl-[14px] pr-[8px]',
              isBorder && `border border-solid border-${buttonTheme}-500`,
              buttonClassName,
              activeClassNames && open && activeClassNames?.button
            )}
            disabled={disabled}>
            {icon && (
              <Icon
                icon={icon}
                size={iconSize}
                color={
                  disabled
                    ? 'neutral'
                    : open && !buttonTheme
                      ? 'white'
                      : (buttonTheme ?? (open ? 'white' : ''))
                }
                shade={iconsShade}
                className={cs(
                  iconClassName,
                  icon !== 'new-context-menu-dots' && text && 'mr-[6px]',
                  disabled && '!cursor-not-allowed'
                )}
              />
            )}
            {text && (
              <span
                className={cs(
                  'text-sm first-letter:capitalize',
                  open && !buttonTheme && 'text-white',
                  !open && buttonTheme === 'white' && 'text-primary-600',
                  textClassName,
                  activeClassNames && open && activeClassNames?.text
                )}
                title={text}>
                {text}
              </span>
            )}
            {iconRight && !isDropdown && (
              <Icon
                icon={iconRight}
                size={iconRightSize}
                color={
                  buttonTheme ??
                  (activeClassNames?.icon && open ? activeClassNames?.icon : open ? 'white' : '')
                }
                shade={iconsShade}
                className={iconRightClassName}
              />
            )}
            {isSeparator && (
              <hr
                className={`block h-full w-[1px] border-none bg-${buttonTheme}-500 ml-[14px] mr-[2px]`}
              />
            )}
            {isDropdown && (
              <Icon
                icon="new-chevron-down"
                size={iconRightSize}
                color={
                  activeClassNames?.icon && open
                    ? activeClassNames?.icon
                    : !open && buttonTheme === 'white'
                      ? 'primary'
                      : (buttonTheme ?? (open ? 'white' : ''))
                }
                shade={iconsShade}
                className={cs('ml-1 duration-150', open && 'rotate-180')}
              />
            )}
          </HeadlessPopover.Button>
          {open &&
            (isFixed ? (
              createPortal(<Panel handleClose={close} />, panElement.current)
            ) : (
              <Panel handleClose={close} />
            ))}
        </>
      )}
    </HeadlessPopover>
  );
};

export default Popover;
