import Input from 'components/shared/Input/Input';

const EditTerminalFormikInput = ({ formik, target, label }) => {
  return (
    <div className="flex w-full flex-col items-start justify-center gap-2">
      <label className="text-sm font-medium leading-snug text-neutral-800">{label}</label>
      <div className="flex h-10 w-full items-center gap-3 rounded-md border border-neutral-200 bg-white">
        <Input
          id={target}
          name={target}
          className="w-full text-sm font-normal leading-snug text-primary-900"
          labelClassName="p-0"
          value={formik.values[target]}
          onChange={(e) => {
            formik.setFieldValue(target, e.target.value);
          }}
        />
      </div>
      {formik.errors[target] ? (
        <span className="-mt-2.5 text-left text-sm text-danger-500">{formik.errors[target]}</span>
      ) : (
        <span className="-mt-2.5 h-5"></span>
      )}
    </div>
  );
};

export default EditTerminalFormikInput;
