import React from 'react';
import cs from 'classnames';
import Icon from '../../../../Icon/Icon';
import { tagColorOptions } from '../../../lib/tagColorOptions';

export const TagOption = ({ label, color, value, id, important, isNew, onRemove }) => {
  const tagColorOption = tagColorOptions.find((option) => option.color === color);
  const borderColor = tagColorOption?.border;

  return (
    <div
      className="relative flex w-full items-center justify-between gap-[2px]"
      data-qa={`tag-option-${label}`}>
      <span
        className={cs(
          'flex h-[24px] cursor-default gap-[6px] rounded leading-5',
          isNew ? '!px-1' : 'px-[10px]',
          tagColorOption ? 'text-neutral-900' : 'text-white'
        )}
        style={{
          backgroundColor: color,
          border: `1px solid ${borderColor || 'transparent'}`
        }}>
        {important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
        {label}
      </span>
      {!isNew && (
        <Icon
          icon="trash"
          onClick={(event) => onRemove(value, event, id)}
          data-qa={`tag-option-remove-${label}`}
        />
      )}
    </div>
  );
};
