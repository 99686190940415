import React, { useEffect } from 'react';

import cs from 'classnames';

import { ia } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';
import Popup from 'components/shared/Popup/Popup';

const EditorToolbar = ({ fabricCanvasRef, isPdfLoaded, selectedAction, setSelectedAction }) => {
  useEffect(() => {
    if (!fabricCanvasRef?.current || !isPdfLoaded || !selectedAction) return;

    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas.isDrawingMode = selectedAction === 'draw';
    fabricCanvas?.discardActiveObject();
    fabricCanvas?.renderAll();
  }, [selectedAction]);

  const toggleDrawingMode = () => {
    const fabricCanvas = fabricCanvasRef.current;
    setSelectedAction((action) => {
      fabricCanvas.isDrawingMode = selectedAction !== 'draw';
      return action === 'draw' ? null : 'draw';
    });
  };

  const Tool = ({ onClick, action, icon }) => {
    return (
      <div
        onClick={
          onClick
            ? onClick
            : () =>
                selectedAction === action ? setSelectedAction(null) : setSelectedAction(action)
        }
        disabled={!isPdfLoaded}
        className={cs(
          '!cursor-pointer relative border border-solid !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
          (ia(action) ? action.includes(selectedAction) : action === selectedAction) &&
            'bg-primary-100'
        )}>
        <Icon className="!cursor-pointer" icon={icon} size={16} shade={900} color="primary" />
      </div>
    );
  };

  return (
    <div className="flex">
      <Tool action="text" icon="new-text-box" />

      <Tool action="date" icon="new-calendarv2" />

      <Popup
        position={['bottom left']}
        on={['hover', 'focus']}
        trigger={
          <div
            onClick={() =>
              selectedAction === 'rectangle'
                ? setSelectedAction(null)
                : setSelectedAction('rectangle')
            }
            disabled={!isPdfLoaded}
            className={cs(
              '!cursor-pointer border border-solid !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
              selectedAction === 'rectangle' && 'bg-primary-100'
            )}>
            <Icon
              className="!cursor-pointer"
              stroke
              icon="new-rectangle-box"
              shade={900}
              color="primary"
            />
          </div>
        }>
        {(close) => (
          <div className="flex flex-col py-1">
            <div
              onClick={() => {
                setSelectedAction('rectangle');
                close();
              }}
              className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
              <Icon
                className="!cursor-pointer"
                stroke
                icon="new-rectangle-box"
                shade={900}
                color="primary"
              />
              <p className="text-primary-900">White Rectangle</p>
            </div>

            <div
              onClick={() => {
                setSelectedAction('circle');
                close();
              }}
              className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
              <Icon
                className="!cursor-pointer"
                stroke
                icon="new-circle"
                shade={900}
                color="primary"
              />
              <p className="text-primary-900">Circle</p>
            </div>

            <div
              onClick={() => {
                setSelectedAction('checkmark');
                close();
              }}
              className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
              <Icon
                className="!cursor-pointer"
                stroke
                icon="new-check"
                shade={900}
                color="primary"
              />
              <p className="text-primary-900">Checkmark</p>
            </div>
          </div>
        )}
      </Popup>

      <Tool
        icon="new-signature-box"
        action={['signature', 'signature_show']}
        onClick={() =>
          selectedAction === 'signature_show'
            ? setSelectedAction(null)
            : setSelectedAction('signature_show')
        }
      />

      <Tool onClick={toggleDrawingMode} action="draw" icon="new-edit" />
    </div>
  );
};

export default EditorToolbar;
