import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { tagColorOptions } from 'components/shared/Tags/lib/tagColorOptions';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { ia } from 'lib/helpers/utility';
import useHasScrollbar from 'lib/hooks/useHasScrollbar';
import useScroll from 'lib/hooks/useScroll';

const PatientTags = ({ handleRemove = () => {} }) => {
  const { importantTags, regularTags } = useTagContext();
  const [tabRef, hasScrollbar, canScrollLeft, canScrollRight] = useHasScrollbar({
    type: 'horizontal'
  });
  return (
    <div
      className={cs(
        'relative flex flex-1 items-center overflow-hidden',
        canScrollRight && 'pr-10',
        canScrollLeft && 'pl-8'
      )}>
      {canScrollLeft && (
        <Icon
          icon="chevron-left"
          className="absolute left-0 z-10 flex h-full w-12 min-w-[60px] items-center justify-center bg-gradient-to-l from-transparent via-neutral-50 to-neutral-50 !pr-4 duration-100 hover:-translate-x-1"
          onClick={useScroll({ ref: tabRef, direction: 'left', distance: 200 })}
        />
      )}
      <div className={cs('relative flex items-center gap-3 overflow-hidden')} ref={tabRef}>
        {importantTags?.map((tag, index) => {
          const isNew = tagColorOptions.find((option) => option.color === tag?.color);
          let currBorderColor = tagColorOptions.find(
            (option) => option.color === tag?.color
          )?.border;
          return (
            <div
              key={index}
              className="flex max-h-[25px] items-center gap-[6px] rounded px-2 py-[2px]"
              style={{
                backgroundColor: tag?.color,
                border: `1px solid ${currBorderColor || 'transparent'}`
              }}>
              {tag?.important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
              <span
                className={cs(
                  'select-none whitespace-nowrap text-sm font-400 leading-5',
                  isNew ? 'text-neutral-900' : 'text-white'
                )}>
                {tag?.name}
              </span>
              <Icon
                icon="new-close-sm"
                size={8}
                color="black"
                stroke
                onClick={() => handleRemove(tag)}
              />
            </div>
          );
        })}
        {ia(regularTags) &&
          regularTags?.map((tag, index) => {
            const isNew = tagColorOptions.find((option) => option.color === tag?.color);
            let currBorderColor = tagColorOptions.find(
              (option) => option.color === tag?.color
            )?.border;
            return (
              <div
                key={index}
                className="flex max-h-[25px] items-center gap-[6px] rounded px-2 py-[2px]"
                style={{
                  backgroundColor: tag?.color,
                  border: `1px solid ${currBorderColor || 'transparent'}`
                }}>
                <span
                  className={cs(
                    'select-none whitespace-nowrap text-sm font-400 leading-5',
                    isNew ? 'text-neutral-900' : 'text-white'
                  )}>
                  {tag?.name}
                </span>
                <Icon
                  icon="new-close-sm"
                  size={8}
                  color="black"
                  stroke
                  onClick={() => handleRemove(tag)}
                />
              </div>
            );
          })}
      </div>

      {canScrollRight && (
        <Icon
          icon="chevron-right"
          className="absolute right-0 z-10 flex h-full w-12 min-w-[60px] cursor-pointer items-center justify-center bg-gradient-to-r from-transparent via-white to-white !pl-4 duration-100 hover:translate-x-1"
          onClick={useScroll({ ref: tabRef, direction: 'right', distance: 200 })}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(PatientTags);
