import React from 'react';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import Button from '../../../shared/Buttons/Button';

const Footer = ({ hideModal, loading, submitForm, title }) => {
  const { device } = useUIContext();

  return (
    <div className="flex w-full justify-between">
      {device !== 'mobile' && (
        <Button outlined color="neutral" onClick={hideModal} text="Cancel" data-qa="cancel-btn" />
      )}

      <Button
        data-qa="create-appointment-event-btn"
        loadingIcon={loading}
        text={title}
        onClick={submitForm}
        disabled={loading}
      />
    </div>
  );
};

export default Footer;
