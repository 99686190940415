import { Honeybadger } from '@honeybadger-io/react';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import NewReferral from './new_referral';

const FormValidators = require('../../validation/validate_new_referral');
const validateNewReferral = FormValidators.validateNewReferral;

const NewReferralContainer = forwardRef(({ handleClose, showConfirmation }, ref) => {
  const [newReferral, setNewReferral] = useState({
    practice: '',
    body: ''
  });
  const [relatedPractices, setRelatedPractices] = useState([]);
  const [errors, setErrors] = useState({
    practice: {
      success: false,
      message: ''
    },
    body: {
      success: false,
      message: ''
    }
  });
  const navigate = useNavigate();

  useEffect(() => {
    getPractices();
  }, []);

  const getPractices = async () => {
    try {
      let res = await interimApi('/api/patient/practice/read', {}, navigate);
      const { practices: loadedPractices } = res.data;
      if (res.data.practices) {
        setRelatedPractices(loadedPractices);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleChange = (event) => {
    const field = event.name;
    const value = event.value;
    setNewReferral({
      ...newReferral,
      [field]: value
    });
  };

  const submitChanges = async () => {
    if (newReferral.body.trim().length > 0) {
      let params = {
        message: {
          practice_id: newReferral.practice,
          body: newReferral.body,
          receiver_id: null,
          kind: 'referral'
        }
      };
      try {
        let res = await interimApi('/api/messages/send', params, navigate);
        if (res.data.message_id) {
          handleClose();
          showConfirmation();
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
        }
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
    }
  };

  const validateForm = () => {
    let payload = validateNewReferral(newReferral);
    if (payload.success) {
      setErrors({
        practice: {
          success: false,
          message: ''
        },
        body: {
          success: false,
          message: ''
        }
      });
      submitChanges(newReferral);
    } else {
      setErrors({
        practice: {
          success: false,
          message: errors.practice
        },
        body: {
          success: false,
          message: errors.body
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    validateForm: () => validateForm()
  }));

  return (
    <div>
      <div className="flex-wrap -ml-3 -mr-3 -mt-3">
        <div className="block p-3">
          <NewReferral
            onSubmit={validateForm}
            onChange={handleChange}
            errors={errors}
            newReferral={newReferral}
            relatedPractices={relatedPractices}
          />
        </div>
      </div>
    </div>
  );
});
export default NewReferralContainer;
