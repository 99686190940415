import { showAlert } from 'components/shared/Alert/Alert';
import { tableNames } from 'constants';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa, optionify } from 'lib/helpers/utility';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { useUserMostClickedSearches } from 'lib/hooks/queries/user/useUserMostClickedSearches';
import useDebounce from 'lib/hooks/useDebounce';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SearchList from '../Codes/components/SearchList';
import SelectedList from '../Codes/components/SelectedList';
import Layout from '../Codes/Layout';
import ListICD10Codes from './components/ListICD10Codes';
import { scrollToSelectedCPT } from './lib/scrollToSelectedCPT';
import { trackUserClick } from './lib/userTrackSearchClick';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

const ICD10Code = ({ isTPlan = null }) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan, tab } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [trackSearchDataLoading, setTrackSearchDataLoading] = useState({});

  const selectedListRef = useRef([]);

  const debouncedSearch = useDebounce(searchTerm, 500);

  const { isFetching: isUserMostClickedSearchesFetching } = useUserMostClickedSearches({
    params: {
      searchType: tableNames.ICD10,
      showOnlyTopSearchesClicked: true
    },
    options: {
      refetchOnMount: true,
      onSettled: (data) => {
        const { top_searches_clicked = [] } = data ?? {};

        const formattedData = formatData(top_searches_clicked);

        setSearchData(iaRa(formattedData));
      }
    }
  });

  const { isFetching } = useICD10Codes({
    params: {
      searchTerm,
      limit: 25,
      withMostUserSearchClicks: true
    },
    dependencies: [debouncedSearch],
    options: {
      enabled: !!debouncedSearch,
      onSettled: (data) => {
        const { icd10 = [] } = data ?? {};

        const formattedData = formatData(icd10);

        const selectedListIds = iaRa(selectedList).map((item) => item?.value);
        const filterAlreadySelectedData = iaRa(formattedData).filter(
          (row) => !selectedListIds.includes(row?.value)
        );

        setSearchData(iaRa(filterAlreadySelectedData));
      }
    }
  });

  const { isFetching: isGetICD10CodesFetching } = useICD10Codes({
    params: {
      ids: isTPlan ? iaRa(selectedTreatmentPlan?.icd10) : iaRa(clinicalNote?.icd_10_codes),
      limit: 99
    },
    dependencies: [selectedTreatmentPlan, tab === 'epn' ? clinicalNote?.icd_10_codes : null],
    options: {
      refetchOnMount: true,
      onSettled: (data) => {
        const { icd10 = [] } = data ?? {};

        const formattedData = iaRa(formatData(icd10));

        const icd10CodeValues = isTPlan
          ? iaRa(selectedTreatmentPlan?.icd10)
          : iaRa(clinicalNote?.icd_10_codes);

        const orderedICDs = iaRa(icd10CodeValues)
          .map((code) => formattedData.find((item) => item?.value === code))
          .filter(Boolean);

        setSelectedList(orderedICDs);
      }
    }
  });

  const formatData = (data) => {
    return optionify(
      data,
      (item) => `${item.code} - ${item.name}`,
      'code',
      (e) => e?.search
    );
  };

  const selectICD10 = async (item) => {
    const { value } = item;

    const doesSelectedICD10Exist = selectedList.find(
      (selectedItem) => selectedItem.value === value
    );

    if (doesSelectedICD10Exist) {
      showAlert({
        color: 'warning',
        title: 'Diagnosis Code',
        message: `ICD-10 Code <strong>(${value})</strong> is already added`
      });

      return;
    }

    await trackUserClick({ setTrackSearchDataLoading, value, navigate });

    setSelectedList((prev) => {
      setSearchData((prev) => prev.filter((item) => item.value !== value));

      return [...iaRa(prev), item];
    });

    if (isTPlan) {
      setSelectedTreatmentPlan((prev) => {
        return {
          ...prev,
          icd10: [...new Set([...iaRa(prev?.icd10), value])]
        };
      });
    } else {
      setClinicalNote((prev) => {
        return {
          ...prev,
          icd_10_codes: [...new Set([...iaRa(prev?.icd_10_codes), value])]
        };
      });
    }

    scrollToSelectedCPT({ selectedListRef, icdValue: value });
  };

  const onClickDelete = (item) => {
    const { value } = item;

    setSelectedList((prev) => iaRa(prev).filter((item) => item?.value !== value));

    const doesDeletedICD10Exist = searchData.find((selectedItem) => selectedItem.value === value);

    if (!doesDeletedICD10Exist) {
      setSearchData((prev) => [item, ...prev]);
    }

    if (isTPlan) {
      setSelectedTreatmentPlan((prev) => {
        return {
          ...prev,
          icd10: iaRa(prev?.icd10).filter((item) => item !== value)
        };
      });

      return;
    }

    setClinicalNote((prev) => {
      return {
        ...prev,
        icd_10_codes: iaRa(prev?.icd_10_codes).filter((item) => item !== value)
      };
    });
  };

  return (
    <Layout
      leftChild={
        <SearchList
          title="All ICD-10 Codes"
          onSearch={(e) => setSearchTerm(e.target.value)}
          searchData={searchData}
          loading={isFetching || isUserMostClickedSearchesFetching}
          trackSearchDataLoading={trackSearchDataLoading}
          searchTerm={debouncedSearch}
          onClickAdd={selectICD10}
        />
      }
      rightChild={
        <SelectedList title="Added ICD-10 Codes">
          <ListICD10Codes
            data={selectedList}
            selectedListRef={selectedListRef}
            onClickDelete={onClickDelete}
            isFetching={isGetICD10CodesFetching}
          />
        </SelectedList>
      }
    />
  );
};

export default ICD10Code;
