import { useQuery } from '@tanstack/react-query';
import { getTreatmentPlan } from '../../../../api/TreatmentPlan';
import { useNavigate } from 'react-router-dom';

export const useTreatmentPlan = (params, options) => {
  const navigate = useNavigate();
  return useQuery(['treatmentPlan', [params]], () => getTreatmentPlan(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
