import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useRecoilState } from 'recoil';
import { timeIncrements } from 'constants';
import { currentPractice } from '../../../practiceState';
import { requestApi } from 'api/Api';
import Select from 'components/shared/Select/Select';

const TimeIncrements = () => {
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [defaultTimeIncrement, setDefaultTimeIncrement] = useState({
    label: '30 minutes',
    value: 30
  });
  const navigate = useNavigate();

  useEffect(() => {
    setDefaultTimeIncrement(practice?.display_settings?.calendar?.defaultTimeIncrement);
  }, [practice]);

  const onChangeDefaultTimeIncrement = async (event) => {
    setDefaultTimeIncrement(event);
    try {
      const display_settings = {
        ...practice.display_settings,
        calendar: {
          ...practice.display_settings.calendar,
          defaultTimeIncrement: { value: event.value, label: event.label }
        }
      };
      const data = await requestApi({
        url: '/api/practice/update',
        params: { fields: { display_settings } },
        navigate
      });

      if (data?.updatedItems > 0) {
        toast.success('Default time increment of calendar was updated successfully');
        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Select
      parentClassName="!mt-3"
      width={200}
      label="Default time increment"
      value={defaultTimeIncrement}
      options={timeIncrements}
      onChange={(event) => onChangeDefaultTimeIncrement(event)}
    />
  );
};

export default TimeIncrements;
