import { makeDefaultFilter } from 'api/Filter';

import { showAlert } from '../../../Alert/Alert';

export default async function handleMakeDefault(
  navigate,
  { id, category, isDefault, data, applyFilter, resetFilter, queryClient }
) {
  const make = await makeDefaultFilter(navigate, { id, category, isDefault });

  if (make.code === 0) {
    showAlert({
      message: 'Filter set as default successfully',
      color: 'success'
    });
    if (isDefault) {
      const filter = data.filters.find((f) => f.id === id);
      applyFilter(filter);
    } else {
      resetFilter();
    }
  } else {
    showAlert({
      message: 'An unexpected error has occured. Please try again later.',
      color: 'danger'
    });
  }

  queryClient.invalidateQueries(['filters']);
  queryClient.invalidateQueries(['default-filters']);
}
