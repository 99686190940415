import Tippy from '@tippyjs/react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import { ia } from 'lib/helpers/utility';
import React from 'react';
import {
  useEnhanceNoteContextData,
  useEnhanceNoteContextApi
} from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const List = ({ type }) => {
  const { setFilledForms } = useEnhanceNoteContextApi();
  const { filledForms = {}, componentRefs } = useEnhanceNoteContextData();

  const currentComponents = Object.entries(filledForms?.[type]?.components)?.filter(
    ([, value]) => !!value?.selected
  );

  const propsForNotFoundNote = {
    icon: 'new-notev1',
    iconSize: 32,
    title: 'No data',
    titleClassName: '!mt-3',
    description: 'No data in display settings'
  };

  const onSelect = (e, itemKey) => {
    e.stopPropagation();
    const { checked = false } = e.target;

    setFilledForms((prevFilledForms = {}) => {
      return {
        ...prevFilledForms,
        [type]: {
          ...prevFilledForms?.[type],
          components: {
            ...prevFilledForms?.[type]?.components,
            [itemKey]: {
              ...prevFilledForms?.[type]?.components?.[itemKey],
              checked
            }
          }
        }
      };
    });
  };

  const scrollToComponent = (componentPath) => {
    const componentRef = componentRefs.current[componentPath];
    if (componentRef) {
      componentRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleDivClick = (e, item) => {
    // Prevent scrolling if the click target is the checkbox or its label
    const isCheckboxOrLabel =
      e.target.matches('input[type="checkbox"]') || e.target.closest('label');

    if (!isCheckboxOrLabel) {
      scrollToComponent(item.path);
    }
  };

  return (
    <div className=" py-[6px] !pr-2 pl-[10px]">
      {ia(currentComponents) ? (
        currentComponents?.map(([itemKey, item]) => (
          <Tippy content={item?.title} placement="right" className="tippy-dark" key={itemKey}>
            <div
              className="flex cursor-pointer items-center rounded-md p-2 shadow-md"
              onClick={(e) => handleDivClick(e, item)}>
              <Checkbox
                onChange={(e) => onSelect(e, itemKey)} // Handle checkbox change
                color="blue"
                label={item?.title}
                value={item.title}
                checked={item.checked}
                data-qa={`left-sidebar-checkbox-${item.title}`}
              />
            </div>
          </Tippy>
        ))
      ) : (
        <NotFound {...propsForNotFoundNote} />
      )}
    </div>
  );
};

export default List;
