import { capitalize, isNull } from 'lodash';
import React from 'react';
import resultCodeMeanings from '../resultCodeMeanings';

export const FaxDetail = ({ fax }) => {
  const faxInfo = fax?.documo_fax_response;

  const DetailRow = ({ label, value, highlight, highlightError }) => {
    const textColor = highlight ? 'text-success-500' : highlightError ? 'text-danger-500' : '';
    return (
      <div className={`flex justify-between items-center !py-2 ${highlight ? textColor : ''}`}>
        <span className="text-gray-600">{label}</span>
        <span className="font-medium">{value}</span>
      </div>
    );
  };

  return (
    <div className="bg-white  !px-8 !pt-6 !pb-8 !mb-4">
      <div className="my-4 ">
        <DetailRow label="Message ID" value={faxInfo.messageId} />
      </div>
      <div className="grid grid-cols-2 gap-4">
        {/* Left column for message details */}
        <div>
          <DetailRow label="Delivery ID" value={faxInfo.deliveryId} />
          <DetailRow label="Message Number" value={faxInfo.messageNumber} />
          <DetailRow label="Created At" value={faxInfo.createdAt} />
          <DetailRow label="Delivery Date" value={faxInfo.resolvedDate} />
          <DetailRow label="Direction" value={faxInfo.direction} />
          <DetailRow
            label="Status"
            value={capitalize(faxInfo.status)}
            highlight={fax.status === 'success'}
            highlightError={fax.status !== 'success'}
          />
          <DetailRow label="Number" value={faxInfo.faxCallerId} />
          <DetailRow label="Country" value={faxInfo.country} />
          <DetailRow label="Recipient Name" value={faxInfo.recipientName || 'N/A'} />
          <DetailRow label="Subject" value={faxInfo.subject || 'N/A'} />
        </div>

        {/* Right column for fax details */}
        <div>
          <DetailRow label="Caller ID" value={faxInfo.faxNumber} />
          <DetailRow
            label="Fax Speed"
            value={isNull(faxInfo.faxSpeed) ? 'N/A' : faxInfo.faxSpeed}
          />
          <DetailRow label="Fax ECM" value={faxInfo.faxECM} />
          <DetailRow
            label="Fax Duration"
            value={isNull(faxInfo.duration) ? 'N/A' : `${faxInfo.duration}ms`}
          />
          <DetailRow label="Fax result code" value={faxInfo.resultCode} />
          <DetailRow
            label="Fax result"
            value={
              resultCodeMeanings.find((code) => code.resultCode == faxInfo.resultCode)?.meaning
            }
          />
          <DetailRow
            label="Fax Tone Detected"
            value={faxInfo.faxDetected ? 'YES' : 'NO'}
            highlight
          />
          <DetailRow label="Pages" value={faxInfo.pagesCount} />
        </div>
      </div>
    </div>
  );
};
