import { Capitalize, ia } from '../../../../../lib/helpers/utility';
import { textBoldAndUnderline, textItalic } from './markdownHelpers';

const formatRows = (row) => {
  return row
    .filter((item) => item.checked)
    .map((item) => {
      const { name, when, where } = item;
      let formattedRow = `${name}`;
      if (when) formattedRow += `, ${when.label}`;

      if (where) formattedRow += `, ${where}`;

      return formattedRow;
    });
};

export const formatSections = (data) => {
  if (data?.noDetails) {
    return textItalic({
      text: `No notable review of systems\n\n`
    })?.trim();
  }

  return Object.entries(data)
    .map(([sectionKey, section]) => {
      if (ia(section)) {
        const formattedSubSections = section
          .map((subSection) => {
            const formattedRows = formatRows(subSection.row).join(',\n');
            if (formattedRows) {
              return `${textBoldAndUnderline({
                text: Capitalize(subSection.title)
              })}:\n${formattedRows}`;
            }
          })
          .filter(Boolean)
          .join('\n\n');

        if (formattedSubSections) {
          return `${textBoldAndUnderline({
            text: `${Capitalize(sectionKey)} section`
          })} :\n\n${formattedSubSections}`;
        }
      }
    })
    .filter(Boolean)
    .join('\n\n');
};
