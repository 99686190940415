import { ia } from 'lib/helpers/utility';
export const weightLossItemFormatter = ({ items = [], goalWeightData = null }) => {
  if (!ia(items)) {
    return goalWeightData?.initial_weight || goalWeightData?.goal_weight
      ? [{ ...goalWeightData }]
      : [];
  }
  if (!goalWeightData) {
    return items;
  }
  return items.map((item) => ({
    ...item,
    ...goalWeightData
  }));
};
