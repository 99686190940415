import moment from 'moment';

export default function getValidityLength(pack, startedDate, level) {
  if (pack.no_expiration) return;

  const validationUnit = pack.validity_time_unit;

  const validityTimeLength = pack.validity_time_length;

  const expiryDate = moment(startedDate).add(validityTimeLength, validationUnit);

  const currentDate = moment();

  const duration = moment.duration(expiryDate.diff(currentDate));

  const humanizedDuration = duration.humanize();

  const daysRemaining = duration.asDays() * -1;

  if (level) {
    if (daysRemaining > 30) {
      return 'primary';
    } else if (daysRemaining > 7) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  return `Expires in ${humanizedDuration}`;
}
