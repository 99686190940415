import React, { useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { addKiosk } from '/api/Kiosk';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMember } from 'lib/hooks/queries/member/useMember';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { DarkColors } from 'components/shared/ColorPicker/ColorPicker';
import Modal from 'components/shared/Modal/Modal';

import NewKiosk from './NewKiosk';

export default function NewKioskModal({ show, setShow, kioskNr, kiosks }) {
  const [status, setStatus] = useState('pending');
  const [confirmedKiosk, setConfirmedKiosk] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const outletContext = useOutletContext();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    status: Yup.string().required('Status is required'),
    color: Yup.string().required('Color is required')
  });

  const { data } = useMember({});

  const getColor = useMemo(() => {
    if (kiosks?.length > 0) {
      const colors = kiosks.map((k) => k.color);
      const newArray = DarkColors.filter((item) => !colors.includes(item));
      const randomValue = newArray[Math.floor(Math.random() * newArray.length)];
      return randomValue;
    }
    return '#13B9FF';
  }, [kiosks]);

  const formik = useFormik({
    initialValues: {
      name: `Kiosk ${kioskNr}`,
      color: getColor,
      status: '',
      description: '',
      notify_users: []
    },
    enableReinitialize: true,
    validationSchema
  });

  const closeNewKiosk = () => {
    setShow(false);
  };

  const addNewKiosk = async () => {
    const addedKiosk = await addKiosk(navigate, {
      practiceId: outletContext?.practiceId,
      values: {
        ...formik.values,
        notify_users: formik?.values?.notify_users.map((np) => np.value)
      },
      kioskId: confirmedKiosk?.id
    });
    setStatus('pending');
    closeNewKiosk();
    if (addedKiosk.code == 0) {
      showAlert({ title: 'Kiosk added successfully', color: 'success' });
    } else {
      showAlert({ title: 'There was a problem adding Kiosk!', color: 'danger' });
    }
    formik.resetForm();
    queryClient.invalidateQueries(['kiosks']);
  };

  return (
    <Modal
      title="Add New Kiosk"
      slideFromRight
      className="w-[360px]"
      handleOpen={show}
      handleClose={closeNewKiosk}
      footer={
        <div className="flex w-full justify-between">
          <Button
            text="Cancel"
            onClick={closeNewKiosk}
            className="border !border-neutral-200 !bg-neutral-50 text-neutral-500 hover:!bg-neutral-100"
          />
          <Button
            text="Add new kiosk"
            onClick={addNewKiosk}
            disabled={status != 'success' || !formik.isValid}
            color="primary"
          />
        </div>
      }>
      <NewKiosk
        setConfirmedKiosk={setConfirmedKiosk}
        status={status}
        practitioners={data?.members?.map(({ user_id, f_name, l_name }) => ({
          value: user_id,
          label: `${f_name} ${l_name}`
        }))}
        setStatus={setStatus}
        formik={formik}
        kiosks={kiosks}
      />
    </Modal>
  );
}
