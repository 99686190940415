import React, { useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { genders, marital_status, races } from 'constants';
import { capitalize } from 'lodash';

import { interimApi } from 'api/InterimApi';
import { updateUser } from 'api/User';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import { propertyPathMap } from '../constants';

import PersonalInfoForm from './PersonalInfoForm';
import useInitUser from './lib/useInitUser';
import useUserFormik from './lib/useUserFormik';

const PersonalInfo = () => {
  const { updateSteps, checkinButton, appointmentId, kiosk, user, practice } = useOutletContext();
  const [patient, setPatient] = useState({});
  const [address, setAddress] = useState({});
  const [states, setStates] = useState([]);
  const [decodedSSN, setDecodedSSN] = useState(false);

  const [allDataEditable, setAllDataEditable] = useState(false);
  const [editableData, setEditableData] = useState({ emailEdit: false, phoneEdit: false });
  const navigate = useNavigate();

  const { device } = useUIContext();

  const formRef = useRef();

  const formik = useUserFormik({ patient, address, user, submitUpdate, editableData });

  const { getSecure } = useInitUser({
    setAllDataEditable,
    appointmentId,
    patient,
    setPatient,
    setEditableData,
    setAddress,
    setStates,
    setDecodedSSN,
    user,
    practice,
    formik
  });

  const keyChanges = (initial, altered) => {
    const changes = {};
    for (const key in altered) {
      if (!editableData.emailEdit && key === 'email') continue;
      if (!editableData.phoneEdit && key === 'phone') continue;
      if (altered[key] !== initial?.[key]) changes[key] = altered?.[key];
    }
    return changes;
  };

  async function submitUpdate(updatedUser, updatedAddress) {
    if (!formik.isValid) return;
    try {
      const patientChanges = keyChanges(patient, updatedUser);
      const addressChanges = keyChanges(address, updatedAddress);

      if (Object.keys(patientChanges).length === 0 && Object.keys(addressChanges).length === 0) {
        updateSteps({
          version: 2,
          formName: propertyPathMap.personalInfoComplete.name(),
          isCompleted: true,
          newStatus: 'completed'
        });
        return;
      }

      const data = await updateUser(navigate, {
        changes: patientChanges,
        addressChanges
      });

      if (data?.code === 0) {
        const { holds, updated } = data;
        if (ia(holds)) {
          showAlert({
            color: 'danger',
            title: 'There was an error updating your account',
            message: 'Some info may be invalid.',
            position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
          });

          const newErrors = {};
          holds.forEach((v) => {
            // Split the field path and set nested errors
            const fieldPath = v?.field.split('.');
            if (fieldPath.length > 1) {
              if (!newErrors[fieldPath[0]]) {
                newErrors[fieldPath[0]] = {};
              }
              newErrors[fieldPath[0]][fieldPath[1]] = capitalize(v?.message);
            } else {
              newErrors[v?.field] = capitalize(v?.message);
            }
          });

          await formik.setErrors(newErrors);

          // Scroll to first error field
          if (holds.length > 0) {
            const firstErrorField = document.querySelector(`[name="${holds[0].field}"]`);
            if (firstErrorField) {
              firstErrorField.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
          }
        } else if (ia(updated)) {
          updateSteps({
            version: 2,
            formName: propertyPathMap.personalInfoComplete.name(),
            isCompleted: true,
            newStatus: 'completed'
          });
        }
      } else {
        console.error(data?.message);
        console.error(data?.error);
        Honeybadger.notify(
          `file: checkin/personal_information_container, method: submitUpdate - else, error: Theres been an error`
        );
        showAlert({
          message: 'There was an error updating your account',
          color: 'danger',
          position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
        });
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  }

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient();
    const pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    if (kiosk) pickerOptions.fromSources = ['webcam'];
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    filestackClient.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    const handle = {
      jpg: data.filesUploaded[0].handle
    };
    const res = await interimApi(
      '/api/filestack/profile_photo',
      {
        handle,
        userId: patient.id
      },
      navigate
    );
    if (res.data.profilePhoto > 0) {
      formik.setFieldValue('profile_photo', handle.jpg);
    } else {
      Honeybadger.notify(
        `file: checkin/personal_information_container, method: saveImage - else, error: Theres been an error`
      );
      showAlert({
        color: 'danger',
        title: 'Profile Photo Error',
        message: 'Uploading image failed. Please try again',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  return (
    <div>
      {states.length > 0 && (
        <PersonalInfoForm
          onSubmit={handleSubmit}
          checkinButton={checkinButton}
          formik={formik}
          formRef={formRef}
          genderList={genders}
          raceList={races}
          stateList={states}
          maritalStatusList={marital_status}
          getSSN={getSecure}
          openFilestackPicker={openFilestackPicker}
          decodedSSN={decodedSSN}
          setDecodedSSN={setDecodedSSN}
          allDataEditable={allDataEditable}
          editableData={editableData}
          kiosk={kiosk}
          showWarning={!user?.authenticated && !user?.isOnboarding}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(PersonalInfo);
