import React, { useState } from 'react';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { ia } from 'lib/helpers/utility';
import { ReactFormGenerator } from 'react-form-builder2';
import Modal from 'components/shared/Modal/Modal';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const Actions = ({ data }) => {
  const [previewPacket, setPreviewPacket] = useState(false);
  const [fields, setFields] = useState([]);
  const { device } = useUIContext();
  const navigate = useNavigate();

  const sendLink = async (type) => {
    const params = {
      link: `${process.env.REACT_APP_BASE_URL}/ia/${data?.token}`,
      patientId: data?.target_id
    };
    await requestApi({
      navigate,
      url: `/api/form/instant_packet/${type}`,
      params,
      onError: () => {
        showAlert({ title: `Failed to send ${type}`, color: 'danger' });
      },
      onSuccess: () => {
        showAlert({ title: `Successfully sent ${type}` });
      }
    });
  };

  const handleCopyLink = async () => {
    const link = `${process.env.REACT_APP_BASE_URL}/ia/${data?.token}`;
    try {
      await navigator.clipboard.writeText(link);
      showAlert({ message: 'Link copied to clipboard' });
    } catch (err) {
      console.error('Failed to copy the link: ', err);
    }
  };

  const handlePreview = () => {
    const uniqueFields = data?.form_ids?.reduce((acc, form) => {
      form?.json?.fields.forEach((field) => {
        acc.push(field);
      });
      return acc;
    }, []);
    if (ia(uniqueFields)) {
      setFields(uniqueFields);
    }
    setPreviewPacket(true);
  };

  const options = [
    {
      label: 'Packet Link',
      icon: 'new-link',
      children: [
        { label: 'Copy Link', icon: 'new-copy', onClick: handleCopyLink },
        {
          label: 'Resend Link',
          icon: 'calendar-tick',
          children: [
            { label: 'Email', icon: 'new-email-blue', onClick: () => sendLink('email') },
            {
              label: 'Text Message',
              icon: 'text-message',
              onClick: () => sendLink('text')
            }
          ]
        }
      ]
    },
    {
      label: 'View Patient',
      onClick: () => window.open(`/portal/charts/${data?.target_id}`, '_blank'),
      icon: 'new-patient-active'
    },
    {
      label: 'Preview',
      icon: 'new-eye',
      onClick: handlePreview
    }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {previewPacket && (
        <Modal
          slideFromRight
          title="All Forms From This Packet"
          isOpen={previewPacket}
          handleClose={() => setPreviewPacket(false)}
          className={cs(
            '!bg-white',
            device === 'tablet-sm' ? 'w-[729px]' : device === 'tablet' ? 'w-[984px]' : 'w-[1240px]'
          )}>
          {fields.length > 0 ? (
            <div className="relative">
              <div className="cursor-not-allowed content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30">
                <ReactFormGenerator data={fields || []} read_only answer_data={null} hide_actions />
              </div>
            </div>
          ) : (
            <div className="flex h-full items-center justify-center">
              <div className="flex flex-col items-center gap-3 text-center">
                <Icon icon="no-files-found" />
                <p className="text-md text-primary-900">Failed to read forms for this packet</p>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default withErrorBoundary(Actions);
