import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getDocumentsCategories, getImportantDocuments, getPatientDocuments } from 'api/Documents';

export const useDocuments = ({
  params = {},
  options = {},
  dependencies = [],
  isAttachments = false
}) => {
  const navigate = useNavigate();
  return useQuery(
    [isAttachments ? 'attachments' : 'documents', ...dependencies],
    () => getPatientDocuments(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};

export const useImportantDocuments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['importantDocuments', ...dependencies],
    () => getImportantDocuments(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};

export const useDocumentsCategories = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['documentsCategories', ...dependencies],
    () => getDocumentsCategories(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
