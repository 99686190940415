import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';

const Objective = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();

  if (customClinicalNote && !clinicalNote?.objective) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <SanitizeComponent value={clinicalNote?.objective} title="Objective" />
    </div>
  );
};

export default Objective;
