import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const DeletedInvoiceError = ({ error = '' }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      <Icon size={80} icon="no-invoice" className="cursor-pointer" />
      <h1 className="text-2xl font-bold text-sky-900">Invoice not available!</h1>
      <p className="mt-2 text-slate-500 w-1/2 text-center font-400 text-lg">{error}</p>
    </div>
  );
};

export default DeletedInvoiceError;
