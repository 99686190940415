import React from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../../../../../shared/Icon/Icon';

const rowOptions = [15, 25, 50, 100];

const RowsPerPage = ({ filters, setFilters }) => {
  return (
    <Popover className="relative">
      {({ close, open }) => (
        <>
          <Popover.Button
            data-qa="rowsPerPage"
            className="relative flex p-[8px] cursor-pointer items-center justify-center gap-[6px] border border-solid border-neutral-100 rounded-md bg-white shadow-sm">
            <label className="cursor-pointer !m-0 text-neutral-500 ">Show:</label>

            <label className="cursor-pointer !m-0 font-500 text-primary-900 w-6">
              {filters.limit}
            </label>

            <Icon
              icon={'new-chevron-down-sm'}
              className={`cursor-pointer ${open && 'rotate-180'}`}
            />
          </Popover.Button>

          <Popover.Panel className="absolute z-10 flex shadow-md right-0 mt-1">
            <div className="bg-white shadow-md rounded-md py-[14px]">
              {rowOptions.map((item, index) => (
                <div
                  key={item}
                  data-qa={`row-${index}`}
                  className="w-[50px] h-[30px] flex justify-center items-center text-primary-900 cursor-pointer hover:bg-primary-50"
                  onClick={() => {
                    setFilters((prevState) => ({ ...prevState, limit: item, page: 1 }));
                    close();
                  }}>
                  {item}
                </div>
              ))}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default RowsPerPage;
