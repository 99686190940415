import { useCallback, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCalendarPosition = ({ calendarRef, isOpen }) => {
  const { pathname } = useLocation();
  if (pathname === '/iframe.html') return;

  const setCalendarFixedPosition = useCallback(() => {
    const container = calendarRef?.current?.parentNode;
    if (container) {
      const parentContainer = container.closest('.ReactModal__Content') || document.body;
      const parentRect = parentContainer.getBoundingClientRect();
      const initialRect = container.getBoundingClientRect();

      void container.offsetHeight;

      const left = initialRect.left - (parentContainer === document.body ? 0 : parentRect.left);
      const top = initialRect.top - (parentContainer === document.body ? 0 : parentRect.top);

      container.style.cssText = `
        position: fixed;
        left: ${left}px;
        top: ${top}px;
        opacity: 0;
        z-index: 1000;
      `;

      requestAnimationFrame(() => {
        container.style.cssText = `
          position: fixed;
          left: ${left}px;
          top: ${top}px;
          opacity: 1;
          z-index: 1000;
        `;
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setCalendarFixedPosition();
      }, 200);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'style') {
            const container = calendarRef?.current?.parentNode;
            if (container && container.style.position !== 'fixed') {
              setCalendarFixedPosition();
            }
          }
        });
      });

      const container = calendarRef?.current?.parentNode;
      if (container) {
        observer.observe(container, { attributes: true });
      }

      return () => observer.disconnect();
    }
  }, [isOpen, setCalendarFixedPosition]);
};
