import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getClaims } from '../../../../api/Claim';

export const useClaims = ({ params = {}, options = {}, dependencies = [], name = 'claims' }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => getClaims(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
