import React from 'react';

import { useFormikContext } from 'formik';

import { ia, io } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';

const Allergies = () => {
  const { values } = useFormikContext();

  if (!io(values?.allergies) || !ia(values?.allergies)) return null;

  return (
    <div className="flex max-w-[650px] items-start justify-between gap-2 rounded-md border border-solid border-danger-200 bg-danger-50 px-2 py-1">
      <Icon icon="new-warning-v2" color="danger" size={16} />
      <p className="truncate text-sm font-500 leading-4 text-danger-500">
        {`Allergies: ${values?.allergies.filter(Boolean).join(', ')}`}
      </p>
    </div>
  );
};

export default Allergies;
