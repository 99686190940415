import React from 'react';
import cs from 'classnames';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(process.env.REACT_AG_GRID_KEY);

import { AgGridReact } from 'ag-grid-react';
import './AGBasicTable.scss';

const AGBasicTable = ({
  customClassName,
  columnDefs = [],
  rowData,
  defaultColDef = {},
  gridRef,
  ...props
}) => {
  return (
    <div className={cs('ag-theme-quartz h-full', customClassName)}>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        rowData={rowData}
        defaultColDef={defaultColDef}
        {...props}
      />
    </div>
  );
};

export default AGBasicTable;
