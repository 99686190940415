import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Icon from 'components/shared/Icon/Icon';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';

const FormComponents = () => {
  const { formik } = useFormBuilderContext();
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className=" mt-9 rounded-[10px] bg-white p-[21px]">
        <div className="mb-[21px] flex h-[30px] w-max items-center gap-2 rounded-[34px] bg-primary-50 px-[14px] py-[5px] text-center">
          <p className="text-sm font-500 text-primary-500">Form Components</p>
          <Icon icon="new-edit-v3" />
        </div>
        <div className="relative">
          <div className="cursor-not-allowed content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30">
            <ReactFormGenerator
              data={formik?.values?.form?.json?.fields || []}
              read_only
              hide_actions
            />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default FormComponents;
