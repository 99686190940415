const atom = require('recoil').atom;

module.exports = {
  practiceState: atom({
    key: 'kioskPracticeState',
    default: {
      name: '',
      logo: '',
      loaded: false
    }
  })
};
