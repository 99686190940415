import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const AdditionalComments = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (!selectedTreatmentPlan.additional_comments) return null;

  return (
    <div>
      <h3 className="text-neutral-800 font-500 text-base">Additional comments</h3>
      <p className="text-sm text-neutral-800 first-letter:capitalize">
        {selectedTreatmentPlan.additional_comments}
      </p>
    </div>
  );
};

export default AdditionalComments;
