import moment from 'moment';

export default function getWeekOfMonth(date) {
  const firstDayOfMonth = moment(date).startOf('month');
  const daysIntoMonth = moment(date).diff(firstDayOfMonth, 'days');
  const weekOfMonth = Math.ceil((daysIntoMonth + firstDayOfMonth.day()) / 7);
  switch (weekOfMonth) {
    case 1:
      return 'first';
    case 2:
      return 'second';
    case 3:
      return 'third';
    case 4:
      return 'fourth';
    case 5:
      return 'fifth';
    default:
      return ''; // Adjust as needed for your specific use case
  }
}
