import { requestApi } from './Api';

export const getCptCodes = async (navigate, params) =>
  await requestApi({
    url: '/api/codes/cpt/get',
    navigate,
    params
  });

  export const deleteCptCode = async (navigate, params) => {
    return await requestApi({
      navigate,
      url: '/api/codes/cpt/delete',
      params
    });
  };
