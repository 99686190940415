import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import React from 'react';

import { CurrentMedications as initialValue } from '../../CustomFormInitialStates';
import { deleteCustomFormCheck } from '../../lib/deleteCustomFormCheck';

const DeleteMedication = ({
  currentMedications,
  setCurrentMedications,
  syncMedicationsAndClinicalNotes,
  handleNoDetails,
  setConfirmationModal,
  confirmationModal,
  medicationToDelete,
  setMedicationToDelete
}) => {
  const handleDeleteRow = (id) => {
    if (currentMedications?.noDetails) return handleNoDetails(true);

    if (!currentMedications?.medications[0]?.id && !id) {
      showAlert({
        title: 'Medications',
        message: 'Please create medication before deleting one.',
        color: 'warning'
      });

      return setCurrentMedications(initialValue);
    }

    let updatedObject = {
      ...currentMedications,
      medications: currentMedications.medications.filter((row) => row.id !== id)
    };

    syncMedicationsAndClinicalNotes(updatedObject);
  };

  return (
    confirmationModal && (
      <Confirm
        variant="danger"
        primaryBtnTxt="Delete"
        title="Delete medications"
        icon="trash"
        message={
          deleteCustomFormCheck(medicationToDelete, currentMedications?.medications)
            ? 'Are you sure you want to delete this medication?'
            : 'Are you sure you want to delete this form?'
        }
        handleContinue={() => {
          handleDeleteRow(medicationToDelete);
          setMedicationToDelete(null);
          setConfirmationModal(false);
        }}
        handleOpen={confirmationModal}
        handleClose={() => setConfirmationModal(false)}
      />
    )
  );
};

export default DeleteMedication;
