import React, { useImperativeHandle, useRef } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const TimeFrames = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();
  const timeFramesRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { timeFrames: clinicalNote?.mha?.timeFrames }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      mha: {
        ...clinicalNote.mha,
        [name ?? key]: value ?? e
      }
    });
  };

  return (
    <Textarea
      isEditor
      label="Times frames"
      placeholder="Enter time-frame"
      id="timeFrames"
      name="timeFrames"
      className="min-h-[200px]"
      value={clinicalNote?.mha?.timeFrames}
      onChange={(e) => handleChange(e, 'timeFrames')}
      transcribing
      forwardedRef={timeFramesRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default TimeFrames;
