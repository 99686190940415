import React, { useState } from 'react';
import GetStarted from './GetStarted';
import Drafts from './Drafts';
import Questionnaire from './Questionnaire';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { useDraftedForms } from 'lib/hooks/queries/forms/useDraftedForms';

const NameAndType = ({ currentBreadcrumb }) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const { formik, setShowNavigationButtons, practiceId } = useFormBuilderContext();
  const { data } = useDraftedForms({ params: { practice_id: practiceId } });

  const handleShowQuestionnaire = () => {
    setShowNavigationButtons(true);
    setShowQuestionnaire(true);
  };

  const draftedForms = data?.drafts || [];
  return (
    <>
      {showQuestionnaire ? (
        <Questionnaire formik={formik} currentBreadcrumb={currentBreadcrumb} />
      ) : (
        <div className="flex h-full flex-col justify-between px-9 py-[15px]">
          <div>
            <GetStarted />
            <Input
              label="Form Name"
              placeholder="Enter form name"
              id="form.name"
              name="form.name"
              value={formik.values.form.name}
              onChange={formik.handleChange}
              error={formik.touched.form?.name && formik.errors.form?.name}
            />
            <div className="flex items-center justify-center py-11 text-center">
              <Button
                data-qa="start-building-form-btn"
                text="Start Building"
                disabled={!formik.values.form.name}
                onClick={handleShowQuestionnaire}
              />
            </div>
            <Drafts draftedForms={draftedForms} />
          </div>
        </div>
      )}
    </>
  );
};

export default NameAndType;
