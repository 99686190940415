import Icon from 'components/shared/Icon/Icon';
import { stripHtmlTags } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { isDataReadOnly } from '../../../lib/aggregatedDataHelper';
import Actions from '../components/Actions';

const disableRow = (params, userPermissions, noteLocked) => {
  const isReadOnly = isDataReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: params?.data,
    noteLocked
  });

  return isReadOnly ? { 'pointer-events': 'none', opacity: '0.4' } : {};
};

export default (props) => [
  {
    field: 'medication',
    headerName: 'Medication',
    flex: 2,
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      return (
        <div className="flex h-full w-full items-center gap-2">
          <Icon
            icon="new-document-preview"
            shade={600}
            className="cursor-pointer"
            color="neutral"
            onClick={() => {
              props.setShowMedicationModal({ open: true, data: params?.data });
            }}
          />

          <span className="text-sm font-400 text-neutral-900">
            {params?.data?.medication?.label ?? 'N/A'}
          </span>

          {params?.data?.createdBy?.isPatient && (
            <Icon icon="new-user-arrow-right" shade={600} color="neutral" />
          )}
        </div>
      );
    }
  },
  {
    field: 'dose',
    headerName: 'Dosage',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      const content = `${params?.data?.doses ?? ''}${params?.data?.dosesUnit ? ` (${params?.data?.dosesUnit})` : ''}`;

      return <span>{content ? content : 'N/A'}</span>;
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    valueFormatter: ({ value }) => (!!value ? capitalize(value) : 'N/A')
  },
  {
    field: 'date',
    headerName: 'Date',
    valueFormatter: ({ value }) => (!!value ? moment.utc(value).format('MMM D, YYYY') : 'N/A')
  },
  {
    field: 'notes',
    headerName: 'Note',
    valueFormatter: ({ value }) => (!!value ? stripHtmlTags(value) : 'N/A')
  },
  {
    field: 'weight',
    headerName: 'Weight',
    valueFormatter: ({ value }) => (!!value ? `${value} lbs` : 'N/A')
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: (params) => <Actions data={params?.data} customProps={props} />,
    maxWidth: 100,
    pinned: 'right',
    cellStyle: (params) => disableRow(params, props.userPermissions, props.noteLocked)
  }
];
