import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import Avatar from 'components/shared/Avatar/Avatar';
import Icon from 'components/shared/Icon/Icon';

const ProfileWithTooltip = ({ user }) => {
  const [shown, setShow] = useState(false);
  const classStyle = 'flex cursor-pointer items-center gap-2 py-2 px-4 hover:bg-primary-50';
  const activeClassStyle =
    'flex cursor-pointer items-center gap-2 py-2 px-4 bg-primary-50 hover:bg-primary-50';

  const handleShowHover = () => setShow(true);

  const handleHideHover = () => setShow(false);

  const Content = () => (
    <>
      <div className="flex items-center gap-2 px-3 py-[6px]">
        <Avatar
          firstName={user.f_name}
          lastName={user.l_name}
          id={user.id || user.user_id}
          image={user.profile_photo}
          size="xl"
        />
        <span className="text-sm font-500 text-primary-900">{`${user.f_name} ${user.l_name}`}</span>
      </div>
      <div className="mb-[6px] h-[1px] w-full bg-neutral-100 px-4"></div>
      <div className="grid gap-y-1 pb-[6px]">
        <NavLink
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          onClick={handleHideHover}
          to="/portal/profile">
          <Icon icon="user-square" size={18} />
          <span className="text-sm text-primary-900" data-qa="edit-profile-btn">
            Edit profile
          </span>
        </NavLink>
      </div>
    </>
  );

  return (
    <Tippy
      placement="right-end"
      content={shown && <Content />}
      interactive
      className="custom-tooltip"
      onShow={handleShowHover}
      onHide={handleHideHover}
      hideOnClick={false}>
      <div
        className={cs(
          'mt-4 flex cursor-pointer flex-col items-center justify-center !rounded-lg border border-solid p-[2px] first:!mt-0',
          shown ? '!border-primary-500 bg-primary-50' : 'border-white'
        )}
        data-qa="profile-popover-btn">
        <Avatar
          firstName={user.f_name}
          lastName={user.l_name}
          id={user.id || user.user_id}
          image={user.profile_photo}
          size="large"
        />
      </div>
    </Tippy>
  );
};

export default ProfileWithTooltip;
