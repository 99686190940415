import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import AGTable from 'components/shared/AGTable/AGTable';
import Allowed from 'components/shared/Permissions/Allowed';

export default function AdminPractitionersPractices() {
  const { practices } = useOutletContext();
  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 150
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300
    },
    {
      field: 'practiceUser.role.name',
      headerName: 'Role',
      width: 300
    },
    {
      field: 'practiceUser.veradigm_user_id',
      headerName: 'ePrescribe Enrolled',
      width: 300,
      cellRenderer: ({ value }) => {
        return value ? 'Yes' : 'No';
      }
    }
  ];
  return (
    <Allowed requiredPermissions="practice.read" showMessage showIllustration>
      <TableContextProvider name="practitioner_practices_admin" cols={cols}>
        <Table practices={practices} />
      </TableContextProvider>
    </Allowed>
  );
}
function Table({ practices }) {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-col  !pb-2">
        <div className="!mb-0 h-full">
          <AGTable data={practices || []} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {practices?.length > 0 && (
            <p className="font-light text-primary-900">
              Showing 0 - {practices?.length + ''} practices
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
