import { isEmpty } from 'lib/helpers/utility';
import React from 'react';

const OrderPhysician = ({ itemProps }) => {
  if (
    isEmpty(itemProps?.order_phys_first) &&
    isEmpty(itemProps?.order_phys_last) &&
    isEmpty(itemProps?.order_phys_npi)
  ) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Order Physician</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {itemProps?.order_phys_first && (
            <div>
              <strong>First Name:</strong> {itemProps?.order_phys_first}
            </div>
          )}

          {itemProps?.order_phys_last && (
            <div>
              <strong>Last Name:</strong> {itemProps?.order_phys_last}
            </div>
          )}

          {itemProps?.order_phys_npi && (
            <div>
              <strong>NPI:</strong> {itemProps?.order_phys_npi}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default OrderPhysician;
