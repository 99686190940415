import React, { useEffect } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { convertMarkdownToHTML } from 'lib/helpers/utility';

import {
  activePromptConversationsState,
  isAIGeneratingState,
  myScribeAILoadingState
} from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { clinicalNote as clinicalNoteState } from 'components/state';

import ResponseMapView from './ResponseMapView';
import { extractOnlyClinicalNoteKeys } from './lib/helpers';

const AIPrompt = ({ prompt, scrollContainerRef }) => {
  const { message, id, intelligence_chat_id, isNote = true, emCPTsText = null, note } = prompt;
  const { onEnhanceNote } = useChatContextApi();
  const isGenerating = useRecoilValue(isAIGeneratingState);
  const isLoading = useRecoilValue(myScribeAILoadingState);
  const { inputTags, userPrompt, selectedPromptNoteType } = useChatContextData();
  const setActivePromptConversations = useSetRecoilState(activePromptConversationsState);
  const { autoMapFormRef } = useClinicalNoteContext();
  const setClinicalNote = useSetRecoilState(clinicalNoteState);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
    }
  }, [message]);

  const onRegenerate = () => {
    setActivePromptConversations((ps) => ps.slice(0, ps.length - 1));
    onEnhanceNote({
      userMessage: {
        message: userPrompt,
        tags: inputTags?.map((item) => item.id),
        agent: 'user'
      },
      intelligenceChatMessageId: id,
      intelligenceChatId: intelligence_chat_id,
      noteType: selectedPromptNoteType?.type,
      isRegenerate: true
    });
  };

  const mapSectionsToCN = () => {
    if (autoMapFormRef?.current?.complaints) {
      autoMapFormRef.current['present_illness'] = autoMapFormRef?.current['complaints'];
    }

    const clinicalNote = extractOnlyClinicalNoteKeys(autoMapFormRef.current);
    setClinicalNote((prev) => ({ ...prev, ...clinicalNote }));
  };

  return (
    <div className="mb-10 mt-2 flex flex-col pb-28">
      <div className="flex gap-2 items-center">
        <div
          className="border-[0.5px] flex items-center justify-center rounded-full border-solid border-primary-100 bg-white"
          style={{
            width: 32,
            height: 32,
            minWidth: 32,
            minHeight: 32
          }}>
          <Icon icon="mysc-ai-triple-stars" />
        </div>
        <div className="text-base font-600 text-neutral-800">MyScribe AI (Beta)</div>
      </div>

      {message?.length > 0 ? (
        <div className="flex flex-col gap-16 mx-[40px]">
          {isNote ? (
            <>
              <ResponseMapView note={note} disabled={isLoading || isGenerating} />
              {emCPTsText && <div dangerouslySetInnerHTML={{ __html: emCPTsText }} />}
            </>
          ) : (
            <div className="flex-1 pb-10">
              <div
                className="w-full p-3 text-neutral-900 text-base font-400"
                dangerouslySetInnerHTML={{
                  __html: convertMarkdownToHTML(message).replace(/\n/g, '<br>')
                }}
              />
            </div>
          )}
          {isNote && (
            <div className="flex items-center justify-end mr-4">
              <div className="mt-1 flex flex-row gap-2">
                <Button
                  size="small"
                  text="Regenerate"
                  icon="new-reset"
                  onClick={onRegenerate}
                  outlined
                  color="neutral"
                  iconIsStroke
                  iconColor="white"
                  disabled={isGenerating || isLoading}
                />
                <SignAndLock
                  buttonProps={{
                    text: 'Preview & Finalize',
                    className:
                      'animate-shine bg-gradient-to-r from-[#13b8ff] to-[#098cc1] !py-[5px]',
                    outlined: true,
                    primary: true,
                    textClassName: 'text-white',
                    icon: null,
                    iconColor: 'black',
                    size: 'small',
                    disabled: isGenerating || isLoading,
                    onClick: mapSectionsToCN
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default AIPrompt;
