import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import React, { useCallback, useRef } from 'react';
import '../style.scss';
import SearchInput from './SearchInput';
import SearchResults from './SearchResults';
import SearchTabs from './SearchTabs';
import useSearch from './useSearch';

const Search = React.memo(({ onChange, isClearable = true, className, ...rest }) => {
  const scrollPositionRef = useRef(0);
  const inputFieldRef = useRef();

  const {
    inputValue,
    setInputValue,
    show,
    setShow,
    setSelectedPractitioner,
    showSearchModal,
    setShowSearchModal,
    confirmButtonRef,
    count,
    data,
    activeTab,
    setActiveTab,
    divRef,
    onTabChange,
    impersonateUser,
    handleScroll,
    handleInputChange,
    clearSearch,
    handleOnClick
  } = useSearch(scrollPositionRef);

  const debouncedHandleScroll = useCallback(() => handleScroll(), [handleScroll]);

  const openSearchModal = useCallback(() => {
    setShowSearchModal(true);
    inputFieldRef.current?.focus();
  }, [setShowSearchModal]);

  return (
    <div className="flex flex-col">
      <div
        onClick={openSearchModal}
        data-qa="search"
        className="flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-lg border-transparent !outline-none hover:bg-neutral-100">
        <Icon icon="new-search" size={18} className="cursor-pointer" />
      </div>
      <Confirm
        show={show?.confirmImpersonate}
        variant="primary"
        primaryBtnTxt="Confirm"
        title="Achtung!"
        icon="new-eye"
        message="You're about to impersonate a provider. Your permissions are limited per HIPAA compliance and activity is logged."
        handleContinue={() => impersonateUser()}
        handleOpen={show?.confirmImpersonate}
        handleClose={() => setShow({ ...show, confirmImpersonate: false })}
        forwardedRef={confirmButtonRef}
      />

      <Modal
        isOpen={showSearchModal}
        handleClose={() => setShowSearchModal(false)}
        clearSearch={clearSearch}
        slideFromRight={true}
        bodyClassName="p-0"
        headerCloseIcon={false}
        isFooter={false}
        headerClassName="pb-0 w-full p-0"
        customHeader={
          <div className="flex w-full flex-col items-center justify-between gap-4">
            <SearchInput
              inputValue={inputValue}
              handleInputChange={handleInputChange}
              setShowSearchModal={setShowSearchModal}
              clearSearch={clearSearch}
              inputFieldRef={inputFieldRef}
            />

            <div className="w-full">
              <SearchTabs
                data={data}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                onTabChange={onTabChange}
                count={count}
              />
            </div>
          </div>
        }>
        <div className="max-h-[calc(100vh-114px)] overflow-y-auto">
          <SearchResults
            data={data[activeTab]}
            handleOnClick={handleOnClick}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setSelectedPractitioner={setSelectedPractitioner}
            setShow={setShow}
            activeTab={activeTab}
            show={show}
            setShowSearchModal={setShowSearchModal}
            divRef={divRef}
            debouncedHandleScroll={debouncedHandleScroll}
          />
        </div>
      </Modal>
    </div>
  );
});

export default Search;
