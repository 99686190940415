import React, { useEffect, useState, useMemo, memo, useCallback, useRef } from 'react';

import Switch from 'components/shared/Switch/Switch';
import {
  useEnhanceNoteContextData,
  useEnhanceNoteContextApi
} from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { filterCheckedComponents, getHeaderButtons } from '../../lib/helpers';
import List from './List';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { updateHiddenComponents } from '../../../shared/lib/helpers';

const MemoizedSwitch = memo(({ onChange, checked }) => (
  <Switch onChange={onChange} checked={checked} data-qa="note-choose-switch" />
));

const MemoizedPopoverWithOptions = memo(({ selectedNote }) => {
  const { typeOfNotes: ctxTypeOfNotes } = useMyScribeAIContext();
  const { filledForms = {} } = useEnhanceNoteContextData();
  const { setFilledForms } = useEnhanceNoteContextApi();

  const scrollPositionRef = useRef(0); // to store scroll position
  const panelRef = useRef(null); // to reference the scrollable panel

  const onSelectForms = useCallback(
    (key, type) => (checked) => {
      setFilledForms((prevFilledForms = {}) => ({
        ...prevFilledForms,
        [type]: {
          ...prevFilledForms?.[type],
          components: {
            ...prevFilledForms?.[type]?.components,
            [key]: {
              ...prevFilledForms?.[type]?.components?.[key],
              selected: checked
            }
          }
        }
      }));
    },
    [setFilledForms]
  );

  const getOptions = useMemo(() => {
    return Object.entries(ctxTypeOfNotes?.[selectedNote?.type]?.components || {}).map(
      ([key, value]) => ({
        label: value?.title,
        component: (
          <MemoizedSwitch
            onChange={onSelectForms(key, selectedNote?.type)}
            checked={!!filledForms?.[selectedNote?.type]?.components?.[key]?.selected}
          />
        )
      })
    );
  }, [ctxTypeOfNotes, filledForms, onSelectForms, selectedNote?.type]);

  // Save scroll position on re-render
  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [getOptions]);

  // Handle scroll event and update scroll position
  const handleScroll = () => {
    if (panelRef.current) {
      scrollPositionRef.current = panelRef.current.scrollTop;
    }
  };

  return (
    <Popover
      iconClassName="mr-0"
      panelClassName="max-h-[300px] overflow-y-auto"
      icon="new-add-square"
      options={getOptions}
      buttonClassName="text-centered"
      isFixed
      panelRef={panelRef}
      onScroll={handleScroll}
    />
  );
});

const EnhanceNoteSidebar = ({ className }) => {
  const [typeOfNotes, setTypeOfNotes] = useState([]);
  const { filledForms = {}, loading, filledFormsLoading } = useEnhanceNoteContextData();

  const { setFilledForms } = useEnhanceNoteContextApi();
  const { typeOfNotes: ctxTypeOfNotes } = useMyScribeAIContext();

  useEffect(() => {
    setTypeOfNotes(Object.values(ctxTypeOfNotes || {}));
  }, [ctxTypeOfNotes]);

  const checkedFilledForms = useMemo(() => filterCheckedComponents(filledForms), [filledForms]);
  const selectedTypeOfNotes = useMemo(
    () => Object.values(checkedFilledForms || {}),
    [checkedFilledForms]
  );

  const onSelectTypeOfNotes = (checked, note) => {
    const newFilledForms = {
      ...filledForms,
      [note?.type]: checked ? { ...filledForms?.[note?.type], checked } : ctxTypeOfNotes[note?.type]
    };

    const updatedFormsWithHiddenComponents = updateHiddenComponents(newFilledForms, true);

    setFilledForms(updatedFormsWithHiddenComponents);
  };

  const headerButtons = getHeaderButtons(typeOfNotes, onSelectTypeOfNotes, filledForms);

  return (
    <div
      className={`fixed z-30 h-full shrink-0 border-0 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] ${className} w-[264px]`}>
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              {button.component}
            </div>
          ))}
        </div>
      </div>
      {loading || filledFormsLoading ? (
        <Skeleton count={30} />
      ) : (
        <div className="h-[calc(100vh-3rem)] overflow-y-auto pb-28">
          {selectedTypeOfNotes?.map((selectedNote) => {
            return (
              <div key={selectedNote?.type}>
                <div className="flex flex-row items-center justify-between gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 ">
                  <div className="font-500 text-primary-600 transition-all">
                    {selectedNote?.label}
                  </div>
                  <MemoizedPopoverWithOptions selectedNote={selectedNote} />
                </div>
                <List type={selectedNote?.type} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default EnhanceNoteSidebar;
