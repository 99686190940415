import React, { Fragment, useCallback, useState } from 'react';
import { Tab } from '@headlessui/react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { Formik } from 'formik';
import PersonalInfo from './PersonalInfo';
import PhysicalAddress from './PhysicalAddress';
import InsuranceProfile from './InsuranceProfile';
import EmergencyContact from './EmergencyContact';
import Skeleton from 'react-loading-skeleton';
import getReShapedPersonalInfo from '../../../../lib/getReShapedPersonalInfo';
import { iaRa } from 'lib/helpers/utility';
import ReferringProviders from 'components/practice/charts/Demographics/ReferringProvider/ReferringProviders';
import { currentPractice } from 'components/practice/practiceState';
import { useRecoilValue } from 'recoil';
import Pharmacies from 'components/practice/charts/Pharmacy/Pharmacies';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useStates } from 'lib/hooks/queries/useStates';
import { handleSubmitPatientChanges, UpdatePatientValidationSchema } from './lib';

const PersonalInfoModal = ({
  patient_id,
  modalVisible,
  setModalUnVisible,
  selectedInsurance,
  showInsurance
}) => {
  const insurance = showInsurance || selectedInsurance;
  const [activeTab, setActiveTab] = useState(insurance ? 2 : 0);

  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: stateData } = useStates();
  const states = stateData?.states || [];

  const { data, isLoading, isFetching } = usePatient({
    params: { id: patient_id },
    dependencies: [patient_id]
  });

  const initialValues = getReShapedPersonalInfo(data?.patient);

  const getTabsData = useCallback(
    () => [
      {
        label: 'Personal Info',
        content: <PersonalInfo />
      },
      {
        label: 'Physical Address',
        content: <PhysicalAddress />
      },
      {
        label: 'Insurance Profile',
        content: <InsuranceProfile selectedInsurance={selectedInsurance} />
      },
      {
        label: 'Pharmacies',
        content: (
          <Pharmacies
            pharmacies={iaRa(data?.patient?.pharmacy)}
            patientId={data?.patient?.id}
            practiceId={practice?.id}
          />
        )
      },
      {
        label: 'Emergency Contact',
        content: <EmergencyContact />
      },

      {
        label: 'Referring Providers',
        content: (
          <ReferringProviders
            patientId={data?.patient.id}
            practicePatient={data?.patient?.practicePatient}
          />
        )
      }
    ],
    [data?.patient, selectedInsurance]
  );

  const handleSubmitChanges = async (values, setErrors) => {
    handleSubmitPatientChanges({
      values,
      setErrors,
      states,
      initialValues,
      queryClient,
      navigate
    });
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };
  return (
    <Formik
      initialValues={getReShapedPersonalInfo(data?.patient)}
      onSubmit={(values, { setErrors }) => handleSubmitChanges(values, setErrors)}
      validationSchema={UpdatePatientValidationSchema}
      enableReinitialize>
      {({ handleSubmit, initialValues }) => (
        <Modal
          handleOpen={modalVisible}
          handleClose={setModalUnVisible}
          isLarge
          title="Demographics"
          slideFromRight
          subModal
          footer={
            <div className="flex w-full items-center justify-between">
              <Button text="Close" onClick={setModalUnVisible} outlined color="neutral" />
              <Button text="Update Patient Data" onClick={() => handleSubmit(initialValues)} />
            </div>
          }>
          {isLoading || isFetching ? (
            <Skeleton count={5} />
          ) : (
            <Tab.Group selectedIndex={activeTab} onChange={handleTabChange}>
              <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
                {getTabsData().map((tab, i) => {
                  return (
                    <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                      {({ selected }) => (
                        <button
                          className={cs(
                            'flex items-center gap-1 border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                            selected
                              ? ' !border-primary-500 font-600 text-primary-500'
                              : 'font-500 text-neutral-800'
                          )}>
                          {tab.label}
                        </button>
                      )}
                    </Tab>
                  );
                })}
              </Tab.List>
              <Tab.Panels>
                {getTabsData().map((tab, i) => (
                  <Tab.Panel key={i}>
                    {({ selected }) => {
                      return selected ? tab.content : null;
                    }}
                  </Tab.Panel>
                ))}
              </Tab.Panels>
            </Tab.Group>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default PersonalInfoModal;
