import { showAlert } from '../../../../../shared/Alert/Alert';

export const handleAction = async ({
  type = null,
  method = null,
  setActiveView = () => null,
  actionRef = {}
}) => {
  const actionTypes = {
    referral_order: {
      save: async () => {
        await actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    },
    non_erx_prescription: {
      save: async () => {
        await actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    },
    lab_order: {
      save: async () => {
        await actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    },

    elab_order: {
      save: async () => {
        await actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    }
  };

  const actionMethod = actionTypes[type][method];

  if (!actionMethod) {
    showAlert({
      color: 'warning',
      title: "The method for the action you attempted to call doesn't exist."
    });
    return;
  }

  const response = await actionMethod();

  return response;
};
