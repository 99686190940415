export const convertPermissions = (permissions) => {
  let result = {};

  Object.keys(permissions).forEach((key) => {
    const actions = permissions[key].actions;

    actions.forEach((action) => {
      const [fieldGroup, fieldAction] = action.field.split('.');

      if (!result[fieldGroup]) {
        result[fieldGroup] = {};
      }

      result[fieldGroup][fieldAction] = action.value ? true : false;
    });
  });

  return result;
};

export const checkFractionString = (fraction = '') => {
  const [a, b] = fraction.split('/').map(Number);
  return b !== 0 && a / b === 1;
};
