export default function reshapeFacilityProvideList(providers = []) {
  return providers?.map((provider) => {
    return {
      label: provider.facility_name,
      value: provider.id,
      facility_addr_1: provider.facility_addr_1,
      facility_addr_2: provider.facility_addr_2,
      facility_city: provider.facility_city,
      facility_id: provider.facility_id,
      facility_name: provider.facility_name,
      facility_npi: provider.facility_npi,
      facility_state: provider.facility_state,
      facility_zip: provider.facility_zip,
      id: provider.id,
      practice_id: provider.practice_id,
      practitioner_ids: provider.practitioner_ids
    };
  });
}
