import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const SessionQuotesAndContents = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();
  const sessionQuotesAndContents = clinicalNote?.epn?.sessionQuotesAndContents;

  if (customClinicalNote && !sessionQuotesAndContents) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <SanitizeComponent title="Session quotes and contents" value={sessionQuotesAndContents} />
    </div>
  );
};

export default SessionQuotesAndContents;
