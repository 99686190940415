import { useRef } from 'react';

const useScroll = ({ ref, distance = 60, direction = 'down' }) => {
  const scrollRef = ref || useRef(null);

  const handleScroll = () => {
    const currentScrollTop = scrollRef.current.scrollTop;
    const currentScrollLeft = scrollRef.current.scrollLeft;
    const scrollDistance = direction === 'down' || direction === 'right' ? distance : -distance;
    scrollRef.current.scrollTo({
      top: currentScrollTop + (direction === 'down' || direction === 'up' ? scrollDistance : 0),
      left:
        currentScrollLeft + (direction === 'right' || direction === 'left' ? scrollDistance : 0),
      behavior: 'smooth'
    });
  };

  return handleScroll;
};

export default useScroll;
