import React, { useEffect, useState, useRef } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useOccupations } from 'lib/hooks/queries/useOccupations';
import { useStates } from 'lib/hooks/queries/useStates';
import { ia } from 'lib/helpers/utility';
import { createPatient } from 'api/Patients';
import { showAlert } from 'components/shared/Alert/Alert';
import { usePractices } from 'lib/hooks/queries/practice/usePractices';
import NewPatientForm from './NewPatientForm';

const NewPatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  dob: Yup.string().required('Date of birth required'),
  needs_practice_id: Yup.boolean(),
  practitioner_id: Yup.string().when('needs_practice_id', (needs_practice_id, schema) => {
    return needs_practice_id[0] ? schema.required('You should select a practice first!.') : schema;
  })
});

const NewPatientAdminContainer = ({ showModal, hideModal, noPractice = false }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [practiceId, setPracticeId] = useState(null);
  const { data } = usePractitioners({ practice_id: id ? id : practiceId }, null, [id, practiceId]);
  const { data: practicesData } = usePractices({ params: { withCount: true } });
  const practices = practicesData?.practices;
  const { data: statesData } = useStates();
  const { data: occupationData } = useOccupations({
    returnAll: true
  });
  const practitioners = data?.practitioners;
  const states = statesData?.states;
  const occupations = occupationData?.occupations;

  useEffect(() => {
    const checkPracticeId = () => {
      if (!practiceId && !id && noPractice) {
        formik.setFieldValue('needs_practice_id', true, false);
      }
    };
    checkPracticeId();
  }, []);

  const [selectedState, setSelectedState] = useState(null);
  const [selectedSecondaryState, setSelectedSecondaryState] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState();
  const [selectedPractice, setSelectedPractice] = useState();
  const [selectedPractitionerId, setSelectedPractitionerId] = useState('');
  const [invitationEmail, setInvitationEmail] = useState(true);
  const [noEmail, setNoEmail] = useState(false);
  const [openSecondaryAddress, setOpenSecondaryAddress] = useState(false);
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      f_name: '',
      m_name: '',
      l_name: '',
      email: '',
      dob: '',
      gender: '',
      personal_id: '',
      ssn: '',
      phone: '',
      marital_status: '',
      title: '',
      primary_doctor: '',
      race: '',
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: '',
      zip: '',
      secondary_address_ln_1: '',
      secondary_address_ln_2: '',
      secondary_city: '',
      secondary_state: '',
      secondary_zip: '',
      practitioner_id: '',
      needs_practice_id: false,
      invitationEmail: true,
      occupation_id: null
    },
    validationSchema: NewPatientValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const patientToBeCreated = {
        patient: {
          f_name: values.f_name,
          m_name: values.m_name,
          l_name: values.l_name,
          email: values.email,
          dob: values.dob,
          gender: values.gender,
          personal_id: values.personal_id,
          ssn: values.ssn,
          phone: values.phone,
          marital_status: values.marital_status,
          title: values.title,
          primary_doctor: values.primary_doctor,
          race: values.race
        },
        ...filterNullValues(
          {
            address_ln_1: values.address_ln_1,
            address_ln_2: values.address_ln_2,
            city: values.city,
            state: values.state,
            zip: values.zip
          },
          'address'
        ),
        ...filterNullValues(
          {
            address_ln_1: values.secondary_address_ln_1,
            address_ln_2: values.secondary_address_ln_2,
            city: values.secondary_city,
            state: values.secondary_state,
            zip: values.secondary_zip
          },
          'secondary_address'
        ),

        practitioner_id: selectedPractitionerId,
        occupation_id: values.occupation_id?.value || null,
        invitationEmail: values.invitationEmail
      };

      const modifiedPatient = {
        ...patientToBeCreated.patient,
        gender: formik.values.gender?.value,
        marital_status: formik.values.marital_status?.value,
        race: formik.values.race?.value
      };

      const modifiedData = {
        ...patientToBeCreated,
        patient: modifiedPatient,
        practice_id: noPractice ? practiceId : id
      };
      mutateCreatePatient.mutate(modifiedData);
      resetForm();
    }
  });

  useEffect(() => {
    if (ia(practitioners)) {
      setSelectedPractitioner(practitioners[0]);
      const [firstPractitioner] = practitioners?.slice(0, 1);
      if (firstPractitioner) {
        const idOfFirstPractitioner = Object?.values(firstPractitioner)[0];
        setSelectedPractitionerId(idOfFirstPractitioner?.toString());
      }
    }
  }, [practitioners]);

  const filterNullValues = (obj, name) => {
    let allNullValues = true;
    let result = {};
    for (let key in obj) {
      if (!!obj[key]) {
        result[key] = obj[key];
        allNullValues = false;
      }
    }

    return allNullValues ? {} : { [name]: result };
  };

  const handleSelectPractitioner = (practitioner) => {
    setSelectedPractitioner(practitioner);
    setSelectedPractitionerId(practitioner?.value?.toString());
    formik.setFieldValue('practitioner_id', practitioner?.value);
  };

  const handleSelectedPractice = (practice) => {
    setSelectedPractice(practice);
    setPracticeId(practice?.value);
    formik.setFieldValue('needs_practice_id', false);
  };

  const handleSelectState = (state, field) => {
    if (field === 'secondary_state') {
      setSelectedSecondaryState(state);
      formik.setFieldValue('secondary_state', state.value);
    } else {
      setSelectedState(state);
      formik.setFieldValue('state', state?.value);
    }
  };

  const handleInvitationEmail = (value) => {
    setInvitationEmail(!invitationEmail);
    formik.setFieldValue('invitationEmail', value);
  };

  const queryClient = useQueryClient();

  const mutateCreatePatient = useMutation({
    mutationFn: (data) => createPatient(navigate, data),
    onSuccess: (data) => {
      formik.setSubmitting(false);
      hideModal();
      switch (data?.code) {
        case 0:
          noPractice
            ? queryClient.invalidateQueries(['patientsAdmin'])
            : queryClient.invalidateQueries(['patients']);
          showAlert({
            title: 'Patient created successfully!'
          });
          break;
        case 3:
          showAlert({
            title: 'Patient duplication!',
            message: 'A patient with this email already exists in your practice.',
            color: 'warning',
            icon: 'user-add'
          });
          break;

        default:
          break;
      }
    }
  });
  const checkPracticeId = () => {
    if (!practiceId && !id && noPractice) {
      formik.setFieldValue('needs_practice_id', true);
    }
  };
  const handleSubmitNewPatient = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };
  return (
    <NewPatientForm
      showModal={showModal}
      hideModal={hideModal}
      formRef={formRef}
      formik={formik}
      noEmail={noEmail}
      setNoEmail={setNoEmail}
      selectedSecondaryState={selectedSecondaryState}
      handleSubmitNewPatient={handleSubmitNewPatient}
      states={states}
      openSecondaryAddress={openSecondaryAddress}
      occupations={occupations}
      selectedState={selectedState}
      handleSelectState={handleSelectState}
      setOpenSecondaryAddress={setOpenSecondaryAddress}
      selectedPractice={selectedPractice}
      practices={practices}
      handleSelectedPractice={handleSelectedPractice}
      handleSelectPractitioner={handleSelectPractitioner}
      noPractice={noPractice}
      selectedPractitioner={selectedPractitioner}
      practitioners={practitioners}
      checkPracticeId={checkPracticeId}
      handleInvitationEmail={handleInvitationEmail}
      invitationEmail={invitationEmail}
    />
  );
};

export default NewPatientAdminContainer;
