export default function getUpdatedValues(initialObj, updatedObj) {
  const updatedValues = {};

  for (const key in updatedObj) {
    if (updatedObj.hasOwnProperty(key)) {
      if (initialObj[key] !== updatedObj[key]) {
        updatedValues[key] = updatedObj[key];
      }
    }
  }

  return updatedValues;
}
