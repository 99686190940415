import React from 'react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { userState as user_state, permissions } from 'components/state';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { useProducts } from 'lib/hooks/queries/products/useProducts';
import { usePackages } from 'lib/hooks/queries/packages/usePackages';
import { mString, reShapeProcedures, reShapeProducts } from 'lib/helpers/utility';
import AEOB from './components/AEOB';
import Icon from 'components/shared/Icon/Icon';
import Payments from 'components/Payments/Payments';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import Section from 'components/practice/NewAppointment/components/Section';
import ProceduresProducts from './components/ProceduresProduct/ProceduresProducts';
import CalculateAndPayments from './components/CalculatePayments/CalculateAndPayments';
import Balance from 'components/practice/NewAppointment/components/Sections/Pricing/components/Balance/Balance';

const Pricing = () => {
  const formik = useFormikContext();
  const userState = useRecoilValue(user_state);
  const hasPaymentPermission = useRecoilValue(permissions)?.payments?.read;
  const { device } = useUIContext();
  const isMobile = device === 'mobile' || device === 'tablet-sm' || device === 'tablet';
  const isPined = isMobile || userState?.display_settings?.appointment?.pricing?.isPined;
  const isOpen = userState?.display_settings?.appointment?.pricing?.isOpen ?? false;
  const includeIds =
    formik?.values?.invoice?.procedures
      ?.filter?.((p) => p?.packageId || p?.invoiceId)
      .map?.(({ id, packageId, invoiceId }) => {
        return { id, packageId, invoiceId };
      }) || [];

  const { data: servicesData } = useServices();
  const { data: productsData } = useProducts({ params: { forUsers: true } });
  const { data: packagesData } = usePackages({
    dependencies: [formik?.values?.id],
    params: { patientId: formik?.values?.patient?.id, includeIds }
  });

  const { data } = usePatient({
    params: { id: formik.values?.user_id },
    dependencies: [formik.values?.user_id]
  });

  const activeClasses = 'animate-shine bg-gradient-to-r from-[#13b8ff] to-[#098cc1] !py-[5px]';

  const HeaderChildren = (
    <div className="flex gap-4">
      {!isOpen && (
        <>
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-wallet-add" size={20} />
            <span className="text-sm font-400 leading-[22px] text-neutral-800">
              Appointment balance:
              <strong className="font-600">{mString(formik?.values?.invoice?.balance)}</strong>
            </span>
          </div>
        </>
      )}

      <div className="flex items-center gap-4">
        <div className="flex items-center gap-[6px]">
          <Icon icon="wallet" size={20} />
          <span className="text-sm font-400 leading-[22px] text-neutral-800">
            Account balance:
            <strong className="font-600">{mString(formik?.values?.patient_balance)}</strong>
          </span>
        </div>

        <AEOB invoice={formik?.values?.invoice} patient={data?.patient} />

        <Payments
          smallButton
          apptInvoice={true}
          appointment={formik?.values}
          appointmentId={formik?.values?.id}
          patientId={formik?.values?.user_id}
          practiceId={formik?.values?.practice_id}
          amount={formik?.values?.invoice?.balance || 0}
          buttonClassName={formik?.values?.isPaymentActive ? activeClasses : true}
        />
      </div>
    </div>
  );

  const gridClass = isPined
    ? 'mb-4 grid-cols-2'
    : hasPaymentPermission
      ? 'grid-cols-[500px,4fr,3fr]'
      : 'grid-cols-1';

  return (
    <Section
      target="pricing"
      title="Pricing Protocol"
      headerChildren={HeaderChildren}
      className={cs('!pb-0', isOpen && !isPined && 'h-[312px] pr-0')}
      isMobile={isMobile}>
      <div className={cs('grid h-full', gridClass)}>
        <div
          className={
            isPined && 'col-span-2 mb-2 border-0 border-b border-solid border-neutral-200 pb-1'
          }>
          <ProceduresProducts
            formik={formik}
            services={reShapeProcedures(servicesData?.services)}
            products={reShapeProducts(productsData?.products)}
            packages={packagesData?.invoicePackages}
          />
        </div>

        {hasPaymentPermission && (
          <>
            <CalculateAndPayments isPined={isPined} />
            <Balance />
          </>
        )}
      </div>
    </Section>
  );
};

export default Pricing;
