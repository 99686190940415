import moment from 'moment';

export const formatDates = (obj) => {
  return Object.keys(obj).reduce((acc, key) => {
    if (moment(obj[key], moment.ISO_8601, true).isValid()) {
      acc[key] = moment(obj[key]).format('YYYY-MM-DDTHH:mm');
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
