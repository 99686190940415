import React, { useState } from 'react';

import cs from 'classnames';
import _ from 'lodash';

import { io } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';

export default function ExportData({
  filters,
  defaultFilters,
  title,
  onGenerateClick = () => {},
  className = '',
  iconColor = 'neutral'
}) {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        onClick={() => setShow(true)}
        className={cs(
          'flex items-center justify-center gap-2 rounded-lg bg-white px-[11px] py-[5px]',
          'cursor-pointer border border-solid !border-neutral-100 shadow-[0px_0px_8px_0px_#1032540A]',
          className
        )}>
        <Icon size={16} color={iconColor} className="cursor-pointer" icon="new-export-v2"></Icon>
      </div>
      <Modal
        icon="list-unordered"
        title={title}
        handleOpen={show}
        handleClose={() => setShow(false)}>
        <div>
          {io(filters) &&
            Object?.keys(filters)?.map((key) => {
              return (
                !_.isEqual(
                  _.sortBy(filters[key].values),
                  _.sortBy(defaultFilters[key]?.values)
                ) && (
                  <div className="mb-1 flex justify-between !px-2">
                    <p className="font-medium text-neutral-600">{filters[key].title}</p>
                    <p className="font-medium text-primary-900">
                      {filters[key]?.preview?.(filters[key].values)}
                    </p>
                  </div>
                )
              );
            })}
          <div
            onClick={onGenerateClick}
            className="mt-3 flex cursor-pointer items-center justify-between !rounded-lg border !border-primary-100 bg-primary-50 !p-2 !px-4 hover:bg-primary-100 ">
            <p className="text-base font-medium text-primary-900">Generate CSV file</p>
            <Icon className="cursor-pointer" icon="file-csv"></Icon>
          </div>
        </div>
      </Modal>
    </>
  );
}
