import PriorityCellRenderer from '../components/TestsTable/PriorityCellRenderer';
import RemoveTestCellRenderer from '../components/TestsTable/RemoveTestCellRenderer';

export const colDefs = [
  {
    field: 'testName',
    headerName: 'Test Name',
    flex: 2,
    cellRenderer: 'agGroupCellRenderer'
  },
  {
    field: 'refLabDisplayName',
    headerName: 'RefLab'
  },
  {
    headerName: 'Priority',
    field: 'status',
    valueGetter: (p) => p.data?.status,
    cellRenderer: (props) => <PriorityCellRenderer {...props} key={props.data.id} />
  },
  {
    field: 'medicalNecessity',
    headerName: 'Medical Necessity',
    valueFormatter: ({ value }) => (value ? value : 'Not Checked')
  },
  {
    field: 'actions',
    headerName: '',
    flex: 1 / 3,
    cellRenderer: RemoveTestCellRenderer
  }
];
