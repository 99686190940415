import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const Info = ({ fromPreAuthModal = false }) => {
  return (
    <div className="mb-3 flex justify-center text-center">
      <p className="flex gap-1.5 rounded-md bg-primary-25 p-2 text-sm font-400 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
        <Icon icon="new-info" color="primary" size={20} />
        {fromPreAuthModal
          ? 'Please fill out required information, sign and press continue on the top right to enter secure credit card information.'
          : 'Please fill out the required information, sign, and press "Capture Card" on the bottom to enter secure credit card information.'}
      </p>
    </div>
  );
};

export default Info;
