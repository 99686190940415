import React from 'react';

import Button from 'components/shared/Buttons/Button';

const VitalsHeader = ({ breadcrumb }) => {
  const { steps, step } = breadcrumb;

  const onShowCreateVitals = () => {
    breadcrumb.push({ title: 'New Vitals', name: 'create' });
  };

  return steps[step]?.name === 'start' ? (
    <Button
      className="mr-5 min-w-[170px]"
      size="small"
      onClick={onShowCreateVitals}
      text="New Vitals"
      data-qa="new-macro-btn"
      icon="new-add-square"
      transparent
    />
  ) : null;
};

export default VitalsHeader;
