import { useRecoilValue } from 'recoil';

import { clinicalNote as clinicalNoteState } from 'components/state';

import Accordion from '../../../../shared/Accordion';
import GeneralNote from '../../../GeneralNote';

const GeneralNoteSection = ({
  components,
  sectionRef,
  options,
  withStyle = true,
  showDisclaimer = true
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.notes?.title}
      id={components?.notes?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <GeneralNote sectionRef={sectionRef} showDisclaimer={showDisclaimer} />
    </Accordion>
  );
};

export default GeneralNoteSection;
