import cs from 'classnames';
import { permissions } from 'components/state';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Calendar } from 'react-date-range';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dosesList, vaccinesList } from '../../../../../constants';
import schedulingState from '../../../../Patient/self_scheduling/schedulingState';
import Checkbox from '../../../Checkbox/Checkbox';
import Input from '../../../Input/Input';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import CustomFormPopover from '../components/CustomFormPopover';
import { isDataReadOnly } from '../lib/aggregatedDataHelper';

const VaccinesRow = ({
  vaccine,
  doses,
  lastDose,
  fromCheckin,
  lastDoseDisabled,
  notSure,
  index,
  note,
  id,
  onChange,
  formik,
  onCheckNotSure = () => null,
  className,
  createdBy = null
}) => {
  const [appointmentData, _] = useRecoilState(schedulingState.appointmentData);
  const userPermissions = useRecoilValue(permissions);

  moment.tz.setDefault(appointmentData?.practice_timezone);

  const dataReadOnly = isDataReadOnly({
    data: { id, createdBy },
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents
  });

  return (
    <div className={cs('flex w-full items-center gap-x-[10px]', className)}>
      <div className="grid w-full items-center gap-4">
        <div
          className={
            fromCheckin ? 'flex flex-col !gap-4' : 'grid w-full gap-4 sm:flex sm:flex-col'
          }>
          <Select
            inputId="select-vaccine"
            label="Vaccine"
            options={vaccinesList}
            value={vaccine}
            id={'vaccine-' + index}
            name="vaccine"
            error={formik?.errors?.vaccine}
            onChange={(event) => onChange('vaccine', event, id)}
            isCreatable
            placeholder="Type and select vaccine"
            disabled={dataReadOnly}
          />
          <Select
            inputId="doses-taken"
            label="Doses taken"
            id={'vaccine-doses-' + index}
            options={dosesList}
            value={doses}
            name="doses"
            onChange={(event) => onChange('doses', event, id)}
            placeholder="How many doses?"
            disabled={dataReadOnly}
          />

          <div className="col-span-2 flex items-end gap-4">
            <CustomFormPopover
              buttonClassName="!p-0"
              button={
                <Input
                  inputWrapperClassName="!flex"
                  data-qa="date-of-last-dose"
                  label="Date of last dose"
                  id={'vaccine-date-' + index}
                  rightIcon="new-calendar-gray"
                  value={moment.utc(lastDose).format('MMM Do, YYYY')}
                  readOnly
                  disabled={lastDoseDisabled || dataReadOnly}
                />
              }
              panel={
                <Calendar
                  data-qa="date-lastdose"
                  date={new Date(lastDose)}
                  onChange={(event) => {
                    onChange('lastDose', moment.utc(event).add('12', 'h').format('YYYY-MM-DD'), id);
                  }}
                />
              }
            />

            <Checkbox
              data-qa="not-sure"
              className="!mb-2"
              label="Not sure"
              id={'vaccine-not-' + index}
              name="notSure"
              isChecked={notSure}
              onChange={() => onCheckNotSure(id)}
              disabled={dataReadOnly}
            />
          </div>
        </div>

        <Textarea
          label="Notes"
          placeholder="Add note here"
          id={'vaccine-note-' + index}
          isEditor={!fromCheckin}
          data-qa="note"
          className={cs(!fromCheckin ? '!min-h-[120px]' : '!min-h-[80px]', 'mb-2  text-sm')}
          name="note"
          value={note}
          onChange={
            !fromCheckin
              ? (value) => onChange('note', value, id)
              : (event) => onChange('note', event.target.value, id)
          }
          transcribing
          disabled={dataReadOnly}
        />
      </div>
    </div>
  );
};

export default VaccinesRow;
