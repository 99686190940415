import formatMedicationsNarrative from '../../../../../../shared/Forms/Custom/lib/formatMedicationsNarrative';

import { showAlert } from '../../../../../../shared/Alert/Alert';
import { formatSections as formatSectionsV1 } from '../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV1';
import { formatSections as formatSectionsV2 } from '../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import { formatSections as formatSectionsGoals } from '../../../../../../shared/Forms/Custom/lib/goalsFormatFormHelper';
import { createReviewOfSystemsNarrative } from '../../../../../../shared/Forms/Custom/lib/reviewSystemsHelper';

export const customFormResponseTypes = [
  'medicationHistory',
  'allergies',
  'medicalHistory',
  'vaccines',
  'conditions',
  'familyHistory',
  'socialHistory',
  'surgicalHistory',
  'weightLossAdministration'
];

export const getOverviewNarrativeOptions = ({ type, data }) => {
  switch (type) {
    case 'allergies':
      return formatSectionsV1({
        data: data.response,
        sectionData: 'allergies',
        sectionName: 'Allergy'
      }).trim();
    case 'vaccines':
      return formatSectionsV1({
        data: data.response,
        sectionData: 'row',
        sectionName: 'Vaccine'
      }).trim();
    case 'familyHistory':
      return formatSectionsV1({
        data: data.response,
        sectionData: 'row',
        sectionName: 'Family History'
      }).trim();
    case 'weightLossAdministration':
      return formatSectionsV1({
        data: data.response,
        sectionData: 'row',
        sectionName: 'Weight Loss Administration'
      }).trim();
    case 'surgicalHistory':
      return formatSectionsV1({
        data: data.response,
        sectionData: 'row',
        sectionName: 'Surgical Hisotry'
      }).trim();
    case 'socialHistory':
    case 'medicalHistory':
      return formatSectionsV2({
        data: data.response
      })?.trim();
    case 'vitals':
      return formatSectionsV2({
        data
      })?.trim();
    case 'medicationHistory':
      return formatMedicationsNarrative(data?.response?.medications)?.trim();
    default:
      return null;
  }
};

export const getFormattedNarrative = ({ foundNoteData, clinicalNote }) => {
  let narrativeOptions;
  let formattedNarrative;

  switch (foundNoteData) {
    case 'goals':
      narrativeOptions = { data: clinicalNote[foundNoteData] };
      return formatSectionsGoals(narrativeOptions)?.narrative || '';
    case 'ros':
      return createReviewOfSystemsNarrative(clinicalNote[foundNoteData]) || '';
    case 'present_illness':
    case 'other_complaints':
      narrativeOptions = {
        data: {
          present_illness: clinicalNote?.present_illness,
          other_complaints: clinicalNote?.other_complaints
        }
      };
      return formatSectionsV2(narrativeOptions);
    case 'cpt_codes':
      return clinicalNote[foundNoteData].map((cpt) => cpt?.code?.label).join(', ');
    default:
      if (typeof clinicalNote[foundNoteData] === 'string') {
        return clinicalNote[foundNoteData];
      }

      narrativeOptions = { data: clinicalNote[foundNoteData] };
      formattedNarrative = formatSectionsV2(narrativeOptions)?.trim();
      return formattedNarrative || null;
  }
};

export const mappedNamesForCN = (name) => {
  switch (name) {
    case 'present_illness':
    case 'other_complaints':
      return 'complaints';
    case 'icd_10_codes':
      return 'diagnosis_codes';
    default:
      return name;
  }
};

export const findInVitals = async ({ readVitals, setResult, setSelectedNote }) => {
  setSelectedNote(null);

  const formattedNarrative = getOverviewNarrativeOptions({
    type: 'vitals',
    data: readVitals
  });

  if (!formattedNarrative) {
    setResult(null);
    showAlert({ title: 'No data found on this previous note.' });
    return;
  }

  setResult(formattedNarrative);
  return;
};
