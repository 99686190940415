import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getStates } from '/api/State';
import { genders, practice_kinds, tax_id_types } from '/constants';
import { optionify } from '/lib/helpers/utility';
import NewPractitioner from './index';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import Skeleton from 'components/shared/Skeleton/Skeleton';
const validator = require('validator');

const NewPractitionerFromNewPracticeValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  dob: Yup.string().required('Date of birth required'),
  gender: Yup.string().required('Gender required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required.' });
    }

    if (!validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  }),
  newPractice: Yup.boolean(),
  address_ln_1: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Address required') : schema;
  }),
  city: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('City required') : schema;
  }),
  state: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('State required') : schema;
  }),
  zip: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Zip code required') : schema;
  }),
  practice_kind: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Kind required') : schema;
  }),
  practice_email: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Email required') : schema;
  }),
  practice_name: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Practice name required') : schema;
  }),
  practice_timezone: Yup.string().when('newPractice', (newPractice, schema) => {
    return newPractice ? schema.required('Timezone required') : schema;
  })
});

const NewPractitionerFromExistingPracticeValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  dob: Yup.string().required('Date of birth required'),
  gender: Yup.string().required('Gender required'),
  email: Yup.string().email().required('Email required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required.' });
    }

    if (!validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  })
});

export default function NewPractitionerContainer() {
  const [loading, setLoading] = useState({ general: false });
  const [practice_list, setPracticeList] = useState([]);
  const [states, setStates] = useState();
  const navigate = useNavigate();
  const [specialtyTypes, setSpecialtyTypes] = useState([]);

  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      f_name: '',
      m_name: '',
      l_name: '',
      gender: '',
      dob: '',
      email: '',
      phone: '',
      npi: '',
      tax_id: '',
      tax_id_type: '',
      claim_md_user_id: '',
      taxonomy: '',
      address_ln_1: '',
      address_ln_2: '',
      address_is_same_as_practice: false,
      city: '',
      state: '',
      zip: '',
      practice_name: '',
      practice_kind: '',
      practice_specialty_code: '',
      practice_email: '',
      practice_phone: '',
      practice_address_ln_1: '',
      practice_address_ln_2: '',
      practice_city: '',
      practice_state: '',
      practice_zip: '',
      practice_id: '',
      practice_timezone: '',
      practice_claim_md_account_key: '',
      insurance: '',
      newPractice: false
    },
    validationSchema: () => {
      return formik.values.newPractice
        ? NewPractitionerFromNewPracticeValidationSchema
        : NewPractitionerFromExistingPracticeValidationSchema;
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const objectToBeUpdated = {
          practitioner: {
            f_name: values?.f_name,
            m_name: values?.m_name,
            l_name: values?.l_name,
            gender: values?.gender,
            dob: values?.dob,
            email: values?.email,
            phone: values?.phone,
            npi: values?.npi,
            tax_id: values?.tax_id,
            tax_id_type: values?.tax_id_type,
            claim_md_user_id: values?.claim_md_user_id,
            taxonomy: values?.taxonomy,
            address_is_same_as_practice: values?.address_is_same_as_practice
          },
          address: {
            address_ln_1: values?.address_ln_1,
            address_ln_2: values?.address_ln_2,
            city: values?.city,
            state: values?.state,
            zip: values?.zip,
            country: values?.country,
            full_street_address: [
              values.address_ln_1,
              values.address_ln_2,
              values.city,
              values.state,
              values.country,
              values.zip
            ].join(' ')
          },
          insurance: values?.insurance,
          practice_id: values?.practice_id,
          practice: {
            name: values?.practice_name,
            kind: values?.practice_kind,
            specialty_code: values?.practice_specialty_code,
            email: values?.practice_email,
            phone: values?.practice_phone,
            timezone: values?.practice_timezone,
            claim_md_account_key: values?.practice_claim_md_account_key
          }
        };

        setSubmitting(true);
        await submitSignup(
          objectToBeUpdated.practitioner,
          objectToBeUpdated.practice_id,
          objectToBeUpdated.practice,
          objectToBeUpdated.address,
          objectToBeUpdated.insurance
        );
        setSubmitting(false);
      } catch (error) {
        console.log('error submitting form', error);
      }
    }
  });
  useEffect(() => {
    getStatesMethod();
    getPractices();
    getSpecialtyTypes();
  }, []);

  const getStatesMethod = async () => {
    try {
      let resData = await getStates(navigate);
      setStates(resData);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getPractices = async () => {
    setLoading({ ...loading, general: true });
    try {
      let res = await requestApi({
        url: '/api/practice/get',
        navigate
      });
      const { code, redirect, practices, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setPracticeList(optionify(practices, 'name', 'id'));
          break;
        case 1:
          console.error(error);
          toast.error(`There's been an error loading practices data. Please try again later.`);
          break;

        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: practitioners/new_practitioner/indexContainer, method: getPractices - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          console.error(`An unhandled code has been received`, res.data);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: practitioners/new_practitioner/indexContainer, method: getPractices - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
    setLoading({ ...loading, general: false });
  };

  const getSpecialtyTypes = async () => {
    try {
      let res = await requestApi({
        url: '/api/codes/benefit_type_code/get',
        navigate
      });
      if (res) {
        setSpecialtyTypes(res.benefit_type_code);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: practitioners/new_practitioner/indexContainer, method: getSpecialtyTypes - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
      console.error(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  const submitSignup = async (practitioner, practice, newPractice, address, insurance) => {
    let selected_practice;
    let success = true;
    if (!practice) {
      try {
        let res = await requestApi({
          url: '/api/practice/create',
          params: {
            practice: { ...newPractice, address: { ...address, country: 1 } }
          },
          navigate
        });
        const { code, practice, error } = res;
        switch (code) {
          case 0:
            selected_practice = practice;
            break;
          case 1:
            success = false;
            console.error(error);
            break;

          default:
            success = false;
            break;
        }
      } catch (error) {
        Honeybadger.notify(
          `file: practitioners/new_practitioner/indexContainer, method: submitSignup - catch - 1, error: ${
            error ?? 'Theres been an error'
          }`
        );
        console.error(`An unexpected error has occurred`, error);
      }
    } else {
      try {
        let res = await requestApi({
          url: '/api/practice/get',
          params: {
            practice_id: practice
          },
          navigate
        });
        if (res.practices) {
          selected_practice = res.practices;
        }
      } catch (error) {
        Honeybadger.notify(
          `file: practitioners/new_practitioner/indexContainer, method: submitSignup - catch - 2, error: ${
            error ?? 'Theres been an error'
          }`
        );
        console.error(`An unexpected error has occurred`, error);
      }
    }

    let selected_user_id = 0;
    let role_id;
    (selected_practice?.roles || [])?.map((role) => {
      if (role.name === 'Practitioner') {
        role_id = role.id;
      }
    });
    if (success) {
      try {
        let res = await requestApi({
          url: '/api/practice/member/invite',
          params: {
            user_id: selected_user_id,
            practice_id: selected_practice.id,
            user: {
              ...practitioner,
              ...{
                ...address,
                address: address.address_ln_1,
                address_2: address.address_ln_2,
                country: 1
              },
              insurance: insurance
            },
            role_id: role_id
          },
          navigate
        });
        const { code, redirect, message, error } = res;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 0:
            const alertMessage = message
              ? message
              : practice
                ? `Practitioner was added successfully to ${selected_practice.name} practice!`
                : `Practitioner was added successfully to the new practice ${selected_practice.name}!`;
            showAlert({
              title: alertMessage,
              color: 'success'
            });
            break;
          case 1:
            success = false;
            showAlert({ title: `An error has occurred while adding new member.`, color: 'danger' });
            console.error(error);
            break;

          default:
            success = false;
            showAlert({
              title:
                error ||
                'An unexpected error has occurred while adding new member. Please try again later or contact support.',
              color: 'danger'
            });
            Honeybadger.notify(
              `file: practitioners/new_practitioner/indexContainer, method: submitSignup - try, error: ${
                error ?? 'Theres been an error'
              }`
            );
            console.error(`Unhandled code has occurred`, res.data);
            break;
        }
      } catch (error) {
        Honeybadger.notify(
          `file: practitioners/new_practitioner/indexContainer, method: submitSignup - catch - 3, error: ${
            error ?? 'Theres been an error'
          }`
        );
        console.error(`An unexpected error has occurred`, error);
      }
    }
    if (success) {
      navigate(`/admin/practices/${selected_practice.id}`);
    }
  };

  if (loading.general || !states) {
    return (
      <div className="!p-4">
        <Skeleton height="500px" />e
      </div>
    );
  }

  return (
    <div>
      <NewPractitioner
        onSubmit={handleSubmit}
        onChange={formik.handleChange}
        formik={formik}
        formRef={formRef}
        practiceTypeList={practice_kinds}
        genderList={genders}
        stateList={states}
        practiceList={practice_list}
        tax_id_types={tax_id_types}
        specialtyTypes={specialtyTypes}
      />
    </div>
  );
}
