import moment from 'moment';
import { ia } from '../lib/helpers/utility';
import { requestApi } from './Api';

const getAppointments = async (defaultParams) => {
  try {
    const {
      currentView,
      defaultDate,
      filters,
      getDates,
      navigate,
      rangeDate,
      selectedView,
      setFilters,
      withEvents,
      isInitial,
      withalRest,
      limit,
      sort,
      page,
      pagination,
      fromPracticeIQueue
    } = defaultParams;

    let params = {
      startDate: moment(defaultDate).startOf('day'),
      endDate: moment(defaultDate).endOf('day'),
      withEvents,
      filters,
      isInitial,
      withal: {
        patient: true,
        practice: true,
        practitionerPractice: true,
        pendingCount: true,
        resources: true,
        ...withalRest
      },
      limit,
      sort,
      page,
      pagination,
      fromPracticeIQueue
    };

    if (filters?.selectedView) {
      getDates(null, filters?.selectedView);
      setFilters((prevState) => ({ ...prevState, selectedView: undefined }));
    }

    if (selectedView == 'agenda' || currentView == 'agenda') {
      params = {
        ...params,
        startDate: moment.utc(defaultDate).startOf('day'),
        endDate: moment.utc(defaultDate).endOf('day')
      };
    }

    if (currentView === 'week' || currentView === 'month') {
      const by = currentView === 'week' ? 'isoWeek' : 'month';

      params = {
        ...params,
        startDate: moment.utc(defaultDate).startOf(by),
        endDate: moment.utc(defaultDate).endOf(by)
      };
    }

    if (rangeDate?.startDate && rangeDate?.endDate) {
      params = {
        ...params,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate
      };
    }

    if (ia(filters?.appointmentTags)) {
      params = {
        ...params,
        appointmentTags: filters?.appointmentTags
      };
    }

    if (ia(filters?.appointmentType)) {
      params = {
        ...params,
        appointmentType: filters?.appointmentType
      };
    }

    if (ia(filters?.serviceType)) {
      params = {
        ...params,
        serviceType: filters?.serviceType
      };
    }

    if (ia(filters?.patient)) {
      params = { ...params, patientIds: filters?.patient };
    }

    if (ia(filters?.practitioners)) {
      params = { ...params, practitionerIds: filters?.practitioners };
    }

    if (ia(filters?.resources)) {
      params = { ...params, resourceIds: filters?.resources };
    }

    if (ia(filters?.appointmentStatus)) {
      params = { ...params, appointmentStatus: filters?.appointmentStatus };
    }

    if (ia(filters?.appointmentConfirmState)) {
      params = { ...params, appointmentConfirmState: filters?.appointmentConfirmState };
    }

    if (ia(filters?.clinicalNoteStatus)) {
      params = { ...params, clinicalNoteStatus: filters?.clinicalNoteStatus };
    }

    if (ia(filters?.superbillStatus)) {
      params = { ...params, superbillStatus: filters?.superbillStatus };
    }

    if (ia(filters?.claimState)) {
      params = { ...params, claimState: filters?.claimState };
    }

    return await requestApi({ url: '/api/appointment/get_multiple', params, navigate });
  } catch (error) {
    console.log('appointment error', error);
  }
};

const getAppointment = async (navigate, params, signal) => {
  if (params?.type !== 'event') {
    return requestApi({ url: '/api/appointment/get_one', navigate, params, signal });
  } else {
    return null;
  }
};

const getAppointmentv2 = async (navigate, params, signal) => {
  if (params?.type !== 'event') {
    return requestApi({ url: '/api/appointment/get_one_v2', navigate, params, signal });
  } else {
    return null;
  }
};

const updateAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/update',
    navigate,
    params
  });

const completeAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/complete',
    navigate,
    params
  });

const updateAppointmentV2 = async (navigate, params, appointment) => {
  const data = await requestApi({
    url: '/api/appointment/update_v2',
    navigate,
    params
  });

  const updatedAppointment = {
    ...appointment,
    ...data.updatedAppointment,
    ...(!!data.updatedAppointment?.invoice && {
      procedures: data.updatedAppointment?.invoice?.procedures || [],
      products: data.updatedAppointment?.invoice?.products || []
    })
  };

  return { ...data, updatedAppointment };
};

const creationActionAppointment = async (navigate, params, others) =>
  requestApi({
    url: '/api/appointment/action/creation',
    navigate,
    params,
    ...others
  });

const remindAppointment = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/reminder', navigate, params, ...others });

const cancelAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/cancel',
    navigate,
    params
  });

const acceptAppointment = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/accept',
    navigate,
    params
  });

const remindAppointmentMultiple = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/reminder_multiple', navigate, params, ...others });

const creationActionAppointmentMultiple = async (navigate, params, others) =>
  requestApi({ url: '/api/appointment/action/creation_multiple', navigate, params, ...others });

const cancelMultipleAppointments = async (navigate, params) =>
  requestApi({
    url: '/api/appointment/cancel_multiple',
    navigate,
    params
  });

const getAptTimelineDetails = async (navigate, params) =>
  requestApi({ url: '/api/appointment/timeline/get_details', navigate, params });

const checkPatientArrival = async (navigate, params) =>
  requestApi({ url: '/api/appointment/check_patient_arrival', navigate, params });

const markPatientArrival = async (navigate, params) =>
  requestApi({ url: '/api/appointment/mark_patient_arrival', navigate, params });

export {
  getAppointments,
  getAppointment,
  getAppointmentv2,
  updateAppointment,
  updateAppointmentV2,
  creationActionAppointment,
  remindAppointment,
  acceptAppointment,
  cancelAppointment,
  remindAppointmentMultiple,
  creationActionAppointmentMultiple,
  cancelMultipleAppointments,
  getAptTimelineDetails,
  completeAppointment,
  checkPatientArrival,
  markPatientArrival
};
