import React, { useRef, useState } from 'react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import '../Custom.scss';
import { FamilyHistory as initialValue } from '../CustomFormInitialStates';
import FamilyHistoryRow from './FamilyHistoryRow';
import Container from '../components/Container';
import Header from '../components/Header';
import useCustomForm from '../lib/useCustomForm';
import DeleteFormModal from '../components/DeleteFormModal';
import { iaRa } from 'lib/helpers/utility';

const FamilyHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  formId = null,
  showNarrative = false,
  onChange,
  setItemModal = () => {}
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [items, setItems] = useState(initialValue);

  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.allergies?.advancedForm
      : true;
  const textAreaRef = useRef();

  const [itemToDelete, setItemToDelete] = useState(null);

  const rows = 'row';

  const props = {
    itemToDelete,
    setItemToDelete,
    formId,
    setItems,
    setModal: setItemModal,
    fromCheckin: true,
    items,
    rows,
    title: 'Family History',
    alertTitle: 'Family History',
    initialValue,
    textAreaRef,
    defaultValue,
    onChange,
    setCurrentFormData
  };

  const { handleAddRow, handleOnChange, handleNoDetails, onDelete, syncItems, length } =
    useCustomForm(props);

  return (
    <div className="FamilyHistory CustomForms">
      <input type="hidden" value={JSON.stringify(items)} ref={forwardedRef} />

      <Header
        title="Family History"
        noDetailsLabel="Do you have any notable family history?"
        isAdvancedForm={isAdvancedForm}
        noDetails={items?.noDetails}
        fromCheckin
        addRow={{ onClick: handleAddRow }}
        emptyState={{
          title: 'No family medical history reported',
          subtitle:
            'Family history includes any medical conditions that run in your family. Please let us know if you have any family medical history to report.',
          label: 'Do you have any family history not reported to our practice?'
        }}
        handleNoDetails={handleNoDetails}>
        <div className="flex w-full flex-col items-center gap-y-4">
          {iaRa(items?.[rows])?.map((item, idx) => (
            <Container
              key={item.id}
              fromCheckin
              onDelete={() => onDelete(item?.id)}
              number={idx + 1}>
              <FamilyHistoryRow
                key={item.id}
                index={idx}
                id={item?.id}
                fromCheckin
                onChange={handleOnChange}
                length={length}
                {...item}
              />
            </Container>
          ))}
        </div>
      </Header>

      <DeleteFormModal syncItems={syncItems} {...props} />
    </div>
  );
};

export default FamilyHistory;
