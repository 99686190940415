import React, { useState } from 'react';
import cs from 'classnames';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import {
  checkFractionString,
  getFieldValue,
  setFieldValue,
  getPermissionGroupKey,
  getGroupCounts,
  getDependents
} from './lib/helper';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';
import Dependents from './Dependents';
import Icon from 'components/shared/Icon/Icon';
import Tippy from '@tippyjs/react';

const PermissionTitle = ({
  permissions,
  title,
  description,
  isSelected,
  setSelected,
  permissionKey,
  highlight,
  actions,
  isFeature,
  canEdit
}) => {
  const { formik, actionsEnabled, setActionsEnabled, setActionDependents, setActionDependencies } =
    useEditRoleContext();
  const [showDependentsModal, setShowDependentsModal] = useState(false);
  const [groupDependents, setGroupDependents] = useState([]);
  const handleComponentOnClick = () => {
    setSelected(permissionKey);
  };
  const allActionsEnabled = checkFractionString(actionsEnabled[permissionKey]);

  const turnOffDependents = () => {
    const b = actionsEnabled[permissionKey].split('/')[1];
    let ratioUpdates = {
      [permissionKey]: `0/${b}`
    };

    actions.forEach((action) => {
      const fieldValue = action.field;
      if (getFieldValue(formik, fieldValue)) {
        setFieldValue(formik, fieldValue, false);
        const actionDependents = getDependents(permissions, fieldValue);
        if (actionDependents?.length > 0) {
          for (const actionDependant of actionDependents) {
            setFieldValue(formik, actionDependant, false);
          }
        }
      }
    });

    const permissionGroupKeys = groupDependents?.map((item) =>
      getPermissionGroupKey(permissions, item)
    );

    const groupCounts = getGroupCounts(permissionGroupKeys);
    const groupCountsKeys = Object.keys(groupCounts);

    for (const groupCountsKey of groupCountsKeys) {
      const permissionGroupActionRatio = actionsEnabled[groupCountsKey];
      let [c, d] = permissionGroupActionRatio.split('/').map((item) => Number.parseInt(item, 10));
      c = c - groupCounts[groupCountsKey];
      ratioUpdates = { ...ratioUpdates, [groupCountsKey]: `${c}/${d}` };
    }

    setActionsEnabled({
      ...actionsEnabled,
      ...ratioUpdates
    });

    setShowDependentsModal(false);
  };

  const onClickHandler = () => {
    const b = actionsEnabled[permissionKey].split('/')[1];
    let ratioUpdates = {
      [permissionKey]: allActionsEnabled ? `0/${b}` : `${b}/${b}`
    };

    if (!allActionsEnabled) {
      let newActionDependencies = {};
      let enabledDependencies = [];
      actions.forEach((action) => {
        const fieldValue = action.field;
        if (!getFieldValue(formik, fieldValue)) {
          const currentActionDependencies = action.dependencies?.filter(
            (dependency) => !getFieldValue(formik, dependency)
          );
          if (currentActionDependencies?.length > 0) {
            newActionDependencies[fieldValue] = currentActionDependencies;
            for (const dependency of currentActionDependencies) {
              if (!getFieldValue(formik, dependency)) {
                setFieldValue(formik, dependency, true);
                if (!enabledDependencies.includes(dependency)) {
                  enabledDependencies.push(dependency);
                }
              }
            }
          }
          setFieldValue(formik, fieldValue, true);
        }
      });
      const permissionGroupKeys = enabledDependencies.map((item) =>
        getPermissionGroupKey(permissions, item)
      );
      const groupCounts = getGroupCounts(permissionGroupKeys);
      const groupCountsKeys = Object.keys(groupCounts);
      setActionDependencies(newActionDependencies);
      for (const groupCountsKey of groupCountsKeys) {
        const permissionGroupActionRatio = actionsEnabled[groupCountsKey];
        let [c, d] = permissionGroupActionRatio.split('/').map((item) => Number.parseInt(item, 10));
        c = c + groupCounts[groupCountsKey];
        ratioUpdates = { ...ratioUpdates, [groupCountsKey]: `${c}/${d}` };
      }
      setActionsEnabled({
        ...actionsEnabled,
        ...ratioUpdates
      });
    } else {
      let hasDependents = false;
      actions.forEach((action) => {
        const fieldValue = action.field;
        if (getFieldValue(formik, fieldValue)) {
          let dependents = getDependents(permissions, fieldValue);
          dependents = dependents.filter((dependent) => getFieldValue(formik, dependent));
          if (dependents.length > 0) {
            hasDependents = true;
          }
        }
      });

      if (hasDependents) {
        let newDependencyOf = {};
        let newGroupDependents = [];
        actions.forEach((action) => {
          const fieldValue = action.field;
          if (getFieldValue(formik, fieldValue)) {
            let dependents = getDependents(permissions, fieldValue);
            dependents = dependents.filter((dependent) => getFieldValue(formik, dependent));
            if (dependents.length > 0) {
              for (const dependent of dependents) {
                if (getFieldValue(formik, dependent)) {
                  if (!newGroupDependents.includes(dependent)) {
                    newGroupDependents.push(dependent);
                  }
                }
              }
              newDependencyOf[fieldValue] = dependents;
            }
          }
        });
        setActionDependents(newDependencyOf);
        setGroupDependents(newGroupDependents);
        setShowDependentsModal(true);
      } else {
        actions.forEach((action) => {
          const fieldValue = action.field;
          setFieldValue(formik, fieldValue, false);
        });

        setActionsEnabled({
          ...actionsEnabled,
          ...ratioUpdates
        });
      }
    }
  };

  return (
    <>
      <div
        className={cs(
          'flex h-fit w-[360px] flex-col items-start justify-start gap-4 self-stretch rounded-md p-4',
          isSelected ? 'bg-primary-50' : 'cursor-pointer'
        )}
        id={permissionKey}
        onClick={handleComponentOnClick}>
        <div className="flex h-fit flex-col items-start justify-start gap-1.5 self-stretch">
          <div className="flex items-center space-x-1 text-sm font-semibold leading-snug text-neutral-800">
            <Highlight text={title} highlight={highlight} />
            {isFeature && (
              <Tippy
                placement="top-start"
                className="tippy-dark"
                content={
                  <p className="relative px-1 pb-1 text-[12px] font-500 text-white">
                    This is a feature permission.
                    {}
                  </p>
                }>
                <div className="ml-1 flex">
                  <Icon icon="new-info-v2" size={18} />
                </div>
              </Tippy>
            )}
          </div>
          <div
            className={
              (cs('text-sm font-normal leading-snug text-neutral-700'),
              isSelected ? '' : 'opacity-50')
            }>
            <Highlight text={description} highlight={highlight} />
          </div>
        </div>
        <div className="inline-flex items-center justify-between self-stretch">
          <div
            className={cs(
              'text-sm font-medium leading-snug text-primary-900',
              isSelected ? '' : 'opacity-50'
            )}>
            {actionsEnabled[permissionKey]} permissions active
          </div>
          {isSelected && canEdit && (
            <Allowed requiredPermissions="role.update">
              <div
                className="flex cursor-pointer items-center justify-center gap-1.5 rounded-[34px] border-[1px] border-solid border-primary-200 px-3.5 py-1 text-sm font-medium leading-snug text-[#13b8ff]"
                onClick={onClickHandler}>
                {allActionsEnabled ? 'Disable All' : 'Enable All'}
              </div>
            </Allowed>
          )}
        </div>
      </div>
      {showDependentsModal && (
        <Confirm
          handleOpen={showDependentsModal}
          handleClose={() => setShowDependentsModal(false)}
          handleContinue={turnOffDependents}
          icon="new-patients"
          iconColor="danger"
          primaryBtnTxt="Turn off"
          title="Warning"
          message="To continue, you need to turn off all the permissions listed below. The actions that follow depend on the permission being disabled."
          variant="danger">
          <Dependents permissions={permissions} dependents={groupDependents} />
        </Confirm>
      )}
    </>
  );
};

export default PermissionTitle;
