import ColorPicker from 'components/shared/ColorPicker/ColorPicker';
import Input from 'components/shared/Input/Input';
import Allowed from 'components/shared/Permissions/Allowed';
import Select from 'components/shared/Select/Select';
import Switch from 'components/shared/Switch/Switch';

const HeaderEventForm = ({ formik, practitioners }) => {
  const { values, handleChange, setFieldValue, touched, errors } = formik;
  return (
    <>
      <div className="flex items-start gap-2">
        <ColorPicker
          color={values.color}
          onChange={(color) => setFieldValue('color', color)}
          label="Color"
        />
        <Input
          data-qa="tittle"
          label="Title"
          name="title"
          onChange={handleChange}
          value={values.title}
          error={touched.title && errors.title}
          className="flex-1"
        />
      </div>
      <Allowed requiredPermissions={['member.read']}>
        <Select
          isImage
          label="Practitioner"
          options={practitioners}
          value={values.practitioner_ids}
          inputId="practitionerSelect"
          data-qa="appointment-practitioner-select"
          className="border-neutral-100"
          placeholder="Type and select practitioner"
          onChange={(value) => setFieldValue('practitioner_ids', value)}
          isMulti
        />
      </Allowed>
      <div className="item-center flex gap-2">
        <label className="m-0 flex text-sm font-500  text-neutral-800">All day</label>
        <Switch
          checked={values.all_day}
          onChange={() => setFieldValue('all_day', !values.all_day)}
          data-qa="switch-all-day"
        />
      </div>
    </>
  );
};

export default HeaderEventForm;
