import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useClaims } from 'lib/hooks/queries/claims/useClaims';

import ButtonActions from 'components/practice/BeyondBilling/Claims/components/ButtonActions';
import {
  CLAIMS_SWITCH_ITEMS,
  getColDefs,
  getPatientDefaultFilters
} from 'components/practice/BeyondBilling/Claims/configs';
import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

const getFinalColDefs = (name, timezone) => {
  const colDefs = [...getColDefs(name, timezone)];

  return colDefs;
};

const category = 'claims';

const PatientClaims = () => {
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const { id, claimId } = useParams();
  const navigate = useNavigate();

  const defaultFilters = getPatientDefaultFilters();

  const NEW_COL_DEFS = getFinalColDefs('patient_claims', practiceTimezone);

  return (
    <>
      <AgTablePage
        defaultColumns={NEW_COL_DEFS}
        defaultFilters={defaultFilters}
        useData={useClaims}
        name={category}
        onSelectRow={(claim) => navigate(`${claim?.id}`)}
        rowStyle={{ cursor: 'pointer' }}
        switchItems={CLAIMS_SWITCH_ITEMS}
        actions={ButtonActions}
        queryParams={{ patientId: id }}
        noTitle
      />
      {claimId && <Outlet />}
    </>
  );
};

export default PatientClaims;
