import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import state, { userState } from 'components/state';
import Button from 'components/shared/Buttons/Button';

const StartCheckinButton = () => {
  const { values } = useFormikContext();
  const [showModal, setShowModal] = useState(false);
  const [redirectLink, setRedirectLink] = useState(null);
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate();
  const [allowScroll] = useScrollBlock();
  const isAptDisabled = values.appointment_disabled;

  const onCheckIn = async () => {
    const appointmentId = values?.id;
    try {
      const { redirectTo } = await requestApi({
        url: `/api/patient/checkin/start`,
        params: {
          appointmentId,
          patientImpersonate: true
        },
        navigate
      });

      setRedirectLink(redirectTo);
      closeModal(redirectTo);
      allowScroll();
      setShowModal(true);
    } catch (error) {
      console.error(error);
    }
    localStorage.setItem('appointmentId', appointmentId);
  };

  const closeModal = (redirectTo, navigateInstant = false) => {
    if (navigateInstant) {
      navigate(redirectTo, { replace: true });
      setShowModal(false);
      setUser({
        ...{},
        logged_in: true
      });
      setPermissions({ ...permissions, practice_id: null });
      return;
    }
    const timer = setTimeout(() => {
      setShowModal(false);
      navigate(redirectTo, { replace: true });
      clearTimeout(timer);
      setUser({
        ...{},
        logged_in: true
      });
      setPermissions({ ...permissions, practice_id: null });
    }, 3000);
  };

  const handleClose = () => closeModal(redirectLink, true);

  const Footer = () => (
    <div className="flex w-full justify-between">
      <Button text="Cancel" outlined color="neutral" onClick={handleClose} />
      <Button text="Continue" primary onClick={handleClose} />
    </div>
  );

  return (
    <>
      <Button
        text="Start Check-in"
        icon="new-play"
        iconColor="white"
        iconSize={15}
        onClick={onCheckIn}
        textClassName="w-max"
        className="md:ml-auto"
        size="small"
        disabled={isAptDisabled}
      />
      <Modal
        isOpen={showModal}
        handleClose={handleClose}
        title="Check in"
        bodyClassName="py-8"
        footer={<Footer />}>
        <div className="flex flex-col items-center">
          <Icon icon="new-checked" size={80} className="rounded-full shadow-[0_0_30px_#30c9ff]" />
          <p className="pt-5 text-sm">You can now hand the device over to the patient.</p>
        </div>
      </Modal>
    </>
  );
};

export default StartCheckinButton;
