import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../api/Api';
import { verifyPassword } from '../../lib/helpers/utility';
import { AlertContent } from '../shared/Alert/Alert';
import Button from '../shared/Buttons/Button';
import { withErrorBoundary } from '../shared/Error/Boundary';
import NewPassword from '../shared/NewPassword/NewPassword';
import './PasswordReset.scss';

const PasswordSet = () => {
  const [password, setPassword] = useState('');
  const [show, setShow] = useState({
    password_reset: false,
    passwords_match: 0,
    password_ok: false,
    error: '',
    loading: false
  });
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    document.title = `Myriad - Set New Password`;
    checkResetToken(token);
  }, []);

  const checkResetToken = async (reset_token) => {
    try {
      const resData = await requestApi({
        url: '/api/user/password/verify',
        params: { token: reset_token },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (redirect) navigate(redirect);
          break;
        default:
          setShow({
            ...show,
            error: error || `Unexpected error code has occurred. Please try again later.`
          });
          Honeybadger.notify(
            `file: auth/set, method: checkResetToken - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          // if (error) toast.error(error);
          // else toast.error(`Unexpected error code has occurred. Please try again later.`);
          if (redirect) {
            setTimeout(() => {
              navigate(redirect);
            }, 5000);
          }
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: auth/set, method: checkResetToken - catch, error: ${error ?? 'Theres been an error'}`
      );
      setTimeout(() => {
        navigate('/password/request');
      }, 5000);
    }
  };

  const requestSetNewPassword = async () => {
    try {
      const anotherPasswordCheck = verifyPassword(password);
      if (!anotherPasswordCheck.password_ok) {
        setShow({ ...show, ...anotherPasswordCheck, loading: false });
        throw new Error(`Password doesn't meet requirements`);
      }
      setShow((ps) => ({ ...ps, loading: true }));
      const resData = await requestApi({
        url: '/api/user/password/set',
        params: {
          token,
          password
        },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          toast.success(`Successfully reset password`);
          setShow({ ...show, password_reset: true, loading: false });
          redirect &&
            setTimeout(() => {
              navigate(redirect);
            }, 3000);
          break;
        case 1:
          toast.success(`Password was not reset.`);
          toast.error(error);
          break;
        default:
          Honeybadger.notify(
            `file: auth/set, method: requestSetNewPassword - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: auth/set, method: requestSetNewPassword - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
    setShow((ps) => ({ ...ps, loading: false }));
  };

  if (show.password_reset) {
    return (
      <div>
        <div className="mb-4 text-2xl text-primary-900">Set New Password</div>
        <p>
          New password has been set! If you're not automatically redirected to login please navigate
          to login.
        </p>
        <Button
          text="Login"
          primary
          className="mx-auto mt-5 w-full"
          onClick={() => navigate('/login')}
          data-qa="password-reset-login-btn"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 text-2xl text-primary-900">Set New Password</div>
      <h4 className="text-sm font-500">Please enter your new desired password.</h4>
      {show.error && (
        <AlertContent
          className="mt-[1rem]"
          color="warning"
          message={show.error}
          data-dd-privacy="allow"
          data-public
        />
      )}
      <NewPassword
        setPassword={setPassword}
        setPasswordOk={(v) => setShow((ps) => ({ ...ps, password_ok: v }))}
      />
      <Button
        onClick={requestSetNewPassword}
        disabled={!show.password_ok}
        className="mt-[0.8rem] w-full"
        text="Reset password"
        loading={show.loading}
        color="primary"
        data-qa="password-reset-reset-password-btn"
      />
    </div>
  );
};

export default withErrorBoundary(PasswordSet);
