import { io } from 'lib/helpers/utility';

export const editorConfig = (media = {}) => {
  const defaultConfig = {
    modules: {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { header: '3' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }]
      ]
    },
    formats: [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'color',
      'background',
      'blockquote',
      'list',
      'bullet',
      'indent'
    ]
  };

  if (io(media.toolbar)) defaultConfig.modules.toolbar = media.toolbar;
  if (io(media.formats)) defaultConfig.formats = media.formats;

  return defaultConfig;
};
