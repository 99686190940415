import React from 'react';

import cs from 'classnames';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';

import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PayerDetails = (params) => {
  const { device } = useUIContext();
  return (
    <>
      {ia(params?.data?.payerAltNames) && (
        <div className="">
          <table>
            <tbody>
              <tr>
                <td
                  className={cs(
                    'pl-[40px] align-top font-700',
                    device === 'tablet-sm'
                      ? 'w-[170px]'
                      : device === 'tablet'
                        ? 'w-[298px]'
                        : device === 'desktop'
                          ? 'w-[440px]'
                          : device === 'laptop'
                            ? 'w-[340px]'
                            : 'w-[410px]'
                  )}>
                  This payer is also known as:
                </td>
                <td className="flex h-[250px] flex-col gap-2 overflow-y-scroll pr-[40px]">
                  {params?.data?.payerAltNames.map((payer, index) => (
                    <div key={index}>{payer}</div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(PayerDetails);
