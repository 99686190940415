export const displayDiagnosis = (claim) => {
  let diagnosisArr = [];

  for (let i = 1; i <= 12; i++) {
    if (claim[`diag_${i}`]) diagnosisArr.push(claim[`diag_${i}`]);
  }

  return String(diagnosisArr);
};

export const sumAdjustment = (procedures) => {
  const total = procedures?.reduce((acc, procedure) => {
    const procedureTotal = procedure?.adjustment?.reduce(
      (sum, { amount }) => sum + Number(amount || 0) * 100,
      0
    );

    return acc + (procedureTotal || 0);
  }, 0);

  return total;
};
