export const getFinalProductObjShape = (values) => ({
  code: values.code,
  name: values.name,
  note: values.note,
  expiration_date: values.expiration_date,
  status: values.status,
  amount_cents: Number(values.amount_cents),
  cost_cents: Number(values.cost_cents),
  amount_cents_with_tax: Math.floor(Number(values.amount_cents_with_tax)),
  state_tax_rate: Number(values.state_tax_rate),
  local_tax_rate: Number(values.local_tax_rate),
  quantity: Number(values.quantity),
  quantity_units: values.quantity_units,
  image: values.image,
  lot: values.lot,
  category_id: values.category_id,
  color: values.color
});
