import React from 'react';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';

import { showAlert } from 'components/shared/Alert/Alert';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from '../../../../../lib/helpers/utility';
import { userState as user_state, clinicalNote as clinicalNoteState } from '../../../../state';
import PreviewNoteModal from '../PreviewNote/PreviewNoteModal';
import { everyoneSigned, updateDocSignatures } from './helpers/documentSignatureHelpers';

const SignLockActionComponent = ({
  documentSignatures,
  onLockNote = () => {},
  updateDocumentSignMutation,
  confirmSignLock,
  showConfirmSignLock,
  hasUserSigned,
  clinicalNoteContext = null,
  isFetching = false,
  fromOutsideClinicalNote = false
}) => {
  const cnFromContext = useClinicalNoteContext();
  const clinicalNoteValue = useRecoilValue(clinicalNoteState);
  const clinicalNote = cnFromContext ? clinicalNoteValue : clinicalNoteContext;
  const userState = useRecoilValue(user_state);

  const isOldCNLocked = !ia(documentSignatures) && clinicalNote?.locked;
  const isOldCNNotLocked = !ia(documentSignatures) && !clinicalNote?.locked;

  const isNewCNLocked = hasUserSigned;
  const isNewCNNotLocked = !hasUserSigned;

  if (isOldCNLocked) {
    return (
      <Confirm
        primaryBtnTxt="Confirm"
        title="Unlock clinical note?"
        icon="new-lock"
        message={`It is the responsibility of the provider and associates to chart each encounter in an accurate and timely manner. Any changes to a locked note using "unlock" functionality are documented electronically using version control, for your protection. When unlocking a note, please consider any material changes be formalized through an addendum to the note for tracking purposes.`}
        handleContinue={async () => {
          await onLockNote({ lockStatus: false });
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }
  if (isOldCNNotLocked) {
    return (
      <Confirm
        primaryBtnTxt="Confirm"
        title="Sign and lock clinical note?"
        icon="new-lock"
        message={`
          Are you sure you want to unlock the clinical note?
        `}
        handleContinue={async () => {
          await onLockNote({ lockStatus: true });
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }

  if (isNewCNNotLocked) {
    return (
      <PreviewNoteModal
        title="Sign & Lock Clinical Note"
        key="sign_lock_action_modal"
        isOpen={confirmSignLock}
        isExport={cnFromContext?.isExport}
        setIsExport={cnFromContext?.setIsExport}
        handleClose={() => showConfirmSignLock(false)}
        signAndLockProps={{
          fromOutsideClinicalNote: !!fromOutsideClinicalNote,
          signAndLockMode: true,
          documentId: clinicalNote?.id,
          documentTypes: ['clinical_note'],
          documentSignatures,
          onDocumentSignSuccess: async () => {
            await onLockNote({
              lockStatus: everyoneSigned({ documentSignatures, userState }),
              componentsOrder: cnFromContext?.selected
            });
          },
          showInPopover: false,
          isFetching
        }}
      />
    );
  }

  if (isNewCNLocked) {
    return (
      <Confirm
        primaryBtnTxt="Unlock"
        title="Unlock Clinical Note"
        icon="new-lock"
        message="It is the responsibility of the provider and associates to chart each encounter in an accurate and timely manner. Any changes to a locked note using unlock functionality are documented electronically using version control, for your protection. When unlocking a note, please consider any material changes be formalized through an addendum to the note for tracking purposes. Are you sure you want to unlock the clinical note?"
        handleContinue={async () => {
          if (!ia(documentSignatures)) {
            showAlert({
              title: 'Something went wrong please refresh!',
              color: 'danger'
            });
            return;
          }

          const canProceed = await updateDocSignatures({
            updateDocumentSignMutation,
            documentSignatures,
            userState
          });

          if (canProceed) {
            await onLockNote({ lockStatus: false });
          }
        }}
        handleOpen={confirmSignLock}
        handleClose={() => showConfirmSignLock(false)}
      />
    );
  }

  return null;
};

export default SignLockActionComponent;
