import Actions from '../Actions';

export const COLUMN_DEFS = [
  {
    field: 'label',
    headerName: 'NDC Code',
    sortable: false,
    flex: 1
  },
  {
    field: 'title',
    headerName: 'Proprietary Name',
    sortable: false,
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const NDC_CODE_TYPES = [
  {
    value: 'brand_name',
    label: 'Proprietary Name'
  },
  {
    value: 'application_number',
    label: 'Application Number / Monograph ID'
  },
  {
    value: 'generic_name',
    label: 'Non Proprietary Name'
  },
  {
    value: 'package_ndc',
    label: 'NDC Code'
  },
  {
    value: 'labeler_name',
    label: 'Labeler'
  }
];
