import React from 'react';
import Icon from '../../../../shared/Icon/Icon';
import cs from 'classnames';

const ArrowToggle = ({ open, setOpen, defaultArrow = 'left' }) => {
  return (
    <div
      className={cs(
        'flex items-center justify-center w-[18px] cursor-pointer h-[18px] border !border-primary-200 z-30 bg-primary-50 rounded-[2px] border-solid absolute',
        defaultArrow === 'left' ? '-right-[9px] top-[64px]' : '-left-[9px] top-[18px]'
      )}
      onClick={setOpen}>
      <Icon
        icon="new-chevron-left"
        size="12px"
        color="primary"
        stroke
        className={cs(
          'duration-200 cursor-pointer',
          defaultArrow === 'right' && 'rotate-180',
          defaultArrow === 'right' && !open && 'rotate-0',
          !open && 'rotate-180 '
        )}
      />
    </div>
  );
};

export default ArrowToggle;
