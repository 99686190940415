import cs from 'classnames';

import BillToSelect from './BillToSelect';
import Comments from './Comments';
import Diagnosis from './Diagnosis';
import DrawAtServiceCenterCheckbox from './DrawAtServiceCenterCheckbox';
import DrawDateTime from './DrawDateTime';
import GuarantorSelect from './GuarantorSelect';
import OrderCollector from './OrderCollector';
import OrderSampleType from './OrderSampleType';
import PracticeSelect from './PracticeSelect';
import ProviderSelect from './ProviderSelect';

const OrderDetails = () => {
  return (
    <div className="order-2 rounded-lg bg-primary-50 col-span-3 ">
      <Diagnosis menuPlacement="bottom" parentClassName="p-4 pb-3" />

      <div className={cs('grid grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4  gap-3 p-4 pt-0')}>
        <div className="grid grid-cols-2 gap-3 col-span-1 xl:row-span-1">
          <ProviderSelect className="col-span-2" />

          <BillToSelect className="col-span-2" />
        </div>

        <div className={cs('grid grid-cols-2 gap-3 col-span-1 xl:row-span-1')}>
          <PracticeSelect className="col-span-2" />

          <GuarantorSelect className="col-span-2" />
        </div>

        <Comments className="col-span-2 xl:col-span-1 xl:row-span-2" />

        <div
          className={cs(
            'grid grid-cols-6 grid-rows-2 gap-3 col-span-2 xl:col-span-3 xl:grid-rows-1 xxl:row-span-2 xxl:col-span-1'
          )}>
          <DrawDateTime className="sm:col-span-4 col-span-3 xl:col-span-2 xxl:col-span-4" />
          <DrawAtServiceCenterCheckbox className="sm:col-span-2 col-span-3 xl:col-span-1 xxl:col-span-2" />
          <OrderCollector className="col-span-3 xl:col-span-2 xxl:col-span-4" />
          <OrderSampleType className="col-span-3 xl:col-span-1 xxl:col-span-2" />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
