import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import { detectDocument } from 'api/Document';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useUIContext } from 'lib/context/UIContext/UIContext';

import { showAlert } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import ImagePicker from 'components/shared/Picker/ImagePicker';

const PersonalIdUpload = ({ breadcrumb, imageEdit, setImageEdit, appointmentId, kiosk, user }) => {
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState({
    front: user?.personal_id_image && user?.personal_id_image?.front,
    back: user?.personal_id_image && user?.personal_id_image?.back
  });

  useEffect(() => {
    if (!handle.front || !handle.back) {
      setHandle((p) => ({
        front: p?.front || user?.personal_id_image?.front,
        back: p?.back || user?.personal_id_image?.back
      }));
    }
  }, [user?.personal_id_image]);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { device } = useUIContext();

  const handleContinue = async (handle) => {
    setLoading(true);

    const response = await requestApi({
      url: `/api/filestack/personal_id_${imageEdit}`,
      params: {
        handle,
        userId: user.id,
        appointmentId
      },
      navigate
    });

    if (response[imageEdit] > 0) {
      queryClient.setQueryData(['checkinUser'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          user: {
            ...oldData.user,
            personal_id_image: handle
          }
        };
      });

      if (breadcrumb.currentStep === 'front') {
        breadcrumb?.goToStep('back');
      } else {
        setImageEdit(null);
      }
    } else {
      Honeybadger.notify(`There was an error uploading the image.`);
      showAlert({
        message: 'Uploading image failed. Please try again',
        color: 'danger',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
    setLoading(false);
  };

  const [coords, setCoords] = useState();
  const [processing, setProcessing] = useState({ open: false });

  const handleDelete = async (type) => {
    await requestApi({
      url: `/api/filestack/personal_id_${type}`,
      params: {
        handle: { ...user?.personal_id_image, [type]: null },
        userId: user?.id
      },
      navigate
    });

    queryClient.setQueryData(['checkinUser'], (oldData) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        user: {
          ...oldData.user,
          personal_id_image: {
            ...oldData.user?.personal_id_image,
            [type]: null
          }
        }
      };
    });
  };

  const handleSave = async (handle, type) => {
    setLoading(true);

    const newHandle = {
      front: user?.personal_id_image && user?.personal_id_image?.front,
      back: user?.personal_id_image && user?.personal_id_image?.back
    };

    newHandle[type] = handle;

    setHandle(newHandle);

    const document = await detectDocument(navigate, {
      handle
    });

    setCoords((prevCords) => ({
      ...prevCords,
      [type]: {
        ...document?.coords,
        version: prevCords?.[type]?.version ? prevCords?.[type]?.version + 1 : 1
      }
    }));

    setLoading(false);
  };

  const handleProcess = async (image, type) => {
    setProcessing({ open: true });

    const filestackClient = await getFilestackClient(navigate);
    const croppedImage = await filestackClient.upload(image);

    const newHandle = {
      front: user?.personal_id_image && user?.personal_id_image?.front,
      back: user?.personal_id_image && user?.personal_id_image?.back
    };

    newHandle[type] = croppedImage?.handle;

    handleContinue(newHandle);
    setCoords(null);
    setHandle(newHandle);
    setProcessing({ open: false });
  };

  const handleClose = () => {
    setImageEdit(null);
    setCoords(null);
  };

  return (
    <>
      <Modal
        handleOpen={!!imageEdit}
        customHeader={<Breadcrumb hasBackButton breadcrumb={breadcrumb} />}
        handleClose={handleClose}
        className="h-full w-full !rounded-none bg-neutral-800"
        title="Add Image"
        slideFromRight={device === 'mobile'}
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          {processing?.open ? (
            <div className="my-4 flex flex-col items-center animate-pulse text-center">
              <Icon icon="new-cards-lg" className=" mt-8 mb-8" size={164} />
              <p className="text-lg font-medium text-white">Card Uploading</p>
              <p className="mt-2 px-16 font-300 text-sm  text-neutral-100">
                Please wait a moment until <br></br> the document is processed...
              </p>
            </div>
          ) : (
            <>
              <p className="text-lg font-medium text-white">
                {user?.personal_id_image?.[imageEdit] && !loading
                  ? imageEdit === 'front'
                    ? 'The front of your ID'
                    : 'The back of your ID'
                  : `Upload the ${imageEdit} of your ID`}
              </p>
              <p className="mt-2 px-16 font-300 text-sm  text-neutral-100">
                {user?.personal_id_image?.[imageEdit] && !loading
                  ? 'Make sure the picture is clear'
                  : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
              </p>
              <ImagePicker
                key={imageEdit}
                label={imageEdit === 'front' ? 'Front of card' : 'Back of card'}
                handle={handle?.[imageEdit]}
                handleDelete={handleDelete}
                name={imageEdit}
                kiosk={kiosk}
                documentLoading={loading}
                coords={coords?.[imageEdit]}
                processing={processing?.open}
                loading={loading}
                resetHandle={() => setHandle({ ...handle, [imageEdit]: null })}
                handleContinue={async (image, skip) => {
                  if (skip) {
                    if (breadcrumb.currentStep === 'front') {
                      breadcrumb?.goToStep('back');
                    } else {
                      setImageEdit(null);
                    }
                  } else {
                    await handleProcess(image, imageEdit);
                  }
                }}
                onCapture={handleSave}
              />
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default withErrorBoundary(PersonalIdUpload);
