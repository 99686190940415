import React, { useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { useRecoilValue } from 'recoil';

import { useEraContext } from 'lib/context/EraContext/EraContext';

import { currentPractice } from 'components/practice/practiceState';

import { formatMatchedClaims } from './lib/formatMatchedClaims';
import {
  DEFAULT_COLUMN_DEFS_BOLD,
  MATCHED_CLAIMS_COLUMN_DEFS,
  getRowClassRules
} from './lib/helpers';

const MatchedClaims = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const gridRef = useRef(null);

  const { era } = useEraContext();

  const onCellMouseOver = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(event.data?.claimId);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const onCellMouseOut = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(null);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const currPractice = useRecoilValue(currentPractice);
  const timezone = currPractice?.timezone;

  const rowData = useMemo(() => formatMatchedClaims({ claims: era?.claims }), [era?.claims]);

  const columnDefs = useMemo(() => MATCHED_CLAIMS_COLUMN_DEFS(timezone), [timezone]);

  return (
    <div className="ag-theme-quartz shadow-none">
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={DEFAULT_COLUMN_DEFS_BOLD}
        rowData={rowData}
        domLayout="autoHeight"
        onCellMouseOver={onCellMouseOver}
        onCellMouseOut={onCellMouseOut}
        rowClassRules={getRowClassRules('', hoveredId)}
        suppressRowHoverHighlight
      />
    </div>
  );
};

export default MatchedClaims;
