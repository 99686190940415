import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { getInternalInvoiceByIds, getOldExternalsByAppointmentIds } from 'api/Billing';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import { getInvoicesFromSelectedRows } from '../lib/utils';

import InvoicePreview from './InvoicePreview';
import SendEmailFooter from './SendEmailFooter';

const ExternalInvoicePreview = ({ item: invoice, isOpen, handleClose }) => {
  const [finalGroupInvoices, setFinalGroupInvoices] = useState([]);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);

  const printRef = useRef();
  const navigate = useNavigate();

  const getExternalInvoiceData = async () => {
    const clickedInvoice = invoice;
    let finalInvoices = [];

    if (!clickedInvoice?.internal_invoice_ids) {
      const { invoices = [] } = await getOldExternalsByAppointmentIds(navigate, {
        appointment_ids: clickedInvoice?.appointment_ids || []
      });
      finalInvoices = invoices;
    } else {
      const { invoices = [] } = await getInternalInvoiceByIds(navigate, {
        internal_invoice_ids: clickedInvoice?.internal_invoice_ids || []
      });
      finalInvoices = invoices;
    }
    if (!finalInvoices.length) {
      showAlert({
        title: 'Attention!',
        message: "Invoice you're trying to preview does not exist",
        color: 'warning'
      });
      return;
    }

    const finalGroupInvoices = getInvoicesFromSelectedRows(finalInvoices);
    setFinalGroupInvoices(finalGroupInvoices);
    setInvoiceModalVisible(true);
  };

  useEffect(() => {
    if (invoice) getExternalInvoiceData();
  }, [invoice]);

  return (
    <Modal
      slideFromRight
      isOpen={isOpen && invoiceModalVisible}
      title="Create Invoice"
      isLarge
      handleClose={handleClose}
      headButton={
        <div className="flex w-full justify-end">
          <ReactToPrint
            trigger={() => (
              <Button
                color="primary-900"
                size="small"
                type="link"
                text="Print"
                icon="new-printer-bulk"
                className="text-primary-900"
              />
            )}
            content={() => printRef?.current}
          />
        </div>
      }
      footer={
        <SendEmailFooter
          handleClose={handleClose}
          finalGroupInvoices={finalGroupInvoices}
          externalInvoices={[invoice]}
        />
      }>
      <InvoicePreview
        ref={printRef}
        selectedRows={finalGroupInvoices}
        externalInvoices={[invoice]}
      />
    </Modal>
  );
};

export default ExternalInvoicePreview;
