import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import {
  LONGITUDINAL_ENCOUNTER_MESSAGES,
  LONGITUDINAL_ENCOUNTER_TYPES
} from 'components/shared/Forms/Custom/Provider/components/lib/constants';
import Icon from 'components/shared/Icon/Icon';

const VitalNoteHeader = ({ title, children }) => {
  return (
    <div className="!sticky top-0 border border-l-0 border-r-0 border-t-0 border-solid border-neutral-100">
      <div className="flex-between flex w-full items-center bg-white">
        <div className={cs('flex p-5 bg-white py-3.5 shadow-[4px_0px_16px_0px_#004F6B0A]')}>
          <Tippy
            content={LONGITUDINAL_ENCOUNTER_MESSAGES(title?.trim()?.replace(/\s+/g, ''))}
            className="tippy-dark">
            <div>
              <Icon icon={LONGITUDINAL_ENCOUNTER_TYPES[title?.trim()?.replace(/\s+/g, '')]} />
            </div>
          </Tippy>
          <p className="ml-1 text-base font-500 text-neutral-800 lg:whitespace-pre xl:whitespace-pre">
            {title}
          </p>
        </div>
        <div className="ml-auto !px-4">{children}</div>
      </div>
    </div>
  );
};
export default VitalNoteHeader;
