import React, { useState } from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { withErrorBoundary } from 'components/shared/Error/Boundary';

import useDisplaySettings from '../../../lib/hooks/useDisplaySettings';
import Icon from '../../shared/Icon/Icon';
import { userState as user_state } from '../../state';

import SecondarySidebarList from './SecondarySidebarList';

const SecondarySidebar = ({ list, target, title }) => {
  const userState = useRecoilValue(user_state);
  const [expanded, setExpanded] = useState(
    userState?.display_settings?.sidebars?.[target]?.expanded ?? true
  );

  const { saveDisplaySettings } = useDisplaySettings(userState);

  const handleExpanded = () => {
    saveDisplaySettings({
      ...userState.display_settings,
      sidebars: {
        ...userState.display_settings?.sidebars,
        [target]: {
          ...userState.display_settings?.sidebars?.[target],
          expanded: !expanded
        }
      }
    });
    setExpanded(!expanded);
  };

  return (
    <div
      className={cs(
        'z-10 w-6 bg-white !py-4 px-1 shadow-small duration-200 ease-out',
        expanded && 'w-[230px] min-w-[230px] !px-[10px]'
      )}>
      <div className="h-full !pb-8">
        <Icon
          icon={expanded ? 'new-double-chevron-left' : 'new-arrow-right'}
          className="flex cursor-pointer justify-end"
          onClick={handleExpanded}
        />
        <SecondarySidebarList list={list} expanded={expanded} target={target} title={title} />
      </div>
    </div>
  );
};

export default withErrorBoundary(SecondarySidebar);
