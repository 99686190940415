import React from 'react';
import cs from 'classnames';
import { useQuery } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import TH from 'components/shared/Receipt/components/table/TH';
import { formatDate, formatTime, ia } from 'lib/helpers/utility';

const ReceiptUpcomingAppointments = ({ patientId, timezone }) => {
  const limit = 3;

  const { data } = useQuery({
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryFn: getUpcomingAppointments,
    queryKey: ['upcomingAppointments', { patientId }]
  });

  const { appointments, upcomingCount } = data || {};

  async function getUpcomingAppointments() {
    if (!patientId) return;

    const response = await requestApi({
      url: '/api/practice/charts/appointments',
      params: { patientId, limit, withCount: true, status: ['approved'] }
    });

    return response;
  }

  const offsetUpcomingCount = (upcomingCount || 0) - limit;

  return (
    ia(appointments) && (
      <div className="flex flex-col overflow-hidden rounded-[12px] !border-[0.6px] border-solid border-neutral-200 !px-5 pb-[2px] shadow-[0px_1px_3px_0px_rgba(25,33,61,0.05),0px_2px_8px_0px_rgba(25,33,61,0.04)]">
        <p className="!pb-1 pt-[14px] text-sm font-700 text-primary-900">Upcoming appointments</p>

        <table className="text-center">
          <thead>
            <tr className="border-[0] border-b-[0.6px] border-solid border-neutral-200">
              <TH label="Date" align="start" />
              <TH label="Time" align="center" />
              <TH label="Provider" align="end" />
            </tr>
          </thead>

          <tbody>
            {appointments.map((item) => {
              const { id, starts_at, practitioner } = item || {};

              return (
                <tr
                  key={id}
                  className={cs(
                    'border-[0] border-b-[0.6px] border-solid border-neutral-200 hover:!bg-transparent',
                    offsetUpcomingCount < 0 && 'last:!border-0'
                  )}>
                  <td className="w-2/5 !px-0 !py-2 text-left align-middle text-xs font-600 leading-3 text-neutral-900">
                    {formatDate(starts_at, timezone)}
                  </td>
                  <td className="!px-0 !py-2 align-middle text-xs font-600 text-neutral-900">
                    {formatTime(starts_at, timezone)}
                  </td>
                  <td className="!px-0 !py-2 text-right align-middle text-xs font-600 text-neutral-900">
                    {practitioner?.fullName}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {offsetUpcomingCount > 0 && (
          <p className="!py-2 text-xs leading-[12px] text-primary-600">
            + {offsetUpcomingCount} upcoming appointments
          </p>
        )}
      </div>
    )
  );
};

export default ReceiptUpcomingAppointments;
