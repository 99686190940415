import { showAlert } from 'components/shared/Alert/Alert';
import { ia, iaRa } from 'lib/helpers/utility';
import { mergeTexts } from './helpers';

export const processTranscriptAudioData = ({
  data,
  setLoading = () => null,
  stopTimer = () => null,
  setTranscriptData = () => null,
  socket = null,
  setShowTranscriptModal = () => null
}) => {
  const transcriptData = data?.data;
  const transcriptError = data?.error;

  setLoading(false);

  const isTranscriptDataEmpty = !ia(transcriptData?.transcript_enhanced);

  if (isTranscriptDataEmpty) {
    stopTimer();

    showAlert({
      title: 'MyScribe AI Transcript',
      message: getErrorMessage({ transcriptData, transcriptError }),
      color: 'danger',
      duration: 10000
    });

    socket?.removeAllListeners('transcript');
    return;
  }

  setTranscriptData((prevState) => {
    let copyOldTranscriptData = [...iaRa(prevState)];

    if (ia(copyOldTranscriptData)) {
      copyOldTranscriptData[0].summary = mergeTexts({
        currentText: copyOldTranscriptData[0]?.summary,
        newText: transcriptData.summary
      });
    } else {
      transcriptData.summary = mergeTexts({
        currentText: '',
        newText: transcriptData.summary
      });
    }

    return [...copyOldTranscriptData, transcriptData];
  });

  setShowTranscriptModal(true);
  stopTimer();
  socket?.removeAllListeners('transcript');
};

const getErrorMessage = ({ transcriptData, transcriptError }) => {
  if (!transcriptData || !('summary' in transcriptData)) {
    return transcriptError
      ? 'Failed to process audio. Please try again.'
      : 'Something went wrong. Please contact support.';
  }

  if (transcriptData.summary?.trim() === '') {
    return "We couldn't hear anything clear. Please check your microphone and try again in a quieter place.";
  }

  return transcriptData.summary;
};
