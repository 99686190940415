import { formatDate, mString } from 'lib/helpers/utility';

export const getColDefs = (timezone) => [
  {
    field: 'dateofservice',
    headerName: 'Date Of Service',
    valueFormatter: ({ value }) => formatDate(value, timezone),
    filter: 'agDateColumnFilter',
    cellClass: 'no-border',
    sort: 'desc'
  },
  {
    field: 'appointment.practitioner.fullName',
    headerName: 'Provider'
  },
  {
    field: 'total_amount',
    headerName: 'Charge',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  },
  {
    field: 'amount_paid',
    headerName: 'Paid',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  },
  {
    field: 'adjustment',
    headerName: 'Adjustment',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  },
  {
    field: 'discount',
    headerName: 'Discount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  },
  {
    field: 'tax',
    headerName: 'Tax',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  },
  {
    field: 'balance',
    headerName: 'Balance',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum',
    cellClass: 'no-border'
  }
];

export const DEFAULT_COLUMN_DEFS = {};

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
