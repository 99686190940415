export default class CustomLoadingOverlay {
  init() {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div aria-live="polite" aria-atomic="true">Loading...</div>`;
  }

  getGui() {
    return this.eGui;
  }
}
