import React, { useState } from 'react';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import Input from 'components/shared/Input/Input';

const CustomContactForm = ({ formik, contactType }) => {
  return (
    <div>
      <div className="mb-4">
        <Input
          label={contactType === 'email' ? 'Email' : 'Phone Number'}
          value={formik.values.email}
          onChange={formik.handleChange}
          placeholder={
            contactType === 'email' ? 'Enter custom email address' : 'Enter custom phone number'
          }
          name="customContact"
          id="customContact"
          error={formik.touched.customContact && formik.errors.customContact}
        />
      </div>
    </div>
  );
};

export default CustomContactForm;
