import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../../lib/helpers/utility';

const TreatmentTeam = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (!ia(selectedTreatmentPlan.treatment_team)) return null;

  return (
    <div>
      <h3 className="text-base font-500 text-neutral-800 !pb-2">Treatment team</h3>
      <div className="grid gap-4">
        {selectedTreatmentPlan.treatment_team.map((item, index) => (
          <div className="grid gap-2 grid-cols-3" key={index}>
            {io(item.provider) && (
              <div>
                <p className="text-sm text-neutral-800 font-600">Provider</p>
                <p className="text-sm text-neutral-800">{item.provider.label}</p>
              </div>
            )}
            {io(item.method) && (
              <div>
                <p className="text-sm text-neutral-800 font-600">Method</p>
                <p className="text-sm text-neutral-800">{item.method.label}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TreatmentTeam;
