import React, { useEffect, useState } from 'react';

import { iaRa } from 'lib/helpers/utility';
import Input from 'components/shared/Input/Input';
import Icon from 'components/shared/Icon/Icon';
import UserItem from './UserItem';
import { usePractitionersAdmin } from 'lib/hooks/queries/practitioners/usePractitionersAdmin';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import _ from 'lodash';
import Pagination from 'components/shared/Pagination/Pagination';

const PortalUsers = ({ setSelectedOwner, selectedOwner }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const [filters, setFilters] = useState({ searchTerm: '', search: '', page: 1 });

  const {
    data = {},
    isLoading,
    isFetching
  } = usePractitionersAdmin({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit, withCount: true },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ ...filters, searchTerm: filters?.search });
    }, 500);

    return () => clearTimeout(timer);
  }, [filters?.search]);

  return (
    <>
      <div className="py-4 px-[24px] flex-1 flex gap-[14px] flex-col bg-neutral-50 overflow-scroll">
        <Input
          data-qa="searchPractitioners"
          type="string"
          placeholder="Search practitioners..."
          value={searchTerm}
          icon="new-search"
          className={` !rounded-2xl !border-secondary-400 md:w-[200px] `}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          rightText={
            searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearchTerm('');
                  setPage(1);
                }}
              />
            )
          }
        />
        {iaRa(data?.practitioner).map((practitioner) => {
          const isChecked = practitioner.id === selectedOwner?.id;
          return (
            <UserItem
              key={practitioner.id}
              {...{
                isChecked,
                item: practitioner,
                setSelectedUser: setSelectedOwner,
                f_name: practitioner.f_name,
                l_name: practitioner.l_name
              }}
            />
          );
        })}
        <Pagination
          containerClassName="flex justify-end"
          onPageChange={({ selected }) => setPage(selected + 1)}
          perPage={limit}
          totalItems={data?.count}
          page={page}
        />
      </div>
    </>
  );
};

export default PortalUsers;
