import cs from 'classnames';
import ProviderVaccines from 'components/shared/Forms/Custom/Provider/Vaccines/ProviderVaccines';
import React from 'react';

const VaccinesSection = ({
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options
}) => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-106px)] text-sm text-neutral-600 duration-200">
        <ProviderVaccines
          formId={formId}
          defaultValue={defaultValue}
          fromClinicalNote={true}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          showNarrative
          setCurrentFormData={setCurrentFormData}
          options={options}
        />
      </div>
    </div>
  );
};

export default VaccinesSection;
