import cs from 'classnames';
import ProviderAllergies from 'components/shared/Forms/Custom/Provider/Allergies/ProviderAllergies';
import React from 'react';

const AllergiesSection = ({
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = [],
  isResizable
}) => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-106px)] text-sm text-neutral-600 duration-200">
        <ProviderAllergies
          isResizable={isResizable}
          formId={formId}
          defaultValue={defaultValue}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          setCurrentFormData={setCurrentFormData}
          options={options}
        />
      </div>
    </div>
  );
};

export default AllergiesSection;
