import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getDefaultFilters } from '../../../api/Filter';

export const useDefaultFilters = () => {
  const navigate = useNavigate();
  return useQuery(['default-filters'], () => getDefaultFilters(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data.defaultFilters
  });
};
