import { orderBy } from 'lodash';

import { iaRa } from 'lib/helpers/utility';

import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';
import { calculateTotalCharge } from 'components/practice/charts/SuperBillPage/lib';

import { claimWithAdjustments } from './claimWithAdjustments';

export const claimWithInvoiceProcedures = ({ claim = {} }) => {
  if (!claim) return null;

  const invoiceProcedures = iaRa(claim?.appointment?.invoice?.procedures).filter(
    filterSuperbillClaimProcedure
  );

  claim.procedures = orderBy(invoiceProcedures, ['created_at']);

  claim.total_charge = calculateTotalCharge(claim.procedures, true);

  claim.claimAdjustments = claimWithAdjustments({
    procedures: claim?.procedures,
    payerId: claim?.payerid,
    claimAdjustments: claim?.claimAdjustments
  });

  return claim;
};
