import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import cs from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { MyScribeTemplateContextProvider } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContextProvider';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import {
  clinicalNote as clinicalNoteState,
  permissions,
  userState as user_state
} from 'components/state';

import PatientOverview from '../CurrentNote/PatientOverview/PatientOverview';
import { getTemplatesPopoverOptions } from '../Navbar/lib/helpers';
import Sidebar from '../Sidebar/Sidebar';
import SidebarPopover from '../Sidebar/SidebarPopover';

import AmbientListeningSidebar from './Templates/AmbientListening/components/AmbientListeningSidebar';
import EnhanceNoteSidebar from './Templates/EnhanceNote/components/EnhanceNoteSideBar/EnhanceNoteSidebar';
import PromptsSidebar from './Templates/MyScribeTemplate/components/PromptsSidebar';

const sidebarTemplatesContent = {
  'map-template-with-myscribe': <PromptsSidebar />,
  'enhance-current-note': <EnhanceNoteSidebar />,
  'ambient-listening': <AmbientListeningSidebar />
};

const MyScribeAI = () => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [userState] = useRecoilState(user_state);
  const [openSidebar, setOpenSidebar] = useState(
    userState?.display_settings?.clinicalNote?.toggleRSidebar?.enabled
  );
  const { is_admin } = useRecoilValue(permissions);
  const navigate = useNavigate();
  const { breadcrumb, favoriteTemplateId } = useMyScribeAIContext();
  const [pathname, setPathname] = useState();
  const { pushBreadcrumb } = breadcrumb;
  const { patient } = useOutletContext();
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [isRightSideOpen, setIsRightSideOpen] = useState(true);
  const [selectedView, setSelectedView] = useState(
    getTemplatesPopoverOptions({ navigate, userId, appointmentId, withTitle: true })[0]
  );
  const { id: userId, appointmentId } = useParams();

  useEffect(() => {
    if (!clinicalNote?.user_id || !clinicalNote?.appointment_id) return;
    setPathname(
      `/portal/charts/${clinicalNote.user_id}/clinical-notes/${
        clinicalNote.appointment_id
      }/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`
    );
  }, [clinicalNote.appointment_id, clinicalNote.user_id, favoriteTemplateId]);

  useEffect(() => {
    if (!pathname) return;
    pushBreadcrumb({
      label: 'MyScribe AI (Beta)',
      icon: <Icon icon="stars-ai" />,
      name: 'myscribe-ai',
      path: favoriteTemplateId ? pathname.replace(`/${favoriteTemplateId}`, '') : pathname
    });
  }, [pathname]);

  if (!is_admin) {
    navigate(-1);
  }

  const toggleLeftSidebar = () => setIsLeftSideOpen((prev) => !prev);
  const toggleRightSidebar = () => setIsRightSideOpen((prev) => !prev);

  const setView = (view) => {
    setSelectedView(view);
    navigate(view.link);
  };

  return (
    <MyScribeTemplateContextProvider>
      <div className="flex h-screen">
        <div
          className={cs(
            'relative fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
            { 'w-[288px]': isLeftSideOpen, 'w-0': !isLeftSideOpen }
          )}
          style={{ transition: 'width 0.2s ease' }}>
          {isLeftSideOpen && (
            <div className="flex flex-col h-full">
              <div className="flex items-center bg-white !h-[40px] px-3 border-solid border-b-[1px] border-r-0 border-neutral-100 shrink-0">
                <Icon
                  icon="new-hamburger"
                  className="!ml-1 cursor-pointer"
                  onClick={toggleLeftSidebar}
                />
                <div className="p-2 text-primary-900">Templates</div>
              </div>
              <SidebarPopover
                selectedView={selectedView}
                setSelectedView={setView}
                options={getTemplatesPopoverOptions({
                  navigate,
                  userId,
                  appointmentId,
                  withTitle: true
                })}
              />
              <div className="flex-1 overflow-y-hidden">
                {sidebarTemplatesContent[selectedView?.id]}
              </div>
            </div>
          )}
        </div>
        <div className="w-full flex-1">
          <div className="flex justify-between items-center !bg-white !h-[40px] px-3 border-solid border-b-[1px] border-l-0 border-neutral-100 w-full">
            {!isLeftSideOpen && (
              <Icon
                icon="new-hamburger"
                className="!ml-1 cursor-pointer"
                onClick={toggleLeftSidebar}
              />
            )}
            <div className="p-2 text-primary-900">MyScribe AI (Beta)</div>
            {!isRightSideOpen && (
              <Icon
                icon="new-hamburger"
                className="!ml-1 cursor-pointer"
                onClick={toggleRightSidebar}
              />
            )}
          </div>
          <PatientOverview className="bg-white" />
          <div className="w-full">
            <Outlet context={{ patient, openSidebar, setOpenSidebar }} />
          </div>
        </div>
        <div
          className={cs(
            'relative fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
            { 'w-[356px]': isRightSideOpen, 'w-0': !isRightSideOpen }
          )}
          style={{ transition: 'width 0.2s ease' }}>
          <Sidebar
            handleSidebar={{ openSidebar: isRightSideOpen, setOpenSidebar: setIsRightSideOpen }}
          />
        </div>
      </div>
    </MyScribeTemplateContextProvider>
  );
};

export default withErrorBoundary(MyScribeAI);
