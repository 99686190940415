import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { myScribeAutoMapSelectedNoteTypeState } from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import Button from 'components/shared/Buttons/Button';

import useNoteType from '../../hooks/useNoteType';

const NoteTypeButtons = () => {
  const { selectedPromptNoteType, typeOfNotes, onNoteTypeClick } = useNoteType();
  const preSelectedNoteType = useRecoilValue(myScribeAutoMapSelectedNoteTypeState);
  const getSelectedNoteCondition = (noteType) => {
    return preSelectedNoteType
      ? preSelectedNoteType === noteType?.type
      : noteType?.type === selectedPromptNoteType?.type;
  };

  return (
    <div className="z-50 flex justify-center border-0 border-b-[1px] border-solid border-neutral-100 bg-white">
      {Object.values(typeOfNotes)
        ?.slice(0, 4)
        ?.map((noteType) =>
          preSelectedNoteType ? (
            <Tippy
              content="You cannot change the note type. To create a new note, please use the “New Note” button."
              className="tippy-dark"
              key={noteType?.id}>
              <diV>
                <Button
                  key={noteType?.id}
                  onClick={() => onNoteTypeClick(noteType)}
                  className={cs(
                    'z-30 mx-2 my-2 h-[40px] w-[100px] !rounded-xl bg-white !px-[15px] !py-1 text-primary-500',
                    getSelectedNoteCondition(noteType) && '!bg-primary-50 outline-2'
                  )}
                  text={noteType.label}
                  iconColor={noteType?.type === selectedPromptNoteType?.type ? 'primary' : 'white'}
                  outlined={getSelectedNoteCondition(noteType)}
                  transparent
                  disabled={!!preSelectedNoteType}
                />
              </diV>
            </Tippy>
          ) : (
            <Button
              key={noteType?.id}
              onClick={() => onNoteTypeClick(noteType)}
              className={cs(
                'z-30 mx-2 my-2 h-[40px] w-[100px] !rounded-xl bg-white !px-[15px] !py-1 text-primary-500',
                getSelectedNoteCondition(noteType) && '!bg-primary-50 outline-2'
              )}
              text={noteType.label}
              iconColor={noteType?.type === selectedPromptNoteType?.type ? 'primary' : 'white'}
              outlined={getSelectedNoteCondition(noteType)}
              transparent
              disabled={!!preSelectedNoteType}
            />
          )
        )}
    </div>
  );
};

export default NoteTypeButtons;
