import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useMember } from 'lib/hooks/queries/member/useMember';

import {
  addKiosk,
  disableTerminalPairing,
  enableTerminalPairing,
  kioskCommand
} from 'components/../api/Kiosk';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

import EditKiosk from './EditKiosk';

export default function EditKioskModal({ kiosk, setKiosk, practiceId }) {
  const [command, setCommand] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const outletContext = useOutletContext();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    status: Yup.string().required('Status is required'),
    color: Yup.string().required('Color is required')
  });

  const { data } = useMember({ params: { practiceId } });
  const members = data?.members?.map((p) => ({
    value: p?.user_id,
    label: `${p?.f_name} ${p?.l_name}`
  }));

  const formik = useFormik({
    initialValues: {
      name: kiosk?.name,
      color: kiosk?.color,
      status: kiosk?.status,
      description: kiosk?.description,
      notify_users: members?.filter((practitioner) =>
        kiosk?.notify_users?.includes(practitioner.value)
      )
    },
    enableReinitialize: true,
    validationSchema
  });

  const closeKioskModal = () => {
    setKiosk(null);
  };

  const addNewKiosk = async () => {
    const editedKiosk = await addKiosk(navigate, {
      practiceId: outletContext?.practiceId,
      values: {
        ...formik.values,
        notify_users: formik?.values?.notify_users?.map((np) => np.value)
      },
      kioskId: kiosk?.id
    });
    closeKioskModal();
    if (editedKiosk.code == 0) {
      showAlert({ title: 'Kiosk edited successfully', color: 'success' });
    } else {
      showAlert({ title: 'There was a problem editing Kiosk!', color: 'danger' });
    }
    formik.resetForm();
    queryClient.invalidateQueries(['kiosks']);
  };

  const sendKioskCommand = async () => {
    if (!command || typeof command !== 'string') {
      showAlert({ title: 'Kiosk command required', color: 'danger' });
      return;
    }
    setLoading(true);
    await kioskCommand(navigate, { command, kioskId: kiosk?.id });
    setLoading(false);
  };

  return (
    <Modal
      title="Edit Kiosk"
      slideFromRight
      className="w-[360px]"
      handleOpen={!!kiosk}
      handleClose={closeKioskModal}
      footer={
        <div className="flex w-full justify-between">
          <Button
            text="Cancel"
            onClick={closeKioskModal}
            className="border !border-neutral-200 !bg-neutral-50 text-neutral-500 hover:!bg-neutral-100"
          />
          <Button
            text="Edit kiosk"
            onClick={addNewKiosk}
            disabled={!formik.isValid}
            color="primary"
          />
        </div>
      }>
      <EditKiosk
        practitioners={data?.members?.map(({ user_id, f_name, l_name }) => ({
          value: user_id,
          label: `${f_name} ${l_name}`
        }))}
        formik={formik}
      />
      <hr className="border-1 border-solid border-neutral-500" />
      <p className="pb-2 text-sm italic">
        When the terminal is not connected, please enable the terminal pairing mode. It'll show a
        small popup on the bottom of the screen with an option to pair a terminal (Moby/5500).
      </p>
      <div className="flex flex-row gap-2 flex-wrap">
        <Button
          color="neutral"
          outlined={true}
          onClick={() => enableTerminalPairing(navigate, { kioskId: kiosk?.id })}
          text="Enable Terminal Pairing"
        />
        <Button
          color="neutral"
          outlined={true}
          onClick={() => disableTerminalPairing(navigate, { kioskId: kiosk?.id })}
          text="Disable Terminal Pairing"
        />
        <Button
          color="neutral"
          outlined={true}
          onClick={() => kioskCommand(navigate, { command: 'reset-terminal', kioskId: kiosk?.id })}
          text="Reset Payment Terminal"
        />
      </div>
      <Allowed requiredPermissions="is_admin">
        <hr className="border-[0.5] border-solid border-neutral-500" />
        <p className="pb-2 text-sm italic">
          Send your own commands to the kiosk for debugging purposes. Do not send unauthorized
          commands.
        </p>
        <div className="flex flex-row gap-2">
          <Input
            onChange={(e) => setCommand(e.target.value)}
            value={command}
            name="Command"
            label="Kiosk Command"
            className="w-full"
          />
          <Button
            color="secondary"
            outlined={true}
            onClick={sendKioskCommand}
            loading={loading}
            text="Send Kiosk Command"
            className="h-fit mt-auto"
            iconRight="new-send-bulk"
          />
        </div>
      </Allowed>
    </Modal>
  );
}
