import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFilter, deleteFilter, makeDefaultFilter } from '../../../api/Filter';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';
import { objMap } from '../../../lib/helpers/utility';
import { useFilters } from '../../../lib/hooks/queries/useFilters';
import { showAlert } from '../Alert/Alert';
import Button from '../Buttons/Button';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';
import CheckboxFilter from './CheckboxFilter';
import DateRangeFilter from './DateRangeFilter';
import MultipleSearchFilter from './MultipleSearchFilter';
import Popup from './Popup';
import RangeFilter from './RangeFilter';
import SearchFilter from './SearchFilter';

const Filter = ({
  filters,
  setFilters,
  category,
  defaultFilters,
  btnClassName,
  width = 500,
  position = 'bottom right',
  menuPortalTarget,
  modal = undefined,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [appliedDefault, setAppliedDefault] = useState(false);
  const [saveInput, setSaveInput] = useState('');
  const { data } = useFilters({
    params: { category },
    dependencies: [category],
    options: { keepPreviousData: true, staleTime: 5000 }
  });
  const defaultData = data?.default;
  const filtersData = data?.filters;
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { device } = useUIContext();
  const [saveButton, setSaveButton] = useState();
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [isHover, setIsHover] = useState(false);

  useEffect(() => {
    if (defaultData?.filters && !appliedDefault) {
      setAppliedDefault(true);
      setAppliedFilter(defaultData?.name);
    }
  }, [defaultData]);

  function setFilter(key, value) {
    setFilters((prev) => {
      const newFilters = { ...prev };
      newFilters[key] = value;
      return newFilters;
    });
  }

  const save = async () => {
    if (!saveInput) return;
    const save = await createFilter(navigate, {
      category,
      name: saveInput,
      filters: objMap(filters, (k, f) => f.values)
    });

    if (save?.filter) {
      setSaveInput('');
      showAlert({
        message: 'Filter saved successfully',
        color: 'success'
      });
    } else {
      showAlert({
        message: 'An unexpected error has occured. Please try again later.',
        color: 'danger'
      });
    }

    queryClient.invalidateQueries(['filters']);
    setSaveButton(false);
  };

  const makeDefault = async (id, isDefault) => {
    const make = await makeDefaultFilter(navigate, {
      id,
      category,
      isDefault
    });

    if (make.code == 0) {
      showAlert({
        message: 'Filter set as default successfully',
        color: 'success'
      });
      if (isDefault) {
        const filter = data.filters.find((f) => f.id === id);

        applyFilter(filter);
      } else {
        resetFilter();
      }
    } else {
      showAlert({
        message: 'An unexpected error has occured. Please try again later.',
        color: 'danger'
      });
    }

    queryClient.invalidateQueries(['filters']);
    queryClient.invalidateQueries(['default-filters']);
  };

  const deleteF = async (id) => {
    const del = await deleteFilter(navigate, {
      id
    });

    queryClient.invalidateQueries(['filters']);
    queryClient.invalidateQueries(['default-filters']);

    if (del.code == 0) {
      showAlert({
        message: 'Filter deleted successfully',
        color: 'success'
      });
    } else {
      showAlert({
        message: 'An unexpected error has occured. Please try again later.',
        color: 'danger'
      });
    }

    queryClient.invalidateQueries(['filters']);
    queryClient.invalidateQueries(['default-filters']);
  };

  function applyFilter(filter) {
    setFilters((prev) => {
      const newFilters = { ...prev };
      return objMap(newFilters, (key, f) => {
        const filters = filter?.filters ? JSON.parse(filter?.filters) : [];
        return { ...f, values: filters[key] };
      });
    });
    setAppliedFilter(filter.name);
  }

  const resetFilter = () => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      return objMap(newFilters, (key, f) => {
        return { ...f, values: defaultFilters[key]?.values };
      });
    });
    setAppliedFilter(null);
  };
  const changedFiltersNumber = useCallback(() => {
    return Object.values(filters).filter((value) => {
      if (value.type == 'date-range') {
        return value.values.startDate && value.values.endDate;
      }
      return value.values && value.values.length > 0;
    }).length;
  }, [filters]);

  return (
    <div>
      <Popup
        width={device === 'mobile' || device === 'tablet-sm' ? '95%' : width}
        position={position}
        open={open}
        modal={
          modal !== undefined
            ? modal
            : ['desktop', 'laptop', 'tablet'].includes(device)
              ? false
              : true
        }
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        trigger={
          <div
            data-qa="filter"
            className={cs(
              'group flex cursor-pointer select-none items-center gap-[6px] rounded-md border border-solid border-neutral-100 py-[6px] !pl-3 !pr-[14px] text-neutral-600 hover:bg-neutral-50 xs:!px-[6px]',
              btnClassName,
              open && '!bg-primary-700'
            )}>
            <Icon
              className="cursor-pointer"
              icon="sort"
              shade={isHover ? 700 : 900}
              color={open ? 'white' : 'primary'}
            />
            {(device === 'desktop' || device === 'laptop') && (
              <span
                className={cs(
                  'text-sm font-500 leading-5 first-letter:capitalize',
                  open ? 'text-white' : 'text-primary-900 group-hover:text-primary-700'
                )}>
                Filter
              </span>
            )}
            {changedFiltersNumber() !== 0 && (
              <span className="flex h-[20px] w-[20px] items-center justify-center rounded-[4px] bg-primary-400 text-xs font-700 text-white">
                {changedFiltersNumber()}
              </span>
            )}
          </div>
        }
        {...rest}>
        <div className="mb-1 mr-1 mt-2 flex flex-col gap-1 overflow-y-auto overflow-x-hidden px-1 pb-3">
          <Icon
            data-qa="new-close-square"
            icon="new-close-square"
            className="absolute right-3 top-3"
            onClick={() => setOpen(false)}
          />
          {Object.keys(filters)?.map((key) => {
            switch (filters[key].type) {
              case 'date-range':
                return (
                  <DateRangeFilter
                    key={key}
                    index={key}
                    data={filters[key]}
                    setFilter={setFilter}
                  />
                );
              case 'search':
                return filters[key].multiple ? (
                  <MultipleSearchFilter
                    key={key}
                    index={key}
                    data={filters[key]}
                    setFilter={setFilter}
                    placeholder={filters[key]?.placeholder}
                    icon={filters[key]?.icon}
                    menuPortalTarget={menuPortalTarget}
                  />
                ) : (
                  <SearchFilter
                    key={key}
                    index={key}
                    data={filters[key]}
                    setFilter={setFilter}
                    placeholder={filters[key]?.placeholder}
                  />
                );
              case 'checkbox':
                return (
                  <CheckboxFilter
                    key={key}
                    index={key}
                    data={filters[key]}
                    setFilter={setFilter}
                    defaultFilters={defaultFilters}
                  />
                );
              case 'range':
                return (
                  <RangeFilter
                    key={key}
                    index={key}
                    data={filters[key]}
                    setFilter={setFilter}
                    defaultFilters={defaultFilters}
                  />
                );
            }
          })}
        </div>

        <div className="flex w-full items-center justify-between gap-2 border-0 !border-b-0 !border-t border-solid !border-neutral-100 bg-white !px-6 !py-3 shadow-[0px_-2px_16px_rgba(0,0,0,0.06)]">
          {saveButton ? (
            <Popup
              onClose={() => setSaveButton(false)}
              trigger={(open) => (
                <div className="flex cursor-pointer select-none items-center gap-2">
                  <Icon
                    color={open || appliedFilter ? 'primary' : 'neutral'}
                    shade={open || appliedFilter ? '700' : '500'}
                    icon="filter-favorite"
                  />
                  {appliedFilter ? (
                    <div className="flex items-center gap-1">
                      <span
                        className={cs(
                          'text-sm font-500',
                          open ? 'text-primary-700' : 'text-neutral-500 '
                        )}>
                        Filter:
                      </span>
                      <span
                        className={cs(
                          'text-sm font-bold first-letter:uppercase',
                          open || appliedFilter ? 'text-primary-700' : 'text-neutral-500'
                        )}>
                        {appliedFilter}
                      </span>
                    </div>
                  ) : (
                    <span
                      className={cs(
                        'text-sm font-500',
                        open || appliedFilter ? 'text-primary-700' : ' text-neutral-500'
                      )}>
                      Filter preferences
                    </span>
                  )}
                </div>
              )}
              width={230}
              open={saveButton}
              contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
              position={['top right', 'top center']}>
              <div className="flex w-[230px] flex-col gap-2 !p-3">
                <Input
                  value={saveInput}
                  label="Filter name"
                  data-qa="filter-name"
                  onChange={(e) => setSaveInput(e.target.value)}
                  placeholder="Enter filter name..."
                />
                <div className="flex justify-between">
                  <div
                    onClick={() => setSaveButton(false)}
                    className="!ml-1 flex cursor-pointer items-center gap-1 text-sm  text-gray-500">
                    <Icon icon="new-close-sm" className="cursor-pointer" data-qa="close"></Icon>
                    Close
                  </div>
                  <Button
                    onClick={save}
                    text="Save"
                    size="small"
                    icon="new-diskette"
                    data-qa="save"
                  />
                </div>
              </div>
            </Popup>
          ) : (
            <Popup
              trigger={(open) => (
                <div className="flex cursor-pointer select-none items-center gap-2">
                  <Icon
                    color={open || appliedFilter ? 'primary' : 'neutral'}
                    shade={open || appliedFilter ? '700' : '500'}
                    icon="filter-favorite"
                  />
                  {appliedFilter ? (
                    <div className="flex items-center gap-1" data-qa="filter:">
                      <span
                        className={cs(
                          'text-sm font-500',
                          open || appliedFilter ? 'text-primary-700' : 'text-neutral-500 '
                        )}>
                        Filter:
                      </span>
                      <span
                        className={cs(
                          'text-sm font-bold first-letter:uppercase',
                          open || appliedFilter ? 'text-primary-700' : 'text-neutral-500'
                        )}>
                        {appliedFilter}
                      </span>
                    </div>
                  ) : (
                    <span
                      data-qa="filter-preferences"
                      className={cs(
                        'text-sm font-500',
                        open ? 'text-primary-700' : ' text-neutral-500'
                      )}>
                      Filter preferences
                    </span>
                  )}
                </div>
              )}
              width={160}
              on={['hover', 'focus']}
              contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
              position={['top right', 'top center']}>
              <div className="flex !w-[160px] flex-col py-[6px]">
                <div
                  onClick={() => setSaveButton(true)}
                  className={cs(
                    'flex cursor-pointer justify-between !px-[12px] py-[6px]  hover:bg-primary-50'
                  )}>
                  <div className="flex gap-[6px] text-sm text-primary-900">
                    <Icon
                      color="primary"
                      icon="new-diskette"
                      className="cursor-pointer"
                      data-qa="save-btn"></Icon>
                    Save
                  </div>
                </div>

                <Popup
                  trigger={(open) => (
                    <div
                      className={cs(
                        'flex cursor-pointer justify-between !px-[12px] py-[6px] hover:bg-primary-50',
                        open && 'bg-primary-50'
                      )}>
                      <div className="flex gap-[6px] text-sm text-primary-900">
                        <Icon
                          icon="history-filled"
                          className="cursor-pointer"
                          data-qa="saved-filters"
                        />
                        Saved filters
                      </div>
                      <Icon
                        icon="new-chevron-right"
                        color="neutral"
                        stroke
                        shade={400}
                        className="cursor-pointer"
                      />
                    </div>
                  )}
                  width={200}
                  contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
                  offsetX={-10}
                  on={['hover', 'focus']}
                  position={['right center']}>
                  <div className="flex !w-[200px] flex-col py-[6px]">
                    {filtersData?.length > 0 ? (
                      filtersData?.map((filter, index) => (
                        <Popup
                          key={index}
                          trigger={(open) => (
                            <div
                              data-qa={`saved-filter-${index}`}
                              className={cs(
                                'flex cursor-pointer justify-between gap-[6px] !px-[12px] py-[6px] text-sm hover:bg-primary-50',
                                open && 'bg-primary-50'
                              )}>
                              <span className="text-sm text-primary-900 first-letter:uppercase">
                                {filter.name}
                              </span>
                              <Icon
                                icon="new-chevron-right"
                                className="cursor-pointer"
                                stroke
                                shade={400}
                                color="neutral"
                              />
                            </div>
                          )}
                          width={160}
                          contentStyle={{
                            borderRadius: '4px',
                            boxShadow: '0px 2px 16px 0px #004F6B33'
                          }}
                          offsetX={-10}
                          on={['hover', 'focus']}
                          position={['right center']}>
                          <div className="flex !w-[160px] flex-col py-[6px]">
                            <div
                              data-qa="apply"
                              onClick={() => applyFilter(filter)}
                              className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                              Apply
                            </div>
                            <div
                              data-qa="set-remove-as-default"
                              onClick={() => makeDefault(filter.id, !filter.is_default)}
                              className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                              {!filter.is_default ? 'Set as default' : 'Remove default'}
                            </div>
                            <div
                              data-qa="delete"
                              onClick={() => deleteF(filter.id)}
                              className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                              Delete
                            </div>
                          </div>
                        </Popup>
                      ))
                    ) : (
                      <div
                        className="flex cursor-pointer !px-3 py-[6px] text-sm text-neutral-500"
                        data-qa="no-saved-filters">
                        No saved filters
                      </div>
                    )}
                  </div>
                </Popup>
                <div
                  onClick={resetFilter}
                  className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                  <Icon icon="rotate-ccw-filled" className="cursor-pointer" />
                  Reset filter
                </div>
              </div>
            </Popup>
          )}
          <Button
            text="Reset filter"
            onClick={resetFilter}
            outlined
            color="neutral"
            data-qa="reset-filter-btn"
          />
        </div>
      </Popup>
    </div>
  );
};

export default Filter;
