import React, { memo, useEffect } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { useLocation } from 'react-router-dom';
import PatientOverview from '../../../CurrentNote/PatientOverview/PatientOverview';
import EnhanceNoteSidebar from './components/EnhanceNoteSideBar/EnhanceNoteSidebar';
import { EnhanceNoteContextProvider } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContextProvider';
import EnhanceNoteView from './components/EnhanceNoteView/EnhanceNoteView';
import Icon from 'components/shared/Icon/Icon';
import EnhanceLoading from './components/EnhanceLoading';
import { useEnhanceNoteContextData } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';

const EnhanceNoteMain = memo(() => {
  const { enhanceLoading } = useEnhanceNoteContextData();

  if (enhanceLoading) {
    return (
      <div className="relative flex h-full flex-grow flex-col items-center justify-start bg-primary-900">
        <Icon icon="ai-bg-pattern" className="absolute left-0 top-0" />
        <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />
        <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90" />
        <EnhanceLoading />
        <div className="relative top-10 z-50 h-full">
          <Icon icon="hippa-secure" className="z-50" />
        </div>
      </div>
    );
  }
  return (
    <div className="flex h-screen">
      <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200">
        <EnhanceNoteSidebar className="relative z-50 h-full bg-white" />
      </div>
      <div className="flex h-[calc(100vh-50px)] w-full flex-col">
        <PatientOverview />
        <EnhanceNoteView />
      </div>
    </div>
  );
});

const EnhanceNote = () => {
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Enhance Current Note',
      name: '/enhance-current-note',
      path: pathname
    });
  }, [breadcrumbs]);

  return (
    <div className="h-screen">
      <EnhanceNoteContextProvider>
        <div className="relative border-b border-gray-300 p-2 shadow-sm">
          <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
        </div>
        <EnhanceNoteMain />
      </EnhanceNoteContextProvider>
    </div>
  );
};

export default memo(EnhanceNote);
