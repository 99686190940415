import { requestApi } from '../Api';

export const getUsers = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/get_multiple', params, navigate });
};


export const getUsersList = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/list', params, navigate });
};


export const updateAdminUser = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/update', params, navigate });
};

export const createAdminUser = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/create', params, navigate });
};

export const deactivateAdminUser = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/deactivate', params, navigate });
};

export const approveAdminUser = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/approve', params, navigate });
};

export const activateAdminUser = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/users/activate', params, navigate });
};
