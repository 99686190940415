import React from 'react';
import Modal from '../../../../../shared/Modal/Modal';
import Button from '../../../../../shared/Buttons/Button';
import { FaxDetail } from './components/FaxDetail';

const InfoFaxModal = ({ infoFaxModalVisible, setInfoFaxModalVisible, fax }) => {
  return (
    <Modal
      handleOpen={!!infoFaxModalVisible}
      handleClose={() => setInfoFaxModalVisible(false)}
      title={'Fax detail'}
      slideFromRight
      bodyClassName={'!p-0'}
      footer={
        <div className="flex w-full justify-between">
          <Button
            transparent
            type="primary"
            color="neutral"
            text="Close"
            size="small"
            className="!p-0"
            onClick={() => setInfoFaxModalVisible(false)}
          />
        </div>
      }>
      <FaxDetail fax={fax} />
    </Modal>
  );
};

export default InfoFaxModal;
