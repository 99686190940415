import React, { useCallback, useMemo, useState } from 'react';
import { ChatContextApi, ChatContextData } from './ChatContext';
import { useMyScribeAIContext } from '../../MyScribeAIContext';

export const ChatContextProvider = ({ children }) => {
  const [inputTags, setInputTags] = useState([]);
  const [componentToAdd, setComponentToAdd] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState({});
  const { typeOfNotes } = useMyScribeAIContext();
  const [selectedPromptNoteType, setSelectedPromptNoteType] = useState(typeOfNotes['soap']);

  const onComponentAdd = useCallback(() => {
    if (!inputTags.some((tag) => tag.id === componentToAdd?.id)) {
      setInputTags((prev) => [...prev, componentToAdd]);
    }
    setComponentToAdd(null);
  }, [componentToAdd, inputTags]);

  const finalData = useMemo(
    () => ({
      inputTags,
      componentToAdd,
      selectedTableRows,
      selectedPromptNoteType
    }),
    [inputTags, componentToAdd, selectedTableRows, selectedPromptNoteType]
  );

  const setTags = useCallback(setInputTags, [setInputTags]);
  const setComponent = useCallback(setComponentToAdd, [setComponentToAdd]);
  const setSelectedRows = useCallback(setSelectedTableRows, [setSelectedTableRows]);
  const setSelectedNoteType = useCallback(setSelectedPromptNoteType, [setSelectedPromptNoteType]);

  const api = useMemo(
    () => ({
      setSelectedTableRows: setSelectedRows,
      setSelectedPromptNoteType: setSelectedNoteType,
      setComponentToAdd: setComponent,
      setInputTags: setTags,
      onAddComponent: onComponentAdd
    }),
    [setSelectedRows, setSelectedNoteType, setComponent, setTags, onComponentAdd]
  );

  return (
    <ChatContextData.Provider value={finalData}>
      <ChatContextApi.Provider value={api}>{children}</ChatContextApi.Provider>
    </ChatContextData.Provider>
  );
};
