import React, { memo, useCallback, useEffect, useState } from 'react';

import {
  usePatientBalanceContextApi,
  usePatientBalanceContextData
} from 'lib/context/PatientBalanceContext/PatientBalanceContext';
import numOrDefault from 'lib/helpers/numOrDefault';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const AllocateForm = memo((params) => {
  const { setAllocatingRow, setFormData } = usePatientBalanceContextApi();
  const { allocatingRow, formData } = usePatientBalanceContextData();
  const transaction = params.data;

  const [maxVal, setMaxVal] = useState(() => {
    const initialMaxVal = Math.min(allocatingRow?.amountPaidSelf || 0, transaction?.balance || 0);
    return { [transaction.id]: initialMaxVal };
  });

  const currentFormData = formData?.[transaction.id];

  useEffect(() => {
    const data = Object.values(formData || {});
    const totalTempPayment = data.reduce((acc, curr) => acc + numOrDefault(curr?.amount), 0);

    setAllocatingRow((prev) => ({
      ...prev,
      payment: numOrDefault(prev?.amountPaidSelf) - totalTempPayment
    }));

    const remainingAmount = allocatingRow?.amountPaidSelf - totalTempPayment;

    setMaxVal((prev) => ({
      ...prev,
      [transaction.id]:
        remainingAmount + currentFormData?.amount < transaction?.balance
          ? remainingAmount + currentFormData?.amount
          : transaction?.balance
    }));
  }, [
    currentFormData?.amount,
    formData,
    setAllocatingRow,
    allocatingRow?.amountPaidSelf,
    transaction?.balance,
    transaction.id
  ]);

  const onAmountChange = useCallback(
    (value) => {
      const amount = +value;

      setFormData((prev) => ({
        ...prev,
        [transaction.id]: {
          ...transaction,
          ...(prev?.[transaction.id] || {}),
          amount
        }
      }));
    },
    [setFormData, transaction]
  );

  return (
    <div className="!border-primary flex h-[139px] gap-[15px] border bg-primary-10 px-2 py-3">
      <CurrencyInput
        id="amount"
        name="amount"
        label="Amount"
        maxValue={maxVal?.[transaction.id]}
        value={transaction?.amount || currentFormData?.amount || ''}
        onValueChange={onAmountChange}
      />
    </div>
  );
});

export default AllocateForm;
