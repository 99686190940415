import cs from 'classnames';
import { allowValidNameChars } from 'lib/helpers/utility';

/**
 * Get common props for Input component
 * @param {import('./types').InputProps} props
 */

export const getCommonInputProps = ({
  // Layout props
  className,
  width,
  inputClassName,
  inputWrapperClassName,
  rounded,

  // Input props
  label,
  name = label || 'inputName',
  id = name || label || 'inputId',
  type = 'text',
  value,
  placeholder,
  disabled = false,
  error,
  autoComplete = 'off',

  // Number input controls
  hideNumberArrows,

  // Icons and actions
  icon,
  rightIcon,
  iconClick = () => {},
  rightIconClick = () => {},
  rightSecondaryIcon,
  actionButtonText,
  actionButtonIcon,

  // Form behavior
  formik,
  focusNextFieldOnEnter = false,
  submitFormOnEnter,
  validateName = false,

  // Misc
  forwardedRef,
  onChange = () => {},

  ...rest
}) => {
  const roundedCss = rounded ? `rounded-${rounded}` : 'rounded-md';
  const hasActionButton = actionButtonText || actionButtonIcon;

  const shouldShowNumberControls = type === 'number' && !icon && !rightIcon;

  const handleInternalNumberChange = (action) => {
    if (type !== 'number') return;
    const currentValue = Number(value) || 0;
    const newValue = action === 'increment' ? currentValue + 1 : Math.max(0, currentValue - 1);

    // Original event structure
    const syntheticEvent = {
      target: {
        value: newValue
      }
    };

    onChange(syntheticEvent);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !submitFormOnEnter) {
      event.preventDefault();
    }

    if (event.key === 'Enter' && focusNextFieldOnEnter && formik) {
      const fields = Object.keys(formik.values);
      const index = fields.indexOf(name);
      if (index >= 0) {
        const nextFieldIndex = index + 1;
        if (nextFieldIndex < fields.length) {
          document.querySelector(`input[name="${fields[nextFieldIndex]}"]`)?.focus();
        } else {
          formik.submitForm();
        }
      }
    }
  };

  return {
    className,
    style: { width },
    inputProps: {
      className: cs(
        'w-full overflow-auto text-ellipsis border-none bg-transparent p-0 text-sm leading-5 text-neutral-900 outline-none placeholder:text-neutral-500',
        error ? 'caret-danger-500' : 'caret-primary-500',
        disabled && 'cursor-not-allowed !text-neutral-500',
        (hideNumberArrows || type === 'number') &&
          '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
        type === 'number' && 'text-center user-select-none',
        inputClassName
      ),
      value,
      onChange,
      onKeyPress: handleKeyPress,
      onKeyDown: validateName ? (e) => allowValidNameChars(e) : (e) => e,
      placeholder,
      id,
      'data-qa': id,
      name,
      type,
      disabled,
      ref: forwardedRef,
      autoComplete,
      ...rest
    },
    wrapperProps: {
      className: cs(
        'm-0 select-none flex items-center justify-between gap-x-3 border border-solid bg-white focus-within:outline focus-within:!outline-2',
        error
          ? '!border-danger-300 focus-within:!outline-danger-100'
          : 'border-neutral-200 focus-within:border-primary-300 focus-within:outline-[#CBF0FD]',
        roundedCss,
        disabled && '!bg-neutral-50',
        !hasActionButton ? 'h-10 px-3 py-[10px]' : 'overflow-hidden pl-3',
        inputWrapperClassName
      )
    },
    iconProps: {
      leftIcon: type === 'number' ? icon || 'new-minus' : icon,
      rightIcon: type === 'number' ? rightIcon || 'new-plus' : rightIcon,
      leftIconClick: type === 'number' ? () => handleInternalNumberChange('decrement') : iconClick,
      rightIconClick:
        type === 'number' ? () => handleInternalNumberChange('increment') : rightIconClick,
      rightSecondaryIcon
    },
    shouldShowNumberControls
  };
};
