import { requestApi } from './Api';

export const getRoleDescriptions = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/permissions/descriptions/get',
    onError: () => {},
    params
  });
};

export const getUsers = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/permissions/get_users',
    params
  });
};

export const getRoleList = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/role/list',
    params
  });
};

export const getRole = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/role/get_role',
    params
  });
};

export const deleteRole = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/role/delete',
    params
  });
};
