import { useCallback, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import cs from 'classnames';

import { showAlert } from '../Alert/Alert';
import Header from '../shared/Header';
import Hint from '../shared/Hint';
import MaximumChars from '../shared/MaximumChars';

import { editorConfig } from './lib/configs';

import './lib/TextEditor.scss';

/**
 * @param {import('./lib/propTypes').TextEditorProps} props
 */

const TextEditor = (props) => {
  const {
    editorParentClassName,
    className,
    placeholder,
    label,
    id = label || 'textEditorId',
    value = '',
    onChange = () => {},
    media = { toolbar: [], formats: [] },
    disabled = false,
    setDisabled = () => {},
    error,
    hint,
    hintIcon = 'new-info',
    maxLength,
    resizeX = false,
    resizeY = false,
    customStyle = null
  } = props;

  const [editorContent, setEditorContent] = useState(value);
  const [length, setLength] = useState(0);
  const quillRef = useRef(null);
  const { modules, formats } = editorConfig(media);

  const onSetDisable = useCallback(
    (event) => {
      setDisabled(event);
    },
    [setDisabled]
  );

  const handleChange = (content, delta, source, editor) => {
    let newContent;
    const currentText = editor.getText();
    const currentLength = currentText.length - 1;
    const isMaxLength = currentLength > maxLength;
    const isPaste =
      source === 'user' &&
      delta.ops.some((op) => typeof op.insert === 'string' && op.insert.length > 1);

    if (isMaxLength) {
      newContent = editorContent; // Revert to previous content
      if (isPaste) {
        // Show alert only for paste operations
        showAlert({
          message: `Pasting is not allowed because your text exceeds the maximum allowed limit of ${maxLength} characters. Please reduce the text length and try again.`,
          color: 'warning'
        });
      }
    } else {
      newContent = content; // Allow the change
    }

    setEditorContent(newContent);
    onChange(newContent);
    setLength((prevState) => (isMaxLength ? prevState : currentLength));
  };

  return (
    <div className={editorParentClassName}>
      <Header {...props} id={id} setDisabled={onSetDisable} />
      <div className="relative">
        <ReactQuill
          ref={quillRef}
          className={cs(
            'relative z-20 min-h-[122px] whitespace-pre-line rounded-md bg-white',
            error && 'is-error',
            disabled
              ? 'cursor-not-allowed !bg-neutral-50 text-neutral-400 placeholder:text-neutral-300'
              : 'bg-white text-neutral-900 placeholder:text-neutral-500',
            disabled && 'cursor-not-allowed',
            resizeX && 'is-resizeX',
            resizeY && 'is-resizeY',
            className
          )}
          onChange={!!maxLength ? handleChange : onChange}
          value={value}
          modules={modules}
          formats={formats}
          bounds={document.body}
          placeholder={placeholder}
          readOnly={disabled}
          style={customStyle}
          id={id}
        />
        <MaximumChars length={length} maxLength={maxLength} disabled={disabled} />
      </div>
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && <p className="pt-2 text-sm text-danger-500">{error}</p>}
    </div>
  );
};

export default TextEditor;
