import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import moment from 'moment';

import { arrayToObject, ia } from '../../helpers/utility';
import { useUnmatchedClaims } from '../../hooks/queries/claims/useUnmatchedClaims';
import { useCodes } from '../../hooks/queries/codes/useCodes';
import { useEra } from '../../hooks/queries/era/useEra';
import { useEras } from '../../hooks/queries/era/useEras';

import { EraContext } from './EraContext';

export const EraContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { type = 'all', id } = useParams();

  const [era, setEra] = useState();
  const [amount, setAmount] = useState(0);
  const [procedures, setProcedures] = useState([]);
  const [status_code, setStatusCode] = useState(null);
  const [selectedClaim, setSelectedClaim] = useState();
  const [allocateEob, setAllocateEob] = useState(false);
  const [selectedUnmatchedClaim, setSelectedUnmatchedClaim] = useState();
  const [filters, setFilters] = useState({
    searchTerm: '',
    searchType: 'Patient',
    page: 1,
    limit: 25,
    search: '',
    column: 'paid_date',
    sort: 'desc',
    state: type,
    withCount: true,
    startDate: moment().subtract(1, 'year').toDate(),
    endDate: moment().endOf('day').toDate()
  });

  const { data, isLoading } = useEras({ params: filters, dependencies: [filters] });

  const { isLoading: eraLoading } = useEra({
    params: { id },
    dependencies: [id],
    options: {
      onSuccess: (response) => setEra(response?.era)
    }
  });

  const { data: codesData, isLoading: codesLoading } = useCodes({
    params: { services: ['adjustment_reason', 'claim_adjustment_reason'] }
  });

  const { data: unmatchedClaimsData, isLoading: unmatchedClaimsLoading } = useUnmatchedClaims({
    params: { patientId: selectedClaim?.claim?.pcn },
    dependencies: [selectedClaim?.claim?.pcn],
    enabled: !!selectedClaim?.claim?.pcn
  });

  const eras = data?.eras;
  const eraCount = data?.count;

  const unmatchedClaims = useMemo(
    () =>
      (unmatchedClaimsData?.claims || []).map((claim) => {
        const procedures = (claim?.invoice?.procedures || []).map((procedure) => {
          const adjustment = selectedClaim?.claim?.charge?.find(
            (_charge) => _charge.proc_code === procedure.procedure_code
          )?.adjustment;
          return { ...procedure, adjustment };
        });

        return {
          ...claim,
          invoice: { ...claim.invoice, procedures }
        };
      }),
    [unmatchedClaimsData, selectedClaim]
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, state: type, page: 1 }));
  }, [type]);

  useEffect(() => {
    if (pathname === '/portal/beyond-billing/era-eob')
      navigate('/portal/beyond-billing/era-eob/all');
  }, [pathname]);

  return (
    <EraContext.Provider
      value={{
        eras,
        isLoading,
        filters,
        setFilters,
        era,
        setEra,
        eraLoading,
        eraCount,
        type,
        codes: ia(codesData?.codes) ? arrayToObject(codesData?.codes, 'code', 'description') : [],
        selectedClaim,
        setSelectedClaim,
        unmatchedClaims,
        selectedUnmatchedClaim,
        setSelectedUnmatchedClaim,
        allocateEob,
        setAllocateEob,
        amount,
        setAmount,
        procedures,
        setProcedures,
        status_code,
        setStatusCode
      }}>
      {children}
    </EraContext.Provider>
  );
};
