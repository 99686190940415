import React from 'react';
import { useRoutes } from 'react-router-dom';

// Portal
import PrivateRoutes from './PrivateRoutes.jsx';
import Login from '../components/Login/Login.jsx';
import Logout from '../components/shared/Logout/Logout.jsx';
import PasswordReset from '../components/Auth';
import PasswordRequest from '../components/Auth/request';
import PasswordSet from '../components/Auth/set';
import PracticePortal from '../components/practice';
import Dashboard from 'components/practice/Dashboard/Dashboard.jsx';
import BeyondBilling from '../components/practice/BeyondBilling';
import BeyondBillingOverview from '../components/practice/BeyondBilling/Overview/Overview';
import Payments from '../components/practice/BeyondBilling/Payments.jsx';

import DynamicAR from '../components/practice/BeyondBilling/DynamicAR/DynamicAR.jsx';
import CMS1500 from '../components/practice/BeyondBilling/Claims/CMS1500/CMS1500.jsx';
import EraWrapper from '../components/practice/BeyondBilling/Claims/ERA&EOB/EraAndEob.jsx';
import Superbills from '../components/practice/BeyondBilling/Superbill/Superbills.jsx';
import PatientCharts from '../components/practice/charts/PatientCharts/PatientCharts';
import PatientChart from '../components/practice/charts/PatientChart/PatientChart.jsx';
import CalendarSettings from '../components/practice/settings/calendar';
import OverviewContainer from '../components/practice/charts/Overview/OverviewContainer';
import Demographics from '../components/practice/charts/Demographics/Demographics';
import Appointments from '../components/practice/charts/Appointments/Appointments';

import Billing from '../components/practice/charts/billing/Billing.jsx';
import BillingTransactions from '../components/practice/charts/billing/transactions.jsx';
import BillingSuperbill from '../components/practice/charts/billing/superbill/Superbill.jsx';

import Documents from '../components/practice/charts/Documents/Container';
import PracticePatientSettings from '../components/practice/charts/PracticePatientSettings';
import Actions from '../components/practice/charts/Actions/Actions';
import SuperbillPage from '../components/practice/charts/SuperBillPage/SuperBillPage.jsx';
import Settings from '../components/practice/settings/Settings';
import Services from '../components/practice/settings/services/Service.jsx';
import Forms from '../components/practice/settings/forms/Forms.jsx';
import Payers from '../components/practice/settings/payers/Payers';
import Account from '../components/practice/settings/Account';
import Roles from '../components/shared/Roles-new/Roles.jsx';
// import Roles from '../components/shared/roles';
import Staff from '../components/shared/Staff';
import EPrescribeSettings from '../components/practice/settings/ePrescribe';
import BillingSettings from '../components/practice/settings/billing';
import PatientAccount from '../components/practice/charts/Account/Account';
import NewContactContainer from '../components/shared/contact/contact_container.js';
import InstantActionPage from '../components/InstantActions/wrapper.jsx';
import ProfileContainer from '../components/practice/Profile/ProfileContainer.jsx';
import Products from '../components/practice/settings/products/Products.jsx';
import Packages from '../components/practice/settings/packages/Packages.jsx';
import Resources from '../components/practice/settings/Resources/Resources.jsx';
import View from '../components/practice/BeyondBilling/Claims/ERA&EOB/View.jsx';
import PurchasedPackages from '../components/practice/charts/Account/tabs/PurchasedPackages/PurchasedPackages';
import Unsubscribe from '../components/Unsubscribe/Unsubscribe.jsx';

// Myportal
import PatientPortal from '../components/Patient';
import PatientDashboard from '../components/Patient/dashboard';
import PatientInbox from '../components/Patient/inbox/inbox';
import PatientConversation from '../components/Patient/inbox/PatientConversation';
import PatientMedicalRecords from '../components/Patient/MedicalRecords';
import PatientAppointments from '../components/Patient/appointments';
import PatientQuestionnaires from '../components/Patient/questionnaires';
import PatientMedications from '../components/Patient/medications';
import PatientReferrals from '../components/Patient/referrals';
import PatientSuperbills from '../components/Patient/superbills';
import PatientDocuments from '../components/Patient/documents';
import AccountContainer from '../components/Patient/account/indexContainer';
import CommunicationLogs from '../components/practice/charts/Communications/CommunicationLogs.jsx';
import CommunicationMessages from '../components/practice/charts/Communications/CommunicationMessages.jsx';
import Communications from '../components/practice/charts/Communications/Communications.jsx';
import PatientInvoicesContainer from '../components/Patient/Invoices/Container.jsx';
// new clinical note
import AllClinicalNotes from '../components/practice/charts/ClinicalNote/AllClinicalNotes/AllClinicalNote.jsx';
import ClinicalNote from '../components/practice/charts/ClinicalNote/ClinicalNote.jsx';
import CurrentNote from '../components/practice/charts/ClinicalNote/CurrentNote/CurrentNote.jsx';

//Admin
import Admin from '../components/admin';
import IconsPage from '../components/admin/pages/IconsPage/IconsPage.jsx';
import AdminDashboard from '../components/admin/pages/Dashboard/Dashboard.jsx';
import AdminPasswordReset from '../components/admin/pages/ResetPassword';
import AdminPasswordRequest from '../components/admin/pages/ResetPassword/request.jsx';
import AdminPasswordSet from '../components/admin/pages/ResetPassword/set.jsx';
import Practices from '../components/admin/pages/Practices';
import PracticeProfile from '../components/admin/pages/Practices/profile.js';
import AdminStaff from '../components/admin/pages/Practices/staff.js';
import AdminForms from '../components/admin/pages/Practices/forms';
import NewFormBuilderContainer from '../components/admin/pages/Practices/forms/new_container.js';
import AdminSettings from '../components/admin/pages/Practices/AdminSettings.jsx';
import TerminalManagement from '../components/admin/pages/Practices/Terminals';
import PatientImport from '../components/admin/pages/Practices/PatientImport';
import AdminBilling from '../components/admin/pages/Practices/Billing/AdminBilling.jsx';
import EditFormBuilderContainer from '../components/admin/pages/Practices/forms/FormEditContainer.js';
import Practitioners from '../components/admin/pages/Practitioners';
import PractitionerProfile from '../components/admin/pages/Practitioners/profile.js';
import PractitionersBasicInfo from '../components/admin/pages/Practitioners/PractitionersBasicInfo.jsx';
import PractitionersPatients from '../components/admin/pages/Practitioners/PractitionersPatients.jsx';
import AdminPatients from '../components/admin/pages/Patients';
import PatientProfile from '../components/admin/pages/Patients/profile.js';
import Databank from '../components/admin/pages/Databank';
import ReportingDashboard from 'components/admin/pages/ReportingCenter/ReportingCenter.jsx';
import BaseDatapointPage from '../components/admin/pages/Databank/baseDatapointPage.js';
import AccessLogs from '../components/admin/pages/AccessLogs/AccessLogs.jsx';
import SettingsContainer from '../components/admin/pages/Settings/indexContainer.js';
import NewPractitionerContainer from '../components/admin/pages/Practitioners/new_practitioner/indexContainer.js';
import CPTCodes from '../components/admin/pages/Databank/CPTCodes';
import ICD10Codes from 'components/admin/pages/Databank/ICD10';
import PracticePatients from '../components/admin/pages/Practices/Patients.jsx';
import Requests from '../components/admin/pages/Requests/Requests.jsx';
import PayerRequests from '../components/admin/pages/Requests/Payers/PayerRequests.jsx';
import PrescriptionManagement from '../components/admin/pages/Practitioners/PrescriptionManagement.jsx';
import AdminPractitionersPractices from '../components/admin/pages/Practitioners/Practices.jsx';
import AdminPractitionersSettings from '../components/admin/pages/Practitioners/Settings.jsx';
import AdminPractitionersLogs from '../components/admin/pages/Practitioners/Logs.jsx';
import Occupations from '../components/admin/pages/Databank/Occupations';
import AdminPracticesTransactions from '../components/admin/pages/Practices/Transaction/Transactions.jsx';
import AdminPracticesOrganization from '../components/admin/pages/Practices/Organization/Organization.jsx';
import GrowthData from '../components/admin/pages/Databank/GrowthData/GrowthData.jsx';
import Landmarks from '../components/admin/pages/Databank/Landmarks/Landmarks.jsx';
import AuditLogs from 'components/audit/logs/AuditLogs.jsx';
// Admin User Management
import AdminUser from '../components/admin/pages/Users';
import AdminUserUser from '../components/admin/pages/Users/User.jsx';
import AdminUserLogs from '../components/admin/pages/Users/Logs.jsx';
import AdminUserOverview from '../components/admin/pages/Users/Overview.jsx';
import AdminUserSessions from '../components/admin/pages/Users/Sessions.jsx';
import AdminPatientBasicInfo from 'components/admin/pages/Patients/PatientBasicInfo.jsx';
import AdminPatientsLogs from '../components/admin/pages/Patients/Logs.jsx';
import AdminPatientsTransactions from '../components/admin/pages/Patients/Transaction/Transactions.jsx';
import PracticeOverviewContainer from '../components/admin/pages/Practices/OverviewContainer.jsx';
// Admin Intelligence
import FacilityProvider from '../components/admin/pages/Practices/Facility/FacilityProvider.jsx';
import CommsCenter from '../components/practice/comms_center/CommsCenter.jsx';
import Fax from '../components/practice/comms_center/fax/Fax/Fax.jsx';
import Main from '../components/practice/comms_center/fax/Main/Main.jsx';
import CoverPages from '../components/practice/comms_center/fax/CoverPages/CoverPages.jsx';
import Contacts from '../components/practice/comms_center/Contacts/Contacts.jsx';

// Check-in steps
import CheckinSteps from '../components/Patient/checkin/CheckinSteps.jsx';
import PersonalInformationContainer from '../components/Patient/checkin/PersonalInfo/PersonalInfo.jsx';
import CheckinForms from '../components/Patient/checkin/checkin_forms.js';
import HipaaFormContainer from '../components/Patient/checkin/hipaa_form_container.jsx';
import PersonalID from '../components/Patient/checkin/personal_id.jsx';
import Insurance from '../components/Patient/checkin/insurance.jsx';
import Payment from '../components/Patient/checkin/payment';
import PayPrompt from '../components/Patient/checkin/payment/payPrompt.jsx';
import Review from '../components/Patient/checkin/Review/Review.jsx';
import PastForms from '../components/Patient/checkin/PastForms/PastForms.jsx';

import PreAuth from '../components/Patient/checkin/payment/preAuth.jsx';
import CheckinComplete from '../components/Patient/checkin/CheckinComplete.jsx';
import ReturnCheck from '../components/Patient/checkin/ReturnCheck.jsx';
import PatientCheckinLogin from '../components/Patient/checkin/Login.jsx';
import PreAuth2 from '../components/Patient/checkin/PreAuth.jsx';

// Self scheduling
import ScheduleAppointment from '../components/Patient/self_scheduling';
import PracticeStep from '../components/Patient/self_scheduling/steps/PracticeStep.jsx';
import ServiceStep from '../components/Patient/self_scheduling/steps/ServiceStep.jsx';
import DateAndTimeStep from '../components/Patient/self_scheduling/steps/DateAndTimeStep.jsx';
import ContactInformation from '../components/Patient/self_scheduling/steps/ContactInformation.jsx';
import CardOnFile from '../components/Patient/self_scheduling/steps/CardOnFile.jsx';
import Confirmation from '../components/Patient/self_scheduling/steps/Confirmation.jsx';

// User Activation
import UserActivateWrapper from '../components/user/Activate/Wrapper.jsx';
import UserActivateInitiate from '../components/user/Activate/Initiate.jsx';
import UserActivateStart from '../components/user/Activate/Start.jsx';
import UserActivateError from '../components/user/Activate/Error.jsx';
import UserActivateVerifyPhone from '../components/user/Activate/VerifyPhone.jsx';
import UserActivateNewPhone from '../components/user/Activate/NewPhone.jsx';
import UserActivateSuccess from '../components/user/Activate/Success.jsx';

// Kiosks
import KioskSettings from '../components/practice/settings/kiosks/KioskSettings.jsx';
import Kiosk from '../components/Kiosk/Kiosk';
import KioskPhone from '../components/Kiosk/views/Phone.jsx';
import KioskIdle from '../components/Kiosk/views/Idle.jsx';
import KioskActivate from '../components/Kiosk/views/Activate.jsx';

// Other Routes
import NotFound from '../components/NotFound.jsx';
import Terms from '../components/Terms.jsx';
import MFADescription from '../components/MFADescription.jsx';
// import TermsAndConditions from '../components/public/TermsAndConditions/TermsAndConditions.jsx';
// import PrivacyPolicy from '../components/public/PrivacyPolicy/PrivacyPolicy.jsx';
import PatientCheckinEditingSelector from '../components/Patient/checkin/EditingSelector.jsx';
import Quotes from '../components/practice/charts/Account/tabs/Quotes/Quotes.jsx';
import InstantPacket from '../components/InstantActions/components/InstantPacket';
import CustomForms from '../components/InstantActions/components/CustomForms';
import PatientTreatmentPlans from '../components/Patient/TreatmentPlans/PatientTreatmentPlans';
import Complete from '../components/InstantActions/components/Complete';
import InstantAuth from 'components/InstantActions/components/InstantAuth';
import AppointmentsRow from '../components/practice/charts/Appointments/AppointmentsRow.jsx';
import PreferredLocation from '../components/admin/pages/Practices/forms/PreferredLocation.jsx';
import Calendar from 'components/practice/Calendar/Calendar.jsx';
import InternalInvoices from 'components/practice/BeyondBilling/ElectronicInvoicing/InternalInvoices';
import ExternalInvoices from 'components/practice/BeyondBilling/ElectronicInvoicing/ExternalInvoices';
import ElectronicInvoicing from 'components/practice/BeyondBilling/ElectronicInvoicing/ElectronicInvoicing';
import NewInvoice from 'components/public/NewInvoice';
import CConFile from 'components/practice/charts/Account/tabs/CConFile/CConFileTable';
import Mobile from 'components/Kiosk/Mobile.jsx';
import PatientLedger from 'components/practice/BeyondBilling/PatientLedger/PatientLedger';
import Ledger from 'components/practice/charts/billing/ledger/Ledger.js';
import Tasks from 'components/practice/PracticeIQueue/Tasks/Tasks.jsx';
import PaySuccess from 'components/Patient/checkin/payment/paySuccess';
import FormBuilder from 'components/practice/settings/forms/FormBuilder/FormBuilder.jsx';
import BillingInvoices from 'components/practice/charts/billing/Invoices/PatientInvoices';
import NewInboundFaxes from 'components/practice/comms_center/fax/InboundFaxes/NewInboundFaxes';
import NewOutboundFaxes from 'components/practice/comms_center/fax/OutboundFaxes/NewOutboundFaxes';
import NewDraftFaxes from 'components/practice/comms_center/fax/Drafts/NewDraftFaxes';
import NewArchivedFaxes from 'components/practice/comms_center/fax/ArchivedFaxes/NewArchivedFaxes';
import FormPackets from 'components/practice/settings/forms/FormPackets/FormPackets.jsx';
import AdminFormBuilder from 'components/admin/pages/Practices/FormBuilder/AdminFormBuilder.jsx';
import PdfEditor from 'components/pdfeditor/PDFEditor.jsx';
import NewClaims from 'components/practice/BeyondBilling/Claims/NewClaims';
import PatientClaims from 'components/practice/charts/billing/claims/PatientClaims';
import PracticeIQueue from 'components/practice/PracticeIQueue/index.jsx';
import Overview from 'components/practice/PracticeIQueue/Overview/Overview.jsx';
import ClinicalNotes from 'components/practice/PracticeIQueue/ClinicalNotes/ClinicalNotes.jsx';
import Notifications from 'components/practice/PracticeIQueue/Notifications/Notifications.jsx';
import AppointmentsQueue from 'components/practice/PracticeIQueue/Appointments/AppointmentsQueue.jsx';
import FormPacket from 'components/practice/PracticeIQueue/FormPacket/FormPacket.jsx';
import MyScribeAI from 'components/practice/charts/ClinicalNote/MyScribeAI/MyScribeAI';
import MyScribeTemplate from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyScribeTemplate/MyScribeTemplate.jsx';
import AmbientListening from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/AmbientListening/AmbientListening.jsx';
import EnhanceNote from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/EnhanceNote/EnhanceNote.jsx';
import IncomeIQueue from 'components/practice/BeyondBilling/IncomeIQueue/IncomeIQueue.jsx';
import MyOwnTemplate from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyOwnTemplate/MyOwnTemplate';
import Welcome from 'components/practice/charts/ClinicalNote/MyScribeAI/components/Welcome';
import Intelligence from 'components/admin/pages/Intelligence';
import AdminIntelligenceAiTask from 'components/admin/pages/Intelligence/AiTask';
import AdminIntelligenceModel from 'components/admin/pages/Intelligence/Model';
import AdminIntelligencePrompts from 'components/admin/pages/Intelligence/Prompt';
import Tags from 'components/practice/settings/Tags/Tags.jsx';
import RWTPlan from 'components/public/RWTPlan/RWTPlan';

const CHECKIN_ROUTES = {
  path: 'checkin',
  element: <CheckinSteps />,
  children: [
    { path: 'choices', element: <PatientCheckinEditingSelector /> },
    {
      path: 'personal-info',
      element: <PersonalInformationContainer />
    },
    {
      path: 'form/:id',
      element: <CheckinForms />
    },
    {
      path: 'hipaa',
      element: <HipaaFormContainer />
    },
    {
      path: 'personal-id',
      element: <PersonalID />
    },
    {
      path: 'insurance',
      element: <Insurance />
    },
    {
      path: 'login',
      element: <PatientCheckinLogin isCheckin={true} />
    },
    {
      path: 'payment',
      element: <Payment />
    },
    {
      path: 'pre-auth',
      element: <PreAuth />
    },
    {
      path: 'pay',
      element: <PayPrompt />
    },
    {
      path: 'pay-success',
      element: <PaySuccess />
    },
    {
      path: 'complete',
      element: <CheckinComplete />
    },
    {
      path: 'review',
      element: <Review />
    },
    {
      path: 'past',
      element: <PastForms />
    },
    {
      path: 'return-check',
      element: <ReturnCheck />
    },
    {
      path: '*',
      element: <NotFound portal="checkin" />
    }
  ]
};

const appRoutes = [
  {
    path: '',
    element: <PrivateRoutes type="empty" />,
    children: [
      {
        path: '/',
        element: <Login loginType="user" />
      }
    ]
  },
  {
    path: 'login',
    element: <Login loginType="user" />
  },
  {
    path: 'pdf',
    element: <PdfEditor />
  },
  {
    path: 'logout',
    element: <Logout />
  },
  {
    path: 'terms',
    element: <Terms />
  },
  {
    path: 'rwt-plan',
    element: <RWTPlan />
  },
  {
    path: 'unsubscribe',
    element: <Unsubscribe />
  },
  {
    path: 'mfa-description',
    element: <MFADescription />
  },
  {
    path: 'password/*',
    element: <PasswordReset />,
    children: [
      {
        path: 'request',
        element: <PasswordRequest />
      },
      {
        path: 'reset/:token',
        element: <PasswordSet />
      },
      {
        path: '*',
        element: <NotFound portal="password/request" />
      }
    ]
  },
  CHECKIN_ROUTES,
  {
    path: 'admin/login',
    element: <Login />
  },
  {
    path: 'admin/password/*',
    element: <AdminPasswordReset />,
    children: [
      {
        path: 'request',
        element: <AdminPasswordRequest />
      },
      {
        path: 'reset/:token',
        element: <AdminPasswordSet />
      },
      {
        path: '*',
        element: <NotFound portal="admin" />
      }
    ]
  },
  {
    path: 'ia/:token',
    element: <InstantActionPage />
  },
  {
    path: 'ia/auth/:token',
    element: <InstantAuth />
  },
  {
    path: 'invoice/:token',
    element: <NewInvoice />
  },
  {
    path: 'instant-packet',
    element: <InstantPacket />,
    children: [
      { path: 'personal-info', element: <PersonalInformationContainer /> },
      { path: 'form/:id', element: <CustomForms /> },
      { path: 'hipaa', element: <HipaaFormContainer /> },
      { path: 'personal-id', element: <PersonalID /> },
      { path: 'insurance', element: <Insurance /> },
      { path: 'pre-auth', element: <PreAuth2 /> },
      { path: 'complete', element: <Complete /> },
      { path: 'return-check', element: <ReturnCheck /> },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: 'contact',
    element: <NewContactContainer />
  },
  {
    path: 'terms',
    // element: <TermsAndConditions />
    element: <Terms />
  },
  {
    path: 'mfa-description',
    element: <MFADescription />
  },
  {
    path: 'privacy',
    // element: <PrivacyPolicy />
    element: <Terms />
  },
  {
    path: 'activate',
    element: <UserActivateWrapper />,
    children: [
      {
        path: 'initiate/:token',
        element: <UserActivateInitiate />
      },
      {
        path: 'start',
        element: <UserActivateStart />
      },
      {
        path: 'error',
        element: <UserActivateError />,
        children: [
          {
            path: ':kind',
            element: <UserActivateError />
          },
          {
            path: '*',
            element: <UserActivateError />
          }
        ]
      },
      {
        path: 'confirm-code',
        element: <UserActivateVerifyPhone />
      },
      {
        path: 'new-phone',
        element: <UserActivateNewPhone />
      },
      {
        path: 'success',
        element: <UserActivateSuccess />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: 'book',
    element: <ScheduleAppointment />,
    children: [
      {
        path: 'practice',
        element: <PracticeStep />
      },
      {
        path: 'service',
        element: <ServiceStep />
      },
      {
        path: 'date-and-time',
        element: <DateAndTimeStep />
      },
      {
        path: 'contact-info',
        element: <ContactInformation />
      },
      {
        path: 'card-on-file',
        element: <CardOnFile />
      },
      {
        path: 'confirmation',
        element: <Confirmation />
      },
      {
        path: '*',
        element: <NotFound portal="book" />
      }
    ]
  },
  {
    path: 'kiosk',
    element: <Kiosk />,
    children: [
      { path: '', element: <KioskIdle /> },
      { path: 'idle', element: <KioskIdle /> },
      { path: 'phone', element: <KioskPhone /> },
      { path: 'activate', element: <KioskActivate /> },
      CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound portal="kiosk" />
      }
    ]
  },

  {
    path: 'mobile',
    element: <Mobile />,
    children: [
      { path: 'idle', element: <KioskIdle /> },
      { path: 'phone', element: <KioskPhone /> },
      { path: 'activate', element: <KioskActivate /> },
      CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound portal="kiosk" />
      }
    ]
  },
  {
    path: 'select-practice',
    element: <PreferredLocation />
  },
  {
    path: '',
    element: <PrivateRoutes type="portal" />,
    children: [
      {
        path: 'portal',
        element: <PracticePortal />,
        children: [
          {
            path: '',
            element: <Dashboard />
          },
          {
            path: 'audit',
            children: [{ path: 'logs', element: <AuditLogs /> }]
          },
          {
            path: 'profile',
            element: <ProfileContainer />
          },
          {
            path: 'appointments',
            element: <Calendar />,
            children: [
              {
                path: ':id',
                element: <Calendar />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'charts',
            element: <PatientCharts />,
            children: [
              {
                path: ':id',
                element: <PatientChart />,
                children: [
                  {
                    path: '',
                    element: <OverviewContainer />
                  },
                  {
                    path: 'demographics',
                    element: <Demographics />
                  },
                  {
                    path: 'appointments/*',
                    element: <Appointments />,
                    children: [
                      {
                        path: '',
                        element: <AppointmentsRow type="upcoming" />
                      },
                      {
                        path: ':type',
                        element: <AppointmentsRow type="past" />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'eprescribe',
                    element: <Actions />
                  },
                  {
                    path: 'billing/*',
                    element: <Billing />,
                    children: [
                      {
                        path: 'ledger',
                        element: <Ledger />
                      },
                      {
                        path: 'claims',
                        element: <PatientClaims />,
                        children: [
                          {
                            path: ':type',
                            element: <PatientClaims />,
                            children: [
                              {
                                path: ':claimId',
                                element: <CMS1500 />
                              }
                            ]
                          },
                          {
                            path: '*',
                            element: <NotFound />
                          }
                        ]
                      },
                      {
                        path: 'invoices',
                        element: <BillingInvoices />
                      },
                      {
                        path: '',
                        element: <BillingTransactions />
                      },
                      {
                        path: 'superbill',
                        element: <BillingSuperbill />,
                        children: [
                          {
                            path: ':superbill',
                            element: <SuperbillPage />
                          }
                        ]
                      },

                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'documents',
                    element: <Documents />
                  },
                  {
                    path: 'communications',
                    element: <Communications />,
                    children: [
                      {
                        path: '',
                        element: <CommunicationMessages />
                      },
                      {
                        path: ':logs',
                        element: <CommunicationLogs />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'settings',
                    element: <PracticePatientSettings />
                  },
                  {
                    path: 'clinical-notes',
                    element: <AllClinicalNotes />,
                    children: [
                      {
                        path: ':appointmentId',
                        element: <ClinicalNote />,
                        children: [
                          {
                            element: <CurrentNote />,
                            children: [
                              {
                                path: ':type',
                                element: <React.Component />,
                                children: [
                                  {
                                    path: ':formName',
                                    element: <React.Component />
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            path: 'myscribe-ai',
                            element: <MyScribeAI />,
                            children: [
                              {
                                path: '',
                                element: <Welcome />
                              },
                              {
                                path: 'map-my-own-template',
                                element: <MyOwnTemplate />
                              },
                              {
                                path: 'map-template-with-myscribe',
                                element: <MyScribeTemplate />
                              },
                              {
                                path: 'ambient-listening',
                                element: <AmbientListening />
                              },
                              {
                                path: 'enhance-current-note',
                                element: <EnhanceNote />
                              },
                              {
                                path: '*',
                                element: <NotFound />
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'account',
                    element: <PatientAccount />,
                    children: [
                      {
                        path: '',
                        element: <PurchasedPackages />
                      },
                      {
                        path: 'purchased-packages',
                        element: <PurchasedPackages />
                      },
                      {
                        path: 'quotes',
                        element: <Quotes />
                      },
                      {
                        path: 'settings',
                        element: <PracticePatientSettings />
                      },
                      {
                        path: 'cards',
                        element: <CConFile />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'beyond-billing',
            element: <BeyondBilling />,
            children: [
              {
                path: 'overview',
                element: <BeyondBillingOverview />
              },
              {
                path: 'income-i-queue',
                element: <IncomeIQueue />
              },
              {
                path: 'payments',
                element: <Payments />
              },
              {
                path: 'patient-ledger',
                element: <PatientLedger name="patient_ledger" />
              },
              {
                path: 'dynamic-ar',
                element: <DynamicAR />,
                children: [
                  {
                    path: ':type',
                    element: <DynamicAR />
                  }
                ]
              },
              {
                path: 'era-eob',
                element: <EraWrapper />,
                children: [
                  {
                    path: ':type',
                    element: <EraWrapper />,
                    children: [
                      {
                        path: ':id',
                        element: <View />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'claims',
                element: <NewClaims />,
                children: [
                  {
                    path: ':type',
                    element: <NewClaims />,
                    children: [
                      {
                        path: ':claimId',
                        element: <CMS1500 />
                      }
                    ]
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'superbills',
                element: <Superbills />,
                children: [
                  {
                    path: ':state',
                    element: <Superbills />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'electronic-invoices',
                element: <ElectronicInvoicing />,
                children: [
                  {
                    path: '',
                    element: <InternalInvoices />
                  },
                  {
                    path: 'external',
                    element: <ExternalInvoices />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'comms-center',
            element: <CommsCenter />,
            children: [
              {
                path: 'fax',
                element: <Fax />,
                children: [
                  { path: 'main', element: <Main /> },
                  { path: 'inbound', element: <NewInboundFaxes /> },
                  { path: 'outbound', element: <NewOutboundFaxes /> },
                  { path: 'drafts', element: <NewDraftFaxes /> },
                  { path: 'Archived', element: <NewArchivedFaxes /> },
                  { path: 'cover-pages', element: <CoverPages /> }
                ]
              },
              {
                path: 'contacts',
                element: <Contacts />
              }
            ]
          },
          {
            path: 'practice-i-queue',
            element: <PracticeIQueue />,
            children: [
              {
                path: 'overview',
                element: <Overview />
              },
              {
                path: 'tasks',
                element: <Tasks />,
                children: [
                  {
                    path: ':id',
                    element: <Tasks />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'clinical-notes',
                element: <ClinicalNotes />
              },
              {
                path: 'notifications',
                element: <Notifications />
              },
              {
                path: 'appointments',
                element: <AppointmentsQueue />
              },
              {
                path: 'forms',
                element: <FormPacket />
              }
            ]
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              {
                path: 'services',
                element: <Services />
              },
              {
                path: 'products',
                element: <Products />
              },
              {
                path: 'packages',
                element: <Packages />
              },
              {
                path: 'calendar',
                element: <CalendarSettings />
              },
              {
                path: 'forms',
                element: <Forms />,
                children: [
                  { path: '', element: <FormBuilder /> },
                  { path: 'form-packets', element: <FormPackets /> }
                ]
              },
              {
                path: 'payers',
                element: <Payers />
              },
              {
                path: 'account',
                element: <Account />
              },
              {
                path: 'staff',
                element: <Staff />
                // children: [
                //   {
                //     path: ':id',
                //     element: <EditStaffMember />
                //   }
                // ]
              },
              {
                path: 'roles',
                element: <Roles />
              },
              {
                path: 'eprescribe',
                element: <EPrescribeSettings />
              },
              {
                path: 'billing',
                element: <BillingSettings />
              },
              {
                path: 'kiosk',
                element: <KioskSettings />
              },
              {
                path: 'resources',
                element: <Resources />
              },
              {
                path: 'tags',
                element: <Tags />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: '*',
            element: <NotFound portal="portal" />
          }
        ]
      }
    ]
  },
  {
    path: 'icons',
    element: <IconsPage />
  },
  {
    path: '',
    element: <PrivateRoutes type="admin" />,
    children: [
      {
        path: 'admin',
        element: <Admin />,
        children: [
          {
            path: '',
            element: <AdminDashboard />
          },
          {
            path: 'practices',
            element: <Practices />,
            children: [
              {
                path: ':id',
                element: <PracticeProfile />,
                children: [
                  {
                    path: '',
                    element: <PracticeOverviewContainer />
                  },
                  {
                    path: 'staff',
                    element: <AdminStaff />
                  },
                  {
                    path: 'patients',
                    element: <PracticePatients />
                  },
                  {
                    path: 'forms',
                    element: <AdminForms />,
                    children: [
                      {
                        path: 'create',
                        element: <NewFormBuilderContainer />
                      },
                      {
                        path: 'edit/:formId',
                        element: <EditFormBuilderContainer />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'settings',
                    element: <AdminSettings />
                  },
                  {
                    path: 'terminals',
                    element: <TerminalManagement />
                  },
                  {
                    path: 'roles',
                    element: <Roles />
                  },
                  {
                    path: 'staff-management',
                    element: <Staff />
                  },
                  {
                    path: 'kiosk',
                    element: <KioskSettings />
                  },
                  {
                    path: 'patient-import',
                    element: <PatientImport />
                  },
                  {
                    path: 'billing',
                    element: <AdminBilling />
                  },
                  {
                    path: 'facility',
                    element: <FacilityProvider />
                  },
                  {
                    path: 'transactions',
                    // element: <AdminBilling />
                    element: <AdminPracticesTransactions />
                  },
                  {
                    path: 'organization',
                    // element: <AdminBilling />
                    element: <AdminPracticesOrganization />
                  },
                  {
                    path: 'form-builder',
                    element: <AdminFormBuilder />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'practitioners',
            element: <Practitioners />,
            children: [
              {
                path: ':id',
                element: <PractitionerProfile />,
                children: [
                  {
                    path: '',
                    element: <PractitionersBasicInfo />
                  },
                  {
                    path: 'patients',
                    element: <PractitionersPatients />
                  },
                  {
                    path: 'eprescribe',
                    element: <PrescriptionManagement />
                  },
                  {
                    path: 'practices',
                    element: <AdminPractitionersPractices />
                  },
                  {
                    path: 'settings',
                    element: <AdminPractitionersSettings />
                  },
                  {
                    path: 'logs',
                    element: <AdminPractitionersLogs />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'new',
                element: <NewPractitionerContainer />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'patients',
            element: <AdminPatients />,
            children: [
              {
                path: ':id',
                element: <PatientProfile />,
                children: [
                  {
                    path: '',
                    element: <AdminPatientBasicInfo />
                  },
                  {
                    path: 'logs',
                    element: <AdminPatientsLogs />
                  },
                  {
                    path: 'transactions',
                    element: <AdminPatientsTransactions />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'users',
            element: <AdminUser />,
            children: [
              {
                path: ':id',
                element: <AdminUserUser />,
                children: [
                  {
                    path: '',
                    element: <AdminUserOverview />
                  },
                  {
                    path: 'logs',
                    element: <AdminUserLogs />
                  },
                  {
                    path: 'sessions',
                    element: <AdminUserSessions />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'reporting-center',
            element: <ReportingDashboard />
          },
          {
            path: 'databank',
            element: <Databank />,
            children: [
              {
                path: ':type',
                element: <BaseDatapointPage />
              },
              {
                path: 'cpt-codes',
                element: <CPTCodes />
              },
              {
                path: 'icd10-codes',
                element: <ICD10Codes />
              },
              {
                path: 'occupations',
                element: <Occupations />
              },
              {
                path: 'growth_data',
                element: <GrowthData />
              },
              {
                path: 'landmarks',
                element: <Landmarks />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'access-logs',
            element: <AccessLogs />
          },
          {
            path: 'requests',
            element: <Requests />,
            children: [
              {
                path: 'payers',
                element: <PayerRequests />
              }
            ]
          },
          {
            path: 'settings',
            element: <SettingsContainer />
          },
          {
            path: 'intelligence',
            element: <Intelligence />,
            children: [
              {
                path: 'ai_task',
                element: <AdminIntelligenceAiTask />
              },
              {
                path: 'model',
                element: <AdminIntelligenceModel />
              },
              {
                path: 'prompts',
                element: <AdminIntelligencePrompts />
              }
            ]
          },
          {
            path: '*',
            element: <NotFound portal="admin" />
          }
        ]
      }
    ]
  },
  {
    path: '',
    element: <PrivateRoutes type="" />,
    children: [
      {
        path: 'myportal',
        element: <PatientPortal />,
        children: [
          {
            path: '',
            element: <PatientDashboard />
          },
          {
            path: 'inbox',
            element: <PatientInbox />
          },
          {
            path: 'inbox/:id',
            element: <PatientConversation />
          },
          {
            path: 'medical-records',
            element: <PatientMedicalRecords />
          },
          {
            path: 'treatment-plans',
            element: <PatientTreatmentPlans />
          },
          {
            path: 'appointments',
            element: <PatientAppointments />
          },
          {
            path: 'questionnaires',
            element: <PatientQuestionnaires />
          },
          {
            path: 'medications',
            element: <PatientMedications />
          },
          {
            path: 'referrals',
            element: <PatientReferrals />
          },
          {
            path: 'superbills',
            element: <PatientSuperbills />
          },
          {
            path: 'documents',
            element: <PatientDocuments />
          },
          {
            path: 'account',
            element: <AccountContainer />
          },
          {
            path: 'invoices',
            element: <PatientInvoicesContainer />
          },
          {
            path: '*',
            element: <NotFound portal="myportal" />
          }
        ]
      },
      // CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export const Routes = () => {
  const routes = useRoutes(appRoutes);

  return routes;
};
