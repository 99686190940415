import { Popover } from '@headlessui/react';
import React from 'react';
import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import cs from 'classnames';

export default function SendAsTextPopover({ callback, loading = false }) {
  return (
    <Popover className="h-full">
      {({ open, close }) => (
        <div className="h-full">
          <Popover.Button
            as={Icon}
            disabled={loading?.message_sent}
            className={cs(
              'relative flex h-8 w-9 min-w-0 items-center justify-center rounded-[5px] bg-primary-500 p-0',
              open && 'rotate-180'
            )}
            icon="arrow-popover"
            iconColor='white'
            iconIsStroke
            loading={loading?.message_sent}
          />

          <Popover.Panel className="absolute right-0 bottom-[calc(100%_+_10px)] z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
            <div
              className="flex gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
              onClick={(e) => {
                callback(e);
                close();
              }}>
              <Icon icon="message" className="flex items-center" />
              <div className="text-xs text-primary-900">Send as text message</div>
            </div>
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
}
