import React from 'react';

import Button from 'components/shared/Buttons/Button';

const ButtonActions = () => {
  const handleDelete = () => {
    console.log('delete');
  };

  const handlePerformActions = () => {
    console.log('perform');
  };
  return (
    <>
      <Button
        data-qa="delete-selected-claims"
        onClick={handleDelete}
        text="Delete"
        type="link"
        color="danger"
        icon="new-trash-patient"
        className="!text-danger-500"
        iconSize={18}
      />
      <Button text="Perform selected actions" size="small" onClick={handlePerformActions} />
    </>
  );
};

export default ButtonActions;
