import {
  appointmentBalanceState,
  appointmentCCState,
  appointmentCheckinState,
  appointmentConfirmState,
  appointmentDataTypeState,
  claimState,
  clinicalNoteStatus,
  superbillStatus
} from 'constants';

import { getPractitioners } from 'api/Practitioner';
import { getResourcesAsOptions } from 'api/Resource';
import { getServices } from 'api/Service';
import { getTags } from 'api/Tags';

export const apptDefaultFilters = ({ searchPatients, arrivalStatuses }) => ({
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: (e) => searchPatients(e),
    values: [],
    title: 'Patients',
    placeholder: 'Search patients'
  },
  practitioners: {
    type: 'infinite-scroll',
    multiple: true,
    values: [],
    title: 'Providers',
    placeholder: 'Select providers',
    icon: false,
    queryKey: 'practitioners',
    queryFn: getPractitioners
  },
  resources: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Resources',
    placeholder: 'Select resources',
    icon: false,
    queryKey: 'resources',
    queryFn: getResourcesAsOptions
  },
  serviceType: {
    type: 'infinite-scroll',
    multiple: true,
    title: 'Service',
    placeholder: 'Search services',
    values: [],
    icon: false,
    queryKey: 'services',
    queryFn: getServices,
    params: { limit: 25, withCount: true }
  },
  appointmentTags: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Tags',
    placeholder: 'Select appointment tags',
    icon: false,
    queryKey: 'tags',
    queryFn: getTags,
    params: { kind: 'appointment' }
  },
  arrivalStatus: {
    type: 'search',
    multiple: true,
    values: [],
    icon: false,
    options: arrivalStatuses,
    title: 'Arrival status',
    placeholder: 'Select arrival statuses'
  },
  appointmentDataType: {
    type: 'checkbox',
    values: [],
    options: appointmentDataTypeState,
    title: 'Select Data Type'
  },
  confirmState: {
    type: 'checkbox',
    values: null,
    options: appointmentConfirmState,
    title: 'Confirmation'
  },
  checkinState: {
    type: 'checkbox',
    values: null,
    options: appointmentCheckinState,
    title: 'Checkin'
  },
  balanceState: {
    type: 'checkbox',
    values: null,
    options: appointmentBalanceState,
    title: 'Balance'
  },
  CCState: {
    type: 'checkbox',
    values: null,
    options: appointmentCCState,
    title: 'Card on file'
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: null,
    options: clinicalNoteStatus,
    title: 'Clinical note status'
  },
  superbillStatus: {
    type: 'checkbox',
    values: null,
    options: superbillStatus,
    title: 'Superbill'
  },
  claimState: {
    type: 'checkbox',
    values: null,
    options: claimState,
    title: 'Claim'
  }
});

export default apptDefaultFilters;
