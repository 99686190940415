import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import Icon from '../../../../../shared/Icon/Icon';
import Input from '../../../../../shared/Input/Input';
import Modal from '../../../../../shared/Modal/Modal';
import Button from '../../../../../shared/Buttons/Button';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import ProfilePicture from '../../../../../Profile/ProfilePicture';

import { usePractitioners } from '../../../../../../lib/hooks/queries/practitioners/usePractitioners';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ia, iaRa } from '../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../shared/Alert/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import { manualAddDocumentSign } from 'api/DocumentSign';
import { manuallyAddDocumentSign } from '../helpers/documentSignatureHelpers';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { clinicalNote as clinicalNoteState } from 'components/state';

const ProvidersModal = ({
  openModal = false,
  closeModal = () => null,
  disabledProviders = [],
  manuallySelectedProviders = []
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { pathname } = useLocation();
  const practice = useRecoilValue(currentPractice);
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState(manuallySelectedProviders);
  const [filters, setFilters] = useState({
    search: ''
  });

  const { data: providers, isFetching } = usePractitioners(null, {
    select: ({ practitioners }) => practitioners
  });

  useEffect(() => {
    if (ia(providers)) setAllProviders(providers);
  }, [providers]);

  useEffect(() => {
    filterProviders();
  }, [filters]);

  const queryClient = useQueryClient();
  const addDocumentSignProvidersMutation = useMutation(
    (data) => manualAddDocumentSign(navigate, data),
    {
      onSettled: () => {
        showAlert({
          title: 'Success!',
          message: `Successfully updated document sign provider${
            ia(selectedProviders, 1) ? 's' : ''
          }!`,
          color: 'success'
        });

        queryClient.invalidateQueries(['documentSign']);
        closeModal();
      }
    }
  );

  const loading = addDocumentSignProvidersMutation?.isLoading;

  const filterProviders = () => {
    if (filters.search === '') {
      setAllProviders(providers);
    }

    const filtered = iaRa(providers).filter((provider) => {
      return (
        provider.f_name.toLowerCase().includes(filters.search.toLowerCase()) ||
        provider.l_name.toLowerCase().includes(filters.search.toLowerCase())
      );
    });

    setAllProviders(filtered);
  };

  const divRef = useRef();
  const navigate = useNavigate();

  const onCheckboxChange = ({ event, providerId }) => {
    if (event.target.checked) {
      setSelectedProviders((prev) => [...prev, providerId]);
    } else {
      setSelectedProviders((prev) => prev.filter((id) => id !== providerId));
    }
  };

  const enhancedCloseModal = () => !loading && closeModal();

  return (
    <Modal
      handleOpen={!!openModal}
      handleClose={() => enhancedCloseModal()}
      title="Assign providers to this document"
      slideFromRight
      customStyling={{ width: '540px' }}
      overlayClassName="z-[301]"
      footer={
        <div className="flex w-full justify-between">
          <Tippy
            disabled={!loading}
            content="Please wait while the process is done"
            className="tippy-dark">
            <div>
              <Button
                outlined
                disabled={loading}
                text="Cancel"
                color="neutral"
                onClick={() => {}}
                data-qa="cancel-select-files-btn"
              />
            </div>
          </Tippy>
          <Button
            disabled={!selectedProviders}
            onClick={() =>
              manuallyAddDocumentSign({
                clinicalNote,
                manuallySelectedProviders,
                selectedProviders,
                enhancedCloseModal,
                pathname,
                addDocumentSignProvidersMutation,
                practice
              })
            }
            text="Assign to providers"
            id="createContactModalBtn"
            data-qa="select-provider-btn"
            loading={loading}
          />
        </div>
      }>
      <div className="!mt-1 rounded-lg bg-white !p-2 shadow-sm">
        <Input
          id="searchProvider"
          type="string"
          placeholder="Search providers..."
          value={filters.search}
          rounded="full"
          icon="new-search"
          className="!mr-5 !rounded-2xl !border-secondary-400 "
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
          disabled={isFetching}
          rightText={
            filters.search && (
              <Icon
                icon="new-close"
                onClick={() => setFilters((prev) => ({ ...prev, search: '' }))}
              />
            )
          }
        />

        {isFetching ? (
          <Skeleton count={13} />
        ) : (
          <div
            className="!mt-4 flex max-h-[calc(100vh-230px)] flex-col !gap-4 overflow-y-auto"
            ref={divRef}>
            {allProviders?.map((provider) => {
              const fullName = `${provider?.f_name} ${provider?.l_name}`;
              const cantBeModified = disabledProviders.includes(provider?.id);

              return (
                <div key={provider?.id} className="flex cursor-pointer items-center gap-3">
                  <Tippy
                    content="This user was automatically assigned by the system and cannot be altered."
                    disabled={!cantBeModified}
                    className="tippy-dark">
                    <div className="flex w-[25px]">
                      <Checkbox
                        value={true}
                        id={provider.id}
                        disabled={cantBeModified}
                        name="triedRest"
                        isChecked={selectedProviders.includes(provider.id) || cantBeModified}
                        onChange={(event) => onCheckboxChange({ event, providerId: provider.id })}
                      />
                    </div>
                  </Tippy>
                  <ProfilePicture
                    firstName={provider?.f_name}
                    lastName={provider?.l_name}
                    id={provider?.id}
                    size={36}
                  />
                  <h3 className="text-base font-500 text-neutral-900">{fullName}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ProvidersModal;
