import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { ia } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import AdjustmentsFields from './AdjustmentsFields';
import ClaimFields from './ClaimFields';
import ProceduresFields from './ProceduresFields';
import { handleAcceptAll } from './lib/helper';

const SyncForm = ({ claim = {}, pendingClaimMdChanges = [], breadcrumb = {} }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const primaryProcedures = claim?.procedures?.filter(
    (proc) => proc?.type === 'primary' && proc?.procedure_code && !proc?.invoiceId
  );

  return (
    <div className="rounded-md p-2">
      <div className="flex justify-end">
        <Button
          text="Accept All"
          type="secondary"
          color="success"
          className="!py-1"
          outlined
          onClick={() => handleAcceptAll(claim?.id, navigate, queryClient, breadcrumb)}
        />
      </div>
      <div className="mt-3">
        {ia(pendingClaimMdChanges) &&
          pendingClaimMdChanges?.map((change) => (
            <>
              {change?.kind === 'claim' && (
                <ClaimFields claim={claim} change={change} breadcrumb={breadcrumb} />
              )}

              {change?.kind === 'invoice' && (
                <ProceduresFields
                  change={change}
                  primaryProcedures={primaryProcedures}
                  breadcrumb={breadcrumb}
                />
              )}

              {change?.kind === 'adjustment' && (
                <AdjustmentsFields change={change} breadcrumb={breadcrumb} />
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(SyncForm);
