import React from 'react';
import css from './spinner.scss';

const Spinner = ({ wrapperStyle }) => {
  return (
    <div className="relative m-1 !h-12 !w-12" style={wrapperStyle}>
      <div className="circle material"></div>
    </div>
  );
};

export default Spinner;
