import cs from 'classnames';
import ProviderMedications from 'components/shared/Forms/Custom/ProviderMedications/ProviderMedications';
import React from 'react';

const MedicationsSection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = [],
  isResizable
}) => {
  return (
    // <Accordion
    //   updateFormType={{ name: 'Medications', parentKey: 'hp', childKey: 'medications' }}
    //   title={components?.medications?.title}
    //   id={components?.medications?.id}
    //   disabled={clinicalNote?.locked}>
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-106px)] text-sm text-neutral-600 duration-200">
        <ProviderMedications options={options} isResizable={isResizable} />
        {/* <CurrentMedications
          formId={formId}
          fromClinicalNote={true}
          defaultValue={defaultValue}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          showNarrative
          setCurrentFormData={setCurrentFormData}
        /> */}
      </div>
    </div>
    // </Accordion>
  );
};

export default MedicationsSection;
