import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { genders, insurancePatientRelation } from 'constants';

import { requestApi } from 'api/Api';

import { findRelationName, findStateName, formatDate, optionify } from 'lib/helpers/utility';

import SearchPatients from 'components/Search/SearchPatients/SearchPatients';
import DatePicker from 'components/shared/DatePicker/DatePicker';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

const GuarantorForms = ({ states, formik, isTablet }) => {
  const navigate = useNavigate();

  const getData = async (id) => {
    try {
      const res = await requestApi({
        url: '/api/patient/get/one',
        params: {
          id
        },
        navigate
      });

      if (res) {
        const { patient: loadedPatient } = res;

        const guarantorAddress = loadedPatient?.userAddress || {
          address_ln_1: '',
          address_ln_2: '',
          zip: '',
          city: '',
          state: ''
        };

        formik.setValues({
          ...formik.values,
          f_name: loadedPatient?.f_name,
          m_name: loadedPatient?.m_name,
          l_name: loadedPatient?.l_name,
          dob: loadedPatient?.dob,
          gender: loadedPatient?.gender,
          email: loadedPatient?.email,
          home_phone: loadedPatient?.phone,
          work_phone: loadedPatient?.alt_phone,
          guarantorAddress
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later. `);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div className={cs('grid gap-4', isTablet && 'grid-cols-2')}>
      <div className={cs('grid gap-y-4 rounded-lg ', isTablet && 'col-span-2 grid-cols-5 gap-4')}>
        <SearchPatients
          width="100%"
          className="bg-white"
          label="Search existing patient"
          isClearable={false}
          handleClick={(e) => getData(e.value)}
        />
        <Input
          label="First Name"
          placeholder="First Name"
          name="f_name"
          value={formik.values.f_name}
          error={formik?.errors?.f_name}
          onChange={formik.handleChange}
          id="guarantor_f_name"
          data-qa="guarantor-f-name"
        />
        <Input
          label="Middle Name"
          placeholder="Middle Name"
          name="m_name"
          value={formik.values.m_name}
          error={formik?.errors?.m_name}
          onChange={formik.handleChange}
          id="guarantor_m_name"
          data-qa="guarantor-m-name"
        />

        <Input
          label="Last Name"
          placeholder="Last Name"
          name="l_name"
          value={formik.values.l_name}
          error={formik?.errors?.l_name}
          onChange={formik.handleChange}
          id="guarantor_l_name"
          data-qa="guarantor-l-name"
        />

        <Select
          label="Relationship"
          className="select-full-width"
          placeholder="Type and select a relationship"
          value={
            formik.values.relationship
              ? {
                  value: formik.values.relationship,
                  label: findRelationName(formik.values.relationship)
                }
              : null
          }
          onChange={(v) => formik.setFieldValue('relationship', v ? v.value : '')}
          options={insurancePatientRelation.filter((item) => item.value !== '18')}
          inputId="guarantor_relationship"
          data-qa="guarantor-relationship"
          error={formik?.errors?.relationship}
        />

        <DatePicker
          label="Date of Birth"
          error={formik?.errors?.dob}
          value={formik.values.dob ? formatDate(formik.values.dob) : ''}
          onChange={(date) => {
            formik.setFieldValue('dob', date);
          }}
        />

        <Select
          inputId="guarantor_gender-select"
          name="gender"
          label="Gender"
          placeholder="Type and select gender"
          options={optionify(genders, 'label', 'value')}
          onChange={(e) => formik.setFieldValue('gender', e?.value)}
          value={genders.find((l) => l?.value === formik.values.gender)}
          error={formik?.errors?.gender}
        />

        <Input
          label="Email"
          placeholder="Email"
          name="email"
          value={formik.values.email}
          error={formik?.errors?.email}
          onChange={formik.handleChange}
          id="guarantor_email"
          data-qa="guarantor-email"
        />

        <Input
          label="Home Phone"
          placeholder="Home phone"
          name="home_phone"
          value={formik.values.home_phone}
          error={formik?.errors?.home_phone}
          onChange={formik.handleChange}
          id="guarantor_home_phone"
          data-qa="guarantor-home-phone"
        />

        <Input
          label="Work Phone"
          placeholder="Work phone"
          name="work_phone"
          value={formik.values.work_phone}
          error={formik?.errors?.work_phone}
          onChange={formik.handleChange}
          id="guarantor_work_phone"
          data-qa="guarantor-work-phone"
        />

        <Input
          label="Address 1"
          placeholder="Address"
          name="guarantorAddress.address_ln_1"
          value={formik.values.guarantorAddress.address_ln_1}
          onChange={formik.handleChange}
          id="guarantor_address_address_ln_1"
          data-qa="guarantor-address-ln-1"
          error={formik?.errors?.guarantorAddress?.address_ln_1}
        />
        <Input
          label="Address 2"
          placeholder="Address 2"
          name="guarantorAddress.address_ln_2"
          value={formik.values.guarantorAddress.address_ln_2}
          onChange={formik.handleChange}
          id="guarantor_address_address_ln_2"
          data-qa="guarantor-address-ln-2"
        />
        <Input
          label="City"
          placeholder="City"
          value={formik.values.guarantorAddress.city}
          name="guarantorAddress.city"
          onChange={formik.handleChange}
          id="guarantor_address_city"
          data-qa="guarantor-address-city"
          error={formik?.errors?.guarantorAddress?.city}
        />
        <Input
          label="Zip"
          placeholder="Zip"
          value={formik.values.guarantorAddress.zip}
          name="guarantorAddress.zip"
          onChange={formik.handleChange}
          id="guarantor_address_zip"
          data-qa="guarantor-zip"
          error={formik?.errors?.guarantorAddress?.zip}
        />
        <Select
          label="State"
          placeholder="Type and select a state"
          value={
            formik?.values?.guarantorAddress.state
              ? {
                  label: findStateName({ states, stateId: formik?.values?.guarantorAddress.state }),
                  value: formik?.values?.guarantorAddress.state
                }
              : ''
          }
          onChange={(v) => {
            formik.setFieldValue('guarantorAddress.state', v?.value);
          }}
          options={states}
          inputId="guarantor_address_state"
          error={formik?.errors?.guarantorAddress?.state}
        />
      </div>
    </div>
  );
};

export default GuarantorForms;
