import { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { ia, iaRa } from 'lib/helpers/utility';
import { usePreferredTestList } from 'lib/hooks/queries/LabSoftOrder/usePreferredTestList';
import { useSearchTests } from 'lib/hooks/queries/LabSoftOrder/useSearchTests';

const useTestsData = ({ filters = {} }) => {
  const [preferredTests, setPreferredTests] = useState([]);
  const [searchedTests, setSearchedTests] = useState([]);

  const { searchTerm, orderCodes, selectedLab, searchType } = filters;

  const isSearch = !!searchTerm || !!orderCodes;

  const queryClient = useQueryClient();
  const labsData = queryClient.getQueryData(['refLabs']);
  const labs = labsData?.data;
  const labsObj = useMemo(() => {
    if (ia(labs)) {
      const obj = labs.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.orderCodePrefix]: {
            displayName: curr.displayName,
            systemName: curr.systemName
          }
        };
      }, {});

      return obj;
    }
  }, [labs]);

  const addLabsToTests = (data) =>
    iaRa(data).map((test) => {
      test.refLabDisplayName = labsObj?.[test.labPrefix].displayName;
      test.refLabSystemName = labsObj?.[test.labPrefix].systemName;
      return test;
    });

  const {
    isLoading: preferredIsLoading,
    isFetching: preferredIsFetching,
    data: preferredTestsData
  } = usePreferredTestList({
    options: {
      initialValues: { data: { tests: [] } },
      select: (res) => iaRa(res?.data)
    }
  });

  useEffect(() => {
    if (ia(preferredTestsData)) {
      const testsWithLabs = addLabsToTests(preferredTestsData);
      setPreferredTests(testsWithLabs);
    }
  }, [preferredTestsData]);

  const { isFetching: searchIsFetching } = useSearchTests({
    dependencies: [searchType === 'name' ? searchTerm : orderCodes, selectedLab?.value],
    params: {
      name: searchType === 'name' ? searchTerm : '',
      orderCodes: searchType === 'order-code' ? orderCodes : '',
      reflabPrefixes: selectedLab?.value
    },
    options: {
      enabled: isSearch,
      initialValues: { data: [] },
      select: (res) => iaRa(res?.data),
      onSuccess(data) {
        const testsWithLabs = addLabsToTests(data);
        setSearchedTests(testsWithLabs);
      }
    }
  });

  const tests = iaRa(isSearch ? searchedTests : preferredTests);
  const loading = isSearch ? searchIsFetching : preferredIsLoading || preferredIsFetching;

  return {
    preferredTestsData,
    tests,
    loading
  };
};

export default useTestsData;
