const { requestApi } = require('api/Api');
const { isItemNull } = require('../../Overview/widgets/Charts/ChartRow');
const Honeybadger = require('@honeybadger-io/js');

exports.getVitals = async ({ patientId }) => {
  const vitalsRes = await requestApi({
    url: '/api/clinical_note/vitals/read',
    params: {
      patientId,
      withal: true
    }
  });

  const vitalsData = vitalsRes?.vitals || [];

  const formattedVitals = vitalsData.map((group) => ({
    appointment_id: group.appointment_id,
    created_by: group.created_by,
    created_at: group.created_at,
    vitals: group.vitals.filter(
      (v) =>
        !isItemNull(v?.heart_rate) ||
        !isItemNull(v?.respirations) ||
        !isItemNull(v?.blood_pressure_systolic) ||
        !isItemNull(v?.blood_pressure_diastolic) ||
        !isItemNull(v?.weight) ||
        !isItemNull(v?.temperature) ||
        !isItemNull(v?.height) ||
        !isItemNull(v?.bmi) ||
        !isItemNull(v?.oxygen_level) ||
        !isItemNull(v?.head_circumference)
    )
  }));

  return formattedVitals;
};

exports.getCustomFormResponse = async ({
  patientId,
  type = [
    'allergies',
    'medicalHistory',
    'vaccines',
    'conditions',
    'familyHistory',
    'socialHistory',
    'surgicalHistory',
    'weightLossAdministration'
  ]
}) => {
  try {
    const customResponsesRes = await requestApi({
      url: '/api/custom_form_response/read',
      params: {
        patientId,
        allVersions: true,
        type
      }
    });

    let { response } = customResponsesRes;

    return response;
  } catch (error) {
    console.log({ error });
  }
};

exports.getPatientHistory = async ({
  patientId,
  type,
  setPatientHistoryLoading = () => {},
  setVitals = () => {},
  setOverviewData = () => {}
}) => {
  try {
    setPatientHistoryLoading(true);

    const vitals = await this.getVitals({ patientId });
    const customFormResponse = await this.getCustomFormResponse({ patientId, type });

    setVitals(vitals);
    setOverviewData((prevState) => ({
      ...prevState,
      ...customFormResponse,
      vitals
    }));
  } catch (error) {
    console.error(error);
    Honeybadger.notify(
      `Clinical note get custom responses - patientId: ${patientId}, error: ${error}`
    );
  } finally {
    setPatientHistoryLoading(false);
  }
};
