import { requestApi } from './Api';

export const getSuperbills = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/billing/superbill/superbills',
    params
  });

export const getPatientSuperbills = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/superbill/read',
    params
  });

export const deleteSuperbill = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/billing/superbill/delete',
    params
  });
};

export const completeSuperbill = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/billing/superbill/complete',
    params
  });
};
