import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import VerificationInput from 'react-verification-input';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import './VerifyPhone.scss';
import { AlertContent, showAlert } from '../../shared/Alert/Alert';
import Button from '../../shared/Buttons/Button';
import Icon from '../../shared/Icon/Icon';
import Switch from '../../shared/Switch/Switch';
import getData from './getData';
import state from './state';

const UserActivateVerifyPhone = () => {
  const [user, setUser] = useRecoilState(state.userState);
  const [show, setShow] = useState({
    loading: false,
    resendLoading: false,
    useFor2fa: true,
    error: '',
    errorTitle: ''
  });
  const [code, setCode] = useState('');
  const [practice, setPractice] = useRecoilState(state.practiceState);
  const [progress, setProgress] = useRecoilState(state.progressState);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getData({ navigate, location, setPractice, setShow, setUser });
  }, []);

  const start = async () => {
    if (show.resendLoading) return;
    setShow((ps) => ({ ...ps, resendLoading: true, error: '', errorTitle: '' }));
    const onSuccess = (data, res) => {
      showAlert({
        message: `New code has been sent${
          data?.kind || ` to your ${data.kind}`
        }. Please allow a few minutes for the message to arrive.`,
        title: 'Code Sent!',
        color: 'success'
      });
      setShow((ps) => ({ ...ps, resendLoading: false }));
    };
    const onError = (error, code, data) => {
      setShow((ps) => ({ ...ps, error, errorTitle: 'Error', resendLoading: false }));
    };
    await requestApi({
      url: '/api/user/mfa/start',
      params: {
        mfaId: user?.mfaId
      },
      onSuccess,
      onError,
      navigate
    });
  };

  const complete = () => {
    if (show.loading) return;
    setShow((ps) => ({ ...ps, loading: true, error: '', errorTitle: '' }));
    const onSuccess = (data, res) => {
      navigate(res?.redirect);
    };
    const onError = (error, code, data) => {
      switch (code) {
        case 5:
          setShow((ps) => ({
            ...ps,
            error:
              'Code entered does not match up with a verification code. Please check the code or click below to resend.',
            errorTitle: 'Code Error',
            loading: false
          }));
          break;

        default:
          setShow((ps) => ({ ...ps, error, errorTitle: 'Error', loading: false }));
          break;
      }
    };
    requestApi({
      url: '/api/user/mfa/complete',
      params: { useFor2fa: show.useFor2fa, code, practiceId: practice?.id },
      navigate,
      onSuccess,
      onError,
      filePath: `${__dirname}/${__filename}`
    });
  };

  return (
    <div>
      <div className="my-4 w-full text-lg text-primary-500">
        Step {progress.current}/{progress.total}
      </div>
      <div className="w-full text-3xl font-500 text-primary-900">Verify your phone number</div>
      <p className="mt-10 w-full text-neutral-700">
        We sent a verification code to (***)****-{user.phone}.{' '}
        <div className="text-primary-500" onClick={() => navigate('/activate/new-phone')}>
          Edit Number
        </div>
        <Icon icon="pencil" stroke="primary" />
      </p>
      <VerificationInput
        validChars="0-9"
        onChange={setCode}
        // onComplete={complete}
        inputProps={{ autoComplete: 'one-time-code' }}
        placeholder=""
        classNames={{
          container: '',
          // character: '!border-2 !border-solid !border-primary-500 bg-primary-200 text-primary-600',
          character: '!rounded-lg border-none text-primary-600 aspect-square bg-white shadow',
          characterSelected:
            '!rounded-lg border-none text-primary-600 aspect-square bg-primary-100 shadow border-b-2 border-b-solid border-b-primary-500',
          characterInactive:
            '!rounded-lg border-none text-primary-600 aspect-square bg-white shadow'
        }}
      />
      <p>Code is valid for 5 minutes or 3 attempts.</p>
      <div className="mt-40 flex flex-row">
        <Switch
          checked={show.useFor2fa}
          onChange={(v) => setShow((ps) => ({ ...ps, useFor2fa: v }))}
        />
        <label
          className="ml-4 text-neutral-700"
          onChange={() => setShow((ps) => ({ ...ps, useFor2fa: !ps.useFor2fa }))}>
          Enable 2FA using this number.
        </label>
      </div>
      {show.error && <AlertContent color="warning" title={show.errorTitle} message={show.error} />}
      <Button
        text="Verify & Complete Registration"
        color="primary"
        onClick={complete}
        className="mt-10 w-full"
        disabled={code.length < 6 || show.loading}
        loading={show.loading}
      />
      <Button
        className="w-full bg-transparent text-primary-500"
        text="Resend Code"
        onClick={start}
        disabled={show.resendLoading}
        loading={show.resendLoading}
      />
    </div>
  );
};

export default UserActivateVerifyPhone;
