import React, { useRef } from 'react';
import Accordion from '../shared/Accordion';
import Orders from './components/Orders';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const OrdersSection = ({ components }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState) || {};
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.orders?.title}
      id={components?.orders?.id}
      disabled={clinicalNote?.locked}>
      <Orders />
    </Accordion>
  );
};

export default OrdersSection;
