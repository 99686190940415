import React from 'react';
import { formatDate, formatDateAndTime } from 'lib/helpers/utility';
import PreviewCell from './components/PreviewCell';

export const getColDefs = (timezone, breadcrumb, setPreviewData) => [
  {
    field: 'created_at',
    cellClass: 'no-border pl-[70px]',
    headerName: 'Last Updated',
    valueFormatter: ({ value }) => (value ? formatDateAndTime(value, timezone) : ''),
    headerCheckboxSelection: true,
    checkboxSelection: (params) => {
      return !['allergies', 'vaccines', 'familyHistory', 'surgicalHistory', 'medications'].includes(
        params?.node?.data?.type
      );
    }
  },
  {
    field: 'preview',
    cellClass: 'no-border',
    headerName: 'Preview',
    cellRenderer: ({ data }) =>
      data?.created_at ? (
        <PreviewCell data={data} breadcrumb={breadcrumb} setPreviewData={setPreviewData} />
      ) : null
  }
];

export const DEFAULT_COLUMN_DEFS = { flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  treeData: true,
  groupDisplayType: 'groupRows',
  rowSelection: 'multiple',
  groupSelectsChildren: true,
  suppressRowClickSelection: true,
  groupRowRendererParams: {
    suppressCount: true,
    checkbox: true
  }
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
