import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPractitionerPatients } from 'api/Practitioner';
export const usePractitionerPatients = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['practitionerPatients', ...dependencies],
    () => getPractitionerPatients(params, navigate),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
