import { ia } from 'lib/helpers/utility';
import moment from 'moment';

export const getHours = (currPractice) => {
  const locale = 'en';
  const newHours = [];

  moment.locale(locale);

  const startHr = currPractice?.working_hours?.startsAt?.hour || 0;
  const endHr = currPractice?.working_hours?.endsAt?.hour || 24;

  for (let hour = startHr; hour < endHr; hour++) {
    newHours.push(moment({ hour }).format('hh:mm A'));
    for (let minutes = 1; minutes < 12; minutes++) {
      newHours.push(
        moment({
          hour,
          minute: minutes * 5
        }).format('hh:mm A')
      );
    }
  }
  return newHours;
};

const getDiffOrNull = (val1, val2) => {
  return val1 > 0 || (val1 === 0 && val2 > 0) || (val1 === null && val2 > 0) ? val2 : null;
};

export const getUpdatedAppointmentShape = ({ updatedAppointment, appointment, procedures }) => {
  const { invoice, deductible, co_payment, co_insurance } = appointment || {};

  const {
    invoice: updatedInvoice,
    modifiedDeductible,
    modifiedCoPayment,
    modifiedCoInsurance
  } = updatedAppointment || {};

  let invoiceFields = {};

  const initialValues = invoice;

  for (const key in updatedInvoice) {
    if (updatedInvoice[key] !== initialValues[key]) invoiceFields[key] = updatedInvoice[key];
  }
  let updated = {
    update_appointment: true,
    id: updatedAppointment.id,
    starts_at: updatedAppointment.starts_at,
    ends_at: updatedAppointment.ends_at,
    practitioner_id: updatedAppointment.practitioner_id,
    practice_note: updatedAppointment.practice_note,
    type: updatedAppointment.type,
    status: updatedAppointment.status,
    state: updatedAppointment.state,
    service_id: updatedAppointment.service_id,
    location: updatedAppointment.location,
    modifiedDeductible: getDiffOrNull(deductible, modifiedDeductible),
    modifiedCoPayment: getDiffOrNull(co_payment, modifiedCoPayment),
    modifiedCoInsurance: getDiffOrNull(co_insurance, modifiedCoInsurance),
    form_ids: updatedAppointment.form_ids,
    procedures: updatedAppointment?.procedures,
    products: updatedAppointment?.products,
    virtual_link: updatedAppointment?.virtual_link,
    tag_ids: updatedAppointment?.tag_ids,
    invoice: invoiceFields
  };

  if (
    (ia(appointment?.resources) &&
      appointment.resources[0].id !== updatedAppointment.resource_id) ||
    (!ia(appointment?.resources) && updatedAppointment.resource_id)
  ) {
    updated['resource_id'] = updatedAppointment.resource_id;
  }
  return updated;
};
