import { iaRa } from 'lib/helpers/utility';

const getPractitionerName = (event, practitioners = []) => {
  if (event.type === 'appointment') {
    return event.practitioner?.fullName;
  }
  return iaRa(event.practitioner_ids)
    .map((practitioner_id) => {
      const practitioner = practitioners.find((pract) => pract.id === practitioner_id);
      return practitioner?.f_name + ' ' + practitioner?.l_name;
    })
    .join(', ');
};

export default getPractitionerName;
