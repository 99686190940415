import React from 'react';

import cs from 'classnames';

import { useTagContext } from 'lib/context/TagContext/TagContext';
import { ia } from 'lib/helpers/utility';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import HorizontalScroll from 'components/shared/HorizontalScroll/HorizontalScroll';
import Icon from 'components/shared/Icon/Icon';
import { tagColorOptions } from 'components/shared/Tags/lib/tagColorOptions';

const PatientTags = ({ handleRemove = () => {} }) => {
  const { importantTags, regularTags } = useTagContext();

  return (
    <HorizontalScroll parentClassName="flex-1 overflow-hidden">
      {importantTags?.map((tag, index) => {
        const isNew = tagColorOptions.find((option) => option.color === tag?.color);
        const currBorderColor = tagColorOptions.find(
          (option) => option.color === tag?.color
        )?.border;
        return (
          <div
            key={index}
            className="flex max-h-[25px] items-center gap-[6px] rounded px-2 py-[2px]"
            style={{
              backgroundColor: tag?.color,
              border: `1px solid ${currBorderColor || 'transparent'}`
            }}>
            {tag?.important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
            <span
              className={cs(
                'select-none whitespace-nowrap text-sm font-400 leading-5',
                isNew ? 'text-neutral-900' : 'text-white'
              )}>
              {tag?.name}
            </span>
            <Icon
              icon="new-close-sm"
              size={8}
              color="black"
              stroke
              onClick={() => handleRemove(tag)}
            />
          </div>
        );
      })}
      {ia(regularTags) &&
        regularTags?.map((tag, index) => {
          const isNew = tagColorOptions.find((option) => option.color === tag?.color);
          const currBorderColor = tagColorOptions.find(
            (option) => option.color === tag?.color
          )?.border;
          return (
            <div
              key={index}
              className="flex max-h-[25px] items-center gap-[6px] rounded px-2 py-[2px]"
              style={{
                backgroundColor: tag?.color,
                border: `1px solid ${currBorderColor || 'transparent'}`
              }}>
              <span
                className={cs(
                  'select-none whitespace-nowrap text-sm font-400 leading-5',
                  isNew ? 'text-neutral-900' : 'text-white'
                )}>
                {tag?.name}
              </span>
              <Icon
                icon="new-close-sm"
                size={8}
                color="black"
                stroke
                onClick={() => handleRemove(tag)}
              />
            </div>
          );
        })}
    </HorizontalScroll>
  );
};

export default withErrorBoundary(PatientTags);
