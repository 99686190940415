import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getLocationImportData } from 'api/Admin/Organization';

export const useLocationImportData = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['locationImportData', ...dependencies],
    () => getLocationImportData(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
