/* eslint-disable max-lines */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import {
  claim_md_employment_type,
  claim_md_genders,
  claim_md_marital_status,
  frequencyCode,
  insurancePatientRelation,
  insurance_type,
  tax_id_types,
  yesAndNoOptions
} from 'constants';
import { useRecoilValue } from 'recoil';

import { Capitalize, deepEqual, ia, iaRa, optionify } from 'lib/helpers/utility';
import { useBillingRenderingProviders } from 'lib/hooks/queries/billing/useBillingRenderingProviders';
import { useClaim } from 'lib/hooks/queries/claims/useClaim';
import { useFacilities } from 'lib/hooks/queries/facility/useFacilities';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { formatCmsDateFields } from 'components/practice/charts/Demographics/lib/helpers';
import ICDRow from 'components/practice/charts/SuperBillPage/components/ICDRow';
import { currentPractice } from 'components/practice/practiceState';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import ClaimProcedures from './components/ClaimProcedures/ClaimProcedures';
import Errors from './components/Errors';
import ModalHeader from './components/ModalHeader';
import SyncClaim from './components/SyncClaim/SyncClaim';
import { handleUpdateClaim } from './lib';
import { addDateFields } from './lib/addDateOptions';
import { claimWithInvoiceProcedures } from './lib/claimsWithInvoiceProcedures';
import { getLabelFromOptions, getYesAndNoLabel, refTypeOptions } from './lib/helpers';

const CMS1500 = ({ claim_id, handleClose }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const practice = useRecoilValue(currentPractice);

  const pathnameParts = location.pathname.split('/');
  const claimID = claim_id || pathnameParts[pathnameParts.length - 1];

  const breadcrumb = useBreadcrumb({
    title: <span className="whitespace-nowrap">Claim #{claimID}</span>,
    name: 'claim'
  });
  const { data: billingRenderingProviders } = useBillingRenderingProviders();
  const { data: facilityProviders } = useFacilities({
    params: { practiceId: practice?.id }
  });

  const renderingProviders = useMemo(
    () => optionify(billingRenderingProviders?.providers?.rendering, 'fullName', 'id'),
    [billingRenderingProviders?.providers?.rendering]
  );
  const billingProviders = useMemo(
    () => optionify(billingRenderingProviders?.providers?.billing, 'bill_name', 'id'),
    [billingRenderingProviders?.providers?.billing]
  );

  const facilities = useMemo(
    () => optionify(facilityProviders?.facilityInfo, 'facility_name', 'id'),
    [facilityProviders?.facilityInfo]
  );

  const createFacilityHandler = (name) => {
    const randomId = Math.random().toString(36).substring(2, 15);
    if (facilityProviders?.facilityInfo) {
      facilityProviders.facilityInfo.push({
        facility_name: name,
        id: randomId
      });
      facilities.push({
        label: name,
        value: randomId
      });
    }
  };

  const createBillingHandler = (name) => {
    const randomId = Math.random().toString(36).substring(2, 15);

    if (billingRenderingProviders?.providers?.billing) {
      billingRenderingProviders.providers.billing.push({
        bill_name: name,
        id: randomId
      });
      billingProviders.push({
        label: name,
        value: randomId
      });
    }
  };

  const createRenderingHandler = (name) => {
    const randomId = Math.random().toString(36).substring(2, 15);
    const [l_name, f_name, m_name] = name.split(/[ ,]+/);
    if (billingRenderingProviders?.providers?.rendering) {
      billingRenderingProviders.providers.rendering.push({
        fullName: name,
        l_name,
        f_name,
        m_name,
        id: randomId
      });
      renderingProviders.push({
        label: name,
        value: randomId
      });
    }
  };

  const { steps, step } = breadcrumb;

  const { data, isLoading: claimLoading } = useClaim({
    params: { id: claimID },
    dependencies: [claimID]
  });
  const initialClaim = claimWithInvoiceProcedures({ claim: data?.claim || {} });

  const [claim, setClaim] = useState(initialClaim);
  const [showPayerAddress, setShowPayerAddress] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Failed to update claim!');
  const {
    data: addressData,
    isLoading,
    isFetching
  } = usePayerAddress({
    params: { payerId: claim?.payerid, withCount: true },
    dependencies: [claim?.payerid]
  });
  const address = addressData?.address;

  const hasChanges = useMemo(() => {
    return !deepEqual(claim, initialClaim);
  }, [claim, initialClaim]);

  useEffect(() => {
    if (data?.claim) {
      const processedClaim = claimWithInvoiceProcedures({ claim: data?.claim || {} });
      setClaim(processedClaim);
      const preFilledFields = addDateFields.filter((field) => processedClaim?.[field.value]);
      setAddedDateFields(preFilledFields);
    }
  }, [data]);

  const handleDeleteDateField = (value) => {
    const updatedFields = addedDateFields.filter((field) => field.value !== value);
    setAddedDateFields(updatedFields);
    setClaim({ ...claim, [value]: null });
  };
  const cmsDateFieldPatient = formatCmsDateFields(claim?.superbill?.cmsDateFieldPatient);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const updateClaim = (event, key) => {
    const field = event?.target?.name;
    let value = event?.target?.value;

    if (key === 'payer_order' || key?.startsWith('diag_')) value = event?.value;

    const updatedClaimObj = {
      ...claim,
      [field ?? key]: key?.startsWith('diag_')
        ? (value ?? '')
        : (value ?? event?.label ?? event ?? null)
    };

    setClaim(updatedClaimObj);
  };

  const handleBack = () => {
    return location.key !== 'default' ? navigate(-1) : navigate('..');
  };
  const [addedDateFields, setAddedDateFields] = useState([]);

  const handleSelectChange = (option) => {
    const fieldExists = addedDateFields.find((field) => field.value === option.value);

    if (!fieldExists) {
      setAddedDateFields((prevFields) => [...prevFields, option]);
    }
  };

  const mutateUpdateClaim = useCustomMutation({
    mutationFn: () => handleUpdateClaim(navigate, claim, initialClaim, setErrorMessage),
    invalidateQueryKey: ['claims', 'claim'],
    successMessage: 'Claim updated successfully!',
    errorMessage,
    onSettled: () => {
      queryClient.invalidateQueries(['claims']);
      queryClient.invalidateQueries(['claim']);
      setShowConfirmationModal(false);
      handleBack();
    }
  });

  const selectRenderingProvider = (event) => {
    const renderingProvider = iaRa(billingRenderingProviders?.providers?.rendering)?.find(
      (provider) => provider.id === event?.value
    );

    setClaim({
      ...claim,
      prov_name_l: renderingProvider?.l_name || '',
      prov_name_f: renderingProvider?.f_name || '',
      prov_name_m: renderingProvider?.m_name || '',
      prov_id: renderingProvider?.id || '',
      prov_taxonomy: renderingProvider?.billingInfo?.taxonomy || '',
      prov_npi: renderingProvider?.billingInfo?.npi || '',
      prov_taxid: renderingProvider?.billingInfo?.taxid || ''
    });
  };

  const selectBillingProvider = (event) => {
    const billingProvider = iaRa(billingRenderingProviders?.providers?.billing)?.find(
      (provider) => provider.id === event?.value
    );

    setClaim({
      ...claim,
      bill_id: billingProvider?.id || '',
      bill_name: billingProvider?.bill_name || '',
      bill_addr_1: billingProvider?.bill_addr_1 || '',
      bill_addr_2: billingProvider?.bill_addr_2 || '',
      bill_city: billingProvider?.bill_city || '',
      bill_state: billingProvider?.state?.abbreviation || '',
      bill_zip: billingProvider?.bill_zip || '',
      bill_phone: billingProvider?.bill_phone || '',
      bill_taxonomy: billingProvider?.bill_taxonomy || '',
      bill_npi: billingProvider?.bill_npi || '',
      bill_taxid: billingProvider?.bill_taxid || ''
    });
  };

  const selectFacilityProvider = (event) => {
    const facilityProvider = iaRa(facilityProviders?.facilityInfo)?.find(
      (provider) => provider.id === event?.value
    );

    setClaim({
      ...claim,
      facility_name: facilityProvider?.facility_name || '',
      facility_addr_1: facilityProvider?.facility_addr_1 || '',
      facility_addr_2: facilityProvider?.facility_addr_2 || '',
      facility_city: facilityProvider?.facility_city || '',
      facility_state: facilityProvider?.state?.abbreviation || '',
      facility_id: facilityProvider?.facility_id || '',
      facility_npi: facilityProvider?.facility_npi || '',
      facility_zip: facilityProvider?.facility_zip || ''
    });
  };

  return (
    <>
      <Modal
        slideFromRight
        isOpen
        handleClose={handleClose || handleBack}
        buttonText="Done"
        isLarge
        className="sm:w-full"
        customHeader={
          <ModalHeader
            claim={claim}
            address={address}
            breadcrumb={breadcrumb}
            setClaim={setClaim}
          />
        }
        headerClassName="z-10"
        footer={
          <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:gap-2">
            <Button
              text="Cancel"
              onClick={handleClose || handleBack}
              color="neutral"
              transparent
              outlined
              data-qa="cancel-btn"
            />
            {claim?.state !== 'accepted' && steps[step].name === 'claim' && (
              <Button
                data-qa="update-btn"
                onClick={() => {
                  ia(claim?.pendingClaimMdChanges)
                    ? setShowConfirmationModal(true)
                    : mutateUpdateClaim.mutate();
                }}
                text={claim?.state === 'ready' ? 'Update' : 'Update & mark ready to submit'}
                loading={mutateUpdateClaim.isLoading}
                disabled={!hasChanges}
              />
            )}
          </div>
        }>
        {claimLoading || isLoading || isFetching ? (
          <Skeleton />
        ) : (
          <>
            {steps[step].name === 'claim' && (
              <div className="p-6 sm:p-0">
                {claim?.state === 'rejected' && <Errors errors={claim?.errors} />}
                <div className="grid grid-cols-3 gap-4">
                  <div className="grid gap-4">
                    <Input
                      data-qa="payer-name"
                      label="1. Payer name"
                      placeholder="Payer name"
                      name="payer_name"
                      value={claim?.payer_name}
                      onChange={updateClaim}
                    />
                    <div className="grid gap-4">
                      <Checkbox
                        data-qa="show-payer-address"
                        label="Show payer address"
                        handleClick={() => setShowPayerAddress(!showPayerAddress)}
                        isChecked={showPayerAddress}
                      />
                    </div>
                  </div>
                  {showPayerAddress && (
                    <div className="col-start-1 col-end-4 row-start-2 row-end-2 grid grid-cols-4 gap-4">
                      <Input
                        data-qa="payer-address-1"
                        label="Payer Address 1"
                        placeholder="Payer Address 1"
                        value={claim?.payer_addr_1 ?? address?.address_ln_1}
                        name="payer_addr_1"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="payer-address-2"
                        label="Payer Address 2"
                        placeholder="Payer Address 2"
                        value={claim?.payer_addr_2 ?? address?.address_ln_2}
                        name="payer_addr_2"
                        onChange={updateClaim}
                      />
                      <div className="grid grid-cols-3 gap-2">
                        <Input
                          data-qa="payer-city"
                          label="City"
                          placeholder="City"
                          value={claim?.payer_city ?? address?.city}
                          name="payer_city"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="payer-st"
                          label="ST"
                          placeholder="ST"
                          value={claim?.payer_state ?? address?.state_label}
                          name="payer_state"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="payer-zip"
                          label="ZIP"
                          placeholder="ZIP"
                          value={claim?.payer_zip ?? address?.zip}
                          name="payer_zip"
                          onChange={updateClaim}
                        />
                      </div>
                      <Input
                        data-qa="payer-office-number"
                        label="Payer Office Number"
                        placeholder="Payer Office Number"
                        value={claim?.payer_office_number}
                        name="payer_office_number"
                        onChange={updateClaim}
                      />
                    </div>
                  )}
                  <div className="grid grid-cols-2 gap-2">
                    <Input
                      data-qa="payer-id"
                      label="Payer id"
                      placeholder="Payer id"
                      value={claim?.payerid}
                      name="payerid"
                      onChange={updateClaim}
                    />
                    <Select
                      label="Insurance"
                      name="payer_order"
                      isClearable={false}
                      placeholder="Insurance"
                      options={insurance_type}
                      inputId="select-payer-insurance"
                      onChange={(event) => updateClaim(event, 'payer_order')}
                      value={{
                        value: claim?.payer_order || 'primary',
                        label: Capitalize(claim?.payer_order || 'Primary')
                      }}
                    />
                  </div>
                  <Input
                    data-qa="insured-id-number"
                    label="1a. Insured I.D. Number"
                    placeholder="Insured I.D. Number"
                    value={claim?.ins_number}
                    name="ins_number"
                    onChange={updateClaim}
                  />
                  <div>
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      2. Patient's name (L, F, M)
                    </label>
                    <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                      <Input
                        data-qa="pat-last-name"
                        placeholder="Last name"
                        value={claim?.pat_name_l}
                        name="pat_name_l"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="pat-first-name"
                        placeholder="First name"
                        value={claim?.pat_name_f}
                        name="pat_name_f"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="pat-middle-name"
                        placeholder="Middle name"
                        className="w-[72px]"
                        value={claim?.pat_name_m}
                        name="pat_name_m"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 items-end gap-2">
                    <DatePopover
                      label="3. Patient's birth day / sex"
                      labelClassName="w-max"
                      placeholder="Birth day"
                      value={claim?.pat_dob}
                      onChange={(event) => updateClaim(event, 'pat_dob')}
                    />
                    <Select
                      inputId="select-sex"
                      placeholder="Type and select sex"
                      value={getLabelFromOptions(claim_md_genders, claim?.pat_sex, 'U')}
                      onChange={(event) => updateClaim(event?.value, 'pat_sex')}
                      options={claim_md_genders}
                    />
                  </div>
                  <div>
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      4. Insured's name (L, F, M)
                    </label>
                    <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                      <Input
                        data-qa="ins-last-name"
                        placeholder="Last name"
                        value={claim?.ins_name_l}
                        name="ins_name_l"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ins-first-name"
                        placeholder="First name"
                        value={claim?.ins_name_f}
                        name="ins_name_f"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ins-middle-name"
                        placeholder="Middle"
                        value={claim?.ins_name_m}
                        name="ins_name_m"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="grid gap-4">
                    <Input
                      data-qa="patients-address-1"
                      label="5. Patient's Address"
                      placeholder="Patient's Address"
                      value={claim?.pat_addr_1}
                      name="pat_addr_1"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="patients-address-2"
                      label="Address 2"
                      placeholder="Address 2"
                      value={claim?.pat_addr_2}
                      name="pat_addr_2"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-3 gap-2">
                      <Input
                        data-qa="city"
                        label="City"
                        placeholder="City"
                        value={claim?.pat_city}
                        name="pat_city"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="state"
                        label="ST"
                        placeholder="ST"
                        value={claim?.pat_state}
                        name="pat_state"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="pat-zip-code"
                        label="ZIP"
                        placeholder="ZIP"
                        value={claim?.pat_zip}
                        name="pat_zip"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="grid h-max gap-4">
                    <Select
                      inputId="patients-relationship"
                      label="6. Patient's relationship"
                      placeholder="Type and select relationship"
                      value={getLabelFromOptions(insurancePatientRelation, claim?.pat_rel)}
                      onChange={(event) => {
                        updateClaim(event, 'pat_rel');
                      }}
                      options={insurancePatientRelation}
                      name="pat_rel"
                      isClearable={false}
                    />
                    <Select
                      inputId="marital-status"
                      label="8. Marital status"
                      placeholder="Type and select relationship"
                      value={getLabelFromOptions(claim_md_marital_status, claim?.pat_marital, 'O')}
                      onChange={(event) => updateClaim(event?.value, 'pat_marital')}
                      options={claim_md_marital_status}
                    />
                    <Select
                      inputId="employment-status"
                      label="Employment status"
                      placeholder="Type and select status"
                      value={getLabelFromOptions(claim_md_employment_type, claim?.pat_employment)}
                      onChange={(event) => updateClaim(event?.value, 'pat_employment')}
                      options={claim_md_employment_type}
                    />
                  </div>
                  <div className="grid gap-4">
                    <Input
                      data-qa="insureds-address"
                      label="7. Insured's address"
                      placeholder="7. Insured's address"
                      value={claim?.ins_addr_1}
                      name="ins_addr_1"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="address-2"
                      label="Address 2"
                      placeholder="Address 2"
                      value={claim?.ins_addr_2}
                      name="ins_addr_2"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-3 gap-2">
                      <Input
                        data-qa="ins-city"
                        label="City"
                        placeholder="City"
                        value={claim?.ins_city}
                        name="ins_city"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ins-state"
                        label="ST"
                        placeholder="ST"
                        value={claim?.ins_state}
                        name="ins_state"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ins-zip"
                        label="ZIP"
                        placeholder="ZIP"
                        value={claim?.ins_zip}
                        name="ins_zip"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="grid h-max gap-4">
                    <div>
                      <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                        9. Secondary insured name (L, F, M)
                      </label>
                      <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                        <Input
                          data-qa="other-ins-last-name"
                          placeholder="Last name"
                          value={claim?.other_ins_name_l}
                          name="other_ins_name_l"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="other-ins-first-name"
                          placeholder="First name"
                          value={claim?.other_ins_name_f}
                          name="other_ins_name_f"
                          className="mt-auto grow"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="other-ins-middle-name"
                          placeholder="Middle name"
                          className="mt-auto w-[72px]"
                          value={claim?.other_ins_name_m}
                          name="other_ins_name_m"
                          onChange={updateClaim}
                        />
                      </div>
                    </div>
                    <Input
                      data-qa="secondary-insureds-policy"
                      label="a. Secondary insured's policy"
                      placeholder="Secondary insured's policy"
                      value={claim?.other_ins_number}
                      name="other_ins_number"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-2 items-end gap-2">
                      <DatePopover
                        label="b. Secondary insured date of birth / sex"
                        labelClassName="w-max"
                        placeholder="Date of birth"
                        name="other_ins_dob"
                        value={claim?.other_ins_dob}
                        onChange={(event) => updateClaim(event, 'other_ins_dob')}
                      />
                      <Select
                        inputId="other-select-sex"
                        placeholder="Type and select sex"
                        value={getLabelFromOptions(claim_md_genders, claim?.other_ins_sex, 'U')}
                        onChange={(event) => updateClaim(event?.value, 'other_ins_sex')}
                        options={claim_md_genders}
                        isClearable={true}
                      />
                    </div>
                    <DatePopover
                      label="c. Primary payment date"
                      labelClassName="w-max"
                      placeholder="Primary payment date"
                      value={claim?.other_ins_payment_date}
                      onChange={(event) => updateClaim(event, 'other_ins_payment_date')}
                    />
                    <div className="grid grid-cols-[auto,1fr] gap-4">
                      <Input
                        data-qa="secondary-payer-name"
                        label="d. Secondary payer name"
                        placeholder="Secondary payer name"
                        value={claim?.other_payer_name}
                        name="other_payer_name"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="other-payer-id"
                        label="Payer id"
                        placeholder="Payer id"
                        value={claim?.other_payerid}
                        name="other_payerid"
                        onChange={updateClaim}
                      />
                    </div>
                    <Input
                      data-qa="other-secondary-payer-claim-id"
                      label="Seconday payer claim id"
                      placeholder="Seconday payer claim id"
                      value={claim?.other_claimfilingcode}
                      name="other_claimfilingcode"
                      onChange={updateClaim}
                    />
                  </div>
                  <div className="grid h-max gap-4">
                    <Select
                      inputId="patient-condition-related"
                      label="10. Patient condition related / a. Employment"
                      placeholder="a. Employment"
                      value={getYesAndNoLabel(claim?.employment_related)}
                      onChange={(event) => updateClaim(event, 'employment_related')}
                      options={yesAndNoOptions}
                    />
                    <div className="grid grid-cols-[3fr,2fr,2fr] gap-2">
                      <Select
                        inputId="auto-accident"
                        label="b. Auto accident"
                        labelClassNam="w-max"
                        placeholder="Auto accident"
                        value={getYesAndNoLabel(claim?.auto_accident)}
                        onChange={(event) => updateClaim(event, 'auto_accident')}
                        options={yesAndNoOptions}
                      />
                      <Input
                        data-qa="auto-accident-state"
                        label="State"
                        placeholder="State"
                        value={claim?.auto_accident_state}
                        name="auto_accident_state"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="accident-country"
                        label="Country"
                        placeholder="Country"
                        value={claim?.accident_country}
                        name="accident_country"
                        onChange={updateClaim}
                      />
                    </div>
                    <Select
                      inputId="other-accident"
                      label="c. Other accident"
                      labelClassNam="w-max"
                      placeholder="Other accident"
                      value={getYesAndNoLabel(claim?.other_accident)}
                      onChange={(event) => updateClaim(event, 'other_accident')}
                      options={yesAndNoOptions}
                    />
                    <Input
                      label="10d. Claims codes"
                      placeholder="Claims codes"
                      data-qa="claims-codes"
                    />
                    <Select
                      inputId="secondary-patient-relationship"
                      label="9e. Secondary patient relationship"
                      placeholder="Secondary patient relationship"
                      value={getLabelFromOptions(insurancePatientRelation, claim?.other_pat_rel)}
                      onChange={(event) => updateClaim(event, 'other_pat_rel')}
                      options={insurancePatientRelation}
                    />
                    <Input
                      data-qa="secondary-payer-group-name"
                      label="9f. Secondary payer group name"
                      placeholder="Secondary payer group name"
                      value={claim?.other_payer_group_name}
                      name="other_payer_group_name"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="secondary-payer-group-number"
                      label="9g. Secondary payer group number"
                      placeholder="Secondary payer group number"
                      value={claim?.other_payer_group_number}
                      name="other_payer_group_number"
                      onChange={updateClaim}
                    />
                  </div>
                  <div className="grid h-max gap-4">
                    <Input
                      data-qa="insureds-policy-group"
                      label="11. Insured's policy group"
                      placeholder="Insured's policy group"
                      name="ins_group"
                      value={claim?.ins_group}
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-2 items-end gap-2">
                      <DatePopover
                        label="a. Insured's date of birth / sex"
                        labelClassName="w-max"
                        placeholder="Date of birth"
                        placementX="right-0"
                        value={claim?.ins_dob}
                        onChange={(event) => updateClaim(event, 'ins_dob')}
                      />
                      <Select
                        inputId="select-sex-genders"
                        placeholder="Type and select sex"
                        value={getLabelFromOptions(claim_md_genders, claim?.ins_sex, 'U')}
                        onChange={(event) => updateClaim(event?.value, 'ins_sex')}
                        options={claim_md_genders}
                        isClearable={true}
                      />
                    </div>
                    <Input
                      data-qa="ins-employers-name"
                      label="b. Employer's name"
                      placeholder="Employer's name"
                      value={claim?.ins_employer}
                      name="ins_employer"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="insurance-plan-name"
                      label="c. Insurance plan name"
                      placeholder="Insurance plan name"
                      value={claim?.ins_plan}
                      name="ins_plan"
                      onChange={updateClaim}
                    />
                    <div>
                      <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                        Supervising provider name (L, F, M)
                      </label>
                      <div className="grid grid-cols-[2fr,2fr,1fr] items-end gap-2">
                        <Input
                          data-qa="last-name-2"
                          placeholder="Last name"
                          value={claim?.chg_supv_prov_name_l}
                          name="chg_supv_prov_name_l"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="first-name-2"
                          placeholder="First name"
                          value={claim?.chg_supv_prov_name_f}
                          name="chg_supv_prov_name_f"
                          className="mt-auto grow"
                          onChange={updateClaim}
                        />
                        <Input
                          data-qa="middle-name-2"
                          placeholder="Middle name"
                          className="mt-auto w-[72px]"
                          value={claim?.chg_supv_prov_name_m}
                          name="chg_supv_prov_name_m"
                          onChange={updateClaim}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <Input
                        data-qa="supervising-id"
                        label="Supervising ID"
                        placeholder="Supervising ID"
                        value={claim?.chg_supv_prov_id}
                        name="chg_supv_prov_id"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="supervising-npi"
                        label="Supervising NPI"
                        placeholder="Supervising NPI"
                        value={claim?.chg_supv_prov_npi}
                        name="chg_supv_prov_npi"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div>
                    <DatePopover
                      label="14. Date of condition"
                      labelClassName="w-max"
                      placeholder="Date of condition"
                      value={claim?.cond_date}
                      onChange={(event) => updateClaim(event, 'cond_date')}
                    />
                    {iaRa(addedDateFields).map(({ label, value }) => (
                      <div className="flex items-center" key={value}>
                        <DatePopover
                          className="mt-1 w-full"
                          key={value}
                          label={label}
                          labelClassName="w-max"
                          placeholder={label}
                          value={claim?.[value] || cmsDateFieldPatient?.[value] || null}
                          onChange={(event) => updateClaim(event, value)}
                        />
                        <Icon
                          icon="trash"
                          onClick={() => handleDeleteDateField(value)}
                          className="m-3 mt-12"
                        />
                      </div>
                    ))}
                    <Select
                      parentClassName="mt-1"
                      label="Add Date"
                      labelClassName="w-max"
                      placeholder="Add Date Field"
                      options={addDateFields}
                      onChange={handleSelectChange}
                      menuPlacement="top"
                    />
                  </div>
                  <div>
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      17. Referring physician name (L, F, M)
                    </label>
                    <div className="grid grid-cols-[2fr,2fr] items-end gap-2">
                      <Select
                        data-qa="ref-type"
                        placeholder="Type"
                        value={getLabelFromOptions(refTypeOptions, claim?.ref_type)}
                        name="ref_type"
                        onChange={(event) => updateClaim(event?.value, 'ref_type')}
                        options={refTypeOptions}
                      />
                      <Input
                        data-qa="ref-name-l"
                        placeholder="Last name"
                        value={claim?.ref_name_l}
                        name="ref_name_l"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ref-name-f"
                        placeholder="First name"
                        value={claim?.ref_name_f}
                        name="ref_name_f"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ref-name-m"
                        placeholder="Middle name"
                        value={claim?.ref_name_m}
                        name="ref_name_m"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Input
                      data-qa="ref-type-identifier"
                      placeholder="Type of identifier"
                      label="17a. Type of identifier"
                      value={claim?.ref_type_identifier}
                      name="ref_type_identifier"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-[1fr,1fr] gap-4">
                      <Input
                        data-qa="ref-npi"
                        label="Referring NPI"
                        placeholder="Referring NPI"
                        value={claim?.ref_npi}
                        name="ref_npi"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="ref-id"
                        label="Other ID"
                        placeholder="Other ID"
                        value={claim?.ref_id}
                        name="ref_id"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <Input
                    data-qa="claim-narrative"
                    className="col-start-1 col-end-3"
                    label="19. Claim narrative"
                    placeholder=" Claim narrative"
                    value={claim?.narrative}
                    name="narrative"
                    onChange={updateClaim}
                  />
                  <div>
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      18. Hospitalization dates
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                      <DatePopover
                        placeholder="From"
                        name="hosp_from_date"
                        placementX="right-0"
                        value={claim?.hosp_from_date}
                        onChange={(event) => updateClaim(event, 'hosp_from_date')}
                      />
                      <DatePopover
                        placeholder="To"
                        placementX="right-0"
                        name="hosp_thru_date"
                        value={claim?.hosp_thru_date}
                        onChange={(event) => updateClaim(event, 'hosp_thru_date')}
                      />
                    </div>
                  </div>
                  <div className="col-start-1 col-end-3">
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      21. Diagnosis or nature of illness or injury (relate to 24E by line)
                    </label>
                    <ICDRow claim={claim} updateDiagnosis={updateClaim} />
                  </div>
                  <div>
                    <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                      22. Resubmission code (Payer ICN)
                    </label>
                    <div className="grid gap-4">
                      <div className="grid grid-cols-[1fr,2fr] gap-2">
                        <Select
                          inputId="code-select"
                          placeholder="Code"
                          value={
                            claim?.frequency_code
                              ? { value: claim?.frequency_code, label: claim?.frequency_code }
                              : null
                          }
                          onChange={(event) => updateClaim(event, 'frequency_code')}
                          options={frequencyCode}
                        />
                        <Input
                          data-qa="icn-dcn-1"
                          placeholder="ICN"
                          value={claim?.icn_dcn_1}
                          name="icn_dcn_1"
                          onChange={updateClaim}
                        />
                      </div>
                      <Input
                        data-qa="prior-auth"
                        label="23. Prior authorization number"
                        placeholder="Prior authorization number"
                        value={claim?.prior_auth}
                        name="prior_auth"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="referral-number"
                        label="Referral number"
                        placeholder="Referral number"
                        value={claim?.referral_number}
                        name="referral_number"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="clia-number"
                        label="CLIA number"
                        placeholder="CLIA number"
                        value={claim?.clia_number}
                        name="clia_number"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <ClaimProcedures claim={claim} setClaim={setClaim} updateClaim={updateClaim} />
                </div>
                <div className="grid grid-cols-7 gap-4 !py-4">
                  <div className="col-start-1 col-end-3 grid grid-cols-2 gap-2">
                    <Input
                      data-qa="bill-taxid"
                      label="25. Tax id"
                      placeholder="Tax id"
                      value={claim?.bill_taxid}
                      name="bill_taxid"
                      onChange={updateClaim}
                    />
                    <Select
                      inputId="bill-texid-type"
                      label="Type"
                      placeholder="Type"
                      name="bill_taxid_type"
                      value={getLabelFromOptions(tax_id_types, claim?.bill_taxid_type)}
                      onChange={(event) => updateClaim(event.value, 'bill_taxid_type')}
                      options={tax_id_types}
                    />
                  </div>
                  <Input
                    data-qa="patient-acct"
                    label="26. Patient acct #"
                    placeholder="Patient acct #"
                    value={claim?.pcn}
                    name="pcn"
                    onChange={updateClaim}
                    disabled
                  />
                  <Select
                    inputId="select-accept-assign"
                    label="27. Accept Assign"
                    placeholder="Accept Assign"
                    name="accept_assign"
                    value={getYesAndNoLabel(claim?.accept_assign)}
                    onChange={(event) => updateClaim(event, 'accept_assign')}
                    options={yesAndNoOptions}
                  />
                  <Input
                    data-qa="total-charge"
                    label="28. Total charge"
                    value={claim?.total_charge}
                    disabled={true}
                    id="total_charge"
                    placeholder="Total Charge"
                    name="total_charge"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="amount-paid"
                    label="29. Amount paid"
                    value={claim?.totalPaid}
                    id="total_charge"
                    placeholder="Amount paid"
                    name="amount_paid"
                    onChange={updateClaim}
                    disabled
                  />
                  <Input
                    data-qa="balance-total-charge"
                    label="30. Balance"
                    id="total_charge"
                    placeholder="Amount paid"
                    name="total_charge"
                    value={
                      claim?.total_charge && claim?.amount_paid
                        ? claim?.total_charge - claim?.amount_paid
                        : claim?.total_charge
                          ? claim?.total_charge
                          : claim?.amount_paid
                            ? claim?.amount_paid
                            : 0
                    }
                    disabled
                  />
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="grid h-max gap-4">
                    <div>
                      <label className="m-0 flex pb-[6px] text-sm font-500 text-neutral-800">
                        31. Rendering provider (L, F, M)
                      </label>
                      <div className="grid items-end gap-2">
                        <Select
                          data-qa="rendering_provider"
                          placeholder="Rendering provider"
                          className="w-full"
                          value={
                            claim?.prov_name_l && claim?.prov_name_f
                              ? {
                                  label: `${claim?.prov_name_l}, ${claim?.prov_name_f}${claim?.prov_name_m ? ` ${claim?.prov_name_m}` : ''}`,
                                  value: `${claim?.prov_name_l}, ${claim?.prov_name_f}${claim?.prov_name_m ? ` ${claim?.prov_name_m}` : ''}`
                                }
                              : null
                          }
                          name="rendering_provider"
                          onChange={selectRenderingProvider}
                          options={renderingProviders}
                          onCreateOption={createRenderingHandler}
                          isCreatable
                        />
                      </div>
                    </div>
                    <Input
                      data-qa="prov-taxonomy"
                      label="Taxonomy"
                      placeholder="Taxonomy"
                      value={claim?.prov_taxonomy}
                      name="prov_taxonomy"
                      onChange={updateClaim}
                    />
                  </div>
                  <div className="grid h-max gap-4">
                    <Select
                      data-qa="facility-name"
                      label="32. Facility"
                      placeholder="Facility"
                      isClearable={!!claim?.facility_name}
                      value={{ label: claim?.facility_name, value: claim?.facility_name }}
                      name="facility_name"
                      onChange={selectFacilityProvider}
                      options={facilities}
                      onCreateOption={createFacilityHandler}
                      isCreatable
                    />
                    <Input
                      data-qa="facility-address-1"
                      label="Address 1"
                      placeholder="Address 1"
                      value={claim?.facility_addr_1}
                      name="facility_addr_1"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="facility-address-2"
                      label="Address 2"
                      placeholder="Address 2"
                      value={claim?.facility_addr_2}
                      name="facility_addr_2"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-[2fr,1fr,1fr] items-end gap-2">
                      <Input
                        data-qa="facility-city"
                        label="City"
                        placeholder="City"
                        value={claim?.facility_city}
                        name="facility_city"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="facility-state"
                        label="ST"
                        placeholder="ST"
                        value={claim?.facility_state}
                        name="facility_state"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="facility-zip"
                        label="ZIP"
                        placeholder="ZIP"
                        value={claim?.facility_zip}
                        name="facility_zip"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                  <div className="grid gap-4">
                    <Select
                      label="33. Billing provider"
                      placeholder="Billing provider"
                      isClearable={!!claim?.bill_name}
                      value={{ label: claim?.bill_name, value: claim?.bill_name }}
                      name="bill_name"
                      onChange={selectBillingProvider}
                      options={billingProviders}
                      onCreateOption={createBillingHandler}
                      isCreatable
                    />
                    <Input
                      data-qa="bill-addr-1"
                      label="Address 1"
                      placeholder="Address 1"
                      value={claim?.bill_addr_1}
                      name="bill_addr_1"
                      onChange={updateClaim}
                    />
                    <Input
                      data-qa="bill-addr-2"
                      label="Address 2"
                      placeholder="Address 2"
                      value={claim?.bill_addr_2}
                      name="bill_addr_2"
                      onChange={updateClaim}
                    />
                    <div className="grid grid-cols-[2fr,1fr,1fr] items-end gap-2">
                      <Input
                        data-qa="bill-city"
                        label="City"
                        placeholder="City"
                        value={claim?.bill_city}
                        name="bill_city"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="bill-state"
                        label="ST"
                        placeholder="ST"
                        value={claim?.bill_state}
                        name="bill_state"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="bill-zip"
                        label="ZIP"
                        placeholder="ZIP"
                        value={claim?.bill_zip}
                        name="bill_zip"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="bill-phone"
                        label="Phone"
                        placeholder="Phone"
                        value={claim?.bill_phone}
                        name="bill_phone"
                        onChange={updateClaim}
                      />
                      <Input
                        data-qa="bill-taxonomy"
                        label="Bill Taxonomy"
                        placeholder="Bill Taxonomy"
                        className="col-start-2 col-end-4"
                        value={claim?.bill_taxonomy}
                        name="bill_taxonomy"
                        onChange={updateClaim}
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-6 gap-4 !pt-4">
                  <Input
                    data-qa="prov-npi"
                    label="Prov npi"
                    placeholder="Prov npi"
                    value={claim?.prov_npi}
                    name="prov_npi"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="prov-id"
                    label="Prov id"
                    placeholder="Prov id"
                    value={claim?.prov_id}
                    name="prov_id"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="facility_npi"
                    label="Facility Npi"
                    placeholder="Facility Npi"
                    value={claim?.facility_npi}
                    name="facility_npi"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="facility_id"
                    label="Facil id"
                    placeholder="Facil id"
                    value={claim?.facility_id}
                    name="facility_id"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="bill_npi"
                    label="Bill Npi"
                    placeholder="Bill Npi"
                    value={claim?.bill_npi}
                    name="bill_npi"
                    onChange={updateClaim}
                  />
                  <Input
                    data-qa="bill_id"
                    label="Bill id"
                    placeholder="Bill id"
                    value={claim?.bill_id}
                    name="bill_id"
                    onChange={updateClaim}
                  />
                </div>
              </div>
            )}
            {steps[step].name === 'sync' && (
              <SyncClaim
                claim={claim}
                pendingClaimMdChanges={claim?.pendingClaimMdChanges}
                breadcrumb={breadcrumb}
              />
            )}
          </>
        )}
      </Modal>
      {showConfirmationModal && (
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={() => {
            mutateUpdateClaim.mutate();
            setShowConfirmationModal(false);
          }}
          title="Update Claim"
          icon="new-billing"
          variant="warning"
          iconColor="warning"
          primaryBtnTxt="Update"
          message={
            <div className="flex flex-col gap-1">
              <p>
                This claim has updates from the clearing house that are not yet synced with your
                current claim.
              </p>
              <p>If you continue, your local changes will override those updates.</p>
              <p>Are you sure you want to continue?</p>
            </div>
          }
        />
      )}
    </>
  );
};

export default CMS1500;
