import { runEligibility } from 'components/practice/appointment/PreviewAppointment/lib/updateStateHelpers';
import Eligibility from 'components/practice/Calendar/RBCalendar/Eligibility';
import InsuranceProfiles from 'components/practice/charts/InsuranceProfile/InsuranceProfiles';
import { currentPractice } from 'components/practice/practiceState';
import { useFormikContext } from 'formik';
import { iaRa } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

const InsuranceProfile = ({ selectedInsurance }) => {
  const [showEligibilityPopover, setShowEligibilityPopover] = useState(false);
  const [runningEligibility, setRunningEligibility] = useState(false);
  const [insuranceBenefits, setInsuranceBenefits] = useState({});
  const [showEligibilityResponse, setShowEligibilityResponse] = useState(false);
  const { values, setValues } = useFormikContext();

  const { data } = usePatient({
    params: { id: values?.id },
    dependencies: [values?.id]
  });

  const practice = useRecoilValue(currentPractice);
  const eligibilityPresent = values?.insurance_benefits?.retrieved || false;
  const handleRunEligibility = (insuranceId) => {
    runEligibility({
      insuranceBenefits: values?.insurance_benefits?.benefits,
      fromDemographics: true,
      setInsuranceBenefits,
      setRunningEligibility,
      insuranceId,
      formik: { values, setValues }
    });
    setShowEligibilityPopover(false);
  };

  const insuranceProfiles = iaRa(data?.patient?.insuranceProfile);

  return (
    <InsuranceProfiles
      patientId={values.id}
      practiceId={practice.id}
      eligibilityPresent={eligibilityPresent}
      setShowEligibilityResponse={setShowEligibilityResponse}
      insuranceProfiles={insuranceProfiles}
      selected={selectedInsurance}
      eligibility={
        insuranceProfiles.length ? (
          <Eligibility
            eligibilityLimitReached={data?.patient?.eligibility_limit_reached}
            setShow={setShowEligibilityPopover}
            show={showEligibilityPopover}
            insuranceProfilesList={insuranceProfiles}
            callback={handleRunEligibility}
            loading={runningEligibility}
            existEligibility={eligibilityPresent}
            eligibilityCounter={practice?.eligibility_counter}
          />
        ) : (
          false
        )
      }
    />
  );
};

export default InsuranceProfile;
