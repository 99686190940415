import Tippy from '@tippyjs/react';
import Status from 'components/shared/Status/Status';
import { formatDate } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React from 'react';
export const multiSelectMedicationsCols = [
  {
    field: 'medication',
    headerName: 'Medication',
    flex: 2,
    checkboxSelection: (params) => !params?.data?.weight_loss_medication_id,

    cellRenderer: (params) => {
      const isDisabled = params?.data?.weight_loss_medication_id;
      const medicationName = params?.data?.medication || 'N/A';
      return (
        <div>
          {isDisabled ? (
            <Tippy
              content={
                isDisabled
                  ? 'This medication already exists under weight loss administration'
                  : null
              }
              className="tippy-dark">
              <span>{medicationName}</span>
            </Tippy>
          ) : (
            <span>{medicationName}</span>
          )}
        </div>
      );
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      const status = params?.data?.status;
      return (
        <div className="flex h-full items-center justify-center !p-3">
          {status === 'active' ? (
            <Status
              status={status}
              textClassName="!text-success-500"
              className="flex max-h-[24px] min-w-fit items-center justify-center border border-solid border-success-200 !bg-success-50"
            />
          ) : (
            <Status
              status={status}
              textClassName="!text-danger-500"
              className="flex max-h-[24px] min-w-fit items-center justify-center border border-solid border-danger-200 !bg-danger-50"
            />
          )}
        </div>
      );
    }
  },
  {
    field: 'dose',
    headerName: 'Dose',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      const content = `${params?.data?.strength ?? ''}${params?.data?.unit ? `(${params?.data?.unit})` : ''}`;

      return <span>{content ? content : 'N/A'}</span>;
    }
  },
  {
    field: 'prescriber.label',
    headerName: 'Prescriber',
    valueFormatter: ({ value }) => (!!value ? capitalize(value) : 'N/A')
  },
  {
    field: 'start_date',
    headerName: 'Start date',
    valueFormatter: ({ value }) => (value ? formatDate(value, undefined) : 'N/A')
  },
  {
    field: 'end_date',
    headerName: 'End date',
    valueFormatter: ({ value }) => (value ? formatDate(value, undefined) : 'N/A')
  }
];
