import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { camelCase } from 'lodash';

import { noKnownPatientAggregateNarrative } from 'api/PatientAggregateNarrative';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import PreviousFormDropdown from './PreviousFormDropdown';
import { LONGITUDINAL_ENCOUNTER_MESSAGES, LONGITUDINAL_ENCOUNTER_TYPES } from './lib/constants';
import { handleNoKnown } from './lib/noteHeaderHelpers';

const NoteHeader = ({ title, children, showPreviousFrom = true }) => {
  const { setOverviewData } = useClinicalNoteContext();
  const [noKnownModal, setNoKnownModal] = useState(false);
  const { id: patientId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const noKnownState = queryClient.getQueryData([
    'patientAggregateNarrative',
    patientId,
    camelCase(title)
  ])?.data?.narrative?.no_known;

  const { mutateAsync: mutateNoKnown, isLoading: noKnownLoading } = useMutation({
    mutationFn: (data) => noKnownPatientAggregateNarrative(navigate, data)
  });

  return (
    <div className="flex items-center border border-l-0 border-r-0 border-t-0 border-solid border-neutral-100">
      <div className={cs('flex p-5 bg-white py-3.5 shadow-[4px_0px_16px_0px_#004F6B0A]')}>
        <Tippy
          content={LONGITUDINAL_ENCOUNTER_MESSAGES(title?.trim()?.replace(/\s+/g, ''))}
          className="tippy-dark">
          <div>
            <Icon icon={LONGITUDINAL_ENCOUNTER_TYPES[title?.trim()?.replace(/\s+/g, '')]} />
          </div>
        </Tippy>
        <p className="ml-1 text-base font-500 text-neutral-800 lg:whitespace-pre xl:whitespace-pre">
          {title}
        </p>
      </div>
      <div className="flex w-full items-center justify-between !px-4">
        {showPreviousFrom && (
          <div>
            <PreviousFormDropdown />
          </div>
        )}
        <div className="flex items-center gap-2">{children}</div>
      </div>

      <Confirm
        handleOpen={!!noKnownModal}
        handleClose={() => setNoKnownModal(false)}
        handleContinue={() =>
          handleNoKnown({
            mutateNoKnown,
            queryClient,
            patientId,
            title: camelCase(title),
            noKnownState,
            setNoKnownModal,
            setOverviewData
          })
        }
        title={`No Known ${title}`}
        icon={noKnownState ? 'new-document' : 'new-document-remove-red'}
        loading={noKnownLoading}
        disabled={noKnownLoading}
        message={
          noKnownState ? (
            <div>
              Are you sure you want to unselect "No Known"? This will remove the "No Known" status
              from the patient's chart.
            </div>
          ) : (
            <div>
              Are you sure you want to mark as 'No Known'?{' '}
              <em className="text-danger-500">
                {' '}
                This will remove any existing {title?.toLowerCase()} from the patient's chart.
              </em>
            </div>
          )
        }
        variant={noKnownState ? 'primary' : 'danger'}
      />
    </div>
  );
};

export default NoteHeader;
