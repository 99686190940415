import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getDynamicAr } from 'api/DynamicAR';

export const useDynamicAr = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['dynamicAr', ...dependencies], () => getDynamicAr(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
