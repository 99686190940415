import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import React, { memo } from 'react';
import SkeletonLib from 'react-loading-skeleton';
import { useOutletContext, useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useUIContext } from '../../../../../../lib/context/UIContext/UIContext';
import { calculateAge, Capitalize, formatPhoneNumber } from '../../../../../../lib/helpers/utility';
import { usePatient } from '../../../../../../lib/hooks/queries/patients/usePatient';
import ProfilePicture from '../../../../../Profile/ProfilePicture';
import Icon from '../../../../../shared/Icon/Icon';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';

const PatientOverview = () => {
  const { id } = useParams();
  const { data, isLoading, isFetching } = usePatient({ params: { id }, dependencies: [id] });
  const patient = data?.patient;
  const { openSidebar, setOpenSidebar } = useOutletContext();
  const { device } = useUIContext();
  const { cnDisplaySettings, setCNDisplaySettings } = useClinicalNoteContext();

  if (isLoading || isFetching)
    return (
      <div className="grid grid-cols-[auto,1fr] gap-x-3 !px-4 !py-[6px]">
        <SkeletonLib
          circle
          count={1}
          width={36}
          height={36}
          baseColor="#EEEEEE"
          highlightColor="#FAFAFA"
        />
        <div className="grid grid-cols-5 gap-x-3">
          <Skeleton height="34px" />
          <Skeleton height="34px" />
          <Skeleton height="34px" />
          <Skeleton height="34px" />
        </div>
      </div>
    );

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
    const updatedSettings = {
      ...cnDisplaySettings,
      toggleRSidebar: {
        ...cnDisplaySettings?.toggleRSidebar,
        enabled: !cnDisplaySettings?.toggleRSidebar?.enabled
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  return (
    <div className="sticky top-0 z-20 flex items-center justify-between bg-primary-50 !px-4 !py-2 sm:gap-3">
      <div className="flex gap-x-3">
        <ProfilePicture
          firstName={patient?.f_name}
          lastName={patient?.l_name}
          id={patient?.id}
          image={patient?.profile_photo}
          size={36}
        />
        {device === 'desktop' ? (
          <div className="flex gap-x-6 gap-y-2 lg:gap-x-4">
            <div className="grid gap-y-[1px]">
              <div className="flex items-center gap-x-1">
                <span className="whitespace-nowrap text-base font-500 capitalize leading-4 text-primary-900">{`${patient?.f_name} ${patient?.l_name}`}</span>
                <span className="text-sm italic leading-4 text-primary-800">{`#${patient?.id}`}</span>
              </div>
              <span className="text-sm leading-4 text-neutral-800">{`Phone: ${
                patient?.phone ? formatPhoneNumber(patient?.phone) : ''
              }`}</span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="text-sm font-500 leading-4 text-primary-800">Date of birth</span>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                {formatDate(patient?.dob)}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="text-sm font-500 leading-4 text-primary-800">Age</span>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                {calculateAge({ dateOfBirth: patient?.dob })}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span className="text-sm font-500 leading-4 text-primary-800">Gender</span>
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                {Capitalize(patient?.gender)}
              </span>
            </div>
            {device === 'desktop' && (
              <div className="grid gap-y-[1px]">
                <span className="text-sm font-500 leading-4 text-primary-800">Address</span>
                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                  {patient?.userAddress?.fullAddress}
                </span>
              </div>
            )}
            <div className="grid gap-y-[1px]">
              {device === 'desktop' ? (
                <span className="text-sm font-500 leading-4 text-primary-800">Email</span>
              ) : (
                <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                  {patient?.userAddress?.fullAddress}
                </span>
              )}
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800">
                {patient?.email || ''}
              </span>
            </div>
          </div>
        ) : (
          <div className="flex !gap-3 gap-y-2">
            <div className="grid gap-y-[1px]">
              <div className="flex items-center gap-x-1">
                <span className="whitespace-nowrap text-base font-500 capitalize leading-4 text-primary-900">{`${patient?.f_name} ${patient?.l_name}`}</span>
                <span className="text-sm italic leading-4 text-primary-800">{`#${patient?.id}`}</span>
              </div>
              <span className="text-sm leading-4 text-neutral-800">{`Phone: ${
                patient?.phone ? formatPhoneNumber(patient?.phone) : ''
              }`}</span>
            </div>
            <div className="grid gap-y-[1px]">
              <div className="flex gap-x-[2px]">
                <span
                  className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                  title={calculateAge({ dateOfBirth: patient?.dob })}>
                  {calculateAge({ dateOfBirth: patient?.dob })}
                </span>
                {patient?.gender && (
                  <span
                    className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                    title={Capitalize(patient?.gender)}>
                    {`- ${Capitalize(patient?.gender)}`}
                  </span>
                )}
              </div>
              <span
                className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                title={calculateAge({ dateOfBirth: patient?.dob })}>
                {calculateAge({ dateOfBirth: patient?.dob })}
              </span>
            </div>
            <div className="grid gap-y-[1px]">
              <span
                className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                title={patient?.userAddress?.fullAddress}>
                {patient?.userAddress?.fullAddress}
              </span>
              <span
                className="overflow-hidden text-ellipsis whitespace-nowrap text-sm leading-4 text-neutral-800"
                title={patient?.email}>
                {patient?.email}
              </span>
            </div>
          </div>
        )}
      </div>
      {!openSidebar && (
        <div
          data-qa="toggle-right-sidebar"
          className="flex h-[30px] w-[30px] min-w-[30px] cursor-pointer items-center justify-center !rounded-md border border-solid !border-primary-100"
          onClick={toggleSidebar}>
          <Icon icon="new-notev1" className="cursor-pointer" />
        </div>
      )}
    </div>
  );
};

export default memo(PatientOverview);
