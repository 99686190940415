import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const PreviewCell = ({ data, breadcrumb, setPreviewData = () => {} }) => {
  const onPreviewClick = () => {
    breadcrumb.setSteps([
      breadcrumb.steps[0],
      {
        title: 'Preview',
        name: 'preview'
      }
    ]);
    breadcrumb.setStep(1);
    setPreviewData(data);
  };

  return (
    <div className="flex h-full items-center justify-end pr-[15px]">
      <Icon icon="new-preview-search" onClick={onPreviewClick} />
    </div>
  );
};

export default PreviewCell;
