import React, { useRef } from 'react';
import Button from '../Buttons/Button';
import Input from '../Input/Input';
import Textarea from '../Textarea/Textarea';
import { withErrorBoundary } from 'react-error-boundary';

const Contact = ({ onSubmit, contact, onChange, errors }) => {
  const textareaRef = useRef();

  return (
    <div className="mx-auto mt-9 max-w-[450px] !rounded-lg bg-neutral-100 p-4">
      <p className="text-lg font-500 text-neutral-800">Contact Us</p>
      <form className="flex flex-col gap-4 !pt-4">
        <Input
          label="Name"
          name="sender_name"
          value={contact.sender_name}
          onChange={onChange}
          error={errors.sender_name.message}
        />
        <Input
          label="Email"
          name="sender_email"
          value={contact.sender_email}
          onChange={onChange}
          error={errors.sender_email.message}
        />
        <Input
          label="Subject"
          name="subject"
          value={contact.subject}
          onChange={onChange}
          error={errors.subject.message}
        />
        <Textarea
          forwardedRef={textareaRef}
          label="Message"
          name="body"
          value={contact.body}
          onChange={onChange}
          error={errors.body.message}
        />
        <Button text="Submit" onClick={onSubmit} />
      </form>
    </div>
  );
};
export default withErrorBoundary(Contact);
