export const convertPermissions = (permissions) => {
  const result = {};

  Object.keys(permissions).forEach((key) => {
    const actions = permissions[key].actions;
    const simplifiedActions = {};

    Object.keys(actions).forEach((action) => {
      if (actions[action].value) {
        simplifiedActions[action] = actions[action].value;
      } else {
        simplifiedActions[action] = false;
      }
    });

    result[key] = simplifiedActions;
  });

  return result;
};

export const checkFractionString = (fraction = '') => {
  const [a, b] = fraction.split('/').map(Number);
  return b !== 0 && a / b === 1;
};
