import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getFacilityInfo } from 'api/Facility';

export const useFacilities = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['facilities', ...dependencies], () => getFacilityInfo(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    ...options
  });
};
