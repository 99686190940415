import { useField, useFormikContext } from 'formik';

import Input from 'components/shared/Input/Input';

const OrderSampleType = ({ className }) => {
  const [field, meta] = useField({
    name: 'orderSampleType'
  });

  const { values } = useFormikContext();

  return (
    <Input
      {...field}
      className={className}
      error={meta.touched && meta.error}
      label="Sample type"
      inputId="orderSampleTypeInput"
      data-qa="order-sample-type-input"
      placeholder=""
      disabled={!values.drawAtServiceCenter}
    />
  );
};

export default OrderSampleType;
