import React, { useState } from 'react';
import PreviewForm from './preview';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';

export default function Questionnaire({ name, json, formId, answers, practiceId, response }) {
  const [modalState, setModalState] = useState(false);

  const showModal = () => {
    setModalState(true);
  };

  const hideModal = () => {
    setModalState(false);
  };

  return (
    <div>
      <div className="items-stretch block basis-0 grow shrink min-h-min -mx-3 -mt-3">
        <div className="items-stretch block basis-0 grow shrink min-h-min p-3">
          <article className="relative m-5 flex flex-col items-stretch justify-between bg-neutral-50 text-neutral-800 shadow-sm">
            <div>
              <div className="text-lg font-500">{name}</div>
              <hr className="my-1" />
              {/*<div className="flex-wrap -ml-3 -mr-3 -mt-3 pt-2">
                <div className="block p-3">
                   <h6>{props.questionnaire.form_description}</h6> 
                </div>
              </div>*/}
              <div className="block p-3 py-0">
                <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                  <div className="block p-3 w-full py-1">
                    <Button
                      text={response ? 'Show my response' : 'Fill out form'}
                      onClick={showModal}
                    />
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <Modal isOpen={modalState} handleClose={hideModal}>
        {json?.fields && json?.fields?.length > 0 && (
          <PreviewForm
            json={json?.fields}
            formId={formId}
            practiceId={practiceId}
            handleClose={hideModal}
            answers={answers}
          />
        )}
      </Modal>
    </div>
  );
}
