import OutsideClinicalNoteExport from 'components/practice/charts/ClinicalNote/AllClinicalNotes/components/OutsideClinicalNoteExport';
import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Popover from 'components/shared/Popovers/Popover/Popover';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Actions = ({ data: clinicalNote }) => {
  const [showExportModal, setShowExportModal] = useState({
    open: false,
    showOnlyExport: true
  });
  const [selectedClinicalNote, setSelectedClinicalNote] = useState({});
  const navigate = useNavigate();

  const onExportClick = ({ e, showOnlyExport = true }) => {
    if (!clinicalNote.id) {
      showAlert({
        title: `Clinical Note ${showOnlyExport ? 'Export' : 'Preview'} Unavailable`,
        message: `Clinical note not initiated; ${showOnlyExport ? 'export' : 'preview'} unavailable.`,
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    setSelectedClinicalNote(clinicalNote);
    setShowExportModal({ open: true, showOnlyExport });
    e?.stopPropagation();
  };

  const startClinicalNote = () => {
    window.open(
      `/portal/charts/${clinicalNote.appointment.user_id}/clinical-notes/${clinicalNote.appointment.id}`,
      '_blank'
    );
  };

  const onSuperbillClick = (e) => {
    if (!clinicalNote.id || !clinicalNote?.superbill?.id) {
      showAlert({
        title: 'Superbill Unavailable',
        message: 'Clinical note not initiated; superbill unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    window.open(
      `/portal/charts/${clinicalNote?.appointment?.user_id}/billing/superbill/${clinicalNote?.superbill?.id}`,
      '_blank'
    );
    e?.stopPropagation();
  };

  const onClaimClick = (e) => {
    if (!clinicalNote.id) {
      showAlert({
        title: 'Claim Unavailable',
        message: 'Clinical note not initiated; claim unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    navigate(
      claimType(clinicalNote?.appointment?.claim?.state, clinicalNote?.appointment?.claim?.id),
      { state: { claimID: clinicalNote?.appointment?.claim?.id } }
    );
    e?.stopPropagation();
  };

  const claimType = (claimType, claimId) => {
    let type = '';
    switch (claimType) {
      case 'complete':
        type = 'accepted';
        break;
      default:
        type = claimType;
    }

    return `/portal/beyond-billing/claims/${type}/${claimId}`;
  };

  const options = [
    {
      label: clinicalNote?.id ? 'View Draft' : 'Start Visit',
      onClick: clinicalNote?.id
        ? (e) => onExportClick({ e, showOnlyExport: false })
        : startClinicalNote,
      icon: clinicalNote?.id ? 'new-eye' : 'new-note-add'
    },
    ...(clinicalNote?.id
      ? [
          {
            label: 'Export',
            onClick: (e) => onExportClick({ e, showOnlyExport: true }),
            icon: 'new-export-v2'
          },
          {
            label: 'View Superbill',
            onClick: onSuperbillClick,
            icon: 'new-superbill'
          },
          { label: 'View Claim', onClick: onClaimClick, icon: 'new-claim' }
        ]
      : [])
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      <OutsideClinicalNoteExport
        showExportModal={showExportModal}
        setShowExportModal={setShowExportModal}
        selectedClinicalNote={selectedClinicalNote}
        setSelectedClinicalNote={setSelectedClinicalNote}
      />
    </>
  );
};

export default withErrorBoundary(Actions);
