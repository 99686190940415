import React from 'react';
import Tippy from '@tippyjs/react';

import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';

export default function Eligibility({
  insuranceProfilesList = [],
  callback = () => {},
  loading = false,
  existEligibility = false,
  eligibilityCounter = 0
}) {
  const handleClick = () => {
    callback(insuranceProfilesList[0].id);
  };

  return (
    <div className="relative">
      {existEligibility ? (
        <Tippy content="Refresh eligibility status" className="tippy-dark">
          <div data-dd-privacy="allow" className="rounded hover:cursor-pointer hover:bg-primary-50">
            <Icon
              stroke
              color="primary"
              className="p-1"
              icon="new-refresh-arrows"
              onClick={eligibilityCounter && handleClick}
              disabled={loading}
            />
          </div>
        </Tippy>
      ) : (
        <>
          {eligibilityCounter ? (
            <Button
              type=""
              outlined
              transparent
              size="small"
              disabled={loading}
              loadingIcon={loading}
              onClick={handleClick}
              text="Run Eligibility"
              className="!border-none"
            />
          ) : (
            <Tippy
              content="You are out of eligibility requests. Please contact support!"
              className="tippy-dark ">
              <div>
                <Button
                  disabled
                  size="small"
                  transparent
                  type="primary"
                  text="Run Eligibility"
                  onClick={handleClick}
                />
              </div>
            </Tippy>
          )}
        </>
      )}
    </div>
  );
}
