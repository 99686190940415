import { useQuery } from '@tanstack/react-query';
import { getUsersList } from 'api/Admin/Users';
import { useNavigate } from 'react-router-dom';

export const useAdminUsersList = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['getAdminUsersList', ...dependencies], () => getUsersList(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
