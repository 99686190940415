import React from 'react';
import Modal from '../Modal';
import Button from '../../Buttons/Button';
import GrowthChart from '../../../practice/charts/ClinicalNote/GrowthChart/GrowthChart';

const GrowthChartModal = ({ showGrowthChartModal, hideGrowthChartModal, patient, vitals }) => {
  return (
    <Modal
      handleOpen={showGrowthChartModal}
      handleClose={hideGrowthChartModal}
      title="Growth Chart"
      slideFromRight
      className="h-full"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button onClick={hideGrowthChartModal} outlined text="Close" />
        </div>
      }>
      <GrowthChart patient={patient} vitals={vitals} isModal={true} />
    </Modal>
  );
};

export default GrowthChartModal;
