import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS
} from 'components/admin/pages/Intelligence/Prompt/helpers';
import AGTable from 'components/shared/AGTable/AGTable';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import { useAiPromptTemplate } from 'lib/hooks/queries/admin/intelligence/useAiPromptTemplate';

const AdminIntelligencePromptTable = () => {
  const { pageSize, page, setPage, sort, filters, setFilters } = useTableContext();
  const [promptId, setPromptId] = useState();
  const { isLoading, isFetching, data } = useAiPromptTemplate({
    params: {
      id: promptId,
      pageSize,
      page,
      sort,
      filters: mapValues(filters)
    },
    options: {},
    dependencies: [pageSize, page, mapValues(filters), promptId],
    // dependencies: [limit, page],
    name: `ai_prompt_template_${promptId}`
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useOutletContext();

  useEffect(() => {
    setLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (searchParams.get('promptId')) {
      getPrompt();
    }
  }, [searchParams]);

  const getPrompt = async () => {
    const paramPromptId = parseInt(searchParams?.get('promptId'), 10);
    setPromptId(paramPromptId);
    setFilters((ps) => ({ ...ps, promptId: paramPromptId }));
  };

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: data?.results,
            page,
            pageSize
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: data?.results,
            page,
            pageSize,
            onPageChange,
            count: data?.count
            // pageCountOverride: Math.ceil((data?.results?.length || 0) / pageSize) + 1
          },
          align: 'right'
        }
      ]
    }),
    [data?.results, page, pageSize, onPageChange]
  );

  return (
    <div className="flex h-full flex-col overflow-hidden !pb-0">
      <AGTable
        loading={isLoading || isFetching}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        animateRows={true}
        data={data?.result}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        enableRangeSelection={true}
        statusBar={statusBar}
        reactiveCustomComponents
        onRowClicked={(e) => {
          setSearchParams({ promptId: e?.data?.id, version: e?.data?.version });
        }}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligencePromptTable);
