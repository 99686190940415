import React from 'react';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import { camelCaseToReadableFormat, formatLargeNumber } from '../../../../../lib/helpers/utility';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

export default function GrandTotal(props) {
  const overview = props?.overview || {};
  const totals = overview?.totals;

  // const [_, setTooltipData] = useState({ opacity: 0, x: 0, y: 0, content: '' });

  // const customTooltip = (tooltipModel) => {
  //   if (tooltipModel.opacity === 0) {
  //     setTooltipData({ opacity: 0 });
  //     return;
  //   }
  //   const title = tooltipModel.title || [];
  //   const body = tooltipModel.body || [];

  //   const content = {
  //     title: title[0] || '',
  //     body: body.map((bodyItem) => bodyItem.lines.join(''))
  //   };

  //   setTooltipData({
  //     opacity: 1,
  //     x: tooltipModel.caretX,
  //     y: tooltipModel.caretY,
  //     content
  //   });
  // };

  const labels = Object.keys(overview?.graphData || {})?.map((date) =>
    moment.unix(date).format('MMM D')
  );

  const sets = {
    accountReceivable: '#6D828B',
    insurancePayments: '#2D9CDB',
    adjustments: '#EF4444',
    patientPayments: '#13B9FF',
    technologyFee: '#8191E8',
    totalReceived: '#22C55E'
  };

  const chartData = {
    labels,
    datasets: Object.keys(sets).map((set) => ({
      label: camelCaseToReadableFormat(set),
      data: Object.values(overview?.graphData || {}).map((val) => val[set]),
      backgroundColor: sets[set],
      pointBorderColor: '#ffffff',

      borderColor: sets[set]
    }))
  };

  const options = {
    responsive: true,
    mantainAspectRatio: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },

    plugins: {
      title: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: (context) => ` ${context?.dataset.label}: $${context?.formattedValue}`
        },
        padding: 16,
        usePointStyle: true
      },
      legend: {
        labels: {
          usePointStyle: true
        }
      },
      datalabels: {
        display: false
      }
    },
    scales: {
      x: {
        ticks: {
          display: true
        },
        grid: {
          color: '#CDD5D8'
        }
      },
      y: {
        grid: {
          display: true,

          color: '#EFE7E4'
        }
      }
    },
    elements: {
      point: {
        radius: 5,
        pointStyle: 'circle',
        borderColor: '#ffffff',
        borderWidth: 2
      },
      line: {
        tension: 0.2
      }
    },
    tooltip: {}
  };

  return (
    <div className="flex flex-col flex-1 overflow-y-auto">
      <WidgetHeader hasPlus={false} icon="chart" {...props} />
      <div className="grid grid-cols-6 md:grid-cols-3 justify-between gap-3 !p-5 !px-7">
        {Object.keys(totals || {})?.map((total, index) => (
          <div className="flex flex-col" key={index}>
            <p className="text-primary-900 font-medium text-lg">
              {totals[total] < 0 ? '-' : ''}${formatLargeNumber(Math.abs(totals[total]).toFixed(2))}
            </p>
            <p className="text-primary-800 text-[13px]"> {camelCaseToReadableFormat(total)}</p>
          </div>
        ))}
      </div>
      <div className="flex-1 flex-col flex justify-center items-center !p-4 !pt-0 !relative">
        <Line height={6} width={20} data={chartData} options={options}></Line>
      </div>
    </div>
  );
}
