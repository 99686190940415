import { useMemo, useRef, useState } from 'react';

import debounce from 'lodash.debounce';

import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';

const OrderCodeSearchInput = ({ value, changeHandler }) => {
  const [orderCode, setOrderCode] = useState(value);

  const pendingChangesRef = useRef('');

  const debouncedSearch = useMemo(
    () =>
      debounce(() => {
        changeHandler('orderCodes', pendingChangesRef.current);
        pendingChangesRef.current = '';
      }, 500),
    []
  );

  const clearSearchTerm = () => {
    setOrderCode('');
    changeHandler('orderCodes', '');
    pendingChangesRef.current = '';
  };

  return (
    <Input
      label="Search tests by order code:"
      id="tests-search"
      data-qa="searchTests"
      type="text"
      placeholder="005013"
      autoComplete="off"
      icon="new-search"
      inputClassName="!min-w-full"
      value={orderCode}
      onChange={(e) => {
        pendingChangesRef.current = e.target.value;

        setOrderCode(e.target.value);
        debouncedSearch();
      }}
      rightText={orderCode && <Icon icon="new-close" onClick={clearSearchTerm} />}
    />
  );
};

export default OrderCodeSearchInput;
