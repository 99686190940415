import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createMultipleClaims } from 'api/Billing';
import { completeSuperbill, deleteSuperbill } from 'api/Superbill';

import { io, pluralize } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';

import {
  COMPLETE_ERROR_MESSAGE,
  COMPLETE_SUCCESS_MESSAGE,
  DELETE_ERROR_MESSAGE,
  DELETE_SUCCESS_MESSAGE,
  READY_ERROR_MESSAGE,
  READY_FOR_CLAIM_MESSAGE,
  READY_VALIDATION_ERROR_MESSAGE
} from '../lib/helpers';

const SUPERBILLS_KEY = 'superbills';

const HeaderButtons = ({ selectedSuperbills, setSelectedSuperbills, setErrors }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const pluralizedSuperbills = pluralize('superbill', selectedSuperbills);

  const mutateDelete = useCustomMutation({
    mutationFn: () => deleteSuperbill(navigate, { selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: DELETE_SUCCESS_MESSAGE,
    errorMessage: DELETE_ERROR_MESSAGE,
    onSuccess: () => {
      setSelectedSuperbills([]);
    }
  });

  const mutateComplete = useCustomMutation({
    mutationFn: () => completeSuperbill(navigate, { selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: COMPLETE_SUCCESS_MESSAGE,
    errorMessage: COMPLETE_ERROR_MESSAGE,
    onSuccess: () => {
      setSelectedSuperbills([]);
    }
  });

  const mutateReadyForClaim = useCustomMutation({
    mutationFn: () => createMultipleClaims(navigate, { superBillIds: selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: (data) => {
      const { errors = {}, superbills = [] } = data?.data || {};
      if (io(errors)) {
        return READY_VALIDATION_ERROR_MESSAGE(errors);
      }
      return READY_FOR_CLAIM_MESSAGE(superbills);
    },
    errorMessage: READY_ERROR_MESSAGE,
    onSuccess: (data) => {
      setSelectedSuperbills([]);
      const errors = data?.data?.errors;
      setErrors(errors);
    }
  });

  const isDeleteLoading = mutateDelete.isLoading;
  const isCompleteLoading = mutateComplete.isLoading;
  const isReadyForClaimLoading = mutateReadyForClaim.isLoading;
  const isDisabled =
    selectedSuperbills.length === 0 ||
    isReadyForClaimLoading ||
    isDeleteLoading ||
    isCompleteLoading;

  return (
    <>
      <div className="flex items-center gap-2">
        <Allowed requiredPermissions="superbill.update">
          <Button
            size="sm"
            data-qa="complete-selected-superbill"
            onClick={() => mutateReadyForClaim.mutate()}
            text="Ready for claim"
            loading={isReadyForClaimLoading}
            disabled={isDisabled}
          />
        </Allowed>
        <Allowed requiredPermissions="superbill.delete">
          <Button
            size="sm"
            data-qa="delete-selected-superbill"
            onClick={() => setShowConfirmation(true)}
            text="Delete"
            loading={isDeleteLoading}
            disabled={isDisabled}
            color="danger"
          />
        </Allowed>
        <Allowed requiredPermissions="superbill.update">
          <Button
            size="sm"
            data-qa="complete-selected-superbill"
            onClick={() => mutateComplete.mutate()}
            text="Mark Complete"
            loading={isCompleteLoading}
            disabled={isDisabled}
            color="success"
          />
        </Allowed>
      </div>
      {showConfirmation && (
        <Confirm
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          handleContinue={() => {
            mutateDelete.mutate();
            setShowConfirmation(false);
          }}
          title={`Delete ${pluralizedSuperbills}`}
          message={`Are you sure you want to delete the selected ${pluralizedSuperbills}?`}
          primaryBtnTxt={`Delete ${pluralizedSuperbills}`}
          secondaryBtnTxt="Close"
          icon="new-delete-patient"
          variant="danger"
        />
      )}
    </>
  );
};

export default HeaderButtons;
