import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { batchDeleteDocuments } from 'api/Documents';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, objMap } from 'lib/helpers/utility';
import { useDocuments } from 'lib/hooks/queries/useDocuments';

import AGTable from 'components/shared/AGTable/AGTable';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import TableCounter from 'components/shared/Table/TableCounter';
import { clinicalNote } from 'components/state';

import DocumentsHeader from './components/DocumentsHeader';
import getDocumentFilters from './lib/defaultFilters';
import documentTableCols from './lib/documentTableCols';
import formatFilters from './lib/formatFilters';

const List = ({
  setViewDocument,
  setShowInstantPacket,
  patient,
  practitioners,
  categories,
  patientId,
  fromPatient,
  appointmentId
}) => {
  const navigate = useNavigate();

  const { locked: isLocked } = useRecoilValue(clinicalNote);

  const { myPortalPractice } = useParams();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const { filters, page, setPage, limit, sort, selectedRows, setSelectedRows, setFilters } =
    useTableContext();

  const selectedItems = selectedRows.map((node) => node.data);

  const { data, isLoading } =
    useDocuments({
      isAttachments: !!appointmentId,
      params: formatFilters({
        filters,
        page,
        limit,
        sort,
        patientId,
        myPortalPractice,
        appointmentId
      }),
      dependencies: [
        appointmentId,
        formatFilters({
          filters,
          page,
          limit,
          sort,
          patientId,
          myPortalPractice,
          appointmentId: appointmentId?.toString()
        }),
        patientId
      ]
    }) || {};

  const documents = data?.documents || [];
  const totalDocuments = data?.totalDocuments || 0;

  const queryClient = useQueryClient();

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: TableCounter,
          statusPanelParams: {
            count: totalDocuments,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: Pagination,
          statusPanelParams: {
            containerClassName: 'flex',
            onPageChange: ({ selected }) => setPage(selected + 1),
            perPage: limit,
            totalItems: totalDocuments,
            page
          },
          align: 'right'
        }
      ]
    }),
    [page, limit, setPage, totalDocuments]
  );

  const onRowClicked = (event) => {
    const { data } = event;

    if (!event.event.target.closest('.ag-selection-checkbox')) {
      setViewDocument({
        load: true,
        title: data?.name,
        handle: data?.document_handle || {
          response_id: data?.id,
          appointment_id: data?.appointment_id,
          form_id: data?.form_id,
          json: data?.json,
          form_json: data?.form_json,
          form_version: data?.form_version
        },
        category: categories.find((cat) => cat?.value == data?.category?.trim()),
        type: data.category === 'efax' ? 'fax' : data?.mimetype || 'form',
        id: data?.id,
        patient_id: data?.patient_id,
        uploader_id: data?.uploader_id,
        appointment_id: data?.appointment_id
      });
    }
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    await onRowClicked(e);
  };

  const batchDelete = async () => {
    const res = await batchDeleteDocuments(navigate, { documents: selectedItems });

    const selectedIds = selectedItems.map((item) => item.id);

    if (ia(res?.data)) {
      showAlert({
        title: 'Documents deleted successfully',
        color: 'success'
      });
      queryClient.setQueriesData(['documents'], (old) => {
        return {
          ...old,
          documents: old?.documents?.filter((doc) => !selectedIds.includes(doc.id))
        };
      });

      const appointmentIds = [...new Set(res?.data?.map((doc) => doc?.appointment_id))]?.filter(
        Boolean
      );

      appointmentIds.forEach((id) => {
        queryClient.setQueriesData(['attachments', id.toString()], (old) => {
          return {
            ...old,
            documents: old?.documents?.filter((doc) => !selectedIds.includes(doc.id))
          };
        });
      });
    } else {
      showAlert({
        title: 'Failed to delete documents',
        color: 'danger'
      });
    }

    setSelectedRows([]);
    setDeleteConfirmation(false);
  };

  useEffect(() => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      return objMap(newFilters, (key, f) => ({
        ...f,
        options: getDocumentFilters(practitioners, patient, categories)[key].options
      }));
    });
  }, [practitioners, patient, categories]);

  return (
    <>
      <DocumentsHeader
        setViewDocument={setViewDocument}
        setShowInstantPacket={setShowInstantPacket}
        patient={patient}
        fromPatient={fromPatient}
        practitioners={practitioners}
        categories={categories}
        patientId={patientId}
        appointmentId={appointmentId}
        isLocked={isLocked}
        setDeleteConfirmation={setDeleteConfirmation}
      />
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz h-full">
          <AGTable
            data={documents}
            rowSelection="multiple"
            defaultColDef={documentTableCols}
            suppressRowClickSelection={true}
            statusBar={statusBar}
            customClassName="ag-grid-interactive"
            loading={isLoading}
            masterDetail={true}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>

      <Confirm
        variant="danger"
        secondaryBtnTxt="Keep"
        title="Delete documents"
        handleClose={() => setDeleteConfirmation(false)}
        icon="new-document-remove-red"
        primaryBtnTxt="Delete documents"
        handleOpen={deleteConfirmation}
        handleContinue={batchDelete}
        message="Are you sure you want to delete these documents?"
      />
    </>
  );
};

export default List;
