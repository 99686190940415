import React, { useState, useEffect } from 'react';
import Header from 'components/shared/Header/Header';
import { defaultColumns } from './lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Button from '../Buttons/Button';
import Wizard from '../Wizard/Wizard';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';
import { NewRoleContextProvider } from 'lib/context/Role/NewRoleContext/NewRoleContextProvider';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import Table from './Table';
import RoleDetails from './components/NewRole/RoleDetails/RoleDetails';
import FooterButtons from './components/FooterButtons';
import ManagePermissions from './components/NewRole/ManagePermissions/ManagePermissions';
import AssignRole from './components/NewRole/AssignRole/AssignRole';
import { EditRoleContextProvider } from 'lib/context/Role/EditRoleContext/EditRoleContextProvider';
import Allowed from '../Permissions/Allowed';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import { currentPractice } from 'components/practice/practiceState';

const Roles = () => {
  const permissions = useRecoilValue(state.permissions);
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice.timezone;
  const [columns, setColumns] = useState(defaultColumns(permissions, practiceTimezone));
  const [key, setKey] = useState(0); //Force re-rendering when permissions change

  useEffect(() => {
    setColumns(defaultColumns(permissions, practiceTimezone));
    setKey((prevKey) => prevKey + 1);
  }, [permissions]);

  return (
    <TableContextProvider key={key} cols={columns} name="notifications" pagination>
      <NewRoleContextProvider>
        <Header title="Roles & Permissions">
          <AddNewRoleButton />
        </Header>
        <EditRoleContextProvider>
          <Table />
        </EditRoleContextProvider>
        <NewRoleModal />
      </NewRoleContextProvider>
    </TableContextProvider>
  );
};

const AddNewRoleButton = React.memo(() => {
  const { setShowNewRoleModal } = useNewRoleContext();
  return (
    <Allowed requiredPermissions="role.create">
      <Button onClick={() => setShowNewRoleModal(true)} text="Add New Role" size="small" />
    </Allowed>
  );
});

const NewRoleModal = () => {
  const { showNewRoleModal, breadCrumbs, setBreadCrumbs, handleClose } = useNewRoleContext();

  const actionViews = {
    role_details: {
      component: <RoleDetails />,
      footer: (
        <FooterButtons
          buttonText="Set Permissions"
          nextTo="manage_permissions"
          showNavigationButtons
          firstStep={true}
          setBreadCrumbs={setBreadCrumbs}
        />
      )
    },
    manage_permissions: {
      component: <ManagePermissions />,
      footer: (
        <FooterButtons
          buttonText="Assign Role"
          backTo="role_details"
          nextTo="assign_role"
          showNavigationButtons
          setBreadCrumbs={setBreadCrumbs}
        />
      )
    },
    assign_role: {
      component: <AssignRole />,
      footer: (
        <FooterButtons
          buttonText="Save Role"
          backTo="manage_permissions"
          isLastStep
          setBreadCrumbs={setBreadCrumbs}
        />
      )
    }
  };

  const getCurrentView = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].component;
  };

  const getCurrentFooter = () => {
    let currentView = breadCrumbs?.find((row) => row.active).view;
    return actionViews[currentView].footer;
  };

  return (
    showNewRoleModal && (
      <Wizard
        isOpen={showNewRoleModal}
        handleClose={handleClose}
        title="Create New Role"
        breadcrumbs={breadCrumbs}
        setBreadCrumbs={setBreadCrumbs}
        footerButtons={getCurrentFooter()}>
        {getCurrentView()}
      </Wizard>
    )
  );
};

export default withErrorBoundary(Roles);
