import React, { useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { ia, iaRa } from 'lib/helpers/utility';

import Loading from 'components/shared/Loading/Loading';
import Typography from 'components/shared/Typography/Typography';

import { formatClaimData } from '../lib/formatClaimData';
import {
  CLAIMS_COLUMN_DEFS,
  DEFAULT_COLUMN_DEFS,
  GRID_OPTIONS,
  SELECTED_CLAIM_COLUMN_DEFS
} from '../lib/helpers';

import NoClaims from './NoClaims';
import { getRowClassRules } from './lib/helpers';

export default function AssignUnmatchedClaim() {
  const { unmatchedClaims, selectedClaim, selectedUnmatchedClaim, isUnmatchedClaimsLoading } =
    useEraContext();
  const [hoveredId, setHoveredId] = useState(null);
  const gridRef = useRef(null);

  const claimColumns = useMemo(() => CLAIMS_COLUMN_DEFS, []);

  const rowData = useMemo(() => formatClaimData({ claims: unmatchedClaims }), [unmatchedClaims]);

  const onCellMouseOver = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(event.data?.claimId);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const onCellMouseOut = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(null);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  if (isUnmatchedClaimsLoading) return <Loading />;

  return (
    <div className="flex flex-1 flex-col gap-2">
      <div className="!mb-4 flex flex-wrap gap-9">
        <div className="flex flex-col gap">
          <Typography variant="p" size="sm" color="text-primary-800">
            Patient
          </Typography>
          <Typography variant="p" size="xs" color="text-neutral-800">
            {selectedClaim?.claim?.patientName
              ? selectedClaim?.claim?.patientName
              : `User ID: ${selectedClaim?.claim?.pcn}`}
          </Typography>
        </div>

        <div className="flex flex-col gap">
          <Typography variant="p" size="sm" color="text-primary-800">
            Date of service
          </Typography>
          <Typography variant="p" size="xs" color="text-neutral-800">
            {selectedClaim?.claim?.date}
          </Typography>
        </div>

        <div className="flex flex-col gap">
          <Typography variant="p" size="sm" color="text-primary-800">
            Billed
          </Typography>
          <Typography variant="p" size="xs" color="text-neutral-800">
            {selectedClaim?.claim?.billed}
          </Typography>
        </div>
      </div>
      <div className="ag-theme-quartz shadow-none mb-4">
        {ia(selectedClaim?.claim?.charge) && (
          <AgGridReact
            columnDefs={SELECTED_CLAIM_COLUMN_DEFS}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            rowData={iaRa(selectedClaim?.claim?.charge)}
            domLayout="autoHeight"
          />
        )}
      </div>

      <Typography variant="p" size="lg" color="text-primary-800">
        Claims
      </Typography>
      {ia(rowData) && (
        <div className="ag-theme-quartz shadow-none">
          <AgGridReact
            columnDefs={claimColumns}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            rowData={rowData}
            domLayout="autoHeight"
            gridOptions={GRID_OPTIONS}
            onGridReady={(params) => (gridRef.current = params.api)}
            rowClassRules={getRowClassRules(selectedUnmatchedClaim, hoveredId)}
            onCellMouseOver={onCellMouseOver}
            onCellMouseOut={onCellMouseOut}
            suppressRowHoverHighlight
          />
        </div>
      )}

      <NoClaims data={rowData} />
    </div>
  );
}
