const atom = require('recoil').atom;

module.exports = {
  intelligenceChatIdState: atom({
    key: 'intelligenceChatIdState',
    default: null
  }),
  promptListState: atom({
    key: 'promptListState',
    default: []
  }),
  activePromptConversationsState: atom({
    key: 'activePromptConversationsState',
    default: []
  }),
  myScribeAILoadingState: atom({
    key: 'myScribeAILoadingState',
    default: false
  }),
  myScribeAutoMapSelectedNoteTypeState: atom({
    key: 'myScribeAutoMapSelectedNoteTypeState',
    default: null
  }),
  myScribeAutoMapActivePromptIdState: atom({
    key: 'myScribeAutoMapActivePromptIdState',
    default: null
  }),
  isAIGeneratingState: atom({
    key: 'isAIGeneratingState',
    default: false
  })
};
