import { useState } from 'react';

import { useField } from 'formik';

import useTestList from '../../hooks/useTestList';

import AddDuplicateTestConfirm from './AddDuplicateTestConfirm';
import TestList from './TestList';
import TestListFilter from './TestListFilter';

const TestsSection = ({ gridApi }) => {
  const [showDuplicateTestConfirm, setShowDuplicateTestConfirm] = useState(false);
  const [duplicateTest, setDuplicateTest] = useState(null);
  const [filters, setFilters] = useState({
    selectedLab: null,
    searchType: 'name',
    orderCodes: '',
    searchTerm: ''
  });

  const [field, _, helpers] = useField({
    name: 'tests'
  });

  const { setValue } = helpers;

  const { tests, testsDataLoading, addDuplicateTest, addTest, testsLoading } = useTestList({
    gridApi,
    setValue,
    filters,
    formikTests: field.value
  });

  const clickHandler = async (newTest) => {
    const addedTests = [];

    gridApi.forEachNode((node) => (!node.detail ? addedTests.push(node.data) : null));

    const existingTestIndex = addedTests.findIndex((test) => test.testID === newTest.testID);

    if (existingTestIndex > -1) {
      setDuplicateTest(newTest);
      setShowDuplicateTestConfirm(true);
    } else {
      addTest(newTest);
    }
  };

  const closeDuplicateTestConfirm = () => {
    setDuplicateTest(null);
    setShowDuplicateTestConfirm(false);
  };

  const filtersChangeHandler = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <>
      <div className="order-1 rounded-lg bg-primary-50">
        <TestListFilter filters={filters} filtersChangeHandler={filtersChangeHandler} />
      </div>

      <TestList
        tests={tests}
        testsLoading={testsLoading}
        testsDataLoading={testsDataLoading}
        clickHandler={clickHandler}
        filters={filters}
      />

      <AddDuplicateTestConfirm
        open={showDuplicateTestConfirm}
        closeHandler={closeDuplicateTestConfirm}
        continueHandler={() => {
          addDuplicateTest(duplicateTest);
          closeDuplicateTestConfirm();
        }}
        testName={duplicateTest?.testName}
      />
    </>
  );
};

export default TestsSection;
