import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { requestApi } from 'api/Api';

import { ia } from 'lib/helpers/utility';

import { checkinV2Map } from 'components/Patient/checkin/constants';
import { nextIncompleteStep } from 'components/Patient/checkin/util';
import 'components/shared/Modal/lib/Modal.scss';

export default function useInstantActions({
  steps,
  checkinButton,
  setNumCompleted,
  setSteps,
  setCurrentStepName,
  setCurrentStepNumber,
  setPracticeLogo,
  setUser,
  setPractitionerId
}) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleExternalSubmit = async () => {
    let naviToNext = false;
    setCompletedSteps(steps);
    if (checkinButton?.current?.click) {
      naviToNext = checkinButton?.current?.click();
      navigateToIncomplete({ steps });
    } else if (checkinButton?.current?.continue) {
      naviToNext = await checkinButton?.current?.continue();
      if (naviToNext) {
        navigateToIncomplete({ steps });
      }
    } else {
      // getPacketStatus();
      navigateToIncomplete({ steps });
    }
  };

  const setCompletedSteps = (stepsArray) => {
    const numberOfCompleted = stepsArray?.filter((v) => v.completed).length || null;
    if (numberOfCompleted) setNumCompleted(numberOfCompleted);
  };

  const initialCurrentStep = ({ path = null, status = false }) => {
    return pathname === path ? 'current' : status ? 'complete' : 'incomplete';
  };

  const currentStep = () => {
    setSteps((prevStep) =>
      prevStep.map((step) => {
        if (step.path === pathname || pathname.includes(step.path)) {
          setCurrentStepName(step.name);
          setCurrentStepNumber(prevStep.findIndex((v) => v.name === step.name) + 1);
          return { ...step, status: 'current' };
        } else {
          return { ...step, status: step.completed ? 'complete' : 'incomplete' };
        }
      })
    );
  };

  const updateCompleteStep = () => {
    setSteps((prev) => {
      const isAllCompleted = isEverythingCompleted(prev);
      const newSteps = prev.map((step) => {
        if (isAllCompleted && step.name === 'Complete')
          return { ...step, status: 'complete', completed: true, isStepDone: true };

        if (!isAllCompleted && !step.completed) return { ...step, isStepDone: false };

        return step;
      });
      return newSteps;
    });
  };

  const navigateToIncomplete = useCallback(
    ({ steps: givenSteps }) => {
      let lSteps = givenSteps;
      if (!lSteps) {
        lSteps = steps;
      }

      if (!ia(lSteps)) {
        return;
      }

      const incomplete = nextIncompleteStep(pathname, lSteps);

      let prefix = '';
      if (pathname.includes('kiosk')) {
        prefix = '/kiosk/';
      }
      navigate(
        incomplete?.path ? `${prefix}${incomplete?.path}` : `${prefix}/instant-packet/complete`
      );
    },
    [steps]
  );

  const isEverythingCompleted = (stepsArray) => {
    return (
      ia(stepsArray) &&
      stepsArray.filter((step) => step.name !== 'Complete').every((step) => step.completed === true)
    );
  };

  const getPacketStatus = async () => {
    const onSuccess = (data) => {
      // on success
      if (data.practiceLogo) {
        setPracticeLogo(data.practiceLogo);
      }
      const dataStep = data?.steps || {};

      let newSteps = dataStep.map((v) => ({
        ...v,
        name: checkinV2Map[v?.name]?.name(v) || v?.name
      }));

      data?.user && setUser(data.user);

      setPractitionerId(data?.practitionerId);

      const isCompleted = isEverythingCompleted(newSteps);

      newSteps.push({
        name: 'Complete',
        path: '/instant-packet/complete',
        status: initialCurrentStep({ path: '/instant-packet/complete', status: isCompleted }),
        completed: isCompleted,
        position: 99
      });

      newSteps = newSteps.sort((a, b) => a.position - b.position);

      if (ia(newSteps)) {
        newSteps.forEach((v) => {
          if (v.path === pathname) {
            setCurrentStepName(v.name);
          }
        });
      }

      setCompletedSteps(newSteps);
      setSteps(newSteps);
    };
    await requestApi({
      url: '/api/form/instant_packet/status',
      onSuccess,
      navigate,
      filePath: __filename
    });
  };

  const updateStep = useCallback(
    async ({ newStatus, stepId, formId, meta }) => {
      let foundStepId = null;

      const newSteps = steps.map((v) => {
        if ((stepId && v.step_id === stepId) || pathname.includes(v.path)) {
          foundStepId = v.step_id;
          return { ...v, completed: ['partial', 'completed'].includes(newStatus) };
        } else {
          return v;
        }
      });

      setSteps(newSteps);

      if (!formId) {
        await requestApi({
          url: '/api/form/instant_packet/continue',
          params: {
            stepId: stepId || foundStepId,
            newStatus,
            meta
          },
          navigate
        });
      }

      navigateToIncomplete({ steps: newSteps });
    },
    [steps]
  );

  const getLastStep = () => {
    if (pathname.endsWith('/pay')) {
      for (let i = 0; i < steps.length; i++) {
        if (checkinV2Map.payment?.name?.() === steps[i]?.name) {
          return steps[i]?.path;
        }
      }
      // return checkinV2Map.payment.path();
    }

    for (let i = 0; i < steps.length; i++) {
      if (i > 0 && steps[i]?.path === pathname) {
        return steps[i - 1]?.path;
      }
    }

    return steps[0]?.path;
  };

  const handleBack = () => {
    let prefix = '';
    if (pathname.includes('kiosk')) {
      prefix = '/kiosk/';
    }

    const lastStep = getLastStep();

    navigate(lastStep ? `${prefix}${lastStep}` : `${prefix}/checkin/hipaa`);
  };

  return {
    updateStep,
    getPacketStatus,
    navigateToIncomplete,
    isEverythingCompleted,
    handleExternalSubmit,
    currentStep,
    updateCompleteStep,
    setCompletedSteps,
    handleBack
  };
}
