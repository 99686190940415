import { Honeybadger } from '@honeybadger-io/react';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import { responseUpdated } from '../../../lib/helpers/utility';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import Loading from '../../shared/Loading/Loading';
import cs from 'classnames';

import state from './state';

const CheckinForms = (props) => {
  const { updateSteps, checkinButton, appointmentId } = useOutletContext();
  const [form, setForm] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [checkinData, setCheckinData] = useRecoilState(state.checkinDataState);

  useEffect(() => {
    getCheckinForm();
  }, [id]);

  const getCheckinForm = async () => {
    // setForm(await FormGet(navigate, { stepId: id })?.form);
    setForm(null);
    const onSuccess = (partData) => {
      const lForm = partData?.form;
      if (lForm?.response)
        setForm(_.set(lForm, 'response.json.fields', JSON.parse(lForm?.response?.json?.fields)));
      else {
        if (checkinData?.forms?.[id]) {
          setForm(_.set(lForm, 'response.json.fields', checkinData?.forms?.[id]));
        } else {
          setForm(lForm);
        }
      }
    };
    const onError = () => {
      //   something
    };

    await requestApi({
      url: '/api/patient/checkin/form/get',
      params: { stepId: id },
      onSuccess,
      onError
    });
  };

  const handleSubmit = async (e) => {
    try {
      setCheckinData((ps) => _.set(ps, `forms.${id}`, e));
      let params = {
        practiceId: form?.practice_id,
        responseId: form?.response?.id,
        formId: form?.id,
        json: { fields: JSON.stringify(e) },
        appointmentId: appointmentId || props.appointmentId,
        patientId: form?.response?.user_id,
        formVersion: form?.version
      };

      let res = {};

      if (responseUpdated({ form, submitResponse: e })) {
        res = await requestApi({ url: '/api/form/upsert_response', params, navigate });
      }

      updateSteps({
        version: 2,
        formId: form.id,
        stepId: id,
        newStatus: 'completed'
      });
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const location = useLocation();

  const fromCheckin = location?.pathname.includes('/checkin');

  if (!form) return <Loading />;

  return (
    // <div className={`!pb-24 xs:!p-0`} style={{ minHeight: '100vh' }}>
    <div className="flex justify-center !pb-24 xs:!p-0">
      <div className={cs(' !p-4 w-full', fromCheckin && 'from-checkin')}>
        <div className="box">
          <ReactFormGenerator
            answer_data={form?.response?.json?.fields || null}
            data={form?.json?.fields || []}
            // data={JSON.parse(form?.response?.json?.fields) || []}
            onSubmit={(e) => handleSubmit(e)}
            submitButton={
              <button hidden ref={checkinButton} type="submit">
                Submit
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(CheckinForms);
