import React, { useState, useRef, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import cs from 'classnames';
import Select from 'components/shared/Select/Select';
import moment from 'moment-timezone';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { PatternFormat } from 'react-number-format';
import { dateFormats } from '../../../../constants';
import Icon from '../../Icon/Icon';

const generateFormat = (format) => {
  return format
    .replace('MM', '##')
    .replace('DD', '##')
    .replace('YYYY', '####')
    .replace('hh', '##')
    .replace('mm', '##')
    .replace('ss', '##');
};

const correctYear = (dateStr) => {
  let dateArray = dateStr.replaceAll('_', '').split('/');
  const year = dateArray[2];

  if (year?.length === 2) {
    const currentYear = moment().year();
    const threshold = (currentYear % 100) + 5;

    dateArray[2] = year <= threshold ? 2000 + parseInt(year) : 1900 + parseInt(year);
    return dateArray.join('/');
  }

  return dateStr;
};

const otherOption = (option) => {
  if (option === 'AM') {
    return 'PM';
  } else if (option === 'PM') {
    return 'AM';
  }
  return option;
};
const formatDateAndTime = (dateTime, amPmPart) => {
  const datePart = dateTime?.split(' ')[0];
  const timeParts = dateTime?.split(' ')[1];
  const [hours, minutes, seconds] = timeParts.split(':');

  const formattedHours = (hours > 12 ? hours - 12 : hours).toString().padStart(2, '0');
  let newAmPm = amPmPart;
  if (hours > 12) {
    newAmPm = otherOption(amPmPart);
  }
  const timeToReturn = `${datePart} ${formattedHours}:${minutes}:${seconds} ${newAmPm}`;
  return timeToReturn;
};

const DatePopover = ({
  label,
  labelClassName,
  inputClassName,
  iconClassName,
  className,
  placeholder = 'Add date',
  icon = 'new-calendar-gray',
  isTime = false,
  format = 'MM/DD/YYYY',
  value,
  placementX,
  placementY = 'top-[110%]',
  onChange = () => {},
  disabled = false,
  required = false,
  id,
  name,
  formik,
  focusNextFieldOnEnter = false,
  error,
  dataQaInput = 'date-popover-input',
  dataQaIcon = 'date-popover-icon',
  ...rest
}) => {
  const dateFormat = isTime ? 'MM/DD/YYYY hh:mm:ss A' : format;
  const selectOptions = [
    { label: 'AM', value: 'AM' },
    { label: 'PM', value: 'PM' }
  ];

  const [formattedValue, setFormattedValue] = useState(
    moment(value, dateFormats, true).isValid()
      ? moment(value, dateFormats, true).format(dateFormat)
      : ''
  );
  const inputFormat = generateFormat(format);
  const debouncedCorrectYear = useRef(debounce((value) => handleChange(value), 1000)).current;
  useEffect(() => {
    if (moment(value)?.isValid()) {
      setFormattedValue(moment(value, dateFormats).format(dateFormat));
    }
  }, [value]);

  const timer = useRef();

  function debounce(func, delay) {
    return function (...args) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  const updateAMorPM = (value) => {
    const selectedAMPM = selectOptions.find((option) => option.value === value?.value)?.value;
    setFormattedValue((prevFormattedValue) => {
      let newValue;
      if (selectedAMPM === 'AM') {
        newValue = moment(prevFormattedValue, dateFormat).subtract(12, 'hours').format(dateFormat);
      } else if (selectedAMPM === 'PM') {
        newValue = moment(prevFormattedValue, dateFormat).add(12, 'hours').format(dateFormat);
      }

      onChange(newValue);
      return newValue;
    });
  };

  const handleChange = (value) => {
    if (isTime) {
      const amPmPart = moment(formattedValue, dateFormat).format('A');
      const test = moment(value, 'MM/DD/YYYY hh:mm:ss').isValid();
      if (!test) return;
      const newDateAndTime = formatDateAndTime(value, amPmPart);

      setFormattedValue(newDateAndTime);
      onChange(newDateAndTime);
    } else {
      const correctedValue = correctYear(value);
      setFormattedValue(correctedValue);
      onChange(correctedValue);
    }
  };

  const handleValueChange = (formattedValue) => {
    const dateParts = formattedValue?.split('/');
    const year = dateParts[2]?.replace(/\D/g, '') || '';
    if (year?.length === 2) {
      debouncedCorrectYear(formattedValue);
    } else {
      clearTimeout(timer.current);
      handleChange(formattedValue);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && focusNextFieldOnEnter && formik) {
      const fields = Object.keys(formik.values);
      const index = fields.indexOf(name);
      if (index >= 0) {
        const nextFieldName = fields[index + 1];
        if (nextFieldName) {
          document.querySelector(`input[name="${nextFieldName}"]`)?.focus();
        } else {
          formik.submitForm();
        }
      }
    }
  };
  const handleOnChangeCalendar = (event) => {
    const currentTime = moment.utc(formattedValue).format('hh:mm:ss A');

    const formattedDate = isTime
      ? moment(event).format(format)
      : moment.utc(event).add(12, 'h').format(format);

    const newFormattedValue = isTime ? `${formattedDate} ${currentTime}` : formattedDate;
    setFormattedValue(newFormattedValue);
    onChange(newFormattedValue);
  };

  return (
    <Popover className={className}>
      {({ open, close }) => (
        <>
          {label && (
            <div className="flex min-h-[28px] items-center justify-between !pb-1">
              <label
                className={cs('m-0 flex text-sm font-500 text-neutral-800', labelClassName)}
                htmlFor={id}>
                {label}
                {required && <span className="text-danger-500">*</span>}
              </label>
            </div>
          )}
          <div className="relative flex">
            <PatternFormat
              className={cs(
                '!h-10 w-full !rounded-md border border-solid !border-neutral-200 !p-0 !px-3 text-sm text-neutral-900 outline-offset-2 placeholder:text-neutral-500 focus-visible:!border-primary-300 focus-visible:!outline-[#CBF0FD]',
                inputClassName
              )}
              mask="_"
              format={isTime ? '##/##/#### ##:##:##' : inputFormat}
              placeholder={placeholder}
              value={formattedValue || value}
              onValueChange={({ formattedValue }) => handleValueChange(formattedValue)}
              disabled={disabled}
              id={id}
              name={name}
              data-qa={dataQaInput}
              onKeyDown={handleKeyPress}
            />
            <Popover.Button
              data-qa={dataQaIcon}
              className={cs(
                'absolute right-[10px] top-1/2 flex -translate-y-1/2 cursor-pointer items-center justify-center p-0',
                iconClassName
              )}>
              {isTime && (
                <div className="mr-1" onClick={(e) => e.stopPropagation()}>
                  <Select
                    onChange={(value) => updateAMorPM(value)}
                    parentClassName="w-20 !border-none"
                    isClearable={false}
                    options={selectOptions}
                    value={{
                      label: formattedValue ? moment(formattedValue, dateFormat).format('A') : 'AM',
                      value: formattedValue ? moment(formattedValue, dateFormat).format('A') : 'AM'
                    }}
                  />
                </div>
              )}
              <Icon icon={icon} className="cursor-pointer" />
            </Popover.Button>
            <Transition
              show={open}
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-0 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-100 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-0 opacity-0"
              className={cs(
                'absolute z-10 flex w-max overflow-hidden !rounded-lg bg-white !px-2 shadow-md',
                placementX,
                placementY
              )}>
              <Popover.Panel>
                <Calendar
                  showDateDisplay={false}
                  date={
                    moment(formattedValue, dateFormats).isValid()
                      ? moment(formattedValue, dateFormats).toDate()
                      : moment().toDate()
                  }
                  onChange={(event) => {
                    handleOnChangeCalendar(event);
                    close();
                  }}
                  {...rest}
                />
              </Popover.Panel>
            </Transition>
          </div>
          {error && <p className={cs('!pt-2 text-left text-sm text-danger-500')}>{error}</p>}
        </>
      )}
    </Popover>
  );
};

export default DatePopover;
