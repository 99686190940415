import TableView from 'components/shared/Tags/components/TableView';
import React from 'react';

const UnPinnedTagsContainer = ({
  currentTags = [],
  handleRemoveTag = () => {},
  handleAddTags = () => {},
  tagsToAdd = [],
  setTagsToAdd = () => {},
  selectActions = [],
  isCreateModal = false,
  setIsCreateModal = () => {}
}) => {
  return (
    <TableView
      currTags={currentTags}
      handleRemove={handleRemoveTag}
      handleAdd={handleAddTags}
      tagsToAdd={tagsToAdd}
      setTagsToAdd={setTagsToAdd}
      selectActions={selectActions}
      isCreateModal={isCreateModal}
      setIsCreateModal={setIsCreateModal}
      tagsKind="chart"
      fromPatientChart
    />
  );
};

export default UnPinnedTagsContainer;
