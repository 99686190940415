import React, { useState } from 'react';
import Input from 'components/shared/Input/Input';
import { capitalize } from 'lodash';
import Select from 'components/shared/Select/Select';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';

export default function AddStaff({
  onChange,
  onSubmit,
  formRef,
  formik,
  genderList,
  roles,
  tax_id_types,
  forOwners = false
}) {
  const [show, setShow] = useState({ claim_md_info: false });

  return (
    <form onSubmit={onSubmit} ref={formRef}>
      <div className="grid  grid-cols-3 gap-4 md:grid-cols-1 ">
        <Input
          placeholder="Enter first name"
          label="First Name"
          name="f_name"
          value={formik.values.f_name}
          onChange={onChange}
          error={formik.errors?.f_name}
        />

        <Input
          placeholder="Enter middle name"
          label="Middle Name"
          name="m_name"
          value={formik.values.m_name}
          onChange={onChange}
          error={formik.errors?.m_name}
        />

        <Input
          placeholder="Enter last name"
          label="Last Name"
          name="l_name"
          value={formik.values.l_name}
          onChange={onChange}
          error={formik.errors?.l_name}
        />

        <Select
          isClearable={false}
          className="!w-full"
          inputId="gender"
          options={genderList}
          label="Gender"
          placeholder="Type and select Gender"
          value={{ value: formik.values?.gender, label: capitalize(formik.values?.gender) }}
          onChange={(option) => formik.setFieldValue('gender', option?.value)}
          error={formik.errors?.gender}
        />
        <div className="flex flex-col">
          <DatePopover
            label="Date of Birth"
            name="dob"
            value={formik.values.dob}
            onChange={(formattedDate) => formik.setFieldValue('dob', formattedDate)}
          />
          {formik.touched.dob && formik.errors.dob ? (
            <span className="pt-1 text-sm text-danger-500">{formik.errors.dob}</span>
          ) : null}
        </div>

        <Input
          placeholder="Enter phone number"
          label="Phone Number"
          name="phone"
          data-qa="phone-number"
          value={formik.values?.phone}
          onChange={onChange}
          error={formik.errors?.phone}
        />
      </div>
      <div className=" grid grid-cols-3 gap-4 py-3 md:grid-cols-1 ">
        <Input
          placeholder="Enter email"
          label="Email"
          name="email"
          data-qa="email"
          value={formik.values?.email}
          onChange={onChange}
          error={formik.errors?.email}
        />
        {!forOwners && (
          <div>
            <Select
              isClearable={false}
              label="Role"
              name="role"
              inputId="select-role"
              data-qa="role"
              options={roles}
              error={formik.errors?.role}
              placeholder="Role"
              value={{
                label: roles.find((item) => item.value === formik.values?.role)?.label
              }}
              onChange={(option) => formik.setFieldValue('role', option?.value)}
            />
          </div>
        )}
      </div>

      {!forOwners && (
        <>
          <div className="pt-3">
            <Checkbox
              checked={show.claim_md_info}
              onChange={(v) => {
                v.persist();
                setShow({ ...show, claim_md_info: v.target.checked });
              }}
              label="ClaimMD & Taxation Info"
            />
          </div>

          {show.claim_md_info && (
            <>
              <h4 className="mb-3 mt-6">Business Info</h4>
              <div className="grid grid-cols-3 gap-4 md:grid-cols-1">
                <Input
                  label="Claim.MD User ID"
                  name="claim_md_user_id"
                  value={formik.values?.claim_md_user_id}
                  onChange={onChange}
                  error={formik.errors?.claim_md_user_id}
                />

                <Input
                  label="NPI"
                  name="npi"
                  data-qa="npi"
                  value={formik.values?.npi}
                  onChange={onChange}
                  error={formik.errors?.npi}
                />
              </div>
              <div className="grid grid-cols-3 gap-4 !pt-2 md:grid-cols-1">
                <Input
                  label="Tax ID"
                  name="tax_id"
                  data-qa="tax-id"
                  value={formik.values?.tax_id}
                  onChange={onChange}
                />

                <Select
                  label="Tax ID Type"
                  name="tax_id_type"
                  data-qa="tax-id-type"
                  options={tax_id_types}
                  placeholder="Tax ID Type"
                  value={{
                    label: tax_id_types.find((tax) => tax.value === formik.values?.tax_id_type)
                      ?.label
                  }}
                  onChange={(options) => formik.setFieldValue('tax_id_type', options?.value)}
                />

                <Input
                  label="Taxonomy"
                  name="taxonomy"
                  data-qa="taxonomy"
                  value={formik.values?.taxonomy}
                  onChange={onChange}
                />
              </div>
              <h4 className="mb-3 mt-6">Claim MD</h4>
              <div className="grid  grid-cols-3 gap-4 md:grid-cols-1">
                <Input
                  label="User ID"
                  name="claim_md_user_id"
                  data-qa="claim-md-user-id"
                  value={formik.values?.claim_md_user_id}
                  onChange={onChange}
                />
              </div>
            </>
          )}
        </>
      )}
    </form>
  );
}
