import React from 'react';

import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';

import TreatmentPlanTable from './TreatmentPlanTable';

const FooterWrapperTreatmentPlan = ({
  treatmentPlan,
  setShowAllRowsTable = () => {},
  onRowClicked = () => {}
}) => {
  return (
    <FooterWrapper
      onClick={() => setShowAllRowsTable(true)}
      text={`${treatmentPlan?.length > 0 ? `View all Treatment Plans (${treatmentPlan?.length})` : ''}   `}>
      {treatmentPlan ? (
        <TreatmentPlanTable data={treatmentPlan} onRowClicked={onRowClicked} />
      ) : (
        <div className="p-[20px] py-3 text-center">
          <p className="font-medium text-neutral-500">No Treatment Plans</p>
        </div>
      )}
    </FooterWrapper>
  );
};
export default FooterWrapperTreatmentPlan;
