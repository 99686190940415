import React from 'react';

import Typography from '../Typography/Typography';

const StatusBarAggregation = ({ data }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <Typography weight="bold">Selected Services:</Typography>
      <Typography>{data.length}</Typography>
    </div>
  );
};

export default StatusBarAggregation;
