import cs from 'classnames';
import { showAlert } from 'components/shared/Alert/Alert';
import _, { capitalize } from 'lodash';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Container from '../../components/Container';
import Header from '../../components/Header';
import { doEmptyFormExist } from '../../lib/emptyFormsExist';
import CurrentMedicationsRow from '../CurrentMedicationsRow';
import { medicationInitialValue } from '../lib';

const MedicationsForm = ({
  type = 'current',
  handleNoDetails,
  syncMedicationsAndClinicalNotes,
  newDesign,
  setMedicationToDelete,
  setConfirmationModal,
  noDetails,
  setNoDetails,
  currentMedications,
  setCurrentMedications
}) => {
  const newMedication = { ...medicationInitialValue, id: self.crypto.randomUUID() };
  const location = useLocation();
  const fromCheckin = location?.pathname?.includes('/checkin');

  const isPast = type === 'past';

  const updateNoDetails = (value) => {
    const other = !isPast ? 'past' : 'current';
    setNoDetails((p) => ({ ...p, [type]: value }));

    if (value === false && noDetails[other] === false) {
      handleNoDetails(true);
    } else {
      if (currentMedications?.noDetails) {
        handleNoDetails(false);
      }
      const updatedObject = {
        ...currentMedications,
        noDetails: false,
        medications: [
          ...currentMedications.medications.filter((med) => med.is_past === (type !== 'past')),
          { ...medicationInitialValue, is_past: isPast, id: self.crypto.randomUUID() }
        ]
      };
      setCurrentMedications(updatedObject);
      syncMedicationsAndClinicalNotes(updatedObject);
    }
  };

  const handleAddRow = (event) => {
    event?.preventDefault();

    const updatedMedication = { ...newMedication, is_past: isPast };

    const shouldStopProcess = doEmptyFormExist({
      newForm: updatedMedication,
      forms: currentMedications?.medications
    });

    if (shouldStopProcess) {
      return showAlert({
        title: 'Medications',
        message: 'Empty medication exists. Please fill in the details.',
        color: 'warning'
      });
    }

    const updatedObject = {
      ...currentMedications,
      noDetails: false,
      medications: [...currentMedications.medications, updatedMedication]
    };

    syncMedicationsAndClinicalNotes(updatedObject);
  };

  const handleOnChange = (key, event, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncMedicationsAndClinicalNotes(updatedObject);
  };

  const handleOnChangeMulti = (values, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, ...values };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncMedicationsAndClinicalNotes(updatedObject);
  };

  const medications = useMemo(
    () => currentMedications?.medications?.filter((m) => m?.is_past === isPast),
    [currentMedications?.medications]
  );

  const isNoDetails = (original, meds) => {
    if (original === false) {
      return true;
    } else if (original === null || meds?.length === 0) {
      return null;
    } else {
      return false;
    }
  };

  return (
    <Header
      noDetailsLabel={
        isPast
          ? 'Have you used any medications in the past that you’re no longer taking?'
          : 'Are you taking any medications?'
      }
      noDetails={isNoDetails(noDetails?.[type], medications)}
      isAdvancedForm
      className="!mt-4"
      title={!isPast && 'Medications'}
      emptyState={
        fromCheckin && {
          title: `No ${isPast ? 'past ' : ''}medications added`,
          subtitle:
            'Please let us know if you are currently taking any medications or have taken any in the past. This helps us ensure we have a complete picture of your health.',
          label: isPast
            ? 'Have you used any medications in the past that you’re no longer taking?'
            : 'Do you have any medications not reported to our practice?'
        }
      }
      addRow={{
        label: 'Do you have any other medications to add?',
        onClick: (event) => handleAddRow(event)
      }}
      handleNoDetails={(checked) => updateNoDetails(!checked)}>
      {!newDesign && (
        <div className="ml-24 flex w-full items-start">
          <p className="mt-4 text-left text-lg text-neutral-800 ">{capitalize(type)} medications</p>
        </div>
      )}
      <div className="flex w-full flex-col items-center gap-y-4">
        {medications?.map((item, idx) => (
          <Container
            number={idx + 1}
            key={item.id}
            onDelete={
              currentMedications?.medications?.filter((m) => m?.is_past === isPast)?.length > 1 ||
              !doEmptyFormExist({
                newForm: newMedication,
                forms: currentMedications?.medications
              })
                ? () => {
                    setMedicationToDelete(item?.id);
                    setConfirmationModal(true);
                  }
                : null
            }>
            <CurrentMedicationsRow
              className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
              key={item.id}
              onChange={handleOnChange}
              newDesign={newDesign}
              medicationInitialValue={{
                ...medicationInitialValue,
                is_past: isPast,
                id: self.crypto.randomUUID()
              }}
              onChangeMulti={handleOnChangeMulti}
              length={currentMedications?.medications?.filter((m) => m?.is_past === isPast).length}
              {...item}
            />
          </Container>
        ))}
      </div>
    </Header>
  );
};

export default MedicationsForm;
