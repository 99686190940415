import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import { iaRa } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { currentPractice } from 'components/practice/practiceState';
import InsuranceProfiles from 'components/practice/charts/InsuranceProfile/InsuranceProfiles';

const InsuranceProfile = ({ selectedInsurance }) => {
  const { values } = useFormikContext();

  const practice = useRecoilValue(currentPractice);

  const { data } = usePatient({ params: { id: values?.id }, dependencies: [values?.id] });
  const insuranceProfiles = iaRa(data?.patient?.insuranceProfile);

  return (
    <InsuranceProfiles
      patientId={values.id}
      patient={data?.patient}
      practiceId={practice.id}
      selected={selectedInsurance}
      insuranceProfiles={insuranceProfiles}
    />
  );
};

export default InsuranceProfile;
