import { userTrackClick } from 'api/User';
import { tableNames } from 'constants';
import { ia } from 'lib/helpers/utility';

export const trackUserClickForOldUI = async ({
  navigate = () => null,
  currentICDValueArray,
  newICDArray
}) => {
  if (!ia(newICDArray)) {
    return;
  }

  const value = newICDArray.filter((item) => !currentICDValueArray.includes(item))?.[0];

  if (!value) {
    return;
  }

  await userTrackClick(navigate, { searchId: value, searchType: tableNames.ICD10 });
};

export const trackUserClick = async ({
  setTrackSearchDataLoading = () => null,
  value,
  navigate = () => null
}) => {
  setTrackSearchDataLoading((prev) => ({ ...prev, [value]: true }));

  await userTrackClick(navigate, { searchId: value, searchType: tableNames.ICD10 });

  setTrackSearchDataLoading((prev) => ({ ...prev, [value]: false }));
};
