import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCustomForms } from '../../../../api/CustomForms';

export const useAllCustomForms = (options) => {
  const navigate = useNavigate();
  return useQuery(['allCustomForms', options], () => getCustomForms(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
