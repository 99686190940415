import React from 'react';
import Input from '../../../../../../../shared/Input/Input';

const PatientInfo = ({ formik }) => {
  return (
    <div className="flex !gap-3 w-full">
      <div className="w-full">
        <Input
          id="f_name"
          name="patient_f_name"
          label="First Name"
          placeholder="John"
          type="text"
          value={formik?.values?.patient_f_name}
          error={formik?.errors?.patient_f_name}
          onChange={formik && formik?.handleChange}
        />
      </div>
      <div className="w-full">
        <Input
          id="l_name"
          name="patient_l_name"
          label="Last Name"
          placeholder="Doe"
          type="text"
          value={formik?.values?.patient_l_name}
          error={formik?.errors?.patient_l_name}
          onChange={formik && formik?.handleChange}
        />
      </div>
      <div className="w-full">
        <Input
          id="age"
          name="patient_age"
          data-qa="age"
          label="Age"
          placeholder="30"
          type="number"
          value={formik?.values?.patient_age}
          error={formik?.errors?.patient_age}
          onChange={formik && formik?.handleChange}
        />
      </div>
    </div>
  );
};

export default PatientInfo;
