import { useNavigate } from 'react-router-dom';

import { userTrackClick } from 'api/User';

const useTrackSearchClick = () => {
  const navigate = useNavigate();

  const trackClick = async (value) => {
    userTrackClick(navigate, { searchId: value, searchType: 'labsoft_test' });
  };

  return trackClick;
};

export default useTrackSearchClick;
