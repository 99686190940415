import React from 'react';
import cs from 'classnames';
import useAutosizeElement from '../../../../../../../../../lib/hooks/useAutosizeElement';

const TextareaCustom = ({
  label,
  icon,
  placeholder,
  value,
  id = label || 'textareaId',
  name = 'textareaName',
  className,
  width,
  disabled = false,
  setDisabled = () => {},
  forwardedRef,
  onChange = () => {},
  textareaClassName,
  rightText = null,
  ...rest
}) => {
  useAutosizeElement(forwardedRef, value);

  return (
    <div className={cs(className)} style={{ width: width }}>
      <label
        className={cs(
          'caret-primary-500 focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
          '!m-0 flex w-full items-center justify-between gap-x-3 overflow-hidden rounded-md border border-neutral-200 bg-white !px-3 text-sm leading-5 text-neutral-900 placeholder:text-neutral-500 focus-within:outline focus-within:!outline-2',
          textareaClassName,
          disabled && 'cursor-not-allowed text-neutral-400'
        )}>
        <textarea
          className={cs(
            '!border-none overflow-hidden resize-none !w-full py-[10px] !outline-none',
            textareaClassName
          )}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          name={name}
          disabled={disabled}
          ref={forwardedRef}
          {...rest}
        />

        {rightText && (
          <div className="flex shrink-0 items-center gap-x-1">
            {rightText && <p className="text-sm text-neutral-600">{rightText}</p>}
          </div>
        )}
      </label>
    </div>
  );
};

export default TextareaCustom;
