import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from 'lib/helpers/utility';
import { useFilledAdvancedForms } from 'lib/hooks/queries/clinicalNotes/useFilledAdvancedForms';

import { clinicalNote as clinicalNoteState, userState } from 'components/state';

import { useMyScribeAIContext } from '../MyScribeAIContext';

import { EnhanceNoteContextApi, EnhanceNoteContextData } from './EnhanceNoteContext';
import {
  extractAdvancedForms,
  getFinalAdvancedForms,
  getInitialFormsState,
  getNonEmptyFields
} from './lib/helpers';

export const EnhanceNoteContextProvider = ({ children }) => {
  const { typeOfNotes } = useMyScribeAIContext();
  const { appointmentId } = useParams();

  const [enhanceLoading, setEnhanceLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);
  const [nestedSelectedChatComponents, setNestedSelectedChatComponents] = useState([]);
  const [filledForms, setFilledForms] = useState();
  const [scrollTo, setScrollTo] = useState(null);

  const componentRefs = useRef({});

  const { currentHpOverviewData, advancedHP, advancedSOAP, customFormTypes, overviewData } =
    useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const currentUser = useRecoilValue(userState);
  const displaySettings = currentUser?.display_settings;
  const myScribeAISettings = displaySettings?.clinicalNote?.myscribe_ai || {};

  const { data, isLoading: filledFormsLoading } = useFilledAdvancedForms({
    params: { appointmentId },
    dependencies: [appointmentId]
  });

  const filledAdvancedForms = data?.advancedForms;

  const advancedForms = useMemo(
    () => extractAdvancedForms(advancedHP, advancedSOAP, customFormTypes),
    [advancedHP, advancedSOAP, customFormTypes]
  );

  const finalFilledAdvancedForms = useMemo(
    () => getFinalAdvancedForms(filledAdvancedForms, advancedForms),
    [filledAdvancedForms, advancedForms]
  );

  const nonEmptyAdvancedFormsTitles = useMemo(
    () => finalFilledAdvancedForms?.map((form) => form?.custom_title).filter(Boolean),
    [finalFilledAdvancedForms]
  );

  const clinicalNoteForms = useMemo(
    () => ({ ...clinicalNote, ...currentHpOverviewData, ...overviewData }),
    [clinicalNote, currentHpOverviewData, overviewData]
  );

  const nonEmptyFields = useMemo(() => getNonEmptyFields(clinicalNoteForms), [clinicalNoteForms]);
  const nonEmptyTitles = useMemo(() => Object.keys(nonEmptyFields || {}), [nonEmptyFields]);

  useEffect(() => {
    const defaultSettings = ia(myScribeAISettings?.enhance_note)
      ? myScribeAISettings.enhance_note.find((settings) => settings.isDefault)
      : null;

    setForms({
      typeOfNotes,
      nonEmptyTitles,
      nonEmptyAdvancedFormsTitles,
      enhanceNoteSettings: defaultSettings?.forms
    });
  }, [myScribeAISettings?.enhance_note, setForms, typeOfNotes]);

  const setForms = useCallback((obj) => {
    const initialFormsState = getInitialFormsState(obj);
    setLoading(false);

    setFilledForms(initialFormsState);
  }, []);

  const onResetSettings = useCallback(() => {
    setForms({
      typeOfNotes,
      nonEmptyTitles,
      nonEmptyAdvancedFormsTitles,
      enhanceNoteSettings: null
    });
  }, [typeOfNotes, nonEmptyTitles, nonEmptyAdvancedFormsTitles, setForms]);

  const onSetSettings = useCallback(
    (enhanceNoteSettings) => {
      setForms({
        typeOfNotes,
        nonEmptyTitles,
        nonEmptyAdvancedFormsTitles,
        enhanceNoteSettings
      });
    },
    [typeOfNotes, nonEmptyTitles, nonEmptyAdvancedFormsTitles, setForms]
  );

  const ctxData = useMemo(
    () => ({
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs,
      filledFormsLoading,
      loading,
      scrollTo
    }),
    [
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs,
      filledFormsLoading,
      loading,
      scrollTo
    ]
  );

  const ctxApi = useMemo(
    () => ({
      setSelectedChatComponents,
      setNestedSelectedChatComponents,
      setFilledForms,
      setEnhanceLoading,
      setLoading,
      setScrollTo,
      setEnhanceNoteSettings: onSetSettings,
      resetSettings: onResetSettings
    }),
    [
      setSelectedChatComponents,
      setNestedSelectedChatComponents,
      setFilledForms,
      setEnhanceLoading,
      setLoading,
      setScrollTo,
      onResetSettings,
      onSetSettings
    ]
  );

  return (
    <EnhanceNoteContextData.Provider value={ctxData}>
      <EnhanceNoteContextApi.Provider value={ctxApi}>{children}</EnhanceNoteContextApi.Provider>
    </EnhanceNoteContextData.Provider>
  );
};
