import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { interimApi } from '../../../api/InterimApi';
import { Chart3dContextProvider } from '../../../lib/context/Chart3dContext/Chart3dContextProvider';
import { ClinicalNoteContextProvider } from '../../../lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import Spinner from '../../shared/spinner/spinner';
import MedicalRecord from './MedicalRecord/MedicalRecord';
import './MedicalRecords.scss';
import { useNavigate } from 'react-router-dom';

export default function MedicalRecords() {
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [refetchData, setRefetchData] = useState(false);
  const [loading, setLoading] = useState({
    spinner: false,
    skeleton: false
  });
  const navigate = useNavigate();

  useEffect(() => {
    // TODO: We only want to show the medical history if the practice enables it. At the moment DONT SHOW any medical records because providers might enter language that the patient should not see
    getMedicalRecords();
  }, [refetchData]);

  const getMedicalRecords = async () => {
    setLoading({ ...loading, spinner: true });
    try {
      let res = await interimApi('/api/patient/medical_records/read', {}, navigate);
      const { medicalRecords: loadedMedicalRecords } = res.data;
      if (res.data.medicalRecords) {
        setMedicalRecords(loadedMedicalRecords);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
    setLoading({ ...loading, spinner: false });
  };

  return (
    <ClinicalNoteContextProvider>
      <Chart3dContextProvider>
        <div className="MedicalRecord">
          <div className="MedicalRecord__table">
            <div className="MedicalRecord__table__head">
              <p>Practice</p>
              <p>Practitioner</p>
              <p>Date</p>
              <p>View details</p>
            </div>
            {loading.spinner ? (
              <div className="Spinner-center">
                <Spinner />
              </div>
            ) : medicalRecords.length > 0 ? (
              medicalRecords.map((medicalRecord, index) => {
                return (
                  <MedicalRecord
                    key={index}
                    medicalRecord={medicalRecord}
                    callback={() => setRefetchData(!refetchData)}
                  />
                );
              })
            ) : (
              <p className="fs-14 w-full p-20 text-center">No medical records!</p>
            )}
          </div>
        </div>
      </Chart3dContextProvider>
    </ClinicalNoteContextProvider>
  );
}
