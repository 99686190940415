import { useQuery } from '@tanstack/react-query';
import { getPatientMessages } from 'api/Patients';
import { useNavigate } from 'react-router-dom';

export const usePatientMessages = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(
    ['patientMessages', ...dependencies],
    () => getPatientMessages(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
