import React from 'react';

export default function List({ items, renderItemComponent, headerComponent, dividerType }) {
  const divider = () => {
    switch (dividerType) {
      case 'full':
        return <div className="border !border-b !border-t-0 !border-neutral-100"></div>;
      case 'none':
        return <div className=""></div>;
      default:
        return <div className="mx-[20px] border !border-b !border-t-0 !border-neutral-100"></div>;
    }
  };

  return (
    <>
      {headerComponent && headerComponent()}
      {items?.map((item, index) => (
        <React.Fragment key={index}>
          {renderItemComponent(item, index)}
          {index !== items.length - 1 && divider()}
        </React.Fragment>
      ))}
    </>
  );
}
