import React, { useRef } from 'react';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import Textarea from 'components/shared/Textarea/Textarea';

const Note = ({ className }) => {
  const { values, setFieldValue } = useFormikContext();
  const noteRef = useRef();
  const innerHeight = noteRef?.current?.clientHeight;
  const isAptDisabled = values.appointment_disabled;

  return (
    <Textarea
      className={className}
      label="Appointment note"
      placeholder="Add any notes, problems or symptoms"
      value={values?.practice_note}
      onChange={(e) => setFieldValue('practice_note', e.target.value)}
      forwardedRef={noteRef}
      textareaClassName={cs('max-h-[90px]', innerHeight > 80 && '!overflow-auto')}
      disabled={isAptDisabled}
    />
  );
};

export default Note;
