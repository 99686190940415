import React from 'react';

const ClaimsSkeleton = () => {
  return (
    <>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <div className="h-5 w-5 animate-pulse rounded bg-neutral-200" />
          <div className="h-5 w-48 animate-pulse rounded bg-neutral-200" />
        </div>
        <div className="h-4 w-16 animate-pulse rounded bg-neutral-200" />
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <div className="h-5 w-5 animate-pulse rounded bg-neutral-200" />
          <div className="h-5 w-48 animate-pulse rounded bg-neutral-200" />
        </div>
        <div className="h-4 w-16 animate-pulse rounded bg-neutral-200" />
      </div>
      <div className="flex items-center justify-between gap-4">
        <div className="flex items-center gap-2">
          <div className="h-5 w-5 animate-pulse rounded bg-neutral-200" />
          <div className="h-5 w-48 animate-pulse rounded bg-neutral-200" />
        </div>
        <div className="h-4 w-16 animate-pulse rounded bg-neutral-200" />
      </div>
    </>
  );
};

export default ClaimsSkeleton;
