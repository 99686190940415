import React, { Fragment } from 'react';

import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';

import { TYPE_OF_NOTES } from '../../../constants';
import { Popover as ReactPopover, Transition } from '@headlessui/react';

const NotesPopover = ({ buttonContent, children, position = 'bottom', className = '' }) => {
  const positionClasses = {
    top: 'bottom-0 mb-2',
    bottom: 'top-full mt-2',
    left: 'right-full mr-2',
    right: 'left-full ml-2'
  };

  return (
    <ReactPopover className={`relative ${className}`}>
      <ReactPopover.Button className="flex items-center focus:outline-none">
        {buttonContent}
      </ReactPopover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <ReactPopover.Panel
          className={`absolute z-10 ${positionClasses[position]} w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg`}>
          {children}
        </ReactPopover.Panel>
      </Transition>
    </ReactPopover>
  );
};
export function getHeaderButtons(typeOfNotes, onSelectTypeOfNotes, filledForms) {
  return [
    { id: 1, component: <Icon icon="new-reset" />, tooltip: 'Reset selection' },
    { id: 2, component: <Icon icon="save-changes" />, tooltip: 'Reset selection' },
    {
      id: 3,
      component: (
        <NotesPopover
          buttonContent={<Icon icon="new-add-square" />}
          className="p-0"
          position="bottom"
          isFixed
          options={TYPE_OF_NOTES}
          icon="new-add-square">
          {typeOfNotes.map((option) => (
            <div className="flex justify-between" key={option.type}>
              <div>{option.label}</div>
              <Switch
                onChange={(e) => onSelectTypeOfNotes(e, option)}
                checked={!!filledForms?.[option.type]}
                data-qa="note-choose-switch"
              />
            </div>
          ))}
        </NotesPopover>
      ),
      tooltip: 'Reset selection'
    }
  ];
}
