import React, { useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import Moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from 'api/InterimApi';
import state from '../../state';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';

export default function Dashboard() {
  const [userState, setUserState] = useRecoilState(state.userState);
  const [latest_messages, setLatestMessage] = useState({
    subject: '',
    sender_name: '',
    date: new Date(),
    unread_messages: ''
  });
  const [latest_appointments, setLatestAppointments] = useState([]);
  const [latest_results, setLatestResults] = useState({
    result_name: '',
    practice_name: '',
    date_published: new Date()
  });
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
    getAppointments();
  }, []);

  const getData = async () => {
    try {
      let res = await interimApi('/api/user/dashboard', {}, navigate);
      const { code, redirect, latest_messages: loadedLatestMessage, user } = res.data;
      if (res.data.code === 0) {
        setLatestMessage({
          ...loadedLatestMessage
        });
        setUserState({
          ...userState,
          ...user,
          user_id: user?.id
        });
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getAppointments = async () => {
    try {
      const params = {
        approved: true,
        upcoming: true
      };
      const res = await interimApi('/api/patient/appointment/read', params, navigate);
      const { redirect, appointments: loadedAppointments } = res.data;
      if (res.data.appointments) {
        setLatestAppointments(loadedAppointments);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4 !p-4">
      <Box className="flex flex-col gap-4">
        <span className="text-xl font-500 text-neutral-900">Upcoming Appointments</span>
        {latest_appointments.length > 0 ? (
          <div className="flex flex-col gap-1">
            <span>
              {Moment.tz(
                latest_appointments[latest_appointments.length - 1].starts_at,
                latest_appointments[latest_appointments.length - 1].practice.timezone
              ).format('MMMM Do, hh:mm A z')}
            </span>
            <span className="mb-0">
              {latest_appointments[latest_appointments.length - 1].practice.name}
            </span>
            <span className="mb-0">
              {
                latest_appointments[latest_appointments.length - 1].practice.practiceAddress
                  .fullAddress
              }
            </span>
          </div>
        ) : (
          <p className="text-sm text-neutral-700">You don't have any upcoming appointments!</p>
        )}
        <Button
          className="mt-auto"
          text="See all appointments"
          onClick={() => navigate('/myportal/appointments')}
        />
      </Box>
      <Box className="flex flex-col gap-4">
        <span className="text-xl font-500 text-neutral-900">Messages</span>
        {latest_messages.number_unread > 0 ? (
          <div className="flex flex-col gap-2">
            <span>{`You have ${latest_messages.number_unread} new messages`}</span>
          </div>
        ) : (
          <p className="text-sm text-neutral-700">You don't have any new messages!</p>
        )}
        <Button
          className="mt-auto"
          text="See all messages"
          onClick={() => navigate('/myportal/inbox')}
        />
      </Box>
      <Box className="flex flex-col gap-4">
        <span className="text-xl font-500 text-neutral-900">Latest Results</span>
        {latest_results.number_unread > 0 ? (
          <div className="flex flex-col gap-2">
            <span>{latest_results.number_unread}</span>
            <span>You have new results</span>
          </div>
        ) : (
          <p className="text-sm text-neutral-700">
            There are no new results published at this time!
          </p>
        )}
        <Button
          className="mt-auto"
          text="See all results"
          onClick={() =>
            navigate(`/myportal/${latest_results.number_unread > 0 ? 'inbox' : 'medical-records'}`)
          }
        />
      </Box>
    </div>
  );
}
