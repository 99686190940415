import React, { useState } from 'react';
import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';

const DeferredPayment = () => {
  const [isDeferredPaymentOpen, setIsDeferredPaymentOpen] = useState(false);

  const handleDeferredPayment = () => setIsDeferredPaymentOpen(!isDeferredPaymentOpen);

  return (
    <div className="-mx-2 flex flex-col gap-2 rounded-lg border border-solid border-warning-200 bg-warning-100 p-2">
      <div className="flex select-none gap-[6px]">
        <div className="flex h-5 !w-5 flex-none items-center justify-center rounded-[3px] bg-white">
          <Icon
            size={14}
            shade={500}
            color="warning"
            icon="new-arrow-down"
            onClick={handleDeferredPayment}
            className={cs('cursor-pointer duration-150', !isDeferredPaymentOpen && 'rotate-180')}
          />
        </div>
        <div className="flex w-full items-start justify-between gap-1">
          <span className="cursor-pointer text-sm text-warning-600" onClick={handleDeferredPayment}>
            Patient deferred payment!
          </span>
          <Icon size={20} color="warning" icon="new-warning-v2" />
        </div>
      </div>

      {isDeferredPaymentOpen && (
        <span className="text-sm text-warning-900">
          This increases the risk of non-payment. To minimize billing costs and losses, use the AEOB
          to clarify costs, set expectations, and, whenever possible, secure payment or keep a card
          on file before starting treatment.
        </span>
      )}
    </div>
  );
};

export default DeferredPayment;
