import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { currentPractice } from '../../practiceState';
import ServiceEntryComponent from './ServiceEntryComponent';
import { ServiceValidationSchema, getFinalShapeServiceObj, initialValues } from './serivceHelpers';
import toast from 'react-hot-toast';

const NewService = forwardRef(({ onClose, service, filters }, ref) => {
  const navigate = useNavigate();
  const [show, setShow] = useState({ loading: false });
  const [practitioners, setPractitioners] = useState([]);
  const practice = useRecoilValue(currentPractice);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: initialValues(service),
    validationSchema: ServiceValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      const serviceToBeCreated = getFinalShapeServiceObj(values);

      setSubmitting(true);
      service ? await handleUpdate(serviceToBeCreated) : await createService(serviceToBeCreated);
      resetForm();
      setSubmitting(false);
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));
  const updatePaySchedule = (e) => {
    const paySchedule = formik.values.paySchedule;
    let updatedPaySchedule = Object.assign([], paySchedule);
    let payScheduleIndex = paySchedule.findIndex(
      (schedule) => schedule.service_id === e.service_id && schedule.payer_id === e.payer_id
    );
    if (payScheduleIndex === -1) {
      updatedPaySchedule.push(e);
    } else {
      updatedPaySchedule[payScheduleIndex] = { ...updatedPaySchedule[payScheduleIndex], ...e };
    }
    formik.setFieldValue('paySchedule', updatedPaySchedule);
  };

  useEffect(() => {
    getPractitioners();
  }, []);

  const createService = async (newService) => {
    var randomColor = Math.floor(Math.random() * 16777215).toString(16);
    randomColor = '#' + randomColor;

    const new_service = {
      ...newService,
      color: randomColor
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getServices', filters.searchTerm, filters.page, filters.limit],
        (prevData) => {
          return {
            ...prevData,
            services: [data.service, ...prevData?.services]
          };
        }
      );
      toast.success('Service created successfully');
      onClose();
    };

    requestApi({
      url: `/api/practice/services/create`,
      params: { service: new_service },
      onSuccess,
      navigate
    });
  };

  const handleUpdate = async (updatedService) => {
    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getServices', filters.searchTerm, filters.page, filters.limit],
        (prevData) => {
          return {
            ...prevData,
            services: prevData?.services.map((ser) =>
              ser.id === data.service.id ? data.service : ser
            )
          };
        }
      );
      toast.success('Service updated successfully');
      onClose();
    };

    requestApi({
      url: `/api/practice/services/update`,
      params: {
        service_id: service.id,
        fields: updatedService,
        paySchedule: formik.values.paySchedule
      },
      onSuccess,
      navigate
    });
  };

  const handleCPTSearch = async (searchTerm, codes = []) => {
    if (searchTerm) {
      try {
        const params = {
          searchTerm: searchTerm,
          limit: 100
        };
        let res = await interimApi('/api/codes/cpt/get', params, navigate);
        if (res?.data?.cpt) {
          return res.data.cpt.map((item) => {
            return {
              value: item.code,
              label: `${item.code} - ${item.name}`,
              description: item.name,
              par_amount: item.par_amount ? item.par_amount : 0
            };
          });
        } else {
          Honeybadger.notify(
            `file: /services/new_service, method: handleCPTSearch - else, error: An unexpected error has occurred.`
          );
        }
      } catch (error) {
        Honeybadger.notify(
          `file: /services/new_service, method: handleCPTSearch - catch, error: ${
            error ?? 'An unexpected error has occurred.'
          }`
        );
      }
    } else if (codes.length > 0) {
      return codes.map((item) => {
        return { value: item, label: item };
      });
    }
  };

  const selectCPT = (e) => {
    const parAmount = e.par_amount.toFixed(2);

    formik.setFieldValue('procedure_code', e.value);
    formik.setFieldValue('description', e.description || formik.values.description);
    formik.setFieldValue('total_cost_cents', parAmount);
    formik.setFieldValue(
      'standard_charge',
      parseFloat(
        parAmount *
          (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage / 100 || 1)
      ).toFixed()
    );
  };

  const getPractitioners = async () => {
    try {
      const {
        data: { members }
      } = await interimApi('/api/practice/member/get', {}, navigate);
      if (members) {
        setPractitioners(
          members.map((practitioner) => ({
            value: practitioner.user_id,
            label: `${practitioner.f_name} ${practitioner.l_name}`
          }))
        );
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /services/new_service, method: getPractitioners - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  return (
    <div>
      <ServiceEntryComponent
        handleCPTSearch={handleCPTSearch}
        selectCPT={selectCPT}
        practitioners={practitioners}
        formik={formik}
        updatePaySchedule={updatePaySchedule}
      />
      {show.loading && <div>Loading...</div>}
    </div>
  );
});

export default NewService;
