import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';

import Switch from 'components/shared/Switch/Switch';
import {
  useEnhanceNoteContextData,
  useEnhanceNoteContextApi
} from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { getHeaderButtons } from '../../lib/helpers';
import List from './List';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

const EnhanceNoteSidebar = ({ className }) => {
  const [typeOfNotes, setTypeOfNotes] = useState([]);
  const { filledForms = {} } = useEnhanceNoteContextData();
  const { setFilledForms } = useEnhanceNoteContextApi();

  const { typeOfNotes: ctxTypeOfNotes } = useMyScribeAIContext();

  useEffect(() => {
    setTypeOfNotes(Object.values(ctxTypeOfNotes));
  }, []);

  const selectedTypeOfNotes = Object.values(filledForms)?.flat();

  const onSelectTypeOfNotes = (e, note) => {
    setTypeOfNotes((prev) =>
      prev.map((item) => ({
        ...note,
        ...item,
        checked: note?.type === item.type ? e : item?.checked
      }))
    );
  };

  const headerButtons = getHeaderButtons(typeOfNotes, onSelectTypeOfNotes, filledForms);

  const onSelectForms = (checked, item, type) => {
    setFilledForms((prevFilledForms = {}) => {
      return {
        ...prevFilledForms,
        [type]: {
          ...prevFilledForms?.[type],
          components: {
            ...prevFilledForms?.[type]?.components,
            [item.path]: {
              ...prevFilledForms?.[type]?.components?.[item?.path],
              selected: checked
            }
          }
        }
      };
    });
  };

  const getOptions = (selectedNote) => {
    return Object.values(ctxTypeOfNotes?.[selectedNote.type]?.components).map((value) => ({
      label: value?.title,
      component: (
        <Switch
          onChange={(e) => onSelectForms(e, value, selectedNote.type)}
          checked={!!filledForms?.[selectedNote.type]?.components?.[value?.path]?.selected}
          data-qa="note-choose-switch"
        />
      )
    }));
  };

  return (
    <div
      className={cs(
        'fixed  z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        className,
        'w-[264px]'
      )}>
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              <Tippy content={button.tooltip} placement="top" className="tippy-dark">
                {button.component}
              </Tippy>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[calc(100vh-3rem)] overflow-y-auto pb-28">
        {selectedTypeOfNotes?.map((selectedNote) => (
          <div key={selectedNote.type}>
            <div className="flex flex-row items-center justify-between gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 ">
              <div className="font-500 text-primary-600 transition-all">{selectedNote?.label}</div>
              <Popover
                iconClassName="mr-0"
                panelClassName="max-h-[300px] overflow-y-auto"
                icon="new-add-square"
                options={getOptions(selectedNote)}
                buttonClassName="text-centered"
                isFixed
              />
            </div>
            <List type={selectedNote.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnhanceNoteSidebar;
