import React from 'react';

import { pluralize } from 'lib/helpers/utility';
import useModal from 'lib/hooks/useModal';

import Button from 'components/shared/Buttons/Button';
import Allowed from 'components/shared/Permissions/Allowed';

import InvoiceStepModal from './InvoiceStepModal';

const ButtonActions = ({ selectedRows }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const rows = selectedRows.map((row) => row.data);

  return (
    <>
      <Allowed requiredPermissions="invoicing.create">
        <Button
          disabled={!selectedRows?.length}
          size="small"
          data-qa="send-invoice-btn"
          icon="file-invoice"
          onClick={openModal}
          iconColor="white"
          text={`Invoice ${pluralize('Patient', rows?.length)} ( ${rows.length} )`}
        />
      </Allowed>
      {isOpen && <InvoiceStepModal isOpen={isOpen} handleClose={closeModal} selectedRows={rows} />}
    </>
  );
};

export default ButtonActions;
