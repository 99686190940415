import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import Transcript from '../../../CurrentNote/TypeNotes/MyScribeAI/components/Transcript';
import Breadcrumbs from '../../components/Breadcrumbs';

import RecordingView from './components/RecordingView';
import TranscriptionLoading from './components/TranscriptionLoading';
import TranscriptionView from './components/TranscriptionView';

const AmbientListening = () => {
  const { appointmentId } = useParams();
  const { breadcrumb } = useMyScribeAIContext();
  const { showTranscriptModal, loading, transcriptData, setShowTranscriptModal } =
    useAmbientListeningContext();
  const { breadcrumbs, popBreadcrumb } = breadcrumb;
  const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);

  if (showTranscriptModal && !loading) {
    const onShowUnsavedModal = () => {
      if (transcriptData.some((transcript) => !transcript?.id)) {
        setUnsavedConfirmation(true);
        return;
      }
      onContinueWithoutSaving();
    };

    const onContinueWithoutSaving = () => {
      popBreadcrumb();
      setUnsavedConfirmation(false);
      setShowTranscriptModal(false);
    };

    return (
      <>
        <div className="relative border-b border-gray-300 p-2 shadow-sm">
          <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onShowUnsavedModal} />
        </div>

        <div className="h-full overflow-auto">
          <TranscriptionView onGoBack={onShowUnsavedModal} />
        </div>
        <Confirm
          handleOpen={unsavedConfirmation}
          handleClose={() => setUnsavedConfirmation(false)}
          handleContinue={onContinueWithoutSaving}
          title="Unsaved changes"
          message="You have unsaved Transcription. Do you want to close this"
          primaryBtnTxt="Yes"
          secondaryBtnTxt="No"
          icon="new-warning-red"
          variant="danger"
        />
      </>
    );
  }

  return (
    <div className="flex h-screen w-full">
      <div className="relative flex h-full w-full overflow-hidden bg-primary-900 pt-5 shadow-2xl">
        <Icon icon="ai-bg-pattern" className="absolute left-0 top-0" />
        <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />
        <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90" />
        <div className="flex w-full flex-col items-center justify-center overflow-auto">
          <div className="z-50 flex w-full flex-col items-center justify-center gap-10">
            {!showTranscriptModal ? <RecordingView /> : <TranscriptionLoading />}
            <Tippy content="Coming Soon.." className="tippy-dark">
              <div className={cs('z-50 mb-[100px] flex', showTranscriptModal && 'hidden')}>
                <Transcript
                  name="Start Recording"
                  className="z-50 cursor-pointer bg-white !px-[15px] !py-3 text-primary-600"
                  customId={appointmentId}
                  disabled={true}
                />
              </div>
            </Tippy>
            <Icon icon="hippa-secure" className="absolute bottom-[80px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmbientListening;
