import React from 'react';
import moment from 'moment';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const Days = ({ currentMonth, selectedDate, onDateClickHandle }) => {
  const practice = useRecoilValue(currentPractice);
  const startDate = moment.tz(currentMonth, practice?.timezone).startOf('week');
  const endDate = moment.tz(currentMonth, practice?.timezone).endOf('week');
  const dateFormat = 'D';
  const dayFormat = 'ddd';
  const rows = [];
  let days = [];
  let day = startDate.clone();
  let formattedDate = '';

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = day.format(dateFormat);
      const dayStr = day.format('ddd DD MMM YY');
      const cloneDay = day.clone();
      const currentDay = day.isSame(new Date(), 'day');
      const selectedDay = day.isSame(selectedDate, 'day');
      days.push(
        <div
          className={cs(
            'flex w-[60px] min-w-[44px] cursor-pointer select-none flex-col items-center gap-2 !rounded-lg px-1 py-1 !pt-1 pb-[2px] hover:bg-primary-100 sm:min-w-fit md:min-w-fit',
            currentDay ? 'border border-solid !border-primary-500' : 'border-transparent',
            selectedDay && '!bg-primary-500'
          )}
          key={day}
          onClick={() => {
            onDateClickHandle(cloneDay, dayStr);
          }}>
          <span
            className={cs(
              'text-sm font-500 leading-[22px]',
              selectedDay ? 'text-white' : currentDay ? 'text-primary-500' : 'text-neutral-600'
            )}>
            {day.format(dayFormat)}
          </span>
          <span
            className={cs(
              'text-lg font-600 leading-[26px]',
              selectedDay ? 'text-white' : currentDay ? 'text-primary-500' : 'text-primary-900'
            )}>
            {formattedDate}
          </span>
        </div>
      );
      day.add(1, 'day');
    }

    rows.push(
      <div className="flex w-full justify-evenly gap-[6px] sm:gap-[2px]" key={day}>
        {days}
      </div>
    );
    days = [];
  }

  return <div className="mx-auto flex w-[90%] justify-center">{rows}</div>;
};

export default Days;
