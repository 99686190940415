import React, { useState } from 'react';

import Allowed from 'components/shared/Permissions/Allowed';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';

import Appointments from './components/Appointments';
import Financials from './components/Financials';

const DashboardPanel = (props) => {
  const [selectedOption, setSelectedOption] = useState('appointments');
  const options = [
    { value: 'appointments', label: 'Appointments', icon: 'calendar-tick' },
    { value: 'financials', label: 'Financials', icon: 'chart' }
  ];

  const components = {
    appointments: Appointments,
    financials: Financials
  };

  const SelectedComponent = components[selectedOption];

  return (
    <div>
      <WidgetHeader
        selected={{ selectedOption, setSelectedOption }}
        options={options}
        hasPlus={false}
        {...props}
      />
      <Allowed
        requiredPermissions={
          selectedOption === 'appointments' ? 'appointments.read' : 'billing.read'
        }
        showMessage={true}
        showIllustration={true}
        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <div className="!p-5">
          <SelectedComponent {...props} />
        </div>
      </Allowed>
    </div>
  );
};

export default DashboardPanel;
