import React, { useEffect, useState } from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { socket } from 'api/Socket';

import { useChatContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { iaRa } from 'lib/helpers/utility';

import {
  activePromptConversationsState,
  intelligenceChatIdState,
  myScribeAILoadingState
} from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import Icon from 'components/shared/Icon/Icon';

import AIPrompt from './AIPrompt';
import UserPrompt from './UserPrompt';

const AnimatingText = () => {
  const { selectedPromptNoteType } = useChatContextData();
  const [specialty, setSpecialty] = useState(null);
  const [dots, setDots] = useState('');
  const intelligenceChatId = useRecoilValue(intelligenceChatIdState);

  useEffect(() => {
    const handleSpecialty = (data) => {
      if (data?.specialty) {
        setSpecialty(data.specialty);
      }
    };

    socket.on('get-specialty', handleSpecialty);
    return () => socket.off('get-specialty', handleSpecialty);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + '.' : ''));
    }, 500);

    return () => clearInterval(interval);
  }, []);

  if (intelligenceChatId) {
    return (
      <div className="flex flex-col gap-3 w-full rounded-lg">
        <div className="text-base font-600 text-neutral-800">
          MyScribe AI (Beta){' '}
          <span className="font-400">
            is responding as a {specialty ? specialty?.toLowerCase() : 'medical'} documentation
            specialist
          </span>{' '}
          <span className="w-5 text-base font-400">{dots}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 w-full rounded-lg">
      <div className="text-base font-600 text-neutral-800">
        MyScribe AI (Beta){' '}
        <span className="font-400">
          is generating your {selectedPromptNoteType?.type?.toUpperCase()} note as a{' '}
          {specialty ? specialty?.toLowerCase() : 'medical'} scribe
        </span>{' '}
        <span className="w-5 text-base font-400">{dots}</span>
      </div>
    </div>
  );
};

const PromptMessages = ({ scrollContainerRef }) => {
  const activePromptConversations = useRecoilValue(activePromptConversationsState);
  const loading = useRecoilValue(myScribeAILoadingState);

  return (
    <div
      className={cs('mx-auto max-w-[768px] overflow-y-hidden pt-4 flex flex-col gap-5 pb-[50px]')}>
      {iaRa(activePromptConversations)?.map((prompt, idx) => {
        if (prompt?.agent === 'user') {
          return (
            <>
              <UserPrompt key={prompt?.id} message={prompt?.message} tags={prompt?.tags} />
              {loading && idx === activePromptConversations?.length - 1 && (
                <div className="flex flex-col gap-2 pb-10">
                  <div className="flex gap-2 items-center">
                    <div
                      className="relative flex items-center justify-center"
                      style={{
                        width: 38,
                        height: 38,
                        minWidth: 38,
                        minHeight: 38
                      }}>
                      <div
                        className="absolute animate-spin rounded-full"
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          background:
                            'conic-gradient(rgba(51, 201, 255, 1), rgba(51, 201, 255, 0.1)'
                        }}></div>

                      <div className="bg-white w-[32px] h-[32px] rounded-full z-20 flex items-center justify-center">
                        <Icon icon="mysc-ai-triple-stars" className="z-50" />
                      </div>
                    </div>

                    <AnimatingText />
                  </div>
                  <div className="mx-[40px] gap-3 flex flex-col">
                    {Array(5)
                      .fill()
                      .map((item) => (
                        <div className="h-full w-full flex flex-col gap-2" key={item}>
                          <div className="w-[111px] h-[12px] bg-primary-50 rounded-full animate-fadeIn-pulse" />
                          <div className="w-full h-[174px] bg-primary-50 rounded-md animate-fadeIn-pulse" />
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </>
          );
        } else if (prompt?.agent === 'ai') {
          return (
            <AIPrompt key={prompt?.id} prompt={prompt} scrollContainerRef={scrollContainerRef} />
          );
        }
        return null;
      })}
    </div>
  );
};

export default PromptMessages;
