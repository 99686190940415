import React from 'react';
import ReactPaginate from 'react-paginate';

import { formatLargeNumber } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';

const Pagination = ({
  onPageChange,
  totalItems,
  pageCountOverride,
  page,
  perPage = 15,
  ...props
}) => {
  const pageCount = Math.ceil(totalItems / perPage);

  return (
    pageCount > 1 && (
      <ReactPaginate
        onPageChange={onPageChange}
        forcePage={Number(page) - 1}
        containerClassName="flex !m-3"
        pageCount={pageCountOverride || pageCount}
        pageClassName="min-w-[24px] min-h-[24px] flex justify-center mx-0.5 rounded-[3px] hover:bg-neutral-100 hover:!text-white cursor-pointer leading-[24px] select-none"
        pageLinkClassName="w-full px-1 !text-[#8BA8BE] text-center"
        activeClassName="bg-primary-500 hover:bg-primary-500 rounded-[3px] !cursor-default"
        activeLinkClassName="!text-white"
        nextClassName="min-w-[24px] bg-neutral-100 hover:bg-neutral-200 rounded-[3px] ml-0.5 select-none cursor-pointer"
        nextLinkClassName="w-full h-full flex justify-center items-center cursor-pointer"
        nextLabel={<Icon icon="chevron-right" size={6} color="primary" shade={900} />}
        previousClassName="min-w-[24px] bg-neutral-100 hover:bg-neutral-200 rounded-[3px] mr-0.5 select-none cursor-pointer"
        previousLinkClassName="w-full h-full flex justify-center items-center cursor-pointer"
        previousLabel={<Icon icon="chevron-left" size={6} color="primary" shade={900} />}
        breakLinkClassName="!text-[#8BA8BE] rounded-full select-none"
        disabledClassName="bg-[#F6F6F6] opacity-30"
        disabledLinkClassName="bg-neutral-50 rounded-[3px] !cursor-default"
        pageLabelBuilder={(page) => formatLargeNumber(page)}
        {...props}
      />
    )
  );
};

export default Pagination;
