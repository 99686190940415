import React, { useState } from 'react';
import Modal from '../../../Modal/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { handleUpdatePatientTags } from 'components/practice/charts/lib/updatePatientTags';
import Reorder from './Reorder';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import Button from 'components/shared/Buttons/Button';

const ReorderWrapper = ({
  openReorderModal = false,
  handleClose = () => {},
  patientId = null,
  queryKeys = [],
  fromTable = false
}) => {
  const { importantTags, setImportantTags, regularTags, setRegularTags } = useTagContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const [updatedImportantTagLinks, setUpdatedImportantTagLinks] = useState([]);
  const [updatedRegularTagLinks, setUpdatedRegularTagLinks] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result, items, type) => {
    const { source, destination } = result;

    if (!destination) return;
    const tags = reorder(items, source.index, destination.index);
    let updatedTagLinks = [];

    if (fromTable) {
      updatedTagLinks = tags
        .map(({ id }, index) => {
          return {
            id,
            fields: {
              position: index + 1
            }
          };
        })
        .filter(Boolean);
      if (type === 'importantTags') {
        setUpdatedImportantTagLinks(updatedTagLinks);
      } else {
        setUpdatedRegularTagLinks(updatedTagLinks);
      }
    } else {
      updatedTagLinks = tags
        .map(({ id, tagLinks }, index) => {
          if (tagLinks[0]) {
            return {
              id,
              fields: {
                position: index + 1
              }
            };
          }
          return null;
        })
        .filter(Boolean);
      if (type === 'importantTags') {
        setUpdatedImportantTagLinks(updatedTagLinks);
      } else {
        setUpdatedRegularTagLinks(updatedTagLinks);
      }
    }

    if (type === 'importantTags') {
      setImportantTags(tags);
    } else {
      setRegularTags(tags);
    }
  };

  const handleSave = async () => {
    const combinedTags = [...updatedImportantTagLinks, ...updatedRegularTagLinks];
    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId: fromTable ? patientId : id,
      queryKeys,
      fromChart: !fromTable,
      mode: 'update',
      tags: combinedTags
    });
    handleClose();
  };

  const ModalHeader = () => {
    return (
      <div className="flex items-center gap-[6px]">
        <Icon icon="new-sort-reorder" />
        <h6 className="text-sm font-500 text-primary-900">Re-order Tags</h6>
      </div>
    );
  };

  return (
    <Modal
      isOpen={openReorderModal}
      customHeader={<ModalHeader />}
      handleClose={handleClose}
      className="!max-h-[400px]"
      bodyClassName="bg-primary-10"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Close" onClick={handleClose} color="neutral" outlined />
          <Button text="Save" onClick={handleSave} />
        </div>
      }>
      <Reorder importantTags={importantTags} regularTags={regularTags} onDragEnd={onDragEnd} />
    </Modal>
  );
};

export default withErrorBoundary(ReorderWrapper);
