import React from 'react';

import cs from 'classnames';

const Header = ({
  title,
  subtitle,
  onBadgeClick = null,
  children,
  className,
  showInfoInRecordings = false,
  titleClassName,
  childrenClassName
}) => (
  <div
    className={cs(
      'relative z-10 flex justify-between gap-4 border-0 !border-b !border-solid !border-neutral-100 bg-white !px-6',
      className
    )}
    data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
    <div
      className={cs(
        'flex items-center gap-1 !py-3 !pr-6',
        (title || subtitle) && 'shadow-[4px_0_16px_0_rgba(0,79,107,0.04)]'
      )}>
      {title && (
        <h3
          className={cs(
            'w-max text-lg font-500 leading-7 text-primary-800 capitalize',
            titleClassName
          )}>
          {title}
        </h3>
      )}
      {subtitle && (
        <div
          className={cs(
            'flex items-center justify-center rounded-full bg-primary-50 !py-1 px-[10px]',
            !!onBadgeClick && 'cursor-pointer'
          )}
          onClick={onBadgeClick}>
          <span className="w-max text-base font-500 leading-6 text-primary-600 capitalize">
            {subtitle}
          </span>
        </div>
      )}
    </div>
    <div className={cs('!py-[10px]', childrenClassName)}>{children}</div>
  </div>
);

export default Header;
