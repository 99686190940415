import { requestApi } from './Api';

export const getTags = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/tags/read',
    params
  });

export const createTag = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/tags/create',
    params
  });

export const deleteTag = async (navigate, id) =>
  await requestApi({
    navigate,
    url: '/api/tags/delete',
    params: { tagId: id }
  });
