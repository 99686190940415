import { Popover } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import SearchPatient from 'components/Search/SearchPatient/SearchPatient';
import Allowed from 'components/shared/Permissions/Allowed';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from '../../api/InterimApi';
import { NewAppointmentContextProvider } from '../../lib/context/Appointment/NewAppointmentContextProvider';
import { reShapePractitioners } from '../../lib/helpers/utility';
import { useNotifications } from '../../lib/hooks/queries/notifications/useNotifications';
import useScrollBlock from '../../lib/hooks/useScrollBlock';
import QuoteModal from '../Patient/quote/QuoteModal';
import SellPackageProductModal from '../Patient/sell/SellPackageProductModal';
import NewAppointment from '../practice/appointment/NewAppointment/NewAppointment';
import NewPatient from '../practice/charts/PatientCharts/components/NewPatient';
import { currentPractice, reloadCalendar } from '../practice/practiceState';
import ActionButton from '../shared/Button/ActionButton/ActionButton';
import Icon from '../shared/Icon/Icon';
import { permissions as userPermissions } from '../state';
import NewMessages from './NewMessages';
import NewNotifications from './NewNotifications';
import UserActivity from './UserActivity';
import { notificationState } from './notificationState';
import NewTask from 'components/practice/PracticeIQueue/Tasks/components/NewTask';
import KioskActions from 'components/Navbar/KioskActions';

const QuickActions = ({ className }) => {
  const navigate = useNavigate();

  const [patientModal, setPatientModal] = useState(false);
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [reloadAppointment, setReloadAppointment] = useRecoilState(reloadCalendar);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [unreadMessagesCount, setUnreadMessagesCount] = useRecoilState(notificationState);
  const { messageUnreadCount } = unreadMessagesCount;
  const hideNewPatientModal = () => setPatientModal(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const { device } = useUIContext();
  const practice = useRecoilValue(currentPractice);
  const [newTaskModal, setNewTaskModal] = useState(false);

  useEffect(() => {
    getMessagesCount();
  }, []);

  const { packages, products } = practice.display_settings;

  const showNewAppointmentModal = () => {
    blockScroll();
    setNewAppointmentModal(true);
    getPractitioners();
  };

  const hideNewAppointmentModal = () => {
    allowScroll();
    setNewAppointmentModal(false);
  };

  const getPractitioners = async () => {
    try {
      const res = await interimApi('/api/practice/member/get_practitioners', {}, navigate);
      const { redirect, practitioners: loadedPractitioners } = res.data;
      if (res.data.practitioners) {
        setPractitioners(loadedPractitioners);
      } else {
        navigate(redirect);
      }
    } catch (error) {
      console.error('error', error);
      Honeybadger.notify(
        `file: Navbar/QuickActions, method: getPractitioners - catch, setServiceserror: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getMessagesCount = async () => {
    try {
      const res = await interimApi(
        '/api/practice/messages/get_multiple',
        {
          withal: { messageCount: true }
        },
        navigate
      );
      const { messageCount } = res.data;
      if (messageCount) {
        setUnreadMessagesCount((prevState) => ({ ...prevState, messageUnreadCount: messageCount }));
      }
    } catch (error) {
      Honeybadger.notify(
        `file: Navbar/QuickActions, method: getMessagesCount - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const showNewTaskModal = () => {
    setNewTaskModal(true);
  };
  const hideNewTaskModal = () => {
    setNewTaskModal(false);
  };

  const { data: notificationsUnreadCount } = useNotifications({
    queryKey: 'unreadNotificationsCount',
    params: { userPracticeNotifs: true, withal: { count: true } },
    options: {
      select: ({ data }) => data.unreadNotificationCount
    }
  });

  return (
    <div className={cs('flex items-center', className)}>
      {device !== 'mobile' && <SearchPatient />}
      <Popover className="relative !ml-1 !mr-2">
        {({ open, close }) => (
          <>
            <Popover.Button data-qa="quick-actions-btn" className="p-0">
              <ActionButton icon="new-actions" active={open} />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-10 mt-[10px] w-max !rounded-lg bg-white shadow-medium">
              <div className="grid">
                <Allowed requiredPermissions="patients.create">
                  <div
                    className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                    data-qa="quick-actions-new-patient"
                    onClick={() => {
                      close();
                      setPatientModal(true);
                    }}>
                    <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                      <Icon icon="new-patient-add" />
                    </div>
                    <div className="grid">
                      <span className="text-sm font-500 text-primary-900">Patient</span>
                      <span className="text-xs text-neutral-600">New Patient</span>
                    </div>
                  </div>
                </Allowed>
                <div className="!px-2">
                  <div className="h-[1px] w-full bg-neutral-100"></div>
                </div>
                <Allowed requiredPermissions={['appointments.create', 'practice_events.create']}>
                  <div
                    data-qa="quick-actions-new-appointment"
                    className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                    onClick={() => {
                      close();
                      showNewAppointmentModal();
                    }}>
                    <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                      <Icon icon="new-calendar" />
                    </div>
                    <div className="grid">
                      <span className="text-sm font-500 text-primary-900">Appointment</span>
                      <span className="text-xs text-neutral-600">New appointment</span>
                    </div>
                  </div>
                </Allowed>
                <div className="!px-2">
                  <div className="h-[1px] w-full bg-neutral-100"></div>
                </div>
                <Allowed requiredPermissions={['products.read', 'packages.read']} isAny={true}>
                  {packages || products ? (
                    <>
                      <div
                        data-qa="quick-actions-sell-package-product"
                        className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                        onClick={() => {
                          close();
                          setSellModalVisible(true);
                        }}>
                        <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                          <Icon icon="payment" />
                        </div>
                        <div className="grid">
                          <span className="text-sm font-500 text-primary-900">Sell</span>
                          <span className="text-xs text-neutral-600">Package/Product</span>
                        </div>
                      </div>

                      <div className="!px-2">
                        <div className="h-[1px] w-full bg-neutral-100"></div>
                      </div>
                    </>
                  ) : null}
                </Allowed>

                <Allowed
                  requiredPermissions={('products.read', 'packages.read', 'services.read')}
                  isAny>
                  <>
                    <div
                      data-qa="quick-actions-quote"
                      className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                      onClick={() => {
                        close();
                        setShowQuoteModal(true);
                      }}>
                      <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                        <Icon icon="payment" />
                      </div>
                      <div className="grid">
                        <span className="text-sm font-500 text-primary-900">Quote</span>
                        <span className="text-xs text-neutral-600">New Quote</span>
                      </div>
                    </div>
                    <div className="!px-2">
                      <div className="h-[1px] w-full bg-neutral-100"></div>
                    </div>
                  </>
                </Allowed>
                <Allowed requiredPermissions="tasks.create">
                  <div
                    data-qa="quick-actions-new-appointment"
                    className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                    onClick={() => {
                      close();
                      showNewTaskModal();
                    }}>
                    <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                      <Icon icon="new-task" size={24} color="primary" shade={600} />
                    </div>
                    <div className="grid">
                      <span className="text-sm font-500 text-primary-900">Task</span>
                      <span className="text-xs text-neutral-600">New Task</span>
                    </div>
                  </div>
                </Allowed>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      <div className="!mr-2 h-[30px]  w-[1px] rounded-sm bg-neutral-200"></div>

      <Allowed requiredPermissions="kiosk.read">
        <KioskActions />
      </Allowed>

      <NewMessages
        unreadMessagesCount={`${messageUnreadCount > 99 ? '99+' : messageUnreadCount}`}
      />

      <NewNotifications unreadNotificationsCount={notificationsUnreadCount} />

      {patientModal ? (
        <NewPatient newPatientModal={patientModal} hideNewPatientModal={hideNewPatientModal} />
      ) : null}

      {sellModalVisible ? (
        <SellPackageProductModal {...{ sellModalVisible, setSellModalVisible }} />
      ) : null}

      {showQuoteModal && <QuoteModal {...{ showQuoteModal, setShowQuoteModal }} />}

      <NewAppointmentContextProvider>
        <NewAppointment
          isOpen={newAppointmentModal}
          hideNewAppointment={hideNewAppointmentModal}
          practitioners={reShapePractitioners(practitioners)}
          getAppointment={() => setReloadAppointment(true)}
        />
      </NewAppointmentContextProvider>

      {newTaskModal && (
        <NewTask
          newTaskModal={newTaskModal}
          hideNewTaskModal={hideNewTaskModal}
          buttonText="Create a new task"
        />
      )}
      <UserActivity />
    </div>
  );
};

export default QuickActions;
