import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTask } from 'api/Tasks';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Modal from 'components/shared/Modal/Modal';
import { userState } from 'components/state';
import { useFormik } from 'formik';
import { iaRa } from 'lib/helpers/utility';
import { useMember } from 'lib/hooks/queries/useMember';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { TaskValidationSchema, shapeTaskValues, taskInitialValues } from '../lib/helpers';
import TaskForm from './TaskForm';

const NewTask = ({ newTaskModal, hideNewTaskModal }) => {
  const [selectedRequester, setSelectedRequester] = useState();
  const currentUser = useRecoilValue(userState);
  const { data: membersList } = useMember({});
  const practice = useRecoilValue(currentPractice);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const members = iaRa(membersList?.members).map((member) => ({
    value: member.user_id,
    label: `${member.f_name} ${member.l_name}`
  }));

  useEffect(() => {
    if (currentUser) {
      setSelectedRequester({
        label: `${currentUser?.f_name} ${currentUser?.l_name}`,
        value: currentUser.id
      });
      formik.setFieldValue('users.requester', currentUser.id);
    }
  }, [currentUser]);

  const { mutateAsync: mutateCreateTaskForm, isLoading } = useMutation({
    mutationFn: (data) => createTask(navigate, data)
  });

  const formik = useFormik({
    initialValues: taskInitialValues(),
    validationSchema: TaskValidationSchema,
    onSubmit: async (values) => {
      const shapedTaskObject = shapeTaskValues(values);

      await mutateCreateTaskForm(shapedTaskObject, {
        onSuccess: ({ code, task, error }) => {
          if (code === 0) {
            queryClient.invalidateQueries(['tasks']);
            queryClient.refetchQueries(['tasks-widget']);
            showAlert({
              title: 'Task created successfully',
              color: 'success',
              buttons: [
                {
                  text: 'View',
                  onClick: () => navigate(`/portal/practice-i-queue/tasks/${task?.id}`),
                  color: 'success'
                }
              ]
            });
            hideNewTaskModal();
          } else {
            showAlert({
              title: 'Task creation failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
        }
      });
    }
  });

  const handleCreate = () => formik.handleSubmit();
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Modal
        slideFromRight
        handleOpen={newTaskModal}
        handleClose={hideNewTaskModal}
        title="Create New Task"
        customStyling={{ maxWidth: '800px', minWidth: '800px' }}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              transparent
              onClick={hideNewTaskModal}
              text="Cancel"
              color="neutral"
              outlined
              data-qa="cancel-btn"
            />
            <Button
              onClick={handleCreate}
              loading={isLoading}
              text="Create task"
              data-qa="create-update-btn"
            />
          </div>
        }>
        <TaskForm
          formik={formik}
          members={members}
          user={currentUser}
          selectedRequester={selectedRequester}
          setSelectedRequester={setSelectedRequester}
          practice={practice}
        />
      </Modal>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(NewTask);
