import React from 'react';
import Box from '../../../../shared/Box';
import Select from '../../../../../../../shared/Select/Select';
import Icon from '../../../../../../../shared/Icon/Icon';
import { usePractitioners } from '../../../../../../../../lib/hooks/queries/practitioners/usePractitioners';
import { io, reShapePractitioners } from '../../../../../../../../lib/helpers/utility';
import { treatmentPlanProviderMethod } from '../../../../../../../../constants';

const TreatmentRow = ({ provider, method, index, length, onChange, onDelete }) => {
  const { data, isLoading } = usePractitioners();

  return (
    <div className="flex items-center gap-4">
      <Box className="grow">
        <div className="grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4">
          <Select
            label="Provider"
            placeholder="Type and select provider"
            isImage
            value={provider}
            options={reShapePractitioners(data?.practitioners)}
            onChange={(event) => onChange(event, 'provider', index)}
            isLoading={isLoading}
            inputId="treatment-plan-provider"
            required
            error={provider === null ? 'Required field' : false}
          />
          <Select
            label="Method"
            placeholder="Type and select Method"
            value={method}
            options={treatmentPlanProviderMethod}
            onChange={(event) => onChange(event, 'method', index)}
            inputId="treatment-plan-method"
            isCreatable
          />
        </div>
      </Box>
      <Icon
        icon="trash"
        onClick={() => onDelete(index)}
        disabled={length <= 1}
        data-qa="delete-treatment-plan"
      />
    </div>
  );
};

export default TreatmentRow;
