import { capitalize, isUndefined } from 'lodash';
import React from 'react';
import { withErrorBoundary } from '../../../../../../shared/Error/Boundary';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend);

const getChartData = (chart) => {
  return {
    backgroundColor: chart.map((c) => c.color),
    data: chart.map((c) => c.angle),
    borderWidth: 0
  };
};

const radialChartOptions = {
  plugins: {
    legend: {
      display: false
    },
    datalabels: {
      display: false
    },
    tooltip: {
      enabled: false
    }
  },
  responsive: true,
  cutout: 35,
  radius: 40
};

const BenefitItemChart = ({ benefitArray }) => {
  benefitArray = benefitArray.map((item) => ({
    ...item,
    benefit_amount: Number(item.benefit_amount || 0)
  }));
  const benefitNotes = benefitArray.find((item) => !!item.benefit_notes)?.benefit_notes;

  const calendarYearBenefit = benefitArray.find(
    (item) => item.benefit_period_description === 'Calendar Year'
  );

  const calendarYearBenefitAmount = calendarYearBenefit?.benefit_amount;

  const yearToDateBenefit = benefitArray.find(
    (item) => item.benefit_period_description === 'Year to Date'
  );

  function returnChartValue(index) {
    let value =
      benefitArray.length === 1
        ? calendarYearBenefitAmount
        : benefitArray[index + 1]
        ? calendarYearBenefitAmount /
          (calendarYearBenefitAmount - benefitArray[index + 1]?.benefit_amount)
        : calendarYearBenefitAmount /
          (calendarYearBenefitAmount - benefitArray[index - 1]?.benefit_amount);
    return value === Infinity ? 0 : value;
  }

  const chartData = yearToDateBenefit
    ? benefitArray
        .filter((item) => item.benefit_period_description !== 'Calendar Year')
        .map((item, index) => ({
          angle: calendarYearBenefitAmount / item.benefit_amount,
          color: index === 0 ? '#13B9FF' : '#D9D9D9'
        }))
    : benefitArray.map((item, index) => ({
        angle:
          item.benefit_period_description == 'Calendar Year'
            ? returnChartValue(index)
            : item?.benefit_amount / calendarYearBenefitAmount,
        color: index === 0 ? '#13B9FF' : '#D9D9D9'
      }));

  return (
    <div className="mb-3 ">
      {benefitNotes ? (
        <p className=" text-sm !font-400 text-primary-900 ">
          {capitalize(benefitNotes.toLowerCase())}
        </p>
      ) : null}
      <p className="mt-[10px] !mb-2 block text-sm !font-400 text-primary-900">
        <span className="mr-2 inline !font-600">
          {calendarYearBenefit?.insurance_plan ? (
            <span className="mr-2 inline-block underline">
              {calendarYearBenefit?.insurance_plan}
            </span>
          ) : null}
          {(calendarYearBenefit.insurance_type_description || '') +
            ' ' +
            calendarYearBenefit.benefit_coverage_description}
        </span>
        {calendarYearBenefit.hasOwnProperty('benefit_amount') &&
        !calendarYearBenefit.hasOwnProperty('benefit_percent')
          ? '$'
          : null}
        {isUndefined(calendarYearBenefit.benefit_percent)
          ? calendarYearBenefit.benefit_amount
          : calendarYearBenefit.benefit_percent}
        {calendarYearBenefit.hasOwnProperty('benefit_percent') ? '%' : null}{' '}
        {calendarYearBenefit.benefit_period_description}
      </p>
      <div className="flex items-center">
        <div className="w-[100px]">
          <Doughnut
            style={{ width: 40, height: 40 }}
            options={radialChartOptions}
            data={{
              datasets: [
                getChartData(
                  Number(calendarYearBenefitAmount) ? chartData : [{ angle: 1, color: '#D9D9D9' }]
                )
              ]
            }}
          />
        </div>
        <div className="">
          {benefitArray
            .filter((item) => item.benefit_period_description !== 'Calendar Year')
            .map((finalItem, index) => (
              <div className="mb-[10px]" key={index}>
                <div className="flex items-center">
                  <span
                    className="mr-3 h-4 w-4 rounded-xl"
                    style={{
                      backgroundColor: !yearToDateBenefit
                        ? '#13B9FF'
                        : index === 0
                        ? '#D9D9D9'
                        : '#13B9FF'
                    }}>
                    {' '}
                  </span>
                  <p className=" block text-sm !font-400 text-primary-900">
                    <span className="mr-2 inline !font-600">
                      {(finalItem.insurance_type_description || '') +
                        ' ' +
                        finalItem.benefit_coverage_description}
                    </span>
                    {calendarYearBenefit.hasOwnProperty('benefit_amount') &&
                    !calendarYearBenefit.hasOwnProperty('benefit_percent')
                      ? '$'
                      : null}
                    {finalItem.benefit_amount || finalItem.benefit_percent}
                    {finalItem.hasOwnProperty('benefit_percent') ? '%' : null}{' '}
                    {finalItem.benefit_period_description}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(BenefitItemChart);
