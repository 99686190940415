import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';
import ColorPicker from 'components/shared/ColorPicker/ColorPicker';
import ImageUploader from 'components/shared/ImageUploader/ImageUploader';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Typography from 'components/shared/Typography/Typography';

import { handleCPTSearch } from '../../lib/helpers';
import Wrapper from '../Wrapper/Wrapper';

const MainSection = ({ formik, service }) => {
  const navigate = useNavigate();
  const practice = useRecoilValue(currentPractice);

  const selectCPT = (event) => {
    const parAmount = event.par_amount.toFixed(2);

    formik.setFieldValue('procedure_code', event.value);
    formik.setFieldValue(
      'description',
      event.description || event.meta?.name || formik.values.description
    );
    formik.setFieldValue('total_cost_cents', parAmount);
    formik.setFieldValue(
      'standard_charge',
      parseFloat(
        parAmount *
          (practice?.display_settings?.billing?.feeSchedule?.medicarePercentage / 100 || 1)
      ).toFixed()
    );
  };

  return (
    <Wrapper isColumn>
      <div className="grid grid-cols-2 gap-4">
        <div className="flex flex-col gap-3 flex-1">
          <div className="flex gap-3 items-start">
            <Input
              label="Name"
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name}
              className="flex flex-1 flex-col"
              id="serviceNameInput"
              data-qa="name-input"
              required
            />
            <ColorPicker
              color={formik.values.color}
              onChange={(color) => formik.setFieldValue('color', color)}
            />
          </div>
          <Select
            label="Procedure Code"
            value={{ value: formik.values?.procedure_code, label: formik.values?.procedure_code }}
            isAsync
            parentClassName="flex flex-1 flex-col"
            placeholder="Start typing the ndc code or other categories."
            isClearable={false}
            noOptionMessage={() => 'Start typing the ndc code or other categories.'}
            onChange={selectCPT}
            loadOptions={(searchTerm) => handleCPTSearch(searchTerm, navigate)}
            disabled={formik.values?.procedure_code && !!service}
          />
        </div>
        <ImageUploader
          className="mt-1"
          handlers={[formik.values.image]}
          onUpload={(file) => {
            formik.setFieldValue('image', file.handle);
          }}
          type="image"
        />
      </div>
    </Wrapper>
  );
};

export default MainSection;
