import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../../../api/InterimApi';
import Icon from '../../../../shared/Icon/Icon';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';

const ReferringProvider = ({
  index,
  provider,
  handleEditMode,
  autofillCMS1500,
  setProviderList
}) => {
  const { id, npi, f_name, m_name, l_name, cond_date, address } = provider || {};
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const handleDelete = async () => {
    try {
      const { data } = await interimApi(
        '/api/referring_provider/delete',
        {
          referringProviderId: id
        },
        navigate
      );

      if (data?.deleted) {
        setShowConfirmationModal(false);
        setProviderList((prevState) => prevState.filter((p) => p?.id !== id));
        toast.success('Successfully deleted.');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <>
      <div key={index} className="grid-auto-row my-3 grid">
        <div className="relative rounded-lg border bg-primary-50 p-20">
          <div className="absolute right-4 top-4 flex gap-3 ">
            <div
              onClick={() => handleEditMode({ ...provider, autofillCMS1500 })}
              data-qa="edit-btn-npi">
              <Icon icon="edit" className="cursor-pointer" />
            </div>
            <div onClick={() => setShowConfirmationModal(true)} data-qa="delete-btn-npi">
              <Icon icon="trash" className="cursor-pointer" />
            </div>
          </div>

          <div>
            <p className="text-base font-semibold">NPI: &nbsp;</p>
            <p className="text-base">{npi}</p>
          </div>

          <div>
            <p className="text-base font-semibold">First Name: &nbsp;</p>
            <p className="text-base">{f_name}</p>
          </div>

          <div>
            <p className="text-base font-semibold">Middle Name: &nbsp;</p>
            <p className="text-base">{m_name}</p>
          </div>

          <div>
            <p className="text-base font-semibold">Last Name: &nbsp;</p>
            <p className="text-base">{l_name}</p>
          </div>

          {address && (
            <div>
              <p className="text-base font-semibold">Address: &nbsp;</p>
              <p className="text-base">{`${address?.address_1}, ${address?.city} ${address?.state} ${address?.postal_code}`}</p>
            </div>
          )}

          {cond_date && (
            <div>
              <p className="text-base font-semibold">Last date of service: &nbsp;</p>
              <p className="text-base">{moment(cond_date).format('M/D/YYYY ddd')}</p>
            </div>
          )}

          {cond_date && (
            <div>
              <p className="text-base font-semibold">Autofill CMS1500: &nbsp;</p>
              <p className="text-base">{autofillCMS1500 ? 'Yes' : 'No'}</p>
            </div>
          )}
        </div>
      </div>

      <Confirm
        variant="danger"
        primaryBtnTxt="Delete"
        secondaryBtnTxt="Cancel"
        handleContinue={handleDelete}
        title="Delete Referring Provider"
        handleOpen={showConfirmationModal}
        message="Are you sure you want to delete?"
        handleClose={() => setShowConfirmationModal(false)}
      />
    </>
  );
};

export default ReferringProvider;
