import ProviderView from '../ProviderView/ProviderView';
import ResourceView from '../ResourceView/ResourceView';

export default {
  day: true,
  week: true,
  month: true,
  agenda: true,
  provider: ProviderView,
  resource: ResourceView
};
