import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Select from 'components/shared/Select/Select';
import Typography from 'components/shared/Typography/Typography';

import handleNdcSearch from './lib/handleNdcSearch';
import { NDC_CODE_TYPES } from './lib/helpers';

const NdcCodeModal = ({ isOpen, initialCodes, type, onClose, onSave }) => {
  const [selectedNdcCodes, setSelectedNdcCodes] = useState(initialCodes);
  const [selectedType, setSelectedType] = useState(type);
  const navigate = useNavigate();

  const handleSave = () => {
    onSave(selectedNdcCodes, selectedType);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      disableHeader
      className="max-w-[480px]"
      bodyClassName="p-0 overflow-hidden"
      footer={
        <div className="flex justify-between gap-2 flex-1">
          <Button text="Cancel" onClick={onClose} outlined color="neutral" />
          <Button
            text="Add NDC Code"
            disabled={selectedNdcCodes.length === 0}
            onClick={handleSave}
          />
        </div>
      }>
      <div className="flex items-center justify-between gap-1.5 h-12 bg-white px-4 py-0">
        <div className="flex items-center gap-1.5">
          <Icon icon="new-search-basic" />
          <Typography variant="small" weight="font-medium" className="text-neutral-800">
            NDC Codes
          </Typography>
        </div>

        <Icon icon="new-close-v2" onClick={onClose} size={14} />
      </div>

      <div className="p-4 overflow-y-auto h-fit">
        <div className="flex flex-col gap-2">
          <Select
            label="Type"
            value={NDC_CODE_TYPES.find((item) => item?.value === (selectedType || 'package_ndc'))}
            isClearable={false}
            options={NDC_CODE_TYPES}
            menuPortalTarget={document.body}
            onChange={(event) => setSelectedType(event?.value)}
          />
          <Select
            label="NDC Code"
            value={selectedNdcCodes}
            isAsync
            cacheOptions
            placeholder="Start typing the ndc code or other categories."
            isClearable={false}
            noOptionMessage={() => 'Start typing the ndc code or other categories.'}
            onChange={(event) => {
              setSelectedNdcCodes(
                event.map((item) => {
                  return {
                    label: item?.value,
                    value: item?.value,
                    title: item?.title || item?.label
                  };
                })
              );
            }}
            loadOptions={(searchTerm) =>
              handleNdcSearch({ searchTerm, navigate, searchType: selectedType })
            }
            isMulti={true}
            menuPortalTarget={document.body}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NdcCodeModal;
