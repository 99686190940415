import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/shared/Input/Input';
import Textarea from 'components/shared/Textarea/Textarea';
import { useNavigate } from 'react-router-dom';
import { createResource, updateResource } from 'api/Resource';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { getUpdatedValues } from 'components/practice/BeyondBilling/Claims/CMS1500/lib';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Name should be at least 2 characters').required('Name is required.')
});

const ResourceForm = forwardRef(({ resource, setLoading, setResourcesModalVisible }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const formik = useFormik({
    initialValues: {
      name: resource?.name || '',
      description: resource?.description || ''
    },
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      resource ? handleUpdateResource(values) : handleCreateResource(values);
    }
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const { handleChange, values, touched, handleBlur, errors, initialValues } = formik;

  const handleUpdateResource = async (values) => {
    const updatedValues = getUpdatedValues(initialValues, values);
    if (Object.keys(updatedValues).length === 0) {
      setResourcesModalVisible(false);
      return;
    }

    const params = {
      id: resource.id,
      ...updatedValues
    };
    const { code } = await updateResource(navigate, params);

    if (code == 0) {
      showAlert({
        title: 'Success',
        message: 'Resource updated successfully',
        color: 'success'
      });
      setResourcesModalVisible(false);
      queryClient.invalidateQueries(['resources']);
    }
  };

  const handleCreateResource = async (values) => {
    const params = {
      name: values.name,
      description: values.description
    };
    const { code } = await createResource(navigate, params);

    if (code == 0) {
      showAlert({
        title: 'Success',
        message: 'Resource created successfully',
        color: 'success'
      });
      setResourcesModalVisible(false);
      queryClient.invalidateQueries(['resources']);
    }
  };

  return (
    <div className="flex flex-col !gap-4">
      <Input
        label="Resource name"
        name="name"
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched?.name && errors?.name}
        value={values.name}
        required
      />
      <Textarea
        maxLength={255}
        label="Resource description"
        placeholder="Enter resource description"
        name="description"
        onChange={handleChange}
        value={values.description}
        onBlur={handleBlur}
        textareaClassName="h-[150px]"
      />
    </div>
  );
});

export default ResourceForm;
