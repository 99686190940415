import React from 'react';

import Skeleton from 'components/shared/Skeleton/Skeleton';

import { ia } from '../../../../../../../lib/helpers/utility';

import ContactPreview from './components/ContactPreview';
import MedicationPreview from './components/MedicationPreview';
import NonERXPrescriptionAndFrequency from './components/NonERXPrescriptionAndFrequency';
import YourSignaturePreview from './components/YourSignaturePreview';

const NonERXPrescriptionPreview = ({ itemProps, loading }) => {
  return loading ? (
    <Skeleton count={10} />
  ) : (
    <div className="!mx-6">
      {!!itemProps?.provider && (
        <div className="!mt-6">
          <ContactPreview providerInfo={itemProps?.provider} />
        </div>
      )}

      {!!itemProps?.non_erx_prescription_and_frequency && (
        <div className="!mt-6">
          <NonERXPrescriptionAndFrequency itemProps={itemProps} />
        </div>
      )}

      {ia(itemProps?.medication_ids) && (
        <div className="!mt-6">
          <MedicationPreview itemProps={itemProps} />
        </div>
      )}

      <div className="!mt-6">
        <YourSignaturePreview itemProps={itemProps} />
      </div>
    </div>
  );
};

export default NonERXPrescriptionPreview;
