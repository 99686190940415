import React from 'react';

import Confirm from 'components/shared/Modal/Confirm/Confirm';

const DeleteTreatmentPlanConfirm = ({
  treatmentPlanToDelete,
  handleDeleteTreatmentPlan = () => {},
  handleClose = () => {},
  loading = false
}) => {
  return (
    <>
      <Confirm
        handleOpen={treatmentPlanToDelete}
        variant="danger"
        icon="trash"
        iconColor="danger"
        title="Delete Treatment Plan"
        loading={loading}
        disabled={loading}
        handleContinue={() => handleDeleteTreatmentPlan()}
        message="Are you sure you want to delete this treatment plan?"
        handleClose={() => handleClose()}
      />
    </>
  );
};
export default DeleteTreatmentPlanConfirm;
