export function generateSOAPList(cnDisplaySettings, advancedSoapTitles) {
  return {
    subjective: cnDisplaySettings?.sections?.soap?.subjective?.enabled && {
      title: 'Subjective',
      path: 'subjective',
      position: cnDisplaySettings?.sections?.soap?.subjective?.position
    },
    objective: cnDisplaySettings?.sections?.soap?.objective?.enabled && {
      title: 'Objective',
      path: 'objective',
      position: cnDisplaySettings?.sections?.soap?.objective?.position
    },
    assessment: cnDisplaySettings?.sections?.soap?.assessment?.enabled && {
      title: 'Assessment',
      path: 'assessment',
      position: cnDisplaySettings?.sections?.soap?.assessment?.position
    },
    plan: cnDisplaySettings?.sections?.soap?.plan?.enabled && {
      title: 'Plan',
      path: 'plan',
      position: cnDisplaySettings?.sections?.soap?.plan?.position
    },
    medications: cnDisplaySettings?.sections?.soap?.medications?.enabled && {
      title: 'Medications',
      path: 'medicationHistory',
      position: cnDisplaySettings?.sections?.soap?.medications?.position
    },
    vitals: cnDisplaySettings?.sections?.soap?.vitals?.enabled && {
      title: 'Vitals',
      path: 'vitals',
      position: cnDisplaySettings?.sections?.soap?.vitals?.position
    },
    ...advancedSoapTitles,
    cptCodes: cnDisplaySettings?.sections?.soap?.cptCodes?.enabled && {
      title: 'CPT® Codes',
      path: 'cpt-codes',
      position: cnDisplaySettings?.sections?.soap?.cptCodes?.position
    },
    diagnosisCodes: cnDisplaySettings?.sections?.soap?.diagnosisCodes?.enabled && {
      title: 'Diagnosis Codes',
      path: 'diagnosis-codes',
      position: cnDisplaySettings?.sections?.soap?.diagnosisCodes?.position
    },
    notes: cnDisplaySettings?.sections?.soap?.notes?.enabled && {
      title: 'General Notes',
      path: 'notes',
      position: cnDisplaySettings?.sections?.soap?.notes?.position
    },
    orders: cnDisplaySettings?.sections?.soap?.orders?.enabled && {
      title: 'Orders',
      path: 'orders',
      position: cnDisplaySettings?.sections?.soap?.orders?.position
    }
  };
}
