import { requestApi } from './Api';

export const getResources = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/resource/get',
    params
  });

export const getResourcesAsOptions = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/resource/get_as_options',
    params
  });

export const createResource = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/resource/create',
    params
  });

export const updateResource = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/resource/update',
    params
  });

export const deleteResource = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/resource/delete',
    params
  });
