import { useCallback, useState } from 'react';

import { ia, iaRa } from 'lib/helpers/utility';

import useFetchTestDetails from './useFetchTestDetails';
import useTestsData from './useTestsData';
import useTrackSearchClick from './useTrackSearchClick';

const useTestList = ({ setValue, formikTests, gridApi, filters }) => {
  const [testsLoading, setTestsLoading] = useState([]);

  const { tests, loading: testsDataLoading, preferredTestsData } = useTestsData({ filters });

  const { mutateAsync: fetchTestDetails } = useFetchTestDetails();

  const trackClick = useTrackSearchClick();

  const setAddedTests = useCallback(
    (updater) => {
      try {
        if (gridApi) {
          let tests = formikTests;
          // gridApi.forEachNode((node) => (!node.detail ? tests.push(node.data) : null));

          if (typeof updater === 'function') {
            tests = updater(iaRa(tests));
          } else {
            tests = iaRa(updater);
          }

          gridApi?.setGridOption('rowData', tests);
          setValue(tests);
        } else {
          throw new Error('GridAPI is null');
        }
      } catch (error) {
        console.log({ error });
      }
    },
    [gridApi, formikTests]
  );

  const addTest = useCallback(
    async (test) => {
      let newTest = test;

      if (!newTest?.sampleDetails) {
        const preferredTest = iaRa(preferredTestsData).find(
          (preferredTest) => preferredTest.testID === newTest.testID
        );

        if (preferredTest) {
          newTest = preferredTest;
        } else {
          setTestsLoading((prev) => [...prev, newTest.testID]);
          const response = await fetchTestDetails({ testId: newTest.testID });

          if (response.data) {
            newTest = { ...newTest, ...response.data };
          }

          setTestsLoading((prev) => {
            prev.pop();
            return [...prev];
          });
        }
      }

      let id = newTest.testID;
      if (newTest?.duplicate) {
        id += `-${newTest.duplicate}`;
      }
      newTest.id = id;

      setAddedTests((prev) => [...prev, newTest]);

      if (ia(newTest.testAOEs) || ia(newTest.orderAOEs)) {
        const node = gridApi?.getRowNode(newTest.id);
        if (node) node?.setExpanded(true);
      }

      trackClick(newTest.testID);
    },
    [setAddedTests, preferredTestsData]
  );

  const addDuplicateTest = (test) => {
    const addedTests = [];

    gridApi.forEachNode((node) => (!node.detail ? addedTests.push(node.data) : null));

    const count = addedTests.reduce(
      (prev, curr) => (curr.testID === test.testID ? prev + 1 : prev),
      0
    );

    const newTest = { ...test, duplicate: count };
    addTest(newTest);
  };

  return {
    tests,
    testsLoading,
    testsDataLoading,
    addTest,
    addDuplicateTest
  };
};

export default useTestList;
