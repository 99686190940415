import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';

const Complaints = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();

  if (customClinicalNote && !clinicalNote?.present_illness && !clinicalNote?.other_complaints) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      {clinicalNote?.present_illness && (
        <SanitizeComponent value={clinicalNote?.present_illness} title="Chief complaints" />
      )}

      {clinicalNote?.other_complaints && (
        <SanitizeComponent value={clinicalNote?.other_complaints} title="Other complaints" />
      )}
    </div>
  );
};

export default Complaints;
