import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Button/Button';
import Switch from 'components/shared/Switch/Switch';
import { snakeToTitleCase } from 'lib/helpers/utility';

export default function CheckinStepsModal({ showModal, hideModal, checkinSteps, handleChange }) {
  return (
    <Modal
      slideFromRight
      handleOpen={showModal}
      handleClose={hideModal}
      title="Check-in steps settings"
      className="max-w-[1100px]"
      footer={
        <>
          <Button primary onClick={hideModal} data-qa="modal-close-btn">
            Close
          </Button>
        </>
      }>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(checkinSteps).map((key) => {
          return (
            <div
              className="flex flex-col gap-2 rounded-md border border-neutral-200 !p-3 border-solid"
              key={key}>
              <div>{snakeToTitleCase(key)}</div>
              <Switch
                onChange={(e) =>
                  handleChange('checkin_steps', {
                    ...checkinSteps,
                    [key]: e
                  })
                }
                checked={!!checkinSteps[key]}
              />
            </div>
          );
        })}
      </div>
    </Modal>
  );
}
