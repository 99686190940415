import React from 'react';
import TransactionsTable from './TransactionsTable';
import { useRecoilValue } from 'recoil';
import state from 'components/state';

const Payments = () => {
  const user = useRecoilValue(state.userState);

  return (
    <div className="mt-0 flex h-full flex-col overflow-hidden bg-white pt-3">
      <TransactionsTable patient_id={user?.user_id} />
    </div>
  );
};

export default Payments;
