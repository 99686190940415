/* eslint-disable react-hooks/rules-of-hooks */
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getEvent } from 'api/Event';
import formatEvent from 'components/practice/appointment/EventForm/lib/formatEvent';

export const useEvent = ({ params, options }) => {
  const navigate = useNavigate();
  const { id } = params;

  return useQuery(['event', id], ({ signal }) => getEvent(navigate, params, signal), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => formatEvent(data?.event),
    ...options
  });
};
