import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateNotifications } from 'api/Notifications';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const options = [
    {
      label: data?.is_read ? 'Mark as unread' : 'Mark as read',
      icon: data?.is_read ? 'new-eye-slash' : 'new-eye',
      onClick: () => handleUpdateNotification(!data?.is_read)
    }
  ];
  const updateNotificationMutation = useMutation((data) => updateNotifications(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['unreadNotificationsCount']);
      queryClient.invalidateQueries(['notifications']);
    }
  });

  const handleUpdateNotification = async (isRead) => {
    await updateNotificationMutation.mutateAsync({
      navigate,
      notificationId: data?.id,
      dataToBeUpdated: { is_read: isRead }
    });
  };

  return (
    <div>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
    </div>
  );
};

export default Actions;
