import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteExternalInvoices } from 'api/Billing';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';
import Popup from 'components/shared/Popup/Popup';

import { onSendEmail, onSendText } from '../lib/utils';

import { CustomSendModal } from './contactButton/CustomSendModal';

const SendInvoiceMessagePopUp = ({ data }) => {
  const { id, patient } = data || {};

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');

  const { mutate: deleteInvoice, isLoading: isDeleteInvoicesLoading } = useMutation({
    mutationFn: (data) => deleteExternalInvoices(navigate, data)
  });

  const onDeleteInvoice = () => {
    deleteInvoice(
      { id },
      {
        onSuccess: (data) => {
          if (data?.deletedInvoice?.deleted) {
            queryClient.invalidateQueries(['external_invoices']);
            showAlert({
              title: 'Success',
              message: 'Invoices have been deleted successfully',
              color: 'success'
            });
          }
        },
        onError: () => {
          showAlert({
            title: 'Error!',
            message: 'There was an error deleting invoice!',
            color: 'danger'
          });
        }
      }
    );
  };

  const onOpenDeleteDialog = () => {
    setShowConfirmationModal(true);
  };

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  const handleCustomSendModalOpen = (type) => {
    setCustomContactType(type);
    setCustomModalVisible(true);
  };

  const menuItems = [
    {
      label: 'Send Text',
      subItems: [
        patient?.phone && {
          label: `${patient?.phone} (primary)`,
          onClick: () => onSendText({ ids: [id], navigate, type: 'primary' }),
          icon: <Icon icon="text-message" size="15px" />
        },
        patient?.alt_phone && {
          label: `${patient?.alt_phone} (alternative)`,
          onClick: () => onSendText({ ids: [id], navigate, type: 'alternative' }),
          icon: <Icon icon="text-message" size="15px" />
        },
        { label: 'Custom', onClick: () => handleCustomSendModalOpen('text') }
      ].filter(Boolean)
    },
    {
      label: 'Send Email',
      subItems: [
        patient?.email && {
          label: `${patient?.email} (primary)`,
          onClick: () => onSendEmail({ ids: [id], navigate, type: 'primary' }),
          icon: <Icon icon="new-email-blue" size="15px" />
        },
        patient?.alt_email && {
          label: `${patient?.alt_email} (alternative)`,
          onClick: () => onSendEmail({ ids: [id], navigate, type: 'alternative' }),
          icon: <Icon icon="new-email-blue" size="15px" />
        },
        { label: 'Custom', onClick: () => handleCustomSendModalOpen('email') }
      ].filter(Boolean)
    }
  ];

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={180}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        <div className="!py-[6px]">
          {menuItems.map((item, index) => (
            <Popup
              key={index}
              trigger={
                <div className="flex cursor-pointer items-center justify-between !p-2 !px-4 transition-all hover:bg-primary-50">
                  <div className="ml-1 text-sm text-primary-900">{item.label}</div>
                  <Icon icon="chevron-right" color="primary" />
                </div>
              }
              position={['left center']}
              on={['hover', 'focus']}
              contentStyle={{
                width: 'fit-content',
                borderRadius: '4px',
                boxShadow: '0px 2px 16px 0px #004F6B33',
                position: 'absolute',
                right: 170
              }}>
              <div className="relative">
                {item.subItems.map((subItem, subIndex) => (
                  <div
                    key={subIndex}
                    className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                    onClick={subItem?.onClick}>
                    {subItem.icon}
                    <div className="ml-1 text-sm text-primary-900">{subItem.label}</div>
                  </div>
                ))}
              </div>
            </Popup>
          ))}
          <Allowed requiredPermissions="invoicing.delete">
            <div
              data-qa="send-text-btn"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={onOpenDeleteDialog}>
              <div className="ml-1 text-sm text-primary-900">Delete</div>
            </div>
          </Allowed>
        </div>
      </Popup>
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete External Invoice"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this invoice?"
          loading={isDeleteInvoicesLoading}
          handleContinue={onDeleteInvoice}
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
      {customModalVisible ? (
        <CustomSendModal
          ids={[id]}
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
        />
      ) : null}
    </div>
  );
};

export default SendInvoiceMessagePopUp;
