const atom = require('recoil').atom;

module.exports = {
  notificationState: atom({
    key: 'notificationState',
    default: {
      messageUnreadCount: 0,
      notificationUnreadCount: 0
    }
  })
};
