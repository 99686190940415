import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import FadeInText from '../AnimatedText';
import { FADE_LIST } from '../../constants';

const DescriptionSection = () => {
  return (
    <div className="flex h-full flex-grow flex-col items-center justify-start gap-10">
      <div className="mt-[50px]">
        <Icon icon="ai-coding" className="mb-5" />
      </div>
      <div className="mb-1 w-2/3 text-center text-2xl text-neutral-400">
        <div className="mb-4">
          Use <span className="text-primary-500">MyScribe AI</span> to
        </div>
        <div className="items-center justify-center">
          <FadeInText texts={FADE_LIST} />
        </div>
      </div>
      <h4 className="mb-4 w-1/2 text-center text-base font-thin text-neutral-700">
        MyScribe AI is HIPAA-compliant, keeping your data secure and self-contained, helping you
        save time and maintain peace of mind.
      </h4>
    </div>
  );
};

export default DescriptionSection;
