import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../../../../../api/Api';
import { ia } from '../../../../../../lib/helpers/utility';
import Input from '../../../../../shared/Input/Input';
import InvoicePackageTable from './components/InvoicePackageTable';

const PurchasedPackages = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10,
    withCount: true
  });

  const [invoicePackages, setInvoicePackages] = useState([]);
  const [invoicePackagesCount, setInvoicePackagesCount] = useState(0);
  const navigate = useNavigate();

  const { id: patientId } = useParams();

  const getPackages = async () => {
    const onSuccess = (data) => {
      if (ia(data.invoicePackages, -1)) {
        if (data.invoicePackagesCount) {
          setInvoicePackagesCount(Number(data.invoicePackagesCount));
        }
        setInvoicePackages(data.invoicePackages);
      }
    };

    patientId &&
      requestApi({
        url: '/api/package/get_by_user',
        params: { patientId, filters },
        onSuccess,
        navigate
      });
  };
  useEffect(() => {
    getPackages();
  }, [filters.search, filters.page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.searchTerm]);

  return (
    <div className="relative bg-neutral-50 !p-4">
      <div className="flex items-center justify-between !pb-2">
        <h3 className="text-base text-neutral-800">Purchased Packages</h3>
        <Input
          data-qa="searchPackage"
          type="string"
          placeholder="Search package..."
          value={filters.searchTerm}
          rounded="full"
          icon="new-search"
          className={'w-[240px]  !border-secondary-400 md:w-[200px]'}
          onChange={(e) =>
            setFilters({ ...filters, searchTerm: e.target.value, page: 1, withCount: true })
          }
        />
      </div>
      {ia(invoicePackages) ? (
        <InvoicePackageTable
          {...{
            invoicePackages,
            setInvoicePackages,
            patientId,
            filters,
            invoicePackagesCount,
            setFilters,
            getPackages
          }}
        />
      ) : (
        <p>No packages have been found!</p>
      )}
    </div>
  );
};

export default PurchasedPackages;
