import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useChatContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { formatDateAndTimeZ } from 'lib/helpers/utility';
import { useTreatmentPlan } from 'lib/hooks/queries/treatmentPlan/useTreatmentPlan';

import { currentPractice } from 'components/practice/practiceState';

import CreatePreviewView from './components/CreatePreviewTPView';
import EditView from './components/EditTPView';
import TableView from './components/TPTableView';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from './configs';

export function Table({ breadcrumb }) {
  const { steps, setSteps, step, setStep, push } = breadcrumb;
  const practice = useRecoilValue(currentPractice);
  const { id } = useParams();
  const { gridApi, selectedRows, setSelectedRows } = useTableContext();
  const { selectedTableRows } = useChatContextData();
  const selectedTableNodes = selectedTableRows?.['treatment_plan']?.selectedNodes;
  const { data } = useTreatmentPlan({ patientId: id });
  const plans = data?.data;

  const rowSelection = useMemo(
    () => ({
      mode: 'singleRow',
      checkboxes: false,
      enableClickSelection: true
    }),
    []
  );

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      selectedTableNodes?.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    } else if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  useEffect(() => {
    setSteps([{ title: 'Treatment Plan', name: 'start' }]);
    setStep(0);
  }, []);

  const onTPPreview = (date) => {
    push({
      title: formatDateAndTimeZ(date, practice.practiceTimezone),
      name: 'preview'
    });
  };

  const currentView = steps[step]?.name;

  if (['create', 'preview'].includes(currentView)) {
    return <CreatePreviewView breadcrumb={breadcrumb} />;
  }

  if (currentView === 'edit') {
    return <EditView breadcrumb={breadcrumb} />;
  }

  return (
    <TableView
      plans={plans}
      gridApi={gridApi}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      rowSelection={rowSelection}
      defaultColDef={DEFAULT_COLUMN_DEFS}
      columnDef={getColDefs(practice.practiceTimezone)}
      gridOptions={GRID_OPTIONS}
      breadcrumb={breadcrumb}
      onTPPreview={onTPPreview}
    />
  );
}
