import Tippy from '@tippyjs/react';

import { TYPE_LABELS } from 'lib/constants/transaction/typeLabels';
import { formatDate, formatDateAndTime, mString } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';

import { AllocateAction } from '../components/AllocateAction';

export const getColDefs = (timezone) => [
  {
    field: 'appointment.starts_at',
    headerName: 'Appointment Date',
    valueFormatter: ({ value }) => formatDate(value, timezone),
    filter: 'agDateColumnFilter'
  },
  {
    field: 'created_at',
    headerName: 'Transaction Date',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone),
    filter: 'agDateColumnFilter',
    sort: 'desc'
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: ({ value }) => TYPE_LABELS[value]
  },
  {
    field: 'amount_paid_self',
    headerName: 'Amount',
    aggFunc: 'sum',
    cellRenderer: ({ value, data }) => {
      return (
        <div className="flex gap-2">
          <p>{mString(value || 0)}</p>
          {data?.type === 'writeoff' && (
            <Tippy
              interactive
              className="tippy-dark"
              appendTo={document.body}
              content={data?.note || 'No note'}>
              <div className="flex">
                <Icon icon="new-info-circle" color="neutral" className="cursor-pointer" />
              </div>
            </Tippy>
          )}
        </div>
      );
    }
  },
  {
    field: 'actions',
    cellRenderer: AllocateAction
  }
];

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true, flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
