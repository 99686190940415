import React, { useCallback, useEffect, useRef, useState } from 'react';
import checkinBackgroundPNG from '../../../../assets/Images/checkin-background.png';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import { ia } from '../../../../lib/helpers/utility';
import '../../../shared/Modal/Modal.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import Wizard from '../../../Patient/checkin/Wizard';
import { requestApi } from '../../../../api/Api';
import { checkinV2Map } from '../../../Patient/checkin/constants';
import { nextIncompleteStep } from '../../../Patient/checkin/util';
import { useRecoilState } from 'recoil';
import state from 'components/Patient/checkin/state';

export default function InstantPacket() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const checkinButton = useRef();
  const { device } = useUIContext();

  const [steps, setSteps] = useState([]);
  const [stepObject, setStepObject] = useState({});
  const [numCompleted, setNumCompletd] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('');
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [checkinInsuranceEnabled, setCheckinInsuranceEnabled] = useState(false);
  const [practiceLogo, setPracticeLogo] = useState('');

  const [user, setUser] = useRecoilState(state?.userState);

  useEffect(() => {
    getPacketStatus();
  }, []);

  const handleExternalSubmit = async () => {
    let naviToNext = false;
    setCompletedSteps(steps);
    if (checkinButton?.current?.click) {
      naviToNext = checkinButton?.current?.click();
      navigateToIncomplete({ steps });
    } else if (checkinButton?.current?.continue) {
      naviToNext = await checkinButton?.current?.continue();
      if (naviToNext) {
        navigateToIncomplete({ steps });
      }
    } else {
      getPacketStatus();
      navigateToIncomplete({ steps });
    }
  };

  useEffect(() => {
    ia(steps) && currentStep();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  const setCompletedSteps = (stepsArray) => {
    const numberOfCompleted = stepsArray?.filter((v) => v.completed).length || null;
    if (numberOfCompleted) setNumCompletd(numberOfCompleted);
  };

  const initialCurrentStep = ({ path = null, status = false }) => {
    return pathname === path ? 'current' : status ? 'complete' : 'incomplete';
  };

  const currentStep = () => {
    setSteps((prevStep) =>
      prevStep.map((step) => {
        if (step.path === pathname || pathname.includes(step.path)) {
          setCurrentStepName(step.name);
          setCurrentStepNumber(prevStep.findIndex((v) => v.name === step.name) + 1);
          return { ...step, status: 'current' };
        } else {
          return { ...step, status: step.completed ? 'complete' : 'incomplete' };
        }
      })
    );
  };

  const updateCompleteStep = () => {
    setSteps((prev) => {
      const isAllCompleted = isEverythingCompleted(prev);
      const newSteps = prev.map((step) => {
        if (isAllCompleted && step.name === 'Complete')
          return { ...step, status: 'complete', completed: true, isStepDone: true };

        if (!isAllCompleted && !step.completed) return { ...step, isStepDone: false };

        return step;
      });
      return newSteps;
    });
  };

  const updateStep = useCallback(
    async ({ newStatus, stepId, formId, meta }) => {
      let foundStepId = null;

      let newSteps = steps.map((v) => {
        if ((stepId && v.step_id === stepId) || pathname.includes(v.path)) {
          foundStepId = v.step_id;
          return { ...v, completed: ['partial', 'completed'].includes(newStatus) };
        } else {
          return v;
        }
      });

      setSteps(newSteps);

      if (!formId) {
        await requestApi({
          url: '/api/form/instant_packet/continue',
          params: {
            stepId: stepId || foundStepId,
            newStatus,
            meta
          },
          navigate
        });
      }

      navigateToIncomplete({ steps: newSteps });
    },
    [steps]
  );

  const updateSteps = async ({
    version,
    stepId,
    formId,
    newStatus,
    formName = null,
    formsCompleted,
    formsToEdit,
    isCompleted = true,
    meta
  }) => {
    if (version === 2) {
      await updateStep({ version, stepId, formId, newStatus, meta });
      return;
    }
    let stepNameComparator = (stepName) => {
      return stepName === formName;
    };
    if (ia(formsCompleted)) {
      stepNameComparator = (stepName) => {
        return formsCompleted.includes(stepName);
      };
    }
    let formsToEditComparator = (stepName) => {
      return stepName === formsToEdit;
    };
    if (ia(formsToEdit)) {
      formsToEditComparator = (stepName) => {
        return formsToEdit.includes(stepName);
      };
    }
    const updatedSteps = steps.map((step) => {
      if (stepNameComparator(step?.name)) {
        return {
          ...step,
          status: isCompleted ? 'complete' : 'incomplete',
          completed: isCompleted,
          isStepDone: true
        };
      } else if (formsToEdit && formsToEditComparator(step?.name)) {
        return {
          ...step,
          status: 'incomplete',
          completed: false,
          isStepDone: false
        };
      } else {
        return step;
      }
    });

    setCompletedSteps(updatedSteps);
    setSteps(updatedSteps);
    navigateToIncomplete({ steps: updatedSteps });
  };

  const navigateToIncomplete = useCallback(
    ({ steps: givenSteps, ignoreIsStepDone = false }) => {
      let lSteps = givenSteps;
      if (!lSteps) {
        lSteps = steps;
      }

      if (!ia(lSteps)) {
        return;
      }

      const incomplete = nextIncompleteStep(pathname, lSteps);

      let prefix = '';
      if (pathname.includes('kiosk')) {
        prefix = '/kiosk/';
      }
      navigate(incomplete?.path ? `${prefix}${incomplete?.path}` : `${prefix}/checkin/complete`);
    },
    [steps]
  );

  const isEverythingCompleted = (stepsArray) => {
    return (
      ia(stepsArray) &&
      stepsArray.filter((step) => step.name !== 'Complete').every((step) => step.completed === true)
    );
  };

  const getPacketStatus = async () => {
    const onSuccess = (data) => {
      // on success
      if (data.practiceLogo) {
        setPracticeLogo(data.practiceLogo);
      }
      let dataStep = data?.steps || {};

      let newSteps = dataStep.map((v) => ({
        ...v,
        name: checkinV2Map[v?.name]?.name(v) || v?.name
      }));

      data?.user && setUser(data.user);

      // let newSteps = [];
      // setStepObject(dataSteps);
      // for (let stepName in dataSteps) {
      //   let propStep = instantPacketPathMap[stepName];
      //   let dataStep = dataSteps[stepName];
      //   if (stepName === 'practiceLogo' || !propStep) {
      //     continue;
      //   }
      //   if (Array.isArray(dataStep)) {
      //     for (let i = 0; i < dataStep.length; i++) {
      //       let element = dataStep[i];
      //       newSteps.push({
      //         name: propStep.name(element, i),
      //         path: `/instant-packet/${propStep.path(element, i)}`,
      //         status: initialCurrentStep({
      //           path: `/instant-packet/${propStep.path(element, i)}`,
      //           status: !!element?.completed
      //         }),
      //         completed: !!element?.completed,
      //         isStepDone: !!element?.completed,
      //         data: element,
      //         index: `${stepName}:${i}`,
      //         order: propStep?.order
      //       });
      //     }
      //   } else {
      //     newSteps.push({
      //       name: propStep.name(dataStep),
      //       path: `/instant-packet/${propStep.path(dataStep)}`,
      //       status: initialCurrentStep({
      //         path: `/instant-packet/${propStep.path(dataStep)}`,
      //         status: dataStep?.completed
      //       }),
      //       completed: dataStep?.completed,
      //       isStepDone: dataStep?.completed,
      //       data: dataStep,
      //       index: stepName,
      //       order: propStep?.order
      //     });
      //   }
      // }

      const isCompleted = isEverythingCompleted(newSteps);

      newSteps.push({
        name: 'Complete',
        path: '/instant-packet/complete',
        status: initialCurrentStep({ path: '/instant-packet/complete', status: isCompleted }),
        completed: isCompleted,
        position: 99
      });

      newSteps = newSteps.sort((a, b) => a.position - b.position);

      if (ia(newSteps)) {
        newSteps.forEach((v) => {
          if (v.path === pathname) {
            setCurrentStepName(v.name);
          }
        });
      }

      setCompletedSteps(newSteps);
      setSteps(newSteps);
    };
    await requestApi({
      url: '/api/form/instant_packet/status',
      onSuccess,
      navigate,
      filePath: __filename
    });
  };

  const updateChoices = useCallback(
    (stepsToCompleted, stepsToEdit) => {
      // current step is probably one that needs to be checked for an update
      let advance = false;

      let stepsToCompleteSet = new Set();
      let stepsToEditSet = new Set();

      if (ia(stepsToCompleted)) {
        stepsToCompleted.forEach((v) => {
          stepsToCompleteSet.add(v);
        });
      }

      if (ia(stepsToEdit)) {
        stepsToEdit.forEach((v) => stepsToEditSet.add(v));
      }

      const newSteps = steps.map((v) => {
        let r = v;
        if (stepsToCompleteSet.has(v?.name)) {
          // this is one of the steps to update
          r.completed = true;
          if (v?.url === pathname) {
            // current step
            advance = true;
          }
        } else if (stepsToEditSet.has(v?.name)) {
          r.completed = false;
        }
        return r;
      });

      setCompletedSteps();
      setSteps(newSteps);

      if (advance) {
        navigateToIncomplete({ steps: newSteps });
      }
    },
    [steps]
  );

  const showButton = () => {
    const excludedPaths = ['/complete'];
    return !excludedPaths.some((path) => pathname.includes(path));
  };

  return (
    <Wizard
      checkinBackgroundPNG={checkinBackgroundPNG}
      loginRequest={false}
      setLoginRequest={() => {}}
      device={device}
      practiceLogo={practiceLogo}
      steps={steps}
      currentStepName={currentStepName}
      currentStepNumber={currentStepNumber}
      updateCompleteStep={updateCompleteStep}
      updateSteps={updateSteps}
      isEverythingCompleted={isEverythingCompleted}
      navigateToIncompleted={navigateToIncomplete}
      stepObject={stepObject}
      setSteps={setSteps}
      checkinButton={checkinButton}
      checkinInsuranceEnabled={checkinInsuranceEnabled}
      showButton={showButton}
      numCompleted={numCompleted}
      handleExternalSubmit={handleExternalSubmit}
      customOnSuccess={handleExternalSubmit}
      updateChoices={updateChoices}
      isInstantPacket
    />
  );
}
