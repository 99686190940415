import moment from 'moment-timezone';

export default function formatRange(rangeDate, view) {
  const { startDate, endDate } = rangeDate;

  const start = moment(startDate).add(12, 'hours');
  const end = moment(endDate);

  if (['day', 'agenda', 'resource', 'provider'].includes(view)) {
    return start.format('dddd MMM DD');
  }

  if (view === 'month') {
    return `${start.format('MMMM YYYY')}`;
  }

  return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
}
