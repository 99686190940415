import { useQuery } from '@tanstack/react-query';
import { getInvoices } from 'api/Transaction';
import { useNavigate } from 'react-router-dom';

export const useInvoices = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['patientInvoices', ...dependencies], () => getInvoices(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
