module.exports = {
  nextIncompleteStep: (pathname, steps) => {
    let oldIndex = steps.length;
    let newIndex = -1;
    let firstIncomplete = -1;
    for (let i = 0; i < steps.length; i++) {
      // find current step
      if (pathname.includes(steps[i].path)) {
        oldIndex = i;
      }
      // find next incomplete
      if (newIndex === -1 && !steps[i].completed && oldIndex < i) {
        newIndex = i;
      }

      if (firstIncomplete === -1 && !steps[i].completed) {
        firstIncomplete = i;
      }

      // if (newIndex !== -1 && firstIncomplete !== -1) {
      //   break;
      // }
    }

    let incomplete;

    if (newIndex !== -1) {
      incomplete = steps[newIndex];
    } else if (firstIncomplete !== -1) {
      incomplete = steps[firstIncomplete];
    }
    return incomplete;
  }
};
