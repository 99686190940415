import { useQueryClient } from '@tanstack/react-query';
import { socket } from 'api/Socket';
import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import React, { useEffect, useMemo } from 'react';
import './MedicationsTable.scss';

const MedicationsTable = ({ data = [], getGridApi = () => null }) => {
  const { gridApi } = useTableContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    getGridApi(gridApi);
    socket.on('veradigm-prescribe', () => {
      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={data}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            // onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MedicationsTable;
