import { AlertContent } from 'components/shared/Alert/Alert';
import React from 'react';
import AlertMessage from './components/AlertMessage';
import SyncForm from './components/SyncForm';
import { ia } from 'lib/helpers/utility';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const SyncClaim = ({ claim = {}, pendingClaimMdChanges = [], breadcrumb = {} }) => {
  return (
    <>
      {!ia(pendingClaimMdChanges) ? (
        <div className="mx-auto flex h-full items-center justify-center">
          <NotFound icon="new-illustration-2" iconSize={300} title="No changes found!" />
        </div>
      ) : (
        <div className="flex w-full flex-col gap-6">
          <AlertContent
            color="warning"
            title="There are updates from the clearing house that don’t match your current claim."
            width="full"
            message={<AlertMessage />}
          />
          <SyncForm
            claim={claim}
            pendingClaimMdChanges={pendingClaimMdChanges}
            breadcrumb={breadcrumb}
          />
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(SyncClaim);
