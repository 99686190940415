export const patientNavbarOptions = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    path: '/myportal'
  },
  {
    label: 'Messages',
    icon: 'chat',
    path: '/myportal/inbox'
  },
  {
    label: 'Treatment Plans',
    icon: 'new-document-sign',
    path: '/myportal/treatment-plans'
  },
  {
    label: 'Appointments',
    icon: 'appointments',
    path: '/myportal/appointments'
  },
  {
    label: 'Payments',
    icon: 'new-payment',
    path: '/myportal/payments'
  },
  {
    label: 'Superbills',
    icon: 'new-superbill',
    path: '/myportal/superbills'
  },
  {
    label: 'Medications',
    icon: 'new-pill',
    path: '/myportal/medications'
  },
  {
    label: 'Documents',
    icon: 'export',
    path: '/myportal/documents'
  },
  {
    label: 'Referrals',
    icon: 'referrals',
    path: '/myportal/referrals'
  },
  {
    label: 'My Account',
    icon: 'user-fill',
    path: '/myportal/account'
  },
  {
    label: 'Logout',
    icon: 'logout',
    path: null
  }
];

// {
//   label: 'Medications',
//   icon: 'medical-records',
//   path: '/myportal/medical-records'
// },
// {
//   label: 'Questionnaires',
//   icon: 'questionnaries',
//   path: '/myportal/questionnaires'
// },
// {
//   label: 'Invoices',
//   icon: 'new-billing',
//   path: '/myportal/invoices'
// }
