import React, { useEffect, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';

import { searchPatients } from 'components/practice/Dashboard/widgets/UpcomingAppointments/Calendar/components/Navigation/components/Filters/Filters';
import apptDefaultFilters from 'components/practice/Dashboard/widgets/UpcomingAppointments/Calendar/components/Navigation/components/Filters/lib/apptDefaultFilters';
import practiceState from 'components/practice/practiceState';
import returnAppliedFilter from 'components/shared/Filter/lib/helpers/handleAppliedFilter';

import { DashboardContext } from './DashboardContext';

export const DashboardContextProvider = ({ children }) => {
  const currentDate = new Date();
  const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const appointmentStatuses = useRecoilValue(practiceState.appointmentStatuses);

  const arrivalStatuses = appointmentStatuses
    .filter(
      (status) =>
        status.value.includes('arrived') || ['appointment_ended', 'no_show'].includes(status.value)
    )
    .map(({ label }) => ({ label, value: label }));

  const [ranges, setRanges] = useState({
    startDate: currentMonthStartDate,
    endDate: currentDate
  });

  const { data: savedFilters = {} } = useDefaultFilters({});

  const [filters, setFilters] = useState(
    returnAppliedFilter(
      apptDefaultFilters({
        arrivalStatuses,
        searchPatients
      }),
      savedFilters['dashboard_calendar'] || null
    )
  );

  useEffect(() => {
    setFilters(
      returnAppliedFilter(
        apptDefaultFilters({
          arrivalStatuses,
          searchPatients
        }),
        savedFilters['dashboard_calendar'] || null
      )
    );
  }, [savedFilters['dashboard_calendar']]);

  const [currentModal, setCurrentModal] = useState({ show: false, id: null, type: '' });
  const [confirmModal, setConfirmModal] = useState({ show: false, id: null, type: '' });

  return (
    <DashboardContext.Provider
      value={{
        ranges,
        setRanges,
        filters,
        setFilters,
        currentModal,
        setCurrentModal,
        confirmModal,
        setConfirmModal
      }}>
      {children}
    </DashboardContext.Provider>
  );
};
