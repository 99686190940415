import React, { useState, useEffect } from 'react';

import ReferringProvider from './ReferringProvider';
import Button from '../../../../shared/Buttons/Button';
import NewReferringProvider from './NewReferringProvider';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import useScrollBlock from '../../../../../lib/hooks/useScrollBlock';

const ReferringProviders = ({ patientId, practicePatient }) => {
  const [blockScroll, allowScroll] = useScrollBlock();

  const [mode, setMode] = useState({ read: false, edit: false });
  const [providerList, setProviderList] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [showNewReferringProvider, setShowNewReferringProvider] = useState(false);
  const [autofillCMS1500, setAutofillCMS1500] = useState(practicePatient?.auto_ref_prov);

  const handleNewReferringProvider = () => {
    blockScroll();
    setShowNewReferringProvider(true);
  };

  const hideNewReferringProvider = () => {
    allowScroll();
    setSelectedProvider(null);
    setShowNewReferringProvider(false);
    setMode({ read: false, edit: false });
  };

  const handleEditMode = (provider) => {
    setMode({ read: true, edit: true });
    setSelectedProvider(provider);
    handleNewReferringProvider();
  };

  const formatRP = (provider) => {
    const { number, first_name, middle_name, last_name, cond_date, address } = provider || {};

    return {
      npi: number,
      f_name: first_name,
      m_name: middle_name,
      l_name: last_name,
      cond_date: cond_date,
      address: address
    };
  };

  return (
    <div className="pb-12">
      <div className="flex justify-between">
        <label className="text-lg font-600">Referring Provider</label>
        <Button
          data-qa="new-referring-provider-btn"
          size="small"
          type="secondary"
          text="New Referring Provider"
          onClick={handleNewReferringProvider}
        />
      </div>

      <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        <NewReferringProvider
          mode={mode}
          setMode={setMode}
          formatRP={formatRP}
          patientId={patientId}
          setProviderList={setProviderList}
          autofillCMS1500={autofillCMS1500}
          selectedProvider={selectedProvider}
          setAutofillCMS1500={setAutofillCMS1500}
          setSelectedProvider={setSelectedProvider}
          showNewReferringProvider={showNewReferringProvider}
          hideNewReferringProvider={hideNewReferringProvider}
        />

        {providerList.map((provider, i) => {
          return (
            <ReferringProvider
              key={i}
              index={i}
              provider={provider}
              handleEditMode={handleEditMode}
              autofillCMS1500={autofillCMS1500}
              setProviderList={setProviderList}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ReferringProviders;
