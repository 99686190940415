import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPractitioners } from 'api/Practitioner';

import { reShapePractitioners } from 'lib/helpers/utility';

export const usePractitioners = (params = {}, options, dependencies = []) => {
  const navigate = useNavigate();
  return useQuery(['practitioners', ...dependencies], () => getPractitioners(params, navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => ({
      ...data,
      practitionersOptions: reShapePractitioners(data?.practitioners)
    }),
    ...options
  });
};
