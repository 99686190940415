import cs from 'classnames';
import ProviderFamilyHistory from 'components/shared/Forms/Custom/Provider/FamilyHistory/ProviderFamilyHistory';
import React from 'react';

const FamilyHistorySection = ({
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = []
}) => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-106px)] text-sm text-neutral-600 duration-200">
        <ProviderFamilyHistory
          fromClinicalNote={true}
          formId={formId}
          defaultValue={defaultValue}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          showNarrative
          setCurrentFormData={setCurrentFormData}
          options={options}
        />
      </div>
    </div>
  );
};

export default FamilyHistorySection;
