import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { getPatients } from 'api/Patients';
import { getPayers } from 'api/Payer';
import { getPractitioners } from 'api/Practitioner';
import { getServices } from 'api/Service';

import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { formatDate } from 'lib/helpers/utility';
import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';
import { useBillingOverview } from 'lib/hooks/queries/widgets/useBilling';

import ClaimsQueue from 'components/practice/BeyondBilling/Overview/widgets/ClaimsQueue';
import ERAEOB from 'components/practice/BeyondBilling/Overview/widgets/ERA&EOB';
import GrandTotal from 'components/practice/BeyondBilling/Overview/widgets/GrandTotal';
import Invoices from 'components/practice/BeyondBilling/Overview/widgets/Invoices';
import Superbills from 'components/practice/BeyondBilling/Overview/widgets/Superbills';
import Board from 'components/shared/CustomizableBoard/Board';
import HeaderButton from 'components/shared/CustomizableBoard/HeaderButton';
import Filter from 'components/shared/Filter/Filter';
import { handleAppliedFilter } from 'components/shared/Filter/lib/helpers';
import Header from 'components/shared/Header/Header';

const widgets = {
  grand_total: GrandTotal,
  billing_claims: ClaimsQueue,
  billing_era_eob: ERAEOB,
  billing_invoices: Invoices,
  billing_superbills: Superbills
};

export default function BeyondBillingOverview() {
  const navigate = useNavigate();

  const defaultFilters = {
    date: {
      values: null,
      type: 'date-range'
    },
    patients: {
      type: 'search',
      multiple: true,
      loadOptions: (e) => searchPatients(e),
      values: [],
      title: 'Patients'
    },
    providers: {
      type: 'infinite-scroll',
      multiple: true,
      options: [],
      values: [],
      title: 'Providers',
      queryKey: 'practitioners',
      queryFn: getPractitioners
    },
    payers: {
      type: 'infinite-scroll',
      multiple: true,
      options: [],
      values: [],
      title: 'Payers',
      queryKey: 'payers',
      queryFn: getPayers
    },
    services: {
      type: 'infinite-scroll',
      multiple: true,
      options: [],
      values: [],
      title: 'Services',
      queryKey: 'services',
      queryFn: getServices,
      params: { limit: 25, withCount: true }
    },
    include_tx_type: {
      values: null,
      title: 'Transaction Type',
      type: 'checkbox',
      options: [
        { label: 'Credit Card', value: 'credit_card' },
        { label: 'Cash', value: 'cash' },
        { label: 'Check', value: 'check' },
        { label: 'Outside Financing', value: 'care_credit' },
        { label: 'Insurance Payments', value: 'era_eob' }
      ]
    },
    exclude: {
      values: null,
      title: 'Exclude',
      type: 'checkbox',
      options: [
        { label: 'Unmatched Claims', value: 'unmatched_claims' },
        { label: 'Unallocated Payments', value: 'unallocated_payments' }
      ]
    }
  };
  const { data: savedFilters = {}, isFetched } = useDefaultFilters({});

  const [filters, setFilters] = useState(
    handleAppliedFilter(
      {
        ...defaultFilters,
        date: {
          ...defaultFilters?.date,
          values: {
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD')
          }
        }
      },
      savedFilters['beyond_billing_overview']
    )
  );

  useEffect(() => {
    if (isFetched) {
      setFilters(handleAppliedFilter(filters, savedFilters['beyond_billing_overview']));
    }
  }, [isFetched]);

  const searchPatients = async (searchTerm) => {
    const patients = await getPatients(navigate, {
      limit: 25,
      searchTerm,
      offset: 0
    });

    return patients.patients.map((p) => ({
      customLabel: (
        <div>
          {p?.fullName && <span>{p.fullName}</span>}
          {p.dob && <span className="pl-1 text-xs text-neutral-500">{formatDate(p.dob)}</span>}
        </div>
      ),
      label: p?.fullName,
      value: p?.id
    }));
  };

  const { data } = useBillingOverview({
    params: {
      startAt: filters?.date?.values?.startDate,
      endAt: filters?.date?.values?.endDate,
      patients: filters?.patients?.values.map((p) => p.value),
      providers: filters?.providers?.values.map((p) => p.value),
      payers: filters?.payers?.values.map((p) => p.value),
      services: filters?.services?.values.map((p) => p.value),
      exclude: filters?.exclude?.values?.reduce?.((acc, val) => ({ ...acc, [val]: true }), {}),
      include_tx_type: filters?.include_tx_type?.values?.reduce?.(
        (acc, val) => ({ ...acc, [val]: true }),
        {}
      )
    },
    dependencies: [filters]
  });

  return (
    <BoardContextProvider board="beyond_billing_overview" cols={{ lg: 4, md: 2, sm: 2 }}>
      <Board
        filters={filters}
        setFilters={setFilters}
        defaultFilters={defaultFilters}
        wrapperClassName="overflow-auto"
        rootClassName="h-full"
        widgets={widgets}
        overview={data}
        showInfoInRecordings={true}>
        <HeaderButton header={() => <Header title="Reporting Overview" className="!border-b-0" />}>
          <Filter
            category="beyond_billing_overview"
            filters={filters}
            defaultFilters={defaultFilters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
          />
        </HeaderButton>
      </Board>
    </BoardContextProvider>
  );
}
