import { requestApi } from 'api/Api';
import checkinBackgroundPNG from 'assets/Images/checkin-background.png';
import IdleWrapper from 'components/Kiosk/components/IdleWrapper';
import { KioskContextProvider } from 'lib/context/KioskContext/KioskContextProvider';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import AutoIdle from 'components/Kiosk/components/AutoIdle';

const AppointmentKiosk = ({ success, loading, setAndAction, resubmit, data, type }) => {
  const { device } = useUIContext();
  const navigate = useNavigate();

  return (
    <div className="h-screen overflow-hidden bg-transparent !pt-6">
      <AutoIdle />

      <img
        src={checkinBackgroundPNG}
        className="absolute left-0 top-0 z-10 h-full w-full object-cover"
      />
      <KioskContextProvider mobile={device === 'mobile'}>
        <IdleWrapper>
          <div className="flex w-full flex-col items-center justify-between">
            <div className="flex w-full flex-col items-center justify-center p-[1.5rem] px-[2rem] sm:p-4">
              <div className="flex w-full flex-col">
                <div className=" text-xl font-500 text-primary-900">
                  You Have Successfully Signed In
                </div>

                <p className=" text-neutral-600">
                  Your information has been received. Thank you for using our self-service kiosk.
                  Please continue to fill out any forms associated with your appointment. If you
                  need any assistance, please do not hesitate to ask one of our staff members. We
                  appreciate your patience and cooperation.
                </p>
                <div className="my-[1rem] flex w-full flex-col rounded-xl border-[1px] border-solid border-primary-100 bg-primary-50 px-[1.2rem] py-[0.7rem] sm:py-[0.3rem]">
                  {data?.provider && (
                    <>
                      <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.3rem]">
                        <span>Provider</span>
                        <span>{data?.provider}</span>
                      </div>
                      <hr className="mb-[0.5rem] border-[1.5px] border-dashed border-primary-100 sm:mb-[0.3rem]" />
                    </>
                  )}
                  {data?.date && (
                    <>
                      <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.3rem]">
                        <span>Date</span>
                        <span>{data?.date}</span>
                      </div>
                    </>
                  )}
                </div>
                <div className="flex gap-3">
                  <Button
                    text="Go back"
                    loading={loading}
                    onClick={() => {
                      if (type == 'mobile') {
                        navigate('/mobile/idle');
                      } else {
                        requestApi({
                          url: '/api/user/logout',
                          params: {},
                          navigate
                        });
                        navigate('/kiosk');
                      }
                    }}
                    color="neutral"
                    className="my-[0.6rem] w-full"
                  />
                  {success && (
                    <Button
                      text="Complete My Forms"
                      loading={loading}
                      onClick={() => {
                        setAndAction(true);
                        setTimeout(() => {
                          resubmit();
                        }, 50);
                      }}
                      className="my-[0.6rem] w-full py-3"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </IdleWrapper>
      </KioskContextProvider>
    </div>
  );
};

export default AppointmentKiosk;
