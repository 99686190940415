import React, { useEffect } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import ActivityItem from './ActivityItem';

const ActivityGroup = ({ date, limit, setLimit, activities, toggleIsOpen }) => {
  useEffect(() => {
    return () => {
      if (limit !== 30) {
        setLimit(30);
      }
    };
  }, []);
  return (
    <div>
      <div>
        <h3 className="text-lg mb-1 text-primary-900">{date}</h3>
        <hr className="border-t-1 border-neutral-100 !mt-0" />
      </div>
      {activities?.length === 0 ? (
        <div className="text-gray-500 text-sm">No activity.</div>
      ) : (
        activities?.map((activity, index) => (
          <ActivityItem key={index} activity={activity} toggleIsOpen={toggleIsOpen} />
        ))
      )}
    </div>
  );
};

export default withErrorBoundary(ActivityGroup);
