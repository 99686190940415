import React from 'react';
import { useRecoilValue } from 'recoil';
import state from '../../state';
import { pm } from '../../../lib/helpers/utility';
import Button from '../Buttons/Button';

const StaffTable = ({ children, handleNewMemberClick }) => {
  const p = useRecoilValue(state.permissions);

  return (
    <>
      <table className={'primary-table'}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
            <th>Status</th>
            <th>Color</th>
            {pm(p, 'member.create') && false && (
              <th>
                <Button
                  size="small"
                  text={'Add Staff Member +'}
                  onClick={handleNewMemberClick}
                  green
                />
              </th>
            )}
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
        {/* <tr>
          <td colSpan={4}>
            {canLoadMore ? (
              <Button green onClick={getData} loading={loading}>
                Load More
              </Button>
            ) : (
              <span>No more staff to show.</span>
            )}
          </td>
        </tr> */}
      </table>
    </>
  );
};

export default StaffTable;
