import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { isEqual } from 'lodash';

const updateAddress = async ({
  addressType,
  updatedAddress,
  initialVal,
  patientId,
  queryClient
}) => {
  const { address, secondary_address } = initialVal || {};
  const initialValues = addressType === 'primary' ? address : secondary_address;

  const addressChanged = !isEqual(
    { ...initialValues, full_street_address: updatedAddress?.full_street_address },
    updatedAddress
  );

  if (!addressChanged) return;

  const onSuccess = () => {
    const addressKey = addressType === 'primary' ? 'userAddress' : 'secondaryUserAddress';
    queryClient.setQueryData(['patient', patientId], (data) => ({
      ...data,
      patient: {
        ...data.patient,
        [addressKey]: updatedAddress
      }
    }));
    showAlert({ title: 'Address updated successfully!', color: 'success' });
  };

  await requestApi({
    url: '/api/address/patch',
    params: {
      loadedAddress: updatedAddress,
      userId: patientId,
      isSecondAddress: addressType === 'secondary'
    },
    onSuccess
  });
};

export default updateAddress;
