import { isArray } from 'lodash';

export const getPercentage = (constantType, displayName, data, total) => {
  let type;
  if (isArray(constantType)) {
    type = constantType?.find((t) => t?.value === displayName);
  }
  let value = 0;
  if (type) {
    value = data[type.name];
  }
  const percentage = (value / total) * 100;
  return isNaN(percentage) ? 0 : percentage;
};

export const getIconByPercentage = (constantType, label, data, total) => {
  const percentage = getPercentage(constantType, label, data, total);
  if (percentage === 0) {
    return 'new-arrow-right-v2';
  } else if (percentage < 0) {
    return 'new-arrow-down-v2';
  } else {
    return 'new-arrow-up-v2';
  }
};

export const getIconColorByPercentage = (constantType, label, data, total) => {
  const percentage = getPercentage(constantType, label, data, total);
  if (percentage === 0) {
    return 'neutral';
  } else if (percentage < 0) {
    return 'danger';
  } else {
    return 'success';
  }
};

export const getClassNamesByPercentage = (constantType, label, data, total) => {
  const percentage = getPercentage(constantType, label, data, total);
  if (percentage === 0) {
    return 'border-solid border-neutral-200  bg-neutral-50 text-neutral-600';
  } else if (percentage < 0) {
    return 'border-solid border-red-200 bg-red-50 text-danger-500';
  } else {
    return 'border-solid border-success-200 bg-success-50 text-success-600';
  }
};
