import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import AdminIntelligencePromptTable from 'components/admin/pages/Intelligence/Prompt/table';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { cols } from 'components/admin/pages/Intelligence/Prompt/helpers';
import AdminIntelligencePromptDetails from 'components/admin/pages/Intelligence/Prompt/details';

const AdminIntelligencePrompts = () => {
  return (
    <>
      <TableContextProvider
        name="admin_intelligence_ai_prompt_template_table"
        defaultFilters={[]}
        cols={cols}>
        <AdminIntelligencePromptTable />
      </TableContextProvider>
      <AdminIntelligencePromptDetails />
    </>
  );
};

export default withErrorBoundary(AdminIntelligencePrompts);
