import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCustomFormResponse } from '../../../../api/CustomForms';

const defaultParams = {
  allVersions: false,
  type: [
    'medicationHistory',
    'allergies',
    'medicalHistory',
    'vaccines',
    'conditions',
    'familyHistory',
    'socialHistory',
    'surgicalHistory',
    'weightLossAdministration'
  ]
}

export const useCustomFormResponses = ({ dependencies = [], params = {}, options }) => {
  const navigate = useNavigate();
  return useQuery(['customFormReponses', ...dependencies], () => getCustomFormResponse(navigate, { ...defaultParams, ...params }), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
