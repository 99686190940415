import React from 'react';
import Accordion from '../../../../shared/Accordion';
import ICDCodes from '../../SOAPNote/components/ICDCodes';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const ICDCodesSection = ({ components, sectionRef }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.diagnosisCodes?.title}
      id={components?.diagnosisCodes?.id}
      disabled={clinicalNote?.locked}>
      <ICDCodes sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ICDCodesSection;
