import React from 'react';

import { capitalize } from 'lodash';

import { ia, imagePath, mString } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';

const statusColors = {
  active: '!text-success-500',
  cancelled: '!text-danger-500',
  expired: '!text-warning-500',
  used: '!text-neutral-600'
};

const PreviewInvoicePackageModal = ({ singlePackageDetails, setSinglePackageDetails }) => {
  const {
    note,
    validity_time_length,
    validity_time_unit,
    validUntil,
    image,
    procedures,
    products,
    total_amount_cents,
    status
  } = singlePackageDetails;
  return (
    <Modal
      handleOpen={!!singlePackageDetails}
      handleClose={() => setSinglePackageDetails(null)}
      title={name}
      customStyling={{ width: '540px' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setSinglePackageDetails(null)}
            id="cancelProductModalBtn"
          />
        </div>
      }>
      <div className="flex flex-col gap-4">
        <div className="flex gap-4">
          <div className="flex flex-grow flex-col justify-between">
            <div className="flex max-h-[107px] flex-grow flex-col overflow-auto">
              <label className="text-base font-500 text-primary-500">Package description</label>
              <label className="text-sm text-neutral-800">
                {note || 'No package description found.'}
              </label>
            </div>
            <div className="flex items-center justify-between gap-2">
              <div className="flex flex-col">
                <label className="text-base font-500 text-primary-500">Validity</label>
                <label className="text-sm text-neutral-800">
                  {validity_time_length + ' ' + validity_time_unit}
                </label>
              </div>
              <div className="flex flex-col">
                <label className="text-base font-500 text-primary-500">Valid until</label>
                <label className="text-sm text-neutral-800">
                  {validUntil ? validUntil : 'N/A'}
                </label>
              </div>
              <div className="flex flex-col">
                <label className="text-base font-500 text-primary-500">Status</label>
                <label className={`text-sm ${statusColors[status]}`}>{capitalize(status)}</label>
              </div>
            </div>
          </div>
          <div className="!w-[182px]">
            <label className="text-base font-500 text-primary-500">Image</label>
            {image ? (
              <img
                className="h-[143px] w-[182px] rounded-[4px]"
                src={imagePath(JSON.parse(image).jpg)}
                alt="Package Image"
              />
            ) : (
              <Icon icon="no-img" />
            )}
          </div>
        </div>
        {ia(procedures) ? (
          <div>
            <label className="text-base font-500 text-primary-500">Services</label>
            <div className="flex flex-col gap-3">
              {procedures.map((procedure) => (
                <div className="!rounded-lg bg-primary-50 !p-4" key={procedure?.id}>
                  <h3 className="pb-3 text-base font-500 text-neutral-800">{procedure?.name}</h3>
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Unit price</label>
                      <label className="text-sm text-neutral-800">
                        {mString(procedure.amount_cents)}
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Quantity</label>
                      <label className="text-sm text-neutral-800">{procedure.quantity}</label>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Total</label>
                      <label className="text-sm text-neutral-800">
                        {mString(procedure.total_amount_cents)}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {ia(products) ? (
          <div>
            <label className="text-base font-500 text-primary-500">Products</label>
            <div className="flex flex-col gap-3">
              {products.map((product) => (
                <div className="!rounded-lg bg-primary-50 !p-4" key={product.id}>
                  <h3 className="pb-3 text-base font-500 text-neutral-800">{product.name}</h3>
                  <div className="flex items-center justify-between">
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Unit price</label>
                      <label className="text-sm text-neutral-800">
                        {mString(product.amount_cents)}
                      </label>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Quantity</label>
                      <label className="text-sm text-neutral-800">{product.quantity}</label>
                    </div>
                    <div className="flex flex-col">
                      <label className="text-sm font-500 text-neutral-600">Total</label>
                      <label className="text-sm text-neutral-800">
                        {mString(product.total_amount_cents)}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : null}

        <div className="flex items-center justify-between rounded-lg bg-primary-50 !p-4">
          <label className="text-base font-600 text-neutral-800">Total package price</label>
          <label className="text-base font-600 text-neutral-800">
            {mString(total_amount_cents)}
          </label>
        </div>
      </div>
    </Modal>
  );
};

export default PreviewInvoicePackageModal;
