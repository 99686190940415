export const CATEGORY_CREATED_SUCCESS_MESSAGE = 'Category created successfully!';
export const CATEGORY_DELETED_SUCCESS_MESSAGE = 'Category deleted successfully!';
export const CATEGORY_DELETED_ERROR_MESSAGE = "You're not authorized to delete categories!";

export const SERVICE_CREATED_SUCCESS_MESSAGE = 'Service created successfully!';
export const SERVICE_UPDATED_SUCCESS_MESSAGE = 'Service updated successfully!';
export const SERVICE_DELETED_SUCCESS_MESSAGE = 'Service deleted successfully!';
export const SERVICE_CREATED_ERROR_MESSAGE = 'Something went wrong while creating service!';
export const SERVICE_UPDATED_ERROR_MESSAGE = 'Something went wrong while updating service!';

export const SERVICE_QUERY_KEY = 'services';
