import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const PaymentMethodBox = ({
  show,
  icon,
  label,
  setShow,
  paymentMethod,
  setTemporarySurchargeDisable
}) => {
  return (
    <div
      data-qa={`payment-method-${label.toLowerCase()}`}
      className={cs(
        'cursor-pointer !rounded-lg border border-solid border-neutral-300 bg-white px-4 py-3',
        show.selectedPaymentOption === paymentMethod && '!border-primary-500 !bg-primary-50'
      )}
      onClick={() => {
        setTemporarySurchargeDisable(false);
        setShow({ ...show, selectedPaymentOption: paymentMethod });
      }}>
      <Icon
        icon={icon}
        className="!mb-4 cursor-pointer"
        color={show.selectedPaymentOption === paymentMethod && 'primary'}
      />

      <label
        className={cs(
          'min-h-[44px] cursor-pointer p-0 text-sm text-primary-900',
          show.selectedPaymentOption === paymentMethod && '!text-primary-500'
        )}>
        {label}
      </label>
    </div>
  );
};

export default PaymentMethodBox;
