import React, { useMemo } from 'react';
import Header from 'components/shared/Header/Header';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useInstantPacket } from 'lib/hooks/queries/useInstantPacket';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import usePageTitle from 'lib/hooks/usePageTitle';
import { DEFAULT_FILTERS, defaultColumns, sendLink } from './lib/helper';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useForms } from 'lib/hooks/queries/forms/useForms';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useNavigate } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { statuses } from './lib/constants';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const FormPacket = () => {
  const practice = useRecoilValue(currentPractice);

  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns(practice?.timezone)}
      name="form-packets-queue"
      pagination>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Patient Forms Queue');
  const category = 'form-packets-queue';
  const navigate = useNavigate();
  const { limit, page, setPage, sort, filters, setFilters, selectedRows, setSelectedRows } =
    useTableContext();
  const practice = useRecoilValue(currentPractice);

  const { data, isLoading, isFetching } = useInstantPacket({
    params: {
      withAl: { withAllForms: true, withPatient: true, withProvider: true },
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const { data: providersList } = useProviders({});

  const { data: formsList } = useForms({});

  const providerOptions = useMemo(() => {
    if (providersList?.practitioners?.length > 0) {
      return providersList.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providersList]);

  const formOptions = useMemo(() => {
    if (formsList?.forms?.length > 0) {
      return formsList.forms.map((f) => ({
        label: f?.name,
        value: f?.id
      }));
    }
    return [];
  }, [formsList]);

  const statusesOptions = useMemo(() => {
    return statuses.map((status) => ({
      label: status.label,
      value: status.value
    }));
  }, []);

  filters.forms.options = formOptions;

  filters.providers.options = providerOptions;

  filters.statuses.options = statusesOptions;

  const options = [
    {
      label: 'Resend Link',
      icon: 'calendar-tick',
      children: [
        {
          label: 'Email',
          icon: 'new-email-blue',
          onClick: () =>
            sendLink({
              type: 'email',
              navigate,
              packets: selectedRows,
              setSelectedRows
            })
        },
        {
          label: 'Text Message',
          icon: 'text-message',
          onClick: () =>
            sendLink({
              type: 'text',
              navigate,
              packets: selectedRows,
              setSelectedRows
            })
        }
      ]
    }
  ];

  const packets = data?.instant_packets || [];
  const count = data?.count || 0;

  return (
    <>
      <Header title="Patient Forms">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <Popover
              className="flex flex-1 justify-center self-center"
              buttonClassName="!bg-primary-700"
              position="left"
              isFixed
              options={options}
            />
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={packets}
              rowSelection="multiple"
              columnDef={defaultColumns(practice?.timezone)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
}

export default withErrorBoundary(FormPacket);
