import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import adminState from '../../lib/adminState';
import Settings from './index';
import { showAlert } from 'components/shared/Alert/Alert';
import { set } from 'lodash';
const FormValidators = require('../../lib/validation/validate_admin_update');
const validateUpdate = FormValidators.validateUpdate;

export default function SettingsContainer() {
  const user = useRecoilState(adminState.current_admin);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({
    id: '',
    f_name: '',
    l_name: '',
    email: ''
  });
  const [updatedAdmin, setUpdatedAdmin] = useState({});
  const [buttonCopy, setButtonCopy] = useState('Update');

  const [errors, setErrors] = useState({
    f_name: {
      success: false,
      message: ''
    },
    l_name: {
      success: false,
      message: ''
    },
    email: {
      success: false,
      message: ''
    },
    password: {
      success: false,
      message: ''
    }
  });

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    let params = {
      id: user[0].user_id
    };
    try {
      let res = await requestApi({
        url: '/api/admin/get',
        params,
        navigate
      });
      const { admin: loadedAdmin } = res;
      if (res.admin) {
        setAdmin({
          ...admin,
          ...loadedAdmin
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleChange = (event) => {
    const field = event.name;
    const value = event.value;
    setAdmin({ ...admin, [field]: value });
    setUpdatedAdmin({ ...updatedAdmin, [field]: value });
  };

  const submitChanges = async (admin) => {
    try {
      const onSuccess = () => {
        showAlert({ title: 'Admin updated successfully', color: 'primary' });
        setLoading(false);
        setButtonCopy('Saved');
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
        setLoading(false);
      };
      setLoading(true);
      await requestApi({
        url: '/api/admin/update',
        params: {
          admin: { ...updatedAdmin, id: user[0].user_id }
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let payload = validateUpdate(admin);
    if (payload.success) {
      setErrors({
        f_name: {
          success: false,
          message: ''
        },
        l_name: {
          success: false,
          message: ''
        },
        email: {
          success: false,
          message: ''
        }
      });
      submitChanges(admin);
    } else {
      const errors = payload.errors;

      setErrors({
        f_name: {
          success: false,
          message: errors.f_name
        },
        l_name: {
          success: false,
          message: errors.l_name
        },
        email: {
          success: false,
          message: errors.message
        }
      });
    }
  };

  return (
    <Settings
      onSubmit={validateForm}
      onChange={handleChange}
      errors={errors}
      admin={admin}
      buttonCopy={buttonCopy}
      loading={loading}
    />
  );
}
