import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getServices } from '../../../../api/Service';
import { useState } from 'react';

export const useServices = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['services', ...dependencies], () => getServices(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useServicesSearch = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    searchTerm: '',
    page: 1,
    limit: 10000,
    column: 'name',
    sort: 'asc'
  });

  const query = useQuery(
    ['services', filters.searchTerm, filters.page, filters.limit],
    () => getServices(navigate, { ...params, filters }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );

  const services = query?.data?.services;
  const serviceCount = query?.data?.serviceCount;

  return { ...query, filters, setFilters, serviceCount, services };
};
