import React, { useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import { currentPractice } from 'components/practice/practiceState';

import { userState } from '../../../state';

import InstantPacket from './InstantPacket/InstantPacket';
import List from './List';
import View from './View';
import getDocumentFilters from './lib/defaultFilters';
import documentTableCols from './lib/documentTableCols';

export default function Documents({ fromPatient }) {
  const currentUser = useRecoilValue(userState);

  const { patient } = useOutletContext() || {};
  const practice = useRecoilValue(currentPractice);

  const patientId = patient?.id || currentUser?.id;

  const exportRef = useRef();

  const print = useReactToPrint({
    content: () => exportRef.current,
    documentTitle: `hipaa-${patient ? patient?.f_name + ' ' + patient?.l_name : 'Patient'}.pdf`
  });

  const [viewDocument, setViewDocument] = useState(null);

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showInstantPacket, setShowInstantPacket] = useState(false);

  const handleCloseModal = () => {
    setViewDocument(null);
    setIsEditOpen(false);
  };

  const { data: practitionersData } = usePractitioners({});
  const practitioners = practitionersData?.practitioners || [];

  return (
    <div className="flex h-full flex-col">
      <TableContextProvider
        defaultFilters={getDocumentFilters(practitioners, patient)}
        cols={documentTableCols({
          setViewDocument,
          isEditOpen,
          setIsEditOpen,
          fromPatient,
          patientId,
          practice
        })}
        name="patient_documents"
        pagination>
        <List
          patientId={patientId}
          patient={patient}
          fromPatient={fromPatient}
          setViewDocument={setViewDocument}
          practitioners={practitioners}
          setShowInstantPacket={setShowInstantPacket}
        />

        {viewDocument && (
          <View
            exportRef={exportRef}
            viewDocument={viewDocument}
            setViewDocument={setViewDocument}
            handleCloseModal={handleCloseModal}
            action={viewDocument.action}
            patientId={patientId}
            handle={viewDocument.handle}
            type={viewDocument.type}
            print={print}
          />
        )}
      </TableContextProvider>
      {showInstantPacket && <InstantPacket setShowInstantPacket={setShowInstantPacket} />}
    </div>
  );
}
