import React, { useState } from 'react';
import CreateMacro from './Create/CreateMacro';
import MacrosSearch from './components/MacroSearch';
import MacrosActions from './components/MacroActions';
import MacrosTypes from './components/MacroTypes';
import MacrosList from './components/MacroList';
import { useMacrosContext } from '../../../../../../lib/context/MacrosContext/MacrosContext';
import { defaultMacroFilters } from './Create/lib/constants';
import DeleteMacros from './Delete/DeleteMacros';
import EditMacro from './Edit/EditMacro';

const MACRO_TYPES = ['appointment_macro', 'note_macro', 'form_macro', 'text_macro'];

const Macros = () => {
  const { filters, setFilters } = useMacrosContext();
  const [createMacroModal, setCreateMacroModal] = useState(false);
  const hasMacroType = filters?.macroType;
  const macroTitle = filters?.macroType?.replace('_', ' ');

  return (
    <div className="h-[calc(100vh-169px)] relative flex overflow-hidden shrink-0 flex-col !py-2 !px-2">
      {hasMacroType && (
        <>
          <MacrosActions
            macroTitle={macroTitle}
            goBack={() => {
              setFilters({ ...defaultMacroFilters });
            }}
            openCreateModal={() => setCreateMacroModal(true)}
          />

          <MacrosSearch filters={filters} setFilters={setFilters} />
        </>
      )}

      <MacrosTypes
        MACRO_TYPES={MACRO_TYPES}
        hasMacroType={hasMacroType}
        filters={filters}
        setFilters={setFilters}
      />

      <MacrosList filters={filters} hasMacroType={hasMacroType} />

      <CreateMacro
        macroType={filters?.macroType}
        open={createMacroModal}
        handleClose={() => setCreateMacroModal(false)}
        macroTitle={macroTitle}
      />

      <EditMacro />

      <DeleteMacros />
    </div>
  );
};

export default Macros;
