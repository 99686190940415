// import { useRecoilValue } from 'recoil';
// import StaffMember from 'components/Staff/components/StaffMember';
// import practiceState from 'components/practice/practiceState';
import { ia } from 'lib/helpers/utility';

import NewInsuranceProfile from 'components/practice/charts/InsuranceProfile/NewInsuranceProfile';

import InsuranceProfileEdit from './InsuranceProfileEdit';
import PayerEdit from './PayerEdit';

const UpdateModal = ({ modalData, setModalData, patientId, practitionerId, practiceId }) => {
  //   const currentPractice = useRecoilValue(practiceState.currentPractice);

  const closeHandler = () => {
    setModalData(null);
  };

  switch (modalData.view) {
    // case 'provider':
    //   return (
    //     <StaffMember
    //       onClose={closeHandler}
    //       handleOpen={true}
    //       member={modalData.data}
    //       practiceId={currentPractice.id}
    //     />
    //   );
    case 'insuranceProfile':
      return ia(modalData.data) ? (
        <InsuranceProfileEdit insuranceProfiles={modalData.data} closeHandler={closeHandler} />
      ) : (
        <NewInsuranceProfile
          newProfile={() => {}}
          patientId={patientId}
          practitionerId={practitionerId}
          practiceId={practiceId}
          showNewInsuranceProfile
          hideNewInsuranceProfile={closeHandler}
          insuranceProfiles={modalData.data}
        />
      );
    case 'payer':
      return <PayerEdit payers={modalData.data} closeHandler={closeHandler} />;
    default:
      return null;
  }
};

export default UpdateModal;
