import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getClaimMdHistory } from '../../../../api/Claim';

export const useClaimMdHistory = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => getClaimMdHistory(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
