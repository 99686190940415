import React from 'react';

import cs from 'classnames';

const Wrapper = ({ children, isColumn = false }) => {
  return (
    <div className="flex flex-col gap-6">
      <div
        className={cs('flex gap-4', {
          'flex-col': isColumn,
          'items-start': !isColumn
        })}>
        {children}
      </div>
    </div>
  );
};

export default Wrapper;
