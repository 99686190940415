import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { camelCaseToReadableFormat, io, pluralize, singularize } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { exportTableToCSV, printTable } from 'components/shared/AGTable/lib/helpers';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Popover from 'components/shared/Popovers/Popover/Popover';
import ToggleSwitch from 'components/shared/ToggleSwitch/ToggleSwitch';
import Typography from 'components/shared/Typography/Typography';

import { checkActiveItem } from '../lib/checkActiveItem';
import { SPECIAL_UPPERCASE_NAMES } from '../lib/constants';
import { ALLOWED_PRINT_EXPORT_CATEGORIES } from '../lib/helpers';

const TableHeader = ({
  category,
  onModalToggle,
  hasNew,
  subtitle,
  actions,
  refresh,
  onRefresh,
  noTitle,
  switchItems,
  title,
  count,
  rightActions,
  loading
}) => {
  const { filters, setFilters, defaultFilters, gridApi, pagination, setPage, ...rest } =
    useTableContext();
  const navigate = useNavigate();
  const location = useLocation();
  const practice = useRecoilValue(currentPractice);
  const { device } = useUIContext();

  const isMobile = device === 'mobile' || device === 'tablet-sm';
  const isTablet = device === 'tablet' || device === 'tablet-lg';
  const isSmallerDevice = isMobile || isTablet;

  const activeItem = useMemo(
    () => checkActiveItem(switchItems, filters, location),
    [switchItems, filters, location]
  );
  const onFilterChange = (callback) => {
    setPage(1);
    setFilters(callback);
  };

  const totalSelected = useMemo(() => {
    return rest.selectedRows?.length;
  }, [rest.selectedRows]);

  const categoryName = SPECIAL_UPPERCASE_NAMES.includes(category)
    ? category.toUpperCase()
    : camelCaseToReadableFormat(category);

  const onSwitchItemClick = (item) => {
    if (item.navigate) {
      navigate(item.navigate);
      return;
    }

    const statusValues = item?.value
      ? [{ label: item.label, value: item.value.toLowerCase() }]
      : [];

    setFilters((prev) => ({
      ...prev,
      ...(prev.status && {
        status: {
          ...prev.status,
          values: statusValues
        }
      })
    }));
  };

  const printExportConfig = useMemo(() => ALLOWED_PRINT_EXPORT_CATEGORIES[category], [category]);

  const onExportToCSV = useCallback(() => {
    exportTableToCSV(gridApi, categoryName);
  }, [gridApi, categoryName]);

  const onPrint = useCallback(() => {
    printTable({ gridApi, categoryName, withTotals: printExportConfig?.withTotals, practice });
  }, [gridApi, categoryName, printExportConfig, practice]);

  const smallDeviceOptions = [
    isMobile &&
      hasNew && {
        label: `New ${singularize(categoryName)}`,
        onClick: onModalToggle,
        icon: 'add-square',
        color: 'primary'
      },
    isSmallerDevice &&
      pagination && {
        component: (
          <DisplayButton
            btnClassName="border-none !px-0 max-h-[20px] hover:bg-transparent !bg-transparent"
            textClassName="text-primary-900 font-normal"
            color="primary"
          />
        )
      },
    isSmallerDevice &&
      io(filters) && {
        component: (
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            alwaysShow
            textClassName="text-primary-900 font-normal"
            btnClassName="border-none !px-0 max-h-[20px] hover:bg-transparent !bg-transparent"
          />
        )
      }
  ].filter(Boolean);

  const options = [
    ...smallDeviceOptions,
    {
      label: 'Export',
      onClick: onExportToCSV,
      icon: 'new-export-v2',
      color: 'primary',
      disabled: !printExportConfig?.export
    },
    {
      label: 'Print',
      onClick: onPrint,
      icon: 'new-printer',
      color: 'primary',
      disabled: !printExportConfig?.print
    }
  ];

  return (
    <Header
      title={!noTitle && (title || categoryName)}
      subtitle={subtitle}
      childrenClassName="flex items-center gap-3 justify-between flex-1">
      <div className="w-full">
        {switchItems && (
          <ToggleSwitch
            items={switchItems}
            onClick={onSwitchItemClick}
            active={activeItem?.value || null}
            count={Number(count) || undefined}
            loading={loading}
          />
        )}
      </div>
      <div className="flex items-center gap-3 justify-end flex-1">
        {typeof actions === 'function'
          ? actions({ category, filters, loading, setFilters, ...rest })
          : actions}
        {totalSelected > 0 && actions && (
          <Typography color="neutral-600" size="small">
            {totalSelected} {pluralize('item', totalSelected)} selected
          </Typography>
        )}
        {!isSmallerDevice && refresh && (
          <Tippy content="Refresh" placement="bottom" theme="dark">
            <Button
              icon="new-refresh-arrows"
              outlined
              color="neutral"
              iconIsStroke
              onClick={onRefresh}
            />
          </Tippy>
        )}
        {!isSmallerDevice && pagination && <DisplayButton />}
        {!isSmallerDevice && io(filters) && (
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={onFilterChange}
            menuPortalTarget={document.body}
          />
        )}
        {!isMobile && hasNew && (
          <Button
            text={`New ${singularize(categoryName)}`}
            icon="add-square"
            size="small"
            transparent
            iconColor="white"
            onClick={onModalToggle}
            textClassName="whitespace-nowrap"
          />
        )}
      </div>
      {typeof rightActions === 'function'
        ? rightActions({ category, loading, filters, setFilters, ...rest })
        : rightActions}
      {printExportConfig?.show && (
        <Popover
          options={options}
          position="left"
          icon="new-context-menu-horizontal"
          optionIconColor="neutral"
          buttonTheme="neutral-700"
          buttonClassName={cs(
            'border border-solid border-neutral-200',
            'hover:bg-neutral-50 hover:border-neutral-300 cursor-pointer'
          )}
          activeClassNames={{
            button: 'border-neutral-500 bg-white',
            icon: 'neutral-700'
          }}
        />
      )}
    </Header>
  );
};

export default TableHeader;
