import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';

const NewFilterItem = ({ saveInput, setSaveInput, setSaveButton, save }) => (
  <div className="flex w-[230px] flex-col gap-2 !p-3">
    <Input
      value={saveInput}
      label="Filter name"
      data-qa="filter-name"
      onChange={(e) => setSaveInput(e.target.value)}
      placeholder="Enter filter name"
    />
    <div className="flex justify-between pt-2">
      <Button
        onClick={() => setSaveButton(false)}
        text="Close"
        size="small"
        data-qa="close"
        outlined
        color="neutral"
      />
      <Button onClick={save} text="Save" size="small" icon="new-diskette" data-qa="save" />
    </div>
  </div>
);

export default NewFilterItem;
