import React from 'react';
import { Link } from 'react-router-dom';

import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';

import { ia, mString, pm } from '../utility';

export default function formatAppointment({
  appointments,
  currentView,
  permissions,
  appointmentStatuses
}) {
  const showValues = pm(permissions, 'payments.read');
  if (!ia(appointments)) {
    return [];
  }

  const formatted = appointments.map((appointment, index) => {
    const startsAt = new Date(appointment.starts_at);
    const endsAt = new Date(appointment.ends_at);
    const resource_id = ia(appointment?.resources) ? appointment?.resources[0]?.id : null;
    return appointment.formatted
      ? appointment
      : {
          formatted: true,
          id: appointment.id,
          resourceId: currentView === 'provider' ? appointment.practitioner.id : resource_id,
          appointment,
          title: (
            <div data-qa={`appointment-${index}`}>
              {currentView === 'agenda' ? (
                <div className="relative" data-dd-privacy="allow" data-public>
                  <div className="customAgendaEvent">
                    <div>{appointment.patient?.fullName}</div>
                    <div>{appointment?.invoice?.procedures_label}</div>
                    <Allowed requiredPermissions="payments.read">
                      <div>{mString(appointment?.invoice?.balance || 0)}</div>
                    </Allowed>
                    <div>{appointment?.practitioner?.fullName}</div>
                    {
                      appointmentStatuses.find((status) => status.value === appointment?.status)
                        ?.label
                    }
                  </div>
                  <Link
                    className="customAgendaEvent__user"
                    to={`/portal/charts/${appointment.patient.id}`}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank">
                    <Icon icon="user" className="cursor-pointer" />
                  </Link>
                </div>
              ) : (
                <div data-dd-privacy="allow" data-public>
                  {appointment.patient?.fullName} | {appointment?.invoice?.procedures_label}{' '}
                  {showValues ? `- ${mString(appointment?.invoice?.balance || 0)} |` : ''}{' '}
                  {appointment?.practitioner?.fullName} |{' '}
                  {
                    appointmentStatuses.find((status) => status.value === appointment?.status)
                      ?.label
                  }
                </div>
              )}
            </div>
          ),
          starts_at: startsAt,
          ends_at: endsAt,
          type: appointment.type,
          eventType: 'appointment'
        };
  });
  return formatted;
}
