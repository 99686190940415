import { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';

import Select from 'components/shared/Select/Select';

import { priorityOptions } from '../../lib/constants';

const PriorityCellRenderer = ({ data, node }) => {
  const { values } = useFormikContext();
  const testIndex = values.tests.findIndex((test) => test.id === data.id);

  const [field, , { setValue }] = useField({
    name: `tests.${testIndex}.status`,
    required: true
  });

  useEffect(() => {
    if (!field.value) {
      changeHandler(priorityOptions[0]);
    } else if (typeof field.value === 'string') {
      const opt = priorityOptions.find((option) => option.value === field.value);
      changeHandler(opt);
    }
  }, []);

  const changeHandler = (option) => {
    setValue(option);
    node.setDataValue('status', option);
  };

  return (
    <div className="flex items-center h-full">
      <Select
        {...field}
        isClearable={false}
        menuPortalTarget={document.body}
        options={priorityOptions}
        className="leading-none"
        parentClassName="w-full"
        onChange={changeHandler}
      />
    </div>
  );
};

export default PriorityCellRenderer;
