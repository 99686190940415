import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { getDashboardClaims, getDashboardOutstandingPaymnets, getDashboardSms } from 'api/Practice';
import moment from 'moment';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Card from 'components/shared/Card/Card';
import usePageTitle from 'lib/hooks/usePageTitle';
import { getVolume } from 'api/Admin/Dashboard';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { mString } from 'lib/helpers/utility';
ChartJS.register(ArcElement, Tooltip, Legend);

const curr = new Date();
const first = curr.getDate() - curr.getDay();
const last = first + 6;
const firstday = new Date(curr.setDate(first)).toUTCString();
const lastday = new Date(curr.setDate(last)).toUTCString();
const fullDate = `${moment(firstday).format('MMM DD')} - ${moment(lastday).format('MMM DD')}`;

const PracticeOverviewContainer = () => {
  const [appointmentsCount, setAppointmentsCount] = useState({
    total: 0,
    approved: 0,
    canceled: 0,
    confirmed: 0
  });
  const [totalMessages, setTotalMessages] = useState(0);
  const [messages, setMessages] = useState(null);
  const [totalClaims, setTotalClaims] = useState(0);
  const [claims, setClaims] = useState(null);
  const [totalReducedCosts, setTotalReducedCosts] = useState('0.00');
  const [HSP, setHSP] = useState({ Billing_costs: '0.00', Potential_AR: '0.00' });
  const [totalOutstandingPayments, setTotalOutstandingPayments] = useState(0.0);
  const [outstandingPayments, setOutstandingPayments] = useState({
    Patients: 0,
    Payers: 0
  });
  const [dataChartReducedCosts, setData] = useState([
    { angle: 1, color: '#017EFA' },
    { angle: 1, color: '#51CBFF' }
  ]);
  const [dataChartOutstandingPayments, setDataChartOutstandingPayments] = useState([
    { angle: 1, color: '#017EFA' },
    { angle: 1, color: '#51CBFF' }
  ]);
  const [volume, setVolume] = useState(0);
  const navigate = useNavigate();
  const { practice } = useOutletContext();
  usePageTitle(`${practice?.name} Overview`);

  useEffect(() => {
    if (practice) {
      getData();
    }
  }, [practice]);

  const getData = async () => {
    const msgData = await getDashboardSms(navigate, { practiceId: practice?.id });
    setTotalMessages(msgData?.total);
    setMessages({ sent: msgData?.sent, received: msgData?.received });
    const claimsData = await getDashboardClaims(navigate, { practiceId: practice?.id });
    setTotalClaims(claimsData?.total);
    setClaims({ accepted: claimsData?.accepted, rejected: claimsData?.rejected });
    const outstandingPayments = await getDashboardOutstandingPaymnets(navigate, {
      practiceId: practice?.id
    });
    setTotalOutstandingPayments(outstandingPayments?.total);
    setOutstandingPayments({
      patients: outstandingPayments?.patients,
      payers: outstandingPayments?.payers
    });
    setDataChartOutstandingPayments([
      { angle: outstandingPayments?.patients, color: '#017EFA' },
      { angle: outstandingPayments?.payers, color: '#51CBFF' }
    ]);
    const volumeData = await getVolume(navigate, {
      practiceId: practice?.id,
      startDate: null,
      endDate: null
    });
    setVolume(volumeData?.processingVolume || { total: 0 });
  };

  const getChartData = (chart) => {
    return {
      backgroundColor: chart.map((c) => c.color),
      data: chart.map((c) => c.angle),
      borderWidth: 0
    };
  };

  const radialChartOptions = {
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: false
      },
      tooltip: {
        enabled: false
      }
    },
    responsive: true,
    cutout: 28
  };

  return (
    // <div className="shadow-default relative flex flex-wrap items-center justify-between gap-20 !rounded-xl bg-white !py-2 !pr-4 !pl-14">
    <div className="p-[1rem] grid grid-cols-3 grid-rows-2 gap-4">
      <Card
        title="Processing Volume"
        theme="greenLight"
        headLine
        icon="new-cash"
        position="1/3"
        shortData={`Total: ${mString(volume)}`}
        // data={volume}
        loadingBasedOn={volume}
      />
      <Card
        title="Text Messages"
        theme="yellow"
        headLine
        icon="chat"
        position="1/1"
        shortData={`Total: ${totalMessages}`}
        data={messages}
        loadingBasedOn={messages}
      />
      {/* {practice?.display_settings?.claims && ( */}
      <Card
        title="Claims"
        theme="purple"
        headLine
        icon="claims"
        position="1/2"
        shortData={`Total: ${totalClaims}`}
        data={claims}
        loadingBasedOn={claims}
      />
      {/* )} */}
      <Card
        title="Outstanding Payments"
        theme="blue"
        chart={
          <Doughnut
            style={{ width: 86, height: 86 }}
            options={radialChartOptions}
            data={{ datasets: [getChartData(dataChartOutstandingPayments)] }}
          />
        }
        position="2/3"
        shortData={`Total: ${totalOutstandingPayments}`}
        data={outstandingPayments}
        loadingBasedOn={practice}
      />
    </div>
  );
};

export default withErrorBoundary(PracticeOverviewContainer);
