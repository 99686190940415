import React, { useRef } from 'react';
import Modal from 'components/shared/Modal/Modal';
import { ReactFormGenerator } from 'react-form-builder2';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import Button from 'components/shared/Buttons/Button';
import { useReactToPrint } from 'react-to-print';

const ViewForm = ({ form, setViewFormModal, viewFormModal }) => {
  const { device } = useUIContext();
  const printRef = useRef();

  const closeViewFormModal = () => {
    setViewFormModal(false);
  };

  const print = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `${form?.name}`
  });

  return (
    <Modal
      slideFromRight
      title={form?.name}
      isOpen={viewFormModal}
      headButton={
        <div className="flex w-full justify-end gap-2 pr-2">
          <Button
            type="secondary"
            icon="new-printer"
            color="neutral"
            size="small"
            text="Print"
            onClick={print}
          />
        </div>
      }
      handleClose={closeViewFormModal}
      className={cs(
        '!bg-white',
        device === 'tablet-sm' ? 'w-[729px]' : device === 'tablet' ? 'w-[984px]' : 'w-[1240px]'
      )}>
      <div
        className="print:!page-break-before relative box-border flex h-full w-full flex-col px-2"
        ref={printRef}>
        <div className="cursor-not-allowed px-2 content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30">
          <ReactFormGenerator
            data={form?.json?.fields || []}
            read_only
            answer_data={null}
            hide_actions
          />
        </div>
      </div>
    </Modal>
  );
};

export default ViewForm;
