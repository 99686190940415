import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTag } from 'api/Tags';
import { ia, iaRa } from 'lib/helpers/utility';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { selectCustomStyle } from '../../lib/selectCustomStyle';
import TagCreate from '../Create';
import Select from '../../../Select/Select';
import { showAlert } from '../../../Alert/Alert';
import { TagOption } from './components/TagOption';
import Allowed from '../../../Permissions/Allowed';
import Confirm from '../../../Modal/Confirm/Confirm';
import { CreateLabel } from './components/CreateLabel';
import { TagMultiValue } from './components/TagMultiValue';
import { withErrorBoundary } from '../../../Error/Boundary';

const TagSelect = ({
  currTags,
  kind,
  setTagIds = () => {},
  label = 'Tags',
  parentClassName,
  className,
  resetTags = false,
  styles,
  actionButtonText = null,
  actionButtonIcon = null,
  ...rest
}) => {
  const [selectedTag, setSelectedTag] = useState([]);
  const { data, isLoading } = useTags({ params: { kind }, dependencies: kind });
  const tags = data?.tags;
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentTagId, setCurrentTagId] = useState(null);
  const [tagName, setTagName] = useState('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectRef = useRef(null);

  useEffect(() => {
    if (resetTags) setSelectedTag([]);
  }, [resetTags]);

  useEffect(() => {
    if (ia(currTags)) setSelectedTag(currTags);
  }, [currTags]);

  const openCreateModal = () => setIsCreateModal(true);
  const closeCreateModal = () => {
    setIsCreateModal(false);
    setTagName('');
  };

  const openConfirmModal = () => setIsConfirmModal(true);
  const closeIsConfirmModal = () => {
    setIsConfirmModal(false);
    setTagName('');
  };

  const { mutateAsync: mutateDeleteTag, isLoading: isDeleting } = useMutation({
    mutationFn: (data) => deleteTag(navigate, data)
  });

  const addNewTagFromCreated = (newTag) => {
    setSelectedTag([...selectedTag, newTag]);
    const tagIds = selectedTag.map((tag) => tag.id);
    const concatWithNewTagId = [...tagIds, newTag.id];
    setTagIds(concatWithNewTagId);
    if (selectRef.current) {
      selectRef.current.focus();
    }
  };

  const handleOnChange = (tag) => {
    setSelectedTag(tag);
    const tagIds = tag.map((tag) => tag.id);
    setTagIds(tagIds);
  };

  const formatOption = (props) => <TagOption {...props} onRemove={handleRemoveOption} />;

  const formatMultiValueContainer = (props) => {
    const handleRemove = (event) => {
      event.stopPropagation();
      const removedTag = props.selectProps.value.find((tag) => tag.value === props.data.value);
      const updatedValue = props.selectProps.value.filter((tag) => tag.value !== props.data.value);
      props.selectProps.onChange(updatedValue, {
        action: 'remove-value',
        removedValue: removedTag
      });
    };

    return <TagMultiValue {...props} handleRemove={handleRemove} />;
  };

  const formatCreateLabel = (inputValue) => (
    <CreateLabel inputValue={inputValue} onClick={openCreateModal} />
  );

  const handleRemoveOption = (value, event, id) => {
    event.stopPropagation();
    openConfirmModal();
    if (id) setCurrentTagId(id);
  };

  const handleTagDelete = async () => {
    await mutateDeleteTag(
      { tagIds: [currentTagId] },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['tags']);
          showAlert({ title: 'Tag deleted successfully!', color: 'success' });
          closeIsConfirmModal();
        }
      }
    );
  };

  const onCreateOption = (inputValue) => {
    const existingOption = iaRa(tags).find((option) => option.value === inputValue);
    if (!existingOption) {
      if (typeof inputValue === 'string') {
        setTagName(inputValue);
      }
      openCreateModal();
    }
  };

  return (
    <>
      <Allowed requiredPermissions="tags.read">
        <Select
          label={label}
          parentClassName={cs('w-full', parentClassName)}
          className={className}
          forwardedRef={selectRef}
          isMulti
          isCreatable
          actionButtonText={actionButtonText}
          actionButtonIcon={actionButtonIcon}
          actionButtonClick={onCreateOption}
          styles={{
            ...selectCustomStyle,
            ...styles
          }}
          placeholder="Search tag"
          inputId="tag-select"
          isClearable={false}
          isLoading={isLoading || isDeleting}
          options={tags || []}
          value={selectedTag}
          onChange={(v) => handleOnChange(v)}
          formatOptionLabel={formatOption}
          formatMultiValueContainer={formatMultiValueContainer}
          formatCreateLabel={formatCreateLabel}
          onCreateOption={onCreateOption}
          {...rest}
        />
      </Allowed>
      <Allowed requiredPermissions="tags.create">
        {isCreateModal && (
          <TagCreate
            isOpen={isCreateModal}
            handleClose={closeCreateModal}
            kind={kind}
            name={tagName}
            addNewTagFromCreated={addNewTagFromCreated}
          />
        )}
      </Allowed>
      <Allowed requiredPermissions="tags.delete">
        <Confirm
          handleOpen={isConfirmModal}
          handleClose={closeIsConfirmModal}
          handleContinue={handleTagDelete}
          title="Delete tag?"
          message="Are you sure you want to delete this tag?"
          variant="danger"
        />
      </Allowed>
    </>
  );
};

export default withErrorBoundary(TagSelect);
