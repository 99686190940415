import React from 'react';
import ReviewSystems from '../../../../../../../shared/Forms/Custom/ReviewSystems/ReviewSystems';
import Accordion from '../../../../shared/Accordion';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const ReviewOfSystems = ({ components, sectionRef, options = [] }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Review Of Systems', parentKey: 'hp', childKey: 'ros' }}
      title={components?.ros?.title}
      id={components?.ros?.id}
      disabled={clinicalNote?.locked}>
      <ReviewSystems
        isClinicalNote
        defaultValue={clinicalNote?.ros}
        sectionRef={sectionRef}
        showNarrative
        options={options}
      />
    </Accordion>
  );
};

export default ReviewOfSystems;
