import React from 'react';

import cs from 'classnames';

import { formatTimeRelativeToToday } from 'lib/helpers/utility';

import Avatar from 'components/shared/Avatar/Avatar';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const NewMessage = ({ newMessages, practice, onNavigate, hasMore, scrollLoading, close }) => {
  return (
    <div>
      {newMessages.map((newMessage, idx) => {
        const { user, userMessages: message, userUnreadMessages } = newMessage;
        return (
          <div
            key={idx}
            onClick={() => onNavigate(user.id, close)}
            className={cs(
              'mr-2 mt-2 flex cursor-pointer items-start gap-[14px] rounded-xl bg-neutral-50 p-2 first:mt-0 hover:bg-primary-50',
              userUnreadMessages > 0 && 'bg-primary-50'
            )}>
            <Avatar fullName={user?.fullName} id={user?.id} image={user?.profile_photo} size="xl" />
            <div className="grid w-full items-center gap-[2px]">
              <div className="flex justify-between">
                <div className="flex items-center gap-1">
                  <p className="max-w-[110px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
                    {`${user?.f_name} ${user?.l_name}`}
                  </p>
                  {userUnreadMessages > 0 && (
                    <div className="flex h-[14px] min-w-[14px] items-center justify-center rounded-full bg-primary-500 p-[2px]">
                      <span className="text-[10px] font-500 text-white">
                        {`${userUnreadMessages > 99 ? '99+' : userUnreadMessages}`}
                      </span>
                    </div>
                  )}
                </div>
                <p className="max-w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs leading-5 text-neutral-600">
                  {formatTimeRelativeToToday(message?.created_at, practice.timezone)}
                </p>
              </div>
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-600">
                {message.body}
              </p>
            </div>
          </div>
        );
      })}
      {hasMore && scrollLoading && (
        <Skeleton count={1} height={50} width="100%" className="!mt-2" />
      )}
    </div>
  );
};

export default NewMessage;
