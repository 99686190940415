import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import SSOAction from 'components/shared/ePrescribe/SSOAction';
import OrderModal from '../OrdersModal';
import { useReferralOrder } from 'lib/hooks/queries/referralOrder/useReferralOrder';
import { deleteReferralOrder } from 'api/ReferalOrder';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { ia } from 'lib/helpers/utility';
import { useNavigate, useParams } from 'react-router-dom';
import { patient } from 'components/shared/ePrescribe/SSOKinds';
import { useNonERXPrescription } from 'lib/hooks/queries/nonERXPrescription/useNonERXPrescription';
import { deleteNonERXPrescription } from 'api/NonERXPrescription';
import { socket } from 'api/Socket';
import { useLabOrder } from 'lib/hooks/queries/labOrder/useLabOrder';
import { deleteLabOrder } from 'api/LabOrder';
import state, { clinicalNote as clinicalNoteState } from 'components/state';
import practiceState from 'components/practice/practiceState';
import { useRecoilValue } from 'recoil';
import ReferralOrder from '../shared/ReferallData';
import NonERXPrescription from '../shared/NonERXPrescription';
import LabOrder from '../shared/LabOrder';

const deleteModalDefaultProps = {
  id: null,
  type: null,
  open: false,
  loading: false
};

const EPrescribe = ({ appointmentId }) => {
  const userState = useRecoilValue(state.userState);
  const practice = useRecoilValue(practiceState.currentPractice);
  const { id } = useParams();

  const handleStartVeradigmSessionSocket = () => {
    const data = {
      appointmentId,
      patientId: id,
      user_id: userState.id,
      practice_id: practice.id
    };
    socket.emit('veradigm-prescribe', { ...data });
  };

  return (
    <SSOAction
      enableEnrollPatient
      patientId={id}
      kind={patient}
      handleStartVeradigmSessionSocket={handleStartVeradigmSessionSocket}
    />
  );
};

const Orders = () => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [navigateToParams, setNavigateToParams] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteModalProps, setDeleteModalProps] = useState(deleteModalDefaultProps);
  const { appointmentId, id: patientId } = useParams();
  const navigate = useNavigate();
  const getOrderParams = (type) => {
    switch (type) {
      case 'referral_order':
        return {
          dependencies: [patientId, appointmentId, clinicalNote?.id],
          params: appointmentId
            ? { clinicalNoteIds: [appointmentId] }
            : { patientId: Number(patientId) },
          options: {
            staleTime: 10000,
            select: ({ data }) => data?.referrals
          }
        };
      case 'non_erx_prescription':
        return {
          dependencies: [patientId, appointmentId, clinicalNote?.id],
          params: appointmentId
            ? { appointmentIds: [appointmentId], withRelations: { provider: true } }
            : { patientIds: [patientId], withRelations: { provider: true } },
          options: {
            staleTime: 10000,
            select: ({ data }) => data?.nonERXPrescription
          }
        };
      case 'lab_order':
        return {
          dependencies: [patientId, appointmentId, clinicalNote?.id],
          params: appointmentId
            ? { appointmentIds: [appointmentId], withRelations: { provider: true, tests: true } }
            : { patientIds: [patientId] },
          options: {
            staleTime: 10000,
            select: ({ data }) => data?.labOrder
          }
        };
      default:
        return {};
    }
  };
  const { data: referralsData, isFetching } = useReferralOrder(getOrderParams('referral_order'));
  const { data: nonERXPrescription, isFetching: nonERXPrescriptionFetching } =
    useNonERXPrescription(getOrderParams('non_erx_prescription'));

  const { data: labOrder, isFetching: labOrderFetching } = useLabOrder(getOrderParams('lab_order'));

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleOnOpenDelete = ({ orderId = null, type = null }) =>
    setDeleteModalProps((prevState) => ({
      ...prevState,
      loading: false,
      open: true,
      type,
      id: orderId
    }));

  const handleOnCloseDelete = () => setDeleteModalProps(deleteModalDefaultProps);

  const queryClient = useQueryClient();
  const deleteReferralOrderMutation = useMutation((data) => deleteReferralOrder(navigate, data), {
    onSuccess: () => {
      showAlert({ color: 'success', message: 'Order Deleted Successfully' });
      queryClient.invalidateQueries(['referralOrder']);
      handleOnCloseDelete();
    },
    onSettled: () => {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
    }
  });

  const deleteNonERXPrescriptionMutation = useMutation(
    (data) => deleteNonERXPrescription(navigate, data),
    {
      onSuccess: () => {
        showAlert({ color: 'success', message: 'Order Deleted Successfully' });
        queryClient.invalidateQueries(['nonERXPrescription']);
        handleOnCloseDelete();
      },
      onSettled: () => {
        setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
      }
    }
  );

  const handleDeleteOrder = async () => {
    setDeleteModalProps((prevState) => ({ ...prevState, loading: true }));
    switch (deleteModalProps?.type) {
      case 'referral_order':
        await deleteReferralOrderMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
      case 'non_erx_prescription':
        await deleteNonERXPrescriptionMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
      case 'lab_order':
        await deleteLabOrderMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
    }
  };

  const deleteLabOrderMutation = useMutation((data) => deleteLabOrder(navigate, data), {
    onSuccess: () => {
      showAlert({ color: 'success', message: 'Order Deleted Successfully' });
      queryClient.invalidateQueries(['labOrder']);
      handleOnCloseDelete();
    },
    onSettled: () => {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
    }
  });

  return (
    <div>
      <div className="!mb-3">
        <EPrescribe {...{ appointmentId }} />
      </div>
      <div className="dashed-top flex justify-end !pb-2">
        <Button
          text="Add new order"
          iconRight="add-circle"
          transparent
          onClick={handleOpen}
          data-qa="add-new-order-btn"
        />
      </div>
      <ReferralOrder
        referralsData={referralsData}
        isFetching={isFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        handleOnOpenDelete={handleOnOpenDelete}
        ableToDelete={true}
      />

      <NonERXPrescription
        nonERXPrescription={nonERXPrescription}
        nonERXPrescriptionFetching={nonERXPrescriptionFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        handleOnOpenDelete={handleOnOpenDelete}
        ableToDelete={true}
      />

      <LabOrder
        labOrder={labOrder}
        labOrderFetching={labOrderFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        handleOnOpenDelete={handleOnOpenDelete}
        ableToDelete={true}
      />
      {!ia(nonERXPrescription) && !ia(referralsData) && !ia(labOrder) && (
        <div className="grid gap-y-4">
          <div className="text-sm font-normal italic leading-snug text-neutral-500">
            No orders added yet.
          </div>
        </div>
      )}

      <Confirm
        variant="danger"
        primaryBtnTxt="Delete"
        title="Delete This Order"
        icon="new-document-remove-red"
        message="Are you sure you want to delete this order?"
        loading={deleteModalProps?.loading}
        handleContinue={handleDeleteOrder}
        handleOpen={deleteModalProps?.open}
        handleClose={() => handleOnCloseDelete()}
      />

      {!!modalOpen && (
        <OrderModal
          modalOpen={!!modalOpen}
          handleClose={handleClose}
          navigateToParams={navigateToParams}
          setNavigateToParams={setNavigateToParams}
        />
      )}
    </div>
  );
};

export default Orders;
