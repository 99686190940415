import React, { useState } from 'react';
import cs from 'classnames';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const ItemList = ({ item, options, setSelectedLength, isProviders, formId, index }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { formik } = usePacketBuilderContext();

  const handleCheck = (id) => {
    const newCheckedStatus = !isChecked;
    setIsChecked(newCheckedStatus);
    if (newCheckedStatus) {
      formik.setFieldValue(options, [...formik.values[options], id]);
      setSelectedLength((prev) => prev + 1);
    } else {
      formik.setFieldValue(
        options,
        formik.values[options]?.filter((item) => item !== id)
      );
      setSelectedLength((prev) => prev - 1);
    }
  };
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div
        className={cs(
          'mb-2 rounded-[10px] border border-solid bg-white p-3',
          isChecked ? 'border-primary-500' : 'border-neutral-100'
        )}>
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-2">
            <Checkbox
              key={item?.id}
              id={index}
              isChecked={formik.values[options]?.includes(item?.id) || isChecked}
              handleClick={() => handleCheck(item?.id)}
            />
            <p className="font-500 text-primary-900">
              {isProviders ? `${item?.f_name} ${item?.l_name}` : `${item?.name}`}
            </p>
            {formId && formId === item?.id && (
              <p className="text-[12px] font-500 text-success-500">Recently Created</p>
            )}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(ItemList);
