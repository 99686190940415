import { requestApi } from './Api';

export const getNonERXPrescription = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/non_erx_prescription/get',
    params
  });

export const upsertNonERXPrescription = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/non_erx_prescription/upsert',
    params,
  });

export const deleteNonERXPrescription = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/non_erx_prescription/delete',
    params,
  });

