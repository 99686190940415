import React from 'react';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import { ia, iaRa } from 'lib/helpers/utility';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const Diagnosis = ({ customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const diagnosis = clinicalNote?.icd_10_codes || [];

  const { data: icd10Data, isFetching } = useICD10Codes({
    dependencies: [...iaRa(diagnosis)],
    params: {
      ids: diagnosis
    },
    options: {
      enabled: ia(diagnosis),
      select: (response) => response?.icd10
    }
  });

  if (customClinicalNote && !ia(diagnosis)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="!pb-3 text-base font-500 text-[#003A4B] print:text-xl">Diagnosis</h3>
      <div className="grid">
        <ul>
          {isFetching ? (
            <Skeleton />
          ) : ia(icd10Data) ? (
            <ICD10Codes icd10Data={icd10Data} />
          ) : (
            <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
              No ICD 10 data
            </p>
          )}
        </ul>
      </div>
    </div>
  );
};

const ICD10Codes = ({ icd10Data = [] }) => {
  return (
    <div>
      <p className="!pb-1 text-base text-neutral-700 first-letter:capitalize">ICD-10 Codes:</p>
      {icd10Data.map((row, idx) => {
        return (
          <li
            key={idx}
            className="ml-[14px] list-inside list-disc text-sm font-500 text-[#003A4B] first-letter:capitalize">
            {row?.code} - {row?.name}
          </li>
        );
      })}
    </div>
  );
};

export default Diagnosis;
