import { useMutation, useQueryClient } from '@tanstack/react-query';
import { processAudio } from 'api/MyScribe';
import { AlertContent } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useCheckUnprocessedAudio } from 'lib/hooks/queries/myscribe/useCheckUnprocessedAudio';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { processTranscriptAudioData } from '../lib/deepgramAIHelpers';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const UnprocessedAudio = () => {
  const { clinicalNote } = useClinicalNoteContext();
  const { appointmentId } = useParams();
  const { setShowTranscriptModal, setTranscriptData } = useAmbientListeningContext();
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useCheckUnprocessedAudio({
    params: { appointmentId },
    dependencies: [appointmentId],
    options: {
      enabled: !!appointmentId && !!clinicalNote?.id,
      onSettled: (response) => setShowAlert(!!response?.data?.myscribe)
    }
  });

  const { mutate, isLoading: processAudioLoading } = useMutation({
    mutationFn: (data) => processAudio(navigate, data),
    onSuccess: (response) => {
      if (response?.code !== 0) return;

      processTranscriptAudioData({
        data: response?.data,
        setShowTranscriptModal,
        setTranscriptData
      });

      queryClient.invalidateQueries(['checkUnprocessedAudio']);
    }
  });

  if (!showAlert) return null;

  return (
    <AlertContent
      color="warning"
      title="Pending Audio"
      width="full"
      message="You have audio files awaiting processing. Click the button below to proceed. Please note that processing may take a few minutes."
      customButtons={[
        <Button
          key={appointmentId}
          text="Process Audio"
          color="warning"
          onClick={() => mutate({ appointmentId })}
          disabled={processAudioLoading}
          loadingIcon={processAudioLoading}
        />
      ]}
    />
  );
};

export default UnprocessedAudio;
