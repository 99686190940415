export const recommendedColors = [
  // Neutral
  ['#CDD5D8', '#8FA0A7', '#526268', '#1B2123'], // 300, 500, 700, 900
  // Primary
  ['#54D2FF', '#13B9FF', '#0085B5', '#004F6B'],
  // Secondary
  ['#B5BFF2', '#8191E8', '#1F35AC', '#0A1239'],
  // Success
  ['#86EFAC', '#22C55E', '#15803D', '#14532D'],
  // Warning
  ['#FCD34D', '#F59E0B', '#B45309', '#78350F'],
  // Danger
  ['#1D4ED8', '#EF4444', '#B91C1C', '#7F1D1D']
];
