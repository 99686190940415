import { useRecoilState, useRecoilValue } from 'recoil';
import state from '../../components/state';
import { requestApi } from '../../api/Api';
import { pm } from '../helpers/utility';
import { useNavigate } from 'react-router-dom';
import adminState from '../../components/admin/lib/adminState';

const usePermitted = () => {
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  const user = useRecoilValue(state.userState);
  const admin = useRecoilValue(adminState.current_admin);
  const navigate = useNavigate();

  const getPermissions = async () => {
    if (
      permissions.practice_id ||
      (!(user.logged_in === admin.logged_in) && (user.logged_in || admin.logged_in))
    ) {
      return {};
    }

    const resData = await requestApi({ url: '/api/permissions/get', navigate });

    if (resData?.permissions) {
      setPermissions(resData?.permissions);
      return resData?.permissions;
    }
  };

  const isPermitted = async (perm) => {
    try {
      let p = permissions;
      if (!permissions.loaded) {
        p = await getPermissions();
      }
      return pm(p, perm);
    } catch (error) {
      return false;
    }
  };

  const isAnyPermitted = async (perm) => {
    let p = permissions;
    if (!permissions.loaded) {
      p = await getPermissions();
    }
    return pm(p, perm, 'or');
  };

  return { isPermitted, isAnyPermitted };
};

export default usePermitted;
