import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useDocumentSign } from '../../../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { formatDateAndTimeZ, getInitials, ia } from '../../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../practiceState';

const checkUserLevel = (userLevel, providerUser) => {
  if (userLevel === 1 && ia(providerUser)) {
    return 'Provider';
  } else if (userLevel === 1 && !ia(providerUser)) {
    return 'Patient';
  } else if (userLevel >= 2 && ia(providerUser)) {
    return 'Supervisor Provider';
  }
};

const processData = (response) => {
  if (!ia(response.data)) return [];

  return response.data
    .map((row) => {
      if (row.status !== 'signed') return null;
      return {
        role: checkUserLevel(row.level, row.user?.practiceRole),
        signature: row?.signature,
        signedBy: `Signed by ${row.user?.fullName}`,
        initials: getInitials(row.user?.fullName),
        date: row?.signed_date
      };
    })
    .filter(Boolean);
};

const TreatmentPlanSignatures = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);

  const { data: signaturesDocument } = useDocumentSign({
    params: {
      documentIds: [selectedTreatmentPlan?.id],
      documentStatus: 'signed',
      withRelations: { user: true }
    },
    dependencies: [selectedTreatmentPlan],
    options: {
      staleTime: 3 * 60 * 1000,
      enabled: !!selectedTreatmentPlan?.id,
      select: (response) => processData(response)
    }
  });

  if (!ia(signaturesDocument)) return null;

  return (
    <div>
      <div class="text-base font-medium leading-normal text-neutral-800">Signatures</div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))]">
        {signaturesDocument?.map((row) => {
          return (
            <div className="!mt-3">
              <div class="!mb-2 text-base font-medium leading-normal text-neutral-800">
                {row.role}
              </div>
              <div class=" inline-flex w-[320px] flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 py-6">
                <div class="text-center text-[32px] leading-snug text-black">
                  <img className="size-32 object-contain" src={row.signature} />
                </div>
                <div class="flex flex-col items-center justify-start gap-1.5">
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.signedBy}
                  </div>
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.initials}
                  </div>
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.date ? formatDateAndTimeZ(row.date, practice.timezone) : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TreatmentPlanSignatures;
