import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getClinicalNote } from '../../../../api/ClinicalNote';

export const useClinicalNoteRead = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['clinicalNoteRead', ...dependencies], () => getClinicalNote(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
