module.exports = {
  datapoints_object: {
    allergy: { type: 'Allergy', name: 'Allergy' },
    condition: { type: 'Condition', name: 'Condition' },
    history: { type: 'History', name: 'History' },
    skin_condition: { type: 'SkinCondition', name: 'Skin Condition' },
    eyes_nose_ear_condition: {
      type: 'EyesNoseEarCondition',
      name: 'Eyes Nose Ear Condition'
    },
    mouth_throat_condition: {
      type: 'MouthThroatCondition',
      name: 'Mouth Throat Condition'
    },
    breast_condition: { type: 'BreastCondition', name: 'Breast Condition' },
    cardiovascular_condition: {
      type: 'CardiovascularCondition',
      name: 'Cardiovascular Condition'
    },
    respiratory_condition: {
      type: 'RespiratoryCondition',
      name: 'Respiratory Condition'
    },
    gastrointestinal_condition: {
      type: 'GastrointestinalCondition',
      name: 'Gastrointestinal Condition'
    },
    genitourinary_condition: {
      type: 'GenitourinaryCondition',
      name: 'Genitourinary Condition'
    },
    musculoskeletal_condition: {
      type: 'MusculoskeletalCondition',
      name: 'Musculoskeletal Condition'
    },
    neurological_condition: {
      type: 'NeurologicalCondition',
      name: 'Neurological Condition'
    },
    [`cpt-codes`]: { type: 'CPTCodes', name: 'CPT Codes' },
    [`icd10-codes`]: { type: 'ICD10Codes', name: 'ICD10 Codes' },
    occupations: { type: 'Occupation', name: 'Occupation' },
    growth_data: { type: 'GrowthData', name: 'Growth Data' },
    landmarks: { type: 'Landmarks', name: 'Landmarks' }
  }
};
