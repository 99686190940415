import CH3DFormsAI from './components/forms/3D/3DFormsAI';
import HPFormsAI from './components/forms/HP/HPFormsAI';
import INTAKEFormsAI from './components/forms/INTAKE/IntakeFormsAI';
import PROGRESSFormsAI from './components/forms/PROGRESS/PROGRESSFormsAI';
import SOAPFormsAI from './components/forms/SOAP/SOAPFormsAI';

export const TYPE_OF_NOTES = [
  {
    label: 'H&P',
    type: `hp`,
    onClick: () => {},
    checked: false,
    forms: HPFormsAI
  },
  {
    label: 'Intake',
    type: `intake`,
    onClick: () => {},
    checked: false,
    forms: INTAKEFormsAI
  },
  {
    label: 'SOAP',
    type: `soap`,
    onClick: () => {},
    checked: false,
    forms: SOAPFormsAI
  },
  {
    label: 'Progress',
    type: `progress`,
    onClick: () => {},
    checked: false,
    forms: PROGRESSFormsAI
  },
  {
    label: '3D Charting',
    type: `3d`,
    onClick: () => {},
    checked: false,
    forms: CH3DFormsAI
  }
];
