import React from 'react';
import { orderBy } from 'lodash';

import Icon from '../../../../shared/Icon/Icon';

const EligibilityErrorInfo = ({ eligibility }) => {
  return (
    <div className="relative flex h-full w-full flex-col items-center !gap-6 !rounded-lg bg-white py-[100px]">
      <div className="absolute right-0 top-0">
        <Icon icon="red-rectangle-shape" />
        <Icon icon="red-rectangle-shape-2" className="absolute right-0 top-0 z-20" />
      </div>

      <h3 className="text-[28px] font-500 text-danger-500">Eligibility request - Error</h3>

      {orderBy(eligibility?.error, 'error_code').map(({ error_code, error_mesg }) => (
        <div className="z-100 flex flex-col items-center !gap-2" key={error_code}>
          <p className="text-base font-500 text-neutral-700">Error code: {error_code}</p>
          <p className="text-base font-500 text-neutral-900">{error_mesg}</p>
        </div>
      ))}
    </div>
  );
};

export default EligibilityErrorInfo;
