import { requestApi } from './Api';

export const getRefLabs = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/get_ref_labs',
    params,
    throwError: true
  });

export const getReferenceLabTests = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/get_reference_lab_tests',
    params
  });

export const getPreferredTestList = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/get_preferred_test_list',
    params,
    throwError: true
  });

export const orderCPOETests = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/order_cpoe_tests',
    params
  });

export const upsertLabSoftOrder = async (navigate, options = {}) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/order_cpoe_tests',
    responseType: 'blob',
    ...options
  });

export const searchLabTests = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/tests/search',
    params
  });

export const checkMedicalNecessity = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/do_rbo_with_abn_values',
    params
  });

export const getTest = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/tests/get',
    params
  });

export const getOrderResults = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/labsoft/results',
    params
  });
