import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import HPIRevisions from '../../../../../../../shared/Forms/Custom/HPIRevisions/HPIRevisions';
import Accordion from '../../../../shared/Accordion';

const HPIRevisionsSection = ({ components, sectionRef, options = [] }) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'HPI', parentKey: 'hp', childKey: 'hpi' }}
      title={components?.hpi?.title}
      id={components?.hpi?.id}
      disabled={clinicalNote?.locked}>
      <HPIRevisions
        isClinicalNote
        defaultValue={clinicalNote?.hpi}
        sectionRef={sectionRef}
        showNarrative
        options={options}
      />
    </Accordion>
  );
};

export default HPIRevisionsSection;
