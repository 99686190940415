import { requestApi } from './Api';

export const getEras = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/era/get',
    params
  });
};

export const getEra = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/era/get_era',
    params
  });
};

export const createEob = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/eob/create',
    params
  });
};
export const updateEob = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/eob/update',
    params
  });
};

export const finalizeEra = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/update_era',
    params
  });
};
export const finalizeEob = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/update_eob',
    params
  });
};

export const matchClaim = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/match_claims',
    params
  });
};

export const postEOB = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/eob/post',
    params
  });
};

export const refreshERA = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/era',
    params
  });
};

export const downloadERA = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/download_era',
    params
  });
};

export const deleteEob = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/eob/delete',
    params
  });
};

export const unAllocateEob = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/practice/billing/claim/eob/unallocate',
    params
  });
};
