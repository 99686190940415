import React, { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { DEFAULT_COLUMN_DEFS, getColDefs, GRID_OPTIONS } from './configs';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import ModalFooter from '../../../../../../../../shared/Modal/ModalFooter/ModalFooter';
import Button from 'components/shared/Buttons/Button';
import {
  useChatContextData,
  useChatContextApi
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import EmptyPastNotes from './components/EmptyPastNotes';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PastNotes = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider name="past_notes" cols={getColDefs(practiceTimezone)}>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { id } = useParams();

  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows } = useChatContextApi();
  const { gridApi } = useTableContext();
  const selectedRowsData = selectedTableRows?.['past_notes']?.data;

  const { clinicalNote: currentNote } = useClinicalNoteContext();

  const { data } = useClinicalNote({
    params: { patientId: id, withDocumentSign: true },
    dependencies: [id],
    options: {
      select: (data) => ({
        clinicalNote: data?.clinicalNote.filter(
          (clinicalNote) => clinicalNote?.id !== currentNote.id
        )
      })
    }
  });

  const clinicalNotes = data?.clinicalNote;

  useEffect(() => {
    if (selectedTableRows?.['past_notes']?.selectedNodes?.length > 0) {
      selectedTableRows?.['past_notes']?.selectedNodes?.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedTableRows((prev) => ({
      ...prev,
      past_notes: {
        selectedNodes,
        data: selectedNodes.map((node) => node.data),
        status: !selectedNodes?.length
          ? 'none'
          : selectedNodes?.length === clinicalNotes?.length
            ? 'all'
            : 'partial'
      }
    }));
  }, [clinicalNotes?.length, gridApi, setSelectedTableRows]);

  return (
    <div className="ag-theme-quartz past_notes_table !mb-0 h-full">
      <AGTable
        onSelectionChanged={onSelectionChanged}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDef={getColDefs(practiceTimezone)}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={clinicalNotes}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        reactiveCustomComponents
        noRowsOverlayComponent={EmptyPastNotes}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px] bg-white">
            <Skeleton count={24} />
          </div>
        )}
      />
      {clinicalNotes?.length > 0 && (
        <ModalFooter
          rightButtons={
            <Button
              key={1}
              text={`Add ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Past Notes`}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onAddComponent}
              disabled={!selectedRowsData?.length}
            />
          }
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}

export default withErrorBoundary(PastNotes);
