import { capitalize } from 'lodash';

import { getTags } from 'api/Tags';

import { formatDate, formatPhoneNumber } from 'lib/helpers/utility';

import TagsCell from '../components/TagsCell';

export const patientStatuses = [
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'inactive',
    label: 'Inactive'
  }
];

export const defaultColumns = [
  { field: 'id', headerName: 'Chart ID' },
  {
    field: 'f_name',
    headerName: 'First Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  { field: 'l_name', headerName: 'Last Name', valueFormatter: ({ value }) => capitalize(value) },
  {
    field: 'p_name',
    headerName: 'Preferred Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'dob',
    headerName: 'Date of Birth',
    valueFormatter: ({ value }) => formatDate(value)
  },
  { field: 'email', headerName: 'Email' },

  {
    field: 'phone',
    headerName: 'Phone',
    valueFormatter: ({ value }) => formatPhoneNumber(value)
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) => {
      return params?.data?.practicePatientLink?.status;
    },
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'tagLinks',
    headerName: 'Tags',
    cellClass: 'no-border',
    minWidth: 300,
    cellRenderer: TagsCell,
    sortable: false
  }
];

export const defaultFilters = {
  searchTerm: {
    type: 'search',
    values: null,
    title: 'Patient',
    placeholder: 'Search patients'
  },
  status: {
    type: 'checkbox',
    values: null,
    options: patientStatuses,
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  },
  tags: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Tags',
    placeholder: 'Select chart tags',
    icon: false,
    queryKey: 'tags',
    queryFn: getTags,
    params: { kind: 'chart' }
  }
};
