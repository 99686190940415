import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation, useParams } from 'react-router-dom';
import Transcript from '../../../CurrentNote/TypeNotes/MyScribeAI/components/Transcript';
import Icon from 'components/shared/Icon/Icon';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import TranscriptionLoading from './components/TranscriptionLoading';
import RecordingView from './components/RecordingView';
import TranscriptionView from './components/TranscriptionView';
import cs from 'classnames';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const AmbientListening = () => {
  const { appointmentId } = useParams();
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { showTranscriptModal, loading, transcriptData, setShowTranscriptModal } =
    useAmbientListeningContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb, popBreadcrumb } = breadcrumb;
  const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Ambient Listening',
      name: '/ambient-listening',
      path: pathname
    });
  }, [breadcrumbs]);

  if (showTranscriptModal && !loading) {
    const onShowUnsavedModal = () => {
      if (transcriptData.some((transcript) => !transcript?.id)) {
        setUnsavedConfirmation(true);
        return;
      }
      onContinueWithoutSaving();
    };

    const onContinueWithoutSaving = () => {
      popBreadcrumb();
      setUnsavedConfirmation(false);
      setShowTranscriptModal(false);
    };

    return (
      <>
        <div className="relative border-b border-gray-300 p-2 shadow-sm">
          <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={onShowUnsavedModal} />
        </div>

        <div className="h-full overflow-auto">
          <TranscriptionView onGoBack={onShowUnsavedModal} />
        </div>
        <Confirm
          handleOpen={unsavedConfirmation}
          handleClose={() => setUnsavedConfirmation(false)}
          handleContinue={onContinueWithoutSaving}
          title="Unsaved changes"
          message="You have unsaved Transcription. Do you want to close this"
          primaryBtnTxt="Yes"
          secondaryBtnTxt="No"
          icon="new-warning-red"
          variant="danger"
        />
      </>
    );
  }

  return (
    <div className="h-full">
      <div className="relative border-b border-gray-300 p-2 shadow-sm">
        <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      </div>
      <div className="relative flex h-full w-full overflow-hidden bg-primary-900 pt-5 shadow-2xl">
        <Icon icon="ai-bg-pattern" className="absolute left-0 top-0" />
        <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />
        <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90" />
        <div className="flex w-full flex-col items-center justify-center overflow-auto">
          <div className="z-50 flex w-full flex-col items-center justify-center gap-10">
            {!showTranscriptModal ? <RecordingView /> : <TranscriptionLoading />}
            <div className={cs('z-50 mb-[100px] flex', showTranscriptModal && 'hidden')}>
              <Transcript
                name="Start Recording"
                className="z-50 cursor-pointer bg-white !px-[15px] !py-3 text-primary-600"
                customId={appointmentId}
              />
            </div>
            <Icon icon="hippa-secure" className="absolute bottom-[80px]" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AmbientListening;
