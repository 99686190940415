export const selectCustomStyle = {
  valueContainer: {
    gap: '2px 8px',
    padding: '4px 10px'
  },
  menu: {
    padding: 4
  },
  option: {
    borderRadius: 4
  }
};
