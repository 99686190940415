import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import Button from '../../shared/Buttons/Button';
import Input from '../../shared/Input/Input';
import Loading from '../../shared/Loading/Loading';
import NewPassword from '../../shared/NewPassword/NewPassword';
import getData from './getData';
import state from './state';

const UserActivateStart = () => {
  const [user, setUser] = useRecoilState(state.userState);
  const [practice, setPractice] = useRecoilState(state.practiceState);
  const [progress, setProgress] = useRecoilState(state.progressState);
  const [pwd, setPwd] = useState('');
  const [show, setShow] = useState({ loading: false, passwordOk: false });
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    getData({ navigate, setUser, setPractice, location, setShow });
  }, []);

  const complete = () => {
    const onSuccess = (data, res) => {
      navigate(res?.redirect);
    };
    requestApi({
      url: '/api/user/invitation/complete',
      params: {
        password: pwd
      },
      navigate,
      onSuccess
    });
  };

  if (show.loading) {
    return (
      <div>
        <div className="text-lg text-primary-500">Step 1/2</div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="my-4 w-full text-lg text-primary-500">
        Step {progress.current}/{progress.total}
      </div>
      <div className="w-full text-3xl font-500 text-primary-900">Setup account password</div>
      <p className="mt-10 w-full text-neutral-700">
        <strong className="font-700 text-neutral-900">{user.f_name}</strong>, we are happy to have
        you at <strong className="font-700 text-neutral-900">{practice.name}</strong>! Please enter
        a strong password to continue with your account registration.
      </p>
      <Input
        value={user.email}
        data-qa="invitation-email"
        label={<span className="text-primary-900">Username</span>}
        disabled={true}
        className="mt-7 w-full"
      />
      <NewPassword
        setPassword={setPwd}
        setPasswordOk={(v) => setShow((ps) => ({ ...ps, passwordOk: v }))}
      />
      <Button
        data-qa="invitation-continue"
        text={'Continue'}
        onClick={complete}
        className="mt-10 w-full"
        loading={show.loading}
        disabled={!show.passwordOk}
      />
    </div>
  );
};

export default UserActivateStart;
