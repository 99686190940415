import React from 'react';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import { appointment_lengths } from 'constants';
import Select from 'components/shared/Select/Select';

const Duration = ({ timezone }) => {
  const { values, setFieldValue } = useFormikContext();
  const starts_at = moment(values?.starts_at).format('YYYY-MM-DDTHH:mm');
  const ends_at = moment(values?.ends_at).format('YYYY-MM-DDTHH:mm');
  const diffMin = moment(ends_at).diff(moment(starts_at), 'minutes');

  const selectTimeLength = (time) => {
    const { value } = time;
    setFieldValue('ends_at', moment.tz(values.starts_at, timezone).add(value, 'm').format());
  };

  return (
    <Select
      parentClassName="col-span-3 w-full"
      label="Appointment duration"
      placeholder="Type and select"
      value={{
        value: diffMin,
        label: `${diffMin} min`
      }}
      onChange={(value) => selectTimeLength(value)}
      options={appointment_lengths}
      inputId="durationSelect"
      menuPortalTarget={document.body}
      menuPlacement="top"
      isClearable={false}
    />
  );
};

export default Duration;
