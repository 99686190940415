import { requestApi } from './Api';

export const getPatientLedger = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/patient_ledger/get',
    params
  });

export const getPatientLedgerCSV = (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/billing/patient_ledger/get_csv', params });
