import React, { useState } from 'react';

import Tippy from '@tippyjs/react';

import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';

import SendEmailFooter from './SendEmailFooter';

const InvoiceStepModalFooter = ({
  step,
  handleClose,
  handleStepChange,
  invoiceCreateRef,
  externalInvoices,
  finalGroupInvoices,
  isFromSelectedRows
}) => {
  const [check, setCheck] = useState(true);

  const isFirstStep = step === 0 || isFromSelectedRows;
  const isLastStep = step === 1;
  const isDisabled = !finalGroupInvoices?.length;

  const handleCheckAEOB = ({ target }) => {
    setCheck(target.checked);
    if (invoiceCreateRef?.current?.onCheckAEOB) {
      invoiceCreateRef.current.onCheckAEOB(target);
    }
  };

  if (step !== 2)
    return (
      <div className="flex w-full justify-between">
        <Button
          outlined
          text={isFirstStep ? 'Cancel' : 'Back'}
          color="neutral"
          onClick={() => (isFirstStep ? handleClose() : handleStepChange(false))}
          id="cancelInvoiceModalBtn"
          data-qa="cancel-btn"
        />
        <div className="flex items-center gap-2">
          {isLastStep && (
            <Tippy
              className="tippy-dark"
              placement="top"
              content={
                <div className="p-2">
                  <div className="pb-2 text-sm">Include a full breakdown of the invoice.</div>
                  <div className="pb-1 text-sm">Charge</div>
                  <div className="pb-1 text-sm">Adjustment</div>
                  <div className="pb-1 text-sm">Ins. Payment</div>
                </div>
              }>
              <div className="relative">
                <Checkbox
                  name="details"
                  data-qa="advanced-aeob-checkbox"
                  className="cursor-pointer pr-5"
                  isChecked={check}
                  handleClick={handleCheckAEOB}
                  label="Advanced EOB"
                />
              </div>
            </Tippy>
          )}
          <Button
            disabled={isDisabled}
            onClick={() => {
              isLastStep ? invoiceCreateRef?.current?.submitForm() : handleStepChange(true);
            }}
            text={isLastStep ? 'Create' : 'Next'}
            id="savePackageModalBtn"
            data-qa="create-update-invoice-btn"
          />
        </div>
      </div>
    );

  return (
    <SendEmailFooter
      handleClose={handleClose}
      externalInvoices={externalInvoices}
      finalGroupInvoices={finalGroupInvoices}
    />
  );
};

export default InvoiceStepModalFooter;
