import React, { useEffect, useState } from 'react';
import AssessmentSection from '../../../../CurrentNote/TypeNotes/HPNote/components/AssessmentSection';
import CPTCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import MedicationsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/MedicationsSection';
import PlanSection from '../../../../CurrentNote/TypeNotes/HPNote/components/PlanSection';
import VitalsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import GeneralNoteSection from '../../../../CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import ObjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/ObjectiveSection';
import SubjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/SubjectiveSection';
import OrdersSection from '../../../../Orders/OrdersSection';
import FormsAI from '../FormsAI';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import AdvancedSoap from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/AdvancedSoap';

const getFormsList = (advancedSOAP, cnDisplaySettings) => {
  const formsList = {
    subjective: SubjectiveSection,
    objective: ObjectiveSection,
    assessment: AssessmentSection,
    medicationHistory: (props) => (
      <div className="relative flex h-[600px] flex-col !rounded-lg border border-solid !border-primary-200 !bg-primary-50 p-5">
        <MedicationsSection {...props} isResizable={false} />
      </div>
    ),
    plan: PlanSection,
    vitals: VitalsSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection,
    ...assignAdvTitlesToComponent(advancedSOAP, AdvancedSoap, cnDisplaySettings)
  };

  return formsList;
};

const SOAPFormsAI = ({ inputName, breadcrumb = null, title = '', components }) => {
  const { setSteps, setStep } = breadcrumb || {};
  const { advancedSOAP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedSOAP, cnDisplaySettings));

  useEffect(() => {
    if (!breadcrumb) return;
    setSteps([
      {
        title,
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  return <FormsAI formsList={formsList} inputName={inputName} components={components} />;
};

export default SOAPFormsAI;
