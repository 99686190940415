import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { downloadFax, previewSavedFax } from 'api/Fax';

import applyChangesToPDF from 'components/shared/DocumentEditor/lib/applyChangesToPDF';

const useFaxActions = ({ fax, renderFaxOnLoad }) => {
  const navigate = useNavigate();

  const [renderedFax, setRenderedFax] = useState(null);

  useEffect(() => {
    if (renderFaxOnLoad && fax) {
      setRenderedFax(renderFax());
    }
  }, [fax]);

  const handleFaxPrint = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToPDF({
      pdf: pdfData.url,
      navigate,
      id: fax.id,
      kind: 'fax'
    });

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, data.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrlFile;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobUrlFile);
    };

    document.body.appendChild(iframe);
  };

  const handleFaxDownload = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToPDF({
      pdf: pdfData.url,
      navigate,
      id: fax.id,
      kind: 'fax'
    });

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, data.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const pdfUrl = blobUrlFile;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `invoice_${new Date(fax.created_at).getTime()}_document.pdf`; // specify the filename
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const renderFax = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob, url } = await applyChangesToPDF({
      pdf: pdfData.url,
      navigate,
      id: fax.id,
      kind: 'fax'
    });

    if (blob) {
      return URL.createObjectURL(blob);
    } else {
      return url;
    }
  };

  return { handleFaxPrint, handleFaxDownload, renderFax, renderedFax, setRenderedFax };
};

export default useFaxActions;
