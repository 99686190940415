import React from 'react';

import cs from 'classnames';

import useHasScrollbar from 'lib/hooks/useHasScrollbar';
import useScroll from 'lib/hooks/useScroll';

import Icon from '../Icon/Icon';

/**
 * @param {import('./lib/propTypes').HorizontalScrollProps}
 */

const iconBaseClassName =
  'absolute z-10 flex h-full w-12 min-w-[60px] items-center justify-center from-transparent duration-100';

const HorizontalScroll = ({ children, className, parentClassName }) => {
  const [ref, hasScrollbar, canScrollLeft, canScrollRight] = useHasScrollbar({
    type: 'horizontal'
  });

  const handleScroll = useScroll({ ref });

  return (
    <div className={cs('relative flex items-center w-full', parentClassName)}>
      {canScrollLeft && (
        <Icon
          icon="chevron-left"
          className={cs(
            iconBaseClassName,
            'left-0 bg-gradient-to-l !pr-4 via-neutral-50 to-white hover:-translate-x-1'
          )}
          onClick={() => handleScroll({ ref, direction: 'left' })}
        />
      )}
      <div
        className={cs('flex w-full gap-1', hasScrollbar && 'overflow-hidden', className)}
        ref={ref}>
        {children}
      </div>
      {canScrollRight && (
        <Icon
          icon="chevron-right"
          className={cs(
            iconBaseClassName,
            'right-0 bg-gradient-to-r !pl-4 via-white to-neutral-50 hover:translate-x-1'
          )}
          onClick={() => handleScroll({ ref, direction: 'right' })}
        />
      )}
    </div>
  );
};

export default HorizontalScroll;
