import React from 'react';
import { useNavigate } from 'react-router-dom';

import { matchClaim, postEOB } from 'api/ERA';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { iaRa, io, keyChanges } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const Footer = ({ onClose }) => {
  const navigate = useNavigate();
  const {
    era,
    selectedClaim,
    allocateEob,
    unmatchedClaims,
    selectedUnmatchedClaim,
    procedures,
    statusCode,
    amount,
    setAllocateEob,
    setSelectedClaim
  } = useEraContext();

  const { isOpen, openModal, closeModal } = useModal();
  const unmatchedClaim = unmatchedClaims.find((claim) => claim.id === selectedUnmatchedClaim);

  const hasProcedureChanges = io(
    keyChanges(iaRa(unmatchedClaim?.invoice?.procedures), iaRa(procedures))
  );

  const mutateMatchClaim = useCustomMutation({
    mutationFn: () =>
      matchClaim(navigate, {
        eraId: era.id,
        claimId: selectedUnmatchedClaim,
        unmatchedClaimIndex: selectedClaim?.index,
        ...(hasProcedureChanges ? { procedures } : {})
      }),
    successMessage: 'Claim posted successfully!',
    errorMessage: 'Failed to post claim!',
    invalidateQueryKey: ['eras', 'era'],
    onSuccess: () => setSelectedClaim(null)
  });

  const mutatePostEOB = useCustomMutation({
    mutationFn: () => {
      if (!statusCode) {
        showAlert({
          message: 'Please select a status code.',
          icon: 'new-close-circle',
          color: 'danger'
        });
        return;
      }

      return postEOB(navigate, {
        amount,
        procedures,
        eobId: era.id,
        claimId: selectedUnmatchedClaim,
        status_code: statusCode
      });
    },
    successMessage: 'Claim posted successfully!',
    invalidateQueryKey: ['eobs', 'eob', 'era'],
    onSuccess: () => {
      setAllocateEob(false);
      closeModal();
    }
  });

  const handlePostEOB = () => {
    if (allocateEob.state === 'accepted') {
      mutatePostEOB.mutate();
      return;
    }
    openModal();
  };

  return (
    <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:gap-2">
      <Button
        text="Cancel"
        onClick={onClose}
        color="neutral"
        transparent
        outlined
        data-qa="cancel-btn"
      />
      {selectedClaim && selectedUnmatchedClaim && (
        <Button text="Post" onClick={() => mutateMatchClaim.mutate()} disabled={!selectedClaim} />
      )}
      {allocateEob && (
        <Button text="Post" onClick={handlePostEOB} disabled={!selectedUnmatchedClaim} />
      )}

      {isOpen && (
        <Confirm
          primaryBtnTxt="Post"
          icon="new-send-bulk"
          message="This claim has not been submitted to the clearing house yet, are you sure you want to post the payment?"
          loading={mutatePostEOB?.isLoading}
          handleContinue={() => mutatePostEOB.mutate()}
          isOpen={isOpen}
          handleClose={closeModal}
        />
      )}
    </div>
  );
};

export default Footer;
