import Tippy from '@tippyjs/react';
import React, { useEffect, useRef, useState } from 'react';

const TruncatedLabel = ({ label }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const spanRef = useRef(null);

  useEffect(() => {
    const checkTruncation = () => {
      if (spanRef.current) {
        setIsTruncated(spanRef.current.scrollWidth > spanRef.current.offsetWidth);
      }
    };

    checkTruncation();
    window.addEventListener('resize', checkTruncation);
    return () => window.removeEventListener('resize', checkTruncation);
  }, [label]);

  return (
    <Tippy content={label} disabled={!isTruncated} className="tippy-dark">
      <div
        ref={spanRef}
        className="max-w-fit overflow-hidden text-ellipsis whitespace-nowrap text-neutral-900">
        {label}
      </div>
    </Tippy>
  );
};

export default TruncatedLabel;
