import ReactModal from 'react-modal';

import cs from 'classnames';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

/**
 * @param {import('./lib/propTypes').ConfirmProps}
 */

const Confirm = ({
  icon,
  iconSize,
  iconColor,
  variant = 'primary',
  title,
  message,
  children,
  primaryBtnTxt = 'Confirm',
  secondaryBtnTxt = 'Cancel',
  isOpen = false,
  handleOpen = false, // recommended to use isOpen instead of handleOpen
  handleContinue,
  handleClose,
  loading,
  customClassName,
  overlayClassName = null,
  disabled = false,
  footer,
  isFooter = true,
  bodyClassName = null,
  ...rest
}) => {
  if (!(isOpen || handleOpen)) return;

  return (
    <ReactModal
      isOpen={isOpen || handleOpen}
      onRequestClose={handleClose}
      contentLabel={title}
      ariaHideApp={false}
      className={cs(
        'relative z-[100] w-[512px] rounded-md bg-white !p-6 shadow-medium outline-none sm:!w-[90%] xs:!w-[calc(100%-32px)] xs:!p-4',
        customClassName
      )}
      overlayClassName={cs('ModalOverlay', overlayClassName)}
      overlayRef={(node) => {
        node?.addEventListener('keypress', (event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (handleContinue) handleContinue();
          }
        });
      }}
      {...rest}>
      <div className="flex items-start justify-between !pb-5">
        {icon && (
          <div
            className={cs(
              'flex h-12 w-12 items-center justify-center rounded-full',
              `bg-${variant}-50`
            )}>
            <Icon icon={icon} size={iconSize} color={iconColor} />
          </div>
        )}
        <div
          data-qa="close-icon"
          className="ml-auto flex h-5 w-5 cursor-pointer items-center justify-center hover:bg-neutral-50"
          onClick={handleClose}>
          <Icon icon="new-close" className="cursor-pointer" />
        </div>
      </div>
      <div className={cs('pb-8', bodyClassName)}>
        <h3 className="!pb-2 text-lg font-500 text-[#171717]">{title}</h3>
        <div className="pr-3">
          {message && <p className="whitespace-pre-line text-sm text-neutral-600">{message}</p>}
          {children && <div>{children}</div>}
        </div>
      </div>
      {isFooter && typeof footer === 'object'
        ? footer
        : isFooter && (
            <div className="flex justify-end gap-x-3">
              <Button
                outlined
                color="neutral"
                onClick={handleClose}
                text={secondaryBtnTxt}
                data-qa="close-confirm-modal-btn"
              />

              <Button
                data-qa="proceed-confirm-modal-btn"
                color={variant}
                loading={loading}
                text={primaryBtnTxt}
                onClick={handleContinue}
                disabled={disabled}
              />
            </div>
          )}
    </ReactModal>
  );
};

export default Confirm;
