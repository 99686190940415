import React from 'react';

import { useRecoilValue } from 'recoil';

import { cmToInch, separateFeetAndInches, separatePoundsAndOunces } from 'lib/helpers/utility';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Textarea from 'components/shared/Textarea/Textarea';
import { userState } from 'components/state';

import { useVitalDataSetRefs } from '../../../Vitals/lib/helpers';
import {
  handleBMIChange,
  handleFeetAndInch,
  handleNumericChange,
  handlePoundsAndOz
} from '../lib/helper';

const UpsertForm = ({
  formik,
  disabled,
  fieldToFocus = '',
  yesCheckbox,
  setYesCheckbox = () => {}
}) => {
  const {
    display_settings: { clinicalNote: cnSettings }
  } = useRecoilValue(userState);
  const refs = useVitalDataSetRefs();
  const isGrowthChartEnabled = cnSettings?.overview?.growth_chart;
  const { pounds, ounces } = separatePoundsAndOunces(formik?.values?.weight);
  const { feet, inches } = separateFeetAndInches(cmToInch(formik?.values?.height));

  return (
    <div className="relative flex h-full w-full flex-col">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 z-10 bg-primary-10" />
      <form className=" z-50 h-[90vh] lg:px-[230px]  xl:px-[230px]">
        <div className="mb-6 grid grid-cols-2 gap-6">
          <DatePopover
            id="timestamp"
            data_qa="timestamp"
            isTime={true}
            label="Vitals taken on"
            onChange={(date) => formik.setFieldValue('timestamp', date)}
            value={formik?.values?.timestamp || new Date().toISOString()}
            disabled={disabled}
          />
        </div>
        <div className="grid grid-cols-2 gap-6">
          <Input
            data-qa="heart_rate"
            id="heart_rate"
            label="Heart rate"
            placeholder="Add heart rate"
            actionButtonText="bpm"
            value={formik?.values?.heart_rate}
            onChange={(event) => handleNumericChange({ event, formik, fieldName: 'heart_rate' })}
            disabled={disabled}
            forwardedRef={refs?.heart_rate}
            focusOnMount={fieldToFocus === 'Heart Rate'}
          />
          <Input
            data-qa="respiratory-rate"
            id="respiratory_rate"
            label="Respiratory rate"
            placeholder="Add respiratory rate"
            actionButtonText="bpm"
            value={formik?.values?.respirations}
            onChange={(event) => handleNumericChange({ event, formik, fieldName: 'respirations' })}
            disabled={disabled}
            forwardedRef={refs?.respirations}
            focusOnMount={fieldToFocus === 'Respiratory Rate'}
          />
          <Input
            data-qa="blood_pressure_systolic"
            id="blood_pressure_systolic"
            label="Blood pressure (systolic)"
            placeholder="Add blood pressure"
            actionButtonText="mmHg"
            value={formik?.values?.blood_pressure_systolic}
            onChange={(event) =>
              handleNumericChange({ event, formik, fieldName: 'blood_pressure_systolic' })
            }
            disabled={disabled}
            forwardedRef={refs?.blood_pressure_systolic}
            focusOnMount={fieldToFocus === 'Blood Pressure(Systolic)'}
          />
          <Input
            label="Blood pressure (diastolic)"
            data-qa="blood_pressure_diastolic"
            id="blood_pressure_diastolic"
            placeholder="Add blood pressure"
            actionButtonText="mmHg"
            value={formik?.values?.blood_pressure_diastolic}
            onChange={(event) =>
              handleNumericChange({ event, formik, fieldName: 'blood_pressure_diastolic' })
            }
            disabled={disabled}
            forwardedRef={refs?.blood_pressure_diastolic}
            focusOnMount={fieldToFocus === 'Blood Pressure(Diastolic)'}
          />
          <div className="flex gap-1">
            <Input
              label="Weight"
              data-qa="pounds"
              id="pounds"
              placeholder="Add pounds"
              actionButtonText="lbs"
              name="pounds"
              disabled={disabled}
              value={pounds}
              onChange={(event) => handlePoundsAndOz({ event, ounces, pounds, formik })}
              forwardedRef={refs?.weight}
              focusOnMount={fieldToFocus === 'Weight'}
            />
            <Input
              className="mt-[27px]"
              data-qa="oz"
              id="oz"
              placeholder="Add oz"
              actionButtonText="oz"
              name="oz"
              disabled={disabled}
              value={ounces}
              onChange={(event) => handlePoundsAndOz({ event, ounces, pounds, formik })}
            />
          </div>
          <Input
            data-qa="temperature"
            label="Temperature"
            id="temperature"
            actionButtonText="°F"
            placeholder="Add temperature"
            value={formik?.values?.temperature}
            onChange={(event) => handleNumericChange({ event, formik, fieldName: 'temperature' })}
            disabled={disabled}
            forwardedRef={refs?.temperature}
            focusOnMount={fieldToFocus === 'Body Temperature'}
          />
          <div className="flex gap-1">
            <Input
              data-qa="feet"
              id="feet"
              label="Height"
              placeholder="Add feet"
              actionButtonText="ft"
              name="feet"
              disabled={disabled}
              value={feet}
              onChange={(event) => handleFeetAndInch({ event, inches, feet, formik })}
              forwardedRef={refs?.height}
              focusOnMount={fieldToFocus === 'Height'}
            />
            <Input
              className="mt-[27px]"
              data-qa="inches"
              id="inches"
              actionButtonText="in"
              placeholder="Add inches"
              name="inches"
              disabled={disabled}
              value={inches}
              onChange={(event) => handleFeetAndInch({ event, inches, feet, formik })}
            />
          </div>
          <Input
            data-qa="bmi"
            label="BMI"
            id="bmi"
            placeholder="Add BMI"
            actionButtonText={
              <span>
                kgm<sup>2</sup>
              </span>
            }
            name="bmi"
            value={formik?.values?.bmi}
            disabled={disabled}
            onChange={(e) => handleBMIChange({ e, formik })}
            forwardedRef={refs?.bmi}
            focusOnMount={fieldToFocus === 'BMI'}
          />
          <Input
            data-qa="oxygen_level"
            id="oxygen_level"
            label="Oxygen level"
            placeholder="Add oxygen level"
            actionButtonText="%"
            value={formik?.values?.oxygen_level}
            onChange={(event) => handleNumericChange({ event, formik, fieldName: 'oxygen_level' })}
            disabled={disabled}
            forwardedRef={refs?.oxygen_level}
            focusOnMount={fieldToFocus === 'Oxygen Level'}
          />
          {isGrowthChartEnabled && (
            <Input
              label="Head circumference"
              data-qa="head_circumference"
              id="head_circumference"
              placeholder="Add head circumference"
              actionButtonText="inch"
              name="head_circumference"
              value={formik?.values?.head_circumference}
              onChange={(event) =>
                handleNumericChange({ event, formik, fieldName: 'head_circumference' })
              }
              disabled={disabled}
              forwardedRef={refs?.head_circumference}
              focusOnMount={fieldToFocus === 'Head Circumference'}
            />
          )}
        </div>
        <div className="mt-6 flex items-center">
          <Checkbox
            name="yesCheckbox"
            handleClick={(e) => setYesCheckbox(e?.target?.checked)}
            isChecked={yesCheckbox}
            disabled={disabled}
          />
          <p className="text-sm font-500 text-neutral-800">Add additional note?</p>
        </div>
        {yesCheckbox && (
          <div className="mt-4 pb-32">
            <Textarea
              isEditor
              label="Note"
              name="note"
              value={formik?.values?.notes}
              onChange={(event) => formik.setFieldValue('notes', event)}
              className="min-h-[200px]"
              disabled={disabled}
            />
          </div>
        )}
      </form>
    </div>
  );
};
export default UpsertForm;
