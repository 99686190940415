import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import Button from '../Buttons/Button';

const AIChatInput = ({
  leftButtons,
  rightButtons,
  placeholder = 'Type your note...',
  className,
  inputClassName,
  disabled = false,
  onSubmit = () => {},
  onGoBack = () => {},
  onTextChange = () => {},
  value,
  setValue = () => {},
  isInput = true,
  children
}) => {
  const [inputHeight, setInputHeight] = useState('auto');
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setInputHeight(`${textareaRef.current.scrollHeight}px`);
    }
  }, [value]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    onTextChange(event);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(value);
      setValue('');
    }
  };

  // Focus textarea when container is clicked
  const handleContainerClick = () => {
    if (textareaRef?.current) {
      textareaRef?.current?.focus();
    }
  };

  return (
    <div
      className={cs(
        'flex flex-col w-full rounded-lg border border-gray-300 bg-[#FFF] py-3 shadow-2xl px-4',
        className
      )}
      onClick={handleContainerClick} // Added click handler
    >
      <div className="flex w-full cursor-pointer">
        <div className="flex items-end">
          {leftButtons || (
            <Button
              key="1"
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onGoBack}
            />
          )}
        </div>

        {/* Main input container */}
        <div className="flex-grow justify-end">
          {isInput && (
            <textarea
              className={cs(
                'h-full max-h-[200px] w-full flex-grow resize-none overflow-y-auto border-none bg-transparent focus:outline-none text-sm',
                inputClassName
              )}
              ref={textareaRef}
              placeholder={placeholder}
              value={value}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              style={{ height: inputHeight }}
              rows={1}
            />
          )}
        </div>
      </div>

      <div className="flex">
        {rightButtons || (
          <Button
            key={1}
            text="Generate"
            icon="stars-ai"
            iconSize={18}
            iconColor="white"
            size="small"
            className={cs('!py-2 !pl-4 !pr-4', disabled ? 'bg-primary-100' : '')}
            onClick={onSubmit}
            disabled={disabled}
          />
        )}
      </div>

      {children}
    </div>
  );
};

export default AIChatInput;
