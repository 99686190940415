export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true,
  suppressAggFuncInHeader: true,
  enableRangeSelection: true,
  groupSelectsChildren: true,
  suppressRowClickSelection: true,
  customClassName: 'ag-grid-interactive',
  rowSelection: 'multiple'
};

export const DEFAULT_COLUMN_DEFS = {
  resizable: true,
  enableRowGroup: true,
  minWidth: 100,
  flex: 1
};

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_COUNT = 1;
export const DEFAULT_STALE_TIME = 1000 * 60 * 5;

export const SPECIAL_UPPERCASE_NAMES = ['era', 'eob'];
