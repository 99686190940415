import { useParams } from 'react-router-dom';

import { useDynamicAr } from 'lib/hooks/queries/dynamicAR/useDynamicAr';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import DynamicARGraph from './DynamicARGraph';
import { COLUMN_DEFS, DYNAMIC_AR_SWITCH_ITEMS } from './lib/helpers';

export default function DynamicAR() {
  const { type } = useParams();

  if (type === 'graph') {
    return <DynamicARGraph />;
  }

  return (
    <AgTablePage
      defaultColumns={COLUMN_DEFS}
      useData={useDynamicAr}
      name="dynamicAr"
      title="Dynamic AR"
      pagination={false}
      switchItems={DYNAMIC_AR_SWITCH_ITEMS}
    />
  );
}
