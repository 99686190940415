import moment from 'moment';
import React from 'react';
import Header from '../../../shared/Header/Header';
import { capitalize } from 'lodash';
import AGTable from '../../../shared/AGTable/AGTable';
import DisplayButton from '../../../shared/AGTable/DisplayButton';
import { TableContextProvider } from '../../../../lib/context/TableContext/TableContextProvider';
import { useKiosks } from '../../../../lib/hooks/queries/useKiosks';
import Button from '../../../shared/Buttons/Button';
import NewKioskModal from './New/NewKioskModal';
import EditKioskModal from './Edit/EditKioskModal';
import Confirm from '../../../shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { deleteKiosk } from '../../../../api/Kiosk';
import Icon from '../../../shared/Icon/Icon';
import Popup from '../../../shared/Filters/Popup';

export default function PatientPayments() {
  const [editKiosk, setEditKiosk] = React.useState(null);
  const [deleteKioskModal, setDeleteKioskModal] = React.useState(null);
  const outletContext = useOutletContext();

  const ColorCircle = ({ value }) => {
    return (
      <div className="flex items-center">
        <div className="w-6 h-6 rounded-full !mr-2" style={{ backgroundColor: value }}></div>
        {' '}
        {value}
      </div>
    );
  };

  const Actions = ({ data }) => {
    return (
      <div className="ml-[6px] flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`flex h-[34px] w-[34px] items-center justify-center border rounded-full border-solid border-neutral-300 ${!open && 'hover:!bg-white'
                } ${open && '!bg-primary-700 transition-all'}`}>
              <Icon
                icon={'three-dots'}
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={160}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <div
                data-qa="edit-product-btn"
                className="flex items-center gap-1 !p-2 transition-all hover:bg-primary-50 !px-4 cursor-pointer"
                onClick={() => {
                  setEditKiosk(data);
                  close();
                }}>
                <Icon icon={'new-edit'} className={'cursor-pointer'} color={'primary'} />
                <div className="ml-1 text-sm text-primary-900">Edit</div>
              </div>
              <div
                data-qa="delete-product-btn"
                className="flex items-center gap-1 !p-2 transition-all hover:bg-primary-50 !px-4 cursor-pointer"
                onClick={() => {
                  setDeleteKioskModal(data.id);
                  close();
                }}>
                <Icon icon={'trash'} className={'cursor-pointer'} color={'primary'} />
                <div className="ml-1 text-sm text-primary-900">Delete</div>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  };

  const cols = [
    { field: 'id', headerName: 'ID' },
    { field: 'name', headerName: 'Name' },
    { field: 'color', headerName: 'Color', cellRenderer: ColorCircle },
    { field: 'status', headerName: 'Status', valueFormatter: ({ value }) => capitalize(value) },
    { field: 'state', headerName: 'State', valueFormatter: ({ value }) => capitalize(value) },
    {
      field: 'last_active',
      headerName: 'Last Active',
      valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY HH:mm.ss')
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: Actions,
      width: 48,
      minWidth: 48,
      cellStyle: { 'padding-left': 0 },
      resizable: false,
      suppressMovable: true
    }
  ];

  return (
    <TableContextProvider name="kiosks_table" cols={cols}>
      <Table
        deleteKioskModal={deleteKioskModal}
        setDeleteKioskModal={setDeleteKioskModal}
        editKiosk={editKiosk}
        setEditKiosk={setEditKiosk}
        practiceId={outletContext?.practiceId}
      />
    </TableContextProvider>
  );
}

function Table({ editKiosk, setEditKiosk, deleteKioskModal, setDeleteKioskModal, practiceId }) {
  const [showNewKioskModal, setShowNewKioskModal] = React.useState(false);

  const { data } = useKiosks({ params: { practiceId } });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleDelete = async () => {
    await deleteKiosk(navigate, { practiceId: practiceId, id: deleteKioskModal });
    queryClient.invalidateQueries(['kiosks']);
    setDeleteKioskModal(null);
  };

  return (
    <>
      <Header title="Kiosks">
        <div className="flex gap-2">
          <DisplayButton />
          <Button
            onClick={() => setShowNewKioskModal(true)}
            text="Add new Kiosk"
            icon="plus"
            size="small"></Button>
        </div>
      </Header>
      <div className="!pb-2 flex  flex-col overflow-hidden h-[94%]">
        <div className="ag-theme-quartz h-full  !mb-0">
          <AGTable data={data?.kiosks} />
        </div>
      </div>
      <NewKioskModal
        show={showNewKioskModal}
        kioskNr={data?.kiosks?.length || 0 + 1}
        setShow={setShowNewKioskModal}
        kiosks={data?.kiosks}
      />
      <EditKioskModal kiosk={editKiosk} setKiosk={setEditKiosk} />
      {!!deleteKioskModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Kiosk"
          message="Are you sure you want to delete Kiosk?"
          handleContinue={handleDelete}
          handleOpen={!!deleteKioskModal}
          handleClose={() => setDeleteKioskModal(null)}
        />
      )}
    </>
  );
}
