import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import Button from 'components/shared/Buttons/Button';
import DeleteEvent from './DeleteEventPopover';

const Footer = ({ appointment, event, type, handleHideHover }) => {
  const navigate = useNavigate();
  const { setCurrentModal, setConfirmModal } = useDashboardContext();

  return (
    <div className="relative z-10 mt-auto flex items-center justify-end gap-2 !px-4 py-[6px] shadow-[0px_-4px_16px_rgba(0,0,0,0.04)]">
      {type === 'appointment' && appointment?.status !== 'cancelled' && (
        <Button
          icon="calendar-remove"
          size="small"
          iconSize={14}
          outlined
          className="!px-[7px] !py-[7px]"
          onClick={() => {
            handleHideHover();
            setConfirmModal({ type: 'appointment', id: appointment?.id, show: true });
          }}
        />
      )}

      {type === 'event' && <DeleteEvent event={event} handleHideHover={handleHideHover} />}
      <Button
        icon="new-eye"
        size="small"
        iconSize={14}
        outlined
        className="!px-[7px] !py-[7px]"
        onClick={() => {
          setCurrentModal({
            show: true,
            id: type === 'appointment' ? appointment?.id : event?.id,
            type
          });
          handleHideHover();
        }}
      />

      {type === 'appointment' && appointment?.status !== 'cancelled' && (
        <Button
          text="Start visit"
          size="small"
          onClick={() => {
            navigate(`/portal/charts/${appointment?.user_id}/clinical-notes/${appointment?.id}`);
          }}
        />
      )}
    </div>
  );
};

export default Footer;
