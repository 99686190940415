import React, { useCallback } from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import useComponentAdd from '../../hooks/useComponentAdd';
import NoteTypeButtons from './NoteTypeButtons';
import DescriptionSection from './DescriptionSection';
import ComponentButtons from './ComponentButtons';
import { useMyScribeTemplateContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import PromptMessages from './PromptMessages';
import '../../../../MyScribeAI.scss';
import ComponentSelectorModal from './ComponentSelectorModal';
import { useRecoilState, useRecoilValue } from 'recoil';
import { intelligenceAiState, clinicalNote as clinicalNoteState } from 'components/state';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';
import { ChatFooter } from './ChatFooter';

const Chat = () => {
  const breadcrumb = useBreadcrumb();
  const {
    componentToAdd,
    inputTags,
    onAddComponent,
    onTagRemove,
    onTagPreview,
    setComponentToAdd
  } = useComponentAdd();
  const IsComponentInTags = inputTags?.find((tag) => tag?.id === componentToAdd?.id);
  const { currentActivePrompt } = useMyScribeTemplateContextData();
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const onAcceptAndMap = useCallback(async () => {
    if (
      intelligenceAi?.loading === false &&
      currentActivePrompt?.response === intelligenceAi?.response &&
      intelligenceAi?.response
    ) {
      const trim = (t) => {
        if (t.includes(':')) {
          return t.replace(/\\:/g, '').trim();
        } else {
          return t.trim();
        }
      };

      let newSoap = { subjective: '', objective: '', assessment: '', plan: '' };
      let splitA = intelligenceAi?.response?.split(/Plan|plan/g);
      newSoap.plan = trim(splitA[1]);
      splitA = splitA[0].split(/Assessment|assessment/g);
      newSoap.assessment = trim(splitA[1]);
      splitA = splitA[0].split(/Objective|objective/g);
      newSoap.objective = trim(splitA[1]);
      splitA = splitA[0].split(/Subjective|subjective/g);
      newSoap.subjective = trim(splitA[1]);
      setClinicalNote((ps) => ({
        ...ps,
        ...newSoap
        // notes: intelligenceAi?.response
      }));
    }
  }, [clinicalNote]);

  const onComponentAdd = () => {
    if (IsComponentInTags) {
      setComponentToAdd(null);
      return;
    }
    onAddComponent();
  };

  return (
    <div className="relative flex h-full w-full flex-col pb-10">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-10 z-10 bg-primary-10" />
      <NoteTypeButtons />
      <div className="relative z-20 flex-grow overflow-y-auto">
        <div className="max-width-[760px] mx-auto">
          {currentActivePrompt ? <PromptMessages /> : <DescriptionSection />}
        </div>
      </div>
      <div
        className={cs(
          'relative bottom-0 z-50 w-full flex-none bg-white',
          currentActivePrompt && 'bottom-10'
        )}>
        <div
          className="absolute top-[-32px] z-50 h-[32px] w-full self-center bg-gradient-to-b from-white/70 to-white backdrop-blur-md"
          style={{ backdropFilter: 'blur(2px)' }}
        />
        <div className="z-50 mx-auto flex max-w-[760px] flex-col">
          {!currentActivePrompt ? (
            <ComponentButtons onTagPreview={onTagPreview} onTagRemove={onTagRemove} />
          ) : (
            <div className="mb-2 flex items-center justify-center gap-2">
              <SignAndLock
                buttonProps={{
                  text: 'Accept & Map Response',
                  loading: intelligenceAi?.loading,
                  onClick: onAcceptAndMap,
                  className: '!py-2 !pl-4 !pr-4 mr-2 border-[1.5px] border-solid',
                  // outlined: true,
                  primary: true,
                  textClassName: 'text-primary-300',
                  icon: null,
                  iconColor: 'black',
                  disabled: intelligenceAi?.loading
                }}
              />
            </div>
          )}
          <div
            className={cs(
              'mb-[25px] flex flex-col items-center px-4',
              !currentActivePrompt && 'pb-[10px] md:pb-[100px] lg:pb-[50px]'
            )}>
            <ChatFooter />
          </div>
        </div>
      </div>
      {componentToAdd && (
        <ComponentSelectorModal
          componentToAdd={componentToAdd}
          onClose={() => setComponentToAdd(null)}
          onTagRemove={onTagRemove}
          onAddComponent={onComponentAdd}
          breadcrumb={breadcrumb}
          IsComponentInTags={IsComponentInTags}
        />
      )}
    </div>
  );
};

export default Chat;
