import React from 'react';
import Switch from 'components/shared/Switch/Switch';
import { NotesPopover } from '../components/TypeNotesSidebar/NotesPopover';
import Icon from 'components/shared/Icon/Icon';

export const groupComponentsByType = (components) => {
  return components.reduce((acc, component) => {
    const { label, title, forms, type } = component;
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push({ title, forms, type });
    return acc;
  }, {});
};

export function filterCheckedForms(data) {
  const result = {};

  for (const key in data) {
    const section = data[key];

    // Filter components with checked: true
    const filteredComponents = {};
    for (const componentKey in section.components) {
      if (section.components[componentKey].checked === true) {
        filteredComponents[componentKey] = { ...section.components[componentKey] };
      }
    }

    // Copy the section if there are any checked components
    if (Object.keys(filteredComponents).length > 0) {
      result[key] = {
        ...section,
        components: filteredComponents
      };
    }
  }

  return result;
}

export function getHeaderButtons(typeOfNotes, onSelectTypeOfNotes, forms) {
  return [
    // { id: 1, component: <Icon icon="new-reset" />, tooltip: 'Reset selection' },
    // { id: 2, component: <Icon icon="save-changes" />, tooltip: 'Reset selection' },
    {
      id: 3,
      component: (
        <NotesPopover
          buttonContent={<Icon icon="new-add-square" />}
          className="p-0"
          position="bottom"
          isFixed
          icon="new-add-square">
          {typeOfNotes.map((option) => (
            <div className="flex justify-between" key={option.type}>
              <div>{option.label}</div>
              <Switch
                onChange={(e) => onSelectTypeOfNotes(e, option)}
                checked={!!forms?.[option.type]?.checked}
                data-qa="note-choose-switch"
              />
            </div>
          ))}
        </NotesPopover>
      ),
      tooltip: 'Reset selection'
    }
  ];
}
