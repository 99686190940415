import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { NewAppointmentContext } from './NewAppointmentContext';
import { currentPractice } from 'components/practice/practiceState';

export const NewAppointmentContextProvider = ({ children }) => {
  const [selectedService, setSelectedService] = useState('');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [selectedPractitioner, setSelectedPractitioner] = useState('');
  const [selectedResource, setSelectedResource] = useState('');
  const [newPatientModal, setNewPatientModal] = useState(false);
  const [selectedTimeLength, setSelectedTimeLength] = useState({ value: 10, label: '10 min' });
  const [selectedLocation, setSelectedLocation] = useState('');
  const [additionalTimes, setAdditionalTimes] = useState([]);

  const practice = useRecoilValue(currentPractice);
  const { data: resourcesData } = useResourcesAsOptions();
  const resources = resourcesData?.resources || [];

  const NewAppointmentValidationSchema = Yup.object().shape({
    patientId: Yup.string().required('Patient required'),
    practitionerId: Yup.string().required('Practitioner required'),
    startsAt: Yup.string().required('Start time required'),
    appointmentLength: Yup.string().required('Appointment length required'),
    procedures: Yup.array().min(1, 'At least one procedures is required')
  });

  const formik = useFormik({
    initialValues: {
      patientId: '',
      practitionerId: '',
      resourceId: '',
      practiceNote: '',
      appointmentType: '',
      startsAt: moment.tz(new Date(), practice?.timezone).format(),
      appointmentLength: selectedTimeLength?.value || '',
      appointmentLocation: '',
      procedures: [],
      products: [],
      packages: [],
      enable_notifications: !!practice?.display_settings?.appointment?.default_notifications
    },
    // enableReinitialize: true,
    validationSchema: NewAppointmentValidationSchema,
    onSubmit: async (values, {}) => {}
  });

  return (
    <NewAppointmentContext.Provider
      value={{
        formik,

        selectedService,
        setSelectedService,

        selectedPractitioner,
        setSelectedPractitioner,

        resources,
        selectedResource,
        setSelectedResource,

        selectedTimeLength,
        setSelectedTimeLength,

        newPatientModal,
        setNewPatientModal,

        selectedPatient,
        setSelectedPatient,

        selectedLocation,
        setSelectedLocation,

        additionalTimes,
        setAdditionalTimes
      }}>
      {children}
    </NewAppointmentContext.Provider>
  );
};
