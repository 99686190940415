import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';
import { calculateTotalCharge } from 'components/practice/charts/SuperBillPage/lib';
import { iaRa } from 'lib/helpers/utility';
import { orderBy } from 'lodash';

export const claimWithInvoiceProcedures = ({ claim = {} }) => {
  if (!claim) return null;

  const invoiceProcedures = iaRa(
    claim?.superbill?.encounter?.appointment?.invoice?.procedures
  ).filter(filterSuperbillClaimProcedure);

  claim.procedures = orderBy(invoiceProcedures, ['created_at']);

  claim.total_charge = calculateTotalCharge(claim.procedures, true);

  return claim;
};
