import { useQuery } from '@tanstack/react-query';
import { getFormReview } from 'api/CustomForms';

import { useNavigate } from 'react-router-dom';

export const useFormReview = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['form-review', ...dependencies], () => getFormReview(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,

    ...options
  });
};
