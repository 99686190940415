import React from 'react';
import Input from '../../../../shared/Input/Input';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import Header from 'components/shared/Header/Header';
import Allowed from 'components/shared/Permissions/Allowed';

const HeaderTable = ({ setNewProductModalVisible, filters, setFilters }) => (
  <Header title="Products">
    <div className="flex">
      <Input
        id="searchProducts"
        type="string"
        placeholder="Search product..."
        value={filters.searchTerm}
        rounded="full"
        icon="new-search"
        className="!mr-5 !rounded-2xl !border-secondary-400 md:w-[200px]"
        onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
        rightText={
          filters.searchTerm && (
            <Icon
              icon="new-close"
              onClick={() => {
                setFilters({ ...filters, searchTerm: '', page: 1 });
              }}
            />
          )
        }
      />
      <Allowed requiredPermissions="products.create">
        <Button
          data-qa="new-product-btn"
          type="primary"
          text="New Product"
          className="!w-max"
          onClick={() => setNewProductModalVisible(true)}
        />
      </Allowed>
    </div>
  </Header>
);

export default HeaderTable;
