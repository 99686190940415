import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMedication } from 'api/Medication';
import SearchProviders from 'components/Search/SearchProviders/SearchProviders';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';
import { permissions } from 'components/state';
import { useFormik } from 'formik';
import { formatDate } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SelectMedicationTable from '../../CurrentMedications/components/SelectMedicationTable';
import { isMedicationReadOnly } from '../../lib/aggregatedDataHelper';
import { setPatientAggregateNarrativeState } from '../../Provider/lib/patientAggregateNarrativeStates';
import { MEDICATION_STATUS, UNIT_OPTIONS } from '../lib/constants';
import { createObjectWithKeys } from '../lib/helpers';
import { ProviderMedicationSchema } from '../lib/providerMedicationSchema';

const UpsertProviderMedication = ({
  showModal = false,
  hideModal = () => null,
  disabled = false
}) => {
  const { id: patientId, appointmentId } = useParams();
  const navigate = useNavigate();
  const userPermissions = useRecoilValue(permissions);

  const medicationReadOnly = isMedicationReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: showModal?.data
  });

  const formik = useFormik({
    initialValues: createObjectWithKeys(showModal?.data),
    enableReinitialize: true,
    validationSchema: disabled || medicationReadOnly ? null : ProviderMedicationSchema,
    onSubmit: async (values) => {
      await upsertFormFunctionMutation.mutateAsync({
        patientId,
        appointmentId,
        medication: { medications: [values] }
      });
    }
  });
  const handleCurrentUnitChange = (unit) => {
    formik.setFieldValue('unit', unit);
  };

  const onChangeCount = (type, value) => {
    const count = Number(formik.values?.[type]) || 0;
    const updatedCount = value === 'increment' ? count + 1 : Math.max(0, count - 1);

    formik.setFieldValue(type, updatedCount);
  };

  const queryClient = useQueryClient();
  const upsertFormFunctionMutation = useMutation((data) => createMedication(navigate, data), {
    onSettled: async (data) => {
      const { code, error = null } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Medication',
          message: error ?? 'Medication creation failed! Please contact support.',
          color: 'danger'
        });

        hideModal();
        return;
      }

      const noKnown = await queryClient.getQueryData([
        'patientAggregateNarrative',
        patientId,
        'medications'
      ])?.data?.narrative?.no_known;

      if (patientId && noKnown) {
        await setPatientAggregateNarrativeState({
          queryClient,
          keys: [patientId, 'medications'],
          payload: {
            synced: true,
            no_known: false
          }
        });
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['medications'] }),
        queryClient.invalidateQueries({ queryKey: ['medication'] })
      ]);
      hideModal();
    }
  });

  const handleSelectMedication = async (medication) => {
    formik.setFieldValue('medication', capitalize(medication?.generic_name));
    if (medication?.product_ndc) {
      formik.setFieldValue('product_ndc', medication?.product_ndc);
    }
    if (medication?.product_id) {
      formik.setFieldValue('product_ndc_id', medication?.product_id);
    }
    if (medication?.route) {
      formik.setFieldValue('route', medication?.route);
    }
  };

  return (
    <Modal
      handleOpen={showModal?.open}
      handleClose={hideModal}
      className="max-h-[90dvh] w-[1160px]"
      bodyClassName="bg-primary-10"
      modalCenterAnimation={true}
      isFooter={false}
      customHeader={
        <div className="flex w-full items-center justify-between">
          <Breadcrumb
            activeClassName="text-primary-700"
            breadcrumb={{
              setStep: () => {},
              step: 1,
              hasBackButton: false,
              steps: [
                { title: 'Medication' },
                { title: formik?.values?.id ? 'Update Medication' : 'Create Medication' }
              ]
            }}
            key="breadcrumb"
          />
        </div>
      }
      isFooterSticky>
      <form className="lg:px-[230px] xl:px-[230px]">
        {medicationReadOnly && (
          <AlertContent
            title="Restricted: Read-Only Data Access"
            message="Editing this area is currently restricted. Contact your internal systems admin if needed."
            color="warning"
            width="full"
            className="!mb-4"
          />
        )}

        <div>
          <label className="text-sm font-500 text-neutral-800">
            Is this medication currently active or inactive?
          </label>
          <span className="text-danger-500">*</span>

          <div className="!mt-3 flex gap-4">
            <Checkbox
              label="Active medication"
              rounded={true}
              handleClick={() => formik.setFieldValue('status', MEDICATION_STATUS.ACTIVE)}
              isChecked={formik.values?.status === MEDICATION_STATUS.ACTIVE}
              disabled={disabled || medicationReadOnly}
            />
            <Checkbox
              label="Inactive medication"
              rounded={true}
              handleClick={() => formik.setFieldValue('status', MEDICATION_STATUS.INACTIVE)}
              isChecked={formik.values?.status === MEDICATION_STATUS.INACTIVE}
              disabled={disabled || medicationReadOnly}
            />
          </div>

          {formik.errors?.status && (
            <p className="!pt-2 text-left text-sm text-danger-500">{formik.errors?.status}</p>
          )}
        </div>

        <div className="relative !mt-4 grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
          <SelectMedicationTable
            medication={formik?.values?.medication}
            handleSelectMedication={handleSelectMedication}
            tableClassName="top-[76px]"
            required={true}
            errorInput={formik?.errors?.medication}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="NDC"
            name="product_ndc"
            value={formik?.values?.product_ndc}
            onChange={formik.handleChange}
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="mt-4">
          <Textarea
            isEditor
            label="SIG"
            name="sig"
            value={formik?.values?.sig}
            onChange={(event) => formik.setFieldValue('sig', event)}
            className="min-h-[150px]"
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="mt-4 grid grid-cols-5 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <div className="col-span-2 sm:col-span-1 md:col-span-1">
            <Input
              label="Unit"
              name="strength"
              value={formik?.values?.strength}
              onChange={(event) => formik.setFieldValue(`strength`, event?.target?.value)}
              units={UNIT_OPTIONS}
              currentUnit={formik?.values?.unit}
              onUnitChange={handleCurrentUnitChange}
              unitPanelClassName="left-0 !z-[9999] h-[300px] overflow-y-scroll"
              error={formik?.errors?.strength || formik?.errors?.unit}
              disabled={disabled || medicationReadOnly}
            />
          </div>
          <Input
            label="Quantity"
            name="quantity"
            value={formik?.values?.quantity}
            type="number"
            hideNumberArrows={true}
            onChange={() =>
              formik.setFieldValue(
                'quantity',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            iconClick={() => onChangeCount('quantity', 'decrement')}
            rightIconClick={() => onChangeCount('quantity', 'increment')}
            icon="new-minus"
            rightIcon="new-plus"
            inputClassName="text-center"
            error={formik?.errors?.quantity}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="Days Supply"
            name="days_supply"
            value={formik?.values?.days_supply}
            type="number"
            hideNumberArrows={true}
            onChange={() =>
              formik.setFieldValue(
                'days_supply',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            iconClick={() => onChangeCount('days_supply', 'decrement')}
            rightIconClick={() => onChangeCount('days_supply', 'increment')}
            icon="new-minus"
            rightIcon="new-plus"
            inputClassName="text-center"
            error={formik?.errors?.days_supply}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="Refills"
            name="refills"
            value={formik?.values?.refills}
            type="number"
            hideNumberArrows={true}
            onChange={() =>
              formik.setFieldValue(
                'refills',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            iconClick={() => onChangeCount('refills', 'decrement')}
            rightIconClick={() => onChangeCount('refills', 'increment')}
            icon="new-minus"
            rightIcon="new-plus"
            inputClassName="text-center"
            error={formik?.errors?.refills}
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="!mt-4 grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
          <Select
            inputId="select-condition"
            label="Condition"
            id="condition"
            value={formik?.values?.condition}
            name="condition"
            onChange={(event) => formik?.setFieldValue('condition', event)}
            isCreatable
            isClearable
            isMulti
            components={{ DropdownIndicator: null }}
            placeholder="Type and select condition"
            disabled={disabled || medicationReadOnly}
          />
          <SearchProviders
            label="Prescriber"
            id="prescribingPhysician"
            placeholder="Prescriber"
            value={formik?.values?.prescriber}
            onChange={(event) => formik?.setFieldValue('prescriber', event)}
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="!mt-4 grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
          <DatePopover
            label="Start Date"
            name="start_date"
            onChange={(date) =>
              formik.setFieldValue('start_date', formatDate(date, undefined, true))
            }
            value={formik.values?.start_date}
            placementY="bottom-[120%] z-[999] right-0 border border-solid border-neutral-100"
            error={formik?.errors?.start_date}
            disabled={disabled || medicationReadOnly}
          />
          {formik.values?.status === MEDICATION_STATUS.INACTIVE && (
            <DatePopover
              label="End Date"
              name="end_date"
              onChange={(date) =>
                formik.setFieldValue('end_date', formatDate(date, undefined, true))
              }
              value={formik.values?.end_date}
              placementY="bottom-[120%] z-[999] right-0 border border-solid border-neutral-100"
              error={formik?.errors?.end_date}
              disabled={disabled || medicationReadOnly}
            />
          )}
        </div>

        <div className="mt-4">
          <Textarea
            isEditor
            label={
              <div className="flex gap-1">
                <span className="text-sm font-500 text-neutral-800">Note</span>
                <span className="test-sm text-neutral-500">(Optional)</span>
              </div>
            }
            name="note"
            value={formik?.values?.note}
            onChange={(event) => formik.setFieldValue('note', event)}
            className="min-h-[150px]"
            disabled={disabled || medicationReadOnly}
          />
        </div>
      </form>
      <ModalFooter
        className="z-50"
        leftButtons={<Button outlined text="Cancel" color="neutral" onClick={hideModal} />}
        rightButtons={
          <Button
            text={formik?.values?.id ? 'Update' : 'Create'}
            data-qa="upsert-btn"
            onClick={() => formik?.submitForm()}
            loadingIcon={upsertFormFunctionMutation?.isLoading}
            disabled={upsertFormFunctionMutation?.isLoading || disabled || medicationReadOnly}
          />
        }
      />
    </Modal>
  );
};

export default UpsertProviderMedication;
