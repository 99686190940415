import React, { useState } from 'react';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';

const SavePopupContent = ({ onClose = () => {}, onSaveForms }) => {
  const [inputValue, setInputValue] = useState('');

  const closePopup = () => {
    onClose();
  };

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const onSave = () => {
    onSaveForms(inputValue);
    setInputValue('');
  };

  return (
    <div className="flex w-[230px] flex-col gap-2 !pr-3">
      <Input
        value={inputValue}
        label="Filter name"
        data-qa="filter-name"
        onChange={onInputChange}
        placeholder="Enter filter name..."
      />
      <div className="flex justify-between">
        <div
          onClick={closePopup}
          className="z-50 !ml-1 flex cursor-pointer items-center gap-1  text-sm text-gray-500">
          <Icon icon="new-close-sm" className="cursor-pointer" data-qa="close"></Icon>
          Close
        </div>
        <Button
          onClick={onSave}
          text="Save"
          size="small"
          icon="new-diskette"
          data-qa="save"
          disabled={!inputValue.trim()?.length}
        />
      </div>
    </div>
  );
};

export default SavePopupContent;
