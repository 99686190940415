import React from 'react';

import { PAYER_TYPES } from 'lib/constants/payer/payerTypes';

import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

import { customSelectStyle } from './lib/styles';

const findPropertyByValue = (array, value) => {
  const foundItem = array?.find((item) => item?.value == value);
  return foundItem?.label;
};

const PayerForm = ({ formik, states }) => {
  const { setFieldValue, values, errors, handleChange } = formik;

  return (
    <div className="grid gap-y-3">
      <Input
        label="Name"
        placeholder="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        id="name"
        error={errors.name}
      />
      <Input
        label="Phone number"
        placeholder="+1(123)4567890"
        name="phone"
        value={values.phone}
        onChange={handleChange}
        id="phoneNumber"
        error={errors.phone}
      />

      <Input
        label="Address 1"
        placeholder="Address"
        name="address_ln_1"
        value={values.address_ln_1}
        onChange={handleChange}
        id="address_ln_1"
        error={errors.address_ln_1}
      />
      <Input
        label="Address 2"
        type="text"
        placeholder="Address 2"
        name="address_ln_2"
        value={values.address_ln_2}
        onChange={handleChange}
        id="address_ln_2"
      />
      <Input
        label="City"
        type="text"
        placeholder="City"
        name="city"
        value={values.city}
        onChange={handleChange}
        id="city"
      />
      <Input
        label="Zip"
        placeholder="Zip"
        name="zip"
        value={values.zip}
        onChange={handleChange}
        id="zip"
        error={errors.zip}
      />
      <Select
        label="State"
        className="select-full-width w-full"
        placeholder="Type and select a state"
        value={{ label: findPropertyByValue(states, formik?.values?.state) }}
        options={states}
        onChange={(v) => {
          setFieldValue('state', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        inputId="countryAddress"
        error={errors.state || null}
        menuPortalTarget={document.body}
      />
      <Select
        label="Type"
        className="select-full-width w-full"
        placeholder="Type and select a type"
        value={{ label: findPropertyByValue(PAYER_TYPES, formik?.values?.type) }}
        options={PAYER_TYPES}
        onChange={(v) => {
          setFieldValue('type', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        error={errors.type || null}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default PayerForm;
