import moment from 'moment';

export function calculateUnitTimeLength(startTime, endTime) {
  const start = moment(startTime, 'hh:mm A');
  const end = moment(endTime, 'hh:mm A');
  const differenceInMinutes = end.diff(start, 'minutes');
  if (differenceInMinutes < 0) {
    return 0;
  }

  return differenceInMinutes;
}
