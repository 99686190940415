import { Honeybadger } from '@honeybadger-io/react';
import Axios from 'configuredAxios';
import isNumber from 'lodash/isNumber';

export const requestApi = async ({
  url,
  params,
  onSuccess = () => {},
  onError = () => {},
  onCatch = () => {},
  navigate = () => {},
  filePath = '',
  throwError = false,
  method = 'post',
  returnRaw = false,
  signal = null, // for abortion requests
  responseType = 'json'
}) => {
  const honeyBadgerNotify = (error) => {
    Honeybadger.notify(`${JSON.stringify({ error })}`, `[error]requestApi:${url}`, {
      tags: `error,frontend,${filePath}`
    });
  };
  try {
    const res = await Axios.request({ method, url, data: params, signal, responseType });

    localStorage.setItem('last_active', new Date().valueOf().toString());

    if (responseType === 'blob' && res.data instanceof Blob) {
      try {
        // Convert blob to text and parse JSON
        const resData = await res.data.text();
        const data = JSON.parse(resData);
        res.data = data;
      } catch {
        if (onSuccess) onSuccess(res.data);
        return res.data;
      }
    }

    const { redirect, error, code, ...data } = res.data;

    switch (code) {
      case -1:
        !!navigate && navigate(redirect);
        break;
      case 0:
        onSuccess(data, res.data, redirect);
        break;
      case 1:
        onError(error, code, redirect);
        if (!signal) honeyBadgerNotify(error);
        break;
      case 2:
      case 3:
      case 4:
      case 5:
        onError(error, code, redirect);
        break;
      default:
        onError(error, code, res.data);
        if (!signal) honeyBadgerNotify(error);
        break;
    }
    if (code !== 0 && throwError) throw { error, code };
    if (returnRaw) {
      return res;
    } else {
      return res.data;
    }
  } catch (error) {
    if (throwError) {
      throw error;
    }
    onCatch(error);
    if (!signal) {
      console.error(error);
      !isNumber(error?.code) && honeyBadgerNotify(error);
    }
    return { data: {}, headers: {}, status: 403 };
  }
};
