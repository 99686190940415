import React from 'react';

import { pluralize } from 'lib/helpers/utility';
import { useCurrentPractice } from 'lib/hooks/queries/practice/useCurrentPractice';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';
import useModal from 'lib/hooks/useModal';

import Button from 'components/shared/Buttons/Button';
import Typography from 'components/shared/Typography/Typography';

import ManageSpecialtyModal from './ManageSpecialtyModal';

const SpecialtySOFHeader = ({ count, onEditServices }) => {
  const { isOpen, openModal, closeModal } = useModal();

  const { data } = useCurrentPractice();
  const { data: professionGroupOptions } = useProfessionGroups();

  const professions = professionGroupOptions?.professions || [];
  const practice = data?.practice || {};

  const professionGroup = professions?.filter(
    (group) => group.id === practice?.profession_group_id
  );

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-row items-start justify-between gap-4">
        <div className="flex flex-col items-start gap-3">
          <Typography size="large" weight="medium">
            Welcome to Schedule of Fees
          </Typography>
          <div className="flex justify-end items-center gap-2">
            <Typography className="neutral-500">Your practice specialty:</Typography>
            <div className="flex flex-wrap items-start gap-2">
              {professionGroup.map((profession) => (
                <Typography
                  key={profession.id}
                  className="text-primary-900 bg-[#E6F8FF] rounded p-1.5 py-0.5">
                  {profession.name}
                </Typography>
              ))}
            </div>
          </div>
        </div>
        <Button text="Manage Specialty" outlined onClick={openModal} />
      </div>
      <div className="flex justify-between items-center gap-4 bg-[linear-gradient(90deg,#F3FCFF_-18.5%,#ECFAFF_-10.85%,#E6F8FF_30.79%)] pl-6 pr-4 py-3 rounded-xl">
        <Typography variant="h3" color="primary-700">
          {count}
        </Typography>
        <Typography size="large" weight="medium" className="flex-1" color="primary-700">
          {pluralize('Service', count)} tailored for your practice specialties.
        </Typography>
        <Button text="Edit Services" outlined type="secondary" onClick={onEditServices} />
      </div>
      {isOpen && <ManageSpecialtyModal isOpen={isOpen} onClose={closeModal} />}
    </div>
  );
};

export default SpecialtySOFHeader;
