module.exports = {
  questions: {
    create_type: {
      id: self.crypto.randomUUID(),
      title: 'Choose Your Form Type',
      description:
        'Patient-facing forms are sent directly to patients, while clinical templates are filled out by providers within clinical notes. Patient forms can be integrated into clinical notes, and clinical templates can also be shared with patients to streamline data collection for encounters.',
      answers: [
        {
          id: self.crypto.randomUUID(),
          title: 'Patient-Facing Forms',
          description: 'Can be integrated with your clinical note.',
          value: 'patient_facing_form'
        },
        {
          id: self.crypto.randomUUID(),
          title: 'Clinical Template',
          description: 'Can be sent to patients if added to a form packet.',
          value: 'clinical_template'
        }
      ]
    },
    clinical_template: {
      existing: {
        id: self.crypto.randomUUID(),
        title: 'Will this clinical template be used for existing or new patients?',
        description:
          'Please note that when integrating patient forms into the clinical note, AI-narratives will be generated based on the data that is inputted in order to save you time and eliminate redundancies.',
        answers: [
          {
            id: self.crypto.randomUUID(),
            title: 'Existing Patients',
            description: 'This will be a progress note format.',
            value: true
          },
          {
            id: self.crypto.randomUUID(),
            title: 'New Patients',
            description:
              'This will be an intake note format, will also apply to patients who have not visited in 12 months.',
            value: false
          }
        ]
      }
    },
    patient_facing_form: {
      integrate: {
        id: self.crypto.randomUUID(),
        title: 'How Do You Want to Integrate This Form?',
        description:
          'When integrating patient forms into clinical notes, AI-enhanced narratives will be created based on approved data only. This will save time and eliminate redundancies.',
        answers: [
          {
            id: self.crypto.randomUUID(),
            title: 'Sync with Clinical Note',
            description: 'Integrate patient responses within my narratives.',
            value: true
          },
          {
            id: self.crypto.randomUUID(),
            title: 'Do not integrate for now',
            description: 'This can always be set up later',
            value: false
          }
        ]
      },
      receive: {
        id: self.crypto.randomUUID(),
        title: 'When Will Patients Receive Forms?',
        description:
          'Decide to integrate patient forms into the intake or progress section of your clinical note.',
        answers: [
          {
            id: self.crypto.randomUUID(),
            title: 'Every Visit',
            description: '',
            value: true
          },
          {
            id: self.crypto.randomUUID(),
            title: 'First Visit or Every 12 Months',
            description: '',
            value: false
          }
        ]
      }
    }
  }
};
