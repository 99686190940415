import React, { useEffect } from 'react';
import Modal from 'components/shared/Modal/Modal';
import TagSelect from 'components/shared/Tags/Select';
import Button from 'components/shared/Buttons/Button';
import { useFaxContext } from 'lib/context/FaxContext/FaxContext';
import { isEqual } from 'lodash';

const AddTag = ({ handleAddTag, openTagModal, setOpenTagModal }) => {
  const { faxTags, setFaxTags, prevTags, disableAdd, setDisableAdd, setPrevTags } = useFaxContext();

  useEffect(() => {
    if (!isEqual(prevTags, faxTags)) {
      setDisableAdd(false);
    }
  }, [prevTags, faxTags]);
  return (
    <Modal
      title="Update Tags"
      isOpen={openTagModal}
      handleClose={() => {
        setFaxTags([]);
        setPrevTags([]);
        setOpenTagModal(false);
      }}
      footer={<Button text="Update Tags" onClick={() => handleAddTag()} disabled={disableAdd} />}
      className="!w-[540px] max-w-[540px]">
      <TagSelect
        label={false}
        currTags={faxTags}
        kind="fax"
        menuPortalTarget={document.body}
        setTagIds={setFaxTags}
      />
    </Modal>
  );
};

export default AddTag;
