import { iaRa } from 'lib/helpers/utility';

import Select from 'components/shared/Select/Select';

import { useOptions } from '../../../lib/useOptions';

const FilterSelect = ({ data, index, setFilter, menuPortalTarget, placeholder }) => {
  const { data: optionData } = useOptions([data?.queryKey], data?.queryFn, data?.params);
  const options = iaRa(optionData?.options);

  const handleChange = (selectedOptions) => {
    const values = selectedOptions.map((option) => ({
      ...option,
      displayLabel: option.label
    }));
    setFilter(index, { ...data, values });
  };

  return (
    <div className="flex flex-col">
      <p className="text-sm font-medium pb-2">{data?.title || 'Select'}</p>
      <div className="relative flex flex-wrap items-center gap-x-3 gap-y-1 pl-[10px] mr-3">
        <Select
          placeholder={placeholder}
          value={data?.values}
          options={options}
          onChange={handleChange}
          isMulti
          menuPortalTarget={menuPortalTarget}
          isClearable={false}
          parentClassName="w-full"
          noDropdown
          styles={{
            control: {
              outline: '0',
              border: 'none',
              padding: '0',
              minHeight: '1.5rem',
              boxShadow: '0'
            },
            valueContainer: {
              padding: '0'
            }
          }}
        />
      </div>
      <div className="mt-1 border border-t-0 border-solid border-neutral-200"></div>
    </div>
  );
};

export default FilterSelect;
