import Button from 'components/shared/Buttons/Button';
import { usePatientBalanceContextApi } from 'lib/context/PatientBalanceContext/PatientBalanceContext';
import React from 'react';

export const UnAllocateAction = ({ data, rowIndex }) => {
  const { setActiveMode, setActiveTab, setAllocatingRow } = usePatientBalanceContextApi();

  const onAllocateClick = () => {
    setActiveTab(0);
    setAllocatingRow({ ...data });
    setActiveMode({ writeoff: false, allocate: true });
  };
  return (
    <div className="flex h-full items-center justify-center">
      <Button
        data-qa={`allocate-${rowIndex}`}
        size="small"
        type="secondary"
        text="Allocate"
        onClick={onAllocateClick}
      />
    </div>
  );
};
