import React, { useImperativeHandle, useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

const Assessment = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const assessmentRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { assessment: clinicalNote?.assessment }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write assessment notes here"
      id="assessment"
      name="assessment"
      className="min-h-[200px]"
      value={clinicalNote?.assessment}
      onChange={(e) => handleChange(e, 'assessment')}
      transcribing
      forwardedRef={assessmentRef}
      disabled={clinicalNote?.locked || intelligenceAi.loading}
    />
  );
};

export default Assessment;
