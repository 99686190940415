import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { formatDateAndTime, ia, iaRa, mString } from '../../../../lib/helpers/utility';
import practiceState, { currentPractice } from '../../practiceState';
import TableCounter from '../../../shared/Table/TableCounter';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { AlertContent } from '../../../shared/Alert/Alert';

export default function PatientLedger({ patientId, startDate, endDate }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ledger, setLedger] = useState([]);
  const filters = useRecoilValue(practiceState.reportingFilters);
  const [order, setOrder] = useState({
    date: false,
    claimId: false,
    provider: false,
    charges: false.valueOf,
    ins_payment: false,
    adjustment: false,
    ins_balance: false,
    pat_resp: false,
    pat_payment: false,
    pat_balance: false,
    total_balance: false
  });
  const practice = useRecoilValue(currentPractice);
  const isPatients = iaRa(filters?.patient).some((row) => row?.value);

  useEffect(() => {
    loadTransactions();
  }, [filters, startDate, endDate]);

  const orderBy = (column) => {
    let orderedLedger = [];
    switch (column) {
      case 'provider':
        const provider = ledger?.map((row) => {
          return {
            ...row,
            provider: row?.practitioner?.fullName || row?.superbill?.practitioner?.fullName
          };
        });
        orderedLedger = provider?.sort((a, b) => {
          return order[column]
            ? a?.provider?.toUpperCase() > b?.provider?.toUpperCase()
              ? 1
              : -1
            : b?.provider?.toUpperCase() > a?.provider?.toUpperCase()
            ? 1
            : -1;
        });
        setLedger(orderedLedger);
        break;
      case 'date':
        const date = ledger?.map((row) => {
          return { ...row, date: row.created_at || row.dos };
        });
        orderedLedger = date?.sort((a, b) => {
          if (order[column]) {
            return moment(a?.date).isBefore(moment(b?.date)) ? 1 : -1;
          } else {
            return moment(b?.date).isBefore(moment(a?.date)) ? 1 : -1;
          }
        });
        setLedger(orderedLedger);
        break;
      case 'charges':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column]
            ? a?.totalCharges - b?.totalCharges
            : b?.totalCharges - a?.totalCharges;
        });
        setLedger(orderedLedger);
        break;
      case 'ins_payment':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column]
            ? a?.insurancePayment - b?.insurancePayment
            : b?.insurancePayment - a?.insurancePayment;
        });
        setLedger(orderedLedger);
        break;
      case 'adjustment':
        const adjustment = ledger?.map((row) => {
          return { ...row, adjustment: row?.insuranceCharge - row?.insurancePayment };
        });
        orderedLedger = adjustment?.sort((a, b) => {
          return order[column] ? a?.adjustment - b?.adjustment : b?.adjustment - a?.adjustment;
        });
        setLedger(orderedLedger);
        break;
      case 'ins_balance':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column]
            ? a?.insuranceBalance - b?.insuranceBalance
            : b?.insuranceBalance - a?.insuranceBalance;
        });
        setLedger(orderedLedger);
        break;
      case 'pat_resp':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column] ? a?.charge - b?.charge : b?.charge - a?.charge;
        });
        setLedger(orderedLedger);
        break;
      case 'pat_payment':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column] ? a?.payment - b?.payment : b?.payment - a?.payment;
        });
        setLedger(orderedLedger);
        break;
      case 'pat_balance':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column]
            ? a?.patientBalance - b?.patientBalance
            : b?.patientBalance - a?.patientBalance;
        });
        setLedger(orderedLedger);
        break;
      case 'total_balance':
        const total_balance = ledger?.map((row) => {
          return { ...row, total_balance: row?.patientBalance + row?.insuranceBalance };
        });
        orderedLedger = total_balance?.sort((a, b) => {
          return order[column]
            ? a?.total_balance - b?.total_balance
            : b?.total_balance - a?.total_balance;
        });
        setLedger(orderedLedger);
        break;
      case 'claimId':
        orderedLedger = ledger?.sort((a, b) => {
          return order[column] ? a?.claim?.id - b?.claim?.id : b?.claim?.id - a?.claim?.id;
        });
        setLedger(orderedLedger);
        break;
      default:
        break;
    }
  };

  const loadTransactions = async () => {
    if (filters.patient || patientId) {
      try {
        let params = {
          patientId: patientId || filters?.patient?.id?.toString(),
          startDate: startDate ? startDate : filters?.selectedRange[0].startDate || null,
          endDate: endDate ? endDate : filters?.selectedRange[0].endDate || null
        };

        if (ia(filters?.patient)) params = { ...params, multipleIds: filters?.patient };

        setLoading(true);

        const res = await interimApi(
          `/api/practice/billing/reporting/patient_ledger`,
          params,
          navigate
        );

        const { code, redirect, error, ledger: loadedLedger } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;

          case 0:
            if (ia(loadedLedger)) {
              setLedger(loadedLedger);
            }
            break;

          default:
            if (error) toast.error(error);
            else toast.error(`An unexpected code has been encountered. Please try again later.`);
            break;
        }
      } catch (error) {
        console.error(error);
        toast.error(`An unexpected error has occurred. Please try again later.`);
      }
      setLoading(false);
    } else {
      setLedger([]);
    }
  };

  if (loading) return <Skeleton count={6} />;

  return (
    <div className="!p-4 grid overflow-hidden">
      <div className="overflow-auto !rounded-md relative">
        {!patientId && !isPatients && (
          <AlertContent message="Select a patient or payer to see the ledger" className="!mb-4" />
        )}
        <table className="primary-table">
          <thead>
            <tr>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    date: !order.date
                  });
                  orderBy('date');
                }}>
                Date of Service
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    claimId: !order.claimId
                  });
                  orderBy('claimId');
                }}>
                Claim ID
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    provider: !order.provider
                  });
                  orderBy('provider');
                }}>
                Provider
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    charges: !order.charges
                  });
                  orderBy('charges');
                }}>
                Charges
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    ins_payment: !order.ins_payment
                  });
                  orderBy('ins_payment');
                }}>
                Ins. Payment
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    adjustment: !order.adjustment
                  });
                  orderBy('adjustment');
                }}>
                Adjustment
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    ins_balance: !order.ins_balance
                  });
                  orderBy('ins_balance');
                }}>
                Ins. Balance
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    pat_resp: !order.pat_resp
                  });
                  orderBy('pat_resp');
                }}>
                Pat. Resp.
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    pat_payment: !order.pat_payment
                  });
                  orderBy('pat_payment');
                }}>
                Pat. Payment
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    pat_balance: !order.pat_balance
                  });
                  orderBy('pat_balance');
                }}>
                Pat. Balance
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    total_balance: !order.total_balance
                  });
                  orderBy('total_balance');
                }}>
                Total Balance
              </th>
            </tr>
          </thead>
          <tbody>
            {ia(ledger) ? (
              [...ledger]?.map((row, index) => {
                return (
                  <tr key={index} className="whitespace-nowrap">
                    <td>{formatDateAndTime(row.dos || row.created_at, practice.timezone, true)}</td>
                    <td>{row?.claim?.id}</td>
                    <td>{row?.practitioner?.fullName || row?.superbill?.practitioner?.fullName}</td>
                    <td>{mString(row?.totalCharges)}</td>
                    <td>{mString(row?.insurancePayment)}</td>
                    <td>{mString(row?.insuranceCharge - row?.insurancePayment)}</td>
                    <td>{mString(row?.insuranceBalance)}</td>
                    <td>{mString(row?.charge)}</td>
                    <td>{mString(row?.payment)}</td>
                    <td>{mString(row?.patientBalance)}</td>
                    <td>{mString(row?.patientBalance + row?.insuranceBalance)}</td>
                  </tr>
                );
              })
            ) : (
              <tr className="relative">
                <td>
                  <span className="empty">No data found!</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TableCounter page={1} limit={ledger.length} count={ledger.length} />
    </div>
  );
}
