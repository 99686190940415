import React from 'react';
import { useRecoilState } from 'recoil';
import { Link } from 'react-router-dom';
import { userState } from 'components/state';
import Logo from 'components/shared/Logo/Logo';
import SidebarList from './components/SidebarList';
import ProfileWithTooltip from 'components/Profile/ProfileWithTooltip';
import Logout from 'components/shared/Logout/Logout';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Sidebar = ({ list }) => {
  const [user] = useRecoilState(userState);

  return (
    <aside className="z-40 flex w-[40px] flex-col justify-between">
      <Link to="/portal" className="flex h-[40px] w-full items-center justify-center">
        <Logo />
      </Link>
      <div className="flex grow flex-col justify-between shadow-[4px_0px_16px_rgba(0,79,107,0.06)]">
        <SidebarList list={list} />
        <div className="flex flex-col items-center gap-3 pb-[86px]">
          {user?.email && <ProfileWithTooltip user={user} />}
          <Logout showText={false} />
        </div>
      </div>
    </aside>
  );
};

export default withErrorBoundary(Sidebar);
