import React from 'react';
import checkinBackgroundPNG from '../assets/Images/checkin-background.png';

const MFADescription = () => {
  return (
    <div className="h-[100vh] overflow-hidden bg-transparent p-[1.2rem]">
      <img
        src={checkinBackgroundPNG}
        className="absolute top-0 left-0 z-10 h-full w-full object-cover"
      />
      <div className="relative z-20 flex h-full overflow-hidden rounded-xl border-[0.75px] border-solid border-white bg-slate-50 py-8 px-10 flex-col">
        <div className=" text-3xl text-primary-500">
          Two-Factor Authentication (2FA) Description for Myriad Health
        </div>
        <div className="h-80vh overflow-y-scroll w-full text-lg">
          <p className="mb-4">
            Myriad Health secures user accounts by implementing two-factor authentication (2FA), an
            essential security feature that requires two forms of identification from users before
            they can access their accounts. This process significantly enhances account security by
            adding a layer of protection against unauthorized access, even in the event of password
            compromise.
          </p>

          <h3 className="text-xl font-semibold mb-2">Authentication Process:</h3>
          <h4 className="text-lg font-semibold mb-2">Primary Authentication:</h4>
          <p className="mb-4">
            Users begin by entering their registered email address and password. Upon successful
            entry of these credentials, the system initiates the secondary authentication phase.
          </p>

          <h4 className="text-lg font-semibold mb-2">
            Secondary Authentication - SMS Verification:
          </h4>
          <ul className="list-disc mb-4 ml-5">
            <li>
              Once the primary authentication is confirmed, the system automatically sends a text
              message containing a unique, one-time verification code to the phone number associated
              with the user's account.
            </li>
            <li>The user is then prompted to enter this verification code into the system.</li>
            <li>
              After the correct verification code is entered, the user is granted access to their
              account.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Security Features:</h3>
          <ul className="list-disc mb-4 ml-5">
            <li>
              One-Time Code: The verification code is for single use and expires shortly after it is
              sent to ensure security and to prevent potential misuse.
            </li>
            <li>
              Automated Delivery: The code is sent automatically to the user’s registered mobile
              phone number, reducing the risk of human error in the distribution of codes.
            </li>
            <li>
              Encryption Standards: All data transmissions, including the sending of the
              verification code, are protected with industry-standard encryption protocols.
            </li>
          </ul>

          <h3 className="text-xl font-semibold mb-2">Compliance and Standards:</h3>
          <p className="mb-4">
            The 2FA system complies with recommended security practices, aligning with guidelines
            from authoritative bodies such as the National Institute of Standards and Technology
            (NIST).
          </p>

          <h3 className="text-xl font-semibold mb-2">User Enrollment and Experience:</h3>
          <ul className="list-disc mb-4 ml-5">
            <li>
              Upon the initial setup, users are required to register a mobile phone number for 2FA.
            </li>
            <li>
              For subsequent logins, the 2FA process is streamlined to ensure a balance between
              robust security and a user-friendly experience.
            </li>
            <li>
              Users have the option to update their registered phone number through secure account
              settings.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MFADescription;
