import React from 'react';
import cs from 'classnames';
import Icon from '../Icon/Icon';

const ActionButton = ({
  text = '',
  icon = null,
  iconSize,
  iconIsStroke,
  onClick = () => {},
  className
}) => {
  if (!text && !icon) return null;
  return (
    <div
      className={cs(
        'flex cursor-pointer select-none items-center gap-x-2 border-0 border-l border-solid border-neutral-200 bg-neutral-50 px-3 py-[9px]',
        className
      )}
      onClick={onClick}>
      {icon && (
        <Icon
          icon={icon}
          color="neutral"
          size={iconSize}
          stroke={iconIsStroke}
          className="cursor-pointer"
        />
      )}
      {text && <p className="not-italic">{text}</p>}
    </div>
  );
};

export default ActionButton;
