import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';

import { requestApi } from 'api/Api';

import { Capitalize, ia, snakeToTitleCase } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popup from 'components/shared/Popup/Popup';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const GrowthDataList = ({
  growthData,
  setGrowthData,
  formik,
  setShowModal,
  setMode,
  loading,
  setLoading
}) => {
  const [deleteId, setDeleteId] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const handleUpdate = (values) => {
    setShowModal(true);

    formik.setValues(values);

    setMode((prev) => ({ ...prev, update: true }));
  };

  const handleDelete = async () => {
    setLoading({ ...loading, delete: true });

    try {
      const data = await requestApi({
        url: 'api/admin/growth_data/delete',
        params: { id: deleteId },
        navigate
      });

      const { code, error } = data || {};

      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          setGrowthData((prevState) => prevState.filter((growth) => growth.id !== deleteId));
          showAlert({ title: 'Deleted successfully', color: 'success' });
          setDeleteId();
          formik.resetForm();
          setShowConfirmationModal(false);
          break;

        default:
          if (error) showAlert({ title: error, color: 'danger' });
          Honeybadger.notify(
            `file: /databank/GrowthData/ListAndDelete, method: handleDelete - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          break;
      }
    } catch (error) {
      console.log(error);
      Honeybadger.notify(
        `file: /databank/GrowthData/ListAndDelete, method: handleDelete - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }

    setLoading({ ...loading, delete: false });
  };

  return (
    <div className="!p-4">
      <table className="primary-table">
        <thead>
          <tr>
            <th>Measurement Type</th>
            <th>Measurement Value</th>
            <th>Chart Type</th>
            <th>Gender</th>
            <th>L</th>
            <th>M</th>
            <th>S</th>
            <th>SD</th>
            <th>P01</th>
            <th>P1</th>
            <th>P3</th>
            <th>P5</th>
            <th>P10</th>
            <th>P15</th>
            <th>P25</th>
            <th>P50</th>
            <th>P75</th>
            <th>P85</th>
            <th>P90</th>
            <th>P95</th>
            <th>P97</th>
            <th>P99</th>
            <th>P999</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {loading?.get ? (
            <tr>
              <td colSpan={7} className="bg-white">
                <Skeleton count={10} height={60} />
              </td>
            </tr>
          ) : (
            ia(growthData) &&
            growthData.map((row, i) => {
              return (
                <tr key={i} style={{ zIndex: growthData.length - i }}>
                  <td>{snakeToTitleCase(row?.measurement_type)}</td>
                  <td>{row?.measurement_value}</td>
                  <td>{snakeToTitleCase(row?.chart_type)}</td>
                  <td>{Capitalize(row?.gender)}</td>
                  <td>{row?.l}</td>
                  <td>{row?.m}</td>
                  <td>{row?.s}</td>
                  <td>{row?.sd}</td>
                  <td>{row?.p01}</td>
                  <td>{row?.p1}</td>
                  <td>{row?.p3}</td>
                  <td>{row?.p5}</td>
                  <td>{row?.p10}</td>
                  <td>{row?.p15}</td>
                  <td>{row?.p25}</td>
                  <td>{row?.p50}</td>
                  <td>{row?.p75}</td>
                  <td>{row?.p85}</td>
                  <td>{row?.p90}</td>
                  <td>{row?.p95}</td>
                  <td>{row?.p97}</td>
                  <td>{row?.p99}</td>
                  <td>{row?.p999}</td>
                  <td>
                    <Popup
                      trigger={(open) => (
                        <div
                          className={`flex h-[34px] w-[34px] items-center justify-center rounded 6px border-neutral-300 hover:border ${
                            !open && 'hover:!bg-white'
                          } ${open && '!bg-primary-700 transition-all'}`}>
                          <Icon
                            icon={'three-dots'}
                            className="flex cursor-pointer"
                            color={open ? 'white' : 'black'}
                          />
                        </div>
                      )}
                      width={180}
                      on={['hover', 'focus']}
                      contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 6px 0px #004F6B33' }}
                      position={['bottom right', 'top center']}>
                      {(close) => (
                        <div className=" !py-[6px]">
                          <div
                            data-qa="update"
                            className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                            onClick={() => {
                              close();
                              handleUpdate(row);
                            }}>
                            <Icon
                              icon={'new-edit'}
                              className={'cursor-pointer'}
                              color={'primary'}
                            />
                            <div className="ml-1 text-sm text-primary-900">Update</div>
                          </div>
                          <div
                            data-qa="delete"
                            className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                            onClick={() => {
                              close();
                              setDeleteId(row.id);
                              setShowConfirmationModal(true);
                            }}>
                            <Icon icon={'trash'} className={'cursor-pointer'} color={'primary'} />
                            <div className="ml-1 text-sm text-primary-900">Delete</div>
                          </div>
                        </div>
                      )}
                    </Popup>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>

      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Growth Row"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this Growth Row?"
          loading={loading?.delete}
          handleContinue={handleDelete}
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default GrowthDataList;
