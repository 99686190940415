import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Spinner from 'components/shared/spinner/spinner';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SavedCardSale = ({
  card,
  amount,
  onSuccess = () => {},
  onFail = () => {},
  invoiceToken,
  invoices,
  setReceipt = () => {},
  appointmentId,
  surcharge
}) => {
  const [show, setShow] = useState({ loading: false });
  const navigate = useNavigate();

  useEffect(() => {
    if (card?.id) {
      chargeSavedCard();
    } else {
      onFail(['Please select a card']);
    }
  }, []);

  const chargeSavedCard = async () => {
    setShow({ ...show, loading: true });
    try {
      await requestApi({
        url: '/api/transactions/token/sale',
        params: {
          amount,
          invoiceToken,
          invoices,
          appointmentId,
          cardId: card.id,
          surchargeAmount: surcharge
        },
        navigate,
        onSuccess: (data) => {
          setReceipt(data?.receipt?.data);
          onSuccess(data.receipt?.transaction_id);
        },
        onError: (error) => {
          onFail(error);
        }
      });
    } catch (error) {
      console.error(error);
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        color: 'error'
      });
      onFail(`An unexpected error occurred, please report with time of error occurrence.`);
    }
    setShow({ ...show, loading: false });
  };

  if (show.loading) {
    return (
      <div className="flex h-full flex-col items-center justify-center !gap-5">
        <Spinner />
        Please wait while transaction is processing
      </div>
    );
  }

  return <div></div>;
};

export default SavedCardSale;
