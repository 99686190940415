import React, { useState, useEffect } from 'react';
import Modal from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/Buttons/Button';
import TPList from './views/TPList';
import TreatmentPlan from './TreatmentPlan';
import { ia, io } from '../../../../../lib/helpers/utility';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useDocumentSign } from '../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../../state';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTreatmentPlan, updateTreatmentPlan } from '../../../../../api/TreatmentPlan';
import { showAlert } from '../../../../shared/Alert/Alert';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMessage from '../../../../shared/ErrorMessage/ErrorMessage';
import SignModal from '../shared/Sign/SignModal';

const TPModal = ({
  isOpen,
  handleClose,
  loadView,
  origin,
  availableDates,
  loadEPNToCN,
  isModal = true,
  handlePreview = () => {},
  footer
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const {
    selectedTreatmentPlan,
    setSelectedTreatmentPlan,
    clinicalNote,
    setClinicalNote,
    setLoading
  } = useClinicalNoteContext();
  const { patient } = useOutletContext();
  const { id } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);
  const navigate = useNavigate();
  const [view, setView] = useState(loadView ?? 'empty');
  const [btnText, setBtnText] = useState('Create treatment plan');

  useEffect(() => {
    if ((ia(treatmentPlan) && view === 'empty') || loadView === 'list') {
      setView('list');
      setBtnText('Select treatment plan');
    }
    if (loadView === 'edit') {
      setView('edit');
      setBtnText('Update treatment plan');
      setSelectedTreatmentPlan(availableDates[0]);
    }
    if (loadEPNToCN) {
      setBtnText('Load data in clinical note');
    }
  }, [loadView]);

  const updateTP = useMutation(
    (data) =>
      updateTreatmentPlan(navigate, {
        treatmentId: selectedTreatmentPlan.id,
        objToBeUpdated: data
      }),
    {
      onSuccess: () => {
        showAlert({
          title: 'Treatment plan updated successfully!'
        });
        queryClient.invalidateQueries('treatmentPlan');
      }
    }
  );

  const createTP = useMutation(
    (data) =>
      createTreatmentPlan(navigate, {
        ...data,
        patient_id: id,
        procedureData: ia(clinicalNote.cpt_codes)
          ? clinicalNote.cpt_codes.map((row) => row.code?.value).filter(Boolean)
          : [],
        payerData: ia(patient.insuranceProfile)
          ? patient.insuranceProfile.map((row) => row?.payer_id).filter(Boolean)
          : []
      }),
    {
      onSuccess: (data) => {
        const createdTreatmentPlanId = data?.data?.id;
        showAlert({
          title: 'Treatment plan created successfully!'
        });
        queryClient.invalidateQueries('treatmentPlan');
        setSelectedTreatmentPlan({ ...selectedTreatmentPlan, id: createdTreatmentPlanId });
      }
    }
  );

  const handleBtnClick = ({ nextView = '', btnText = '' }) => {
    let tempNextView = nextView;
    let tempBtnText = btnText;

    switch (tempNextView) {
      case 'empty':
        tempNextView = 'create';
        tempBtnText = 'Create treatment plan';
        break;
      case 'create':
        tempNextView = 'preview';
        tempBtnText = 'Create treatment plan';
        createTP.mutate(selectedTreatmentPlan);
        handlePreview(new Date());
        break;
      case 'list':
        tempNextView = 'preview';
        tempBtnText = 'Select treatment plan';
        break;
      case 'preview':
        tempNextView = 'edit';
        tempBtnText = 'Edit treatment plan';
        break;
      case 'edit':
        tempNextView = 'preview';
        tempBtnText = 'Update treatment plan';
        updateTP.mutate(selectedTreatmentPlan);
        break;

      default:
        if (view === 'empty') {
          tempNextView = 'create';
          tempBtnText = 'Create treatment plan';
        } else if (view === 'create') {
          tempNextView = 'preview';
          tempBtnText = 'Edit treatment plan';
          createTP.mutate(selectedTreatmentPlan);
        } else if (view === 'list' && selectedTreatmentPlan?.id) {
          tempNextView = 'preview';
          tempBtnText = 'Edit treatment plan';

          if (loadEPNToCN) {
            setLoading(true);

            showAlert({ title: 'Loaded data successfully!' });
            const modifiedTP = {
              ...selectedTreatmentPlan,
              icd_10_codes: selectedTreatmentPlan.icd10
            };
            delete modifiedTP.id;
            delete modifiedTP.icd10;
            setClinicalNote({
              ...clinicalNote,
              ...modifiedTP,
              user_id: selectedTreatmentPlan.patient_id
            });

            setTimeout(() => {
              setLoading(false);
              handleClose();
            }, 300);
          }
        } else if (view === 'preview') {
          tempNextView = 'edit';
          tempBtnText = 'Update treatment plan';
        } else if (view === 'edit') {
          tempNextView = 'preview';
          tempBtnText = 'Edit treatment plan';
          updateTP.mutate(selectedTreatmentPlan);
          break;
        }
        break;
    }
    setView(tempNextView);
    setBtnText(tempBtnText);
  };

  const { data: hasUserSigned } = useDocumentSign({
    params: {
      documentIds: [selectedTreatmentPlan?.id],
      userIds: [user?.id]
    },
    dependencies: [selectedTreatmentPlan?.id],
    options: {
      enabled: isOpen && !!selectedTreatmentPlan?.id,
      select: (response) => response.data.find((row) => row.user_id == user.id)?.status === 'signed'
    }
  });

  const noProvidersSelected = (selectedTreatmentPlan.treatment_team ?? []).every(
    (item) => item.provider === null
  );

  if (!isModal) {
    return (
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <TreatmentPlan view={view} origin={origin} />

        {selectedTreatmentPlan?.id && (
          <SignModal
            setShowConfirmationModal={setShowConfirmationModal}
            showConfirmationModal={showConfirmationModal}
            documentId={selectedTreatmentPlan.id}
            documentTypes={['treatment_plan']}
          />
        )}
        {footer ? (
          <div className="flex justify-center">
            {footer({
              submit: handleBtnClick,
              isSubmitting: createTP.isLoading,
              disabled:
                (view === 'list' && !selectedTreatmentPlan?.id) ||
                ((view === 'edit' || view === 'create') &&
                  (noProvidersSelected ||
                    !selectedTreatmentPlan?.treatment_frequency_date?.startDate ||
                    !selectedTreatmentPlan?.treatment_frequency_date?.endDate))
            })}
          </div>
        ) : null}
      </ErrorBoundary>
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Treatment plan"
      slideFromRight
      className="w-[720px] sm:w-full"
      headButton={
        view === 'preview' && io(selectedTreatmentPlan) && !hasUserSigned ? (
          <Button
            text="Sign"
            icon="new-lock-2-opened"
            iconIsStroke
            type="secondary"
            className="!ml-4"
            onClick={() => setShowConfirmationModal(true)}
          />
        ) : null
      }
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            outlined
            color="neutral"
            text="Close"
            onClick={handleClose}
            data-qa="close-modal-btn"
          />
          <Button
            data-qa={`modal-btn-${btnText}`}
            text={btnText}
            onClick={handleBtnClick}
            disabled={
              (view === 'list' && !selectedTreatmentPlan?.id) ||
              ((view === 'edit' || view === 'create') &&
                (noProvidersSelected ||
                  !selectedTreatmentPlan?.treatment_frequency_date?.startDate ||
                  !selectedTreatmentPlan?.treatment_frequency_date?.endDate))
            }
          />
        </div>
      }>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        {(io(selectedTreatmentPlan) && view === 'preview') ||
        view === 'create' ||
        view === 'edit' ? (
          <TreatmentPlan view={view} origin={origin} />
        ) : (
          <TPList data={treatmentPlan} onClick={handleBtnClick} />
        )}
        {selectedTreatmentPlan?.id && (
          <SignModal
            setShowConfirmationModal={setShowConfirmationModal}
            showConfirmationModal={showConfirmationModal}
            documentId={selectedTreatmentPlan.id}
            documentTypes={['treatment_plan']}
          />
        )}
      </ErrorBoundary>
    </Modal>
  );
};

export default TPModal;
