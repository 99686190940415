import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Switch from 'components/shared/Switch/Switch';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';

const ManagePermission = ({ permission, highlight }) => {
  const { title, description, actions } = permission;
  const [enabledAll, setEnabledAll] = useState(false);
  const { formik } = useNewRoleContext();

  const enableAll = () => {
    if (formik.values.permissions !== null) {
      actions.forEach((action) => {
        const [fieldGroup, fieldAction] = action.field.split('.');
        formik.setFieldValue(`permissions.${fieldGroup}.${fieldAction}`, true);
      });
      setEnabledAll(true);
    }
  };

  const disableAll = () => {
    if (formik.values.permissions !== null) {
      actions.forEach((action) => {
        const [fieldGroup, fieldAction] = action.field.split('.');
        formik.setFieldValue(`permissions.${fieldGroup}.${fieldAction}`, false);
      });
      setEnabledAll(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-start gap-4 self-stretch rounded-md bg-white p-4">
      <div className="flex w-full justify-between bg-white">
        <div>
          <p className="text-sm font-semibold leading-snug text-neutral-800">
            <Highlight text={title} highlight={highlight} />
          </p>
          <p className="text-sm font-normal leading-snug text-neutral-700">
            <Highlight text={description} highlight={highlight} />
          </p>
        </div>
        <Button
          text={!enabledAll ? 'Enable all' : 'Disable all'}
          transparent
          onClick={() => {
            if (!enabledAll) {
              enableAll();
            } else {
              disableAll();
            }
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-0.5">
        {actions.map((action, index) => {
          const [fieldGroup, fieldAction] = action.field.split('.');
          return (
            <div
              key={index}
              className={`inline-flex items-center justify-between px-4 py-2 ${formik.values.permissions?.[fieldGroup]?.[fieldAction] ? '' : 'bg-neutral-50'}`}>
              <div>
                <p className="text-sm font-medium leading-snug text-primary-900">
                  <Highlight text={action.title} highlight={highlight} />
                </p>
                <p className="text-xs font-normal leading-tight text-neutral-500">
                  <Highlight text={action.description} highlight={highlight} />
                </p>
              </div>
              <div className="relative h-[18px] w-[30px] rounded-[15px] bg-neutral-100">
                <Switch
                  checked={formik.values.permissions?.[fieldGroup]?.[fieldAction]}
                  onChange={(value) => {
                    formik.setFieldValue(`permissions.${fieldGroup}.${fieldAction}`, value);
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ManagePermission;
