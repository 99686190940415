import React, { useRef } from 'react';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import NewProduct from './NewProduct';

const ProductModal = ({ product, isOpen, handleClose, filters }) => {
  const productFormRef = useRef();

  return (
    <Modal
      customStyling={{ width: '700px' }}
      bodyClassName="!p-4 !max-h-[unset] !h-full"
      handleOpen={isOpen}
      handleClose={handleClose}
      title={`${product ? 'Edit' : 'Create new'} product`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={handleClose}
            id="cancelProductModalBtn"
          />
          <Button
            onClick={() => productFormRef.current.submitForm()}
            text={product ? 'Update' : 'Create product'}
            id="createProductModalBtn"
            data-qa="create-update-products-btn"
          />
        </div>
      }>
      <NewProduct ref={productFormRef} onClose={handleClose} product={product} filters={filters} />
    </Modal>
  );
};

export default ProductModal;
