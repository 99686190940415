import { Popover } from '@headlessui/react';
import cs from 'classnames';
import React, { useState } from 'react';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../../shared/Icon/Icon';
import TPModal from '../../TreatmentPlan/TPModal';

const NavbarPopover = () => {
  const { cnDisplaySettings, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [showTPModal, setShowTPModal] = useState(false);

  const openTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(true);
  };

  const closeTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(false);
  };

  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <div>
            <Popover.Button className="p-0" data-qa="clinical-notes-menu">
              <Icon
                shade={100}
                color="primary"
                stroke
                icon="chevrons-down"
                className={cs(
                  'flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-md bg-primary-800',
                  open && '!bg-primary-700'
                )}
              />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-max min-w-[160px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
              {cnDisplaySettings?.treatment_plan?.enabled && (
                <div
                  data-qa="treatment-plan-btn"
                  className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                  onClick={openTPModal}>
                  <Icon icon="new-task-square" />
                  <span className="text-sm font-400 text-primary-900">Treatment plan</span>
                </div>
              )}
            </Popover.Panel>
          </div>
        )}
      </Popover>
      <TPModal isOpen={showTPModal} handleClose={closeTPModal} />
    </>
  );
};

export default NavbarPopover;
