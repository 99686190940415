import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

export const useOptions = (queryKey, queryFn, params) => {
  const navigate = useNavigate();
  return useQuery(queryKey, () => queryFn(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};
