import React from 'react';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import cs from 'classnames';

import { formatDateAndTimeZ, ia } from '../../../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../../practiceState';

const PreviousNoteTable = ({ allClinicalNotes, isDataFetching, selectedNote, setSelectedNote }) => {
  const practice = useRecoilValue(currentPractice);
  return (
    <table className="primary-table h-full overflow-x-hidden responsive-table selectable-children rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
      <thead>
        <tr>
          <th className="!pl-4">Appointment date</th>
        </tr>
      </thead>
      <tbody>
        {ia(allClinicalNotes) ? (
          allClinicalNotes?.map((note, idx) => (
            <tr
              key={idx}
              data-qa={`note-${idx}`}
              className={cs('cursor-pointer hover:!bg-primary-50')}
              onClick={() => {
                setSelectedNote({ ...note });
              }}>
              <td className="flex h-full items-center gap-x-4">
                <span className="font-500 first-letter:capitalize">
                  {note?.id === selectedNote?.id && isDataFetching ? (
                    <Skeleton />
                  ) : (
                    formatDateAndTimeZ(note?.appointment?.starts_at, practice.timezone)
                  )}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <span className="!py-6 !px-3 block text-center text-sm text-primary-900">
            No previous notes found!
          </span>
        )}
      </tbody>
    </table>
  );
};

export default PreviousNoteTable;
