import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

export default function FooterWrapper({ children, text, onClick, link, hasMore = true }) {
  return (
    <div
      data-dd-privacy="allow"
      className={cs('mt-auto flex flex-col justify-between', children && 'flex-1 overflow-y-auto')}>
      {children && (
        <div className="flex flex-1 flex-col overflow-y-auto">{children}</div>
      )}
      {hasMore && (
        <div
          data-dd-privacy="allow"
          onClick={onClick}
          className="flex cursor-pointer justify-center gap-2 px-4 py-[10px] text-center shadow-[0px_0px_12px_rgba(0,79,107,0.04)]">
          {link && <Icon icon="new-link" />}
          <p className="font-500 text-neutral-600 hover:text-neutral-800">{text}</p>
        </div>
      )}
    </div>
  );
}
