import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { kioskCommand, kioskStartCheckin } from 'api/Kiosk';
import cs from 'classnames';
import { useKiosks } from 'lib/hooks/queries/useKiosks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { requestApi } from '../../../api/Api';
import { interimApi } from '../../../api/InterimApi';
import { checkinV2Map } from '../../../components/Patient/checkin/constants';
import { ia, iaRa } from '../../../lib/helpers/utility';
import useScrollBlock from '../../../lib/hooks/useScrollBlock';
import { patientAppointment } from '../../Patient/patientState';
import state, { userState } from '../../state';
import { showAlert } from '../Alert/Alert';
import Box from '../Box/Box';
import Button from '../Buttons/Button';
import Popup from '../Filters/Popup';
import Icon from '../Icon/Icon';
import Modal from '../Modal/Modal';
import Allowed from '../Permissions/Allowed';
import Skeleton from '../Skeleton/Skeleton';
import './CheckinSteps.scss';
import Steps from './Steps';
const CheckinSteps = ({
  formik,
  appointment,
  refetchCustomForms,
  insuranceProfiles,
  setAppointment
}) => {
  const [checkinSteps, setCheckinSteps] = useState({
    loading: true,
    steps: []
  });
  const setAppointmentState = useSetRecoilState(patientAppointment);
  const [blockScroll, allowScroll] = useScrollBlock();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [redirectToCheckin, setRedirectToCheckin] = useState();
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  const setUser = useSetRecoilState(userState);
  const timeoutRef = useRef();
  const queryClient = useQueryClient();

  const { data: kiosksQuery } = useKiosks({ params: {} });
  const kiosks = kiosksQuery?.kiosks;

  useEffect(() => {
    getCheckinStatus();
    // getPreAuthForm();
  }, [refetchCustomForms]);

  useEffect(() => {
    if (redirectToCheckin) {
      setUser({ ...{}, logged_in: true });
      closeModal();
      allowScroll();
      setShowModal(true);
      setPermissions({ ...permissions, practice_id: null });
    }
  }, [redirectToCheckin]);

  // const getPreAuthForm = async () => {
  //   try {
  //     let result = await interimApi(
  //       '/api/pre_auth/read',
  //       {
  //         patientId: appointment?.user_id,
  //         practiceId: appointment?.practice_id
  //       },
  //       navigate
  //     );
  //     const { preAuthForms } = result.data;
  //
  //     if (ia(preAuthForms)) setCheckinSteps((prevState) => ({ ...prevState, preAuth: true }));
  //   } catch (error) {
  //     Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
  //   }
  // };

  const getCheckinStatus = async () => {
    try {
      let res = await requestApi({
        url: '/api/patient/checkin/status',
        params: {
          appointmentId: appointment?.id
        },
        navigate
      });
      const dataSteps = res?.steps;
      if (ia(dataSteps)) {
        let newSteps = [];
        let customForms = [];
        let formsCompleted = 0;
        dataSteps.forEach((v) => {
          if (v.kind === 'form') {
            customForms.push(v);
            formsCompleted += !!v.completed;
          } else {
            newSteps.push({ ...v, name: checkinV2Map[v.name]?.name() || v.name });
          }
        });

        // if (customForms.length > 0) {
        newSteps.push({
          step_id: customForms[0]?.step_id || 0,
          status: '',
          completed: formsCompleted === customForms.length,
          kind: 'form',
          position: customForms[0]?.position || 99,
          name: 'Custom Forms',
          forms: customForms,
          numCompleted: formsCompleted
        });
        // }

        setCheckinSteps({ loading: false, steps: newSteps });
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const closeModal = (skipTimeout) => {
    if (skipTimeout) {
      clearTimeout(timeoutRef?.current);
      navigate(redirectToCheckin);
    } else {
      timeoutRef.current = setTimeout(() => {
        navigate(redirectToCheckin);
      }, 3000);
    }
    // queryClient.invalidateQueries();
  };

  const checkIn = async () => {
    const appointmentId = appointment?.id;
    try {
      const res = await interimApi(
        `/api/patient/checkin/start`,
        {
          appointmentId,
          patientImpersonate: true
        },
        navigate
      );
      const { redirectTo } = res.data;
      setRedirectToCheckin(redirectTo);
    } catch (error) {
      console.error(error);
    }
    setAppointmentState({ id: appointmentId });
    localStorage.setItem('appointmentId', appointmentId);
  };

  const sendCheckinToKiosk = async (kioskId) => {
    try {
      const res = await kioskStartCheckin(navigate, {
        kioskId,
        appointmentId: appointment?.id
      });

      if (res.redirectTo) {
        await kioskCommand(navigate, {
          kioskId,
          command: 'url-change',
          page: 'checkin',
          patientId: appointment?.patient?.id,
          appointmentId: appointment?.id,
          url: res.redirectTo
        });
      }
      showAlert({ title: 'Sent to Kiosk', color: 'success' });
    } catch (error) {
      showAlert({ title: 'Failed to send to Kiosk', color: 'danger' });
    }
  };

  const handleClose = () => closeModal(true);

  if (showModal) {
    return (
      <Modal
        handleClose={() => {
          closeModal(true);
        }}
        handleOpen={showModal}
        handleClick={() => {
          closeModal(true);
        }}
        title="Check in"
        bodyClassName="py-8"
        footer={
          <div className="flex w-full justify-between">
            <Button text="Cancel" outlined color="neutral" onClick={handleClose} />
            <Button text="Continue" primary onClick={handleClose} />
          </div>
        }>
        <div className="flex flex-col items-center">
          <Icon icon="new-checked" size={80} className="rounded-full shadow-[0_0_30px_#30c9ff]" />
          <p className="!pt-5 text-sm">You can now hand the device over to the patient.</p>
        </div>
      </Modal>
    );
  }

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-sm font-500">Check-in Steps</p>
        {formik.values.status !== 'cancelled' ? (
          <div className="flex gap-2">
            <Button
              transparent
              type="primary"
              color="primary"
              text="Start Check-in"
              data-qa="start-checkin-btn"
              onClick={checkIn}
              size="small"
              className="!p-0"
            />
            <Allowed requiredPermissions="kiosk.read">
              {kiosks && kiosks.length > 0 && (
                <Popup
                  trigger={(open) => (
                    <div
                      className={`flex h-[28px] w-[28px] items-center justify-center rounded-full border  border-neutral-300  hover:border ${
                        !open && 'hover:!bg-white'
                      } ${open && 'border-none !bg-primary-900 transition-all'}`}>
                      <Icon
                        icon="three-dots"
                        className="flex cursor-pointer"
                        color={open ? 'white' : 'black'}
                      />
                    </div>
                  )}
                  width={200}
                  on={['hover', 'focus']}
                  contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
                  position={['top right', 'top center']}>
                  <div className=" !py-[6px]">
                    <Popup
                      trigger={(open) => (
                        <div
                          data-qa="delete-product-btn"
                          className={cs(
                            'flex cursor-pointer items-center justify-between gap-1 !p-2 !px-4 transition-all hover:bg-primary-50',
                            open && 'bg-primary-50'
                          )}>
                          <div className="flex items-center gap-1">
                            <Icon
                              size={18}
                              icon="kiosk-nav"
                              color="primary"
                              className="cursor-pointer"
                            />
                            <div className="ml-1 text-sm text-primary-900">Send to Kiosk</div>
                          </div>
                          <Icon icon="new-chevron-right"></Icon>
                        </div>
                      )}
                      width={200}
                      contentStyle={{
                        borderRadius: '4px',
                        boxShadow: '0px 2px 16px 0px #004F6B33'
                      }}
                      position={['left center']}
                      on={['hover', 'focus']}>
                      {(close) => (
                        <div className=" !py-[6px]">
                          {kiosks?.map((kiosk) => (
                            <div
                              key={kiosk?.id}
                              data-qa="edit-product-btn"
                              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                              onClick={async () => {
                                await sendCheckinToKiosk(kiosk?.id);
                                close();
                              }}>
                              <Icon
                                icon="kiosk"
                                size={18}
                                customColor={kiosk?.color}
                                className="cursor-pointer"
                              />
                              <div className="ml-1 text-sm text-primary-900">{kiosk?.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Popup>
                  </div>
                </Popup>
              )}
            </Allowed>
          </div>
        ) : null}
      </div>
      <Box className="!mt-2 flex flex-col gap-1">
        {checkinSteps.loading && <Skeleton height="36px" count={5} />}
        {!checkinSteps.loading &&
          iaRa(checkinSteps.steps).map((v, i) => (
            <Steps
              kiosks={kiosks}
              step={v}
              index={i}
              key={`checkin-steps-step-${i}`}
              appointment={appointment}
              formik={formik}
              insuranceProfiles={insuranceProfiles}
              setAppointment={setAppointment}
            />
          ))}
      </Box>
    </div>
  );
};

export default CheckinSteps;
