import React from 'react';
import { Transition as HeadlessTransition } from '@headlessui/react';
import { animations } from './animations';

/**
 * @param {Object} props
 * @param {Boolean} props.isOpen - By default true
 * @param {keyof animations} props.type - The types of animation
 * @param {{enter: number, leave: number}} [props.duration={ enter: 200, leave: 100 }]
 *
 */

const Transition = ({
  isOpen = true,
  children,
  type = 'fade',
  duration = { enter: 200, leave: 100 },
  ...rest
}) => {
  const transitionProps = animations[type] || animations.fade;

  return (
    <HeadlessTransition
      show={isOpen}
      enter={`${transitionProps.enter} duration-${duration.enter}`}
      enterFrom={transitionProps.enterFrom}
      enterTo={transitionProps.enterTo}
      leave={`${transitionProps.leave} duration-${duration.leave}`}
      leaveFrom={transitionProps.leaveFrom}
      leaveTo={transitionProps.leaveTo}
      {...rest}>
      {children}
    </HeadlessTransition>
  );
};

export default Transition;
