import React, { useEffect } from 'react';
import Button from '../../../shared/Buttons/Button';
import { requestApi } from 'api/Api';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useFormReview } from 'lib/hooks/queries/useFormReview';
import ReviewCard from './ReviewCard';

const Review = () => {
  const outlet = useOutletContext();

  const { data } = useFormReview({
    params: {
      appointmentId: outlet?.appointmentId,
      steps: outlet.reviewSteps
    },
    dependencies: [outlet?.appointmentId]
  });

  const { kiosk, setContinueText, checkinButton, defaultContinueText } = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    setContinueText('Complete');

    return () => {
      defaultContinueText();
    };
  }, []);

  const setKioskIdle = () => {
    requestApi({
      url: '/api/kiosk/command',
      onSuccess: () => {
        navigate('/kiosk/idle');
      },
      params: {
        command: 'idle'
      }
    });
  };

  return (
    <div className="flex flex-col items-center gap-6 p-6">
      {data?.response &&
        data?.response?.map((data, index) => <ReviewCard data={data} key={index} />)}
      <div
        hidden
        ref={checkinButton}
        onClick={() => (kiosk ? setKioskIdle() : navigate('/login'))}></div>
    </div>
  );
};

export default Review;
