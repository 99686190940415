import React from 'react';

import Tippy from '@tippyjs/react';
import _ from 'lodash';

import Popover from 'components/shared/Popovers/Popover/Popover';
import Switch from 'components/shared/Switch/Switch';

import ResetSettingsButton from '../../shared/ResetSettingsButton';
import SaveSettingsPopup from '../../shared/SaveSettingsPopup';

// Function to hide common components across selected forms but keep one visible
export const updateHiddenComponents = (forms, isEnhanceNote) => {
  // Perform a deep clone of the forms object
  const updatedForms = _.cloneDeep(forms);

  const componentLocations = {};

  // Collect component locations in checked forms
  for (const [formType, form] of Object.entries(updatedForms || {})) {
    if (!form.checked) continue;

    for (const [componentKey, component] of Object.entries(form.components || {})) {
      if (!componentLocations[componentKey]) {
        componentLocations[componentKey] = [];
      }

      // Include only selected components if isEnhanceNote is true
      if (!isEnhanceNote || component.selected) {
        componentLocations[componentKey].push(formType);
      }
    }
  }

  // Update `hide` property for duplicate components
  for (const [componentKey, formTypes] of Object.entries(componentLocations)) {
    const [firstForm, ...restForms] = formTypes;

    // Ensure the first form's component is visible
    if (firstForm) {
      updatedForms[firstForm].components[componentKey] = {
        ...updatedForms[firstForm].components[componentKey],
        hide: false
      };
    }

    // Hide the component in other forms
    for (const formType of restForms) {
      updatedForms[formType].components[componentKey] = {
        ...updatedForms[formType].components[componentKey],
        hide: true
      };
    }
  }

  return updatedForms;
};

export function filterNotHiddenComponents(components) {
  // Filter components with hide: true
  const filteredComponents = {};
  for (const componentKey in components) {
    if (!components[componentKey].hide) {
      filteredComponents[componentKey] = { ...components[componentKey] };
    }
  }

  return filteredComponents;
}

export function transformData(data) {
  const result = [];
  data.forEach((item) => {
    const { label: typeLabel, components, forms, type, isAdvanceForm } = item;
    Object.keys(components).forEach((key) => {
      const component = components[key];
      result.push({
        title: component.title,
        path: component.path,
        position: component.position || null,
        checked: component.checked,
        selected: component.selected,
        isAdvanceForm,
        typeLabel,
        forms,
        key,
        type
      });
    });
  });
  return result;
}

export function getSelectedForms(selectedForms, clinicalNoteForms, advancedForms) {
  const finalSelectedForms = {};
  for (const sectionKey in selectedForms) {
    const section = selectedForms[sectionKey];

    for (const componentKey in section.components) {
      switch (componentKey) {
        case 'complaints':
          finalSelectedForms['present_illness'] = clinicalNoteForms?.['present_illness'];
          finalSelectedForms['other_complaints'] = clinicalNoteForms?.['other_complaints'];
          break;
        case 'patient-needs':
        case 'patientNeeds':
          finalSelectedForms['present_illness'] = clinicalNoteForms?.['present_illness'];
          break;
        case 'cpt-codes':
        case 'cptCodes':
          finalSelectedForms['cpt_codes'] = clinicalNoteForms?.['cpt_codes'];
          break;
        case 'diagnosis-codes':
        case 'diagnosisCodes':
          finalSelectedForms['icd_10_codes'] = clinicalNoteForms?.['icd_10_codes'];
          break;
        case 'orders':
          finalSelectedForms[componentKey] = clinicalNoteForms?.['components_order'];
          break;
        case 'medications':
          finalSelectedForms[componentKey] = clinicalNoteForms?.['medicationHistory'];
          break;
        case 'problemManifestationDefinition':
          finalSelectedForms['other_complaints'] = clinicalNoteForms?.['other_complaints'];
          break;
        case 'riskAndCoMorbidities':
          finalSelectedForms['risk-and-co-morbidities'] =
            clinicalNoteForms?.['risk-and-co-morbidities'];
          break;
        case 'timeFrames':
          finalSelectedForms['time-frames'] = clinicalNoteForms?.['time-frames'];
          break;
        default: {
          if (clinicalNoteForms?.[componentKey]) {
            finalSelectedForms[componentKey] = clinicalNoteForms[componentKey];
          }
          const currentForm = advancedForms?.find((form) => form?.custom_title === componentKey);
          if (currentForm) {
            finalSelectedForms[`formId:${currentForm?.id}`] = {
              ...currentForm,
              isAdvanceForm: true
            };
          }
        }
      }
    }
  }

  return finalSelectedForms;
}
export function saveFormSettings({ saveDisplaySettings, userState, settings = {} }) {
  return saveDisplaySettings({
    ...userState?.display_settings,
    clinicalNote: {
      ...userState?.display_settings?.clinicalNote,
      myscribe_ai: {
        ...(userState?.display_settings?.clinicalNote?.myscribe_ai || {}),
        ...settings
      }
    }
  });
}

export function getEnabledTypesAndForms({ data, isEnhanceNoteTemplate = true }) {
  const result = {};

  for (const key in data) {
    const section = data[key];

    if (data?.[key]?.checked) {
      result[key] = { enabled: data?.[key]?.checked, forms: {} };
      for (const componentKey in section.components) {
        if (isEnhanceNoteTemplate) {
          result[key]['forms'][componentKey] = {
            ...result[key]['forms'][componentKey],
            enabled: !!section?.components?.[componentKey]?.selected
          };
        } else {
          result[key]['forms'][componentKey] = {
            ...result[key]['forms'][componentKey],
            enabled: !!section?.components?.[componentKey]?.checked
          };
        }

        if ('checked' in section.components[componentKey]) {
          result[key]['forms'][componentKey] = {
            ...result[key]['forms'][componentKey],
            checked: !!section?.components?.[componentKey]?.checked
          };
        }
      }
    }
  }

  return result;
}

export function getHeaderButtons({
  typeOfNotes,
  onSelectTypeOfNotes,
  forms,
  setSettings = () => {},
  resetSettings = () => {},
  template = 'enhance_note'
}) {
  const finalForms = getEnabledTypesAndForms({ data: forms });

  const options = typeOfNotes.map((option) => ({
    label: option.label,
    component: (
      <Switch
        onChange={(e) => onSelectTypeOfNotes(e, option)}
        checked={!!finalForms?.[option.type]?.enabled}
        data-qa="note-choose-switch"
      />
    )
  }));

  return [
    {
      id: 1,
      component: (
        <Tippy content="Reset selection" className="tippy-dark" placement="bottom">
          <div className="flex">
            <ResetSettingsButton onReset={resetSettings} />
          </div>
        </Tippy>
      ),
      tooltip: 'Reset selection'
    },
    {
      id: 2,
      component: (
        <SaveSettingsPopup setSettings={setSettings} forms={finalForms} template={template} />
      )
    },
    {
      id: 3,
      component: (
        <Popover
          iconClassName="mr-0"
          panelClassName="max-h-[300px] overflow-y-auto"
          icon="new-add-square"
          options={options}
          buttonClassName="text-centered"
          tooltip="Add additional note type"
          isFixed
        />
      )
    }
  ];
}
