import React from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import { fileIcon, getFileExtension } from '../../../../lib';
import _ from 'lodash';
import Tippy from '@tippyjs/react';

const PatientDoc = ({ tabState, setTabState, isChecked, doc }) => {
  const truncatedDocName = _.truncate(doc.name, {
    length: 60,
    separator: ' '
  });

  return (
    <div className="flex items-center">
      <Checkbox
        isChecked={isChecked}
        handleClick={() =>
          setTabState((prev) => ({
            ...prev,
            selectedFiles: isChecked
              ? prev.selectedFiles.filter((file) => file.id !== doc.id)
              : [
                  ...prev.selectedFiles,
                  {
                    ...doc,
                    patientName: tabState.selectedPatient.fullName,
                    existingFile: !doc.form_json
                  }
                ]
          }))
        }
      />
      <Icon icon={fileIcon[getFileExtension(doc.mimetype)]} className="!mr-2" />
      <Tippy className="tippy-dark" content={doc.name}>
        <div className="flex flex-col">
          <h4 className="text-m font-s text-16 leading-24 text-base font-medium text-neutral-900">
            {truncatedDocName}
          </h4>
        </div>
      </Tippy>
    </div>
  );
};

export default PatientDoc;
