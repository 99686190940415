import React from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { currentPractice } from 'components/practice/practiceState';
import Transactions from 'components/shared/Transactions/transactions';
import { permissions as userPermissions } from 'components/state';

import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS, getColDefs } from './configs';

const TransactionsTable = ({ patient_id }) => {
  const { id } = useParams();
  const patientId = patient_id || id;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const permissions = useRecoilValue(userPermissions);

  return (
    <TableContextProvider
      name="billing_transactions"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone, permissions)}
      pagination>
      <Transactions
        category="billing_transactions"
        patientId={patientId}
        cols={getColDefs(practiceTimezone)}
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
      />
    </TableContextProvider>
  );
};

export default TransactionsTable;
