import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useExternalInvoices } from 'lib/hooks/queries/billing/useExternalInvoices';

import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ExternalInvoicePreview from './components/ExternalInvoicePreview';
import { DEFAULT_FILTERS, getColDefs } from './lib/external/configs';
import { INVOICE_SWITCH_ITEMS } from './lib/helpers';

const ExternalInvoices = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const initialColumnDefs = useMemo(() => getColDefs(practiceTimezone), [practiceTimezone]);

  return (
    <AgTablePage
      ModalComponent={ExternalInvoicePreview}
      defaultColumns={initialColumnDefs}
      defaultFilters={DEFAULT_FILTERS}
      useData={useExternalInvoices}
      name="invoices"
      title="External Invoices"
      rowStyle={{ cursor: 'pointer' }}
      switchItems={INVOICE_SWITCH_ITEMS}
      noNewButton
    />
  );
};

export default ExternalInvoices;
