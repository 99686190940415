import React, { useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Subjective = ({ subjectiveRef, sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();

  useImperativeHandle(sectionRef, () => ({
    formData: { subjective: clinicalNote?.subjective }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write subjective notes here"
      id="subjective"
      data-qa="subjective"
      name="subjective"
      className="min-h-[200px]"
      value={clinicalNote?.subjective}
      onChange={(e) => handleChange(e, 'subjective')}
      transcribing
      forwardedRef={subjectiveRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Subjective;
