import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { deleteCustomForm } from 'api/CustomForms';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, iaRa, optionify } from 'lib/helpers/utility';
import { useCustomForm } from 'lib/hooks/queries/customForms/useCustomForm';
import { useCustomFormTypes } from 'lib/hooks/queries/customForms/useCustomFormTypes';

import PreviewForm from 'components/Patient/questionnaires/preview';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import Popup from 'components/shared/Popup/Popup';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import DuplicateForm from './DuplicateForm';
import EditFormBuilderContainer from './FormEditContainer';

export default function AdminForms() {
  const formRef = useRef();
  const [formToBeUpdated, setFormToBeUpdated] = useState(null);
  const [newFormModalVisible, setNewFormModalVisible] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const [deleteFormModal, setDeleteFormModal] = useState(null);
  const dataRef = useRef(null);

  const hideFormModal = () => {
    setFormToBeUpdated(null);
    setNewFormModalVisible(false);
  };

  const { practice } = useOutletContext();
  const { data, isLoading } = useCustomFormTypes({
    params: { practice_id: practice?.id },
    dependencies: [practice?.id],
    options: {
      enabled: !!practice?.id,
      select: (data) => optionify(data?.form_functions, 'name', 'id')
    }
  });

  useEffect(() => {
    dataRef.current = iaRa(data);
  }, [data]);

  const findLabelByValue = (valueToFind) => {
    const category = iaRa(dataRef?.current).find((category) => category?.value == valueToFind);
    return category ? category?.label : null;
  };

  const Actions = ({ data }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <Allowed requiredPermissions="form.update">
                <div
                  data-qa="edit-form-btn"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setFormToBeUpdated(data);
                  }}>
                  <Icon icon="new-edit" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Edit</div>
                </div>
              </Allowed>
              <div
                data-qa="edit-product-btn"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  close();
                  setSelectedForm(data);
                  setPreviewForm(true);
                }}>
                <Icon icon="new-eye" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Preview</div>
              </div>
              <Allowed requiredPermissions="form.create">
                <div
                  data-qa="delete-product-btn"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedForm(data);
                    setShowDuplicateModal(true);
                  }}>
                  <Icon icon="copy" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Duplicate</div>
                </div>
              </Allowed>
              <Allowed requiredPermissions="form.delete">
                <div
                  data-qa="delete-product-btn"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedForm(data);
                    setDeleteFormModal(true);
                  }}>
                  <Icon icon="trash" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Delete</div>
                </div>
              </Allowed>
            </div>
          )}
        </Popup>
      </div>
    );
  };

  const cols = [
    { field: 'id', headerName: 'ID', maxWidth: 150 },
    { field: 'name', headerName: 'Name' },
    { field: 'version', headerName: 'Version' },
    { field: 'type', headerName: 'Type', valueFormatter: ({ value }) => findLabelByValue(value) },
    {
      field: 'created_at',
      headerName: 'Last modified'
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: Actions,
      maxWidth: 100,
      minWidth: 36,
      resizable: false
    }
  ];

  if (isLoading) return <Skeleton height="100vh" />;

  return (
    <Allowed requiredPermissions="form.read" showMessage showIllustration>
      <TableContextProvider name="practice_forms" pagination cols={cols}>
        <Table
          setNewFormModalVisible={setNewFormModalVisible}
          formToBeUpdated={formToBeUpdated}
          setShowDuplicateModal={setShowDuplicateModal}
          showDuplicateModal={showDuplicateModal}
          previewForm={previewForm}
          setPreviewForm={setPreviewForm}
          deleteFormModal={deleteFormModal}
          setDeleteFormModal={setDeleteFormModal}
          selectedForm={selectedForm}
        />
      </TableContextProvider>

      <Modal
        customStyling={{ width: '100%' }}
        bodyClassName="!p-4 !max-h-[unset] !h-full"
        handleOpen={newFormModalVisible || !!formToBeUpdated}
        handleClose={hideFormModal}
        title={`${formToBeUpdated ? 'Edit' : 'Create new'} form`}
        slideFromRight
        key={newFormModalVisible || formToBeUpdated}
        footer={
          <div className="flex w-full justify-between">
            <Button
              outlined
              text="Cancel"
              color="neutral"
              onClick={hideFormModal}
              id="cancelFormModalBtn"
            />
            <Button
              onClick={() => formRef.current.submitForm()}
              text={formToBeUpdated ? 'Update' : 'Create form'}
              id="createFormModalBtn"
              data-qa="create-update-forms-btn"
            />
          </div>
        }>
        <EditFormBuilderContainer
          form={formToBeUpdated}
          formFunctions={data}
          hideFormModal={hideFormModal}
          ref={formRef}
        />
      </Modal>
    </Allowed>
  );
}
function Table({
  setNewFormModalVisible,
  showDuplicateModal,
  setPreviewForm,
  setShowDuplicateModal,
  previewForm,
  setDeleteFormModal,
  selectedForm,
  deleteFormModal
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const {
    data = {},
    isLoading,
    isFetching
  } = useCustomForm({
    params: {
      practice_id: id,
      searchTerm: debouncedSearchTerm,
      page,
      sort,
      limit,
      withCount: true
    },
    dependencies: [debouncedSearchTerm, page, sort, limit, id],
    page,
    sort
  });
  const forms = data?.forms;

  const mutateDeleteForm = useMutation({
    mutationFn: () => deleteCustomForm(navigate, { id: selectedForm.id }),
    onSuccess: () => {
      queryClient.invalidateQueries(['customForm']);
      showAlert({ title: 'Form deleted successfully!', color: 'primary' });
    }
  });

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div className="ml-auto mr-3 flex items-center gap-2">
        <DisplayButton />
        <Input
          data-qa="search-forms"
          type="string"
          placeholder="Search Forms..."
          value={searchTerm}
          icon="new-search"
          inputWrapperClassName="h-[30px]"
          className="md:w-[200px]"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          rightText={
            searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearchTerm('');
                  setPage(1);
                }}
              />
            )
          }
        />
        <Allowed requiredPermissions="form.create">
          <Button
            data-qa="new-product-btn"
            type="primary"
            size="small"
            text="New Form"
            className="!w-max"
            onClick={() => setNewFormModalVisible(true)}
          />
        </Allowed>
      </div>
      <div className="flex h-[94%] flex-col  overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable data={forms} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.formsCount > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.formsCount > page * limit ? page * limit : data?.formsCount} out of{' '}
              {data?.formsCount}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.formsCount}
            page={page}
          />
          {!!deleteFormModal && (
            <Confirm
              handleOpen={!!deleteFormModal}
              handleClose={() => setDeleteFormModal(false)}
              handleContinue={() => {
                mutateDeleteForm.mutate();
                setDeleteFormModal(false);
              }}
              title="Delete form"
              icon="new-document-remove-red"
              message="Are you sure you want to delete this form?"
              variant="danger"
            />
          )}
          {!!showDuplicateModal && (
            <DuplicateForm
              showDuplicateModal={showDuplicateModal}
              setShowDuplicateModal={setShowDuplicateModal}
              formId={selectedForm.id}
              formVersion={selectedForm.version}
            />
          )}
          <Modal
            handleOpen={!!previewForm}
            handleClose={() => setPreviewForm(false)}
            slideFromRight>
            {ia(selectedForm?.json?.fields) && (
              <>
                <PreviewForm
                  json={selectedForm.json.fields}
                  handleClose={() => setPreviewForm(false)}
                />
              </>
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
}
