import Tippy from '@tippyjs/react';

import { iaRa, isEmpty } from 'lib/helpers/utility';

import Status from 'components/shared/Status/Status';

const TreatmentPlanStatus = ({ signers, classNames }) => {
  const signersLeft = iaRa(signers)
    ?.filter((row) => row?.status !== 'signed')
    ?.map((row) => `${row?.user?.f_name} ${row?.user?.l_name}`)
    .join(', ');

  const noSignersLeft = isEmpty(signersLeft);

  return (
    <Tippy
      arrow={true}
      placement="left-start"
      className="tippy-dark w-fit"
      content={noSignersLeft ? 'Everyone signed.' : `${signersLeft} left to sign.`}>
      <div className="flex w-full">
        <Status
          color={noSignersLeft ? 'success' : 'warning'}
          status={
            noSignersLeft
              ? 'Signed'
              : `Awaiting ${signersLeft?.split(',').length > 1 ? 'signatures' : 'signature'}`
          }
          className={('w-max', classNames)}
        />
      </div>
    </Tippy>
  );
};
export default TreatmentPlanStatus;
