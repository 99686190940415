import React from 'react';

const fields = ['professionalFullName', 'phone', 'email', 'address'];

const renderFields = ({ fieldValues }) => {
  return fields.map((field) => {
    if (!fieldValues[field]) return null;

    return (
      <p
        key={`provider-${field}`}
        className="font-400 text-neutral-800 text-sm first-of-type:!mt-2.5 mt-1.5">
        {fieldValues[field]}
      </p>
    );
  });
};

const ContactPreview = ({ providerInfo }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="text-neutral-800 text-base font-500">Provider</div>
      <div className="flex flex-col">{renderFields({ fieldValues: providerInfo })}</div>
    </div>
  );
};

export default ContactPreview;
