import { useNavigate } from 'react-router-dom';

import { Popover } from '@headlessui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { togglePractice } from 'api/Practice';

import practiceState from 'components/practice/practiceState';
import Avatar from 'components/shared/Avatar/Avatar';
import Icon from 'components/shared/Icon/Icon';

const TogglePractice = () => {
  const ownerPractices = useRecoilValue(practiceState.ownerPractices);
  const currentPractice = useRecoilValue(practiceState.currentPractice);
  const ownerSelectedLocation = useRecoilValue(practiceState.ownerSelectedLocation);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleTogglePractice = async (practiceId, close) => {
    if (practiceId === currentPractice.id) {
      close();
      return;
    }

    const data = await togglePractice(navigate, { practice_id: practiceId });
    if (data.code === 0) {
      queryClient.invalidateQueries();
      window.location.reload();
      close();
    }
  };

  if (!ownerSelectedLocation || ownerPractices.length === 0) {
    return (
      <div className="h-[28px] rounded bg-primary-50 flex items-center !gap-2 px-[8px]  py-[6px]">
        <Icon icon="practice-location" />
        <h3 className="text-primary-600 font-500 text-base">{currentPractice.name}</h3>
      </div>
    );
  }
  return (
    <Popover className="relative">
      {({ close, open }) => (
        <>
          <Popover.Button>
            <div className="p-[1px] bg-primary-50  rounded">
              <div
                className={`h-[28px] rounded  flex items-center !gap-2 px-[8px] py-[6px] ${
                  open && ' border !border-primary-300'
                }`}>
                <Icon icon="practice-location" />
                <h3 className="text-primary-600 font-500 text-base">{currentPractice.name}</h3>
                <div className="flex  flex-col gap-1">
                  <Icon icon="chevron-up" color="primary" size={7} />
                  <Icon icon="chevron-down" color="primary" size={7} className="mr-[0px]" />
                </div>
              </div>
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute left-0 top-10 z-[99999] min-w-[460px] flex flex-col gap-2 rounded bg-white !py-[12px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
            <div className="text-base text-neutral-600 !ml-4">Locations</div>
            {ownerPractices?.map((practice) => (
              <div
                key={practice.id}
                className={`flex gap-2 items-center !px-[16px] py-[6px] hover:bg-primary-50 cursor-pointer ${
                  practice.id === currentPractice.id && 'bg-primary-50 '
                }`}
                onClick={() => handleTogglePractice(practice.id, close)}>
                <Avatar
                  firstName={practice.name}
                  size={18}
                  autoBackground={false}
                  fontSize="text-xs"
                />
                <div className="text-base text-primary-900 ">{practice.name}</div>
              </div>
            ))}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default TogglePractice;
