import moment from 'moment';

const ranges = {
  startOfWeek: moment().startOf('week').toDate(),
  endOfWeek: moment().endOf('week').toDate(),
  startOfLastWeek: moment().subtract(1, 'week').startOf('week').toDate(),
  endOfLastWeek: moment().subtract(1, 'week').endOf('week').toDate(),
  startOfToday: moment().startOf('day').toDate(),
  endOfToday: moment().endOf('day').toDate(),
  startOfYesterday: moment().subtract(1, 'day').startOf('day').toDate(),
  endOfYesterday: moment().subtract(1, 'day').endOf('day').toDate(),
  startOfMonth: moment().startOf('month').toDate(),
  endOfMonth: moment().endOf('month').toDate(),
  startOfLastMonth: moment().subtract(1, 'month').startOf('month').toDate(),
  endOfLastMonth: moment().subtract(1, 'month').endOf('month').toDate()
};

export const CALENDAR_STATIC_RANGES = [
  {
    label: 'Today',
    getValue: () => {
      return [ranges.startOfToday, ranges.endOfToday];
    }
  },
  {
    label: 'Yesterday',
    getValue: () => {
      return [ranges.startOfYesterday, ranges.endOfYesterday];
    }
  },
  {
    label: 'This Week',
    getValue: () => {
      return [ranges.startOfWeek, ranges.endOfWeek];
    }
  },
  {
    label: 'Last Week',
    getValue: () => {
      return [ranges.startOfLastWeek, ranges.endOfLastWeek];
    }
  },
  {
    label: 'This Month',
    getValue: () => {
      return [ranges.startOfMonth, ranges.endOfMonth];
    }
  },
  {
    label: 'Last Month',
    getValue: () => {
      return [ranges.startOfLastMonth, ranges.endOfLastMonth];
    }
  }
];
