import { requestApi } from './Api';

export const getNdcCodes = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/ndc/get',
    params
  });
export const getNdcCodeOptions = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/ndc/options/get',
    params
  });
