import _ from 'lodash';
import { requestApi } from './Api';

export const createInstantPacket = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/form/instant_packet/create',
    params,
    onError: () => {}
  });
};

export const getPracticeInstantPacket = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/practice_i_queue/instant_packet/get',
    params
  });
};
