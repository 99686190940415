/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 face_muscles.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';

export function FaceMuscles(props) {
  const { nodes, materials } = useGLTF(
    `${process.env.ENV !== 'local' ? '/public' : ''}/models/face_muscles.glb`
  );
  return (
    <group {...props} dispose={null}>
      <mesh
        geometry={nodes.skull.geometry}
        material={materials.hyoid_bone}
        position={[0.028, 0.276, 0.481]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.1}
      />
      <mesh
        geometry={nodes.eyes093.geometry}
        material={materials['eye2.005']}
        position={[0.032, -0.262, 0.173]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={0.105}
      />
    </group>
  );
}
