import React, { useState } from 'react';
import './MedicalRecord.scss';
import moment from 'moment';
import Button from '../../../shared/Button/Button';
import MedicalRecordModal from '../MedicalRecordModal/MedicalRecordModal';

export default function MedicalRecord({ medicalRecord }) {
  const [medicalRecordModal, setMedicalRecordModal] = useState(false);

  return (
    <div className="MedicalRecord__table__item">
      <p>{medicalRecord.practice.name}</p>
      <p>{medicalRecord?.practitioner?.fullName}</p>
      <p>{moment(medicalRecord.appointment?.starts_at).format('MMM DD, YYYY')}</p>
      <Button primary small onClick={() => setMedicalRecordModal(true)}>
        Show
      </Button>
      {medicalRecord && (
        <MedicalRecordModal
          showMedicalRecordModal={medicalRecordModal}
          hideMedicalRecordModal={() => setMedicalRecordModal(false)}
          medicalRecord={medicalRecord}
        />
      )}
    </div>
  );
}
