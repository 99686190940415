import React from 'react';
import { getOrdinalSuffix, inchToCm } from '../../../../../lib/helpers/utility';

export const GrowthPatientBlock = ({
  fullName,
  measurementType,
  chartType,
  currentUnits,
  patientDetails,
  vitals
}) => {
  return (
    <div
      className={
        '!mt-3 flex flex-wrap justify-between gap-3 rounded-md bg-primary-50 !p-2 shadow-sm'
      }>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm font-500 text-primary-800">Full Name:</p>
        <p className="text-xs text-neutral-800">{fullName}</p>
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm font-500 text-primary-800">{measurementType}:</p>
        <p className="text-xs text-neutral-800">
          {patientDetails?.x} {currentUnits.x}
        </p>
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm font-500 text-primary-800">{chartType}:</p>
        {chartType !== 'HEAD' ? (
          <p className="text-xs text-neutral-800">
            {patientDetails?.y} {currentUnits.y}
          </p>
        ) : (
          // For Head Circumference We Need To Display Both Units (Imperial && Metric)
          <>
            <p className="text-xs text-neutral-800">
              {patientDetails?.y} {currentUnits.y}
            </p>
            <p className="text-xs text-neutral-800">
              {vitals.head_circumference_unit === 'cm'
                ? vitals.head_circumference
                : inchToCm(vitals?.head_circumference)}{' '}
              (cm)
            </p>
          </>
        )}
      </div>
      <div className="flex flex-col gap-[2px]">
        <p className="text-sm font-500 text-primary-800">Percentile:</p>
        <p className="text-xs text-neutral-800">
          {getOrdinalSuffix({ number: patientDetails?.percentile?.split('p')[1] })}
        </p>
      </div>
    </div>
  );
};

export default GrowthPatientBlock;
