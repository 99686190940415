import { findRelationValue } from 'lib/helpers/utility';

export default async function (index, patients) {
  const updatedPatient = [...patients];

  const patientsWithCorrectedRelation = updatedPatient?.map((patient) => {
    if (typeof patient[index] === 'string') {
      const correctedRelation = findRelationValue(patient[index]);
      if (correctedRelation) {
        return [...patient.slice(0, index), correctedRelation, ...patient.slice(index + 1)];
      }
    }
    return patient;
  });
  return patientsWithCorrectedRelation;
}
