import { getEnabledComponents } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { iaRa, isEmpty } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';

import { generateHPList } from '../../lib/generateHPList';
import { generateIntakeList } from '../../lib/generateIntakeList';
import { generateProgressList } from '../../lib/generateProgressList';
import { generateSOAPList } from '../../lib/generateSOAPList';
import CH3DFormsAI from '../components/forms/3D/3DFormsAI';
import HPFormsAI from '../components/forms/HP/HPFormsAI';
import INTAKEFormsAI from '../components/forms/INTAKE/IntakeFormsAI';
import PROGRESSFormsAI from '../components/forms/PROGRESS/PROGRESSFormsAI';
import SOAPFormsAI from '../components/forms/SOAP/SOAPFormsAI';

export const getTypeOfNotes = ({
  cnDisplaySettings,
  advancedHP,
  advancedSOAP,
  customFormTypes
}) => {
  const customFormTypeEntries = Object.entries(customFormTypes || {}).reduce(
    (acc, [key, value]) => {
      acc.components = getEnabledComponents(() => {}, key, cnDisplaySettings, value, true);
      acc.label = 'Custom Form Type';
      acc.type = key;
      acc.id = key;
      acc.includedInHashtags = false;
      return { [key]: acc };
    },
    {}
  );

  const typeOfNotes = {
    ...(cnDisplaySettings?.['hp']?.enabled && {
      hp: {
        label: 'H&P',
        type: 'hp',
        id: 'hp',
        components: getEnabledComponents(generateHPList, 'hp', cnDisplaySettings, advancedHP),
        forms: HPFormsAI,
        includedInHashtags: false,
        checked: false
      }
    }),
    ...(cnDisplaySettings?.['epn']?.enabled && {
      intake: {
        label: 'Intake',
        type: 'intake',
        id: 'intake',
        components: getEnabledComponents(
          generateIntakeList,
          'intake',
          cnDisplaySettings,
          advancedHP
        ),
        forms: INTAKEFormsAI,
        includedInHashtags: false,
        checked: false
      }
    }),
    ...(cnDisplaySettings?.['soap']?.enabled && {
      soap: {
        label: 'SOAP',
        type: 'soap',
        id: 'soap',
        components: getEnabledComponents(generateSOAPList, 'soap', cnDisplaySettings, advancedSOAP),
        forms: SOAPFormsAI,
        includedInHashtags: false,
        checked: false
      }
    }),
    ...(cnDisplaySettings?.['mha']?.enabled && {
      progress: {
        label: 'Progress',
        type: 'progress',
        id: 'progress',
        components: getEnabledComponents(
          generateProgressList,
          'progress',
          cnDisplaySettings,
          advancedSOAP
        ),
        forms: PROGRESSFormsAI,
        includedInHashtags: false,
        checked: false
      }
    }),
    ...(cnDisplaySettings?.['chart3d']?.enabled && {
      '3d': {
        id: '3d',
        type: '3d',
        label: '3D Charting',
        components: { face: { title: 'Face', path: 'face' } },
        forms: CH3DFormsAI,
        includedInHashtags: false,
        checked: false
      }
    }),
    ...(cnDisplaySettings?.['custom-form-type']?.enabled && customFormTypeEntries)
  };

  return typeOfNotes;
};

export const copyTranscript = (transcriptData) => {
  const texts = iaRa(transcriptData).map((data) => {
    const text = data?.transcript_enhanced
      .map((row) => {
        const speaker = typeof row?.speaker === 'number' ? `Speaker ${row?.speaker}` : row?.speaker;
        return `${speaker} - ${row.merged_sentences}`;
      })
      .join('\n');

    return text;
  });

  const isEmptyTexts = texts.every((text) => isEmpty(text));

  if (isEmptyTexts) {
    showAlert({
      title: 'Copy Transcript',
      message: 'Failed while generating the copy transcript. Please try again.',
      color: 'danger'
    });

    return;
  }

  const mergedText = texts.join('\n');

  navigator.clipboard
    .writeText(mergedText)
    .then(() =>
      showAlert({
        title: 'Copy Transcript',
        message: 'Transcript copied to clipboard',
        color: 'success'
      })
    )
    .catch(() =>
      showAlert({
        title: 'Copy Transcript',
        message: 'Failed to copy transcript',
        color: 'error'
      })
    );
};

export function replacePlaceholders(template, data) {
  return template?.replace(/\{\{(\w+)\}\}/g, (match, key) => {
    return key in data ? data[key] : match;
  });
}

export function convertClinicalNoteToHTML(response) {
  if (!response || typeof response !== 'object') return '<p>No data available</p>';

  let html = '';

  for (const section in response) {
    html += `<h5>${section}</h5>`;
    if (section === 'Patient Vitals') {
      html += processVitals(response[section]);
    } else {
      html += processSection(response[section]);
    }
  }

  html += '</div>';
  return html;
}

function processSection(sectionData) {
  if (Array.isArray(sectionData)) {
    let html = '<ul>';
    sectionData.forEach((item) => {
      html += `<li>${typeof item === 'object' ? processSection(item) : item}</li>`;
    });
    html += '</ul>';
    return html;
  } else if (typeof sectionData === 'string') {
    return `<p>${sectionData}</p>`;
  } else if (typeof sectionData === 'object') {
    let html = '<ul>';
    for (const key in sectionData) {
      html += `<li><strong>${key}:</strong> `;
      if (typeof sectionData[key] === 'object') {
        html += processSection(sectionData[key]);
      } else {
        html += `${sectionData[key]}</li>`;
      }
    }
    html += '</ul>';
    return html;
  }
  return '';
}

function processVitals(vitals) {
  let html = '<div class="vitals-section">';
  vitals.forEach((v) => {
    html += `<div class="vitals-entry">
          <p><strong>Date:</strong> ${v.Date || 'N/A'}</p>
          <p><strong>Respirations:</strong> ${v.Respirations || 'N/A'}</p>
          <p><strong>Heart Rate:</strong> ${v['Heart rate'] || 'N/A'}</p>
          <p><strong>BP Systolic:</strong> ${v['Blood pressure systolic'] || 'N/A'}</p>
          <p><strong>BP Diastolic:</strong> ${v['Blood pressure diastolic'] || 'N/A'}</p>
          <p><strong>BMI:</strong> ${v.BMI || 'N/A'}</p>
          <p><strong>Oxygen Level:</strong> ${v['Oxygen level'] || 'N/A'}</p>
          <p><strong>Notes:</strong> ${v.Notes || 'N/A'}</p>
      </div>`;
  });
  html += '</div>';
  return html;
}
