import React, { Fragment, useState } from 'react';
import AppointmentModal from './components/AppointmentModal';
import AppointmentForm from './components/AppointmentForm';
import { Tab } from '@headlessui/react';
import cs from 'classnames';
import EventForm from '../EventForm/EventForm';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import { pm } from 'lib/helpers/utility';

export const customSelectStyle = {
  control: () => ({
    minHeight: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 16,
    backgroundColor: '#f8f8f8',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};

export const customSelectStyle__FullWidthOptions = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 14,
    backgroundColor: '#f8f8f8',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  container: (provided) => {
    return {
      ...provided,
      position: 'initial'
    };
  }
};

const NewAppointment = ({
  services,
  practitioners,
  selectedDateTime,
  isOpen,
  onAfterOpen,
  hideNewAppointment,
  getAppointment = () => {},
  selectedPatient: loadedSelectedPatient,
  selectedPractitioner: loadedSelectedPractitioner
}) => {
  const [selectedTab, setSelectedTab] = useState('appointment');
  const [footer, setFooter] = useState(<></>);
  const { resources } = useNewAppointmentContext();
  const permissions = useRecoilValue(state.permissions);

  const tabsData = [
    ...(pm(permissions, 'appointments.create')
      ? [
          {
            label: 'Patient Appointment',
            content: (
              <AppointmentForm
                hideNewAppointment={hideNewAppointment}
                practitioners={practitioners}
                resources={resources}
                selectedDateTime={selectedDateTime}
                selectedPatient={loadedSelectedPatient}
                selectedPractitioner={loadedSelectedPractitioner}
                services={services}
                setFooter={setFooter}
                getAppointment={getAppointment}
                setSelectedTab={setSelectedTab}
              />
            )
          }
        ]
      : []),
    ...(pm(permissions, 'practice_events.create')
      ? [
          {
            label: 'Event',
            content: (
              <EventForm
                setSelectedTab={setSelectedTab}
                setFooter={setFooter}
                hideModal={hideNewAppointment}
                practitioners={practitioners}
                selectedDateTime={selectedDateTime}
              />
            )
          }
        ]
      : [])
  ];
  return (
    <AppointmentModal
      {...{
        footer,
        handleClose: hideNewAppointment,
        isOpen,
        onAfterOpen,
        title: selectedTab
      }}>
      <Tab.Group>
        <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
          {tabsData.map((tab, i) => {
            return (
              <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                {({ selected }) => (
                  <button
                    className={cs(
                      'flex items-center gap-1 border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                      selected
                        ? ' !border-primary-500 font-600 text-primary-500'
                        : 'font-500 text-neutral-800'
                    )}>
                    {tab.label}
                  </button>
                )}
              </Tab>
            );
          })}
        </Tab.List>
        <Tab.Panels>
          {tabsData.map((tab, i) => (
            <Tab.Panel key={i}>
              {({ selected }) => {
                return selected ? tab.content : null;
              }}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </AppointmentModal>
  );
};

export default NewAppointment;
