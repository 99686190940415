import React, { useEffect, useMemo, useState } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useUserBoard } from 'lib/hooks/queries/boards/useUserBoard';
import { generateUUID } from 'three/src/math/MathUtils';
import { objMap, pm } from 'lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { BoardContext } from './BoardContext';
import state from 'components/state';
import { showAlert } from 'components/shared/Alert/Alert';

export default function BoardContextProvider({
  board,
  cols,
  layouts = {
    lg: [],
    md: [],
    sm: []
  },
  children
}) {
  const [editMode, setEditMode] = useState(false);
  const [layout, setLayout] = useState(layouts);
  const [selectedFeature, setSelectedFeature] = useState();
  const [breakpoint, setBreakpoint] = useState('lg');
  const [changedSizes, setChangedSizes] = useState([]);
  const { data } = useUserBoard({ board });
  const permissions = useRecoilValue(state.permissions);
  const [addedItem, setAddedItem] = useState();
  const [deleteWidgetModal, setDeleteWidgetModal] = useState(null);

  const defaultBoard = useMemo(
    () =>
      data?.default?.[breakpoint]?.filter((item) =>
        item?.permissions ? pm(permissions, item.permissions) : true
      ),
    [breakpoint, data?.default, permissions]
  );

  useEffect(() => {
    initialize(data?.board);
  }, [data]);

  const changePosition = (layout, item, breakpoint) => {
    const numCols = cols[breakpoint] || 2;
    let currentRow = 0;
    let currentCol = 0;

    while (true) {
      const isSpaceOccupied = layout?.some((i) => {
        const occupied =
          ((currentRow >= i.y && currentRow < i.y + i.h) ||
            (currentRow + item.h > i.y && currentRow + item.h <= i.y + i.h)) &&
          ((currentCol >= i.x && currentCol < i.x + i.w) ||
            (currentCol + item.w > i.x && currentCol + item.w <= i.x + i.w));

        return occupied;
      });

      if (!isSpaceOccupied) {
        item.x = currentCol;
        item.y = currentRow;
        break;
      }

      currentCol += 1;

      if (currentCol >= numCols - (breakpoint == 'lg' || breakpoint == 'md' ? item.w - 1 : 1)) {
        currentCol = 0;
        currentRow++;
      }
    }

    return item;
  };

  const filterItem = (item, id) =>
    item
      ? {
        ...item,
        i: item.i || id || generateUUID(),
        x: item.x || 0,
        y: item.y || 0,
        w: item.w || item.minW || 2,
        h: item.h || item.minH || 1,
        visible: true
      }
      : null;

  function initialize(board) {
    if (board) {
      const ids = [];
      setLayout(
        objMap(board, (breakpoint, layout) => {
          const updatedLayout = layout.slice();
          return layout
            .filter(
              (item) =>
                item.visible &&
                defaultBoard.find((defaultItem) => defaultItem.widget === item.widget)
            )
            .map((item, index) => {
              if (!ids[index]) {
                ids.push(generateUUID());
              }
              if ((item.x != null || item.x === 0) && (item.y != null || item.y === 0)) {
                item = filterItem(item, ids[index]);
              } else {
                item = filterItem(item, ids[index]);
                item = changePosition(updatedLayout, item, breakpoint);
              }
              updatedLayout[index] = item;
              return item;
            });
        })
      );
    }
  }

  const addItem = (item, br, uuid) => {
    const id = uuid || generateUUID();

    const defaultItem = defaultBoard?.find((layoutItem) => layoutItem.name === item);
    if (!defaultItem.multiple && layout.lg.find((layoutItem) => layoutItem.name === item))
      return showAlert({
        message: "Can't add multiple of this widget!",
        color: 'danger'
      });

    setLayout((prevLayout) => {
      return objMap(prevLayout, (breakpoint, layout) => {
        const newLayout = layout.slice();
        if (!br || br === breakpoint) {
          var newItem = {
            ...data?.default?.[breakpoint]?.find((layoutItem) => layoutItem.name === item)
          };

          newItem = filterItem(newItem, id);
          newItem = changePosition(layout, newItem, breakpoint);

          newLayout.push(newItem);
        }

        return newLayout;
      });
    });
    if (addedItem !== id) {
      setAddedItem(id);
    }
  };

  return (
    <BoardContext.Provider
      value={{
        editMode,
        setEditMode,
        layout,
        setLayout,
        breakpoint,
        setBreakpoint,
        data,
        defaultBoard,
        selectedFeature,
        setSelectedFeature,
        changedSizes,
        setChangedSizes,
        board,
        initialize,
        addItem,
        addedItem,
        setAddedItem,
        deleteWidgetModal,
        setDeleteWidgetModal,
        cols
      }}>
      {children}
    </BoardContext.Provider>
  );
}
