import React from 'react';
import cs from 'classnames';
import { getClassNamesByPercentage, getPercentage } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useNavigate } from 'react-router-dom';

const ListView = ({ options, constantType, data, total, navigatePath }) => {
  const navigate = useNavigate();
  const handleNavigation = async (itemPath) => {
    if (navigatePath === 'era-eob/all') {
      navigate(`/portal/beyond-billing/era-eob/${itemPath}`);
    } else if (navigatePath === 'superbills' && itemPath === 'draft') {
      navigate(`/portal/beyond-billing/${navigatePath}`);
    } else if (navigatePath === 'electronic-invoices' && itemPath === 'electronic-invoices') {
      navigate(`/portal/beyond-billing/${navigatePath}`);
    } else {
      navigate(`/portal/beyond-billing/${navigatePath}/${itemPath}`);
    }
  };

  return (
    <div className="h-full px-4 py-3">
      <div className="flex flex-col gap-4">
        {options?.data?.map((item, index) => (
          <div
            className="flex cursor-pointer justify-between rounded-md bg-primary-10 px-4 py-3"
            key={index}
            onClick={() => handleNavigation(item?.tableName)}>
            <div className="flex items-center gap-2">
              <p className="font-sm font-medium text-primary-900">{item?.name}</p>
            </div>
            <div className="flex justify-between gap-2">
              {data && total && constantType && (
                <div
                  className={cs(
                    '!bg-red flex gap-1 rounded-full px-2',
                    getClassNamesByPercentage(constantType, item?.name, data, total)
                  )}>
                  <p className="tex-sm font-medium">
                    {getPercentage(constantType, data, item?.name, total).toFixed()}%
                  </p>
                </div>
              )}
              <p className="text-sm font-400 text-primary-900">{item?.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(ListView);
