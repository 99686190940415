import Honeybadger from '@honeybadger-io/js';
import { serviceChargeTypes } from 'constants.js';
import * as Yup from 'yup';

import { getCptCodes } from 'api/CptCodes';

import { Capitalize } from 'lib/helpers/utility';

const ZERO_VALUE = 0;
const DEFAULT_TIME_LENGTH = 60;
const DEFAULT_COLOR = '#000000';
const DEFAULT_CHARGE_TYPE = {
  value: 'cash',
  label: 'Self Pay'
};

const findServiceChargeType = (value) => {
  return serviceChargeTypes.find((chargeType) => chargeType.value === value);
};

const getNdcCodes = (serviceNdc) => {
  return serviceNdc.flatMap((item) =>
    item.ndc_codes.map((ndc) => ({
      value: ndc?.package_ndc,
      label: ndc?.package_ndc,
      title: `[ ${Capitalize(ndc?.generic_name)} ]\n ${ndc?.packaging_description}`
    }))
  );
};

export const getInitialValues = (serviceToBeUpdated) => ({
  name: serviceToBeUpdated?.name || '',
  description: serviceToBeUpdated?.description || '',
  image: serviceToBeUpdated?.image || '',
  procedure_code: serviceToBeUpdated?.procedure_code || '',
  par_amount: serviceToBeUpdated?.cpt?.par_amount || ZERO_VALUE,
  total_cost_cents: serviceToBeUpdated?.total_cost_cents || ZERO_VALUE,
  time_length: serviceToBeUpdated?.time_length || DEFAULT_TIME_LENGTH,
  self_scheduling: serviceToBeUpdated?.self_scheduling || false,
  charge_type: serviceToBeUpdated?.charge_type
    ? findServiceChargeType(serviceToBeUpdated?.charge_type)
    : DEFAULT_CHARGE_TYPE,
  standard_charge: serviceToBeUpdated?.standard_charge || ZERO_VALUE,
  cpt: serviceToBeUpdated?.cpt || null,
  paySchedule: serviceToBeUpdated?.payerConnection || [],
  serviceId: serviceToBeUpdated?.id || null,
  practitioner_ids: serviceToBeUpdated?.practitioner_ids || [],
  color: serviceToBeUpdated?.color || DEFAULT_COLOR,
  category_id: serviceToBeUpdated?.category_id || null,
  state_tax_rate: serviceToBeUpdated?.state_tax_rate || ZERO_VALUE,
  local_tax_rate: serviceToBeUpdated?.local_tax_rate || ZERO_VALUE,
  ndc_codes: getNdcCodes(serviceToBeUpdated?.serviceNdc || [])
});

export const ServiceValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required.'),
  total_cost_cents: Yup.number().required('Price is required'),
  charge_type: Yup.object().required('Charge type is required'),
  time_length: Yup.number().required('Time length is required')
});

export const getFinalShapeServiceObj = (values) => ({
  name: values.name,
  description: values.description,
  procedure_code: values.procedure_code,
  total_cost_cents: Number(values.total_cost_cents),
  time_length: values.time_length,
  self_scheduling: values.self_scheduling,
  charge_type: values.charge_type.value,
  par_amount: Number(values.par_amount),
  standard_charge: Number(values.standard_charge),
  image: values.image,
  practitioner_ids: values.practitioner_ids,
  color: values.color,
  category_id: values.category_id,
  state_tax_rate: values.state_tax_rate,
  local_tax_rate: values.local_tax_rate,
  ndc_codes: values.ndc_codes.map((item) => item.value)
});

const formatCptCode = (item) => ({
  value: item.code,
  label: `${item.code} - ${item.name}`,
  par_amount: item.par_amount || 0,
  meta: item,
  base_unit: item.base_unit
});

const DEFAULT_LIMIT = 100;

export const handleCPTSearch = async (searchTerm, navigate) => {
  if (!searchTerm) {
    return [];
  }

  try {
    const params = {
      searchTerm,
      limit: DEFAULT_LIMIT
    };

    const data = await getCptCodes(navigate, params);
    const cptCodes = data?.cpt;

    if (!cptCodes) {
      throw new Error('No CPT codes returned from API');
    }

    return cptCodes.map(formatCptCode);
  } catch (error) {
    const errorMessage = `Failed to search CPT codes: ${error.message}`;
    Honeybadger.notify(errorMessage);
    return [];
  }
};
