import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getClinicalNotes } from '../../../../api/ClinicalNote';

export const useClinicalNote = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['clinicalNotes', ...dependencies], () => getClinicalNotes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
