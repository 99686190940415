module.exports = {
  otherForms: [
    { name: 'HIPAA Agreement', id: 'hipaa', hardcoded: true },
    {
      name: 'Pre-Authorized Healthcare Form (CC on file)',
      id: 'pre-auth',
      altId: 'pre_auth',
      hardcoded: true
    },
    { name: 'Insurance', id: 'insurance', hardcoded: true },
    { name: 'Personal ID', id: 'personal-id', altId: 'personal_id', hardcoded: true },
    { name: 'Demographics', id: 'personal-info', altId: 'personal_info', hardcoded: true }
  ],
  statuses: [
    { label: 'Completed', value: 'completed' },
    { label: 'Partially Completed', value: 'partially_completed' },
    { label: 'Not Completed', value: 'not_completed' }
  ]
};
