import { useQueryClient } from '@tanstack/react-query';
import { upsertMultiCustomFormResponse } from 'api/CustomForms';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import { permissions } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, iaRa } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { isMedicationReadOnly } from '../../../lib/aggregatedDataHelper';
import { translateMedicationToWeightLossData } from '../lib/helpers';
import MultiSelectMedicationsTable from './MultiSelectMedicationTable';
import { multiSelectMedicationsCols } from './lib/helpers';
const ExistingMedication = ({ showModal = false, hideModal = () => null, data }) => {
  return (
    <TableContextProvider cols={multiSelectMedicationsCols} name="multi_select_medications">
      <Table showModal={showModal} hideModal={hideModal} data={data} />
    </TableContextProvider>
  );
};
const Table = ({ showModal = false, hideModal = () => null, data }) => {
  const { selectedRows = [], setSelectedRows = () => {} } = useTableContext();
  const userPermissions = useRecoilValue(permissions);
  const medicationReadOnly = isMedicationReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: showModal?.data
  });
  const { id: patientId, appointmentId } = useParams();
  const { setOverviewData } = useClinicalNoteContext();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const onRowClicked = async (e) => {
    if (e?.data?.weight_loss_medication_id) {
      return;
    }
    const clickedMedications = e.data;

    e.node.setSelected(true);
    setSelectedRows([{ data: clickedMedications }]);
  };

  const onCellClicked = async (e) => {
    if (['0', 'actions'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }
    await onRowClicked(e);
  };
  const addMedicationToWeightLossAdministration = async () => {
    setLoading(true);
    const formattedData = selectedRows.map((row) => {
      const { data } = row;
      return translateMedicationToWeightLossData({ medication: data, medication_id: data?.id });
    });
    const responses = formattedData.map((row) => ({ row: [row] }));
    if (!ia(responses)) {
      showAlert({
        title: 'Add Medication to Weight Loss Administration',
        message: 'Please select a medication to add',
        color: 'warning'
      });
      setLoading(false);
    }
    const params = {
      patientId,
      appointmentId,
      responses,
      type: 'weightLossAdministration'
    };
    const onSucess = async (data) => {
      showAlert({
        title: 'Medication',
        message: `Selected medications were successfully transferred to the Weight Loss Administration`,
        color: 'success'
      });
      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: [...data.newResponses, ...iaRa(prev?.weightLossAdministration)]
      }));

      hideModal();
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['medications'] }),
        queryClient.invalidateQueries({ queryKey: ['medication'] })
      ]);
    };

    const onError = (error) => {
      showAlert({
        title: 'Add Medication to Weight Loss Administration',
        message: error,
        color: 'warning'
      });
      return;
    };
    await upsertMultiCustomFormResponse(navigate, params, onError, onSucess);
    setLoading(false);
  };

  return (
    <Modal
      handleOpen={showModal?.existing}
      handleClose={hideModal}
      className="max-h-[90dvh] w-[1160px]"
      bodyClassName="bg-primary-10"
      modalCenterAnimation={true}
      isFooter={false}
      title="Select from Patient Medication"
      isFooterSticky>
      <form className="lg:px-[230px] xl:px-[230px]">
        {medicationReadOnly && (
          <AlertContent
            title="Restricted: Read-Only Data Access"
            message="Editing this area is currently restricted. Contact your internal systems admin if needed."
            color="warning"
            width="full"
            className="!mb-4"
          />
        )}
        <MultiSelectMedicationsTable data={data} onCellClicked={onCellClicked} />
      </form>
      <ModalFooter
        className="z-50"
        leftButtons={<Button outlined text="Cancel" color="neutral" onClick={hideModal} />}
        rightButtons={
          <div className="flex gap-2">
            <Button
              text="Add"
              data-qa="upsert-btn"
              onClick={() => {
                addMedicationToWeightLossAdministration();
              }}
              loadingIcon={loading}
              disabled={!ia(selectedRows) || medicationReadOnly}
            />
          </div>
        }
      />
    </Modal>
  );
};

export default ExistingMedication;
