import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getNonERXPrescription } from '../../../../api/NonERXPrescription';

export const useNonERXPrescription = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['nonERXPrescription', ...dependencies], () => getNonERXPrescription(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
