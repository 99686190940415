import { memo } from 'react';

import cs from 'classnames';

import { iaRa } from 'lib/helpers/utility';

import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

import useAOEQuestion from '../../hooks/useAOEQuestion';

const AOEQuestion = ({ testIndex, index, question }) => {
  const { question: questionText, isRequired: required, pickListSettings } = question;

  const { field, error, setTouched, setValue } = useAOEQuestion({ question, index, testIndex });

  let component;

  if (pickListSettings) {
    const { approvedAnswers, maxSelectLimit } = pickListSettings;

    const options = iaRa(approvedAnswers).map(({ code, value }) => {
      return {
        code,
        value: code || value,
        label: value
      };
    });

    component = (
      <Select
        {...field}
        onBlur={() => {
          setTouched(true);
        }}
        error={error}
        onChange={(opt) => setValue(opt)}
        label={questionText}
        labelClassName="whitespace-normal"
        options={options}
        isMulti={maxSelectLimit > 1}
        required={required}
        isOptionDisabled={() => field.value?.length >= maxSelectLimit}
        menuPortalTarget={document.body}
      />
    );
  } else {
    component = (
      <Input
        {...field}
        labelClassName="whitespace-normal"
        label={questionText}
        required={required}
        error={error}
      />
    );
  }

  return (
    <div
      className={cs(
        'mb-4 last:mb-0',
        pickListSettings &&
          pickListSettings.maxSelectLimit > 1 &&
          'sm:col-span-1 col-span-2 xxl:col-span-3'
      )}>
      {component}
    </div>
  );
};

export default memo(AOEQuestion);
