import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Goals from '../../ProgressNote/components/Goals';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const GoalsSection = ({ components, sectionRef, options, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{ name: 'Goals', parentKey: 'intake', childKey: 'goals' }}
      title={components?.goals?.title}
      id={components?.goals?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Goals section="intake" sectionRef={sectionRef} />
    </Accordion>
  );
};

export default GoalsSection;
