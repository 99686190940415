import React from 'react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';

const ModalFooter = ({
  leftButtons,
  rightButtons,
  className,
  onSubmit = () => {},
  onGoBack = () => {}
}) => {
  return (
    <div className={cs('blurred-footer', className)}>
      <div className="max-w-[750px]">
        <div>
          {leftButtons || (
            <Button
              key={1}
              text="Close"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onGoBack}
            />
          )}
        </div>
        <div className="flex items-center">
          {rightButtons || (
            <Button
              key={1}
              text="Add"
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onSubmit}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalFooter;
