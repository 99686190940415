import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import Input from 'components/shared/Input/Input';
import state from 'components/state';

const EmergencyContact = () => {
  const permissions = useRecoilValue(state.permissions);

  const { handleChange: onChange, values, errors } = useFormikContext();
  const disabled = !permissions?.patients?.update;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h4 className="text !my-2 text-xl text-primary-700">Primary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Name"
            onChange={onChange}
            placeholder="Full name"
            id="emergency_contact.name"
            data-qa="emergency-contact-name"
            name="emergency_contact.name"
            value={values?.emergency_contact?.name}
            error={errors?.emergency_contact?.name}
            disabled={disabled}
          />

          <Input
            label="Phone"
            onChange={onChange}
            placeholder="Phone Number"
            id="emergency_contact.phone"
            data-qa="emergency-contact-phone"
            name="emergency_contact.phone"
            value={values?.emergency_contact?.phone}
            error={errors?.emergency_contact?.phone}
            disabled={disabled}
          />

          <Input
            label="Relationship"
            onChange={onChange}
            placeholder="Relation to patient"
            id="emergency_contact.relationship"
            data-qa="emergency-contact-relationship"
            name="emergency_contact.relationship"
            value={values?.emergency_contact?.relationship}
            error={errors?.emergency_contact?.relationship}
            disabled={disabled}
          />
        </div>
      </div>

      <div>
        <h4 className="text !my-2 text-xl text-primary-700">Secondary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Name"
            onChange={onChange}
            placeholder="Full name"
            id="secondary_emergency_contact.name"
            data-qa="secondary-emergency-contact-name"
            name="secondary_emergency_contact.name"
            value={values?.secondary_emergency_contact?.name}
            error={errors?.secondary_emergency_contact?.name}
            disabled={disabled}
          />

          <Input
            label="Phone"
            onChange={onChange}
            placeholder="Phone Number"
            id="secondary_emergency_contact.phone"
            data-qa="secondary-emergency-contact-phone"
            name="secondary_emergency_contact.phone"
            value={values?.secondary_emergency_contact?.phone}
            error={errors?.secondary_emergency_contact?.phone}
            disabled={disabled}
          />

          <Input
            label="Relationship"
            onChange={onChange}
            placeholder="Relation to patient"
            id="secondary_emergency_contact.relationship"
            data-qa="secondary-emergency-contact-relationship"
            name="secondary_emergency_contact.relationship"
            value={values?.secondary_emergency_contact?.relationship}
            error={errors?.secondary_emergency_contact?.relationship}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default EmergencyContact;
