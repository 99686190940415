import React from 'react';

import TransactionsTable from './transactions/TransactionsTable';

export default function Billing() {
  return (
    <div className="mt-0 flex h-full flex-col overflow-hidden bg-white !p-0">
      <TransactionsTable />
    </div>
  );
}
