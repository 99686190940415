import React, { useState } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { upsertUserBoard } from '../../../api/Boards';
import { useNavigate } from 'react-router-dom';
import Button from '../Buttons/Button';
import { Popover } from '@headlessui/react';
import Icon from '../Icon/Icon';

import cs from 'classnames';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';

export default function HeaderButton({ header, children }) {
  const {
    initialize,
    addItem,
    setChangedSizes,
    breakpoint,
    setDeleteWidgetModal,
    data,
    editMode,
    setEditMode,
    layout,
    setLayout,
    setSelectedFeature,
    board,
    defaultBoard
  } = useBoardContext();

  const [prevLayout, setPrevLayout] = useState();

  const navigate = useNavigate();

  const applyEdit = async () => {
    const res = await upsertUserBoard(navigate, { board, layout });
    setChangedSizes(
      layout[breakpoint].filter((item) => {
        const prevItem = prevLayout[breakpoint].find((prev) => prev.i === item.i);
        return item.w != prevItem?.w || item.h != prevItem?.h;
      })
    );
    if (res) {
      setEditMode(false);
    }
  };

  const enterEdit = () => {
    setEditMode(true);
    setPrevLayout(layout);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setLayout(prevLayout);
  };

  const resetEdit = () => {
    initialize(data?.default);
  };

  return (
    <div
      data-dd-privacy="allow"
      className={cs(
        'z-20 flex w-full',
        'justify-between border-0 !border-b !border-solid !border-neutral-100 bg-white'
      )}>
      {header()}
      <div className={cs('z-20 flex items-center gap-3 backdrop-blur-lg backdrop-filter', '!pr-5')}>
        {editMode ? (
          <>
            <span
              onClick={cancelEdit}
              className="flex cursor-pointer gap-2 !pl-2 text-sm font-medium text-primary-500">
              Cancel
            </span>
            <Button
              type="secondary"
              className="!bg-white !px-2 !py-[5px] !font-medium"
              text="Reset to default"
              icon="new-diskette"
              onClick={resetEdit}
            />
            <Popover className="relative">
              {({ open }) => (
                <div>
                  <Popover.Button
                    type="secondary"
                    className={`flex items-center gap-2 rounded-full border border-solid border-primary-200 !px-[10px] !py-[5px] text-sm font-medium  ${
                      open
                        ? 'border-primary-700 !bg-primary-700 !text-white'
                        : ' border-primary-200 bg-white text-primary-500 '
                    }`}>
                    <Icon icon="new-edit" color={open ? 'white' : 'primary'} />
                    Add Widgets
                    <Icon
                      icon="new-chevron-down"
                      className={open ? 'rotate-180 transition-all' : 'transition-all'}
                      color={open ? 'white' : 'primary'}
                    />
                  </Popover.Button>

                  <Popover.Panel className="absolute right-0 z-[99999] mt-[6px] flex w-max  cursor-pointer flex-col gap-2 rounded-lg bg-white  !px-4 !py-4 shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
                    <div className="flex flex-col gap-2" data-dd-privacy="allow">
                      <p className="mb-2 p-2 text-neutral-600">Add Widgets</p>
                      {defaultBoard?.map((feature) => {
                        const item = layout.lg.find(
                          (layoutItem) => layoutItem.widget === feature.widget
                        );
                        const canAdd = feature.multiple || !item;
                        return (
                          <div
                            key={feature?.widget}
                            className="flex items-center justify-between text-base">
                            <div
                              data-dd-privacy="allow"
                              className="flex !pr-5 text-lg font-400 text-primary-900"
                              draggable={canAdd}
                              // unselectable="on"
                              onDragStart={(e) => {
                                if (canAdd) {
                                  e.dataTransfer.setData('text/plain', '');
                                  setSelectedFeature(feature.name);
                                }
                              }}>
                              {canAdd && (
                                <Icon icon="new-sort" className="mr-1" color="primary"></Icon>
                              )}
                              <p>{feature.name}</p>
                            </div>

                            <div
                              data-dd-privacy="allow"
                              onClick={() =>
                                canAdd ? addItem(feature.name) : setDeleteWidgetModal(item.i)
                              }
                              className={`cursor-pointer rounded-full bg-gray-50 p-2 transition-all ${
                                !canAdd && 'rotate-45'
                              }`}>
                              <Icon icon="new-plus" className="cursor-pointer text-sm"></Icon>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </Popover.Panel>
                </div>
              )}
            </Popover>
            <Button
              type="secondary"
              className="!bg-white !px-[12px] !py-[5px] !font-medium"
              text="Save changes"
              icon="new-diskette"
              onClick={applyEdit}
            />
          </>
        ) : (
          <>
            {children}
            <Button
              type="secondary"
              className="!bg-white !px-[11px] !py-[5px]"
              text="Customize"
              icon="new-edit"
              onClick={enterEdit}
            />
          </>
        )}
      </div>
    </div>
  );
}
