export default function updateItemCount({
  newItems,
  setNewItems,
  itemId,
  invoiceId,
  packageId,
  increment = true
}) {
  const updatedItems = newItems.map((item) => {
    if (
      item.id === itemId &&
      item.invoiceId === invoiceId &&
      (item.packageId === packageId || (!item.packageId && !packageId))
    ) {
      const count = increment ? item.count + 1 : item.count > 1 ? item.count - 1 : item.count;

      return { ...item, count };
    }
    return item;
  });

  setNewItems(updatedItems);
}
