import React from 'react';
import Accordion from '../../../../shared/Accordion';
import ClinicalSocialHistory from 'components/shared/Forms/Custom/SocialHistory/ClinicalSocialHistory';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const SocialHistorySection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = []
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      updateFormType={{
        name: 'Social History',
        parentKey: 'hp',
        childKey: 'socialHistory'
      }}
      title={components?.socialHistory?.title}
      id={components?.socialHistory?.id}
      disabled={clinicalNote?.locked}>
      <ClinicalSocialHistory
        formId={formId}
        fromClinicalNote={true}
        defaultValue={defaultValue}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
        showNarrative
        setCurrentFormData={setCurrentFormData}
        options={options}
      />
    </Accordion>
  );
};

export default SocialHistorySection;
