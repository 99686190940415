import React from 'react';
import Filters from './components/Filters';
import Today from './components/Today';
import Months from './components/Months';
import Years from './components/Years';

const Navigation = ({ currentMonth, changeMonthHandle, goToTodayHandle }) => (
  <div className="flex justify-between !px-4 !pb-3 gap-2 xs:!px-3">
    <Filters />
    <div className="flex items-center gap-2">
      <Today goToTodayHandle={goToTodayHandle} />
      <Months {...{ currentMonth, changeMonthHandle }} />
      <Years {...{ currentMonth, changeMonthHandle }} />
    </div>
  </div>
);

export default Navigation;
