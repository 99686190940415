import React, { createContext, useContext, useState } from 'react';

export const ScheduleOfFeesContext = createContext();

export const useScheduleOfFeesContext = () => useContext(ScheduleOfFeesContext);

export const ScheduleOfFeesContextProvider = ({ children, defaultData = [] }) => {
  const [rowData, setRowData] = useState(defaultData);
  return (
    <ScheduleOfFeesContext.Provider value={{ rowData, setRowData }}>
      {children}
    </ScheduleOfFeesContext.Provider>
  );
};
