import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { growthOptionChart, growthOptionGender } from '/constants';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Pagination from 'components/shared/Pagination/Pagination';
import Filters from './Filters';
import GrowthDataList from './ListAndDelete';
import NewAndUpdateGrowth from './NewAndUpdate';
import NewAndUpdateValidation from './NewAndUpdateValidation';
import { requestApi } from 'api/Api';

const LIMIT = 10;
const GrowthData = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    page: 1,
    limit: LIMIT,
    chartType: [],
    gender: [],
    applyFilters: false,
    withCount: true
  });
  const [growthData, setGrowthData] = useState([]);
  const [growthDataCount, setGrowthDataCount] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState({ create: false, update: false });
  const [loading, setLoading] = useState({
    get: false,
    create: false,
    update: false,
    delete: false
  });
  const navigate = useNavigate();

  useEffect(() => {
    getGrowthData();
  }, [filters?.page, filters?.searchTerm, filters?.applyFilters]);

  const getGrowthData = async () => {
    setLoading({ ...loading, get: true });
    try {
      let data = await requestApi({
        url: `/api/admin/growth_data/get`,
        params: {
          ...filters
        },
        navigate
      });
      const { code, redirect, growthData, growthDataCount } = data;

      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          setGrowthData(growthData);
          if (growthDataCount) setGrowthDataCount(growthDataCount);
          break;

        default:
          Honeybadger.notify(
            `file: /databank/GrowthData, method: getGrowthData - try, error: 'An unexpected error has occurred.`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: /databank/GrowthData, method: getGrowthData - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }

    setLoading({ ...loading, get: false });
  };

  const formik = useFormik({
    initialValues: {
      id: null,
      measurement_type: '',
      measurement_value: null,
      chart_type: '',
      gender: '',
      l: null,
      m: null,
      s: null,
      sd: null,
      p01: null,
      p1: null,
      p3: null,
      p5: null,
      p10: null,
      p15: null,
      p25: null,
      p50: null,
      p75: null,
      p85: null,
      p90: null,
      p95: null,
      p97: null,
      p99: null,
      p999: null
    },
    validationSchema: NewAndUpdateValidation,
    enableReinitialize: true
  });

  const hideModal = () => {
    setShowModal(false);

    setMode({ update: false, create: true });

    formik.resetForm();
  };

  const changePage = ({ selected }) => {
    setFilters((prevState) => ({ ...prevState, page: selected + 1, withCount: false }));
  };

  return (
    <div className="flex h-screen flex-col">
      <div className="!m-8">
        <div className="!mb-5 flex justify-between">
          <div className="flex !gap-3">
            <Input
              id="search"
              type="string"
              placeholder="Search Measurement Value..."
              value={filters?.searchTerm}
              onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
              rightText={
                filters?.searchTerm && (
                  <Icon
                    icon="new-close"
                    onClick={() => setFilters({ ...filters, searchTerm: '', page: 1 })}
                  />
                )
              }
            />

            <Filters
              filters={filters}
              setFilters={setFilters}
              type="chartType"
              filterOptions={growthOptionChart}
            />

            <Filters
              filters={filters}
              setFilters={setFilters}
              type="gender"
              filterOptions={growthOptionGender}
            />
          </div>

          <Button
            size="small"
            type="secondary"
            text="New Growth Data"
            onClick={() => setShowModal(true)}
          />
        </div>

        <div className={'!mt-3 flex w-fit flex-wrap rounded-md bg-primary-50 !p-2 shadow-sm'}>
          <p className="text-sm font-500 text-primary-800">
            Please note that all the data below is in metric units (cm, kg). For instance, weight is
            measured in kilograms (kg), while length/height is measured in centimeters (cm).
          </p>
        </div>
      </div>

      <div className="overflow-auto">
        <GrowthDataList
          formik={formik}
          setMode={setMode}
          loading={loading}
          growthData={growthData}
          hideModal={hideModal}
          setLoading={setLoading}
          setGrowthData={setGrowthData}
          setShowModal={setShowModal}
        />
      </div>

      <Pagination
        onPageChange={changePage}
        page={filters?.page}
        perPage={LIMIT}
        totalItems={growthDataCount}
      />

      <NewAndUpdateGrowth
        mode={mode}
        formik={formik}
        loading={loading}
        showModal={showModal}
        hideModal={hideModal}
        setLoading={setLoading}
        setGrowthData={setGrowthData}
      />
    </div>
  );
};

export default GrowthData;
