import React, { useEffect, useState } from 'react';
import AssessmentSection from '../../../../CurrentNote/TypeNotes/HPNote/components/AssessmentSection';
import CPTCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import MedicationsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/MedicationsSection';
import PlanSection from '../../../../CurrentNote/TypeNotes/HPNote/components/PlanSection';
import VitalsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import GeneralNoteSection from '../../../../CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import ObjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/ObjectiveSection';
import SubjectiveSection from '../../../../CurrentNote/TypeNotes/SOAPNote/components/SubjectiveSection';
import OrdersSection from '../../../../Orders/OrdersSection';
import FormsAI from '../FormsAI';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import AdvancedSoap from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/AdvancedSoap';

const getFormsList = (advancedSOAP, cnDisplaySettings) => {
  const formsList = {
    subjective: (props) => <SubjectiveSection {...props} withStyle={false} />,
    objective: (props) => <ObjectiveSection {...props} withStyle={false} />,
    assessment: (props) => <AssessmentSection {...props} withStyle={false} />,
    medicationHistory: (props) => (
      <div className="relative flex h-[600px] flex-col">
        <MedicationsSection {...props} isResizable={false} />
      </div>
    ),
    plan: (props) => <PlanSection {...props} withStyle={false} />,
    vitals: (props) => <VitalsSection {...props} withStyle={false} />,
    'cpt-codes': (props) => <CPTCodesSection {...props} withStyle={false} />,
    'diagnosis-codes': (props) => (
      <ICDCodesSection {...props} withStyle={false} withScroll={false} />
    ),
    notes: (props) => <GeneralNoteSection {...props} withStyle={false} />,
    orders: (props) => <OrdersSection {...props} withStyle={false} />,
    ...assignAdvTitlesToComponent(
      advancedSOAP,
      (props) => <AdvancedSoap {...props} withStyle={false} />,
      cnDisplaySettings
    )
  };

  return formsList;
};

const SOAPFormsAI = ({ inputName, breadcrumb = null, title = '', components }) => {
  const { setSteps, setStep } = breadcrumb || {};
  const { advancedSOAP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedSOAP, cnDisplaySettings));

  useEffect(() => {
    if (!breadcrumb) return;
    setSteps([
      {
        title,
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  return <FormsAI formsList={formsList} inputName={inputName} components={components} />;
};

export default SOAPFormsAI;
