export const convertPermissions = (permissions) => {
  let result = {};

  Object.keys(permissions).forEach((key) => {
    const actions = permissions[key].actions;

    actions.forEach((action) => {
      const [fieldGroup, fieldAction] = action.field.split('.');

      if (!result[fieldGroup]) {
        result[fieldGroup] = {};
      }

      result[fieldGroup][fieldAction] = action.value ? true : false;
    });
  });

  return result;
};

export const checkFractionString = (fraction = '') => {
  const [a, b] = fraction.split('/').map(Number);
  return b !== 0 && a / b === 1;
};

export const getActionDefinition = (permissions, field) => {
  const permissionKeys = Object.keys(permissions);

  for (const permissionKey of permissionKeys) {
    const permissionGroupActions = permissions[permissionKey].actions;
    const actionKeys = Object.keys(permissionGroupActions);
    for (const actionKey of actionKeys) {
      if (permissionGroupActions[actionKey].field === field) {
        return permissionGroupActions[actionKey];
      }
    }
  }

  return null;
};

export const getPermissionGroupKey = (permissions, field) => {
  const permissionKeys = Object.keys(permissions);

  for (const permissionKey of permissionKeys) {
    const permissionGroupActions = permissions[permissionKey].actions;
    const actionKeys = Object.keys(permissionGroupActions);
    for (const actionKey of actionKeys) {
      if (permissionGroupActions[actionKey].field === field) {
        return permissionKey;
      }
    }
  }

  return null;
};

export const getFieldValue = (formik, field) => {
  const [fieldGroup, fieldAction] = field.split('.');
  return formik.values?.permissions?.[fieldGroup]?.[fieldAction];
};

export const setFieldValue = (formik, field, value) => {
  const [fieldGroup, fieldAction] = field.split('.');
  formik.setFieldValue(`permissions.${fieldGroup}.${fieldAction}`, value);
};

export const getDependents = (permissions, fieldValue) => {
  const permissionKeys = Object.keys(permissions);
  let depencyList = [];
  for (const permissionKey of permissionKeys) {
    const permissionGroupActions = permissions[permissionKey].actions;
    for (const action of permissionGroupActions) {
      if (action.dependencies && action.dependencies.includes(fieldValue)) {
        depencyList.push(action.field);
      }
    }
  }
  return depencyList;
};

export const getGroupCounts = (groups) => {
  const groupCounts = {};
  for (const group of groups) {
    groupCounts[group] = (groupCounts[group] || 0) + 1;
  }
  return groupCounts;
};
