import { Popover } from '@headlessui/react';
import cs from 'classnames';
import { addDays, endOfDay, startOfDay } from 'date-fns';
import React from 'react';
import { DateRangePicker as CalendarRangePicker } from 'react-date-range';
import { useRecoilValue } from 'recoil';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';
import { formatDate } from '../../../lib/helpers/utility';
import { defaultStaticRanges } from '../../practice/BeyondBilling/FinancialReporting/StaticFilteringRanges';
import { currentPractice } from '../../practice/practiceState';
import Icon from '../Icon/Icon';

const DateRangePicker = ({
  ranges = [
    {
      startDate: startOfDay(new Date()),
      endDate: endOfDay(new Date()),
      key: 'selection'
    }
  ],
  startDate,
  endDate,
  onChange = () => null,
  months = 2,
  minDate = -300,
  maxDate = 900,
  direction = 'horizontal',
  scroll = { enabled: true },
  showSelectionPreview = true,
  moveRangeOnFirstSelection = true,
  staticRanges = defaultStaticRanges,
  buttonClassName = '',
  buttonTextColor = '',
  iconColor = 'primary',
  customStyling = {},
  isTime = false,
  editableDateInputs = false,
  customButton = null
}) => {
  const practice = useRecoilValue(currentPractice);
  const { device } = useUIContext();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            as="div"
            className={cs(
              'relative flex min-h-[30px] min-w-[30px] cursor-pointer items-center justify-center gap-1 rounded-md border border-neutral-100 bg-white !p-2 shadow-sm',
              open && 'todo:AddActiveStyling',
              buttonClassName
            )}>
            {customButton ? (
              customButton
            ) : (
              <>
                <Icon icon="new-calendar" size={18} color={iconColor} />
                {device !== 'mobile' && (
                  <>
                    <label className={cs('!m-0 cursor-pointer text-neutral-800', buttonTextColor)}>
                      {formatDate(startDate, practice.timezone)}
                    </label>
                    <span className={buttonTextColor}>-</span>
                    <label className={cs('!m-0 cursor-pointer text-neutral-800', buttonTextColor)}>
                      {formatDate(endDate, practice.timezone)}
                    </label>
                  </>
                )}
              </>
            )}
          </Popover.Button>
          <Popover.Panel
            className="absolute right-0 z-10 mt-1 flex overflow-hidden !rounded-lg shadow-md"
            style={customStyling}>
            <CalendarRangePicker
              editableDateInputs={editableDateInputs}
              dateDisplayFormat={isTime ? 'dd/MM/yyyy hh:mm:ss a' : 'dd/MM/yyyy'}
              onChange={onChange}
              showSelectionPreview={showSelectionPreview}
              moveRangeOnFirstSelection={moveRangeOnFirstSelection}
              months={
                device === 'mobile' || device === 'tablet-sm' || device === 'tablet' ? 1 : months
              }
              minDate={addDays(new Date(), minDate)}
              maxDate={addDays(new Date(), maxDate)}
              direction={direction}
              scroll={scroll}
              ranges={[
                {
                  startDate: startOfDay(ranges[0].startDate),
                  endDate: endOfDay(ranges[0].endDate),
                  key: 'selection'
                }
              ]}
              staticRanges={staticRanges}
            />
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default DateRangePicker;
