import React, { useState } from 'react';
import Button from '../Button/Button';
import Message from './Message';
import './Error.scss';

const ErrorMessage = ({ errors, title, collapsable = false }) => {
  const [show, setShow] = useState({ collapsed: false });

  if (!errors) return null;

  if (Array.isArray(errors)) {
    return (
      <div className="Warning">
        <div className="Warning__row">
          {title && <div>{title}</div>}
          {collapsable && <Button>{show.collapsed ? 'Expand' : 'Collapse'}</Button>}
        </div>
        <ul>{errors.length > 0 && errors.map((v) => <Message message={v} />)}</ul>
      </div>
    );
  }

  if (typeof errors === 'string') {
    return (
      <div className="Warning">
        {title && <div>{title}</div>}
        {errors && <div>{errors}</div>}
      </div>
    );
  }

  return null;
};

export default ErrorMessage;
