import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import _, { capitalize } from 'lodash';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { formatDate, formatPhoneNumber } from 'lib/helpers/utility';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';

import NewPatientAdmin from './NewPatientAdminContainer';

export default function Patients() {
  const [showModal, setShowModal] = useState(false);
  const columns = [
    { field: 'id', headerName: 'Chart ID' },
    {
      field: 'f_name',
      headerName: 'First Name',
      valueFormatter: ({ value }) => capitalize(value)
    },
    { field: 'l_name', headerName: 'Last Name', valueFormatter: ({ value }) => capitalize(value) },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      valueFormatter: ({ value }) => formatDate(value)
    },
    { field: 'email', headerName: 'Email' },

    {
      field: 'phone',
      headerName: 'Phone',
      valueFormatter: ({ value }) => formatPhoneNumber(value)
    },
    {
      colId: 'status',
      headerName: 'Status',
      valueGetter: (params) => {
        return params.data.practicePatientLink.status;
      },
      valueFormatter: ({ value }) => capitalize(value)
    }
  ];

  return (
    <Allowed requiredPermissions="patients.read" showMessage showIllustration>
      <TableContextProvider cols={columns} pagination name="patient_chartsAdmin">
        <Table setShowModal={setShowModal} />
      </TableContextProvider>
      {!!showModal && (
        <NewPatientAdmin hideModal={() => setShowModal(false)} showModal={!!showModal} />
      )}
    </Allowed>
  );
}

function Table({ setShowModal }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const navigate = useNavigate();

  const {
    data = {},
    isLoading,
    isFetching
  } = usePatients({
    params: { practiceId: id, searchTerm: debouncedSearchTerm, page, sort, limit },
    dependencies: [debouncedSearchTerm, page, sort, limit, id],
    page,
    sort
  });

  const patients = data.patients;

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/admin/patients/${event.data.id}`);
    }
  };
  return (
    <div className="flex h-full flex-col">
      <div className="ml-auto mr-3 flex items-center gap-2">
        <DisplayButton />
        <Input
          data-qa="searchPatient"
          type="string"
          placeholder="Search patients..."
          value={searchTerm}
          inputWrapperClassName="h-[30px]"
          icon="new-search"
          className="md:w-[200px]"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          rightText={
            searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearchTerm('');
                  setPage(1);
                }}
              />
            )
          }
        />
        <Allowed requiredPermissions="patients.create">
          <Button
            data-qa="new-cpt-code-btn"
            size="small"
            type="primary"
            text="New Patient"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Allowed>
      </div>
      <div className="flex h-full flex-col  overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0 h-full">
          <AGTable
            data={patients}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
        </div>
      </div>
    </div>
  );
}
