import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useEras } from 'lib/hooks/queries/era/useEras';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import HeaderActions from '../components/HeaderActions';

import { COLUMN_DEFS, ERA_SWITCH_ITEMS, INITIAL_FILTERS } from './lib/helpers';

const Era = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <AgTablePage
        defaultColumns={COLUMN_DEFS}
        defaultFilters={INITIAL_FILTERS}
        useData={useEras}
        name="era"
        onSelectRow={(era) => navigate(`${era?.id}`)}
        rowStyle={{ cursor: 'pointer' }}
        headerActions={HeaderActions}
        switchItems={ERA_SWITCH_ITEMS}
      />
      {id && <Outlet />}
    </>
  );
};

export default Era;
