const normalizePhone = (_n, forceString = false) => {
  if (!_n) return '';
  let split = _n.split('x');

  const n = String(split[0]).replace(/[\'\ \"()\-a-zA-Z\n\r]/g, '');
  if (n.length < 10 || n.length > 12) {
    return n;
  }
  let r = '';

  if (/^\+1/.test(n)) r = n.substring(2);
  else if (/^1\d{10}/.test(n)) r = n.substring(1);
  else r = n;

  if (r.includes('(')) r = r.replace(/\(/, '');
  if (r.includes(')')) r = r.replace(/\)/, '');
  if (r.includes('-')) r = r.replace(/\-/g, '');
  if (r.includes(' ')) r = r.replace(/\ /g, '');

  return forceString ? String('+1' + r) : String(r);
};

export default normalizePhone;
