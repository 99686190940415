import { requestApi } from './Api';

export const enhanceNarrative = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/enhance_narrative',
    params
  });

export const getMyScribePatientForms = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/patient/get_forms',
    params
  });

export const getMyScribeAIPrompts = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/chat/list',
    params
  });

export const getMyScribeAIPrompt = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/chat/get',
    params
  });

export const deleteMyscribeAIPrompt = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/chat/delete',
    params
  });
