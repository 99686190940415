import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { formatDate, stripHtmlTags } from 'lib/helpers/utility';
import SimpleTable from 'components/shared/Forms/Custom/ProviderMedications/components/SimpleTable';

const cols = [
  {
    field: 'vaccine.value',
    headerName: 'Vaccine',
    cellRenderer: ({ data }) =>
      data?.vaccine?.value ? (
        <span style={{ wordBreak: 'break-word' }}>{data.vaccine.value}</span>
      ) : (
        'N/A'
      )
  },
  {
    field: 'doses.value',
    headerName: 'Doses taken',
    cellRenderer: ({ data }) => (data?.doses?.value ? data?.doses?.value : 'N/A')
  },
  {
    field: 'lastDose',
    headerName: 'Date of last dose',
    className: 'min-w-20',
    cellRenderer: ({ value, data }) =>
      value && data?.lastDoseDisabled === false ? formatDate(value, undefined) : 'N/A'
  },
  {
    field: 'note',
    headerName: 'Note',
    cellRenderer: ({ data }) => (data?.note ? stripHtmlTags(data.note) : 'N/A')
  }
];

const VaccinesSimpleTable = ({ data, emptyState = 'No vaccines' }) => {
  return (
    <>
      <TableContextProvider name="vaccines_simple_table" defaultFilters={[]} cols={cols}>
        <SimpleTable data={data} emptyState={emptyState} />
      </TableContextProvider>
    </>
  );
};

export default VaccinesSimpleTable;
