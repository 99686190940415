import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { columnHeaderBaseOptions } from './lib/constants';
import handleFileLoadedMatrix from './lib/handleFileLoadedMatrix';
import UploadFile from './components/UploadFile';
import GenerateEmails from './components/GenerateEmails';
import SubmitFile from './components/SubmitFile';
import FileSuccess from './components/FileSuccess';
import PastImportsWrapper from './components/PastImports/PastImportsWrapper';
import Default from './components/Default';
import uploadFile from './lib/uploadFile';
import Allowed from 'components/shared/Permissions/Allowed';

const parserOptions = { header: false, dynamicTyping: true, skipEmptyLines: true };
const defaultFile = { data: [], numberOfEntries: 0, fileName: '', path: '', sampleEntries: [] };

const PatientImport = () => {
  const [show, setShow] = useState({
    loading: false,
    patientsImported: '',
    patientsNotImported: '',
    patientsMissingInfo: '',
    generateEmails: false,
    defaultService: false,
    defaultProvider: false,
    stage: 'uploadFile',
    holds: false,
    messages: []
  });
  const [file, setFile] = useState(defaultFile);
  const [headers, setHeaders] = useState([]);
  const [headerValue, setHeaderValue] = useState([]);
  const [columnHeaderOptions, setColumnHeaderOptions] = useState([]);
  const [patientDetailsFormat, setPatientDetailsFormat] = useState({
    dobFormat: null,
    policyStartDateFormat: null,
    policyEndDateFormat: null,
    patientName: null,
    providerName: null,
    transactionAmountFormat: null,
    transactionAmountSelfFormat: null
  });
  const dobFormatRef = useRef(null);
  const nameFormatRef = useRef(null);
  const providerNameFormatRef = useRef(null);
  const policyStartDateFormatRef = useRef(null);
  const policyEndDateFormatRef = useRef(null);
  const transactionAmountFormatRef = useRef(null);
  const transactionAmountSelfFormatRef = useRef(null);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleFileLoaded = async (data, fileInfo) => {
    await handleFileLoadedMatrix({
      data,
      fileInfo,
      setFile,
      setHeaders,
      setHeaderValue,
      setColumnHeaderOptions,
      columnHeaderBaseOptions,
      defaultFile,
      show,
      setShow
    });
  };

  const handleUploadFile = async () => {
    await uploadFile({
      show,
      setShow,
      headerValue,
      file,
      id,
      navigate,
      patientDetailsFormat
    });
  };

  const stageComponents = {
    uploadFile: (
      <UploadFile
        show={show}
        setShow={setShow}
        parserOptions={parserOptions}
        handleFileLoaded={handleFileLoaded}
        file={file}
        id={id}
        headers={headers}
      />
    ),
    generateEmails: <GenerateEmails show={show} setShow={setShow} file={file} />,
    submitFile: (
      <SubmitFile
        file={file}
        id={id}
        show={show}
        headers={headers}
        headerValue={headerValue}
        patientDetailsFormat={patientDetailsFormat}
        setPatientDetailsFormat={setPatientDetailsFormat}
        columnHeaderOptions={columnHeaderOptions}
        handleUploadFile={handleUploadFile}
        setShow={setShow}
        setHeaderValue={setHeaderValue}
        setColumnHeaderOptions={setColumnHeaderOptions}
        navigate={navigate}
        dobFormatRef={dobFormatRef}
        nameFormatRef={nameFormatRef}
        providerNameFormatRef={providerNameFormatRef}
        policyStartDateFormatRef={policyStartDateFormatRef}
        policyEndDateFormatRef={policyEndDateFormatRef}
        transactionAmountFormatRef={transactionAmountFormatRef}
        transactionAmountSelfFormatRef={transactionAmountSelfFormatRef}
      />
    ),
    fileSuccess: <FileSuccess show={show} setShow={setShow} />,
    pastImports: <PastImportsWrapper show={show} setShow={setShow} id={id} />,
    default: <Default />
  };

  const componentToShow = stageComponents[show.stage] || stageComponents.default;

  return (
    <Allowed requiredPermissions="patients.create" showMessage showIllustration>
      {componentToShow}
    </Allowed>
  );
};

export default PatientImport;
