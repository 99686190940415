import Tippy from '@tippyjs/react';
import React, { useEffect, useState } from 'react';
import Button from '../../../../../shared/Buttons/Button';
import ConfirmSignLock from '../../shared/ConfirmSignLock';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from '../../../../../../lib/helpers/utility';
import { useDocumentSign } from '../../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { userState, clinicalNote as clinicalNoteState } from '../../../../../state';
import { showPreviewNote } from '../../lib/showPreviewNote';

const SignAndLock = ({ buttonProps }) => {
  const {
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    showConfirmSignLock,
    saveButtonText,
    setDocumentSignInfo,
    documentSignInfo,
    setIsExport,
    setShowPreviewNoteLoading,
    navigate,
    saveNoteRef = null
  } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const { signLockButtonInfo = null } = documentSignInfo || {};
  const user = useRecoilValue(userState);
  const currentUserId = user?.user_id ?? user.id;
  const [userSign, setUserSign] = useState(false);
  const { id, appointmentId } = useParams() || {};
  const [loading, setLoading] = useState(false);
  const { device } = useUIContext();

  const { data: documentSignatures } = useDocumentSign({
    params: {
      documentIds: [clinicalNote?.id],
      withRelations: { user: true }
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id,
      select: (response) => response.data
    }
  });

  const isUserAssignedToDocument = iaRa(documentSignatures).find(
    (row) => row?.user_id == currentUserId
  );

  const hasUserSigned = isUserAssignedToDocument?.status === 'signed';

  useEffect(() => {
    processDocumentSignInfo();
  }, [documentSignatures, clinicalNote?.id, saveButtonText]);

  const processDocumentSignInfo = () => {
    const userSign = isUserAssignedToDocument?.id ? hasUserSigned : clinicalNote?.locked;

    setUserSign(userSign);

    const signersLeft = iaRa(documentSignatures)
      ?.map((row) => `${row?.user?.f_name} ${row?.user?.l_name}`)
      .join(', ');

    const signLockButtonInfo = !clinicalNote?.id
      ? 'Clinical note has not started yet.'
      : saveButtonText === 'Saving'
        ? 'Please wait until the note is saved.'
        : !isUserAssignedToDocument?.id
          ? `You're not assigned to this document. The assigned providers are: ${signersLeft}`
          : null;

    setDocumentSignInfo({
      isUserAssignedToDocument,
      signLockButtonInfo,
      userSign
    });

    return;
  };

  const onShowConfirmSignLock = async () => {
    if (clinicalNote?.locked) {
      showConfirmSignLock(true);
      return;
    }

    setLoading(true);
    await showPreviewNote({
      patientId: clinicalNote?.user_id || id,
      appointmentId: clinicalNote?.appointment_id || appointmentId,
      saveButtonText,
      exportNote: true,
      setClinicalNote,
      setAdvancedHP,
      setAdvancedSOAP,
      setCustomFormTypes,
      setPreviewNoteModal: showConfirmSignLock,
      setIsExport,
      setLoading: setShowPreviewNoteLoading,
      onSaveNote: saveNoteRef?.current?.onSaveNote,
      navigate
    });
    setLoading(false);
  };

  return (
    <div className="flex items-center gap-x-2">
      <Tippy
        placement="bottom"
        className="tippy-dark"
        content={signLockButtonInfo}
        disabled={!signLockButtonInfo}>
        <div>
          <Button
            icon={userSign ? 'new-unlockv2' : 'new-lockv2'}
            data-qa="sign-lock-unlock-btn"
            {...(['desktop', 'laptop', 'tablet-lg'].includes(device) && {
              text: userSign ? 'Unlock' : 'Sign & lock'
            })}
            className="hover:!bg-primary-800"
            textClassName="text-primary-100"
            shade={100}
            transparent
            size="small"
            disabled={
              saveButtonText === 'Saving' ||
              !clinicalNote?.id ||
              !isUserAssignedToDocument?.id ||
              loading
            }
            {...buttonProps}
            onClick={(...r) => {
              if (buttonProps?.onClick) {
                buttonProps?.onClick();
                setTimeout(() => onShowConfirmSignLock(...r), 100);
              } else {
                onShowConfirmSignLock(...r);
              }
            }}
          />
        </div>
      </Tippy>

      <ConfirmSignLock documentSignatures={documentSignatures} hasUserSigned={userSign} />
    </div>
  );
};

export default SignAndLock;
