import { iaRa, optionify } from 'lib/helpers/utility';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';

import {
  addAdjustment,
  deleteAdjustment,
  updateAdjustmentProperty
} from '../../../../EOB/lib/allocateHelpers';

const AllocateAdjustment = ({
  adjustments,
  groupList,
  codeList,
  setProcedures,
  procedureCode,
  index
}) => {
  const isFirstItem = index === 0;

  return (
    <div className={`flex flex-col gap-1${!isFirstItem ? ' mt-1' : ''}`}>
      {iaRa(adjustments)?.map(({ amount, group, code }, idx) => {
        const isFirstItem = idx === 0;
        return (
          <div className="flex gap-1 items-center" key={idx}>
            <CurrencyInput
              value={amount * 100}
              parentClassName="w-24 min-w-24"
              label={isFirstItem && 'Amount'}
              wrapperClassName="!px-[6px] !py-[6px]"
              onBlur={(amount) => {
                updateAdjustmentProperty(
                  setProcedures,
                  idx,
                  'amount',
                  (amount / 100).toFixed(2),
                  index
                );
              }}
            />
            <Select
              isClearable={false}
              inputId="selectGroup"
              className="font-400 !p-0"
              parentClassName="w-24 min-w-24 !p-0"
              label={isFirstItem && 'Group'}
              value={{ value: group, label: group }}
              options={optionify(groupList, 'label', 'value')}
              onChange={({ value }) =>
                updateAdjustmentProperty(setProcedures, idx, 'group', value, index)
              }
              menuPortalTarget={document.body}
              styles={{ control: { maxHeight: '40px' } }}
            />
            <Select
              isClearable={false}
              inputId="selectCode"
              className="font-400"
              parentClassName="w-24 min-w-24"
              label={isFirstItem && 'Code'}
              value={{ value: code, label: code }}
              options={optionify(codeList, 'label', 'value')}
              onChange={({ value }) =>
                updateAdjustmentProperty(setProcedures, idx, 'code', value, index)
              }
              menuPortalTarget={document.body}
              styles={{ control: { maxHeight: '40px' } }}
            />

            <Icon
              icon="trash"
              color="neutral"
              className={isFirstItem && `!mt-6`}
              onClick={() => deleteAdjustment(setProcedures, idx, index)}
            />
          </div>
        );
      })}

      <div className="flex ">
        <div
          className="flex cursor-pointer items-center"
          onClick={() => addAdjustment(setProcedures, procedureCode, index)}>
          <Icon icon="new-add-circle" className="cursor-pointer" />

          <p className="m-2 w-full text-right text-sm text-primary-500" data-qa="add-adjustment">
            Add Adjustment
          </p>
        </div>
      </div>
    </div>
  );
};

export default AllocateAdjustment;
