import { useCallback, useEffect, useState } from 'react';

const reloadPage = () => window.location.reload(true);

const currentVersion = window.__APP_VERSION__;
const UpdateStatus = { checking: 0, current: 1, available: 2, major: 3, critical: 4 };

export const useVersionCheck = ({ interval, type = 'manual', ignoreServerCache }) => {
  const [status, setStatus] = useState(UpdateStatus.checking);
  const [versions, setVersions] = useState({});

  const checkUpdate = useCallback(() => {
    if (typeof currentVersion === 'undefined') {
      setStatus(UpdateStatus.current);
      return;
    }

    setStatus(UpdateStatus.checking);

    let requestStr = `/${window.__APP_VERSION_FILE__}`;

    if (ignoreServerCache) {
      requestStr += `?v=${Date.now()}`;
    }

    fetch(requestStr)
      .then((res) => res.json())
      .then((data) => {
        if (data.latestVersion === currentVersion) {
          setStatus(UpdateStatus.current);
        } else {
          setStatus(UpdateStatus.available);
        }
        setVersions(data);
      })
      .catch(() => {
        setStatus(UpdateStatus.current);
      });
  }, [ignoreServerCache]);

  useEffect(() => {
    if (type !== 'manual') {
      checkUpdate();
    }
  }, [checkUpdate, type]);

  useEffect(() => {
    if (status !== UpdateStatus.current) {
      return;
    }

    if (type === 'interval') {
      const timeoutId = window.setTimeout(() => checkUpdate(), interval || 10000);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }
  }, [type, interval, status, checkUpdate]);

  return { status, reloadPage, checkUpdate, UpdateStatus, currentVersion, versions };
};
