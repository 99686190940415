import React, { useState } from 'react';

import Button from '../../../../../../../shared/Buttons/Button';
import SignModal from '../../../../shared/Sign/SignModal';
import cs from 'classnames';
import moment from 'moment-timezone';

import { formatDateAndTimeZ, ia } from '../../../../../../../../lib/helpers/utility';
import { useDocumentSign } from '../../../../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../../practiceState';
import { userState } from '../../../../../../../state';
import { processData } from '../../../../shared/helpers/documentSignatureHelpers';

const YourSignature = ({ formik }) => {
  const { id: orderReferralId } = formik?.values || {};
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const practice = useRecoilValue(currentPractice);

  const user = useRecoilValue(userState);
  const fullName = `${user?.f_name} ${user?.l_name}`;

  const [temporarySignature, setTemporarySignature] = useState(null);

  let { data: documentData } = useDocumentSign({
    params: {
      documentIds: [orderReferralId],
      documentTypes: ['referral_order'],
      withRelations: { user: true }
    },
    dependencies: [orderReferralId],
    options: {
      refetchOnMount: false,
      enabled: !!orderReferralId,
      select: (response) => {
        return {
          hasSigned: response.data.find((row) => row.user_id == user.id)?.status === 'signed',
          signatures: processData(response)
        };
      }
    }
  });

  const { hasSigned = false, signatures = [] } = documentData || {};

  if (user?.id != formik?.values?.provider_id) {
    return null;
  }

  if (!hasSigned && !ia(signatures) && temporarySignature?.signature) {
    return (
      <div className="!mt-[6px] inline-flex w-full flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 !py-6">
        <div className="text-center text-[32px] text-black">
          <img className="size-32 object-contain" src={temporarySignature?.signature} />
        </div>
        <div className="flex flex-col items-center justify-start gap-1.5">
          <div className="text-center text-sm text-neutral-800">{fullName}</div>
          <div className="text-center text-sm text-neutral-800">
            {formatDateAndTimeZ(moment(), practice.timezone)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="text-md font-400 text-neutral-900">Your Signature</p>
      {hasSigned ? (
        signatures?.map((row) => {
          return (
            <div className="!mt-3">
              <div className="!mb-2 text-base font-medium leading-normal text-neutral-800">
                {row.role}
              </div>
              <div
                className={cs(
                  'inline-flex w-full flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 py-6',
                  signatures?.length > 1 && 'w-[260px]'
                )}>
                <div className="text-center text-[32px] leading-snug text-black">
                  <img className="size-32 object-contain" src={row.signature} />
                </div>
                <div className="flex flex-col items-center justify-start gap-1.5">
                  <div className="text-center text-sm leading-snug text-neutral-800">
                    {row.signedBy}
                  </div>
                  <div className="text-center text-sm leading-snug text-neutral-800">
                    {row.initials}
                  </div>
                  <div className="text-center text-sm leading-snug text-neutral-800">
                    {row.date ? formatDateAndTimeZ(row?.date, practice?.timezone) : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Button
          text="Sign"
          icon="new-lock-2-opened"
          iconIsStroke
          type="secondary"
          size="small"
          className="!mt-1.5"
          onClick={() => setShowConfirmationModal(true)}
        />
      )}

      <SignModal
        setShowConfirmationModal={setShowConfirmationModal}
        showConfirmationModal={showConfirmationModal}
        confirmModalProps={{
          title: 'Sign Referral Order',
          message: 'Are you sure you want to sign this referral order',
          primaryBtnTxt: 'Sign referral order'
        }}
        onlyReturnSignatureProps={true}
        documentTypes={['referral_order']}
        returnedData={(data) => {
          setTemporarySignature(data);
          formik?.setValues({ ...formik.values, signatureData: data });
        }}
      />
    </div>
  );
};

export default YourSignature;
