import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PatientDetails from '../PatientChart/components/PatientDetails';
import PatientProfile from '../PatientChart/components/PatientProfile';
import PatientActions from '../PatientChart/components/PatientActions';
import Icon from 'components/shared/Icon/Icon';
import { TagContextProvider } from 'lib/context/TagContext/TagContextProvider';

const OverviewBlock = ({ patient, isLoading = false, showNewAppointmentModal }) => {
  const navigate = useNavigate();
  const { device } = useUIContext();

  if (!patient || isLoading)
    return (
      <div className="overflow-hidden rounded-2xl bg-white">
        <Skeleton count={1} height="92px" background="white" highlight="#f8f8f8" />
      </div>
    );

  return (
    <>
      <div className="relative flex items-center justify-between border !border-x-0 !border-b !border-t-0 !border-neutral-100 bg-white py-[10px] pl-1 pr-2">
        <div className="flex min-w-0 gap-4">
          <div className="flex items-center gap-2">
            <div
              className="flex h-6 w-6 shrink-0 cursor-pointer items-center justify-center rounded-full border border-solid border-neutral-300 bg-white shadow-small hover:!bg-neutral-50"
              onClick={() => navigate('/portal/charts')}>
              <Icon icon="new-arrow-left" className="flex cursor-pointer" size="12px" />
            </div>
            <PatientProfile patient={patient} />
          </div>
          {(device === 'desktop' || device === 'laptop') && <PatientDetails patient={patient} />}
        </div>
        <TagContextProvider>
          <PatientActions patient={patient} showNewAppointmentModal={showNewAppointmentModal} />
        </TagContextProvider>
      </div>
      {device !== 'desktop' && device !== 'laptop' && (
        <PatientDetails patient={patient} showAsTabs />
      )}
    </>
  );
};

export default OverviewBlock;
