function getFileExtension(filename) {
  const parts = filename?.split('.');
  if (parts?.length > 1) {
    const type = parts[parts.length - 1];
    return fileIconsTypes.includes(type) ? type : 'unknown';
  }
  return 'unknown';
}

export default getFileExtension;
const fileIconsTypes = [
  'css',
  'csv',
  'docx',
  'exe',
  'html',
  'jpg',
  'js',
  'json',
  'pdf',
  'png',
  'ppt',
  'pptx',
  'txt',
  'xls',
  'xlsx',
  'xml',
  'zip'
];
