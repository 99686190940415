import React, { useState } from 'react';
import CPTCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import VitalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import GeneralNoteSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import GoalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GoalsSection';
import ActiveSymptomsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/ActiveSymptomsSection';
import FunctionalImpairmentSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/FunctionalImpairmentSection';
import InterventionsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/InterventionsSection';
import ProgressSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/ProgressSection';
import RiskFactorsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/RiskFactorsSection';
import SessionQuotesAndContentsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/SessionQuotesAndContentsSection';
import SessionSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/ProgressNote/components/SessionSection';
import OrdersSection from 'components/practice/charts/ClinicalNote/Orders/OrdersSection';
import FormsAI from '../FormsAI';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import AdvancedSoap from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/AdvancedSoap';

const getFormsList = (advancedSOAP, cnDisplaySettings) => {
  const formsList = {
    goals: (props) => <GoalsSection {...props} withStyle={false} />,
    session: (props) => <SessionSection {...props} withStyle={false} />,
    'risk-factors': (props) => <RiskFactorsSection {...props} withStyle={false} />,
    'session-quotes-and-contents': (props) => (
      <SessionQuotesAndContentsSection {...props} withStyle={false} />
    ),
    'active-symptoms': (props) => <ActiveSymptomsSection {...props} withStyle={false} />,
    'functional-impairment': (props) => (
      <FunctionalImpairmentSection {...props} withStyle={false} />
    ),
    vitals: (props) => <VitalsSection {...props} withStyle={false} />,
    interventions: (props) => <InterventionsSection {...props} withStyle={false} />,
    progress: (props) => <ProgressSection {...props} withStyle={false} />,
    'cpt-codes': (props) => <CPTCodesSection {...props} withStyle={false} />,
    'diagnosis-codes': (props) => (
      <ICDCodesSection {...props} withStyle={false} withScroll={false} />
    ),
    notes: (props) => <GeneralNoteSection {...props} withStyle={false} />,
    orders: (props) => <OrdersSection {...props} withStyle={false} />,
    ...assignAdvTitlesToComponent(
      advancedSOAP,
      (props) => <AdvancedSoap {...props} withStyle={false} />,
      cnDisplaySettings
    )
  };

  return formsList;
};

const PROGRESSFormsAI = ({ inputName, components }) => {
  const { advancedSOAP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedSOAP, cnDisplaySettings));

  return <FormsAI formsList={formsList} inputName={inputName} components={components} />;
};

export default PROGRESSFormsAI;
