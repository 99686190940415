import { useQueryClient } from '@tanstack/react-query';
import Button from 'components/shared/Buttons/Button';
import { removeUnderscores } from 'lib/helpers/utility';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { handleAccept, handleCancel } from './lib/helper';

const ClaimFields = ({ claim = {}, change = {}, breadcrumb = {} }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <>
      {Object.entries(change?.data)?.map(([key, value]) => (
        <div
          key={change.claim_id}
          className="mb-2 flex items-center justify-between rounded-md bg-white px-1 py-2 hover:bg-primary-50">
          <div className="flex gap-1">
            <p className="font-600">{removeUnderscores(key).toUpperCase()}:</p>
            <p>
              was updated from
              <span className="font-600"> {claim[key] ? claim[key] : ' - '}</span> to
              <span className="font-600"> {value}</span>
            </p>
          </div>
          <div className="flex gap-2">
            <Button
              text="Cancel"
              type="secondary"
              color="danger"
              size="small"
              outlined
              onClick={() =>
                handleCancel({
                  id: change?.claim_id,
                  changeId: change?.id,
                  key,
                  value,
                  kind: 'claim',
                  navigate,
                  queryClient,
                  breadcrumb
                })
              }
            />
            <Button
              text="Accept"
              type="secondary"
              color="success"
              size="small"
              outlined
              onClick={() =>
                handleAccept({
                  id: change?.claim_id,
                  changeId: change?.id,
                  key,
                  value,
                  kind: 'claim',
                  navigate,
                  queryClient,
                  breadcrumb
                })
              }
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ClaimFields;
