import { colors } from './initials';

export const getRandomColor = (items) => {
  const notUsedColors = colors.filter((color) => !items?.find((p) => p.color === color));
  return notUsedColors[Math.floor(Math.random() * notUsedColors.length)];
};

export function filterItem(item) {
  if (item) {
    switch (item.item_type) {
      case 'service':
        return {
          id: item.id,
          name: item?.name,
          item_type: item?.item_type,
          color: item.color || getRandomColor(),
          sales_count: 0,
          invoice_sales_count: 0,
          charge_type: item?.charge_type,
          charge: item?.standard_charge,
          procedure_code: item?.procedure_code || null,
          total_cost_cents: item?.total_cost_cents || 0,
          visible: true
        };
      case 'icd10':
        return {
          id: item?.id,
          item_type: item?.item_type,
          invoice_sales_count: 0,
          name: item?.name,
          sales_count: 0,
          color: item?.color || getRandomColor(),
          visible: true
        };
      default:
        return {
          id: item?.id,
          name: item?.name,
          quantity: item?.quantity,
          amount_cents: item?.amount_cents || item?.total_amount_cents || 0,
          total_amount_cents: item?.amount_cents || item?.amount_cents || 0,
          amount_cents_with_tax: item?.amount_cents_with_tax || 0,
          lot: item?.lot,
          item_type: item?.item_type,
          sales_count: 0,
          color: item?.color || getRandomColor(),
          expiration_date: item?.expiration_date,
          invoice_sales_count: 0,
          visible: true,
          state_tax_rate: item?.state_tax_rate,
          local_tax_rate: item?.local_tax_rate
        };
    }
  }
}

export function calculateDistance(point1, point2) {
  return Math.sqrt(
    Math.pow(point1[0] - point2[0], 2) +
      Math.pow(point1[1] - point2[1], 2) +
      Math.pow(point1[2] - point2[2], 2)
  );
}

export function filterUniqueById(array) {
  const result = [];
  const uniqueKeys = new Set();

  for (const obj of array) {
    const key = obj.id || obj.procedure_code;

    if (key && !uniqueKeys.has(key)) {
      result.push(obj);
      uniqueKeys.add(key);
    }
  }

  return result;
}

export function getUnits(item, clinicalNote, type, key = 'id', isProcedure) {
  var units = 0;

  if (clinicalNote?.charts3d && Object.keys(clinicalNote.charts3d).includes(type)) {
    units = clinicalNote?.charts3d[type]?.points
      ?.filter((point) => {
        return !isProcedure
          ? point.product === item[key]
          : point.product === item[key] || point.product === item.procedure_code;
      })
      .reduce((a, b) => a + b.units, 0);
  }

  return units;
}
