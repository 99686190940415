import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { archiveClaims, deleteClaims, submitClaims } from 'api/Claim';

import { ia } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import PrintPopover from './PrintPopover';
import ReviewPendingChanges from './ReviewPendingChanges';

const ButtonActions = ({ selectedRows, setSelectedRows }) => {
  const [previewClaims, setPreviewClaims] = useState(false);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showArchiveConfirmationModal, setShowArchiveConfirmationModal] = useState(false);
  const [claimIdsWithPendingChanges, setClaimIdsWithPendingChanges] = useState([]);

  const navigate = useNavigate();

  const selectedClaims = selectedRows.map((node) => node.data);
  const selectedClaimsIds = selectedRows.map((claim) => claim?.data?.id);
  const electronicClaims = selectedClaims.filter((claim) => claim?.kind === 'electronic');
  const paperClaims = selectedClaims.filter((claim) => claim?.kind === 'paper');
  const claimIdsToSubmit = electronicClaims.map((claim) => claim?.id);

  const mutateSubmitClaim = useCustomMutation({
    mutationFn: () => submitClaims(navigate, claimIdsToSubmit),
    invalidateQueryKey: ['claims'],
    onMutate: () => {
      setShowSubmitConfirmation(false);
    },
    successMessage: 'Claims submitted successfully!',
    errorMessage: 'Failed to submit claims!',
    onSuccess: () => {
      setShowSubmitConfirmation(false);
      setSelectedRows([]);
    }
  });

  const mutateDeleteClaim = useCustomMutation({
    mutationFn: () => deleteClaims(navigate, selectedClaimsIds),
    invalidateQueryKey: ['claims'],
    onMutate: () => {
      setShowDeleteConfirmationModal(true);
    },
    successMessage: 'Claims deleted successfully!',
    errorMessage: 'Failed to delete claims!',
    onSuccess: () => {
      setShowDeleteConfirmationModal(false);
      setSelectedRows([]);
    }
  });

  const mutateArchiveClaim = useCustomMutation({
    mutationFn: () => archiveClaims(navigate, selectedClaimsIds),
    invalidateQueryKey: ['claims'],
    onMutate: () => {
      setShowArchiveConfirmationModal(true);
    },
    successMessage: 'Claims archived successfully!',
    errorMessage: 'Failed to archive claims!',
    onSuccess: () => {
      setShowArchiveConfirmationModal(false);
      setSelectedRows([]);
    }
  });

  const loading =
    mutateSubmitClaim?.isLoading || mutateArchiveClaim?.isLoading || mutateDeleteClaim?.isLoading;

  const onSubmitClaim = () => {
    const hasNotReadyClaims = electronicClaims.some((claim) => claim.state !== 'ready');

    if (hasNotReadyClaims) {
      showAlert({
        title: 'Some claims are not ready to be submitted.',
        message: 'All selected claims must be with state "Ready" before submitting',
        icon: 'new-close-circle',
        color: 'danger'
      });
      return;
    }

    const pendingIds = selectedClaims
      ?.filter((claim) => ia(claim?.pending_claim_md_changes))
      .map((claim) => claim?.id);

    if (ia(pendingIds)) {
      setClaimIdsWithPendingChanges(pendingIds);
      setShowSubmitConfirmation(true);
      return;
    }

    if (ia(paperClaims) && ia(electronicClaims)) {
      setPreviewClaims(true);
      return;
    }

    mutateSubmitClaim.mutate();
  };

  return (
    <>
      <Button
        data-qa="delete-selected-claims"
        onClick={() => setShowDeleteConfirmationModal(true)}
        text="Delete"
        type="link"
        loading={mutateDeleteClaim?.isLoading}
        disabled={selectedClaims.length === 0 || loading}
        color="danger"
        icon="new-trash-patient"
        className="!text-danger-500"
        iconSize={18}
      />
      <PrintPopover claims={selectedClaims} />
      <Button
        data-qa="archive-selected-claims"
        onClick={() => setShowArchiveConfirmationModal(true)}
        text="Archive"
        type="link"
        loading={mutateArchiveClaim?.isLoading}
        disabled={selectedClaims.length === 0 || loading}
        icon="new-unarchive"
        color="neutral"
        className="text-neutral-700"
        iconSize={18}
      />
      <Button
        data-qa="submit-selected-claims"
        onClick={onSubmitClaim}
        text="Submit"
        loading={mutateSubmitClaim?.isLoading}
        disabled={electronicClaims.length === 0 || loading}
        icon="new-send-bulk"
        iconColor="white"
        iconSize={18}
      />

      {showDeleteConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title={`Delete ${selectedClaimsIds.length > 1 ? 'Claims' : 'Claim'}`}
          icon="new-document-remove-red"
          message={`Are you sure you want to delete ${selectedClaimsIds.length > 1 ? ' these claims' : 'this claim'}?`}
          loading={mutateDeleteClaim?.isLoading}
          handleContinue={() => mutateDeleteClaim.mutate()}
          handleOpen={showDeleteConfirmationModal}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {showSubmitConfirmation && (
        <ReviewPendingChanges
          type="archived"
          claimIdsWithPendingChanges={claimIdsWithPendingChanges}
          showSubmitConfirmation={showSubmitConfirmation}
          setShowSubmitConfirmation={setShowSubmitConfirmation}
          handleContinue={() => mutateSubmitClaim.mutate()}
        />
      )}
      {previewClaims && (
        <Confirm
          variant="primary"
          primaryBtnTxt="Submit Electronic Claims"
          title="Some claims can only be printed."
          message={`Paper claims, such as claim ID ${paperClaims.map((claim) => claim.id).join(', ')} can only be printed.`}
          handleContinue={() => mutateSubmitClaim.mutate()}
          handleOpen={previewClaims}
          handleClose={() => setPreviewClaims(false)}
        />
      )}

      {showArchiveConfirmationModal && (
        <Confirm
          variant="primary"
          primaryBtnTxt="Archive Claims"
          title="Are you sure you want to archive these claims?"
          message="This action will move these claims to the archived section and they will no longer be visible in the claims list."
          handleContinue={() => mutateArchiveClaim.mutate()}
          handleOpen={showArchiveConfirmationModal}
          handleClose={() => setShowArchiveConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default ButtonActions;
