import Button from 'components/shared/Buttons/Button';
import { ia, removeUnderscores } from 'lib/helpers/utility';
import React from 'react';
import { handleAccept, handleCancel } from './lib/helper';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const ProceduresFields = ({ change = {}, primaryProcedures = [], breadcrumb = {} }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return (
    <>
      {ia(change?.data) &&
        change?.data?.map((proc, index) => {
          const procedureCode = primaryProcedures[proc?.position]?.procedure_code;

          if ('procedure_code' in proc && proc?.procedure_code === '') {
            return (
              <div
                key={index}
                className="mb-2 flex items-center justify-between rounded-md bg-white px-1 py-2 hover:bg-primary-50">
                <p>
                  <span className="font-600">Procedure {procedureCode}:</span> was deleted
                </p>
                <div className="flex gap-2">
                  <Button
                    text="Cancel"
                    type="secondary"
                    color="danger"
                    size="small"
                    outlined
                    onClick={() =>
                      handleCancel({
                        id: change?.claim_id,
                        changeId: change?.id,
                        updatedField: proc,
                        kind: 'invoice',
                        key: 'procedures',
                        navigate,
                        queryClient,
                        breadcrumb
                      })
                    }
                  />
                  <Button
                    text="Accept"
                    type="secondary"
                    color="success"
                    size="small"
                    outlined
                    onClick={() =>
                      handleAccept({
                        id: change?.claim_id,
                        changeId: change?.id,
                        updatedField: proc,
                        kind: 'invoice',
                        key: 'procedures',
                        navigate,
                        queryClient,
                        breadcrumb
                      })
                    }
                  />
                </div>
              </div>
            );
          }

          const formattedFields = Object.entries(proc)
            .filter(([key]) => key !== 'position' && key !== 'type' && key !== 'id')
            .map(([key, value]) => {
              if (key === 'modifiers' && Array.isArray(value)) {
                return `-${removeUnderscores(key).toUpperCase()}: ${value.join(',')}`;
              }
              return `-${removeUnderscores(key).toUpperCase()}: ${value}`;
            });

          return (
            <div
              key={index}
              className="mb-2 flex flex-col rounded-md bg-white px-1 py-2 hover:bg-primary-50">
              <p>
                <span className="font-600">
                  Procedure {procedureCode || proc?.procedure_code}:{' '}
                </span>
                {procedureCode ? 'was updated' : 'was added'}
              </p>
              {formattedFields.map((field, idx) => (
                <p key={idx} className="ml-4">
                  {field}
                </p>
              ))}
              <div className="flex justify-end gap-2">
                <Button
                  text="Cancel"
                  type="secondary"
                  color="danger"
                  size="small"
                  outlined
                  onClick={() =>
                    handleCancel({
                      id: change?.claim_id,
                      changeId: change?.id,
                      updatedField: proc,
                      kind: 'invoice',
                      key: 'procedures',
                      navigate,
                      queryClient,
                      breadcrumb
                    })
                  }
                />
                <Button
                  text="Accept"
                  type="secondary"
                  color="success"
                  size="small"
                  outlined
                  onClick={() =>
                    handleAccept({
                      id: change?.claim_id,
                      changeId: change?.id,
                      updatedField: proc,
                      kind: 'invoice',
                      key: 'procedures',
                      navigate,
                      queryClient,
                      breadcrumb
                    })
                  }
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default ProceduresFields;
