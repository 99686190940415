import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../api/InterimApi';

const useWebSocket = ({ handleMessage = () => {} }) => {
  const [ws, setWs] = useState(null);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  const generateWebSocketToken = async () => {
    try {
      const { data } = await interimApi('/api/user/get_socket_token', {}, navigate);
      const { token } = data;
      setToken(token);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    generateWebSocketToken();
  }, []);

  useEffect(() => {
    let apiUrl = process.env.ENV === 'local' ? 'localhost' : process.env.REACT_APP_BASE_API_URL;

    if (process.env.ENV !== 'local' && apiUrl.includes('://')) {
      apiUrl = apiUrl.split('://')[1];
    }
    const secureProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

    const webSocketApiUrl = `${secureProtocol}://${apiUrl}:9004/message?senderId=${token}`;
    const newWs = new WebSocket(webSocketApiUrl);

    setWs(newWs);

    return () => {
      newWs.close();
    };
  }, [token]);

  useEffect(() => {
    if (ws) {
      ws.addEventListener('open', () => {});

      ws.addEventListener('message', handleMessage);
    }
  }, [ws]);

  return { ws };
};

export default useWebSocket;
