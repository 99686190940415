import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createMedication, deleteMedication } from 'api/Medication';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CONFIRMATION_MODAL, MEDICATION_ACTION_TYPES, MEDICATION_STATUS } from '../lib/constants';

const Actions = ({ data, customProps }) => {
  const [confirmationModal, setConfirmationModal] = useState(CONFIRMATION_MODAL);

  const { id: patientId, appointmentId } = useParams();
  const navigate = useNavigate();

  const handleStatusLabel = data?.status === MEDICATION_STATUS.ACTIVE ? 'Inactive' : 'Active';

  const options = [
    {
      icon: 'status',
      color: data?.status === MEDICATION_STATUS.ACTIVE ? 'danger' : 'primary',
      stroke: true,
      label: `Mark ${handleStatusLabel}`,
      onClick: () =>
        setConfirmationModal({ open: true, type: MEDICATION_ACTION_TYPES.UPDATE_STATUS })
    },
    {
      icon: 'new-edit-pen',
      label: 'Edit',
      onClick: () => onHandleEdit()
    },
    {
      icon: 'trash',
      label: 'Delete',
      onClick: () => setConfirmationModal({ open: true, type: MEDICATION_ACTION_TYPES.DELETE })
    }
  ];

  const onHandleDelete = async () => {
    await mutateDeleteMedication.mutateAsync();
    setConfirmationModal(CONFIRMATION_MODAL);
  };

  const onHandleEdit = () => {
    customProps?.setShowMedicationModal({ open: true, data });
  };

  const onHandleUpdateStatus = async () => {
    await upsertFormFunctionMutation.mutateAsync({
      patientId,
      appointmentId,
      medication: {
        medications: [
          {
            ...data,
            status:
              data?.status === MEDICATION_STATUS.ACTIVE
                ? MEDICATION_STATUS.INACTIVE
                : MEDICATION_STATUS.ACTIVE
          }
        ]
      }
    });

    setConfirmationModal(CONFIRMATION_MODAL);
  };

  const queryClient = useQueryClient();
  const mutateDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMedication(navigate, {
        medicationId: data?.id
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }

      showAlert({
        title: `Medication deleted successfully`
      });

      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  const upsertFormFunctionMutation = useMutation((data) => createMedication(navigate, data), {
    onSettled: (data) => {
      const { code, error = null } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Medication',
          message: error ?? 'Medication creation failed! Please contact support.',
          color: 'danger'
        });

        return;
      }

      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    }
  });

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Popover
        options={options}
        optionIconColor="primary"
        position="left"
        isFixed
        icon="new-context-menu-dots"
      />

      {confirmationModal?.open && confirmationModal?.type === MEDICATION_ACTION_TYPES.DELETE && (
        <Confirm
          icon="new-info"
          iconColor="danger"
          iconSize={42}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Medication"
          message="Are you sure you want to delete this medication?"
          handleContinue={onHandleDelete}
          handleOpen={!!confirmationModal?.open}
          handleClose={() => setConfirmationModal(CONFIRMATION_MODAL)}
          disabled={mutateDeleteMedication?.isLoading}
          loading={mutateDeleteMedication?.isLoading}
        />
      )}

      {confirmationModal?.open &&
        confirmationModal?.type === MEDICATION_ACTION_TYPES.UPDATE_STATUS && (
          <Confirm
            icon="new-info"
            iconSize={42}
            variant="warning"
            primaryBtnTxt={`Mark ${handleStatusLabel}`}
            title={`Mark ${handleStatusLabel} Medication`}
            message={`Are you sure you want to mark this medication as ${handleStatusLabel}?`}
            handleContinue={onHandleUpdateStatus}
            handleOpen={!!confirmationModal?.open}
            handleClose={() => setConfirmationModal(CONFIRMATION_MODAL)}
            disabled={upsertFormFunctionMutation?.isLoading}
            loading={upsertFormFunctionMutation?.isLoading}
          />
        )}
    </div>
  );
};

export default Actions;
