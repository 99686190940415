import React, { useCallback, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useMacros } from 'lib/hooks/queries/macros/useMacros';
import { DEFAULT_COLUMN_DEFS, getColDefs, GRID_OPTIONS } from './configs';
import {
  useChatContextData,
  useChatContextApi
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import PreviewView from '../PreviewView';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import CreateMacroContent from './components/CreateMacroContent';
import EditMacrosContent from './components/EditMacroContent';
import { useBreadcrumbFunctions } from './hooks/useBreadcrumbFunctions';
import EmptyMacros from './components/EmptyMacros';

const MacrosTable = ({ breadcrumb }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider name="macros" cols={getColDefs(practiceTimezone)}>
      <Table breadcrumb={breadcrumb} />
    </TableContextProvider>
  );
};

function Table({ breadcrumb }) {
  const { steps, setSteps, step, setStep, push, goBack } = useBreadcrumbFunctions(breadcrumb);
  const { gridApi } = useTableContext();
  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows } = useChatContextApi();

  const selectedRowsData = selectedTableRows?.['macros']?.data;

  const { data = {} } = useMacros({ params: { type: ['text_macro'] } });

  const macros = data?.macros;

  useEffect(() => {
    setSteps([{ title: 'Select Text Macros', name: 'start' }]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableRows?.['macros']?.selectedNodes?.length > 0) {
      selectedTableRows?.['macros']?.selectedNodes.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedTableRows((prev) => ({
      ...prev,
      macros: {
        selectedNodes,
        data: selectedNodes.map((node) => node.data),
        status: !selectedNodes.length
          ? 'none'
          : selectedNodes?.length === macros?.length
            ? 'all'
            : 'partial'
      }
    }));
  }, [gridApi, macros?.length, setSelectedTableRows]);

  const onPreviewMacros = () => {
    push({
      title: 'Preview Macros',
      name: 'preview'
    });
  };

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  if (steps[step]?.name === 'create')
    return (
      <div className="mt-3">
        <CreateMacroContent goBack={goBack} />
      </div>
    );

  if (steps[step]?.name === 'edit')
    return (
      <EditMacrosContent
        goBack={goBack}
        step={step}
        setSteps={setSteps}
        selectedRowsData={selectedRowsData}
      />
    );

  if (steps[step]?.name === 'preview') {
    const onEditMacros = () => {
      setSteps((prev) => [
        ...prev.slice(0, step),
        {
          title: 'Edit Macros',
          name: 'edit'
        }
      ]);
    };
    return (
      <div className="flex justify-center pb-[112px]">
        <PreviewView
          isHtml
          previewData={selectedRowsData?.map((item) => ({ label: item?.name, value: item?.body }))}
        />
        <ModalFooter
          className="z-50"
          rightButtons={[
            <Button
              key={1}
              text="Edit"
              icon="save-changes"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!mr-2 !py-2 !pl-4 !pr-4"
              onClick={onEditMacros}
              outlined
            />,
            <Button
              key={1}
              text={`Add ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Macros`}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onAddComponent}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onGoBack}
            />
          }
        />
      </div>
    );
  }

  return (
    <div className="ag-theme-quartz macros_table !mb-0 h-full">
      <AGTable
        onSelectionChanged={onSelectionChanged}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={macros}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        noRowsOverlayComponent={EmptyMacros}
        noRowsOverlayComponentParams={{ breadcrumb }}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px] bg-white">
            <Skeleton count={24} />
          </div>
        )}
        reactiveCustomComponents
      />
      {macros?.length > 0 && (
        <ModalFooter
          rightButtons={
            <Button
              key={1}
              text={`Add ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Text Macro`}
              disabled={!selectedRowsData?.length}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onPreviewMacros}
            />
          }
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}

export default withErrorBoundary(MacrosTable);
