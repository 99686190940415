import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { useFormikContext } from 'formik';
import { ia } from 'lib/helpers/utility';
import { capitalize, isEmpty } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CNStarted = ({ data }) => {
  const { values } = useFormikContext();
  const navigate = useNavigate();

  const handleCN = () => navigate(`/portal/charts/${values?.user_id}/clinical-notes/${values?.id}`);

  const clinicalNoteStarted = [
    ...Object.values(data?.templates_filled?.soap || {}),
    ...Object.values(data?.templates_filled?.progress || {})
  ].some((val) => val === true);

  const isAptDisabled = values.appointment_disabled;

  if (isEmpty(data)) {
    return (
      <div
        className={`flex w-full items-center justify-end gap-1 ${
          !isAptDisabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
        }`}
        onClick={!isAptDisabled ? () => handleCN() : null}>
        <p className="text-sm  text-primary-600">Start Clinical Note</p>
        <Icon icon="new-start-note-v2" color="primary" size={14} />
      </div>
    );
  }

  return (
    <Allowed requiredPermissions="clinical_note.read">
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between">
          <p className="text-sm font-500 text-neutral-800">Clinical Note</p>
          <div
            className={`flex  items-center gap-2 ${
              !isAptDisabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
            }`}
            onClick={
              !isAptDisabled
                ? () => navigate(`/portal/charts/${values.user_id}/clinical-notes/${values.id}`)
                : null
            }>
            <p className="text-sm  text-primary-600">View</p>
            <Icon icon="new-document-preview" color="primary" />
          </div>
        </div>

        {/* {ENCOUNTER CODES} */}
        {!isEmpty(data.encounter_codes) && (
          <div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-500 text-neutral-800">Encounter Codes</p>
            </div>
            {Object.keys(data.encounter_codes).map((key, idx) => (
              <div className="ml-6" key={idx}>
                {ia(data.encounter_codes[key]) && (
                  <div key={idx} className=" w-full items-center justify-between">
                    <p className="text-sm  text-neutral-800">{key}</p>
                    {data.encounter_codes[key].map((code, index) => (
                      <p
                        key={index}
                        className="ml-6 max-w-full overflow-hidden text-ellipsis whitespace-nowrap  text-sm text-neutral-800">
                        {code}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}

        {/* {TEMPLATES FILLED} */}
        {clinicalNoteStarted && (
          <div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-500 text-neutral-800">Templates Filled</p>
            </div>
            {Object.keys(data.templates_filled).map((key, idx) => {
              const allFalse = Object.values(data.templates_filled[key]).every(
                (val) => val === false
              );
              if (!allFalse) {
                return (
                  <div className="ml-6" key={idx}>
                    <p className="text-sm  text-neutral-800">{capitalize(key)}</p>
                    {Object.keys(data.templates_filled[key]).map(
                      (template, index) =>
                        data.templates_filled[key][template] && (
                          <p
                            key={index}
                            className="ml-6 max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm  italic text-neutral-800">
                            {capitalize(template)}
                          </p>
                        )
                    )}
                  </div>
                );
              }
            })}
          </div>
        )}

        {/* {TREATMENT PLAN STARTED} */}
        {ia(data.treatment_plan_started) && (
          <div>
            <div className="flex items-center justify-between">
              <p className="text-sm font-500 text-neutral-800">Treatment Plan Started</p>
            </div>
            {data.treatment_plan_started.map((treatment, idx) => (
              <div className="ml-6 w-full" key={idx}>
                <div className="item-center flex w-full justify-between gap-2 ">
                  <p className="w-[100px] text-sm  text-neutral-800">ICD10 Code </p>
                  <p className="max-w-full overflow-hidden text-ellipsis whitespace-nowrap text-sm  italic text-neutral-800">
                    {treatment.icd_10_codes.join(', ')}
                  </p>
                </div>
                <div className="item-center flex w-full justify-between gap-2 ">
                  <p className="w-[100px] text-sm  text-neutral-800">Start Date</p>
                  <p className="w-[100px] text-sm  text-neutral-800">{treatment.startDate}</p>
                </div>
                <div className="item-center flex w-full justify-between gap-2 ">
                  <p className="w-[100px] text-sm  text-neutral-800">End Date</p>
                  <p className="w-[100px] text-sm  text-neutral-800">{treatment.endDate}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Allowed>
  );
};

export default CNStarted;
