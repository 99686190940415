import React, { useState } from 'react';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { ia } from 'lib/helpers/utility';
import NotFound from '../../components/NotFound';
import NewTask from 'components/practice/PracticeIQueue/Tasks/components/NewTask';
import { useWidgetTasks } from 'lib/hooks/queries/tasks/useTasksWidget';
import Task from './Task';
import './Tasks.scss';

const List = () => {
  const { data } = useWidgetTasks();
  const [showModal, setShowModal] = useState(false);
  const tasks = data?.tasks;

  if (!ia(tasks)) {
    return (
      <>
        <NotFound
          icon="new-illustration-4"
          title="You don't have any tasks"
          description="You can start creating new tasks right now."
          createText="Create new task"
          onCreate={() => setShowModal(true)}
        />
        {showModal && (
          <NewTask newTaskModal={showModal} hideNewTaskModal={() => setShowModal(false)} />
        )}
      </>
    );
  }
  return (
    <ErrorBoundary>
      <div className="tasks  grid h-auto grid-cols-1 gap-6 overflow-y-scroll px-[16px] pt-[16px]">
        {tasks?.map((task) => (
          <Task key={task?.id} task={task} />
        ))}
      </div>
    </ErrorBoundary>
  );
};

export default List;
