import cs from 'classnames';

import Icon from '../Icon/Icon';

import './lib/Checkbox.scss';

/**
 * @param {import('./lib/propTypes').CheckboxProps} props
 */

const Checkbox = ({
  id,
  label,
  labelTextColor,
  className,
  inputClassName,
  labelClassName,
  parentLabelClassName,
  innerLabelClassName,
  dropDownClassName,
  onChange,
  handleClick, // recommended to use onChange instead of handleClick
  isChecked = false,
  isPartial,
  isDropdown,
  rounded,
  children,
  expanded,
  setExpanded,
  forwardedRef,
  disabled,
  color,
  radio = false,
  size = 24, // The dynamic size prop with a default value
  icon = null,
  iconColor = null,
  iconClassName = null,
  ...rest
}) => {
  return (
    <div
      className={cs(
        'Checkbox',
        className,
        isChecked && 'Checkbox--checked',
        children && 'Checkbox--hasChildren',
        expanded && 'Checkbox--expanded',
        rounded && 'Checkbox--rounded'
      )}
      style={{ '--checkbox-size': `${size}px` }}>
      {isDropdown ? (
        <div className={cs('Checkbox__isDropdown', dropDownClassName)}>
          <div
            onClick={setExpanded}
            className={cs(
              inputClassName,
              'flex w-full cursor-pointer items-center px-4 py-[11px]',
              disabled ? '!cursor-not-allowed' : 'cursor-pointer'
            )}>
            <div className="flex items-center gap-3">
              {icon && (
                <Icon
                  icon={icon}
                  color={iconColor}
                  className={cs(disabled && 'cursor-not-allowed', iconClassName)}
                />
              )}

              <input
                style={{ width: size, height: size }} // Apply dynamic size here
                className={cs(
                  inputClassName,
                  isChecked && isPartial ? 'isPartial' : '',
                  disabled ? '!cursor-not-allowed' : 'cursor-pointer',
                  inputClassName
                )}
                data-qa={`checkbox-${id}`}
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={onChange || handleClick}
                ref={forwardedRef}
                value={isChecked}
                disabled={disabled}
                {...rest}
              />
              <span>{label}</span>
            </div>
            <Icon
              icon="new-arrow-down"
              className={cs(
                'arrow-down',
                expanded && 'rotate-180',
                disabled ? 'cursor-not-allowed' : 'cursor-pointer'
              )}
            />
          </div>
          {children && <div className="Checkbox__children">{children}</div>}
        </div>
      ) : (
        <label htmlFor={id} className={labelClassName}>
          <div className={cs('flex w-full items-center', parentLabelClassName)}>
            <div className={cs('flex items-center gap-3', innerLabelClassName)}>
              {icon && (
                <Icon
                  icon={icon}
                  color={iconColor}
                  className={cs(disabled && 'cursor-not-allowed', iconClassName)}
                />
              )}

              <input
                style={{ width: size, height: size }} // Apply dynamic size here
                className={cs(
                  color === 'blue' && 'Checkbox--blue',
                  radio && 'Checkbox--radio',
                  disabled ? '!cursor-not-allowed' : 'cursor-pointer',
                  inputClassName,
                  isChecked && isPartial ? 'isPartial' : ''
                )}
                data-qa={`checkbox-${id}`}
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={onChange || handleClick}
                ref={forwardedRef}
                value={isChecked}
                disabled={disabled}
                {...rest}
              />
              <span className={cs('w-fit', color === 'blue' && 'text-primary-900', labelTextColor)}>
                {label}
              </span>
            </div>
          </div>
          {children && <div className="Checkbox__children">{children}</div>}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
