import React, { useCallback, useEffect, useState } from 'react';
import JsonView from 'react18-json-view';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { defaultsDeep } from 'lodash';
import { useRecoilValue } from 'recoil';

import { iaRa, optionify, pm } from 'lib/helpers/utility';
import { useAiModel } from 'lib/hooks/queries/admin/intelligence/useAiModel';

import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Allowed from 'components/shared/Permissions/Allowed';
import Select from 'components/shared/Select/Select';
import state from 'components/state';

import { getHandlebarValues } from '../../../../../api/Admin/intelligence/prompts';

import { promptOptionsDefaults, promptOptionsExplainer } from './helpers';

const AdminIntelligencePromptForm = ({ onChange, formik, value }) => {
  const permissions = useRecoilValue(state.permissions);
  const [searchParams] = useSearchParams();
  const [handlebarEntries, setHandlebarEntries] = useState([]);
  const {
    _isLoading,
    _isFetching,
    data: modelData
  } = useAiModel({
    params: {
      limit: 100,
      page: 1,
      sort: 'DESC'
    },
    options: {},
    dependencies: [],
    // dependencies: [limit, page],
    name: 'ai_models'
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('promptId')) {
      getHandlebars();
    }
  }, []);

  const getHandlebars = async () => {
    try {
      const { data: handlebarValuesStr } = await getHandlebarValues(navigate, {
        promptId: searchParams.get('promptId'),
        version: searchParams.get('version')
      });
      if (!handlebarValuesStr?.length) {
        return;
      }

      const finalHandlebarValues = handlebarValuesStr.split('|').map((pair) => {
        const [name, value] = pair.split(':').map((str) => str.trim());
        return { name, value };
      });
      setHandlebarEntries(finalHandlebarValues);
    } catch (error) {
      console.error('Error getting handlebar values');
    }
  };

  const addTag = useCallback(
    (e) => {
      onChange({ name: 'body', value: `${formik?.values?.body} ${e}` });
    },
    [formik, value]
  );

  return (
    <div className="flex w-full flex-col gap-4 p-4">
      <div className="font-600 text-primary-600">Ai Model</div>
      <Allowed requiredPermissions="prompts.create">
        <Select
          label="Ai Model"
          isAsyn={true}
          required={true}
          options={optionify(modelData?.models?.results)}
          onChange={(e) => onChange({ name: 'ai_model_id', value: e })}
          placeholder="Search Models"
          value={formik?.values?.ai_model_id}
        />
      </Allowed>
      <div className="font-600 text-primary-600">Prompt Body</div>
      <div className="flex flex-col gap-2">
        <div className="font-500">Add Tags to Prompt Body</div>
        <Allowed requiredPermissions="prompts.create">
          <div className="flex flex-row flex-wrap gap-2">
            {iaRa(handlebarEntries)?.map(({ name, value }) => (
              <Button key={name} text={name} onClick={() => addTag(`{{${value}}}`)} />
            ))}
          </div>
        </Allowed>
      </div>
      <textarea
        className="h-full min-h-40 min-w-40 resize-y rounded-l border-neutral-200 p-2"
        // label="Prompt Template Text"
        placeholder="Please write a nice note"
        id="body"
        name="body"
        onChange={(e) => {
          onChange({ name: 'body', value: e.target.value });
        }}
        defaultValue={value?.body}
        value={formik?.values?.body}
        disabled={!pm(permissions, 'prompts.create')}
      />
      <div className="font-600 text-primary-600">Model Parameters</div>
      <div className="font-500">Parameters</div>
      <JsonView
        name="options"
        editable={pm(permissions, 'prompts.create')}
        onChange={(v) => {
          onChange({ name: 'options', value: v?.src });
        }}
        className="json-tree-view rounded-l border-[1px] border-solid border-neutral-200 bg-white p-2"
        src={defaultsDeep(formik?.values?.options, promptOptionsDefaults)}
        collapsed={1}
      />
      <div className="font-500">Parameters Explanation</div>
      <JsonView
        className="json-tree-view max-w-[50vw] rounded-l border-[1px] border-solid border-neutral-200 bg-white p-2"
        src={promptOptionsExplainer}
        editable={false}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligencePromptForm);
