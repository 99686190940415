import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Progress from './Progress';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const ProgressSection = ({ components, sectionRef, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{ name: 'Progress', parentKey: 'progress', childKey: 'progress' }}
      title={components?.progress?.title}
      id={components?.progress?.id}
      disabled={clinicalNote?.locked}>
      <Progress sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ProgressSection;
