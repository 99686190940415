import React, { useEffect } from 'react';
import { patient } from '../../../shared/ePrescribe/SSOKinds';
import SSOAction from '../../../shared/ePrescribe/SSOAction';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import practiceState from 'components/practice/practiceState';
import state from 'components/state';
import { socket } from 'api/Socket';

export default function Actions() {
  const { id } = useParams();
  const userState = useRecoilValue(state.userState);
  const practice = useRecoilValue(practiceState.currentPractice);

  const handleStartVeradigmSessionSocket = () => {
    const data = {
      patientId: id,
      user_id: userState.id,
      practice_id: practice.id
    };
    socket.emit('veradigm-prescribe', { ...data });
  };

  useEffect(() => {
    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  return (
    <div className="!p-8 scrollbar overflow-auto">
      <h3 className="text-lg font-500 text-neutral-800">ePrescribe</h3>
      <SSOAction
        enableEnrollPatient
        patientId={id}
        kind={patient}
        handleStartVeradigmSessionSocket={handleStartVeradigmSessionSocket}
      />
      <div>Powered by Veradigm </div>
    </div>
  );
}
