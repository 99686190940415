import React from 'react';
import { useNavigate } from 'react-router-dom';

import { createContact, updateContact } from 'api/Contacts';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import BaseModal from 'components/shared/Modal/Modal';

import useContactFormik from '../lib/useContactFormik';

import ContactForm from './Form';

const Modal = ({ item: contact, isOpen, handleClose }) => {
  const navigate = useNavigate();

  const handleFormikSubmit = async (values) => {
    const valuesCopy = {
      ...values,
      fax_number: values.fax_number.replace(/\D/g, '')
    };
    if (!!contact) {
      await updateContactMutation({ ...valuesCopy, id: contact?.id });
    } else {
      await createContactMutation(valuesCopy);
    }
  };
  const contactFormik = useContactFormik(contact, handleFormikSubmit);

  const { mutateAsync: createContactMutation, isLoading: createLoading } = useCustomMutation({
    mutationFn: (data) => createContact(navigate, data),
    invalidateQueryKey: 'contacts',
    successMessage: 'Contact Created Successfully',
    onSuccess: () => {
      contactFormik.resetForm();
      handleClose();
    }
  });

  const { mutateAsync: updateContactMutation, isLoading: updateLoading } = useCustomMutation({
    mutationFn: (data) => updateContact(navigate, data),
    invalidateQueryKey: 'contacts',
    successMessage: 'Successfully updated contact',
    onSuccess: () => {
      contactFormik.resetForm();
      handleClose();
    }
  });

  const isLoading = createLoading || updateLoading;

  return (
    <BaseModal
      customStyling={{ width: '700px' }}
      handleOpen={isOpen}
      bodyClassName="!p-4 !h-[calc(100vh-115px)]"
      handleClose={handleClose}
      title={`${contact ? 'Update' : 'New'} contact`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={handleClose}
            id="closeContactModalBtn"
          />
          <Button
            outlined
            disabled={isLoading || !contactFormik.isValid}
            loading={isLoading}
            text={contact ? 'Update' : 'Create contact'}
            onClick={contactFormik.handleSubmit}
            color="primary"
            id="createUpdateContactModalBtn"
          />
        </div>
      }>
      <ContactForm formik={contactFormik} />
    </BaseModal>
  );
};

export default Modal;
