import React, { useEffect, useMemo } from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';

import { NK_PREFIXES } from '../lib/helpers';

import './FormsTable.scss';

const FullWidthCellRenderer = (params) => {
  if (params.data?.isNKMWarning) {
    return (
      <div className="flex items-center gap-2 p-3 bg-warning-50 border-solid  border-neutral-100 w-full">
        <Icon icon="new-help" color="warning" />
        <span className="text-warning-600">{NK_PREFIXES[params?.data?.title]}</span>
      </div>
    );
  }
  return null;
};

const FormsTable = ({ items, getGridApi = () => null, showNKWarning = false, title = '' }) => {
  const { gridApi } = useTableContext();

  useEffect(() => {
    if (gridApi) getGridApi(gridApi);
  }, [gridApi]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const tableData = useMemo(() => {
    if (showNKWarning) {
      return [
        {
          title,
          isFullWidth: true,
          isNKMWarning: true
        }
      ];
    }
    return items;
  }, [items, showNKWarning, title]);

  const isFullWidthRow = (params) => {
    return params?.rowNode?.data?.isFullWidth;
  };

  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={tableData}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            isFullWidthRow={isFullWidthRow}
            fullWidthCellRenderer={FullWidthCellRenderer}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default FormsTable;
