import { Honeybadger } from '@honeybadger-io/react';
import { isUndefined } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import {
  checkinSteps,
  clinicalNoteSections,
  defaultPracticeSettings,
  selfScheduling
} from 'constants';
import { snakeToTitleCase, pm } from 'lib/helpers/utility';
import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import Switch from 'components/shared/Switch/Switch';
import { showAlert } from 'components/shared/Alert/Alert';
import CheckinStepsModal from './CheckinSteps/CheckinStepsModal';
import SelfSchedulingModal from './SelfScheduling/SelfSchedulingModal';
import Allowed from 'components/shared/Permissions/Allowed';
import state from 'components/state';
import { useRecoilValue } from 'recoil';

const Box = ({ children }) => {
  return (
    <div className="flex flex-col gap-2 rounded-md border border-solid border-neutral-200 px-4 py-3">
      {children}
    </div>
  );
};

export default function AdminSettings() {
  const { practice } = useOutletContext();
  const [showModal, setShowModal] = useState(false);
  const [showCheckinStepsModal, setShowCheckinStepsModal] = useState(false);
  const [showSelfSchedulingModal, setShowSelfSchedulingModal] = useState(false);
  const permissions = useRecoilValue(state.permissions);
  const updateDisabled = !pm(permissions, 'practice.update');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const hideSelfSchedulingModal = () => {
    setShowSelfSchedulingModal(false);
  };

  const hideCheckinStepsModal = () => {
    setShowCheckinStepsModal(false);
  };

  const handleChange = async (index, val) => {
    try {
      let res;
      let display_settings = practice?.display_settings;

      let practice_id = practice.id;
      let fields = {};

      if (['surcharge', 'care_credit', 'emails_enabled', 'texts_enabled'].includes(index?.name)) {
        fields = { [index?.name]: index?.state };
      } else if (index === 'checkin_steps') {
        fields = { checkin_steps: val };
      } else if (index === 'self_scheduling') {
        fields = { self_scheduling: val };
      } else {
        display_settings[index] = val;
        fields = { display_settings };
      }

      res = await requestApi({
        url: '/api/practice/update',
        params: {
          practice_id,
          fields
        },
        navigate
      });
      if (res.code === 0) {
        showAlert({ title: 'Settings was updated successfully!', color: 'success' });
        queryClient.invalidateQueries(['practice']);
      } else {
        showAlert({ title: 'There was an error updating settings', color: 'danger' });
        Honeybadger.notify(`handleChange admin settings`);
      }
    } catch (err) {
      Honeybadger.notify(`handleChange admin settings: ${err}`);
    }
  };
  return (
    <div className="flex h-full flex-col overflow-hidden !px-4 !pb-4">
      <Allowed requiredPermissions="practice.update">
        <div className="ml-auto flex flex-wrap gap-3 !py-3 xs:grid xs:w-full">
          <Button primary onClick={() => setShowModal(true)}>
            Edit clinical notes settings
          </Button>
          <Button
            primary
            onClick={() => setShowCheckinStepsModal(true)}
            data-qa="edit-checkin-steps-btn">
            Edit check-in steps
          </Button>
          <Button primary onClick={() => setShowSelfSchedulingModal(true)}>
            Edit self scheduling steps
          </Button>
        </div>
      </Allowed>
      <div className="overflow-auto !pr-1">
        <p className="pb-1 text-lg font-600 text-neutral-700">Forms</p>
        <div className="grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 xs:grid-cols-1">
          {practice?.display_settings &&
            Object.keys({ ...defaultPracticeSettings, ...practice?.display_settings }).map(
              (v, key) => {
                if (
                  typeof practice?.display_settings[v] === 'boolean' ||
                  ['products', 'packages'].includes(v)
                ) {
                  return (
                    <Box key={key}>
                      <div>{snakeToTitleCase(v)}</div>
                      <Switch
                        onChange={() =>
                          handleChange(
                            v,
                            isUndefined(practice?.display_settings[v])
                              ? !defaultPracticeSettings[v]
                              : !practice?.display_settings[v]
                          )
                        }
                        checked={
                          isUndefined(practice?.display_settings[v])
                            ? defaultPracticeSettings[v]
                            : practice?.display_settings[v]
                        }
                        disabled={updateDisabled}
                      />
                    </Box>
                  );
                }
              }
            )}
          <Box>
            <div>Technology fee</div>
            <div>
              <Switch
                onChange={(e) => handleChange({ name: 'surcharge', state: e })}
                checked={practice?.surcharge}
                disabled={updateDisabled}
              />
            </div>
          </Box>
          <Box>
            <div>Outside Financing</div>
            <div>
              <Switch
                onChange={(e) => handleChange({ name: 'care_credit', state: e })}
                checked={practice?.care_credit}
                disabled={updateDisabled}
              />
            </div>
          </Box>
        </div>
        <div className="!pt-4">
          <p className="pb-1 text-lg font-600 text-neutral-700">Patient Communication</p>
          <div className="grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 xs:grid-cols-1">
            <Box>
              <div>Emails Enabled</div>
              <div>
                <Switch
                  onChange={(e) => handleChange({ name: 'emails_enabled', state: e })}
                  checked={practice?.emails_enabled}
                  disabled={updateDisabled}
                />
              </div>
            </Box>
            <Box>
              <div>Texts Enabled</div>
              <div>
                <Switch
                  onChange={(e) => handleChange({ name: 'texts_enabled', state: e })}
                  checked={practice?.texts_enabled}
                  disabled={updateDisabled}
                />
              </div>
            </Box>
          </div>
        </div>
      </div>
      <Modal
        slideFromRight
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        title="Clinical notes settings"
        className="max-w-[1100px]"
        footer={
          <>
            <Button primary onClick={() => setShowModal(false)}>
              Close
            </Button>
          </>
        }>
        <div className="grid grid-cols-2 gap-3">
          {Object.keys({
            ...clinicalNoteSections,
            ...practice?.display_settings?.clinical_notes
          }).map((key) => {
            return (
              <Box key={key}>
                <div>{snakeToTitleCase(key)}</div>
                <Switch
                  onChange={() =>
                    handleChange('clinical_notes', {
                      ...practice?.display_settings?.clinical_notes,
                      [key]: !practice?.display_settings?.clinical_notes[key]
                    })
                  }
                  checked={
                    practice?.display_settings?.clinical_notes
                      ? practice?.display_settings?.clinical_notes[key]
                      : true
                  }
                  disabled={updateDisabled}
                />
              </Box>
            );
          })}
        </div>
      </Modal>
      <CheckinStepsModal
        showModal={showCheckinStepsModal}
        hideModal={hideCheckinStepsModal}
        checkinSteps={{ ...checkinSteps, ...practice?.checkin_steps }}
        handleChange={handleChange}
      />
      <SelfSchedulingModal
        showModal={showSelfSchedulingModal}
        hideModal={hideSelfSchedulingModal}
        selfScheduling={{ ...selfScheduling, ...practice?.self_scheduling } || {}}
        handleChange={handleChange}
      />
    </div>
  );
}
