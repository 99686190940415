import { useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { clinicalNote as clinicalNoteState } from 'components/state';

import Accordion from '../../../../shared/Accordion';

import Objective from './Objective';

const ObjectiveSection = ({
  components,
  sectionRef,
  options,
  withStyle = true,
  showDisclaimer = true
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const objectiveRef = useRef(null);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.objective?.title}
      id={components?.objective?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Objective
        objectiveRef={objectiveRef}
        sectionRef={sectionRef}
        showDisclaimer={showDisclaimer}
      />
    </Accordion>
  );
};

export default ObjectiveSection;
