import paymentPattern from 'assets/Patterns/payment_pattern.svg';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import arrow from './icons/arrow.svg';
import insertCard from './icons/insert_card.svg';
import './index.scss';

import { ia } from 'lib/helpers/utility';

import CardBox from 'components/Payments/components/CardBox';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PayCard = ({
  cards,
  setCards,
  payWithAlternative,
  onCancel,
  show,
  setShow = () => {},
  setSelectedCard = () => {}
}) => {
  return (
    <div className="flex h-[100vh] w-full flex-col items-center !p-20">
      <div className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 h-full w-full">
        <img src={paymentPattern} className="h-full w-full"></img>
      </div>

      <div className="absolute left-[60%] top-[8%] sm:hidden">
        <img className="arrow-animation" src={arrow}></img>
      </div>
      <img alt="Insert Card" className="w-64" src={insertCard}></img>
      <p className="mt-3 text-center text-2xl font-medium text-primary-600">
        Tap or insert your card
      </p>

      {ia(cards) && (
        <div className="!mb-5 !mt-5 flex w-full flex-col items-center">
          <p className="text-sm font-400 text-neutral-500">Saved Cards</p>

          <div className="!mt-3 flex w-full flex-wrap justify-center !gap-4  xl:!px-16">
            {cards.map((c, i) => {
              return (
                <CardBox
                  setShow={setShow}
                  show={show}
                  key={i}
                  card={c}
                  setSelectedCard={setSelectedCard}
                  cards={cards}
                  setCards={setCards}
                />
              );
            })}
          </div>
        </div>
      )}

      <div className="flex flex-col items-center">
        <p className="!mt-6 text-neutral-600">Or enter card information manually.</p>
        <div
          className="!mt-2 flex cursor-pointer items-center gap-1"
          onClick={payWithAlternative && payWithAlternative}>
          <Icon icon="credit-card-income" color="primary" stroke />
          <p className="ml-2 text-base text-primary-600">Enter card manually</p>
        </div>
      </div>

      <div className="flex h-full flex-1 items-end">
        <div className="mb-2 flex cursor-pointer gap-1" onClick={() => onCancel && onCancel()}>
          <Icon icon="new-close-sm" className="!cursor-pointer" color="neutral" stroke />
          <p className="ml-2 text-base text-neutral-600">Close</p>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(PayCard);
