import { Popover } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import React, { useEffect } from 'react';
import { ia, removeSpecialChar } from '../../../lib/helpers/utility';
import Icon from '../Icon/Icon';
import InputOptions from '../InputOptions/InputOptions';
import Transcribe from '../Transcribe/Transcribe';

const Input = ({
  label,
  labelClassName,
  options,
  currentUnit,
  units,
  unitPanelClassName,
  onUnitChange,
  customIcon,
  transcribing,
  tooltip,
  icon,
  iconClick = () => {},
  placeholder,
  value,
  rightText,
  rightTextClassName,
  rightIcon,
  rightIconClick = () => {},
  rightSecondaryIcon,
  name = label || 'inputName',
  id = name || label || 'inputId',
  type = 'text',
  className,
  inputClassName,
  width,
  rounded,
  required = false,
  disabled = false,
  setDisabled = () => {},
  error,
  hint,
  formik, // use this when focusNextFieldOnEnter is true
  focusNextFieldOnEnter = false,
  hideNumberArrows,
  forwardedRef,
  onChange = () => {},
  submitFormOnEnter,
  autoComplete = 'off',
  inputWrapperClassName,
  focusOnMount = false,
  noSpecialCharacters = false,
  ...rest
}) => {
  useEffect(() => {
    focusOnMount && forwardedRef?.current && forwardedRef?.current?.focus();
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !submitFormOnEnter) {
      event.preventDefault();
    }

    if (event.key === 'Enter' && focusNextFieldOnEnter && formik) {
      const fields = Object.keys(formik.values);
      const index = fields.indexOf(name);
      if (index >= 0) {
        const nextFieldIndex = index + 1;

        if (nextFieldIndex < fields.length) {
          const nextFieldName = fields[nextFieldIndex];
          // formik.setFieldTouched(name, true);
          // formik.setFieldValue(name, event.target.value);
          document.querySelector(`input[name="${nextFieldName}"]`)?.focus();
        } else {
          formik.submitForm();
        }
      }
    }
  };

  const roundedCss = rounded ? `rounded-${rounded}` : 'rounded-md';
  const fromCheckin = location?.pathname.includes('/checkin');

  return (
    <div className={cs(className)} style={{ width }}>
      {(label || tooltip || (transcribing && !fromCheckin) || customIcon || options) && (
        <div className="flex min-h-[28px] items-center justify-between !pb-1">
          <div className="flex items-center gap-x-2">
            {label && (
              <label
                className={cs('m-0 flex text-sm font-500  text-neutral-800', labelClassName)}
                htmlFor={id}>
                {label}
                {required && <span className="text-danger-500">*</span>}
              </label>
            )}
            {tooltip && (
              <Tippy content={<span>{tooltip}</span>} arrow>
                <div className="flex">
                  <Icon icon="info" />
                </div>
              </Tippy>
            )}
          </div>
          <div className="ml-auto flex items-center gap-x-1">
            {transcribing && !fromCheckin && (
              <Transcribe
                value={value}
                setValue={(text) =>
                  onChange({
                    target: {
                      value: text
                    }
                  })
                }
                setDisable={setDisabled}
                disabled={disabled}
              />
            )}
            {customIcon}
            {ia(options) && (
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={cs(
                        'custom-btn flex !h-5 !w-5 cursor-pointer items-center justify-center rounded-[3px] bg-transparent p-0 hover:!bg-neutral-100',
                        open && '!bg-primary-700 hover:!bg-primary-700'
                      )}>
                      <Icon icon="new-candle" className="cursor-pointer" color={open && 'white'} />
                    </Popover.Button>
                    <Popover.Panel className="absolute right-0 z-10 mt-[6px] w-max rounded-[4px] bg-white shadow-medium">
                      <div className="grid">
                        {options.map((option, idx) => (
                          <div
                            className="even:border-0 even:!border-b-[1px] even:!border-t-[1px] even:border-solid even:border-neutral-100"
                            key={idx}>
                            <div className="!px-2 py-[6px]" onClick={option.onClick}>
                              <div className="grid cursor-pointer items-center rounded-[4px] !px-2 py-[6px] hover:bg-primary-50">
                                <span className="text-xs font-500 text-primary-900">
                                  {option.title}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </Popover.Panel>
                  </>
                )}
              </Popover>
            )}
          </div>
        </div>
      )}
      <label
        className={cs(
          '!m-0 flex h-10 items-center justify-between gap-x-3 border border-solid bg-white !px-3 py-[10px] focus-within:outline focus-within:!outline-2',
          error
            ? '!border-danger-300 focus-within:!outline-danger-100'
            : '!border-neutral-200 focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
          roundedCss,
          disabled && '!bg-neutral-50',
          inputWrapperClassName
        )}
        htmlFor={id}>
        <div className="flex grow items-center justify-between gap-x-2">
          {icon && <Icon htmlFor={id} icon={icon} onClick={iconClick} />}
          <input
            className={cs(
              'w-full overflow-auto text-ellipsis border-none bg-transparent p-0 text-sm leading-5 text-neutral-900 outline-none placeholder:text-neutral-500',
              error ? 'caret-danger-500' : 'caret-primary-500',
              disabled && 'cursor-not-allowed !text-neutral-500',
              hideNumberArrows &&
                '[appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none',
              inputClassName
            )}
            value={value}
            onChange={onChange}
            onKeyPress={handleKeyPress}
            placeholder={placeholder}
            id={id}
            data-qa={id}
            name={name}
            type={type}
            disabled={disabled}
            ref={forwardedRef}
            autoComplete={autoComplete}
            onKeyDown={noSpecialCharacters ? (e) => removeSpecialChar(e) : (e) => e}
            {...rest}
          />

          {ia(units) && (
            <InputOptions
              options={units}
              currentOption={currentUnit}
              onClickOption={onUnitChange}
              panelClassName={unitPanelClassName}
            />
          )}
        </div>

        {(rightText || rightIcon || rightSecondaryIcon) && (
          <div className="flex shrink-0 items-center gap-x-1">
            {rightText && (
              <p className={cs('mb-0 text-sm text-neutral-600', rightTextClassName)}>{rightText}</p>
            )}
            {rightSecondaryIcon && <Icon icon={rightSecondaryIcon} className="m-0" />}
            {rightIcon && <Icon icon={rightIcon} onClick={rightIconClick} className="m-0" />}
          </div>
        )}
      </label>

      {hint && !error && <p className={cs('!pt-2 text-sm text-neutral-600')}>{hint}</p>}
      {error && <p className={cs('!pt-2 text-left text-sm text-danger-500')}>{error}</p>}
    </div>
  );
};

export default Input;
