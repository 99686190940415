import React, { forwardRef } from 'react';
import { useRecoilValue } from 'recoil';
import Loading from '../Loading/Loading';
import { ia } from '../../../lib/helpers/utility';
import { currentPractice } from '../../practice/practiceState';
import ReceiptTotal from './components/Total';
import ReceiptItems from './components/Items';
import ReceiptHeader from './components/Header';
import ReceiptFooter from './components/Footer';
import ReceiptTransactions from './components/Transactions';
import ReceiptUpcomingAppointments from './components/UpcomingAppointments';
import ReceiptPatientTransactionDetails from './components/PatientTransactionDetails';

const Receipt = forwardRef(({ receipt, walkout = false, parentRef }) => {
  const patient = receipt?.patient;
  const transaction = receipt?.transaction;
  const transactions = receipt?.transactions;
  const invoice = receipt?.invoice;

  const practice = useRecoilValue(currentPractice);

  if (!patient || !practice) return <Loading />;

  const { timezone } = practice || {};
  return (
    <>
      <div
        ref={parentRef}
        className="-m-4 flex min-h-[-webkit-fill-available;] flex-col !gap-4 overflow-auto bg-white !p-10">
        <ReceiptHeader
          patient={patient}
          practice={practice}
          timezone={timezone}
          transaction={transaction}
        />

        <ReceiptPatientTransactionDetails
          patient={patient}
          timezone={timezone}
          transaction={transaction}
          showDetails={!walkout}
        />

        <ReceiptItems
          walkout={walkout}
          timezone={timezone}
          transactions={
            walkout
              ? [{ ...transaction, invoice }]
              : ia(transactions)
                ? transactions
                : [transaction]
          }
        />

        <ReceiptTotal
          showDetails={!walkout}
          transaction={transaction}
          transactions={ia(transactions) ? transactions : [transaction]}
        />

        {walkout && <ReceiptTransactions transactions={transactions} timezone={timezone} />}

        <ReceiptUpcomingAppointments
          timezone={timezone}
          patientId={transaction?.patient_id || patient?.id}
        />

        <ReceiptFooter practice={practice} />
      </div>
    </>
  );
});

export default Receipt;
