export const tagColorOptions = [
  // Neutral colors
  { color: '#ECEFF0', border: '#DADFE2' }, // neutral-100, neutral-200
  { color: '#CDD5D8', border: '#C1CACE' }, // neutral-300, neutral-400

  // Primary colors
  { color: '#B0EAFF', border: '#8AE0FF' }, // primary-100, primary-200
  { color: '#54D2FF', border: '#33C9FF' }, // primary-300, primary-400

  // Secondary colors
  { color: '#E0E3F9', border: '#CBD1F5' }, // secondary-100, secondary-200
  { color: '#B5BFF2', border: '#A1ACEE' }, // secondary-300, secondary-400

  // Success colors
  { color: '#DCFCE7', border: '#BBF7D0' }, // success-100, success-200
  { color: '#86EFAC', border: '#4ADE80' }, // success-300, success-400

  // Warning colors
  { color: '#FEF3C7', border: '#FDE68A' }, // warning-100, warning-200
  { color: '#FCD34D', border: '#FBBF24' }, // warning-300, warning-400

  // Danger colors
  { color: '#FEE2E2', border: '#FECACA' }, // danger-100, danger-200
  { color: '#FCA5A5', border: '#F87171' } // danger-300, danger-400
];
