import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

import Tabs from 'components/shared/Tabs/NewTabs';
import Modal from 'components/shared/Modal/Modal';
import NotFound from '../NewAppointment/components/NotFound';
import { Capitalize, formatDate, ia } from 'lib/helpers/utility';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { currentPractice } from 'components/practice/practiceState';
import EligibilityPreview from 'components/practice/Eligibility/BenefitPreview/EligibilityPreview';
import { useBillingRenderingProviders } from 'lib/hooks/queries/billing/useBillingRenderingProviders';
import { runEligibility } from 'components/practice/appointment/PreviewAppointment/lib/updateStateHelpers';

const EligibilityWrapper = ({ patient, onSuccess = () => {}, insProfiles, formik }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [practice, setPractice] = useRecoilState(currentPractice);

  const [tabIndex, setTabIndex] = useState(0);
  const [runningEligibility, setRunningEligibility] = useState(false);
  const [showEligibilityResponse, setShowEligibilityResponse] = useState(false);

  const closeEligibilityModal = () => setShowEligibilityResponse(false);

  const insuranceBenefits = patient?.insurance_benefits || {};
  const insuranceProfiles = patient?.insuranceProfile || insProfiles;
  const eligibilityPresent = insuranceBenefits?.primary?.retrieved || false;

  const { data: { providers } = {} } = useBillingRenderingProviders();

  const handleRunEligibility = (params) => {
    runEligibility({
      formik, // for old appt preview
      params,
      navigate,
      onSuccess,
      queryClient,
      setPractice,
      setRunningEligibility,
      patientId: patient?.id
    });
  };

  const tabsData = [];

  for (let type in insuranceBenefits) {
    const insurance = insuranceBenefits[type];

    if (['primary', 'secondary', 'tertiary'].includes(type)) {
      tabsData.push({
        label: Capitalize(type),
        content: (
          <EligibilityPreview
            eligibility={insurance.benefits}
            closeModal={closeEligibilityModal}
            insuranceProfilesList={insuranceProfiles}
          />
        )
      });
    }
  }

  const renderProviderInfo = (providerType, insProfileId) => {
    // Check if there's more than one provider
    const moreThanOne = ia(providers?.[providerType], 1);

    const providerInfo = providers?.[providerType]?.map?.((provider, idx) => {
      const isBillingProvider = providerType === 'billing';
      const npi = provider?.bill_npi || provider?.billingInfo?.npi;

      const nameLabel = (
        <div className="flex gap-1">
          <span className="text-neutral-500">Name:</span>
          <span>{provider?.bill_name || provider?.fullName}</span>
        </div>
      );

      const npiLabel = (
        <div className="flex gap-1">
          <span className="text-neutral-500">NPI:</span>
          <span>{npi}</span>
        </div>
      );

      return {
        className: '!items-start',
        onClick: () => {
          handleRunEligibility({
            insuranceProfileId: insProfileId,
            provider: { id: provider?.id, type: providerType, npi }
          });
        },
        label: (
          <div className="flex flex-col justify-between gap-x-2 gap-y-1">
            {!moreThanOne && (
              <span className="text-primary-900">
                {isBillingProvider ? 'Billing Provider' : 'Rendering Provider'}
              </span>
            )}
            {nameLabel}
            {npiLabel}
          </div>
        ),
        icon: (
          <div className="mt-[2px] flex size-4 place-content-center rounded-full border border-solid border-neutral-200 bg-neutral-50">
            <span className="text-xxs font-500 leading-[14px] text-neutral-600">{idx + 1}</span>
          </div>
        )
      };
    });

    if (moreThanOne) {
      return [
        {
          position: 'right',
          children: providerInfo,
          icon: 'new-first-line',
          label: providerType === 'billing' ? 'Billing Provider' : 'Rendering Provider'
        }
      ];
    } else {
      return providerInfo;
    }
  };

  const billingProvider = (insProfileId) => renderProviderInfo('billing', insProfileId) || [];
  const renderingProvider = (insProfileId) => renderProviderInfo('rendering', insProfileId) || [];

  const insuranceRender = () => {
    const hasReachedLimit = practice?.eligibility_counter <= 0;
    return insuranceProfiles?.map((insurance) => {
      const { id, payer_name, type } = insurance || {};
      return {
        icon: 'new-first-line',
        property_of: eligibilityPresent ? 're-run eligibility' : 'run eligibility',
        children: !hasReachedLimit ? [...billingProvider(id), ...renderingProvider(id)] : null,
        label: (
          <Tippy
            key={id}
            hideOnClick={false}
            className="tippy-dark"
            disabled={!hasReachedLimit}
            content="You are out of eligibility requests. Please contact support!">
            <div className="flex items-center justify-between gap-2">
              <span className="w-40 truncate text-primary-900" title={payer_name}>
                {payer_name}
              </span>
              <span className={`capitalize text-neutral-500 ${hasReachedLimit && 'mr-1'}`}>
                {type}
              </span>
            </div>
          </Tippy>
        )
      };
    });
  };

  const EmptyState = () => (
    <NotFound
      className="!pr-2 hover:bg-none"
      title="No insurance profile"
      icon="new-no-income-illustration"
      description="Please add an insurance profile to run eligibility"
    />
  );

  const options = [
    ...(!ia(insuranceProfiles) ? [{ component: <EmptyState /> }] : []),
    ...(eligibilityPresent && ia(insuranceProfiles)
      ? [
          {
            label: (
              <div className="flex justify-between gap-2">
                <span className="text-primary-800">View response</span>
                <span className="tracking-wide text-neutral-500">
                  {formatDate(
                    insuranceBenefits?.eligibility_run ||
                      insuranceBenefits?.primary?.eligibility_run
                  )}
                </span>
              </div>
            ),
            icon: 'new-first-line',
            property_of: 'eligibility response',
            onClick: () => setShowEligibilityResponse(true)
          }
        ]
      : []),
    ...insuranceRender()
  ];

  return (
    <>
      <Popover
        isFixed
        isDropdown
        position="left"
        options={options}
        text="Live eligibility"
        icon="new-checkbox-rec"
        iconRightColor="primary"
        buttonTheme="primary"
        loading={runningEligibility}
        panelClassName="min-w-[260px]"
        textClassName="!text-primary-500"
        optionGroupLabelClassName="!text-neutral-800 !font-400"
        optionsGroupBy={ia(insuranceProfiles) ? 'property_of' : null}
        buttonClassName="!rounded-md border border-solid border-primary-200 !px-[10px] !py-[5px] !min-h-[30px] h-[30px] !bg-transparent"
      />

      {showEligibilityResponse && (
        <Modal
          isLarge
          slideFromRight
          bodyClassName="p-0"
          className="w-[1320px]"
          title="Eligibility response"
          handleClose={closeEligibilityModal}
          handleOpen={showEligibilityResponse}>
          <Tabs
            hasContent
            theme="secondary"
            tabsData={tabsData}
            className="z-[100]"
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabsParentClassName="!sticky top-0 z-[99]"
          />
        </Modal>
      )}
    </>
  );
};

export default EligibilityWrapper;
