import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import InstantActionsAppointmentConfirmed from './pages/InstantActionsComponent/AppointmentConfirmed';
import InstantActionsComponent from './pages/InstantActionsComponent/instantActionsComponent';
import InstantActionsConfirm from './pages/InstantActionsComponent/instantActionsConfirmAppointment';
import AppointmentKiosk from './pages/InstantActionsComponent/AppointmentKiosk';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Spinner from 'components/shared/spinner/spinner';

const VIEW_KIND = {
  appointmentCreate: 'appointment_create',
  appointmentConfirmed: 'appointment_confirmed',
  appointmentRequest: 'appointment_request',
  kioskFail: 'kioskFail',
  userFail: 'userFail'
};

const InstantActionPage = ({ actionType, setActionType }) => {
  const [show, setShow] = useState({ loading: false, noToken: false, expiredLink: false });
  const [view, setView] = useState({
    kind: null,
    success: null,
    text: ''
  });
  const [andAction, setAndAction] = useState(false);
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState({ redirect: false });

  useEffect(() => {
    const requestToken = token;
    if (requestToken) {
      getRedirectData(requestToken);
    } else {
      setShow({ ...show });
    }
  }, []);

  const getRedirectData = async (token, optAndAction = false) => {
    setShow({ loading: true });
    let newShow = Object.assign({}, show);
    try {
      const res = await interimApi(
        '/api/link/action',
        {
          token,
          andAction: andAction || optAndAction
        },
        navigate
      );
      const { code, redirect, error, message, data, type } = res.data;
      setActionType(type);

      if (message) setView(message);

      if (data) {
        setData(data);
      }

      switch (code) {
        case -1:
          navigate(redirect);

          break;
        case 0:
          // this case shouldn't happen
          break;
        case 1:
          if (type === 'kiosk') {
            setShow((ps) => ({ ...ps, kind: VIEW_KIND.kioskFail }));
          } else {
            setShow((ps) => ({ ...ps, kind: VIEW_KIND.userFail }));
          }
          break;
        case 2:
        case 3:
          newShow.expiredLink = true;
          break;
        case 5:
          navigate(`/ia/auth/${token}`);
          break;
        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: InstantActions/index, method: getRedirectData - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: InstantActions/index, method: getRedirectData - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
    newShow.loading = false;
    setShow(newShow);
  };

  const handleRedirect = async () => {
    setLoading({ ...loading, redirect: true });

    const { data } = await interimApi('/api/user/check_token', {}, navigate);

    if (data?.token_valid) {
      navigate(view?.redirectTo);
    } else {
      navigate('/login');
      localStorage.setItem('redirectTo', view?.redirectTo);
    }

    setLoading({ ...loading, redirect: false });
  };

  if (show.noToken) {
    return <InstantActionsComponent success={false} text="Invalid link has been provided." />;
  }

  if (show.expiredLink) {
    return (
      <InstantActionsComponent
        success={false}
        text="The link has expired. Please request a new link."
      />
    );
  }

  switch (view.kind) {
    case VIEW_KIND.appointmentCreate:
      return (
        <InstantActionsConfirm
          text={view.text}
          success={view.success}
          setAndAction={setAndAction}
          handleRedirect={handleRedirect}
          loading={loading?.redirect}
          token={token}
          resubmit={() => getRedirectData(token, true)}
          data={data}
        />
      );
    case VIEW_KIND.appointmentConfirmed:
      return actionType ? (
        <AppointmentKiosk
          text={view.text}
          type={actionType}
          success={view.success}
          setAndAction={setAndAction}
          handleRedirect={handleRedirect}
          loading={loading?.redirect}
          token={token}
          resubmit={() => getRedirectData(token, true)}
          data={data}
        />
      ) : (
        <InstantActionsAppointmentConfirmed
          text={view.text}
          success={view.success}
          setAndAction={setAndAction}
          handleRedirect={handleRedirect}
          loading={loading?.redirect}
          token={token}
          resubmit={() => getRedirectData(token, true)}
          data={data}
        />
      );
    case VIEW_KIND.appointmetnRequest:
      return <InstantActionsComponent text={view.text} success={view.success} />;
    case VIEW_KIND.kioskFail:
      return (
        <div className="flex h-[100vh] flex-col items-center justify-center">
          <Icon icon="404-not-found" />
          <h3>Oops...</h3>
          <div>Unfortunately, we didn't find the link.</div>
          <Button
            to="/kiosk/idle"
            text="Exit"
            iconRight="new-logout"
            color="warning"
            outlined={true}
          />
        </div>
      );
    case VIEW_KIND.userFail:
      return (
        <div className="flex h-[100vh] flex-col items-center justify-center">
          <Icon icon="404-not-found" />
          <h3>Oops...</h3>
          <div>Unfortunately, we didn't find the link.</div>
          <Button to="/login" text="Exit" iconRight="new-logout" color="warning" outlined={true} />
        </div>
      );
    default:
      return (
        <div className="flex h-[100vh] flex-col items-center justify-center">
          <h3>Redirecting...</h3>
          {show.loading && <Spinner wrapperStyle={{ width: '100%', marginTop: '20px' }} />}
        </div>
      );
  }
};

export default InstantActionPage;
