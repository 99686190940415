import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import SimpleTable from '../../../../../../shared/Forms/Custom/ProviderMedications/components/SimpleTable';
import Status from 'components/shared/Status/Status';
import { capitalize } from 'lodash';
import { formatDate } from 'lib/helpers/utility';

const cols = [
  {
    field: 'medication',
    headerName: 'Medication',
    className: 'break-words',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      return (
        <span style={{ wordBreak: 'break-word' }} className="text-sm font-400 text-neutral-900">
          {params?.data?.medication ?? 'N/A'}
        </span>
      );
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      const status = params?.data?.status;
      return (
        <div className="flex h-full items-center justify-center !p-3">
          {status === 'active' ? (
            <Status
              status={status}
              textClassName="!text-success-500"
              className="flex max-h-[24px] min-w-[50px] items-center justify-center border border-solid border-success-200 !bg-success-50"
            />
          ) : (
            <Status
              status={status}
              textClassName="!text-danger-500"
              className="flex max-h-[24px] min-w-[50px] items-center justify-center border border-solid border-danger-200 !bg-danger-50"
            />
          )}
        </div>
      );
    }
  },
  {
    field: 'dose',
    headerName: 'Dose',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      if (!params?.data?.strength && !params?.data?.unit) return 'N/A';

      return (
        <span>
          {params?.data?.strength}
          {params?.data?.unit}
        </span>
      );
    }
  },
  {
    field: 'prescriber.label',
    headerName: 'Prescriber',
    valueFormatter: ({ data }) =>
      !!data?.prescriber?.label ? capitalize(data.prescriber.label) : 'N/A'
  },
  {
    field: 'start_date',
    headerName: 'Start date',
    className: 'min-w-20',
    valueFormatter: ({ data }) =>
      data?.start_date ? formatDate(data.start_date, undefined) : 'N/A'
  },
  {
    field: 'end_date',
    headerName: 'End date',
    className: 'min-w-20',
    valueFormatter: ({ data }) => (data?.end_date ? formatDate(data.end_date, undefined) : 'N/A')
  }
];

const MedicationsSimpleTable = ({ data, emptyState = 'No medications' }) => {
  return (
    <>
      <TableContextProvider name="medications_simple_table" defaultFilters={[]} cols={cols}>
        <SimpleTable data={data} emptyState={emptyState} />
      </TableContextProvider>
    </>
  );
};

export default MedicationsSimpleTable;
