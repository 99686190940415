export const PAYER_TYPES = [
  {
    label: 'Electronic',
    value: 'electronic'
  },
  {
    label: 'Paper',
    value: 'paper'
  }
];
