import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { formatDate, iaRa } from 'lib/helpers/utility';
import { isEmpty } from 'lodash';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { currentPractice } from 'components/practice/practiceState';
import moment from 'moment';
import { useMember } from 'lib/hooks/queries/useMember';
import Select from 'components/shared/Select/Select';

const InsurancePriorAuth = () => {
  const { values, initialValues, setFieldValue } = useFormikContext();
  const { data } = useMember({});
  const members = iaRa(data?.members);
  const memberOptions = members.map((m) => ({
    label: `${m.f_name} ${m.l_name}`,
    value: m.user_id
  }));
  const priorAuthNeeded = values.checkin?.prior_auth_needed;
  const currentUser = useRecoilValue(userState);
  const practice = useRecoilValue(currentPractice);

  const handleSetPriorAuthNumber = (e) => {
    const value = e.target.value;

    if (isEmpty(initialValues.checkin.prior_auth_submission)) {
      const prior_auth_submission = {
        user_id: currentUser.id,
        data: {
          number: value,
          status: 'submitted',
          submitted_on: formatDate(new Date(), practice.timezone, true)
        }
      };
      setFieldValue('checkin.prior_auth_submission', prior_auth_submission);
      return;
    }
    setFieldValue('checkin.prior_auth_submission.data.number', value);
  };

  if (!priorAuthNeeded) return null;

  const handleDateChange = (date, close) => {
    const dt = moment.tz(moment(date).add(12, 'h'), practice.timezone);
    setFieldValue(
      'checkin.prior_auth_submission.data.submitted_on',
      formatDate(dt, practice.timezone, true)
    );
    close();
  };

  const handleChangeSubmittedBy = (value, close) => {
    setFieldValue('checkin.prior_auth_submission.user_id', value);
    close();
  };
  const handleChangeSubmissionStatus = (value, close) => {
    setFieldValue('checkin.prior_auth_submission.data.status', value);
    close();
  };

  const getSubmittedBy = () => {
    const member = members.find((m) => m.user_id === values.checkin.prior_auth_submission.user_id);

    return `${member?.f_name} ${member?.l_name}`;
  };

  const selectedMember = memberOptions.find(
    (member) => member.value === values.checkin.prior_auth_submission.user_id
  );
  const statusOptions = [
    { label: 'Submitted', value: 'submitted' },
    { label: 'Approved', value: 'approved' },
    { label: 'Denied', value: 'denied' }
  ];
  const status = statusOptions.find(
    (st) => st.value === values?.checkin?.prior_auth_submission?.data?.status
  );

  const isAptDisabled = values.appointment_disabled;

  return (
    <>
      <div className="mt-2 flex items-center gap-[6px]">
        {!isEmpty(values.checkin.prior_auth_submission) ? (
          <Icon
            icon="new-check"
            color="success"
            stroke={true}
            size={20}
            className="cursor-pointer"
          />
        ) : (
          <span className="mb-1 text-[20px]">-</span>
        )}
        <h4 className="text-sm font-500 text-neutral-800">
          Insurance Prior Auth
          {isEmpty(values.checkin.prior_auth_submission) && ' Not Submitted'}
        </h4>
      </div>
      <div className="ml-12 mt-[2px] flex flex-col gap-1">
        {!isEmpty(initialValues.checkin.prior_auth_submission) && (
          <>
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm  text-neutral-800 ">Submitted on</p>

              <Popover className="relative z-40 flex-1 ">
                {({ close }) => (
                  <>
                    <PopoverButton
                      className="flex w-full cursor-pointer flex-col items-end p-0 text-right"
                      disabled={isAptDisabled}>
                      <p className="text-sm text-neutral-800 ">
                        {formatDate(values.checkin.prior_auth_submission?.data?.submitted_on)}
                      </p>
                      <p className="h-[1px] w-[70px] bg-neutral-200"></p>
                    </PopoverButton>
                    <PopoverPanel className="absolute bottom-full z-10 flex w-max shadow-md">
                      <Calendar
                        showDateDisplay
                        className="w-full"
                        onChange={(date) => handleDateChange(date, close)}
                        date={
                          new Date(
                            formatDate(values.checkin.prior_auth_submission?.data?.submitted_on)
                          )
                        }
                      />
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            </div>
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm  text-neutral-800 ">Submitted by</p>
              <Popover className="relative z-50 flex-1 ">
                {({ close }) => (
                  <>
                    <PopoverButton
                      className="flex w-full cursor-pointer flex-col items-end p-0 text-right"
                      disabled={isAptDisabled}>
                      <p className="text-sm  text-neutral-800 ">{getSubmittedBy()}</p>
                      <div className="h-[1px] w-[70px] bg-neutral-200"></div>
                    </PopoverButton>
                    <PopoverPanel className="absolute bottom-full z-10 flex w-max shadow-md">
                      <Select
                        options={memberOptions}
                        value={selectedMember}
                        parentClassName="w-[200px]"
                        placeholder="Change submitted by"
                        onChange={({ value }) => handleChangeSubmittedBy(value, close)}
                        autoFocus
                        isClearable={false}
                        disabled={isAptDisabled}
                      />
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            </div>
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm  text-neutral-800 ">Status</p>
              <Popover className="relative z-40 flex-1 ">
                {({ close }) => (
                  <>
                    <PopoverButton
                      className="flex w-full cursor-pointer flex-col items-end p-0 text-right"
                      disabled={isAptDisabled}>
                      <p className="text-sm  text-neutral-800 ">{status.label}</p>
                      <div className="h-[1px] w-[70px] bg-neutral-200"></div>
                    </PopoverButton>
                    <PopoverPanel className="absolute bottom-full z-10 flex w-max shadow-md">
                      <Select
                        options={statusOptions}
                        parentClassName="w-[200px]"
                        value={status}
                        placeholder="Change prior auth status"
                        onChange={({ value }) => handleChangeSubmissionStatus(value, close)}
                        autoFocus
                        isClearable={false}
                        disabled={isAptDisabled}
                      />
                    </PopoverPanel>
                  </>
                )}
              </Popover>
            </div>
          </>
        )}
        <div className="flex w-full items-center justify-between ">
          <p className="flex-1 text-sm  text-neutral-800 ">
            {!values.checkin.prior_auth_submission?.data?.number && 'Enter'} Prior Auth Number
          </p>
          <input
            className="w-[70px] border-0 border-b border-solid border-b-neutral-200 bg-transparent text-right focus:outline-none"
            onChange={handleSetPriorAuthNumber}
            value={values.checkin.prior_auth_submission?.data?.number}
            disabled={isAptDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default InsurancePriorAuth;
