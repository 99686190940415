import { requestApi } from 'api/Api';

export const getPromptTemplates = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/prompts/get', params, navigate });
};

export const listPromptTemplates = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/prompts/list', params, navigate });
};

export const createPromptTemplate = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/prompts/create', params, navigate });
};

export const updatePromptTemplate = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/prompts/update', params, navigate });
};

export const getHandlebarValues = (navigate, params) =>
  requestApi({ navigate, url: '/api/admin/intelligence/possible_handlebars/get', params });
