import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';

import { requestApi } from 'api/Api';

import ColorPicker from 'components/shared/ColorPicker/ColorPicker';

const Services = ({ service }) => {
  const [color, setColor] = useState(service.color);
  const navigate = useNavigate();

  const updateColor = async (color) => {
    try {
      const params = {
        service_id: service.id,
        fields: { color }
      };
      await requestApi({
        url: '/api/practice/services/update',
        params,
        navigate
      });
    } catch (error) {
      Honeybadger.notify(`Error updating color: ${error}`);
      console.error(error);
    }
  };

  const handleChange = (color) => {
    setColor(color);
    updateColor(color);
  };

  return (
    <div className="grid grid-cols-3 items-center">
      <div className="text-sm">{service.name}</div>
      <ColorPicker color={color} onChange={handleChange} label={false} />
    </div>
  );
};

export default Services;
