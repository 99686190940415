import { getInternalInvoiceByIds } from 'api/Billing';
import PreviewAppointment from 'components/practice/appointment/PreviewAppointment/PreviewAppointment';
import AltContact from 'components/practice/BeyondBilling/ElectronicInvoicing/components/AltContact';
import ContactButton from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/ContactButton';
import CustomContact from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/CustomContact';
import { CustomSendModal } from 'components/practice/BeyondBilling/ElectronicInvoicing/components/contactButton/CustomSendModal';
import { InvoiceModal } from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoiceModal';
import InvoicePrintView from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoicePrintView';
import NewInvoice from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoice';
import NewInvoiceForm from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoiceForm';
import NewInvoiceView from 'components/practice/BeyondBilling/ElectronicInvoicing/components/NewInvoiceView';
import { ModalCreateFooter } from 'components/practice/BeyondBilling/ElectronicInvoicing/InternalInvoices';
import {
  getInvoicesFromSelectedRows,
  onSendEmail,
  onSendText
} from 'components/practice/BeyondBilling/ElectronicInvoicing/lib/utils';
import {
  appointmentStatuses as appointmentStatusesState,
  currentPractice
} from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import Pagination from 'components/shared/Pagination/Pagination';
import TableCounter from 'components/shared/Table/TableCounter';
import { externalInvoiceModalState } from 'components/state';
import { requestApi } from 'api/Api';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues, reShapePractitioners, reShapeProcedures } from 'lib/helpers/utility';
import { useAppointments } from 'lib/hooks/queries/appointment/useAppointments';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import useCreateExternalInvoice from 'lib/hooks/useCreateExternalInvoice';
import usePageTitle from 'lib/hooks/usePageTitle';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilState, useRecoilValue } from 'recoil';
import HeaderActions from './components/HeaderActions';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';
import { useAppointmentv2 } from 'lib/hooks/queries/appointment/useAppointmentv2';

const AppointmentsQueue = () => {
  const currPractice = useRecoilValue(currentPractice);
  const appointmentStatuses = useRecoilValue(appointmentStatusesState);

  return (
    <TableContextProvider
      defaultFilters={DEFAULT_FILTERS}
      cols={defaultColumns(currPractice?.timezone, appointmentStatuses)}
      name="appointments-queue"
      pagination>
      <Table />
    </TableContextProvider>
  );
};

const initialView = {
  title: 'Create Invoice',
  step: 0,
  mode: 'create'
};

function Table() {
  usePageTitle('Appointments');
  const category = 'appointments';
  const kind = 'appointment';
  const navigate = useNavigate();

  const pageToPrintRef = useRef();

  const [showPreviewAppointmentModal, setShowPreviewAppointmentModal] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [externalInvoices, setExternalInvoices] = useState([]);
  const [internalInvoices, setInternalInvoices] = useState([]);
  const [getInternalInvoicesLoading, setGetInternalInvoicesLoading] = useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [activeView, setActiveView] = useState(initialView);

  const currPractice = useRecoilValue(currentPractice);
  const [externalInvoiceModal, setExternalInvoiceModalState] =
    useRecoilState(externalInvoiceModalState);
  const appointmentStatuses = useRecoilValue(appointmentStatusesState);

  const { isOpen: isExternalModalOpen, id: invoiceId } = externalInvoiceModal;
  const claims = currPractice?.display_settings?.claims;

  const {
    limit,
    page,
    setPage,
    sort,
    filters,
    setFilters,
    selectedRows,
    setSelectedRows,
    gridApi
  } = useTableContext();

  const { data, isLoading, isFetching } = useAppointments({
    params: {
      defaultDate: null,
      withEvents: false,
      practitionerPractice: false,
      rangeDate: {
        startDate: filters?.appointment_date?.values?.startDate,
        endDate: filters?.appointment_date?.values?.endDate
      },
      limit,
      page,
      pagination: true,
      sort,
      filters: mapValues({
        ...filters,
        practitioners: filters?.provider?.values?.map((item) => item.value),
        serviceType: filters?.service?.values?.map((item) => item.value),
        patient: filters?.patient?.values?.map((item) => item),
        appointmentTags: filters?.appointmentTags?.values?.map((item) => item?.value),
        appointmentType: filters?.appointmentType?.values?.map((item) => item?.value),
        resources: filters?.resources?.values?.map((item) => item.value)
      }),
      fromPracticeIQueue: true
    },
    dependencies: [limit, page, sort, filters]
  });

  const { data: appointmentData } = useAppointmentv2({
    params: {
      id: appointment?.id,
      withal: {
        medicalHistory: true,
        payments: true,
        patient: true,
        practitioner: true,
        allergies: true,
        practitionerPractice: true,
        tag: true,
        resources: true
      }
    },
    options: { enabled: !!appointment }
  });
  const fullAppointment = appointmentData?.appointment;

  const { data: providersList = {} } = useProviders({});

  const { data: proceduresList } = useServices({});

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });

  const { data: resourceList } = useResourcesAsOptions({});

  const providerOptions = useMemo(() => {
    if (providersList?.practitioners?.length > 0) {
      return providersList.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providersList]);

  filters.provider.options = providerOptions;
  let finalGroupInvoices = [];
  const selectedRowInvoices = internalInvoices;

  useEffect(() => {
    if (isExternalModalOpen) {
      handleInvoiceModalOpen(invoiceId);
    }
  }, [invoiceId, isExternalModalOpen]);

  if (selectedRowInvoices?.length > 0) {
    finalGroupInvoices = getInvoicesFromSelectedRows(selectedRowInvoices);
  }

  const initialValues = {};

  finalGroupInvoices?.map((invoice) => {
    initialValues[invoice.id] = {
      internal_invoice_ids: invoice.internal_invoice_ids,
      patient: invoice.patient,
      due_date: new Date(),
      memo: '',
      amount_cents: invoice.procedures.reduce((acc, curr) => {
        const currCharge = curr?.charge || 0;
        acc += currCharge;
        return acc;
      }, 0),
      total_amount_cents: 0,
      discount_amount_cents: 0,
      tax_percentage: 0,
      tax_amount_cents: 0,
      surcharge_amount: 0
    };
  });

  const { invoiceFormik, isCreateInvoicesLoading } = useCreateExternalInvoice({
    initialValues,
    navigate,
    setActiveView,
    setExternalInvoices
  });

  const serviceOptions = useMemo(() => {
    if (proceduresList?.services?.length > 0) {
      return proceduresList.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [proceduresList]);
  filters.service.options = serviceOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags?.map((t) => ({
        label: t?.label,
        value: t?.id
      }));
    }
  }, [tagsList]);
  filters.appointmentTags.options = tagOptions;

  const arrivalOptions = useMemo(() => {
    return appointmentStatuses
      .filter((status) => status.value.includes('arrived') || status.value == 'appointment_ended')
      .map(({ label }) => ({ label, value: label }));
  }, [appointmentStatuses]);

  filters.arrivalStatus.options = arrivalOptions;

  const resourcesOptions = useMemo(() => {
    if (resourceList?.resources?.length > 0) {
      return resourceList?.resources?.map((r) => ({
        label: r?.label,
        value: r?.value
      }));
    }
  }, [resourceList]);
  filters.resources.options = resourcesOptions;

  const appointments = data?.appointment || [];
  const count = data?.count?.count || 0;

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'checkbox') return;
    if (e?.column?.getColId() === 'actions') return;
    if (['actions', '0'].includes(e?.column?.colId)) return;
    setAppointment(e?.data);
    setShowPreviewAppointmentModal(true);
  };

  const hidePreviewAppointment = () => {
    setShowPreviewAppointmentModal(false);
    setAppointment(null);
  };

  function handleInvoiceModalClose() {
    setActiveView((prev) => ({ ...prev, mode: 'create', step: 0, title: 'Create Invoice' }));

    invoiceFormik.resetForm();

    setInvoiceModalVisible(false);
    setExternalInvoiceModalState({ id: null, isOpen: false });
    gridApi.deselectAll();
  }

  async function handleInvoiceModalOpen(selectedRowId) {
    try {
      setInvoiceModalVisible(true);
      setGetInternalInvoicesLoading(true);

      let selectedInvoiceIds = [selectedRowId];
      if (!selectedRowId) {
        selectedInvoiceIds = selectedRows.map((row) => row?.data?.invoice?.id).filter(Boolean);
      }
      const { invoices = [] } = await getInternalInvoiceByIds(navigate, {
        internal_invoice_ids: selectedInvoiceIds
      });

      setInternalInvoices(invoices);
      setGetInternalInvoicesLoading(false);
    } catch (error) {
      console.error(error);
      setGetInternalInvoicesLoading(false);
    }
  }

  let headButton = null;
  let prevButton = null;

  let modalContent = null;

  let modalFooter = null;

  if (
    activeView.step > 1 ||
    (selectedRows?.length > 0 && finalGroupInvoices.length > 0) ||
    activeView?.mode === 'create'
  ) {
    modalFooter = (
      <ModalCreateFooter
        invoiceIds={finalGroupInvoices.map((invoice) => invoice?.id)}
        formik={invoiceFormik}
        loading={isCreateInvoicesLoading}
        handleInvoiceModalClose={handleInvoiceModalClose}
      />
    );
  }

  if ((selectedRows?.length > 0 || invoiceId) && finalGroupInvoices.length > 0) {
    modalContent = (
      <>
        {finalGroupInvoices.map((finalInvoice) => {
          const invoiceDetails = getProceduresTotal([finalInvoice]);

          const hasMany = ia(finalInvoice.internal_invoice_ids, 1);
          const isAEOB = invoiceFormik?.values?.[finalInvoice.id]?.aeob ?? true;

          return (
            <div key={finalInvoice.id}>
              <InvoicePrintView
                practice={currPractice}
                patient={finalInvoice?.patient}
                createdAt={!hasMany ? finalInvoice?.dateofservice : null}
                content={
                  <NewInvoice invoice={finalInvoice} isAEOB={isAEOB}>
                    <NewInvoiceForm
                      formik={invoiceFormik}
                      invoiceId={finalInvoice.id}
                      initialValues={initialValues}
                      invoiceDetails={invoiceDetails}
                      hasClaims={claims}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
  }

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  if (activeView.mode === 'preview') {
    const handleCustomSendModalOpen = (type) => {
      setCustomContactType(type);
      setCustomModalVisible(true);
    };
    modalContent = (
      <>
        {finalGroupInvoices.map((finalInvoice, idx) => {
          const externalInvoice = externalInvoices.find(
            (invoice) => invoice.patient_id === finalInvoice.patient.id
          );

          const invoiceDetails = getProceduresTotal([finalInvoice]);

          return (
            <div key={idx}>
              <InvoicePrintView
                practice={currPractice}
                patient={finalInvoice?.patient}
                createdAt={finalInvoice?.dateofservice}
                content={
                  <NewInvoice invoice={finalInvoice} isView isAEOB={externalInvoice?.aeob}>
                    <NewInvoiceView
                      hasClaims={claims}
                      invoiceDetails={{
                        ...invoiceDetails,
                        discount: externalInvoice?.discount_amount_cents || 0,
                        tax_percentage: externalInvoice?.tax_percentage || 0,
                        tax_amount_cents: externalInvoice?.tax_amount_cents || 0,
                        surcharge_amount: externalInvoice?.surcharge_amount || 0,
                        memo: externalInvoice?.memo,
                        aeob: externalInvoice?.aeob
                      }}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
    headButton = (
      <div className="flex w-full justify-end">
        <ReactToPrint
          trigger={() => (
            <Button
              color=""
              size="small"
              type="link"
              text="Print"
              icon="new-printer-bulk"
              className="text-primary-900"
            />
          )}
          content={() => pageToPrintRef?.current}
        />
      </div>
    );
    prevButton = null;
    modalFooter = (
      <div className="flex w-full justify-between">
        <Button
          outlined
          text="Cancel"
          color="neutral"
          onClick={handleInvoiceModalClose}
          id="cancelInvoiceModalBtn"
          data-qa="cancel-btn"
        />
        <div className="flex">
          <ContactButton type="text">
            {externalInvoices.some((invoice) => invoice?.patient?.phone) && (
              <div
                data-qa="send-primary-text"
                onClick={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                <Icon icon="text-message" size="15px" />{' '}
                {finalGroupInvoices.length > 1
                  ? 'Primary phone'
                  : `${externalInvoices[0]?.patient?.phone} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_phone) && (
              // Check for alternative phone
              <AltContact
                type="phone"
                handleAlt={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltPhone: true,
                    isPopover: true
                  })
                }>
                alternative
                {finalGroupInvoices.length > 1
                  ? 'Alternative phone'
                  : `${externalInvoices[0]?.patient?.alt_phone} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('text')} type="phone" />
          </ContactButton>

          <ContactButton type="email">
            {externalInvoices.some((invoice) => invoice?.patient?.email) && (
              <div
                data-qa="send-primary-email"
                onClick={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                {finalGroupInvoices.length > 1
                  ? 'Primary email'
                  : `${externalInvoices[0]?.patient?.email} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_email) && (
              // Check for alternative email
              <AltContact
                type="email"
                handleAlt={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltEmail: true,
                    isPopover: true
                  })
                }>
                {finalGroupInvoices.length > 1
                  ? 'Alternative email'
                  : `${externalInvoices[0]?.patient?.alt_email} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('email')} type="email" />
          </ContactButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <Header title="Appointments">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <>
              <HeaderActions selectedRows={selectedRows} setSelectedRows={setSelectedRows} />
            </>
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
            contentStyle={{ maxHeight: '80%' }}
          />
          <Button
            disabled={!selectedRows?.length}
            size="small"
            data-qa="invoice-patient-btn"
            onClick={() => handleInvoiceModalOpen()}
            text="Invoice Patient"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} height="51px" />
          ) : (
            <AGTable
              data={appointments}
              rowSelection="multiple"
              onCellClicked={onCellClicked}
              columnDef={defaultColumns(currPractice?.timezone, appointmentStatuses)}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {showPreviewAppointmentModal && (
        <PreviewAppointment
          appointment={{ ...appointment, ...fullAppointment }}
          setAppointment={setAppointment}
          showPreviewAppointment={showPreviewAppointmentModal}
          hidePreviewAppointment={hidePreviewAppointment}
          practitioners={reShapePractitioners(providersList?.practitioners)}
          services={reShapeProcedures(proceduresList?.services)}
        />
      )}

      {invoiceModalVisible ? (
        <InvoiceModal
          prevButton={prevButton}
          title={activeView.title}
          invoiceModalVisible={invoiceModalVisible}
          onClose={handleInvoiceModalClose}
          modalContent={
            <div ref={pageToPrintRef} className="h-full w-auto">
              {getInternalInvoicesLoading ? <Skeleton className="h-full" /> : modalContent}
            </div>
          }
          footer={modalFooter}
          headButton={headButton}
        />
      ) : null}
      {customModalVisible ? (
        <CustomSendModal
          ids={externalInvoices.map((invoice) => invoice.id)}
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
        />
      ) : null}
    </>
  );
}

export default withErrorBoundary(AppointmentsQueue);
