import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { updateDocument } from 'api/Documents';

import { imagePath } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import DocumentModal from 'components/shared/DocumentEditor/DocumentModal';
import getEditableDocumentType from 'components/shared/DocumentEditor/lib/getEditableDocumentType';
import { clinicalNote } from 'components/state';

import Modal from '../../../shared/Modal/Modal';

import EditDocument from './components/EditDocument';
import PreviewDocument from './components/PreviewDocument';
import setMultipleQueriesData from './lib/setMultipeQueriesData';

export default function View({
  viewDocument,
  setViewDocument,
  handleCloseModal,
  action,
  patientId,
  handle,
  type,
  exportRef,
  print,
  fromPatient,
  appointmentId,
  categories
}) {
  const { locked: isLocked } = useRecoilValue(clinicalNote);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const submitFormButton = useRef();
  const [editLoading, setEditLoading] = useState(false);

  const canUpdate =
    viewDocument?.patient_id === viewDocument?.uploader_id || !fromPatient || appointmentId;

  const [edit, setEdit] = useState({
    name: viewDocument?.title,
    category: viewDocument?.category
  });

  const handleEdit = async () => {
    setEditLoading(true);
    const data = await updateDocument(navigate, {
      handle,
      name: edit.name,
      category: edit.category.value
    });

    if (data?.update > 0) {
      setMultipleQueriesData(
        queryClient,
        [
          'documents',
          ['attachments', appointmentId?.toString() || viewDocument?.appointment_id?.toString()]
        ],
        (old) => {
          return {
            ...old,
            documents: old?.documents?.map((item) => {
              if (item?.document_handle === handle) {
                return {
                  ...item,
                  name: edit.name,
                  category: edit?.category?.value?.toString()
                };
              } else {
                return item;
              }
            })
          };
        }
      );
      showAlert({ title: 'Document edited successfully', color: 'success' });
      handleCloseModal();
    } else {
      showAlert({ title: 'There was a problem while editing document.', color: 'success' });
    }
    setEditLoading(false);
  };

  const handleFormSubmit = async () => {
    if (submitFormButton.current) {
      submitFormButton.current.click();
    }
  };

  const [loading, setLoading] = useState({
    personal_id_front: false,
    personal_id_back: false,
    insurance_front: false,
    insurance_back: false,
    filestack: false
  });
  const [url, setUrl] = useState();
  const [hipaaData, setHipaaData] = useState();

  useEffect(() => {
    if (handle && type !== 'form') getUrl();
  }, [handle]);

  const getUrl = async () => {
    setLoading((prev) => ({ ...prev, filestack: true }));

    if (
      [
        'image',
        'image/jpg',
        'image/jpeg',
        'image/png',
        'pdf',
        'application/pdf',
        'rtf',
        'application/rtf',
        'eml',
        'application/eml',
        'custom/html',
        'message/rfc822'
      ].includes(type)
    ) {
      setUrl(imagePath(handle));
    } else if (type === 'hipaa') {
      if (handle?.url) {
        setUrl(imagePath(handle));
      } else if (handle?.data) {
        if (handle?.data) {
          const hipaaJSON = JSON.parse(handle?.data);
          setHipaaData(decodeURIComponent(hipaaJSON?.htmlData));
        } else {
          setHipaaData(<div>No HIPAA Data</div>);
        }
      }
    }

    setLoading((prev) => ({ ...prev, filestack: false }));
  };

  if (action !== 'Edit' && getEditableDocumentType(viewDocument.type)) {
    return (
      <DocumentModal
        {...{
          src: type !== 'fax' ? url : null,
          id: viewDocument?.id,
          kind: type === 'fax' ? 'fax' : 'patient_documents',
          type: getEditableDocumentType(viewDocument.type),
          handleOpen: !!viewDocument,
          handleClose: handleCloseModal,
          isEditable: !isLocked && (!fromPatient || appointmentId),
          title: viewDocument?.title,
          isFooter: !isLocked && canUpdate,
          footer:
            isLocked || !canUpdate ? null : (
              <div className="flex flex-end gap-2">
                <Button
                  className="mr-auto"
                  text="Cancel"
                  outlined
                  color="neutral"
                  onClick={handleCloseModal}
                />

                <Button text="Update" loading={editLoading} primary onClick={handleEdit} />
              </div>
            ),

          subheaderComponent:
            isLocked || !canUpdate ? null : (
              <EditDocument
                {...{
                  edit,
                  setEdit,
                  handle,
                  type,
                  action: 'Edit',
                  loading,
                  patientId,
                  fromPatient,
                  submitFormButton,
                  handleCloseModal,
                  categories,
                  inline: true,
                  handleEdit,
                  appointmentId
                }}
              />
            )
        }}
      />
    );
  }

  return (
    <Modal
      customHeader={
        <div className="flex w-full mr-4 justify-between">
          <p className="text-ellipsis text-lg">{viewDocument?.title}</p>
          {viewDocument?.type === 'hipaa' && (
            <Button
              size="small"
              text="Print"
              iconColor="white"
              icon="new-printer"
              onClick={() => print()}
            />
          )}
        </div>
      }
      handleOpen={!!viewDocument}
      handleClose={handleCloseModal}
      isLarge
      className={
        ['hipaa', 'insurance'].includes(viewDocument.type) ? 'max-w-[1280px]' : 'max-w-[640px]'
      }
      slideFromRight
      footer={
        viewDocument?.action === 'Edit' && (
          <div className="flex flex-end gap-2">
            <Button
              className="mr-auto"
              text="Cancel"
              outlined
              color="neutral"
              onClick={handleCloseModal}
            />

            <Button
              text="Update"
              primary
              onClick={viewDocument?.type === 'form' ? handleFormSubmit : handleEdit}
            />
          </div>
        )
      }>
      {action === 'Edit' ? (
        <EditDocument
          {...{
            edit,
            setEdit,
            handle,
            type,
            action,
            fromPatient,
            loading,
            patientId,
            inline: true,
            submitFormButton,
            handleCloseModal,
            categories,
            appointmentId
          }}
        />
      ) : (
        <PreviewDocument
          {...{
            url,
            hipaaData,
            setLoading,
            exportRef,
            handle,
            type,
            patientId,
            setViewDocument,
            loading
          }}
        />
      )}
    </Modal>
  );
}
