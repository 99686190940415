import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { prompts, headerButtons } from '../constants';
import { useMyScribeTemplateContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';

const PromptsSidebar = ({ className, isOpen, setIsOpen }) => {
  const { setCurrentActivePromptId } = useMyScribeTemplateContextApi();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const onPromptClick = (id) => {
    setCurrentActivePromptId(id);
  };

  return (
    <div
      className={cs(
        'fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        {
          'w-[264px]': isOpen,
          'w-0': !isOpen
        },
        className
      )}
      style={{
        transition: 'width 0.2s ease'
      }}>
      {isOpen && (
        <div className="flex items-center justify-between bg-primary-50 !py-[6px] px-3">
          <Icon
            icon="new-hamburger"
            className="!ml-1 cursor-pointer"
            onClick={toggleSidebar} // Toggle sidebar on click
          />
          <>
            <div className="p-2 pl-0">Prompts</div>
            <div className="flex items-center">
              {headerButtons.map((button) => (
                <div className="p-1" key={button.id}>
                  {button.component}
                </div>
              ))}
            </div>
          </>
        </div>
      )}
      {isOpen && (
        <div>
          {prompts.map((prompt) => (
            <div
              key={prompt.id}
              onClick={() => onPromptClick(prompt.id)}
              className="m-3 mb-4 cursor-pointer rounded-lg p-2 hover:bg-primary-50">
              <div className="mb-2 flex items-center">
                <Icon icon={prompt.icon} className="mr-2 text-blue-500" />
                <span className="text-sm text-neutral-600">{prompt.title}</span>
              </div>
              <p className="text-sm font-thin text-neutral-800">{prompt.description}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PromptsSidebar;
