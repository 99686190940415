import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import { usePracticePatientLinks } from 'lib/hooks/queries/practice/usePracticePatientLink';

import MedicationsTable from 'components/practice/charts/Overview/widgets/components/MedicationsTable';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import UpsertProviderMedication from 'components/shared/Forms/Custom/ProviderMedications/components/UpsertMedication';
import { getRowId } from 'components/shared/Forms/Custom/ProviderMedications/lib/medicationTableSelectHelpers';
import { permissions, userState } from 'components/state';

import patientMedicationsCols, { disableIfNotOwner } from './lib/helpers';

export default function Medications() {
  const userPermissions = useRecoilValue(permissions);
  const practice = useRecoilValue(currentPractice);

  const [itemModal, setItemModal] = useState({ open: false, data: null });
  const patientId = useRecoilValue(userState)?.user_id;

  const { data } = useMedication({
    params: { patientId },
    dependencies: [String(patientId)],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications)
    }
  });

  const { data: practiceOptions } = usePracticePatientLinks({
    dependencies: [patientId],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.patientLinks)
    }
  });

  return (
    <div className="h-[calc(100%-44px)] w-full">
      <Button
        text="Add Medication"
        className="ml-auto m-1"
        icon="new-add-square"
        iconColor="white"
        size="small"
        onClick={() => setItemModal({ open: true })}
      />

      <TableContextProvider
        cols={patientMedicationsCols({
          setShowMedicationModal: setItemModal,
          userPermissions,
          practice,
          showCheckboxes: false,
          patientId
        })}
        name="medications"
        defaultFilters={[]}>
        <MedicationsTable data={data} getRowId={getRowId} />
      </TableContextProvider>

      {itemModal?.open && (
        <UpsertProviderMedication
          disabled={
            itemModal?.data ? disableIfNotOwner({ data: itemModal?.data, patientId }) : false
          }
          showModal={itemModal}
          practiceOptions={practiceOptions}
          patientId={patientId}
          hideModal={() => setItemModal(null)}
        />
      )}
    </div>
  );
}
