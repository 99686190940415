import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveClaims, deleteClaims, getClaimsCSV, submitClaims } from 'api/Claim';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import { useClaims } from 'lib/hooks/queries/claims/useClaims';
import { capitalize } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  getColDefs,
  getPatientDefaultFilters,
  GRID_OPTIONS
} from './configs';

const getFinalColDefs = (name, timezone) => {
  let colDefs = [...getColDefs(name, timezone)];

  return colDefs;
};

const NewPatientClaims = ({ name, patient, setClaimId }) => {
  const { claimId: id } = useParams();

  if (id) return <Outlet />;

  return <Table name={name} patient={patient} setClaimId={setClaimId} />;
};

function Table({ name, patient: patientProp, setClaimId }) {
  let category = name;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const chartContext = useOutletContext() || {};
  const patient = chartContext?.patient || patientProp;
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const { type = 'all' } = useParams();
  let finalType = type === 'acknowledged' ? 'accepted' : type;

  if (name === 'claims') {
    category = `${name}_${type}`;
  }

  const { limit, page, setPage, sort, selectedRows, setSelectedRows, filters, setFilters } =
    useTableContext();
  const selectedClaims = selectedRows.map((node) => node.data);
  const selectedClaimsIds = selectedRows.map((claim) => claim?.data?.id);

  const [loading, setLoading] = useState({ claims: false, counter: false, submit: false });
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useClaims({
    params: {
      limit,
      page,
      sort,
      state: finalType,
      withCount: true,
      patientId: patient?.id,
      filters: mapValues({
        ...filters,
        ...(name === 'patient_claims' && {
          patients: {
            type: 'search',
            multiple: true,
            loadOptions: [],
            values: [
              {
                label: patient?.fullName,
                value: patient?.id
              }
            ],
            title: 'Patients',
            preview: (values) => values.map((v) => v.label).join(', ')
          }
        })
      }),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters), patient, finalType]
  });

  const claims = data?.claims;
  const count = data?.count?.[type] || 0;

  useEffect(() => {
    setSelectedRows([]);
  }, [limit, setSelectedRows, filters]);

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: claims,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: claims,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [claims, count, page, limit, onPageChange]);

  const mutateSubmitClaim = useMutation({
    mutationFn: () => submitClaims(navigate, selectedClaimsIds),
    onMutate: () => {
      setLoading((prev) => ({ ...prev, submit: true }));
    },
    onSettled: () => {
      setLoading((prev) => ({ ...prev, submit: false }));
    },
    onSuccess: (data) => {
      const word = selectedClaimsIds.length > 1 ? 'claims' : 'claim';
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          setSelectedRows([]);
          showAlert({
            title: `${capitalize(word)} submitted successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to submit ${word}!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to submit ${word}!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutateArchiveClaim = useMutation({
    mutationFn: () => archiveClaims(navigate, selectedClaimsIds),
    onMutate: () => {
      setLoading((prev) => ({ ...prev, archive: true }));
    },
    onSettled: () => {
      setLoading((prev) => ({ ...prev, archive: false }));
    },
    onSuccess: (data) => {
      const word = selectedClaimsIds.length > 1 ? 'claims' : 'claim';
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          setSelectedRows([]);
          showAlert({
            title: `${capitalize(word)} archived successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to archive ${word}!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to archive ${word}!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutateDeleteClaim = useMutation({
    mutationFn: () => deleteClaims(navigate, selectedClaimsIds),
    onMutate: () => {
      setLoading((prev) => ({ ...prev, delete: true }));
    },
    onSettled: () => {
      setLoading((prev) => ({ ...prev, delete: false }));
    },
    onSuccess: (data) => {
      const word = selectedClaimsIds.length > 1 ? 'claims' : 'claim';
      switch (data?.code) {
        case 0:
          setShowDeleteConfirmationModal(false);

          queryClient.invalidateQueries(['claims']);
          setSelectedRows([]);
          showAlert({
            color: 'success',
            title: `${capitalize(word)} deleted successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to delete ${word}!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to delete ${word}!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const exportCSV = async () => {
    const data = await getClaimsCSV(navigate, {
      limit,
      page,
      sort,
      state: finalType,
      withCount: true,
      patientId: patient?.id,
      filters: mapValues({
        ...filters,
        ...(name === 'patient_claims' && {
          patients: {
            type: 'search',
            multiple: true,
            loadOptions: [],
            values: [
              {
                label: patient?.fullName,
                value: patient?.id
              }
            ],
            title: 'Patients',
            preview: (values) => values.map((v) => v.label).join(', ')
          }
        })
      }),
      category,
      colDefs: getFinalColDefs(null, practiceTimezone)
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'claims.csv';
    a.click();
  };

  const onRowClicked = async (e) => {
    const clickedClaim = e.data;
    const selectedNodes = e.api.getSelectedNodes();

    selectedNodes.forEach((node) => {
      node.setSelected(false);
    });
    setClaimId ? setClaimId(clickedClaim?.id) : navigate(`${type}/${clickedClaim?.id}`);
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }
    await onRowClicked(e);
  };

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const handleDelete = () => {
    mutateDeleteClaim.mutate();
  };

  const onDeleteModalOpen = () => {
    setShowDeleteConfirmationModal(true);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title={name === 'claims' && 'Claims'} subtitle={name === 'claims' && type}>
        <div className="-mt-[10px] mb-1 flex flex-wrap justify-end gap-2 pt-0">
          {ia(selectedClaims) ? (
            <div className="flex items-center gap-2 opacity-100 transition-opacity">
              <Button
                size="small"
                data-qa="submit-selected-claims"
                onClick={() => mutateSubmitClaim.mutate()}
                text="Submit"
                loading={loading.submit}
                disabled={
                  selectedClaims.length === 0 || loading.submit || loading.archive || loading.delete
                }
              />
              {type !== 'archived' && (
                <Button
                  size="small"
                  data-qa="archive-selected-claims"
                  onClick={() => mutateArchiveClaim.mutate()}
                  text="Archive"
                  loading={loading.archive}
                  disabled={
                    selectedClaims.length === 0 ||
                    loading.submit ||
                    loading.archive ||
                    loading.delete
                  }
                  color="neutral"
                />
              )}
              <Button
                size="small"
                data-qa="delete-selected-claims"
                onClick={onDeleteModalOpen}
                text="Delete"
                loading={loading.delete}
                disabled={
                  selectedClaims.length === 0 || loading.submit || loading.archive || loading.delete
                }
                color="danger"
              />
            </div>
          ) : null}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={name === 'claims' ? DEFAULT_FILTERS : getPatientDefaultFilters()}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
          />
          <ExportData
            title="Generate Internal Invoice report"
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            onGenerateClick={exportCSV}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isGetInvoicesLoading || isFetching ? (
            <Skeleton count={25} />
          ) : (
            <AGTable
              defaultColDef={DEFAULT_COLUMN_DEFS}
              gridOptions={GRID_OPTIONS}
              animateRows={true}
              data={claims}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              isRowSelectable={() => true}
              customClassName="ag-grid-interactive"
              enableRangeSelection={true}
              masterDetail={false}
              statusBar={statusBar}
              onCellClicked={onCellClicked}
              getRowId={getRowId}
              reactiveCustomComponents
            />
          )}
        </div>
      </div>
      {showDeleteConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title={`Delete ${selectedClaimsIds.length > 1 ? 'Claims' : 'Claim'}`}
          icon="new-document-remove-red"
          message={`Are you sure you want to delete ${selectedClaimsIds.length > 1 ? ' these claims' : 'this claim'}?`}
          loading={mutateDeleteClaim?.isLoading}
          handleContinue={handleDelete}
          handleOpen={showDeleteConfirmationModal}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
    </ErrorBoundary>
  );
}

export default NewPatientClaims;
