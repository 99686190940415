import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import PastMedicalHistory from '../../../../../../../shared/Forms/Custom/PastMedicalHistory/PastMedicalHistory';

const MedicalHistorySection = ({
  components,
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = []
}) => {
  const { clinicalNote } = useClinicalNoteContext();

  return (
    <Accordion
      updateFormType={{
        name: 'Medical History',
        parentKey: 'hp',
        childKey: 'medicalHistory'
      }}
      title={components?.medicalHistory?.title}
      id={components?.medicalHistory?.id}
      disabled={clinicalNote?.locked}>
      <PastMedicalHistory
        formId={formId}
        defaultValue={defaultValue}
        fromClinicalNote={true}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
        showNarrative
        setCurrentFormData={setCurrentFormData}
        options={options}
      />
    </Accordion>
  );
};

export default MedicalHistorySection;
