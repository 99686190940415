import * as Yup from 'yup';

const customValidationSchema = Yup.object().shape({
  customContact: Yup.string().when('contactType', {
    is: 'email',
    then: () => Yup.string().email('Invalid email address').required('Required'),
    otherwise: () =>
      Yup.string()
        .matches(/^[0-9]+$/, 'Invalid phone number')
        .required('Required')
  }),
  secondaryContact: Yup.string().when('contactType', {
    is: 'email',
    then: () => Yup.string().email('Invalid email address'),
    otherwise: () => Yup.string().matches(/^[0-9]+$/, 'Invalid phone number')
  })
});

export default customValidationSchema;
