import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { cols } from './helpers';
import AdminIntelligenceTasksTable from 'components/admin/pages/Intelligence/AiTask/table';
import AdminIntelligenceAiTaskModal from 'components/admin/pages/Intelligence/AiTask/modal';

const AdminIntelligenceAiTask = () => {
  return (
    <>
      <TableContextProvider
        name="admin_intelligence_ai_tasks_table"
        defaultFilters={[]}
        cols={cols}>
        <AdminIntelligenceTasksTable />
      </TableContextProvider>
      <AdminIntelligenceAiTaskModal />
    </>
  );
};

export default withErrorBoundary(AdminIntelligenceAiTask);
