import React from 'react';
import cs from 'classnames';
import { formatTimeRelativeToToday, snakeToTitleCase } from '../../../lib/helpers/utility';
import Skeleton from '../../shared/Skeleton/Skeleton';
import Icon from '../../shared/Icon/Icon';
import { tippy } from '@tippyjs/react';

const notificationIconType = (type) => {
  switch (type) {
    case 'treatment_plan':
      return 'new-document-sign';
    case 'clinical_note':
      return 'new-notev2';
    case 'e_fax':
      return 'clipboard-import';
    case 'e_fax_signature':
      return 'new-document-sign';
    default:
      return 'new-notifications';
  }
};

const SHOULD_PUSH_STATE = ['e_fax_signature'];

const NewNotification = ({
  newNotifications = [],
  practice,
  onNavigate,
  hasMore,
  scrollLoading,
  close
}) => {
  const handleStateFromNotification = (notification) => {
    if (SHOULD_PUSH_STATE.includes(notification.type)) {
      return { notification };
    }

    return null;
  };

  const handleMouseEnter = (content, idx) => {
    const notif = document.getElementById(`notif-${idx}`);
    if (notif && notif.offsetHeight < notif.scrollHeight) {
      tippy(notif, {
        content,
        hideOnClick: true
      }).show();
    }
  };

  const handleMouseLeave = (idx) => {
    const notif = document.getElementById(`notif-${idx}`);
    if (notif) {
      const instance = tippy(notif);
      if (instance) {
        instance.hide();
        instance.destroy();
      }
    }
  };

  return (
    <div>
      {newNotifications.map((notification, idx) => {
        return (
          <div
            key={idx}
            onClick={() =>
              onNavigate({
                link: notification.link,
                linkState: handleStateFromNotification(notification),
                notificationId: notification.id,
                closePopover: close
              })
            }
            className={cs(
              '!mr-2 !mt-2 flex cursor-pointer items-start gap-[14px] rounded-xl bg-neutral-50 !p-2 first:!mt-0 hover:bg-primary-50',
              !notification?.is_read && 'bg-primary-50'
            )}>
            <div className="inline-flex h-9 w-9 items-center justify-center rounded-[40px] bg-white p-1.5">
              <div className="flex h-6 w-6 justify-center">
                <Icon color="primary" icon={notificationIconType(notification.type)} size={36} />
              </div>
            </div>

            <div className="grid w-full items-center gap-[2px]">
              <div className="flex justify-between">
                <div className="flex items-center gap-1">
                  <p className="max-w-[110px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
                    {notification.type === 'e_fax'
                      ? notification.title
                      : snakeToTitleCase(notification.type)}
                  </p>
                </div>
                <p className="max-w-[70px] overflow-hidden text-ellipsis whitespace-nowrap text-xs leading-5 text-neutral-600">
                  {formatTimeRelativeToToday(notification.created_at, practice.timezone)}
                </p>
              </div>
              {notification.type === 'e_fax' ? (
                <>
                  <p
                    className="max-w-xs overflow-hidden text-xs text-neutral-600"
                    style={{
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2 // For two lines
                    }}>
                    From: {notification.meta?.faxNumber}
                  </p>
                  {notification.content && (
                    <p
                      className="max-w-xs overflow-hidden text-xs text-neutral-600"
                      style={{
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 2 // For two lines
                      }}>
                      Subject: {notification.content}
                    </p>
                  )}
                </>
              ) : (
                <p
                  id={`notif-${idx}`}
                  onMouseEnter={() => handleMouseEnter(notification.content, idx)}
                  onMouseLeave={() => handleMouseLeave(idx)}
                  className="max-w-xs overflow-hidden text-xs text-neutral-600"
                  style={{
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: 2 // For two lines
                  }}>
                  {notification.content}
                </p>
              )}
            </div>
          </div>
        );
      })}

      {hasMore && scrollLoading && (
        <Skeleton count={2} height={50} width="100%" className="!mt-2" />
      )}
    </div>
  );
};

export default NewNotification;
