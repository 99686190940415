import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import AddStaffContainer from '../../add_staff_container';
import { useOutletContext } from 'react-router-dom';

const InviteByEmail = forwardRef((props, ref) => {
  const { practice } = useOutletContext();
  const submitStaffRef = useRef();

  useImperativeHandle(ref, () => ({
    inviteOwner: () => {
      submitStaffRef?.current?.submitForm();
    }
  }));

  return (
    <AddStaffContainer
      practice_id={practice.id}
      practice={practice}
      submitStaffRef={submitStaffRef}
      handleSuccess={() => {}}
      handleClose={() => {}}
      role_list={practice.roles}
      forOwners={true}
    />
  );
});

export default InviteByEmail;
