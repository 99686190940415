import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import practiceState from 'components/state';
import { useFormik } from 'formik';
import { getFullAddress, ia, io, snakeToTitleCase } from 'lib/helpers/utility';
import { useOccupations } from 'lib/hooks/queries/useOccupations';
import { useStates } from 'lib/hooks/queries/useStates';
import { capitalize, isEqual, set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import EditDemographics from './EditDemographics';
import { formatCmsDateFields } from './lib/helpers';

const UpdatePatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  alt_email: Yup.string().email().nullable(),
  dob: Yup.string().required('Date of birth required'),
  custom_id: Yup.string()
    .matches(/^[a-zA-Z0-9!@#$%^&*)(]*$/g, 'Special Characters are not allowed')
    .nullable()
});

const Demographics = () => {
  const formRef = useRef();
  const { patient, setPatient, getPracticePatientData } = useOutletContext();
  const { data, isLoading: statesIsLoading } = useStates();
  const [loading, setLoading] = useState(false);
  const { data: occupationsData, isLoading: occupationsIsLoading } = useOccupations({
    returnAll: true
  });
  const states = data?.states;
  const occupations = occupationsData?.occupations;
  const [decodedSSN, setDecodedSSN] = useState(false);
  const [customChartId, setCustomChartId] = useState();
  const [practicePatientData, setPracticePatientData] = useState();
  const currPractice = useRecoilValue(currentPractice);
  const permissions = useRecoilValue(practiceState.permissions);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const address = patient?.userAddress;
  const secondaryAddress = patient?.secondaryUserAddress;
  const dateFields = formatCmsDateFields(patient?.cmsDateFieldPatient);

  const formik = useFormik({
    initialValues: {
      id: patient?.id,
      f_name: patient?.f_name,
      m_name: patient?.m_name,
      l_name: patient?.l_name,
      p_name: patient?.p_name,
      pronoun: patient?.pronoun,
      insurance_benefits: patient?.insurance_benefits,
      eligibility_limit_reached: patient?.eligibilityRequestReached,
      phone: patient?.phone,
      alt_phone: patient?.alt_phone,
      alt_phone_notify: patient?.alt_phone_notify,
      email: patient?.email,
      alt_email: patient?.alt_email,
      alt_email_notify: patient?.alt_email_notify,
      dob: patient?.dob,
      gender: patient?.gender,
      race: patient?.race,
      ssn: patient?.ssn,
      custom_id: customChartId,
      personal_id: patient?.personal_id,
      marital_status: patient?.marital_status,
      cmsDateFields: {
        lastseen_date: dateFields?.lastseen_date,
        chiro_manifest_date: dateFields?.chiro_manifest_date,
        accident_date: dateFields?.accident_date,
        initial_treatment_date: dateFields?.initial_treatment_date,
        last_menstrual_date: dateFields?.last_menstrual_date,
        chiro_xray_date: dateFields?.chiro_xray_date,
        assumed_care_date: dateFields?.assumed_care_date,
        relinquished_care_date: dateFields?.relinquished_care_date,
        lastworked_date: dateFields?.lastworked_date,
        disability_from_date: dateFields?.disability_from_date,
        disability_thru_date: dateFields?.disability_thru_date,
        hearingvision_prescription_date: dateFields?.hearingvision_prescription_date,
        returntowork_date: dateFields?.returntowork_date,
        firstcontact_date: dateFields?.firstcontact_date,
        pat_death_date: dateFields?.pat_death_date
      },
      address: {
        id: address?.id,
        zip: address?.zip,
        city: address?.city,
        state: address?.state,
        country: address?.country,
        address_ln_1: address?.address_ln_1,
        address_ln_2: address?.address_ln_2
      },
      secondary_address: {
        id: secondaryAddress?.id,
        zip: secondaryAddress?.zip,
        city: secondaryAddress?.city,
        state: secondaryAddress?.state,
        country: secondaryAddress?.country,
        address_ln_1: secondaryAddress?.address_ln_1,
        address_ln_2: secondaryAddress?.address_ln_2
      },
      emergency_contact: {
        name: patient?.emergency_contact?.name,
        phone: patient?.emergency_contact?.phone,
        relationship: patient?.emergency_contact?.relationship
      },
      secondary_emergency_contact: {
        name: patient?.secondary_emergency_contact?.name,
        phone: patient?.secondary_emergency_contact?.phone,
        relationship: patient?.secondary_emergency_contact?.relationship
      },
      occupation_id: patient?.occupation_id || patient?.occupation[0]?.id
    },
    enableReinitialize: true,
    validationSchema: UpdatePatientValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const patientToBeUpdated = {
        patient: {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          p_name: values?.p_name,
          pronoun: values?.pronoun,
          phone: values?.phone,
          alt_phone: values?.alt_phone,
          alt_phone_notify: values?.alt_phone_notify,
          email: values?.email,
          alt_email: values?.alt_email,
          alt_email_notify: values?.alt_email_notify,
          ssn: values?.ssn,
          dob: values?.dob,
          race: values?.race,
          gender: values?.gender,
          personal_id: values?.personal_id,
          occupation_id: values.occupation_id,
          marital_status: values?.marital_status,
          emergency_contact: values?.emergency_contact,
          secondary_emergency_contact: values?.secondary_emergency_contact,
          cmsDateFields: {
            lastseen_date: values?.lastseen_date,
            chiro_manifest_date: values?.chiro_manifest_date,
            accident_date: values?.accident_date,
            initial_treatment_date: values?.initial_treatment_date,
            last_menstrual_date: values?.last_menstrual_date,
            chiro_xray_date: values?.chiro_xray_date,
            assumed_care_date: values?.assumed_care_date,
            relinquished_care_date: values?.relinquished_care_date,
            lastworked_date: values?.lastworked_date,
            disability_from_date: values?.disability_from_date,
            disability_thru_date: values?.disability_thru_date,
            hearingvision_prescription_date: values?.hearingvision_prescription_date,
            returntowork_date: values?.returntowork_date,
            firstcontact_date: values?.firstcontact_date,
            pat_death_date: values?.pat_death_date
          }
        },
        address: {
          ...values?.address,
          full_street_address: getFullAddress({ address: values?.address, states })
        },
        secondary_address: {
          ...values?.secondary_address,
          full_street_address: getFullAddress({ address: values?.secondary_address, states }) || ''
        },
        custom_id: values?.custom_id
      };

      setSubmitting(true);

      await submitChanges(patientToBeUpdated);

      setSubmitting(false);
    }
  });

  useEffect(() => {
    if (permissions?.loaded) {
      getCustomChartId();
    }
  }, [permissions]);

  const getSecure = async () => {
    try {
      let data = await requestApi({
        url: '/api/user/get_secure',
        params: { id: patient?.id },
        navigate
      });
      const ssn = data?.secure?.ssn;

      if (data) {
        formik.setFieldValue('ssn', ssn);
        setDecodedSSN(true);
      } else {
        Honeybadger.notify(`getSecure patientID: ${patient?.id}`);
      }
    } catch (err) {
      Honeybadger.notify(`getSecure patientID: ${patient?.id}, error: ${err}`);
    }
  };

  const getCustomChartId = async () => {
    try {
      let data = await requestApi({
        url: '/api/practice/patient/read',
        params: { patient_id: patient?.id },
        navigate
      });

      const { practicePatientData } = data;

      if (data) {
        if (practicePatientData?.custom_id) {
          setCustomChartId(practicePatientData?.custom_id);
          formik.setFieldValue('custom_id', practicePatientData?.custom_id);
        }

        setPracticePatientData(practicePatientData);
      } else {
        Honeybadger.notify(`getCustomChartId patientID: ${patient?.id}`);
      }
    } catch (err) {
      Honeybadger.notify(`getCustomChartId patientID: ${patient?.id}, error: ${err}`);
    }
  };

  const submitChanges = async (patientToBeUpdated) => {
    setLoading(true);
    const {
      patient: updatedPatient,
      address: updatedAddress,
      secondary_address: updatedSecondaryAddress,
      custom_id
    } = patientToBeUpdated;

    if (custom_id !== customChartId) {
      try {
        const onSuccess = async () => {
          setCustomChartId(custom_id);
          showAlert({ title: 'Custom Id updated successfully!', color: 'success' });
        };
        const onError = (error) => {
          error
            ? showAlert({ title: error, color: 'danger' })
            : showAlert({ title: 'The custom ID was not updated successfully', color: 'danger' });

          Honeybadger.notify(
            `submitChanges, (custom_id), pid: ${updatedPatient.id}, error: ${data?.message}`
          );
        };
        requestApi({
          url: '/api/practice/patient/update',
          params: { user_id: updatedPatient.id, data: { custom_id } },
          navigate,
          onSuccess,
          onError
        });
      } catch (error) {
        Honeybadger.notify(
          `submitChanges, (custom_id), pid: ${updatedPatient.id}, err: ${data?.message}`
        );
      }
    }

    if (updatedPatient) {
      try {
        const changes = {};

        const values = updatedPatient;
        const initialValues = formik.initialValues;

        for (const key in values) {
          if (values[key] !== initialValues[key]) changes[key] = values[key];
        }

        if (io(changes)) {
          let params = { changes, user_id: updatedPatient.id };

          if (changes?.occupation_id) {
            params.occupation_id = changes?.occupation_id;

            delete changes?.occupation_id;
          }
          const onSuccess = async ({ holds, updated }) => {
            let message = '';

            if (ia(holds)) {
              let newErrors = {};
              holds.forEach((v) => {
                showAlert({
                  color: 'warning',
                  title: snakeToTitleCase(v?.field),
                  message: capitalize(v?.message)
                });
                set(newErrors, v?.field, capitalize(v?.message));
              });
              await formik.setErrors(newErrors);
              return;
            }

            if (updated) {
              showAlert({
                color: 'success',
                title: 'Demographics updated successfully!'
              });
              queryClient.invalidateQueries(['patient', patient?.id]);
              queryClient.invalidateQueries(['patients']);
            }
            if (message) {
              showAlert({
                color: 'warning',
                title: 'Some entries have not been saved',
                message
              });
            }
          };
          const onError = (error) => {
            showAlert({ message: error, color: 'danger' });
            Honeybadger.notify(
              `submitChanges, (updatedPatient), pid: ${updatedPatient.id}, error: ${error}`
            );
          };

          await requestApi({
            url: '/api/user/update',
            params,
            navigate,
            onSuccess,
            onError
          });
        }
      } catch (err) {
        Honeybadger.notify(`submitChanges (updatedPatient) pID: ${updatedPatient.id}, err: ${err}`);
      }
    }

    if (updatedAddress || updatedSecondaryAddress) {
      updateAddress('primary', updatedAddress);
      updateAddress('secondary', updatedSecondaryAddress);
    }
    setLoading(false);
  };

  const updateAddress = async (addressType, updatedAddress) => {
    try {
      const { address, secondary_address } = formik?.initialValues || {};
      const initialValues = addressType === 'primary' ? address : secondary_address;

      const addressChanged = !isEqual(
        { ...initialValues, full_street_address: updatedAddress.full_street_address },
        updatedAddress
      );

      if (!addressChanged) return;

      const onSuccess = () => {
        const addressKey = addressType === 'primary' ? 'userAddress' : 'secondaryUserAddress';
        queryClient.setQueryData(['patient', patient?.id], (data) => ({
          ...data,
          patient: {
            ...data.patient,
            [addressKey]: updatedAddress
          }
        }));
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
        Honeybadger.notify(`submitChanges (updateAddress), pID: ${patient?.id}, error: ${error}`);
      };

      await requestApi({
        url: '/api/address/patch',
        params: {
          loadedAddress: updatedAddress,
          userId: patient?.id,
          isSecondAddress: addressType === 'secondary'
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (err) {
      Honeybadger.notify(`submitChanges (updateAddress) pID: ${patient?.id}, error: ${err}`);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formRef.current) formik.submitForm();
  };

  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <EditDemographics
        formik={formik}
        formRef={formRef}
        patient={patient}
        getSSN={getSecure}
        stateList={states}
        decodedSSN={decodedSSN}
        currPractice={currPractice}
        handleSubmit={handleSubmit}
        occupationList={occupations}
        setDecodedSSN={setDecodedSSN}
        onChange={formik.handleChange}
        pharmacies={patient?.pharmacy}
        practice_state={permissions}
        practicePatientData={practicePatientData}
        insuranceProfiles={patient?.insuranceProfile}
        loading={loading}
      />
    </div>
  );
};

export default Demographics;
