import React, { useRef, useState } from 'react';
import cs from 'classnames';

const VerificationCode = ({ onComplete, digits = 6, status = 'pending', setStatus }) => {
  const [code, setCode] = useState(new Array(digits).fill(''));
  const inputRefs = useRef(new Array(digits).fill(null)); // Create refs for all inputs

  const handleChange = (value, index) => {
    setStatus && setStatus('pending');
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    const codeString = newCode.join('');
    if (codeString.length === digits) {
      onComplete && onComplete(codeString);
    }

    // Focus next input if the value is not empty
    if (value && index < digits - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (event, index) => {
    // Handle backspace for empty input (to move to previous input)
    if (event.key === 'Backspace' && !code[index] && index > 0) {
      const newCode = [...code];
      newCode[index - 1] = ''; // Clear the previous digit
      setCode(newCode);
      inputRefs.current[index - 1].focus();
    }
    // Handle arrow keys to move between inputs
    else if (event.key === 'ArrowLeft' && index > 0) {
      inputRefs.current[index - 1].focus();
    } else if (event.key === 'ArrowRight' && index < digits - 1) {
      inputRefs.current[index + 1].focus();
    } else {
      const cursor = event.target.selectionStart;
      if (cursor === 0) {
        inputRefs.current[index].select();
      } else if (index < digits - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();

    const paste = e.clipboardData.getData('text');

    const pasteArray = paste.split('').slice(0, digits);

    const newCode = [...code];
    let lastIndex = 0;

    pasteArray.forEach((char, index) => {
      if (index < digits) {
        newCode[index] = char;
        lastIndex = index;
      }
    });

    setCode(newCode);

    if (lastIndex === digits - 1) {
      onComplete && onComplete(newCode.join(''));
    }

    if (lastIndex < digits - 1) {
      inputRefs.current[lastIndex + 1].focus();
    }
  };

  return (
    <div className="flex justify-between">
      {code.map((digit, index) => (
        <input
          className={cs(
            '!w-14 !h-14 bg-gray-100 rounded-xl border border-gray-300 text-center text-2xl outline-none animate-pulse border-solid',
            digit && 'bg-primary-50 !border-primary-500 !border-b-2 text-primary-900',
            status === 'error' && '!border-red-500 !bg-red-50 !text-red-900',
            status === 'success' &&
            '!border-success-500 !bg-success-50 !text-success-900 opacity-[0.8]',
            status === 'loading' && 'animate-pulse'
          )}
          disabled={status === 'success'}
          type="tel"
          maxLength="1"
          key={index}
          onPaste={handlePaste}
          value={digit}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onFocus={(e) => e.target.select()}
          ref={(el) => (inputRefs.current[index] = el)} // Set the ref
        />
      ))}
    </div>
  );
};

export default VerificationCode;
