import { Transition } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import Tippy from '@tippyjs/react';
import { getLabOrder } from 'api/LabOrder';
import { getNonERXPrescription } from 'api/NonERXPrescription';
import { getReferralOrder } from 'api/ReferalOrder';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { assign } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Axios from '../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa, io } from '../../../../../../lib/helpers/utility';
import { showAlert as Alert, AlertContent } from '../../../../../shared/Alert/Alert';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../../shared/Icon/Icon';
import { userState, clinicalNote as clinicalNoteState } from '../../../../../state';
import { selectedInitialState } from '../lib/initials';
import { reorder, reorderChildren } from '../lib/renderedDragAndDropHelper';
import {
  checkIfAllFormTypesFalse,
  prepareSelection,
  processAdvancedCustomForms,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../lib/selectedHelper';
import DragDropContainer from './components/DragDropContainer';

const Selected = () => {
  const [isCheckAll, setIsCheckAll] = useState(true);
  const {
    currentHpOverviewData,
    vitals,
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    customFormTypes,
    selected,
    setSelected,
    selectedCustomFormTypes = {},
    overviewData
  } = useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { currentMyScribe } = useAmbientListeningContext();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState({
    forms: true
  });

  const [showAlert, setShowAlert] = useState(true);
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const apptID = appointmentId ?? clinicalNote?.appointment_id;

  const toggleExpand = (key) => {
    setSelected((prevData) => ({
      ...prevData,
      [key]: { ...prevData[key], expanded: !prevData[key].expanded || false }
    }));
  };

  const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
  const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);
  const getAdvancedFroms = Object.entries(customFormTypes || {}).map(([, object]) => {
    return processAdvancedCustomForms(object, cnDisplaySettings);
  });

  useEffect(() => {
    getSelected();
  }, [cnDisplaySettings, currentHpOverviewData, overviewData, currentMyScribe]);

  const getOrders = async () => {
    const promises = await Promise.all([
      await getReferralOrder(navigate, {
        clinicalNoteIds: [apptID]
      }),
      await getNonERXPrescription(navigate, {
        appointmentIds: [apptID]
      }),
      await getLabOrder(navigate, {
        appointmentIds: [apptID],
        withRelations: { provider: true, tests: true }
      })
    ]);

    return {
      referralsData: iaRa(promises?.[0]?.data?.referrals),
      nonERXPrescription: iaRa(promises?.[1]?.data?.nonERXPrescription),
      labOrder: iaRa(promises?.[2]?.data?.labOrder)
    };
  };

  const prepareSelectedData = async (useDefaultSelection = true) => {
    setLoading({ ...loading, forms: true });
    try {
      const { referralsData, nonERXPrescription, labOrder } = await getOrders();
      const processedAdvancedForms = assign({}, ...getAdvancedFroms);

      let selectedObj = {
        ...selectedInitialState(
          clinicalNote,
          cnDisplaySettings,
          currentHpOverviewData,
          vitals,
          currentMyScribe,
          referralsData,
          nonERXPrescription,
          labOrder,
          overviewData
        ),
        ...getAdvancedHP,
        ...getAdvancedSOAP,
        ...processedAdvancedForms
      };

      let componentsOrder = null;
      if (clinicalNote?.locked && io(clinicalNote?.components_order)) {
        componentsOrder = clinicalNote?.components_order;
      }

      let selection = null;
      if (useDefaultSelection) {
        const res = await Axios.post('/api/practice/medical_history/get_default_selection', {
          practitionerId: user?.user_id
        });
        selection = res.data.selection;
      }

      const result = prepareSelection({
        savedSelection: selection ? { ...selectedObj, ...selection } : null,
        initialSelection: selectedObj,
        componentsOrder,
        selectedCustomFormTypes
      });

      setSelected(result);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
    setLoading({ ...loading, forms: false });
  };

  const getSelected = async () => {
    await prepareSelectedData(true);
  };

  const resetSelected = async () => {
    await prepareSelectedData(false);
  };

  const saveSelected = async () => {
    try {
      let res = await Axios.post('/api/practice/medical_history/save_default_selection', {
        practitionerId: user?.user_id,
        selection: selected
      });
      if (res.data) {
        Alert({
          title: 'Final Note',
          message: 'The selected sections are saved!',
          color: 'success'
        });
      }
    } catch (error) {
      toast.error('There was an error while saving!', { duration: 3000 });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll((prevState) => !prevState);
    const updateSelected = { ...selected };

    Object.keys(updateSelected)
      .filter((key) => io(updateSelected[key]))
      .forEach((key) => {
        updateSelected[key] = {
          ...updateSelected[key],
          checked: !isCheckAll,
          ...(ia(updateSelected[key].formType) && {
            formType: updateSelected[key].formType.map((f) => ({
              ...f,
              checked: f.title === 'Narrative' ? !isCheckAll : false
            }))
          })
        };
      });

    setSelected(updateSelected);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updateSelected = reorder(selected, result.source.index, result.destination.index);
    setSelected(updateSelected);
  };

  const onDragEndChildren = (result) => {
    if (!result.destination) return;

    const updateSelected = reorderChildren(
      selected,
      result.source.index,
      result.destination.index,
      result.destination.droppableId
    );

    setSelected({ ...updateSelected });
  };

  const handleClick = (key) => {
    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        checked: !selected[key].checked
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'PARENT',
      key
    });

    setSelected(processedObject);
  };

  const handleClickDropdown = (key, dropdownId) => {
    const updatedFormTypes = selected[key].formType.map((type) => {
      if (type.id === dropdownId) return { ...type, checked: !type.checked };

      return type;
    });

    if (!ia(updatedFormTypes)) return;

    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        formType: updatedFormTypes
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'CHILDREN',
      key
    });

    setSelected(processedObject);
  };

  return (
    <div className="flex h-full flex-col bg-primary-10">
      {!clinicalNote?.locked && (
        <Transition
          as={Fragment}
          show={showAlert}
          enter="transition-opacity ease-all duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-all duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="px-3 pb-1 pt-3">
            <AlertContent
              width="full"
              title="If you save your current selection, it will be automatically loaded next time you visit this screen."
              handleClose={() => setShowAlert(false)}
            />
          </div>
        </Transition>
      )}
      <div className="flex items-center justify-between gap-x-2 !px-4 !pt-2">
        <p className="text-base text-neutral-800">Export forms</p>
        <div className="flex items-center gap-x-[4px]">
          <Icon
            icon="new-diskette"
            color="primary"
            shade={700}
            disabled={clinicalNote?.locked}
            onClick={() => saveSelected()}
            className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
          />
          <Tippy content="Reset selection" className="tippy-dark no-arrow">
            <div className="flex">
              <Icon
                icon="new-reset-neutral"
                color="primary"
                shade={700}
                stroke={true}
                disabled={clinicalNote?.locked || loading?.forms}
                onClick={resetSelected}
                className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
              />
            </div>
          </Tippy>
        </div>
      </div>

      <Checkbox
        label="Select all forms"
        onChange={handleSelectAll}
        isChecked={isCheckAll}
        disabled={clinicalNote?.locked}
        className="ml-[38px] flex w-full items-center rounded-[4px] pb-0"
        inputClassName="border-neutral-400"
        parentLabelClassName="px-4 py-[11px]"
        id="allForms"
      />

      <DragDropContainer
        onDragEnd={onDragEnd}
        onDragEndChildren={onDragEndChildren}
        loading={loading}
        toggleExpand={toggleExpand}
        handleClick={handleClick}
        handleClickDropdown={handleClickDropdown}
      />
    </div>
  );
};

export default Selected;
