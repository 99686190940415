import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useMyScribeAIContext } from '../MyScribeAIContext';
import { EnhanceNoteContextData, EnhanceNoteContextApi } from './EnhanceNoteContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useFilledAdvancedForms } from 'lib/hooks/queries/clinicalNotes/useFilledAdvancedForms';
import { useParams } from 'react-router-dom';
import { filterTypeOfNotes, getFinalAdvancedForms, getNonEmptyFields } from './lib/helpers';

export const EnhanceNoteContextProvider = ({ children }) => {
  const { typeOfNotes } = useMyScribeAIContext();
  const { appointmentId } = useParams();

  const [enhanceLoading, setEnhanceLoading] = useState(false);
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);
  const [nestedSelectedChatComponents, setNestedSelectedChatComponents] = useState([]);
  const [filledForms, setFilledForms] = useState();

  const componentRefs = useRef({});

  const { currentHpOverviewData, clinicalNote, advancedHP, advancedSOAP, customFormTypes } =
    useClinicalNoteContext() || {};

  const { data } = useFilledAdvancedForms({
    params: { appointmentId },
    dependencies: [appointmentId]
  });

  const filledAdvancedForms = data?.advancedForms;

  const customAdvancedForms = useMemo(
    () => Object.values(customFormTypes || {})?.flat(),
    [customFormTypes]
  );

  const advancedForms = useMemo(
    () => [...advancedHP, ...advancedSOAP, ...customAdvancedForms],
    [advancedHP, advancedSOAP, customAdvancedForms]
  );

  const finalFilledAdvancedForms = useMemo(
    () => getFinalAdvancedForms(filledAdvancedForms, advancedForms),
    [filledAdvancedForms, advancedForms]
  );

  const nonEmptyAdvancedFormsTitles = useMemo(
    () =>
      finalFilledAdvancedForms
        ?.filter((form) => form?.custom_title?.length > 0)
        ?.map((form) => form?.custom_title),
    [finalFilledAdvancedForms]
  );

  const clinicalNoteForms = useMemo(
    () => ({ ...clinicalNote, ...currentHpOverviewData }),
    [clinicalNote, currentHpOverviewData]
  );

  const nonEmptyFields = useMemo(() => getNonEmptyFields(clinicalNoteForms), [clinicalNoteForms]);
  const nonEmptyTitles = useMemo(() => Object.keys(nonEmptyFields || {}), [nonEmptyFields]);

  const initialFilledTypeOfNotes = useMemo(
    () =>
      filterTypeOfNotes(typeOfNotes, [...nonEmptyTitles, ...(nonEmptyAdvancedFormsTitles || [])]),
    [typeOfNotes, nonEmptyTitles, nonEmptyAdvancedFormsTitles]
  );

  const ctxData = useMemo(
    () => ({
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs
    }),
    [
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs
    ]
  );

  const api = useMemo(
    () => ({
      setSelectedChatComponents,
      setNestedSelectedChatComponents,
      setFilledForms,
      setEnhanceLoading
    }),
    []
  );

  useEffect(() => {
    if (!filledForms && Object.keys(initialFilledTypeOfNotes || {})?.length > 0) {
      setFilledForms(initialFilledTypeOfNotes);
    }
  }, [filledForms, initialFilledTypeOfNotes]);

  return (
    <EnhanceNoteContextData.Provider value={ctxData}>
      <EnhanceNoteContextApi.Provider value={api}>{children}</EnhanceNoteContextApi.Provider>
    </EnhanceNoteContextData.Provider>
  );
};
