import React from 'react';

import { formatDateAndTime, mString, formatTime } from '../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';

const ExportTable = ({ appointments }) => {
  const appointmentStatuses = useRecoilValue(aptStatuses);
  return (
    <table className="primary-table">
      <thead>
        <tr className="w-full">
          <th className="w-[30%]">Datetime</th>
          <th className="w-[20%]">Patient</th>
          <th className="w-[20%]">Service</th>
          <th>Balance</th>
          <th className="w-[20%]">Provider</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {appointments.map((appointment, i) => {
          const { starts_at, ends_at, patient, practitioner, status, invoice } = appointment || {};
          return (
            <tr key={i}>
              <td>{formatDateAndTime(starts_at) + ' - ' + formatTime(ends_at)}</td>
              <td>{patient?.fullName}</td>
              <td>{invoice?.procedures?.map?.((p) => p?.name).join(', ')}</td>
              <td>{mString(invoice?.balance || 0)}</td>
              <td>{practitioner?.fullName}</td>
              <td>{appointmentStatuses.find((st) => st.value === status)?.label}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;
