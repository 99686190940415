import React from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';

import { formatDate, iaRa, isEmpty } from 'lib/helpers/utility';
import { generateNarrativeIfEmpty } from '../../lib/generateCustomFormNarrativeIfEmpty';

const ListCustomForms = ({ forms }) => {
  return iaRa(forms)?.map((form, idx) => {
    const shouldShowDiv = idx === 0 ?? idx === forms?.length - 1;

    if (!form?.response) return null;

    return (
      <React.Fragment key={idx}>
        {!shouldShowDiv && (
          <div className="!my-2 border !border-b !border-t-0 border-solid !border-neutral-300"></div>
        )}

        <p className="!mb-2 text-sm font-500 text-neutral-900">{formatDate(form?.createdAt)}</p>

        <RenderNarrativeValue
          narrative={
            !isEmpty(form?.response?.narrative?.trim())
              ? form?.response?.narrative?.trim()
              : generateNarrativeIfEmpty({ form })
          }
        />
      </React.Fragment>
    );
  });
};

export default ListCustomForms;
