import React from 'react';

import cs from 'classnames';
import { useRecoilState } from 'recoil';

import { useGetPermissions } from 'lib/hooks/queries/permissions/usePermissions';

import { pm } from '../../../lib/helpers/utility';
import state from '../../state';
import { withErrorBoundary } from '../Error/Boundary';
import Icon from '../Icon/Icon';

const PermissionsAllowed = ({
  requiredPermissions = '',
  isAny = false,
  showMessage = false,
  showIllustration = false,
  customMessage = 'Necessary permissions not present!',
  description = 'You are not authorized to access this resource.',
  messageClassName = '',
  className = '',
  children
}) => {
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  useGetPermissions({
    options: {
      enabled: !permissions,
      onSuccess: (data) => {
        const newPermissions = data?.permissions;
        setPermissions({ ...newPermissions, loaded: true });
      }
    }
  });

  if (!requiredPermissions) {
    return children;
  }

  if (isAny && pm(permissions, requiredPermissions, 'or')) {
    return children;
  }

  if (pm(permissions, requiredPermissions)) {
    return children;
  }

  if (showMessage) {
    return (
      <>
        <div
          className={cs('flex h-full flex-col items-center justify-center text-center', className)}>
          {showIllustration && <Icon icon="no-permissions-illustration" />}
          <p
            className={cs(
              '!px-4 !pb-1 text-center text-base font-600 text-primary-900',
              messageClassName
            )}>
            {customMessage}
          </p>
          <p className="!px-4 text-center text-sm leading-6 text-neutral-600">{description}</p>
        </div>
      </>
    );
  }

  return null;
};

export default withErrorBoundary(PermissionsAllowed);
