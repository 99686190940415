import Honeybadger from '@honeybadger-io/js';
import reshapeFacilityProvideList from './reshapeFacilityProvideList';
import { requestApi } from 'api/Api';

export default async function getBillingProviders(superbill) {
  try {
    let res = await requestApi({
      url: '/api/practice/billing/get_facilities',
      params: {
        practitionerId: superbill?.practitioner?.id
      }
    });

    if (res.providers) {
      let facilityProvider = {
        facility_name: superbill?.claim?.facility_name,
        facility_addr_1: superbill?.claim?.facility_addr_1,
        facility_addr_2: superbill?.claim?.facility_addr_2,
        facility_city: superbill?.claim?.facility_city,
        facility_state: superbill?.claim?.facility_state,
        facility_zip: superbill?.claim?.facility_zip,
        facility_npi: superbill?.claim?.facility_npi,
        facility_id: superbill?.claim?.facility_id,
        label: superbill?.claim?.facility_name,
        value: superbill?.claim?.facility_id
      };
      let facilityProviderList = [];
      if (res.providers?.length > 1) {
        facilityProviderList = reshapeFacilityProvideList(res.providers);
      }
      return { facilityProvider, facilityProviderList };
    } else {
      Honeybadger.notify(`There's been an unexpected error, please try again later.`);
    }
  } catch (err) {
    console.log(err);
    Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
  }
}
