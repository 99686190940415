import React from 'react';
import { NavLink } from 'react-router-dom';
import cs from 'classnames';

const classStyle = 'rounded-full !py-2 !px-4 text-sm text-neutral-600 whitespace-nowrap';
const activeClassStyle =
  'rounded-full bg-gradient-to-r from-[#13B8FF] to-primary-200 !py-2 !px-4 text-sm font-600 text-white whitespace-nowrap !text-white';

const Tabs = ({ id }) => {
  return (
    <div className="relative shrink-0 overflow-hidden from-transparent to-white !px-10 !py-2">
      <div data-qa="basic-info" className={cs('flex w-full gap-4 overflow-x-auto')}>
        <NavLink
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}`}
          end>
          Basic Info
        </NavLink>
        <NavLink
          data-qa="patients"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}/patients`}>
          Patients
        </NavLink>
        <NavLink
          data-qa="ePrescribe"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}/eprescribe`}>
          ePrescribe
        </NavLink>
        <NavLink
          data-qa="practices"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}/practices`}>
          Practices
        </NavLink>
        <NavLink
          data-qa="logs"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}/logs`}>
          Access Logs
        </NavLink>
        <NavLink
          data-qa="settings"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practitioners/${id}/settings`}>
          Settings
        </NavLink>
      </div>
    </div>
  );
};

export default Tabs;