import { ia } from 'lib/helpers/utility';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function useReferralOrderQueryParamHandler({ referralsData, setNavigateToParams, handleOpen }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const openReferralOrderByQueryParams = () => {
      const documentId = queryParams.get('referral_order_id');
      if (documentId && ia(referralsData)) {
        const referral = referralsData.find((referral) => referral.id === Number(documentId));
        if (!referral?.id) {
          navigate(location.pathname);
          return;
        }

        setNavigateToParams({
          type: 'referral_order',
          itemProps: referralsData.find((referral) => referral.id === Number(documentId))
        });
        handleOpen();
        navigate(location.pathname);
      }
    };

    openReferralOrderByQueryParams();
  }, [queryParams, referralsData]);

  return null;
}
export default useReferralOrderQueryParamHandler;
