import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { requestApi } from 'api/Api';
import { genders, tax_id_types } from '/constants';
import { optionify } from '/lib/helpers/utility';
import AddStaff from './add_staff';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
const validator = require('validator');

const NewStaffValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  dob: Yup.string().required('Date of birth required'),
  email: Yup.string().required('Email required'),
  role: Yup.string().required('Role is  required'),
  gender: Yup.string().required('Gender required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value === undefined || value === null || value === '') {
      return createError({ message: 'Phone number required.' });
    }

    if (!validator.isMobilePhone(value)) {
      return createError({ message: 'Provide a correct phone number.' });
    }

    return true;
  })
});

export default function AddStaffContainer({
  practice_id,
  handleSuccess,
  handleClose,
  submitStaffRef,
  role_list: loaded_role_list,
  forOwners = false
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [role_list, setRoleList] = useState([]);
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      f_name: '',
      m_name: '',
      l_name: '',
      gender: '',
      dob: '',
      email: '',
      phone: '',
      role: '',
      type: 'staff',
      claim_md_user_id: '',
      npi: '',
      taxonomy: '',
      tax_id: '',
      tax_id_type: ''
    },
    validationSchema: NewStaffValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if (!forOwners && !values.role) {
        showAlert({
          color: 'danger',
          message: 'Role is required'
        });
        return;
      }
      const staffToBeCreated = {
        f_name: values.f_name,
        m_name: values.m_name,
        l_name: values.l_name,
        gender: values.gender,
        dob: values.dob,
        email: values.email,
        phone_number: values.phone,
        role: values.role,
        type: 'staff',
        claim_md_user_id: values.claim_md_user_id,
        npi: values.npi,
        taxonomy: values.taxonomy,
        tax_id: values.tax_id,
        tax_id_type: values.tax_id_type
      };

      setSubmitting(true);
      await submitSignup(staffToBeCreated, forOwners);
      setSubmitting(false);
      resetForm();
    }
  });

  useEffect(() => {
    if (practice_id !== 0 && (!Array.isArray(loaded_role_list) || loaded_role_list.length === 0)) {
      loadRoleList();
    } else {
      setRoleList(optionify(loaded_role_list, 'name', 'id'));
    }
  }, [practice_id, loaded_role_list]);

  const loadRoleList = async () => {
    try {
      let res = await requestApi({
        url: '/api/practice/role/get',
        params: {
          practice_id: parseInt(practice_id)
        },
        navigate
      });
      const { code, redirect, role_list: loaded_role_list, error } = res;
      switch (code) {
        case 0:
          setRoleList(optionify(loaded_role_list, 'name', 'id'));
          break;
        case 1:
          console.trace(`There's been an error loading role list - code ${code}`, error);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };
  const memberInviteEmailCase = (member, new_user_id, message) => {
    showAlert({
      title: 'This practice is not able to send emails.Try updating the settings',
      color: 'warning'
    });
    handleSuccess({ ...member, user_id: new_user_id });
    queryClient.invalidateQueries(['staffPracticeAdmin']);
    showAlert({ title: message, color: 'success' });
  };

  const submitSignup = async (member, forOwners) => {
    var params = {
      user: member,
      practice_id,
      role_id: member.role
    };
    try {
      let res = forOwners
        ? await requestApi({
            url: '/api/practice_owner/invite',
            params,
            navigate
          })
        : await requestApi({
            url: '/api/practice/member/invite',
            params,
            navigate
          });
      const { code, redirect, message, new_user_id, error } = res;

      const alertMessage = message
        ? message
        : `Successfully added ${member.f_name} ${member.l_name}`;

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          handleSuccess({ ...member, user_id: new_user_id });
          queryClient.invalidateQueries(['staffPracticeAdmin']);
          showAlert({ title: alertMessage, color: 'success' });
          break;
        case 1:
          console.error(error);
          forOwners
            ? showAlert({
                title: `An error has occurred while adding new member.`,
                color: 'danger'
              })
            : memberInviteEmailCase(member, new_user_id, alertMessage);
          Honeybadger.notify(`An error has occurred while adding new member: ${error}`);
          break;
        default:
          showAlert({
            title:
              error ||
              'An unexpected error has occurred while adding new member. Please try again later or contact support.',
            color: 'danger'
          });
          Honeybadger.notify(`An error has occurred while adding new member: ${error}`);
          break;
      }
    } catch (error) {
      showAlert({
        title: `An unexpected error has occurred while adding new member. Please try again later or contact support.`,
        color: 'danger'
      });
      Honeybadger.notify(`An error has occurred while adding new member: ${error}`);
      console.error(error);
    }
  };
  useImperativeHandle(submitStaffRef, () => ({
    submitForm: () => {
      formik.submitForm();
    },
    isLoading: formik.isSubmitting
  }));
  const handleSubmitNewStaff = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  return (
    <div className="w-[780px]">
      <AddStaff
        onChange={formik.handleChange}
        onSubmit={handleSubmitNewStaff}
        handleClose={handleClose}
        formRef={formRef}
        formik={formik}
        genderList={genders}
        roles={role_list}
        tax_id_types={tax_id_types}
        forOwners={forOwners}
      />
    </div>
  );
}
