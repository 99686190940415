import React, { useState } from 'react';

import { Html, Line } from '@react-three/drei';

import cs from 'classnames';
import Icon from '../../../../../../../shared/Icon/Icon';
import { useChart3dContext } from '../../../../../../../../lib/context/Chart3dContext/Chart3dContext';
import { showAlert } from '../../../../../../../shared/Alert/Alert';
import { isColorLight } from '../../../../../../../../lib/helpers/utility';

export const pointText = (type, point) => {
  switch (type) {
    default:
      return point.units;
  }
};

export default function Point({ point, index, preview }) {
  const {
    selected,
    setSelected,
    hovered,
    setHovered,
    setPoints,
    points,
    handleQuantity,
    setSelectedItem,
    items,
    drawing,
    setDrawing
  } = useChart3dContext();

  const isSelected = selected === index;
  const isHovering = hovered === index;
  const notSelected = (selected || selected === 0) && !isSelected;

  const [typeHovering, setTypeHovering] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setDrawing(false);
    setSelectedItem(point.product);
    setSelected(isSelected ? null : index);
  };

  const product = items?.find((p) => p.id === point.product);
  const color = product?.color;
  const type = product?.item_type;

  const isLight = isColorLight(color);

  const deletePoint = () => {
    handleQuantity(false, points[index].units);
    const newPoints = [...points];
    newPoints.splice(index, 1);
    setSelected(null);
    setHovered(null);
    return newPoints;
  };

  const addUnits = (e, add) => {
    e.stopPropagation();
    setPoints((points) => {
      var newPoints = [...points];
      if (newPoints[index].units > 1 || add) {
        if (handleQuantity(add)) {
          newPoints[index].units += add ? 1 : -1;
        }
      } else {
        if (add) {
          showAlert({ title: 'Not enough products!', color: 'danger' });
        } else {
          newPoints = deletePoint(e);
        }
      }

      point = newPoints[index];
      return newPoints;
    });
  };

  const toggleHover = (e, hover, type) => {
    e.stopPropagation();
    setTypeHovering(type);
    setHovered(hover ? index : null);
  };

  if (preview)
    return (
      <>
        {point?.draw &&
          point?.draw?.map(
            (line) => line?.length > 1 && <Line points={line} color={color} lineWidth={2}></Line>
          )}
        <mesh key={index} position={point.position}>
          <Html zIndexRange={[1, 2]}>
            <div
              style={{ pointerEvents: 'none', backgroundColor: color }}
              className={cs(
                'absolute translate-y-[-50%] translate-x-[-50%] rounded-full w-5 h-5 text-xs flex justify-center items-center font-bold',
                isLight ? '!text-black' : '!text-white'
              )}>
              {pointText(type, point)}
            </div>
          </Html>
        </mesh>
      </>
    );

  return (
    <>
      {point?.draw &&
        point?.draw?.map(
          (line) =>
            line?.length > 1 && (
              <Line points={line} color={color} lineWidth={isSelected ? 4 : 2}></Line>
            )
        )}
      <group position={point.position}>
        {isSelected ? (
          <mesh
            key={index + 'Selected'}
            onClick={handleClick}
            onPointerOver={(e) => toggleHover(e, true)}
            onPointerOut={(e) => toggleHover(e, false)}
            scale={0.05}>
            <Html distanceFactor={2.8} zIndexRange={[1, 2]}>
              {!drawing && (
                <div
                  style={{ pointerEvents: 'none' }}
                  className={cs(
                    'absolute translate-y-[-50%] translate-x-[-50%] transition-all rounded-full bg-[rgba(255,255,255,0.3)] border-white !border-dashed !border-1 w-7 h-7 aspect-square',
                    '!w-12 !h-12 !backdrop-blur-lg '
                  )}></div>
              )}
              <div
                style={{ pointerEvents: 'none', backgroundColor: color }}
                className={cs(
                  'absolute translate-y-[-50%] translate-x-[-50%] transition-all cursor-pointer rounded-full w-7 h-7 aspect-square flex justify-center items-center font-bold text-white border border-white !border-2',
                  isHovering && typeHovering == null && 'scale-[1.15]',
                  isLight ? '!text-black !border-black' : '!text-white',
                  drawing && '!w-4 !h-4 !text-xs !border-none'
                )}>
                {pointText(type, point)}
              </div>
            </Html>

            {!drawing && (
              <>
                <sphereGeometry args={[1, 32, 32]} />
                <meshBasicMaterial transparent opacity={0} />
              </>
            )}
          </mesh>
        ) : (
          <mesh
            key={index}
            onClick={handleClick}
            onPointerOver={(e) => toggleHover(e, true)}
            onPointerOut={(e) => toggleHover(e, false)}
            scale={0.06}>
            <Html zIndexRange={[1, 2]}>
              <div
                style={{ pointerEvents: 'none', backgroundColor: color }}
                className={cs(
                  `absolute translate-y-[-50%] translate-x-[-50%] transition-all cursor-pointer rounded-full w-6 h-6 aspect-square flex justify-center items-center font-bold border  !border-2`,
                  isHovering && 'scale-[1.15]',
                  notSelected && 'opacity-10 !w-5 !h-5 text-sm',
                  isLight ? '!text-black !border-black' : '!text-white !border-white'
                )}>
                {pointText(type, point)}
              </div>
            </Html>
            {!drawing && (
              <>
                <sphereGeometry args={[1, 32, 32]} />
                <meshBasicMaterial transparent opacity={0} />
              </>
            )}
          </mesh>
        )}

        {isSelected && !drawing && (
          <>
            <mesh
              key={index}
              onClick={(e) => addUnits(e, false)}
              position={[-0.12, 0, 0]}
              onPointerOver={(e) => toggleHover(e, true, 'minus')}
              onPointerOut={(e) => toggleHover(e, false, null)}
              scale={0.07}>
              <Html distanceFactor={2.8} zIndexRange={[2, 3]}>
                <div
                  style={{ pointerEvents: 'none' }}
                  className={cs(
                    'absolute translate-y-[-50%] translate-x-[-50%] transition-all cursor-pointer rounded-full w-5 h-5 aspect-square flex justify-center items-center font-bold text-white',
                    '!bg-white !text-white',
                    typeHovering === 'minus' && 'scale-[1.15] !bg-primary-50'
                  )}>
                  {point.units <= 1 ? (
                    <Icon icon="trash" color={'primary'} className={'scale-[0.8]'} />
                  ) : (
                    <Icon icon="new-minus" stroke color={'primary'} className={'scale-[0.8]'} />
                  )}
                </div>
              </Html>
              <sphereGeometry args={[1, 32, 32]} />
              <meshBasicMaterial transparent opacity={0} />
            </mesh>

          
              <mesh
                key={index}
                onClick={(e) => addUnits(e, true)}
                position={[0.12, 0, 0]}
                onPointerOver={(e) => toggleHover(e, true, 'plus')}
                onPointerOut={(e) => toggleHover(e, false, null)}
                scale={0.07}>
                <Html distanceFactor={2.8} zIndexRange={[2, 3]}>
                  <div
                    style={{ pointerEvents: 'none' }}
                    className={cs(
                      'absolute translate-y-[-50%] translate-x-[-50%] transition-all cursor-pointer rounded-full bg-red-600 w-5 h-5 aspect-square flex justify-center items-center font-bold text-white',
                      '!bg-white !text-white',
                      typeHovering === 'plus' && 'scale-[1.15] !bg-primary-50'
                    )}>
                    <Icon icon="new-plus" color={'primary'} className={'scale-[0.8]'} />
                  </div>
                </Html>
                <sphereGeometry args={[1, 32, 32]} />
                <meshBasicMaterial transparent opacity={0} />
              </mesh>
            
          </>
        )}
      </group>
    </>
  );
}
