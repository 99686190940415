import { useEffect } from 'react';

import { useField, useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import { reShapePractitioners } from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import Select from 'components/shared/Select/Select';
import { userState } from 'components/state';

const OrderCollector = ({ className }) => {
  const [field, meta, { setValue }] = useField({
    name: 'orderCollector'
  });

  const user = useRecoilValue(userState);
  const { data: practitionersList } = usePractitioners();
  const practitioners = practitionersList?.practitioners;
  const options = reShapePractitioners(practitioners);

  const { values } = useFormikContext();

  useEffect(() => {
    if (!field.value?.value) {
      const currentCollector = options.find((p) => p?.value === user?.user_id);
      if (currentCollector) setValue(currentCollector);
    }
  }, [practitionersList]);

  return (
    <Select
      {...field}
      error={meta.touched && meta.error}
      isClearable={false}
      onChange={(opt) => setValue(opt)}
      isImage
      label="Collector"
      required
      options={options}
      inputId="orderCollectorSelect"
      data-qa="order-collector-select"
      parentClassName={className}
      placeholder="Type and select collector"
      disabled={!values.drawAtServiceCenter}
    />
  );
};

export default OrderCollector;
