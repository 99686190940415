import React, { useState, useMemo, useEffect } from 'react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import _ from 'lodash';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import usePageTitle from 'lib/hooks/usePageTitle';
import Popup from 'components/shared/Filters/Popup';
import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { useNavigate } from 'react-router-dom';
import Input from 'components/shared/Input/Input';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteIcd10Code } from 'api/Icd10';
import { showAlert } from 'components/shared/Alert/Alert';
import NewAndUpdateICD10 from './NewAndUpdate';
import Header from 'components/shared/Header/Header';

export default function ICD10Codes() {
  const [deleteICD10CodeModal, setDeleteICD10CodeModal] = useState(null);
  const [selectedICD10Code, setSelectedICD10Code] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState('create');

  const Actions = ({ data }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <div
                data-qa="update"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  close();
                  setSelectedICD10Code(data);
                  setShowModal(true);
                  setMode('update');
                }}>
                <Icon icon="new-mask" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Update</div>
              </div>
              <div
                data-qa="delete"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  close();
                  setSelectedICD10Code(data);
                  setDeleteICD10CodeModal(true);
                }}>
                <Icon icon="trash" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Delete</div>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  };
  const cols = [
    {
      field: 'code',
      headerName: 'Code',
      maxWidth: 150
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 300
    },
    {
      field: 'common_tags',
      headerName: 'Common Tags'
    },
    {
      field: 'actions',
      headerName: '',
      maxWidth: 100,
      minWidth: 100,
      resizable: false,
      cellRenderer: Actions
    }
  ];

  return (
    <>
      <TableContextProvider name="icd10" pagination cols={cols}>
        <Table
          setShowModal={setShowModal}
          setMode={setMode}
          selectedICD10Code={selectedICD10Code}
          deleteICD10CodeModal={deleteICD10CodeModal}
          setDeleteICD10CodeModal={setDeleteICD10CodeModal}
        />
      </TableContextProvider>

      {!!showModal && (
        <NewAndUpdateICD10
          mode={mode}
          selectedICD10Code={selectedICD10Code}
          hideModal={() => setShowModal(false)}
          showModal={!!showModal}
        />
      )}
    </>
  );
}

function Table({
  selectedICD10Code,
  setMode,
  setShowModal,
  deleteICD10CodeModal,
  setDeleteICD10CodeModal
}) {
  usePageTitle('ICD10 Codes');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const queryClient = useQueryClient();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const navigate = useNavigate();

  const {
    data = {},
    isLoading,
    isFetching
  } = useICD10Codes({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit, withCount: true },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });
  const icd10Codes = data?.icd10 || [];
  const mutateDelete = useMutation({
    mutationFn: () => deleteIcd10Code(navigate, { code: selectedICD10Code.code }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['icd10Codes']);
          setDeleteICD10CodeModal(null);
          showAlert({ title: 'ICD10 Code deleted successfully!', color: 'success' });
          break;
        default:
          showAlert({ title: 'Failed to delete ICD10 Code.', color: 'danger' });
          break;
      }
    }
  });
  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <Header title="ICD10 Codes">
        <div className="!mt-2 flex items-center gap-2">
          <DisplayButton />
          <Input
            data-qa="search-icd-codes"
            type="string"
            placeholder="Search icd10 codes"
            value={searchTerm}
            icon="new-search"
            inputWrapperClassName="h-[30px]"
            className="md:w-[200px]"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
          <Button
            data-qa="new-staff-member-btn"
            size="small"
            type="primary"
            text="New ICD10 Code"
            onClick={() => {
              setShowModal(true);
              setMode('create');
            }}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col  !p-3 !pb-2">
        <div className="ag-theme-quartz !mb-0 h-full">
          <AGTable data={icd10Codes} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.icd10Count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.icd10Count > page * limit ? page * limit : data?.icd10Count} out of{' '}
              {data?.icd10Count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.icd10Count}
            page={page}
          />
          {!!deleteICD10CodeModal && (
            <Confirm
              variant="danger"
              primaryBtnTxt="Delete"
              title="Delete ICD10 Code"
              icon="new-document-remove-red"
              message="Are you sure you want to delete this ICD10 Code?"
              handleContinue={() => {
                mutateDelete.mutate();
                setDeleteICD10CodeModal(false);
              }}
              handleOpen={!!deleteICD10CodeModal}
              handleClose={() => setDeleteICD10CodeModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
