import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';

import { updateProfilePicture } from 'api/Patients';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { calculateAge, formatDate } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Avatar from 'components/shared/Avatar/Avatar';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const PatientProfile = ({ patient = {} }) => {
  const { device } = useUIContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = (data) => mutateProfilePicture.mutate(data);
    filestackClient.picker(pickerOptions).open();
  };

  const mutateProfilePicture = useMutation({
    mutationFn: (data) =>
      updateProfilePicture(navigate, {
        handle: { jpg: data.filesUploaded[0].handle },
        userId: patient.id
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(['patient', patient?.id]);
      showAlert({ message: 'Profile picture updated successfully!' });
    }
  });

  return (
    <div className="flex items-center gap-x-3 gap-y-4 sm:gap-x-2 md:gap-x-2">
      <div onClick={() => openFilestackPicker()}>
        <Avatar
          fullName={patient.fullName}
          id={patient.id}
          image={patient.profile_photo}
          size={device === 'desktop' || device === 'laptop' ? '2xl' : 'xl'}
        />
      </div>
      {device === 'mobile' ? (
        <div className="flex max-w-[120px] flex-col">
          <div className="flex">
            <Tippy
              className="tippy-dark"
              content={`${patient.f_name} ${patient.l_name}`}
              placement="bottom">
              <p
                className="overflow-hidden text-ellipsis whitespace-nowrap text-lg font-500 text-primary-900 sm:text-base md:text-base"
                data-dd-privacy="allow"
                data-public>
                {`${patient.f_name} ${patient.l_name}`}
              </p>
            </Tippy>
          </div>
          <div className="flex gap-1">
            <p className="whitespace-nowrap text-xs text-neutral-800">
              {`${formatDate(patient?.dob)}
                  (${calculateAge({ dateOfBirth: patient?.dob })} years old)`}
            </p>
          </div>
          <div className="flex">
            <Tippy
              className="tippy-dark"
              content={`${patient?.p_name ? `${patient.p_name}` : ''}`}
              placement="bottom">
              <p
                className="overflow-hidden text-ellipsis whitespace-nowrap text-[10px] font-500 text-primary-900"
                data-dd-privacy="allow"
                data-public>
                {`${patient?.p_name ? `(${patient.p_name})` : ''}`}
              </p>
            </Tippy>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <p
            className="w-max text-lg font-500 text-primary-900 sm:text-base md:text-base"
            data-dd-privacy="allow"
            data-public>
            {`${patient.f_name} ${patient.l_name} ${patient?.p_name ? `(${patient.p_name})` : ''}`}
          </p>
          <div className="flex gap-1">
            <p className="whitespace-nowrap text-xs text-neutral-800">
              {`${formatDate(patient?.dob)}
                  (${calculateAge({ dateOfBirth: patient?.dob })} years old)`}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(PatientProfile);
