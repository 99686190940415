import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { groupAndSort, snakeToTitleCase } from 'lib/helpers/utility';

import Avatar from 'components/shared/Avatar/Avatar';

import { getFirstName, getLastName } from '../lib/getName';

const AllAvatars = ({
  items,
  size,
  fontSize,
  itemSize,
  itemsToShow = 4,
  isCompact,
  placement,
  itemsGroupBy,
  itemsGroupLabelClassName
}) => {
  const remainingCount = items.length - itemsToShow;

  if (remainingCount <= 0) return null;

  const groupedItems = groupAndSort(items, itemsGroupBy);

  return (
    <Tippy
      className="custom-tooltip py-2 w-max max-w-[300px]"
      animation="fade"
      interactive={true}
      arrow={false}
      placement={placement}
      content={
        <div className="max-h-[400px] overflow-y-auto flex flex-col gap-1 mr-1">
          {groupedItems.map(([groupName, groupItems], groupIndex) => (
            <div key={groupIndex}>
              {itemsGroupBy && (
                <span
                  className={cs(
                    'px-4 py-1 text-xs font-500 leading-[14px] text-primary-500',
                    itemsGroupLabelClassName
                  )}>
                  {snakeToTitleCase(groupName)}
                </span>
              )}
              {groupItems.map((item, index) => (
                <div key={index} className="flex items-center gap-2 pl-4 pr-3 py-1 select-none">
                  <Avatar
                    size="large"
                    fontSize="text-xs"
                    firstName={getFirstName(item)}
                    lastName={getLastName(item)}
                    image={item.image}
                    id={item.id}
                    isSquare={false}
                  />
                  <div className="flex flex-col overflow-hidden">
                    <span
                      className="text-sm text-neutral-800 leading-[18px] font-500 line-clamp-1"
                      title={`${getFirstName(item)} ${getLastName(item)}`}>
                      {getFirstName(item)} {getLastName(item)}
                    </span>
                    <p
                      className="text-xs font-400 text-neutral-700 leading-[14px] line-clamp-2"
                      title={item.description}>
                      {item.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      }>
      <div
        className={cs(
          'flex items-center justify-center rounded-full',
          !isCompact && 'bg-neutral-50 outline-white outline-none outline-offset-0',
          size === 'small' || size === 'medium' ? 'ml-1' : 'ml-[6px]'
        )}
        style={{
          width: itemSize,
          height: itemSize,
          marginLeft: !isCompact && -itemSize / 4, // add margin based on the size of the avatar
          zIndex: itemsToShow + 1
        }}>
        <span className={cs('font-600 text-neutral-700 flex items-center', fontSize)}>
          <span className="leading-3 mb-[2px] ">+</span>
          <span>{remainingCount}</span>
        </span>
      </div>
    </Tippy>
  );
};

export default AllAvatars;
