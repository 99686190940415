import React, { useEffect, useState } from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { sanitize } from 'lib/helpers/sanitize';
import {
  cmToInch,
  formatDateAndTimeSeconds,
  ia,
  io,
  separateFeetAndInches,
  separatePoundsAndOunces,
  stripHtmlTags
} from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';

const Vitals = ({ customClinicalNote = null }) => {
  const { currentHpOverviewData, overviewData } = customClinicalNote ?? useClinicalNoteContext();
  const [vitals, setVitals] = useState(currentHpOverviewData?.vitals ?? overviewData?.vitals);
  const practice = useRecoilValue(currentPractice);

  useEffect(() => {
    setVitals(currentHpOverviewData?.vitals ?? overviewData?.vitals);
  }, [currentHpOverviewData?.vitals, overviewData?.vitals]);

  if (customClinicalNote && !io(vitals)) {
    return null;
  }

  const renderVital = (vital) => {
    const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);
    const { feet, inches } = separateFeetAndInches(cmToInch(vital?.height));

    const bloodPressure =
      vital?.blood_pressure_systolic || vital?.blood_pressure_diastolic
        ? `${vital?.blood_pressure_systolic || ''} / ${vital?.blood_pressure_diastolic || ''}`
        : '';

    const weight = pounds || ounces ? `${pounds || ''} lb ${ounces || ''} oz` : '';
    const height = feet || inches ? `${feet || ''} ft ${inches || ''} in` : '';

    const vitalMapping = [
      { label: 'Blood pressure', value: bloodPressure, unit: 'mmHg' },
      { label: 'Heart rate', value: vital?.heart_rate, unit: 'bpm' },
      { label: 'Respirations', value: vital?.respirations, unit: 'bpm' },
      { label: 'Weight', value: weight },
      { label: 'Temperature', value: vital?.temperature, unit: '°F' },
      { label: 'Height', value: height },
      { label: 'Bmi', value: vital?.bmi, unit: 'kgm²' },
      { label: 'Oxygen level', value: vital?.oxygen_level, unit: '%' },
      {
        label: 'Note',
        value: stripHtmlTags(vital?.notes),
        colSpan: true,
        customStyling: 'break-all',
        sanitized: true
      }
    ];
    return (
      <div className="grid grid-cols-[repeat(auto-fill,minmax(90px,1fr))] gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0 print:grid-cols-6 print:gap-x-6">
        {vitalMapping.map((v, idx) =>
          v.value && v.value !== 'null' && v.value !== '' ? (
            <div className={cs('grid', v?.colSpan && 'col-span-full')} key={idx}>
              <p className="text-sm font-500 text-neutral-700 print:text-base">{v.label}</p>
              {v.sanitized ? (
                <p
                  className={cs(
                    'text-sm font-500 text-[#003A4B] first-letter:capitalize print:text-base',
                    v?.customStyling
                  )}
                  dangerouslySetInnerHTML={sanitize(vital?.notes)}></p>
              ) : (
                <p
                  className={cs(
                    'text-sm font-500 text-[#003A4B] first-letter:capitalize print:text-base',
                    v?.customStyling
                  )}>
                  {v.value} {v.unit || ''}
                </p>
              )}
            </div>
          ) : null
        )}
      </div>
    );
  };

  return (
    <div className="bg-white !px-6 !py-5">
      <h3 className="mb-5 text-base font-500 text-[#003A4B] print:text-xl">Vitals</h3>
      {ia(vitals?.vitals) ? (
        vitals?.vitals.map((vital, idx) => (
          <div key={idx}>
            <h4 className="text-sm font-500 text-[#003A4B] print:text-lg">
              Vital Set (
              {formatDateAndTimeSeconds(
                vital?.timestamp,
                practice?.timezone,
                null,
                'MM/DD/YYYY hh:mm:ss A z'
              )}
              )
            </h4>
            {renderVital(vital)}
          </div>
        ))
      ) : (
        <p className="!text-sm font-500 text-[#003A4B] print:text-base">No vitals data available</p>
      )}
    </div>
  );
};

export default Vitals;
