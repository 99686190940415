import React, { useEffect } from 'react';
import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { ia, iaRa, isEmpty } from '../../../../../../../../../lib/helpers/utility';
import { formatSeconds } from '../../lib/helpers';

const predefinedClassNames = [
  '!text-primary-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-primary-100',
  '!text-red-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-red-200 !rounded-md bg-red-100',
  '!text-primary-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-green-100'
];

const ViewTranscript = ({
  part = null,
  transcriptData = {},
  speakerClassNames = {},
  setSpeakerClassNames = () => null
}) => {
  const data = transcriptData?.transcript_enhanced;

  useEffect(() => {
    setSpeakerClassNames((prevState) => ({
      ...prevState,
      ...assignSpeakerClassNames({ data: transcriptData?.speakers })
    }));
  }, [transcriptData?.speakers]);

  const sentenceCheck = (sentences) => {
    if (isEmpty(sentences)) {
      return (
        <p className="inline-block text-sm font-500 text-danger-600">
          Something went wrong while recording the conversation.
        </p>
      );
    }

    return sentences;
  };

  return iaRa(data).map((row, i) => {
    const sentenceText = sentenceCheck(row?.merged_sentences);

    const formattedStart = formatSeconds({ seconds: row.start });

    const currentSpeaker =
      typeof row?.speaker === 'number' ? `Speaker ${row?.speaker}` : row?.speaker;

    const className = speakerClassNames[currentSpeaker];

    return (
      <div key={i} className="first-of-type:!mt-0">
        <div
          className={cs(
            'inline-block w-full items-start gap-1.5 self-center !bg-transparent bg-white !p-3 text-primary-900'
          )}>
          <Tippy
            content={part ? `Part ${part} - ${formattedStart}` : formattedStart}
            className="tippy-dark">
            <span
              className={cs(
                '!w-[186px] !text-center',
                className?.speaker ?? predefinedClassNames[0]
              )}>
              {currentSpeaker}
            </span>
          </Tippy>

          <span className={cs(className?.sentence, '!whitespace-pre-wrap')}>{sentenceText}</span>
        </div>
      </div>
    );
  });
};

const assignSpeakerClassNames = ({ data = [] }) => {
  const predefinedClassNames = [
    '!text-primary-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-primary-100',
    '!text-primary-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-white',
    '!text-red-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-red-200 !rounded-md bg-red-100',
    '!text-primary-700 font-500 text-sm !px-2 !py-[2px] !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-green-100'
  ];

  let newSpeakerClassNames = {};

  data.forEach((row) => {
    if (!newSpeakerClassNames[row?.new_name]) {
      switch (row?.new_name) {
        case 'Speaker 1':
        case 'Patient':
          newSpeakerClassNames[row?.new_name] = {
            speaker:
              '!text-primary-600 font-500 text-sm !px-2 !py-1 !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-white',
            sentence: ''
          };
          break;

        case 'Speaker 0':
        case 'Provider':
          newSpeakerClassNames[row?.new_name] = {
            speaker:
              '!text-primary-600 font-500 text-sm !px-2 !py-1 !mr-1.5 border border-solid !border-primary-200 !rounded-md bg-primary-50',
            sentence: 'text-primary-500 font-500'
          };
          break;

        default:
          const randomIndex = Math.floor(Math.random() * predefinedClassNames.length);
          newSpeakerClassNames[row?.new_name] = {
            speaker: predefinedClassNames[randomIndex],
            sentence: ''
          };
          break;
      }
    }
  });

  return newSpeakerClassNames;
};

export default ViewTranscript;
