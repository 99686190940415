import React, { memo, useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';

import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import { ia } from 'lib/helpers/utility';

import EmptyTableState from '../../../MyScribeTemplate/components/EmptyTableComponent';
import { getHeaderButtons, updateHiddenComponents } from '../../../shared/lib/helpers';

import List from './List';

const TypeOfNotesSidebar = () => {
  const [typeOfNotes, setTypeOfNotes] = useState([]);
  const { typeOfNotes: ctxTypeOfNotes } = useMyScribeAIContext();
  const { chatForms, setChatForms, setManualTemplateSettings, resetSettings } =
    useMyTemplateContext();

  const selectedTypeOfNotes = Object.values(chatForms || {})
    ?.flat()
    ?.filter((item) => item.checked);

  useEffect(() => {
    setTypeOfNotes(Object.values(ctxTypeOfNotes || {}));
  }, [ctxTypeOfNotes]);

  const onSelectTypeOfNotes = (checked, note) => {
    const newChatForms = {
      ...chatForms,
      [note?.type]: checked
        ? {
            ...chatForms?.[note?.type],
            checked
          }
        : ctxTypeOfNotes[note?.type]
    };

    const updatedFormsWithHiddenComponents = updateHiddenComponents(newChatForms);

    setChatForms(updatedFormsWithHiddenComponents);
  };

  const headerButtons = getHeaderButtons({
    typeOfNotes,
    onSelectTypeOfNotes,
    forms: chatForms,
    setSettings: setManualTemplateSettings,
    resetSettings,
    template: 'manual_template'
  });

  return (
    <div className="relative z-30 h-full w-[264px] shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 bg-white shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200">
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              <Tippy content={button.tooltip} placement="top" className="tippy-dark">
                {button.component}
              </Tippy>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[calc(100vh-3rem)] overflow-y-auto pb-28">
        {ia(selectedTypeOfNotes) ? (
          selectedTypeOfNotes?.map((selectedNote) => (
            <div key={selectedNote.type}>
              <div
                className="relative flex items-center gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 font-500 text-primary-600 transition-all
        ">
                {selectedNote?.label}
              </div>
              <List open={true} type={selectedNote.type} />
            </div>
          ))
        ) : (
          <EmptyTableState className="flex items-center justify-center">
            <EmptyTableState.Icon icon="new-no-data" />
            <div>
              <h3 className="text-center text-lg text-primary-900">No Forms Found!</h3>
              <p className="text-center text-sm text-neutral-600">
                You will see the list of forms once you have at least one form on.
              </p>
            </div>
          </EmptyTableState>
        )}
      </div>
    </div>
  );
};

export default memo(TypeOfNotesSidebar);
