/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 face_female.glb 
*/

import React from 'react';
import { useGLTF } from '@react-three/drei';

export function FaceFemale(props) {
  const { nodes, materials } = useGLTF(
    `${process.env.ENV !== 'local' ? '/public' : ''}/models/face_female.glb`
  );
  return (
    <group {...props} dispose={null}>
      <group position={[0.027, 0.368, 0.237]} scale={10.094}>
        <mesh geometry={nodes.Mesh455.geometry} material={materials['body_skin.002']} />
        <mesh geometry={nodes.Mesh455_1.geometry} material={materials['head_skin.002']} />
        <mesh geometry={nodes.Mesh455_2.geometry} material={materials['eye2.003']} />
      </group>
    </group>
  );
}
