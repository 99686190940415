export const textBoldAndUnderline = ({ text = null }) => {
  if (!text) return '';

  return `<strong><u>${text}</u></strong>`;
};

export const textItalic = ({ text = null }) => {
  if (!text) return '';

  return `<i>${text}</i>`;
};
