import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation, useOutletContext } from 'react-router-dom';
import PatientOverview from '../../../CurrentNote/PatientOverview/PatientOverview';
import Chat from './components/chat/Chat';
import Button from 'components/shared/Buttons/Button';
import Sidebar from 'components/practice/charts/ClinicalNote/Sidebar/Sidebar';
import { ChatContextProvider } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContextProvider';
import PromptsSidebar from './components/PromptsSidebar';
import { MyScribeTemplateContextProvider } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContextProvider';
import {
  useMyScribeTemplateContextApi,
  useMyScribeTemplateContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import { intelligenceAiState } from 'components/state';
import { useRecoilValue } from 'recoil';

const MyScribeTemplateMainView = () => {
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;
  const { openSidebar, setOpenSidebar } = useOutletContext();
  const intelligenceAi = useRecoilValue(intelligenceAiState);

  const { setCurrentActivePrompt, setLocalPrompts } = useMyScribeTemplateContextApi();
  const { localPrompts, currentActivePrompt } = useMyScribeTemplateContextData();

  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Map Templates with MyScribe',
      name: '/map-template-with-myscribe',
      path: pathname
    });
  }, [breadcrumbs]);

  useEffect(() => {
    if (intelligenceAi?.response) {
      setCurrentActivePrompt({
        ...currentActivePrompt,
        response: intelligenceAi?.response
      });
    }
    return () => {
      setCurrentActivePrompt(null);
    };
  }, [intelligenceAi?.response, setCurrentActivePrompt]);

  const addNewPrompt = () => {
    const newPrompt = {
      promptTemplate: {
        icon: 'new-icon',
        body: '',
        template_name: 'New Note'
      }
    };
    if (localPrompts?.length > 0 && !localPrompts?.[0]?.id) {
      return;
    }
    setLocalPrompts((prevPrompts) => [newPrompt, ...prevPrompts]);
    setIsLeftSideOpen(true);
    setCurrentActivePrompt(null);
  };

  return (
    <>
      <div className="relative border-b border-gray-300 p-2 shadow-sm">
        <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      </div>
      <div className="flex h-screen w-full">
        <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200">
          <PromptsSidebar
            className="relative h-full"
            isOpen={isLeftSideOpen}
            setIsOpen={setIsLeftSideOpen}
          />
        </div>
        <div className="flex w-full">
          {!isLeftSideOpen && (
            <div className="flex h-[52px] items-center bg-primary-50 py-2">
              <Button
                onClick={() => setIsLeftSideOpen(true)}
                icon="new-hamburger"
                className="z-30 m-1 !py-1"
                outlined
              />

              <Button
                onClick={addNewPrompt}
                icon="comment-plus"
                className="z-30 m-1 !py-1"
                outlined
              />
            </div>
          )}
          <div
            className={`flex h-[calc(100vh-50px)] w-full ${isLeftSideOpen && openSidebar && 'md:w-[calc(100%-350px)] lg:w-[calc(100%-300px)]'} flex-col ${openSidebar && !isLeftSideOpen ? 'md:w-[calc(100%-350px)] lg:w-[calc(100%-300px)]' : 'w-full'} `}>
            <PatientOverview />
            <ChatContextProvider>
              <Chat />
            </ChatContextProvider>
          </div>

          {openSidebar && (
            <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 duration-200">
              <Sidebar handleSidebar={{ openSidebar, setOpenSidebar }} />;
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const MyScribeTemplate = () => {
  return (
    <MyScribeTemplateContextProvider>
      <MyScribeTemplateMainView />
    </MyScribeTemplateContextProvider>
  );
};

export default MyScribeTemplate;
