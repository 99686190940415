import React from 'react';
import Button from 'components/shared/Buttons/Button';
import PastImports from './PastImports';

const PastImportsWrapper = ({ show, setShow, id }) => {
  return (
    <div className="mx-auto my-auto flex h-fit w-fit flex-col justify-center overflow-hidden rounded-lg border-none bg-white p-2 transition duration-300">
      <Button
        text="Import Files"
        onClick={() => setShow({ ...show, stage: 'uploadFile' })}
        color="primary"
      />
      <div className="h-60vh w-50vw flex min-h-[20rem] min-w-[20rem] flex-col overflow-auto">
        <PastImports practiceId={id} />
      </div>
    </div>
  );
};

export default PastImportsWrapper;
