import { Popover } from '@headlessui/react';
import React, { useRef, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import toast from 'react-hot-toast';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { requestApi } from 'api/Api';
import { interimApi } from 'api/InterimApi';
import FaxDocsActions from 'components/practice/charts/Documents/components/FaxDocsActions';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { documentCategories } from 'constants.js';
import { formatDateAndTime } from 'lib/helpers/utility';

const initConfirmation = { delete: false, share: false };

const List = ({
  loading,
  documents,
  setViewDocument,
  getDocuments,
  totalDocuments,
  filters,
  setFilters,
  isEditOpen,
  setIsEditOpen,
  fromPatient,
  patientId
}) => {
  const exportRef = useRef([]);
  const navigate = useNavigate();

  const practice = useRecoilValue(currentPractice);

  const [currentFile, setCurrentFile] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(initConfirmation);

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  const handleDownload = async ({ type, handle, title }) => {
    try {
      if (type !== 'form') {
        const res = await interimApi('/api/filestack/download', { handle, patientId }, navigate);

        const { file } = res.data;

        if (!file) toast.error('There was a problem downloading the document.');

        getBase64FromUrl(file).then((url) => {
          const downloadLink = document.createElement('a');

          if (type === 'image/jpeg' || type === 'image/png') {
            const element = document.createElement('img');
            element.src = url;
            downloadLink.appendChild(element);
          }

          downloadLink.href = url;
          downloadLink.download = title;

          downloadLink.click();
        });
      } else {
        toast.error('There was a problem downloading the document.');
      }
    } catch (error) {
      toast.error('There was a problem downloading the document.');
      console.error(error);
    }
  };

  const handleShareWithPatient = async ({ type, id }) => {
    try {
      const { success, email } = await requestApi({
        navigate,
        params: { patientId, type, id },
        url: '/api/practice/charts/documents/share_with_patient'
      });

      const message = success
        ? `Document shared with patient successfully${!email ? ', but the email failed' : ''}.`
        : 'There was a problem sharing the document with the patient.';

      const color = success ? 'success' : 'danger';

      showAlert({ message, color });

      if (success) setShowConfirmation(initConfirmation);
    } catch (error) {
      showAlert({
        message: 'There was a problem sharing the document with the patient.',
        color: 'danger'
      });
    }
  };

  const handleDeletion = async ({ type, id }) => {
    try {
      let data;

      switch (type) {
        case 'filestack':
          data = (
            await interimApi('/api/practice/charts/documents/delete', { handle: id }, navigate)
          ).data;
          break;

        case 'form':
          data = (await interimApi('/api/form/delete_response', { id }, navigate)).data;
          break;

        default:
          toast.error('There was a problem while deleting document.');
          break;
      }

      if (data?.data?.deleted || data?.data > 0) {
        toast.success('Document deleted successfully.');
        setShowConfirmation(initConfirmation);
        getDocuments();
      } else {
        toast.error('There was a problem while deleting document.');
      }
    } catch (error) {
      toast.error('There was a problem while deleting document.');
      console.error(error);
    }
  };

  const handleConfirmationFile = ({ id, type, mode }) => {
    setCurrentFile({ id, type });
    setShowConfirmation({ ...initConfirmation, [mode]: true });
  };

  const handleClose = () => {
    setCurrentFile(null);
    setShowConfirmation(initConfirmation);
  };

  const changePage = ({ selected }) => {
    setFilters((prev) => ({ ...prev, page: selected + 1 }));
  };

  const requestSort = (column) => {
    let sortBy = 'ASC';

    if (filters?.sort?.column === column && filters?.sort?.sortBy === 'ASC') sortBy = 'DESC';

    setFilters((prev) => ({ ...prev, sort: { column, sortBy } }));
  };

  const renderSortArrow = (column) => {
    const { sort } = filters;

    if (sort?.column === column && sort?.sortBy === 'ASC') {
      return <Icon icon="next" />;
    } else if (sort?.column === column && sort?.sortBy === 'DESC') {
      return <Icon icon="back" />;
    } else {
      return '';
    }
  };

  const pageCount = Math.ceil(totalDocuments / filters.limit);

  return (
    <>
      <table className="w-full">
        <thead>
          <tr>
            <td onClick={() => requestSort('name')}>
              <div>Document Name {renderSortArrow('name')}</div>
            </td>
            <td>
              <div className="!cursor-default">Date of Service</div>
            </td>
            <td onClick={() => requestSort('category')}>
              <div>Document Category {renderSortArrow('category')}</div>
            </td>
            {!fromPatient && (
              <td onClick={() => requestSort('uploader_name')}>
                <div>Uploaded by {renderSortArrow('uploader_name')}</div>
              </td>
            )}
            <td onClick={() => requestSort('created_at')}>
              <div>Document Date {renderSortArrow('created_at')}</div>
            </td>
            <td>Actions</td>
          </tr>
        </thead>

        <tbody>
          {loading ? (
            <tr style={{ backgroundColor: '#fff' }}>
              <td colSpan="5">
                <Skeleton count={15} />
              </td>
            </tr>
          ) : documents.length > 0 ? (
            documents.map((document, i) => {
              const {
                id,
                form_id,
                appointment_id,
                mimetype,
                uploader_name,
                json,
                form_json,
                form_version,
                created_at,
                date_of_service
              } = document;

              let form;

              if (form_id) {
                form = {
                  response_id: id,
                  appointment_id,
                  form_id,
                  json,
                  form_json,
                  form_version
                };
              }

              const cat = documentCategories.filter(
                (cat) => cat?.value == document?.category?.trim()
              )[0];

              const documentDOS = date_of_service
                ? formatDateAndTime(date_of_service, practice?.timezone)
                : 'Not linked with an appointment';

              return (
                <tr key={id}>
                  <td>{document?.name || document?.form?.name}</td>
                  <td>{documentDOS}</td>
                  <td className="capitalize">{cat?.label || document?.category || ''}</td>
                  {!fromPatient && <td>{uploader_name || ''}</td>}
                  <td>{formatDateAndTime(created_at, practice?.timezone)}</td>
                  {cat?.label === 'eFax' ? (
                    <FaxDocsActions
                      fax={document}
                      fromPatient={fromPatient}
                      currentFile={currentFile}
                      handleConfirmationFile={handleConfirmationFile}
                    />
                  ) : (
                    <td>
                      <Popover className="relative">
                        {({ open, close }) => (
                          <div>
                            <Popover.Button
                              className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                                !open && 'hover:!bg-white'
                              } ${open && '!bg-primary-700 transition-all'}`}>
                              <Icon
                                icon="three-dots"
                                className="flex cursor-pointer"
                                color={open ? 'white' : 'black'}
                              />
                            </Popover.Button>

                            <Popover.Panel className="absolute bottom-10  right-0 z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
                              <Allowed
                                requiredPermissions={
                                  fromPatient ? '' : 'patient_documents_center.read'
                                }>
                                <div
                                  data-qa="view-btn"
                                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                  onClick={() => {
                                    close();
                                    setViewDocument({
                                      load: true,
                                      title: document?.name,
                                      handle: document?.document_handle || form,
                                      type: mimetype || 'form'
                                    });
                                  }}>
                                  <Icon icon="new-eye" color="primary" />
                                  <div className="ml-1 text-sm text-primary-900">View</div>
                                </div>
                              </Allowed>

                              <Allowed requiredPermissions="patient_documents_center.update">
                                <div
                                  data-qa="edit-btn"
                                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                  onClick={() => {
                                    document?.form_id && setIsEditOpen(true);
                                    close();
                                    setViewDocument({
                                      load: true,
                                      title: document?.name,
                                      handle: document?.document_handle || form,
                                      type: mimetype || 'form',
                                      action: 'Edit',
                                      category: cat
                                    });
                                  }}>
                                  <Icon icon="new-edit" color="primary" />
                                  <div className="ml-1 text-sm text-primary-900">Edit</div>
                                </div>
                              </Allowed>

                              <Allowed requiredPermissions="patient_documents_center.delete">
                                <div
                                  data-qa="delete-btn"
                                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                  onClick={() => {
                                    close();
                                    handleConfirmationFile({
                                      mode: 'delete',
                                      id: document?.document_handle || document?.id,
                                      type: document?.form_id ? 'form' : 'filestack'
                                    });
                                  }}>
                                  <Icon icon="trash" color="primary" />
                                  <div className="ml-1 text-sm text-primary-900">Delete</div>
                                </div>
                              </Allowed>

                              <Allowed
                                requiredPermissions={
                                  fromPatient ? '' : 'patient_documents_center.read'
                                }>
                                {document?.form_id && !isEditOpen ? (
                                  <>
                                    <div
                                      className="PrintForm print:!bg-white"
                                      ref={(e) => (exportRef.current[i] = e)}
                                      style={{ display: 'none' }}>
                                      <ReactFormGenerator
                                        answer_data={
                                          form?.json?.fields ? JSON.parse(form?.json?.fields) : {}
                                        }
                                        data={form?.form_json?.fields}
                                        submitButton={<div />}
                                        read_only={true}
                                      />
                                    </div>

                                    <ReactToPrint
                                      trigger={() => (
                                        <div
                                          data-qa="download-btn"
                                          className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50">
                                          <Icon icon="new-chevron-down" color="primary" />
                                          <div className="ml-1 text-sm text-primary-900">
                                            Download
                                          </div>
                                        </div>
                                      )}
                                      content={() => exportRef.current[i]}
                                    />
                                  </>
                                ) : (
                                  <div
                                    data-qa="download-btn"
                                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                    onClick={() => {
                                      close();
                                      handleDownload({
                                        title: document?.name,
                                        handle: document?.document_handle || form,
                                        type: mimetype || 'form'
                                      });
                                    }}>
                                    <Icon icon="new-chevron-down" color="primary" />
                                    <div className="ml-1 text-sm text-primary-900">Download</div>
                                  </div>
                                )}
                              </Allowed>

                              {!fromPatient && (
                                <Allowed requiredPermissions="patient_communication.create">
                                  <div
                                    data-qa="delete-btn"
                                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                    onClick={() => {
                                      close();
                                      handleConfirmationFile({
                                        mode: 'share',
                                        id: document?.id,
                                        type: document?.form_id ? 'form' : 'document'
                                      });
                                    }}>
                                    <Icon icon="new-arrow-swap-down" color="primary" />
                                    <div className="ml-1 text-sm text-primary-900">
                                      Share with patient
                                    </div>
                                  </div>
                                </Allowed>
                              )}
                            </Popover.Panel>
                          </div>
                        )}
                      </Popover>
                    </td>
                  )}
                </tr>
              );
            })
          ) : (
            <tr style={{ backgroundColor: '#fff' }}>
              <td colSpan="5" className="text-center text-sm">
                <p>No documents found.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {pageCount > 0 && (
        <ReactPaginate
          previousLabel={
            <div className="mr-2 flex items-center">
              <Icon icon="new-chevron-left" color="neutral" stroke size={20} />
              <span>Prev</span>
            </div>
          }
          nextLabel={
            <div className="flex items-center">
              <span>Next</span>
              <Icon icon="new-chevron-right" color="neutral" stroke size={20} />
            </div>
          }
          pageCount={pageCount}
          onPageChange={changePage}
          forcePage={Number(filters?.page) - 1}
          containerClassName="Pagination items-center"
        />
      )}

      <Confirm
        variant="danger"
        secondaryBtnTxt="Keep"
        title="Delete document"
        handleClose={handleClose}
        icon="new-document-remove-red"
        primaryBtnTxt="Delete document"
        handleOpen={showConfirmation?.delete}
        handleContinue={() => handleDeletion(currentFile)}
        message="Are you sure you want to delete the document?"
      />

      <Confirm
        title="Share document"
        secondaryBtnTxt="Cancel"
        handleClose={handleClose}
        icon="new-arrow-swap-down"
        primaryBtnTxt="Share document"
        handleOpen={showConfirmation?.share}
        handleContinue={() => handleShareWithPatient(currentFile)}
        message="Are you sure you want to share the document with patient?"
      />
    </>
  );
};

export default List;
