import { useLocation } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createTreatmentPlan } from 'api/TreatmentPlan';

import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';

const useCreateTP = ({
  id,
  patient,
  selectedTreatmentPlan,
  setSelectedTreatmentPlan,
  clinicalNote,
  navigate,
  outsideCN = false
}) => {
  const queryClient = useQueryClient();
  const params = { patientId: id, withRelations: { createdBy: true } };
  const location = useLocation();
  const fromPracticeIQueue = location?.pathname?.includes('practice-i-queue/treatment-plans');

  const mutation = useMutation(
    (data) =>
      createTreatmentPlan(navigate, {
        ...data,
        patient_id: id,
        procedureData: ia(clinicalNote.cpt_codes)
          ? clinicalNote.cpt_codes.map((row) => row.code?.value).filter(Boolean)
          : [],
        payerData: ia(patient.insuranceProfile)
          ? patient.insuranceProfile.map((row) => row?.payer_id).filter(Boolean)
          : []
      }),
    {
      onSuccess: ({ data, code }) => {
        if (code === 0) {
          const createdTreatmentPlanId = data?.id;
          showAlert({
            title: 'Treatment plan',
            message: 'Treatment plan created successfully!',
            color: 'success'
          });
          if (outsideCN && !fromPracticeIQueue) {
            queryClient.invalidateQueries(['treatmentPlan', [params]]);
          } else {
            queryClient.invalidateQueries('treatmentPlan');
          }
          setSelectedTreatmentPlan({ ...selectedTreatmentPlan, id: createdTreatmentPlanId });
        } else if (code === 1) {
          showAlert({
            title: 'There was a problem creating your treatment plan.'
          });
        }
      }
    }
  );

  return mutation;
};

export default useCreateTP;
