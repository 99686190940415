import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import { permissions } from 'components/state';
import { emptyStateMessages } from '../lib/emptyStateMessages';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';
import { DEFAULT_COLUMN_DEFS, getColDefs, GRID_OPTIONS } from './lib/config';
import NotFound from 'components/practice/NewAppointment/components/NotFound';

const SelectedItems = ({ type, list, isClinicalNoteLocked = false }) => {
  const { initialValues, values, setFieldValue } = useFormikContext();
  const userPermissions = useRecoilValue(permissions);
  const hasPaymentPermission = userPermissions?.payments?.read;
  const hasBillingPermission = userPermissions?.billing?.read;

  const isServices = type === 'procedures';
  const selectedItems = [...(values[type] || [])];

  const originalProceduresProducts = {
    products: initialValues?.invoice?.products || [],
    procedures: initialValues?.invoice?.procedures || []
  };

  const EmptyData = () => {
    const { title, startDesc, icon } = emptyStateMessages(isServices);
    return <NotFound icon={icon} title={title} className="mt-12" description={startDesc} />;
  };

  return (
    <div className="-ml-4 h-[266px] overflow-auto pl-4">
      <AGBasicTable
        rowData={selectedItems}
        gridOptions={GRID_OPTIONS}
        noRowsOverlayComponent={EmptyData}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDefs={getColDefs(type, hasPaymentPermission, hasBillingPermission)}
        context={{
          type,
          list,
          setFieldValue,
          selectedItems,
          isClinicalNoteLocked,
          originalProceduresProducts
        }}
      />
    </div>
  );
};

export default SelectedItems;
