import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const EmptyView = () => {
  return (
    <div className="mb-[100px] flex h-full flex-grow flex-col items-center overflow-y-auto pt-[150px]">
      <Icon icon="choose-enhance-note" />
      <h2 className="mb-4 text-xl text-[#004F6B]">Select notes that you would like to enhance</h2>
      <h4 className="mb-4 text-base text-[#6D828B]">
        You can start creating new clinical notes on chart details.
      </h4>
    </div>
  );
};

export default EmptyView;
