const { requestApi } = require('../../api/Api');

module.exports = {
  getPracticeInfo: async ({ navigate, setPractice, practice, filePath, pathname }) => {
    if (practice?.name || pathname.includes('/kiosk/activate')) {
      return;
    }
    const onSuccess = (data) => {
      // set practice info
      setPractice(data?.practice);
    };
    await requestApi({ url: '/api/kiosk/practice', onSuccess, navigate, filePath });
  }
};
