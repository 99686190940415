import { useEffect, useState } from 'react';

const useKeyboardStatus = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);
  const [initialHeight, setInitialHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      // Check if the keyboard is open by comparing the current height to the initial height
      if (window.innerHeight < initialHeight - 30) {
        setIsKeyboardOpen(true);
      } else {
        setIsKeyboardOpen(false);
      }
    };

    // Set the initial height only when the component mounts
    setInitialHeight(window.innerHeight);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isKeyboardOpen;
};

export default useKeyboardStatus;
