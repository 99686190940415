import { showAlert } from '../components/shared/Alert/Alert';
import { requestApi } from './Api';

export const getProducts = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/product/get',
    params,
    onError: () => {}
  });

export const getProductCategories = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/product/category/get',
    params,
    onError: () => {}
  });
