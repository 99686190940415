import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteContact } from 'api/Contacts';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';

import ContactModal from './Modal';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();

  let options = [
    {
      label: 'Edit',
      onClick: () => {
        setShowEditModal(true);
      },
      icon: 'new-edit'
    },
    { label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }
  ];

  const { mutateAsync: deleteContactMutation } = useCustomMutation({
    mutationFn: (id) => deleteContact(navigate, id),
    invalidateQueryKey: 'contacts',
    successMessage: 'Contact Deleted Successfully',
    errorMessage: 'Something went wrong deleting the contact!'
  });

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <ContactModal item={data} isOpen={showEditModal} handleClose={onEditModalToggle} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete contact"
          title="Delete Contact"
          icon="new-calendar-red"
          message="Are you sure you want to delete this contact?"
          handleOpen={showDeleteModal}
          handleContinue={() => deleteContactMutation(data.id)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
