import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getSignatures } from 'api/Document';

export const useSignatures = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['signatures', ...dependencies], () => getSignatures(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
