import { useQuery } from '@tanstack/react-query';
import { getDashboardWidget } from 'api/Widgets';
import { useNavigate } from 'react-router-dom';

export const useDashboard = (params = {}, options = {}) => {
  const navigate = useNavigate();
  const { startAt, endAt } = params;

  return useQuery(['dashboard', startAt, endAt], () => getDashboardWidget(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
