import React from 'react';
import CheckinStepsStep from './Step';

const CheckinStepsPayment = (props) => {
  return <CheckinStepsStep {...props} />;
  // return (
  //   <div
  //     key={`checkin-step-payment-${index}`}
  //     className="flex cursor-pointer items-center"
  //     onClick={() => setShowPreAuthModal(true)}>
  //     {step.completed ? (
  //       <Icon icon="checkmark-circle" color={'primary'} />
  //     ) : (
  //       <Icon icon="new-close-circle" />
  //     )}
  //     <p className="!mx-2 text-sm font-400">{stepName(step)}</p>
  //   </div>
  // );
};

export default CheckinStepsPayment;
