import React, { useImperativeHandle, useRef } from 'react';
import Textarea from '../../../../shared/Textarea/Textarea';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

const GeneralNote = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const generalNoteRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { notes: clinicalNote?.notes }
  }));

  const handleChange = (e) => {
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      notes: value ?? e
    });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write general notes here"
      id="notes"
      data-qa="general-notes"
      name="notes"
      className="min-h-[200px]"
      value={clinicalNote?.notes}
      onChange={(e) => handleChange(e)}
      transcribing
      forwardedRef={generalNoteRef}
      disabled={clinicalNote?.locked || intelligenceAi.loading}
    />
  );
};

export default GeneralNote;
