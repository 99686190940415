import React from 'react';
import { useLocation } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { ia } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';
import Popover from '../Popovers/Popover/Popover';
import Transcribe from '../Transcribe/Transcribe';

const Header = ({
  isHeader = true,
  headerClassName,
  label,
  labelClassName,
  additionalComponents,
  transcribing,
  icon,
  options,
  isOptional,
  disabled,
  value,
  onChange,
  setDisabled = () => {},
  name,
  id,
  required,
  tooltip,
  tooltipIcon = 'new-info-v2',
  isTooltipDark = true
}) => {
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');

  if (
    !(
      isHeader &&
      (label ||
        isOptional ||
        icon ||
        tooltip ||
        additionalComponents ||
        (transcribing && !fromCheckin) ||
        options)
    )
  )
    return null;

  return (
    <div className={cs('flex min-h-[28px] items-center justify-between pb-1', headerClassName)}>
      <div className="flex w-full items-center gap-x-1">
        {label && (
          <label
            className={cs(
              'm-0 text-sm font-500 first-letter:capitalize',
              disabled ? 'text-neutral-400' : 'text-neutral-800',
              labelClassName
            )}
            htmlFor={id}>
            {label}
            {required && <span className="text-danger-400">*</span>}
          </label>
        )}
        {isOptional && (
          <span className={cs('text-sm', disabled ? 'text-neutral-300' : 'text-neutral-600')}>
            (Optional)
          </span>
        )}
        {tooltip && (
          <Tippy content={<span>{tooltip}</span>} className={isTooltipDark && 'tippy-dark'} arrow>
            <div className="flex">
              <Icon icon={tooltipIcon} />
            </div>
          </Tippy>
        )}
        <div className="ml-auto flex items-center gap-x-1">{additionalComponents}</div>
      </div>
      <div className="ml-auto flex items-center gap-x-1">
        {transcribing && !fromCheckin && (
          <Transcribe
            value={value}
            setValue={(text) =>
              onChange({
                target: {
                  value: text,
                  name
                }
              })
            }
            setDisable={setDisabled}
            disabled={disabled}
          />
        )}
        {typeof icon === 'object'
          ? icon
          : icon && (
              <Icon
                icon={icon}
                color={disabled && 'neutral'}
                size={16}
                className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
              />
            )}
        {ia(options) && (
          <Popover
            options={options}
            icon="new-candle"
            position="left"
            buttonClassName="!min-h-6 !min-w-6 !rounded-[3px]"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
