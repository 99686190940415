import cs from 'classnames';
import ProviderWeightLoss from 'components/shared/Forms/Custom/Provider/WeightLoss/ProviderWeightLoss';
import React from 'react';
import Accordion from '../../../../shared/Accordion';
import WeightLossAdministration from '../../../../../../../shared/Forms/Custom/WeightLossAdministration/WeightLossAdministration';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const WeightLossAdministrationSection = ({
  formId,
  defaultValue,
  hpNoteOverviewRef,
  customFormsParams,
  setCurrentFormData,
  options = []
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-256px)] text-sm text-neutral-600 duration-200">
        <ProviderWeightLoss
          formId={formId}
          defaultValue={defaultValue}
          hpNoteOverviewRef={hpNoteOverviewRef}
          customFormsParams={customFormsParams}
          setCurrentFormData={setCurrentFormData}
          options={options}
        />
      </div>
    </div>
  );
};

export default WeightLossAdministrationSection;
