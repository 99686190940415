export const handleDiscountChange = ({
  idx,
  invoices,
  newBalance,
  setInvoice,
  setInvoices,
  discountType,
  calculateTotalAmount
}) => {
  // discount.value is only for currencyInput

  const updatedInvoices = invoices.map((invoice, index) => {
    if (index === idx) {
      let balance;

      const { total_amount, amount_paid, discount, discountAmount } = invoice || {};

      const currentBalance = total_amount - amount_paid;

      const discountValue = +(newBalance ?? discount?.value ?? discount?.amount_cents ?? 0);

      const newDiscountType = discountType || 'amount';

      const discountObj = { amount_cents: discountValue, type: newDiscountType };

      if (newDiscountType === 'percent') {
        balance = currentBalance - (currentBalance * discountValue) / 100 / 100;

        discountObj.value = newBalance;
        discountObj.amount_cents = Math.round((currentBalance * newBalance) / 100 / 100);

        if (!newBalance) {
          balance = currentBalance - discountValue;

          discountObj.amount_cents = Math.round(discountValue);
          discountObj.value = ((discountValue * 100) / currentBalance) * 100;
        }
      } else if (newDiscountType === 'amount') {
        balance = currentBalance - discountValue;
        discountObj.value = newBalance;

        if (!newBalance) {
          const amountCents = (currentBalance * discountValue) / 100 / 100;

          balance = currentBalance - amountCents;

          discountObj.value = amountCents;
          discountObj.amount_cents = Math.round(amountCents);
        }
      }

      const discountUpdated = discountObj?.amount_cents !== discountAmount;

      setInvoice && setInvoice((prev) => ({ ...prev, discount: discountObj }));

      return {
        ...invoice,
        discountUpdated,
        discount: discountObj,
        balance: balance + (invoice?.newTax ?? invoice?.tax ?? 0)
      };
    } else {
      return invoice;
    }
  });

  setInvoices && setInvoices(updatedInvoices);
  calculateTotalAmount && calculateTotalAmount(updatedInvoices);
};

export const handleTaxChange = ({ idx, value, invoices, setInvoices, calculateTotalAmount }) => {
  const updatedInvoices = invoices.map((invoice, index) => {
    const { total_amount, amount_paid, discount } = invoice || {};

    const currentBalance = total_amount - amount_paid - (discount?.amount_cents || 0) + +value;

    if (index === idx) {
      return { ...invoice, newTax: +value, balance: currentBalance };
    } else {
      return invoice;
    }
  });

  setInvoices && setInvoices(updatedInvoices);
  calculateTotalAmount && calculateTotalAmount(updatedInvoices);
};
