import { ia, isEmpty } from 'lib/helpers/utility';
import React from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const ReviewOfSystems = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const ros = clinicalNote?.ros?.practitioner;
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !ia(ros)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Review of systems</h3>
      {isTemplateChecked && (
        <div className="flex flex-wrap !gap-5 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-2 first-of-type:!border-t-0">
          {clinicalNote?.ros?.noDetails ? (
            <p className="text-xs text-neutral-700 first-letter:capitalize">No details</p>
          ) : (
            <>
              {ia(ros) &&
                ros?.map((block) => {
                  const hasCheckedSymptom = block.row.some((symptom) => symptom.checked);
                  if (block.noBlock || !hasCheckedSymptom) return null;

                  return (
                    <div
                      key={block.title}
                      className={
                        ('!basis-40', block.title === 'Psychiatric' && `order-${block.row.length}`)
                      }>
                      {hasCheckedSymptom && (
                        <p className="text-xs text-neutral-700 first-letter:capitalize">
                          {block.title}
                        </p>
                      )}
                      <ul className="p-0">
                        {block.row.map((symptom) => {
                          if (!symptom.checked) return null;
                          return (
                            <li key={symptom.name} className="w-max list-inside list-disc !pl-4">
                              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                                {symptom.name}
                              </p>
                              {symptom.when && (
                                <div className="flex items-center gap-x-1 !pl-6">
                                  <p className="text-xs text-neutral-700 first-letter:capitalize">
                                    When:
                                  </p>
                                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                                    {symptom.when.value}
                                  </p>
                                </div>
                              )}
                              {symptom.where && (
                                <div className="flex items-center gap-x-1 !pl-6">
                                  <p className="text-xs text-neutral-700 first-letter:capitalize">
                                    Where:
                                  </p>
                                  <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                                    {symptom.where}
                                  </p>
                                </div>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
            </>
          )}
        </div>
      )}

      {isNarrativeChecked && !isEmpty(clinicalNote?.ros?.narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={clinicalNote?.ros?.narrative} />
        </div>
      )}
    </div>
  );
};

export default ReviewOfSystems;
