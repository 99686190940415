import React from 'react';
import cs from 'classnames';
export * from './Icon.generated';
import { IconSvg } from './Icon.generated';
import './Icon.scss';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Icon = ({
  htmlFor,
  icon,
  size,
  className,
  color,
  shade = 500,
  stroke,
  onClick,
  disabled = false,
  customColor,
  ...rest
}) => (
  <ErrorBoundary FallbackComponent={ErrorMessage}>
    <label
      onClick={!disabled ? onClick : undefined}
      htmlFor={htmlFor || icon}
      style={{
        ...(size && { width: size, minWidth: size }),
        ...(customColor && (stroke ? { stroke: customColor } : { fill: customColor }))
      }}
      className={cs(
        'Icon',
        `Icon--${icon}`,
        size && 'Icon--size',
        customColor && `Icon-color--${customColor}`,
        color === 'white' || color === 'black' ? `Icon--${color}` : `Icon--${color}-${shade}`,
        stroke ? 'change-stroke-color' : 'change-fill-color',
        customColor && (stroke ? 'custom-stroke-color' : 'custom-fill-color'),
        onClick && !disabled && 'cursor-pointer',
        disabled && '!cursor-not-allowed opacity-50',
        className
      )}
      {...rest}>
      {IconSvg[icon]}
    </label>
  </ErrorBoundary>
);

export default Icon;
