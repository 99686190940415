import React, { useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { removePracticeFromOrganization } from 'api/Practice';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';

import AddOwnerModal from './components/AddOwnerModal';
import { ia, iaRa } from 'lib/helpers/utility';
import { Popover } from '@headlessui/react';
import Icon from 'components/shared/Icon/Icon';
import ImportDataModal from './components/ImportDataModal';
import { OrganizationContextProvider } from 'lib/context/OrganizationContext/OrganizationContextProvider';

const AdminPracticesOrganization = () => {
  const { practice } = useOutletContext();
  const [addOwnerModalVisible, setAddOwnerModalVisible] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [importDataModalVisible, setImportDataModalVisible] = useState(false);
  const [view, setView] = useState(null);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleRemove = async () => {
    const params = { practice_id: practice.id, user_id: practice.organization.user_id };
    const data = await removePracticeFromOrganization(navigate, params);
    if (data.code === 0) {
      queryClient.invalidateQueries('practice');
      showAlert({
        color: 'success',
        message: 'Practice removed from organization successfully'
      });
    }
    setConfirmationModalVisible(false);
  };

  return (
    <div className="h-fit min-h-[30vh] p-[1rem]">
      {iaRa(practice.children).map((child) => (
        <div key={child.id} className="text-base">
          Practice <Link to={`/admin/practices/${child.id}`}>{child.name}</Link> is part of our
          organization.
        </div>
      ))}

      {practice.organization && (
        <div className="flex items-center justify-between">
          <div className="text-base">
            This practice is part of{' '}
            <Link to={`/admin/practices/${practice.organization.id}`}>
              {practice.organization.name}
            </Link>{' '}
            organization.
          </div>
          <Button text="Import data" onClick={() => setImportDataModalVisible(true)} />
        </div>
      )}

      {practice.organization ? (
        <div>
          <Button
            text="Remove from organization"
            type="secondary"
            color="warning"
            onClick={() => setConfirmationModalVisible(true)}
          />
        </div>
      ) : !ia(practice.children) ? (
        <Popover className="relative">
          {({ open, close }) => (
            <div>
              <Popover.Button
                className={`flex  items-center justify-center rounded-full border-solid  border-neutral-300`}>
                <Button
                  text="Convert/Join an existing organization"
                  type="secondary"
                  iconRight={open ? 'chevron-up' : 'chevron-down'}
                />
              </Popover.Button>
              <Popover.Panel
                className={`bg-white' }  absolute left-1 z-[99999] w-max cursor-pointer
                  rounded-lg !px-2 !py-[6px] shadow-md`}>
                <div
                  className="!mb-1 flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                  onClick={() => {
                    setView('convert');
                    close();
                  }}>
                  <div className="text-sm text-primary-900">Convert to an organization</div>
                </div>
                <div
                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                  onClick={() => {
                    setView('add-to-existing');
                    close();
                  }}>
                  <div className="text-sm text-primary-900">
                    Add practice to an existing organization
                  </div>
                </div>
              </Popover.Panel>
            </div>
          )}
        </Popover>
      ) : null}

      {!!view && <AddOwnerModal {...{ view, setView }} />}
      {confirmationModalVisible && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Remove"
          title="Remove practice from organization"
          icon="new-document-remove-red"
          message="Are you sure you want to remove practice from organization?"
          handleContinue={handleRemove}
          handleOpen={confirmationModalVisible}
          handleClose={() => setConfirmationModalVisible(false)}
        />
      )}
      {importDataModalVisible && (
        <OrganizationContextProvider>
          <ImportDataModal
            {...{
              importDataModalVisible,
              setImportDataModalVisible,
              organizationId: practice.organization.id
            }}
          />
        </OrganizationContextProvider>
      )}
    </div>
  );
};

export default withErrorBoundary(AdminPracticesOrganization);
