import Tippy from '@tippyjs/react';
import { getCptCodes } from 'api/CptCodes';
import { getIcd10Codes } from 'api/Icd10';
import { getPatients } from 'api/Patients';
import ClinicalNoteStatus from 'components/practice/charts/ClinicalNote/AllClinicalNotes/components/ClinicalNoteStatus';
import { showAlert } from 'components/shared/Alert/Alert';
import Status from 'components/shared/Status/Status';
import {
  findAppointmentStatusColor,
  findAppointmentStatusLabel,
  formatDate,
  formatDateAndTimeZ,
  ia
} from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import TableCheckbox from '../../TableCheckbox';
import TableHeaderCheckbox from '../../TableHeaderCheckbox';
import Actions from '../components/Actions';

const searchICD10Codes = async (searchTerm, navigate) => {
  const codes = await getIcd10Codes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.icd10.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

const searchCPTCodes = async (searchTerm, navigate) => {
  const codes = await getCptCodes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.cpt.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

const clinicalNoteStatus = [
  {
    value: 'locked',
    label: 'Locked'
  },
  {
    value: 'draft',
    label: 'Draft'
  },
  {
    value: 'no_clinical_note',
    label: 'Not Started'
  }
];

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    label: p?.fullName,
    value: p?.id
  }));
};

export const defaultColumns = (timezone, appointmentStatuses) => [
  {
    field: 'checkbox',
    headerName: '',
    cellRenderer: TableCheckbox,
    headerComponent: TableHeaderCheckbox,
    maxWidth: 60,
    cellClass: 'no-border',
    enableRowGroup: true,
    resizable: false,
    sortable: false
  },
  {
    field: 'appointment.id',
    headerName: 'ID',
    cellClass: 'no-border',
    valueFormatter: (params) => {
      if (params.node.group) {
        return null;
      }
      return params.value ? capitalize(params.value) : '-';
    },
    enableRowGroup: true
  },
  {
    field: 'appointment.starts_at',
    headerName: 'Appointment Date',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? formatDateAndTimeZ(value, timezone) : '-'),
    enableRowGroup: true
  },
  {
    field: 'practitioner',
    headerName: 'Provider',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value?.professionalFullName : '-'),
    enableRowGroup: true
  },
  {
    field: 'patient',
    headerName: 'Patient',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value?.f_name + ' ' + value?.l_name : '-'),
    enableRowGroup: true
  },
  {
    field: 'services',
    headerName: 'Services(CPT)',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) => (ia(value) ? value?.map((v) => (v ? v : 'N/A')).join(', ') : '-')
  },
  {
    field: 'icd_10_codes',
    headerName: 'Diagnosis(ICD)',
    cellClass: 'no-border',
    sortable: false,
    valueFormatter: ({ value }) => (ia(value) ? value?.map((v) => (v ? v : 'N/A')).join(', ') : '-')
  },
  {
    field: 'medical_history.state',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: (params) => {
      if (!params?.data) {
        return null;
      }
      return (
        <div className="flex h-full items-center justify-center">
          <ClinicalNoteStatus
            data={params?.data}
            classNames="flex min-w-[80px] justify-center items-center"
          />
        </div>
      );
    },
    resizable: false
  },
  {
    field: 'appointment.status',
    headerName: 'Appointment Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (
      <Tippy
        arrow={true}
        placement="left-start"
        className="tippy-black w-fit"
        content={findAppointmentStatusLabel(appointmentStatuses, value)}>
        <div className="flex h-full items-center">
          <Status
            status={findAppointmentStatusLabel(appointmentStatuses, value)}
            color={findAppointmentStatusColor(appointmentStatuses, value)}
            className="flex min-w-[80px] items-center"
          />
        </div>
      </Tippy>
    ),
    maxWidth: 200,
    minWidth: 50,
    resizable: false
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: false,
  groupSelectsChildren: true,
  enableRangeSelection: true,
  suppressAggFuncInHeader: true,
  suppressRowGroup: true
};

export const DEFAULT_FILTERS = {
  appointment_date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Appointment Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  patient: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  cpt: {
    type: 'search',
    multiple: true,
    loadOptions: searchCPTCodes,
    values: [],
    title: 'Services',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  icd: {
    type: 'search',
    multiple: true,
    loadOptions: searchICD10Codes,
    values: [],
    title: 'Diagnosis',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: [],
    options: clinicalNoteStatus,
    title: 'Clinical Note Status'
  }
};

export const getPracticeDefaultFilters = (practice) => {
  const newDefaultFilters = {
    ...DEFAULT_FILTERS,
    appointment_date: {
      values: {
        startDate: moment.min(moment(practice.created_at), moment(0)).toDate(),
        endDate: moment().toDate()
      },
      type: 'date-range',
      title: 'Appointment Date',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    }
  };

  return newDefaultFilters;
};

export const handleSignAndLock = async ({
  user,
  selectedRows,
  setSignMessage,
  setShowConfirmationModal,
  setSelectedRows,
  setAreFinalized,
  setAllowToSign
}) => {
  setAreFinalized(false);
  const userId = user?.user_id ?? user.id;
  let selectedClinicalNotes = selectedRows.filter((obj) => obj.data.id).map((cn) => cn.data);

  if (!ia(selectedClinicalNotes)) {
    setSignMessage(`This Clinical Note hasn't started yet`);
    setShowConfirmationModal(true);
    setSelectedRows([]);
    return;
  }

  let userClinicalNotes = selectedClinicalNotes?.filter(
    (cn) => cn.document_sign && cn.document_sign.some((doc) => doc.user_id === userId)
  );

  if (!ia(userClinicalNotes)) {
    setSignMessage(`You are not assigned to the documents selected`);
    setShowConfirmationModal(true);
    setSelectedRows([]);
    return;
  }

  let filteredClinicalNotes = userClinicalNotes?.filter(
    (cn) =>
      cn.state !== 'finalized' &&
      cn.document_sign &&
      cn.document_sign.some((doc) => doc.status !== 'signed')
  );

  if (!ia(filteredClinicalNotes)) {
    setSignMessage(`This Clinical Note is signed and locked`);
    setShowConfirmationModal(true);
    setSelectedRows([]);
    return;
  }

  setAllowToSign(filteredClinicalNotes);
  setShowConfirmationModal(true);
};

export const handleUnlock = async ({
  user,
  setAreFinalized,
  setSignMessage,
  setShowConfirmationModal,
  setAllowToSign,
  selectedRows
}) => {
  const userId = user?.user_id ?? user.id;

  let selectedClinicalNotes = selectedRows.filter((obj) => obj.data.id).map((cn) => cn.data);

  let userClinicalNotes = selectedClinicalNotes?.filter(
    (cn) => cn.document_sign && cn.document_sign.some((doc) => doc.user_id === userId)
  );
  let isFinalized = userClinicalNotes.every((doc) => doc.state === 'finalized');
  if (isFinalized) {
    setAreFinalized(true);
    setSignMessage(`Are you sure you want to unlock the documents selected`);
    setShowConfirmationModal(true);
    setAllowToSign(userClinicalNotes);
    return;
  }
};

export const handleExportModal = ({
  selectedRows,
  setDocumentIds,
  setExportModal,
  setButtonLoading,
  setDownloadPDF
}) => {
  const notes = selectedRows.filter((obj) => obj.data.id).map((cn) => cn.data);
  if (notes.length === 0) {
    showAlert({
      title: 'Clinical Note Export',
      message: `The clinical notes selected can't be export because they have haven't started yet.`
    });
    return;
  }

  const samePatient = notes.every((cn) => cn?.patient?.id === notes[0]?.patient?.id);

  setDocumentIds(
    notes?.map((cn) => ({
      cnId: cn?.id,
      appointmentId: cn?.appointment?.id,
      startsAt: cn?.appointment?.starts_at,
      patient: cn?.patient,
      provider: cn?.practitioner
    }))
  );

  if (samePatient) {
    setExportModal(true);
  } else {
    setButtonLoading(true);
    setDownloadPDF(true);
  }
};
