import React, { useImperativeHandle } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';
import EnhancementStatus from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/EnhancementStatus';

const Subjective = ({ subjectiveRef, sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  useImperativeHandle(sectionRef, () => ({
    formData: { subjective: clinicalNote?.subjective }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write subjective notes here"
      id="subjective"
      data-qa="subjective"
      name="subjective"
      className="min-h-[200px]"
      value={clinicalNote?.subjective}
      onChange={(e) => handleChange(e, 'subjective')}
      transcribing
      forwardedRef={subjectiveRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Subjective;
