import { useEffect, useMemo } from 'react';

import { useFormikContext } from 'formik';

import { ia, iaRa, io } from 'lib/helpers/utility';

import Header from 'components/shared/shared/Header';

import AOEQuestionsList from './AOEQuestionsList';
import SampleDetails from './SampleDetails';

const ExpandedTableComponent = ({ data, node }) => {
  const { values, setFieldValue, errors, isSubmitting } = useFormikContext();

  const testIndex = values.tests.findIndex((test) => test.id === data.id);
  const test = testIndex > -1 ? values.tests[testIndex] : null;
  const firstTestOfLab =
    testIndex > -1
      ? values.tests.findIndex((test) => test.labPrefix === data.labPrefix) === testIndex
      : false;

  useEffect(() => {
    if (io(errors) && errors?.tests?.[testIndex]) {
      node.parent.setExpanded(true);
    }
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (test && firstTestOfLab && ia(test?.orderAOEs)) {
      setFieldValue(`tests.${testIndex}.hasOrderAOEs`, true);
    }
  }, [firstTestOfLab, setFieldValue, testIndex, test]);

  const questions = useMemo(() => {
    if (!test) return;

    let questionsArray = [];

    if (firstTestOfLab && ia(test?.orderAOEs)) {
      const orderAOEs = test.orderAOEs.map((aoe) => {
        aoe.orderAOE = true;
        return aoe;
      });

      questionsArray = [...orderAOEs, ...test.testAOEs];
    } else {
      questionsArray = iaRa(test.testAOEs);
    }

    return questionsArray;
  }, [test]);

  if (testIndex === -1) return null;

  const details = test.sampleDetails;

  return (
    <div className="px-5 py-4">
      <div>
        <Header
          label="Sample Details"
          labelClassName="relative !text-base font-600 text-primary-900 before:absolute before:bottom-0 before:h-[1px] before:w-6 before:bg-primary-500 before:duration-200 before:ease-linear before:content-['']"
        />

        <SampleDetails key={`${test.id}-test-sample-details`} details={details} />

        <AOEQuestionsList
          key={`${test.id}-test-questions`}
          questions={questions}
          testIndex={testIndex}
        />
      </div>
    </div>
  );
};

export default ExpandedTableComponent;
