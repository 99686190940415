import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import cs from 'classnames';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from 'lib/helpers/utility';

import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

import SignModal from '../../shared/Sign/SignModal';
import TreatmentPlan from '../TreatmentPlan';
import TPList from '../views/TPList';

const TPModalContent = ({
  isModal,
  view,
  treatmentPlan,
  loadEPNToCN,
  handleBtnClick,
  showConfirmationModal,
  setShowConfirmationModal,
  footer,
  createTPIsLoading,
  noProvidersSelected,
  origin,
  outsideCN = false
}) => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {!isModal ||
      (io(selectedTreatmentPlan) && view === 'preview') ||
      view === 'create' ||
      view === 'edit' ? (
        <div className={cs('mx-auto mt-3 w-full max-w-[750px] px-8 pb-[112px]')}>
          <TreatmentPlan view={view} origin={origin} outsideCN={outsideCN} />
        </div>
      ) : (
        <TPList data={treatmentPlan} onClick={handleBtnClick} loadEPNToCN={loadEPNToCN} />
      )}
      {selectedTreatmentPlan?.id && (
        <SignModal
          setShowConfirmationModal={setShowConfirmationModal}
          showConfirmationModal={showConfirmationModal}
          documentId={selectedTreatmentPlan.id}
          documentTypes={['treatment_plan']}
          outsideCN={outsideCN}
        />
      )}
      {!isModal && footer ? (
        <div className="flex justify-center">
          {footer({
            submit: handleBtnClick,
            isSubmitting: createTPIsLoading,
            disabled:
              (view === 'list' && !selectedTreatmentPlan?.id) ||
              ((view === 'edit' || view === 'create') &&
                (noProvidersSelected ||
                  !selectedTreatmentPlan?.treatment_frequency_date?.startDate ||
                  !selectedTreatmentPlan?.treatment_frequency_date?.endDate))
          })}
        </div>
      ) : null}
    </ErrorBoundary>
  );
};

export default TPModalContent;
