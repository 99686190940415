import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getOrders } from 'api/Orders';

export const useOrders = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['orders', ...dependencies], () => getOrders(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
