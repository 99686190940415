import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { deleteTag } from 'api/Tags';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useAllTags } from 'lib/hooks/queries/tags/useAllTags';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TableCounter from 'components/shared/Table/TableCounter';

import TagModal from './components/TagModal';
import { defaultColumns, defaultFilters } from './lib/helper';

const Tags = () => {
  const practice = useRecoilValue(currentPractice);

  return (
    <TableContextProvider
      defaultFilters={defaultFilters}
      cols={defaultColumns(practice?.timezone)}
      name="tags"
      pagination>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  const category = 'tags';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openTagModal, setOpenTagModal] = useState(false);
  const [singleTag, setSingleTag] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { limit, page, sort, filters, setFilters, setPage, selectedRows, setSelectedRows } =
    useTableContext();

  const { data, isLoading, isFetching } = useAllTags({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const tags = data?.tags || [];
  const count = data?.count || 0;

  const isFirstColumn = (params) => {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);
  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    setSingleTag(e?.data);
    setOpenTagModal(true);
  };

  const handleCloseModal = () => {
    setOpenTagModal(false);
    setSingleTag({});
  };

  const { mutateAsync: mutateDeleteTag } = useMutation({
    mutationFn: (data) => deleteTag(navigate, data)
  });

  const handleContinue = async () => {
    const tagIds = selectedRows?.map((s) => s?.data?.id);
    const tagKinds = selectedRows
      ?.map((s) => s?.data?.kind)
      .filter(Boolean)
      .reduce((acc, kind) => {
        if (!acc.includes(kind)) {
          acc.push(kind);
        }
        return acc;
      }, []);
    await mutateDeleteTag(
      { tagIds },
      {
        onSuccess: () => {
          tagKinds.map((kind) => {
            let queryToInvalidate;
            if (kind === 'chart') {
              queryToInvalidate = 'patients';
            } else if (kind === 'fax') {
              queryToInvalidate = 'fax';
            } else {
              queryToInvalidate = 'appointment';
            }
            if (queryToInvalidate === 'fax') {
              queryClient.refetchQueries(['archivedFaxes']);
              queryClient.refetchQueries(['draftFaxes']);
              queryClient.refetchQueries(['inboundFaxes']);
              queryClient.refetchQueries(['outboundFaxes']);
            } else {
              queryClient.refetchQueries([queryToInvalidate]);
            }
            queryClient.refetchQueries(['tags', kind]);
          });
          queryClient.invalidateQueries(['allTags']);
          showAlert({ title: 'Tag deleted successfully!', color: 'success' });
          setShowDeleteModal(false);
        }
      }
    );
    setSelectedRows([]);
  };
  return (
    <>
      <Header title="Tags">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <>
              <p className="text-xs font-400 text-neutral-600">
                {selectedRows.length} tags selected
              </p>
              <Allowed requiredPermissions="tags.delete">
                <Button
                  color="danger"
                  text="Delete"
                  icon="new-trash-patient"
                  size="small"
                  iconColor="white"
                  onClick={() => setShowDeleteModal(true)}
                />
              </Allowed>
            </>
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Button
            text="Create tag"
            icon="new-tag-new"
            size="small"
            iconColor="white"
            onClick={() => setOpenTagModal(true)}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={tags}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {openTagModal && (
        <TagModal tag={singleTag} isOpen={openTagModal} handleClose={handleCloseModal} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt={`Yes, delete ${selectedRows.length > 1 ? 'tags' : 'tag'}`}
          title={`Delete ${selectedRows.length} selected ${selectedRows.length > 1 ? 'tags' : 'tag'}`}
          icon="new-calendar-red"
          message={`Are you sure you want to delete ${selectedRows.length} selected ${selectedRows.length > 1 ? 'tags' : 'tag'}?`}
          handleOpen={showDeleteModal}
          handleContinue={handleContinue}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
}

export default Tags;
