import React from 'react';
import Input from 'components/shared/Input/Input';
import { useFormikContext } from 'formik';
import Select from 'components/shared/Select/Select';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import { useStates } from 'lib/hooks/queries/useStates';
import { ia, optionify } from 'lib/helpers/utility';

const PhysicalAddress = () => {
  const permissions = useRecoilValue(state.permissions);
  const { handleChange: onChange, values, errors, setFieldValue } = useFormikContext();
  const { data } = useStates();
  const stateList = data?.states || [];

  const disabled = !permissions?.patients?.update;

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h4 className="text !my-2 text-xl text-primary-700">Primary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Address"
            data-qa="address-ln-one"
            id="address.address_ln_1"
            name="address.address_ln_1"
            onChange={onChange}
            placeholder="Address"
            value={values?.address?.address_ln_1}
            error={errors?.address?.address_ln_1}
            disabled={disabled}
          />

          <Input
            label="Address 2"
            data-qa="address-ln-two"
            name="address.address_ln_2"
            id="address.address_ln_2"
            onChange={onChange}
            placeholder="Address 2"
            value={values?.address?.address_ln_2}
            disabled={disabled}
          />

          <Input
            label="City"
            data-qa="address-city"
            id="address.city"
            name="address.city"
            onChange={onChange}
            placeholder="City"
            value={values?.address?.city}
            error={errors?.address?.city}
            disabled={disabled}
          />

          <Select
            label="State"
            id="address.state"
            placeholder="Type and select state"
            options={optionify(stateList)}
            error={errors?.address?.state}
            onChange={(e) => setFieldValue('address.state', e?.value)}
            value={
              ia(stateList)
                ? stateList.map((l) => {
                    if (l?.id === values?.address?.state) return { value: l?.id, label: l?.name };
                  })
                : null
            }
            disabled={disabled}
          />

          <Input
            label="Zip code"
            data-qa="address-zipcode"
            id="address.zip"
            name="address.zip"
            onChange={onChange}
            placeholder="Zip code"
            value={values?.address?.zip}
            disabled={disabled}
          />
        </div>
      </div>

      <div>
        <h4 className="text !my-2 text-xl text-primary-700">Secondary</h4>

        <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          <Input
            label="Address"
            onChange={onChange}
            placeholder="Address"
            data-qa="secondary-address-ln-one"
            id="secondary_address.address_ln_1"
            name="secondary_address.address_ln_1"
            value={values?.secondary_address?.address_ln_1}
            disabled={disabled}
          />

          <Input
            label="Address 2"
            onChange={onChange}
            placeholder="Address 2"
            data-qa="secondary-address-ln-two"
            id="secondary_address.address_ln_2"
            name="secondary_address.address_ln_2"
            value={values?.secondary_address?.address_ln_2}
            disabled={disabled}
          />

          <Input
            label="City"
            placeholder="City"
            onChange={onChange}
            data-qa="secondary-city-address"
            id="secondary_address.city"
            name="secondary_address.city"
            value={values?.secondary_address?.city}
            disabled={disabled}
          />

          <Select
            label="State"
            placeholder="Type and select state"
            id="secondary_address.state"
            options={optionify(stateList)}
            onChange={(e) => setFieldValue('secondary_address.state', e?.value)}
            value={
              ia(stateList)
                ? stateList.map((l) => {
                    if (l?.id === values?.secondary_address?.state)
                      return { value: l?.id, label: l?.name };
                  })
                : null
            }
            disabled={disabled}
          />

          <Input
            label="Zip code"
            onChange={onChange}
            placeholder="Zip code"
            data-qa="secondary-address-zipcode"
            id="secondary_address.zip"
            name="secondary_address.zip"
            value={values?.secondary_address?.zip}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default PhysicalAddress;
