import React from 'react';
import { ia, isEmpty } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import MedicalHistoryModal from '../Modals/MedicalHistoryModal';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';
import Allowed from 'components/shared/Permissions/Allowed';

const MedicalHistoryOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (!cnSettings?.overview?.medical_history && !cnSettings?.overview?.medicalHistory) return null;

  return (
    <Allowed requiredPermissions="patients_medical_history.read">
      <SimpleAccordion
        title="Medical history"
        onToggle={() => toggleOne('medicalHistory')}
        open={isOpen.medicalHistory}
        edit={
          <MedicalHistoryModal
            formId={currentHpOverviewData?.medicalHistory?.id}
            defaultValue={currentHpOverviewData?.medicalHistory?.response}
            setCurrentHpOverviewData={setCurrentHpOverviewData}
            setCustomResponses={setOverviewData}
          />
        }
        addPermissions="patients_medical_history.update">
        {ia(overviewData?.medicalHistory) &&
          overviewData?.medicalHistory?.map((medicalHistory, index) => {
            const item = medicalHistory?.response;

            if (item?.noDetails) {
              return (
                <NoKnown
                  key={index}
                  form={medicalHistory}
                  description="No notable medical history"
                  shouldShowDivider={index !== 0}
                />
              );
            }

            if (
              !item?.allergies?.allergy &&
              !item?.allergies?.allergy &&
              !item?.chronic?.illness &&
              !item?.chronic?.note &&
              !item?.surgeries?.surgery
            ) {
              return null;
            }

            return (
              <>
                <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                <React.Fragment>
                  {!isEmpty(item?.chronic?.illness) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Chronic:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.chronic.illness}
                        {item.chronic.illness === 'yes' && (
                          <span className="m-0 block first-letter:capitalize">
                            {item.chronic.note}
                          </span>
                        )}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item?.surgeries?.surgery) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Surgeries:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.surgeries.surgery}
                        {item.surgeries.surgery === 'yes' && (
                          <span className="m-0 block first-letter:capitalize">
                            {item.surgeries.note}
                          </span>
                        )}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item?.allergies?.allergy) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Allergies:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.allergies.allergy}
                        {item.allergies.allergy === 'yes' && (
                          <span className="m-0 block break-all first-letter:capitalize">
                            {item.allergies.note}
                          </span>
                        )}
                      </span>
                    </div>
                  )}

                  {!isEmpty(item?.hospitalization?.illnessOrInjury) && (
                    <div className="flex justify-between gap-x-4">
                      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                        Hospitalization:
                      </span>
                      <span className="text-right text-sm font-400 text-primary-900 first-letter:capitalize">
                        {item.hospitalization.illnessOrInjury}
                        {item.hospitalization.illnessOrInjury === 'yes' && (
                          <span className="m-0 block break-all first-letter:capitalize">
                            {item.hospitalization.note}
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </React.Fragment>
                <CreationInfo
                  createdAt={medicalHistory?.createdAt}
                  createdBy={medicalHistory?.createdBy?.fullName}
                />
              </>
            );
          })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default MedicalHistoryOverview;
