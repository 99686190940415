import React, { useEffect, useState } from 'react';
import { requestApi } from 'api/Api';
import Modal from '../../shared/Modal/Modal';
import { useIdleTimer } from 'react-idle-timer';
import { useLocation, useNavigate } from 'react-router-dom';
import NotFound from 'components/practice/Dashboard/components/NotFound';

const AutoIdleWarper = ({ children }) => {
  const { pathname } = useLocation();

  if (pathname === '/kiosk' || pathname.includes('idle')) return null;
  return <AutoIdle />;
};

const AutoIdle = () => {
  const { pathname } = useLocation();

  const [remaining, setRemaining] = useState(60);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const { reset } = useIdleTimer({
    timeout: 60 * 1000,
    onIdle: () => {
      setShowModal(true);
      requestApi({
        url: '/api/kiosk/idle_request',
        params: { status: 'idle', url: pathname },
        navigate
      });
      setRemaining(60);
    },
    onActive: () => {
      setShowModal(false);
      if (showModal) {
        requestApi({
          url: '/api/kiosk/idle_request',
          params: { status: 'active', url: pathname },
          navigate
        });
      }
      reset();
    },
    onAction: reset,
    debounce: 500,
    events: ['keydown', 'mousedown', 'touchstart', 'touchmove', 'MSPointerDown', 'MSPointerMove']
  });

  useEffect(() => {
    let interval;
    if (showModal) {
      interval = setInterval(() => {
        setRemaining((prevRemaining) => {
          if (prevRemaining <= 1) {
            clearInterval(interval);
            setRemaining(60);
            reset();
            navigate('/kiosk');
            return 0;
          }
          return prevRemaining - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal]);

  return (
    <Modal
      handleOpen={showModal && remaining <= 30}
      disableHeader={true}
      shouldCloseOnOverlayClick={false}
      overlayClassName="z-[300]"
      isFooter={false}>
      <div className="my-10">
        <NotFound
          icon="new-automatization"
          title="Are you still there?"
          description={`You will be logged out in ${Math.floor(remaining / 60)}:${
            remaining % 60 < 10 ? `0${remaining % 60}` : remaining % 60
          } minutes due to inactivity.`}
          className="pt-0"
        />
      </div>
    </Modal>
  );
};
export default AutoIdleWarper;
