import Button from 'components/shared/Buttons/Button';
import React from 'react';

const MacrosHeader = ({ breadcrumb }) => {
  const { steps, step } = breadcrumb;

  const onShowCreateMacro = () => {
    breadcrumb.push({ title: 'New Text Macro', name: 'create' });
  };

  return steps[step]?.name === 'start' ? (
    <Button
      className="mr-5 min-w-[170px]"
      size="small"
      onClick={onShowCreateMacro}
      text="New Text Macro"
      data-qa="new-macro-btn"
      icon="new-add-square"
      transparent
    />
  ) : null;
};

export default MacrosHeader;
