import React, { useEffect, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { getClinicalNote } from '../../../../../../../../api/ClinicalNote';
import {
  formatDate,
  ia,
  iaRa,
  spaceToKebabCase
} from '../../../../../../../../lib/helpers/utility';
import { currentPractice } from '../../../../../../practiceState';
import {
  mapRenderedInitialState,
  processSelectedForms
} from '../../../../PreviewNote/lib/renderedHelper';

import { getMedications } from 'api/Medication';
import { getCustomFormResponse } from 'components/practice/charts/ClinicalNote/lib/getPatientHistory';
import { processCustomFormTypes } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import RenderedComponents from '../../../../PreviewNote/Rendered/components/RenderedComponents';
import { getOverview } from '../../../../lib/getOverview';

const ClinicalSummaryReview = ({ itemProps }) => {
  const { id, appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [componentProps, setComponentProps] = useState({
    checkedRendered: null,
    hpForms: null,
    soapForms: null
  });
  const practice = useRecoilValue(currentPractice);

  const isOnlyCurrentCN =
    itemProps?.clinical_notes.length === 1 && itemProps?.clinical_notes[0] === appointmentId;

  useEffect(() => {
    if (isOnlyCurrentCN) {
      prepareForms();
    }

    if (itemProps?.clinical_notes && itemProps?.clinical_summary) {
      setLoading(true);

      prepareComponentProps();

      prepareForms();
    }
  }, [itemProps]);

  const prepareComponentProps = () => {
    const selected = itemProps?.clinical_summary;
    const rendered = mapRenderedInitialState(selected);

    const { checkedRendered, hpForms, soapForms, advancedForms } = processSelectedForms({
      selected,
      rendered
    });

    setComponentProps({ checkedRendered, hpForms, soapForms, advancedForms });
  };

  const navigate = useNavigate();

  const prepareForms = async () => {
    const compProps = [];
    const cnIds = itemProps?.clinical_notes;

    await Promise.all(
      cnIds?.map(async (item) => {
        const result = await getClinicalNote(navigate, {
          appointmentId: item,
          loadedPracticeId: practice?.id
        });

        const { clinicalNote, advancedHP, advancedSOAP, serviceCPT, customFormTypes, appointment } =
          result;

        const processedAdvancedHP = advancedHP?.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));
        const processedAdvancedSOAP = advancedSOAP?.map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        }));

        const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });

        const cpt_codes = iaRa(serviceCPT);

        const overviewData = await getCustomFormResponse({
          patientId: id || clinicalNote?.user_id,
          type: ['allergies', 'vaccines', 'familyHistory', 'surgicalHistory']
        });

        const data = await getMedications(() => {}, {
          patientId: id || clinicalNote?.user_id,
          v2: true
        });

        if (ia(data?.medicationHistory?.response?.medications)) {
          overviewData.medicationHistory = data?.medicationHistory?.response?.medications;
        }

        compProps.push({
          customClinicalNote: {
            clinicalNote: { ...clinicalNote, cpt_codes },
            appointment,
            advancedHP: processedAdvancedHP,
            advancedSOAP: processedAdvancedSOAP,
            customFormTypes: processedCustomFormTypes,
            overviewData,
            currentHpOverviewData: await getOverview({
              patientId: id || clinicalNote?.user_id,
              appointmentId: item,
              navigate,
              isKebab: false
            })
          }
        });
      })
    );

    setResult(compProps);
    setLoading(false);
  };

  return (
    <div>
      <div className="text-base font-500 text-neutral-800">Clinical Summary</div>
      {loading ? (
        <Skeleton count={5} />
      ) : ia(result) ? (
        result?.map((row, index) => {
          const displayDivider = result?.length > index + 1;

          return (
            <div key={index} className="!mt-2.5">
              <p className="w-fit rounded-lg bg-primary-50 !px-2 !py-1 font-500 text-neutral-800">
                {formatDate(
                  row?.customClinicalNote?.appointment?.starts_at,
                  practice?.timezone,
                  true
                )}
              </p>

              <RenderedComponents {...componentProps} {...row} className="!bg-transparent" />

              {displayDivider && <div className="border border-solid !border-primary-100"></div>}
            </div>
          );
        })
      ) : (
        <p className="text-neutral-800">No clinical summary found</p>
      )}
    </div>
  );
};

export default ClinicalSummaryReview;
