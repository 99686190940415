import React, { useRef } from 'react';
import Accordion from '../../../../shared/Accordion';
import Objective from './Objective';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';
import ClinicalNoteCurrentNoteTypeNotesMyScribeWarning from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeWarning';

const ObjectiveSection = ({ components, sectionRef, options }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const objectiveRef = useRef(null);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.objective?.title}
      id={components?.objective?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Objective objectiveRef={objectiveRef} sectionRef={sectionRef} />
      <ClinicalNoteCurrentNoteTypeNotesMyScribeWarning />
    </Accordion>
  );
};

export default ObjectiveSection;
