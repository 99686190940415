import React from 'react';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Icon from 'components/shared/Icon/Icon';
import { iaRa } from 'lib/helpers/utility';

const ReferralOrder = ({
  referralsData,
  isFetching,
  setNavigateToParams,
  handleOpen,
  handleOnOpenDelete,
  ableToDelete
}) => {
  return isFetching ? (
    <Skeleton count={5} />
  ) : (
    iaRa(referralsData).map((row, index) => (
      <div key={index} className="!mt-3 flex w-full items-center !gap-1 self-center">
        <div
          onClick={() => {
            setNavigateToParams({ type: 'referral_order', itemProps: row });
            handleOpen();
          }}
          className="flex w-full cursor-pointer items-center gap-1.5 rounded-lg 
           bg-white !p-2 transition-all hover:!border-primary-200 hover:!bg-primary-50">
          <Icon size={24} icon="new-note" color="primary" />
          <div className="font-medium text-neutral-800">Type: Referral,</div>
          <div className="font-medium text-neutral-800">To: {row?.to_name}</div>
        </div>
        {ableToDelete && (
          <div
            onClick={() => handleOnOpenDelete({ orderId: row.id, type: 'referral_order' })}
            className="flex h-full cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !p-2.5 transition-all hover:!border-primary-500 hover:!bg-primary-50">
            <Icon icon="new-trash-v2" className="cursor-pointer" size={20} />
          </div>
        )}
      </div>
    ))
  );
};

export default ReferralOrder;
