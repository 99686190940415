import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import adsBackground from '../../../assets/Images/ads/ads-background.png';
import chatPNG from '../../../assets/Images/ads/chat.svg';
import checkinPNG from '../../../assets/Images/ads/checkin.svg';
import paymentPNG from '../../../assets/Images/ads/payment.svg';
import { withErrorBoundary } from '../../shared/Error/Boundary';

const ANIMATIONS = ['', '!opacity-100', ''];

const AdsAdvertisement = ({ wrapperClassName }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currAnimation, setCurrAnimation] = useState(1);
  const [ads, setAds] = useState([
    {
      title: "Myriad's Comprehensive Check-in",
      subtitle:
        'Skip the wait, patients fill forms from any device with integration into clinical documentation. Ask about our kiosk solutions today!',
      image: checkinPNG
    },
    {
      title: 'Myriad Chat',
      subtitle: 'Two-way patient messaging through text, patient portal and more!',
      image: chatPNG
    },
    {
      title: 'Myriad Pay',
      subtitle:
        'Collect estimated patient portions up-front accurately and seamlessly. Patient payment has never been easier or more efficient!',
      image: paymentPNG
    }
  ]);

  useEffect(() => {
    // get ads from the db
    let interval = setInterval(() => {
      setCurrentStep((ps) => (ps === ads.length - 1 ? 0 : ps + 1));
      setCurrAnimation(0);
      setTimeout(() => {
        setCurrAnimation(1);
      }, 200);
      setTimeout(() => {
        setCurrAnimation(2);
      }, 4800);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={cs('relative', wrapperClassName)}>
      <img src={adsBackground} className="absolute top-0 left-0 z-10 h-full w-full object-cover" />
      <div className="z-20 relative flex h-full flex-col justify-between overflow-hidden sm:hidden !p-4 sm!p-3 overflow-hidden">
        <div className="flex gap-4 flex-col">
          <h3
            className={`text-white opacity-30 transition duration-200 ${ANIMATIONS[currAnimation]}`}>
            {ads[currentStep]?.title}
          </h3>
          <h6
            className={` text-white opacity-30 transition duration-200 ${ANIMATIONS[currAnimation]}`}>
            {ads[currentStep]?.subtitle}
          </h6>
        </div>
        <img
          src={ads[currentStep]?.image}
          className={cs(
            'w-max h-max m-auto object-contain opacity-30 transition duration-200',
            ANIMATIONS[currAnimation],
            currentStep === 0 && '!h-[80%]'
          )}
        />
        <div className="flex w-full justify-center">
          {ads.map((v, i) => (
            <div
              className={`m-1 rounded-full border-[0.25rem] border-solid ${currentStep === i ? 'border-primary-50' : 'border-primary-100'
                }`}
              key={`advertisement-button-index-${i}`}
              onClick={() => setCurrentStep(i)}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(AdsAdvertisement);
