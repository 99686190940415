import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';

import { createMultipleClaims } from 'api/Billing';
import { completeSuperbill, deleteSuperbill } from 'api/Superbill';

import { ia, iaRa, io, pluralize } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';

import {
  COMPLETE_ERROR_MESSAGE,
  COMPLETE_SUCCESS_MESSAGE,
  DELETE_ERROR_MESSAGE,
  DELETE_SUCCESS_MESSAGE,
  READY_ERROR_MESSAGE,
  READY_FOR_CLAIM_MESSAGE,
  READY_VALIDATION_ERROR_MESSAGE
} from '../lib/helpers';

const SUPERBILLS_KEY = 'superbills';

const ButtonActions = ({ filters, selectedRows, setSelectedRows, errors, setErrors, loading }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const navigate = useNavigate();

  const selectedSuperbills = selectedRows.map((row) => row.data.id);

  const haveSelectedSuperbills = selectedSuperbills.length > 0;

  const pluralizedSuperbills = pluralize('superbill', selectedSuperbills);

  const queryClient = useQueryClient();

  const mutateDelete = useCustomMutation({
    mutationFn: () => deleteSuperbill(navigate, { selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: DELETE_SUCCESS_MESSAGE,
    errorMessage: DELETE_ERROR_MESSAGE,
    onSuccess: () => {
      setSelectedRows([]);
    }
  });

  const mutateComplete = useCustomMutation({
    mutationFn: () => completeSuperbill(navigate, { selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: COMPLETE_SUCCESS_MESSAGE,
    errorMessage: COMPLETE_ERROR_MESSAGE,
    onSuccess: () => {
      setSelectedRows([]);
    }
  });

  const mutateReadyForClaim = useCustomMutation({
    mutationFn: () => createMultipleClaims(navigate, { superBillIds: selectedSuperbills }),
    invalidateQueryKey: SUPERBILLS_KEY,
    successMessage: (data) => {
      const { errors = {}, superbills = [] } = data?.data || {};
      if (io(errors)) {
        return READY_VALIDATION_ERROR_MESSAGE(errors);
      }
      return READY_FOR_CLAIM_MESSAGE(superbills);
    },
    errorMessage: READY_ERROR_MESSAGE,
    onSuccess: (data) => {
      const errors = data?.data?.errors;
      if (errors) {
        setErrors(errors);
      }
      setSelectedRows([]);
    }
  });

  const checkSuperbillsForErrors = useCallback(() => {
    if (loading) return;

    const superbillKeys = queryClient
      .getQueriesData()
      .map(([key]) => key)
      .filter((key) => key[0] === 'superbills');

    if (!superbillKeys.length) return;

    superbillKeys.forEach((key) => {
      queryClient.setQueryData(key, (oldData) => {
        if (!ia(oldData?.superbills)) {
          return oldData;
        }
        return {
          ...oldData,
          superbills: oldData.superbills.map((row) =>
            row.claim_id && errors[row.claim_id] ? { ...row, error: errors[row.claim_id] } : row
          )
        };
      });
    });
  }, [loading, queryClient, errors]);

  useEffect(() => {
    checkSuperbillsForErrors(loading);
  }, [loading, checkSuperbillsForErrors]);

  const isDeleteLoading = mutateDelete.isLoading;
  const isCompleteLoading = mutateComplete.isLoading;
  const isReadyForClaimLoading = mutateReadyForClaim.isLoading;
  const isDisabled =
    !haveSelectedSuperbills || isReadyForClaimLoading || isDeleteLoading || isCompleteLoading;

  const hasComplete =
    iaRa(filters?.status?.values)?.some(({ value }) => value === 'complete') ||
    filters?.status?.values?.value === 'complete';

  return (
    <>
      <Tippy
        content="Select at least one item"
        placement="bottom"
        className="tippy-dark"
        disabled={haveSelectedSuperbills}>
        <div className="flex items-center gap-2">
          <Allowed requiredPermissions="superbill.delete">
            <Button
              data-qa="delete-selected-superbill"
              onClick={() => setShowConfirmation(true)}
              text="Delete"
              type="link"
              loading={isDeleteLoading}
              disabled={isDisabled}
              color="danger"
              icon="new-trash-patient"
              className="!text-danger-500"
              iconSize={18}
            />
          </Allowed>
          {!hasComplete && (
            <Allowed requiredPermissions="superbill.update">
              <Button
                data-qa="complete-selected-superbill"
                onClick={() => mutateComplete.mutate()}
                text="Mark Complete"
                type="link"
                loading={isCompleteLoading}
                disabled={isDisabled}
                icon="new-checkbox-rec"
                color="neutral"
                className="text-neutral-700"
                iconSize={18}
              />
            </Allowed>
          )}
          <Allowed requiredPermissions="superbill.update">
            <Button
              data-qa="complete-selected-superbill"
              onClick={() => mutateReadyForClaim.mutate()}
              text="Ready For Claim"
              loading={isReadyForClaimLoading}
              disabled={isDisabled}
              icon="new-send-bulk"
              iconColor="white"
              iconSize={18}
            />
          </Allowed>
        </div>
      </Tippy>
      {showConfirmation && (
        <Confirm
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          handleContinue={() => {
            mutateDelete.mutate();
            setShowConfirmation(false);
          }}
          title={`Delete ${pluralizedSuperbills}`}
          message={`Are you sure you want to delete the selected ${pluralizedSuperbills}?`}
          primaryBtnTxt={`Delete ${pluralizedSuperbills}`}
          secondaryBtnTxt="Close"
          icon="new-delete-patient"
          variant="danger"
        />
      )}
    </>
  );
};

export default ButtonActions;
