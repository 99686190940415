import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { Capitalize, formatDateAndTime, ia, mergeStrings } from '../../../../lib/helpers/utility';
import practiceState, { currentPractice } from '../../practiceState';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import TableCounter from '../../../shared/Table/TableCounter';

const LIMIT = 200;

export default function InsClaims({ grandTotalView }) {
  const [claims, setClaims] = useState([]);
  const [loading, setLoading] = useState(false);
  const filters = useRecoilValue(practiceState.reportingFilters);
  const [order, setOrder] = useState({
    patient: false,
    payer: false,
    payerId: false,
    date: false,
    claimId: false,
    provider: false,
    charge: false.valueOf,
    status: false,
    note: false
  });
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  useEffect(() => {
    getClaims();
  }, [filters]);

  const orderBy = (column) => {
    let orderedInsClaims = [];
    switch (column) {
      case 'patient':
        const fullName = claims?.map((row) => {
          return {
            ...row,
            fullName:
              row?.superbill?.patient?.fullName || mergeStrings(row?.pat_name_f, row?.pat_name_l)
          };
        });
        orderedInsClaims = fullName?.sort((a, b) => {
          return order[column]
            ? a.fullName.toUpperCase() > b.fullName.toUpperCase()
              ? 1
              : -1
            : b.fullName.toUpperCase() > a.fullName.toUpperCase()
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'status':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? claimStatus(a) > claimStatus(b)
              ? 1
              : -1
            : claimStatus(b) > claimStatus(a)
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'provider':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a.appointment?.practitioner?.fullName.toUpperCase() >
              b.appointment?.practitioner?.fullName.toUpperCase()
              ? 1
              : -1
            : b.appointment?.practitioner?.fullName.toUpperCase() >
              a.appointment?.practitioner?.fullName.toUpperCase()
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'payer':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.payer_name?.toUpperCase() > b?.payer_name?.toUpperCase()
              ? 1
              : -1
            : b?.payer_name?.toUpperCase() > a?.payer_name?.toUpperCase()
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'createdBy':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.superbill?.practitioner?.fullName?.toUpperCase() >
              b?.superbill?.practitioner?.fullName?.toUpperCase()
              ? 1
              : -1
            : b?.superbill?.practitioner?.fullName?.toUpperCase() >
              a?.superbill?.practitioner?.fullName?.toUpperCase()
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'method':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.type?.toUpperCase() > b?.type?.toUpperCase()
              ? 1
              : -1
            : b?.type?.toUpperCase() > a?.type?.toUpperCase()
              ? 1
              : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'date':
        orderedInsClaims = claims?.sort((a, b) => {
          if (order[column]) {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
          } else {
            return moment(b.created_at).isBefore(moment(a.created_at)) ? 1 : -1;
          }
        });
        setClaims(orderedInsClaims);
        break;
      case 'charge':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column] ? a.total_charge - b.total_charge : b.total_charge - a.total_charge;
        });
        setClaims(orderedInsClaims);
        break;
      case 'claimId':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column] ? a.id - b.id : b.id - a.id;
        });
        setClaims(orderedInsClaims);
        break;
      case 'payerId':
        const payerId = claims?.map((row) => {
          return { ...row, payerId: row?.payerid || row?.payer_icn };
        });
        orderedInsClaims = payerId?.sort((a, b) => {
          return order[column] ? a?.payerId - b?.payerId : b?.payerId - a?.payerId;
        });
        setClaims(orderedInsClaims);
        break;
      default:
        break;
    }
  };

  const getClaims = async () => {
    setLoading(true);
    if (filters) {
      try {
        let params = {
          limit: LIMIT,
          offset: 0,
          startDate: filters.selectedRange[0].startDate,
          endDate: filters.selectedRange[0].endDate
        };

        if (filters?.patient?.type == 'payer') {
          params = { ...params, payerId: filters?.patient?.id };
        } else {
          params = { ...params, patientId: filters?.patient?.id };
        }

        if (ia(filters?.patient)) {
          params = { ...params, multipleIds: filters?.patient };
        }

        let res = await interimApi(
          '/api/practice/billing/reporting/insurance_claims',
          params,
          navigate
        );
        if (res?.data?.claim) {
          setClaims(res?.data?.claim);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error: ', error);
      }
    }
  };

  const claimStatus = (claim) => {
    if (claim.state === 'accepted') {
      if (claim.insurance_payment.total > 0) {
        if (parseFloat(claim.total_charge * 100).toFixed(2) > claim.insurance_payment.total) {
          return 'Underpaid';
        } else {
          return 'Paid';
        }
      } else {
        return 'Not paid';
      }
    } else {
      return Capitalize(claim.state);
    }
  };

  if (loading) {
    return <Skeleton count={6} />;
  }

  return (
    <div className="grid overflow-hidden">
      <div className="overflow-auto !rounded-md relative">
        <table className="primary-table">
          <thead>
            <tr>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    patient: !order.patient
                  });
                  orderBy('patient');
                }}>
                Patient
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    payer: !order.payer
                  });
                  orderBy('payer');
                }}>
                Payer
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    payerId: !order.payerId
                  });
                  orderBy('payerId');
                }}>
                Payer ID
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    date: !order.date
                  });
                  orderBy('date');
                }}>
                Date of Service
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    claimId: !order.claimId
                  });
                  orderBy('claimId');
                }}>
                Claim ID
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    provider: !order.provider
                  });
                  orderBy('provider');
                }}>
                Provider
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    charge: !order.charge
                  });
                  orderBy('charge');
                }}>
                Charge
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    status: !order.status
                  });
                  orderBy('status');
                }}>
                Status
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    note: !order.note
                  });
                  orderBy('note');
                }}>
                Note
              </th>
            </tr>
          </thead>
          <tbody>
            {ia(claims) ? (
              claims?.map((claim, index) => (
                <tr key={index}>
                  <td>
                    {claim?.superbill?.patient?.fullName ||
                      `${claim?.pat_name_f} ${claim?.pat_name_l}`}
                  </td>
                  <td>{claim?.payer_name}</td>
                  <td>{claim?.payerid || claim?.payer_icn}</td>
                  <td>
                    {formatDateAndTime(claim?.created_at || claim?.from_dos, practice.timezone)}
                  </td>
                  <td>{claim?.id}</td>
                  <td>{claim?.superbill?.practitioner?.fullName}</td>
                  <td>${claim?.total_charge}</td>
                  <td>{claimStatus(claim)}</td>
                  <td>{claim?.notes}</td>
                </tr>
              ))
            ) : (
              <tr className="relative">
                <td>
                  <span className="empty">No data found!</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TableCounter page={1} limit={claims.length} count={claims.length} />
    </div>
  );
}
