import React from 'react';
import Switch from 'components/shared/Switch/Switch';
import cs from 'classnames';
import Skeleton from 'react-loading-skeleton';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { pm } from 'lib/helpers/utility';

const PermissionActions = ({ actions, permissionKey, highlight }) => {
  const { formik, actionsEnabled, setActionsEnabled } = useEditRoleContext();
  const permissions = useRecoilValue(state.permissions);

  const handleOnChange = (action, value) => {
    formik.setFieldValue(`permissions.${permissionKey}.${action}`, value);
    const activeActionRation = actionsEnabled[permissionKey];
    let [a, b] = activeActionRation.split('/');
    if (value) {
      a++;
    } else {
      a--;
    }
    setActionsEnabled({ ...actionsEnabled, [permissionKey]: `${a}/${b}` });
  };
  return (
    <div className="flex h-fit w-full flex-col items-center justify-start gap-px self-stretch rounded-md border border-neutral-100 bg-neutral-100">
      {!actions ? (
        <Skeleton rows={12} />
      ) : (
        Object.keys(actions)?.map((action) => {
          return (
            <div
              key={action}
              className={cs(
                'inline-flex items-center justify-start gap-2.5 self-stretch px-4 py-3',
                formik.values?.permissions?.[permissionKey][action] ? 'bg-white' : 'bg-neutral-50'
              )}>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
                <div className="self-stretch text-sm font-medium leading-snug text-primary-900">
                  <Highlight text={actions[action].title} highlight={highlight} />
                </div>
                <div className="self-stretch text-xs font-normal leading-tight text-neutral-500">
                  <Highlight text={actions[action].description} highlight={highlight} />
                </div>
              </div>
              <Switch
                size={18}
                disabled={!pm(permissions, 'role.update')}
                checked={formik.values?.permissions?.[permissionKey][action]}
                onChange={(value) => {
                  handleOnChange(action, value);
                }}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default PermissionActions;
