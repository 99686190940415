import React from 'react';
import moment from 'moment-timezone';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';

import Icon from '../../../../shared/Icon/Icon';
import Input from '../../../../shared/Input/Input';
import Select from '../../../../shared/Select/Select';
import { timeFilterOption } from '../../appointmentHelpers';

export default function AdditionalTime({
  times,
  index,
  hours,
  practice,
  deleteTime,
  handleDateTimeChange,
  onClickAdditionalDate
}) {
  const formattedTime = moment.tz(times.dateTime, practice?.timezone).format('hh:mm A');

  return (
    <div className="flex gap-[10px] border-x-0 border-b-0 border-t border-solid border-t-neutral-100 !pt-4">
      <Popover className="relative w-8/12">
        {({ close }) => (
          <>
            <Popover.Button className="w-full p-0">
              <Input
                label=""
                rightIcon="new-calendar-gray"
                id={`additional-date-${index}`}
                placeholder={moment.tz(times.dateTime, practice?.timezone).format('M/D/YYYY, dddd')}
              />
            </Popover.Button>

            <Popover.Panel className="absolute z-10 flex w-full shadow-md">
              <Calendar
                showDateDisplay
                className="w-full"
                onChange={(date) => {
                  close();
                  onClickAdditionalDate({ date, index });
                }}
                date={new Date(moment.tz(times.dateTime, practice?.timezone))}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      <Select
        isClearable={false}
        rightIcon="new-clock-gray"
        filterOption={timeFilterOption}
        parentClassName="relative w-4/12"
        value={{ label: formattedTime, value: formattedTime }}
        options={hours.map((hour) => ({ value: hour, label: hour }))}
        onChange={(val) => handleDateTimeChange({ newTime: val.value, index, type: 'time' })}
      />

      <div>
        <Icon
          icon="trash"
          onClick={() => deleteTime(index)}
          className="flex h-10 w-6 justify-center"
        />
      </div>
    </div>
  );
}
