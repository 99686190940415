import { createAllergiesNarrativeHelperV2 } from 'components/shared/Forms/Custom/lib/allergiesNarrativeHelper';
import { createFamilyHistoryNarrativeV2 } from 'components/shared/Forms/Custom/lib/familyHistoryNarrativeHelper';
import { formatSections as formatSectionsV1 } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV1';
import { createPastMedicalHistoryNarrative } from 'components/shared/Forms/Custom/lib/pastMedicalHistoryNarrativeHelper';
import { createSocialHistoryNarrative } from 'components/shared/Forms/Custom/lib/socialHistoryNarrativeHelper';
import { createSurgicalHistoryNarrativeV2 } from 'components/shared/Forms/Custom/lib/surgicalHistoryNarrativeHelper';
import { createVaccineNarrativeV2 } from 'components/shared/Forms/Custom/lib/vaccineNarrativeHelper';
import { createMedicationNarrative } from '../../lib/medicationNarrativeHelper';

const SECTION_V1 = 'weightLossAdministration';
const MEDICATIONS = 'medications';
const SOCIAL_HISTORY = 'socialHistory';
const MEDICAL_HISTORY = 'medicalHistory';
const FAMILY_HISTORY = 'familyHistory';
const VACCINE = 'vaccines';
const ALLERGIES = 'allergies';
const SURGICAL_HISTORY = 'surgicalHistory';

export const getNarrativeGenerateFN = (type) => {
  if (!type) return null;

  switch (type) {
    case SECTION_V1:
      return formatSectionsV1;
    case MEDICATIONS:
      return createMedicationNarrative;
    case SOCIAL_HISTORY:
      return createSocialHistoryNarrative;
    case MEDICAL_HISTORY:
      return createPastMedicalHistoryNarrative;
    case FAMILY_HISTORY:
      return createFamilyHistoryNarrativeV2;
    case VACCINE:
      return createVaccineNarrativeV2;
    case ALLERGIES:
      return createAllergiesNarrativeHelperV2;
    case SURGICAL_HISTORY:
      return createSurgicalHistoryNarrativeV2;
    default:
      break;
  }
};
