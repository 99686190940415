import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ia } from 'lib/helpers/utility';
import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';
import { useTableLimit } from 'lib/hooks/queries/useTableLimit';
import useWebSocket from 'lib/hooks/useMessageWebSocket';

import PreferredLocation from 'components/admin/pages/Practices/forms/PreferredLocation';
import { permissions } from 'components/state';

import Navbar from '../Navbar/Navbar';
import { notificationState } from '../Navbar/notificationState';
import Sidebar from '../Sidebars/Sidebar/Sidebar';

import { practiceSidebarLinks } from './lib/practiceSidebarLinks';
import practiceState from './practiceState';

const PracticePortal = () => {
  const [practice] = useRecoilState(practiceState.currentPractice);
  const ownerPractices = useRecoilValue(practiceState.ownerPractices);
  const ownerSelectedLocation = useRecoilValue(practiceState.ownerSelectedLocation);
  const setNotificationsState = useSetRecoilState(notificationState);
  const { pathname } = useLocation();
  const { appointmentId } = useParams();
  const { isFetched } = useDefaultFilters({});
  const { isFetched: isLimitFetched } = useTableLimit({});
  const isClinicalNote = pathname.includes(`clinical-notes/${appointmentId}/`);
  const messageSocketCallback = (event) => {
    const incomingMessage = JSON.parse(event.data);

    if (incomingMessage?.practice_id != practice?.id) return;

    if (incomingMessage?.type === 'messageCount') return;
    setNotificationsState((prev) => {
      return {
        ...prev,
        messageUnreadCount: Number(prev.messageUnreadCount) + 1,
        userId: incomingMessage.sender_id,
        userBody: incomingMessage.body
      };
    });
  };

  useWebSocket({ dependency: practice?.id, handleMessage: messageSocketCallback });

  const { is_admin } = useRecoilValue(permissions);

  if (isClinicalNote) return <Outlet />;

  if (ia(ownerPractices) && !ownerSelectedLocation) {
    return <PreferredLocation />;
  }

  return (
    <>
      {isFetched && isLimitFetched && (
        <section className="flex bg-white">
          <Sidebar list={practiceSidebarLinks(is_admin)} />
          <section className="relative h-[100vh] w-full overflow-hidden bg-neutral-50 hover:!border-neutral-100 active:!border-neutral-100">
            <Navbar />
            <main className="mt-[40px] flex h-[calc(100%-40px)] flex-col justify-between overflow-y-auto overflow-x-hidden">
              <Outlet />
            </main>
          </section>
        </section>
      )}
    </>
  );
};

export default PracticePortal;
