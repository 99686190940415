import React, { useMemo, useRef, useState } from 'react';

import useModal from 'lib/hooks/useModal';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import ScheduleOfFees from 'components/shared/ScheduleOfFees/ScheduleOfFees';
import BreadCrumbs from 'components/shared/Wizard/BreadCrumbs/BreadCrumbs';

import {
  STEPS,
  getButtonProps,
  initialBreadcrumbs
} from '../../practice/settings/Services/lib/modalHelpers';
import Icon from '../Icon/Icon';
import Typography from '../Typography/Typography';

import ReviewAndEditSOF from './ReviewAndEditSOF';
import SpecialtySOF from './SpecialtySOF';
import {
  CANCEL_BTN_TEXT,
  CONFIRM_BTN_TEXT,
  CONFIRM_TITLE,
  GENERATE_MESSAGE,
  SCHEDULE_OF_FEES_TITLE
} from './lib/helpers';

const ModalHeader = ({ breadcrumbs }) => (
  <div className="flex flex-wrap items-center gap-6">
    <span>{SCHEDULE_OF_FEES_TITLE}</span>
    <BreadCrumbs breadcrumbs={breadcrumbs} />
  </div>
);

const ScheduleOfFeesModal = ({ isOpen, closeModal }) => {
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [currentStep, setCurrentStep] = useState(STEPS.INTRO);
  const [isDisabled, setIsDisabled] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const scheduleOfFeesRef = useRef(null);
  const specialtySOFRef = useRef(null);
  const reviewAndEditSOFRef = useRef(null);

  const {
    isOpen: isConfirmOpen,
    openModal: openConfirmModal,
    closeModal: closeConfirmModal
  } = useModal();

  const onSelect = (isDisabled) => {
    setIsDisabled(isDisabled);
  };

  const updateBreadcrumbs = (finishedStep, nextStep) => {
    setBreadcrumbs((prev) =>
      prev.map((breadcrumb, index) => ({
        ...breadcrumb,
        isFinished:
          nextStep < finishedStep
            ? index <= nextStep
            : index === finishedStep || breadcrumb.isFinished,
        active: index === nextStep
      }))
    );
  };

  const handleNext = () => {
    if (currentStep === STEPS.INTRO) {
      openConfirmModal();
      return;
    }

    if (currentStep === STEPS.SELECT) {
      updateBreadcrumbs(STEPS.SELECT, STEPS.REVIEW);
      setCurrentStep(STEPS.REVIEW);
      setSelectedRows(scheduleOfFeesRef.current?.selectedRows);
      closeConfirmModal();
      return;
    }

    setCurrentStep(STEPS.REVIEW);
    reviewAndEditSOFRef.current?.saveServicesHandler?.();
    closeModal();
  };

  const confirmHandler = () => {
    specialtySOFRef.current?.saveServicesHandler?.();
    closeConfirmModal();
    closeModal();
  };

  const handleBack = () => {
    if (currentStep === STEPS.REVIEW) {
      updateBreadcrumbs(STEPS.REVIEW, STEPS.SELECT);
      setCurrentStep(STEPS.SELECT);
      setSelectedRows(reviewAndEditSOFRef.current?.rowData);
      return;
    }

    updateBreadcrumbs(STEPS.SELECT, STEPS.INTRO);
    setCurrentStep(STEPS.INTRO);
    setSelectedRows(specialtySOFRef.current?.selectedRows);
  };

  const { backButton, nextButton } = useMemo(
    () =>
      getButtonProps({
        currentStep,
        closeModal,
        handleBack,
        handleNext,
        isDisabled
      }),
    [currentStep, isDisabled]
  );

  const onEditServices = () => {
    updateBreadcrumbs(STEPS.INTRO, STEPS.SELECT);
    setCurrentStep(STEPS.SELECT);
    setSelectedRows(specialtySOFRef.current?.selectedRows);
  };

  return (
    <Modal
      title={<ModalHeader breadcrumbs={breadcrumbs} />}
      isOpen={isOpen}
      handleClose={closeModal}
      className="h-[90dvh] w-[90dvw]"
      slideFromRight
      isLarge
      bodyClassName="p-0"
      footer={
        <div className="flex w-full justify-between">
          <Button {...backButton} />
          <Button {...nextButton} />
        </div>
      }>
      {currentStep === STEPS.INTRO ? (
        <SpecialtySOF ref={specialtySOFRef} onEdit={onEditServices} onSelect={onSelect} />
      ) : currentStep === STEPS.SELECT ? (
        <ScheduleOfFees
          ref={scheduleOfFeesRef}
          defaultServices={selectedRows}
          onSelect={onSelect}
        />
      ) : (
        <ReviewAndEditSOF
          ref={reviewAndEditSOFRef}
          defaultServices={selectedRows}
          onSelect={onSelect}
        />
      )}
      {isConfirmOpen && (
        <Modal
          disableHeader
          isOpen={isConfirmOpen}
          title={CONFIRM_TITLE}
          message={GENERATE_MESSAGE(specialtySOFRef.current?.selectedRows?.length)}
          footer={
            <div className="flex w-full justify-between">
              <Button text={CANCEL_BTN_TEXT} onClick={closeConfirmModal} outlined size="small" />
              <Button text={CONFIRM_BTN_TEXT} onClick={confirmHandler} size="small" />
            </div>
          }>
          <div className="flex flex-col items-start gap-4 pt-3 pb-2 px-3">
            <div className="flex justify-between items-center gap-3 w-full">
              <Typography variant="h4" weight="medium" color="primary-900">
                {CONFIRM_TITLE}
              </Typography>
              <Icon
                icon="close"
                size={12}
                onClick={closeConfirmModal}
                customColor="text-neutral-500"
              />
            </div>
            <Typography variant="p" className="neutral-700">
              {GENERATE_MESSAGE(specialtySOFRef.current?.selectedRows?.length)}
            </Typography>
          </div>
        </Modal>
      )}
    </Modal>
  );
};

export default ScheduleOfFeesModal;
