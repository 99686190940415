import { useRecoilValue } from 'recoil';

import { formatDateAndTime, formatTime, mString, snakeToTitleCase } from 'lib/helpers/utility';

import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';

import { getServicesName } from '../lib';

const ExportTable = ({ appointments }) => {
  const appointmentStatuses = useRecoilValue(aptStatuses);

  return (
    <table className="primary-table">
      <thead>
        <tr className="w-full">
          <th className="w-[30%] !text-xs">Datetime</th>
          <th className="w-[20%] !text-xs">Patient</th>
          <th className="w-[20%] !text-xs">Service</th>
          <th className="!text-xs">Balance</th>
          <th className="w-[20%] !text-xs">Provider</th>
          <th className="!text-xs">Status</th>
        </tr>
      </thead>
      <tbody>
        {appointments.map((appointment, i) => {
          const { starts_at, ends_at, patient, practitioner, status, invoice } = appointment || {};
          return (
            <tr key={i}>
              <td className="!text-xs">
                {formatDateAndTime(starts_at) + ' - ' + formatTime(ends_at)}
              </td>
              <td className="!text-xs">{patient?.fullName}</td>
              <td className="!text-xs">{getServicesName(appointment.invoice?.procedures)}</td>
              <td className="!text-xs">{mString(invoice?.balance || 0)}</td>
              <td className="!text-xs">{practitioner?.fullName}</td>
              <td className="!text-xs">
                {appointmentStatuses.find((st) => st.value === status)?.label ||
                  snakeToTitleCase(status)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;
