import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from '../../../../../../../lib/helpers/utility';

const Diagnosis = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (!ia(selectedTreatmentPlan.icd10)) return null;

  return (
    <div className="grid gap-2">
      <h3 className="text-base font-500 text-neutral-800">Diagnosis</h3>
      <div className="grid">
        <p className="!pb-1 text-sm text-neutral-700 first-letter:capitalize">ICD-10 codes:</p>
        <ul>
          {selectedTreatmentPlan.icd10.map((code, idx) => (
            <li
              key={idx}
              className="float-left ml-[14px] list-inside list-disc text-sm font-500 text-neutral-800 first-letter:capitalize first-of-type:ml-0">
              {code}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Diagnosis;
