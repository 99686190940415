import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useAiPromptTemplate } from 'lib/hooks/queries/admin/intelligence/useAiPromptTemplate';

import AdminIntelligencePromptTable from 'components/admin/pages/Intelligence/Prompt/table';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Loader from 'components/shared/Loader/Loader';
import Modal from 'components/shared/Modal/Modal';

import { promptCols } from './helpers';

const AdminIntelligencePromptDescriptionLogic = () => {
  const { setFilters } = useTableContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageSize, page, sort, filters } = useTableContext();
  const [promptId, setPromptId] = useState();
  const { isLoading, isFetching, data } = useAiPromptTemplate({
    params: {
      id: promptId,
      pageSize,
      page,
      sort,
      filters: mapValues(filters)
    },
    options: {},
    dependencies: [pageSize, page, mapValues(filters), promptId],
    // dependencies: [limit, page],
    name: `ai_prompt_template_${promptId}`
  });

  useEffect(() => {
    if (searchParams.get('promptId')) {
      getMyScribeAIPrompt();
    }
  }, [searchParams]);

  const getMyScribeAIPrompt = async () => {
    const paramPromptId = parseInt(searchParams?.get('promptId'), 10);
    setPromptId(paramPromptId);
    setFilters((ps) => ({ ...ps, promptId: paramPromptId }));
  };

  return (
    <Modal
      slideFromRight
      isOpen={typeof promptId === 'number'}
      handleClose={() =>
        setSearchParams((sp) => {
          setPromptId(null);
          sp.delete('promptId');
        })
      }
      className="w-3/4"
      title={`Prompt ${data?.description?.name}:${data?.description?.id}`}>
      {(isLoading || isFetching) && (
        <div className="flex flex-row gap-2">
          <Loader outlined color="primary" />
          <div>Loading...</div>
        </div>
      )}
      {!(isLoading || isFetching) && data?.description?.description && (
        <div>
          <div>Description:</div>
          <div>{data?.description?.description}</div>
        </div>
      )}
      {!(isLoading || isFetching) && data?.description?.path && (
        <div>
          <strong>Path:</strong>
          <code>{data?.description?.path}</code>
        </div>
      )}
      <AdminIntelligencePromptTable />
    </Modal>
  );
};

const AdminIntelligencePromptDescription = (props) => {
  return (
    <TableContextProvider
      name="admin_intelligence_ai_prompt_template_description_table"
      defaultFilters={[]}
      cols={promptCols}
      {...props}>
      <AdminIntelligencePromptDescriptionLogic />
    </TableContextProvider>
  );
};

export default withErrorBoundary(AdminIntelligencePromptDescription);
