import React from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useTransactions } from 'lib/hooks/queries/billing/useTransactions';

import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import { permissions as userPermissions } from 'components/state';

import TransactionResponseModal from './TransactionResponseModal';
import { DEFAULT_FILTERS, getColDefs } from './configs';

const Transactions = ({ patient_id }) => {
  const { id } = useParams();
  const patientId = patient_id || id;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const permissions = useRecoilValue(userPermissions);

  const cols = getColDefs(practiceTimezone, permissions);

  return (
    <AgTablePage
      ModalComponent={TransactionResponseModal}
      name="transactions"
      patientId={patientId}
      defaultColDef={cols}
      useData={useTransactions}
      defaultFilters={DEFAULT_FILTERS}
      defaultColumns={cols}
      rowStyle={{ cursor: 'pointer' }}
      queryParams={{ patientId }}
      noTitle
      noNewButton
    />
  );
};

export default Transactions;
