import NotFound from 'components/NotFound';
import React from 'react';

const Documentation = () => {
  if (process.env.ENV !== 'local') return <NotFound className="mt-10" />;

  return (
    <iframe
      src="http://localhost:6006"
      style={{
        width: '100%',
        height: '100vh',
        border: 'none'
      }}
      title="Storybook"
    />
  );
};

export default Documentation;
