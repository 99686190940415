import Status from 'components/shared/Status/Status';

import { payerKinds } from './lib/constants';

const PayerKind = ({ kind }) => {
  const payerKind = payerKinds?.[kind];

  if (!payerKind) return null;

  const { status, color } = payerKind;

  return (
    <div className="flex items-center h-full">
      <Status status={status} textClassName="block" color={color} />
    </div>
  );
};

export default PayerKind;
