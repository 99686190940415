function formatNumber(value, index, setFieldValue, returnable = false) {
  const input = value;
  const numericValue = input?.replace(/[^+\d]/g, '');
  let formattedValue = '';

  if (numericValue?.length < 10) {
    if (returnable) {
      return '';
    }
    setFieldValue(`fax_numbers[${index}].fax_number`, '');
    return;
  }
  if (numericValue?.startsWith('+1')) {
    // If it starts with '+1', remove the first two characters and then format
    formattedValue = `(${numericValue?.slice(2, 5)}) ${numericValue?.slice(
      5,
      8
    )}-${numericValue?.slice(8, 12)}`;
  } else if (numericValue?.length >= 10) {
    formattedValue = `(${numericValue?.slice(0, 3)}) ${numericValue?.slice(
      3,
      6
    )}-${numericValue?.slice(6, 10)}`;
  } else {
    formattedValue = numericValue;
  }

  if (returnable) {
    return formattedValue;
  }

  setFieldValue(`fax_numbers[${index}].fax_number`, formattedValue);
}
export default formatNumber;
