import Popover from 'components/shared/Popovers/Popover/Popover';
import Select from 'components/shared/Select/Select';
import { appointmentStatuses } from 'constants';
import React, { useState } from 'react';
import MultiActions from './MultiActions';

const HeaderActions = ({ selectedRows, setSelectedRows }) => {
  const [type, setType] = useState();
  const [appointmentStatus, setAppointmentStatus] = useState();
  const [multiActionsModal, setMultiActionsModal] = useState({
    appointmentCreation: false,
    appointmentReminder: false,
    appointmentCancel: false,
    appointmentStatus: false
  });

  const appointmentOptions = [
    {
      label: 'Resend Appointment Creation',
      icon: 'calendar-tick',
      children: [
        {
          label: 'Email',
          icon: 'new-email-blue',
          onClick: () => {
            setType('email');
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentCreation: true
            }));
          }
        },
        {
          label: 'Text Message',
          icon: 'text-message',
          onClick: () => {
            setType('email');
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentCreation: true
            }));
          }
        }
      ]
    },
    {
      label: 'Resend Appointment Reminder/Forms',
      icon: 'calendar-reminder',
      children: [
        {
          label: 'Email',
          icon: 'new-email-blue',
          onClick: () => {
            setType('email');
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentReminder: true
            }));
          }
        },
        {
          label: 'Text Message',
          icon: 'text-message',
          onClick: () => {
            setType('text_message');
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentReminder: true
            }));
          }
        }
      ]
    },
    {
      label: 'Cancel Appointment',
      icon: 'calendar-remove',
      onClick: () =>
        setMultiActionsModal((prevState) => ({
          ...prevState,
          appointmentCancel: true
        }))
    }
  ];
  return (
    <>
      <Popover
        className="flex flex-1 justify-center self-center"
        buttonClassName="!bg-primary-700"
        position="left"
        isFixed
        options={appointmentOptions}
      />
      <Select
        placeholder="Appointment Status"
        options={appointmentStatuses}
        isClearable={true}
        menuPortalTarget={document.body}
        parentClassName="font-400 w-[300px]"
        onChange={(e) => {
          setAppointmentStatus(e?.value);
          setMultiActionsModal((prevState) => ({
            ...prevState,
            appointmentStatus: true
          }));
        }}
        value={appointmentStatus}
      />
      <MultiActions
        multiActionsModal={multiActionsModal}
        setMultiActionsModal={setMultiActionsModal}
        appointments={selectedRows}
        setSelectedRows={setSelectedRows}
        appointmentStatus={appointmentStatus}
        type={type}
      />
    </>
  );
};

export default HeaderActions;
