import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { useRecoilState } from 'recoil';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Modal from 'components/shared/Modal/Modal';
import state from '../state';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';

const NewTerminal = ({ practiceId, handleSuccess, handleOpen, handleClose }) => {
  const [newTerminal, setNewTerminal] = useState({
    pin: '',
    merchant_id: '',
    vendor_id: '',
    user_id: '',
    brand: '',
    kind: '',
    name: '',
    online: false,
    note: '',
    group_id: null
  });
  const [show, setShow] = useState({ groupLoading: false, newTerminalOpen: false });
  const [selectedTerminalGroup, setSelectedTerminalGroup] = useState(null);
  const [terminalGroups, setTerminalGroups] = useRecoilState(state.terminalGroupState);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const navigate = useNavigate();

  const addNewTerminal = async () => {
    try {
      const onSuccess = ({ terminalId }) => {
        showAlert({ title: 'Successfully added a new terminal!', color: 'primary' });
        handleSuccess({ ...newTerminal, id: terminalId });
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
      };

      await requestApi({
        url: `/api/transactions/terminal/add`,
        params: {
          data: { ...newTerminal, practice_id: practiceId }
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/newTerminal, method: addNewTerminal - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleOnChange = (e) => {
    setNewTerminal({ ...newTerminal, [e.target.name]: e.target.value });
  };

  const onCreateGroup = async (newGroupName) => {
    let newShow = Object.assign({}, show);
    const onSuccess = ({ group }) => {
      setNewTerminal({ ...newTerminal, group_id: group.value });
      setSelectedGroup(group);
      setTerminalGroups([...terminalGroups, group]);
    };
    const onError = (error) => {
      showAlert({ title: error, color: 'danger' });
    };
    try {
      await requestApi({
        url: '/api/transactions/group/add',
        params: {
          practiceId: practiceId,
          groupName: newGroupName
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/newTerminal, method: onCreateGroup - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
    setShow(newShow);
  };

  return (
    <Modal
      slideFromRight
      handleOpen={handleOpen}
      handleClose={handleClose}
      title="New Terminal"
      footer={
        <>
          <Button warning onClick={handleClose}>
            Cancel
          </Button>
          <Button green onClick={addNewTerminal}>
            Add Terminal
          </Button>
        </>
      }>
      <div>
        <div>Name</div>
        <input value={newTerminal.name} onChange={handleOnChange} name="name" />
      </div>
      <div>
        <div>PIN</div>
        <input value={newTerminal.pin} onChange={handleOnChange} name="pin" />
      </div>
      <div>
        <div>Vendor ID</div>
        <input value={newTerminal.vendor_id} onChange={handleOnChange} name="vendor_id" />
      </div>
      <div>
        <div>Merchant ID</div>
        <input value={newTerminal.merchant_id} onChange={handleOnChange} name="merchant_id" />
      </div>
      <div>
        <div>User ID</div>
        <input value={newTerminal.user_id} onChange={handleOnChange} name="user_id" />
      </div>
      <div>
        <div>Brand</div>
        <input value={newTerminal.brand} onChange={handleOnChange} name="brand" />
      </div>
      <div>
        <div>Kind</div>
        <input value={newTerminal.kind} onChange={handleOnChange} name="kind" />
      </div>
      <div>
        <div>Group</div>
        <CreatableSelect
          isClearable
          isDisabled={show.groupLoading}
          isLoading={show.groupLoading}
          onChange={(e) => {
            setNewTerminal({ ...newTerminal, group_id: e?.value || null });
            setSelectedTerminalGroup(e);
          }}
          onCreateOption={onCreateGroup}
          options={terminalGroups}
          value={selectedTerminalGroup}
          style={{ width: '15rem' }}
        />
      </div>
      <div>
        <div>Notes</div>
        <textarea value={newTerminal.note} onChange={handleOnChange} name="note" />
      </div>
      <div>
        <Checkbox
          label="Online Terminal"
          checked={newTerminal.online}
          onChange={(v) => setNewTerminal({ ...newTerminal, online: v.target.checked })}
        />
      </div>
    </Modal>
  );
};

export default NewTerminal;
