import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import React from 'react';
import SuperBillPage from './SuperBillPage';

const SuperbillModal = ({ handleClose, superbill_id, patientId }) => {
  return (
    <Modal
      slideFromRight
      isOpen
      handleClose={handleClose}
      isLarge
      className="sm:w-full"
      title="Superbill"
      headerClassName="z-10"
      footer={
        <div className="flex w-full flex-wrap items-center justify-between gap-4 sm:gap-2">
          <Button
            text="Cancel"
            onClick={handleClose}
            color="neutral"
            transparent
            outlined
            data-qa="cancel-btn"
          />
        </div>
      }>
      <SuperBillPage superbill_id={superbill_id} patientId={patientId} />
    </Modal>
  );
};

export default SuperbillModal;
