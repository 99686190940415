import React from 'react';
import Accordion from '../../../../shared/Accordion';
import ActiveSymptoms from './ActiveSymptoms';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const ActiveSymptomsSection = ({ components, sectionRef, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{
        name: 'Active Symptoms',
        parentKey: 'progress',
        childKey: 'activeSymptoms'
      }}
      title={components?.activeSymptoms?.title}
      id={components?.activeSymptoms?.id}
      disabled={clinicalNote?.locked}>
      <ActiveSymptoms sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ActiveSymptomsSection;
