import { requestApi } from './Api';

export const getSupervision = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/supervision/get',
    params,
    throwError: true
  });

export const updateSupervision = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/supervision/update',
    params,
    throwError: true
  });

export const submitSupervision = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/supervision/create',
    params: params,
    throwError: true
  });
