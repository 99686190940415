import updateAutoMapTemplate from './lib/updateAutoMapTemplate';
import updateClinicalNote from './lib/updateClinicalNote';

export default (data) => {
  switch (data?.vertical) {
    case 'clinical_narrative':
      updateClinicalNote(data);
      break;
    case 'auto_map':
      updateAutoMapTemplate(data);
      break;
    default:
      updateClinicalNote(data);
      break;
  }
};
