import React from 'react';

const InstructionsPreview = ({ itemProps }) => {
  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Instructions</div>

      <ul className="!pl-5">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap">
          {itemProps?.instructions ?? 'N/A'}
        </li>
      </ul>
    </div>
  );
};

export default InstructionsPreview;
