import moment from 'moment';
import { calculateUnitTimeLength } from './calculateUnitTimeLength';
export const handleUnitValue = (
  procedure,
  isTimeUnit,
  formattedStartTime,
  formattedEndTime,
  updateProcedures,
  index,
  manualChange = false
) => {
  if (isTimeUnit && procedure?.unit_time_length) {
    return procedure?.unit_time_length;
  } else if (procedure?.base_unit && isTimeUnit) {
    return calculateUnitTimeLength(
      procedure?.start_time || formattedStartTime,
      procedure?.end_time || formattedEndTime
    );
  } else if (procedure?.base_unit && !isTimeUnit && !manualChange) {
    const start = moment(formattedStartTime, 'hh:mm A');
    const end = moment(formattedEndTime, 'hh:mm A');
    const differenceInMinutes = end.diff(start, 'minutes');
    const unitDifference = differenceInMinutes / 15;
    const formattedUnit = Math.round(unitDifference.toFixed(2));
    const newUnit = formattedUnit + procedure?.base_unit;
    if (procedure?.units !== newUnit) {
      updateProcedures({ target: { value: newUnit, name: 'units' } }, index);
    }

    return newUnit;
  } else {
    return procedure?.units;
  }
};
