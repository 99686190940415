import { Capitalize, formatDate, formatDateAndTime } from 'lib/helpers/utility';

export const cols = [
  { field: 'id', headerName: 'ID', maxWidth: 100 },
  {
    field: 'status',
    headerName: 'Status',
    valueFormatter: ({ value }) => Capitalize(value)
  },
  { field: 'prompt_template_id', headerName: 'Prompt ID', maxWidth: 150 },
  { field: 'prompt_template_version', headerName: 'Prompt Version', maxWidth: 150 },
  { field: 'token_count', headerName: 'Req Token Count' },
  { field: 'eval_token_count', headerName: 'Res Token Count' },
  { field: 'request', headerName: 'Res Token Count' },
  { field: 'response', headerName: 'Res Token Count' },
  {
    field: 'created_at',
    headerName: 'Requested At',
    valueFormatter: ({ value }) => formatDateAndTime(value)
  }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'AI Task Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  status: {
    type: 'search',
    multiple: true,
    options: [
      { label: 'Initiated', value: 'initiated' },
      { label: 'Processing', value: 'processing' },
      { label: 'Complete', value: 'complete' }
    ]
  }
};

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: true
};
export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'never',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};
