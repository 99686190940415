import React, { useState } from 'react';
import Icon from '../../../../../../shared/Icon/Icon';
import WeightLossAdministration from '../../../../../../shared/Forms/Custom/WeightLossAdministration/WeightLossAdministration';
import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Buttons/Button';
import { useParams } from 'react-router-dom';
import Axios from '../../../../../../../configuredAxios';
import toast from 'react-hot-toast';
import { syncHpOverviewTemplates } from '../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { ia } from '../../../../../../../lib/helpers/utility';

const WeightLossAdministrationModal = ({
  defaultValue,
  setCustomResponses,
  setCurrentHpOverviewData,
  formId
}) => {
  const [currentFormData, setCurrentFormData] = useState(defaultValue);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id, appointmentId } = useParams();

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId,
        patientId: id,
        appointmentId: appointmentId,
        response: currentFormData,
        type: 'weightLossAdministration'
      });

      const { newResponse } = result.data;
      if (newResponse) {
        toast.success('Successfully saved!');
        syncHpOverviewTemplates({
          formId,
          type: 'weightLossAdministration',
          setOverviewData: setCustomResponses,
          setCurrentHpOverviewData,
          customFormResponse: newResponse
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {ia(defaultValue?.row) ? (
        <Icon icon="new-edit-gray" onClick={() => setShowModal(true)} />
      ) : (
        <Icon icon="new-plus" onClick={() => setShowModal(true)} />
      )}
      <Modal
        slideFromRight
        handleOpen={showModal}
        title="Weight loss administration"
        className="w-[850px]"
        handleClose={() => {
          setShowModal(false);
        }}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              text="Close"
              transparent
              outlined
              onClick={() => {
                setShowModal(false);
              }}
            />
            <Button text="Save" onClick={() => submitForm()} />
          </div>
        }>
        {loading ? (
          <div>
            <Skeleton count={1} height="74px" />
            <Skeleton count={1} height="300px" />
            <Skeleton count={1} height="300px" />
          </div>
        ) : (
          <div>
            <div className="!pt-6">
              <WeightLossAdministration
                fromClinicalNote={true}
                formId={formId}
                setItemModal={setShowModal}
                defaultValue={defaultValue}
                setCurrentFormData={setCurrentFormData}
                setCurrentHpOverviewData={setCurrentHpOverviewData}
              />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default WeightLossAdministrationModal;
