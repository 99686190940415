import React from 'react';
import cs from 'classnames';

import { ia } from 'lib/helpers/utility';
import { getValidityLength } from '../lib';
import RenderServices from './RenderServices';

const ServicesFromPackages = ({ packageList, values, newItems, setNewItems, additionalTimes }) => {
  return packageList.map((invoicePackage) => {
    const { id: invoiceId, name, procedures, packages, created_at } = invoicePackage || {};

    return (
      <div
        key={invoiceId}
        className="m-2 !mb-2 grid w-[calc(100%-16px)] !gap-2 rounded-lg bg-primary-50 !p-2">
        {ia(procedures) && (
          <div className="!mb-2 grid w-full !gap-2">
            <label className="text-sm font-500 text-primary-500">{name}</label>

            <RenderServices
              {...{ procedures, invoiceId, newItems, setNewItems, values, additionalTimes }}
            />
          </div>
        )}

        {packages?.map?.((pack) => {
          const { id: packageId, name: packageName, procedures: packageProcedures } = pack || {};

          return (
            <div key={invoiceId * packageId} className="!mb-2">
              <div className="grid w-full !gap-2">
                <div className="flex items-center justify-between">
                  <label className="text-xs font-500 text-primary-500">{packageName}</label>
                  <label
                    className={cs(
                      'text-xs font-400',
                      `text-${getValidityLength(pack, created_at, true)}-600`
                    )}>
                    {getValidityLength(pack, created_at)}
                  </label>
                </div>

                <RenderServices
                  {...{
                    values,
                    newItems,
                    invoiceId,
                    packageId,
                    setNewItems,
                    additionalTimes,
                    procedures: packageProcedures
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  });
};

export default ServicesFromPackages;
