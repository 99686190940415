import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

const useNoteType = () => {
  const { typeOfNotes } = useMyScribeAIContext();
  const { setSelectedPromptNoteType } = useChatContextApi();
  const { selectedPromptNoteType } = useChatContextData();

  const onNoteTypeClick = (noteType) => {
    setSelectedPromptNoteType(noteType);
  };

  return { setSelectedPromptNoteType, selectedPromptNoteType, typeOfNotes, onNoteTypeClick };
};

export default useNoteType;
