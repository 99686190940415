import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { DEFAULT_COLUMN_DEFS, getColDefs, GRID_OPTIONS } from './configs';
import { usePatientForms } from 'lib/hooks/queries/myscribeAI/usePatientForms';
import { useParams } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import {
  useChatContextData,
  useChatContextApi
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import ModalFooter from '../../../../../../../../shared/Modal/ModalFooter/ModalFooter';
import EmptyPatientForms from './components/EmptyPatientForms';
import { cloneDeep } from 'lodash';
import Icon from 'components/shared/Icon/Icon';

export function Table({ breadcrumb }) {
  const { setSteps, setStep } = breadcrumb;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows, onRemoveComponent } =
    useChatContextApi();
  const selectedTableNodes = selectedTableRows?.['patient_forms']?.selectedNodes;

  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedRowsData = selectedNodes.map((node) => node.data);

  const { appointmentId } = useParams();

  const { data = {} } = usePatientForms({
    params: {
      appointmentId
    },
    dependencies: [appointmentId]
  });

  const { gridApi } = useTableContext();

  const forms = data?.forms;

  useEffect(() => {
    setSteps([
      {
        title: 'Patient Forms',
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      setSelectedNodes(selectedTableNodes);
      selectedTableNodes?.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  const deletePatientFormsSelectedTableRows = useCallback(() => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['patient_forms'];
    setSelectedTableRows(newSelectedTableRows);
  }, [selectedTableRows, setSelectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;

    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  }, [gridApi]);

  const onDeletePatientFormsTag = () => {
    deletePatientFormsSelectedTableRows();
    onRemoveComponent();
    setComponentToAdd(null);
  };

  const onAddTag = () => {
    if (!selectedNodes?.length) {
      deletePatientFormsSelectedTableRows();
      onRemoveComponent();
    }

    setSelectedTableRows((prev) => ({
      ...prev,
      patient_forms: {
        selectedNodes,
        data: selectedNodes.map((node) => node.data),
        status: !selectedNodes?.length
          ? 'none'
          : selectedNodes?.length === forms?.length
            ? 'all'
            : 'partial'
      }
    }));
    onAddComponent();
  };

  return (
    <div className="ag-theme-quartz forms_table !mb-0 h-full">
      <AGTable
        onSelectionChanged={onSelectionChanged}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDef={getColDefs(practiceTimezone)}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={forms}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        reactiveCustomComponents
        noRowsOverlayComponent={EmptyPatientForms}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px]">
            <Skeleton count={24} />
          </div>
        )}
      />
      {forms?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon
                key={1}
                icon="trash"
                className="cursor-pointer"
                onClick={onDeletePatientFormsTag}
              />
            ),
            <Button
              key={2}
              text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Patient Forms`}
              disabled={!selectedRowsData?.length && !selectedTableNodes?.length}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!ml-4 !py-2 !pl-4 !pr-4"
              onClick={onAddTag}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}
