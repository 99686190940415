import { useEffect, useMemo, useRef } from 'react';
import { ia } from 'lib/helpers/utility';

export const useYearChange = ({ value, isRange, isOpen, calendarRef }) => {
  // Ref to store the previous year value
  const previousYearRef = useRef(null);

  // Memoize the current year value
  const currentYear = useMemo(() => {
    if (!value || !isRange) return null;
    return ia(value) ? new Date(value[0])?.getFullYear() : new Date(value)?.getFullYear();
  }, [value, isRange]);

  // Auto focus to next month if year is valid and isRange is true
  useEffect(() => {
    if (value && isRange && isOpen && currentYear) {
      // If previousYear is not set, initialize it
      if (previousYearRef.current === null) {
        previousYearRef.current = currentYear;
        return;
      }

      // Check if year has changed
      if (currentYear !== previousYearRef.current) {
        const monthInputs =
          calendarRef.current?.parentNode?.parentNode?.parentNode?.querySelectorAll(
            'input[name="month"]'
          );

        // get the second month input with index 1
        const secondMonthInput = monthInputs?.[1];
        if (secondMonthInput) secondMonthInput.focus();

        // Update the previous year reference
        previousYearRef.current = currentYear;
      }
    }
  }, [value, isRange, isOpen, currentYear, calendarRef]);
};
