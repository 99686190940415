import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { deleteMember } from 'api/Member';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useStaffPracticeAdmin } from 'lib/hooks/queries/practice/useStaffPracticeAdmin';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';

export default function StaffTable({
  show,
  setShow,
  selectedStaff,
  deletePracticeStaffModal,
  setDeletePracticeStaffModal
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const {
    data = {},
    isLoading,
    isFetching
  } = useStaffPracticeAdmin({
    params: {
      practice_id: id,
      searchTerm: debouncedSearchTerm,
      page,
      sort,
      limit,
      withCount: true
    },
    dependencies: [debouncedSearchTerm, page, sort, limit, id],
    page,
    sort
  });
  const members = data?.members;

  const mutateDelete = useMutation({
    mutationFn: () => deleteMember(navigate, { memberId: selectedStaff.id, practiceId: id }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['staffPracticeAdmin']);
          setDeletePracticeStaffModal(null);
          showAlert({ title: 'Member deleted successfully', color: 'success' });
          break;
        default:
          showAlert({ title: 'Failed to delete practice member.', color: 'danger' });
          break;
      }
    }
  });
  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <div className="!mt-2 ml-auto flex items-center gap-2">
        <DisplayButton />
        <Input
          data-qa="search-staff"
          type="string"
          placeholder="Search staff..."
          value={searchTerm}
          icon="new-search"
          inputWrapperClassName="h-[30px]"
          className="md:w-[200px]"
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setPage(1);
          }}
          rightText={
            searchTerm && (
              <Icon
                icon="new-close"
                onClick={() => {
                  setSearchTerm('');
                  setPage(1);
                }}
              />
            )
          }
        />
        <Allowed requiredPermissions="member.create">
          <Button
            data-qa="new-staff-member-btn"
            size="small"
            type="primary"
            className="mr-3"
            text="New staff member"
            onClick={() => setShow({ ...show, newMember: true })}
          />
        </Allowed>
      </div>
      <div className="flex h-[94%] flex-col  overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable data={members} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
          {!!deletePracticeStaffModal && (
            <Confirm
              variant="danger"
              primaryBtnTxt="Delete"
              title="Delete member"
              icon="new-document-remove-red"
              message="Are you sure you want to delete this member?"
              handleContinue={() => {
                mutateDelete.mutate();
                setDeletePracticeStaffModal(false);
              }}
              handleOpen={!!deletePracticeStaffModal}
              handleClose={() => setDeletePracticeStaffModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
