import { remindAppointment } from 'api/Appointment';
import { showAlert } from 'components/shared/Alert/Alert';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { kioskCommand, kioskStartCheckin } from 'api/Kiosk';
import { useKiosks } from 'lib/hooks/queries/useKiosks';
import { useRecoilValue } from 'recoil';
import { appointmentStatuses as aptStatutes } from 'components/practice/practiceState';
import { ia } from 'lib/helpers/utility';

const useTimelineActions = (target, setAptEndedModalVisible) => {
  const appointmentStatuses = useRecoilValue(aptStatutes);
  const navigate = useNavigate();
  const { values, setFieldValue, initialValues } = useFormikContext();
  const { data } = useKiosks({ params: {} });
  const kiosks = data?.kiosks || [];
  const handleRemind = async (type = '') => {
    const params = { appointment_id: values?.id, type };
    await remindAppointment(navigate, params);
    showAlert({ message: 'Appointment reminder sent successfully', color: 'success' });
  };

  const handleToggleConfirmation = (confirmed) => {
    setFieldValue('confirmed', confirmed);

    handleChangeAptStatus(confirmed ? 'confirmed' : 'unconfirmed');
  };

  const sendCheckinToKiosk = async (kioskId) => {
    try {
      const res = await kioskStartCheckin(navigate, {
        kioskId,
        appointmentId: values?.id
      });

      if (res.redirectTo) {
        await kioskCommand(navigate, {
          kioskId,
          command: 'url-change',
          page: 'checkin',
          patientId: values?.patient?.id,
          appointmentId: values?.id,
          url: res.redirectTo
        });
      }
      showAlert({ title: 'Sent to Kiosk', color: 'success' });
    } catch (error) {
      showAlert({ title: 'Failed to send to Kiosk', color: 'danger' });
    }
  };

  const handleChangeAptStatus = (status) => {
    let timeline = structuredClone(values.timeline);
    let timelineBar = structuredClone(values.timeline_bar);

    const aptStatus = appointmentStatuses.find((aptStatus) => aptStatus.value == status);
    if (!aptStatus) return;

    // Update the timeline object
    for (const category in timeline) {
      if (timeline[category][target]) {
        timeline[category][target].value = aptStatus.value;
        timeline[category][target].name = aptStatus.label;
        timeline[category][target].color = aptStatus.color;
        updateTimelineStatus(aptStatus, target);
        break;
      }
    }
    // Update the timeline_bar object
    for (let i = 0; i < timelineBar.length; i++) {
      if (timelineBar[i].name === target) {
        timelineBar[i].label = aptStatus.value;
        timelineBar[i].color = aptStatus.color;
        break;
      }
    }

    setFieldValue('timeline', timeline);
    setFieldValue('timeline_bar', timelineBar);
  };

  const updateTimelineStatus = (status, target) => {
    const timeline = structuredClone(initialValues.timeline);
    for (const group in timeline) {
      if (timeline[group][target]) {
        const groupTarget = timeline[group][target];
        let timeline_status = values.timeline_status || {};
        if (groupTarget.name !== status.label) {
          timeline_status = {
            ...timeline_status,
            [target]: {
              status: status.color,
              name: status.label,
              value: status.value
            }
          };
        } else {
          delete timeline_status[target];
        }

        setFieldValue('timeline_status', timeline_status);
        break;
      }
    }
  };

  const balanceOptions = [
    {
      value: 'balance_not_paid',
      label: 'Balance Not Paid',
      color: 'danger',
      property_of: 'financial'
    },
    {
      value: 'balance_partially_paid',
      label: 'Balance Partially Paid',
      color: 'warning',
      property_of: 'financial'
    },
    { value: 'balance_paid', label: 'Balance Paid', color: 'success', property_of: 'financial' }
  ].filter((it) => it.label !== values.timeline.financial.balance.name);

  const options = {
    confirmed: [
      {
        label: values.confirmed ? 'Unconfirmed' : 'Confirmed',
        onClick: () => handleToggleConfirmation(!values.confirmed),
        icon: values.confirmed ? 'new-close-v2' : 'new-check',
        color: values.confirmed ? 'danger' : 'success',
        size: 20,
        stroke: true
      },
      {
        label: 'Send Reminders',
        icon: 'new-notifications',
        color: 'primary',
        children: [
          {
            label: 'Send via email',
            onClick: () => handleRemind('email'),
            icon: 'new-email-blue',
            color: 'primary'
          },
          {
            label: 'Send via text message',
            onClick: () => handleRemind('text_message'),
            icon: 'text-message',
            color: 'primary'
          }
        ]
      }
    ],
    checkin: [
      ...[
        { name: 'Not Checked-In', value: 'not_checked_in', color: 'danger' },
        { name: 'Checked-In', value: 'checked_in', color: 'success' },
        { name: 'Partially Checked-In', value: 'partially_checked_in', color: 'warning' }
      ]
        .filter((it) => it.name !== values.timeline['check-in'].checkin.name)
        .map((it) => ({
          label: it.name,
          onClick: () => handleChangeAptStatus(it.value),
          icon:
            it.color === 'success'
              ? 'new-check'
              : it.color === 'warning'
                ? 'new-partially-completed'
                : 'new-close-v2',
          color: it.color,
          size: 20,
          stroke: it.color !== 'warning'
        })),
      ia(kiosks) && kiosks.length > 1
        ? {
            label: 'Send to Kiosk',
            icon: 'kiosk-nav',
            color: 'primary',
            children: kiosks.map((kiosk) => ({
              label: kiosk.name,
              onClick: () => sendCheckinToKiosk(kiosk.id),
              icon: 'kiosk-nav',
              color: 'primary'
            }))
          }
        : ia(kiosks) && {
            label: 'Send to Kiosk',
            onClick: () => sendCheckinToKiosk(kiosks[0].id),
            icon: 'kiosk-nav',
            color: 'primary'
          }
    ].filter(Boolean),
    balance: [
      ...balanceOptions.map((it) => ({
        label: it.label,
        onClick: () => handleChangeAptStatus(it.value),
        icon:
          it.color === 'success'
            ? 'new-check'
            : it.color === 'warning'
              ? 'new-partially-completed'
              : 'new-close-v2',
        color: it.color,
        size: 20,
        stroke: it.color !== 'warning'
      }))
    ],

    arrived: [
      {
        label: 'Not Arrived',
        onClick: () => handleChangeAptStatus('not_arrived'),
        icon: 'new-close-v2',
        color: 'danger',
        size: 20,
        stroke: true
      },
      {
        label: 'Arrived - Waiting',
        onClick: () => handleChangeAptStatus('arrived_waiting'),
        icon: 'new-partially-completed',
        color: 'warning'
      },
      ...appointmentStatuses
        .filter((item) => item.value.includes('arrived_with'))
        .map((item) => ({
          label: item.label,
          onClick: () => handleChangeAptStatus(item.value),
          icon: 'new-partially-completed',
          color: 'warning'
        })),

      {
        label: 'Appointment Ended',
        onClick: () => setAptEndedModalVisible(true),
        icon: 'new-check',
        color: 'success',
        size: 20,
        stroke: true
      }
    ],
    superbill: [
      {
        label: 'Superbill Not Created',
        onClick: () => handleChangeAptStatus('superbill_not_created'),
        icon: 'new-close-v2',
        color: 'danger',
        size: 20,
        stroke: true
      },
      {
        label: 'Superbill Not Scrubbed',
        onClick: () => handleChangeAptStatus('superbill_not_scrubbed'),
        icon: 'new-partially-completed',
        color: 'warning'
      },
      {
        label: 'Superbill Scrubbed',
        onClick: () => handleChangeAptStatus('superbill_scrubbed'),
        icon: 'new-check',
        color: 'success',
        size: 20,
        stroke: true
      }
    ],
    claim: [
      {
        label: 'Claim Not Created',
        onClick: () => handleChangeAptStatus('claim_not_created'),
        icon: 'new-close-v2',
        color: 'danger',
        size: 20,
        stroke: true
      },
      {
        label: 'Claim Rejected',
        onClick: () => handleChangeAptStatus('claim_rejected'),
        icon: 'new-close-v2',
        color: 'danger',
        size: 20,
        stroke: true
      },
      {
        label: 'Claim Denied',
        onClick: () => handleChangeAptStatus('claim_denied'),
        icon: 'new-close-v2',
        color: 'danger',
        size: 20,
        stroke: true
      },
      {
        label: 'Claim Ready',
        onClick: () => handleChangeAptStatus('claim_ready'),
        icon: 'new-partially-completed',
        color: 'warning'
      },
      {
        label: 'Claim Acknowledged',
        onClick: () => handleChangeAptStatus('claim_acknowledged'),
        icon: 'new-partially-completed',
        color: 'warning'
      },
      {
        label: 'Claim Pending',
        onClick: () => handleChangeAptStatus('claim_pending'),
        icon: 'new-partially-completed',
        color: 'warning'
      },
      {
        label: 'Claim Paid',
        onClick: () => handleChangeAptStatus('claim_paid'),
        icon: 'new-check',
        color: 'success',
        size: 20,
        stroke: true
      }
    ]
  };

  return options;
};

export default useTimelineActions;
