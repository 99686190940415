import { forwardRef } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import Icon from '../Icon/Icon';

import { getVariantClasses } from './lib/helpers';

/**
 * @type {import('react').ForwardRefExoticComponent<import('./lib/propTypes').TypographyProps & import('react').RefAttributes<HTMLElement>>}
 */

const Typography = forwardRef((props, ref) => {
  const {
    variant = 'label',
    size = 'md',
    className,
    children,
    color = 'text-neutral-900',
    textDecoration = 'none',
    iconColor = 'primary',
    weight = variant.startsWith('h') ? 'font-medium' : 'font-normal',
    icon,
    iconRight,
    tooltip,
    tooltipPlacement = 'bottom',
    tooltipColor = 'dark',
    required = false,
    iconIsStroke = false,
    iconSize,
    ...rest
  } = props;

  const baseClasses = getVariantClasses(variant, size);

  const Component = variant === 'display' ? 'h1' : variant;

  return (
    <Component
      ref={ref}
      className={cs(
        'flex tracking-[-0.02em]',
        baseClasses,
        weight,
        color,
        textDecoration,
        className
      )}
      {...rest}>
      <div className="flex items-center gap-2">
        {icon && (
          <Tippy
            content={tooltip || ''}
            disabled={!tooltip}
            placement={tooltipPlacement}
            className={tooltipColor === 'dark' && 'tippy-dark'}>
            <div className="flex items-center">
              <Icon icon={icon} color={iconColor} iconIsStroke={iconIsStroke} iconSize={iconSize} />
            </div>
          </Tippy>
        )}
        {children}
        {iconRight && (
          <Tippy
            content={tooltip || ''}
            disabled={!tooltip}
            placement={tooltipPlacement}
            className={tooltipColor === 'dark' && 'tippy-dark'}>
            <div className="flex items-center">
              <Icon
                icon={iconRight}
                color={iconColor}
                iconIsStroke={iconIsStroke}
                iconSize={iconSize}
              />
            </div>
          </Tippy>
        )}
      </div>
      {required && <span className="text-danger-400">*</span>}
    </Component>
  );
});

Typography.displayName = 'Typography';

export default Typography;
