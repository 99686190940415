import React, { useState, useEffect } from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { matchPriority } from './helper';
import ErrorBoundary from 'components/shared/Error/Boundary';
import './Tasks.scss';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { iaRa } from 'lib/helpers/utility';

const Task = ({ task }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    let countdownInterval;
    if (isChecked) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(countdownInterval);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(countdownInterval);
    };
  }, [isChecked]);

  const updateTaskStatus = async ({ taskId, status }) => {
    try {
      const res = await requestApi({
        url: '/api/tasks/update_multiple',
        params: {
          taskIds: [taskId],
          actions: {
            changeStatus: {
              status
            },
            markDelete: false
          },
          navigate
        }
      });
      return res;
    } catch (error) {
      showAlert({
        title: `Task update failed`,
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }
  };
  let newTimeout;
  const handleCheck = async () => {
    if (!isChecked) {
      setIsChecked(true);
      setCountdown(5);

      const updatedTask = await updateTaskStatus({ taskId: task?.id, status: 'completed' });
      newTimeout = setTimeout(() => {
        queryClient.setQueryData(['tasks-widget'], (oldTasks) => {
          const updatedTasks = iaRa(oldTasks?.tasks).filter((elem) => elem.id !== task.id);
          return {
            tasks: updatedTasks
          };
        });
        if (updatedTask.code === 0) {
          showAlert({
            title: `Task updated successfully`,
            color: 'success'
          });
        } else {
          showAlert({
            title: `Task update failed`,
            message: updatedTask?.error ?? 'Something went wrong',
            color: 'danger'
          });
        }
      }, 5000);
      setTimer(newTimeout);
    } else {
      setIsChecked(false);
      setCountdown(5);
      clearTimeout(timer);
      await updateTaskStatus({ taskId: task?.id, status: task.status });
    }
  };

  const handleUndo = async () => {
    await updateTaskStatus({ taskId: task?.id, status: task.status });
    setIsChecked(false);
    setCountdown(5);
    clearTimeout(timer);
  };

  return (
    <ErrorBoundary>
      {isChecked ? (
        <div
          key={task?.id}
          className="max-h-[84px] min-h-[84px] gap-3 rounded-[8px] bg-primary-50 !px-4 !py-[13px]">
          <div key={task?.id} className="flex justify-between ">
            <div className="flex">
              <Checkbox isChecked={true} onChange={handleCheck} />
              <div>
                <p className=" pl-4 text-left font-500 text-primary-900">Task completed!</p>
                <p className=" pl-4 text-left">
                  You have <span className="font-600 text-primary-900">{countdown} s</span> to undo
                  the action before it disappears from the list.
                </p>
              </div>
            </div>
            <p onClick={handleUndo} className="cursor-pointer font-600 text-primary-600">
              Undo
            </p>
          </div>
          <div className="!mt-2 ml-[33px] h-2 max-w-full rounded bg-primary-100">
            <div className="stripe"></div>
          </div>
        </div>
      ) : (
        <div
          key={task?.id}
          className=" flex max-h-[70px] min-h-[70px] justify-between gap-3 !px-4 !py-[13px]">
          <div className="flex items-start">
            <Checkbox isChecked={false} onChange={handleCheck} />
            <div className="items-center pl-4 ">
              <p className="text-left font-500 leading-[22px] text-primary-900">{task.title}</p>
            </div>
          </div>
          {task.priority && (
            <p
              className={`max-h-[28px] items-center rounded-[24px] border border-solid px-3 py-1 text-xs ${
                task.priority === '0'
                  ? 'border-red-200 bg-red-50 text-danger-500'
                  : task.priority === '1'
                    ? 'border-primary-200 bg-primary-50 text-primary-600'
                    : task.priority === '2'
                      ? 'border-success-200 bg-success-50 text-success-600'
                      : 'border-neutral-200 bg-neutral-50 text-neutral-600'
              }`}>
              {matchPriority(task.priority)}
            </p>
          )}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default Task;
