import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { batchDeleteDocuments } from 'api/Documents';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useDocuments } from 'lib/hooks/queries/useDocuments';

import AGTable from 'components/shared/AGTable/AGTable';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import TableCounter from 'components/shared/Table/TableCounter';

import DocumentsHeader from './components/DocumentsHeader';
import documentTableCols from './lib/documentTableCols';
import formatFilters from './lib/formatFilters';

const List = ({ setViewDocument, setShowInstantPacket, patient, practitioners, patientId }) => {
  const navigate = useNavigate();

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  const { filters, page, setPage, limit, sort, selectedRows, setSelectedRows } = useTableContext();

  const selectedItems = selectedRows.map((node) => node.data);

  const { data, isLoading } =
    useDocuments({
      params: formatFilters({ filters, page, limit, sort, patientId }),
      dependencies: [formatFilters({ filters, page, limit, sort, patientId }), patientId]
    }) || {};

  const documents = data?.documents || [];
  const totalDocuments = data?.totalDocuments || 0;

  const queryClient = useQueryClient();

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: TableCounter,
          statusPanelParams: {
            count: totalDocuments,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: Pagination,
          statusPanelParams: {
            containerClassName: 'flex',
            onPageChange: ({ selected }) => setPage(selected + 1),
            perPage: limit,
            totalItems: totalDocuments,
            page
          },
          align: 'right'
        }
      ]
    }),
    [page, limit, setPage, totalDocuments]
  );

  const onRowClicked = (event) => {
    const { data } = event;
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      setViewDocument({
        load: true,
        title: data?.name,
        handle: data?.document_handle || {
          response_id: data?.id,
          appointment_id: data?.appointment_id,
          form_id: data?.form_id,
          json: data?.json,
          form_json: data?.form_json,
          form_version: data?.form_version
        },
        type: data?.mimetype || 'form'
      });
    }
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    await onRowClicked(e);
  };

  const batchDelete = async () => {
    await batchDeleteDocuments(navigate, { documents: selectedItems });
    queryClient.invalidateQueries(['documents']);
    setSelectedRows([]);
    setDeleteConfirmation(false);
  };

  return (
    <>
      <DocumentsHeader
        setViewDocument={setViewDocument}
        setShowInstantPacket={setShowInstantPacket}
        patient={patient}
        practitioners={practitioners}
        patientId={patientId}
        setDeleteConfirmation={setDeleteConfirmation}
      />
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz h-full">
          <AGTable
            data={documents}
            rowSelection="multiple"
            defaultColDef={documentTableCols}
            suppressRowClickSelection={true}
            statusBar={statusBar}
            customClassName="ag-grid-interactive"
            loading={isLoading}
            masterDetail={true}
            onCellClicked={onCellClicked}
          />
        </div>
      </div>

      <Confirm
        variant="danger"
        secondaryBtnTxt="Keep"
        title="Delete documents"
        handleClose={() => setDeleteConfirmation(false)}
        icon="new-document-remove-red"
        primaryBtnTxt="Delete documents"
        handleOpen={deleteConfirmation}
        handleContinue={batchDelete}
        message="Are you sure you want to delete these documents?"
      />
    </>
  );
};

export default List;
