import React from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { formatDate, mString } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';

const DEFAULT_COL_DEFS = {
  enableRowGroup: true,
  resizable: true,
  filter: false,
  suppressMenu: true,
  suppressMovable: true
};

const GRID_OPTIONS = {
  domLayout: 'autoHeight',
  animateRows: true,
  rowGroupPanelShow: 'always'
};

const NewInvoiceDetailsTable = ({ invoices, isView = false, isAEOB = true }) => {
  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;
  const practiceTimezone = practice.timezone;

  const COLUMN_DEFS = [
    {
      field: 'details',
      minWidth: 170,
      cellClass: 'no-border',
      flex: 1
    },
    {
      field: 'dateofservice',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, practiceTimezone, true),
      maxWidth: 130,
      cellClass: 'no-border',
      flex: 1
    },
    { field: 'type', cellClass: 'no-border', flex: 1 },
    {
      field: 'qty',
      maxWidth: 80,
      minWidth: 70,
      cellClass: 'no-border',
      flex: 1
    },
    {
      field: 'charge',
      hide: !isAEOB,
      valueFormatter: ({ value }) => mString(value || 0),
      cellClass: 'no-border',
      flex: 1
    },
    {
      field: 'ins_payment',
      headerName: 'Ins Pay',
      valueGetter: (params) => {
        const insPayment = params?.data?.ins_payment;
        const finalInsPayment = mString(insPayment || 0);
        const isEstimated = params?.data?.isEstimated;

        if (isEstimated) {
          return `estimated ${finalInsPayment}`;
        }
        return finalInsPayment;
      },
      hide: !claims || !isAEOB,
      cellClass: 'no-border',
      flex: 1
    },
    {
      field: 'ins_adjustment',
      headerName: 'Ins Adj',
      valueGetter: (params) => {
        const insAdj = params?.data?.ins_adjustment;
        const finalInsAdj = mString(insAdj || 0);
        const isEstimated = params?.data?.isEstimated;

        if (isEstimated) {
          return `estimated ${finalInsAdj}`;
        }
        return finalInsAdj;
      },
      hide: !claims || !isAEOB,
      cellClass: 'no-border',
      flex: 1
    },
    {
      field: 'ptBalance',
      valueFormatter: ({ value }) => mString(value || 0),
      maxWidth: 120,
      cellClass: 'no-border',
      flex: 1
    }
  ];
  return (
    <div className={cs('flex w-auto flex-col overflow-hidden !p-3 !pb-2', !isView ? 'h-full' : '')}>
      <AGBasicTable
        animateRows={true}
        defaultColDef={DEFAULT_COL_DEFS}
        columnDefs={COLUMN_DEFS}
        rowData={invoices}
        suppressRowClickSelection={true}
        customClassName="ag-grid-interactive"
        gridOptions={{
          ...GRID_OPTIONS,
          rowGroupPanelShow: !isView,
          rowClassRules: {
            'cancelled-row': (params) => params.data?.status === 'cancelled'
          }
        }}
      />
    </div>
  );
};

export default NewInvoiceDetailsTable;
