import React, { useRef, useState } from 'react';
import { iaRa } from 'lib/helpers/utility';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import '../Custom.scss';
import { Allergies as initialValue } from '../CustomFormInitialStates';
import AllergiesRow from './AllergiesRow';
import Header from '../components/Header';
import Container from '../components/Container';
import DeleteFormModal from '../components/DeleteFormModal';
import useCustomForm from '../lib/useCustomForm';

const Allergies = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  formId = null,
  showNarrative = false,
  onChange,
  setAllergyModal = () => {},
  options = []
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [items, setItems] = useState(initialValue);

  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.allergies?.advancedForm
      : true;
  const textAreaRef = useRef();

  const [itemToDelete, setItemToDelete] = useState(null);

  const rows = 'allergies';

  const props = {
    itemToDelete,
    setItemToDelete,
    formId,
    setItems,
    setModal: setAllergyModal,
    fromCheckin: true,
    items,
    rows,
    title: 'Allergies',
    alertTitle: 'Allergy',
    initialValue,
    textAreaRef,
    defaultValue,
    onChange,
    setCurrentFormData
  };

  const { handleAddRow, handleOnChange, handleNoDetails, onDelete, syncItems, length } =
    useCustomForm(props);

  return (
    <div className="Allergies CustomForms flex w-full flex-col items-center justify-center">
      <Header
        title="Allergies"
        noDetailsLabel="Do you have any known allergies?"
        isAdvancedForm={isAdvancedForm}
        noDetails={items.noDetails}
        fromCheckin
        emptyState={{
          title: 'No allergies reported yet',
          subtitle:
            'Allergies refer to reactions to medications, foods, or other substances. Please let us know if you have any to help us provide safer care.',
          label: 'Do you have any allergies not reported to our practice?'
        }}
        addRow={{ onClick: handleAddRow, label: 'Do you want to add another allergy?' }}
        handleNoDetails={handleNoDetails}>
        <div className="flex w-full flex-col items-center gap-y-4">
          {iaRa(items?.[rows])?.map((item, idx) => (
            <Container
              key={item.id}
              fromCheckin
              onDelete={() => onDelete(item?.id)}
              number={idx + 1}>
              <AllergiesRow
                key={item.id}
                index={idx}
                id={item?.id}
                fromCheckin
                onChange={handleOnChange}
                length={length}
                {...item}
              />
            </Container>
          ))}
        </div>
      </Header>

      <input type="hidden" value={JSON.stringify(items)} ref={forwardedRef} />

      <DeleteFormModal syncItems={syncItems} {...props} />
    </div>
  );
};

export default Allergies;
