import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { checkUnprocessedAudio } from '../../../../api/MyScribe';

export const useCheckUnprocessedAudio = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['checkUnprocessedAudio', ...dependencies],
    () => checkUnprocessedAudio(navigate, params),
    {
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
