import { capitalize } from 'lodash';
import { useRecoilValue } from 'recoil';

import {
  formatDateAndTime,
  formatTime,
  iaRa,
  mString,
  snakeToTitleCase
} from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';

import { getCptCodes, getPractitionerName, getServicesName } from '../lib';

const ExportTable = ({ events, exportOnlyAppointments }) => {
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const { data } = usePractitioners();
  const practitioners = iaRa(data?.practitioners);
  return (
    <table className="primary-table">
      <thead>
        <tr className="w-full">
          <th className="!text-xs !w-[20%]">Datetime</th>
          <th className="!text-xs">Patient ID</th>
          <th className="!text-xs">Patient</th>
          {!exportOnlyAppointments && <th className="!text-xs">Event Name</th>}
          <th className="!text-xs">Insurance Name</th>
          <th className="!text-xs">Service</th>
          <th className="!text-xs">CPT Code</th>

          <th className="!text-xs">Provider</th>
          <th className="!text-xs">Balance</th>
          <th className="!text-xs">Status</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, i) => (
          <tr key={i}>
            <td className="!text-xs">
              {formatDateAndTime(event.start_date) + ' - ' + formatTime(event.end_date)}
            </td>
            <td className="!text-xs">{event.patient?.id}</td>
            <td className="!text-xs">{event.patient?.fullName}</td>
            {!exportOnlyAppointments && <td className="!text-xs">{capitalize(event.title)}</td>}
            <td className="!text-xs">{event.patient?.insuranceName}</td>
            <td className="!text-xs">{getServicesName(event.invoice?.procedures)}</td>
            <td className="!text-xs">{getCptCodes(event.invoice?.procedures)}</td>
            <td className="!text-xs">{getPractitionerName(event, practitioners)}</td>
            <td className="!text-xs">
              {event.type === 'appointment' && mString(event.invoice?.balance || 0)}
            </td>
            <td className="!text-xs">
              {appointmentStatuses.find((st) => st.value === event.status)?.label ||
                snakeToTitleCase(event.status)}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ExportTable;
