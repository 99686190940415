import React, { useEffect, useRef, useState } from 'react';
import { currentPractice } from 'components/practice/practiceState';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Textarea from 'components/shared/Textarea/Textarea';
import { discountTypes } from 'constants';
import { mString } from 'lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import Switch from 'components/shared/Switch/Switch';
import cs from 'classnames';

const NewInvoiceForm = ({ formik, invoiceId, initialValues, invoiceDetails, hasClaims }) => {
  const practice = useRecoilValue(currentPractice);

  const [isSurchargeApplied, setIsSurchargeApplied] = useState(false);
  const { surcharge, surcharge_enabled, surcharge_percentage } = practice;

  const surchargeObj = { surcharge, surcharge_enabled, surcharge_percentage };

  const {
    tax = 0,
    prevDiscount = 0,
    charge,
    amount_paid = 0,
    ins_payment = 0,
    ins_adjustment = 0,
    adjustment = 0,
    ptBalance,
    totalAmount
  } = invoiceDetails || {};

  const discountRef = useRef();

  const [discount, setDiscount] = useState({ type: 'amount', value: 0 });

  const isAEOB = formik.values?.[invoiceId]?.aeob ?? true;

  const finalCharge = charge ?? Number(formik.values?.[invoiceId]?.amount_cents || 0);

  const finalDiscount = Number(formik.values?.[invoiceId]?.discount_amount_cents || 0);
  const finalTaxPercentage = Number(formik.values?.[invoiceId]?.tax_percentage || 0);
  const finalTaxAmountCents = Number(formik.values?.[invoiceId]?.tax_amount_cents || 0);

  let total =
    finalCharge - ins_payment - ins_adjustment - prevDiscount - amount_paid + tax - adjustment;

  if (!isAEOB) total = ptBalance;

  let amountDue = total - finalDiscount + finalTaxAmountCents;

  const calculated_surcharge_amount = formik.values?.[invoiceId]?.surcharge_amount || 0;

  if (surchargeObj?.surcharge && surchargeObj?.surcharge_enabled && isSurchargeApplied) {
    const percentage = surcharge_percentage / 100;
    const totalAmountFromPercentage = amountDue * percentage;

    amountDue += Math.round(totalAmountFromPercentage);
  }

  useEffect(() => {
    discountRef.current = 0;

    formik.setFieldValue(`${invoiceId}.amount_cents`, amountDue);
    formik.setFieldValue(`${invoiceId}.total_amount_cents`, amountDue);
    formik.setFieldValue(
      `${invoiceId}.discount_amount_cents`,
      initialValues[invoiceId].discount_amount_cents
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAEOB]);

  const onDiscountValueChange = (value) => {
    let newDiscount = +value;

    if (discount.type === 'amount' && value > 0) {
      setDiscount((prev) => ({ ...prev, value: newDiscount }));

      const subtotal = total - newDiscount;
      const taxAmount = subtotal * (finalTaxPercentage / 100);
      formik.setFieldValue(`${invoiceId}.total_amount_cents`, subtotal + taxAmount);
      formik.setFieldValue(`${invoiceId}.discount_amount_cents`, newDiscount);

      return;
    }

    if (discount.type === 'percent' && value > 0) {
      setDiscount((prev) => ({ ...prev, value: +value }));

      const discountAmount = (+value / 100 / 100) * total;

      const discountPercentage = (discountAmount / total) * 100 * 100;
      discountRef.current = discountPercentage;

      formik.setFieldValue(`${invoiceId}.discount_amount_cents`, discountAmount);

      const subtotal = total - discountAmount;
      const taxAmount = subtotal * (finalTaxPercentage / 100);
      formik.setFieldValue(`${invoiceId}.tax_amount_cents`, taxAmount);
      formik.setFieldValue(`${invoiceId}.total_amount_cents`, subtotal + taxAmount);
    }
  };

  const onDiscountUnitChange = (type) => {
    setDiscount((prev) => ({ ...prev, type }));

    if (type === 'amount' && discount.value >= 0) {
      const discountAmount = total - amountDue + finalTaxAmountCents;

      discountRef.current = discountAmount;

      formik.setFieldValue(`${invoiceId}.discount_amount_cents`, discountAmount);

      return;
    }

    if (type === 'percent' && discount.value >= 0) {
      const current_amount_cents = total;
      const discountAmount = total - amountDue + finalTaxAmountCents;

      const discountPercentage = (discountAmount / current_amount_cents) * 100 * 100;
      discountRef.current = discountPercentage;

      formik.setFieldValue(`${invoiceId}.discount_amount_cents`, discountAmount);
    }
  };

  const onTaxChange = (value, discount) => {
    const tax = +value / 100;
    formik.setFieldValue(`${invoiceId}.tax_percentage`, tax);

    const subtotal = total - discount || finalDiscount;

    const taxAmount = subtotal * (tax / 100);
    formik.setFieldValue(`${invoiceId}.tax_amount_cents`, taxAmount);
    formik.setFieldValue(`${invoiceId}.total_amount_cents`, subtotal + taxAmount);
  };

  const onTechnologyFeeToggle = (value, newAmount) => {
    setIsSurchargeApplied(value);

    if (value) {
      if (surchargeObj?.surcharge && surchargeObj?.surcharge_enabled) {
        const percentage = surcharge_percentage / 100;
        const totalAmountFromPercentage = Math.round((newAmount || amountDue) * percentage);

        amountDue += totalAmountFromPercentage;

        formik.setFieldValue(`${invoiceId}.surcharge_amount`, totalAmountFromPercentage);
        formik.setFieldValue(`${invoiceId}.total_amount_cents`, amountDue);
      }
      return;
    }

    formik.setFieldValue(`${invoiceId}.surcharge_amount`, 0);
  };

  return (
    <>
      <div className="mt-5 flex w-full !gap-3">
        <div className="col-span-1 w-1/3">
          <DatePopover
            id={`${invoiceId}.due_date`}
            name={`${invoiceId}.due_date`}
            label="Due Date"
            placementY="bottom-[110%]"
            value={formik?.values?.[invoiceId]?.due_date}
            onChange={(value) => formik.setFieldValue(`${invoiceId}.due_date`, value)}
          />
        </div>
        <div className="col-span-1 w-2/3">
          <Textarea
            id={`${invoiceId}.memo`}
            name={`${invoiceId}.memo`}
            label="Memo"
            value={formik?.values?.[invoiceId]?.memo || ''}
            error={formik?.errors?.[invoiceId]?.memo}
            onChange={(value) => formik.setFieldValue(`${invoiceId}.memo`, value)}
            isEditor
          />
        </div>
      </div>
      <div className="relative z-20 m-[16px_-16px_-16px_-16px] flex min-h-[40px] items-center !gap-2 bg-gradient-to-r from-[#004F6B3D] to-[#004f6b0d] !px-4 py-3 uppercase text-primary-900">
        <div className="my-4 w-full px-8">
          <div className="mx-3">
            <div className="mb-4">
              {isAEOB ? (
                <>
                  <div className="mt-3 flex justify-between">
                    <span>Billed</span>
                    {mString(charge)}
                  </div>

                  {hasClaims && (
                    <>
                      <div className="mt-3 flex justify-between">
                        <span>Insurance Covered</span>
                        <span>
                          {ins_payment > 0 ? '-' : ''}
                          {mString(ins_payment)}
                        </span>
                      </div>

                      <div className="mt-3 flex justify-between">
                        <span>Insurance Adjusted</span>
                        <span>
                          {ins_adjustment > 0 ? '-' : ''}
                          {mString(ins_adjustment)}
                        </span>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <div className="mt-3 flex justify-between">
                  <span>Billed</span>
                  {mString(totalAmount)}
                </div>
              )}

              {amount_paid > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Patient Paid</span>
                  <span>-{mString(amount_paid)}</span>
                </div>
              )}

              {prevDiscount > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Discount</span>
                  <span>-{mString(prevDiscount)}</span>
                </div>
              )}

              {adjustment > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Adjustment</span>
                  <span>-{mString(adjustment)}</span>
                </div>
              )}

              {tax > 0 && (
                <div className="mt-3 flex justify-between">
                  <span>Tax</span>
                  <span>{mString(tax)}</span>
                </div>
              )}

              <hr className="my-8 h-px border-0 bg-gray-300" />

              <div className="flex justify-between border-t pt-3">
                <span className="font-bold">Subtotal</span>
                <span className="text-lg font-bold">{mString(total)}</span>
              </div>

              <div className="mt-3 flex justify-between">
                <span>Additional Discount</span>

                <CurrencyInput
                  id={`${invoiceId}.discount_amount_cents`}
                  name={`${invoiceId}.discount_amount_cents`}
                  value={discountRef.current}
                  prefix={discount.type !== 'percent' ? '$' : ''}
                  suffix={discount.type === 'percent' ? '%' : ''}
                  parentClassName="!w-[140px]"
                  className="!h-10 !rounded-md border border-neutral-100"
                  onValueChange={onDiscountValueChange}
                  disabled={false}
                  units={discountTypes}
                  currentUnit={discountTypes.find((type) => type?.value === discount.type)?.label}
                  onUnitChange={onDiscountUnitChange}
                  optionPosition="top"
                />
              </div>

              <div className="flex justify-between border-t pt-3">
                <div className="flex items-center">
                  <span className="mr-3">Technology Fee</span>
                  <Switch onChange={onTechnologyFeeToggle} checked={isSurchargeApplied} />
                </div>

                <p className={cs('text-sm font-500 text-primary-900')}>
                  {mString(calculated_surcharge_amount)}
                </p>
              </div>
            </div>

            <div className="mt-3 flex justify-between border-t pt-3">
              <span className="font-bold">Amount Due</span>
              <span className="text-2xl font-bold">{mString(amountDue)}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewInvoiceForm;
