import React from 'react';
import cs from 'classnames';
import Icon from '../../Icon/Icon';
import { customStyle } from './style';
import ActionButton from '../../shared/ActionButton';

export const formatImageOption = ({ image, value, label, imageClassName }) => {
  return (
    <div className="flex items-center gap-x-2">
      {image ? (
        <div className="rounded-smooth flex h-6 w-6 items-center justify-center overflow-hidden bg-neutral-100">
          <img
            className={cs(imageClassName ? imageClassName : 'h-full w-full object-cover')}
            src={image}
            alt={value}
          />
        </div>
      ) : (
        <Icon icon="new-no-img" className="cursor-pointer" />
      )}
      {label}
    </div>
  );
};

/**
 * Get common props for Select component
 * @param {import('./types').SelectProps} props
 */

export const getCommonSelectProps = ({
  // Basic props
  className,
  id,
  name,
  label,
  placeholder = 'Select',
  value = null,
  options = [],
  onChange,
  inputValue,
  defaultOptions = [],

  // Select behavior
  isMulti = false,
  isClearable = true,
  disabled = false,
  isLoading,
  noDropdown,

  // Styling
  styles,
  width,
  minWidth,
  height,
  error,

  // Icons and layout
  icon,
  headerIcon,
  rightIcon,
  rightText,

  // Formatting
  formatOptionLabel,
  formatCreateLabel,
  formatMultiValueContainer,
  filterOption,

  // Action button
  actionButtonText,
  actionButtonIcon,
  actionButtonClick,

  // Other
  menuPortalTarget,
  forwardedRef,

  ...rest
}) => {
  const hasActionButton = actionButtonText || actionButtonIcon;

  return {
    className: cs('select', className),
    classNamePrefix: 'select',
    id: id || name || label ? `select-${id || name || label}` : 'selectId',
    placeholder,
    value,
    options,
    onChange,
    isMulti,
    isClearable,
    isDisabled: disabled,
    isLoading,
    inputValue,
    defaultOptions,
    headerIcon,
    styles: customStyle({
      width,
      minWidth,
      height,
      disabled,
      error,
      icon,
      isMulti,
      rightIcon,
      styles
    }),
    inputId: id,
    components: {
      IndicatorSeparator: () => rightText,
      ...(noDropdown && {
        DropdownIndicator: () => <span className="w-2 opacity-0">none</span>
      }),
      ...(formatMultiValueContainer && {
        MultiValueContainer: formatMultiValueContainer
      }),
      ...(hasActionButton && {
        DropdownIndicator: () => (
          <ActionButton
            text={actionButtonText}
            icon={actionButtonIcon}
            onClick={actionButtonClick}
          />
        )
      })
    },
    formatOptionLabel,
    formatCreateLabel,
    filterOption,
    menuPortalTarget,
    menuPlacement: 'auto',
    ref: forwardedRef,
    ...rest
  };
};
