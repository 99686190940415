import React from 'react';
import { useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import RequestAssistance from 'components/Kiosk/components/RequestAssistance';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';
import Tippy from '@tippyjs/react';

const Navbar = ({
  device,
  steps,
  currentStepName,
  currentStepNumber,
  stepObject,
  handleExternalSubmit,
  handleBack,
  showButton,
  setShow,
  fakeModal,
  disableContinue,
  continueText,
  continueColor,
  setFakeModal,
  progress,
  kiosk
}) => {
  const { pathname } = useLocation();
  const isPreAuth = pathname.includes('/instant-packet/pre-auth');
  const isHipaa = pathname.includes('/hipaa');
  const isPay = pathname.includes('/pay');

  return (
    <>
      <div
        className={cs(
          'sticky top-0 z-20 w-full bg-white text-2xl font-600 shadow-[0px_2px_16px_0px_#0000000F]',
          device !== 'mobile' && 'border-0 !border-b border-solid border-neutral-100'
        )}
        onClick={() => {
          setShow((ps) => !ps);
        }}>
        <div className="!m-2 flex flex-row">
          {device === 'mobile' ? (
            <div className="flex cursor-pointer items-center">
              <Icon icon="new-hamburger" className="!mr-2 cursor-pointer" />
              <div className="flex aspect-square h-[2.2rem] w-[2.2rem] flex-row items-center justify-center rounded-full border-2 border-solid border-primary-100 text-center text-xl text-primary-600">{`${
                currentStepNumber || 1
              }`}</div>

              <div className="-mb-[0.1rem] ml-[0.5rem] flex flex-col items-start justify-center">
                <div className="-mb-[0.3rem] text-xs font-400 text-neutral-600">
                  STEP {currentStepNumber} / {steps.length}
                </div>

                <div className="w-fit text-lg font-600 text-primary-500">{currentStepName}</div>
              </div>
            </div>
          ) : (
            <div
              className={cs(
                ' my-auto ml-4 flex items-center',
                device === 'tablet-sm' ? 'h-[38px]' : '!h-[40px]'
              )}>
              <p className="text-lg !font-400 text-neutral-800">{currentStepName}</p>
            </div>
          )}

          <div className="ml-auto mr-1 flex items-center gap-2">
            {kiosk && <RequestAssistance className="!mr-2" page={currentStepName} />}
            {device && !isPreAuth && !fakeModal && (
              <>
                {handleBack && currentStepNumber != 1 && (
                  <Button
                    color="neutral"
                    outlined
                    text="Back"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleBack();
                    }}
                    data-qa="wizard-back-btn"
                  />
                )}
                {showButton && (
                  <Tippy
                    className="tippy-dark"
                    disabled={!disableContinue}
                    content={
                      isHipaa
                        ? 'Please sign the HIPAA Consent form'
                        : isPay
                          ? 'Payment failed'
                          : ' '
                    }>
                    <div className="flex">
                      <Button
                        text={continueText}
                        color={!disableContinue ? continueColor : 'neutral'}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleExternalSubmit();
                        }}
                        data-qa="continue-primary-btn"
                        disabled={disableContinue}
                      />
                    </div>
                  </Tippy>
                )}
              </>
            )}

            {fakeModal && (
              <Button
                color="neutral"
                outlined
                text="Close"
                onClick={(e) => {
                  e.stopPropagation();
                  setFakeModal(null);
                }}
                data-qa="wizard-back-btn"
              />
            )}
          </div>
        </div>
      </div>

      {device === 'mobile' && (
        <div className="flex h-[5px] flex-row overflow-hidden bg-neutral-200">
          <div
            className={`h-full bg-primary-500 ${
              (stepObject.numCompleted !== steps.length && 'rounded-r-full') || ''
            }`}
            style={{ width: `${progress}px` }}></div>
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(Navbar);
