import React, { forwardRef, useEffect } from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useGetAiTask } from 'lib/hooks/queries/admin/intelligence/useAiTasks';
import Loader from 'components/shared/Loader/Loader';

const AdminIntelligenceAiTaskEntry = forwardRef(({ src, onHeightChange }, ref) => {
  const { data, isLoading, isFetching } = useGetAiTask({
    params: { aiTaskId: src },
    name: `ai_task_${src}`,
    dependencies: [src]
  });

  useEffect(() => {
    if (ref.current && !isLoading) {
      const height = ref.current.getBoundingClientRect().height;
      onHeightChange(height);
    }
  }, [isLoading || isFetching, onHeightChange, ref]);

  return (
    <div>
      {(isLoading || isFetching) && (
        <div className="flex flex-row gap-2">
          <Loader />
          <div>Loading...</div>
        </div>
      )}
      <div className="flex flex-row gap-2">
        {data?.data?.request?.includes('<') ? (
          <div className="flex flex-col" dangerouslySetInnerHTML={data?.data?.request}></div>
        ) : (
          <div className="flex flex-col">{data?.data?.request}</div>
        )}
        {data?.data?.response.includes('<') ? (
          <div className="flex flex-col" dangerouslySetInnerHTML={data?.data?.response}></div>
        ) : (
          <div className="flex flex-col">{data?.data?.response}</div>
        )}
      </div>
    </div>
  );
});

export default withErrorBoundary(AdminIntelligenceAiTaskEntry);
