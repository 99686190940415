import { useQuery } from '@tanstack/react-query';
import { getReportsWidget } from 'api/Widgets';
import { useNavigate } from 'react-router-dom';

export const useAdminReports = (params = {}, options = {}) => {
  const navigate = useNavigate();
  const { startAt, endAt } = params;

  return useQuery(['adminReports', startAt, endAt], () => getReportsWidget(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
