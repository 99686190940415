import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { clinicalNoteSections } from '../../../../constants';
import { optionify } from 'lib/helpers/utility';
import { usePractice } from 'lib/hooks/queries/practice/usePractice';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import OverviewBlock from './OverviewBlock';
import Tabs from './Tabs';
import state from './state';
import { requestApi } from 'api/Api';
import Allowed from 'components/shared/Permissions/Allowed';

function PracticeProfile() {
  const { id } = useParams();
  const { data } = usePractice({ practice_id: id });
  const setTerminalGroups = useSetRecoilState(state.terminalGroupState);
  const practice = {
    ...data?.practices,
    roles: data?.practices?.roles ? data?.practices?.roles : [],
    members: data?.members?.members,
    total_patients: data?.patients?.length,
    active_patients: data?.patients?.length,
    display_settings: {
      clinical_notes: {
        ...clinicalNoteSections,
        ...data?.practices?.display_settings?.clinical_notes
      },
      ...data?.practices.display_settings
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    getTerminalGroups();
  }, []);

  const getTerminalGroups = async () => {
    try {
      const res = await requestApi({
        url: '/api/transactions/group/get',
        params: { practiceId: id },
        navigate
      });
      const { code, redirect, error, groups } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setTerminalGroups(optionify(groups));
          break;
        default:
          Honeybadger.notify(
            `file: admin/practices/profile, method: getTerminalGroups - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: admin/practices/profile, method: getTerminalGroups - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  return (
    <Allowed requiredPermissions="practice.read" showMessage showIllustration>
      <div className="flex h-full flex-col">
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <OverviewBlock practice={practice} />
        </ErrorBoundary>
        <div className="!mt-4 flex h-full flex-col overflow-hidden rounded-2xl bg-white shadow-small">
          <Tabs id={id} />
          {practice ? (
            <Outlet
              context={{
                practiceId: id,
                practice
              }}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      </div>
    </Allowed>
  );
}

export default withErrorBoundary(PracticeProfile);
