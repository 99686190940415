import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';

import Wizard from 'components/Patient/checkin/Wizard/Wizard';
import state from 'components/Patient/checkin/state';
import 'components/shared/Modal/lib/Modal.scss';

import checkinBackgroundPNG from 'assets/Images/checkin-background.png';

import useInstantActions from '../lib/useInstantActions';

export default function InstantPacket() {
  const { pathname } = useLocation();
  const checkinButton = useRef();
  const { device } = useUIContext();

  const [steps, setSteps] = useState([]);
  const [numCompleted, setNumCompleted] = useState(0);
  const [currentStepName, setCurrentStepName] = useState('');
  const [currentStepNumber, setCurrentStepNumber] = useState(1);
  const [practiceLogo, setPracticeLogo] = useState('');
  const [practitionerId, setPractitionerId] = useState();

  const [_, setUser] = useRecoilState(state?.userState);

  const {
    updateStep,
    getPacketStatus,
    navigateToIncomplete,
    isEverythingCompleted,
    handleExternalSubmit,
    currentStep,
    updateCompleteStep,
    setCompletedSteps,
    handleBack
  } = useInstantActions({
    steps,
    checkinButton,
    setNumCompleted,
    setSteps,
    setCurrentStepName,
    setCurrentStepNumber,
    setPracticeLogo,
    setUser,
    setPractitionerId
  });

  useEffect(() => {
    getPacketStatus();
  }, []);

  useEffect(() => {
    ia(steps) && currentStep();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [pathname]);

  const updateSteps = async ({
    version,
    stepId,
    formId,
    newStatus,
    formName = null,
    formsCompleted,
    formsToEdit,
    isCompleted = true,
    meta
  }) => {
    if (version === 2) {
      await updateStep({ version, stepId, formId, newStatus, meta });
      return;
    }
    let stepNameComparator = (stepName) => {
      return stepName === formName;
    };
    if (ia(formsCompleted)) {
      stepNameComparator = (stepName) => {
        return formsCompleted.includes(stepName);
      };
    }
    let formsToEditComparator = (stepName) => {
      return stepName === formsToEdit;
    };
    if (ia(formsToEdit)) {
      formsToEditComparator = (stepName) => {
        return formsToEdit.includes(stepName);
      };
    }
    const updatedSteps = steps.map((step) => {
      if (stepNameComparator(step?.name)) {
        return {
          ...step,
          status: isCompleted ? 'complete' : 'incomplete',
          completed: isCompleted,
          isStepDone: true
        };
      } else if (formsToEdit && formsToEditComparator(step?.name)) {
        return {
          ...step,
          status: 'incomplete',
          completed: false,
          isStepDone: false
        };
      } else {
        return step;
      }
    });

    setCompletedSteps(updatedSteps);
    setSteps(updatedSteps);
    navigateToIncomplete({ steps: updatedSteps });
  };

  const updateChoices = useCallback(
    (stepsToCompleted, stepsToEdit) => {
      // current step is probably one that needs to be checked for an update
      let advance = false;

      const stepsToCompleteSet = new Set();
      const stepsToEditSet = new Set();

      if (ia(stepsToCompleted)) {
        stepsToCompleted.forEach((v) => {
          stepsToCompleteSet.add(v);
        });
      }

      if (ia(stepsToEdit)) {
        stepsToEdit.forEach((v) => stepsToEditSet.add(v));
      }

      const newSteps = steps.map((v) => {
        const r = v;
        if (stepsToCompleteSet.has(v?.name)) {
          // this is one of the steps to update
          r.completed = true;
          if (v?.url === pathname) {
            // current step
            advance = true;
          }
        } else if (stepsToEditSet.has(v?.name)) {
          r.completed = false;
        }
        return r;
      });

      setCompletedSteps();
      setSteps(newSteps);

      if (advance) {
        navigateToIncomplete({ steps: newSteps });
      }
    },
    [steps]
  );

  return (
    <Wizard
      checkinBackgroundPNG={checkinBackgroundPNG}
      loginRequest={false}
      setLoginRequest={() => {}}
      device={device}
      practiceLogo={practiceLogo}
      steps={steps}
      practitionerId={practitionerId}
      currentStepName={currentStepName}
      currentStepNumber={currentStepNumber}
      updateCompleteStep={updateCompleteStep}
      updateSteps={updateSteps}
      isEverythingCompleted={isEverythingCompleted}
      navigateToIncompleted={navigateToIncomplete}
      stepObject={{}}
      setSteps={setSteps}
      checkinButton={checkinButton}
      checkinInsuranceEnabled={false}
      showButton={!pathname.includes('/complete')}
      numCompleted={numCompleted}
      handleExternalSubmit={handleExternalSubmit}
      customOnSuccess={handleExternalSubmit}
      updateChoices={updateChoices}
      isInstantPacket
      handleBack={handleBack}
    />
  );
}
