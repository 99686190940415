import { useMemo, useState } from 'react';

const useBreadcrumb = (firstStep) => {
  const [steps, setSteps] = useState([firstStep]);
  const [step, setStep] = useState(0);

  const currentStep = useMemo(() => steps[step]?.name, [step]);
  const current = useMemo(() => steps[step], [step]);

  const goBack = () => {
    if (step === 0) return;
    let trimmedSteps = steps.slice(0, step);
    setSteps(trimmedSteps);
    setStep(step - 1);
  };

  const goToStep = (s) => {
    setStep(steps.findIndex((step) => step.name === s));
  };

  const push = (s) => {
    let trimmedSteps = steps.slice(0, step + 1);
    setSteps([...trimmedSteps, s]);
    setStep(step + 1);
  };

  return { steps, step, setStep, goBack, push, currentStep, setSteps, goToStep, current };
};

export default useBreadcrumb;
