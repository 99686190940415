import { useState, useEffect } from 'react';

const getSizes = () => {
  const width = window.innerWidth;
  if (width <= 480) return 'mobile';
  if (width <= 768) return 'tablet-sm';
  if (width <= 1024) return 'tablet';
  if (width <= 1280) return 'tablet-lg';
  if (width <= 1512) return 'laptop';
  return 'desktop';
};

export const useWindowSize = () => {
  const [device, setDevice] = useState(getSizes);
  useEffect(() => {
    const handleResize = () => {
      setDevice(getSizes());
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return device;
};
