import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const ProblemManifestationDefinition = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();

  if (customClinicalNote && !clinicalNote?.other_complaints) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <SanitizeComponent
        value={clinicalNote?.other_complaints}
        title="Problem Manifestation / Definition"
      />
    </div>
  );
};

export default ProblemManifestationDefinition;
