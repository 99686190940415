import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { deleteResponse, linkAppointment } from 'api/CustomForms';
import { deleteDocument, shareDocumentWithPatient } from 'api/Documents';
import { deleteFax } from 'api/Fax';
import { downloadFile } from 'api/Filestack';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { getBase64FromUrl } from 'lib/helpers/utility';

import FaxDocsActions from 'components/practice/charts/Documents/components/FaxDocsActions';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import formatFilters from '../lib/formatFilters';

import DocumentActionsPopover from './DocumentActionsPopover';

const initConfirmation = { delete: false, share: false };

const DocActions = ({
  setViewDocument,
  isEditOpen,
  setIsEditOpen,
  fromPatient,
  patientId,
  cat,
  mimetype,
  form,
  index,
  data
}) => {
  const navigate = useNavigate();

  const [currentFile, setCurrentFile] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(initConfirmation);

  const queryClient = useQueryClient();

  const { filters, page, sort, limit } = useTableContext();

  const handleDownload = async ({ type, handle, title }) => {
    if (type === 'form')
      return showAlert({
        message: 'There was a problem downloading the document.',
        color: 'danger'
      });

    const { file } = await downloadFile(navigate, { handle, patientId });

    if (!file)
      showAlert({ message: 'There was a problem downloading the document.', color: 'danger' });

    const url = await getBase64FromUrl(file);

    const downloadLink = document.createElement('a');

    if (type === 'image/jpeg' || type === 'image/png') {
      const element = document.createElement('img');
      element.src = url;
      downloadLink.appendChild(element);
    }

    downloadLink.href = url;
    downloadLink.download = title;

    downloadLink.click();
  };

  const handleShareWithPatient = async ({ type, id }) => {
    const { success, email } = await shareDocumentWithPatient(navigate, { patientId, type, id });

    const message = success
      ? `Document shared with patient successfully${!email ? ', but the email failed' : ''}.`
      : 'There was a problem sharing the document with the patient.';

    const color = success ? 'success' : 'danger';

    showAlert({ message, color });

    if (success) setShowConfirmation(initConfirmation);
  };

  const handleDeletion = async ({ type, id }) => {
    const getData = async () => {
      switch (type) {
        case 'filestack':
          return deleteDocument(navigate, { handle: id });
        case 'form':
          return deleteResponse(navigate, { id });
        case 'fax':
          return deleteFax(navigate, id);
        default:
          showAlert({ title: 'There was a problem while deleting document.', color: 'danger' });
      }
    };

    const data = await getData();

    if (data?.deleted || data?.data > 0) {
      showAlert({ title: 'Document deleted successfully', color: 'success' });
      setShowConfirmation(initConfirmation);

      queryClient.invalidateQueries(['documents']);
    } else {
      showAlert({ title: 'There was a problem while deleting document.', color: 'danger' });
    }
  };

  const handleConfirmationFile = ({ id, type, mode }) => {
    setCurrentFile({ id, type });
    setShowConfirmation({ ...initConfirmation, [mode]: true });
  };

  const handleClose = () => {
    setCurrentFile(null);
    setShowConfirmation(initConfirmation);
  };

  const setSelectedAppointment = async (appointment, document) => {
    let params = {
      practiceId: document?.practice_id,
      patientId: document?.patient_id,
      responseId: document?.id,
      formId: document?.form_id,
      appointmentId: appointment ? appointment?.id : null
    };

    const { response } = await linkAppointment(navigate, params);

    queryClient.setQueryData(
      ['documents', formatFilters({ filters, page, limit, sort, patientId }), patientId],
      (old) => {
        return {
          ...old,
          documents: old?.documents?.map((item) => {
            if (item?.id === document?.id) {
              return {
                ...item,
                appointment_id: response?.id,
                date_of_service: response?.starts_at
              };
            } else {
              return item;
            }
          })
        };
      }
    );
  };

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        {cat?.label === 'eFax' ? (
          <FaxDocsActions
            fax={data}
            fromPatient={fromPatient}
            currentFile={currentFile}
            handleConfirmationFile={handleConfirmationFile}
          />
        ) : (
          <DocumentActionsPopover
            {...{
              setViewDocument,
              isEditOpen,
              setIsEditOpen,
              handleConfirmationFile,
              patientId,
              cat,
              document: data,
              mimetype,
              form,
              index,
              data,
              handleDownload,
              setSelectedAppointment
            }}
          />
        )}
      </div>

      <Confirm
        variant="danger"
        secondaryBtnTxt="Keep"
        title="Delete document"
        handleClose={handleClose}
        icon="new-document-remove-red"
        primaryBtnTxt="Delete document"
        handleOpen={showConfirmation?.delete}
        handleContinue={() => handleDeletion(currentFile)}
        message="Are you sure you want to delete the document?"
      />

      <Confirm
        title="Share document"
        secondaryBtnTxt="Cancel"
        handleClose={handleClose}
        icon="new-arrow-swap-down"
        primaryBtnTxt="Share document"
        handleOpen={showConfirmation?.share}
        handleContinue={() => handleShareWithPatient(currentFile)}
        message="Are you sure you want to share the document with patient?"
      />
    </>
  );
};

export default DocActions;
