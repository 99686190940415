import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';

import { requestApi } from 'api/Api';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { imagePath } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Loading from 'components/shared/Loading/Loading';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';

const Card = ({ handle, setViewDocument, patientId, type, label, url, loading }) => {
  const navigate = useNavigate();
  const [zoomed, setZoomed] = useState({ visible: false, image: null });
  const [typeToDelete, setTypeToDelete] = useState(null);

  const queryClient = useQueryClient();

  const openFilestackOverlay = async () => {
    const filestackClient = await getFilestackClient();
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      fromSources: ['local_file_system', 'webcam'],
      exposeOriginalFile: true,
      imageMin: [200, 200],
      rootId: `Overlay-${type}`
    };

    pickerOptions.onUploadDone = handleSave;
    filestackClient.picker(pickerOptions).open();
  };

  const handleSave = async (files) => {
    let handleUploaded = {};

    switch (type) {
      case 'personal_id_front':
        handleUploaded = { front: files?.filesUploaded[0]?.handle, back: handle?.back };
        break;

      case 'personal_id_back':
        handleUploaded = { front: handle?.front, back: files?.filesUploaded[0]?.handle };
        break;

      case 'insurance_front':
        handleUploaded = { front: files?.filesUploaded[0]?.handle, back: handle?.back };
        break;

      case 'insurance_back':
        handleUploaded = { front: handle?.front, back: files?.filesUploaded[0]?.handle };
        break;

      default:
        showAlert({ message: `There's been an error while uploading image.`, color: 'danger' });
        break;
    }

    let data = await requestApi({
      url: `/api/filestack/${type}`,
      params: {
        handle: handleUploaded,
        userId: patientId
      },
      navigate
    });

    if (data.front > 0 || data.back > 0) {
      setViewDocument((prev) => ({ ...prev, handle: handleUploaded }));
      queryClient.setQueryData(['importantDocuments', patientId], (oldData) => {
        if (type === 'personal_id_front' || type === 'personal_id_back')
          return { ...oldData, id: handleUploaded };

        if (type === 'insurance_front' || type === 'insurance_back')
          return { ...oldData, insurance: handleUploaded };
      });
    } else {
      showAlert({ message: 'Image update failed. Please try again.', color: 'danger' });
    }
  };

  const deleteHandle = async (type) => {
    let handleDeleted = {};

    switch (type) {
      case 'personal_id_front':
        handleDeleted = { front: null, back: handle?.back };
        break;

      case 'personal_id_back':
        handleDeleted = { front: handle?.front, back: null };
        break;

      case 'insurance_front':
        handleDeleted = { front: null, back: handle?.back };
        break;

      case 'insurance_back':
        handleDeleted = { front: handle?.front, back: null };
        break;

      default:
        showAlert({ message: `There's been an error while deleting image.`, color: 'danger' });
        break;
    }

    const data = await requestApi({
      url: `/api/filestack/${type}`,
      params: {
        handle: handleDeleted,
        userId: patientId
      },
      navigate
    });

    if (data.front > 0 || data.back > 0) {
      setViewDocument((prev) => ({ ...prev, handle: handleDeleted }));

      queryClient.setQueryData(['importantDocuments', patientId], (oldData) => {
        if (type === 'personal_id_front' || type === 'personal_id_back')
          return { ...oldData, id: handleDeleted };

        if (type === 'insurance_front' || type === 'insurance_back')
          return { ...oldData, insurance: handleDeleted };
      });
    } else {
      showAlert({ message: 'Image update failed. Please try again.', color: 'danger' });
    }
    setTypeToDelete(null);
  };

  return (
    <div className="CardWrapper mb-4">
      <div className="flex items-center justify-between gap-x-2">
        <label className="pb-[6px] text-sm font-500 text-neutral-800" htmlFor="front-card-btn">
          {label}
        </label>
        <div className="flex items-center gap-x-2">
          <Icon icon="new-edit" onClick={openFilestackOverlay} />
          <Icon icon="trash" color="primary" onClick={() => setTypeToDelete(type)} />
        </div>
      </div>
      <div
        className={cs(
          'rounded-lg border !border-dashed !border-[#dcdcdc] p-1',
          url && 'flex items-center justify-between'
        )}>
        {loading ? (
          <Loading />
        ) : url ? (
          <img
            className="aspect-video h-72 w-full cursor-zoom-in rounded-md object-cover"
            src={imagePath(url)}
            onClick={() => setZoomed({ visible: true, image: url })}
          />
        ) : (
          <div
            id="FileStackInline"
            className="flex aspect-video h-72 cursor-pointer items-center justify-center  overflow-hidden rounded-md bg-white hover:bg-neutral-50"
            onClick={openFilestackOverlay}
            data-qa="select-files-to-upload">
            <Icon size={96} icon="new-file-upload" className="cursor-pointer" />
          </div>
        )}
      </div>
      <Modal
        isOpen={zoomed.visible}
        handleClose={() => setZoomed({ visible: false, image: null })}
        className="h-[80%] w-[80%]"
        isFooter={false}>
        <img className="h-full w-full object-contain" src={imagePath(zoomed.image)} />
      </Modal>
      {!!typeToDelete && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Image"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this image?"
          handleContinue={() => deleteHandle(typeToDelete)}
          handleOpen={!!typeToDelete}
          handleClose={() => setTypeToDelete(null)}
        />
      )}
    </div>
  );
};

export default Card;
