import React, { useState } from 'react';
import cs from 'classnames';
import { pick } from 'lodash';
import { Popover } from '@headlessui/react';

import {
  handleChangePackageQuantity,
  handleChangePrice,
  handleChangeQuantity,
  handleChangeUnitPrice,
  removeItem
} from './lib';
import Icon from '../../shared/Icon/Icon';
import Input from '../../shared/Input/Input';
import { procedureTypes } from '../../../constants';
import Checkbox from '../../shared/Checkbox/Checkbox';
import EmptyProductsPackages from './EmptyProductsPackages';
import { ia, iaRa, mString } from '../../../lib/helpers/utility';
import InputOptions from '../../shared/InputOptions/InputOptions';
import CurrencyInput from '../../shared/CurrencyInput/CurrencyInput';
import { handleProcedureType } from '../../practice/appointment/appointmentHelpers';

const AddProceduresProducts = ({ label, list, type, formik }) => {
  const { values, errors, setFieldValue } = formik;

  const selectedItems = values[type];
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = list.filter?.((item) =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const handleCheckboxChange = (item) => {
    const fields =
      type === 'products'
        ? ['id', 'name', 'amount_cents_with_tax', 'quantity', 'total_amount_cents', 'sales_count']
        : type === 'services'
          ? ['id', 'name', 'time_length', 'charge_type', 'procedure_code', 'total_cost_cents']
          : [
              'id',
              'name',
              'note',
              'image',
              'products',
              'procedures',
              'sales_count',
              'amount_cents',
              'no_expiration',
              'total_amount_cents',
              'validity_time_unit',
              'validity_time_length'
            ];

    const selectedFields = pick(item, fields);

    let procType = '';

    if (item?.charge_type === 'cash') {
      procType = 'self_pay';
    } else if (item?.charge_type === 'copay') {
      procType = 'primary';
      selectedFields.charge = item.standard_charge;
      selectedFields.total_cost_cents = 0;
    } else {
      procType = 'unapplied';
    }

    if (type === 'services') {
      selectedFields.quantity = 1;
      selectedFields.sales_count = 0;
    }

    const newItem =
      type === 'services' && !item?.invoiceId
        ? { ...selectedFields, type: procType }
        : selectedFields;

    if (selectedItems.some((selectedItem) => selectedItem.id === newItem.id)) {
      const filtered = selectedItems.filter((selectedItem) => selectedItem.id !== newItem.id);
      setFieldValue(type, filtered);
    } else {
      setFieldValue(type, [...selectedItems, newItem]);
    }
  };

  return (
    <div>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="w-full p-0" data-qa={`add-btn-type-${type}`}>
              <div
                className={cs(
                  'flex cursor-pointer items-center justify-between rounded-lg !border border-solid border-transparent border-l-primary-50 border-r-primary-100 bg-gradient-to-r from-primary-50 to-primary-100 !px-4 !py-2',
                  open && '!border-primary-500'
                )}>
                <Icon
                  icon="new-dropdown-select"
                  className="grid h-9 w-9 cursor-pointer place-content-center rounded-full bg-white"
                />

                <label className="!mb-0 cursor-pointer text-sm font-500 text-primary-500">
                  Add {label}
                </label>

                <Icon
                  color="primary"
                  icon="new-chevron-down"
                  className={cs(
                    open && 'rotate-180',
                    'grid h-6 w-6 cursor-pointer place-content-center rounded-full bg-primary-200'
                  )}
                />
              </div>
            </Popover.Button>

            <Popover.Panel className="absolute z-10 mt-[6px] flex w-full flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]">
              <div
                className={`h-[365px] overflow-y-auto !p-3 ${
                  ia(filteredData) && 'h-[350px] !pb-16'
                }`}>
                {type === 'packages' ? (
                  <div className="flex flex-col gap-2">
                    {ia(filteredData) ? (
                      filteredData?.map((l, index) => {
                        return (
                          <div
                            className="flex items-start !rounded-lg bg-primary-50 !p-4"
                            key={l?.id}>
                            <Checkbox
                              data-qa={`checkbox-${index}`}
                              id={l?.id}
                              handleClick={() => handleCheckboxChange(l)}
                              isChecked={selectedItems.some((item) => item.id === l.id)}
                            />
                            <div className="w-full">
                              <p className="text-sm font-600 text-primary-500">{l.name}</p>
                              {l.procedures.map((procedure) => (
                                <div
                                  className="mb-[6px] flex w-full items-center justify-between "
                                  key={procedure.id}>
                                  <p className="text-sm text-neutral-600">{procedure.name}</p>
                                  <p className="text-xs text-neutral-600">x{procedure.quantity}</p>
                                </div>
                              ))}
                              {l.products.map((product) => (
                                <div
                                  className="mb-[6px] flex w-full items-center justify-between "
                                  key={product.id}>
                                  <p className="text-sm text-neutral-600">{product.name}</p>
                                  <p className="text-xs text-neutral-600">x{product.quantity}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <EmptyProductsPackages type="package" />
                    )}
                  </div>
                ) : (
                  <div>
                    {ia(list) ? (
                      <>
                        <Input
                          icon="search"
                          placeholder={`Search ${label}`}
                          className="!mb-2"
                          rounded="full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e?.target?.value)}
                          rightText={
                            searchTerm && (
                              <Icon icon="new-close" onClick={() => setSearchTerm('')} />
                            )
                          }
                        />
                        <p className="text-xs font-500 leading-5 text-neutral-500">{label}</p>
                      </>
                    ) : null}

                    {iaRa(filteredData).map((l, index) => {
                      return (
                        <div className="flex items-center justify-between py-[6px]" key={l?.id}>
                          <Checkbox
                            data-qa={`checkbox-${index}`}
                            id={l?.id}
                            label={l?.name}
                            handleClick={() => handleCheckboxChange(l)}
                            isChecked={selectedItems.some((item) => item.id === l.id)}
                          />

                          {type === 'products' && (
                            <p className="text-xs text-neutral-500">{mString(l?.amount_cents)}</p>
                          )}
                        </div>
                      );
                    })}

                    {!ia(list) && type === 'products' && <EmptyProductsPackages type="product" />}

                    {!ia(list) && type === 'services' && (
                      <EmptyProductsPackages type="service" link="/portal/settings" />
                    )}
                  </div>
                )}
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      {type === 'services' && errors?.services && (
        <p className="!pt-2 text-sm text-danger-500">{errors?.services}</p>
      )}

      {ia(selectedItems) && selectedItems[0]?.id && (
        <div className="!my-4">
          {selectedItems.map((item, i) => {
            const { name, quantity, sales_count, procedure_code } = item || {};

            return (
              <div className="!mb-2 flex items-start gap-[10px]" key={i}>
                <div className={cs('grid w-full', type === 'procedures' && 'min-w-[70%]')}>
                  {i === 0 && <p className="mb-[6px] text-sm font-500 text-neutral-800">{label}</p>}

                  <div
                    className={cs(
                      'flex h-10 items-center justify-between rounded-md !border border-solid border-neutral-100 bg-neutral-50 !px-3 !py-2'
                    )}>
                    <div className="grid overflow-hidden">
                      <p
                        title={name}
                        className="flex items-center truncate text-sm text-neutral-800">
                        {name}
                      </p>
                    </div>

                    {type === 'services' && !item?.packageId && ia(procedureTypes) && (
                      <InputOptions
                        position="top"
                        options={
                          procedure_code
                            ? procedureTypes
                            : procedureTypes.filter((t) => t?.value !== 'primary')
                        }
                        currentOption={
                          procedureTypes.find(
                            (o) => o?.value === (item?.type === 'cash' ? 'self_pay' : item?.type)
                          )?.label
                        }
                        onClickOption={(option) =>
                          handleProcedureType(setFieldValue, selectedItems, i, option, type)
                        }
                      />
                    )}
                  </div>
                </div>

                {type === 'products' && (
                  <CurrencyInput
                    label={i === 0 && 'Unit Price'}
                    value={item?.amount_cents_with_tax}
                    onValueChange={(v) =>
                      handleChangeUnitPrice(setFieldValue, selectedItems, v, item?.id)
                    }
                    disabled={true}
                  />
                )}

                {(type === 'services' || type === 'products') && (
                  <Input
                    min={1}
                    type="number"
                    id={quantity + i}
                    inputClassName="!w-8"
                    value={type === 'products' ? sales_count : quantity || 1}
                    label={i === 0 && 'Qty'}
                    onChange={(e) => {
                      handleChangeQuantity(
                        setFieldValue,
                        selectedItems,
                        e.target.value,
                        item?.id,
                        quantity,
                        type
                      );
                    }}
                  />
                )}

                {type === 'products' && (
                  <CurrencyInput
                    label={i === 0 && 'Price'}
                    id={`total_amount_cents-${i}`}
                    value={item?.total_amount_cents}
                    onValueChange={(v) =>
                      handleChangePrice(type, setFieldValue, selectedItems, v, item?.id)
                    }
                    disabled={true}
                  />
                )}

                {type === 'packages' && (
                  <>
                    <CurrencyInput
                      label={i === 0 && 'Unit Price'}
                      value={item?.amount_cents}
                      onValueChange={(v) =>
                        handleChangeUnitPrice(setFieldValue, selectedItems, v, item?.id)
                      }
                      disabled={true}
                      error={errors?.products && errors?.products[i]?.amount_cents}
                    />

                    <Input
                      min={1}
                      id={sales_count + i}
                      type="number"
                      inputClassName="!w-8"
                      value={sales_count}
                      hideNumberArrows={false}
                      label={i === 0 && 'Qty'}
                      onChange={(e) =>
                        handleChangePackageQuantity(
                          type,
                          setFieldValue,
                          selectedItems,
                          e.target.value,
                          item?.id
                        )
                      }
                    />

                    <CurrencyInput
                      label={i === 0 && 'Price'}
                      id={`total_amount_cents-${i}`}
                      value={item?.total_amount_cents}
                      disabled={true}
                      error={errors?.products && errors?.products[i]?.total_amount_cents}
                    />
                  </>
                )}

                <Icon
                  icon="trash"
                  className={i === 0 ? 'pt-[39px]' : 'pt-[12px]'}
                  onClick={() => removeItem(setFieldValue, selectedItems, type, i)}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AddProceduresProducts;
