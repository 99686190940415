import { fabric } from 'fabric';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';

const useEditorTools = ({
  fabricCanvasRef,

  isBold,
  isItalic,
  fontFamily,
  setSignature
}) => {
  const practiceState = useRecoilValue(currentPractice);

  const getCenterPosition = (pointer, object) => {
    return {
      left: pointer.x - object.width / 2,
      top: pointer.y - object.height / 2
    };
  };

  const addImage = (url, pointer) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        const fabricCanvas = fabricCanvasRef.current;
        img.set({ left: pointer.x, top: pointer.y, selectable: true });
        img.scaleToWidth(120);
        const centerPosition = getCenterPosition(pointer, {
          width: 120,
          height: 120 * (img.height / img.width)
        });
        img.set(centerPosition);
        fabricCanvas.add(img);
        fabricCanvas.setActiveObject(img);
        fabricCanvas.renderAll();
      },
      { crossOrigin: 'anonymous' }
    );
  };

  const handleAction = (action, pointer) => {
    switch (action) {
      case 'text':
        addText('Click to type...', pointer);
        break;
      case 'date':
        addText(
          moment.tz(practiceState.timezone).format('dddd, MMMM Do YYYY, h:mm:ss A z'),
          pointer
        );
        break;
      case 'rectangle':
        addRectangle(pointer);
        break;
      case 'circle':
        addCircle(pointer);
        break;
      case 'checkmark':
        addCheckMark(pointer);
        break;
      case 'signature':
        setSignature((sign) => {
          if (sign) {
            addImage(sign, pointer);
            return null;
          }
        });
        break;
      default:
        break;
    }
  };

  const addText = (txt, pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const text = new fabric.IText(txt, {
      left: pointer.x,
      top: pointer.y,
      fill: 'black',
      editable: true,
      selectable: true,
      fontSize: 24,
      fontFamily,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal'
    });

    const centerPosition = getCenterPosition(pointer, text);
    text.set(centerPosition);

    fabricCanvas.add(text);
    fabricCanvas.setActiveObject(text);
    fabricCanvas.renderAll();
  };

  const addRectangle = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const rect = new fabric.Rect({
      left: pointer.x,
      top: pointer.y,
      fill: '#FFFFFF',
      width: 100,
      height: 40,
      selectable: true
    });

    const centerPosition = getCenterPosition(pointer, rect);
    rect.set(centerPosition);

    fabricCanvas.add(rect);
    fabricCanvas.setActiveObject(rect);
    fabricCanvas.sendToBack(rect);
    fabricCanvas.renderAll();
  };

  const addCircle = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const circle = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      fill: '#000000',
      radius: 20,
      selectable: true
    });

    const centerPosition = getCenterPosition(pointer, circle);
    circle.set(centerPosition);
    fabricCanvas.add(circle);
    fabricCanvas.setActiveObject(circle);
    fabricCanvas.renderAll();
  };

  const addCheckMark = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const mark = new fabric.Path(
      'M20.5962 0.150419C18.8621 1.1761 16.9023 2.6445 15.0595 4.30046C13.5744 5.63537 11.9904 7.24402 10.5234 8.90674C9.5113 10.0558 8.42012 11.3924 7.43443 12.6918C7.09158 13.1447 6.20479 14.3579 5.92623 14.7584C5.78283 14.9645 5.70371 15.0642 5.68722 15.0591C5.67404 15.0558 4.41967 14.3326 2.90158 13.4539L0.140668 11.8537L0.0780328 11.9263C0.0450666 11.9669 0.00880386 12.0108 0.000562327 12.026C-0.0142724 12.0531 0.219787 12.3336 6.18831 19.4289L6.66797 20H6.7339H6.79983L7.174 19.2345C9.02175 15.4647 10.8365 12.3606 12.8953 9.44746C15.165 6.23862 17.7347 3.28323 20.6258 0.56103C20.8319 0.366708 21 0.202802 21 0.196043C21 0.187594 20.8863 0.0219976 20.8648 3.0783e-05C20.8615 -0.00165897 20.7412 0.0659313 20.5962 0.150419Z',
      {
        left: pointer.x,
        top: pointer.y,
        fill: 'black',
        width: 40,
        height: 40,
        selectable: true
      }
    );
    const centerPosition = getCenterPosition(pointer, mark);
    mark.set(centerPosition);

    fabricCanvas.add(mark);
    fabricCanvas.setActiveObject(mark);
    fabricCanvas.renderAll();
  };

  return { handleAction };
};

export default useEditorTools;
