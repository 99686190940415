import React from 'react';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import { camelCaseToReadableFormat, ia } from 'lib/helpers/utility';

const loadValues = (options, values = [], value) => {
  const currentOptions = structuredClone(values) || [];

  const filteredValues = currentOptions?.includes(value)
    ? currentOptions?.filter((v) => v !== value)
    : [...currentOptions, value];

  if (filteredValues.length === 0) return null;

  return filteredValues;
};

export default function CheckboxFilter({ data, index, setFilter }) {
  return (
    <div className="flex cursor-pointer flex-col gap-2 rounded-lg !py-2">
      <p className="!px-4 font-medium text-primary-700 text-sm">{data?.title || 'Search'}</p>
      <div className="relative !mx-4 flex flex-wrap items-center gap-x-3 gap-y-1">
        {data?.options?.map((option, idx) => {
          const value = typeof option === 'object' ? option?.value : option;
          return (
            <Checkbox
              key={idx}
              data-qa={`checkbox-${option}`}
              labelTextColor="!text-primary-900"
              isChecked={data?.values?.includes(value)}
              inputClassName="!h-[20px] !w-[20px] before:!h-[18px] before:!w-[18px] after:!w-[6px] after:!h-[11px] after:!top-[3px] after:!left-[6px]"
              handleClick={() =>
                setFilter(index, {
                  ...data,
                  values: loadValues(data?.options, data?.values, value)
                })
              }
              label={typeof option === 'object' ? option?.label : camelCaseToReadableFormat(option)}
            />
          );
        })}
      </div>
    </div>
  );
}
