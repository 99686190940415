import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';

import { deleteGuarantor as deleteGuarantorReq } from 'api/PatientGuarantor';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import SimpleAccordion from '../ClinicalNote/shared/SimpleAccordion';

import GuarantorContent from './components/GuarantorContent';

export default function GuarantorMobile({
  guarantor,
  index,
  deleteGuarantor,
  selected,
  setSelected,
  isOpen,
  toggleOne,
  handleEditGuarantor
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();

  const { mutate: deleteGuarantorFn, isLoading: loading } = useCustomMutation({
    mutationFn: () =>
      deleteGuarantorReq(
        {
          id: guarantor.id
        },
        navigate
      ),
    successMessage: 'Successfully deleted guarantor.',
    onSuccess: (data) => {
      if (data.deleted) {
        deleteGuarantor(guarantor);
      }
      setShowConfirmationModal(false);
    }
  });

  const sameId = selected && selected?.id === guarantor?.id;
  return (
    <>
      <SimpleAccordion
        key={`guarantor-${guarantor.id}-accordion`}
        showExpandIcon={true}
        title={
          <div className="flex items-center gap-[6px] p-2 sm:p-0 xs:p-0">
            <Icon icon="new-heart-plus" />
            <span className="text-sm font-500 text-neutral-800">{`Guarantor ${index + 1}`}</span>
          </div>
        }
        titleHighlight={false}
        open={isOpen && isOpen[guarantor?.id]}
        onToggle={() => toggleOne(guarantor.id)}
        onClick={() => setSelected && setSelected(guarantor)}
        className={cs(
          'cursor-pointer !border-2',
          !sameId
            ? '!border-transparent bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.04)]'
            : '!border-primary-100 !bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)]'
        )}
        edit={
          <>
            <div className="flex flex-wrap items-center gap-1">
              <Icon
                icon="new-edit-gray"
                data-qa="edit-btn-guarantor"
                onClick={() => handleEditGuarantor(guarantor, index)}
                color="neutral"
              />
              <Icon
                icon="trash"
                data-qa="delete-btn-guarantor"
                onClick={() => setShowConfirmationModal(true)}
              />
            </div>
          </>
        }>
        <GuarantorContent className="p-2 sm:p-0 xs:p-0" guarantor={guarantor} />
      </SimpleAccordion>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={deleteGuarantorFn}
        title="Delete Guarantor"
        loading={loading}
        message="Are you sure you want to delete this guarantor?"
        variant="danger"
      />
    </>
  );
}
