import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

import { getSelectedForms } from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyScribeTemplate/lib/helpers';
import { generateAdvancedSectionFormMap } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import { clinicalNote as clinicalNoteState } from 'components/state';

import { useMyScribeAIContext } from '../../MyScribeAIContext';

import { ChatContextApi, ChatContextData } from './ChatContext';
import { transformToFinalPromptArray } from './lib/helpers';

export const ChatContextProvider = ({ children }) => {
  const [inputTags, setInputTags] = useState([]);
  const [userPrompt, setUserPrompt] = useState('');

  const [componentToAdd, setComponentToAdd] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState({});
  const { typeOfNotes } = useMyScribeAIContext();
  const [selectedPromptNoteType, setSelectedPromptNoteType] = useState(null);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const {
    setEnhanceNarrativeParams,
    currentHpOverviewData,
    overviewData,
    cnDisplaySettings,
    customFormTypes
  } = useClinicalNoteContext();
  const selectedPromptTagsIds = inputTags.map((tag) => tag.id);
  const selectedForms = getSelectedForms(selectedPromptTagsIds, clinicalNote, selectedTableRows, {
    ...currentHpOverviewData,
    ...overviewData
  });
  const sectionMap = {
    hp: cnDisplaySettings?.hp?.enabled,
    intake: cnDisplaySettings?.mha?.enabled,
    soap: cnDisplaySettings?.soap?.enabled,
    progress: cnDisplaySettings?.epn?.enabled,
    '3d': cnDisplaySettings?.chart3d?.enabled,
    ...generateAdvancedSectionFormMap({ cnDisplaySettings, customFormTypes })
  };

  const enabledSection = Object.entries(sectionMap).find(([, isEnabled]) => isEnabled)?.[0];
  const initialType = cnDisplaySettings?.soap.enabled ? 'soap' : enabledSection;

  const onComponentAdd = useCallback(() => {
    if (!inputTags.some((tag) => tag.id === componentToAdd?.id)) {
      setInputTags((prev) => [...prev, componentToAdd]);
    }
    setComponentToAdd(null);
  }, [componentToAdd, inputTags]);

  const onComponentRemove = useCallback(() => {
    const filteredTags = inputTags.filter((tag) => tag.id !== componentToAdd?.id);
    setInputTags(filteredTags);
  }, [componentToAdd, inputTags]);

  const onEnhanceNote = useCallback(
    async (obj) => {
      const newSelectedForms = transformToFinalPromptArray(selectedForms);

      setEnhanceNarrativeParams({
        messageKind: 'auto_map',
        body: obj?.userMessage?.message,
        name: selectedPromptNoteType?.type,
        selectedForms: newSelectedForms,
        ...obj
      });
      setSelectedTableRows({});
    },
    [selectedForms, selectedPromptNoteType?.type, setEnhanceNarrativeParams]
  );

  const finalData = useMemo(
    () => ({
      inputTags,
      componentToAdd,
      selectedTableRows,
      selectedPromptNoteType,
      userPrompt
    }),
    [inputTags, componentToAdd, selectedTableRows, selectedPromptNoteType, userPrompt]
  );

  const setTags = useCallback(setInputTags, [setInputTags]);
  const setComponent = useCallback(setComponentToAdd, [setComponentToAdd]);
  const setSelectedRows = useCallback(setSelectedTableRows, [setSelectedTableRows]);
  const setSelectedNoteType = useCallback(setSelectedPromptNoteType, [setSelectedPromptNoteType]);

  const api = useMemo(
    () => ({
      setSelectedTableRows: setSelectedRows,
      setSelectedPromptNoteType: setSelectedNoteType,
      setComponentToAdd: setComponent,
      setInputTags: setTags,
      onAddComponent: onComponentAdd,
      onRemoveComponent: onComponentRemove,
      onEnhanceNote,
      setUserPrompt
    }),
    [
      setSelectedRows,
      setSelectedNoteType,
      setComponent,
      setTags,
      onComponentAdd,
      onComponentRemove,
      onEnhanceNote,
      setUserPrompt
    ]
  );

  useEffect(() => {
    setSelectedPromptNoteType(typeOfNotes[initialType]);
  }, [initialType, typeOfNotes]);

  return (
    <ChatContextData.Provider value={finalData}>
      <ChatContextApi.Provider value={api}>{children}</ChatContextApi.Provider>
    </ChatContextData.Provider>
  );
};
