import React from 'react';
import { useRecoilValue } from 'recoil';

import { getInvoiceDetails } from '../lib/internal/configs';
import NewInvoiceDetailsTable from './NewInvoiceDetailsTable';
import { currentPractice } from 'components/practice/practiceState';

const NewInvoice = ({ invoice, children, isView = false, isAEOB }) => {
  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;
  const patientInvoiceDetails = getInvoiceDetails(invoice, claims);

  return (
    <>
      <div className="w-full !p-4">
        <div className="flex gap-3"></div>
        <div>
          <NewInvoiceDetailsTable
            invoices={patientInvoiceDetails}
            isView={isView}
            isAEOB={isAEOB}
          />
        </div>
        {children}
      </div>
    </>
  );
};

export default NewInvoice;
