export function transformToFinalPromptArray(obj) {
  const result = [];
  const aggregationForms = [
    'medications',
    'surgicalHistory',
    'familyHistory',
    'allergies',
    'vaccines',
    'weightLossAdministration'
  ];

  // Handle "vitals" dynamically
  if (obj.vitals) {
    obj.vitals.forEach((vitalGroup) => {
      if (vitalGroup.vitals) {
        vitalGroup.vitals.forEach((vital) => {
          if (vital.id) {
            result.push(`vitals:${vital.id}`);
          }
        });
      }
    });
  }

  // Handle "patient_history" dynamically
  if (obj.patient_history) {
    obj.patient_history.forEach((history) => {
      const type = history.type || 'unknown';

      if (aggregationForms.includes(type)) {
        // Add single string for aggregation forms
        if (!result.includes(`patient_history:${type}`)) {
          result.push(`patient_history:${type}`);
        }
      } else if (type === 'vitals' && Array.isArray(history.vitals)) {
        // Special handling for "vitals" inside "patient_history"
        history.vitals.forEach((vital) => {
          if (vital.id) {
            result.push(`patient_history:vitals:${vital.id}`);
          }
        });
      } else if (history.id) {
        result.push(`patient_history:${type}:${history.id}`);
      } else {
        result.push(`patient_history:${type}`);
      }
    });
  }

  // Handle "patient_forms" with form_id
  if (obj.patient_forms) {
    obj.patient_forms.forEach((form) => {
      if (form.form_id) {
        result.push(`patient_forms:${form.form_id}`);
      }
    });
  }

  // Handle "diagnosis-codes" (array of strings)
  if (obj['diagnosis-codes']) {
    obj['diagnosis-codes'].forEach((code) => {
      result.push(`diagnosis-codes:${code}`);
    });
  }

  // Generic handling for other top-level properties
  Object.keys(obj).forEach((key) => {
    if (['vitals', 'patient_history', 'patient_forms', 'diagnosis-codes'].includes(key)) return; // Skip custom cases

    const items = obj[key];
    if (Array.isArray(items)) {
      items.forEach((item) => {
        if (item.id) {
          result.push(`${key}:${item.id}`);
        }
      });
    } else if (items && typeof items === 'object' && items.id) {
      result.push(`${key}:${items.id}`);
    }
  });

  return result;
}
