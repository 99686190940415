import { requestApi } from './Api';

const getFaxUser = (navigate) => requestApi({ navigate, url: '/api/fax/get/fax_user' });

const getInboundFaxes = (navigate, params) =>
  requestApi({ navigate, url: '/api/fax/get/inbound', params });

const getUnreadFaxes = (navigate) => requestApi({ navigate, url: '/api/fax/get/unread' });

const getOutboundFaxes = (navigate, params) =>
  requestApi({ navigate, url: '/api/fax/get/outbound', params });

const getDraftFaxes = (navigate, params) =>
  requestApi({ navigate, url: '/api/fax/get/drafts', params });

const getSingleDraftFax = (navigate, faxId) =>
  requestApi({ navigate, url: '/api/fax/get/single_draft', params: { faxId } });

const getArchivedFaxes = (navigate, params) =>
  requestApi({ navigate, url: '/api/fax/get/archived', params });

const sendFax = (navigate, data, onError) =>
  requestApi({ navigate, url: '/api/fax/send', params: data, onError });

const previewFax = (navigate, data) =>
  requestApi({ navigate, url: '/api/fax/preview', params: data });

const previewSavedFax = (navigate, faxId, withDocumentSign = false) =>
  requestApi({
    navigate,
    url: '/api/fax/preview_saved',
    params: { id: faxId, withDocumentSign }
  });

const downloadFax = (navigate, faxId) =>
  requestApi({ navigate, url: '/api/fax/download', params: { id: faxId } });

const draftFax = (navigate, data) => requestApi({ navigate, url: '/api/fax/draft', params: data });

const markFaxesAsRead = (navigate, faxIds, onSuccess) =>
  requestApi({ navigate, url: '/api/fax/mark_as_read', params: faxIds, onSuccess });

const assignFaxesToPatient = (navigate, faxes) =>
  requestApi({ navigate, url: '/api/fax/assign', params: faxes });

const assignFaxesToProviders = (navigate, faxes) =>
  requestApi({ navigate, url: '/api/fax/assign_providers', params: faxes });

const archiveFaxes = (navigate, faxIds) =>
  requestApi({ navigate, url: '/api/fax/archive', params: faxIds });

const unarchiveFaxes = (navigate, faxIds) =>
  requestApi({ navigate, url: '/api/fax/unarchive', params: faxIds });

const deleteFax = (navigate, id) =>
  requestApi({ navigate, url: '/api/fax/delete', params: { id } });

const resendFax = (navigate, params) => requestApi({ navigate, url: '/api/fax/resend', params });

const renameFax = (navigate, data) =>
  requestApi({ navigate, url: '/api/fax/rename', params: data });

const getFaxesCSV = (navigate, params) => requestApi({ navigate, url: '/api/fax/get/csv', params });

const updateFax = (navigate, params) => requestApi({ navigate, url: '/api/fax/update', params });

export {
  getFaxUser,
  getInboundFaxes,
  getFaxesCSV,
  getOutboundFaxes,
  getDraftFaxes,
  getArchivedFaxes,
  sendFax,
  previewFax,
  previewSavedFax,
  draftFax,
  markFaxesAsRead,
  assignFaxesToPatient,
  assignFaxesToProviders,
  archiveFaxes,
  getSingleDraftFax,
  deleteFax,
  downloadFax,
  resendFax,
  unarchiveFaxes,
  renameFax,
  getUnreadFaxes,
  updateFax
};
