import { Honeybadger } from '@honeybadger-io/react';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import { ia } from 'lib/helpers/utility';
import Loading from 'components/shared/Loading/Loading';
import practiceState from 'components/practice/practiceState';
import ConsentModal from './components/TechnologyConsent/ConsentModal';
import AccountImage from './components/AccountImage';
import SelfScheduling from './components/SelfScheduling';
import ContactInformation from './components/ContactInformation';
import AccountAddress from './components/AccountAddress';
import Technology from './components/Technology';
import StickyNotes from './components/StickyNotes';
import DBAName from './components/DBAName';
import { showAlert } from 'components/shared/Alert/Alert';

export default function Account() {
  const [practice, setPractice] = useRecoilState(practiceState.currentPractice);
  const [address, setAddress] = useState();
  const [loading, setLoading] = useState(false);
  const [showCurrencyInput, setShowCurrencyInput] = useState(false);
  const [selectedSurchargeButton, setSelectedSurchargeButton] = useState();
  const [isServicesAvailable, setIsServicesAvailable] = useState(false);
  const [showTechnologyModal, setShowTechnologyModal] = useState(false);
  const [surchargeLoading, setSurchargeLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getPractice();
  }, []);

  const { data } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    const isAvailable = data?.services.some(
      (service) =>
        service?.state === 'active' && service.self_scheduling && ia(service?.practitioner_ids)
    );
    setIsServicesAvailable(isAvailable);
  }, [data]);

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  const updateSurcharge = async (data, type) => {
    try {
      let res;
      setSurchargeLoading(true);
      if (type == 'surcharge_enabled') {
        res = await requestApi({
          url: '/api/practice/update',
          params: {
            practice_id: practice.id,
            fields: { surcharge_enabled: data }
          },
          navigate
        });
      } else if (type == 'surcharge_percentage') {
        res = await requestApi({
          url: '/api/practice/update',
          params: {
            practice_id: practice.id,
            fields: { surcharge_percentage: parseFloat(data || 0) }
          },
          navigate
        });
      }

      if (res.code === 0) {
        showAlert({
          message: 'Successful',
          color: 'success'
        });
        if (type == 'surcharge_enabled') {
          setPractice((prev) => ({ ...prev, surcharge_enabled: data }));
        } else if (type == 'surcharge_percentage') {
          setPractice((prev) => ({ ...prev, surcharge_percentage: parseFloat(data || 0) }));
        }
      } else {
        Honeybadger.notify(
          `practice: ${practice.id}, file: /practice/settings/account/index.js, method: updateSurcharge, error`
        );
      }
      setSurchargeLoading(false);
    } catch (error) {
      Honeybadger.notify(
        `practice: ${practice.id}, file: /practice/settings/account/index.js, method: updateSurcharge, error:${error}`
      );
      console.error(error);
    }
  };

  const getPractice = async () => {
    setLoading(true);
    try {
      const res = await requestApi({ url: '/api/practice/current_practice', params: {}, navigate });
      const { redirect, practice: loadedPractice } = res;
      if (loadedPractice) {
        setPractice((prev) => ({
          ...loadedPractice,
          ...prev
        }));
        rememberSelected(loadedPractice.surcharge_percentage);
        setAddress(loadedPractice.practiceAddress);
      } else {
        navigate(redirect);
      }
    } catch (error) {
      Honeybadger.notify(
        `practice: ${practice.id}, file: /practice/settings/account/index.js, method: getPractice, error:${error}`
      );
      console.error(error);
    }
    setLoading(false);
  };

  const surchargeOptions = [1, 2, 3, 4, '%'];

  const rememberSelected = (btn) => {
    if (Number(btn) == 0) {
      setSelectedSurchargeButton(-1);
    } else if (!isNaN(btn) && surchargeOptions.includes(Number(btn))) {
      setSelectedSurchargeButton(Number(btn - 1));
    } else if (!isNaN(btn) && !surchargeOptions.includes(btn)) {
      setSelectedSurchargeButton(4);
      setShowCurrencyInput(true);
    }
  };

  const hideTechnologyModal = () => {
    setShowTechnologyModal(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="!p-3">
          <AccountImage practice={practice} setPractice={setPractice} />
          <SelfScheduling practice={practice} isServicesAvailable={isServicesAvailable} />
          <DBAName practice={practice} />
          <ContactInformation practice={practice} />
          <AccountAddress address={address} setAddress={setAddress} practice={practice} />
          <Technology
            practice={practice}
            updateSurcharge={updateSurcharge}
            surchargeLoading={surchargeLoading}
            setShowTechnologyModal={setShowTechnologyModal}
            surchargeOptions={surchargeOptions}
            selectedSurchargeButton={selectedSurchargeButton}
            showCurrencyInput={showCurrencyInput}
            setShowCurrencyInput={setShowCurrencyInput}
            setSelectedSurchargeButton={setSelectedSurchargeButton}
          />
          <StickyNotes practice={practice} setPractice={setPractice} />
          <ConsentModal
            showTechnologyModal={showTechnologyModal}
            hideTechnologyModal={hideTechnologyModal}
            setPractice={setPractice}
            practice={practice}
            updateSurcharge={() => updateSurcharge(true, 'surcharge_enabled')}
          />
        </div>
      )}
    </>
  );
}
