import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../api/Api';
import { verifyEmail } from '../../lib/helpers/utility';
import Button from '../shared/Buttons/Button';
import Input from '../shared/Input/Input';

const PasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [state, setState] = useState({ email_valid: false, email_sent: false });
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Myriad - Password Reset`;
  }, []);

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'email':
        setEmail(value);
        if (verifyEmail(value)) {
          setState({ ...state, email_valid: true });
        } else {
          setState({ ...state, email_valid: false });
        }
        break;
    }
  };

  const requestNewPassword = async () => {
    try {
      if (!verifyEmail(email)) {
        setState({ ...state, email_valid: false });
        toast.error(`Please enter a valid email!`);
        throw new Error(`Invalid email.`);
      }
      const resData = await requestApi({
        url: '/api/user/password/request',
        params: { email: email },
        navigate
      });
      const { code, redirect, error } = resData;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setState({ ...state, email_sent: true });
          navigate(redirect);
          toast.success(`Successfully sent a password reset email!`);
          break;
        case 1:
          toast.error(error);
          break;

        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: auth/request, method: requestNewPassword - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: auth/request, method: requestNewPassword - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  if (state.email_sent) {
    return (
      <div>
        <div className="text-2xl text-primary-900">Password Reset</div>
        <div className="text-success-600">Email has been sent!</div>
        <Button
          color="primary"
          className={'mt-[0.8rem] w-full'}
          onClick={() => setState({ ...state, email_sent: false })}
          text="Send Another"
        />
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4 text-2xl text-primary-900">Password Reset</div>
      <Input
        label={'Please enter your email to request a password reset.'}
        id="resetPassword"
        value={email}
        name="email"
        type="email"
        onChange={handleUpdate}
        placeholder="example@email.com"
        error={!state.email_valid && email.length >= 5 ? 'Please enter a valid email' : ''}
      />
      <Button
        text="Send Request"
        className={'mt-[0.8rem] w-full'}
        color="primary"
        onClick={requestNewPassword}
        data-qa="send-request-btn"
        disabled={!state.email_valid}
      />
    </div>
  );
};

export default PasswordRequest;
