import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../../../shared/Icon/Icon';
import cs from 'classnames';
import AdvancedFormsSwitch from '../../../shared/AdvancedFormsSwitch';
import CreateMacro from '../../../Sidebar/Macros/Create/CreateMacro';
import { useParams } from 'react-router-dom';
import { Capitalize } from '../../../../../../../lib/helpers/utility';
import DisplaySettings from './DisplaySettings';

const ManagementPopover = () => {
  const { cnDisplaySettings, setCNDisplaySettings, setShowPrevNoteModal } =
    useClinicalNoteContext();
  const [createMacroModal, setCreateMacroModal] = useState(false);
  const { type } = useParams();

  return (
    <>
      <Popover className="relative">
        {({ open, close }) => (
          <div>
            <Popover.Button
              data-qa="section-management-popover"
              className={cs(
                'flex w-[30px] h-[30px] cursor-pointer items-center justify-center rounded-lg',
                open && 'bg-primary-700'
              )}>
              <Icon
                icon="new-sliders-horiz"
                color={open ? 'white' : 'primary'}
                size="18px"
                stroke
                className="cursor-pointer"
              />
            </Popover.Button>
            <Popover.Panel className="absolute left-0 top-full z-20 mt-[6px] grid w-max max-w-[253px] cursor-pointer gap-y-1 rounded-md bg-white !py-2 shadow-medium">
              <AdvancedFormsSwitch
                cnDisplaySettings={cnDisplaySettings?.sections}
                setCNDisplaySettings={setCNDisplaySettings}
              />
              <div className="w-full h-[1px] bg-neutral-100"></div>
              <div
                data-qa="create-new-appointment-template"
                className="flex gap-x-2 py-[6px] !px-4 hover:bg-primary-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setCreateMacroModal(true);
                  close();
                }}>
                <Icon icon="new-diskette" color="primary" />
                <span className="text-sm font-400 text-primary-900">{`Create new ${
                  type === 'hp' ? 'H&P' : Capitalize(type)
                } macro`}</span>
              </div>
              <div
                data-qa="load-from-previous-visit"
                className="flex gap-x-2 py-[6px] !px-4 hover:bg-primary-50"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowPrevNoteModal(true);
                  close();
                }}>
                <Icon icon="new-reset-history" color="primary" />
                <span className="text-sm font-400 text-primary-900">
                  Load from a previous visit
                </span>
              </div>

              <div className="w-full h-[1px] bg-neutral-100"></div>
              <div className="pl-[22px] !pr-5">
                <p className="select-none text-sm text-neutral-600">Display settings</p>
              </div>
              <div className="flex overflow-hidden">
                <DisplaySettings />
              </div>
            </Popover.Panel>
          </div>
        )}
      </Popover>
      {createMacroModal && (
        <CreateMacro
          open={createMacroModal}
          handleClose={() => setCreateMacroModal(false)}
          macroTitle={`${Capitalize(type)} Macro`}
          macroType="note_macro"
        />
      )}
    </>
  );
};

export default ManagementPopover;
