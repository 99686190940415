import React, { useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { ia } from 'lib/helpers/utility';
import { useClaims } from 'lib/hooks/queries/claims/useClaims';

import SearchPatients from 'components/Search/SearchPatients/SearchPatients';
import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';
import Typography from 'components/shared/Typography/Typography';

import { ALLOCATE_EOB_COLUMN_DEFS } from '../lib/allocateEobColumns';
import { GRID_OPTIONS } from '../lib/helpers';

import NoClaims from './NoClaims';
import { formatAllowedEob } from './lib/formatAllowedEob';
import handleStatusRowAdd from './lib/handleStatusRowAdd';
import { DEFAULT_COLUMN_DEFS, getRowClassRules } from './lib/helpers';

export default function AllocateEOB({ showInfoInRecordings = false }) {
  const {
    era,
    setSelectedUnmatchedClaim,
    setAmount,
    selectedUnmatchedClaim,
    setProcedures,
    setStatusCode,
    setAllocateEob
  } = useEraContext();

  const [patientId, setPatientId] = useState();
  const [hoveredId, setHoveredId] = useState(null);

  const onCellMouseOver = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(event.data?.claimId);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const onCellMouseOut = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(null);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const gridRef = useRef(null);

  const { data } = useClaims({
    params: {
      withCount: true,
      filters: {
        patients: [
          {
            label: 'Patient',
            value: patientId
          }
        ]
      }
    },
    dependencies: [patientId],
    options: { enabled: !!patientId }
  });

  const handleSelectPatient = (selectedClaim) => {
    setSelectedUnmatchedClaim(selectedClaim.id);
    setAllocateEob(selectedClaim);
    setProcedures(
      selectedClaim.invoice_procedures.filter((p) => filterSuperbillClaimProcedure(p) || [])
    );

    const totalCharge = selectedClaim ? Number(selectedClaim.total_charge) * 100 : 0;
    const amount =
      totalCharge > era?.unallocated_amount_cents ? era?.unallocated_amount_cents : totalCharge;

    setAmount(amount);
    setStatusCode(null);

    const api = gridRef.current.api;
    handleStatusRowAdd(api, selectedClaim, selectedUnmatchedClaim);
  };

  const rowData = useMemo(
    () => (patientId ? formatAllowedEob({ claims: data?.claims }) : []),
    [data?.claims, patientId]
  );

  const columnDefs = useMemo(() => ALLOCATE_EOB_COLUMN_DEFS({ onChange: handleSelectPatient }), []);

  return (
    <div
      data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}
      className="flex flex-col gap-2 flex-1">
      <div className="flex items-center gap-2 mt-6">
        <Typography variant="p" color="text-primary-800">
          Patient Name/ID
        </Typography>
        <div className="w-[304px]">
          <SearchPatients
            className="rounded-full border-neutral-200 bg-neutral-50 w-fit"
            handleClick={(e) => {
              if (e?.value) {
                setPatientId(e.value);
              } else {
                setPatientId(null);
              }
            }}
          />
        </div>
      </div>

      {patientId && ia(rowData) && (
        <div className="ag-theme-quartz shadow-none h-full">
          <AgGridReact
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            rowData={rowData}
            gridOptions={{
              ...GRID_OPTIONS,
              getRowHeight: (params) => (params.node.isStatusRow ? 100 : undefined)
            }}
            rowClassRules={getRowClassRules(selectedUnmatchedClaim, hoveredId)}
            onCellMouseOver={onCellMouseOver}
            onCellMouseOut={onCellMouseOut}
            suppressRowHoverHighlight
          />
        </div>
      )}

      <NoClaims data={rowData} />
    </div>
  );
}
