import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import cs from 'classnames';
import useOutsideClick from 'lib/hooks/useOutsideClick';

const ColorPickerRgb = ({ color, onChange, editable = true, className }) => {
  const [show, setShow] = useState({ edit: false });
  const pickerRef = useRef();

  useOutsideClick(pickerRef, () => setShow({ ...show, edit: false }));

  const handleSwatchClick = (e) => {
    if (editable) setShow({ ...show, edit: !show.edit });
  };

  const handleChange = (e) => {
    const newColor = e.hex;
    onChange(newColor);
  };

  return (
    <div className={cs('relative', className)} ref={pickerRef}>
      <div
        className="!m-0 flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg border border-solid !border-neutral-100 bg-white  shadow-[0px_2px_4px_0px_rgba(27,33,35,0.04)] "
        onClick={handleSwatchClick}>
        <div className={'h-7 w-7 rounded-sm'} style={{ background: color }}></div>
      </div>
      {show.edit && (
        <div className="absolute z-10 mt-1 top-full">
          <div onClick={() => setShow({ ...show, edit: false })}></div>
          <SketchPicker color={color} onChange={handleChange} />
        </div>
      )}
    </div>
  );
};

export default ColorPickerRgb;
