import React from 'react';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import { Doughnut } from 'react-chartjs-2';
import { capitalize } from 'lodash';
import Icon from '../../../../shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';

export default function Invoices(props) {
  const { overview } = props;
  const navigate = useNavigate();
  const count = overview?.count?.invoices;
  const labels = ['not paid', 'paid'];
  const colors = ['#13B9FF', '#8191E8'];
  const data = [count?.unpaid || 0, count?.paid || 0];
  const all = data.reduce((a, b) => a + b, 0);

  const percentage = (data) => {
    return (Math.floor((data / all) * 100) || 0) + '%';
  };

  const chartData = {
    labels: labels.map((label) => capitalize(label)),
    datasets: [
      {
        data,
        backgroundColor: colors,
        borderWidth: 1
      }
    ]
  };

  const options = {
    cutout: 110,
    aspectRatio: 1,
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        display: false
      }
    },
    tooltip: {}
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader icon="chart" hasPlus={false} {...props}>
        <div
          onClick={() => {
            navigate(`../electronic-invoices`);
          }}
          className="mx-1 cursor-pointer rounded-lg bg-neutral-50 !px-2 !py-2">
          <Icon className="cursor-pointer" icon="new-link"></Icon>
        </div>
      </WidgetHeader>

      <div className="flex flex-1 items-center justify-between overflow-hidden">
        <div className="flex flex-1 flex-col justify-center gap-2 !pl-10">
          <table className="inline-block text-left">
            {labels.map((label, index) => (
              <tr key={index}>
                <td className="!py-[5px] !pr-2">
                  <div
                    style={{ backgroundColor: colors[index] }}
                    className={`h-4 w-4 rounded-full `}></div>
                </td>
                <td className="!pr-3">
                  <p className="font-medium">{capitalize(label)}</p>
                </td>
                <td className="!pr-3">
                  <p className="text-neutral-600">{percentage(data[index])}</p>
                </td>
                <td>
                  <p className="font-semibold text-neutral-900">{data[index]}</p>
                </td>
              </tr>
            ))}
          </table>
        </div>
        <div className="!aspect-1 relative h-full !max-h-[360px] !max-w-[360px]">
          <div className="absolute left-1/2 top-1/2 flex translate-x-[-50%] translate-y-[-50%] flex-col justify-center text-center">
            <p className="text-2xl font-semibold text-primary-900">{all}</p>
            <p className="-mt-1 text-neutral-600">Invoices</p>
          </div>
          <Doughnut className="p-[36px]" options={options} data={chartData} />
        </div>
      </div>
    </div>
  );
}
