import React from 'react';
import { formatDate, isEmpty } from '../../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import cs from 'classnames';
import { userState } from '../../../../../../state';
import Icon from '../../../../../../shared/Icon/Icon';

const CreationInfo = ({ createdBy, createdAt }) => {
  const currentUser = useRecoilValue(userState);

  const fullName =
    createdBy === 'currentUser' ? `${currentUser.f_name} ${currentUser.l_name}` : `${createdBy}`;

  return (
    <div className="!mt-2 flex flex-col items-start justify-start gap-0.5 rounded-[3px] bg-neutral-50 px-1.5 py-1">
      <div className="flex items-start justify-between gap-0.5 self-stretch">
        <div className="flex items-center justify-start gap-1">
          <Icon icon="new-calendar-gray" color="text-neutral-500" />
          <div className="w-[120px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal leading-snug text-primary-900">
            {formatDate(createdAt)}
          </div>
        </div>
        {!isEmpty(createdBy) && (
          <div className="flex items-center justify-start gap-1">
            <Icon icon="new-user-edit" color="text-neutral-500" />
            <div
              className={cs(
                'overflow-hidden text-ellipsis whitespace-nowrap text-sm font-normal leading-snug text-primary-900',
                fullName.length > 10 && 'w-[80px]'
              )}>
              {fullName}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreationInfo;
