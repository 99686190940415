import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPractitioner } from 'api/Practitioner';

export const usePractitioner = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['practitioner', ...dependencies], () => getPractitioner(params, navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
