import React, { useEffect, useState } from 'react';
import Icon from '../shared/Icon/Icon';
import Button from '../shared/Buttons/Button';
import { useNavigate, useLocation } from 'react-router-dom';
import { unsubscribeUser, isUserUnsubscribed, subscribeUser } from 'api/User';
import { useMutation } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';

const Unsubscribe = () => {
  const [userUnsubscribed, setUserUnsubscribed] = useState(false);
  const [changeDone, setChangeDone] = useState(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const email = query.get('email');
  const timestamp = query.get('timestamp');
  const hash = query.get('hash');

  const navigate = useNavigate();
  const unsubscribeUserMutation = useMutation((data) => unsubscribeUser(navigate, data));
  const isUserUnsubscribedMutation = useMutation((data) => isUserUnsubscribed(navigate, data));
  const subscribeUserMutation = useMutation((data) => subscribeUser(navigate, data));

  const checkIsUserUnsubscribed = async (email) => {
    const { unsubscribed } = await isUserUnsubscribedMutation.mutateAsync({ email });
    if (unsubscribed) {
      setUserUnsubscribed(true);
    }
  };

  useEffect(() => {
    checkIsUserUnsubscribed(email);
  }, []);

  const handleUnsubscribe = async () => {
    const result = await unsubscribeUserMutation.mutateAsync({
      email,
      timestamp,
      hash
    });
    if (result.code === 0) {
      setChangeDone(true);
    } else {
      showAlert({
        message: 'Something went wrong, please try again later.',
        color: 'danger'
      });
    }
  };

  const handleSubscribe = async () => {
    const result = await subscribeUserMutation.mutateAsync({
      email,
      timestamp,
      hash
    });
    if (result.code === 0) {
      setChangeDone(true);
    } else {
      showAlert({
        message: 'Something went wrong, please try again later.',
        color: 'danger'
      });
    }
  };

  return !userUnsubscribed ? (
    <div className="flex h-screen flex-col items-center justify-center px-4 text-center">
      <Icon icon="unsubscribe" className="mb-4" />
      {!changeDone ? (
        <>
          <h1 className="text-lg font-semibold leading-tight text-primary-900 md:text-xl">
            Are you sure you want to unsubscribe?
          </h1>
          <p className="text-sm text-neutral-600 md:text-base">
            You will not be able to receive any emails from us.
          </p>
          <Button
            text="Unsubscribe"
            outlined
            transparent
            size="small"
            className="mt-4 px-4 py-2"
            onClick={handleUnsubscribe}
          />
        </>
      ) : (
        <h1 className="text-lg font-semibold leading-tight text-primary-900 md:text-xl">
          You have successfully unsubscribed from our emails.
        </h1>
      )}
    </div>
  ) : (
    <div className="flex h-screen flex-col items-center justify-center px-4 text-center">
      <Icon icon="unsubscribe" className="mb-4" />
      {!changeDone ? (
        <>
          <h1 className="text-lg font-semibold leading-tight text-primary-900 md:text-xl">
            You have already unsubscribed from our emails.
          </h1>
          <p className="text-sm text-neutral-600 md:text-base">
            Would you like to subscribe again?
          </p>
          <Button
            text="Subscribe"
            primary
            size="small"
            className="mt-4 px-4 py-2"
            onClick={handleSubscribe}
          />
        </>
      ) : (
        <h1 className="text-lg font-semibold leading-tight text-primary-900 md:text-xl">
          You have successfully subscribed to our emails.
        </h1>
      )}
    </div>
  );
};

export default Unsubscribe;
