import React, { useEffect, useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import PastMedicalHistory from 'components/shared/Forms/Custom/PastMedicalHistory/PastMedicalHistory';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import Axios from 'configuredAxios';
import { isEmpty, stripHtmlTags } from 'lib/helpers/utility';

const formatMedHistory = (data) => {
  return data?.map((item) => {
    var items = [];
    if (
      item?.response?.noDetails ||
      (item?.response?.chronic?.illness == 'no' &&
        item?.response?.surgeries?.surgery == 'no' &&
        item?.response?.allergies?.allergy == 'no' &&
        item?.response?.hospitalization?.illnessOrInjury == 'no')
    ) {
      items = [
        {
          title: 'No notable Medical History'
        }
      ];
    } else {
      if (item?.response?.chronic?.illness == 'yes') {
        items.push({
          title: 'Chronic Illness',
          note: item?.response?.chronic?.note
        });
      }
      if (item?.response?.surgeries?.surgery == 'yes') {
        items.push({
          title: 'Surgery',
          note: item?.response?.surgeries?.note
        });
      }
      if (item?.response?.allergies?.allergy == 'yes') {
        items.push({
          title: 'Allergies',
          note: item?.response?.allergies?.note
        });
      }

      if (item?.response?.hospitalization?.illnessOrInjury == 'yes') {
        items.push({
          title: 'Hospitalization',
          note: item?.response?.hospitalization?.note
        });
      }
    }

    return {
      createdAt: item.createdAt,
      createdBy: item.createdBy,
      list: items,
      original: item
    };
  });
};

const MedicalHistoryItem = ({ item, openItemModal }) => {
  return (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-[12px] hover:bg-primary-25">
      <List
        items={item?.list}
        dividerType="none"
        renderItemComponent={(listItem, index) => (
          <div className="flex flex-col gap-[4px]">
            <div className="flex items-center justify-between gap-2">
              <p className="font-medium text-neutral-800">{listItem?.title}</p>
              {index == 0 && <Icon className="cursor-pointe" icon="new-chevron-right"></Icon>}
            </div>

            {!isEmpty(stripHtmlTags(listItem?.note)) && (
              <p className="line-clamp-2 text-neutral-600">{stripHtmlTags(listItem.note)}</p>
            )}
          </div>
        )}
      />

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function MedicalHistory(props) {
  const { customData, layout, changedSizes, patientId } = props;
  const medicalHistory =
    customData?.medicalHistory?.length > 0 ? formatMedHistory(customData?.medicalHistory) : null;
  const defaultSize = useMemo(() => layout.find((l) => l.i === props.item.i)?.h, [layout]);

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () => limitItems(medicalHistory, defaultSize, { note: 0.15 }),
    [medicalHistory, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [itemModal, setItemModal] = useState(null);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    setItemModal(item?.original);
    setCurrentFormData(item?.original?.response);
  };

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId: itemModal?.id,
        patientId,
        response: currentFormData,
        type: 'medicalHistory'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ title: 'Successfully saved' });
        setItemModal();
        setCurrentFormData();
        queryClient.invalidateQueries(['customFormResponse']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto" data-dd-privacy="mask">
      <WidgetHeader
        onPlusClick={() => setItemModal({})}
        icon="activity-2"
        addPermissions="patients_medical_history.create"
        {...props}></WidgetHeader>

      <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
        <FooterWrapper
          onClick={() => setShow(true)}
          hasMore={hasMore}
          text={`Show all Medical history (${medicalHistory?.length})`}>
          <Allowed
            requiredPermissions="patients_medical_history.read"
            showMessage={true}
            showIllustration={true}
            messageClassName="font-medium text-neutral-500">
            {items ? (
              <List
                items={items}
                renderItemComponent={(item, index) => (
                  <MedicalHistoryItem openItemModal={openItemModal} item={item} />
                )}
              />
            ) : (
              <div className="p-[20px] py-3 text-center">
                <p className="font-medium text-neutral-500">No Medical History</p>
              </div>
            )}
          </Allowed>
        </FooterWrapper>
      </div>

      <Modal
        handleOpen={show}
        title="Medical History"
        slideFromRight
        className="w-[1020px] bg-white"
        handleClose={() => setShow(false)}>
        {medicalHistory ? (
          <List
            items={medicalHistory}
            renderItemComponent={(item, index) => (
              <MedicalHistoryItem openItemModal={openItemModal} item={item} />
            )}
          />
        ) : (
          <div className="p-[20px] py-3 text-center">
            <p className="font-medium text-neutral-500">No Medicccal History</p>
          </div>
        )}
      </Modal>

      <Modal
        className="w-[1020px]"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              data-qa="close-btn"
              text="Close"
              transparent
              outlined
              onClick={() => {
                setItemModal();
              }}
            />
            <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
          </div>
        }
        handleOpen={!!itemModal}
        title="Medical History"
        slideFromRight
        handleClose={() => setItemModal()}>
        <PastMedicalHistory
          setItemModal={setItemModal}
          defaultValue={itemModal?.response}
          formId={itemModal?.id}
          setCurrentFormData={setCurrentFormData}
        />
      </Modal>
    </div>
  );
}
