import React from 'react';
import cs from 'classnames';

export default function Row({ title, children, last, className }) {
  return (
    <div className={cs(last ? 'pt-1' : 'py-1', className)}>
      {title && (
        <p className="mb-[1rem] flex w-full justify-center text-center text-primary-900">{title}</p>
      )}
      {children}
    </div>
  );
}
