import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPatientHistory } from 'api/ClinicalNote';

export const usePatientHistory = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['patient_history', ...dependencies], () => getPatientHistory(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
