import { Popover } from '@headlessui/react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import React, { useState } from 'react';
import { generatePDF } from '../lib/generatePDF';

const PrintPopover = ({ address, claim }) => {
  const [cms1500Popover, setCms1500Popover] = useState(false);
  const [onlyTextPopover, setOnlyTextPopover] = useState(false);

  return (
    <Popover className="relative flex justify-end">
      {({ open, close }) => (
        <>
          <Popover.Button
            data-qa="claim-pdf-type"
            as={Button}
            icon="new-printer-bulk"
            type="link"
            text="Print"
            color={open ? 'primary' : ''}
            size="small"
          />
          <Popover.Panel className="absolute right-0 top-full z-10 mt-[10px] w-max min-w-[120px] rounded-[4px] bg-white !py-2 shadow-medium">
            <div
              data-qa="cms1500-print-btn"
              className="relative flex cursor-default items-center justify-between gap-1 !px-2 py-[6px] transition-all hover:bg-primary-50"
              onMouseEnter={() => {
                setCms1500Popover(true);
              }}
              onMouseLeave={() => {
                setCms1500Popover(false);
              }}>
              <span className="text-sm text-primary-900">CMS 1500</span>
              <Icon icon="new-chevron-right" />
              {cms1500Popover && (
                <div className="absolute -top-3 right-[96%] z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                  <div
                    data-qa="load-from-appointments-templates"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCms1500Popover(false);
                      generatePDF({ claim, address });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      With payer name and address
                    </span>
                  </div>
                  <div
                    data-qa="create-new-appointment-template"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCms1500Popover(false);
                      generatePDF({ claim, address, withPayerNameAddress: false });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      Without payer name and address
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div
              data-qa="onlytext-print-btn"
              className="relative flex cursor-default items-center justify-between gap-1 !px-2 py-[6px] transition-all hover:bg-primary-50"
              onMouseEnter={() => {
                setOnlyTextPopover(true);
              }}
              onMouseLeave={() => {
                setOnlyTextPopover(false);
              }}>
              <span className="text-sm text-primary-900">Only text</span>
              <Icon icon="new-chevron-right" />
              {onlyTextPopover && (
                <div className="absolute -top-3 right-[96%] z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                  <div
                    data-qa="load-from-appointments-templates"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOnlyTextPopover(false);
                      generatePDF({ claim, address, withBackground: false });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      With payer name and address
                    </span>
                  </div>
                  <div
                    data-qa="create-new-appointment-template"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOnlyTextPopover(false);
                      generatePDF({
                        claim,
                        address,
                        withBackground: false,
                        withPayerNameAddress: false
                      });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      Without payer name and address
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default PrintPopover;
