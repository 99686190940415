import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { interimApi } from 'api/InterimApi';

import { iaRa } from 'lib/helpers/utility';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import Filters from 'components/practice/Dashboard/widgets/UpcomingAppointments/Calendar/components/Navigation/components/Filters/Filters';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';

import ExportCalendar from '../Export/Export';
import PendingAppointments from '../PendingAppointments';
import { formatRange } from '../lib';

import './RBCToolbar.scss';

const VIEWS = ['day', 'week', 'month', 'agenda', 'provider', 'resource'];
const NewRbcToolbar = (toolbar) => {
  const { currentView, handleNewAppointment, rangeDate, pendingCount, getDates, defaultDate } =
    toolbar;
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [showPendingAppointment, setShowPendingAppointment] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [loadingPending, setLoadingPending] = useState(false);
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  const handlePendingAppointment = () => {
    blockScroll();
    getPendingAppointments();
    setShowPendingAppointment(true);
  };

  const hidePendingAppointment = () => {
    allowScroll();
    setShowPendingAppointment(false);
  };

  const goToBack = () => {
    const date = moment.tz(new Date(defaultDate), practice.timezone).toDate();
    switch (currentView) {
      case 'week':
        date.setDate(date.getDate() - 7);
        break;
      case 'month':
        date.setMonth(date.getMonth() - 1);
        break;
      default:
        date.setDate(date.getDate() - 1);
        break;
    }

    getDates(date, currentView);
  };

  const goToNext = () => {
    const date = moment.tz(new Date(defaultDate), practice.timezone).toDate();
    switch (currentView) {
      case 'week':
        date.setDate(date.getDate() + 7);
        break;
      case 'month':
        date.setMonth(date.getMonth() + 1);
        break;
      default:
        date.setDate(date.getDate() + 1);
        break;
    }
    getDates(date, currentView);
  };

  const goToCurrent = () => {
    const date = moment.tz(new Date(), practice.timezone).toDate();

    getDates(date, currentView);
  };

  const getPendingAppointments = async () => {
    try {
      setLoadingPending(true);

      const params = {
        appointmentStatus: ['pending'],
        withal: { patient: true, practice: true, practitioner: true, services: true }
      };

      const { data } = await interimApi('/api/appointment/get_multiple', params, navigate);

      if (data?.appointment) setPendingAppointments(data?.appointment);

      setLoadingPending(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };
  return (
    <div className="RBCToolbar">
      <Allowed requiredPermissions={['appointments.create', 'practice_events.create']} isAny={true}>
        <Button
          onClick={handleNewAppointment}
          text="New appointment"
          type="secondary"
          data-qa="new-appointment-btn"
        />
      </Allowed>
      <div className="flex items-center gap-x-5" data-dd-privacy="allow">
        <div className="flex items-center gap-x-2" data-dd-privacy="allow">
          <div
            className="h-6 w-6 bg-primary-50 rounded flex items-center justify-center cursor-pointer"
            onClick={goToBack}>
            <Icon icon="chevron-left" color="primary" onClick={goToNext} />
          </div>
          <Button
            onClick={goToCurrent}
            text="Today"
            size="small"
            data-qa="calendar-current-btn"
            color="neutral"
            outlined={true}
            textClassName="!text-primary-800 select-none"
          />
          <div
            className="h-6 w-6 bg-primary-50 rounded flex items-center justify-center cursor-pointer"
            onClick={goToNext}>
            <Icon icon="chevron-right" color="primary" onClick={goToNext} />
          </div>
        </div>
        <span className="text-sm font-500 select-none min-w-[130px]">
          {formatRange(rangeDate, currentView)}
        </span>
      </div>

      <div className="flex gap-x-2" data-dd-privacy="allow">
        <Allowed requiredPermissions="appointments.read">
          <Button
            data-qa="pending-btn"
            color="warning"
            type="secondary"
            onClick={handlePendingAppointment}
            text={`${pendingCount} Pending`}
            size="small"
          />
          <Filters buttonClassNames="outline outline-1 outline-neutral-100" />
        </Allowed>
        <ExportCalendar rangeDate={rangeDate} />
      </div>

      <div className="flex gap-x-2">
        {iaRa(VIEWS).map((name) => {
          const isSelected = currentView === name;
          return (
            <Button
              key={name}
              data-qa={`calendar-view-${name}`}
              color={isSelected ? 'primary' : 'neutral'}
              outlined={!isSelected}
              textClassName={isSelected ? 'text-white' : '!text-primary-800'}
              onClick={() => {
                getDates(defaultDate, name);
                // getDates(moment.tz(defaultDate, practice.timezone), name);
              }}
              text={name}
              size="small"
            />
          );
        })}
      </div>
      <PendingAppointments
        view={currentView}
        practice={practice}
        loadingPending={loadingPending}
        pendingAppointments={pendingAppointments}
        setPendingAppointments={setPendingAppointments}
        showPendingAppointments={showPendingAppointment}
        hidePendingAppointments={hidePendingAppointment}
      />
    </div>
  );
};

export default NewRbcToolbar;
