import axios from 'axios';

const Axios = axios.create({
  baseURL: 'https://localhost:9790/rest/command'
});

const Request = (data) => {
  return Axios.post('', data, { headers: { 'Content-Type': 'application/json' } });
};

export default Request;
