import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useTerminals } from 'lib/hooks/queries/terminals/useTerminals';

import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';

import TerminalModal from './components/TerminalModal';
import { DEFAULT_COLUMNS, DEFAULT_FILTERS, GRID_OPTIONS } from './lib/configs';

const TerminalManagement = () => {
  const { id } = useParams();

  return (
    <TableContextProvider cols={DEFAULT_COLUMNS} pagination name="terminals-data">
      <TerminalManagementContent practiceId={id} />
    </TableContextProvider>
  );
};

const TerminalManagementContent = ({ practiceId }) => {
  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [terminalData, setTerminalData] = useState(null);
  const [terminalType, setTerminalType] = useState('new');

  const {
    data = {},
    isLoading,
    isFetching
  } = useTerminals({
    params: { page, sort, limit, withCount: true, filters, practiceId },
    dependencies: [page, sort, limit, filters],
    page,
    sort
  });

  const defaultFilters = useMemo(() => DEFAULT_FILTERS(practiceId), [practiceId]);

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: data?.terminalData,
            count: data?.count,
            page,
            limit,
            pagination: true
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: data?.terminalData,
            count: data?.count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [data?.terminalData, data?.count, page, limit, onPageChange]);

  useEffect(() => {
    setFilters(defaultFilters);
  }, []);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const editTerminal = (e) => {
    setTerminalData(e?.data);
    setTerminalType('edit');
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setTerminalData(null);
    setTerminalType('new');
  };

  return (
    <>
      <Header title="Terminal Management" childrenClassName="w-full">
        <div className="flex items-center gap-2 justify-end flex-1">
          <DisplayButton />
          <Filter
            height={500}
            maxHeight="60%"
            category="terminals_admin"
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
          />
          <Button
            text="New Terminal"
            icon="add-square"
            size="small"
            transparent
            iconColor="white"
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </div>
      </Header>
      <div className="ag-theme-quartz !mb-0  h-full">
        <AGTable
          defaultColDef={DEFAULT_COLUMNS}
          gridOptions={GRID_OPTIONS}
          defaultFilters={defaultFilters}
          animateRows={true}
          data={data?.terminalData}
          suppressRowClickSelection={true}
          onRowClicked={editTerminal}
          customClassName="ag-grid-interactive"
          enableRangeSelection={true}
          expanded
          masterDetail={true}
          loading={isLoading || isFetching}
          statusBar={statusBar}
        />
      </div>
      <TerminalModal
        type={terminalType}
        isOpen={modalOpen}
        item={terminalData}
        handleClose={closeModal}
      />
    </>
  );
};

export default TerminalManagement;
