import { useImperativeHandle } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { pm } from 'lib/helpers/utility';

import EnhancementStatus from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/EnhancementStatus';
import Textarea from 'components/shared/Textarea/Textarea';
import state, { clinicalNote as clinicalNoteState } from 'components/state';

const Subjective = ({ subjectiveRef, sectionRef = null, showDisclaimer = true }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const permissions = useRecoilValue(state.permissions);
  const isPermission = pm(
    permissions,
    ['myscribe.narrative_update', 'myscribe.narrative_read'],
    'or'
  );

  useImperativeHandle(sectionRef, () => ({
    formData: { subjective: clinicalNote?.subjective }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;
    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write subjective notes here"
      id="subjective"
      data-qa="subjective"
      name="subjective"
      className="min-h-[200px]"
      value={clinicalNote?.subjective}
      onChange={(e) => handleChange(e, 'subjective')}
      transcribing
      forwardedRef={subjectiveRef}
      disabled={clinicalNote?.locked}
      info={
        isPermission && showDisclaimer && 'AI can make mistakes, please confirm the information!'
      }
      infoHighlights={['AI', 'mistakes']}
    />
  );
};

export default Subjective;
