module.exports = {
  claims: [
    { name: 'accepted', value: 'Accepted' },
    { name: 'rejected', value: 'Rejected' },
    { name: 'paid', value: 'Paid' }
  ],
  eras: [
    { name: 'finalized', value: 'Finalized' },
    { name: 'pending', value: 'Pending' }
  ],
  superbills: [
    { name: 'complete', value: 'Complete' },
    { name: 'draft', value: 'Draft' }
  ],
  invoices: [
    { name: 'paid', value: 'Paid' },
    { name: 'unpaid', value: 'Unpaid' }
  ]
};
