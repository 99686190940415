import { mString } from 'lib/helpers/utility';

import { MAX_PERCENT_VALUE } from './constants';

const baseErrorMessage = 'Please enter a valid positive amount';

const formatValue = (value, symbol) => (symbol === '%' ? `${value / 100}%` : mString(value));

export const getErrorMessage = (minValue, maxValue, symbol, validationMessages = {}) => {
  const isPercent = symbol === '%';
  const maxValueFormatted = isPercent ? MAX_PERCENT_VALUE : maxValue;
  const formattedMax = formatValue(maxValueFormatted, symbol);
  const formattedMin = formatValue(minValue, symbol);

  if (minValue !== undefined && maxValueFormatted !== undefined) {
    return (
      validationMessages?.between?.(formattedMin, formattedMax) ||
      `${baseErrorMessage} between ${formattedMin} and ${formattedMax}.`
    );
  }

  if (minValue !== undefined) {
    return (
      validationMessages?.min?.(formattedMin) ||
      `${baseErrorMessage} greater than or equal to ${formattedMin}.`
    );
  }
  if (maxValueFormatted !== undefined) {
    return (
      validationMessages?.max?.(formattedMax) ||
      `${baseErrorMessage} less than or equal to ${formattedMax}.`
    );
  }
  return validationMessages?.default || baseErrorMessage;
};
