import { useEffect, useState } from 'react';

import { iaRa } from 'lib/helpers/utility';
import { useLabOrder } from 'lib/hooks/queries/labOrder/useLabOrder';
import { useNonERXPrescription } from 'lib/hooks/queries/nonERXPrescription/useNonERXPrescription';
import { useReferralOrder } from 'lib/hooks/queries/referralOrder/useReferralOrder';

const useOrder = ({ type, id }) => {
  const [activeOrder, setActiveOrder] = useState(null);

  const {
    data: labOrder,
    isFetching: labOrderIsFetching,
    isLoading: labOrderIsLoading
  } = useLabOrder({
    dependencies: [id],
    params: {
      labOrderIds: [id],
      withRelations: {
        tests: true,
        insurances: true,
        results: true,
        patient: true
      }
    },
    options: {
      enabled: !!((type === 'lab_order' || type === 'elab_order') && id),
      select: (data) => iaRa(data?.data?.labOrder)?.[0]
    }
  });

  const {
    data: nonERXPrescription,
    isFetching: nonERXPrescriptionIsFetching,
    isLoading: nonERXPrescriptionIsLoading
  } = useNonERXPrescription({
    dependencies: [id],
    params: { nonERXPrescriptionIds: [id], withRelations: { provider: true, patient: true } },
    options: {
      enabled: !!(type === 'non_erx_prescription' && id),
      select: (data) => iaRa(data?.data?.nonERXPrescription)?.[0]
    }
  });

  const {
    data: referralOrder,
    isFetching: referralOrderIsFetching,
    isLoading: referralOrderIsLoading
  } = useReferralOrder({
    dependencies: [id],
    params: {
      referralOrderIds: [id],
      withRelations: {
        patient: true
      }
    },
    options: {
      enabled: !!(type === 'referral_order' && id),
      select: (data) => iaRa(data?.data?.referrals)?.[0]
    }
  });

  const labOrderLoading = labOrderIsFetching || labOrderIsLoading;
  const referralOrderLoading = referralOrderIsFetching || referralOrderIsLoading;
  const nonERXPrescriptionLoading = nonERXPrescriptionIsFetching || nonERXPrescriptionIsLoading;

  let loading = false;

  switch (type) {
    case 'lab_order':
    case 'elab_order':
      loading = labOrderLoading;
      break;
    case 'non_erx_prescription':
      loading = nonERXPrescriptionLoading;
      break;
    case 'referral_order':
      loading = referralOrderLoading;
      break;
    default:
      break;
  }

  useEffect(() => {
    if (!loading) {
      switch (type) {
        case 'lab_order':
        case 'elab_order':
          labOrder &&
            setActiveOrder({
              labOrder,
              labOrderTests: labOrder.tests,
              labOrderInsurances: labOrder.insurances,
              labOrderResults: labOrder.labSoftOrderResults
            });
          break;
        case 'non_erx_prescription':
          nonERXPrescription && setActiveOrder(nonERXPrescription);
          break;
        case 'referral_order':
          referralOrder && setActiveOrder(referralOrder);
          break;
        default:
          setActiveOrder(null);
      }
    }
  }, [type, id, loading, labOrder, nonERXPrescription, referralOrder]);

  return { order: activeOrder, loading };
};

export default useOrder;
