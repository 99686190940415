import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPractitionersAdmin } from '../../../../api/Practitioner';

export const usePractitionersAdmin = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['practitionersAdmin', ...dependencies],
    () => getPractitionersAdmin(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
