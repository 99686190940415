import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { io } from 'lib/helpers/utility';
import { isEqual } from 'lodash';
import React, { useRef, useState } from 'react';
import Button from '../../../../shared/Buttons/Button';
import Modal from '../../../../shared/Modal/Modal';
import Vitals from './Vitals';
import { doEmptyVitalExist, initialVitals } from './lib/helpers';

const VitalsModal = ({
  showVitalsModal = false,
  hideVitalsModal = () => {},
  lastVitals = {},
  fromOverview = false,
  setLastVitals = () => {},
  isGrowthChartEnabled = false,
  setOverviewVitals = () => {},
  fieldToFocus = ''
}) => {
  const childComponentRef = useRef(null);

  const handleOnSave = () => {
    childComponentRef.current.saveVitals();
  };

  const handleOnReset = () => {
    childComponentRef.current.resetVitals();
  };
  const handleAssignToCn = () => {
    childComponentRef.current.handleAssignToCn();
  };
  const [assignToCn, setAssignToCn] = useState(false);
  const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);
  const [vitals, setVitals] = useState(io(lastVitals) ? lastVitals : initialVitals());

  const headButtonContent = (
    <div className="ml-auto flex items-center gap-x-4">
      <Button
        onClick={handleOnReset}
        text="Reset vitals"
        icon="new-reset"
        outlined
        data-qa="reset-vitals"
        className="mr-4"
        iconIsStroke
      />
    </div>
  );
  const handleUnsavedChanges = () => {
    if (isDisabeld()) {
      hideVitalsModal();
      return;
    }
    setUnsavedConfirmation(true);
  };
  const unSavedChangesCheck = () => {
    if (fromOverview) {
      handleUnsavedChanges();
    } else {
      hideVitalsModal();
    }
  };
  const isDisabeld = () => {
    if (
      isEqual(lastVitals?.vitals, vitals?.vitals) ||
      doEmptyVitalExist({ newVital: initialVitals()?.vitals[0], vitals: vitals?.vitals })
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Modal
        className="w-[1020px]"
        isOpen={showVitalsModal}
        handleClose={unSavedChangesCheck}
        title="Vitals"
        portalClassName="VitalsModal"
        slideFromRight
        headButton={!fromOverview ? headButtonContent : null}
        footer={
          <div className="flex w-full items-center justify-between">
            <Button onClick={unSavedChangesCheck} outlined text="Close" data-qa="close-btn" />
            {assignToCn ? (
              <Button onClick={handleAssignToCn} text="Assign" data-qa="assign-vitals-btn" />
            ) : (
              <Button
                onClick={handleOnSave}
                disabled={isDisabeld()}
                text="Save vitals"
                data-qa="save-vitals-btn"
              />
            )}
          </div>
        }>
        <Vitals
          ref={childComponentRef}
          vitals={vitals}
          setVitals={setVitals}
          setAssignToCn={setAssignToCn}
          assignToCn={assignToCn}
          lastVitals={lastVitals}
          hideVitalsModal={hideVitalsModal}
          fromOverview={fromOverview}
          setLastVitals={setLastVitals}
          isGrowthChartEnabled={isGrowthChartEnabled}
          setOverviewVitals={setOverviewVitals}
          fieldToFocus={fieldToFocus}
        />
      </Modal>
      {fromOverview && (
        <Confirm
          handleOpen={unsavedConfirmation}
          handleClose={() => setUnsavedConfirmation(false)}
          handleContinue={hideVitalsModal}
          title="Unsaved changes"
          message="You have unsaved changes. Do you want to close this modal?"
          primaryBtnTxt="Yes"
          secondaryBtnTxt="No"
          icon="new-calendar-red"
          variant="danger"
        />
      )}
    </>
  );
};

export default VitalsModal;
