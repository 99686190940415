import { iaRa } from 'lib/helpers/utility';

export const transformData = ({ data, formType = 'other', shouldMap = true, validation }) => {
  return iaRa(data)
    .flatMap((item) => {
      const rows = iaRa(item?.response?.[formType === 'allergies' ? 'allergies' : 'row']);

      if (!shouldMap) return rows;

      return rows
        .map((rowItem) => ({
          ...rowItem,
          cfr_id: item.id,
          createdBy: item.createdBy,
          createdAt: item?.createdAt
        }))
        .filter((item) => item?.[validation]?.label?.length > 0);
    })
    .filter(Boolean);
};

export const cleanAndSetHTML = (htmlContent) => {
  // Remove extra spaces between tags
  const cleanedHTML = htmlContent
    ?.replace(/>\s+</g, '><')
    // Preserve single spaces between words
    .replace(/\s+/g, ' ')
    // Remove spaces before closing tags
    .replace(/\s+>/g, '>')
    // Remove spaces after opening tags
    .replace(/<\s+/g, '<');

  return cleanedHTML;
};

export const isDataReadOnly = ({
  canUserUpdatePatientDocuments = false,
  data = null,
  noteLocked = false
}) => {
  const isDataExisting = Boolean(data?.id);
  const isPatientCreated = data?.createdBy?.isPatient;
  return noteLocked || (isDataExisting && !canUserUpdatePatientDocuments && isPatientCreated);
};

export const isMedicationReadOnly = ({
  canUserUpdatePatientDocuments = false,
  data = null,
  noteLocked = false
}) => {
  const isMedicationExisting = Boolean(data?.id);
  const isPatientCreated = data?.created_by === data?.patient_id;
  const isVeradigmMedication = Boolean(data?.veradigm_id);

  return (
    noteLocked ||
    (isMedicationExisting &&
      (isVeradigmMedication || isPatientCreated) &&
      !canUserUpdatePatientDocuments)
  );
};
