import NewAppointment from 'components/practice/appointment/NewAppointment/NewAppointment';
import { NewAppointmentContextProvider } from 'lib/context/Appointment/NewAppointmentContextProvider';
import { ia, reShapePractitioners, reShapeProcedures } from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useServices } from 'lib/hooks/queries/services/useServices';
import React from 'react';

const NewAppointmentModal = ({ showModal, setShowModal }) => {
  const { data: servicesData } = useServices({ options: { refetchOnMount: false } });
  const { data: practitionersData } = usePractitioners(null);

  return (
    ia(servicesData?.services) &&
    showModal && (
      <NewAppointmentContextProvider>
        <NewAppointment
          isOpen={showModal}
          hideNewAppointment={() => setShowModal(false)}
          services={reShapeProcedures(servicesData?.services)}
          practitioners={reShapePractitioners(practitionersData?.practitioners)}
        />
      </NewAppointmentContextProvider>
    )
  );
};

export default NewAppointmentModal;
