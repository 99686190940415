import React from 'react';
import cs from 'classnames';

const Box = ({ children, className, shadow = true, ...rest }) => {
  return (
    <div className={cs('!rounded-lg bg-white !p-4 ', shadow && 'shadow-sm', className)} {...rest}>
      {children}
    </div>
  );
};

export default Box;
