import React from 'react';

import NewItemCounter from './NewItemCounter';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { addNewItems, isProcedureChecked, procedureAvailability } from '../lib';

const RenderServices = ({
  procedures,
  invoiceId,
  packageId,
  newItems,
  setNewItems,
  values,
  additionalTimes
}) => {
  return procedures?.map?.((procedure, index) => {
    const availability = procedureAvailability({
      newItems,
      procedure,
      invoiceId,
      additionalTimes,
      hasId: !!values?.id,
      ...(packageId && { packageId }),
      selectedProcedures: values.procedures,
      oldProcedures: values?.invoice?.procedures
    });

    const isDisabled = availability === 0;
    const isChecked = isProcedureChecked(newItems, procedure, packageId, invoiceId);

    return (
      <div className="flex items-center justify-between !gap-2" key={procedure?.id + invoiceId}>
        <div className="flex items-center">
          <Checkbox
            isChecked={isChecked}
            data-qa={`checkbox-${index}`}
            disabled={!isChecked && isDisabled}
            handleClick={() =>
              addNewItems({
                newItems,
                setNewItems,
                newItem: {
                  ...procedure,
                  invoiceId,
                  isPackage: true,
                  ...(packageId && { packageId })
                }
              })
            }
          />
          <p className="text-sm text-neutral-900">{procedure?.name}</p>
        </div>

        <div className="flex items-center !gap-2">
          <NewItemCounter
            newItem={procedure}
            newItems={newItems}
            packageId={packageId}
            invoiceId={invoiceId}
            setNewItems={setNewItems}
            disabled={isDisabled}
          />
          <p className="w-[85px] select-none whitespace-nowrap text-end text-xs font-400 text-neutral-500">
            {`Available: ${availability}/${procedure?.quantity}`}
          </p>
        </div>
      </div>
    );
  });
};

export default RenderServices;
