import React, { useEffect, useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Switch from 'components/shared/Switch/Switch';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';

const ManagePermission = ({ permission, highlight, name }) => {
  const { title, description, actions } = permission;
  const [enabledAll, setEnabledAll] = useState(false);
  const { formik } = useNewRoleContext();

  const enableAll = () => {
    if (formik.values.permissions !== null) {
      Object.keys(actions).forEach((action) => {
        formik.setFieldValue(`permissions.${name}.${action}`, true);
      });
      setEnabledAll(true);
    }
  };

  const disableAll = () => {
    if (formik.values.permissions !== null) {
      Object.keys(actions).forEach((action) => {
        formik.setFieldValue(`permissions.${name}.${action}`, false);
      });
      setEnabledAll(false);
    }
  };

  useEffect(() => {
    if (formik.values.permissions?.[name]) {
      const initialValues = formik.values.permissions?.[name];
      const allActionsEnabled = Object.values(initialValues).every((value) => value);
      setEnabledAll(allActionsEnabled);
    }
  }, [formik.values.permissions?.[name]]);

  return (
    <div className="flex flex-col items-center justify-start gap-4 self-stretch rounded-md bg-white p-4">
      <div className="flex w-full justify-between bg-white">
        <div>
          <p className="text-sm font-semibold leading-snug text-neutral-800">
            <Highlight text={title} highlight={highlight} />
          </p>
          <p className="text-sm font-normal leading-snug text-neutral-700">
            <Highlight text={description} highlight={highlight} />
          </p>
        </div>
        <Button
          text={!enabledAll ? 'Enable all' : 'Disable all'}
          transparent
          onClick={() => {
            if (!enabledAll) {
              enableAll();
            } else {
              disableAll();
            }
          }}
        />
      </div>
      <div className="flex w-full flex-col gap-0.5">
        {Object.keys(actions).map((action, index) => (
          <div
            key={index}
            className={`inline-flex items-center justify-between px-4 py-2 ${formik.values.permissions?.[name]?.[action] ? '' : 'bg-neutral-50'}`}>
            <div>
              <p className="text-sm font-medium leading-snug text-primary-900">
                <Highlight text={actions[action].title} highlight={highlight} />
              </p>
              <p className="text-xs font-normal leading-tight text-neutral-500">
                <Highlight text={actions[action].description} highlight={highlight} />
              </p>
            </div>
            <div className="relative h-[18px] w-[30px] rounded-[15px] bg-neutral-100">
              <Switch
                checked={formik.values.permissions?.[name]?.[action]}
                onChange={(value) => {
                  formik.setFieldValue(`permissions.${name}.${action}`, value);
                }}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ManagePermission;
