import { requestApi } from 'api/Api';
import { formatDate } from './formatDate';
import { showAlert } from 'components/shared/Alert/Alert';

export const sendEmail = async ({
  id,
  navigate,
  onSuccess = () => {},
  onError = () => {},
  toAltEmail,
  customEmail = null
}) => {
  try {
    await requestApi({
      url: '/api/practice/billing/invoicing/email',
      params: {
        invoiceId: id,
        toAltEmail,
        customEmail
      },
      navigate,
      onSuccess,
      onError
    });
  } catch (error) {
    console.error(error);
  }
};
export const sendText = async ({
  id,
  navigate,
  onSuccess = () => {},
  onError = () => {},
  toAltPhone,
  customPhone = null
}) => {
  try {
    await requestApi({
      url: '/api/practice/billing/invoicing/text',
      params: {
        invoiceId: id,
        toAltPhone,
        customPhone
      },
      navigate,
      onSuccess,
      onError
    });
  } catch (error) {
    console.error(error);
  }
};

export const getInvoicesFromSelectedRows = (selectedRowData) => {
  const finalInvoices = selectedRowData?.reduce((acc, invoice) => {
    const { patient_id, dateofservice, last_applied } = invoice;

    if (!acc?.[patient_id]) {
      acc[patient_id] = {
        total_amount: 0,
        amount_paid: 0,
        balance: 0,
        discount: 0,
        procedures: [],
        packages: [],
        products: [],
        tax: 0,
        internal_invoice_ids: [],
        created_at: invoice?.created_at,
        dateofservice: invoice?.dateofservice,
        adjustment: 0
      };
    }

    const patientInvoice = acc[patient_id];

    patientInvoice.internal_invoice_ids.push(invoice.id);
    patientInvoice.id = invoice.id;
    patientInvoice.patient = invoice.patient;
    patientInvoice.total_amount += invoice.total_amount;
    patientInvoice.amount_paid += invoice.amount_paid;
    patientInvoice.balance += invoice.invoice_balance ?? invoice?.balance ?? 0;
    patientInvoice.tax += invoice?.tax;
    patientInvoice.adjustment += invoice?.adjustment;

    if (invoice.discount) patientInvoice.discount = invoice?.discount;

    invoice?.procedures?.length > 0 &&
      patientInvoice.procedures.push(
        ...invoice.procedures.map((procedure) => ({ ...procedure, dateofservice, last_applied }))
      );
    invoice?.products?.length > 0 &&
      patientInvoice.products.push(
        ...invoice.products.map((product) => ({ ...product, dateofservice }))
      );
    invoice?.packages?.length > 0 &&
      patientInvoice.packages.push(...invoice.packages.map((pkg) => ({ ...pkg, dateofservice })));
    patientInvoice.created_at = patientInvoice?.created_at || formatDate({ data: new Date() });
    patientInvoice.dateofservice =
      patientInvoice?.dateofservice || formatDate({ data: new Date() });

    return acc;
  }, {});

  return Object.values(finalInvoices);
};

export const onSendEmail = async ({
  ids = [],
  setButton = () => {},
  navigate,
  toAltEmail = false,
  isPopover = false,
  customEmail = null
}) => {
  let successEmailsCount = 0;
  if (!isPopover) {
    setButton((prev) => ({ ...prev, loading: true }));
  }
  const onSuccess = (res) => {
    if (res?.success) {
      ++successEmailsCount;
    }
    if (!isPopover) {
      setButton((prev) => ({ ...prev, label: 'Sent', loading: false }));
    }
  };
  const onError = (error) => {
    showAlert({
      title: 'Error',
      message: error,
      color: 'danger'
    });
    if (!isPopover) {
      setButton((prev) => ({ ...prev, loading: false }));
    }
  };
  const sendEmailPromises = ids.map((id) =>
    sendEmail({ id, navigate, onSuccess, onError, toAltEmail, customEmail })
  );
  await Promise.all(sendEmailPromises);
  if (successEmailsCount > 0) {
    showAlert({
      title: 'Success',
      message:
        successEmailsCount > 1
          ? `${successEmailsCount} Invoices have been sent successfully`
          : 'The invoice has been successfully sent',
      color: 'success'
    });
  }
};

export const onSendText = async ({
  ids = [],
  setButton = () => {},
  navigate,
  toAltPhone = false,
  isPopover = false,
  customPhone = null
}) => {
  let successMessagesCount = 0;
  if (!isPopover) {
    setButton((prev) => ({ ...prev, loading: true }));
  }

  const onSuccess = (res) => {
    if (res?.success) {
      ++successMessagesCount;
    }

    if (!isPopover) {
      setButton((prev) => ({ ...prev, label: 'Sent', loading: false }));
    }
  };
  const onError = (error) => {
    showAlert({
      title: 'Error',
      message: error,
      color: 'danger'
    });
    if (!isPopover) {
      setButton((prev) => ({ ...prev, loading: false }));
    }
  };

  const sendEmailPromises = ids.map((id) =>
    sendText({ id, navigate, onSuccess, onError, toAltPhone, customPhone })
  );
  await Promise.all(sendEmailPromises);

  if (successMessagesCount > 0) {
    showAlert({
      title: 'Success',
      message:
        successMessagesCount > 1
          ? `${successMessagesCount} Invoices have been sent successfully`
          : 'The invoice has been successfully sent',
      color: 'success'
    });
  }
};
