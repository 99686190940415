import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Checkbox from '../../../../../../../shared/Checkbox/Checkbox';
import { RiskFactors as initialValue } from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from '../../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from '../../../../../../../shared/Icon/Icon';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { userState } from '../../../../../../../state';

const RiskFactors = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote = () => {}, cnDisplaySettings } = useClinicalNoteContext();
  const [timer, setTimer] = useState(null);
  const otherNoteRef = useRef();
  const [riskFactors, setRiskFactors] = useState([]);

  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm = cnDisplaySettings?.sections?.progress?.riskFactors?.advancedForm;
  const user = useRecoilValue(userState);
  const textAreaRef = useRef();

  useEffect(() => {
    setRiskFactors(clinicalNote?.epn?.riskFactors || initialValue);
  }, [clinicalNote]);

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { riskFactors } }
  }));

  const syncRiskFactorsAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ riskFactorsObject: updatedObject });
    } else {
      setRiskFactors(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleChange = (e, key) => {
    const { name, value } = e.target;

    let updatedObject = {};

    if (name === 'none') {
      const updatedRiskFactors = { ...riskFactors };
      Object.keys(updatedRiskFactors).forEach((riskKey) => {
        updatedRiskFactors[riskKey] = false;
      });
      updatedRiskFactors[name] = !riskFactors[name];

      updatedObject = {
        ...updatedRiskFactors
      };
    } else {
      updatedObject = {
        ...riskFactors,
        none: false,
        [key ?? name]: key ? value : !riskFactors[name]
      };
    }

    syncRiskFactorsAndClinicalNotes(updatedObject);
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      epn: {
        ...prevState.epn,
        riskFactors: updatedObject
      }
    }));
  };

  const handleNarrative = ({
    riskFactorsObject = riskFactors,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...riskFactorsObject, narrative: event };

    setRiskFactors(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ riskFactorsObject = riskFactors, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: riskFactorsObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'risk factors'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      riskFactorsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <React.Fragment>
      {isAdvancedForm && (
        <div>
          <p
            className="!pb-3 text-sm font-500
         text-neutral-800">
            Risk factors: SI, HI, Other
          </p>
          <div className="grid grid-cols-[repeat(auto-fill,minmax(140px,1fr))] gap-x-10 gap-y-4 xl:flex">
            <Checkbox
              label="Minimal risk"
              name="minimalRisk"
              isChecked={riskFactors.minimalRisk}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="Low risk"
              name="lowRisk"
              isChecked={riskFactors.lowRisk}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="Moderate risk"
              name="moderateRisk"
              isChecked={riskFactors.moderateRisk}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="High risk"
              name="highRisk"
              isChecked={riskFactors.highRisk}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <Checkbox
              label="None"
              name="none"
              isChecked={riskFactors.none}
              onChange={(event) => handleChange(event)}
              disabled={clinicalNote?.locked}
            />
            <div className="grid gap-y-2">
              <Checkbox
                label="Other"
                name="other"
                isChecked={riskFactors.other}
                onChange={(event) => handleChange(event)}
                disabled={clinicalNote?.locked}
              />
              {riskFactors.other && (
                <Textarea
                  label="Additional note"
                  placeholder="Write other notes here"
                  value={riskFactors.otherNote}
                  transcribing
                  forwardedRef={otherNoteRef}
                  onChange={(event) => handleChange(event, 'otherNote')}
                />
              )}
            </div>
          </div>
        </div>
      )}

      <div className="dashed-top !mt-4 !pt-3">
        <NarrativeForm
          syncNarrative={syncNarrative}
          setSyncNarrative={setSyncNarrative}
          onChange={(event) => handleNarrative({ event, sync: false })}
          setCurrentForm={setRiskFactors}
          restData={{
            className: 'w-full',
            label: 'Risk Factors Narrative',
            placeholder: 'Add narrative here',
            id: 'Narrative-risk-factors',
            'data-qa': 'narrative-risk-factors',
            name: 'Narrative-risk-factors',
            value: riskFactors?.narrative,
            forwardedRef: textAreaRef,
            formName: 'riskFactors'
          }}
          narrativeOptions={isAdvancedForm ? narrativeOptions : []}
        />
      </div>
    </React.Fragment>
  );
};

export default RiskFactors;
