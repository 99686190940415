import React from 'react';
import UndefinedBenefitLevelItem from './UndefinedBenefitLevelItem';
import BenefitLevelItem from './BenefitLevelItem';
import { withErrorBoundary } from '../../../../../../shared/Error/Boundary';

const BenefitItem = ({ benefitArray, item, it }) => {
  return (
    <>
      {benefitArray.map((finalItem, index) => (
        <div className="mb-3" key={index}>
          {item?.[0] === 'undefined' ? (
            <UndefinedBenefitLevelItem finalItem={finalItem} it={it} />
          ) : (
            <BenefitLevelItem finalItem={finalItem} />
          )}
        </div>
      ))}
    </>
  );
};

export default withErrorBoundary(BenefitItem);
