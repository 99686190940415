import { getFullAddress } from 'lib/helpers/utility';

export default function transformPatientDataForSubmission(values, states) {
  const patientToBeUpdated = {
    patient: {
      id: values?.id,
      f_name: values?.f_name,
      m_name: values?.m_name,
      l_name: values?.l_name,
      p_name: values?.p_name,
      phone: values?.phone,
      alt_phone: values?.alt_phone,
      alt_phone_notify: values?.alt_phone_notify,
      email: values?.email,
      alt_email: values?.alt_email,
      alt_email_notify: values?.alt_email_notify,
      ssn: values?.ssn,
      dob: values?.dob,
      race: values?.race,
      gender: values?.gender,
      personal_id: values?.personal_id,
      occupation_id: values.occupation_id,
      marital_status: values?.marital_status,
      emergency_contact: values?.emergency_contact,
      secondary_emergency_contact: values?.secondary_emergency_contact
    },
    address: {
      ...values?.address,
      full_street_address: getFullAddress({ address: values?.address, states })
    },
    secondary_address: {
      ...values?.secondary_address,
      full_street_address: getFullAddress({ address: values?.secondary_address, states }) || ''
    },
    custom_id: values?.custom_id
  };

  return patientToBeUpdated;
}
