import React, { useState } from 'react';
import Switch from 'components/shared/Switch/Switch';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';

const StickyNotes = ({ practice, setPractice }) => {
  const [stickyNotificationLoading, setStickyNotificationLoading] = useState(false);

  const navigate = useNavigate();
  const updateStickyNotification = async (data) => {
    setStickyNotificationLoading(true);

    const new_display_settings = {
      ...practice.display_settings,
      sticky_notes: {
        alert: data
      }
    };

    const onSuccess = () => {
      showAlert({
        title: 'Successful',
        message: 'Sticky notes notification updated successfully',
        color: 'success'
      });
      setPractice((prev) => ({ ...prev, display_settings: new_display_settings }));
      setStickyNotificationLoading(false);
    };

    requestApi({
      url: '/api/practice/update',
      navigate,
      params: {
        practice_id: practice.id,
        fields: { display_settings: new_display_settings }
      },
      onSuccess
    });
  };
  return (
    <div className="w-full p-3">
      <label className='font-500'>Sticky Notes</label>
      <div className="w-full p-3 flex gap-2 items-center">
        <Switch
          disabled={stickyNotificationLoading}
          onChange={(e) => updateStickyNotification(e)}
          checked={practice?.display_settings?.sticky_notes?.alert}
        />
        <label>Enable/Disable Sticky Notes Notification</label>
      </div>
    </div>
  );
};

export default StickyNotes;
