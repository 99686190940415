import { ia } from 'lib/helpers/utility';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';
import { getMedications } from 'api/Medication';
import { socket } from 'api/Socket';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { intelligenceAiState } from 'components/state';

import { CurrentMedications as initialValue } from '../../CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../lib/customFormsHelper';
import medicationInitialValue from './medicationInitialValue';
import { createMedicationNarrative } from '../../lib/medicationNarrativeHelper';

const useMedications = ({
  defaultValue,
  setCurrentFormData,
  onChange,
  setConfirmationModal,
  setNoDetails,
  currentAppointmentId,
  currentPatientId,
  setSyncNarrative,
  syncNarrative
}) => {
  const { setCurrentHpOverviewData, advancedFormNarrativeRef } = useClinicalNoteContext() || {};
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const [currentMedications, setCurrentMedications] = useState(initialValue);

  const textAreaRef = useRef();

  const validateMedications = (medications) => {
    return {
      ...medications,
      medications: currentMedications?.medications?.filter((item) => item?.medication?.length > 0)
    };
  };

  useEffect(() => {
    let jsonData = defaultValue;
    if (typeof defaultValue == 'string') {
      jsonData = JSON.parse(defaultValue);
    }

    if ((jsonData && ia(jsonData?.medications)) || jsonData?.noDetails) {
      if (jsonData?.medications?.find((item) => item?.is_past)) {
        setCurrentMedications(jsonData);
      } else {
        setCurrentMedications({
          ...jsonData,
          medications: [
            ...jsonData.medications,
            {
              ...medicationInitialValue,
              is_past: true,
              id: self.crypto.randomUUID()
            }
          ]
        });
      }

      setNoDetails({
        current: jsonData?.noDetails === null ? null : !jsonData.noDetails,
        past: jsonData?.noDetails === null ? null : !jsonData.noDetails
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(currentMedications);
  }, [currentMedications]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setCurrentMedications;
    }
  }, []);

  useEffect(() => {
    if (
      currentMedications.narrative !== intelligenceAi.response &&
      intelligenceAi?.path?.includes?.('medicationHistory') &&
      intelligenceAi.response !== ''
    ) {
      setCurrentMedications((ps) => ({
        ...ps,
        narrative: intelligenceAi.response ?? ps?.narrative
      }));
    }
  }, [intelligenceAi]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'CurrentMedications', type: 'custom' } });
    }
  };

  const syncMedicationsAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ currentMedicationsObject: updatedObject });
    } else {
      setCurrentMedications(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleNoDetails = (noDetails) => {
    const updatedObject = {
      ...currentMedications,
      noDetails,
      medications: noDetails
        ? [
            medicationInitialValue,
            { ...medicationInitialValue, is_past: true, id: self.crypto.randomUUID() }
          ]
        : []
    };
    syncMedicationsAndClinicalNotes(updatedObject);
    setConfirmationModal(false);
  };

  const handleNarrative = ({
    currentMedicationsObject = currentMedications,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);
    const updatedObject = { ...currentMedicationsObject, narrative: event };

    setCurrentMedications(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const generateNarrative = ({
    currentMedicationsObject = currentMedications,
    scrollable = false
  } = {}) => {
    const formattedNarrative = createMedicationNarrative(
      validateMedications(currentMedicationsObject)?.medications
    );
    handleNarrative({
      currentMedicationsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  const handleRefetchMedications = async () => {
    const params = {
      appointmentId: currentAppointmentId || null,
      patientId: currentPatientId
    };
    const data = await getMedications(null, params);
    setCurrentHpOverviewData &&
      setCurrentHpOverviewData((prev) => ({ ...prev, medicationHistory: data.medicationHistory }));
  };

  useEffect(() => {
    socket.on('veradigm-prescribe', () => {
      handleRefetchMedications();
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  return {
    currentMedications,
    setCurrentMedications,
    generateNarrative,
    handleNoDetails,
    handleNarrative,
    textAreaRef,
    syncMedicationsAndClinicalNotes,
    validateMedications
  };
};

export default useMedications;
