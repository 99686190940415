import React, { useRef } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import Icon from '../../../../../../../shared/Icon/Icon';
import Textarea from './TextareaCustom/TextareaCustom';

import { formatSeconds } from '../lib/helpers';
import { iaRa, isEmpty } from '../../../../../../../../lib/helpers/utility';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const EditRecordedTranscript = ({ transcriptData, className = '' }) => {
  const { setTranscriptData } = useAmbientListeningContext();

  const transcriptDataId = transcriptData?.id ?? transcriptData?.temp_id;
  const data = transcriptData?.transcript_enhanced;

  const sentenceCheck = (sentences) => {
    if (isEmpty(sentences)) {
      return (
        <p className="inline-block text-sm font-500 text-danger-600">
          Something went wrong while recording the conversation.
        </p>
      );
    }

    return sentences;
  };

  const editSentence = ({ sentence, uniqueId }) => {
    if (!transcriptDataId || !uniqueId) return;

    setTranscriptData((prevState) =>
      prevState.map((data) => {
        if ((data?.id || data?.temp_id) == transcriptDataId) {
          return {
            ...data,
            transcript_enhanced: data.transcript_enhanced.map((sentenceObj) => {
              if (sentenceObj.unique_id == uniqueId) {
                return { ...sentenceObj, merged_sentences: sentence || 'N/A' };
              }

              return sentenceObj;
            })
          };
        }

        return data;
      })
    );
  };

  return (
    <div className={cs('justify-between !rounded-lg bg-primary-50 !p-4 ', className)}>
      {iaRa(data).map((row, i) => {
        const sentenceText = sentenceCheck(row?.merged_sentences);
        const formattedStart = formatSeconds({ seconds: row.start });
        const isLowConfidence = row?.custom_confidence <= 0.85;

        const sentenceRef = useRef();

        return (
          <div key={i} className="!mt-[10px] flex gap-1.5 first-of-type:!mt-0">
            <div className="whitespace-nowrap">
              <Tippy content={formattedStart} className="tippy-dark">
                <span className="!min-w-[86px] !rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[2px] text-center text-sm font-600 !text-primary-700">
                  {typeof row?.speaker === 'number' ? `Speaker ${row?.speaker}` : row?.speaker}
                </span>
              </Tippy>
            </div>

            <Textarea
              forwardedRef={sentenceRef}
              rightText={
                isLowConfidence && (
                  <div className="flex w-min">
                    <Tippy
                      content="Review Needed: This sentence has a low confidence score and may contain inaccuracies. Please check for errors."
                      className="tippy-dark w-[220px]"
                      placement="bottom">
                      <div>
                        <Icon icon="new-info" color="neutral" />
                      </div>
                    </Tippy>
                  </div>
                )
              }
              onChange={(e) =>
                editSentence({
                  sentence: e?.target?.value ?? null,
                  uniqueId: row?.unique_id
                })
              }
              className="w-full"
              value={sentenceText}
            />
          </div>
        );
      })}
    </div>
  );
};

export default EditRecordedTranscript;
