import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateUpdateAdminValidationSchema = () =>
  Yup.object().shape({
    first_name: Yup.string()
      .min(2, 'First name should be at least 2 characters')
      .required('First name is required.'),

    fax_number: Yup.string().min(10, 'Fax number is too short.'),

    phone_number: Yup.string().min(10, 'Phone number is too short.'),

    email: Yup.string().email('Invalid email')
  });

export default function useContactFormik(contactData = {}, onSubmit) {
  const formik = useFormik({
    initialValues: {
      first_name: contactData?.first_name || '',
      last_name: contactData?.last_name || '',
      email: contactData?.email || '',
      fax_number: contactData?.fax_number || '',
      phone_number: contactData?.phone_number || '',
      image: contactData?.image || ''
    },
    enableReinitialize: true,
    validationSchema: CreateUpdateAdminValidationSchema,
    onSubmit
  });

  return formik;
}
