import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const Arrows = ({ changeWeekHandle }) => {
  return (
    <>
      <Icon
        icon="new-chevron-left"
        className="absolute left-[6px] top-1/2 h-full -translate-y-1/2 select-none rounded-[4px] hover:bg-primary-100 sm:left-0 lg:left-1"
        onClick={() => changeWeekHandle('prev')}
        data-qa="calendar-prev-btn"
      />
      <Icon
        icon="new-chevron-right"
        className="absolute right-[6px] top-1/2 h-full -translate-y-1/2 select-none rounded-[4px] hover:bg-primary-100 sm:right-0 lg:right-1"
        color="neutral"
        stroke
        onClick={() => changeWeekHandle('next')}
        data-qa="calendar-next-btn"
      />
    </>
  );
};

export default Arrows;
