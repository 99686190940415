import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import Icon from 'components/shared/Icon/Icon';
import { userState as user_state } from 'components/state';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { costShareInfo } from 'components/practice/appointment/PreviewAppointment/lib/costShareInfo';

const SelfPayAllocation = ({ wrapperClassName }) => {
  const [confirmSelfPayEdit, setConfirmSelfPayEdit] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);

  const userState = useRecoilValue(user_state);
  const appointmentSettings = userState?.display_settings?.appointment || {};
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const showSelfPayConfirmation = appointmentSettings?.pricing?.showConfirmations?.selfPay;

  const { values, setFieldValue } = useFormikContext();
  const {
    invoice: { last_applied = {} },
    modifiedSelfPay
  } = values || {};

  const handleSelfPayConfirmation = () => {
    const newSettings = {
      ...appointmentSettings,
      pricing: {
        ...appointmentSettings.pricing,
        showConfirmations: {
          ...appointmentSettings.pricing.showConfirmation,
          selfPay: false
        }
      }
    };

    saveDisplaySettings({ ...userState.display_settings, appointment: newSettings });
  };

  return (
    <>
      <div className="flex items-center justify-between gap-2">
        <div className="flex gap-1">
          <span className="text-sm">Self Pay</span>
          {last_applied?.self_pay?.modified_value > 0 && (
            <Tippy
              interactive
              className="tippy-dark"
              content={costShareInfo(last_applied, 'self_pay')}>
              <div className="flex">
                <Icon icon="new-info-v2" />
              </div>
            </Tippy>
          )}
        </div>

        <CurrencyInput
          id="self-pay"
          className="text-right"
          onValueChange={() => {}}
          wrapperClassName={wrapperClassName}
          onBlur={(v) => {
            const value = +v;
            if (value !== last_applied?.self_pay?.value) {
              setFieldValue('modifiedSelfPay', value);
              if (showSelfPayConfirmation && !dontShowAgain) setConfirmSelfPayEdit(true);
            } else if (value === last_applied?.self_pay?.value && !dontShowAgain) {
              setFieldValue('modifiedSelfPay', undefined);
            }
          }}
          value={modifiedSelfPay ?? last_applied?.self_pay?.value ?? 0}
        />
      </div>

      {confirmSelfPayEdit && (
        <Confirm
          icon="new-edit"
          variant="primary"
          title="Confirm Allocation"
          handleOpen={confirmSelfPayEdit}
          handleClose={() => {
            setConfirmSelfPayEdit(false);
            setFieldValue('modifiedSelfPay', undefined);
          }}
          handleContinue={() => {
            setConfirmSelfPayEdit(false);
            if (dontShowAgain) handleSelfPayConfirmation();
          }}
          message={
            <>
              <p className="text-neutral-700">
                Adjusting the self-pay amount will evenly distribute the reduction across services
                or products. Manual adjustments can be made afterward if needed. Are you sure you
                want to proceed with this edit?
              </p>
              <Checkbox
                className="mt-4"
                checked={dontShowAgain}
                labelClassName="cursor-pointer "
                label="Don't show this message again"
                onChange={() => setDontShowAgain(!dontShowAgain)}
              />
            </>
          }
        />
      )}
    </>
  );
};

export default SelfPayAllocation;
