import { getProviders } from 'api/Member';
import { getPayers } from 'api/Payer';

import { formatDate } from 'lib/helpers/utility';

import { SUPERBILL_SWITCH_ITEMS } from 'components/practice/BeyondBilling/Superbill/lib/superbillTableColumns';

export default {
  date: {
    values: null,
    type: 'date-range',
    title: 'Date of Payment',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },

  superbill_id: {
    type: 'search',
    values: '',
    title: 'Superbill ID',
    placeholder: 'Search by superbill ID'
  },
  billing_provider_searchTerm: {
    type: 'search',
    values: '',
    title: 'Billing Provider',
    placeholder: 'Search by billing provider'
  },
  providers: {
    type: 'infinite-scroll',
    multiple: true,
    queryFn: getProviders,
    queryKey: 'practitioners',
    values: [],
    title: 'Provider',
    placeholder: 'Search provider',
    formatData: (data) =>
      data.map((practitioner) => ({
        label: `${practitioner?.f_name} ${practitioner?.l_name}`,
        value: practitioner?.id
      }))
  },
  payers: {
    type: 'infinite-scroll',
    queryFn: getPayers,
    queryKey: 'payers',
    multiple: true,
    values: [],
    title: 'Payer',
    placeholder: 'Search payer',
    formatData: (data) => data.map((payer) => ({ label: payer?.name, value: payer?.id }))
  },
  status: {
    title: 'Status',
    type: 'search',
    placeholder: 'Search by status',
    multiple: true,
    options: SUPERBILL_SWITCH_ITEMS.slice(1)
  }
};
