import React from 'react';
import SOAPFormsAI from '../../components/forms/SOAP/SOAPFormsAI';
import MacrosTable from './components/macros/MacrosTable';
import PastNotes from './components/pastNotes/PastNotesTable';
import PatientFormsTable from './components/patientForms/PatientFormsTable';
import PatientHistoryTable from './components/patientHistory/components/PatientHistoryTable';
import TreatmentPlanTable from './components/treatmentPlan/TreatmentPlanTable';
import Button from 'components/shared/Buttons/Button';
import TreatmentPlanHeader from './components/treatmentPlan/TreatmentPlanHeader';
import MacrosHeader from './components/macros/components/MacrosHeader';

export const componentButtons = [
  {
    id: 'vitals',
    text: 'Vitals',
    onClick: () => {},
    icon: 'heart',
    selected: false,
    forms: SOAPFormsAI,
    includedInHashtags: false
  },
  {
    id: 'cpt-codes',
    text: 'CPT® Codes',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false,
    forms: SOAPFormsAI,
    includedInHashtags: false
  },
  {
    id: 'diagnosis-codes',
    text: 'Diagnosis Codes',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false,
    forms: SOAPFormsAI,
    includedInHashtags: false
  },
  {
    id: 'macros',
    text: 'Macros',
    onClick: () => {},
    icon: 'new-note-2',
    content: ({ breadcrumb }) => <MacrosTable breadcrumb={breadcrumb} />,
    headerButton: ({ breadcrumb }) => <MacrosHeader breadcrumb={breadcrumb} />,
    selected: false,
    includedInHashtags: false
  },
  {
    id: 'treatment-plan',
    text: 'Treatment Plan',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false,
    content: ({ breadcrumb }) => <TreatmentPlanTable breadcrumb={breadcrumb} />,
    includedInHashtags: false,
    headerButton: ({ breadcrumb }) => <TreatmentPlanHeader breadcrumb={breadcrumb} />
  },
  {
    id: 'past_notes',
    text: 'Past Notes',
    onClick: () => {},
    icon: 'new-note-2',
    selected: false,
    content: ({ breadcrumb }) => <PastNotes breadcrumb={breadcrumb} />,
    includedInHashtags: false
  },
  {
    id: 'patient_forms',
    text: 'Patient Forms',
    onClick: () => {},
    icon: 'new-note-text',
    selected: false,
    content: ({ breadcrumb }) => <PatientFormsTable breadcrumb={breadcrumb} />,
    includedInHashtags: false
  },
  {
    id: 'patient_history',
    text: 'Patient History',
    onClick: () => {},
    icon: 'new-calendar-note',
    selected: false,
    content: ({ breadcrumb }) => <PatientHistoryTable breadcrumb={breadcrumb} />,
    includedInHashtags: false
  }
];

export const FADE_LIST = ['Preserve Your Time', 'Protect Your Data', 'Perpetuate Your Practice'];

export const popoverOptions = [{ label: '3D Chart', onClick: () => {}, icon: 'new-calendar-note' }];

export const prompts = [
  {
    id: 1,
    title: 'Wellness Checkup',
    icon: 'new-messages',
    description: 'Write a note for patient Adrian Samuel undergoing a routine wellness checkup.',
    active: true
  },
  {
    id: 2,
    title: 'Manage Diabetes Mellitus',
    icon: 'new-messages',
    description: 'Write a detailed note for Adrian Samuel managing diabetes mellitus.',
    active: false
  },
  {
    id: 3,
    title: 'Unexplained Weight Loss',
    icon: 'new-messages',
    description: 'Write a note for Adrian Samuel reporting unexplained weight loss.',
    active: false
  },
  {
    id: 4,
    title: 'Upper Respiratory Infection',
    icon: 'new-messages',
    description: 'Write a note for Adrian Samuel with symptoms of an upper respiratory infection.',
    active: false
  }
];

export const headerButtons = [
  { id: 1, component: <Button icon="new-notev1" text="New Note" outlined size="small" /> }
];
