export const getPopoverPosition = ({ device, isDetailsOpen, type }) => {
  const isMobileOrTablet = ['mobile', 'tablet-sm', 'tablet'].includes(device);

  if (isMobileOrTablet && isDetailsOpen) {
    return 'bottom-[calc(100%+10px)] !w-2/3';
  }

  if (isMobileOrTablet && type === 'products') {
    return 'top-[20px] !w-2/3 left-1/2 -translate-x-1/2';
  }

  if (isMobileOrTablet) {
    return '-bottom-[220px] left-[120px] !w-2/3';
  }

  if (type === 'procedures' && isDetailsOpen) {
    return 'bottom-[calc(100%+4px)]';
  }

  if (type === 'procedures') {
    return '-bottom-[250px] left-[120px]';
  }

  if (type === 'products' && isDetailsOpen) {
    return 'bottom-[50px]';
  }

  if (type === 'products') {
    return '-bottom-[170px] left-[320px]';
  }

  return '';
};
