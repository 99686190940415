import { useCallback, useMemo } from 'react';

import { useField } from 'formik';

import { ia, io } from 'lib/helpers/utility';

const useAOEQuestion = ({ question, testIndex, index }) => {
  const { isRequired: required, pickListSettings, validationRegex, answer } = question;

  const AOEType = !!question?.orderAOE ? 'orderAOEs' : 'testAOEs';
  const fieldName = `tests.${testIndex}.${AOEType}.${index}.answer`;

  const regex = useMemo(
    () => (validationRegex ? new RegExp(validationRegex) : null),
    [validationRegex]
  );

  const validate = useCallback(
    (value) => {
      if (!value && required) return 'Field is required';
      if (regex && !regex.test(value)) return 'Invalid input';

      const { minSelectLimit, maxSelectLimit } = pickListSettings || {};
      if (minSelectLimit) {
        const minimalLimitCheck = maxSelectLimit > 1 ? ia(value, minSelectLimit - 1) : io(value);
        if (!minimalLimitCheck) {
          return `Please select at least ${pickListSettings.minSelectLimit} options`;
        }
      }

      return undefined;
    },
    [regex, required, pickListSettings]
  );

  const [field, meta, { setValue, setTouched }] = useField({
    name: fieldName,
    validate,
    required,
    value: answer
  });

  const error = meta.touched && meta.error;

  return {
    field,
    error,
    setValue,
    setTouched
  };
};

export default useAOEQuestion;
