import React, { useEffect, useState } from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import { getCurrentWithoutHidden, getStepsLeft } from '../lib/helpers';
import Input from 'components/shared/Input/Input';
import { ia } from 'lib/helpers/utility';
import ItemList from './ItemList';
import cs from 'classnames';
import StepsChart from './StepsChart';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useNavigate } from 'react-router-dom';

const Forms = ({ currentBreadcrumb, formsList, formId }) => {
  const { breadCrumbs, setSearchTerm, setEnableSubmit, formik } = usePacketBuilderContext();
  const [selectedLength, setSelectedLength] = useState(formik.values?.form_ids?.length);
  const { device } = useUIContext();
  const navigate = useNavigate();
  const currentBreadcrumbStep = getCurrentWithoutHidden(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);

  useEffect(() => {
    if (selectedLength == 0) {
      setEnableSubmit(false);
    } else {
      setEnableSubmit(true);
    }
  }, [selectedLength]);

  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  const addNewForm = () => {
    navigate('/portal/settings/forms', {
      state: { openModal: true, packet_id: formik?.values?.id }
    });
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div>
        <StepsChart
          chartData={chartData}
          title="Choose Form(s)"
          description="All forms selected will be included in this form packet."
          innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
          showButton
          buttonText="Add New Form"
          handleClick={addNewForm}
        />
        <div className="px-9 pt-10">
          <div className="flex items-center justify-between ">
            <p className="text-sm font-500 text-primary-900">Add forms to your packet.</p>
            <div className="flex items-center gap-3">
              <p className={cs(selectedLength ? 'text-primary-900' : 'text-neutral-500')}>
                {selectedLength} selected
              </p>
              <Input
                placeholder="Search forms"
                icon="new-search"
                rounded="full"
                width={device === 'tablet-sm' ? '250px' : '280px'}
                onChange={(e) =>
                  setSearchTerm((prevState) => ({
                    ...prevState,
                    forms: e.target.value
                  }))
                }
              />
            </div>
          </div>
          <div className="mt-[21px]">
            {ia(formsList.forms) &&
              formsList?.forms?.map((form, index) => (
                <ItemList
                  key={index}
                  index={index}
                  item={form}
                  options="form_ids"
                  setSelectedLength={setSelectedLength}
                  formId={formId}
                />
              ))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(Forms);
