import { useQueryClient } from '@tanstack/react-query';
import { upsertCustomFormResponse } from 'api/CustomForms';
import ResizableContainer from 'components/practice/charts/ClinicalNote/shared/VerticallyResizableDiv/ResizableContainer';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import { clinicalNote as clinicalNoteState, permissions, userState } from 'components/state';
import { useFormik } from 'formik';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { FamilyHistory as initialValue } from '../../CustomFormInitialStates';
import FamilyHistoryRow from '../../FamilyHistory/FamilyHistoryRow';
import { transformData } from '../../lib/aggregatedDataHelper';
import AddToTodaysNote from '../components/AddToTodaysNote';
import CustomFormConfirm from '../components/CustomFormConfirm';
import FormsTable from '../components/FormsTable';
import NarrativeForm from '../components/NarrativeForm';
import NoteHeader from '../components/NoteHeader';
import UpsertModal from '../components/UpsertModal';
import { setPatientAggregateNarrativeState } from '../lib/patientAggregateNarrativeStates';
import { FINAL_NOTE_OPTIONS } from './lib/constants';
import providerFamilyHistoryTableCols from './lib/providerFamilyHistoryTableCols';

const ProviderFamilyHistory = ({ customFormsParams, options, isResizable = true }) => {
  const { setSelectedCustomFormTypes, overviewData, setOverviewData } = useClinicalNoteContext();
  const userPermissions = useRecoilValue(permissions);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { id: patientId, appointmentId } = useParams();

  const familyHistory = useMemo(() => {
    if (overviewData?.familyHistory) {
      return transformData({
        data: overviewData?.familyHistory,
        formType: 'familyHistory',
        validation: 'medicalCondition'
      });
    }
    return [];
  }, [overviewData?.familyHistory]);

  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [resize, setResize] = useState(250);
  const user = useRecoilValue(userState);
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: initialValue?.row?.[0],
    enableReinitialize: true,
    validationSchema: Yup.object({
      medicalCondition: Yup.object().required('Medical Condition is required')
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      const id = values?.cfr_id;
      delete values?.cfr_id;
      delete values?.createdBy;

      const result = await upsertCustomFormResponse(navigate, {
        formId: id,
        patientId: customFormsParams?.id || patientId,
        appointmentId: customFormsParams?.appointmentId || appointmentId,
        response: { row: [values] },
        type: 'familyHistory'
      });

      result.newResponse.createdBy = {
        id: user.user_id,
        fullName: `${user?.f_name ?? ''} ${user?.l_name ?? ''}`.trim(),
        isPatient: Number(customFormsParams?.id || patientId) === user.user_id
      };

      if (id) {
        setOverviewData((prev) => ({
          ...prev,
          familyHistory: prev?.familyHistory?.map((familyHistory) => {
            if (familyHistory?.id === id) {
              return result.newResponse;
            }
            return familyHistory;
          })
        }));
      } else {
        setOverviewData((prev) => ({
          ...prev,
          familyHistory: [result.newResponse, ...iaRa(prev?.familyHistory)]
        }));

        const noKnown = await queryClient.getQueryData([
          'patientAggregateNarrative',
          patientId,
          'familyHistory'
        ])?.data?.narrative?.no_known;

        if (patientId && noKnown) {
          await setPatientAggregateNarrativeState({
            queryClient,
            keys: [patientId, 'familyHistory'],
            payload: {
              synced: true,
              no_known: false
            }
          });
        }
      }
      setShowFormModal(false);
      setLoading(false);
      resetForm();
    }
  });

  const handleOnChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const hideModal = () => {
    formik.resetForm();
    setShowFormModal(false);
  };

  const onDeleteItem = async (customFormId) => {
    setOverviewData((prev) => {
      return {
        ...prev,
        familyHistory: prev?.familyHistory?.filter(
          (familyHistory) => familyHistory?.id !== customFormId
        )
      };
    });
  };

  return (
    <TableContextProvider
      cols={() =>
        providerFamilyHistoryTableCols({
          setItemToDelete,
          setShowFormModal,
          formik,
          userPermissions,
          noteLocked: clinicalNote?.locked
        })
      }
      name="Family History"
      defaultFilters={[]}>
      <div className="h-full">
        <AddToTodaysNote
          type="familyHistory"
          finalNoteOptions={FINAL_NOTE_OPTIONS}
          onChange={({ type, options }) =>
            setSelectedCustomFormTypes((prevState) => ({
              ...prevState,
              [type]: options
            }))
          }
        />

        <NoteHeader title="Family History">
          <DisplayButton />
          <Button
            text="Add Family History"
            icon="new-add-square"
            iconColor="white"
            disabled={clinicalNote?.locked}
            size="small"
            onClick={() => {
              setShowFormModal(true);
            }}
          />
        </NoteHeader>

        {showFormModal && (
          <UpsertModal
            showModal={showFormModal}
            title="Family History"
            loading={loading}
            formik={formik}
            hideModal={hideModal}
            onDeleteItem={onDeleteItem}>
            <FamilyHistoryRow formik={formik} onChange={handleOnChange} {...formik.values} />
          </UpsertModal>
        )}

        <ResizableContainer
          isResizable={isResizable}
          onResize={(height) => setResize(height)}
          topContent={<FormsTable items={familyHistory} getGridApi={(api) => setGridApi(api)} />}
          bottomContent={
            <NarrativeForm
              key={`family-history-narrative-${customFormsParams?.id || patientId}`}
              type="familyHistory"
              loading={loading}
              gridApi={gridApi}
              resize={resize}
              items={familyHistory}
              options={options}
            />
          }
          resizableDivClassNames={{
            contentClassName: '!mt-0 !pt-0'
          }}
        />
      </div>

      <CustomFormConfirm
        type="Vaccine"
        itemToDelete={itemToDelete}
        onDeleteItem={onDeleteItem}
        setItemToDelete={setItemToDelete}
      />
    </TableContextProvider>
  );
};

export default ProviderFamilyHistory;
