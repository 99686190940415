import React from 'react';
import PatientDoc from './components/PatientDoc';

const SelectedFiles = ({ tabState, setTabState }) => {
  return (
    <div className="flex flex-col gap-4">
      {tabState.selectedFiles.map((doc) => (
        <PatientDoc
          key={doc.id}
          {...{
            doc,
            tabState,
            setTabState,
            isChecked: tabState.selectedFiles.some((file) => file.id === doc.id)
          }}
        />
      ))}
    </div>
  );
};

export default SelectedFiles;
