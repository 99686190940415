import Checkbox from 'components/shared/Checkbox/Checkbox';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import ReorderWrapper from 'components/shared/Tags/components/Reorder/ReorderWrapper';
import { usePatientChartContext } from 'lib/context/PatientChartContext/PatientChartContext';
import React from 'react';

const Actions = ({
  showPinTagsModal = false,
  handleClosePinTagsModal = () => {},
  updateDisplaySettings = () => {},
  openReorderModal = false,
  setOpenReorderModal = () => {}
}) => {
  const { isPinnedConfirmation, setIsPinnedConfirmation } = usePatientChartContext();
  return (
    <>
      {showPinTagsModal && (
        <Confirm
          handleOpen={showPinTagsModal}
          primaryBtnTxt="Yes, pin tags"
          secondaryBtnTxt="No, not at this time"
          title="Are you sure you want to pin tags to all charts?"
          icon="new-pin-filled"
          iconSize={24}
          handleClose={handleClosePinTagsModal}
          handleContinue={updateDisplaySettings}>
          <div className="flex flex-col gap-3">
            <p className="text-sm text-neutral-700">
              This will give better visibility to tags on all patient charts.
            </p>
            <Checkbox
              isChecked={isPinnedConfirmation}
              handleClick={() => setIsPinnedConfirmation(!isPinnedConfirmation)}
              label="Don’t show this message again"
              labelClassName="text-sm !text-neutral-600"
              size={18}
            />
          </div>
        </Confirm>
      )}
      {openReorderModal && (
        <ReorderWrapper
          openReorderModal={openReorderModal}
          handleClose={() => setOpenReorderModal(false)}
        />
      )}
    </>
  );
};

export default Actions;
