import { useFormik } from 'formik';
import { showAlert } from 'components/shared/Alert/Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createExternalInvoices } from 'api/Billing';

const useCreateExternalInvoice = ({
  initialValues,
  navigate,
  setActiveView,
  setExternalInvoices
}) => {
  const queryClient = useQueryClient();

  const { mutate: createInvoices, isLoading: isCreateInvoicesLoading } = useMutation({
    mutationFn: (data) => createExternalInvoices(navigate, data)
  });

  const invoiceFormik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const finalInvoices = Object.keys(values).map((key) => {
        const finalObj = { ...initialValues[key], ...values[key] };
        return finalObj;
      });

      if (finalInvoices.some((invoice) => invoice?.amount_cents < 0)) {
        showAlert({
          title: 'Attention!',
          message:
            finalInvoices?.length > 1
              ? 'At least one invoice has a negative amount. Invoice amounts must be zero or higher. Please correct any negative values and try again.'
              : 'The invoice amount must be greater than or equal to zero. Please enter a valid amount and try again.',
          color: 'warning'
        });
        return;
      }

      createInvoices(finalInvoices, {
        onSuccess: (externalInvoiceData) => {
          const createdInvoices = externalInvoiceData.invoices;
          setExternalInvoices(createdInvoices || []);

          queryClient.invalidateQueries(['external_invoices']);
          showAlert({
            title: 'Success',
            message: 'Invoices have been created successfully',
            color: 'success'
          });
          setActiveView((prev) => ({ ...prev, mode: 'preview', title: 'Preview Invoice' }));
        },
        onError: () => {
          showAlert({
            title: 'Attention!',
            message: 'There was an error creating invoices!',
            color: 'danger'
          });
        }
      });
    }
  });

  return { invoiceFormik, isCreateInvoicesLoading };
};

export default useCreateExternalInvoice;
