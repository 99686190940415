import { useQuery } from '@tanstack/react-query';
import { getPatientLedger } from 'api/Ledger';
import { useNavigate } from 'react-router-dom';

export const usePatientLedger = ({ params = {}, options = {}, dependencies = [], name }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => getPatientLedger(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
