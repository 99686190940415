import React, { useState } from 'react';
import ContactsModal from '../../../../../../comms_center/Contacts/components/ContactsModal';
import NewReferringProvider from '../../../../../Demographics/ReferringProvider/NewReferringProvider';
import Input from '../../../../../../../shared/Input/Input';
import Icon from '../../../../../../../shared/Icon/Icon';
import Tippy from '@tippyjs/react';

import { isEmpty, optionify } from '../../../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../../../shared/Alert/Alert';
import Select from '../../../../../../../shared/Select/Select';
import { usePractitioners } from '../../../../../../../../lib/hooks/queries/practitioners/usePractitioners';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';

const TippyInfo = ({ icon = 'user-square', onClick = () => null, ...rest }) => {
  return (
    <Tippy placement="bottom" className="tippy-dark" {...rest}>
      <div>
        <Icon icon={icon} className="cursor-pointer" onClick={onClick} />
      </div>
    </Tippy>
  );
};

const fields = [
  { prefix: 'from', label: 'Phone', placeholder: '+1 5003 (400) 333', type: 'text' },
  { prefix: 'from', label: 'Email', placeholder: 'john@doe.com', type: 'email' },
  { prefix: 'from', label: 'Address', placeholder: 'Address', type: 'text' },
  { prefix: 'to', label: 'Phone', placeholder: '+1 5003 (400) 333', type: 'text' },
  { prefix: 'to', label: 'Email', placeholder: 'john@doe.com', type: 'email' },
  { prefix: 'to', label: 'Address', placeholder: 'Address', type: 'text' }
];

const referrerInfoDataMapper = (userData) => {
  const userAddress = userData?.address;
  const fields = ['address_1', 'city', 'state', 'country_code', 'postal_code'];

  const referrerFullAddress = fields
    .map((key) => {
      return !isEmpty(userAddress[key]) && userAddress[key];
    })
    .filter(Boolean)
    .join(', ');

  return {
    to_name: `${userData?.first_name} ${userData?.last_name}`,
    to_email: '',
    to_phone: userAddress?.telephone_number ?? '',
    to_address: referrerFullAddress ?? '',
    npi: userData?.number ?? ''
  };
};

const referrerFromContactInfoDataMapper = (userData) => {
  return {
    to_name: `${userData?.first_name} ${userData?.last_name}`,
    to_email: userData?.email ?? '',
    to_phone: userData?.fax_number ?? '',
    to_address: '',
    npi: ''
  };
};

const currentUserInfoDataMapper = (userData) => {
  return {
    from_name: `${userData?.f_name} ${userData?.l_name}`,
    from_phone: userData?.phone ?? '',
    from_email: userData?.email ?? '',
    from_address: userData?.userAddress?.fullAddress ?? '',
    provider_id: userData?.id || ''
  };
};

const renderInputs = ({ prefix, formik }) =>
  fields
    .filter((field) => field.prefix === prefix)
    .map((field, index) => {
      const fieldName = `${prefix}_${field?.label.toLowerCase()}`;
      const fieldLabel = `${field?.label}`;
      const fieldPlaceholder = field?.placeholder;
      const fieldType = field?.type;
      const fieldValue = formik?.values[fieldName];
      const fieldError = formik.touched[fieldName] && formik?.errors[fieldName];

      return (
        <div className="!mt-4" key={index}>
          <Input
            id={fieldName}
            name={fieldName}
            label={fieldLabel}
            placeholder={fieldPlaceholder}
            type={fieldType}
            value={fieldValue}
            error={fieldError}
            onChange={formik && formik?.handleChange}
          />
        </div>
      );
    });

const ContactInfo = ({ formik, fromProvider = false, toProvider = false }) => {
  const [showNewReferringProvider, setShowNewReferringProvider] = useState(false);
  const [contactsModalVisible, setContactsModalVisible] = useState(false);

  const { data, isFetching } = usePractitioners(
    { practitionerWithAddress: true },
    {
      select: ({ practitioners }) =>
        optionify(
          practitioners,
          (e) => `${e?.f_name} ${e?.l_name}`,
          'id',
          (e) => e
        )
    }
  );

  if (fromProvider && toProvider) {
    showAlert({
      color: 'warning',
      message: 'Either fromProvider or toProvider can be true, but not both at the same time.'
    });
    return null;
  }

  const populateReferrerFields = (info) => formik.setValues({ ...formik.values, ...info });

  const selectValueProps = {
    value: formik?.values?.id,
    label: formik?.values?.from_name,
    image: formik?.values?.profile_photo
  };

  return (
    <div className="flex w-[438px] flex-col rounded-xl bg-white px-4 py-3 shadow-sm">
      <div className="text-lg font-500 text-primary-900">{fromProvider ? 'From' : 'To'}</div>
      {fromProvider && (
        <div className="!mt-4 w-full">
          {isFetching ? (
            <Skeleton />
          ) : (
            <Select
              inputId="provider-select"
              name="provider"
              label="Provider"
              placeholder="Type and select provider"
              isImage
              isClearable={false}
              options={data}
              value={selectValueProps}
              onChange={(e) =>
                formik.setValues({ ...formik?.values, ...currentUserInfoDataMapper(e?.meta) })
              }
            />
          )}
        </div>
      )}
      <div className="w-full">
        {toProvider && (
          <div className="!mt-4">
            <Input
              id="to_name"
              data-qa="to-name"
              name="to_name"
              label="Name"
              headerIcon={
                <div className="flex items-center gap-1.5 text-sm">
                  <p>Search from</p>
                  <TippyInfo
                    content={'Contacts'}
                    icon="user-square"
                    onClick={() => setContactsModalVisible(true)}
                  />
                  <p>or</p>
                  <TippyInfo
                    content={'NPI'}
                    icon="new-npi"
                    onClick={() => setShowNewReferringProvider(true)}
                  />
                </div>
              }
              placeholder="John Doe"
              type="text"
              value={formik?.values?.to_name}
              error={formik?.errors?.to_name}
              onChange={formik && formik?.handleChange}
            />
          </div>
        )}
        {renderInputs({ prefix: fromProvider ? 'from' : 'to', formik })}

        <ContactsModal
          contactsModalVisible={contactsModalVisible}
          setContactsModalVisible={setContactsModalVisible}
          selectOne={true}
          handleSelectContacts={(userData) =>
            populateReferrerFields(referrerFromContactInfoDataMapper(userData?.[0]))
          }
        />

        <NewReferringProvider
          mode={{ select: true }}
          formatRP={() => null}
          selectedProvider={(userData) => populateReferrerFields(referrerInfoDataMapper(userData))}
          showNewReferringProvider={showNewReferringProvider}
          hideNewReferringProvider={() => setShowNewReferringProvider(false)}
          className={'w-[924px] sm:w-full'}
        />
      </div>
    </div>
  );
};

export default ContactInfo;
