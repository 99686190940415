import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useClinicalNoteRead } from 'lib/hooks/queries/clinicalNotes/useClinicalNote';
import { useOverview } from 'lib/hooks/queries/overview/useOverview';

import { currentPractice } from 'components/practice/practiceState';
import { clinicalNote as clinicalNoteState, userState } from 'components/state';

import { requestApi } from '../../../../../api/Api';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  formatDate,
  formatDateAndTimeZ,
  iaRa,
  io,
  spaceToKebabCase
} from '../../../../../lib/helpers/utility';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import Modal from '../../../../shared/Modal/Modal';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { processCustomFormTypes } from '../lib/processCustomNoteTypes';

import LoadPreviousNoteTable from './LoadPreviousNoteTable';
import { cleanNote, modifyData } from './lib/processData';
import { saveAdvancedForms, saveCustomForms, saveVitals } from './lib/saveForms';

const PreviousNotes = () => {
  const {
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    setLoading,
    showPrevNoteModal,
    setShowPrevNoteModal,
    setServiceCPT,
    setCurrentHpOverviewData,
    currentHpOverviewData,
    overviewData,
    setOverviewData,
    setVitals,
    saveNoteRef = { current: null },
    appointment
  } = useClinicalNoteContext() || {};
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const [showPreviewProps, setShowPreviewProps] = useState({ clinicalNoteId: null });
  const [selectedNoteIndex, setSelectedNoteIndex] = useState(null);
  const { id, appointmentId } = useParams();
  const navigate = useNavigate();
  const practice = useRecoilValue(currentPractice);
  const user = useRecoilValue(userState);

  const { data, isLoading } = useQuery({
    queryKey: ['getClinicalNotes', id],
    queryFn: getClinicalNotes,
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });

  const allClinicalNotes = iaRa(data?.clinicalNote)?.filter((note) => note?.id);

  const { data: cnRead, isFetching: isFetchingCurrentCN } = useClinicalNoteRead({
    dependencies: [selectedNoteIndex],
    params: {
      patientId: id,
      appointmentId: allClinicalNotes?.[selectedNoteIndex]?.appointment_id
    },
    options: useQueryOptions
  });

  const { data: currentOverviewData, isFetching: isFetchingOverview } = useOverview({
    dependencies: [selectedNoteIndex],
    params: {
      patientId: Number(id),
      appointmentId: Number(allClinicalNotes?.[selectedNoteIndex]?.appointment_id),
      allVersions: true
    },
    options: useQueryOptions
  });

  const useQueryOptions = {
    enabled: selectedNoteIndex >= 0,
    refetchOnMount: true
  };

  const isDataLoaded = !isFetchingCurrentCN && !isFetchingOverview;

  useEffect(() => {
    prepareDataToPreviewAndLoad();
  }, [isDataLoaded]);

  const prepareDataToPreviewAndLoad = () => {
    if (!isDataLoaded) return;
    if (!cnRead && !currentOverviewData) return;

    const { clinicalNote, advancedHP, advancedSOAP, serviceCPT, customFormTypes } = cnRead || {};

    let processedCurrentOverviewData = {};
    if (io(currentOverviewData)) {
      processedCurrentOverviewData = modifyData({
        data: currentOverviewData,
        currentHpOverviewData,
        appointmentId,
        patientId: id,
        practiceId: practice?.id
      });
    }

    const dos = formatDate(appointment?.starts_at, practice?.timezone);
    const cpt_codes = iaRa(serviceCPT).map((row) => {
      return {
        ...row,
        uuid: self.crypto.randomUUID(),
        created_at: formatDateAndTimeZ(new Date(), practice?.timezone),
        from_date: dos,
        thru_date: dos
      };
    });

    setShowPreviewProps({
      clinicalNoteId: cnRead?.clinicalNote?.id,
      customClinicalNote: {
        clinicalNote: { ...clinicalNote, cpt_codes },
        advancedHP: iaRa(advancedHP).map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        })),
        advancedSOAP: iaRa(advancedSOAP).map((item) => ({
          ...item,
          custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
            item?.form_id || item?.id
          }`
        })),
        customFormTypes: processCustomFormTypes({ customFormTypes }),
        serviceCPT,
        currentHpOverviewData: processedCurrentOverviewData
      },
      originalOverviewData: currentHpOverviewData
    });
  };

  async function getClinicalNotes() {
    const response = await requestApi({
      url: '/api/clinical_note/read_multiple',
      navigate,
      params: {
        patientId: id,
        pagination: false
      }
    });
    return response;
  }

  const loadNote = async () => {
    setLoading(true);

    const {
      clinicalNote: cn,
      advancedHP,
      advancedSOAP,
      customFormTypes,
      serviceCPT,
      currentHpOverviewData
    } = showPreviewProps?.customClinicalNote || {};
    const currentOverviewData = currentHpOverviewData;

    setCurrentHpOverviewData(currentOverviewData);

    const cleanedNote = cleanNote(cn);

    setClinicalNote({ ...clinicalNote, ...cleanedNote });

    if (advancedHP) {
      setAdvancedHP(advancedHP);
    }

    if (advancedSOAP) {
      setAdvancedSOAP(advancedSOAP);
    }

    if (customFormTypes) {
      setCustomFormTypes(customFormTypes);
    }

    if (serviceCPT) {
      setServiceCPT(serviceCPT);
    }

    setTimeout(async () => {
      await saveNoteRef?.current?.onSaveNote();

      // TODO: OPTIMIZE - CREATE NEW ENDPOINT TO HANDLE MULTIPLE UPSERTS
      await saveAdvancedForms({
        items: [
          ...advancedHP,
          ...advancedSOAP,
          ...Object.keys(customFormTypes || {})
            .map((key) => customFormTypes?.[key])
            .flat()
        ],
        appointmentId,
        patientId: id
      });

      await saveCustomForms({
        items: currentOverviewData,
        originalItems: showPreviewProps?.originalOverviewData,
        patientId: id,
        appointmentId,
        navigate,
        setOverviewData,
        setCurrentHpOverviewData,
        user
      });

      if (io(currentOverviewData?.vitals)) {
        await saveVitals({
          patientId: id,
          appointmentId: parseInt(appointmentId),
          navigate,
          setCurrentHpOverviewData,
          setOverviewData,
          user,
          vitals: currentOverviewData?.vitals
        });
      }

      setShowPrevNoteModal(false);
      setLoading(false);
    }, 500);
  };

  const onCancelPreviewNote = () => {
    setShowPreviewProps({});
    setSelectedNoteIndex(null);
  };

  return (
    <Modal
      handleOpen={showPrevNoteModal}
      handleClose={() => setShowPrevNoteModal(false)}
      title="Load from previous notes"
      className="w-[600px]"
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            onClick={() => setShowPrevNoteModal(false)}
            outlined
            text="Cancel"
            color="neutral"
            data-qa="cancel-btn"
          />
        </div>
      }>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-500 leading-6 text-neutral-800">Previous Clinical Notes</h3>
        </div>
        {isLoading ? (
          <Skeleton count={5} />
        ) : (
          <LoadPreviousNoteTable
            allClinicalNotes={allClinicalNotes}
            showPreviewProps={showPreviewProps}
            selectedNoteIndex={selectedNoteIndex}
            isDataLoaded={isDataLoaded}
            handleNoteClick={(index) => setSelectedNoteIndex(index)}
            onCancelPreviewNote={onCancelPreviewNote}
            loadNote={loadNote}
          />
        )}
      </div>
      <Icon icon="new-pattern-two-rect" className="!absolute bottom-[68px] left-0" />
    </Modal>
  );
};

export default PreviousNotes;
