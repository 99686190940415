import { formatDate } from 'lib/helpers/utility';
import HtmlRenderer from '../../../../components/HtmlRenderer';

export const getColDefs = () => [
  {
    field: 'name',
    headerName: 'Macro Label',
    cellClass: 'no-border',
    sort: 'desc',
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'body',
    headerName: 'Macro Area',
    cellRenderer: HtmlRenderer,
    cellClass: 'no-border'
  }
];

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true, flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
