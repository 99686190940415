import Input from 'components/shared/Input/Input';
import React, { useState } from 'react';
import { questions } from '../../lib/questionnaire';
import Icon from 'components/shared/Icon/Icon';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import StepsChart from '../StepsChart/StepsChart';
import { getCurrentStep, getStepsLeft } from '../../lib/helpers';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import cs from 'classnames';

const Questionnaire = ({ formik, currentBreadcrumb }) => {
  const { breadCrumbs, setEnableNextTo } = useFormBuilderContext();
  const [currentStep, setCurrentStep] = useState('create_type');
  const [currentSubStep, setCurrentSubStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);

  const handleNext = (value, step, subStep = null) => {
    subStep
      ? formik.setFieldValue(`form_details.${subStep}`, value)
      : formik.setFieldValue(`form_details.${step}`, value);
    setCompletedSteps((prev) => [...prev, subStep || step]);

    if (step === 'create_type') {
      if (value === 'clinical_template') {
        setCurrentStep('clinical_template');
        setEnableNextTo(false);
      } else if (value === 'patient_facing_form') {
        setCurrentStep('patient_facing_form');
        setCurrentSubStep('integrate');
        setEnableNextTo(false);
      }
    } else if (step === 'clinical_template') {
      formik.setFieldValue('form_details.integrate', null);
      formik.setFieldValue('form_details.receive', null);
      setEnableNextTo(true);
    } else if (step === 'patient_facing_form') {
      setEnableNextTo(false);
      if (currentSubStep === 'integrate') {
        setCurrentSubStep('receive');
      } else if (currentSubStep === 'receive') {
        formik.setFieldValue('form_details.existing', null);
        setEnableNextTo(true);
      }
    }
  };

  const renderNextQuestion = (step, subStep = null) => {
    const question = subStep ? questions[step][subStep] : questions[step];
    if (!question) return null;

    return (
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <div className="!pb-9">
          <div className="flex justify-between">
            <p
              className={cs(
                'pb-2 text-sm font-600',
                formik.values.form_details[subStep || step] !== undefined &&
                  formik.values.form_details[subStep || step] !== null
                  ? 'text-neutral-800'
                  : ' text-primary-500'
              )}>
              {question.title}
            </p>
            {formik.values.form_details[subStep || step] !== undefined &&
              formik.values.form_details[subStep || step] !== null && (
                <div className="flex h-[20px] w-[20px] !items-center justify-center rounded-full bg-success-600 !text-center">
                  <Icon icon="new-check" size="14px" />
                </div>
              )}
          </div>
          <p className="pb-[21px] text-[12px] font-400 text-primary-900">{question.description}</p>
          <div className="grid grid-cols-2 gap-4">
            {question.answers.map((answer, index) => (
              <div
                key={answer.value}
                onClick={() => handleNext(answer.value, step, subStep)}
                className="grid cursor-pointer select-none items-center gap-2 rounded-[10px] border border-solid border-neutral-100 bg-white !p-4">
                <RadioButton
                  id={answer?.id}
                  dataQA={index}
                  className="flex"
                  label={
                    <label id={answer?.id} htmlFor={answer?.id}>
                      <p className="select-none pb-[4px] text-sm font-500 text-primary-900">
                        {answer.title}
                      </p>
                      <p className="select-none text-[12px] font-400 text-neutral-600">
                        {answer.description}
                      </p>
                    </label>
                  }
                  name={subStep || step}
                  value={answer.value}
                  isSelected={formik.values.form_details[subStep || step] === answer.value}
                />
              </div>
            ))}
          </div>
        </div>
      </ErrorBoundary>
    );
  };

  const currentBreadcrumbStep = getCurrentStep(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);
  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div>
        <StepsChart
          chartData={chartData}
          title="Name & Type"
          description="Choose how and where your form will be used within your interface."
          innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
        />
        <div className="px-9 py-[15px]">
          <div className="pb-4">
            <div className="flex justify-between pb-2">
              <label>Form Name</label>
              {!formik.errors.form?.name && (
                <div className="flex h-[20px] w-[20px] !items-center justify-center rounded-full bg-success-600 !text-center">
                  <Icon icon="new-check" size="14px" />
                </div>
              )}
            </div>
            <Input
              placeholder="Enter form name"
              id="form.name"
              name="form.name"
              value={formik.values.form?.name}
              onChange={formik.handleChange}
              className="!pb-6"
              error={formik.touched.form?.name && formik.errors.form?.name}
            />
            <hr className="h-[1px] border-0 bg-neutral-100" />
          </div>
          <form>
            {renderNextQuestion('create_type')}

            {formik?.values?.form_details?.create_type === 'clinical_template'
              ? renderNextQuestion('clinical_template', 'existing')
              : completedSteps.includes('create_type') &&
                currentStep === 'clinical_template' &&
                renderNextQuestion('clinical_template', 'existing')}

            {formik?.values?.form_details?.create_type === 'patient_facing_form' ? (
              <>
                {renderNextQuestion('patient_facing_form', 'integrate')}
                {formik?.values?.form_details?.integrate !== undefined &&
                  formik?.values?.form_details?.integrate !== null &&
                  renderNextQuestion('patient_facing_form', 'receive')}
              </>
            ) : (
              completedSteps.includes('create_type') &&
              currentStep === 'patient_facing_form' && (
                <>
                  {renderNextQuestion('patient_facing_form', 'integrate')}
                  {(currentSubStep === 'receive' || completedSteps.includes('integrate')) &&
                    renderNextQuestion('patient_facing_form', 'receive')}
                </>
              )
            )}
          </form>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Questionnaire;
