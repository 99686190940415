import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useReadPatientBalance } from 'lib/hooks/queries/billing/usePatientBalance';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS,
  getColDefs
} from '../configs/allocatedConfigs';

const NewAllocatedTable = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider
      name="allocated_transactions"
      cols={getColDefs(practiceTimezone)}
      pagination
      defaultFilters={DEFAULT_FILTERS}>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { limit, page, sort, setPage } = useTableContext();

  const { id: patientId } = useParams();

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useReadPatientBalance({
    params: {
      limit,
      page,
      sort,
      patientId,
      unpaid: false
    },
    dependencies: [limit, page, sort, patientId],
    name: 'allocated'
  });

  const transactions = useMemo(() => data?.allocated?.data || [], [data?.allocated?.data]);
  const count = data?.allocated?.count || 0;
  const statusPanelData = data?.allocated?.statusPanelData;

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: transactions,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: transactions,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [transactions, count, page, limit, onPageChange, statusPanelData]);

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {isGetInvoicesLoading || isFetching ? (
        <Skeleton count={limit} />
      ) : (
        <div className="ag-theme-quartz !mb-0 mt-3 h-[calc(100vh-185px)]">
          <AGTable
            defaultColDef={DEFAULT_COLUMN_DEFS}
            columnDef={getColDefs(practiceTimezone)}
            gridOptions={GRID_OPTIONS}
            animateRows={true}
            data={transactions}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            isRowSelectable={() => true}
            customClassName="ag-grid-interactive"
            reactiveCustomComponents
            statusBar={statusBar}
            enableRangeSelection
          />
        </div>
      )}
    </ErrorBoundary>
  );
}

export default NewAllocatedTable;
