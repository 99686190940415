import { optionify } from '../lib/helpers/utility';
import { requestApi } from './Api';

export const getStates = async (
  navigate,
  options = { method: 'get', optionify: false, returnData: false }
) => {
  const res = await requestApi({
    navigate,
    url: '/api/get_states',
    onError: () => {},
    method: options?.method || 'get'
  });
  if (options.returnData) {
    return { states: options?.optionify ? optionify(res?.states) : res?.states };
  }
  return options?.optionify ? optionify(res?.states) : res?.states;
};
