module.exports = {
  IDProofing: 'IDProofing',
  messageQueue: 'messageQueue',
  patient: 'patient',
  patientLockdown: 'patientLockdown',
  standard: 'standard',
  taskMode: 'taskMode',
  userOptions: 'userOptions',
  userWithPatientOptions: 'userWithPatientOptions',
  utilityMode: 'utilityMode'
};
