import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { ia, showCardExpiration } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Button/Button';
import PreAuthFormModal from '../../../shared/Modal/PreAuthFormModal/PreAuthFormModal';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { userState } from '../../../state';
import schedulingState from '../schedulingState';
import './css/PracticeStep.scss';

export default function CardOnFile() {
  const [showPreAuthModal, setShowPreAuthModal] = useState(false);
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);
  const currentUser = useRecoilValue(userState);
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();
  const [{ preAuthLoading, cardDataLoading }, setLoading] = useState({
    preAuthLoading: false,
    cardDataLoading: false
  });

  useEffect(() => {
    setLoading((prevState) => ({ ...prevState, preAuthLoading: true }));

    setTimeout(() => {
      if (currentUser?.user_id)
        getStatus({
          dataObject: {
            user_full_name: `${currentUser.f_name} ${currentUser.l_name}`,
            user_id: currentUser.user_id
          }
        });
    }, 500);

    setLoading((prevState) => ({ ...prevState, preAuthLoading: false }));
  }, [currentUser]);

  useEffect(() => {
    appointmentData?.userId && loadSavedCards();
  }, [appointmentData]);

  const loadSavedCards = async () => {
    try {
      const res = await interimApi(
        '/api/transactions/token/list',
        {
          newAppointment: true
        },
        navigate
      );
      const { code, redirect, error, cards: loadedCards } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedCards)) setCards(loadedCards);
          break;
        default:
          if (error) toast.error(error);
          Honeybadger.notify(
            `file: self_scheduling/steps/CardOnFile, method: loadSavedCards - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: self_scheduling/steps/CardOnFile, method: loadSavedCards - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getStatus = async ({ dataObject, nextStep = false }) => {
    try {
      const { status, data } = await interimApi(
        '/api/patient/appointment/self_schedule',
        dataObject,
        navigate
      );

      if (status == 200) {
        setAppointmentData({ ...(data?.object || {}) });
        nextStep && navigate('/book/confirmation');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const hidePreAuthModal = () => {
    setShowPreAuthModal(false);
  };

  return (
    <>
      <div className="w-full">
        {!preAuthLoading ? (
          <div className="!mb-5 md:w-full">
            <Button black onClick={() => setShowPreAuthModal(true)}>
              Add Card On File
            </Button>
          </div>
        ) : (
          <div className="!mb-5 md:w-full">
            <Skeleton width={240} height={30} className="py-[5px]" />
          </div>
        )}

        {cardDataLoading ? (
          <Skeleton width={240} height={80} count={3} />
        ) : ia(cards) ? (
          <>
            <div className={'flex flex-wrap'}>
              {cards?.map((card, idx) => {
                const expiration = showCardExpiration(card?.expiration);
                return (
                  <div
                    key={idx}
                    className={
                      'mx-2.5 mt-0 mb-5 flex w-2/5 justify-between rounded-md border border-solid border-neutral-50 !p-5 leading-6 md:w-full'
                    }>
                    <div className={'flex flex-col flex-wrap'}>
                      <h4>Card {card?.id}</h4>
                      <p>Card Number {card?.pan}</p>
                      <p>Card Type: {card?.kind}</p>
                      <p>Expiration Date: {expiration}</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="flex justify-end">
              <Button
                className="ContinueButton"
                black
                disabled={!ia(cards)}
                onClick={() => getStatus({ dataObject: { hasCards: true }, nextStep: true })}>
                Continue
              </Button>
            </div>
          </>
        ) : (
          'No Cards Found.'
        )}
      </div>

      <PreAuthFormModal
        showPreAuthModal={showPreAuthModal}
        hidePreAuthModal={hidePreAuthModal}
        practiceId={appointmentData.practice_id}
        practiceName={appointmentData.practice_name}
        patientId={appointmentData?.userId}
        patientName={appointmentData?.user_full_name}
        setShow={() => {}}
        preAuthFromSelfScheduling={true}
        type="annual"
        subModal={false}
        showOverrides={{
          cash: false,
          check: false,
          cardOnFile: false,
          careCredit: false,
          cardPresent: false,
          online: true,
          noFooter: false
        }}
        onSuccess={(event) => {
          if (event?.card?.id) {
            event?.card?.id && setCards((prevState) => [...prevState, { ...event.card }]);
            hidePreAuthModal();
          }
        }}
      />
    </>
  );
}
