import React, { useEffect, useState } from 'react';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';

const Form = ({ formik, edit, selectedState, handleSelectState, states }) => {
  const [showBillingAddress, setShowBillingAddress] = useState(false);

  useEffect(() => {
    if (formik?.errors?.address_ln_1 || formik?.errors?.zip) {
      setShowBillingAddress(true);
    }
  }, [formik?.errors?.address_ln_1, formik?.errors?.zip]);

  return (
    <>
      <div className="xxs: grid grid-cols-4 gap-x-4 gap-y-6 sm:grid-cols-2 sm:!gap-y-4 xxs:grid-cols-1">
        <Input
          label="Patient name"
          id="patientName"
          type="text"
          name="patientName"
          className="white"
          value={formik.values.patientName}
          onChange={formik.handleChange}
          placeholder="Patient name"
          disabled={!edit}
          error={formik.errors.patientName}
        />
        <Input
          label="Cardholder name"
          id="cardholderName"
          type="text"
          name="cardholderName"
          value={formik.values.cardholderName}
          onChange={formik.handleChange}
          placeholder="Cardholder name"
          disabled={!edit}
          error={formik.errors.cardholderName}
        />
        <DatePopover
          label="Date"
          value={formik.values.date}
          onChange={(date) => {
            formik.setFieldValue('date', date);
          }}
          id="date"
          disabled={!edit}
        />
        <div className="m-auto flex justify-around">
          <Button
            text={`${showBillingAddress ? 'Hide' : 'Show'} Billing address`}
            onClick={() => setShowBillingAddress(!showBillingAddress)}
            icon={showBillingAddress ? 'arrow-square-up' : 'arrow-square-down'}
            size="small"
            transparent
          />
        </div>
      </div>
      {showBillingAddress && (
        <div className="xxs: grid grid-cols-5 gap-x-4 gap-y-6 !py-4 sm:grid-cols-2 sm:!gap-y-4 xxs:grid-cols-1">
          <Input
            label="Billing Address 1"
            type="text"
            placeholder="Address"
            name="address_ln_1"
            value={formik.values.address_ln_1}
            onChange={formik.handleChange}
            id="addressFirst"
            disabled={!edit}
            error={formik.errors.address_ln_1}
          />
          <Input
            label="Billing Address 2"
            type="text"
            placeholder="Address 2"
            name="address_ln_2"
            value={formik.values.address_ln_2}
            onChange={formik.handleChange}
            id="addressSecond"
            disabled={!edit}
            error={formik.errors.address_ln_2}
          />
          <Input
            label="City"
            type="text"
            placeholder="City"
            name="city"
            value={formik.values.city}
            onChange={formik.handleChange}
            id="city"
            disabled={!edit}
            error={formik.errors.city}
          />
          <Select
            label="State"
            minWidth={174}
            placeholder="Type and select state"
            value={selectedState}
            onChange={(value) => handleSelectState(value)}
            options={states}
            inputId="state"
            disabled={!edit}
            error={formik.errors.state}
          />
          <Input
            label="Zip code"
            type="text"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            id="zipCode"
            disabled={!edit}
            error={formik.errors.zip}
          />
        </div>
      )}
    </>
  );
};

export default Form;
