const { capitalize } = require('lodash');

exports.createHPINarrative = (data) => {
  if (!data || data.type !== 'hpi') return '';

  const {
    noDetails,
    location,
    duration,
    timing,
    quality,
    context,
    contextNote,
    relievingFactor,
    relievingFactorsNote,
    modifyingFactors,
    signsAndSymptoms
  } = data;

  if (noDetails) {
    return '<p>No details have been reported by the patient.</p>';
  }

  let narrative = '<p>The patient ';

  narrative += 'has been experiencing ';
  if (quality && quality.value) {
    narrative += `<strong>${quality.value.toLowerCase()}</strong> `;
  }
  if (location && location.value) {
    narrative += `in the <strong>${location.value.toLowerCase()}</strong> `;
  }
  if (timing && timing.value) {
    narrative += `<strong>${timing.value.toLowerCase()}</strong> `;
  }
  if (duration && duration.value) {
    narrative += `for <strong>${duration.value.toLowerCase()}</strong> `;
  }
  narrative += '.</p>';

  if (context && context.value) {
    narrative += `<p>This occurs during <em>${context.value.toLowerCase()}</em>.</p>`;
  }
  if (contextNote) {
    narrative += `<p>Additional context provided: <em>${capitalize(contextNote)}</em>.</p>`;
  }

  if (signsAndSymptoms) {
    narrative += `<p>Signs and symptoms reported include: <strong>${signsAndSymptoms.trim()}</strong>.</p>`;
  }

  if (relievingFactorsNote) {
    narrative += `<p>The patient noted the following for symptom relief: <strong>${capitalize(
      relievingFactorsNote
    )}</strong>.</p>`;
  }
  if (relievingFactor && relievingFactor.value) {
    narrative += `<p>Relieving factors include: <strong>${relievingFactor.value.toLowerCase()}</strong>.</p>`;
  }

  const modifying = [];
  if (modifyingFactors && !modifyingFactors?.noModifyingFactors) {
    const mf = modifyingFactors;
    if (mf.takenMedication === 'yes')
      modifying.push(`taken the medication <strong>"${mf.medication}"</strong>`);
    if (mf.appliedHeat === 'yes') modifying.push('applied heat');
    if (mf.helpedHeat === 'yes') modifying.push('<em>heat helped alleviate symptoms</em>');
    if (mf.appliedColdCompress === 'yes') modifying.push('used a cold compress');
    if (mf.helpedColdCompress === 'yes')
      modifying.push('<em>cold compress helped alleviate symptoms</em>');
    if (mf.hadSurgery === 'yes') modifying.push('had surgery for this issue');
    if (mf.helpedSurgery === 'yes') modifying.push('<em>surgery helped improve symptoms</em>');
    if (mf.triedPhysicalTherapy === 'yes') modifying.push('tried physical therapy');
    if (mf.helpedPhysicalTherapy === 'yes')
      modifying.push('<em>physical therapy was beneficial</em>');
    if (mf.beenRespirator === 'yes') modifying.push('been on a respirator');
    if (mf.helpedRespirator === 'yes')
      modifying.push('<em>respirator use helped alleviate symptoms</em>');
    if (mf.triedRest === 'yes') modifying.push('tried rest');
    if (mf.helpedRest === 'yes') modifying.push('<em>rest was helpful</em>');
    if (mf.otherModifyingFactors) {
      modifying.push(
        `tried other approaches such as <strong>"${mf.otherModifyingFactors}"</strong>`
      );
    }
    if (mf.helpedOtherModifyingFactors === 'helped') {
      modifying.push('<em>these approaches were helpful</em>');
    } else if (mf.helpedOtherModifyingFactors === 'hurt') {
      modifying.push('<em>these approaches worsened the symptoms</em>');
    }
  }
  if (modifying.length > 0) {
    narrative += `<p>The patient has also ${formatList(modifying)} to manage their condition.</p>`;
  }

  return narrative;
};

const formatList = (items) => {
  if (items?.length === 1) return items[0];
  return items?.slice(0, -1).join(', ') + ', and ' + items?.[items.length - 1];
};
