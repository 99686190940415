import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { assignFaxesToProviders } from 'api/Fax';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, iaRa } from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import { showAlert } from 'components/shared/Alert/Alert';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const AssignProvidersModal = ({
  assignProvidersModalVisible,
  setAssignProvidersModalVisible,
  faxes,
  faxQueryKey
}) => {
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    search: ''
  });

  const { pathname } = useLocation();

  const queryClient = useQueryClient();

  const { setSelectedRows } = useTableContext();

  const { data: providers, isFetching } = usePractitioners(null, {
    select: ({ practitioners }) => practitioners
  });

  useEffect(() => {
    if (ia(providers)) setAllProviders(providers);
  }, [providers]);

  useEffect(() => {
    filterProviders();
  }, [filters]);

  const filterProviders = () => {
    if (filters.search === '') {
      setAllProviders(providers);
    }

    const filtered = iaRa(providers).filter((provider) => {
      return (
        provider.f_name.toLowerCase().includes(filters.search.toLowerCase()) ||
        provider.l_name.toLowerCase().includes(filters.search.toLowerCase())
      );
    });

    setAllProviders(filtered);
  };

  const divRef = useRef();
  const navigate = useNavigate();

  const assignFaxes = async () => {
    setLoading(true);

    const params = { providerIds: selectedProviders, faxes, link: pathname };
    const data = await assignFaxesToProviders(navigate, params);

    if (data?.code == 0) {
      setSelectedRows([]);
      await queryClient.invalidateQueries([faxQueryKey]);
      showAlert({ color: 'success', message: 'Faxes assigned successfully!' });
    } else {
      showAlert({ color: 'danger', message: 'Faxes failed to assign!' });
    }

    data.code == 0 && setAssignProvidersModalVisible(false);

    setLoading(false);
  };

  const onCheckboxChange = ({ event, providerId }) => {
    if (event.target.checked) {
      setSelectedProviders((prev) => [...prev, providerId]);
    } else {
      setSelectedProviders((prev) => prev.filter((id) => id !== providerId));
    }
  };

  return (
    <Modal
      handleOpen={!!assignProvidersModalVisible}
      handleClose={() => setAssignProvidersModalVisible(false)}
      title="Assign fax to providers"
      slideFromRight
      customStyling={{ width: '540px' }}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => {}}
            data-qa="cancel-select-files-btn"
          />
          <Button
            disabled={!selectedProviders}
            onClick={assignFaxes}
            text="Assign to providers"
            id="createContactModalBtn"
            data-qa="select-files-btn"
            loading={loading}
          />
        </div>
      }>
      <div className="!mt-1 rounded-lg bg-white !p-2 shadow-sm">
        <Input
          id="searchPatient"
          type="string"
          placeholder="Search providers..."
          value={filters.search}
          rounded="full"
          icon="new-search"
          className="!mr-5 !rounded-2xl !border-secondary-400 "
          onChange={(e) => setFilters((prev) => ({ ...prev, search: e.target.value }))}
          disabled={isFetching}
          rightText={
            filters.search && (
              <Icon
                icon="new-close"
                onClick={() => setFilters((prev) => ({ ...prev, search: '' }))}
              />
            )
          }
        />

        {isFetching ? (
          <Skeleton count={13} />
        ) : (
          <div
            className="!mt-4 flex max-h-[calc(100vh-230px)] flex-col !gap-4 overflow-y-auto"
            ref={divRef}>
            {allProviders?.map((provider) => {
              const fullName = `${provider?.f_name} ${provider?.l_name}`;

              return (
                <div key={provider?.id} className="flex cursor-pointer items-center gap-3">
                  <div className="flex w-[25px]">
                    <Checkbox
                      value={true}
                      id={provider.id}
                      name="triedRest"
                      isChecked={selectedProviders.includes(provider.id)}
                      onChange={(event) => onCheckboxChange({ event, providerId: provider.id })}
                    />
                  </div>
                  <Avatar
                    firstName={provider?.f_name}
                    lastName={provider?.l_name}
                    id={provider?.id}
                    size="xl"
                  />
                  <h3 className="text-base font-500 text-neutral-900">{fullName}</h3>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AssignProvidersModal;
