import React, { useEffect, useRef, useState } from 'react';

import Button from '../../../../../../../../shared/Buttons/Button';
import Icon from '../../../../../../../../shared/Icon/Icon';
import Modal from '../../../../../../../../shared/Modal/Modal';
import Textarea from '../../../../../../../../shared/Textarea/Textarea';

import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useMyScribeAIContext } from '../../../../../../../../../lib/context/MyScribeAI/MyScribeAIContext';

const SummaryModal = ({ showModal = false, hideModal = () => null }) => {
  const { transcriptData, setTranscriptData, isUpsertProcessLoading, actionRef } =
    useAmbientListeningContext() || {};

  const [localSummary, setLocalSummary] = useState(transcriptData?.[0]?.summary);

  useEffect(() => {
    setLocalSummary(transcriptData?.[0]?.summary);
  }, [transcriptData]);

  const textAreaRef = useRef(null);

  const handleChange = ({ summary = null }) => setLocalSummary(summary);

  const onUpdateSummary = () => {
    setTranscriptData((prevState) => {
      let copyTranscriptData = [...prevState];
      copyTranscriptData[0].summary = localSummary;

      return copyTranscriptData;
    });

    actionRef?.current?.upsert({ fromSummary: true });
    hideModal();
  };

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={
        <div className="flex items-center gap-[6px] self-center">
          <Icon
            icon="new-microphone"
            size={24}
            color="primary"
            className="flex justify-center bg-white"
          />
          <p className="text-base font-500 text-neutral-800">Edit MyScribe AI Narrative</p>
        </div>
      }
      slideFromRight
      className="w-[1020px]"
      footer={
        <div className="flex w-full justify-between">
          <Button
            disabled={isUpsertProcessLoading}
            outlined
            text="Cancel"
            onClick={() => hideModal()}
            color="neutral"
          />

          <Button
            loading={isUpsertProcessLoading}
            disabled={isUpsertProcessLoading}
            onClick={() => onUpdateSummary()}
            text="Update narrative"
          />
        </div>
      }>
      <div>
        <Textarea
          isEditor
          label="Narrative"
          placeholder="Edit the summary"
          className="min-h-[200px]"
          forwardedRef={textAreaRef}
          value={localSummary}
          name="myscribe-summary"
          id="myscribe-summary"
          data-qa="myscribe-summary"
          onChange={(event) => handleChange({ summary: event })}
        />
      </div>
    </Modal>
  );
};

export default SummaryModal;
