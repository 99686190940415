export const setPatientAggregateNarrativeState = ({ queryClient, keys, payload }) =>
  queryClient.setQueryData(['patientAggregateNarrative', ...keys], (prev) => ({
    ...prev,
    data: {
      ...prev?.data,
      narrative: {
        ...prev?.data?.narrative,
        ...payload
      }
    }
  }));
