export default function isColorDark(color) {
  let loadedColor = color || '#13B9FF';
  loadedColor = loadedColor?.replace('#', '');

  const r = parseInt(loadedColor.substring(0, 2), 16);
  const g = parseInt(loadedColor.substring(2, 4), 16);
  const b = parseInt(loadedColor.substring(4, 6), 16);

  const brightness = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

  return brightness < 0.5;
}
