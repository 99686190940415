import React, { useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { Capitalize, ia, iaRa, snakeToTitleCase } from 'lib/helpers/utility';
import { useNdcCodesOptions } from 'lib/hooks/queries/ndc/useNdcCodesOptions';

import Button from 'components/shared/Buttons/Button';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Switch from 'components/shared/Switch/Switch';

import {
  getLabelForMeasureValue,
  getNdcMeasureOptions
} from '../BeyondBilling/Claims/CMS1500/lib/helpers';
import practiceState from '../practiceState';

import { conditionalFormatTimeZ } from './SuperBillPage/lib/conditionTimeFormat';
import { generateBaseUnit } from './SuperBillPage/lib/generateBaseUnit';
import { getHours } from './SuperBillPage/lib/getHours';
import { handleOnChangeUnit } from './SuperBillPage/lib/handleOnChangeUnit';
import { handleUnitSwitch } from './SuperBillPage/lib/handleUnitSwitch';
import { handleUnitValue } from './SuperBillPage/lib/handleUnitValue';
import { shouldDisableProcedureProduct } from './SuperBillPage/lib/shouldDisableProcedure';

export default function CPTRow({
  item,
  handleProcedureChanges,
  index,
  posOptions,
  handleDeletion,
  handleCPTSearch,
  selectCPT,
  superbill,
  convertProcedureToPrimary
}) {
  const disableProcedure = shouldDisableProcedureProduct({
    superbill,
    procedure: item
  });
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const formattedStartTime = conditionalFormatTimeZ(
    item?.appointment_start,
    currPractice?.timezone
  );
  const formattedEndTime = conditionalFormatTimeZ(item?.appointment_end, currPractice?.timezone);
  const hours = getHours(currPractice, formattedStartTime, formattedEndTime);
  const timeOptions = iaRa(hours).map((time) => ({
    value: time,
    label: time
  }));

  const isNotPrimary = item?.type && item.type !== 'primary';
  const [manualChange, setManualChange] = useState(false);
  const { data } = useNdcCodesOptions({
    options: { enabled: !!item?.procedure_code },
    params: { procedure_code: item?.procedure_code },
    dependencies: [item?.procedure_code]
  });
  const ndcOptions = iaRa(data?.ndcOptions);
  useEffect(() => {
    if (ia(ndcOptions)) {
      const ndc = ndcOptions.find((ndc) => ndc?.package_ndc === item?.ndc_code);
      if (!ndc) {
        handleProcedureChanges({ target: { value: '', name: 'ndc_code' } }, index);
      }
    }
  }, [ndcOptions]);

  return (
    <div
      className={cs(
        'gap-x-4 gap-y-1 !rounded-lg border border-solid border-neutral-300 p-2 odd:bg-neutral-50',
        isNotPrimary && '!border-warning-300 !bg-warning-50'
      )}>
      {isNotPrimary && (
        <div className="mb-3 flex justify-between">
          <div className="flex gap-1.5">
            <Icon icon="new-info" color="warning" />
            <p className="self-center text-sm text-warning-600">
              This service type is <em className="font-600">"{snakeToTitleCase(item.type)}"</em>, it
              will not be transferred to the claim unless converted to insurance type
            </p>
          </div>
          <Button
            text="Convert to insurance"
            size="small"
            outlined
            onClick={() => convertProcedureToPrimary(index)}
          />
        </div>
      )}
      <div className={cs('grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-x-4 gap-y-1 ')}>
        <TippyContainer isDisabled={disableProcedure}>
          <Select
            label="Code"
            isAsync
            loadOptions={handleCPTSearch}
            onChange={(e) => selectCPT(e, index)}
            cacheOptions
            value={{ label: item.procedure_code }}
            noOptionsMessage={() => 'Start typing the code or description.'}
            inputId="cptCodes"
            menuPortalTarget={document.body}
            disabled={disableProcedure}
            isClearable={false}
          />
        </TippyContainer>
        {ia(ndcOptions) && (
          <>
            <div>
              <Select
                isClearable={false}
                label="NDC Code"
                placeholder="Select NDC code"
                name="ndc_code"
                value={{ value: item.ndc_code, label: item.ndc_code }}
                options={
                  ia(ndcOptions)
                    ? ndcOptions.map((item) => ({
                        value: item?.package_ndc,
                        label: `[ ${Capitalize(item?.generic_name)} ]\n ${item?.packaging_description}`
                      }))
                    : []
                }
                onChange={(event) =>
                  handleProcedureChanges(
                    {
                      target: { value: event?.value, name: 'ndc_code' }
                    },
                    index
                  )
                }
              />
            </div>

            <div>
              <Input
                label="NDC Dosage"
                placeholder="NDC Dosage"
                name="ndc_dosage"
                value={item.ndc_dosage}
                onChange={(e) => handleProcedureChanges(e, index)}
              />
            </div>

            <div>
              <Select
                label="NDC Measure"
                placeholder="NDC Measure"
                isClearable={false}
                name="ndc_measure"
                value={{
                  value: item.ndc_measure,
                  label: getLabelForMeasureValue(item.ndc_measure)
                }}
                options={getNdcMeasureOptions()}
                onChange={(e) =>
                  handleProcedureChanges(
                    { target: { value: e?.value, name: 'ndc_measure' } },
                    index
                  )
                }
              />
            </div>
          </>
        )}

        <Input
          label="NDC Narrative"
          name="narrative"
          value={item.narrative}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <Input
          label="Modifier"
          name="modifiers"
          value={item.modifiers}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <Input
          label="Diagnosis pointer"
          name="diagnosis"
          value={item.diagnosis}
          onChange={(e) => handleProcedureChanges(e, index)}
        />
        <CurrencyInput
          label="Charges"
          name="charge"
          value={item.charge}
          onValueChange={(value) =>
            handleProcedureChanges({ target: { value, name: 'charge' } }, index)
          }
        />
        <div className="flex items-center justify-center">
          <Input
            label={item?.base_unit && item?.isTimeUnit ? 'Minutes' : 'Days/Units'}
            name="unit_time_length"
            tooltip={item?.base_unit ? 'Toggle between Days/Units and Minutes' : ''}
            headerIcon={
              item?.base_unit && (
                <div className="flex gap-8">
                  <Switch
                    checked={item?.isTimeUnit || false}
                    onChange={(checked) => {
                      item.isTimeUnit = checked;
                      handleUnitSwitch({
                        procedure: item,
                        isTimeUnit: item?.isTimeUnit,
                        handleProcedureChanges,
                        index,
                        startTime: formattedStartTime,
                        endTime: formattedEndTime
                      });
                    }}
                  />
                </div>
              )
            }
            value={handleUnitValue(
              item,
              item?.isTimeUnit,
              item?.start_time || formattedStartTime,
              item?.end_time || formattedEndTime,
              handleProcedureChanges,
              index,
              manualChange
            )}
            onChange={(e) => {
              setManualChange(true);
              handleOnChangeUnit({
                procedure: item,
                isTimeUnit: item?.isTimeUnit,
                handleProcedureChanges,
                index,
                startTime: formattedStartTime,
                endTime: formattedEndTime,
                value: e.target.value
              });
            }}
            min={1}
            type="number"
          />
        </div>
        <Select
          label="POS"
          className="print:hidden"
          options={posOptions}
          value={posOptions.find((p) => p.value === String(item.place_of_service))}
          onChange={(e) =>
            handleProcedureChanges({ target: { value: e?.value, name: 'place_of_service' } }, index)
          }
          isMulti={false}
        />
        <Input
          label="Place of service"
          className="hidden print:block"
          value={item.place_of_service}
          type="text"
        />

        <DatePopover
          name="from_date"
          label="From date"
          rightIcon={false}
          placeholder="From"
          value={item?.from_date}
          onChange={(event) =>
            handleProcedureChanges({ target: { name: 'from_date', value: event } }, index)
          }
        />

        <DatePopover
          name="thru_date"
          label="Thru date"
          rightIcon={false}
          placeholder="Thru"
          value={item?.thru_date}
          onChange={(event) =>
            handleProcedureChanges({ target: { name: 'thru_date', value: event } }, index)
          }
        />

        {item?.base_unit && (
          <>
            <Select
              label="Start Time"
              parentClassName="w-full"
              isClearable={false}
              className="!-mt-[2px]"
              rightIcon="new-clock-gray"
              options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
              onChange={(val) => {
                handleProcedureChanges({ target: { value: val.value, name: 'start_time' } }, index);
                generateBaseUnit({
                  procedure: item,
                  formattedStartTime,
                  formattedEndTime,
                  index,
                  updateProcedures: handleProcedureChanges
                });
              }}
              value={
                timeOptions.find((option) => option.value == item.start_time) ||
                timeOptions.find((option) => option.value == formattedStartTime)
              }
            />
            <Select
              label="End Time"
              parentClassName="w-full"
              isClearable={false}
              className="!-mt-[2px]"
              rightIcon="new-clock-gray"
              options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
              onChange={(val) => {
                handleProcedureChanges({ target: { value: val.value, name: 'end_time' } }, index);
                generateBaseUnit({
                  procedure: item,
                  formattedStartTime,
                  formattedEndTime,
                  index,
                  updateProcedures: handleProcedureChanges
                });
              }}
              value={
                timeOptions.find((option) => option.value == item.end_time) ||
                timeOptions.find((option) => option.value == formattedEndTime)
              }
            />
          </>
        )}

        <TippyContainer className="mb-1 mt-auto max-h-[40px]" isDisabled={disableProcedure}>
          <Button
            disabled={disableProcedure}
            close
            onClick={() => handleDeletion(index)}
            text="Remove"
            iconColor="white"
            icon="trash"
            color="danger"
            outlined
            className="mt-auto max-h-[40px] w-full"
          />
        </TippyContainer>
      </div>
    </div>
  );
}

const TippyContainer = ({ isDisabled = false, className = null, children }) => {
  return (
    <Tippy
      className="tippy-dark"
      disabled={!isDisabled}
      content="Procedure cannot be removed because the clinical note is locked.">
      <div className={className}>{children}</div>
    </Tippy>
  );
};
