import React from 'react';
import DefaultView from './DefaultView';
import PractitionersDndList from './ProvidersDndList';
import TimeIncrements from './TimeIncrements';

const ViewsSettings = () => {
  return (
    <div className="box">
      <p className="!pb-2 text-base font-500">Views settings</p>
      <div className="flex flex-wrap gap-x-[40px] gap-y-7">
        <div>
          <DefaultView />
          <TimeIncrements />
        </div>
        <PractitionersDndList />
      </div>
    </div>
  );
};

export default ViewsSettings;
