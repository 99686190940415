import React, { useRef, useState } from 'react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import '../Custom.scss';
import { Vaccines as initialValue } from '../CustomFormInitialStates';
import VaccinesRow from './VaccinesRow';
import Header from '../components/Header';
import Container from '../components/Container';
import useCustomForm from '../lib/useCustomForm';
import { iaRa } from 'lib/helpers/utility';
import DeleteFormModal from '../components/DeleteFormModal';

const Vaccines = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  formId = null,
  showNarrative = false,
  onChange,
  setItemModal = () => {},
  options = []
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [items, setItems] = useState(initialValue);

  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.allergies?.advancedForm
      : true;
  const textAreaRef = useRef();

  const [itemToDelete, setItemToDelete] = useState(null);

  const rows = 'row';

  const props = {
    itemToDelete,
    setItemToDelete,
    formId,
    setItems,
    setModal: setItemModal,
    fromCheckin: true,
    items,
    rows,
    title: 'Allergies',
    alertTitle: 'Allergy',
    initialValue,
    textAreaRef,
    defaultValue,
    onChange,
    setCurrentFormData
  };

  const { handleAddRow, handleOnChange, handleNoDetails, onDelete, syncItems, length } =
    useCustomForm(props);

  const handleNotSure = (id) => {
    const updatedObject = {
      ...items,
      row: items?.row.map((row) => {
        if (row.id === id) {
          return { ...row, notSure: !row.notSure, lastDoseDisabled: !row.notSure };
        } else {
          return row;
        }
      })
    };
    syncItems(updatedObject);
  };

  return (
    <div className="Vaccines CustomForms">
      <input type="hidden" value={JSON.stringify(items)} ref={forwardedRef} />
      <Header
        title="Vaccines"
        noDetailsLabel="Do you have any vaccine?"
        noDetails={items?.noDetails}
        isAdvancedForm={isAdvancedForm}
        emptyState={{
          title: 'No vaccines recorded',
          subtitle:
            'Vaccines help protect against various diseases by boosting your immune system. Please let us know if you’ve taken any vaccines to keep your health records complete.',
          label: 'Do you have any vaccines not reported to our practice?'
        }}
        addRow={{ onClick: handleAddRow }}
        fromCheckin
        handleNoDetails={handleNoDetails}>
        {iaRa(items?.[rows])?.map((item, idx) => (
          <Container key={item.id} fromCheckin onDelete={() => onDelete(item?.id)} number={idx + 1}>
            <VaccinesRow
              key={item.id}
              index={idx}
              id={item?.id}
              fromCheckin
              onChange={handleOnChange}
              onCheckNotSure={handleNotSure}
              length={length}
              {...item}
            />
          </Container>
        ))}
      </Header>
      <DeleteFormModal syncItems={syncItems} {...props} />
    </div>
  );
};

export default Vaccines;
