import React from 'react';

const AnswersBox = ({ question, answer }) => {
  return (
    <div className="mb-2 rounded-[10px] bg-neutral-50 px-[16px] py-[10px]">
      <p className="text-sm font-500 text-neutral-600">{question}</p>
      <p className="text-sm font-500 text-neutral-800">{answer}</p>
    </div>
  );
};

export default AnswersBox;
