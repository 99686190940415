import React from 'react';

import Tippy from '@tippyjs/react';

import { formatDate, ia } from 'lib/helpers/utility';

const ClaimsTippy = ({ value, maxLength, timezone }) => {
  const remainingValues = value?.slice(maxLength);
  return (
    <>
      {remainingValues?.length > 0 ? (
        <Tippy
          arrow={true}
          placement="left-start"
          className="tippy-dark"
          content={value.map((item, idx) => (
            <p key={idx} className="relative px-1 text-[12px] font-500 text-white">
              {formatDate(item, timezone)}
            </p>
          ))}>
          <p>{formatDate(value[0], timezone)}...</p>
        </Tippy>
      ) : (
        <p>{ia(value) ? formatDate(value[0], timezone) : '-'}</p>
      )}
    </>
  );
};

export default ClaimsTippy;
