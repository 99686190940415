import { getRecoil, setRecoil } from 'recoil-nexus';

import myScribeAiState from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import state from 'components/state';

let isGenerationStarted = false;
const updateAutoMapTemplate = (data) => {
  const {
    vertical,
    response,
    done = false,
    abort = false,
    isRegenerate = false,
    isNote = true,
    note,
    ...rest
  } = data;

  try {
    if (!isGenerationStarted) {
      if (!isRegenerate) {
        setRecoil(myScribeAiState.promptListState, (prevPrompts) => {
          if (prevPrompts?.[0]?.id === rest?.intelligence_chat_id) {
            return prevPrompts;
          }
          const newPrompt = {
            id: rest?.intelligence_chat_id,
            name: 'New Note'
          };

          return [newPrompt, ...prevPrompts];
        });
      }
      // setRecoil(myScribeAiState.myScribeAILoadingState, false);
      setRecoil(myScribeAiState.isAIGeneratingState, true);
      isGenerationStarted = true;
    }

    if (isNote) {
      if (done || abort) {
        setRecoil(myScribeAiState.myScribeAILoadingState, false);
      }
    } else {
      setRecoil(myScribeAiState.myScribeAILoadingState, true);
    }

    if (done || abort) {
      setRecoil(myScribeAiState.intelligenceChatIdState, rest?.intelligence_chat_id);

      setRecoil(myScribeAiState.isAIGeneratingState, false);
      isGenerationStarted = false;
    }

    const newAiClinicalNoteState = getRecoil(state.intelligenceAiState);

    if (newAiClinicalNoteState?.timeoutTimeout)
      clearTimeout(newAiClinicalNoteState?.timeoutTimeout);

    if (!isNote) {
      setPromptStates({
        done,
        abort,
        response,
        jsonResponse: response,
        path: vertical,
        isRegenerate,
        isNote,
        ...rest
      });
    } else {
      if (done || abort) {
        setPromptStates({
          done,
          abort,
          response,
          jsonResponse: response,
          path: vertical,
          isRegenerate,
          isNote,
          note,
          ...rest
        });
      }
    }
  } catch (error) {
    console.error('❌ Error', error);
  }
};

const setPromptStates = (intelligenceAi) => {
  if (intelligenceAi?.response) {
    if (!intelligenceAi?.isRegenerate) {
      setRecoil(
        myScribeAiState.myScribeAutoMapActivePromptIdState,
        intelligenceAi?.intelligence_chat_id
      );
    }

    if (intelligenceAi?.done || intelligenceAi?.abort) {
      console.log('🚀 ~ setPromptStates ~ intelligenceAi?.note:', intelligenceAi?.note);

      setRecoil(myScribeAiState.myScribeAutoMapSelectedNoteTypeState, intelligenceAi.noteType);
      if (intelligenceAi?.intelligenceChat) {
        const { id, name, description, noteType } = intelligenceAi?.intelligenceChat || {};

        setRecoil(myScribeAiState.promptListState, (prevPrompts) => {
          if (prevPrompts?.[0]?.id === id) {
            prevPrompts[0] = {
              id,
              name,
              description,
              noteType
            };
          }
          return prevPrompts;
        });
      }

      if (intelligenceAi?.intelligenceChatMessage) {
        setRecoil(myScribeAiState.activePromptConversationsState, (prevState) => {
          let newPrompt = [...prevState];
          if (newPrompt[newPrompt?.length - 1]?.agent === 'ai') {
            newPrompt[newPrompt?.length - 1] = {
              ...intelligenceAi?.intelligenceChatMessage,
              emCPTs: intelligenceAi?.emCPTs,
              note: intelligenceAi?.note
            };
          } else {
            newPrompt = [
              ...prevState,
              {
                ...intelligenceAi?.intelligenceChatMessage,
                emCPTsText: intelligenceAi?.emCPTsText,
                note: intelligenceAi?.note
              }
            ];
          }
          return newPrompt;
        });
      }
      setRecoil(myScribeAiState.isAIGeneratingState, false);
    }

    if (!intelligenceAi?.isNote || intelligenceAi?.done) {
      const newMessageObj = {
        id: self.crypto.randomUUID(),
        message: intelligenceAi?.response,
        isNote: intelligenceAi?.isNote,
        note: intelligenceAi?.note,
        emCPTsText: intelligenceAi?.emCPTsText,
        agent: 'ai'
      };

      setRecoil(myScribeAiState.activePromptConversationsState, (prevState) => {
        let newConversation = [...prevState];
        if (newConversation[newConversation?.length - 1]?.agent === 'ai') {
          newConversation[newConversation?.length - 1] = newMessageObj;
        } else {
          newConversation = [...prevState, newMessageObj];
        }
        return newConversation;
      });
    }
  }
};

export default updateAutoMapTemplate;
