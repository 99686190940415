export const filterAndApplyPositions = (complexObj, positionsObj) => {
  if (!complexObj || !positionsObj) return complexObj;

  const filteredObj = {};

  Object.keys(positionsObj).forEach((key) => {
    const exactMatchKey = Object.keys(complexObj).find(
      (objKey) => objKey.toLowerCase() === key.toLowerCase()
    );

    if (exactMatchKey) {
      filteredObj[exactMatchKey] = {
        ...complexObj[exactMatchKey],
        position: positionsObj[key]
      };
      return;
    }

    const matchingKey = Object.keys(complexObj).find(
      (objKey) =>
        complexObj[objKey]?.title === key ||
        complexObj[objKey]?.title?.toLowerCase() === key.toLowerCase()
    );

    if (matchingKey) {
      filteredObj[matchingKey] = {
        ...complexObj[matchingKey],
        position: positionsObj[key]
      };
    }
  });

  return filteredObj;
};
