import { initialVitals } from 'components/practice/charts/ClinicalNote/Vitals/lib/helpers';
import { ia } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Accordion from '../../../../shared/Accordion';
import Vitals from '../../../../Vitals/Vitals';

const VitalsSection = ({ components, hpNoteOverviewRef, customFormsParams, sectionRef }) => {
  const {
    clinicalNote,
    vitalsRef,
    currentHpOverviewData = () => {},
    setCurrentHpOverviewData = () => {},
    setVitals: setOverviewVitals = () => {},
    currentHpOverviewLoading
  } = useClinicalNoteContext();
  const onReset = () => {
    vitalsRef.current.resetVitals();
  };
  if (currentHpOverviewLoading) return null;
  const [vitals, setVitals] = useState(
    ia(currentHpOverviewData?.vitals?.vitals) ? currentHpOverviewData?.vitals : initialVitals
  );

  return (
    <Accordion
      updateFormType={{ name: 'Vitals', parentKey: 'hp', childKey: 'vitals' }}
      title={components?.vitals?.title}
      id={components?.vitals?.id}
      disabled={clinicalNote?.locked}
      sectionRef={sectionRef}
      advanced={false}
      options={[{ title: 'Reset Vitals', icon: 'new-reset', onClick: () => onReset() }]}>
      <Vitals
        ref={vitalsRef}
        vitals={vitals}
        setVitals={setVitals}
        lastVitals={currentHpOverviewData?.vitals}
        setLastVitals={setCurrentHpOverviewData}
        setOverviewVitals={setOverviewVitals}
        hpNoteOverviewRef={hpNoteOverviewRef}
        customFormsParams={customFormsParams}
      />
    </Accordion>
  );
};

export default VitalsSection;
