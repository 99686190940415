import React, { useState } from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { ia } from 'lib/helpers/utility';
import Appointment from './components/Appointment';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import FullPreview from './components/FullPreview';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import moment from 'moment-timezone';
import NewAppointmentModal from 'components/practice/Dashboard/components/NewAppointmentModal';
import Confirmations from './components/Confirmations';
import NotFound from 'components/practice/Dashboard/components/NotFound';

const Appointments = ({ data = [], isLoading, selectedDate }) => {
  const practice = useRecoilValue(currentPractice);
  const timeSlots = {};
  const { device } = useUIContext();
  const { currentModal, confirmModal } = useDashboardContext();
  const [showModal, setShowModal] = useState(false);

  data.forEach((appointment) => {
    const hour = moment.tz(appointment?.starts_at, practice?.timezone).format('h A');
    if (!timeSlots[hour]) {
      timeSlots[hour] = [];
    }
    timeSlots[hour].push({
      ...appointment,
      sortKey: moment(appointment?.starts_at).toISOString()
    });
  });

  const sortTimeSlotsKeys = (a, b) => {
    const convertTo24Hour = (time) => {
      let [hour, period] = time.split(' ');
      hour = parseInt(hour, 10);
      if (period === 'PM' && hour !== 12) {
        hour += 12;
      } else if (period === 'AM' && hour === 12) {
        hour = 0;
      }
      return hour;
    };

    return convertTo24Hour(a) - convertTo24Hour(b);
  };

  const sortedKeys = Object.keys(timeSlots).sort(sortTimeSlotsKeys);

  const sortedTimeSlots = sortedKeys.reduce((acc, key) => {
    acc[key] = timeSlots[key].sort((a, b) => moment(a.sortKey).diff(moment(b.sortKey)));
    acc[key] = acc[key].map(({ ...rest }) => rest);
    return acc;
  }, {});

  const Placeholder = () => (
    <div className="!mb-5 flex flex-col !gap-2 !py-4 px-[22px]">
      <Skeleton width={40} height="20px" />
      <div className="!ml-5 flex !gap-4">
        <Skeleton width={20} height="20px" circle />
        <Skeleton
          width={device === 'mobile' ? 200 : device === 'laptop' ? 290 : 250}
          height="22px"
        />
      </div>
      <div className="!ml-5 flex !gap-4">
        <Skeleton width={20} height="20px" circle />
        <Skeleton
          width={device === 'mobile' ? 200 : device === 'laptop' ? 290 : 250}
          height="20px"
        />
      </div>
    </div>
  );

  if (isLoading) return <Placeholder />;

  if (!ia(data))
    return (
      <>
        <NotFound
          icon="new-illustration-7"
          iconClassName="!pb-5"
          title="No appointments yet!"
          description="You can start scheduling new appointments right now."
          createText="Schedule new appointment"
          onCreate={() => {
            setShowModal(true);
          }}
        />
        <NewAppointmentModal showModal={showModal} setShowModal={setShowModal} />
      </>
    );

  return (
    <ErrorBoundary>
      <div className="flex cursor-default flex-col !py-4 px-[22px]">
        {Object.entries(sortedTimeSlots).map(([time, dataPerSlot], index) => (
          <div key={index} className="relative">
            <div className="flex items-center">
              <span className="flex min-w-[36px] max-w-[36px] text-xs font-500 leading-5 text-neutral-500">
                {time}
              </span>
              <span className={cs('z-10 h-[5px] w-[5px] rounded-full', 'bg-primary-900')} />
            </div>
            {dataPerSlot?.map((item, idx) => (
              <div key={idx} className="ml-[14px] flex items-center gap-x-3">
                <Icon
                  className="flex items-center justify-center"
                  icon={
                    item?.appointment?.status === 'cancelled'
                      ? 'calendar-remove'
                      : item.eventType === 'event'
                        ? 'calendar-event'
                        : 'new-calendarv2'
                  }
                  color={item?.appointment?.status === 'cancelled' ? 'neutral' : 'primary'}
                  size={item.eventType === 'event' ? 15 : 16}
                />
                <Appointment item={item} index={idx} />
              </div>
            ))}
            <span className="absolute left-[38px] top-[12px] h-full w-[1px] bg-neutral-100" />
          </div>
        ))}
      </div>
      {currentModal.show && (
        <FullPreview id={currentModal.id} type={currentModal.type} show={currentModal.show} />
      )}
      <Confirmations
        id={confirmModal.id}
        type={confirmModal.type}
        show={confirmModal.show}
        selectedDate={selectedDate}
      />
    </ErrorBoundary>
  );
};

export default Appointments;
