import { useQueryClient } from '@tanstack/react-query';
import { transformData } from 'components/shared/Forms/Custom/lib/aggregatedDataHelper';
import { createFamilyHistoryNarrativeV2 } from 'components/shared/Forms/Custom/lib/familyHistoryNarrativeHelper';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, isEmpty, stripHtmlTags } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import React from 'react';
import { useParams } from 'react-router-dom';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import FamilyHistorySimpleTable from './FamilyHistorySimpleTable';
import { filterTodayOnly, formatData } from '../../lib/renderedHelper';
import { useRecoilValue } from 'recoil';
import practiceState from 'components/practice/practiceState';
import { clinicalNote as clinicalNoteState } from 'components/state';

const FamilyHistory = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData } = customClinicalNote ?? useClinicalNoteContext();
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const { isNarrativeChecked, isTemplateChecked, isTodaysDataOnlyChecked } = checkedFormTypes;
  const { timezone } = useRecoilValue(practiceState.currentPractice);

  const { id: patientId } = useParams();
  const patient = patientId ?? String(clinicalNote?.user_id);

  const data = overviewData?.familyHistory;

  const queryClient = useQueryClient();
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'familyHistory',
        patientId: patient
      },
      dependencies: [patient, 'familyHistory'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          queryClient.setQueryData(['patientAggregateNarrative', patient, 'familyHistory'], () => ({
            data: {
              narrative: {
                narrative: createFamilyHistoryNarrativeV2(
                  transformData({
                    data,
                    shouldMap: false,
                    validation: 'medicalCondition'
                  })
                ),
                synced: true
              }
            }
          }));
        }
      }
    });

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (customClinicalNote && !ia(data)) {
    return null;
  }

  const tableData = isTemplateChecked ? formatData(data) : [];

  const filteredData = isTodaysDataOnlyChecked
    ? formatData(filterTodayOnly(timezone, data, 'createdAt'))
    : data;

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Family history</h3>

      {isTodaysDataOnlyChecked ? (
        <FamilyHistorySimpleTable data={filteredData} emptyState="No family history added today" />
      ) : null}
      {isTemplateChecked ? <FamilyHistorySimpleTable data={tableData} /> : null}

      {isNarrativeChecked && !!ia(data) && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <SanitizeComponent
            value={
              isEmpty(stripHtmlTags(patientNarrative?.narrative).trim())
                ? 'No known family history'
                : patientNarrative?.narrative
            }
          />
        </div>
      )}
    </div>
  );
};

export default FamilyHistory;
