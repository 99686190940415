import { useFormik } from 'formik';
import React from 'react';
import { getChangedTagFields, tagInitialValues, TagValidationSchema } from '../lib/helper';
import TagForm from './TagForm';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createTag, updateTag } from 'api/Tags';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { mapValues } from 'lib/helpers/utility';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { userState } from 'components/state';
import { useRecoilValue } from 'recoil';

const TagModal = ({ tag = {}, isOpen = false, handleClose = () => {} }) => {
  const currentUser = useRecoilValue(userState);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { limit, page, filters, sort } = useTableContext();
  const { mutateAsync: mutateCreateTag } = useMutation({
    mutationFn: (data) => createTag(navigate, data)
  });

  const { mutateAsync: mutateUpdateTag } = useMutation({
    mutationFn: (data) => updateTag(navigate, data)
  });

  const formik = useFormik({
    initialValues: tagInitialValues(tag),
    validationSchema: TagValidationSchema,
    onSubmit: async (values) => {
      let queryKeys = ['allTags', limit, page, sort, mapValues(filters)];
      if (tag.id) {
        const changedFields = getChangedTagFields(formik.initialValues, formik.values);
        await mutateUpdateTag(
          {
            tagId: tag?.id,
            changedFields
          },
          {
            onSuccess: ({ code, tag }) => {
              if (code === 0) {
                let queryToInvalidate;
                let kind = tag?.kind;
                if (tag?.kind === 'chart') {
                  queryToInvalidate = 'patients';
                } else if (tag?.kind === 'fax') {
                  queryToInvalidate = 'fax';
                } else {
                  queryToInvalidate = 'appointment';
                }
                if (queryToInvalidate === 'fax') {
                  queryClient.refetchQueries(['archivedFaxes']);
                  queryClient.refetchQueries(['draftFaxes']);
                  queryClient.refetchQueries(['inboundFaxes']);
                  queryClient.refetchQueries(['outboundFaxes']);
                } else {
                  queryClient.refetchQueries([queryToInvalidate]);
                }
                queryClient.refetchQueries(['tags', kind]);
                queryClient.setQueryData(queryKeys, (oldData) => {
                  try {
                    let newTags = oldData?.tags?.map((t) => {
                      if (t?.id !== parseInt(tag.id)) {
                        return t;
                      } else {
                        return tag;
                      }
                    });
                    return {
                      ...oldData,
                      tags: newTags
                    };
                  } catch (error) {
                    showAlert({
                      title: 'Tags update failed',
                      message: error ?? 'Something went wrong',
                      color: 'danger'
                    });
                  }
                });
                showAlert({ message: 'Tag updated successfully!', color: 'success' });
                handleClose();
              } else {
                showAlert({ message: 'Tag update failed!', color: 'danger' });
              }
            }
          }
        );
      } else {
        await mutateCreateTag(
          { ...values },
          {
            onSuccess: ({ code, tag }) => {
              if (code === 0) {
                queryClient.refetchQueries(['tags']);
                queryClient.setQueryData(queryKeys, (oldData) => {
                  try {
                    let addedTag = { ...tag, user: currentUser, created_at: new Date() };
                    let newTags = oldData?.tags ? [...oldData.tags] : [];
                    const updatedTags = [addedTag, ...newTags];

                    return {
                      ...oldData,
                      tags: updatedTags
                    };
                  } catch (error) {
                    showAlert({
                      title: 'Tags creation failed',
                      message: error ?? 'Something went wrong',
                      color: 'danger'
                    });
                  }
                });
                showAlert({ title: 'Tag created successfully!', color: 'success' });
                handleClose();
              } else {
                showAlert({ message: 'Tag creation failed!', color: 'danger' });
              }
            }
          }
        );
      }
    }
  });

  const handleChange = (key, value) => formik.setFieldValue(key, value);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      className="!h-[896px] w-[1160px] bg-primary-10"
      bodyClassName="z-50 mx-auto max-w-[700px] !justify-center"
      isFooter={false}
      isFooterSticky
      title={tag?.name ? tag?.name : 'New tag'}>
      <Icon icon="chat-bg-pattern" className="absolute left-0 top-0" />
      <TagForm formik={formik} handleChange={handleChange} />
      <ModalFooter
        className="z-50"
        leftButtons={<Button outlined text="Close" color="neutral" onClick={handleClose} />}
        rightButtons={
          <Button
            text={tag.id ? 'Save' : 'Create'}
            icon={tag.id ? 'new-diskette' : 'new-add-square'}
            iconColor="white"
            onClick={() => formik?.submitForm()}
            className="gap-[2px]"
          />
        }
      />
    </Modal>
  );
};

export default TagModal;
