import React, { useState } from 'react';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteForm } from 'api/Forms';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import ViewForm from './ViewForm';

const Actions = ({ data }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [formToView, setFormToView] = useState({});
  const [viewFormModal, setViewFormModal] = useState(false);

  const { setOpenModal, setBreadCrumbs, formik, setIsEdit, practiceId } = useFormBuilderContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateDeleteForm } = useMutation({
    mutationFn: (data) => deleteForm(navigate, data)
  });

  const handleEdit = () => {
    setBreadCrumbs([{ title: 'Builder', view: 'builder', active: true, isFinished: false }]);
    formik.setValues({ ...formik.values, form: data });
    setIsEdit(true);
    setOpenModal(true);
  };

  const handleDelete = async () => {
    const variables = { formId: data?.id, practice_id: practiceId };
    await mutateDeleteForm(variables, {
      onSuccess: ({ code }) => {
        if (code === 0) {
          queryClient.refetchQueries(['forms']);
          queryClient.refetchQueries(['customForm']);
          showAlert({ title: 'Form Deleted successfully', color: 'success' });
        } else {
          showAlert({ title: 'Form deletion failed', color: 'danger' });
        }
        setShowConfirmationModal(false);
      }
    });
  };

  const handleView = () => {
    setFormToView(data);
    setViewFormModal(true);
  };

  const options = [
    { label: 'View', onClick: handleView, icon: 'new-eye' },
    { label: 'Edit', onClick: handleEdit, icon: 'new-edit' },
    { label: 'Delete', onClick: () => setShowConfirmationModal(true), icon: 'trash' }
  ];
  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Form"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this form?"
          handleOpen={showConfirmationModal}
          handleContinue={handleDelete}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
      {viewFormModal && (
        <ViewForm
          form={formToView}
          setViewFormModal={setViewFormModal}
          viewFormModal={viewFormModal}
        />
      )}
    </>
  );
};

export default Actions;
