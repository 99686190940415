import React, { useState } from 'react';

import Button from '../../../../../../../shared/Buttons/Button';
import SignModal from '../../../../shared/Sign/SignModal';
import cs from 'classnames';
import moment from 'moment-timezone';

import { formatDateAndTimeZ, ia } from '../../../../../../../../lib/helpers/utility';
import { useDocumentSign } from '../../../../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../../practiceState';
import { userState } from '../../../../../../../state';
import { processData } from '../../../../shared/helpers/documentSignatureHelpers';

const modalPropType = (actionType) => {
  switch (actionType) {
    case 'order_referral':
      return 'Order Referral';
    case 'medical_prescription':
      return 'Medical Prescription';
    case 'lab_order':
      return 'Lab/Imaging Order';
    default:
      return null;
  }
};

const YourSignatureMedical = ({ formik, actionType = 'medical_prescription' }) => {
  const { id: actionId } = formik?.values || {};
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const practice = useRecoilValue(currentPractice);

  const user = useRecoilValue(userState);
  const fullName = `${user?.f_name} ${user?.l_name}`;

  const [temporarySignature, setTemporarySignature] = useState(null);

  let { data: documentData } = useDocumentSign({
    params: {
      documentIds: [actionId],
      documentTypes: [actionType],
      withRelations: { user: true }
    },
    dependencies: [actionId],
    options: {
      refetchOnMount: false,
      enabled: !!actionId,
      select: (response) => {
        return {
          hasSigned: response.data.find((row) => row.user_id == user.id)?.status === 'signed',
          signatures: processData(response)
        };
      }
    }
  });

  const { hasSigned = false, signatures = [] } = documentData || {};

  if (user?.id != formik?.values?.provider_id) {
    return null;
  }

  if (!hasSigned && !ia(signatures) && temporarySignature?.signature) {
    return (
      <div class="!mt-[6px] inline-flex w-full flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 !py-6">
        <div class="text-center text-[32px] text-black">
          <img className="size-32 object-contain" src={temporarySignature?.signature} />
        </div>
        <div class="flex flex-col items-center justify-start gap-1.5">
          <div class="text-center text-sm text-neutral-800">{fullName}</div>
          <div class="text-center text-sm text-neutral-800">
            {formatDateAndTimeZ(moment(), practice.timezone)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <p className="text-md font-400 text-neutral-900">Your Signature</p>
      {hasSigned ? (
        signatures?.map((row) => {
          return (
            <div className="!mt-3">
              <div class="!mb-2 text-base font-medium leading-normal text-neutral-800">
                {row.role}
              </div>
              <div
                class={cs(
                  'inline-flex w-full flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 py-6',
                  signatures?.length > 1 && 'w-[260px]'
                )}>
                <div class="text-center text-[32px] leading-snug text-black">
                  <img className="size-32 object-contain" src={row.signature} />
                </div>
                <div class="flex flex-col items-center justify-start gap-1.5">
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.signedBy}
                  </div>
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.initials}
                  </div>
                  <div class="text-center text-sm leading-snug text-neutral-800">
                    {row.date ? formatDateAndTimeZ(row?.date, practice?.timezone) : 'N/A'}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <Button
          text="Sign"
          icon="new-lock-2-opened"
          iconIsStroke
          type="secondary"
          size="small"
          className="!mt-1.5"
          onClick={() => setShowConfirmationModal(true)}
        />
      )}

      <SignModal
        setShowConfirmationModal={setShowConfirmationModal}
        showConfirmationModal={showConfirmationModal}
        confirmModalProps={{
          title: `Sign ${modalPropType(actionType)}`,
          message: `Are you sure you want to sign this ${modalPropType(actionType)}`,
          primaryBtnTxt: `Sign ${modalPropType(actionType)}`
        }}
        onlyReturnSignatureProps={true}
        documentTypes={[actionType]}
        returnedData={(data) => {
          setTemporarySignature(data);
          formik?.setValues({ ...formik.values, signatureData: data });
        }}
      />
    </div>
  );
};

export default YourSignatureMedical;
