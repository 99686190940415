import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import React from 'react';
import { getCurrentStep, getStepsLeft } from '../../lib/helpers';
import StepsChart from '../StepsChart/StepsChart';
import FormDetails from './components/FormDetails';
import FormComponents from './components/FormComponents';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const Review = ({ currentBreadcrumb }) => {
  const { breadCrumbs } = useFormBuilderContext();
  const currentBreadcrumbStep = getCurrentStep(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);
  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div>
        <StepsChart
          chartData={chartData}
          title="Form Review"
          description="Have a final check of your form before confirming."
          innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
        />
        <div className="p-4">
          <FormDetails />
          <FormComponents />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default Review;
