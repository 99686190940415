import React from 'react';

import Tippy from '@tippyjs/react';

import { TYPE_LABELS } from 'lib/constants/transaction/typeLabels';
import numOrDefault from 'lib/helpers/numOrDefault';
import { formatDate, formatDateAndTime, mString, snakeToTitleCase } from 'lib/helpers/utility';

import CardProcessor from 'components/shared/CardProcessor';
import Actions from 'components/shared/Transactions/components/Actions';

export const getColDefs = (timezone) => [
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 120
  },
  {
    field: 'practice.name',
    headerName: 'Practice Name'
  },
  {
    field: 'created_at',
    headerName: 'Date',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone)
  },
  {
    field: 'status',
    headerName: 'Status',
    valueFormatter: ({ value }) => snakeToTitleCase(value)
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: ({ value }) => TYPE_LABELS[value]
  },
  {
    field: 'cc_type',
    headerName: 'Card Type',
    cellRenderer: ({ value }) => (
      <div className="flex h-full items-center justify-center">
        <CardProcessor>{value}</CardProcessor>
      </div>
    )
  },
  { field: 'cc_number', headerName: 'Card Number (Last 4)' },
  {
    field: 'amount_paid_self',
    headerName: 'Amount',
    initialWidth: 260,
    cellRenderer: ({ value, data }) => {
      return (
        <Tippy
          className="tippy-dark"
          content={
            <>
              <p>Charge: {mString(numOrDefault(value) + numOrDefault(data?.amount_refunded))}</p>
              <p>Technology fee: {mString(numOrDefault(data?.surcharge_amount))}</p>
            </>
          }>
          <p>
            {mString(numOrDefault(value) + numOrDefault(data?.amount_refunded))}
            {!!data?.amount_refunded ? (
              <span className="text-red-600">
                &nbsp; (Refunded:
                <span className="font-bold"> -{mString(numOrDefault(data?.amount_refunded))}</span>)
              </span>
            ) : null}
          </p>
        </Tippy>
      );
    },
    aggFunc: 'sum',
    sortable: false
  },
  {
    field: 'cashier_name',
    headerName: 'Cashier'
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: (params) => (
      <Actions
        data={{
          ...params.data,
          isPatient: true
        }}
      />
    ),
    maxWidth: 70,
    cellClass: 'no-border',
    no_show_csv: true
  }
];

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: true
};

export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'always',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};

export const DEFAULT_FILTERS = {
  date: {
    values: null,
    type: 'date-range',
    title: 'Date of Transaction',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  status: {
    type: 'checkbox',
    options: ['started', 'cancelled', 'completed', 'initiated', 'voided', 'failed'],
    values: null,
    title: 'Status',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  type: {
    type: 'search',
    multiple: true,
    options: [
      {
        label: 'Card Present',
        value: 'CARD_PRESENT'
      },
      {
        label: 'In Progress',
        value: 'IN_PROGRESS'
      },
      {
        label: 'Amex',
        value: 'amex'
      },
      {
        label: 'Cash',
        value: 'cash'
      },
      {
        label: 'Online',
        value: 'ONLINE'
      },
      {
        label: 'Charge',
        value: 'charge'
      },
      {
        label: 'Check',
        value: 'check'
      }
    ],
    values: [],
    title: 'Type',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  amount: {
    min: 0,
    max: 1000,
    values: 0,
    type: 'range',
    title: 'Amount',
    preview: (values) => mString(values)
  },
  practice: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Practice Name',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
