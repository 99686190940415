import React from 'react';

import { ComponentParamsContext } from './ComponentParamsContext';

export const ComponentParamsProvider = ({ children, ...props }) => {
  return (
    <ComponentParamsContext.Provider value={{ ...props }}>
      {children}
    </ComponentParamsContext.Provider>
  );
};
