import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import state from './state';

const UserActivateProgress = () => {
  const [progress, setProgress] = useRecoilState(state.progressState);
  const [progressElements, setProgressElements] = useState(null);
  const location = useLocation();

  useEffect(() => {
    let newPEs = [];
    for (let i = 0; i < progress.total; i++) {
      newPEs.push(
        <div
          className={`w-1/${progress.total} border-5 border-solid ${i === progress.current - 1 ? 'border-primary-600' : 'border-primary-100'
            } m-1 rounded-md`}></div>
      );
    }
    setProgressElements(newPEs);
  }, [progress]);

  return (
    <div className="self-center mb-4 flex w-full flex-col justify-center">
      {!location.pathname.includes('error') && (
        <div className="flex w-full flex-row justify-evenly">{progressElements}</div>
      )}
      <div className="mt-[1.5rem] text-center text-neutral-600">
        Already have an account?{' '}
        <Link to="/login" className="text-primary-600">
          Login
        </Link>
      </div>
    </div>
  );
};

export default UserActivateProgress;
