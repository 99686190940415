import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import Icon from 'components/shared/Icon/Icon';
import { useChartExport } from 'lib/hooks/queries/patients/useChartExport';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import moment from 'moment';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export default function ChartExport({ selectedRows, show, setShow }) {
  const { data, isLoading, isFetching } = useChartExport({
    params: { selectedRows },
    dependencies: [selectedRows]
  });

  const exportCSV = async () => {
    const blob = new Blob([data?.csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `EHI-Export-${moment().format('YYYY-MM-DD')}.csv`;
    a.click();
  };

  const exportHTML = async () => {
    const htmlFiles = data?.html;

    if (htmlFiles?.length === 1) {
      const file = htmlFiles[0];

      const htmlBlob = new Blob([file?.content], { type: 'text/html' });

      saveAs(htmlBlob, `${file?.fileName}`);
    } else {
      const zip = new JSZip();

      htmlFiles?.forEach((file) => {
        zip.file(file?.fileName, file?.content);
      });

      const zipBlob = await zip.generateAsync({ type: 'blob' });

      saveAs(zipBlob, `EHI-Export-${moment().format('YYYY-MM-DD')}.zip`);
    }
  };

  return (
    <>
      <Modal
        icon="list-unordered"
        title="Export EHI data"
        handleOpen={show}
        handleClose={() => setShow(false)}>
        <div>
          {isLoading || isFetching ? (
            <Skeleton count={1} />
          ) : (
            <>
              <div
                onClick={exportCSV}
                className="mt-3 flex cursor-pointer items-center justify-between !rounded-lg border !border-primary-100 bg-primary-50 !p-2 !px-4 hover:bg-primary-100 ">
                <p className="text-base font-medium text-primary-900">Generate CSV file</p>
                <Icon className="cursor-pointer" icon="file-csv"></Icon>
              </div>
              <div
                onClick={exportHTML}
                className="mt-3 flex cursor-pointer items-center justify-between !rounded-lg border !border-primary-100 bg-primary-50 !p-2 !px-4 hover:bg-primary-100 ">
                <p className="text-base font-medium text-primary-900">Generate HTML file</p>
                <Icon className="cursor-pointer" icon="file-html"></Icon>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}
