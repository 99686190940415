import { capitalize } from 'lodash';
import endsOptions from './endsOptions';
import repeatEventOptions from './repeatEventOptions';
import { ia, iaRa } from '../../../../../lib/helpers/utility';
import moment from 'moment';

export default function getEventFromInitialValues({ event, selectedDateTime, timezone }) {
  let startDateTime = moment.tz(new Date(), timezone).toDate();
  let endDateTime = moment.tz(new Date(), timezone).add(1, 'hour').toDate();

  if (selectedDateTime?.start && selectedDateTime?.endDateTime) {
    const startDate = new Date(selectedDateTime.start);
    const endDate = new Date(selectedDateTime.endDateTime);

    if (startDate.getDate() !== endDate.getDate()) {
      endDate.setDate(startDate.getDate());
      selectedDateTime.endDateTime = endDate.toISOString();
      startDate.setHours(8, 30);
      selectedDateTime.start = startDate.toISOString();

      endDate.setHours(9, 0);
      selectedDateTime.endDateTime = endDate.toISOString();
    }
  }

  return {
    title: event?.title || '',
    all_day: event?.all_day || false,
    start_date: event?.start_date
      ? new Date(event?.start_date)
      : new Date(selectedDateTime?.start || startDateTime),
    until: event?.until
      ? new Date(event?.until)
      : new Date(selectedDateTime?.endDateTime || selectedDateTime?.start || endDateTime),
    repeat_event: event
      ? repeatEventOptions.find((it) => it.value == event?.repeat_event)
      : repeatEventOptions[0],
    repeat_every: event?.repeat_every || 1,
    ends_type: event?.ends_type ? endsOptions.find((it) => it.value === event.ends_type) : null,
    end_date: event?.end_date ? new Date(event?.end_date) : new Date(),
    repeat_on: event?.repeat_on || null,
    events: event?.events || 1,
    description: event?.description || '',
    weekdays: ia(event?.weekdays)
      ? event?.weekdays.map((day) => ({ label: capitalize(day.charAt(0)), value: day }))
      : [],
    practitioner_ids: iaRa(event?.practitioner_ids).map((item) => ({
      ...item,
      label: item.name,
      value: item.id
    })),
    color: event?.color || '#13B9FF',
    type: null
  };
}
