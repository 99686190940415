import React from 'react';

import Typography from '../Typography/Typography';

const StatusBarAggregation = ({ data }) => {
  return (
    <div className="flex items-center justify-center gap-2">
      <Typography variant="body1" weight="font-bold">
        Selected Services:
      </Typography>
      <Typography variant="body1">{data.length}</Typography>
    </div>
  );
};

export default StatusBarAggregation;
