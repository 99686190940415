import React from 'react';
import Select from 'components/shared/Select/Select';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import { getHours } from '../../../../lib/getHours';

const Time = ({ currentPractice, className }) => {
  const { values, setFieldValue } = useFormikContext();
  const currentTime = moment.tz(values.starts_at, currentPractice?.timezone).format('hh:mm A');
  const currentValue = { value: currentTime, label: currentTime };
  const hours = getHours(currentPractice);
  const options = hours.map((time) => ({ value: time, label: time }));
  const diffMin = moment(values?.ends_at).diff(moment(values?.starts_at), 'minutes');
  const isAptDisabled = values.appointment_disabled;

  const handleTimeChange = (selectedOption) => {
    const [timeString, period] = selectedOption.value.split(' ');
    const [hour, minute] = timeString.split(':');
    const date = moment
      .tz(values.starts_at, currentPractice?.timezone)
      .hour(period === 'PM' ? (parseInt(hour) % 12) + 12 : parseInt(hour) % 12)
      .minute(parseInt(minute));
    const dateUTC = date.utc().format();
    setFieldValue('starts_at', dateUTC);
    setFieldValue(
      'ends_at',
      moment.tz(dateUTC, currentPractice?.timezone).add(diffMin, 'm').format()
    );
  };

  return (
    <Select
      parentClassName={className}
      label="Time"
      isClearable={false}
      rightIcon="new-clock-gray"
      options={options}
      value={currentValue}
      onChange={(val) => handleTimeChange(val)}
      disabled={isAptDisabled}
    />
  );
};

export default Time;
