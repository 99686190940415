import { Honeybadger } from '@honeybadger-io/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import { ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import ErrorRequirements from 'components/shared/Error/Requirements';
import PractitionerLicenseManager from 'components/shared/PractitionerLicense/Manager';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';

const PrescriptionManagement = () => {
  const { practitionerId, practitioner: loadedPractitioner, practices } = useOutletContext();
  const [practitioner, setPractitioner] = useState({ user_id: 0, veradigm_user_id: '' });
  const [show, setShow] = useState({ loading: false, breakLink: false, breakLinkLoading: false, practiceId: null });
  const [requirements, setRequirements] = useState([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (loadedPractitioner) {
      setPractitioner(loadedPractitioner);
    } else if (practitionerId) {
      loadPractitioner();
    }
  }, [_]);

  const enrollPractitioner = async (practiceId) => {
    try {
      const onSuccess = ({ veradigm_user_guid, requirements: loadedRequirements }) => {
        if (ia(loadedRequirements)) setRequirements(loadedRequirements);
        setPractitioner({ ...practitioner, veradigm_user_id: veradigm_user_guid });
        showAlert({ title: `Successfully enrolled user! GUID: ${veradigm_user_guid}` });
        setShow((ps) => ({ ...ps, loading: false }));
      };
      const onError = (error, code) => {
        if (code === 3) {
          if (ia(loadedRequirements)) setRequirements(loadedRequirements);
        }
        if (error) showAlert({ title: error, color: 'danger' });
        setShow((ps) => ({ ...ps, loading: false }));
      };
      setShow((ps) => ({ ...ps, loading: true }));
      await requestApi({
        url: '/api/prescribe/enrollUser',
        params: {
          userId: practitioner.id,
          practiceId: practiceId
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: practitioners/PerscriptionManagement, method: enrollPractitioner - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const loadPractitioner = async () => {
    try {
      const onSuccess = ({ practitioner: apiLoadedPractitioner }) => {
        setPractitioner(apiLoadedPractitioner);
        queryClient.invalidateQueries({ queryKey: ['practitioner'] });
      };
      const onError = (error) => {
        if (error) showAlert({ title: error, color: 'danger' });
      };
      await requestApi({
        url: '/api/practitioner/get',
        params: { practitioner_id: practitionerId, comment: 'a0' },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: practitioners/PrescriptionManagement, method: loadPractitioner - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const breakLink = useCallback(async () => {
    try {
      const onSuccess = () => {
        setShow((ps) => ({ ...ps, breakLink: false, breakLinkLoading: false, practiceId: null }));
        queryClient.invalidateQueries({ queryKey: ['practitioner'] });
      };

      const onFail = () => {
        setShow((ps) => ({ ...ps, breakLink: false, breakLinkLoading: false, practiceId: null }));
        queryClient.invalidateQueries({ queryKey: ['practitioner'] });
      }

      setShow((ps) => ({ ...ps, breakLink: true, breakLinkLoading: true }));

      await requestApi({
        url: '/api/prescribe/deleteUser',
        params: {
          userId: practitioner?.id,
          practiceId: show.practiceId
        },
        navigate,
        onSuccess,
        onFail
      });
    } catch (e) {
      console.error(e);
      Honeybadger.notify(
        `file: practitioners/PrescriptionManagement, method: breakLink - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  },[show, practitioner]);

  return (
    <div className="m-5 flex flex-col">
      <PractitionerLicenseManager practitionerId={practitionerId} />
      <hr />
      <h3 className="mb-3 text-lg font-semibold">Practices</h3>
      <p className="p-3">
        Practices this practitioner is enrolled in ePrescribe with. Please make sure all information
        is correct before proceeding with enrollment of a practitioner.
      </p>
      <ErrorRequirements
        requirements={requirements}
        title="Note: ePrescribe Requirements not met, please fulfill the requirements to enroll this user."
      />
      <div className="max-h-80 overflow-auto">
        <table className="primary-table">
          <thead>
          <tr>
            <td>ID</td>
            <td>Name</td>
            <td>Role Name</td>
            <td>Veradigm User Type</td>
            <td>ePrescribe Enrolled</td>
          </tr>
          </thead>
          <tbody>
          {ia(practices) ? (
            practices.map((v) => (
              <tr key={v.id}>
                <td className="p-3">
                  <Link to={`/admin/practices/${v.id}`}>{v.id}</Link>
                </td>
                <td>
                  <Link to={`/admin/practices/${v.id}`}>{v.name}</Link>
                </td>
                <td>
                  {v?.practiceUser?.role?.name ? v?.practiceUser?.role?.name : 'Not yet set! ⚠️'}
                </td>
                <td>
                  {v?.practiceUser?.role?.veradigm_user_type
                    ? v?.practiceUser?.role?.veradigm_user_type
                    : 'Not yet set! ⚠️'}
                </td>
                <td>
                  {v?.practiceUser?.veradigm_user_id ? (
                    <div className={'flex flex-row items-center gap-2'}>
                      <div>
                        Already Enrolled
                      </div>
                      <Button text="Break Link" color={'danger'} outlined onClick={() => setShow(ps => ({
                        ...ps,
                        breakLink: true,
                        practiceId: v?.practiceUser?.practice_id
                      }))} />
                    </div>
                  ) : (
                    <Button text="Enroll" loading={show.loading}
                            onClick={() => enrollPractitioner(v?.practiceUser?.practice_id)} />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No Practices Found</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
      <Confirm handleOpen={show.breakLink} title={'Confirm Breaking Link with Veradgim?'}
               message={'This change is permanent and cannot be reversed. Please make sure to break the link on Veradigm side as well.'}
               handleClose={() => setShow((ps) => ({ ...ps, breakLink: false }))} handleContinue={breakLink} loading={show.breakLinkLoading} />
      {/*<Modal isOpen={show.breakLink} ></Modal>*/}
      {/* {!practitioner.veradigm_user_id && (
        <Button success loading={show.loading} onClick={enrollPractitioner}>
          Enroll Practitioner
        </Button>
      )} */}
    </div>
  );
};

export default PrescriptionManagement;
