import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const Layout = ({ leftChild = null, rightChild = null }) => {
  return (
    <div className="h-full">
      <div className="dashed-top" />
      <div className="grid h-full grid-cols-2 gap-6">
        {leftChild}
        <div className="absolute left-1/2 top-[61px] flex h-[calc(100%-61px)] w-[24px] -translate-x-1/2 justify-center bg-white">
          <Icon icon="new-arrow-left" className="rotate-180" />
        </div>
        {rightChild}
      </div>
    </div>
  );
};

export default Layout;
