import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';

import { activateAdminUser, approveAdminUser, deactivateAdminUser } from 'api/Admin/Users';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Popover from 'components/shared/Popovers/Popover/Popover';

function getActions(statuses) {
  const uniqueStatuses = Array.from(new Set(statuses));

  if (
    uniqueStatuses.length > 2 ||
    (uniqueStatuses.includes('inactive') && uniqueStatuses.includes('pending')) ||
    (uniqueStatuses.includes('inactive') && uniqueStatuses.includes('active'))
  ) {
    return [];
  }

  if (uniqueStatuses.includes('pending') && uniqueStatuses.includes('active')) {
    return ['deactivate'];
  }

  if (uniqueStatuses.length === 1) {
    if (uniqueStatuses[0] === 'pending') {
      return ['deactivate', 'approve'];
    }
    if (uniqueStatuses[0] === 'active') {
      return ['deactivate'];
    }
    if (uniqueStatuses[0] === 'inactive') {
      return ['activate'];
    }
  }

  return [];
}

const BulkAdminActions = ({ selectedRows }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const usersStatusData = selectedRows.map((row) => row.data.status);
  const userIds = selectedRows.map((row) => row.data.id);

  const possibleActions = getActions(usersStatusData);

  const disabled = selectedRows.length == 0 || possibleActions.length === 0;

  const onDeactivateUsers = () => {
    deactivateMutate.mutate();
  };

  const onApproveUsers = () => {
    approveUserMutate.mutate();
  };

  const onActivateUsers = () => {
    activateUserMutate.mutate();
  };

  const deactivateMutate = useMutation({
    mutationFn: () => {
      return deactivateAdminUser(navigate, {
        userIds
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admins deactivated successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong deactivating admins',
          color: 'danger'
        });
      }
    }
  });

  const approveUserMutate = useMutation({
    mutationFn: () => {
      return approveAdminUser(navigate, {
        userIds
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admins approved successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong approving admins',
          color: 'danger'
        });
      }
    }
  });

  const activateUserMutate = useMutation({
    mutationFn: () => {
      return activateAdminUser(navigate, {
        userIds
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admins activated successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong activating admins',
          color: 'danger'
        });
      }
    }
  });

  const popupActionsOptions = [
    ...(possibleActions.includes('approve')
      ? [
          {
            label: 'Approve',
            icon: 'tick-circle',
            onClick: onApproveUsers,
            permission: { requiredPermissions: ['admin_users.approve'] }
          }
        ]
      : []),
    ...(possibleActions.includes('activate')
      ? [
          {
            label: 'Activate',
            icon: 'tick-circle',
            onClick: onActivateUsers,
            permission: { requiredPermissions: ['admin_users.activate'] }
          }
        ]
      : []),
    ...(possibleActions.includes('deactivate')
      ? [
          {
            label: 'Deactivate',
            icon: 'trash',
            onClick: onDeactivateUsers,
            permission: { requiredPermissions: ['admin_users.delete'] }
          }
        ]
      : [])
  ];

  return (
    <>
      {disabled ? (
        <Tippy
          className="tippy-dark"
          content={
            selectedRows.length == 0
              ? 'Select at least one admin row to enable actions'
              : possibleActions.length === 0
                ? 'Please select some combination to take action for all users'
                : null
          }
          placement="top">
          <div>
            <Button
              icon="three-dots"
              outlined
              color="neutral"
              iconSize={16}
              className="!h-[30px] !w-[38px]"
              disabled={disabled}
            />
          </div>
        </Tippy>
      ) : (
        <Popover
          buttonClassName="border !border-solid !min-h-[30px] !h-[30px] !w-[38px] border-neutral-200"
          disabled={disabled}
          options={popupActionsOptions}
        />
      )}
    </>
  );
};

export default BulkAdminActions;
