import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa, mapValues } from 'lib/helpers/utility';
import { usePracticeCPTCodes } from 'lib/hooks/queries/cptCodes/useCPTCodes';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';
import useModal from 'lib/hooks/useModal';

import ServiceModal from 'components/practice/settings/Services/components/ServiceModal';
import AGTable from 'components/shared/AGTable/AGTable';

import { CustomStatusBarCount } from '../AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from '../AGTable/CustomStatusBarPagination';

import StatusBarAggregation from './StatusBarAggregation';
import Header from './components/Header';
import {
  COLUMN_DEFS,
  DEFAULT_COLUMN_DEFS,
  GRID_OPTIONS,
  defaultFilters,
  restructureScheduleOfFees
} from './lib/helpers';

const ScheduleOfFees = forwardRef((props, ref) => {
  const { data: professionGroupOptions } = useProfessionGroups();
  const professions = professionGroupOptions?.professions || [];

  const initialFilters = defaultFilters(professions);

  return (
    <TableContextProvider
      defaultFilters={initialFilters}
      cols={COLUMN_DEFS}
      name="services"
      onSelect={props.onSelect}
      pagination>
      <ScheduleOfFeesTable ref={ref} {...props} />
    </TableContextProvider>
  );
});

const ScheduleOfFeesTable = forwardRef(({ hasNew = false, defaultServices = [] }, ref) => {
  const { isOpen, openModal, closeModal } = useModal();
  const { gridRef, limit, page, sort, setPage, filters } = useTableContext();
  const [currentSelectedRows, setCurrentSelectedRows] = useState(defaultServices);

  const queryFilters = useMemo(() => {
    return mapValues(filters);
  }, [filters]);

  const { data = {}, isLoading } = usePracticeCPTCodes({
    params: { page, sort, limit, withCount: true, filters: queryFilters, all: true },
    dependencies: [page, sort, limit, queryFilters]
  });

  const feeOfSchedules = useMemo(() => restructureScheduleOfFees(iaRa(data?.cpt)), [data]);
  const count = data?.count || 0;

  useEffect(() => {
    if (gridRef.current.api && currentSelectedRows.length > 0) {
      const selectedIds = currentSelectedRows.map((row) => row.procedure_code);
      gridRef.current.api.forEachNode((node) => {
        if (selectedIds.includes(node.data?.procedure_code)) {
          node.setSelected(true);
        }
      });
    }
  }, [feeOfSchedules, gridRef, gridRef?.current?.api, page, currentSelectedRows, defaultServices]);

  useImperativeHandle(ref, () => ({
    selectedRows: currentSelectedRows
  }));

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: feeOfSchedules,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: StatusBarAggregation,
          statusPanelParams: {
            data: currentSelectedRows
          },
          align: 'center'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: feeOfSchedules,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [feeOfSchedules, count, currentSelectedRows, page, limit, onPageChange]);

  const onSelectionChanged = useCallback(() => {
    if (!gridRef.current.api) return;
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedCodes = new Set(selectedNodes.map((node) => node.data.procedure_code));

    setCurrentSelectedRows((prev) => {
      const codesOnCurrentPage = new Set(feeOfSchedules.map((fee) => fee.procedure_code));
      const existingCodes = new Set(prev.map((row) => row?.procedure_code));
      const filteredRows = prev.filter((row) => {
        const code = row?.procedure_code;
        return !codesOnCurrentPage.has(code) || selectedCodes.has(code);
      });

      const newNodes = selectedNodes
        .map((node) => node.data)
        .filter((node) => !existingCodes.has(node.procedure_code));

      return [...filteredRows, ...newNodes];
    });
  }, [gridRef, setCurrentSelectedRows, feeOfSchedules]);

  return (
    <div className="flex h-full w-full grow flex-col gap-4 p-0">
      <Header category="services" onOpenModal={openModal} data={feeOfSchedules} hasNew={hasNew} />
      <AGTable
        data={feeOfSchedules}
        gridOptions={GRID_OPTIONS}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        loading={isLoading}
        rowSelection="multiple"
        getRowId={(row) => row.data.procedure_code}
        onSelectionChanged={onSelectionChanged}
        statusBar={statusBar}
      />
      {isOpen && <ServiceModal isOpen={isOpen} onClose={closeModal} />}
    </div>
  );
});

export default ScheduleOfFees;
