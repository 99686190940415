import React, { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';

import { upsertCustomFormResponse } from 'api/CustomForms';
import { showAlert } from 'components/shared/Alert/Alert';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import FamilyHistoryRow from 'components/shared/Forms/Custom/FamilyHistory/FamilyHistoryRow';
import UpsertModal from 'components/shared/Forms/Custom/Provider/components/UpsertModal';
import Allowed from 'components/shared/Permissions/Allowed';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { FamilyHistory as initialValue } from '../../../../../components/shared/Forms/Custom/CustomFormInitialStates';

const FamilyHistorySingle = ({ item, openItemModal }) => {
  const isNoDetailsTrue = !!item?.original?.noDetails;

  return (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      {isNoDetailsTrue ? (
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No notable family history</p>
          <Icon className="cursor-pointer" icon="new-chevron-right" />
        </div>
      ) : (
        <List
          items={item?.list}
          dividerType="none"
          renderItemComponent={(listItem, index) => {
            return (
              <>
                <div className="flex items-center justify-between gap-2">
                  <p className="break-all font-medium text-neutral-800">
                    {listItem?.row?.map((item) => item?.medicalCondition?.label).join(', ')}
                  </p>
                  {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
                </div>
              </>
            );
          }}
        />
      )}

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function FamilyHistory(props) {
  const { customData, layout, patientId } = props;
  const familyHistory = customData?.familyHistory ? customData?.familyHistory : null;

  const defaultSize = useMemo(
    () => layout.find((l) => l.i === props.item.i)?.h,
    [familyHistory, layout]
  );

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () =>
      limitItems(familyHistory, defaultSize, {
        description: 0.15,
        createdAt: 0.2,
        group: 0.11,
        item: 0.3
      }),
    [familyHistory, defaultSize]
  );

  const [itemModal, setItemModal] = useState(null);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    formik.setValues(item?.original?.row[0]);
    setItemModal(item);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue?.row[0],
    enableReinitialize: true,
    validationSchema: Yup.object({
      medicalCondition: Yup.object().required('Medical Condition is required')
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const result = await upsertCustomFormResponse(navigate, {
        formId: itemModal?.id,
        patientId,
        response: { row: [values] },
        type: 'familyHistory'
      });

      if (result?.newResponse) {
        showAlert({ title: 'Successfully saved' });
        setItemModal();
        queryClient.invalidateQueries(['customFormResponse']);
        resetForm();
      }
      setLoading(false);
    }
  });

  const handleOnChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        onPlusClick={() => setItemModal({})}
        icon="history"
        addPermissions="patients_medical_history.update"
        {...props}></WidgetHeader>
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          <FooterWrapper
            hasMore={hasMore}
            onClick={() => setShow(true)}
            text={`Show all Family History (${count})`}>
            {items ? (
              <List
                items={items}
                renderItemComponent={(item, index) => (
                  <FamilyHistorySingle openItemModal={openItemModal} item={item} />
                )}
              />
            ) : (
              <div className="p-[20px] py-3 text-center">
                <p className="font-medium text-neutral-500">No Family History</p>
              </div>
            )}
          </FooterWrapper>
        </div>

        <Modal
          handleOpen={show}
          title="Family History"
          slideFromRight
          className="w-[1020px] bg-white"
          handleClose={() => setShow(false)}>
          {familyHistory ? (
            <List
              items={familyHistory}
              renderItemComponent={(item, index) => (
                <FamilyHistorySingle openItemModal={openItemModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Family History</p>
            </div>
          )}
        </Modal>

        <UpsertModal
          showModal={!!itemModal}
          title="Family History"
          loading={loading}
          formik={formik}
          id={itemModal?.id}
          hideModal={() => {
            formik.resetForm();
            setItemModal(null);
          }}>
          <FamilyHistoryRow formik={formik} onChange={handleOnChange} {...formik.values} />
        </UpsertModal>
      </Allowed>
    </div>
  );
}
