import React from 'react';

import { useComponentParamsContext } from 'lib/context/ComponentParamsContext/ComponentParamsContext';

import Icon from 'components/shared/Icon/Icon';

const Actions = ({ data }) => {
  const { onDelete } = useComponentParamsContext();

  return (
    <div className="flex items-center justify-center h-full">
      <Icon icon="trash" size={14} onClick={() => onDelete(data)} />
    </div>
  );
};

export default Actions;
