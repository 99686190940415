export default function getDiagnosesWhenNoClaim(superbill) {
  const diagnoses = {
    diag_1: '',
    diag_2: '',
    diag_3: '',
    diag_4: '',
    diag_5: '',
    diag_6: '',
    diag_7: '',
    diag_8: '',
    diag_9: '',
    diag_10: '',
    diag_11: '',
    diag_12: ''
  };

  const icd_10_codes = superbill?.encounter?.icd_10_codes;

  if (Array.isArray(icd_10_codes)) {
    const maxDiagnoses = Math.min(icd_10_codes.length, 12);

    for (let i = 0; i < maxDiagnoses; i++) {
      diagnoses[`diag_${i + 1}`] = icd_10_codes[i];
    }
  }

  return diagnoses;
}
