import { currentPractice } from 'components/practice/practiceState';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';
import { formatDate, mString } from 'lib/helpers/utility';
import React, { useCallback, useRef } from 'react';
import { useRecoilValue } from 'recoil';

const DEFAULT_COL_DEFS = { enableRowGroup: true, resizable: true };

const getColDefs = (timezone) => [
  {
    field: 'id',
    headerName: 'Invoice ID',
    maxWidth: 150,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'agGroupCellRenderer'
  },
  {
    field: 'created_at',
    headerName: 'Invoice Date',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    maxWidth: 150
  },
  {
    field: 'appointment.starts_at',
    headerName: 'DOS',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    maxWidth: 130
  },
  {
    field: 'patient.fullName',
    headerName: 'Patient'
  },
  {
    field: 'total_amount',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'invoice_balance',
    headerName: 'Balance',
    aggFunc: 'sum',
    valueFormatter: ({ value }) => mString(value || 0)
  }
];

const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true
};

const NewInvoiceTable = ({ invoices, setGroupSelectedRows = () => {} }) => {
  const gridRef = useRef();
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    setGroupSelectedRows(selectedRows);
  }, [setGroupSelectedRows]);

  const onFirstDataRendered = useCallback((params) => {
    const nodesToSelect = [];
    params.api.forEachNode((node) => {
      nodesToSelect.push(node);
    });
    params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  }, []);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden !p-3 !pb-2">
      <AGBasicTable
        gridRef={gridRef}
        columnDefs={getColDefs(practiceTimezone)}
        defaultColDef={DEFAULT_COL_DEFS}
        animateRows={true}
        rowData={invoices}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        customClassName="ag-grid-interactive"
        gridOptions={GRID_OPTIONS}
        onSelectionChanged={onSelectionChanged}
        onFirstDataRendered={onFirstDataRendered}
      />
    </div>
  );
};

export default NewInvoiceTable;
