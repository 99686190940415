import React from 'react';

import Input from 'components/shared/Input/Input';
import Typography from 'components/shared/Typography/Typography';

const LeftInputs = ({ procedure, handleInputChange }) => {
  return (
    <div className="flex flex-col gap-2 flex-1">
      <Input
        label="Prior Auth / CLIA:"
        placeholder="Prior Auth / CLIA"
        name="chg_prior_auth"
        value={procedure?.chg_prior_auth}
        onChange={handleInputChange}
      />
      <div className="flex flex-col gap-1 mt-2">
        <Typography weight="500" color="neutral-800">
          24. ORDERING PROVIDER (L,F,M)
        </Typography>
        <div className="flex gap-1">
          <Input
            placeholder="L"
            name="ord_prov_name_l"
            value={procedure?.ord_prov_name_l}
            onChange={handleInputChange}
          />
          <Input
            placeholder="F"
            name="ord_prov_name_f"
            value={procedure?.ord_prov_name_f}
            onChange={handleInputChange}
          />
          <Input
            placeholder="M"
            name="ord_prov_name_m"
            value={procedure?.ord_prov_name_m}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <Input
        label="NPI"
        placeholder="NPI"
        name="ord_prov_npi"
        value={procedure?.ord_prov_npi}
        onChange={handleInputChange}
      />
      <Input
        label="Address 1"
        placeholder="Address 1"
        name="ord_prov_addr_1"
        value={procedure?.ord_prov_addr_1}
        onChange={handleInputChange}
      />
      <Input
        label="Address 2"
        placeholder="Address 2"
        name="ord_prov_addr_2"
        value={procedure?.ord_prov_addr_2}
        onChange={handleInputChange}
      />
      <div className="flex gap-1">
        <Input
          label="City"
          placeholder="City"
          name="ord_prov_city"
          value={procedure?.ord_prov_city}
          onChange={handleInputChange}
        />
        <Input
          label="St"
          placeholder="St"
          name="ord_prov_state"
          value={procedure?.ord_prov_state}
          onChange={handleInputChange}
        />
        <Input
          label="Zip"
          placeholder="Zip"
          name="ord_prov_zip"
          value={procedure?.ord_prov_zip}
          onChange={handleInputChange}
        />
      </div>
      <Input
        label="Ordering Prov. Contact"
        placeholder="Ordering Prov. Contact"
        name="ord_prov_contact_name"
        value={procedure?.ord_prov_contact_name}
        onChange={handleInputChange}
      />
      <Input
        label="Ordering Prov. Phone"
        placeholder="Ordering Prov. Phone"
        name="ord_prov_contact_phone"
        value={procedure?.ord_prov_contact_phone}
        onChange={handleInputChange}
      />
      <Input
        label="Ordering Prov. Fax"
        placeholder="Ordering Prov. Fax"
        name="ord_prov_contact_fax"
        value={procedure?.ord_prov_contact_fax}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default LeftInputs;
