import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { createGuarantor as createGuarantorReq } from 'api/PatientGuarantor';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';

import GuarantorForms from './components/GuarantorForms';
import { GuarantorValidationSchema } from './lib/helpers';

const NewGuarantor = ({
  patientId,
  showNewGuarantor,
  hideNewGuarantor,
  addGuarantor,
  practiceId,
  states
}) => {
  const { device } = useUIContext();
  const createGuarantor = useRef();
  const { id } = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const [confirmClose, setConfirmClose] = useState(false);

  const isTablet = device === 'tablet';

  const { mutateAsync: createNewGuarantor, isLoading: loading } = useCustomMutation({
    mutationFn: (guarantor) => createGuarantorReq(guarantor, navigate),
    successMessage: 'Successfully created guarantor!',
    onSuccess: (data) => {
      if (data?.created) {
        addGuarantor(data?.created);
      }

      hideNewGuarantor();
    }
  });

  const formik = useFormik({
    initialValues: {
      patient_id: patientId,
      practice_id: id || practiceId,
      f_name: '',
      l_name: '',
      m_name: '',
      email: '',
      gender: '',
      dob: '',
      home_phone: '',
      work_phone: '',
      relationship: '',
      guarantorAddress: {
        address_ln_1: '',
        address_ln_2: '',
        city: '',
        state: null,
        zip: ''
      }
    },
    validationSchema: GuarantorValidationSchema,
    onSubmit: async (values) => {
      await createNewGuarantor(values);
    }
  });

  const handleModalClose = () => {
    hideNewGuarantor();
  };

  return (
    <>
      <Modal
        handleOpen={showNewGuarantor}
        handleClose={handleModalClose}
        className={cs(isTablet ? 'h-full w-full !rounded-none' : 'w-[612px] !rounded-none')}
        title="Create new guarantor"
        slideFromRight={!isTablet}
        footer={
          <div className="flex w-full justify-between" data-dd-privacy="mask">
            <Button
              color="neutral"
              size="small"
              outlined
              onClick={handleModalClose}
              text="Cancel"
            />
            <Button
              data-qa="create-guarantor-btn"
              onClick={() => formik.submitForm()}
              forwardedRef={createGuarantor}
              loading={loading}
              size="small"
              text="Confirm & Create Guarantor"
            />
          </div>
        }>
        <form data-dd-privacy="allow">
          <GuarantorForms formik={formik} states={states} isTablet={isTablet} />
        </form>
      </Modal>

      <Confirm
        isOpen={confirmClose}
        handleClose={() => setConfirmClose(false)}
        handleContinue={() => {
          setConfirmClose(false);
          hideNewGuarantor();
        }}
        title="Discard changes?"
        message="Are you sure you want to discard changes to this guarantor?"
      />
    </>
  );
};

export default NewGuarantor;
