import { requestApi } from './Api';

export const getReferralOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/referral_order/get',
    params
  });

export const upsertReferralOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/referral_order/upsert',
    params,
  });

export const deleteReferralOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/referral_order/delete',
    params,
  });

