import { getTerminalGroups } from 'api/Admin/Practices/Terminals';

import Badge from 'components/shared/Badge/Badge';

import Actions from '../components/Actions';

export const DEFAULT_COLUMNS = [
  {
    field: 'id',
    headerName: 'ID',
    cellClass: 'no-border'
  },
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border'
  },
  {
    field: 'online',
    headerName: 'Type',
    cellClass: 'no-border',
    cellRenderer: ({ value }) =>
      value ? (
        <div className="flex h-full items-center justify-center">
          <Badge text="Online" color="primary" />
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <Badge text="Card Present" color="danger" />
        </div>
      )
  },
  {
    field: 'pin',
    headerName: 'PIN',
    cellClass: 'no-border'
  },
  {
    field: 'merchant_id',
    headerName: 'Merchant ID',
    cellClass: 'no-border'
  },
  {
    field: 'vendor_id',
    headerName: 'Vendor ID',
    cellClass: 'no-border'
  },
  {
    field: 'user_id',
    headerName: 'User ID',
    cellClass: 'no-border'
  },
  {
    field: 'brand',
    headerName: 'Brand',
    cellClass: 'no-border'
  },
  {
    field: 'kind',
    headerName: 'Kind',
    cellClass: 'no-border'
  },
  {
    field: 'note',
    headerName: 'Notes',
    cellClass: 'no-border'
  },
  {
    field: 'group_id',
    headerName: 'Group ID',
    cellClass: 'no-border'
  },
  {
    field: 'active',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) =>
      value ? (
        <div className="flex h-full items-center justify-center">
          <Badge text="Active" color="primary" />
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <Badge text="Inactive" color="danger" />
        </div>
      )
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: (params) => <Actions data={params?.data} />,
    maxWidth: 200,
    minWidth: 200,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const DEFAULT_FILTERS = (practiceId) => {
  return {
    id: {
      type: 'search',
      values: null,
      title: 'ID',
      placeholder: 'Search by ID',
      preview: (values) => values
    },
    name: {
      type: 'search',
      values: null,
      title: 'Name',
      placeholder: 'Search by Name',
      preview: (values) => values
    },
    brand: {
      type: 'search',
      values: null,
      title: 'Brand',
      placeholder: 'Search by Brand',
      preview: (values) => values
    },
    kind: {
      type: 'search',
      values: null,
      title: 'Kind',
      placeholder: 'Search by Kind',
      preview: (values) => values
    },
    note: {
      type: 'search',
      values: null,
      title: 'Notes',
      placeholder: 'Search by Notes',
      preview: (values) => values
    },
    group_id: {
      type: 'infinite-scroll',
      multiple: true,
      options: [],
      values: [],
      title: 'Group',
      placeholder: 'Search groups',
      queryKey: 'groups',
      queryFn: getTerminalGroups,
      params: { practiceId, onlySearchable: true },
      formatData: (data) => data.map((group) => ({ label: group?.name, value: group?.id }))
    },
    online: {
      type: 'checkbox',
      values: null,
      options: ['online', 'card present'],
      title: 'Online',
      preview: (values) => values.map((v) => v.label).join(', ')
    },
    active: {
      type: 'checkbox',
      values: null,
      options: ['active', 'inactive'],
      title: 'Active',
      preview: (values) => values.map((v) => v.label).join(', ')
    }
  };
};

export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'never',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};
