import React from 'react';
import { Popover, Transition } from '@headlessui/react';

export const NotesPopover = ({ buttonContent, children, position = 'bottom', className = '' }) => {
  const positionClasses = {
    top: 'bottom-0 mb-2',
    bottom: 'top-full mt-2',
    left: 'right-full mr-2',
    right: 'left-full ml-2'
  };

  return (
    <Popover className={`relative ${className}`}>
      <Popover.Button className="flex items-center focus:outline-none">
        {buttonContent}
      </Popover.Button>

      <Transition
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1">
        <Popover.Panel
          className={`absolute z-10 ${positionClasses[position]} w-64 rounded-lg border border-gray-200 bg-white p-4 shadow-lg`}>
          <div className="grid gap-1">{children}</div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
