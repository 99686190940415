import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../shared/Icon/Icon';

import Options from './Options';

const Accordion = ({
  title,
  children,
  disabled,
  updateFormType = {},
  sectionRef = null,
  advanced = true,
  options,
  withStyle = true,
  withHeader = true
}) => {
  const { cnDisplaySettings, setCNDisplaySettings } = useClinicalNoteContext();

  const handleUpdate = (event, key, parentKey) => {
    const updatedObject = {
      ...cnDisplaySettings,
      sections: {
        ...cnDisplaySettings?.sections,
        [parentKey]: {
          ...cnDisplaySettings?.sections[parentKey],
          [key]: {
            ...cnDisplaySettings?.sections[parentKey][key],
            advancedForm: event
          }
        }
      }
    };
    setCNDisplaySettings(updatedObject);
  };

  return (
    <div
      className={cs(
        '!relative flex h-full select-none flex-col !p-4 !pt-3 outline-none before:absolute before:left-0 before:top-0 before:h-full before:w-1 before:bg-neutral-100',
        disabled &&
          "cursor-not-allowed !border-neutral-200 !bg-primary-50 content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30",
        disabled && !withStyle && '!bg-transparent',
        withStyle && '!rounded-lg border border-solid border-primary-200 !bg-primary-50'
      )}
      tabIndex={0}>
      {withHeader && (
        <div className="flex w-full items-center justify-between">
          <span className="text-lg font-500 leading-7 text-primary-900">{title}</span>

          <div className="flex items-center gap-1">
            {sectionRef?.current?.patientResponse && (
              <Tippy content="Patient response" placement="bottom" className="tippy-dark">
                <div
                  data-qa="patient-response-option"
                  onClick={() => sectionRef?.current?.patientResponse()}
                  className="tippy-dark flex h-6 w-6 cursor-pointer items-center justify-center rounded-lg hover:bg-primary-100">
                  <Icon icon="new-user-arrow-right" className="cursor-pointer" />
                </div>
              </Tippy>
            )}
            <Options
              handleUpdate={handleUpdate}
              sectionRef={sectionRef}
              advanced={advanced}
              options={options}
              {...updateFormType}
            />
          </div>
        </div>
      )}
      <div
        className={cs(
          'h-full overflow-hidden text-sm text-neutral-600 duration-200',
          withHeader && '!pt-5'
        )}>
        {children}
      </div>
    </div>
  );
};

export default Accordion;
