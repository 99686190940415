import React from 'react';

import { documentCategories } from 'constants';

import { formatDate, formatDateAndTimeZ } from 'lib/helpers/utility';

import DocActions from '../components/DocumentActions';

export default (props) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Document Name',
      flex: 2,
      headerCheckboxSelection: true,
      checkboxSelection: (params) => !params.node.group
    },
    {
      field: 'date_of_service',
      headerName: 'Date of Service',
      valueFormatter: ({ value }) =>
        value ? formatDateAndTimeZ(value, props?.practice?.timezone) : '-'
    },
    {
      field: 'category',
      headerName: 'Document Category',
      valueFormatter: ({ value }) => documentCategories.find((cat) => cat?.value == value)?.label
    },
    {
      field: 'uploader_name',
      headerName: 'Uploaded by'
    },
    {
      field: 'created_at',
      headerName: 'Document Date',
      valueFormatter: ({ value }) => (value ? formatDate(value, props?.practice?.timezone) : '-')
    },
    {
      field: 'actions',
      headerName: '',
      pinned: 'right',
      cellClass: 'no-border',
      maxWidth: 70,
      cellRenderer: ({ data, index }) => (
        <DocActions
          {...{
            setViewDocument: props?.setViewDocument,
            isEditOpen: props?.isEditOpen,
            setIsEditOpen: props?.setIsEditOpen,
            fromPatient: props?.fromPatient,
            patientId: props?.patientId,
            cat: documentCategories.find((cat) => cat?.value == data?.category?.trim()),
            mimetype: data?.mimetype,
            form: {
              response_id: data?.id,
              appointment_id: data?.appointment_id,
              form_id: data?.form_id,
              json: data?.json,
              form_json: data?.form_json,
              form_version: data?.form_version
            },
            index,
            data
          }}
        />
      )
    }
  ];
  return columns;
};
