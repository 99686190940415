import React from 'react';
import HtmlRenderer from '../../../components/HtmlRenderer';

const PreviewView = ({ previewData, isHtml = false }) => {
  return (
    <div className="z-50 mt-3 flex h-full bg-transparent px-[230px]">
      <ul className="space-y-2">
        {previewData?.map(({ label, value }, index) => (
          <li key={index} className="mb-5">
            <div className="mb-2 text-lg font-semibold text-neutral-800">{label}</div>
            {isHtml ? (
              <HtmlRenderer value={value} />
            ) : (
              <div className="text-neutral-800">{value}</div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PreviewView;
