import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  Capitalize,
  camelToKebabCase,
  camelToSpaceCase,
  io,
  sortObjectByProperty
} from '../../../../../../../lib/helpers/utility';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import Loading from 'components/shared/Loading/Loading';
import { defaultSettings } from 'constants';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../../../../../shared/Icon/Icon';
import Switch from '../../../../../../shared/Switch/Switch';
import { advancedFormStatus, getAdvancedForms, returnFormName } from './lib/displaySettingHelpers';

const DisplaySettings = () => {
  const {
    advancedHP,
    advancedSOAP,
    cnDisplaySettings,
    setCNDisplaySettings,
    cnDisplaySettingsLoading,
    customFormTypes
  } = useClinicalNoteContext();
  const navigate = useNavigate();
  const { id: patientId, appointmentId, type, formName } = useParams();
  const [list, setList] = useState({});

  const advancedForms = getAdvancedForms({
    type,
    advancedHP,
    advancedSOAP,
    customFormTypes,
    cnDisplaySettings
  });

  useEffect(() => {
    const finalList = getEnabledKeysFromSections();

    if (!io(finalList)) return;

    const sortedObject = sortObjectByProperty(finalList, 'position');

    setList(sortedObject);
  }, []);

  if (advancedForms === advancedFormStatus.NOT_SUPPORTED) {
    return (
      <AlertContent
        title="Forms not supported"
        message={
          <div>
            Unsupported note type: <em className="font-600">"{Capitalize(type)}"</em>. No forms
            available.
          </div>
        }
        color="warning"
      />
    );
  }

  const getEnabledKeysFromSections = () => {
    const advForms = typeof advancedForms === 'string' ? {} : advancedForms;
    const initialCNDisplaySettings = {
      ...defaultSettings?.clinicalNote?.sections?.[type],
      ...advForms
    };

    const providerClinicalNoteSection = cnDisplaySettings?.sections?.[type];
    const cnSectionKeys = Object.keys(providerClinicalNoteSection ?? {});

    const filteredObjects = {};
    for (const key of cnSectionKeys) {
      if (key in initialCNDisplaySettings) {
        filteredObjects[key] = cnDisplaySettings.sections?.[type]?.[key];
      }
    }

    return { ...advancedForms, ...filteredObjects };
  };

  const reorder = (obj, startIndex, endIndex) => {
    const keys = Object.keys(obj);
    const [removedKey] = keys.splice(startIndex, 1);
    keys.splice(endIndex, 0, removedKey);
    const reorderedObj = {};
    keys.forEach((key, index) => {
      reorderedObj[key] = { ...obj[key], position: index };
    });
    return reorderedObj;
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver && '#f3fcff',
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 560,
    minHeight: 0,
    marginRight: 2
  });

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updateSelected = reorder(list, result.source.index, result.destination.index);
    setList(updateSelected);
    setCNDisplaySettings((prevState) => ({
      ...prevState,
      sections: {
        ...prevState.sections,
        [type]: updateSelected
      }
    }));
  };

  const handleUpdate = (event, key) => {
    const sectionToKebab = camelToKebabCase(key);
    if (sectionToKebab === formName || typeof key === 'number') navigate(type);
    setList((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState?.[key],
        enabled: event
      }
    }));

    setCNDisplaySettings((prevState) => ({
      ...prevState,
      sections: {
        ...prevState.sections,
        [type]: {
          ...prevState.sections[type],
          [key]: {
            ...prevState.sections?.[type]?.[key],
            enabled: event
          }
        }
      }
    }));

    if (event) {
      navigateToForm({
        patient: patientId,
        appointment: appointmentId,
        section: type,
        form: returnFormName({ formName: key })
      });
    }
  };

  const onDoubleClickListForm = ({ form = null }) => {
    if (!list?.[form]?.enabled) {
      showAlert({
        color: 'warning',
        title: 'Form is disabled',
        message: 'Please enable the form to view the content'
      });

      return;
    }

    if (form === 'medications') {
      form = 'medicationHistory';
    }

    navigateToForm({
      patient: patientId,
      appointment: appointmentId,
      section: type,
      form: returnFormName({ formName: form })
    });
  };

  const navigateToForm = ({
    patient = patientId,
    appointment = appointmentId,
    section = type,
    form = formName
  }) => {
    navigate(`/portal/charts/${patient}/clinical-notes/${appointment}/${section}/${form}`);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {io(list) ? (
              <div className="relative">
                {cnDisplaySettingsLoading && (
                  <div className="absolute flex w-full justify-center">
                    <Loading />
                  </div>
                )}
                {Object.entries(list).map(([key, item], index) => (
                  <Draggable
                    draggableId={key}
                    key={key}
                    index={index}
                    isDragDisabled={cnDisplaySettingsLoading}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Tippy
                          content={item.title ?? camelToSpaceCase(key)}
                          placement="right"
                          className="tippy-dark">
                          <div
                            onDoubleClick={() => onDoubleClickListForm({ form: key })}
                            className={cs(
                              'flex items-center justify-between gap-x-[9px] py-[6px] !pl-1 !pr-3 transition-all hover:bg-primary-50',
                              cnDisplaySettingsLoading && 'opacity-50'
                            )}>
                            <div className="flex min-h-[18px] gap-[2px] overflow-hidden">
                              <Icon icon="new-sort" />
                              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-400 leading-4 text-primary-900">
                                {item.title ?? camelToSpaceCase(key)}
                              </span>
                            </div>
                            <Switch
                              disabled={cnDisplaySettingsLoading}
                              onChange={(e) => handleUpdate(e, key)}
                              checked={list?.[key]?.enabled}
                            />
                          </div>
                        </Tippy>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            ) : (
              <Skeleton height="30px" count={3} />
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DisplaySettings;
