import { requestApi } from 'api/Api';

import { optionify } from 'lib/helpers/utility';

export const createAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/create', params, navigate });
};

export const listAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/list', params, navigate });
};

export const getAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/get', params, navigate });
};

export const searchAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/search', params, navigate });
};

export const searchAiModelOptionify = (navigate, params) => {
  const data = requestApi({ url: '/api/admin/intelligence/models/search', params, navigate });
  return optionify(data);
};

export const updateAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/update', params, navigate });
};
