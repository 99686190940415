import { requestApi } from './Api';

export const getClaims = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/claim/get_claims',
    params
  });

export const getClaimsCSV = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/claim/get_csv',
    params
  });

export const getClaim = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/claim/get_claim',
    params
  });

export const updateClaims = (navigate, claim) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/claim/update',
    params: { claim },
    throwError: true
  });

export const getUnmatchedClaims = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/practice/billing/claim/unmatched_claims',
    params
  });

export const submitClaims = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/file',
    params,
    navigate
  });

export const archiveClaims = (navigate, params) => {
  return requestApi({
    url: '/api/practice/billing/claim/archive',
    params,
    navigate
  });
};

export const deleteClaims = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/delete',
    params,
    navigate
  });

export const markClaimPending = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/mark_pending',
    params,
    navigate
  });

export const updatePendingChanges = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/update_pending_changes',
    params,
    navigate
  });

export const getClaimMdHistory = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/response_request',
    params,
    navigate
  });

export const uploadClaimAttachment = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/upload_attachment',
    params,
    navigate
  });

export const deleteClaimAttachment = (navigate, params) =>
  requestApi({
    url: '/api/practice/billing/claim/delete_attachment',
    params,
    navigate
  });
