import React, { useState, useRef, createRef, useEffect } from 'react';
import cs from 'classnames';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { readableToSnakeCase } from 'lib/helpers/utility';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useAptTimelineDetails } from 'lib/hooks/queries/appointment/useAptTimelineDetails';
import Item from './components/Item/Item';
import { userState as user_state } from 'components/state';
import ProgressBar from './components/ProgressBar/ProgressBar';
import StartCheckinButton from './components/StartCheckinButton';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Section from 'components/practice/NewAppointment/components/Section';

const Timeline = () => {
  const { values } = useFormikContext();
  const { device } = useUIContext();
  const userState = useRecoilValue(user_state);
  const isMobile = device === 'mobile' || device === 'tablet-sm' || device === 'tablet';
  const isPined = isMobile || userState?.display_settings?.appointment?.timeline?.isPined;
  const isAptDisabled = values.appointment_disabled;
  const itemRefs = useRef({});
  const [focusedItem, setFocusedItem] = useState(null);
  const [openItems, setOpenItems] = useState({});

  const keysWithTrueValues = Object.keys(openItems).filter((key) => openItems[key] === true);

  const { data, isFetching } = useAptTimelineDetails({
    params: {
      appointment_id: values.id,
      patient_id: values.user_id,
      keys: keysWithTrueValues
    }
  });

  // Initially open item
  useEffect(() => {
    const initialOpenItems = {};
    Object.values(values.timeline_bar || {}).forEach((status) => {
      if (
        isPined ||
        status.name === values.status ||
        status.status_options.find((option) => readableToSnakeCase(option) === values.status)
      ) {
        initialOpenItems[status.name] = true;
      }
    });

    setOpenItems(initialOpenItems);
  }, [isPined]);

  const handleToggleItem = (target) => {
    setFocusedItem(null);
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [target]: !prevOpenItems[target]
    }));
  };

  const getItemRef = (key) => {
    if (!itemRefs.current[key]) {
      itemRefs.current[key] = createRef();
    }
    return itemRefs.current[key];
  };

  const focusItem = (key) => {
    setFocusedItem(key);
    setOpenItems((prevOpenItems) => ({
      ...prevOpenItems,
      [key]: true
    }));
    const ref = itemRefs.current[key];
    if (ref && ref.current) {
      ref.current.focus();
    }
  };

  const HeaderChildren = (
    <>
      <ProgressBar focusItem={focusItem} />
      <StartCheckinButton />
    </>
  );

  return (
    <Section
      title="Appointment Timeline"
      headerChildren={HeaderChildren}
      target="timeline"
      parentClassName="flex flex-col h-full"
      className="h-full"
      isMobile={isMobile}>
      <div className={cs('mb-4 grid h-full', isPined ? 'grid-cols-2' : 'grid-cols-4')}>
        {Object.entries(values.timeline || {}).map(([section, statuses]) => (
          <div
            key={section}
            className={cs(
              'flex h-full flex-col border-0 border-r border-solid border-neutral-200 px-4 first-of-type:-mb-[1px] first-of-type:pl-0 last-of-type:mr-0 last-of-type:pr-0',
              isPined
                ? 'pb-4 odd:pl-0 even:border-r-0 even:pr-0 first-of-type:border-b last-of-type:border-t'
                : 'last-of-type:border-none'
            )}>
            <div className="relative">
              {isAptDisabled && (
                <div className="pointer-events-none absolute inset-0 z-[100] bg-white opacity-40"></div>
              )}
              <span className="block py-2 capitalize">{section}</span>
              {Object.entries(statuses).map(([key, status]) => (
                <Item
                  key={key}
                  ref={getItemRef(key)}
                  target={key}
                  color={status.color}
                  name={status.name}
                  currStatus={values.status}
                  isOpen={!!openItems[key]}
                  isFocus={focusedItem === key}
                  onToggle={() => handleToggleItem(key)}
                  data={!isEmpty(data?.timelineDetails) && data.timelineDetails[key]}
                  isFetching={isFetching}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default withErrorBoundary(Timeline);
