import React from 'react';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { clinicalNote as clinicalNoteState } from 'components/state';

import { currentPractice } from '../../../../practiceState';
import TPTable from '../../TreatmentPlan/views/TPTable';

import { getTPTableCols } from './lib/getTPTableCols';

const TPList = ({ data = [] }) => {
  const practice = useRecoilValue(currentPractice);
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <TableContextProvider
      name="sidebar_treatment_plans"
      cols={getTPTableCols({
        treatment_plan_id: clinicalNote?.treatment_plan_id,
        timezone: practice.timezone
      })}>
      <TPTable data={data} />
    </TableContextProvider>
  );
};

export default TPList;
