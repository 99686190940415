import cs from 'classnames';
import React from 'react';
import Button from '../../Buttons/Button';
import Loader from '../../Loader/Loader';

import camera from '../../../practice/charts/InsuranceProfile/icons/camera.svg';
import gallery from '../../../practice/charts/InsuranceProfile/icons/gallery.svg';
import refresh from '../../../practice/charts/InsuranceProfile/icons/refresh.svg';

import { getCroppedImage } from '../lib/helpers';

const ImagePickerActions = ({
  handle,
  name,
  processing,
  resetHandle,
  coords,
  loading,
  imageRef,
  handleContinue,
  onFileChange,
  capture,
  cameraReady,
  setFacingMode,
  crop,
  setUrl
}) => {
  const flipCamera = () => {
    setFacingMode((prev) => (prev === 'environment' ? 'user' : 'environment'));
  };

  if (loading || (!coords && handle)) {
    return (
      <div className="flex gap-2">
        <Loader color="neutral" shade="800"></Loader>
        <p>{!coords && handle ? 'Detecting document...' : 'Uploading image...'}</p>
      </div>
    );
  }

  if (processing) {
    return (
      <div className="flex gap-2">
        <Loader color="neutral" shade="800"></Loader>
        <p>Processing document...</p>
      </div>
    );
  }

  if (handle) {
    return (
      <div className="flex flex-col items-center">
        <div className="flex gap-2">
          <Button
            color="white"
            text="Re-take picture"
            icon="rotate-ccw"
            iconColor="white"
            iconIsStroke
            outlined
            className="w-[11rem] cursor-pointer gap-1 border border-white !bg-transparent"
            transparent
            onClick={resetHandle}></Button>

          <Button
            text="Looks good, continue"
            icon="new-check"
            iconColor="white"
            iconIsStroke
            className="cursor-pointer gap-1"
            onClick={() => getCroppedImage(crop, imageRef, handleContinue, setUrl)}></Button>
        </div>
      </div>
    );
  }
  return (
    <>
      <div
        onClick={() => document.getElementById(`modal-${name}-upload`).click()}
        className="flex cursor-pointer items-center justify-center rounded-full border !border-neutral-600 !p-2 transition-all hover:scale-110">
        <img src={gallery} />
      </div>

      <input
        type="file"
        accept="image/*"
        onChange={onFileChange}
        className="hidden"
        id={`modal-${name}-upload`}
      />

      <div
        onClick={cameraReady ? capture : undefined} // Disable click if camera is not ready
        className={cs(
          'relative flex cursor-pointer items-center justify-center rounded-full bg-white !p-3',
          { 'pointer-events-none opacity-50': !cameraReady } // Style change if not ready
        )}>
        <img src={camera} />
        <div className="absolute -bottom-1 -left-1 -right-1 -top-1 flex items-center justify-center rounded-full border border-white transition-all hover:-bottom-2 hover:-left-2 hover:-right-2 hover:-top-2 focus:bottom-0 focus:left-0 focus:right-0 focus:top-0"></div>
      </div>

      <div
        onClick={flipCamera}
        className="flex cursor-pointer items-center justify-center rounded-full border !border-neutral-600 !p-2 transition-all hover:scale-110">
        <img src={refresh} />
      </div>
    </>
  );
};

export default ImagePickerActions;
