import { requestApi } from './Api';

export const getUser = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/get',
    params
  });

export const updateUser = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/update',
    params
  });

export const getUsers = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/get_multiple',
    params
  });

export const unsubscribeUser = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/unsubscribe',
    params
  });

export const isUserUnsubscribed = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/isUnsubscribed',
    params
  });

export const subscribeUser = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/subscribe',
    params
  });

export const userTrackClick = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/user/search/upsert',
    params
  });

export const getUserMostClickedSearches = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/user/search/retrieve_top_searches',
    params
  });

export const getCheckinUser = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/patient/checkin/get_user',
    params
  });
