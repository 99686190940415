import { formatDateAndTime, iaRa, mString } from 'lib/helpers/utility';

import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';

import { formatProcedure } from '../../lib/formatProcedure';
import { displayDiagnosis, sumAdjustment } from '../../lib/utils';

export const formatAllowedEob = ({ claims }) => {
  let rows = [];

  iaRa(claims).forEach((claim) => {
    const date = claim.created_at;
    const claimDate = date ? formatDateAndTime(date) : '';
    const claimRow = {
      id: claim.id,
      select: true,
      date: claimDate,
      createdAt: claim.created_at,
      patientName: `${claim.pat_name_f} ${claim.pat_name_l}` || '',
      procedure_code: '',
      provider: `${claim.prov_name_f} ${claim.prov_name_l}` || '',
      diagnosis: displayDiagnosis(claim),
      billed: mString(claim?.total_charge * 100),
      adjustment: mString(sumAdjustment(claim.procedures)),
      paid: mString(claim?.invoice?.amount_paid),
      total_insurance_payment: mString(claim?.insurance_payment?.total),
      isClaimRow: true,
      invoice_procedures: claim?.invoice_procedures,
      claimId: claim.id,
      state: claim?.state
    };

    rows.push(claimRow);

    const relatedProcedures = iaRa(claim?.invoice_procedures)
      .filter(filterSuperbillClaimProcedure)
      .map((procedure, index) => formatProcedure(procedure, claim, index));

    rows = rows.concat(relatedProcedures);
  });

  return rows;
};
