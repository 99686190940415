import { useState } from 'react';

import { ia } from 'lib/helpers/utility';

import PayerModal from 'components/practice/settings/payers/components/PayerModal';

const PayerEdit = ({ payers, closeHandler }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleClose = () => {
    if (currentIndex + 1 >= payers?.length) closeHandler();
    setCurrentIndex((prev) => prev + 1);
  };

  if (!ia(payers) || !payers?.[currentIndex]) return null;

  return (
    <PayerModal
      key={`payer-${currentIndex}`}
      payer={payers[currentIndex]}
      isOpen={true}
      handleClose={handleClose}
    />
  );
};

export default PayerEdit;
