import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { iaRa, snakeToTitleCase } from 'lib/helpers/utility';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  hpiContextList,
  hpiDurationList,
  hpiLocationList,
  hpiQualityList,
  hpiRelievingFactorList,
  hpiTimingList
} from '../../../../../constants';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../shared/Textarea/Textarea';
import {
  clinicalNote as clinicalNoteState,
  intelligenceAiState
} from '../../../../state';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Input from '../../../Input/Input';
import RadioButton from '../../../RadioButton/RadioButton';
import Select from '../../../Select/Select';
import Container from '../components/Container';
import Header from '../components/Header';
import Row from '../components/Row';
import SelectableButton from '../components/SelectableButton';
import '../Custom.scss';
import { HPIRevisions as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { createHPINarrative } from '../lib/hpiNarrativeHelper';
import useNewDesign from '../lib/useNewDesign';

const HPIRevisions = ({
  forwardedRef,
  defaultValue,
  sectionRef,
  showNarrative = false,
  onChange,
  options = []
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [clinicalNote, setClinicalNote = () => {}] = useRecoilState(clinicalNoteState);
  const [hpiRevisions, setHpiRevisions] = useState(initialValue);
  const [locked, setLocked] = useState(clinicalNote?.locked || false);

  const otherModifyingRef = useRef(null);
  const contextNoteRef = useRef(null);
  const signsAndSymptomsRef = useRef(null);
  const relievingFactorsNote = useRef(null);

  const [syncNarrative, setSyncNarrative] = useState(true);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const isAdvancedForm = cnDisplaySettings
    ? cnDisplaySettings?.sections?.hp?.hpi?.advancedForm
    : true;
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setHpiRevisions(JSON.parse(defaultValue));
      } else {
        setHpiRevisions(defaultValue);
      }
    } else {
      setHpiRevisions(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    setLocked(clinicalNote?.locked || false);
  }, [clinicalNote]);

  useEffect(() => {
    notifyChange(hpiRevisions);
  }, [hpiRevisions]);

  useEffect(() => {
    if (
      hpiRevisions.narrative !== intelligenceAi.response &&
      intelligenceAi?.path?.includes?.('hpi') &&
      intelligenceAi.response !== ''
    ) {
      setHpiRevisions((ps) => ({
        ...ps,
        narrative: intelligenceAi.response ?? ps?.narrative
      }));
    }
  }, [intelligenceAi]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'HPIRevisions', type: 'custom' } });
    }
  };

  useImperativeHandle(sectionRef, () => ({
    formData: { hpi: hpiRevisions }
  }));

  const handleChange = ({ event, key, parentKey, childKey, isBoolean }) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    const updatedObject = {
      ...hpiRevisions
    };

    if (parentKey && childKey) {
      updatedObject[key] = {
        ...updatedObject[key],
        [name ?? childKey]: name ? (isBoolean ? !JSON.parse(value) : value) : event
      };
    } else {
      updatedObject[name ?? (childKey || key)] = name
        ? isBoolean
          ? !JSON.parse(value)
          : value
        : event;
    }

    if (syncNarrative) {
      handleNarrative({
        rosObject: updatedObject,
        event: createHPINarrative(updatedObject)
      });
    } else {
      setHpiRevisions(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const saveClinicalNote = (updatedObject) => {
    if (setClinicalNote)
      setClinicalNote((prevState) => ({
        ...prevState,
        hpi: updatedObject
      }));
  };

  const clearOppositeElement = ({ parentKey, key }) => {
    setHpiRevisions({
      ...hpiRevisions,
      [parentKey]: {
        ...hpiRevisions[parentKey],
        [key]: ''
      }
    });
  };

  const handleNarrative = ({
    rosObject = hpiRevisions,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...rosObject, narrative: event };

    setHpiRevisions(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative</p>
        </div>
      ),
      onClick: () => {
        const formattedNarrative = createHPINarrative(hpiRevisions);

        handleNarrative({
          event: formattedNarrative,
          scrollable: true
        });
      }
    }
  ];

  const newDesign = useNewDesign();

  const CustomButton = (props) =>
    newDesign ? (
      <SelectableButton
        {...props}
        onChange={() =>
          props.onChange({
            target: { name: props.name, value: props.value }
          })
        }
        isChecked={hpiRevisions?.modifyingFactors?.[props.name] == props.value}
        label={props?.label ? snakeToTitleCase(props?.label) : ''}
      />
    ) : (
      <RadioButton {...props} />
    );

  const CustomRow = ({ children, title }) =>
    newDesign ? (
      <Row className="my-3 w-full" title={title}>
        <div className="grid grid-cols-2 gap-4">{children}</div>
      </Row>
    ) : (
      <div>
        <p className="!pb-3 font-500 text-neutral-800">{title}</p>
        <div className="flex gap-5">{children}</div>
      </div>
    );

  return (
    <div className="hpi grid gap-y-4">
      <input type="hidden" value={JSON.stringify(hpiRevisions)} ref={forwardedRef} />
      <Header title="History of present illness" isAdvancedForm={isAdvancedForm}>
        {isAdvancedForm && !newDesign && (
          <Checkbox
            data-qa="no-details-to-report"
            label="No details to report"
            name="noDetails"
            disabled={locked}
            isChecked={hpiRevisions?.noDetails}
            onChange={() =>
              handleChange({
                event: !hpiRevisions.noDetails,
                key: 'noDetails',
                parentKey: 'hpi'
              })
            }
          />
        )}

        {!hpiRevisions?.noDetails && isAdvancedForm && (
          <>
            <Container>
              <div
                className={
                  newDesign
                    ? 'flex flex-col gap-4'
                    : 'grid grid-cols-4 gap-4 sm:flex sm:flex-col md:flex md:flex-col lg:grid-cols-2'
                }>
                <Select
                  radius="50px"
                  label="Location"
                  placeholder="Type and select location"
                  id="location"
                  name="location"
                  isCreatable
                  options={hpiLocationList}
                  disabled={locked}
                  value={hpiRevisions?.location}
                  onChange={(event) =>
                    handleChange({
                      event,
                      key: 'location',
                      parentKey: 'hpi'
                    })
                  }
                  data-qa="text-qa"
                />
                <Select
                  label="Duration"
                  placeholder="Type and select duration"
                  id="duration"
                  name="duration"
                  isCreatable
                  options={hpiDurationList}
                  disabled={locked}
                  value={hpiRevisions?.duration}
                  onChange={(event) =>
                    handleChange({
                      event,
                      key: 'duration',
                      parentKey: 'hpi'
                    })
                  }
                />
                <Select
                  label="Duration of ailment"
                  placeholder="Type and select duration"
                  id="timing"
                  name="timing"
                  isCreatable
                  options={hpiTimingList}
                  disabled={locked}
                  value={hpiRevisions?.timing}
                  onChange={(event) =>
                    handleChange({
                      event,
                      key: 'timing',
                      parentKey: 'hpi'
                    })
                  }
                />
                <Select
                  label="Quality"
                  placeholder="Type and select quality"
                  id="quality"
                  name="quality"
                  isCreatable
                  options={hpiQualityList}
                  disabled={locked}
                  value={hpiRevisions?.quality}
                  onChange={(event) =>
                    handleChange({
                      event,
                      key: 'quality',
                      parentKey: 'hpi'
                    })
                  }
                />
                <div className="col-span-2 grid gap-4">
                  <Select
                    label="What makes the problem or symptoms worse (certain activities, time of day, food, etc.)?"
                    placeholder="Type and select context"
                    id="context"
                    name="context"
                    isCreatable
                    options={hpiContextList}
                    disabled={locked}
                    value={hpiRevisions?.context}
                    onChange={(event) =>
                      handleChange({
                        event,
                        key: 'context',
                        parentKey: 'hpi'
                      })
                    }
                  />
                  <Textarea
                    label="Please provide any further context on present illness, if you wish:"
                    placeholder="Add note here"
                    transcribing
                    forwardedRef={contextNoteRef}
                    disabled={locked}
                    value={hpiRevisions?.contextNote}
                    name="contextNote"
                    textareaClassName="!min-h-[80px]"
                    id="contextNote"
                    onChange={(event) =>
                      handleChange({
                        event,
                        parentKey: 'hpi'
                      })
                    }
                  />
                </div>
                <div className="col-span-2 grid gap-4">
                  <Select
                    label="What makes the problem or symptoms better (rest, medication, certain positions, etc.)?"
                    placeholder="Type and select relieving factor"
                    id="relievingFactor"
                    name="relievingFactor"
                    isCreatable
                    options={hpiRelievingFactorList}
                    disabled={locked}
                    value={hpiRevisions?.relievingFactor}
                    onChange={(event) =>
                      handleChange({
                        event,
                        key: 'relievingFactor',
                        parentKey: 'hpi'
                      })
                    }
                  />
                  <Textarea
                    label="Please provide any further context on present illness, if you wish:"
                    placeholder="Add note here"
                    transcribing
                    forwardedRef={relievingFactorsNote}
                    disabled={locked}
                    value={hpiRevisions?.relievingFactorsNote}
                    name="relievingFactorsNote"
                    textareaClassName="!min-h-[80px]"
                    id="relievingFactorsNote"
                    onChange={(event) =>
                      handleChange({
                        event,
                        parentKey: 'hpi'
                      })
                    }
                  />
                </div>
              </div>
            </Container>

            {isAdvancedForm && newDesign && (
              <div className="mb-3 mt-3 flex w-full max-w-[500px] flex-col items-center justify-center !px-4 md:!px-6 lg:!px-6 xl:!px-6">
                <p className="mb-[1rem] mt-2 px-6 text-center text-sm text-neutral-600">
                  Are there any modifying factors that make your symptoms better or worse?
                </p>

                <div className={cs('!grid w-full !grid-cols-2 gap-2', '-mb-3')}>
                  <SelectableButton
                    data-qa="yes"
                    label="Yes"
                    disabled={locked}
                    isChecked={!hpiRevisions?.modifyingFactors?.noModifyingFactors}
                    onChange={() =>
                      handleChange({
                        event: false,
                        parentKey: 'hpi',
                        key: 'modifyingFactors',
                        childKey: 'noModifyingFactors'
                      })
                    }
                  />
                  <SelectableButton
                    data-qa="no"
                    label="No"
                    disabled={locked}
                    isChecked={hpiRevisions?.modifyingFactors?.noModifyingFactors}
                    onChange={() =>
                      handleChange({
                        event: true,
                        parentKey: 'hpi',
                        key: 'modifyingFactors',
                        childKey: 'noModifyingFactors'
                      })
                    }
                  />
                </div>
              </div>
            )}

            {(!newDesign || !hpiRevisions?.modifyingFactors?.noModifyingFactors) && (
              <Container className="mt-4 !gap-0">
                {!newDesign && (
                  <>
                    <div className="grid !pb-4">
                      <span className="!pb-3 text-base font-500 leading-6 text-neutral-900">
                        Modifying factors
                      </span>
                      <Checkbox
                        data-qa="no-modifying-factors"
                        label="No modifying factors"
                        name="noDetails"
                        disabled={locked}
                        isChecked={hpiRevisions?.modifyingFactors?.noModifyingFactors}
                        onChange={() =>
                          handleChange({
                            event: !hpiRevisions.modifyingFactors?.noModifyingFactors,
                            parentKey: 'hpi',
                            key: 'modifyingFactors',
                            childKey: 'noModifyingFactors'
                          })
                        }
                      />
                    </div>
                    <div className="dashed-top"></div>
                  </>
                )}
                {!hpiRevisions?.modifyingFactors?.noModifyingFactors && (
                  <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] sm:grid-cols-1">
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you taken any medication?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="takenMedicationYes"
                          disabled={locked}
                          name="takenMedication"
                          isSelected={hpiRevisions?.modifyingFactors?.takenMedication === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'takenMedication'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="takenMedicationNo"
                          name="takenMedication"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.takenMedication === 'no'}
                          onChange={(event) => {
                            clearOppositeElement({
                              parentKey: 'modifyingFactors',
                              key: 'medication'
                            });
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'takenMedication'
                            });
                          }}
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.takenMedication === 'yes' && (
                        <Input
                          className={newDesign ? 'w-full' : 'w-[280px]'}
                          label="Medication"
                          placeholder="Medication"
                          disabled={locked}
                          value={hpiRevisions?.modifyingFactors?.medication}
                          transcribing
                          onChange={(event) =>
                            handleChange({
                              event: event.target.value,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'medication'
                            })
                          }
                        />
                      )}
                      {hpiRevisions?.modifyingFactors?.takenMedication === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedMedicationYes"
                            name="helpedMedication"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedMedication === 'yes'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedMedication'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedMedicationNo"
                            name="helpedMedication"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedMedication === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedMedication'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you applied heat?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="appliedHeatYes"
                          name="appliedHeat"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.appliedHeat === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'appliedHeat'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="appliedHeatNo"
                          name="appliedHeat"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.appliedHeat === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'appliedHeat'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.appliedHeat === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedHeatYes"
                            name="helpedHeat"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedHeat === 'yes'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedHeat'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedHeatNo"
                            name="helpedHeat"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedHeat === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedHeat'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you applied cold compress of any kind?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="appliedColdCompressYes"
                          name="appliedColdCompress"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.appliedColdCompress === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'appliedColdCompress'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="appliedColdCompressNo"
                          name="appliedColdCompress"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.appliedColdCompress === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'appliedColdCompress'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.appliedColdCompress === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedColdCompressYes"
                            name="helpedColdCompress"
                            disabled={locked}
                            isSelected={
                              hpiRevisions?.modifyingFactors?.helpedColdCompress === 'yes'
                            }
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedColdCompress'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedColdCompressNo"
                            name="helpedColdCompress"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedColdCompress === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedColdCompress'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you ever had surgery for this issue?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="hadSurgeryYes"
                          name="hadSurgery"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.hadSurgery === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'hadSurgery'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="hadSurgeryNo"
                          name="hadSurgery"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.hadSurgery === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'hadSurgery'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.hadSurgery === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedSurgeryYes"
                            name="helpedSurgery"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedSurgery === 'yes'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                grandParentKey: 'hp',
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedSurgery'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedSurgeryNo"
                            name="helpedSurgery"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedSurgery === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedSurgery'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you tried physical therapy?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="triedPhysicalTherapyYes"
                          name="triedPhysicalTherapy"
                          disabled={locked}
                          isSelected={
                            hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'yes'
                          }
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'triedPhysicalTherapy'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="triedPhysicalTherapyNo"
                          name="triedPhysicalTherapy"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'triedPhysicalTherapy'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.triedPhysicalTherapy === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedPhysicalTherapyYes"
                            name="helpedPhysicalTherapy"
                            disabled={locked}
                            isSelected={
                              hpiRevisions?.modifyingFactors?.helpedPhysicalTherapy === 'yes'
                            }
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedPhysicalTherapy'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedPhysicalTherapyNo"
                            name="helpedPhysicalTherapy"
                            disabled={locked}
                            isSelected={
                              hpiRevisions?.modifyingFactors?.helpedPhysicalTherapy === 'no'
                            }
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedPhysicalTherapy'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you ever been on a respirator?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="beenRespiratorYes"
                          name="beenRespirator"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.beenRespirator === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'beenRespirator'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="beenRespiratorNo"
                          name="beenRespirator"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.beenRespirator === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'beenRespirator'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.beenRespirator === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedRespiratorYes"
                            name="helpedRespirator"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedRespirator === 'yes'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedRespirator'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedRespiratorNo"
                            name="helpedRespirator"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedRespirator === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedRespirator'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        !newDesign &&
                        'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                      }>
                      <CustomRow title="Have you tried rest?">
                        <CustomButton
                          label="yes"
                          value="yes"
                          id="triedRestYes"
                          name="triedRest"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.triedRest === 'yes'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'triedRest'
                            })
                          }
                        />
                        <CustomButton
                          label="no"
                          value="no"
                          id="triedRestNo"
                          name="triedRest"
                          disabled={locked}
                          isSelected={hpiRevisions?.modifyingFactors?.triedRest === 'no'}
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'triedRest'
                            })
                          }
                        />
                      </CustomRow>
                      {hpiRevisions?.modifyingFactors?.triedRest === 'yes' && (
                        <CustomRow title="Has this helped?">
                          <CustomButton
                            label="yes"
                            value="yes"
                            id="helpedRestYes"
                            name="helpedRest"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedRest === 'yes'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedRest'
                              })
                            }
                          />
                          <CustomButton
                            label="no"
                            value="no"
                            id="helpedRestNo"
                            name="helpedRest"
                            disabled={locked}
                            isSelected={hpiRevisions?.modifyingFactors?.helpedRest === 'no'}
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedRest'
                              })
                            }
                          />
                        </CustomRow>
                      )}
                    </div>
                    <div
                      className={
                        newDesign
                          ? 'grid grid-cols-1'
                          : 'col-span-full grid grid-cols-2 !py-4 md:grid-cols-1'
                      }>
                      <div
                        className={
                          !newDesign &&
                          'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                        }>
                        <Textarea
                          label="Associated Signs and Symptoms"
                          placeholder="Add note here"
                          transcribing
                          forwardedRef={signsAndSymptomsRef}
                          disabled={locked}
                          value={hpiRevisions?.signsAndSymptoms}
                          name="signsAndSymptoms"
                          id="signsAndSymptoms"
                          textareaClassName="!min-h-[80px]"
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi'
                            })
                          }
                        />
                      </div>
                      <div
                        className={
                          newDesign
                            ? 'my-3 w-full'
                            : 'dashed-bottom flex flex-col gap-6 !py-5 !pr-12 sm:!px-0 sm:!py-4'
                        }>
                        <Textarea
                          label="Other modifying factors"
                          placeholder="Add note here"
                          transcribing
                          forwardedRef={otherModifyingRef}
                          disabled={locked}
                          value={hpiRevisions?.modifyingFactors?.otherModifyingFactors}
                          name="otherModifyingFactors"
                          id="otherModifyingFactors"
                          onChange={(event) =>
                            handleChange({
                              event,
                              parentKey: 'hpi',
                              key: 'modifyingFactors',
                              childKey: 'otherModifyingFactors'
                            })
                          }
                        />
                        <CustomRow title="Has this helped or hurt?">
                          <CustomButton
                            label="helped"
                            value="helped"
                            id="helpedOtherModifyingFactorsHelped"
                            name="helpedOtherModifyingFactors"
                            disabled={locked}
                            isSelected={
                              hpiRevisions?.modifyingFactors?.helpedOtherModifyingFactors ===
                              'helped'
                            }
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedOtherModifyingFactors'
                              })
                            }
                          />
                          <CustomButton
                            label="hurt"
                            value="hurt"
                            id="helpedOtherModifyingFactorsHurt"
                            name="helpedOtherModifyingFactors"
                            disabled={locked}
                            isSelected={
                              hpiRevisions?.modifyingFactors?.helpedOtherModifyingFactors === 'hurt'
                            }
                            onChange={(event) =>
                              handleChange({
                                event,
                                parentKey: 'hpi',
                                key: 'modifyingFactors',
                                childKey: 'helpedOtherModifyingFactors'
                              })
                            }
                          />
                        </CustomRow>
                      </div>
                    </div>
                  </div>
                )}
              </Container>
            )}
          </>
        )}
      </Header>

      {cnDisplaySettings && showNarrative && (
        <NarrativeForm
          syncNarrative={syncNarrative}
          setSyncNarrative={setSyncNarrative}
          onChange={(event) => handleNarrative({ event, sync: false })}
          setCurrentForm={setHpiRevisions}
          restData={{
            className: 'w-full',
            label: 'HPI Narrative',
            placeholder: 'Add narrative here',
            id: 'Narrative-hpi',
            'data-qa': 'narrative-hpi',
            name: 'Narrative-hpi',
            value: hpiRevisions?.narrative,
            forwardedRef: textAreaRef,
            formName: 'hpi',
            customFormId: ''
          }}
          narrativeOptions={isAdvancedForm ? [...narrativeOptions, ...iaRa(options)] : []}
        />
      )}
    </div>
  );
};

export default HPIRevisions;
