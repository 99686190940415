import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useNavigate } from 'react-router-dom';
import {
  cancelAppointment,
  handleCreationAction,
  handleRemind,
  updateAppointments
} from '../lib/helper';

const MultiActions = ({
  multiActionsModal,
  setMultiActionsModal,
  type,
  appointments,
  setSelectedRows,
  appointmentStatus
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  return (
    <>
      {multiActionsModal.appointmentCancel && (
        <Confirm
          handleOpen={multiActionsModal.appointmentCancel}
          handleClose={() =>
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentCancel: false
            }))
          }
          handleContinue={() =>
            cancelAppointment({
              setLoading,
              navigate,
              selectedRows: appointments.map((a) => a?.data?.id),
              queryClient,
              setMultiActionsModal,
              setSelectedRows
            })
          }
          title="Cancel appointments"
          message="Are you sure you want to cancel these appointments?"
          primaryBtnTxt="Cancel appointments"
          secondaryBtnTxt="Keep"
          icon="new-calendar-red"
          variant="danger"
          loading={loading}
        />
      )}

      {multiActionsModal.appointmentStatus && (
        <Confirm
          handleOpen={multiActionsModal.appointmentStatus}
          handleClose={() =>
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentStatus: false
            }))
          }
          handleContinue={() =>
            updateAppointments({
              queryClient,
              navigate,
              setMultiActionsModal,
              status: appointmentStatus,
              appointments,
              setSelectedRows
            })
          }
          title="Update appointments status"
          message="Are you sure you want to update the status for these appointments?"
          primaryBtnTxt="Update status"
          secondaryBtnTxt="Cancel"
          icon="new-calendar"
        />
      )}

      {multiActionsModal.appointmentCreation && (
        <Confirm
          handleOpen={multiActionsModal.appointmentCreation}
          handleClose={() =>
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentCreation: false
            }))
          }
          handleContinue={() =>
            handleCreationAction({
              navigate,
              type,
              appointments: appointments.map((a) => a?.data?.id),
              setSelectedRows,
              setMultiActionsModal
            })
          }
          title="Appointment Creation Reminder"
          message="Are you sure you want to send creation reminders for these appointments?"
          primaryBtnTxt="Send Creation Reminders"
          secondaryBtnTxt="Cancel"
          icon="new-calendar"
        />
      )}
      {multiActionsModal.appointmentReminder && (
        <Confirm
          handleOpen={multiActionsModal.appointmentReminder}
          handleClose={() =>
            setMultiActionsModal((prevState) => ({
              ...prevState,
              appointmentReminder: false
            }))
          }
          handleContinue={() =>
            handleRemind({
              navigate,
              type,
              appointments: appointments.map((a) => a?.data?.id),
              setSelectedRows,
              setMultiActionsModal
            })
          }
          title="Appointment Reminder"
          message="Are you sure you want to send reminders for these appointments?"
          primaryBtnTxt="Send Reminders"
          secondaryBtnTxt="Cancel"
          icon="new-calendar"
        />
      )}
    </>
  );
};

export default MultiActions;
