import React from 'react';
import { getAuthorizationRequired, returnInplanName } from '../../eligibilityHelpers';
import { capitalize } from 'lodash';
import { withErrorBoundary } from '../../../../../shared/Error/Boundary';

const UndefinedBenefitLevelItem = ({ finalItem, it }) => {
  return (
    <div className="flex flex-col !gap-2">
      <h3 className="text-base font-600 text-primary-900">{returnInplanName(it?.[0])}</h3>
      {finalItem.benefit_notes ? (
        <p className="text-sm !font-400 text-primary-900 ">
          {capitalize(finalItem.benefit_notes.toLowerCase())}
        </p>
      ) : null}
      <p className="text-sm !font-600 text-primary-900">
        {finalItem.insurance_type_description || ''} {finalItem.benefit_coverage_description}{' '}
        {finalItem.benefit_period_description || ''}{' '}
        {finalItem.hasOwnProperty('authorization_required') ? (
          <span className="inline">
            {getAuthorizationRequired(finalItem.authorization_required)}
          </span>
        ) : null}
        <span className="inline font-400">
          {finalItem.hasOwnProperty('benefit_amount') ? '$' : null}
          {finalItem.benefit_amount || finalItem.benefit_percent}
          {finalItem.hasOwnProperty('benefit_percent') ? '%' : null}
        </span>
      </p>
      {finalItem.hasOwnProperty('entity_code') ? (
        <p className="text-sm !font-600 text-primary-900">
          {finalItem.entity_description?.[0] + ' ' + finalItem.entity_name?.[0]}
        </p>
      ) : null}
      <p className="text-sm !font-600 text-primary-900">
        {(finalItem.entity_addr_1 ? finalItem.entity_addr_1?.[0] + ', ' : '') +
          (finalItem.entity_city ? finalItem.entity_city?.[0] + ', ' : '') +
          (finalItem.entity_state ? finalItem.entity_state?.[0] + ', ' : '') +
          (finalItem.entity_zip ? finalItem.entity_zip?.[0] : '')}{' '}
      </p>

      {finalItem.entity_website ? (
        <span className="text-sm !font-600 text-primary-900">
          Website
          <a
            className="ml-2 inline"
            href={`http://${finalItem.entity_website}`}
            target="_blank"
            rel="noopener noreferrer">
            {String(finalItem.entity_website).toLowerCase()}
          </a>
        </span>
      ) : null}
    </div>
  );
};

export default withErrorBoundary(UndefinedBenefitLevelItem);
