import React, { useState } from 'react';

import { addDateFields } from 'components/practice/BeyondBilling/Claims/CMS1500/lib/addDateOptions';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';

const CMS1500Fields = ({ formik, disabled }) => {
  const [showCmsFields, setShowCmsFields] = useState(false);
  const cmsFieldsButtonClick = () => {
    const footer = document.getElementById('demographics-cms-date-fields');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <div>
      <div className="flex">
        <label className="text-lg font-600">CMS1500 Fields</label>
        <Button
          size="small"
          type="secondary"
          className="ml-3 w-10"
          onClick={() => {
            setShowCmsFields(!showCmsFields);
            setTimeout(() => {
              cmsFieldsButtonClick();
            }, 300);
          }}
          icon={<Icon icon={showCmsFields ? 'chevron-up' : 'chevron-down'} />}
        />
      </div>
      {showCmsFields && (
        <div
          id="demographics-cms-date-fields"
          className="mt-3 grid grid-cols-4 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
          {addDateFields.map(({ label, value }) => (
            <DatePopover
              key={value}
              placementY="bottom-[48px] right-0"
              label={label}
              onChange={(date) => formik.setFieldValue(value, date)}
              placeholder={label}
              data-qa={value}
              name={value}
              value={formik?.values?.cmsDateFields?.[value] || null}
              error={formik?.errors?.cmsDateFields?.[value]}
              disabled={disabled}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CMS1500Fields;
