import React, { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { useRef } from 'react';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../shared/Icon/Icon';
import useOutsideClick from '../../../../../lib/hooks/useOutsideClick';
import Button from '../../../../shared/Buttons/Button';
import { ia } from '../../../../../lib/helpers/utility';
import toast from 'react-hot-toast';

export default function Filters({
  filters = {},
  setFilters = {},
  filterOptions = [],
  type = '',
  singleSelect = false
}) {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOutsideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(false);
  }, []);

  const isSelected = (value) => filters[type].includes(value);

  const handleMultipleSelect = (value) => {
    setIsOpen(true);

    if (isSelected(value)) {
      handleDeselect(value);
      return;
    }

    setFilters((prevState) => ({ ...prevState, [type]: [...prevState[type], value] }));
    return;
  };

  const handleSingleSelect = (value) => {
    setIsOpen(true);

    if (isSelected(value)) {
      handleDeselect(value);
      return;
    }

    setFilters((prevState) => ({ ...prevState, [type]: [value] }));
    return;
  };

  const handleSelectAll = () => {
    let selectAll = [];

    if (filters[type].length !== filterOptions.length)
      selectAll = filterOptions.map((option) => option.value);

    return setFilters((prevState) => ({ ...prevState, [type]: selectAll }));
  };

  const handleDeselect = (value) => {
    setIsOpen(true);

    setFilters((prevState) => ({
      ...prevState,
      [type]: prevState[type].filter((option) => option !== value)
    }));
    return;
  };

  const applyFilters = () => {
    if (!ia(filters[type])) {
      toast.error('You need to select at least one chart.');
      return;
    }

    setFilters((prevState) => ({ ...prevState, applyFilters: !prevState.applyFilters }));
    setIsOpen(false);
    return;
  };

  const clearFilters = () => {
    setFilters((prevState) => ({
      ...prevState,
      [type]: []
    }));
    applyFilters();
    return;
  };

  return (
    <div ref={ref} className="relative self-center">
      <Listbox>
        <>
          <Listbox.Button
            onClick={() => setIsOpen(!isOpen)}
            open={isOpen}
            className="w-full cursor-default rounded-lg text-sm">
            <span
              className={`text-blue border-b-3 flex items-center gap-1 ${
                isOpen && `border-x-0 border-t-0 border-solid border-blue-400`
              } `}>
              <Icon icon="new-filter" />
              <span className="text-sm text-neutral-600">
                {type === 'chartType' ? 'Chart Type' : 'Gender'}:
              </span>
              <span className="font-900 min-w-[75px] text-sm text-primary-900">
                {filters[type]?.length
                  ? `${filters[type]?.length} Selected`
                  : type === 'chartType'
                    ? 'All Charts'
                    : 'All Genders'}
              </span>
              {isOpen ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
            </span>
          </Listbox.Button>
          {isOpen && (
            <Transition
              unmount={false}
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-[9999] mt-1 min-w-max rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="!p-5">
                  {!singleSelect && (
                    <>
                      <div className="cursor-default !p-2 transition-all hover:rounded-lg hover:bg-primary-50">
                        <Checkbox
                          id="select-all"
                          label={`${
                            filters[type]?.length === filterOptions.length
                              ? 'Unselect all'
                              : 'Select all'
                          }`}
                          color="blue"
                          isChecked={filters[type]?.length === filterOptions.length}
                          handleClick={() => handleSelectAll()}
                        />
                      </div>
                      <hr className="!mb-2 !mt-2" />{' '}
                    </>
                  )}
                  {filterOptions.map((filter, sortIdx) => {
                    const selected = isSelected(filter.value);
                    return (
                      <Listbox.Option
                        key={sortIdx}
                        className={`relative cursor-default select-none rounded-lg !p-2 transition-all hover:bg-primary-50`}
                        value={filter}>
                        <div className="cursor-pointer">
                          <Checkbox
                            label={filter.label}
                            value={filter.value}
                            isChecked={selected}
                            color="blue"
                            radio={singleSelect}
                            handleClick={(event) =>
                              singleSelect
                                ? handleSingleSelect(event.target.value)
                                : handleMultipleSelect(event.target.value)
                            }
                          />
                        </div>
                      </Listbox.Option>
                    );
                  })}
                  <hr className="!mb-2 !mt-2" />
                  <div className="flex justify-between gap-8">
                    {!singleSelect && (
                      <Button
                        text={'Clear'}
                        color="neutral"
                        outlined
                        onClick={clearFilters}
                        size="small"
                      />
                    )}
                    <Button
                      className="w-full"
                      text={'Apply'}
                      outlined
                      onClick={() => applyFilters()}
                      size="small"
                    />
                  </div>
                </div>
              </Listbox.Options>
            </Transition>
          )}
        </>
      </Listbox>
    </div>
  );
}
