import React from 'react';

import { ia } from 'lib/helpers/utility';

import { AlertContent } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Typography from 'components/shared/Typography/Typography';

const Errors = ({ errors = [] }) => {
  return (
    <>
      {ia(errors) && (
        <div className="mb-2 bg-white rounded-lg">
          <Typography size="medium" variant="p" className="p-2">
            Claim Errors / Responses {`(${errors?.length})`}
          </Typography>
          <div className="grid gap-2 p-2 overflow-y-scroll max-h-[170px]">
            {errors?.map((error, index) => (
              <AlertContent
                key={index}
                width="full"
                title={error.message}
                color="danger"
                className='shadow-md shadow-neutral-400"'
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default withErrorBoundary(Errors);
