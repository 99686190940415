import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getCurrentPractice } from 'api/Practice';

export const useCurrentPractice = (options = {}) => {
  const navigate = useNavigate();
  return useQuery(['currentPractice'], () => getCurrentPractice(navigate, options), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
