import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Popover } from '@headlessui/react';

import { getPayerAddresses } from 'api/Payer';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

import { generatePDF } from '../CMS1500/lib/generatePDF';

const PrintPopover = ({ claims }) => {
  const [cms1500Popover, setCms1500Popover] = useState(false);
  const [onlyTextPopover, setOnlyTextPopover] = useState(false);

  const navigate = useNavigate();

  const handlePrint = async ({ withBackground = true, withPayerNameAddress = true }) => {
    const payersId = [...new Set(claims.map((claim) => claim.payerid))];
    const { addresses } = await getPayerAddresses(navigate, { payersId });

    const claimsWithAddresses = claims.map((claim) => ({
      ...claim,
      payerAddress: addresses?.find((addr) => addr.payerId === claim.payerid)
    }));

    for (const { payerAddress, ...claim } of claimsWithAddresses) {
      await generatePDF({
        claim,
        address: payerAddress,
        withBackground,
        withPayerNameAddress
      });
    }
  };

  return (
    <Popover className="relative flex justify-end">
      {({ close }) => (
        <>
          <Popover.Button
            color="neutral"
            data-qa="claim-pdf-type"
            as={Button}
            icon="new-printer-bulk"
            type="link"
            text="Print"
            size="small"
            className="text-neutral-700"
          />
          <Popover.Panel className="absolute right-0 bottom-full z-10 mb-[10px] w-max min-w-[120px] rounded-[4px] bg-white !py-2 shadow-medium">
            <div
              data-qa="cms1500-print-btn"
              className="relative flex cursor-default items-center justify-between gap-1 !px-2 py-[6px] transition-all hover:bg-primary-50"
              onMouseEnter={() => {
                setCms1500Popover(true);
              }}
              onMouseLeave={() => {
                setCms1500Popover(false);
              }}>
              <span className="text-sm text-primary-900">CMS 1500</span>
              <Icon icon="new-chevron-right" />
              {cms1500Popover && (
                <div className="absolute -top-3 left-full z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                  <div
                    data-qa="load-from-appointments-templates"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCms1500Popover(false);
                      handlePrint({ withBackground: true, withPayerNameAddress: true });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      With payer name and address
                    </span>
                  </div>
                  <div
                    data-qa="create-new-appointment-template"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCms1500Popover(false);
                      handlePrint({ withBackground: true, withPayerNameAddress: false });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      Without payer name and address
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div
              data-qa="onlytext-print-btn"
              className="relative flex cursor-default items-center justify-between gap-1 !px-2 py-[6px] transition-all hover:bg-primary-50"
              onMouseEnter={() => {
                setOnlyTextPopover(true);
              }}
              onMouseLeave={() => {
                setOnlyTextPopover(false);
              }}>
              <span className="text-sm text-primary-900">Only text</span>
              <Icon icon="new-chevron-right" />
              {onlyTextPopover && (
                <div className="absolute -top-3 left-full z-30 mt-1 grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
                  <div
                    data-qa="load-from-appointments-templates"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOnlyTextPopover(false);
                      handlePrint({ withBackground: false });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      With payer name and address
                    </span>
                  </div>
                  <div
                    data-qa="create-new-appointment-template"
                    className="flex gap-x-[9px] py-[6px] !pl-4 pr-[14px] transition-all hover:bg-primary-50"
                    onClick={(e) => {
                      e.stopPropagation();
                      setOnlyTextPopover(false);
                      handlePrint({ withBackground: false, withPayerNameAddress: false });
                      close();
                    }}>
                    <span className="text-sm font-400 text-primary-900">
                      Without payer name and address
                    </span>
                  </div>
                </div>
              )}
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default PrintPopover;
