import React, { useImperativeHandle, useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

const SessionQuotesAndContents = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const sessionQuotesAndContentsRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { sessionQuotesAndContents: clinicalNote?.epn?.sessionQuotesAndContents } }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      epn: {
        ...clinicalNote.epn,
        [name ?? key]: value ?? e
      }
    });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Add quotes here"
      id="sessionQuotesAndContents"
      name="sessionQuotesAndContents"
      className="min-h-[200px]"
      value={clinicalNote?.epn?.sessionQuotesAndContents}
      onChange={(e) => handleChange(e, 'sessionQuotesAndContents')}
      transcribing
      forwardedRef={sessionQuotesAndContentsRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default SessionQuotesAndContents;
