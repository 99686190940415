import Board from 'components/shared/CustomizableBoard/Board';
import StickyButton from 'components/shared/CustomizableBoard/StickyButton';
import DateRangePicker from 'components/shared/DateRange/DateRangePicker';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';
import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { useDashboard } from 'lib/hooks/queries/widgets/useDashboard';
import usePageTitle from 'lib/hooks/usePageTitle';
import React from 'react';
import Header from './components/Header';
import CommunicationsUsage from './widgets/CommunicationsUsage/CommunicationsUsage';
import DashboardPanel from './widgets/DashboardPanel/DashboardPanel';
import EncounterReports from './widgets/EncounterReports/EncounterReports';
import HealthcareProgramEfficiencySaving from './widgets/HealthcareProgramEfficiencySaving';
import Income from './widgets/Income';
import NewPatients from './widgets/NewPatients';
import Tasks from './widgets/Tasks/Tasks';
import UpcomingAppointments from './widgets/UpcomingAppointments/UpcomingAppointments';

const widgets = {
  dashboard_panel: DashboardPanel,
  upcoming_appointments: UpcomingAppointments,
  communications_usage: CommunicationsUsage,
  healthcare_program_efficiency_saving: HealthcareProgramEfficiencySaving,
  tasks: Tasks,
  income: Income,
  encounter_reports: EncounterReports,
  new_patients: NewPatients
};

export default function Dashboard() {
  return (
    <DashboardContextProvider>
      <BoardContextProvider
        layouts={{
          lg: [],
          xmd: [],
          md: [],
          sm: []
        }}
        cols={{ lg: 3, xmd: 12, md: 12, sm: 1 }}
        board="dashboard">
        <DashboardBoard />
      </BoardContextProvider>
    </DashboardContextProvider>
  );
}

function DashboardBoard() {
  const { ranges, setRanges } = useDashboardContext();
  const params = {
    startAt: ranges.startDate,
    endAt: ranges.endDate
  };
  const { data } = useDashboard(params);
  const { editMode } = useBoardContext();

  usePageTitle('Dashboard');

  return (
    <div
      className="h-full overflow-y-auto overflow-x-hidden bg-neutral-50 !pb-8"
      data-dd-privacy="allow">
      <Header />
      <div className="-mt-14">
        <Board
          dashboardData={data}
          breakpoints={{ lg: 1200, xmd: 800, md: 710, sm: 0 }}
          rowHeight={145}
          widgets={widgets}
          margin={[12, 12]}>
          <div className="absolute -top-[74px] right-[14px] z-20 flex items-center gap-2">
            {!editMode && (
              <DateRangePicker
                iconColor="white"
                buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.16)] hover:!border-[rgb(255,255,255,0.72)] sm:!bg-[#55C6EF] !py-0 !px-2 flex min-w-[30px] min-h-[30px]"
                buttonTextColor="text-white border-danger-500"
                customStyling={window.innerWidth < 677 ? { right: '-54px' } : {}}
                ranges={[
                  {
                    startDate: ranges?.startDate,
                    endDate: ranges?.endDate,
                    key: 'selection'
                  }
                ]}
                onChange={(e) => {
                  setRanges((prevState) => ({
                    ...prevState,
                    startDate: e.selection.startDate,
                    endDate: e.selection.endDate
                  }));
                }}
                startDate={ranges?.startDate}
                endDate={ranges?.endDate}
                minDate={-3650}
                maxDate={3650}
              />
            )}
            <StickyButton
              iconColor="white"
              className="!relative !top-auto !m-0 !h-auto"
              buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)]"
            />
          </div>
        </Board>
      </div>
    </div>
  );
}
