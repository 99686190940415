import React from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';

import { refreshERA } from 'api/ERA';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';

const HeaderActions = ({ category }) => {
  const isEra = category === 'era';

  const navigate = useNavigate();

  const { mutate: refreshERAs, isLoading } = useCustomMutation({
    mutationFn: () => refreshERA(navigate),
    successMessage: "ERA's refreshed successfully!",
    errorMessage: "Failed to refresh ERA's!",
    invalidateQueryKey: ['eras']
  });

  return (
    <div className="flex items-center gap-2">
      {isEra && (
        <Button
          outlined
          shade="700"
          text="Refresh"
          color="neutral"
          icon="new-update"
          disabled={isLoading}
          onClick={refreshERAs}
          loadingIcon={isLoading}
          textClassName="text-sm leading-[114%] text-neutral-700"
          className={cs(
            'h-[32px] px-3 py-0 border-neutral-200',
            'hover:bg-neutral-50 hover:border-neutral-300 cursor-pointer'
          )}
        />
      )}
    </div>
  );
};

export default HeaderActions;
