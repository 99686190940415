import React from 'react';
import checkinBackgroundPNG from '../assets/Images/checkin-background.png';
import Button from 'components/shared/Buttons/Button';
import { useNavigate } from 'react-router-dom';

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="h-[100vh] overflow-hidden bg-transparent p-[1.2rem]">
      <img
        src={checkinBackgroundPNG}
        className="absolute top-0 left-0 z-10 h-full w-full object-cover"
      />
      <div className="relative z-20 flex h-full overflow-hidden rounded-xl border-[0.75px] border-solid border-white bg-slate-50 py-8 px-10 flex-col">
        <div className="flex flex-row gap-4">
          {localStorage.getItem('myriad-kiosk-prev-url') && (
            <Button
              color="primary"
              text="Back"
              onClick={() => navigate(localStorage.getItem('myriad-kiosk-prev-url'))}
            />
          )}
          <div className=" text-3xl text-primary-500">Terms & Conditions</div>
        </div>
        <hr />
        {/* </div> */}
        <div className="h-80vh overflow-y-scroll w-full text-lg">
          <div>
            <div>
              <div className="flex flex-col">
                <div className="italic text-primary-500">November 1, 2023</div>
                <h4>Table of Contents</h4>
                <ol className="list-decimal text-primary-900 capitalize font-700  " start="1">
                  <li>
                    <p>SCOPE AND PURPOSE:</p>
                  </li>
                  <li>
                    <p>WHAT DOES MYRIAD HEALTH DO?</p>
                  </li>
                  <li>
                    <p>WHAT PERSONAL INFORMATION DO WE COLLECT?</p>
                  </li>
                  <li>
                    <p>HOW DO WE COLLECT YOUR INFORMATION?</p>
                  </li>
                  <li>
                    <p>HOW DO WE USE YOUR INFORMATION?</p>
                  </li>
                  <li>
                    <p>HOW DO WE SHARE YOUR INFORMATION?</p>
                  </li>
                  <li>
                    <p>RETENTION AND PROTECTION OF DATA</p>
                  </li>
                  <li>
                    <p>COOKIES AND AUTOMATED DATA COLLECTION TECHNOLOGIES</p>
                  </li>
                  <li>
                    <p>SOCIAL MEDIA AND OTHER INTEGRATIONS</p>
                  </li>
                  <li>
                    <p>STATE CONSUMER PRIVACY RIGHTS</p>
                  </li>
                  <li>
                    <p>MINORS</p>
                  </li>
                  <li>
                    <p>INTERNATIONAL TRANSFER</p>
                  </li>
                  <li>
                    <p>MANDATORY DISCLOSURES</p>
                  </li>
                  <li>
                    <p>CERTIFICATION AND COST DISCLOSURES.</p>
                  </li>
                  <li>
                    <p>EHI EXPORT REQUEST PROCESS.</p>
                  </li>
                  <li>
                    <p>UPDATES TO THIS PRIVACY POLICY</p>
                  </li>
                  <li>
                    <p>CONTACT</p>
                  </li>
                </ol>
              </div>
              <div>
                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="1">
                    <li>
                      <p>SCOPE AND PURPOSE:</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>
                    This privacy policy (“Policy”) describes how Myriad Health LLC and our parent
                    companies, subsidiaries, and affiliated companies (“we,” “us,” “our”, and/or
                    “Myriad”) may collect, use, and share information about you that we obtain
                    through myriad.health and other websites and applications that link to this
                    Policy (collectively, the “Sites”). This Policy also applies to any information
                    we collect offline, such as when you visit our offices, attend Myriad events, or
                    interact with our representatives at other events, or in other contexts in which
                    we make this Policy available to you.
                  </p>
                </div>

                <div>
                  <p>
                    This Policy does not apply to Myriad product offerings that have their own
                    privacy policies, or to websites of third parties to which we provide links. We
                    do not control and are not responsible for the privacy practices of the websites
                    of other entities and we urge you to review any applicable third-party privacy
                    policies for yourself.
                  </p>
                </div>

                <div>
                  <p>
                    Our processing of data on behalf of our healthcare provider customers is
                    governed by the agreements we enter into with our customers, which may include
                    Business Associate Agreements as applicable and required under the Health
                    Insurance Portability and Accountability Act (“HIPAA”). Your healthcare provider
                    may also have its own privacy practices and/or policies that govern its
                    collection and use of your data. We are not responsible for how your healthcare
                    provider treats your information, and we recommend you review their privacy
                    policies.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="2">
                    <li>
                      <p>WHAT DOES MYRIAD HEALTH DO?</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    Myriad is a provider of network-enabled services for healthcare and medical
                    services customers nationwide, headquartered in the United States. We offer
                    cloud-based solutions in healthcare to deliver providers with multiple potential
                    solutions including electronic health records, and patient engagement tools.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="3">
                    <li>
                      <p>WHAT PERSONAL INFORMATION DO WE COLLECT?</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    Personal information is data that can be used to identify you. The types of
                    personal information that we collect depend on your interactions with us. Over
                    the last 12 months, we have collected personal information that generally fall
                    into the following categories:
                  </p>
                </div>

                <div>
                  <p>Identifiers, such as your name, email address, or IP address.</p>
                </div>

                <div>
                  <p>
                    Information contained in our customer records, such as postal address or
                    telephone number.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    Commercial information, such as information regarding products or services you
                    purchased.
                  </p>
                </div>

                <div>
                  <p>
                    Internet or other electronic network activity information, such as how you
                    interact with our website.
                  </p>
                </div>

                <div>
                  <p>Professional or employment information, such as your job title or employer.</p>
                </div>

                <div>
                  <p>
                    Geolocation data, such as your general location information (e.g., city/state)
                    which may be collected or derived from your IP address. In addition, some of our
                    services may request your precise location information via GPS-based
                    functionality to allow certain features to work. We will ask your permission
                    before we collect or use precise location information, in accordance with any
                    applicable legal requirements to the extent precise geolocation information is
                    considered “sensitive” information under applicable law.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="4">
                    <li>
                      <p>HOW DO WE COLLECT YOUR INFORMATION?</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>We may collect information from you in the following ways:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        We collect information you provide directly to us, such as when you
                        voluntarily enter information into fields on the Sites, sign up for or
                        request certain services or information, agree to participate in our
                        surveys, or call our customer service. Depending on how you interact with
                        us, we may ask for your name, practice/organization name, address, email
                        address, telephone number, and type of user (for example, patient, provider
                        or partner). If you have an account with us, we may also collect your
                        username or other login information (e.g., Practice ID) you use to log into
                        or access your account. If you visit our offices or attend in-person Myriad
                        events, we may collect information to protect the health and safety of our
                        personnel, clients, guests, and the general public, such as health and
                        travel information or any other information you provide to us.
                      </p>
                    </li>
                    <li>
                      <p>
                        When you access our Sites, we may collect information about your visit and
                        your device using automatic data collection technologies as described in the
                        “Cookies and Automated Data Collection Technologies” section below. This
                        information may include IP address, geolocation information, browser type
                        and version, device type, mobile device identifiers, and information
                        reflecting how you searched, browsed, and were directed to the Sites,
                        including mouse movement, click, touch, scroll, and keystroke activity.
                      </p>
                    </li>
                    <li>
                      <p>
                        We may also collect information from other sources, such as lead generation
                        companies, social networks, and business partners that offer co-branded
                        services or help us sell or distribute our products. We may also collect
                        information from other users of our services or from available sources.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="5">
                    <li>
                      <p>HOW DO WE USE YOUR INFORMATION?</p>
                    </li>
                  </ol>
                </div>
              </div>
              <div>
                <div>
                  <p>We use your information:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        In ways that you would expect us to based on why we collected it. For
                        example, if you contact us with a request for information about our products
                        or services, we will use your information to respond to your request.
                      </p>
                    </li>
                    <li>
                      <p>
                        To provide, enhance and improve our services, including to optimize our
                        Sites’ functionality and identify our visitors’ and users’ areas of
                        interest. For example, when you participate in our surveys, screeners,
                        and/or information gathering sessions, or otherwise provide feedback, we may
                        use that feedback to develop new products and services.
                      </p>
                    </li>
                    <li>
                      <p>
                        To identify and authenticate you, such as to determine and validate whether
                        you are an existing user of our services or products or a prospective
                        client.
                      </p>
                    </li>
                    <li>
                      <p>
                        To enable cross-device/cross-context tracking for an account you may have
                        with us. For example, you might use multiple browsers on a single device, or
                        use various devices (such as desktops, smartphones, and tablets), which can
                        result in your having multiple accounts or profiles across various contexts
                        and devices. Cross-device/cross-context technology may be used to connect
                        these various accounts or profiles and the corresponding data from the
                        different contexts and devices so you can more easily use your account(s).
                      </p>
                    </li>
                    <li>
                      <p>
                        To communicate with you, such as send you emails, solicitations,
                        invitations, newsletters, awareness campaigns, and announcements.
                      </p>
                    </li>
                    <li>
                      <p>
                        To maintain the safety, security, and integrity of our Sites and services,
                        and for our own internal legal compliance purposes.
                      </p>
                    </li>
                    <li>
                      <p>
                        To protect the health and safety of our personnel, clients, guests, and the
                        general public.
                      </p>
                    </li>
                    <li>
                      <p>
                        For other purposes explained at the time of collection, or for other
                        business purposes consistent with the context of the collection of your
                        information.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    We may use information that does not identify you and could not reasonably be
                    used to identify you (including information that has been aggregated,
                    anonymized, or de-identified) for any purpose except as prohibited by applicable
                    law.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="6">
                    <li>
                      <p>HOW DO WE SHARE YOUR INFORMATION?</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    We disclose the following categories of personal information for commercial
                    purposes: identifiers, commercial information, internet activity, and
                    geolocation data.
                  </p>
                </div>

                <div>
                  <p>We share information outside of Myriad in the following circumstances:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        With service providers and vendors that provide services to us, such as to
                        provide analytics, manage our content, administer ads, provide insights to
                        us related to marketing needs, for market research purposes, and to analyze
                        our marketing efforts.
                      </p>
                    </li>
                    <li>
                      <p>
                        With third parties that provide use audience matching services. For
                        instance, we incorporate the Facebook pixel on our non patient facing Sites
                        and may share your email address with Facebook as part of our use of
                        Facebook Custom Audiences. This helps us find more potential customers that
                        have similar interests as you do. Some technology services may provide us
                        with their own data, which is then uploaded into another technology service
                        for matching common factors between those datasets.
                      </p>
                    </li>
                    <li>
                      <p>
                        With our related entities and/or affiliates for business purposes including,
                        but not limited to, customer support, marketing, technical and business
                        operations. We also may share information with affiliates for commercial
                        purposes.
                      </p>
                    </li>
                    <li>
                      <p>
                        When you make your information public or otherwise accessible to other users
                        through the Sites, such as information that you post related to reviews of
                        partners or on the Success Community. Please think carefully before posting
                        such information as you are solely responsible for the content you post and
                        the potential use of such information by others. Once you have posted
                        information, you may not be able to edit or delete such information.
                      </p>
                    </li>
                    <li>
                      <p>
                        With our customers, when you engage in our surveys as an authorized user,
                        through the onboarding process, through surveys collecting feedback on how
                        we are doing, surveys administered post interaction with us related to
                        support or training, and other surveys, including focus groups and usability
                        design activities such as click tests, card sorts, and other surveys and
                        tests you participate in. We typically notify you in advance that we will
                        share your information with our customers if you complete a survey.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>We also share information with other entities in the following situations:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        Where you have given us your consent to share or use information about you;
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        When we believe that we need to share information about you to provide a
                        service that you have requested from us or from others;
                      </p>
                    </li>
                    <li>
                      <p>
                        Where we are required by law or other legal process to disclose information,
                        and where required, in response to a lawful request by public authorities,
                        including meeting national security or law enforcement requirements;
                      </p>
                    </li>
                    <li>
                      <p>
                        Where we believe that it is necessary to avoid liability or violations of
                        the law;
                      </p>
                    </li>
                    <li>
                      <p>
                        To protect the rights, property, life, health, security, and safety of us,
                        the Sites, or anyone else;
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        To an actual or potential buyer (and its agents and advisers) in connection
                        with any actual or proposed purchase, merger, or acquisition of all or any
                        part of our business.
                      </p>
                    </li>
                    <li>
                      <p>
                        At your request or direction, such as when you choose to share information
                        with a social network about your activities on the Sites; or
                      </p>
                    </li>
                    <li>
                      <p>
                        To any other person with notice to you and your consent to the disclosure.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    Notwithstanding the above, we may share information that does not identify you
                    and could not reasonably be used to identify you (including information that has
                    been aggregated, anonymized, or de-identified) except as prohibited by
                    applicable law.
                  </p>
                </div>

                <div>
                  <p>
                    With respect to deidentified patient information, we disclose such deidentified
                    information to third parties only when permissible pursuant to our contractual
                    commitments with our customers and in accordance with Health Insurance
                    Portability and Accountability Act (“HIPAA”) requirements or other applicable
                    law. We employ the safe harbor method or the expert determination method, as
                    enumerated under HIPAA. Those third parties to whom the deidentified data is
                    disclosed are third party service providers/vendors with whom we have
                    relationships and/or academic researchers and/or institutions that are
                    contributing to healthcare.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="7">
                    <li>
                      <p>RETENTION AND PROTECTION OF DATA</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    While we maintain your information, we protect it using administrative,
                    physical, and technical security safeguards designed to protect your
                    information. When we collect certain sensitive information, we encrypt the
                    transmission of that information using secure socket layer technology (SSL).
                    Despite these measures, we cannot guarantee the security of the information we
                    maintain about you.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    We retain information for different periods of time depending on the purposes
                    for which we collect and use it, as described in this Policy. We will not retain
                    information for longer than needed to fulfill these purposes unless a longer
                    retention period is required to comply with legal obligations. Also, there may
                    be technical or other operational reasons where we are unable to delete or
                    de-identify your information. Where this is the case, we will take reasonable
                    measures to prevent further processing your information.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="8">
                    <li>
                      <p>COOKIES AND AUTOMATED DATA COLLECTION TECHNOLOGIES</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    Our Sites use cookies and similar technologies (such as pixels and pixel tags,
                    ad tags, Software Development Kits (“SDKs”) clear GIFs, session replay scripts,
                    and Javascript). Cookies are small text files placed on your device that help
                    the Sites work and help us gather statistical information about how visitors use
                    the Sites, improve your experience, and maintain security.
                  </p>
                </div>

                <div>
                  <p>
                    Cookies also help us deliver advertisements, some of which may be tailored to
                    your behaviors on the Sites. We engage third parties to help us deliver these
                    advertisements, and these third parties may collect your information over time
                    and across our Sites (and third party sites) in order to associate different
                    devices you use and further gain insights into the goods and services that may
                    interest you.
                  </p>
                </div>

                <div>
                  <p>
                    To exercise your options with respect to cookies, please select “Cookie
                    Preferences” on the banner that is visible at the bottom of our website, or
                    click link on the bottom of myriad.health labeled, “Cookie Preferences”.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="9">
                    <li>
                      <p>SOCIAL MEDIA AND OTHER INTEGRATIONS</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    Some of our Sites and services may have social media and technology integrations
                    that are operated or controlled by separate entities. We also may collect
                    information from third party social media and marketing companies to enhance our
                    data sets. Some examples include:
                  </p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        Links. Our Sites may include links that hyperlink to websites, platforms,
                        and other services not operated or controlled by us.
                      </p>
                    </li>
                    <li>
                      <p>
                        Liking, Sharing, and Logging-In. We may embed a pixel or SDK on our Sites
                        that allows you to “like” or “share” content on, or log in to, your account
                        through social media. If you choose to engage with such integration, we may
                        receive information from the social network that you have authorized to
                        share with us. Please note that the social network may independently collect
                        information about you through the integration.
                      </p>
                    </li>
                    <li>
                      <p>
                        Brand Pages and Chatbots. We may offer our content through social media. Any
                        information you provide to us when you engage with our social media content
                        is treated in accordance with this Policy. Also, if you publicly reference
                        our Sites on social media (e.g., by using a hashtag associated with Myriad
                        in a tweet or post), we may use your reference on or in connection with our
                        Sites.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        Platform Linking. Our Sites may offer you the ability to link to another
                        service or partner to retrieve certain data about your account on that
                        service. For example, if you link your account to one of the partners in the
                        Myriad marketplace, the linking may allow us to obtain information such as
                        your username and email address. For more information about how these
                        platforms handle information about you, please refer to their respective
                        privacy policies and terms of use.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    Please note that when you interact with other entities, including when you leave
                    our Sites, those entities may independently collect information about you and
                    solicit information from you. The information collected and stored by those
                    entities remains subject to their own policies and practices, including what
                    information they share with us, your rights and choices on their services and
                    devices, and whether they store information in the U.S. or elsewhere. We
                    encourage you to familiarize yourself with and consult their privacy policies
                    and terms of use.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="10">
                    <li>
                      <p>STATE CONSUMER PRIVACY RIGHTS</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>Rights for Residents of Applicable States</p>
                </div>

                <div>
                  <p>
                    If you are a resident of a state with applicable consumer privacy laws, you may
                    have the following rights:
                  </p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>To confirm whether we process your personal information.</p>
                    </li>
                    <li>
                      <p>To access your personal information.</p>
                    </li>
                    <li>
                      <p>To correct inaccuracies in your personal information.</p>
                    </li>
                    <li>
                      <p>To delete your personal information that we have obtained.</p>
                    </li>
                    <li>
                      <p>
                        To receive a copy of your personal information in a portable and readily
                        usable format.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <div>
                  <ul className="list-disc">
                    <li>
                      <p>To opt out of the sale or sharing of your personal information.</p>
                    </li>
                    <li>
                      <p>
                        To opt out of the processing of your personal information for purposes of
                        (i) targeted advertising or (ii) automated decision-making or profiling in
                        furtherance of decisions that produce a legal or similarly significant
                        effect on you.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    If you live in a state that requires specific consent prior to processing your
                    sensitive personal information for certain purposes, we will obtain such and you
                    can withdraw your consent at any time.
                  </p>
                </div>

                <div>
                  <p>Residents of applicable states may exercise the above rights by:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        Submitting a request to
                        <a
                          href="mailto:support@myriad.health"
                          target="_blank"
                          rel="noreferrer noopener">
                          support@myriad.health
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>
                    We may ask you to provide us with information necessary to reasonably verify
                    your identity before responding to your request. We will consider all requests
                    and provide our response within the time period required by applicable law.
                    Please note, however, that certain information may be exempt from such requests.
                    If we deny your request in whole or in part, you may have the right to appeal
                    the decision. In such circumstances, we will provide you with information
                    regarding the appeals process.
                  </p>
                </div>

                <div>
                  <p>Response Timing and Format</p>
                </div>

                <div>
                  <p>
                    We endeavor to respond to a consumer request within forty-five (45) days of its
                    receipt. If we require more time (up to 90 days), we will inform you of the
                    reason and extension period in writing.
                  </p>
                </div>

                <div>
                  <p>California Resident Privacy Notice</p>
                </div>
              </div>

              <div>
                <p>
                  Below, please find the categories of information we may have collected about you
                  in the last twelve months, the purposes for the collection, and the third parties
                  with whom your personal information may have been disclosed, shared, or sold. For
                  more information on these practices, please see Sections III-VI.
                </p>
              </div>

              <div>
                <div>
                  <div id="{c84f21e8-b772-410e-a192-60c58377408b}{102}"></div>
                  <table border="1">
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <div>
                              <p>Categories of personal information collected</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div>
                              <p>Purposes for the collection or sharing of personal information</p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div>
                              <p>
                                Third parties with whom personal information may have been
                                disclosed, shared, or sold
                              </p>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <ul className="list-disc">
                            <li>
                              <p>Identifiers</p>
                            </li>
                            <li>
                              <p>Information contained in our customer records</p>
                            </li>
                            <li>
                              <p>Commercial information</p>
                            </li>
                            <li>
                              <p>Internet or other electronic network activity information</p>
                            </li>
                            <li>
                              <p>Professional or employment information</p>
                            </li>
                            <li>
                              <p>Geolocation data</p>
                            </li>
                            <li>
                              <p>Inferences drawn from other personal information</p>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <ul className="list-disc">
                            <li>
                              <p>To provide the Sites</p>
                            </li>
                            <li>
                              <p>To improve the Sites</p>
                            </li>
                            <li>
                              <p>To personalize the Sites</p>
                            </li>
                            <li>
                              <p>Marketing and advertising</p>
                            </li>
                            <li>
                              <p>Business operations</p>
                            </li>
                            <li>
                              <p>Where you have given us your consent</p>
                            </li>
                            <li>
                              <p>As required by applicable law</p>
                            </li>
                            <li>
                              <p>
                                To evaluate or conduct a merger, divestiture, restructuring,
                                reorganization, dissolution, or other sale or transfer of some or
                                all of our assets
                              </p>
                            </li>
                          </ul>
                        </td>
                        <td>
                          <div>
                            <div>
                              <ul className="list-disc">
                                <li>
                                  <p>Service providers</p>
                                </li>
                                <li>
                                  <p>Third Party Partners</p>
                                </li>
                                <li>
                                  <p>Our related entities</p>
                                </li>
                                <li>
                                  <p>
                                    Other users through the Sites (when you make your information
                                    public or otherwise accessible)
                                  </p>
                                </li>
                                <li>
                                  <p>With our customers</p>
                                </li>
                                <li>
                                  <p>Where you have given us your consent</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    If you are a California resident, you may have the following rights with respect
                    to the personal information we process about you:
                  </p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        To request information about the categories of personal information we have
                        collected about you, the categories of sources from which we collected the
                        personal information, the purposes for collecting or sharing the personal
                        information, the categories of third parties with whom we have shared or
                        sold your personal information, and the specific pieces of personal
                        information we have collected about you.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        To request that we delete personal information that we have collected from
                        you.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        To request that we correct inaccurate personal information that we maintain
                        about you.
                      </p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>To opt out of the sale or sharing of your personal information.</p>
                    </li>
                  </ul>
                </div>

                <div>
                  <p>California residents may exercise the above rights by:</p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>
                        Submitting a request to
                        <a
                          href="mailto:support@myriad.health"
                          target="_blank"
                          rel="noreferrer noopener">
                          support@myriad.health
                        </a>
                      </p>
                    </li>
                  </ul>
                </div>
                <div>
                  <p lang="EN-US">914-431-7335</p>
                </div>

                <div>
                  <p>
                    Only you, or someone legally authorized to act on your behalf, may make a
                    verifiable consumer request related to your personal information. You may also
                    make a verifiable consumer request on behalf of your minor child.
                  </p>
                </div>

                <div>
                  <p>
                    We may ask you to provide us with information necessary to reasonably verify
                    your identity before responding to your request. We may require you to use your
                    email address in order to perform such verification. We will consider all
                    requests and provide our response within the time period required by applicable
                    law. Please note, however, that certain information may be exempt from such
                    requests. If we deny your request in whole or in part, you may have the right to
                    appeal the decision. In such circumstances, we will provide you with information
                    regarding the appeals process.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <p>
                    You may only make a consumer request for access or data portability twice within
                    a 12-month period. We will not discriminate against you for exercising any of
                    your rights.
                  </p>
                </div>

                <div>
                  <p>Response Timing and Format</p>
                </div>

                <div>
                  <p>
                    We endeavor to respond to a consumer request within forty-five (45) days of its
                    receipt. If we require more time (up to 90 days), we will inform you of the
                    reason and extension period in writing.
                  </p>
                </div>

                <div>
                  <p>
                    Any disclosures we provide will only cover the 12-month period preceding the
                    consumer request's receipt. The response we provide will also explain the
                    reasons we cannot comply with a request, if applicable.
                  </p>
                </div>

                <div>
                  <p>Other California Privacy Rights</p>
                </div>

                <div>
                  <p>
                    California's "Shine the Light" law (Civil Code Section § 1798.83) permits users
                    of our Sites that are California residents to request certain information
                    regarding our disclosure of personal information to third parties for their
                    direct marketing purposes in particular: Customers who are residents of
                    California may request (i) a list of the categories of personal information
                    disclosed by us to third parties during the immediately preceding calendar year
                    for those third parties’ own direct marketing purposes; and (ii) a list of the
                    categories of third parties to whom we disclosed such information. To make such
                    a request, please write us to support@myriad.health . We may require additional
                    information from you to allow us to verify your identity and we are only
                    required to respond to requests once during any calendar year.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="11">
                    <li>
                      <p>MINORS</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    The Sites are intended for a general audience and are not intended for minors
                    under the age of eighteen. Myriad does not wish to obtain any information from
                    or about such minors through the Sites. If you are under eighteen years old, do
                    not use the Sites.
                  </p>
                </div>

                <div>
                  <p>
                    We do not knowingly gather personal information (as defined by the U.S.
                    Children’s Privacy Protection Act, or “CPPA”) about children under the age of
                    13. If you are a parent or guardian and you believe we have collected
                    information from your child in a manner not permitted by law, contact us using
                    the information in the “Contact” section below. We will remove the data to the
                    extent required by applicable laws.
                  </p>
                </div>

                <div>
                  <p>
                    We do not knowingly “sell,” as that term is defined under the CPPA, the personal
                    information of minors under 16 years old who are California residents.
                  </p>
                </div>
              </div>
              <div>
                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="12">
                    <li>
                      <p>INTERNATIONAL TRANSFER</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    We are based in the U.S. and the information we collect is governed by U.S. law.
                    If you are accessing the Sites from outside of the U.S., please be aware that
                    information collected through the Sites may be transferred to, processed,
                    stored, and used in the U.S. and other jurisdictions. Data protection laws in
                    the U.S. and other jurisdictions may be different from those of your country of
                    residence. Your use of the Sites or provision of any information therefore
                    constitutes your consent to the transfer to and from, processing, usage,
                    sharing, and storage of information about you in the U.S. and other
                    jurisdictions as set out in this Policy.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="13">
                    <li>
                      <p>MANDATORY DISCLOSURES</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    This Health IT Module is compliant with the ONC Certification Criteria for
                    Health IT and has been certified by an ONC–ACB in accordance with the applicable
                    certification criteria adopted by the Secretary of Health and Human Services.
                    This certification does not represent an endorsement by the U.S. Department of
                    Health and Human Services.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="13">
                    <li>
                      <p>CERTIFICATION AND COST DISCLOSURES.</p>
                    </li>
                  </ol>
                </div>
                <div>
                  <p>
                    Your base package for myriad.health is monetized exclusively through credit card
                    processing and credit card processing service fees. Some items may be quantity
                    specific, such as patient texting, email, HIPAA-Compliant E-fax, AI Transcribing
                    minutes, EPCS and E-prescribe licenses, live eligibility checks and data
                    storage. Any needs past the included and generous allotments will be negotiated
                    on a case-by-case basis.
                  </p>
                  <p>Developer: Myriad Systems</p>
                  <p>Product: Myriad Health</p>
                  <p>Version: 1.0</p>
                  <p>Certification number: 15.05.05.3188.MYRD.01.00.0.240308</p>
                  <p>Certification date: March 8, 2024</p>
                  <p>
                    Criteria Certified: 170.315(a)(12), 170.315(b)(10), 170.315(d)(1-9),
                    170.315(d)(12-13), 170.315(g)(4-5)
                  </p>
                </div>
                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="13">
                    <li>
                      <p>EHI EXPORT REQUEST PROCESS</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    At Myriad Health, our commitment to providing seamless access to Electronic
                    Health Information (EHI) is reflected in the intuitive design of our data
                    management features. These features are tailored to meet the needs of our
                    clients, ensuring efficient access and management of health information.
                  </p>
                  <p>
                    <strong>Accessing and Exporting EHI:</strong>
                  </p>
                  <p>
                    To access and export EHI, our platform offers a straightforward process,
                    enabling users to retrieve detailed patient data with ease. This functionality
                    supports various needs, including patient transfers or fulfilling specific
                    record requests.
                  </p>
                  <p>
                    <strong>How to Use:</strong>
                  </p>
                  <p>1. Navigate to the patient charts list within the Myriad Health system.</p>
                  <p>
                    2. Within the patient charts, select one or more patients and click on the
                    option to initiate the EHI export.
                  </p>
                  <p>
                    3. Follow the prompts to generate the export. The system will guide you through
                    selecting the specific data or date ranges you wish to include in the export,
                    culminating in the generation of a CSV document.
                  </p>
                  <p>
                    Our support team is always on standby to assist with the EHI export process or
                    to answer any questions you might have, ensuring a smooth and efficient user
                    experience.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="13">
                    <li>
                      <p>UPDATES TO THIS PRIVACY POLICY</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    We reserve the right to make updates and revisions to this Policy at our
                    discretion and at any time. When we make changes to this Policy, we will post
                    the updated notice on myriad.health and update the effective date. Any changes
                    will be effective as of the “Effective” date. Your continued use of our Sites
                    following the posting of changes constitutes your acceptance of such changes.
                  </p>
                </div>

                <div>
                  <ol className="list-decimal text-primary-900 capitalize mt-[0.5rem]" start="14">
                    <li>
                      <p>CONTACT</p>
                    </li>
                  </ol>
                </div>

                <div>
                  <p>
                    If you have any questions or comments about this Policy, the ways in which
                    Myriad collects and uses your information described here, your choices and
                    rights regarding such use, or you wish to exercise your rights under an
                    applicable state law, please contact us by:
                  </p>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>Calling +1(914)431-7335</p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>Visiting myriad.health/contact</p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="list-disc">
                    <li>
                      <p>Writing to: support@myriad.health</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div>
                <p>
                  If you have a disability and would like to access this Policy in an alternative
                  format, please contact us at
                  <a href="mailto:support@myriad.health" target="_blank" rel="noreferrer noopener">
                    support@myriad.health
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Terms;
