import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import PatientCheckinLogin from '../Login';
import Sidebar from './Sidebar';
import '../style.scss';
import { requestApi } from 'api/Api';
import cs from 'classnames';
import AutoIdle from 'components/Kiosk/components/AutoIdle';
import { useRecoilValue } from 'recoil';
import { userState } from '../state';
import { useCheckinUser } from 'lib/hooks/queries/user/useCheckinUser';
import Navbar from './Navbar';

const DEFAULT_CONTINUE_TEXT = 'Save & Continue';
const DEFAULT_CONTINUE_COLOR = 'primary';

const Wizard = ({
  loginRequest,
  setLoginRequest,
  practitionerId,
  device,
  practiceLogo,
  steps,
  currentStepName,
  currentStepNumber,
  updateCompleteStep,
  updateSteps,
  isEverythingCompleted,
  navigateToIncompleted,
  stepObject,
  setSteps,
  checkinButton,
  checkinInsuranceEnabled,
  handleExternalSubmit,
  appointmentId,
  handleBack,
  customOnSuccess,
  practice,
  numCompleted,
  showButton = true,
  updateChoices,
  isInstantPacket,
  reviewSteps
}) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disableContinue, setDisableContinue] = useState(false);
  const [continueText, setContinueText] = useState(DEFAULT_CONTINUE_TEXT);
  const [continueColor, setContinueColor] = useState(DEFAULT_CONTINUE_COLOR);
  const [kiosk, setKiosk] = useState(false);
  const [fakeModal, setFakeModal] = useState(null);
  const userSt = useRecoilValue(userState);
  const { data } = useCheckinUser({});
  const [hipaaSign, setHipaaSign] = useState();

  const [responses, setResponses] = useState({});

  const [cid, setCid] = useState(appointmentId);
  const { pathname } = useLocation();

  const defaultContinueText = () => {
    setContinueText(DEFAULT_CONTINUE_TEXT);
  };

  const defaultContinueColor = () => {
    setContinueColor(DEFAULT_CONTINUE_COLOR);
  };

  useEffect(() => {
    requestApi({
      url: '/api/kiosk/token',
      onSuccess: (data) => {
        setKiosk(data.token);
        setCid(data.cid);
      }
    });
  }, []);

  useEffect(() => {
    if (numCompleted) setProgress(window.screen.availWidth * (numCompleted / steps.length));
  }, [window.screen.availWidth, numCompleted, steps]);

  return (
    <div className={cs('h-[100svh] overflow-hidden bg-transparent ', fakeModal && '!m-0 !p-0')}>
      {kiosk && <AutoIdle />}

      {loginRequest && (
        <div className="relative z-20 flex h-full w-full overflow-hidden">
          <PatientCheckinLogin setLoginRequest={setLoginRequest} />
        </div>
      )}
      {!loginRequest && (
        <div className="relative z-20 flex h-full overflow-hidden">
          {(device !== 'mobile' || show) && !fakeModal && (
            <div className="h-full max-w-[360px] border-0 !border-l !border-solid border-white bg-transparent">
              <Sidebar
                kiosk={kiosk}
                steps={steps}
                practiceLogo={practiceLogo}
                device={device}
                setShow={setShow}
                disableContinue={disableContinue}
              />
            </div>
          )}
          <div
            className="flex h-full w-full flex-col overflow-hidden bg-neutral-50"
            onClick={() => {
              show && setShow(false);
            }}>
            {!fakeModal && (
              <Navbar
                {...{
                  device,
                  steps,
                  currentStepName,
                  currentStepNumber,
                  stepObject,
                  handleExternalSubmit,
                  handleBack,
                  showButton,
                  setShow,
                  fakeModal,
                  disableContinue,
                  continueText,
                  continueColor,
                  setFakeModal,
                  progress
                }}
              />
            )}

            <div
              className={cs(
                'checkin-steps__outlet !ml-0 flex w-full justify-center overflow-y-auto bg-[#FAFDFF]',
                '!h-full',
                fakeModal && '!m-0 !p-0'
              )}>
              <div
                className={cs(
                  'w-full',
                  pathname.includes('payment') || fakeModal ? '' : 'max-w-[800px]'
                )}>
                <Outlet
                  context={{
                    kiosk,
                    updateCompleteStep,
                    updateSteps,
                    isEverythingCompleted,
                    navigateToIncompleted,
                    steps,
                    stepObject,
                    setSteps,
                    checkinButton,
                    checkinInsuranceEnabled,
                    setLoginRequest,
                    appointmentId,
                    customOnSuccess,
                    practiceLogo,
                    practice,
                    updateChoices,
                    setDisableContinue,
                    cid,
                    setContinueText,
                    defaultContinueText,
                    setContinueColor,
                    defaultContinueColor,
                    handleBack,
                    fakeModal,
                    setFakeModal,
                    handleExternalSubmit,
                    isInstantPacket,
                    responses,
                    setResponses,
                    reviewSteps,
                    practitionerId,
                    hipaaSign,
                    setHipaaSign,
                    user: { ...userSt, ...(data?.user || {}), address_object: data?.address }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(Wizard);
