import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import { withErrorBoundary } from '../../../../../shared/Error/Boundary';

const BenefitLevelItem = ({ finalItem }) => {
  return (
    <div className="flex flex-col !gap-2 ">
      {finalItem.benefit_notes ? (
        <p className=" text-sm !font-400 text-primary-900 ">
          {capitalize(finalItem.benefit_notes.toLowerCase())}
        </p>
      ) : null}
      {finalItem.benefit_begin ? (
        <p className=" text-sm !font-400 text-primary-900 ">
          Benefit begin {moment(finalItem.benefit_begin).format('MM/DD/YYYY')}
        </p>
      ) : null}
      <p className="!mt-1 text-sm !font-400 text-primary-900">
        <span className="mr-2 inline !font-600">
          {(finalItem.insurance_type_description || '') +
            ' ' +
            finalItem.benefit_coverage_description}
        </span>
        {finalItem.hasOwnProperty('benefit_amount') && !finalItem.hasOwnProperty('benefit_percent')
          ? '$'
          : null}
        {finalItem.benefit_amount || finalItem.benefit_percent}
        {finalItem.hasOwnProperty('benefit_percent') ? '%' : null}{' '}
        {finalItem.benefit_period_description}
      </p>
    </div>
  );
};

export default withErrorBoundary(BenefitLevelItem);
