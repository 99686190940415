export default function handleChangeStartDate(values, setFieldValue, date, editing) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  date.setHours(values.start_date.getHours());
  date.setMinutes(values.start_date.getMinutes());

  setFieldValue('start_date', date);

  const untilDate = new Date(date);
  if (editing) {
    untilDate.setHours(values.until.getHours());
    untilDate.setMinutes(values.until.getMinutes());
  } else {
    untilDate.setHours(untilDate.getHours() + 1);
  }

  setFieldValue('until', untilDate);
}
