import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import CurrencyInput from 'react-currency-input-field';
import '!style-loader!css-loader!react-datepicker/dist/react-datepicker.css';
import Input from 'components/shared/Input/Input';

export default function CPTRow({ item }) {
  return (
    <div className="block p-3 w-full border border-solid border-neutral-300" id="procedureBox">
      <div className="flex-wrap -ml-3 -mr-3 -mt-3">
        <div className="block p-3">
          <Input value={item.code} name="code" />
        </div>
        <div className="block p-3">
          <Input value={item.narrative} name="narrative" />
        </div>
        <div className="block p-3">
          <Input name="modifiers" value={item.modifiers} />
        </div>
        <div className="block p-3">

          <Input name="diagnosis" value={item.diagnosis} />
        </div>
        <div className="block p-3">
          <CurrencyInput
            className='border border-solid !border-neutral-200 !h-[40px] !rounded-md focus-visible:!border-primary-500 focus-visible:outline-primary-100 outline-offset-2'
            name="charge"
            value={item.charge}
            prefix="$"
            allowDecimals={true}
            decimalSeparator={'.'}
          />
        </div>
        <div className="block p-3">
          <Input name="units" value={item.units} />
        </div>
        <div className="block p-3">
          <Input value={item.place_of_service}
          />
        </div>
        <div className="block p-3 min-w-[125px]">
          <DatePicker
            selected={moment(item.from_date).toDate()}
            monthsShown="1"
            dateFormat="MM/dd/yyyy"
            name="from_date"
            style={{ minWidth: '50px !important' }}
            type="string"
            readOnly={true}
          />
        </div>
        <div className="block p-3 min-w-[125px]">
          <DatePicker
            selected={moment(item.thru_date).toDate()}
            monthsShown="1"
            dateFormat="MM/dd/yyyy"
            name="from_date"
            style={{ minWidth: '50px !important' }}
            type="string"
            readOnly={true}
          />
        </div>
      </div>
    </div>
  );
}
