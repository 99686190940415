import React, { useRef } from 'react';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import Textarea from 'components/shared/Textarea/Textarea';

const Note = () => {
  const { values, setFieldValue } = useFormikContext();
  const noteRef = useRef();
  const innerHeight = noteRef?.current?.clientHeight;

  return (
    <Textarea
      label="Note"
      placeholder="Add any notes, problems or symptoms"
      value={values?.practice_note}
      onChange={(e) => setFieldValue('practice_note', e.target.value)}
      forwardedRef={noteRef}
      textareaClassName={cs('max-h-[90px]', innerHeight > 80 && '!overflow-auto')}
    />
  );
};

export default Note;
