import { useEffect, useRef, useState } from 'react';
import { HexAlphaColorPicker, HexColorInput } from 'react-colorful';

import cs from 'classnames';
import debounce from 'lodash/debounce';

import Popover from '../Popovers/Popover/Popover';

import { recommendedColors } from './lib/getClasses';

import './lib/ColorPicker.scss';

/**
 * @param {import('./lib/propTypes').ColorPickerProps}
 */

const ColorPicker = ({
  color = '#13B9FF',
  onChange = () => {},
  label = 'Color',
  className,
  labelClassName,
  buttonClassName,
  panelClassName,
  disabled,
  required,
  isFixed = true // used just for documentation
}) => {
  const [selectedColor, setSelectedColor] = useState(color);
  const popoverRef = useRef(null);

  // Create a debounced version of the entire change handler
  const handleChangeColor = useRef(
    debounce((newColor) => {
      setSelectedColor(newColor);
      onChange(newColor);
    }, 200)
  ).current;

  // Cleanup on unmount
  useEffect(() => () => handleChangeColor.cancel(), []);

  const handleRecommendedColorClick = (recommendedColor) => {
    handleChangeColor(recommendedColor);
    popoverRef?.current?.buttonRef?.current?.click();
  };

  return (
    <div className={className}>
      {label && (
        <div className="flex items-center min-h-[28px] pb-1">
          <label
            className={cs(
              'text-sm font-500 first-letter:capitalize',
              disabled ? 'text-neutral-400' : 'text-neutral-800',
              labelClassName
            )}>
            {label}
            {required && <span className="text-danger-400">*</span>}
          </label>
        </div>
      )}
      <Popover
        ref={popoverRef}
        buttonClassName="!p-0"
        panelClassName="!p-0 !rounded-2xl border border-solid border-neutral-200"
        buttonTheme="transparent"
        dataQa="color-picker"
        disabled={disabled}
        isFixed={isFixed}
        icon={false}
        text={
          <div
            className={cs(
              '!p-0 flex size-10 items-center justify-center rounded-lg border border-solid border-neutral-100 bg-white shadow-[0px_2px_4px_0px_rgba(27,33,35,0.04)]',
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
              buttonClassName
            )}>
            <div
              className={cs(
                'size-7 rounded-sm',
                selectedColor === '#ffffff' && 'border border-solid border-neutral-200'
              )}
              style={{ background: selectedColor }}
            />
          </div>
        }>
        <div className={cs('color-picker', panelClassName)}>
          <div className="p-4">
            <HexAlphaColorPicker color={selectedColor} onChange={handleChangeColor} />
            <div className="flex items-center gap-3">
              <span className="text-sm text-neutral-800 font-500">Hex</span>
              <HexColorInput
                className="w-full text-neutral-900 border-solid rounded-md border border-neutral-200 px-3 py-2 text-sm focus-within:outline focus-within:border-primary-300 focus-within:outline-2 focus-within:outline-[#CBF0FD]"
                prefixed
                alpha
                onChange={handleChangeColor}
                color={selectedColor}
              />
            </div>
          </div>
          <div className="p-4 border-0 border-t border-solid border-neutral-200">
            <div className="pb-2 text-sm text-neutral-700 leading-[22px]">Recommended Colors</div>
            <div className="grid grid-cols-2 gap-2">
              {recommendedColors.map((row, rowIndex) => (
                <div key={rowIndex} className="flex gap-2">
                  {row.map((recommendedColor, colIndex) => {
                    const isSelected =
                      selectedColor?.toUpperCase() === recommendedColor?.toUpperCase();
                    return (
                      <div
                        key={colIndex}
                        className={cs(
                          'group flex items-center justify-center size-[24px]',
                          isSelected ? 'cursor-default' : 'cursor-pointer'
                        )}
                        onClick={() => handleRecommendedColorClick(recommendedColor)}>
                        <span
                          className={cs(
                            'size-[18px] rounded-full transition-all',
                            isSelected
                              ? 'border-solid border-3 border-white outline outline-2'
                              : 'group-hover:scale-110'
                          )}
                          style={{ background: recommendedColor, outlineColor: recommendedColor }}
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default ColorPicker;
