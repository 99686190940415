import { Popover } from '@headlessui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import Status from 'components/shared/Status/Status';

const AdminUserEntry = ({ user, index }) => {
  return (
    <tr key={`admin-user-entry-${index}`}>
      <td>
        <Link to={`/admin/users/${user?.id}`}>{user?.id}</Link>
      </td>
      <td>{user?.f_name}</td>
      <td>{user?.l_name}</td>
      <td>{user?.email}</td>
      <td>
        <Status status={user?.status} />
      </td>
      <td>
        <Popover className="relative">
          {({ open, close }) => (
            <div>
              <Popover.Button
                data-qa={`admin-user-${index}`}
                className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                  !open && 'hover:!bg-white'
                } ${open && '!bg-primary-700 transition-all'}`}>
                <Icon
                  icon={'three-dots'}
                  className="flex cursor-pointer"
                  color={open ? 'white' : 'black'}
                />
              </Popover.Button>

              <Popover.Panel
                className={`absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white ${
                  index ? '!-top-[90px]' : 'top-10'
                } !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`}>
                {/* {pm(p, 'member.update') && (
                  <div
                    data-qa="edit-admin-user-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    id={member.user_id}
                    onClick={(e) => {
                      onEdit(e);
                      close();
                    }}>
                    <Icon icon={'new-edit'} color={'primary'} />
                    <div className="ml-1 text-sm text-primary-900">Edit</div>
                  </div>
                )} */}

                <div
                  data-qa="admin-data-settings-btn"
                  className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                  }}>
                  <Icon icon={'trash'} color={'primary'} />
                  <div className="ml-1 text-sm text-primary-900">Deactivate (WIP)</div>
                </div>
              </Popover.Panel>
            </div>
          )}
        </Popover>
      </td>
    </tr>
  );
};

export default withErrorBoundary(AdminUserEntry);
