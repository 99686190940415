import { useEraContext } from 'lib/context/EraContext/EraContext';

import RadioButton from 'components/shared/RadioButton/RadioButton';

import AdjustmentCellRenderer from '../components/AdjustmentCellRenderer';
import DateCellRenderer from '../components/DateCellRenderer';
import ManualPayment from '../components/ManualPayment';

export const ALLOCATE_EOB_COLUMN_DEFS = ({ onChange }) => [
  {
    headerName: '',
    field: 'select',
    maxWidth: 50,
    pinned: 'left',
    cellRenderer: function ActionsCellRenderer({ data }) {
      const { selectedUnmatchedClaim } = useEraContext();

      return data?.isClaimRow ? (
        <RadioButton
          id={data?.id}
          value={data?.id}
          isSelected={data?.id === selectedUnmatchedClaim}
          onChange={() => onChange(data)}
          wrapperClassName="flex align-center justify-center h-full flex-1"
        />
      ) : null;
    }
  },
  {
    headerName: 'Posted/DoS',
    field: 'date',
    rowGroup: true,
    hide: true,
    valueGetter: (params) => params.data?.groupDate
  },
  {
    headerName: 'Posted/DoS',
    field: 'date',
    autoHeight: true,
    minWidth: 350,
    cellRenderer: DateCellRenderer
  },
  {
    headerName: 'Patient/Service',
    field: 'procedure_code',
    cellRenderer: (params) => {
      if (params.data?.isClaimRow) {
        return params.data.patientName;
      }
      return params.data?.procedure_code || '';
    }
  },
  {
    headerName: 'Provider',
    field: 'provider'
  },
  {
    headerName: 'Diagnosis',
    field: 'diagnosis'
  },
  {
    headerName: 'Billed',
    field: 'billed'
  },
  {
    headerName: 'Ins. Paid',
    field: 'total_insurance_payment',
    autoHeight: true,
    autoWidth: true,
    minWidth: 150,
    cellRenderer: ManualPayment
  },
  {
    headerName: 'Adjustment',
    field: 'adjustment',
    autoHeight: true,
    autoWidth: true,
    minWidth: 350,
    cellRenderer: AdjustmentCellRenderer
  },
  {
    headerName: 'Pt. Paid',
    field: 'paid',
    cellRenderer: ({ data }) => {
      if (!data?.isClaimRow) return null;

      return data.paid;
    }
  },
  {
    headerName: 'Note',
    field: 'note',
    maxWidth: 100
  }
];
