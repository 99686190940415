import React from 'react';

import moment from 'moment-timezone';

import { snakeToTitleCase } from 'lib/helpers/utility';

export const hintTooltip = (timezone) => {
  return (
    <p>
      The local date reflects the practice's timezone <b>({snakeToTitleCase(timezone)})</b> and may
      differ from your timezone <b>({moment.tz.guess()})</b>. This ensures all records align with
      the practice's local time.
    </p>
  );
};
