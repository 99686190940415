export function generateBeyondBillingList(userState) {
  return {
    overview: {
      name: 'Overview',
      path: 'overview',
      icon: 'new-graph'
    },
    'income-i-queue': {
      name: 'IncomeIQueue',
      path: 'income-i-queue',
      icon: 'new-note-text',
      permission: 'is_admin'
    },
    payments: {
      name: 'Payments',
      path: 'payments',
      icon: 'new-billing',
      permission: 'payments.read'
    },
    'patient-ledger': {
      name: 'Patient Ledger',
      path: 'patient-ledger',
      icon: 'new-macro-type',
      permission: 'payments.read'
    },
    // 'financial-reporting': {
    //   name: 'Financial Reporting',
    //   path: 'financial-reporting',
    //   icon: 'receipt-text',
    //   open:
    //     userState?.display_settings?.sidebars?.beyondBilling?.['financial-reporting']?.open ?? true,
    //   children: [
    //     {
    //       name: 'Grand total',
    //       path: 'financial-reporting',
    //       end: true
    //     },
    //     {
    //       name: 'Insurance Claims',
    //       path: 'financial-reporting/insurance-claims'
    //     }
    //   ]
    // },
    'dynamic-ar': {
      name: 'Dynamic AR',
      path: 'dynamic-ar',
      icon: 'new-notev3',
      open: userState?.display_settings?.sidebars?.beyondBilling?.['dynamic-ar']?.open ?? true,
      children: [
        {
          name: 'List',
          path: 'dynamic-ar',
          end: true
        },
        {
          name: 'Graph',
          path: 'dynamic-ar/graph'
        }
      ]
    },
    'era-eob': {
      name: 'ERA/EOB',
      path: 'era-eob',
      icon: 'new-calendar-note',
      open: userState?.display_settings?.sidebars?.beyondBilling?.['era-eob']?.open ?? true,
      children: [
        {
          name: 'All',
          path: 'era-eob/all',
          end: true
        },
        {
          name: 'Pending Review',
          path: 'era-eob/pending'
        },
        {
          name: 'Finalized',
          path: 'era-eob/finalized'
        }
      ]
    },
    claims: {
      name: 'Claims',
      path: 'claims',
      icon: 'wallet',
      open: userState?.display_settings?.sidebars?.beyondBilling?.claims?.open ?? true,
      children: [
        {
          name: 'All',
          path: 'claims',
          end: true
        },
        {
          name: 'Ready',
          path: 'claims/ready'
        },
        {
          name: 'Acknowledged',
          path: 'claims/acknowledged'
        },
        {
          name: 'Denied',
          path: 'claims/denied'
        },
        {
          name: 'Paid',
          path: 'claims/paid'
        },
        {
          name: 'Rejected',
          path: 'claims/rejected'
        },
        {
          name: 'Pending',
          path: 'claims/pending'
        },
        {
          name: 'Archived',
          path: 'claims/archived'
        }
      ]
    },
    superbills: {
      name: 'Superbills',
      path: 'superbills',
      icon: 'new-bill',
      open: userState?.display_settings?.sidebars?.beyondBilling?.superbills?.open ?? true,
      children: [
        {
          name: 'Draft',
          path: 'superbills',
          end: true
        },
        {
          name: 'Complete',
          path: 'superbills/complete'
        }
      ],
      permission: 'superbill.read'
    },
    'electronic-invoices': {
      name: 'Electronic Invoices',
      path: 'electronic-invoices',
      icon: 'new-file-dollar',
      open:
        userState?.display_settings?.sidebars?.beyondBilling?.['electronic-invoices']?.open ?? true,
      children: [
        {
          name: 'Internal Invoices',
          path: 'electronic-invoices',
          end: true
        },
        {
          name: 'External Invoices',
          path: 'electronic-invoices/external'
        }
      ],
      permission: 'invoicing.read'
    }
  };
}
