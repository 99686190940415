import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getHipaa } from 'api/Checkin/Hipaa';

export const useHipaa = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['hipaa', dependencies], () => getHipaa(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
