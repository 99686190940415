export default [
  {
    day: 'Monday',
    location: { starts_at: 9, ends_at: 17 },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Tuesday',
    location: { starts_at: 9, ends_at: 17 },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Wednesday',
    location: { starts_at: 9, ends_at: 17 },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Thursday',
    location: { starts_at: 9, ends_at: 17 },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Friday',
    location: { starts_at: 9, ends_at: 17 },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Saturday',
    location: { starts_at: null, ends_at: null },
    virtual: { starts_at: null, ends_at: null }
  },
  {
    day: 'Sunday',
    location: { starts_at: null, ends_at: null },
    virtual: { starts_at: null, ends_at: null }
  }
];
