import moment from 'moment';
import { showAlert } from '../../../../shared/Alert/Alert';

export default function handleDateTimeChange(values, setFieldValue, val, type) {
  const date = moment(values[type]);
  const newDate = date
    .set('hour', moment(val.newTime, 'hh:mm A').hour())
    .set('minute', moment(val.newTime, 'hh:mm A').minute());

  if (type === 'start_date' && moment(newDate).isAfter(moment(values.until))) {
    setFieldValue('until', new Date(moment(newDate).add(30, 'minutes')));
  }
  setFieldValue(type, new Date(newDate));
}
