import { requestApi } from './Api';

export const getPatientAggregateNarrative = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/patient_aggregate_narrative/get',
    params
  });
};

export const upsertPatientAggregateNarrative = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/patient_aggregate_narrative/upsert',
    params
  });
};

export const noKnownPatientAggregateNarrative = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/patient_aggregate_narrative/no_known/delete',
    params
  });
};
