import { priceCell, qtyCell, unitPriceCell } from './productCells';
import {
  allowedCell,
  chargeCell,
  deleteItem,
  ptPortionCell,
  selfPayAtm,
  typeCell
} from './serviceCells';

export const DEFAULT_COLUMN_DEFS = {
  resizable: true,
  animateRows: false,
  suppressMenu: true,
  suppressMovable: true,
  cellStyle: {
    border: 'none',
    overflow: 'visible'
  }
};

export const GRID_OPTIONS = {
  rowHeight: 42,
  animateRows: false,
  isRowSelectable: false,
  suppressRowFocus: true,
  suppressContextMenu: true,
  suppressAggFuncInHeader: true,
  suppressDoubleClickEdit: true,
  reactiveCustomComponents: true,
  suppressRowHoverHighlight: true,
  suppressRowClickSelection: true,
  suppressDragLeaveHidesColumns: true,
  getRowStyle(params) {
    if (params.node.rowIndex % 2 == 0) return { backgroundColor: 'white' };
    return { backgroundColor: '#F5FCFF' };
  }
};
export const getColDefs = (type, hasPaymentPermission, hasBillingPermission) => [
  {
    maxWidth: 40,
    headerName: '',
    field: 'delete',
    sortable: false,
    cellRenderer: deleteItem
  },
  {
    width: 200,
    field: 'name',
    tooltipField: 'name',
    cellClass: '!truncate',
    headerName: type === 'procedures' ? 'Service' : 'Product'
  },
  ...(type == 'procedures'
    ? [
        {
          maxWidth: 126,
          sortable: false,
          headerName: 'Type',
          cellRenderer: typeCell,
          cellStyle: { padding: '0' },
          hide: !hasBillingPermission
        },
        {
          maxWidth: 114,
          sortable: false,
          headerName: 'Ins. Charge',
          cellRenderer: chargeCell,
          cellStyle: { padding: '0' },
          hide: !hasPaymentPermission
        },
        {
          maxWidth: 120,
          sortable: false,
          cellRenderer: selfPayAtm,
          headerName: 'Self Pay Amt.',
          cellStyle: { padding: '0' },
          hide: !hasPaymentPermission
        },
        {
          maxWidth: 114,
          sortable: false,
          headerName: 'Allowed',
          cellRenderer: allowedCell,
          cellStyle: { padding: '0' },
          hide: !hasPaymentPermission
        },
        {
          maxWidth: 136,
          sortable: false,
          headerName: 'Est. Pt. Portion',
          cellStyle: { padding: '0' },
          hide: !hasPaymentPermission,
          cellRenderer: ptPortionCell
        }
      ]
    : [
        {
          maxWidth: 130,
          sortable: false,
          headerName: 'Unit Price',
          hide: !hasPaymentPermission,
          cellRenderer: unitPriceCell
        },
        {
          maxWidth: 130,
          sortable: false,
          headerName: 'Qty',
          cellRenderer: qtyCell,
          cellStyle: { padding: '0' }
        },
        {
          maxWidth: 130,
          sortable: false,
          headerName: 'Price',
          cellRenderer: priceCell,
          hide: !hasPaymentPermission
        }
      ])
];
