import { useEffect, useState } from 'react';

import cs from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Buttons/Button';
import {
  intelligenceChatMessageIdState,
  myScribeAutoMapCurrentActivePromptState,
  userState
} from 'components/state';

import useComponentAdd from '../../hooks/useComponentAdd';

const UserPrompt = ({ message }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editableMessage, setEditableMessage] = useState(message);
  const [finalMessage, setFinalMessage] = useState(message);
  const patient = useRecoilValue(userState);
  const setCurrentActivePrompt = useSetRecoilState(myScribeAutoMapCurrentActivePromptState);
  const { onEnhanceNote, setUserPrompt } = useChatContextApi();
  const { inputTags, selectedTableRows, setInputTags } = useComponentAdd();
  const [promptTags, setPromptTags] = useState(inputTags || []);
  const intelligenceChatMessageId = useRecoilValue(intelligenceChatMessageIdState);

  useEffect(() => {
    setPromptTags(inputTags);
    setInputTags([]);
  }, []);

  useEffect(() => {
    setEditableMessage(message);
    setFinalMessage(message);
    setUserPrompt(message);
  }, [message]);

  const onTagRemove = () => {
    setPromptTags((prev) => prev.slice(0, prev.length - 1));
  };

  const handleInputChange = (e) => {
    setEditableMessage(e.target.value);
    setUserPrompt(e.target.value);
  };

  const onEditOpen = () => {
    setIsEdit(true);
  };

  const onEditClose = () => {
    setIsEdit(false);
    setEditableMessage(finalMessage);
  };

  const onPromptUpdate = () => {
    setFinalMessage(editableMessage);
    setUserPrompt(editableMessage);
    setIsEdit(false);

    setCurrentActivePrompt((ps) => ps.slice(0, ps.length - 1));

    onEnhanceNote({
      userMessage: {
        message: editableMessage,
        tags: inputTags?.map((item) => item.id),
        agent: 'user'
      },
      intelligenceChatMessageId
    });
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  return (
    <div className="flex flex-row items-start">
      <Avatar
        firstName={patient?.f_name}
        lastName={patient?.l_name}
        id={patient?.id}
        image={patient?.header_photo}
        size="xl"
        className="mr-2"
      />

      <div className="flex flex-grow flex-col">
        <div className="w-full rounded-lg bg-white">
          {isEdit ? (
            <AIChatInput
              isHTML
              className="mb-3 w-full border-2 border-primary-300 shadow-primary-50 outline outline-1 outline-primary-300 ring-4 ring-[#CBF0FD]"
              leftButtons={[]}
              rightButtons={[]}
              tags={promptTags}
              getTagClassName={getTagClassName}
              onTagRemove={onTagRemove}
              onTextChange={handleInputChange}
              onSubmit={onPromptUpdate}
              placeholder="Type anything.."
              value={editableMessage}
            />
          ) : (
            <div className="p-3">{finalMessage}</div>
          )}
          <div className="flex flex-wrap items-center">
            {!isEdit &&
              promptTags.map((tag) => (
                <span
                  // onClick={() => onTagPreview(tag.id)}
                  key={tag.id}
                  className={cs(
                    'relative my-1 ml-1 cursor-pointer rounded bg-primary-50 p-1 px-2 text-sm text-primary-700',
                    getTagClassName(tag.id)
                  )}>
                  #{tag?.text}
                </span>
              ))}
          </div>
        </div>
        <div className="flex items-center justify-end">
          {!isEdit ? (
            <Button
              size="small"
              text="Edit"
              onClick={onEditOpen}
              icon="new-edit-v3"
              iconSize={18}
              outlined
            />
          ) : (
            <div className="flex flex-row gap-1">
              <Button text="Cancel" onClick={onEditClose} iconSize={18} transparent size="small" />
              <Button size="small" text="Update" onClick={onPromptUpdate} iconSize={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPrompt;
