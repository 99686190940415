import React from 'react';

import Icon from '../../../../../shared/Icon/Icon';
import { isProcedureChecked, updateItemCount } from '../lib';

const NewItemCounter = ({ newItem, newItems, setNewItems, packageId, invoiceId }) => {
  return (
    !!isProcedureChecked(newItems, newItem, packageId, invoiceId) && (
      <div className="flex gap-2 items-center">
        <div className="bg-white w-5 h-5 rounded-full flex items-center justify-center">
          <Icon
            stroke
            color="primary"
            icon="new-minus"
            onClick={() =>
              updateItemCount({
                itemId: newItem.id,
                increment: false,
                newItems,
                setNewItems,
                packageId,
                invoiceId
              })
            }
          />
        </div>

        <p className="w-6 h-6 rounded-full bg-primary-500 flex items-center justify-center font-500 text-white !text-xs select-none">
          {isProcedureChecked(newItems, newItem, packageId, invoiceId).count}
        </p>

        <div className="bg-white w-5 h-5 rounded-full flex items-center justify-center">
          <Icon
            color="primary"
            icon="new-plus"
            onClick={() =>
              updateItemCount({ itemId: newItem.id, newItems, setNewItems, packageId, invoiceId })
            }
          />
        </div>
      </div>
    )
  );
};

export default NewItemCounter;
