import { useQuery } from '@tanstack/react-query';
import { getNdcCodeOptions } from 'api/NdcCodes';
import { useNavigate } from 'react-router-dom';

export const useNdcCodesOptions = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['ndc_codes_options', ...dependencies],
    () => getNdcCodeOptions(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
