import { formatDate, ia, iaRa } from 'lib/helpers/utility';

import { translateWeightLossDataToMedication } from '../../Provider/WeightLoss/lib/helpers';

import { UNIT_OPTIONS } from './constants';

export const createObjectWithKeys = (
  existingData,
  fromWeightLoss,
  showPreviewMedicationFromWhl = false,
  patientId = null
) => {
  const keys = [
    'id',
    'medication',
    'product_ndc',
    'product_ndc_id',
    'weight_loss_medication_id',
    'sig',
    'unit',
    'strength',
    'quantity',
    'days_supply',
    'refills',
    'prescriber',
    'condition',
    'start_date',
    'end_date',
    'status',
    'route',
    'note',
    'created_by'
  ];
  if (patientId) keys.push('practice_id');

  const newObject = keys.reduce((acc, key) => {
    switch (key) {
      case 'unit':
        acc[key] = existingData?.[key] || UNIT_OPTIONS?.[0]?.value;
        break;
      case 'start_date':
      case 'end_date':
        acc[key] = existingData?.[key] ? existingData[key] : null;
        break;
      default:
        acc[key] = existingData?.[key] || null;
    }
    return acc;
  }, {});
  if (showPreviewMedicationFromWhl) return newObject;
  const data = translateWeightLossDataToMedication(existingData);
  return fromWeightLoss ? data : newObject;
};

export const medicationRouteOptions = [
  'AURICULAR (OTIC)',
  'BUCCAL',
  'CONJUNCTIVAL',
  'CUTANEOUS',
  'DENTAL',
  'ELECTRO-OSMOSIS',
  'ENDOCERVICAL',
  'ENDOSINUSIAL',
  'ENDOTRACHEAL',
  'ENTERAL',
  'EPIDURAL',
  'EXTRA‑AMNIOTIC',
  'EXTRACORPOREAL',
  'HEMODIALYSIS',
  'INFILTRATION',
  'INTERSTITIAL',
  'INTRA-ABDOMINAL',
  'INTRA-AMNIOTIC',
  'INTRA-ARTERIAL',
  'INTRA-ARTICULAR',
  'INTRABILIARY',
  'INTRABRONCHIAL',
  'INTRABURSAL',
  'INTRACARDIAC',
  'INTRACARTILAGINOUS',
  'INTRACAUDAL',
  'INTRACAVERNOUS',
  'INTRACAVITARY',
  'INTRACEREBRAL',
  'INTRACISTERNAL',
  'INTRACORNEAL',
  'INTRACORONAL, DENTAL',
  'INTRACORONARY',
  'INTRACORPORUS CAVERNOSUM',
  'INTRADERMAL',
  'INTRADISCAL',
  'INTRADUCTAL',
  'INTRADUODENAL',
  'INTRADURAL',
  'INTRAEPIDERMAL',
  'INTRAESOPHAGEAL',
  'INTRAGASTRIC',
  'INTRAGINGIVAL',
  'INTRAILEAL',
  'INTRALESIONAL',
  'INTRALUMINAL',
  'INTRALYMPHATIC',
  'INTRAMEDULLARY',
  'INTRAMENINGEAL',
  'INTRAMUSCULAR',
  'INTRAOCULAR',
  'INTRAOVARIAN',
  'INTRAPERICARDIAL',
  'INTRAPERITONEAL',
  'INTRAPLEURAL',
  'INTRAPROSTATIC',
  'INTRAPULMONARY',
  'INTRASINAL',
  'INTRASPINAL',
  'INTRASYNOVIAL',
  'INTRATENDINOUS',
  'INTRATESTICULAR',
  'INTRATHECAL',
  'INTRATHORACIC',
  'INTRATUBULAR',
  'INTRATUMOR',
  'INTRATYMPANIC',
  'INTRAUTERINE',
  'INTRAVASCULAR',
  'INTRAVENOUS',
  'INTRAVENOUS BOLUS',
  'INTRAVENOUS DRIP',
  'INTRAVENTRICULAR',
  'INTRAVESICAL',
  'INTRAVITREAL',
  'IONTOPHORESIS',
  'IRRIGATION',
  'LARYNGEAL',
  'NASAL',
  'NASOGASTRIC',
  'NOT APPLICABLE',
  'OCCLUSIVE DRESSING TECHNIQUE',
  'OPHTHALMIC',
  'ORAL',
  'OROPHARYNGEAL',
  'OTHER',
  'PARENTERAL',
  'PERCUTANEOUS',
  'PERIARTICULAR',
  'PERIDURAL',
  'PERINEURAL',
  'PERIODONTAL',
  'RECTAL',
  'RESPIRATORY (INHALATION)',
  'RETROBULBAR',
  'SOFT TISSUE',
  'SUBARACHNOID',
  'SUBCONJUNCTIVAL',
  'SUBCUTANEOUS',
  'SUBLINGUAL',
  'SUBMUCOSAL',
  'TOPICAL',
  'TRANSDERMAL',
  'TRANSMUCOSAL',
  'TRANSPLACENTAL',
  'TRANSTRACHEAL',
  'TRANSTYMPANIC',
  'UNASSIGNED',
  'UNKNOWN',
  'URETERAL',
  'URETHRAL',
  'VAGINAL'
];

export const injectionRoutes = [
  'INTRA-ARTERIAL',
  'INTRA-ARTICULAR',
  'INTRABURSAL',
  'INTRACARDIAC',
  'INTRACAVERNOUS',
  'INTRACAVITARY',
  'INTRADERMAL',
  'INTRADISCAL',
  'INTRADUCTAL',
  'INTRADURAL',
  'INTRALESIONAL',
  'INTRALYMPHATIC',
  'INTRAMEDULLARY',
  'INTRAMENINGEAL',
  'INTRAMUSCULAR',
  'INTRAOCULAR',
  'INTRAPERITONEAL',
  'INTRAPLEURAL',
  'INTRAPROSTATIC',
  'INTRASPINAL',
  'INTRASYNOVIAL',
  'INTRATENDINOUS',
  'INTRATESTICULAR',
  'INTRATHECAL',
  'INTRATUMOR',
  'INTRAUTERINE',
  'INTRAVASCULAR',
  'INTRAVENOUS',
  'INTRAVENTRICULAR',
  'INTRAVESICAL',
  'INTRAVITREAL',
  'SUBCUTANEOUS',
  'SUBMUCOSAL'
];
export const formatMedicationDataForUpsert = (data, fromWeightLoss) => {
  if (fromWeightLoss) {
    delete data?.cfr_id;
    data.weight = data.weight === '' || data.weight === ' ' || !data.weight ? null : data.weight;

    const id = data?.medication_id;
    delete data?.medication_id;
    return { ...data, id };
  }
  return data;
};
export const formatWeightLossDataForUpsert = (data) => {
  const weight = data?.weight;
  delete data?.weight;
  delete data?.createdBy;
  return { ...data, weight };
};
export const hasSingleStatus = (data) => {
  if (!ia(data)) return false;
  const initialStatus = data[0]?.status;
  return iaRa(data).every((item) => item.status === initialStatus);
};
export const formatMultipleMedsFormat = (medications, currentStatus) => {
  const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
  return medications.map((med) => ({
    ...med,
    status: newStatus
  }));
};
export const formatMultipleWhlFormat = (whlData, currentStatus) => {
  const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
  return {
    response: {
      row: whlData.map((formattedWhlData) => ({
        ...formattedWhlData,
        status: newStatus
      }))
    }
  };
};
