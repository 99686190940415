const { Honeybadger } = require('@honeybadger-io/react');
const { default: Axios } = require('../../../../../configuredAxios');
const { camelToKebabKeys, iaRa } = require('../../../../../lib/helpers/utility');
const { getMedications } = require('../../../../../api/Medication');
const { isItemNull } = require('../../Overview/widgets/Charts/ChartRow');

exports.getOverview = async ({ patientId, appointmentId, isKebab = false } = {}) => {
  if (!patientId || !appointmentId) return;

  try {
    const vitalsRes = await Axios.post('/api/clinical_note/vitals/read', {
      patientId,
      appointmentId,
      withal: true
    });
    const customResponsesRes = await Axios.post('/api/custom_form_response/read', {
      patientId,
      appointmentId,
      type: [
        // 'allergies',
        // 'vaccines',
        // 'familyHistory',
        // 'surgicalHistory',
        'medicalHistory',
        'conditions',
        'socialHistory',
        'weightLossAdministration'
      ]
    });
    const vitalsData = iaRa(vitalsRes?.data?.vitals);

    const formattedVitals = vitalsData.map((group) => ({
      appointment_id: group.appointment_id,
      created_by: group.created_by,
      created_at: group.created_at,
      vitals: group.vitals.filter(
        (v) =>
          !isItemNull(v?.heart_rate) ||
          !isItemNull(v?.respirations) ||
          !isItemNull(v?.blood_pressure_systolic) ||
          !isItemNull(v?.blood_pressure_diastolic) ||
          !isItemNull(v?.weight) ||
          !isItemNull(v?.temperature) ||
          !isItemNull(v?.height) ||
          !isItemNull(v?.bmi) ||
          !isItemNull(v?.oxygen_level) ||
          !isItemNull(v?.head_circumference)
      )
    }));
    const vitalsObject = formattedVitals[0] || {};

    let { response } = customResponsesRes.data;

    const kebabResponse = camelToKebabKeys(response);
    return {
      ...(isKebab ? { ...kebabResponse } : { ...response }),
      vitals: vitalsObject
    };
  } catch (error) {
    console.error(error);
    Honeybadger.notify(
      `Clinical note get custom responses - patientId: ${patientId}, error: ${error}`
    );
  }
};
