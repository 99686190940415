import React from 'react';
import Button from 'components/shared/Buttons/Button';

const FileSuccess = ({ show, setShow }) => {
  return (
    <div className="border-none rounded-lg p-2 flex flex-col mx-auto my-auto justify-center h-fit w-fit bg-white overflow-hidden transition duration-300">
      <span className="text-xl font-bold py-1">Patient Import</span>
      <span className="text-l font-bold py-1">Step 4/4</span>
      <div className="flex flex-col min-h-[20rem] h-60vh min-w-[20rem] w-50vw overflow-auto">
        <span className="py-1">Success!</span>
        <span className="py-1">Please expect an email soon with the status of import.</span>
        <Button
          text="Import More"
          onClick={() =>
            setShow({
              ...show,
              stage: 'uploadFile',
              patientsImported: '',
              patientsMissingInfo: '',
              patientsNotImported: ''
            })
          }
          color="primary"
        />
      </div>
    </div>
  );
};

export default FileSuccess;
