import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';
import { pm } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import TagModal from './TagModal';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteTag } from 'api/Tags';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const userPermissions = useRecoilValue(permissions);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { mutateAsync: mutateDeleteTag } = useMutation({
    mutationFn: (data) => deleteTag(navigate, data)
  });

  const handleContinue = async (tag) => {
    let kind = tag?.kind;
    await mutateDeleteTag(
      { tagIds: [tag?.id] },
      {
        onSuccess: () => {
          let queryToInvalidate;
          if (kind === 'chart') {
            queryToInvalidate = 'patients';
          } else if (kind === 'fax') {
            queryToInvalidate = 'fax';
          } else {
            queryToInvalidate = 'appointment';
          }
          if (queryToInvalidate === 'fax') {
            queryClient.refetchQueries(['archivedFaxes']);
            queryClient.refetchQueries(['draftFaxes']);
            queryClient.refetchQueries(['inboundFaxes']);
            queryClient.refetchQueries(['outboundFaxes']);
          } else {
            queryClient.refetchQueries([queryToInvalidate]);
          }
          queryClient.refetchQueries(['tags', kind]);
          queryClient.invalidateQueries(['allTags']);
          showAlert({ title: 'Tag deleted successfully!', color: 'success' });
          setShowDeleteModal(false);
        }
      }
    );
  };

  let options = [
    ...(pm(userPermissions, 'tags.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'tags.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];
  return (
    <>
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <TagModal tag={data} isOpen={showEditModal} handleClose={() => setShowEditModal(false)} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete tag"
          title="Delete Tag"
          icon="new-calendar-red"
          message="Are you sure you want to delete this tag?"
          handleOpen={showDeleteModal}
          handleContinue={() => handleContinue(data)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default Actions;
