import { memo } from 'react';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { getKeysByType, iaRa } from 'lib/helpers/utility';

import { handleAdjustmentCodes } from '../../../../ERA&EOBHelpers';

import AllocateAdjustment from './AllocateAdjustment';

const AdjustmentCellRenderer = memo(({ data, node }) => {
  const { codes, selectedUnmatchedClaim, setProcedures } = useEraContext();

  if (data?.isStatusRow) return null;

  if (data?.isClaimRow) {
    return data.adjustment;
  }
  const isSelected = selectedUnmatchedClaim === data?.claimId;

  return isSelected ? (
    <AllocateAdjustment
      index={data.procedureIndex}
      setProcedures={(updater) => {
        setProcedures((prev) => {
          const updated = typeof updater === 'function' ? updater(prev) : updater;
          node.setData({
            ...data,
            adjustment: iaRa(updated[data.procedureIndex]?.adjustment)
          });
          return updated;
        });
      }}
      procedureCode={data.procedure_code}
      adjustments={data.adjustment}
      codeList={getKeysByType(codes, 'number')}
      groupList={getKeysByType(codes, 'letter')}
    />
  ) : (
    handleAdjustmentCodes(data?.adjustment, codes)
  );
});
export default AdjustmentCellRenderer;
