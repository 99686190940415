import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getRoleList } from 'api/Roles';

export const useRoleList = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['getRoleList', ...dependencies], () => getRoleList(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options
  });
};
