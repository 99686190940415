import React, { useState } from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { Outlet } from 'react-router-dom';
import NavLinkTabs from 'components/shared/NavLinkTabs/Tabs';
import Header from 'components/shared/Header/Header';
import Loader from 'components/shared/Loader/Loader';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { pm } from 'lib/helpers/utility';

const AdminIntelligence = () => {
  const permissions = useRecoilValue(state.permissions);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="flex h-full flex-col">
      <Header
        subtitle={
          <NavLinkTabs
            list={[
              ...(pm(permissions, 'ai_tasks.read') ? [{ name: 'AI Tasks', path: 'ai_task' }] : []),
              ...(pm(permissions, 'prompts.read')
                ? [{ name: 'Prompt Template', path: 'prompts' }]
                : []),
              ...(pm(permissions, 'models.read') ? [{ name: 'Models', path: 'model' }] : [])
            ]}
          />
        }>
        {isLoading && (
          <div className="flex flex-row gap-2">
            <Loader outlined color="primary" />
            <div>Loading...</div>
          </div>
        )}
      </Header>
      <Outlet
        context={{
          setLoading: setIsLoading
        }}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligence);
