import { requestApi } from './Api';

export const getDocumentHistory = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/document/history/get',
    params
  });

export const listDocumentHistory = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/document/history/list',
    params
  });

export const selectDocumentHistory = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/document/history/select',
    params
  });

export const saveDocumentHistory = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/document/history/save',
    params
  });
