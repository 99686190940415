import { requestApi } from './Api';

export const getPreferences = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/user/preferences/get',
    params
  });

export const getTablePreferences = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/user/preferences/table_preferences',
    params
  });

export const upsertPreferences = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/user/preferences/upsert',
    params
  });
