import { AlertContent } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import { clinicalNote as clinicalNoteState, permissions } from 'components/state';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { isDataReadOnly } from '../../lib/aggregatedDataHelper';
import DeleteCustomFormButton from './DeleteCustomFormButton';

const UpsertModal = ({
  showModal = false,
  hideModal = () => null,
  formik,
  children,
  title,
  loading,
  id = formik?.values?.cfr_id,
  disableBtn = false,
  onDeleteItem
}) => {
  const userPermissions = useRecoilValue(permissions);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const dataReadOnly = isDataReadOnly({
    data: formik?.values,
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    noteLocked: clinicalNote?.locked
  });

  return (
    showModal && (
      <Modal
        handleOpen={showModal}
        handleClose={hideModal}
        title={title}
        className="h-[90dvh] w-[1160px]"
        bodyClassName="bg-primary-10"
        modalCenterAnimation={true}
        isFooter={false}
        customHeader={
          <div className="flex w-full items-center justify-between">
            <Breadcrumb
              activeClassName="text-primary-700"
              breadcrumb={{
                setStep: () => {},
                step: 1,
                hasBackButton: false,
                steps: [{ title }, { title: id ? `Update ${title}` : `Create ${title}` }]
              }}
              key="breadcrumb"
            />
          </div>
        }
        isFooterSticky>
        <form className="mx-auto w-full max-w-[750px] px-8">
          {dataReadOnly && (
            <AlertContent
              title="Restricted: Read-Only Data Access"
              message="Editing this area is currently restricted. Contact your internal systems admin if needed."
              color="warning"
              width="full"
              className="!mb-4"
            />
          )}
          {children}
        </form>

        <ModalFooter
          className="z-50"
          leftButtons={
            <Button outlined text="Cancel" color="neutral" onClick={() => hideModal()} />
          }
          rightButtons={
            <div className="flex gap-2">
              {id && (
                <DeleteCustomFormButton
                  id={id}
                  modalOpen={showModal}
                  hideModal={hideModal}
                  disabled={dataReadOnly}
                  onDeleteItem={onDeleteItem}
                  type={title}
                />
              )}

              <Button
                disabled={loading || disableBtn || dataReadOnly}
                loading={loading}
                text={id ? 'Update' : 'Create'}
                data-qa="upsert-btn"
                onClick={() => formik.handleSubmit()}
              />
            </div>
          }
        />
      </Modal>
    )
  );
};

export default UpsertModal;
