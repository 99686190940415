import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import SignatureModal from 'components/shared/Modal/SignatureModal/SignatureModal';
import './TechnologyForm.scss';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';

export default function TechnologyConsentForm({
  hideTechnologyModal,
  updateSurcharge,
  setPractice,
  practice
}) {
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signature, setSignature] = useState(null);
  const sigPad = useRef();
  const divToPrint = useRef();
  const fullFormRef = useRef();
  const today = moment.utc().format('MM/DD/YYYY');
  const navigate = useNavigate();

  const hideSignatureModal = () => {
    setShowSignatureModal(false);
  };

  const trimSignature = () => {
    setSignature(sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const savePDF = async (handle) => {
    try {
      const { data } = await requestApi({
        url: '/api/practice/update',
        params: {
          fields: { technology_fee_consent: handle }
        },
        navigate
      });
      if (data.updatedItems > 0) {
        setPractice({ ...practice, technology_fee_consent: handle });
        updateSurcharge();
        hideTechnologyModal();
      }
    } catch (error) {
      Honeybadger.notify(
        `practice: ${practice.id}, file: TechnologyConsentForm, method: savePDF, error: ${error}`
      );
      console.error(error);
    }
  };

  return (
    <div className="TechnologyForm">
      <div ref={fullFormRef}>
        <div className="TechnologyForm__block">
          <h3 className="TechnologyForm__block__title">
            Myriad Health LLC Miscellaneous Fee Calculator Compliance Agreement
          </h3>
        </div>
        <div className="TechnologyForm__block">
          <p>
            Client has requested the ”Myriad Health LLC Miscellaneous Fee Calculator” add on
            feature. Client agrees to use the Miscellaneous Fee Calculator in compliance with any
            and all applicable federal, state, and local laws. Use of the Miscellaneous Fee
            Calculator in violation of any federal, state, or local laws, applicable health
            insurance company policies, or Payment Network rules may result in fines, penalties,
            non-payment or transaction chargebacks. Client understands that Myriad Health LLC is not
            responsible for any such violations of law, health insurance company policies, or
            Payment Network rules with respect to Client’s use of Miscellaneous Fee Calculator,
            which may be switched on or off at the Client’s discretion. Client understands that the
            Miscellaneous Fee Calculator can be adjusted or turned off at Client’s discretion.
            Client understands and agrees that this fee is applied as a cost of doing business to{' '}
            ALL transaction types offered; client will not apply this fee to only credit/debit
            transactions.{' '}
          </p>
        </div>
        <div className="TechnologyForm__block">
          <p className="TechnologyForm__block__sub flex flex-col">
            <em className="font-bold">Client Business name: {practice.name}</em>
            <em className="font-bold">Date: {today}</em>
            <em className="font-bold">Signature:</em>
          </p>
        </div>
      </div>
      <div className="TechnologyForm__block">
        <div className="TechnologyForm__block flex items-start justify-between">
          {signature ? (
            <div className="TechnologyForm__signature">
              <img src={signature} ref={divToPrint} />
            </div>
          ) : (
            <Button text="Click to sign" onClick={() => setShowSignatureModal(true)} />
          )}
        </div>
      </div>
      <SignatureModal
        showSignatureModal={showSignatureModal}
        hideSignatureModal={hideSignatureModal}
        signature={signature}
        trimSignature={trimSignature}
        savePDF={savePDF}
        sigPad={sigPad}
        divToPrint={divToPrint}
        fullFormRef={fullFormRef}
        y={140}
        fileName={`myriad-technology-consent-${practice.name}`}
      />
    </div>
  );
}
