import React from 'react';

export const CreateLabel = ({ inputValue, onClick }) => {
  return (
    <div
      className="absolute -left-[12px] -top-2 flex h-[calc(100%+16px)] w-[calc(100%+24px)] cursor-pointer items-center gap-1 rounded-[4px] border-0 !border-t border-solid !border-danger-100 bg-primary-50 !pl-[10px]"
      onClick={onClick}
      data-qa="tag-create-new">
      <span className="text-sm font-400 leading-[22px] text-neutral-900">Create a tag for</span>
      <span className="text-sm font-500 leading-[22px] text-neutral-900">"{inputValue}"</span>
    </div>
  );
};
