import { iaRa } from 'lib/helpers/utility';
import { isEqual } from 'lodash';

export const doEmptyFormExist = ({ newForm = {}, forms = [] }) => {
  const copyForms = [...iaRa(forms)];

  return copyForms.some((row) => {
    const copyRow = { ...row };
    const copyNewForm = { ...newForm };

    delete copyRow?.id;
    delete copyNewForm?.id;

    return isEqual(copyRow, copyNewForm);
  });
};
