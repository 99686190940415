import React, { useEffect, useState } from 'react';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';
import ManagePermission from './components/ManagePermission';
import { useRoleDescriptions } from 'lib/hooks/queries/roles/useRoleDescriptions';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Input from 'components/shared/Input/Input';
import Icon from 'components/shared/Icon/Icon';
import useDebounce from 'lib/hooks/useDebounce';

const ManagePermissions = () => {
  const { formik, setEnableNextTo } = useNewRoleContext();
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const {
    data = {},
    isLoading,
    isFetching
  } = useRoleDescriptions({
    params: {
      searchTerm: debouncedSearchTerm
    },
    dependencies: [debouncedSearchTerm]
  });

  const { descriptions } = data;

  useEffect(() => {
    setEnableNextTo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && !isFetching) {
      if (formik.values.permissions === null) {
        const descriptions = data?.descriptions;
        const permissions = Object.keys(descriptions);
        permissions.forEach((permission) => {
          const actions = Object.keys(descriptions[permission].actions);
          const permissionObject = {};
          actions.forEach((action) => {
            permissionObject[action] = false;
          });
          formik.setFieldValue(`permissions.${permission}`, permissionObject);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (formik.values.permissions !== null) {
      const hasPermissionTrue = Object.values(formik.values.permissions).some((permission) =>
        Object.values(permission).some((action) => action === true)
      );

      setEnableNextTo(hasPermissionTrue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.permissions]);

  return (
    <>
      {isLoading ? (
        <Skeleton count={1} height={1020} />
      ) : (
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <div className="inline-flex w-full flex-col items-center justify-start gap-9 rounded-md px-9 py-10">
            <div className="flex flex-col items-start justify-start gap-[21px] self-stretch">
              <div className="inline-flex items-center justify-between self-stretch">
                <p className="text-center text-base font-medium leading-normal text-primary-900">
                  Setup permissions for your new role.
                </p>
                <div className="flex !h-[42px] w-[200px] items-center justify-start gap-2.5 !outline-none focus-within:!border-none">
                  <Input
                    id="searchPermissions"
                    type="string"
                    placeholder="Search permissions"
                    value={searchTerm}
                    icon="new-search"
                    inputWrapperClassName="w-[200px] h-[42px] !rounded-none !bg-transparent !py-2 !pb-1 !pl-0 border-b-[1px] !border-neutral-200 border-0 justify-start items-center gap-3 inline-flex !outline-transparent focus-within:!border-b-[1px] focus-within:!border-neutral-200 focus-within:!outline-transparent"
                    onChange={(e) => {
                      setSearchTerm(e.target.value);
                    }}
                    rightText={
                      searchTerm && (
                        <Icon
                          icon="new-close"
                          onClick={() => {
                            setSearchTerm('');
                          }}
                        />
                      )
                    }
                  />
                </div>
              </div>
              {Object.keys(descriptions).map((description) => (
                <ManagePermission
                  key={description}
                  name={description}
                  permission={descriptions[description]}
                  highlight={searchTerm}
                />
              ))}
            </div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
};

export default ManagePermissions;
