import { useQuery } from '@tanstack/react-query';
import { getPractitioner, getPractitioners } from '../../../../api/Practitioner';
import { useNavigate } from 'react-router-dom';

export const usePractitioner = ({params = {}, options = {} ,dependencies = []}) => {
  const navigate = useNavigate();
  return useQuery(['practitioner',...dependencies], () => getPractitioner(params, navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
