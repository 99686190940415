import cs from 'classnames';
import PrintView from 'components/Patient/quote/components/PrintView';
import Configurations from 'components/Payments/Configurations';
import InvoiceReadOnly from 'components/Payments/components/InvoiceReadOnly';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Popup from 'components/shared/Filters/Popup';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import React, { useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';

export const InvoiceActionPopover = ({ data }) => {
  const invoice = data || {};

  const ref = useRef();

  const practice = useRecoilValue(currentPractice);

  const { patient } = useOutletContext();

  const [mode, setMode] = useState({ preview: false, pay: false });
  const [show, setShow] = useState(false);

  const handleOpen = ({ close, mode }) => {
    setShow(true);
    close();
    setMode((prev) => ({ ...prev, [mode]: true }));
  };

  const handleClose = () => {
    setShow(false);
    setMode({ preview: false, pay: false });
  };

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={180}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        {(close) => (
          <div className=" !py-[6px]">
            <div
              data-qa="view-receipt"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => handleOpen({ close, mode: 'preview' })}>
              <div className="ml-1 text-sm text-primary-900">Preview Details</div>
            </div>
            <div
              data-qa="view-receipt"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => handleOpen({ close, mode: 'pay' })}>
              <div className="ml-1 text-sm text-primary-900">Pay Balance</div>
            </div>
          </div>
        )}
      </Popup>

      <Modal
        slideFromRight
        isOpen={show}
        bodyClassName="!p-0"
        title="Invoice"
        className={cs('bg-white', mode?.preview && 'w-[800px]')}
        headButton={
          <div className="flex w-full justify-end">
            <ReactToPrint
              trigger={() => (
                <Button
                  color=""
                  size="small"
                  type="link"
                  text="Print"
                  icon="new-printer-bulk"
                  className="text-primary-900"
                />
              )}
              content={() => ref?.current}
            />
          </div>
        }
        handleClose={handleClose}>
        {mode?.preview && (
          <div ref={ref}>
            <PrintView
              patient={patient}
              practice={practice}
              createdAt={invoice?.dateofservice}
              content={<InvoiceReadOnly invoices={[invoice]} />}
            />
          </div>
        )}

        {mode?.pay && (
          <Configurations
            newInvoice={[
              {
                ...invoice,
                balance: invoice.balance,
                expanded: true,
                selected: true
              }
            ]}
            amount={invoice.balance}
            patientId={patient?.id}
            onClose={handleClose}
          />
        )}
      </Modal>
    </div>
  );
};

export default InvoiceActionPopover;
