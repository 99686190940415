import { isValidDate, search } from 'lib/helpers/utility';
import moment from 'moment';

export default function selectInsuranceCompany(e, setFieldValue, states) {
  const fieldsToUpdate = {};

  if (e === null) {
    fieldsToUpdate.payerid = null;
    fieldsToUpdate.payer_name = null;
    fieldsToUpdate.payer_addr_1 = null;
    fieldsToUpdate.payer_addr_2 = null;
    fieldsToUpdate.payer_city = null;
    fieldsToUpdate.payer_state = null;
    fieldsToUpdate.payer_zip = null;
    fieldsToUpdate.payer_office_number = null;
    fieldsToUpdate.ins_number = null;
    fieldsToUpdate.ins_name_f = null;
    fieldsToUpdate.ins_name_m = null;
    fieldsToUpdate.ins_name_l = null;
    fieldsToUpdate.ins_addr_1 = null;
    fieldsToUpdate.ins_addr_2 = null;
    fieldsToUpdate.ins_city = null;
    fieldsToUpdate.ins_state = null;
    fieldsToUpdate.ins_zip = null;
    fieldsToUpdate.ins_phone = null;
    fieldsToUpdate.pat_rel = null;
    fieldsToUpdate.ins_dob = null;
    fieldsToUpdate.ins_group = null;
  } else {
    const { ins, payer } = e;

    fieldsToUpdate.payerid = payer?.payerid;
    fieldsToUpdate.payer_name = payer?.payer_name;
    fieldsToUpdate.payer_addr_1 = payer?.payer_addr_1;
    fieldsToUpdate.payer_addr_2 = payer?.payer_addr_2;
    fieldsToUpdate.payer_city = payer?.payer_city;
    fieldsToUpdate.payer_state = search(states, payer?.payer_state_id, 'id');
    fieldsToUpdate.payer_zip = payer?.payer_zip;
    fieldsToUpdate.payer_office_number = payer?.payer_office_number;
    fieldsToUpdate.ins_number = ins?.ins_number;
    fieldsToUpdate.ins_name_f = ins?.ins_name_f ?? '';
    fieldsToUpdate.ins_name_m = ins?.ins_name_m ?? '';
    fieldsToUpdate.ins_name_l = ins?.ins_name_l ?? '';
    fieldsToUpdate.ins_addr_1 = ins?.ins_addr_1;
    fieldsToUpdate.ins_addr_2 = ins?.ins_addr_2;
    fieldsToUpdate.ins_city = ins?.ins_city;
    fieldsToUpdate.ins_state = search(states, ins?.ins_state, 'id');
    fieldsToUpdate.ins_zip = ins?.ins_zip;
    fieldsToUpdate.ins_phone = ins?.ins_phone;
    fieldsToUpdate.pat_rel = e.pat_rel;
    fieldsToUpdate.ins_dob = isValidDate(ins?.ins_dob)
      ? moment(ins?.ins_dob).format('YYYY-MM-DD')
      : null;
    fieldsToUpdate.ins_group = ins?.ins_group;
  }

  Object.entries(fieldsToUpdate).forEach(([fieldName, value]) => {
    setFieldValue(fieldName, value);
  });
}
