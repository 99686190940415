export const getCategoryName = (category) => {
  switch (category) {
    case 0:
      return 'Icons';
    case 1:
      return 'Logos';
    case 2:
      return 'Illustrations';
    case 3:
      return 'Backgrounds | Patterns | Shapes';
    default:
      return '';
  }
};

export const getIconCategory = (name) => {
  const lowerName = name.toLowerCase();

  if (lowerName.includes('logo') && !lowerName.includes('logout')) return 1; // Logos
  if (lowerName.includes('illustration') || lowerName.includes('ilustration')) return 2; // Illustrations
  if (
    lowerName.includes('bg') ||
    lowerName.includes('background') ||
    lowerName.includes('pattern') ||
    lowerName.includes('shape')
  )
    return 3; // Backgrounds/Patterns
  return 0; // Regular Icons
};

export const isLightIcon = (name) => {
  const lowerName = name.toLowerCase();
  return lowerName.includes('white');
};
