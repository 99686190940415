import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import { ia } from '../../../lib/helpers/utility';
import Select from '../../shared/Select/Select';

const SearchProviders = ({
  label = '',
  placeholder = 'Type something',
  disabled,
  onChange,
  value,
  className
}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const searchProvider = async (value) => {
    try {
      setLoading(true);

      const parts = value.split(' ');
      const numbers = parts.filter((part) => !isNaN(part)).join(' ');
      const nonNumbers = parts.filter((part) => isNaN(part));
      const firstName = nonNumbers.shift() || '';
      const lastName = nonNumbers.join(' ');

      const params = {
        number: numbers,
        first_name: firstName,
        last_name: lastName
      };

      const data = await interimApi(`/api/npi/get`, params, navigate);

      if (ia(data?.data.npi)) {
        const options = data.data.npi.map((item) => ({
          value: `${item.first_name} ${item.last_name} - NPI: ${item.number} - ${item.address.address_1}, ${item.address.city} ${item.address.state} ${item.address.postal_code}`,
          label: `${item.first_name} ${item.last_name} - NPI: ${item.number} - ${item.address.address_1}, ${item.address.city} ${item.address.state} ${item.address.postal_code}`
        }));
        setLoading(false);
        return options;
      }
      setLoading(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
      setLoading(false);
    }
  };

  return (
    <Select
      inputId="search-providers"
      className={className}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      id="search-providers"
      isAsync
      value={value}
      isClearable
      isLoading={loading}
      loadOptions={(event) => searchProvider(event)}
      onChange={onChange}
    />
  );
};

export default SearchProviders;
