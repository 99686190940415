import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { OrganizationContextProvider } from 'lib/context/OrganizationContext/OrganizationContextProvider';
import React, { useEffect, useState } from 'react';
import DataSelector from './SelectOwner/DataSelector';
import { getOrganizationLocations, importLocationData } from 'api/Practice';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useOrganizationContext } from 'lib/context/OrganizationContext/OrganizationContext';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';

const ImportDataModal = ({ importDataModalVisible, setImportDataModalVisible, organizationId }) => {
  const [locations, setLocations] = useState([]);
  const { practice } = useOutletContext();
  const { state, selectedLocation, importAll } = useOrganizationContext();
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();
  const navigation = useNavigate();

  useEffect(() => {
    fetchLocations();
  }, []);

  const fetchLocations = async () => {
    const params = { organizationId };
    await getOrganizationLocations(navigation, params).then((data) => {
      const filteredLocations = data?.locations
        ?.filter((loc) => loc.id !== practice.id)
        .map((loc) => ({ value: loc.id, label: loc.name }));
      setLocations(filteredLocations);
    });
  };

  const handleImportData = async () => {
    const params = {
      practiceId: practice.id,
      selectedLocation: selectedLocation?.value
    };

    if (importAll) {
      params.importAll = true;
    } else {
      params.importAll = false;
      params.data = state;
    }
    setLoading(true);
    const { code, duplicatePatients } = await importLocationData(navigation, params);
    if (code === 0) {
      setImportDataModalVisible(false);
      queryClient.invalidateQueries('practice');

      if (duplicatePatients) {
        showAlert({
          message: 'Data imported successfully, but some patients already exist',
          color: 'warning'
        });
      } else {
        showAlert({ message: 'Data imported successfully', color: 'success' });
      }
    }
  };

  return (
    <Modal
      handleOpen={importDataModalVisible}
      handleClose={() => setImportDataModalVisible(false)}
      title="Import Data"
      bodyClassName="p-0"
      customStyling={{ width: '720px' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setImportDataModalVisible(false)}
            data-qa="cancel-select-owner-btn"
          />

          <Button
            onClick={handleImportData}
            text={'Import Data'}
            id="createContactModalBtn"
            data-qa="append-owner-btn"
            loading={loading}
            disabled={!selectedLocation || loading}
          />
        </div>
      }>
      <DataSelector passedLocations={locations} />
    </Modal>
  );
};

export default ImportDataModal;
