import React, { useRef, useState } from 'react';
import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import moment from 'moment-timezone';

const Years = ({ currentMonth, changeMonthHandle }) => {
  const dateFormat = 'YYYY';
  const formattedYear = moment(currentMonth).format(dateFormat);
  const currentYear = moment().year();
  const currentYearRef = useRef(null);
  const [isHover, setIsHover] = useState(false);

  const handleYearSelect = (selectedYear) => {
    const newDate = moment(currentMonth).year(selectedYear);
    changeMonthHandle(newDate);
  };

  // list of 10 years ago and 10 years before
  const yearList = Array.from({ length: 21 }, (_, index) => currentYear - 10 + index)
    .toString()
    .split(',');

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <div>
          <Popover.Button
            onClick={currentYearRef?.current?.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })}
            className={cs(
              'group flex min-w-[86px] cursor-pointer select-none items-center gap-[6px] rounded-md bg-white px-[10px] py-[5px] xs:min-w-[70px]',
              open
                ? '!border-primary-300 !bg-primary-700 outline outline-2 outline-primary-50'
                : '!border-transparent'
            )}
            data-qa="calendar-year"
            as="div"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            <span
              className={cs(
                'text-sm font-500',
                open ? 'text-white' : 'text-primary-900 group-hover:text-primary-700'
              )}>
              {formattedYear}
            </span>
            <Icon
              icon="chevron-down"
              className={cs('ml-auto cursor-pointer duration-150', open && 'rotate-180 transform')}
              size={11}
              shade={isHover ? 700 : 900}
              color={open ? 'white' : 'primary'}
            />
          </Popover.Button>
          <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-[110px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
            <div className="mr-[6px] max-h-[420px] overflow-auto">
              {yearList.map((option, index) => (
                <div
                  key={index}
                  ref={formattedYear === option ? currentYearRef : null}
                  className={cs(
                    'flex gap-x-2 py-[5px] !pl-4 pr-[14px] transition-all hover:bg-primary-50',
                    formattedYear === option && 'bg-primary-50'
                  )}
                  onClick={() => {
                    handleYearSelect(option);
                    close();
                  }}>
                  <span className="text-sm font-400 text-primary-900">{option}</span>
                </div>
              ))}
            </div>
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default Years;
