import StickyNotes from 'components/practice/StickyNotes/StickyNotes';
import React, { useState } from 'react';
import CreatePopover from '../../OverviewBlock/Popovers/CreatePopover';
import SellPackageProductModal from 'components/Patient/sell/SellPackageProductModal';
import Modal from 'components/shared/Modal/Modal';
import Configurations from 'components/Payments/Configurations';
import { handleUpdatePatientTags } from '../../lib/updatePatientTags';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import OverviewPopover from '../../OverviewBlock/Popovers/OverviewPopover';
import { useRecoilValue } from 'recoil';
import { permissions, userState as user_state } from 'components/state';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import cs from 'classnames';
import { usePatientChartContext } from 'lib/context/PatientChartContext/PatientChartContext';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Allowed from 'components/shared/Permissions/Allowed';
import UnPinnedTagsContainer from './PatientTags/UnPinnedSection/UnPinnedTagsContainer';
import TagActions from './PatientTags/UnPinnedSection/components/Actions';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { debounce } from 'lodash';

const PatientActions = ({ patient = {}, showNewAppointmentModal }) => {
  const permission = useRecoilValue(permissions);
  const userState = useRecoilValue(user_state);
  const tagSettings = userState?.display_settings?.patientChartTags;
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const { togglePinnedSection, setTogglePinnedSection, isPinnedConfirmation } =
    usePatientChartContext();
  const tagsKind = 'chart';
  const { data } = useTags({ params: { kind: tagsKind }, dependencies: [tagsKind] });
  const allTags = data?.tags || [];
  const navigate = useNavigate();
  const { device } = useUIContext();
  const queryClient = useQueryClient();
  const { id } = useParams();
  const { updateTags } = useTagContext() || {};
  const [sellModalVisible, setSellModalVisible] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPinTagsModal, setShowPinTagsModal] = useState(false);
  const [isCreateModal, setIsCreateModal] = useState(false);
  const [openReorderModal, setOpenReorderModal] = useState(false);
  const [tagsToAdd, setTagsToAdd] = useState([]);

  const commonProps = {
    showNewAppointmentModal,
    setSellModalVisible,
    setShowPaymentModal
  };

  const debouncedUpdateTags = debounce(async (patientId, tags, mode) => {
    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId,
      tags,
      mode,
      fromChart: true
    });
  }, 1000);

  const handleAddTags = (tag) => {
    const filteredNewTags = tag?.filter(
      (newTag) => !patient?.tags.some((existingTag) => existingTag?.id === newTag?.id)
    );
    debouncedUpdateTags(patient?.id, filteredNewTags, 'add');
    setTagsToAdd([]);
    setIsCreateModal(false);
  };

  const handleRemoveTag = async (id) => {
    await handleUpdatePatientTags({
      navigate,
      queryClient,
      patientId: patient?.id,
      tags: [id],
      mode: 'remove',
      fromChart: true
    });
    setTagsToAdd([]);
  };

  const updateDisplaySettings = (isShowMessage = true) => {
    saveDisplaySettings({
      ...userState.display_settings,
      patientChartTags: {
        isPinned: true,
        showConfirmations: {
          ...userState.display_settings?.patientChartTags?.showConfirmations,
          pin: isShowMessage ? !isPinnedConfirmation : false
        }
      }
    });
    setShowPinTagsModal(false);
  };

  const handlePinTagsModal = () => {
    if (tagSettings?.showConfirmations?.pin) {
      setShowPinTagsModal(!showPinTagsModal);
      setTogglePinnedSection(true);
    } else {
      updateDisplaySettings(false);
      setTogglePinnedSection(true);
    }
  };

  const handleClosePinTagsModal = () => {
    setShowPinTagsModal(false);
    setTogglePinnedSection(false);
  };

  const selectActions = [
    {
      label: 'Create Tag',
      icon: 'new-tag-new',
      onClick: () => setIsCreateModal(true),
      disabled: false
    },
    {
      label: 'Re-order Tags',
      icon: 'new-sort-reorder',
      onClick: () => {
        updateTags(patient?.tags, allTags);
        setOpenReorderModal(true);
      },
      disabled: patient?.tags?.length < 2,
      tippyText:
        patient?.tags?.length < 2
          ? 'Select tags to add to this patient’s chart'
          : 'Re-order selected tags'
    },
    {
      label: 'Pin Tags',
      icon: 'new-pin-filled',
      onClick: handlePinTagsModal,
      disabled: false
    },
    {
      label: 'Manage Tags',
      icon: 'new-tag-settings',
      onClick: () => navigate('/portal/settings/tags'),
      disabled: false
    }
  ];

  return (
    <div
      className={cs(
        'flex flex-shrink-0 items-center',
        device !== 'mobile' ? 'gap-2' : ' gap-0 !pr-2',
        tagSettings?.isPinned &&
          !togglePinnedSection &&
          'pointer-events-none z-[100] rounded-md border border-dashed border-primary-500 bg-white px-2'
      )}>
      {patient && (
        <>
          {!togglePinnedSection && (
            <Allowed requiredPermissions="tags.read">
              <UnPinnedTagsContainer
                currentTags={patient?.tags}
                handlePinTagsModal={handlePinTagsModal}
                isCreateModal={isCreateModal}
                setIsCreateModal={setIsCreateModal}
                selectActions={selectActions}
                handleAddTags={handleAddTags}
                handleRemoveTag={handleRemoveTag}
                tagsToAdd={tagsToAdd}
                setTagsToAdd={setTagsToAdd}
              />
            </Allowed>
          )}
          {(device === 'desktop' || device === 'laptop' || device.includes('tablet')) && (
            <Allowed requiredPermissions="patient_sticky_notes.read">
              <StickyNotes
                patientId={patient.id}
                customStyle={{
                  button: 'cursor-pointer items-center bg-warning-50 flex gap-x-[6px] rounded-full',
                  icon: { size: 18 }
                }}
              />
            </Allowed>
          )}
        </>
      )}
      {(device === 'desktop' || device === 'laptop' || device.includes('tablet')) && (
        <Allowed
          requiredPermissions={[
            'appointments.create',
            'products.read',
            'packages.read',
            'payments.create'
          ]}
          isAny>
          <CreatePopover {...commonProps} />
        </Allowed>
      )}
      {permission?.patients?.delete && <OverviewPopover patient={patient} {...commonProps} />}
      {sellModalVisible && (
        <SellPackageProductModal
          sellModalVisible={sellModalVisible}
          setSellModalVisible={setSellModalVisible}
        />
      )}
      {showPaymentModal && (
        <Modal
          isOpen={showPaymentModal}
          handleClose={() => setShowPaymentModal(false)}
          slideFromRight
          handleOpen={showPaymentModal}
          title="New payment">
          <Configurations patientId={id} onClose={() => setShowPaymentModal(false)} />
        </Modal>
      )}
      <TagActions
        showPinTagsModal={showPinTagsModal}
        handleClosePinTagsModal={handleClosePinTagsModal}
        updateDisplaySettings={updateDisplaySettings}
        openReorderModal={openReorderModal}
        setOpenReorderModal={setOpenReorderModal}
      />
    </div>
  );
};

export default withErrorBoundary(PatientActions);
