import React from 'react';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Icon from 'components/shared/Icon/Icon';
import { iaRa } from 'lib/helpers/utility';

const NonERXPrescription = ({
  nonERXPrescription,
  nonERXPrescriptionFetching,
  setNavigateToParams,
  handleOpen,
  handleOnOpenDelete,
  ableToDelete
}) => {
  return nonERXPrescriptionFetching ? (
    <Skeleton count={5} />
  ) : (
    iaRa(nonERXPrescription).map((row, index) => (
      <div key={index} className="!mt-3 flex w-full items-center !gap-1 self-center">
        <div
          onClick={() => {
            setNavigateToParams({ type: 'non_erx_prescription', itemProps: row });
            handleOpen();
          }}
          className="flex w-full cursor-pointer items-center gap-1.5 rounded-lg bg-white !p-2 transition-all hover:!border-primary-200 hover:!bg-primary-50">
          <Icon size={24} icon="new-note" color="primary" />
          <div className="font-medium text-neutral-800">Type: Non e-RX Prescription,</div>
          <div className="font-medium text-neutral-800">Provider: {row?.provider?.fullName}</div>
        </div>
        {ableToDelete && (
          <div
            onClick={() => handleOnOpenDelete({ orderId: row.id, type: 'non_erx_prescription' })}
            className="flex h-full cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !p-2.5 transition-all hover:!border-primary-500 hover:!bg-primary-50">
            <Icon icon="new-trash-v2" className="cursor-pointer" size={20} />
          </div>
        )}
      </div>
    ))
  );
};

export default NonERXPrescription;
