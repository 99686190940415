import { objMap } from 'lib/helpers/utility';

export default function handleAppliedFilter(prev, filter) {
  try {
    const newFilters = { ...prev };
    const filledFilters = objMap(newFilters, (key, f) => {
      const filters = filter?.filters ? JSON.parse(filter?.filters) : [];
      return { ...f, values: filters[key] || newFilters[key].values };
    });
    return filledFilters;
  } catch (error) {
    return filter;
  }
}
