import React, { useImperativeHandle, useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

const Plan = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const planRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { plan: clinicalNote?.plan }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };
  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write plan notes here"
      id="plan"
      name="plan"
      className="min-h-[200px]"
      value={clinicalNote?.plan}
      onChange={(e) => handleChange(e, 'plan')}
      transcribing
      forwardedRef={planRef}
      disabled={clinicalNote?.locked || intelligenceAi.loading}
    />
  );
};

export default Plan;
