import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import { Link } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import CheckinPracticeLogo from 'components/Patient/checkin/PracticeLogo';

const InstantActionsAppointmentConfirmed = ({ loading, setAndAction, resubmit, data }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-between">
      <div className="h-fit w-full bg-white">
        <CheckinPracticeLogo practiceLogo={data?.practiceLogo} />
      </div>
      <div className="flex w-full flex-col items-center justify-center p-[2rem] sm:p-4">
        <Icon icon="appointment-success" />
        <div className="flex w-full flex-col text-center">
          <div className="my-[1rem] text-3xl font-500 text-primary-900 sm:my-[0.5rem]">
            Thank you for confirming your upcoming appointment
          </div>
          <p className="my-[1.5rem] text-lg text-primary-900 sm:my-[1.5rem]">
            Please complete all forms prior to your visit by clicking the button below. This will
            allow for a more streamlined and pleasant healthcare experience.
          </p>
          <Button
            text="Complete My Forms"
            loading={loading}
            onClick={() => {
              setAndAction(true);
              setTimeout(() => {
                resubmit();
              }, 50);
            }}
            className="my-[1rem] w-full"
          />
        </div>
      </div>
      <div className="w-full">
        <label className="!mx-10 !mb-5 flex flex-col text-center text-neutral-500">
          <Link to="/login">Login</Link>
          <Link to="/terms">Terms of Service and Privacy Policy</Link>
        </label>
        {data?.practiceLogo && (
          <div className="flex h-[44px] w-full items-center justify-between bg-primary-50 px-40">
            <h4 className="text-sm text-neutral-600">Powered By</h4>
            <Icon icon="logo" size={90} />
          </div>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(InstantActionsAppointmentConfirmed);
