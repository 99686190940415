import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash';
import { useRecoilValue } from 'recoil';

import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { camelToSpaceCase } from 'lib/helpers/utility';
import { usePatientHistory } from 'lib/hooks/queries/clinicalNotes/usePatientHistory';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import PreviewView from '../../PreviewView';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from '../configs';
import { formatDataForTable, getFinalPreviewData } from '../lib/helpers';

import EmptyPatientHistory from './EmptyPatientHistory';

export function Table({ breadcrumb, previewData, setPreviewData }) {
  const { setSteps, setStep, steps, step } = breadcrumb;
  const { id } = useParams();
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { setComponentToAdd, onAddComponent, setSelectedTableRows, onRemoveComponent } =
    useChatContextApi();
  const { selectedTableRows } = useChatContextData();
  const {
    selectedRows: selectedNodes,
    setSelectedRows: setSelectedNodes,
    gridApi
  } = useTableContext();
  const selectedTableNodes = selectedTableRows?.['patient_history']?.selectedNodes;
  const selectedRowsData = selectedNodes.map((node) => node.data);

  const { data } = usePatientHistory({
    params: { patientId: id },
    dependencies: [id],
    options: {
      select: (data) => ({
        history: formatDataForTable(data?.history)
      })
    }
  });
  const historyList = data?.history;

  useEffect(() => {
    setSteps([{ title: 'Patient History', name: 'start' }]);
    setStep(0);
  }, [setStep, setSteps]);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      selectedTableNodes.forEach((row) => {
        setSelectedNodes(selectedTableNodes);
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes, setSelectedNodes]);

  const getDataPath = useCallback((data) => [camelToSpaceCase(data.type), data.created_at], []);

  const getRowStyle = (params) => {
    const node = params.node;

    if (node.level === 1) {
      return {
        ...(node.lastChild && {
          borderBottom: '2px solid #54D2FF',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4
        }),
        borderLeft: '4px solid #54D2FF',
        borderRight: '2px solid #54D2FF',
        borderTop: '2px linear-gradient(to right, rgba(19, 185, 255, 0),rgba(19, 185, 255, 0.29))',
        background: 'linear-gradient(to right, rgba(19, 185, 255, 0),rgba(19, 185, 255, 0.29))'
      };
    }
  };

  const deletePatientFormsSelectedTableRows = useCallback(() => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['patient_history'];
    setSelectedTableRows(newSelectedTableRows);
  }, [selectedTableRows, setSelectedTableRows]);

  let body = (
    <>
      <div className="patient_table !mb-0 h-full flex-col px-1">
        <AGTable
          getRowStyle={getRowStyle}
          defaultColDef={DEFAULT_COLUMN_DEFS}
          columnDef={getColDefs(practiceTimezone, breadcrumb, setPreviewData)}
          gridOptions={GRID_OPTIONS}
          animateRows={true}
          data={historyList}
          getDataPath={getDataPath}
          noRowsOverlayComponent={EmptyPatientHistory}
          loadingOverlayComponent={() => (
            <div className="h-full w-[1160px] min-w-[480px] bg-white">
              <Skeleton count={24} />
            </div>
          )}
        />
      </div>
    </>
  );

  if (steps[step]?.name === 'preview') {
    const finalPreviewData = getFinalPreviewData(previewData);
    body = (
      <div className="pb-[112px]">
        <PreviewView previewData={finalPreviewData} />;
      </div>
    );
  }

  const onBackClick = () => {
    if (steps[step]?.name === 'preview') {
      setSteps((prev) => prev.slice(0, step));
      setStep((prev) => prev - 1);
    } else {
      setComponentToAdd(null);
    }
  };

  const onAddTag = () => {
    if (!selectedNodes?.length) {
      deletePatientFormsSelectedTableRows();
      onRemoveComponent();
    }

    setSelectedTableRows((prev) => ({
      ...prev,
      patient_history: {
        data: selectedRowsData || [],
        selectedNodes,
        status: !selectedRowsData?.length
          ? 'none'
          : selectedRowsData.length === historyList.length
            ? 'all'
            : 'partial'
      }
    }));
    onAddComponent();
  };

  const onDeletePatientHistoryTag = () => {
    deletePatientFormsSelectedTableRows();
    onRemoveComponent();
    setComponentToAdd(null);
  };

  return (
    <>
      {body}
      <div className="flex justify-center">
        {historyList?.length > 0 && (
          <ModalFooter
            className="z-50"
            rightButtons={[
              selectedTableNodes?.length > 0 && (
                <Icon
                  key={1}
                  icon="trash"
                  className="mr-3 cursor-pointer"
                  onClick={onDeletePatientHistoryTag}
                />
              ),
              <Button
                key={2}
                text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} Patient History`}
                iconSize={18}
                iconColor="white"
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={onAddTag}
                disabled={!selectedRowsData?.length && !selectedTableNodes?.length}
              />
            ]}
            leftButtons={
              <Button
                key={1}
                text="Back"
                outlined
                transparent
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={onBackClick}
              />
            }
          />
        )}
      </div>
    </>
  );
}
