import { useLocation } from 'react-router-dom';

export default function useNewDesign() {
  const location = useLocation();
  const newDesignLocations = [
    'checkin',
    'forms',
    'instant-packet',
    'documents',
    'appointments',
    'attachments'
  ];
  return newDesignLocations.some((path) => location?.pathname.includes(`/${path}`));
}
