import { ia, search } from 'lib/helpers/utility';
import moment from 'moment';
import getDiagnosesWhenClaim from './getDiagnosesWhenClaim';

export default function getClaimInfoFromSuperbill({ superbill, referringProvider, states }) {
  return {
    ...superbill.claim,
    ...getDiagnosesWhenClaim(superbill),
    pcn: superbill.patient.id,
    pat_sex: !superbill.patient.gender ? 'other' : superbill.patient.gender,
    pat_name_l: superbill.patient.l_name,
    pat_name_f: superbill.patient.f_name,
    pat_name_m: superbill.patient.m_name,
    pat_addr_1: superbill.patient?.userAddress?.address_ln_1,
    pat_addr_2: superbill.patient?.userAddress?.address_ln_2,
    pat_city: superbill.patient?.userAddress?.city,
    pat_state: search(states, superbill.patient?.userAddress?.state, 'id'),
    pat_zip: superbill.patient?.userAddress?.zip,
    pat_phone: superbill.patient.phone,
    pat_dob: moment(superbill.patient.dob).format('YYYY-MM-DD'),
    pat_rel:
      superbill.claim?.pat_rel || ia(superbill.insuranceProfile)
        ? superbill.insuranceProfile[0]?.relation
        : null,
    pat_marital: null,
    pat_employment: null,
    prov_name_l: superbill.claim.prov_name_l || superbill.practitioner.l_name,
    prov_name_m: superbill.claim.prov_name_m || superbill.practitioner.m_name,
    prov_name_f: superbill.claim.prov_name_f || superbill.practitioner.f_name,
    prov_npi: superbill.claim.prov_npi || superbill.practitioner?.practitionerClaimMdInfo?.npi,
    prov_id:
      superbill.claim.prov_id || superbill.practitioner?.practitionerClaimMdInfo?.atypical_id,
    prov_taxid:
      superbill.claim.prov_taxid ||
      superbill.practitioner?.practitionerClaimMdInfo?.tax_id ||
      superbill.practice?.tax_id,
    prov_taxid_type:
      superbill.claim.tax_id_type ||
      superbill.practitioner?.practitionerClaimMdInfo?.tax_id_type ||
      superbill.practice?.tax_id_type,
    prov_taxonomy:
      superbill.claim.prov_taxonomy ||
      superbill.practitioner?.practitionerClaimMdInfo?.taxonomy ||
      superbill.practice?.taxonomy,
    ref_name_l: superbill.claim.ref_name_l || referringProvider?.l_name || null,
    ref_name_f: superbill.claim.ref_name_f || referringProvider?.f_name || null,
    ref_name_m: superbill.claim.ref_name_m || referringProvider?.m_name || null,
    ref_npi: superbill.claim.ref_npi || referringProvider?.npi || null,
    cond_date: superbill.claim.cond_date
      ? moment.utc(superbill.claim.cond_date).format('YYYY-MM-DD')
      : referringProvider?.cond_date
        ? moment.utc(referringProvider?.cond_date).format('YYYY-MM-DD')
        : null
  };
}
