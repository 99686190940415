import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const DBAName = ({ practice }) => {
  const [dbaName, setDBAName] = useState(practice.dba_name || '');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleChange = (e) => {
    setDBAName(e.target.value);
  };

  const saveDBAName = async () => {
    setLoading(true);
    try {
      let res = await requestApi({
        url: '/api/practice/update',
        params: {
          practice_id: practice.id,
          fields: { dba_name: dbaName }
        },
        navigate
      });
      const { code } = res;
      if (code === 0) {
        showAlert({ message: 'DBA Name successfully updated', color: 'success' });
      }
      setLoading(false);
    } catch (error) {
      showAlert({ message: 'An error has occurred. Please try again later.', color: 'danger' });
      setLoading(false);
    }
    setLoading(false);
  };
  return (
    <div className="w-full p-3">
      <div className="w-full p-3">
        <Input
          label="Legal Name"
          placeholder="Legal Name"
          type="text"
          name={'legal_name'}
          value={practice?.name}
          disabled
        />
      </div>
      <div className="w-full p-3">
        <Input
          label="DBA Name"
          type="text"
          placeholder="DBA Name"
          name={'dba_name'}
          value={dbaName}
          onChange={(e) => handleChange(e)}
          tooltip="The 'Doing Business As' (DBA) name entered here will be used in all patient communications, including appointment reminders and billing notices. If left blank, communications will default to the legal name of the practice. Use a DBA name that patients easily recognize to enhance clarity and branding in your interactions."
        />
      </div>
      <div className="p-3 pt-2 ">
        <Button text="Save" color="primary" loading={loading} onClick={saveDBAName} />
      </div>
    </div>
  );
};

export default DBAName;
