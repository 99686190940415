import Honeybadger from '@honeybadger-io/js';

import { getNdcCodes } from 'api/NdcCodes';

import { Capitalize } from 'lib/helpers/utility';

const handleNdcSearch = async ({ searchTerm, navigate, searchType = 'package_ndc' }) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 20,
        searchType,
        strict: true,
        v2: true
      };
      let data = await getNdcCodes(navigate, params);
      const ndcCodes = data?.ndc;
      if (ndcCodes) {
        return ndcCodes.map((item) => {
          return {
            value: item?.package_ndc,
            label: `[ ${Capitalize(item?.generic_name)} ]\n ${item?.packaging_description}`
          };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  }
};

export default handleNdcSearch;
