import React from 'react';

import { capitalize } from 'lodash';

import { formatDate } from 'lib/helpers/utility';

import Dot from 'components/shared/Dot/Dot';
import Icon from 'components/shared/Icon/Icon';
import Status from 'components/shared/Status/Status';

import { isMedicationReadOnly } from '../../lib/aggregatedDataHelper';
import Actions from '../components/Actions';

import { MEDICATION_STATUS } from './constants';

const disableRow = (params, userPermissions, noteLocked, fromWeightLoss) => {
  if (params?.data?.weight_loss_medication_id && fromWeightLoss) {
    return { 'pointer-events': 'none', opacity: '0.4' };
  }
  const isReadOnly = isMedicationReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: params?.data,
    noteLocked
  });

  return isReadOnly ? { 'pointer-events': 'none', opacity: '0.4' } : {};
};

export default (props) => {
  const columns = [
    {
      field: 'medication',
      headerName: 'Medication',
      headerCheckboxSelection: props?.showCheckboxes,
      checkboxSelection: props?.showCheckboxes,
      flex: 2,
      cellRenderer: (params) => {
        if (params.node.group || !params?.data) {
          return null;
        }

        const isMedicationActive = params?.data?.status === MEDICATION_STATUS.ACTIVE;
        const isPatient = params?.data?.patient_id === params?.data?.created_by;

        return (
          <div className="flex h-full w-full items-center gap-2">
            <Icon
              icon="new-document-preview"
              shade={600}
              color="neutral"
              onClick={() => {
                if (props.fromWeightLoss) props.setShowPreviewMedicationFromWhl(true);
                props.setShowMedicationModal({ open: true, data: params?.data });
              }}
            />

            <Dot size="md" color={isMedicationActive ? 'success' : 'danger'} />

            <span className="truncate text-sm font-400 text-neutral-900">
              {params?.data?.medication}
            </span>

            {isPatient && <Icon icon="new-user-arrow-right" shade={600} color="neutral" />}
          </div>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: (params) => {
        if (params.node.group || !params?.data) {
          return null;
        }

        const status = params?.data?.status;
        return (
          <div className="flex h-full items-center justify-center !p-3">
            {status === 'active' ? (
              <Status
                status={status}
                textClassName="!text-success-500"
                className="flex max-h-[24px] min-w-fit items-center justify-center border border-solid border-success-200 !bg-success-50"
              />
            ) : (
              <Status
                status={status}
                textClassName="!text-danger-500"
                className="flex max-h-[24px] min-w-fit items-center justify-center border border-solid border-danger-200 !bg-danger-50"
              />
            )}
          </div>
        );
      }
    },
    {
      field: 'dose',
      headerName: 'Dose',
      cellRenderer: (params) => {
        if (params.node.group || !params?.data) {
          return null;
        }

        const content = `${params?.data?.strength ?? ''}${params?.data?.unit ? ` (${params?.data?.unit})` : ''}`;

        return <span>{content ? content : 'N/A'}</span>;
      }
    },
    {
      field: 'prescriber.label',
      headerName: 'Prescriber',
      valueFormatter: ({ value }) => (!!value ? capitalize(value) : 'N/A')
    },
    {
      field: 'start_date',
      headerName: 'Start date',
      valueFormatter: ({ value }) => (value ? value : 'N/A')
    },
    {
      field: 'end_date',
      headerName: 'End date',
      valueFormatter: ({ value }) => (value ? value : 'N/A')
    },
    {
      field: 'actions',
      headerName: 'Actions',
      cellRenderer: (params) => <Actions data={params?.data} customProps={props} />,
      maxWidth: 100,
      pinned: 'right',
      cellStyle: (params) =>
        disableRow(params, props.userPermissions, props.noteLocked, props.fromWeightLoss)
    }
  ];
  return columns;
};
