import React from 'react';
import Icon from '../../Icon/Icon';
import cs from 'classnames';

const ActionButton = ({ onClick, icon, text, className, active, ...rest }) => {
  return (
    <div
      data-qa={`action-button-${icon}`}
      className={cs(
        'flex h-[32px] w-[32px] items-center justify-center rounded-lg border-transparent !outline-none hover:bg-neutral-100',
        active && 'border !border-primary-500 !bg-primary-50',
        className
      )}
      onClick={onClick}
      {...rest}>
      {icon && <Icon icon={icon} className="cursor-pointer" />}
      {text && <span className="ml-2">{text}</span>}
    </div>
  );
};

export default ActionButton;
