import React, { useEffect, useState, useRef } from 'react';
import { EditRoleContext } from './EditRoleContext';
import { useParams, useNavigate } from 'react-router-dom';
import { isEqual } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import state from 'components/state';
import { useRecoilState, useRecoilValue } from 'recoil';

const EditRoleValidationSchema = Yup.object().shape({
  role_name: Yup.string().required('Role name is required')
});

export const EditRoleContextProvider = ({ children }) => {
  const [showEditRoleModal, setShowEditRoleModal] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [actionsEnabled, setActionsEnabled] = useState({});
  const userState = useRecoilValue(state.userState);
  const [userPermissions, setUserPermissions] = useRecoilState(state.permissions);
  const { id } = useParams();
  const currentUserId = userState.user_id;
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const roleTypes = [
    { value: 'nurse', label: 'Nurse' },
    { value: 'doctor', label: 'Doctor' },
    { value: 'biller', label: 'Biller' }
  ];

  const formik = useFormik({
    initialValues: {
      role_id: null,
      role_name: '',
      role_description: '',
      role_type: '',
      permissions: null,
      users_assigned: []
    },
    validationSchema: EditRoleValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      handleUpdate();
      setSubmitting(false);
    }
  });

  const initialFormValues = useRef(null);

  useEffect(() => {
    if (
      formik.values.role_name !== '' &&
      formik.values.permissions !== null &&
      initialFormValues.current === null
    ) {
      initialFormValues.current = { ...formik.values };
    }

    if (initialFormValues.current !== null) {
      const hasFormChanged = !isEqual(initialFormValues.current, formik.values);

      if (hasFormChanged) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    }
  }, [formik.values]);

  const handleClose = () => {
    setShowEditRoleModal(false);
    setEnableSubmit(false);
    setActionsEnabled({});
    formik.resetForm();
  };

  const handleUpdate = async () => {
    const usersAssigned = formik.values.users_assigned;
    const newPermissions = formik.values.permissions;
    try {
      const res = await requestApi({
        url: `/api/permissions/update`,
        params: {
          ...formik.values,
          practiceId: id
        },
        navigate
      });

      queryClient.invalidateQueries(['getRoleList']);
      queryClient.invalidateQueries(['getRoleDescriptions']);
      queryClient.invalidateQueries(['getRole']);
      queryClient.invalidateQueries(['permissions']);
      handleClose();
      const { code } = res;
      if (code === 0) {
        showAlert({ title: 'Role create succesfully and assigned to users.', color: 'success' });
        if (usersAssigned.includes(currentUserId)) {
          //Update recoil permissions
          setUserPermissions(newPermissions);
        }
      } else {
        showAlert({ title: 'Role creation failed.', color: 'danger' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const { id: practiceId } = useParams();
  return (
    <EditRoleContext.Provider
      value={{
        formik,
        setShowEditRoleModal,
        showEditRoleModal,
        handleClose,
        practiceId,
        roleTypes,
        enableSubmit,
        setEnableSubmit,
        actionsEnabled,
        setActionsEnabled
      }}>
      {children}
    </EditRoleContext.Provider>
  );
};
