import React, { useEffect, useRef, useState } from 'react';

import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import moment from 'moment';
import { useRecoilState } from 'recoil';
import * as Yup from 'yup';

import { requestApi } from 'api/Api';
import { createPatient } from 'api/Patients';
import { getStates } from 'api/State';

import { ia } from 'lib/helpers/utility';
import { useSmartNavigate } from 'lib/hooks/useSmartNavigate';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';

import { appointmentData } from '../schedulingState';

import './css/ContactInformation.scss';

const validator = require('validator');

const NewPatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email('Email must be valid email').required('Email required'),
  password: Yup.string()
    .required('Password is required')
    .min(4, 'Password must be at least 4 characters'),
  password_confirmation: Yup.string()
    .required('Password confirmation is required')
    .min(4, 'Password must be at least 4 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  dob: Yup.string()
    .required('Date of birth required')
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
      'Date must be in MM/DD/YYYY format'
    )
    .test('maxDate', 'Date cannot be in the future', (value) => {
      if (!value) return false;
      const parsedDate = moment(value, 'MM/DD/YYYY', true);
      return parsedDate.isValid() && !parsedDate.isAfter(moment());
    }),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;
    if (value === undefined || value === null || value === '')
      return createError({ message: 'Phone number required' });
    if (!validator.isMobilePhone(value)) return createError({ message: 'Invalid phone number.' });
    return true;
  }),
  address_ln_1: Yup.string().required('Address required'),
  city: Yup.string().required('City required'),
  state: Yup.string().required('State required'),
  zip: Yup.string().required('Zip code required')
});

export default function ContactInformation() {
  const [appData] = useRecoilState(appointmentData);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const { navigate, navigateTo } = useSmartNavigate();
  const formRef = useRef();

  useEffect(() => {
    getStatesMethod();
  }, []);

  useEffect(() => {
    if (!appData.userId) return;
    appData?.practice_require_card
      ? navigate('/book/card-on-file')
      : navigate('/book/confirmation');
  }, [appData.userId]);

  const formik = useFormik({
    initialValues: {
      f_name: '',
      l_name: '',
      email: '',
      password: '',
      password_confirmation: '',
      dob: '',
      phone: '',
      address_ln_1: '',
      city: '',
      state: '',
      zip: '',
      invitationEmail: true
    },
    validationSchema: NewPatientValidationSchema,
    validateOnChange: true,
    onSubmit: async (values, { setSubmitting }) => {
      const patientToBeCreated = {
        patient: {
          f_name: values.f_name,
          l_name: values.l_name,
          email: values.email,
          dob: values.dob,
          phone: values.phone,
          password: values.password
        },
        address: {
          address_ln_1: values.address_ln_1,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        practitioner_id: appData.practitioner_userId,
        invitationEmail: values.invitationEmail
      };

      setSubmitting(true);
      await submitPatient(patientToBeCreated);
      setSubmitting(false);
    }
  });

  const submitPatient = async (data) => {
    const modifiedPatient = {
      ...data.patient
    };

    const modifiedData = {
      ...data,
      patient: modifiedPatient,
      type: 'self_scheduling_register'
    };

    try {
      const res = await createPatient(navigate, modifiedData);

      if (res.patient) {
        if (res?.patient?.patientId || res?.patient?.id) {
          if (res?.code === 0 && res?.message) {
            showAlert({ message: res.data.message, duration: 6000, color: 'success' });
          } else {
            showAlert({ message: 'Account created successfully.', color: 'success' });
          }
          submitUserLogin(modifiedData.patient.email, modifiedData.patient.password);
        } else if (res.data.code === 2) {
          showAlert({ message: 'This patient already exists in your directory', color: 'danger' });
        } else {
          console.error(res.data.message);
          Honeybadger.notify(
            `file: self_scheduling/steps/ContactInformation, method: submitPatient - else, error: ${
              res.data.message ?? 'Theres been an error'
            }`
          );
          if (res.data.message) showAlert({ message: res.data.message });
        }
      } else {
        if (res.code === 3) {
          return showAlert({
            message: res?.error ?? 'This email already exists!',
            color: 'danger'
          });
        }
        if (res.code !== 0) {
          return showAlert({
            message: res?.error || 'Something went wrong with the register!',
            color: 'danger'
          });
        }
      }
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: submitPatient - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getStatus = async ({ user_id, fullName }) => {
    try {
      const { status } = await requestApi({
        url: '/api/patient/appointment/self_schedule',
        params: {
          user_full_name: fullName,
          user_id
        },
        navigate
      });
      if (status == 200) {
        navigate('/book/card-on-file');
      }
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: getStatus - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const submitUserLogin = async (email, password) => {
    try {
      const res = await requestApi({
        url: '/api/user/login',
        params: {
          email,
          password
        },
        navigate
      });

      const { code, user, error } = res;
      switch (code) {
        case 0:
          appData?.practice_require_card
            ? await getStatus({ fullName: `${user.f_name} ${user.l_name}`, user_id: user.user_id })
            : navigate('/book/confirmation');
          break;
        case 1:
          if (error) {
            showAlert({ message: error, color: 'danger' });
          } else {
            showAlert({
              message: `There's been an error logging in. Please check your credentials.`,
              color: 'danger'
            });
          }
          break;
        default:
          if (error) showAlert({ message: error, color: 'danger' });
          Honeybadger.notify(
            `file: self_scheduling/steps/ContactInformation, method: submitUserLogin - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (err) {
      const res = err.response;
      if (res) {
        const { code, error, redirect } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 1:
            if (error) showAlert({ message: error, color: 'danger' });
            else
              showAlert({
                message: `There's been an error logging in. Please check your credentials.`,
                color: 'danger'
              });
            break;
          default:
            if (error) showAlert({ message: error, color: 'danger' });
            Honeybadger.notify(
              `file: self_scheduling/steps/ContactInformation, method: submitUserLogin - catch, error: ${
                error ?? 'Theres been an error'
              }`
            );
            break;
        }
      }
    }
  };

  const handleSubmitNewPatient = (e) => {
    e.preventDefault();
    if (formRef.current) {
      formik.submitForm();
    }
  };

  const getStatesMethod = async () => {
    try {
      let res = await getStates(navigate, { method: 'get' });
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/ContactInformation, method: getStates - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const handleSelectState = (state) => {
    setSelectedState(state);
    formik.setFieldValue('state', state?.value);
  };

  return (
    <div className="ml-10 w-full">
      <div className="mb-20">
        <h3 className="ContactTitle">Register</h3>
      </div>
      <form ref={formRef}>
        <div className="GroupContainer mb-10">
          <Input
            required
            className="w-[250px]"
            label="First name"
            placeholder="First name"
            value={formik.values.f_name}
            onChange={formik.handleChange}
            name="f_name"
            id="firstName"
            error={formik.errors.f_name}
          />
          <Input
            required
            label="Last name"
            className="w-[250px]"
            placeholder="Last Name"
            value={formik.values.l_name}
            onChange={formik.handleChange}
            name="l_name"
            id="last_name"
            error={formik.errors.l_name}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Input
            required
            label="Phone Number"
            className="w-[250px]"
            value={formik.values.phone}
            onChange={formik.handleChange}
            placeholder="Phone number"
            name="phone"
            id="phone"
            error={formik.errors.phone}
          />
          <Input
            required
            className="w-[250px]"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="email"
            name="email"
            id="email"
            error={formik.errors.email}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Input
            required
            label="Address"
            placeholder="Address"
            value={formik.values.address_ln_1}
            onChange={formik.handleChange}
            name="address_ln_1"
            id="address_ln_1"
            error={formik.errors.address_ln_1}
          />
          <Input
            required
            label="City"
            placeholder="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            name="city"
            id="city"
            error={formik.errors.city}
          />
          <Input
            required
            label="Zip code"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formik.handleChange}
            id="zipCode"
            error={formik.errors.zip}
          />
        </div>
        <div className="GroupContainer mb-10">
          <div>
            <Select
              label="State"
              required
              parentClassName="w-[250px]"
              placeholder="Type and select state"
              value={selectedState}
              onChange={(value) => handleSelectState(value)}
              options={
                ia(states)
                  ? states.map((state) => ({
                      value: state.id,
                      label: state.name
                    }))
                  : []
              }
              inputId="state"
            />
            {formik.errors.state && (
              <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.state}</span>
            )}
          </div>
          <div>
            <DatePopover
              label="Date of birth"
              labelClassName="w-max"
              placeholder="Date of birth"
              name="dob"
              className="w-[250px]"
              value={formik.values.dob}
              onChange={(event) => formik.setFieldValue('dob', event)}
            />
            {formik.errors.dob && (
              <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.dob}</span>
            )}
          </div>
        </div>
        <div className="GroupContainer mb-20">
          <Input
            required
            className="w-[250px]"
            label="Password"
            type="Password"
            value={formik.values.password}
            onChange={formik.handleChange}
            placeholder="Password"
            name="password"
            id="password"
            error={formik.errors.password}
          />
          <Input
            required
            className="w-[250px]"
            label="Confirm Password"
            type="Password"
            placeholder="Password"
            value={formik.values.password_confirmation}
            onChange={formik.handleChange}
            name="password_confirmation"
            id="password_confirmation"
            error={formik.errors.password_confirmation}
          />
        </div>
        <div className="GroupContainer mb-10">
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            black
            onClick={handleSubmitNewPatient}>
            Create Account
          </Button>
        </div>
        <div className="GroupContainer">
          Already have an account?
          <div
            className="TextBlueColor"
            onClick={() => navigateTo('/login', { selfSchedulingRedirect: true })}>
            Log in
          </div>
        </div>
      </form>
    </div>
  );
}
