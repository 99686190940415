import cs from 'classnames';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ia } from '../../../../../../../lib/helpers/utility';
import Checkbox from '../../../../../../shared/Checkbox/Checkbox';
import { getItemStyle } from '../../lib/renderedDragAndDropHelper';
import FormList from './FormList';

const List = ({
  item,
  index,
  toggleExpand,
  handleClick,
  keyParent,
  handleClickDropdown,
  onDragEndChildren
}) => {
  const { clinicalNote } = useClinicalNoteContext() || {};

  const hasFormType = ia(item?.formType);

  const listClassName = hasFormType
    ? 'w-full'
    : 'flex w-full items-center rounded-[4px] bg-neutral-100 bg-white border border-solid border-neutral-100';
  const listLabelClassName = hasFormType ? 'cursor-pointer' : 'w-full';
  const listInputClassName =
    hasFormType &&
    'rounded !border border-solid border-transparent bg-neutral-100 bg-white border border-solid border-neutral-100 ';

  return (
    <Draggable
      key={item.id}
      draggableId={item.id}
      index={index}
      isDragDisabled={clinicalNote?.locked}>
      {(provided, snapshot) => (
        <div
          key={item.id}
          className="!mb-3 flex cursor-pointer items-start gap-x-[2px]"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
          <div className="!mx-2 w-full">
            <Checkbox
              disabled={clinicalNote?.locked}
              data-qa={`form-${item.id}`}
              label={item.title || item.label}
              isChecked={item.checked}
              onChange={(e) => handleClick(keyParent, e.target.value)}
              className={cs(listClassName, snapshot.isDragging && 'bg-neutral-200')}
              dropDownClassName="bg-white border border-solid border-neutral-100 rounded-md"
              labelClassName={listLabelClassName}
              inputClassName={cs(
                listInputClassName,
                item.expanded && 'shadow-[0px_4px_8px_rgba(0,0,0,0.04)]'
              )}
              parentLabelClassName="px-4 py-[11px]"
              leftIcon="new-sort"
              isDropdown={hasFormType}
              expanded={item?.expanded}
              setExpanded={() => toggleExpand(keyParent)}>
              {hasFormType && (
                <FormList
                  forms={item.formType}
                  keyParent={keyParent}
                  handleClickDropdown={handleClickDropdown}
                  onDragEndChildren={onDragEndChildren}
                />
              )}
            </Checkbox>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default List;
