import { isObject } from 'lodash';

const colors = ['#FCA5A5', '#4ADE80', '#33C9FF', '#22C55E', '#F59E0B', '#EF4444', '#27373D'];

export const generateChartData = (notifications) => {
  const chartData = [];
  let colorIndex = 0;
  if (isObject(notifications)) {
    for (const [key, value] of Object.entries(notifications)) {
      const parsedValue = parseInt(value, 10);
      chartData.push({
        key,
        name: key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' '),
        value: isNaN(parsedValue) ? 0 : parsedValue,
        fill: colors[colorIndex % colors.length]
      });
      colorIndex++;
    }
  }

  return chartData;
};

export const getPercentage = (data, displayName, total) => {
  const value = data[displayName];
  const percentage = (value / total) * 100;
  return isNaN(percentage) ? 0 : percentage;
};

export const getIconByPercentage = (label, data, total) => {
  const percentage = getPercentage(data, label, total);
  if (percentage === 0) {
    return 'new-arrow-right-v2';
  } else if (percentage < 0) {
    return 'new-arrow-down-v2';
  } else {
    return 'new-arrow-up-v2';
  }
};

export const getIconColorByPercentage = (label, data, total) => {
  const percentage = getPercentage(data, label, total);
  if (percentage === 0) {
    return 'neutral';
  } else if (percentage < 0) {
    return 'danger';
  } else {
    return 'success';
  }
};

export const getClassNamesByPercentage = (label, data, total) => {
  const percentage = getPercentage(data, label, total);
  if (percentage === 0) {
    return 'border-solid border-neutral-200  bg-neutral-50 text-neutral-600';
  } else if (percentage < 0) {
    return 'border-solid border-red-200 bg-red-50 text-danger-500';
  } else {
    return 'border-solid border-success-200 bg-success-50 text-success-600';
  }
};
