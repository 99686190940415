import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { orderBy } from 'lodash';
import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import Icon from 'components/shared/Icon/Icon';
import { permissions } from 'components/state';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { discountTypes, procedureTypes } from 'constants.js';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import {
  handleDiscountChange,
  handleTaxChange
} from 'components/Payments/components/Invoices/invoicesHelpers';
import { currentPractice } from 'components/practice/practiceState';
import { calculateProceduresTotal } from 'components/public/lib/utils';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { Capitalize, arrayToObject, formatDate, ia, iaRa, mString } from 'lib/helpers/utility';
import TaxDetails from 'components/practice/appointment/PreviewAppointment/Financial/components/TaxDetails';

const AppointmentInvoices = ({
  patientId,
  appointment,
  invoices = [],
  setInvoices,
  hasInvoice,
  calculateTotalAmount,
  setTemporarySurchargeDisable,
  amountInputDisabled,
  show,
  showUnpaidButton = true
}) => {
  const navigate = useNavigate();

  const p = useRecoilValue(permissions);
  const practice = useRecoilValue(currentPractice);

  const [loading, setLoading] = useState(false);
  const [showTaxDetails, setShowTaxDetails] = useState(false);
  const [showButton, setShowButton] = useState(showUnpaidButton);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [selectAll, setSelectAll] = useState(invoices.every((obj) => obj.selected));

  const claims = practice?.display_settings?.claims;

  const { device } = useUIContext();

  useEffect(() => {
    if (!hasInvoice && !ia(invoices)) getInvoices();
  }, []);

  const getInvoices = async () => {
    setLoading(true);

    const onSuccess = (data) => {
      const { invoices: loadedInvoices } = data;

      if (ia(loadedInvoices, -1)) {
        const filtered = loadedInvoices
          .filter((i) => i?.appointment_id !== appointment?.id)
          .map((i) => ({
            ...i,
            expanded: false,
            selected: hasInvoice ? false : true,
            discountAmount: i?.discount?.amount_cents
          }));

        const orderedData = orderBy(
          filtered,
          [(item) => (item.appointment ? item.appointment.starts_at : item.created_at)],
          'desc'
        );

        const orderedInvoices = [...invoices, ...orderedData];

        setInvoices(orderedInvoices);
        calculateTotalAmount(orderedInvoices);
      }

      setShowButton(false);
      setLoading(false);
    };

    const onError = () => setLoading(false);

    requestApi({
      url: '/api/appointment/invoices/get',
      params: { user_id: patientId },
      onSuccess,
      onError,
      navigate
    });
  };

  const handleCheckboxChange = (index) => {
    const updatedInvoices = invoices.map((obj, i) => {
      if (i === index) return { ...obj, selected: !obj.selected };
      return obj;
    });

    setInvoices(updatedInvoices);
    setTemporarySurchargeDisable(false);
    calculateTotalAmount(updatedInvoices);
  };

  const handleExpandedChange = (index) => {
    setInvoices((prevData) =>
      prevData.map((obj, i) => {
        if (i === index) return { ...obj, expanded: !obj.expanded };
        return obj;
      })
    );
  };

  const handleBalanceChange = (index, balance) => {
    const newBalance = Number(balance);

    const updatedInvoices = invoices.map((obj, i) => {
      if (i === index) {
        return { ...obj, balance: newBalance };
      } else {
        return obj;
      }
    });

    setInvoices(updatedInvoices);
    calculateTotalAmount(updatedInvoices);
  };

  const handleSelectAllChange = () => {
    const updatedInvoices = invoices.map((invoice) => ({
      ...invoice,
      selected: !selectAll
    }));

    setSelectAll(!selectAll);
    setInvoices(updatedInvoices);
    calculateTotalAmount(updatedInvoices);
  };

  const { data } = useQuery({
    queryKey: ['getCodes'],
    queryFn: getCodes,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const codes = ia(data?.codes) ? arrayToObject(data?.codes, 'code', 'description') : [];

  async function getCodes() {
    return await requestApi({
      navigate,
      url: '/api/codes/get',
      params: { services: ['adjustment_reason', 'claim_adjustment_reason'] }
    });
  }

  const handleAdjustmentCodes = (adjustmentCodes) => {
    const content = adjustmentCodes?.map((adj, i) => {
      const { group, code, amount } = adj || {};

      return group && code && amount ? (
        <div className="!mb-2 flex flex-col !gap-1 text-left font-400" key={i}>
          <p>{`${mString(amount * 100)} · ${group}${code}`}</p>

          <div>
            <p className="text-xs">{`${group} - ${codes[group]}`}</p>
            <p className="text-xs">{`${code} - ${codes[code]}`}</p>
          </div>
        </div>
      ) : (
        mString(0)
      );
    });

    return (
      <Tippy content={content} interactive>
        <div className="relative">
          <Icon icon="info" />
        </div>
      </Tippy>
    );
  };

  return (
    <div className="overflow-x-auto bg-white">
      {device === 'mobile' ? (
        <>
          <div
            className={cs(
              'flex gap-3 !p-4 !py-3 ',
              !invoices[0]?.expanded && 'border !border-x-0 !border-t-0 !border-neutral-200'
            )}>
            <label className="!text-sm=text-primary-800 mb-0 w-2/3">Invoice</label>

            <label className="= mb-0 w-1/3 !text-sm text-primary-800">Service Date</label>

            <label className="mb-0 w-1/3 text-right !text-sm text-primary-800">Balance</label>
          </div>
          <div className="flex flex-col">
            {ia(invoices) &&
              invoices?.map((i, idx) => {
                let amountPaid = (i?.amount_paid || 0) + (i?.discount?.amount_cents || 0);

                const currentBalance = i?.total_amount - amountPaid + (i?.newTax ?? i?.tax ?? 0);

                const stripped = idx % 2 == 0;

                const {
                  tax_amount_cents = 0,
                  discount = 0,
                  prevDiscount = 0,
                  charge = 0,
                  amount_paid = 0,
                  ins_payment = 0,
                  ins_adjustment = 0
                } = i || {};

                const total =
                  charge -
                  amount_paid -
                  ins_payment -
                  ins_adjustment -
                  prevDiscount +
                  tax_amount_cents;

                const amountDue = total - discount;

                return (
                  <div
                    key={i?.id}
                    className={cs(
                      i?.expanded && 'border !border-x-0 !border-primary-400',
                      stripped && 'bg-[#F5FCFF]',
                      invoices[idx + 1]?.expanded && '!border-b-0'
                      // i?.selected ? '!border-primary-100 bg-primary-50 ' : '!border-neutral-100'
                    )}>
                    <div className="flex gap-3  !p-4">
                      <div className="flex w-2/3 items-center  gap-3">
                        <Icon
                          icon="new-chevron-down"
                          color={i?.expanded && 'primary'}
                          className={cs(
                            ' cursor-pointer rounded-md p-[2px] ease-in-out',
                            i?.expanded ? 'rotate-180 bg-primary-100' : 'bg-neutral-100'
                          )}
                          onClick={() => handleExpandedChange(idx)}
                        />
                        <Checkbox
                          id={`invoice-${i.id}`}
                          inputClassName="scale-[0.8]"
                          labelClassName="text-primary-900"
                          isChecked={i?.selected}
                          label={`Invoice #${i.id}`}
                          handleClick={() => handleCheckboxChange(idx)}
                        />
                      </div>

                      <label className="mb-0 w-1/3 font-500 text-primary-900">
                        {i?.appointment?.starts_at &&
                          formatDate(i?.appointment?.starts_at, appointment?.practice?.timezone)}
                      </label>

                      <label className="mb-0 w-1/3 text-right font-500 text-primary-900">
                        {mString(currentBalance || 0)}
                      </label>
                    </div>

                    {i?.expanded && (
                      <>
                        <div className="flex w-full flex-col !gap-2 bg-white !p-4">
                          <div className="flex items-end justify-between gap-4">
                            <p className="text-xs font-medium text-neutral-500">Billed</p>
                            <p className="font-medium text-neutral-800">{mString(charge)}</p>
                          </div>

                          <div className="flex items-end justify-between gap-4">
                            <p className="text-xs font-medium text-neutral-500">
                              Insurance Covered
                            </p>
                            <p className="font-medium text-neutral-800">
                              {ins_payment > 0 ? '-' : ''}
                              {mString(ins_payment)}
                            </p>
                          </div>

                          <div className="flex items-end justify-between gap-4">
                            <p className="text-xs font-medium text-neutral-500">
                              Insurance Adjusted
                            </p>
                            <p className="font-medium text-neutral-800">
                              {ins_adjustment > 0 ? '-' : ''}
                              {mString(ins_adjustment)}
                            </p>
                          </div>
                        </div>

                        <div className="flex w-full flex-col !gap-2 border !border-x-0 !border-b-0 !border-primary-200 bg-primary-50 !p-4">
                          <div className="flex items-end justify-between gap-4">
                            <p className="text-sm font-medium text-neutral-700">Subtotal</p>
                            <p className="text-sm font-medium text-neutral-700">{mString(total)}</p>
                          </div>

                          <div className="flex items-end justify-between gap-4">
                            <p className="text-sm font-medium text-neutral-700">Amount Due</p>
                            <p className="text-base font-medium text-neutral-800">
                              {mString(amountDue)}
                            </p>
                          </div>
                        </div>

                        {invoiceDetails === i.id && (
                          <>
                            <div className="flex w-full justify-between !gap-2 border !border-x-0 !border-t-0 !border-primary-200 bg-white !p-4">
                              <p className="font-semibold text-primary-600 ">
                                Detailed Invoice Information
                              </p>
                              <p className="font-medium text-neutral-800">
                                {i?.appointment?.starts_at &&
                                  formatDate(
                                    i?.appointment?.starts_at,
                                    appointment?.practice?.timezone
                                  )}{' '}
                                (Date of Service)
                              </p>
                            </div>

                            {ia(i?.procedures) &&
                              i?.procedures.map((procedure) => {
                                const {
                                  name,
                                  type,
                                  amount,
                                  total_cost_cents,
                                  sales_count,
                                  pt_balance,
                                  adjustment
                                } = procedure || {};
                                const { charge, ins_adjustment, ins_payment, pt_adjustment } =
                                  calculateProceduresTotal([procedure]);

                                const ptBalance = pt_balance ?? (total_cost_cents || 0);

                                return (
                                  <>
                                    <div className="flex w-full flex-col !gap-2 border !border-x-0 !border-t-0 !border-primary-200 bg-white !p-4">
                                      <p className="font-medium text-neutral-900">{name}</p>

                                      <div className="flex items-end justify-between gap-4">
                                        <p className="text-xs font-medium text-neutral-500">Type</p>
                                        <p className="font-medium text-neutral-800">
                                          {procedureTypes?.find((o) => o?.value === type)?.label ||
                                            Capitalize((type || '').replace('_', ' ')) ||
                                            'Service'}
                                        </p>
                                      </div>

                                      <div className="flex items-end justify-between gap-4">
                                        <p className="text-xs font-medium text-neutral-500">Qty</p>
                                        <p className="font-medium text-neutral-800">
                                          {sales_count || 1}
                                        </p>
                                      </div>

                                      <div className="flex items-end justify-between gap-4">
                                        <p className="text-xs font-medium text-neutral-500">
                                          Charge
                                        </p>
                                        <p className="font-medium text-neutral-800">
                                          {mString(charge || amount || total_cost_cents || 0)}
                                        </p>
                                      </div>

                                      {claims && (
                                        <>
                                          <div className="flex items-end justify-between gap-4">
                                            <p className="text-xs font-medium text-neutral-500">
                                              Est. Ins. Pay
                                            </p>
                                            <p className="font-medium text-neutral-800">
                                              {mString(ins_payment || 0)}
                                            </p>
                                          </div>
                                          <div className="flex items-end justify-between gap-4">
                                            <p className="text-xs font-medium text-neutral-500">
                                              Est. Ins. Adj
                                            </p>
                                            <p className="font-medium text-neutral-800">
                                              {mString(ins_adjustment || 0)}
                                              {ia(adjustment) && handleAdjustmentCodes(adjustment)}
                                            </p>
                                          </div>
                                        </>
                                      )}

                                      <div className="flex items-end justify-between gap-4">
                                        <p className="text-xs font-medium text-neutral-500">
                                          Pt. bal
                                        </p>
                                        <p className="font-medium text-neutral-800">
                                          {mString(ptBalance)}
                                          {ia(pt_adjustment) &&
                                            handleAdjustmentCodes(pt_adjustment)}
                                        </p>
                                      </div>
                                    </div>

                                    {/* <div className="!p-4 border !border-x-0 !border-primary-200 flex flex-col bg-white w-full !gap-2">
                                      <div className="flex justify-between items-end gap-4">
                                        <p className="text-neutral-500 text-xs font-medium">
                                          Subtotal
                                        </p>
                                        <p className="text-neutral-800 font-medium">600$</p>
                                      </div>

                                      <div className="flex justify-between items-end gap-4">
                                        <p className="text-neutral-500 text-xs font-medium">
                                          AmountDue
                                        </p>
                                        <p className="text-neutral-800 font-medium">600$</p>
                                      </div>
                                    </div> */}
                                  </>
                                );
                              })}

                            {/* TODO: Add products and packages */}

                            {ia(i?.products) &&
                              i?.products.map(
                                ({ name, amount, sales_count, total_amount_cents }) => {
                                  return (
                                    <>
                                      <div className="flex w-full flex-col !gap-2 border !border-x-0 !border-t-0 !border-primary-200 bg-white !p-4">
                                        <p className="font-medium text-neutral-900">{name}</p>

                                        <div className="flex items-end justify-between gap-4">
                                          <p className="text-xs font-medium text-neutral-500">
                                            Type
                                          </p>
                                          <p className="font-medium text-neutral-800">Product</p>
                                        </div>

                                        <div className="flex items-end justify-between gap-4">
                                          <p className="text-xs font-medium text-neutral-500">
                                            Qty
                                          </p>
                                          <p className="font-medium text-neutral-800">
                                            {sales_count || 1}
                                          </p>
                                        </div>

                                        <div className="flex items-end justify-between gap-4">
                                          <p className="text-xs font-medium text-neutral-500">
                                            Charge
                                          </p>
                                          <p className="font-medium text-neutral-800">
                                            {mString(amount || total_amount_cents)}
                                          </p>
                                        </div>

                                        {claims && (
                                          <>
                                            <div className="flex items-end justify-between gap-4">
                                              <p className="text-xs font-medium text-neutral-500">
                                                Est. Ins. Pay
                                              </p>
                                              <p className="font-medium text-neutral-800">
                                                {mString(0)}
                                              </p>
                                            </div>
                                            <div className="flex items-end justify-between gap-4">
                                              <p className="text-xs font-medium text-neutral-500">
                                                Est. Ins. Adj
                                              </p>
                                              <p className="font-medium text-neutral-800">
                                                {mString(0)}
                                              </p>
                                            </div>
                                          </>
                                        )}

                                        <div className="flex items-end justify-between gap-4">
                                          <p className="text-xs font-medium text-neutral-500">
                                            Pt. bal
                                          </p>
                                          <p className="font-medium text-neutral-800">
                                            {mString(total_amount_cents)}
                                          </p>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                          </>
                        )}

                        <div
                          onClick={() => setInvoiceDetails((details) => (details ? null : i.id))}
                          className="flex w-full cursor-pointer items-center justify-center bg-white !p-3 hover:!bg-[#F5FCFF]">
                          <p className="text-primary-600">
                            {invoiceDetails === i.id ? 'Hide Details' : 'View Details'}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </>
      ) : (
        <>
          <div
            className={cs(
              'flex gap-3 !p-4 !py-3 ',
              !invoices[0]?.expanded && 'border !border-x-0 !border-t-0 !border-neutral-200'
            )}>
            <div className="flex w-2/3 gap-3">
              <span className="w-[24px]"></span>

              <Checkbox
                id="invoice-all"
                label="Invoice"
                inputClassName="scale-[0.8]"
                isChecked={selectAll || (invoices.every((obj) => obj.selected) && true)}
                // isPartial={selectAll && true}
                handleClick={handleSelectAllChange}
              />
            </div>

            <label className="mb-0 w-1/3 !text-sm font-500">Service Date</label>

            <label className="mb-0 w-1/3 text-right !text-sm font-500">Balance</label>
          </div>

          <div className="flex flex-col">
            {ia(invoices) &&
              invoices?.map((i, idx) => {
                const isAppointment = i?.appointment_id;

                let amountPaid = i?.amount_paid + (i?.discount?.amount_cents || 0);

                const currentBalance = i?.total_amount - amountPaid + (i?.newTax ?? i?.tax ?? 0);

                const total_insurance_payment = calculateProceduresTotal(
                  i?.procedures
                )?.ins_payment;

                const stripped = idx % 2 == 0;

                const calcPercentage = Math.round(
                  (i?.discount?.amount_cents / i?.total_amount) * 10000
                );

                return (
                  <div
                    key={i?.id}
                    className={cs(
                      i?.expanded && 'border !border-x-0 !border-primary-400',
                      stripped && 'bg-[#F5FCFF]',
                      invoices[idx + 1]?.expanded && '!border-b-0'
                      // i?.selected ? '!border-primary-100 bg-primary-50 ' : '!border-neutral-100'
                    )}>
                    <div className="flex gap-3  !p-4">
                      <div className="flex w-2/3 items-center  gap-3">
                        <Icon
                          icon="new-chevron-down"
                          color={i?.expanded && 'primary'}
                          className={cs(
                            ' cursor-pointer rounded-md p-[2px] ease-in-out',
                            i?.expanded ? 'rotate-180 bg-primary-100' : 'bg-neutral-100'
                          )}
                          onClick={() => handleExpandedChange(idx)}
                        />
                        <Checkbox
                          id={`invoice-${i.id}`}
                          inputClassName="scale-[0.8]"
                          isChecked={i?.selected}
                          label={`Invoice #${i.id}`}
                          handleClick={() => handleCheckboxChange(idx)}
                        />
                      </div>

                      <label className="mb-0 w-1/3 font-500">
                        {i?.appointment?.starts_at &&
                          formatDate(i?.appointment?.starts_at, appointment?.practice?.timezone)}
                      </label>

                      <label className="mb-0 w-1/3 text-right font-500">
                        {mString(currentBalance || 0)}
                      </label>
                    </div>

                    {i?.expanded && (
                      <>
                        <div className=" grid w-full !gap-4 bg-white">
                          <table className="bg-inherit">
                            <thead className="uppercase text-neutral-600">
                              <tr className="border-x-0 !border-b border-t-0 border-solid border-b-primary-100 text-left text-xs tracking-wide">
                                <td className="!py-2 !pl-4 !font-400" title="Details">
                                  Details
                                </td>
                                <td className="!py-2 !font-400" title="Type">
                                  Type
                                </td>
                                <td className="!py-2 !font-400" title="Quantity">
                                  Qty
                                </td>
                                <td className="!py-2 !font-400" title="Charge">
                                  Charge
                                </td>
                                {claims ? (
                                  <>
                                    <td
                                      className="w-[105px] !py-2 !font-400"
                                      title="Estimated Insurance Payment">
                                      Est. Ins. Pay
                                    </td>
                                    <td
                                      className="w-[105px] !py-2 !font-400"
                                      title="Estimated Insurance Adjustment">
                                      Est. Ins. Adj
                                    </td>
                                  </>
                                ) : null}
                                <td
                                  className="w-[105px] !py-2 !pr-4 !text-end !font-400"
                                  title="Patient Balance">
                                  Pt. bal
                                </td>
                              </tr>
                            </thead>

                            <tbody>
                              {/* Procedures */}
                              {ia(i?.procedures) &&
                                i?.procedures.map((procedure, index) => {
                                  const {
                                    name,
                                    type,
                                    amount,
                                    total_cost_cents,
                                    sales_count,
                                    quantity,
                                    pt_balance,
                                    adjustment
                                  } = procedure || {};
                                  const { charge, ins_adjustment, ins_payment, pt_adjustment } =
                                    calculateProceduresTotal([procedure]);

                                  const ptBalance = pt_balance ?? (total_cost_cents || 0);

                                  return (
                                    <tr
                                      key={name + index}
                                      className="border-x-0 !border-b border-t-0 border-solid border-b-primary-100 text-left hover:bg-transparent">
                                      <td className="max-w-[240px] !py-2 !pl-4 text-left text-sm text-primary-900">
                                        {name}
                                      </td>
                                      <td className="text-sm text-primary-900">
                                        {procedureTypes?.find((o) => o?.value === type)?.label ||
                                          Capitalize((type || '').replace('_', ' ')) ||
                                          'Service'}
                                      </td>
                                      <td className="!py-2 text-sm text-primary-900">
                                        {quantity || sales_count || 1}
                                      </td>
                                      <td className="!py-2 text-sm text-primary-900">
                                        {mString(charge || amount || total_cost_cents || 0)}
                                      </td>
                                      {claims ? (
                                        <>
                                          <td className="!py-2 text-sm text-primary-900">
                                            {mString(ins_payment || 0)}
                                          </td>
                                          <td className="!py-2 text-sm text-primary-900">
                                            <div className="flex gap-1">
                                              {mString(ins_adjustment || 0)}
                                              {ia(adjustment) && handleAdjustmentCodes(adjustment)}
                                            </div>
                                          </td>
                                        </>
                                      ) : null}
                                      <td className="!py-2 !pl-0 !pr-4 text-sm font-bold text-primary-900">
                                        <div className="flex justify-end gap-1">
                                          {mString(ptBalance)}
                                          {ia(pt_adjustment) &&
                                            handleAdjustmentCodes(pt_adjustment)}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}

                              {/* Packages with procedures and products*/}
                              {ia(i?.packages) &&
                                i?.packages.map(
                                  (
                                    {
                                      name,
                                      total_amount_cents,
                                      procedures,
                                      products,
                                      status,
                                      sales_count
                                    },
                                    index
                                  ) => {
                                    const hasProcedures = ia(procedures);
                                    const hasProducts = ia(products);

                                    return (
                                      <>
                                        <tr
                                          key={name + index}
                                          className={cs(
                                            'text-left hover:bg-transparent',
                                            status === 'cancelled' && 'line-through',
                                            !hasProcedures &&
                                              'border-x-0 !border-b border-t-0 border-solid border-b-primary-100'
                                          )}>
                                          <td className="max-w-[240px] !py-2 !pl-4 text-left text-sm text-primary-900">
                                            {name}
                                          </td>
                                          <td className="!py-2 text-sm text-primary-900">
                                            Package
                                          </td>
                                          <td className="!py-2 text-sm text-primary-900">
                                            {sales_count || 1}
                                          </td>
                                          <td className="!py-2 text-sm text-primary-900">
                                            {mString(total_amount_cents)}
                                          </td>
                                          {claims ? <td colSpan={2}></td> : null}
                                          <td className="!py-2 !pl-0 !pr-4 !text-end text-sm font-bold text-primary-900">
                                            {mString(total_amount_cents)}
                                          </td>
                                        </tr>

                                        {hasProcedures &&
                                          procedures.map(({ name, quantity }, index) => {
                                            return (
                                              <tr
                                                key={name + index}
                                                className={cs(
                                                  'text-left hover:bg-transparent',
                                                  status === 'cancelled' && 'line-through',
                                                  procedures?.length - 1 === index &&
                                                    !hasProducts &&
                                                    'border-x-0 !border-b border-t-0 border-solid border-b-primary-100 '
                                                )}>
                                                <td className="max-w-[240px] !py-2 !pl-6 text-left text-sm text-neutral-600">
                                                  {name}
                                                </td>
                                                <td></td>
                                                <td className="!py-2 text-sm text-neutral-600">
                                                  {quantity || 1}
                                                </td>

                                                <td></td>
                                                {claims ? <td colSpan={2}></td> : null}
                                                <td></td>
                                              </tr>
                                            );
                                          })}

                                        {hasProducts &&
                                          products.map(({ name, quantity }, index) => {
                                            return (
                                              <tr
                                                key={name + index}
                                                className={cs(
                                                  'text-left hover:bg-transparent',
                                                  status === 'cancelled' && 'line-through',
                                                  products?.length - 1 === index &&
                                                    'border-x-0 !border-b border-t-0 border-solid border-b-primary-100'
                                                )}>
                                                <td className="max-w-[240px] !py-2 !pl-6 text-left text-sm text-neutral-600">
                                                  {name}
                                                </td>
                                                <td></td>
                                                <td className="!py-2 text-sm text-neutral-600">
                                                  {quantity || 1}
                                                </td>
                                                <td></td>
                                                {claims ? <td colSpan={2}></td> : null}
                                                <td></td>
                                              </tr>
                                            );
                                          })}
                                      </>
                                    );
                                  }
                                )}

                              {/* Products */}
                              {ia(i?.products) &&
                                i?.products.map(
                                  ({ name, amount, sales_count, total_amount_cents }, index) => {
                                    return (
                                      <tr
                                        key={name + index}
                                        className="border-x-0 !border-b border-t-0 border-solid border-b-primary-100 text-left hover:bg-transparent">
                                        <td className="max-w-[240px] !py-2 !pl-4 text-left text-sm text-primary-900">
                                          {name}
                                        </td>
                                        <td className="!py-2 text-sm text-primary-900">Product</td>
                                        <td className="!py-2 text-sm text-primary-900">
                                          {sales_count || 1}
                                        </td>
                                        <td className="!py-2 text-sm text-primary-900">
                                          {mString(amount || total_amount_cents)}
                                        </td>
                                        {claims ? (
                                          <>
                                            <td className="!py-2 text-sm text-primary-900">
                                              {mString(0)}
                                            </td>
                                            <td className="!py-2 text-sm text-primary-900">
                                              {mString(0)}
                                            </td>
                                          </>
                                        ) : null}
                                        <td className="!py-2 !pl-0 !pr-4 !text-end text-sm font-bold text-primary-900">
                                          {mString(total_amount_cents)}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )}
                            </tbody>
                          </table>
                        </div>

                        <div className=" flex justify-end !gap-2 bg-[#F5FCFF] !px-4 !py-3">
                          <div className="flex flex-col gap-1  ">
                            <div className="flex items-end justify-between gap-16">
                              <p className="mb-[1px] text-sm text-primary-800">Patient total</p>
                              <p className="text-base font-semibold text-primary-900">
                                {mString(i?.total_amount)}
                              </p>
                            </div>

                            <div className="flex items-end justify-between gap-16">
                              <p className="mb-[1px] text-sm text-primary-800">Insurance paid</p>
                              <p className="text-base font-semibold text-primary-900">
                                {mString(
                                  i?.last_applied?.type === 'era' ? total_insurance_payment : 0
                                )}
                              </p>
                            </div>

                            <div className="flex items-end justify-between gap-16">
                              <p className="mb-[1px] text-sm text-primary-800">Patient paid</p>
                              <p className="text-base font-semibold text-primary-900">
                                {mString(i?.amount_paid)}
                              </p>
                            </div>

                            <div className="border !border-x-0 !border-t-0 !border-primary-300 !py-1"></div>

                            {show ? (
                              <div className="flex items-end justify-between gap-16">
                                <p className="mb-[1px] text-sm text-primary-800">Payment amount</p>
                                <p className="text-base font-semibold text-primary-900">
                                  {mString(i?.balance)}
                                </p>
                              </div>
                            ) : (
                              <>
                                {i?.total_amount > 0 && (
                                  <div className="!mt-2 flex items-center justify-between !gap-8">
                                    <p className="text-sm text-primary-800">Discount </p>

                                    <CurrencyInput
                                      data-qa="set-discount"
                                      value={
                                        !i?.discount?.value && i?.discount?.type === 'percent'
                                          ? calcPercentage
                                          : (i?.discount?.value ?? i?.discount?.amount_cents)
                                      }
                                      prefix={i?.discount?.type !== 'percent' ? '$' : ''}
                                      suffix={i?.discount?.type === 'percent' ? '%' : ''}
                                      parentClassName="!w-[140px]"
                                      maxValue={
                                        i?.discount?.type === 'percent'
                                          ? 10000
                                          : currentBalance + (i?.discount?.amount_cents || 0)
                                      }
                                      className="text-right font-600 text-primary-900"
                                      onValueChange={(newBalance) =>
                                        handleDiscountChange({
                                          idx,
                                          newBalance,
                                          invoices,
                                          setInvoices,
                                          calculateTotalAmount,
                                          discountType: i?.discount?.type
                                        })
                                      }
                                      disabled={
                                        !p?.billing?.discount || amountInputDisabled || false
                                      }
                                      units={discountTypes}
                                      currentUnit={
                                        discountTypes.find(
                                          (t) => t?.value === (i?.discount?.type || 'amount')
                                        )?.label
                                      }
                                      onUnitChange={(value) => {
                                        if (value === i?.discount?.type) return;
                                        handleDiscountChange({
                                          idx,
                                          invoices,
                                          setInvoices,
                                          discountType: value,
                                          calculateTotalAmount
                                        });
                                      }}
                                    />
                                  </div>
                                )}

                                {i?.total_amount > 0 && (
                                  <>
                                    <div className="flex items-center justify-between !gap-8">
                                      <p className="flex items-center gap-[6px] text-sm text-primary-800">
                                        Tax
                                        {isAppointment && (
                                          <Icon
                                            shade="800"
                                            color="primary"
                                            icon={`chevron-${showTaxDetails ? 'up' : 'down'}`}
                                            onClick={() => setShowTaxDetails((show) => !show)}
                                          />
                                        )}
                                      </p>

                                      <CurrencyInput
                                        data-qa="set-tax"
                                        onValueChange={(value) =>
                                          handleTaxChange({
                                            idx,
                                            value,
                                            invoices,
                                            setInvoices,
                                            calculateTotalAmount
                                          })
                                        }
                                        value={i?.newTax ?? i?.tax}
                                        parentClassName="!w-[140px]"
                                        disabled={amountInputDisabled || false}
                                        className="text-right font-600 text-primary-900"
                                      />
                                    </div>

                                    {showTaxDetails && isAppointment && (
                                      <TaxDetails
                                        tax={i?.tax}
                                        actions={false}
                                        items={[...iaRa(i?.procedures), ...iaRa(i?.products)]}
                                      />
                                    )}
                                  </>
                                )}

                                <div className="flex items-center justify-between !gap-16">
                                  <p className="text-sm text-primary-800"> Payment amount </p>

                                  <CurrencyInput
                                    data-qa="set-payment-amount"
                                    value={i?.balance}
                                    maxValue={currentBalance}
                                    parentClassName="!w-[140px]"
                                    disabled={amountInputDisabled}
                                    className="text-right font-600 text-primary-900"
                                    onValueChange={(v) => handleBalanceChange(idx, v)}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
          </div>
        </>
      )}

      {showButton && (
        <div className="!p-4">
          <Button
            type="link"
            onClick={() => getInvoices()}
            text="Show other unpaid invoices"
            className="mb-0 pl-0 font-500 text-primary-500"
          />
        </div>
      )}

      {!showButton && invoices?.length === 0 && (
        <p className="w-full !p-4 text-center font-500 text-neutral-900">
          You don't have any invoices.
        </p>
      )}
    </div>
  );
};

export default AppointmentInvoices;
