import React, { useState } from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import { ia } from 'lib/helpers/utility';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const PacketsList = ({ formPacket, packetId, index }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const { formik, setSelectedLength } = useFormBuilderContext();

  const handleCheck = (id) => {
    const newCheckedStatus = !isChecked;
    const { form_packet_ids } = formik.values;
    setIsChecked(newCheckedStatus);
    if (newCheckedStatus) {
      formik.setFieldValue('form_packet_ids', [...form_packet_ids, id]);
      setSelectedLength((prev) => prev + 1);
    } else {
      formik.setFieldValue(
        'form_packet_ids',
        form_packet_ids.filter((packetId) => packetId !== id)
      );
      setSelectedLength((prev) => prev - 1);
    }
  };

  const { form_ids, service_ids, provider_ids } = formPacket;
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div
        className={cs(
          'mb-2 rounded-[10px] border border-solid bg-white p-3',
          isActive || isChecked ? 'border-primary-500' : 'border-neutral-100'
        )}>
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-2">
            <Checkbox
              key={formPacket.id}
              id={index}
              isChecked={formik.values?.form_packet_ids.includes(formPacket?.id) || isChecked}
              handleClick={() => handleCheck(formPacket?.id)}
            />
            {isActive && (
              <Tippy content="Active" className="tippy-dark" placement="bottom">
                <div className="flex items-center ">
                  <Icon icon="active" />
                </div>
              </Tippy>
            )}
            <p className="text-sm font-500 text-primary-900">{formPacket?.name}</p>
            {packetId && packetId === formPacket?.id && (
              <p className="text-[12px] font-500 text-success-500">Recently Created</p>
            )}
          </div>
          <div>
            <Icon
              icon={isActive ? 'arrow-square-up' : 'arrow-square-down'}
              onClick={() => setIsActive(!isActive)}
              color={isActive && 'primary'}
            />
          </div>
        </div>
        {isActive && (
          <div>
            <hr className="h-[1px] border-0 bg-neutral-100" />
            <div className="flex justify-between">
              <div>
                <p className="pb-2 text-[12px] font-500 text-primary-500">Forms</p>
                <p className="pb-2 text-[12px] font-500 text-primary-500">Service</p>
                <p className="pb-2 text-[12px] font-500 text-primary-500">Providers</p>
              </div>
              <div>
                <div className="flex items-center justify-end">
                  {ia(form_ids) ? (
                    <>
                      {form_ids.slice(0, 4).map((form, idx) => (
                        <p
                          key={idx}
                          className="relative px-1 pb-2 text-[12px] font-500 text-neutral-500">
                          {form.label}
                          <span className="absolute right-0 top-[5px] h-[10px] w-[1px] bg-neutral-200"></span>
                        </p>
                      ))}
                      {form_ids.length > 4 && (
                        <Tippy
                          content={form_ids.slice(4).map((form, idx) => (
                            <p
                              key={idx}
                              className="relative px-1 pb-1 text-[12px] font-500 text-white">
                              {form.label}
                            </p>
                          ))}
                          className="tippy-dark"
                          placement="top">
                          <div className="flex items-center">
                            <p className="relative top-[-4px] rounded-full bg-neutral-50 px-2 text-[12px] font-500 text-neutral-500">
                              +{form_ids.length - 4}
                            </p>
                          </div>
                        </Tippy>
                      )}
                    </>
                  ) : (
                    <p className="pb-2  text-[12px] font-500 text-neutral-500">N/A</p>
                  )}
                </div>
                <div className="flex items-center justify-end">
                  {ia(service_ids) ? (
                    <>
                      {service_ids.slice(0, 4).map((service, idx) => (
                        <p
                          key={idx}
                          className="relative px-1 pb-2 text-[12px] font-500 text-neutral-500">
                          {service.label}
                          <span className="absolute right-0 top-[5px] h-[10px] w-[1px] bg-neutral-200"></span>
                        </p>
                      ))}
                      {service_ids.length > 4 && (
                        <Tippy
                          content={service_ids.slice(4).map((service, idx) => (
                            <p
                              key={idx}
                              className="relative px-1 pb-1 text-[12px] font-500 text-white">
                              {service.label}
                            </p>
                          ))}
                          className="tippy-dark"
                          placement="top">
                          <div className="flex items-center ">
                            <p className="relative top-[-4px] rounded-full bg-neutral-50 px-2 text-[12px] font-500 text-neutral-500">
                              +{service_ids.length - 4}
                            </p>
                          </div>
                        </Tippy>
                      )}
                    </>
                  ) : (
                    <p className="pb-2 text-[12px] font-500 text-neutral-500">N/A</p>
                  )}
                </div>
                <div className="flex items-center justify-end">
                  {ia(provider_ids) ? (
                    <>
                      {provider_ids.slice(0, 4).map((provider, idx) => (
                        <p
                          key={idx}
                          className="relative px-1 pb-1 text-[12px] font-500 text-neutral-500">
                          {provider.label}
                          <span className="absolute right-0 top-[5px] h-[10px] w-[1px] bg-neutral-200"></span>
                        </p>
                      ))}
                      {provider_ids.length > 4 && (
                        <Tippy
                          content={provider_ids.slice(4).map((provider, idx) => (
                            <p
                              key={idx}
                              className="relative px-1 pb-2 text-[12px] font-500 text-white">
                              {provider.label}
                            </p>
                          ))}
                          className="tippy-dark"
                          placement="top">
                          <div className="flex items-center ">
                            <p className="relative top-[-4px] rounded-full bg-neutral-50 px-2 text-[12px] font-500 text-neutral-500">
                              +{provider_ids.length - 4}
                            </p>
                          </div>
                        </Tippy>
                      )}
                    </>
                  ) : (
                    <p className="pb-2 text-[12px] font-500 text-neutral-500">N/A</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default PacketsList;
