import React from 'react';

import { mString, snakeToTitleCase, sumBy } from 'lib/helpers/utility';
import Allowed from 'components/shared/Permissions/Allowed';

const ReceiptTotal = ({ transaction, showDetails, transactions }) => {
  const {
    id,
    amount_paid_self,
    surcharge_amount,
    type,
    invoice_id,
    parent_id,
    appointment_id,
    amount_refunded
  } = transaction || {};

  const isParent = !parent_id && !invoice_id && !appointment_id;

  const isWriteoff = type === 'writeoff';

  const amountPaid = amount_paid_self - (surcharge_amount || 0) || 0;

  const amountPaidWithSurcharge = amountPaid + (surcharge_amount || 0);

  const previousPayments =
    sumBy(transactions, 'invoice.amount_paid') - (!isWriteoff ? amountPaid : 0);

  const hasInvoiceOrManyTransactions =
    !!transaction?.invoice || (transactions.length > 0 && transaction?.id !== transactions[0]?.id);

  const amountDue = transactions?.reduce((acc, t) => {
    if (!t.invoice) return acc;

    const { total_amount = 0, discountAmount = 0, adjustment = 0, tax = 0 } = t.invoice || {};

    return (
      acc +
      total_amount -
      discountAmount -
      adjustment +
      tax -
      (!isParent ? previousPayments : 0) +
      (isWriteoff ? amountPaid : 0)
    );
  }, 0);

  // TODO: Don't use sumBy, instead use one reduce for all the calculations

  const totalAdjustment = sumBy(transactions, 'invoice.adjustment');

  return (
    showDetails && (
      <Allowed requiredPermissions="payments.read">
        <div className="flex flex-col items-end">
          <div className="flex w-[305px] flex-col items-end justify-end !gap-3 rounded-[8px] bg-primary-600 !px-5 !py-2 shadow-[0px_3px_12px_0px_rgba(35,136,255,0.33)] print:bg-transparent print:shadow-[0px_1px_3px_0px_rgba(25,33,61,0.05),0px_2px_8px_0px_rgba(25,33,61,0.04)]">
            <div className="flex w-full flex-col items-start">
              <div className="flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                <p className="w-fit text-xs font-600 leading-[14px] text-white">Subtotal</p>
                <p className=" w-fit text-xs leading-[14px] text-white">
                  {mString(
                    !hasInvoiceOrManyTransactions
                      ? amountPaid + (amount_refunded || 0)
                      : sumBy(transactions, 'invoice.total_amount')
                  )}
                </p>
              </div>

              <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                <p className="text-xs font-600 leading-[14px] text-white">Technology Fee</p>

                <p className="text-xs leading-[14px] text-white">
                  {mString(surcharge_amount || 0)}
                </p>
              </div>

              {amount_refunded ? (
                <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                  <p className="text-xs font-600 leading-[14px] text-white">Refunded</p>

                  <p className="text-xs leading-[14px] text-white">
                    -{mString(amount_refunded || 0)}
                  </p>
                </div>
              ) : null}

              {hasInvoiceOrManyTransactions && (
                <>
                  <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                    <p className="text-xs font-600 leading-[14px] text-white">Discount</p>

                    <p className="text-xs leading-[14px] text-white">
                      {mString(-sumBy(transactions, 'invoice.discountAmount'))}
                    </p>
                  </div>

                  <div className="flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                    <p className="text-xs font-600 leading-[14px] text-white">Previous Payments</p>

                    <p className="text-xs leading-[14px] text-white">
                      {mString(previousPayments > 0 ? -previousPayments : 0)}
                    </p>
                  </div>

                  {totalAdjustment > 0 && !isWriteoff && (
                    <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                      <p className="text-xs font-600 leading-[14px] text-white">Adjustment</p>

                      <p className="text-xs leading-[14px] text-white">
                        {mString(-totalAdjustment)}
                      </p>
                    </div>
                  )}

                  <div className="mb-[-1.00px] flex w-full items-center justify-between border-b-[0.6px] border-primary-100 px-0 py-[8px] [border-bottom-style:solid]">
                    <p className="text-xs font-600 leading-[14px] text-white">Tax</p>

                    <p className="text-xs leading-[14px] text-white">
                      {mString(sumBy(transactions, 'invoice.tax'))}
                    </p>
                  </div>
                </>
              )}
            </div>

            <div className="flex w-full flex-col items-end justify-end !gap-[4px] !pb-2">
              <div className="flex w-full items-center  justify-between">
                <p className="text-xs font-600 leading-[14px] text-white">Current Amount Due</p>

                <p className="text-xs font-600 leading-[14px] text-white">
                  {mString(
                    hasInvoiceOrManyTransactions
                      ? amountDue + (surcharge_amount || 0)
                      : sumBy(transactions, 'invoice.balance') +
                          amountPaid +
                          (surcharge_amount || 0)
                  )}
                </p>
              </div>

              {id && (
                <>
                  <div className="flex w-full items-center  justify-between">
                    <p className="text-xs font-700 leading-[14px] text-white">Paid</p>

                    <p className=" text-[18px] leading-[22px] text-white">
                      {mString(amountPaidWithSurcharge)}
                    </p>
                  </div>

                  {hasInvoiceOrManyTransactions && (
                    <>
                      <div className="flex w-full items-center justify-between">
                        <p className="text-xs font-600 leading-[14px] text-white">
                          Remaining Balance
                        </p>

                        <p className="text-xs font-600 leading-[14px] text-white">
                          {mString(sumBy(transactions, 'invoice.balance'))}
                        </p>
                      </div>

                      {amountDue - amountPaid < 0 && (
                        <div className="mt-1 flex w-full items-center justify-between">
                          <p className="text-xs font-600 leading-[14px] text-white">
                            Unallocated amount
                          </p>

                          <p className="text-xs font-600 leading-[14px] text-white">
                            {mString((amountDue - amountPaid) * -1)}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </Allowed>
    )
  );
};

export default ReceiptTotal;
