import React from 'react';

import { formatPhoneNumber } from '../../../../lib/helpers/utility';

const ReceiptFooter = ({ practice }) => {
  const { name, phone, email, header_photo } = practice || {};

  return (
    <div className="flex justify-between !px-4 !mt-4">
      <div>
        <p className="text-xxs text-neutral-900 font-600">{name}</p>
        <p className="text-xxs text-neutral-600">{email}</p>
        <p className="text-xxs text-neutral-600">{formatPhoneNumber(phone)}</p>
      </div>

      {header_photo && (
        <img
          alt="Image"
          className="w-8 h-8 object-cover"
          src={`https://cdn.filestackcontent.com/${JSON.parse(header_photo).jpg}`}
        />
      )}
    </div>
  );
};

export default ReceiptFooter;
