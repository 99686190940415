import {
  getColDefs,
  getPatientDefaultFilters
} from 'components/practice/BeyondBilling/Claims/configs';
import NewPatientClaims from 'components/practice/BeyondBilling/Claims/NewPatientClaims';
import { currentPractice } from 'components/practice/practiceState';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
const getFinalColDefs = (name, timezone) => {
  let colDefs = [...getColDefs(name, timezone)];

  return colDefs;
};
const PatientClaims = ({ patient }) => {
  const name = 'patient_claims';
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const { type = 'all' } = useParams();

  let NEW_COL_DEFS = getFinalColDefs(name, practiceTimezone);
  if (type === 'all') {
    NEW_COL_DEFS = [
      ...NEW_COL_DEFS.slice(0, 7),
      { headerName: 'Status', field: 'state', valueFormatter: ({ value }) => value?.toUpperCase() },
      ...NEW_COL_DEFS.slice(7)
    ];
  }
  return (
    <TableContextProvider
      key={NEW_COL_DEFS}
      name={name}
      cols={NEW_COL_DEFS}
      pagination
      defaultFilters={getPatientDefaultFilters()}>
      <NewPatientClaims name={name} patient={patient} />
    </TableContextProvider>
  );
};

export default PatientClaims;
