import React from 'react';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { clinicalNote as clinicalNoteState } from 'components/state';

import { currentPractice } from '../../../../practiceState';
import { getTPTableCols } from '../lib/getTPTableCols';

import TPTable from './TPTable';

const TPList = ({ data, onClick, loadEPNToCN = false }) => {
  const practice = useRecoilValue(currentPractice);
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <>
      <TableContextProvider
        name="treatment_plans"
        cols={getTPTableCols({
          treatment_plan_id: clinicalNote?.treatment_plan_id,
          timezone: practice.timezone
        })}>
        <TPTable data={data} loadEPNToCN={loadEPNToCN} onClick={onClick} />
      </TableContextProvider>
    </>
  );
};

export default TPList;
