import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { activateAdminUser, approveAdminUser, deactivateAdminUser } from 'api/Admin/Users';

import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';

const AdminActions = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const onEdit = () => {
    props.onHandleEdit(props.paramsData);
  };

  const onDeactivate = () => {
    deactivateMutate.mutate();
  };

  const onApproveUser = () => {
    approveUserMutate.mutate();
  };

  const onActivateUser = () => {
    activateUserMutate.mutate();
  };

  const deactivateMutate = useMutation({
    mutationFn: () => {
      return deactivateAdminUser(navigate, {
        userIds: [props.paramsData.id]
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admin deactivated successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong deactivating admin',
          color: 'danger'
        });
      }
    }
  });

  const approveUserMutate = useMutation({
    mutationFn: () => {
      return approveAdminUser(navigate, {
        userIds: [props.paramsData.id]
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admin approved successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong approving admin',
          color: 'danger'
        });
      }
    }
  });

  const activateUserMutate = useMutation({
    mutationFn: () => {
      return activateAdminUser(navigate, {
        userIds: [props.paramsData.id]
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admin activated successfully', color: 'success' });
      } else {
        showAlert({
          title: data?.error ?? 'Something went wrong deactivating admins',
          color: 'danger'
        });
      }
    }
  });

  const actionOptions = [
    {
      label: 'Edit User',
      icon: 'new-user-edit',
      onClick: onEdit,
      permission: { requiredPermissions: ['admin_users.update'] }
    },
    ...(props.paramsData.status === 'pending'
      ? [
          {
            label: 'Approve',
            icon: 'tick-circle',
            onClick: onApproveUser,
            permission: { requiredPermissions: ['admin_users.approve'] }
          }
        ]
      : []),
    ...(props.paramsData.status === 'inactive'
      ? [
          {
            label: 'Activate',
            icon: 'tick-circle',
            onClick: onActivateUser,
            permission: { requiredPermissions: ['admin_users.activate'] }
          }
        ]
      : []),
    ...(props.paramsData.status !== 'inactive'
      ? [
          {
            label: 'Deactivate',
            icon: 'trash',
            onClick: onDeactivate,
            permission: { requiredPermissions: ['admin_users.delete'] }
          }
        ]
      : [])
  ];

  return <Popover isFixed options={actionOptions} />;
};

export default AdminActions;
