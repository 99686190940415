import React, { useCallback } from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import JsonView from 'react18-json-view';
import Button from 'components/shared/Buttons/Button';
import { promptOptionsDefaults, promptOptionsExplainer } from './helpers';
import { defaultsDeep } from 'lodash';

const AdminIntelligencePromptForm = ({ onChange, formik, value }) => {
  const addTag = useCallback(
    (e) => {
      onChange({ name: 'body', value: `${formik?.values?.body} ${e}` });
    },
    [formik, value]
  );

  return (
    <div className="flex w-full flex-col gap-4 p-4">
      <div className="font-600 text-primary-600">Prompt Body</div>
      <div className="flex flex-col gap-2">
        <div className="font-500">Add Tags to Prompt Body</div>
        <div className="flex flex-row flex-wrap gap-2">
          <Button text="User Context" onClick={() => addTag('{{userContext}}')} />
          <Button text="Body" onClick={() => addTag('{{body}}')} />
          <Button text="Form Name" onClick={() => addTag('{{formName}}')} />
          <Button text="Form Type" onClick={() => addTag('{{formType}}')} />
        </div>
      </div>
      <textarea
        className="h-full min-h-40 min-w-40 resize-none rounded-2xl border-neutral-200 p-2"
        // label="Prompt Template Text"
        placeholder="Please write a nice note"
        id="body"
        name="body"
        onChange={(e) => {
          onChange({ name: 'body', value: e.target.value });
        }}
        defaultValue={value?.body}
        value={formik?.values?.body}
      />
      <div className="font-600 text-primary-600">Model Parameters</div>
      <div className="font-500">Parameters</div>
      <JsonView
        name="options"
        editable={true}
        onChange={(v) => {
          console.log(v);
          onChange({ name: 'options', value: v?.src });
        }}
        className="json-tree-view rounded-2xl border-[1px] border-solid border-neutral-200 bg-white p-2"
        src={defaultsDeep(formik?.values?.options, promptOptionsDefaults)}
        collapsed={1}
      />
      <div className="font-500">Parameters Explanation</div>
      <JsonView
        className="json-tree-view max-w-[50vw] rounded-2xl border-[1px] border-solid border-neutral-200 bg-white p-2"
        src={promptOptionsExplainer}
        editable={false}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligencePromptForm);
