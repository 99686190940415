import { requestApi } from './Api';

export const getLabOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/get',
    params
  });

export const upsertLabOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/upsert',
    params,
  });

export const deleteLabOrder = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/lab_order/delete',
    params,
  });

