import React, { useEffect, useState } from 'react';
import papaparse from 'papaparse';

const CSVviewer = ({ CSVFile }) => {
  const [headers, setHeaders] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    let CSVData = papaparse.parse(CSVFile, { header: false, skipEmptyLines: true, quoteChar: '`' });
    let localHeaders = CSVData.data.shift();
    let localData = CSVData.data;
    setHeaders(localHeaders);
    setData(localData);
  }, []);

  return (
    <div className="bg-white !p-2">
      <div className="overflow-auto font-mono">
        <table>
          <thead>
            <tr>
              {headers.map((v, i) => (
                <td key={`csv_viewer-header-${v}-index-${i}`}>{v}</td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((v, i) => (
              <tr>
                {headers.map((w, j) => (
                  <td key={`csv_viewer-data-row-index-${i}-column-index-${j}`}>{v[j]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CSVviewer;
