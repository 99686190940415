import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import Select from 'components/shared/Select/Select';
import { currentPractice } from 'components/practice/practiceState';

const Practitioner = ({ practitioners, className }) => {
  const { values, errors, setFieldValue } = useFormikContext();
  const currPractice = useRecoilValue(currentPractice);
  const patientLink = values?.patient?.practicePatientLink?.virtual_link;
  const practiceLink = currPractice?.display_settings?.appointment?.virtual_link;
  const isAptDisabled = values.appointment_disabled;

  return (
    <Select
      label="Practitioner"
      parentClassName={className}
      required
      placeholder="Type and select a practitioner"
      value={practitioners.find((practitioner) => practitioner?.value === values?.practitioner_id)}
      onChange={(value) => {
        setFieldValue('practitioner_id', value?.value);
        setFieldValue('virtual_link', patientLink || value?.virtual_link || practiceLink);
      }}
      options={practitioners}
      inputId="practitionerSelect"
      error={errors.practitioner_id}
      isImage
      disabled={isAptDisabled}
    />
  );
};

export default Practitioner;
