import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { requestApi } from '../../../../api/Api';
import { responseUpdated } from '../../../../lib/helpers/utility';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import _ from 'lodash';

export default function CustomForms() {
  const { updateSteps, checkinButton, cid, responses, setResponses } = useOutletContext();
  const [formIndex, setFormIndex] = useState(null);
  const [form, setForm] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  console.log({ responses });

  useEffect(() => {
    getForm();
  }, [id]);

  const getForm = async () => {
    setForm(null);
    const onSuccess = (partData) => {
      const lForm = partData?.form;
      if (lForm?.response)
        setForm(_.set(lForm, 'response.json.fields', JSON.parse(lForm?.response?.json?.fields)));
      else setForm(lForm);
    };
    const onError = () => {
      //   something
    };

    await requestApi({
      url: '/api/form/instant_packet/form/get',
      params: { stepId: id, appointmentId: cid },
      navigate,
      onSuccess,
      onError
    });
  };

  const handleSubmit = async (e) => {
    try {
      let params = {
        practiceId: form.practice_id,
        responseId: form?.response?.id,
        formId: form.id,
        json: { fields: JSON.stringify(e) },
        patientId: form?.response?.user_id,
        formVersion: form.version,
        appointmentId: cid
      };

      let res = {};

      if (responseUpdated({ form, submitResponse: e }))
        res = await requestApi({
          url: cid ? '/api/form/upsert_response' : '/api/form/instant_upsert_response',
          params,
          navigate
        });

      setResponses({ ...responses, [form.id]: e });

      updateSteps({ version: 2, formId: form?.id, newStatus: 'completed' });

      // res?.upsert
      //   ? updateSteps({ formName: form.name, isCompleted: true })
      //   : updateSteps({
      //       formName: form.name,
      //       isCompleted: form?.response ? true : false
      //     });
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  if (!form) return <Skeleton />;

  console.log({ form });

  return (
    <div className="!pb-24 xs:!p-0">
      <div className="!p-4">
        <div className="box">
          <ReactFormGenerator
            answer_data={form?.response?.json?.fields || responses[form?.id] || null}
            data={form?.json.fields || []}
            onSubmit={(e) => handleSubmit(e)}
            submitButton={
              <button ref={checkinButton} hidden type="submit" className={` button`}>
                Submit
              </button>
            }
          />
        </div>
      </div>
    </div>
  );
}
