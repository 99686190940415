import cs from 'classnames';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import useScrollBlock from '../../../../lib/hooks/useScrollBlock';
import Icon from '../../../shared/Icon/Icon';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import EditInsuranceProfile from './EditInsuranceProfile';
import InsuranceProfile from './InsuranceProfile';
import InsuranceProfileMobile from './InsuranceProfileMobile';
import NewInsuranceProfile from './NewInsuranceProfile';
import { useQueryClient } from '@tanstack/react-query';

export default function InsuranceProfiles({
  insuranceProfiles,
  patientId,
  selected,
  eligibility,
  eligibilityPresent,
  setShowEligibilityResponse,
  setSelected,
  practiceId,
  noInsurancePress,
  kiosk,
  fakeModal,
  setFakeModal
}) {
  const [insuranceProfileList, setInsuranceProfileList] = useState(insuranceProfiles);
  const [blockScroll, allowScroll] = useScrollBlock();
  const [showNewInsuranceProfile, setShowNewInsuranceProfile] = useState(false);
  const [showEditInsuranceProfile, setShowEditInsuranceProfile] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const initialIsOpenState = insuranceProfiles?.reduce((acc, curr) => {
    acc[curr.id] = true;
    return acc;
  }, {});
  const [isOpen, setIsOpen] = useState(initialIsOpenState);
  const { device } = useUIContext();
  const { pathname } = useLocation();
  const fromCheckin = pathname.includes('/checkin');
  const queryClient = useQueryClient();

  //new insurance profile modal
  const handleNewInsuranceProfile = () => {
    !kiosk && blockScroll();
    !kiosk && setShowNewInsuranceProfile(true);
    kiosk && setFakeModal({ type: 'new' });
  };
  const hideNewInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowNewInsuranceProfile(false);
    kiosk && setFakeModal(null);
  };

  const handleEditInsuranceProfile = (data, index) => {
    !kiosk && blockScroll();
    !kiosk && setShowEditInsuranceProfile({ data, index });
    kiosk && setFakeModal({ type: 'edit', data, index });
  };
  const hideEditInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowEditInsuranceProfile(null);
    kiosk && setFakeModal(null);
  };

  useEffect(() => {
    setInsuranceProfileList(insuranceProfiles);
  }, [insuranceProfiles]);

  const newProfile = (newProfile) => {
    const insuranceProfile = [...insuranceProfileList, newProfile];
    handleModifyInsurances(insuranceProfile);
  };

  const updateProfile = (updatedProfile, index) => {
    const profilesCopy = [...insuranceProfileList];
    profilesCopy[index] = updatedProfile;
    handleModifyInsurances(profilesCopy);
  };

  const deleteProfile = (insuranceProfile) => {
    const filteredInsuranceProfile = insuranceProfileList.filter(
      (i) => i.id !== insuranceProfile.id
    );
    handleModifyInsurances(filteredInsuranceProfile);
  };

  const handleModifyInsurances = (insuranceProfile) => {
    console.log(patientId);
    queryClient.setQueryData(['patient', patientId], (prevData) => ({
      ...prevData,
      patient: {
        ...prevData.patient,
        insuranceProfile
      }
    }));
  };

  const toggleOne = (key) => {
    setIsOpen({ ...isOpen, [key]: isOpen && !isOpen[key] });
  };

  if (!Array.isArray(insuranceProfileList)) {
    return (
      <div className="mt-3 w-full">
        <Skeleton count={8} />
      </div>
    );
  }

  // console.log(showEditInsuranceProfile, fakeModal);

  return (
    <div className="h-full" data-dd-privacy="allow">
      {!fakeModal && (
        <>
          <div className="flex items-center justify-between">
            {insuranceProfileList?.length > 0 ||
              (!fromCheckin && (
                <p className="mb-3 text-lg font-600 leading-10 text-neutral-800">
                  My insurance {insuranceProfileList?.length > 1 ? 'profiles' : 'profile'}
                </p>
              ))}
            <div className="flex items-center ">
              {eligibilityPresent && (
                <Button
                  transparent
                  type="primary"
                  color="primary"
                  text="Eligibility response"
                  onClick={() => setShowEligibilityResponse(true)}
                  size="small"
                  className="!p-0"
                />
              )}
              {eligibility}
            </div>
          </div>
          <div
            className={cs(
              ' gap-4 !pb-8 ',
              fromCheckin ? 'flex flex-col' : 'grid grid-cols-2 xs:flex xs:flex-col'
            )}>
            {insuranceProfileList?.length === 0 && !fromCheckin ? (
              <div
                data-qa="practice-charts-insuranceProfile-NoInsuranceButton"
                onClick={noInsurancePress}
                className="flex min-h-[350px] flex-col items-center justify-center !rounded-lg border-2 !border-primary-100 bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)] sm:min-h-[210px] xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4">
                <Icon
                  icon={
                    device === 'mobile' ? 'new-frame-heart-search-sm' : 'new-frame-heart-search'
                  }
                  className="animate-pulse"
                />
                <p className="!pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm">
                  I don't have insurance profile
                </p>
              </div>
            ) : (
              insuranceProfiles?.length === 0 && (
                <div className="flex flex-col items-center justify-center px-6 py-12">
                  <Icon icon="new-no-data" />
                  <p className="mt-3 text-base font-500 text-primary-900">
                    No Insurance Information
                  </p>
                  <p className="text-sm font-400 text-neutral-700">
                    Add insurance information by clicking the button below.
                  </p>
                  <div
                    onClick={handleNewInsuranceProfile}
                    className="mt-5 flex cursor-pointer gap-2">
                    <Icon icon="new-add-square" className="cursor-pointer" color="primary" />
                    <p className="text-center text-sm !text-primary-500">
                      {' '}
                      Add Insurance Information
                    </p>
                  </div>
                </div>
              )
            )}

            {insuranceProfileList?.map((insuranceProfile, index) => {
              return device === 'mobile' || fromCheckin ? (
                <InsuranceProfileMobile
                  key={index}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                  isOpen={isOpen}
                  kiosk={kiosk}
                  toggleOne={toggleOne}
                />
              ) : (
                <InsuranceProfile
                  key={index}
                  fakeModal={fakeModal}
                  setFakeModal={setFakeModal}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  selected={selected}
                  kiosk={kiosk}
                  setSelected={setSelected}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                />
              );
            })}

            {insuranceProfileList?.length < 5 && (
              <div
                data-qa="practice-charts-insuranceProfile-NewInsuranceButton"
                onClick={handleNewInsuranceProfile}
                className={cs(
                  'group col-end-3 flex min-h-[350px] cursor-pointer flex-col items-center justify-center !rounded-lg border-2 !border-dotted border-neutral-400 py-6 shadow-[0px_4px_16px_rgba(0,0,0,0.04)] hover:!border-primary-100 hover:bg-primary-50 sm:min-h-[210px] md:col-end-2 lg:py-0 xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4',
                  // { 'order-2': device === 'tablet' },
                  fromCheckin && '!h-16 !min-h-0 !flex-row items-center !justify-start gap-2 px-4'
                )}
                style={{
                  gridRowEnd:
                    insuranceProfileList.length >= 3
                      ? insuranceProfileList.length
                      : insuranceProfileList.length + 1
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}>
                <div
                  className={cs(
                    fromCheckin && '!h-[32px] !w-[32px] !rounded-xl !bg-[#D9F4FF]',
                    'flex h-[54px] w-[54px] items-center justify-center rounded-2xl bg-neutral-100 group-hover:bg-[#9EE2FF] xs:h-[32px] xs:w-[32px] xs:!rounded-lg'
                  )}>
                  <Icon
                    icon="new-plus"
                    color={isHover || fromCheckin ? 'primary' : 'neutral'}
                    className="cursor-pointer"
                  />
                </div>
                <p
                  className={cs(
                    'pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm',
                    fromCheckin && '!pt-0'
                  )}>
                  Create new insurance profile
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {(showNewInsuranceProfile || fakeModal?.type === 'new') && (
        <NewInsuranceProfile
          fakeModal={fakeModal}
          newProfile={newProfile}
          patientId={patientId}
          kiosk={kiosk}
          showNewInsuranceProfile={showNewInsuranceProfile}
          hideNewInsuranceProfile={hideNewInsuranceProfile}
          practiceId={practiceId}
          insuranceProfiles={insuranceProfileList}
        />
      )}

      {(!!showEditInsuranceProfile || fakeModal?.type === 'edit') && (
        <EditInsuranceProfile
          updateProfile={updateProfile}
          index={showEditInsuranceProfile ? showEditInsuranceProfile?.index : fakeModal?.index}
          kiosk={kiosk}
          insuranceProfile={
            showEditInsuranceProfile ? showEditInsuranceProfile?.data : fakeModal?.data
          }
          patientId={patientId}
          showEditInsuranceProfile={showEditInsuranceProfile}
          hideEditInsuranceProfile={hideEditInsuranceProfile}
          fakeModal={fakeModal}
          setFakeModal={setFakeModal}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
}
