import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { interimApi } from '../../../api/InterimApi';
import Error from '../../shared/Error/Error';
import Address from '../../shared/address/Address';

const validationSchema = Yup.object().shape({
  address_ln_1: Yup.string().required('Address is required'),
  address_ln_2: Yup.string(),
  city: Yup.string().required('City required'),
  state: Yup.number().required('State required'),
  zip: Yup.string().required('Zip code required')
});

const COFAddress = ({ card, onSuccess = () => {}, onFail = () => {}, addressRef }) => {
  const formRef = useRef();

  const formik = useFormik({
    initialValues: {
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: '',
      zip: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const address = {
        address_ln_1: values.address_ln_1,
        address_ln_2: values.address_ln_2,
        city: values.city,
        state: values.state,
        zip: values.zip
      };

      setSubmitting(true);
      await saveNewBillingAddress(address);
      setSubmitting(false);
    }
  });
  const [error, setError] = useState({ errors: null, title: '' });
  const navigate = useNavigate();

  const saveNewBillingAddress = async (address) => {
    try {
      if (!card?.id) {
        setError({ errors: error || 'No card id present.', title: 'Billing Address Save Error' });
        return;
      }
      const res = await interimApi(
        '/api/transactions/token/addAddress',
        {
          cardId: card?.id,
          address: address
        },
        navigate
      );
      const { code, redirect, error, addressId } = res.data;
      switch (code) {
        case -1:
          history.push(redirect);
          break;
        case 0:
          if (addressId) onSuccess(addressId);
          else {
            setError({ errors: error || 'Unknown error', title: 'Billing Address Save Error' });
            onFail();
          }
          break;
        default:
          setError({ errors: error || 'Unknown error', title: 'Billing Address Save Error' });
          console.error(error || 'Unexpected error has occurred');
          onFail();
          break;
      }
    } catch (error) {
      setError({ errors: 'Unknown error', title: 'Billing Address Save Error' });
      console.error(error);
      onFail();
    }
  };

  React.useImperativeHandle(addressRef, () => ({
    handleSubmit: formik.submitForm
  }));

  return (
    <div>
      <Error {...error} />

      <p className="!mb-4">Please add a billing address before proceeding to use this card.</p>

      <form ref={formRef}>
        <Address formik={formik} label="" />
      </form>
    </div>
  );
};

export default withErrorBoundary(COFAddress);
