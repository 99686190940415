import React from 'react';
import Button from '../../Buttons/Button';
import SendAsTextPopover from '../Popover.jsx/SendAsTextPopover';

export default function MessageTextBox({
  onChange,
  onClick,
  body,
  sendAsText = false,
  loading = false
}) {
  const onKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // setBody(body + `\n`); // <- this wasn't necessary since apparently that's the default
        //   shift enter behavior
        return;
      } else {
        onClick(false);
      }
    }
  };

  return (
    <div className="relative mt-4 rounded-lg">
      <textarea
        name="text-area"
        id="text-area"
        rows="5"
        value={body}
        onChange={onChange}
        placeholder="Please enter your message here"
        className="h-[130px] w-full resize-none rounded-lg border-[1px] border-neutral-300 !p-5 placeholder:text-neutral-600 focus:border-neutral-600 focus:outline-none"></textarea>
      <div className="absolute bottom-5 right-5 flex !gap-1">
        <Button
          size="small"
          onClick={() => onClick(false)}
          loading={loading?.message_sent}
          disabled={loading?.message_sent}
          onKeyDownHandler={onKeyDownHandler}
          className="h-8 w-14 bg-primary-500 !px-2 !py-2"
          text="Send"
        />
        {sendAsText && <SendAsTextPopover loading={loading} callback={() => onClick(true)} />}
      </div>
    </div>
  );
}
