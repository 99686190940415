import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { ia, iaRa } from 'lib/helpers/utility';
import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';

import returnAppliedFilter from 'components/shared/Filter/lib/helpers/handleAppliedFilter';

import { usePreferences } from '../../hooks/queries/usePreferences';

import { TableContext } from './TableContext';
import { areArraysEqualUnordered, formatValue, updatePreferences } from './helper';

export const TableContextProvider = ({
  cols,
  name,
  pagination,
  children,
  defaultFilters,
  dependencies,
  onSelect = () => {}
}) => {
  const [colDefs, setColDefs] = useState(cols);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [updatedPreferences, setUpdatedPreferences] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState();
  const [gridApi, setGridApi] = useState(null);
  const [errors, setErrors] = useState({});
  const [forceRefresh, setForceRefresh] = useState(false);

  const gridRef = useRef();
  const queryClient = useQueryClient();

  const { data: savedFilters = {}, isFetched } = useDefaultFilters({});

  const navigate = useNavigate();

  useEffect(() => {
    if (ia(cols) && ia(dependencies)) {
      setColDefs(cols);
    }
  }, dependencies);

  const { data, isFetched: isLimitFetched } = usePreferences({
    params: { name },
    dependencies: [name],
    options: {
      enabled: name?.length > 0
    }
  });
  const [limit, setLimit] = useState(data?.preferences?.value?.limit || 25);
  const [filters, setFilters] = useState(returnAppliedFilter(defaultFilters, savedFilters[name]));

  useEffect(() => {
    if (isFetched && isLimitFetched) {
      setFilters(returnAppliedFilter(filters, savedFilters[name]));
      setLimit(data?.preferences?.value?.limit || 25);
    }
  }, [isFetched, isLimitFetched]);

  useEffect(() => {
    if (!updatedPreferences) {
      if (!data) {
        setColDefs(cols);
        return;
      } else {
        const preferenceColumns = data?.preferences?.value?.columnOrder;
        if (ia(preferenceColumns)) {
          const areEqual = areArraysEqualUnordered(
            formatValue(cols)?.columnOrder,
            formatValue(preferenceColumns)?.columnOrder
          );

          if (!areEqual) {
            setColDefs(cols);
            updatePreferences({ navigate, cols, limit, queryClient, name });
            setUpdatedPreferences(true);
            return;
          } else {
            const columns = data?.preferences?.value?.columnOrder.map((col) => {
              return {
                ...iaRa(cols)?.find((c) => c?.field === col?.field),
                hide: col.hide,
                pinned: col.pinned,
                rowGroup: col.rowGroup
              };
            });
            setColDefs(columns);
          }
          setUpdatedPreferences(true);
        }
      }
    }
  }, [data?.preferences?.value?.columnOrder]);

  const onSortChanged = () => {
    if (!gridApi) return;
    const currentSort = gridApi.getColumnState().filter((col) => {
      return col.sort === 'asc' || col.sort === 'desc';
    });
    setColDefs(gridApi.getColumnDefs());

    setSort(currentSort.map((col) => ({ field: col.colId, sort: col.sort }))[0]);
  };

  const onColumnMoved = async () => {
    if (!gridApi) return;

    const currentColumns = gridApi.getColumnDefs();

    setColDefs(currentColumns);
    await updatePreferences({ navigate, cols: currentColumns, limit, queryClient, name });
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;
    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedRows(selectedNodes);
    onSelect(selectedNodes.length === 0);
  }, [gridApi, setSelectedRows]);

  return (
    <TableContext.Provider
      value={{
        limit,
        setLimit,
        colDefs,
        gridApi,
        onGridReady,
        onColumnMoved,
        setColDefs,
        onSortChanged,
        sort,
        page,
        setPage,
        name,
        formatValue,
        tableLoading,
        setTableLoading,
        onSelectionChanged,
        selectedRows,
        setSelectedRows,
        pagination,
        filters,
        setFilters,
        gridRef,
        defaultFilters,
        errors,
        setErrors,
        forceRefresh,
        setForceRefresh
      }}>
      {children}
    </TableContext.Provider>
  );
};
