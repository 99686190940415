import React, { useRef, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { usePractice } from 'lib/hooks/queries/practice/usePractice';

import PrintViewHeader from 'components/Patient/quote/components/PrintViewHeader';
import { currentPractice } from 'components/practice/practiceState';

import { requestApi } from '../../../../../../api/Api';
import { showAlert } from '../../../../../shared/Alert/Alert';
import Button from '../../../../../shared/Buttons/Button';
import Confirm from '../../../../../shared/Modal/Confirm/Confirm';
import CPTCodes from '../../PreviewNote/Rendered/components/CPTCodes';
import AdditionalComments from '../components/preview/AdditionalComments';
import Diagnosis from '../components/preview/Diagnosis';
import Goals from '../components/preview/Goals';
import PatientNeeds from '../components/preview/PatientNeeds';
import ProblemManifestationDefinition from '../components/preview/ProblemManifestationDefinition';
import TreatmentFrequency from '../components/preview/TreatmentFrequency';
import TreatmentPlanSignatures from '../components/preview/TreatmentPlanSignatures';
import TreatmentTeam from '../components/preview/TreatmentTeam';

const TPNarrative = ({ id, patientSide = false }) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();
  const { patient } = useOutletContext() || {};
  const practice = useRecoilValue(currentPractice);
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  const location = useLocation();
  const fromPracticeIQueue = location?.pathname?.includes('practice-i-queue/treatment-plans');
  const { data: tpPatient } = usePatient({
    params: { id: selectedTreatmentPlan?.patient_id },
    dependencies: [selectedTreatmentPlan?.patient_id],
    options: {
      enabled: fromPracticeIQueue || patientSide,
      select: (data) => data?.patient
    }
  });

  const treatmentPlanCpts = {
    clinicalNote: selectedTreatmentPlan
  };
  const { data: tpPractice } = usePractice({ practice_id: selectedTreatmentPlan?.practice_id });

  const sendEmailToPatient = async () => {
    setLoading(true);
    const onSuccess = () => {
      showAlert({ title: 'Treatment Plan', message: 'Email sent successfully!' });
      setLoading(false);
      setShowConfirmation(false);
    };
    requestApi({
      url: '/api/clinical_note/treatment_plan/email_patient',
      navigate,
      params: { treatmentId: id },
      onSuccess
    });
  };

  return (
    <div>
      <div className="flex items-center justify-end !gap-3">
        <Tippy content="Print as PDF" placement="bottom" className="tippy-dark">
          <div>
            <ReactToPrint
              documentTitle="treatment-plan"
              bodyClass="!p-6 bg-white"
              content={() => ref.current}
              trigger={() => (
                <Button type="secondary" icon="new-printer-outline" color="neutral" iconIsStroke />
              )}
            />
          </div>
        </Tippy>
        {!patientSide && (
          <Tippy content="Send email to patient" placement="bottom" className="tippy-dark">
            <div>
              <Button
                type="secondary"
                icon="new-mail-outline"
                color="neutral"
                iconIsStroke
                onClick={() => setShowConfirmation(true)}
              />
            </div>
          </Tippy>
        )}
      </div>
      <div ref={ref} className="grid gap-6">
        <PrintViewHeader
          patient={patient || tpPatient}
          practice={patientSide ? tpPractice?.practices : practice}
        />
        <Diagnosis />
        <CPTCodes customClinicalNote={treatmentPlanCpts} customStyling="!px-0 !bg-transparent" />
        <PatientNeeds />
        <ProblemManifestationDefinition />
        <Goals />
        <TreatmentTeam />
        <AdditionalComments />
        <TreatmentFrequency />
        <TreatmentPlanSignatures />
      </div>
      {showConfirmation && (
        <Confirm
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          handleContinue={sendEmailToPatient}
          title="Send email to patient"
          message="Are you sure you want to send email to patient?"
          primaryBtnTxt="Send email to patient"
          secondaryBtnTxt="Close"
          icon="new-mail"
          loading={loading}
        />
      )}
    </div>
  );
};

export default TPNarrative;
