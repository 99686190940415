import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getFilters } from '../../../api/Filter';

export const useFilters = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['filters', ...dependencies], () => getFilters(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
