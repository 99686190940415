import React, { useRef } from 'react';
import cs from 'classnames';
import Hover from './Hover';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import moment from 'moment-timezone';

const Appointment = ({ item, index }) => {
  const ref = useRef();
  const { setCurrentModal } = useDashboardContext();
  const practice = useRecoilValue(currentPractice);

  const handleShowModal = () => {
    setCurrentModal({ show: true, id: item?.id, type: item?.eventType });
  };

  return (
    <>
      <div
        ref={ref}
        data-qa={`appointment-${index}`}
        id="appointment-item"
        className="relative flex grow cursor-pointer select-none items-center gap-2 rounded-md !p-3 transition ease-in hover:bg-primary-50"
        onClick={handleShowModal}>
        <span
          className="z-10 h-[5px] w-[5px] rounded-full"
          style={{
            backgroundColor:
              item?.appointment?.status === 'cancelled'
                ? '#C1CACE'
                : item?.appointment?.practitioner?.practice?.color || item?.color || '#004F6B'
          }}
        />
        <span
          className={cs(
            'relative w-[64px] whitespace-nowrap text-sm font-500 leading-[22px]',
            item?.appointment?.status === 'cancelled'
              ? 'text-neutral-500 line-through'
              : 'text-primary-900'
          )}>
          {moment.tz(item?.starts_at, practice?.timezone).format('h:mm A')}
        </span>
        <span
          className={cs(
            'text-sm font-500 leading-[22px]',
            item?.appointment?.status === 'cancelled'
              ? 'text-neutral-500 line-through'
              : 'text-primary-900'
          )}>
          {item?.event_title ?? item?.appointment.patient?.fullName}
        </span>
      </div>
      <Hover item={item} appointmentRef={ref} index={index} />
    </>
  );
};
export default Appointment;
