import cs from 'classnames';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Icon from 'components/shared/Icon/Icon';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { interimApi } from '../../../api/InterimApi';
import { formatPhoneNumber, ia, io } from '../../../lib/helpers/utility';
import '../style.scss';

const SearchPatients = ({
  handleClick,
  onChange,
  isClearable = true,
  passedCustomStyling,
  className,
  label,
  required,
  error,
  isImage,
  rightButton,
  preselectedPatient,
  width,
  exclude = [],
  submitAppointment,
  setSelectedAppointmentType,
  setSelectedService,
  ...rest
}) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleChange = (value) => {
    setSelectedPatient(value);
    handleClick && handleClick(value);
    setShowMenu(false);
  };

  const handleInputChange = (value) => {
    onChange && onChange(value);
    setInputValue(value);
    if (inputValue === '') {
      setShowMenu(false);
    }
  };

  const searchPatients = async (searchTerm, callback) => {
    if (!searchTerm) {
      setShowMenu(false);
      callback([]);
      setInputValue(null);
    } else {
      setShowMenu(true);
      setInputValue(searchTerm);

      const params = {
        limit: 20,
        offset: 0,
        searchTerm,
        type: 'patients'
      };

      try {
        const { data } = await interimApi('/api/practice/charts/get', params, navigate);

        const { patients: loadedPatients } = data;

        if (data.patients) {
          const patientArray = [];

          ia(loadedPatients) &&
            loadedPatients?.forEach((element) => {
              if (!exclude.includes(element?.id)) {
                patientArray.push({
                  label: element?.fullName,
                  value: element?.id,
                  id: element?.id,
                  dob: element?.dob,
                  phone: element?.phone,
                  gender: element?.gender,
                  profilePhoto: element?.profile_photo,
                  email: element?.email,
                  f_name: element?.f_name,
                  l_name: element?.l_name,
                  virtual_link: element?.practicePatientLink?.virtual_link
                });
              }
            });
          callback(patientArray);
        }
      } catch (err) {
        console.error('error', err);
      }
    }
  };

  const handlePatientClick = (option) => {
    formik.setFieldValue('patientId', option.value);
    navigate(`/portal/charts/${option.value}`);
  };

  const handleOptionClick = (option) => {
    setSelectedPatient(option);
    setInputValue(option.label);
    setShowMenu(false);
    handleClick
      ? handleClick({
          ...option,
          value: option?.value,
          type: option?.email ? 'patient' : 'payer',
          virtual_link: option?.virtual_link
        })
      : handlePatientClick(option);
  };

  const formatOptionLabel = (option) => {
    const {
      value,
      label,
      data: { profilePhoto, dob, gender, email, f_name, l_name, virtual_link }
    } = option;

    return (
      <div
        className="!mr-2 !mt-2 flex cursor-pointer items-start gap-[14px] rounded-xl bg-neutral-50 !p-2 first:!mt-0 hover:bg-primary-50"
        onClick={() =>
          handleOptionClick({
            value,
            label,
            email,
            dob,
            gender,
            l_name,
            f_name,
            id: value,
            profilePhoto,
            fullName: label,
            virtual_link
          })
        }>
        <ProfilePicture
          size={56}
          id={option.value}
          lastName={l_name}
          firstName={f_name}
          image={option.data.profilePhoto}
        />
        <div className="grid items-center gap-[2px]">
          {option?.label && (
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
              {option.label}
            </p>
          )}
          {option?.data?.phone && (
            <p className="text-xs text-neutral-800">{formatPhoneNumber(option?.data?.phone)}</p>
          )}
          {option?.data?.dob && (
            <p className="text-xs text-neutral-800">
              {moment.utc(option?.data?.dob).format('MMM DD, YYYY')}
            </p>
          )}
        </div>
      </div>
    );
  };

  const formik = useFormik({
    initialValues: {
      patientId: '',
      practitionerId: '',
      practiceNote: '',
      appointmentType: '',
      serviceId: '',
      startsAt: new Date(),
      appointmentLength: ''
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      await submitAppointment(values);
      setSubmitting(false);
      resetForm();
      setSelectedAppointmentType(null);
      setSelectedService(null);
      setInputValue(null);
      setSelectedPatient(null);
    }
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: width || 'auto',
      maxHeight: 320,
      minHeight: 42,
      border: 'unset',
      borderRadius: 16,
      padding: '6px 8px 6px 8px',
      boxShadow: '0px 0px 24px rgba(0, 79, 107, 0.16)',
      overflow: 'auto',
      zIndex: 999999999999
    }),
    container: (provided) => ({
      ...provided,
      width: width || '100%'
    }),

    control: (_, { selectProps: {} }) => ({
      width: width || 304,
      height: 38,
      display: 'flex',
      padding: '2px 2px 2px 30px',
      fontSize: 14,
      fontWeight: 50
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const fontWeight = 500;
      const margin = 0;

      return { ...provided, opacity, transition, fontWeight, margin };
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#6D828B'
      };
    },
    input: (provided) => ({
      ...provided,
      color: '#1B2123'
    })
  };

  return (
    <div className="flex flex-col">
      {(label || rightButton) && (
        <div className="!mb-[6px] flex justify-between">
          {label && (
            <label className="m-0 flex !pr-4 text-sm font-500 text-neutral-800">
              {label}
              {required && <span className="text-danger-500">*</span>}
            </label>
          )}
          {rightButton && rightButton}
        </div>
      )}

      <div
        data-qa="search-patient"
        className={cs(
          className,
          'SearchPatients',
          inputValue && 'border-primary-500 !bg-primary-50'
        )}>
        <div className="flex items-center ">
          {isImage && (io(selectedPatient) || io(preselectedPatient)) ? (
            <ProfilePicture
              size={24}
              fontSize="text-xs"
              className="absolute left-2"
              id={selectedPatient?.value || preselectedPatient?.value}
              firstName={selectedPatient?.f_name || preselectedPatient?.f_name}
              lastName={selectedPatient?.l_name || preselectedPatient?.l_name}
              image={selectedPatient?.profilePhoto || preselectedPatient?.profilePhoto}
            />
          ) : (
            <Icon icon={inputValue ? 'new-search-blue' : 'new-search'} className="!left-4" />
          )}
        </div>

        <AsyncSelect
          menuIsOpen={showMenu}
          inputValue={inputValue}
          onChange={handleChange}
          isClearable={isClearable}
          loadOptions={searchPatients}
          onInputChange={handleInputChange}
          components={{
            Option: formatOptionLabel,
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null
          }}
          inputId="searchPatients"
          placeholder="Search patients"
          noOptionsMessage={() => null}
          classNamePrefix="SearchPatients"
          className="SearchPatients__wrapper max-w-full"
          value={selectedPatient || preselectedPatient}
          styles={{ ...customStyles, ...passedCustomStyling }}
          {...rest}
        />
      </div>

      {error && <p className={cs('!pt-2 text-sm text-danger-500')}>{error}</p>}
    </div>
  );
};

export default SearchPatients;
