import React from 'react';
import Header from 'components/shared/Header/Header';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const RWTPlan = () => {
  const plans = [
    {
      title: '2025 RWT Plan',
      link: 'https://myriad-public.s3.us-east-1.amazonaws.com/Real+World+Test+Plan+for+2025+(2).pdf'
    }
  ];
  return (
    <div>
      <Header title="RWT Plans"></Header>

      <div className="p-4">
        {plans.map((plan, index) => (
          <div key={index} className="row">
            <div className="col-md-12">
              <a href={plan.link} target="_blank" rel="noreferrer">
                {plan.title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(RWTPlan);
