import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

const useComponentAdd = () => {
  const { componentToAdd, inputTags, selectedTableRows } = useChatContextData();
  const { setComponentToAdd, setInputTags, setSelectedTableRows } = useChatContextApi();

  const onTemplateClick = (component) => {
    const isComponentAdded = inputTags?.find((comp) => comp.id === component?.id);
    if (!isComponentAdded) {
      setComponentToAdd(component);
    }
  };

  const onAddComponent = () => {
    if (componentToAdd && !inputTags.some((tag) => tag.id === componentToAdd?.id)) {
      setInputTags((prev) => [...prev, componentToAdd]);
      setComponentToAdd(null);
    }
  };

  const onTagRemove = (id) => {
    const finalId = id || inputTags[inputTags.length - 1]?.id;
    if (finalId) {
      setSelectedTableRows((prev) => {
        delete prev[finalId];
        return prev;
      });
      const filteredTags = inputTags.filter((tag) => tag.id !== finalId);
      setInputTags(filteredTags);
      return;
    }
    setInputTags((prev) => prev.slice(0, prev.length - 1));
  };

  const onTagPreview = (id) => {
    const clickedComponent = inputTags.find((tag) => tag.id === id);
    setComponentToAdd(clickedComponent);
  };

  return {
    componentToAdd,
    inputTags,
    setInputTags,
    selectedTableRows,
    setSelectedTableRows,
    onTemplateClick,
    onAddComponent,
    onTagRemove,
    onTagPreview,
    setComponentToAdd
  };
};

export default useComponentAdd;
