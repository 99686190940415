import React from 'react';
import AIPrompt from './AIPrompt';
import UserPrompt from './UserPrompt';

const PromptMessages = () => {
  return (
    <div className="mx-auto max-w-[760px] pt-[16px]">
      <UserPrompt
        message="Write a note for  patient Adrian Samuel undergoing a routine wellness checkup.."
        avatarUrl="/path/to/user-avatar.png"
      />
      <AIPrompt
        message={
          <div>
            <h2 className="mb-2 text-lg font-bold">Subjective:</h2>
            <p className="mb-4">
              The patient, a 45-year-old female, presents with a chief complaint of a persistent
              cough for the past 2 weeks. She reports the cough is dry and worse at night. She
              denies any fever, chills, or shortness of breath. She has a history of seasonal
              allergies but has never experienced a cough lasting this long. She has been taking
              over-the-counter antihistamines with minimal relief.
            </p>

            <h3 className="mb-2 text-base font-bold">Objective:</h3>
            <ul className="list-disc pl-6">
              <li>
                Vital signs: BP 120/80 mmHg, HR 72 bpm, RR 16 breaths/min, Temp 98.6°F, SpO2 98% on
                room air
              </li>
              <li>
                Physical exam: Lungs clear to auscultation bilaterally, no wheezing or rhonchi.
                Nasal mucosa is slightly erythematous. Throat is clear with no exudates. No cervical
                lymphadenopathy.
              </li>
              <li>Recent chest X-ray: No acute infiltrates or abnormalities noted.</li>
            </ul>

            <h3 className="mb-2 text-base font-bold">Assessment:</h3>
            <p className="mb-4">
              The patient, a 45-year-old female, presents with a chief complaint of a persistent
              cough for the past 2 weeks. She reports the cough is dry and worse at night. She
              denies any fever, chills, or shortness of breath. She has a history of seasonal
              allergies but has never experienced a cough lasting this long. She has been taking
              over-the-counter antihistamines with minimal relief.
            </p>
            <h3 className="mb-2 text-base font-bold">Assessment:</h3>
            <p className="mb-4">
              The patient, a 45-year-old female, presents with a chief complaint of a persistent
              cough for the past 2 weeks. She reports the cough is dry and worse at night. She
              denies any fever, chills, or shortness of breath. She has a history of seasonal
              allergies but has never experienced a cough lasting this long. She has been taking
              over-the-counter antihistamines with minimal relief.
            </p>
            <h3 className="mb-2 text-base font-bold">Assessment:</h3>
            <p className="mb-4">
              The patient, a 45-year-old female, presents with a chief complaint of a persistent
              cough for the past 2 weeks. She reports the cough is dry and worse at night. She
              denies any fever, chills, or shortness of breath. She has a history of seasonal
              allergies but has never experienced a cough lasting this long. She has been taking
              over-the-counter antihistamines with minimal relief.
            </p>
            <h3 className="mb-2 text-base font-bold">Assessment:</h3>
            <p className="mb-4">
              The patient, a 45-year-old female, presents with a chief complaint of a persistent
              cough for the past 2 weeks. She reports the cough is dry and worse at night. She
              denies any fever, chills, or shortness of breath. She has a history of seasonal
              allergies but has never experienced a cough lasting this long. She has been taking
              over-the-counter antihistamines with minimal relief.
            </p>
          </div>
        }
        avatarUrl="/path/to/ai-avatar.png"
      />
    </div>
  );
};

export default PromptMessages;
