import { snakeToTitleCase } from 'lib/helpers/utility';

import Checkbox from 'components/shared/Checkbox/Checkbox';

const loadValues = (options, values = [], value) => {
  const currentOptions = structuredClone(values) || [];

  const filteredValues = currentOptions?.includes(value)
    ? currentOptions?.filter((v) => v !== value)
    : [...currentOptions, value];

  if (filteredValues.length === 0) return null;

  return filteredValues;
};

const FilterCheckbox = ({ data, index, setFilter }) => {
  return (
    <div className="flex cursor-pointer flex-col gap-2 rounded-lg">
      <p className="font-medium text-sm">{data?.title || 'Search'}</p>
      <div className="relative flex flex-wrap items-center gap-x-3 gap-y-2">
        {data?.options?.map((option, idx) => {
          const value = typeof option === 'object' ? option?.value : option;
          return (
            <Checkbox
              key={idx}
              data-qa={`checkbox-${option}`}
              innerLabelClassName="gap-2"
              size={16}
              label={typeof option === 'object' ? option?.label : snakeToTitleCase(option)}
              isChecked={data?.values?.includes(value)}
              handleClick={() =>
                setFilter(index, {
                  ...data,
                  values: loadValues(data?.options, data?.values, value)
                })
              }
            />
          );
        })}
      </div>
    </div>
  );
};

export default FilterCheckbox;
