import React from 'react';
import { NavLink } from 'react-router-dom';

import cs from 'classnames';

import useHasScrollbar from 'lib/hooks/useHasScrollbar';

import Allowed from 'components/shared/Permissions/Allowed';

const Tabs = ({ id }) => {
  const classStyle = 'rounded-full !py-2 !px-4 text-sm text-neutral-600 whitespace-nowrap';
  const activeClassStyle =
    'rounded-full bg-gradient-to-r from-[#13B8FF] to-primary-200 !py-2 !px-4 text-sm font-600 text-white whitespace-nowrap !text-white';
  const [myDivRef, hasScrollbar] = useHasScrollbar({ type: 'horizontal' });

  return (
    <div className="relative shrink-0 overflow-hidden from-transparent to-white !px-10 !py-2">
      <div
        ref={myDivRef}
        data-qa="overview"
        className={cs('flex w-full gap-4 overflow-x-auto', hasScrollbar && '!pb-2')}>
        <NavLink
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practices/${id}`}
          end>
          Overview
        </NavLink>
        <Allowed requiredPermissions="member.read">
          <NavLink
            data-qa="Staff"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/staff`}>
            Staff
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="patients.read">
          <NavLink
            data-qa="Patients"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/patients`}>
            Patients
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="terminal.read">
          <NavLink
            data-qa="terminals"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/terminals`}>
            Terminals
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="role.read">
          <NavLink
            data-qa="roles"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/roles`}>
            Roles
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="patients.create">
          <NavLink
            data-qa="patient-import"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/patient-import`}>
            Patient Import
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="billing.read">
          <NavLink
            data-qa="billing"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/billing`}>
            Billing
          </NavLink>
        </Allowed>
        <NavLink
          data-qa="facility"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practices/${id}/facility`}>
          Facility
        </NavLink>
        <Allowed requiredPermissions="kiosk.read">
          <NavLink
            data-qa="kiosk"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/kiosk`}>
            Kiosk
          </NavLink>
        </Allowed>
        <Allowed requiredPermissions="payments.read">
          <NavLink
            data-qa="transactions"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/transactions`}>
            Transactions
          </NavLink>
        </Allowed>
        <NavLink
          data-qa="organization"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practices/${id}/organization`}>
          Organization
        </NavLink>
        <Allowed requiredPermissions="form.create">
          <NavLink
            data-qa="form-builder"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            to={`/admin/practices/${id}/form-builder`}>
            Form Builder
          </NavLink>
        </Allowed>
        <NavLink
          data-qa="settings"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/practices/${id}/settings`}>
          Settings
        </NavLink>
      </div>
    </div>
  );
};

export default Tabs;
