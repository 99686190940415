import React from 'react';
import Input from '../../../../../shared/Input/Input';
import Icon from '../../../../../shared/Icon/Icon';

const AppointmentSearchProvider = ({ filters, setFilters }) => {
  return (
    <Input
      data-qa="searchProvider"
      type="string"
      placeholder="Search provider"
      value={filters.searchTerm}
      icon="new-search"
      className={` !rounded-2xl !border-secondary-400 md:w-[200px] `}
      onChange={(e) =>
        setFilters((prevState) => ({ ...prevState, searchTerm: e.target.value, page: 1 }))
      }
      rightText={
        filters.searchTerm && (
          <Icon
            icon="new-close"
            onClick={() => {
              setFilters((prevState) => ({ ...prevState, searchTerm: '', page: 1 }));
            }}
          />
        )
      }
    />
  );
};

export default AppointmentSearchProvider;
