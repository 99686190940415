import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const AIPrompt = ({ message, avatarUrl }) => {
  return (
    <div className="my-4 flex items-start justify-start">
      <div
        className="mr-2 flex items-center justify-center rounded-[7px] border-solid border-primary-100 bg-white"
        style={{
          width: 36,
          height: 36,
          minWidth: 36,
          minHeight: 36
        }}>
        <Icon icon="stars-ai" />
      </div>
      <div className="pb-5 pr-2">
        <div className="rounded-lg bg-white p-3 text-gray-900">{message}</div>
        <div className="flex items-center justify-end">
          <div className="flex flex-row gap-1">
            <Button
              size="small"
              text="Copy"
              onClick={() => {}}
              iconSize={18}
              outlined
              color="neutral"
            />
            <Button
              size="small"
              text="Regenerate"
              onClick={() => {}}
              iconSize={18}
              outlined
              color="neutral"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AIPrompt;
