import React, { useState } from 'react';
import Switch from 'components/shared/Switch/Switch';
import Button from 'components/shared/Buttons/Button';
import CurrencyInput from 'react-currency-input-field';
import { isEmpty } from 'lib/helpers/utility';
import Input from 'components/shared/Input/Input';

const Technology = ({
  practice,
  updateSurcharge,
  surchargeLoading,
  setShowTechnologyModal,
  surchargeOptions,
  selectedSurchargeButton,
  showCurrencyInput,
  setShowCurrencyInput,
  setSelectedSurchargeButton
}) => {
  const [surchargeAmount, setSurchargeAmount] = useState(0);
  const [errors, setErrors] = useState({
    surchargeExceeded: {
      success: false,
      message: ''
    }
  });

  const onButtonClick = (option, idx) => {
    if (isNaN(option)) {
      setShowCurrencyInput(!showCurrencyInput);
      setSurchargeAmount(0);
      if (selectedSurchargeButton == idx) {
        setSelectedSurchargeButton(-1);
        updateSurcharge(0, 'surcharge_percentage');
      } else {
        setSelectedSurchargeButton(idx);
      }
    } else if (selectedSurchargeButton == idx) {
      if (idx != 4) {
        setSurchargeAmount(0);
        updateSurcharge(0, 'surcharge_percentage');
      }
      setSelectedSurchargeButton(-1);
    } else {
      setShowCurrencyInput(false);
      setSelectedSurchargeButton(idx);
      updateSurcharge(option, 'surcharge_percentage');
    }
  };

  const validateSurcharge = (event) => {
    event.preventDefault();
    const exceedFour = 4;
    if (surchargeAmount > exceedFour) {
      setErrors({
        surchargeExceeded: {
          success: false,
          message: `You can not exceed 4%.`
        }
      });
    } else {
      setErrors({
        surchargeExceeded: {
          success: true,
          message: ''
        }
      });
      updateSurcharge(surchargeAmount, 'surcharge_percentage');
    }
  };

  const enableSurcharge = (e) => {
    isEmpty(practice?.technology_fee_consent)
      ? setShowTechnologyModal(true)
      : updateSurcharge(e, 'surcharge_enabled');
  };

  return (
    <div className="w-full p-3">
      <label className='font-500 block pb-2'>Technology fee</label>
      <div>
        <div className="w-full p-3">
          <Input
            type="text"
            label="Current technology fee percentage"
            disabled
            placeholder="Technology fee percentage"
            name={'Technology fee percentage'}
            value={
              practice?.surcharge_enabled && practice?.surcharge_percentage
                ? `${practice.surcharge_percentage}%`
                : 'No technology fee.'
            }
          />
          <div className="flex items-center gap-2 py-2">
            <Switch
              disabled={surchargeLoading}
              onChange={(e) => enableSurcharge(e)}
              checked={practice?.surcharge_enabled}
            />
            <label className='font-500 block pb-2'>Enable/Disable Technology fee</label>
          </div>
          {practice?.surcharge && practice.surcharge_enabled && (
            <div>
              <label className='font-500 block pb-2'>Percentage Select</label>
              <div className="mb-10 overflow-hidden border-2 border-solid border-gray-300 bg-white">
                {surchargeOptions?.map((option, idx) => {
                  return (
                    <button
                      className=" hover:bg-gray-300 w-1/5 border-none outline-none cursor-pointer px-4 py-3 transition duration-300"
                      key={idx}
                      style={{
                        backgroundColor: `${selectedSurchargeButton == idx ? '#01a2ff' : ''}`
                      }}
                      onClick={() => onButtonClick(option, idx)}>
                      {isNaN(option) ? option : `${option}%`}
                    </button>
                  );
                })}
              </div>
              <div>
                <label className='font-500 block pb-2'>Technology fee Custom Percentage</label>
                <div>
                  <CurrencyInput
                    className='border border-solid !border-neutral-200 !h-[40px] !rounded-md focus-visible:!border-primary-500 focus-visible:outline-primary-100 outline-offset-2'
                    prefix="%"
                    onValueChange={(v) => {
                      setSurchargeAmount(v);
                    }}
                    placeholder={`${practice?.surcharge_percentage}%`}
                    allowDecimals={true}
                    min={0}
                  />
                </div>
                {!errors?.surchargeExceeded?.success && (
                  <p className="text-danger-500 text-center">
                    {errors?.surchargeExceeded?.message}
                  </p>
                )}
                <Button
                  text="Save Custom Percentage"
                  className="mt-3"
                  primary
                  onClick={(e) => validateSurcharge(e)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Technology;
