import React, { useEffect, useState } from 'react';
import { ia, iaRa, io } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { getOwnerPractices, setDefaultPractice } from 'api/Practice';
import { useLocation, useNavigate } from 'react-router-dom';
import background from 'assets/Images/select-practice-background.png';
import state from '../../../../state';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import practiceState from 'components/practice/practiceState';
import Icon from 'components/shared/Icon/Icon';
import { requestApi } from 'api/Api';
import { defaultPracticeSettings } from 'constants';
import cs from 'classnames';

const PreferredLocation = () => {
  const [loading, setLoading] = useState(false);
  const [selectedPractice, setSelectedPractice] = useState();
  const [setAsDefault, setSetAsDefault] = useState(false);
  const setPermissions = useSetRecoilState(state.permissions);
  const [user_state, setUserState] = useRecoilState(state.userState);
  const [currentPractice, setCurrentPractice] = useRecoilState(practiceState.currentPractice);
  const [ownerPractices, setOwnerPractices] = useRecoilState(practiceState.ownerPractices);
  const setOwnerSelectedLocation = useSetRecoilState(practiceState.ownerSelectedLocation);

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const getPractices = async () => {
    const data = await getOwnerPractices(navigate, {
      practice_ids: ownerPractices.map((item) => item.id)
    });

    if (!ownerPractices.length) {
      setOwnerPractices(data.practices);
    }
  };

  useEffect(() => {
    getPractices();
  }, []);

  const handleSetDefaultPractice = async () => {
    setLoading(true);

    const params = {
      practice_id: selectedPractice.id,
      setAsDefault
    };

    const data = await setDefaultPractice(navigate, params);

    if (data.code === 0) {
      setPermissions(data.permissions);
      setOwnerSelectedLocation(selectedPractice.id);
      await checkTokenAndGetPractice();
      if (pathname === '/select-practice') {
        await checkTokenAndGetPractice();
      }
      navigate('/portal');
    }

    setLoading(false);
  };

  const checkTokenAndGetPractice = async () => {
    try {
      const resData = await requestApi({ url: '/api/user/check_token', navigate });
      const { token_valid, user_data, is_admin, session_token } = resData;
      if (token_valid) {
        user_data?.display_settings &&
          io(user_data?.display_settings?.appointmentFilters) &&
          setUserState({
            ...user_state,
            ...user_data,
            authenticated: true,
            logged_in: true
          });
        const resData = await requestApi({ url: '/api/practice/current_practice', navigate });
        const { code, practice, child_practices, selected_practice_id } = resData;
        if (practice) {
          setCurrentPractice({
            ...currentPractice,
            id: practice.id,
            name: practice.name,
            email: practice.email,
            phone: practice.phone,
            address: practice.practiceAddress.fullAddress,
            header_photo: practice.header_photo,
            display_settings: { ...defaultPracticeSettings, ...practice?.display_settings },
            timezone: practice.timezone,
            appointment_types: practice?.appointment_types,
            eligibility_counter: practice?.eligibility_counter,
            care_credit: practice?.care_credit,
            surcharge: practice?.surcharge,
            surcharge_percentage: practice?.surcharge_percentage,
            surcharge_enabled: practice?.surcharge_enabled,
            socket_id: practice?.socket_id
          });

          if (ia(child_practices)) {
            setOwnerPractices(child_practices);
          }
          if (selected_practice_id) {
            setOwnerSelectedLocation(selected_practice_id);
          }
        }
      }
    } catch (error) {}
  };
  return (
    <div
      className="flex h-[100vh] w-[100vw] items-center justify-center bg-slate-100"
      style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover' }}>
      <Icon icon="new-logo-bold" className="absolute left-6 top-6" />
      <div className="shadow  flex h-[60%] max-h-[666px] w-[52%] max-w-[754px] flex-col !rounded-lg bg-white">
        {/* header */}
        <div className="flex h-[130px] flex-col !px-6 !pb-4 !pt-3">
          <div className="flex items-center">
            <Icon icon="preferred_location" className="" size={50} />
            <h1 className="text-2xl font-500 text-primary-600">Preferred Location</h1>
          </div>
          <p className="text-base text-primary-900">
            Please choose your preferred location from the options provided below.
          </p>
        </div>

        {/* body */}
        <div className="flex flex-1 flex-col gap-[14px] overflow-auto bg-neutral-50 px-[24px] py-4">
          {iaRa(ownerPractices).map((practice, index) => {
            const isChecked = practice.id === selectedPractice?.id;
            return (
              <div
                key={practice.id}
                className={cs(
                  'flex cursor-pointer items-center gap-2 rounded-md border border-solid px-4 py-3 hover:bg-primary-50',
                  isChecked
                    ? '!border-2 !border-primary-200 shadow-sm shadow-primary-900'
                    : '!border-neutral-200',
                  index === 0 && '!mt-4'
                )}
                onClick={() => setSelectedPractice(practice)}>
                <Checkbox
                  radio
                  isChecked={isChecked}
                  onChange={() => setSelectedPractice(practice)}
                />
                <p className={`text-sm text-${isChecked ? 'primary' : 'neutral'}-700 font-500`}>
                  {practice.name}
                </p>
              </div>
            );
          })}
        </div>

        {/* footer */}
        <div className="flex !h-[62px] items-center justify-between bg-white px-[24px] py-3">
          <div className="flex items-center gap-2">
            <Checkbox isChecked={setAsDefault} onChange={() => setSetAsDefault((prev) => !prev)} />
            <p className="text-sm font-500 text-neutral-700">Set this as my default location</p>
          </div>
          <Button
            onClick={handleSetDefaultPractice}
            text="Continue"
            disabled={!selectedPractice}
            loadingIcon={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default PreferredLocation;
