import { isEqual } from 'lodash';

import { upsertPreferences } from 'api/Preferences';

export const areArraysEqualUnordered = (arr1, arr2) => {
  if (arr1?.length !== arr2?.length) return false;

  const arr2Copy = [...arr2];
  for (const elem of arr1) {
    const index = arr2Copy?.findIndex((e) => isEqual(e?.field, elem?.field));
    if (index === -1) return false;
    arr2Copy?.splice(index, 1);
  }
  return true;
};

export const formatValue = (cols, limit) => {
  return {
    columnOrder: cols?.map((col) => ({
      hide: col.hide || null,
      field: col.field || null,
      pinned: col.pinned || null,
      rowGroup: col.rowGroup || null
    })),
    limit
  };
};

export const updatePreferences = async ({ navigate, cols = [], limit = 25, queryClient, name }) => {
  const res = await upsertPreferences(navigate, { name, value: formatValue(cols, limit) });
  if (res?.code === 0) {
    queryClient.setQueryData(['preferences', name], (oldData) => {
      const newPreferences = res?.preferences;
      return {
        ...oldData,
        preferences: { ...oldData?.preferences, value: newPreferences }
      };
    });
  }
};
