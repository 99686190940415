import { iaRa } from 'lib/helpers/utility';

export const customFilter = (data, row = 'row', key) => {
  if (!data) return [];

  return data
    ?.map((item) => {
      var itemsList = iaRa(item?.response?.[row]).filter((item) => item[key]);

      return {
        list: iaRa(itemsList),
        createdAt: item?.createdAt,
        createdBy: item?.createdBy,
        id: item?.id,
        appointmentId: item?.appointmentId,
        original: item
      };
    })
    .filter((item) => item !== null);
};

const filterSocialHistory = (data) => {
  const socialHistory = {
    cigarettes: [],
    vape: [],
    alcohol: [],
    drugs: [],
    education: [],
    employed: [],
    exercise: [],
    martialStatus: [],
    sexualActivity: [],
    children: []
  };
  data.map((item) => {
    Object.keys(socialHistory).map((key) => {
      if (
        item?.response?.[key] &&
        !Object.values(item?.response?.[key])?.every((i) => !i || i == '')
      ) {
        socialHistory[key].push({
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          id: item?.id,
          ...item?.response?.[key]
        });
      }
    });
  });
  return Object.fromEntries(
    Object.entries(socialHistory).filter(([_, v]) => v != null && v.length > 0)
  );
};

const formatHistoriesCustomFormV2 = (data) => {
  return iaRa(data).map((row) => {
    return { ...row, list: [row?.response], original: row?.response };
  });
};

export default function filterCustomData(data) {
  return {
    allergies: customFilter(data?.response?.allergies, 'allergies', 'allergy'),
    socialHistory: data?.response?.socialHistory
      ? formatHistoriesCustomFormV2(data?.response?.socialHistory)
      : null,
    // socialHistory: data?.response?.socialHistory
    //   ? filterSocialHistory(data?.response?.socialHistory)
    //   : null,
    originalSocialHistory: data?.response?.socialHistory,
    vaccines: customFilter(data?.response?.vaccines, 'row', 'vaccine'),
    familyHistory: data?.response?.familyHistory
      ? formatHistoriesCustomFormV2(data?.response?.familyHistory)
      : null,
    surgicalHistory: data?.response?.surgicalHistory
      ? formatHistoriesCustomFormV2(data?.response?.surgicalHistory)
      : null,
    medicalHistory: data?.response?.medicalHistory
  };
}
