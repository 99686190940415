import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';

import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../lib/helpers/utility';
import { useDocumentSign } from '../../../../../lib/hooks/queries/documentSign/useDocumentSign';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import Button from '../../../../shared/Buttons/Button';
import Modal from '../../../../shared/Modal/Modal';
import { userState } from '../../../../state';

import TPModalContent from './components/TPModalContent';
import TPModalFooter from './components/TPModalFooter';
import useNavigation from './hooks/useNavigation';
import useViewActions from './hooks/useViewActions';

const TPModal = ({
  isOpen,
  handleClose,
  loadView = 'list',
  origin,
  availableDates,
  loadEPNToCN,
  isModal = true,
  handlePreview = () => {},
  footer,
  outsideCN = false,
  initialView = false
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();

  const {
    breadcrumb,
    currentRoute: { name: view, btnText },
    navigate: modalNavigate
  } = useNavigation(loadView);

  const { patient } = useOutletContext() || {};
  const user = useRecoilValue(userState);
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  const { id } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);

  const { defaultViewActions, createTPIsLoading } = useViewActions({
    navigate,
    handleClose,
    handlePreview,
    patient,
    loadEPNToCN,
    outsideCN
  });

  const noProvidersSelected = !isModal
    ? (selectedTreatmentPlan?.treatment_team ?? []).every((item) => item.provider === null)
    : null;

  const { data: signers } = useDocumentSign({
    params: {
      documentIds: [selectedTreatmentPlan?.id],
      userIds: [user?.id]
    },
    dependencies: [selectedTreatmentPlan?.id],
    options: {
      refetchOnMount: !outsideCN,
      enabled: isOpen && !!selectedTreatmentPlan?.id,
      select: (response) => response?.data
    }
  });

  const userSignRow = signers?.find((row) => row.user_id == user.id);

  useEffect(() => {
    if ((ia(treatmentPlan) && view === 'empty') || loadView === 'list') {
      modalNavigate('list');
    }
    if (loadView === 'edit') {
      modalNavigate('edit');
      if (!outsideCN) setSelectedTreatmentPlan(availableDates[0]);
    }
    if (loadView === 'preview') {
      modalNavigate('preview');
    }
  }, [loadView]);

  const handleBtnClick = ({ nextView = '', ...rest } = {}) => {
    try {
      defaultViewActions[view]?.action?.(rest);
      modalNavigate(nextView);
    } catch (error) {
      console.error('Error in handleBtnClick:', error);
    }
  };

  const backHandler = (view) => {
    let tempNextView = '';

    switch (view) {
      case 'preview':
      case 'create':
        tempNextView = 'list';
        break;
      case 'edit':
        tempNextView = 'preview';
        break;
      default:
        tempNextView = 'list';
        break;
    }

    modalNavigate(tempNextView);
  };

  const content = (
    <TPModalContent
      isModal={isModal}
      view={view}
      treatmentPlan={treatmentPlan}
      loadEPNToCN={loadEPNToCN}
      handleBtnClick={handleBtnClick}
      showConfirmationModal={showConfirmationModal}
      setShowConfirmationModal={setShowConfirmationModal}
      footer={footer}
      createTPIsLoading={createTPIsLoading}
      noProvidersSelected={noProvidersSelected}
      origin={origin}
      outsideCN={outsideCN}
    />
  );

  if (!isModal) {
    return content;
  }

  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      handleClose={handleClose}
      className="h-[896px] w-[1160px] min-w-[480px]"
      bodyClassName={cs(
        'min-h-[400px] bg-primary-10 !p-0',
        ['preview', 'create', 'edit'].includes(view) && 'mt-3 flex flex-col items-center pb-[112px]'
      )}
      headerClassName="h-16"
      customHeader={
        <div className="flex w-full items-center justify-between">
          <Breadcrumb
            activeClassName="text-primary-700 truncate"
            breadcrumb={breadcrumb}
            key="breadcrumb"
          />
          {view === 'list' ? (
            <Button
              className="mr-5 min-w-[230px]"
              size="small"
              icon="new-add-square"
              onClick={() => handleBtnClick({ nextView: 'create' })}
              text="New Treatment Plan"
              data-qa="new-treatment-btn"
              transparent
            />
          ) : null}
          {view === 'preview' &&
          io(selectedTreatmentPlan) &&
          io(userSignRow) &&
          userSignRow?.status !== 'signed' ? (
            <Button
              text="Sign"
              icon="new-lock-2-opened"
              iconIsStroke
              type="secondary"
              className="!ml-4"
              onClick={() => setShowConfirmationModal(true)}
            />
          ) : null}
        </div>
      }
      isFooterSticky
      isFooter={false}
      footerClassName="z-30"
      stickyFooterClassName="max-w-[750px] mx-auto"
      footer={
        !['empty', 'list'].includes(view) ? (
          <TPModalFooter
            noProvidersSelected={noProvidersSelected}
            view={view}
            handleBack={backHandler}
            btnText={btnText}
            handleBtnClick={handleBtnClick}
            outsideCN={outsideCN}
            initialView={initialView}
            handleClose={handleClose}
          />
        ) : null
      }>
      {content}
    </Modal>
  );
};

export default TPModal;
