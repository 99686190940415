import React from 'react';
import Icon from 'components/shared/Icon/Icon';

export const NameCell = (params) => {
  const fax = params?.data;

  if (!fax) return;

  return (
    <div className="flex flex-row">
      <Icon icon="archive" />
      <p className="ml-2">{fax?.name || 'Unnamed'}</p>
    </div>
  );
};
