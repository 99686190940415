import React from 'react';

import { useRecoilValue } from 'recoil';
import { insuranceKeys } from '../../../lib/labOrderHelpers';
import { currentPractice } from '../../../../../../practiceState';
import { findRelationName, formatDateAndTimeZ } from '../../../../../../../../lib/helpers/utility';

const InsuranceDetails = ({ itemProps }) => {
  const practice = useRecoilValue(currentPractice);

  const shouldNotDisplay = insuranceKeys.every((row) => !itemProps?.[row]);

  if (shouldNotDisplay) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Insurance Details</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {itemProps?.ins_addr && (
            <div>
              <strong>Address:</strong> {itemProps?.ins_addr}
            </div>
          )}

          {itemProps?.ins_bill_to && (
            <div>
              <strong>Bill To:</strong> {itemProps?.ins_bill_to}
            </div>
          )}

          {itemProps?.ins_city && (
            <div>
              <strong>City:</strong> {itemProps?.ins_city}
            </div>
          )}

          {itemProps?.ins_code && (
            <div>
              <strong>Code:</strong> {itemProps?.ins_code}
            </div>
          )}

          {itemProps?.ins_contact_first_name && (
            <div>
              <strong>Contact First Name:</strong> {itemProps?.ins_contact_first_name}
            </div>
          )}

          {itemProps?.ins_contact_last_name && (
            <div>
              <strong>Contact Last Name:</strong> {itemProps?.ins_contact_last_name}
            </div>
          )}

          {itemProps?.ins_contact_phone && (
            <div>
              <strong>Contact Phone:</strong> {itemProps?.ins_contact_phone}
            </div>
          )}

          {itemProps?.ins_group_name && (
            <div>
              <strong>Group Name:</strong> {itemProps?.ins_group_name}
            </div>
          )}

          {itemProps?.ins_group_num && (
            <div>
              <strong>Group Number:</strong> {itemProps?.ins_group_num}
            </div>
          )}

          {itemProps?.ins_name && (
            <div>
              <strong>Name:</strong> {itemProps?.ins_name}
            </div>
          )}

          {itemProps?.ins_plan_eff_dt && (
            <div>
              <strong>Plan Effective Date:</strong>{' '}
              {formatDateAndTimeZ(itemProps?.ins_plan_eff_dt, practice?.timezone)}
            </div>
          )}
          {itemProps?.ins_plan_exp_dt && (
            <div>
              <strong>Plan Expiry Date:</strong>{' '}
              {formatDateAndTimeZ(itemProps?.ins_plan_exp_dt, practice?.timezone)}
            </div>
          )}

          {itemProps?.ins_plan_type && (
            <div>
              <strong>Plan Type:</strong> {itemProps?.ins_plan_type}
            </div>
          )}

          {itemProps?.ins_policy_num && (
            <div>
              <strong>Policy Number:</strong> {itemProps?.ins_policy_num}
            </div>
          )}

          {itemProps?.ins_relationship && (
            <div>
              <strong>Relationship:</strong>
              {findRelationName(itemProps?.ins_relationship)}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default InsuranceDetails;
