import React, { useEffect, useState } from 'react';

import cs from 'classnames';

import { usePacketBuilderContext } from 'lib/context/PacketBuilder/PacketBuilderContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';

import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';

import { getCurrentWithoutHidden, getStepsLeft } from '../lib/helpers';

import ItemList from './ItemList';
import StepsChart from './StepsChart';

const Providers = ({ currentBreadcrumb, providersList }) => {
  const { breadCrumbs, setEnableNextTo, setSearchTerm, formik, setEnableSubmit } =
    usePacketBuilderContext();
  const [selectedLength, setSelectedLength] = useState(formik.values?.provider_ids?.length);

  const { device } = useUIContext();

  const currentBreadcrumbStep = getCurrentWithoutHidden(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);

  useEffect(() => {
    if (selectedLength == 0) {
      setEnableSubmit(false);
      setEnableNextTo(false);
    } else {
      setEnableSubmit(true);
      setEnableNextTo(true);
    }
  }, [selectedLength]);

  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div>
        <StepsChart
          chartData={chartData}
          title="Choose Provider(s)"
          description="This form packet will be sent for each appointment scheduled for selected provider(s)."
          innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
        />
        <div className="px-9 pt-10">
          <div className="flex items-center justify-between ">
            <p className="text-sm font-500 text-primary-900">Select from the list of providers.</p>
            <div className="flex items-center gap-3">
              <p className={cs(selectedLength ? 'text-primary-900' : 'text-neutral-500')}>
                {selectedLength} selected
              </p>
              <Input
                placeholder="Search providers"
                icon="new-search"
                rounded="full"
                width={device === 'tablet-sm' ? '250px' : '280px'}
                onChange={(e) =>
                  setSearchTerm((prevState) => ({
                    ...prevState,
                    providers: e.target.value
                  }))
                }
              />
            </div>
          </div>
          <div className="mt-[21px]">
            {ia(providersList?.practitioners) &&
              providersList?.practitioners.map((provider, index) => (
                <ItemList
                  key={index}
                  index={index}
                  item={provider}
                  options="provider_ids"
                  setSelectedLength={setSelectedLength}
                  isProviders
                />
              ))}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(Providers);
