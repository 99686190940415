import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { deleteResource } from 'api/Resource';
import { showAlert } from 'components/shared/Alert/Alert';
import Popup from 'components/shared/Filters/Popup';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import NewResourceModal from './NewResourceModal';

const ResourceTableActions = ({ data }) => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);
  const queryClient = useQueryClient();
  const [resource, setResource] = useState(null);

  const handleDelete = async () => {
    const { code } = await deleteResource(navigate, { id: data.id });

    if (code == 0) {
      showAlert({
        title: 'Success',
        message: 'Resource deleted successfully',
        color: 'success'
      });
      queryClient.invalidateQueries(['resources']);
    }
  };

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={120}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        {(close) => (
          <div className=" !py-[6px]">
            <div
              data-qa="send-email-btn"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => {
                close();
                setResource(data);
              }}>
              <Icon icon="new-edit" color="primary" />
              <div className="ml-1 text-sm text-primary-900">Edit</div>
            </div>
            <div
              data-qa="send-email-btn"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={() => {
                setConfirm(true);
                close();
              }}>
              <Icon icon="trash" color="primary" />
              <div className="ml-1 text-sm text-primary-900">Delete</div>
            </div>
          </div>
        )}
      </Popup>
      {!!confirm && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete resource"
          icon="new-document-remove-red"
          message="Are you sure you want to delete resource?"
          handleContinue={handleDelete}
          handleOpen={confirm}
          handleClose={() => setConfirm(false)}
        />
      )}
      {resource && (
        <NewResourceModal
          resourcesModalVisible={!!resource}
          setResourcesModalVisible={() => setResource(null)}
          resource={resource}
        />
      )}
    </div>
  );
};

export default ResourceTableActions;
