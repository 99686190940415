import { useMutation, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { finalizeEob, finalizeEra, refreshERA } from '../../../../../api/ERA';
import { useEraContext } from '../../../../../lib/context/EraContext/EraContext';
import { EraContextProvider } from '../../../../../lib/context/EraContext/EraContextProvider';
import { ia } from '../../../../../lib/helpers/utility';
import { showAlert } from '../../../../shared/Alert/Alert';
import Header from '../../../../shared/Header/Header';
import '../../BeyondBilling.scss';
import AllocateEOB from './AllocateEOB';
import EraRowDetails from './EraRowDetails';
import Filters from './Filters';
import NewEOB from './NewEOB';
import Table from './Table';

const EraAndEob = () => {
  const { eras, type, eraCount, codes } = useEraContext();
  const [newEOB, setNewEOB] = useState(false);
  const [selectedEOB, setSelectedEOB] = useState();
  const [eobToEdit, setEOBToEdit] = useState({});
  const [mode, setMode] = useState('create');
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const mutateFinalizeERA = useMutation({
    mutationFn: ({ eraId, finalized }) =>
      finalizeEra(navigate, {
        eraId,
        fields: {
          finalized: finalized ? false : true
        }
      }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: `ERA finalized successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to finalize ERA!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to finalize ERA!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });
  const mutateFinalizeEOB = useMutation({
    mutationFn: ({ eobId, finalized }) =>
      finalizeEob(navigate, {
        eobId,
        fields: {
          finalized: finalized ? false : true
        }
      }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: `EOB finalized successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to finalize EOB!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to finalize EOB!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const refresh = useMutation({
    mutationFn: () => refreshERA(navigate),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['eras']);
          showAlert({
            title: `ERA's refreshed successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to refresh ERA's!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to refresh ERA's!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  if (id) return <Outlet />;

  return (
    <>
      <Header title="ERA/EOB" subtitle={type} showInfoInRecordings={true}>
        <Filters setMode={setMode} setNewEOB={setNewEOB} refresh={refresh} />
      </Header>
      <Table showInfoInRecordings={true}>
        {ia(eras) &&
          eras?.map((era, index) => {
            return (
              <EraRowDetails
                era={era}
                type={type}
                key={index}
                index={index}
                setSelectedEOB={setSelectedEOB}
                setNewEOB={setNewEOB}
                setMode={setMode}
                setEOBToEdit={setEOBToEdit}
                handleFinalized={(eraId, finalized, type) => {
                  if (type === 'era') {
                    mutateFinalizeERA.mutate({ eraId, finalized });
                  } else if (type === 'eob') {
                    mutateFinalizeEOB.mutate({ eobId: eraId, finalized });
                  }
                }}
              />
            );
          })}
      </Table>

      {newEOB && (
        <NewEOB
          newEOB={newEOB}
          eob={eobToEdit}
          mode={mode}
          setNewEOB={setNewEOB}
          showInfoInRecordings={true}
        />
      )}

      {selectedEOB && (
        <AllocateEOB
          codes={codes}
          loadedEOB={selectedEOB}
          handleClose={() => setSelectedEOB(null)}
          showInfoInRecordings={true}
        />
      )}
    </>
  );
};

const EraWrapper = () => {
  return (
    <EraContextProvider>
      <EraAndEob />
    </EraContextProvider>
  );
};

export default EraWrapper;
