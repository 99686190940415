import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createExternalInvoices, getInternalInvoices, getInternalInvoicesCSV } from 'api/Billing';
import { currentPractice } from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';
import Button from 'components/shared//Buttons/Button';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import { showAlert } from 'components/shared/Alert/Alert';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useFormik } from 'formik';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useInternalInvoices } from 'lib/hooks/queries/billing/useInternalInvoices';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';
import AltContact from './components/AltContact';
import { InvoiceModal } from './components/InvoiceModal';
import InvoicePrintView from './components/InvoicePrintView';
import NewInvoice from './components/NewInvoice';
import NewInvoiceForm from './components/NewInvoiceForm';
import NewInvoiceTable from './components/NewInvoiceTable';
import NewInvoiceView from './components/NewInvoiceView';
import PatientsMultiSearch from './components/PatientsMultiSearch';
import ContactButton from './components/contactButton/ContactButton';
import CustomContact from './components/contactButton/CustomContact';
import { CustomSendModal } from './components/contactButton/CustomSendModal';
import {
  getColDefs,
  DEFAULT_COLUMN_DEFS,
  GRID_OPTIONS,
  getPracticeDefaultFilters
} from './lib/internal/configs';
import { getInvoicesFromSelectedRows, onSendEmail, onSendText } from './lib/utils';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import AGTable from 'components/shared/AGTable/AGTable';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Tippy from '@tippyjs/react';

const getPatientInvoices = async (params, navigate) => {
  const { invoices = [] } = await getInternalInvoices(navigate, params);
  return invoices;
};

function ModalStepsFooter({
  handleInvoiceModalClose = () => {},
  onChangeStep = () => {},
  disableStepChange = []
}) {
  return (
    <div className="flex w-full justify-between">
      <Button
        outlined
        text="Cancel"
        color="neutral"
        onClick={handleInvoiceModalClose}
        id="cancelInvoiceModalBtn"
        data-qa="cancel-btn"
      />
      <Button
        disabled={disableStepChange}
        onClick={onChangeStep}
        text="Next"
        id="savePackageModalBtn"
        data-qa="create-update-invoice-btn"
      />
    </div>
  );
}

const InternalInvoices = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider
      name="internal_invoices"
      cols={getColDefs(practiceTimezone)}
      pagination
      defaultFilters={getPracticeDefaultFilters(practice)}>
      <Table />
    </TableContextProvider>
  );
};

export function ModalCreateFooter({
  loading,
  handleInvoiceModalClose = () => {},
  formik,
  invoiceIds
}) {
  const ref = useRef();

  const [check, setCheck] = useState(true);

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onCheckAEOB = ({ target }) => {
    invoiceIds.map((id) => {
      formik.setFieldValue(`${id}.aeob`, target.checked);
      setCheck(target.checked);
    });
  };
  return (
    <div className="flex w-full justify-between">
      <Button
        outlined
        text="Cancel"
        color="neutral"
        onClick={handleInvoiceModalClose}
        id="cancelInvoiceModalBtn"
        data-qa="cancel-btn"
      />
      <div ref={ref} className="flex items-center justify-center">
        <Tippy
          className="tippy-dark"
          placement="top"
          content={
            <div className="p-2">
              <div className="pb-2 text-sm">Include a full breakdown of the invoice.</div>
              <div className="pb-1 text-sm">Charge</div>
              <div className="pb-1 text-sm">Adjustment</div>
              <div className="pb-1 text-sm">Ins. Payment</div>
            </div>
          }>
          <div className="relative">
            <Checkbox
              name="details"
              data-qa="advanced-aeob-checkbox"
              className="cursor-pointer pr-5"
              isChecked={check}
              handleClick={onCheckAEOB}
              label="Advanced EOB"
            />
          </div>
        </Tippy>
        <Button
          onClick={handleSubmit}
          text="Create"
          id="savePackageModalBtn"
          data-qa="create-update-invoice-btn"
          loading={loading}
        />
      </div>
    </div>
  );
}

const initialView = {
  title: 'Create Invoice',
  step: 0,
  mode: 'create'
};

function Table() {
  const category = 'internal_invoices';
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const pageToPrintRef = useRef();

  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const claims = practice?.display_settings?.claims;

  const [patientInvoices, setPatientInvoices] = useState([]);
  const [groupSelectedRows, setGroupSelectedRows] = useState([]);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [externalInvoices, setExternalInvoices] = useState([]);
  const [internalInvoice, setInternalInvoice] = useState();
  const [activeView, setActiveView] = useState(initialView);
  const [customContactType, setCustomContactType] = useState('email');

  const {
    limit,
    page,
    setPage,
    sort,
    selectedRows = [],
    setSelectedRows = () => {},
    filters,
    setFilters,
    gridApi
  } = useTableContext();

  const { data: providers } = useProviders({});

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.providers.options = providerOptions;

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useInternalInvoices({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const { mutate: createInvoices, isLoading: isCreateInvoicesLoading } = useMutation({
    mutationFn: (data) => createExternalInvoices(navigate, data)
  });

  const invoices = data?.invoices;
  const count = data?.count || 0;
  const statusPanelData = data?.statusPanelData;

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [invoices, count, page, limit, onPageChange, statusPanelData]);

  const selectedRowData = selectedRows?.map((row) => row.data);

  let finalGroupInvoices = getInvoicesFromSelectedRows(groupSelectedRows);

  if (selectedRowData?.length > 0) {
    finalGroupInvoices = getInvoicesFromSelectedRows(selectedRowData);
  }

  if (internalInvoice) {
    finalGroupInvoices = getInvoicesFromSelectedRows([internalInvoice]);
  }

  const initialValues = {};

  finalGroupInvoices?.map((invoice) => {
    initialValues[invoice.id] = {
      internal_invoice_ids: invoice.internal_invoice_ids,
      patient: invoice.patient,
      due_date: new Date(),
      memo: '',
      amount_cents: invoice.procedures.reduce((acc, curr) => {
        const currCharge = curr?.charge || 0;
        acc += currCharge;
        return acc;
      }, 0),
      total_amount_cents: 0,
      discount_amount_cents: 0,
      tax_percentage: 0,
      tax_amount_cents: 0,
      surcharge_amount: 0,
      isDetailed: false
    };
  });

  const invoiceFormik = useFormik({
    initialValues,
    onSubmit: (values) => {
      const finalInvoices = Object.keys(values).map((key) => {
        const finalObj = { ...initialValues[key], ...values[key] };
        return finalObj;
      });

      if (finalInvoices.some((invoice) => invoice?.amount_cents < 0)) {
        showAlert({
          title: 'Attention!',
          message:
            finalInvoices?.length > 1
              ? 'At least one invoice has a negative amount. Invoice amounts must be zero or higher. Please correct any negative values and try again.'
              : 'The invoice amount must be greater than or equal to zero. Please enter a valid amount and try again.',
          color: 'warning'
        });
        return;
      }

      createInvoices(finalInvoices, {
        onSuccess: (externalInvoiceData) => {
          const createdInvoices = externalInvoiceData.invoices;
          setExternalInvoices(createdInvoices);

          queryClient.invalidateQueries(['external_invoices']);
          showAlert({
            title: 'Success',
            message: 'Invoices have been created successfully',
            color: 'success'
          });
          setActiveView((prev) => ({ ...prev, mode: 'preview', title: 'Preview Invoice' }));
        },
        onError: () => {
          showAlert({
            title: 'Attention!',
            message: 'There was an error creating invoices!',
            color: 'danger'
          });
        }
      });
    }
  });

  const steps = {
    0: (
      <div className="mt-3 w-full">
        <PatientsMultiSearch onSelect={getPatients} />
      </div>
    ),
    1: (
      <div className="h-full">
        <NewInvoiceTable
          invoices={patientInvoices}
          groupSelectedRows={groupSelectedRows}
          setGroupSelectedRows={setGroupSelectedRows}
        />
      </div>
    ),
    2: (
      <>
        {finalGroupInvoices.map((finalInvoice) => {
          const invoiceDetails = getProceduresTotal([finalInvoice]);

          const hasMany = ia(finalInvoice.internal_invoice_ids, 1);
          const isAEOB = invoiceFormik?.values?.[finalInvoice.id]?.aeob ?? true;

          return (
            <div key={finalInvoice.id}>
              <InvoicePrintView
                practice={practice}
                patient={finalInvoice?.patient}
                createdAt={!hasMany ? finalInvoice?.dateofservice : null}
                content={
                  <NewInvoice invoice={finalInvoice} isAEOB={isAEOB}>
                    <NewInvoiceForm
                      formik={invoiceFormik}
                      invoiceId={finalInvoice.id}
                      initialValues={initialValues}
                      invoiceDetails={invoiceDetails}
                      hasClaims={claims}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    )
  };

  let modalContent = steps[activeView.step];

  if (selectedRows?.length > 0 && finalGroupInvoices.length > 0) {
    modalContent = steps[2];
  }

  let headButton = null;
  let modalFooter = (
    <ModalStepsFooter
      handleInvoiceModalClose={handleInvoiceModalClose}
      onChangeStep={onNext}
      disableStepChange={
        (activeView?.step === 1 && !patientInvoices?.length) ||
        (activeView?.step === 1 && !groupSelectedRows?.length)
      }
    />
  );
  let prevButton = <Button icon="back-large" transparent onClick={onPrev} />;

  if (activeView.step > 1 || (selectedRows?.length > 0 && finalGroupInvoices.length > 0)) {
    modalFooter = (
      <ModalCreateFooter
        invoiceIds={finalGroupInvoices.map((invoice) => invoice?.id)}
        formik={invoiceFormik}
        loading={isCreateInvoicesLoading}
        handleInvoiceModalClose={handleInvoiceModalClose}
      />
    );
  }

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  if (activeView.mode === 'preview') {
    const handleCustomSendModalOpen = (type) => {
      setCustomContactType(type);
      setCustomModalVisible(true);
    };
    modalContent = (
      <>
        {finalGroupInvoices.map((finalInvoice, idx) => {
          const externalInvoice = externalInvoices?.find(
            (invoice) => invoice.patient_id === finalInvoice.patient.id
          );

          const invoiceDetails = getProceduresTotal([finalInvoice]);

          return (
            <div key={idx}>
              <InvoicePrintView
                practice={practice}
                patient={finalInvoice?.patient}
                createdAt={finalInvoice?.dateofservice}
                content={
                  <NewInvoice invoice={finalInvoice} isView isAEOB={externalInvoice?.aeob}>
                    <NewInvoiceView
                      hasClaims={claims}
                      invoiceDetails={{
                        ...invoiceDetails,
                        discount: externalInvoice?.discount_amount_cents || 0,
                        tax_percentage: externalInvoice?.tax_percentage || 0,
                        tax_amount_cents: externalInvoice?.tax_amount_cents || 0,
                        surcharge_amount: externalInvoice?.surcharge_amount || 0,
                        memo: externalInvoice?.memo
                      }}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
    headButton = (
      <div className="flex w-full justify-end">
        <ReactToPrint
          trigger={() => (
            <Button
              color=""
              size="small"
              type="link"
              text="Print"
              icon="new-printer-bulk"
              className="text-primary-900"
            />
          )}
          content={() => pageToPrintRef?.current}
        />
      </div>
    );
    prevButton = null;
    modalFooter = (
      <div className="flex w-full justify-between">
        <Button
          outlined
          text="Cancel"
          color="neutral"
          onClick={handleInvoiceModalClose}
          id="cancelInvoiceModalBtn"
          data-qa="cancel-btn"
        />
        <div className="flex">
          <ContactButton type="text">
            {externalInvoices.some((invoice) => invoice?.patient?.phone) && (
              <div
                data-qa="send-primary-text"
                onClick={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                <Icon icon="text-message" size="15px" />{' '}
                {finalGroupInvoices.length > 1
                  ? 'Primary phone'
                  : `${externalInvoices[0]?.patient?.phone} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_phone) && (
              // Check for alternative phone
              <AltContact
                type="phone"
                handleAlt={() =>
                  onSendText({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltPhone: true,
                    isPopover: true
                  })
                }>
                alternative
                {finalGroupInvoices.length > 1
                  ? 'Alternative phone'
                  : `${externalInvoices[0]?.patient?.alt_phone} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('text')} type="phone" />
          </ContactButton>

          <ContactButton type="email">
            {externalInvoices.some((invoice) => invoice?.patient?.email) && (
              <div
                data-qa="send-primary-email"
                onClick={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate
                  })
                }
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                {finalGroupInvoices.length > 1
                  ? 'Primary email'
                  : `${externalInvoices[0]?.patient?.email} (primary)`}
              </div>
            )}
            {externalInvoices.some((invoice) => invoice?.patient?.alt_email) && (
              // Check for alternative email
              <AltContact
                type="email"
                handleAlt={() =>
                  onSendEmail({
                    ids: externalInvoices.map((invoice) => invoice.id),
                    navigate,
                    toAltEmail: true,
                    isPopover: true
                  })
                }>
                {finalGroupInvoices.length > 1
                  ? 'Alternative email'
                  : `${externalInvoices[0]?.patient?.alt_email} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('email')} type="email" />
          </ContactButton>
        </div>
      </div>
    );
  }

  const getRowId = useCallback((params) => {
    return params?.data?.id;
  }, []);

  async function getPatients(patients) {
    const filters = {
      patients: {
        type: 'search',
        multiple: true,
        values: patients,
        title: 'Patients'
      }
    };

    const params = { filters: mapValues(filters) };
    const patientInvoices = await getPatientInvoices(params, navigate);
    setPatientInvoices(patientInvoices);
  }

  async function exportCSV() {
    const data = await getInternalInvoicesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'internal_invoices.csv';
    a.click();
  }

  function onPrev() {
    if (activeView.mode === 'preview') {
      setActiveView((prev) => ({ ...prev, mode: 'create', title: 'Create Invoice' }));
      return;
    }
    if (activeView.step === 0) {
      handleInvoiceModalClose();
      setInternalInvoice(null);
      return;
    }
    setActiveView((prev) => ({ ...prev, step: --prev.step }));
  }

  function onNext() {
    setActiveView((prev) => ({ ...prev, step: ++prev.step }));
  }

  function handleInvoiceModalOpen() {
    setInvoiceModalVisible(true);
  }

  function handleInvoiceModalClose() {
    setActiveView((prev) => ({ ...prev, mode: 'create', step: 0, title: 'Create Invoice' }));

    invoiceFormik.resetForm();

    setInvoiceModalVisible(false);
    setInternalInvoice(null);
    gridApi?.deselectAll();
  }

  const onRowClicked = async (e) => {
    const clickedInvoice = e.data;
    const selectedNodes = e.api.getSelectedNodes();

    // Deselect all preselected rows
    selectedNodes.forEach((node) => {
      node.setSelected(false);
    });

    // Select the clicked row
    e.node.setSelected(true);
    setSelectedRows([{ data: clickedInvoice }]);

    setInternalInvoice(clickedInvoice);
    setInvoiceModalVisible(true);
  };

  const onCellClicked = async (e) => {
    if (['0', 'actions', 'eraIds'].includes(e?.column?.colId)) return;

    if (e?.node?.group) {
      return;
    }
    await onRowClicked(e);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title="Electronic Invoicing" subtitle="Internal">
        <div className="flex flex-wrap items-center justify-end gap-4">
          <div className="flex gap-2">
            <DisplayButton />
            <Filter
              category={category}
              defaultFilters={getPracticeDefaultFilters(practice)}
              filters={filters}
              setFilters={setFilters}
              menuPortalTarget={document.body}
            />
            <ExportData
              title="Generate Internal Invoice report"
              defaultFilters={getPracticeDefaultFilters(practice)}
              filters={filters}
              onGenerateClick={exportCSV}
            />
            <Button
              disabled={selectedRows?.length > 0}
              size="small"
              outlined
              onClick={handleInvoiceModalOpen}
              text="New Invoice"
              data-qa="new-invoice-btn"
            />
            <Button
              disabled={!selectedRows?.length}
              size="small"
              data-qa="send-invoice-btn"
              onClick={handleInvoiceModalOpen}
              text={`Invoice patient${
                selectedRows?.length > 1 ? `s ( ${finalGroupInvoices.length} )` : ''
              }`}
            />
          </div>
        </div>
      </Header>

      {isGetInvoicesLoading || isFetching ? (
        <Skeleton count={limit} />
      ) : (
        <AGTable
          onCellClicked={onCellClicked}
          defaultColDef={DEFAULT_COLUMN_DEFS}
          columnDef={getColDefs(practiceTimezone)}
          gridOptions={GRID_OPTIONS}
          animateRows={false}
          getRowId={getRowId}
          data={invoices}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          isRowSelectable={() => true}
          customClassName="ag-grid-interactive"
          statusBar={statusBar}
        />
      )}
      {invoiceModalVisible ? (
        <InvoiceModal
          prevButton={prevButton}
          title={activeView.title}
          invoiceModalVisible={invoiceModalVisible}
          onClose={handleInvoiceModalClose}
          modalContent={
            <div ref={pageToPrintRef} className="h-full w-auto">
              {modalContent}
            </div>
          }
          footer={modalFooter}
          headButton={headButton}
        />
      ) : null}
      {customModalVisible ? (
        <CustomSendModal
          ids={externalInvoices.map((invoice) => invoice.id)}
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
        />
      ) : null}
    </ErrorBoundary>
  );
}

export default InternalInvoices;
