import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilState } from 'recoil';

import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import { interimApi } from '../../../../../api/InterimApi';
import { Capitalize, ia, iaRa, orderById } from '../../../../../lib/helpers/utility';
import Icon from '../../../../shared/Icon/Icon';
import Skeleton from '../../../../shared/Skeleton/Skeleton';
import { currentPractice } from '../../../practiceState';

const ProvidersDndList = () => {
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [state, setState] = useState({
    providers: [],
    selectedProviders: [],
    providersOrderAndId: practice?.display_settings?.calendar?.providerView?.selectedProviders || []
  });

  const navigate = useNavigate();

  const id2List = {
    droppable: 'providers',
    droppable2: 'selectedProviders'
  };

  const grid = 8;

  useEffect(() => {
    submitSelectProviders();
  }, [state.providersOrderAndId]);

  const { data: allPractitioners, isLoading } = usePractitioners();
  const practitioners = iaRa(allPractitioners?.practitioners);

  useEffect(() => {
    const recoilSelectedProviders =
      practice?.display_settings?.calendar?.providerView?.selectedProviders;

    if (ia(recoilSelectedProviders)) {
      const sortProviders = practitioners
        ?.filter((p) => !recoilSelectedProviders.some((fp) => fp.id === p.id))
        .sort((a, b) => a.f_name.localeCompare(b.f_name));

      const filtertSelectedProviders = practitioners?.filter((p) =>
        recoilSelectedProviders.find((fp) => fp.id === p.id)
      );

      const sortSelectedProviders = orderById(filtertSelectedProviders, recoilSelectedProviders);

      setState({
        ...state,
        providers: sortProviders,
        selectedProviders: sortSelectedProviders
      });
    } else {
      setState({
        ...state,
        selectedProviders: practitioners
      });
    }
  }, [practitioners]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(getList(source.droppableId), source.index, destination.index);

      let newState = { [id2List[source.droppableId]]: items };

      if (source.droppableId === 'droppable2') {
        const updateProvidersOrderAndId = iaRa(items).map((provider, idx) => ({
          id: provider.id,
          order: idx
        }));
        newState.providersOrderAndId = updateProvidersOrderAndId;
      }

      setState((prevState) => ({
        ...prevState,
        ...newState
      }));
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );

      let updatedProvidersOrderAndId = [...state.providersOrderAndId];
      if (destination.droppableId === 'droppable2') {
        updatedProvidersOrderAndId = iaRa(result.droppable2).map((provider, idx) => ({
          id: provider.id,
          order: idx
        }));
      } else if (source.droppableId === 'droppable2' && destination.droppableId === 'droppable') {
        updatedProvidersOrderAndId = iaRa(result.droppable2).map((provider, idx) => ({
          id: provider.id,
          order: idx
        }));
      }
      setState((prevState) => ({
        ...prevState,
        providers: result.droppable,
        selectedProviders: result.droppable2,
        providersOrderAndId: updatedProvidersOrderAndId
      }));
    }
  };

  const getList = (id) => {
    if (id === 'droppable') {
      return state.providers;
    } else if (id === 'droppable2') {
      return state.selectedProviders;
    }
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    border: isDragging ? '1px solid #13B9FF' : '1px solid #CDD5D8',
    background: isDragging ? '#B0EAFF' : 'white',
    padding: grid * 2,
    margin: `${grid}px 0 0`,
    ...draggableStyle
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver && '#E6F8FF',
    padding: grid,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 250,
    minHeight: 0,
    paddingRight: 2,
    width: 240
  });

  const submitSelectProviders = async () => {
    try {
      const display_settings = {
        ...practice.display_settings,
        calendar: {
          ...practice.display_settings.calendar,
          providerView: {
            ...practice.display_settings.calendar.providerView,
            selectedProviders: state.providersOrderAndId
          }
        }
      };
      const { data } = await interimApi(
        '/api/practice/update',
        { fields: { display_settings } },
        navigate
      );

      if (data?.updatedItems > 0) {
        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="border-b-0 border-l border-r-0 border-t-0 border-solid !border-neutral-100 border-inherit !pl-10">
      <label className="text-sm text-neutral-800">Provider view settings</label>
      <div className="flex gap-x-8 gap-y-7">
        <DragDropContext onDragEnd={onDragEnd}>
          <div>
            <Tippy
              content={
                <span>
                  Drag a provider to the right list to add them to your Provider View in the
                  calendar
                </span>
              }>
              <div className="flex items-center justify-between bg-neutral-50 !p-3 !pb-2 !pl-4">
                <span className="select-none text-sm font-500 text-neutral-800">Providers</span>
                <Icon icon="info" />
              </div>
            </Tippy>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className="h-full bg-neutral-50 !pr-2 pb-3 pl-3">
                  <div className="flex flex-col !pr-2">
                    {isLoading || state.providers === null ? (
                      <Skeleton height="36px" count={6} />
                    ) : state?.providers?.length === 0 ? (
                      <span className="text-sm font-500 text-neutral-800">No providers found!</span>
                    ) : (
                      iaRa(state?.providers).map((item, idx) => (
                        <Draggable key={item.id} draggableId={`item-${item.id}`} index={idx}>
                          {(provided) => (
                            <span
                              className="rounded-md !py-2 px-3 text-sm font-500 text-neutral-800"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}>
                              {`${Capitalize(item.f_name)} ${Capitalize(item.l_name)}`}
                            </span>
                          )}
                        </Draggable>
                      ))
                    )}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>

          <div>
            <Tippy
              content={
                <div className="inline-block items-center gap-1">
                  <span>
                    Order providers to determine their order in your calendar's Provider View.
                  </span>
                  <span className="pl-1 text-danger-500">At least one provider.</span>
                </div>
              }>
              <div className="flex items-center justify-between bg-neutral-50 !p-3 !pb-2 !pl-4">
                <span className="select-none text-sm font-500 text-neutral-800">
                  Selected providers
                </span>
                <Icon icon="info" />
              </div>
            </Tippy>
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={cs(
                    'h-full bg-neutral-50 !pr-2 pb-3 pl-3',
                    state?.selectedProviders?.length <= 1 && 'cursor-not-allowed'
                  )}>
                  <div className="flex flex-col !pr-2">
                    {isLoading || state.selectedProviders === null ? (
                      <Skeleton height="36px" count={6} />
                    ) : state?.selectedProviders?.length === 0 ? (
                      <span className="text-sm font-500 text-neutral-800">No providers found!</span>
                    ) : (
                      iaRa(state?.selectedProviders).map((item, idx) => (
                        <Draggable
                          key={item.id}
                          draggableId={`item-${item.id}`}
                          index={idx}
                          isDragDisabled={state?.selectedProviders?.length <= 1}>
                          {(provided, snapshot) => (
                            <span
                              className="rounded-md !py-2 px-3 text-sm font-500 text-neutral-800"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}>
                              {`${Capitalize(item.f_name)} ${Capitalize(item.l_name)}`}
                            </span>
                          )}
                        </Draggable>
                      ))
                    )}
                  </div>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
    </div>
  );
};

export default ProvidersDndList;
