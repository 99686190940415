import Actions from './components/Actions/Actions';
import Reset from './components/Reset';

const Footer = ({
  saveButton,
  setSaveButton,
  saveInput,
  setSaveInput,
  save,
  appliedFilter,
  filtersData,
  applyFilter,
  makeDefault,
  deleteFilter,
  resetFilter
}) => (
  <div className="flex w-full items-center justify-between gap-2 px-5 py-2 border-0 !border-b-0 !border-t border-solid !border-neutral-100 bg-white shadow-[0px_-2px_16px_rgba(0,0,0,0.06)]">
    <Actions
      saveButton={saveButton}
      setSaveButton={setSaveButton}
      saveInput={saveInput}
      setSaveInput={setSaveInput}
      save={save}
      appliedFilter={appliedFilter}
      filtersData={filtersData}
      applyFilter={applyFilter}
      makeDefault={makeDefault}
      deleteFilter={deleteFilter}
    />
    <Reset onClick={resetFilter} />
  </div>
);

export default Footer;
