import Tippy from '@tippyjs/react';

const PatientService = ({ data }) => {
  if (data?.isClaimRow) {
    return (
      <Tippy
        content={data.patientName ? 'View Chart' : null}
        placement="top"
        className="tippy-dark"
        disabled={!data.patientName}>
        <span
          className="hover:underline cursor-pointer"
          onClick={() => {
            if (data.patientName) {
              window.open(`/portal/charts/${data.pcn}/billing`, '_blank');
            }
          }}>
          {data.patientName ? data.patientName : `User ID: ${data.pcn}`}
        </span>
      </Tippy>
    );
  }
  return <span className="text-primary-700">{data?.procedure_code || ''}</span>;
};

export default PatientService;
