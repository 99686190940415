import React, { memo, useCallback, useMemo } from 'react';

import cs from 'classnames';

import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';

import Icon from 'components/shared/Icon/Icon';

import ChatRow from '../../shared/components/ChatRow';
import VirtualizedList from '../../shared/components/VirtualizedList';
import { transformData } from '../../shared/lib/helpers';
import { filterCheckedForms } from '../lib/helpers';

import { ChatFooter } from './ChatFooter';

let type;
const Chat = () => {
  const { chatForms = {}, componentRefs, scrollTo } = useMyTemplateContext();
  const chatSelectedForms = useMemo(() => filterCheckedForms(chatForms), [chatForms]);
  const formsList = useMemo(
    () => transformData(Object.values(chatSelectedForms)),
    [chatSelectedForms]
  );

  const renderRow = useCallback(
    (item, index, handleInputChange, inputValues) => {
      let head = null;

      if (item?.type !== type || index === 0) {
        type = item?.type;
        head = (
          <div className="py-1 pb-3 text-xl text-primary-500">{item?.typeLabel?.toUpperCase()}</div>
        );
      }

      return (
        <div
          className={cs(
            'mx-auto h-full w-2/3 py-2 md:w-3/4',
            index === formsList?.length - 1 && 'pb-[140px]'
          )}>
          {head}
          <ChatRow
            key={`${item.key}-${item.type}`}
            item={item}
            index={index}
            value={inputValues?.[index] || ''}
            onInputChange={handleInputChange}
            componentRefs={componentRefs}
            components={chatSelectedForms[item?.type]?.components}
          />
        </div>
      );
    },
    [formsList?.length, componentRefs, chatSelectedForms]
  );

  return (
    <div id="my-template-chat" className="overflow-y-none flex h-full flex-col">
      <Icon icon="chat-bg-pattern" className="!absolute left-[288px] right-0 top-0 z-[-1]" />
      {formsList?.length > 0 ? (
        <div className="flex-grow">
          <div className="mx-auto flex h-full w-full flex-col">
            <VirtualizedList data={formsList} renderItem={renderRow} scrollToIndex={scrollTo} />
          </div>
        </div>
      ) : (
        <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto">
          <Icon icon="choose-notes" />
          <h2 className="mb-4 text-xl text-[#004F6B]">Select type of note and choose forms</h2>
          <h4 className="mb-4 text-base text-[#6D828B]">
            You can start creating new clinical notes on chart details.
          </h4>
        </div>
      )}

      <div className="px-auto absolute bottom-[20px] z-[100] flex w-1/2 self-center bg-transparent">
        <ChatFooter formsList={formsList} />
      </div>
    </div>
  );
};

export default memo(Chat);
