import React from 'react';
import Accordion from '../../../../shared/Accordion';
import TimeFrames from './TimeFrames';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const TimeFramesSection = ({ components, sectionRef, options, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.timeFrames?.title}
      id={components?.timeFrames?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <TimeFrames sectionRef={sectionRef} />
    </Accordion>
  );
};

export default TimeFramesSection;
