import { ia, iaRa, spaceToKebabCase } from 'lib/helpers/utility';

export const processCNDisplaySettingsCustomForms = ({
  setDisplaySettings = () => null,
  data = []
}) => {
  if (!ia(data)) return;

  const customNotes = iaRa(data).reduce((acc, item) => {
    acc[spaceToKebabCase(item?.name)] = { enabled: true, custom: true };

    return acc;
  }, {});

  setDisplaySettings((prev) => {
    const filteredCustomNoteObjects = filterObjects(prev?.clinicalNote);
    const filteredCustomNoteKeys = Object.keys(filteredCustomNoteObjects ?? {});

    removeUnwantedCustomNote({
      newData: customNotes,
      currentData: filteredCustomNoteObjects,
      cnDisplaySettings: prev?.clinicalNote
    });

    const notes = {
      ...prev,
      clinicalNote: {
        ...customNotes,
        ...prev.clinicalNote
      }
    };

    return notes;
  });
};

const filterObjects = (obj) => {
  const filtered = {};
  for (const key in obj) {
    if (obj?.[key]?.custom === true) {
      filtered[key] = obj[key];
    }
  }
  return filtered;
};

const removeUnwantedCustomNote = ({ newData, currentData, cnDisplaySettings }) => {
  for (const key in currentData) {
    if (newData.hasOwnProperty(key)) continue;
    delete cnDisplaySettings?.[key];
  }
};
