import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useImperativeHandle } from 'react';
import { useReactToPrint } from 'react-to-print';
import { formatDate } from '../../../../../../lib/helpers/utility';
import Confirm from '../../../../../shared/Modal/Confirm/Confirm';
import RenderedComponents from './components/RenderedComponents';
import './components/style.scss';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const RenderedContent = ({
  isExport,
  printButtonRef = null,
  showConfirmationModal = false,
  setShowConfirmationModal = () => {},
  renderedRef,
  practice,
  provider,
  patient,
  appointmentDate,
  checkedRendered,
  hpForms,
  soapForms,
  advancedForms,
  sendEmailToPatient = () => {},
  loading
}) => {
  const { setIsPrinting } = useClinicalNoteContext();

  const print = useReactToPrint({
    content: () => renderedRef.current,
    onBeforeGetContent: async () => {
      setIsPrinting(true);
      await new Promise((resolve) => setTimeout(resolve, 2000));
    },

    onAfterPrint: () => {
      setIsPrinting(false);
    }
  });

  useImperativeHandle(printButtonRef, () => ({
    handlePrint: print
  }));
  return (
    <div className={cs('h-full grow overflow-hidden bg-neutral-200')}>
      <div
        className="print:!page-break-before flex h-full flex-col overflow-auto bg-[#F2F5F6]"
        ref={renderedRef}>
        <div className="flex items-center justify-between bg-white !py-4 !pl-6 !pr-4">
          {practice?.header_photo && (
            <div className="flex h-[90px] w-[140px] justify-start">
              <img
                className="!w-auto object-contain"
                src={`https://cdn.filestackcontent.com/${JSON.parse(practice?.header_photo).jpg}`}
              />
            </div>
          )}
          <div className="ml-auto grid">
            <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
              {practice.name}
            </p>
            <p className="text-right text-sm font-600 text-[#003A4B] print:text-xl">
              {provider?.fullName}
            </p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.address}</p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.phone}</p>
            <p className="text-right text-xs text-[#003A4B] print:text-base">{practice.email}</p>
          </div>
        </div>
        <div className="flex items-center justify-between bg-[#003A4B] !py-3 !pl-6 !pr-4">
          <div className="grid">
            <p className="text-sm font-600 !text-white print:text-xl">
              {patient?.fullName} {/* TODO: create full name for user state */}
            </p>
            <ul className="flex items-center gap-x-1">
              <li className="text-xs !text-white print:text-base">
                {moment(patient?.dob).format('MM/DD/YYYY')}
              </li>
              <span className="text-xs !text-white print:text-base">•</span>
              <li className="text-xs !text-white print:text-base">
                {moment.utc().diff(patient?.dob, 'years')} years
              </li>
              {patient?.gender && (
                <>
                  <span className="text-xs !text-white print:text-base">•</span>
                  <li className="text-xs capitalize !text-white print:text-base">
                    {patient?.gender}
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="grid">
            <p className="text-right text-sm font-600 !text-white print:text-xl">
              {formatDate(appointmentDate, practice?.timezone)}
            </p>
            <p className="text-right text-xs !text-white print:text-base">
              Chart ID: {patient?.id}
            </p>
          </div>
        </div>
        <div className="print-wrapper !mb-[10%] flex flex-col gap-[6px] whitespace-pre-line !outline-none print:!mb-0 print:break-inside-auto">
          <RenderedComponents
            checkedRendered={checkedRendered}
            hpForms={hpForms}
            soapForms={soapForms}
            advancedForms={advancedForms}
          />
        </div>
      </div>
      {showConfirmationModal && (
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={sendEmailToPatient}
          title="Send email to patient"
          message="Are you sure you want to send email to patient?"
          primaryBtnTxt="Send email to patient"
          secondaryBtnTxt="Close"
          icon="new-mail"
          loading={loading?.email}
        />
      )}
    </div>
  );
};

export default RenderedContent;
