import NotFound from 'components/practice/Dashboard/components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import numOrDefault from 'lib/helpers/numOrDefault';
import { formatLargeNumber } from 'lib/helpers/utility';
import React from 'react';

const Prime = ({ data }) => {
  const prices = JSON.parse(process.env.REACT_APP_PRICES);
  let primeEligCount = {
    new: data?.thisPeriodAndTotalCount?.periodEligibilityRequestPrime
  };
  const formattedData =
    data?.filteredEligibilityData?.map((dataPoint) => {
      const date = new Date(dataPoint.date);
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - date.getDay() + 1);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      const startDateString = startOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const endDateString = endOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const weekLabel = `${startDateString}-${endDateString}`;
      return {
        ...dataPoint,
        date: weekLabel
      };
    }) ?? [];
  const options = {
    data: formattedData,
    height: 270,
    series: [
      {
        type: 'bar',
        xKey: 'date',
        yKey: 'eligibility_request_prime',
        fill: '#13B9FF',
        stroke: '#004F6B',
        cornerRadius: 15
      }
    ],
    axes: [
      {
        type: 'category',
        nice: true,
        position: 'bottom'
      },
      {
        position: 'left'
      }
    ],
    theme: 'ag-material'
  };

  return (
    <div>
      {data?.filteredEligibilityData[0]?.eligibility_request_prime ? (
        <>
          <div className="flex items-center justify-between !px-5 pt-[14px]">
            <div className="flex items-center justify-center">
              <p className="text-md text-neutral-500">This Period: </p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {formatLargeNumber(primeEligCount?.new)}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <p className="text-md pl-3 text-neutral-500">Cost</p>
              <div className="pl-3 text-xl font-bold text-primary-900">
                {`$${numOrDefault(primeEligCount?.new * prices.elig_prime).toFixed(2) || '0'}`}
              </div>
            </div>
          </div>
          <AGChart options={options} />
        </>
      ) : (
        <>
          <NotFound
            icon="new-illustration-6"
            title="You don't have eligibilty prime requests yet"
          />
        </>
      )}
    </div>
  );
};

export default Prime;
