import { getCptCodes } from 'api/CptCodes';
import { getIcd10Codes } from 'api/Icd10';
import { getPatients } from 'api/Patients';

import { iaRa } from 'lib/helpers/utility';

const searchICD10Codes = async (searchTerm, navigate) => {
  const codes = await getIcd10Codes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.icd10.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

const searchCPTCodes = async (searchTerm, navigate) => {
  const codes = await getCptCodes(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return codes?.cpt.map((c) => ({
    label: `${c?.code} ${c?.name}`,
    value: c?.code
  }));
};

const treatmentPlanStatus = [
  {
    value: 'signed',
    label: 'Signed'
  },
  {
    value: 'awaiting_signatures',
    label: 'Awaiting Signatures'
  }
];

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return iaRa(patients?.patients)?.map((p) => ({
    label: p?.fullName,
    value: p?.id
  }));
};
export const DEFAULT_FILTERS = {
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  patient: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patient',
    placeholder: 'Search Patient',
    preview: (values) => values?.map((v) => v?.label).join(', ')
  },
  cpt: {
    type: 'search',
    multiple: true,
    loadOptions: searchCPTCodes,
    values: [],
    title: 'Services',
    preview: (values) => values?.map((v) => v?.label).join(', ')
  },
  icd: {
    type: 'search',
    multiple: true,
    loadOptions: searchICD10Codes,
    values: [],
    title: 'Diagnosis',
    preview: (values) => values?.map((v) => v?.label).join(', ')
  }
};

export const formatFilters = (mapValues) => {
  const formattedFilters = {};
  Object.keys(mapValues).forEach((key) => {
    const filter = mapValues[key];
    if (filter?.values) {
      formattedFilters[key] = iaRa(filter?.values).map((v) => v?.value);
    } else {
      formattedFilters[key] = filter;
    }
  });
  return formattedFilters;
};
