import { useMutation } from '@tanstack/react-query';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { onSendEmail, onSendText } from '../../lib/utils';
import CustomContactForm from './CustomContactForm';
import customValidationSchema from './customValidationSchema';

const initialSend = {
  loading: false,
  label: 'Send'
};

const content = {
  email: {
    title: 'Sending Invoices to a Custom Email Address',
    message:
      'Attention: You are about to email one or more invoices to a custom email address instead of the one currently on file.',
    disclaimer: {
      title: 'Important Notice',
      content:
        'These invoices contain confidential information, including procedure codes and names. You are responsible for ensuring that this information is shared securely and appropriately. If you are confident that the custom email address is accurate and secure, please click "Send email". Otherwise, please review and correct the recipient information.'
    }
  },
  text: {
    title: 'Sending Invoices to a Custom Phone Number',
    message:
      'Attention: You are about to send one or more invoices to a custom phone number instead of the one currently on file.',
    disclaimer: {
      title: 'Important Notice',
      content:
        'These invoices contain confidential information, including procedure codes and names. You are responsible for ensuring that this information is shared securely and appropriately. If you are confident that the custom phone number is accurate and secure, please click "Send text". Otherwise, please review and correct the recipient information.'
    }
  },
  confirmation: 'Please confirm your intention to proceed.',

  lastQuestion: 'Do you wish to continue?'
};
export function CustomSendModal({ visible, onClose = () => {}, ids, contactType = 'email' }) {
  const navigate = useNavigate();

  const [sendButton, setSendButton] = useState(initialSend);

  const { mutate: send, isLoading: sendLoading } = useMutation({
    mutationFn:
      contactType === 'email'
        ? (data) =>
            onSendEmail({
              ids,
              setButton: setSendButton,
              navigate,
              customEmail: data
            })
        : (data) =>
            onSendText({
              ids,
              setButton: setSendButton,
              navigate,
              customPhone: data
            })
  });

  const customContactForm = useFormik({
    initialValues: {
      customContact: '',
      contactType
    },
    validationSchema: customValidationSchema,
    onSubmit: (values) => {
      const customContact = values?.customContact;
      send(customContact, {
        onSuccess: () => {
          onClose();
        }
      });
    }
  });

  return (
    <Confirm
      variant="warning"
      handleOpen={visible}
      handleClose={onClose}
      handleContinue={customContactForm.handleSubmit}
      title={content?.[contactType]?.title}
      primaryBtnTxt={`Send ${contactType}`}
      icon="new-info"
      loading={sendButton.loading}>
      <div className="mt-3">
        <CustomContactForm formik={customContactForm} contactType={contactType} />
      </div>
      <div className="mt-3">
        <em className="text-xs font-bold text-slate-500">
          {content?.[contactType]?.disclaimer?.title}:
        </em>
        <em className="text-xs text-slate-500">{content?.[contactType]?.disclaimer?.content}</em>
      </div>
    </Confirm>
  );
}
