import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import { useRecoilState } from 'recoil';

import { useDocumentSign } from 'lib/hooks/queries/documentSign/useDocumentSign';

import { updateDocumentSign } from '../../../../../../api/DocumentSign';
import { updateUser } from '../../../../../../api/User';
import { iaRa, isEmpty } from '../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../shared/Alert/Alert';
import Confirm from '../../../../../shared/Modal/Confirm/Confirm';
import { clinicalNote as clinicalNoteState, userState } from '../../../../../state';

import SignPad from './SignPad';
import SignPadAndLockPad from './components/SignAndLockPad';

const defaultConfirmModalProps = {
  title: 'Sign treatment plan',
  message: 'Are you sure you want to sign this treatment plan?',
  primaryBtnTxt: 'Sign treatment plan',
  secondaryBtnTxt: 'Cancel',
  icon: 'new-document-sign'
};

const SignModal = ({
  documentId,
  documentTypes = ['treatment_plan'],
  documentSignatures = [],
  setShowConfirmationModal = () => null,
  showConfirmationModal,
  overrideStatus = null,
  alertUsers = true,
  onDocumentSignSuccess = null,
  confirmModalProps = {},
  onlyReturnSignatureProps = false,
  returnedData = () => null,
  showInPopover = true,
  signAndLockButtonRef = null,
  fromOutsideClinicalNote = false,
  classNameSignModal = null,
  outsideCN = false
}) => {
  const { id } = useParams();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const [user, setUser] = useRecoilState(userState);
  const sigPad = useRef(null);
  const [signature, setSignature] = useState(null);
  const [updateSignature, setUpdateSignature] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentSignaturesData, setDocumentSignaturesData] = useState(iaRa(documentSignatures));

  const isCurrentUserCNAuthor = (user?.id ?? user?.user_id) == clinicalNote?.practitioner_id;

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isEmpty(user?.signature)) setSignature(user.signature);
  }, [user?.signature]);

  const queryClient = useQueryClient();

  useDocumentSign({
    params: {
      documentIds: [clinicalNote?.id],
      withRelations: { user: true }
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!fromOutsideClinicalNote && !!clinicalNote?.id,
      onSettled: (data) => {
        const readyData = iaRa(data?.data);

        setClinicalNote((prev) => ({ ...prev, document_sign: readyData }));
        setDocumentSignaturesData(readyData);
      }
    }
  });

  const updateDocumentSignMutation = useMutation((data) => updateDocumentSign(navigate, data), {
    onSuccess: (data) => {
      if (data.code === 7) {
        showAlert({
          title: "You're not assigned to sign this document!",
          color: 'danger'
        });
        return;
      }

      showAlert({
        title: 'Success!',
        message: `Document signed successfully!`,
        color: 'success'
      });
      queryClient.invalidateQueries(['documentSign']);

      if (documentTypes?.[0] === 'treatment_plan' && outsideCN) {
        const params = { patientId: id, withRelations: { createdBy: true, document_sign: true } };
        queryClient.invalidateQueries(['treatmentPlan', [params]]);
      }

      onDocumentSignSuccess && onDocumentSignSuccess(data);
    }
  });

  const updateUserMutation = useMutation((data) => updateUser(navigate, data), {
    onSuccess: (data) => {
      if (data.code !== 0) {
        showAlert({
          title: 'Signature Error!',
          message: 'Something went wrong. Please try again!',
          color: 'danger'
        });

        return;
      }

      setUser((prev) => ({ ...prev, signature }));
    }
  });

  const handleContinue = async () => {
    setLoading(true);
    let trimmedSignature = signature;

    if (!signature) trimmedSignature = trimSignature();

    if (!trimmedSignature) {
      showAlert({
        title: 'Signature Error!',
        message: "You can't save a blank signature!",
        color: 'danger'
      });

      setLoading(false);
      return;
    }

    if (updateSignature) {
      await updateUserMutation.mutateAsync({
        user_id: user.id,
        changes: { signature: trimmedSignature }
      });
    }

    const signatureData = {
      documentId,
      documentTypes,
      signature: trimmedSignature,
      currentUser: user.id,
      link: user.kind === 'patient' ? `/portal/charts/${user.id}/clinical-notes` : pathname,
      overrideStatus,
      alertUsers
    };

    if (!onlyReturnSignatureProps) {
      await updateDocumentSignMutation.mutateAsync(signatureData);
    } else {
      returnedData(signatureData);
    }

    setLoading(false);
    setShowConfirmationModal(false);
  };

  useImperativeHandle(signAndLockButtonRef, () => {
    return {
      handleContinue
    };
  });

  const trimSignature = () => {
    if (sigPad?.current) {
      if (sigPad.current?.isEmpty()) {
        return;
      }

      const trimmedSignature = sigPad.current?.getTrimmedCanvas().toDataURL('image/png');
      setSignature(trimmedSignature);

      return trimmedSignature;
    }
  };

  const clearSignature = (e) => {
    e.preventDefault();

    if (sigPad?.current) sigPad.current?.clear();

    setSignature(null);
  };

  const showAddCoSigners = isCurrentUserCNAuthor && documentTypes?.[0] === 'clinical_note';

  const DisplayType = ({ children }) => {
    if (showInPopover) {
      return (
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={handleContinue}
          {...defaultConfirmModalProps}
          {...confirmModalProps}
          overlayClassName="z-[300]"
          loading={loading}>
          {children}
        </Confirm>
      );
    }

    return children;
  };

  const SignPadType = () => {
    const signPadProps = {
      user,
      clearSignature,
      sigPad,
      signature,
      updateSignature,
      setUpdateSignature,
      documentSignaturesData,
      showAddCoSigners
    };

    if (showAddCoSigners) {
      return <SignPadAndLockPad {...signPadProps} />;
    }

    return <SignPad {...signPadProps} />;
  };

  return (
    <DisplayType>
      <div className={cs('mt-6', classNameSignModal)}>{SignPadType()}</div>
    </DisplayType>
  );
};

export default SignModal;
