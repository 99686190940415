import { requestApi } from '../Api';

export const adminTrackClick = async ({ navigate, params, onSuccess }) =>
  requestApi({
    navigate,
    url: '/api/admin/search/upsert',
    params,
    onSuccess
  });

export const getAdminMostClickedSearches = async (navigate, params) => {
  const a = await requestApi({
    navigate,
    url: '/api/admin/search/retrieve_top_searches',
    params
  });
  return a;
};
