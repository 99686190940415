import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';

const ExistingCNConfirm = ({
  patientId,
  setShowCreateConfirm,
  todayNotes,
  note,
  setNote,
  showLoadOrCreateConfirm,
  setShowLoadOrCreateConfirm
}) => {
  const navigate = useNavigate();

  const navigateToNote = () => {
    navigate(`/portal/charts/${patientId}/clinical-notes/${note.value}`);
  };

  const createNewNote = () => {
    setShowLoadOrCreateConfirm(false);
    setShowCreateConfirm(true);
  };

  const closeHandler = () => {
    setShowLoadOrCreateConfirm(false);
  };

  return (
    <Confirm
      handleOpen={showLoadOrCreateConfirm}
      handleClose={closeHandler}
      title="Today's Note Already Exists"
      icon="new-notev2"
      isFooter={false}
      bodyClassName="!pb-[0px]">
      <p className="whitespace-pre-line text-sm text-neutral-600">
        You already have a clinical note for today. Would you like to navigate to the existing note
        or create a new one?
      </p>
      <Select
        className="mt-3"
        inputId="provider"
        data-qa="appointment-practitioner-select"
        options={todayNotes}
        value={note}
        onChange={(e) => setNote(e)}
        isClearable={false}
        noDropdown={todayNotes?.length === 1}
      />

      <div className="-mr-[12px] flex justify-end gap-x-3 pt-8">
        <Button
          outlined
          color="neutral"
          onClick={createNewNote}
          text="Create New Note"
          data-qa="close-confirm-modal-btn"
        />

        <Button
          data-qa="proceed-confirm-modal-btn"
          color="primary"
          text="Navigate to Note"
          onClick={navigateToNote}
        />
      </div>
    </Confirm>
  );
};

export default ExistingCNConfirm;
