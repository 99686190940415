import React, { useState } from 'react';
import { useFormikContext } from 'formik';
import { createInstantPacket } from 'api/InstantPacket';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import CustomFormsModal from 'components/shared/Modal/CustomFormsModal/CustomFormsModal';
import HippaModal from '../components/HippaModal';
import PersonalInfoModal from '../components/Personalnfo/PersonalInfoModal';
import { useQueryClient } from '@tanstack/react-query';
import PersonalIdModal from '../components/PersonalIdModal';
import FormPacketModalEdit from '../components/FormPacketModalEdit';
import { useKiosks } from 'lib/hooks/queries/useKiosks';
import Insurance from './components/Insurance';
import PatientForms from './components/PatientForms';
import InsurancePriorAuth from './components/InsurancePriorAuth';
import PaymentModal from '../components/PaymentModal';

const Checkin = ({ data = {} }) => {
  const [formModalVisible, setFormModalVisible] = useState({
    hipaa: false,
    insurance: false,
    payment: false,
    'personal-id': false,
    'personal-info': false,
    formPacketModal: false
  });
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedInsurance, setSelectedInsurance] = useState(null);
  const [step, setStep] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { values } = useFormikContext();

  const { data: kiosksQuery } = useKiosks({ params: {} });
  const kiosks = kiosksQuery?.kiosks || [];

  const sendFormsToKiosk = async (forms, kioskId) => {
    const packet = await createInstantPacket(navigate, {
      appointmentId: values.id,
      kiosk: true,
      patientId: values?.patient?.id,
      formIds: forms
    });

    await requestApi({
      url: '/api/kiosk/command',
      params: {
        url: packet?.token,
        command: 'url-change',
        page: 'form',
        kioskId,
        patientId: values?.patient?.id
      }
    });

    showAlert({ title: 'Sent to Kiosk', color: 'success' });
  };

  const selectForm = async (formStep) => {
    if (!formStep) {
      setSelectedForm(formStep);
      return;
    }

    setSelectedForm(null);

    const onSuccess = (partData) => {
      const lForm = partData?.form;
      setSelectedForm(lForm);
    };

    await requestApi({
      url: '/api/patient/checkin/form/getResponse',
      params: { formId: formStep?.form_id, appointmentId: values?.id },
      onSuccess
    });
  };

  const refetchDetails = async () => {
    await queryClient.invalidateQueries(['apt-timeline-details']);
  };

  const handleSelectInsurance = (insurance) => {
    setFormModalVisible({ ...formModalVisible, ['personal-info']: true });
    setSelectedInsurance(insurance);
  };

  const personalInfoModalVisible =
    formModalVisible?.['personal-info'] || formModalVisible.insurance;

  return (
    <div className="relative">
      <PatientForms
        data={data}
        kiosks={kiosks}
        sendFormsToKiosk={sendFormsToKiosk}
        selectForm={selectForm}
        setStep={setStep}
        setFormModalVisible={setFormModalVisible}
        formModalVisible={formModalVisible}
      />
      <Insurance data={data} handleSelectInsurance={handleSelectInsurance} />
      {values.checkin?.prior_auth_needed && <InsurancePriorAuth />}

      {formModalVisible.hipaa && step && (
        <HippaModal
          appointment={values}
          modalVisible={formModalVisible.hipaa}
          setModalUnVisible={() => setFormModalVisible({ ...formModalVisible, hipaa: false })}
        />
      )}
      {personalInfoModalVisible && (
        <PersonalInfoModal
          patient_id={values?.patient.id}
          modalVisible={personalInfoModalVisible}
          setModalUnVisible={() =>
            setFormModalVisible({ ...formModalVisible, ['personal-info']: false, insurance: false })
          }
          selectedInsurance={selectedInsurance}
          showInsurance={formModalVisible?.insurance}
        />
      )}

      {formModalVisible['personal-id'] && (
        <PersonalIdModal
          modalVisible={formModalVisible['personal-id']}
          setModalUnVisible={() =>
            setFormModalVisible({ ...formModalVisible, ['personal-id']: false })
          }
        />
      )}

      {formModalVisible.formPacketModal && (
        <FormPacketModalEdit
          showFormPacketModal={formModalVisible.formPacketModal}
          hideFormPacketModal={() =>
            setFormModalVisible({ ...formModalVisible, formPacketModal: false })
          }
        />
      )}

      {selectedForm && (
        <CustomFormsModal
          sendFormsToKiosk={sendFormsToKiosk}
          showCustomForms={!!selectedForm}
          hideCustomForms={() => selectForm(null)}
          selectedForm={selectedForm}
          appointment={values}
          kiosks={kiosks}
          appendParentSelector={false}
          invalidateQuery={refetchDetails}
          className="w-[1200px]"
        />
      )}
      {formModalVisible.payment && (
        <PaymentModal
          modalVisible={true}
          setModalUnVisible={() => setFormModalVisible({ ...formModalVisible, payment: false })}
          appointmentId={values.id}
        />
      )}
    </div>
  );
};

export default Checkin;
