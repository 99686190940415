import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import SimpleTable from '../../../../../../shared/Forms/Custom/ProviderMedications/components/SimpleTable';
import { stripHtmlTags } from 'lib/helpers/utility';

const renderAllergyValue = (item, type) => {
  if (!item[type]?.value) return 'N/A';

  return item[type]?.value;
};

const renderNoteValue = (item) => {
  if (!item?.note) return 'N/A';

  return stripHtmlTags(item.note);
};

const cols = [
  {
    headerName: 'Allergy',
    cellRenderer: ({ data }) => {
      return renderAllergyValue(data, 'allergy');
    }
  },
  {
    headerName: 'Medication provided',
    cellRenderer: ({ data }) => {
      return renderAllergyValue(data, 'medication');
    }
  },
  {
    headerName: 'Note',
    cellRenderer: ({ data }) => {
      return renderNoteValue(data);
    }
  }
];

const AllergiesSimpleTable = ({ data, emptyState = 'No allergies' }) => {
  return (
    <TableContextProvider name="allergies_simple_table" defaultFilters={[]} cols={cols}>
      <SimpleTable data={data} emptyState={emptyState} />
    </TableContextProvider>
  );
};

export default AllergiesSimpleTable;
