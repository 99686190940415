import React from 'react';
import cs from 'classnames';
import Icon from '../../Icon/Icon';

const RightComponents = ({
  rightText,
  rightTextClassName,
  rightIcon,
  rightIconClick = () => {},
  rightSecondaryIcon,
  actionButtonText,
  actionButtonIcon
}) => {
  const hasRightComponent = rightText || rightIcon || rightSecondaryIcon;
  const hasActionButton = actionButtonText || actionButtonIcon;

  if (!hasRightComponent || hasActionButton) return null;

  return (
    <div className="flex shrink-0 items-center gap-x-1">
      {rightText && (
        <p className={cs('mb-0 text-sm text-neutral-600', rightTextClassName)}>{rightText}</p>
      )}
      {rightSecondaryIcon && <Icon icon={rightSecondaryIcon} className="m-0" />}
      {rightIcon && <Icon icon={rightIcon} onClick={rightIconClick} className="m-0" />}
    </div>
  );
};

export default RightComponents;
