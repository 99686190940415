import React, { forwardRef } from 'react';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';

import { useLog } from 'lib/hooks/queries/admin/useLog';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Spinner from 'components/shared/spinner/spinner';

const JsonTreeView = forwardRef(({ src }, ref) => {
  const id = src;

  const { data, isLoading } = useLog({
    params: { logId: id },
    dependencies: [id]
  });

  return (
    <div ref={ref}>
      {isLoading ? (
        <Spinner />
      ) : (
        <JsonView
          className="json-tree-view"
          src={() => {
            try {
              return { ...data?.log, data: JSON.parse(data?.log?.data) };
            } catch (e) {
              return data?.log || {};
            }
          }}
          collapsed={1}
        />
      )}
    </div>
  );
});

export default withErrorBoundary(JsonTreeView);
