import React, { useEffect } from 'react';

import { iaRa, optionify } from 'lib/helpers/utility';
import { useReferenceLabs } from 'lib/hooks/queries/LabSoftOrder/useReferenceLabs';

import Select from 'components/shared/Select/Select';

const baseOption = { label: 'All labs', value: '' };

const LabSelect = ({ selectedLab, changeHandler }) => {
  const {
    data: options,
    isLoading,
    isFetching
  } = useReferenceLabs({
    options: {
      initialValues: { data: [options] },
      select: (data) => {
        const options = optionify(iaRa(data?.data), 'displayName', 'orderCodePrefix');

        options?.unshift(baseOption);

        return options;
      }
    }
  });

  useEffect(() => {
    if (!selectedLab && options) {
      changeHandler('selectedLab', options[0]);
    }
  }, [options]);

  return (
    <Select
      label="Lab filter:"
      options={options}
      isLoading={isLoading || isFetching}
      value={selectedLab}
      isClearable={false}
      onChange={(option) => changeHandler('selectedLab', option)}
    />
  );
};

export default LabSelect;
