import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { capitalize } from 'lodash';

import { confirmKioskCode } from '../../../../../api/Kiosk';
import { AlertContent } from '../../../../shared/Alert/Alert';
import ColorPicker from '../../../../shared/ColorPicker/ColorPicker';
import Input from '../../../../shared/Input/Input';
import Select from '../../../../shared/Select/Select';
import Textarea from '../../../../shared/Textarea/Textarea';
import VerificationCode from '../../../../shared/VerificationCode/VerificationCode';

export default function NewKiosk({ formik, status, setStatus, setConfirmedKiosk, practitioners }) {
  const navigate = useNavigate();
  const outletContext = useOutletContext();

  const [alert, setAlert] = useState(true);

  const submitCode = async (newCode) => {
    setStatus('loading');
    const data = await confirmKioskCode(navigate, {
      practiceId: outletContext?.practiceId,
      code: newCode
    });
    if (data?.kiosk) {
      setStatus('success');
      setConfirmedKiosk(data.kiosk);
    } else {
      setStatus('error');
    }
  };

  const statuses = [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'Inactive'
    }
  ];

  return (
    <div className="flex flex-col gap-2">
      {alert && (
        <AlertContent
          className="!w-full flex-wrap"
          title="To add a new kiosk please prompt a verification code from the kiosk and enter below."
          handleClose={() => setAlert(false)}
        />
      )}
      <div className="flex flex-col gap-2 !rounded-lg bg-white !p-4 !px-5">
        <p className="text-neutral-700 ">Enter verification code:</p>
        <VerificationCode onComplete={submitCode} status={status} setStatus={setStatus} />
      </div>

      <div className="flex gap-2">
        <ColorPicker
          color={formik?.values?.color}
          onChange={(color) => formik.setFieldValue('color', color)}
        />
        <Input
          name="name"
          className="flex-1"
          label="Name"
          type="text"
          placeholder="Enter Kiosk name"
          required
          value={formik?.values?.name}
          error={formik?.errors?.name}
          onChange={formik && formik?.handleChange}
        />
        <Select
          name="status"
          label="Status"
          className="flex-1"
          options={statuses}
          type="text"
          placeholder="Type and select Kiosk status"
          required
          value={formik?.values?.status ? { label: capitalize(formik?.values?.status) } : null}
          error={formik?.errors?.status}
          isClearable={false}
          onChange={({ value }) => formik?.setFieldValue('status', value)}
        />
      </div>

      <Textarea
        name="description"
        label="Description"
        placeholder="Enter Kiosk description"
        textareaClassName="!h-24"
        rows={3}
        value={formik?.values?.description}
        onChange={formik && formik?.handleChange}
      />

      <Select
        name="notify_users"
        label="Users that will be notified for this kiosk's requests"
        className="flex-1"
        isMulti
        options={practitioners}
        type="text"
        placeholder="Type and select Members"
        value={formik?.values?.notify_users}
        error={formik?.errors?.notify_users}
        isClearable={false}
        onChange={(value) => formik?.setFieldValue('notify_users', value)}
      />
    </div>
  );
}
