import React from 'react';
import AIPrompt from './AIPrompt';
import UserPrompt from './UserPrompt';
import { useMyScribeTemplateContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';

const PromptMessages = () => {
  const { currentActivePrompt } = useMyScribeTemplateContextData();

  return (
    <div className="mx-auto max-w-[760px] pt-[16px]">
      <>
        <UserPrompt message={currentActivePrompt?.userPrompt} />
        <AIPrompt message={currentActivePrompt?.response} />
      </>
    </div>
  );
};

export default PromptMessages;
