import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteEob, finalizeEob, unAllocateEob } from 'api/ERA';

import { iaRa } from 'lib/helpers/utility';
import { useEra } from 'lib/hooks/queries/era/useEra';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';

import { ACTION_CONFIRMATION_MESSAGES } from '../lib/helpers';

import ClaimsData from './ClaimsData/ClaimsData';
import NewEOB from './NewEOB/NewEOB';

const Actions = ({ data, node }) => {
  const navigate = useNavigate();
  const [loadData, setLoadData] = useState(false);
  const [selectedClaims, setSelectedClaims] = useState([]);

  const { isOpen, openModal, closeModal } = useModal();
  const {
    isOpen: isDeleteConfirmOpen,
    openModal: openDeleteConfirm,
    closeModal: closeDeleteConfirm
  } = useModal();

  const isDeleting = isDeleteConfirmOpen === 'delete';

  const isFinalized = data?.finalized;
  const canUnAllocate = data.unallocated_amount_cents < data.total_paid;
  const { title, message } =
    ACTION_CONFIRMATION_MESSAGES?.(selectedClaims)?.[isDeleteConfirmOpen] ?? {};

  const { data: eraData, isLoading: isEraLoading } = useEra({
    params: { id: data?.id },
    dependencies: [data?.id],
    options: {
      enabled: !!data?.id && loadData,
      onSuccess: (data) => {
        const initialSelectedClaims = iaRa(data?.era?.claims).map((claim) => claim.id);
        setSelectedClaims(initialSelectedClaims);
      }
    }
  });

  const mutateFinalizeEOB = useCustomMutation({
    invalidateQueryKey: 'eobs',
    successMessage: 'EOB finalized successfully!',
    errorMessage: 'Failed to finalize EOB!',
    mutationFn: ({ eobId, finalized }) => finalizeEob(navigate, { eobId, fields: { finalized } })
  });

  const mutateDeleteEOB = useCustomMutation({
    invalidateQueryKey: ['eobs', 'claims'],
    successMessage: 'EOB deleted successfully!',
    errorMessage: 'Failed to delete EOB!',
    mutationFn: ({ eobId }) => deleteEob(navigate, { eobId })
  });

  const handleDeleteConfirm = () => {
    mutateDeleteEOB.mutate({ eobId: data.id });
  };

  const mutateUnAllocateEOB = useCustomMutation({
    invalidateQueryKey: ['eobs', 'claims'],
    successMessage: 'EOB unallocated successfully!',
    errorMessage: 'Failed to unallocate EOB!',
    mutationFn: ({ eobId, claims }) => unAllocateEob(navigate, { eobId, claims })
  });

  const handleUnAllocateEOB = () => {
    mutateUnAllocateEOB.mutate({ eobId: data.id, claims: selectedClaims });
  };
  const options = [
    { label: 'Edit', onClick: openModal, icon: 'new-edit' },
    {
      label: isFinalized ? 'Mark Pending' : 'Mark Finalized',
      onClick: () => {
        mutateFinalizeEOB.mutate({ eobId: data.id, finalized: !isFinalized });
      },
      icon: isFinalized ? 'appointment-pending' : 'checkmark'
    },
    ...(canUnAllocate
      ? [
          {
            label: 'Unallocate',
            onClick: () => {
              setLoadData(true);
              openDeleteConfirm('unAllocate');
            },
            icon: 'rotate-ccw-filled'
          }
        ]
      : []),
    {
      label: 'Delete',
      onClick: () => {
        setLoadData(true);
        openDeleteConfirm('delete');
      },
      icon: 'trash'
    }
  ];

  const handleContinueConfirmation = () => {
    if (isDeleting) {
      handleDeleteConfirm();
    } else {
      handleUnAllocateEOB();
    }
  };

  if (node?.group) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />

      {isOpen && (
        <NewEOB isOpen={isOpen} eob={data} handleClose={closeModal} showInfoInRecordings={true} />
      )}

      <Confirm
        isOpen={isDeleteConfirmOpen}
        handleClose={closeDeleteConfirm}
        handleContinue={handleContinueConfirmation}
        disabled={isEraLoading || (!isDeleting && !selectedClaims.length)}
        variant="danger"
        icon="new-receipt-3"
        iconColor="danger"
        title={title}
        message={message}>
        {!isDeleting && (
          <ClaimsData
            claims={eraData?.era?.claims}
            selectedClaims={selectedClaims}
            setSelectedClaims={setSelectedClaims}
            loading={isEraLoading}
          />
        )}
      </Confirm>
    </div>
  );
};

export default Actions;
