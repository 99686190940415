import React from 'react';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { getColDefs } from './configs';
import { Table } from './Table';

const TreatmentPlanTable = ({ breadcrumb, onAddComponent }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider name="treatment_plan" cols={getColDefs(practiceTimezone)}>
      <Table breadcrumb={breadcrumb} onAddComponent={onAddComponent} />
    </TableContextProvider>
  );
};

export default withErrorBoundary(TreatmentPlanTable);
