import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { formatPhoneNumber, ia } from '../../../../lib/helpers/utility';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import schedulingState from '../schedulingState';
import './css/PracticeStep.scss';

export default function PracticeStep() {
  const { setSidebarItems } = useOutletContext();
  const [checked, setChecked] = useState(false);
  const [visitedPractices, setVisitedPractices] = useState([]);
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const queryParam = params.get('token');

  useEffect(() => {
    if (queryParam || appointmentData?.savedToken) {
      getPracticePatientInfo(queryParam || appointmentData?.savedToken);
    } else {
      getPracticePatientInfo();
    }
  }, [appointmentData]);

  useEffect(() => {
    if (checked) {
      getStatus(checked);
    }
  }, [checked]);

  const selectedPractice = (practice, type) => {
    setChecked(practice);
    if (checked && practice?.id == checked?.id && type == 'button') {
      setChecked(false);
    }
  };

  const getStatus = async (practice) => {
    try {
      const { status, data } = await interimApi(
        '/api/patient/appointment/self_schedule',
        {
          practice_id: practice.id,
          practice_name: practice.name,
          practice_timezone: practice.timezone,
          practice_instant_booking: practice.instant_booking,
          practice_phone: practice.phone,
          practice_require_card: practice.self_scheduling?.require_card_on_file
        },
        navigate
      );
      if (status == 200) {
        !data?.object?.practice_require_card &&
          setSidebarItems((prevState) => prevState.filter((step) => step.id !== 5));

        setAppointmentData(data?.object || {});
        navigate('/book/service');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getPracticePatientInfo = async (token) => {
    try {
      let res = null;
      if (token) {
        res = await interimApi('/api/practice/patient/link/get', { token: token }, navigate);
      } else {
        res = await interimApi('/api/practice/patient/link/get', {}, navigate);
      }
      const { code, redirect, error, patientLink } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(patientLink, 1) || appointmentData?.practice_id) {
            setVisitedPractices(patientLink);
          } else {
            const practice = patientLink[0];
            await getStatus(practice);
          }
          break;
        default:
          Honeybadger.notify(
            `file: self_scheduling/steps/DateAndTimeStep, method: getPracticePatientInfo - try, error: ${error ?? 'Theres been an error'
            }`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: self_scheduling/steps/DateAndTimeStep, method: getPracticePatientInfo - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    }
  };

  return (
    <>
      {ia(visitedPractices) ? (
        <div className="w-full">
          <div className='PracticeContainer'>
            {visitedPractices?.map((practice, idx) => {
              return (
                <div key={idx} className='VisitedPracticesContainer'>
                  <div className='VisitedPracticesContent'>
                    <h4>{practice?.name}</h4>
                    <p>{practice?.email}</p>
                    <p>{formatPhoneNumber(practice?.phone)}</p>
                    <p>{practice?.full_street_address}</p>
                  </div>
                  <div className='-mr-6 flex items-center justify-center'>
                    <RadioButton
                      id={practice.id}
                      label={' '}
                      name={practice.name}
                      isSelected={checked ? checked.id == practice.id : false}
                      onChange={() => selectedPractice(practice, 'button')}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <Skeleton width={240} height={80} count={3} />
      )}
    </>
  );
}
