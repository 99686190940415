import React, { useState } from 'react';
import { KioskContext } from './KioskContext';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { useQuery } from '@tanstack/react-query';

export const KioskContextProvider = ({ children, mobile }) => {
  const navigate = useNavigate();
  const [userIds, setUserIds] = useState([]);

  const { data } = useQuery(['kiosk'], async () => {
    let result = await requestApi({
      url: mobile ? 'api/kiosk/mobile/get' : 'api/kiosk/get',
      navigate
    });
    localStorage.setItem('myriadIsKiosk', true);
    return result;
  });

  return (
    <KioskContext.Provider
      value={{
        kiosk: data?.kiosk,
        url: data?.url,
        mobile,
        userIds,
        setUserIds
      }}>
      {children}
    </KioskContext.Provider>
  );
};
