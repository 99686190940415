import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import AnswersBox from './AnswersBox';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import questionnaire from '../../../lib/questionnaire';
import { formTypeFormatter, getAnswerLabel } from '../../../lib/helpers';

const FormDetails = () => {
  const { formik } = useFormBuilderContext();
  return (
    <div className="rounded-[10px] bg-white p-[21px]">
      <div className="mb-[21px] flex h-[30px] w-max items-center gap-2 rounded-[34px] bg-primary-50 px-[14px] py-[5px] text-center">
        <p className="text-sm font-500 text-primary-500">Form Details</p>
        <Icon icon="new-edit-v3" />
      </div>
      <div>
        <AnswersBox question="Form Name" answer={formik?.values?.form?.name} />
        <AnswersBox
          question={questionnaire?.questions?.create_type?.title}
          answer={formTypeFormatter(formik?.values?.form_details?.create_type)}
        />
        {formik?.values?.form_details?.create_type === 'clinical_template' ? (
          <AnswersBox
            question={questionnaire?.questions?.clinical_template?.existing?.title}
            answer={getAnswerLabel(
              questionnaire?.questions?.clinical_template?.existing,
              formik?.values?.form_details?.existing
            )}
          />
        ) : (
          <>
            <AnswersBox
              question={questionnaire?.questions?.patient_facing_form?.integrate?.title}
              answer={getAnswerLabel(
                questionnaire?.questions?.patient_facing_form?.integrate,
                formik?.values?.form_details?.integrate
              )}
            />

            <AnswersBox
              question={questionnaire?.questions?.patient_facing_form?.receive?.title}
              answer={getAnswerLabel(
                questionnaire?.questions?.patient_facing_form?.receive,
                formik?.values?.form_details?.receive
              )}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default FormDetails;
