export const initFabricHandlers = ({
  fabricCanvas,
  setObjectSelected,
  setIsBold,
  setIsItalic,
  setFontFamily,
  setSelectedAction,
  handleAction,
  pushToHistory
}) => {
  fabricCanvas?.on('selection:created', () => {
    const activeObject = fabricCanvas?.getActiveObject();
    setObjectSelected(activeObject.type);

    if (activeObject.type === 'activeSelection') {
      let hasPath = false;
      activeObject.forEachObject((obj) => {
        if (obj.type === 'path') {
          hasPath = true;
        }
      });

      if (hasPath) {
        activeObject.hasControls = false;
        activeObject.lockMovementX = true;
        activeObject.lockMovementY = true;
        activeObject.lockScalingX = true;
        activeObject.lockScalingY = true;
        activeObject.lockRotation = true;
      }
    }

    setIsBold(activeObject.fontWeight === 'bold');
    setIsItalic(activeObject.fontStyle === 'italic');
    setFontFamily(activeObject.fontFamily || 'Times New Roman');
  });

  fabricCanvas?.on('selection:cleared', () => {
    setObjectSelected(null);
    setIsBold(false);
    setIsItalic(false);
    setFontFamily('Times New Roman');
  });

  fabricCanvas?.on('selection:updated', () => {
    const activeObject = fabricCanvas?.getActiveObject();
    setObjectSelected(activeObject.type);

    setIsBold(activeObject.fontWeight === 'bold');
    setIsItalic(activeObject.fontStyle === 'italic');
    setFontFamily(activeObject.fontFamily || 'Times New Roman');
  });

  fabricCanvas?.on('mouse:down', (options) => {
    setSelectedAction((action) => {
      if (action && options.pointer) {
        handleAction(action, options.pointer);
      }
      return action === 'draw' ? 'draw' : null;
    });
  });

  const updateHistory = (opt) => {
    if (fabricCanvas.isDrawingMode && opt && opt.target && opt.target.type === 'path') {
      return;
    }
    pushToHistory(fabricCanvas);
  };

  fabricCanvas?.on('object:added', (e) => {
    updateHistory();
    if (e.target?.type === 'path') {
      e.target.set({
        selectable: true,
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        lockRotation: true,
        hasControls: false,
        evented: false
      });
    }
  });

  fabricCanvas?.on('object:modified', updateHistory);
  fabricCanvas?.on('object:removed', updateHistory);

  fabricCanvas?.on('path:created', () => {
    if (fabricCanvas.isDrawingMode) {
      pushToHistory(fabricCanvas);
    }
  });
};

export const dispatchFabricHandlers = (fabricCanvas) => {
  fabricCanvas?.off('object:added');
  fabricCanvas?.off('object:modified');
  fabricCanvas?.off('object:removed');
  fabricCanvas?.off('selection:created');
  fabricCanvas?.off('selection:cleared');
  fabricCanvas?.off('selection:updated');
  fabricCanvas?.off('object:added');
};
