import ProfilePicture from 'components/Profile/ProfilePicture';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import React from 'react';

const UserItem = ({ item, setSelectedUser = () => { }, isChecked, f_name = '', l_name = '' }) => (
  <div
    className={`flex cursor-pointer items-center gap-3 p-1 rounded-md ${isChecked ? 'bg-primary-50' : ''
      }`}
    onClick={() => setSelectedUser(item)}>
    <Checkbox radio isChecked={isChecked} onChange={() => setSelectedUser(item)} id={item.id} />
    <ProfilePicture firstName={f_name} lastName={l_name} id={item?.user_id || item?.id} size={36} />
    <h3 className="text-base font-500 text-neutral-900">{f_name + ' ' + l_name}</h3>
  </div>
);

export default UserItem;
