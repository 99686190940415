import React from 'react';

import CardProcessor from '../../CardProcessor';
import { formatDateAndTime, formatPhoneNumber } from '../../../../lib/helpers/utility';

const ReceiptPatientTransactionDetails = ({ patient, transaction, showDetails, timezone }) => {
  const transactionType = (type) => {
    switch (type) {
      case 'cash':
        return 'Cash';
      case 'check':
        return 'Check';
      case 'care_credit':
        return 'Outside Financing';
      case 'ONLINE':
        return 'Credit Card';
      default:
        return null;
    }
  };

  const renderCommonInfo = () => (
    <>
      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Transaction ID: </p>
        <p className="text-xxs text-neutral-900 font-600">{transaction?.id}</p>
      </div>

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Transaction time: </p>
        <p className="text-xxs text-neutral-900 font-600">
          {formatDateAndTime(transaction?.created_at, timezone)}
        </p>
      </div>
    </>
  );

  const renderCheckInfo = () => (
    <>
      {renderCommonInfo()}

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Note: </p>
        <p className="text-xxs text-neutral-900 font-600">{transaction?.note}</p>
      </div>
    </>
  );

  const renderCreditCardInfo = () => (
    <>
      {renderCommonInfo()}

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Transaction result: </p>
        <p className="text-xxs text-neutral-900 font-600">{transaction?.id}</p>
      </div>

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Transaction type: </p>
        <p className="text-xxs text-neutral-900 font-600">
          {transaction?.type === 'ONLINE' ? 'Online' : 'In person'}
        </p>
      </div>

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Card brand: </p>
        <p className="text-xxs text-neutral-900 font-600">
          <CardProcessor>{transaction?.cc_type}</CardProcessor>
        </p>
      </div>

      <div className="flex justify-between">
        <p className="text-xxs text-neutral-700">Account number: </p>
        <p className="text-xxs text-neutral-900 font-600">
          {transaction?.cc_number ? transaction?.cc_number.substr(-4) : ''}
        </p>
      </div>
    </>
  );

  return (
    <div className="w-auto flex !gap-4 !px-4 !mb-2 ml-[132px] -mt-[60px]">
      {/* To */}
      <div className="w-2/5">
        <p className="text-sm font-600 leading-4 text-neutral-900 mb-[10px]">To:</p>

        <p className="text-xs font-600 leading-4 text-primary-600 !mb-2">
          {patient?.fullName || `${patient?.f_name} ${patient?.l_name}`}
        </p>

        <div className="flex flex-col !gap-[6px]">
          {patient?.fullAddress && (
            <p className="text-xxs font-500 text-neutral-700">{patient?.fullAddress}</p>
          )}

          {patient?.phone && (
            <p className="text-xxs font-500 text-neutral-700">
              {formatPhoneNumber(patient?.phone)}
            </p>
          )}

          <p className="text-xxs font-500 text-neutral-700">{patient?.email}</p>
        </div>
      </div>

      {/* Details */}
      {showDetails && (
        <div className="w-3/5 flex flex-col !gap-6 !pl-4 border-l-[0.6px] [border-left-style:solid] border-neutral-200">
          <p className="text-sm font-600 leading-4 text-neutral-900">Details:</p>

          <div className="flex flex-col !gap-[6px]">
            <div className="flex justify-between">
              <p className="text-xxs text-neutral-700">Entry method: </p>
              <p className="text-xxs text-neutral-900 font-600">
                {transactionType(transaction?.type)}
              </p>
            </div>

            {transaction?.type === 'cash' && renderCommonInfo()}

            {(transaction?.type === 'check' || transaction?.type === 'care_credit') &&
              renderCheckInfo()}

            {['cash', 'check', 'care_credit'].indexOf(transaction?.type) === -1 &&
              renderCreditCardInfo()}
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiptPatientTransactionDetails;
