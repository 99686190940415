import React, { useEffect } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { ia, io } from 'lib/helpers/utility';
import { useCustomForm } from 'lib/hooks/queries/customForms/useCustomForm';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import SelectInstantAppointment from './SelectInstantAppointment';

export default function InstantFormSelection({
  data,
  patientId,
  id,
  appointmentId,
  practice,
  selectedAppointment,
  setSelectedAppointment,
  selectedForms,
  apptId,
  setSelectedFormIds,
  selectedFormIds,
  selectedForm,
  setSelectedForm,
  previewFormId,
  setPreviewFormId
}) {
  const otherForms = [
    { name: 'HIPAA Agreement', id: 'hipaa', hardcoded: true },
    { name: 'Pre-Authorized Healthcare Form (CC on file)', id: 'pre-auth', hardcoded: true },
    { name: 'Insurance', id: 'insurance', hardcoded: true },
    { name: 'Personal ID', id: 'personal-id', hardcoded: true },
    { name: 'Demographics', id: 'personal-info', hardcoded: true }
  ];

  const { data: previewFormData } = useCustomForm({
    params: { form_id: previewFormId },
    dependencies: [previewFormId]
  });

  useEffect(() => {
    if (selectedForms?.forms && apptId) {
      setSelectedFormIds([
        ...(selectedForms?.forms?.map((form) => form.id) || []),
        ...otherForms.map((form) => form.id)
      ]);
    }
  }, [selectedForms]);

  useEffect(() => {
    if (
      previewFormId &&
      previewFormData &&
      !ia(previewFormData?.forms) &&
      io(previewFormData?.forms, 1)
    ) {
      setSelectedForm(previewFormData?.forms);
    }
  }, [previewFormData, previewFormId]);

  const isFormSelected = (id) => selectedFormIds.includes(id);

  const handleClick = (formId) => {
    if (isFormSelected(formId)) {
      setSelectedFormIds((prevIds) => prevIds.filter((id) => id !== formId));
    } else {
      setSelectedFormIds((prevIds) => [...prevIds, formId]);
    }
  };

  const previewForm = (id) => {
    if (isNaN(parseInt(id))) return;

    setPreviewFormId(id);
  };

  return selectedForm ? (
    <div className="new-forms rounded-lg bg-white bg-opacity-100 p-[0.5rem] opacity-100 !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
      <ReactFormGenerator hide_actions data={selectedForm?.json?.fields} read_only />
    </div>
  ) : (
    <table className="primary-table responsive-table selectable-children h-full rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
      <thead className="!z-[999]">
        <tr>
          <th className="h-full !pl-16">Name</th>
          <th className="relative !z-[999]">
            {!appointmentId && (
              <SelectInstantAppointment
                {...{ patientId, id, selectedAppointment, setSelectedAppointment, practice }}
              />
            )}
          </th>
        </tr>
      </thead>
      <tbody className="z-10 h-[calc(100vh-233px)]">
        {ia(data?.forms) &&
          ia([...otherForms, ...data.forms]) &&
          [...otherForms, ...data.forms]?.map((form, idx) => (
            <tr
              key={idx}
              className="cursor-pointer hover:!bg-primary-50"
              onClick={() => handleClick(form.id)}>
              <td className="flex h-full items-center gap-x-4">
                <Checkbox
                  radio
                  className="scale-75"
                  isChecked={isFormSelected(form.id)}
                  data-qa={`load-form-checkbox-${idx}`}
                />
                <span className="font-500 first-letter:capitalize ">{form.name}</span>
              </td>

              <td className="mr-4">
                <div className="mr-4 flex h-full items-center justify-end text-right">
                  {!form.hardcoded && (
                    <Icon
                      icon="new-eye"
                      onClick={(e) => {
                        e.stopPropagation();
                        previewForm(form.id);
                      }}
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
