import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import React, { Suspense, useEffect, useRef, useState } from 'react';

import { OrbitControls, useGLTF } from '@react-three/drei';
import { Canvas, useThree } from '@react-three/fiber';
import { useQuery } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import cs from 'classnames';
import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Allowed from 'components/shared/Permissions/Allowed';
import { useNavigate } from 'react-router-dom';
import { ACESFilmicToneMapping } from 'three';

function euclideanDistance(a, b) {
  return Math.sqrt(Math.pow(a?.x - b?.x, 2) + Math.pow(a?.y - b?.y, 2) + Math.pow(a?.z - b?.z, 2));
}

const options = [
  {
    label: 'Male',
    value: 'male'
  },
  {
    label: 'Female',
    value: 'female'
  },
  {
    label: 'Muscle',
    value: 'muscle'
  }
];

const Model = ({
  setMark,
  hovered = null,
  points,
  setPoints,
  mark,
  setHovered,
  model,
  setSaved
}) => {
  const male = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_male.glb`);
  const female = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_female.glb`);
  const muscle = useGLTF(`${process.env.ENV !== 'local' ? '/public' : ''}/models/face_muscles.glb`);

  const models = {
    male,
    female,
    muscle
  };

  const { scene } = models[model];

  const { camera } = useThree();

  const [startRotation, setStartRotation] = useState();

  const handleClickDown = (event) => {
    event.stopPropagation();
    setStartRotation({ x: camera?.rotation?.x, y: camera?.rotation?.y, z: camera?.rotation?.z });
  };

  const handleClickUp = (event) => {
    if (mark || mark === 0) {
      event.stopPropagation();
      const distance = euclideanDistance(startRotation, {
        x: camera?.rotation?.x,
        y: camera?.rotation?.y,
        z: camera?.rotation?.z
      });

      if (distance < 0.02) {
        if (hovered || hovered === 0) {
          if (points[hovered]?.mark === mark) {
            setPoints((points) => {
              let newPoints = [...points];
              newPoints.splice(hovered, 1);
              return newPoints;
            });
            setHovered(null);
          } else {
            setMark(points[hovered]?.mark);
          }
        } else {
          setPoints((points) => [
            ...points,
            {
              position: [event.point.x, event.point.y, event.point.z],
              mark
            }
          ]);
        }
        setSaved(false);
      }
    }
  };

  return (
    <primitive
      scale={0.7}
      onPointerDown={handleClickDown}
      onPointerUp={handleClickUp}
      object={scene}
    />
  );
};

const Point = ({ point, index, mark, setHovered }) => {
  const isSelected = point.mark === mark;
  return (
    <mesh
      onPointerEnter={() => setHovered(index)}
      onPointerLeave={() => setHovered(null)}
      scale={isSelected ? 0.1 : 0.04}
      position={point.position}>
      <sphereGeometry args={[0.1, 32, 32]} />
      <meshBasicMaterial color="cyan" transparent opacity={isSelected ? 1 : 0.4} />
    </mesh>
  );
};

const Landmarks = () => {
  const [marks, setMarks] = useState([]);
  const [mark, setMark] = useState();
  const [input, setInput] = useState();

  const [points, setPoints] = useState([]);
  const [hovered, setHovered] = useState();

  const [editModal, setEditModal] = useState(null);
  const [editInput, setEditInput] = useState();

  const [deleteModal, setDeleteModal] = useState(null);

  const [model, setModel] = useState('male');

  const [saved, setSaved] = useState(true);

  const [importModal, setImportModal] = useState(null);

  const inputRef = useRef();

  const addMark = () => {
    if (input) {
      const markId = Math.max(...(marks?.map((item) => item.id) || [])) + 1;
      setMarks([{ label: input, id: markId }, ...marks]);
      setInput('');
      setMark(marks.length);
      setSaved(false);
    }
  };

  const navigate = useNavigate();

  const handleSave = () => {
    const response = requestApi({
      url: 'api/landmarks/update',
      navigate,
      onSuccess: () => {
        setSaved(true);
      },
      method: 'post',
      params: {
        model,
        marks: {
          marks,
          points
        }
      }
    });
  };

  const getLandmarks = async () => {
    const onSuccess = (data) => {
      setMarks(data?.landmarks?.marks || []);

      var newPoints = [...(data?.landmarks?.points || [])];
      const markIds = data?.landmarks?.marks?.map((m) => m.id);
      newPoints = newPoints.filter((point) => {
        return markIds.includes(point.mark);
      });

      setPoints(newPoints || []);
    };

    const response = requestApi({
      url: 'api/landmarks/get',
      navigate,
      onSuccess,
      params: {
        model
      }
    });

    return response;
  };

  const { data } =
    useQuery({
      queryKey: ['getLandmarks', model],
      queryFn: getLandmarks,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }) || {};

  return (
    <Allowed requiredPermissions="landmarks.read" showMessage showIllustration>
      <div className="mt-12 flex h-[80vh] h-full gap-4 pr-5">
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <div className="flex h-[80vh] !w-72 flex-col gap-2 overflow-auto">
            <div className="mb-3 flex gap-2">
              <a
                className="flex flex-1 justify-center gap-1 rounded bg-primary-600 p-[10px] text-center text-sm text-white"
                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                  JSON.stringify({ marks, points })
                )}`}
                download={`${model}.json`}>
                Export
              </a>

            <Allowed requiredPermissions="landmarks.create">
            <button
              className="flex flex-1 justify-center gap-1 rounded bg-primary-600 p-[10px] text-center text-sm text-white"
              onClick={() =>
                setImportModal({
                  points: true,
                  file: null
                })
              }>
              Import
            </button>
            </Allowed>
          </div>

          <p className="mb-2 text-lg">Model</p>
          <Select
            isClearable={false}
            icon="scanning"
            options={options}
            value={{ label: model }}
            onChange={(e) => {
              setMark();
              setSaved(true);
              handleSave();
              setModel(e?.value);
            }}></Select>
          <p className="mb-2 mt-3 text-lg">Marks</p>
          <Allowed requiredPermissions="landmarks.create">
          <div className="flex gap-2">
            <Input
              placeholder="Landmark..."
              value={input}
              ref={inputRef}
              onChange={(e) => setInput(e.target.value)}></Input>
            <Button disabled={!input} onClick={addMark} text="Add Mark"></Button>
          </div>
          </Allowed>
          {marks.map((m, index) => (
            <div
              key={'mark-' + index}
              onClick={() => setMark(m?.id)}
              className={cs(
                'flex w-full cursor-pointer justify-between rounded bg-white !p-3',
                m.id == mark && '!bg-primary-500 text-white'
              )}>
              {m.label}
              <div className="flex gap-2">
              <Allowed requiredPermissions="landmarks.update">
                <Icon
                  color={m.id == mark ? 'white' : 'info'}
                  icon="new-edit"
                  onClick={() => {
                    setEditModal(index);
                    setEditInput(m?.label);
                  }}
                  className="cursor-pointer"></Icon>
                  </Allowed>
                  <Allowed requiredPermissions="landmarks.delete">
                <Icon
                  color={m.id == mark ? 'white' : 'danger'}
                  icon="trash"
                  onClick={() => {
                    setDeleteModal(index);
                  }}
                  className="cursor-pointer"></Icon>
                  </Allowed>
              </div>
              </div>
            ))}
          </div>
          <div className="relative flex h-[80vh] flex-1 rounded-lg bg-white">
            <Canvas
              style={{ width: '100%', height: '100%' }}
              gl={{ antialias: true, toneMappingExposure: 3, toneMapping: ACESFilmicToneMapping }}
              linear
              camera={{ position: [0, 15, 30], fov: 25 }}>
              <Suspense fallback={null}>
                <Model
                  model={model}
                  setHovered={setHovered}
                  points={points}
                  hovered={hovered}
                  setPoints={setPoints}
                  mark={mark}
                  marks={marks}
                  setMark={setMark}
                  setSaved={setSaved}
                />
              </Suspense>

              {points.map((point, index) => (
                <Point
                  point={point}
                  setHovered={setHovered}
                  mark={mark}
                  index={index}
                  key={index}
                />
              ))}

              <OrbitControls
                makeDefault
                maxPolarAngle={Math.PI / 1.7}
                maxDistance={8}
                minDistance={1}
              />
            </Canvas>

            <div className="absolute left-0 top-0 p-3">
              <Button
                disabled={saved}
                className={cs(
                  'mb-3 w-full',
                  !saved ? 'animate-bounce !bg-warning-600 text-white' : '!bg-neutral-400'
                )}
                text={saved ? 'Saved' : 'Save'}
                onClick={handleSave}
              />
            </div>
          </div>
          <Confirm
            handleOpen={editModal !== null}
            slideFromRight
            handleClose={() => setEditModal(null)}
            icon="new-edit"
            handleContinue={() => {
              setMarks((marks) => {
                let newMarks = [...marks];
                newMarks[editModal] = editInput;
                return newMarks;
              });
              setSaved(false);
              setEditModal(null);
            }}>
            <Input
              label="Edit Mark"
              onChange={(e) => setEditInput(e.target.value)}
              value={editInput}></Input>
          </Confirm>
          <Confirm
            variant="danger"
            primaryBtnTxt="Delete"
            title={`Delete ${marks[deleteModal]}`}
            icon="trash"
            message="Are you sure you want to delete Mark and all its points?"
            handleContinue={() => {
              setMark(null);
              setMarks((marks) => {
                let newMarks = [...marks];
                newMarks.splice(deleteModal, 1);
                setPoints((points) => points.filter((point) => point.mark !== deleteModal));
                return newMarks;
              });
              setSaved(false);
              setDeleteModal(null);
            }}
            handleOpen={deleteModal !== null}
            handleClose={() => setDeleteModal(null)}
          />

          <Confirm
            handleOpen={importModal !== null}
            handleClose={() => setImportModal(null)}
            title="Import landmarks"
            handleContinue={async () => {
              try {
                const data = JSON.parse(await importModal?.file?.text());
                setMarks(data?.marks || marks);
                setPoints(importModal.points ? data?.points || points : []);
                setImportModal(null);
                setSaved(false);
              } catch (e) {
                console.error(e);
                showAlert({ title: 'Invalid json format', color: 'danger' });
              }
            }}
            icon="new-file-upload">
            <div className="mt-4 flex gap-2">
              <Checkbox label="Marks" disabled={true} checked={true}></Checkbox>
              <Checkbox
                label="Points"
                checked={importModal?.points}
                onChange={(e) =>
                  setImportModal({ ...importModal, points: e.target.checked })
                }></Checkbox>
              <input
                type="file"
                name="file"
                onChange={(e) => setImportModal({ ...importModal, file: e.target.files[0] })}></input>
            </div>
          </Confirm>
        </ErrorBoundary>
      </div>
    </Allowed>
  );
};
export default withErrorBoundary(Landmarks);
