import { mString } from 'lib/helpers/utility';

export const COLUMN_DEFS = [
  {
    field: 'name',
    headerName: 'Patient/Payer',
    cellClass: 'no-border',
    flex: 1,
    valueGetter: ({ data, node }) => {
      if (node.level === -1) {
        return 'Totals';
      }
      return data?.fullName || data?.name;
    }
  },
  {
    field: 'email',
    headerName: 'Balance Type',
    cellClass: 'no-border',
    flex: 1,
    valueGetter: ({ data, node }) => {
      if (node.level === -1) {
        return '';
      }
      return data?.email ? 'Patient' : 'Insurance';
    }
  },
  {
    field: 'zeroToThirty',
    headerName: '0-30 days',
    cellClass: 'no-border',
    flex: 1,
    aggFunc: 'sum',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'thirtyOneToSixty',
    headerName: '31-60 days',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1,
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'sixtyOneToNinety',
    headerName: '61-90 days',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1,
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'ninetyOneToOneHundredAndTwenty',
    headerName: '91-120 days',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1,
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'oneHundredAndTwentyPlus',
    headerName: '121+ days',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1,
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'total',
    headerName: 'Total',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1,
    valueFormatter: ({ value }) => mString(value)
  }
];

export const DYNAMIC_AR_SWITCH_ITEMS = [
  {
    label: 'Table',
    value: 'table',
    navigate: '/portal/beyond-billing/dynamic-ar'
  },
  {
    label: 'Graph',
    value: 'graph',
    navigate: '/portal/beyond-billing/dynamic-ar/graph'
  }
];
