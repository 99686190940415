import React, { useEffect, useState } from 'react';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Button from 'components/shared/Buttons/Button';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { useMyScribeTemplateContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import cs from 'classnames';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

const UserPrompt = ({ message }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editableMessage, setEditableMessage] = useState(message);
  const [finalMessage, setFinalMessage] = useState(message);

  const patient = useRecoilValue(userState);
  const { setCurrentActivePrompt } = useMyScribeTemplateContextApi();
  const { onEnhanceNote } = useChatContextApi();
  const { inputTags, selectedTableRows } = useChatContextData();

  useEffect(() => {
    setEditableMessage(message);
    setFinalMessage(message);
  }, [message]);

  const handleInputChange = (e) => {
    setEditableMessage(e.target.value);
  };

  const onEditOpen = () => {
    setIsEdit(true);
  };

  const onEditClose = () => {
    setIsEdit(false);
    setEditableMessage(finalMessage);
  };

  const onPromptUpdate = () => {
    setFinalMessage(editableMessage);
    setIsEdit(false);

    setCurrentActivePrompt((ps) => ({ ...ps, response: '', userPrompt: editableMessage }));

    onEnhanceNote();
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  return (
    <div className="flex flex-row items-start">
      <ProfilePicture
        firstName={patient?.f_name}
        id={patient?.id}
        image={patient?.header_photo}
        size={36}
        className="mr-2"
      />

      <div className="flex flex-grow flex-col">
        <div className="w-full rounded-lg bg-white">
          {isEdit ? (
            <AIChatInput
              isHTML
              className="mb-3 w-full border-2 border-primary-300 shadow-primary-50 outline outline-1 outline-primary-300 ring-4 ring-[#CBF0FD]"
              leftButtons={[]}
              rightButtons={[]}
              onTextChange={handleInputChange}
              onSubmit={onPromptUpdate}
              placeholder="Type anything.."
              value={editableMessage}
            />
          ) : (
            <div className="p-3">{finalMessage}</div>
          )}
          {inputTags.map((tag) => (
            <span
              // onClick={() => onTagPreview(tag.id)}
              key={tag.id}
              className={cs(
                'relative my-1 ml-1 cursor-pointer rounded bg-primary-50 p-1 px-2 text-sm text-primary-700',
                getTagClassName(tag.id)
              )}>
              #{tag?.text}
            </span>
          ))}
        </div>
        <div className="flex items-center justify-end">
          {!isEdit ? (
            <Button
              size="small"
              text="Edit"
              onClick={onEditOpen}
              icon="new-edit-v3"
              iconSize={18}
              outlined
            />
          ) : (
            <div className="flex flex-row gap-1">
              <Button text="Cancel" onClick={onEditClose} iconSize={18} transparent size="small" />
              <Button size="small" text="Update" onClick={onPromptUpdate} iconSize={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPrompt;
