import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { getStates as getStatesAPI } from 'api/State';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { getFullAddress } from 'lib/helpers/utility';
import Button from 'components/shared/Button/Button';
import Modal from 'components/shared/Modal/Modal';
import PharmacyForm from './PharmacyForm';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';

const NewPharmacy = ({
  patientId,
  showPharmacyModal,
  hidePharmacyModal,
  pharmacy = null,
  newPharmacy,
  updatePharmacy
}) => {
  //if pharmacy var isn't null it means we're editing
  const navigate = useNavigate();
  const { device } = useUIContext();
  const createPharmacy = useRef();
  const [loading, setLoading] = useState(false);

  const [states, setStates] = useState([]);

  useEffect(() => {
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const res = await getStatesAPI(navigate, { optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleEnterPreventDefault = (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };
  const NewPharmacyValidationSchema = Yup.object().shape({
    name: Yup.string().min(3).required('Pharmacy name is required.'),
    address_ln_1: Yup.string().required('Address 1 is required.'),
    state: Yup.number().required('Please select a state')
  });
  const formik = useFormik({
    initialValues: {
      name: pharmacy ? pharmacy.name : '',
      address_ln_1: pharmacy ? pharmacy.address.address_ln_1 : '',
      address_ln_2: pharmacy ? pharmacy.address.address_ln_2 : '',
      city: pharmacy ? pharmacy.address.city : '',
      state: pharmacy ? pharmacy.address.state : '',
      zip: pharmacy ? pharmacy.address.zip : '',
      phone: pharmacy ? pharmacy.phone : '',
      fax_number: pharmacy ? pharmacy.fax_number : ''
    },
    validationSchema: NewPharmacyValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      let pharmacyObject = {
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        name: values.name,
        phone: values.phone,
        fax_number: values.fax_number,
        patientId
      };

      pharmacyObject.address = {
        ...pharmacyObject?.address,
        full_street_address: getFullAddress({ address: pharmacyObject?.address, states })
      };

      pharmacy ? await editPharmacy(pharmacyObject) : await createNewPharmacy(pharmacyObject);
      setSubmitting(false);
      resetForm();
    }
  });

  const createNewPharmacy = async (pharmacyObj) => {
    try {
      setLoading(true);
      const onSuccess = ({ pharmacy }) => {
        newPharmacy(pharmacy);
        showAlert({ title: 'Successfully created', color: 'primary' });
        hidePharmacyModal();
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
      };
      await requestApi({
        url: '/api/pharmacy/create',
        params: { ...pharmacyObj },
        navigate,
        onSuccess,
        onError
      });
    } catch (err) {
      showAlert({
        title: err || `There's been an unexpected error, please try again later.`,
        color: 'danger'
      });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
    setLoading(false);
  };

  const editPharmacy = async (updatedPharmacy) => {
    updatedPharmacy.address.id = pharmacy.address.id;
    updatedPharmacy.pharmacyId = pharmacy.id;
    try {
      setLoading(true);
      const onSuccess = ({ pharmacy }) => {
        updatePharmacy(pharmacy);
        showAlert({ title: 'Successfully updated', color: 'primary' });
        hidePharmacyModal();
        setLoading(false);
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
      };
      await requestApi({
        url: '/api/pharmacy/update',
        params: { ...updatedPharmacy },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <Modal
      handleOpen={showPharmacyModal}
      handleClose={hidePharmacyModal}
      title={pharmacy ? 'Edit Pharmacy' : 'Create New Pharmacy'}
      slideFromRight
      footer={
        <>
          {device !== 'mobile' && (
            <Button transparent onClick={hidePharmacyModal} id="cancel-new-pharmacy">
              Cancel
            </Button>
          )}
          <Button
            id="create-new-pharmacy"
            primary
            onClick={() => formik.submitForm()}
            forwardedRef={createPharmacy}
            loading={loading}>
            {pharmacy ? 'Edit' : 'Create'} Pharmacy
          </Button>
        </>
      }>
      <form onSubmit={formik.handleSubmit} onKeyPress={handleEnterPreventDefault}>
        {/*  */}
        <PharmacyForm states={states} formik={formik} />
      </form>
    </Modal>
  );
};

export default NewPharmacy;
