export const handleTaxItems = ({ formik, type, id, action }) => {
  const { values, setFieldValue } = formik || {};

  if (action === 'add') {
    setFieldValue(
      type,
      values[type].map((item, i) =>
        (item?.uuid || item?.id) === id ? { ...item, tax_excluded: false } : item
      )
    );
  } else if (action === 'remove') {
    setFieldValue(
      type,
      values[type].map((item, i) =>
        (item?.uuid || item?.id) === id ? { ...item, tax_excluded: true } : item
      )
    );
  }
};
