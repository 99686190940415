import React, { useMemo } from 'react';
import NotFound from '../components/NotFound';
import WidgetHeader from '../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import { mString } from 'lib/helpers/utility';
import AGChart from 'components/shared/AGChart/AGChart';
import useChartSize from 'lib/hooks/useChartSize';

const HealthcareProgramEfficiencySaving = (props) => {
  const item = { name: 'Healthcare Program Efficiency Savings' };
  const { dashboardData } = props;

  const { item: itemObj, layout } = props;
  const widgetName = itemObj?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const formattedData = dashboardData?.count?.healthcareProgramSavings
    ? [
        {
          asset: 'Billing Cost',
          amount: parseFloat(dashboardData?.count?.healthcareProgramSavings?.billingCost)
        },
        {
          asset: 'Potential AR',
          amount: parseFloat(dashboardData?.count?.healthcareProgramSavings?.potentialAR)
        }
      ]
    : [];

  const options = {
    data: formattedData,
    height: height > 0 ? height - 30 - height / proportion : 0,
    width: width > 0 ? width / 2 : 0,
    legend: {
      enabled: false
    },
    series: [
      {
        type: 'donut',
        calloutLabelKey: 'asset',
        angleKey: 'amount',
        innerRadiusRatio: 0.7,
        fills: ['#00ABE8', '#8AE0FF'],
        calloutLabel: {
          enabled: false
        },
        innerLabels: [
          {
            text: 'Total',
            color: '#8FA0A7',
            fontSize: 12
          },
          {
            text: mString(
              parseInt(
                dashboardData?.count?.healthcareProgramSavings?.billingCost +
                  dashboardData?.count?.healthcareProgramSavings?.potentialAR
              ),
              false
            ),
            margin: 4,
            fontSize: 16,
            color: '#004F6B'
          },
          { text: '' }
        ]
      }
    ],
    theme: 'ag-material'
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  return (
    <div className="flex h-full flex-col">
      <WidgetHeader icon="chart" item={item} hasPlus={false} {...props} />
      {dashboardData?.count?.healthcareProgramSavings?.billingCost > 0 ||
      dashboardData?.count?.healthcareProgramSavings?.potentialAR > 0 ? (
        <div className="flex h-full justify-between gap-4 !p-6 sm:flex-wrap">
          <div className="flex flex-col !gap-7 md:!gap-4">
            <div className="flex flex-col gap-2">
              <div className="flex items-baseline gap-2">
                <div className="h-[12px] w-[4px] !rounded-lg bg-[#00ABE8]"></div>
                <span className="text-base font-500 leading-4 text-neutral-500">
                  Reduced Billing Costs
                </span>
              </div>
              <span className="text-xl font-600 leading-4 text-primary-900">
                {mString(dashboardData?.count?.healthcareProgramSavings?.billingCost, false)}
              </span>
              <span className="text-sm font-500 leading-4 text-neutral-600">
                Efficiency savings
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-baseline gap-2">
                <div className="h-[12px] w-[4px] !rounded-lg bg-[#8AE0FF]"></div>
                <span className="text-base font-500 leading-4 text-neutral-500">
                  Reduced Potential AR
                </span>
              </div>
              <span className="text-xl font-600 leading-4 text-primary-900">
                {mString(dashboardData?.count?.healthcareProgramSavings?.potentialAR, false)}
              </span>
              <span className="text-sm font-500 leading-4 text-neutral-600">
                Efficiency savings
              </span>
            </div>
          </div>
          <AGChart options={options} key={chartKey} />
        </div>
      ) : (
        <NotFound
          icon="new-illustration-9"
          className="!pt-8"
          title="No HPES reports found!"
          description="You can start scheduling new appointments right now."
        />
      )}
    </div>
  );
};

export default HealthcareProgramEfficiencySaving;
