import React, { useEffect } from 'react';
import moment from 'moment';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { useRecoilValue } from 'recoil';

import RadioButton from '../../../../shared/RadioButton/RadioButton';
import Select from '../../../../shared/Select/Select';
import Input from '../../../../shared/Input/Input';
import { showAlert } from '../../../../shared/Alert/Alert';
import { currentPractice as currPractice } from '../../../practiceState';
import { formatDateAndTime } from '../../../../../lib/helpers/utility';
import {
  endsOptions,
  getWeekOfMonth,
  handleChangeEnds,
  handleRepeatEvent,
  handleSelectWeekday,
  repeatEventOptions,
  weekdays
} from '../lib';

const RecurrenceEventForm = ({ formik, event }) => {
  const currentPractice = useRecoilValue(currPractice);

  const { values, setFieldValue } = formik;

  return (
    <>
      <hr className="-mt-2 bg-neutral-100 -mb-2" />
      <div className="flex items-center !gap-4">
        <Select
          inputId="repeat-event"
          label="Repeat event"
          value={values.repeat_event}
          options={repeatEventOptions}
          parentClassName={`${values.repeat_event?.value ? 'flex-1' : 'w-[50%]'}`}
          onChange={(val) => handleRepeatEvent(setFieldValue, val)}
          name="repeat_event"
          isClearable={false}
          icon="repeat"
        />
        {values.repeat_event?.value ? (
          <div className="flex items-end gap-2 !w-[196px]">
            <Input
              data-qa="repeat-every"
              label="Repeat every"
              onChange={(e) => {
                let positive = Math.floor(Math.abs(e.target?.value)) || 1;
                setFieldValue('repeat_every', positive);
                if (positive > 1 && values.repeat_event?.name === 'Day') {
                  setFieldValue('weekdays', []);
                } else if (values.repeat_event?.name === 'Day') {
                  setFieldValue('weekdays', weekdays);
                }
              }}
              value={values.repeat_every}
              name="repeat_every"
              type="number"
              min={1}
            />
            <Input value={values.repeat_event?.name} disabled className="w-[100px]" />
          </div>
        ) : (
          <div className="w-[196px]"></div>
        )}
      </div>
      {values.repeat_event?.value && (
        <div className="flex flex-col !gap-5">
          {((values.repeat_event?.name === 'Day' && values.repeat_every === 1) ||
            values.repeat_event?.name === 'Week') && (
            <div>
              <label className="m-0 flex text-sm font-500  text-neutral-800">Weekdays</label>
              <div className="flex gap-2 !mt-2">
                {weekdays.map((weekday, i) => (
                  <p
                    key={i}
                    onClick={() => handleSelectWeekday(weekday, values.weekdays, setFieldValue)}
                    className={`flex-1 cursor-pointer rounded-md shadow-sm h-[40px] flex items-center justify-center !hover:bg-primary-50$ ${
                      values.weekdays.map((item) => item?.value).includes(weekday?.value)
                        ? 'bg-primary-500 text-white'
                        : 'bg-neutral-100 text-neutral-800'
                    }`}>
                    {weekday.label}
                  </p>
                ))}
              </div>
            </div>
          )}

          {['Month', 'Year'].includes(values.repeat_event?.name) && (
            <div>
              <label className="m-0 flex text-sm font-500 !mb-2 text-neutral-800">Repeat on</label>
              <div className="flex gap-2">
                <div>
                  <RadioButton
                    id="day-input"
                    value={values.repeat_on}
                    label={`Day ${values.start_date.getDate()}`}
                    isSelected={values.repeat_on === 'day'}
                    onChange={() => setFieldValue('repeat_on', 'day')}
                    data-qa="login-email-radio"
                    className="text-neutral-900"
                  />
                </div>
                <RadioButton
                  id="week-input"
                  value={values.repeat_on}
                  label={`On the ${getWeekOfMonth(values.start_date)} ${moment(
                    values.start_date
                  ).format('dddd')} ${
                    values.repeat_event?.name === 'Year'
                      ? 'of ' + moment(values.start_date).format('MMMM')
                      : ''
                  }`}
                  isSelected={values.repeat_on === 'week'}
                  onChange={() => setFieldValue('repeat_on', 'week')}
                  data-qa="login-email-radio"
                  className="text-neutral-900"
                />
              </div>
            </div>
          )}
          <div className="flex items-center !gap-4">
            <Select
              inputId="ends"
              label="Ends"
              value={values.ends_type}
              options={endsOptions}
              parentClassName={`${values?.ends_type?.value !== 'never' ? 'flex-1' : 'w-[50%]'}`}
              onChange={(val) => handleChangeEnds(setFieldValue, val, values.start_date)}
              isClearable={false}
            />
            {values?.ends_type?.value !== 'never' && (
              <>
                {values.ends_type?.value === 'on_date' ? (
                  <Popover className="relative flex-1">
                    {({ close }) => (
                      <>
                        <Popover.Button className="w-full p-0">
                          <Input
                            data-qa="event-end-date"
                            label="On Date"
                            rightIcon="new-calendar-gray"
                            value={moment
                              .tz(values.end_date, currentPractice?.timezone)
                              .format('M/D/YYYY, dddd')}
                          />
                        </Popover.Button>

                        <Popover.Panel className="absolute z-10 flex w-full shadow-md">
                          <Calendar
                            showDateDisplay
                            className="w-full"
                            onChange={(date) => {
                              if (
                                moment(date).isAfter(
                                  moment(event?.initial_date || values.start_date).add(1, 'days')
                                )
                              ) {
                                //set hours 22:00 to avoid time zone issues
                                date.setHours(22);
                                setFieldValue('end_date', date);
                                close();
                                return;
                              }
                              showAlert({
                                color: 'warning',
                                message:
                                  'End date should be at least one day ahead of the start date.'
                              });
                            }}
                            date={
                              new Date(
                                formatDateAndTime(values.start_date, currentPractice?.timezone)
                              )
                            }
                          />
                        </Popover.Panel>
                      </>
                    )}
                  </Popover>
                ) : (
                  <Input
                    label="Events"
                    className="flex-1"
                    onChange={(e) => {
                      let positive = Math.floor(Math.abs(e.target?.value)) || 1;
                      setFieldValue('events', positive);
                    }}
                    value={values.events}
                    name="events"
                    type="number"
                    min={1}
                    onBlur={formik.handleBlur}
                  />
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RecurrenceEventForm;
