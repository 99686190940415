import React, { useRef, useImperativeHandle } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

const RiskAndCoMorbidities = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const riskAndCoMorbiditiesRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { mha: { riskAndCoMorbidities: clinicalNote?.mha?.riskAndCoMorbidities } }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      mha: {
        ...clinicalNote.mha,
        [name ?? key]: value ?? e
      }
    });
  };

  return (
    <Textarea
      isEditor
      label="Risk and co-morbidities"
      placeholder="Enter risk and co-morbidities"
      id="riskAndCoMorbidities"
      name="riskAndCoMorbidities"
      className="min-h-[200px]"
      value={clinicalNote?.mha?.riskAndCoMorbidities}
      onChange={(e) => handleChange(e, 'riskAndCoMorbidities')}
      transcribing
      forwardedRef={riskAndCoMorbiditiesRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default RiskAndCoMorbidities;
