import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCustomFormResponse } from '../../../../api/CustomForms';
import { getBalance } from '../../../../api/Transaction';
import {
  getAppointments,
  getClaims,
  getClinicalNotes,
  getICD10,
  getLedger,
  getMedications,
  getVitals
} from '../../../../api/Widgets';

export const useAppointments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['overview-appointments', ...dependencies],
    () => getAppointments(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};

export const useClinicalNotes = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['overview-clinical-notes', ...dependencies],
    () => getClinicalNotes(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};

export const useLedger = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['overview-ledger', ...dependencies], () => getLedger(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useOverviewMedications = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['medications', ...dependencies], () => getMedications(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
export const useICDOveview = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['overview-icd10', ...dependencies], () => getICD10(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useVitals = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['vitals', ...dependencies], () => getVitals(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useBalance = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['overview-balance-ledger', ...dependencies],
    () => getBalance(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};

export const useCustomFormResponse = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['customFormResponse', ...dependencies],
    () =>
      getCustomFormResponse(navigate, {
        type: [
          'medicationHistory',
          'allergies',
          'medicalHistory',
          'vaccines',
          'conditions',
          'familyHistory',
          'socialHistory',
          'surgicalHistory',
          'weightLossAdministration'
        ],
        allVersions: true,
        ...params
      }),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
