import React, { useEffect, useState } from 'react';
import { IconSvg } from 'components/shared/Icon/Icon.generated';
import Icon from 'components/shared/Icon/Icon';
import { ia } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const AdminIconsSelection = () => {
  const [icons, setIcons] = useState([]);

  useEffect(() => {
    let newIcons = [];

    for (let icon in IconSvg) {
      newIcons.push(icon);
    }

    setIcons(newIcons);
  }, []);

  return (
    <div className="p-[1rem]">
      <label className="text-3xl font-600 text-primary-500">Icons</label>
      <div className="flex flex-row flex-wrap justify-between">
        {ia(icons) &&
          icons.map((v) => (
            <div
              className="flex flex-col items-center justify-center rounded-2xl border-2 border-solid border-neutral-50 bg-neutral-700 p-4"
              key={`icon-${v}`}>
              <Icon icon={v} />
              <div className="text-neutral-500">{v}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(AdminIconsSelection);
