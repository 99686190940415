import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import EmptyView from './EmptyView';
import {
  useEnhanceNoteContextData,
  useEnhanceNoteContextApi
} from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import NotFound from 'components/NotFound';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import CustomFormsAI from '../../../../components/forms/CUSTOM/CustomFormsAI';
import { useNavigate } from 'react-router-dom';

const EnhanceNoteView = () => {
  const { filledForms = {}, componentRefs } = useEnhanceNoteContextData();
  const { setEnhanceLoading } = useEnhanceNoteContextApi();
  const navigate = useNavigate();

  const components = Object.entries(filledForms).map(([key, value]) => {
    return (
      <div key={key}>
        <div className="text-xl text-primary-500">{key.toUpperCase()}</div>
        {Object.values(value?.components)
          ?.filter((component) => component?.selected && component?.checked)
          ?.map((component) => {
            const Input = value?.forms || CustomFormsAI || NotFound;

            const componentRef = (el) => {
              componentRefs.current[component.path] = el;
            };

            return (
              <div ref={componentRef} className="py-2" key={component.title} id={component?.title}>
                <Input inputName={component.path} type={component?.type || key} />
              </div>
            );
          })
          .filter(Boolean)}
      </div>
    );
  });

  const onGenerate = () => {
    console.log('🚀 ~ onGenerate ~ onGenerate:');
    setEnhanceLoading(true);
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <div className="overflow-y-none flex h-full flex-col pb-24">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 z-[-1]" />
      {components?.length > 0 ? (
        <div className="flex-grow overflow-y-scroll px-4 pb-[150px]">
          <div className="mx-[230px] flex flex-col px-10 sm:mx-0 md:mx-[50px] lg:mx-[100px] xl:mx-[150px]">
            {components}
          </div>
        </div>
      ) : (
        <EmptyView />
      )}
      <div className="px-auto absolute bottom-[60px] z-50 flex w-1/2 self-center bg-transparent">
        <AIChatInput
          rightButtons={[
            <Button
              key={1}
              text="Enhance"
              icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className={cs('!py-2 !pl-4 !pr-4')}
              onClick={onGenerate}
              disabled={!Object.keys(filledForms)?.length}
            />
          ]}
          className="footer-blurred mb-10 items-center pb-2"
          onGoBack={onGoBack}
          isInput={false}
        />
      </div>
    </div>
  );
};

export default EnhanceNoteView;
