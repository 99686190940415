import React, { useState } from 'react';
import AdsWrapper from 'components/shared/Ads/Wrapper';
import { withErrorBoundary } from '../shared/Error/Boundary';
import InstantActionPage from 'components/InstantActions';

const InstantActionsWrapper = () => {
  const [actionType, setActionType] = useState(null);

  if (actionType)
    return <InstantActionPage actionType={actionType} setActionType={setActionType} />;
  return (
    <AdsWrapper>
      <InstantActionPage actionType={actionType} setActionType={setActionType} />
    </AdsWrapper>
  );
};

export default withErrorBoundary(InstantActionsWrapper);
