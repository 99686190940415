import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import {
  camelCaseToReadableFormat,
  iaRa,
  snakeToTitleCase,
  stripHtmlTags
} from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';

const SingleRow = ({ row, device }) => {
  const [showDetails, setShowDetails] = React.useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const details = useMemo(() => row.details, [row.details]);

  const formatText = (text) => {
    if (typeof text === 'number') {
      text = text.toString();
    }
    if (!text || typeof text !== 'string') return '';
    const words = text?.replace(/([a-z])([A-Z])/g, '$1 $2')?.split(/(?=[A-Z])/);
    const readableText = iaRa(words).join(' ');
    const capitalizeText = readableText?.charAt(0).toUpperCase() + readableText?.slice(1);
    return stripHtmlTags(capitalizeText) || capitalizeText;
  };

  return (
    <>
      <div
        className={cs(
          'flex cursor-pointer justify-between gap-2',
          !row.details && (device === 'laptop' || device === 'desktop') ? 'flex-row' : 'flex-col'
        )}>
        {row?.label && <p className="font-400">{stripHtmlTags(snakeToTitleCase(row.label))}</p>}

        {row.value &&
          (row.details ? (
            <div
              className={cs('flex cursor-pointer justify-between gap-2')}
              onClick={() => toggleDetails()}>
              <p className="font-600">{camelCaseToReadableFormat(row.value)}</p>
              <div className="flex items-center gap-1">
                <Icon
                  icon="new-document-preview"
                  className="cursor-pointer"
                  size={18}
                  color="primary"
                  shade={700}
                />
                <p className="font-500 text-primary-700">
                  {showDetails ? 'Hide details' : 'View details'}
                </p>
                <Icon
                  icon="new-chevron-down"
                  size={18}
                  className={
                    showDetails
                      ? 'rotate-180 cursor-pointer transition-all'
                      : 'cursor-pointer transition-all'
                  }
                  color="primary"
                  shade={700}
                />
              </div>
            </div>
          ) : Array.isArray(row.value) ? (
            <div
              className={cs(
                device === 'laptop' || (device === 'desktop' && '!max-w-[60%]'),
                'flex flex-col'
              )}>
              {row.value?.map((val) => (
                <p
                  key={val}
                  className={
                    device === 'laptop' || device === 'desktop'
                      ? ' text-right font-600'
                      : 'font-600'
                  }>
                  {formatText(val)}
                </p>
              ))}
            </div>
          ) : (
            <p
              className={
                device === 'laptop' || device === 'desktop'
                  ? '!max-w-[60%] text-right font-600'
                  : 'font-600'
              }>
              {formatText(row.value)}
            </p>
          ))}
      </div>

      {details && showDetails && (
        <div className={cs('mb-2 flex flex-col gap-2')}>
          {details?.map((detail, index) => (
            <div
              key={detail?.label}
              className={cs(
                'flex w-full gap-1 text-sm text-primary-900',
                index < details.length - 1 &&
                  'border-0 border-b border-solid border-primary-50 pb-3',
                details.length === 1 && 'border-0 border-solid border-primary-50',
                index === 0 && 'mt-3 border-t pt-3',
                device === 'laptop' || device === 'desktop'
                  ? 'flex-row justify-between'
                  : 'flex-col'
              )}>
              <p className="font-400">{stripHtmlTags(detail?.label)}</p>
              <p
                className={cs(
                  'font-600',
                  (device === 'laptop' || device === 'desktop') && '!max-w-[60%] text-right'
                )}>
                {formatText(detail?.value)}
              </p>
            </div>
          ))}
        </div>
      )}

      {row.table &&
        Object.values(row.table)?.some((row) => row != null && row != '') &&
        Object.keys(row.table).map(
          (key) =>
            row.table?.[key] != null &&
            row.table?.[key] != '' && (
              <div key={key} className="flex justify-between gap-2 text-sm text-primary-700">
                <p className="font-400">{snakeToTitleCase(key)}</p>
                <p className="font-500">{row.table?.[key]}</p>
              </div>
            )
        )}
    </>
  );
};

const ReviewCard = ({ data }) => {
  const navigate = useNavigate();

  const { device } = useUIContext();

  return (
    <div
      className={cs(
        device === 'laptop' || device === 'desktop' ? '!max-w-[650px]' : '!max-w-[550px]',
        '!w-full rounded-xl bg-gradient-to-r from-[#DFF5FF] to-[#B6E9FF] p-5 '
      )}>
      <div className="j-center flex items-center justify-between">
        <p className="font-medium text-primary-500">{data?.name}</p>
        <div
          onClick={() => navigate(`../${data?.url}`)}
          className="flex h-7 w-7 cursor-pointer items-center justify-center rounded-lg bg-primary-50 hover:bg-primary-100 active:bg-primary-200">
          <Icon className="cursor-pointer" icon="new-edit" size={18} />
        </div>
      </div>

      <div className="mt-4 flex w-full flex-col gap-3">
        {data?.fields?.map((row, idx) => (
          <div
            key={row?.label || idx}
            className={cs(
              'flex w-full flex-col gap-1 text-sm text-primary-900',
              idx < data?.fields?.length - 1 &&
                'border-0 border-b border-solid border-primary-50 pb-3'
            )}>
            {row.type === 'form' ? (
              <div>
                <p className={cs(idx > 0 && 'mt-3', 'mb-5 text-base font-500 text-primary-600')}>
                  {stripHtmlTags(row?.label)}
                </p>
                <div className="flex w-full flex-col gap-3">
                  {row.value && row.value?.length > 0 ? (
                    row.value.map((vals, idx) => (
                      <div
                        key={vals?.label || idx}
                        className={cs(
                          'flex w-full flex-col gap-1 text-sm text-primary-900',
                          idx < row?.value?.length - 1 &&
                            'border-0 border-b border-solid border-primary-50 pb-3'
                        )}>
                        <SingleRow device={device} key={idx} row={vals} idx={idx} />
                      </div>
                    ))
                  ) : (
                    <p>No data</p>
                  )}
                </div>
              </div>
            ) : (
              <SingleRow device={device} row={row} idx={idx} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReviewCard;
