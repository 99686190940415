import { Popover } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import moment from 'moment-timezone';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import Axios from '../../../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { formatDate } from '../../../../../../../../lib/helpers/utility';
import { Session as initialValue } from '../../../../../../../shared/Forms/Custom/CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../../../../../../../shared/Forms/Custom/lib/customFormsHelper';
import { formatSections } from '../../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV2';
import Icon from '../../../../../../../shared/Icon/Icon';
import Input from '../../../../../../../shared/Input/Input';
import RadioButton from '../../../../../../../shared/RadioButton/RadioButton';
import Select from '../../../../../../../shared/Select/Select';
import { userState } from '../../../../../../../state';
import { currentPractice } from '../../../../../../practiceState';

const Session = ({ sectionRef = null }) => {
  const { clinicalNote, setClinicalNote = () => {}, cnDisplaySettings } = useClinicalNoteContext();
  const [session, setSession] = useState([]);
  const [timer, setTimer] = useState(null);
  const { appointmentId } = useParams();
  const practice = useRecoilValue(currentPractice);
  const [hours, setHours] = useState([]);

  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm = cnDisplaySettings?.sections?.progress?.session?.advancedForm;
  const user = useRecoilValue(userState);
  const textAreaRef = useRef();

  useEffect(() => {
    setSession(clinicalNote?.epn?.session || initialValue);
  }, [clinicalNote]);

  useEffect(() => {
    getHours();
    getAppointment();
  }, []);

  useImperativeHandle(sectionRef, () => ({
    formData: { epn: { session } }
  }));

  const syncSessionAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ sessionObject: updatedObject });
    } else {
      setSession(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleChange = (key, event) => {
    const updatedObject = {
      ...session,
      [key]: event
    };

    syncSessionAndClinicalNotes(updatedObject);
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote((prevState) => ({
      ...prevState,
      epn: {
        ...prevState.epn,
        session: updatedObject
      }
    }));
  };

  // todo: convert this with ReactQuery
  const getAppointment = async () => {
    try {
      if (clinicalNote?.epn?.session) return;
      let params = { id: appointmentId };
      const { data } = await Axios.post('/api/appointment/get_one', params);
      const { location, starts_at, ends_at } = data.appointment;
      if (data.appointment) {
        setSession({
          ...session,
          type:
            location === 'virtual' ? 'telehealth' : location === 'in_person' ? 'faceToface' : null,
          startDate: formatDate(starts_at, practice?.timezone),
          startTime: {
            value: moment.tz(starts_at, practice?.timezone).format('h:mm A'),
            label: moment.tz(starts_at, practice?.timezone).format('h:mm A')
          },
          endDate: formatDate(ends_at, practice?.timezone),
          endTime: {
            value: moment.tz(ends_at, practice?.timezone).format('h:mm A'),
            label: moment.tz(ends_at, practice?.timezone).format('h:mm A')
          }
        });
      }
    } catch (err) {
      Honeybadger.notify(`Appointment get one in clinical notes EPN - ${err} `);
    }
  };

  const getHours = () => {
    const locale = 'en';
    const newHours = [];

    moment.locale(locale);

    const startHr = practice?.working_hours?.startsAt?.hour || 0;
    const endHr = practice?.working_hours?.endsAt?.hour || 24;

    for (let hour = startHr; hour < endHr; hour++) {
      newHours.push({
        value: moment({ hour }).format('hh:mm A'),
        label: moment({ hour }).format('hh:mm A')
      });
      for (let minutes = 1; minutes < 12; minutes++) {
        newHours.push({
          value: moment({
            hour,
            minute: minutes * 5
          }).format('hh:mm A'),
          label: moment({
            hour,
            minute: minutes * 5
          }).format('hh:mm A')
        });
      }
    }
    setHours(newHours);
  };

  const handleNarrative = ({ sessionObject = session, event, scrollable = false, sync = true }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...sessionObject, narrative: event };

    setSession(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({ sessionObject = session, scrollable = false } = {}) => {
    const narrativeOptions = {
      data: sessionObject,
      isProvider: user?.kind === 'practitioner',
      sectionName: 'session'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      sessionObject,
      event: formattedNarrative,
      scrollable
    });
  };

  return (
    <React.Fragment>
      {isAdvancedForm && (
        <div>
          <div>
            <p className="!pb-3 font-500 text-neutral-800">Type of session</p>
            <div className="flex">
              <RadioButton
                wrapperClassName="w-max"
                label="telehealth"
                id="telehealth"
                value="telehealth"
                disabled={clinicalNote?.locked}
                isSelected={session?.type === 'telehealth'}
                onChange={(event) => handleChange('type', event.target.value)}
              />
              <RadioButton
                wrapperClassName="w-max"
                label="Face to face"
                id="faceToface"
                value="faceToface"
                disabled={clinicalNote?.locked}
                isSelected={session?.type === 'faceToface'}
                onChange={(event) => handleChange('type', event.target.value)}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 sm:grid-cols-1">
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1">
              <Popover>
                <div className="relative">
                  <Popover.Button className="w-full !p-0">
                    <Input
                      label="Start date"
                      rightIcon="new-calendar-gray"
                      value={session?.startDate || ''}
                    />
                  </Popover.Button>
                  <Popover.Panel className="absolute left-0 top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
                    <Calendar
                      date={new Date(session?.startDate)}
                      onChange={(event) =>
                        handleChange(
                          'startDate',
                          moment.utc(event).add('12', 'h').format('MMM D, YYYY')
                        )
                      }
                    />
                  </Popover.Panel>
                </div>
              </Popover>
              <Select
                label="Start time"
                placeholder="Start time"
                value={session?.startTime}
                options={hours}
                onChange={(event) => handleChange('startTime', event)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <Icon icon="new-clock" className="!mr-3" />
                }}
                menuPortalTarget={document.body}
              />
            </div>
            <div className="grid grid-cols-2 gap-2 sm:grid-cols-1 md:grid-cols-1">
              <Popover>
                <div className="relative">
                  <Popover.Button className="w-full !p-0">
                    <Input
                      label="End date"
                      rightIcon="new-calendar-gray"
                      value={session?.endDate || ''}
                    />
                  </Popover.Button>
                  <Popover.Panel className="absolute -left-1 top-[110%] z-30 flex w-max bg-white p-3 shadow-md">
                    <Calendar
                      date={new Date(session?.endDate)}
                      onChange={(event) =>
                        handleChange(
                          'endDate',
                          moment.utc(event).add('12', 'h').format('MMM D, YYYY')
                        )
                      }
                    />
                  </Popover.Panel>
                </div>
              </Popover>
              <Select
                label="End time"
                placeholder="End time"
                value={session?.endTime}
                options={hours}
                onChange={(event) => handleChange('endTime', event)}
                components={{
                  IndicatorSeparator: () => null,
                  DropdownIndicator: () => <Icon icon="new-clock" className="!mr-3" />
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
        </div>
      )}

      {cnDisplaySettings && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setSession}
            restData={{
              className: 'w-full',
              label: 'Session Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-session',
              'data-qa': 'narrative-session',
              name: 'Narrative-session',
              value: session?.narrative,
              forwardedRef: textAreaRef,
              formName: 'session'
            }}
            narrativeOptions={isAdvancedForm ? narrativeOptions : []}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default Session;
