import React from 'react';
import { useNavigate } from 'react-router-dom';

import { finalizeEra } from 'api/ERA';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Popover from 'components/shared/Popovers/Popover/Popover';

import { downloadEraFile } from '../lib/downloadEraFile';

const Actions = ({ data, node }) => {
  const navigate = useNavigate();

  const isFinalized = data?.finalized;

  const mutateFinalizeERA = useCustomMutation({
    invalidateQueryKey: 'eras',
    successMessage: 'ERA finalized successfully!',
    errorMessage: 'Failed to finalize ERA!',
    mutationFn: ({ eraId, finalized }) => finalizeEra(navigate, { eraId, fields: { finalized } })
  });

  const mutateDownloadERA = useCustomMutation({
    successMessage: 'ERA downloaded successfully!',
    errorMessage: 'Failed to download ERA!',
    mutationFn: ({ id }) => downloadEraFile(navigate, id)
  });

  const options = [
    {
      label: 'Download',
      onClick: () => mutateDownloadERA.mutate({ id: data.id }),
      icon: 'new-file-download'
    },
    {
      label: isFinalized ? 'Mark Pending' : 'Mark Finalized',
      onClick: () => {
        mutateFinalizeERA.mutate({ eraId: data.id, finalized: !isFinalized });
      },
      icon: isFinalized ? 'appointment-pending' : 'checkmark'
    }
  ];

  if (node?.group) {
    return null;
  }

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
    </div>
  );
};

export default Actions;
