import React, { useState } from 'react';
import { ia } from '../../../lib/helpers/utility';
import Button from '../Buttons/Button';
import CustomFormsModal from '../Modal/CustomFormsModal/CustomFormsModal';
import FormPacketModal from '../../practice/appointment/components/FormPacketAppointment/FromPacketModal';
import CheckinStepsStep from './Step';
import { requestApi } from '../../../api/Api';
import { createInstantPacket } from '../../../api/InstantPacket';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../Alert/Alert';
import Popup from '../Filters/Popup';
import Icon from '../Icon/Icon';

import cs from 'classnames';
import Allowed from '../Permissions/Allowed';

const CheckinStepsForms = ({ step, appointment, formik, kiosks }) => {
  const [show, setShow] = useState({ expanded: false, packet: false, form: false });
  const [selectedForm, setSelectedForm] = useState(null);

  const navigate = useNavigate();

  const sendFormsToKiosk = async (forms, kioskId) => {
    const formIds = forms || step.forms.map((form) => form.form_id);

    const packet = await createInstantPacket(navigate, {
      appointmentId: appointment.id,
      kiosk: true,
      patientId: appointment?.patient?.id,
      formIds
    });

    await requestApi({
      url: '/api/kiosk/command',
      params: {
        url: packet?.token,
        command: 'url-change',
        page: 'form',
        kioskId,
        patientId: appointment?.patient?.id
      }
    });

    showAlert({ title: 'Sent to Kiosk', color: 'success' });
  };

  const selectForm = async (formStep) => {
    if (!formStep) {
      setSelectedForm(formStep);
      return;
    }

    setSelectedForm(null);

    const onSuccess = (partData) => {
      const lForm = partData?.form;
      setSelectedForm(lForm);
    };

    await requestApi({
      url: '/api/patient/checkin/form/getResponse',
      params: { formId: formStep?.form_id, appointmentId: appointment?.id },
      onSuccess
    });
  };

  return (
    <>
      <CheckinStepsStep
        step={step}
        onClick={() => setShow((ps) => ({ ...ps, expanded: !ps.expanded }))}
        addInfo={`${step.numCompleted}/${step.forms.length}`}
        rightComponent={
          <Popup
            trigger={(open) => (
              <div
                data-qa="custom-form-popover"
                className={`flex h-[28px] w-[28px] items-center justify-center rounded-full border  border-neutral-300  hover:border ${
                  !open && 'hover:!bg-white'
                } ${open && 'border-none !bg-primary-900 transition-all'}`}>
                <Icon
                  icon="three-dots"
                  className="flex cursor-pointer"
                  color={open ? 'white' : 'black'}
                />
              </div>
            )}
            width={200}
            on={['hover', 'focus']}
            contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
            position={['top right', 'top center']}>
            {(close) => (
              <div className=" !py-[6px]">
                <div
                  data-qa="edit-custom-forms"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    setShow((ps) => ({ ...ps, packet: true }));
                    close();
                  }}>
                  <Icon size={18} icon="new-edit" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Edit</div>
                </div>
                <Allowed requiredPermissions="kiosk.read">
                  {kiosks && kiosks.length > 0 && (
                    <Popup
                      trigger={(open) => (
                        <div
                          data-qa="delete-product-btn"
                          className={cs(
                            'flex cursor-pointer items-center justify-between gap-1 !p-2 !px-4 transition-all hover:bg-primary-50',
                            open && 'bg-primary-50'
                          )}>
                          <div className="flex items-center gap-1">
                            <Icon
                              size={18}
                              icon="kiosk-nav"
                              color="primary"
                              className="cursor-pointer"
                            />
                            <div className="ml-1 text-sm text-primary-900">Send to Kiosk</div>
                          </div>
                          <Icon icon="new-chevron-right"></Icon>
                        </div>
                      )}
                      width={200}
                      contentStyle={{
                        borderRadius: '4px',
                        boxShadow: '0px 2px 16px 0px #004F6B33'
                      }}
                      position={['left center']}
                      on={['hover', 'focus']}>
                      {(close) => (
                        <div className=" !py-[6px]">
                          {kiosks?.map((kiosk) => (
                            <div
                              key={kiosk.id}
                              data-qa="edit-product-btn"
                              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                              onClick={() => {
                                sendFormsToKiosk(null, kiosk.id);
                                close();
                              }}>
                              <Icon
                                icon="kiosk"
                                size={18}
                                customColor={kiosk.color}
                                className="cursor-pointer"
                              />
                              <div className="ml-1 text-sm text-primary-900">{kiosk.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Popup>
                  )}
                </Allowed>
              </div>
            )}
          </Popup>
        }
      />
      {/* <Button
        size="small"
        text={'Send Pre auth'}
        onClick={() => sendFormsToKiosk(['pre-auth'])}></Button> */}

      {show.expanded && ia(step.forms) && (
        <div className="mt-1 flex max-h-[300px] w-full flex-wrap gap-1 overflow-auto pl-7 pr-1">
          {step.forms.map((form, index) => (
            <Button
              size="small"
              color={form.completed ? 'success' : 'danger'}
              key={index}
              className="grow"
              onClick={(e) => {
                selectForm(form);
                e.stopPropagation();
              }}
              text={form.name}
            />
          ))}
        </div>
      )}
      {selectedForm && (
        <CustomFormsModal
          sendFormsToKiosk={sendFormsToKiosk}
          showCustomForms={!!selectedForm}
          hideCustomForms={() => selectForm(null)}
          selectedForm={selectedForm}
          appointment={appointment}
          kiosks={kiosks}
        />
      )}
      {show.packet && (
        <FormPacketModal
          formik={formik}
          appointment={appointment}
          showFormPacketModal={show.packet}
          hideFormPacketModal={() => setShow((ps) => ({ ...ps, packet: false }))}
        />
      )}
    </>
  );
};

export default CheckinStepsForms;
