import Checkbox from 'components/shared/Checkbox/Checkbox';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import ReorderWrapper from 'components/shared/Tags/components/Reorder/ReorderWrapper';
import TagCreate from 'components/shared/Tags/components/Create';
import React from 'react';
import { usePatientChartContext } from 'lib/context/PatientChartContext/PatientChartContext';

const Actions = ({
  showUnpinTagsModal = false,
  updateDisplaySettings = () => {},
  openCreateModal = false,
  openReorderModal = false,
  showRemoveConfirmation = false,
  handleClosePinTagsModal = () => {},
  handleCreateModal = () => {},
  handleAddTags = () => {},
  handleReorderModal = () => {},
  closeRemoveTagModal = () => {},
  updateRemoveConfirmation = () => {}
}) => {
  const {
    isUnpinnedConfirmation,
    setIsUnpinnedConfirmation,
    isRemoveConfirmation,
    setIsRemoveConfirmation
  } = usePatientChartContext();
  return (
    <>
      {showUnpinTagsModal && (
        <Confirm
          handleOpen={showUnpinTagsModal}
          primaryBtnTxt="Yes, unpin tags"
          secondaryBtnTxt="No, not at this time"
          title="Are you sure you want to unpin tags from all charts?"
          icon="new-pin-filled"
          iconSize={24}
          handleClose={handleClosePinTagsModal}
          handleContinue={updateDisplaySettings}>
          <div className="flex flex-col gap-3">
            <p className="text-sm text-neutral-700">
              This will hide excess tags on all patient charts.
            </p>
            <Checkbox
              isChecked={isUnpinnedConfirmation}
              handleClick={() => setIsUnpinnedConfirmation(!isUnpinnedConfirmation)}
              label="Don’t show this message again"
              labelClassName="text-sm !text-neutral-600"
              size={18}
            />
          </div>
        </Confirm>
      )}
      {openCreateModal && (
        <TagCreate
          isOpen={openCreateModal}
          handleClose={handleCreateModal}
          kind="chart"
          addNewTagFromCreated={handleAddTags}
        />
      )}
      {openReorderModal && (
        <ReorderWrapper openReorderModal={openReorderModal} handleClose={handleReorderModal} />
      )}
      {showRemoveConfirmation && (
        <Confirm
          handleOpen={showRemoveConfirmation}
          variant="danger"
          primaryBtnTxt="Remove"
          secondaryBtnTxt="No, not at this time"
          title="Are you sure you want to remove this tag from the patient's chart?"
          icon="new-pin-filled"
          iconColor="danger"
          iconSize={24}
          handleClose={closeRemoveTagModal}
          handleContinue={updateRemoveConfirmation}>
          <Checkbox
            isChecked={isRemoveConfirmation}
            handleClick={() => setIsRemoveConfirmation(!isRemoveConfirmation)}
            label="Don’t show this message again"
            labelClassName="text-sm !text-neutral-600"
            size={18}
          />
        </Confirm>
      )}
    </>
  );
};

export default Actions;
