import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPayments } from '../../../../api/Billing';

export const usePayments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['payments', ...dependencies], () => getPayments(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
