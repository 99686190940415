import React, { memo } from 'react';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { colDefs } from '../../lib/colDefs';

import SelectedTestsTable from './SelectedTestsTable';

const SelectedTestsTableWrapper = ({ getGridApi }) => {
  return (
    <div className="h-full order-5 col-span-3 md:pb-2 lg:pb-2">
      <TableContextProvider name="lab_tests" cols={colDefs}>
        <SelectedTestsTable getGridApi={getGridApi} />
      </TableContextProvider>
    </div>
  );
};

export default memo(SelectedTestsTableWrapper);
