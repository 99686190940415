import React, { useMemo, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { usePackages } from 'lib/hooks/queries/packages/usePackages';

import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import TableCounter from 'components/shared/Table/TableCounter';

import Skeleton from '../../../shared/Skeleton/Skeleton';

import HeaderTable from './components/HeaderTable';
import PackageModal from './components/PackageModal';
import { defaultColumns, defaultFilters } from './lib/helpers';

const Packages = () => {
  const columnDefs = defaultColumns();

  return (
    <TableContextProvider
      defaultFilters={defaultFilters}
      cols={columnDefs}
      name="packages"
      pagination>
      <PackagesTable />
    </TableContextProvider>
  );
};

const PackagesTable = () => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(false);

  const { limit, page, sort, setPage, filters } = useTableContext();

  const { data, isLoading, isFetching } = usePackages({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      withCount: true
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: {
      select: (data) => ({
        ...data,
        packages: data.packages.map((pack) => ({
          ...pack,
          products: pack.products.map((prod) => ({ ...prod, label: prod.name })),
          procedures: pack.procedures.map((procedure) => ({ ...procedure, label: procedure.name }))
        }))
      })
    }
  });

  const packages = data?.packages;
  const count = data?.packageCount;

  const onCellClicked = async (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    setSelectedPackage(event?.data);
    setShowEditModal(true);
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100
    };
  }, []);

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
    setSelectedPackage(false);
  };

  return (
    <Allowed requiredPermissions="packages.read" showMessage={true} showIllustration={true}>
      <HeaderTable setNewPackage={onEditModalToggle} />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="flex h-full flex-col overflow-hidden">
          <div className="ag-theme-quartz !mb-0 h-full">
            <AGTable
              data={packages}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              onCellClicked={onCellClicked}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          </div>
          <div className="flex items-center justify-between px-3">
            <TableCounter page={page} limit={limit} count={count && count} />
            <Pagination
              onPageChange={({ selected }) => setPage(selected + 1)}
              totalItems={count ? count : null}
              page={page}
              perPage={limit}
            />
          </div>
        </div>
      )}
      {showEditModal && (
        <PackageModal
          currentPackage={selectedPackage}
          isOpen={showEditModal}
          handleClose={onEditModalToggle}
          filters={filters}
        />
      )}
    </Allowed>
  );
};

export default Packages;
