import { Popover } from '@headlessui/react';
import React from 'react';
import Icon from '../../../../../shared/Icon/Icon';

export default function LogPopover({ data }) {
  return (
    <Popover className={'h-full'}>
      {({ close }) => (
        <>
          <Popover.Button className="flex h-4 w-4 items-center border-solid justify-center gap-[2px] rounded-full border border-neutral-300 bg-neutral-100 p-0">
            <div className="flex h-[1px] w-[1px] rounded-full bg-neutral-500"></div>
            <div className="flex h-[1px] w-[1px] rounded-full bg-neutral-500"></div>
            <div className="flex h-[1px] w-[1px] rounded-full bg-neutral-500"></div>
          </Popover.Button>

          <Popover.Panel className="absolute z-[99999] mt-1  w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !p-2 shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
            {data?.map((option, index) => {
              return (
                <div
                  key={index}
                  className="flex gap-2 rounded-lg !p-2 transition-all hover:bg-primary-50"
                  onClick={(e) => {
                    option.method(e);
                    close();
                  }}>
                  <Icon icon={option.icon} className="flex items-center" />
                  <div className="text-sm text-primary-900">{option.label}</div>
                </div>
              );
            })}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
}
