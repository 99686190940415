import React, { useEffect, useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Loader from 'components/shared/Loader/Loader';
import cs from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAiTask } from 'api/Admin/intelligence/aiTask';
import Switch from 'components/shared/Switch/Switch';

const AdminIntelligenceAiTaskModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [aiTaskId, setAiTaskId] = useState();
  const [selectedAiTask, setSelectedAiTask] = useState();
  const [show, setShow] = useState({ loading: false, original: false });
  const navigate = useNavigate();

  useEffect(() => {
    if (searchParams.get('aiTaskId')) {
      getData();
    }
  }, [searchParams]);

  const getData = async () => {
    if (show.loading) return;
    setShow((ps) => ({ ...ps, loading: true }));
    const taskId = parseInt(searchParams.get('aiTaskId'), 10);
    setAiTaskId(taskId);
    const res = await getAiTask(navigate, { id: taskId });
    setSelectedAiTask(res.data);
    setShow((ps) => ({ ...ps, loading: false }));
  };

  return (
    <Modal
      slideFromRight
      isOpen={aiTaskId}
      bodyClassName="!p-0"
      title={`AI Task #${aiTaskId}`}
      className={cs('bg-white', 'w-9/12')}
      handleClose={() => {
        setAiTaskId(null);
        setSearchParams((sp) => sp.delete('aiTaskId'));
      }}>
      {show?.loading && (
        <div className="flex flex-row gap-2">
          <Loader outlined color="primary" />
          <div>Loading...</div>
        </div>
      )}
      {!show?.loading && (
        <div className="flex h-full flex-col gap-4 bg-neutral-100 p-4">
          <div className="flex flex-row items-center gap-2 rounded-2xl bg-white p-2">
            <div>Show Original String</div>
            <Switch
              checked={show.original}
              color="primary"
              onChange={() => setShow((ps) => ({ ...ps, original: !ps.original }))}
            />
          </div>
          <div className="flex h-full flex-row items-center justify-center gap-2">
            <div className="flex h-full w-1/2 flex-col rounded-2xl border-2 bg-white p-2">
              <strong>Request</strong>
              <hr />
              {!show.original ? (
                <div
                  className="flex flex-col"
                  dangerouslySetInnerHTML={{ __html: selectedAiTask?.request }}></div>
              ) : (
                <div>{selectedAiTask?.request}</div>
              )}
            </div>
            <div>➡️</div>
            <div className="flex h-full w-1/2 flex-col rounded-2xl bg-white p-2">
              <strong>Response</strong>
              <hr />
              {!show.original ? (
                <div dangerouslySetInnerHTML={{ __html: selectedAiTask?.response }}></div>
              ) : (
                <div>{selectedAiTask?.response}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default withErrorBoundary(AdminIntelligenceAiTaskModal);
