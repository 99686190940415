import React from 'react';
import Button from 'components/shared/Buttons/Button';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { componentButtons, popoverOptions } from '../../constants';
import useComponentAdd from '../../hooks/useComponentAdd';

const ComponentButtons = ({ onTagPreview = () => {}, onTagRemove = () => {} }) => {
  const { inputTags, selectedTableRows, onTemplateClick } = useComponentAdd();

  const getPopoverOptions = (id) => [
    { label: 'Edit', icon: 'new-edit', onClick: () => onTagPreview(id) },
    { label: 'Remove', icon: 'trash', onClick: () => onTagRemove(id) }
  ];

  return (
    <div className="mb-2 flex flex-wrap justify-center gap-1">
      {Object.values(componentButtons)?.map((componentButton) => {
        const isActive = inputTags.some((tag) => tag.text === componentButton.text);

        return (
          <>
            {!isActive ? (
              <Button
                key={componentButton.id}
                onClick={() => onTemplateClick(componentButton)}
                icon={componentButton.icon}
                className="z-30 !py-1 text-neutral-600"
                text={
                  componentButton?.includedInHashtags
                    ? componentButton.text
                    : `Add ${componentButton.text}`
                }
                textClassName="text-neutral-700 font-thin"
                color="neutral"
                outlined
                size="small"
              />
            ) : (
              <Popover
                isDropdown
                isSeparator
                isBorder
                text={
                  componentButton?.includedInHashtags
                    ? componentButton.text
                    : `Add ${componentButton.text}`
                }
                buttonTheme={
                  selectedTableRows?.[componentButton?.id]?.status === 'partial'
                    ? 'warning'
                    : 'primary'
                }
                buttonClassName="!min-h-[30px]"
                icon="history-filled"
                position="left"
                options={getPopoverOptions(componentButton?.id)}
              />
            )}
          </>
        );
      })}
      <Popover
        options={popoverOptions}
        isFixed
        buttonClassName="text-neutral-600 !outline !outline-1 !outline-neutral-200 border-neutral-200 !min-h-[28px] !min-w-[28px]"
        className="self-center"
        optionIconColor="neutral"
      />
    </div>
  );
};

export default ComponentButtons;
