import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useClaims } from 'lib/hooks/queries/claims/useClaims';

import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ButtonActions from './components/ButtonActions';
import { CLAIMS_SWITCH_ITEMS, DEFAULT_FILTERS, getColDefs } from './configs';

const getFinalColDefs = (name, timezone) => {
  const colDefs = [...getColDefs(name, timezone)];

  return colDefs;
};

const category = 'claims';

const NewClaims = () => {
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;
  const navigate = useNavigate();

  const { id } = useParams();

  const NEW_COL_DEFS = getFinalColDefs(category, practiceTimezone);

  return (
    <>
      <AgTablePage
        defaultColumns={NEW_COL_DEFS}
        defaultFilters={DEFAULT_FILTERS}
        useData={useClaims}
        name={category}
        onSelectRow={(claim) => navigate(`${claim?.id}`)}
        rowStyle={{ cursor: 'pointer' }}
        switchItems={CLAIMS_SWITCH_ITEMS}
        actions={ButtonActions}
      />
      {id && <Outlet />}
    </>
  );
};

export default NewClaims;
