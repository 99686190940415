import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';

const ActionsButton = ({ appliedFilter, open }) => (
  <div className="flex cursor-pointer select-none items-center gap-2">
    <Icon
      color={open || appliedFilter ? 'primary' : 'neutral'}
      shade={open || appliedFilter ? '700' : '500'}
      icon="filter-favorite"
    />
    {appliedFilter ? (
      <div className="flex items-center gap-1" data-qa="filter:">
        <span
          className={cs(
            'text-sm font-500',
            open || appliedFilter ? 'text-primary-700' : 'text-neutral-500'
          )}>
          Filter:
        </span>
        <span
          className={cs(
            'text-sm font-bold first-letter:uppercase',
            open || appliedFilter ? 'text-primary-700' : 'text-neutral-500'
          )}>
          {appliedFilter}
        </span>
      </div>
    ) : (
      <span
        data-qa="filter-preferences"
        className={cs('text-sm font-500', open ? 'text-primary-700' : 'text-neutral-500')}>
        Filter preferences
      </span>
    )}
  </div>
);

export default ActionsButton;
