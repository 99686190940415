import React, { useEffect, useState } from 'react';
import RecordVisit from './components/RecordVisit';
import Icon from '../../../../../../shared/Icon/Icon';
import Transcription from './components/Transcription';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../../lib/helpers/utility';
import { useNavigate, useParams } from 'react-router-dom';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const MyScribeAI = ({ context }) => {
  const { currentMyScribe, isFetching } = useAmbientListeningContext();
  const { components, setComponents } = useClinicalNoteContext();
  const { id: patientId, formName, appointmentId } = useParams();
  const [formsList] = useState({
    'record-visit': RecordVisit,
    transcription: Transcription
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (!patientId || !appointmentId || !ia(currentMyScribe)) return;

    navigate(`/portal/charts/${patientId}/clinical-notes/${appointmentId}/myscribe/transcription`);
  }, [currentMyScribe]);

  useEffect(() => {
    setComponents({
      'record-visit': {
        title: 'Record visit',
        path: 'record-visit'
      },
      transcription: {
        title: 'Transcript',
        path: 'transcription'
      }
    });
  }, []);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  if (!io(components) || !CurrentForm || isFetching)
    return <Skeleton height="calc(100vh - 214px)" />;

  return (
    <div className="relative">
      <div className="relative z-10">
        <CurrentForm
          context={context}
          customId={appointmentId}
          key={formName}
          components={components}
        />
      </div>

      <Icon
        icon="myscribe-transcription-background-pattern"
        className="fixed bottom-10 left-[264px] z-[5]"
      />
    </div>
  );
};

export default MyScribeAI;
