import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { ia } from '../../../lib/helpers/utility';
import PatientNavbar from '../../navbars/PatientNavbar/PatientNavbar';
import Button from '../../shared/Button/Button';
import Loading from '../../shared/Loading/Loading';
import './index.scss';
import schedulingState from './schedulingState';

const sidebarSteps = [
  { id: 1, name: 'Select Practice', url: '/book/practice', practice: '' },
  { id: 2, name: 'Select Service', url: '/book/service', service: '' },
  { id: 3, name: 'Select Date & Time', url: '/book/date-and-time', date_time: '' },
  { id: 4, name: 'Contact Information', url: '/book/contact-info', contact_info: '' },
  { id: 5, name: 'Card On File', url: '/book/card-on-file', card_on_file: '' },
  { id: 6, name: 'Confirmation', url: '/book/confirmation' }
];

export default function ScheduleAppointment() {
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);
  const [sidebarItems, setSidebarItems] = useState(sidebarSteps);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const queryParam = params.get('token');
  const { pathname } = useLocation();

  const [hasParams, setHasParams] = useState(!!queryParam);

  useEffect(() => {
    if (!hasParams) {
      setHasParams(!!queryParam || !!appointmentData?.savedToken);
    }
  }, [appointmentData]);

  useEffect(() => {
    getStatus();
    if (pathname == '/book' || pathname == '/book/') {
      navigate(`/book/practice${queryParam ? `?token=${queryParam}` : ''}`);
    }
  }, [_, pathname]);

  useEffect(() => {
    rememberSteps();
  }, [_, pathname]);

  useEffect(() => {
    if (
      appointmentData?.hasOwnProperty('practice_require_card') &&
      appointmentData.practice_require_card === false
    )
      setSidebarItems((prevState) => prevState.filter((step) => step.id !== 5));
  }, [appointmentData]);

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = async () => {
    setLoading((prev) => ({ ...prev, token: true }));

    const { data } = await interimApi('/api/user/check_token', {}, () => { });

    if (data?.token_valid) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }

    setLoading(false);
  };

  const rememberSteps = async () => {
    try {
      const parsedJSON = JSON.parse(localStorage.getItem('stepsCompleted'));
      let completedSteps = null;
      if (ia(parsedJSON)) {
        const both = [...JSON.parse(localStorage.getItem('stepsCompleted')), pathname].filter(
          (url) => {
            if (url != '/book/' && url != '/book' && url != '/confirmation') {
              return url;
            }
          }
        );
        completedSteps = JSON.stringify(new Set(both));
      } else {
        completedSteps = JSON.stringify([pathname]);
      }
      localStorage.setItem('stepsCompleted', completedSteps);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getStatus = async () => {
    setLoading(true);
    try {
      let params = {};
      if (queryParam) params = { ...params, savedToken: queryParam };

      if (pathname != '/book/confirmation') {
        const { status, data } = await interimApi(
          '/api/patient/appointment/self_schedule',
          params,
          navigate
        );

        if (status == 200) setAppointmentData(data?.object || {});
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
    setLoading(false);
  };

  const sideBarSelectedContent = (item) => {
    if (item.hasOwnProperty('practice') && appointmentData.practice_name) {
      return <p className="Selected">{appointmentData?.practice_name}</p>;
    }
    if (item.hasOwnProperty('service') && appointmentData.service_name) {
      return <p className="Selected">{appointmentData?.service_name}</p>;
    }
    if (
      item.hasOwnProperty('date_time') &&
      appointmentData.practitioner_name &&
      appointmentData.starts_at
    ) {
      return (
        <>
          <div>
            <p className="Selected">{appointmentData.practitioner_name}</p>
          </div>
          <div>
            <p className="Selected">
              {moment
                .tz(appointmentData.starts_at, appointmentData.practice_timezone)
                .format('M/D/YYYY h:mm A z')}
            </p>
          </div>
        </>
      );
    }
    if (item.hasOwnProperty('contact_info') && appointmentData.contact_info) {
      return <p className="Selected">{appointmentData?.contact_info}</p>;
    }
  };

  const changeRoute = (url) => {
    navigate(url);
  };

  if (!hasParams && (loading || !isLoggedIn)) {
    return (
      <>
        <div className="NoAccess">
          {loading ? (
            <Loading />
          ) : (
            <>
              <PatientNavbar />
              <p>Please login in order to view this page!</p>
              <Button primary onClick={() => navigate('/login')}>
                Go to login
              </Button>
            </>
          )}
        </div>
      </>
    );
  }

  return (
    <div className="SelfSchedule">
      <div className="SelfSchedule__header">
        <h4>Request an appointment</h4>
      </div>
      <div className="SelfSchedule__body">
        <div className="SelfSchedule__sidebar">
          {sidebarItems?.map((item, idx) => {
            const parsedJSON = JSON.parse(localStorage.getItem('stepsCompleted'));
            return (
              <div
                onClick={() =>
                  ia(parsedJSON) && parsedJSON?.includes(item.url) && changeRoute(item.url)
                }
                key={idx}>
                <div className={'SidebarItem'}>
                  <label
                    className={'SidebarNumbers'}
                    style={{
                      backgroundColor: `${pathname?.includes(item.url) ? 'black' : 'gray'}`
                    }}>
                    {ia(parsedJSON) && parsedJSON.includes(item.url) ? <p>&#10004;</p> : idx + 1}
                  </label>
                  <div>
                    <label
                      className="cursor-pointer"
                      style={{ color: `${pathname.includes(item.url) ? 'black' : 'gray'}` }}>
                      {item.name}
                    </label>
                    {sideBarSelectedContent(item)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="SelfSchedule__main w-full">
          <Outlet context={{ setSidebarItems, setAppointmentData, appointmentData }} />
        </div>
      </div>
    </div>
  );
}
