import React, { useCallback, useRef, useState } from 'react';

import Button from '../../Buttons/Button';
import Icon from '../../Icon/Icon';
import Modal from '../Modal';

import PreAuthForm from './PreAuthForm';

import './PreAuthFormModal.scss';

const PreAuthFormModal = ({
  showPreAuthModal,
  hidePreAuthModal,
  subModal,
  showOverrides,
  ...rest
}) => {
  const [show, setShow] = useState({
    startNewTransaction: false,
    transactionStartText: 'Continue'
  });

  const configurationRef = useRef();
  const formikRef = useRef();

  const Header = useCallback(() => {
    return (
      <div className="flex w-full items-center justify-between">
        <h6 className="whitespace-nowrap">Pre-Authorized Healthcare Form</h6>
        <div className="flex gap-2">
          <Button color="neutral" outlined text="Back" onClick={hidePreAuthModal} />
          <Button
            text={show.transactionStartText}
            onClick={(e) => {
              if (configurationRef?.current) {
                if (formikRef?.current) {
                  formikRef?.current?.isValid && configurationRef?.current?.startPayment(e);
                }
              }
            }}
          />
        </div>
      </div>
    );
  }, []);
  return (
    <Modal
      handleOpen={showPreAuthModal}
      handleClose={hidePreAuthModal}
      isLarge
      portalClassName="PreAuthFormModal"
      bodyClassName="bg-white !px-6 !pb-10 xs:!px-3"
      parentSelector={
        subModal ? () => document.getElementById('AppointmentPreviewModal') : undefined
      }
      slideFromRight
      subModal={subModal}
      prevButton={
        subModal ? (
          <Button close onClick={hidePreAuthModal}>
            <Icon icon="back-large" />
          </Button>
        ) : (
          <></>
        )
      }
      headButton={<Header />}
      className="xs:!w-full sm:!w-full md:!w-full"
      headerCloseIcon={false}
      isFooter={false}>
      <PreAuthForm
        showOverrides={showOverrides}
        show={show}
        setShow={setShow}
        hidePreAuthModal={hidePreAuthModal}
        configurationRef={configurationRef}
        formikRef={formikRef}
        hideButton={true}
        fromPreAuthModal={true}
        {...rest}
      />
    </Modal>
  );
};

export default PreAuthFormModal;
