import React, { forwardRef } from 'react';

import state from '/components/state';
import { useRecoilValue } from 'recoil';

import { ia } from '../../../lib/helpers/utility';
import { currentPractice } from '../../practice/practiceState';
import Loading from '../Loading/Loading';

import ReceiptFooter from './components/Footer';
import ReceiptHeader from './components/Header';
import ReceiptItems from './components/Items';
import ReceiptPatientTransactionDetails from './components/PatientTransactionDetails';
import ReceiptTotal from './components/Total';
import ReceiptTransactions from './components/Transactions';
import ReceiptUpcomingAppointments from './components/UpcomingAppointments';

const Receipt = forwardRef(({ receipt, walkout = false, parentRef }) => {
  const userState = useRecoilValue(state.userState);
  const patient = receipt?.patient;
  const transaction = receipt?.transaction;
  const transactions = receipt?.transactions;
  const invoice = receipt?.invoice;

  let practice = useRecoilValue(currentPractice);

  const isPatient = userState?.logged_in && !practice?.id;

  if (!practice.id) {
    practice = receipt?.practice;
  }

  if (!patient || !practice) return <Loading />;

  const { timezone } = practice || {};

  return (
    <>
      <div
        ref={parentRef}
        className="-m-4 flex min-h-[-webkit-fill-available;] flex-col !gap-4 overflow-auto bg-white p-10 sm:p-4">
        <ReceiptHeader
          patient={patient}
          practice={practice}
          timezone={timezone}
          transaction={transaction}
        />

        <ReceiptPatientTransactionDetails
          patient={patient}
          timezone={timezone}
          transaction={transaction}
          showDetails={!walkout}
        />

        <ReceiptItems
          walkout={walkout}
          timezone={timezone}
          transactions={
            walkout
              ? [{ ...transaction, invoice }]
              : ia(transactions)
                ? transactions
                : [transaction]
          }
          isPatient={isPatient}
        />

        <ReceiptTotal
          showDetails={!walkout}
          transaction={transaction}
          transactions={ia(transactions) ? transactions : [transaction]}
          isPatient={isPatient}
        />

        {walkout && <ReceiptTransactions transactions={transactions} timezone={timezone} />}

        <ReceiptUpcomingAppointments
          timezone={timezone}
          patientId={transaction?.patient_id || patient?.id}
        />

        <ReceiptFooter practice={practice} />
      </div>
    </>
  );
});

export default Receipt;
