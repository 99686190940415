import React from 'react';
import { ia } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import WeightLossAdministrationModal from '../Modals/WeightLossAdministrationModal';
import CreationInfo from './CreationInfo';

const WeightLossAdministrationOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  currentHpOverviewData = {},
  setCurrentHpOverviewData = {},
  overviewData = {},
  setOverviewData = {}
}) => {
  if (
    !cnSettings?.overview.weight_loss_administration &&
    !cnSettings?.overview.weightLossAdministration
  ) {
    return null;
  }

  return (
    <SimpleAccordion
      title="Weight loss administration"
      onToggle={() => toggleOne('weightLossAdministration')}
      open={isOpen.weightLossAdministration}
      edit={
        <WeightLossAdministrationModal
          formId={currentHpOverviewData?.weightLossAdministration?.id}
          defaultValue={currentHpOverviewData?.weightLossAdministration?.response}
          setCurrentHpOverviewData={setCurrentHpOverviewData}
          setCustomResponses={setOverviewData}
        />
      }>
      {ia(overviewData?.weightLossAdministration) &&
        overviewData?.weightLossAdministration?.map((weightLossAdministration, index) => {
          return (
            <React.Fragment key={index}>
              {weightLossAdministration?.response?.initialWeight && (
                <div className="flex justify-between">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Initial weight:
                  </span>
                  <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                    {weightLossAdministration?.response?.initialWeight} lbs
                  </span>
                </div>
              )}
              {weightLossAdministration?.response?.goalWeight && (
                <div className="flex justify-between">
                  <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                    Goal weight:
                  </span>
                  <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                    {weightLossAdministration?.response?.goalWeight} lbs
                  </span>
                </div>
              )}
              {weightLossAdministration?.response?.row?.map((item, idx) => (
                <React.Fragment key={idx}>
                  <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                  <div className="grid gap-y-1">
                    {item.medication && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Medication:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.medication.value}
                        </span>
                      </div>
                    )}
                    {item.location && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Location:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.location}
                        </span>
                      </div>
                    )}
                    {item.doses && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Dosage:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.dosesUnit
                            ? `${item.doses} ${item.dosesUnit}`
                            : `${item.doses} units`}
                        </span>
                      </div>
                    )}
                    {item.typeOfInjection && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Type of injection:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.typeOfInjection}
                        </span>
                      </div>
                    )}
                    {item.date && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Date:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.date}
                        </span>
                      </div>
                    )}
                    {item.weight && (
                      <div className="flex justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Weight:
                        </span>
                        <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.weight} lbs
                        </span>
                      </div>
                    )}
                    {item.notes && (
                      <div className="flex flex-wrap  justify-between">
                        <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                          Note:
                        </span>
                        <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                          {item.notes}
                        </span>
                      </div>
                    )}
                  </div>
                </React.Fragment>
              ))}
              {!weightLossAdministration?.response?.noDetails &&
                weightLossAdministration?.response?.generalNote && (
                  <>
                    <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                    <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                      General Note:
                    </span>
                    <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                      {weightLossAdministration?.response?.generalNote}
                    </span>
                  </>
                )}
              <CreationInfo
                createdAt={weightLossAdministration?.createdAt}
                createdBy={weightLossAdministration?.createdBy?.fullName}
              />
            </React.Fragment>
          );
        })}
    </SimpleAccordion>
  );
};

export default WeightLossAdministrationOverview;
