import React from 'react';

import { useIncomeIQueue } from 'lib/hooks/queries/billing/useIncomeIQueue';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ButtonActions from './components/ButtonActions';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';

const IncomeIQueue = () => {
  return (
    <AgTablePage
      defaultColumns={defaultColumns}
      defaultFilters={DEFAULT_FILTERS}
      useData={useIncomeIQueue}
      title="Income I Queue"
      name="appointment"
      actions={ButtonActions}
    />
  );
};

export default IncomeIQueue;
