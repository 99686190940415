import React, { useState } from 'react';
import PermissionTitle from './PermissionTitle';
import PermissionActions from './PermissionActions';
import Input from 'components/shared/Input/Input';
import Icon from 'components/shared/Icon/Icon';
import SortPermissions from './SortPermissions';
import { useRole } from 'lib/hooks/queries/roles/useRole';
import Skeleton from 'react-loading-skeleton';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import { convertPermissions } from './lib/helper';
import useDebounce from 'lib/hooks/useDebounce';
import { io } from 'lib/helpers/utility';
import { useParams } from 'react-router-dom';

const sort = [
  { label: 'Name', value: 'name' },
  { label: 'Active', value: 'active' }
];

const EditPermissions = ({ roleId }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm);
  const [selected, setSelected] = useState(null);
  const [sortOrder, setSortOrder] = useState(sort[0]);
  const { formik, actionsEnabled, setActionsEnabled } = useEditRoleContext();
  const { id } = useParams();

  const { data, isLoading, isFetching } = useRole({
    params: {
      role_id: roleId,
      practice_id: id,
      search_term: debouncedSearchTerm,
      order_by: sortOrder.value
    },
    dependencies: [roleId, debouncedSearchTerm, sortOrder],
    options: {
      refetchOnMount: true,
      onSuccess: (data) => {
        const key = Object.keys(data?.permissions)[0];
        setSelected(key);

        if (!io(formik.values.permissions)) {
          const permissions = data.permissions;
          const permissionValues = convertPermissions(permissions);
          formik.setFieldValue('permissions', permissionValues);
          formik.setFieldValue('role_id', roleId);
          formik.setFieldValue('users_assigned', data.users_assigned);
          let actionNumbers = {};
          Object.keys(permissions).forEach((permissionKey) => {
            actionNumbers[permissionKey] = permissions[permissionKey].actionsEnabled;
          });
          setActionsEnabled(actionNumbers);
        }
      }
    }
  });

  const permissions = data?.permissions;

  return (
    <>
      {isLoading && isFetching ? (
        <Skeleton rows={11} />
      ) : (
        <>
          <div className="inline-flex h-8 w-full items-center justify-between gap-2.5 px-6">
            <div className="font-['Plus Jakarta Sans'] shrink grow basis-0 text-xs font-semibold leading-tight text-[#8fa0a7]">
              PERMISSIONS FOR THIS ROLE
            </div>
            <div className="flex items-center justify-start gap-4">
              <SortPermissions sort={sort} sortOrder={sortOrder} setSortOrder={setSortOrder} />
              <div className="border-[#eceff0 flex h-8 w-[280px] items-center justify-start gap-2.5 rounded-[27px] border px-2.5 py-1.5">
                <Input
                  id="searchPermissions"
                  type="string"
                  placeholder="Search permissions"
                  value={searchTerm}
                  icon="new-search"
                  className="w-full"
                  inputWrapperClassName="w-full !rounded-none !bg-transparent !py-2 !pb-1 !pl-0 border-b-[1px] !border-[#dadfe2] border-0 justify-start items-center gap-3 inline-flex !outline-transparent focus-within:!border-b-[1px] focus-within:!border-[#dadfe2] focus-within:!outline-transparent"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  rightText={
                    searchTerm && (
                      <Icon
                        icon="new-close"
                        onClick={() => {
                          setSearchTerm('');
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
          {permissions && Object.keys(permissions).length === 0 ? (
            <div className="flex h-[700px] w-full items-start justify-start gap-6 px-6">
              <div className="inline-flex h-[143.70px] w-full flex-col items-center justify-start gap-4">
                <div className="relative h-[105.70px] w-[126.50px]">
                  <Icon icon="no-results" />
                </div>
                <div className="font-['Plus Jakarta Sans'] text-sm font-semibold leading-snug text-[#13b8ff]">
                  No Permissions Found
                </div>
              </div>
            </div>
          ) : (
            <div className="flex h-[700px] w-full items-start justify-start gap-6 px-6">
              <div className="flex h-[700px] w-fit flex-col items-start justify-start overflow-y-auto overflow-x-clip ">
                {Object.keys(permissions).map((item) => {
                  return (
                    <PermissionTitle
                      key={roleId + '' + item}
                      title={permissions[item]?.title}
                      description={permissions[item]?.description}
                      permissionsActive={actionsEnabled[item]}
                      isSelected={selected === item}
                      setSelected={setSelected}
                      permissionKey={item}
                      highlight={searchTerm}
                    />
                  );
                })}
              </div>
              <div className="flex w-[768px] flex-col items-end justify-start gap-[21px]">
                <PermissionActions
                  actions={permissions[selected]?.actions}
                  permissionKey={selected}
                  highlight={searchTerm}
                />
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default EditPermissions;
