import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import React from 'react';

const TranscriptionLoading = () => {
  return (
    <div className="flex w-full flex-col items-center justify-start bg-primary-900">
      <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />
      <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90" />

      <div className="relative bottom-10 flex h-full w-1/2 flex-grow flex-col items-center justify-start gap-5">
        <div className="z-50">
          <Icon icon="ai-coding" className="mb-5" />
        </div>
        <div className="z-50 text-center text-white">
          <div className="text-2xl font-medium ">Recording ended successfully!.</div>
          <div className="mt-2 text-base text-primary-100">Transcription is being generated.</div>
        </div>
        <div className="z-50 h-[30px] w-[30px]">
          <svg className="circle-wrapper" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
          </svg>
        </div>
        <div className="flex h-full w-full flex-col gap-5">
          {Array(5)
            .fill()
            .map((item) => (
              <div key={item} className="flex gap-5">
                <div className="h-full w-full">
                  <Skeleton
                    baseColor="#D3D3D3"
                    highlightColor="#FAFAFA"
                    count={1}
                    height="12px"
                    width="30%"
                  />
                  <Skeleton
                    baseColor="#D3D3D3"
                    highlightColor="#FAFAFA"
                    count={1}
                    height="40px"
                    width="100%"
                  />
                </div>
                <div className="h-full w-full">
                  <Skeleton
                    baseColor="#D3D3D3"
                    highlightColor="#FAFAFA"
                    count={1}
                    height="12px"
                    width="30%"
                  />
                  <Skeleton
                    baseColor="#D3D3D3"
                    highlightColor="#FAFAFA"
                    count={1}
                    height="40px"
                    width="100%"
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
export default TranscriptionLoading;
