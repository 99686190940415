import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Icon from '../../../../../../shared/Icon/Icon';
import { formatSize, fileIcon, getFileExtension } from '../lib';

function DragAndDropFiles({ selectedFiles, setFieldValue }) {
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedFiles = Array.from(selectedFiles);
    const [movedFile] = reorderedFiles.splice(result.source.index, 1);
    reorderedFiles.splice(result.destination.index, 0, movedFile);

    setFieldValue('files', reorderedFiles);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="file-list">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef} className="">
            {selectedFiles.map((file, idx) => (
              <Draggable key={file.draggableId} draggableId={file?.draggableId} index={idx}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="!mb-2 flex h-[46px] items-center justify-between rounded-lg bg-primary-50 !p-2">
                    <div className="flex items-center gap-6">
                      <Icon icon={fileIcon[getFileExtension(file.name)]} />
                      <div className="flex flex-col">
                        <h3 className="w-[350px] truncate text-sm text-neutral-900 md:w-[200px]">
                          {file.name}
                        </h3>
                        <p className="text-xs text-neutral-400">
                          {formatSize(file.size) || `Charts/${file.patientName}/Documents`}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center gap-1">
                      <Icon
                        icon="trash"
                        onClick={() =>
                          setFieldValue(
                            'files',
                            selectedFiles.filter((_, index) => index !== idx)
                          )
                        }
                      />
                      <Icon icon="new-sort" className="cursor-grab " />
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DragAndDropFiles;
