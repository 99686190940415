import React from 'react';
import { Calendar } from 'react-date-range';

import { Popover } from '@headlessui/react';
import { useRecoilValue } from 'recoil';

import Allowed from 'components/shared/Permissions/Allowed';
import { permissions } from 'components/state';

import { genders, marital_status, pronounOptions, races } from '../../../../../constants';
import { formatDate, ia, optionify, pm } from '../../../../../lib/helpers/utility';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import Input from '../../../../shared/Input/Input';
import Select from '../../../../shared/Select/Select';

const PersonalInfo = ({
  formik,
  onChange,
  occupationList,
  getSSN,
  decodedSSN,
  setDecodedSSN,
  practice_state,
  disabled
}) => {
  const userPermissions = useRecoilValue(permissions);
  const userCanEditSecure = pm(userPermissions, 'secure.update');

  return (
    <div>
      <label className="text-lg font-600">Personal Info</label>

      <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
        <Input
          data-qa="first-name"
          name="f_name"
          validateName
          label="First name"
          placeholder="First name"
          onChange={onChange}
          value={formik?.values?.f_name}
          error={formik?.errors?.f_name}
          disabled={disabled}
        />

        <Input
          data-qa="middle-name"
          name="m_name"
          validateName
          label="Middle Name"
          onChange={onChange}
          placeholder="Middle Name"
          value={formik.values.m_name}
          disabled={disabled}
        />

        <Input
          data-qa="last-name"
          name="l_name"
          validateName
          label="Last name"
          onChange={onChange}
          placeholder="Last name"
          value={formik?.values?.l_name}
          error={formik?.errors?.l_name}
          disabled={disabled}
        />

        <Input
          data-qa="preferred-name"
          name="p_name"
          validateName
          label="Preferred name"
          onChange={onChange}
          placeholder="Preferred name"
          value={formik?.values?.p_name}
          error={formik?.errors?.p_name}
          disabled={disabled}
        />

        <Select
          inputId="pronoun-select"
          name="pronoun"
          label="Pronouns"
          placeholder="Type and select pronouns"
          options={optionify(pronounOptions, 'label', 'value')}
          onChange={(e) => formik.setFieldValue('pronoun', e?.value)}
          value={pronounOptions.find((l) => l?.value === formik.values.pronoun)}
          disabled={disabled}
        />

        <Input
          data-qa="phone"
          name="phone"
          label="Cell Phone"
          onChange={onChange}
          placeholder="+1(123)4567890"
          value={formik?.values?.phone}
          error={formik?.errors?.phone}
          disabled={disabled}
        />

        <div className="flex flex-col gap-1">
          <Input
            data-qa="alt_phone"
            name="alt_phone"
            label="Alternative Phone"
            onChange={onChange}
            placeholder="+1(321)4567890"
            value={formik?.values?.alt_phone}
            error={formik?.errors?.alt_phone?.replace('alt_phone', 'Alternative Phone')}
            disabled={disabled}
          />
          {formik?.values?.alt_phone && (
            <Checkbox
              isChecked={formik.values.alt_phone_notify}
              handleClick={() =>
                onChange({
                  target: { name: 'alt_phone_notify', value: !formik?.values?.alt_phone_notify }
                })
              }
              label="Enable for notifications"
              disabled={disabled}
            />
          )}
        </div>

        <Input
          data-qa="email"
          name="email"
          label="Email"
          placeholder="email@example.com"
          onChange={onChange}
          value={formik.values.email}
          error={formik?.errors?.email}
          disabled={disabled}
        />

        <div className="flex flex-col gap-1">
          <Input
            data-qa="alt_email"
            name="alt_email"
            label="Alternative Email"
            placeholder="alternative_email@example.com"
            onChange={onChange}
            value={formik.values.alt_email}
            error={formik?.errors?.alt_email?.replace('alt_email', 'Alternative Email')}
            disabled={disabled}
          />
          {formik?.values?.alt_email && (
            <Checkbox
              isChecked={formik.values.alt_email_notify}
              handleClick={() =>
                onChange({
                  target: { name: 'alt_email_notify', value: !formik?.values?.alt_email_notify }
                })
              }
              label="Enable for notifications"
              disabled={disabled}
            />
          )}
        </div>

        <Popover className="relative -mt-[3px]">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  data-qa="date-of-birth"
                  label="Date of birth"
                  rightIcon="new-calendar-gray"
                  value={formatDate(formik?.values?.dob, undefined, true)}
                  error={formik?.errors?.dob}
                  disabled={disabled}
                />
              </Popover.Button>

              <Popover.Panel className="absolute z-10 flex shadow-md">
                <Calendar
                  minDate={new Date(1850, 0, 1)}
                  date={new Date(formik?.values?.dob) || new Date()}
                  onChange={(date) => {
                    formik.setFieldValue('dob', new Date(date).toLocaleDateString('en-US'));
                    close();
                  }}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>

        <Select
          inputId="gender-select"
          name="gender"
          label="Gender"
          placeholder="Type and select gender"
          options={optionify(genders, 'label', 'value')}
          onChange={(e) => formik.setFieldValue('gender', e?.value)}
          value={genders.find((l) => l?.value === formik.values.gender)}
          disabled={disabled}
        />

        <Select
          inputId="race-select"
          name="race"
          label="Ethnicity"
          placeholder="Type and select race"
          options={optionify(races, 'label', 'value')}
          onChange={(e) => formik.setFieldValue('race', e?.value)}
          value={races.find((l) => l?.value === formik.values.race)}
          disabled={disabled}
        />

        <Select
          inputId="maritalstatus-select"
          name="marital_status"
          label="Marital Status"
          placeholder="Type and select marital status"
          options={optionify(marital_status, 'label', 'value')}
          onChange={(e) => formik.setFieldValue('marital_status', e?.value)}
          value={marital_status.find((l) => l?.value === formik.values.marital_status)}
          disabled={disabled}
        />

        <Select
          inputId="occupation-id-select"
          name="occupation_id"
          label="Occupation"
          placeholder="Type and select occupation"
          options={
            ia(occupationList)
              ? occupationList.map((occupation) => ({
                  label: occupation.name,
                  value: occupation.id
                }))
              : []
          }
          onChange={(e) => formik.setFieldValue('occupation_id', e?.value)}
          value={
            ia(occupationList)
              ? occupationList.map((l) => {
                  if (l?.id === formik.values.occupation_id)
                    return { value: l?.id, label: l?.name };
                })
              : null
          }
          disabled={disabled}
        />

        <Input
          data-qa="drivers-license"
          name="personal_id"
          onChange={onChange}
          label="Driver's License"
          placeholder="Driver's License"
          value={formik?.values?.personal_id}
          disabled={disabled}
        />

        <Allowed requiredPermissions="secure.read">
          <div>
            <div className="relative">
              <Input
                data-qa="social-security-number"
                name="ssn"
                onChange={onChange}
                label="Social Security Number"
                placeholder="Social Security Number"
                value={formik?.values?.ssn}
                type={
                  !formik?.initialValues?.ssn
                    ? 'text'
                    : formik?.values.ssn && !decodedSSN
                      ? 'password'
                      : 'text'
                }
                rightIcon={
                  formik?.values?.ssn &&
                  practice_state?.secure?.read &&
                  (!formik?.initialValues?.ssn
                    ? ''
                    : formik?.values.ssn && decodedSSN
                      ? 'new-eye'
                      : 'new-eye-slash')
                }
                rightIconClick={() =>
                  formik?.initialValues?.ssn && !decodedSSN ? getSSN() : setDecodedSSN(!decodedSSN)
                }
                hint={!formik.values?.ssn && 'No SSN on file'}
                disabled={!userCanEditSecure || disabled}
              />
            </div>
          </div>
        </Allowed>
      </div>
    </div>
  );
};

export default PersonalInfo;
