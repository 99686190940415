import React, { forwardRef } from 'react';

import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';

import InvoicePrintView from './InvoicePrintView';
import NewInvoice from './NewInvoice';
import NewInvoiceView from './NewInvoiceView';

const InvoicePreview = forwardRef(({ selectedRows, externalInvoices }, ref) => {
  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;

  return (
    <>
      {selectedRows.map((finalInvoice, idx) => {
        const externalInvoice = externalInvoices?.find(
          (invoice) => invoice.patient_id === finalInvoice.patient.id
        );

        const invoiceDetails = getProceduresTotal([finalInvoice]);

        return (
          <div key={idx} ref={ref}>
            <InvoicePrintView
              practice={practice}
              patient={finalInvoice?.patient}
              createdAt={finalInvoice?.dateofservice}
              content={
                <NewInvoice invoice={finalInvoice} isView isAEOB={externalInvoice?.aeob}>
                  <NewInvoiceView
                    hasClaims={claims}
                    invoiceDetails={{
                      ...invoiceDetails,
                      discount: externalInvoice?.discount_amount_cents || 0,
                      tax_percentage: externalInvoice?.tax_percentage || 0,
                      tax_amount_cents: externalInvoice?.tax_amount_cents || 0,
                      surcharge_amount: externalInvoice?.surcharge_amount || 0,
                      memo: externalInvoice?.memo,
                      aeob: externalInvoice?.aeob
                    }}
                  />
                </NewInvoice>
              }
            />
          </div>
        );
      })}
    </>
  );
});

export default InvoicePreview;
