import React from 'react';
import { formatPhoneNumber } from 'lib/helpers/utility';

const ReceiptFooter = ({ practice }) => {
  const { name, phone, email, header_photo } = practice || {};

  return (
    <div className="mt-4 flex justify-between px-4">
      <div>
        <p className="text-xxs font-600 text-neutral-900">{name}</p>
        <p className="text-xxs text-neutral-600">{email}</p>
        <p className="text-xxs text-neutral-600">{formatPhoneNumber(phone)}</p>
      </div>
      {header_photo && (
        <img
          alt="Practice logo"
          className="w-24 object-contain"
          src={`https://cdn.filestackcontent.com/${JSON.parse(header_photo).jpg}`}
        />
      )}
    </div>
  );
};

export default ReceiptFooter;
