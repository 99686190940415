import { formatDateAndTime } from 'lib/helpers/utility';

import PreviewCell from '../patientHistory/components/PreviewCell';

export const getColDefs = (breadcrumb, timezone, setPreviewData) => [
  {
    field: 'created_at',
    headerName: 'Creation Date',
    valueFormatter: ({ value }) => (value ? formatDateAndTime(value, timezone) : ''),
    cellClass: 'no-border',
    sort: 'desc',
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'preview',
    cellClass: 'no-border',
    headerName: 'Preview',
    cellRenderer: ({ data }) =>
      data?.created_at ? (
        <PreviewCell data={data} breadcrumb={breadcrumb} setPreviewData={setPreviewData} />
      ) : null
  }
];

export const DEFAULT_COLUMN_DEFS = { flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};
