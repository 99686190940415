import React, { memo, useCallback } from 'react';

import {
  usePatientBalanceContextApi,
  usePatientBalanceContextData
} from 'lib/context/PatientBalanceContext/PatientBalanceContext';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Textarea from 'components/shared/Textarea/Textarea';

const WriteOffForm = memo((params) => {
  const { setFormData } = usePatientBalanceContextApi();
  const { formData } = usePatientBalanceContextData();

  const transaction = params.data;
  const currentFormData = formData?.[transaction.id];

  const onAmountChange = useCallback(
    (value) => {
      const amount = +value;

      setFormData((prev) => ({
        ...prev,
        [transaction.id]: {
          ...transaction,
          ...(prev?.[transaction.id] && prev?.[transaction.id]),
          amount
        }
      }));
    },
    [setFormData, transaction]
  );

  const onNoteChange = useCallback(
    (e) => {
      const note = e.target.value;
      setFormData((prev) => ({
        ...prev,
        [transaction.id]: {
          ...transaction,
          ...(prev?.[transaction.id] && prev?.[transaction.id]),
          note
        }
      }));
    },
    [setFormData, transaction]
  );

  return (
    <div className="!border-primary mb-3 flex h-[139px] gap-[15px] border bg-primary-10 px-2 py-3">
      <div className="!w-1/4 mt-1">
        <CurrencyInput
          minValue={0}
          id="amount"
          name="amount"
          label="Amount"
          maxValue={transaction.balance}
          onValueChange={onAmountChange}
          value={currentFormData?.amount || transaction?.balance || 0}
        />
      </div>

      <div className="!w-3/4">
        <Textarea
          label="Note"
          id="description"
          name="description"
          onChange={onNoteChange}
          className="min-h-[200px]"
          placeholder="Enter note here"
          value={currentFormData?.note || ''}
        />
      </div>
    </div>
  );
});

export default WriteOffForm;
