import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getMyScribe } from '../../../../api/MyScribe';

export const useMyScribe = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['myscribe', ...dependencies], () => getMyScribe(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
