import React from 'react';
import Modal from '../Modal/Modal';
import BreadCrumbs from './BreadCrumbs/BreadCrumbs';
import Switch from '../Switch/Switch';
import Button from '../Buttons/Button';
import { ia } from 'lib/helpers/utility';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import cs from 'classnames';
import ErrorBoundary from '../Error/Boundary';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const Wizard = ({
  isOpen,
  handleClose,
  title,
  footerButtons,
  children,
  handleSaveProgress,
  handleAutoSave,
  autoSave,
  breadcrumbs,
  showAutoSave,
  showSaveProgress,
  saveProgressLoading,
  setBreadCrumbs,
  enableNavigation = false
}) => {
  const handleNavigation = (view, index) => {
    const previousIndex = index > 0 ? index - 1 : index == 0 ? index : null;
    const canNavigate =
      enableNavigation || (previousIndex !== null && breadcrumbs[previousIndex]?.isFinished);
    if (canNavigate) {
      setBreadCrumbs((prevBreadcrumbs) =>
        prevBreadcrumbs.map((crumb, idx) => ({
          ...crumb,
          active: idx === index
        }))
      );
    }
  };

  const HeaderButtons = () => {
    return (
      <div className="ml-3 flex w-full items-center !justify-between">
        {device !== 'tablet-sm' && ia(breadcrumbs) && (
          <BreadCrumbs breadcrumbs={breadcrumbs} onNavigate={handleNavigation} />
        )}
        <div className={cs('!mr-4 flex gap-4', device === 'tablet-sm' && 'ml-auto')}>
          {showAutoSave && (
            <div className="flex items-center gap-[6px]">
              <Switch onChange={handleAutoSave} checked={!!autoSave} />
              <span className="text-[14px] font-400 text-primary-900">Auto-save</span>
            </div>
          )}
          {showSaveProgress && (
            <Button
              type="secondary"
              icon="new-diskette"
              transparent
              disabled={saveProgressLoading}
              loadingIcon={saveProgressLoading}
              text={saveProgressLoading ? 'Saving' : 'Save Progress'}
              onClick={handleSaveProgress}
              size="small"
              className="!border-none !bg-primary-50"
            />
          )}
        </div>
      </div>
    );
  };

  const { device } = useUIContext();
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Modal
        slideFromRight
        isOpen={isOpen}
        handleClose={handleClose}
        title={title}
        headButton={<HeaderButtons />}
        footer={footerButtons}
        bodyClassName="!p-0 bg-primary-10"
        className={cs(
          '!bg-white',
          device === 'tablet-sm' ? 'w-[729px]' : device === 'tablet' ? 'w-[984px]' : 'w-[1240px]'
        )}>
        {device === 'tablet-sm' && ia(breadcrumbs) && (
          <div className="bg-white p-4">
            <BreadCrumbs breadcrumbs={breadcrumbs} onNavigate={handleNavigation} />
          </div>
        )}
        {children}
      </Modal>
    </ErrorBoundary>
  );
};

export default Wizard;
