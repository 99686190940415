import React from 'react';
import Icon from '../Icon/Icon';

const CheckinStepsStep = ({ step, onClick, addInfo = null, onEdit, rightComponent }) => {
  return (
    <div
      key={`step-${step?.step_id}-${step?.form_id}`}
      className="flex items-center justify-between">
      <div className="flex flex-row items-center gap-1">
        {step?.completed ? (
          <Icon icon="checkmark-circle" color="primary" />
        ) : (
          <Icon icon="new-close-circle" />
        )}
        <p className="!mx-2 select-none text-sm font-400">{step?.name}</p>
        {addInfo && <p className="self-end text-xs italic text-neutral-500">{addInfo}</p>}
        {onClick && <Icon icon="new-eye" onClick={onClick} />}
      </div>
      {onEdit && <Icon icon="new-edit" onClick={onEdit} color="black" />}

      {rightComponent && rightComponent}
    </div>
  );
};

export default CheckinStepsStep;
