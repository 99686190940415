import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChatContextApi, ChatContextData } from './ChatContext';
import { useMyScribeAIContext } from '../../MyScribeAIContext';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useMyScribeTemplateContextData } from '../MyScribeTemplateContext';
import { getSelectedForms } from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyScribeTemplate/lib/helpers';
import { generateAdvancedSectionFormMap } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';

export const ChatContextProvider = ({ children }) => {
  const [inputTags, setInputTags] = useState([]);
  const [componentToAdd, setComponentToAdd] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState({});
  const { typeOfNotes } = useMyScribeAIContext();
  const [selectedPromptNoteType, setSelectedPromptNoteType] = useState(null);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const {
    setEnhanceNarrativeParams,
    currentHpOverviewData,
    overviewData,
    cnDisplaySettings,
    customFormTypes
  } = useClinicalNoteContext();
  const { userPrompt } = useMyScribeTemplateContextData();
  const selectedPromptTagsIds = inputTags.map((tag) => tag.id);
  let selectedForms = getSelectedForms(selectedPromptTagsIds, clinicalNote, selectedTableRows, {
    ...currentHpOverviewData,
    ...overviewData
  });

  const sectionMap = {
    hp: cnDisplaySettings?.hp?.enabled,
    intake: cnDisplaySettings?.mha?.enabled,
    soap: cnDisplaySettings?.soap?.enabled,
    progress: cnDisplaySettings?.epn?.enabled,
    '3d': cnDisplaySettings?.chart3d?.enabled,
    ...generateAdvancedSectionFormMap({ cnDisplaySettings, customFormTypes })
  };

  const enabledSection = Object.entries(sectionMap).find(([, isEnabled]) => isEnabled)?.[0];
  const initialType = cnDisplaySettings?.soap.enabled ? 'soap' : enabledSection;

  const onComponentAdd = useCallback(() => {
    if (!inputTags.some((tag) => tag.id === componentToAdd?.id)) {
      setInputTags((prev) => [...prev, componentToAdd]);
    }
    setComponentToAdd(null);
  }, [componentToAdd, inputTags]);

  const onComponentRemove = useCallback(() => {
    const filteredTags = inputTags.filter((tag) => tag.id !== componentToAdd?.id);
    setInputTags(filteredTags);
  }, [componentToAdd, inputTags]);

  const onEnhanceNote = useCallback(
    (editedUserPrompt = null) => {
      let newSelectedForms = [];

      if (selectedForms?.patient_history) {
        for (const ph of selectedForms?.patient_history) {
          if (!newSelectedForms?.includes(ph?.type)) newSelectedForms.push(ph?.type);
        }
      }

      setEnhanceNarrativeParams({
        kind: 'auto_map',
        messageKind: 'auto_map',
        body: editedUserPrompt || userPrompt,
        name: selectedPromptNoteType?.type,
        clinicalNoteId: clinicalNote?.id,
        selectedForms: newSelectedForms
      });
    },
    [
      clinicalNote?.id,
      selectedForms,
      selectedPromptNoteType?.type,
      setEnhanceNarrativeParams,
      userPrompt
    ]
  );

  const finalData = useMemo(
    () => ({
      inputTags,
      componentToAdd,
      selectedTableRows,
      selectedPromptNoteType
    }),
    [inputTags, componentToAdd, selectedTableRows, selectedPromptNoteType]
  );

  const setTags = useCallback(setInputTags, [setInputTags]);
  const setComponent = useCallback(setComponentToAdd, [setComponentToAdd]);
  const setSelectedRows = useCallback(setSelectedTableRows, [setSelectedTableRows]);
  const setSelectedNoteType = useCallback(setSelectedPromptNoteType, [setSelectedPromptNoteType]);

  const api = useMemo(
    () => ({
      setSelectedTableRows: setSelectedRows,
      setSelectedPromptNoteType: setSelectedNoteType,
      setComponentToAdd: setComponent,
      setInputTags: setTags,
      onAddComponent: onComponentAdd,
      onRemoveComponent: onComponentRemove,
      onEnhanceNote
    }),
    [
      setSelectedRows,
      setSelectedNoteType,
      setComponent,
      setTags,
      onComponentAdd,
      onComponentRemove,
      onEnhanceNote
    ]
  );

  useEffect(() => {
    setSelectedPromptNoteType(typeOfNotes[initialType]);
  }, [initialType, typeOfNotes]);

  return (
    <ChatContextData.Provider value={finalData}>
      <ChatContextApi.Provider value={api}>{children}</ChatContextApi.Provider>
    </ChatContextData.Provider>
  );
};
