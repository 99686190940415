import { useState, useEffect } from 'react';

const useChartSize = (container) => {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [proportion, setProportion] = useState(0);
  const containerHeight = container?.offsetHeight || 0;
  const containerWidth = container?.offsetWidth || 0;
  const containerProportion = containerHeight / 100;

  useEffect(() => {
    const updateSizes = () => {
      if (container) {
        setHeight(containerHeight);
        setWidth(containerWidth);
        setProportion(containerHeight / 100);
      }
    };

    updateSizes();

    window.addEventListener('resize', updateSizes);

    return () => {
      window.removeEventListener('resize', updateSizes);
    };
  }, [containerHeight, containerWidth, containerProportion, container]);

  return { height, width, proportion };
};

export default useChartSize;
