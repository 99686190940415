const atom = require('recoil').atom;

module.exports = {
  userState: atom({
    key: 'patientCheckinUserState',
    default: {
      id: 0,
      f_name: '',
      l_name: '',
      not_logged_in: true
    }
  }),
  selectionState: atom({
    key: 'patientCheckinSelectionState',
    default: {
      personal_info: null,
      personal_iden: null,
      insurance: null
    }
  }),
  checkinDataState: atom({
    key: 'checkinDataState',
    default: {
      forms: {}
    }
  })
};
