import { useMemo, useRef, useState } from 'react';

import Tippy from '@tippyjs/react';
import debounce from 'lodash.debounce';

import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';

const TestNameSearchInput = ({ value, changeHandler }) => {
  const [searchTerm, setSearchTerm] = useState(value);

  const pendingChangesRef = useRef('');

  const debouncedSearch = useMemo(
    () =>
      debounce(() => {
        changeHandler('searchTerm', pendingChangesRef.current);
        pendingChangesRef.current = '';
      }, 500),
    []
  );

  const clearSearchTerm = () => {
    setSearchTerm('');
    changeHandler('searchTerm', '');
    pendingChangesRef.current = '';
  };

  return (
    <Input
      label="Search tests by name:"
      id="tests-search"
      data-qa="searchTests"
      type="text"
      placeholder="ex. cbc w* diff"
      autoComplete="off"
      icon="new-search"
      inputClassName="!min-w-full"
      value={searchTerm}
      hint={
        <Tippy
          content="All searches will automatically use a trailing wild card. Wild card searches can be used to search for a test where the name contains a value."
          placement="bottom"
          className="tippy-dark">
          <span>Use an asterisk (*) as a wild card to match all or part of a test name.</span>
        </Tippy>
      }
      onChange={(e) => {
        pendingChangesRef.current = e.target.value;

        setSearchTerm(e.target.value);
        debouncedSearch();
      }}
      rightText={searchTerm && <Icon icon="new-close" onClick={clearSearchTerm} />}
    />
  );
};

export default TestNameSearchInput;
