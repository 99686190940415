import React from 'react';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import cs from 'classnames';

import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../../practiceState';
import { formatDateAndTimeZ } from '../../../../../../../../lib/helpers/utility';
import { processData } from '../../../../shared/helpers/documentSignatureHelpers';
import { useDocumentSign } from '../../../../../../../../lib/hooks/queries/documentSign/useDocumentSign';

const YourSignaturePreview = ({ itemProps }) => {
  const nonERXPrescriptionId = itemProps?.id;
  const practice = useRecoilValue(currentPractice);

  let { data: documentData, isFetching } = useDocumentSign({
    params: {
      documentIds: [nonERXPrescriptionId],
      documentTypes: ['non_erx_prescription'],
      withRelations: { user: true }
    },
    dependencies: [nonERXPrescriptionId],
    options: {
      refetchOnMount: false,
      select: (response) => {
        return {
          signatures: processData(response)
        };
      }
    }
  });

  if (isFetching) {
    return <Skeleton height="247px" />;
  }

  const { signatures = [] } = documentData || {};

  const signaturesMoreThanOne = signatures?.length > 1;

  return (
    <div className={`grid grid-cols-[repeat(${signaturesMoreThanOne ? '2' : '1'},1fr)] gap-2`}>
      {signatures?.map((row) => {
        return (
          <div className="!mt-3">
            <div class="!mb-2 text-base font-medium leading-normal text-neutral-800">
              {row.role}
            </div>
            <div
              class={cs(
                'w-full flex-col items-center justify-start gap-6 rounded-md border !border-primary-100 bg-primary-50 py-6'
              )}>
              <div class="text-center  text-[32px]">
                <img className="size-32 object-contain" src={row.signature} />
              </div>
              <div class="flex flex-col items-center justify-start gap-1.5">
                <div class="text-center text-sm text-neutral-800">{row.signedBy}</div>
                <div class="text-center text-sm text-neutral-800">
                  {row.date ? formatDateAndTimeZ(row.date, practice?.timezone) : 'N/A'}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default YourSignaturePreview;
