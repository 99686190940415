import React from 'react';
import Moment from 'moment';

export default function PreviewReferral({ referral }) {
  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-1">
          <span>Practice:</span>
          <span className="font-500">{referral.practice.name}</span>
        </div>
        <div className="flex gap-1">
          <span>Date:</span>
          <span className="font-500">{Moment(referral.created_at).format('MMM DD, YYYY')}</span>
        </div>
        <div className="flex gap-1">
          <span>Date:</span>
          <span className="font-500">{referral.body}</span>
        </div>
      </div>
    </div>
  );
}
