import React, { useRef, useState } from 'react';
import { MyTemplateContext } from './MyTemplateContext';

export const MyTemplateContextProvider = ({ children }) => {
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);

  const componentRefs = useRef({});

  return (
    <MyTemplateContext.Provider
      value={{
        selectedChatComponents,
        setSelectedChatComponents,
        componentRefs
      }}>
      {children}
    </MyTemplateContext.Provider>
  );
};
