import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getStates } from '../../../api/State';
import { findStateName, ia, optionify } from '../../../lib/helpers/utility';
import state from '../../state';
import Input from '../Input/Input';
import Select from '../Select/Select';

const Address = ({
  formik,
  updateAddress,
  initialValue,
  value,
  detailedInfo = true,
  label = 'Address'
}) => {
  const navigate = useNavigate();

  const [address, setAddress] = useState({
    address_ln_1: '',
    address_ln_2: '',
    city: '',
    state: '',
    zip: ''
  });
  const [errors, setErrors] = useState({
    address_ln_1: {},
    address_ln_2: {},
    city: {},
    state: {},
    zip: {}
  });
  const [stateList, setStateList] = useRecoilState(state.geographicalStatesState);

  useEffect(() => {
    if (initialValue) {
      setAddress(initialValue);
    }
    if (!Array.isArray(stateList) || stateList.length === 0) {
      loadStates();
    }
  }, []);

  const onAddressChange = (e) => {
    const newAddress = { ...address, [e.target.name]: e.target.value };
    setAddress(newAddress);
    updateAddress(newAddress);
  };

  const loadStates = async () => {
    try {
      const res = await getStates(navigate, { optionify: true, method: 'get' });
      setStateList(res);
    } catch (error) {
      Honeybadger.notify(
        `file: /address/Address, method: loadStates - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  return (
    // <div className="!mt-6 border-x-0 border-b-0 border-t-2 border-solid border-neutral-100">
    <div>
      {label && <h3 className="!my-4 text-black">{label}</h3>}
      <div className="grid gap-4">
        <div className="grid w-full grid-cols-2 !gap-4">
          <Input
            label="Address"
            placeholder="Enter Address"
            name="address_ln_1"
            value={formik?.values?.address_ln_1}
            onChange={formik?.handleChange}
            id="address_ln_1"
            error={formik?.errors?.address_ln_1}
          />
          <Input
            label="Address 2"
            placeholder="Enter Address 2"
            name="address_ln_2"
            value={formik?.values?.address_ln_2}
            onChange={formik?.handleChange}
            id="address_ln_2"
            error={formik?.errors.address_ln_2}
          />
        </div>

        <div className="grid w-full grid-cols-3 !gap-4">
          <Input
            label="City"
            placeholder="Enter City"
            name="city"
            value={formik?.values?.city}
            onChange={formik?.handleChange}
            id="city"
            error={formik?.errors.city}
          />

          <Select
            label="State"
            placeholder="Type and select State"
            value={
              formik?.values?.state && {
                label: findStateName({ states: stateList, stateId: formik?.values?.state }),
                value: formik?.values?.state
              }
            }
            onChange={(e) => formik?.setFieldValue('state', e?.value || null)}
            options={stateList}
            inputId="state"
            error={formik?.errors.state}
          />

          <Input
            label="ZIP Code"
            placeholder="Enter ZIP Code"
            name="zip"
            value={formik?.values?.zip}
            onChange={formik?.handleChange}
            id="zip"
            error={formik?.errors.zip}
          />
        </div>
      </div>
    </div>
  );
};

export default Address;
