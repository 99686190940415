import cs from 'classnames';

/**
 * @param {import('./lib/propTypes').ProgressProps} props
 */

const ProgressBar = ({ progress, className }) => (
  <div className={cs('relative h-[6px] w-full rounded-[6px] bg-neutral-200', className)}>
    <div
      className="absolute left-0 top-0 h-[6px] rounded-[6px] bg-primary-500"
      style={{ width: `${progress}%` }}>
      <div className="h-[6px] w-[8px] rounded-[6px] bg-primary-500"></div>
    </div>
  </div>
);

export default ProgressBar;
