import Popover from 'components/shared/Popovers/Popover/NewPopover';
import ReceiptModal from 'components/shared/Receipt/ReceiptModal';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InvoiceModal from './InvoiceModal';

const PaymentsActions = ({ data }) => {
  const navigate = useNavigate();

  const [showReceipt, setShowReceipt] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);

  const onViewReceipt = () => {
    setShowReceipt(true);
  };

  const onViewInvoice = () => {
    setShowInvoice(true);
  };

  const onViewEraAndEob = () => {
    navigate(`/portal/beyond-billing/era-eob/all/${data.id}`);
  };

  let options = [];

  if (data?.type === 'transaction') {
    options.push({ label: 'View Receipt', onClick: onViewReceipt, icon: 'new-eye' });
  }

  if (data?.invoice_id) {
    options.push({ label: 'View Invoice', onClick: onViewInvoice, icon: 'new-eye' });
  }

  if (data?.type === 'era') {
    options.push({ label: 'View ERA', onClick: onViewEraAndEob, icon: 'new-eye' });
  }

  if (data?.type === 'eob') {
    options.push({ label: 'View EOB', onClick: onViewEraAndEob, icon: 'new-eye' });
  }

  if (!options.length) {
    return null;
  }

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {showReceipt && (
        <ReceiptModal
          transactionId={data.id}
          isOpen={showReceipt}
          onClose={() => setShowReceipt(false)}
        />
      )}
      {showInvoice && (
        <InvoiceModal
          patient={{ id: data.patient_id, dob: data.patient_dob, fullName: data.patient }}
          invoiceId={data.invoice_id}
          isOpen={showInvoice}
          setIsOpen={setShowInvoice}
          onClose={() => setShowReceipt(false)}
        />
      )}
    </>
  );
};

export default PaymentsActions;
