import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { permissions } from 'components/state';
import { formatDateAndTime, ia } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';

const Confirmed = ({ data = {} }) => {
  const practice = useRecoilValue(currentPractice);
  const { is_admin } = useRecoilValue(permissions);

  return (
    <Allowed requiredPermissions="appointments.read">
      <div>
        {data?.confirmed && (
          <>
            <h4 className="text-sm font-500 text-neutral-800">Confirmed from</h4>
            <div className="flex items-center justify-between">
              <p className="ml-4 mt-[2px] text-sm font-400 text-neutral-800">
                {capitalize(data.confirmed_from)}
              </p>
              <p className="ml-4 mt-[2px] text-sm font-400 text-neutral-800">
                {formatDateAndTime(data.confirmed_at, practice?.timezone)}
              </p>
            </div>
          </>
        )}
        {/* //Im adding this feature flag "is_admin" temporary for testing in prod */}
        {ia(data?.reminders) && is_admin && (
          <div className="mt-2">
            <h4 className="mb-1 text-sm font-500 text-neutral-800">Reminders Sent</h4>
            {data?.reminders.map((reminder, idx) => (
              <div key={idx} className="mb-[6px] flex items-center justify-between">
                <div className=" flex items-center gap-[6px]">
                  <Icon
                    icon={
                      reminder.color === 'success'
                        ? 'new-check'
                        : reminder.color === 'warning'
                          ? 'new-partially-completed'
                          : 'new-close-v2'
                    }
                    color={reminder.color}
                    stroke={reminder.color !== 'warning'}
                    size={20}
                    className="cursor-pointer"
                  />
                  <p className="text-sm text-neutral-800">{reminder.medium}</p>
                  {reminder.type === 'remind' && (
                    <p className="text-xs italic text-neutral-500">scheduled</p>
                  )}
                </div>
                <p className="text-sm text-neutral-800">{reminder.created_at}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </Allowed>
  );
};

export default withErrorBoundary(Confirmed);
