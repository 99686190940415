import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCustomFormsByIds } from '../../../../api/CustomForms';

export const useCustomPtResponseById = ({ dependencies = [], params = {}, options }) => {
  const navigate = useNavigate();
  return useQuery(
    ['customPtResponseById', ...dependencies],
    () => getCustomFormsByIds(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
