import React, { useState } from 'react';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Input from 'components/shared/Input/Input';
import Icon from 'components/shared/Icon/Icon';
import AssignUser from './components/AssignUsers';
import { usePracticeMembers } from 'lib/hooks/queries/permissions/usePracticeMembers';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';
import cs from 'classnames';
import { useParams } from 'react-router-dom';

const AssignRole = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { newRoleSelectedUsers } = useNewRoleContext();
  const { id } = useParams();
  const { data = {}, isLoading } = usePracticeMembers({
    params: { searchTerm, practice_id: id },
    dependencies: [searchTerm]
  });

  const members = data?.members;

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {isLoading ? (
        <Skeleton count={1} height={1020} />
      ) : (
        <div className="inline-flex h-fit w-full shrink grow basis-0 flex-col items-start justify-start gap-[21px] self-stretch px-9 pt-10">
          <div className="inline-flex h-[42px] items-center justify-between self-stretch">
            <div className="text-center text-base font-medium leading-normal text-primary-900">
              Assign newly created role to staff members.
            </div>
            <div className="flex h-[42px] items-center justify-start gap-4">
              <div
                className={cs(
                  ' pt-3 text-center text-sm font-medium leading-snug text-primary-900',
                  newRoleSelectedUsers === 0 && 'opacity-25'
                )}>
                <span>{newRoleSelectedUsers} selected</span>
              </div>
              <div className="flex !h-[42px] w-[200px] items-center justify-start gap-2.5 !outline-none focus-within:!border-none">
                <Input
                  id="searchStaff"
                  type="string"
                  placeholder="Search staff"
                  value={searchTerm}
                  icon="new-search"
                  inputWrapperClassName="w-[200px] h-[42px] !rounded-none !bg-transparent !py-2 !pb-1 !pl-0 border-b-[1px] !border-neutral-200 border-0 justify-start items-center gap-3 inline-flex !outline-transparent focus-within:!border-b-[1px] focus-within:!border-neutral-200 focus-within:!outline-transparent"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  rightText={
                    searchTerm && (
                      <Icon
                        icon="new-close"
                        onClick={() => {
                          setSearchTerm('');
                        }}
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
          {members.map((member) => (
            <AssignUser member={member} key={member.id} />
          ))}
        </div>
      )}
    </ErrorBoundary>
  );
};

export default AssignRole;
