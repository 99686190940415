import React from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Button from 'components/shared/Buttons/Button';

const GenerateEmails = ({ file, show, setShow }) => {
  return (
    <div className="mx-auto my-auto flex h-fit w-fit flex-col justify-center overflow-hidden rounded-lg border-none bg-white p-2 transition duration-300">
      <span className="py-1 text-xl font-bold">Patient Import</span>
      <span className="text-l py-1 font-bold">Step 2/4</span>
      <div className="h-60vh w-50vw flex min-h-[20rem] min-w-[20rem] flex-col overflow-auto">
        <span className="py-1">File Info</span>
        <span className="py-1">Name: {file.fileName}</span>
        <span className="py-1">Entries: {file.numberOfEntries}</span>
        <div className="my-4">
          <Checkbox
            label="Generate Emails for empty email addresses?"
            isChecked={show.generateEmails}
            handleClick={() => setShow({ ...show, generateEmails: !show.generateEmails })}
          />
        </div>
        <div className="my-4">
          <Checkbox
            label="Select a default service for appointments?"
            isChecked={show.defaultService}
            handleClick={() => setShow({ ...show, defaultService: !show.defaultService })}
          />
        </div>
        <div className="my-4">
          <Checkbox
            label="Select a default provider for appointments?"
            isChecked={show.defaultProvider}
            handleClick={() => setShow({ ...show, defaultProvider: !show.defaultProvider })}
          />
        </div>
      </div>
      <div className="flex h-14 flex-row justify-around p-1">
        <Button
          text="Back"
          onClick={() => setShow({ ...show, stage: 'uploadFile' })}
          color="warning"
          shade={300}
          size="small"
        />
        <Button
          text="Next"
          onClick={() => setShow({ ...show, stage: 'submitFile' })}
          color="success"
          size="small"
        />
      </div>
    </div>
  );
};

export default GenerateEmails;
