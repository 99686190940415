import React from 'react';
import Icon from '../../../../../../shared/Icon/Icon';
import { Capitalize } from '../../../../../../../lib/helpers/utility';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';

const MacrosActions = ({ macroTitle = 'Macro', goBack = () => {}, openCreateModal = () => {} }) => {
  const { filters } = useMacrosContext();

  return (
    <div className="flex justify-between">
      <div
        onClick={goBack}
        className="flex cursor-pointer items-center gap-2 rounded-lg !p-1 transition-all hover:bg-neutral-50">
        <Icon icon={'back'} className="cursor-pointer" shade={900} color="primary" />
        <p className="w-32 overflow-hidden text-ellipsis whitespace-nowrap text-lg text-primary-900">
          {Capitalize(macroTitle)}
        </p>
      </div>
      {filters?.macroType !== 'form_macro' && (
        <div
          onClick={openCreateModal}
          className="flex cursor-pointer items-center gap-1.5 !p-1 transition-all hover:bg-primary-50">
          <Icon icon={'new-add-square'} />
          <p data-qa="new-add-macro" className="font-500 text-primary-500">
            New Macro
          </p>
        </div>
      )}
    </div>
  );
};

export default MacrosActions;
