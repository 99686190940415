import { createExternalInvoices } from 'api/Billing';
import { kioskCommand } from 'api/Kiosk';
import { socket } from 'api/Socket';
import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { ia, mString } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loading from 'components/shared/Loading/Loading';
import { updateInvoices } from 'api/Invoice';

const kioskPaymentStates = {
  idle: 0,
  inProgress: 1,
  declined: 2,
  success: 3,
  error: 4
};

const PaymentsKiosk = ({
  kioskId,
  onFail,
  onSuccess,
  onCancel,
  tokenize,
  invoices,
  patientId,
  selectedInternalInvoices
}) => {
  const [show, setShow] = useState({ state: kioskPaymentStates.inProgress });
  const navigate = useNavigate();

  useEffect(() => {
    if (kioskId) {
      initiateTransaction();
    }

    return () => {
      socket.off('kioskPaymentScreenMessage');
    };
  }, [kioskId]);

  const initiateTransaction = async () => {
    if (!kioskId) {
      return;
    }
    // send command to kiosk for initiation
    if (!ia(invoices)) {
      showAlert({
        color: 'warning',
        title: 'No invoices available',
        message: 'No invoices selected to make a payments'
      });
    }

    setShow((ps) => ({ ...ps, state: kioskPaymentStates.inProgress }));

    // Update internal invoices
    await updateInvoices(navigate, { invoices });

    const newExternalInvoice = await createExternalInvoices(navigate, selectedInternalInvoices);

    const extInvoice = newExternalInvoice?.invoices?.[0];

    const kioskCommandRes = await kioskCommand(navigate, {
      kioskId,
      command: 'url-change',
      url: extInvoice?.link,
      patientId: extInvoice?.patient_id,
      externalInvoiceToken: extInvoice?.token,
      page: 'Payment'
    });

    showAlert({
      icon: 'outbound-fax-success',
      color: 'success',
      title: `Payment Request Sent`,
      message: `Payment request sent to kiosk ${kioskCommandRes?.kioskName} for ${mString(newExternalInvoice?.invoices[0]?.total_amount_cents)}`,
      duration: 15000,
      buttons: [
        {
          text: 'Cancel Payment',
          color: 'warning',
          onClick: async () => {
            await kioskCommand(navigate, {
              kioskId,
              command: 'payment-cancel'
            });
            await kioskCommand(navigate, {
              kioskId,
              command: 'idle'
            });
            showAlert({
              icon: 'outbound-fax-error',
              color: 'success',
              title: `Payment Request Cancelled`,
              message: `Payment request cancelled.`
            });
          }
        }
      ]
    });

    onSuccess();

    // socket.emit('kioskJoinClient', { kioskId });
  };

  if (show.state === kioskPaymentStates.error) {
    return <div>Kiosk payment failure</div>;
  }

  if (show.state === kioskPaymentStates.declined) {
    return <div>Kiosk payment failure</div>;
  }

  if (show.state === kioskPaymentStates.success) {
    return <div>Kiosk payment success</div>;
  }

  if (show.state === kioskPaymentStates.inProgress) {
    return (
      <div>
        <Loading />
        <div>Kiosk payment in progress</div>
      </div>
    );
  }

  if (show.state === kioskPaymentStates.idle) {
    return <div>Kiosk Idle</div>;
  }

  return <div></div>;
};

export default withErrorBoundary(PaymentsKiosk);
