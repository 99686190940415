import { ia } from 'lib/helpers/utility';

// TODO @Izet check those keys if is needeed to pass from backed
export const getInitialData = (appointment, diffMin) => ({
  ...appointment,
  currentAmount: appointment?.amount,
  modifiedDeductible: null,
  modifiedCoPayment: null,
  modifiedCoInsurance: null,
  procedures: appointment?.invoice?.procedures || [],
  products: appointment?.invoice?.products || [],
  appointmentLength: diffMin,
  practice_id: appointment?.practitioner?.id,
  resource_id: ia(appointment?.resources)
    ? appointment?.resources?.[0].id || appointment?.resources?.[0].resource_id
    : undefined
});

export const fields = [
  'type',
  'state',
  'form_ids',
  'products',
  'location',
  'procedures',
  'service_id',
  'practice_note',
  'practitioner_id',
  'modifiedCoPayment',
  'modifiedDeductible',
  'modifiedCoInsurance',
  'tag_ids'
];
