export const NK_PREFIXES = {
  Medications: 'NKM - No Known Medications',
  Allergies: 'NKA - No Known Allergies',
  Vaccines: 'NKV - No Known Vaccines',
  FamilyHistory: 'NKFH - No Known Family History',
  SurgicalHistory: 'NKSH - No Known Surgical History'
};

export const getPopoverLabels = {
  Allergies: {
    add: 'allergy',
    noKnown: 'Allergies'
  },
  FamilyHistory: {
    add: 'Family History',
    noKnown: 'Family History'
  },
  SurgicalHistory: {
    add: 'Surgical History',
    noKnown: 'Surgical History'
  },
  Vaccines: {
    add: 'vaccine',
    noKnown: 'Vaccines'
  },
  Medications: {
    add: 'past medication',
    noKnown: 'Medications'
  },
  WeightLossAdministration: {
    add: 'Medication'
  }
};

export const getPopoverOptions = ({
  noKnownState = false,
  setShowFormModal = () => {},
  setShowNoKnownModal = () => {},
  setShowMedicationModal = () => {},
  isLoading = false,
  handleSSO = () => {},
  title = ''
}) => {
  const noSpaceTitle = title.replace(/\s+/g, '');
  let optionsToReturn = [
    {
      loading: isLoading,
      label: `Add ${getPopoverLabels[noSpaceTitle]?.add}`,
      onClick: () =>
        noSpaceTitle === 'WeightLossAdministration' || noSpaceTitle === 'Medications'
          ? setShowMedicationModal({ open: true })
          : setShowFormModal(true),
      icon: 'new-add-square'
    }
  ];
  if (noSpaceTitle === 'Medications') {
    optionsToReturn.push({
      loading: isLoading,
      label: 'E-Prescribe a new medication via Veradigm',
      onClick: () => handleSSO(),
      icon: 'new-eprescribe-v2'
    });
  }
  if (noSpaceTitle === 'WeightLossAdministration') {
    optionsToReturn.push({
      loading: isLoading,
      label: 'Select from Patient Medications',
      onClick: () => setShowMedicationModal({ existing: true }),
      icon: 'new-pill'
    });
  }
  if (!noKnownState && noSpaceTitle !== 'WeightLossAdministration') {
    optionsToReturn.push({
      loading: isLoading,
      label: `Mark as ‘No known’ ${getPopoverLabels[noSpaceTitle]?.noKnown}`,
      onClick: () => setShowNoKnownModal(true),
      icon: 'new-help'
    });
  }

  return optionsToReturn;
};
