import React from 'react';
import Button from '../../shared/Buttons/Button';
import { useNavigate, useParams } from 'react-router-dom';

const UserActivateError = () => {
  const navigate = useNavigate();
  const { kind } = useParams();

  let error = (
    <div>
      An unknown error has occurred. Please login to continue with your account, or request a new
      link from the practice.
    </div>
  );

  if (kind) {
    switch (kind) {
      case 'nes':
        error = (
          <>
            <div className="text-3xl font-500 text-primary-900">Oops, Link has Expired</div>
            <div>
              An email with a new link has been sent to your email. Please follow the new link to
              finish setting up your account.
            </div>
          </>
        );
        break;
      default:
        error = (
          <>
            <div className="text-3xl font-500 text-primary-900">Error</div>
            <div>
              An unknown error has occurred. Please login to continue with your account, or request
              a new link from the practice.
            </div>
          </>
        );
    }
  }

  return (
    <div>
      {error}
      <Button className={'mt-4 w-full'} text={'To Login'} onClick={() => navigate('/login')} />
    </div>
  );
};

export default UserActivateError;
