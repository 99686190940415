import React from 'react';
import './Popover.scss';
import cs from 'classnames';

const Popover = ({ handleOpen, children, className, center = false, forwardedRef, ...rest }) => {
  return (
    <div
      className={cs('Popover', center && 'Popover--center', className, handleOpen && 'open')}
      ref={forwardedRef}
      {...rest}>
      <div>{children}</div>
    </div>
  );
};

export default Popover;
