import React, { useState } from 'react';
import GetStarted from './GetStarted';
import Drafts from './Drafts';
import Questionnaire from './Questionnaire';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { useDraftedForms } from 'lib/hooks/queries/forms/useDraftedForms';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { useNavigate } from 'react-router-dom';

const NameAndType = ({ currentBreadcrumb }) => {
  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const { formik, setShowNavigationButtons } = useFormBuilderContext();
  const { data } = useDraftedForms({});
  const navigate = useNavigate();

  const buildFormPacket = () => {
    navigate('/portal/settings/forms/form-packets', {
      state: { openModal: true, form_id: formik?.values?.form?.id }
    });
  };

  const handleShowQuestionnaire = () => {
    setShowNavigationButtons(true);
    setShowQuestionnaire(true);
  };

  const draftedForms = data?.drafts || [];
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {showQuestionnaire ? (
        <Questionnaire formik={formik} currentBreadcrumb={currentBreadcrumb} />
      ) : (
        <div className="flex h-full flex-col justify-between px-9 py-[15px]">
          <div>
            <GetStarted />
            <Input
              label="Form Name"
              placeholder="Enter form name"
              id="form.name"
              name="form.name"
              value={formik.values.form?.name}
              onChange={formik.handleChange}
              error={formik.touched.form?.name && formik.errors.form?.name}
            />
            <div className="flex items-center justify-center py-11 text-center">
              <Button
                data-qa="start-building-form-btn"
                text="Start Building"
                disabled={!formik.values.form?.name}
                onClick={handleShowQuestionnaire}
              />
            </div>
            <Drafts draftedForms={draftedForms} />
          </div>
          <div className="flex items-center justify-center text-center">
            <p className="pr-4 text-sm font-500 text-primary-900">
              Skip the steps of creating a form and go straight to:
            </p>
            <Button
              type="secondary"
              icon="add-square"
              text="Build New Form Packet"
              data-qa="build-new-form-packet-btn"
              transparent
              className="!border-none !bg-primary-50"
              onClick={buildFormPacket}
            />
          </div>
        </div>
      )}
    </ErrorBoundary>
  );
};

export default NameAndType;
