import { processCustomFormTypes } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import {
  deleteKeys,
  ia,
  iaRa,
  io,
  spaceToKebabCase
} from '../../../../../../../../lib/helpers/utility';

const mapAdvancedForms = ({
  advancedForms,
  setAdvancedForms,
  withPreviousData,
  applyForceSave,
  isAppointmentMacro = false,
  isAdvancedCustomNote = false,
  customNoteType = null
}) => {
  applyForceSave({ type: 'advancedForm', value: true });

  if (isAppointmentMacro && isAdvancedCustomNote) {
    setAdvancedForms(
      processCustomFormTypes({
        customFormTypes: advancedForms,
        isMacro: true
      })
    );

    return;
  }

  advancedForms = Array.isArray(advancedForms) ? advancedForms : [advancedForms];
  if (isAppointmentMacro && !ia(advancedForms)) {
    return;
  }

  if (withPreviousData) {
    deleteKeys({
      object: advancedForms,
      keys: [
        'id',
        'custom_title',
        'appointment',
        'appointment_id',
        'user_id',
        'practitioner_id',
        'created_at',
        'updated_at',
        'practice_id',
        'created_by'
      ]
    });

    if (isAdvancedCustomNote) {
      processCustomAdvancedFormsWithPreviousData({
        setAdvancedForms,
        customNoteType,
        advancedForms
      });

      return;
    }

    setAdvancedForms((prevState) => {
      const formsArray = advancedForms;

      formsArray.forEach((form) => {
        const existingForm = iaRa(prevState).find((row) => getFormId(row) == getFormId(form));

        if (existingForm) {
          prevState = iaRa(prevState).map((row) => {
            const rowId = getFormId(row);
            const formId = getFormId(form);

            if (rowId == formId) {
              return { ...row, ...form, is_macro: true };
            }
            return row;
          });
        }
      });

      return prevState;
    });

    return;
  }

  setAdvancedForms(
    iaRa(advancedForms).map((item) => ({
      ...item,
      is_macro: true,
      custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
        item?.form_id || item?.id
      }`
    }))
  );

  return;
};

const getFormId = (value = null) => {
  if (!value) return null;

  return value?.form_id ?? value?.form?.id ?? value?.id;
};

export const loadMacro = ({
  selectedMacro,
  setClinicalNote = () => {},
  setAdvancedHP,
  setAdvancedSOAP,
  setCustomFormTypes,
  setLoading,
  withPreviousData = false,
  applyForceSave = () => null
}) => {
  setLoading(true);

  let parsedMacro = JSON.parse(selectedMacro?.body);

  const isAppointmentMacro = selectedMacro?.type == 'appointment_macro';

  if (parsedMacro?.advancedCustomNote) {
    mapAdvancedForms({
      advancedForms: parsedMacro?.advancedCustomNote,
      setAdvancedForms: setCustomFormTypes,
      withPreviousData,
      applyForceSave,
      isAppointmentMacro,
      isAdvancedCustomNote: true,
      customNoteType: parsedMacro?.customNoteType
    });
  }

  if (parsedMacro?.advancedHP) {
    mapAdvancedForms({
      advancedForms: parsedMacro?.advancedHP,
      setAdvancedForms: setAdvancedHP,
      withPreviousData,
      applyForceSave,
      isAppointmentMacro
    });
  }

  if (parsedMacro?.advancedSOAP) {
    mapAdvancedForms({
      advancedForms: parsedMacro?.advancedSOAP,
      setAdvancedForms: setAdvancedSOAP,
      withPreviousData,
      applyForceSave,
      isAppointmentMacro
    });
  }

  if (!io(parsedMacro?.clinicalNote)) {
    setTimeout(() => {
      setLoading(false);
    }, 500);
    return;
  }

  deleteKeys({
    object: parsedMacro.clinicalNote,
    keys: [
      'id',
      'appointment_id',
      'user_id',
      'practitioner_id',
      'created_at',
      'updated_at',
      'practice_id',
      'state',
      'locked',
      'advancedHP',
      'advancedSOAP'
    ]
  });

  const objectLength = Object?.keys(parsedMacro?.clinicalNote)?.length === 1;

  if (objectLength && parsedMacro?.clinicalNote?.epn) {
    setClinicalNote((prevState) => {
      const newState = updateNestedObjects({
        updatedData: parsedMacro.clinicalNote.epn,
        targetName: 'epn',
        clinicalNoteData: { ...prevState }
      });

      return { ...newState };
    });
  } else if (objectLength && parsedMacro?.clinicalNote?.mha) {
    setClinicalNote((prevState) => {
      const newState = updateNestedObjects({
        updatedData: parsedMacro.clinicalNote.mha,
        targetName: 'mha',
        clinicalNoteData: { ...prevState }
      });

      return { ...newState };
    });
  } else {
    parsedMacro = checkIfMacroContainsOldCPTs({ parsedMacro });

    setClinicalNote((prevState) => ({
      ...(withPreviousData && prevState),
      ...parsedMacro.clinicalNote
    }));
  }

  setTimeout(() => {
    setLoading(false);
  }, 500);
};

const checkIfMacroContainsOldCPTs = ({ parsedMacro }) => {
  const cpts = iaRa(parsedMacro?.clinicalNote?.cpt_codes);

  if (!ia(cpts)) return parsedMacro;

  const chceckIfOldCPTs = cpts.some((item) => item?.code?.label && item?.code?.value);

  if (!!chceckIfOldCPTs) {
    parsedMacro.clinicalNote.cpt_codes = iaRa(cpts).map((item) => {
      return {
        uuid: item?.id || self.crypto.randomUUID(),
        procedure_code: item?.code?.value,
        name: item?.code?.label,
        modifiers: iaRa(item?.modifiers),
        charge: item?.code?.charge || 0
      };
    });
  }

  return parsedMacro;
};

const updateNestedObjects = ({ updatedData, targetName, clinicalNoteData }) => {
  for (const key in updatedData) {
    clinicalNoteData[targetName] = {
      ...clinicalNoteData[targetName],
      [key]: updatedData[key]
    };
  }

  return clinicalNoteData;
};

export const copyMacroText = async (selectedMacro) => {
  return await navigator.clipboard.writeText(JSON.parse(selectedMacro?.body));
};

const processCustomAdvancedFormsWithPreviousData = ({
  setAdvancedForms = () => null,
  customNoteType = null,
  advancedForms = null
}) => {
  if (!customNoteType || !advancedForms) return;

  setAdvancedForms((prevState) => {
    const copyPrevState = { ...prevState };
    const currentNoteType = copyPrevState?.[customNoteType];

    if (!currentNoteType) {
      return prevState;
    }

    advancedForms.forEach((advancedForm) => {
      const existingForm = currentNoteType.find((row) => getFormId(row) == getFormId(advancedForm));

      if (!existingForm) {
        const newForm = {
          ...advancedForm,
          custom_title: `${spaceToKebabCase(advancedForm?.form?.name || advancedForm?.name)}-${
            advancedForm?.form_id || advancedForm?.id
          }`,
          is_macro: true
        };

        currentNoteType.push(newForm);
      } else {
        currentNoteType.forEach((row, index) => {
          const rowId = getFormId(row);
          const advancedFormId = getFormId(advancedForm);

          if (rowId == advancedFormId) {
            currentNoteType[index] = { ...row, ...advancedForm, is_macro: true };
          }
        });
      }
    });

    return {
      ...copyPrevState,
      [customNoteType]: currentNoteType
    };
  });
};
