import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { Popover } from '@headlessui/react';
import { useRecoilValue } from 'recoil';
import moment from 'moment';
import { useFormikContext } from 'formik';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { formatDateAndTime, getHours, ia } from 'lib/helpers/utility';
import {
  currentPractice,
  appointmentStatuses as appointmentStatusesState
} from 'components/practice/practiceState';
import Select from 'components/shared/Select/Select';
import { useAptTimelineDetails } from 'lib/hooks/queries/appointment/useAptTimelineDetails';
import { AlertContent } from 'components/shared/Alert/Alert';

const AptEndedModal = ({ modalVisible, setModalUnVisible }) => {
  const { values, setFieldValue } = useFormikContext();
  const [endDate, setEndDate] = useState(new Date(values.ends_at));

  const practice = useRecoilValue(currentPractice);
  const appointmentStatuses = useRecoilValue(appointmentStatusesState);
  const timezone = practice?.timezone;

  const { data } = useAptTimelineDetails({
    params: {
      appointment_id: values.id,
      patient_id: values.user_id,
      keys: ['arrived']
    }
  });

  useEffect(() => {
    if (ia(data?.timelineDetails?.arrived)) {
      const { plain_end_time } =
        data.timelineDetails.arrived.find((it) => it.type == 'appointment_ended') || {};
      if (plain_end_time) {
        setEndDate(new Date(plain_end_time));
      } else {
        const firstArrivalStartTime =
          data?.timelineDetails?.arrived[0] &&
          moment(data.timelineDetails.arrived[0]?.plain_start_time);
        const appointmentLength = moment(values.ends_at).diff(moment(values.starts_at), 'minutes');
        const expectedEndTime =
          firstArrivalStartTime && firstArrivalStartTime.add(appointmentLength, 'minutes');
        setEndDate(expectedEndTime);
      }
    }
  }, [data]);

  const handleDateChange = (date) => {
    const dt = moment.tz(moment(date).add(12, 'h'), timezone).format('L');
    const time = moment.tz(endDate, timezone).format('h:mm A') || '';
    let newTime = moment.tz(dt + ' ' + time, 'M/D/YYYY h:mm A z', timezone);

    setEndDate(moment.tz(newTime, timezone).format());
  };

  const handleTimeChange = (selectedOption) => {
    const [timeString, period] = selectedOption.value.split(' ');
    const [hour, minute] = timeString.split(':');
    const date = moment
      .tz(endDate, timezone)
      .hour(period === 'PM' ? (parseInt(hour) % 12) + 12 : parseInt(hour) % 12)
      .minute(parseInt(minute))
      .second(0)
      .millisecond(0);
    const dateUTC = date.utc().format();
    setEndDate(dateUTC);
  };

  const handleConfirm = () => {
    const aptStatus = appointmentStatuses.find((it) => it.value === 'appointment_ended');

    setFieldValue('appointment_end_date', endDate);
    setFieldValue('timeline_status.arrived', {
      status: 'completed',
      name: 'Appointment Ended',
      value: 'appointment_ended'
    });

    const updatedTimeline = {
      ...structuredClone(values.timeline),
      clinical: {
        ...values.timeline.clinical,
        arrived: {
          value: aptStatus.value,
          name: aptStatus.label,
          color: aptStatus.color
        }
      }
    };

    const updatedTimelineBar = values.timeline_bar.map((it) =>
      it.name === 'arrived' ? { ...it, label: aptStatus.value, color: aptStatus.color } : it
    );

    setFieldValue('timeline', updatedTimeline);
    setFieldValue('timeline_bar', updatedTimelineBar);
    setModalUnVisible();
  };

  return (
    <Modal
      handleOpen={modalVisible}
      handleClose={setModalUnVisible}
      className="w-[450px]"
      title="Appointment Ended"
      prevButton={<Icon size={25} icon="new-check" color="success" stroke className="!mr-2" />}
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Close" onClick={setModalUnVisible} outlined color="neutral" />
          <Button text="Update Patient Data" onClick={handleConfirm} />
        </div>
      }>
      <div className="flex flex-col gap-2">
        <p className="text-sm text-neutral-700">
          Select the date and time the appointment ended to ensure accurate documentation.
        </p>
        {endDate && (
          <AlertContent
            className="mt-2 !w-full"
            message={`Suggested time based on selected appointment duration: ${formatDateAndTime(endDate, timezone)}`}
          />
        )}

        <div className="flex  !gap-4">
          <div className="w-full">
            <Popover
              className="relative z-[1000] flex-1
          ">
              {({ close }) => (
                <>
                  <Popover.Button className="w-full p-0">
                    <Input
                      data-qa="event-start-date"
                      rightIcon="new-calendar-gray"
                      value={formatDateAndTime(endDate, timezone, '', 'M/D/YYYY, dddd')}
                      label="Date ended"
                    />
                  </Popover.Button>

                  <Popover.Panel className=" z-10 flex w-full shadow-md">
                    <Calendar
                      showDateDisplay
                      className="w-full"
                      onChange={(date) => {
                        handleDateChange(date);
                        close();
                      }}
                      date={new Date(formatDateAndTime(endDate, timezone))}
                    />
                  </Popover.Panel>
                </>
              )}
            </Popover>
          </div>
          <Select
            isClearable={false}
            rightIcon="new-clock-gray"
            parentClassName="w-full"
            options={getHours(1)}
            onChange={(val) => handleTimeChange(val)}
            value={{
              label: formatDateAndTime(endDate, timezone, '', 'h:mm A'),
              value: formatDateAndTime(endDate, timezone, '', 'h:mm A')
            }}
            label="Time"
          />
        </div>
      </div>
    </Modal>
  );
};

export default AptEndedModal;
