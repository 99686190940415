import React, { useEffect, useState } from 'react';
import Switch from 'components/shared/Switch/Switch';
import cs from 'classnames';
import Skeleton from 'react-loading-skeleton';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { pm } from 'lib/helpers/utility';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import {
  getPermissionGroupKey,
  getFieldValue,
  setFieldValue,
  getDependents,
  getGroupCounts
} from './lib/helper';
import Dependencies from './Dependencies';
import Dependents from './Dependents';

const PermissionActions = ({ roleId, permissions, actions, permissionKey, highlight, canEdit }) => {
  const {
    formik,
    actionsEnabled,
    setActionsEnabled,
    actionDependents,
    setActionDependents,
    actionDependencies,
    setActionDependencies,
    dependencyOfModal,
    setDependencyOfModal,
    showModal,
    setShowModal
  } = useEditRoleContext();
  const userPermissions = useRecoilValue(state.permissions);
  const [actionWithDependents, setActionWithDependents] = useState();

  const turnOffDependents = () => {
    setFieldValue(formik, actionWithDependents, false);
    const activeActionRatio = actionsEnabled[permissionKey];
    let [a, b] = activeActionRatio.split('/');
    a--;
    let ratioUpdates = {
      [permissionKey]: `${a}/${b}`
    };
    let permissionGroupKeys = [];
    for (const dependency of actionDependents[actionWithDependents]) {
      setFieldValue(formik, dependency, false);
      permissionGroupKeys.push(getPermissionGroupKey(permissions, dependency));
    }
    const groupCounts = getGroupCounts(permissionGroupKeys);
    const groupCountsKeys = Object.keys(groupCounts);

    for (const groupCountsKey of groupCountsKeys) {
      const permissionGroupActionRatio = actionsEnabled[groupCountsKey];
      let [c, d] = permissionGroupActionRatio.split('/');
      c = c - groupCounts[groupCountsKey];
      ratioUpdates = { ...ratioUpdates, [groupCountsKey]: `${c}/${d}` };
    }
    setActionsEnabled({ ...actionsEnabled, ...ratioUpdates });
    setShowModal(false);
  };

  useEffect(() => {
    return () => {
      setActionDependents({});
      setActionDependencies({});
      setDependencyOfModal({});
    };
  }, []);

  const handleOnChange = (action, value) => {
    const fieldValue = action.field;
    const activeActionRatio = actionsEnabled[permissionKey];
    let [a, b] = activeActionRatio.split('/');
    if (value) {
      setFieldValue(formik, fieldValue, true);
      a++;
      const dependencies = action.dependencies?.filter(
        (dependency) => !getFieldValue(formik, dependency)
      );
      if (dependencies) {
        setActionDependencies({ ...actionDependencies, [fieldValue]: dependencies });
        let ratioUpdates = {
          [permissionKey]: `${a}/${b}`
        };
        let permissionGroupKeys = [];
        for (const dependency of dependencies) {
          if (!getFieldValue(formik, dependency)) {
            setFieldValue(formik, dependency, true);
            permissionGroupKeys.push(getPermissionGroupKey(permissions, dependency));
          }
        }
        const groupCounts = getGroupCounts(permissionGroupKeys);
        const groupCountsKeys = Object.keys(groupCounts);

        for (const groupCountsKey of groupCountsKeys) {
          const permissionGroupActionRatio = actionsEnabled[groupCountsKey];
          let [c, d] = permissionGroupActionRatio
            .split('/')
            .map((item) => Number.parseInt(item, 10));
          c = c + groupCounts[groupCountsKey];
          ratioUpdates = { ...ratioUpdates, [groupCountsKey]: `${c}/${d}` };
        }

        setActionsEnabled({ ...actionsEnabled, ...ratioUpdates });
      } else {
        setActionsEnabled({
          ...actionsEnabled,
          [permissionKey]: `${a}/${b}`
        });
      }
    } else {
      let dependents = getDependents(permissions, fieldValue);
      dependents = dependents?.filter((dependentField) => getFieldValue(formik, dependentField));
      setActionDependents({ ...actionDependents, [fieldValue]: dependents });
      setDependencyOfModal(dependents);
      if (dependents?.length > 0) {
        setActionWithDependents(fieldValue);
        setShowModal(true);
      } else {
        setFieldValue(formik, fieldValue, false);
        a--;
        setActionsEnabled({ ...actionsEnabled, [permissionKey]: `${a}/${b}` });
      }
    }
  };

  return (
    <>
      <div
        key={roleId + '' + permissionKey}
        className="mb-5 flex h-fit max-h-[800px] w-full flex-col items-center justify-start gap-px self-stretch overflow-y-auto rounded-md border border-neutral-100 bg-neutral-100">
        {!actions ? (
          <Skeleton rows={12} />
        ) : (
          actions?.map((action) => {
            const [fieldGroup, fieldAction] = action.field.split('.');
            const actionField = action.field;
            return (
              <>
                <div
                  key={fieldGroup + '' + fieldAction}
                  className={cs(
                    'inline-flex items-center justify-start gap-2.5 self-stretch px-4 py-3',
                    getFieldValue(formik, actionField) ? 'bg-white' : 'bg-neutral-50'
                  )}>
                  <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
                    <div className="self-stretch text-sm font-medium leading-snug text-primary-900">
                      <Highlight text={action.title} highlight={highlight} />
                    </div>
                    <div className="self-stretch text-xs font-normal leading-tight text-neutral-500">
                      <Highlight text={action.description} highlight={highlight} />
                    </div>
                  </div>
                  <Switch
                    size={18}
                    disabled={!pm(userPermissions, 'role.update') || !canEdit}
                    checked={getFieldValue(formik, actionField)}
                    onChange={(value) => {
                      handleOnChange(action, value);
                    }}
                  />
                </div>
                {actionDependencies[actionField]?.length > 0 &&
                  getFieldValue(formik, actionField) && (
                    <Dependencies
                      permissions={permissions}
                      formik={formik}
                      dependencies={actionDependencies[actionField]}
                    />
                  )}
                {actionDependents[actionField]?.length > 0 &&
                  !getFieldValue(formik, actionField) && (
                    <Dependencies
                      permissions={permissions}
                      formik={formik}
                      dependencies={actionDependents[actionField]}
                      turnedOn={false}
                    />
                  )}
              </>
            );
          })
        )}
      </div>
      {showModal && (
        <Confirm
          handleOpen={showModal}
          handleClose={() => setShowModal(false)}
          handleContinue={turnOffDependents}
          icon="new-patients"
          iconColor="danger"
          primaryBtnTxt="Turn off"
          title="Warning"
          message="To continue, you need to turn off all the permissions listed below. The actions that follow depend on the permission being disabled."
          variant="danger">
          <Dependents permissions={permissions} dependents={dependencyOfModal} />
        </Confirm>
      )}
    </>
  );
};

export default PermissionActions;
