import React, { useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate, useParams } from 'react-router-dom';
import { createInstantPacket } from 'api/InstantPacket';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import { useForms } from 'lib/hooks/queries/forms/useForms';
import { currentPractice } from 'components/practice/practiceState';
import { updateAppointmentV2 } from 'api/Appointment';
import InstantFormSelection from './components/InstantFormSelection';
import { useRecoilValue } from 'recoil';
import InstantPacketSuccess from './components/InstantPacketSuccess';

export default function InstantPacket({ setShowInstantPacket, appointmentId, patientId }) {
  const { id } = useParams();
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const [selectedFormIds, setSelectedFormIds] = useState([]);
  const [selectedForm, setSelectedForm] = useState(false);
  const [previewFormId, setPreviewFormId] = useState(null);

  const practice = useRecoilValue(currentPractice);
  const apptId = useMemo(
    () => selectedAppointment?.id || appointmentId,
    [selectedAppointment, appointmentId]
  );
  const { data, isLoading } = useForms({
    params: {
      limit: 10000
    }
  });

  const { data: selectedForms } = useForms({
    params: {
      limit: 10000,
      appointmentId: apptId
    },
    dependencies: [appointmentId || selectedAppointment?.id]
  });

  const otherForms = [
    { name: 'HIPAA Agreement', id: 'hipaa', hardcoded: true },
    { name: 'Pre-Authorized Healthcare Form (CC on file)', id: 'pre-auth', hardcoded: true },
    { name: 'Insurance', id: 'insurance', hardcoded: true },
    { name: 'Personal ID', id: 'personal-id', hardcoded: true },
    { name: 'Demographics', id: 'personal-info', hardcoded: true }
  ];

  const [link, setLink] = useState(null);

  const navigate = useNavigate();

  const handleCreate = async () => {
    if (apptId) {
      const appointmentForms = selectedForms?.forms?.map((form) => form.id) || [];
      const selectedIds = selectedFormIds.filter(
        (id) => !otherForms.map((form) => form.id).includes(id)
      );
      const formIds = [...new Set([...appointmentForms, ...selectedIds])];
      await updateAppointmentV2(
        navigate,
        {
          id: apptId,
          form_ids: formIds
        },
        true
      );
    }
    const params = {
      formIds: selectedFormIds,
      patientId: patientId || id,
      appointmentId: apptId
    };
    const { token } = await createInstantPacket(navigate, params);
    if (token) {
      showAlert({ title: 'Instant packet created successfully!' });
      setLink(token);
    }
  };

  return (
    <Modal
      isOpen={true}
      slideFromRight
      bodyClassName="p-0"
      handleClose={() => setShowInstantPacket(false)}
      title={
        <div className="flex flex-col">
          <div>Send Patient Forms</div>
          <div className="sticky top-0 flex items-center space-x-4 bg-white">
            {selectedForm && (
              <Icon
                icon="back-large"
                onClick={() => {
                  setPreviewFormId(null);
                  setSelectedForm(null);
                }}
              />
            )}
            <h3 className="mb-4 text-base font-500 leading-6 text-neutral-800">
              {selectedForm ? `Form: ${selectedForm?.name}` : 'Forms'}
            </h3>
          </div>
        </div>
      }
      className="w-[720px]"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            outlined
            transparent
            text="Close"
            color="neutral"
            data-qa="patient-form-close-btn"
            onClick={() => setShowInstantPacket(false)}
          />

          {!link && (
            <Button
              text="Create"
              onClick={() => handleCreate()}
              data-qa="patient-form-create-btn"
            />
          )}
        </div>
      }>
      <Icon icon="new-pattern-two-rect" className="absolute bottom-[69px] left-0 -z-10" />
      <>
        {link ? (
          <InstantPacketSuccess {...{ link, id, patientId }} />
        ) : (
          <>
            {isLoading ? (
              <Skeleton />
            ) : (
              <InstantFormSelection
                {...{
                  data,
                  patientId,
                  id,
                  appointmentId,
                  practice,
                  selectedAppointment,
                  setSelectedAppointment,
                  selectedForms,
                  apptId,
                  setSelectedFormIds,
                  selectedFormIds,
                  selectedForm,
                  setSelectedForm,
                  setPreviewFormId,
                  previewFormId
                }}
              />
            )}
          </>
        )}
      </>
    </Modal>
  );
}
