import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Loader from 'components/shared/Loader/Loader';
import Modal from 'components/shared/Modal/Modal';
import Switch from 'components/shared/Switch/Switch';
import React, { useRef, useState } from 'react';
import PreviewNote from './PreviewNote';

const PreviewNoteModal = ({
  isOpen,
  handleClose,
  title,
  isExport,
  setIsExport = () => null,
  signAndLockProps = null,
  outsideAppointmentId = null,
  patientId = null,
  isPatient = false
}) => {
  const [loading, setLoading] = useState(false);

  const signAndLockButtonRef = useRef();
  const printButtonRef = useRef();
  const sendEmailToPatientButtonRef = useRef();

  const isSignAndLockMode = signAndLockProps?.signAndLockMode && signAndLockButtonRef?.current;
  const signAndLockPropsFetching = signAndLockProps?.isFetching;

  const onButtonClick = async () => {
    setLoading(true);
    await signAndLockButtonRef?.current?.handleContinue();
    setLoading(false);
  };

  const currentViewClassName = ({ check = true }) => {
    return cs('text-sm', check ? 'text-primary-500' : 'text-neutral-500');
  };

  const modalView = signAndLockProps?.signAndLockMode
    ? {
        modalCenterAnimation: true,
        className: 'h-[95%] w-[80%]'
      }
    : { slideFromRight: true, className: 'w-[1020px]' };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={title}
      {...modalView}
      bodyClassName="p-0 overflow-hidden"
      footer={
        isSignAndLockMode ? (
          <div className="flex w-full justify-between">
            <Button text="Cancel" color="neutral" outlined />
            <Button
              text="Sign & Lock"
              ref={signAndLockButtonRef}
              onClick={onButtonClick}
              loading={loading}
              disabled={loading || signAndLockPropsFetching}
            />
          </div>
        ) : null
      }
      headButton={
        <div className="flex w-full items-center justify-between">
          <div className="!ml-3 flex items-center gap-x-1">
            {!isSignAndLockMode && (
              <>
                <span className={currentViewClassName({ check: !isExport })}>Preview</span>
                <Switch checked={isExport} onChange={() => setIsExport(!isExport)} />
                <span className={currentViewClassName({ check: isExport })}>Export</span>
              </>
            )}
          </div>
          {isExport && (
            <div className="mr-3 flex items-center gap-x-1 self-center">
              <Button
                type="secondary"
                color="neutral"
                icon="new-printer"
                text="Print"
                size="small"
                onClick={() => printButtonRef?.current?.handlePrint()}
                data-qa="print-btn"
              />

              {!isPatient && (
                <Button
                  data-qa="send-email-btn"
                  type="secondary"
                  color="neutral"
                  icon="new-mail"
                  text="Email"
                  size="small"
                  onClick={() =>
                    sendEmailToPatientButtonRef?.current?.setShowConfirmationModal?.(true)
                  }
                />
              )}
            </div>
          )}
        </div>
      }>
      {signAndLockPropsFetching ? (
        <NoteLoading />
      ) : (
        <PreviewNote
          {...(outsideAppointmentId && patientId && { outsideAppointmentId, patientId })}
          printButtonRef={printButtonRef}
          sendEmailToPatientButtonRef={sendEmailToPatientButtonRef}
          isExport={isExport}
          signAndLockProps={{ ...signAndLockProps, signAndLockButtonRef, isSignAndLockMode }}
        />
      )}
    </Modal>
  );
};

const NoteLoading = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div>
        <Loader className="!h-16 !w-16" outlined color="success" />
      </div>

      <p>Loading, please wait...</p>
      <p>Your content is being prepared. This might take a moment. Thank you for your patience!</p>
    </div>
  );
};

export default PreviewNoteModal;
