import React from 'react';
import Popup from 'components/shared/Filters/Popup';
import cs from 'classnames';

const ContactButton = ({ children, type }) => {
  return (
    <Popup
      trigger={() => (
        <div
          data-qa={`send-${type}`}
          className={`flex cursor-pointer select-none items-center gap-2 ${
            type === 'phone' ? 'mr-1' : 'ml-1'
          } !rounded-md bg-primary-500 !px-4`}>
          <span className={cs('text-sm font-500', 'text-white')}>
            Send {type === 'phone' ? 'text' : type}
          </span>
        </div>
      )}
      on={['hover', 'focus']}
      contentStyle={{
        width: 'fit-content',
        borderRadius: '4px',
        boxShadow: '0px 2px 16px 0px #004F6B33'
      }}
      position={['top right', 'top center']}>
      <div className="flex min-w-fit flex-col py-[6px]">{children}</div>
    </Popup>
  );
};

export default ContactButton;
