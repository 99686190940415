import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import Form from './form';
import NewFormBuilder from './new';
import { requestApi } from 'api/Api';
const FormValidators = require('../../../lib/validation/validate_form_create');
const validateUpdate = FormValidators.validateUpdate;

const NewFormBuilderContainer = () => {
  const [form, setForm] = useState({
    name: '',
    type: '',
    json: ''
  });
  const [form_json, setFormJson] = useState();
  const [form_functions, setFormFunctions] = useState([]);
  const [errors, setErrors] = useState({
    name: {
      success: false,
      message: ''
    },
    type: {
      success: false,
      message: ''
    }
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getFormFunction();
  }, []);

  const getFormFunction = async () => {
    try {
      let res = await requestApi({
        url: '/api/form/functions/get',
        params: {},
        navigate
      });

      const { form_functions: loadedFormFunctions } = res;
      if (res) {
        setFormFunctions([...form_functions, ...loadedFormFunctions]);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleChange = (event) => {
    const field = event.name;
    const value = event.value;
    setForm({
      ...form,
      [field]: value
    });
  };

  const onFormChange = (event) => {
    setFormJson({ fields: event });
  };

  const submitChanges = async () => {
    try {
      let params = {
        form: { ...form, json: form_json },
        practice_id: id
      };
      if (form.name !== '' && form.type !== '' && form_json !== '') {
        let res = await requestApi({
          url: '/api/form/create',
          params,
          navigate
        });

        if (res.status == 200) {
          navigate(-1);
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
        }
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let payload = validateUpdate(form);
    if (payload.success) {
      setErrors({
        name: {
          success: false,
          message: ''
        },
        type: {
          success: false,
          message: ''
        }
      });
      submitChanges(form);
    } else {
      const errors = payload.errors;
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${errors}`);
      setErrors({
        name: {
          success: false,
          message: errors.f_name
        },
        type: {
          success: false,
          message: errors.m_name
        }
      });
    }
  };

  return (
    <div className="relative">
      <div className="!mb-8 flex items-center !gap-6">
        <Button
          onClick={() => navigate(`/admin/practices/${id}/forms`)}
          text="Back"
          icon="chevron-left"
          type="white"
          color="primary"
          className={'rounded-md border-2 border-solid border-neutral-500'}
        />
        <Form onChange={handleChange} errors={errors} form={form} formFunctions={form_functions} />
        <Button onClick={validateForm} text={'Save Form'} color="success" />
      </div>
      <NewFormBuilder onSubmit={validateForm} practice_id={id} onFormChange={onFormChange} />
    </div>
  );
};

export default NewFormBuilderContainer;
