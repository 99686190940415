import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { iaRa } from 'lib/helpers/utility';
import { usePatientPractices } from 'lib/hooks/queries/patients/usePatientPractices';

import PracticeBox from '../components/PracticeBox';

export default function Documents() {
  const { data } = usePatientPractices({});
  const practices = useMemo(() => data?.practices, [data]);
  const navigate = useNavigate();

  useEffect(() => {
    if (practices && practices.length === 1) {
      navigate(`/myportal/documents/${practices[0]?.practice?.id}`, { replace: true });
    }
  }, [practices]);

  return (
    <div className="p-12 md:p-8 sm:p-4 xs:p-4">
      <p className="text-primary-900 mb-4 ">Select practice</p>
      <div className="grid xl:grid-cols-3 gap-4 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
        {iaRa(practices).map(({ practice }) => (
          <PracticeBox
            title={practice.name}
            key={practice.id}
            message="Show documents for this practice"
            headerPhoto={practice.header_photo}
            onClick={() => navigate(`/myportal/documents/${practice.id}`)}
          />
        ))}
      </div>
    </div>
  );
}
