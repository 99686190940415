import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment-timezone';

import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { iaRa, mapValues } from 'lib/helpers/utility';
import { useAppointmentEvents } from 'lib/hooks/queries/appointment/useAppointmentEvents';

import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';

import Appointments from './components/Appointments/Appointments';
import Arrows from './components/Arrows';
import Days from './components/Days';
import Navigation from './components/Navigation/Navigation';

const Calendar = () => {
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [, setCurrentWeek] = useState(moment().week());
  const [selectedDate, setSelectedDate] = useState(moment());
  const { filters } = useDashboardContext();
  const navigate = useNavigate();

  const { data, isLoading } = useAppointmentEvents({
    defaultDate: selectedDate,
    rangeDate: {
      startDate: moment(selectedDate).startOf('day').toDate(),
      endDate: moment(selectedDate).endOf('day').toDate()
    },
    navigate,
    withEvents: true,
    filters: mapValues({
      ...filters,
      practitioners: filters?.practitioners?.values?.map((item) => item.value),
      serviceType: filters?.serviceType?.values?.map((item) => item.value),
      appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
      appointmentTags: filters?.appointmentTags?.values?.map((item) => item.value),
      resources: filters?.resources?.values?.map((item) => item.value),
      arrivalStatus: filters?.arrivalStatus?.values?.map((item) => item.value)
    })
  });

  const changeMonthHandle = (newMonth) => setCurrentMonth(newMonth);

  const changeWeekHandle = (btnType) => {
    if (btnType === 'prev') {
      setCurrentMonth(moment(currentMonth).subtract(1, 'weeks'));
      setCurrentWeek(moment(currentMonth).subtract(1, 'weeks').week());
    }
    if (btnType === 'next') {
      setCurrentMonth(moment(currentMonth).add(1, 'weeks'));
      setCurrentWeek(moment(currentMonth).add(1, 'weeks').week());
    }
  };

  const goToTodayHandle = (today) => {
    setCurrentMonth(today);
    setSelectedDate(today);
  };

  const onDateClickHandle = (day) => setSelectedDate(day);

  return (
    <div className="flex h-full flex-col overflow-hidden">
      <div className="bg-primary-50 !py-3">
        <Navigation {...{ currentMonth, changeMonthHandle, goToTodayHandle }} />
        <div className="relative">
          <Days {...{ currentMonth, selectedDate, onDateClickHandle }} />
          <Arrows {...{ currentMonth, changeWeekHandle }} />
        </div>
      </div>
      <div className="flex flex-col overflow-auto">
        <Appointments {...{ data: iaRa(data?.events), isLoading, selectedDate }} />
      </div>
      <FooterWrapper onClick={() => navigate('appointments')} text="Go to calendar" />
    </div>
  );
};

export default Calendar;
