import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

export default function InstantPacketSuccess({ link, id, patientId }) {
  const [loading, setLoading] = useState({ email: false, text: false });
  const navigate = useNavigate();

  const sendLink = async (type) => {
    setLoading({ ...loading, [type]: true });
    const params = { link, patientId: patientId || id };
    await requestApi({
      navigate,
      url: `/api/form/instant_packet/${type}`,
      params,
      onError: () => {
        showAlert({ title: `Failed to send ${type}`, color: 'danger' });
      },
      onSuccess: () => {
        showAlert({ title: `Successfully sent ${type}` });
      }
    });
    setLoading({ ...loading, [type]: false });
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      showAlert({ title: 'Copied!' });
    } catch (err) {
      showAlert({ title: 'Failed to copy!' });
    }
  };

  return (
    <div className="mt-[20%] inline-flex !h-52 w-[100%] flex-col items-center justify-start !gap-5">
      <div className="self-stretch text-center text-2xl font-medium text-primary-500">
        Packet created successfully!
      </div>
      <div className="flex h-24 flex-col items-center justify-start gap-4 self-stretch">
        <div className="self-stretch text-center text-base font-medium leading-normal text-primary-900">
          Send this link to the patient:
        </div>
        <div className="inline-flex items-center justify-start gap-4 rounded-lg border border-gray-100 bg-white !p-4 shadow">
          <div className="text-sm font-medium leading-snug text-neutral-700">{link}</div>
          <Icon
            data-qa="copy-link"
            icon="copy"
            onClick={() => copyToClipboard()}
            className="flex gap-2.5 p-1.5"
          />
        </div>
      </div>
      <div className="inline-flex items-start justify-center !gap-2.5">
        <Button
          data-qa="patient-form-send-email"
          id="email"
          text="Email patient"
          icon="new-email"
          onClick={() => sendLink('email')}
          loading={loading.email}
        />
        <Button
          data-qa="patient-form-text"
          id="text"
          text="Text patient"
          icon="new-text-message"
          onClick={() => sendLink('text')}
          loading={loading.text}
        />
      </div>
    </div>
  );
}
