import React from 'react';

import { permissions, userState } from 'components/state';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { removeBracketsInText } from 'lib/helpers/utility';
import { useMyScribe } from 'lib/hooks/queries/myscribe/useMyScribe';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';

const MyScribeExportNote = ({ customClinicalNote }) => {
  const { transcriptData, setTranscriptData } = useAmbientListeningContext() || {};

  const { appointmentId } = useParams();

  const apptId = customClinicalNote?.clinicalNote?.appointment_id || appointmentId;

  const { data: currentMyScribe } = useMyScribe({
    params: { appointmentId: apptId },
    dependencies: [Number(apptId)],
    options: {
      enabled: !!apptId,
      select: (data) => {
        return data?.data?.myscribe;
      },
      onSettled: (data) => {
        if (data?.data?.myscribe) {
          setTranscriptData(data?.data?.myscribe);
        }
      }
    }
  });

  const myscribeTranscriptData = (currentMyScribe || transcriptData)?.[0];

  const user = useRecoilValue(userState);

  const permission = useRecoilValue(permissions);

  const myScribeAINarrative = removeBracketsInText(myscribeTranscriptData?.summary);
  const myScribeAuthor = myscribeTranscriptData?.provider_id ?? null;

  const isCurrentUserSameAsAuthor = myScribeAuthor == (user?.id ?? user?.user_id);

  const hasPermissionToSeeNarrative =
    permission?.myscribe?.narrative?.read || isCurrentUserSameAsAuthor;

  if (!myScribeAINarrative)
    return (
      <div className="bg-white !px-6 !py-4">
        <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Encounter Narrative</h3>
        <p className="text-sm">
          You currently have no transcript data. You can start one by clicking on 'record visit'.
        </p>
      </div>
    );

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Encounter Narrative</h3>

      {!hasPermissionToSeeNarrative ? (
        <p className="text-sm">You don't have the permission to view the MyScribe AI narrative.</p>
      ) : myScribeAINarrative ? (
        <SanitizeComponent value={myScribeAINarrative} />
      ) : (
        <p className="text-sm">
          You currently have no transcript data. You can start one by clicking on 'record visit'.
        </p>
      )}
    </div>
  );
};

export default MyScribeExportNote;
