import { requestApi } from './Api';


export const getPractitioner = async (params, navigate) => {
  return await requestApi({
    navigate,
    url: '/api/practitioner/get',
    params
  });
}
export const getPractitioners = async (params, navigate) => {
  return await requestApi({
    navigate,
    url: '/api/practice/member/get_practitioners',
    params,
    onError: () => { }
  });
};
export const getPractitionerPatients = async (params, navigate) => {
  return await requestApi({
    navigate,
    url: '/api/practitioner/get_patients',
    params
  });
};
export const getPractitionersAdmin = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/practitioner/get_multiple', params });
