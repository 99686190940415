import React from 'react';
import cs from 'classnames';
import COFAddress from '../COF/Address';
import Icon from '../../shared/Icon/Icon';
import { termKind } from '../Configurations';

const CardBox = ({
  card,
  show,
  setShow,
  selectedCard,
  setSelectedCard,
  cards,
  setCards,
  addressRef
}) => {
  const { id, pan, kind, expiration, billing_address } = card || {};

  const handleOnClick = (card) => {
    setSelectedCard(card);
    setShow((ps) => ({
        ...ps,
        processingKind: 'elavonSavedCard',
        selectedPaymentOption: termKind.elavonSavedCard
      })
    );
  };

  const handleIcon = (kind) => {
    if (kind === 'VISA') return 'new-payment-visa';
    else if (kind === 'MASTERCARD' || kind === 'MC') return 'new-payment-mastercard';
    else if (kind === 'DISCOVER') return 'new-payment-discover';
    else if (kind === 'AMEX') return 'new-payment-amex';
    else return 'new-credit-card';
  };

  const selected =
    id === selectedCard?.id &&
    (show.processingKind === 'elavonSavedCard' ||
      show.selectedPaymentOption === termKind.elavonSavedCard);

  return (
    <>
      <div
        className={cs(
          'flex w-[calc(50%-8px)] cursor-pointer items-center !rounded-lg border border-solid border-neutral-300 bg-white !p-4 sm:w-full',
          selected &&
            (billing_address
              ? '!border-primary-500 !bg-primary-50'
              : '!border-danger-500 !bg-danger-50')
        )}
        onClick={() => handleOnClick(card)}>
        <Icon
          icon={handleIcon(kind)}
          className="!mr-4 flex h-10 w-14 cursor-pointer items-center justify-center rounded border border-solid border-neutral-100 bg-white"
        />

        <div className="flex flex-col">
          <label className="!mb-0 cursor-pointer text-sm text-neutral-500">
            **** **** **** <span className="inline text-primary-900">{pan?.substring(12, 16)}</span>
          </label>

          <label className="mb-0 cursor-pointer text-xs text-neutral-500">
            Expires ({expiration?.substring(0, 2)}/{expiration?.substring(2, 4)})
          </label>
        </div>
      </div>

      {selected && !billing_address && (
        <div className="w-full !rounded-lg border border-solid !border-danger-500 !bg-danger-50 !p-4">
          <COFAddress
            addressRef={addressRef}
            card={selectedCard}
            onSuccess={(newBillingAddressId) => {
              setSelectedCard({
                ...selectedCard,
                billing_address: newBillingAddressId
              });

              setCards(
                cards.map((c) =>
                  c?.id === id ? { ...c, billing_address: newBillingAddressId } : c
                )
              );
            }}
          />
        </div>
      )}
    </>
  );
};

export default CardBox;
