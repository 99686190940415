import React, { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';
import { completeInstantForm } from 'api/CustomForms';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';
import { useInsuranceProfiles } from 'lib/hooks/queries/insuranceProfiles/useInsuranceProfiles';

import InsuranceProfiles from 'components/practice/charts/InsuranceProfile/InsuranceProfiles';
import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import CheckinLoginWarning from './LoginWarning';
import { propertyPathMap } from './constants';
import state from './state';

import './style.scss';

const Insurance = () => {
  const {
    updateSteps,
    checkinButton,
    appointmentId,
    fakeModal,
    handleExternalSubmit,
    isInstantPacket,
    practice,
    practitionerId,
    setFakeModal,
    kiosk,
    setContinueText
  } = useOutletContext();

  const selections = useRecoilValue(state.selectionState);
  const patient = useRecoilValue(state.userState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { device } = useUIContext();

  useEffect(() => {
    if (appointmentId) {
      if (selections?.insurance !== null) {
        if (!patient.authenticated && !patient.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
        }
      }
    }
  }, [appointmentId, selections]);

  const { data } = useInsuranceProfiles({ params: { userId: patient?.id } });
  const insuranceProfiles = data?.insuranceProfile || [];

  useEffect(() => {
    if (!ia(insuranceProfiles)) {
      setContinueText('Skip');
    } else {
      setContinueText('Save & Continue');
    }

    return () => {
      setContinueText('Save & Continue');
    };
  }, [data]);

  const getCheckinStatus = async (complete = false) => {
    if (ia(insuranceProfiles) && !isInstantPacket) {
      const res = await requestApi({
        url: 'api/patient/insurance/select',
        params: {
          insuranceProfileId: insuranceProfiles[0].id
        },
        navigate
      });
      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          // updateSteps({
          //   version: 2,
          //   // formId: 1,
          //   newStatus: 'completed'
          //   // formName: propertyPathMap.insuranceIdComplete.name(),
          //   // isCompleted: true
          // });
          break;
        default:
          Honeybadger.notify(
            `file: checkin/insurance, method: getCheckinStatus - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error)
            showAlert({
              message: error,
              color: 'danger',
              position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
            });
          break;
      }
    } else {
      await requestApi({
        url: 'api/patient/insurance/no_insurance',
        params: {
          appointmentId
        },
        navigate
      });
    }

    if ((complete || ia(insuranceProfiles)) && isInstantPacket) {
      await completeInstantForm(navigate, {
        formId: 'insurance'
      });
    }

    updateSteps({
      formName: propertyPathMap.insuranceIdComplete.name(),
      isCompleted: complete || ia(insuranceProfiles),
      newStatus: complete || ia(insuranceProfiles) ? 'completed' : 'incomplete',
      version: 2
    });

    return true;
  };

  return (
    <div className={cs('h-full ', fakeModal ? '!w-full p-0' : '!px-4 !py-4 sm:!px-4')}>
      {!patient?.authenticated && !patient?.isOnboarding && (
        <CheckinLoginWarning
          data="insurance profiles"
          note="Please note that previously selected insurance profile will be selected if no insurance is chosen."
        />
      )}
      <InsuranceProfiles
        patientId={patient?.id}
        fakeModal={fakeModal}
        practitionerId={practitionerId}
        kiosk={kiosk}
        setFakeModal={setFakeModal}
        practiceId={practice?.id}
        getCheckinStatus={getCheckinStatus}
        noInsurancePress={() => {
          handleExternalSubmit();
        }}
      />
      <div hidden ref={checkinButton} onClick={() => getCheckinStatus()}></div>
    </div>
  );
};

export default withErrorBoundary(Insurance);
