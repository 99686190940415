import React, { useMemo } from 'react';

import { useIncomeIQueueContext } from 'lib/context/IncomeIQueueContext/IncomeIQueueContext';
import { IncomeIQueueContextProvider } from 'lib/context/IncomeIQueueContext/IncomeIQueueContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues, optionify } from 'lib/helpers/utility';
import { useIncomeIQueue } from 'lib/hooks/queries/billing/useIncomeIQueue';
import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TableCounter from 'components/shared/Table/TableCounter';

import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';

const isFirstColumn = (params) => {
  let displayedColumns = params.api.getAllDisplayedColumns();
  let thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

const IncomeIQueue = () => {
  return (
    <IncomeIQueueContextProvider>
      <TableContextProvider
        defaultFilters={DEFAULT_FILTERS}
        cols={defaultColumns}
        category="income-i-queue">
        <Table />
      </TableContextProvider>
    </IncomeIQueueContextProvider>
  );
};

function Table() {
  usePageTitle('Income I Queue');
  const category = 'income-i-queue';
  const { actions } = useIncomeIQueueContext();
  const { limit, page, sort, filters, setPage, setFilters, selectedRows } = useTableContext();

  const { data, isLoading, isFetching } = useIncomeIQueue({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const { data: providers = {} } = usePractitioners();

  const { data: payers } = usePayers({
    options: { select: (response) => optionify(response.payers, 'name', 'id') }
  });

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.provider.options = providerOptions;

  const payerOptions = useMemo(() => {
    if (payers?.length > 0) {
      return payers.map((p) => p);
    }
    return [];
  }, [payers]);

  filters.payer.options = payerOptions;

  const defaultColDef = useMemo(() => {
    return {
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const appointments = data?.appointment || [];
  const count = data?.count || 0;

  const handleDelete = () => {
    console.log('delete');
  };

  const handlePerformActions = () => {
    console.log('perform', actions);
  };

  return (
    <>
      <Header title="IncomeIQueue">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={DEFAULT_FILTERS}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
            contentStyle={{ maxHeight: '80%' }}
          />
          {ia(selectedRows) && selectedRows.length > 0 && (
            <>
              <Button
                text="Delete"
                type="primary"
                color="danger"
                outlined
                size="small"
                className="!h-[30px]"
                onClick={handleDelete}
              />
              <Button text="Perform selected actions" size="small" onClick={handlePerformActions} />
            </>
          )}
        </div>
      </Header>
      <div className="flex  h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0  h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              data={appointments}
              rowSelection="multiple"
              defaultColDef={defaultColDef}
              columnDef={defaultColumns}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
}

export default IncomeIQueue;
