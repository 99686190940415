import React, { useState, useEffect } from 'react';
import Icon from '../Icon/Icon';
import Input from '../Input/Input';
import useDebounce from 'lib/hooks/useDebounce';
const _ = require('lodash')

export default function SearchFilter({ data, index, setFilter, placeholder = 'Search' }) {
  const [inputValue, setInputValue] = useState(data.values);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(data.values);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(inputValue);
    }, 650);

    handler();

    return () => {
      handler.cancel();
    };
  }, [inputValue]);

  useEffect(() => {
    if (inputValue !== data.values) {
      setFilter(index, { ...data, values: debouncedSearchTerm });
    }
  }, [debouncedSearchTerm]);

  return (
    <div className="flex cursor-pointer flex-col !py-2">
      <p className="!px-4 font-medium text-primary-700 text-sm">{data?.title || 'Search'}</p>
      <div className="relative !mx-4 mt-1 flex justify-between">
        <Input
          placeholder={placeholder}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          id="data"
          data-qa="request-data"
          name="data"
          width="100%"
          required
          inputWrapperClassName="!border-0 focus-within:!border-0 !mb-1 !px-[2px] border-solid !h-6 focus-within:!outline-[#FFFFFF]"
          className="focus-within:!outline-0"
        />
        <div className="z-100 absolute bottom-0 right-0 top-0 mr-[2px] flex">
          <Icon color="primary" shade={900} stroke icon="new-search" />
        </div>
      </div>
      <div className="!mx-4 mt-1 border !border-t-0 border-neutral-200 border-solid"></div>
    </div>
  );
}
