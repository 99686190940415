import { getPractitioners } from 'api/Practitioner';

export default {
  nameSearchTerm: {
    type: 'search',
    values: '',
    title: 'Name',
    placeholder: 'Search by name'
  },
  providers: {
    type: 'infinite-scroll',
    multiple: true,
    values: [],
    title: 'Providers',
    placeholder: 'Select providers',
    icon: false,
    queryKey: 'practitioners',
    queryFn: getPractitioners
  }
};
