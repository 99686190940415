import { useQuery } from '@tanstack/react-query';
import { getUnmatchedClaims } from '../../../../api/Claim';
import { useNavigate } from 'react-router-dom';

export const useUnmatchedClaims = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['unmatchedClaims', ...dependencies],
    () => getUnmatchedClaims(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: 5000,
      ...options
    }
  );
};
