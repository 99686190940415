import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getProductCategories, getProducts } from '../../../../api/Products';
import { useEffect, useState } from 'react';

export const useProductsCategories = ({ options, params } = {}) => {
  const navigate = useNavigate();


  return useQuery(
    ['productCategories'],
    () => getProductCategories(navigate),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
