import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getStates } from 'api/State';

export const useStates = (options, params = {}) => {
  const navigate = useNavigate();
  return useQuery(
    ['states'],
    () => getStates(navigate, { returnData: true, method: 'get', ...params }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
