import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import _, { capitalize } from 'lodash';
import usePageTitle from 'lib/hooks/usePageTitle';
import { usePractices } from 'lib/hooks/queries/practice/usePractices';
import { formatPhoneNumber } from 'lib/helpers/utility';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Header from 'components/shared/Header/Header';
import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';

export default function Practices() {
  const columns = [
    { field: 'id', headerName: 'ID', maxWidth: 100 },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 500,
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'phone',
      headerName: 'Phone',
      valueFormatter: ({ value }) => formatPhoneNumber(value)
    },
    {
      field: 'timezone',
      headerName: 'Timezone',
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      colId: 'status',
      headerName: 'Status',
      valueGetter: (params) => {
        return params.data.status;
      },
      valueFormatter: ({ value }) => capitalize(value)
    }
  ];

  return (
    <TableContextProvider cols={columns} pagination name="admin_practices_table">
      <Table />
    </TableContextProvider>
  );
}

function Table() {
  usePageTitle('Practices');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const navigate = useNavigate();

  const {
    data = {},
    isLoading,
    isFetching
  } = usePractices({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit, withCount: true },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });
  const practices = data.practices;
  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/admin/practices/${event.data.id}`);
    }
  };

  if (id) return <Outlet />;

  return (
    <div className="flex h-full flex-col">
      <Header title="Practices">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Input
            data-qa="search-practices"
            type="string"
            placeholder="Search practices"
            value={searchTerm}
            icon="new-search"
            inputWrapperClassName="h-[30px]"
            className="md:w-[200px]"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
        </div>
      </Header>
      <div className="flex h-full flex-col  !pb-2">
        <div className="ag-theme-quartz !mb-0 h-full">
          <AGTable
            data={practices}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.practiceCount > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.practiceCount > page * limit ? page * limit : data?.practiceCount} out of{' '}
              {data?.practiceCount}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.practiceCount}
            page={page}
          />
        </div>
      </div>
    </div>
  );
}
