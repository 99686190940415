import React from 'react';
import Tippy from '@tippyjs/react';
import Status from '../../../../../shared/Status/Status';

import { ia, iaRa, isEmpty } from '../../../../../../lib/helpers/utility';

const ClinicalNoteStatus = ({ data: clinicalNote, classNames }) => {
  const signersLeft = iaRa(clinicalNote?.document_sign)
    ?.filter((row) => row?.status !== 'signed')
    ?.map((row) => `${row?.user.f_name} ${row?.user.l_name}`)
    .join(', ');

  const determineStatus = () => {
    let status = '';

    switch (clinicalNote?.state) {
      case 'draft':
        const documentInfo = iaRa(clinicalNote?.document_sign);
        const noSigners = documentInfo?.every((row) => row?.status !== 'signed');

        if (noSigners) {
          status = 'draft';
          break;
        }

        const providersLeft = documentInfo?.filter((row) => row?.status !== 'signed');

        if (ia(providersLeft)) {
          status = 'awaiting co-sign';
          break;
        }

        status = 'draft';
        break;
      case 'finalized':
        status = 'signed and locked';
        break;
      default:
        status = clinicalNote.state ?? 'not started';
        break;
    }

    return status;
  };

  const cnStatus = determineStatus(clinicalNote);

  return (
    <Tippy
      arrow={true}
      placement="left-start"
      className="tippy-black w-fit"
      content={
        !clinicalNote?.state
          ? 'Not started.'
          : isEmpty(signersLeft)
            ? 'Everyone signed.'
            : `${signersLeft} left to sign.`
      }>
      <div className="flex w-full">
        <Status status={cnStatus} className={('w-max', classNames)} />
      </div>
    </Tippy>
  );
};

export default ClinicalNoteStatus;
