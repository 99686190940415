import { Popover } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import { addDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { interimApi } from '../../../../../api/InterimApi';
import {
  Capitalize,
  convertJSONToCSV,
  downloadCSV,
  ia,
  mString,
  mapValues
} from '../../../../../lib/helpers/utility';
import { showAlert } from '../../../../shared/Alert/Alert';
import Button from '../../../../shared/Buttons/Button';
import ExportTable from './Table';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { getAppointments as getAppointmentsApi } from 'api/Appointment';

const ExportCalendar = ({ exportRange, setExportRange }) => {
  return (
    <Popover className="relative">
      <>
        <Popover.Button as={Button} size="small" icon="new-export" outlined color="neutral" />
        <Popover.Panel className="absolute z-[999] !mt-1 gap-1 rounded-lg bg-white !p-2 shadow-md">
          {({ open }) =>
            open ? <Calendar exportRange={exportRange} setExportRange={setExportRange} /> : null
          }
        </Popover.Panel>
      </>
    </Popover>
  );
};

const Calendar = ({ exportRange, setExportRange }) => {
  const exportRef = useRef();
  const { filters } = useDashboardContext();

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState({ generate: false });
  const navigate = useNavigate();
  useEffect(() => {
    getAppointments();
  }, [exportRange]);

  const getAppointments = async () => {
    setLoading({ ...loading, generate: true });

    const params = {
      rangeDate: {
        startDate: exportRange[0]?.startDate,
        endDate: exportRange[0]?.endDate
      },
      filters: mapValues({
        ...filters,
        practitioners: filters?.practitioners?.values?.map((item) => item.value),
        serviceType: filters?.serviceType?.values?.map((item) => item.value),
        appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
        appointmentTags: filters?.appointmentTags?.values?.map((item) => item.id)
      })
    };
    const data = await getAppointmentsApi({ ...params, navigate });
    if (data.appointment) setAppointments(data.appointment);
    setLoading({ ...loading, generate: false });
  };

  const labelRange = `${moment.utc(exportRange[0]?.startDate).format('MMM D')} -
    ${moment.utc(exportRange[0]?.endDate).format('MMM D')} - Total appointments: ${
      appointments?.length
    }`;

  const transformAppointmentsToCSVData = () => {
    return appointments.map((appointment) => {
      const { starts_at, ends_at, patient, practitioner, status, invoice } = appointment;

      return {
        Datetime: `${moment(starts_at).format('ddd MMM D, h:mm A')} - ${moment(ends_at).format(
          'h:mm A'
        )}`,
        Patient: patient?.fullName || '',
        Service: invoice?.procedures?.map((p) => p?.name).join(', ') || '',
        Balance: mString(invoice?.balance || 0),
        Provider: practitioner?.fullName || '',
        Status: Capitalize(status === 'cancelled' ? 'canceled' : status)
      };
    });
  };

  const handleDownloadCSV = async () => {
    const csvData = transformAppointmentsToCSVData();
    const csvContent = convertJSONToCSV(csvData);
    if (csvContent) {
      downloadCSV(
        csvContent,
        `${moment.utc(exportRange[0]?.startDate).format('MMM D')} -
    ${moment.utc(exportRange[0]?.endDate).format('MMM D')}.csv`
      );
    } else {
      showAlert({
        title: 'No appointments found!',
        message:
          "We couldn't find any appointments. Please make sure you selected a date range before downloading.",
        color: 'danger'
      });
    }
  };

  return (
    <div className="PopoverNavBarFilters">
      <DateRangePicker
        months={1}
        weekStartsOn={1}
        ranges={exportRange}
        direction="horizontal"
        scroll={{ enabled: true }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        maxDate={addDays(new Date(), 300)}
        minDate={addDays(new Date(), -300)}
        onChange={(item) => setExportRange([item.selection])}
      />
      <div className="flex items-center gap-2">
        <ReactToPrint
          documentTitle={labelRange}
          content={() => exportRef.current}
          trigger={() => <Button outlined text="PDF" loading={loading.generate} />}
        />
        <Button
          text="CSV"
          outlined
          onClick={() => handleDownloadCSV()}
          loading={loading.generate}
        />
      </div>

      <div ref={exportRef} className="hidden bg-white print:block">
        <h4 className="!mb-2 text-neutral-900">{labelRange}</h4>
        <ExportTable appointments={appointments} />
      </div>
    </div>
  );
};

export default ExportCalendar;
