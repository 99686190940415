import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';

import { useRecoilValue } from 'recoil';

import { iaRa } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';

import Card from 'components/practice/charts/Documents/Card';
import { currentPractice } from 'components/practice/practiceState';
import { AlertContent } from 'components/shared/Alert/Alert';
import HipaaForm from 'components/shared/Forms/HipaaForm/HipaaForm';
import Loading from 'components/shared/Loading/Loading';

import InsuranceProfiles from '../../InsuranceProfile/InsuranceProfiles';

const PreviewDocument = ({
  url,
  hipaaData,
  setLoading,
  handle,
  type,
  patientId,
  exportRef,
  setViewDocument,
  loading
}) => {
  const { data } = usePatient({ params: { id: patientId }, dependencies: [patientId] });
  const insuranceProfiles = iaRa(data?.patient?.insuranceProfile);

  const practice = useRecoilValue(currentPractice);

  if (loading?.filestack) {
    return <Loading />;
  }
  if (type === 'image' || type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg') {
    return <img className="w-full max-w-[640px]" src={url} />;
  } else if (type === 'hipaa' || type === 'pdf' || type === 'application/pdf') {
    return type === 'hipaa' && !url ? (
      hipaaData ? (
        <div
          className="print:p-4"
          ref={exportRef}
          dangerouslySetInnerHTML={{ __html: hipaaData }}></div>
      ) : (
        <>
          <AlertContent
            className="mb-[1rem] w-full"
            color="warning"
            title="Warning"
            message="HIPAA form was not signed by the patient yet."
          />
          <HipaaForm ref={exportRef} disabled={true} />
        </>
      )
    ) : (
      <iframe
        src={url}
        ref={exportRef}
        className="print:p-4"
        style={{ width: '100%', height: 'calc(100vh - 11.25rem)' }}></iframe>
    );
  } else if (['application/html', 'application/rtf', 'html', 'rtf', 'custom/html'].includes(type)) {
    return (
      <div className="h-[80vh] rounded-2xl bg-white p-0.5">
        <iframe srcDoc={url} className="h-full w-full bg-white" />
      </div>
    );
  } else if (['application/eml', 'eml', 'message/rfc822'].includes(type)) {
    if (!url?.[0] || !url?.[1]) {
      return <Loading />;
    }
    return (
      <div className="h-[80vh] rounded-2xl bg-white p-0.5">
        <div dangerouslySetInnerHTML={{ __html: url[0] }} className="h-fit w-full bg-white" />
        <hr className="m-0" />
        <iframe srcDoc={url[1]} className="h-[70vh] w-full bg-white" />
      </div>
    );
  } else if (type === 'id') {
    return (
      <div className="IdAndInsurance">
        <Card
          handle={handle}
          patientId={patientId}
          setViewDocument={setViewDocument}
          label="Front"
          loading={loading.personal_id_front}
          setLoading={setLoading}
          url={handle?.front}
          type="personal_id_front"
        />
        <Card
          handle={handle}
          patientId={patientId}
          setViewDocument={setViewDocument}
          label="Back"
          loading={loading.personal_id_back}
          setLoading={setLoading}
          url={handle?.back}
          type="personal_id_back"
        />
      </div>
    );
  } else if (type === 'insurance') {
    return loading.filestack ? (
      <Loading />
    ) : (
      <div className="IdAndInsurance">
        <InsuranceProfiles
          patientId={patientId}
          patient={data?.patient}
          practiceId={practice.id}
          insuranceProfiles={insuranceProfiles}
        />
      </div>
    );
  } else if (type === 'form') {
    return (
      <div className="new-forms">
        <ReactFormGenerator
          answer_data={handle?.json?.fields && JSON.parse(handle?.json?.fields)}
          data={handle?.form_json?.fields}
          submitButton={<div />}
          read_only={true}
        />
      </div>
    );
  } else {
    return <p>Preview is not available.</p>;
  }
};

export default PreviewDocument;
