import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import useWebSocket from '../../../../lib/hooks/useMessageWebSocket';
import { notificationState } from '../../../Navbar/notificationState';
import MessageComponent from '../../../shared/Messaging/Message';
import { communicationFilter } from '../../practiceState';

export default function Message() {
  const { patient, groupedMessages, practice, handleChange, handleSubmit, loading, body } =
    useOutletContext();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [communicationFilters, setCommunicationFilters] = useRecoilState(communicationFilter);
  const [searchParams] = useSearchParams();
  const { id: patient_id } = useParams();
  const messageId = searchParams.get('messageId');

  const [notificationsState, setNotificationsState] = useRecoilState(notificationState);
  const { messageUnreadCount } = notificationsState;
  useEffect(() => {
    const onScrollToTop = async () => {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        messageApply: !prevState.messageApply,
        logApply: !prevState.logApply,
        scroll: true
      }));
    };

    !messageId && onScrollToTop();
  }, [page]);

  useEffect(() => {
    if (ws) readAllMessages();
  }, [ws, practice, patient_id, groupedMessages]);

  const { ws } = useWebSocket({
    dependency: practice.id
  });

  const readAllMessages = async () => {
    let params = {
      practiceId: practice.id,
      kind: 'message',
      markRead: true,
      userId: patient_id
    };
    try {
      const { data } = await interimApi('/api/practice/messages/update', params, navigate);
      const { messages: patchedMessages } = data;
      if (patchedMessages > 0) {
        ws.send(JSON.stringify({ practiceId: practice.id, patchedMessages, type: 'messageCount' }));
      }
    } catch (error) {
      toast.error(`Something went wrong. Please try again later.`);
    }
  };

  return (
    <MessageComponent
      patient={patient}
      groupedMessages={groupedMessages}
      practice={practice}
      loading={loading}
      onChange={handleChange}
      onClick={handleSubmit}
      body={body}
      setPage={setPage}
    />
  );
}
