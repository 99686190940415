import { deleteCustomFormResponse } from 'api/CustomForms';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomFormConfirm = ({
  type,
  itemToDelete,
  setItemToDelete,
  modalOpen,
  hideModal,
  onDeleteItem
}) => {
  const navigate = useNavigate();

  const onDelete = async (customFormId) => {
    await deleteCustomFormResponse(navigate, {
      customFormId
    });

    if (onDeleteItem) onDeleteItem(customFormId);
  };

  const onConfirmContinue = async () => {
    await onDelete(itemToDelete);
    setItemToDelete(null);
    if (!!modalOpen) {
      hideModal();
    }
  };

  return (
    <Confirm
      handleOpen={!!itemToDelete}
      handleClose={() => setItemToDelete(null)}
      title={`Delete ${type ?? ''}`}
      icon="new-info"
      iconSize={42}
      iconColor="danger"
      message="Are you sure you want to delete this item?"
      primaryBtnTxt="Delete"
      secondaryBtnTxt="Cancel"
      variant="danger"
      overlayClassName="z-[1001]"
      handleContinue={onConfirmContinue}
    />
  );
};

export default CustomFormConfirm;
