import React from 'react';
import Icon from '../../../../../../../../shared/Icon/Icon';
import Skeleton from '../../../../../../../../shared/Skeleton/Skeleton';

const LoadingTranscript = () => {
  return (
    <div className="relative h-full">
      <div className="absolute z-10 flex h-full w-full flex-col items-center justify-center">
        <div className="flex h-64 w-64 items-center justify-center !rounded-full border !border-primary-100 bg-primary-50">
          <div className="flex flex-col items-center justify-center text-center">
            <p className="inline-block bg-gradient-to-r from-[#13B8FF] to-[#007BB0] bg-clip-text text-lg font-medium leading-snug text-transparent">
              MyScribe
            </p>

            <Icon icon="new-myscribe-ai-processing" />
          </div>
        </div>

        <div className="!mt-4 flex !h-28 !w-full flex-col items-center justify-center bg-white text-center">
          <p className="text-lg font-500 text-primary-900">Recording ended successfully!</p>
          <div className="flex w-[320px] justify-center">
            <p className="text-base text-neutral-600">Transcription is being generated</p>
            <p className="animate-ellipsis flex w-[10px] flex-col justify-end pb-0.5 text-start"></p>
          </div>
          <div className="flex justify-center">
            <p className="text-sm text-neutral-600">
              Please be patient while your request is processed. This may take a few minutes.
            </p>
          </div>
        </div>
      </div>

      <Skeleton count={15} height="50px" baseColor="#E6F8FF" />
    </div>
  );
};

export default LoadingTranscript;
