import { requestApi } from '../Api';
const _ = require('lodash');

export const filterSortLogs = (logs) => {
  return _.uniqBy(logs, (e) => e.id).sort((a, b) => b.id - a.id);
};

export const listLogs = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/logs/list', params, navigate });
};

export const getLog = async (navigate, params) => {
  return await requestApi({ url: '/api/admin/logs/get', params, navigate });
};
