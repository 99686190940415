import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { listPromptTemplates } from 'api/Admin/intelligence/prompts';

export const useAiPromptTemplate = ({ params, options, dependencies, name }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => listPromptTemplates(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
