import { useTagContext } from 'lib/context/TagContext/TagContext';
import { ia } from 'lib/helpers/utility';
import React from 'react';
import { tagColorOptions } from '../../lib/tagColorOptions';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const OrderedView = ({ handleRemove = () => {}, showAllTags }) => {
  const { device } = useUIContext();
  const { importantTags, regularTags } = useTagContext() || [];

  const otherTags =
    device !== 'desktop'
      ? regularTags
      : ia(importantTags) && importantTags.length == 1
        ? regularTags?.slice(showAllTags - importantTags.length)
        : ia(importantTags)
          ? regularTags
          : regularTags?.slice(showAllTags);

  const tagContent = (tag, currBorderColor, isNew) => {
    return (
      <div
        className="flex max-h-[25px] max-w-max items-center gap-[6px] truncate rounded px-2 py-[2px]"
        style={{
          backgroundColor: tag?.color,
          border: `1px solid ${currBorderColor || 'transparent'}`
        }}>
        {tag?.important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
        <span
          className={cs(
            'select-none truncate text-sm font-400 leading-5',
            isNew ? 'text-neutral-900' : 'text-white'
          )}>
          {tag?.name}
        </span>
        <Icon
          icon="new-close-sm"
          size={8}
          color="black"
          stroke
          onClick={(e) => {
            e.stopPropagation();
            handleRemove(tag);
          }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-3">
      {importantTags?.slice(showAllTags).length > 0 && (
        <div className="flex flex-col gap-1">
          <p className="text-sm text-neutral-800">Important tags</p>
          <div className="flex flex-col gap-2">
            {importantTags.slice(showAllTags).map((tag, index) => {
              const isNew = tagColorOptions.find((option) => option.color === tag?.color);
              let currBorderColor = tagColorOptions.find(
                (option) => option.color === tag?.color
              )?.border;
              return tag?.name?.length > 40 ? (
                <Tippy
                  appendTo={document.body}
                  content={tag?.name}
                  className="tippy-dark text-base"
                  maxWidth="none"
                  key={index}>
                  <div>{tagContent(tag, currBorderColor, isNew)}</div>
                </Tippy>
              ) : (
                <>{tagContent(tag, currBorderColor, isNew)}</>
              );
            })}
          </div>
        </div>
      )}

      {ia(otherTags) && (
        <div className="flex flex-col gap-1">
          <p className="text-sm text-neutral-800">
            {ia(importantTags) ? 'All other tags on this chart' : 'All tags on this chart'}
          </p>
          <div className="flex flex-col gap-2">
            {otherTags?.map((tag, index) => {
              const isNew = tagColorOptions.find((option) => option.color === tag?.color);
              let currBorderColor = tagColorOptions.find(
                (option) => option.color === tag?.color
              )?.border;
              return tag?.name?.length > 40 ? (
                <Tippy
                  content={tag?.name}
                  className="tippy-dark text-base"
                  key={index}
                  maxWidth="none">
                  <div>{tagContent(tag, currBorderColor, isNew)}</div>
                </Tippy>
              ) : (
                <>{tagContent(tag, currBorderColor, isNew)}</>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderedView;
