export const useBreadcrumbFunctions = (breadcrumb) => {
  const { steps, setSteps, step, setStep, push } = breadcrumb;

  const goToStep = (name, title) => {
    push({ title, name });
  };

  const goBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  return { steps, step, goToStep, goBack, setSteps, setStep, push };
};
