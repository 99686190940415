import React, { useState } from 'react';
import { FaxContext } from './FaxContext';

export const FaxContextProvider = ({ children }) => {
  const [openTagModal, setOpenTagModal] = useState(false);
  const [faxTags, setFaxTags] = useState([]);
  const [faxId, setFaxId] = useState(0);
  const [prevTags, setPrevTags] = useState([]);
  const [disableAdd, setDisableAdd] = useState(true);
  return (
    <FaxContext.Provider
      value={{
        openTagModal,
        setOpenTagModal,
        faxTags,
        setFaxTags,
        faxId,
        setFaxId,
        prevTags,
        setPrevTags,
        disableAdd,
        setDisableAdd
      }}>
      {children}
    </FaxContext.Provider>
  );
};
