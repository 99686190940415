import { getRecoil, setRecoil } from 'recoil-nexus';

import { removeHtmlTags } from 'lib/helpers/utility';

import { convertClinicalNoteToHTML } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';
import state from 'components/state';

let isGenerationStarted = false;
const updateAutoMapTemplate = (data) => {
  const { vertical, response, done = false, isDescription = false, ...rest } = data;
  if (!isGenerationStarted) {
    setRecoil(state.aiGeneratingState, false);
    isGenerationStarted = true;
  }
  try {
    // Convert JSON to HTML
    const htmlResponse = convertClinicalNoteToHTML(JSON.parse(response));
    const newAiClinicalNoteState = getRecoil(state.intelligenceAiState);

    if (newAiClinicalNoteState?.timeoutTimeout)
      clearTimeout(newAiClinicalNoteState?.timeoutTimeout);

    setActivePrompt({
      isDescription,
      ...(!isDescription && { loading: !done }),
      response: htmlResponse,
      jsonResponse: response,
      path: `${vertical}`,
      ...rest
    });
  } catch (error) {
    console.error('❌ Error', error);
  }
};

const setActivePrompt = (intelligenceAi) => {
  if (intelligenceAi) {
    if (intelligenceAi?.isDescription) {
      setRecoil(state.myScribeAutoMapPromptListState, (prevPrompts) => {
        const newPrompt = {
          id: intelligenceAi?.intelligence_chat_id,
          name: removeHtmlTags(intelligenceAi?.response),
          description: ''
        };
        if (intelligenceAi?.intelligence_chat_message_id) {
          return [newPrompt, ...prevPrompts.slice(1)];
        }
        return [newPrompt, ...prevPrompts];
      });
      setRecoil(state.intelligenceChatIdState, intelligenceAi?.intelligence_chat_id);
      setRecoil(
        state.intelligenceChatMessageIdState,
        parseInt(intelligenceAi?.intelligence_chat_message_id)
      );

      return;
    }

    if (intelligenceAi?.response && !intelligenceAi?.isDescription) {
      setRecoil(state.myScribeAutoMapCurrentActivePromptState, (prevState) => {
        const newMessageObj = {
          id: 1,
          message: intelligenceAi?.response,
          agent: 'ai'
        };
        let newPrompt = [...prevState];
        if (newPrompt[newPrompt?.length - 1]?.agent === 'ai') {
          newPrompt[newPrompt?.length - 1] = newMessageObj;
        } else {
          newPrompt = [...prevState, newMessageObj];
        }
        return newPrompt;
      });
    }
  }
};

export default updateAutoMapTemplate;
