import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { requestApi } from 'api/Api';
import NavLinkTabs from 'components/shared/NavLinkTabs/Tabs';
import Status from 'components/shared/Status/Status';

const AdminUserUser = () => {
  const [show, setShow] = useState({ loading: false });
  const [user, setUser] = useState({ f_name: '', l_name: '', email: '', status: '' });
  const { id: paramId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (parseInt(paramId)) {
      getAdminUser();
    }
  }, [paramId]);

  const getAdminUser = async () => {
    setShow((ps) => ({ ...ps, loading: true }));
    const res = await requestApi({
      url: '/api/admin/users/get',
      navigate,
      params: { userId: parseInt(paramId) }
    });
    setUser((ps) => ({ ...ps, ...res?.user }));
    setShow((ps) => ({ ...ps, loading: false }));
  };

  if (show?.loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex h-full flex-col">
      <div className="mb-[1rem] flex flex-row items-center gap-4 rounded-2xl bg-white p-[1rem]">
        <div className="font-700 text-primary-900">
          {user?.f_name} {user?.l_name}
        </div>
        <div>{user?.email}</div>
        <Status status={user?.status} />
      </div>
      <div className="flex h-full flex-col rounded-2xl bg-white p-[1rem]">
        <NavLinkTabs
          list={[
            { name: 'Overview', path: '' },
            { name: 'Logs', path: 'logs' },
            { name: 'Sessions', path: 'sessions' }
          ]}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default AdminUserUser;
