import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io, isEmpty } from 'lib/helpers/utility';
import React from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';

const Session = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();
  const session = clinicalNote?.epn?.session;
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(session)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Session</h3>
      {isTemplateChecked && (
        <div className="grid grid-cols-5 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0">
          {session?.type && (
            <div className="grid">
              <p className="text-xs font-500 text-neutral-700 print:text-base">Type of session</p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {session?.type}
              </p>
            </div>
          )}
          {session?.startDate && (
            <div className="grid">
              <p className="text-xs font-500 text-neutral-700 print:text-base">Start date</p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {session?.startDate}
              </p>
            </div>
          )}
          {io(session?.startTime) && (
            <div className="grid">
              <p className="text-xs font-500 text-neutral-700 print:text-base">Start time</p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {session?.startTime.value}
              </p>
            </div>
          )}
          {session?.endDate && (
            <div className="grid">
              <p className="text-xs font-500 text-neutral-700 print:text-base">End date</p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {session?.endDate}
              </p>
            </div>
          )}
          {io(session?.endTime) && (
            <div className="grid">
              <p className="text-xs font-500 text-neutral-700 print:text-base">End time</p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                {session?.endTime.value}
              </p>
            </div>
          )}
        </div>
      )}

      {isNarrativeChecked && !isEmpty(session?.narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={session?.narrative} />
        </div>
      )}
    </div>
  );
};

export default Session;
