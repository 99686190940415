import cs from 'classnames';
import * as filestack from 'filestack-js';
import React, { useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
const client = filestack.init(process.env.REACT_APP_FILESTACK);

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import overlay from '../../practice/charts/InsuranceProfile/icons/overlay.svg';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import ImagePickerActions from './components/ImagePickerActions';
import { captureHighResScreenshot, resizeFile } from './lib/helpers';

const ImagePicker = ({
  handle,
  onCapture = () => {},
  name,
  kiosk,
  processing,
  handleContinue,
  resetHandle,
  coords = {},
  removeOverlay,
  defaultFacingMode = 'environment',
  dimensions = [1280, 720]
}) => {
  const webcamRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [cameraReady, setCameraReady] = useState(false); // State to track camera readiness
  const [facingMode, setFacingMode] = useState(defaultFacingMode);
  const [url, setUrl] = useState(handle ? `https://cdn.filestackcontent.com/${handle}` : null);
  const [crop, setCrop] = useState(null);

  const { device } = useUIContext();

  const imageRef = useRef(null);

  const handleCameraLoad = () => {
    setCameraReady(true);
  };

  useEffect(() => {
    setUrl(handle ? `https://cdn.filestackcontent.com/${handle}` : null);
  }, [handle]);

  const videoConstraints = {
    facingMode: { ideal: facingMode },
    width: { ideal: dimensions[0] || 1280 },
    height: { ideal: dimensions[1] || 720 }
  };

  const capture = async () => {
    setLoading(true);
    const imageSrc = captureHighResScreenshot(webcamRef);
    const blob = await fetch(imageSrc).then((res) => res.blob());
    const resizedImageBlob = await resizeFile(blob);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await client.upload(resizedImageBlob);
    onCapture(response?.handle, name);
    setLoading(false);
  };

  const onFileChange = async (event) => {
    setLoading(true);
    const file = event.target.files[0];
    const resizedImageBlob = await resizeFile(file);
    setUrl(URL.createObjectURL(resizedImageBlob));
    const response = await client.upload(resizedImageBlob);
    onCapture(response?.handle, name);
    setLoading(false);
  };

  useEffect(() => {
    const img = imageRef.current;
    const handleImageLoad = () => {
      if (coords && img) {
        const scaleX = img.width / img.naturalWidth;
        const scaleY = (img.height / img.naturalHeight) * (img.naturalHeight / img.naturalWidth);

        setCrop({
          unit: 'px',
          width: Math.abs(coords?.width) * scaleX,
          height: Math.abs(coords?.height) * scaleY,
          x: coords?.x * scaleX,
          y: coords?.y * scaleY
        });
      }
    };

    if (imageRef.current?.complete) {
      handleImageLoad();
    }

    img?.addEventListener('load', handleImageLoad);

    return () => {
      img?.removeEventListener('load', handleImageLoad);
    };
  }, [coords]);

  useEffect(() => {
    if (imageRef.current?.complete) {
      setCrop(null);
    }
  }, [imageRef.current?.complete]);

  return (
    <div className="flex w-full flex-col items-center px-6">
      <div
        style={{ maxWidth: '30rem', maxHeight: '60vh' }}
        className={url ? 'relative mt-4' : 'relative mt-4 overflow-hidden rounded-xl'}>
        {url ? (
          <>
            {!processing ? (
              <ReactCrop crop={crop} onChange={(newCrop) => setCrop(newCrop)}>
                <img ref={imageRef} crossOrigin="anonymous" src={url} />
              </ReactCrop>
            ) : (
              <img ref={imageRef} className="w-full" crossOrigin="anonymous" src={url} />
            )}

            {loading && !kiosk && !removeOverlay && (
              <div className="absolute bottom-0 left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl ">
                <img className="!w-full" src={overlay}></img>
              </div>
            )}
          </>
        ) : (
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              screenshotQuality={1}
              className="h-full w-full rounded-xl object-contain"
              onUserMedia={handleCameraLoad} // Event to mark camera as ready
            ></Webcam>
            {!kiosk && (
              <div
                className={cs(
                  'absolute  left-0 right-0 top-0 flex items-center justify-center overflow-hidden rounded-xl',
                  device === 'tablet'
                    ? 'bottom-0'
                    : device === 'mobile' || device === 'tablet-sm'
                      ? 'bottom-[4.38px]'
                      : 'bottom-[5.5px]'
                )}>
                {!removeOverlay && <img className="!w-full" src={overlay}></img>}
              </div>
            )}
          </>
        )}
      </div>

      <div className="mt-5 flex items-center justify-center gap-4 text-white">
        <ImagePickerActions
          handle={handle}
          name={name}
          processing={processing}
          resetHandle={resetHandle}
          coords={coords}
          crop={crop}
          loading={loading}
          imageRef={imageRef}
          handleContinue={handleContinue}
          onFileChange={onFileChange}
          capture={capture}
          cameraReady={cameraReady}
          setUrl={setUrl}
          setFacingMode={setFacingMode}
        />
      </div>
    </div>
  );
};

export default ImagePicker;
