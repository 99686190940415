import { useState } from 'react';

import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';

import DocumentModal from 'components/practice/PracticeIQueue/Orders/components/DocumentModal';
import OrdersModal from 'components/practice/charts/ClinicalNote/Orders/OrdersModal';
import Button from 'components/shared/Buttons/Button';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import OrdersTable from 'components/shared/Forms/Custom/Orders/Orders';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

export default function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [activeDocument, setActiveDocument] = useState(null);

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        onPlusClick={() => {
          setModalOpen(true);
        }}
        icon="new-note"
        iconColor="primary"
        addPermissions="patients_medical_history.update"
        {...props}
      />
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          <FooterWrapper
            onClick={() => setShow(true)}
            text={orders.length > 0 ? `Orders (${orders.length})` : ''}>
            <OrdersTable setOrders={setOrders} orders={orders} />
          </FooterWrapper>
        </div>

        <Modal
          handleOpen={show && !modalOpen}
          className="w-[81%] h-full bg-white sm:w-[86%] md:w-[86%] lg:w-[86%] xxl:max-w-[1920px]"
          bodyClassName="p-0"
          title="Orders"
          handleClose={() => setShow(false)}
          customHeader={
            <div className="flex items-center justify-between w-full !me-6">
              <h6 className="text-primary-900 capitalize whitespace-nowrap">Orders</h6>
              <div className="flex items-center">
                <Button
                  text="Add new order"
                  iconRight="add-circle"
                  transparent
                  onClick={() => {
                    setModalOpen(true);
                  }}
                  data-qa="add-new-order-btn"
                />
              </div>
            </div>
          }>
          <OrdersTable setOrders={setOrders} />
        </Modal>

        {!!modalOpen && (
          <ClinicalNoteContextProvider>
            <AmbientListeningContextProvider>
              <Chart3dContextProvider>
                <OrdersModal
                  modalOpen={modalOpen && !activeDocument}
                  handleClose={() => {
                    setModalOpen(false);
                  }}
                  openResultsModal={(item) =>
                    setActiveDocument({ url: item.report_url, title: item.report_title })
                  }
                />
              </Chart3dContextProvider>
            </AmbientListeningContextProvider>
          </ClinicalNoteContextProvider>
        )}

        <DocumentModal
          title={activeDocument?.title}
          url={activeDocument?.url}
          open={!!activeDocument}
          closeHandler={() => {
            setActiveDocument(null);
          }}
        />
      </Allowed>
    </div>
  );
}
