export const ndcSearchOptions = [
  {
    value: 'brand_name',
    label: 'Proprietary Name'
  },
  {
    value: 'application_number',
    label: 'Application Number / Monograph ID'
  },
  {
    value: 'generic_name',
    label: 'Non Proprietary Name'
  },
  {
    value: 'package_ndc',
    label: 'NDC Code'
  },
  {
    value: 'labeler_name',
    label: 'Labeler'
  }
];
