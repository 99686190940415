import { downloadERA } from 'api/ERA';

import { ia } from 'lib/helpers/utility';

export const downloadEraFile = async (navigate, id) => {
  const data = await downloadERA(navigate, { id });

  if (ia(data?.era?.data)) {
    const linkSource = `data:application/pdf;base64,${data?.era?.data[0]}`;
    const downloadLink = document.createElement('a');
    const fileName = `era_${data?.era?.eraid}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return data;
};
