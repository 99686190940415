import React from 'react';
import Moment from 'moment';
import { Truncate } from 'lib/helpers/utility';

export default function ReferralRow({ referral }) {
  return (
    <div className="grid cursor-pointer grid-cols-3 gap-2 rounded-md bg-neutral-50 px-3 py-2 hover:bg-neutral-100">
      <span className="text-sm text-neutral-900">{referral.practice.name}</span>
      <span className="text-sm text-neutral-900">
        {Moment(referral.created_at).format('MMM DD, YYYY')}
      </span>
      <span className="text-sm text-neutral-900">{Truncate(referral.body, 15)}</span>
    </div>
  );
}
