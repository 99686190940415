import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';

import { getServices } from 'api/Service';

import { formatDate, ia, iaRa } from 'lib/helpers/utility';

import {
  calculateTotalCharge,
  getPlaceOfService,
  handleCPTSearch
} from 'components/practice/charts/SuperBillPage/lib';
import { handleSelectCPT } from 'components/practice/charts/SuperBillPage/lib/handleSelectCPT';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

import { Procedure } from '../Procedure';

const ClaimProcedures = ({ claim, setClaim, updateClaim }) => {
  const [hasNdcOptions, setHasNdcOptions] = useState(false);

  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  const hasBaseUnit = iaRa(claim?.procedures).some((item) => item.base_unit);
  const hasNdcCode = iaRa(claim?.procedures).some((item) => item.ndc_code);

  const selectCPT = async (e, index) => {
    const data = await getServices(navigate, { cpt: e.value });
    const service = data?.services;

    const updatedProcedures = handleSelectCPT({
      procedures: claim?.procedures,
      index,
      service,
      e,
      practice,
      claim
    });

    setClaim((prevClaim) => ({
      ...prevClaim,
      procedures: updatedProcedures,
      total_charge: calculateTotalCharge(updatedProcedures, true)
    }));
  };

  const addCPTRow = () => {
    const procedures = ia(claim?.procedures) ? [...(claim?.procedures || [])] : [];

    const dos = formatDate(claim?.appointment?.starts_at, practice?.timezone);

    procedures?.push({
      code: null,
      modifiers: null,
      charge: 0,
      diagnosis: null,
      from_date: dos,
      thru_date: dos,
      narrative: '',
      units: '1',
      place_of_service: getPlaceOfService(claim?.superbill, practice),
      type: 'primary'
    });
    setClaim({ ...claim, procedures });
  };

  return (
    <div className="col-start-1 col-end-4">
      <table className="primary-table">
        <thead className="!relative">
          <tr>
            <th className="w-[300px] !p-1">
              <div className="flex items-center gap-1 self-center">
                <p>24. A. Date(s) of service</p>
                <Tippy
                  className="tippy-dark"
                  content="Only primary-type services will be shown in the claim; other services will appear in the clinical note and superbill.">
                  <div>
                    <Icon icon="new-info" color="primary" />
                  </div>
                </Tippy>
              </div>
            </th>
            <th className="!p-1">NDC Narrative</th>
            <th className="!p-1">B. Pos</th>
            <th className="!p-1">C. Emg</th>
            <th className="!p-1">D. Procedure</th>
            <th className="!p-1">Modifier</th>
            <th className="!p-1">E. Diag ref</th>
            <th className="!p-1">F. $ Charges</th>
            <th className="!p-1">G. Units</th>
            {(hasNdcCode || hasNdcOptions) && (
              <>
                <th className="!p-1"> NDC Code</th>
                <th className="!p-1"> NDC Dosage</th>
                <th className="!p-1"> NDC Measure</th>
              </>
            )}
            {hasBaseUnit && (
              <>
                <th className="!p-1">Start time</th>
                <th className="!p-1">End time</th>
              </>
            )}
            <th className="!p-1"></th>
          </tr>
        </thead>
        <tbody>
          {ia(claim?.procedures) ? (
            claim?.procedures?.map((item, index) => (
              <Procedure
                claim={claim}
                hasNdcOptions={hasNdcOptions}
                setHasNdcOptions={setHasNdcOptions}
                setClaim={setClaim}
                key={index}
                procedure={item}
                selectCPT={selectCPT}
                hasBaseUnit={hasBaseUnit}
                handleCPTSearch={(a, b) => handleCPTSearch(a, b)}
                index={index}
                updateClaim={updateClaim}
              />
            ))
          ) : (
            <td className="ml-10 !p-4">
              <span className="text-sm font-500 text-neutral-800">No cpt codes added!</span>
            </td>
          )}
        </tbody>
      </table>
      <Button
        data-qa="add-cpt-btn"
        onClick={() => addCPTRow()}
        className="!mt-2"
        text="Add CPT"
        color="success"
        type="secondary"
      />
    </div>
  );
};

export default ClaimProcedures;
