import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';
import moment from 'moment';

import { requestApi } from 'api/Api';

import { formatPhoneNumber, ia, iaRa } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';

import Avatar from 'components/shared/Avatar/Avatar';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const SearchPatient = () => {
  const ref = useRef();

  useOutsideClick(ref, () => {
    setShowMenu(false);
    setInputValue('');
  });

  const [inputValue, setInputValue] = useState('');
  const [showMenu, setShowMenu] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (value) => {
    setInputValue(value);
    if (inputValue === '') {
      setShowMenu(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      searchPatients(inputValue, setPatients);
    }, 500);

    return () => clearTimeout(timer);
  }, [inputValue]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && showMenu && patients.length > 0) {
        handleOptionClick(patients[0]);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [showMenu, patients]);

  const searchPatients = async (searchTerm, callback) => {
    if (!searchTerm) {
      setShowMenu(false);
      callback([]);
      setInputValue('');
    } else {
      setShowMenu(true);
      setLoading(true);
      setInputValue(searchTerm);

      const params = {
        limit: 20,
        offset: 0,
        searchTerm,
        type: 'patients'
      };

      try {
        const data = await requestApi({ url: '/api/practice/charts/get', navigate, params });
        setLoading(false);

        const { patients: loadedPatients } = data;

        if (data.patients) {
          const patientArray = [];

          ia(loadedPatients) &&
            loadedPatients?.forEach((element) => {
              patientArray.push({
                label: element?.fullName,
                value: element?.id,
                id: element?.id,
                dob: element?.dob,
                phone: element?.phone,
                gender: element?.gender,
                profilePhoto: element?.profile_photo,
                email: element?.email,
                f_name: element?.f_name,
                l_name: element?.l_name,
                virtual_link: element?.practicePatientLink?.virtual_link
              });
            });
          setPatients(patientArray);
        }
      } catch (err) {
        console.error('error', err);
      }
    }
  };

  const handlePatientClick = (option) => {
    navigate(`/portal/charts/${option.value}`);
  };

  const handleOptionClick = (option) => {
    setShowMenu(false);
    handlePatientClick(option);
  };

  const formatOptionLabel = (option) => {
    const { value, label, dob, gender, email, f_name, l_name, virtual_link, profilePhoto } = option;
    return (
      <div
        key={value}
        className="mr-2 mt-2 flex cursor-pointer items-start gap-[14px] rounded-xl bg-neutral-50 p-2 first:mt-0 hover:bg-primary-50"
        onClick={() =>
          handleOptionClick({
            value,
            label,
            email,
            dob,
            gender,
            l_name,
            f_name,
            id: value,
            profilePhoto,
            fullName: label,
            virtual_link
          })
        }>
        <Avatar
          size="3xl"
          id={option.value}
          lastName={l_name}
          firstName={f_name}
          image={profilePhoto}
        />
        <div className="grid items-center gap-[2px]">
          {option?.label && (
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
              {option.label}
            </p>
          )}
          {option?.phone && (
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
              {formatPhoneNumber(option?.phone)}
            </p>
          )}
          {option?.dob && (
            <p className="text-xs text-neutral-800">
              {moment.utc(option?.dob).format('MMM DD, YYYY')}
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="relative flex flex-col" ref={ref}>
      <div className="relative flex w-full items-center justify-end">
        <div
          className={cs(
            'absolute right-0 flex h-[30px] w-[200px] border-neutral-200 !px-3 transition-all duration-200 ease-in-out'
          )}
          style={{
            borderBottom: !isFocused ? '1px solid #DADFE2' : '1px solid #13B9FF',
            caretColor: '#13B9FF'
          }}>
          <input
            placeholder="Search Patients"
            className={cs(
              'z-10 inline-block w-full border-0 bg-transparent text-sm text-neutral-900 placeholder:text-sm placeholder:text-neutral-500 focus:outline-none'
            )}
            onFocus={() => setIsFocused(true)}
            onBlur={() => {
              setIsFocused(false);
            }}
            value={inputValue}
            onChange={(e) => handleInputChange(e.target.value)}
          />
          <Icon
            icon="new-search-basic-v2"
            color="primary"
            shade={900}
            className="absolute right-3 top-[7px]"
          />
        </div>
        {showMenu && (
          <div className="absolute -left-[200px] top-5 z-50 flex min-h-[50px] w-[250px] flex-col justify-center !rounded-lg border border-primary-500 bg-white !p-2 !pr-1 !shadow-lg">
            <div className="max-h-96 overflow-auto">
              {loading ? (
                <Skeleton count={5} height={50} width="100%" />
              ) : ia(patients) ? (
                iaRa(patients).map((patient) => formatOptionLabel(patient))
              ) : (
                <span className="text-sm text-neutral-500">No patients found!</span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchPatient;
