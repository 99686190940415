const _ = require('lodash'); // Ensure lodash is imported

import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Tippy from '@tippyjs/react';

const toNumericId = (era) => Number(era.era_id);

const NavigateToERACell = ({ data, node }) => {
  const ref = useRef();

  if (!!node?.group) {
    return;
  }

  const claims = data?.claims || [];

  const eras = claims?.map((claim) => claim?.insurance_payment?.era).flat();

  const uniqueEras = _.uniqBy(eras, toNumericId);
  const [firstEra, ...restEras] = uniqueEras || [];

  return (
    <div className="flex flex-row">
      {firstEra && (
        <Link to={`/portal/beyond-billing/era-eob/all/${firstEra.era_id}`} target="_blank">
          {firstEra?.era_id}
        </Link>
      )}
      {restEras.length > 0 && (
        <div className="ml-3">
          <div ref={ref}>+{restEras.length}</div>
          <Tippy
            className="custom-tooltip !rounded-md"
            theme="light"
            reference={ref}
            content={
              <div className="flex-column flex p-2">
                {restEras?.map((era) => (
                  <div key={era.era_id} className="m-1 rounded-md p-1 hover:bg-primary-50">
                    <Link to={`/portal/beyond-billing/era-eob/all/${era.era_id}`} target="_blank">
                      {era.era_id}
                    </Link>
                  </div>
                ))}
              </div>
            }
            allowHTML
            interactive
            appendTo={() => document.body}
            placement="right"
            offset={[0, 10]}
          />
        </div>
      )}
    </div>
  );
};

export default NavigateToERACell;
