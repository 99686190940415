import React from 'react';
import cs from 'classnames';

export default function TimeSlots({
  day,
  index,
  handleLoadMore,
  handleClick,
  type = '',
  selectAppointmentLocation,
  appointmentLocation
}) {
  const slotVarType = type === 'location' ? 'locationAvailableSlots' : 'virtualAvailableSlots';
  const aptLocationVarType = type === 'location' ? 'in_person' : 'virtual';

  const RenderSlots = () => {
    return day[slotVarType].slice(0, day.showAll ? day[slotVarType].length : 4).map((i, idx) => {
      return (
        <div
          className={cs(
            'grid h-10 cursor-pointer place-content-center rounded-md border border-solid text-sm',
            day.selected == idx && appointmentLocation === aptLocationVarType
              ? '!border-neutral-800 !text-neutral-800'
              : 'border-neutral-100 text-neutral-500'
          )}
          key={i}
          onClick={() => {
            handleClick({ newTime: i, index, type: 'time', itemSelected: idx });
            type === 'virtual' && selectAppointmentLocation({ label: 'Virtual', value: 'virtual' });
            type === 'location' &&
              selectAppointmentLocation({ value: 'in_person', label: 'In-Person' });
          }}>
          <p>{i}</p>
        </div>
      );
    });
  };

  return (
    <div className="!mt-[15px]">
      <label className="!mb-3 !mt-2 text-sm text-neutral-800">
        Select from available time slots for {type}
      </label>

      <div className="grid grid-cols-5 !gap-3">
        <RenderSlots />

        {!day.showAll && day[slotVarType].length > 4 && (
          <div
            onClick={() => handleLoadMore({ index })}
            className={cs(
              'grid h-10 cursor-pointer place-content-center rounded-md border border-solid border-neutral-100 text-sm text-neutral-500 hover:!border-neutral-800 hover:text-neutral-800'
            )}>
            <p>Load All</p>
          </div>
        )}
      </div>
    </div>
  );
}
