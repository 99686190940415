import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import Questionnaire from './questionnaire';

export default function Questionnaires({ }) {
  const [form, setFormState] = useState({
    forms: []
  });
  const [response, setResponse] = useState({
    responses: []
  });
  const [currentScreen, setCurrentScreen] = useState('questionnaires');
  const navigate = useNavigate();

  useEffect(() => {
    getQuestionnaires();
    getResponses();
  }, []);

  const getQuestionnaires = async () => {
    try {
      let res = await interimApi('/api/form/questionnaires/get', {}, navigate);
      const { forms: loadedForms } = res.data;
      if (res.data.forms) {
        setFormState({
          forms: loadedForms
        });
      } else {
        // navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getResponses = async () => {
    let params = {
      type: '4'
    };
    try {
      let res = await interimApi('/api/form/questionnaires/get_responses', params, navigate);
      const { responses: loadedResponses } = res.data;
      if (res.data) {
        setResponse({
          responses: loadedResponses
        });
      } else {
        // navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div>
      {/* <PatientNavbar name={'Questionnaires'} /> */}
      <div className="flex-wrap -ml-3 -mr-3 -mt-3">
        <div className="block p-3">
          <div className="p-12">
            <div className="flex-wrap -ml-3 -mr-3 -mt-3 justify-center">
              <div
                className={`button mx-4 ${currentScreen === 'questionnaires' && 'bg-[#3298dc] text-white border-transparent'
                  } has-text-centered`}
                onClick={() => setCurrentScreen('questionnaires')}>
                New questionnaires
              </div>
              <div
                className={`button mx-4 ${currentScreen === 'responses' && 'bg-[#3298dc] text-white border-transparent'
                  } has-text-centered`}
                onClick={() => setCurrentScreen('responses')}>
                Show my responses
              </div>
            </div>
            {currentScreen === 'questionnaires' ? (
              <div className="p-12">
                <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                  {form.forms &&
                    form.forms.map((questionnaire, index) => {
                      return (
                        <div className="block p-3 flex-none w-1/3 mb-2" key={index}>
                          <Questionnaire
                            name={questionnaire.name}
                            json={questionnaire.json}
                            formId={questionnaire.id}
                            practiceId={questionnaire.practice_id}
                            response={false}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            ) : (
              <div className="p-12">
                <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                  {response.responses &&
                    response.responses.map((response, index) => {
                      return (
                        <div className="block p-3 flex-none w-1/3 mb-2" key={index}>
                          <Questionnaire
                            name={response?.form?.name}
                            json={response?.form?.json}
                            formId={response?.form?.form_id}
                            answers={response?.json}
                            practiceId={response?.form?.practice_id}
                            response={true}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
