import React from 'react';
import Select from '../../../../shared/Select/Select';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import Input from '../../../../shared/Input/Input';
import { Capitalize, optionify } from '../../../../../lib/helpers/utility';
import moment from 'moment';
import {
  appointment_status,
  appointment_state,
  appointment_lengths
} from '../../../../../constants';
import { useRecoilValue } from 'recoil';
import practiceState from '../../../practiceState';
import { getHours } from '../lib/previewAptHelpers';
import { capitalize } from 'lodash';

const AppointmentPreviewInputs = ({ formik, dateAndTime, practiceTimezone, changeDateTime }) => {
  const appointmentStatuses = useRecoilValue(practiceState.appointmentStatuses);
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const hours = getHours(currPractice);

  const selectTimeLength = (time) => {
    const { value } = time;

    formik.setFieldValue('appointmentLength', value);
    formik.setFieldValue(
      'ends_at',
      moment.tz(formik.values.starts_at, practiceTimezone).add(value, 'm').format()
    );
  };
  const selectedStatus = appointmentStatuses.find(
    (status) => status.value === formik.values.status
  ) || { label: capitalize(formik.values.status), value: formik.values.status };
  return (
    <div data-dd-privacy="allow">
      <div className="grid w-full grid-cols-3 gap-3" data-dd-privacy="allow">
        <Select
          label="Appointment status"
          placeholder="Type and select"
          value={!!formik.values?.status && selectedStatus}
          onChange={(value) => {
            formik.setFieldValue('status', value.value);
          }}
          options={appointmentStatuses}
          inputId="statusSelect"
          isClearable={false}
        />
        <Select
          className="w-full"
          label="Appointment type"
          placeholder="Type and select"
          value={
            !!formik.values?.type && {
              value: formik.values.type,
              label: Capitalize(String(formik.values.type).replaceAll('_', ' '))
            }
          }
          onChange={(value) => {
            formik.setFieldValue('type', value.value);
          }}
          options={currPractice?.appointment_types}
          inputId="serviceSelect"
          error={formik.errors.type}
          isClearable={false}
        />
        <div className="flex flex-col justify-between" data-dd-privacy="allow">
          <Select
            label="Appointment duration"
            className="w-full"
            placeholder="Type and select"
            value={
              !!formik.values?.appointmentLength && {
                value: formik.values.appointmentLength,
                label: `${formik.values.appointmentLength} min`
              }
            }
            onChange={selectTimeLength}
            options={appointment_lengths}
            inputId="durationSelect"
            menuPortalTarget={document.body}
            menuPlacement="top"
            components={{ IndicatorSeparator: () => null }}
            error={formik.errors.appointmentLength}
            isClearable={false}
          />
        </div>
      </div>
      <div className="!mt-4 grid w-full grid-cols-[37%,27%,31%]  !gap-3" data-dd-privacy="allow">
        <Popover className="relative -mt-[3px]">
          {({ close }) => (
            <>
              <Popover.Button className="w-full p-0">
                <Input
                  data-qa="preview-appointment-date"
                  label="Date"
                  rightIcon="new-calendar-gray"
                  value={moment.tz(dateAndTime, practiceTimezone).format('MM/DD/YYYY, ddd')}
                />
              </Popover.Button>

              <Popover.Panel className="absolute z-10 flex shadow-md">
                <Calendar
                  date={new Date(dateAndTime)}
                  onChange={(date) => {
                    changeDateTime({ newTime: date, type: 'date' });
                    close();
                  }}
                />
              </Popover.Panel>
            </>
          )}
        </Popover>

        <Select
          label="Time"
          isClearable={false}
          rightIcon="new-clock-gray"
          className="!-mt-[2px]"
          options={hours.map((hour) => ({ value: hour, label: hour }))}
          value={{
            label: moment.tz(dateAndTime, practiceTimezone).format('hh:mm A'),
            value: moment.tz(dateAndTime, practiceTimezone).format('hh:mm A')
          }}
          onChange={(val) => changeDateTime({ newTime: val.value, type: 'time' })}
        />

        <Select
          label="State"
          placeholder="Type and select State"
          value={
            !!formik.values?.state && {
              value: formik.values.state,
              label: appointment_state[formik.values.state]
            }
          }
          isClearable={false}
          className="!-mt-[2px]"
          inputId="stateSelect"
          options={optionify(appointment_state)}
          onChange={(value) => formik.setFieldValue('state', value.value)}
        />
      </div>
    </div>
  );
};

export default AppointmentPreviewInputs;
