import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Button from '../../Button/Button';
import SignatureCanvas from 'react-signature-canvas';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import * as filestack from 'filestack-js';
import './SignatureModal.scss';

const client = filestack.init(process.env.REACT_APP_FILESTACK);
const PAGE_CHARACTERS = 2300;
const LINE_WIDTH = 180;
const LINE_HEIGHT = 1.5;

const SignatureModal = ({
  showSignatureModal,
  hideSignatureModal,
  signature,
  trimSignature,
  sigPad,
  divToPrint,
  fullFormRef,
  savePDF = () => { },
  x = 10,
  y = 120,
  fileName = 'myriad-file'
}) => {
  const [drawn, setDrawn] = useState(false);
  useEffect(() => {
    if (signature) {
      printDocument();
    }
  }, [signature]);

  const printDocument = () => {
    const signatureCurrent = divToPrint.current;
    const fullFormCurrent = fullFormRef.current;
    html2canvas(signatureCurrent, { scale: 0.8 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png', 1.0);
      const raw_hipaaText = fullFormCurrent.innerHTML;
      const hipaaText = raw_hipaaText
        .replace(/&amp;/g, '&')
        .replace(/<[^>\/]*>/g, '')
        .replace(/<[^>]*>/g, '\n')
        .replace(/\n\n[\n]*/g, '\n\n');
      const pdf = new jsPDF({ orientation: 'p', unit: 'mm', lineHeight: LINE_HEIGHT });
      let pages = hipaaText.length / PAGE_CHARACTERS;
      pdf.setFontSize(12);
      let wordArray = hipaaText.split(' ');
      let text_page = [];
      let new_text = '';
      for (let i = 0; i < wordArray.length; i++) {
        if (`${new_text} ${wordArray[i]}`.length > PAGE_CHARACTERS) {
          text_page.push(new_text);
          new_text = wordArray[i];
        } else {
          new_text += ` ${wordArray[i]}`;
        }
      }
      if (new_text.length > 0) {
        text_page.push(new_text);
      }
      pdf.text(pdf.splitTextToSize(text_page[0], LINE_WIDTH, LINE_HEIGHT), 10, 10);
      for (let i = 1; i < pages; i++) {
        pdf.addPage();
        pdf.text(pdf.splitTextToSize(text_page[i], LINE_WIDTH, LINE_HEIGHT), 10, 10);
      }
      pdf.addImage(imgData, 'JPEG', x, y);
      // pdf.save(`${fileName}.pdf`);
      const completePdf = pdf.output('blob');
      client.upload(completePdf).then((result) => {
        if (result?.handle) {
          savePDF(result?.handle);
        }
      });
    });
  };

  const clearSignature = () => {
    sigPad.current.clear();
  };

  return (
    <Modal
      handleOpen={showSignatureModal}
      handleClose={hideSignatureModal}
      title="Sign here"
      shouldCloseOnOverlayClick={false}
      footer={
        <>
          <Button transparent onClick={clearSignature}>
            Clear
          </Button>
          <Button
            data-qa="sign-continue-btn"
            success
            disabled={!drawn}
            onClick={() => {
              trimSignature();
              hideSignatureModal();
            }}>
            Sign & Continue
          </Button>
        </>
      }>
      <SignatureCanvas
        penColor="black"
        canvasProps={{ width: 500, height: 200, className: 'SignatureCanvas' }}
        onEnd={() => setDrawn(true)}
        ref={sigPad}
      />
    </Modal>
  );
};

export default SignatureModal;
