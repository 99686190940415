import React from 'react';
import PatientLedger from 'components/practice/BeyondBilling/PatientLedger/PatientLedger';

const Ledger = ({ patient }) => {
  return (
    <div className="mt-0 flex h-full flex-col overflow-hidden bg-white p-0">
      <PatientLedger name="ledger" patient={patient} />
    </div>
  );
};

export default Ledger;
