import React, { useState } from 'react';
import { mString } from '../../../../lib/helpers/utility';
import { Popover } from '@headlessui/react';
import Icon from '../../../shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../../shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from '../../../../api/Api';
import toast from 'react-hot-toast';
import Allowed from 'components/shared/Permissions/Allowed';

const ServiceEntry = ({ service, index, setServiceToBeUpdated, filters, lastIndex }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleDelete = async () => {
    const onSuccess = () => {
      queryClient.setQueryData(
        ['getServices', filters.searchTerm, filters.page, filters.limit],
        (prevData) => {
          return {
            ...prevData,
            services: prevData?.services.filter((ser) => ser.id !== service.id)
          };
        }
      );
      toast.success('Service deleted successfully');
      setShowConfirmationModal(false);
    };
    await requestApi({
      url: '/api/practice/services/delete',
      params: {
        serviceId: service.id
      },
      navigate,
      onSuccess
    });
  };

  return (
    <>
      <tr key={`service-entry-index-${index}-view`}>
        <td>{service.name}</td>
        <td className="!block w-[300px] truncate !leading-[34px]" title={service.description}>
          {service.description}
        </td>
        <td>{service.procedure_code}</td>
        <td>{service.time_length} mins</td>
        <Allowed requiredPermissions="payments.read">
          <td>{mString(service.total_cost_cents)}</td>
        </Allowed>
        <td className="relative">
          <div>
            <Popover className="relative">
              {({ open, close }) => (
                <div>
                  <Popover.Button
                    data-qa={`service-popover-${service.name}`}
                    className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border border-solid border-neutral-300 bg-white shadow-small hover:!bg-primary-700 ${
                      open && '!bg-primary-700 transition-all'
                    }`}>
                    <Icon icon="three-dots" className="flex cursor-pointer" />
                  </Popover.Button>

                  <Popover.Panel
                    className={`absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white ${
                      lastIndex ? '!-top-[90px]' : 'top-10'
                    } !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`}>
                    <div
                      data-qa={`edit-option-${service.name}`}
                      className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        setServiceToBeUpdated(service);
                        close();
                      }}>
                      <Icon icon="new-edit" color="primary" />
                      <div className="ml-1 text-sm text-primary-900">Edit</div>
                    </div>
                    <div
                      data-qa={`delete-option-${service.name}`}
                      className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        setShowConfirmationModal(true);
                        close();
                      }}>
                      <Icon icon="trash" color="primary" />
                      <div className="ml-1 text-sm text-primary-900">Delete</div>
                    </div>
                  </Popover.Panel>
                </div>
              )}
            </Popover>
          </div>
        </td>
      </tr>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={handleDelete}
        title="Delete service"
        message="Are you sure you want to delete this service?"
        variant="danger"
      />
    </>
  );
};

export default ServiceEntry;
