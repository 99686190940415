import React from 'react';
import { formatLargeNumber } from '../../../lib/helpers/utility';

const TableCounter = ({ page, limit, count }) => {
  const startIndex = (page - 1) * limit + 1;
  const endIndex = Math.min(page * limit, count);

  if (!count) return null;
  return (
    <span className="text-primary-900 text-sm font-light leading-7">
      Showing {startIndex}-{endIndex} items out of {formatLargeNumber(count)}
    </span>
  );
};

export default TableCounter;
