import { ia } from 'lib/helpers/utility';

export const canShowEligibilityType = ({ type, data = [] }) => {
  if (!ia(data) || !type) return false;

  for (const item of data) {
    if (Object.prototype.hasOwnProperty.call(item, type) && item[type] > 0) {
      return true;
    }
  }
  return false;
};
