import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getLabOrder } from '../../../../api/LabOrder';

export const useLabOrder = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['labOrder', ...dependencies], () => getLabOrder(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
