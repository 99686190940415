import { requestApi } from './Api';

export const getTreatmentPlan = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/treatment_plan/get',
    params
  });

export const createTreatmentPlan = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/treatment_plan/create',
    params: params,
    throwError: true
  });

export const updateTreatmentPlan = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/treatment_plan/update',
    params: params,
    throwError: true
  });

//   create params
//     (patient_id = null),
//     (icd10 = null),
//     (patient_needs = null),
//     (problem_manifestation_definition = null),
//     (goals = null),
//     (treatment_team = null),
//     (additional_comments = null),
//     (treatment_frequency_date = null);
