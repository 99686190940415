import React from 'react';
import Switch from '../../../../shared/Switch/Switch';

const AdvancedFormsSwitch = ({ cnDisplaySettings, setCNDisplaySettings }) => {
  const switchAllForms = (value) => {
    const updatedSections = {};

    for (const sectionKey in cnDisplaySettings) {
      updatedSections[sectionKey] = {};

      for (const itemKey in cnDisplaySettings[sectionKey]) {
        updatedSections[sectionKey][itemKey] = {
          ...cnDisplaySettings[sectionKey][itemKey],
          advancedForm: value
        };
      }
    }

    setCNDisplaySettings((prevState) => ({
      ...prevState,
      sections: updatedSections
    }));
  };

  const isAllFormsAdvanced = () =>
    Object.keys(cnDisplaySettings).every((key) => {
      return Object.keys(cnDisplaySettings[key]).every((subKey) => {
        if (key === 'notes') return true;
        return cnDisplaySettings[key][subKey]?.advancedForm === true;
      });
    });

  return (
    <div className='hover:bg-primary-50" flex items-center justify-between gap-x-3 py-[6px] !pl-4 pr-[14px] transition-all'>
      <span className="text-sm font-400 text-neutral-600">Advanced Templates</span>
      <Switch
        onChange={(e) => switchAllForms(e)}
        checked={isAllFormsAdvanced()}
        data-qa="advances-templates"
      />
    </div>
  );
};

export default AdvancedFormsSwitch;
