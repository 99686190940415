import React, { useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { getTransactionsCSV } from 'api/Transaction';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues, pm } from 'lib/helpers/utility';
import { useTransactions } from 'lib/hooks/queries/billing/useTransactions';
import { useMember } from 'lib/hooks/queries/member/useMember';

import AGTable from 'components/shared/AGTable/AGTable';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import RawResponsesModal from 'components/shared/Transactions/components/RawResponsesModal';
import state from 'components/state';

const Transactions = ({
  category,
  practiceId = null,
  patientId = null,
  defaultColumnDefs,
  gridOptions,
  defaultFilters,
  isPatient
}) => {
  const navigate = useNavigate();
  const permissions = useRecoilValue(state.permissions);
  const [_URLSearchParams_, setSearchParams] = useSearchParams();

  const { limit, page, setPage, sort, filters, setFilters, colDefs } = useTableContext();

  const { data, isLoading, isFetching } = useTransactions({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category,
      patientId,
      ...(practiceId && { practiceId }),
      ...(isPatient && { withal: { practice: true, cashier: true } })
    },
    dependencies: [limit, page, sort, mapValues(filters), patientId, practiceId && practiceId],
    name: 'transactions'
  });

  const count = data?.count || 0;
  const transactions = data?.transactions;
  const statusPanelData = data?.statusPanelData;
  const totalAmountPaid = statusPanelData?.find((item) => item.field === 'amount_paid_self')?.value;

  const { data: membersData } = useMember({});
  const members = membersData?.members;

  const cashierOptions = useMemo(() => {
    if (!isPatient && members?.length > 0) {
      return members.map((member) => ({
        id: member.user_id,
        label:
          member?.f_name +
          ' ' +
          member?.l_name +
          `${member?.status !== 'active' ? ` ( ${member?.status} )` : ''}`,
        value: member?.id
      }));
    }
    return [];
  }, [isPatient, members]);

  const practices = useMemo(() => {
    if (isPatient && data) {
      const uniquePractices = [];
      for (const t of transactions || []) {
        if (!uniquePractices.some((practice) => practice?.id === t.practice?.id)) {
          uniquePractices.push(t.practice);
        }
      }
      return uniquePractices;
    }
    return [];
  }, [isPatient, data, transactions]);

  const practiceOptions = useMemo(() => {
    if (practices?.length > 0) {
      return practices.map((practice) => ({
        id: practice?.id,
        label: practice?.name,
        value: practice?.id
      }));
    }
    return [];
  }, [practices]);

  if (!isPatient) {
    filters.cashier.options = cashierOptions;
  } else {
    filters.practice.options = practiceOptions;
  }

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: transactions,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: transactions,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [transactions, count, page, limit, onPageChange, statusPanelData]);

  const exportCSV = async () => {
    const data = await getTransactionsCSV(navigate, {
      filters: mapValues(filters),
      colDefs,
      sort,
      category,
      patientId,
      ...(practiceId && { practiceId })
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'transactions.csv';
    a.click();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header>
        <div className="-mt-[10px] mb-1 flex flex-wrap justify-end gap-2 pt-0">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
          />
          <ExportData
            title="Generate Transaction report"
            defaultFilters={defaultFilters}
            filters={filters}
            onGenerateClick={exportCSV}
          />
        </div>
      </Header>
      <div className={cs('flex h-full flex-col overflow-hidden !pb-0')}>
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={25} />
          ) : (
            <AGTable
              key={totalAmountPaid}
              defaultColDef={defaultColumnDefs}
              columnDef={colDefs}
              gridOptions={gridOptions}
              animateRows={true}
              data={transactions}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              isRowSelectable={() => true}
              onRowClicked={(e) => {
                if (pm(permissions, 'is_admin')) {
                  setSearchParams({ txnResponseId: e?.data?.id });
                }
              }}
              customClassName="ag-grid-interactive"
              enableRangeSelection={true}
              masterDetail={false}
              statusBar={statusBar}
              reactiveCustomComponents
            />
          )}
        </div>
      </div>
      <RawResponsesModal transactions={transactions} />
    </ErrorBoundary>
  );
};

export default Transactions;
