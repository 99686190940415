import React from 'react';

import CustomPagination from '../CustomPagination/CustomPagination';

export const CustomStatusBarPagination = ({
  data = [],
  count,
  page,
  limit,
  onPageChange = () => {},
  pageCountOverride
}) => {
  return (
    <div className="flex h-full flex-wrap items-center space-x-4 text-sm bg-red">
      {data?.length > 0 ? (
        <CustomPagination
          onPageChange={onPageChange}
          perPage={limit}
          totalItems={count || 1}
          page={page}
          pageCountOverride={pageCountOverride}
        />
      ) : null}
    </div>
  );
};
