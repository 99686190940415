import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';

import { Honeybadger } from '@honeybadger-io/react';
import { useFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';

import { getStates } from 'api/State';

import { useUIContext } from 'lib/context/UIContext/UIContext';

import Configurations from 'components/Payments/Configurations';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import Consent from './Consent';
import Disclaimer from './Disclaimer';
import Form from './Form';
import Info from './Info';

const PreAuthValidationSchema = Yup.object().shape({
  patientName: Yup.string().required('Field is required'),
  cardholderName: Yup.string().required('Field is required'),
  signature: Yup.string().required('Signature is required'),
  date: Yup.string().required('Date is required'),
  address_ln_1: Yup.string().required('Address is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string().required('Zip code is required')
});

const PreAuthForm = ({
  type = 'annual',
  practiceId,
  practiceName = '',
  patientId,
  patientName = '',
  billingAddress,
  show,
  preAuthFromSelfScheduling = false,
  setShow = () => {},
  onSuccess = () => {},
  showOverrides = {
    cash: false,
    check: false,
    cardOnFile: false,
    careCredit: false,
    cardPresent: false,
    online: true,
    noFooter: false
  },
  hidePreAuthModal,
  edit = true,
  configurationRef,
  hideButton,
  formikRef,
  fromPreAuthModal = false,
  user
}) => {
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState();
  const { device } = useUIContext();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      patientName,
      cardholderName: patientName,
      signature: '',
      date: user?.dob || new Date(),
      type: [
        'co_insurance',
        'co_pay',
        'co_pay_and_insurance',
        'annual',
        'all_paid',
        'insurance_balance',
        'recurring'
      ].includes(type)
        ? type
        : null,
      practiceId,
      patientId,
      cardId: null,
      address_ln_1: billingAddress?.address_ln_1,
      address_ln_2: billingAddress?.address_ln_2,
      city: billingAddress?.city,
      state: billingAddress?.state,
      zip: billingAddress?.zip
    },
    validationSchema: PreAuthValidationSchema,
    validateOnMount: true,
    onSubmit: () => {
      if (_.isEmpty(formik.errors)) {
        setShow({ ...show, startNewTransaction: true });
      }
    }
  });

  const sigPad = useRef();
  const fullFormRef = useRef();

  useEffect(() => {
    getStatesMethods();
  }, []);

  useEffect(() => {
    if (states) {
      handleSelectState(
        {
          value: billingAddress?.state,
          label: states?.find((state) => state.value === billingAddress?.state)?.label
        },
        'state'
      );
    }
  }, [states, billingAddress?.state]);

  useEffect(() => {
    if (formikRef) {
      formikRef.current = formik;
    }
  }, [formik]);

  const trimSignature = () => {
    formik.setFieldValue('signature', sigPad.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const clearSignature = () => {
    formik.setFieldValue('signature', null);
    sigPad.current.clear();
  };

  const handleSelectState = (state, field) => {
    if (field === 'secondary_state') {
      formik.setFieldValue('secondary_state', state?.value);
    } else {
      setSelectedState(state);
      formik.setFieldValue('state', state?.value);
    }
  };

  const getStatesMethods = async () => {
    try {
      const res = await getStates(navigate, { method: 'get', optionify: true });
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div>
      <div ref={fullFormRef} className="relative z-[1]">
        {formik.values.type !== null && <Consent formik={formik} practiceName={practiceName} />}
        <div className="!pt-3">
          <Form
            formik={formik}
            edit={edit}
            selectedState={selectedState}
            states={states}
            handleSelectState={handleSelectState}
          />

          <div className="!pt-6">
            <Info fromPreAuthModal={fromPreAuthModal} />

            {formik.values.signature && !edit ? (
              <div>
                <label className="label mb-1" htmlFor="signature">
                  Cardholder signature
                </label>
                <div className="flex flex-row gap-3">
                  <img src={formik.values.signature} />
                </div>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <div>
                  <label className="label mb-1 pl-0" htmlFor="signature">
                    Cardholder signature
                  </label>
                  <div className="flex flex-col gap-2">
                    <ReactSignatureCanvas
                      onEnd={trimSignature}
                      penColor="#004f6b"
                      canvasProps={{
                        width:
                          device === 'desktop' || device === 'laptop'
                            ? 600
                            : device === 'tablet' || device === 'tablet-sm'
                              ? 490
                              : device === 'mobile'
                                ? 330
                                : 422,
                        height: device === 'tablet' || device === 'tablet-sm' ? 180 : 250,
                        className: 'SignatureCanvas'
                      }}
                      ref={sigPad}
                      data-qa="signature-Input completed={show.completed}"
                    />
                    <Button
                      outlined
                      text="Clear signature"
                      onClick={clearSignature}
                      data-qa="signature-clear"
                    />
                  </div>
                </div>

                {formik.errors.signature && (
                  <span className="!pt-2 text-left text-sm text-danger-500">
                    {formik.errors.signature}
                  </span>
                )}
              </div>
            )}
          </div>
          <Disclaimer />
        </div>
      </div>
      <div className="content-center">
        <Configurations
          amount={0}
          patientId={patientId}
          preAuthForm={{
            patientId: formik.values.patientId,
            practiceId: formik.values.practiceId,
            type: formik.values.type,
            patientName: formik.values.patientName,
            cardholderName: formik.values.cardholderName,
            cardholderSignature: formik.values.signature,
            date: formik.values.date,
            billingAddress: {
              address_ln_1: formik.values.address_ln_1,
              address_ln_2: formik.values.address_ln_2,
              city: formik.values.city,
              state: formik.values.state,
              zip: formik.values.zip
            }
          }}
          onSuccess={(event) => {
            setShow({ ...show, startNewTransaction: false });
            onSuccess(event);
          }}
          onClose={hidePreAuthModal}
          disabled={
            !formik.values.patientName ||
            !formik.values.cardholderName ||
            !formik.values.address_ln_1 ||
            !formik.values.zip ||
            !formik.values.signature
          }
          showOverrides={showOverrides}
          showTransactionOverride={true}
          preAuthFromSelfScheduling={preAuthFromSelfScheduling}
          configurationRef={configurationRef}
          hideButton={hideButton}
        />
      </div>
    </div>
  );
};

export default withErrorBoundary(PreAuthForm);
