import React, { useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import FinancialReportingFilters from './FinancialReportingFilters';
import Header from '../../../shared/Header/Header';

export default function FinancialReporting() {
  const exportRef = useRef();
  const { state } = useLocation();

  return (
    <div className="flex flex-col h-full" ref={exportRef}>
      <Header title="Financial Reporting" subtitle={state} className='z-30'>
        <FinancialReportingFilters exportRef={exportRef} />
      </Header>
      <Outlet />
    </div>
  );
}
