import React, { useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Instructions = ({ formik }) => {
  const instructions = useRef();

  return (
    <Textarea
      label="Instructions"
      name="instructions"
      placeholder="Write instructions here"
      transcribing
      forwardedRef={instructions}
      value={formik?.values?.instructions}
      onChange={formik?.handleChange}
    />
  );
};

export default Instructions;
