import { requestApi } from './Api';

export const getPatientGuarantors = async (navigate, params, signal) => {
  return requestApi({
    navigate,
    url: '/api/patient_guarantor/get',
    params,
    signal
  });
};

export const createGuarantor = async (params, navigate) => {
  return requestApi({
    navigate,
    url: '/api/patient_guarantor/create',
    params
  });
};
export const updateGuarantor = async (params, navigate) => {
  return requestApi({
    navigate,
    url: '/api/patient_guarantor/update',
    params,
    onError: () => {}
  });
};
export const deleteGuarantor = async (params, navigate) => {
  return requestApi({
    navigate,
    url: '/api/patient_guarantor/delete',
    params
  });
};
