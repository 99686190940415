import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { formatDateAndTime } from 'lib/helpers/utility';
import React, { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import Icon from 'components/shared/Icon/Icon';

const DetailCellRenderer = ({ data, node, api, context, breadcrumb, setPreviewData }) => {
  const rowId = node.id;
  const type = data?.type;
  const { gridApi } = useTableContext();
  const detailGridData = data.detailGridData;
  const { selectedDetailGridData = [] } = context || {};
  const [finalSelectedDetailGridData, setFinalSelectedDetailGridData] =
    useState(selectedDetailGridData);

  useEffect(() => {
    setFinalSelectedDetailGridData(selectedDetailGridData);
  }, [selectedDetailGridData]);

  useEffect(() => {
    return () => {
      if (!api.isDestroyed()) {
        api.removeDetailGridInfo(rowId);
      }
    };
  }, []);

  useEffect(() => {
    api.addDetailGridInfo(rowId, detailGridData);
  }, []);

  const handleChange = (event, item) => {
    const checked = event.target.checked;
    let newSelectedDetailGridData = checked
      ? [...finalSelectedDetailGridData, item]
      : finalSelectedDetailGridData.filter((i) => i?.id !== item?.id);

    setFinalSelectedDetailGridData(newSelectedDetailGridData);

    gridApi.setGridOption('context', {
      selectedDetailGridData: newSelectedDetailGridData
    });

    const parentNode = gridApi.getRowNode(node.parent.id);
    const allSelected = detailGridData.length === newSelectedDetailGridData.length;
    if (allSelected) {
      parentNode?.setSelected(allSelected);
    }

    if (!newSelectedDetailGridData?.length) {
      parentNode?.setSelected(false);
    }
  };

  const isRowSelected = (itemId) => {
    return finalSelectedDetailGridData.find((item) => item.id === itemId);
  };

  const onPreviewClick = (item) => {
    breadcrumb.push({
      title: 'Preview',
      name: 'preview'
    });
    setPreviewData({ type, data: item });
  };

  return (
    <div className="mb-2 border-2 border-b-2 border-l-4 border-t-0 border-solid border-primary-400  bg-gradient-to-r from-[#F5FCFF] to-[#13B9FF49] pl-6 pr-3">
      {detailGridData?.map((item, idx) => (
        <div key={idx} className="flex items-center justify-between p-2 px-3.5">
          <div className="flex items-center font-bold">
            <Checkbox
              labelClassName="font-normal text-sm"
              size={16}
              name="no_email"
              data-qa="no-email"
              checked={isRowSelected(item.id)}
              onChange={(e) => handleChange(e, item)}
              label={formatDateAndTime(item?.created_at)}
            />
          </div>
          <div>
            <Icon icon="new-preview-search" onClick={() => onPreviewClick(item)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default memo(DetailCellRenderer);
