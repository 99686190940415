import NewTask from 'components/practice/PracticeIQueue/Tasks/components/NewTask';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import List from './List';

const Tasks = (props) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const item = { name: 'My tasks' };

  return (
    <div className="flex h-full flex-col">
      <WidgetHeader
        icon="new-task"
        item={item}
        iconColor="primary"
        hasPlus={true}
        onPlusClick={() => {
          setShowModal(true);
        }}
        {...props}
      />
      <List />

      <FooterWrapper onClick={() => navigate('practice-i-queue/tasks')} text="All tasks" />
      {showModal && (
        <NewTask newTaskModal={showModal} hideNewTaskModal={() => setShowModal(false)} />
      )}
    </div>
  );
};
export default Tasks;
