import { fabric } from 'fabric';

const { pdfjs } = require('react-pdf');

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const useEditorRenderer = ({
  fabricCanvasRef,
  setScale,
  setTotalPages = () => {},
  canvasStates,
  setIsPdfLoaded,
  pdf,
  currentPage,
  fileType,
  pushToHistory,
  historyState,
  setIsLoading = () => {}
}) => {
  const canvasWidth = 700;

  const renderImage = async (url) => {
    try {
      const fabricCanvas = fabricCanvasRef?.current;
      if (!fabricCanvas) return;

      // Create a promise to handle image loading
      const loadImage = () => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.crossOrigin = 'anonymous';
          img.onload = () => resolve(img);
          img.onerror = reject;
          img.src = url;
        });
      };

      // Wait for image to load before proceeding
      const img = await loadImage();

      // Now we can safely access img.width and img.height
      const newScale = canvasWidth / img.width;

      fabricCanvas.setWidth(img.width * newScale);
      fabricCanvas.setHeight(img.height * newScale);
      setScale(newScale);

      // Create a promise to handle fabric image loading and rendering
      return new Promise((resolve) => {
        fabric.Image.fromURL(url, (fabricImg) => {
          fabricImg.scale(newScale);
          if (fabricImg)
            fabricCanvas?.setBackgroundImage(fabricImg, () => {
              fabricCanvas?.renderAll();

              if (historyState && historyState.index < 0) {
                pushToHistory(fabricCanvas);
              }
              setIsPdfLoaded(true);
              resolve(); // Resolve the promise after background image is loaded and rendered
            });
        });
        setTotalPages(1);
      });

      // if (canvasStates[1]) {
      //   fabricCanvas.loadFromJSON(canvasStates[1], fabricCanvas.renderAll.bind(fabricCanvas));
      // } else {
      //   fabricCanvas.clear();
      // }
    } catch (error) {
      setIsPdfLoaded(true);
      console.error('Error rendering image:', error);
    }
  };

  // Existing: function to render PDF pages
  const renderPdf = async (url, pageNum) => {
    try {
      const page = await pdf?.current?.getPage(pageNum);
      const newScale = canvasWidth / page?.getViewport({ scale: 1 })?.width;
      const viewport = page?.getViewport({ scale: newScale });
      setScale(newScale);

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport?.height;
      canvas.width = viewport?.width;

      const renderContext = {
        canvasContext: context,
        viewport
      };

      await page?.render(renderContext).promise;

      const fabricCanvas = fabricCanvasRef.current;
      if (fabricCanvas) {
        fabricCanvas.setWidth(viewport?.width);
        fabricCanvas.setHeight(viewport?.height);

        // Create a promise to handle background image loading
        return new Promise((resolve) => {
          fabricCanvas.setBackgroundImage(canvas.toDataURL(), () => {
            fabricCanvas.renderAll();

            if (canvasStates[pageNum]) {
              fabricCanvas.loadFromJSON(canvasStates[pageNum], () => {
                fabricCanvas.renderAll();

                if (historyState && historyState.index < 0) {
                  pushToHistory(fabricCanvas);
                }

                setIsPdfLoaded(true);
                resolve(); // Resolve the promise after everything is loaded and rendered
              });
            } else {
              fabricCanvas.clear();
              setIsPdfLoaded(true);
              resolve(); // Resolve if no canvas states to load
            }
          });
        });
      }
    } catch (error) {
      console.error('Error rendering PDF:', error);
      setIsPdfLoaded(true); // Set loaded even on error to prevent infinite loading
    }
  };

  const loadFile = async (src) => {
    setIsLoading(true);
    try {
      if (fileType === 'pdf') {
        pdf.current = await pdfjs.getDocument(src).promise;
        setTotalPages(pdf.current.numPages);
        await renderPdf(src, currentPage || 1);
      } else if (fileType === 'image') {
        await renderImage(src);
      }
    } catch (error) {
      console.error('Error loading file:', error);
    } finally {
      setIsLoading(false); // Set loading to false after all operations are complete
    }
  };

  return { renderPdf, renderImage, loadFile };
};

export default useEditorRenderer;
