import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';

import { deleteGuarantor as deleteGuarantorReq } from 'api/PatientGuarantor';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import GuarantorContent from './components/GuarantorContent';

export default function Guarantor({
  guarantor,
  index,
  deleteGuarantor,
  selected,
  setSelected,
  handleEditGuarantor
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const navigate = useNavigate();

  const { mutate: deleteGuarantorFn, isLoading: loading } = useCustomMutation({
    mutationFn: () =>
      deleteGuarantorReq(
        {
          id: guarantor.id
        },
        navigate
      ),
    successMessage: 'Successfully deleted guarantor.',
    onSuccess: (data) => {
      if (data.deleted) {
        deleteGuarantor(guarantor);
      }
      setShowConfirmationModal(false);
    }
  });

  const sameId = selected && selected?.id === guarantor?.id;

  return (
    <>
      <div
        key={guarantor.id}
        className={cs(
          'relative flex cursor-pointer flex-col gap-4 !rounded-lg border-solid !p-4 hover:!border-primary-100 hover:!bg-primary-50',
          !sameId
            ? 'border !border-transparent bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.04)]'
            : 'border-2 !border-primary-100 bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)]'
        )}
        onClick={() => setSelected && setSelected(guarantor)}>
        <div className="flex flex-wrap items-center justify-between gap-2">
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-guarantee-lg" size={18} />
            <span className="text-sm font-500 text-neutral-800">{`Guarantor ${index + 1}`}</span>
          </div>
          <div className="flex flex-wrap items-center gap-2">
            <Icon
              icon="new-edit"
              data-qa="edit-btn-guarantor"
              onClick={() => handleEditGuarantor(guarantor, index)}
              color="neutral"
            />
            <Icon
              icon="trash"
              data-qa="delete-btn-guarantor"
              onClick={() => setShowConfirmationModal(true)}
            />
          </div>
        </div>
        <GuarantorContent guarantor={guarantor} />
      </div>

      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={() => deleteGuarantorFn()}
        title="Delete Guarantor"
        loading={loading}
        message="Are you sure you want to delete this guarantor?"
        variant="danger"
      />
    </>
  );
}
