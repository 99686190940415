export default function describeUrl(url) {
  const patterns = [
    {
      check: (url) => url.includes('/api/practitioner/'),
      actions: [
        { pattern: 'get_multiple', message: 'Retrieved information about multiple practitioners.' },
        {
          pattern: 'license/upsert',
          message: "Updated or inserted a practitioner's license information."
        },
        { pattern: 'get', message: 'Retrieved practitioner information.' },
        {
          pattern: 'license/issuer/load',
          message: "Loaded a practitioner's license issuer details."
        },
        { pattern: 'license/load', message: 'Loaded practitioner license details.' }
      ],
      default: 'Performed a practitioner-related action.'
    },
    {
      check: (url) => url.includes('/api/appointment/'),
      actions: [
        { pattern: 'get_one', message: 'Retrieved information about an appointment.' },
        { pattern: 'get_multiple', message: 'Retrieved information about multiple appointments.' },
        { pattern: 'create', message: 'Created a new appointment.' },
        { pattern: 'invoices/get', message: 'Retrieved invoices for appointments.' },
        { pattern: 'update', message: 'Updated an appointment.' }
      ],
      default: 'Performed an appointment-related action.'
    },
    {
      check: (url) => url.includes('/api/patient/'),
      actions: [
        { pattern: 'checkin/start', message: 'Started the check-in process for a patient.' },
        {
          pattern: 'checkin/get_hipaa_form',
          message: 'Retrieved the HIPAA form for patient check-in.'
        },
        { pattern: 'checkin/payers', message: 'Retrieved payer information for patient check-in.' },
        { pattern: 'checkin/status', message: 'Checked the check-in status for a patient.' },
        { pattern: 'get/multiple', message: 'Retrieved information about multiple patients.' },
        { pattern: 'get/one', message: 'Retrieved information about a patient.' },
        { pattern: 'transaction/balance', message: "Retrieved a patient's transaction balance." },
        { pattern: 'appointment/read', message: 'Retrieved appointment details for a patient.' },
        { pattern: 'add_patient', message: 'Added a new patient.' }
      ],
      default: 'Performed a patient-related action.'
    },
    {
      check: (url) => url.includes('/api/practice/'),
      actions: [
        {
          pattern: 'billing/reporting/grand_total',
          message: 'Retrieved grand total billing report.'
        },
        {
          pattern: 'billing/reporting/insurance_claims',
          message: 'Retrieved insurance claims billing report.'
        },
        {
          pattern: 'billing/reporting/dynamic_ar',
          message: 'Accessed dynamic accounts receivable billing report.'
        },
        { pattern: 'billing/claim/get_claim', message: 'Retrieved a billing claim.' },
        { pattern: 'billing/claim/get_claims', message: 'Retrieved multiple billing claims.' },
        {
          pattern: 'billing/claim/unmatched_claims',
          message: 'Retrieved unmatched billing claims.'
        },
        { pattern: 'billing/era/get_era', message: 'Retrieved ERA billing information.' },
        { pattern: 'billing/era/get_eras', message: 'Retrieved multiple ERA billing information.' },
        { pattern: 'billing/get_payments', message: 'Retrieved billing payments.' },
        { pattern: 'settings/get', message: 'Accessed practice settings.' },
        {
          pattern: 'settings/forms/form_packet/get',
          message: 'Retrieved form packets from practice settings.'
        },
        {
          pattern: 'settings/forms/form_packet/selected',
          message: 'Retrieved selected form packets from practice settings.'
        },
        { pattern: 'settings/payers/get', message: 'Retrieved payer settings for the practice.' },
        { pattern: 'settings/practice_user/update', message: 'Updated practice user settings.' },
        { pattern: 'charts/appointments', message: 'Accessed chart appointments.' },
        { pattern: 'charts/get', message: 'Retrieved practice chart details.' },
        { pattern: 'charts/count', message: 'Counted practice charts.' },
        { pattern: 'messages/get_multiple', message: 'Retrieved multiple practice messages.' },
        { pattern: 'messages/get', message: 'Retrieved practice messages.' },
        { pattern: 'messages/update', message: 'Updated practice messages.' },
        { pattern: 'current_practice', message: 'Retrieved current practice details.' },
        { pattern: 'medical_history/get', message: 'Retrieved medical history for the practice.' },
        { pattern: 'patient/sticky_notes/read', message: 'Read patient sticky notes.' },
        { pattern: 'dashboard/claims', message: 'Accessed claims dashboard.' },
        {
          pattern: 'dashboard/outstanding_payments',
          message: 'Accessed outstanding payments on the dashboard.'
        },
        { pattern: 'dashboard/sms', message: 'Accessed SMS dashboard.' },
        { pattern: 'services/get', message: 'Retrieved services offered by the practice.' },
        {
          pattern: 'member/get_practitioners',
          message: 'Retrieved practitioners in the practice.'
        },
        { pattern: 'member/get', message: 'Retrieved practice member information.' },
        { pattern: 'member/invite', message: 'Sent an invite to a practice member.' },
        { pattern: 'member/get_v2', message: 'Retrieved practice member information (version 2).' },
        { pattern: 'role/get', message: 'Retrieved roles within the practice.' },
        { pattern: 'update', message: 'Updated practice information.' }
      ],
      default: 'Performed a practice-related action.'
    },
    {
      check: (url) => url.includes('/api/fax/'),
      actions: [
        { pattern: 'get/archived', message: 'Retrieved archived faxes.' },
        { pattern: 'get/fax_user', message: 'Retrieved user-specific fax information.' },
        { pattern: 'get/outbound', message: 'Retrieved outbound faxes.' },
        { pattern: 'get/drafts', message: 'Retrieved fax drafts.' },
        { pattern: 'get/inbound', message: 'Retrieved inbound faxes.' }
      ],
      default: 'Managed fax documents.'
    },
    {
      check: (url) => url.includes('/api/user/'),
      actions: [
        { pattern: 'preferences/get', message: 'Retrieved user preferences.' },
        { pattern: 'get_socket_token', message: 'Retrieved a socket token for the user.' },
        { pattern: 'boards/get', message: 'Retrieved user boards information.' },
        { pattern: 'logout', message: 'Logged out the user.' },
        { pattern: 'check_token', message: "Checked the user's token for validity." },
        { pattern: 'update', message: 'Updated user information.' },
        { pattern: 'login', message: 'Logged in the user.' }
      ],
      default: 'Performed a user-related action.'
    },
    {
      check: (url) => url.includes('/api/widgets/'),
      actions: [
        { pattern: 'overview/medications', message: 'Retrieved an overview of medications.' },
        { pattern: 'overview/appointments', message: 'Retrieved an overview of appointments.' },
        { pattern: 'overview/vitals', message: 'Retrieved an overview of vitals.' },
        { pattern: 'overview/clinical_notes', message: 'Retrieved an overview of clinical notes.' },
        { pattern: 'overview/ledger', message: 'Retrieved financial ledger overview.' },
        { pattern: 'dashboard', message: 'Accessed dashboard widgets.' }
      ],
      default: 'Performed a widget-related action.'
    },
    {
      check: (url) => url.includes('/api/admin/'),
      actions: [
        {
          pattern: 'practice/billing/upsert',
          message: 'Updated or inserted practice billing information.'
        },
        { pattern: 'practice/billing/read', message: 'Read practice billing information.' },
        { pattern: 'codes/get', message: 'Retrieved administrative codes.' },
        { pattern: 'update', message: 'Updated administrative details.' },
        { pattern: 'get', message: 'Retrieved administrative details.' },
        { pattern: 'users/list', message: 'Listed administrative users.' },
        { pattern: 'practice/facility/read', message: 'Read practice facility information.' },
        {
          pattern: 'practice/impersonate',
          message: 'Impersonated a practice for administrative purposes.'
        },
        {
          pattern: 'dashboard/volume',
          message: 'Accessed administrative dashboard volume statistics.'
        },
        { pattern: 'dashboard', message: 'Accessed administrative dashboard.' },
        { pattern: 'search', message: 'Performed an administrative search.' },
        { pattern: 'logout', message: 'Logged out an admin user.' },
        { pattern: 'login', message: 'Logged in an admin user.' },
        { pattern: 'claimmd/get', message: 'Retrieved ClaimMD information.' },
        { pattern: 'databank/create', message: 'Created a databank entry.' },
        { pattern: 'databank/get', message: 'Retrieved databank information.' },
        { pattern: 'logs/list', message: 'Listed administrative logs.' }
      ],
      default: 'Performed an administrative action.'
    },
    {
      check: (url) => url.includes('/api/'),
      actions: [
        { pattern: 'requests/count', message: 'Counted requests.' },
        { pattern: 'package/get_by_user', message: 'Retrieved packages by user.' },
        {
          pattern: 'practice_owner/set_default_practice',
          message: 'Set the default practice for the owner.'
        },
        {
          pattern: 'practice_owner/get_practices',
          message: 'Retrieved practices owned by the user.'
        },
        { pattern: 'codes/get', message: 'Retrieved codes.' },
        { pattern: 'codes/place_of_service/get', message: 'Retrieved place of service codes.' },
        { pattern: 'codes/benefit_type_code/get', message: 'Retrieved benefit type codes.' },
        { pattern: 'referring_provider/get', message: 'Retrieved referring provider information.' },
        { pattern: 'messages/send', message: 'Sent a message.' },
        { pattern: 'calendar_event/create', message: 'Created a calendar event.' },
        { pattern: 'transactions/group/get', message: 'Retrieved transaction groups.' },
        { pattern: 'transactions/terminal/list', message: 'Listed transaction terminals.' },
        {
          pattern: 'transactions/terminal/get',
          message: 'Retrieved transaction terminal information.'
        },
        {
          pattern: 'transactions/terminal/update',
          message: 'Updated transaction terminal information.'
        },
        { pattern: 'transactions/cash/add', message: 'Added a cash transaction.' },
        { pattern: 'transactions/list', message: 'Listed transactions.' },
        { pattern: 'transactions/token/list', message: 'Listed transaction tokens.' },
        { pattern: 'product/get', message: 'Retrieved product information.' },
        { pattern: 'insurance_profiles/get', message: 'Retrieved insurance profiles.' },
        { pattern: 'occupation/get', message: 'Retrieved occupation information.' },
        { pattern: 'permissions/get', message: 'Retrieved permissions.' },
        { pattern: 'clinical_note/read', message: 'Read a clinical note.' },
        { pattern: 'clinical_note/read_multiple', message: 'Read multiple clinical notes.' },
        { pattern: 'hsp/get', message: 'Retrieved HSP information.' },
        { pattern: 'filters/get', message: 'Retrieved filter configurations.' },
        { pattern: 'form/get', message: 'Retrieved a form.' },
        { pattern: 'landmarks/get', message: 'Retrieved landmarks.' },
        { pattern: 'calendar/availability', message: 'Checked calendar availability.' },
        { pattern: 'get_states', message: 'Retrieved state listings.' },
        { pattern: 'custom_form_response/read', message: 'Read custom form responses.' },
        { pattern: 'comms_contacts/get', message: 'Retrieved communication contacts.' },
        { pattern: 'prescribe/enrollUser', message: 'Enrolled a user for prescribing.' },
        { pattern: 'notifications/get', message: 'Retrieved notifications.' },
        { pattern: 'send_grid/view_email', message: 'Viewed an email through SendGrid.' },
        { pattern: 'appointment/count', message: 'Counted appointments.' }
      ],
      default: 'Performed an unspecified action.'
    },
    {
      check: (url) => url.includes('/public/'),
      actions: [
        { pattern: 'typography.css', message: 'Accessed typography CSS.' },
        { pattern: 'index.css', message: 'Accessed index CSS.' },
        { pattern: 'styles/react-big-calendar.css', message: 'Accessed React Big Calendar styles.' }
      ],
      default: 'Accessed public resources.'
    },
    {
      check: (url) => url.includes('/api/filestack/'),
      actions: [{ pattern: 'profile_photo', message: 'Managed profile photo via Filestack.' }],
      default: 'Performed a Filestack-related action.'
    }
  ];

  for (const { check, actions, default: defaultMsg } of patterns) {
    if (check(url)) {
      for (const { pattern, message } of actions) {
        if (url.includes(pattern)) return message;
      }
      return defaultMsg;
    }
  }

  return 'Performed an action that could not be specifically categorized.';
}
