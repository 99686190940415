import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import moment from 'moment-timezone';
import { useRecoilState, useRecoilValue } from 'recoil';

import { updateTreatmentPlan } from 'api/TreatmentPlan';

import { showAlert } from 'components/shared/Alert/Alert';

import { updateDocumentSign } from '../../../../../api/DocumentSign';
import { interimApi } from '../../../../../api/InterimApi';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';
import { clinicalNote as clinicalNoteState, userState as user_state } from '../../../../state';
import { titleToPosition } from '../PreviewNote/lib/selectedHelper';
import TPModal from '../TreatmentPlan/TPModal';
import { treatmentPlanLabels } from '../lib/constants';

import SignLockActionComponent from './SignLockActionComponent';

const noteTypes = ['intake', 'progress', 'soap'];

const ConfirmSignLock = ({ documentSignatures = [], hasUserSigned = false }) => {
  const {
    confirmSignLock,
    showConfirmSignLock,
    advancedHPRef,
    advancedSOAPRef,
    advancedFormRef,
    vitalsRef,
    setSelectedTreatmentPlan,
    selected,
    loadedTP,
    setLoadedTP,
    setSaveButtonText,
    updateSaveButton = () => {}
  } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const { hpNoteOverviewRefs } = useOutletContext();
  const queryClient = useQueryClient();
  const userState = useRecoilValue(user_state);
  const { id, appointmentId, type } = useParams();
  const params = { patientId: id };
  const { data: { data: treatmentPlan } = {} } = useTreatmentPlan(params);
  const navigate = useNavigate();
  const [availableDates, setAvailableDates] = useState([]);
  const [loadView, setLoadView] = useState(null);
  const [TPOrigin, setTPOrigin] = useState(null);
  const [showTPModal, setShowTPModal] = useState(false);
  const [confirmCreateTPlan, setConfirmCreateTPlan] = useState(false);
  const [confirmUpdateTPlan, setConfirmUpdateTPlan] = useState(false);

  useEffect(() => {
    if (type === 'intake') {
      handleMHA();
    } else if (type === 'progress') {
      handleEPN();
    } else if (type === 'soap') {
      handleSOAP();
    } else {
      resetLoadView();
    }
  }, [type, treatmentPlan?.length]);

  useEffect(() => {
    if (clinicalNote?.treatment_plan_id && ia(treatmentPlan)) {
      const tp = treatmentPlan?.find((tp) => tp.id === clinicalNote?.treatment_plan_id);
      if (tp) setLoadedTP(tp);
    }
  }, [clinicalNote?.treatment_plan_id, treatmentPlan?.length]);

  const handleMHA = () => {
    setLoadView('create');
    setTPOrigin('intake');
  };

  const handleEPN = () => {
    if (!treatmentPlan) return;
    if (treatmentPlan.length === 0) {
      resetLoadView();
    } else if (treatmentPlan.length === 1) {
      setLoadView('edit');
      setTPOrigin('progress');
    }
    const newAvailableDates = treatmentPlan?.filter((item) => filterAvailableDates(item));
    setAvailableDates(newAvailableDates);
    if (newAvailableDates.length > 1) {
      setLoadView('list');
    } else {
      setLoadView('edit');
    }
    setTPOrigin('progress');
  };

  const handleSOAP = () => {
    if (!treatmentPlan) return;
    if (treatmentPlan.length === 0) resetLoadView();

    const newAvailableDates = treatmentPlan?.filter((item) => filterAvailableDates(item));
    setAvailableDates(newAvailableDates);
    if (newAvailableDates.length > 1) setLoadView('update');
    setTPOrigin('soap');
  };

  const filterAvailableDates = (item) => {
    if (item.treatment_frequency_date?.endDate) {
      const endDateMoment = moment(item.treatment_frequency_date.endDate, 'MM/DD/YYYY');
      const currentDate = moment();
      return (
        !endDateMoment.isBefore(currentDate, 'day') && !endDateMoment.isSame(currentDate, 'day')
      );
    }
    return false;
  };

  const resetLoadView = () => {
    setLoadView(null);
    setTPOrigin(null);
  };

  const closeTPModal = () => {
    setSelectedTreatmentPlan({});
    setShowTPModal(false);
  };

  const onLockNote = async ({ lockStatus = false, componentsOrder = selected } = {}) => {
    try {
      setSaveButtonText('Saving');
      if (io(componentsOrder)) {
        componentsOrder = titleToPosition(componentsOrder);
      }

      showConfirmSignLock(false);
      Object.values(hpNoteOverviewRefs).forEach((el) => el?.current?.click());
      advancedHPRef?.current?.click();
      advancedSOAPRef?.current?.click();
      advancedFormRef?.current?.click();
      vitalsRef?.current?.saveVitals();

      const newLockState = lockStatus;
      const newState = newLockState ? 'finalized' : 'draft';
      const lockHistory = clinicalNote.lock_history ? [...clinicalNote.lock_history] : [];
      lockHistory.push({ lock: newLockState, timestamp: new Date(), user_id: userState.id });
      const user_id = clinicalNote?.appointment?.user_id || id;
      const appointment_id = clinicalNote?.appointment_id || appointmentId;

      const params = {
        clinicalNote: {
          ...clinicalNote,
          locked: newLockState,
          state: newState,
          lock_history: lockHistory,
          user_id,
          appointment_id,
          ...(newLockState && { components_order: componentsOrder })
        }
      };

      const res = await interimApi('/api/clinical_note/upsert', params, navigate);
      const { clinicalNoteId, error } = res.data;

      setClinicalNote({
        ...clinicalNote,
        locked: newLockState,
        state: newState,
        lock_history: lockHistory,
        user_id,
        appointment_id
      });

      if (!clinicalNoteId) {
        Honeybadger.notify(`Clinical note lock error, error: ${error}`);
        return;
      }

      queryClient.invalidateQueries(['clinicalNotes']);
      queryClient.resetQueries(['claim']);
      queryClient.resetQueries(['appointment']);

      const clinicalNoteAuthor =
        clinicalNote?.practitioner_id == (userState.id || userState.user_id);

      if (newLockState && !noteTypes.includes(TPOrigin)) {
        navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
      }

      if (clinicalNoteAuthor && !hasUserSigned && TPOrigin === 'intake') {
        setConfirmCreateTPlan(true);
      }

      const activeTP = treatmentPlan?.find((tp) => tp.id === clinicalNote?.treatment_plan_id);
      if (
        clinicalNoteAuthor &&
        !hasUserSigned &&
        (TPOrigin === 'progress' || TPOrigin === 'soap') &&
        clinicalNote?.treatment_plan_id &&
        activeTP
      ) {
        const tpValues = {
          icd10: activeTP?.icd10,
          patient_needs: activeTP?.patient_needs,
          problem_manifestation_definition: activeTP?.problem_manifestation_definition,
          goals: activeTP?.goals
        };
        const cnValues = {
          icd10: clinicalNote?.icd_10_codes,
          patient_needs: clinicalNote?.present_illness,
          problem_manifestation_definition: clinicalNote?.other_complaints,
          goals: clinicalNote?.goals
        };

        const hasChanges = !isEqual(tpValues, cnValues);
        if (hasChanges) setConfirmUpdateTPlan(true);
        else navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
      }

      if (lockStatus) {
        navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      updateSaveButton();
    }
  };

  const updateDocumentSignMutation = useMutation((data) => updateDocumentSign(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documentSign']);
    }
  });

  const { mutate: mutateTP } = useMutation(
    ({ id, data }) => {
      updateTreatmentPlan(navigate, {
        treatmentId: id,
        objToBeUpdated: data
      });
    },
    {
      onSuccess: () => {
        showAlert({
          title: 'Treatment plan updated successfully!'
        });
        queryClient.invalidateQueries('treatmentPlan');
        navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
      }
    }
  );

  const updateTP = (TP) => {
    const data = {
      ...TP,
      icd10: clinicalNote?.icd_10_codes,
      patient_needs: clinicalNote?.present_illness,
      problem_manifestation_definition: clinicalNote?.other_complaints,
      goals: clinicalNote?.goals
    };

    mutateTP({ id: TP.id, data });
  };

  if (confirmSignLock) {
    return (
      <SignLockActionComponent
        documentSignatures={documentSignatures}
        clinicalNote={clinicalNote}
        onLockNote={onLockNote}
        userState={userState}
        confirmSignLock={confirmSignLock}
        showConfirmSignLock={showConfirmSignLock}
        hasUserSigned={hasUserSigned}
        updateDocumentSignMutation={updateDocumentSignMutation}
      />
    );
  }

  if (confirmCreateTPlan)
    return (
      <Confirm
        primaryBtnTxt={treatmentPlanLabels.create}
        secondaryBtnTxt="No, I don't"
        title="Do you want to create a treatment plan?"
        message="Are you sure you want to create treatment plan from clinical note?"
        icon="new-task1"
        iconSize="24px"
        handleContinue={() => {
          setConfirmCreateTPlan(false);
          setShowTPModal(true);
        }}
        handleOpen={confirmCreateTPlan}
        handleClose={() => {
          setConfirmCreateTPlan(false);
          navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
        }}
      />
    );

  if (confirmUpdateTPlan)
    return (
      <Confirm
        primaryBtnTxt="Update treatment plan"
        secondaryBtnTxt="No, I don't"
        title="Do you want to update the treatment plan?"
        message="New progress notes have been added today. Would you like to include them in your treatment plan?"
        icon="new-task1"
        iconSize="24px"
        handleContinue={() => {
          setConfirmUpdateTPlan(false);
          if (loadedTP) updateTP(loadedTP);
          else setShowTPModal(true);
        }}
        handleOpen={confirmUpdateTPlan}
        handleClose={() => {
          setConfirmUpdateTPlan(false);
          navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
        }}
      />
    );

  if (showTPModal)
    return (
      <TPModal
        isOpen={showTPModal}
        handleClose={closeTPModal}
        loadView={loadView}
        origin={TPOrigin}
        availableDates={availableDates}
      />
    );
};

export default ConfirmSignLock;
