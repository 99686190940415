import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Icon from '../../../../shared/Icon/Icon';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import useOutsideClick from '../../../../../lib/hooks/useOutsideClick';
import { useRef } from 'react';
import Button from '../../../../shared/Button/Button';
import { useRecoilState } from 'recoil';
import { communicationFilter } from '../../../practiceState';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function TypeFilter({ name, type = 'messageType', filterOptions = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const [communicationFilters, setCommunicationFilters] = useRecoilState(communicationFilter);
  const ref = useRef();
  const location = useLocation();

  useOutsideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  const isSelected = (value) => {
    return communicationFilters[type]?.find((el) => el.value === value) ? true : false;
  };

  const handleSelect = (value) => {
    if (!isSelected(value)) {
      const selectedTypesUpdated = [
        ...communicationFilters[type],
        filterOptions.find((el) => el.value === value)
      ];
      setCommunicationFilters({ ...communicationFilters, [type]: selectedTypesUpdated });
    } else {
      handleDeselect(value);
    }
    setIsOpen(true);
  };

  const handleSelectAll = () => {
    if (communicationFilters[type].length === filterOptions.length) {
      setCommunicationFilters({ ...communicationFilters, [type]: [] });
    } else {
      setCommunicationFilters({ ...communicationFilters, [type]: filterOptions });
    }
  };

  const handleDeselect = (value) => {
    const selectedTypesUpdated = communicationFilters[type].filter((el) => el.value !== value);
    setCommunicationFilters({ ...communicationFilters, [type]: selectedTypesUpdated });
    setIsOpen(true);
  };

  const applyFilters = () => {
    if (type === 'messageType') {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        messageApply: !prevState.messageApply
      }));
    } else if (type === 'logType') {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        logApply: !prevState.logApply
      }));
    }
    setIsOpen(false);
  };

  const clearFilters = () => {
    if (type === 'messageType') {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        messageApply: !prevState.messageApply,
        [type]: []
      }));
    } else if (type === 'logType') {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        logApply: !prevState.logApply,
        [type]: []
      }));
    }
  };

  return (
    <div ref={ref} className="relative self-center">
      <Listbox>
        <>
          <Listbox.Button
            onClick={() => setIsOpen(!isOpen)}
            open={isOpen}
            className="w-full cursor-default rounded-lg text-sm">
            <span
              className={`text-blue border-b-3 flex items-center gap-1 ${
                isOpen && `border-x-0 border-t-0 border-solid border-blue-400`
              } `}>
              <Icon icon="new-filter" />
              <span className="text-sm text-neutral-600">{name}:</span>
              <span className="font-900 min-w-[75px] text-sm text-primary-900">
                {communicationFilters[type]?.length
                  ? `${communicationFilters[type]?.length} Selected`
                  : type === 'messageType'
                  ? 'All Messages'
                  : 'All Types'}
              </span>
              {isOpen ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
            </span>
          </Listbox.Button>
          {isOpen && (
            <Transition
              unmount={false}
              show={isOpen}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Listbox.Options className="absolute z-10 mt-1 min-w-max rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <div className="!p-5">
                  <div className="cursor-default !p-2 transition-all hover:rounded-lg hover:bg-primary-50">
                    <Checkbox
                      id="select-all"
                      label={`${
                        communicationFilters[type]?.length === filterOptions.length
                          ? 'Unselect all'
                          : 'Select all'
                      }`}
                      color="blue"
                      isChecked={communicationFilters[type]?.length === filterOptions.length}
                      handleClick={() => handleSelectAll()}
                    />
                  </div>
                  <hr className="!mt-2 !mb-2" />
                  {filterOptions.map((filter, sortIdx) => {
                    const selected = isSelected(filter.value);
                    return (
                      <Listbox.Option
                        key={sortIdx}
                        className={`relative cursor-default select-none rounded-lg !p-2 transition-all hover:bg-primary-50`}
                        value={filter}>
                        <div className="cursor-pointer">
                          <Checkbox
                            label={filter.label}
                            value={filter.value}
                            isChecked={selected}
                            color="blue"
                            handleClick={(event) => handleSelect(event.target.value)}
                          />
                        </div>
                      </Listbox.Option>
                    );
                  })}
                  <hr className="!mt-2 !mb-2" />
                  <div className="flex justify-between gap-8">
                    <Button gray outline onClick={() => clearFilters()} small>
                      Clear
                    </Button>
                    <Button primary outline onClick={() => applyFilters()} small>
                      Apply
                    </Button>
                  </div>
                </div>
              </Listbox.Options>
            </Transition>
          )}
        </>
      </Listbox>
    </div>
  );
}
