import * as Yup from 'yup';

export const claimValidationSchema = Yup.object().shape({
  payerid: Yup.string().required("Payer ID can't be blank"),
  payer_name: Yup.string().required("Payer name can't be blank"),
  pcn: Yup.number().positive("PCN can't be blank").integer(),
  pat_name_l: Yup.string().required("Patient's last name can't be blank"),
  pat_name_f: Yup.string().required("Patient's first name can't be blank"),
  pat_addr_1: Yup.string().required("Patient's address can't be blank"),
  pat_city: Yup.string().required("Patient's city can't be blank"),
  pat_state: Yup.string().required("Patient's state can't be blank"),
  pat_zip: Yup.string().required("Patient's zip code can't be blank"),
  pat_dob: Yup.string().required("Patient's date of birth can't be blank"),
  pat_sex: Yup.string().required("Patient's sex can't be blank"),
  pat_rel: Yup.string().required("Patient's relation can't be blank"),
  accept_assign: Yup.string().required("Accept assign can't be blank"),
  total_charge: Yup.number().min(1, 'Total amount has to be greater than $0'),
  bill_name: Yup.string().required("Biller's name can't be blank"),
  bill_addr_1: Yup.string().required("Biller's address line one can't be blank"),
  bill_addr_2: Yup.string().required("Biller's address line two can't be blank"),
  bill_city: Yup.string().required("Biller's city can't be blank"),
  bill_state: Yup.string().required("Biller's state can't be blank"),
  bill_zip: Yup.string().required("Biller's zip code can't be blank"),
  bill_npi: Yup.string().required("Biller's NPI can't be blank"),
  bill_id: Yup.string().when('bill_npi', (bill_npi, schema) => {
    return !bill_npi || bill_npi.length === 0
      ? schema.required("Biller's ID is required when NPI is missing")
      : schema;
  }),
  bill_phone: Yup.string().required("Biller's phone can't be blank"),
  bill_taxid: Yup.string().required("Biller's tax id can't be blank"),
  bill_taxid_type: Yup.string().required("Biller's tax id type can't be blank"),
  procedures: Yup.array().of(
    Yup.object().shape({
      charge: Yup.number().min(1, 'Charge for each procedure has to be greater than $0'),
      units: Yup.number().min(1, 'Days/Units has to be greater than 0'),
      place_of_service: Yup.number().min(1, 'Please select a place of service for all procedures')
    })
  )
});
