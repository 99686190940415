export const practiceSidebarLinks = (isAdmin) => [
  {
    path: '',
    name: 'Dashboard',
    icon: 'new-dark-dashboard',
    end: true
  },
  {
    path: 'appointments',
    name: 'Calendar',
    icon: 'new-dark-calendar',
    end: false
  },
  {
    path: 'charts',
    name: 'Charts',
    permission: 'patients.read',
    icon: 'new-dark-patients',
    end: false
  },
  {
    path: 'beyond-billing',
    name: 'Beyond Billing',
    icon: 'new-billing',
    permission: 'billing.read',
    end: false,
    children: [
      {
        path: 'beyond-billing/overview',
        name: 'Overview',
        icon: 'new-graph'
      },
      {
        path: 'beyond-billing/income-i-queue',
        name: 'IncomeIQueue',
        icon: 'new-note-text',
        permission: 'is_admin'
      },
      {
        name: 'Payments',
        path: 'beyond-billing/payments',
        icon: 'new-billing',
        permission: 'payments.read'
      },
      {
        path: 'beyond-billing/patient-ledger',
        name: 'Patient Ledger',
        icon: 'new-macro-type',
        permission: 'payments.read'
      },
      // {
      //   path: 'beyond-billing/financial-reporting',
      //   name: 'Financial Reporting',
      //   icon: 'receipt-text'
      // },
      {
        path: 'beyond-billing/dynamic-ar',
        name: 'Dynamic AR',
        icon: 'new-notev3'
      },
      {
        path: 'beyond-billing/era-eob/era',
        name: 'ERA',
        icon: 'new-receipt-item'
      },
      {
        path: 'beyond-billing/era-eob/eob',
        name: 'EOB',
        icon: 'new-receipt-3'
      },
      {
        path: 'beyond-billing/claims',
        name: 'Claims',
        icon: 'wallet'
      },
      {
        path: 'beyond-billing/superbills',
        name: 'Superbills',
        icon: 'new-bill',
        permission: 'superbill.read'
      },
      {
        path: 'beyond-billing/electronic-invoicing',
        name: 'Electronic Invoicing',
        icon: 'new-file-dollar'
      }
    ]
  },
  {
    path: 'comms-center/fax/main',
    name: 'Comms Center',
    icon: 'new-light-features',
    end: false
  },
  {
    path: 'practice-i-queue',
    name: 'PracticeIQueue',
    icon: 'new-practice-i-queue',
    end: false,
    children: [
      {
        name: 'Overview',
        path: 'practice-i-queue/overview',
        icon: 'new-graph'
      },
      {
        name: 'Tasks',
        path: 'practice-i-queue/tasks',
        icon: 'new-task',
        permission: 'tasks.read'
      },
      {
        name: 'Clinical Notes',
        path: 'practice-i-queue/clinical-notes',
        icon: 'new-notev2',
        permission: 'clinical_note.read'
      },
      {
        name: 'Treatment Plans',
        path: 'practice-i-queue/treatment-plans',
        icon: 'new-task1',
        permission: 'clinical_note.read'
      },
      {
        name: 'Notifications',
        path: 'practice-i-queue/notifications',
        icon: 'new-notifications'
      },
      {
        name: 'Appointments',
        path: 'practice-i-queue/appointments',
        icon: 'new-calendar',
        permission: 'appointments.read'
      },
      {
        name: 'Forms',
        path: 'practice-i-queue/forms',
        icon: 'new-calendar-note',
        permission: 'form_packet.read'
      },
      isAdmin
        ? {
            name: 'Orders',
            path: 'practice-i-queue/orders',
            icon: 'new-note',
            permission: ''
          }
        : null
    ].filter(Boolean)
  },
  {
    path: 'settings',
    name: 'Settings',
    icon: 'new-settings',
    end: false,
    children: [
      {
        path: 'settings',
        name: 'Services',
        icon: 'new-star',
        permission: 'services.read',
        end: true
      },
      {
        path: 'settings/products',
        name: 'Products',
        permission: 'products.read',
        icon: 'new-pill'
      },
      {
        path: 'settings/packages',
        name: 'Packages',
        icon: 'new-packages',
        permission: 'packages.read'
      },
      {
        path: 'settings/calendar',
        name: 'Calendar',
        icon: 'new-calendarv2'
      },
      {
        path: 'settings/forms',
        name: 'Forms',
        icon: 'new-calendar-note',
        permission: 'form.read'
      },
      {
        path: 'settings/payers',
        name: 'Payers',
        icon: 'new-payers'
      },
      {
        path: 'settings/account',
        name: 'Account',
        icon: 'user-square'
      },
      {
        path: 'settings/staff',
        name: 'Staff',
        permission: 'member.read',
        icon: 'new-three-people'
      },
      {
        path: 'settings/roles',
        name: 'Roles & Permissions',
        permission: 'role.read',
        icon: 'new-patients'
      },
      {
        path: 'settings/eprescribe',
        name: 'ePrescribe',
        permission: 'prescribe.read',
        icon: 'new-eprescribe-v2'
      },
      {
        path: 'settings/billing',
        name: 'Billing',
        permission: 'billing.read',
        icon: 'wallet'
      },
      {
        path: 'settings/kiosk',
        name: 'Kiosk',
        permission: 'kiosk.read',
        icon: 'new-packages'
      },
      {
        path: 'settings/resources',
        name: 'Resources',
        icon: 'new-menu-board',
        permission: 'resources.read'
      },
      {
        path: 'settings/tags',
        name: 'Tags',
        icon: 'new-tag',
        permission: 'tags.read'
      }
    ]
  }
];
