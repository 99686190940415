import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import Axios from '../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../lib/helpers/utility';
import schedulingState from '../../../../../Patient/self_scheduling/schedulingState';
import { userState } from '../../../../../state';
// components
import { isItemNull } from 'components/practice/charts/Overview/widgets/Charts/ChartRow';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import AllergiesOverview from './components/AllergiesOverview';
import FamilyHistoryOverview from './components/FamilyHistoryOverview';
import GrowthChartOverview from './components/GrowthChartOverview';
import MedicalHistoryOverview from './components/MedicalHistoryOverview';
import MedicationHistoryOverview from './components/MedicationHistoryOverview';
import SocialHistoryOverview from './components/SocialHistoryOverview';
import SurgicalHistoryOverview from './components/SurgicalHistoryOverview';
import VaccinesOverview from './components/VaccinesOverview';
import VitalsOverview from './components/VitalsOverview';
import WeightLossAdministrationOverview from './components/WeightLossAdministrationOverview';

const GeneralOverview = () => {
  const { patient, practicePatient } = useOutletContext();

  const {
    overviewData,
    setOverviewData,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    vitals,
    setVitals,
    patientHistoryLoading: loading
  } = useClinicalNoteContext();
  const [vitalsModal, setVitalsModal] = useState(false);
  const [growthChartModal, setGrowthChartModal] = useState(false);
  const [isOpen, setIsOpen] = useState({
    alerts: false,
    vitals: false,
    allergies: false,
    vaccines: false,
    socialHistory: false,
    familyHistory: false,
    medicalHistory: false,
    medicationHistory: false,
    surgicalHistory: false
  });
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);

  const [expanded, setExpanded] = useState(true);
  const [prevExpanded, setPrevExpanded] = useState();

  const { pathname } = useLocation();
  const is3dPage = pathname.includes(`/3d`);

  useEffect(() => {
    if (is3dPage) {
      setPrevExpanded(expanded);
      setExpanded(false);
    } else {
      if (prevExpanded) {
        setExpanded(prevExpanded);
        setPrevExpanded();
      }
    }
  }, [is3dPage]);

  const {
    display_settings: { clinicalNote: cnSettings }
  } = useRecoilValue(userState);

  const isGrowthChartEnabled = cnSettings?.overview?.growth_chart;

  // TODO: Conditions Modal

  moment.tz.setDefault(appointmentData?.practice_timezone);

  useEffect(() => {
    const isAlerts = ia(practicePatient?.alerts);
    const isVitals =
      ia(vitals) && vitals?.map((vitals) => Object.values(vitals || {}).some((value) => io(value)));
    const isAllergies = ia(overviewData?.allergies);
    const isVaccines = ia(overviewData?.vaccines);
    const isWeightLossAdministration = ia(overviewData?.weightLossAdministration);
    const isSocialHistory =
      ia(overviewData?.socialHistory) &&
      overviewData?.socialHistory?.map((socialHistory) =>
        Object.values(socialHistory?.response || {}).some((value) => io(value))
      );
    const isFamilyHistory = ia(overviewData?.familyHistory);
    const isMedicalHistory = overviewData?.medicalHistory?.map((medicalHistory) =>
      Object.values(medicalHistory?.response || {}).some((value) => io(value) || value !== '')
    );
    const isMedicationHistory = ia(overviewData?.medicationHistory);
    const isSurgicalHistory = ia(overviewData?.surgicalHistory);

    setIsOpen({
      ...isOpen,
      alerts: isAlerts,
      vitals: isVitals,
      allergies: overviewData?.allergies?.response?.noDetails ? false : isAllergies,
      vaccines: overviewData?.vaccines?.response?.noDetails ? false : isVaccines,
      weightLossAdministration: overviewData?.weightLossAdministration?.response?.noDetails
        ? false
        : isWeightLossAdministration,
      socialHistory: overviewData?.socialHistory?.response?.noDetails ? false : isSocialHistory,
      familyHistory: overviewData?.familyHistory?.response?.noDetails ? false : isFamilyHistory,
      medicalHistory: overviewData?.medicalHistory?.response?.noDetails ? false : isMedicalHistory,
      medicationHistory: overviewData?.medicationHistory?.response?.noDetails
        ? false
        : isMedicationHistory,
      surgicalHistory: overviewData?.surgicalHistory?.response?.noDetails
        ? false
        : isSurgicalHistory,
      growthChart: isGrowthChartEnabled
    });
  }, [vitals, overviewData, practicePatient?.alerts]);

  const toggleOne = (key) => {
    setIsOpen({ ...isOpen, [key]: !isOpen[key] });
  };

  // const handleCopy = (id) => {
  //   setCopiedIndex(id);
  //   setTimeout(() => {
  //     setCopiedIndex(null);
  //   }, 2000);
  // };

  const overviewProps = {
    cnSettings,
    isOpen,
    toggleOne,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    overviewData,
    setOverviewData
  };

  if (loading)
    return (
      <div className="h-[calc(100%-60px)] !p-3">
        <Skeleton height="100%" />
      </div>
    );

  return (
    <div className="relative flex h-[calc(100%-65px)] w-full shrink-0 flex-col shadow-[8px_0_16px_0_rgba(0,79,107,0.04)]">
      <div className="flex flex-col overflow-auto !pl-3 !pr-2 !pt-2">
        {/* <GeneralOverviewFilters /> */}
        <div className="!mt-2 flex h-full min-h-[200px] flex-col gap-y-2 overflow-auto !pr-2 lg:!pr-1">
          {/* <AlertsOverview
              cnSettings={cnSettings}
              isOpen={isOpen}
              toggleOne={toggleOne}
              practicePatient={practicePatient}
            /> */}
          <AllergiesOverview {...overviewProps} />
          <VitalsOverview
            cnSettings={cnSettings}
            isOpen={isOpen}
            toggleOne={toggleOne}
            currentHpOverviewData={currentHpOverviewData}
            setCurrentHpOverviewData={setCurrentHpOverviewData}
            vitals={vitals}
            setVitals={setVitals}
            vitalsModal={vitalsModal}
            setVitalsModal={setVitalsModal}
            isGrowthChartEnabled={isGrowthChartEnabled}
          />
          <GrowthChartOverview
            growthChartModal={growthChartModal}
            setGrowthChartModal={setGrowthChartModal}
            patient={patient}
            isGrowthChartEnabled={isGrowthChartEnabled}
            {...overviewProps}
          />
          <MedicationHistoryOverview {...overviewProps} />
          <VaccinesOverview {...overviewProps} />
          <WeightLossAdministrationOverview {...overviewProps} />
          <SocialHistoryOverview {...overviewProps} />
          <FamilyHistoryOverview {...overviewProps} />
          <MedicalHistoryOverview {...overviewProps} />
          <SurgicalHistoryOverview {...overviewProps} />
        </div>
      </div>
    </div>
  );
};

export default GeneralOverview;
