import { formatSections } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV3';
import { ia, iaRa, io } from 'lib/helpers/utility';
import _ from 'lodash';

const isObjectType = ({ value = null }) => {
  if (!value) return false;

  return typeof value === 'object' && !Array.isArray(value) && value !== null;
};

const getEventValue = ({ event }) => {
  if (!event) return null;

  if (isObjectType({ value: event?.target?.value })) {
    return event?.target?.value;
  }

  return event;
};

const checkIfCustomFormsAreSame = ({
  oldChanges,
  newChanges,
  isCustomForm = false,
  targetFormName
}) => {
  // TEMPORARY FIX
  // Custom forms (e.g., allergies, social history, family history, surgical history, etc.)
  // should always have the narrative field filled. When a form has "Custom forms", onChange is triggered initially
  // without user input, causing "newChanges" to be null and ignoring the narrative.
  // To handle this, we check if the narrative inside the custom form is null.
  // If it is, we assume it's the first time the form is being called and return true.
  if (!newChanges.narrative && isCustomForm) {
    return true;
  }

  if (!oldChanges || !newChanges || !isCustomForm) return false;

  let parsedFields = typeof oldChanges == 'string' ? JSON.parse(oldChanges) : oldChanges;

  parsedFields = iaRa(parsedFields)?.find((field) => {
    if (field.name === targetFormName) {
      return field;
    }
  })?.value;

  const stringifyParsedFields =
    typeof parsedFields != 'string' ? JSON.stringify(parsedFields) : parsedFields;

  const stringifyNewChanges =
    typeof newChanges != 'string' ? JSON.stringify(newChanges) : newChanges;

  return stringifyParsedFields == stringifyNewChanges;
};

export const handleOnChange = ({
  e = null,
  isMounted = false,
  currentForm = null,
  setCurrentForm = () => null,
  syncON = true,
  setFormUpdated = () => null,
  setNarrativeUpdated = () => null,
  advancedHPRef = null,
  timer = null,
  setTimer = () => null
}) => {
  if (!isMounted) return;

  const event = getEventValue({ event: e });

  if (
    checkIfCustomFormsAreSame({
      oldChanges: currentForm?.json?.fields,
      newChanges: event?.target?.value,
      isCustomForm: event?.target?.type === 'custom',
      targetFormName: e?.target?.name
    })
  ) {
    return;
  }

  setCurrentForm((prev) => {
    let updatedForm = _.cloneDeep(prev);
    const fieldsData =
      event?.target?.type === 'custom' ? event : getUpdatedFieldsData(updatedForm, event);

    if (isFormObjectAvailable(updatedForm)) {
      if (event?.target?.type === 'custom') {
        let parsedFields =
          typeof updatedForm.json.fields == 'string'
            ? JSON.parse(updatedForm.json.fields)
            : updatedForm.json.fields;

        parsedFields = iaRa(parsedFields)?.map((field) => {
          if (field.name === e?.target?.name) {
            return { ...field, value: event.target.value };
          }

          return field;
        });

        if (JSON.stringify(event.target.value) == currentForm?.json?.fields) {
          return;
        }

        updatedForm.json.fields = JSON.stringify(parsedFields);
      } else {
        updatedForm.json.fields = JSON.stringify(fieldsData);
      }
    } else {
      updatedForm['form'] =
        typeof prev.json.fields === 'string'
          ? { ...prev, json: { ...prev.json, fields: JSON.parse(prev.json.fields) } }
          : { ...prev };
      updatedForm.json.fields = JSON.stringify(fieldsData);
    }

    const formattedSections = formatSections({
      fieldData: updatedForm?.form?.json?.fields,
      answerData: JSON.parse(updatedForm?.json?.fields)
    });

    if (syncON) {
      updatedForm['narrative'] = formattedSections;
      setNarrativeUpdated(true);
    }

    updatedForm['simple_narrative'] = formattedSections;

    setFormUpdated(true);
    return updatedForm;
  });

  clearTimeout(timer);

  const newTimer = setTimeout(() => {
    advancedHPRef?.current?.click();
  }, 3000);

  setTimer(newTimer);
};

function removeStringBeforeUnderscore(text) {
  const regex = /.*_/;
  return text.replace(regex, '');
}

const getUpdatedFieldsData = (currentForm, event) => {
  const eventTargetName = event?.target?.name;
  const eventTargetValue = event?.target?.value;
  const eventTargetId = event?.target?.id;

  let fields = [],
    fieldIndex = -1;

  if (isFormObjectAvailable(currentForm) && typeof currentForm?.json?.fields === 'string') {
    fields = JSON.parse(currentForm.json.fields);
  }

  if (ia(fields)) {
    const currentFormJSONFields = currentForm?.form?.json?.fields;

    if (eventTargetName?.includes('option')) {
      const processedTargetName = removeStringBeforeUnderscore(eventTargetName);

      const parentElement = currentFormJSONFields.find((parent) => {
        if (parent.element?.toLowerCase() == 'checkboxes') {
          return iaRa(parent?.options)?.find((option) => {
            return removeStringBeforeUnderscore(option?.key) === processedTargetName;
          });
        }
      });

      const parentElementFieldName = parentElement?.field_name;
      const parentElementOptions = parentElement?.options;

      const targetKey = parentElementOptions?.find(
        (option) => removeStringBeforeUnderscore(option?.key) === processedTargetName
      ).key;

      const result = fields.map((item) => {
        if (parentElementFieldName == item.name) {
          const findIndex = iaRa(item?.value).findIndex((item) => item == targetKey);

          if (findIndex == -1) {
            return { ...item, value: [...item.value, targetKey] };
          } else {
            return {
              ...item,
              value: iaRa(item?.value).filter((item) => item != targetKey)
            };
          }
        }

        return item;
      });

      return result;
    }

    if (eventTargetName?.includes('dropdown')) {
      const parentElement = currentFormJSONFields.find((parent) => {
        if (parent?.element?.toLowerCase() == 'dropdown' && eventTargetName == parent?.field_name) {
          return iaRa(parent?.options).find((option) => {
            return option?.value === eventTargetValue;
          });
        }
      });

      const parentElementFieldName = parentElement?.field_name;
      const parentElementOptions = parentElement?.options;

      const targetKey = parentElementOptions?.find(
        (option) => option?.value === eventTargetValue
      ).key;

      const result = fields.map((item) => {
        if (parentElementFieldName == item.name) {
          return { ...item, value: [targetKey] };
        }

        return item;
      });

      return result;
    }

    if (eventTargetName?.includes('radiobuttons')) {
      const targetNameId = removeStringBeforeUnderscore(eventTargetId);

      const parentElement = currentFormJSONFields.find((parent) => {
        if (
          parent?.element?.toLowerCase() == 'radiobuttons' &&
          eventTargetName == parent?.field_name
        ) {
          return iaRa(parent?.options)?.find((option) => {
            return removeStringBeforeUnderscore(option?.key) === targetNameId;
          });
        }
      });

      const parentElementFieldName = parentElement?.field_name;
      const parentElementOptions = parentElement?.options;

      const targetKey = parentElementOptions?.find(
        (option) => removeStringBeforeUnderscore(option?.key) === targetNameId
      ).key;

      const result = fields.map((item) => {
        if (parentElementFieldName == item.name) {
          return { ...item, value: [targetKey] };
        }

        return item;
      });

      return result;
    }

    fieldIndex = fields.findIndex((item) => item.name === eventTargetName);
  } else if (io(fields)) {
    fields = Object.keys(fields).map((key) => {
      return { name: key, value: fields[key] };
    });
  }

  if (fieldIndex !== -1) {
    fields[fieldIndex].value = eventTargetValue;
  } else {
    return { name: eventTargetName, value: eventTargetValue };
  }

  return fields;
};

const isFormObjectAvailable = (form) => {
  return io(form?.form);
};

export const hasFormData = (currForm) => {
  const fields = io(currForm?.form) ? currForm?.form?.json?.fields : currForm?.json?.fields;
  return typeof fields !== 'string';
};

export const generateNarrative = ({
  currentForm = null,
  setCurrentForm = () => null,
  setNarrativeUpdated = () => null
}) => {
  let narrative = '',
    isCustomForm = false;

  const fields =
    typeof currentForm?.json?.fields === 'string'
      ? JSON.parse(currentForm?.json?.fields)
      : currentForm?.json?.fields;

  if (ia(fields)) {
    fields.forEach((field) => {
      const isValueTypeOfObject = isObjectType({ value: field?.value });
      if (isValueTypeOfObject) {
        isCustomForm = true;
        const parsedValue = field.value;
        if (parsedValue?.narrative) {
          narrative += parsedValue.narrative + '\n\n';
        }
      }
    });
  }

  if (!isCustomForm) {
    narrative = formatSections({
      fieldData: currentForm?.form?.json?.fields,
      answerData: fields
    });

    setNarrativeUpdated(true);
  }

  setCurrentForm((prev) => ({ ...prev, narrative, simple_narrative: narrative }));
};
