import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import {
  useMyScribeTemplateContextApi,
  useMyScribeTemplateContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import Button from 'components/shared/Buttons/Button';
import { replacePlaceholders } from '../../../lib/helpers';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { snakeToTitleCase } from 'lib/helpers/utility';

const PromptsSidebar = ({ className, isOpen, setIsOpen }) => {
  const { setCurrentActivePrompt, setLocalPrompts } = useMyScribeTemplateContextApi();
  const { localPrompts, isGetPromptsLoading } = useMyScribeTemplateContextData();

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const onPromptClick = (prompt) => {
    setCurrentActivePrompt(prompt);
  };

  const addNewPrompt = () => {
    const newPrompt = {
      promptTemplate: {
        // id: `prompt-${Date.now()}`,
        // title: 'New Prompt',
        // description: 'Description for new prompt',
        icon: 'new-icon',
        body: null,
        template_name: 'New Note'
      }
    };
    if (localPrompts?.length > 0 && !localPrompts?.[0]?.id) {
      return;
    }
    setLocalPrompts((prevPrompts) => [newPrompt, ...prevPrompts]);
    setCurrentActivePrompt(null);
  };

  return (
    <div
      className={cs(
        'fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        {
          'w-[264px]': isOpen,
          'w-0': !isOpen
        },
        className
      )}
      style={{
        transition: 'width 0.2s ease'
      }}>
      {isOpen && (
        <>
          <div className="flex items-center justify-between bg-primary-50 !py-[8px] px-3">
            <Icon icon="new-hamburger" className="!ml-1 cursor-pointer" onClick={toggleSidebar} />
            <>
              <div className="p-2 pl-0">Prompts</div>
              <div className="flex items-center">
                <Button
                  icon="new-notev1"
                  text="New Note"
                  outlined
                  size="small"
                  onClick={addNewPrompt}
                />
              </div>
            </>
          </div>
          {isGetPromptsLoading ? (
            <div>
              <div className="m-3 mb-4 cursor-pointer rounded-lg p-2">
                <Skeleton height="20px" width="100px" />
                <Skeleton height="20px" />
              </div>
            </div>
          ) : (
            <div>
              {localPrompts?.map((prompt) => (
                <div
                  key={prompt.id}
                  onClick={() => onPromptClick(prompt)}
                  className="m-3 mb-4 cursor-pointer rounded-lg p-2 hover:bg-primary-50">
                  <div className="mb-2 flex items-center">
                    <Icon icon="new-messages" className="mr-2" />
                    <span className="text-sm text-neutral-600">
                      {snakeToTitleCase(
                        prompt?.kind?.split('.')[prompt?.kind?.split('.')?.length - 1]
                      ) ?? 'New Note'}
                    </span>
                  </div>
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-thin text-neutral-800">
                    {replacePlaceholders(prompt?.promptTemplate?.body, {
                      formName: prompt?.kind
                        ?.split('.')
                        [prompt?.kind?.split('.')?.length - 2]?.toUpperCase(),
                      body: '',
                      userContext: ''
                    })}
                  </p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PromptsSidebar;
