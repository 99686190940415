import React, { useEffect } from 'react';

import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from '../../../../../lib/helpers/utility';

import TPEdit from './views/TPEdit';
import TPPreview from './views/TPPreview';

const TreatmentPlan = ({
  view,
  origin,
  patientSideTreatmentPlan,
  patientSide = false,
  outsideCN = false
}) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  useEffect(() => {
    if (io(patientSideTreatmentPlan)) setSelectedTreatmentPlan(patientSideTreatmentPlan);
  }, [patientSideTreatmentPlan]);

  if (['edit', 'create'].includes(view)) {
    return <TPEdit view={view} origin={origin} outsideCN={outsideCN} />;
  } else {
    return <TPPreview id={selectedTreatmentPlan?.id} patientSide={patientSide} />;
  }
};

export default TreatmentPlan;
