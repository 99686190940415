import moment from 'moment';

export default function formatDate(d) {
  const today = moment().startOf('day');
  const yesterday = moment(today).subtract(1, 'day');
  const tomorrow = moment(today).add(1, 'day');
  // const startOfCurrentWeek = moment(today).startOf('week');
  // const startOfNextWeek = moment(today).add(1, 'week').startOf('week');
  // const startOfPastWeek = moment(today).subtract(1, 'week').startOf('week');

  const date = moment(d);

  if (date?.isSame(yesterday, 'day')) {
    return 'Yesterday';
  } else if (date?.isSame(today, 'day')) {
    return 'Today';
  } else if (date?.isSame(tomorrow, 'day')) {
    return 'Tomorrow';
  } else {
    return date?.format('MMM DD, YYYY');
  }
}
