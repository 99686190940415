const handleStatusRowAdd = (api, selectedClaim, selectedUnmatchedClaim) => {
  if (selectedClaim && selectedClaim.id !== selectedUnmatchedClaim) {
    const rowsToRemove = [];
    api.forEachNode((node) => {
      if (node.data?.isStatusRow) {
        rowsToRemove.push(node.data);
      }
    });

    if (rowsToRemove.length) {
      api.applyTransaction({ remove: rowsToRemove });
    }
  }

  let lastRowIndex = -1;
  api.forEachNode((node) => {
    if (node.data?.claimId === selectedClaim.id) {
      lastRowIndex = node.rowIndex;
    }
  });

  const statusRow = { isStatusRow: true, claimId: selectedClaim.id };
  api.applyTransaction({ add: [statusRow], addIndex: lastRowIndex + 1 });
};

export default handleStatusRowAdd;
