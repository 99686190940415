import React, { useState } from 'react';
import Step from './Step';
import Button from 'components/shared/Buttons/Button';
import { useOrganizationContext } from 'lib/context/OrganizationContext/OrganizationContext';
import { showAlert } from 'components/shared/Alert/Alert';
import { capitalize } from 'lodash';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Switch from 'components/shared/Switch/Switch';

const steps = ['customForms', 'patients', 'services', 'packages', 'products'];

const MultiStepForm = () => {
  const { importAll, setImportAll, selectedLocation } = useOrganizationContext();
  const [currentStep, setCurrentStep] = useState(0);

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleSelectImportType = () => {
    if (!selectedLocation) {
      showAlert({
        color: 'warning',
        title: 'Please select a practice location to import data'
      });

      return;
    }
    setImportAll(!importAll);
  };
  return (
    <div className="!pt-4">
      <div className="flex items-center justify-between gap-2">
        <h6 className={`${importAll ? 'text-neutral-400' : 'text-primary-700'}`}>
          Select necessary data to import
        </h6>

        <div className="flex flex-col items-end">
          <label htmlFor="">Import all data!</label>
          <Switch checked={importAll} onChange={handleSelectImportType} />
        </div>
      </div>

      {!importAll && selectedLocation && (
        <>
          <div className="flex items-center gap-2 rounded bg-white !p-2 shadow-sm">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`cursor-pointer rounded-md p-2 ${
                  currentStep === index ? 'bg-primary-50 text-primary-500' : ''
                }`}
                onClick={() => setCurrentStep(index)}>
                {capitalize(step)}
              </div>
            ))}
          </div>

          <Step stepName={steps[currentStep]} />
        </>
      )}
    </div>
  );
};

export default MultiStepForm;
