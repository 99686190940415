import Tippy from '@tippyjs/react';
import cs from 'classnames';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { ia, iaRa } from 'lib/helpers/utility';
import React from 'react';
import TruncatedLabel from './TruncatedLabel';

const Display = ({ loading, data, searchTerm, onClickAdd, trackSearchDataLoading = {} }) => {
  if (loading) {
    return <Skeleton count={15} height="25px" />;
  }

  if (!ia(data) && !!searchTerm) {
    return (
      <NotFound
        title="No Diagnosis Codes found"
        className="overflow-hidden"
        description={
          searchTerm && (
            <span>
              No Diagnosis Codes found for the search term{' '}
              <em className="font-500 text-neutral-900">"{searchTerm}"</em>. Please try again.
            </span>
          )
        }
      />
    );
  }

  return iaRa(data).map((row, index) => (
    <div
      key={index}
      className={cs(
        'flex items-center justify-between gap-2 rounded-md !px-1 !py-2 transition-all first-of-type:!mt-0  hover:!bg-white',
        !!trackSearchDataLoading?.[row?.value] &&
          'pointer-events-none cursor-not-allowed opacity-50'
      )}>
      <div className="flex gap-3 overflow-hidden">
        <span className="overflow-hidden text-ellipsis whitespace-nowrap text-neutral-900">
          <TruncatedLabel label={row?.label} />
        </span>
      </div>
      <div className="flex gap-1.5">
        {row?.meta?.often_clicked && (
          <Tippy
            className="tippy-dark"
            content="This code appears at the top because it is frequently selected by you.">
            <div>
              <Icon icon="new-history" />
            </div>
          </Tippy>
        )}
        <div
          className="flex cursor-pointer items-center gap-1.5"
          data-qa={`add-icd-${index}`}
          onClick={() => onClickAdd(row)}>
          <p className="text-xs font-500 text-primary-500">Add</p>
          <Icon icon="new-add-circle" size={15} className="cursor-pointer" />
          {!!trackSearchDataLoading?.[row?.value] && (
            <Loader color="primary" shade="50" type="primary" className="!bg-primary-500" />
          )}
        </div>
      </div>
    </div>
  ));
};

export default Display;
