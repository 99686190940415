import { useQuery } from '@tanstack/react-query';
import { getReportsWidget } from 'api/Widgets';
import { useNavigate } from 'react-router-dom';

export const useAdminReports = ({params = {}, options = {}, dependencies = []}) => {
  const navigate = useNavigate();

  return useQuery(['adminReports',  ...dependencies], () => getReportsWidget(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
