import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPreferredTestList } from 'api/LabSoftOrder';

export const usePreferredTestList = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(
    ['preferredTestList', ...dependencies],
    () => getPreferredTestList(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: 3,
      ...options
    }
  );
};
