import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useRecoilValue } from 'recoil';
import { userState as user_state } from 'components/state';
import { usePatientChartContext } from 'lib/context/PatientChartContext/PatientChartContext';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import PatientTags from './components/PatientTags';
import _ from 'lodash';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import { useNavigate } from 'react-router-dom';
import Popover from 'components/shared/Popovers/Popover/Popover';
import DropdownPanel from 'components/shared/Tags/components/DropdownPanel';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import Actions from './components/Actions';

const PinnedTagsContainer = ({
  tags = [],
  showHighlight = false,
  tagsKind = 'chart',
  handleAddTags = () => {},
  handleRemove = () => {},
  tagsToAdd = [],
  setTagsToAdd = () => {}
}) => {
  const navigate = useNavigate();
  const { device } = useUIContext();
  const { isUnpinnedConfirmation, isRemoveConfirmation, setTogglePinnedSection } =
    usePatientChartContext();
  const { updateTags } = useTagContext();
  const userState = useRecoilValue(user_state);
  const { data } = useTags({ params: { kind: tagsKind }, dependencies: [tagsKind] });
  const allTags = data?.tags || [];
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const tagSettings = userState?.display_settings?.patientChartTags;
  const [showUnpinTagsModal, setShowUnpinTagsModal] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openReorderModal, setOpenReorderModal] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [tagToRemove, setTagToRemove] = useState();
  const [selectedTags, setSelectedTags] = useState(tags || []);
  const scrollPositionRef = useRef(0); // to store scroll position
  const panelRef = useRef(null);
  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [selectedTags]);

  const handleScroll = () => {
    if (panelRef.current) {
      scrollPositionRef.current = panelRef.current.scrollTop;
    }
  };

  useEffect(() => {
    //this formats the tags to important and regular
    updateTags(tags, allTags);
  }, [tags]);

  const updateDisplaySettings = (isShowMessage = true) => {
    saveDisplaySettings({
      ...userState.display_settings,
      patientChartTags: {
        isPinned: false,
        showConfirmations: {
          ...userState.display_settings?.patientChartTags?.showConfirmations,
          unpin: isShowMessage ? !isUnpinnedConfirmation : false
        }
      }
    });
    setShowUnpinTagsModal(false);
  };

  const updateRemoveConfirmation = () => {
    saveDisplaySettings({
      ...userState.display_settings,
      patientChartTags: {
        ...userState.display_settings.patientChartTags,
        showConfirmations: {
          ...userState.display_settings?.patientChartTags?.showConfirmations,
          remove: !isRemoveConfirmation
        }
      }
    });
    handleRemove && handleRemove(tagToRemove);
    setShowRemoveConfirmation(false);
  };

  const handleClosePinTagsModal = () => {
    setShowUnpinTagsModal(false);
    setTogglePinnedSection(true);
  };

  const showRemoveModal = (tag) => {
    if (!isRemoveConfirmation) {
      setTagToRemove(tag?.id);
      setShowRemoveConfirmation(true);
    } else {
      handleUpdateTags(tag?.id);
    }
  };

  const handleUnPinTagsModal = () => {
    if (tagSettings?.showConfirmations?.unpin) {
      setShowUnpinTagsModal(!showUnpinTagsModal);
      setTogglePinnedSection(false);
    } else {
      updateDisplaySettings(false);
      setTogglePinnedSection(false);
    }
  };

  const addTag = (tag) => {
    const newTags = [...tagsToAdd, tag];
    setTagsToAdd(newTags);
    handleAddTags(newTags);
  };

  const handleUpdateTags = (tag) => {
    const tagId = typeof tag === 'object' ? tag.id : tag;

    if (selectedTags.some((selectedTag) => selectedTag.id === tagId)) {
      setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tagId));
      if (!isRemoveConfirmation) {
        setShowRemoveConfirmation(true);
        setTagToRemove(tagId);
      } else {
        handleRemove && handleRemove(tagId);
      }
    } else {
      setSelectedTags([...selectedTags, tag]);
      addTag(tag);
    }
  };

  const handleCreateModal = () => setOpenCreateModal(!openCreateModal);

  const handleReorderModal = () => setOpenReorderModal(!openReorderModal);

  const closeRemoveTagModal = () => setShowRemoveConfirmation(false);

  const options = [
    { label: 'Create Tag', icon: 'new-tag-new', onClick: handleCreateModal, disabled: false },
    {
      label: 'Re-order Tags',
      icon: 'new-sort-reorder',
      onClick: handleReorderModal,
      disabled: tags?.length < 2,
      tippyText:
        tags?.length < 2 ? 'Select tags to add to this patient’s chart' : 'Re-order selected tags'
    },
    { label: 'Unpin Tags', icon: 'new-pin-filled', onClick: handleUnPinTagsModal, disabled: false },
    {
      label: 'Manage Tags',
      icon: 'new-tag-settings',
      onClick: () => navigate('/portal/settings/tags'),
      disabled: false
    }
  ];

  return (
    <div
      className={cs(
        'relative flex max-h-[48px] items-center justify-between gap-2 bg-white px-2',
        !tagSettings?.isPinned && showHighlight
          ? 'pointer-events-none z-[100] rounded-md border border-dashed border-primary-500 bg-white'
          : 'border border-solid border-neutral-100'
      )}>
      <PatientTags handleRemove={showRemoveModal} />
      <Popover
        isFixed
        isDropdown
        position="left"
        icon="new-tag"
        text={device !== 'mobile' && 'Manage Tags'}
        iconSize={device !== 'mobile' ? 18 : 16}
        iconRight={device !== 'mobile' && 'new-chevron-down'}
        buttonClassName="min-w-[150px] whitespace-nowrap md:!pl-0 sm:!pl-0"
        panelClassName="!p-0"
        buttonTheme="transparent">
        {({ closePopover }) => (
          <DropdownPanel
            tags={allTags}
            selectedTags={selectedTags}
            actions={options}
            handleUpdateTags={handleUpdateTags}
            closePopover={closePopover}
            panelRef={panelRef}
            onScroll={handleScroll}
            scrollPositionRef={scrollPositionRef}
            tagsKind={tagsKind}
          />
        )}
      </Popover>
      <Actions
        showUnpinTagsModal={showUnpinTagsModal}
        updateDisplaySettings={updateDisplaySettings}
        openCreateModal={openCreateModal}
        openReorderModal={openReorderModal}
        showRemoveConfirmation={showRemoveConfirmation}
        handleClosePinTagsModal={handleClosePinTagsModal}
        handleCreateModal={handleCreateModal}
        handleAddTags={handleUpdateTags}
        handleReorderModal={handleReorderModal}
        closeRemoveTagModal={closeRemoveTagModal}
        updateRemoveConfirmation={updateRemoveConfirmation}
      />
    </div>
  );
};

export default withErrorBoundary(PinnedTagsContainer);
