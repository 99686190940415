import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useMyScribeAIContext } from '../MyScribeAIContext';
import { EnhanceNoteContextData, EnhanceNoteContextApi } from './EnhanceNoteContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useFilledAdvancedForms } from 'lib/hooks/queries/clinicalNotes/useFilledAdvancedForms';
import { useParams } from 'react-router-dom';
import { filterTypeOfNotes, getFinalAdvancedForms, getNonEmptyFields } from './lib/helpers';
import { updateHiddenComponents } from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/shared/lib/helpers';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

export const EnhanceNoteContextProvider = ({ children }) => {
  const { typeOfNotes } = useMyScribeAIContext();
  const { appointmentId } = useParams();

  const [enhanceLoading, setEnhanceLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);
  const [nestedSelectedChatComponents, setNestedSelectedChatComponents] = useState([]);
  const [filledForms, setFilledForms] = useState();

  const componentRefs = useRef({});

  const { currentHpOverviewData, advancedHP, advancedSOAP, customFormTypes, overviewData } =
    useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);

  const { data, isLoading: filledFormsLoading } = useFilledAdvancedForms({
    params: { appointmentId },
    dependencies: [appointmentId]
  });

  const filledAdvancedForms = data?.advancedForms;

  const customAdvancedForms = useMemo(
    () => Object.values(customFormTypes || {})?.flat(),
    [customFormTypes]
  );

  const advancedForms = useMemo(
    () => [...advancedHP, ...advancedSOAP, ...customAdvancedForms],
    [advancedHP, advancedSOAP, customAdvancedForms]
  );

  const finalFilledAdvancedForms = useMemo(
    () => getFinalAdvancedForms(filledAdvancedForms, advancedForms),
    [filledAdvancedForms, advancedForms]
  );

  const nonEmptyAdvancedFormsTitles = useMemo(
    () =>
      finalFilledAdvancedForms
        ?.filter((form) => form?.custom_title?.length > 0)
        ?.map((form) => form?.custom_title),
    [finalFilledAdvancedForms]
  );

  const clinicalNoteForms = useMemo(
    () => ({ ...clinicalNote, ...currentHpOverviewData, ...overviewData }),
    [clinicalNote, currentHpOverviewData, overviewData]
  );

  const nonEmptyFields = useMemo(() => getNonEmptyFields(clinicalNoteForms), [clinicalNoteForms]);
  const nonEmptyTitles = useMemo(() => Object.keys(nonEmptyFields || {}), [nonEmptyFields]);

  const initialFilledTypeOfNotes = useMemo(
    () =>
      filterTypeOfNotes(typeOfNotes, [...nonEmptyTitles, ...(nonEmptyAdvancedFormsTitles || [])]),
    [typeOfNotes, nonEmptyTitles, nonEmptyAdvancedFormsTitles]
  );
  const finalForms = useMemo(
    () => updateHiddenComponents(initialFilledTypeOfNotes, true),
    [initialFilledTypeOfNotes]
  );

  useEffect(() => {
    if (finalForms) {
      setLoading(false);
      setFilledForms(finalForms);
    }
  }, []);

  const ctxData = useMemo(
    () => ({
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs,
      filledFormsLoading,
      loading
    }),
    [
      selectedChatComponents,
      nestedSelectedChatComponents,
      filledForms,
      enhanceLoading,
      componentRefs,
      filledFormsLoading,
      loading
    ]
  );

  const ctxApi = useMemo(
    () => ({
      setSelectedChatComponents,
      setNestedSelectedChatComponents,
      setFilledForms,
      setEnhanceLoading,
      setLoading
    }),
    []
  );

  return (
    <EnhanceNoteContextData.Provider value={ctxData}>
      <EnhanceNoteContextApi.Provider value={ctxApi}>{children}</EnhanceNoteContextApi.Provider>
    </EnhanceNoteContextData.Provider>
  );
};
