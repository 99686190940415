import React from 'react';
import { useFormikContext } from 'formik';

import Item from './components/Item';
import Discount from './components/Discount';
import { mString } from 'lib/helpers/utility';
import Allocation from './components/Allocation';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const Balance = () => {
  const { values, setFieldValue } = useFormikContext();

  const paymentsAmount = values?.payments?.reduce((acc, curr) => {
    return acc + (curr.amount_paid_self || 0) - (curr.surcharge_amount || 0);
  }, 0);

  const { balance, tax, total_amount } = values?.invoice || {};

  return (
    <div className="flex flex-col overflow-hidden">
      <Item
        icon="new-wallet-add"
        price={mString(balance)}
        text="Appointment balance"
        className="min-h-[38px] gap-2 border-0 border-b border-solid border-neutral-200 !px-4"
        priceClassName="font-600 !text-base"
      />
      <div className="flex h-full max-h-[290px] flex-col gap-x-3 gap-y-[6px] overflow-auto border-0 border-l border-solid border-neutral-200 py-3 pl-4 pr-1">
        <Item icon="new-dollar-circle" text="Est. Patient Portion" price={mString(total_amount)} />
        <Item icon="new-wallet-add" text="Payments" price={mString(paymentsAmount)} />
        <Item text="Discount" icon="new-discount-shape" price={<Discount />} />
        <Item
          icon="new-receipt-discount"
          text="Tax"
          price={
            <CurrencyInput
              value={tax ?? 0}
              className="text-right"
              onValueChange={(v) => setFieldValue('tax', +v)}
              wrapperClassName="!pl-1 !pr-0 h-[26px] w-[85px] !px-[4px] !bg-transparent border-none border-bottom [border-bottom-style:solid] rounded-none focus-within:!outline-[0px] !outline-0"
            />
          }
        />
        <Allocation />
      </div>
    </div>
  );
};

export default Balance;
