import TagCreate from 'components/shared/Tags/components/Create';
import TagsView from 'components/shared/Tags/components/View/View';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import React, { useEffect, useRef, useState } from 'react';
import Popover from '../../Popovers/Popover/Popover';
import DropdownPanel from './DropdownPanel';
import { ia } from 'lib/helpers/utility';
import _ from 'lodash';
import { withErrorBoundary } from '../../Error/Boundary';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { userState as user_state } from 'components/state';
import { useRecoilValue } from 'recoil';
import Checkbox from '../../Checkbox/Checkbox';

const TableView = ({
  currTags = [],
  handleAdd = () => {},
  handleRemove = () => {},
  tagsToAdd = [],
  setTagsToAdd = () => {},
  tagsKind = null,
  selectActions = [],
  tableActions = [],
  isCreateModal = false,
  setIsCreateModal = () => {},
  usePendingState = false,
  fromTable = false,
  fromPatientChart = false,
  fromFaxes = false,
  removeConfirmationTitle = "Are you sure you want to remove this tag from the patient's chart?"
}) => {
  const userState = useRecoilValue(user_state);
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const { data } = useTags({ params: { kind: tagsKind }, dependencies: [tagsKind] });
  const tags = data?.tags || [];
  const { importantTags, regularTags, updateTags, updateTagsForTables } = useTagContext() || {};
  const tagSettings = userState?.display_settings?.patientChartTags;
  const [selectedTags, setSelectedTags] = useState(currTags || []);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [isRemoveConfirmation, setIsRemoveConfirmation] = useState(
    !tagSettings?.showConfirmations?.remove
  );
  const [pendingTags, setPendingTags] = useState([]);
  const [tagToRemove, setTagToRemove] = useState();

  const scrollPositionRef = useRef(0); // to store scroll position
  const panelRef = useRef(null);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [scrollPositionRef.current]);

  const handleScroll = () => {
    if (panelRef.current) {
      scrollPositionRef.current = panelRef.current.scrollTop;
    }
  };

  useEffect(() => {
    if (ia(currTags) && fromTable && !fromFaxes) {
      setSelectedTags(currTags?.map((tagLink) => tagLink?.tag).filter(Boolean));
      updateTagsForTables(currTags);
    }
    if (fromPatientChart) {
      updateTags(currTags, tags);
    }
  }, [currTags]);

  const updateDisplaySettings = () => {
    saveDisplaySettings({
      ...userState.display_settings,
      patientChartTags: {
        ...userState.display_settings.patientChartTags,
        showConfirmations: {
          ...userState.display_settings.patientChartTags.showConfirmations,
          remove: !isRemoveConfirmation
        }
      }
    });
    handleRemove && handleRemove(tagToRemove);
    setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tagToRemove));
    setShowRemoveConfirmation(false);
  };

  const handleUpdateTags = (tag) => {
    if (selectedTags.some((selectedTag) => selectedTag.id === tag.id)) {
      if (!isRemoveConfirmation) {
        setShowRemoveConfirmation(true);
        setTagToRemove(tag?.id);
      } else {
        setSelectedTags(selectedTags.filter((selectedTag) => selectedTag.id !== tag.id));
        handleRemove && handleRemove(tag?.id);
      }
    } else {
      setSelectedTags([...selectedTags, tag]);
      addTag(tag);
    }
  };

  const addTag = (tag) => {
    if (usePendingState) {
      const newTags = [...pendingTags, tag];
      setPendingTags(newTags);
      handleAdd(newTags);
    } else {
      const newTags = [...tagsToAdd, tag];
      setTagsToAdd(newTags);
      handleAdd(newTags);
    }
  };

  const handleCloseRemoveTagModal = () => setShowRemoveConfirmation(false);
  return (
    <>
      <div className="flex h-full items-center justify-end gap-2">
        <TagsView
          currTags={currTags}
          itemsToShow={2}
          label={false}
          handleRemove={handleUpdateTags}
          appendTo={document.body}
          fromTable={fromTable}
          fromPatientChart={fromPatientChart}
          importantTags={importantTags}
          regularTags={regularTags}
          fromFaxes={fromFaxes}
          disableDeviceCheck={fromTable}
        />
        <Popover isFixed position="left" icon="new-tag-new" iconSize={20} panelClassName="!p-0">
          {({ closePopover }) => (
            <DropdownPanel
              tags={tags}
              selectedTags={selectedTags}
              actions={fromTable ? tableActions : selectActions}
              handleUpdateTags={handleUpdateTags}
              closePopover={closePopover}
              panelRef={panelRef}
              onScroll={handleScroll}
              scrollPositionRef={scrollPositionRef}
              tagsKind={tagsKind}
            />
          )}
        </Popover>

        {showRemoveConfirmation && (
          <Confirm
            handleOpen={showRemoveConfirmation}
            variant="danger"
            primaryBtnTxt="Remove"
            secondaryBtnTxt="No, not at this time"
            title={removeConfirmationTitle}
            icon="new-pin-filled"
            iconColor="danger"
            iconSize={24}
            handleClose={handleCloseRemoveTagModal}
            handleContinue={updateDisplaySettings}>
            <Checkbox
              isChecked={isRemoveConfirmation}
              handleClick={() => setIsRemoveConfirmation(!isRemoveConfirmation)}
              label="Don’t show this message again"
              labelClassName="text-sm !text-neutral-600"
              size={18}
            />
          </Confirm>
        )}
        {isCreateModal && (
          <TagCreate
            isOpen={isCreateModal}
            handleClose={() => setIsCreateModal(false)}
            kind={tagsKind}
            addNewTagFromCreated={handleUpdateTags}
          />
        )}
      </div>
    </>
  );
};

export default withErrorBoundary(TableView);
