import React from 'react';
import EmptyTableState from '../../EmptyTableComponent';

const EmptyPatientHistory = () => {
  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="no-data-found" />
      <EmptyTableState.Text
        title="No Patient History Found!"
        description="You will see the list of forms once you have at least one Patient History record"
      />
    </EmptyTableState>
  );
};

export default EmptyPatientHistory;
