import React from 'react';
import { ReactFormBuilder } from 'react-form-builder2';
import { FormBuilderToolbar } from 'components/shared/Forms/Custom/Register';
import { ia } from 'lib/helpers/utility';

export default function FormBuilder({ formik, onFormChange, isEdit }) {
  const isEditing = isEdit && ia(formik?.values?.json?.fields);
  return (
    <div className="block">
      <ReactFormBuilder
        onPost={(e) => onFormChange(e.task_data)}
        data={isEditing ? formik?.values?.json.fields : null}
        toolbarItems={FormBuilderToolbar}
      />
    </div>
  );
}
