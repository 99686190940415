import { ia } from 'lib/helpers/utility';

export default function formatFaxData(values, preview = false) {
  const formData = new FormData();
  try {
    delete values.type;
    const faxObject = {
      ...values,
      fax_numbers: [
        ...values.contacts.map((contact) => contact.fax_number),
        ...values.fax_numbers.map((number) => String(number?.fax_number)?.replace(/\D/g, ''))
      ].filter((number) => !!number),
      filesOrder: values.files.map((file) => file.id),
      files: values.files.filter((file) => !file.existingFile),
      existing_files: values.files.filter((file) => file.existingFile)
    };
    delete faxObject.contacts;
    Object.entries(faxObject).forEach(([key, value]) => {
      if (key === 'files') {
        value.forEach((file) => {
          formData.append(key, file);
        });
      } else if (['existing_files', 'filesOrder'].includes(key)) {
        formData.append(key, JSON.stringify(value));
      } else if (key === 'tag_ids') {
        const tagIds = ia(value)
          ? value.map((tag) => (typeof tag === 'object' ? tag.id : tag))
          : value;
        formData.append(key, JSON.stringify(tagIds));
      } else if (!preview) {
        formData.append(key, value);
      }
    });
    return formData;
  } catch (error) {
    console.log({ error });
    return formData;
  }
}
