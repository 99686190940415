import React from 'react';
import EditMacros from './EditMacros';
import Button from 'components/shared/Buttons/Button';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import { useQueryClient } from '@tanstack/react-query';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

const EditMacrosContent = ({ goBack, step, setSteps, selectedRowsData }) => {
  const { setSelectedTableRows } = useChatContextApi();
  const { selectedTableRows } = useChatContextData();
  const queryClient = useQueryClient();
  const onEditMacros = async (values) => {
    const newMacros = Object.values(values).sort(
      (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    );
    const newMacroIds = newMacros.map((macro) => macro.id);
    console.log('🚀 ~ onEditMacros ~ newMacros:', newMacros);
    // const newSelectedTableRows = selectedTableRows?.['macros']?.selectedNodes?.map((node) =>
    //   newMacroIds.includes(node.data.id) ? {...node, }
    // );
    console.log('🚀 ~ EditMacrosContent ~ selectedTableRows:', selectedTableRows);

    // queryClient.setQueryData(['macros'], { macros: newMacros });
    // setSteps((prev) => [
    //   ...prev.slice(0, step),
    //   {
    //     title: 'Preview Macros',
    //     name: 'preview'
    //   }
    // ]);
  };

  return (
    <div className="z-0 flex justify-center">
      <div className="z-0 mb-[120px]">
        <EditMacros
          handleClose={goBack}
          textAreaClassName="h-52"
          macroTitle="Text Macro"
          macros={selectedRowsData}
          setMacros={onEditMacros}
          footer={({ isSubmitting, values }) => (
            <ModalFooter
              className="z-50"
              rightButtons={
                <Button
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  text="Save"
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={() => onEditMacros(values)}
                />
              }
              leftButtons={
                <Button
                  text="Back"
                  outlined
                  transparent
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={goBack}
                />
              }
            />
          )}
        />
      </div>
    </div>
  );
};

export default EditMacrosContent;
