import CustomFormNote from '../CurrentNote/TypeNotes/CustomFormNote/CustomFormNote';

import { iaRa, io, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';

export const processCustomFormTypes = ({ customFormTypes = null, isMacro = false }) => {
  if (!io(customFormTypes)) return null;

  const result = Object.keys(customFormTypes || {}).reduce((acc, key) => {
    acc[key] = iaRa(customFormTypes[key])?.map((item) => ({
      ...item,
      ...(isMacro && { is_macro: true }),
      custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
        item?.form_id || item?.id
      }`
    }));
    return acc;
  }, {});

  return result;
};

export const processCustomNoteTypes = ({ customFormTypes = null }) => {
  if (!io(customFormTypes)) return null;

  const customFormTypesObjects = {
    ...Object.keys(customFormTypes).reduce((acc, key) => {
      acc[key] = CustomFormNote;
      return acc;
    }, {})
  };

  return customFormTypesObjects ?? {};
};

export const generateCustomNoteTypes = ({ customFormTypes = null, cnDisplaySettings }) => {
  if (!io(customFormTypes)) return null;

  const result = Object.keys(customFormTypes || {})
    .map((key) => {
      const setting = cnDisplaySettings?.[key];

      if (!setting || setting?.enabled) {
        return {
          label: snakeToTitleCase(key),
          path: key
        };
      }

      return null;
    })
    .filter(Boolean);

  return result;
};

export const generateAdvancedSectionFormMap = ({
  cnDisplaySettings = null,
  customFormTypes = null
}) => {
  if (!io(cnDisplaySettings) || !io(customFormTypes)) return;

  const advancedSectionFormMap = {};

  Object.keys(customFormTypes ?? {}).forEach((key) => {
    advancedSectionFormMap[key] = cnDisplaySettings?.[key]?.enabled;
  });

  return advancedSectionFormMap;
};
