import React from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

import Icon from 'components/shared/Icon/Icon';

const MyScribeAILink = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { favoriteTemplateId } = useMyScribeAIContext();
  const { id: userId, appointmentId } = useParams();

  const onNavigate = () => {
    if (pathname.includes('myscribe-ai/')) {
      navigate(`/portal/charts/${userId}/clinical-notes/${appointmentId}/myscribe-ai`);
    } else if (pathname.includes('myscribe-ai')) {
      navigate(`/portal/charts/${userId}/clinical-notes/${appointmentId}`);
    }
  };

  return (
    <>
      {pathname.includes('myscribe-ai') ? (
        <div className="flex cursor-pointer select-none items-center" onClick={onNavigate}>
          <Icon icon="chevron-left" color="primary" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#C1EFFF] bg-clip-text pl-1 text-lg font-medium leading-snug text-transparent">
            {pathname.includes('myscribe-ai/')
              ? 'Go Back Clinical Templates'
              : 'Go Back Clinical Note'}
          </div>
        </div>
      ) : (
        <Link
          className="flex cursor-pointer select-none items-center"
          to={`/portal/charts/${userId}/clinical-notes/${appointmentId}/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`}>
          <Icon icon="stars-ai" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
            Go To MyScribe AI
          </div>
        </Link>
      )}
    </>
  );
};

export default MyScribeAILink;
