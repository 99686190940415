import React, { useState } from 'react';
import { PatientChartContext } from './PatientChartContext';
import { useRecoilValue } from 'recoil';
import { userState as user_state } from 'components/state';

export const PatientChartContextProvider = ({ children }) => {
  const userState = useRecoilValue(user_state);
  const tagSettings = userState?.display_settings?.patientChartTags;
  const [togglePinnedSection, setTogglePinnedSection] = useState(tagSettings?.isPinned);
  const [isPinnedConfirmation, setIsPinnedConfirmation] = useState(
    !tagSettings?.showConfirmations?.pin
  );
  const [isUnpinnedConfirmation, setIsUnpinnedConfirmation] = useState(
    !tagSettings?.showConfirmations?.unpin
  );
  const [isRemoveConfirmation, setIsRemoveConfirmation] = useState(
    !tagSettings?.showConfirmations?.remove
  );
  const [openReorderModal, setOpenReorderModal] = useState(false);

  return (
    <PatientChartContext.Provider
      value={{
        togglePinnedSection,
        setTogglePinnedSection,
        isUnpinnedConfirmation,
        setIsUnpinnedConfirmation,
        isRemoveConfirmation,
        setIsRemoveConfirmation,
        isPinnedConfirmation,
        setIsPinnedConfirmation,
        openReorderModal,
        setOpenReorderModal
      }}>
      {children}
    </PatientChartContext.Provider>
  );
};
