import React from 'react';
import { NavLink } from 'react-router-dom';
import commsCenterTabs from './commsCenterTabs';
import { useUnreadFaxes } from 'lib/hooks/queries/fax/useUnreadFaxes';

const CommsCenterSidebarList = ({ expanded }) => {
  const { data = {} } = useUnreadFaxes();

  if (expanded) {
    return (
      <div>
        {commsCenterTabs.map((group) => (
          <div className="!pt-6 first:!pt-0" key={group.path}>
            <span className="!pb-2 pl-[12px] text-[10px] uppercase tracking-widest text-neutral-500">
              {group.name}
            </span>
            <div className="flex flex-col">
              {group.children.map((item) => {
                return (
                  <NavLink
                    data-qa={`${item.name}`}
                    name={item.name}
                    key={item.path}
                    end={item.end ? item.end : false}
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? 'm-0  flex cursor-pointer items-center justify-between gap-2 rounded-[4px] bg-primary-50 !px-3 !py-2 text-sm leading-5 text-primary-500 hover:text-primary-500'
                        : 'm-0  flex cursor-pointer items-center justify-between gap-2 rounded-[4px] bg-white !px-3 !py-2 text-sm leading-5 text-neutral-800  hover:!bg-neutral-100 hover:text-neutral-800'
                    }>
                    {item.name}
                    {item.name == 'Inbound Faxes' && data.unread > 0 && (
                      <span className="flex h-[20px] w-[20px] font-600 items-center justify-center rounded-full bg-red-100 text-red-500 text-xxs">
                        {data.unread}
                      </span>
                    )}
                    {item.name == 'NewInbound Faxes' && data.unread > 0 && (
                      <span className="flex h-[20px] w-[20px] font-600 items-center justify-center rounded-full bg-red-100 text-red-500 text-xxs">
                        {data.unread}
                      </span>
                    )}
                  </NavLink>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return null;
  }
};

export default CommsCenterSidebarList;
