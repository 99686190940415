import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPracticePatientLinks } from 'api/Practice';

export const usePracticePatientLinks = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['practicePatientLinks', ...dependencies],
    () => getPracticePatientLinks(params, navigate),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
