export const attachTypeOptions = [
  { value: '', label: 'No Paperwork (PWK)' },
  { value: 'AS', label: 'Admission Summary (AS)' },
  { value: 'A3', label: 'Allergies/Sensitivities Document (A3)' },
  { value: 'AM', label: 'Ambulance Certification (AM)' },
  { value: 'A4', label: 'Autopsy Report (A4)' },
  { value: 'BS', label: 'Baseline (BS)' },
  { value: 'BR', label: 'Benchmark Testing Results (BR)' },
  { value: 'BT', label: 'Blanket Test Results (BT)' },
  { value: 'CT', label: 'Certification (CT)' },
  { value: '13', label: 'Certified Test Report (13)' },
  { value: '11', label: 'Chemical Analysis (11)' },
  { value: 'CB', label: 'Chiropractic Justification (CB)' },
  { value: 'CK', label: 'Consent Form(s) (CK)' },
  { value: '10', label: 'Continued Treatment (10)' },
  { value: 'V5', label: 'Death Notification (V5)' },
  { value: 'DA', label: 'Dental Models (DA)' },
  { value: 'DG', label: 'Diagnostic Report (DG)' },
  { value: 'DJ', label: 'Discharge Monitoring Report (DJ)' },
  { value: 'DS', label: 'Discharge Summary (DS)' },
  { value: 'D2', label: 'Drug Profile Document (D2)' },
  { value: '04', label: 'Drugs Administered (04)' },
  { value: 'DB', label: 'Durable Medical Equipment Prescription (DB)' },
  { value: 'EB', label: 'Explanation of Benefits (EB)' },
  { value: '07', label: 'Functional Goals (07)' },
  { value: 'HC', label: 'Health Certificate (HC)' },
  { value: 'HR', label: 'Health Clinic Records (HR)' },
  { value: 'I5', label: 'Immunization Record (I5)' },
  { value: '06', label: 'Initial Assessment (06)' },
  { value: '15', label: 'Justification for Admission (15)' },
  { value: 'LA', label: 'Laboratory Results (LA)' },
  { value: 'M1', label: 'Medical Record Attachment (M1)' },
  { value: 'MT', label: 'Models (MT)' },
  { value: 'NN', label: 'Nursing Notes (NN)' },
  { value: 'OE', label: 'Objective Physical Examination (OE)' },
  { value: 'OB', label: 'Operative Note (OB)' },
  { value: 'OD', label: 'Orders and Treatments Document (OD)' },
  { value: 'OC', label: 'Oxygen Content Averaging Report (OC)' },
  { value: 'OX', label: 'Oxygen Therapy Certification (OX)' },
  { value: 'PQ', label: 'Paramedical Results (PQ)' },
  { value: 'PE', label: 'Parenteral or Enteral Certification (PE)' },
  { value: 'P4', label: 'Pathology Report (P4)' },
  { value: 'P5', label: 'Patient Medical History Document (P5)' },
  { value: 'XP', label: 'Photographs (XP)' },
  { value: 'PZ', label: 'Physical Therapy Certification (PZ)' },
  { value: 'PN', label: 'Physical Therapy Notes (PN)' },
  { value: 'B3', label: 'Physician Order (B3)' },
  { value: 'PY', label: 'Physicians Report (PY)' },
  { value: '08', label: 'Plan of Treatment (08)' },
  { value: 'B2', label: 'Prescription (B2)' },
  { value: '09', label: 'Progress Report (09)' },
  { value: 'PO', label: 'Prosthetics or Orthotic Certification (PO)' },
  { value: 'RB', label: 'Radiology Films (RB)' },
  { value: 'RR', label: 'Radiology Reports (RR)' },
  { value: '21', label: 'Recovery Plan (21)' },
  { value: 'B4', label: 'Referral Form (B4)' },
  { value: 'RX', label: 'Renewable Oxygen Content Averaging Report (RX)' },
  { value: '03', label: 'Report Justifying Treatment Beyond Utilization Guidelines (03)' },
  { value: 'RT', label: 'Report of Tests and Analysis Report (RT)' },
  { value: 'IR', label: 'State School Immunization Records (IR)' },
  { value: 'OZ', label: 'Support Data for Claim (OZ)' },
  { value: 'SG', label: 'Symptoms Document (SG)' },
  { value: '05', label: 'Treatment Diagnosis (05)' }
];
