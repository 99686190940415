import React from 'react';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const GetStarted = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="py-[40px] text-center ">
        <h5 className="pb-3 text-[24px] font-500 text-primary-500">Let’s Get Started</h5>
        <p className="text-[16px] font-500 text-primary-900">
          Please select a title for your Form Packet.
        </p>
      </div>
    </ErrorBoundary>
  );
};

export default withErrorBoundary(GetStarted);
