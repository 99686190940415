import React from 'react';
import Tippy from '@tippyjs/react';
import NotFound from 'components/practice/Dashboard/components/NotFound';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import { iaRa, io } from 'lib/helpers/utility';
import { TYPE_OF_NOTES } from '../../../../constants';
import CustomFormsAI from '../../../../components/forms/CUSTOM/CustomFormsAI';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

const List = ({ type }) => {
  const { setSelectedChatComponents, componentRefs } = useMyTemplateContext();
  const { typeOfNotes } = useMyScribeAIContext();
  const currentComponents = typeOfNotes?.[type]?.components || {};

  const propsForNotFoundNote = {
    icon: 'new-notev1',
    iconSize: 32,
    title: 'No data',
    titleClassName: '!mt-3',
    description: 'No data in display settings'
  };

  const onSelect = (e, item) => {
    e.stopPropagation();
    const { checked = false } = e.target;

    setSelectedChatComponents((prevSelected) => {
      if (checked) {
        const note_item = TYPE_OF_NOTES?.find((note) => note.type === type);
        return [
          ...iaRa(prevSelected),
          {
            title: item?.path,
            forms: note_item?.forms || CustomFormsAI,
            label: note_item?.label || note_item?.title || '',
            type
          }
        ];
      } else {
        return prevSelected.filter((selectedItem) => selectedItem.title !== item.path);
      }
    });

    const chat = document.querySelector('#my-template-chat > div > div.flex');
    if (chat) {
      chat.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const scrollToComponent = (componentTitle) => {
    const componentRef = componentRefs.current[componentTitle];
    if (componentRef) {
      componentRef.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className=" py-[6px] !pr-2 pl-[10px]">
      {io(currentComponents) ? (
        Object.values(currentComponents)?.map((item) => (
          <Tippy content={item?.title} placement="right" className="tippy-dark" key={item.title}>
            <div
              className="flex cursor-pointer items-center rounded-md p-2 shadow-md"
              onClick={() => scrollToComponent(item.path)}>
              <Checkbox
                onChange={(e) => onSelect(e, item)}
                color="blue"
                label={item?.title}
                // disabled={cnSaving}
                value={item.title}
                checked={item?.checked}
                data-qa={`left-sidebar-checkbox-${item.title}`}
              />
            </div>
          </Tippy>
        ))
      ) : (
        <NotFound {...propsForNotFoundNote} />
      )}
    </div>
  );
};

export default List;
