import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from 'api/InterimApi';
import patientState from '../patientState';
import ConversationBox from './ConversationBox';

export default function Inbox() {
  const [messages, setMessagesState] = useRecoilState(patientState.messages);
  const [practice_id, setPracticeId] = useRecoilState(patientState.conversation_practice_id);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await interimApi('/api/messages/inbox', {}, navigate);
      const { redirect, messages: loadedMessages } = res.data;
      if (res.data.messages) {
        setMessagesState({
          all_messages: loadedMessages
        });
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const handleClick = (practice_id) => {
    setPracticeId(practice_id);
    navigate(`/myportal/inbox/${practice_id}`);
  };

  return (
    <div className="p-12 grid grid-cols-3 gap-4 sm:grid-cols-2 xs:grid-cols-1">
      {messages.all_messages &&
        messages.all_messages.map((message, index) => {
          return (
            <ConversationBox
              key={index}
              message={message}
              onclick={() => handleClick(message.practice_id)}
            />
          );
        })}
    </div>
  );
}
