import React from 'react';

import Icon from 'components/shared/Icon/Icon';

const ResetSettingsButton = ({ onReset = () => {} }) => {
  const resetSettings = () => {
    onReset();
  };

  return (
    <div className="p-2 hover:!bg-neutral-50 rounded">
      <Icon icon="new-reset" onClick={resetSettings} />
    </div>
  );
};

export default ResetSettingsButton;
