import React from 'react';
import Accordion from '../../../../shared/Accordion';
import ProblemManifestationDefinition from './ProblemManifestationDefinition';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const ProblemManifestationDefinitionSection = ({ components, sectionRef, options }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.problemManifestationDefinition?.title}
      id={components?.problemManifestationDefinition?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <ProblemManifestationDefinition sectionRef={sectionRef} />
    </Accordion>
  );
};

export default ProblemManifestationDefinitionSection;
