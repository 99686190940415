export const getFirstName = (item) => {
  if (item.firstName || item.f_name) {
    return item.firstName || item.f_name;
  }
  if (item.fullName) {
    return item.fullName.split(' ')[0];
  }
  return '';
};

export const getLastName = (item) => {
  if (item.lastName || item.l_name) {
    return item.lastName || item.l_name;
  }
  if (item.fullName) {
    const nameParts = item.fullName.split(' ');
    return nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';
  }
  return '';
};
