import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from '../../../../../../../lib/helpers/utility';
// Components
import ClinicalSocialHistory from 'components/shared/Forms/Custom/SocialHistory/ClinicalSocialHistory';
import ActiveSymptoms from './ActiveSymptoms';
import AdvancedForm from './AdvancedForm';
import Allergies from './Allergies';
import Assessment from './Assessment';
import Chart3d from './Chart3d';
import Complaints from './Complaints';
import CPTCodes from './CPTCodes';
import Diagnosis from './Diagnosis';
import Exam from './Exam';
import FamilyHistory from './FamilyHistory';
import FunctionalImpairment from './FunctionalImpairment';
import Goals from './Goals';
import HPIRevisions from './HPIRevisions';
import Interventions from './Interventions';
import LabOrImagingOrders from './LabOrImagingOrders';
import MedicalHistory from './MedicalHistory';
import Medications from './Medications';
import MyScribeExportNote from './MyScribeExportNote';
import NonERXPrescription from './NonERXPrescription';
import Notes from './Notes';
import Objective from './Objective';
import PatientNeeds from './PatientNeeds';
import Plan from './Plan';
import ProblemManifestationDefinition from './ProblemManifestationDefinition';
import Progress from './Progress';
import ProviderSignature from './ProviderSignature';
import ReferralOrders from './ReferralOrder';
import ReviewOfSystems from './ReviewOfSystems';
import RiskCoMorbidities from './RiskCoMorbidities';
import RiskFactors from './RiskFactors';
import Session from './Session';
import SessionQuotesAndContents from './SessionQuotesAndContents';
import SocialHistory from './SocialHistory';
import Subjective from './Subjective';
import SurgicalHistory from './SurgicalHistory';
import Test from './Test';
import TimeFrames from './TimeFrames';
import Vaccines from './Vaccines';
import Vitals from './Vitals';
import WeightLossAdministration from './WeightLossAdministration';

const componentMapping = {
  complaints: (props) => <Complaints {...props} />,
  patientNeeds: (props) => <PatientNeeds {...props} />,
  problemManifestationDefinition: (props) => <ProblemManifestationDefinition {...props} />,
  chart3d: (props) => <Chart3d {...props} />,
  hpi: (props) => <HPIRevisions {...props} />,
  diagnosisCodes: (props) => <Diagnosis {...props} />,
  ros: (props) => <ReviewOfSystems {...props} />,
  goals: (props) => <Goals {...props} />,
  exam: (props) => <Exam {...props} />,
  test: (props) => <Test {...props} />,
  session: (props) => <Session {...props} />,
  riskFactors: (props) => <RiskFactors {...props} />,
  sessionQuotesAndContents: (props) => <SessionQuotesAndContents {...props} />,
  activeSymptoms: (props) => <ActiveSymptoms {...props} />,
  functionalImpairment: (props) => <FunctionalImpairment {...props} />,
  interventions: (props) => <Interventions {...props} />,
  progress: (props) => <Progress {...props} />,
  subjective: (props) => <Subjective {...props} />,
  objective: (props) => <Objective {...props} />,
  assessment: (props) => <Assessment {...props} />,
  plan: (props) => <Plan {...props} />,
  allergiesConditions: (props) => <Allergies {...props} />,
  allergies: (props) => <Allergies {...props} />,
  medications: (props) => <Medications {...props} />,
  timeFrames: (props) => <TimeFrames {...props} />,
  riskAndCoMorbidities: (props) => <RiskCoMorbidities {...props} />,
  vitals: (props) => <Vitals {...props} />,
  vaccines: (props) => <Vaccines {...props} />,
  weightLossAdministration: (props) => <WeightLossAdministration {...props} />,
  socialHistory: (props) => <SocialHistory {...props} />,
  // Needs to be fixed
  // location?.pathname.includes('/checkin') ? (
  //   <SocialHistory {...props} />
  // ) : (
  //   <ClinicalSocialHistory {...props} />
  // ),
  medicalHistory: (props) => <MedicalHistory {...props} />,
  familyHistory: (props) => <FamilyHistory {...props} />,
  surgicalHistory: (props) => <SurgicalHistory {...props} />,
  cptCodes: (props) => <CPTCodes {...props} />,
  referralOrders: (props) => <ReferralOrders {...props} />,
  nonERXPrescriptionOrders: (props) => <NonERXPrescription {...props} />,
  labOrImagingOrders: (props) => <LabOrImagingOrders {...props} />,
  myscribe: (props) => <MyScribeExportNote {...props} />,
  notes: (props) => <Notes {...props} />,
  providerSignature: (props) => <ProviderSignature {...props} />
};

const matchingAdvancedForm = ({ customFormTypes, currentAdvForm }) => {
  for (const [_, value] of Object.entries(customFormTypes || {})) {
    const findMatchingForm = value.find(
      (item) =>
        item.form &&
        (item.custom_title === currentAdvForm.key ||
          item.form.name === currentAdvForm?.title ||
          item.name === currentAdvForm?.title)
    );

    if (findMatchingForm) {
      return findMatchingForm;
    }
  }

  return undefined;
};

const RenderedComponents = ({
  checkedRendered,
  hpForms,
  soapForms,
  advancedForms,
  customClinicalNote
}) => {
  const { advancedHP, advancedSOAP, customFormTypes } =
    customClinicalNote || useClinicalNoteContext();

  if (!ia(checkedRendered)) return null;

  const processAdvancedHpForm = ({ currentHpForm }) => {
    const matchingAdvancedHP = advancedHP?.find(
      (item) =>
        item.form &&
        (item.custom_title === currentHpForm.key ||
          item.form.name === currentHpForm.title ||
          item.name === currentHpForm.title)
    );

    return { ...matchingAdvancedHP, checkedFormTypes: currentHpForm?.checkedFormTypes } || null;
  };

  const processSOAPForm = ({ currentSOAPForm }) => {
    const matchingAdvancedSOAP = advancedSOAP?.find(
      (item) =>
        item.form &&
        (item.custom_title === currentSOAPForm.key ||
          item.form.name === currentSOAPForm.title ||
          item.name === currentSOAPForm.title)
    );

    return { ...matchingAdvancedSOAP, checkedFormTypes: currentSOAPForm?.checkedFormTypes } || null;
  };
  const processAdvancedForm = ({ currentAdvForm }) => {
    const result = matchingAdvancedForm({ currentAdvForm, customFormTypes });

    return { ...result, checkedFormTypes: currentAdvForm?.checkedFormTypes } || null;
  };

  return iaRa(checkedRendered).map((obj, index) => {
    const Component = componentMapping[obj.key];

    if (Component) {
      return (
        <div key={index} className="break-normal">
          <Component
            key={index}
            checkedFormTypes={obj.checkedFormTypes}
            customClinicalNote={customClinicalNote}
          />
        </div>
      );
    }

    const currentHpForm = hpForms?.find(
      (item) => item.key === obj.key && item.advancedType === 'hp'
    );
    if (currentHpForm) {
      return <AdvancedForm key={index} currentForm={processAdvancedHpForm({ currentHpForm })} />;
    }

    const currentSOAPForm = soapForms?.find(
      (item) => item.key === obj.key && item.advancedType === 'soap'
    );
    if (currentSOAPForm) {
      return <AdvancedForm key={index} currentForm={processSOAPForm({ currentSOAPForm })} />;
    }

    const currentAdvForm = advancedForms?.find(
      (item) => item.key === obj.key && item.advancedType === 'customForm'
    );
    if (currentAdvForm) {
      return <AdvancedForm key={index} currentForm={processAdvancedForm({ currentAdvForm })} />;
    }

    return null;
  });
};

export default RenderedComponents;
