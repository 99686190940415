import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import cs from 'classnames';
import EmptyProductsPackages from 'components/Patient/sell/EmptyProductsPackages';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { permissions, userState as user_state } from 'components/state';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { arrayToObject, ia, mString } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NewItemCounter, SelectedItems, ServicesFromPackages } from './components';
import { addNewItems, handleAddItems, isProcedureChecked } from './lib';
import { getPopoverPosition } from './lib/getPopoverPosition';

const AddProceduresProducts = ({ label, list, type, packageList = [], formik }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};
  const { values, errors, touched, setFieldValue } = formik;
  const selectedItems = [...(values[type] || [])];
  const [newItems, setNewItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const userState = useRecoilValue(user_state);
  const hasPaymentPermission = useRecoilValue(permissions)?.payments?.read;
  const isDetailsOpen = userState?.display_settings?.appointment?.details?.isOpen;
  const { device } = useUIContext();

  const filteredData = list?.filter?.((item) =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const servicesObject = useMemo(() => arrayToObject(list, 'id', 'image'), [list]);

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength
    });
  };

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  const isServices = type === 'procedures';

  // Texts for empty state
  const title = isServices ? 'No services' : 'No products';
  const link = `/portal/settings/${isServices ? 'services' : 'products'}`;
  const buttonText = isServices ? 'Create a service' : 'Create a product';
  const icon = isServices ? 'new-search-services-illustration' : 'new-no-products-illustration';
  const startDesc = isServices ? 'Start adding services now!' : 'Start adding products now!';
  const createDesc = isServices ? 'Start creating services now!' : 'Start creating products now!';

  if (!ia(list))
    // If practice has no services or products
    return (
      <NotFound
        icon={icon}
        title={title}
        buttonText={buttonText}
        description={createDesc}
        onClick={() => navigate(link)}
        textWrapperClassName={isServices && '!-mt-4'}
        className="!pb-2"
      />
    );

  return (
    <div ref={ref}>
      {!ia(selectedItems) ? (
        <NotFound
          icon={icon}
          title={title}
          description={startDesc}
          textWrapperClassName={isServices && '!-mt-4'}
          className="!pb-2"
        />
      ) : (
        <SelectedItems
          type={type}
          label={label}
          values={values}
          setFieldValue={setFieldValue}
          selectedItems={selectedItems}
          servicesObject={servicesObject}
          list={type === 'products' ? list : []}
          isClinicalNoteLocked={isClinicalNoteLocked}
        />
      )}

      <Popover className="relative">
        {({ open, close }) => (
          <>
            <PopoverButton
              className={cs('w-full cursor-pointer p-0')}
              data-qa={`add-btn-type-${type}`}
              onClick={() => {
                open && handleAddItemsFunc();
                !values?.id && !open && setTimeout(() => scrollToElement(), 100);
              }}>
              <div
                className={cs(
                  'flex items-center gap-[6px]',
                  !ia(selectedItems) && '!justify-center pb-3'
                )}>
                <Icon icon="add-circle" size={18} />
                <label className="cursor-pointer text-sm font-500 text-primary-500">
                  {`Add ${label}`}
                </label>
              </div>
            </PopoverButton>
            <PopoverPanel
              className={cs(
                'absolute z-20 mt-[6px] flex w-full flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]',
                getPopoverPosition({ device, isDetailsOpen, type })
              )}>
              <div>
                {isServices && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}

                {ia(list) && (
                  <Input
                    icon="search"
                    rounded="full"
                    value={searchTerm}
                    id={`search-${type}`}
                    data-qa={`search-${label}`}
                    placeholder={`Search ${label}`}
                    rightIcon={searchTerm && 'new-close'}
                    rightIconClick={() => setSearchTerm('')}
                    onChange={(e) => setSearchTerm(e?.target?.value)}
                    className="sticky top-0 z-10 w-full bg-white p-3"
                  />
                )}

                <div className="px-3">
                  <p className="text-xs font-500 leading-5 text-neutral-500">{label}</p>

                  {ia(filteredData) ? (
                    <div className="h-[310px] overflow-y-auto overflow-x-hidden">
                      {filteredData?.map((item, index) => {
                        const outOfStock = !isServices && item?.quantity === 0;

                        return (
                          <div
                            className={cs(
                              'mr-[2px] mt-[2px] flex !min-h-[34px] items-center justify-between rounded-md p-1 first-of-type:mt-0',
                              !!isProcedureChecked(newItems, item) && 'bg-primary-50'
                            )}
                            key={item?.id}>
                            <Checkbox
                              id={index}
                              disabled={outOfStock}
                              onBlur={formik.handleBlur}
                              label={item?.name + (outOfStock ? ' (Out of stock)' : '')}
                              labelTextColor={cs('leading-4 ', outOfStock && '!text-danger-600')}
                              handleClick={() =>
                                addNewItems({ newItem: item, newItems, setNewItems })
                              }
                              isChecked={
                                isServices
                                  ? !!isProcedureChecked(newItems, item)
                                  : newItems.some((sItem) => sItem.id === item.id)
                              }
                            />

                            <div className="flex items-center !gap-2">
                              <NewItemCounter
                                newItem={item}
                                newItems={newItems}
                                setNewItems={setNewItems}
                              />

                              {type === 'products' && hasPaymentPermission && (
                                <p className="w-14 text-xs text-neutral-500">
                                  {mString(item?.amount_cents)}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : type === 'products' ? (
                    <EmptyProductsPackages type="product" />
                  ) : null}
                </div>
                <div className="flex items-center justify-between p-2 px-3 shadow-[0px_0px_16px_rgba(0,0,0,0.05)]">
                  <Button text="Close" size="small" outlined color="neutral" onClick={close} />
                  <Button
                    text="Done"
                    size="small"
                    onClick={() => {
                      handleAddItemsFunc();
                      close();
                    }}
                  />
                </div>
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>

      {isServices && touched?.procedures && errors?.procedures && (
        <p className="!pt-2 text-sm text-danger-500">{errors?.procedures}</p>
      )}

      {type === 'products' && ia(additionalTimes) && ia(values?.products) && (
        <p className="text-sm text-warning-500">
          Products are only available for the current appointment.
        </p>
      )}
    </div>
  );
};

export default AddProceduresProducts;
