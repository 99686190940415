import React from 'react';
import TableSelection from './TableSelection';

const Step = ({ stepName }) => {
    return (
        <div>
            <TableSelection table={stepName} />
        </div>
    );
};

export default Step;
