module.exports = {
  priorities: [
    { value: '0', label: 'High' },
    { value: '1', label: 'Medium' },
    { value: '2', label: 'Low' }
  ],
  statuses: [
    { value: 'requested', label: 'Requested' },
    { value: 'in progress', label: 'In progress' },
    { value: 'completed', label: 'Completed' }
  ]
};
