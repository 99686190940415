import React, { useEffect, useRef, useState } from 'react';
import { isEmpty, orderBy } from 'lodash';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { getStates } from 'api/State';
import { formatDate, ia } from 'lib/helpers/utility';
import { diagnosisPointers } from 'constants.js';
import { getPatientSuperbills } from 'api/Superbill';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { createClaim as createClaimApi } from 'api/Billing';
import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import PatientInfo from 'components/practice/charts/SuperBillPage/components/PatientInfo';
import ClaimErrors from 'components/practice/charts/SuperBillPage/components/ClaimErrors';
import SuperbillFooter from 'components/practice/charts/SuperBillPage/components/SuperbillFooter';
import ServicesAndDiagnosis from 'components/practice/charts/SuperBillPage/components/ServicesAndDiagnosis';
import { FacilityInformation } from 'components/practice/charts/SuperBillPage/components/FacilityInformation';
import SuperbillHeaderActions from 'components/practice/charts/SuperBillPage/components/SuperbillHeaderActions';
import {
  calculateTotalCharge,
  claimValidationSchema,
  getClaimFromSuperbillWhenNoClaim,
  getClaimInfoFromSuperbill,
  getInitialProcedure,
  getPlaceOfService,
  initialValues
} from 'components/practice/charts/SuperBillPage/lib/index';
import {
  formatProcedureModifiers,
  reshapeProceduresWithoutClaim
} from 'components/practice/charts/SuperBillPage/lib/reshapeProcedures';

function SuperbillPage({ superbill_id, patientId }) {
  const navigate = useNavigate();
  const practice = useRecoilValue(currentPractice);
  const [superbill, setSuperbill] = useState();
  const [showErrors, setShowErrors] = useState(false);
  const [states, setStates] = useState();
  const queryClient = useQueryClient();
  const [procedures, setProcedures] = useState(getInitialProcedure(practice));
  const [patient, setPatient] = useState({});
  const [loading, setLoading] = useState({ export: false });
  const exportRef = useRef();
  const params = useParams();

  const patient_id = params?.id || patientId;
  const superbillId = params?.superbill || superbill_id;

  const formik = useFormik({
    initialValues: initialValues(patient_id),
    validationSchema: claimValidationSchema,
    onSubmit: () => {
      setShowErrors(false);
      createClaim();
    }
  });
  const { values: claim, errors, handleSubmit, setFieldValue } = formik;

  useEffect(() => {
    setFieldValue('procedures', procedures);
    setFieldValue('total_charge', calculateTotalCharge(procedures));
  }, [procedures]);

  useEffect(() => {
    getStatesMethod().then((res) => {
      if (res) {
        getSuperbill();
      }
    });
  }, []);

  useEffect(() => {
    if (superbill) {
      if (superbill?.claim) {
        const claimFieldsToUpdated = getClaimInfoFromSuperbill({
          superbill,
          states
        });

        Object.entries(claimFieldsToUpdated).forEach(([fieldName, value]) => {
          setFieldValue(fieldName, value);
        });

        const procedureClaim = reshapeProceduresWithoutClaim({
          superbill,
          diagnosisPointers,
          practice
        });

        const dos = formatDate(superbill?.encounter?.appointment?.starts_at, practice?.timezone);

        setProcedures(
          ia(procedureClaim)
            ? orderBy(procedureClaim, 'created_at')
            : [
                {
                  procedure_code: '',
                  modifiers: [],
                  charge: 0,
                  diagnosis: [],
                  from_date: dos,
                  thru_date: dos,
                  narrative: '',
                  units: 1,
                  place_of_service: getPlaceOfService(superbill, practice)
                }
              ]
        );

        setFieldValue('total_charge', calculateTotalCharge(procedureClaim));
      } else {
        const claimFieldsToUpdated = getClaimFromSuperbillWhenNoClaim({
          claim,
          superbill,
          states
        });

        Object.entries(claimFieldsToUpdated).forEach(([fieldName, value]) => {
          setFieldValue(fieldName, value);
        });

        const procedureArray = reshapeProceduresWithoutClaim({
          superbill,
          diagnosisPointers,
          practice
        });

        setFieldValue('total_charge', calculateTotalCharge(procedureArray));
        setProcedures(procedureArray);
      }
      setPatient(superbill.patient);
    }
  }, [superbill]);

  const createClaim = async () => {
    let params = {
      newClaim: {
        ...claim,
        procedures: formatProcedureModifiers({ procedures }),
        state: 'ready'
      },
      superbillId: superbill.id
    };
    let res = await createClaimApi(navigate, params);
    if (res.code == 0) {
      showAlert({
        color: 'success',
        message: 'Superbill is ready for claim'
      });
      queryClient.resetQueries(['patient_superbill']);
      queryClient.resetQueries(['claims']);
      queryClient.resetQueries(['claim']);
      await getSuperbill();
    }
  };

  const getStatesMethod = async () => {
    let res = await getStates(navigate);
    setStates(res);
    return res;
  };

  const getSuperbill = async () => {
    let params = {
      id: superbillId
    };
    let res = await getPatientSuperbills(navigate, params);

    if (!isEmpty(res.superbill)) {
      setSuperbill(res.superbill);
    } else {
      navigate(`/portal/charts/${patient_id}/billing/superbill`);
    }
  };

  const handleSubmitClaim = async () => {
    setFieldValue('total_charge', calculateTotalCharge(procedures)).then(() => {
      if (Object.keys(errors).length) {
        setShowErrors(true);
        scrollToFooter();
      }
      handleSubmit();
    });
  };

  const scrollToFooter = () => {
    const footer = document.getElementById('superbill-page-footer');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth', block: 'end' });
      showAlert({
        title: 'Claim errors!',
        message: 'Please scroll down to see the errors and correct them.',
        color: 'danger'
      });
    }
  };
  return (
    <div className="h-full w-full overflow-auto">
      {superbill ? (
        <div className="!px-4 !py-6 print:!bg-[#fff]" ref={exportRef}>
          <SuperbillHeaderActions {...{ exportRef, loading, setLoading, superbill }} />
          {claim?.state === 'rejected' && (
            <div className="grid w-full gap-1">
              {claim?.errors?.map((error, index) => {
                return <AlertContent key={index} message={error.message} color="danger" />;
              })}
            </div>
          )}
          <PatientInfo
            {...{
              formik,
              states,
              patient,
              superbill
            }}
          />
          <FacilityInformation {...{ formik, states, superbill }} />
          <ServicesAndDiagnosis
            {...{
              formik,
              handleSubmitClaim,
              procedures,
              setProcedures,
              superbill,
              states
            }}
          />
          {showErrors && Object.keys(errors).length > 0 && <ClaimErrors errors={errors} />}
        </div>
      ) : (
        <div className="!rounded-lg bg-white p-12">
          <Skeleton count={2} height="290px" />
        </div>
      )}
      <SuperbillFooter />
    </div>
  );
}

export default withErrorBoundary(SuperbillPage);
