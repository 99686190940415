import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { readPayers } from 'api/Payer';

import { usePayers } from 'lib/hooks/queries/payers/usePayers';
import useModal from 'lib/hooks/useModal';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import HeaderButton from './components/HeaderButton';
import PayerDetails from './components/PayerDetails';
import PayerModal from './components/PayerModal';
import { DEFAULT_FILTERS, defaultColumns } from './lib/helper';

const Payers = () => {
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModal();

  const selectInputRef = useRef(null);

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        const res = await readPayers(navigate, { searchTerm });
        if (res.payers) {
          return res.payers.map((payer) => {
            return { value: payer.id, label: payer.name };
          });
        }
      } catch (error) {
        console.error(error);
        showAlert({
          title: 'Error!',
          message: error ?? 'There was an error getting all payers.',
          color: 'danger'
        });
      }
    }
  };

  return (
    <>
      <AgTablePage
        defaultColumns={defaultColumns}
        defaultFilters={DEFAULT_FILTERS}
        useData={usePayers}
        name="payers"
        detailCellRenderer={PayerDetails}
        masterDetail={true}
        headerActions={
          <HeaderButton
            openModal={openModal}
            searchPayers={searchPayers}
            selectInputRef={selectInputRef}
          />
        }
      />
      {isOpen && (
        <PayerModal isOpen={isOpen} handleClose={closeModal} selectInputRef={selectInputRef} />
      )}
    </>
  );
};

export default withErrorBoundary(Payers);
