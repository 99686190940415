import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import useModal from 'lib/hooks/useModal';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popup from 'components/shared/Popup/Popup';

import constant from '../lib/constant';

import TerminalModal from './TerminalModal';

export const Actions = ({ data }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const [modalType, setModalType] = useState(null);
  const [deletionModal, setDeletionModal] = useState(false);
  const queryClient = useQueryClient();

  const triggerEditModal = (type) => {
    setModalType(type);
    openModal();
  };

  const navigate = useNavigate();

  const updateTerminal = async (newStatus = constant.deleted) => {
    try {
      const res = await requestApi({
        url: '/api/transactions/terminal/update',
        params: {
          changes: (() => {
            const { checks: _checks, terminalResponse: _terminalResponse, ...rest } = data;
            return { ...rest, status: newStatus };
          })()
        },
        navigate
      });

      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          showAlert({
            title: `Deleted terminal ${data.id}`,
            color: 'success'
          });
          await queryClient.invalidateQueries(['terminal_admin']);
          break;
        default:
          Honeybadger.notify(
            `file: /terminals/terminalEntry, method: saveChanges - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/terminalEntry, method: saveChanges - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const changeTerminalActivity = async () => {
    try {
      const res = await requestApi({
        url: '/api/transactions/terminal/update',
        params: {
          changes: (() => {
            const { checks: _checks, terminalResponse: _terminalResponse, ...rest } = data;
            return {
              ...rest,
              active: !data?.active,
              status: !data?.active ? 'active' : 'inactive'
            };
          })()
        },
        navigate
      });

      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          showAlert({
            title: data?.active
              ? `Terminal ${data?.id} Deactivated`
              : `Terminal ${data?.id} Activated`,
            color: 'success'
          });
          await queryClient.invalidateQueries(['terminal_admin']);
          break;
        default:
          Honeybadger.notify(
            `file: /terminals/terminalEntry, method: saveChanges - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/terminalEntry, method: saveChanges - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  return (
    <>
      <Confirm
        isOpen={deletionModal}
        title={`Delete Terminal ${data?.id}`}
        message="Are you sure you want to delete this terminal?"
        variant="danger"
        icon="trash"
        handleClose={() => setDeletionModal(false)}
        handleContinue={() => {
          updateTerminal(constant.deleted);
          setDeletionModal(false);
        }}
      />
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {() => (
            <div className=" !py-[6px]">
              <div
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  triggerEditModal('edit');
                }}>
                <div className="ml-1 text-sm text-primary-900">Edit</div>
              </div>
              <div
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  setDeletionModal(true);
                }}>
                <div className="ml-1 text-sm text-primary-900">Delete</div>
              </div>
              <div
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  changeTerminalActivity();
                }}>
                <div className="ml-1 text-sm text-primary-900">
                  {data?.active ? 'Deactivate' : 'Activate'}
                </div>
              </div>
              {data.status !== constant.temporaryDeactivation && (
                <div
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    updateTerminal(constant.temporaryDeactivation);
                  }}>
                  <div className="ml-1 text-sm text-primary-900">Temporarily Deactivate</div>
                </div>
              )}
            </div>
          )}
        </Popup>
        <TerminalModal item={data} isOpen={isOpen} type={modalType} handleClose={closeModal} />
      </div>
    </>
  );
};

export default Actions;
