import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { usePatientLedger } from 'lib/hooks/queries/ledger/usePatientLedger';

import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import Allowed from 'components/shared/Permissions/Allowed';

import { DEFAULT_FILTERS, getFinalColDefs, getPatientDefaultFilters } from './configs';

const PatientLedger = ({ name, patient: patientProp }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice?.timezone;
  const claims = practice?.display_settings?.claims;

  const chartContext = useOutletContext();
  const patient = chartContext?.patient || patientProp;

  const columnDefs = useMemo(
    () => getFinalColDefs(name, claims, practiceTimezone),
    [name, claims, practiceTimezone]
  );
  const initialFilters = useMemo(
    () => (name === 'patient_ledger' ? DEFAULT_FILTERS : getPatientDefaultFilters()),
    [name]
  );

  return (
    <Allowed requiredPermissions="payments.read" showMessage={true} showIllustration={true}>
      <AgTablePage
        defaultColumns={columnDefs}
        defaultFilters={initialFilters}
        useData={usePatientLedger}
        name="ledgers"
        title="Patient Ledger"
        noTitle={!!patient?.id}
        queryParams={{ patientId: patient?.id }}
      />
    </Allowed>
  );
};

export default PatientLedger;
