import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';

import { requestApi } from 'api/Api';

import { showAlert } from 'components/shared/Alert/Alert';

import Spinner from '../../shared/spinner/spinner';

const CashPayment = ({
  amount,
  patientId,
  practiceId,
  invoiceId,
  appointmentId,
  surcharge,
  invoiceToken,
  invoices,
  payerId,
  onSuccess = () => {},
  onFail = () => {},
  setReceipt = () => {}
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    submitPayment();
  }, []);

  const submitPayment = async () => {
    await requestApi({
      url: '/api/transactions/cash/add',
      params: {
        invoices,
        patientId,
        invoiceId,
        practiceId,
        invoiceToken,
        appointmentId,
        surcharge_amount: surcharge,
        transaction: {
          surcharge_amount: surcharge,
          amount: { value: amount, currency: 'USD' },
          note: ``,
          type: 'cash',
          payer_id: payerId
        }
      },
      navigate,
      onSuccess: (data) => {
        onSuccess(data);
        setReceipt(data?.receipt?.data);
        showAlert({ title: 'Transaction Finished!', color: 'success' });
      },
      onError: (error) => {
        Honeybadger.notify(
          `file: cash/cash, method: submitPayment - try, error: ${error ?? 'Theres been an error'}`
        );
        onFail(error);
      },
      onCatch: (error) => {
        console.error(error);
        Honeybadger.notify(
          `file: cash/cash, method: submitPayment - catch, error: ${error ?? 'Theres been an error'}`
        );
        onFail(`There's been an unexpected error. Please try again later.`);
      }
    });
  };

  return (
    <div className="px-50 pb-50 text-center">
      <Spinner wrapperStyle={{ margin: '0 auto' }} />
      <p>Just a moment...</p>
    </div>
  );
};

export default CashPayment;
