import { getPatients } from 'api/Patients';
import { getPractitioners } from 'api/Practitioner';

import numOrDefault from 'lib/helpers/numOrDefault';
import { formatDate, mString } from 'lib/helpers/utility';

import NavigateToClaimCell from './components/NavigateToClaimCell';
import NavigateToERACell from './components/NavigateToERACell';

const EstCell = (params) => {
  if (params?.node?.group) {
    let isEstimated = false;
    params.node.allLeafChildren.forEach((child) => {
      const procedures = child?.data?.procedures || [];
      if (procedures?.some((procedure) => procedure?.type === 'primary')) {
        if (child?.data?.last_applied?.type !== 'era') {
          isEstimated = true;
        }
      }
    });
    return `${params.valueFormatted} ${isEstimated ? 'estimated' : ''}`;
  }
  const procedures = params?.data?.procedures || [];
  if (procedures?.some((procedure) => procedure?.type === 'primary')) {
    if (params?.data?.last_applied?.type !== 'era') {
      return `${params.valueFormatted} estimated`;
    }
  }
  return params.valueFormatted;
};

export const getColDefs = (timezone, claims) => [
  {
    field: 'dateofservice',
    headerName: 'Date of Service',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const dos = params?.data?.dateofservice;
      return formatDate(dos, timezone, true);
    }
  },
  {
    field: 'patient_name',
    headerName: 'Patient',
    pinned: 'left'
  },
  {
    field: 'provider',
    headerName: 'Provider'
  },
  {
    field: 'charge',
    headerName: 'Charge',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    sortable: false
  },
  {
    field: 'ins_payment',
    headerName: 'Ins. Payment',
    valueFormatter: ({ value }) => mString(value),
    aggFunc: 'sum',
    sortable: false,
    hide: !!claims
  },
  {
    field: 'ins_adjustment',
    headerName: 'Ins. Adj',
    valueFormatter: ({ value }) => mString(value),
    cellRenderer: EstCell,
    aggFunc: 'sum',
    sortable: false,
    hide: !!claims
  },
  {
    field: 'pt_payment',
    headerName: 'Pt. Payment',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    sortable: false
  },
  {
    field: 'ins_balance',
    headerName: 'Ins. Balance',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    cellRenderer: EstCell,
    aggFunc: 'sum',
    sortable: false,
    hide: !!claims
  },
  {
    field: 'pt_balance',
    headerName: 'Pt. Balance',
    valueFormatter: ({ value }) => mString(value),
    cellRenderer: EstCell,
    aggFunc: 'sum',
    sortable: false
  },

  {
    field: 'claimsIds',
    headerName: 'Claim ID',
    cellRenderer: NavigateToClaimCell,
    sortable: false
  },
  {
    field: 'era_eob',
    sortable: false,
    headerName: 'ERA/EOB',
    cellRenderer: NavigateToERACell
  },
  {
    field: 'total_balance',
    headerName: 'Total Balance',
    aggFunc: 'sum',
    pinned: 'right',
    valueFormatter: ({ value }) => mString(value),
    sortable: false
  }
];

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: true
};

export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'always',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};

export const DEFAULT_FILTERS = {
  date: {
    values: null,
    type: 'date-range',
    title: 'Date of Invoice',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  patients: {
    type: 'infinite-scroll',
    values: [],
    queryFn: getPatients,
    queryKey: 'patients',
    multiple: true,
    title: 'Patient',
    placeholder: 'Search patient',
    formatData: (data) => data.map((patient) => ({ label: patient?.fullName, value: patient?.id }))
  },
  providers: {
    type: 'infinite-scroll',
    multiple: true,
    values: [],
    title: 'Providers',
    placeholder: 'Search providers',
    queryKey: 'practitioners',
    queryFn: getPractitioners
  }
};

export const getFinalColDefs = (name, claims, timezone) => {
  let colDefs = [...getColDefs(timezone, claims)];
  if (name === 'patient_ledger') {
    colDefs[1] = {
      field: 'patient_name',
      headerName: 'Patient',
      rowGroup: true,
      hide: true,
      pinned: 'left'
    };
  }

  if (name === 'ledger') {
    colDefs = colDefs.filter((col) => col.field !== 'patient_name');
  }
  return colDefs;
};

export const getPatientDefaultFilters = () => {
  const newDefaultFilters = { ...DEFAULT_FILTERS };
  delete newDefaultFilters.patients;

  return newDefaultFilters;
};
