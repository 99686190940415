import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import CSVviewer from 'components/shared/CSVviewer/CSVviewer';
import Modal from 'components/shared/Modal/Modal';
import { pastImports } from 'api/Admin/PatientImports';
import { showAlert } from 'components/shared/Alert/Alert';

const LIMIT = 10;

const PastImports = ({ practiceId }) => {
  const [imports, setImports] = useState([]);
  const [show, setShow] = useState({
    loading: false,
    canLoadMore: true,
    csvModal: false,
    csvFile: null,
    csvFileName: ''
  });
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (show.loading) {
      return;
    }
    let newShow = Object.assign({}, show);
    newShow.loading = true;
    setShow({ ...show, loading: true });
    try {
      const res = await pastImports(navigate, { practiceId, offset: imports.length, limit: LIMIT });
      const { code, error, redirect, patientImports: loadedPatientImports } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedPatientImports)) {
            if (loadedPatientImports.length < LIMIT) {
              newShow.canLoadMore = false;
            }
            let newImports = Object.assign([], imports);
            for (let i = 0; i < loadedPatientImports.length; i++) {
              newImports.push(loadedPatientImports[i]);
            }
            setImports(newImports);
          } else {
            newShow.canLoadMore = false;
          }
          break;
        default:
          showAlert({
            color: 'danger',
            message: error || `Unexpected error has occurred. Please try again later.`
          });
          break;
      }
    } catch (error) {
      console.error(error);
      showAlert({
        color: 'danger',
        message: 'An unexpected error has occurred. Please try again later.'
      });
    }
    newShow.loading = false;
    setShow({ ...newShow, loading: false });
  };

  return (
    <div className="font-mono">
      <span className="py-1 text-xl font-bold">Past Patient Imports</span>
      <table>
        <thead>
          <tr>
            <td>ID</td>
            <td>Date</td>
            <td>Importer ID</td>
            <td>File Name</td>
            <td>Imported</td>
            <td>Missing Info</td>
            <td>Errors</td>
          </tr>
        </thead>
        <tbody>
          {ia(imports) &&
            imports.map((v, index) => {
              return (
                <tr key={`past-import-id-${v.id}-index-${index}`}>
                  <td>{v.id}</td>
                  <td>{moment(v.created_at).format('YYYY/MM/DD HH:mm:ss.SSS')}</td>
                  <td>
                    <Link to={`/admin/users/${v.importer_id}`}>{v.importer_id}</Link>
                  </td>
                  <td>{v.file_name}</td>
                  <td>
                    <Button
                      onClick={() =>
                        v.imported
                          ? setShow({
                              ...show,
                              csvModal: true,
                              csvFile: v.imported,
                              csvFileName: 'Imported'
                            })
                          : showAlert({
                              color: 'danger',
                              message: 'No data in Imported CSV'
                            })
                      }
                      color="primary"
                      outlined={true}
                      text="View Imported"
                    />
                  </td>
                  <td>
                    <Button
                      onClick={() =>
                        v.missing_info
                          ? setShow({
                              ...show,
                              csvModal: true,
                              csvFile: v.missing_info,
                              csvFileName: 'Missing Info'
                            })
                          : showAlert({
                              color: 'danger',
                              message: 'No data in Missing Info CSV'
                            })
                      }
                      color="primary"
                      outlined={true}
                      text="View Missing Info"
                    />
                  </td>
                  <td>
                    <Button
                      onClick={() =>
                        v.errors
                          ? setShow({
                              ...show,
                              csvModal: true,
                              csvFile: v.errors,
                              csvFileName: 'Errors'
                            })
                          : showAlert({
                              color: 'danger',
                              message: 'No data in Errors CSV'
                            })
                      }
                      color="primary"
                      outlined={true}
                      text="View Errors"
                    />
                  </td>
                </tr>
              );
            })}
          {show.canLoadMore && (
            <tr>
              <td colSpan={7}>
                <Button
                  onClick={getData}
                  color="primary"
                  loading={show.loading}
                  style={{ width: '80%', marginLeft: 'auto', marginRight: 'auto' }}
                  text="Load More"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        title={`${show.csvFileName} CSV`}
        handleOpen={show.csvModal}
        handleClose={() => setShow({ ...show, csvFile: null, csvModal: false, csvFileName: '' })}
        customStyling={{ width: '90vw' }}>
        <CSVviewer CSVFile={show.csvFile} />
      </Modal>
    </div>
  );
};

export default PastImports;
