import React from 'react';
import OrderReferralPreview from '../../../Orders/views/OrderReferralPreview/OrderReferralPreview';

import { useQueryClient } from '@tanstack/react-query';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { ia } from 'lib/helpers/utility';
import { useReferralOrder } from 'lib/hooks/queries/referralOrder/useReferralOrder';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const ReferralOrders = ({ customClinicalNote = null, checkedFormTypes = {} }) => {
  const { isTemplateChecked } = checkedFormTypes;
  let clinicalNote = useRecoilValue(clinicalNoteState);
  const queryClient = useQueryClient();
  const patient = queryClient.getQueryData(['patient', clinicalNote?.user_id]);

  const customAppointmentId = customClinicalNote?.clinicalNote?.appointment_id;

  const { appointmentId } = useParams();

  const apptID = customAppointmentId ?? appointmentId ?? clinicalNote?.appointment_id;

  const { data: referralsData, isFetching } = useReferralOrder({
    dependencies: [apptID, clinicalNote?.id],
    params: {
      clinicalNoteIds: [apptID]
    },
    options: {
      enabled: isTemplateChecked,
      staleTime: 10000,
      select: ({ data }) => data?.referrals
    }
  });

  if (isFetching) return <Skeleton height="300px" />;

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Referral Orders</h3>

      {ia(referralsData) ? (
        referralsData.map((row, index) => {
          return (
            <div key={index} className="!mt-3">
              <OrderReferralPreview
                itemProps={{ ...row, patient: patient?.patient ?? customClinicalNote?.patient }}
              />
              {referralsData?.length - 1 > index && <div className="border border-solid" />}
            </div>
          );
        })
      ) : (
        <p className="text-sm">No Referral Orders</p>
      )}
    </div>
  );
};

export default ReferralOrders;
