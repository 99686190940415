import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { archiveClaims, deleteClaims, markClaimPending } from 'api/Claim';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { claimCSV, downloadCSV } from 'lib/helpers/utility';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { generatePDF } from '../CMS1500/lib/generatePDF';
import { capitalize } from 'lodash';
import { useClaim } from 'lib/hooks/queries/claims/useClaim';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useTableContext } from 'lib/context/TableContext/TableContext';

const claimState = {
  R: 'Rejected',
  A: 'Acknowledged'
};

const TimeLineElement = ({ state }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: state.status === 'R' ? 'rgb(255,236,240)' : 'rgb(239,250,244)',
        color: state.status === 'R' ? 'rgb(215,62,91)' : 'rgb(59,133,97)'
      }}
      contentArrowStyle={{
        borderRight:
          state.status === 'R' ? '7px solid  rgb(255,236,240)' : '7px solid  rgb(239,250,244)'
      }}
      date={state.response_time}
      iconStyle={{
        background: state.status === 'R' ? 'rgb(241,71,103)' : 'rgb(74,198,141)',
        color: '#fff'
      }}>
      <h4
        className="vertical-timeline-element-title"
        style={{
          color: state.status === 'R' ? 'rgb(215,62,91)' : 'rgb(59,133,97)'
        }}>
        Status: {claimState[state.status]}
      </h4>
      {state?.messages?.map(({ message }, index) => {
        return <ul key={index}>{message}</ul>;
      })}
    </VerticalTimelineElement>
  );
};

export const ClaimActions = ({ data }) => {
  const [pdfGenerationMode, setPDFGenerationMode] = useState();
  const [csvGenerationMode, setCSVGenerationMode] = useState();
  const [pdfGenerationInProgress, setPDFGenerationInProgress] = useState(false);

  const {
    data: claimData,
    isFetching,
    isLoading
  } = useClaim({
    params: { id: data?.id },
    dependencies: [data?.id, pdfGenerationMode, csvGenerationMode],
    options: { enabled: !!pdfGenerationMode || !!csvGenerationMode }
  });
  const claim = claimData?.claim;
  const { type = 'all' } = useParams();

  const { setSelectedRows } = useTableContext();

  const [claimStateHistory, setClaimStateHistory] = useState(false);
  const [printPopover, setPrintPopover] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const { data: addressData } = usePayerAddress({
    params: { payerId: claim?.payerid, withCount: true },
    dependencies: [claim?.payerid],
    options: { enabled: !!claim && printPopover && !!pdfGenerationMode }
  });
  const address = addressData?.address;

  const claimLoading = isLoading || isFetching;

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const mutateArchiveClaim = useMutation({
    mutationFn: () => archiveClaims(navigate, [data?.id]),
    onSuccess: (data) => {
      setSelectedRows([]);
      const word = 'claim';
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          showAlert({
            title: `${capitalize(word)} archived successfully!`,
            color: 'success'
          });
          break;
        case 2:
          showAlert({
            title: `Failed to archive ${word}!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to archive ${word}!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutateDeleteClaim = useMutation({
    mutationFn: () => deleteClaims(navigate, [data.id]),
    onSuccess: (data) => {
      setSelectedRows([]);
      const word = 'claim';
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          showAlert({
            color: 'success',
            title: `${capitalize(word)} deleted successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to delete ${word}!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to delete ${word}!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutateMarkClaimPending = useMutation({
    mutationFn: () => markClaimPending(navigate, [data.id]),
    onSuccess: (data) => {
      setSelectedRows([]);
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['claims']);
          showAlert({
            color: 'success',
            title: `Claim marked pending successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to mark claim pending!`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to mark claim pending!`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const handlePDFGeneration = async (options) => {
    if (pdfGenerationInProgress) return;

    setPDFGenerationInProgress(true);
    try {
      await generatePDF(options);
      setPDFGenerationMode(null);
    } catch {
      showAlert({
        color: 'danger',
        message: 'Failed to generate PDF. Please try again.'
      });
    } finally {
      setPDFGenerationInProgress(false);
    }
  };

  useEffect(() => {
    if (pdfGenerationMode && claim) {
      const pdfOptions = {
        claim,
        address,
        withPayerNameAddress: !pdfGenerationMode.includes('WITHOUT_PAYER'),
        withBackground: !pdfGenerationMode.includes('TEXT')
      };
      handlePDFGeneration(pdfOptions);
    }
  }, [address, claim, pdfGenerationMode]);

  useEffect(() => {
    if (csvGenerationMode && claim) {
      const csv = claimCSV(claim);
      downloadCSV(csv, `claim_${claim?.id}.csv`);
      setCSVGenerationMode(false);
    }
  }, [claim, csvGenerationMode]);

  if (!data) return null;

  const handleCMSPrintWithPayerAndAddress = async () => {
    setPrintPopover(true);
    setPDFGenerationMode('WITH_PAYER');
  };

  const handleCMSPrintWithoutPayerAndAddress = async () => {
    setPrintPopover(false);
    setPDFGenerationMode('WITHOUT_PAYER');
  };

  const handleTextPrintWithPayerAndAddress = async () => {
    setPrintPopover(true);
    setPDFGenerationMode('TEXT_WITH_PAYER');
  };

  const handleTextPrintWithoutPayerAndAddress = async () => {
    setPDFGenerationMode('TEXT_WITHOUT_PAYER');
    setPrintPopover(false);
  };

  const handleDownloadCSV = () => {
    setCSVGenerationMode(true);
  };

  const handleStatus = async () => {
    try {
      const res = await requestApi({
        url: '/api/practice/billing/claim/response_request',
        params: { claimId: data.id },
        navigate
      });
      if (res) {
        setClaimStateHistory(res);
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  };

  const handleMarkPending = () => {
    mutateMarkClaimPending.mutate();
  };

  const onDeleteClick = () => {
    setShowDeleteConfirmationModal(true);
  };

  const handleDelete = () => {
    mutateDeleteClaim.mutate();
  };

  const handleArchive = () => {
    mutateArchiveClaim.mutate();
  };

  const handleClaimView = () => {
    setSelectedRows([]);
    navigate(`${type}/${data?.id}`);
  };

  const options = [
    { label: 'View Claim', onClick: handleClaimView, icon: 'new-eye' },
    { label: 'Status', onClick: handleStatus, icon: 'status' },
    {
      label: 'Print',
      icon: 'new-printer',
      children: [
        {
          label: 'CMS1500',
          children: [
            {
              label: 'With payer name and address',
              onClick: handleCMSPrintWithPayerAndAddress,
              loading: pdfGenerationMode === 'WITH_PAYER' && claimLoading
            },
            {
              label: 'Without payer name and address',
              onClick: handleCMSPrintWithoutPayerAndAddress,
              loading: pdfGenerationMode === 'WITHOUT_PAYER' && claimLoading
            }
          ]
        },
        {
          label: 'Only Text',
          children: [
            {
              label: 'With payer name and address',
              onClick: handleTextPrintWithPayerAndAddress,
              loading: pdfGenerationMode === 'TEXT_WITH_PAYER' && claimLoading
            },
            {
              label: 'Without payer name and address',
              onClick: handleTextPrintWithoutPayerAndAddress,
              loading: pdfGenerationMode === 'TEXT_WITHOUT_PAYER' && claimLoading
            }
          ]
        }
      ]
    },
    {
      label: 'Download CSV',
      onClick: handleDownloadCSV,
      icon: 'new-import',
      loading: csvGenerationMode && claimLoading
    },
    type !== 'pending'
      ? {
          label: 'Mark Pending',
          onClick: handleMarkPending,
          icon: 'new-task'
        }
      : null,
    type !== 'archived'
      ? {
          label: 'Archive',
          onClick: handleArchive,
          icon: 'archive-small'
        }
      : null,
    {
      label: 'Delete',
      onClick: onDeleteClick,
      icon: 'trash'
    }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {showDeleteConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Claim"
          icon="new-document-remove-red"
          message="Are you sure you want to delete this claim?"
          loading={mutateDeleteClaim?.isLoading}
          handleContinue={handleDelete}
          handleOpen={showDeleteConfirmationModal}
          handleClose={() => setShowDeleteConfirmationModal(false)}
        />
      )}
      {claimStateHistory && (
        <Modal
          slideFromRight
          isOpen={!!claimStateHistory}
          handleClose={() => setClaimStateHistory(null)}
          buttonText="Done"
          title="Claim status history"
          className="w-[50%]"
          footer={
            <Button
              onClick={() => setClaimStateHistory(null)}
              text="Close"
              color="neutral"
              data-qa="close-btn"
            />
          }>
          <VerticalTimeline>
            {claimStateHistory?.history?.claim?.map((row, index) => {
              return <TimeLineElement key={index} state={row} />;
            })}
          </VerticalTimeline>
        </Modal>
      )}
    </>
  );
};

export default ClaimActions;
