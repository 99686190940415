import { upperCase } from 'lodash';

export default function formatFilters({ filters, page, limit, sort, patientId }) {
  return {
    patientId,
    page,
    filters,
    limit,
    sort: sort && { column: sort?.field, sortBy: upperCase(sort?.sort) },
    searchTerm: filters?.name?.values,
    category: filters?.category?.values,
    type: filters?.type?.values,
    staff: filters?.uploaded_by?.values,
    date: [{ ...filters?.created_at?.values, key: 'selection' }]
  };
}
