export const UNIT_OPTIONS = [
  { label: 'Units', value: null },
  { label: 'Miligrams (mg)', value: 'mg' },
  { label: 'Microgram (mcg)', value: 'mcg' },
  { label: 'Milliliters (ml)', value: 'ml' },
  { label: 'Grams (g)', value: 'g' },
  { label: 'Drops (gtt)', value: 'gtt' },
  { label: 'Sprays (sp)', value: 'sp' },
  { label: 'Capsules (cap)', value: 'cap' },
  { label: 'Tablets (tab)', value: 'tab' },
  { label: 'Teaspoons (tsp)', value: 'tsp' },
  { label: 'Inhalations (inh)', value: 'inh' },
  { label: 'International Unit (IU)', value: 'IU' }
];

export const MEDICATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted'
};

export const FINAL_NOTE_OPTIONS = [
  { label: "Today's data only", value: 'TodaysData' },
  { label: 'Active Medications', value: 'ActiveMedications' },
  { label: 'Inactive Medications', value: 'InactiveMedications' },
  { label: 'Template', value: 'Template' },
  { label: 'Narrative', value: 'Narrative' }
];

export const CONFIRMATION_MODAL = {
  open: false,
  type: null
};

export const MEDICATION_ACTION_TYPES = {
  DELETE: 'delete',
  UPDATE_STATUS: 'updateStatus',
  ADD_TO_WEIGHT_LOSS: 'addToWeightLoss'
};
