import React from 'react';
import Loader from 'components/shared/Loader/Loader';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useRecoilValue } from 'recoil';
import { intelligenceAiState } from 'components/state';

const SOAPNoteEnhancementStatus = ({ children }) => {
  const intelligenceState = useRecoilValue(intelligenceAiState);

  return (
    <div className="flex flex-row gap-2">
      <div>{children}</div>
      {intelligenceState?.loading && (
        <div className="flex flex-row gap-2">
          <span>Enhancing...</span>
          <Loader
            outlined={false}
            color="primary"
            shade="50"
            className="bg-primary-500 text-primary-50"
          />
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(SOAPNoteEnhancementStatus);
