import React from 'react';
import Draw from './components/Draw';
import Location from './components/Location';
import TestDetails from './components/TestDetails';
import OrderDetails from './components/OrderDetails';
import PatientDetails from './components/PatientDetails';
import OrderPhysician from './components/OrderPhysician';
import ICD10OrDiagnosis from './components/ICD10OrDiagnosis';
import InsuranceDetails from './components/InsuranceDetails';
import YourSignaturePreview from './components/YourSignaturePreview';

const LabOrderPreview = ({ itemProps }) => {
  const labOrder = itemProps?.labOrder;
  const labOrderTests = itemProps?.labOrderTests;

  return (
    <div className="!mx-6">
      <div className="!mt-6">
        <TestDetails tests={labOrderTests} />
      </div>

      <div className="!mt-6">
        <ICD10OrDiagnosis itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <Location itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <Draw itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <OrderDetails itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <InsuranceDetails itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <PatientDetails itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <OrderPhysician itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <YourSignaturePreview itemProps={itemProps} />
      </div>
    </div>
  );
};

export default LabOrderPreview;
