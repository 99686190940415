import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, isEmpty, stripHtmlTags } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';

import practiceState from 'components/practice/practiceState';
import { transformData } from 'components/shared/Forms/Custom/lib/aggregatedDataHelper';
import { createVaccineNarrativeV2 } from 'components/shared/Forms/Custom/lib/vaccineNarrativeHelper';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { clinicalNote as clinicalNoteState } from 'components/state';

import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { filterTodayOnly, formatData } from '../../lib/renderedHelper';

import VaccinesSimpleTable from './VaccinesSimpleTable';

const Vaccines = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData } = customClinicalNote ?? useClinicalNoteContext();
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const { isNarrativeChecked, isTemplateChecked, isTodaysDataOnlyChecked } = checkedFormTypes;
  const { timezone } = useRecoilValue(practiceState.currentPractice);
  const { id: patientId } = useParams();
  const patient = patientId ?? String(clinicalNote?.user_id);

  const data = overviewData?.vaccines;

  const queryClient = useQueryClient();
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'vaccines',
        patientId: patient
      },
      dependencies: [patient, 'vaccines'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          queryClient.setQueryData(['patientAggregateNarrative', patient, 'vaccines'], () => ({
            data: {
              narrative: {
                narrative: createVaccineNarrativeV2(
                  transformData({
                    data,
                    shouldMap: false,
                    validation: 'vaccine'
                  })
                ),
                synced: true
              }
            }
          }));
        }
      }
    });

  const isPatientNarrativeEmpty = isEmpty(stripHtmlTags(patientNarrative?.narrative)?.trim());

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (customClinicalNote && !ia(data) && isPatientNarrativeEmpty) {
    return null;
  }

  const tableData = isTemplateChecked ? formatData(data) : [];

  const filteredData = isTodaysDataOnlyChecked
    ? formatData(filterTodayOnly(timezone, data, 'createdAt'))
    : [];

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Vaccines</h3>

      {isTodaysDataOnlyChecked ? (
        <VaccinesSimpleTable data={filteredData} emptyState="No vaccines added today" />
      ) : null}

      {isTemplateChecked ? <VaccinesSimpleTable data={tableData} /> : null}

      {isNarrativeChecked && !isPatientNarrativeEmpty && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <SanitizeComponent value={patientNarrative?.narrative} />
        </div>
      )}
    </div>
  );
};

export default Vaccines;
