import React from 'react';

import Modal from 'components/shared/Modal/Modal';

const DocumentModal = ({ url, title, open, closeHandler }) => {
  return (
    <Modal
      customHeader={
        <div className="flex w-full mr-4 justify-between">
          <p className="text-ellipsis text-lg">{title}</p>
        </div>
      }
      handleOpen={open}
      handleClose={closeHandler}
      isLarge
      className="!bg-white sm:w-full transition-[width] h-full w-[924px]">
      <iframe
        src={url}
        className="print:p-4"
        style={{ width: '100%', height: 'calc(100vh - 11.25rem)' }}></iframe>
    </Modal>
  );
};

export default DocumentModal;
