import React, { useState } from 'react';
import FormsAI from '../FormsAI';
import PatientNeedsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/PatientNeedsSection';
import ProblemManifestationDefinitionSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/ProblemManifestationDefinitionSection';
import ICDCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import GoalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GoalsSection';
import VitalsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import TimeFramesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/TimeFramesSection';
import RiskAndCoMorbiditiesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/RiskAndCoMorbiditiesSection';
import CPTCodesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import GeneralNoteSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/IntakeNote/components/GeneralNoteSection';
import OrdersSection from 'components/practice/charts/ClinicalNote/Orders/OrdersSection';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import AdvancedHP from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AdvancedHP';

const getFormsList = (advancedHP, cnDisplaySettings) => {
  const formsList = {
    'patient-needs': PatientNeedsSection,
    'problem-manifestation-definition': ProblemManifestationDefinitionSection,
    'diagnosis-codes': ICDCodesSection,
    goals: GoalsSection,
    vitals: VitalsSection,
    'time-frames': TimeFramesSection,
    'risk-and-co-morbidities': RiskAndCoMorbiditiesSection,
    'cpt-codes': CPTCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection,
    ...assignAdvTitlesToComponent(advancedHP, AdvancedHP, cnDisplaySettings)
  };

  return formsList;
};

const INTAKEFormsAI = ({ inputName }) => {
  const { advancedHP, cnDisplaySettings } = useClinicalNoteContext();
  const [formsList] = useState(getFormsList(advancedHP, cnDisplaySettings));

  return <FormsAI formsList={formsList} inputName={inputName} />;
};

export default INTAKEFormsAI;
