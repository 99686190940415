import React from 'react';
import Accordion from '../../../../shared/Accordion';
import RiskFactors from './RiskFactors';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const RiskFactorsSection = ({ components, sectionRef, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{ name: 'Risk Factors', parentKey: 'progress', childKey: 'riskFactors' }}
      title={components?.riskFactors?.title}
      id={components?.riskFactors?.id}
      disabled={clinicalNote?.locked}>
      <RiskFactors sectionRef={sectionRef} />
    </Accordion>
  );
};

export default RiskFactorsSection;
