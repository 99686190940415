import React from 'react';
import Input from '../../../../shared/Input/Input';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import Header from 'components/shared/Header/Header';

const HeaderTable = ({ setNewPackageModalVisible, filters, setFilters }) => (
  <Header title="Packages">
    <div className="flex gap-2">
      <Input
        id="searchPackage"
        type="string"
        placeholder="Search package"
        value={filters.searchTerm}
        rounded="full"
        icon="new-search"
        className="!mr-5 md:w-[200px]"
        onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
        rightText={
          filters.searchTerm && (
            <Icon
              icon="new-close"
              onClick={() => setFilters({ ...filters, searchTerm: '', page: 1 })}
              id="clearPackageSearchTermBtn"
            />
          )
        }
      />
      <Button
        type="primary"
        text="New Package"
        onClick={() => setNewPackageModalVisible(true)}
        id="newPackageBtn"
        data-qa="new-package-btn"
        className="!w-max"
      />
    </div>
  </Header>
);

export default HeaderTable;
