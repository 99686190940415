import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { pm } from 'lib/helpers/utility';

import NewServiceModal from 'components/practice/settings/Services/components/NewServiceModal/NewServiceModal';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';

import { useScheduleOfFeesContext } from '../context/ScheduleOfFeesProvider';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { setSelectedRows, gridRef } = useTableContext();
  const { setRowData } = useScheduleOfFeesContext();

  const userPermissions = useRecoilValue(permissions);

  let options = [
    ...(pm(userPermissions, 'services.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'services.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];

  const handleDelete = async (service) => {
    setSelectedRows((prev) =>
      prev.filter((row) => row.data.procedure_code !== service.procedure_code)
    );

    if (gridRef.current?.api) {
      const currentRows = gridRef.current.api.getModel().rowsToDisplay.map((row) => row.data);
      const updatedRows = currentRows.filter(
        (row) => row.procedure_code !== service.procedure_code
      );
      gridRef.current.api.setRowData(updatedRows);

      const updatedIds = updatedRows.map((row) => row.procedure_code);
      gridRef.current.api.forEachNode((node) => {
        if (updatedIds.includes(node.data.procedure_code)) {
          node.setSelected(true);
        }
      });
    }
  };

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  const onSave = async (updatedService) => {
    setSelectedRows((prev) =>
      prev.map((row) =>
        row.data.procedure_code === data.procedure_code ? { data: updatedService } : row
      )
    );

    setRowData((prev) =>
      prev.map((row) => (row.procedure_code === data.procedure_code ? updatedService : row))
    );

    if (gridRef?.current?.api) {
      const node = gridRef.current.api.getRowNode(data.procedure_code);
      if (node) {
        node.setData(updatedService);
        node.setSelected(true);
      }
    }

    onEditModalToggle();
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <NewServiceModal
          service={data}
          isOpen={showEditModal}
          onClose={onEditModalToggle}
          onSave={onSave}
        />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete service"
          title="Delete Service"
          icon="new-calendar-red"
          message="Are you sure you want to delete this service?"
          handleOpen={showDeleteModal}
          handleContinue={() => handleDelete(data)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
