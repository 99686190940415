import { calculateProceduresTotal } from 'components/public/lib/utils';
import { procedureTypes } from 'constants';
import { Capitalize, formatDate, ia, mString } from 'lib/helpers/utility';
import NavigatePopover from '../../components/InternalInvoicesNavigatePopover';
import { searchPatients } from '../searchPatients';
import NavigateToERACell from 'components/practice/BeyondBilling/PatientLedger/components/NavigateToERACell';
import moment from 'moment';

export const getColDefs = (timezone, aeob) => [
  {
    field: 'id',
    headerName: 'Invoice ID',
    maxWidth: 150,
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'dateofservice',
    headerName: 'Date Of Service',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    filter: 'agDateColumnFilter'
  },
  {
    field: 'patient_name',
    headerName: 'Patient'
  },
  {
    field: 'total_amount',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'invoice_balance',
    headerName: 'Balance',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'eraIds',
    headerName: 'ERA ID',
    cellRenderer: NavigateToERACell,
    sortable: false
  },
  { field: 'external_invoice_id', headerName: 'External Invoice ID', cellClass: 'no-border' },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: NavigatePopover,
    maxWidth: 70
  }
];

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true };

export const GRID_OPTIONS = {
  animateRows: false,
  rowGroupPanelShow: 'always',
  groupSelectsChildren: true,
  enableRangeSelection: true,
  suppressAggFuncInHeader: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  era: {
    type: 'checkbox',
    options: ['pending', 'posted'],
    values: null,
    title: 'Insurance Payment',
    preview: (values) => values
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: searchPatients,
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  appointmentResources: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Appointment Resources',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  appointmentTags: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Appointment Tags',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};

export const getPracticeDefaultFilters = (practice) => {
  const newDefaultFilters = {
    ...DEFAULT_FILTERS,
    date: {
      values: {
        startDate: moment.min(moment(practice.created_at), moment(0)).toDate(),
        endDate: moment().toDate()
      },
      type: 'date-range',
      title: 'Date of Invoice',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    }
  };

  return newDefaultFilters;
};

export const getProcedureDetails = ({ procedures, claims }) => {
  return (
    ia(procedures) &&
    procedures.map((procedure) => {
      const {
        name,
        type,
        total_cost_cents,
        quantity,
        pt_balance,
        dateofservice,
        charge: procCharge,
        last_applied
      } = procedure || {};

      const procedureType =
        procedureTypes?.find((o) => o?.value === type)?.label ||
        Capitalize((type || '').replace('_', ' ')) ||
        'Service';

      let finalProcedure = procedure;

      if (['self_pay', 'cash'].includes(type)) {
        // finalProcedure.charge = pt_balance ?? total_cost_cents ?? 0;
        finalProcedure.charge = procCharge ?? pt_balance ?? total_cost_cents ?? 0;
      }

      const { charge, ins_adjustment, ins_payment } = calculateProceduresTotal([finalProcedure]);

      const detailsObj = {
        ...procedure,
        details: name,
        type: procedureType,
        qty: quantity || 1,
        charge: charge ?? pt_balance ?? total_cost_cents ?? 0,
        ...(claims && {
          ins_payment: ins_payment || 0,
          ins_adjustment: ins_adjustment || 0
        }),
        ptBalance: pt_balance ?? total_cost_cents ?? 0,
        dateofservice,
        isEstimated: last_applied?.type !== 'era' && type === 'primary'
      };

      return detailsObj;
    })
  );
};

export const modifyProducts = ({ products = [], isTotal = false }) => {
  if (!products?.length) return [];

  return (
    ia(products) &&
    products.map((product) => {
      const { name, total_amount_cents, sales_count, pt_balance, dateofservice } = product || {};
      const finalSalesCount = sales_count ?? 1;
      const finalCharge = isTotal
        ? (total_amount_cents ?? 0)
        : (total_amount_cents / finalSalesCount ?? 0);

      const detailsObj = {
        ...product,
        name,
        type: 'product',
        quantity: finalSalesCount,
        charge: finalCharge,
        pt_balance: pt_balance ?? total_amount_cents ?? 0,
        dateofservice
      };

      return detailsObj;
    })
  );
};

export const modifyPackages = ({ packages = [], isTotal = false }) => {
  if (!packages?.length) return [];

  return (
    ia(packages) &&
    packages.map((pack) => {
      const { name, total_amount_cents, sales_count, pt_balance, dateofservice } = pack || {};

      const finalSalesCount = sales_count || 1;
      const finalCharge = isTotal
        ? (total_amount_cents ?? 0)
        : (total_amount_cents / finalSalesCount ?? 0);

      const detailsObj = {
        ...pack,
        name,
        type: 'package',
        quantity: finalSalesCount,
        charge: finalCharge,
        pt_balance: pt_balance ?? total_amount_cents ?? 0,
        dateofservice
      };

      return detailsObj;
    })
  );
};

export const getInvoiceDetails = (invoice, claims = null) => {
  const procedures = invoice?.procedures || [];
  const products = invoice?.products || [];
  const packages = invoice?.packages || [];

  const modifiedProducts = modifyProducts({ products }) || [];
  const modifiedPackages = modifyPackages({ packages }) || [];

  const services = [...procedures, ...modifiedProducts, ...modifiedPackages];

  const proceduresDetails = getProcedureDetails({
    procedures: services,
    claims
  });

  return proceduresDetails;
};
