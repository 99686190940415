import React from 'react';

import Input from 'components/shared/Input/Input';

const RightInputs = ({ procedure, handleInputChange }) => {
  return (
    <div className="flex flex-col gap-2 flex-1">
      <Input
        label="24K. FACILITY"
        placeholder="Facility"
        name="chg_facility_name"
        value={procedure?.chg_facility_name}
        onChange={handleInputChange}
      />
      <Input
        label="Address 1"
        placeholder="Address 1"
        name="chg_facility_addr_1"
        value={procedure?.chg_facility_addr_1}
        onChange={handleInputChange}
      />
      <Input
        label="Address 2"
        placeholder="Address 2"
        name="chg_facility_addr_2"
        value={procedure?.chg_facility_addr_2}
        onChange={handleInputChange}
      />
      <div className="flex gap-1">
        <Input
          label="City"
          placeholder="City"
          name="chg_facility_city"
          value={procedure?.chg_facility_city}
          onChange={handleInputChange}
        />
        <Input
          label="St"
          placeholder="St"
          name="chg_facility_state"
          value={procedure?.chg_facility_state}
          onChange={handleInputChange}
        />
        <Input
          label="Zip"
          placeholder="Zip"
          name="chg_facility_zip"
          value={procedure?.chg_facility_zip}
          onChange={handleInputChange}
        />
      </div>
      <Input
        label="NPI"
        placeholder="NPI:"
        name="chg_facility_npi"
        value={procedure?.chg_facility_npi}
        onChange={handleInputChange}
      />
      <Input
        label="Facility CLIA:"
        placeholder="Facility CLIA"
        name="facility_clia"
        value={procedure?.facility_clia}
        onChange={handleInputChange}
      />
      <Input
        label="24L. PURCHASED SERVICE NPI"
        placeholder="Purchased Service NPI"
        name="chg_purchased_npi"
        value={procedure?.chg_purchased_npi}
        onChange={handleInputChange}
      />
      <Input
        label="PURCHASED SERVICE ID"
        placeholder="Purchased Service Id"
        name="chg_purchased_id"
        value={procedure?.chg_purchased_id}
        onChange={handleInputChange}
      />
      <Input
        label="PURCHASED SERVICE PRICE"
        placeholder="Purchased Service Price"
        name="chg_purchased_charge"
        value={procedure?.chg_purchased_charge}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default RightInputs;
