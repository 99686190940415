import React from 'react';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useIncomeIQueueContext } from 'lib/context/IncomeIQueueContext/IncomeIQueueContext';

const Actions = ({ data }) => {
  const { actions, setActions } = useIncomeIQueueContext();

  const currentAction = actions?.find((action) => action?.id === data?.id)?.action || 'Select';

  const options = [
    {
      label: 'First Action',
      onClick: () =>
        setActions((prev) => {
          const existingActionIndex = prev.findIndex((action) => action?.id === data?.id);

          if (existingActionIndex > -1) {
            const updatedActions = [...prev];
            updatedActions[existingActionIndex].action = 'First Action';
            return updatedActions;
          }

          return [...prev, { id: data?.id, data, action: 'First Action' }];
        })
    },
    {
      label: 'Second Action',
      onClick: () =>
        setActions((prev) => {
          const existingActionIndex = prev.findIndex((action) => action?.id === data?.id);

          if (existingActionIndex > -1) {
            const updatedActions = [...prev];
            updatedActions[existingActionIndex].action = 'Second Action';
            return updatedActions;
          }

          return [...prev, { id: data?.id, data, action: 'Second Action' }];
        })
    }
  ];

  return (
    <div className="h-full w-full">
      <Popover
        options={options}
        isDropdown
        text={currentAction}
        position="left"
        isFixed
        icon=""
        className="flex w-full items-center justify-between"
        buttonClassName="w-full flex justify-between !rounded-none !hover:bg-transparent !bg-transparent !border-b border-solid border-neutral-300"
        panelClassName="w-[141px] !rounded-none"
        textClassName="text-primary-900 font-400"
        iconRightClassName="neutral-500"
      />
    </div>
  );
};

export default Actions;
