import React, { useEffect, useRef, useState } from 'react';
import { MyTemplateContext } from './MyTemplateContext';
import { useMyScribeAIContext } from '../MyScribeAIContext';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { generateAdvancedSectionFormMap } from 'components/practice/charts/ClinicalNote/lib/processCustomNoteTypes';
import { updateHiddenComponents } from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/shared/lib/helpers';

export const MyTemplateContextProvider = ({ children }) => {
  const { typeOfNotes: initialTypeOfNotes } = useMyScribeAIContext();
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);
  const [chatForms, setChatForms] = useState({});
  const { customFormTypes, cnDisplaySettings } = useClinicalNoteContext();
  const [scrollTo, setScrollTo] = useState(null);

  const componentRefs = useRef({});

  useEffect(() => {
    if (initialTypeOfNotes) {
      // setLoading(false);
      setChatForms(initialTypeOfNotes);
      onSelectInitialTypeOfNotes(initialTypeOfNotes);
    }
  }, [initialTypeOfNotes]);

  const onSelectInitialTypeOfNotes = (initialTypeOfNotes) => {
    const sectionMap = {
      hp: cnDisplaySettings?.hp?.enabled,
      intake: cnDisplaySettings?.mha?.enabled,
      soap: cnDisplaySettings?.soap?.enabled,
      progress: cnDisplaySettings?.epn?.enabled,
      '3d': cnDisplaySettings?.chart3d?.enabled,
      ...generateAdvancedSectionFormMap({ cnDisplaySettings, customFormTypes })
    };

    const enabledSection = Object.entries(sectionMap).find(([, isEnabled]) => isEnabled)?.[0];
    const initialType = cnDisplaySettings?.soap.enabled ? 'soap' : enabledSection;

    const newChatForms = {
      ...initialTypeOfNotes,
      [initialType]: {
        ...initialTypeOfNotes?.[initialType],
        checked: true
      }
    };

    const updatedFormsWithHiddenComponents = updateHiddenComponents(newChatForms);

    setChatForms(updatedFormsWithHiddenComponents);
  };

  return (
    <MyTemplateContext.Provider
      value={{
        selectedChatComponents,
        setSelectedChatComponents,
        componentRefs,
        chatForms,
        setChatForms,
        scrollTo,
        setScrollTo
      }}>
      {children}
    </MyTemplateContext.Provider>
  );
};
