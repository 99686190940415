import React, { useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { ComponentParamsProvider } from 'lib/context/ComponentParamsContext/ComponentParamsProvider';

import { COLUMN_DEFS } from './lib/helpers';

const NdcTable = ({ formik }) => {
  const codes = formik.values.ndc_codes;

  const handleDelete = useCallback(
    (code) => {
      const updatedCodes = codes.filter((_code) => _code.value !== code.value);
      formik.setFieldValue('ndc_codes', updatedCodes);
    },
    [codes, formik]
  );

  return (
    <ComponentParamsProvider onDelete={handleDelete}>
      <div className="ag-theme-quartz  shadow-none h-fit">
        <AgGridReact rowData={codes} columnDefs={COLUMN_DEFS} domLayout="autoHeight" />
      </div>
    </ComponentParamsProvider>
  );
};

export default NdcTable;
