import React from 'react';

import cs from 'classnames';
import { useFormik } from 'formik';

import { deepEqual, ia } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

import { getPayScheduleChanges } from '../../lib/helpers';

import CodingSection from './components/CodingSection/CodingSection';
import NdcTable from './components/CodingSection/components/NdcTable';
import DetailsSection from './components/DetailsSection/DetailsSection';
import MainSection from './components/MainSection/MainSection';
import PayerTable from './components/PayerTable/PayerTable';
import PricingSection from './components/PricingSection/PricingSection';
import { ServiceValidationSchema, getFinalShapeServiceObj, getInitialValues } from './lib/helpers';

const NewServiceModal = ({
  service,
  isOpen,
  onClose = () => {},
  onSave = async (_service) => {},
  isLoading = false
}) => {
  const initialValues = getInitialValues(service);

  const formik = useFormik({
    initialValues,
    validationSchema: ServiceValidationSchema,
    onSubmit: async (values) => {
      const serviceToBeCreated = getFinalShapeServiceObj(values);
      const payScheduleChanges = getPayScheduleChanges(
        values.paySchedule,
        service?.payerConnection
      );
      await onSave(serviceToBeCreated, payScheduleChanges);
    }
  });

  const isFormChanged = !deepEqual(formik.values, initialValues);

  return (
    <Modal
      customStyling={{ width: '700px' }}
      handleOpen={isOpen}
      className={cs(
        '!h-[calc(100vh-115px)] !w-[calc(100vw-115px)] bg-primary-10 pb-28 sm:!h-full sm:!w-full'
      )}
      bodyClassName="flex justify-center flex-1"
      handleClose={onClose}
      isLarge
      title="Edit Service"
      isFooterSticky
      stickyFooterClassName="max-w-[700px]"
      footer={
        <div className={cs('flex w-full justify-between ')}>
          <Button
            outlined
            text="Close"
            color="neutral"
            onClick={onClose}
            id="closeServiceModalBtn"
          />
          <Button
            onClick={() => formik.submitForm()}
            text={`${service ? 'Update' : 'Create'} Service`}
            id="createUpdateServiceBtn"
            data-qa="create-update-service-btn"
            icon="shade-plus"
            iconColor="white"
            loading={isLoading}
            disabled={!isFormChanged}
          />
        </div>
      }>
      <div className="flex h-full w-[700px] flex-col gap-6">
        <MainSection formik={formik} service={service} />
        <CodingSection formik={formik} />
        <PricingSection formik={formik} />
        {ia(formik.values.ndc_codes) && <NdcTable formik={formik} />}
        <DetailsSection formik={formik} />
        {ia(formik.values.paySchedule) && (
          <Allowed requiredPermissions="payments.read">
            <PayerTable formik={formik} />
          </Allowed>
        )}
      </div>
    </Modal>
  );
};

export default NewServiceModal;
