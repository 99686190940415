import React from 'react';
import Icon from '../../../../shared/Icon/Icon';

const emptyIcon = {
  draft: 'empty-draft',
  archive: 'empty-archive',
  contact: 'empty-contact',
  inbound: 'empty-inbound'
};

const EmptyFaxTable = ({ title, children }) => {
  return (
    <div className="h-[50vh] w-full flex flex-col items-center justify-center gap-4">
      <Icon icon={emptyIcon[title]} />
      <h3 className="text-lg font-600 text-primary-900">
        You don't have any {title} {title !== 'contact' && 'faxes'} yet
      </h3>
      {children}
    </div>
  );
};

export default EmptyFaxTable;
