import React, { useState } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

import SignModal from '../shared/Sign/SignModal';

import Rendered from './Rendered/Rendered';
import Selected from './Selected/Selected';

const PreviewNote = ({
  isExport,
  printButtonRef = null,
  sendEmailToPatientButtonRef = null,
  outsideAppointmentId,
  patientId,
  signAndLockProps = null
}) => {
  const [showSignModal, setShowSignModal] = useState(true);

  return (
    <div className="h-full">
      <div className="flex h-full">
        <div
          className={cs(
            'overflow-hidden transition-all duration-200',
            isExport ? 'w-[376px] min-w-[376px]' : 'w-0'
          )}>
          <Selected patientId={patientId} />
        </div>
        <div className="flex w-full flex-col">
          <Rendered
            isExport={isExport}
            printButtonRef={printButtonRef}
            sendEmailToPatientButtonRef={sendEmailToPatientButtonRef}
            outsideAppointmentId={outsideAppointmentId}
            patientId={patientId}
          />

          {signAndLockProps?.signAndLockMode && (
            <div className="flex flex-col justify-center bg-white !px-6 py-2 shadow-[0_-2px_5px_-5px_#333]">
              <div className="flex items-center justify-between">
                <div className="flex gap-1.5">
                  <span className="font-500 text-primary-900">Your Signature</span>
                  <Tippy
                    content="This is the signature that will appear when you sign and lock the final note."
                    className="tippy-dark">
                    <span>
                      <Icon icon="new-info" color="primary" />
                    </span>
                  </Tippy>
                </div>
                <Button
                  transparent
                  text={`${showSignModal ? 'Minimize' : 'Expand'} Signature`}
                  icon={showSignModal ? 'chevrons-down' : 'chevrons-up'}
                  size="small"
                  iconColor="white"
                  onClick={() => setShowSignModal((prev) => !prev)}
                />
              </div>
              <div
                className={cs(
                  'duration-450 transition-all ease-in-out',
                  showSignModal ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
                )}>
                <SignModal classNameSignModal="!mt-0" {...signAndLockProps} />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PreviewNote;
