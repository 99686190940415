import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { chartExport } from '../../../../api/Patients';

export const useChartExport = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['chartExport', ...dependencies], () => chartExport(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
