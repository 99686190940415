import React from 'react';
import * as Yup from 'yup';
import Modal from '../../../../../../shared/Modal/Modal';
import Input from '../../../../../../shared/Input/Input';
import Button from '../../../../../../shared/Buttons/Button';
import Textarea from '../../../../../../shared/Textarea/Textarea';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';
import { createNoteMacroObject, findNoteMacroType } from './lib/noteMacroHelpers';
import { Capitalize } from '../../../../../../../lib/helpers/utility';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../../../../../../api/Api';
import { useFormik } from 'formik';
import cs from 'classnames';

const CreateMacro = ({
  macroTitle,
  macroType,
  open,
  handleClose,
  defaultTextareaValue = null,
  defaultInputValue = '',
  isModal = true,
  customMacroBody = {},
  slideFromRight = true,
  isEditor = false,
  className,
  textAreaClassName,
  footer
}) => {
  const { clinicalNote } = useClinicalNoteContext();
  const { createMacro } = useMacrosContext();
  const { type } = useParams();
  const navigate = useNavigate();

  const createMacroType = async ({ inputValue, textareaValue }) => {
    const response = await requestApi({
      url: '/api/clinical_note/read',
      params: {
        patientId: clinicalNote?.user_id,
        appointmentId: clinicalNote?.appointment_id,
        exporting: true
      },
      navigate
    });

    switch (macroType) {
      case 'appointment_macro':
        return {
          name: inputValue,
          type: macroType,
          body: JSON.stringify({
            clinicalNote: response?.clinicalNote,
            advancedHP: response?.advancedHP,
            advancedSOAP: response?.advancedSOAP,
            advancedCustomNote: response?.customFormTypes
          })
        };
      case 'note_macro':
        return createNoteMacroObject({
          name: inputValue,
          type: macroType,
          formType: type,
          currentMacroFields: findNoteMacroType(type),
          advancedHP: response?.advancedHP,
          advancedSOAP: response?.advancedSOAP,
          advancedCustomNote: response?.customFormTypes,
          clinicalNote: response?.clinicalNote
        });
      case 'form_macro':
        const body =
          customMacroBody?.advancedHP ||
          customMacroBody?.advancedSOAP ||
          customMacroBody?.advancedCustomNote
            ? JSON.stringify(customMacroBody)
            : JSON.stringify({ clinicalNote: customMacroBody });

        return {
          name: inputValue,
          type: macroType,
          body
        };
      case 'text_macro':
        return {
          name: inputValue,
          type: macroType,
          body: JSON.stringify(textareaValue)
        };
    }
  };

  const handleCreate = async (values) => {
    const macroToBeCreated = await createMacroType(values);
    await createMacro(macroToBeCreated);
    handleClose();
    clearInputs();
  };

  const clearInputs = () => {
    formik.resetForm();
  };

  const macroValidationSchema = Yup.object().shape({
    inputValue: Yup.string().required('Name is required'),
    ...(macroType === 'text_macro' && {
      textareaValue: Yup.string().required('Text area is required')
    })
  });

  const formik = useFormik({
    initialValues: {
      inputValue: defaultInputValue,
      textareaValue: defaultTextareaValue
    },
    enableReinitialize: true,
    validationSchema: macroValidationSchema,
    onSubmit: (values) => handleCreate(values)
  });

  if (!isModal) {
    return (
      <>
        <Input
          id={`${macroTitle}-input-name`}
          label={`${Capitalize(macroTitle)} Label`}
          data-qa="enter-appointment-template-label"
          placeholder={`Enter ${macroTitle} label`}
          name="inputValue"
          autoComplete="off"
          value={formik?.values?.inputValue}
          onChange={(event) => formik.setFieldValue('inputValue', event.target.value)}
          required={true}
          error={formik?.errors?.inputValue}
        />
        {macroType === 'text_macro' && (
          <div className="!mt-3">
            <Textarea
              className={textAreaClassName}
              id={`${macroTitle}-textarea`}
              label={
                <p className="flex">
                  {Capitalize(macroTitle)} Area<span className="text-danger-500">*</span>
                </p>
              }
              name="textareaValue"
              data-qa={`enter-${macroTitle}-label`}
              placeholder={`Enter ${macroTitle} area`}
              value={formik.values?.textareaValue}
              onChange={(value) => formik.setFieldValue('textareaValue', value)}
              error={formik?.errors?.textareaValue}
              isEditor={isEditor}
            />
          </div>
        )}
        <div
          className={cs(
            'Modal__footer',
            'flex justify-center border-0 bg-primary-10 !p-0 shadow-none'
          )}>
          {footer ? (
            <div className="flex justify-center">
              {footer({
                submit: formik.handleSubmit,
                isSubmitting: formik.isSubmitting,
                values: formik?.values
              })}
            </div>
          ) : null}
        </div>
      </>
    );
  }

  return (
    <Modal
      className={className}
      isOpen={open}
      handleClose={handleClose}
      title={`Create New ${macroTitle}`}
      slideFromRight={slideFromRight}
      footerClassName={footer && 'bg-primary-10 shadow-none border-0 !p-0 flex justify-center'}
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            onClick={handleClose}
            outlined
            text="Cancel"
            color="neutral"
            data-qa="cancel-cnat-btn"
          />
          <Button
            loading={formik.isSubmitting}
            disabled={formik.isSubmitting}
            onClick={formik.handleSubmit}
            text={`Create New ${Capitalize(macroTitle)}`}
            data-qa="create-new-appointment-macro-btn"
          />
        </div>
      }>
      <Input
        id={`${macroTitle}-input-name`}
        label={`${Capitalize(macroTitle)} Label`}
        data-qa="enter-appointment-template-label"
        placeholder={`Enter ${macroTitle} label`}
        name="inputValue"
        autoComplete="off"
        value={formik.values?.inputValue}
        onChange={(event) => formik.setFieldValue('inputValue', event.target.value)}
        required={true}
        error={formik?.errors?.inputValue}
      />

      {macroType === 'text_macro' && (
        <div className="!mt-3">
          <Textarea
            id={`${macroTitle}-textarea`}
            label={
              <p className="flex">
                {Capitalize(macroTitle)} Area<span className="text-danger-500">*</span>
              </p>
            }
            name="textareaValue"
            data-qa={`enter-${macroTitle}-label`}
            placeholder={`Enter ${macroTitle} area`}
            value={formik.values?.textareaValue}
            onChange={(event) => formik.setFieldValue('textareaValue', event.target.value)}
            error={formik?.errors?.textareaValue}
          />
        </div>
      )}
    </Modal>
  );
};

export default CreateMacro;
