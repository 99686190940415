import { useEffect } from 'react';

import cs from 'classnames';
import { useField } from 'formik';
import { useRecoilValue } from 'recoil';

import { ia } from 'lib/helpers/utility';

import practiceState from 'components/practice/practiceState';
import Select from 'components/shared/Select/Select';

const PracticeSelect = ({ className }) => {
  const [field, meta, { setValue }] = useField({
    name: 'orderLocation'
  });

  const currentPractice = useRecoilValue(practiceState.currentPractice);
  const ownerPractices = useRecoilValue(practiceState.ownerPractices);
  const ownerSelectedLocation = useRecoilValue(practiceState.ownerSelectedLocation);

  const currentUserOptions = ia(ownerPractices)
    ? ownerPractices.map((practice) => ({
        label: practice.name,
        value: practice.id
      }))
    : [
        {
          label: currentPractice.name,
          value: currentPractice.id
        }
      ];

  useEffect(() => {
    if (ownerSelectedLocation) {
      const option = currentUserOptions?.find((opt) => opt.value === ownerSelectedLocation);
      if (option) setValue(option);
    } else if (ownerPractices.length === 0) {
      setValue(currentUserOptions[0]);
    }
  }, []);

  const changeHandler = (option) => {
    setValue(option);
  };

  return (
    <Select
      {...field}
      error={meta.touched && meta.error}
      isClearable={false}
      data-qa="order-location-select"
      inputId="orderLocationSelect"
      label="Location"
      required
      options={currentUserOptions}
      parentClassName={cs(className)}
      onChange={changeHandler}
    />
  );
};

export default PracticeSelect;
