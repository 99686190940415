import React, { useState } from 'react';
import stepName from './stepName';
import Icon from '../Icon/Icon';
import PreAuthFormModal from '../Modal/PreAuthFormModal/PreAuthFormModal';

const CheckinStepsPreAuth = ({ step, index, appointment }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <div
        key={`checkin-steps-pre-auth-${index}`}
        className="flex cursor-pointer items-center"
        onClick={() => setShow(true)}>
        {step?.completed ? (
          <Icon icon="checkmark-circle" color="primary" />
        ) : (
          <Icon icon="new-close-circle" />
        )}
        <p className="!mx-2 text-sm font-400">{stepName(step)}</p>
      </div>
      <PreAuthFormModal
        showPreAuthModal={show}
        hidePreAuthModal={() => setShow(false)}
        appointment={appointment}
        practiceId={appointment?.practice?.id}
        practiceName={appointment?.practice?.name}
        patientId={appointment?.patient?.id}
        patientName={appointment?.patient?.fullName}
        billingAddress={appointment?.patient?.userAddress}
        type="annual"
      />
    </>
  );
};

export default CheckinStepsPreAuth;
