import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getUnreadFaxes } from '../../../../api/Fax';

export const useUnreadFaxes = () => {
  const navigate = useNavigate();

  return useQuery(['unreadFaxes'], () => getUnreadFaxes(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
};
