import cs from 'classnames';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import Input from '../../../../../../../shared/Input/Input';
import RadioButton from '../../../../../../../shared/RadioButton/RadioButton';

import { debounce } from 'lodash';
import { iaRa, isEmpty } from '../../../../../../../../lib/helpers/utility';
import { processTranscriptData } from '../lib/helpers';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const SPEAKER_OPTIONS = ['Provider', 'Patient', 'Other'];

const RecordedSpeakers = ({ transcriptEnhanced, className = '', actionRef }) => {
  const { transcriptData, setTranscriptData } = useAmbientListeningContext();

  const [defaultInputValue, setDefaultInputValue] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState({});

  const currentTranscriptDataId = transcriptEnhanced?.id ?? transcriptEnhanced?.temp_id;
  const transcriptEnhancedSpeakers = transcriptEnhanced?.speakers;

  const renameSpeaker = ({ currentSpeakerId, newName }) => {
    if (isEmpty(newName) || !currentTranscriptDataId) return;

    setTranscriptData((prevState) =>
      processTranscriptData({ prevState, currentTranscriptDataId, currentSpeakerId, newName })
    );
  };

  const debouncedRenameSpeaker = debounce((args) => {
    renameSpeaker(args);
  }, 350);

  const selectSpeaker = ({ row, name }) => {
    setSelectedSpeaker((prevState) => ({ ...prevState, [row]: name }));

    if (name == 'Other') {
      return;
    }

    renameSpeaker({
      currentSpeakerId: row,
      newName: name
    });
  };

  const defaultSpeakers = ({ transcriptSpeakers = transcriptEnhancedSpeakers }) => {
    const preSelectedKeys = ['Provider', 'Patient'];
    const speakers = iaRa(transcriptSpeakers).reduce((acc, speaker) => {
      if (preSelectedKeys.includes(speaker.new_name)) {
        acc[speaker.id] = speaker.new_name;
        return acc;
      }

      setDefaultInputValue((prevState) => ({ ...prevState, [speaker.id]: speaker.new_name }));
      acc[speaker.id] = 'Other';

      return acc;
    }, {});

    setSelectedSpeaker(speakers);
  };

  useEffect(() => {
    defaultSpeakers({ transcriptSpeakers: transcriptEnhancedSpeakers });
  }, []);

  const sameAsOriginal = () => {
    const originalSpeaker = transcriptData?.[0]?.speakers;

    if (!originalSpeaker) return;

    const updatedSpeakers = iaRa(transcriptEnhancedSpeakers)?.map((speaker) => {
      const matchingSpeaker = originalSpeaker.find(
        (obj) => obj.initial_name === speaker.initial_name
      );

      if (matchingSpeaker) {
        renameSpeaker({ currentSpeakerId: speaker.id, newName: matchingSpeaker.new_name });

        return {
          ...speaker,
          new_name: matchingSpeaker.new_name,
          old_name: speaker.new_name
        };
      }

      return speaker;
    });

    defaultSpeakers({ transcriptSpeakers: updatedSpeakers });

    setTranscriptData((prevState) =>
      iaRa(prevState).map((data) =>
        (data?.id || data?.temp_id) === currentTranscriptDataId
          ? { ...data, speakers: updatedSpeakers }
          : data
      )
    );
  };

  useImperativeHandle(actionRef, () => ({
    applyOriginalSpeakers: () => sameAsOriginal()
  }));

  return (
    <div
      className={cs('flex flex-wrap justify-between !rounded-lg bg-primary-50 !px-4', className)}>
      {iaRa(transcriptEnhancedSpeakers).map((row) => {
        const rowId = row?.id;

        return (
          <div key={rowId} className="!py-3">
            <p className="font-500 text-primary-700">Speaker {row.initial_name}</p>
            <div className="!mt-[6px] flex gap-3">
              {SPEAKER_OPTIONS.map((name, i) => {
                const uniqueId = `${rowId}-${name}-${i}`;

                return (
                  <RadioButton
                    id={uniqueId}
                    key={uniqueId}
                    label={name}
                    className="before:!bg-white"
                    isSelected={selectedSpeaker[rowId] === name}
                    onChange={() => selectSpeaker({ row: rowId, name })}
                  />
                );
              })}
            </div>

            {selectedSpeaker[rowId] === 'Other' && (
              <div className="!mt-3">
                <Input
                  id={rowId}
                  onChange={(e) =>
                    debouncedRenameSpeaker({
                      currentSpeakerId: rowId,
                      newName: e?.target?.value ?? ''
                    })
                  }
                  defaultValue={defaultInputValue[rowId]}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RecordedSpeakers;
