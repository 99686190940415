import React from 'react';
import cs from 'classnames';
import { Popover } from '@headlessui/react';

const CustomFormPopover = ({
  button = null,
  panel = null,
  buttonClassName = null,
  panelClassName = null,
  popoverClassName = null
}) => (
  <Popover className={cs('relative w-fit', popoverClassName)}>
    <Popover.Button className={cs(buttonClassName)}>{button}</Popover.Button>
    <Popover.Panel
      className={cs(
        'absolute right-0 z-50 !mt-1 w-max cursor-pointer rounded-lg bg-white shadow-md',
        panelClassName
      )}>
      {panel}
    </Popover.Panel>
  </Popover>
);

export default CustomFormPopover;
