import cs from 'classnames';

import Switch from 'components/shared/Switch/Switch';
import Header from 'components/shared/shared/Header';

import LabSelect from './LabSelect';
import OrderCodeSearchInput from './OrderCodeSearchInput';
import TestNameSearchInput from './TestNameSearchInput';

const TestListFilter = ({ filters, filtersChangeHandler }) => {
  return (
    <div className="grid grid-cols-1 gap-4 p-3">
      <div className="">
        <Header label="Search by:" />
        <div className="flex gap-2 items-center pt-1">
          <p className={cs('text-xs', filters.searchType === 'name' && 'font-600')}>Name</p>
          <Switch
            onColor="#b0eaff"
            offColor="#b0eaff"
            onHandleColor="#13b9ff"
            offHandleColor="#13b9ff"
            onChange={(checked) =>
              filtersChangeHandler('searchType', checked ? 'order-code' : 'name')
            }
            checked={filters.searchType === 'order-code'}
          />
          <p className={cs('text-xs', filters.searchType === 'order-code' && 'font-600')}>
            Order Code
          </p>
        </div>
      </div>
      {filters.searchType === 'name' ? (
        <TestNameSearchInput value={filters.searchTerm} changeHandler={filtersChangeHandler} />
      ) : (
        <OrderCodeSearchInput value={filters.orderCodes} changeHandler={filtersChangeHandler} />
      )}

      <LabSelect selectedLab={filters.selectedLab} changeHandler={filtersChangeHandler} />
    </div>
  );
};

export default TestListFilter;
