import React from 'react';
import humanizeEvent from '../lib/humanizeEvent';
import { currentPractice as currPractice } from '../../../practiceState';
import { useRecoilValue } from 'recoil';
import Textarea from '../../../../shared/Textarea/Textarea';
import Icon from '../../../../shared/Icon/Icon';

const FooterEventForm = ({ formik, event }) => {
  const currentPractice = useRecoilValue(currPractice);
  const { handleChange, values } = formik;

  return (
    <>
      <Textarea
        data-qa="description"
        label="Description"
        onChange={handleChange}
        value={values.description}
        name="description"
      />
      {event && event.repeat_event && (
        <div className="flex items-center gap-1">
          <Icon icon="new-info" color="primary" />
          <p className="text-primary-600 text-sm">
            {humanizeEvent(event, currentPractice?.timezone)}
          </p>
        </div>
      )}
    </>
  );
};

export default FooterEventForm;
