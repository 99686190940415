import cs from 'classnames';
import React from 'react';
import './Status.scss';
import { snakeToTitleCase } from 'lib/helpers/utility';

const Status = ({ status, className, textClassName, color }) => {
  return (
    <div
      className={cs(
        'Status',
        `Status--${status?.replaceAll(' ', '-')}`,
        color && `Color Color--${color}`,
        className
      )}>
      <span className={textClassName}>{snakeToTitleCase(status)}</span>
    </div>
  );
};

export default Status;
