import * as Yup from 'yup';

export const GuarantorValidationSchema = Yup.object().shape({
  patient_id: Yup.string().optional(),
  f_name: Yup.string().required('First name is required'),
  l_name: Yup.string().required('Last name is required'),
  m_name: Yup.string().required('Middle name is required'),
  email: Yup.string().email('Invalid input for email').required('Email is required'),
  gender: Yup.string().required('Gender is required'),
  dob: Yup.date().required('Date of birth is required'),
  home_phone: Yup.string().required('Phone is required'),
  work_phone: Yup.string().nullable(),
  relationship: Yup.string().required('Relationship is required'),
  address: Yup.number().optional(),
  guarantorAddress: Yup.object().shape({
    address_ln_1: Yup.string().required('Address line 1 is required'),
    address_ln_2: Yup.string().nullable(),
    city: Yup.string().required('City is required'),
    state: Yup.number().required('State is required'),
    zip: Yup.string().required('Zip code is required')
  })
});
