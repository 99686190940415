import React, { useState } from 'react';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import EditRoleModal from './EditRole/EditRoleModal';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import { useDeleteRole } from 'lib/hooks/queries/roles/useDeleteRole';
import { showAlert } from 'components/shared/Alert/Alert';
import { queryClient } from 'App';
import { pm } from 'lib/helpers/utility';
import state from 'components/state';
import { useRecoilValue } from 'recoil';

const Actions = ({ data }) => {
  const [showDeleteRolelModal, setShowDeleteRolelModal] = useState(false);
  const { showEditRoleModal, setShowEditRoleModal } = useEditRoleContext();
  const permissions = useRecoilValue(state.permissions);
  const { id } = data;

  const { mutateAsync, isLoading } = useDeleteRole({
    params: {
      role_id: id
    },
    dependencies: [id],
    options: {
      onSuccess: (deleteResponse) => {
        setShowDeleteRolelModal(false);
        if (deleteResponse.code === 0) {
          showAlert({
            title: `Role deleted successfully`,
            color: 'success'
          });
        } else {
          showAlert({
            title: `Failed to delete role`,
            color: 'danger'
          });
        }
        queryClient.invalidateQueries('getRole');
      }
    }
  });

  const deleteRoleMessage =
    data?.usersAssigned === '0'
      ? 'Are you sure you want to delete this role?'
      : 'You cannot delete this role, because some users still use this role. Please assign them a different role and try again!';

  const roleOptions = [
    ...(pm(permissions, 'role.update')
      ? [
          {
            label: 'Edit Role',
            icon: 'new-user-edit',
            onClick: () => {
              setShowEditRoleModal(true);
            }
          }
        ]
      : []),
    ...(pm(permissions, 'role.delete')
      ? [
          {
            label: 'Delete Role',
            icon: 'new-delete-patient',
            onClick: () => {
              setShowDeleteRolelModal(true);
            }
          }
        ]
      : [])
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={roleOptions}
      />
      {showDeleteRolelModal && (
        <Confirm
          handleOpen={showDeleteRolelModal}
          handleClose={() => setShowDeleteRolelModal(false)}
          handleContinue={async () => {
            await mutateAsync();
          }}
          title="Delete Role"
          message={deleteRoleMessage}
          primaryBtnTxt="Delete Role"
          secondaryBtnTxt="Keep"
          icon="new-delete-patient"
          variant="danger"
          disabled={isLoading || data.usersAssigned !== '0'}
          loading={isLoading}
        />
      )}
      {showEditRoleModal && (
        <EditRoleModal
          showModal={showEditRoleModal}
          hideModal={() => setShowEditRoleModal(false)}
          roleName={data?.name}
          roleType={data?.type}
          roleDescription={data?.description}
          roleId={data?.id}
        />
      )}
    </>
  );
};

export default withErrorBoundary(Actions);
