import React from 'react';
import SSOAction from '../../../shared/ePrescribe/SSOAction';
import {
  IDProofing,
  messageQueue,
  standard,
  taskMode,
  userOptions,
  utilityMode
} from '../../../shared/ePrescribe/SSOKinds';

const EPrescribeSettings = () => {
  return (
    <div className="!p-3">
      <div>ePrescribe Settings</div>
      <SSOAction kind={IDProofing} />
      <SSOAction kind={messageQueue} />
      <SSOAction kind={taskMode} />
      <SSOAction kind={userOptions} />
      <SSOAction kind={standard} />
      <SSOAction kind={utilityMode} />
      <div>Powered by Veradigm</div>
    </div>
  );
};

export default EPrescribeSettings;
