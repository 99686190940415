import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ReactFormGenerator } from 'react-form-builder2';

import { requestApi } from 'api/Api';
import { interimApi } from 'api/InterimApi';
import Input from 'components/shared/Input/Input';
import { documentCategories } from 'constants.js';
import Select from 'components/shared/Select/Select';
import Button from 'components/shared/Buttons/Button';
import Loading from 'components/shared/Loading/Loading';
import Card from 'components/practice/charts/Documents/Card';
import { AlertContent } from 'components/shared/Alert/Alert';
import HipaaForm from 'components/shared/Forms/HipaaForm/HipaaForm';

const View = ({
  title,
  handle,
  type,
  patientId,
  setViewDocument,
  action,
  category,
  getDocuments,
  setIsEditOpen,
  setId,
  setInsurance
}) => {
  const [loading, setLoading] = useState({
    personal_id_front: false,
    personal_id_back: false,
    insurance_front: false,
    insurance_back: false,
    filestack: false
  });
  const [url, setUrl] = useState();
  const [hipaaData, setHipaaData] = useState();
  const [edit, setEdit] = useState({ name: title, category });
  const navigate = useNavigate();

  useEffect(() => {
    if (handle && type !== 'form') getUrl();
  }, [handle]);

  const getUrl = async () => {
    setLoading((prev) => ({ ...prev, filestack: true }));

    try {
      if (
        [
          'image',
          'image/jpg',
          'image/jpeg',
          'image/png',
          'pdf',
          'application/pdf',
          'rtf',
          'application/rtf',
          'eml',
          'application/eml',
          'custom/html',
          'message/rfc822'
        ].includes(type)
      ) {
        const res = await interimApi('/api/filestack/read', { handle, patientId }, navigate);
        const { code, redirect, error, file } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 0:
            setUrl(file);
            break;
          default:
            toast.error("Couldn't load the file");
            console.error(error);
            break;
        }
      } else if (type === 'id') {
        let front, back;

        if (handle?.front) {
          const frontRes = await interimApi(
            '/api/filestack/read',
            { handle: handle?.front },
            navigate
          );
          const {
            code: frontCode,
            redirect: frontRedirect,
            error: frontError,
            file: frontFile
          } = frontRes.data;
          switch (frontCode) {
            case -1:
              navigate(frontRedirect);
              break;
            case 0:
              front = frontFile;
              break;
            default:
              toast.error("Couldn't load the file");
              console.error(frontError);
              break;
          }
        }

        if (handle?.back) {
          const backRes = await interimApi(
            '/api/filestack/read',
            { handle: handle?.back },
            navigate
          );
          const {
            code: backCode,
            redirect: backRedirect,
            error: backError,
            file: backFile
          } = backRes.data;
          switch (backCode) {
            case -1:
              navigate(backRedirect);
              break;
            case 0:
              back = backFile;
              break;
            default:
              toast.error("Couldn't load the file");
              console.error(backError);
              break;
          }
        }

        setUrl({ front, back });
      } else if (type === 'insurance') {
        let front, back;

        if (handle?.front) {
          const frontRes = await interimApi(
            '/api/filestack/read',
            { handle: handle?.front },
            navigate
          );

          const {
            code: frontCode,
            redirect: frontRedirect,
            error: frontError,
            file: frontFile
          } = frontRes.data;
          switch (frontCode) {
            case -1:
              navigate(frontRedirect);
              break;
            case 0:
              front = frontFile;
              break;
            default:
              toast.error("Couldn't load the file");
              console.error(frontError);
              break;
          }
        }

        if (handle?.back) {
          const backRes = await interimApi(
            '/api/filestack/read',
            { handle: handle?.back },
            navigate
          );
          const {
            code: backCode,
            redirect: backRedirect,
            error: backError,
            file: backFile
          } = backRes.data;
          switch (backCode) {
            case -1:
              navigate(backRedirect);
              break;
            case 0:
              back = backFile;
              break;
            default:
              toast.error("Couldn't load the file");
              console.error(backError);
              break;
          }
        }

        setUrl({ front, back });
      } else if (type === 'hipaa') {
        if (handle?.url) {
          const res = await requestApi({
            url: '/api/filestack/read',
            params: { handle: handle?.url },
            navigate
          });
          const { code, file } = res;
          switch (code) {
            case 0:
              setUrl(file);
              break;
          }
        } else if (handle?.data) {
          if (handle?.data) {
            let hipaaJSON = JSON.parse(handle?.data);
            setHipaaData(decodeURIComponent(hipaaJSON?.htmlData));
          } else {
            setHipaaData(<div>No HIPAA Data</div>);
          }
        }
      }
    } catch (error) {
      toast.error("Couldn't load the file");
    }

    setLoading((prev) => ({ ...prev, filestack: false }));
  };

  const handleEdit = async () => {
    try {
      const { data } = await interimApi(
        '/api/practice/charts/documents/update',
        {
          handle,
          name: edit.name,
          category: edit.category.value
        },
        navigate
      );

      if (data?.update > 0) {
        toast.success('Document edited successfully.');

        getDocuments();

        setViewDocument({ load: false, document: {} });
      } else {
        toast.error('There was a problem while editing document.');
      }
    } catch (error) {
      toast.error('There was a problem while editing document.');
      console.error(error);
    }
  };

  const submitFormButton = useRef();

  const handleFormSubmit = async (e, responseId, appointmentId, formId, formVersion) => {
    const updatedResponses = e?.map((response) => {
      return response.name
        ? { ...response }
        : { ...response, name: 'no_name', custom_name: 'no_custom_name' };
    });

    try {
      let params = {
        responseId,
        appointmentId,
        patientId,
        formId,
        json: { fields: JSON.stringify(updatedResponses) },
        formVersion
      };

      let res = await interimApi('/api/form/upsert_response', params, navigate);

      if (res.data.upsert) {
        toast.success('Document edited successfully.');

        getDocuments();

        setIsEditOpen(false);

        setViewDocument({ load: false, document: {} });
      } else {
        toast.error('Necessary permissions not present or error encountered');
      }
    } catch (error) {
      toast.error('There was a problem while editing document.');
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setViewDocument({ load: false, document: {} });
    setIsEditOpen(false);
  };

  return (
    <div className="DocumentsCenterModal">
      {title && <h3>{title}</h3>}
      {(() => {
        if (loading?.filestack) {
          return <Loading />;
        }
        if (type === 'image' || type === 'image/jpeg' || type === 'image/png') {
          return <img src={url} />;
        } else if (type === 'hipaa' || type === 'pdf' || type === 'application/pdf') {
          return type === 'hipaa' && !url ? (
            hipaaData ? (
              <div dangerouslySetInnerHTML={{ __html: hipaaData }}></div>
            ) : (
              <>
                <AlertContent
                  className="mb-[1rem] w-full"
                  color="warning"
                  title="Warning"
                  message="HIPAA form was not signed by the patient yet."
                />
                <HipaaForm disabled={true} />
              </>
            )
          ) : (
            <iframe src={url} style={{ width: '100%', height: 'calc(100vh - 11.25rem)' }}></iframe>
          );
        } else if ([].includes(type)) {
          return (
            <div>
              <iframe src={url} />
            </div>
          );
        } else if (
          ['application/html', 'application/rtf', 'html', 'rtf', 'custom/html'].includes(type)
        ) {
          return (
            <div className="h-[80vh] rounded-2xl bg-white p-0.5">
              <iframe srcDoc={url} className="h-full w-full bg-white" />
            </div>
          );
        } else if (['application/eml', 'eml', 'message/rfc822'].includes(type)) {
          if (!url?.[0] || !url?.[1]) {
            return <Loading />;
          }
          return (
            <div className="h-[80vh] rounded-2xl bg-white p-0.5">
              <div dangerouslySetInnerHTML={{ __html: url[0] }} className="h-fit w-full bg-white" />
              <hr className="m-0" />
              <iframe srcDoc={url[1]} className="h-[70vh] w-full bg-white" />
            </div>
          );
        } else if (type === 'id') {
          return (
            <div className="IdAndInsurance">
              <Card
                setId={setId}
                handle={handle}
                patientId={patientId}
                setViewDocument={setViewDocument}
                label="Front"
                loading={loading.personal_id_front}
                setLoading={setLoading}
                url={url?.front}
                type="personal_id_front"
              />
              <Card
                setId={setId}
                handle={handle}
                patientId={patientId}
                setViewDocument={setViewDocument}
                label="Back"
                loading={loading.personal_id_back}
                setLoading={setLoading}
                url={url?.back}
                type="personal_id_back"
              />
            </div>
          );
        } else if (type === 'insurance') {
          return loading.filestack ? (
            <Loading />
          ) : (
            <div className="IdAndInsurance">
              <Card
                setInsurance={setInsurance}
                handle={handle}
                patientId={patientId}
                setViewDocument={setViewDocument}
                label="Front"
                loading={loading.insurance_front}
                setLoading={setLoading}
                url={url?.front}
                type="insurance_front"
              />
              <Card
                setInsurance={setInsurance}
                handle={handle}
                patientId={patientId}
                setViewDocument={setViewDocument}
                label="Back"
                loading={loading.insurance_back}
                setLoading={setLoading}
                url={url?.back}
                type="insurance_back"
              />
            </div>
          );
        } else if (type === 'form') {
          if (action === 'Edit') {
            return (
              <div className="new-forms">
                <ReactFormGenerator
                  answer_data={JSON.parse(handle?.json?.fields)}
                  data={handle?.form_json?.fields}
                  submitButton={
                    <div className="ResizableModal__footer justify-between bg-white">
                      <Button outlined color="neutral" onClick={handleCloseModal} text="Cancel" />
                      <Button
                        text="Update"
                        color="primary"
                        buttonType="submit"
                        forwardedRef={submitFormButton}
                      />
                    </div>
                  }
                  onSubmit={(e) =>
                    handleFormSubmit(
                      e,
                      handle?.response_id,
                      handle?.appointment_id,
                      handle?.form_id,
                      handle?.form_version
                    )
                  }
                />
              </div>
            );
          } else {
            return (
              <div className="new-forms">
                <ReactFormGenerator
                  answer_data={JSON.parse(handle?.json?.fields)}
                  data={handle?.form_json?.fields}
                  submitButton={<div />}
                  read_only={true}
                />
              </div>
            );
          }
        } else {
          return <p>Preview is not available.</p>;
        }
      })()}

      {!loading.filestack && type !== 'form' && action === 'Edit' && (
        <div className="EditWrapper">
          <h3>Edit</h3>
          <div className="EditDocumentsForm">
            <Input
              label="Document name"
              placeholder="Filename"
              value={edit.name}
              onChange={(e) => setEdit({ ...edit, name: e.target.value })}
            />
            <div>
              <Select
                isClearable
                label="Document Category"
                options={documentCategories}
                value={edit.category}
                className="Select"
                placeholder="Category"
                onChange={(e) => setEdit({ ...edit, category: e })}
                menuPortalTarget={document.body}
              />
            </div>
          </div>
          <div className="ResizableModal__footer justify-between bg-white">
            <Button outlined onClick={handleCloseModal} text="Cancel" color="neutral" />
            <Button color="primary" onClick={() => handleEdit()} text="Update" />
          </div>
        </div>
      )}
    </div>
  );
};

export default View;
