import { requestApi } from './Api';

export const getIcd10Codes = async (navigate, params) =>
  await requestApi({
    url: '/api/codes/icd10/get',
    navigate,
    params
  });

  export const deleteIcd10Code = async (navigate, params) => {
    return await requestApi({
      navigate,
      url: '/api/codes/icd10/delete',
      params
    });
  };
