export const getNdcMeasureOptions = () => {
  return [
    {
      value: 'f2',
      label: 'INTERNATIONAL UNIT (F2)'
    },
    {
      value: 'me',
      label: 'MILLIGRAM (ME)'
    },
    {
      value: 'gr',
      label: 'GRAM (GR)'
    },
    {
      value: 'ml',
      label: 'MILLILITER (ML)'
    },
    {
      value: 'un',
      label: 'UNIT (UN)'
    }
  ];
};
export const getLabelForMeasureValue = (value) => {
  const options = getNdcMeasureOptions();
  const option = options.find((option) => option.value === value);
  return option ? option.label : '';
};
