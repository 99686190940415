import {
  FilterCheckbox,
  FilterDateRange,
  FilterInfiniteScroll,
  FilterMultipleSearch,
  FilterRange,
  FilterSearch,
  FilterSelect
} from './components';

const Content = ({ filters, setFilter, menuPortalTarget, defaultFilters, numberOfCols }) => {
  return (
    <div
      className={`grid gap-x-5 gap-y-6 overflow-y-auto overflow-x-hidden py-[18px] px-[20px] grid-cols-${numberOfCols}`}>
      {Object.keys(filters)?.map((key) => {
        switch (filters[key].type) {
          case 'date-range':
            return (
              <FilterDateRange key={key} index={key} data={filters[key]} setFilter={setFilter} />
            );
          case 'search':
            return filters[key].multiple ? (
              <FilterMultipleSearch
                key={key}
                index={key}
                data={filters[key]}
                setFilter={setFilter}
                placeholder={filters[key]?.placeholder}
                icon={filters[key]?.icon}
                menuPortalTarget={menuPortalTarget}
              />
            ) : (
              <FilterSearch
                key={key}
                index={key}
                data={filters[key]}
                setFilter={setFilter}
                placeholder={filters[key]?.placeholder}
              />
            );
          case 'checkbox':
            return (
              <FilterCheckbox
                key={key}
                index={key}
                data={filters[key]}
                setFilter={setFilter}
                defaultFilters={defaultFilters}
              />
            );
          case 'select':
            return (
              <FilterSelect
                key={key}
                index={key}
                data={filters[key]}
                setFilter={setFilter}
                defaultFilters={defaultFilters}
                menuPortalTarget={menuPortalTarget}
                placeholder={filters[key]?.placeholder}
              />
            );
          case 'range':
            return (
              <FilterRange
                key={key}
                index={key}
                data={filters[key]}
                setFilter={setFilter}
                defaultFilters={defaultFilters}
              />
            );
          case 'infinite-scroll':
            return (
              <FilterInfiniteScroll
                key={key}
                index={key}
                data={filters[key]}
                queryKey={filters[key]?.queryKey}
                queryFn={filters[key]?.queryFn}
                queryParams={filters[key]?.params}
                formatData={filters[key]?.formatData}
                placeholder={filters[key]?.placeholder}
                setFilter={setFilter}
                defaultFilters={defaultFilters}
              />
            );
        }
      })}
    </div>
  );
};

export default Content;
