import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import { ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import FormPacketAppointment from 'components/practice/appointment/components/FormPacketAppointment/FormPacketAppointment';
import { useFormikContext } from 'formik';

const FormPacketModal = ({ showFormPacketModal, hideFormPacketModal }) => {
  const { values: appointment, setFieldValue } = useFormikContext();
  const [formPacketLoading, setFormPacketLoading] = useState(false);
  const [formPackets, setFormPackets] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    showFormPacketModal && getFormPackets();
  }, [showFormPacketModal]);

  const extractFormIds = () => {
    let formIds = [];
    formPackets.forEach((formPacket) => {
      return formPacket.form_ids.forEach(
        (form) => form.checked && !formIds.includes(form.value) && formIds.push(form.value)
      );
    });

    setFieldValue('form_ids', formIds);
    hideFormPacketModal();
  };

  const getFormPacketsSelected = async (formPackets) => {
    try {
      const { form_ids } = appointment;
      let allFormIds = ia(appointment.form_ids) ? appointment.form_ids : form_ids;

      return setFormPackets(
        formPackets.map((formPacket) => {
          return {
            ...formPacket,
            form_ids: formPacket.form_ids.map((form) => {
              const doesSameIdExistHere = allFormIds?.includes(form.value);
              return doesSameIdExistHere ? { ...form, checked: true } : { ...form, checked: false };
            })
          };
        })
      );
    } catch (error) {
      Honeybadger.notify(
        `appointmentId: ${appointment.id}, file: FormPacketAppointment/FormPacketModal, method: getFormPacketsSelected, error: ${error}`
      );
    }
  };

  const getFormPackets = async () => {
    try {
      setFormPacketLoading(true);
      const result = await interimApi(
        '/api/practice/settings/forms/form_packet/get',
        {
          withGraphFetched: { allForms: true, activeOnly: true },
          userId: appointment.user_id
        },
        navigate
      );
      let { packets } = result.data;

      if (ia(packets)) {
        await getFormPacketsSelected(packets);
      }
      setFormPacketLoading(false);
    } catch (error) {
      Honeybadger.notify(
        `appointmentId: ${appointment.id}, file: FormPacketAppointment/FormPacketModal, method: getFormPackets, error: ${error}`
      );
    }
  };

  return (
    <Modal
      handleOpen={showFormPacketModal}
      handleClose={hideFormPacketModal}
      title="Form Packets"
      className="w-[1200px]"
      slideFromRight
      subModal
      prevButton={<Button icon="back-large" transparent onClick={hideFormPacketModal} />}
      footer={
        <div className="flex w-full justify-between">
          <Button
            text="Close"
            color="neutral"
            onClick={hideFormPacketModal}
            data-qa="close-modal-btn"
          />
          <Button text="Save" onClick={extractFormIds} data-qa="save-modal-btn" />
        </div>
      }>
      <FormPacketAppointment
        loading={formPacketLoading}
        formPackets={formPackets}
        setFormPackets={setFormPackets}
      />
    </Modal>
  );
};

export default FormPacketModal;
