import { showAlert } from '../../../../../shared/Alert/Alert';

export const handleAction = async ({
  component = null,
  type = null,
  method = null,
  setActiveView = () => null,
  actionRef = {}
}) => {
  const actionTypes = {
    referral_order: {
      save: () => {
        actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        if (component === 'preview') {
          setActiveView((prevState) => ({
            ...prevState,
            type: 'referral_order',
            component: 'create'
          }));
          return;
        }

        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    },
    non_erx_prescription: {
      save: () => {
        actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        if (component === 'preview') {
          setActiveView((prevState) => ({
            ...prevState,
            type: 'non_erx_prescription',
            component: 'create'
          }));
          return;
        }

        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    },
    lab_order: {
      save: () => {
        actionRef?.current?.onSubmit();
        return;
      },

      preview: () => setActiveView((prevState) => ({ ...prevState, component: 'preview' })),

      edit: () => setActiveView((prevState) => ({ ...prevState, component: 'create' })),

      back: () => {
        if (component === 'preview') {
          setActiveView((prevState) => ({ ...prevState, type: 'lab_order', component: 'create' }));
          return;
        }

        setActiveView({ type: 'initial', component: 'list' });
        return;
      }
    }
  };

  const actionMethod = actionTypes[type][method];

  if (!actionMethod) {
    showAlert({
      color: 'warning',
      title: "The method for the action you attempted to call doesn't exist."
    });
    return;
  }

  return await actionMethod();
};
