export default function areMedicationsFilled(medications = []) {
  const filteredMedications = medications?.filter((med) => !checkProps(med));
  return filteredMedications.length > 0;
}

function checkProps(obj) {
  for (let key in obj) {
    if (key === 'id') continue;

    if (obj[key] !== null && obj[key] !== '') {
      return false;
    }
  }
  return true;
}
