import React from 'react';
import { useFormikContext } from 'formik';
import Icon from 'components/shared/Icon/Icon';

const ChartID = () => {
  const { values } = useFormikContext();

  const customChartID = values?.patient?.practicePatientLink?.custom_id;

  if (!customChartID) return null;
  return (
    <div className="flex items-start justify-between gap-2 rounded-md border border-solid border-primary-200 bg-primary-50 px-2 py-1">
      <Icon icon="new-user-2" size={16} />
      <div className="flex items-center gap-[2px]">
        <p className="text-sm leading-4 text-primary-500">Chart ID:</p>
        <p className="text-sm font-500 leading-4 text-primary-500">{customChartID}</p>
      </div>
    </div>
  );
};

export default ChartID;
