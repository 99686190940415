import { useQuery } from '@tanstack/react-query';
import { getClaim } from '../../../../api/Claim';
import { useNavigate } from 'react-router-dom';

export const useClaim = ({ params = {}, options = {}, dependencies = [], name = 'claim' }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => getClaim(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
