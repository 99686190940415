import { upsertCustomFormResponse } from 'api/CustomForms';
import { iaRa } from 'lib/helpers/utility';
import { formatWeightLossDataForUpsert } from '../../../ProviderMedications/lib/helpers';

export const translateMedicationToWeightLossData = ({ medication, weight, medication_id }) => {
  if (!medication) {
    return {};
  }
  return {
    medication_id,
    days_supply: medication.days_supply,
    doses: medication.strength,
    dosesUnit: medication.unit,
    date: medication.start_date,
    notes: medication.note,
    location: medication.location,
    medication: { label: medication.medication, value: medication?.medication },
    weight,
    route: medication.route,
    product_ndc: medication.product_ndc,
    product_ndc_id: medication.product_ndc_id,
    quantity: medication.quantity,
    refills: medication.refills,
    sig: medication.sig,
    status: medication.status,
    prescriber: medication.prescriber,
    condition: medication.condition,
    end_date: medication.end_date
  };
};

export const translateMedicationToWeightLossDatav2 = (medication) => {
  if (!medication) {
    return {};
  }
  return {
    days_supply: medication.days_supply,
    doses: medication.strength,
    dosesUnit: medication.unit,
    date: medication.start_date,
    notes: medication.note,
    location: medication.location,
    medication: { label: medication.medication, value: medication?.medication },
    weight: medication?.weight,
    route: medication.route,
    product_ndc: medication.product_ndc,
    product_ndc_id: medication.product_ndc_id,
    quantity: medication.quantity,
    refills: medication.refills,
    sig: medication.sig,
    status: medication.status,
    prescriber: medication.prescriber,
    condition: medication.condition,
    end_date: medication.end_date
  };
};

export const translateWeightLossDataToMedication = (data) => {
  if (!data) {
    return {};
  }
  return {
    cfr_id: data.cfr_id,
    medication_id: data.medication_id,
    weight: data.weight,
    strength: data.doses,
    days_supply: data.days_supply,
    unit: data.dosesUnit,
    start_date: data.date,
    note: data.notes,
    location: data?.location || null,
    medication: data.medication?.value,
    route: data.route,
    product_ndc: data.product_ndc,
    product_ndc_id: data.product_ndc_id,
    quantity: data.quantity,
    refills: data.refills,
    sig: data.sig,
    status: data.status,
    prescriber: data.prescriber,
    condition: data.condition,
    end_date: data.end_date
  };
};
export const initialValues = (data) => {
  return {
    initial_weight: data?.initial_weight,
    goal_weight: data?.goal_weight
  };
};
export const ableToUpdateWhlWeight = (data) => {
  if (!data?.initial_weight && !data?.goal_weight) {
    return false;
  }
  return true;
};
export const upsertWeightLoss = async ({
  data,
  medication_id,
  navigate,
  setOverviewData,
  customFormsParams,
  patientId,
  appointmentId
}) => {
  const id = data.cfr_id;
  delete data?.cfr_id;
  const formatedData = formatWeightLossDataForUpsert(data);

  const translatedData = translateMedicationToWeightLossData({
    medication: formatedData,
    weight: formatedData?.weight,
    medication_id
  });
  const result = await upsertCustomFormResponse(navigate, {
    formId: id,
    patientId: customFormsParams?.id || patientId,
    appointmentId: customFormsParams?.appointmentId || appointmentId,
    response: { row: [translatedData] },
    type: 'weightLossAdministration'
  });

  if (id) {
    setOverviewData((prev) => ({
      ...prev,
      weightLossAdministration: prev?.weightLossAdministration?.map((weightLoss) => {
        if (weightLoss?.id === id) {
          return result.newResponse;
        }
        return weightLoss;
      })
    }));
  } else {
    setOverviewData((prev) => ({
      ...prev,
      weightLossAdministration: [result.newResponse, ...iaRa(prev?.weightLossAdministration)]
    }));
  }
};
