import React, { useRef } from 'react';
import { useRecoilValue } from 'recoil';
import humanizeEvent from '../lib/humanizeEvent';
import Icon from 'components/shared/Icon/Icon';
import Textarea from 'components/shared/Textarea/Textarea';
import { currentPractice as currPractice } from 'components/practice/practiceState';

const FooterEventForm = ({ formik, event }) => {
  const currentPractice = useRecoilValue(currPractice);
  const { handleChange, values } = formik;
  const descriptionRef = useRef();

  return (
    <>
      <Textarea
        data-qa="description"
        label="Description"
        onChange={handleChange}
        value={values.description}
        name="description"
        forwardedRef={descriptionRef}
      />
      {event && event.repeat_event && (
        <div className="flex items-center gap-1">
          <Icon icon="new-info" color="primary" />
          <p className="text-sm text-primary-600">
            {humanizeEvent(event, currentPractice?.timezone)}
          </p>
        </div>
      )}
    </>
  );
};

export default FooterEventForm;
