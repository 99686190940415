import { memo, useCallback, useEffect, useMemo } from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import AGTable from 'components/shared/AGTable/AGTable';

import EmptyTestsTableState from './EmptyTestsTableState';
import ExpandedTableComponent from './ExpandedTableComponent';

const Table = ({ getGridApi }) => {
  const { gridApi } = useTableContext();

  useEffect(() => {
    if (gridApi) {
      getGridApi(gridApi);
      gridApi?.setGridOption('rowData', []);
    }
  }, [gridApi]);

  const getRowId = useCallback(({ data }) => {
    return data.id;
  }, []);

  const defaultColDef = useMemo(
    () => ({
      flex: 1
    }),
    []
  );

  const detailCellRenderer = useMemo(() => {
    return ExpandedTableComponent;
  }, []);

  return (
    <AGTable
      noRowsOverlayComponent={EmptyTestsTableState}
      defaultColDef={defaultColDef}
      masterDetail
      getRowId={getRowId}
      detailCellRenderer={detailCellRenderer}
      detailRowAutoHeight
      keepDetailRows
      reactiveCustomComponents={true}
      customClassName=""
    />
  );
};

const SelectedTestsTable = memo(Table);

export default SelectedTestsTable;
