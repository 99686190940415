import { currentPractice } from '../../../practiceState';
import humanizeEvent from './humanizeEvent';
import repeatEventOptions from './repeatEventOptions';

export default function formatEvent(event) {
  const hasRecurrence = !!event?.repeat_event;
  const recurrenceType = repeatEventOptions.find(
    // eslint-disable-next-line eqeqeq
    (option) => option.value == event?.repeat_event
  ).label;
  const eventDescription = event?.description;
  const recurrenceDescription = humanizeEvent(event, currentPractice?.timezone);
  return {
    hasRecurrence,
    recurrenceType,
    eventDescription,
    recurrenceDescription,
    ...event
  };
}
