import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const Container = ({ children, number, onDelete, fromCheckin, className }) => {
  const { device } = useUIContext();

  return (
    <div
      className={cs(
        'relative flex w-full flex-row justify-center gap-2',
        'items-center',
        device === 'mobile' || device === 'tablet-sm' ? '-mr-3' : '',
        className
      )}>
      {/* {fromCheckin && (number || onDelete) && (
        <div
          className={cs(
            device === 'mobile'
              ? 'absolute -top-3 right-8 z-10 flex flex-row-reverse gap-2'
              : ' mr-2 mt-5 flex h-full w-8 flex-col'
          )}>
          {number && (
            <p
              className={cs(
                ' !mb-2 flex h-8 w-8 items-center justify-center rounded-[0.5rem] p-2 text-sm font-semibold text-primary-500 ',
                device === 'mobile' ? ' bg-primary-900 text-white ' : ' border-0 bg-primary-100'
              )}>
              {number}
            </p>
          )}
          {onDelete && (
            <div
              onClick={onDelete}
              className={cs(
                ' flex h-8 w-8 cursor-pointer items-center justify-center rounded-[0.5rem] pb-1 text-sm font-semibold ',
                device === 'mobile'
                  ? 'border border-solid !border-red-200 bg-red-100 text-white hover:bg-red-200'
                  : ' border-0 bg-red-100 hover:bg-red-200'
              )}>
              <Icon
                icon="trash"
                color="danger"
                className="m-4 cursor-pointer"
                shade={500}
                size={14}
              />
            </div>
          )}
        </div>
      )} */}

      {!fromCheckin && number && (
        <p
          className={cs(
            'mr-3 flex h-6 w-6 items-center justify-center rounded-[0.5rem] bg-neutral-200 p-2 text-sm font-semibold text-neutral-700'
          )}>
          {number}
        </p>
      )}

      <div
        className={cs(
          'w-full ',
          !fromCheckin
            ? 'mt-4 rounded-lg bg-white p-4 shadow-[0px_0px_16px_0px_#37414514]'
            : 'max-w-[500px] rounded-2xl border-[1px] border-solid border-primary-100 bg-gradient-to-r from-[#DFF5FF] to-[#B6E9FF]  !px-4 !py-7 md:max-w-[450px] md:!px-6 lg:!px-6 xl:!px-6'
        )}>
        {fromCheckin && number && (
          <div className="relative  max-w-[500px] md:max-w-[450px]">
            <p
              className={cs(
                'absolute  -top-3 flex h-6 w-6 items-center justify-center rounded-[0.5rem] border-[1px] border-solid !border-[#8AE0FF] bg-primary-50 p-2 text-sm font-semibold text-primary-500',
                device === 'mobile' || device === 'tablet-sm' ? ' right-0' : '-right-3'
              )}>
              {number}
            </p>
          </div>
        )}

        {children}
      </div>

      {onDelete && (
        <div
          onClick={onDelete}
          className={cs(
            'flex h-full w-6 cursor-pointer flex-col items-center justify-center pb-1 text-sm font-semibold',
            device === 'mobile' || device === 'tablet-sm' || !fromCheckin
              ? ''
              : 'absolute translate-x-[280px] md:translate-x-[250px]'
          )}>
          <Icon icon="trash" color="neutral" className="m-4 cursor-pointer" shade={700} size={16} />
        </div>
      )}
    </div>
  );
};

export default Container;
