import { useEffect, useRef, useState } from 'react';

const useHasScrollbar = ({ type = 'vertical', trigger = null } = {}) => {
  const [hasScrollbar, setHasScrollbar] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const container = ref.current;

    const updateHasScrollbar = () => {
      if (container) {
        let hasScrollbar = false;
        if (type === 'horizontal') {
          const { scrollWidth, clientWidth, scrollLeft } = container;
          hasScrollbar = container.scrollWidth > container.offsetWidth;
          setCanScrollLeft(scrollLeft > 0);
          setCanScrollRight(scrollLeft < scrollWidth - clientWidth);
        } else {
          const { scrollHeight, clientHeight, scrollTop } = container;
          hasScrollbar = container.scrollHeight > container.offsetHeight;
          setCanScrollLeft(scrollTop > 0);
          setCanScrollRight(scrollTop < scrollHeight - clientHeight);
        }
        setHasScrollbar(hasScrollbar);
      }
    };

    const handleScroll = () => {
      updateHasScrollbar();
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
      updateHasScrollbar();
    }
    const observer = new MutationObserver(updateHasScrollbar);

    if (container) {
      observer.observe(container, { childList: true, subtree: true });
    }

    // Initial call
    updateHasScrollbar();

    window.addEventListener('resize', updateHasScrollbar);

    return () => {
      window.removeEventListener('resize', updateHasScrollbar);
      if (observer && container) {
        container.removeEventListener('scroll', handleScroll);
        observer.disconnect();
      }
    };
  }, [type, trigger, ref.current?.clientWidth]);

  return [ref, hasScrollbar, canScrollLeft, canScrollRight];
};

export default useHasScrollbar;
