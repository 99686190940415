import React, { useState } from 'react';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Modal from 'components/shared/Modal/Modal';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import OrderZ from 'components/practice/charts/ClinicalNote/Orders/components/Orders';
import OrdersModal from 'components/practice/charts/ClinicalNote/Orders/OrdersModal';
import OrdersBody from 'components/shared/Forms/Custom/Orders/OrderBody';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';
import Allowed from 'components/shared/Permissions/Allowed';

export default function Orders(props) {
  const [orders, setOrders] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        onPlusClick={() => {
          setModalOpen(true);
        }}
        icon="new-note"
        iconColor="primary"
        addPermissions="patients_medical_history.update"
        {...props}
      />
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          <FooterWrapper
            onClick={() => setShow(true)}
            text={orders.length > 0 ? `Orders (${orders.length})` : ''}>
            <OrdersBody setOrders={setOrders} orders={orders} />
          </FooterWrapper>
        </div>

        <Modal
          handleOpen={show}
          className="bg-white"
          title="Orders"
          slideFromRight
          handleClose={() => setShow(false)}>
          <OrderZ />
        </Modal>

        {!!modalOpen && (
          <ClinicalNoteContextProvider>
            <Chart3dContextProvider>
              <OrdersModal
                modalOpen={modalOpen}
                handleClose={() => {
                  setModalOpen(false);
                }}
              />
            </Chart3dContextProvider>
          </ClinicalNoteContextProvider>
        )}
      </Allowed>
    </div>
  );
}
