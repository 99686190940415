import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from 'api/InterimApi';
import { NewAppointmentContextProvider } from 'lib/context/Appointment/NewAppointmentContextProvider';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import {
  ia,
  iaRa,
  mapValues,
  optionify,
  orderById,
  reShapePractitioners,
  reShapeProcedures
} from 'lib/helpers/utility';
import usePageTitle from 'lib/hooks/usePageTitle';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import NewAppointment from '../../appointment/NewAppointment/NewAppointment';
import PreviewAppointment from '../../appointment/PreviewAppointment/PreviewAppointment';
import practiceState, { currentPractice } from '../../practiceState';
import RBCToolbar from './RBCToolbar/RBCToolbar';
import './style/RBCalendar.scss';
import EventPreview from '../../appointment/EventForm/EvenPreview/EventPreview';
import { isColorDark } from '../../appointment/EventForm/lib';
import { useAppointmentEvents } from 'lib/hooks/queries/appointment/useAppointmentEvents';
import { views } from './lib';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { getServices } from 'api/Service';
import { showAlert } from 'components/shared/Alert/Alert';
import './style/react-big-calendar.scss';
import { useQueryClient } from '@tanstack/react-query';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { useAppointmentv2 } from 'lib/hooks/queries/appointment/useAppointmentv2';
import { isEmpty } from 'lodash';
import { useServices } from 'lib/hooks/queries/services/useServices';

const DnDCalendar = withDragAndDrop(Calendar);

const RBCalendar = (props) => {
  const {
    selectedView = null,
    showViews = true,
    showCalendarViewButton = false,
    className,
    position
  } = props;
  const [appointment, setAppointment] = useState(null);
  const [appointmentId, setAppointmentId] = useState(null);
  const [event, setEvent] = useState(null);
  const [showPreviewAppointment, setShowPreviewAppointment] = useState(false);
  const [showNewAppointment, setShowNewAppointment] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const navigate = useNavigate();
  const [practice, setPractice] = useState(null);
  const [loading, setLoading] = useState({ appointments: false, appointment: false });
  const [defaultDate, setDefaultDate] = useState(moment().startOf('day').toDate());
  const { working_hours, display_settings } = useRecoilValue(currentPractice);
  const [currentView, setCurrentView] = useState(
    display_settings?.calendar?.defaultView?.value.toLowerCase() || 'day'
  );
  const [sortedProviders, setSortedProviders] = useState(null);
  const [rangeDate, setRange] = useState({ startDate: '', endDate: '' });
  const [blockScroll, allowScroll] = useScrollBlock();
  const [selectedPatient, setSelectedPatient] = useRecoilState(practiceState.selectedPatient);
  const [selectedDateTime, setSelectedDateTime] = useState({ start: null, end: null });
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [pendingCount, setPendingCount] = useState('');
  const { device } = useUIContext();
  const [defaultTimeIncrement, setDefaultTimeIncrement] = useState(15);
  const [isInitial, setIsInitial] = useState(true);
  const { filters } = useDashboardContext();
  const queryClient = useQueryClient();
  const { data: servicesData } = useServices();
  const services = servicesData?.services || [];

  const param = {
    currentView,
    defaultDate,
    getDates,
    navigate,
    rangeDate,
    selectedView,
    withEvents: true,
    setEvent
  };
  const { data, isLoading } = useAppointmentEvents({
    ...param,
    isInitial,
    filters: mapValues({
      ...filters,
      practitioners: filters?.practitioners?.values?.map((item) => item.value),
      serviceType: filters?.serviceType?.values?.map((item) => item.value),
      appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
      appointmentTags: filters?.appointmentTags?.values?.map((item) => item.id),
      resources: filters?.resources?.values?.map((item) => item.value)
    })
  });

  const { data: appointmentData } = useAppointmentv2({
    params: {
      id: appointmentId,
      withal: {
        medicalHistory: true,
        payments: true,
        patient: true,
        practitioner: true,
        allergies: true,
        practitionerPractice: true,
        tag: true,
        resources: true
      }
    },
    options: { enabled: !!appointmentId }
  });

  const fullAppointment = appointmentData?.appointment || {};

  usePageTitle('Calendar');
  const { id } = useParams();

  const { data: resourceData } = useResourcesAsOptions();
  const resources = resourceData?.resources || [];

  useEffect(() => {
    getPractice();
    getPractitioners();
    setIsInitial(false);
  }, []);

  useEffect(() => {
    if (selectedView) {
      setCurrentView(selectedView);
      return;
    }
    if (display_settings?.calendar?.defaultView?.value) {
      setCurrentView(display_settings?.calendar?.defaultView?.value.toLowerCase());
    } else {
      setCurrentView('day');
    }
    if (display_settings?.calendar?.defaultTimeIncrement?.value) {
      setDefaultTimeIncrement(display_settings?.calendar?.defaultTimeIncrement?.value);
    }
  }, [display_settings]);

  const providersOrderAndId = display_settings?.calendar?.providerView?.selectedProviders;
  useEffect(() => {
    if (ia(providersOrderAndId)) {
      const filtertProviders = practitioners?.filter((p) =>
        providersOrderAndId.find((fp) => fp.id === p.id)
      );
      const sortProviders = orderById(filtertProviders, providersOrderAndId);
      setSortedProviders(sortProviders);
    }
  }, [display_settings, practitioners]);

  const getPractice = async () => {
    try {
      const res = await interimApi('/api/practice/current_practice', {}, navigate);
      const { redirect, practice: loadedPractice } = res.data;
      if (loadedPractice) {
        setPractice(loadedPractice);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
    }
  };

  const getPractitioners = async () => {
    try {
      const res = await interimApi('/api/practice/member/get_practitioners', {}, navigate);
      const { redirect, practitioners: loadedPractitioners } = res.data;
      if (res.data.practitioners) {
        setPractitioners(loadedPractitioners);
      } else {
        navigate(redirect);
      }
    } catch (err) {
      console.error('error', err);
      showAlert({ message: 'An unexpected error has occurred. Please try again later.' });
    }
  };

  const onAppointmentDrop = (event) => {
    if (event.event.eventType === 'event') {
      showAlert({
        title: 'Cannot reschedule events from the calendar!',
        color: 'warning'
      });
      return;
    }

    setShowConfirmationModal(true);
    setCurrentEvent(event);
  };

  const rescheduleAppointment = async (updatedAppointment) => {
    setLoading({ ...loading, reschedule: true });
    try {
      const appointment = updatedAppointment.event.appointment;

      const params = {
        id: appointment.id,
        starts_at: updatedAppointment.start,
        ends_at: updatedAppointment.end,
        practitioner_id:
          currentView === 'provider' && updatedAppointment.resourceId
            ? updatedAppointment.resourceId
            : appointment.practitioner_id,
        resource_id:
          currentView === 'resource' && updatedAppointment.resourceId
            ? updatedAppointment.resourceId
            : updatedAppointment.resource_id || undefined
      };

      const res = await interimApi('/api/appointment/update', params, navigate);

      if (res.data.affectedRows > 0) {
        queryClient.invalidateQueries(['appointment-events', id]);
        showAlert({ message: 'Appointment was updated successfully!' });
        setShowConfirmationModal(false);
      } else {
        showAlert({ message: 'There was an error updating this appointment', color: 'danger' });
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }

    setLoading({ ...loading, reschedule: false });
  };

  const eventStyleGetter = (event) => {
    let styleClass = '';
    if (event.eventType === 'event') {
      let practitionerColor = event.color;
      const isDark = isColorDark(practitionerColor);
      let fontColor = isDark ? 'white' : 'black';
      let serviceColor = 'white';
      let isCancelled = false;

      styleClass = {
        style: {
          borderLeft: `10px solid ${serviceColor}`,
          background: practitionerColor,
          color: fontColor,
          cl: 10,
          textDecoration: isCancelled && currentView !== 'agenda' ? 'line-through red' : 'none'
        }
      };

      if (isCancelled && currentView === 'agenda') {
        styleClass = {
          ...styleClass,
          className: 'isCancelledContentRBC'
        };
      }

      if (data?.length < 36) {
        styleClass = {
          ...styleClass,
          className: `${styleClass.className} slotInlineContentRBC`
        };
      }
    } else {
      const { appointment } = event;
      const { service_id } = appointment;

      let practitionerColor = 'transparent';
      let serviceColor = 'white';
      let fontColor = 'black';
      let isCancelled = appointment?.status === 'cancelled' ? true : false;

      if (appointment?.practitioner?.practice?.color)
        practitionerColor = appointment?.practitioner?.practice?.color;

      const appointmentFirstServiceId = ia(service_id) && service_id[0];
      const appointmentService = services.find(
        (service) => service.id == appointmentFirstServiceId
      );

      if (appointmentService) serviceColor = appointmentService.color;

      styleClass = {
        style: {
          borderLeft: `10px solid ${serviceColor}`,
          background: practitionerColor,
          color: fontColor,
          cl: 10,
          textDecoration: isCancelled && currentView !== 'agenda' ? 'line-through red' : 'none'
        }
      };

      if (isCancelled && currentView === 'agenda') {
        styleClass = {
          ...styleClass,
          className: 'isCancelledContentRBC'
        };
      }

      if (appointmentService?.time_length < 30) {
        styleClass = {
          ...styleClass,
          className: `${styleClass.className} slotInlineContentRBC`
        };
      }
    }

    return styleClass;
  };

  //preview appointment modal
  const handleShowPreviewAppointment = () => {
    blockScroll();
    setShowPreviewAppointment(true);
  };
  const hidePreviewAppointment = () => {
    allowScroll();
    setShowPreviewAppointment(false);
    setSelectedPatient({ ...selectedPatient, id: null });
    setTimeout(() => {
      setAppointment(null);
      setAppointmentId(null);
    }, 500);

    if (id) navigate('/portal/appointments', { replace: true });
  };

  function getDates(date, view) {
    let start, end;
    if (view === 'day' || view === 'provider' || view === 'agenda') {
      start = moment(date || defaultDate)
        .startOf('day')
        .toDate();
      end = moment(date || defaultDate)
        .endOf('day')
        .toDate();
    } else if (view === 'week') {
      start = moment(date || defaultDate)
        .startOf('isoWeek')
        .toDate();
      end = moment(date || defaultDate)
        .endOf('isoWeek')
        .toDate();
    } else if (view === 'month') {
      start = moment(date || defaultDate)
        .startOf('month')
        .toDate();
      end = moment(date || defaultDate)
        .endOf('month')
        .toDate();
    }

    setSelectedDateTime({ start, end: null });

    setRange({ startDate: start, endDate: end });
  }
  const dateWithTimeZone = (timeZone, hour, minute, second) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    let date = new Date(Date.UTC(year, month, day, hour, minute, second));
    let utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    let tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    let offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);
    return date;
  };

  const onSelectSlot = ({ start, end, action, resourceId }) => {
    const startDateTime = moment.tz(start, practice?.timezone);
    const endDateTime = moment.tz(end, practice?.timezone);
    setSelectedDateTime({ start: startDateTime, endDateTime });
    setShowNewAppointment(true);

    practitioners?.find((item) => item.id === resourceId && setSelectedPractitioner(item));
  };

  let minTime = dateWithTimeZone(practice?.timezone, 8, 0, 0);
  let maxTime = dateWithTimeZone(practice?.timezone, 20, 0, 0);

  if (working_hours?.startsAt && working_hours?.endsAt) {
    minTime = dateWithTimeZone(
      practice?.timezone,
      working_hours?.startsAt.hour,
      working_hours?.startsAt.minute,
      0
    );
    maxTime = dateWithTimeZone(
      practice?.timezone,
      working_hours?.endsAt.hour,
      working_hours?.endsAt.minute,
      0
    );
  }
  const getOffset = moment().format('Z');
  const getOffsetInteger = parseInt(getOffset.slice(0, 3));
  moment.tz.setDefault(practice?.timezone);
  moment.locale('ko', {
    week: {
      dow: 1,
      doy: 1
    }
  });

  const localizer = momentLocalizer(moment);
  const formats = {
    agendaHeaderFormat: (date, culture, localizer) => {
      return localizer.format(date?.start, 'dddd MMM Do', culture);
    }
  };

  useEffect(() => {
    if (id) {
      setAppointmentId(id);
    }
  }, [id]);

  return (
    <>
      <div
        key={iaRa(data?.length)}
        className={cs(
          'RBCalendar',
          currentView === 'day' && 'RBCalendar--day',
          currentView === 'week' && 'RBCalendar--week',
          loading?.appointments && 'loading',
          className
        )}
        style={{ gridArea: position }}
        data-dd-privacy="allow">
        {ia(practitioners) && (
          <ErrorBoundary
            FallbackComponent={ErrorMessage}
            isImage
            className="flex min-h-[400px] flex-col items-center justify-center p-14">
            <DnDCalendar
              className={isLoading && 'hidden'}
              defaultDate={defaultDate}
              localizer={localizer}
              events={ia(data) ? data : []}
              startAccessor="starts_at"
              endAccessor="ends_at"
              selectable={true}
              resizable={true}
              length={1}
              event
              messages={{
                event:
                  device === 'desktop' ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div class="customAgendaTitle">
                        <p>Patient</p>
                        <p>Service</p>
                        <p>Balance</p>
                        <p>Provider</p>
                        <p>Status</p>
                        <p>State</p>
                      </div></div>`
                      }}></div>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<div class="customAgendaTitle">
                        <p>Appointment details</p>`
                      }}></div>
                  )
              }}
              onNavigate={(date, view) => {
                getDates(date, view);
              }}
              onView={(view) => {
                setCurrentView(view);
                getDates(null, view);
              }}
              step={defaultTimeIncrement / 2}
              min={minTime}
              max={maxTime}
              onEventDrop={onAppointmentDrop}
              eventPropGetter={eventStyleGetter}
              onSelectEvent={(event) => {
                if (event.eventType === 'event') {
                  delete event.title;
                  setEvent(event);
                } else {
                  handleShowPreviewAppointment();
                  setAppointment(event.appointment);
                  setAppointmentId(event.appointment.id);
                }
              }}
              onSelectSlot={onSelectSlot}
              defaultView={currentView}
              views={views}
              resources={
                currentView === 'resource'
                  ? resources.map((r) => ({ id: r.value, name: r.label }))
                  : currentView === 'provider' && ia(sortedProviders)
                    ? sortedProviders
                    : currentView === 'provider'
                      ? practitioners
                      : null
              }
              resourceIdAccessor="id"
              resourceTitleAccessor={
                currentView === 'provider'
                  ? (practitioner) => `${practitioner.f_name} ${practitioner.l_name}`
                  : (resource) => `${resource.name}`
              }
              components={{
                toolbar: (props) => (
                  <RBCToolbar
                    showCalendarViewButton={showCalendarViewButton}
                    showViews={showViews}
                    handleNewAppointment={() => setShowNewAppointment(true)}
                    practice={practice}
                    setCurrentView={(v) => setCurrentView(v)}
                    setDefaultDate={setDefaultDate}
                    services={optionify(services, 'name', 'id')}
                    practitioners={iaRa(practitioners).map((practitioner) => ({
                      value: practitioner.id,
                      label: `${practitioner.f_name} ${practitioner.l_name}`
                    }))}
                    loading={loading?.appointments}
                    appointmentTypes={practice?.appointment_types}
                    pendingCount={pendingCount}
                    {...props}
                  />
                ),
                practitioner: {
                  header: () => null
                }
              }}
              formats={formats}
            />
          </ErrorBoundary>
        )}
        {isLoading && <Skeleton height="90px" count={4} />}
        {(appointment || !isEmpty(fullAppointment)) && !loading.appointment && (
          <PreviewAppointment
            appointment={{ ...appointment, ...fullAppointment }}
            loading={loading?.appointment}
            setAppointment={setAppointment}
            services={reShapeProcedures(services)}
            showPreviewAppointment={showPreviewAppointment}
            hidePreviewAppointment={hidePreviewAppointment}
            setRefetchAppointments={() => {}}
            practitioners={reShapePractitioners(practitioners)}
          />
        )}
        {event && (
          <EventPreview
            {...{
              event,
              isOpen: !!event,
              setEvent,
              practitioners: reShapePractitioners(practitioners)
            }}
          />
        )}
        {ia(services) && showNewAppointment && (
          <NewAppointmentContextProvider>
            <NewAppointment
              selectedDateTime={selectedDateTime}
              services={reShapeProcedures(services)}
              isOpen={showNewAppointment}
              hideNewAppointment={() => setShowNewAppointment(false)}
              selectedPractitioner={selectedPractitioner}
              practitioners={reShapePractitioners(practitioners)}
            />
          </NewAppointmentContextProvider>
        )}
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={() => rescheduleAppointment(currentEvent)}
          primaryBtnTxt="Reschedule appointment"
          secondaryBtnTxt="Keep"
          title="Confirm reschedule"
          message={`Are you sure you want to reschedule this appointment to ${moment(
            currentEvent?.start
          )}`}
          icon="new-calendar-edit"
          loading={loading?.reschedule}
        />
      </div>
    </>
  );
};

const RBCalendarWrapper = (props) => {
  return (
    <DashboardContextProvider>
      <RBCalendar {...props} />
    </DashboardContextProvider>
  );
};

export default RBCalendarWrapper;
