import React from 'react';

import { iaRa, removeBracketsInText } from 'lib/helpers/utility';
import { permissions, userState } from 'components/state';
import { useRecoilValue } from 'recoil';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const MyScribeExportNote = () => {
  const { transcriptData = [] } = useAmbientListeningContext() || {};

  const myscribeTranscriptData = iaRa(transcriptData)?.[0];

  const user = useRecoilValue(userState);

  const permission = useRecoilValue(permissions);

  const myScribeAINarrative = removeBracketsInText(myscribeTranscriptData?.summary);
  const myScribeAuthor = myscribeTranscriptData?.provider_id ?? null;

  const isCurrentUserSameAsAuthor = myScribeAuthor == (user?.id ?? user?.user_id);

  const hasPermissionToSeeNarrative =
    permission?.myscribe?.narrative?.read || isCurrentUserSameAsAuthor;

  if (!myScribeAINarrative)
    return (
      <div className="bg-white !px-6 !py-4">
        <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Encounter Narrative</h3>
        <p className="text-sm">
          You currently have no transcript data. You can start one by clicking on 'record visit'.
        </p>
      </div>
    );

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Encounter Narrative</h3>

      {!hasPermissionToSeeNarrative ? (
        <p className="text-sm">You don't have the permission to view the MyScribe AI narrative.</p>
      ) : myScribeAINarrative ? (
        <SanitizeComponent value={myScribeAINarrative} />
      ) : (
        <p className="text-sm">
          You currently have no transcript data. You can start one by clicking on 'record visit'.
        </p>
      )}
    </div>
  );
};

export default MyScribeExportNote;
