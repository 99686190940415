import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { getAppointments } from 'api/Appointment';

import { formatAppointments, formatEvents } from 'lib/helpers/appointment';
import { formatDate, getDirtyValues } from 'lib/helpers/utility';

import {
  appointmentStatuses as aptStatuses,
  currentPractice
} from 'components/practice/practiceState';
import state from 'components/state';

export const useAppointmentEvents = (params, format = true, options) => {
  const currPractice = useRecoilValue(currentPractice);
  const permissions = useRecoilValue(state.permissions);
  const navigate = useNavigate();
  const appointmentStatuses = useRecoilValue(aptStatuses);

  const {
    practice,
    rangeDate,
    reloadAppointment,
    currentView,
    filters,
    setEvent,
    limit,
    page,
    sort
  } = params;

  const rangeDates = {
    startDate: formatDate(rangeDate?.startDate),
    endDate: formatDate(rangeDate?.endDate)
  };

  return useQuery(
    ['appointment-events', practice, rangeDates, reloadAppointment, filters, limit, page, sort],
    () => getAppointments({ ...params, navigate }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
      select: (data) => {
        return format
          ? {
              events: [
                ...formatAppointments({
                  appointments: data?.appointment,
                  currentView,
                  permissions,
                  appointmentStatuses
                }),
                ...formatEvents({
                  calendarEvents: data?.events,
                  setEvent,
                  timezone: currPractice.timezone,
                  currentView
                })
              ],
              pendingCount: data?.pendingCount
            }
          : data;
      },
      ...options
    }
  );
};
