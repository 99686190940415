import { requestApi } from './Api';

export const getPractices = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/get',
    onError: () => {},
    params
  });
};

export const getCurrentPractice = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/current_practice',
    params
  });
};

export const getStaffPracticeAdmin = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/member/get_v2',
    params
  });
};

export const getDashboardSms = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/dashboard/sms',
    params
  });
};

export const getDashboardClaims = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/dashboard/claims',
    params
  });
};

export const getDashboardOutstandingPaymnets = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/dashboard/outstanding_payments',
    params
  });
};

export const getPayers = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/settings/payers/get',
    params
  });
};

export const getOwnerPractices = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/get_practices',
    params
  });
};
export const setDefaultPractice = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/set_default_practice',
    params
  });
};
export const togglePractice = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/toggle_practice',
    params
  });
};
export const createPracticeOwner = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/create_owner',
    params
  });
};
export const getPracticeOwners = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/get_owners',
    params
  });
};
export const appendPracticeToOrganization = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/add_practice_to_owner',
    params
  });
};
export const removePracticeFromOrganization = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/remove_practice_from_owner',
    params
  });
};
export const getOrganizationLocations = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/get_locations',
    params
  });
};
export const importLocationData = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice_owner/import_data',
    params
  });
};
export const getAuditLogs = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/practice/audit_logs/get',
    params
  });
};
export const getPracticePatientLinks = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/patient/link/list',
    params
  });
};
