import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getOnlyCustomForm } from '../../../../api/CustomForms';

export const useGetOnlyCustomForm = ({ dependencies = [], params = {}, options } = {}) => {
  const navigate = useNavigate();
  return useQuery(['onlyCustomForm', ...dependencies], () => getOnlyCustomForm(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    ...options
  });
};
