import React from 'react';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Icon from 'components/shared/Icon/Icon';
import { iaRa } from 'lib/helpers/utility';

const LabOrder = ({
  labOrder,
  labOrderFetching,
  setNavigateToParams,
  handleOpen,
  handleOnOpenDelete,
  ableToDelete
}) => {
  return labOrderFetching ? (
    <Skeleton count={5} />
  ) : (
    iaRa(labOrder).map((row, index) => (
      <div key={index} className="!mt-3 flex w-full items-center !gap-1 self-center">
        <div
          onClick={() => {
            const { tests, ...otherParams } = row;

            setNavigateToParams({
              type: 'lab_order',
              itemProps: { labOrder: otherParams, labOrderTests: tests }
            });
            handleOpen();
          }}
          className="flex w-full cursor-pointer items-center gap-1.5 rounded-lg  bg-white !p-2 transition-all hover:!border-primary-200 hover:!bg-primary-50">
          <Icon size={24} icon="new-note" color="primary" />
          <div className="font-medium text-neutral-800">Type: Lab/Imaging Order,</div>
          <div className="font-medium text-neutral-800">Provider: {row?.provider?.fullName}</div>
        </div>
        {ableToDelete && (
          <div
            onClick={() => handleOnOpenDelete({ orderId: row.id, type: 'lab_order' })}
            className="flex h-full cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !p-2.5 transition-all hover:!border-primary-500 hover:!bg-primary-50">
            <Icon icon="new-trash-v2" className="cursor-pointer" size={20} />
          </div>
        )}
      </div>
    ))
  );
};

export default LabOrder;
