import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import AdminIntelligenceModelTable from 'components/admin/pages/Intelligence/Model/table';
import { cols } from './helper';

const AdminIntelligenceModel = () => {
  return (
    <>
      <TableContextProvider
        name={'admin_intelligence_ai_model_table'}
        defaultFilters={[]}
        cols={cols}>
        <AdminIntelligenceModelTable />
      </TableContextProvider>
    </>
  );
};

export default withErrorBoundary(AdminIntelligenceModel);
