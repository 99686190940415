import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getTerminals } from 'api/Terminal';

export const useTerminals = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['terminal_admin', ...dependencies], () => getTerminals(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    ...options
  });
};
