import React, { useState, useEffect } from 'react';
import Button from '../../../Buttons/Button';
import OtherProblemsRow from './OtherProblemsRow';
import { OtherProblems as initialValue } from '../CustomFormInitialStates';
import Header from '../components/Header';
import Container from '../components/Container';
import SelectableButton from '../components/SelectableButton';
import cs from 'classnames';

const OtherProblems = ({ forwardedRef, defaultValue, onChange }) => {
  const [otherProblems, setOtherProblems] = useState(initialValue);
  const fromCheckin = location?.pathname.includes('/checkin');

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setOtherProblems(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setOtherProblems(defaultValue);
      }
    } else {
      setOtherProblems(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(otherProblems);
  }, [otherProblems]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'OtherProblems', type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    setOtherProblems([
      {
        note: '',
        problems: null,
        id: self.crypto.randomUUID()
      },
      ...otherProblems
    ]);
  };

  const handleDeleteRow = (id) => {
    setOtherProblems(otherProblems.filter((row) => row.id !== id));
  };

  const handleOnChange = (key, event, id) => {
    setOtherProblems(
      otherProblems.map((row) => {
        if (row.id === id) {
          return { ...row, [key]: event };
        } else {
          return row;
        }
      })
    );
  };

  return (
    <div className="OtherProblems CustomForms">
      <Header title="Other Problems" fromCheckin={fromCheckin}>
        {!fromCheckin && (
          <div
            className={cs(
              otherProblems?.length > 1 && 'pr-8',

              'flex w-full flex-row justify-end'
            )}>
            <Button
              text="Add row"
              iconRight="add-circle"
              transparent
              onClick={handleAddRow}
              data-qa="add-allergy-btn"
              textClassName="text-primary-500 mr-1 text-sm"
            />
          </div>
        )}

        <input type="hidden" value={JSON.stringify(otherProblems)} ref={forwardedRef} />
        <div className={cs(fromCheckin && 'mt-4', ' flex w-full flex-col gap-4')}>
          {otherProblems?.map((item, idx) => (
            <Container
              fromCheckin={fromCheckin}
              onDelete={otherProblems.length > 1 ? () => handleDeleteRow(item.id) : null}
              key={item.id}
              number={idx + 1}>
              <OtherProblemsRow
                key={item.id}
                fromCheckin={fromCheckin}
                onChange={handleOnChange}
                {...item}
              />
            </Container>
          ))}
        </div>

        {fromCheckin && (
          <div className="mb-3 mt-3 flex w-full max-w-[500px] flex-col items-center justify-center !px-4 md:!px-6 lg:!px-6 xl:!px-6">
            <p className="mb-[1rem] mt-2 text-sm text-neutral-600">
              Do you want to add another row?
            </p>

            <div className={cs(' !grid w-full !grid-cols-2 gap-2', '-mb-3')}>
              <SelectableButton
                data-qa="yes"
                label="Yes"
                isChecked={false}
                onChange={handleAddRow}
              />
              <SelectableButton data-qa="no" label="No" isChecked={true} onChange={() => null} />
            </div>
          </div>
        )}
      </Header>
    </div>
  );
};

export default OtherProblems;
