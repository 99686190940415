import { useFormik } from 'formik';
import * as Yup from 'yup';

const CreateUpdateAdminValidationSchema = () =>
  Yup.object().shape({
    f_name: Yup.string().required('First name is required'),
    l_name: Yup.string().required('Last name is required'),
    phone: Yup.string().required('Phone number is required'),
    role: Yup.object().required("The role is required"),
    email: Yup.string().email("Please type a valid format").required('Email is required'),
  });

export default function useAdminFormik(adminData = {}, onSubmit) {
  const formik = useFormik({
    initialValues: {
      userId: adminData?.id ?? "",
      f_name: adminData?.f_name ?? "",
      l_name: adminData?.l_name ?? "",
      role: adminData?.role ?? null,
      email: adminData?.email ?? "",
      phone: adminData?.phone ?? ""
    },
    enableReinitialize: true,
    validationSchema: CreateUpdateAdminValidationSchema,
    onSubmit,
  });

  return formik;
}
