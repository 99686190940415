import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { spaceToKebabCase } from '../../../../../../lib/helpers/utility';
import { useParams } from 'react-router-dom';

import ShowDataResult from './lib/components/ShowDataResult';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import PreviousNoteTable from './lib/components/PreviousNoteTable';

import { camelCase, snakeCase } from 'lodash';
import { showAlert } from '../../../../../shared/Alert/Alert';

import { useVitals } from '../../../../../../lib/hooks/queries/vitals/useVitals';
import { useClinicalNote } from '../../../../../../lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { useClinicalNoteRead } from '../../../../../../lib/hooks/queries/clinicalNotes/useClinicalNote';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useCustomFormResponses } from '../../../../../../lib/hooks/queries/customForms/useCustomFormResponse';
import { formatSections as formatSectionsV3 } from '../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import {
  customFormResponseTypes,
  findInVitals,
  getFormattedNarrative,
  getOverviewNarrativeOptions,
  mappedNamesForCN
} from './lib/previousNotesHelpers';

const PreviousNotes = () => {
  const { components } = useClinicalNoteContext();
  const [result, setResult] = useState(null);
  const [selectedNote, setSelectedNote] = useState(null);
  const { id, formName } = useParams();

  const isNotVitalsForm = formName !== 'vitals';
  const isNotCustomFormResponse = !customFormResponseTypes.includes(formName);

  const { data, isFetching: clinicalNoteFetching } = useClinicalNote({
    params: { pagination: false, patientId: id },
    dependencies: [id]
  });

  const { data: readCN, isFetching } = useClinicalNoteRead({
    params: {
      patientId: id,
      appointmentId: selectedNote?.appointment_id
    },
    dependencies: [selectedNote?.id],
    options: {
      enabled: selectedNote?.id && isNotVitalsForm && isNotCustomFormResponse ? true : false
    }
  });

  const { data: readVitals, isFetching: vitalsFetching } = useVitals({
    params: {
      patientId: id,
      appointmentId: selectedNote?.appointment_id
    },
    dependencies: [selectedNote?.id],
    options: {
      enabled: selectedNote?.id && formName == 'vitals' ? true : false
    }
  });

  const { data: readCustomFromResponses, isFetching: customFormResponsesFetching } =
    useCustomFormResponses({
      params: {
        patientId: id,
        appointmentId: selectedNote?.appointment_id
      },
      dependencies: [selectedNote?.id],
      options: {
        enabled: selectedNote?.id && customFormResponseTypes?.includes(formName) ? true : false,
        select: (data) => data?.response
      }
    });

  useEffect(() => {
    if (formName !== 'vitals' && isNotCustomFormResponse && selectedNote?.id && !!readCN) {
      loadNote(readCN);
    } else if (
      customFormResponseTypes?.includes(formName) &&
      selectedNote?.id &&
      readCustomFromResponses
    ) {
      loadNote({});
    } else if (formName == 'vitals' && selectedNote?.id && readVitals) {
      findInVitals({ readVitals, setResult, setSelectedNote });
    }
  }, [selectedNote, isFetching, vitalsFetching, customFormResponsesFetching]);

  const allClinicalNotes = data?.clinicalNote;

  const loadNote = async (response) => {
    setSelectedNote(null);

    const { clinicalNote, advancedHP, advancedSOAP } = response || {};

    const overviewData = await findInOverview();

    if (overviewData) {
      setResult(overviewData);
      return;
    }

    const clinicalNoteData = findInClinicalNote({ clinicalNote });

    if (clinicalNoteData) {
      setResult(clinicalNoteData);
      return;
    }

    const advancedNoteData = findInAdvancedNote({ advancedHP, advancedSOAP });

    if (advancedNoteData) {
      setResult(advancedNoteData);
      return;
    }

    if (!overviewData && !clinicalNoteData && !advancedNoteData) {
      setResult(null);
      showAlert({
        title: 'Previous Note Preview',
        message: `No information found for the specified form ${formName ?? ''}`,
        color: 'warning'
      });
    }

    return;
  };

  const findInOverview = async () => {
    let foundNoteData = null;

    foundNoteData = Object.keys(readCustomFromResponses || {})?.find(
      (key) => key === camelCase(formName)
    );

    if (foundNoteData) {
      const formattedNarrative = getOverviewNarrativeOptions({
        type: foundNoteData,
        data: readCustomFromResponses[foundNoteData]
      });

      return formattedNarrative;
    }

    return null;
  };

  const findInClinicalNote = ({ clinicalNote }) => {
    let foundNoteData = null;

    let findFormName = formName ?? Object.values(components)?.[0]?.path;

    const selectedClinicalNote = clinicalNote || {};

    foundNoteData = Object.keys(selectedClinicalNote)?.find((key) => {
      if (mappedNamesForCN(key) === snakeCase(findFormName)) return key;
    });

    if (!foundNoteData) return null;

    const formattedNarrative = getFormattedNarrative({ foundNoteData, clinicalNote });

    if (formattedNarrative) return formattedNarrative;

    return null;
  };

  const findInAdvancedNote = ({ advancedHP, advancedSOAP }) => {
    let foundNoteData = null;

    foundNoteData = [...(advancedHP || []), ...(advancedSOAP || [])]?.find((item) => {
      const title = `${spaceToKebabCase(item?.form?.name || item?.name)}-${
        item?.form_id || item?.id
      }`;

      if (title === formName) return item;
    });

    if (foundNoteData?.form) {
      return formatSectionsV3({
        fieldData: foundNoteData?.form?.json?.fields,
        answerData: foundNoteData?.json && JSON.parse(foundNoteData?.json?.fields)
      });
    }

    return null;
  };

  return (
    <div className="h-full w-full !p-2">
      <div
        className={cs(
          'absolute flex h-full w-full flex-col break-words transition-all duration-500',
          result ? 'right-0' : '-right-36'
        )}>
        <ShowDataResult result={result} setResult={setResult} />
      </div>

      {clinicalNoteFetching ? (
        <Skeleton count={5} />
      ) : (
        !result && (
          <PreviousNoteTable
            allClinicalNotes={allClinicalNotes}
            isDataFetching={isFetching || vitalsFetching || customFormResponsesFetching}
            selectedNote={selectedNote}
            setSelectedNote={setSelectedNote}
          />
        )
      )}
    </div>
  );
};

export default PreviousNotes;
