import { calendarFilters, reportingFilters } from 'components/practice/practiceState';
import Icon from 'components/shared/Icon/Icon';
import { customStyle } from 'components/shared/Select/style';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValue } from 'recoil';
import photo from '../../../../user_avatar.png';
import '../style.scss';
import { interimApi } from '/api/InterimApi';
import { formatPhoneNumber, ia } from '/lib/helpers/utility';

const MultipleSearch = ({ handleClick, passedCustomStyling, calendarFilter, onlyPatients }) => {
  const [inputValue, setInputValue] = useState(null);
  const [arrayOfPatients, setArrayOfPatients] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [filters, setFilters] = useRecoilState(calendarFilters);
  const beyondBillingFilter = useRecoilValue(reportingFilters);
  const navigate = useNavigate();

  useEffect(() => {
    if (!calendarFilter && arrayOfPatients?.length < 1)
      setArrayOfPatients(beyondBillingFilter.patient || []);
  }, [beyondBillingFilter]);

  const typeOfFilter = (newArray) => {
    if (calendarFilter) {
      return (
        ia(newArray) &&
        newArray?.map((obj) => {
          return { value: obj?.value, label: obj?.label, type: obj?.email ? 'patient' : 'payer' };
        })
      );
    } else {
      return (
        ia(newArray) &&
        newArray?.map((obj) => {
          return { value: obj?.value, label: obj?.label, type: obj?.email ? 'patient' : 'payer' };
        })
      );
    }
  };

  const handleChange = (value) => {
    if (calendarFilter) setFilters((prevState) => ({ ...prevState, patient: value || [] }));

    setArrayOfPatients(value || []);
    handleClick && handleClick(typeOfFilter(value));
    setShowMenu(false);
  };

  const handleInputChange = (value) => {
    setInputValue(value);
    if (inputValue === '') {
      setShowMenu(false);
    }
  };

  const searchPatients = async (searchTerm, callback) => {
    if (!searchTerm) {
      setShowMenu(false);
      callback([]);
      setInputValue(null);
    } else {
      setShowMenu(true);
      setInputValue(searchTerm);

      let params = {
        limit: 25,
        searchTerm,
        offset: 0,
        withPayers: true
      };

      if (onlyPatients) {
        params = { ...params, type: 'patients' };
      }

      try {
        const res = await interimApi('/api/practice/charts/get', params, navigate);
        const { patients: loadedPatients, payers: loadedPayers } = res.data;
        if (ia(loadedPatients) || ia(loadedPayers)) {
          const loadedData = [...(loadedPatients || []), ...(loadedPayers || [])];
          const patientPayerArray = [];

          ia(loadedData) &&
            loadedData.forEach((element) => {
              if (element?.email != undefined) {
                patientPayerArray.push({
                  label: element?.fullName,
                  value: element?.id,
                  dob: element?.dob,
                  phone: element?.phone,
                  gender: element?.gender,
                  profilePhoto: element?.profile_photo,
                  email: element?.email
                });
              } else {
                patientPayerArray.push({
                  label: element?.name,
                  value: element?.id
                });
              }
            });

          callback(patientPayerArray);
        }
      } catch (err) {
        console.error('error', err);
      }
    }
  };

  const handlePatientClick = (option) => {
    // change link to /myportal in patient role
    navigate(`/portal/charts/${option.value}`);
  };

  const handleOptionClick = (option) => {
    const newArray = [
      ...arrayOfPatients,
      { value: option?.data?.value, label: option?.data?.label, email: option?.data?.email }
    ];
    setArrayOfPatients(newArray);
    setInputValue('');
    setShowMenu(false);
    handleClick ? handleClick(typeOfFilter(newArray)) : handlePatientClick(option);
  };

  const formatOptionLabel = (option) => (
    <div className="AsyncOptions" onClick={() => handleOptionClick(option)}>
      <div className="AsyncOptions__avatar">
        {option.data.profilePhoto ? (
          <img
            src={`https://cdn.filestackcontent.com/${JSON.parse(option.data.profilePhoto).jpg}`}
          />
        ) : (
          <img src={photo} />
        )}
      </div>
      <div className="AsyncOptions__data">
        <div className="AsyncOptions__data__name">{option.label}</div>
        {option?.data?.email ? (
          <div className="AsyncOptions__data__block">
            <div className="AsyncOptions__data__block--inner">
              <label>DOB:</label>
              <p>{moment(option?.data?.dob).format('MMM DD, YYYY')}</p>
            </div>
            <div className="AsyncOptions__data__block--inner">
              <label>Gender:</label>
              <p>{option?.data?.gender && capitalize(option?.data?.gender)}</p>
            </div>
            <div className="AsyncOptions__data__block--inner">
              <label>Phone:</label>
              <p>{formatPhoneNumber(option?.data?.phone)}</p>
            </div>
            <div className="AsyncOptions__data__block--inner">
              <label>Email:</label>
              <p>{option?.data?.email}</p>
            </div>
          </div>
        ) : (
          <div className="AsyncOptions__data__block">
            <div className="AsyncOptions__data__block--inner">
              <label>Payer Id:</label>
              <p>{option?.data?.value}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="SearchPatients" data-qa="multiple-search-input">
      <Icon icon="search" />
      <AsyncSelect
        value={calendarFilter ? filters?.patient : arrayOfPatients}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputChange}
        menuIsOpen={showMenu}
        isClearable={true}
        isMulti
        loadOptions={searchPatients}
        components={{
          Option: formatOptionLabel,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        noOptionsMessage={() => null}
        styles={{
          ...customStyle({}),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: 40
          })
        }}
        placeholder="Search patient or payer"
        inputId="search"
        className="SearchPatients__wrapper"
      />
    </div>
  );
};

export default MultipleSearch;
