import { useCallback, useEffect, useRef, useState } from 'react';

import { debounce } from 'lodash';

const useEditorHistory = ({ fabricCanvasRef }) => {
  const [historyState, setHistoryState] = useState({
    history: [],
    index: -1
  });

  const isRestoring = useRef(false);

  const debouncedPushToHistory = useRef(
    debounce((canvasInstance) => {
      const canvasJson = canvasInstance.toJSON();
      setHistoryState((prev) => {
        // Remove any future history if we’ve undone some steps
        const newHistory = prev.history.slice(0, prev.index + 1);
        newHistory.push(canvasJson);
        return { history: newHistory, index: newHistory.length - 1 };
      });
    }, 100)
  ).current;

  const loadHistoryState = (index) => {
    if (!fabricCanvasRef.current || !historyState.history[index]) return;

    isRestoring.current = true;
    fabricCanvasRef.current.loadFromJSON(historyState.history[index], () => {
      fabricCanvasRef.current.renderAll();
      // Re-enable history recording after state restoration.
      isRestoring.current = false;
    });
  };

  // Undo: move one step back in history.
  const undo = useCallback(() => {
    if (historyState.index <= 0) return; // No more undo steps.
    const newIndex = historyState.index - 1;
    loadHistoryState(newIndex);
    setHistoryState((prev) => ({ ...prev, index: newIndex }));
  }, [historyState]);

  // Redo: move one step forward in history.
  const redo = useCallback(() => {
    if (historyState.index >= historyState.history.length - 1) return; // No more redo steps.
    const newIndex = historyState.index + 1;
    loadHistoryState(newIndex);
    setHistoryState((prev) => ({ ...prev, index: newIndex }));
  }, [historyState]);

  useEffect(() => {
    const onKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
        event.preventDefault();
        redo();
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();
        undo();
      }
    };

    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [undo, redo]);

  const pushToHistory = (data) => {
    if (isRestoring.current) return;
    debouncedPushToHistory(data);
  };

  return { historyState, undo, redo, pushToHistory };
};

export default useEditorHistory;
