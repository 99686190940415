import React from 'react';
import Modal from 'components/shared/Modal/Modal';

export default function EmailModal({ emailHtml, setEmailHtml }) {
  function clearIframeTag() {
    const iframe = document.getElementById('my-iframe');
    const aTag = iframe.contentWindow.document.getElementsByTagName('a');
    for (let i = 0; i < aTag.length; i++) {
      aTag[i].href = '';
    }
  }

  return (
    <Modal
      isOpen={emailHtml ? true : false}
      handleClose={() => setEmailHtml(false)}
      className="h-[80vh] w-1/2"
      bodyClassName="!p-0 h-full max-h-[none] overflow-hidden"
      modalCenterAnimation={true}
      shouldCloseOnOverlayClick={true}>
      <iframe
        id="my-iframe"
        srcDoc={emailHtml}
        width="100%"
        height="100%"
        onLoad={clearIframeTag}
      />
    </Modal>
  );
}
