import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getProducts } from '../../../../api/Products';

export const useProducts = ({ params = {}, options = {} }) => {
  const navigate = useNavigate();
  return useQuery(['products'], () => getProducts(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
