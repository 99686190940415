import { formatDate, formatDateAndTime } from 'lib/helpers/utility';
import * as Yup from 'yup';

const validator = require('validator');

export const cols = [
  { field: 'id', headerName: 'ID', maxWidth: 100 },
  { field: 'version', headerName: 'Version', maxWidth: 100 },
  { field: 'template_name', headerName: 'Name' },
  { field: 'body', headerName: 'Prompt Template', minWidth: 100 },
  // { field: 'Options', headerName: 'Options', maxWidth: 150 },
  {
    field: 'created_at',
    headerName: 'Created At',
    valueFormatter: ({ value }) => formatDateAndTime(value)
  },
  {
    field: 'updated_at',
    headerName: 'Updated At',
    valueFormatter: ({ value }) => formatDateAndTime(value)
  }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'AI Task Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  }
};

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: false
};
export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'never',
  enableRangeSelection: true,
  // showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};

export const initialValues = (data) => ({
  id: data?.id,
  version: data?.version,
  template_name: data?.template_name,
  body: data?.body,
  ai_model_id: data?.ai_model_id,
  options: data?.options
});

export const validate = Yup.object().shape({
  ai_model_id: Yup.number().required('Model Specification Required')
});

export const promptOptionsExplainer = {
  mirostat:
    'Enable Mirostat sampling for controlling perplexity. (default: 0, 0 = disabled, 1 = Mirostat, 2 = Mirostat 2.0)',
  mirostat_eta:
    'Influences how quickly the algorithm responds to feedback from the generated text. A lower learning rate will result in slower adjustments, while a higher learning rate will make the algorithm more responsive. (Default: 0.1)',
  mirostat_tau:
    'Controls the balance between coherence and diversity of the output. A lower value will result in more focused and coherent text. (Default: 5.0)',
  num_ctx: 'Sets the size of the context window used to generate the next token. (Default: 2048)',
  repeat_last_n:
    'Sets how far back for the model to look back to prevent repetition. (Default: 64, 0 = disabled, -1 = num_ctx)',
  repeat_penalty:
    'Sets how strongly to penalize repetitions. A higher value (e.g., 1.5) will penalize repetitions more strongly, while a lower value (e.g., 0.9) will be more lenient. (Default: 1.1)',
  temperature:
    'The temperature of the model. Increasing the temperature will make the model answer more creatively. (Default: 0.8)',
  seed: 'Sets the random number seed to use for generation. Setting this to a specific number will make the model generate the same text for the same prompt. (Default: 0)',
  stop: 'Sets the stop sequences to use. When this pattern is encountered the LLM will stop generating text and return. Multiple stop patterns may be set by specifying multiple separate stop parameters in a modelfile.',
  tfs_z:
    'Tail free sampling is used to reduce the impact of less probable tokens from the output. A higher value (e.g., 2.0) will reduce the impact more, while a value of 1.0 disables this setting. (default: 1)',
  num_predict:
    'Maximum number of tokens to predict when generating text. (Default: 128, -1 = infinite generation, -2 = fill context)',
  top_k:
    'Reduces the probability of generating nonsense. A higher value (e.g. 100) will give more diverse answers, while a lower value (e.g. 10) will be more conservative. (Default: 40)',
  top_p:
    'Works together with top-k. A higher value (e.g., 0.95) will lead to more diverse text, while a lower value (e.g., 0.5) will generate more focused and conservative text. (Default: 0.9)',
  min_p:
    'Alternative to the top_p, and aims to ensure a balance of quality and variety. The parameter p represents the minimum probability for a token to be considered, relative to the probability of the most likely token. For example, with p=0.05 and the most likely token having a probability of 0.9, logits with a value less than 0.045 are filtered out. (Default: 0.0)'
};

export const promptOptionsDefaults = {
  mirostat: 0,
  mirostat_eta: 0.1,
  mirostat_tau: 5.0,
  num_ctx: 2048,
  repeat_last_n: 64,
  repeat_penalty: 1.1,
  temperature: 0.8,
  seed: 0,
  tfs_z: 1,
  num_predict: 128,
  top_k: 40,
  top_p: 0.9,
  min_p: 0.0
};
