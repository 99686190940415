import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import Icon from '../Icon/Icon';

const Hint = ({
  hint,
  icon = 'new-info',
  iconSize = 16,
  tooltip,
  disabled,
  error,
  className,
  tooltipClassName
}) => {
  if (!hint) return null;

  return (
    <Tippy content={tooltip} disabled={!tooltip} className={cs('tippy-dark', tooltipClassName)}>
      <div className={cs('flex items-center gap-x-1 pt-2', className)}>
        <Icon
          icon={icon}
          color={error ? 'danger' : 'neutral'}
          size={iconSize}
          shade={disabled ? 400 : 500}
        />
        <p
          className={cs(
            'text-sm',
            disabled ? 'text-neutral-400' : error ? 'text-danger-500' : 'text-neutral-700'
          )}>
          {hint}
        </p>
      </div>
    </Tippy>
  );
};

export default Hint;
