import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import _ from 'lodash';

import { PatientChartContextProvider } from 'lib/context/PatientChartContext/PatientChartContextProvider';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, iaRa, mapValues } from 'lib/helpers/utility';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import TableCounter from 'components/shared/Table/TableCounter';

import ChartExport from './components/ChartExport';
import NewPatient from './components/NewPatient';
import { defaultColumns, defaultFilters } from './lib/helper';

const isFirstColumn = (params) => {
  var displayedColumns = params.api.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

export default function PatientCharts() {
  return (
    <TableContextProvider
      cols={defaultColumns}
      defaultFilters={defaultFilters}
      pagination
      name="patient_charts">
      <Table />
    </TableContextProvider>
  );
}

function Table() {
  usePageTitle('Charts');
  const category = 'patient_charts';
  const kind = 'chart';
  const [newPatientModal, setNewPatientModal] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading, selectedRows, filters, setFilters } =
    useTableContext();

  const navigate = useNavigate();

  const {
    data = {},
    isLoading,
    isFetching
  } = usePatients({
    params: {
      searchTerm: filters?.searchTerm?.values,
      userStatus: filters?.status?.values,
      tags: filters?.tags?.values,
      page,
      sort,
      limit
    },
    dependencies: [mapValues(filters), page, sort, limit],
    page,
    sort,
    options: {
      keepPreviousData: true,
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 12 * (60 * 1000) // 12 mins
    }
  });
  const patients = data.patients;
  const count = data?.count || 0;

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });
  const tagOptions = useMemo(() => {
    return iaRa(tagsList?.tags)?.map((tag) => ({
      label: tag?.label,
      value: tag?.id
    }));
  }, [tagsList]);
  filters.tags.options = tagOptions;

  const hideNewPatientModal = () => setNewPatientModal(false);

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const onCellClicked = (e) => {
    if (e?.column?.colId === 'tagLinks') return;
    navigate(`/portal/charts/${e.data.id}`);
  };

  if (id)
    return (
      <PatientChartContextProvider>
        <Outlet />
      </PatientChartContextProvider>
    );
  return (
    <Allowed requiredPermissions="patients.read" showMessage={true} showIllustration={true}>
      <Header title="Charts">
        <div className="flex items-center gap-2" data-dd-privacy="allow">
          <Allowed requiredPermissions="patients.export">
            <Tippy
              className="tippy-dark"
              content={
                selectedRows.length == 0
                  ? 'Select at least one patient row to enable export'
                  : 'EHI Export'
              }
              placement="top">
              <div>
                <Button
                  icon="new-export-v2"
                  outlined
                  color="neutral"
                  iconSize={16}
                  className="!h-[30px] !w-[38px]"
                  disabled={selectedRows.length == 0}
                  onClick={() => setShowExport(true)}
                />
              </div>
            </Tippy>
          </Allowed>

          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <Allowed requiredPermissions="patients.create">
            <Button
              text="New Patient"
              icon="user-add"
              size="small"
              onClick={() => setNewPatientModal(true)}
              type="primary"
              data-qa="new-patient-btn"
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex h-full  flex-col overflow-hidden !pb-2" data-dd-privacy="allow">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={patients}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
            onCellClicked={onCellClicked}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={count}
            page={page}
          />
        </div>
      </div>
      {newPatientModal && (
        <NewPatient newPatientModal={newPatientModal} hideNewPatientModal={hideNewPatientModal} />
      )}
      {showExport && ia(selectedRows) && (
        <ChartExport
          selectedRows={selectedRows.map((r) => r.data.id)}
          show={showExport}
          setShow={setShowExport}
        />
      )}
    </Allowed>
  );
}
