import React from 'react';
import { Outlet } from 'react-router-dom';
import usePageTitle from 'lib/hooks/usePageTitle';
import NavbarAdmin from '../Navbar/NavbarAdmin';
import Sidebar from './components/Sidebar';

const Admin = () => {
  usePageTitle('Admin Portal');

  return (
    <section className="flex bg-white">
      <Sidebar />
      <section className="relative h-[100vh] w-full overflow-hidden rounded-bl-[48px] rounded-tl-[48px] border border-neutral-100 bg-neutral-50 hover:!border-neutral-100 active:!border-neutral-100">
        <NavbarAdmin />
        <main className="mt-[54px] flex h-[calc(100%-54px)] flex-col justify-between overflow-y-auto overflow-x-hidden">
          <Outlet />
        </main>
      </section>
    </section>
  );
};

export default Admin;
