import { mString } from 'lib/helpers/utility';

let delayed;
export const options = {
  animation: {
    onComplete: () => {
      delayed = true;
    },
    delay: (context) => {
      let delay = 0;
      if (context.type === 'data' && context.mode === 'default' && !delayed) {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    }
  },
  plugins: {
    datasets: {
      color: '#36A2EB'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false
    },
    y: {
      stacked: false
    }
  }
};

export const labels = [
  '0 - 30 Days',
  '31 - 60 Days',
  '61 - 90 Days',
  '91 - 120 Days',
  '121+ Days',
  'Total'
];

export const formatDataset = (amounts, label, color) => ({
  label,
  data: Object.values(amounts).map((amount) => amount || 0),
  backgroundColor: color,
  datalabels: {
    anchor: 'end',
    align: 'top',
    offset: 8,
    formatter: (value) => mString(value)
  }
});
