import PrintView from 'components/Patient/quote/components/PrintView';
import InvoiceReadOnly from 'components/Payments/components/InvoiceReadOnly';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import Receipt from 'components/shared/Receipt/Receipt';
import { useFormikContext } from 'formik';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { CustomSendModal } from './components/CustomSendModal/CustomSendModal';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import {
  sendEmail,
  sendText
} from 'components/practice/BeyondBilling/ElectronicInvoicing/lib/utils';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';

const Balance = () => {
  const [receiptModalVisible, setReceiptModalVisible] = useState(false);
  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');
  const { values } = useFormikContext();
  const practice = useRecoilValue(currentPractice);
  const receiptRef = useRef();
  const navigate = useNavigate();

  const { data: patient } = usePatient({
    params: { id: values?.patient?.id },
    dependencies: [values?.patient?.id]
  });

  const { invoice, starts_at, payments } = values;
  const receipt = {
    invoice,
    patient: patient?.patient,
    transactions: payments,
    transaction: { date_of_service: starts_at }
  };
  const balancePaid = ['success', 'warning'].includes(values.timeline.financial?.balance?.color);

  const patientNumbers = [
    { label: patient?.patient?.phone, value: patient?.patient?.phone, toAltPhone: false },
    { label: patient?.patient?.alt_phone, value: patient?.patient?.alt_phone, toAltPhone: true },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const patientEmails = [
    { label: patient?.patient?.email, value: patient?.patient?.email, toAltEmail: false },
    { label: patient?.patient?.alt_email, value: patient?.patient?.alt_email, toAltEmail: true },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const handleCustomSendModalClose = () => setCustomModalVisible(false);

  const handleSendCustom = async (data) => {
    const type = data[0];
    const value = data[2];

    if (type === 'email') {
      await sendEmail({
        id: invoice.id,
        navigate,
        customEmail: value
      });
    } else {
      await sendText({
        id: invoice.id,
        navigate,
        customPhone: value
      });
    }
  };

  const successCallback = () => {
    showAlert({
      title: 'Success',
      message: 'The invoice has been successfully sent',
      color: 'success'
    });
  };

  const handelSendEmail = async (email) => {
    if (email.value === 'Custom') {
      setCustomModalVisible(true);
      setCustomContactType('email');
    } else {
      await sendText({
        id: invoice.id,
        navigate,
        toAltPhone: email.toAltEmail,
        onSuccess: successCallback
      });
    }
  };

  const handleSendText = async (number) => {
    if (number.value === 'Custom') {
      setCustomModalVisible(true);
      setCustomContactType('text');
    } else {
      await sendText({
        id: invoice.id,
        navigate,
        toAltPhone: number.toAltPhone,
        onSuccess: successCallback
      });
    }
  };

  return (
    <Allowed requiredPermissions="invoicing.read">
      {balancePaid && (
        <div className="flex w-full items-center justify-between">
          <p className="text-sm  text-neutral-800 ">Receipt</p>
          <div
            className="flex cursor-pointer items-center gap-1"
            onClick={() => setReceiptModalVisible(true)}>
            <p className="text-sm  text-primary-600">View</p>
            <Icon
              icon="new-document-preview"
              color="primary"
              onClick={() => setReceiptModalVisible(true)}
            />
          </div>
        </div>
      )}

      <div className="flex w-full items-center justify-between ">
        <p className="text-sm  text-neutral-800 ">Invoice</p>
        <div className="flex items-center gap-2">
          <div
            className="flex cursor-pointer items-center gap-1"
            onClick={() => setInvoiceModalVisible(true)}>
            <p className="text-sm  text-primary-600">View</p>
            <Icon
              icon="new-document-preview"
              color="primary"
              onClick={() => setInvoiceModalVisible(true)}
            />
          </div>
          <Popover
            isFixed
            panelClassName="z-[200]"
            icon="three-dots"
            iconClassName="rotate-90"
            buttonClassName="!min-h-[20px] !min-w-[20px] !rounded-[3px]"
            options={[
              {
                label: 'Send via Email',
                icon: 'new-email-blue',
                color: 'primary',
                children: patientEmails.map((email) => ({
                  label: email.label,
                  onClick: () => handelSendEmail(email)
                }))
              },
              {
                label: 'Send via Text Message',
                icon: 'text-message',
                color: 'primary',
                children: patientNumbers.map((number) => ({
                  label: number.label,
                  onClick: () => handleSendText(number)
                }))
              }
            ]}
            optionIconSize={18}
            rerender={() => {}}
          />
        </div>
      </div>

      {receiptModalVisible && (
        <Modal
          slideFromRight
          title="Receipt"
          className="w-[1200px]"
          headButton={
            <div className="flex w-full items-center justify-end">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color=""
                    size="small"
                    type="link"
                    text="Print"
                    icon="new-printer-bulk"
                    className="text-primary-900"
                  />
                )}
                content={() => receiptRef.current}
              />
            </div>
          }
          handleOpen={receiptModalVisible}
          handleClose={() => setReceiptModalVisible(false)}
          handleClick={() => setReceiptModalVisible(false)}
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                outlined
                transparent
                text="Close"
                color="neutral"
                data-qa="close-btn"
                onClick={() => setReceiptModalVisible(false)}
              />
            </div>
          }>
          <Receipt receipt={receipt} parentRef={receiptRef} walkout />
        </Modal>
      )}
      {invoiceModalVisible && (
        <Modal
          slideFromRight
          title="Invoice"
          className="w-[1200px]"
          handleOpen={invoiceModalVisible}
          handleClose={() => setInvoiceModalVisible(false)}
          handleClick={() => setInvoiceModalVisible(false)}
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                outlined
                transparent
                text="Close"
                color="neutral"
                data-qa="close-btn"
                onClick={() => setInvoiceModalVisible(false)}
              />
            </div>
          }>
          <PrintView
            patient={patient?.patient}
            practice={practice}
            createdAt={invoice?.dateofservice}
            content={<InvoiceReadOnly invoices={[invoice]} />}
          />
        </Modal>
      )}
      {customModalVisible ? (
        <CustomSendModal
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
          sendLink={(...data) => handleSendCustom(...data)}
        />
      ) : null}
    </Allowed>
  );
};

export default Balance;
