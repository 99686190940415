const validator = require('validator')

const validateDatapoint = (payload) => {
  const errors = {}
  let message = ''
  let isFormValid = true

  if (!payload || typeof payload.name !== 'string' || payload.name.trim().length === 0) {
    isFormValid = false
    errors.name = 'Please provide a name.'
  }

  if (!isFormValid) {
    message = 'Check the form for errors.'
  }

  return {
    success: isFormValid,
    message,
    errors
  }
}

module.exports = {
  validateDatapoint: validateDatapoint
}
