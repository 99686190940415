import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { deletePrompt, getPrompt } from 'api/MyScribeAi';

import { useMyScribeTemplateContextData } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import { isJSON } from 'lib/helpers/utility';

import { convertClinicalNoteToHTML } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import {
  clinicalNote as clinicalNoteState,
  intelligenceChatIdState,
  myScribeAutoMapCurrentActivePromptState,
  myScribeAutoMapPromptListState
} from 'components/state';

const PromptsSidebar = ({ className, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const clinicalNote = useRecoilValue(clinicalNoteState);

  const [activePromptId, setActivePromptId] = useState(null);

  const setCurrentActivePrompt = useSetRecoilState(myScribeAutoMapCurrentActivePromptState);
  const { isGetPromptsLoading } = useMyScribeTemplateContextData();
  const localPrompts = useRecoilValue(myScribeAutoMapPromptListState);
  const setIntelligenceChatId = useSetRecoilState(intelligenceChatIdState);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const onPromptClick = async (id) => {
    try {
      const { chat } = await getPrompt(navigate, { id });
      console.log('🚀 ~ onPromptClick ~ chat:', chat);
      setActivePromptId(id);
      const newChat = chat.map((item) =>
        item?.agent === 'ai'
          ? {
              ...item,
              jsonMessage: item?.message,
              message: isJSON(item.message)
                ? convertClinicalNoteToHTML(JSON.parse(item.message))
                : item.message
            }
          : item
      );
      setIntelligenceChatId(chat[0]?.intelligence_chat_id);
      setCurrentActivePrompt(newChat);
    } catch (error) {
      console.error({ error });
    }
  };

  const addNewPrompt = () => {
    setActivePromptId(null);
    setCurrentActivePrompt(null);
    setIntelligenceChatId(null);
  };

  const onDeletePrompt = async (id) => {
    try {
      const { status, message } = await deletePrompt(navigate, { id });
      if (status === 'deleted') {
        showAlert({ title: 'Prompt Deleted', message, color: 'success' });
        queryClient.setQueryData(
          ['prompts', clinicalNote?.id],
          ({ conversations = [], ...rest }) => {
            return { conversations: conversations.filter((item) => item.id !== id), ...rest };
          }
        );
      }
    } catch (error) {
      console.error('Error deleting prompt: ', error);
    }
  };

  const getPopoverActions = (id) => [
    { label: 'Delete', icon: 'trash', onClick: () => onDeletePrompt(id) }
  ];

  return (
    <div
      className={cs(
        'fixed z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200 ',
        {
          'w-[264px]': isOpen,
          'w-0': !isOpen
        },
        className
      )}
      style={{
        transition: 'width 0.2s ease'
      }}>
      {isOpen && (
        <>
          <div className="flex items-center justify-between bg-primary-50 !py-[8px] px-3">
            <Icon icon="new-hamburger" className="!ml-1 cursor-pointer" onClick={toggleSidebar} />
            <div className="p-2 pl-0">Prompts</div>
            <Button
              icon="new-notev1"
              text="New Note"
              outlined
              size="small"
              onClick={addNewPrompt}
            />
          </div>
          {isGetPromptsLoading ? (
            <div className="m-3">
              <Skeleton height="20px" width="100px" />
              <Skeleton height="20px" />
            </div>
          ) : (
            <div className="overflow-y-auto h-[calc(100%-52px)]">
              {localPrompts?.map((prompt) => (
                <div
                  key={prompt.id}
                  onClick={() => onPromptClick(prompt.id)}
                  className={cs(
                    'm-3 mb-4 cursor-pointer rounded-lg p-2 hover:bg-primary-50',
                    activePromptId === prompt?.id && 'bg-primary-50'
                  )}>
                  <div className="flex justify-between">
                    <div className="mb-2 flex items-center">
                      <Icon icon="new-messages" className="mr-2" />
                      <span className="text-sm text-neutral-600">
                        {isJSON(prompt?.name) ? (
                          <div
                            className="font-base"
                            dangerouslySetInnerHTML={{
                              __html: convertClinicalNoteToHTML(JSON.parse(prompt.name))
                            }}
                          />
                        ) : (
                          prompt.name
                        )}
                      </span>
                    </div>
                    <Popover isFixed options={getPopoverActions(prompt.id)} />
                  </div>
                  <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-thin text-neutral-800">
                    {prompt.description}
                  </p>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PromptsSidebar;
