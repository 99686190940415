import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { deleteTag } from 'api/Tags';
import { deleteCustomFormType } from 'api/CustomForms';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { iaRa, optionify } from 'lib/helpers/utility';
import { useCustomFormTypes } from 'lib/hooks/queries/customForms/useCustomFormTypes';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import CreateFormFunction from './components/CreateFormFunction';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { showAlert } from 'components/shared/Alert/Alert';
import Select from 'components/shared/Select/Select';
import Icon from 'components/shared/Icon/Icon';

const DEFAULT_CREATE_MODAL_PROPS = {
  open: false,
  props: null
};

const CustomFormTypeSelect = ({
  formik,
  currentForm,
  kind,
  onSelectChange = () => {},
  label = 'Form Type',
  className,
  ...rest
}) => {
  const [selectedForm, setSelectedForm] = useState(currentForm ?? null);
  const [createModalProps, setCreateModalProps] = useState(DEFAULT_CREATE_MODAL_PROPS);
  const [isConfirmModal, setIsConfirmModal] = useState(false);
  const [currentFormId, setCurrentFormId] = useState(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const selectRef = useRef(null);

  const openConfirmModal = () => setIsConfirmModal(true);
  const closeIsConfirmModal = ({ clear = false } = {}) => {
    setIsConfirmModal(false);

    if (clear) {
      setCurrentFormId(null);
      handleOnChange(null);
    }
  };

  const { practice } = useOutletContext();
  const { data, isLoading } = useCustomFormTypes({
    params: { practice_id: practice?.id },
    dependencies: [practice?.id],
    options: {
      enabled: !!practice?.id,
      select: (data) => optionify(data?.form_functions, 'name', 'id', (e) => e)
    }
  });

  const { mutateAsync: mutateDeleteCustomForm, isLoading: isDeleting } = useMutation({
    mutationFn: (data) => deleteCustomFormType(navigate, data)
  });

  const handleOnChange = (form) => {
    setSelectedForm(form);
    onSelectChange(form);
  };

  const formatOption = (props) => {
    const { label, value, meta } = props;

    return (
      <div
        className="relative flex w-full items-center justify-between gap-[6px]"
        data-qa={`form-option-${label}`}>
        <span className={cs('h-[24px] cursor-default rounded-3xl py-[2px] leading-5')}>
          {/* {label} */}
        </span>
        {!props.__isNew__ && (
          <Tippy
            disabled={meta?.practice_id}
            className="tippy-dark"
            content="Cannot modify this form as it is not a custom type.">
            <div>
              <Icon
                disabled={!meta?.practice_id}
                icon="trash"
                onClick={(event) => handleRemoveOption({ event, id: value })}
                data-qa={`form-option-remove-${label}`}
              />
            </div>
          </Tippy>
        )}
      </div>
    );
  };

  const formatCreateLabel = (inputValue) => {
    return (
      <div
        className="absolute -left-[12px] -top-2 flex h-[calc(100%+16px)] w-[calc(100%+24px)] cursor-pointer items-center gap-1 rounded-[4px] border-0 !border-t border-solid !border-danger-100 bg-primary-50 !pl-[10px]"
        onClick={() => setCreateModalProps({ open: true })}
        data-qa="form-create-new">
        <span className="text-sm font-400 leading-[22px] text-neutral-900">Create a form for</span>
        <span className="text-sm font-500 leading-[22px] text-neutral-900">"{inputValue}"</span>
      </div>
    );
  };

  const handleRemoveOption = ({ event, id }) => {
    if (!event || !id) return;

    event.stopPropagation();

    openConfirmModal();

    setCurrentFormId(id);
  };

  const handleContinue = async () => {
    await mutateDeleteCustomForm(
      { formId: currentFormId },
      {
        onSettled: (data) => {
          const { code = null, error = null } = data ?? {};

          if (code !== 0) {
            showAlert({
              title: 'Form Type',
              message: error ?? 'Form could not be deleted! Please contact support.',
              color: 'danger'
            });

            closeIsConfirmModal();
            return;
          }

          queryClient.invalidateQueries(['customFormTypes']);
          showAlert({ title: 'Form type deleted successfully!', color: 'success' });
          closeIsConfirmModal({ clear: true });
        }
      }
    );
  };

  const onCreateOption = (inputValue) => {
    const existingOption = iaRa(data).find((option) => option?.value === inputValue);

    if (existingOption) return;

    const passIfInputValueIsString = typeof inputValue === 'string' ? inputValue : null;
    setCreateModalProps({ open: true, name: passIfInputValueIsString });
  };

  return (
    <>
      <Select
        label={label}
        parentClassName={cs('w-full', className)}
        forwardedRef={selectRef}
        isCreatable
        rightIcon="new-plus"
        rightIconColor="neutral"
        rightIconShade={400}
        rightIconSize={20}
        rightIconClick={onCreateOption}
        styles={{
          valueContainer: {
            gap: '2px 8px',
            padding: '4px 10px'
          },
          menu: {
            padding: 4
          },
          option: {
            borderRadius: 4
          }
        }}
        placeholder="Search forms"
        inputId="form-select"
        isClearable={false}
        isLoading={isLoading || isDeleting}
        options={data || []}
        value={selectedForm}
        onChange={(v) => handleOnChange(v)}
        formatOptionLabel={formatOption}
        formatCreateLabel={formatCreateLabel}
        onCreateOption={onCreateOption}
        error={formik?.errors?.type}
        {...rest}
      />
      {createModalProps?.open && (
        <CreateFormFunction
          modalProps={createModalProps}
          hideModal={() => setCreateModalProps(DEFAULT_CREATE_MODAL_PROPS)}
          setSelectedForm={handleOnChange}
        />
      )}
      <Confirm
        handleOpen={isConfirmModal}
        handleClose={closeIsConfirmModal}
        handleContinue={handleContinue}
        title="Delete custom form?"
        message="Are you sure you want to delete this custom form?"
        variant="danger"
      />
    </>
  );
};

export default withErrorBoundary(CustomFormTypeSelect);
