import React, { useEffect, useState } from 'react';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import HipaaFormModal from 'components/shared/Modal/HipaaFormModal/HipaaFormModal';

const HippaModal = ({ appointment, modalVisible, setModalUnVisible }) => {
  const [hipaaForm, setHipaaForm] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getHipaaForm();
  }, []);

  // TODO @Izet create custom api for get_hipaa_form
  const getHipaaForm = async () => {
    try {
      const res = await requestApi({
        url: '/api/patient/checkin/get_hipaa_form',
        params: {
          appointmentId: appointment?.id
        },
        navigate
      });

      if (res?.hipaa_consent?.data) {
        const decodedHtmlString = decodeURIComponent(
          JSON.parse(res?.hipaa_consent?.data)?.htmlData
        );

        const parser = new DOMParser();
        const doc = parser.parseFromString(decodedHtmlString, 'text/html');

        doc.querySelectorAll('img').forEach((img) => {
          img.setAttribute('draggable', 'false');
        });

        const updatedHtmlString = doc.body.innerHTML;

        setHipaaForm(updatedHtmlString);
      } else {
        setHipaaForm('noHIPAA');
      }
    } catch (e) {
      console.error(e);
      setHipaaForm(
        '<div>There has been an error retrieving HIPAA form. Please try again later.</div>'
      );
    }
  };

  return (
    <HipaaFormModal
      hipaaForm={hipaaForm}
      showHipaaModal={modalVisible}
      hideHipaaModal={setModalUnVisible}
      appendParentSelector={false}
    />
  );
};

export default HippaModal;
