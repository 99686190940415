import { useQuery } from '@tanstack/react-query';
import { getImportantDocuments, getPatientDocuments } from 'api/Documents';
import { useNavigate } from 'react-router-dom';

export const useDocuments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['documents', ...dependencies], () => getPatientDocuments(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useImportantDocuments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['importantDocuments', ...dependencies],
    () => getImportantDocuments(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
