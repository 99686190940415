import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import state from '/components/state';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io, sortObjectByProperty } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import OrdersSection from '../../../Orders/OrdersSection';
import { filterFalsyValues } from '../../../lib/filterFalsyValues';
import { generateHPList } from '../../../lib/generateHPList';

import AdvancedHP from './components/AdvancedHP';
import AllergiesSection from './components/AllergiesSection';
import AssessmentSection from './components/AssessmentSection';
import CPTCodesSection from './components/CPTCodesSection';
import ComplaintsSection from './components/ComplaintsSection';
import ExamSection from './components/ExamSection';
import FamilyHistorySection from './components/FamilyHistorySection';
import HPIRevisionsSection from './components/HPIRevisionsSection';
import ICDCodesSection from './components/ICDCodesSection';
import MedicalHistorySection from './components/MedicalHistorySection';
import MedicationsSection from './components/MedicationsSection';
import NotesSection from './components/NotesSection';
import PlanSection from './components/PlanSection';
import ReviewOfSystems from './components/ReviewOfSystems';
import SocialHistorySection from './components/SocialHistorySection';
import SurgicalHistorySection from './components/SurgicalHistorySection';
import TestSection from './components/TestSection';
import VaccinesSection from './components/VaccinesSection';
import VitalsSection from './components/VitalsSection';
import WeightLossAdministrationSection from './components/WeightLossAdministrationSection';
import { exceptions as narrativeExceptions, map as narrativeMap } from './constants';

const HPNote = ({ context }) => {
  const intelligenceAiState = useRecoilValue(state.intelligenceAiState);
  const { hpNoteOverviewRefs } = context;
  const {
    components,
    setComponents,
    advancedHP,
    setOverviewData,
    cnDisplaySettings,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton,
    setEnhanceNarrativeParams,
    isFetchingEnhanceNarrative,
    currentHpOverviewLoading
  } = useClinicalNoteContext();
  const { id, appointmentId, formName, type } = useParams();
  const queryClient = useQueryClient();
  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton,
    currentHpOverviewLoading
  };

  const [formsList, setFormList] = useState({
    complaints: ComplaintsSection, // part of clinical_note -
    hpi: HPIRevisionsSection, // part of clinical_note -
    ros: ReviewOfSystems, // part of clinical_note -
    allergies: AllergiesSection, // not part of clinical note -
    medicationHistory: MedicationsSection, // part of soap and clinical -
    vaccines: VaccinesSection, // not part of clinical note -
    vitals: VitalsSection, // wip
    weightLossAdministration: WeightLossAdministrationSection, // not part of clinical note -
    socialHistory: SocialHistorySection, // not part of clinical note -
    familyHistory: FamilyHistorySection, // not part of clinical note -
    medicalHistory: MedicalHistorySection, // not part of clinical note -
    surgicalHistory: SurgicalHistorySection, // not part of clinical note -
    exam: ExamSection, // part of clinical_note -
    test: TestSection, // part of clinical_note -
    assessment: AssessmentSection, // part of clinical_note -
    plan: PlanSection, // part of clinical_note -
    'cpt-codes': CPTCodesSection, // part of clinical_note -
    'diagnosis-codes': ICDCodesSection, // part of clinical_note -
    notes: NotesSection, // part of clinical_note -
    orders: OrdersSection // not part of clinical note -
  });
  const sectionRef = useRef();

  const firstActiveComponent = Object.keys(components)[0];
  const currAdvForm = advancedHP?.find((item) => {
    const title = item?.custom_title;
    if ((!formName && title === firstActiveComponent) || formName === title) return item;
  });

  useEffect(() => {
    const advancedHPForms = advancedHP.map((item) => {
      if (cnDisplaySettings?.sections?.hp[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    });
    const filteredAdvancedHPForms = advancedHPForms.filter((value) => io(value));
    const advancedTitles =
      ia(filteredAdvancedHPForms) &&
      Object.fromEntries(
        filteredAdvancedHPForms.map((item) => {
          const title = item?.custom_title;
          return [
            title,
            {
              title: item?.form?.name || item?.name,
              path: title,
              position: cnDisplaySettings?.sections?.hp?.[item?.custom_title]?.position
            }
          ];
        })
      );

    const assignAdvTitlesToComponent = Object.keys(advancedTitles).reduce((acc, key) => {
      acc[key] = () => <AdvancedHP currAdvForm={currAdvForm} />;
      return acc;
    }, {});

    setFormList((prevState) => ({ ...prevState, ...assignAdvTitlesToComponent }));

    const enabledComponents = filterFalsyValues(generateHPList(cnDisplaySettings, advancedTitles));
    const sortedComponents = sortObjectByProperty(enabledComponents, 'position');
    setComponents(sortedComponents);
  }, [cnDisplaySettings?.sections, advancedHP, formName, currAdvForm]);

  useEffect(() => {}, [intelligenceAiState]);

  const CurrentForm = formsList && formsList?.[formName || Object.values(components)?.[0]?.path];

  const handleEnhanceNarrative = async () => {
    let body = sectionRef?.current?.formData?.[formName];
    if (!body) {
      let narrativeName = formName;
      if (narrativeExceptions.includes(formName)) {
        narrativeName = narrativeMap[formName];
      }
      body = await queryClient.getQueryData(['patientAggregateNarrative', id, narrativeName]).data
        ?.narrative?.narrative;
    }
    setEnhanceNarrativeParams({
      kind: type,
      name: formName,
      body: body.replace(/[\u{0080}-\u{FFFF}]/gu, ''),
      customFormId: null
    });
  };

  if (!io(components) || !CurrentForm) return <Skeleton height="146px" />;

  const currentFormName = formName || Object.values(components)?.[0]?.path;

  return (
    <div className="flex h-full flex-col gap-y-3">
      <CurrentForm
        key={formName}
        components={components}
        sectionRef={sectionRef}
        options={[
          {
            title: 'Enhance with MyScribe AI',
            icon: 'new-myscribe-stars',
            onClick: handleEnhanceNarrative,
            loading: isFetchingEnhanceNarrative,
            id: 'myscribe-enhance-narrative'
          }
        ]}
        formId={currentHpOverviewData?.[currentFormName]?.id}
        defaultValue={currentHpOverviewData?.[currentFormName]?.response}
        hpNoteOverviewRef={hpNoteOverviewRefs?.[currentFormName]}
        customFormsParams={customFormsParams}
        setCurrentFormData={(newData) =>
          setCurrentHpOverviewData((prevState) => ({
            ...prevState,
            [currentFormName]: { ...(prevState?.[currentFormName] || {}), response: newData }
          }))
        }
      />
    </div>
  );
};

export default HPNote;
