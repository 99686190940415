import React from 'react';
import Icon from '../../../../../../../shared/Icon/Icon';

import { beautifyAppendedString } from '../../../helpers/sidebarHelpers';

const ShowDataResult = ({ result, setResult }) => {
  return (
    <div className="h-full overflow-scroll !p-2">
      <div
        data-qa="back-action"
        onClick={() => setResult(null)}
        className="flex w-min cursor-pointer items-center gap-2 rounded-lg !p-1 transition-all hover:bg-neutral-50">
        <Icon icon="back" className="cursor-pointer" shade={900} color="primary" />
        <p className="w-32 overflow-hidden text-ellipsis whitespace-nowrap text-lg text-primary-900">
          Back
        </p>
      </div>
      {beautifyAppendedString(result)}
    </div>
  );
};

export default ShowDataResult;
