import React from 'react';

import { formatDate, snakeToTitleCase } from '../../../../../../../../lib/helpers/utility';

const PatientDetails = ({ itemProps }) => {
  return (
    <div>
      <div className="text-base font-500 text-neutral-800">Patient Details</div>

      <ul className="!mt-3 !pl-5">
        <li className="!list-outside list-disc whitespace-pre-wrap break-normal text-sm">
          {itemProps?.pat_f_name && (
            <div>
              <strong>First Name:</strong> {itemProps?.pat_f_name}
            </div>
          )}

          {itemProps?.pat_l_name && (
            <div>
              <strong>Last Name:</strong> {itemProps?.pat_l_name}
            </div>
          )}

          {itemProps?.pat_home_phone && (
            <div>
              <strong>Home Phone:</strong> {itemProps?.pat_home_phone}
            </div>
          )}

          {itemProps?.pat_work_phone && (
            <div>
              <strong>Work Phone:</strong> {itemProps?.pat_work_phone}
            </div>
          )}

          {itemProps?.pat_sex && (
            <div>
              <strong>Sex:</strong> {snakeToTitleCase(itemProps?.pat_sex)}
            </div>
          )}

          {itemProps?.pat_addr && (
            <div>
              <strong>Address:</strong> {itemProps?.pat_addr}
            </div>
          )}

          {itemProps?.pat_dob && (
            <div>
              <strong>DOB:</strong>
              {formatDate(itemProps?.pat_dob)}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default PatientDetails;
