import React, { createElement, forwardRef, useState } from 'react';
import cs from 'classnames';
import { ia } from 'lib/helpers/utility';
import useTimelineActions from '../../lib/useTimelineActions';
import { bgClassesOnFocus, borderClassesOnFocus, stripeClasses } from '../../lib/getColorClasses';
import Icon from 'components/shared/Icon/Icon';
import AptEndedModal from '../content/components/AptEndedModal';
import Transition from 'components/shared/Transition/Transition';
import Popover from 'components/shared/Popovers/Popover/Popover';

import {
  Balance,
  CCOnFile,
  CNArrived,
  CNStarted,
  Checkin,
  Claim,
  Confirmed,
  Superbill
} from '../content';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { isEmpty } from 'lodash';

import BillingPatientModal from '../content/components/Billing/BillingPatientModal';
import { useFormikContext } from 'formik';

const Item = forwardRef(
  ({ color, name, target, isOpen, isFocus, onToggle, data, isFetching }, ref) => {
    const [aptEndedModalVisible, setAptEndedModalVisible] = useState(false);
    const [billingModalState, setBillingModalState] = useState({
      open: false,
      selectedTab: null,
      id: null
    });
    const timelineActions = useTimelineActions(target, setAptEndedModalVisible);
    const { values } = useFormikContext();

    const contentList = {
      confirmed: Confirmed,
      checkin: Checkin,
      balance: Balance,
      'cc-on-file': CCOnFile,
      arrived: CNArrived,
      'clinical-note-started': CNStarted,
      superbill: Superbill,
      claim: Claim
    };

    const isAptDisabled = values.appointment_disabled;
    const showItemActions = ia(timelineActions[target]) && !isAptDisabled;

    return (
      <div
        ref={ref}
        tabIndex={-1}
        className={cs(
          "group relative mt-[10px] rounded-md border border-solid border-neutral-100 before:absolute before:left-0 before:h-full before:w-[4px] before:rounded-l-md before:content-[''] first-of-type:mt-0",
          stripeClasses[color],
          isFocus && borderClassesOnFocus[color]
        )}>
        <div
          className="flex cursor-pointer select-none items-center justify-between gap-[6px] py-[14px] pl-3 pr-[10px]"
          onClick={onToggle}>
          <div className="flex items-center gap-[6px]">
            <Icon
              icon="new-chevron-right"
              color="neutral"
              stroke
              size={18}
              className={cs('cursor-pointer duration-150', isOpen && '-rotate-90')}
            />
            {color && (
              <Icon
                icon={
                  color === 'success' || color === 'neutral'
                    ? 'new-check'
                    : color === 'warning'
                      ? 'new-partially-completed'
                      : 'new-close-v2'
                }
                color={color}
                stroke={color !== 'warning'}
                size={20}
                className="cursor-pointer"
              />
            )}
            <span className="text-sm text-neutral-800">{name}</span>
          </div>
          {showItemActions && (
            <Popover
              isFixed
              panelClassName="z-[200]"
              iconClassName="rotate-90"
              buttonClassName="!min-h-[20px] !min-w-[20px] !rounded-[3px]"
              options={timelineActions[target]}
              optionIconSize={18}
              rerender={onToggle}
            />
          )}
        </div>
        <Transition isOpen={isOpen} type="scale">
          <div
            className={cs(
              'relative z-[1000]',
              isOpen
                ? `ml-1 w-[calc(100%-4px)] select-none py-[10px] pl-[10px] pr-[14px] text-sm duration-150`
                : 'h-0 p-0',
              isFocus && bgClassesOnFocus[color]
            )}>
            {contentList[target] ? (
              isFetching && isEmpty(data) ? (
                <Skeleton count={3} height="10px" />
              ) : (
                createElement(contentList[target], {
                  data,
                  setAptEndedModalVisible,
                  setBillingModalState
                })
              )
            ) : null}
          </div>
        </Transition>

        {aptEndedModalVisible && (
          <AptEndedModal
            modalVisible={aptEndedModalVisible}
            setModalUnVisible={() => setAptEndedModalVisible(false)}
          />
        )}

        {billingModalState.open && (
          <BillingPatientModal
            modalVisible={billingModalState.open}
            selectedTab={billingModalState.selectedTab}
            dataId={billingModalState.id}
            patient_id={values.user_id}
            setModalUnVisible={() =>
              setBillingModalState({
                id: null,
                selectedTab: null,
                open: false
              })
            }
          />
        )}
      </div>
    );
  }
);

export default Item;
