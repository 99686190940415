import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import Tabs from 'components/shared/Tabs/NewTabs';
import Modal from 'components/shared/Modal/Modal';
import { Capitalize, ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import { currentPractice } from 'components/practice/practiceState';
import Eligibility from 'components/practice/Calendar/RBCalendar/Eligibility';
import EligibilityPreview from 'components/practice/Calendar/RBCalendar/BenefitPreview/EligibilityPreview';
import { runEligibility } from 'components/practice/appointment/PreviewAppointment/lib/updateStateHelpers';

const EligibilityWrapper = ({ patient, onSuccess = () => {} }) => {
  const formik = useFormikContext();

  const queryClient = useQueryClient();
  const [practice, setPractice] = useRecoilState(currentPractice);

  const [tabIndex, setTabIndex] = useState(0);
  const [runningEligibility, setRunningEligibility] = useState(false);
  const [showEligibilityPopover, setShowEligibilityPopover] = useState(false);
  const [showEligibilityResponse, setShowEligibilityResponse] = useState(false);
  const [insuranceBenefits, setInsuranceBenefits] = useState(patient?.insurance_benefits || {});

  const closeEligibilityModal = () => setShowEligibilityResponse(false);

  const insuranceProfiles = patient?.insuranceProfile;
  const eligibilityPresent = insuranceBenefits?.primary?.retrieved || false;

  const handleRunEligibility = () => {
    runEligibility({
      formik,
      onSuccess,
      queryClient,
      setPractice,
      insuranceBenefits,
      setInsuranceBenefits,
      setRunningEligibility,
      hasAppt: true,
      appointmentId: formik.values?.id
    });

    setShowEligibilityPopover(false);
  };

  const tabsData = [];

  for (let type in insuranceBenefits) {
    const insurance = insuranceBenefits[type];

    if (['primary', 'secondary', 'tertiary'].includes(type)) {
      tabsData.push({
        label: Capitalize(type),
        content: (
          <EligibilityPreview
            eligibility={insurance.benefits}
            closeModal={closeEligibilityModal}
            insuranceProfilesList={insuranceProfiles}
          />
        )
      });
    }
  }

  return (
    <>
      {eligibilityPresent && (
        <Button
          transparent
          size="small"
          type="primary"
          color="primary"
          className="!py-0 !pl-[0] !pr-[0]"
          text="Eligibility response"
          onClick={() => setShowEligibilityResponse(true)}
        />
      )}

      {ia(insuranceProfiles) && (
        <Eligibility
          loading={runningEligibility}
          show={showEligibilityPopover}
          callback={handleRunEligibility}
          setShow={setShowEligibilityPopover}
          existEligibility={eligibilityPresent}
          insuranceProfilesList={insuranceProfiles}
          eligibilityCounter={practice?.eligibility_counter}
        />
      )}

      <Modal
        slideFromRight
        bodyClassName="p-0"
        className="w-[1200px]"
        title="Eligibility response"
        handleClose={closeEligibilityModal}
        handleOpen={showEligibilityResponse}>
        <Tabs
          hasContent
          theme="secondary"
          tabsData={tabsData}
          className="z-[100]"
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          tabsParentClassName="!sticky top-0 z-[99]"
        />
      </Modal>
    </>
  );
};

export default EligibilityWrapper;
