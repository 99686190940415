import { requestApi } from './Api';

export const getDocumentSign = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/document_sign/get',
    params
  });

export const createDocumentSign = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/document_sign/create',
    params
  });

export const updateDocumentSign = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/document_sign/update',
    params
  });

export const manualAddDocumentSign = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/document_sign/manual_add',
    params
  });

export const updateMultipleDocumentSign = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/document_sign/update_multiple',
    params
  });
