import { iaRa } from 'lib/helpers/utility';
import React, { useRef, useState } from 'react';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Container from '../components/Container';
import DeleteFormModal from '../components/DeleteFormModal';
import Header from '../components/Header';
import '../Custom.scss';
import { SurgicalHistory as initialValue } from '../CustomFormInitialStates';
import useCustomForm from '../lib/useCustomForm';
import SurgicalHistoryRow from './SurgicalHistoryRow';

const SurgicalHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  formId = null,
  showNarrative,
  onChange,
  setItemModal = () => {}
}) => {
  const { cnDisplaySettings } = useClinicalNoteContext() || {};
  const [items, setItems] = useState(initialValue);

  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.allergies?.advancedForm
      : true;
  const textAreaRef = useRef();

  const [itemToDelete, setItemToDelete] = useState(null);

  const rows = 'row';

  const props = {
    itemToDelete,
    setItemToDelete,
    formId,
    setItems,
    setModal: setItemModal,
    fromCheckin: true,
    items,
    rows,
    title: 'Surgical History',
    alertTitle: 'Surgical History',
    initialValue,
    textAreaRef,
    defaultValue,
    onChange,
    setCurrentFormData
  };

  const { handleAddRow, handleOnChange, handleNoDetails, onDelete, syncItems, length } =
    useCustomForm(props);

  return (
    <div className="grid">
      <input type="hidden" value={JSON.stringify(items)} ref={forwardedRef} />
      <Header
        title="Surgical History"
        noDetailsLabel="Do you have any notable surgical history?"
        isAdvancedForm={isAdvancedForm}
        fromCheckin
        noDetails={items?.noDetails}
        emptyState={{
          title: 'No surgeries reported',
          subtitle:
            'Surgical history covers any procedures you’ve had in the past. Please share this information to help us better understand your medical background.',
          label: 'Do you have any surgeries not reported to our practice?'
        }}
        addRow={{ onClick: handleAddRow, label: 'Do you want to add another surgery?' }}
        handleNoDetails={handleNoDetails}>
        <div className="flex w-full flex-col items-center gap-y-4">
          {iaRa(items?.[rows])?.map((item, idx) => (
            <Container
              key={item.id}
              fromCheckin
              onDelete={() => onDelete(item?.id)}
              number={idx + 1}>
              <SurgicalHistoryRow
                key={item.id}
                index={idx}
                id={item?.id}
                fromCheckin
                onChange={handleOnChange}
                length={length}
                {...item}
              />
            </Container>
          ))}
        </div>
      </Header>
      <DeleteFormModal syncItems={syncItems} {...props} />
    </div>
  );
};

export default SurgicalHistory;
