export const cleanUpVitals = ({ data = {} }) => {
  delete data?.created_at;
  const updatedVitals = data?.vitals?.map((vital) => {
    delete vital?.id;
    delete vital?.createdBy;
    return vital;
  });

  return {
    ...data,
    vitals: updatedVitals
  };
};
