import React from 'react';
import Tippy from '@tippyjs/react';

import Dot from 'components/shared/Dot/Dot';
import { procedureTypes } from 'constants.js';
import Icon from 'components/shared/Icon/Icon';
import { Capitalize, ia } from 'lib/helpers/utility';
import Popover from 'components/shared/Popovers/Popover/Popover';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import {
  removeItem,
  handleChangePriceProcedures,
  handleProcedureType
} from '../../lib/appointmentHelpers';
import { shouldDisableProcedureProduct } from '../../lib/shouldDisableProcedureProduct';

const currencyInputClass =
  'h-[41px] !px-4 !bg-transparent border-none !border-b-2 [border-bottom-style:solid] [border-bottom-color:transparent] focus-within:!border-b-2 rounded-none border-white focus-within:!outline-[0px] !outline-0 focus-within:shadow-medium';

const DotWrapper = () => (
  <p className="absolute -mt-[1px] ml-1 flex h-full items-center">
    <Dot color="success" size="sm" />
  </p>
);

export const deleteItem = (event) => {
  const {
    rowIndex,
    data: { id, procedure_code },
    context: {
      type,
      setFieldValue,
      selectedItems,
      isClinicalNoteLocked,
      originalProceduresProducts
    }
  } = event || {};

  const tooltipMessage = `${type ? Capitalize(type) : 'Item'} cannot be removed because the clinical note is locked.`;

  const disabled = shouldDisableProcedureProduct({
    type,
    productId: id,
    isClinicalNoteLocked,
    originalProceduresProducts,
    procedureCode: procedure_code
  });

  return (
    <Tippy className="tippy-dark h-full" content={disabled ? tooltipMessage : 'Remove'}>
      <div className="h-full">
        <Icon
          icon="new-close"
          className="h-full"
          disabled={disabled}
          data-qa="remove-procedure-products-icon"
          onClick={() => removeItem(setFieldValue, selectedItems, type, rowIndex)}
        />
      </div>
    </Tippy>
  );
};

export const typeCell = (event) => {
  const {
    rowIndex,
    data: { type, procedure_code, invoiceId },
    context: { setFieldValue, selectedItems, list }
  } = event || {};

  const procedureOptions = (
    procedure_code ? procedureTypes : procedureTypes.filter((t) => t?.value !== 'primary')
  ).map((option) => ({
    ...option,
    onClick: () => handleProcedureType(setFieldValue, selectedItems, rowIndex, option.value, list)
  }));

  const currentType = procedureTypes.find(
    (o) => o?.value === (type === 'cash' ? 'self_pay' : type)
  )?.label;

  const hasInvoiceId = !!invoiceId;

  return !hasInvoiceId && ia(procedureTypes) ? (
    <Popover
      isFixed
      isDropdown
      icon={false}
      position="left"
      buttonTheme={null}
      text={currentType}
      panelClassName="z-[100]"
      options={procedureOptions}
      textClassName="text-neutral-900 font-400 text-nowrap w-full"
      className="w-full border-0 border-b border-solid border-neutral-200"
      buttonClassName="!min-h-[28px] !rounded-none !min-w-[90px] !px-4 !justify-end h-[40px] w-full text-left"
    />
  ) : (
    <label className="text-500 !mb-0 px-4 text-sm text-neutral-500">
      {invoiceId ? 'Package' : 'Service'}
    </label>
  );
};

export const chargeCell = (event) => {
  const {
    rowIndex,
    data: { charge, invoiceId, pt_balance, total_cost_cents },
    context: { setFieldValue, selectedItems }
  } = event || {};

  return (
    <CurrencyInput
      disabled={!!invoiceId}
      onValueChange={() => {}}
      wrapperClassName={currencyInputClass}
      value={charge ?? pt_balance ?? total_cost_cents ?? 0}
      onBlur={(v) =>
        handleChangePriceProcedures(setFieldValue, selectedItems, v, rowIndex, 'charge')
      }
    />
  );
};

export const selfPayAtm = (event) => {
  const {
    rowIndex,
    data: { invoiceId, type, pt_balance, total_cost_cents },
    context: { setFieldValue, selectedItems }
  } = event || {};

  const isSelfPay = type === 'self_pay';

  return (
    <>
      {isSelfPay && <DotWrapper />}

      <CurrencyInput
        disabled={!!invoiceId}
        onValueChange={() => {}}
        wrapperClassName={currencyInputClass}
        value={pt_balance ?? total_cost_cents ?? 0}
        className={isSelfPay ? 'text-success-600' : ''}
        onBlur={(v) => handleChangePriceProcedures(setFieldValue, selectedItems, v, rowIndex)}
      />
    </>
  );
};

export const allowedCell = (event) => {
  const {
    rowIndex,
    data: { ins_payment, invoiceId, type },
    context: { setFieldValue, selectedItems }
  } = event || {};

  return (
    <>
      {type === 'primary' && <DotWrapper />}
      <CurrencyInput
        disabled={!!invoiceId}
        value={ins_payment || 0}
        onValueChange={() => {}}
        wrapperClassName={currencyInputClass}
        className={type === 'primary' ? 'text-success-600' : ''}
        onBlur={(v) =>
          handleChangePriceProcedures(setFieldValue, selectedItems, v, rowIndex, 'ins_payment')
        }
      />
    </>
  );
};

export const ptPortionCell = (event) => {
  const {
    rowIndex,
    context: { setFieldValue, selectedItems },
    data: { pt_balance, total_cost_cents, invoiceId, type, modified, allowed, ins_payment }
  } = event || {};

  let value = total_cost_cents ?? 0;

  if ((modified && pt_balance) || pt_balance > 0) value = pt_balance ?? 0;

  return (
    <CurrencyInput
      value={value || 0}
      disabled={!!invoiceId}
      onValueChange={() => {}}
      wrapperClassName={currencyInputClass}
      maxValue={type === 'primary' ? (allowed ?? ins_payment ?? undefined) : undefined}
      onBlur={(v) => handleChangePriceProcedures(setFieldValue, selectedItems, v, rowIndex)}
    />
  );
};
