import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

import { Canvas } from '@react-three/fiber';
import { CameraControls, PerspectiveCamera } from '@react-three/drei';
import Model from './components/Model';
import Point from './components/Point';

import ProductsBar from './components/ProductsBar';

import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

import cs from 'classnames';

import { useChart3dContext } from '../../../../../../../lib/context/Chart3dContext/Chart3dContext';
import { AlertContent } from '../../../../../../shared/Alert/Alert';
import Select from '../../../../../../shared/Select/Select';
import Icon from '../../../../../../shared/Icon/Icon';
import { ACESFilmicToneMapping } from 'three';
import Input from '../../../../../../shared/Input/Input';
import Textarea from '../../../../../../shared/Textarea/Textarea';

export const Scene = ({ preview }) => {
  const { filterPoints } = useChart3dContext();

  return (
    <Suspense fallback={null}>
      <Model preview={preview} />
      <scene>
        {filterPoints().map((point, index) => (
          <Point preview={preview} point={point} index={index} key={index} />
        ))}
      </scene>
    </Suspense>
  );
};

export const Canvas3D = () => {
  const {
    mode,
    setMode,
    selectedItem,
    hovered,
    selected,
    points,
    setPoints,
    setDrawing,
    drawing,
    drawHistory,
    setDrawHistory
  } = useChart3dContext();
  const { previewNoteModal } = useClinicalNoteContext();

  const [showAlert, setShowAlert] = useState(false);

  const [openNote, setOpenNote] = useState(null);

  const cameraControlsRef = useRef(null);

  const modes = [
    {
      label: 'Skin',
      value: 'skin',
      icon: 'trash'
    },
    {
      label: 'Muscular',
      value: 'muscles',
      icon: 'glass'
    }
  ];

  const areaUnits = [
    {
      label: 'Near',
      value: 'near'
    },
    {
      label: 'Inside',
      value: 'in'
    }
  ];

  const zoom = (zoomin) => {
    if (cameraControlsRef.current) {
      cameraControlsRef.current.dolly(zoomin ? 1 : -1, true);
    }
  };

  useEffect(() => {
    setDrawHistory(points[selected]?.draw);
  }, []);

  const canUndo = () => {
    return points[selected]?.draw?.length > 0;
  };

  const undo = () => {
    if (canUndo()) {
      setPoints((points) => {
        const newPoints = [...points];
        newPoints[selected]?.draw?.pop();
        return newPoints;
      });
    }
  };

  const canRedo = () => {
    return drawHistory?.length > points[selected]?.draw?.length;
  };
  const redo = () => {
    if (canRedo()) {
      setPoints((points) => {
        const newPoints = [...points];
        newPoints[selected]?.draw?.push(drawHistory[newPoints[selected]?.draw.length]);
        return newPoints;
      });
    }
  };

  const [key, setKey] = useState();

  useEffect(() => {
    setKey('');
    if (drawing) {
      switch (key) {
        case 'undo':
          return undo();
        case 'redo':
          return redo();
      }
    }
  }, [key]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'z') {
        setKey('redo');
      } else if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        setKey('undo');
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <div className="relative h-[calc(100vh-211px)] w-full flex-1 overflow-hidden">
      <Canvas
        className={cs(
          'flex-1 rounded-3xl bg-primary-50',
          hovered !== null
            ? 'cursor-pointer'
            : selectedItem !== null && selected === null && 'cursor-copy'
        )}
        gl={{ antialias: true, toneMappingExposure: 3, toneMapping: ACESFilmicToneMapping }}
        linear>
        {!previewNoteModal ? <Scene /> : <></>}

        <PerspectiveCamera makeDefault position={[0, 0, 8]} fov={25}></PerspectiveCamera>

        <CameraControls
          ref={cameraControlsRef}
          makeDefault
          maxPolarAngle={Math.PI / 1.7}
          maxDistance={8}
          minDistance={4}
        />
      </Canvas>

      <div className="absolute left-0 top-0 z-[2]  mx-[28px] my-[24px] flex flex-col gap-4">
        {showAlert && (
          <div className="w-1/2">
            <AlertContent
              width="full"
              title="Select products and add them to the 3D Chart.Swipe horizontally and vertically to rotate and zoom in/out to see more details!"
              handleClose={() => setShowAlert(false)}
            />
          </div>
        )}

        <div className="flex gap-2">
          <Select
            menuPlacement="bottom"
            icon="scanning"
            isClearable={false}
            className="rounded-lg !border-none shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)] outline-none"
            placeholder="Type and select mode"
            options={modes}
            isSearchable={false}
            onChange={({ value }) => setMode(value)}
            value={{
              label: modes.find((m) => m.value == mode)?.label
            }}></Select>

          <Icon onClick={() => setShowAlert(!showAlert)} color="primary" icon="new-info"></Icon>
        </div>
      </div>

      {(selected || selected === 0) && (
        <div className="absolute right-0 top-0 z-10 mx-[28px] my-[24px] flex flex-col gap-4">
          <div className="flex flex-col justify-end gap-2">
            <div className="flex gap-2">
              {drawing ? (
                <>
                  <div
                    onClick={undo}
                    className={`w-10  ${
                      canUndo() ? 'cursor-pointer ' : ' cursor-not-allowed'
                    } flex h-10 items-center justify-center rounded-lg bg-white shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]`}>
                    <Icon
                      className={`${
                        canUndo() ? 'cursor-pointer ' : ' cursor-not-allowed opacity-50'
                      }`}
                      icon="undo"></Icon>
                  </div>

                  <div
                    onClick={redo}
                    className={`w-10  ${
                      canRedo() ? 'cursor-pointer ' : ' cursor-not-allowed'
                    } flex h-10 items-center justify-center rounded-lg bg-white shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]`}>
                    <Icon
                      className={`${
                        canRedo() ? 'cursor-pointer ' : ' cursor-not-allowed opacity-50'
                      }`}
                      icon="redo"></Icon>
                  </div>
                </>
              ) : (
                <>
                  <Input
                    className="rounded-lg !border-none shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)] outline-none"
                    placeholder="Area..."
                    onChange={(e) => {
                      points[selected].area = e.target.value;
                      setPoints([...points]);
                    }}
                    value={points[selected]?.area || ''}
                    icon="new-edit"
                    onUnitChange={(value) => {
                      points[selected].type = value;
                      setPoints([...points]);
                    }}
                    currentUnit={
                      areaUnits.find((unit) => unit.value == points[selected]?.type)?.label
                    }
                    units={areaUnits}></Input>
                  <div
                    onClick={() => setOpenNote(!openNote)}
                    className={cs(
                      'flex !aspect-square items-center justify-center rounded-lg bg-amber-600 p-[8px] px-[10px] shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]',
                      openNote && '!bg-amber-800'
                    )}>
                    <Icon icon="new-notev1" color="white" className="text-white"></Icon>
                  </div>
                </>
              )}
              <div
                onClick={() => setDrawing(!drawing)}
                className={cs(
                  'flex !aspect-square w-10 items-center justify-center rounded-lg bg-emerald-600 !p-[8px] shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]',
                  drawing && '!bg-emerald-800'
                )}>
                <Icon
                  icon={drawing ? 'new-close-circle' : 'new-edit'}
                  color="white"
                  className="text-white"></Icon>
              </div>
            </div>
            {openNote && !drawing && (
              <Textarea
                onChange={(e) =>
                  setPoints((points) => {
                    var newPoints = [...points];
                    newPoints[selected].note = e.target.value;
                    return newPoints;
                  })
                }
                value={points[selected].note || ''}
                placeholder="Add your note here..."
              />
            )}
          </div>
        </div>
      )}

      <div className="absolute bottom-0 right-0 z-10 mx-[28px] my-[24px] flex gap-2">
        <div
          onClick={() => zoom()}
          className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg bg-white shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]">
          <Icon className="cursor-pointer" icon="new-minus"></Icon>
        </div>

        <div
          onClick={() => zoom(true)}
          className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-lg bg-white shadow-[2px_5px_60px_0px_rgba(0,79,109,0.1)]">
          <Icon className="cursor-pointer" icon="new-plus"></Icon>
        </div>
      </div>
    </div>
  );
};

const Face = ({ context }) => {
  const { patient } = context;

  const { handleGetClinicalNote, products, type, procedures, setPatient } = useChart3dContext();

  useEffect(() => {
    setPatient(patient);
  }, []);

  const { openSidebar } = useOutletContext();

  useEffect(() => {
    handleGetClinicalNote();
  }, [products, procedures, type]);

  const { previewNoteModal } = useClinicalNoteContext();

  return (
    <>
      <Suspense
        fallback={
          <Skeleton count={1} height="100%" width="100%" background="#fff" highlight="#f8f8f8" />
        }>
        <div className="relative flex h-full w-full">
          <div
            className={cs(
              openSidebar
                ? '!w-[460px] sm:!w-[280px] md:!w-[300px] lg:!w-[340px]'
                : '!w-[650px] sm:!w-[350px] md:!w-[400px] lg:!w-[460px]',
              '!mr-3 flex flex-col gap-4 overflow-auto !pr-3'
            )}>
            <ProductsBar openSidebar={openSidebar} />
          </div>
          {!previewNoteModal ? <Canvas3D></Canvas3D> : <></>}
        </div>
      </Suspense>
    </>
  );
};

export default Face;
