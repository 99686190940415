import React from 'react';
import Configurations from '../../Payments/Configurations';

const PatientInvoicesContainer = () => {
  return (
    <div className="">
      <Configurations
        className="h-80vh"
        showOverrides={{
          cash: false,
          check: false,
          careCredit: false,
          cardPresent: false
        }}
      />
    </div>
  );
};

export default PatientInvoicesContainer;
