import { getInternalInvoiceByIds } from 'api/Billing';
import PrintView from 'components/Patient/quote/components/PrintView';
import InvoiceReadOnly from 'components/Payments/components/InvoiceReadOnly';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';

const InvoiceModal = ({ invoiceId, patient, isOpen, setIsOpen }) => {
  const navigate = useNavigate();
  const ref = useRef();
  const practice = useRecoilValue(currentPractice);
  const [data, setData] = useState();

  useEffect(() => {
    if (invoiceId) {
      getInvoiceData();
    }
  }, [invoiceId, navigate, patient.id]);

  const getInvoiceData = async () => {
    const data = await getInternalInvoiceByIds(navigate, {
      internal_invoice_ids: [invoiceId],
      user_id: patient?.id
    });
    setData(data);
  };

  const [invoice = {}] = data?.invoices || [];

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      slideFromRight
      isOpen={isOpen}
      bodyClassName="!p-0"
      title="Invoice"
      className="w-[800px]"
      headButton={
        <div className="flex w-full justify-end">
          <ReactToPrint
            trigger={() => (
              <Button
                color=""
                size="small"
                type="link"
                text="Print"
                icon="new-printer-bulk"
                className="text-primary-900"
              />
            )}
            content={() => ref?.current}
          />
        </div>
      }
      handleClose={handleClose}>
      <div ref={ref}>
        <PrintView
          patient={patient}
          practice={practice}
          createdAt={invoice?.dateofservice}
          content={<InvoiceReadOnly invoices={[invoice]} />}
        />
      </div>
    </Modal>
  );
};

export default InvoiceModal;
