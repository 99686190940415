import React from 'react';

const GetStarted = () => {
  return (
    <div className="py-[40px] text-center ">
      <h5 className="pb-3 text-[24px] font-500 text-primary-500">Let’s Get Started</h5>
      <p className="text-[16px] font-500 text-primary-900">
        Please select a title for your patient or clinical form.
      </p>
    </div>
  );
};

export default GetStarted;
