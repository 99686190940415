import React from 'react';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Switch from 'components/shared/Switch/Switch';
import Select from 'components/shared/Select/Select';
import { ia, randomString } from 'lib/helpers/utility';
import { genders, marital_status, races } from '../../../../constants';
const NewPatientForm = ({
  showModal,
  hideModal,
  formRef,
  formik,
  noEmail,
  setNoEmail,
  selectedSecondaryState,
  handleSubmitNewPatient,
  states,
  openSecondaryAddress,
  occupations,
  selectedState,
  handleSelectState,
  setOpenSecondaryAddress,
  selectedPractice,
  practices,
  handleSelectedPractice,
  handleSelectPractitioner,
  noPractice,
  selectedPractitioner,
  practitioners,
  checkPracticeId,
  handleInvitationEmail,
  invitationEmail
}) => {
  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title="Create new patient"
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between">
          <Button transparent onClick={hideModal} text="Cancel" color="neutral" outlined />
          <Button
            onClick={handleSubmitNewPatient}
            loadingIcon={formik.isSubmitting}
            text="Create"
            data-qa="create-patient-btn"
          />
        </div>
      }>
      <form ref={formRef}>
        <div className="groupColumns !pt-0 pr-3">
          <h3 className="groupColumns__title">Account information</h3>
          <div className="groupColumnsFour">
            <Input
              label="First name"
              placeholder="First name"
              name="f_name"
              data-qa="first-name"
              value={formik.values.f_name}
              onChange={formik.handleChange}
              id="firstName"
              error={formik.errors.f_name}
            />
            <div>
              <Input
                label="Middle name"
                placeholder="Middle name"
                name="m_name"
                data-qa="middle-name"
                value={formik.values.m_name}
                onChange={formik.handleChange}
                id="middleName"
                error={formik.errors.m_name}
              />
            </div>
            <div>
              <Input
                label="Last name"
                placeholder="Last name"
                name="l_name"
                data-qa="last-name"
                value={formik.values.l_name}
                onChange={formik.handleChange}
                id="lastName"
                error={formik.errors.l_name}
              />
            </div>
            <div className="grid gap-y-1">
              <Input
                label="Email"
                placeholder="email@example.com"
                name="email"
                data-qa="email"
                value={noEmail ? '' : formik.values.email}
                onChange={formik.handleChange}
                id="email"
                disabled={noEmail}
                error={formik.errors.email}
              />
              <Checkbox
                label="No email"
                name="no_email"
                data-qa="no-email"
                isChecked={noEmail}
                onChange={() => {
                  setNoEmail(!noEmail);
                  formik.setFieldValue(
                    'email',
                    !noEmail ? `autogen_${randomString(16)}@gen.myriad.health` : ''
                  );
                }}
              />
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Personal information</h3>
          <div className="groupColumnsFour">
            <div>
              <DatePopover
                label="Date of birth"
                labelClassName="w-max"
                placeholder="Date of birth"
                name="other_ins_dob"
                value={formik.values.dob}
                onChange={(event) => formik.setFieldValue('dob', event)}
              />
              {formik.errors.dob && <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.dob}</span>}
            </div>

            <Input
              label="Phone number"
              placeholder="+1(123)4567890"
              name="phone"
              value={formik.values.phone}
              onChange={formik.handleChange}
              id="phoneNumber"
              data-qa="phone-number"
              error={formik.errors.phone}
            />
            <div>
              <label className="block m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="gender">
                Gender
              </label>
              <Select
                placeholder="Type and select gender"
                value={formik.values.gender}
                onChange={(value) => formik.setFieldValue('gender', value)}
                options={genders}
                inputId="gender"
              />
              {formik.errors.gender && <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.gender}</span>}
            </div>
            <div>
              <label className="block m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="race">
                Race
              </label>
              <Select
                placeholder="Type and select race"
                value={formik.values.race}
                onChange={(value) => {
                  formik.setFieldValue('race', value);
                }}
                options={races}
                inputId="race"
              />
            </div>
            <div>
              <label
                className="block m-0 pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="maritalStatus">
                Marital Status
              </label>
              <Select
                placeholder="Type and select status"
                value={formik.values.marital_status}
                onChange={(value) => formik.setFieldValue('marital_status', value)}
                options={marital_status}
                inputId="maritalStatus"
              />
            </div>
            <Input
              label="SSN"
              placeholder="Social Security Number"
              name="ssn"
              value={formik.values.ssn}
              onChange={formik.handleChange}
              id="socialSecurityNumber"
              data-qa="social-security-number"
            />
            <Input
              label={`Driver's License`}
              placeholder="Driver's License"
              name="personal_id"
              value={formik.values.personal_id}
              onChange={formik.handleChange}
              id="driverLicense"
              data-qa="driver-license"
            />
            <div>
              <label
                className="block m-0 pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="maritalStatus">
                Occupation
              </label>
              <Select
                placeholder="Type and select status"
                value={formik.values.occupation_id}
                onChange={(value) => formik.setFieldValue('occupation_id', value)}
                options={
                  ia(occupations)
                    ? occupations.map((occupation) => ({
                      value: occupation.id,
                      label: occupation.name
                    }))
                    : []
                }
                inputId="occupation"
              />
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Physical address</h3>
          <div className="groupColumnsFour">
            <Input
              label="Address"
              placeholder="Address"
              name="address_ln_1"
              value={formik.values.address_ln_1}
              onChange={formik.handleChange}
              id="addressFirst"
              data-qa="address-first"
              error={formik.errors.address_ln_1}
            />
            <Input
              label="Address 2"
              placeholder="Address 2"
              name="address_ln_2"
              value={formik.values.address_ln_2}
              onChange={formik.handleChange}
              id="addressSecond"
              data-qa="address-second"
              error={formik.errors.address_ln_2}
            />

            <Input
              label="City"
              placeholder="City"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              id="city"
              data-qa="city"
              error={formik.errors.city}
            />
            <div>
              <label className="block m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="state">
                State
              </label>
              <Select
                placeholder="Type and select state"
                value={selectedState}
                onChange={(value) => handleSelectState(value)}
                options={
                  ia(states)
                    ? states.map((state) => ({
                      value: state.id,
                      label: state.name
                    }))
                    : []
                }
                inputId="state"
              />
              {formik.errors.state && <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.state}</span>}
            </div>
            <Input
              label="Zip code"
              placeholder="Zip"
              name="zip"
              value={formik.values.zip}
              onChange={formik.handleChange}
              id="zipCode"
              data-qa="zip-code"
              error={formik.errors.zip}
            />
          </div>
        </div>
        <div className="groupColumns">
          <div className="flex gap-4 items-center">
            <h3 className="text-lg font-500">Secondary Physical address</h3>
            <div className="flex gap-4">
              <Switch
                onChange={(value) => setOpenSecondaryAddress(value)}
                checked={openSecondaryAddress}
                data-qa="secondary-physical-address"
              />
            </div>
          </div>
          {openSecondaryAddress && (
            <div className="groupColumnsFour pt-2">
              <Input
                label="Address"
                placeholder="Address"
                name="secondary_address_ln_1"
                value={formik.values.secondary_address_ln_1}
                onChange={formik.handleChange}
                id="addressFirst"
                data-qa="address-first-2"
                error={formik.errors.secondary_address_ln_1}
              />
              <Input
                label="Address 2"
                placeholder="Address 2"
                name="secondary_address_ln_2"
                value={formik.values.secondary_address_ln_2}
                onChange={formik.handleChange}
                id="addressSecond"
                data-qa="address-second-2"
                error={formik.errors.secondary_address_ln_2}
              />

              <Input
                label="City"
                placeholder="City"
                name="secondary_city"
                value={formik.values.secondary_city}
                onChange={formik.handleChange}
                id="city"
                data-qa="city-2"
                error={formik.errors.secondary_city}
              />
              <div>
                <label className="block m-0 pb-[6px] text-sm font-500 text-neutral-800" htmlFor="state">
                  State
                </label>
                <Select
                  placeholder="Type and select state"
                  value={selectedSecondaryState}
                  onChange={(value) => handleSelectState(value, 'secondary_state')}
                  options={states}
                  inputId="state"
                />
                {formik.errors.secondary_secondary_state && (
                  <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.state}</span>
                )}
              </div>
              <Input
                label="Zip code"
                placeholder="Zip"
                name="secondary_zip"
                value={formik.values.secondary_zip}
                onChange={formik.handleChange}
                id="zipCode"
                data-qa="zip-code-2"
                error={formik.errors.secondary_zip}
              />
            </div>
          )}
        </div>
        <div className="groupColumns !pb-6">
          <div className="flex gap-8">
            {!!noPractice && (
              <div>
                <h3 className="groupColumns__title">Practices</h3>
                <label
                  className="block m-0 pb-[6px] text-sm font-500 text-neutral-800"
                  htmlFor="practice">
                  Practice
                </label>
                <Select
                  className="max-h-32"
                  placeholder="Type and select practice"
                  value={selectedPractice}
                  onChange={(value) => handleSelectedPractice(value)}
                  options={practices?.map((practice) => ({
                    value: practice.id,
                    label: `${practice.name}`
                  }))}
                  inputId="practice"
                  menuPlacement="top"
                />
              </div>
            )}
            <div>
              <h3 className="groupColumns__title">Practitioners</h3>
              <label
                className="block m-0 pb-[6px] text-sm font-500 text-neutral-800"
                htmlFor="practitioner">
                Practitioner
              </label>
              <div onClick={checkPracticeId}>
                <Select
                  placeholder="Type and select practitioner"
                  value={selectedPractitioner}
                  error={formik.errors.practitioner_id}
                  onChange={(value) => handleSelectPractitioner(value)}
                  options={practitioners?.map((practitioner) => ({
                    value: practitioner.id,
                    label: `${practitioner.f_name} ${practitioner.l_name}`
                  }))}
                  inputId="practitioner"
                  menuPlacement="top"
                />
              </div>
              {formik.errors.practitioner_id && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.practitioner_id}</span>
              )}
            </div>
            <div className="w-max">
              <h3 className="groupColumns__title notifications-title">Notifications</h3>
              <div className="flex gap-2 items-center">
                <Switch
                  onChange={(value) => handleInvitationEmail(value)}
                  checked={invitationEmail}
                  data-qa="send-invitation-email"
                />
                <label
                  className="font-500 m-0 p-0 text-sm text-neutral-800"
                  htmlFor="invitationEmail">
                  Send invitation email
                </label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
export default NewPatientForm;
