import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCards } from 'api/Cards';

export const useCards = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['cards', ...dependencies], () => getCards(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
