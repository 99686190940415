export function generatePracticeIQueueList() {
  return {
    overview: {
      name: 'Overview',
      path: 'overview',
      icon: 'new-graph'
    },
    tasks: {
      name: 'Tasks',
      path: 'tasks',
      icon: 'new-task',
      permission: 'tasks.read'
    },
    'clinical-notes': {
      name: 'Clinical Notes',
      path: 'clinical-notes',
      icon: 'new-notev2',
      permission: 'clinical_note.read'
    },
    notifications: {
      name: 'Notifications',
      path: 'notifications',
      icon: 'new-notifications'
    },
    appointments: {
      name: 'Appointments',
      path: 'appointments',
      icon: 'new-calendar',
      permission: 'appointments.read'
    },
    'form-packets': {
      name: 'Forms',
      path: 'forms',
      icon: 'new-calendar-note',
      permission: 'form_packet.read'
    }
  };
}
