import { useQuery } from '@tanstack/react-query';
import { getNdcCodes } from 'api/NdcCodes';
import { useNavigate } from 'react-router-dom';

export const useNdcCodes = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['ndc_codes', ...dependencies], () => getNdcCodes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
