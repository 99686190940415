import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getStates } from '../../../api/State';

export const useStates = (options) => {
  const navigate = useNavigate();
  return useQuery(['states'], () => getStates(navigate, { returnData: true, method: 'get' }), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
