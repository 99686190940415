import React from 'react';
import { iaRa, optionify } from 'lib/helpers/utility';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import CustomFormTypeSelect from './CustomFormType/CustomFormTypeSelect';

export default function Form({ onSubmit, formik, formFunctions = [] }) {

  const currentForm = () => {
    const result = iaRa(formFunctions).find((f) => f?.value == formik?.values?.type);
    if (!result?.value) return null;
    return result;
  };

  return (
    <form onSubmit={onSubmit} className="grid grid-cols-2 gap-2 w-1/2">
      <Input
        id="form-name"
        label="Form Name"
        name="name"
        value={formik.values.name}
        onChange={(value) => formik.setFieldValue('name', value.target.value)}
        error={formik.errors.name}
      />
      <CustomFormTypeSelect
        formik={formik}
        menuPortalTarget={document.body}
        currentForm={currentForm()}
        onSelectChange={(event) => formik.setFieldValue('type', event?.value)}
      />
    </form>
  );
}
