const handleChangePrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, total_amount_cents: price, amount_cents: price / s?.quantity };
      return s;
    })
  );
};
export default handleChangePrice;
