import React from 'react';
import { useUser } from '../../../../../../../lib/hooks/queries/user/useUser';
import ContactPreview from './components/ContactPreview';
import YourSignaturePreview from './components/YourSignaturePreview';
import NonERXPrescriptionAndFrequency from './components/NonERXPrescriptionAndFrequency';
import MedicationPreview from './components/MedicationPreview';
import { ia } from '../../../../../../../lib/helpers/utility';

const NonERXPrescriptionPreview = ({ itemProps }) => {
  const { data: currentUserInfo } = useUser({
    dependencies: [itemProps?.provider_id],
    params: { userIdentifier: itemProps?.provider_id },
    options: {
      enabled: !!itemProps?.provider_id,
      select: ({ user }) => user
    }
  });

  return (
    <div className="!mx-6">
      {!!currentUserInfo && (
        <div className="!mt-6">
          <ContactPreview providerInfo={currentUserInfo} />
        </div>
      )}

      {!!itemProps?.non_erx_prescription_and_frequency && (
        <div className="!mt-6">
          <NonERXPrescriptionAndFrequency itemProps={itemProps} />
        </div>
      )}

      {ia(itemProps?.medication_ids) && (
        <div className="!mt-6">
          <MedicationPreview itemProps={itemProps} />
        </div>
      )}

      <div className="!mt-6">
        <YourSignaturePreview itemProps={itemProps} />
      </div>
    </div>
  );
};

export default NonERXPrescriptionPreview;
