import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { ia, roundToNearestHalf, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import './ProgressBar.scss';
import { useFormikContext } from 'formik';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { completeAppointment } from 'api/Appointment';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/NewPopover';

const ProgressBar = ({ focusItem }) => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const { values, initialValues, setFieldValue } = useFormikContext();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const appointmentStatus =
    appointmentStatuses.find((item) => values.status === item.value)?.label || values.status;
  const formattedCurrStatus = spaceToKebabCase(appointmentStatus);
  const appointmentCompleted = values.completed;

  useEffect(() => {
    if (values.status === 'completed' && initialValues.status !== 'completed') {
      setConfirmationModalVisible(true);
    }
  }, [values.status]);

  const handleCompleteAppointment = async () => {
    const { code } = await completeAppointment(navigate, {
      id: values.id,
      completed: !appointmentCompleted
    });
    if (code === 0) {
      setConfirmationModalVisible(false);
      await queryClient.invalidateQueries(['appointment', values?.id]); // TEMPORARY: this will invalidate the query for old appointment preview
      await queryClient.invalidateQueries(['appointmentv2', values.id]);
      await queryClient.invalidateQueries(['appointment-events']);
      await queryClient.invalidateQueries(['apt-timeline-details']);
    }
  };

  const handleClose = () => {
    setConfirmationModalVisible(false);
    setFieldValue('status', initialValues.status);
  };

  if (!ia(values.timeline_bar)) return null;

  return (
    <div className="relative ml-auto flex items-center gap-3 sm:order-3 sm:mx-auto md:order-3 md:mx-auto lg:pr-9 xl:pr-9">
      <span className="relative flex h-[28px] items-center gap-[10px] rounded-2xl bg-white px-[10px] py-2">
        <span
          className="z-20 max-w-[124px] truncate bg-white text-sm font-500 capitalize leading-[22px] text-primary-600"
          title={appointmentStatus}>
          {appointmentStatus}
        </span>
        <div className="relative flex h-[16px] w-[16px] items-center justify-center rounded-full bg-primary-600">
          <div className="z-20 h-[10px] w-[10px] rounded-full bg-white" />
        </div>
      </span>
      <div className="relative flex items-center">
        {values.timeline_bar.map((status, index) => (
          <React.Fragment key={status.name}>
            <Tippy content={snakeToTitleCase(status?.label)} placement="top" className="tippy-dark">
              <button
                className={cs(
                  'relative z-10 mr-[1px] flex h-[10px] w-[46px] cursor-pointer items-center justify-center border-2 border-solid border-transparent duration-150 first-of-type:rounded-l-md last-of-type:mr-0 last-of-type:rounded-r-md hover:rounded-[1px]',
                  `bg-${status.color}-400`,
                  `hover:border-${status.color}-700`
                )}
                onClick={() => focusItem(status.name)}>
                {status.status_options.find(
                  (option) => spaceToKebabCase(option) === formattedCurrStatus
                ) && (
                  <div className="pointer-events-none relative flex h-[16px] w-[16px] items-center justify-center rounded-full bg-white shadow-[0px_2px_8px_rgba(0,0,0,0.2)]">
                    <div className="h-[10px] w-[10px] rounded-full bg-primary-600" />
                    <div
                      className="absolute right-full top-1/2 z-30 -translate-y-1/2 border-0 border-t-2 border-dashed border-primary-600"
                      style={{ width: (index + 1) * 48 }}
                    />
                  </div>
                )}
              </button>
            </Tippy>
            {(index + 1) % 2 === 0 && index !== values.timeline_bar.length - 1 && (
              <div className="-ml-[1px] mb-[2px] h-[14px] w-[3px] rounded-[2px] bg-white shadow-[0px_-2px_8px_rgba(0,0,0,0.16)]" />
            )}
          </React.Fragment>
        ))}
      </div>

      <Popover
        isFixed
        isDropdown
        position="left"
        text={`${roundToNearestHalf(values?.statusPercentage)}% Completed`}
        icon={false}
        options={[
          appointmentCompleted
            ? {
                label: 'Incomplete',
                value: 'incomplete',
                property_of: 'Update Appointment status to:',
                color: 'danger',
                icon: 'new-close-v2',
                size: 20,
                stroke: true,
                onClick: () => setConfirmationModalVisible(true)
              }
            : {
                label: 'Complete',
                value: 'completed',
                color: 'success',
                property_of: 'Update Appointment status to:',
                icon: 'new-check',
                stroke: true,
                onClick: () => setConfirmationModalVisible(true)
              }
        ]}
        optionsGroupBy="property_of"
        buttonTheme="white"
        buttonClassName="py-[2px] !px-[10px] !min-h-0 !rounded-full"
        panelClassName="max-h-[300px] xl:max-h-[500px] z-[100]"
      />
      <Confirm
        handleOpen={!!confirmationModalVisible}
        handleClose={handleClose}
        handleContinue={handleCompleteAppointment}
        title={appointmentCompleted ? 'Incomplete Appointment?' : 'Complete Appointment?'}
        icon="calendar-tick"
        message={
          appointmentCompleted
            ? 'Are you sure you want to mark these steps incomplete and the appointment unfinished? There are multiple steps that are still pending, and if you leave the appointment incomplete, the steps on the progress bar will remain active, showing their true progress without any manual overrides!'
            : 'Are you sure you want to mark these steps completed and appointment completed? There are multiple steps that are not finished and if you complete the appointment all the steps on the progress bar will be marked grey as they were manually overridden!'
        }
        variant="primary"
      />
    </div>
  );
};

export default withErrorBoundary(ProgressBar);
