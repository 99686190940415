import React, { useRef } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useReactToPrint } from 'react-to-print';

import { imagePath } from 'lib/helpers/utility';

import useDocumentActions from 'components/shared/DocumentEditor/lib/useDocumentActions';
import Popover from 'components/shared/Popovers/Popover/Popover';

import SelectInstantAppointment from '../InstantPacket/components/SelectInstantAppointment';

const DocumentActionsPopover = ({
  setViewDocument,
  isEditOpen,
  setIsEditOpen,
  patientId,
  cat,
  form,
  fromPatient,
  data,
  setDownloadLoading,
  setSelectedAppointment,
  handleConfirmationFile,
  practice,
  appointmentId,
  isLocked
}) => {
  const hasPermissions = !fromPatient || data?.patient_id === data?.uploader_id || appointmentId;

  const { handleDownload } = useDocumentActions({
    data,
    file: imagePath(data?.document_handle),
    patientId
  });

  const exportRef = useRef([]);

  const print = useReactToPrint({
    content: () => exportRef.current,
    documentTitle: `${data?.name}-${data.created_at}.pdf`
  });

  const downloadDocument = async () => {
    setDownloadLoading(true);
    if (data?.form_id) {
      await print();
    } else {
      await handleDownload();
    }
    setDownloadLoading(false);
  };

  const deleteData = {
    mode: 'delete',
    id: data?.document_handle || data?.id,
    document_handle: data?.document_handle,
    form_id: data?.form_id,
    name: data?.name,
    appointment_id: data?.appointment_id,
    type: data?.mimetype || 'form',
    category: cat
  };

  const defaultData = {
    load: true,
    title: data?.name,
    handle: data?.document_handle || form,
    type: data.category === 'efax' ? 'fax' : data?.mimetype || 'form',
    id: data?.id,
    category: cat,
    appointment_id: data?.appointment_id,
    patient_id: data?.patient_id,
    uploader_id: data?.uploader_id
  };

  const options = [
    {
      label: data?.form_id || isLocked || !hasPermissions ? 'View' : 'View & Edit',
      onClick: () => {
        setViewDocument(defaultData);
      },
      icon: 'new-eye'
    },
    data?.form_id &&
      hasPermissions && {
        label: 'Edit',
        onClick: () => {
          setIsEditOpen(true);
          setViewDocument({
            ...defaultData,
            action: 'Edit'
          });
        },
        icon: 'new-edit'
      },
    hasPermissions &&
      !isLocked &&
      (appointmentId
        ? {
            label: 'Delete',
            icon: 'trash',
            onClick: () => {},
            children: [
              {
                label: 'Remove from note',
                onClick: () =>
                  handleConfirmationFile({
                    ...deleteData
                  }),
                icon: 'trash'
              },
              {
                label: 'Delete all instances',
                onClick: () => handleConfirmationFile({ ...deleteData, deleteInstances: true }),
                icon: 'trash'
              }
            ]
          }
        : {
            label: 'Delete',
            onClick: () => {
              handleConfirmationFile({
                mode: 'delete',
                id: data?.document_handle || data?.id,
                type: data?.form_id ? 'form' : 'filestack',
                name: data?.name,
                appointment_id: data?.appointment_id
              });
            },
            icon: 'trash'
          }),

    !isEditOpen && {
      label: 'Download',
      onClick: downloadDocument,
      icon: 'new-chevron-down'
    },

    !fromPatient
      ? data?.appointment_id
        ? {
            label: 'Linked DOS',
            icon: 'new-link',
            color: 'primary',
            stroke: true,
            children: [
              {
                component: (
                  <SelectInstantAppointment
                    selectedAppointment={data?.appointment_id}
                    setSelectedAppointment={async (appointment) => {
                      await setSelectedAppointment(appointment, data);
                    }}
                    patientId={patientId}
                    practice={practice}
                    update
                    buttonClassName="hover:bg-primary-50 w-36"
                    popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-30%]"
                  />
                )
              },
              {
                label: 'Unlink DOS',
                icon: 'new-close-v2',
                color: 'primary',
                stroke: true,
                onClick: async () => {
                  await setSelectedAppointment(null, data);
                }
              }
            ]
          }
        : {
            component: (
              <SelectInstantAppointment
                selectedAppointment={data?.appointment_id}
                setSelectedAppointment={async (appointment) => {
                  await setSelectedAppointment(appointment, data);
                }}
                patientId={patientId}
                practice={practice}
                popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-90%]"
              />
            )
          }
      : null,
    !fromPatient &&
      data?.uploader_id !== patientId && {
        label: data?.myportal ? 'Unshare document' : 'Share with patient',
        icon: 'new-arrow-swap-down',
        onClick: () => {
          handleConfirmationFile({
            mode: data?.myportal ? 'unshare' : 'share',
            id: data?.id,
            type: data?.form_id ? 'form' : 'document'
          });
        }
      }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {data?.form_id && !isEditOpen && (
        <div
          className="PrintForm print:p-8 print:!bg-white"
          ref={exportRef}
          style={{ display: 'none' }}>
          <ReactFormGenerator
            answer_data={form?.json?.fields ? JSON.parse(form?.json?.fields) : {}}
            data={form?.form_json?.fields}
            submitButton={<div />}
            read_only={true}
          />
        </div>
      )}
    </>
  );
};

export default DocumentActionsPopover;
