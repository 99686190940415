import React from 'react';

import { useRecoilValue } from 'recoil';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { ia, mString } from 'lib/helpers/utility';

import { calcTotalAdjustment } from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA&EOBHelpers';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Loading from 'components/shared/Loading/Loading';
import Typography from 'components/shared/Typography/Typography';

import MatchedClaims from './MatchedClaims';
import UnMatchedClaimsRows from './UnMatchedClaims';

const Claim = () => {
  const { era, setAllocateEob, isEraLoading } = useEraContext();

  const currPractice = useRecoilValue(currentPractice);

  if (isEraLoading) return <Loading />;

  return (
    <>
      <div className="mb-6 flex justify-between">
        <div className="flex flex-col">
          <Typography variant="p" weight="medium" color="text-primary-900">
            {era?.payer?.name}
          </Typography>

          <Typography variant="p" size="small" color="text-primary-800">
            {era?.payer?.payerAddress?.fullAddress}
          </Typography>
        </div>
        <div className="flex flex-col text-center">
          <Typography variant="p" weight="medium" color="text-primary-900">
            Total Paid:{' '}
            {era?.paid_amount >= 0 ? mString(era?.paid_amount) : mString(era?.total_paid)}
          </Typography>
          <Typography variant="p" size="small" color="text-primary-800">
            Total Adjustment: {calcTotalAdjustment(era?.claims, era?.unmatched_claims)}
          </Typography>
        </div>
        <div className="flex flex-col text-end">
          <Typography variant="p" weight="medium" color="text-primary-900">
            {currPractice?.name}
          </Typography>
          <Typography variant="p" size="small" color="text-primary-800">
            {currPractice?.address}
          </Typography>
        </div>
      </div>
      {era?.type === 'eob' && (
        <div className="flex justify-between">
          <Typography variant="p" weight="medium" color="text-primary-900">
            Unallocated Amount: {mString(era?.unallocated_amount_cents)}
          </Typography>
          <Button text="Allocate" onClick={() => setAllocateEob(true)} />
        </div>
      )}
      {ia(era?.unmatched_claims) && (
        <>
          <Typography variant="p" size="large" weight="medium" color="text-primary-900">
            Not Posted
          </Typography>
          <UnMatchedClaimsRows />
        </>
      )}
      {ia(era?.claims) && (
        <>
          <Typography variant="p" size="large" weight="medium" color="text-primary-900">
            Posted
          </Typography>
          <MatchedClaims />
        </>
      )}
    </>
  );
};

export default Claim;
