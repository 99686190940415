export const UNIT_OPTIONS = [
  { label: 'Miligrams (mg)', value: 'mg' },
  { label: 'Milliliters (ml)', value: 'ml' },
  { label: 'Grams (g)', value: 'g' },
  { label: 'Drops', value: 'drops' },
  { label: 'Sprays', value: 'sprays' },
  { label: 'Capsules', value: 'capsules' },
  { label: 'Tablets', value: 'tablets' },
  { label: 'Teaspoons', value: 'teaspoons' },
  { label: 'Inhalations', value: 'inhalations' },
  { label: 'International Unit (IU)', value: 'IU' }
];

export const MEDICATION_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DELETED: 'deleted'
};

export const FINAL_NOTE_OPTIONS = [
  { label: "Today's data only", value: 'TodaysData' },
  { label: 'Template', value: 'Template' },
  { label: 'Narrative', value: 'Narrative' }
];

export const CONFIRMATION_MODAL = {
  open: false,
  type: null
};

export const ALLERGIES_ACTION_TYPES = {
  DELETE: 'delete',
  UPDATE_STATUS: 'updateStatus'
};
