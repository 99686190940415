import React from 'react';
import { useFormikContext } from 'formik';
import { ia } from 'lib/helpers/utility';
import TagsView from 'components/shared/Tags/components/View/View';
import _ from 'lodash';

const Tags = () => {
  const { values } = useFormikContext();
  const patientTagLink = values?.patient?.tagLinks;
  const orderTags = _.orderBy(patientTagLink, (tag) => tag?.position, 'asc');
  const tags = orderTags?.map((tagLink) => tagLink?.tag).filter(Boolean);

  if (!ia(patientTagLink)) return null;

  return (
    <div className="flex items-center gap-2">
      <span className="text-sm leading-4 text-neutral-600">Chart Tags:</span>
      <TagsView
        currTags={tags}
        label={false}
        itemsToShow={3}
        fromAppointmentPreview
        disableDeviceCheck
      />
    </div>
  );
};

export default Tags;
