import moment from 'moment';

export default function getRangeBasedOnView(view, defaultDate) {
  switch (view) {
    case 'week':
      return {
        startDate: moment(defaultDate).startOf('isoWeek').toDate(),
        endDate: moment(defaultDate).endOf('isoWeek').toDate()
      };

    case 'month':
      return {
        startDate: moment(defaultDate).startOf('month').toDate(),

        endDate: moment(defaultDate).endOf('month').toDate()
      };
    default:
      return {
        startDate: moment(defaultDate).startOf('day').toDate(),
        endDate: moment(defaultDate).endOf('day').toDate()
      };
  }
}
