import { getProviders } from 'api/Member';
import { getPatients } from 'api/Patients';

import { formatDate, formatDateAndTimeZ, ia, stripHtmlTags } from 'lib/helpers/utility';

import OrdersActionsCellRenderer from '../../../../practice/charts/Overview/widgets/components/OrdersActionsCellRenderer';

export const getDefaultFilters = (isAdmin) => ({
  type: {
    title: 'Type',
    placeholder: 'Search order type',
    type: 'search',
    multiple: true,
    options: [
      isAdmin ? { label: 'eLab Order', value: 'elab_order' } : null,
      { label: 'Lab Order', value: 'lab_order' },
      { label: 'Referral Order', value: 'referral_order' },
      { label: 'Non ERX Prescription', value: 'non_erx_prescription' }
    ].filter(Boolean)
  },
  provider: {
    type: 'infinite-scroll',
    multiple: true,
    queryFn: getProviders,
    queryKey: 'practitioners',
    values: [],
    title: 'Provider',
    placeholder: 'Search provider'
  },
  patient: {
    type: 'infinite-scroll',
    values: [],
    queryFn: getPatients,
    queryKey: 'patients',
    multiple: true,
    title: 'Patient',
    placeholder: 'Search patient',
    formatData: (data) => data.map((patient) => ({ label: patient?.fullName, value: patient?.id }))
  },

  order_date: {
    values: null,
    type: 'date-range',
    title: 'Order Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  result_date: {
    values: null,
    type: 'date-range',
    title: 'Result Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  }
});

export const getCols = ({
  openEditModal,
  openPreviewModal,
  openDeleteModal,
  timezone,
  setActiveDocument
}) => [
  { field: 'type', headerName: 'Type', flex: 1 },
  {
    field: 'provider_name',
    headerName: 'Provider Name',
    flex: 1
  },
  {
    field: 'patient_name',
    headerName: 'Patient Name',
    flex: 1
  },
  {
    field: 'created_at',
    headerName: 'Order Date',
    flex: 1,
    valueFormatter: ({ value }) => (value ? formatDateAndTimeZ(value, timezone) : '-')
  },
  {
    field: 'result_date',
    headerName: 'Result Date',
    flex: 1,
    valueFormatter: ({ value }) => (value ? formatDateAndTimeZ(value, timezone) : '-')
  },
  {
    field: 'additional_info',
    headerName: 'Details',
    flex: 2,
    cellRenderer: ({ data, value }) => {
      if (!value || !data.rawType) return '-';

      if (data.rawType === 'non_erx_prescription') {
        return stripHtmlTags(value);
      } else if (data.rawType === 'referral_order') {
        return value;
      } else if (data.rawType === 'lab_order' || data.rawType === 'elab_order') {
        return ia(value) ? value.map((d) => d.label).join(', ') : '-';
      }
    }
  },
  {
    field: 'actions',
    width: 100,
    headerName: 'Actions',
    cellRenderer: (props) => (
      <OrdersActionsCellRenderer
        data={props.data}
        openEditModal={openEditModal}
        openPreviewModal={openPreviewModal}
        openDeleteModal={openDeleteModal}
        setActiveDocument={setActiveDocument}
      />
    )
  }
];
