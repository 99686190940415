import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import Tabs from 'components/shared/Tabs/NewTabs';
import React, { useState } from 'react';
import ClaimMD from '../ClaimMD';
import NonPrime from '../Eligibility/NonPrime';
import Prime from '../Eligibility/Prime';

const ClaimMDWidget = (props, { dashboardData }) => {
  const item = { name: 'Claim MD' };
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Claim MD',
      content: <ClaimMD data={dashboardData} />
    },
    {
      label: 'Eligibility Prime',
      content: <Prime data={dashboardData} />
    },
    {
      label: 'Eligibility Non Prime',
      content: <NonPrime data={dashboardData} />
    }
  ];

  return (
    <div>
      <WidgetHeader icon="new-notev2" item={item} hasPlus={false} {...props} />
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
      />
    </div>
  );
};

export default ClaimMDWidget;
