import { requestApi } from 'api/Api';

export const createAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/create', params, navigate });
};

export const listAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/list', params, navigate });
};

export const updateAiModel = (navigate, params) => {
  return requestApi({ url: '/api/admin/intelligence/models/update', params, navigate });
};
