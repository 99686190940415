import React from 'react';
import RadioButton from '../../../../shared/RadioButton/RadioButton';
import { ia } from '../../../../../lib/helpers/utility';
import { appointmentLocations } from '../../../../../constants';
import Input from '../../../../shared/Input/Input';
import { currentPractice } from '../../../practiceState';
import { useRecoilValue } from 'recoil';

const Location = ({ formik, practitioners, className }) => {
  const currPractice = useRecoilValue(currentPractice);

  const handleChangeVirtualLink = () => {
    const patientLink = formik.values.patient?.practicePatientLink?.virtual_link;
    const practitionersLink = practitioners.find(
      (practitioner) => practitioner?.value === formik?.values?.practitioner_id
    )?.virtual_link;
    const practice_virtual_link = currPractice?.display_settings?.appointment?.virtual_link;

    formik.setFieldValue('virtual_link', patientLink || practitionersLink || practice_virtual_link);
  };

  return (
    <div className="flex gap-4" data-dd-privacy="allow">
      <div className={className}>
        <label className="text-sm font-500">Location</label>
        <div className="!mt-2 flex gap-4" data-dd-privacy="allow">
          {ia(appointmentLocations) &&
            appointmentLocations.map(({ value, label }, i) => {
              return (
                <RadioButton
                  className="w-auto !pl-8 !pr-0"
                  wrapperClassName="!w-auto"
                  key={i}
                  id={value}
                  value={value}
                  label={label}
                  isSelected={value === formik.values.location}
                  onChange={() => {
                    formik.setFieldValue('location', value);
                    if (value === 'virtual') {
                      handleChangeVirtualLink();
                    }
                  }}
                />
              );
            })}
        </div>
      </div>
      {formik.values.location == 'virtual' && (
        <Input
          className="grow"
          label="Meeting link"
          value={formik.values.virtual_link}
          onChange={(e) => {
            formik.handleChange(e);
          }}
          name="virtual_link"
          placeholder="Enter meeting link"
        />
      )}
    </div>
  );
};

export default Location;
