import React from 'react';
import ReactSkeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const Skeleton = ({
  baseColor = '#EEEEEE',
  highlight = '#FAFAFA',
  height = '52px',
  count = 1,
  containerClassName,
  containerStyle,
  ...rest
}) => {
  return (
    <SkeletonTheme highlightColor={highlight} baseColor={baseColor}>
      <ReactSkeleton
        className="skeleton"
        height={height}
        count={count}
        containerClassName={containerClassName}
        containerStyle={containerStyle}
        {...rest}
      />
    </SkeletonTheme>
  );
};

export default Skeleton;
