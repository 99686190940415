import { capitalize } from 'lodash';
import { formatDateAndTime, ia, iaRa, snakeToTitleCase } from '../../../../../lib/helpers/utility';
import { isMoment } from 'moment';
import { textBoldAndUnderline, textItalic } from './markdownHelpers';

export default function formatMedicationsNarrative(medications) {
  if (!ia(medications)) {
    return textItalic({
      text: `No notable medications`
    })?.trim();
  }

  console.log({ medications });
  let formattedData = textItalic({ text: 'Provider reports: \n\n' });
  iaRa(medications).forEach((medication, index) => {
    const {
      practice_id,
      patient_id,
      appointment_id,
      created_by,
      id,
      ndc_product_id,
      veradigm_id,
      veradigm_pharmacy_id,
      current_medication_narrative,
      status,
      product_ndc,
      product_ndc_id,
      created_at,
      updated_at,
      ...medicationData
    } = medication;

    console.log({ medicationData });

    Object.entries(medicationData).forEach(([key, value]) => {
      if (value) {
        formattedData += `${textBoldAndUnderline({ text: snakeToTitleCase(key) })}: `;

        if (value && typeof value === 'object') {
          if (isMoment(value)) {
            formattedData += `${formatDateAndTime(value, null, true)}\n`;
          } else if (ia(value)) {
            formattedData += `${value
              .map((it) => capitalize(it.value) || capitalize(it))
              .join(', ')}\n`;
          } else {
            Object.entries(value).forEach(([subKey, subValue]) => {
              if (subKey === 'value') {
                formattedData += ` ${subValue}\n`;
              }
            });
          }
        } else {
          formattedData += `${value}\n`;
        }
      }
    });

    formattedData += '\n';
  });
  return formattedData;
}
