import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { requestApi } from 'api/Api';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';

export const AllocateAction = ({ data, rowIndex }) => {
  const { id } = data || {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleUnAllocate = async () => {
    setLoading(true);

    let params = { transactionId: id };

    await requestApi({
      url: '/api/practice/patient/balance/unallocate',
      params,
      navigate,
      onSuccess: async () => {
        showAlert({
          title: 'Success',
          message: 'Amount unallocated successfully!',
          color: 'success'
        });

        setShowConfirmationModal(false);
        await Promise.all([
          queryClient.refetchQueries({ queryKey: ['patientInvoices'] }),
          queryClient.refetchQueries({ queryKey: ['allocated'] }),
          queryClient.refetchQueries({ queryKey: ['unallocated'] })
        ]);
      }
    });
    setLoading(false);
  };

  const onUnAllocateClick = () => {
    setShowConfirmationModal(true);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Button
        data-qa={`unallocate-${rowIndex}`}
        size="small"
        type="secondary"
        text="Unallocate"
        onClick={onUnAllocateClick}
      />
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          loading={loading}
          primaryBtnTxt="Unallocate"
          title="Unallocate transaction"
          handleContinue={handleUnAllocate}
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          message="Do you want to unallocate this transaction?"
        />
      )}
    </div>
  );
};
