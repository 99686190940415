import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import { usePrompts } from 'lib/hooks/queries/myscribeAI/usePrompts';

import { promptListState } from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import { clinicalNote as clinicalNoteState } from 'components/state';

import { MyScribeTemplateContextApi, MyScribeTemplateContextData } from './MyScribeTemplateContext';

export const MyScribeTemplateContextProvider = ({ children }) => {
  const [localPrompts, setLocalPrompts] = useRecoilState(promptListState);

  const { appointmentId } = useParams();

  const clinicalNote = useRecoilValue(clinicalNoteState);

  const {
    data: promptData,
    isFetching,
    isLoading
  } = usePrompts({
    params: {
      clinicalNoteId: clinicalNote?.id,
      appointmentId
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id
    }
  });
  let isGetPromptsLoading = isFetching || isLoading;
  const prompts = promptData?.conversations;

  if (!clinicalNote?.id) {
    isGetPromptsLoading = false;
  }

  useEffect(() => {
    setLocalPrompts(prompts || []);
  }, [prompts]);

  const data = useMemo(
    () => ({
      localPrompts,
      isGetPromptsLoading
    }),
    [isGetPromptsLoading, localPrompts]
  );

  const api = useMemo(() => ({ setLocalPrompts }), [setLocalPrompts]);

  return (
    <MyScribeTemplateContextData.Provider value={data}>
      <MyScribeTemplateContextApi.Provider value={api}>
        {children}
      </MyScribeTemplateContextApi.Provider>
    </MyScribeTemplateContextData.Provider>
  );
};
