import React, { useState, useEffect, useRef } from 'react';
import Button from '../Buttons/Button';
import cs from 'classnames';

const AIChatInput = ({
  leftButtons,
  rightButtons,
  placeholder = 'Type your note...',
  className,
  inputClassName,
  disabled = false,
  onSubmit = () => {},
  onGoBack = () => {},
  onTextChange = () => {},
  value,
  setValue = () => {},
  tags = null,
  onTagRemove = () => {},
  onTagPreview = () => {},
  getTagClassName = () => {},
  isInput = true
}) => {
  const [inputHeight, setInputHeight] = useState('auto');
  const textareaRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (tags?.length > 0) {
      inputRef.current.focus();
    }
  }, [tags?.length]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      setInputHeight(`${textareaRef.current.scrollHeight}px`);
    }
  }, [value]);

  const handleInputChange = (event) => {
    setValue(event.target.value);
    onTextChange(event);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmit(value);
      setValue('');
    }
  };

  const handleLastInputKeyPress = (event) => {
    if (event.key !== 'Backspace') {
      event.preventDefault();
    }
    if (event.key === 'Backspace') {
      onTagRemove();
    }
  };

  // Focus textarea when container is clicked
  const handleContainerClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  return (
    <div
      className={cs(
        'flex w-full cursor-pointer flex-row items-end rounded-lg border border-gray-300 bg-[#FFF] px-3 pt-3 shadow-2xl',
        className
      )}
      onClick={handleContainerClick} // Added click handler
    >
      <div className="flex items-end">
        {leftButtons || (
          <Button
            key="1"
            text="Back"
            outlined
            transparent
            size="small"
            className="!py-2 !pl-4 !pr-4"
            onClick={onGoBack}
          />
        )}
      </div>

      {/* Main input container */}
      <div className="flex-grow justify-end px-2 py-0">
        {isInput && (
          <>
            <textarea
              className={cs(
                'mb-2 h-full max-h-[200px] w-full flex-grow resize-none overflow-y-auto border-none bg-transparent focus:outline-none',
                inputClassName
              )}
              ref={textareaRef}
              placeholder={placeholder}
              value={value}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              style={{ height: inputHeight }}
              rows={1}
            />
            {tags?.length > 0 && (
              <div className="flex flex-wrap items-center">
                <p className="my-1 text-sm">Add</p>
                {tags.map((tag) => (
                  <span
                    onClick={() => onTagPreview(tag.id)}
                    key={tag.id}
                    className={cs(
                      'my-1 ml-1 cursor-pointer rounded bg-primary-50 p-1 px-2 text-sm text-primary-700',
                      getTagClassName(tag.id)
                    )}>
                    #{tag?.text}
                  </span>
                ))}
                <input
                  ref={inputRef}
                  className="ml-1 mt-1 h-9 w-5 cursor-pointer border-0 focus:outline-none"
                  onKeyDown={handleLastInputKeyPress}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className="flex items-end pb-1">
        {rightButtons || (
          <Button
            key={1}
            text="Generate"
            icon="stars-ai"
            iconSize={18}
            iconColor="white"
            size="small"
            className={cs('!py-2 !pl-4 !pr-4', disabled ? 'bg-primary-100' : '')}
            onClick={onSubmit}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default AIChatInput;
