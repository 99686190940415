import { Honeybadger } from '@honeybadger-io/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  generateNarrative,
  handleOnChange,
  hasFormData
} from 'components/practice/charts/ClinicalNote/lib/advancedFormsHelpers';
import { AlertContent } from 'components/shared/Alert/Alert';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { upsertResponse } from '../../../../../../../../api/CustomForms';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io, isEmpty, responseUpdated } from '../../../../../../../../lib/helpers/utility';
import { useCustomFormsById } from '../../../../../../../../lib/hooks/queries/customForms/useCustomFormsById';
import Button from '../../../../../../../shared/Buttons/Button';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import Accordion from '../../../../shared/Accordion';
import '../../HPNote/components/AdvancedForms.scss';
import CustomFormNarrative from '../../HPNote/components/CustomFormNarrative';

const AdvancedSoap = ({ currAdvForm = {} }) => {
  const [currentForm, setCurrentForm] = useState(null);
  const [oldForm, setOldForm] = useState(null);
  const {
    clinicalNote,
    advancedSOAPRef,
    setPatientResponseSidebar,
    setSaveButtonText,
    updateSaveButton,
    forceSave,
    applyForceSave
  } = useClinicalNoteContext();
  const { id, appointmentId } = useParams();
  const sectionRef = useRef();
  const [formUpdated, setFormUpdated] = useState(false);
  const [narrativeUpdated, setNarrativeUpdated] = useState(false);
  const [syncON, setSyncON] = useState(true);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const queryKeys = [
    'customFormsById',
    currAdvForm.form_id || currAdvForm.id,
    currAdvForm?.created_at || currAdvForm?.updated_at,
    appointmentId,
    id
  ];

  const { data, isLoading, isFetching } = useCustomFormsById({
    dependencies: [
      currAdvForm.form_id || currAdvForm.id,
      currAdvForm?.created_at || currAdvForm?.updated_at,
      appointmentId,
      id
    ],
    params: {
      formId: currAdvForm.form_id || currAdvForm.id,
      appointmentId,
      patientId: id,
      patientResponses: false
    },
    options: {
      enabled: !!currAdvForm?.id,
      refetchOnMount: true
    }
  });

  useEffect(() => {
    if (data?.data?.formResponse) {
      updateForm(currAdvForm?.is_macro ? currAdvForm : data?.data?.formResponse);
      delete currAdvForm?.is_macro;
    }
  }, [data]);

  useEffect(() => {
    if (currAdvForm?.id) {
      setPatientResponseSidebar((prevState) => ({ ...prevState, formId: currAdvForm?.id }));
    }
  }, [currAdvForm?.id]);

  const currentAdvancedFormId = currAdvForm?.form_id || currAdvForm?.form?.id || currAdvForm?.id;
  useEffect(() => {
    if (currentAdvancedFormId) {
      setPatientResponseSidebar((prevState) => ({ ...prevState, formId: currentAdvancedFormId }));
    }
  }, [currentAdvancedFormId]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const shouldGenerateNarrativeInitially =
      currentForm?.form_id && !currAdvForm?.form_id && isEmpty(currentForm?.narrative);

    if (shouldGenerateNarrativeInitially) {
      generateNarrative({ currentForm, setCurrentForm });
    }
  }, [currentForm?.form_id]);

  useImperativeHandle(sectionRef, () => ({
    formData: { advancedSOAP: currentForm },
    patientResponse: () =>
      setPatientResponseSidebar((prevState) => ({
        ...prevState,
        open: !prevState.open
      }))
  }));

  const submitMutation = useMutation({
    mutationFn: (data) => upsertResponse(navigate, data),
    onMutate: () => {
      setSaveButtonText('Saving');
    },
    onSettled: () => {
      updateSaveButton();
    },
    onSuccess: (data) => {
      const { upsert } = data || {};
      if (upsert) {
        setFormUpdated(false);
        setNarrativeUpdated(false);
        updateForm(upsert);

        const currentData = queryClient.getQueryData(queryKeys);

        const newData = {
          ...currentData,
          data: { ...currentData.data, formResponse: { ...upsert } }
        };

        queryClient.setQueryData(queryKeys, newData);
      } else {
        toast.error('Necessary permissions not present or error encountered');
      }
    }
  });

  const updateForm = (data) => {
    setCurrentForm(data);
    setOldForm(data);
  };

  const handleFormSubmit = async (e, responseId, formId, formVersion) => {
    try {
      // Some of the fields don't get a name and custom_name, which leads to the form generator crashing. Adding these two attributes in order to prevent that
      const updatedResponses = e?.map((response) => {
        return response.name
          ? { ...response }
          : { ...response, name: 'no_name', custom_name: 'no_custom_name' };
      });

      if (forceSave?.advancedForm) {
        applyForceSave({ type: 'advancedForm', value: false });
      } else {
        if (!formUpdated && !narrativeUpdated) return;

        if (formUpdated) {
          const hasFormChanged = !responseUpdated({
            currentForm: oldForm,
            submitResponse: updatedResponses
          });

          if (hasFormChanged) {
            return;
          }
        }
      }

      let params = {
        responseId,
        formId,
        json: { fields: JSON.stringify(updatedResponses) },
        appointmentId,
        patientId: id,
        formVersion,
        isNotClinicalNote: false,
        narrative: currentForm?.narrative,
        simple_narrative: currentForm?.simple_narrative
      };

      submitMutation.mutate(params);
    } catch (err) {
      Honeybadger.notify(`Advanced soap, error: ${err}`);
    }
  };

  if (!io(currentForm) || isLoading || isFetching || !hasFormData(currentForm)) {
    return <Skeleton height="calc(100vh - 214px)" />;
  }

  return (
    <div key={currAdvForm?.id}>
      {!currentForm?.form_id && !clinicalNote?.locked && (
        <AlertContent
          message="Since this is a new note, the form must be saved initially for the first time to produce a narrative."
          color="warning"
          width="full"
          className="!mb-3"
        />
      )}
      <Accordion
        key={currAdvForm?.id}
        title={currAdvForm?.name ?? currAdvForm?.form?.name}
        id={id}
        disabled={clinicalNote?.locked}
        sectionRef={sectionRef}>
        <div className="advanced-form h-auto whitespace-pre-wrap">
          <ReactFormGenerator
            key={`form-${currAdvForm?.id}`}
            onChange={(e) => {
              handleOnChange({
                e,
                isMounted,
                currentForm,
                setCurrentForm,
                syncON,
                setFormUpdated,
                advancedHPRef: advancedSOAPRef,
                timer,
                setTimer
              });
            }}
            answer_data={currentForm?.form && JSON.parse(currentForm?.json?.fields)}
            data={currentForm.form ? currentForm.form?.json?.fields : currentForm?.json?.fields}
            read_only={clinicalNote?.locked}
            submitButton={
              <Button text="Submit" buttonType="submit" hidden forwardedRef={advancedSOAPRef} />
            }
            onSubmit={(e) =>
              handleFormSubmit(
                e,
                currentForm.form ? currentForm.id : null,
                currAdvForm.form_id || currAdvForm.id,
                currentForm.form_version || currentForm.version,
                currentForm
              )
            }
          />
        </div>

        <CustomFormNarrative
          key={currAdvForm.form_id || currAdvForm.id}
          formId={currAdvForm.form_id || currAdvForm.id}
          setFormUpdated={setFormUpdated}
          setNarrativeUpdated={setNarrativeUpdated}
          narrativeData={currentForm.narrative}
          syncON={syncON}
          setSyncON={setSyncON}
          currentForm={currentForm}
          setCurrentForm={setCurrentForm}
        />
      </Accordion>
    </div>
  );
};

export default AdvancedSoap;
