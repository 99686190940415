import React, { useCallback, useMemo, useState } from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';

import AGTable from '../AGTable/AGTable';
import CustomStatusBarAggregationComponent from '../AGTable/CustomStatusBarAggregationComponent';
import { CustomStatusBarCount } from '../AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from '../AGTable/CustomStatusBarPagination';

import { RowSelectionActions } from './components/RowSelectionActions';
import TableHeader from './components/TableHeader';
import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_PAGE_COUNT,
  DEFAULT_STALE_TIME,
  GRID_OPTIONS
} from './lib/constants';

const AgTablePage = ({
  title,
  name,
  useData,
  queryParams = {},
  queryOptions = {},
  defaultColumns,
  defaultFilters,
  ModalComponent,
  subtitle,
  onSelectRow,
  headerActions,
  refresh,
  skeletonSize,
  noTitle,
  switchItems,
  rightHeaderActions,
  actions,
  noNewButton,
  noHeader,
  pagination = true,
  formatData,
  ...props
}) => {
  return (
    <TableContextProvider
      defaultFilters={defaultFilters}
      cols={defaultColumns}
      name={name}
      pagination={pagination}>
      <AgTablePageContent
        useData={useData}
        queryParams={queryParams}
        queryOptions={queryOptions}
        ModalComponent={ModalComponent}
        subtitle={subtitle}
        onSelectRow={onSelectRow}
        headerActions={headerActions}
        refresh={refresh}
        skeletonSize={skeletonSize}
        noTitle={noTitle}
        switchItems={switchItems}
        title={title}
        rightHeaderActions={rightHeaderActions}
        noNewButton={noNewButton}
        actions={actions}
        noHeader={noHeader}
        formatData={formatData}
        {...props}
      />
    </TableContextProvider>
  );
};

const AgTablePageContent = ({
  useData,
  queryParams = {},
  queryOptions = {},
  ModalComponent,
  subtitle,
  onSelectRow,
  headerActions,
  refresh = false,
  skeletonSize,
  noTitle,
  switchItems,
  title,
  rightHeaderActions,
  noNewButton,
  actions,
  noHeader,
  formatData,
  ...props
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const { name, limit, page, sort, pagination, setPage, filters, errors, forceRefresh } =
    useTableContext();

  const queryFilters = useMemo(() => {
    return mapValues(filters);
  }, [filters]);

  const { data, refetch, isLoading, isRefetching } = useData({
    name,
    params: {
      limit,
      page,
      sort,
      filters: queryFilters,
      withCount: true,
      ...queryParams
    },
    options: {
      staleTime: DEFAULT_STALE_TIME,
      ...queryOptions
    },
    dependencies: [limit, page, sort, queryFilters, queryParams]
  });

  const loading = isLoading || isRefetching;

  const items = useMemo(() => {
    if (loading) return;
    return formatData ? formatData(data || []) : data?.[name] || [];
  }, [data, name, loading, formatData]);

  const count = data?.count ?? DEFAULT_PAGE_COUNT;
  const statusPanelData = data?.statusPanelData;

  const onCellClicked = (event) => {
    if (['actions', '0'].includes(event?.column?.colId)) return;
    if (onSelectRow) {
      onSelectRow(event?.data);
      return;
    }
    setSelectedItem(event?.data);
    setShowModal(true);
  };

  const onModalToggle = () => {
    setShowModal((prev) => !prev);
    setSelectedItem(null);
  };

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: items,
            count,
            page,
            limit,
            pagination
          },
          align: 'left'
        },
        ...(pagination
          ? [
              {
                statusPanel: CustomStatusBarPagination,
                statusPanelParams: {
                  data: items,
                  count,
                  page,
                  limit,
                  onPageChange
                },
                align: 'right'
              }
            ]
          : []),
        ...(statusPanelData
          ? [
              {
                statusPanel: CustomStatusBarAggregationComponent,
                statusPanelParams: {
                  statusPanelData
                },
                align: 'center'
              }
            ]
          : [])
      ]
    };
  }, [items, count, page, limit, onPageChange, statusPanelData, pagination]);

  return (
    <>
      {!noHeader && (
        <TableHeader
          category={name}
          onModalToggle={onModalToggle}
          hasNew={!!ModalComponent && !noNewButton}
          subtitle={subtitle}
          actions={headerActions}
          refresh={refresh}
          onRefresh={refetch}
          noTitle={noTitle}
          switchItems={switchItems}
          title={title}
          count={count}
          rightActions={rightHeaderActions}
          loading={loading}
        />
      )}
      <div className="flex h-full flex-col overflow-hidden relative">
        <div className="ag-theme-quartz h-full">
          <AGTable
            key={forceRefresh}
            data={items}
            gridOptions={GRID_OPTIONS}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            onCellClicked={onCellClicked}
            statusBar={statusBar}
            reactiveCustomComponents={onCellClicked}
            loading={loading}
            skeletonSize={skeletonSize}
            {...props}
          />
        </div>
        <RowSelectionActions actions={actions} />
      </div>
      {showModal && ModalComponent && (
        <ModalComponent
          item={selectedItem}
          isOpen={showModal}
          handleClose={onModalToggle}
          filters={filters}
          errors={errors}
          category={name}
        />
      )}
    </>
  );
};

export default AgTablePage;
