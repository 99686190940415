import React, { useMemo } from 'react';

import cs from 'classnames';
import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import { snakeToTitleCase } from 'lib/helpers/utility';

import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/Popover';

const Progress = ({ className }) => {
  const { values, setFieldValue } = useFormikContext();
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const isAptDisabled = values?.appointment_disabled;

  let currStatusGroup =
    appointmentStatuses.find((item) => item.value === values.status)?.property_of || values.status;

  if (currStatusGroup === 'checkin') {
    currStatusGroup = 'Check-In';
  } else {
    currStatusGroup = snakeToTitleCase(currStatusGroup);
  }

  const appointmentStatus =
    appointmentStatuses.find((item) => item.value === values.status)?.label || values.status;

  let options = useMemo(
    () =>
      appointmentStatuses
        .map((status) => ({
          ...status,
          onClick: () => setFieldValue('status', status.value)
        }))
        .filter((item) => !(item.value === 'completed' && isAptDisabled)),
    [appointmentStatuses, isAptDisabled, values]
  );

  const isPriorAuthNotNeeded =
    values.timeline?.financial?.['cc-on-file']?.name == 'Prior-Auth not Needed';

  if (isPriorAuthNotNeeded) {
    options = options.filter((option) => !['no_cc_on_file', 'cc_on_file'].includes(option.value));
  }

  return (
    <div className={cs('flex flex-col gap-[6px]', isAptDisabled && 'z-[150]', className)}>
      <span className="text-sm font-500 text-neutral-800 first-letter:capitalize">
        Appointment progress
      </span>
      <div className="flex h-full min-h-[116px] flex-col justify-between gap-5 rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] p-4 pt-5">
        <div className="flex items-center justify-between gap-5 sm:gap-2">
          <span className="whitespace-nowrap text-sm font-500 leading-[22px] text-primary-600">
            Current Stage:
          </span>
          <span
            className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-500 leading-7 text-primary-900"
            title={snakeToTitleCase(currStatusGroup)}>
            {currStatusGroup}
          </span>
        </div>
        <div className="flex items-center justify-between gap-5">
          <span className="text-sm font-500 leading-[22px] text-primary-600">Status: </span>
          <Popover
            id="appointment-statuses"
            className="rounded-md bg-white"
            buttonClassName="max-w-[180px]"
            textClassName="text-ellipsis overflow-hidden whitespace-nowrap"
            panelClassName="max-h-[300px] xl:max-h-[500px] overflow-y-auto"
            icon={false}
            text={appointmentStatus}
            buttonTheme={options.find((option) => option.value === values.status)?.color}
            isFixed
            isDropdown
            position="left"
            options={options}
            optionsGroupBy="property_of"
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
