import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import CreatableSelect from 'react-select/creatable';
import { useRecoilState } from 'recoil';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import state from '../state';
import { requestApi } from 'api/Api';

const DEFAULT_TERMINAL_OBJECT = {
  id: 0,
  pin: '',
  merchant_id: '',
  vendor_id: '',
  user_id: '',
  brand: '',
  kind: '',
  name: '',
  online: false,
  active: false,
  note: '',
  group_id: null
};

const TerminalEntry = ({ terminal: givenTerminal, index, practiceId }) => {
  const [origTerminal, setOrigTerminal] = useState(DEFAULT_TERMINAL_OBJECT);
  const [terminal, setTerminal] = useState(DEFAULT_TERMINAL_OBJECT);
  const [show, setShow] = useState({
    edit: false,
    priviledged_info: false,
    loading: false,
    saveDisabled: true
  });
  const [changes, setChanges] = useState({});
  const [selectedTerminalGroup, setSelectedTerminalGroup] = useState(null);
  const [terminalGroups, setTerminalGroups] = useRecoilState(state.terminalGroupState);
  const navigate = useNavigate();

  useEffect(() => {
    setTerminal({ ...givenTerminal });
    setOrigTerminal({ ...givenTerminal });
    let terminalGroup = terminalGroups.find((v) => v.value === givenTerminal.group_id);
    if (terminalGroup) {
      setSelectedTerminalGroup(terminalGroup);
    }
  }, []);

  useEffect(() => {
    let terminalGroup = terminalGroups.find((v) => v.value === terminal.group_id);
    if (terminalGroup) {
      setSelectedTerminalGroup(terminalGroup);
    }
  }, [terminalGroups]);

  const getTerminalData = async (ns) => {
    let newShow = Object.assign({}, ns || show);
    newShow.loading = true;
    setShow(newShow);
    try {
      const res = await requestApi({
        url: '/api/transactions/terminal/get',
        params: { tid: terminal.id },
        navigate
      });
      const { code, redirect, error, terminalData } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          newShow.priviledged_info = true;
          if (terminalData) {
            const newTerminalData = { ...terminal, ...terminalData };
            setTerminal(newTerminalData);
            setOrigTerminal(newTerminalData);
          }
          break;

        default:
          Honeybadger.notify(
            `file: /terminals/terminalEntry, method: onCreateGroup - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          break;
      }
      newShow.loading = false;
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/terminalEntry, method: onCreateGroup - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
    setShow(newShow);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setTerminal({ ...terminal, [name]: value });
    setChanges({ ...changes, [name]: value });
    setShow({ ...show, saveDisabled: false });
  };

  const saveChanges = async () => {
    setShow({ ...newShow, loading: true });
    let newShow = _.cloneDeep(show);
    try {
      const res = await requestApi({
        url: '/api/transactions/terminal/update',
        params: { changes: changes, terminal: terminal },
        navigate
      });

      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setOrigTerminal(terminal);
          toast.success(`Updated terminal ${terminal.name}`);
          newShow.edit = false;
          break;
        default:
          Honeybadger.notify(
            `file: /terminals/terminalEntry, method: saveChanges - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/terminalEntry, method: saveChanges - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
    setShow({ ...newShow, loading: false });
  };

  const onCreateGroup = async (newGroupName) => {
    try {
      const res = await requestApi({
        url: '/api/transactions/group/add',
        params: { practiceId: practiceId, groupName: newGroupName },
        navigate
      });
      const { code, redirect, error, group } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setTerminalGroups([...terminalGroups, group]);
          break;
        default:
          Honeybadger.notify(
            `file: /terminals/terminalEntry, method: onCreateGroup - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: /terminals/terminalEntry, method: onCreateGroup - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const startEdit = () => {
    if (show.priviledged_info) {
      setShow({ ...show, edit: true });
    } else {
      getTerminalData({ ...show, edit: true });
    }
  };

  const cancelEdit = () => {
    setTerminal(origTerminal);
    setShow({ ...show, edit: false });
  };

  if (show.edit) {
    return (
      <tr key={`terminal-entry-index-${index}-0`} className="px-5">
        <td>{terminal.id}</td>
        <td>{terminal.practice_id}</td>
        <td>
          <input value={terminal.name} onChange={handleChange} name="name" />
        </td>
        <td>
          <Checkbox
            color="primary"
            style={{ color: '#00a2ff' }}
            checked={terminal.online}
            onChange={(e) => {
              handleChange({ target: { name: 'online', value: e.target.checked } });
            }}
            name="online"
          />
        </td>
        <td>
          <input value={terminal.pin} onChange={handleChange} name="pin" />
        </td>
        <td>
          <input value={terminal.merchant_id} onChange={handleChange} name="merchant_id" />
        </td>
        <td>
          <input value={terminal.vendor_id} onChange={handleChange} name="vendor_id" />
        </td>
        <td>
          <input value={terminal.user_id} onChange={handleChange} name="user_id" />
        </td>
        <td>
          <input value={terminal.brand} onChange={handleChange} name="brand" />
        </td>
        <td>
          <input value={terminal.kind} onChange={handleChange} name="kind" />
        </td>
        <td>
          <input value={terminal.note} onChange={handleChange} name="note" />
        </td>
        <td>
          <div style={{ width: '15rem' }}>
            <CreatableSelect
              isClearable
              isDisabled={show.groupLoading}
              isLoading={show.groupLoading}
              onChange={(e) => {
                handleChange({ target: { name: 'group_id', value: e?.value || null } });
                setSelectedTerminalGroup(e);
                // setTerminal({ ...terminal, group_id: e?.value || null });
              }}
              onCreateOption={onCreateGroup}
              options={terminalGroups}
              value={selectedTerminalGroup}
            />
          </div>
        </td>
        <td>
          <Checkbox
            color="primary"
            style={{ color: '#00a2ff' }}
            checked={terminal.active}
            onChange={(e) => {
              handleChange({ target: { name: 'active', value: e.target.checked } });
            }}
            name="active"
          />
        </td>
        <td>
          <Button green onClick={saveChanges} disabled={show.saveDisabled}>
            Save
          </Button>
          <Button warning onClick={cancelEdit}>
            Cancel
          </Button>
        </td>
      </tr>
    );
  }

  return (
    <tr key={`terminal-entry-index-${index}-0`}>
      <td>{terminal.id}</td>
      <td>{terminal.practice_id}</td>
      <td>{terminal.name}</td>
      <td>{terminal.online ? '✓' : '✕'}</td>
      {show.priviledged_info ? (
        <>
          <td>{terminal.pin}</td>
          <td>{terminal.merchant_id}</td>
          <td>{terminal.vendor_id}</td>
          <td>{terminal.user_id}</td>
        </>
      ) : (
        <td colSpan={4}>
          <Button loading={show.loading} onClick={getTerminalData}>
            View Details
          </Button>
        </td>
      )}
      <td>{terminal.brand}</td>
      <td>{terminal.kind}</td>
      <td>{terminal.note}</td>
      <td>{selectedTerminalGroup?.label || terminal.group_id}</td>
      <td>{terminal.active ? '✓' : '✕'}</td>
      <td>
        <Button warning onClick={startEdit}>
          Edit
        </Button>
      </td>
    </tr>
  );
};

export default TerminalEntry;
