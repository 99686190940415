import React, { useEffect } from 'react';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { optionify } from '/lib/helpers/utility';

const NewAddressExistingPractice = ({ formik, stateList, onChange }) => {
  useEffect(() => {
    formik.setFieldValue('address_is_same_as_practice', false, false);
  }, []);
  return (
    <>
      <div className="grid grid-cols-5 gap-4 md:grid-cols-1 lg:grid-cols-3">
        <Input
          label="Address"
          name="address_ln_1"
          value={formik.values?.address_ln_1}
          onChange={onChange}
          error={formik.errors?.address_ln_1}
          data-qa="practitioner-address-ln-1"
        />

        <Input
          label="Address 2"
          name="address_ln_2"
          value={formik.values?.address_ln_2}
          onChange={onChange}
          data-qa="practitioner-address-ln-2"
        />

        <Input
          label="City"
          name="city"
          value={formik.values?.city}
          onChange={onChange}
          error={formik.errors?.city}
          data-qa="practitioner-city"
        />

        <Select
          isClearable={false}
          label="State"
          name="state"
          options={optionify(stateList, 'name', 'id')}
          placeholder="State"
          value={{
            label: stateList.find((state) => state.id === formik.values?.state)?.name
          }}
          error={formik.errors?.state}
          onChange={(e) => formik.setFieldValue('state', e?.value || null)}
          data-qa="practitioner-state"
        />

        <Input
          label="Zip Code"
          name="zip"
          value={formik.values?.zip}
          onChange={onChange}
          error={formik.errors?.zip}
          data-qa="practitioner-zip-code"
        />
      </div>
    </>
  );
};
export default NewAddressExistingPractice;
