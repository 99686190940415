import { useMutation, useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { mergePatients, updatePracticePatientLink } from 'api/Patients';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { Capitalize, isEmpty, pm } from 'lib/helpers/utility';
import StickyNotes from 'components/practice/StickyNotes/StickyNotes';
import { currentPractice } from 'components/practice/practiceState';
import OptionPopover from './OptionPopover';
import ChartExport from '../../PatientCharts/components/ChartExport';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';

export default function OverviewPopover({
  patient,
  showNewAppointmentModal,
  setSellModalVisible,
  setShowPaymentModal
}) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const { timezone } = useRecoilValue(currentPractice);
  const [reason, setReason] = useState();
  const [deceaseDate, setDeceaseDate] = useState(moment());
  const { device } = useUIContext();
  const [isChecked, setIsChecked] = useState(false);
  const [selectedDupe, setSelectedDupe] = useState(null);
  const [showExport, setShowExport] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const permissions = useRecoilValue(state.permissions);

  const patientStatus = patient?.practicePatient?.status === 'active' ? 'inactive' : 'active';
  const patientStatusPopoverIcon =
    patient.status === 'active' ? 'new-patient-inactive' : 'new-patient-active';
  const patientStatusModalIcon =
    patient.status === 'active' ? 'new-patient-inactive' : 'new-patient-active';
  const queryClient = useQueryClient();

  const mutatePracticePatientLink = useMutation({
    mutationFn: () => {
      setLoading(true);
      return modalInfo.popoverType === 'merge'
        ? mergePatients(navigate, {
            basePatientId: patient.id,
            dupPatientId: selectedDupe
          })
        : updatePracticePatientLink(navigate, {
            patientId: patient.id,
            changes: {
              status: modalInfo.popoverType === 'status' ? patientStatus : modalInfo.popoverType,
              ...(isEmpty(reason) ? null : { reason }),
              ...(modalInfo.popoverType === 'deceased' && deceaseDate
                ? { decease_date: deceaseDate }
                : null)
            }
          });
    },
    onSuccess: (response) => {
      setLoading(false);
      const { message } = response;
      queryClient.invalidateQueries(['patient']);
      queryClient.invalidateQueries(['patients']);
      showAlert({ color: 'success', message: message || 'Profile was updated successfully!' });
      onCloseModal();
    }
  });

  const initialOptions = [
    ...(pm(permissions, 'patients.update')
      ? [
          {
            label: `Mark ${Capitalize(patientStatus)}`,
            type: `status`,
            icon: `${patientStatusPopoverIcon}`,
            iconColor: 'primary',
            onClick: () =>
              onOptionClick({
                title: `Mark Patient ${Capitalize(patientStatus)}`,
                message: `Are you sure you wish to mark this patient ${patientStatus}?`,
                primaryBtnTxt: `Mark ${Capitalize(patientStatus)}`,
                icon: `${patientStatusModalIcon}`,
                popoverType: 'status'
              })
          }
        ]
      : []),
    ...(pm(permissions, 'patients.update')
      ? [
          {
            label: 'Mark Deceased',
            type: 'deceased',
            icon: 'new-deceased-patient',
            permissions: 'patients.update',
            onClick: () =>
              onOptionClick({
                title: 'Deceased Patient',
                message: 'Are you sure you wish to mark this patient deceased?',
                primaryBtnTxt: 'Mark Deceased',
                icon: 'new-modal-patient-deceased',
                popoverType: 'deceased'
              })
          }
        ]
      : []),
    ...(pm(permissions, 'patients.delete')
      ? [
          {
            label: 'Delete Patient',
            type: 'deleted',
            icon: 'new-trash-patient',
            permission: 'patients.delete',
            onClick: () =>
              onOptionClick({
                title: 'Delete Patient',
                message: 'Are you sure you wish to permanently delete this patient?',
                primaryBtnTxt: 'Delete Patient',
                icon: 'new-delete-patient',
                popoverType: 'deleted'
              })
          }
        ]
      : []),
    ...(pm(permissions, 'patients.merge')
      ? [
          {
            label: 'Merge Duplicate',
            type: 'merge',
            icon: 'new-merge',
            permission: 'patients.merge',
            iconColor: 'primary',
            onClick: () =>
              onOptionClick({
                title: 'Merge Duplicate Patient Records',
                message: 'Are you certain you wish to permanently merge these patient records?',
                primaryBtnTxt: 'Merge Charts',
                icon: 'new-merge-charts',
                popoverType: 'merge'
              })
          }
        ]
      : []),
    ...(pm(permissions, 'patients.export')
      ? [
          {
            label: 'EHI Export',
            icon: 'new-export-v2',
            onClick: () => setShowExport(true)
          }
        ]
      : [])
  ];
  const [chartOptions, setChartOptions] = useState(initialOptions);
  const mobileOptions = [
    ...(pm(permissions, 'patient_sticky_notes.read')
      ? [
          {
            label: 'Notes',
            icon: 'new-note',
            onClick: () => setShowNotes(true)
          }
        ]
      : []),
    ...(pm(
      permissions,
      ['appointments.create', 'products.read', 'packages.read', 'payments.create'],
      'or'
    )
      ? [
          {
            label: 'Create',
            icon: 'add-circle',
            children: [
              ...(pm(permissions, 'appointments.create')
                ? [
                    {
                      label: 'Appointment',
                      icon: 'new-calendar',
                      onClick: () => showNewAppointmentModal(true)
                    }
                  ]
                : []),
              ...(pm(permissions, ['products.read', 'packages.read'], 'or')
                ? [
                    {
                      label: 'Transaction',
                      icon: 'new-receipt-textv2',
                      onClick: () => setSellModalVisible(true)
                    }
                  ]
                : []),

              ...(pm(permissions, 'payments.create')
                ? [
                    {
                      label: 'Payment',
                      icon: 'money-send',
                      onClick: () => setShowPaymentModal(true)
                    }
                  ]
                : [])
            ]
          }
        ]
      : [])
  ];

  useEffect(() => {
    if (device === 'mobile') {
      setChartOptions([...initialOptions, ...mobileOptions]);
    } else {
      setChartOptions(initialOptions);
    }
  }, [device]);

  const onOptionClick = (modalProps) => {
    if (modalProps.popoverType) {
      setShowModal(true);
      setModalInfo(modalProps);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setLoading(false);
    setDeceaseDate(null);
    setReason([]);
    setModalInfo({});
  };

  if (showModal) {
    return (
      <Confirm
        handleOpen={showModal}
        handleClose={() => onCloseModal()}
        loading={loading}
        variant="danger"
        handleContinue={() => mutatePracticePatientLink.mutate()}
        disabled={modalInfo.popoverType === 'merge' && (!isChecked || !selectedDupe)}
        {...modalInfo}>
        <OptionPopover
          type={modalInfo.popoverType}
          timezone={timezone}
          reason={reason}
          setReason={setReason}
          deceaseDate={deceaseDate}
          setDeceaseDate={setDeceaseDate}
          isChecked={isChecked}
          setIsChecked={() => setIsChecked(!isChecked)}
          selectedDupe={selectedDupe}
          setSelectedDupe={setSelectedDupe}
          patient={patient}
        />
      </Confirm>
    );
  }

  return (
    <>
      <Popover options={chartOptions} position="left" icon="new-context-menu-horizontal" />
      {showNotes && (
        <StickyNotes
          patientId={patient.id}
          customStyle={{
            button:
              'relative flex items-center gap-x-1 py-[6px] !px-3 !pl-[13px] transition-all hover:bg-primary-50 w-full',
            text: 'text-primary-900 text-xs',
            icon: {
              size: 16,
              color: 'primary'
            }
          }}
          openNotes={showNotes}
          setOpenNotes={setShowNotes}
        />
      )}
      {showExport && patient && (
        <ChartExport
          selectedRows={patient ? [patient.id] : []}
          show={showExport}
          setShow={setShowExport}
        />
      )}
    </>
  );
}
