import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import useCopy from 'lib/hooks/useCopy';
import usePageTitle from 'lib/hooks/usePageTitle';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import { IconSvg } from 'components/shared/Icon/lib/Icon.generated';

import { getCategoryName, getIconCategory, isLightIcon } from './lib/helpers';

const IconsPage = () => {
  const [iconData, setIconData] = useState([]);
  const containerRef = useRef(null);
  const { copyToClipboard } = useCopy();

  useEffect(() => {
    const iconNames = Object.keys(IconSvg);

    // Add category information to each icon
    const categorizedIcons = iconNames.map((name) => ({
      name,
      width: 0,
      category: getIconCategory(name)
    }));

    // Sort by category first
    categorizedIcons.sort((a, b) => a.category - b.category);
    setIconData(categorizedIcons);

    setTimeout(() => {
      if (containerRef.current) {
        const iconElements = containerRef.current.querySelectorAll('#icon-wrapper svg');

        const updatedIconData = categorizedIcons.map((icon, index) => ({
          ...icon,
          width: iconElements[index]?.getAttribute('width') || 0,
          height: iconElements[index]?.getAttribute('height') || 0
        }));

        setIconData(updatedIconData);
      }
    }, 100);
  }, []);

  usePageTitle('Icons');

  // Group icons by category
  const groupedIcons = iconData.reduce((acc, icon) => {
    const category = icon.category;
    if (!acc[category]) acc[category] = [];
    acc[category].push(icon);
    return acc;
  }, {});

  return (
    <div ref={containerRef} className="bg-secondary-50 p-4">
      {Object.entries(groupedIcons).map(([category, icons]) => (
        <div key={category} className="mb-8">
          <h2 className="text-xxl mb-4 font-500 text-secondary-600">
            {getCategoryName(Number(category))}
          </h2>
          <div
            className={cs(
              'grid gap-4',
              category !== '0' ? 'grid-cols-5' : 'grid-cols-8 sm:grid-cols-4'
            )}>
            {icons.map(({ name, width, height }) => (
              <div
                key={`icon-${name}`}
                className={cs(
                  'group relative flex max-h-[150px] min-h-[150px] cursor-pointer flex-col items-center justify-center overflow-hidden rounded-2xl border border-solid border-neutral-200',
                  isLightIcon(name) ? 'bg-neutral-800' : 'bg-white'
                )}
                title="Copy"
                onClick={() => copyToClipboard(name)}>
                <Icon
                  icon={name}
                  id="icon-wrapper"
                  className={cs(
                    'flex grow cursor-pointer justify-center',
                    category === '0'
                      ? 'size-10 min-h-10 min-w-10'
                      : 'min-h-size-24 min-w-size-24 size-24'
                  )}
                />
                <div
                  className={cs(
                    'w-full select-none rounded-bl-md border-0 border-t border-solid border-neutral-200 p-1 text-center text-sm ',
                    isLightIcon(name)
                      ? 'text-neutral-100 group-hover:bg-neutral-700'
                      : 'text-neutral-600 group-hover:bg-neutral-50'
                  )}>
                  {name}
                </div>
                <div
                  className={cs(
                    'absolute right-0 top-0 select-none rounded-bl-md border-0 border-b border-l border-solid border-neutral-200 bg-secondary-50 p-1 text-sm',
                    isLightIcon(name) ? '!bg-transparent text-neutral-100' : 'text-neutral-600'
                  )}>
                  {width === height ? `${width}px` : `${width} x ${height}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default withErrorBoundary(IconsPage);
