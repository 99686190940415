import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { interimApi } from '../../../api/InterimApi';
import { formatDate } from '../../../lib/helpers/utility';
import Button from '../../shared/Button/Button';
import Spinner from '../../shared/spinner/spinner';
import PatientDocumentModal from './components/PatientDocumentModal';
import './style.scss';
import { useNavigate } from 'react-router-dom';

export default function Documents() {
  const [loading, setLoading] = useState({ spinner: false, skeleton: false });
  const [superbills, setSuperbills] = useState([]);
  const [superbill, setSuperbill] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSuperbills();
  }, []);

  const getSuperbills = async () => {
    setLoading({ ...loading, spinner: true });

    try {
      let { data } = await interimApi('/api/patient/billing/superbill/read', {}, navigate);

      if (data?.superbills) setSuperbills(data?.superbills);
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: documents/index, method: getSuperbills - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }

    setLoading({ ...loading, spinner: false });
  };

  return (
    <div>
      <div className="patientDocuments">
        <div className="patientDocuments__table">
          <div className="patientDocuments__table__head">
            <p>ID</p>
            <p>Provider</p>
            <p>Practice</p>
            <p>Date</p>
            <p></p>
          </div>
          {loading.spinner ? (
            <div className="Spinner-center">
              <Spinner />
            </div>
          ) : superbills.length > 0 ? (
            superbills.map((superbill) => {
              return (
                <div className="patientDocuments__table__item" key={superbill?.id}>
                  <p>{superbill?.id}</p>
                  <p>{superbill?.practitioner?.fullName}</p>
                  <p>{superbill?.practice?.name}</p>
                  <p>{formatDate(superbill?.created_at)}</p>
                  <Button
                    primary
                    small
                    onClick={() => {
                      setShowModal(true);
                      setSuperbill(superbill);
                    }}>
                    Show
                  </Button>
                </div>
              );
            })
          ) : (
            <p className="fs-14 w-full p-20 text-center">No superbills!</p>
          )}
        </div>
      </div>

      {superbill && (
        <PatientDocumentModal
          showModal={showModal}
          hideModal={() => setShowModal(false)}
          superbill={superbill}
          loading={loading}
        />
      )}
    </div>
  );
}
