import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';

export const TreatmentPlanWidgetHeader = ({ setShowTPModal, props }) => {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();
  return (
    <WidgetHeader
      onPlusClick={() => {
        setSelectedTreatmentPlan({});
        setShowTPModal({ open: true, loadView: 'create' });
      }}
      icon="new-task1"
      iconColor="primary"
      {...props}
    />
  );
};
