import { requestApi } from './Api';

export const getPatientInsuranceProfiles = async (navigate, params, signal) => {
  return await requestApi({
    navigate,
    url: '/api/insurance_profiles/get',
    params,
    signal
  });
};
