import { useMemo, useState } from 'react';

import {
  CURRENCY_UNITS,
  CURRENCY_VARIANTS,
  PERCENT_AMOUNT_DIVISOR,
  STYLE_VARIANTS
} from '../lib/constants';

const useUnit = ({ currentUnit, value = 0, maxValue = 1, onUnitChange, setInputValue, style }) => {
  const [inputUnit, setInputUnit] = useState(currentUnit?.value || STYLE_VARIANTS[style]);

  const currentStyle = useMemo(() => {
    if (!inputUnit || inputUnit === 'decimal') return style;
    return CURRENCY_UNITS.includes(inputUnit)
      ? CURRENCY_VARIANTS.currency
      : CURRENCY_VARIANTS.percent;
  }, [style, inputUnit]);

  const isPercent = currentStyle === CURRENCY_VARIANTS.percent;

  const handleUnitChange = (unit) => {
    setInputUnit(unit);
    onUnitChange(unit);

    const isNewUnitPercent = !CURRENCY_UNITS.includes(unit);
    const isOldUnitPercent = !CURRENCY_UNITS.includes(inputUnit);

    if (isNewUnitPercent !== isOldUnitPercent) {
      if (isNewUnitPercent) {
        const percentageValue = (value / maxValue) * PERCENT_AMOUNT_DIVISOR;

        setInputValue(percentageValue);
      } else {
        setInputValue(value);
      }
      return;
    }

    setInputValue(value);
  };

  return { inputUnit, handleUnitChange, currentStyle, isPercent };
};

export default useUnit;
