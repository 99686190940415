import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';

import { useQueryClient } from '@tanstack/react-query';

import { ia, iaRa } from 'lib/helpers/utility';
import { useClaimMdHistory } from 'lib/hooks/queries/claims/useClaimMdHistory';

import NotFound from 'components/practice/Dashboard/components/NotFound';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Typography from 'components/shared/Typography/Typography';

const claimState = {
  R: 'Rejected',
  A: 'Acknowledged'
};

const TimeLineElement = ({ state }) => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: state.status === 'R' ? 'rgb(255,236,240)' : 'rgb(239,250,244)',
        color: state.status === 'R' ? 'rgb(215,62,91)' : 'rgb(59,133,97)'
      }}
      contentArrowStyle={{
        borderRight:
          state.status === 'R' ? '7px solid  rgb(255,236,240)' : '7px solid  rgb(239,250,244)'
      }}
      date={state.response_time}
      iconStyle={{
        background: state.status === 'R' ? 'rgb(241,71,103)' : 'rgb(74,198,141)',
        color: '#fff'
      }}>
      <Typography
        variant="h6"
        color={state.status === 'R' ? 'text-danger-700' : 'text-success-700'}>
        Status: {claimState[state.status]}
      </Typography>
      {state?.messages?.map(({ message }, index) => {
        return <ul key={index}>{message}</ul>;
      })}
    </VerticalTimelineElement>
  );
};

const TimeLineElementSkeleton = () => {
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{
        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
        backgroundSize: '200% 100%',
        animation: 'pulse-bg 1.5s infinite linear',
        height: '150px'
      }}
      date={<Skeleton width={200} />}
      contentArrowStyle={{
        borderRight: '7px solid #f0f0f0'
      }}
      iconStyle={{
        background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
        backgroundSize: '200% 100%',
        animation: 'pulse-bg 1.5s infinite linear',
        color: '#fff',
        boxShadow: 'none'
      }}></VerticalTimelineElement>
  );
};
const SKELETON_ELEMENTS = [...Array(5)];

const TimelineElements = ({ elements, isLoading, noContent }) => {
  if (isLoading) {
    return SKELETON_ELEMENTS.map((row) => {
      return <TimeLineElementSkeleton key={row} />;
    });
  }

  if (noContent) {
    return (
      <div className="flex items-center justify-center h-full">
        <NotFound icon="new-illustration-4" description="This claim has not been submitted yet." />
      </div>
    );
  }

  return elements.map((row, index) => {
    return <TimeLineElement key={index} state={row} />;
  });
};
const ClaimMdHistory = ({ isOpen, onClose, claimId }) => {
  const [claimState, setClaimState] = useState(null);

  const queryClient = useQueryClient();
  const { data, isLoading } = useClaimMdHistory({
    params: { claimId },
    dependencies: [claimId],
    options: {
      enabled: !!claimId,
      onSuccess: ({ history }) => {
        if (history?.updatedState) {
          setClaimState(history.updatedState);
        }
      }
    }
  });

  const updateClaimState = (claimId, state) => {
    if (!state) return;

    queryClient.setQueriesData(['claims'], (queryData) => {
      if (!ia(queryData?.claims)) return queryData;

      const claims = queryData.claims.map((claim) => {
        if (claim.id !== claimId) return claim;

        return { ...claim, state };
      });

      return { ...queryData, claims };
    });
  };

  const handleClose = () => {
    updateClaimState(claimId, claimState);
    setClaimState(null);
    onClose();
  };

  const claimStateHistory = iaRa(data?.history?.claim);
  const noContent = !ia(claimStateHistory);
  return (
    <Modal
      slideFromRight
      isOpen={isOpen}
      handleClose={handleClose}
      buttonText="Done"
      title="Claim status history"
      className="w-[50%]"
      footer={<Button onClick={handleClose} text="Close" color="neutral" data-qa="close-btn" />}>
      <VerticalTimeline className="h-full" lineColor={noContent ? 'transparent' : 'white'}>
        <TimelineElements
          elements={claimStateHistory}
          isLoading={isLoading}
          noContent={noContent}
        />
      </VerticalTimeline>
    </Modal>
  );
};

export default ClaimMdHistory;
