import { useMutation, useQueryClient } from '@tanstack/react-query';
import { upsertPatientWeightLossGoal } from 'api/Patients';
import ResizableContainer from 'components/practice/charts/ClinicalNote/shared/VerticallyResizableDiv/ResizableContainer';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { clinicalNote as clinicalNoteState, permissions } from 'components/state';
import { useFormik } from 'formik';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa, io } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import { usePatientWeightLossGoal } from 'lib/hooks/queries/patients/usePatientWeightLossGoal';
import { isEqual } from 'lodash';
import React, { useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { transformData } from '../../lib/aggregatedDataHelper';
import MedicationSelectionModal from '../../ProviderMedications/components/MedicationSelectionModal';
import MedicationsTable from '../../ProviderMedications/components/MedicationsTable';
import UpsertProviderMedication from '../../ProviderMedications/components/UpsertMedication';
import providerMedicationsTableCols from '../../ProviderMedications/lib/providerMedicationsTableCols';
import HeaderMedicationTable from '../Allergies/components/HeaderMedicationTable';
import AddToTodaysNote from '../components/AddToTodaysNote';
import FormsTable from '../components/FormsTable';
import NarrativeForm from '../components/NarrativeForm';
import NoteHeader from '../components/NoteHeader';
import providerWeightLossTableCols from '../WeightLoss/lib/providerWeightLossTableCols';
import ExistingMedication from './components/ExistingMedications';
import { FINAL_NOTE_OPTIONS } from './lib/constants';
import { initialValues } from './lib/helpers';
import { weightLossItemFormatter } from './lib/narrativeHelper';

const ProviderWeightLoss = ({ customFormsParams, options }) => {
  const {
    setSelectedCustomFormTypes,
    overviewData,
    medications,
    setMedications,
    weightLossRef: ref
  } = useClinicalNoteContext();
  const userPermissions = useRecoilValue(permissions);
  const { id: patientId } = useParams();
  const weightLoss = useMemo(() => {
    if (overviewData?.weightLossAdministration) {
      return transformData({
        data: overviewData?.weightLossAdministration,
        formType: 'weightLossAdministration',
        validation: 'medication'
      });
    }
    return [];
  }, [overviewData?.weightLossAdministration]);
  const { data: patientWeightLossGoal } = usePatientWeightLossGoal({
    params: {
      patientId
    },
    dependencies: [patientId],
    options: {
      select: (data) => data?.patientWeightLossGoal
    }
  });
  const mutateUpsertWeightLossGoal = useMutation({
    mutationFn: () =>
      upsertPatientWeightLossGoal(navigate, {
        patientId,
        fields: formik?.values
      }),
    onSuccess: async ({ code }) => {
      if (code !== 0) {
        showAlert({
          title: 'Weight Loss Goal',
          message: 'An error has occurred upserting Weight Loss Goal. Please try again later.',
          color: 'danger'
        });
      }
      queryClient.invalidateQueries(['patientWeightLossGoal']);
    }
  });
  const upsertWeightLossGoal = async () => {
    if (formik.errors?.initial_weight || formik.errors?.goal_weight) {
      showAlert({
        title: 'Weight Loss Goal',
        message: 'Please enter valid weight values.',
        color: 'danger'
      });
      return;
    }
    if (isEqual(patientWeightLossGoal, formik?.values)) {
      return;
    }
    await mutateUpsertWeightLossGoal.mutateAsync();
  };

  const navigate = useNavigate();

  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [showMedicationSelectionModal, setShowMedicationSelectionModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [resize, setResize] = useState(250);
  const queryClient = useQueryClient();
  const [showMedicationTable, setShowMedicationTable] = useState(false);
  const [showPreviewMedicationFromWhl, setShowPreviewMedicationFromWhl] = useState(false);
  let clinicalNote = useRecoilValue(clinicalNoteState);
  const { isLoading: isMedicationLoading } = useMedication({
    params: {
      patientId
    },
    dependencies: [patientId],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications),
      onSuccess: (medicationData) => {
        setMedications(medicationData);
      }
    }
  });

  const formik = useFormik({
    initialValues: initialValues(patientWeightLossGoal),
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      initial_weight: Yup.number().typeError('Initial weight must be a number').nullable(),
      goal_weight: Yup.number().typeError('Goal weight must be a number').nullable()
    })
  });
  useImperativeHandle(ref, () => ({
    upsertWeightLossGoal
  }));
  const handleOnChange = (name, value) => {
    if (value === '' || value == 0) value = null;
    formik.setFieldValue(name, value);
  };
  return (
    <>
      <TableContextProvider
        cols={() =>
          providerMedicationsTableCols({
            fromWeightLoss: true,
            showPreviewMedicationFromWhl,
            setShowPreviewMedicationFromWhl,
            showMedicationModal,
            setShowMedicationModal,
            noteLocked: clinicalNote?.locked,
            userPermissions
          })
        }
        name="Medications"
        defaultFilters={[]}>
        <HeaderMedicationTable
          title="Patient Medications"
          titleClassName="!text-neutral-800 !text-base !font-500"
          className="text-black">
          <Button
            size="small"
            className="w-10 !pl-[10px]"
            onClick={() => {
              setShowMedicationTable((prevState) => !prevState);
            }}
            icon={
              <Icon icon={showMedicationTable ? 'chevron-up' : 'chevron-down'} color="primary" />
            }
          />
        </HeaderMedicationTable>
        {showMedicationTable && (
          <MedicationsTable
            customStyling="!h-[210px]"
            data={medications}
            getGridApi={(api) => setGridApi(api)}
          />
        )}
      </TableContextProvider>
      <TableContextProvider
        cols={() =>
          providerWeightLossTableCols({
            fromWeightLoss: true,
            setShowMedicationModal,
            noteLocked: clinicalNote?.locked,
            userPermissions
          })
        }
        name="WeightLoss Administration"
        defaultFilters={[]}>
        <div className="h-full">
          <AddToTodaysNote
            type="weightLossAdministration"
            finalNoteOptions={FINAL_NOTE_OPTIONS}
            onChange={({ type, options }) =>
              setSelectedCustomFormTypes((prevState) => ({
                ...prevState,
                [type]: options
              }))
            }
          />
          <NoteHeader showNoKnown={false} title="Weight Loss Administration">
            <DisplayButton />
            <Button
              text="Add Medication"
              disabled={clinicalNote?.locked}
              icon="new-add-square"
              iconColor="white"
              size="small"
              onClick={() => setShowMedicationSelectionModal(true)}
            />
          </NoteHeader>
          {showMedicationSelectionModal && (
            <MedicationSelectionModal
              isOpen={showMedicationSelectionModal}
              handleClose={() => setShowMedicationSelectionModal(false)}
              onPastMedicationSelect={() => {
                setShowMedicationModal({ open: true });
                setShowMedicationSelectionModal(false);
              }}
              fromWeightLoss={true}
              onExistingMedicationSelect={() => {
                setShowMedicationModal({ existing: true });
                setShowMedicationSelectionModal(false);
              }}
            />
          )}
          {io(showMedicationModal) && showMedicationModal?.existing && (
            <ExistingMedication
              data={medications}
              showModal={showMedicationModal}
              hideModal={() => setShowMedicationModal({ existing: false })}
            />
          )}
          {io(showMedicationModal) && showMedicationModal?.open && (
            <UpsertProviderMedication
              setShowPreviewMedicationFromWhl={setShowPreviewMedicationFromWhl}
              showPreviewMedicationFromWhl={showPreviewMedicationFromWhl}
              fromWeightLoss={true}
              disabled={showPreviewMedicationFromWhl}
              customFormsParams={customFormsParams}
              showModal={showMedicationModal}
              hideModal={() => setShowMedicationModal({ open: false })}
            />
          )}
          <div className="!relative">
            <Box className="grid grid-cols-2 gap-5">
              <Input
                type="number"
                label="Initial weight"
                placeholder="Add initial weight"
                name="initial_weight"
                error={formik?.errors?.initial_weight}
                value={formik?.values?.initial_weight || null}
                onChange={(e) => handleOnChange('initial_weight', e.target.value)}
                rightText="lbs"
              />
              <Input
                type="number"
                label="Goal weight"
                placeholder="Add goal weight"
                name="goal_weight"
                value={formik?.values?.goal_weight}
                error={formik?.errors?.goal_weight || null}
                onChange={(e) => handleOnChange('goal_weight', e.target.value)}
                rightText="lbs"
              />
            </Box>
          </div>
          <ResizableContainer
            onResize={(height) => setResize(height)}
            topContent={<FormsTable items={weightLoss} />}
            bottomContent={
              <NarrativeForm
                key={`weightLossAdministration-narrative-${customFormsParams?.id || patientId}`}
                type="weightLossAdministration"
                loading={loading}
                gridApi={gridApi}
                resize={resize}
                items={weightLossItemFormatter({
                  items: weightLoss,
                  goalWeightData: formik?.values
                })}
                options={options}
              />
            }
            resizableDivClassNames={{
              contentClassName: '!mt-0 !pt-0'
            }}
          />
        </div>
      </TableContextProvider>
    </>
  );
};

export default ProviderWeightLoss;
