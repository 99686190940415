import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { createServices } from 'api/Service';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import { usePracticeCPTCodes } from 'lib/hooks/queries/cptCodes/useCPTCodes';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import AGTable from '../AGTable/AGTable';

import SpecialtySOFHeader from './components/SpecialtySOFHeader';
import {
  CREATE_SERVICES_ERROR_MESSAGE,
  CREATE_SERVICES_QUERY_KEY,
  CREATE_SERVICES_SUCCESS_MESSAGE,
  DEFAULT_COLUMN_DEFS,
  GRID_OPTIONS,
  SPECIALTY_COLUMN_DEFS,
  restructureScheduleOfFees
} from './lib/helpers';

const SpecialtySOF = forwardRef((props, ref) => {
  return (
    <TableContextProvider
      cols={SPECIALTY_COLUMN_DEFS}
      name="services"
      onSelect={props.onSelect}
      pagination>
      <SpecialtySOFTable {...props} ref={ref} />
    </TableContextProvider>
  );
});

const SpecialtySOFTable = forwardRef((props, ref) => {
  const { onEdit } = props;

  const [selectedRows, setSelectedRows] = useState([]);

  const navigate = useNavigate();

  const { gridApi } = useTableContext();

  const { data = {}, isLoading } = usePracticeCPTCodes({
    params: { getAll: true }
  });

  const serviceSpecialties = useMemo(() => restructureScheduleOfFees(iaRa(data?.cpt)), [data]);
  const count = serviceSpecialties?.length || 0;

  const updateStandardCharge = () => {
    if (!gridApi) {
      return;
    }

    const updatedRows = serviceSpecialties.map((row) => ({
      ...row,
      standard_charge: row.total_cost_cents
    }));

    gridApi.setRowData(updatedRows);
  };

  useEffect(() => {
    if (serviceSpecialties) {
      updateStandardCharge();
    }
  }, [isLoading]);

  useEffect(() => {
    if (gridApi && serviceSpecialties.length > 0) {
      gridApi.selectAll();
      const selectedNodes = gridApi.getSelectedNodes();

      setSelectedRows((prev) => {
        const existingCodes = new Set(prev.map((row) => row.procedure_code));
        const newRows = [];

        for (const node of selectedNodes) {
          if (!existingCodes.has(node.data.procedure_code)) {
            newRows.push(node.data);
          }
        }

        return [...prev, ...newRows];
      });
    }
  }, [serviceSpecialties, gridApi]);

  const { mutateAsync: createServicesHandler } = useCustomMutation({
    mutationFn: (params) => createServices(navigate, params),
    invalidateQueryKey: CREATE_SERVICES_QUERY_KEY,
    successMessage: CREATE_SERVICES_SUCCESS_MESSAGE,
    errorMessage: CREATE_SERVICES_ERROR_MESSAGE
  });

  const saveServicesHandler = async () => {
    await createServicesHandler({ services: selectedRows });
  };

  useImperativeHandle(ref, () => ({
    saveServicesHandler,
    selectedRows
  }));

  return (
    <div className="flex h-full w-full grow flex-col gap-4 p-0">
      <SpecialtySOFHeader count={count} onEditServices={onEdit} />
      <AGTable
        data={serviceSpecialties}
        gridOptions={GRID_OPTIONS}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        loading={isLoading}
        rowSelection="multiple"
        getRowId={(row) => row.data.procedure_code}
      />
    </div>
  );
});

export default SpecialtySOF;
