import React from 'react';
import Button from '../../../../../../../shared/Buttons/Button';
import Icon from '../../../../../../../shared/Icon/Icon';
import Modal from '../../../../../../../shared/Modal/Modal';
import Skeleton from '../../../../../../../shared/Skeleton/Skeleton';
import LoadingTranscript from './Transcript/LoadingTranscript';

import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { ia } from '../../../../../../../../lib/helpers/utility';
import MyscribeTranscript from './MyScribeTranscript';

const TranscriptModal = ({ showModal = false, hideModal = () => null }) => {
  const { transcriptData, isUpsertProcessLoading, actionRef, loading } =
    useAmbientListeningContext() || {};

  const audioProcessingFooter = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[120px]" />
  );
  const audioProcessingIcon = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[20px] !rounded-full" />
  );
  const audioProcessingTitle = (
    <Skeleton count={1} height="20px" baseColor="#E6F8FF" className="w-[320px]" />
  );

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={
        <div className="flex items-center gap-[6px] self-center">
          {loading ? audioProcessingIcon : <Icon icon="new-microphone" size={24} color="primary" />}

          {loading ? (
            audioProcessingTitle
          ) : (
            <p className="text-base font-500 text-neutral-800">
              {ia(transcriptData, 1) ? 'Edit transcript' : 'Recording ended successfully!'}
            </p>
          )}
        </div>
      }
      slideFromRight
      className="w-[1020px]"
      footer={
        <div className="flex w-full justify-between">
          <Button
            disabled={isUpsertProcessLoading || loading}
            outlined
            text={loading ? audioProcessingFooter : 'Cancel'}
            onClick={hideModal}
            color="neutral"
          />

          <Button
            disabled={isUpsertProcessLoading || loading}
            loading={isUpsertProcessLoading}
            onClick={() => actionRef?.current?.upsert()}
            text={
              loading
                ? audioProcessingFooter
                : ia(transcriptData, 1)
                  ? 'Update transcript'
                  : 'Confirm & generate transcript'
            }
          />
        </div>
      }>
      {loading ? (
        <LoadingTranscript />
      ) : (
        <MyscribeTranscript setTranscriptData={actionRef?.current?.setTranscriptData} />
      )}
    </Modal>
  );
};

export default TranscriptModal;
