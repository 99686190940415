import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';

const ResizableDiv = ({
  initialHeight = 200,
  minHeight = 200,
  maxHeight = 500,
  onResize,
  resizableDivClassNames = {
    containerDivClassName: null,
    handleResizeClassName: null,
    contentWrapperClassName: null,
    contentClassName: null
  },
  children,
  isResizable = true
}) => {
  const [height, setHeight] = useState(initialHeight);
  const [isDragging, setIsDragging] = useState(false);
  const divRef = useRef(null);

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleTouchStart = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newHeight = height - e.movementY;
      if (newHeight >= minHeight && newHeight <= maxHeight) {
        setHeight(newHeight);
        onResize(newHeight);
      }
    }
  };

  const handleTouchMove = (e) => {
    if (isDragging) {
      const touch = e.touches[0];
      const newHeight = height - (touch.clientY - divRef.current.getBoundingClientRect().top);
      if (newHeight >= minHeight && newHeight <= maxHeight) {
        setHeight(newHeight);
        onResize(newHeight);
      }
    }
  };

  useEffect(() => {
    if (!isResizable) return;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [isDragging, height]);

  return (
    <div className={cs('w-full', resizableDivClassNames?.containerDivClassName)}>
      <div
        className={cs(
          'relative z-10 h-4 cursor-ns-resize border-b-0 border-l-0 border-r-0 border-solid border-neutral-100 bg-white ',
          resizableDivClassNames?.handleResizeClassName
        )}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}>
        <Icon
          icon="new-column-spacing"
          className="absolute top-[-8px] flex h-full w-full cursor-ns-resize items-center justify-center"
        />
      </div>
      <div
        ref={divRef}
        className={cs(
          'w-full overflow-auto bg-white',
          resizableDivClassNames?.contentWrapperClassName
        )}
        style={{ height: `${height}px` }}>
        <div className={cs('mt-2 p-4', resizableDivClassNames?.contentClassName)}>{children}</div>
      </div>
    </div>
  );
};

export default ResizableDiv;
