import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { createExternalInvoices } from 'api/Billing';

import { ia } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { currentPractice } from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';
import { showAlert } from 'components/shared/Alert/Alert';

import { initialSelectedInvoice } from '../lib/initialSelectedInvoice';
import { getInvoicesFromSelectedRows } from '../lib/utils';

import InvoicePrintView from './InvoicePrintView';
import NewInvoice from './NewInvoice';
import NewInvoiceForm from './NewInvoiceForm';

const InvoiceCreate = forwardRef(
  ({ selectedRows, setExternalInvoices = () => {}, setStep = () => {} }, ref) => {
    const practice = useRecoilValue(currentPractice);
    const claims = practice?.display_settings?.claims;
    const navigate = useNavigate();

    const internalInvoices = getInvoicesFromSelectedRows(selectedRows);

    const initialValues = useMemo(
      () => initialSelectedInvoice(internalInvoices),
      [internalInvoices]
    );

    const { mutate: createInvoices } = useCustomMutation({
      mutationFn: (data) => createExternalInvoices(navigate, data),
      successMessage: 'Invoices have been created successfully',
      errorMessage: 'There was an error creating invoices!',
      invalidateQueries: 'external_invoices'
    });

    const invoiceFormik = useFormik({
      initialValues,
      onSubmit: (values) => {
        const finalInvoices = Object.keys(values).map((key) => {
          const finalObj = { ...initialValues[key], ...values[key] };
          return finalObj;
        });

        if (finalInvoices.some((invoice) => invoice?.amount_cents < 0)) {
          showAlert({
            title: 'Attention!',
            message:
              finalInvoices?.length > 1
                ? 'At least one invoice has a negative amount. Invoice amounts must be zero or higher. Please correct any negative values and try again.'
                : 'The invoice amount must be greater than or equal to zero. Please enter a valid amount and try again.',
            color: 'warning'
          });
          return;
        }

        createInvoices(finalInvoices, {
          onSuccess: (externalInvoiceData) => {
            const createdInvoices = externalInvoiceData.invoices;
            setExternalInvoices(createdInvoices);
            setStep(2);
          }
        });
      }
    });

    const onCheckAEOB = (target) => {
      selectedRows.map((row) => {
        invoiceFormik.setFieldValue(`${row.id}.aeob`, target.checked);
      });
    };

    useImperativeHandle(ref, () => ({
      submitForm: invoiceFormik.submitForm,
      onCheckAEOB
    }));

    return (
      <>
        {internalInvoices.map((finalInvoice) => {
          const invoiceDetails = getProceduresTotal([finalInvoice]);

          const hasMany = ia(finalInvoice.internal_invoice_ids, 1);
          const isAEOB = invoiceFormik?.values?.[finalInvoice.id]?.aeob ?? true;

          return (
            <div key={finalInvoice.id}>
              <InvoicePrintView
                practice={practice}
                patient={finalInvoice?.patient}
                createdAt={!hasMany ? finalInvoice?.dateofservice : null}
                content={
                  <NewInvoice invoice={finalInvoice} isAEOB={isAEOB}>
                    <NewInvoiceForm
                      formik={invoiceFormik}
                      invoiceId={finalInvoice.id}
                      initialValues={initialValues}
                      invoiceDetails={invoiceDetails}
                      hasClaims={claims}
                    />
                  </NewInvoice>
                }
              />
            </div>
          );
        })}
      </>
    );
  }
);

export default InvoiceCreate;
