import { ia, iaRa } from 'lib/helpers/utility';

const compareValues = (value1, value2) => {
  if (!value1 || !value2) return false;
  return value1.toString().toLowerCase() === value2.toString().toLowerCase();
};

const findMatchInArrayValues = (filterValues, itemValue) => {
  return filterValues.some((filterItem) => compareValues(filterItem?.value, itemValue));
};

const findMatchInObjectValues = (filterValues, itemValue) => {
  return Object.values(filterValues).some((val) => {
    if (!val) return false;
    if (typeof val === 'object' && val.value) {
      return compareValues(val.value, itemValue);
    }
    return compareValues(val, itemValue);
  });
};

const findMatchingItems = (switchItems, filter) => {
  let matchCount = 0;
  const matchingItems = iaRa(switchItems).filter((item) => {
    let isMatch = false;

    if (ia(filter.values)) {
      isMatch = findMatchInArrayValues(filter.values, item.value);
    } else if (typeof filter.values === 'object') {
      isMatch = findMatchInObjectValues(filter.values, item.value);
    }

    if (isMatch) matchCount++;
    return isMatch;
  });

  return { matchCount, matchingItems };
};

const findItemByPath = (switchItems, pathname) => {
  return iaRa(switchItems).find((item) => item.navigate === pathname) || null;
};

export const checkActiveItem = (switchItems, filters, location) => {
  for (const filter of Object.values(filters)) {
    if (!filter.values) continue;

    const { matchCount, matchingItems } = findMatchingItems(switchItems, filter);

    if (matchCount > 1) return null;
    if (matchCount === 1) return matchingItems[0];
  }

  return findItemByPath(switchItems, location.pathname);
};
