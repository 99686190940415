import React from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteClaimAttachment, uploadClaimAttachment } from 'api/Claim';

import { iaRa } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import Badge from 'components/shared/Badge/Badge';
import Button from 'components/shared/Buttons/Button';
import FileUploader from 'components/shared/FileUploader/FileUploader';
import Modal from 'components/shared/Modal/Modal';
import Select from 'components/shared/Select/Select';

import { attachTypeOptions } from '../lib/attachTypeOptions';

const DocumentUpload = ({ claim, setClaim }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const currentAttachments = iaRa(claim?.attachments);
  const count = currentAttachments.length;
  const hasAttachments = count > 0;
  const claimId = claim?.id;

  if (!claim?.payer?.attachments || !claimId) return null;

  const buttonContent = (
    <div className="flex items-center gap-2">
      <span className="font-medium text-xs leading-[14px]">Attachments</span>
      {hasAttachments && (
        <Badge
          size="medium"
          text={count}
          className="group-hover:text-primary-600 group-hover:bg-primary-100"
          isRounded={false}
        />
      )}
    </div>
  );

  return (
    <div className="flex items-center gap-2">
      <Button
        icon="paper-clip"
        transparent
        size="small"
        text={buttonContent}
        onClick={openModal}
        className="group"
      />

      {isOpen && (
        <DocumentUploadModal
          isOpen={isOpen}
          onClose={closeModal}
          currentAttachments={currentAttachments}
          setClaim={setClaim}
          claim={claim}
        />
      )}
    </div>
  );
};

const DocumentUploadModal = ({ isOpen, onClose, claim, currentAttachments, setClaim }) => {
  const navigate = useNavigate();
  const claim_id = claim?.id;

  const updateClaim = (attachments) => {
    setClaim((prev) => ({ ...prev, attachments, claim_md_synced: false }));
  };

  const { mutate: uploadAttachment } = useCustomMutation({
    mutationFn: (params) => uploadClaimAttachment(navigate, params),
    disableAlert: true,
    onSuccess: (params) => {
      updateClaim([...currentAttachments, params.data]);
    }
  });

  const { mutate: deleteAttachment } = useCustomMutation({
    mutationFn: (params) => deleteClaimAttachment(navigate, params),
    disableAlert: true
  });

  const saveImage = async (data) => {
    await uploadAttachment({ claim_id, handler_id: data.handle, name: data.name });
  };

  const handleDelete = async (handle) => {
    const attachment = currentAttachments.find((_attachment) => _attachment.handler_id === handle);
    await deleteAttachment({ claim_id, id: attachment.id });
    const updatedAttachments = currentAttachments.filter((a) => a.id !== attachment.id);
    updateClaim(updatedAttachments);
  };

  const handlers = currentAttachments.map((attachment) => attachment.handler_id);

  const attachType =
    attachTypeOptions.find((option) => option.value === claim?.attach_type_code) ||
    attachTypeOptions[0];

  const onAttachTypeChange = (option) => {
    setClaim((prev) => ({
      ...prev,
      attach_type_code: option?.value
    }));
  };

  return (
    <Modal isOpen={isOpen} handleClose={onClose} title="Claim Attachments">
      <div className="flex flex-col gap-4">
        <Select
          label="Attach Type"
          name="attach_type_code"
          value={attachType}
          options={attachTypeOptions}
          onChange={onAttachTypeChange}
          isClearable={false}
        />
        <FileUploader
          handlers={handlers}
          onUpload={saveImage}
          onDelete={handleDelete}
          isMultiple
          maxFiles={10}
        />
      </div>
    </Modal>
  );
};

export default DocumentUpload;
