import React from 'react';

const SelectedList = ({ title = null, children }) => {
  return (
    <div className="flex flex-col overflow-hidden pl-4 pt-3">
      <span className="text-base text-neutral-800">{title}</span>
      <div className="mt-2 overflow-y-auto pr-4">{children}</div>
    </div>
  );
};

export default SelectedList;
