import React, { useEffect } from 'react';

import { Honeybadger } from '@honeybadger-io/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { socket } from '../../../../../../../../api/Socket';
import { showAlert } from '../../../../../../../shared/Alert/Alert';
import Button from '../../../../../../../shared/Buttons/Button';
import state, { clinicalNote as clinicalNoteState } from '../../../../../../../state';
import { processTranscriptAudioData } from '../lib/deepgramAIHelpers';
import useTimer from './RecordTimer';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

let mediaRecorder, silenceTimeout, mediaStream;

const Transcript = ({ customId = null, setDisable = () => {} }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { recording, setRecording, setTranscriptData, setShowTranscriptModal, setLoading } =
    useAmbientListeningContext();

  const [_, setSocketHasConnection] = useRecoilState(state.socketHasConnection);

  const startRecording = async (e) => {
    e?.preventDefault();

    try {
      mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setRecording(true);
      setDisable(true);
    } catch (error) {
      showAlert({
        title: 'Microphone Access Required',
        message: 'Please allow microphone access to start recording.',
        color: 'danger'
      });
    }
  };

  const { timer, startTimer, stopTimer, pauseTimer, currentState, currentTime } = useTimer();

  useEffect(() => {
    if (recording) {
      deepGramAudio2text();
      startTimer();
    }

    if (!recording) {
      stopTimer();
      clearTimeout(silenceTimeout);
    }
  }, [recording]);

  useEffect(() => {
    return () => {
      stopMediaRecorder();
      socket.removeAllListeners('transcript');
      setRecording(false);
      setDisable(false);
    };
  }, []);

  useEffect(() => {
    setSocketHasConnection(!!socket?.connected);
  }, [socket?.connected]);

  const handleRecording = async ({ e, action }) => {
    e?.preventDefault();

    if (recording && mediaRecorder?.state !== 'inactive') {
      stopMediaRecorder();
    }

    const lessThan1Seconds = Number(currentTime) <= 1;

    if (socket && socket.connected) {
      if (lessThan1Seconds && action === 'stop') {
        socket.emit(`deepgram-myscribe-${action}`);
        stopTimer();

        showAlert({
          title: 'Recording',
          message: 'Recording must be at least 1 seconds long.',
          color: 'danger'
        });

        socket.removeAllListeners('transcript');
      } else {
        socket.emit(`deepgram-myscribe-${action}`, {
          customId
        });
      }
    }

    if (action === 'stop') {
      if (!lessThan1Seconds) {
        setLoading(true);
        setShowTranscriptModal(true);
      }

      stopTimer();
    } else if (action === 'pause') {
      socket?.removeAllListeners('transcript');
      pauseTimer();
    }

    setRecording(false);
    setDisable(false);
  };

  const stopMediaRecorder = () => {
    mediaRecorder?.stop();
    mediaRecorder?.stream.getTracks().forEach((track) => track.stop());
    mediaStream?.getTracks().forEach((track) => track.stop());
    mediaStream = null;
    mediaRecorder = null;
    setRecording(false);
  };

  const notSupported = () => {
    showAlert({
      title: 'Recording',
      message: 'Browser not supported',
      color: 'danger'
    });

    setRecording(false);
  };

  const deepGramAudio2text = () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        if (!MediaRecorder.isTypeSupported('audio/webm')) {
          const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

          if (isSafari) {
            mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/mp4' });
          } else {
            return notSupported();
          }
        } else {
          mediaRecorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
        }

        if (!socket.connected) {
          socket.connect();
          socket.on('connect', () => {
            socket.emit('deepgram-myscribe-connect');
            mediaRecorder.addEventListener('dataavailable', async (event) => {
              if (event.data.size >= 0 && socket.connected) {
                socket.emit('deepgram-myscribe-packet-sent', {
                  data: event.data,
                  customId
                });
              }
            });
          });
        } else {
          socket.emit('deepgram-myscribe-connect');
          mediaRecorder.addEventListener('dataavailable', async (event) => {
            if (event.data.size >= 0 && socket.connected) {
              socket.emit('deepgram-myscribe-packet-sent', {
                data: event.data,
                customId
              });
            }
          });
        }

        mediaRecorder.start(1000);

        socket.on('transcript', async (response) => {
          processTranscriptAudioData({
            data: response,
            setLoading,
            stopTimer,
            setTranscriptData,
            socket,
            setShowTranscriptModal
          });
        });
      })
      .catch((error) => {
        Honeybadger.notify(
          `There's been an error trying to record. Please try again later. Make sure to use Chrome for best experience. ${error}`
        );
      });
  };

  const RecordButtons = () => {
    if (currentState === 'START' || currentState === 'PAUSE') {
      return (
        <div className="z-30 flex gap-3">
          <Button
            onClick={(e) => handleRecording({ e, action: 'stop' })}
            icon="new-stop-circle"
            tooltip="End recording"
            className="h-[46px] w-[160px] bg-primary-600 !px-[15px] !py-3"
            text="End recording"
          />

          <Button
            icon="timer"
            outlined
            className="h-[46px] border border-solid border-primary-500 bg-white !px-[15px] !py-3 text-primary-600"
            text={<div>{timer}</div>}
          />

          {/* {currentState === 'PAUSE' ? (
            <Button
              onClick={startRecording}
              icon="new-play"
              className="h-[46px] w-[200px] border border-solid border-primary-500 bg-white !px-[15px] !py-3 text-primary-600"
              text={
                <div className="flex items-center gap-1.5">
                  <p className="font-500">Resume</p>
                  <div>{timer}</div>
                </div>
              }
            />
          ) : (
            <Button
              onClick={(e) => handleRecording({ e, action: 'pause' })}
              icon="new-pause"
              outlined
              className="h-[46px] w-[200px] border border-solid border-primary-500 bg-white !px-[15px] !py-3 text-primary-600"
              text={
                <div className="flex w-[150px] items-center justify-between gap-1.5">
                  <p className="font-500">Pause</p>
                  <div>{timer}</div>
                </div>
              }
            />
          )} */}
        </div>
      );
    }

    return (
      <Button
        onClick={(e) => startRecording(e)}
        disabled={clinicalNote?.locked}
        icon="new-microphone"
        className="z-30 h-[46px] w-[160px] !bg-white !px-[15px] !py-3 text-primary-600"
        text="Start recording"
        transparent
      />
    );
  };

  return <RecordButtons />;
};

export default Transcript;
