import React from 'react';
import cs from 'classnames';
import { getClassNamesByPercentage, getPercentage, returnCount } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const ListView = ({ options, constantType, data, total, keyName }) => {
  return (
    <div className="h-full px-4 py-3">
      <div className="flex flex-col gap-4">
        {options?.data?.map((item, index) => (
          <div className="flex justify-between rounded-md bg-primary-10 px-4 py-3" key={index}>
            <div className="flex items-center gap-2">
              <p className="font-sm font-medium text-primary-900">{item?.name}</p>
            </div>
            <div className="flex justify-between gap-2">
              {constantType && data && total && (
                <>
                  <div
                    className={cs(
                      'flex gap-1 rounded-full px-2',
                      getClassNamesByPercentage(constantType, item?.name, data, total, keyName)
                    )}>
                    <p className="tex-sm font-medium">
                      {getPercentage(constantType, item?.name, data, total, keyName).toFixed()}%
                    </p>
                  </div>
                  <p className="text-sm font-400 text-primary-900">
                    {returnCount(constantType, item?.name, data, keyName)}
                  </p>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(ListView);
