module.exports = {
  IDProofing: 'ID Proofing',
  messageQueue: 'Message Queue',
  patient: 'Prescribe',
  patientLockdown: 'Patient Lock Down',
  standard: 'Standard Mode',
  taskMode: 'Task Mode',
  userOptions: 'User Options',
  userWithPatientOptions: 'User With Patient Options',
  utilityMode: 'Utility Mode'
};
