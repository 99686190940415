import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import cs from 'classnames';

export function InvoiceModal({
  modalContent,
  footer,
  invoiceModalVisible,
  onClose = () => {},
  prevButton,
  headButton,
  title
}) {
  const handleInvoiceModalClose = () => {
    onClose();
  };

  return (
    <Modal
      slideFromRight
      isOpen={invoiceModalVisible}
      bodyClassName="!p-0"
      title={title}
      className={cs('bg-white', 'w-9/12')}
      headButton={headButton}
      handleClose={handleInvoiceModalClose}
      footer={footer}
      prevButton={prevButton}>
      {modalContent}
    </Modal>
  );
}
