import { requestApi } from './Api';

export const downloadFile = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/filestack/download',
    params
  });

export const readFile = async (navigate, params, onError) =>
  requestApi({
    navigate,
    url: '/api/filestack/read',
    params,
    onError
  });

export const getSecurity = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filestack/security',
    params
  });
};

export const getFileStackUrl = async (navigate, params = {}) => {
  return requestApi({
    navigate,
    url: '/api/filestack/preview',
    params
  });
};
