import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { createAttachment } from 'api/Attachments';
import { createDocument } from 'api/Documents';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';
import { useImportantDocuments } from 'lib/hooks/queries/useDocuments';

import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';

import getDocumentFilters from '../lib/defaultFilters';
import setMultipleQueriesData from '../lib/setMultipeQueriesData';

const DocumentsHeader = ({
  setViewDocument,
  setShowInstantPacket,
  patient,
  practitioners,
  patientId,
  categories,
  fromPatient,
  setDeleteConfirmation,
  appointmentId,
  isLocked
}) => {
  const navigate = useNavigate();

  const { myPortalPractice } = useParams();

  const { device } = useUIContext();

  const userState = useRecoilValue(state.userState);

  const { filters, setFilters, selectedRows } = useTableContext();

  const selectedItems = selectedRows.map((node) => node.data);

  const queryClient = useQueryClient();

  const { data: importantDocuments } = useImportantDocuments({
    params: { patientId },
    dependencies: [patientId]
  });
  const {
    hipaa,
    insurance = { front: null, back: null },
    id = { front: null, back: null }
  } = importantDocuments || {};

  const openFilestackPicker = async () => {
    if (isLocked) {
      return showAlert({ title: 'Clinical note is locked!', color: 'danger' });
    }
    const filestackClient = await getFilestackClient(navigate);
    const pickerOptions = {
      accept: ['image/*', '.pdf'],
      fromSources: ['local_file_system'],
      maxFiles: 15,
      maxSize: 50 * 1024 * 1024,
      uploadInBackground: false
    };

    pickerOptions.onUploadDone = async (documents) => {
      const params = {
        documents: documents?.filesUploaded,
        patientId,
        myPortalPractice
      };

      const { f_name, l_name } = userState || {};

      let data = null;

      if (appointmentId) {
        data = await createAttachment(navigate, {
          patientId,
          appointmentId,
          documents: documents?.filesUploaded
        });
      } else {
        data = await createDocument(navigate, params);
      }

      if (!data?.documents) {
        return showAlert({ title: 'Failed to upload files!', color: 'danger' });
      }

      data = data?.documents?.map((file) => ({
        ...file,
        category: 'clinical',
        uploader_name: `${f_name || ''} ${l_name || ''}`
      }));

      setMultipleQueriesData(
        queryClient,
        ['documents', appointmentId ? ['attachments', appointmentId.toString()] : null].filter(
          Boolean
        ),
        (old) => ({
          ...old,
          documents: [...data, ...(old?.documents || [])]
        })
      );
    };

    filestackClient.picker(pickerOptions).open();
  };

  const options = [
    {
      label: 'Personal ID',
      onClick: () => setViewDocument({ load: true, handle: id, type: 'id', title: 'Personal ID' }),
      icon: 'new-cards-lg'
    },
    {
      label: 'Insurance Profile',
      onClick: () =>
        setViewDocument({
          load: true,
          handle: insurance,
          type: 'insurance',
          title: 'Insurance Profile'
        }),
      icon: 'new-credit-card'
    },
    {
      label: 'HIPAA Form',
      onClick: () =>
        setViewDocument({ load: true, handle: hipaa, type: 'hipaa', title: 'HIPAA Form' }),
      icon: 'new-shield-tick'
    }
  ];

  return (
    <Header
      title={appointmentId ? 'Attachments' : 'Documents'}
      childrenClassName="whitespace-nowrap py-8">
      <div className="flex items-center gap-2">
        {!fromPatient && (
          <>
            {device === 'desktop' || device === 'laptop' ? (
              options?.map((option) => (
                <Button
                  key={option.label}
                  icon={option.icon}
                  iconSize={16}
                  size="small"
                  text={option.label}
                  type="secondary"
                  className="rounded-md border-0"
                  onClick={option.onClick}
                />
              ))
            ) : (
              <Popover isFixed options={options} />
            )}
          </>
        )}

        {ia(selectedItems) && (
          <Button
            onClick={() => setDeleteConfirmation(true)}
            size="small"
            icon="trash"
            iconColor="white"
            text="Delete"
            color="danger"
          />
        )}

        <DisplayButton />
        <Filter
          menuPortalTarget={document.body}
          category="document-filters"
          filters={filters}
          defaultFilters={getDocumentFilters(practitioners, patient, categories)}
          setFilters={setFilters}
          isAdmin={true}
        />

        <Button
          icon="new-add-square"
          iconSize={16}
          iconColor="white"
          size="small"
          type="primary"
          disabled={isLocked}
          text={appointmentId ? 'Add Attachment' : 'Add Document'}
          onClick={openFilestackPicker}
        />
        {!fromPatient && (
          <Button
            data-qa="send-patient-form-btn"
            icon="new-send-bulk"
            iconColor="white"
            size="small"
            type="primary"
            outlined
            text="Send Patient Forms"
            onClick={() => setShowInstantPacket(true)}
          />
        )}
      </div>
    </Header>
  );
};

export default DocumentsHeader;
