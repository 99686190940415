import React, { useEffect, useState } from 'react';
import { io } from '../../../../../../../lib/helpers/utility';
import { useParams } from 'react-router-dom';
import Face from './Face';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const Note3D = ({ context }) => {
  const { components, setComponents } = useClinicalNoteContext();
  const { formName } = useParams();
  const [formsList, setFormList] = useState({
    face: Face
  });

  useEffect(() => {
    setComponents({
      face: {
        title: 'Face',
        path: 'face'
      }
    });
  }, []);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  if (!io(components) || !CurrentForm) return <Skeleton height="calc(100vh - 214px)" />;

  return <CurrentForm context={context} key={formName} components={components} />;
};

export default Note3D;
