import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { formatPhoneNumber, ia } from '../../../../lib/helpers/utility';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import schedulingState from '../schedulingState';
import './css/ServiceStep.scss';
import Pagination from '../../../shared/Pagination/Pagination';
import { isUndefined } from 'lodash';
import { useServiceCategories } from '../../../../lib/hooks/queries/services/useServiceCategories';
import FilterComponent from './FilterComponent';
export default function ServiceStep() {
  const [services, setServices] = useState([]);
  const [serviceCount, setServiceCount] = useState(0);
  const [filters, setFilters] = useState({
    page: 1,
    limit: 15,
    category: null
  });
  const [checked, setChecked] = useState(false);
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { data } = useServiceCategories({
    params: { practiceId: appointmentData?.practice_id || null }
  });

  useEffect(() => {
    if (appointmentData.practice_id) {
      getServices();
    }
  }, [appointmentData, filters.page, filters.category]);

  useEffect(() => {
    if (checked) {
      getStatus(checked.id, checked.name, checked.time_length);
    }
  }, [checked]);

  const selectedService = (service) => {
    setChecked(service);
    if (checked && service?.id == checked?.id) {
      setChecked(false);
    }
  };

  const getStatus = async (id, name, length) => {
    try {
      const { status, data } = await interimApi(
        '/api/patient/appointment/self_schedule',
        {
          service_id: id,
          service_name: name,
          service_length: length
        },
        navigate
      );
      setAppointmentData(data?.object || {});
      if (status == 200) {
        navigate('/book/date-and-time');
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getServices = async () => {
    const onError = (error) => {
      Honeybadger.notify(
        `file: self_scheduling/steps/ServiceStep, method: getServices - catch, error: ${error ?? 'Theres been an error'
        }`
      );
    };
    const onSuccess = (data) => {
      const loaded_services = data.services;
      const isServicesAvailable = loaded_services.some(
        (service) =>
          service.state === 'active' && service.self_scheduling && ia(service?.practitioner_ids)
      );

      if (isServicesAvailable) {
        setServices(loaded_services);

        !isUndefined(data.serviceCount) && setServiceCount(data.serviceCount);
      } else {
        setServices([]);
      }
    };

    await requestApi({
      url: '/api/practice/services/get',
      onError,
      onSuccess,
      navigate,
      params: {
        offset: 0,
        self_scheduling: true,
        practiceId: appointmentData.practice_id,
        withCount: true,
        filters: { ...filters, category: filters.category?.id }
      }
    });
  };

  if (loading) {
    return <Skeleton width={240} height={80} count={3} />;
  }

  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };
  return (
    <>
      {ia(services) || filters.category ? (
        <div className="min-h-[75vh] w-full flex flex-col ">
          {ia(data?.categories) && (
            <FilterComponent
              categories={data?.categories}
              filters={filters}
              setFilters={setFilters}
            />
          )}
          <div className='ServiceContainer'>
            {services?.map((service, idx) => {
              return (
                <div
                  key={idx}
                  className='ServicesContainer'
                  onClick={() => selectedService(service)}>
                  <div className='ServicesContent'>
                    <p className='font-500 text-lg pb-2 block text-neutral-900 leading-5'>{service?.name} - Service</p>
                    <p>Time: {service?.time_length} minutes</p>
                    {service?.description && <p>Description: {service?.description}</p>}
                  </div>
                  <RadioButton
                    wrapperClassName='w-max'
                    id={service.id}
                    name={service.name}
                    isSelected={checked ? checked.id == service.id : false}
                    onChange={() => selectedService(service)}
                  />
                </div>
              );
            })}
          </div>
          <Pagination
            onPageChange={changePage}
            page={filters.page}
            totalItems={serviceCount}
            perPage={filters.limit}
          />
        </div>
      ) : (
        <div className="w-full">
          Self-scheduling is currently unavailable. To book an appointment, please call us at{' '}
          <p className="underline">{formatPhoneNumber(appointmentData?.practice_phone)}.</p>
        </div>
      )}
    </>
  );
}
