import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPreferences } from '../../../api/Preferences';

export const usePreferences = ({ params, dependencies, options }) => {
  const navigate = useNavigate();
  return useQuery(['preferences', ...dependencies], () => getPreferences(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
