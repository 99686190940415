import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import formatMedicationsNarrative from '../../../../../../../shared/Forms/Custom/lib/formatMedicationsNarrative';
import { sanitize } from 'lib/helpers/sanitize';

const MedicationPreview = ({ itemProps }) => {
  const { currentHpOverviewData } = useClinicalNoteContext();

  const currentMedicationData =
    currentHpOverviewData?.medicationHistory?.response?.medications?.filter((medication) =>
      itemProps?.medication_ids?.includes(medication?.id)
    );

  const narrative = formatMedicationsNarrative(currentMedicationData);

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Medications</div>

      <ul className="!pl-5 !mt-3">
        <li
          className="list-disc text-sm break-normal whitespace-pre-wrap"
          dangerouslySetInnerHTML={sanitize(!!currentHpOverviewData ? narrative : 'N/A')}></li>
      </ul>
    </div>
  );
};

export default MedicationPreview;
