import cs from 'classnames';

import { getPatients } from 'api/Patients';
import { getServices } from 'api/Service';

import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { formatDate } from 'lib/helpers/utility';

import Filter from 'components/shared/Filter/Filter';

export const searchPatients = async (searchTerm) => {
  const patients = await getPatients(() => {}, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    customLabel: (
      <div>
        {p?.fullName && <span>{p.fullName}</span>}
        {p.dob && <span className="pl-1 text-xs text-neutral-500">{formatDate(p.dob)}</span>}
      </div>
    ),
    label: p?.fullName,
    value: p?.id
  }));
};

export const searchServices = async (searchTerm) => {
  const services = await getServices(() => {}, {
    limit: 25,
    filters: { searchTerm },
    forPractice: true,
    offset: 0
  });

  return services.services.map((p) => ({
    label: p?.name,
    value: p?.id
  }));
};

const Filters = ({ buttonClassNames /* todo: remove this prop */ }) => {
  const { filters, setFilters } = useDashboardContext();

  return (
    <Filter
      category="dashboard_calendar"
      filters={filters}
      setFilters={setFilters}
      saveButton
      btnClassName={cs(
        'text-primary-900 bg-white !border-transparent leading-[24px] !py-1',
        buttonClassNames
      )}
      numberOfCols={2}
    />
  );
};

export default Filters;
