import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPrompts } from 'api/MyScribeAi';

export const usePrompts = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['prompts', ...dependencies], () => getPrompts(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
