import React from 'react';

const TerminalTable = ({ children, action }) => {
  return (
    <table className="primary-table">
      <thead>
        <tr>
          <td>ID</td>
          <td>Practice ID</td>
          <td>Name</td>
          <td>Online</td>
          <td>PIN</td>
          <td>Merchant ID</td>
          <td>Vendor ID</td>
          <td>User ID</td>
          <td>Brand</td>
          <td>Kind</td>
          <td>Notes</td>
          <td>Group</td>
          <td>Active</td>
          <td>{action}</td>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default TerminalTable;
