import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Button from '../../../../../shared/Buttons/Button';
import PatientNeeds from '../../CurrentNote/TypeNotes/IntakeNote/components/PatientNeeds';
import ProblemManifestationDefinition from '../../CurrentNote/TypeNotes/IntakeNote/components/ProblemManifestationDefinition';
import Goals from '../../CurrentNote/TypeNotes/ProgressNote/components/Goals';
import ICDCodes from '../../CurrentNote/TypeNotes/SOAPNote/components/ICDCodes';
import Box from '../../shared/Box';
import AdditionalComments from '../components/edit/AdditionalComments';
import TreatmentFrequency from '../components/edit/TreatmentFrequency';
import TreatmentTeam from '../components/edit/TreatmentTeam/TreatmentTeam';

const TPEdit = ({ view, origin }) => {
  const formRef = useRef();
  const { setSelectedTreatmentPlan, clinicalNote } = useClinicalNoteContext();
  const { type } = useParams();

  useEffect(() => {
    if (origin === 'intake' || origin === 'progress') loadDataFromCN();
  }, []);

  const loadDataFromCN = () => {
    setSelectedTreatmentPlan((prevSelectedTreatmentPlan) => ({
      ...prevSelectedTreatmentPlan,
      icd10: clinicalNote?.icd_10_codes,
      patient_needs: clinicalNote?.present_illness,
      problem_manifestation_definition: clinicalNote?.other_complaints,
      goals: clinicalNote?.goals
    }));
  };

  return (
    <div>
      {view === 'create' && (
        <div className="flex justify-end">
          <Button
            type="secondary"
            color="neutral"
            onClick={loadDataFromCN}
            text="Load data from clinical note"
            data-qa="load-data-from-clinical-note-btn"
          />
        </div>
      )}
      <div className="grid gap-4 !pb-5 !pt-3" ref={formRef}>
        <ICDCodes box={false} isTPlan showOldUI={true} />
        <PatientNeeds isTPlan />
        <ProblemManifestationDefinition isTPlan />
        <Box className="bg-white !pr-2">
          <Goals isNarrative={false} title="Goals" isTPlan section={type} isAdvancedForm />
        </Box>
        <TreatmentTeam />
        <AdditionalComments />
        <TreatmentFrequency />
      </div>
    </div>
  );
};

export default TPEdit;
