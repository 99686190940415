import React, { useState, useEffect } from 'react';

const DateHeader = (props) => {
  const [sort, setSort] = useState(null);
  const [filterActive, setFilterActive] = useState(false);

  useEffect(() => {
    setSort(props.column.getSort());
    setFilterActive(props.column.isFilterActive());

    props.column.addEventListener('sortChanged', onSortChanged);
    props.column.addEventListener('filterChanged', onFilterChanged);

    return () => {
      props.column.removeEventListener('sortChanged', onSortChanged);
      props.column.removeEventListener('filterChanged', onFilterChanged);
    };
  }, [props.column]);

  const onSortChanged = () => {
    setSort(props.column.getSort());
  };

  const onFilterChanged = () => {
    setFilterActive(props.column.isFilterActive());
  };

  const onSortRequested = (order, event) => {
    props.setSort(order, event.shiftKey);
  };

  const getNextSortOrder = () => {
    if (sort === 'asc') return null;
    if (sort === 'desc') return null;
    return 'asc';
  };

  const isAnyColumnSorted = props.columnApi.getColumnState().some((colState) => colState.sort);

  const onFilterClicked = (event) => {
    event.stopPropagation(); // Prevents sorting when clicking the filter icon
    props.showColumnMenu(event.currentTarget);
  };

  return (
    <div
      className="custom-header group relative flex w-full cursor-pointer items-center justify-between"
      onClick={(event) => onSortRequested(getNextSortOrder(), event)}>
      <div className="flex">
        <span className="mr-1">{props.displayName}</span>
        {sort === 'asc' && <i className="ag-icon ag-icon-asc"></i>}
        {sort === 'desc' && <i className="ag-icon ag-icon-desc"></i>}
        {!isAnyColumnSorted && <i className="ag-icon ag-icon-desc"></i>}
      </div>
      <div className="self-end">
        <i
          className={`ag-icon ag-icon-menu ${filterActive ? 'filter-active' : ''} ml-2 flex items-center justify-center rounded p-3 opacity-0 transition-opacity duration-200 hover:bg-gray-200 group-hover:opacity-100`}
          onClick={onFilterClicked}></i>
      </div>
    </div>
  );
};

export default DateHeader;
