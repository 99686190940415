import { requestApi } from './Api';

const getPatientDocuments = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/get', params });

const getImportantDocuments = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/get_important', params });

const shareDocumentWithPatient = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/share_with_patient', params });

const deleteDocument = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/delete', params });

const updateDocument = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/update', params });

const createDocument = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/create', params });

const batchDeleteDocuments = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/batch_delete', params });

const getDocumentsCategories = async (navigate, params) =>
  requestApi({ navigate, url: '/api/practice/charts/documents/categories', params });

export {
  createDocument,
  deleteDocument,
  getImportantDocuments,
  getPatientDocuments,
  shareDocumentWithPatient,
  updateDocument,
  batchDeleteDocuments,
  getDocumentsCategories
};
