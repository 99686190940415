import { io } from 'socket.io-client';

// "undefined" means the URL will be computed from the `window.location` object
// const URL = process.env.NODE_ENV === 'production' ? undefined : 'http://localhost:8080';
let splitApiUrl = 'api.myriad.health';
const apiUrl = process.env.ENV !== 'local' ? process.env.REACT_APP_BASE_API_URL : 'localhost';
if (process.env.ENV !== 'local' && apiUrl.includes('://')) {
  splitApiUrl = apiUrl.split('://')[1];
}

if (!splitApiUrl.includes('api') && process.env.ENV !== 'local') {
  splitApiUrl = 'api.' + splitApiUrl;
}

const secureProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';

// const webSocketApiUrl = `${secureProtocol}://${splitApiUrl}/socket.io`;
const webSocketApiUrl = `${secureProtocol}://${splitApiUrl}`;

const URL = process.env.ENV !== 'local' ? webSocketApiUrl : 'ws://localhost:8080';
// const URL = 'ws://localhost:8080';
export const socket = io(URL, {
  autoConnect: false,
  withCredentials: true,
  transports: ['websocket', 'polling']
});
