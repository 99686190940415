import React from 'react';

import { ia } from 'lib/helpers/utility';

import Header from 'components/shared/shared/Header';

import AOEQuestion from './AOEQuestion';

const AOEQuestionsList = ({ questions, testIndex }) => {
  if (!ia(questions)) return null;

  return (
    <div className="mt-4">
      <Header
        label="Questions"
        labelClassName="relative !text-base font-600 text-primary-900 first-letter:capitalize before:absolute before:bottom-0 before:h-[1px] before:w-6 before:bg-primary-500 before:duration-200 before:ease-linear before:content-['']"
      />
      <div className="grid gap-x-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 xxl:grid-cols-3">
        {questions.map((question, index) => (
          <AOEQuestion
            key={`${question.testID}-test-question-${question.questionID}`}
            testIndex={testIndex}
            index={index}
            question={question}
          />
        ))}
      </div>
    </div>
  );
};

export default AOEQuestionsList;
