import { stripHtmlTags } from 'lib/helpers/utility';
import * as Yup from 'yup';

export const FormFunctionValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  description: Yup.string()
    .required('Description is required')
    .test('is-not-empty', 'Description is required', (value) => {
      const strippedContent = stripHtmlTags(value);
      return strippedContent?.trim()?.length > 0;
    })
});

export const getInitialData = (data = null) => {
  return {
    ...(data?.id && { id: data?.id }),
    name: data?.name ?? null,
    description: data?.description ?? null
  };
};
