import moment from 'moment-timezone';

import { ia } from 'lib/helpers/utility';

const formatHintDate = (date, timezone, showTime) => {
  const format = showTime ? 'MM/DD/YYYY h:mm A' : 'MM/DD/YYYY';
  return moment(date).tz(timezone, false).format(format);
};

export const formatHint = ({ value, timezone, isTime, isTimeRange, isSeconds }) => {
  if (!value) return null;

  if (ia(value)) {
    const startDate = formatHintDate(value[0], timezone, isTimeRange);
    const endDate = formatHintDate(value[1], timezone, isTimeRange);
    return `Local date: ${startDate} - ${endDate}`;
  }

  return `Local date: ${moment(value)
    .tz(timezone, false)
    .format(isTime ? `MM/DD/YYYY h:mm${isSeconds ? ':ss' : ''} A` : 'MM/DD/YYYY')}`;
};
