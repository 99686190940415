export const sides = {
  front: {
    position: [0, 0, 7],
    rotation: [0, 0, 0],
    nextSide: 'left',
    name: 'Front View'
  },
  left: {
    position: [-7, 0, 0],
    rotation: [0, -Math.PI / 2, 0],
    nextSide: 'right',
    name: 'Left View'
  },
  right: {
    position: [7, 0, 0],
    rotation: [0, Math.PI / 2, 0],
    nextSide: null,
    name: 'Right View'
  }
};

export const colors = [
  '#475569',
  '#dc2626',
  '#ea580c',
  '#d97706',
  '#ca8a04',
  '#65a30d',
  '#16a34a',
  '#059669',
  '#0d9488',
  '#0891b2',
  '#0284c7',
  '#2563eb',
  '#4f46e5',
  '#7c3aed',
  '#9333ea',
  '#c026d3',
  '#db2777',
  '#e11d48',
  '#0f172a',
  '#7f1d1d',
  '#881337'
];


