import { HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import { interimApi } from "api/InterimApi";

export const handleCPTSearch = async (searchTerm, codes = [], navigate) => {
    if (searchTerm) {
        try {
            const params = {
                searchTerm: searchTerm,
                limit: 100
            };
            let res = await interimApi('/api/codes/cpt/get', params, navigate);
            if (res.data.cpt) {
                return res.data.cpt.map((item) => {
                    const label = item.name ? `${item.code} - ${item.name}` : `${item.code}`;
                    return {
                        value: item.code,
                        label: label
                    };
                });
            } else {
                HoneybadgerErrorBoundary.notify(`There's been an unexpected error, please try again later.`);
            }
        } catch (err) {
            Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
        }
    } else if (codes.length > 0) {
        return codes.map((item) => {
            return { value: item, label: item };
        });
    }
};

export const handleICD10Search = async (searchTerm, codes = [], navigate) => {
    if (searchTerm) {
        try {
            const params = {
                searchTerm: searchTerm,
                limit: 100
            };
            let res = await interimApi('/api/codes/icd10/get', params, navigate);
            if (res.data.icd10) {
                return res.data.icd10.map((item) => {
                    return { value: item.code, label: `${item.code} - ${item.name}` };
                });
            } else {
                Honeybadger.notify(`There's been an unexpected error, please try again later.`);
            }
        } catch (err) {
            Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
        }
    } else if (codes.length > 0) {
        return codes.map((item) => {
            return { value: item, label: item };
        });
    }
};

export const handleModifierSearch = async (searchTerm, codes = [], navigate) => {
    if (searchTerm) {
        try {
            const params = {
                searchTerm: searchTerm,
                limit: 100
            };
            let res = await interimApi('/api/codes/modifiers/get', params, navigate);
            if (res.data.modifiers) {
                return res.data.modifiers.map((item) => {
                    return { value: item.code, label: `${item.code} - ${item.name}` };
                });
            } else {
                Honeybadger.notify(`There's been an unexpected error, please try again later`);
            }
        } catch (err) {
            Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
        }
    } else if (codes.length > 0) {
        return codes.map((item) => {
            return { value: item, label: item };
        });
    }
};