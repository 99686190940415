import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import cs from 'classnames';
import { groupBy } from 'lodash';
import { createPortal } from 'react-dom';
import { Popover as HeadlessPopover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ia, pm, snakeToTitleCase, spaceToKebabCase } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';
import { calculatePopoverPosition } from './lib/position';
import { usePopoverContext } from 'components/shared/Popovers/Popover/PopoverContext';
import state from 'components/state';
import { useRecoilValue } from 'recoil';

const Popover = ({
  position = 'right', // by default is 'right', means in the right position where the button is
  isFixed = false,
  options = [],
  icon = 'new-context-menu-dots',
  iconSize = 16,
  iconClassName = '',
  iconRight = '',
  iconRightColor,
  iconRightSize = 16,
  iconsShade = 500,
  iconRightClassName = '',
  optionIconSize = 16,
  optionIconColor = 'primary',
  optionActiveIcon = 'new-check', // if options is nested the icon will not be shown
  optionsGroupBy,
  optionGroupLabelClassName = '',
  text = '',
  loading = false,
  disabled,
  className = '',
  buttonClassName = '',
  buttonTheme = null,
  textClassName = '',
  panelClassName = '',
  activeClassNames = {},
  isDropdown = false,
  isSeparator = false,
  isBorder = false,
  rerender = '',
  id = null,
  onScroll,
  panelRef: outsidePanelRef = null,
  children
}) => {
  const buttonRef = useRef(null);
  let panelRef = useRef(null);
  const panElement = useRef(document.createElement('popover'));
  const timeoutRef = useRef(null);
  const [panelFixedPosition, setPanelFixedPosition] = useState({});
  const [hoveredIndexes, setHoveredIndexes] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [panelWidth, setPanelWidth] = useState(140); // default width, this is used only to calculate the position of panel
  const permissions = useRecoilValue(state.permissions);
  const popoverContext = usePopoverContext();

  if (outsidePanelRef) {
    panelRef = outsidePanelRef;
  }

  const updatePopoverPosition = useCallback(() => {
    if (!isFixed || !buttonRef.current || !panelRef?.current) return;

    const buttonRect = buttonRef.current.getBoundingClientRect();
    const panelRect = panelRef.current.getBoundingClientRect();

    const newPosition = calculatePopoverPosition({
      buttonRect,
      panelRect,
      panelWidth,
      position
    });

    setPanelFixedPosition(newPosition);
  }, [isFixed, position, panelWidth, rerender]);

  useLayoutEffect(() => {
    const root = document.getElementById('root');
    let panContainer = document.getElementById('popovers');

    if (!panContainer && root) {
      panContainer = document.createElement('div');
      panContainer.id = 'popovers';
      root.appendChild(panContainer);
    }

    if (isFixed && panContainer) {
      panContainer.appendChild(panElement.current);

      const handleResizeOrScroll = (event) => {
        // Check if the scroll event is from within the popover
        if (
          panelRef?.current &&
          event?.target instanceof Node &&
          panelRef?.current.contains(event.target)
        )
          return;

        updatePopoverPosition();
      };

      window.addEventListener('resize', handleResizeOrScroll);
      window.addEventListener('scroll', handleResizeOrScroll, true);

      return () => {
        window.removeEventListener('resize', handleResizeOrScroll);
        window.removeEventListener('scroll', handleResizeOrScroll, true);

        if (panElement.current.parentNode === panContainer) {
          panContainer.removeChild(panElement.current);
        }
      };
    }
  }, [isFixed, updatePopoverPosition]);

  useLayoutEffect(() => {
    if (panelRef?.current) {
      const width = panelRef?.current.getBoundingClientRect().width;
      setPanelWidth(width);
      updatePopoverPosition(); // Ensure position is recalculated when the popover opens
    }
  }, [updatePopoverPosition, isOpen]);

  const handleMouseEnter = (level, index) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    setHoveredIndexes((prev) => {
      if (prev[level] !== index) {
        return { ...prev, [level]: index };
      }
      return prev;
    });
  };

  const handleMouseLeave = (level) => {
    // if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      setHoveredIndexes((prev) => ({
        ...prev,
        [level]: null
      }));
    }, 500);
  };

  const handleOnClick = (option, handleClose, event) => {
    if (option.onClick) {
      event.stopPropagation();
      option.onClick(popoverContext?.context);
      handleClose();
    }
  };

  const handlePopoverClick = () => {
    setIsOpen(!isOpen);
    setHoveredIndexes({});
  };

  const renderOptions = (options, level = 1, handleClose) => {
    if (level === 1 && optionsGroupBy) {
      const groupedOptions = groupBy(options, (option) => option[optionsGroupBy] || 'others');

      return Object.entries(groupedOptions).map(([groupName, groupOptions], groupIndex) => (
        <div className="flex flex-col" key={groupIndex}>
          <span
            className={cs(
              'px-4 py-1 text-xs font-500 leading-[14px] text-primary-500',
              optionGroupLabelClassName
            )}>
            {snakeToTitleCase(groupName)}
          </span>
          <div className="flex flex-col gap-1">
            {groupOptions.map((option, index) => renderOption(option, index, level, handleClose))}
          </div>
        </div>
      ));
    } else {
      return options.map((option, index) => renderOption(option, index, level, handleClose));
    }
  };

  const renderOption = (option, index, level, handleClose) => {
    if (option?.permission) {
      if (
        option?.permission?.isAny &&
        !pm(permissions, option?.permission?.requiredPermissions, 'or')
      ) {
        return null;
      }
      if (!pm(permissions, option?.permission?.requiredPermissions)) {
        return null;
      }
    }
    const label = option.label || option.title; //title is used for the old popover options
    return (
      <div
        key={index}
        className={cs(
          'relative flex items-center justify-between gap-x-2 py-[6px] !pl-4 hover:bg-primary-50',
          option.onClick && !ia(option.children) ? 'cursor-pointer pr-4' : 'cursor-default pr-2',
          label === text && 'bg-primary-50 !pr-2'
        )}
        data-qa={`popover-option-${spaceToKebabCase(label)}`}
        onMouseEnter={() => option.children && !option.disabled && handleMouseEnter(level, index)}
        onMouseLeave={() => option.children && !option.disabled && handleMouseLeave(level)}
        onClick={(event) => handleOnClick(option, handleClose, event)}>
        {option.disabled && (
          <div className="pointer-events-none absolute inset-0 z-[100] bg-white opacity-40"></div>
        )}
        <div className={cs('flex grow items-center gap-x-2', option.className)}>
          {option.loading ? (
            <Loader
              outlined
              className={`text-${optionIconColor}-500`}
              type={hoveredIndexes[level] === index ? 'secondary' : 'primary'}
            />
          ) : typeof option.icon === 'object' ? (
            option.icon
          ) : (
            option.icon && (
              <Icon
                icon={option.icon}
                size={optionIconSize}
                color={option.color || optionIconColor}
                shade={iconsShade}
                stroke={option.stroke}
              />
            )
          )}
          <span className="grow select-none whitespace-nowrap text-sm font-400 text-primary-900">
            {label}
          </span>
        </div>
        {typeof option.component === 'object' ? (
          option.component
        ) : option.children ? (
          <Icon
            icon="new-chevron-right"
            size={optionIconSize}
            className="ml-auto"
            color={optionIconColor}
            shade={iconsShade}
            stroke
          />
        ) : (
          optionActiveIcon &&
          label === text && (
            <Icon
              icon={optionActiveIcon}
              size={19}
              className="ml-auto"
              color={optionIconColor}
              shade={iconsShade}
              stroke
            />
          )
        )}
        {option.children && hoveredIndexes[level] === index && (
          <div
            className={cs(
              'absolute -top-[14px] z-50 mt-[6px] grid min-w-[120px] rounded-md bg-white py-2 shadow-[0px_2px_16px_0px_rgba(0,79,107,0.2)]',
              option.position === 'left'
                ? 'right-full'
                : option.position === 'right'
                  ? 'left-full'
                  : position === 'left'
                    ? 'right-full'
                    : 'left-full'
            )}>
            {renderOptions(option.children, level + 1, handleClose)}
          </div>
        )}
      </div>
    );
  };

  const Panel = ({ handleClose }) => (
    <PopoverPanel
      ref={panelRef}
      onScroll={onScroll}
      style={isFixed ? panelFixedPosition : {}}
      className={cs(
        'z-[100] grid min-w-[120px] gap-y-2 rounded-md bg-white py-2 shadow-[0px_2px_16px_0px_rgba(0,79,107,0.2)]',
        panelClassName,
        !isFixed && 'absolute mt-[6px]',
        !isFixed && position === 'left' ? 'right-0' : 'left-0'
      )}>
      {typeof children === 'function'
        ? children({ closePopover: handleClose })
        : children
          ? children
          : renderOptions(options, 1, handleClose)}
    </PopoverPanel>
  );

  return (
    <HeadlessPopover
      id={id}
      className={cs('relative w-fit', className)}
      open={isOpen}
      onClose={() => setIsOpen(false)}>
      {({ open, close }) => (
        <>
          <PopoverButton
            ref={buttonRef}
            onClick={handlePopoverClick}
            data-qa="popover-button"
            className={cs(
              'flex min-h-[34px] min-w-[34px] cursor-pointer items-center justify-center rounded-md p-0 font-500',
              (open && !buttonTheme) || (open && buttonTheme === 'white')
                ? 'bg-primary-700 text-white'
                : `bg-${buttonTheme}-200 text-${buttonTheme}-600`,
              !open && !buttonTheme && 'bg-transparent text-neutral-800 hover:!bg-neutral-50',
              !open &&
                buttonTheme !== 'white' &&
                buttonTheme !== 'transparent' &&
                `text-${buttonTheme}-500 bg-${buttonTheme}-50`,
              !open && buttonTheme === 'white' && 'bg-white',
              text && !isSeparator && 'pl-2 pr-4',
              (iconRight || isDropdown) && 'pl-4',
              isSeparator && 'grid grid-flow-col pl-[14px] pr-[8px]',
              isBorder && `border border-solid border-${buttonTheme}-500`,
              buttonClassName,
              activeClassNames && open && activeClassNames?.button
            )}
            disabled={disabled}>
            {loading ? (
              <Loader color={buttonTheme} shade={open ? 200 : 50} className="mr-1" />
            ) : typeof icon === 'object' ? (
              <div className="mr-[6px]">{icon}</div>
            ) : (
              icon && (
                <Icon
                  icon={icon}
                  size={iconSize}
                  color={
                    disabled
                      ? 'neutral'
                      : open && !buttonTheme
                        ? 'white'
                        : open && buttonTheme === 'transparent'
                          ? 'primary'
                          : !open && buttonTheme === 'white'
                            ? 'primary'
                            : (buttonTheme ?? (open ? 'white' : ''))
                  }
                  shade={iconsShade}
                  className={cs(
                    iconClassName,
                    icon !== 'new-context-menu-dots' && text && 'mr-[6px]',
                    disabled && '!cursor-not-allowed'
                  )}
                />
              )
            )}
            {typeof text === 'string' ? (
              <span
                className={cs(
                  'text-sm first-letter:capitalize',
                  open && !buttonTheme && 'text-white',
                  !open && buttonTheme === 'white' && 'text-primary-600',
                  !open && buttonTheme === 'transparent' && 'text-neutral-600',
                  open && buttonTheme === 'transparent' && 'text-primary-500',
                  textClassName,
                  activeClassNames && open && activeClassNames?.text
                )}
                title={text}>
                {text}
              </span>
            ) : (
              text && text
            )}
            {iconRight && !isDropdown && (
              <Icon
                icon={iconRight}
                size={iconRightSize}
                color={
                  buttonTheme ??
                  (activeClassNames?.icon && open ? activeClassNames?.icon : open ? 'white' : '')
                }
                shade={iconsShade}
                className={iconRightClassName}
              />
            )}
            {isSeparator && (
              <hr
                className={`block h-full w-[1px] border-none bg-${buttonTheme}-500 ml-[14px] mr-[2px]`}
              />
            )}
            {isDropdown && (
              <Icon
                icon="new-chevron-down"
                size={iconRightSize}
                color={
                  activeClassNames?.icon && open
                    ? activeClassNames?.icon
                    : iconRightColor
                      ? iconRightColor
                      : !open && buttonTheme === 'white'
                        ? 'primary'
                        : open && buttonTheme === 'transparent'
                          ? 'primary'
                          : (buttonTheme ?? (open ? 'white' : ''))
                }
                shade={iconsShade}
                className={cs('ml-1 duration-150', open && 'rotate-180')}
              />
            )}
          </PopoverButton>
          {open &&
            (isFixed ? (
              createPortal(<Panel handleClose={close} />, panElement.current)
            ) : (
              <Panel handleClose={close} />
            ))}
        </>
      )}
    </HeadlessPopover>
  );
};

export default Popover;
