import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getRefLabs } from 'api/LabSoftOrder';

export const useReferenceLabs = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['refLabs', ...dependencies], () => getRefLabs(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: 3,
    ...options
  });
};
