import React from 'react';

const Highlight = ({ text, highlight }) => {
  if (!highlight) return text;
  if (!text) return null;

  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="!bg-warning-100">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

export default Highlight;
