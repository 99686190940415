import React, { useEffect, useState } from 'react';
import { requestApi } from 'api/Api';
import { HmacSHA256 } from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { datadogRum } from '@datadog/browser-rum';
import { Honeybadger } from '@honeybadger-io/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { hasBeenAuthChecked, permissions as userPermissions, userState } from '../state';
import { currentPractice, ownerPractices, ownerSelectedLocation } from '../practice/practiceState';
import Modal from '../shared/Modal/Modal';
import NotFound from 'components/practice/Dashboard/components/NotFound';

const AutoLogout = () => {
  const [user, setUser] = useRecoilState(userState);
  const permissions = useRecoilValue(userPermissions);
  const [practice, setPractice] = useRecoilState(currentPractice);
  const [remaining, setRemaining] = useState(5 * 60);
  const [showModal, setShowModal] = useState(false);
  const setHasBeenAuthChecked = useSetRecoilState(hasBeenAuthChecked);
  const setOwnerSelectedLocation = useSetRecoilState(ownerSelectedLocation);
  const setOwnerPractices = useSetRecoilState(ownerPractices);
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.user_id && practice?.id) {
      const hmac = HmacSHA256(user?.user_id.toString(), process.env.INTERCOM_SECRET_KEY).toString();
      window.Intercom('boot', {
        app_id: process.env.ENV === 'production' ? 't04zjjzz' : 'nn7w439t',
        email: user?.email,
        user_id: user?.user_id,
        created_at: user?.created_at,
        hide_default_launcher: practice?.id ? false : true,
        user_hash: hmac
      });
    } else {
      window.Intercom('boot', {
        app_id: process.env.ENV === 'production' ? 't04zjjzz' : 'nn7w439t',
        hide_default_launcher: true
      });
    }
    if (permissions.is_admin && permissions.practice_id) {
      window.Intercom('shutdown');
    }
  }, [user, practice]);

  const logout = async () => {
    try {
      let logoutRes = null;
      try {
        logoutRes = await requestApi({ url: '/api/user/logout', navigate });
      } catch (e) {
        console.error(e);
      }

      sessionStorage.removeItem('myriad-session-reference-id');

      setUser({
        user_id: '',
        f_name: '',
        l_name: '',
        email: '',
        state: '',
        kind: '',
        logged_in: false
      });

      datadogRum.clearUser();

      setOwnerPractices([]);
      setOwnerSelectedLocation(null);
      setHasBeenAuthChecked({ checked: false, isImpersonate: false });
      setPractice({ id: null, name: '', email: '', timezone: '', display_settings: null });

      const isKiosk = !!localStorage.getItem('myriadIsKiosk');
      window.localStorage.clear();
      window.Intercom('shutdown');
      localStorage.setItem('logout-event', `logout-${new Date().toISOString()}`);

      if (isKiosk) {
        localStorage.setItem('myriadIsKiosk', true);
        navigate('/kiosk/idle');
        return;
      }

      if (logoutRes?.redirect) {
        navigate(logoutRes?.redirect);
      } else {
        navigate('/login', { state: { idle: true } });
      }
    } catch (error) {
      window.localStorage.clear();
      Honeybadger.notify(
        `file: AutoLogout, method: logout - catch, error: ${error ?? 'Theres been an error'}`
      );
    }
  };

  const { reset } = useIdleTimer({
    timeout: 10 * 60 * 1000,
    onIdle: () => {
      setShowModal(true);
      setRemaining(5 * 60); // Start the countdown from 5 minutes when the modal is shown
    },
    onActive: () => {
      setShowModal(false);
      reset();
    },
    onAction: reset,
    debounce: 500,
    crossTab: {
      type: 'localStorage',
      emitOnAllTabs: true
    }
  });

  useEffect(() => {
    let interval;
    if (showModal) {
      interval = setInterval(() => {
        setRemaining((prevRemaining) => {
          if (prevRemaining <= 1) {
            clearInterval(interval);
            logout();
            return 0;
          }
          return prevRemaining - 1;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [showModal]);

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'logout-event' && user.logged_in) {
        logout();
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [user.logged_in]);

  if (!user?.logged_in) return null;

  return (
    <Modal
      handleOpen={showModal}
      disableHeader={true}
      shouldCloseOnOverlayClick={false}
      overlayClassName="z-[300]"
      isFooter={false}>
      <div className="my-10">
        <NotFound
          icon="new-automatization"
          title="Are you still there?"
          description={`You will be logged out in ${Math.floor(remaining / 60)}:${
            remaining % 60 < 10 ? `0${remaining % 60}` : remaining % 60
          } minutes due to inactivity.`}
          className="pt-0"
        />
      </div>
    </Modal>
  );
};
export default AutoLogout;
