import React from 'react';

import { useRecoilValue } from 'recoil';

import { iaRa, isJSON } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';
import { aiGeneratingState, myScribeAutoMapCurrentActivePromptState } from 'components/state';

import { convertClinicalNoteToHTML } from '../../../../lib/helpers';

import AIPrompt from './AIPrompt';
import UserPrompt from './UserPrompt';

const ThinkingText = () => {
  return <span className="text-gray-500 font-bold animate-pulse">Generating...</span>;
};

const PromptMessages = () => {
  const currentActivePrompt = useRecoilValue(myScribeAutoMapCurrentActivePromptState);

  const generating = useRecoilValue(aiGeneratingState);

  return (
    <div className="mx-auto max-w-[760px] overflow-y-auto px-4 pt-4">
      {iaRa(currentActivePrompt)?.map((prompt) => {
        if (prompt?.agent === 'ai') {
          return (
            <AIPrompt
              key={prompt.id}
              message={
                isJSON(prompt.message)
                  ? convertClinicalNoteToHTML(JSON.parse(prompt.message))
                  : prompt.message
              }
            />
          );
        } else if (prompt?.agent === 'user') {
          return (
            <>
              <UserPrompt key={prompt?.id} message={prompt?.message} />
              {generating && (
                <div className="flex mb-10 mt-2 items-center">
                  <div
                    className="mr-2 flex items-center justify-center rounded-[7px] border-solid border-primary-100 bg-white"
                    style={{
                      width: 36,
                      height: 36,
                      minWidth: 36,
                      minHeight: 36
                    }}>
                    <Icon icon="stars-ai" />
                  </div>
                  <ThinkingText />
                </div>
              )}
            </>
          );
        }
        return null;
      })}
    </div>
  );
};

export default PromptMessages;
