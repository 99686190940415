import React from 'react';
import cs from 'classnames';
import { sizes, colors, padding } from './dotTypes';

/**
 * @param {Object} props
 * @param {keyof sizes} props.size
 * @param {keyof colors} props.color
 * @param {string} [props.className]
 */

const Dot = ({ size = 'md', color = 'primary', className }) => {
  return (
    <div
      className={cs(
        'flex items-center justify-center rounded-full bg-white',
        padding[size],
        className
      )}>
      <div className={cs('rounded-full', sizes[size], colors[color])} />
    </div>
  );
};

export default Dot;
