import { useEffect, useImperativeHandle, useMemo, useState } from 'react';

import { useFormikContext } from 'formik';

import { ia, iaRa, io } from 'lib/helpers/utility';
import { useMedicalNecessityCheck } from 'lib/hooks/queries/LabSoftOrder/useMedicalNecessityCheck';

// import Checkbox from 'components/shared/Checkbox/Checkbox';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Header from 'components/shared/shared/Header';

const DetailRow = ({ label, value }) => {
  return (
    <>
      <p className="col-span-2 font-500 text-sm">{label}</p>
      <p className="col-span-2 font-500 text-sm">{value || '-'}</p>
    </>
  );
};

const MedicalNecessityConfirm = ({ actionRef }) => {
  const [open, setOpen] = useState(false);
  const { submitForm, setValues, validateForm, values, isSubmitting } = useFormikContext();

  const testIDs = useMemo(() => values.tests.map((test) => test.testID), [values.tests]);
  const ICDCodes = useMemo(() => values.diags.map((diag) => diag.value), [values.diags]);
  const patientID = useMemo(() => values.patientId, [values.patientId]);

  useImperativeHandle(
    actionRef,
    () => ({
      onSubmit: async () => {
        const errors = await validateForm();
        if (io(errors)) {
          /*
           * Doesn't actually submit the form.
           * Used to add the errors to the respective fields,
           * and set them as touched in one go.
           */
          await submitForm();
        } else {
          setOpen(true);
        }
      }
    }),
    [values, validateForm]
  );

  const {
    data: RBOData,
    isFetching,
    isFetched
  } = useMedicalNecessityCheck({
    params: {
      patientID,
      testIDs,
      ICDCodes
    },
    dependencies: [patientID, testIDs, ICDCodes],
    options: {
      enabled: open && ia(testIDs) && ia(ICDCodes),
      select: (data) => iaRa(data?.data)
    }
  });

  useEffect(() => {
    if (RBOData) {
      const rboDataObj = RBOData.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.testID]: curr
        };
      }, {});

      setValues((val) => {
        const testsWithRBO = val.tests.map((test) => {
          test.rboResult = rboDataObj[test.testID]?.reason;
          test.ABNValue = rboDataObj[test.testID]?.ABNValue;
          return test;
        });

        val.tests = testsWithRBO;

        return val;
      });
    }
  }, [RBOData]);

  const continueHandler = async () => {
    await submitForm();
    setOpen(false);
  };

  const cancelHandler = () => {
    setOpen(false);
  };

  // const printChangeHandler = (e) => {
  //   setFieldValue('print', e.target.checked);
  // };

  return (
    <Confirm
      icon="new-info"
      variant="warning"
      title="Confirm Order Placement"
      handleOpen={open}
      loading={isSubmitting}
      disabled={isFetching || isSubmitting}
      handleContinue={continueHandler}
      handleClose={cancelHandler}>
      <div className="flex flex-col ">
        <div className="mb-3 border-[1px] border-solid border-neutral-100 rounded">
          <div className="p-1 sticky top-0 bg-white grid grid-cols-4 drop-shadow-sm">
            <Header label="Test Name" labelClassName="font-600" headerClassName="col-span-2" />
            <Header
              label="Medical Necessity"
              headerClassName="col-span-2"
              labelClassName="font-600"
            />
          </div>

          <div className="p-1 col-span-4 grid grid-cols-4 gap-x-2 gap-y-2 max-h-[20vh] overflow-y-auto">
            {isFetching ? (
              <>
                <div className="pr-2 col-span-2">
                  <Skeleton count={testIDs.length} height="28px" />
                </div>
                <div className="col-span-2">
                  <Skeleton count={testIDs.length} height="28px" />
                </div>
              </>
            ) : isFetched ? (
              RBOData.map((item) => (
                <DetailRow key={item.testID} label={item.testName} value={item?.reason} />
              ))
            ) : null}
          </div>
        </div>
        {/* <div className="py-4">
          <Header label="Configure" labelClassName="font-600" />
          <Checkbox
            label="Print order summary"
            isChecked={!!values?.print}
            onChange={printChangeHandler}
          />
        </div> */}
      </div>
    </Confirm>
  );
};

export default MedicalNecessityConfirm;
