export const billToOptions = [
  { label: 'Third Party (Insurance)', value: 'T' },
  { label: 'Client (In-house)', value: 'C' },
  { label: 'Patient', value: 'P' }
];

export const priorityOptions = [
  { label: 'Routine', value: 'Routine' },
  { label: 'Stat', value: 'Stat' },
  { label: 'ASAP', value: 'ASAP' },
  { label: 'Pre-Op', value: 'Pre-Op' }
];

export const initialFormikValues = {
  patientId: '',
  practitionerId: '',
  tests: [],
  diags: [],
  orderProvider: '',
  orderLocation: '',
  orderCollector: '',
  orderSampleType: '',
  orderComments: '',
  drawDateTime: '',
  drawAtServiceCenter: false
};
