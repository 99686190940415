const { updatePatientTags } = require('api/Patients');
const { showAlert } = require('components/shared/Alert/Alert');

export const handleUpdatePatientTags = async ({
  navigate = null,
  queryClient = null,
  patientId = null,
  tags = [],
  mode = '',
  queryKeys = [],
  fromChart = false
}) => {
  try {
    const result = await updatePatientTags(navigate, {
      patientId,
      tags,
      mode
    });
    if (result?.code === 0) {
      if (!fromChart) {
        queryClient.setQueryData(queryKeys, (oldData) => {
          try {
            const newPatientsData = oldData?.patients?.map((p) => {
              if (p?.id !== parseInt(patientId)) {
                return p;
              }
              let newTags = structuredClone(p.tagLinks);
              if (mode === 'remove') {
                newTags = newTags.filter(
                  (tag) => !result.tags.some((r) => r.tag_id === tag?.tag_id)
                );
              }
              if (mode === 'add') {
                const addedTags = result?.tags?.flatMap((t) => t);
                newTags = [...newTags, ...addedTags];
              }
              if (mode === 'update') {
                const addedTags = result?.tags?.flatMap((t) => t);
                const filterOldPositions = newTags?.filter(
                  (tag) => !addedTags.some((addedTag) => addedTag?.tag_id === tag?.tag_id)
                );
                newTags = [...filterOldPositions, ...addedTags];
              }
              return {
                ...p,
                tagLinks: newTags
              };
            });
            return {
              ...oldData,
              patients: newPatientsData
            };
          } catch (error) {
            showAlert({
              title: 'Tags update failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
        });
      } else {
        queryClient.invalidateQueries(['patients']);
      }
    }
  } catch (error) {
    showAlert({
      title: 'Tags update failed',
      message: error ?? 'Something went wrong',
      color: 'danger'
    });
  }
};
