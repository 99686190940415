import React from 'react';

import { iaRa } from 'lib/helpers/utility';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const Goals = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (
    !selectedTreatmentPlan?.goals?.[0]?.goal &&
    !selectedTreatmentPlan?.goals?.[0]?.objectives[0]?.objective &&
    !selectedTreatmentPlan?.goals?.[0]?.objectives[0]?.treatments[0]?.treatment
  )
    return null;

  return (
    <div className="grid gap-6">
      {iaRa(selectedTreatmentPlan?.goals).map((goal, goalIndex) => (
        <ul className="pl-5" key={goalIndex}>
          <p className="-ml-5 pb-[10px] text-base font-600 text-neutral-800 first-letter:capitalize">{`Goal ${
            goalIndex + 1
          }`}</p>
          <li key={goalIndex} className="list-disc">
            <p className="pl-1 text-sm text-neutral-800 first-letter:capitalize">{goal?.goal}</p>
            {goal?.objectives[0]?.objective && (
              <ul className="pt-[10px]">
                {goal.objectives.map((objective, objectiveIndex) => (
                  <React.Fragment key={objectiveIndex}>
                    <p className="pb-[10px] text-base font-500 text-neutral-800">{`Objective${
                      goal.objectives.length > 1 ? ' ' + (objectiveIndex + 1) : ''
                    }`}</p>
                    <li className="ml-5 list-disc" key={objectiveIndex}>
                      <p className="text-sm text-neutral-800 first-letter:capitalize">
                        {objective?.objective}
                      </p>
                      {objective?.treatments[0]?.treatment && (
                        <ul className="pl-5 pt-[10px]">
                          {iaRa(objective?.treatments).map((treatment, treatmentIndex) => (
                            <React.Fragment key={treatmentIndex}>
                              <p className="-ml-5 pb-[10px] text-base font-500 text-neutral-800">{`Treatment${
                                objective?.treatments?.length > 1 ? ' ' + (treatmentIndex + 1) : ''
                              }`}</p>
                              <li className="ml-5 list-disc" key={treatmentIndex}>
                                <p className="text-sm text-neutral-800 first-letter:capitalize">
                                  {treatment?.treatment}
                                </p>
                              </li>
                            </React.Fragment>
                          ))}
                        </ul>
                      )}
                    </li>
                  </React.Fragment>
                ))}
              </ul>
            )}
          </li>
        </ul>
      ))}
    </div>
  );
};

export default Goals;
