import { useState } from 'react';

import Button from 'components/shared/Buttons/Button';

import OrdersModal from '../OrdersModal';

const AddOrderButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenPreview = () => setModalOpen(true);

  const handleClosePreview = () => setModalOpen(false);

  return (
    <>
      <Button
        text="Add Order"
        iconRight="add-circle"
        iconColor="white"
        size="small"
        onClick={handleOpenPreview}
        data-qa="add-new-order-btn"
      />
      {!!modalOpen && <OrdersModal modalOpen={!!modalOpen} handleClose={handleClosePreview} />}
    </>
  );
};

export default AddOrderButton;
