import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { sanitize } from 'lib/helpers/sanitize';

const ProblemManifestationDefinition = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (!selectedTreatmentPlan.problem_manifestation_definition) return null;

  return (
    <div className="grid gap-2">
      <h3 className="text-base font-500 text-neutral-800">Problem manifestation or definition</h3>
      <p
        className="text-sm font-500 text-neutral-800"
        dangerouslySetInnerHTML={sanitize(selectedTreatmentPlan?.problem_manifestation_definition)}
      />
    </div>
  );
};

export default ProblemManifestationDefinition;
