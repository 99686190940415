import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';

import Box from '../../../../shared/Box/Box';
import Icon from '../../../../shared/Icon/Icon';
import { costShareInfo } from '../lib/costShareInfo';
import Button from '../../../../shared/Buttons/Button';
import { currentPractice } from '../../../practiceState';
import CurrencyInput from '../../../../shared/CurrencyInput/CurrencyInput';
import { ia } from '../../../../../lib/helpers/utility';
import TaxDetails from 'components/practice/appointment/PreviewAppointment/Financial/components/TaxDetails';

const Financial = ({
  formik,
  children,
  eligibility,
  insuranceProfiles,
  eligibilityPresent,
  setShowEligibilityResponse
}) => {
  const {
    invoice,
    patient,
    products,
    procedures,
    modifiedDeductible,
    modifiedCopayment,
    modifiedCoinsurance
  } = formik.values || {};

  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;

  const [showTaxDetails, setShowTaxDetails] = useState(false);

  const { last_applied, tax } = invoice || {};

  const hasCoPayType = procedures.some(({ procedure_code }) => procedure_code);

  const { deductible: insDeductible } =
    insuranceProfiles?.find?.((ip) => ip.id === last_applied?.ins_profile_id) || {};

  const disableCostShare = !hasCoPayType || !ia(insuranceProfiles);

  return (
    <>
      <div data-dd-privacy="allow" data-public>
        <div className="flex items-center justify-between" data-dd-privacy="allow">
          <p className="text-sm font-500">Financial</p>
          {formik.values.status !== 'cancelled' ? (
            <div className="flex items-center ">
              {eligibilityPresent && (
                <Button
                  transparent
                  type="primary"
                  color="primary"
                  text="Eligibility response"
                  onClick={() => setShowEligibilityResponse(true)}
                  size="small"
                  className="!p-0"
                />
              )}
              {eligibility}
            </div>
          ) : null}
        </div>

        <Box className="!mt-2">
          <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
            <label className="label" htmlFor="patientTotalAmount">
              Payment
            </label>

            {children}
          </div>
          <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
            <label className="label" htmlFor="patientTotalAmount">
              Appointment Cost
            </label>

            <CurrencyInput
              disabled={true}
              id="patientTotalAmount"
              className="!w-[114px] text-right"
              value={formik.values.invoice?.total_amount || 0}
            />
          </div>

          <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
            <label className="label" htmlFor="patientPortionInput">
              Appointment Balance
            </label>

            <CurrencyInput
              disabled={true}
              id="patientPortionInput"
              className="!w-[114px] text-right"
              value={formik.values.invoice?.balance || 0}
            />
          </div>

          <div className="!mb-2 flex items-center justify-between">
            <label className="label flex items-center gap-[6px]">
              Tax
              <Icon
                shade="800"
                color="primary"
                icon={`chevron-${showTaxDetails ? 'up' : 'down'}`}
                onClick={() => setShowTaxDetails((show) => !show)}
              />
            </label>

            <CurrencyInput
              value={tax || 0}
              className="!w-[114px] text-right"
              onValueChange={(v) => formik.setFieldValue('invoice.tax', +v)}
            />
          </div>

          {showTaxDetails && (
            <TaxDetails tax={tax} items={[...procedures, ...products]} formik={formik} />
          )}
        </Box>
      </div>

      {/* TODO: CostShare Component */}
      {claims && (
        <div className="flex flex-col" data-dd-privacy="allow" data-public>
          <Box className="!mt-2">
            <>
              <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
                <div className="relative flex items-center !gap-1" data-dd-privacy="allow">
                  <label className="m-0 text-neutral-800" htmlFor="deductibleOwedInput">
                    Deductible
                  </label>

                  {last_applied?.deductible?.value > 0 && (
                    <Tippy
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'deductible')}>
                      <div className="relative">
                        <Icon icon="info" />
                      </div>
                    </Tippy>
                  )}
                </div>

                <CurrencyInput
                  disabled={disableCostShare}
                  id="deductibleOwedInput"
                  className="!w-[114px] text-right"
                  value={modifiedDeductible ?? last_applied?.deductible?.value ?? 0}
                  onValueChange={(v) => formik.setFieldValue('modifiedDeductible', +v)}
                />
              </div>

              {insDeductible > 0 && formik.values.modifiedDeductible > insDeductible && (
                <p className="!mb-4 text-xs text-neutral-600">
                  The amount entered exceeds the amount in the insurance profile.
                </p>
              )}

              <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
                <div className="relative flex items-center !gap-1" data-dd-privacy="allow">
                  <label className="m-0 text-neutral-800" htmlFor="copayInput">
                    Co-Payment
                  </label>

                  {last_applied?.co_payment?.value > 0 && (
                    <Tippy
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'co_payment')}>
                      <div className="relative">
                        <Icon icon="info" />
                      </div>
                    </Tippy>
                  )}
                </div>

                <CurrencyInput
                  id="copayInput"
                  disabled={disableCostShare}
                  className="!w-[114px] text-right"
                  value={modifiedCopayment ?? last_applied?.co_payment?.value ?? 0}
                  onValueChange={(v) => formik.setFieldValue('modifiedCopayment', +v)}
                />
              </div>

              <div className="!mb-2 flex items-center justify-between" data-dd-privacy="allow">
                <div className="relative flex items-center !gap-1" data-dd-privacy="allow">
                  <label className="m-0 text-neutral-800" htmlFor="coinsuranceInput">
                    Co-Insurance
                  </label>

                  {last_applied?.co_insurance?.value > 0 && (
                    <Tippy
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'co_insurance')}>
                      <div className="relative">
                        <Icon icon="info" />
                      </div>
                    </Tippy>
                  )}
                </div>

                <CurrencyInput
                  id="coinsuranceInput"
                  disabled={disableCostShare}
                  className="!w-[114px] text-right"
                  value={modifiedCoinsurance ?? last_applied?.co_insurance?.value ?? 0}
                  onValueChange={(v) => formik.setFieldValue('modifiedCoinsurance', +v)}
                />
              </div>

              {!hasCoPayType && (
                <p className="text-xs text-neutral-600">
                  Mark at least one of the services as an insurance type.
                </p>
              )}

              {hasCoPayType && !ia(insuranceProfiles) && (
                <p className="!mb-4 text-xs text-neutral-600">
                  Please add an insurance profile to calculate cost share.
                </p>
              )}
            </>
          </Box>
        </div>
      )}
    </>
  );
};

export default Financial;
