import { currentPractice } from 'components/practice/practiceState';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import UpsertProviderMedication from 'components/shared/Forms/Custom/ProviderMedications/components/UpsertMedication';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import { permissions } from 'components/state';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import MedicationsTable from './components/MedicationsTable';
import medicationsCols from './lib/medicationsCols';

export default function Medications(props) {
  const [show, setShow] = useState(false);
  const [itemModal, setItemModal] = useState({
    open: false,
    data: null
  });
  const userPermissions = useRecoilValue(permissions);
  const practice = useRecoilValue(currentPractice);

  const { patientId, layout } = props;

  const defaultSize = useMemo(
    () => Math.round(layout.find((l) => l.i === props.item.i)?.h * 2.2),
    [layout]
  );

  const { data } = useMedication({
    params: { patientId },
    dependencies: [String(patientId)],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications)
    }
  });

  const items = data?.slice(0, defaultSize);
  const hasMore = data?.length > defaultSize;

  const openItemModal = (item = null) => {
    setItemModal({ data: item, open: true });
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto" data-dd-privacy="allow">
      <WidgetHeader
        onPlusClick={() => openItemModal()}
        icon="medication"
        addPermissions="patients_medical_history.update"
        {...props}></WidgetHeader>
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <ClinicalNoteContextProvider>
          <TableContextProvider
            cols={medicationsCols({
              setShowMedicationModal: setItemModal,
              userPermissions,
              practice
            })}
            name="Medications"
            defaultFilters={[]}>
            <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
              <FooterWrapper
                hasMore={hasMore}
                onClick={() => setShow(true)}
                text={`Show all medications (${data?.length})`}>
                {items ? (
                  <MedicationsTable data={items} />
                ) : (
                  <div className="p-[20px] py-3 text-center">
                    <p className="font-medium text-neutral-500">No Medications</p>
                  </div>
                )}
              </FooterWrapper>
            </div>

            <Modal
              handleOpen={show}
              className="w-[91%] bg-white sm:w-[96%] md:w-[96%] lg:w-[96%] xxl:max-w-[1920px]"
              bodyClassName="p-0"
              title="Medications"
              slideFromRight
              handleClose={() => setShow(false)}>
              <MedicationsTable data={data} />
            </Modal>
          </TableContextProvider>

          {itemModal?.open && (
            <UpsertProviderMedication showModal={itemModal} hideModal={() => setItemModal(null)} />
          )}
        </ClinicalNoteContextProvider>
      </Allowed>
    </div>
  );
}
