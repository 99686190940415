import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import { Table } from './Table';
import { getColDefs } from './configs';

const VitalsTable = ({ breadcrumb }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const [previewData, setPreviewData] = useState({});
  return (
    <TableContextProvider
      name="patient_vitals"
      cols={getColDefs(breadcrumb, practiceTimezone, setPreviewData)}>
      <Table {...{ breadcrumb, previewData, setPreviewData }} />
    </TableContextProvider>
  );
};

export default withErrorBoundary(VitalsTable);
