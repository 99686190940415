import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import React, { useMemo, useState } from 'react';
import Allowed from 'components/shared/Permissions/Allowed';
import UpsertModal from 'components/shared/Forms/Custom/Provider/components/UpsertModal';
import AllergiesRow from 'components/shared/Forms/Custom/Allergies/AllergiesRow';
import { useFormik } from 'formik';
import { upsertCustomFormResponse } from 'api/CustomForms';
import { useNavigate } from 'react-router-dom';
import { Allergies as initialValue } from '../../../../../components/shared/Forms/Custom/CustomFormInitialStates';
import * as Yup from 'yup';

const Allergy = ({ item, openAllergyModal }) => {
  const isNoDetailsTrue = !!item?.original?.response?.noDetails;

  return (
    <div
      onClick={() => openAllergyModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      {isNoDetailsTrue ? (
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No known allergies</p>
          <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>
        </div>
      ) : (
        <List
          items={item?.list}
          dividerType="none"
          renderItemComponent={(listItem, index) => (
            <>
              <div className="flex items-center justify-between gap-2">
                <p className="break-all font-medium text-neutral-800">{listItem?.allergy?.label}</p>
                {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
              </div>

              {listItem.typicalReaction && (
                <div className="flex items-center gap-2">
                  <p className="font-medium text-primary-600">
                    {listItem?.typicalReaction?.label?.replace?.('(severe)', '')}
                  </p>
                  {listItem?.typicalReaction?.label?.includes?.('(severe)') && (
                    <>
                      <div className="h-[10px] w-[1px] bg-neutral-400"></div>
                      <p className="font-medium text-danger-500">Severe</p>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        />
      )}

      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default function Allergies(props) {
  const { customData, layout, patientId } = props;
  const allergies = customData?.allergies?.length > 0 ? customData?.allergies : null;
  const defaultSize = useMemo(() => layout.find((l) => l.i === props.item.i)?.h, [layout]);

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () => limitItems(allergies, defaultSize, { typicalReaction: 0.16 }),
    [allergies, defaultSize]
  );

  const [allergyModal, setAllergyModal] = useState(null);

  const queryClient = useQueryClient();

  const openAllergyModal = (item) => {
    formik.setValues(item?.original?.response?.allergies[0]);
    setAllergyModal(item?.original);
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue?.allergies[0],
    enableReinitialize: true,
    validationSchema: Yup.object({
      allergy: Yup.object().required('Allergy is required')
    }),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);

      const result = await upsertCustomFormResponse(navigate, {
        formId: allergyModal?.id,
        patientId,
        response: { allergies: [values] },
        type: 'allergies'
      });

      if (result?.newResponse) {
        showAlert({ title: 'Successfully saved' });
        setAllergyModal();
        queryClient.invalidateQueries(['customFormResponse']);
        resetForm();
      }
      setLoading(false);
    }
  });

  const handleOnChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto" data-dd-privacy="allow">
      <WidgetHeader
        onPlusClick={() => setAllergyModal({})}
        icon="thermometer"
        addPermissions="patients_medical_history.update"
        {...props}></WidgetHeader>

      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          <FooterWrapper
            hasMore={hasMore}
            onClick={() => setShow(true)}
            text={`Show all allergies (${count})`}>
            {items ? (
              <List
                items={items}
                renderItemComponent={(item, index) => (
                  <Allergy openAllergyModal={openAllergyModal} item={item} />
                )}
              />
            ) : (
              <div className="p-[20px] py-3 text-center">
                <p className="font-medium text-neutral-500">No Allergies</p>
              </div>
            )}
          </FooterWrapper>
        </div>

        <Modal
          handleOpen={show}
          className="w-[1020px] bg-white"
          title="Allergies"
          slideFromRight
          handleClose={() => setShow(false)}>
          {allergies ? (
            <List
              items={allergies}
              renderItemComponent={(item, index) => (
                <Allergy openAllergyModal={openAllergyModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Allergies</p>
            </div>
          )}
        </Modal>

        <UpsertModal
          showModal={!!allergyModal}
          title="Allergy"
          loading={loading}
          formik={formik}
          id={allergyModal?.id}
          hideModal={() => {
            formik.resetForm();
            setAllergyModal(null);
          }}>
          <AllergiesRow formik={formik} onChange={handleOnChange} {...formik.values} />
        </UpsertModal>
      </Allowed>
    </div>
  );
}
