import React, { useEffect } from 'react';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { optionify } from '/lib/helpers/utility';

const SameAsPractice = ({ formik }) => {
  useEffect(() => {
    formik.setFieldValue('address_is_same_as_practice', true, false);
  }, []);
  return <></>;
};
export default SameAsPractice;
