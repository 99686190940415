import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

import { Popover } from '@headlessui/react';
import { addDays } from 'date-fns';
import { capitalize } from 'lodash';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { getAppointments as getAppointmentsApi } from 'api/Appointment';

import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import {
  convertJSONToCSV,
  downloadCSV,
  formatDate,
  iaRa,
  mString,
  mapValues,
  snakeToTitleCase
} from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import {
  appointmentStatuses as aptStatuses,
  currentPractice
} from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';

import { getCptCodes, getPractitionerName, getServicesName } from '../lib';

import ExportTable from './Table';

import './Export.scss';

const ExportCalendar = ({ rangeDate }) => {
  return (
    <Popover className="relative">
      <>
        <Popover.Button as={Button} size="small" icon="new-export" outlined color="neutral" />
        <Popover.Panel className="absolute z-[999] !mt-1 gap-1 rounded-lg bg-white !p-2 shadow-md">
          {({ open }) => (open ? <Calendar rangeDate={rangeDate} /> : null)}
        </Popover.Panel>
      </>
    </Popover>
  );
};

const Calendar = ({ rangeDate }) => {
  const exportRef = useRef();
  const practice = useRecoilValue(currentPractice);
  const { filters } = useDashboardContext();
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const [exportRange, setExportRange] = useState([
    {
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,
      key: 'selection'
    }
  ]);
  const [events, setEvent] = useState([]);
  const [loading, setLoading] = useState({ generate: false });
  const navigate = useNavigate();
  const { data } = usePractitioners();
  const practitioners = iaRa(data?.practitioners);

  const exportOnlyAppointments =
    iaRa(filters?.appointmentDataType?.values).includes('with_appointments') &&
    !iaRa(filters?.appointmentDataType?.values).includes('with_events');

  useEffect(() => {
    getAppointments();
  }, [exportRange]);

  const getAppointments = async () => {
    setLoading({ ...loading, generate: true });

    const params = {
      rangeDate: {
        startDate: exportRange[0]?.startDate,
        endDate: exportRange[0]?.endDate
      },
      exporting: true,
      withEvents: true,
      filters: mapValues({
        ...filters,
        practitioners: filters?.practitioners?.values?.map((item) => item.value),
        serviceType: filters?.serviceType?.values?.map((item) => item.value),
        appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
        appointmentTags: filters?.appointmentTags?.values?.map((item) => item.value)
      })
    };

    const data = await getAppointmentsApi({ ...params, navigate });

    const allEvents = [
      ...data.appointment.map((apt) => ({
        ...apt,
        start_date: apt.starts_at,
        end_date: apt.ends_at,
        type: 'appointment',
        sort_date: new Date(apt.starts_at)
      })),
      ...data.events.map((event) => ({
        ...event,
        type: 'event',
        sort_date: new Date(event.start_date)
      }))
    ].sort((a, b) => a.sort_date - b.sort_date);

    setEvent(iaRa(allEvents));

    setLoading({ ...loading, generate: false });
  };

  const range = `${formatDate(exportRange[0]?.startDate, practice.timezone)} -
    ${formatDate(exportRange[0]?.endDate, practice.timezone)}`;

  const transformAppointmentsToCSVData = () => {
    return events.map((event) => {
      const { start_date, end_date, patient, status, invoice } = event;

      return {
        Datetime: `${moment(start_date).format('ddd MMM D, h:mm A')} - ${moment(end_date).format(
          'h:mm A'
        )}`,
        ['Patient ID']: patient?.id || '',
        Patient: patient?.fullName || '',
        ...(!exportOnlyAppointments && { ['Event Name']: capitalize(event.title) }),
        ['Insurance Name']: patient?.insuranceName || '',
        Service: getServicesName(event.invoice?.procedures),
        ['Cpt Code']: getCptCodes(event.invoice?.procedures),
        Provider: getPractitionerName(event, practitioners),
        Balance: event.type === 'appointment' ? mString(invoice?.balance || 0) : '',
        Status:
          event.type === 'appointment'
            ? appointmentStatuses.find((st) => st.value === status)?.label ||
              snakeToTitleCase(status)
            : ''
      };
    });
  };
  const handleDownloadCSV = async () => {
    const csvData = transformAppointmentsToCSVData();
    const csvContent = convertJSONToCSV(csvData);
    const title = `${formatDate(exportRange[0]?.startDate, practice.timezone)}-${formatDate(exportRange[0]?.endDate, practice.timezone)}`;
    if (csvContent) {
      downloadCSV(csvContent, `Appointment and events, ${title}.csv`);
    } else {
      showAlert({
        title: 'No appointments found!',
        message:
          "We couldn't find any appointments. Please make sure you selected a date range before downloading.",
        color: 'danger'
      });
    }
  };

  const handleSetRange = (item) => {
    const range = {
      startDate: moment(item.selection.startDate)
        .tz(practice.timezone)
        .startOf('day')
        .utc()
        .toDate(),
      endDate: moment(item.selection.endDate).tz(practice.timezone).endOf('day').utc().toDate(),
      key: 'selection'
    };

    setExportRange([range]);
  };

  return (
    <div className="PopoverNavBarFilters">
      <DateRangePicker
        months={1}
        weekStartsOn={1}
        ranges={exportRange}
        direction="horizontal"
        scroll={{ enabled: true }}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        maxDate={addDays(new Date(), 300)}
        minDate={addDays(new Date(), -300)}
        onChange={handleSetRange}
      />
      <div className="flex items-center gap-2">
        <ReactToPrint
          documentTitle={range}
          content={() => exportRef.current}
          trigger={() => <Button outlined text="PDF" loading={loading.generate} />}
        />
        <Button
          text="CSV"
          outlined
          onClick={() => handleDownloadCSV()}
          loading={loading.generate}
        />
      </div>

      <div ref={exportRef} className="hidden bg-white print:block">
        <div className="flex items-center justify-between gap-4 px-2">
          <p className="mb-2 text-xs text-primary-800 font-500">
            Total appointments and events: {events?.length}
          </p>
          <p className="mb-2 text-xs text-primary-800 font-500">Date: {range}</p>
        </div>
        <ExportTable events={events} exportOnlyAppointments={exportOnlyAppointments} />
      </div>
    </div>
  );
};

export default ExportCalendar;
