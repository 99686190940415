import React, { useCallback, useMemo, useState } from 'react';
import { MyScribeTemplateContextApi, MyScribeTemplateContextData } from './MyScribeTemplateContext';

export const MyScribeTemplateContextProvider = ({ children }) => {
  const [currentActivePromptId, setCurrentActivePromptId] = useState(null);

  const setCurrentPromptId = useCallback(setCurrentActivePromptId, [setCurrentActivePromptId]);

  const data = useMemo(() => ({ currentActivePromptId }), [currentActivePromptId]);

  const api = useMemo(
    () => ({ setCurrentActivePromptId: setCurrentPromptId }),
    [setCurrentPromptId]
  );

  return (
    <MyScribeTemplateContextData.Provider value={data}>
      <MyScribeTemplateContextApi.Provider value={api}>
        {children}
      </MyScribeTemplateContextApi.Provider>
    </MyScribeTemplateContextData.Provider>
  );
};
