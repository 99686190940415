import React, { useImperativeHandle } from 'react';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Objective = ({ objectiveRef, sectionRef = null }) => {
  const { clinicalNote, setClinicalNote } = useClinicalNoteContext();

  useImperativeHandle(sectionRef, () => ({
    formData: { objective: clinicalNote?.objective }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote({ ...clinicalNote, [name ?? key]: value ?? e });
  };

  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write objective notes here"
      id="objective"
      data-qa="objective"
      name="objective"
      className="min-h-[200px]"
      value={clinicalNote?.objective}
      onChange={(e) => handleChange(e, 'objective')}
      transcribing
      forwardedRef={objectiveRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Objective;
