import React from 'react';

const Today = ({ goToTodayHandle }) => {
  const goToToday = () => {
    goToTodayHandle(new Date());
  };

  return (
    <span
      className="cursor-pointer select-none rounded-md bg-white !px-[10px] !py-[5px] text-sm font-500 text-primary-900 hover:text-primary-700"
      onClick={goToToday}
      data-qa="calendar-today">
      Today
    </span>
  );
};

export default Today;
