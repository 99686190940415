import cs from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { ia } from '../../../lib/helpers/utility';
import useHasScrollbar from '../../../lib/hooks/useHasScrollbar';
import PermissionAllowed from '../../shared/Permissions/Allowed';
import Icon from '../Icon/Icon';

const classStyle =
  'rounded-full !py-2 !px-3 text-sm text-neutral-600 whitespace-nowrap border !border-transparent !rounded-lg flex items-center gap-x-1 hover:text-neutral-700';
const activeClassStyle =
  '!py-2 !px-3 text-sm font-600 text-primary-500 whitespace-nowrap border !border-primary-200 bg-primary-50 !rounded-lg flex items-center gap-x-1';

const NavLinkTabs = ({ list }) => {
  const [myDivRef, hasScrollbar] = useHasScrollbar({ type: 'horizontal' });

  return (
    <div className="relative shrink-0 overflow-hidden !px-6 py-[6px]">
      <div
        ref={myDivRef}
        data-qa="overview"
        className={cs('flex w-full gap-4 overflow-x-auto', hasScrollbar && '!pb-2')}>
        {ia(list) &&
          list.map((v, i) =>
            v?.permission ? (
              <PermissionAllowed
                requiredPermissions={v?.permission}
                key={`navlink-tab-permissions-${v?.name}-index-${i}`}>
                <NavLink
                  data-qa={`tabs-${v?.path}`}
                  className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
                  to={v?.path}
                  end>
                  {({ isActive }) => (
                    <>
                      {v?.icon && (
                        <Icon icon={v?.icon} color={isActive ? 'primary' : 'neutral'} size="18px" />
                      )}
                      {v?.name}
                    </>
                  )}
                </NavLink>
              </PermissionAllowed>
            ) : (
              <NavLink
                data-qa={`tabs-${v?.path}`}
                className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
                to={v?.path}
                end
                key={`navlink-tab-${v?.name}-index-${i}`}>
                {({ isActive }) => (
                  <>
                    {v?.icon && (
                      <Icon icon={v?.icon} color={isActive ? 'primary' : 'neutral'} size="18px" />
                    )}
                    {v?.name}
                  </>
                )}
              </NavLink>
            )
          )}
      </div>
    </div>
  );
};

export default NavLinkTabs;
