import React from 'react';
import QRCode from 'react-qr-code';

const ScanQRCode = ({ url = 'https://myriad.health/login' }) => {
  return (
    <div className="flex flex-col items-center gap-3 !pt-4">
      <p className="text-base font-400 leading-6 text-white">Or scan to sign-in from your phone!</p>
      <div className="w-[140px] !rounded-lg bg-white">
        <QRCode
          size={256}
          style={{ width: '100%', height: 'auto', padding: 10 }}
          value={url}
          fgColor="#00678C"
        />
      </div>
    </div>
  );
};

export default ScanQRCode;
