import { requestApi } from './Api';

export const getClinicalNotes = async (navigate, params) =>
  await requestApi({
    url: '/api/clinical_note/read_multiple',
    navigate,
    params
  });

export const getClinicalNote = async (navigate, params) =>
  await requestApi({
    url: '/api/clinical_note/read',
    navigate,
    params
  });

export const getPatientHistory = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/patient/get_history',
    params
  });

export const getFilledAdvancedForms = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/filled_advanced_forms/get',
    params
  });
