import React, { useEffect, useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import EventForm from '../EventForm';
import { getCalendarEvent } from 'api/CalendarEvent';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const EventPreview = ({ isOpen, hideEventModal = false, event, setEvent, practitioners }) => {
  const [eventObject, setEventObject] = useState(null);
  const [footer, setFooter] = useState(<></>);
  const navigate = useNavigate;
  const handleClose = () => {
    setEvent(null);
  };
  useEffect(() => {
    getCalendarEvent(navigate, { id: event?.id }).then((res) => {
      if (event) {
        delete event?.end_date;

        if (res?.event?.repeat_event) {
          event.until = event?.ends_at;
        }

        delete event?.start_at;
        delete event?.end_at;
      }
      //we add this initial date to be able to compare the initial date with the end date

      setEventObject({
        ...res?.event,
        ...event,
        initial_date: res.event.start_date,
        practitioner_ids: res?.event?.practitioner_ids
      });
    });
  }, []);

  return (
    <Modal
      slideFromRight
      bodyClassName="!p-4"
      id="NewAppointmentModal"
      className="w-[620px]"
      title={event?.event_title || event?.title}
      isOpen={isOpen}
      handleClose={hideEventModal || handleClose}
      footer={footer}>
      {eventObject ? (
        <EventForm
          event={eventObject}
          setFooter={setFooter}
          hideModal={hideEventModal || handleClose}
          practitioners={practitioners}
        />
      ) : (
        <Skeleton count={5} height={60} />
      )}
    </Modal>
  );
};

export default EventPreview;
