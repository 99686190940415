import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { createMedication } from 'api/Medication';
import { syncHpOverviewTemplates } from 'components/shared/Forms/Custom/lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from 'components/shared/Forms/Custom/lib/deleteCustomForms';
import { iaRa } from 'lib/helpers/utility';
import { error } from 'logrocket';

// TODO: OPTIMIZE - CREATE NEW ENDPOINT TO HANDLE MULTIPLE UPSERTS
export const saveAdvancedForms = async ({ items = [], appointmentId, patientId }) => {
  try {
    for (let item of items) {
      if (!item?.form?.id) continue;

      const fields =
        typeof item?.json?.fields === 'string'
          ? item?.json?.fields
          : JSON.stringify(item?.json?.fields);

      let params = {
        responseId: item?.id,
        formId: item?.form_id || item?.id,
        json: { fields },
        appointmentId,
        patientId,
        formVersion: item?.form_version || item?.version,
        isNotClinicalNote: false,
        narrative: item?.narrative
      };

      await requestApi({ url: '/api/form/upsert_response', params });
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveCustomForms = async ({
  items = {},
  originalItems,
  patientId,
  appointmentId,
  navigate,
  setOverviewData = () => null,
  setCurrentHpOverviewData = () => null
}) => {
  try {
    let params = { patientId, appointmentId, newResponses: {}, originalResponses: {} };

    const aggregatedTypes = [
      'allergies',
      'vaccines',
      'familyHistory',
      'surgicalHistory',
      'medicationHistory'
    ];

    for (let item in items) {
      const currentItem = items?.[item];
      const currentItemType = currentItem?.type || item;
      const currentItemResponse = currentItem?.response;

      if (aggregatedTypes.includes(currentItemType)) {
        continue;
      }

      params['newResponses'][currentItemType] = {
        ...(currentItemType !== 'medicationHistory' && { formId: currentItem?.id }),
        patientId,
        appointmentId,
        response: currentItemResponse,
        type: currentItemType
      };
    }

    for (let item in originalItems) {
      const currentItem = originalItems?.[item];
      const currentItemType = currentItem?.type || item;
      const currentItemResponse = currentItem?.response;

      if (aggregatedTypes.includes(currentItemType)) {
        continue;
      }

      params['originalResponses'][currentItemType] = {
        ...(currentItemType !== 'medicationHistory' && { formId: currentItem?.id }),
        patientId,
        appointmentId,
        response: currentItemResponse,
        type: currentItemType
      };
    }

    const result = await requestApi({
      url: '/api/custom_form_response/multiple_upsert',
      params,
      navigate
    });

    const { deleted: customFormsDeleted, created: customFormsCreated } = result?.data || {};

    for (let item of iaRa(customFormsDeleted)) {
      deleteFormOverviewAndHpNote({
        setOverviewData,
        setHpNoteData: setCurrentHpOverviewData,
        formId: item.formId,
        type: item.type
      });
    }

    for (let item in customFormsCreated || {}) {
      const currentItem = customFormsCreated?.[item];
      const currentItemType = currentItem?.type || item;

      if (!currentItem?.id) {
        continue;
      }

      syncHpOverviewTemplates({
        formId: currentItem?.id,
        type: currentItemType,
        setOverviewData,
        setCurrentHpOverviewData,
        customFormResponse: currentItem
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export const saveVitals = async ({
  patientId,
  appointmentId,
  navigate,
  setVitals = () => null,
  setCurrentHpOverviewData = () => null,
  vitals
}) => {
  const onSuccess = (data) => {
    const { newVitals, newPatientVitals } = data || {};
    let userType = 'update';

    if (newVitals && newPatientVitals) {
      userType = 'insert';
    }

    const vitalsWithId = {
      ...vitals,
      id: data?.newVitals,
      created_by: { id: null, fullName: 'currentUser' }
    };

    setCurrentHpOverviewData((prevState) => ({ ...prevState, vitals: vitalsWithId }));
    setVitals((prevState) =>
      userType === 'update'
        ? prevState?.map((vital) => (vital.id === vitals.id ? vitalsWithId : vital))
        : [vitalsWithId, ...(prevState || [])]
    );
  };

  await requestApi({
    url: '/api/clinical_note/vitals/create',
    params: {
      patientId,
      appointmentId,
      vitals
    },
    navigate,
    onSuccess
  });
};

export const saveMedication = async ({
  navigate,
  appointmentId,
  patientId,
  medication,
  setCurrentHpOverviewData = () => null,
  queryClient = null
}) => {
  const params = {
    appointmentId,
    patientId,
    medication: {
      medications: [],
      ...medication
    }
  };

  const onSuccess = (data) => {
    const medicationsResponse = data?.newResponse;

    queryClient.invalidateQueries({ queryKey: ['medications'] });

    setCurrentHpOverviewData((prevState) => {
      const previousData = prevState || {};

      return {
        ...previousData,
        [medicationsResponse?.type]: medicationsResponse
      };
    });
  };

  const handleInvalidateQuery = async () => {};

  await createMedication(navigate, params, () => null, onSuccess).then(handleInvalidateQuery);
};
