import React, { useEffect } from 'react';
import { socket } from '../../api/Socket';
import { useRecoilState } from 'recoil';
import state from './state';
import { withErrorBoundary } from '../shared/Error/Boundary';

/**
 * @description This compoennt is to initiazlie the socket connection
 */
const Socket = () => {
  const [socketState, setSocketState] = useRecoilState(state.socketState);

  useEffect(() => {
    const onConnect = () => {
      setSocketState((prevState) => ({ ...prevState, connected: true }));
    };

    const onDisconnect = () => {
      setSocketState((prevState) => ({ ...prevState, connected: false }));
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);

    return () => {
      socket.off('connect');
      socket.off('disconnect');
    };
  }, []);

  return null;
};

export default withErrorBoundary(Socket);
