import React, { useEffect, useState } from 'react';
import useAIBreadcrumbs from 'components/practice/charts/ClinicalNote/MyScribeAI/hooks/useAIBreadcrumbs';
import { useClinicalNoteContext } from '../ClinicalNoteContext/ClinicalNoteContext';
import { MyScribeAIContext } from './MyScribeAIContext';
import { getTypeOfNotes } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';

export const MyScribeAIContextProvider = ({ children }) => {
  const [typeOfNotes, setTypeOfNotes] = useState({});
  const [favoriteTemplateId, setFavoriteTemplateId] = useState();

  const breadcrumb = useAIBreadcrumbs();

  const { cnDisplaySettings, advancedHP, advancedSOAP, customFormTypes } = useClinicalNoteContext();
  const currentUser = useRecoilValue(userState);
  const settingsFavoriteTemplateId =
    currentUser?.display_settings?.clinicalNote?.myscribe_ai?.favoriteTemplateId || null;

  useEffect(() => {
    if (!settingsFavoriteTemplateId) return;
    setFavoriteTemplateId(settingsFavoriteTemplateId);
  }, [settingsFavoriteTemplateId]);

  useEffect(() => {
    setTypeOfNotes(
      getTypeOfNotes({
        cnDisplaySettings,
        advancedHP,
        advancedSOAP,
        customFormTypes
      })
    );
  }, [advancedHP, advancedSOAP, customFormTypes]);

  return (
    <MyScribeAIContext.Provider
      value={{
        favoriteTemplateId,
        setFavoriteTemplateId,
        breadcrumb,
        typeOfNotes
      }}>
      {children}
    </MyScribeAIContext.Provider>
  );
};
