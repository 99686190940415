import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { usePatientHistory } from 'lib/hooks/queries/clinicalNotes/usePatientHistory';
import _ from 'lodash';
import PreviewView from '../../PreviewView';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from '../configs';
import DetailCellRenderer from './DetailCellRenderer';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import './PatientHistoryTable.scss';
import { formatDataForTable, getFinalPreviewData } from '../lib/helpers';
import EmptyPatientHistory from './EmptyPatientHistory';

const PatientHistoryTable = ({ breadcrumb }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  return (
    <TableContextProvider name="patient_history" cols={getColDefs(practiceTimezone)}>
      <Table breadcrumb={breadcrumb} />
    </TableContextProvider>
  );
};

function Table({ breadcrumb }) {
  const { setSteps, setStep, steps, step } = breadcrumb;
  const { id } = useParams();
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const [previewData, setPreviewData] = useState({});
  const { setComponentToAdd, onAddComponent, setSelectedTableRows } = useChatContextApi();
  const { selectedTableRows } = useChatContextData();
  const { gridApi, gridRef } = useTableContext();
  const selectedDetailGridRows = useRef({});

  const { data } = usePatientHistory({
    params: { patientId: id },
    dependencies: [id],
    options: {
      select: (data) => ({
        history: formatDataForTable(data?.history)
      })
    }
  });
  const historyList = data?.history;

  useEffect(() => {
    setSteps([{ title: 'Patient History', name: 'start' }]);
    setStep(0);
  }, [setStep, setSteps]);

  useEffect(() => {
    if (selectedTableRows?.['patient_history']?.selectedNodes) {
      const selectedNodes = selectedTableRows['patient_history'].selectedNodes;

      selectedNodes.forEach((node) => {
        const parentRowNode = gridApi?.getRowNode(node.id);
        if (parentRowNode) {
          parentRowNode.setExpanded(true);
          parentRowNode.setSelected(true);

          // Get nested rows from context if present
          const childRowsFromContext = node.childRows || [];

          // Select the child rows if present in the context
          childRowsFromContext.forEach((childNodeFromContext) => {
            const childRowNode = gridApi?.getRowNode(childNodeFromContext.id);
            if (childRowNode) {
              childRowNode.setSelected(true);
            }
          });

          const detailGridData = parentRowNode.data?.detailGridData || [];
          const selectedDetailGridData = [...childRowsFromContext];

          detailGridData.forEach((childItem) => {
            const childRowNode = gridApi?.getRowNode(childItem.id);
            if (childRowNode) {
              const isSelectedInContext = childRowsFromContext.some(
                (contextChild) => contextChild.id === childItem.id
              );
              if (isSelectedInContext) {
                childRowNode.setSelected(true);
                selectedDetailGridData.push(childItem);
              } else {
                childRowNode.setSelected(false);
              }
            }
          });

          gridApi.setGridOption('context', {
            selectedDetailGridData
          });
        }
      });
    }
  }, [selectedTableRows, gridApi]);

  const onSelectionChanged = useCallback(
    _.debounce(() => {
      if (!gridApi) return;
    }, 200),
    [gridApi]
  );

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  const getRowStyle = (params) => {
    if (params?.node?.level === 1) {
      return {
        borderBottom: '1px solid #54D2FF'
      };
    }
  };

  const onRowSelected = (params) => {
    const selectedNodes = params.api.getSelectedNodes();
    const currentSelectedDetailGridData = gridRef.context?.selectedDetailGridData || [];
    const allDetailGridDataLength = historyList.map((item) => item.detailGridData).flat().length;

    // Handling the 'SelectAll' condition
    if (!params.event && params.source === 'uiSelectAll') {
      const isSelectAll = selectedNodes.length === history.length;
      const newSelectedGridRows = isSelectAll
        ? [
            ...currentSelectedDetailGridData,
            ...selectedNodes.map((node) => node.data.detailGridData).flat()
          ]
        : [];

      selectedDetailGridRows.current = {
        data: [...newSelectedGridRows, ...(selectedDetailGridRows?.current?.data || [])],
        status: !newSelectedGridRows.length
          ? 'none'
          : newSelectedGridRows.length === allDetailGridDataLength
            ? 'all'
            : 'partial'
      };

      // Update selection for each node and the context
      selectedNodes.forEach((node) => {
        node.setSelected(isSelectAll);
        node.setExpanded(isSelectAll);
      });

      gridApi.setGridOption('context', {
        selectedDetailGridData: newSelectedGridRows
      });

      return;
    }

    // Handling individual row selection through event (manual click)
    if (params.event) {
      const checked = params?.event?.srcElement?.checked;

      if (checked) {
        params.node.setSelected(true);
        params.node.setExpanded(true);

        const newSelectedGridRows = [
          ...currentSelectedDetailGridData,
          ...params.data.detailGridData
        ];

        selectedDetailGridRows.current = {
          data: [...newSelectedGridRows, ...(selectedDetailGridRows?.current?.data || [])],
          selectedNodes,
          status: !newSelectedGridRows.length
            ? 'none'
            : newSelectedGridRows.length === allDetailGridDataLength
              ? 'all'
              : 'partial'
        };

        gridApi.setGridOption('context', {
          selectedDetailGridData: newSelectedGridRows
        });

        return;
      } else {
        const unSelectedIds = params.data.detailGridData.map((item) => item.id);
        const newSelectedDetailGridData = currentSelectedDetailGridData.filter(
          (item) => !unSelectedIds.includes(item.id)
        );

        gridApi.setGridOption('context', {
          selectedDetailGridData: newSelectedDetailGridData
        });

        gridApi.refreshCells({ force: true, rowNodes: [params.node] });
        gridApi.refreshHeader({ force: true });

        params.node.setSelected(false);
        params.node.setExpanded(false);
      }
    }
  };

  const detailCellRenderer = useCallback(
    (props) => (
      <DetailCellRenderer {...props} setPreviewData={setPreviewData} breadcrumb={breadcrumb} />
    ),
    [breadcrumb]
  );

  const context = useMemo(() => {
    return {
      selectedDetailGridData: []
    };
  }, []);

  const onSubmit = () => {
    const finalSelectedRows = selectedDetailGridRows.current || [];
    setSelectedTableRows((prev) => ({
      ...prev,
      patient_history: {
        data: finalSelectedRows?.data || [],
        selectedNodes: finalSelectedRows?.selectedNodes,
        status: finalSelectedRows?.status
      }
    }));
    onAddComponent();
  };

  let body = (
    <>
      <div className="patient_table !mb-0 h-full flex-col">
        <AGTable
          getRowStyle={getRowStyle}
          onSelectionChanged={onSelectionChanged}
          defaultColDef={DEFAULT_COLUMN_DEFS}
          columnDef={getColDefs(practiceTimezone)}
          gridOptions={GRID_OPTIONS}
          animateRows={true}
          data={historyList}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          isRowSelectable={() => true}
          customClassName="ag-grid-interactive p-0 m-0"
          reactiveCustomComponents
          masterDetail={true}
          detailRowAutoHeight={true}
          context={context}
          detailCellRenderer={detailCellRenderer}
          onRowSelected={onRowSelected}
          reactUi={true}
          noRowsOverlayComponent={EmptyPatientHistory}
          loadingOverlayComponent={() => (
            <div className="h-full w-[1160px] min-w-[480px] bg-white">
              <Skeleton count={24} />
            </div>
          )}
        />
      </div>
    </>
  );

  if (steps[step]?.name === 'preview') {
    const finalPreviewData = getFinalPreviewData(previewData);

    body = <PreviewView previewData={finalPreviewData} />;
  }

  const onBackClick = () => {
    if (steps[step]?.name === 'preview') {
      onGoBack();
      return;
    }
    setComponentToAdd(null);
  };

  return (
    <>
      {body}
      <div className="flex justify-center">
        {historyList?.length > 0 && (
          <ModalFooter
            className="z-50"
            rightButtons={
              <Button
                key={1}
                text="Add Patient History"
                // disabled={!selectedRowsData?.length}
                iconSize={18}
                iconColor="white"
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={onSubmit}
              />
            }
            leftButtons={
              <Button
                key={1}
                text="Back"
                outlined
                transparent
                size="small"
                className="!py-2 !pl-4 !pr-4"
                onClick={onBackClick}
              />
            }
          />
        )}
      </div>
    </>
  );
}
const memoPatientHistory = memo(PatientHistoryTable);
export default withErrorBoundary(memoPatientHistory);
