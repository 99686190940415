import React from 'react';
import { Link } from 'react-router-dom';
import CheckinPracticeLogo from '../../../Patient/checkin/PracticeLogo';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';

const instantActionsConfirmAppointment = ({ success, loading, setAndAction, resubmit, data }) => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-between">
      <div className="w-full h-fit bg-white">
        <CheckinPracticeLogo practiceLogo={data?.practiceLogo} />
      </div>
      <div className="w-full p-[2rem] sm:p-4 flex flex-col justify-center items-center">
        <Icon icon="appointment-pending" />
        <div className="flex flex-col w-full text-center">
          <div className="my-[1rem] sm:my-[0.5rem] text-3xl text-primary-900 font-500">
            Upcoming appointment
          </div>
          <p className="text-lg my-[1rem] sm:my-[0.5rem] text-primary-900">
            Please confirm the appointment below.
          </p>
          <div className="rounded-xl border-solid border-[1px] border-primary-100 bg-primary-50 px-[1.2rem] py-[0.7rem] sm:py-[0.3rem] flex flex-col w-full my-[1.5rem]">
            {data?.name && (
              <>
                <div className="flex flex-row justify-between text-primary-900 mt-[0.5rem] sm:mt-[0.5rem]">
                  <span>Practice</span>
                  <span>{data?.name}</span>
                </div>
                <hr className="border-dashed border-[1.5px] border-primary-100 mb-[0.5rem] sm:mb-[0.3rem]" />
              </>
            )}
            {data?.location && (
              <>
                <div className="flex flex-row justify-between text-primary-900 mt-[0.5rem] sm:mt-[0.3rem]">
                  <span>Location</span>
                  <span>{data?.location}</span>
                </div>
                <hr className="border-dashed border-[1.5px] border-primary-100 mb-[0.5rem] sm:mb-[0.3rem]" />
              </>
            )}
            {data?.provider && (
              <>
                <div className="flex flex-row justify-between text-primary-900 mt-[0.5rem] sm:mt-[0.3rem]">
                  <span>Provider</span>
                  <span>{data?.provider}</span>
                </div>
                <hr className="border-dashed border-[1.5px] border-primary-100 mb-[0.5rem] sm:mb-[0.3rem]" />
              </>
            )}
            {data?.date && (
              <>
                <div className="flex flex-row justify-between text-primary-900 mt-[0.5rem] sm:mt-[0.3rem]">
                  <span>Date</span>
                  <span>{data?.date}</span>
                </div>
                {data?.phone && (
                  <hr className="border-dashed border-[1.5px] border-primary-100 mb-[0.5rem] sm:mb-[0.3rem]" />
                )}
              </>
            )}
            {data?.phone && (
              <>
                <div className="flex flex-row justify-between text-primary-900 my-[0.5rem] sm:my-[0.5rem]">
                  <span>Phone</span>
                  <span>{data?.phone}</span>
                </div>
              </>
            )}
          </div>
          {success && (
            <Button
              text="Confirm Appointment"
              loading={loading}
              onClick={() => {
                setAndAction(true);
                resubmit();
              }}
              className="w-full my-[0.6rem]"
            />
          )}
        </div>
      </div>
      <div className="w-full">
        <label className="!mx-10 !mb-5 text-center text-neutral-500 flex flex-col">
          <Link to="/login">Login</Link>
          <Link to="/terms">Terms of Service and Privacy Policy</Link>
        </label>
        {data?.practiceLogo && (
          <div className="flex h-[44px] w-full items-center justify-between bg-primary-50 px-40">
            <h4 className="text-sm text-neutral-600">Powered By</h4>
            <Icon icon="logo-extra-small" />
          </div>
        )}
      </div>
    </div>
  );
};

export default instantActionsConfirmAppointment;
