import { deleteFilter } from 'api/Filter';

import { showAlert } from '../../../Alert/Alert';

export default async function handleDelete(
  navigate,
  { id, data, appliedFilter, resetFilter, queryClient }
) {
  const del = await deleteFilter(navigate, { id });

  const deletedFilter = data?.filters?.find((f) => f.id === id)?.name;
  if (deletedFilter === appliedFilter) {
    resetFilter();
  }

  if (del.code === 0) {
    showAlert({
      message: 'Filter deleted successfully',
      color: 'success'
    });
  } else {
    showAlert({
      message: 'An unexpected error has occured. Please try again later.',
      color: 'danger'
    });
  }

  queryClient.invalidateQueries(['filters']);
  queryClient.invalidateQueries(['default-filters']);
}
