import React from 'react';

import { useRecoilValue } from 'recoil';

import { isEmpty, stripHtmlTags } from 'lib/helpers/utility';

import { clinicalNote as clinicalNoteState } from 'components/state';

import SanitizeComponent from '../../Sanitize/SanitizeComponent';

const Notes = ({ customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }

  if (isEmpty(stripHtmlTags(clinicalNote?.notes))) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <SanitizeComponent value={clinicalNote?.notes} title="Notes" />
    </div>
  );
};

export default Notes;
