import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { attachDocument, deleteAttachment } from 'api/Attachments';
import { deleteResponse } from 'api/CustomForms';
import { deleteDocument, shareDocumentWithPatient } from 'api/Documents';

import { showAlert } from 'components/shared/Alert/Alert';

import setMultipleQueriesData from './setMultipeQueriesData';

const initConfirmation = { delete: false, share: false };

const useDocumentHandler = ({
  patientId,
  showConfirmation,
  appointmentId,
  setShowConfirmation,

  currentFile,
  setCurrentFile
}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [shareLoading, setShareLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleShareWithPatient = async ({ type, id }) => {
    setShareLoading(true);
    const isUnshare = showConfirmation?.unshare || false;
    const { success, email } = await shareDocumentWithPatient(navigate, {
      patientId,
      type,
      id,
      ...(isUnshare ? { unshare: true } : {})
    });

    const message = success
      ? `Document ${isUnshare ? 'un' : ''}shared with patient successfully${!email && !isUnshare ? ', but the email failed' : ''}.`
      : `There was a problem  ${isUnshare ? 'un' : ''}sharing the document with the patient.`;

    const color = success ? 'success' : 'danger';

    showAlert({ message, color });

    queryClient.setQueriesData(['documents'], (old) => {
      return {
        ...old,
        documents: old?.documents?.map((item) => {
          if (item?.id === id) {
            return {
              ...item,
              myportal: !isUnshare
            };
          } else {
            return item;
          }
        })
      };
    });

    if (success) setShowConfirmation(initConfirmation);
    setShareLoading(false);
  };

  const handleDeletion = async ({ type, id }) => {
    setDeleteLoading(true);

    const getData = async () => {
      if (appointmentId) {
        return deleteAttachment(navigate, {
          ...currentFile,
          appointmentId
        });
      }
      switch (type) {
        case 'filestack':
          return deleteDocument(navigate, { handle: id });
        case 'form':
          return deleteResponse(navigate, { id });
        default:
          showAlert({ title: 'There was a problem while deleting document.', color: 'danger' });
      }
    };

    const data = await getData();

    if (data?.deleted || data?.data > 0) {
      showAlert({
        title: `${currentFile?.name} ${currentFile.deleteInstances || !appointmentId ? 'deleted' : 'removed from note'} successfully`,
        color: 'success'
      });

      if (currentFile.deleteInstances || !appointmentId) {
        setMultipleQueriesData(
          queryClient,
          [
            'documents',
            ['attachments', appointmentId?.toString() || currentFile?.appointment_id?.toString()]
          ],
          (old) => {
            return {
              ...old,
              documents: old?.documents?.filter((item) =>
                typeof id === 'string' ? item.document_handle !== id : item?.id !== id
              )
            };
          }
        );
      } else {
        setMultipleQueriesData(
          queryClient,
          ['documents', ['attachments', currentFile?.appointment_id?.toString()]],
          (old) => {
            return {
              ...old,
              documents: currentFile.deleteInstances
                ? old?.documents?.filter((item) =>
                    typeof id === 'string' ? item.document_handle !== id : item?.id !== id
                  )
                : old?.documents?.map((item) => {
                    if (typeof id === 'string' ? item.document_handle === id : item?.id === id) {
                      return {
                        ...item,
                        appointment_id: null,
                        date_of_service: null
                      };
                    } else {
                      return item;
                    }
                  })
            };
          }
        );

        queryClient.setQueriesData(
          ['attachments', appointmentId?.toString() || currentFile?.appointment_id?.toString()],
          (old) => {
            return {
              ...old,
              documents: old?.documents?.filter((item) =>
                typeof id === 'string' ? item.document_handle !== id : item?.id !== id
              )
            };
          }
        );
      }

      setShowConfirmation(initConfirmation);
    } else {
      showAlert({ title: 'There was a problem while deleting document.', color: 'danger' });
    }
    setCurrentFile(null);
    setDeleteLoading(false);
  };

  const setSelectedAppointment = async (appointment, document) => {
    if (document?.appointment_id === appointment?.id) return;
    const params = {
      ...document,
      appointmentId: appointment ? appointment?.id : null
    };

    const { data } = await attachDocument(navigate, params);

    let newAttachment = {};

    await queryClient.setQueriesData(['documents'], (old) => {
      return {
        ...old,
        documents: old?.documents?.map((item) => {
          if (item?.id === document?.id) {
            const newItem = {
              ...item,
              appointment_id: data?.id,
              date_of_service: data?.starts_at
            };

            newAttachment = { ...newItem, old_appointment_id: item?.appointment_id };

            return newItem;
          } else {
            return item;
          }
        })
      };
    });

    if (document?.appointment_id) {
      if (appointment && document?.appointment_id !== appointment?.id) {
        await queryClient.setQueriesData(
          ['attachments', document?.appointment_id?.toString()],
          (old) => {
            return {
              ...old,
              documents: old?.documents?.filter((item) => item?.id !== document?.id)
            };
          }
        );
      }
    }

    await queryClient.setQueriesData(
      ['attachments', appointment?.id?.toString() || document?.appointment_id?.toString()],
      (old) => {
        if (!old?.documents?.find((item) => item?.id === document?.id) && appointment) {
          return {
            ...old,
            documents: [newAttachment, ...(old?.documents || [])]
          };
        } else if (old?.documents?.find((item) => item?.id === document?.id) && !appointment) {
          return {
            ...old,
            documents: old?.documents?.filter((item) => item?.id !== document?.id)
          };
        } else {
          return old?.documents?.map((item) => {
            if (item?.id === document?.id) {
              return {
                ...item,
                appointment_id: data?.id,
                date_of_service: data?.starts_at
              };
            } else {
              return item;
            }
          });
        }
      }
    );
  };

  return {
    shareLoading,
    deleteLoading,
    handleShareWithPatient,
    handleDeletion,
    setSelectedAppointment
  };
};

export default useDocumentHandler;
