import React, { useRef, useCallback, useState, memo, useEffect } from 'react';
import { VariableSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const VirtualizedList = ({ data, renderItem, scrollToIndex }) => {
  const listRef = useRef();
  const rowHeights = useRef(new Map());
  const [inputValues, setInputValues] = useState({});
  const lastFocusedRef = useRef(null);

  const setRowHeight = useCallback((index, size) => {
    const currentHeight = rowHeights.current.get(index);
    if (currentHeight !== size) {
      rowHeights.current.set(index, size);
      listRef.current.resetAfterIndex(index, true);
    }
  }, []);

  const getItemSize = (index) => rowHeights.current.get(index) || 400;

  const handleInputChange = (index, value) => {
    setInputValues((prev) => ({
      ...prev,
      [index]: value
    }));
  };

  const handleFocus = (index) => {
    lastFocusedRef.current = index;
  };

  const memoizedRenderItem = useCallback(
    (item, index) => {
      return (
        <div key={index}>
          {renderItem(item, index, handleInputChange, handleFocus, inputValues)}
        </div>
      );
    },
    [renderItem, inputValues]
  );

  const smoothScrollToIndex = useCallback((index) => {
    if (listRef.current) {
      const totalScroll = [...Array(index).keys()].reduce((sum, idx) => sum + getItemSize(idx), 0);

      listRef.current._outerRef.scrollTo({
        top: totalScroll,
        behavior: 'smooth'
      });
    }
  }, []);

  useEffect(() => {
    if (scrollToIndex !== null && scrollToIndex !== undefined) {
      smoothScrollToIndex(scrollToIndex);
    }
  }, [scrollToIndex, smoothScrollToIndex]);

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          className="w-full"
          ref={listRef}
          height={height}
          width={width}
          itemCount={data.length}
          itemSize={getItemSize}>
          {({ index, style }) => (
            <div style={style}>
              <MeasuredRow index={index} setRowHeight={setRowHeight}>
                <MemoizedRowContent>{memoizedRenderItem(data[index], index)}</MemoizedRowContent>
              </MeasuredRow>
            </div>
          )}
        </List>
      )}
    </AutoSizer>
  );
};

const MemoizedRowContent = memo(({ children }) => children);

const MeasuredRow = memo(({ index, setRowHeight, children }) => {
  const rowRef = useRef();

  useEffect(() => {
    if (rowRef.current) {
      const height = rowRef.current.getBoundingClientRect().height;
      setRowHeight(index, height);
    }
  }, [index, children, setRowHeight]);

  return <div ref={rowRef}>{children}</div>;
});

export default memo(VirtualizedList);
