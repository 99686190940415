import React, { useState, useMemo, useEffect } from 'react';
import Input from 'components/shared/Input/Input';
import Skeleton from 'react-loading-skeleton';
import UserTile from '../UserTile';
import Icon from 'components/shared/Icon/Icon';
import useDebounce from 'lib/hooks/useDebounce';
import { useRoleUsers } from 'lib/hooks/queries/permissions/usePracticeMembers';
import { useParams } from 'react-router-dom';

const MemoizedInput = React.memo(Input);

const ShowAllButton = ({ expanded, onClick }) => (
  <div
    className="flex cursor-pointer items-center gap-1.5 rounded-md bg-primary-50 px-2.5 py-[5px]"
    onClick={onClick}>
    <span className="whitespace-nowrap text-xs font-medium text-primary-500">
      {expanded ? 'Show less' : 'Show all'}
    </span>
  </div>
);

const AssignedUsers = ({ roleId, forAdmin, isAdmin }) => {
  const [totalMembers, setTotalMembers] = useState([]);
  const [searchStaffTerm, setSearchStaffTerm] = useState('');
  const [hasMembers, setHasMembers] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(true);
  const debouncedSearchTerm = useDebounce(searchStaffTerm);
  const { id } = useParams();

  const queryParams = useMemo(
    () => ({
      params: { searchTerm: debouncedSearchTerm, roleId, practice_id: id, forAdmin },
      dependencies: [debouncedSearchTerm, roleId, shouldRefetch],
      options: {
        onSuccess: (data) => {
          const members = data?.users || [];
          if (hasMembers === null) {
            if (members.length > 0) {
              setHasMembers(true);
            } else {
              setHasMembers(false);
            }
          }
          setTotalMembers(members);
        }
      }
    }),
    [debouncedSearchTerm, roleId, shouldRefetch]
  );

  const { isLoading } = useRoleUsers(queryParams);
  const isExpandable = totalMembers.length > 10;
  const renderedMembers = expanded ? totalMembers : totalMembers.slice(0, 10);

  const toggleExpand = () => setExpanded((prev) => !prev);

  useEffect(() => {
    // Trigger a refetch each time the component mounts
    setShouldRefetch((prev) => !prev);
  }, []);

  return hasMembers ? (
    <div className="relative flex min-h-[75px] w-full flex-col items-start justify-center gap-4">
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <span className="text-sm font-medium text-neutral-800">Users under this role</span>
          <span className="text-xs text-neutral-500">(Click to edit profile)</span>
        </div>
        <div className="flex h-8 w-40 items-center gap-2.5 !rounded-[27px] border border-neutral-200">
          <MemoizedInput
            id="searchUsers"
            type="string"
            placeholder="Search users"
            value={searchStaffTerm}
            icon="new-search"
            inputWrapperClassName="!rounded-none !bg-transparent !py-2 !pb-1 !pl-0 border-b-[1px] !border-neutral-200 border-0 justify-start items-center gap-3 inline-flex !outline-transparent focus-within:!border-b-[1px] focus-within:!border-neutral-200 focus-within:!outline-transparent"
            onChange={(e) => setSearchStaffTerm(e.target.value)}
            rightText={
              searchStaffTerm && <Icon icon="new-close" onClick={() => setSearchStaffTerm('')} />
            }
          />
        </div>
      </div>
      {!(debouncedSearchTerm !== '' && totalMembers.length === 0) ? (
        <div
          className="flex w-full flex-wrap gap-1.5 rounded-[100px]"
          style={{ msOverflowStyle: 'none', scrollbarWidth: 'none' }}>
          {isLoading ? (
            <Skeleton height="16px" />
          ) : (
            <>
              {renderedMembers.map((member, index) => (
                <UserTile key={index} member={member} forAdmin={forAdmin} isAdmin={isAdmin} />
              ))}
              {isExpandable && <ShowAllButton expanded={expanded} onClick={toggleExpand} />}
            </>
          )}
        </div>
      ) : (
        <div className="flex w-full justify-between">
          <div className="flex items-center gap-2">
            <span className="text-xs text-neutral-500">No users found</span>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="flex w-full justify-between">
      <div className="flex items-center gap-2">
        <span className="text-sm font-medium text-neutral-800">No users under this role</span>
      </div>
    </div>
  );
};

export default AssignedUsers;
