import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { requestApi } from '../../../../../../api/Api';
import { ia } from '../../../../../../lib/helpers/utility';
import Input from '../../../../../shared/Input/Input';
import QuotesTable from './components/QuotesTable';

const Quotes = () => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10,
    withCount: true
  });

  const [quotes, setQuotes] = useState([]);
  const [quotesCount, setQuotesCount] = useState(0);
  const navigate = useNavigate();

  const { id: patientId } = useParams();

  const getQuotes = async () => {
    const onSuccess = ({ data }) => {
      if (ia(data.quotes, -1)) {
        if (data.quotesCount) setQuotesCount(Number(data.quotesCount));

        setQuotes(data.quotes);
      }
    };

    patientId &&
      requestApi({
        url: '/api/patient/quote/read',
        params: { patientId, filters },
        onSuccess,
        navigate
      });
  };

  useEffect(() => {
    getQuotes();
  }, [filters.search, filters.page]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.searchTerm]);

  return (
    <div className="relative bg-neutral-50 !p-4">
      <div className="flex items-center justify-between !pb-2">
        <h3 className="text-base text-neutral-800">Quotes</h3>
        <Input
          type="string"
          rounded="full"
          icon="new-search"
          data-qa="search-quote"
          value={filters.searchTerm}
          placeholder="Search quote..."
          className={'w-[240px]  !border-secondary-400 md:w-[200px]'}
          onChange={(e) =>
            setFilters({ ...filters, searchTerm: e.target.value, page: 1, withCount: true })
          }
        />
      </div>

      {ia(quotes) ? (
        <QuotesTable
          quotes={quotes}
          filters={filters}
          setQuotes={setQuotes}
          patientId={patientId}
          getQuotes={getQuotes}
          setFilters={setFilters}
          quotesCount={quotesCount}
        />
      ) : (
        <p>No quotes have been found!</p>
      )}
    </div>
  );
};

export default Quotes;
