import React from 'react';
import cs from 'classnames';

const Box = ({ children, className, isStyle = true }) => {
  return (
    <div
      className={cs(
        'grid gap-4 !rounded-lg bg-neutral-50',
        isStyle && 'border border-solid border-neutral-200 !p-4',
        className
      )}>
      {children}
    </div>
  );
};

export default Box;
