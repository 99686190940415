export const DarkColors = [
  '#8FA0A7',
  '#526268',
  '#1B2123',
  '#EF4444',
  '#B91C1C',
  '#7F1D1D',
  '#13B9FF',
  '#0085B5',
  '#004F6B',
  '#8191E8',
  '#1F35AC',
  '#0A1239',
  '#22C55E',
  '#15803D',
  '#14532D',
  '#F59E0B',
  '#B45309',
  '#78350F'
];
