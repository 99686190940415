import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useInboundFaxes } from 'lib/hooks/queries/fax/useInboundFaxes';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NewFaxesTable from '../NewFaxesTable';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { archiveFaxes, getFaxesCSV, markFaxesAsRead, previewSavedFax, updateFax } from 'api/Fax';
import cs from 'classnames';
import practiceState, { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import { useRecoilValue } from 'recoil';
import AssignPatientModal from '../components/AssignPatientModal';
import AssignProvidersModal from '../components/AssignProvidersModal';
import PreviewPdfModal from '../components/SendFax/components/PreviewPdfModal';
import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, getColDefs, GRID_OPTIONS } from './configs';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import AddTag from '../components/AddTag';
import { isEqual } from 'lodash';
import { useFaxContext } from 'lib/context/FaxContext/FaxContext';

const NewInboundFaxes = () => {
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const practiceTimezone = currPractice?.timezone;
  return (
    <TableContextProvider
      name="inbound_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone)}
      pagination>
      <InboundFaxesTable />
    </TableContextProvider>
  );
};

function InboundFaxesTable() {
  const category = 'inbound_faxes';
  const kind = 'fax';

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const currPractice = useRecoilValue(practiceState.currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);
  const [previewFax, setPreviewFax] = useState(null);
  const [pdfData, setPdfData] = useState(null);

  const [assignPatientModalVisible, setAssignPatientModalVisible] = useState(false);
  const [assignProvidersModalVisible, setAssignProvidersModalVisible] = useState(false);

  const { pathname, state } = useLocation();

  const { limit, page, sort, filters, selectedRows } = useTableContext();
  const {
    openTagModal,
    setOpenTagModal,
    faxTags,
    faxId,
    prevTags,
    setDisableAdd,
    setFaxTags,
    setPrevTags
  } = useFaxContext();
  const selectedRowsData = selectedRows.map((node) => node.data);
  const practice = useRecoilValue(currentPractice);

  const { data: patientsData } = usePatients({
    params: { practiceId: practice.id }
  });
  const patients = patientsData?.patients;

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });

  const patientOptions = useMemo(() => {
    if (patients?.length > 0) {
      return patients.map((patient) => ({
        id: patient.user_id,
        label: patient?.f_name + ' ' + patient?.l_name,
        value: patient?.id
      }));
    }
    return [];
  }, [patients]);
  filters.patients.options = patientOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags.map((t) => ({
        label: t?.name,
        value: t?.id
      }));
    }
    return [];
  }, [tagsList]);
  filters.tags.options = tagOptions;

  const { data, isFetching, isLoading } = useInboundFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });
  const inboundFaxes = data?.inboundFaxes;
  const inboundFaxesCount = data?.count || 0;

  useEffect(() => {
    if (state?.notification?.id && ia(inboundFaxes)) {
      fromNotification();
    }
  }, [state?.notification?.id, inboundFaxes]);

  const onPreviewFax = async (fax) => {
    previewSavedFax(navigate, fax.id, true).then(async (data) => {
      if (!fax?.read) {
        await handleMarkAsRead([fax.id]);
        queryClient.invalidateQueries(['inboundFaxes']);
      }

      setPdfData(data.url);

      setPreviewFaxModalVisible(true);

      setPreviewFax(data.fax);
    });
  };

  const fromNotification = async () => {
    const documentId =
      state?.notification?.meta?.documentId ?? state?.notification?.related_entity_id;

    onPreviewFax({
      id: documentId
    });

    navigate(pathname, {
      replace: true
    });
  };

  const handleMarkAsRead = async (faxId = null) => {
    await markFaxesAsRead(
      navigate,
      ia(faxId) ? faxId : selectedRowsData?.map((fax) => fax.id),
      onSuccess
    );

    function onSuccess() {
      queryClient.invalidateQueries(['inboundFaxes']);
      queryClient.invalidateQueries(['unreadFaxes']);

      showAlert({ message: 'Faxes marked as read', color: 'success' });
    }
  };

  const onFaxesArchive = () => {
    archiveFaxes(
      navigate,
      selectedRowsData.map((fax) => fax.id)
    ).then(() => queryClient.invalidateQueries(['inboundFaxes']));
  };

  const onRowClicked = (clickedFax) => {
    onPreviewFax(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0', 'tags'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'inbound'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };
  const { mutateAsync: mutateUpdateFax } = useMutation({
    mutationFn: (data) => updateFax(navigate, data)
  });

  const handleAddTag = async () => {
    if (isEqual(prevTags, faxTags)) return;
    setDisableAdd(false);
    await mutateUpdateFax(
      { faxId, fields: { tag_ids: faxTags } },
      {
        onSuccess: ({ code, error }) => {
          if (code === 0) {
            queryClient.invalidateQueries(['inboundFaxes']);
            showAlert({
              title: 'Fax updated successfully',
              color: 'success'
            });
          } else {
            showAlert({
              title: 'Fax update failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
        }
      }
    );
    setFaxTags([]);
    setPrevTags([]);
    setOpenTagModal(false);
  };

  return (
    <div className="h-full" data-dd-privacy="allow">
      <NewFaxesTable
        onExportCSV={onExportCSV}
        headerButtons={
          ia(selectedRowsData) ? (
            <div>
              {ia(selectedRowsData) ? (
                <div className="flex items-center gap-2 opacity-100 transition-opacity">
                  <Button
                    text="Mark as read"
                    onClick={handleMarkAsRead}
                    className={cs(
                      'h-[34px] py-1 text-sm',
                      `${!selectedRowsData.some((fax) => fax.read === false) && 'hidden'}`
                    )}
                  />
                  <Button
                    text="Assign Patient"
                    color="success"
                    className="h-[34px] py-1 text-sm"
                    onClick={() => setAssignPatientModalVisible(true)}
                  />
                  <Button
                    text="Assign Providers"
                    color="success"
                    className="h-[34px] py-1 text-sm"
                    onClick={() => setAssignProvidersModalVisible(true)}
                  />
                  <Button
                    text="Archive"
                    color="neutral"
                    className="h-[34px] py-1 text-sm"
                    onClick={onFaxesArchive}
                  />
                </div>
              ) : null}
            </div>
          ) : null
        }
        data={inboundFaxes || []}
        page={page}
        limit={limit}
        count={inboundFaxesCount}
        category="inbound_faxes"
        name="Inbound Faxes"
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
      />
      {previewFaxModalVisible && (
        <PreviewPdfModal
          {...{
            pdfData,
            previewFax,
            previewFaxModalVisible,
            setPreviewFaxModalVisible,
            hasEdit: true
          }}
        />
      )}
      {assignPatientModalVisible && (
        <AssignPatientModal
          {...{
            assignPatientModalVisible,
            setAssignPatientModalVisible,
            faxQueryKey: 'inboundFaxes',
            faxes: selectedRowsData.map((fax) => ({ id: fax.id, files: fax.files }))
          }}
        />
      )}
      {assignProvidersModalVisible && (
        <AssignProvidersModal
          {...{
            assignProvidersModalVisible,
            setAssignProvidersModalVisible,
            faxQueryKey: 'inboundFaxes',
            faxes: selectedRowsData.map((fax) => ({ id: fax?.id, files: fax?.files }))
          }}
        />
      )}
      {openTagModal && (
        <AddTag
          openTagModal={openTagModal}
          setOpenTagModal={setOpenTagModal}
          handleAddTag={handleAddTag}
        />
      )}
    </div>
  );
}

export default NewInboundFaxes;
