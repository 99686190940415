import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io, sortObjectByProperty } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { filterFalsyValues } from '../../../lib/filterFalsyValues';
import { generateSOAPList } from '../../../lib/generateSOAPList';
import OrdersSection from '../../../Orders/OrdersSection';
import MedicationsSection from '../HPNote/components/MedicationsSection';
import VitalsSection from '../HPNote/components/VitalsSection';
import AdvancedSoap from './components/AdvancedSoap';
import AssessmentSection from './components/AssessmentSection';
import CPTCodesSection from './components/CPTCodesSection';
import GeneralNoteSection from './components/GeneralNoteSection';
import ICDCodesSection from './components/ICDCodesSection';
import ObjectiveSection from './components/ObjectiveSection';
import PlanSection from './components/PlanSection';
import SubjectiveSection from './components/SubjectiveSection';
import { useRecoilValue } from 'recoil';
import { permissions } from 'components/state';
import Loader from 'components/shared/Loader/Loader';

const SOAPNote = ({ context }) => {
  const userPermissions = useRecoilValue(permissions);
  const { hpNoteOverviewRefs } = context;
  const {
    components,
    setComponents,
    advancedSOAP,
    cnDisplaySettings,
    setOverviewData,
    currentHpOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton,
    setEnhanceNarrativeParams,
    isFetchingEnhanceNarrative
  } = useClinicalNoteContext();
  const { id, appointmentId, formName, type } = useParams();
  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  };
  const [formsList, setFormList] = useState({
    subjective: SubjectiveSection,
    objective: ObjectiveSection,
    assessment: AssessmentSection,
    medicationHistory: MedicationsSection,
    plan: PlanSection,
    vitals: VitalsSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: GeneralNoteSection,
    orders: OrdersSection
  });
  const sectionRef = useRef();

  const firstActiveComponent = Object.keys(components)[0];
  const currAdvForm = advancedSOAP?.find((item) => {
    const title = item?.custom_title;
    if ((!formName && title === firstActiveComponent) || formName === title) return item;
  });

  useEffect(() => {
    const advancedSOAPForms = advancedSOAP.map((item) => {
      if (cnDisplaySettings?.sections?.soap[item.custom_title]?.enabled === false) {
        return null;
      }
      return item;
    });
    const filteredAdvancedSOAPForms = advancedSOAPForms.filter((value) => io(value));
    const advancedTitles =
      ia(filteredAdvancedSOAPForms) &&
      Object.fromEntries(
        filteredAdvancedSOAPForms.map((item) => {
          const title = item?.custom_title;
          return [
            title,
            {
              title: item?.form?.name || item?.name,
              path: title,
              position: cnDisplaySettings?.sections?.soap?.[item?.custom_title]?.position
            }
          ];
        })
      );

    const assignAdvTitlesToComponent = Object.keys(advancedTitles).reduce((acc, key) => {
      acc[key] = () => <AdvancedSoap currAdvForm={currAdvForm} />;
      return acc;
    }, {});

    setFormList((prevState) => ({ ...prevState, ...assignAdvTitlesToComponent }));

    const enabledComponents = filterFalsyValues(
      generateSOAPList(cnDisplaySettings, advancedTitles)
    );
    const sortedComponents = sortObjectByProperty(enabledComponents, 'position');
    setComponents(sortedComponents);
  }, [cnDisplaySettings, advancedSOAP, formName, currAdvForm]);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  const handleEnhanceNarrative = () => {
    // console.log(type, formName, sectionRef?.current?.formData?.[formName], null);
    setEnhanceNarrativeParams({
      kind: type,
      name: formName || 'subjective',
      body: sectionRef?.current?.formData?.[formName || 'subjective'],
      customFormId: null
    });
  };

  if (!io(components) || !CurrentForm) return <Skeleton height="146px" />;

  return (
    <div className="flex h-full flex-col gap-y-3">
      {isFetchingEnhanceNarrative && (
        <div className="flex flex-row gap-2">
          <Loader outlined={true} className="bg-secondary-600 text-white " />
          <span>Enhancing...</span>
        </div>
      )}
      <CurrentForm
        key={formName}
        components={components}
        sectionRef={sectionRef}
        options={[
          {
            title: 'Enhance with MyScribe AI',
            icon: 'new-myscribe-stars',
            onClick: () => handleEnhanceNarrative(),
            loading: isFetchingEnhanceNarrative
            // permission: {requiredPermissions: 'myscribe.create'}
          }
        ]}
        disabled={isFetchingEnhanceNarrative}
        formId={currentHpOverviewData?.[formName]?.id}
        defaultValue={currentHpOverviewData?.[formName]?.response}
        hpNoteOverviewRef={hpNoteOverviewRefs?.[formName]}
        customFormsParams={customFormsParams}
        setCurrentFormData={(newData) =>
          setCurrentHpOverviewData((prevState) => ({
            ...prevState,
            [formName]: { ...prevState[formName], response: newData }
          }))
        }
      />
    </div>
  );
};

export default SOAPNote;
