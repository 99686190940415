import React from 'react';
import { useOutletContext } from 'react-router-dom';

const FormsAI = ({ inputName, formsList, components = {}, ...rest }) => {
  const { patient } = useOutletContext();

  const Component = formsList[inputName];
  return Component ? <Component context={{ patient }} components={components} {...rest} /> : null;
};

export default FormsAI;
