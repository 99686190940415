import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';
import { kioskPaymentStop } from 'api/Kiosk';
import { socket } from 'api/Socket';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';

import state from '../state';

import PayCard from './payCard';
import PayFailure from './payFailure';
import PaySuccess from './paySuccess';

const customStyling = {
  width: '100vw',
  height: '100vh',
  borderRadius: '0px'
};

const CheckinPaymentKiosk = ({
  payWithAlternative: propPayWithAlternative,
  kioskAuthorization = 'not_configured',
  // handlePaymentConfirmationReceived = () => {},
  onSuccess,
  onCancel: propOnCancel = () => {
    console.info('On Cancel Not Configured');
  },
  promptPayment = () => {},
  onContinue,
  continueText,
  // error,
  success = false,
  cards,
  setCards,
  showOverrides,
  setShowOverrides,
  setSelectedCard,
  setReceipt = () => {}
}) => {
  const [show, setShow] = useState({
    loading: false,
    callToAction: false,
    message: '',
    messageTitle: '',
    messageKind: ''
  });
  const user = useRecoilValue(state.userState);
  const timeoutRef = useRef();
  const [transactionId, setTransactionId] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    initSocket(kioskAuthorization);
    return () => {
      socket.emit('');
      socket.off('kioskPaymentScreenMessage');
    };
  }, [kioskAuthorization]);

  useEffect(() => {
    if (!socket.connected) {
      socket.connect();
    }
  }, [socket.connected]);

  const payWithAlternative = async () => {
    const data = await kioskPaymentStop(navigate, {});
    if (data?.transaction?.status) {
      if (data?.transaction?.status === 'completed') {
        setShow((ps) => ({
          ...ps,
          message: data?.message,
          messageTitle: 'Success',
          messageKind: 'success'
        }));
        setTransactionId(data?.transaction?.id);
        onSuccess && onSuccess(data?.transaction?.id);
      } else {
        setShow((ps) => ({
          ...ps,
          message: data?.message,
          messageTitle: 'Processing Error',
          messageKind: 'error'
        }));
        propPayWithAlternative && propPayWithAlternative();
      }
    } else {
      propPayWithAlternative && propPayWithAlternative();
    }
  };

  const onCancel = async () => {
    const data = await kioskPaymentStop(navigate, {});
    if (data?.transaction?.status) {
      if (data?.transaction?.status === 'completed') {
        setShow((ps) => ({
          ...ps,
          message: data?.message,
          messageTitle: 'Success',
          messageKind: 'success'
        }));
        setTransactionId(data?.transaction?.id);
        onSuccess && onSuccess();
        setReceipt && setReceipt(data?.receipt?.data);
      } else {
        setShow((ps) => ({
          ...ps,
          message: data?.message,
          messageTitle: 'Processing Error',
          messageKind: 'error'
        }));
      }
    } else {
      propOnCancel && propOnCancel();
    }
  };

  const Continue = () => {
    if (onContinue) {
      onContinue();
    } else {
      socket.emit('kioskClientMessage', { kind: 'leave' });
      requestApi({ url: '/api/user/logout', navigate, params: {} });
    }
  };

  const initSocket = (kAuth) => {
    try {
      if (kAuth === 'not_configured') {
        return;
      }

      if (!kAuth) {
        payWithAlternative && payWithAlternative();
        return;
      }

      // this is a kiosk, a payment should've been prompted
      timeoutRef.current = setTimeout(async () => {
        // if a transaction hasn't been
        // onError();
        const data = await kioskPaymentStop(navigate, {});
        if (data?.transaction?.status) {
          if (data?.transaction?.status === 'completed') {
            setShow((ps) => ({
              ...ps,
              message: data?.message,
              messageTitle: 'Success',
              messageKind: 'success'
            }));
            setTransactionId(data?.transaction?.id);
            onSuccess && onSuccess(data?.transaction?.id);
          } else {
            setShow((ps) => ({
              ...ps,
              message: data?.message,
              messageTitle: 'Processing Error',
              messageKind: 'error'
            }));
          }
        } else {
          setShow((ps) => ({
            ...ps,
            message: 'Terminal not connected, please try again.',
            messageKind: 'error'
          }));
        }
      }, 30000);

      socket.on('kioskPaymentScreenMessage', (data) => {
        Honeybadger.notify(
          `kioskPaymentScreenMessage: ${typeof data === 'string' ? data : JSON.stringify(data)}`
        );
        if (data?.transactionId) {
          setTransactionId(data?.transactionId);
        }
        switch (data?.kind) {
          case 'handlePaymentConfirmationReceived':
            handlePaymentConfirmationReceived();
            break;
          case 'switchToAlternative':
            payWithAlternative && payWithAlternative();
            break;
          case 'success':
            setShow((ps) => ({
              ...ps,
              message: data?.message,
              messageTitle: 'Success',
              messageKind: 'success'
            }));
            onSuccess && onSuccess(data?.transactionId);
            break;
          case 'error':
            setShow((ps) => ({
              ...ps,
              message: data?.message,
              messageTitle: 'Processing Error',
              messageKind: 'warning'
            }));
            break;
        }
      });

      socket.emit('kioskJoinClient', { kioskAuthorization: kAuth });
    } catch (e) {
      console.error(e);
    }
  };

  const handlePaymentConfirmationReceived = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  return (
    <Modal
      isOpen={true}
      disableHeader={true}
      footer={null}
      isFooter={false}
      handleClose={onCancel}
      customStyling={customStyling}>
      <div className="flex flex-col items-center justify-center !p-0">
        {['error', 'warning'].includes(show.messageKind) ? (
          <PayFailure
            payWithAlternative={payWithAlternative}
            promptPayment={() => {
              promptPayment();
              setShow((ps) => ({
                ...ps,
                message: '',
                messageTitle: '',
                messageKind: ''
              }));
            }}
            onCancel={onCancel}
            errorMessage={show.message}
          />
        ) : success || show.messageKind === 'success' ? (
          <PaySuccess
            onCancel={() => Continue()}
            transactionId={transactionId}
            continueText={continueText}
          />
        ) : (
          <PayCard
            cards={cards}
            setCards={setCards}
            payWithAlternative={payWithAlternative}
            user={user}
            onCancel={onCancel}
            show={showOverrides || show}
            setShow={setShowOverrides || setShow}
            setSelectedCard={setSelectedCard}
          />
        )}
      </div>
    </Modal>
  );
};

export default withErrorBoundary(CheckinPaymentKiosk);
