import React, { useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { clinicalNote as clinicalNoteState } from 'components/state';

import Accordion from '../../../../shared/Accordion';

import Assessment from './Assessment';

const AssessmentSection = ({
  components,
  sectionRef,
  options,
  showDisclaimer = true,
  withStyle = true
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const assessmentRef = useRef(null);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.assessment?.title}
      id={components?.assessment?.id}
      disabled={clinicalNote?.locked}
      options={options}
      withStyle={withStyle}>
      <Assessment
        assessmentRef={assessmentRef}
        sectionRef={sectionRef}
        showDisclaimer={showDisclaimer}
      />
    </Accordion>
  );
};

export default AssessmentSection;
