import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteTreatmentPlan } from 'api/TreatmentPlan';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { io } from 'lib/helpers/utility';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useServices } from 'lib/hooks/queries/services/useServices';
import { useTreatmentPlan } from 'lib/hooks/queries/treatmentPlan/useTreatmentPlan';

import {
  DEFAULT_FILTERS,
  formatFilters
} from 'components/practice/PracticeIQueue/TreatmentPlan/lib/helpers';
import TPModal from 'components/practice/charts/ClinicalNote/TreatmentPlan/TPModal';
import SignModal from 'components/practice/charts/ClinicalNote/shared/Sign/SignModal';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Modal from 'components/shared/Modal/Modal';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';
import TableCounter from 'components/shared/Table/TableCounter';

import DeleteTreatmentPlanConfirm from './DeleteTreatmentPlanConfirm';
import FooterWrapperTreatmentPlan from './FooterWraperTreatmentPlan';
import TreatmentPlanTable from './TreatmentPlanTable';
import { TreatmentPlanWidgetHeader } from './TreatmentPlanWidgetHeader';

export function TreatmentPlanMainTable({
  props,
  treatmentPlanToDelete,
  setTreatmentPlanToDelete,
  showTPModal,
  setShowTPModal,
  fromPracticeIQueue = false
}) {
  const { id } = useParams();
  const navigate = useNavigate();
  const { limit, page, sort, setPage, filters, setFilters } = useTableContext();
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showAllRowsTable, setShowAllRowsTable] = useState(false);
  const [tpDeleteLoading, setTpDeleteLoading] = useState(false);
  const category = fromPracticeIQueue ? 'treatment_plan_iqueue' : '';

  const queryClient = useQueryClient();

  const params = fromPracticeIQueue
    ? {
        filters: formatFilters(filters),
        limit,
        page,
        sort,
        withRelations: { createdBy: true, document_sign: true },
        withCount: true
      }
    : {
        patientId: id,
        withRelations: { createdBy: true, document_sign: true }
      };

  const { data: providers = {} } = usePractitioners({}, { enabled: fromPracticeIQueue }, []);

  const { data: { data: treatmentPlan } = {}, data: { count } = 0 } = useTreatmentPlan(params);
  const { data: procedures } = useServices({
    options: {
      enabled: fromPracticeIQueue
    }
  });
  const { data: icdList } = useICD10Codes({
    params: { withMostUserSearchClicks: true },
    options: {
      enabled: fromPracticeIQueue
    }
  });

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.provider.options = providerOptions || [];

  const serviceOptions = useMemo(() => {
    if (procedures?.services?.length > 0) {
      return procedures.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [procedures]);
  filters.cpt.options = serviceOptions || [];

  const icdOptions = useMemo(() => {
    if (icdList?.icd10?.length > 0) {
      return icdList?.icd10?.map((i) => ({
        label: i?.name,
        value: i?.code
      }));
    }
    return [];
  }, [icdList]);

  filters.icd.options = icdOptions || [];

  const handleDeleteTreatmentPlan = async () => {
    setTpDeleteLoading(true);
    if (treatmentPlanToDelete) {
      await mutateDeleteTreatmentPlan.mutateAsync();
    }
    setTpDeleteLoading(false);
  };

  const mutateDeleteTreatmentPlan = useMutation({
    mutationFn: () =>
      deleteTreatmentPlan(navigate, {
        treatmentId: treatmentPlanToDelete
      }),
    onSuccess: () => {
      setTreatmentPlanToDelete(null);
      showAlert({
        title: `Treatment Plan`,
        message: 'Treatment plan deleted successfully!',
        color: 'success'
      });

      queryClient.invalidateQueries(['treatmentPlan', [params]]);
    }
  });

  const onRowClicked = (event) => {
    if (event?.event?.target?.closest('.actions-column')) {
      return;
    }
    setShowTPModal({ open: true, loadView: 'preview' });
    setSelectedTreatmentPlan(event?.data);
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      {!fromPracticeIQueue && (
        <TreatmentPlanWidgetHeader setShowTPModal={setShowTPModal} props={props} />
      )}

      <Allowed
        requiredPermissions="clinical_note.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col overflow-y-auto">
          {fromPracticeIQueue && (
            <Header title="Treatment Plans">
              <div className="flex items-center gap-2">
                <DisplayButton />
                <Filter
                  category={category}
                  defaultFilters={DEFAULT_FILTERS}
                  filters={filters}
                  setFilters={setFilters}
                  menuPortalTarget={document.body}
                  btnClassName="!h-[30px]"
                />
              </div>
            </Header>
          )}
          {!fromPracticeIQueue && (
            <FooterWrapperTreatmentPlan
              treatmentPlan={treatmentPlan}
              setShowAllRowsTable={setShowAllRowsTable}
              onRowClicked={onRowClicked}
            />
          )}

          {showConfirmationModal && (
            <SignModal
              setShowConfirmationModal={setShowConfirmationModal}
              showConfirmationModal={showConfirmationModal}
              documentId={selectedTreatmentPlan?.id}
              documentTypes={['treatment_plan']}
              asPatient={true}
            />
          )}

          {fromPracticeIQueue && (
            <TreatmentPlanTable data={treatmentPlan} onRowClicked={onRowClicked} />
          )}

          {treatmentPlanToDelete && (
            <DeleteTreatmentPlanConfirm
              treatmentPlanToDelete={treatmentPlanToDelete}
              loading={tpDeleteLoading}
              handleClose={() => setTreatmentPlanToDelete(null)}
              handleDeleteTreatmentPlan={handleDeleteTreatmentPlan}
            />
          )}

          {fromPracticeIQueue && (
            <div className="flex items-center justify-between px-3">
              <TableCounter page={page} limit={limit} count={count && count} />
              <Pagination
                onPageChange={({ selected }) => setPage(selected + 1)}
                totalItems={count ? count : null}
                page={page}
                perPage={limit}
              />
            </div>
          )}
          <Modal
            handleOpen={showAllRowsTable && !fromPracticeIQueue}
            className="w-[91%] bg-white sm:w-[96%] md:w-[96%] lg:w-[96%] xxl:max-w-[1920px]"
            bodyClassName="p-0"
            title="Treatment Plans"
            slideFromRight
            handleClose={() => setShowAllRowsTable(false)}>
            <TreatmentPlanTable data={treatmentPlan} onRowClicked={onRowClicked} />
          </Modal>

          {io(showTPModal) && showTPModal?.open && (
            <TPModal
              loadView={showTPModal?.loadView}
              isOpen={showTPModal?.open}
              handleClose={() => setShowTPModal({ open: false, loadView: 'list' })}
              outsideCN={true}
              initialView={showTPModal?.loadView !== 'list'}
            />
          )}
        </div>
      </Allowed>
    </div>
  );
}
