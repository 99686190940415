import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';

import { impersonate } from 'api/Admin/Practices/Members';
import { requestApi } from 'api/Api';

import { showAlert } from 'components/shared/Alert/Alert';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

export default function PractitionersBasicInfo() {
  const { practitioner } = useOutletContext();
  const [show, setShow] = useState({ loading: false, confirmImpersonate: false });
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState({
    password: false,
    clearingHouse: false
  });
  const [confirmPassword, setConfirmPassword] = useState();
  const [clearingHouseModal, setClearingHouseModal] = useState(false);
  const [clearingHouseData, setClearingHouseData] = useState({
    npi: null,
    tax_id: null,
    tax_id_type: null,
    taxonomy: null,
    atypical_id: null
  });
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (practitioner.id) {
      getClearingHouseData();
    }
  }, [practitioner]);

  const getClearingHouseData = async () => {
    try {
      const res = await requestApi({
        url: '/api/admin/claimmd/get',
        params: {
          practitionerId: id
        },
        navigate
      });
      const { code, error, practitionerInfo: info } = res;
      switch (code) {
        case -1:
          // push redirect
          break;
        case 0:
          if (info) {
            setClearingHouseData(info);
          }
          break;
        default:
          Honeybadger.notify(
            `file: practitioners/PractitionersBasicInfo, method: getClearingHouseData - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error) showAlert({ title: error, color: 'danger' });
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: practitioners/PractitionersBasicInfo, method: getClearingHouseData - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
      console.error(error);
    }
  };

  const updatePassword = async () => {
    setLoading({ ...loading, password: true });
    if (
      password === confirmPassword &&
      typeof password === 'string' &&
      password.trim().length !== 0
    ) {
      try {
        const res = await requestApi({
          url: `/api/user/update`,
          params: {
            user_id: id,
            updatedPassword: password
          },
          navigate
        });
        if (res.success) {
          showAlert({ title: 'Password updated successfully', color: 'success' });
        }
      } catch (error) {
        console.error(error);
        Honeybadger.notify(
          `file: practitioners/PractitionersBasicInfo, method: updatePassword - catch, error: ${
            error ?? 'Theres been an error'
          }`
        );
        showAlert({ tittle: 'There was an error updating the password', color: 'danger' });
      }
    } else {
      showAlert({ title: 'Incorrect password', color: 'danger' });
    }
    setLoading({ ...loading, password: false });
  };

  const updateClearingHouse = async () => {
    setLoading({ ...loading, clearingHouse: true });
    try {
      if (clearingHouseData?.npi?.length !== 10) {
        showAlert({ title: 'NPI must be exactly 10 digits.', color: 'danger' });
        setLoading({ ...loading, clearingHouse: false });
        return;
      }

      if (clearingHouseData?.tax_id?.length !== 9) {
        showAlert({ title: 'Tax ID must be exactly 9 digits.', color: 'danger' });
        setLoading({ ...loading, clearingHouse: false });
        return;
      }

      if (!isNaN(Number(clearingHouseData.tax_id_type))) {
        showAlert({
          title: 'Tax ID Type should not be a number.It should be (E = EIN or S = SSN) ',
          color: 'danger'
        });
        setLoading({ ...loading, clearingHouse: false });
        return;
      }

      const res = await requestApi({
        url: '/api/admin/claimmd/upsert',
        params: {
          info: clearingHouseData,
          practitionerId: practitioner.id
        },
        navigate
      });
      const { code, error, success } = res;
      switch (code) {
        case -1:
          // push redirect
          break;
        case 0:
          if (success) {
            setClearingHouseModal(false);
            showAlert({ title: 'Info updated Successfully.', color: 'success' });
          }
          break;
        default:
          Honeybadger.notify(
            `file: practitioners/PractitionersBasicInfo, method: updateClearingHouse - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error) showAlert({ title: error, color: 'danger' });
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: practitioners/PractitionersBasicInfo, method: updateClearingHouse - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
      console.error(error);
    }
    setLoading({ ...loading, clearingHouse: false });
  };

  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setClearingHouseData({ ...clearingHouseData, [field]: value });
  };

  const impersonateUser = async () => {
    try {
      await impersonate(navigate, {
        targetId: practitioner.id
      });
      setShow({ ...show, loading: false, confirmImpersonate: false });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: practitioners/PractitionersBasicInfo, method: impersonateUser - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  return (
    <>
      {practitioner && (
        <div className="grid grid-cols-3 gap-5 !p-5">
          <Allowed requiredPermissions="member.update">
            <Box>
              <p className="pb-3 text-lg font-semibold">Change Password</p>
              <div className="grid gap-3">
                <Input
                  data-qa="password"
                  label="Password"
                  type="password"
                  value={password}
                  onChange={(p) => setPassword(p.target.value)}
                />
                <Input
                  data-qa="confirm-password"
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(p) => setConfirmPassword(p.target.value)}
                />
                <Button
                  className="mt-2"
                  text="Update Password"
                  loading={loading.password}
                  onClick={() => updatePassword()}
                  data-qa="update-password-btn"
                />
              </div>
            </Box>
          </Allowed>
          <Allowed requiredPermissions="billing.read">
            <Box className="grid h-max gap-3">
              <p className="pb-3 text-lg font-semibold">Clearinghouse Info</p>
              <Button
                text="Clearinghouse info"
                onClick={() => setClearingHouseModal(true)}
                data-qa="clearinghouse-info"
              />
            </Box>
          </Allowed>
          <Allowed requiredPermissions="impersonate.create">
            <Box className="grid h-max gap-3">
              <p className="pb-3 text-lg font-semibold">Impersonate User</p>
              <Button
                text="Impersonate"
                onClick={() => setShow({ ...show, confirmImpersonate: true })}
                data-qa="impersonate-user"
              />
            </Box>
          </Allowed>
          <Confirm
            variant="primary"
            primaryBtnTxt="Confirm"
            title="Achtung!"
            icon="new-eye"
            message="You're about to impersonate a provider. Your permissions are limited per HIPAA compliance and activity is logged."
            handleContinue={() => impersonateUser()}
            handleOpen={show?.confirmImpersonate}
            handleClose={() => setShow({ ...show, confirmImpersonate: false })}
          />
          <Modal
            slideFromRight
            handleOpen={clearingHouseModal}
            handleClose={() => setClearingHouseModal(false)}
            title="Claim MD Practitioner Info"
            footer={
              <Button
                text="Update"
                loading={loading.clearingHouse}
                onClick={() => updateClearingHouse()}
              />
            }>
            <div className="grid grid-cols-3 gap-4">
              <Input
                data-qa="npi"
                label="NPI"
                name="npi"
                value={clearingHouseData.npi}
                onChange={handleChange}
              />
              <Input
                data-qa="tax_id"
                label="Tax ID"
                name="tax_id"
                value={clearingHouseData.tax_id}
                onChange={handleChange}
              />
              <Input
                data-qa="tax_id_type"
                label="Tax ID Type (E = EIN, S = SSN)"
                name="tax_id_type"
                value={clearingHouseData.tax_id_type}
                onChange={handleChange}
              />
              <Input
                data-qa="taxonomy"
                label="Taxonomy"
                name="taxonomy"
                value={clearingHouseData.taxonomy}
                onChange={handleChange}
              />
              <Input
                data-qa="atypical_id"
                label="Atypical ID"
                name="atypical_id"
                value={clearingHouseData.atypical_id}
                onChange={handleChange}
              />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
}
