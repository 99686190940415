import React from 'react';

import { mString } from '../../../../../lib/helpers/utility';

export const costShareInfo = (last_applied, type) => {
  let label = '';
  let explainPercentage = '';

  const { value, percentage, modified_value } = last_applied?.[type] || {};

  if (modified_value !== undefined) {
    label = `user overwrite ${mString(modified_value)}`;
  } else {
    const typeLabel = {
      insurance: 'insurance profile',
      eligibility: 'eligibility response',
      era: 'ERA'
    };

    label = last_applied?.type ? `${typeLabel[last_applied.type]} ` : '';

    if (percentage) {
      label = label + ` (${percentage}%)`;

      explainPercentage = `${percentage}% of ${mString(
        last_applied?.co_insurance?.primaryAmount
      )} (Allowable Amount) = ${mString(value)}`;
    } else {
      percentage ? percentage + '%' : mString(value);
      label = label + `(${mString(value)})`;
    }
  }

  return (
    <>
      <p className="text-xs">{`Applied from ${label}.`}</p>
      {explainPercentage && <p className="text-xs">{explainPercentage}.</p>}
    </>
  );
};
