import { useQuery } from '@tanstack/react-query';
import { getResources } from 'api/Resource';
import { useNavigate } from 'react-router-dom';

export const useResources = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['resources', ...dependencies], () => getResources(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
