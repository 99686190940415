import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../../../../shared/Icon/Icon';
import Confirm from '../../../../shared/Modal/Confirm/Confirm';
import Status from '../../../../shared/Status/Status';
import { requestApi } from '../../../../../api/Api';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from '../../../../shared/Alert/Alert';
import toast from 'react-hot-toast';
import Allowed from 'components/shared/Permissions/Allowed';

const ProductTableRow = ({ product, key, setProductToBeUpdated, filters, lastIndex }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const deleteProduct = async () => {
    const onSuccess = (data) => {
      const { product: productUpdated } = data;
      queryClient.setQueryData(
        ['getProducts', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            // products: []
            products: prevData?.products.filter((prod) => prod.id !== productUpdated.id)
          };
        }
      );
      toast.success('Product deleted successfully');

      setShowConfirmationModal(false);
    };
    const onError = (error, code) => {
      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete products!",
          color: 'danger'
        });
    };
    await requestApi({
      url: 'api/product/update',
      params: {
        product: { ...product, status: 'deleted' }
      },
      onSuccess,
      onError,
      navigate
    });
  };

  return (
    <>
      <tr key={`service-entry-index-${key}-view`}>
        <td>
          <div className="flex h-full items-center gap-2 px-2">
            <div style={{ backgroundColor: product.color }} className="h-6 w-6 rounded-lg"></div>{' '}
            <p>{product.code}</p>
          </div>
        </td>
        <td>{product.name}</td>
        <td>{product.category?.name}</td>

        <td>{product.quantity}</td>
        <Allowed requiredPermissions="payments.read">
          <td>${product.amount_cents / 100}</td>
        </Allowed>
        <td>{product.sales_count}</td>
        <td>{product.lot}</td>

        <td>
          <Status status={product.status} className="w-max" />
        </td>
        <td className="relative">
          <Popover className="relative">
            {({ open, close }) => (
              <div>
                <Popover.Button
                  data-qa={`product-row-${product.code}`}
                  className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                    !open && 'hover:!bg-white'
                  } ${open && '!bg-primary-700 transition-all'}`}>
                  <Icon
                    icon="three-dots"
                    className="flex cursor-pointer"
                    color={open ? 'white' : 'black'}
                  />
                </Popover.Button>

                <Popover.Panel
                  className={`absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white ${
                    lastIndex ? '!-top-[90px]' : 'top-10'
                  } !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`}>
                  <div
                    data-qa="edit-product-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      setProductToBeUpdated(product);
                      close();
                    }}>
                    <Icon icon="new-edit" color="primary" />
                    <div className="ml-1 text-sm text-primary-900">Edit</div>
                  </div>
                  <div
                    data-qa="delete-product-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      close();
                    }}>
                    <Icon icon="trash" color="primary" />
                    <div className="ml-1 text-sm text-primary-900">Delete</div>
                  </div>
                </Popover.Panel>
              </div>
            )}
          </Popover>
        </td>
      </tr>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={deleteProduct}
        title="Delete product"
        message="Are you sure you want to delete this product?"
        variant="danger"
      />
    </>
  );
};

export default ProductTableRow;
