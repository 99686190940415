import { showAlert } from 'components/shared/Alert/Alert';
import { calculateBaseUnit } from './calculateBaseUnit';
import { calculateUnitTimeLength } from './calculateUnitTimeLength';

export const generateBaseUnit = ({
  procedure,
  index,
  formattedStartTime,
  formattedEndTime,
  updateProcedures
}) => {
  try {
    const base_unit = calculateBaseUnit(
      procedure.start_time || formattedStartTime,
      procedure.end_time || formattedEndTime,
      procedure.base_unit
    );
    const unit_time_length = calculateUnitTimeLength(
      procedure.start_time || formattedStartTime,
      procedure.end_time || formattedEndTime
    );

    if (base_unit == 0) {
      showAlert({
        title: 'Calculate Unit',
        message: 'Start time cannot be later than end time. Please check the time inputs.',
        color: 'warning'
      });
      return;
    }

    const new_unit = base_unit > 0 ? Number(base_unit) : Number(procedure.units);

    if (isNaN(new_unit) || new_unit < 0) {
      showAlert({ title: 'Base Unit', message: 'Invalid base unit calculation.', color: 'danger' });
      return;
    }

    updateProcedures({ target: { value: new_unit, name: 'units' } }, index);
    updateProcedures({ target: { value: unit_time_length, name: 'unit_time_length' } }, index);
  } catch (error) {
    console.error('Error generating base unit', error);
    showAlert({
      title: 'Base Unit',
      message: 'There was an error calculating the unit. Please check the time inputs.',
      color: 'danger'
    });
  }
};
