import { Honeybadger } from '@honeybadger-io/react';
import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import Button from 'components/shared/Buttons/Button';

export default function PreviewForm({ json, formId, practiceId, answers, handleClose }) {
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    try {
      let params = {
        practice_id: practiceId,
        form_id: formId,
        json: { fields: JSON.stringify(e) },
        questionnaire: true
      };
      let res = await interimApi('/api/form/create_response', params, navigate);
      if (res.status == 200) {
        handleClose();
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div>
      <div className="block !p-6">
        <ReactFormGenerator
          data={json}
          answer_data={answers && answers}
          read_only={answers}
          onSubmit={(e) => handleSubmit(e)}
          submitButton={answers ? <div /> : <Button text="Submit" buttonType="submit" />}
        />
      </div>
    </div>
  );
}
