import React, { useEffect, useState } from 'react';
import { DashboardContext } from './DashboardContext';
import {
  apptDefaultFilters,
  searchPatients,
  searchServices
} from 'components/practice/Dashboard/widgets/UpcomingAppointments/Calendar/components/Navigation/components/Filters';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { currentPractice } from 'components/practice/practiceState';
import { useRecoilValue } from 'recoil';
import returnAppliedFilter from 'components/shared/Filters/lib/returnAppliedFilter';
import { useDefaultFilters } from 'lib/hooks/queries/useDefaultFilters';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';

export const DashboardContextProvider = ({ children }) => {
  const currentDate = new Date();
  const currentMonthStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
  const { data: loadPractitioners } = usePractitioners();
  const { data: loadResources } = useResourcesAsOptions();
  const practice = useRecoilValue(currentPractice);

  const [ranges, setRanges] = useState({
    startDate: currentMonthStartDate,
    endDate: currentDate
  });

  const { data: savedFilters = {} } = useDefaultFilters({});

  const [filters, setFilters] = useState(
    returnAppliedFilter(
      apptDefaultFilters({
        currentPractice: practice,
        loadPractitioners,
        loadResources: loadResources?.resources,
        searchPatients,
        searchServices
      }),
      savedFilters['dashboard_calendar'] || null
    )
  );

  useEffect(() => {
    setFilters(
      returnAppliedFilter(
        apptDefaultFilters({
          currentPractice: practice,
          loadPractitioners,
          loadResources: loadResources?.resources,
          searchPatients,
          searchServices
        }),
        savedFilters['dashboard_calendar'] || null
      )
    );
  }, [savedFilters['dashboard_calendar']]);

  const [currentModal, setCurrentModal] = useState({ show: false, id: null, type: '' });
  const [confirmModal, setConfirmModal] = useState({ show: false, id: null, type: '' });

  return (
    <DashboardContext.Provider
      value={{
        ranges,
        setRanges,
        filters,
        setFilters,
        currentModal,
        setCurrentModal,
        confirmModal,
        setConfirmModal
      }}>
      {children}
    </DashboardContext.Provider>
  );
};
