import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { deleteVital, upsertVital } from 'api/Vitals';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { userState } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from 'lib/helpers/utility';
import _, { isEqual } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import VitalOverviewHeader from './components/VitalPatientOverviewHeader';
import VitalRow from './components/VitalRow';

import { useRecoilValue } from 'recoil';
import {
  checkIfExistedBefore,
  doEmptyVitalExist,
  initialVitals,
  updateVitalsOrReset
} from './lib/helpers';
const Vitals = forwardRef(
  (
    {
      lastVitals = {},
      setAssignToCn = () => null,
      assignToCn = false,
      setLastVitals,
      vitals,
      setVitals,
      hideVitalsModal = () => null,
      fromOverview = false,
      // customFormsParams,
      setOverviewVitals,
      isGrowthChartEnabled = false,
      fieldToFocus = ''
    },
    ref
  ) => {
    const { setSaveButtonText = () => null, updateSaveButton = () => null } =
      useClinicalNoteContext() || {};
    const [changes, setChanges] = useState(false);
    const queryClient = useQueryClient();
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [unsavedConfirmation, setUnsavedConfirmation] = useState(false);
    const { id, appointmentId } = useParams();
    const navigate = useNavigate();
    const [currentUnit, setCurrentUnit] = useState(lastVitals?.head_circumference_unit || 'in');
    const currentUser = useRecoilValue(userState);

    useEffect(() => {
      if (ia(lastVitals?.vitals)) {
        setVitals(lastVitals);
        return;
      }
    }, [lastVitals]);
    const saveVitals = async () => {
      if (!changes || isEqual(lastVitals?.vitals, vitals?.vitals)) return;
      const emptyVitals = doEmptyVitalExist({
        newVital: initialVitals()?.vitals[0],
        vitals: vitals?.vitals
      });
      if ((ia(vitals?.vitals, 1) && emptyVitals) || (fromOverview && emptyVitals)) {
        showAlert({
          color: 'warning',
          message: 'Please fill the current vital before saving'
        });
        return;
      } else if (ia(vitals?.vitals) && emptyVitals) {
        return;
      }

      const appointment_id = appointmentId ? appointmentId : vitals?.appointment_id || null;
      const params = {
        patientId: id,
        vitals,
        appointmentId: appointment_id
      };
      try {
        setSaveButtonText('Saving');
        const onSuccess = (data) => {
          const userType = data?.newVitals ? 'insert' : 'update';
          const vitalsWithId = {
            ...vitals,
            appointment_id: Number(appointment_id),
            created_by: {
              id: currentUser?.user_id,
              fullName: `${currentUser?.f_name} ${currentUser?.l_name}`
            },
            vitals: data?.newVitals
          };

          showAlert({ message: 'Vitals saved successfully', color: 'success' });
          if (fromOverview) queryClient.invalidateQueries(['vitals']);
          hideVitalsModal();
          setLastVitals((prev) => ({
            ...prev,
            vitals: {
              ...vitalsWithId
            }
          }));

          setOverviewVitals((prev) => {
            const updatedVitals = prev?.map((vital) => {
              if (vital?.appointment_id === appointment_id) {
                return vitalsWithId;
              }
              return vital;
            });

            if (userType === 'insert') {
              const excludeVitalsWithSameAppId = updatedVitals.filter(
                (vital) => vital?.appointment_id != appointmentId
              );
              return [vitalsWithId, ...excludeVitalsWithSameAppId];
            }
            return updatedVitals;
          });
        };

        const onError = (error) => {
          showAlert({ title: error, color: 'danger' });
        };

        await requestApi({ url: '/api/clinical_note/vitals/create', params, onSuccess, onError });
      } catch (error) {
        Honeybadger.notify(
          `Clinical note save vitals -  catch block - patientId: ${id}, error: ${error}`
        );
      }
      updateSaveButton();
      setChanges(false);
    };

    const resetVitals = () => {
      setVitals(initialVitals());
      setLastVitals((prev) => ({
        ...prev,
        vitals: initialVitals()
      }));
      setChanges(true);
    };
    const resetVitalsOverview = () => {
      setUnsavedConfirmation(false);
      setVitals((prevVitals) => ({
        ...prevVitals,
        vitals: initialVitals()?.vitals
      }));

      return;
    };
    const handleAddVitalButtonOverview = () => {
      if (changes) {
        setUnsavedConfirmation(true);
      } else {
        resetVitalsOverview();
      }
    };

    const handleAssignToCn = async () => {
      const appointmentId = vitals?.appointment_id;
      const { appointment_id, ...compVitals } = vitals;
      if (isEqual(compVitals, initialVitals())) {
        showAlert({
          color: 'warning',
          message: 'Please fill the current vital before assigning to a clinical note'
        });
        return;
      }

      if (!vitals?.appointment_id) {
        showAlert({
          message: 'Please select the clinical note to assign',
          color: 'warning'
        });
        return;
      }
      const existingOne = checkIfExistedBefore(vitals);
      const onSuccess = () => {
        showAlert({
          message: `Vital assigned ${!existingOne ? 'and saved' : ''} successfully`,
          color: 'success'
        });
        if (fromOverview) queryClient.invalidateQueries(['vitals']);
        !existingOne && resetVitals();

        setAssignToCn(false);
      };

      const onError = (error) => {
        showAlert({ title: error, message: 'Assign Vital', color: 'danger' });
      };
      const params = {
        patientId: id,
        vitals,
        appointmentId
      };

      await upsertVital(navigate, params, onError, onSuccess);
    };

    useImperativeHandle(ref, () => ({
      saveVitals,
      resetVitals,
      handleAssignToCn
    }));
    const handleAddVital = () => {
      if (doEmptyVitalExist({ newVital: initialVitals()?.vitals[0], vitals: vitals?.vitals })) {
        showAlert({
          color: 'warning',
          message: 'Please fill the current vital before adding another one'
        });
        return;
      }
      if (fromOverview) {
        handleAddVitalButtonOverview();
        return;
      }
      const newVital = {
        ...initialVitals()?.vitals[0],
        id: self.crypto.randomUUID(),
        timestamp: new Date().toISOString()
      };

      setVitals((prev) => ({
        ...prev,
        vitals: [newVital, ...prev.vitals]
      }));
      setChanges(true);
    };

    const handleOnChange = (event, key, vitalId) => {
      const value = event?.target?.value || event?.value || null;
      setVitals((prevVitals) => {
        const updatedVitals = prevVitals.vitals.map((vital) => {
          if (vital?.id === vitalId) {
            return {
              ...vital,
              [key]: value,
              ...(key === 'head_circumference' && { head_circumference_unit: currentUnit })
            };
          }
          return vital;
        });

        return {
          ...prevVitals,
          vitals: updatedVitals
        };
      });
      setChanges(true);
    };

    const handleDeleteVital = async (vitalId) => {
      const updateVitalOrResetOnDelete = () => {
        updateVitalsOrReset({ vitalId, vitals, setVitals, resetVitals, setLastVitals });
      };
      if (typeof vitalId !== 'number') {
        updateVitalOrResetOnDelete();
      } else {
        const onSuccess = () => {
          if (!fromOverview) {
            updateVitalOrResetOnDelete();
            setLastVitals((prev) => ({
              ...prev,
              vitals: {
                ...prev.vitals,
                vitals: prev.vitals?.vitals?.filter((vital) => vital?.id !== vitalId)
              }
            }));
          } else {
            queryClient.invalidateQueries(['vitals']);
            showAlert({
              title: 'Delete Vital',
              message: 'Vital deleted successfully',
              color: 'success'
            });
            hideVitalsModal();
          }
        };
        const onError = (error) => {
          showAlert({
            title: 'Delete Vital',
            message: error || 'Error occurred while deleting vital',
            color: 'danger'
          });
        };
        await deleteVital(navigate, { vitalId }, onSuccess, onError);
      }
    };

    return (
      <div>
        <VitalOverviewHeader
          patientId={id}
          assignToCn={assignToCn}
          fromOverview={fromOverview}
          setAssignToCn={setAssignToCn}
          vitals={vitals}
          setVitals={setVitals}
        />
        <Button
          onClick={() => handleAddVital()}
          text="Add vital"
          transparent
          className="ml-auto"
          iconRight="add-circle"
          data-qa="add-vital"
          textClassName="text-primary-500"
        />
        <>
          {iaRa(vitals?.vitals)?.map((vital, index) => (
            <div key={index} className="mx-3 my-5">
              <VitalRow
                fieldToFocus={fieldToFocus}
                index={index}
                vitals={vitals}
                handleDeleteVital={handleDeleteVital}
                setChanges={setChanges}
                setConfirmDelete={setConfirmDelete}
                vital={vital}
                assignToCn={assignToCn}
                setVitals={setVitals}
                currentUnit={currentUnit}
                setCurrentUnit={setCurrentUnit}
                handleOnChange={handleOnChange}
                isGrowthChartEnabled={isGrowthChartEnabled}
              />
            </div>
          ))}
        </>
        {confirmDelete && (
          <Confirm
            variant="danger"
            primaryBtnTxt="Delete"
            title="Delete Vital"
            icon="trash"
            message="Are you sure you want to delete this vital?"
            handleContinue={() => {
              handleDeleteVital(confirmDelete);
              setConfirmDelete(null);
            }}
            handleOpen={confirmDelete}
            handleClose={() => setConfirmDelete(null)}
          />
        )}
        <Confirm
          handleOpen={unsavedConfirmation}
          handleClose={() => setUnsavedConfirmation(false)}
          handleContinue={resetVitalsOverview}
          title="Unsaved changes"
          message="You have unsaved changes. Do you want to continue with the action?"
          primaryBtnTxt="Yes"
          secondaryBtnTxt="No"
          icon="new-calendar-red"
          variant="danger"
        />
      </div>
    );
  }
);

export default Vitals;
