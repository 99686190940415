import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { formatDateAndTime, ia } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';

import EmptyProductsPackages from 'components/Patient/sell/EmptyProductsPackages';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import ListItem from 'components/practice/appointment/components/ProceduresProduct/components/ListItem';
import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import InfiniteScroll from 'components/shared/InfiniteScroll/InfiniteScroll';
import { permissions } from 'components/state';

import { SelectedItems, ServicesFromPackages } from './components';
import { handleAddItems, isProcedureChecked } from './lib';
import { emptyStateMessages } from './lib/emptyStateMessages';

const AddProceduresProducts = ({
  label,
  endpoint,
  queryKey,
  type,
  packageList = [],
  formik,
  formatData
}) => {
  const ref = useRef(null);
  const infiniteScrollRef = useRef(null);
  const navigate = useNavigate();
  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};
  const { values, setFieldValue } = formik;
  const selectedItems = [...(values[type] || [])];
  const [newItems, setNewItems] = useState([]);
  const userPermissions = useRecoilValue(permissions);

  const [list, setList] = useState();

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const { timezone, display_settings } = useRecoilValue(currentPractice);

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength,
      dos: display_settings?.claims
        ? formatDateAndTime(values?.starts_at, timezone, false, 'MM/DD/YYYY')
        : null
    });
  };

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  const isServices = type === 'procedures';

  const { title, buttonText, icon, link, createDesc } = emptyStateMessages(isServices);

  if (ia(list) && list?.length === 0)
    // If practice has no services or products
    return (
      <NotFound
        icon={icon}
        title={title}
        buttonText={buttonText}
        description={createDesc}
        onClick={() => navigate(link)}
        textWrapperClassName={isServices && '!-mt-4'}
        className="!pb-2"
      />
    );

  const handleScroll = () => {
    if (infiniteScrollRef?.current) {
      infiniteScrollRef?.current?.handleScroll();
    }
  };

  return (
    <>
      <SelectedItems type={type} list={list} isClinicalNoteLocked={isClinicalNoteLocked} />
      <Popover>
        {({ open, close }) => (
          <div ref={ref}>
            <PopoverButton
              className="absolute -top-7 right-5 cursor-pointer p-0"
              data-qa={`add-btn-type-${type}`}
              onClick={() => {
                open && handleAddItemsFunc();
                !open && setTimeout(() => scrollToElement(), 100);
              }}>
              <div
                className={cs(
                  'flex items-center gap-[6px]',
                  !ia(selectedItems) && '!justify-center pb-3'
                )}>
                <Icon icon="add-circle" size={18} />
                <label className="cursor-pointer text-sm font-500 text-primary-500">
                  {`Add ${label}`}
                </label>
              </div>
            </PopoverButton>
            <PopoverPanel
              className={cs(
                'absolute -bottom-[165px] -right-[1px] z-50 mt-[6px] flex !w-[450px] flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]'
              )}>
              <div
                ref={infiniteScrollRef}
                onScroll={handleScroll}
                className="relative flex h-[430px] flex-col gap-2 overflow-auto border-b border-neutral-300 px-2 pb-2">
                {isServices && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}
                <InfiniteScroll
                  ref={infiniteScrollRef}
                  EmptyComponent={type === 'products' ? EmptyProductsPackages : NotFound}
                  endpoint={endpoint}
                  queryKey={queryKey}
                  setData={setList}
                  formatData={formatData}
                  data={list}
                  withSearch>
                  {({ item, index }) => (
                    <ListItem
                      item={item}
                      index={index}
                      formik={formik}
                      type={type}
                      newItems={newItems}
                      permissions={userPermissions}
                      setNewItems={setNewItems}
                      isChecked={
                        type === 'procedures'
                          ? !!isProcedureChecked(newItems, item)
                          : newItems.some((sItem) => sItem.id === item.id)
                      }
                    />
                  )}
                </InfiniteScroll>
              </div>
              <div className="flex w-[calc(100%-6px)] items-center justify-between bg-white p-2 px-3 shadow-[0px_0px_16px_rgba(0,0,0,0.05)]">
                <Button
                  text="Cancel"
                  size="small"
                  outlined
                  color="neutral"
                  onClick={() => {
                    close();
                    ia(newItems) && setNewItems([]);
                  }}
                />
                <Button
                  text="Done"
                  size="small"
                  onClick={() => {
                    close();
                    handleAddItemsFunc();
                  }}
                />
              </div>
            </PopoverPanel>
          </div>
        )}
      </Popover>
    </>
  );
};

export default AddProceduresProducts;
