import React, { useState } from 'react';
import { Formik } from 'formik';
import Honeybadger from '@honeybadger-io/js';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateAppointmentV2 } from 'api/Appointment';
import { checkUnsavedChanges } from './lib/checkUnsavedChanges';
import { deleteKeys, getDirtyValues } from 'lib/helpers/utility';
import { UpdateAppointmentValidationSchema } from './lib/validationSchemas';
import Header from './components/Header/Header';
import Footer from './components/Footer';
import Modal from 'components/shared/Modal/Modal';
import UnsavedChanges from './components/UnsavedChanges';
import { showAlert } from 'components/shared/Alert/Alert';
import Details from './components/Sections/Details/Details';
import Pricing from './components/Sections/Pricing/Pricing';
import Timeline from './components/Sections/Timeline/Timeline';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const Appointment = ({ isOpen, handleClose, appointment }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isUnsaved, setIsUnsaved] = useState(false);

  const { mutateAsync: mutateAppointment } = useMutation({
    mutationFn: (data) => {
      const dirtyValues = getDirtyValues(data, appointment);
      // delete data to avoid submitting in backend
      deleteKeys({
        object: dirtyValues,
        keys: [
          'tags',
          'calculate',
          'timeline',
          'timeline_bar',
          'isPaymentActive',
          'arrived_data',
          'appointment_disabled'
        ]
      });

      return updateAppointmentV2(
        navigate,
        {
          ...dirtyValues,
          id: data.id
        },
        data
      );
    },
    onSuccess: async (data) => {
      const { code, updatedAppointment, error } = data;

      if (code === 0) {
        if (appointment?.id === updatedAppointment?.id) {
          queryClient.setQueryData(['appointmentv2', updatedAppointment?.id], (oldData) => {
            return {
              ...oldData,
              appointment: updatedAppointment
            };
          });
          // Appointment multiple
          queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
            ...oldData,
            appointment: oldData.appointment.map((apt) =>
              apt.id === updatedAppointment.id ? updatedAppointment : apt
            )
          }));
          showAlert({ color: 'success', message: 'Appointment updated successfully!' });
        } else {
          queryClient.invalidateQueries(['appointment-events']);
          // When we rescheduling an appointment
          handleClose();
          showAlert({
            message: 'Appointment rescheduled successfully!',
            type: 'success',
            buttons: [
              {
                text: 'View',
                onClick: () => navigate(`/portal/appointments/${updatedAppointment?.id}`)
              }
            ]
          });
        }

        await queryClient.invalidateQueries(['apt-timeline-details']);
      } else if (code === 4) {
        error?.map((errorMessage) => showAlert({ message: errorMessage, color: 'danger' }));
      }
    },
    onError: (error) => Honeybadger.notify(`Appointment update error: ${error}`)
  });

  const handleUnsaved = (values, initialValues) => {
    const isUnsaved = checkUnsavedChanges(values, initialValues);
    setIsUnsaved(isUnsaved);
    if (!isUnsaved) handleClose();
  };

  const handleOnSubmit = ({ values, resetForm }) => {
    mutateAppointment(values);
    resetForm();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={appointment}
      validationSchema={UpdateAppointmentValidationSchema}
      onSubmit={(values, { resetForm }) => handleOnSubmit({ values, resetForm })}>
      {({ values, initialValues }) => (
        <Modal
          isOpen={isOpen}
          handleClose={() => handleUnsaved(values, initialValues)}
          isLarge
          slideFromRight
          disableHeader
          className="flex flex-col bg-white"
          headerClassName="sm:items-start"
          bodyClassName="p-0 overflow-hidden flex flex-col h-full"
          footerClassName="mt-auto h-max z-10"
          footer={<Footer handleClose={handleClose} appointment={values} />}>
          <Header appointment={appointment} handleClose={handleClose} />
          <div className="flex h-full flex-col overflow-y-auto overflow-x-hidden">
            <Details appointment={appointment} />
            <Pricing />
            <Timeline />
          </div>
          <UnsavedChanges {...{ isUnsaved, setIsUnsaved, handleClose }} />
        </Modal>
      )}
    </Formik>
  );
};

export default withErrorBoundary(Appointment);
