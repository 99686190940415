import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import { ReactFormGenerator } from 'react-form-builder2';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const ViewForm = ({ form, setViewFormModal, viewFormModal }) => {
  const { device } = useUIContext();
  const closeViewFormModal = () => {
    setViewFormModal(false);
  };

  return (
    <Modal
      slideFromRight
      title={form?.name}
      isOpen={viewFormModal}
      handleClose={closeViewFormModal}
      className={cs(
        '!bg-white',
        device === 'tablet-sm' ? 'w-[729px]' : device === 'tablet' ? 'w-[984px]' : 'w-[1240px]'
      )}>
      <div className="relative">
        <div className="cursor-not-allowed content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30">
          <ReactFormGenerator data={form?.json?.fields || []} hide_actions read_only />
        </div>
      </div>
    </Modal>
  );
};

export default ViewForm;
