import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

const Notes = ({ customClinicalNote = null }) => {
  const { clinicalNote } = customClinicalNote ?? useClinicalNoteContext();

  if (customClinicalNote && !clinicalNote?.notes) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <SanitizeComponent value={clinicalNote?.notes} title="Notes" />
    </div>
  );
};

export default Notes;
