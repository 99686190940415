import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { createServices } from 'api/Service';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import ServiceModal from 'components/practice/settings/Services/components/ServiceModal';
import AGTable from 'components/shared/AGTable/AGTable';

import StatusBarAggregation from './StatusBarAggregation';
import Header from './components/Header';
import {
  ScheduleOfFeesContextProvider,
  useScheduleOfFeesContext
} from './context/ScheduleOfFeesProvider';
import {
  CREATE_SERVICES_ERROR_MESSAGE,
  CREATE_SERVICES_QUERY_KEY,
  CREATE_SERVICES_SUCCESS_MESSAGE,
  DEFAULT_COLUMN_DEFS,
  GRID_OPTIONS,
  REVIEW_COLUMN_DEFS,
  defaultFilters
} from './lib/helpers';

const ReviewAndEditSOF = forwardRef((props, ref) => {
  const { data: professionGroupOptions } = useProfessionGroups();
  const professions = professionGroupOptions?.professions || [];

  const initialFilters = defaultFilters(professions);

  return (
    <ScheduleOfFeesContextProvider defaultData={props.defaultServices}>
      <TableContextProvider
        defaultFilters={initialFilters}
        cols={REVIEW_COLUMN_DEFS}
        name="services"
        onSelect={props.onSelect}
        pagination>
        <ReviewAndEditSOFTable ref={ref} {...props} />
      </TableContextProvider>
    </ScheduleOfFeesContextProvider>
  );
});

const ReviewAndEditSOFTable = forwardRef(({ hasNew = false }, ref) => {
  const { isOpen, openModal, closeModal } = useModal();
  const { filters } = useTableContext();
  const { rowData, setRowData } = useScheduleOfFeesContext();

  const navigate = useNavigate();

  const queryFilters = useMemo(() => {
    return mapValues(filters);
  }, [filters]);

  const tableData = useMemo(() => {
    const keys = Object.keys(queryFilters);
    const hasActiveFilters = keys.some((key) => queryFilters[key]?.length > 0);
    if (!keys || !hasActiveFilters) {
      return rowData;
    }

    const activeFilters = Object.values(queryFilters).flat();

    return rowData.filter((row) => {
      if (!row.professionGroups?.length) {
        return false;
      }

      return row.professionGroups.some((professionGroup) =>
        activeFilters.some((filter) => filter.value === professionGroup.id)
      );
    });
  }, [rowData, queryFilters]);

  const { mutateAsync: createServicesHandler } = useCustomMutation({
    mutationFn: (params) => createServices(navigate, params),
    invalidateQueryKey: CREATE_SERVICES_QUERY_KEY,
    successMessage: CREATE_SERVICES_SUCCESS_MESSAGE,
    errorMessage: CREATE_SERVICES_ERROR_MESSAGE,
    onSuccess: () => {
      closeModal();
    }
  });

  const saveServicesHandler = async () => {
    await createServicesHandler({ services: rowData });
  };

  useImperativeHandle(ref, () => ({
    saveServicesHandler,
    rowData
  }));

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: StatusBarAggregation,
          statusPanelParams: {
            data: rowData
          },
          align: 'center'
        }
      ]
    };
  }, [rowData]);

  const onUpdateData = (data) => setRowData(data);

  return (
    <div className="flex h-full w-full grow flex-col gap-4 p-0">
      <Header
        category="services"
        onOpenModal={openModal}
        data={rowData}
        hasNew={hasNew}
        onUpdateData={onUpdateData}
      />
      <AGTable
        data={tableData}
        gridOptions={GRID_OPTIONS}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        rowSelection="multiple"
        getRowId={(row) => row.data.procedure_code}
        paginationPageSize={rowData.length}
        pagination
        suppressPaginationPanel
        suppressScrollOnNewData
        statusBar={statusBar}
      />
      {isOpen && <ServiceModal isOpen={isOpen} onClose={closeModal} />}
    </div>
  );
});

export default ReviewAndEditSOF;
