import React from 'react';
import StepsChart from '../../StepsChart/StepsChart';
import Icon from 'components/shared/Icon/Icon';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import cs from 'classnames';
import { formTypeFormatter } from '../../../lib/helpers';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

const Header = ({
  chartData,
  currentBreadcrumbStep,
  getBreadcrumbsLeft,
  setPreviewMode,
  disabledPreview,
  typeOptions
}) => {
  const { formik, isEdit } = useFormBuilderContext();
  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="grid grid-cols-3 gap-0">
        <div className="col-span-2 !m-[10px] !mb-0 flex h-14 items-center justify-between rounded-[16px] bg-neutral-100 px-[21px] py-4 text-center">
          <div className="flex gap-4">
            <div className="flex items-center gap-2 border-0 !border-r !border-solid  !border-neutral-200 px-2">
              <p className=" font-500 text-primary-500">Name</p>
              {!isEdit ? (
                <p className="pr-2 text-sm font-500 text-primary-900">
                  {' '}
                  {formik?.values?.form?.name}
                </p>
              ) : (
                <Input
                  placeholder="Enter form name"
                  id="form.name"
                  name="form.name"
                  value={formik.values.form?.name}
                  onChange={formik.handleChange}
                  error={formik.touched.form?.name && formik.errors.form?.name}
                />
              )}
            </div>
            <div className="flex items-center gap-2 px-2">
              <p className=" text-sm font-500 text-primary-500">Type</p>

              {!isEdit ? (
                <p className="text-sm font-500 text-primary-900">
                  {formTypeFormatter(formik?.values?.form_details?.create_type)}
                </p>
              ) : (
                <Select
                  name="form.type"
                  options={typeOptions}
                  value={typeOptions.find((option) => option.value === formik.values?.form?.type)}
                  onChange={(type) => formik.setFieldValue('form.type', type?.value)}
                  menuPortalTarget={document.body}
                  isClearable={false}
                  width="200px"
                  minWidth="200px"
                />
              )}
            </div>
          </div>
          <div
            className={cs('flex !items-center gap-x-2', !disabledPreview ? 'cursor-pointer' : '')}
            onClick={() => {
              if (formik.values?.form?.json?.fields) {
                setPreviewMode(true);
              }
            }}>
            <Icon icon="new-eye" color={disabledPreview ? 'neutral' : 'primary'} shade={500} />
            <p
              className={cs(
                'text-sm font-500',
                disabledPreview ? 'text-neutral-200' : 'text-primary-500'
              )}>
              Preview
            </p>
          </div>
        </div>
        <StepsChart
          roundedBottom
          chartData={chartData}
          title="Form Builder"
          description="Please drag and drop components to custom-build your desired form. If you get stuck, check out our Form Library or contact us for assistance."
          innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
        />
      </div>
    </ErrorBoundary>
  );
};

export default Header;
