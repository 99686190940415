import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { formatDate, ia } from '../../../../lib/helpers/utility';
import practiceState, { currentPractice } from '../../practiceState';
import TableCounter from '../../../shared/Table/TableCounter';
import Skeleton from '../../../shared/Skeleton/Skeleton';

const LIMIT = 200;

export default function PaymentsAdjustments() {
  const [claims, setClaims] = useState([]);
  const [era, setERA] = useState([]);
  const [loading, setLoading] = useState(false);
  const filters = useRecoilValue(practiceState.reportingFilters);
  const [order, setOrder] = useState({
    patient: false,
    date: false,
    claimId: false,
    provider: false,
    payer: false,
    check_trace: false,
    paid_amount: false.valueOf,
    adjustment: false,
    note: false,
    adjustment_reason: false
  });
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  useEffect(() => {
    getERA();
  }, [filters]);

  useEffect(() => {
    if (era) {
      mapClaims();
    }
  }, [era]);

  const orderBy = (column) => {
    let orderedInsClaims = [];
    switch (column) {
      case 'patient':
        const fullName = claims?.map((row) => {
          return { ...row, fullName: row?.superbill?.patient?.fullName || row?.pcn };
        });
        orderedInsClaims = fullName?.sort((a, b) => {
          return order[column]
            ? a?.fullName?.localeCompare(b?.fullName, undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            : b?.fullName?.localeCompare(a?.fullName, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
        });
        setClaims(orderedInsClaims);
        break;
      case 'status':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? claimStatus(a) > claimStatus(b)
              ? 1
              : -1
            : claimStatus(b) > claimStatus(a)
            ? 1
            : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'provider':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.fullName?.localeCompare(b?.fullName, undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            : b?.fullName?.localeCompare(a?.fullName, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
        });
        setClaims(orderedInsClaims);
        break;
      case 'payer':
        const payerName = claims?.map((row) => {
          return { ...row, payerName: row?.payer_name || row?.payer_icn };
        });
        orderedInsClaims = payerName?.sort((a, b) => {
          return order[column]
            ? a?.payerName?.localeCompare(b?.payerName, undefined, {
                numeric: true,
                sensitivity: 'base'
              })
            : b?.payerName?.localeCompare(a?.payerName, undefined, {
                numeric: true,
                sensitivity: 'base'
              });
        });
        setClaims(orderedInsClaims);
        break;
      case 'check_trace':
        const orderedEraAndEob = claims?.sort((a, b) => {
          return order[column]
            ? a?.check?.localeCompare(b?.check, undefined, { numeric: true, sensitivity: 'base' })
            : b?.check?.localeCompare(a?.check, undefined, { numeric: true, sensitivity: 'base' });
        });
        setERA(orderedEraAndEob);
        break;
      case 'createdBy':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.superbill?.practitioner?.fullName?.toUpperCase() >
              b?.superbill?.practitioner?.fullName?.toUpperCase()
              ? 1
              : -1
            : b?.superbill?.practitioner?.fullName?.toUpperCase() >
              a?.superbill?.practitioner?.fullName?.toUpperCase()
            ? 1
            : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'method':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column]
            ? a?.type?.toUpperCase() > b?.type?.toUpperCase()
              ? 1
              : -1
            : b?.type?.toUpperCase() > a?.type?.toUpperCase()
            ? 1
            : -1;
        });
        setClaims(orderedInsClaims);
        break;
      case 'date':
        orderedInsClaims = claims?.sort((a, b) => {
          if (order[column]) {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 1 : -1;
          } else {
            return moment(b.created_at).isBefore(moment(a.created_at)) ? 1 : -1;
          }
        });
        setClaims(orderedInsClaims);
        break;
      case 'paid_amount':
        const paid_amount = claims?.map((row) => {
          return { ...row, paid_amount: insurancePaidAmount(row) };
        });
        orderedInsClaims = paid_amount?.sort((a, b) => {
          return order[column] ? a.paid_amount - b.paid_amount : b.paid_amount - a.paid_amount;
        });
        setClaims(orderedInsClaims);
        break;
      case 'adjustment':
        const adjustment = claims?.map((row) => {
          return {
            ...row,
            adjustment: Math.abs(row?.total_charge - insurancePaidAmount(row)).toFixed(2)
          };
        });
        orderedInsClaims = adjustment?.sort((a, b) => {
          return order[column] ? a.adjustment - b.adjustment : b.adjustment - a.adjustment;
        });
        setClaims(orderedInsClaims);
        break;
      case 'claimId':
        orderedInsClaims = claims?.sort((a, b) => {
          return order[column] ? a.id - b.id : b.id - a.id;
        });
        setClaims(orderedInsClaims);
        break;
      case 'payerId':
        const payerId = claims?.map((row) => {
          return { ...row, payerId: row?.payerid || row?.payer_icn };
        });
        orderedInsClaims = payerId?.sort((a, b) => {
          return order[column] ? a?.payerId - b?.payerId : b?.payerId - a?.payerId;
        });
        setClaims(orderedInsClaims);
        break;
      default:
        break;
    }
  };

  const getERA = async () => {
    if (filters) {
      setLoading(true);

      try {
        let params = {
          limit: LIMIT,
          offset: 0,
          startDate: filters.selectedRange[0].startDate,
          endDate: filters.selectedRange[0].endDate
        };

        if (ia(filters?.patient)) {
          params = { ...params, multipleIds: filters?.patient };
        }

        let res = await interimApi('/api/practice/billing/claim/get_era', params, navigate);
        if (res?.data?.era) {
          setERA(res?.data?.era);
        }
      } catch (error) {
        console.error('Error: ', error);
      }
      setLoading(false);
    }
  };

  const mapClaims = () => {
    let claimsArray = [];
    if (filters?.patient?.type == 'payer') {
      era.map((e) => {
        if (e?.payerid == filters?.patient?.id) {
          ia(e.claims) && claimsArray.push(...e.claims);
          ia(e.unmatched_claims) && claimsArray.push(...e.unmatched_claims);
        }
      });
      setClaims(claimsArray);
    } else {
      era.map((e) => {
        ia(e.claims) && claimsArray.push(...e.claims);
        ia(e.unmatched_claims) && claimsArray.push(...e.unmatched_claims);
      });

      claimsArray = claimsArray
        .filter((item) => !filters.patient?.id || parseInt(item.pcn) === filters?.patient?.id)
        .map((item) => item);

      setClaims(claimsArray);
    }
  };

  const insurancePaidAmount = (claim) => {
    let amount = 0;
    if (claim?.era) {
      claim.insurance_payment.era.map((e) => {
        if (e.era_id === claim.era) {
          amount = (e.amount_paid / 100).toFixed(2);
        }
      });
    } else if (claim.eob) {
      claim.insurance_payment.eob.map((e) => {
        if (e.eob_id === claim.eob) {
          amount = (e.amount_paid / 100).toFixed(2);
        }
      });
    } else {
      amount = claim.total_paid;
    }
    return amount;
  };

  if (loading) return <Skeleton count={6} />;

  return (
    <div className="!p-4 !pb-0 grid overflow-hidden">
      <div className="overflow-auto !rounded-md relative">
        <table className="primary-table">
          <thead>
            <tr>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    patient: !order.patient
                  });
                  orderBy('patient');
                }}>
                Patient
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    date: !order.date
                  });
                  orderBy('date');
                }}>
                Date of Service
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    claimId: !order.claimId
                  });
                  orderBy('claimId');
                }}>
                Claim ID
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    provider: !order.provider
                  });
                  orderBy('provider');
                }}>
                Provider
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    payer: !order.payer
                  });
                  orderBy('payer');
                }}>
                Payer
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    check_trace: !order.check_trace
                  });
                  orderBy('check_trace');
                }}>
                Check #
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    paid_amount: !order.paid_amount
                  });
                  orderBy('paid_amount');
                }}>
                Paid Amount
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    adjustment: !order.adjustment
                  });
                  orderBy('adjustment');
                }}>
                Adjustment
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    note: !order.note
                  });
                  orderBy('note');
                }}>
                Note
              </th>
              <th
                onClick={() => {
                  setOrder({
                    ...order,
                    adjustment_reason: !order.adjustment_reason
                  });
                  orderBy('adjustment_reason');
                }}>
                Adjustment Reason
              </th>
            </tr>
          </thead>
          <tbody>
            {ia(claims) ? (
              claims?.map((claim, index) => (
                <tr key={index}>
                  <td>{claim?.superbill?.patient?.fullName || claim?.pcn}</td>
                  <td>{formatDate(claim?.created_at || claim?.from_dos, practice.timezone)}</td>
                  <td>{claim?.id}</td>
                  <td>{claim?.superbill?.practitioner?.fullName}</td>
                  <td>{claim?.payer_name || claim?.payer_icn}</td>
                  <td>{claim?.check}</td>
                  <td>${insurancePaidAmount(claim)}</td>
                  <td>${Math.abs(claim?.total_charge - insurancePaidAmount(claim)).toFixed(2)}</td>
                  <td>{claim?.notes}</td>
                  <td>{claims?.adjustment_reason}</td>
                </tr>
              ))
            ) : (
              <tr className="relative">
                <td>
                  <span className="empty">No data found!</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <TableCounter page={1} limit={claims.length} count={claims.length} />
    </div>
  );
}
