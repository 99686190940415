import React, { useMemo } from 'react';

import { useServices } from 'lib/hooks/queries/services/useServices';
import useModal from 'lib/hooks/useModal';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import Button from 'components/shared/Buttons/Button';

import ScheduleOfFeesModal from '../../../shared/ScheduleOfFees/ScheduleOfFeesModal';

import ServiceModal from './components/ServiceModal';
import { defaultColumns, defaultFilters } from './lib/helpers';

const Services = () => {
  const { isOpen, openModal, closeModal } = useModal();

  const columnDefs = useMemo(() => defaultColumns(), []);
  const initialFilters = useMemo(() => defaultFilters(), []);

  return (
    <>
      <AgTablePage
        ModalComponent={ServiceModal}
        defaultColumns={columnDefs}
        defaultFilters={initialFilters}
        useData={useServices}
        name="services"
        queryParams={{ forPractice: true, getPayers: true }}
        headerActions={<Button text="Load SOF" size="small" onClick={openModal} />}
      />
      {isOpen && <ScheduleOfFeesModal isOpen={isOpen} closeModal={closeModal} />}
    </>
  );
};

export default Services;
