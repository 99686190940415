import { Honeybadger } from '@honeybadger-io/react';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Textarea from 'components/shared/Textarea/Textarea';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const NewAndUpdateICD10 = ({ mode, selectedICD10Code, hideModal, showModal }) => {
  const NewCodeValidationSchema = Yup.object().shape({
    code: mode === 'create' ? Yup.string().required('Code is required') : Yup.string().nullable(),
    name: Yup.string().required('Name is required')
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const commonTagsRef = useRef();

  const formik = useFormik({
    initialValues: {
      code: mode === 'update' ? selectedICD10Code?.code : '',
      name: mode === 'update' ? selectedICD10Code?.name : '',
      common_tags: mode === 'update' ? selectedICD10Code?.common_tags : ''
    },
    enableReinitialize: true,
    validationSchema: NewCodeValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (mode === 'create') {
          await handleCreate(values);
        } else if (mode === 'update') {
          await handleUpdate(values);
        }
        setSubmitting(false);
        hideModal();
      } catch (error) {
        Honeybadger.notify(`Form submission error: ${error}`);
        setSubmitting(false);
      }
    }
  });

  const handleCreate = async () => {
    try {
      const resData = await requestApi({
        url: `/api/codes/icd10/create`,
        params: {
          newCode: formik.values
        },
        navigate
      });
      const { code, icd10 } = resData || {};
      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          hideModal();
          showAlert({ title: 'New ICD10 Code added.', color: 'success' });
          queryClient.invalidateQueries(['icd10Codes']);
          break;

        case 1:
          showAlert({ title: icd10?.error, color: 'danger' });
          Honeybadger.notify(`icd10 error: ${icd10?.error}`);
          break;

        default:
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleCreate: ${error}`);
    }
  };

  const handleUpdate = async () => {
    try {
      const resData = await requestApi({
        url: `/api/codes/icd10/update`,
        params: {
          fields: formik.values
        },
        navigate
      });

      const { code } = resData || {};

      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          hideModal();
          showAlert({ title: 'ICD10 Code updated successfully', color: 'success' });
          queryClient.invalidateQueries(['icd10Codes']);
          break;

        default:
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleUpdate newAndUpdateICD10: ${error}`);
    }
  };

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={`${mode === 'update' ? 'Update' : 'New'} ICD10 Code`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={hideModal} />

          {mode === 'update' ? (
            <Button text="Update" onClick={handleUpdate} />
          ) : (
            <Button text="Create" onClick={handleCreate} />
          )}
        </div>
      }>
      <div className="grid gap-y-4">
      {mode === 'create' && (
          <Input
            data-qa="code"
            id="code"
            name="code"
            label="Code"
            error={formik?.errors?.code}
            value={formik?.values?.code}
            onChange={formik?.handleChange}
          />
        )}

        <Input
          id="name"
          name="name"
          label="Name"
          value={formik?.values?.name}
          error={formik?.errors?.name}
          onChange={formik && formik?.handleChange}
        />

        <Textarea
          id="common_tags"
          name="common_tags"
          label="Common Tags"
          forwardedRef={commonTagsRef}
          value={formik?.values?.common_tags}
          error={formik?.errors?.common_tags}
          onChange={formik && formik?.handleChange}
        />
      </div>
    </Modal>
  );
};

export default NewAndUpdateICD10;
