import { Honeybadger } from '@honeybadger-io/react';
import ICD10Code from 'components/practice/charts/ClinicalNote/shared/ICD10Code/ICD10Code';
import { trackUserClickForOldUI } from 'components/practice/charts/ClinicalNote/shared/ICD10Code/lib/userTrackSearchClick';
import { iaRa } from 'lib/helpers/utility';
import { debounce } from 'lodash';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Select from '../../../../../../../shared/Select/Select';
import Box from '../../../../shared/Box';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

export const handleICD10Search = async (searchTerm, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 25
      };
      let res = await Axios.post('/api/codes/icd10/get', params);
      if (res.data.icd10) {
        return res.data.icd10.map((item) => {
          return { value: item.code, label: `${item.code} - ${item.name}` };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};

const ICDCodes = ({
  menuPlacement = 'bottom',
  box = true,
  isTPlan,
  sectionRef = null,
  showOldUI = false,
  withScroll
}) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const { selectedTreatmentPlan, setSelectedTreatmentPlan, tab } = useClinicalNoteContext();
  const [loadedICD10, setLoadedICD10] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!showOldUI) {
      return;
    }

    getICD10Codes(isTPlan ? selectedTreatmentPlan.icd10 : clinicalNote.icd_10_codes);
  }, [selectedTreatmentPlan?.icd10, tab === 'epn' ? clinicalNote?.icd_10_codes : null]);

  useImperativeHandle(sectionRef, () => ({
    formData: { icd_10_codes: clinicalNote?.icd_10_codes }
  }));

  const handleIcd10Search = (searchTerm, callback) => {
    debouncedICD10Search(searchTerm, callback);
  };

  const debouncedICD10Search = debounce(async (searchTerm, callback) => {
    if (!searchTerm) {
      callback([]);
      return;
    }

    try {
      const params = { searchTerm, limit: 25 };
      let res = await Axios.post('/api/codes/icd10/get', params);

      if (res.data.icd10) {
        const formattedData = res.data.icd10.map((item) => ({
          value: item.code,
          label: `${item.code} - ${item.name}`
        }));
        callback(formattedData);
      } else {
        Honeybadger.notify("There's been an unexpected error, please try again later.");
        callback([]);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      callback([]);
    }
  }, 200);

  const getICD10Codes = async (ids) => {
    if (ids) {
      try {
        const params = {
          ids
        };
        let res = await Axios.post('/api/codes/icd10/get', params);
        if (res.data.icd10) {
          setLoadedICD10(
            res.data.icd10.map((item) => {
              return { value: item.code, label: `${item.code} - ${item.name}` };
            })
          );
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
        }
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
    }
  };

  const selectICD10 = async (e) => {
    e = iaRa(e);
    const currentICDValueArray = iaRa(loadedICD10).map((item) => item?.value);

    setLoadedICD10(e);

    if (isTPlan) {
      setSelectedTreatmentPlan({
        ...selectedTreatmentPlan,
        icd10: e.map((item) => {
          return item.value;
        })
      });
    } else {
      setClinicalNote({
        ...clinicalNote,
        icd_10_codes: e.map((item) => {
          return item.value;
        })
      });
    }

    await trackUserClickForOldUI({
      navigate,
      currentICDValueArray,
      newICDArray: e.map((item) => item?.value)
    });
  };

  if (!showOldUI) {
    return <ICD10Code isTPlan={isTPlan} withScroll={withScroll} />;
  }

  return (
    <Box isStyle={box}>
      <Select
        label="ICD-10 Codes"
        placeholder="Type and select ICD-10 codes"
        name="icd_10_codes"
        id="icd_10_codes"
        isAsync
        isMulti
        isClearable
        value={loadedICD10}
        loadOptions={(inputValue, callback) => handleIcd10Search(inputValue, callback)}
        onChange={selectICD10}
        disabled={(!isTPlan && clinicalNote?.locked) || intelligenceAi.loading}
        menuPlacement={menuPlacement}
        components={{
          IndicatorSeparator: () => null
        }}
        inputId="select-icd-codes"
      />
    </Box>
  );
};

export default ICDCodes;
