import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import RequestsSidebar from './RequestsSidebar';

export default function PracticeProfile() {
  const navigate = useNavigate();

  const { data } =
    useQuery({
      queryKey: ['getRequestsCount'],
      queryFn: getRequestsCount,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getRequestsCount() {
    const response = await requestApi({
      url: '/api/requests/count',
      params: {
        withCount: true
      },
      navigate
    });

    return response;
  }

  return (
    <div>
      <div>
        <div className="flex p-4 gap-4">
          <div>
            <div className="sticky">
              <RequestsSidebar requests={data?.requests} />
            </div>
          </div>
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}
