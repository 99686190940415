import React, { useState } from 'react';
import useAIBreadcrumbs from 'components/practice/charts/ClinicalNote/MyScribeAI/hooks/useAIBreadcrumbs';
import { useClinicalNoteContext } from '../ClinicalNoteContext/ClinicalNoteContext';
import { MyScribeAIContext } from './MyScribeAIContext';
import { getTypeOfNotes } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';

export const MyScribeAIContextProvider = ({ children }) => {
  const { cnDisplaySettings, advancedHP, advancedSOAP, customFormTypes } = useClinicalNoteContext();
  const typeOfNotes = getTypeOfNotes({
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    customFormTypes
  });

  const [favoriteTemplateId, setFavoriteTemplateId] = useState();

  const breadcrumb = useAIBreadcrumbs();

  return (
    <MyScribeAIContext.Provider
      value={{
        favoriteTemplateId,
        setFavoriteTemplateId,
        breadcrumb,
        typeOfNotes
      }}>
      {children}
    </MyScribeAIContext.Provider>
  );
};
