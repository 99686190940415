import React from 'react';
import { Link } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import CheckinPracticeLogo from 'components/Patient/checkin/PracticeLogo';

export default function instantActionsComponent({ success, text, practiceLogo = '' }) {
  return (
    <div className="flex h-screen flex-col justify-between">
      {/* <div className="flex w-full flex-row justify-start mt-[1rem] px-[3rem] md:px-2 bg-white"> */}
      <div className="flex w-full flex-row justify-start bg-white px-[3rem] py-[1rem] md:px-2">
        <CheckinPracticeLogo practiceLogo={practiceLogo} />
      </div>
      <div className="ConfirmationContainer">
        {success ? <Icon icon="success-2" /> : <Icon icon="new-automatization" />}
        <div className="ConfirmationContentContainer">
          <div className="ContactTitle">{text}</div>
        </div>
      </div>
      <div className="">
        <label className="!mx-10 !mb-5 flex flex-col text-center text-neutral-500">
          <Link to="/login">Login</Link>
          <Link to="/terms">Terms of Service and Privacy Policy</Link>
        </label>
        {practiceLogo && (
          <div className="flex h-[44px] w-full items-center justify-between bg-primary-50 px-40">
            <h4 className="text-sm text-neutral-600">Powered By</h4>
            <Icon icon="logo" size={90} />
          </div>
        )}
      </div>
    </div>
  );
}
