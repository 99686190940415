import { createFilter } from 'api/Filter';

import { objMap } from 'lib/helpers/utility';

import { showAlert } from '../../../Alert/Alert';

export default async function handleSave(
  navigate,
  { category, saveInput, filters, setSaveInput, setSaveButton, queryClient }
) {
  if (!saveInput) return;

  const save = await createFilter(navigate, {
    category,
    name: saveInput,
    filters: objMap(filters, (k, f) => f?.values)
  });

  if (save?.filter) {
    setSaveInput('');
    showAlert({
      message: 'Filter saved successfully',
      color: 'success'
    });
  } else {
    showAlert({
      message: 'An unexpected error has occured. Please try again later.',
      color: 'danger'
    });
  }

  queryClient.invalidateQueries(['filters']);
  setSaveButton(false);
}
