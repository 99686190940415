import { updateFax } from 'api/Fax';
import { showAlert } from 'components/shared/Alert/Alert';

export const handleUpdateFaxTags = async ({
  navigate = null,
  queryClient = null,
  faxId = null,
  tags = [],
  mode = '',
  queryKeys = []
}) => {
  try {
    let result = await updateFax(navigate, { faxId, fields: { tag_ids: tags } });
    if (result?.code === 0) {
      queryClient.setQueryData(queryKeys, (oldData) => {
        try {
          let queryKey = queryKeys[0];

          let newFaxesData = oldData?.[queryKey]?.map((f) => {
            if (f?.id !== parseInt(faxId)) {
              return f;
            }
            let newTags = structuredClone(f.tags) || [];

            if (mode === 'remove') {
              let filteredTags = result?.fax?.tags || [];
              newTags = [...filteredTags];
            }

            if (mode === 'add') {
              let addedTags = result?.fax?.tags;
              newTags = [...addedTags];
            }

            return {
              ...f,
              tags: newTags
            };
          });
          return {
            ...oldData,
            [queryKey]: newFaxesData
          };
        } catch (error) {
          showAlert({
            title: 'Tags update failed',
            message: error?.message ?? 'Something went wrong',
            color: 'danger'
          });
          return oldData;
        }
      });
    }
  } catch (error) {
    showAlert({
      title: 'Tags update failed',
      message: error ?? 'Something went wrong',
      color: 'danger'
    });
  }
};
