import numOrDefault from 'lib/helpers/numOrDefault';
import { formatDate, mString } from 'lib/helpers/utility';
import InvoiceActionPopover from 'components/practice/charts/billing/Invoices/components/InvoiceActionPopover';

export const getColDefs = (timezone) => [
  { headerName: 'ID', field: 'id', headerCheckboxSelection: true, checkboxSelection: true },
  {
    field: 'provider',
    headerName: 'Provider'
  },
  {
    headerName: 'Charge Amount',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'total_amount'
  },
  {
    headerName: 'Amount Paid',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'amount_paid'
  },
  {
    headerName: 'Adjustment',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'adjustment'
  },
  {
    headerName: 'Discount',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'discountAmount'
  },
  {
    headerName: 'Tax',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'tax'
  },
  {
    headerName: 'Balance',
    valueFormatter: ({ value }) => mString(numOrDefault(value)),
    aggFunc: 'sum',
    field: 'balance'
  },
  {
    headerName: 'Date of Service',
    field: 'dateofservice',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const dos = params?.data?.dateofservice;
      return formatDate(dos, timezone, true);
    }
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: InvoiceActionPopover,
    maxWidth: 70,
    cellClass: 'no-border'
  }
];

export const DEFAULT_COLUMN_DEFS = { resizable: true, enableRowGroup: true };

export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true,
  suppressAggFuncInHeader: true,
  enableRangeSelection: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) => formatDate(values.startDate) + ' - ' + formatDate(values.endDate)
  },
  id: {
    type: 'search',
    values: null,
    title: 'ID',
    placeholder: 'Search ID',
    preview: (values) => values
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    placeholder: 'Search Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
