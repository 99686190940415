import React from 'react';

import { cloneDeep } from 'lodash';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import EmptyTreatmentPlan from './EmptyTreatmentPlan';

const TPTableView = ({
  plans,
  onTPPreview,
  gridApi,
  setSelectedRows,
  rowSelection,
  defaultColDef,
  columnDef,
  gridOptions,
  breadcrumb
}) => {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();
  const { setSelectedTableRows, onRemoveComponent, setComponentToAdd } = useChatContextApi();
  const { selectedTableRows } = useChatContextData();
  const selectedTableNodes = selectedTableRows?.['treatment_plan']?.selectedNodes;

  const onRowClicked = (row) => {
    const node = gridApi?.getRowNode(row.node.id);
    if (node) {
      node.setSelected(true);
    }
    setSelectedRows([node]);
    setSelectedTreatmentPlan(row.data);
    onTPPreview(row.data.updated_at);
  };

  const onDeleteTreatmentPlanTag = () => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['treatment_plan'];
    setSelectedTableRows(newSelectedTableRows);
    onRemoveComponent();
    setComponentToAdd(null);
  };

  return (
    <div className="ag-theme-quartz forms_table !mb-0 h-full">
      <AGTable
        defaultColDef={defaultColDef}
        columnDef={columnDef}
        gridOptions={gridOptions}
        animateRows={true}
        data={plans}
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        reactiveCustomComponents
        onRowClicked={onRowClicked}
        rowSelection={rowSelection}
        noRowsOverlayComponent={EmptyTreatmentPlan}
        noRowsOverlayComponentParams={{ breadcrumb }}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px]">
            <Skeleton count={24} />
          </div>
        )}
      />
      {selectedTableNodes?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon
                key={1}
                icon="trash"
                className="ml-4 cursor-pointer"
                onClick={onDeleteTreatmentPlanTag}
              />
            )
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
};

export default TPTableView;
