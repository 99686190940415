import React from 'react';
import { getPatients } from 'api/Patients';
import { formatDate } from 'lib/helpers/utility';

export const searchPatients = async (searchTerm, navigate) => {
  const patients = await getPatients(navigate, {
    limit: 25,
    searchTerm,
    offset: 0
  });

  return patients.patients.map((p) => ({
    customLabel: (
      <div>
        {p?.fullName && <span>{p.fullName}</span>}
        {p.dob && <span className="text-xs pl-1 text-neutral-500">{formatDate(p.dob)}</span>}
      </div>
    ),
    label: p?.fullName,
    value: p?.id
  }));
};
