import React, { useState } from 'react';
import Tippy from '@tippyjs/react';
import Content from './Content/Content';
import { useUIContext } from 'lib/context/UIContext/UIContext';

const Hover = ({ item = {}, appointmentRef = null }) => {
  const { device } = useUIContext();
  const [shown, setShow] = useState(false);

  const handleShowHover = () => setShow(true);
  const handleHideHover = () => setShow(false);

  if (device === 'mobile' || device === 'tablet-sm' || device === 'tablet') return null;

  return (
    <Tippy
      className="custom-tooltip min-w-[500px] !rounded-lg"
      theme="light"
      reference={appointmentRef}
      content={
        shown && <Content id={item?.id} type={item?.eventType} handleHideHover={handleHideHover} />
      }
      allowHTML
      interactive
      appendTo={() => document.body}
      placement="left"
      offset={[0, 0]}
      onShow={handleShowHover}
      onHide={handleHideHover}
    />
  );
};

export default Hover;
