import React, { useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import { ia } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import SelfPayAllocation from './SelfPayAllocation';
import { currentPractice } from 'components/practice/practiceState';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import { costShareInfo } from 'components/practice/appointment/PreviewAppointment/lib/costShareInfo';

const Allocation = ({ insuranceProfiles }) => {
  const [isAllocationOpen, setIsAllocationOpen] = useState(false);

  const practice = useRecoilValue(currentPractice);
  const claims = practice?.display_settings?.claims;

  const { values, setFieldValue } = useFormikContext();
  const { invoice, modifiedDeductible, modifiedCoPayment, modifiedCoInsurance } = values || {};
  const { procedures = [], last_applied } = invoice || {};

  const hasCoPayType = procedures?.some(
    ({ type, procedure_code }) => type === 'primary' && procedure_code
  );

  // const { deductible: insDeductible } =
  //   insuranceProfiles?.find?.((ip) => ip.id === last_applied?.ins_profile_id) || {};

  const disableCostShare = !hasCoPayType || !ia(insuranceProfiles);

  const wrapperClassName =
    '!py-1 h-[30px] w-[85px] !px-[4px] !bg-transparent border-none border-bottom [border-bottom-style:solid] rounded-none border-white focus-within:!outline-[0px] !outline-0';

  const handleAllocation = () => setIsAllocationOpen(!isAllocationOpen);

  return (
    claims && (
      <div className="-mx-2 flex flex-col gap-2 rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] p-2">
        <div className="flex select-none gap-[6px]">
          <div className="flex size-[20px] cursor-pointer place-content-center rounded-[3px] bg-white">
            <Icon
              size={14}
              color="primary"
              icon="new-arrow-down"
              onClick={handleAllocation}
              className={cs('duration-150', !isAllocationOpen && 'rotate-180')}
            />
          </div>
          <span className="cursor-pointer text-sm" onClick={handleAllocation}>
            Patient portion allocation
          </span>
        </div>

        {isAllocationOpen && (
          <>
            {!hasCoPayType && (
              <p className="pl-7 text-xs text-neutral-600">
                Mark at least one of the services as an insurance type.
              </p>
            )}

            {hasCoPayType && !ia(insuranceProfiles) && (
              <p className="pl-7 text-xs text-neutral-600">
                Please add an insurance profile to calculate cost share.
              </p>
            )}

            <div className="grid gap-[6px] pl-7">
              <div className="flex items-center justify-between gap-2">
                <div className="flex gap-1">
                  <span className="text-sm">Deductible</span>
                  {last_applied?.deductible?.value > 0 && (
                    <Tippy
                      interactive
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'deductible')}>
                      <div className="flex">
                        <Icon icon="new-info-v2" />
                      </div>
                    </Tippy>
                  )}
                </div>
                <CurrencyInput
                  id="deductible"
                  className="text-right"
                  disabled={disableCostShare}
                  wrapperClassName={wrapperClassName}
                  value={modifiedDeductible ?? last_applied?.deductible?.value ?? 0}
                  onValueChange={(v) => setFieldValue('modifiedDeductible', +v)}
                />
              </div>

              {/* {insDeductible > 0 && formik.values.modifiedDeductible > insDeductible && (
                <p className="!mb-4 text-xs text-neutral-600">
                  The amount entered exceeds the amount in the insurance profile.
                </p>
              )} */}

              <div className="flex items-center justify-between gap-2">
                <div className="flex gap-1">
                  <span className="text-sm">Co-Payment</span>
                  {last_applied?.co_payment?.value > 0 && (
                    <Tippy
                      interactive
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'co_payment')}>
                      <div className="flex">
                        <Icon icon="new-info-v2" />
                      </div>
                    </Tippy>
                  )}
                </div>
                <CurrencyInput
                  id="co-payment"
                  className="text-right"
                  disabled={disableCostShare}
                  wrapperClassName={wrapperClassName}
                  value={modifiedCoPayment ?? last_applied?.co_payment?.value ?? 0}
                  onValueChange={(v) => setFieldValue('modifiedCoPayment', +v)}
                />
              </div>

              <div className="flex items-center justify-between gap-2">
                <div className="flex gap-1">
                  <span className="text-sm">Co-Insurance</span>
                  {last_applied?.co_insurance?.value > 0 && (
                    <Tippy
                      interactive
                      className="tippy-dark"
                      content={costShareInfo(last_applied, 'co_insurance')}>
                      <div className="flex">
                        <Icon icon="new-info-v2" />
                      </div>
                    </Tippy>
                  )}
                </div>
                <CurrencyInput
                  id="co-insurance"
                  className="text-right"
                  disabled={disableCostShare}
                  wrapperClassName={wrapperClassName}
                  value={modifiedCoInsurance ?? last_applied?.co_insurance?.value ?? 0}
                  onValueChange={(v) => setFieldValue('modifiedCoInsurance', +v)}
                />
              </div>

              <SelfPayAllocation wrapperClassName={wrapperClassName} />
            </div>
          </>
        )}
      </div>
    )
  );
};

export default Allocation;
