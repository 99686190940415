import React from 'react';

import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Typography from 'components/shared/Typography/Typography';

import { YES_AND_NO_OPTIONS } from '../lib/constants';

const MiddleInputs = ({ procedure, handleInputChange, handleSelectChange }) => {
  const epsdt = YES_AND_NO_OPTIONS.find(
    (option) => option.value === (procedure?.epsdt_indicator || 'N')
  );
  const familyPlan = YES_AND_NO_OPTIONS.find(
    (option) => option.value === (procedure?.familyplan_indicator || 'N')
  );

  return (
    <div className="flex flex-col gap-2 flex-1">
      <div className="flex gap-1">
        <Select
          label="24H. EPSDT"
          placeholder="EPSDT"
          name="epsdt_indicator"
          parentClassName="w-full"
          options={YES_AND_NO_OPTIONS}
          value={epsdt}
          onChange={(event) => handleSelectChange(event, 'epsdt_indicator')}
          isClearable={false}
        />
        <Select
          label="Family Plan"
          placeholder="Family Plan"
          name="familyplan_indicator"
          parentClassName="w-full"
          value={familyPlan}
          options={YES_AND_NO_OPTIONS}
          onChange={(event) => handleSelectChange(event, 'familyplan_indicator')}
          isClearable={false}
        />
      </div>
      <div className="flex flex-col gap-1 mt-2">
        <Typography weight="500" color="neutral-800">
          24J. RENDERING PROVIDER (L,F,M)
        </Typography>
        <div className="flex gap-1">
          <Input
            placeholder="L"
            name="chg_prov_name_l"
            value={procedure?.chg_prov_name_l}
            onChange={handleInputChange}
          />
          <Input
            placeholder="F"
            name="chg_prov_name_f"
            value={procedure?.chg_prov_name_f}
            onChange={handleInputChange}
          />
          <Input
            placeholder="M"
            name="chg_prov_name_m"
            value={procedure?.chg_prov_name_m}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <Input
        label="Taxonomy"
        placeholder="Taxonomy"
        name="chg_prov_taxonomy"
        value={procedure?.chg_prov_taxonomy}
        onChange={handleInputChange}
      />
      <Input
        label="NPI"
        placeholder="NPI:"
        name="chg_prov_npi"
        value={procedure?.chg_prov_npi}
        onChange={handleInputChange}
      />
      <Input
        label="LEGACY ID"
        placeholder="Legacy Id"
        name="chg_prov_id"
        value={procedure?.chg_prov_id}
        onChange={handleInputChange}
      />

      {/*FIX: add supv provider for charge (claim md does not submit this)

      <div className="flex flex-col gap-1 mt-2">
        <Typography weight="500" color="neutral-800">
          SUPERVISING PROVIDER NAME (L,F,M)
        </Typography>
        <div className="flex gap-1">
          <Input
            placeholder="L"
            name="chg_supv_prov_name_l"
            value={procedure?.chg_supv_prov_name_l}
            onChange={handleInputChange}
          />
          <Input
            placeholder="F"
            name="chg_supv_prov_name_f"
            value={procedure?.chg_supv_prov_name_f}
            onChange={handleInputChange}
          />
          <Input
            placeholder="M"
            name="chg_supv_prov_name_m"
            value={procedure?.chg_supv_prov_name_m}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="flex gap-1">
        <Input
          label="ID"
          placeholder="Id"
          name="chg_supv_prov_id"
          value={procedure?.chg_supv_prov_id}
          onChange={handleInputChange}
        />
        <Input
          label="NPI"
          placeholder="Npi"
          name="chg_supv_prov_npi"
          value={procedure?.chg_supv_prov_npi}
          onChange={handleInputChange}
        />
      </div> */}
    </div>
  );
};

export default MiddleInputs;
