import { iaRa } from 'lib/helpers/utility';

export const keyChangesProductNdc = ({ initialProductNdc, updatedProductNdc }) => {
  const initial = iaRa(initialProductNdc);
  const updated = iaRa(updatedProductNdc);
  if (
    initial.length === updated.length &&
    initial.every((code, index) => code === updated[index])
  ) {
    return null;
  }
  return updated ?? null;
};
