import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { interimApi } from 'api/InterimApi';
import { ia, mString, randomString } from 'lib/helpers/utility';
import Spinner from 'components/shared/spinner/spinner';
import terminalRequest from './terminalRequest';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';

/* const signature = {
  signatureBitmap: {
    data: "` +J  !_ P____ X _'___ _& _'  ( _' _/ _'  ( _/ _/  ( _7 _/  ( _7 _/ _7 _7  ( _/ _/ _/ _/ _'  ( _& _%___ _'_______________________W____ X__W_ P____ Y_ P_ X  !_ X  \"  !  )  !  )  )  )  )  )  1  )  1  *  )  (  1  )  1  )  )  (  )  )  (  )  !p`#+Q  !  0  !  0  0  )  8  8  0  8  )  0 _/  0  0 _'  ( _&  (___ _& _'______ _&___ _'___ _&___ _'___ _'___ _& _'___ _/___ _' _/ _& _'p`'&__ X _&p`+)?_ P____ X___________W_ X________^_________ _'___ _'___ _/ _' _/  ( _/  0  8  (  (  1  (  )  0  )  )  )  )  )  !  )  !  !_ Y  !  !_ Y  !_ Y_ Y_ Y_ Y_ X_ Y_ Xp`-*( _'_ X_______ X _'_ X _'_ X _'_ X _'___ _'______ _'__^ _' _' _' _/ _' _/ _/  0 _/  0  (  0  (  0  1  0  1  (  1  )  1  )  )  )  !  )  !_ Z  !_ Y_ Y_ Q_ Z_ Q_ P_ Q_ Q_ Q_ H_ Q_ P_ Q_ P_ P__W_ X_ P___p",
    format: 'SIG_BIN_2'
  }
};
*/

const TerminalWrapper = ({
  practiceId,
  patientId,
  invoiceId,
  amount = 0,
  amount_pending = 0,
  saveCard = false,
  appointmentId,
  updateBalance = () => {},
  updateAmount = () => {},
  noInterface: givenNoInterface = false,
  transactionKind = '',
  transactionId = '',
  tokenizedCardId,
  surcharge,
  setReceipt,
  selectedTid,
  onClose = () => {},
  onFail = () => {},
  onComplete = () => {},
  invoices
}) => {
  const [response, setResponse] = useState([]);
  const transactionInProgress = useRef(false);
  const requestNameRef = useRef('');
  const requestIdRef = useRef(0);
  const attempt = useRef(0);
  const paymentGatewayIdRef = useRef('');
  const [transactionChanId, setChanId] = useState(null);
  const [paymentGatewayId, setPaymentGatewayId] = useState('');
  const [transaction_status, setTransactionStatus] = useState({
    transactionInProgress: false,
    finishTransaction: false,
    moreOptions: false,
    selectTerminal: false,
    canRunTransaction: false
  });
  const details = useRef({
    vendorId: '',
    merchantId: '',
    pin: '',
    userId: '',
    paymentGatewayEnvironment: 'PROD',
    confirmed: false
  });
  const setDetails = (d) => (details.current = d);
  // const [details, setDetails] = useState({
  //   vendorId: '',
  //   merchantId: '',
  //   pin: '',
  //   userId: '',
  //   paymentGatewayEnvironment: 'PROD',
  //   confirmed: false
  // });
  const [show, setShow] = useState({
    transactionInProgress: false,
    canRunTransaction: false,
    finishTransaction: false,
    moreOptions: false,
    selectTerminal: false,
    loading: true,
    canRunTransaction: false,
    success: false,
    voidTransaction: false,
    noInterface: !!givenNoInterface
  });
  const [terminals, setTerminals] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    attempt.current = 0;
    requestNameRef.current = randomString(16);
    getCredentials();
    setChanId(null);
    return () => {
      cancelTransaction();
    };
  }, []);

  const startInitiationProcess = ({ loadedTerminalData, newShow }) => {
    // we have the credentials
    let newDetails = {
      ...details.current,
      vendorId: loadedTerminalData.vendor_id,
      merchantId: loadedTerminalData.merchant_id,
      pin: loadedTerminalData.pin,
      userId: loadedTerminalData.user_id,
      paymentGatewayEnvironment: loadedTerminalData.env
    };
    setPaymentGatewayId(loadedTerminalData.payment_gateway_id);
    paymentGatewayIdRef.current = loadedTerminalData.payment_gateway_id;
    setDetails(newDetails);
    if (loadedTerminalData.payment_gateway_id === '') {
      openPaymentGateway(newDetails);
    } else {
      if (show.noInterface) {
        initiateTranscation();
      } else {
        newShow.canRunTransaction = true;
      }
      // setShow({ ...newShow, canRunTransaction: true });
      // checkCredentials(newDetails);
    }
  };

  const getCredentials = async (s, alreadyCalled) => {
    let newShow = Object.assign({}, s || show);
    try {
      let request = { practiceId };
      if (selectedTid) {
        request['tid'] = selectedTid;
      }
      const res = await interimApi('/api/transactions/terminal/get', request, navigate);
      const { code, redirect, error, terminalData: loadedTerminalData } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (Array.isArray(loadedTerminalData)) {
            // if (show.noInterface) {
            //   onFail();
            //   return;
            // }
            const cleanTerminals = [];
            for (let i = 0; i < loadedTerminalData.length; i++) {
              if (!loadedTerminalData[i].online) {
                cleanTerminals.push(loadedTerminalData[i]);
              }
            }
            if (cleanTerminals.length === 1) {
              // we only have one terminal, auto select it
              if (!!!alreadyCalled) {
                selectTerminal({ target: { name: cleanTerminals[0].id } }, true);
              } else {
                startInitiationProcess({ loadedTerminalData: cleanTerminals[0], newShow });
              }
            } else {
              // we are given options to select a terminal
              setTerminals(cleanTerminals);
              // setTerminals(optionify(loadedTerminalData, 'id', 'id', true));
              newShow.noInterface = false;
              newShow.selectTerminal = true;
            }
          } else {
            startInitiationProcess({ loadedTerminalData, newShow });
          }
          break;

        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          Honeybadger.notify(`getCredentials: ${errorMsg}`);
          onFail(errorMsg);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`getCredentials: ${error}`);
      onFail();
    }
    newShow.loading = false;
    setShow((prevState) => ({ ...prevState, ...newShow }));
  };

  const selectTerminal = async (e, alreadyCalled) => {
    let newShow = {};
    try {
      const newTid = parseInt(e.target.name);
      const res = await interimApi('/api/transactions/terminal/select', { tid: newTid }, navigate);
      const { code, redirect, error, terminalData: loadedTerminalData } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          newShow.loading = false;
          setShow((prevState) => ({ ...prevState, ...newShow }));
          break;
        case 0:
          newShow.selectTerminal = false;
          setShow((prevState) => ({ ...prevState, ...newShow }));
          if (!!!alreadyCalled) getCredentials(newShow, true);
          break;

        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          Honeybadger.notify(`selectTerminal: ${errorMsg}`);
          onFail(errorMsg);
          newShow.loading = false;
          setShow((prevState) => ({ ...prevState, ...newShow }));
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`selectTerminal: ${error}`);
      newShow.loading = false;
      setShow((prevState) => ({ ...prevState, ...newShow }));
    }
  };

  const listTerminals = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await interimApi(
          `/api/transactions/terminal/list`,
          { presentOnly: true },
          navigate
        );
        const { code, redirect, error, terminalData: loadedTerminalData } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            reject();
            break;
          case 0:
            setTerminals(loadedTerminalData);
            resolve(loadedTerminalData);
            break;
          default:
            if (error) {
              Honeybadger.notify(`listTerminals: ${error}`);
            } else Honeybadger.notify(`There's been an unexpected error. Please try again later.`);
            reject();
            break;
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const incrementRequestId = () => {
    let newRequestId = requestIdRef.current;
    if (typeof newRequestId === 'number') {
      newRequestId += 1;
    } else {
      newRequestId = 1;
    }
    requestIdRef.current = newRequestId;
    return `${requestNameRef.current}-${newRequestId.toString()}`;
  };

  const openPaymentGateway = async (d) => {
    try {
      const response = await makeRequest({
        method: 'openPaymentGateway',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          vendorId: d.vendorId,
          vendorAppName: 'MyriadTerminal',
          vendorAppVersion: '0.0.0',
          userId: d.userId,
          pin: d.pin,
          paymentGatewayEnvironment: d.paymentGatewayEnvironment,
          app: 'MyriadTerminal',
          merchantId: d.merchantId,
          // handleDigitalSignature: true,
          handleDigitalSignature: false,
          email: 'support@myriad.health',
          overrideDebitNetworkPreferences: {
            visa: 'GLOBAL_NETWORK',
            mastercard: 'GLOBAL_NETWORK',
            discover: 'GLOBAL_NETWORK'
          }
        }
      });
      if (response?.data?.paymentGatewayCommand?.openPaymentGatewayData?.result === 'SUCCESS') {
        const newPaymentGatewayId =
          response?.data?.paymentGatewayCommand?.openPaymentGatewayData?.paymentGatewayId;
        setPaymentGatewayId(newPaymentGatewayId);
        paymentGatewayIdRef.current = newPaymentGatewayId;
        // toast.success(
        //   `PaymentGatewayId:${response.data.paymentGatewayCommand.openPaymentGatewayData.paymentGatewayId}`
        // );
        setShow((prevState) => ({ ...prevState, canRunTransaction: true }));
        try {
          const res = await interimApi(
            '/api/transactions/terminal/updateGateway',
            {
              data: {
                pin: d.pin,
                payment_gateway_id: newPaymentGatewayId,
                merchant_id: d.merchantId,
                vendor_id: d.vendorId
              }
            },
            navigate
          );
          if (show.noInterface) {
            initiateTranscation();
          }
        } catch (error) {
          console.error(error);
          if (show.noInterface) {
            onFail();
            return;
          }
        }
      } else {
        Honeybadger.notify(
          `openPaymentGateway: An unexpected error has occurred. Please try again later or contact customer support.`
        );
        if (show.noInterface) {
          onFail();
          return;
        }
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(`openPaymentGateway: There's been an error opening the gateway ${error}`);
    }
  };

  const initiateTranscation = () => {
    switch (transactionKind) {
      case 'sale':
        return startTransaction();
        break;
      case 'tokenizedSale':
        return startTokenizedTransaction(tokenizedCardId);
        break;
      case 'void':
        return voidTransaction(transactionId);
        break;
      case 'linkedRefund':
        return linkedRefund();
        break;
      case 'tokenize':
        return startGetTokenTransaction();
        break;
      default:
        return startTransaction();
        break;
    }
  };

  const startGetTokenTransaction = async () => {
    try {
      let requestBody = {
        method: 'startPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          transactionType: 'SALE',
          tenderType: 'CARD',
          cardType: null,
          generateToken: true,
          addToken: true
        }
      };
      const transaction_session = await makeRequest(requestBody);
      if (transaction_session.data.paymentGatewayCommand.chanId) {
        notifyTransactionStart(transaction_session?.data?.paymentGatewayCommand?.chanId);
        setChanId(transaction_session?.data?.paymentGatewayCommand?.chanId);
        setTransactionStatus({ ...transaction_status, transactionInProgress: true });
        transactionInProgress.current = true;
        if (show.noInterface) {
          const res = await rCheckStatus(
            transaction_session?.data?.paymentGatewayCommand?.chanId,
            saveToken
          );
        }
        // completeTransaction(res);
      } else {
        toast.error(`Transaction could not be started`);
        Honeybadger.notify(`Transaction could not be started`);
      }
    } catch (error) {
      console.error(error);
      onFail(`There's been an error getting token. Please try again later.`);
      Honeybadger.notify(`There's been an error getting token. Please try again later. ${error}`);
    }
  };

  const saveToken = async (r) => {
    try {
      const ptd = r?.data?.paymentGatewayCommand?.paymentTransactionData;
      if (ptd?.result !== 'APPROVED') {
        let error = `There's been an error while processing transcation.`;
        if (ia(ptd?.errors)) {
          error = (
            <>
              {ptd.errors.length > 1 && (
                <div>`Errors have occurred while proccessing transaction`</div>
              )}
              {ptd.errors.length > 0 && (
                <ul>
                  {ptd.errors.map((v, i) => (
                    <li key={`ptd-error-terminal-interface-index-${i}`}>{v}</li>
                  ))}
                </ul>
              )}
            </>
          );
        }
        onFail(error);
        return;
      }

      const res = await interimApi(
        '/api/transactions/token/add',
        {
          card: {
            token: ptd?.tokenizedCard,
            pan: ptd?.maskedPan,
            cardScheme: ptd?.cardScheme,
            cardEntryType: ptd?.cardEntryType,
            terminalId: selectedTid
          },
          targetUserId: patientId
        },
        navigate
      );

      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setShow((prevState) => ({ ...prevState, success: true }));
          setReceipt(res?.data?.receipt?.data);
          onComplete();
          showAlert({ title: 'Transaction Finished!', color: 'success' });
          break;

        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          toast.error(errorMsg);
          onFail(errorMsg);
          return;
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
      onFail();
    }
  };

  const startTokenizedTransaction = async (cardId) => {
    try {
      const res = await interimApi(
        '/api/transactions/token/get',
        {
          cardId,
          patientId
        },
        navigate
      );
      const { code, redirect, error, card } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (card && card?.token) {
            startTransaction(card?.token);
          } else {
            onFail(
              `Something went wrong with the stored card. Please try again not using this stored card.`
            );
          }
          break;
        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          toast.error(errorMsg);
          onFail(errorMsg);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
  };

  const startTransaction = async (token) => {
    try {
      let requestBody = {
        method: 'startPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          transactionType: 'SALE',
          baseTransactionAmount: { currencyCode: 'USD', value: amount },
          tenderType: 'CARD',
          cardType: null,
          taxAmounts: [],
          isTaxInclusive: false,
          // discountAmounts: [{ currencyCode: 'USD', value: 10 }]
          discountAmounts: []
        }
      };
      if (token) {
        requestBody.parameters['tokenizedCardNumber'] = token;
      } else if (saveCard) {
        requestBody.parameters['generateToken'] = true;
        requestBody.parameters['addToken'] = true;
      }
      const transaction_session = await makeRequest(requestBody);
      if (transaction_session?.data?.paymentGatewayCommand?.chanId) {
        notifyTransactionStart(transaction_session?.data?.paymentGatewayCommand?.chanId);
        setChanId(transaction_session?.data?.paymentGatewayCommand?.chanId);
        setTransactionStatus({ ...transaction_status, transactionInProgress: true });
        transactionInProgress.current = true;
        if (show.noInterface) {
          const res = await rCheckStatus(
            transaction_session?.data?.paymentGatewayCommand?.chanId,
            completeTransaction
          );
        }
        // completeTransaction(res);
      } else {
        toast.error(`Transaction could not be started`);
      }
    } catch (error) {
      let errors =
        getErrors(error.response) ||
        `An error has occurred while initiating transaction. Please request credentials review from support.`;
      if (error?.response?.status === 403) {
        if (attempt?.current < 3) {
          openPaymentGateway(details.current);
        } else {
          onFail(errors);
        }
      } else {
        transactionInProgress.current = false;
        setTransactionStatus({ ...transaction_status, transactionInProgress: false });
        toast.error(`An error has occurred. Please try again later.`);
        console.error(error);
        onFail(errors);
      }
    }
  };

  const linkedRefund = async () => {
    try {
      let requestBody = {
        method: 'linkedRefund',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          baseTransactionAmount: {
            value: amount,
            currencyCode: 'USD'
          },
          originalTransId: transactionId,
          tenderType: 'CARD',
          cardType: 'CREDIT'
        }
      };
      const res = await makeRequest(requestBody);
      await notifyTransactionStart(res?.data?.paymentGatewayCommand?.chanId, true);
      const newChanId = res?.data?.paymentGatewayCommand?.chanId;
      setChanId(newChanId);
      rCheckStatus(newChanId, completeTransaction);
    } catch (error) {
      toast.error(`An error has occurred. Please try again later.`);
      console.error(error);
      onFail();
    }
  };

  const notifyTransactionStart = async (transactionId, isRefund = false) => {
    try {
      // transaction: { id: transactionId, amount: { value: isRefund ? (amount + surcharge) * -1 : (amount + surcharge) } },
      let chargeAmount = amount;

      if (isRefund) {
        chargeAmount *= -1;
      }
      const res = await interimApi(
        '/api/transactions/terminal/transaction/initiate',
        {
          patientId,
          invoiceId,
          practiceId,
          appointmentId,
          transaction: {
            id: transactionId,
            surcharge_amount: surcharge,
            amount: { value: chargeAmount }
          }
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          break;
        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          toast.error(errorMsg);
          onFail(errorMsg);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
    }
  };

  const cancelTransaction = async () => {
    attempt.current = 0;
    try {
      setTransactionStatus({ ...transaction_status, transactionInProgress: false });
      transactionInProgress.current = false;
      const res = await makeRequest({
        method: 'cancelPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          chanId: transactionChanId
        }
      });
      toast.success(`Cancelling transaction: ${JSON.stringify(res.data)}`);
    } catch (error) {
      toast.error(`There's been an error`);
      console.error(error);
    }
  };

  const rCheckStatus = (chanId, callback) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoadingMessage('Please allow the patient to Insert/Tap to pay and sign.');
        let request_interval = setInterval(async () => {
          try {
            const res = await makeRequest({
              method: 'getPaymentTransactionStatus',
              targetType: 'paymentGatewayConverge',
              version: '1.0',
              parameters: {
                chanId
              }
            });
            if (res.data?.paymentGatewayCommand?.completed) {
              clearInterval(request_interval);
              if (show.noInterface) {
                setLoadingMessage('One more moment...');
                if (callback) {
                  attempt.current += 1;
                  callback(res);
                }
                resolve();
              } else {
                resolve(res);
              }
            }
            if (!transactionInProgress.current) {
              reject(new Error(`Transaction was canceled.`));
            }
          } catch (error) {
            console.error(error);
          }
        }, 200);
      } catch (error) {
        reject(error);
      }
    });
  };

  const checkStatus = (chanId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await makeRequest({
          method: 'getPaymentTransactionStatus',
          targetType: 'paymentGatewayConverge',
          version: '1.0',
          parameters: {
            chanId
          }
        });
        if (res.data?.paymentGatewayCommand?.completed) {
          resolve(res);
        } else {
          reject(`No updates to show.`);
        }
      } catch (error) {
        reject(error);
      }
    });
  };

  const getErrors = (r) => {
    let error = `There's been an error while processing transcation.`;
    if (!r?.data?.paymentGatewayCommand?.paymentTransactionData) {
      return null;
    }
    const ptd = r?.data?.paymentGatewayCommand?.paymentTransactionData;
    if (ptd.result !== 'APPROVED') {
      if (ia(ptd.errors)) {
        error = (
          <>
            {ptd.errors.length > 1 && (
              <div>`Errors have occurred while proccessing transaction`</div>
            )}
            {ptd.errors.length > 0 && (
              <ul>
                {ptd.errors.map((v, i) => (
                  <li key={`ptd-error-terminal-interface-index-${i}`}>{v}</li>
                ))}
              </ul>
            )}
          </>
        );
      }
    }
    return error;
  };

  const completeTransaction = async (raw_transaction) => {
    try {
      const ptd = raw_transaction?.data?.paymentGatewayCommand?.paymentTransactionData;
      let e = getErrors(raw_transaction);
      let transaction = {
        pan: ptd.maskedPan,
        cardScheme: ptd.cardScheme,
        cardEntryType: ptd.cardEntryType,
        resultMessage: ptd.resultMessage,
        result: ptd.result,
        amount: ptd.amount,
        id: ptd.id,
        chanId: raw_transaction?.data?.paymentGatewayCommand?.chanId
      };
      if (ptd.tokenizedCard) {
        transaction['tokenizedCard'] = ptd.tokenizedCard;
      }
      const res = await interimApi(
        '/api/transactions/terminal/transaction/complete',
        {
          invoices,
          patientId,
          transaction,
          appointmentId,
          rawTransaction: raw_transaction
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          updateBalance(amount - ptd.amount.value);
          updateAmount(ptd.amount.value);
          setShow((prevState) => ({ ...prevState, success: true }));
          break;
        default:
          let errorMsg = error || `An unexpected error has occurred. Please try again later.`;
          toast.error(errorMsg);
          onFail(errorMsg);
          return;
          break;
      }
      if (ptd.result === 'APPROVED') {
        setReceipt(res.data.receipt.data);
        onComplete();
        showAlert({ title: 'Transaction Finished!', color: 'success' });
      } else {
        onFail(e);
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
      onFail();
    }
  };

  const makeRequest = (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        let pgid = paymentGatewayId;
        if (!pgid) {
          pgid = paymentGatewayIdRef.current;
        }
        const res = await terminalRequest({
          ...data,
          parameters: { ...data.parameters, paymentGatewayId: pgid },
          requestId: incrementRequestId(),
          version: '1.0'
        });

        setResponse([...response, res.data]);
        resolve(res.data);
      } catch (error) {
        reject(error);
      }
    });
  };

  const lookupTransactions = async () => {
    try {
      await makeRequest({
        method: 'searchPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          first6CC: null,
          creditCard: null,
          utcDate: null,
          endDate: '20220513',
          beginDate: '20220511',
          transId: null,
          note: ''
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const checkCredentials = async (d) => {
    try {
      const res = await makeRequest({
        method: 'searchPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          first6CC: null,
          creditCard: null,
          utcDate: null,
          endDate: `${moment().format('YYYYMMDD')}`,
          beginDate: `${moment().subtract(1, 'd').format('YYYYMMDD')}`,
          transId: null,
          note: ''
        }
      });
      if (res.data.paymentGatewayCommand?.completed) {
        setShow((prevState) => ({ ...prevState, canRunTransaction: amount > 0 }));
      } else {
        openPaymentGateway(d);
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  const changeTerminal = async () => {
    try {
      setShow((prevState) => ({ ...prevState, selectTerminal: true }));
      await listTerminals();
    } catch (error) {
      console.error(error);
    }
  };

  const onDemandCheckStatus = async () => {
    try {
      const res = await checkStatus(transactionChanId);
      completeTransaction(res);
    } catch (error) {
      console.error(error);
      toast.error(`There's been an error while checking status`);
    }
  };

  const voidTransaction = async (t) => {
    if (!t) {
      toast.error(`No transaction selected.`);
      return;
    }
    try {
      let requestBody = {
        method: 'startPaymentTransaction',
        targetType: 'paymentGatewayConverge',
        version: '1.0',
        parameters: {
          transactionType: 'VOID',
          originalTransId: t,
          tenderType: 'CARD'
        }
      };
      if (saveCard) {
        requestBody.parameters['generateToken'] = true;
        requestBody.parameters['addToken'] = true;
      }
      const res = await makeRequest(requestBody);
      let newChanId = res?.data?.paymentGatewayCommand?.chanId;
      setChanId(newChanId);
      rCheckStatus(newChanId, (r) => notifyVoidTransaction(t, r));
    } catch (error) {
      console.error(error, transactionChanId);
      toast.error(`There's been an error voiding a transaction.`);
      onFail(`There was an error while voiding this transaction. Please try again later.`);
    }
  };

  const notifyVoidTransaction = async (t, res) => {
    try {
      let e = getErrors(res);

      if (res.data?.paymentGatewayCommand?.paymentTransactionData?.result !== 'APPROVED') {
        console.error(res.data);
        toast.error(`There's been an error voididng the transaction.`);
        onFail(e);
        return;
        // onFail(`An error has occurred: ${res.data.paymentGatewayCommand.}`)
      }
      const voidRes = await interimApi(
        '/api/transactions/terminal/transaction/void',
        {
          elavonTransactionId: t
        },
        navigate
      );
      const { code, redirect, error } = voidRes.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          toast.success(`Successfully voided trnasaction`);
          break;
        default:
          let errorMsg = error || `There's been an unexpected error with this transaction.`;
          toast.error(errorMsg);
          onFail(errorMsg);
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
      onFail(`There's been an unexpected error. Please try again later.`);
    }
  };

  if (show.noInterface) {
    return (
      <>
        <Spinner />
        <div>{loadingMessage}</div>
      </>
    );
  }

  if (show.success) {
    return (
      <div>
        <div>
          <Icon icon="new-check" size={30} color="white" />
        </div>
        <h3>Transaction completed!</h3>
        <Button text="Close" color="success" onClick={onClose} />
      </div>
    );
  }

  if (show.finishTransaction) {
    return (
      <div>
        <h3>Finish Transaction</h3>
        <div>Please enter the Chan-ID for the transaction you want to finish</div>
        <input
          value={transactionChanId}
          onChange={(e) => setChanId(e.target.value)}
          placeholder="Chan-ID"
        />
        <div className="flex flex-wrap">
          <Button
            text="Cancel"
            color="warning"
            onClick={() => setShow({ ...show, finishTransaction: false })}
          />
          <Button
            text="Continue"
            color="success"
            onClick={() =>
              setShow({ ...show, finishTransaction: false, transaction_in_progress: true })
            }
          />
        </div>
      </div>
    );
  }

  if (show.voidTransaction) {
    return (
      <div>
        <h3>Finish Transaction</h3>
        <div>Please enter the Chan-ID for the transaction you want to void</div>
        <input
          value={transactionChanId}
          onChange={(e) => setChanId(e.target.value)}
          placeholder="Chan-ID"
        />
        <div className="flex flex-wrap">
          <Button
            text="Cancel"
            color="warning"
            onClick={() => setShow({ ...show, finishTransaction: false })}
          />
          <Button
            text="Void"
            color="success"
            onClick={() => {
              voidTransaction(transactionChanId);
              setShow({ ...show, voidTrnasaction: false, transaction_in_progress: true });
            }}
          />
        </div>
      </div>
    );
  }

  if (show.moreOptions) {
    return (
      <div className="flex flex-col flex-wrap">
        <h3>More Options</h3>
        <Button
          text="Finish a transaction"
          onClick={() => setShow({ ...show, finishTransaction: true })}
        />
        <Button
          text="Void a transaction"
          onClick={() => setShow({ ...show, voidTransaction: true })}
        />
        <Button text="Back" onClick={() => setShow({ ...show, finishTransaction: true })} />
      </div>
    );
  }

  if (show.selectTerminal) {
    return (
      <div className="flex flex-col flex-wrap">
        <h3>Select a Merchant</h3>
        <table>
          <thead>
            <tr>
              <td>ID</td>
              <td>Name</td>
              <td>Notes</td>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {terminals.map((v) => (
              <tr>
                <td>{v.id}</td>
                <td>{v.name}</td>
                <td>{v.note}</td>
                <td>
                  <Button
                    text="Select"
                    color="success"
                    onClick={selectTerminal}
                    name={v.id}
                    data-qa={`select-merchant-btn-${v.id}`}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  // if (!details.confirmed && false) {
  //   return (
  //     <div className='flex flex-col flex-wrap'>
  //       <input
  //         onChange={(e) => setDetails({ ...details.current, vendorId: e.target.value })}
  //         value={details.current.vendorId}
  //         placeholder="vendorId"
  //       />
  //       <input
  //         onChange={(e) => setDetails({ ...details.current, merchantId: e.target.value })}
  //         value={details.current.merchantId}
  //         placeholder="merchantId"
  //       />
  //       <input
  //         onChange={(e) => setDetails({ ...details.current, pin: e.target.value })}
  //         value={details.current.pin}
  //         placeholder="pin"
  //       />
  //       <input
  //         onChange={(e) => setDetails({ ...details.current, userId: e.target.value })}
  //         value={details.current.userId}
  //         placeholder="userId"
  //       />
  //       <input
  //         onChange={(e) => setDetails({ ...details.current, paymentGatewayEnvironment: e.target.value })}
  //         value={details.current.paymentGatewayEnvironment}
  //         placeholder="paymentGatewayEnvironment"
  //       />
  //       <Button text='Confirm' onClick={() => setDetails({ ...details.current, confirmed: true })} />
  //     </div>
  //   );
  // }

  if (transaction_status.transactionInProgress) {
    return (
      <div className="flex flex-col flex-wrap">
        <h3>Transaction In Progress</h3>
        <div className="flex flex-wrap">
          <Button text="Check Status" color="warning" onClick={onDemandCheckStatus} />
          <Button text="Cancel Transcation" color="danger" onClick={cancelTransaction} />
        </div>
      </div>
    );
  }

  if (show.canRunTransaction) {
    return (
      <div className="flex flex-col flex-wrap">
        <h3>New Transaction - Card Present</h3>
        <Button
          text={`Start Transaction for ${mString(amount)}`}
          color="success"
          onClick={startTransaction}
          name={v.id}
        />
        <div className="flex flex-wrap">
          <Button text="Change Terminal" color="warning" onClick={changeTerminal} />
          <Button
            text="More Options"
            color="secondary"
            onClick={() => setShow({ ...show, moreOptions: true })}
          />
        </div>
        <input
          value={transactionChanId}
          onChange={(e) => {
            setChanId(e.target.value);
          }}
        />
        {transactionChanId && (
          <div>
            <Button text="Check Status" color="warning" onClick={onDemandCheckStatus} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-wrap">
      <h3>New Transaction - Card Present</h3>
      <Button text="Change Terminal" color="warning" onClick={changeTerminal} />
      <div>Terminal currently unavailable.</div>
    </div>
  );

  return (
    <div className="flex flex-wrap">
      {!transactionChanId && (
        <div className="flex flex-col flex-wrap">
          <Button text="Open Payment Gateway" onClick={openPaymentGateway} />
          <input onChange={(e) => setPaymentGatewayId(e.target.value)} value={paymentGatewayId} />
        </div>
      )}
      {paymentGatewayId && (
        <div className="flex flex-col flex-wrap">
          <div>
            <span>Transaction chanId</span>
            <span>{transactionChanId}</span>
          </div>
          {!transactionChanId && (
            <Button text="Start Transaction" color="success" onClick={startTransaction} />
          )}
          {transactionChanId && (
            <Button text="Cancel Transaction" color="danger" onClick={cancelTransaction} />
          )}

          <Button
            text="Check Status"
            color="warning"
            onClick={() => rCheckStatus(transactionChanId)}
          />
          <Button text="Lookup Transactions" onClick={lookupTransactions} />
        </div>
      )}
      <div className="flex flex-col flex-wrap">
        <div>Responses:</div>
        {response.map((v, i) => (
          <div key={`terminal-wrapper-${i}`}>{JSON.stringify(v)}</div>
        ))}
      </div>
    </div>
  );
};

export default TerminalWrapper;
