const useRandomGradient = (number) => {
  const gradientAngle = 105.63;
  const gradientStartPercentage = -2.73;
  const gradientEndPercentage = 92.21;

  const gradients = [
    { colorStart: '#DEB8F5', colorEnd: '#BA42C0' },
    { colorStart: '#FFB648', colorEnd: '#FF9797' },
    { colorStart: '#004F6B', colorEnd: '#86EFAC' },
    { colorStart: '#4B749F', colorEnd: '#243748' },
    { colorStart: '#392D69', colorEnd: '#B57BEE' },
    { colorStart: '#F86594', colorEnd: '#FFCAA6' },
    { colorStart: '#00ECBC', colorEnd: '#007ADF' },
    { colorStart: '#3378FF', colorEnd: '#9442FE' },
    { colorStart: '#13B8FF', colorEnd: '#00678C' },
    { colorStart: '#16A34A', colorEnd: '#00678C' },
    { colorStart: '#90BF84', colorEnd: '#C1ECB8' },
    { colorStart: '#CA5FDA', colorEnd: '#FFCFAA' },
    { colorStart: '#DEBE5C', colorEnd: '#FCDC95' },
    { colorStart: '#BCC5CE', colorEnd: '#939FAE' },
    { colorStart: '#71C5EE', colorEnd: '#00437B' }
  ];

  const formattedGradients = gradients.map(
    (gradient) =>
      `linear-gradient(${gradientAngle}deg, ${gradient.colorStart} ${gradientStartPercentage}%, ${gradient.colorEnd} ${gradientEndPercentage}%)`
  );

  return formattedGradients[number % gradients.length];
};

export default useRandomGradient;
