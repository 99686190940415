import { Popover } from '@headlessui/react';
import cs from 'classnames';
import moment from 'moment';
import React, { useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../../../../api/Api';
import { mString } from '../../../../../../../lib/helpers/utility';
import PrintView from '../../../../../../Patient/quote/components/PrintView';
import InvoiceReadOnly from '../../../../../../Payments/components/InvoiceReadOnly';
import { showAlert } from '../../../../../../shared/Alert/Alert';
import Button from '../../../../../../shared/Buttons/Button';
import Icon from '../../../../../../shared/Icon/Icon';
import Loader from '../../../../../../shared/Loader/Loader';
import Modal from '../../../../../../shared/Modal/Modal';
import Pagination from '../../../../../../shared/Pagination/Pagination';
import { currentPractice } from '../../../../../practiceState';

const QuotesTable = ({ quotes, setQuotes, filters, quotesCount, setFilters }) => {
  const ref = useRef();
  const navigate = useNavigate();
  const { patient } = useOutletContext();
  const practice = useRecoilValue(currentPractice);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);

  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };

  const convertQuote = (quote, close) => {
    const onSuccess = (data) => {
      if (!!data?.invoice) {
        close();

        setLoading(false);

        setQuotes((prev) => prev.filter((i) => i.id !== data?.invoice?.id));

        showAlert({ title: 'Quote', message: 'Quote converted to invoice.' });
      }
    };

    const onError = (error, code) => {
      if (code == 4) {
        error.map((errorMessage) =>
          showAlert({ title: 'Error', message: errorMessage, color: 'danger' })
        );
      }

      setLoading(false);
    };

    setLoading(true);

    requestApi({
      onError,
      navigate,
      onSuccess,
      params: { invoice: quote },
      url: '/api/patient/quote/convert'
    });
  };

  return (
    <>
      <table className="primary-table">
        <thead>
          <tr>
            <th className="w-[40%] cursor-pointer">Name</th>
            <th className="cursor-pointer">Created date</th>
            <th className="cursor-pointer">Total payment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {quotes.map((quote, i) => (
            <tr key={quote.id}>
              <td className="!font-500">{quote.name}</td>
              <td>{moment(quote.created_at).format('MM/DD/YYYY')}</td>
              <td>{mString(quote.total_amount - quote.amount_paid)}</td>

              <td className="relative w-[50px]">
                <Popover className="relative">
                  {({ open, close }) => (
                    <div>
                      <Popover.Button
                        className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                          !open && 'hover:!bg-white'
                        } ${open && '!bg-primary-700 transition-all'}`}>
                        <Icon
                          icon={'three-dots'}
                          className="flex cursor-pointer"
                          color={open ? 'white' : 'black'}
                        />
                      </Popover.Button>

                      <Popover.Panel
                        className={cs(
                          'absolute !px-2 right-0 top-10 z-[99999] w-max cursor-pointer rounded-lg  bg-white !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]',
                          (i === quotes?.length - 1 || i === quotes?.length - 2) && 'top-[-90px]'
                        )}>
                        <div
                          data-qa="preview-details"
                          className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                          onClick={() => {
                            setInvoice(quote);
                            close();
                          }}>
                          <Icon icon="new-eye" color="primary" />

                          <div className="ml-1 text-sm text-primary-900">Preview Details</div>
                        </div>

                        <div
                          data-qa="convert-to-invoice"
                          className={cs(
                            loading && 'pointer-events-none cursor-not-allowed',
                            'flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50'
                          )}
                          onClick={() => convertQuote(quote, close)}>
                          {loading ? (
                            <Loader outlined={true} />
                          ) : (
                            <Icon icon="new-import-bulk" color="primary" />
                          )}

                          <div className="ml-1 text-sm text-primary-900">Convert to invoice</div>
                        </div>
                      </Popover.Panel>
                    </div>
                  )}
                </Popover>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="mt-[26px] flex items-center justify-between">
        <label className="text-xs text-primary-900">
          Showing {filters.page * filters.limit - 9}-
          {filters.page * filters.limit < quotesCount ? filters.page * filters.limit : quotesCount}{' '}
          out of {quotesCount}
        </label>

        <Pagination
          page={filters.page}
          perPage={filters.limit}
          totalItems={quotesCount}
          onPageChange={changePage}
        />
      </div>

      {!!invoice ? (
        <Modal
          slideFromRight
          isOpen={!!invoice}
          bodyClassName="!p-0"
          title="Preview Quote"
          className="!w-[800px] bg-white"
          handleClose={() => setInvoice(false)}
          headButton={
            <div className="flex justify-end items-center w-full">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color=""
                    size="small"
                    type="link"
                    text="Print"
                    icon="new-printer-bulk"
                    className="text-primary-900"
                  />
                )}
                content={() => ref.current}
              />
            </div>
          }>
          <div ref={ref}>
            <PrintView
              patient={patient}
              practice={practice}
              createdAt={invoice?.created_at}
              content={<InvoiceReadOnly invoices={[invoice]} />}
            />
          </div>
        </Modal>
      ) : null}
    </>
  );
};

export default QuotesTable;
