import { requestApi } from './Api';

export const createMedication = async (navigate, params, onError, onSuccess) =>
  await requestApi({
    navigate,
    url: '/api/medications/create',
    params,
    onError,
    onSuccess
  });

export const getMedications = async (navigate = () => {}, params) =>
  await requestApi({
    navigate,
    url: '/api/medications/get',
    params
  });

export const deleteMedication = async (navigate = () => {}, params) =>
  await requestApi({
    navigate,
    url: '/api/medications/delete',
    params
  });
export const upsertWeightLossSync = async (navigate = () => {}, params) =>
  await requestApi({
    navigate,
    url: '/api/medications/upsert_weightloss_sync',
    params
  });
export const addMedicationToWeightLoss = async (navigate = () => {}, params) =>
  await requestApi({
    navigate,
    url: '/api/medications/add_medication_weightloss',
    params
  });
