export const groupComponentsByType = (components) => {
  return components.reduce((acc, component) => {
    const { label, title, forms, type } = component;
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push({ title, forms, type });
    return acc;
  }, {});
};

export function getSelectedForms(selectedChatComponentTitles, clinicalNoteForms, advancedForms) {
  let selectedForms = {};

  selectedChatComponentTitles.forEach((key) => {
    switch (key) {
      case 'complaints':
        selectedForms[key] = {
          other_complaints: clinicalNoteForms?.['other_complaints'],
          present_illness: clinicalNoteForms?.['present_illness']
        };
        break;
      case 'patient-needs':
        selectedForms[key] = clinicalNoteForms?.['present_illness'];
        break;
      case 'cpt-codes':
        selectedForms[key] = clinicalNoteForms?.['cpt_codes'];
        break;
      case 'diagnosis-codes':
        selectedForms[key] = clinicalNoteForms?.['icd_10_codes'];
        break;
      default:
        if (clinicalNoteForms?.[key]) {
          selectedForms[key] = clinicalNoteForms[key];
        }
        const currentForm = advancedForms?.find((form) => form?.custom_title === key);
        if (currentForm) {
          selectedForms[key] = { ...currentForm, isAdvanceForm: true };
        }
    }
  });

  return selectedForms;
}
