import React from 'react';

import cs from 'classnames';

import { snakeToTitleCase } from 'lib/helpers/utility';

import Avatar from 'components/shared/Avatar/Avatar';

const Hover = (item) => {
  const { image, firstName, lastName, id, description, itemsGroupBy, itemsGroupLabelClassName } =
    item;

  return (
    <div className="flex flex-col">
      {item[itemsGroupBy] && (
        <span
          className={cs(
            'px-4 py-1 text-xs font-500 leading-[14px] text-primary-500',
            itemsGroupLabelClassName
          )}>
          {snakeToTitleCase(item[itemsGroupBy])}
        </span>
      )}
      <div className="flex items-center gap-2 pl-4 pr-3 py-1 select-none">
        <Avatar
          size="large"
          fontSize="text-xs"
          firstName={firstName}
          lastName={lastName}
          image={image}
          id={id}
          isSquare={false}
        />
        <div className="flex flex-col overflow-hidden">
          {(firstName || lastName) && (
            <span
              className="text-sm text-neutral-800 leading-[18px] font-500 line-clamp-1"
              title={`${firstName} ${lastName}`}>
              {firstName} {lastName}
            </span>
          )}
          {description && (
            <p
              className="text-xs font-400 text-neutral-700 leading-[14px] line-clamp-2"
              title={description}>
              {description}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Hover;
