import { requestApi } from './Api';

export const getCptCodes = async (navigate, params) =>
  requestApi({
    url: '/api/codes/cpt/get',
    navigate,
    params
  });

export const deleteCptCode = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/codes/cpt/delete',
    params
  });
};

export const getWithSpecialties = async (navigate, params) =>
  requestApi({
    url: '/api/codes/cpt/get_with_specialties',
    navigate,
    params
  });
