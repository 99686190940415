import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { imagePath, reShapeProcedures } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';
import Tabs from 'components/shared/Tabs/NewTabs';

import AddProceduresProducts from './AddProceduresProducts';

const ProceduresProducts = ({ packages = [], formik: formikProp }) => {
  const { formik: formikContext } = useNewAppointmentContext() || {};
  const practice = useRecoilValue(currentPractice);
  const { packages: packagesPermission = false, products: productsPermission = false } =
    practice.display_settings;
  const formik = formikProp || formikContext;
  const { values } = formik || {};
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Services',
      length: values?.procedures?.length,
      permissions: ['services.read', 'packages.read'],
      content: (
        <AddProceduresProducts
          type="procedures"
          label="Services"
          endpoint="api/practice/services/get"
          queryKey="services"
          packageList={packagesPermission ? packages : []}
          formik={formik}
          formatData={(data) => reShapeProcedures(data)}
        />
      ),
      hasPermission: true
    },
    {
      label: 'Products',
      length: values?.products?.length,
      permissions: ['products.read'],
      content: (
        <AddProceduresProducts
          type="products"
          label="Products"
          endpoint="api/product/get"
          queryKey="products"
          formik={formik}
          formatData={(data) =>
            data.map((p) => ({
              ...p,
              total_amount_cents: p?.amount_cents,
              sales_count: 1,
              ...(p?.image && {
                image: imagePath(JSON.parse(p?.image || `{}`)?.jpg)
              })
            }))
          }
        />
      ),
      hasPermission: productsPermission
    }
  ];

  return (
    <Tabs
      tabsData={tabsData}
      theme="secondary"
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      hasContent
      className="!pl-4"
      panelsClassName="z-[60]"
    />
  );
};

export default ProceduresProducts;
