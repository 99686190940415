import React from 'react';
import Switch from 'components/shared/Switch/Switch';
import { getActionDefinition, getFieldValue } from './lib/helper';
import cs from 'classnames';

const Dependencies = ({ permissions, formik, dependencies, turnedOn = true }) => {
  return (
    <div
      className={cs(
        'grid w-full items-center px-4 py-2',
        turnedOn ? 'bg-neutral-100' : 'bg-warning-100'
      )}>
      <p className="mb-2 text-[0.87rem] font-medium leading-snug text-primary-900">
        {turnedOn
          ? `The above action requires these permissions, which have been automatically enabled.`
          : `The actions below have been turned off automatically, as they depend on the action that has been disabled.`}
      </p>
      <div className="grid gap-[2px]">
        {dependencies.map((dependency) => {
          const dependencyAction = getActionDefinition(permissions, dependency);
          return (
            <div
              key={dependency}
              className={cs(
                'flex w-full grid-cols-2 items-center justify-between px-4 py-3',
                turnedOn ? 'bg-secondary-50' : 'bg-warning-50'
              )}>
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-0.5">
                <div className="text-sm font-medium leading-snug text-primary-900">
                  {dependencyAction.title}
                </div>
                <div className="text-xs font-normal leading-tight text-neutral-500">
                  {dependencyAction.description}
                </div>
              </div>
              <Switch
                size={18}
                color={turnedOn ? 'primary' : 'warning'}
                checked={getFieldValue(formik, dependency)}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dependencies;
