import { requestApi } from './Api';

export const getPatients = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/practice/charts/get', params });

export const getPatientsAdmin = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/patient/get/multiple', params });

export const getPatient = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/patient/get/one', params });

export const updateProfilePicture = async (navigate, params) =>
  await requestApi({ navigate, url: '/api/filestack/profile_photo', params });

export const updatePatient = async (navigate, options) =>
  await requestApi({
    url: '/api/user/update',
    params: options,
    navigate
  });

export const updatePracticePatientLink = async (navigate, options) =>
  await requestApi({
    url: '/api/practice/patient/link/update',
    params: options,
    navigate
  });

export const createPatient = async (navigate, options) =>
  await requestApi({
    url: '/api/practice/patient/add_patient',
    params: options,
    navigate
  });

export const chartExport = async (navigate, options) =>
  await requestApi({
    url: '/api/practice/charts/export',
    params: options,
    navigate
  });

export const mergePatients = async (navigate, options) =>
  await requestApi({
    url: '/api/practice/charts/merge',
    params: options,
    navigate
  });
