import { useQuery } from '@tanstack/react-query';
import { getPracticeInstantPacket } from 'api/InstantPacket';
import { useNavigate } from 'react-router-dom';

export const useInstantPacket = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['document-history', ...dependencies],
    () => getPracticeInstantPacket(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
