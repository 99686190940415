import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const AdminUserTable = ({ children }) => {
  return (
    <table className='primary-table'>
      <thead>
        <tr>
          <td>ID</td>
          <td colSpan={2}>Name</td>
          <td>Email</td>
          <td>Status</td>
          <td>Options</td>
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

export default withErrorBoundary(AdminUserTable);
