import React, { useEffect, useState } from 'react';
import { requestApi } from 'api/Api';
import { runEligibility } from 'components/practice/appointment/PreviewAppointment/lib/updateStateHelpers';
import EligibilityPreview from 'components/practice/Calendar/RBCalendar/BenefitPreview/EligibilityPreview';
import Eligibility from 'components/practice/Calendar/RBCalendar/Eligibility';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Tabs from 'components/shared/Tabs/NewTabs';
import { Capitalize, pm } from 'lib/helpers/utility';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Button from '../../../shared/Buttons/Button';
import Input from '../../../shared/Input/Input';
import PermissionsAllowed from '../../../shared/Permissions/Allowed';
import InsuranceProfiles from '../InsuranceProfile/InsuranceProfiles';
import Pharmacies from '../Pharmacy/Pharmacies';
import CMS1500Fields from './components/CMS1500Fields';
import EmergencyContact from './components/EmergencyContact';
import PersonalInfo from './components/PersonalInfo';
import PhysicalAddress from './components/PhysicalAddress';
import ReferringProviders from './ReferringProvider/ReferringProviders';
import { useRecoilValue } from 'recoil';
import state from 'components/state';

export default function EditDemographics({
  handleSubmit,
  onChange,
  stateList,
  getSSN,
  setDecodedSSN,
  decodedSSN,
  practice_state,
  formRef,
  formik,
  insuranceProfiles,
  pharmacies,
  currPractice,
  practicePatientData,
  occupationList,
  loading
}) {
  const patientId = formik?.values?.id;
  const practiceId = currPractice?.id;
  const [canEdit, setCanEdit] = useState(false);
  const navigate = useNavigate();
  const { kiosk, fakeModal, setFakeModal } = useOutletContext();
  const p = useRecoilValue(state.permissions);

  const isPermittedCheck = async () => {
    const { permissions } = await requestApi({ url: '/api/permissions/get', navigate });
    const editable = pm(permissions, 'patients.update');
    setCanEdit(editable);
  };

  useEffect(() => {
    isPermittedCheck();
  }, []);
  const [showEligibilityPopover, setShowEligibilityPopover] = useState(false);
  const [runningEligibility, setRunningEligibility] = useState(false);
  const [eligibilityLimitReached, setEligibilityLimitReached] = useState(false);
  const [showEligibilityResponse, setShowEligibilityResponse] = useState(false);
  const [hasInsurance, setHasInsurance] = useState(false);
  const [insuranceBenefits, setInsuranceBenefits] = useState({});
  const [tabIndex, setTabIndex] = useState(0);

  const closeEligibilityModal = () => setShowEligibilityResponse(false);

  useEffect(() => {
    setEligibilityLimitReached(formik.values?.eligibility_limit_reached);
    setInsuranceBenefits(formik.values?.insurance_benefits);
    setHasInsurance(insuranceProfiles?.length > 0);
  }, [insuranceProfiles, insuranceBenefits, formik.values]);

  const eligibilityPresent = formik.values?.insurance_benefits?.primary?.retrieved || false;

  const handleRunEligibility = (insuranceId) => {
    runEligibility({
      insuranceBenefits,
      fromDemographics: true,
      setInsuranceBenefits,
      setRunningEligibility,
      insuranceId,
      formik
    });
    setShowEligibilityPopover(false);
  };

  const tabsData = [];

  for (let type in insuranceBenefits) {
    const insurance = insuranceBenefits[type];

    if (['primary', 'secondary', 'tertiary'].includes(type)) {
      tabsData.push({
        label: Capitalize(type),
        content: (
          <EligibilityPreview
            eligibility={insurance.benefits}
            closeModal={closeEligibilityModal}
            insuranceProfilesList={insuranceProfiles}
          />
        )
      });
    }
  }

  return (
    <div className="mb-8 !px-4">
      <form ref={formRef} onSubmit={handleSubmit} className="relative grid !gap-y-5">
        <PersonalInfo
          formik={formik}
          onChange={onChange}
          occupationList={occupationList}
          getSSN={getSSN}
          decodedSSN={decodedSSN}
          setDecodedSSN={setDecodedSSN}
          practice_state={practice_state}
          disabled={!canEdit}
        />

        <PermissionsAllowed requiredPermissions="patients_address.read">
          <PhysicalAddress
            formik={formik}
            onChange={onChange}
            stateList={stateList}
            disabled={!pm(p, 'patients_address.update') && !canEdit}
          />
        </PermissionsAllowed>

        <InsuranceProfiles
          patientId={patientId}
          practiceId={practiceId}
          eligibilityPresent={eligibilityPresent}
          setShowEligibilityResponse={setShowEligibilityResponse}
          insuranceProfiles={insuranceProfiles}
          kiosk={kiosk}
          fakeModal={fakeModal}
          setFakeModal={setFakeModal}
          eligibility={
            hasInsurance ? (
              <Eligibility
                eligibilityLimitReached={eligibilityLimitReached}
                setShow={setShowEligibilityPopover}
                show={showEligibilityPopover}
                insuranceProfilesList={insuranceProfiles}
                callback={handleRunEligibility}
                loading={runningEligibility}
                existEligibility={eligibilityPresent}
                eligibilityCounter={currPractice?.eligibility_counter}
              />
            ) : (
              false
            )
          }
        />

        <Pharmacies pharmacies={pharmacies} patientId={patientId} practiceId={practiceId} />

        <EmergencyContact formik={formik} onChange={onChange} disabled={!canEdit} />

        <div>
          <label className="my-2 text-lg font-600">Custom Chart Id</label>

          <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
            <Input
              data-qa="custom-id"
              name="custom_id"
              label="Custom Id"
              onChange={onChange}
              placeholder="Custom Chart Id"
              value={formik?.values?.custom_id}
              error={formik?.errors?.custom_id}
              disabled={!canEdit}
            />
          </div>
        </div>

        {practicePatientData && (
          <ReferringProviders patientId={patientId} practicePatient={practicePatientData} />
        )}
        <CMS1500Fields
          formik={formik}
          onChange={onChange}
          currPractice={currPractice}
          disabled={!canEdit}
        />

        <Modal
          slideFromRight
          bodyClassName="p-0"
          className="w-[1200px]"
          title="Eligibility response"
          handleClose={closeEligibilityModal}
          handleOpen={showEligibilityResponse}>
          <Tabs
            hasContent
            theme="secondary"
            tabsData={tabsData}
            className="z-[100]"
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            tabsParentClassName="!sticky top-0 z-[99]"
          />
        </Modal>

        <PermissionsAllowed requiredPermissions="patients.update">
          <div className="fixed bottom-10 right-[70px] md:right-[48px]">
            <Button
              text="Update"
              buttonType="submit"
              data-qa="update-btn"
              loading={loading}
              disabled={loading}
            />
          </div>
        </PermissionsAllowed>
      </form>
    </div>
  );
}
