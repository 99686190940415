import Icon from 'components/shared/Icon/Icon';
import moment from 'moment';
import React from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import insurance from './img/insurance.png';
import payment from './img/payment.png';
import personal_id from './img/personal_id.png';
import personal_info from './img/personal_info.png';
import form0 from './img/custom_form0.png';
import hipaa from './img/hipaa.png';

const images = {
  insurance,
  payment,
  'personal-id': personal_id,
  'personal-info': personal_info,
  form: form0,
  hipaa
};

const PastForms = () => {
  const { steps, disableContinue, checkinButton, setSteps } = useOutletContext();
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-start gap-3 p-6">
        <Icon icon="new-info" className="mt-2"></Icon>
        <p className="text-sm text-neutral-600">
          Please take a moment to review the information you’ve previously provided. Update any
          sections where information may have changed to ensure accuracy. If everything is correct,
          simply confirm to proceed. Keeping your details up to date helps us provide you with the
          best care possible.
        </p>
      </div>
      <div className="grid h-auto grid-cols-2 items-start gap-2 p-6 sm:grid-cols-1 xs:grid-cols-1">
        {steps
          .find((step) => step.past)
          ?.past.map((step, index) => (
            <div
              onClick={() => {
                if (disableContinue) return;
                navigate(step.path);
              }}
              className="flex cursor-pointer flex-col gap-4 rounded-lg border border-solid border-neutral-100 bg-white p-4 shadow-[0px_4px_16px_0px_#0085B514] hover:border-primary-200 hover:bg-primary-50"
              key={index}>
              <p className="font-500 text-neutral-800">{step.name}</p>

            <img className="aspect-[2] w-full" src={images[step.kind]} />

              <i className="text-center text-sm text-neutral-500">
                Last updated: {moment(step.updated).format('MM/DD/YYYY')}
              </i>
            </div>
          ))}

        <div
          hidden
          ref={checkinButton}
          onClick={() => {
            setSteps((p) => p.map((s) => (s.past ? { ...s, completed: true } : s)));
            navigate('../review');
          }}></div>
      </div>
    </div>
  );
};

export default PastForms;
