import React from 'react';
import Popup from 'components/shared/Filters/Popup';
import cs from 'classnames';

const CustomContact = ({ handleCustom, type }) => {
  return (
    <div
      onClick={handleCustom}
      className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
      Custom {type}
    </div>
  );
};

export default CustomContact;
