import cs from 'classnames';
import moment from 'moment-timezone';

import BaseHint from '../../shared/Hint';
import { formatHint } from '../lib/formatHint';
import { hintTooltip } from '../lib/hintTooltip';

const Hint = ({
  isValue,
  value,
  timezone,
  isTime,
  isTimeRange,
  isTimezone,
  isSeconds,
  disabled,
  isHint,
  error
}) => {
  const isSameTimezoneOffset = () => {
    const localOffset = moment().tz(moment.tz.guess())?.utcOffset();
    const practiceOffset = moment().tz(timezone)?.utcOffset();
    return localOffset !== practiceOffset;
  };

  const hint = formatHint({ value, timezone, isTime, isTimeRange, isSeconds });
  const isHintValid = isTimezone && isSameTimezoneOffset() && isValue && isHint;

  return (
    <>
      {isHintValid && (
        <BaseHint
          hint={hint}
          disabled={disabled}
          tooltip={hintTooltip(timezone)}
          tooltipClassName="w-[250px]"
          color="danger"
        />
      )}
      {error && (
        <p className={cs('text-sm text-danger-500', isHintValid ? 'pl-5 pt-1' : 'pt-2')}>{error}</p>
      )}
    </>
  );
};

export default Hint;
