import { ia } from 'lib/helpers/utility';
import moment from 'moment';

export default function validationChecks(val, validationErrors) {
  let hasErrors = false;
  const key = val.day;
  const entries = { ...val };
  delete entries.day;

  Object.entries(entries).map(([typeKey, value]) => {
    if (ia(value)) {
      hasErrors = checkBreaks(value, validationErrors, key, typeKey, hasErrors);
    } else {
      hasErrors = checkTimes(value, validationErrors, key, typeKey, hasErrors);
    }
  });
  return hasErrors;
}

function checkBreaks(value, validationErrors, key, typeKey, hasErrors) {
  value.map((item) => {
    if (item.starts_at != null && item.ends_at == null) {
      hasErrors = true;
      validationErrors(
        `${key} - ${typeKey}`,
        `Please ensure both 'Starts At' and 'Ends At' times are defined for ${key} of ${typeKey}. Only defining 'Starts At' is insufficient.`
      );
    }
    if (item.starts_at == null && item.ends_at != null) {
      hasErrors = true;
      validationErrors(
        `${key} - ${typeKey}`,
        `Please specify both 'Starts At' and 'Ends At' times for ${key} of ${typeKey}. Only defining 'Ends At' is insufficient.`
      );
    }
    if (moment(item.starts_at, 'HH:mm a').isAfter(moment(item.ends_at, 'HH:mm a'))) {
      hasErrors = true;
      validationErrors(
        `${key} - ${typeKey}`,
        `Please ensure that the 'Starts At' time is before the 'Ends At' time for ${key} on ${typeKey}.`
      );
    }
    if (moment(item.starts_at, 'HH:mm a').isSame(moment(item.ends_at, 'HH:mm a'))) {
      hasErrors = true;
      validationErrors(
        `${key} - ${typeKey}`,
        `Please review the available times at ${key} of ${typeKey} again. The 'Starts At' and 'Ends At' times cannot be the same for a day.`
      );
    }
  });
  return hasErrors;
}

function checkTimes(value, validationErrors, key, typeKey, hasErrors) {
  const starts_at = moment(value.starts_at, 'HH:mm a');
  const ends_at = moment(value.ends_at, 'HH:mm a');
  if (value.starts_at != null && value.ends_at == null) {
    hasErrors = true;
    validationErrors(
      `${key} - ${typeKey}`,
      `Please ensure both 'Starts At' and 'Ends At' times are defined for ${key} of ${typeKey}. Only defining 'Starts At' is insufficient.`
    );
  }
  if (value.starts_at == null && value.ends_at != null) {
    hasErrors = true;
    validationErrors(
      `${key} - ${typeKey}`,
      `Please specify both 'Starts At' and 'Ends At' times for ${key} of ${typeKey}. Only defining 'Ends At' is insufficient.`
    );
  }
  if (starts_at.isAfter(ends_at)) {
    hasErrors = true;
    validationErrors(
      `${key} - ${typeKey}`,
      `Please ensure that the 'Starts At' time is before the 'Ends At' time for ${key} on ${typeKey}.`
    );
  }
  if (starts_at.isSame(ends_at)) {
    hasErrors = true;
    validationErrors(
      `${key} - ${typeKey}`,
      `Please review the available times at ${key} of ${typeKey} again. The 'Starts At' and 'Ends At' times cannot be the same for a day.`
    );
  }
  return hasErrors;
}
