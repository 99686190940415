import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getEras } from '../../../../api/ERA';

export const useEras = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['eras', ...dependencies], () => getEras(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
