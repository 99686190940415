import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPatientSuperbills } from 'api/Superbill';

export const usePatientSuperbill = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['patient_superbill', ...dependencies],
    () => getPatientSuperbills(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
