import { Honeybadger } from '@honeybadger-io/react';
import React, { useState, useRef, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import AddStaffContainer from './add_staff_container';
import { capitalize } from 'lodash';
import moment from 'moment';
import StaffTable from './practiceStaffTable';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import Popup from 'components/shared/Filters/Popup';
import { requestApi } from 'api/Api';
import StaffMember from 'components/Staff/components/StaffMember';
import SupervisorSettings from 'components/Staff/components/SupervisorSettings';
import Allowed from 'components/shared/Permissions/Allowed';
export default function AdminStaff() {
  const { practice, updatePractice } = useOutletContext();
  const { id } = useParams();
  const [deletePracticeStaffModal, setDeletePracticeStaffModal] = useState(null);
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [memberToSupervisor, setMemberToSupervisor] = useState();
  const [show, setShow] = useState({
    newMember: false,
    staffEdit: false,
    supervisorSettings: false,
    confirmImpersonate: false
  });
  const [isLoading, setIsLoading] = useState(false);

  const submitStaffRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      if (submitStaffRef.current?.isLoading !== isLoading) {
        setIsLoading(submitStaffRef.current?.isLoading);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isLoading]);

  const impersonateUser = async () => {
    try {
      sessionStorage.removeItem('myriad-session-reference-id');
      const res = await requestApi({
        url: '/api/admin/practice/impersonate',
        params: {
          targetId: selectedStaff.id
        },
        navigate
      });

      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setShow({ ...show, confirmImpersonate: false });
          window.open(redirect, '_blank');
          break;
        default:
          Honeybadger.notify(
            `file: staff/PractitionersBasicInfo, method: impersonateUser - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: staff/PractitionersBasicInfo, method: impersonateUser - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const Actions = ({ data, index }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              data-qa={`open-popover-${index}`}
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <Allowed requiredPermissions="impersonate.create">
                <div
                  data-qa="impersonate"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedStaff(data);
                    setShow({ ...show, confirmImpersonate: true });
                  }}>
                  <Icon icon="new-mask" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Impersonate</div>
                </div>
              </Allowed>
              <div
                data-qa="visit-profile"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  navigate(`/admin/practitioners/${data.id}`);
                }}>
                <Icon icon="new-eye" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Visit profile</div>
              </div>
              <Allowed requiredPermissions="member.update">
                <div
                  data-qa="edit-product-btn"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedStaff(data);
                    setShow({ ...show, staffEdit: true });
                  }}>
                  <Icon icon="new-edit" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Edit</div>
                </div>
              </Allowed>
              <div
                data-qa="delete-product-btn"
                className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                onClick={() => {
                  close();
                  setMemberToSupervisor(data);
                  setShow({ ...show, supervisorSettings: true });
                }}>
                <Icon icon="new-user-octagon" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Make supervisor</div>
              </div>
              <Allowed requiredPermissions="member.delete">
                <div
                  data-qa="delete-product-btn"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedStaff(data);
                    setDeletePracticeStaffModal(true);
                  }}>
                  <Icon icon="trash" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Delete</div>
                </div>
              </Allowed>
            </div>
          )}
        </Popup>
      </div>
    );
  };
  const ColorCircle = ({ value }) => {
    return (
      <div className="flex items-center">
        <div className="!mr-2 h-6 w-6 rounded-full" style={{ backgroundColor: value }}></div>{' '}
        {value}
      </div>
    );
  };
  const navigateToVistProfile = (id) => {
    navigate(`/admin/practitioners/${id}`);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied to clipboard');
  };

  const cols = [
    {
      field: 'id',
      headerName: 'ID',
      maxWidth: 150,
      cellRenderer: (params) => (
        <div onClick={() => navigateToVistProfile(params.value)} style={{ cursor: 'pointer' }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'fullName',
      headerName: 'Name',
      cellRenderer: (params) => (
        <div onClick={() => navigateToVistProfile(params.data.id)} style={{ cursor: 'pointer' }}>
          {params.value}
        </div>
      )
    },
    {
      field: 'email',
      headerName: 'E-mail',
      cellRenderer: (params) => {
        const { value } = params;
        const handleCellClick = () => {
          copyToClipboard(value);
        };
        return (
          <div onClick={handleCellClick} style={{ cursor: 'pointer' }}>
            {value}
          </div>
        );
      }
    },
    { field: 'role', headerName: 'Role', valueFormatter: ({ value }) => capitalize(value) },
    {
      field: 'created_at',
      headerName: 'Invited',
      valueFormatter: ({ value }) => moment(value).format('MM/DD/YYYY HH:mm.ss')
    },
    { field: 'color', headerName: 'Color', cellRenderer: ColorCircle },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: (params) => <Actions index={params.rowIndex} data={params.data} />,
      maxWidth: 100,
      minWidth: 36,
      resizable: false
    }
  ];

  return (
    <Allowed requiredPermissions="member.read" showMessage showIllustration>
      <>
        <TableContextProvider name="practice_staff" pagination cols={cols}>
          <StaffTable
            selectedStaff={selectedStaff}
            show={show}
            setShow={setShow}
            deletePracticeStaffModal={deletePracticeStaffModal}
            setDeletePracticeStaffModal={setDeletePracticeStaffModal}
          />
        </TableContextProvider>

        <Modal
          slideFromRight
          handleOpen={show.newMember}
          handleClose={() => setShow({ ...show, newMember: false })}
          footer={
            <div className="flex w-full  justify-between">
              <Button
                data-qa="cancel-btn"
                type="primary"
                color="neutral"
                outlined={true}
                text="Cancel"
                onClick={() => setShow({ ...show, newMember: false })}
              />
              <Button
                data-qa="save-btn"
                type="primary"
                text="Save"
                loading={isLoading}
                onClick={() => submitStaffRef?.current?.submitForm()}
              />
            </div>
          }
          title="Personal information">
          <AddStaffContainer
            practice_id={practice.id}
            practice={practice}
            submitStaffRef={submitStaffRef}
            handleSuccess={() => setShow({ ...show, newMember: false })}
            handleClose={() => setShow({ ...show, newMember: false })}
            role_list={practice.roles}
          />
        </Modal>
        <Confirm
          variant="primary"
          primaryBtnTxt="Confirm"
          title="Achtung!"
          icon="new-eye"
          message="You're about to impersonate a provider. Your permissions are limited per HIPAA compliance and activity is logged."
          handleContinue={() => impersonateUser()}
          handleOpen={show?.confirmImpersonate}
          handleClose={() => setShow({ ...show, confirmImpersonate: false })}
        />
        {!!show.staffEdit && (
          <StaffMember
            practiceId={practice.id}
            member={{ ...selectedStaff, user_id: selectedStaff.id }}
            handleOpen={!!show.staffEdit}
            onClose={() => setShow({ ...show, staffEdit: false })}
            setSelectedStaff={setSelectedStaff}
          />
        )}
        {!!show.supervisorSettings && (
          <SupervisorSettings
            member={{
              ...memberToSupervisor,
              practice_id: id,
              user_id: memberToSupervisor.id
            }}
            showModal={!!show.supervisorSettings}
            hideModal={() => setShow({ ...show, supervisorSettings: false })}
          />
        )}
      </>
    </Allowed>
  );
}
