import {
  getFormCount,
  getOptionsCounts
} from 'components/practice/charts/ClinicalNote/Sidebar/PreviousFormResponses/lib/previousFormHelpers';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const Counter = ({ count }) => {
  if (isNaN(Number(count))) return null;

  const content = count > 99 ? '99+' : count;

  return (
    <div className="flex h-[26px] w-[26px] items-center justify-center rounded-md border border-solid border-primary-200 bg-primary-50 text-xs font-medium text-primary-600">
      {content}
    </div>
  );
};

let options = [
  {
    icon: 'new-user-2',
    value: 'patient',
    label: 'View patient submissions',
    count: 0,
    component: <Counter count={0} />,
    onClick() {}
  },
  {
    icon: 'user-square',
    label: 'View provider submissions',
    value: 'provider',
    component: <Counter count={0} />,
    count: 0,
    onClick() {}
  }
];

const PreviousFormDropdown = () => {
  const { setActivePreviousFormResponses, overviewData, setPatientResponseSidebar, components } =
    useClinicalNoteContext();

  const params = useParams();

  options = useMemo(() => {
    const formKey = params?.formName || Object.values(components)?.[0]?.path;
    let data = getFormCount(formKey, options, overviewData);

    data = data.map((opt) => {
      opt.component = <Counter count={opt.count} />;
      opt.onClick = () => {
        setPatientResponseSidebar((prev) => ({
          ...prev,
          open: true,
          view: 'previous_form_responses'
        }));

        setActivePreviousFormResponses({
          option: opt.value,
          form: formKey
        });
      };
      return opt;
    });
    return data;
  }, [overviewData]);

  return (
    <Popover
      text="Previously filled form"
      icon="new-user-arrow-right"
      options={options}
      optionIconSize={18}
      isDropdown
    />
  );
};

export default PreviousFormDropdown;
