import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const RiskCoMorbidities = ({ customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }

  if (customClinicalNote && !clinicalNote?.mha?.riskAndCoMorbidities) {
    return null;
  }

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <SanitizeComponent
        value={clinicalNote?.mha?.riskAndCoMorbidities}
        title="Risk and co-Morbidities"
      />
    </div>
  );
};

export default RiskCoMorbidities;
