import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import React from 'react';
import { withErrorBoundary } from '../../../Error/Boundary';
import Icon from '../../../Icon/Icon';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';
import _ from 'lodash';
import { useTagContext } from 'lib/context/TagContext/TagContext';
import { tagColorOptions } from '../../lib/tagColorOptions';
import OrderedView from './OrderedView';

const TagsView = ({
  currTags,
  kind,
  itemsToShow,
  label = 'Tags',
  className,
  appendTo = 'parent',
  handleRemove = () => {},
  fromTable = false,
  fromPatientChart = false,
  fromFaxes = false,
  disableDeviceCheck = false,
  fromAppointment = false
}) => {
  const { formattedTags } = useTagContext() || [];
  const { data: loadedTags } = useTags({ params: { kind }, dependencies: [kind] });
  const shouldUseFormattedTags = !fromFaxes && (fromTable || ia(formattedTags));
  const currentTags = currTags
    ? _.orderBy(currTags, (tag) => tag?.important, 'desc')
    : loadedTags?.tags;
  const tags = shouldUseFormattedTags ? formattedTags : currentTags;
  const { device } = useUIContext();
  const showAllTags = disableDeviceCheck ? itemsToShow : device === 'desktop' ? itemsToShow : 0;

  const tagContent = (tag, currBorderColor, isNew) => {
    return (
      <div
        className="flex max-w-max items-center gap-[6px] truncate rounded px-2 py-[2px]"
        style={{
          backgroundColor: tag?.color,
          border: `1px solid ${currBorderColor || 'transparent'}`
        }}>
        {tag?.important && <Icon icon="new-info-circle" color="neutral" shade={900} />}
        <span
          className={cs(
            'select-none overflow-hidden text-ellipsis text-sm font-400 leading-5',
            isNew ? 'text-neutral-900' : 'text-white'
          )}>
          {tag?.name}
        </span>
        {!fromAppointment && (
          <Icon
            icon="new-close-sm"
            size={8}
            color="black"
            stroke
            onClick={() => handleRemove(tag)}
          />
        )}
      </div>
    );
  };

  return (
    <div className={cs('overflow-hidden', className)}>
      {label && <label className="mb-1">{label}</label>}
      <div className="item-center flex gap-2">
        {device === 'desktop' || (device !== 'mobile' && !fromPatientChart) ? (
          <div
            className="grid items-center gap-2"
            style={{ gridTemplateColumns: `repeat(${itemsToShow},minmax(0,auto))` }}>
            {tags?.slice(0, itemsToShow).map((tag, index) => {
              const isNew = tagColorOptions.find((option) => option.color === tag?.color);
              let currBorderColor = tagColorOptions.find(
                (option) => option.color === tag?.color
              )?.border;
              return fromTable ? (
                <Tippy content={tag?.name} className="tippy-dark text-base" key={index}>
                  <div>{tagContent(tag, currBorderColor, isNew)}</div>
                </Tippy>
              ) : (
                <>{tagContent(tag, currBorderColor, isNew)}</>
              );
            })}
          </div>
        ) : device === 'mobile' && !fromPatientChart && !fromTable ? (
          <Tippy
            appendTo={appendTo}
            className="custom-tooltip !max-w-[150px] !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
            content={
              <div className="flex flex-col gap-[10px] overflow-y-auto overflow-x-hidden truncate !pr-2">
                {tags?.map((tag, index) => {
                  const isNew = tagColorOptions.find((option) => option.color === tag?.color);
                  let currBorderColor = tagColorOptions.find(
                    (option) => option.color === tag?.color
                  )?.border;
                  return (
                    <Tippy content={tag?.name} className="tippy-dark text-base" key={index}>
                      <div>{tagContent(tag, currBorderColor, isNew)}</div>
                    </Tippy>
                  );
                })}
              </div>
            }
            placement={fromTable ? 'bottom' : 'right'}
            interactive>
            <span className="select-none rounded border border-solid border-primary-200 bg-primary-100 px-2 py-[1px] text-xs font-500 leading-5 text-neutral-900">
              {`+${tags?.length}`}
            </span>
          </Tippy>
        ) : null}
        {tags?.length > showAllTags ? (
          fromPatientChart ? (
            <Tippy
              appendTo={appendTo}
              className="custom-tooltip !max-w-[360px] !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
              content={<OrderedView handleRemove={handleRemove} showAllTags={showAllTags} />}
              interactive>
              <span className="flex select-none items-center rounded border border-solid border-primary-200 bg-primary-100 px-2 py-[1px] text-xs font-500 leading-5 text-neutral-900">
                {device !== 'desktop'
                  ? `${tags?.length} tags`
                  : tags?.length - itemsToShow > 0 && `+${tags?.length - itemsToShow}`}
              </span>
            </Tippy>
          ) : (
            <Tippy
              appendTo={appendTo}
              className="custom-tooltip !max-w-[150px] !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
              content={
                <div className="flex flex-col gap-[10px] overflow-y-auto overflow-x-hidden truncate !pr-2">
                  {tags?.slice(showAllTags).map((tag, index) => {
                    const isNew = tagColorOptions.find((option) => option.color === tag?.color);
                    let currBorderColor = tagColorOptions.find(
                      (option) => option.color === tag?.color
                    )?.border;
                    return tag?.name?.length > 10 ? (
                      <Tippy content={tag?.name} className="tippy-dark text-base" key={index}>
                        <div>{tagContent(tag, currBorderColor, isNew)}</div>
                      </Tippy>
                    ) : (
                      <>{tagContent(tag, currBorderColor, isNew)}</>
                    );
                  })}
                </div>
              }
              placement={fromTable ? 'bottom' : 'right'}
              interactive>
              <span className="flex select-none items-center rounded border border-solid border-primary-200 bg-primary-100 px-2 py-[1px] text-xs font-500 leading-5 text-neutral-900">
                {`+${tags?.length - showAllTags}`}
              </span>
            </Tippy>
          )
        ) : null}
      </div>
    </div>
  );
};

export default withErrorBoundary(TagsView);
