import React from 'react';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';
import { userState } from 'components/state';
import headerPattern from '../../../../assets/Patterns/header-pattern.svg';
import cs from 'classnames';

const Header = () => {
  const currentUser = useRecoilValue(userState);
  const practice = useRecoilValue(currentPractice);
  const currentTime = moment().tz(practice.timezone);
  let greeting;

  if (currentTime.isBefore(moment('12:00 PM', 'h:mm A'))) {
    greeting = 'Good Morning';
  } else if (currentTime.isBefore(moment('6:00 PM', 'h:mm A'))) {
    greeting = 'Good Afternoon';
  } else {
    greeting = 'Good Evening';
  }

  return (
    <div className="relative h-[146px] p-[18px] pl-6">
      <img src={headerPattern} className="absolute left-0 top-0 h-full w-full object-cover" />
      <div className="relative z-10 pt-2 sm:pt-2 md:pt-0">
        <h3 className="text-2xl font-500 leading-8 text-white sm:text-xl md:text-xl">{`${greeting} ${currentUser?.f_name} ${currentUser?.l_name}`}</h3>
        <p
          className={cs(
            'pt-1 text-sm font-400 text-white sm:w-2/5 sm:pt-3 md:w-2/5',
            window.innerWidth < 768 && 'w-full'
          )}>
          We're delighted to have your expertise here in the clinic today.
        </p>
      </div>
    </div>
  );
};

export default Header;
