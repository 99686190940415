import * as Yup from 'yup';

export const TerminalValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  pin: Yup.string().required('PIN is required'),
  merchant_id: Yup.string().required('Merchant ID is required'),
  vendor_id: Yup.string().required('Vendor ID is required'),
  user_id: Yup.string().required('User ID is required'),
  brand: Yup.string().required('Brand is required'),
  kind: Yup.string().required('Kind is required')
});
