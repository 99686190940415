import React from 'react';

import { useContacts } from 'lib/hooks/queries/contacts/useContacts';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ContactModal from './components/Modal';
import { defaultColumns, defaultFilters } from './lib/helpers';

const ContactsPage = () => {
  return (
    <AgTablePage
      ModalComponent={ContactModal}
      useData={useContacts}
      name="contacts"
      defaultColumns={defaultColumns}
      defaultFilters={defaultFilters}
      queryParams={{ withCount: true }}
    />
  );
};

export default ContactsPage;
