import { useImperativeHandle, useRef } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { pm } from 'lib/helpers/utility';

import Textarea from 'components/shared/Textarea/Textarea';
import state, { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

import EnhancementStatus from './TypeNotes/SOAPNote/components/EnhancementStatus';

const GeneralNote = ({ sectionRef = null, showDisclaimer = true }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const permissions = useRecoilValue(state.permissions);
  const isPermission = pm(
    permissions,
    ['myscribe.narrative_update', 'myscribe.narrative_read'],
    'or'
  );

  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const generalNoteRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { notes: clinicalNote?.notes }
  }));

  const handleChange = (e) => {
    const value = e.target?.value;

    setClinicalNote({
      ...clinicalNote,
      notes: value ?? e
    });
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write general notes here"
      id="notes"
      data-qa="general-notes"
      name="notes"
      className="min-h-[200px]"
      value={clinicalNote?.notes}
      onChange={(e) => handleChange(e)}
      transcribing
      forwardedRef={generalNoteRef}
      disabled={clinicalNote?.locked || intelligenceAi.loading}
      info={
        isPermission && showDisclaimer && 'AI can make mistakes, please confirm the information!'
      }
      infoHighlights={['AI', 'mistakes']}
    />
  );
};

export default GeneralNote;
