import { yesAndNoOptions } from 'constants';
import { isObject } from 'lodash';

export const getNdcMeasureOptions = () => {
  return [
    {
      value: 'f2',
      label: 'INTERNATIONAL UNIT (F2)'
    },
    {
      value: 'me',
      label: 'MILLIGRAM (ME)'
    },
    {
      value: 'gr',
      label: 'GRAM (GR)'
    },
    {
      value: 'ml',
      label: 'MILLILITER (ML)'
    },
    {
      value: 'un',
      label: 'UNIT (UN)'
    }
  ];
};
export const getLabelForMeasureValue = (value) => {
  const options = getNdcMeasureOptions();
  const option = options.find((option) => option.value === value?.toLowerCase());
  return option ? option.label : '';
};

export const getYesAndNoLabel = (value) => {
  const option = yesAndNoOptions.find(
    (option) =>
      option?.value?.toLowerCase() === value?.toLowerCase() ||
      option?.label?.toLowerCase() === value?.toLowerCase()
  );
  return isObject(option) ? option : null;
};

export const getLabelFromOptions = (options, value, defaultValue) => {
  if (!value) return null;

  const option = options.find(
    (item) =>
      item?.value?.toLowerCase() === value?.toLowerCase() ||
      item?.label?.toLowerCase() === value?.toLowerCase()
  );

  const defaultOption = options.find(
    (item) => item?.value?.toLowerCase() === defaultValue?.toLowerCase()
  );

  return isObject(option) ? option : defaultValue ? defaultOption : null;
};

export const refTypeOptions = [
  {
    value: 'DN',
    label: 'Referring Provider'
  },
  {
    value: 'DK',
    label: 'Ordering Provider'
  },
  {
    value: 'DQ',
    label: 'Supervising Provider'
  }
];
