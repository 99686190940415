import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { useFormikContext } from 'formik';
import { formatDate } from 'lib/helpers/utility';
import { isEmpty } from 'lodash';

import React, { useState } from 'react';
import ClaimReasonModal from './components/ClaimReasonModal';

const Claim = ({ data, setBillingModalState }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const { values } = useFormikContext();

  const handleOpenClaim = () => {
    setBillingModalState({
      open: true,
      selectedTab: 'Claims',
      id: data.claimId
    });
  };

  const handleOpenReasonModal = () => setModalVisible(true);

  const claimIncomplete = values.timeline?.billing?.claim.color === 'danger';

  return (
    <Allowed requiredPermissions="billing.read">
      {!data?.claimId ? (
        <p className="text-sm  text-neutral-800">
          The claim will be created once the superbill is successfully scrubbed.
        </p>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <p className="text-sm font-500 text-neutral-800">Prior Auth Needed </p>
            <p className="text-sm  text-neutral-800">{data?.priorAuthNeeded ? 'Yes' : 'No'}</p>
          </div>
          {data?.priorAuthNeeded && !isEmpty(data?.priorAuthData) && (
            <>
              <div className="flex items-center justify-between">
                <p className="text-sm font-500 text-neutral-800">Prior Auth Approved </p>
                <p className="text-sm  text-neutral-800">
                  {data?.priorAuthData.data.status === 'approved' ? 'Yes' : 'No'}
                </p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-500 text-neutral-800">Prior Auth Number </p>
                <p className="text-sm  text-neutral-800">{data?.priorAuthData.data.number}</p>
              </div>
              <div className="flex items-center justify-between">
                <p className="text-sm font-500 text-neutral-800">Prior Auth Date </p>
                <p className="text-sm  text-neutral-800">
                  {formatDate(data?.priorAuthData.created_at)}
                </p>
              </div>
            </>
          )}
          <div className="flex items-center justify-between">
            <p className="text-sm font-500 text-neutral-800">Claim#{data.claimId}</p>
            <div className="flex cursor-pointer items-center gap-2" onClick={handleOpenClaim}>
              <p className="text-sm  text-primary-600">View</p>
              <Icon icon="new-document-preview" color="primary" />
            </div>{' '}
          </div>
          {claimIncomplete && (
            <div className="flex items-center justify-between">
              <p className="text-sm font-500 text-neutral-800">Reason</p>
              <div
                className="flex cursor-pointer items-center gap-2"
                onClick={handleOpenReasonModal}>
                <p className="text-sm  text-primary-600">View</p>
                <Icon icon="new-document-preview" color="primary" />
              </div>
            </div>
          )}
        </div>
      )}
      {modalVisible && (
        <ClaimReasonModal
          claimId={data.claimId}
          modalVisible={modalVisible}
          setModalUnVisible={() => setModalVisible(false)}
        />
      )}
    </Allowed>
  );
};

export default Claim;
