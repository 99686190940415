export default function (weekday, weekdays, setFieldValue) {
  if (weekdays.map((item) => item.value).includes(weekday.value)) {
    setFieldValue(
      'weekdays',
      weekdays.filter((w) => w.value !== weekday.value)
    );
    return;
  }
  setFieldValue('weekdays', [...weekdays, weekday]);
}
