import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';

const Preview = ({ setPreviewMode }) => {
  const { formik } = useFormBuilderContext();
  return (
    <>
      <div className="!m-[10px] !mb-0 flex h-[62px] items-center justify-between rounded-[16px] bg-primary-500 p-4">
        <div className="flex gap-2">
          <Icon icon="new-eye" color="white" />
          <p className="text-sm font-500 text-white">Preview Mode</p>
        </div>
        <Button
          text="back to edit"
          type="secondary"
          outlined
          onClick={() => setPreviewMode(false)}
        />
      </div>
      <div className="relative p-4">
        <div className="cursor-not-allowed content-[''] before:absolute before:top-0 before:z-10 before:h-full before:w-full before:opacity-30">
          <ReactFormGenerator
            data={formik?.values?.form?.json?.fields || []}
            submitButton={<div />}
            // hide_actions
            read_only
          />
        </div>
      </div>
    </>
  );
};

export default Preview;
