import React, { useState } from 'react';
import * as Yup from 'yup';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useFormik } from 'formik';
import { createTag } from 'api/Tags';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tagColorOptions } from '../lib/tagColorOptions';
import Icon from '../../Icon/Icon';
import Input from '../../Input/Input';
import Modal from '../../Modal/Modal';
import Button from '../../Buttons/Button';
import Checkbox from '../../Checkbox/Checkbox';
import Transition from '../../Transition/Transition';
import RadioButton from '../../RadioButton/RadioButton';
import { withErrorBoundary } from '../../Error/Boundary';
import { AlertContent, showAlert } from '../../Alert/Alert';

const TagCreate = ({ isOpen, handleClose, name, kind, addNewTagFromCreated }) => {
  const [selectedColor, setSelectedColor] = useState(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      kind,
      name,
      isPublic: true,
      color: '',
      isImportant: false
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Tag name is required'),
      color: Yup.string().required('Tag color is required')
    }),
    onSubmit: async (values) =>
      await mutateCreateTag(
        { ...values },
        {
          onSuccess: ({ code, tag }) => {
            if (code === 0) {
              queryClient.invalidateQueries(['tags', kind]);
              showAlert({ title: 'Tag created successfully!', color: 'success' });
              addNewTagFromCreated({ ...tag, label: tag.name, value: tag.name });
              handleClose();
            } else {
              showAlert({ message: 'Tag creation failed!', color: 'danger' });
            }
          }
        }
      )
  });

  const { mutateAsync: mutateCreateTag, isLoading } = useMutation({
    mutationFn: (data) => createTag(navigate, data)
  });

  const handleChange = (key, value) => formik.setFieldValue(key, value);

  const handleSelectColor = (color) => {
    setSelectedColor(color);
    formik.setFieldValue('color', color);
  };

  const handleCreate = () => formik.handleSubmit();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      disableHeader
      className="min-w-[480px] !bg-white"
      bodyClassName="!bg-white px-4"
      footerClassName="!py-2 !px-4"
      footer={
        <div className="flex w-full items-center justify-between bg-white">
          <Button text="Close" color="neutral" outlined onClick={handleClose} size="small" />
          <Button
            text="Create"
            onClick={handleCreate}
            size="small"
            loading={isLoading}
            data-qa="tag-create-btn"
          />
        </div>
      }>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-[6px]">
          <Icon icon="new-tag" size={24} color="primary" data-qa="new-tags-btn" />
          <span className="text-lg font-500 leading-7 text-primary-900">Create tag</span>
        </div>
        <div className="flex gap-4">
          <Input
            label="Name"
            className="max-w-[284px] grow"
            value={formik.values.name}
            name="name"
            id="name"
            data-qa="tag-name"
            onChange={(event) => handleChange('name', event.target.value)}
            error={formik.errors.name}
          />
          <div className="flex flex-col gap-4">
            <span className="text-sm font-500 leading-[22px] text-neutral-800">Privacy</span>
            <div className="flex items-center gap-3">
              <RadioButton
                label="Public"
                name="public"
                data-qa="tag-radio-public"
                id="public"
                value={formik.values.isPublic}
                isSelected={formik.values.isPublic}
                onChange={() => handleChange('isPublic', true)}
              />
              <RadioButton
                label="Private"
                name="private"
                id="private"
                data-qa="tag-radio-private"
                value={!formik.values.isPublic}
                isSelected={!formik.values.isPublic}
                onChange={() => handleChange('isPublic', false)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 !pb-1">
          <span className="text-sm font-500 leading-[22px] text-neutral-800">Color</span>
          <div className="flex max-w-[448px] flex-wrap gap-x-[14px] gap-y-3">
            {tagColorOptions.map((option, i) => (
              <div
                data-qa={`tag-color-${i}`}
                key={i}
                className="relative z-10 flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full"
                style={{
                  backgroundColor: option.color,
                  border: `1px solid ${option.border}`
                }}
                onClick={() => handleSelectColor(option.color)}>
                <Transition isOpen={selectedColor === option.color} type="scale">
                  <div>
                    <Icon icon="checkmark" color="white" size={18} className="mt-[3px]" />
                    <div
                      className="absolute left-[-3px] top-[-3px] -z-10 h-[calc(100%+6px)] w-[calc(100%+6px)] rounded-full opacity-30"
                      style={{
                        backgroundColor: option.color
                      }}
                    />
                  </div>
                </Transition>
              </div>
            ))}
          </div>
          {formik.errors.color && <p className="text-danger-500">{formik.errors.color}</p>}
        </div>
        <div className="flex flex-col gap-2 pb-1">
          <span className="text-sm font-500 leading-[22px] text-neutral-800">Importance</span>
          <div className="flex gap-2">
            <Checkbox
              label="Mark as important"
              isChecked={formik.values.isImportant}
              onChange={() => handleChange('isImportant', !formik.values.isImportant)}></Checkbox>
            <Tippy
              content="Use this to communicate urgency of this tag to your team."
              className="tippy-dark"
              placement="right">
              <div className="flex items-center ">
                <Icon icon="new-info-circle" />
              </div>
            </Tippy>
          </div>
        </div>
        <div className="overflow-hidden rounded-md border border-solid !border-primary-100">
          <AlertContent
            className="!w-full !border-0"
            icon="new-info"
            iconShade={200}
            title={
              formik.values.isPublic === true
                ? 'Public tags can be viewed by anyone.'
                : 'Private tags are only viewable and editable by you.'
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default withErrorBoundary(TagCreate);
