import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { addDays } from 'date-fns';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { ia } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Buttons/Button';
import RadioButton from '../../../shared/RadioButton/RadioButton';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import schedulingState from '../schedulingState';
import './css/DateAndTimeStep.scss';

export default function DateAndTimeStep() {
  const [checked, setChecked] = useState(false);
  const [practitioners, setPractitioners] = useState([]);
  const navigate = useNavigate();
  const [appointmentData, setAppointmentData] = useRecoilState(schedulingState.appointmentData);
  const [availableTimes, setAvailableTimes] = useState([]);
  const [times, setTimes] = useState([]);
  const [date, setDate] = useState(new Date());
  const [slotTime, setSlotTime] = useState();
  const [availablePractitioners, setAvailablePractitioners] = useState([
    { in_person: [], virtual: [] }
  ]);
  const [allDates, setAllDates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingPractitioners, setLoadingPractitioners] = useState(true);
  const [timeSelected, setTimeSelected] = useState(true);
  const [appointmentLocation, setAppointmentLocation] = useState('in_person');

  moment.tz.setDefault(appointmentData?.practice_timezone);
  useEffect(() => {
    getPractitioners();
  }, [appointmentData.practice_id]);

  useEffect(() => {
    getAllDateAvailabilityForPractitioners(new Date());
  }, [practitioners]);

  useEffect(() => {
    findAvailableTimes(date);
  }, [availableTimes]);

  useEffect(() => {
    getAppointments();
  }, [availablePractitioners]);

  const getAvailability = async (id, dateTime) => {
    try {
      if (!id) {
        return;
      }

      const { data } = await interimApi(
        '/api/calendar/availability',
        {
          practitioner_id: id,
          dateTime: moment(dateTime).add(12, 'h')
        },
        navigate
      );
      if (data?.slotsAvailable) {
        return data.slotsAvailable;
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getAllAvailabilityForPractitioners = async (date, time) => {
    try {
      if (!ia(allDates)) return;
      const locationAvailablePractitionerIds = [];
      const virtualAvailablePractitionerIds = [];

      for (const date of allDates) {
        if (date.availableTime.location.includes(time)) {
          locationAvailablePractitionerIds.push(date.practitioner);
        }
        if (date.availableTime.virtual.includes(time)) {
          virtualAvailablePractitionerIds.push(date.practitioner);
        }
      }

      setAvailablePractitioners({
        in_person: locationAvailablePractitionerIds,
        virtual: virtualAvailablePractitionerIds
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getAllDateAvailabilityForPractitioners = async (date) => {
    try {
      setLoading(true);

      const practitionerIds = practitioners.map((practitioner) => practitioner.user_id);

      const allAvailableDates = (
        await Promise.all(
          practitionerIds.map(async (id) => {
            const result = await getAvailability(id, date);
            return result ? { availableTime: result, practitioner: id } : null;
          })
        )
      ).filter((result) => result !== null);

      setAllDates(allAvailableDates);

      if (ia(allAvailableDates)) {
        ia(availablePractitioners) && setAvailablePractitioners([]);

        const newResults = { location: [], virtual: [] };
        for (const allAvailableDate of allAvailableDates) {
          newResults.location = [
            ...new Set([...newResults.location, ...allAvailableDate.availableTime.location])
          ];
          newResults.virtual = [
            ...new Set([...newResults.virtual, ...allAvailableDate.availableTime.virtual])
          ];
        }
        newResults.location.sort((a, b) => {
          const timeA = new Date(`2000/01/01 ${a}`).getTime();
          const timeB = new Date(`2000/01/01 ${b}`).getTime();
          return timeA - timeB;
        });

        newResults.virtual.sort((a, b) => {
          const timeA = new Date(`2000/01/01 ${a}`).getTime();
          const timeB = new Date(`2000/01/01 ${b}`).getTime();
          return timeA - timeB;
        });
        setAvailableTimes(newResults);
      }
      setLoading(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };
  const selectPractitioner = (p, type) => {
    setChecked(p);
    if (checked && p.user_id == checked.user_id && type == 'button') {
      setChecked(false);
    }
  };

  const findPractitionerCheckedData = (checked) => {
    return practitioners?.find((practitioner) => practitioner.user_id == checked);
  };

  const getStatus = async () => {
    try {
      const savedData = {
        practitioner_userId: checked.user_id,
        practitioner_name: `${checked.f_name} ${checked.l_name}`,
        starts_at: slotTime,
        ends_at: moment
          .tz(slotTime, appointmentData.practice_timezone)
          .add(appointmentData.service_length, 'm')
          .format(),
        appointmentLocation
      };

      const { status, data } = await interimApi(
        '/api/patient/appointment/self_schedule',
        savedData,
        navigate
      );
      setAppointmentData(data?.object || {});
      setTimeout(() => {
        if (status == 200) {
          navigate('/book/contact-info');
        }
      }, 1500);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getPractitioners = async () => {
    setLoadingPractitioners(true);
    try {
      const {
        data: { members }
      } = await interimApi(
        '/api/practice/member/get',
        {
          practiceId: appointmentData.practice_id,
          serviceId: appointmentData.service_id
        },
        navigate
      );
      if (members) {
        setPractitioners(members);
      }
      setLoadingPractitioners(false);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getAppointments = async () => {
    try {
      const res = await interimApi(
        '/api/appointment/get_without_auth',
        {
          timeSlot: slotTime,
          practitioners: new Set([
            ...availablePractitioners.in_person,
            ...availablePractitioners.virtual
          ])
        },
        navigate
      );
      if (res.data.chosenPractitioner) {
        const checkedPractitionerData = findPractitionerCheckedData(res.data.chosenPractitioner);
        setChecked(checkedPractitionerData);
      } else {
        setChecked(null);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: self_scheduling/steps/DateAndTimeStep, method: getAppointments - catch, error: ${error ?? 'Theres been an error'
        }`
      );
      console.error(error);
    }
  };

  const findAvailableTimes = (timeValue) => {
    let result = Object.entries(availableTimes);
    result = result.at(0);

    result ? setTimes({ date: result[0], times: result[1] }) : setTimes([]);
    setLoading(false);
  };
  const onTimeClick = ({ times, time, idx, type }) => {
    const slicedTime = moment(
      moment.tz(date, appointmentData?.practice_timezone).format('M/D/YYYY') + ' ' + time,
      'M/D/YYYY h:mm A z'
    );
    setAppointmentLocation(type);
    setTimeSelected(idx);
    getAllAvailabilityForPractitioners(times.date, time);
    setSlotTime(slicedTime);
  };

  return (
    <>
      <div className="w-full">
        <div className="DateTime w-full">
          <div className="DateTime__block">
            <h6>Available Date & Time</h6>
            <Calendar
              onChange={(value) => {
                setDate(value);
                getAllDateAvailabilityForPractitioners(value);
                setTimeSelected(null);
              }}
              date={date}
              minDate={new Date()}
              maxDate={addDays(new Date(), +44)}
            />
            <div className={`mt-3 ${!ia(availableTimes.location) && 'hidden'}`}>
              Select from available time slots for location
            </div>
            <div className="Times">
              {!loading && ia(availableTimes.location) ? (
                availableTimes.location.map((time, idx) => {
                  return (
                    <div
                      key={idx}
                      className={cs(
                        'Times__item',
                        timeSelected === idx &&
                        appointmentLocation === 'in_person' &&
                        'Times__item--active'
                      )}
                      onClick={() => onTimeClick({ times, time, idx, type: 'in_person' })}>
                      {time}
                    </div>
                  );
                })
              ) : loading ? (
                <div className="Times">
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                </div>
              ) : (
                <div className="mt-20 w-max">
                  No location time slots are available for this date.
                </div>
              )}
            </div>
            <div className={`mt-3 ${!ia(availableTimes.virtual) && 'hidden'}`}>
              Select from available time slots for virtual
            </div>
            <div className="Times">
              {!loading && ia(availableTimes.virtual) ? (
                availableTimes.virtual.map((time, idx) => {
                  return (
                    <div
                      key={idx}
                      className={cs(
                        'Times__item',
                        timeSelected === idx &&
                        appointmentLocation === 'virtual' &&
                        'Times__item--active'
                      )}
                      onClick={() => onTimeClick({ times, time, idx, type: 'virtual' })}>
                      {time}
                    </div>
                  );
                })
              ) : loading ? (
                <div className="Times">
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                  <div className="Times__item">
                    <Skeleton count={3} height={40} />
                  </div>
                </div>
              ) : (
                <div className="mt-20 w-max">
                  No virtual time slots are available for this date.
                </div>
              )}
            </div>
          </div>
          <div className="DateTime__block">
            <h6>Practitioners</h6>
            <div className="Practitioners">
              {ia(practitioners) ? (
                practitioners?.map((practitioner, idx) => {
                  return (
                    <div
                      key={idx}
                      className="Practitioners__item"
                      style={{
                        opacity: `${!availablePractitioners[appointmentLocation]?.includes(
                          practitioner.user_id
                        )
                          ? 0.5
                          : 1
                          }`
                      }}>
                      <span className="Practitioners__item__name">
                        {practitioner?.f_name} {practitioner?.l_name}
                      </span>
                      <RadioButton
                        wrapperClassName='w-max'
                        id={practitioner.user_id}
                        disabled={
                          !availablePractitioners[appointmentLocation]?.includes(
                            practitioner.user_id
                          )
                        }
                        name={practitioner.name}
                        isSelected={checked ? checked.user_id == practitioner.user_id : false}
                        onChange={() => selectPractitioner(practitioner, 'button')}
                      />
                    </div>
                  );
                })
              ) : loadingPractitioners ? (
                <Skeleton count={3} height={80} width="100%" />
              ) : (
                <div className="mt-20 w-max">No practitioners available for this service</div>
              )}
            </div>
          </div>
        </div>
        <Button
          // className="ContinueButton"
          outlined
          color="primary"
          disabled={!checked}
          onClick={() => checked && getStatus()}
          text="Continue"
        />
      </div>
    </>
  );
}
