import React, { useState } from 'react';
import { useEffect } from 'react';
import Spinner from '../spinner/spinner';
import './Button.scss';
import cs from 'classnames';
import Icon from '../Icon/Icon';
import { id } from 'date-fns/locale';

const Button = (
  props = {
    id,
    children,
    icon,
    iconPositionEnd,
    black,
    primary,
    secondary,
    tertiary,
    gray,
    grayDark,
    grayLight,
    danger,
    success,
    green,
    red,
    warning,
    info,
    blue,
    blueLight,
    purple,
    outline,
    transparent,
    color,
    style,
    close,
    shade,
    className,
    small,
    forwardedRef,
    onClick: () => {
      console.warn(`onClick not handled when calling Button.jsx`);
    },
    handleResolve: (v) => {
      console.warn(`handleResolve not handled when calling Button.jsx`, v);
    },
    handleRejection: (v) => {
      console.warn(`handleRejection not handled when calling Button.jsx`, v);
    },
    loading: false,
    disabled: false,
    ...rest
  }
) => {
  const [buttonColor, setButtonColor] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    let propsKeys = Object.keys(props);
    let newButtonColor = '';
    for (let i = 0; i < propsKeys.length; i++) {
      if (
        [
          'neutral',
          'primary',
          'secondary',
          'tertiary',
          'gray',
          'grayDark',
          'grayLight',
          'danger',
          'success',
          'black',
          'red',
          'green',
          'warning',
          'info',
          'blue',
          'blueLight',
          'purple',
          'green',
          'white'
        ].includes(propsKeys[i])
      ) {
        newButtonColor = propsKeys[i];
      }
    }
    setButtonColor(newButtonColor);
  }, []);

  const handleOnClick = async (event) => {
    if (typeof onClick === 'function' && typeof onClick.then === 'function') {
      // if the prop onClick is a promise
      props
        .onClick(event)
        .then((v) => props.handleResolve(v))
        .catch((e) => props.handleRejection(e));
    } else {
      // default function call
      props.onClick(event);
    }
  };

  return (
    <button
      data-qa={`click-btn-${props.id}`}
      id={props.id}
      name={props.name}
      onClick={handleOnClick}
      ref={props.forwardedRef}
      type={props.type ?? undefined}
      className={cs(
        'Button',
        buttonColor && props.shade
          ? `Button--${buttonColor}-${props.shade}`
          : buttonColor && !props.outline
          ? `Button--${buttonColor}`
          : '',
        buttonColor && props.outline
          ? `bg-${buttonColor}-50 hover:!bg-${buttonColor}-100 !border border-solid border-${buttonColor}-500 text-${buttonColor}-500 hover:text-${buttonColor}-600 font-500 duration-200 ease-linear`
          : props.outline
          ? `Button--outline`
          : '',
        props.transparent && 'Button--transparent',
        props.tertiary && 'Button--tertiary',
        props.close && 'Button--close',
        props.className,
        props.small && 'Button--small'
      )}
      style={props.style}
      disabled={buttonLoading || props.disabled || props.loading}
      {...props}>
      {props.icon && !props.iconPositionEnd && <Icon icon={props.icon} />}
      {props.loading || buttonLoading ? (
        <Spinner className="Spinner" />
      ) : (
        <>
          {props.children}
          {props.icon && props.iconPositionEnd && <Icon icon={props.icon} />}
        </>
      )}
    </button>
  );
};

export default Button;
