import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from '../../../../../../../lib/helpers/utility';

const TreatmentFrequency = () => {
  const { selectedTreatmentPlan } = useClinicalNoteContext();

  if (!selectedTreatmentPlan.treatment_frequency_date) return null;

  return (
    <div className="grid gap-6">
      {io(selectedTreatmentPlan.treatment_frequency_date.frequency) && (
        <div className="grid gap-2">
          <h3 className="text-neutral-800 font-500 text-base">Treatment frequency</h3>
          <p className="text-sm text-neutral-800 first-letter:capitalize">
            {selectedTreatmentPlan.treatment_frequency_date.frequency?.label}
          </p>
        </div>
      )}
      {selectedTreatmentPlan.treatment_frequency_date.startDate && (
        <div className="grid gap-2">
          <h3 className="text-neutral-800 font-500 text-base">Starting date</h3>
          <p className="text-sm text-neutral-800 first-letter:capitalize">
            {selectedTreatmentPlan.treatment_frequency_date.startDate}
          </p>
        </div>
      )}
      {selectedTreatmentPlan.treatment_frequency_date.endDate && (
        <div className="grid gap-2">
          <h3 className="text-neutral-800 font-500 text-base">End date</h3>
          <p className="text-sm text-neutral-800 first-letter:capitalize">
            {selectedTreatmentPlan.treatment_frequency_date.endDate}
          </p>
        </div>
      )}
    </div>
  );
};

export default TreatmentFrequency;
