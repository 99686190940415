import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import React from 'react';
import { NavLink, useOutletContext, useParams } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../../shared/Icon/Icon';

const SelectNote = ({ openLSidebar }) => {
  const { type } = useParams();
  const { saveNoteRef, saveButtonText, loading: clinicalNoteLoading } = useClinicalNoteContext();
  const { enabledNotes } = useOutletContext();

  if (clinicalNoteLoading) {
    return <Skeleton />;
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <div>
          <Popover.Button className="w-full p-0" data-qa="clinical-notes-menu">
            <div
              className={cs(
                'flex items-center justify-between gap-x-[6px] !px-4 !py-[14px]',
                open ? 'bg-primary-700' : 'bg-primary-50'
              )}>
              {enabledNotes?.map((item, index) => {
                const activeLink =
                  type === item.path ? (!openLSidebar && type === '3d' ? '3D' : item.label) : '';
                if (activeLink)
                  return (
                    <span
                      className={cs(
                        'overflow-hidden text-ellipsis whitespace-nowrap text-base font-500 first-letter:capitalize',
                        open ? 'text-white' : 'text-primary-900'
                      )}
                      key={index}>
                      {activeLink}
                    </span>
                  );
              })}

              <Icon
                icon="new-chevron-down"
                color={open ? 'white' : 'primary'}
                shade={open ? '500' : '800'}
                size="20px"
                className={cs('cursor-pointer duration-200', open && 'rotate-180 ')}
              />
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute left-0 z-10 grid w-max min-w-full cursor-pointer gap-y-1 rounded-b-lg border !border-t-0 !border-primary-200 bg-primary-50 !py-2 shadow-medium">
            {enabledNotes?.map((item, index) => (
              <NavLink
                to={item?.path}
                className={({ isActive }) =>
                  `relative flex items-center gap-x-2 !py-2 !pl-8 !pr-4 transition-all hover:bg-primary-100 ${
                    isActive && 'bg-primary-100 font-500'
                  }`
                }
                key={index}
                onClick={() => {
                  close();

                  const cnSaving = saveButtonText === 'Saving';
                  if (clinicalNoteLoading || cnSaving) return;
                  saveNoteRef.current.onSaveNote();
                }}>
                {({ isActive }) => (
                  <>
                    <Icon
                      icon="new-check"
                      color="primary"
                      stroke
                      className={cs(
                        'absolute left-2 top-1/2 -translate-y-1/2',
                        isActive ? '' : 'hidden'
                      )}
                    />
                    <span
                      className={cs('select-none text-base text-primary-900')}
                      data-qa={`enable-note-${index}`}>
                      {item.label === '3D Charting' && !openLSidebar ? '3D' : item.label}
                    </span>
                  </>
                )}
              </NavLink>
            ))}
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default SelectNote;
