import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import cs from 'classnames';

import { useUIContext } from '../../../lib/context/UIContext/UIContext';
import { formatDateRelativeToToday, ia } from '../../../lib/helpers/utility';
import useRandomGradient from '../../../lib/hooks/useRandomGradient';
import Allowed from '../Permissions/Allowed';
import Spinner from '../spinner/spinner';

import MessageReceiverBox from './MessageBox/MessageReceiverBox';
import MessageSenderBox from './MessageBox/MessageSenderBox';
import MessageTextBox from './MessageBox/MessageTextBox';

const firstLeftMessageStyle = 'rounded-[25.5px_25.5px_25.5px_0px]';
const middleLeftMessageStyle = 'rounded-[0px_25.5px_25.5px_0px]';
const lastLeftMessageStyle = 'rounded-[0px_25.5px_25.5px_25.5px]';
const firstRightMessageStyle = 'rounded-[26px_26px_0px_26px]';
const middleRightMessageStyle = 'rounded-[26px_0px_0px_26px]';
const lastRightMessageStyle = 'rounded-[26px_0px_26px_26px]';

export default function Message({
  patient,
  groupedMessages,
  practice,
  loading,
  onChange,
  onClick,
  body,
  setPage,
  type = 'practice'
}) {
  const currentPatient = patient;
  const gradientColorPractice = useRandomGradient(practice.id);
  const gradientColorUser = useRandomGradient(currentPatient.id);
  const scrollableDivRef = useRef(null);
  const [canScrollToEnd, setCanScrollToEnd] = useState(true);
  const messagesEndRef = useRef(null);
  const { device } = useUIContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const messageId = searchParams.get('messageId');

  const relativeRef = useRef(null);
  const [currentHeight, setCurrentHeight] = useState(0);

  const scrollToLastMessage = () => {
    const message = document.getElementById(`message-${messageId}`);
    setSearchParams(searchParams.delete('messageId'));
    if (message) {
      setTimeout(() => {
        message?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        message.classList.add('rounded-lg', 'bg-primary-50');
        setTimeout(() => {
          message.classList.remove('rounded-lg', 'bg-primary-50');
        }, 3000);
      }, 500);
    } else if (ia(groupedMessages) && messagesEndRef.current && canScrollToEnd) {
      messagesEndRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
      setCanScrollToEnd(false);
    }
  };

  const onSendMessage = (e) => {
    onClick(e);
    setCanScrollToEnd(true);
  };

  const onScrollToTop = async () => {
    const container = scrollableDivRef.current;
    if (container.scrollTop === 0) {
      setPage((prevPage) => prevPage + 1);
      setCurrentHeight(relativeRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    scrollToLastMessage();
  }, [messagesEndRef.current]);

  useEffect(() => {
    const container = scrollableDivRef.current;
    container.addEventListener('scroll', onScrollToTop);
    return () => {
      container.removeEventListener('scroll', onScrollToTop);
    };
  }, []);

  useEffect(() => {
    const logsWrapperHeight = relativeRef.current.offsetHeight;
    scrollableDivRef.current.scrollTo(0, logsWrapperHeight - currentHeight);
  }, [groupedMessages]);

  return (
    <div className="flex w-full flex-grow flex-col overflow-hidden rounded-lg bg-white !p-3 !pr-3 shadow-xl">
      <div
        ref={scrollableDivRef}
        className={cs(
          'relative flex min-h-[200px] flex-grow flex-col overflow-auto p-4 !pr-3',
          device !== 'desktop' && 'h-[250px]'
        )}>
        {loading.general && (
          <div className="absolute left-1/2 top-8 z-10 -translate-x-1/2">
            <Spinner />
          </div>
        )}
        <div ref={relativeRef}>
          {ia(groupedMessages) &&
            groupedMessages.map((group, idxGroup) => {
              return (
                <div key={idxGroup}>
                  <div className="flex items-center py-4">
                    <div className="h-px flex-grow bg-neutral-100"></div>
                    <span className="flex-shrink !px-4 text-sm text-neutral-700">
                      {formatDateRelativeToToday(group.date, practice.timezone)}
                    </span>
                    <div className="h-px flex-grow bg-neutral-100"></div>
                  </div>
                  {ia(group.messageGroups) &&
                    group.messageGroups.map((groupMessage, idxMessage) => {
                      const message = groupMessage.message;
                      const isFirstMessageOfPerson = groupMessage?.isFirstMessageOfPerson || false;
                      const isLastMessageOfPerson = groupMessage?.isLastMessageOfPerson || false;
                      const shouldShowLabel = groupMessage?.shouldShowLabel;
                      if (message.sender_id !== currentPatient.id) {
                        const practiceProps = {
                          name: message?.user
                            ? `${message.user?.f_name} ${message.user?.l_name}`
                            : 'Myriad Health',
                          photo: practice.header_photo,
                          gradientColor: gradientColorPractice,
                          message,
                          timezone: practice.timezone,
                          form: isFirstMessageOfPerson
                            ? type === 'practice'
                              ? firstRightMessageStyle
                              : firstLeftMessageStyle
                            : isLastMessageOfPerson
                              ? type === 'practice'
                                ? lastRightMessageStyle
                                : lastLeftMessageStyle
                              : type === 'practice'
                                ? middleRightMessageStyle
                                : middleLeftMessageStyle,
                          shouldShowLabel
                        };
                        return (
                          <div
                            id={`message-${message.id}`}
                            key={idxMessage}
                            ref={
                              idxMessage === group.messageGroups.length - 1 ? messagesEndRef : null
                            }>
                            {type === 'practice' ? (
                              <MessageSenderBox {...practiceProps} />
                            ) : (
                              <MessageReceiverBox {...practiceProps} />
                            )}
                          </div>
                        );
                      } else {
                        const patientProps = {
                          name: `${currentPatient.f_name} ${currentPatient.l_name}`,
                          photo: currentPatient.profile_photo,
                          gradientColor: gradientColorUser,
                          message,
                          timezone: practice.timezone,
                          form: isFirstMessageOfPerson
                            ? type === 'patient'
                              ? firstRightMessageStyle
                              : firstLeftMessageStyle
                            : isLastMessageOfPerson
                              ? type === 'patient'
                                ? lastRightMessageStyle
                                : lastLeftMessageStyle
                              : type === 'patient'
                                ? middleRightMessageStyle
                                : middleLeftMessageStyle,
                          shouldShowLabel
                        };
                        return (
                          <div
                            id={`message-${message.id}`}
                            key={idxMessage}
                            ref={
                              idxMessage === group.messageGroups.length - 1 ? messagesEndRef : null
                            }>
                            {type === 'patient' ? (
                              <MessageSenderBox {...patientProps} />
                            ) : (
                              <MessageReceiverBox {...patientProps} />
                            )}
                          </div>
                        );
                      }
                    })}
                </div>
              );
            })}
        </div>
      </div>
      <Allowed requiredPermissions={type === 'practice' ? 'patient_communication.create' : false}>
        <MessageTextBox
          onChange={onChange}
          onClick={onSendMessage}
          body={body}
          sendAsText={type === 'practice' ? true : false}
          loading={loading}
        />
      </Allowed>
    </div>
  );
}
