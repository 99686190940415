import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Capitalize } from 'lib/helpers/utility';
import useScrollBlock from 'lib/hooks/useScrollBlock';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import NewPharmacy from './NewPharmacy';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';

export default function Pharmacy({
  pharmacy,
  deletePharmacy,
  selected,
  setSelected,
  patientId,
  updatePharmacy
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showEditPharmacy, setShowEditPharmacy] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const navigate = useNavigate();
  const handleEditPharmacy = () => {
    blockScroll();
    setShowEditPharmacy(true);
  };
  const hideEditPharmacyModal = () => {
    allowScroll();
    setShowEditPharmacy(false);
  };

  const handleDeletePharmacy = async () => {
    try {
      const onSuccess = () => {
        deletePharmacy(pharmacy.id);
        setShowConfirmationModal(false);
        showAlert({ title: 'Successfully deleted pharmacy', color: 'primary' });
      };
      const onError = (error) => {
        showAlert({ title: error, color: 'danger' });
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
      };
      await requestApi({
        url: '/api/pharmacy/delete',
        params: { id: pharmacy.id },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <>
      <div
        key={pharmacy.id}
        className={`grid-auto-row my-3 grid ${
          selected && 'cursor-pointer duration-300 hover:-translate-y-1 hover:scale-90'
        } ${
          selected?.id === pharmacy.id &&
          'rounded-lg border-4 border-solid border-green-600 transition-all delay-100'
        }`}
        onClick={() => setSelected && setSelected(pharmacy)}>
        <div className="relative rounded-lg border bg-primary-50 p-20">
          <div className="absolute right-4 top-4 flex gap-3 ">
            <div onClick={(e) => handleEditPharmacy(pharmacy)} data-qa="edit-btn-pharmacy">
              <Icon icon="edit" className="cursor-pointer" />
            </div>
            <div onClick={() => setShowConfirmationModal(true)} data-qa="delete-btn-pharmacy">
              <Icon icon="trash" className="cursor-pointer" />
            </div>
          </div>

          <div>
            <p className="text-base font-semibold">Pharmacy name:&nbsp;</p>
            <p className="text-base">{pharmacy.name}</p>
          </div>
          <div>
            <p className="text-base font-semibold">Phone:&nbsp;</p>
            <p className="text-base">{pharmacy.phone || "' '"}</p>
          </div>
          <div>
            <p className="text-base font-semibold">Fax Number:&nbsp;</p>
            <p className="text-base">{pharmacy.fax_number || "' '"}</p>
          </div>
          <div>
            <p className="text-base font-semibold">Address 1:&nbsp;</p>
            <p className="text-base">{Capitalize(pharmacy.address.address_ln_1)}</p>
          </div>
          <div>
            <p className="text-base font-semibold">Address 2:&nbsp;</p>
            <p className="text-base">{Capitalize(pharmacy.address.address_ln_2) || ''}</p>
          </div>

          <div>
            <p className="text-base font-semibold">City:&nbsp;</p>
            <p className="text-base">{pharmacy.address.city || ''}</p>
          </div>
          <div>
            <p className="text-base font-semibold">State:&nbsp;</p>
            <p className="text-base">{pharmacy.address.addressState.name || ''}</p>
          </div>
          <div>
            <p className="text-base font-semibold">Zip Code:&nbsp;</p>
            <p className="text-base">{pharmacy.address.zip || ''}</p>
          </div>
        </div>
      </div>
      {showEditPharmacy && (
        <NewPharmacy
          patientId={patientId}
          showPharmacyModal={showEditPharmacy}
          hidePharmacyModal={hideEditPharmacyModal}
          pharmacy={pharmacy}
          updatePharmacy={updatePharmacy}
        />
      )}
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={handleDeletePharmacy}
        title="Delete pharmacy"
        message="Are you sure you want to delete this pharmacy?"
        variant="danger"
      />
    </>
  );
}
