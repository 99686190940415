export const getClasses = ({ text, size = 'large', color = 'primary', disabled, isRounded }) => {
  const isNumber = !isNaN(Number(text)) && text !== '';

  const baseClasses = [
    'w-max flex items-center justify-center gap-2 border border-solid transition-colors select-none',
    isRounded ? 'rounded-full' : 'rounded-md', // rounded 6px
    disabled && 'cursor-not-allowed'
  ];

  const sizeClasses = {
    small: isNumber ? 'min-w-5 h-5 px-2 leading-[14px]' : 'h-5 px-2 leading-[14px]', // 20px
    medium: isNumber ? 'min-w-6 h-6 px-2 leading-[14px]' : 'h-6 px-2 leading-[14px]', // 24px
    large: isNumber ? 'min-w-7 h-7 px-2 leading-4' : 'h-7 px-3 leading-4' // 28px
  };

  const colorClasses = {
    neutral: disabled
      ? 'text-neutral-200 bg-neutral-25 border-neutral-100'
      : 'text-neutral-500 bg-neutral-50 border-neutral-200 hover:text-neutral-600 hover:bg-neutral-100',
    primary: disabled
      ? 'text-primary-200 bg-primary-25 border-primary-100'
      : 'text-primary-500 bg-primary-50 border-primary-200 hover:text-primary-600 hover:bg-primary-100',
    secondary: disabled
      ? 'text-secondary-200 bg-secondary-25 border-secondary-100'
      : 'text-secondary-500 bg-secondary-50 border-secondary-200 hover:text-secondary-600 hover:bg-secondary-100',
    success: disabled
      ? 'text-success-200 bg-success-25 border-success-100'
      : 'text-success-500 bg-success-50 border-success-200 hover:text-success-600 hover:bg-success-100',
    warning: disabled
      ? 'text-warning-200 bg-warning-25 border-warning-100'
      : 'text-warning-500 bg-warning-50 border-warning-200 hover:text-warning-600 hover:bg-warning-100',
    danger: disabled
      ? 'text-danger-200 bg-danger-25 border-danger-100'
      : 'text-danger-500 bg-danger-50 border-danger-200 hover:text-danger-600 hover:bg-danger-100'
  };

  return [...baseClasses, sizeClasses[size], colorClasses[color]].join(' ');
};
