import React, { useState } from 'react';
import { Calendar } from 'react-date-range';
import { useNavigate } from 'react-router-dom';

import { Popover } from '@headlessui/react';
import { genders, marital_status, races } from 'constants';
import { useFormikContext } from 'formik';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';

import { formatDate, ia, optionify } from 'lib/helpers/utility';
import { useOccupations } from 'lib/hooks/queries/occupations/useOccupations';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import state from 'components/state';

const PersonalInfo = () => {
  const permissions = useRecoilValue(state.permissions);
  const navigate = useNavigate();
  const [decodedSSN, setDecodedSSN] = useState(false);

  const {
    handleChange: onChange,
    values,
    errors,
    setFieldValue,
    initialValues
  } = useFormikContext();
  const occupationsResponse = useOccupations({
    returnAll: true
  });
  const occupationList = occupationsResponse?.data?.occupations || [];
  const disabled = !permissions?.patients?.update;

  const getSSN = async () => {
    try {
      let data = await requestApi({
        url: '/api/user/get_secure',
        params: { id: values?.id },
        navigate
      });
      const ssn = data?.secure?.ssn;

      if (data) {
        setFieldValue('ssn', ssn);
        setDecodedSSN(true);
      }
    } catch (err) {}
  };

  return (
    <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
      <Input
        data-qa="first-name"
        name="f_name"
        label="First name"
        placeholder="First name"
        onChange={onChange}
        value={values?.f_name}
        error={errors?.f_name}
        disabled={disabled}
      />

      <Input
        data-qa="middle-name"
        name="m_name"
        label="Middle Name"
        onChange={onChange}
        placeholder="Middle Name"
        value={values?.m_name}
        disabled={disabled}
      />

      <Input
        data-qa="last-name"
        name="l_name"
        label="Last name"
        onChange={onChange}
        placeholder="Last name"
        value={values?.l_name}
        error={errors?.l_name}
        disabled={disabled}
      />

      <Input
        data-qa="preferred-name"
        name="p_name"
        label="Preferred name"
        onChange={onChange}
        placeholder="Preferred name"
        value={values?.p_name}
        error={errors?.p_name}
        disabled={disabled}
      />

      <Input
        data-qa="phone"
        name="phone"
        label="Cell Phone"
        onChange={onChange}
        placeholder="+1(123)4567890"
        value={values?.phone}
        error={errors?.phone}
        disabled={disabled}
      />

      <div className="flex flex-col gap-1">
        <Input
          data-qa="alt_phone"
          name="alt_phone"
          label="Alternative Phone"
          onChange={onChange}
          placeholder="+1(321)4567890"
          value={values?.alt_phone}
          error={errors?.alt_phone?.replace('alt_phone', 'Alternative Phone')}
          disabled={disabled}
        />
        {values?.alt_phone && (
          <Checkbox
            isChecked={values.alt_phone_notify}
            handleClick={() =>
              onChange({
                target: { name: 'alt_phone_notify', value: !values?.alt_phone_notify }
              })
            }
            label="Enable for notifications"
            disabled={disabled}
          />
        )}
      </div>

      <Input
        data-qa="email"
        name="email"
        label="Email"
        placeholder="email@example.com"
        onChange={onChange}
        value={values.email}
        error={errors?.email}
        disabled={disabled}
      />

      <div className="flex flex-col gap-1">
        <Input
          data-qa="alt_email"
          name="alt_email"
          label="Alternative Email"
          placeholder="alternative_email@example.com"
          onChange={onChange}
          value={values.alt_email}
          error={errors?.alt_email?.replace('alt_email', 'Alternative Email')}
          disabled={disabled}
        />
        {values?.alt_email && (
          <Checkbox
            isChecked={values.alt_email_notify}
            handleClick={() =>
              onChange({
                target: { name: 'alt_email_notify', value: !values?.alt_email_notify }
              })
            }
            label="Enable for notifications"
            disabled={disabled}
          />
        )}
      </div>

      <Popover className="relative -mt-[3px]">
        {({ close }) => (
          <>
            <Popover.Button className="w-full p-0">
              <Input
                data-qa="date-of-birth"
                label="Date of birth"
                rightIcon="new-calendar-gray"
                value={formatDate(values?.dob)}
                error={errors?.dob}
                disabled={disabled}
              />
            </Popover.Button>

            <Popover.Panel className="absolute z-10 flex shadow-md">
              <Calendar
                date={new Date(values?.dob) || new Date()}
                onChange={(date) => {
                  setFieldValue('dob', moment.utc(date).add(12, 'h'));
                  close();
                }}
              />
            </Popover.Panel>
          </>
        )}
      </Popover>

      <Select
        inputId="gender-select"
        name="gender"
        label="Gender"
        placeholder="Type and select gender"
        options={optionify(genders, 'label', 'value')}
        onChange={(e) => setFieldValue('gender', e?.value)}
        value={genders.find((l) => l?.value === values.gender)}
        disabled={disabled}
      />

      <Select
        inputId="race-select"
        name="race"
        label="Ethnicity"
        placeholder="Type and select race"
        options={optionify(races, 'label', 'value')}
        onChange={(e) => setFieldValue('race', e?.value)}
        value={races.find((l) => l?.value === values.race)}
        disabled={disabled}
      />

      <Select
        inputId="maritalstatus-select"
        name="marital_status"
        label="Marital Status"
        placeholder="Type and select marital status"
        options={optionify(marital_status, 'label', 'value')}
        onChange={(e) => setFieldValue('marital_status', e?.value)}
        value={marital_status.find((l) => l?.value === values.marital_status)}
        disabled={disabled}
      />

      <Select
        inputId="occupation-id-select"
        name="occupation_id"
        label="Occupation"
        placeholder="Type and select occupation"
        options={
          ia(occupationList)
            ? occupationList.map((occupation) => ({
                label: occupation.name,
                value: occupation.id
              }))
            : []
        }
        onChange={(e) => setFieldValue('occupation_id', e?.value)}
        value={
          ia(occupationList)
            ? occupationList.map((l) => {
                if (l?.id === values.occupation_id) return { value: l?.id, label: l?.name };
              })
            : null
        }
        disabled={disabled}
      />

      <Input
        data-qa="drivers-license"
        name="personal_id"
        onChange={onChange}
        label="Driver's License"
        placeholder="Driver's License"
        value={values?.personal_id}
        disabled={disabled}
      />

      <div>
        <div className="relative">
          <Input
            data-qa="social-security-number"
            name="ssn"
            onChange={onChange}
            label="Social Security Number"
            placeholder="Social Security Number"
            value={values?.ssn}
            type={!initialValues?.ssn ? 'text' : values.ssn && !decodedSSN ? 'password' : 'text'}
            rightIcon={
              values?.ssn &&
              permissions?.secure?.read &&
              (!initialValues?.ssn ? '' : values.ssn && decodedSSN ? 'new-eye' : 'new-eye-slash')
            }
            rightIconClick={() =>
              initialValues?.ssn && !decodedSSN ? getSSN() : setDecodedSSN(!decodedSSN)
            }
            hint={!values?.ssn && 'No SSN on file'}
            disabled={disabled}
          />
        </div>
      </div>

      <div>
        <Input
          data-qa="custom-id"
          name="custom_id"
          label="Custom Id"
          onChange={onChange}
          placeholder="Custom Chart Id"
          value={values?.custom_id}
          error={errors?.custom_id}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PersonalInfo;
