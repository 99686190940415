export const getNonEmptyFields = (obj) => {
  const result = {};

  for (const key in obj) {
    const value = obj[key];

    if (value !== null && value !== '' && value !== undefined) {
      // Recursively filter nested objects or arrays
      if (Array.isArray(value)) {
        if (value.length > 0) {
          result[key] = value;
        }
      } else if (typeof value === 'object' && !Array.isArray(value)) {
        const nestedResult = getNonEmptyFields(value);
        if (Object.keys(nestedResult).length > 0) {
          result[key] = nestedResult;
        }
      } else {
        result[key] = value;
      }
    }
  }

  return result;
};

export const filterTypeOfNotes = (typeOfNotes, titles) => {
  const finalTitles = titles.map((title) => {
    switch (title) {
      case 'present_illness':
        return 'patient-needs';
      case 'other_complaints':
        return 'complaints';
      case 'cpt_codes':
        return 'cpt-codes';
      case 'icd_10_codes':
        return 'diagnosis-codes';
      case 'charts3d':
        return 'face';
      default:
        return title;
    }
  });

  const filteredNotes = {};
  for (let noteType in typeOfNotes) {
    const note = typeOfNotes[noteType];
    const { components } = note;

    const filteredComponents = {};
    for (let componentKey in components) {
      const component = components[componentKey];

      if (finalTitles.includes(componentKey) || finalTitles.includes(component.path)) {
        filteredComponents[componentKey] = { ...component, checked: true, selected: true };
      } else {
        filteredComponents[componentKey] = { ...component, checked: false, selected: false };
      }
    }

    if (Object.keys(filteredComponents).length > 0) {
      filteredNotes[noteType] = {
        ...note,
        components: filteredComponents,
        checked: true
      };
    }
  }

  return filteredNotes;
};

export const getFinalAdvancedForms = (filledAdvancedForms, advancedForms) => {
  const advancedFormsMap = new Map();
  advancedForms.forEach((form) => {
    advancedFormsMap.set(form.id, form);
  });

  const finalAdvancedForms = filledAdvancedForms?.map((filledForm) => {
    const matchedForm = advancedFormsMap.get(filledForm.form_id);

    if (matchedForm) {
      return {
        ...filledForm,
        form_name: matchedForm.name,
        custom_title: matchedForm.custom_title,
        form_version: matchedForm.version
      };
    }

    return filledForm;
  });

  return finalAdvancedForms;
};
