import { getService } from 'api/Service';

import { imagePath } from 'lib/helpers/utility';

export const addItem = (formik, type) => {
  const newItem = {};

  const updatedItems = [...formik.values[type], newItem];

  formik.setFieldValue(type, updatedItems);
};

export const addItems = (setFieldValue, currentValues, newValues, type) => {
  const updatedItems = [...currentValues, ...newValues];

  setFieldValue(type, updatedItems);
};

export const removeItem = (setFieldValue, items, type, i) => {
  const updatedItems = items;

  updatedItems.splice(i, 1);

  setFieldValue(type, updatedItems);
};

export const handleChangeQuantity = (setFieldValue, currentValues, val, id) => {
  const sales_count = Number(val);

  const updatedProducts = currentValues.map((product) =>
    product.id === id
      ? { ...product, sales_count, total_amount_cents: product.amount_cents * sales_count }
      : product
  );

  setFieldValue('products', updatedProducts);
};

export const handleChangePackageQuantity = (type, setFieldValue, currentValues, val, id) => {
  const sales_count = Number(val);
  setFieldValue(
    type,
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, sales_count, total_amount_cents: s.amount_cents * sales_count };
      return s;
    })
  );
};

export const handleChangePrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, total_amount_cents: price, amount_cents: price / s?.quantity };
      return s;
    })
  );
};

export const handleChangePriceProcedures = (setFieldValue, currentValues, val, i, key) => {
  const price = Number(val);

  const updatedValues = currentValues.map((procedure, index) => {
    if (index !== i) return procedure;

    const { pt_balance, total_cost_cents } = procedure;
    const modifiedProcedure = { ...procedure };

    if (key === 'charge') {
      modifiedProcedure.charge = price; // Charge
    } else if (key === 'ins_payment') {
      modifiedProcedure.ins_payment = price; // Allowed
      modifiedProcedure.allowed = price;
    } else {
      if ((pt_balance && price !== pt_balance) || (!pt_balance && price !== total_cost_cents)) {
        modifiedProcedure.pt_balance = price; // Est. Pt. Portion
        modifiedProcedure.modified = true; //?

        if (price === total_cost_cents) {
          delete modifiedProcedure.pt_balance;
          delete modifiedProcedure.modified;
        }
      } else if (pt_balance === total_cost_cents) {
        delete modifiedProcedure.pt_balance;
        delete modifiedProcedure.modified;
      }
    }
    return modifiedProcedure;
  });

  setFieldValue('procedures', updatedValues);
};

export const handleChangeUnitPrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, amount_cents: price, total_amount_cents: price * s?.quantity };
      return s;
    })
  );
};

export const reShapeProducts = (data) => {
  return (
    data?.products?.map((p) => ({
      id: p?.id,
      quantity: 1,
      label: p.name,
      amount_cents: p.amount_cents,
      total_amount_cents: p.amount_cents,
      ...(p?.image && {
        image: imagePath(JSON.parse(p?.image || `{}`)?.jpg)
      })
    })) || []
  );
};

const NO_VALUE = undefined;

export const handleProcedureType = async (
  setFieldValue,
  currentValues,
  index,
  typeItem,
  serviceId
) => {
  const { service } = typeItem !== 'unapplied' ? await getService(() => {}, { serviceId }) : {};

  const updatedValues = currentValues.map((s, i) => {
    if (i === index) {
      if (typeItem === 'self_pay') {
        return {
          ...s,
          pt_balance: NO_VALUE,
          modified: NO_VALUE,
          ins_payment: NO_VALUE,
          allowed: NO_VALUE,
          type: typeItem,
          total_cost_cents: service?.total_cost_cents || service?.charge
        };
      } else if (typeItem === 'primary') {
        return {
          ...s,
          pt_balance: NO_VALUE,
          modified: NO_VALUE,
          total_cost_cents: NO_VALUE,
          charge: service?.standard_charge || 0,
          type: typeItem
        };
      } else if (typeItem === 'unapplied') {
        return {
          ...s,
          pt_balance: NO_VALUE,
          modified: NO_VALUE,
          total_cost_cents: NO_VALUE,
          ins_payment: NO_VALUE,
          allowed: NO_VALUE,
          charge: NO_VALUE,
          type: typeItem
        };
      } else {
        return { ...s, type: typeItem };
      }
    }
    return s;
  });

  setFieldValue('procedures', updatedValues);
};

export const timeFilterOption = (candidate, input) => {
  const normalizedLabel = candidate.label.toLowerCase();
  const normalizedInput = input.toLowerCase().trim();

  const regex = /^(\d{1,2})(\s?(a|p)m?)?$/;
  const match = normalizedInput.match(regex);

  if (match) {
    const [_, inputHour, , inputMeridiem] = match;

    const formattedInputHour = inputHour.padStart(2, '');

    if (!inputMeridiem) {
      return normalizedLabel.startsWith(`${formattedInputHour}:`);
    }

    const fullInputMeridiem =
      inputMeridiem.trim() === 'p'
        ? 'pm'
        : inputMeridiem.trim() === 'a'
          ? 'am'
          : inputMeridiem.trim();

    return (
      normalizedLabel.startsWith(`${formattedInputHour}:`) &&
      normalizedLabel.endsWith(fullInputMeridiem)
    );
  }

  return normalizedLabel.startsWith(normalizedInput);
};
