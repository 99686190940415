import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { useFormik } from 'formik';
import LogRocket from 'logrocket';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import * as Yup from 'yup';
import { defaultSettings } from 'constants';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { io } from 'lib/helpers/utility';
import { findOpenAndCloseClinicHours } from 'lib/helpers/workingHours';
import usePageTitle from '../../lib/hooks/usePageTitle';
import PatientCheckinLogin from 'components/Patient/checkin/Login';
import { current_admin } from 'components/admin/lib/adminState';
import practiceState from '../practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import { permissions, userState } from '../state';
import './Login.scss';
import Icon from 'components/shared/Icon/Icon';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import { datadogRum } from '@datadog/browser-rum';

const LoginValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email required'),
  password: Yup.string().min(4, 'Too Short!').max(50, 'Too Long!').required('Password required')
});

const Login = ({ loginType }) => {
  const [currUserState, setUserState] = useRecoilState(userState);
  const [currPermissions, setPermissions] = useRecoilState(permissions);
  const [currentAdmin, setCurrentAdmin] = useRecoilState(current_admin);
  const [showPassword, setShowPassword] = useState(false);
  const [currentPractice, setCurrentPractice] = useRecoilState(practiceState.currentPractice);
  const setAppointmentFilters = useSetRecoilState(practiceState.calendarFilters);

  const navigate = useNavigate();
  const { device } = useUIContext();

  usePageTitle('Login');

  const getCurrentPractice = async () => {
    try {
      const { practice } = await requestApi({
        url: '/api/practice/current_practice',
        params: {},
        navigate
      });
      if (practice) {
        const workingHours = findOpenAndCloseClinicHours(practice?.available_times);
        setCurrentPractice({
          ...currentPractice,
          id: practice.id,
          name: practice.name,
          email: practice.email,
          phone: practice.phone,
          address: practice.practiceAddress.fullAddress,
          header_photo: practice.header_photo,
          timezone: practice.timezone,
          working_hours: workingHours,
          display_settings: practice?.display_settings,
          appointment_types: practice?.appointment_types,
          eligibility_counter: practice?.eligibility_counter,
          care_credit: practice?.care_credit,
          surcharge: practice?.surcharge,
          surcharge_percentage: practice?.surcharge_percentage,
          surcharge_enabled: practice?.surcharge_enabled,
          socket_id: practice?.socket_id
        });
      }
      return { id: practice.id, name: practice.name };
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const getPermissions = async () => {
    if (
      currPermissions.loaded ||
      (!(currUserState.logged_in === currentAdmin.logged_in) &&
        (currUserState.logged_in || currentAdmin.logged_in))
    ) {
      return;
    }
    try {
      const onSuccess = ({ permissions }) => {
        setPermissions({ ...permissions });
      };
      const onError = (error) => {
        console.error(error);
        showAlert({
          title: `There's been an error getting permissions. Please try again later.`,
          color: 'danger'
        });
        Honeybadger.notify(
          `file: Login, method: getPermissions - try, error: ${error ?? 'Theres been an error'}`
        );
      };
      await requestApi({
        url: '/api/permissions/get',
        params: {},
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: Login, method: getPermissions - catch, error: ${error ?? 'Theres been an error'}`
      );
    }
  };

  const submitUserLogin = async ({ email, password }) => {
    try {
      sessionStorage.removeItem('myriad-session-reference-id');
      const onSuccess = async ({ user }) => {
        setUserState({
          ...user,
          display_settings: user.display_settings || defaultSettings,
          logged_in: true
        });
        await getPermissions();
        const currPractice = await getCurrentPractice();

        let newUser = {
          id: user?.id,
          name: `${user.f_name} ${user.l_name}`,
          email: user.email,
          admin: false
        };

        if (currPractice?.id) {
          newUser['practiceId'] = currPractice?.id;
          newUser['practiceName'] = currPractice?.name;
        }

        datadogRum.setUser(newUser);

        LogRocket &&
          LogRocket.identify(user.user_id, {
            name: `${user.f_name} ${user.l_name}`,
            email: user.email,
            practiceName: currPractice?.name,
            practiceId: currPractice?.id
          });
        Honeybadger.setContext({
          user_id: user?.user_id,
          user_email: user?.email,
          practice_name: currPractice?.name,
          practice_id: currPractice?.id
        });

        window.intercomSettings = {
          app_id: process.env.REACT_APP_INTERCOM_APP_ID,
          name: `${user?.f_name} ${user?.l_name}`,
          user_id: user?.user_id,
          email: user?.email,
          phone: user?.phone,
          created_at: user?.created_at,
          practice_name: currPractice?.name,
          practice_id: currPractice?.id
        };

        const redirectLink = localStorage.getItem('redirectTo');
        redirectLink && redirectLink !== '/login'
          ? navigate(redirectLink)
          : navigate(res.data.redirect);

        localStorage.removeItem('redirectTo');
        if (user?.display_settings && io(user?.display_settings?.appointmentFilters)) {
          setAppointmentFilters(user?.display_settings?.appointmentFilters);
        }
      };
      const onError = (error) => {
        showAlert({
          title: error || `There's been an error logging in. Please check your credentials.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/user/login',
        params: {
          email,
          password
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (err) {
      const res = err.response;
      if (res) {
        const { code, error, redirect } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 1:
            if (error) showAlert({ title: error, color: 'danger' });
            else
              showAlert({
                title: `There's been an error logging in. Please check your credentials.`,
                color: 'danger'
              });
            break;
          default:
            if (error) showAlert({ title: error, color: 'danger' });
            break;
        }
      }
    }
  };

  const submitAdminLogin = async ({ email, password }) => {
    try {
      const onSuccess = (data, d, redirect) => {
        const { admin: loaded_admin } = data;
        setCurrentAdmin({
          ...loaded_admin,
          logged_in: true
        });

        datadogRum.setUser({
          id: loaded_admin?.id,
          name: `${loaded_admin.f_name} ${loaded_admin.l_name}`,
          email: loaded_admin.email,
          admin: true
        });

        LogRocket &&
          LogRocket.identify(`admin_${loaded_admin.user_id}`, {
            name: `${loaded_admin.f_name} ${loaded_admin.l_name}`,
            email: loaded_admin.email,
            isAdmin: true
          });
        localStorage.setItem('admin-state', JSON.stringify(loaded_admin));
        navigate(redirect);
      };
      const onError = (error) => {
        showAlert({
          title: error || `There's been an error logging in. Please check your credentials.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/admin/login',
        params: {
          email,
          password
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (err) {
      const res = err.response;
      if (res) {
        const { code, error, redirect } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 1:
            if (error) showAlert({ title: error, color: 'danger' });
            else
              showAlert({
                title: `There's been an error logging in. Please check your credentials.`,
                color: 'danger'
              });

            break;
          default:
            if (error) showAlert({ title: error, color: 'danger' });
            break;
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      loginType === 'user'
        ? await submitUserLogin({
            email: values.email,
            password: values.password
          })
        : await submitAdminLogin({
            email: values.email,
            password: values.password
          });
      setSubmitting(false);
    }
  });

  if (loginType === 'user') {
    return <PatientCheckinLogin />;
  }

  return (
    // <div className="Login--overlay">
    <div className="Login--overlay !bg-primary-800" data-dd-privacy="allow">
      {device === 'mobile' && <Icon icon="logo" size={200} className="!mb-6" />}
      <div className="Login">
        <div className="Login__left">
          <h3>Login</h3>
          <form onSubmit={formik.handleSubmit} className="Login__form">
            <div className="Login__left__block">
              <Input
                data-qa="login-email"
                label="Email address"
                id="email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                submitFormOnEnter
              />
              <span className={cs('Error', formik.errors.email && 'show')}>
                {formik.errors.email}
              </span>
            </div>
            <div className="Login__left__block">
              <div className="relative" data-dd-privacy="mask">
                <Input
                  data-qa="login-password"
                  label="Password"
                  id="password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  submitFormOnEnter
                  rightIcon={showPassword ? 'new-eye' : 'new-eye-slash'}
                  rightIconClick={() => setShowPassword(!showPassword)}
                />
              </div>
              <span className={cs('Error', formik.errors.password && 'show')}>
                {formik.errors.password}
              </span>
            </div>
            <div className="Login__form__footer">
              <Link to={loginType === 'user' ? '/password/request' : '/admin/password/request'}>
                Forgot password?
              </Link>
              <Button
                buttonType="submit"
                text="Login"
                disabled={formik.isSubmitting}
                onClick={formik.submitForm}
                data-qa="login-btn"
              />
            </div>
          </form>
          {device === 'mobile' && (
            <div className="Login__left__contact">
              <p className="font-500">Don't have an account yet?</p>
              <div className="flex gap-4">
                <Link to="/contact">Contact us</Link>
                <a href="https://www.myriadbymeta.com/">Learn more</a>
              </div>
            </div>
          )}
        </div>
        {device !== 'mobile' && (
          <div className="Login__right">
            <Icon icon="logo" size={200} className="!pb-10" />
            <div className="pb-7">
              <h3>Hello again!</h3>
              <p>
                We're proud that you have decided to choose our team. <br />
                In order to access this page, you need to log in.
              </p>
            </div>
            <div>
              <p className="font-500">Don't have an account yet?</p>
              <div className="flex gap-10">
                {/* <Link to="/contact" className="Login__right__button">
                  Contact us
                </Link> */}
                <a href="https://www.myriadbymeta.com/" className="Login__right__button">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
