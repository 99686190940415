import React, { useRef, useState } from 'react';
import Modal from '../../../../shared/Modal/Modal';
import Button from '../../../../shared/Buttons/Button';
import ResourceForm from './ResourceForm';

const NewResourceModal = ({ resourcesModalVisible, setResourcesModalVisible, resource }) => {
  const [loading, setLoading] = useState(false);
  const resourceRef = useRef();

  return (
    <Modal
      handleOpen={!!resourcesModalVisible}
      handleClose={() => setResourcesModalVisible(false)}
      title={`${resource ? 'Update' : 'Add'} resource`}
      customStyling={{ width: '520' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setResourcesModalVisible(false)}
            data-qa="cancel-resource-modal-btn"
          />
          <Button
            onClick={() => resourceRef.current.submitForm()}
            text={resource ? 'Update' : 'Add'}
            id="createContactModalBtn"
            data-qa="add-resource-btn"
            loading={loading}
          />
        </div>
      }>
      <ResourceForm
        resource={resource}
        setLoading={setLoading}
        ref={resourceRef}
        setResourcesModalVisible={setResourcesModalVisible}
      />
    </Modal>
  );
};

export default NewResourceModal;
