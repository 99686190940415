import React, { useEffect, useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment';
import { showAlert } from 'components/shared/Alert/Alert';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { iaRa } from 'lib/helpers/utility';
import InsuranceProfiles from 'components/practice/charts/InsuranceProfile/InsuranceProfiles';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import state from './state';
import './style.scss';
import { requestApi } from 'api/Api';
import CheckinLoginWarning from './LoginWarning';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';

const Insurance = () => {
  const {
    updateSteps,
    checkinButton,
    appointmentId,
    fakeModal,
    handleExternalSubmit,
    isInstantPacket,
    practice,
    practitionerId,
    setFakeModal,
    kiosk
  } = useOutletContext();

  const [selected, setSelected] = useState(null);

  const selections = useRecoilValue(state.selectionState);
  const patient = useRecoilValue(state.userState);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { device } = useUIContext();

  useEffect(() => {
    if (appointmentId) {
      if (selections?.insurance !== null) {
        if (!patient.authenticated && !patient.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
        }
      }
    }
  }, [appointmentId, selections]);

  const getCheckinStatus = async () => {
    if (isInstantPacket) return;
    if (selected?.id) {
      let res = await requestApi({
        url: 'api/patient/insurance/select',
        params: {
          insuranceProfileId: selected.id
        },
        navigate
      });
      const { code, redirect, error } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          // updateSteps({
          //   version: 2,
          //   // formId: 1,
          //   newStatus: 'completed'
          //   // formName: propertyPathMap.insuranceIdComplete.name(),
          //   // isCompleted: true
          // });
          break;
        default:
          Honeybadger.notify(
            `file: checkin/insurance, method: getCheckinStatus - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          if (error)
            showAlert({
              message: error,
              color: 'danger',
              position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
            });
          break;
      }
    } else {
      await requestApi({
        url: 'api/patient/insurance/no_insurance',
        params: {
          appointmentId
        },
        navigate
      });
    }
    updateSteps({
      version: 2,
      // formId: 1,
      newStatus: 'completed'
      // formName: propertyPathMap.insuranceIdComplete.name(),
      // isCompleted: true
    });
    // updateSteps({ formName: propertyPathMap.insuranceIdComplete.name(), isCompleted: true });
    return true;
  };

  const { data } = usePatient({
    params: { id: patient?.id, practice_id: practice?.id },
    dependencies: [patient?.id],
    options: {
      enabled: !!patient?.id
    }
  });

  const insuranceProfiles = iaRa(data?.patient?.insuranceProfile);

  return (
    <div className={cs('h-full ', fakeModal ? '!w-full p-0' : '!px-4 !py-4 sm:!px-4')}>
      {!patient?.authenticated && !patient?.isOnboarding && (
        <CheckinLoginWarning
          data="insurance profiles"
          note="Please note that previously selected insurance profile will be selected if no insurance is chosen."
        />
      )}
      <InsuranceProfiles
        insuranceProfiles={insuranceProfiles}
        patientId={patient?.id}
        fakeModal={fakeModal}
        practitionerId={practitionerId}
        kiosk={kiosk}
        setFakeModal={setFakeModal}
        practiceId={practice?.id}
        noInsurancePress={() => {
          setSelected(null);
          handleExternalSubmit();
        }}
      />
      <div hidden ref={checkinButton} onClick={() => getCheckinStatus()}></div>
    </div>
  );
};

export default withErrorBoundary(Insurance);
