import React, { Fragment } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Icon from 'components/shared/Icon/Icon';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const SortPermissions = ({ sort, sortOrder, setSortOrder }) => {
  const handleSelect = (order) => {
    setSortOrder(order);
  };

  return (
    <div className="relative self-center">
      <Listbox value={sortOrder} onChange={handleSelect}>
        {({ open }) => (
          <>
            <Listbox.Button className="w-full cursor-default rounded-lg text-sm">
              <span
                className={`text-blue border-b-3 flex items-center gap-1 ${
                  open && `border-x-0 border-t-0 border-solid border-blue-400`
                } `}>
                <Icon icon="sort-arrow" />
                <span className="text-sm text-neutral-600">Sort By:</span>
                <span className="text-sm text-primary-900">{sortOrder.label}</span>
                {open ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
              </span>
            </Listbox.Button>
            {open && (
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 min-w-fit overflow-auto rounded-md bg-white text-base shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  <div className="!p-5">
                    {sort.map((order, sortIdx) => (
                      <Listbox.Option
                        key={sortIdx}
                        className="relative cursor-default select-none rounded-lg !p-2 pl-10 pr-4 transition-all hover:bg-primary-50"
                        value={order}>
                        <div className="cursor-pointer">
                          <Checkbox
                            label={order.label}
                            value={order.value}
                            radio
                            isChecked={order.value === sortOrder.value}
                            color="blue"
                          />
                        </div>
                      </Listbox.Option>
                    ))}
                  </div>
                </Listbox.Options>
              </Transition>
            )}
          </>
        )}
      </Listbox>
    </div>
  );
};

export default SortPermissions;
