import React from 'react';

const TagColor = ({ color = '', options = [] }) => {
  let currBorderColor = options?.find((option) => option.color === color)?.border;
  return (
    <div
      className="relative z-10 flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full"
      style={{
        backgroundColor: color,
        border: `1px solid ${currBorderColor}`
      }}></div>
  );
};

export default TagColor;
