import React from 'react';
import Icon from '../../../../../../../../shared/Icon/Icon';

import { sanitize } from '../../../../../../../../../lib/helpers/sanitize';
import { removeBracketsInText } from 'lib/helpers/utility';
import { permissions, userState } from 'components/state';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const Summary = ({ setShowModal = () => null, copyTranscript = () => null }) => {
  const permission = useRecoilValue(permissions);
  const user = useRecoilValue(userState);

  const { clinicalNote } = useClinicalNoteContext();
  const { transcriptData } = useAmbientListeningContext();

  const transcriptSummary = removeBracketsInText(transcriptData?.[0]?.summary);

  const myScribeAuthor = transcriptData?.[0]?.provider_id ?? null;

  const isCurrentUserSameAsAuthor = myScribeAuthor == (user?.id ?? user?.user_id);

  const hasPermissionToUpdate = permission?.myscribe?.narrative_update || isCurrentUserSameAsAuthor;
  const hasPermissionToRead = permission?.myscribe?.narrative_read || isCurrentUserSameAsAuthor;

  return (
    <div className="h-[calc(100vh-200px)] overflow-auto !p-4">
      <div className="flex items-center justify-between">
        <p className="text-lg font-500 text-primary-700">Narrative</p>
        {!!transcriptSummary && hasPermissionToUpdate && (
          <div className="flex">
            <div onClick={copyTranscript}>
              <Icon
                size={28}
                icon="new-copy"
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
              />
            </div>

            <div>
              <Icon
                size={28}
                icon="new-edit"
                disabled={clinicalNote?.locked}
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
                onClick={() => setShowModal(true)}
              />
            </div>
          </div>
        )}
      </div>

      {!hasPermissionToRead ? (
        <p className="!mt-3 text-sm font-500 text-primary-900">
          You don't have the permission to view the MyScribe AI narrative.
        </p>
      ) : transcriptSummary ? (
        <div
          className="!mt-3 text-sm font-500 text-primary-900"
          dangerouslySetInnerHTML={sanitize(transcriptSummary)}
        />
      ) : (
        <p className="!mt-3 text-center text-sm italic text-primary-900">
          You currently have no transcript data. You can start one by clicking on 'record visit'.
        </p>
      )}
    </div>
  );
};

export default Summary;
