import React from 'react';
import { AgCharts } from 'ag-charts-enterprise';
AgCharts.setLicenseKey(process.env.REACT_AG_GRID_KEY);
import { AgChartsReact } from 'ag-charts-react';

const AGChart = ({ options, ...rest }) => {
  return <AgChartsReact options={options} {...rest} />;
};

export default AGChart;
