import React from 'react';
import * as filestack from 'filestack-js';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const AccountImage = ({ practice, setPractice }) => {
  const navigate = useNavigate();

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    const handle = {
      jpg: data.filesUploaded[0].handle
    };
    const res = await requestApi({
      url: '/api/practice/update',
      params: { fields: { header_photo: handle } },
      navigate
    });
    if (res.updatedItems > 0) {
      setPractice({ ...practice, header_photo: JSON.stringify(handle) });
    } else {
      console.error('There was an error uploading this image. Please try again.');
      showAlert({
        message: 'Uploading image failed. Please try again',
        color: 'danger'
      });
      Honeybadger.notify(
        `practice: ${practice.id}, file: /practice/settings/account/index.js, method: saveImage, error: upload image failed`
      );
    }
  };
  return (
    <div>
      <h6>Practice Image</h6>
      <div
        className="hover:cursor-pointer h-[200px] w-[200px] border border-gray-300 text-center flex justify-center items-center"
        onClick={() => openFilestackPicker()}>
        {practice?.header_photo ? (
          <img className='w-full' src={`https://cdn.filestackcontent.com/${JSON.parse(practice.header_photo).jpg}`} />
        ) : (
          <span>Upload image</span>
        )}
      </div>
    </div>
  );
};

export default AccountImage;
