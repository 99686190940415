import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Box from 'components/practice/charts/ClinicalNote/shared/Box';
import { defaultSettings } from 'constants';
import { io, snakeToTitleCase, pm } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Switch from 'components/shared/Switch/Switch';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { useGetOnlyCustomForm } from 'lib/hooks/queries/customForms/useGetOnlyCustomForm';
import { processCNDisplaySettingsCustomForms } from './lib/cnDisplaySettingsHelpers';
import Allowed from 'components/shared/Permissions/Allowed';
import state from 'components/state';
import { useRecoilValue } from 'recoil';

export default function Settings() {
  const { practitioner } = useOutletContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [displaySettings, setDisplaySettings] = useState(
    io(practitioner?.display_settings)
      ? _.merge({}, defaultSettings, practitioner?.display_settings)
      : defaultSettings
  );
  const [showModal, setShowModal] = useState(false);
  const permissions = useRecoilValue(state.permissions);

  const { data: customNotes, isLoading } = useGetOnlyCustomForm({
    params: { provider_id: practitioner?.id },
    dependencies: [practitioner?.id],
    options: {
      enabled: !!practitioner?.id,
      select: (data) => Object.values(data?.form_functions || {}),
      onSettled: (data) => processCNDisplaySettingsCustomForms({ setDisplaySettings, data })
    }
  });

  useEffect(() => {
    setDisplaySettings(
      io(practitioner?.display_settings)
        ? _.merge({}, defaultSettings, practitioner?.display_settings)
        : defaultSettings
    );
  }, [practitioner]);

  useEffect(() => {
    if (!showModal) return;

    queryClient.invalidateQueries(['practitioner']);
  }, [showModal]);

  const handleChange = async ({ grandParentKey, parentKey, key, value }) => {
    try {
      let res;
      let params;

      if (!grandParentKey) {
        params = {
          practitioner_id: practitioner.id,
          fields: {
            [key]: value
          }
        };
      } else {
        const newVal = {
          [grandParentKey]: {
            ...displaySettings[grandParentKey],
            [parentKey]: { ...displaySettings[grandParentKey][parentKey], [key]: value }
          }
        };
        params = {
          practitioner_id: practitioner.id,
          fields: {
            display_settings: {
              ...displaySettings,
              ...newVal
            }
          }
        };
      }

      res = await requestApi({
        url: '/api/practice/settings/practice_user/update',
        params,
        navigate
      });
      if (res.code === 0) {
        showAlert({ title: 'Settings updated successfully', color: 'success' });
        queryClient.invalidateQueries(['practitioner']);
      } else {
        Honeybadger.notify(
          `file: practitioners/Settings, method: handleChange - try, error: There's been an error`
        );
        showAlert({ title: 'There was an error updating the setting', color: 'danger' });
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const cnDisplaySettingsData = Object.entries(displaySettings?.clinicalNote).sort(
    ([key1], [key2]) => key1?.localeCompare(key2)
  );

  return (
    <>
      <Allowed requiredPermissions="member.update">
        <Button
          className="mx-auto mt-5 w-64"
          text="Edit clinical notes settings"
          onClick={() => setShowModal(true)}
        />
      </Allowed>
      <div className="grid grid-cols-4 gap-4 sm:grid-cols-2 md:grid-cols-3 xs:grid-cols-1">
        <Box>
          <div>EPCS</div>
          <Switch
            onChange={(e) => {
              handleChange({ key: 'epcs', value: e });
            }}
            checked={practitioner?.epcs || false}
            disabled={!pm(permissions, 'member.update')}
          />
        </Box>
      </div>
      <Modal
        slideFromRight
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        title="Clinical notes settings"
        className="max-w-[1100px]"
        footer={
          <>
            <Button text="Close" primary onClick={() => setShowModal(false)} />
          </>
        }>
        <div className="grid grid-cols-3 gap-4">
          {cnDisplaySettingsData?.map(([key, value], index) => {
            return (
              <Box
                key={index}
                className={cs(
                  'gap-y-1',
                  key === 'overview' && 'col-start-1 col-end-4 row-start-4'
                )}>
                <label className="block pb-1 font-500">{snakeToTitleCase(key)}</label>
                <Switch
                  onChange={() =>
                    handleChange({
                      grandParentKey: 'clinicalNote',
                      parentKey: key,
                      key: 'enabled',
                      value: !displaySettings?.clinicalNote?.[key]?.enabled
                    })
                  }
                  checked={
                    displaySettings?.clinicalNote
                      ? displaySettings?.clinicalNote?.[key]?.enabled
                      : true
                  }
                />
                {key === 'overview' && (
                  <div className="!mt-3 grid grid-cols-3 gap-4">
                    {Object.entries(value).map(([subKey, subValue], subIndex) => {
                      if (subKey === 'enabled') return null;
                      return (
                        <Box className="gap-y-1" key={subIndex}>
                          <label className="block pb-1 font-500">{snakeToTitleCase(subKey)}</label>
                          <Switch
                            onChange={() =>
                              handleChange({
                                grandParentKey: 'clinicalNote',
                                parentKey: key,
                                key: subKey,
                                value: !displaySettings?.clinicalNote[key]?.[subKey]
                              })
                            }
                            checked={displaySettings?.clinicalNote[key]?.[subKey]}
                          />
                        </Box>
                      );
                    })}
                  </div>
                )}
              </Box>
            );
          })}
        </div>
      </Modal>
    </>
  );
}
