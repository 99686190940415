import { Line, Text, Text3D } from '@react-three/drei';
import React from 'react';
import { useChart3dContext } from '../../../../../../../../lib/context/Chart3dContext/Chart3dContext';
import { isColorLight } from '../../../../../../../../lib/helpers/utility';
import { pointText } from './Point';

export default function RenderPoint({ point, index, sides, side, items }) {
  const rot = sides[side] ? sides[side].rotation : [0, 0, 0];

  const product = items?.find((p) => p.id === point?.product);
  const color = product?.color;
  const type = product?.item_type;

  if (side == 'left' && point.position[0] > 0) return <></>;
  if (side == 'right' && point.position[0] < 0) return <></>;

  return (
    <>
      <group position={point.position} rotation={[rot[0], rot[1], rot[2]]} key={index} scale={0.05}>
        <mesh>
          <circleGeometry args={[1, 32, 32]}></circleGeometry>
          <meshBasicMaterial toneMapped={false} attach="material" color={color}></meshBasicMaterial>
        </mesh>
        <mesh>
          <Text color={isColorLight(color) ? 0x000000 : 0xffffff} position={[0, -0.1, 0.1]}>
            {pointText(type, point)}
          </Text>
        </mesh>
      </group>
    </>
  );
}
