import React from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';

const category = 'products';

const TableHeader = ({ setNewProduct }) => {
  const { filters, setFilters } = useTableContext();

  return (
    <Header title="Products">
      <div className="flex items-center gap-2">
        <DisplayButton />
        <Filter
          category={category}
          defaultFilters={filters}
          filters={filters}
          setFilters={setFilters}
          menuPortalTarget={document.body}
          btnClassName="!h-[30px]"
        />
        <Button
          text="Create product"
          icon="new-tag-new"
          size="small"
          iconColor="white"
          onClick={() => setNewProduct(true)}
        />
      </div>
    </Header>
  );
};

export default TableHeader;
