import { isEqual } from 'lodash';
import moment from 'moment-timezone';

export const unitsOptions = [
  {
    value: 'in',
    label: 'inch (in)'
  },
  {
    value: 'cm',
    label: 'centimeters (cm)'
  }
];

export const initialVitals = {
  vitals: [
    {
      id: self.crypto.randomUUID(),
      heart_rate: '',
      respirations: '',
      blood_pressure_systolic: '',
      blood_pressure_diastolic: '',
      weight: '',
      temperature: '',
      height: '',
      bmi: '',
      oxygen_level: '',
      head_circumference: '',
      head_circumference_unit: '',
      notes: '',
      timestamp: new Date().toISOString()
    }
  ]
};
export const clinicalNoteFilters = (patient) => {
  return {
    searchTerm: '',
    page: 1,
    limit: 15,
    column: 'appointment.starts_at',
    sort: 'DESC',
    startDate: moment(patient?.created_at).utc().startOf('day').toDate(),
    endDate: moment().utc().endOf('day').toDate(),
    pagination: true
  };
};

export const doEmptyVitalExist = ({ newVital = {}, vitals = [] }) => {
  const copyVitals = [...vitals];
  return copyVitals.some((row) => {
    const copyRow = { ...row };
    const copyNewVital = { ...newVital };

    delete copyRow?.id;
    delete copyNewVital?.id;
    delete copyRow?.timestamp;
    delete copyNewVital?.timestamp;
    return isEqual(copyRow, copyNewVital);
  });
};
export const checkIfExistedBefore = (vitals) => {
  let existingOne;
  if (typeof vitals?.vitals[0]?.id !== 'number') {
    existingOne = false;
  } else {
    existingOne = true;
  }
  return existingOne;
};

export const updateVitalsOrReset = ({ vitalId, vitals, setVitals, resetVitals }) => {
  if (vitals?.vitals?.length > 1) {
    setVitals((prevVitals) => {
      const updatedVitals = prevVitals.vitals.filter((vital) => vital?.id !== vitalId);
      return {
        ...prevVitals,
        vitals: updatedVitals
      };
    });
  } else {
    resetVitals();
  }
};
