import React, { useEffect, useState } from 'react';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Icon from '../../../../../shared/Icon/Icon';

import { useParams } from 'react-router-dom';
import { formatSections } from '../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import Skeleton from '../../../../../shared/Skeleton/Skeleton';
import { beautifyAppendedString } from '../helpers/sidebarHelpers';
import { io } from '../../../../../../lib/helpers/utility';
import { useCustomPtResponseById } from '../../../../../../lib/hooks/queries/customForms/useCustomPtResponseById';

const PatientResponse = ({}) => {
  const { patientResponseSidebar } = useClinicalNoteContext();
  const { formId } = patientResponseSidebar;
  const [response, setResponse] = useState();
  const { id, appointmentId } = useParams();

  const { data, isFetching } = useCustomPtResponseById({
    dependencies: [formId, appointmentId, id],
    params: {
      formId,
      appointmentId,
      patientId: id,
      patientResponses: true
    },
    options: {
      keepPreviousData: true,
      enabled: !!formId
    }
  });

  useEffect(() => {
    !isFetching && processResponseData();
  }, [isFetching]);

  const processResponseData = () => {
    if (!io(data?.data)) return;

    const formResponse = data?.data?.formResponse;
    if (formResponse) {
      setResponse(
        formResponse?.form
          ? formatSections({
              fieldData: formResponse?.form?.json?.fields,
              answerData: JSON.parse(formResponse?.json?.fields)
            })
          : null
      );

      return;
    }

    setResponse(null);
  };

  const result = beautifyAppendedString(response);

  if (isFetching) {
    return (
      <div className="!p-3 overflow-y-scroll h-full">
        <Skeleton count={6} height="50px" />
      </div>
    );
  }

  if (!result) {
    return (
      <div className="flex flex-col justify-center items-center !p-3 !mt-12">
        <div className="bg-primary-50 rounded-full relative w-[72px] h-[72px] flex justify-center items-center">
          <Icon icon="new-user-arrow-right" size="32px" />
          <Icon className="absolute bottom-0 right-0" icon="new-search-basic" size="24px" />
        </div>
        <p className="text-primary-900 font-600 text-lg text-center !mt-3">No patient response</p>
        <p className="text-primary-900 text-sm text-center !mt-2">
          Ensure patients complete their form sections to access their responses.
        </p>
      </div>
    );
  }

  return <div className="!p-3 overflow-y-scroll h-full">{result}</div>;
};

export default PatientResponse;
