export const payerKinds = {
  0: {
    status: 'In-Network',
    color: 'success'
  },
  1: {
    status: 'Out-Of-Network',
    color: 'danger'
  },
  2: {
    status: 'Unknown',
    color: 'warning'
  }
};
