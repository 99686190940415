import React, { useEffect, useState } from 'react';
import { isEmpty } from '../../../../../../lib/helpers/utility';
import Icon from '../../../../../shared/Icon/Icon';
import Input from '../../../../../shared/Input/Input';

const ORDER_TYPES = [
  { name: 'Lab/Imaging Order', type: 'lab_order' },
  { name: 'Referral Order', type: 'referral_order' },
  { name: 'Non eRX Prescription', type: 'non_erx_prescription' }
  // { name: 'Radiology Order', type: 'radiology' }
];

const SearchInput = ({ filter, setFilter }) => {
  return (
    <Input
      id="search"
      type="string"
      placeholder="Search order"
      icon="new-search"
      value={filter}
      rightText={!isEmpty(filter) && <Icon icon="new-close" onClick={() => setFilter('')} />}
      onChange={(e) => setFilter(e?.target?.value)}
    />
  );
};

const OrderTypeSelection = ({ onSelect = () => null }) => {
  const [orderTypes, setOrderTypes] = useState([]);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    filterOrderTypes();
  }, [filter]);

  const filterOrderTypes = () => {
    if (isEmpty(filter)) {
      setOrderTypes(ORDER_TYPES);
      return;
    }

    setOrderTypes(
      ORDER_TYPES.filter(({ name, type }) => name.includes(filter) || type.includes(filter))
    );
    return;
  };

  return (
    <div>
      <SearchInput filter={filter} setFilter={setFilter} />

      {orderTypes.map((row, index) => {
        return (
          <div
            key={index}
            onClick={() => onSelect(row)}
            className="!mt-3 flex cursor-pointer gap-1.5 rounded-lg border border-solid border-neutral-200 bg-white !p-2 transition-all hover:!bg-primary-50">
            <Icon size={24} icon="new-note" color="primary" />
            <div className="font-medium text-neutral-800" data-qa={`${row.name}`}>
              {row?.name}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderTypeSelection;
