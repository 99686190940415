import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { archiveFaxes, getFaxesCSV } from 'api/Fax';
import { socket } from 'api/Socket';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useOutboundFaxes } from 'lib/hooks/queries/fax/useOutboundFaxes';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import { useTags } from 'lib/hooks/queries/tags/useTags';

import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import DocumentModal from 'components/shared/DocumentEditor/DocumentModal';

import NewFaxesTable from '../NewFaxesTable';
import AssignPatientModal from '../components/AssignPatientModal';

import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS, getColDefs } from './configs';

const NewOutboundFaxes = () => {
  const currPractice = useRecoilValue(currentPractice);
  const timezone = currPractice?.timezone;
  const [previewFax, setPreviewFax] = useState(null);
  return (
    <TableContextProvider
      name="outbound_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs({ timezone, setPreviewFax })}
      pagination>
      <OutboundFaxesTable setPreviewFax={setPreviewFax} previewFax={previewFax} />
    </TableContextProvider>
  );
};

function OutboundFaxesTable({ setPreviewFax, previewFax }) {
  const category = 'outbound_faxes';
  const kind = 'fax';
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;
  const [assignPatientModalVisible, setAssignPatientModalVisible] = useState(false);
  const { limit, page, sort, filters, selectedRows } = useTableContext();

  const selectedRowsData = selectedRows.map((node) => node.data);

  const [outboundFaxes, setOutboundFaxes] = useState([]);
  const { data, isFetching, isLoading } = useOutboundFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });
  const count = data?.count || 0;
  const practice = useRecoilValue(currentPractice);
  const { data: patientsData } = usePatients({
    params: { practiceId: practice.id }
  });
  const patients = patientsData?.patients;

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });

  const patientOptions = useMemo(() => {
    if (patients?.length > 0) {
      return patients.map((patient) => ({
        id: patient.user_id,
        label: patient?.f_name + ' ' + patient?.l_name,
        value: patient?.id
      }));
    }
    return [];
  }, [patients]);
  filters.patients.options = patientOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags.map((t) => ({
        label: t?.name,
        value: t?.id
      }));
    }
    return [];
  }, [tagsList]);
  filters.tags.options = tagOptions;

  useEffect(() => {
    if (data?.outboundFaxes) {
      setOutboundFaxes(data?.outboundFaxes);
    }
  }, [data?.outboundFaxes]);

  useEffect(() => {
    socket.on('outbound_fax', (data) => {
      const fax = data?.fax;
      setOutboundFaxes((prevFaxes) => [...prevFaxes, fax]);
    });

    return () => {
      socket.off('outbound_fax');
    };
  }, []);

  const onFaxesArchive = () => {
    archiveFaxes(
      navigate,
      selectedRowsData.map((fax) => fax.id)
    ).then(() => queryClient.invalidateQueries(['outboundFaxes']));
  };

  const onPreviewFax = async (fax) => {
    setPreviewFax(fax);
  };

  const onRowClicked = (clickedFax) => {
    onPreviewFax(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0', 'tags'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'outbound'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };

  return (
    <div className="h-full" data-dd-privacy="allow">
      <NewFaxesTable
        headerButtons={
          ia(selectedRowsData) ? (
            <div className="flex items-center gap-2 opacity-100 transition-opacity">
              <Button
                data-qa="assign-btn"
                text="Assign"
                color="success"
                className="h-[34px] py-1 text-sm"
                onClick={() => setAssignPatientModalVisible(true)}
              />
              <Button
                data-qa="archive-btn"
                text="Archive"
                color="neutral"
                className="h-[34px] py-1 text-sm"
                onClick={onFaxesArchive}
              />
            </div>
          ) : null
        }
        data={outboundFaxes}
        category="outbound_faxes"
        name="Outbound Faxes"
        onExportCSV={onExportCSV}
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
        count={count}
      />
      {assignPatientModalVisible && (
        <AssignPatientModal
          {...{
            assignPatientModalVisible,
            setAssignPatientModalVisible,
            faxQueryKey: 'outboundFaxes',
            faxes: selectedRowsData.map((fax) => ({ id: fax.id, files: fax.files }))
          }}
        />
      )}

      {!!previewFax && (
        <DocumentModal
          kind="fax"
          id={previewFax?.id}
          handleOpen={!!previewFax}
          handleClose={() => setPreviewFax()}
          isEditable
          title={'Fax #' + previewFax?.id}
        />
      )}
    </div>
  );
}

export default NewOutboundFaxes;
