const handleChangeUnitPrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, amount_cents: price, total_amount_cents: price * s?.quantity };
      return s;
    })
  );
};

export default handleChangeUnitPrice;
