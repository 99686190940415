export const handleSelectNDC = (
  event = { value: '', label: '' },
  index = null,
  customHandler = () => {}
) => {
  const { value, label } = event;
  customHandler({ target: { value, name: 'ndc_code' } }, index);

  customHandler({ target: { value: label?.slice(0, 80), name: 'narrative' } }, index);
};
