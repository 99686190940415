import React, { useMemo, useState } from 'react';

import Honeybadger from '@honeybadger-io/js';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { findStateName, imagePath, snakeToTitleCase } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import ImagePicker from 'components/shared/Picker/ImagePicker';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import { permissions } from 'components/state';

import CheckinLoginWarning from '../LoginWarning';

export default function PersonalInfoForm({
  formik,
  genderList,
  raceList,
  stateList,
  maritalStatusList,
  decodedSSN,
  setDecodedSSN,
  checkinButton,
  allDataEditable,
  editableData = { emailEdit: true, phoneEdit: true },
  showWarning = false,
  kiosk
}) {
  const handleChange = formik.handleChange;
  const currentPermissions = useRecoilValue(permissions);

  const [imageEdit, setImageEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { device } = useUIContext();

  const saveImage = async (imgHandle, type) => {
    try {
      setLoading(true);
      formik.setFieldValue('profile_photo', imgHandle);
      setLoading(false);
    } catch (error) {
      Honeybadger.notify(`Error saving ${type} image: ${error}`);
      showAlert({
        message: 'Uploading image failed. Please try again',
        color: 'danger',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
  };

  const profilePhoto = useMemo(() => {
    try {
      return JSON.parse(formik.values.profile_photo || `{}`).jpg;
    } catch (e) {
      return formik.values.profile_photo;
    }
  }, [formik.values?.profile_photo]);

  return (
    <div className="p-6 sm:!px-4">
      {showWarning && <CheckinLoginWarning data="personal data" />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
        className="flex flex-col ">
        <label className="!mb-4 text-base font-600">Basic Information</label>
        <div className="template grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          <Input
            id="f_name"
            label="First name"
            name="f_name"
            validateName
            placeholder="First Name"
            value={formik.values.f_name}
            disabled={!allDataEditable}
            error={formik.errors.f_name}
            onChange={handleChange}
          />

          <div
            id="photo"
            className="row-span-2 mt-[2rem] flex gap-5 pl-5 hover:cursor-pointer sm:order-[1] sm:pl-1 md:mt-[28px] md:!pl-3 xs:order-3 xs:pl-1 ">
            {profilePhoto && profilePhoto != '{"jpg":""}' && profilePhoto != '' ? (
              <img
                onClick={() => setImageEdit('front')}
                className="!aspect-1 !h-[5rem] !w-[5rem] cursor-pointer overflow-hidden rounded-full object-cover"
                src={imagePath(profilePhoto)}></img>
            ) : (
              <div
                onClick={() => setImageEdit('front')}
                className="!aspect-1 !h-[5rem] !w-[5rem]  ">
                <Icon icon="new-avatar" size={80} className="cursor-pointer" />
              </div>
            )}
            <div className="flex flex-col items-start justify-start gap-2">
              <p className="font-500 text-neutral-800">Upload Image</p>
              <p className="text-sm text-neutral-700">Min 400x400px, PNG or JPEG</p>
              <div
                onClick={() => setImageEdit('front')}
                className="cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white px-3 py-2 hover:bg-neutral-100">
                <p className="text-sm text-neutral-700">Upload</p>
              </div>
            </div>
          </div>
          <Input
            id="m_name"
            label="Middle name"
            type="text"
            validateName
            placeholder="Middle Name"
            name="m_name"
            value={formik.values.m_name}
            disabled={!allDataEditable}
            error={formik.errors.m_name}
            onChange={handleChange}
          />

          <Input
            id="l_name"
            label="Last name"
            type="text"
            validateName
            placeholder="Last Name"
            name="l_name"
            value={formik.values.l_name}
            disabled={!allDataEditable}
            error={formik.errors.l_name}
            onChange={handleChange}
          />
          <div className="order-[2]" id="gender">
            <Select
              label="Gender"
              placeholder="Type and select Gender"
              options={genderList}
              value={
                (formik.values.gender && {
                  label: snakeToTitleCase(formik.values.gender)
                }) ||
                null
              }
              onChange={(e) => formik.setFieldValue('gender', e?.value || null)}
            />
            {formik.errors.gender && (
              <span className="text-sm text-red-600">{formik.errors.gender}</span>
            )}
          </div>
        </div>

        <label className="!my-4 text-base font-600">Background Information</label>
        <div className="template grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          <DatePopover
            id="dob"
            name="dob"
            label="Date of birth"
            value={formik.values.dob}
            onChange={(date) => formik.setFieldValue('dob', date)}
            formik={formik}
            error={formik.errors.dob}
          />
          <Select
            label="Ethnicity"
            options={raceList}
            placeholder="Type and select Race"
            value={(formik.values.race && { label: formik.values.race }) || null}
            onChange={(e) => formik.setFieldValue('race', e?.value || null)}
            error={formik.errors.race}
          />
          <Input
            inputClassName="!min-w-[unset]"
            type={!decodedSSN ? 'password' : 'text'}
            label="Social Security Number"
            placeholder="Social Security Number"
            name="ssn"
            rightIcon={decodedSSN ? 'new-eye' : 'new-eye-slash'}
            value={decodedSSN ? formik.values?.ssn : '*'.repeat(formik.values?.ssn?.length)}
            disabled={
              !allDataEditable &&
              (currentPermissions?.secure?.update === false || decodedSSN === false)
            }
            onChange={handleChange}
            rightIconClick={() => setDecodedSSN(!decodedSSN)}
            error={formik.errors.ssn}
          />
          <Input
            id="personal_id"
            label="Personal ID"
            type="text"
            placeholder="Personal ID"
            name="personal_id"
            value={formik.values.personal_id}
            onChange={handleChange}
            error={formik.errors.personal_id}
          />
          <div className="col-span-2">
            <Select
              label="Marital Status"
              placeholder="Type and select Marital Status"
              options={maritalStatusList}
              value={
                (formik.values.marital_status && { label: formik.values.marital_status }) || null
              }
              onChange={(e) => formik.setFieldValue('marital_status', e?.value || null)}
              error={formik.errors.marital_status}
            />
          </div>
        </div>

        <label className="!my-4 text-base font-600">Contact Information</label>
        <div className="template grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          <div>
            <Input
              id="phone_contact"
              label="Phone number"
              type="text"
              placeholder="+1(123)4567890"
              name="phone"
              value={formik.values.phone}
              onChange={handleChange}
              error={formik.errors.phone}
              required={editableData.phoneEdit}
              disabled={!editableData.phoneEdit}
            />
          </div>

          <div className="flex flex-col gap-1">
            <Input
              data-qa="alt_phone"
              name="alt_phone"
              label="Alternative Phone"
              onChange={handleChange}
              placeholder="+1(321)4567890"
              value={formik?.values?.alt_phone}
              error={
                formik?.errors?.alt_phone &&
                formik?.errors?.alt_phone?.replace('alt_phone', 'Alternative Phone')
              }
            />
            {formik?.values?.alt_phone && (
              <Checkbox
                isChecked={formik.values.alt_phone_notify}
                handleClick={() =>
                  handleChange({
                    target: {
                      name: 'alt_phone_notify',
                      value: !formik?.values?.alt_phone_notify
                    }
                  })
                }
                label="Enable for notifications"
              />
            )}
          </div>

          <div className="col-span-2">
            <Input
              id="email"
              label="Email"
              type="text"
              placeholder="email@example.com"
              name="email"
              value={formik.values.email}
              error={formik.errors.email}
              onChange={handleChange}
              required={editableData.emailEdit}
              disabled={!editableData.emailEdit}
            />
          </div>

          <div className={cs('flex flex-col gap-2')}>
            <Input
              data-qa="alt_email"
              name="alt_email"
              label="Alternative Email"
              placeholder="alternative_email@example.com"
              onChange={handleChange}
              value={formik.values.alt_email}
              error={
                formik?.errors?.alt_email &&
                formik?.errors?.alt_email?.replace('alt_email', 'Alternative Email')
              }
            />
            {formik?.values?.alt_email && (
              <Checkbox
                isChecked={formik.values.alt_email_notify}
                handleClick={() =>
                  handleChange({
                    target: {
                      name: 'alt_email_notify',
                      value: !formik?.values?.alt_email_notify
                    }
                  })
                }
                label="Enable for notifications"
              />
            )}
          </div>
        </div>

        <label className="my-4 text-base font-600">Address</label>
        <div className="template grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          <div>
            <Input
              id="address_ln_1"
              label="Address"
              type="text"
              placeholder="Address"
              name="address_ln_1"
              value={formik.values?.address_ln_1}
              onChange={handleChange}
              required
              error={formik.errors?.address_ln_1}
            />
          </div>
          <div>
            <Input
              id="address_ln_2"
              label="Address 2"
              type="text"
              placeholder="Address 2"
              name="address_ln_2"
              value={formik.values?.address_ln_2}
              onChange={handleChange}
              error={formik.errors?.address_ln_2}
            />
          </div>
          <div>
            <Input
              id="city"
              label="City"
              type="text"
              placeholder="City"
              name="city"
              value={formik.values?.city}
              onChange={handleChange}
              required
              error={formik.errors.city}
            />
          </div>
          <div></div>
          <div>
            <Select
              id="state"
              options={stateList}
              placeholder="Type and select State"
              value={
                (formik.values.state && {
                  label: findStateName({ states: stateList, stateId: formik.values.state })
                }) ||
                null
              }
              label="State"
              onChange={(e) => formik.setFieldValue('state', e?.value || null)}
            />
            {formik.errors.state && (
              <span className="text-base text-red-600">{formik.errors.state}</span>
            )}
          </div>
          <div>
            <Input
              id="zip"
              label="Zip code"
              type="text"
              placeholder="Zip"
              name="zip"
              value={formik.values?.zip}
              onChange={handleChange}
              required
              error={formik.errors.zip}
            />
          </div>
        </div>

        <label className="my-4 text-base font-600">Emergency Contact</label>
        <div className="grid grid-cols-[repeat(auto-fit,minmax(264px,1fr))] gap-4">
          <div>
            <Input
              id="emergency_contact.name"
              label="Name"
              type="text"
              placeholder="Full name"
              name="emergency_contact.name"
              value={formik.values.emergency_contact?.name}
              onChange={(e) => formik.setFieldValue('emergency_contact.name', e.target.value)}
              error={formik.errors.emergency_contact?.name}
            />
          </div>
          <div>
            <Input
              id="emergency_contact.phon"
              label="Phone Number"
              type="text"
              placeholder="Phone Number"
              name="emergency_contact.phone"
              value={formik.values.emergency_contact?.phone}
              onChange={(e) => formik.setFieldValue('emergency_contact.phone', e.target.value)}
              error={formik.errors.emergency_contact?.phone}
            />
          </div>
          <div>
            <Input
              id="emergency_contact.relationship"
              label="Relationship"
              type="text"
              placeholder="Relation to patient"
              name="emergency_contact.relationship"
              value={formik.values.emergency_contact?.relationship}
              onChange={(e) =>
                formik.setFieldValue('emergency_contact.relationship', e.target.value)
              }
              error={formik.errors.emergency_contact?.relationship}
            />
          </div>
        </div>

        <div className="has-text-centered block p-3 pt-6">
          <button
            hidden
            onClick={() => {
              formik?.submitForm();
            }}
            ref={checkinButton}
            className="cursor-pointer justify-center whitespace-nowrap border border-solid border-[#dbdbdb] bg-success-500 px-4 py-2 text-center text-[#363636]">
            Continue
          </button>
        </div>
      </form>

      <Modal
        handleOpen={!!imageEdit}
        handleClose={() => setImageEdit(null)}
        className="h-full w-full !rounded-none bg-neutral-800"
        title="Add Image"
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {formik?.values.profile_photo && !loading
              ? 'Looks good?'
              : 'Take or upload a picture of yourself!'}
          </p>

          <ImagePicker
            name={imageEdit}
            defaultFacingMode="user"
            resetHandle={() => formik.setFieldValue('profile_photo', null, true)}
            handle={profilePhoto}
            onCapture={saveImage}
            handleContinue={() => setImageEdit(false)}
            kiosk={kiosk}
            removeOverlay
            noCrop
            dimensions={[1080, 1080]}
            loading={loading}
            handleDelete={() => formik.setFieldValue('profile_photo', null, true)}
          />
        </div>
      </Modal>
    </div>
  );
}
