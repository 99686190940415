import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const NameCell = (params) => {
  const fax = params?.data;

  if (!fax) return;

  const isRead = !!fax.read;
  return (
    <div className="flex flex-row">
      <Icon icon={isRead ? 'inbox-in' : 'inbox-in-active'} />
      <p className="ml-2">{fax?.name || 'Unnamed'}</p>
    </div>
  );
};
export default NameCell;
