import { socket } from 'api/Socket';

export function getSelectedForms(
  selectedPromptTagsIds,
  clinicalNote,
  selectedTableRows,
  currentHpOverviewData
) {
  const selectedForms = {};
  selectedPromptTagsIds.map((key) => {
    if (key === 'cpt-codes') {
      selectedForms[key] = clinicalNote?.['cpt_codes'];
    }
    if (key === 'diagnosis-codes') {
      selectedForms[key] = clinicalNote?.['icd_10_codes'];
    }
    if (key === 'treatment-plan') {
      selectedForms[key] = selectedTableRows?.['treatment_plan']?.data;
    }
    if (currentHpOverviewData?.[key] || clinicalNote?.[key]) {
      selectedForms[key] = currentHpOverviewData?.[key] || clinicalNote?.[key];
    }
    if (selectedTableRows?.[key]) {
      selectedForms[key] = selectedTableRows?.[key]?.data;
    }
  });
  return selectedForms;
}

export function stopResponseGeneration({ intelligenceChatId }) {
  socket.emit('user-message', {
    kind: 'myscribe-ai',
    message: {
      intelligenceChatId,
      kind: 'abort_task',
      isAbort: true
    }
  });
}
