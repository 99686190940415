import React from 'react';

export default function Submitted() {
  return (
    <div className="mx-auto mt-9 flex max-w-[450px] flex-col gap-4 !rounded-lg bg-neutral-100 p-4">
      <p className="text-2xl font-500 text-neutral-900">Thank you for contacting us!</p>
      <p className="text-sm font-400 text-neutral-800">
        One of our team members will reach out to you soon!
      </p>
    </div>
  );
}
