import React from 'react';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';

import TPModal from 'components/practice/charts/ClinicalNote/TreatmentPlan/TPModal';
import Button from 'components/shared/Buttons/Button';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';

const CreatePreviewTPView = ({ breadcrumb }) => {
  const { steps, setSteps, step, setStep } = breadcrumb;

  const { setSelectedTableRows, onAddComponent } = useChatContextApi();
  const { selectedTreatmentPlan } = useClinicalNoteContext();
  const { selectedRows } = useTableContext();

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  const previewTPModal = (date) => {
    setSteps((prev) => [
      ...prev.slice(0, steps.length - 1),
      {
        title: date.toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
          timeZoneName: 'short'
        }),
        name: 'preview'
      }
    ]);
  };

  const onEditTP = () => {
    setSteps((prev) => [...prev.slice(0, step), { title: 'Edit Treatment Plan', name: 'edit' }]);
  };

  const onAddTreatmentPlan = () => {
    setSelectedTableRows((prev) => ({
      ...prev,
      treatment_plan: {
        selectedNodes: selectedRows,
        data: selectedTreatmentPlan
      }
    }));
    onAddComponent();
  };

  return (
    <div className="z-50 mt-3 flex flex-col px-[230px] pb-[112px]">
      <TPModal
        loadView={steps[step]?.name}
        isModal={false}
        isOpen={true}
        handlePreview={previewTPModal}
        footer={({ submit, isSubmitting, disabled }) =>
          steps[step]?.name === 'create' ? (
            <ModalFooter
              rightButtons={
                <Button
                  disabled={disabled}
                  loading={isSubmitting}
                  text="Create Treatment Plan"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={() => submit({ nextView: steps[step]?.name })}
                />
              }
              leftButtons={
                <Button
                  text="Back"
                  outlined
                  transparent
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onGoBack}
                />
              }
            />
          ) : (
            <ModalFooter
              rightButtons={[
                <Button
                  key={1}
                  text="Edit"
                  icon="save-changes"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="!mr-2 !py-2 !pl-4 !pr-4"
                  onClick={onEditTP}
                  outlined
                />,
                <Button
                  key={2}
                  text="Add Treatment Plan"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onAddTreatmentPlan}
                />
              ]}
              leftButtons={
                <Button
                  text="Back"
                  outlined
                  transparent
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onGoBack}
                />
              }
            />
          )
        }
      />
    </div>
  );
};

export default CreatePreviewTPView;
