import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getDocumentHistory } from 'api/DocumentHistory';

import { previewSavedFax, resendFax } from '../../../../../../api/Fax';
import { ia } from '../../../../../../lib/helpers/utility';
import { showAlert } from '../../../../../shared/Alert/Alert';
import Button from '../../../../../shared/Buttons/Button';
import applyChangesToPDF from '../../../../../shared/DocumentEditor/lib/applyChangesToPDF';
import Icon from '../../../../../shared/Icon/Icon';
import Input from '../../../../../shared/Input/Input';
import Modal from '../../../../../shared/Modal/Modal';
import ContactsModal from '../../../Contacts/components/ContactsModal';

const ForwardFaxModal = ({
  forwardFaxModalVisible,
  setForwardFaxModalVisible,
  fax,
  refetchFaxes
}) => {
  const [faxNumber, setFaxNumber] = useState(fax?.fax_caller_id);
  const [error, setError] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [contactsModalVisible, setContactsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSelectContacts = (contacts) => {
    setContacts(contacts);
    setContactsModalVisible(false);
  };

  const handleClearContact = (contact) => {
    setContacts(contacts.filter((item) => item.id !== contact.id));
  };

  const handleResendFax = async () => {
    setLoading(true);
    let blob = null;
    const number = ia(contacts) ? contacts[0].fax_number : String(faxNumber)?.replace(/\D/g, '');

    if (!number) {
      setError('Please enter a valid fax number');
      setLoading(false);
      return;
    }

    const historyData = await getDocumentHistory(navigate, {
      kind: 'fax',
      id: fax.id
    });

    if (historyData.record) {
      const preview = await previewSavedFax(navigate, fax?.id, true);

      const appliedChange = await applyChangesToPDF({
        pdf: preview?.url,
        navigate,
        id: fax?.id,
        historyData,
        kind: 'fax'
      });
      blob = appliedChange.blob;
    }

    const formData = new FormData();
    formData.append('id', fax.id);
    formData.append('fax_numbers', [number]);

    if (blob) formData.append('files', blob, `file_${fax.id}.pdf`);

    await resendFax(navigate, formData).then(() =>
      refetchFaxes().then(() => {
        showAlert({
          color: 'success',
          message: `Fax ${number === faxNumber ? 'resent' : 'forwarded'} successfully!`
        });
      })
    );
    setLoading(false);
  };

  return (
    <Modal
      handleOpen={!!forwardFaxModalVisible}
      handleClose={() => setForwardFaxModalVisible(false)}
      title="Select a recipient"
      prevButton={<Icon size={32} icon="forward" color="primary" className="!mr-2" />}
      customStyling={{ width: '540px' }}
      headerClassName="bg-primary-50"
      bodyClassName="!p-0"
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setForwardFaxModalVisible(false)}
          />
          <Button
            data-qa="send-fax-btn"
            text="Send fax"
            onClick={handleResendFax}
            loading={loading}
          />
        </div>
      }>
      <div className="!p-6">
        <div className="flex items-center justify-between">
          <label htmlFor="">Recipient number</label>
          <label
            htmlFor=""
            className="cursor-pointer text-xs text-neutral-600"
            onClick={() => setContactsModalVisible(true)}>
            Search from contacts{' '}
            <span className="inline">
              <Icon
                icon="user-square"
                className="inline cursor-pointer"
                data-qa="search-from-contacts"
              />
            </span>
          </label>
        </div>
        <div className="flex w-full flex-col gap-2">
          {contacts.map((contact) => (
            <div
              key={contact.id}
              className="flex h-10 items-start justify-between rounded-md border border-solid border-neutral-100 bg-white !px-3 !py-2 shadow-sm">
              <p>{contact.first_name + ' ' + contact.last_name}</p>
              <Icon icon="cross" onClick={() => handleClearContact(contact)} className="mt-1" />
            </div>
          ))}
        </div>
        {!ia(contacts) && (
          <Input
            data-qa="fax-number"
            required
            placeholder="(500) 234-5689"
            hideNumberArrows
            onChange={(e) => {
              setError(null);
              setFaxNumber(e.target.value);
            }}
            name="fax_number"
            value={faxNumber}
            onBlur={() => {
              formatNumber(faxNumber, setFaxNumber);
            }}
            className="!mt-2"
            error={error}
          />
        )}
        {contactsModalVisible && (
          <ContactsModal
            {...{
              contactsModalVisible,
              setContactsModalVisible,
              handleSelectContacts,
              selectedContacts: contacts,
              selectOne: true
            }}
          />
        )}
      </div>
    </Modal>
  );
};

export default ForwardFaxModal;

function formatNumber(value, setValue) {
  const input = value;
  const numericValue = input?.replace(/[^+\d]/g, '');
  let formattedValue = '';

  if (numericValue?.length < 10) {
    setValue('');
    return;
  }
  if (numericValue?.startsWith('+1')) {
    // If it starts with '+1', remove the first two characters and then format
    formattedValue = `(${numericValue?.slice(2, 5)}) ${numericValue?.slice(
      5,
      8
    )}-${numericValue?.slice(8, 12)}`;
  } else if (numericValue?.length >= 10) {
    formattedValue = `(${numericValue?.slice(0, 3)}) ${numericValue?.slice(
      3,
      6
    )}-${numericValue?.slice(6, 10)}`;
  } else {
    formattedValue = numericValue;
  }

  setValue(formattedValue);
}
