import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { objMap } from 'lib/helpers/utility';
import { useFilters } from 'lib/hooks/queries/useFilters';

import Popover from '../Popovers/Popover/Popover';

import Content from './components/Content/Content';
import FilterButton from './components/FilterButton/FilterButton';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import {
  getFilterValues,
  handleAppliedFilter,
  handleDelete,
  handleMakeDefault,
  handleSave
} from './lib/helpers';

const Filter = ({
  filters,
  setFilters,
  category,
  defaultFilters,
  position = 'left',
  btnClassName,
  textClassName,
  menuPortalTarget,
  alwaysShow = false,
  isHeader = true,
  headerTitle = 'Filter',
  numberOfCols = 1
}) => {
  const [appliedDefault, setAppliedDefault] = useState(false);
  const [saveInput, setSaveInput] = useState('');
  const [saveButton, setSaveButton] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(null);
  const [count, setCount] = useState(0);

  const { data } = useFilters({
    params: { category },
    dependencies: [category],
    options: { keepPreviousData: true, staleTime: 5000 }
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { device } = useUIContext();

  useEffect(() => {
    if (data?.default?.filters && !appliedDefault) {
      setAppliedDefault(true);
      setAppliedFilter(data.default?.name);
    }
  }, [data?.default]);

  useEffect(() => {
    const countNumber = getFilterValues(filters).filter(Boolean).length;
    setCount(countNumber);
  }, [filters]);

  const setFilter = useCallback(
    (key, value) => {
      setFilters((prev) => ({
        ...prev,
        [key]: value
      }));
    },
    [setFilters]
  );

  const save = () =>
    handleSave(navigate, {
      category,
      saveInput,
      filters,
      setSaveInput,
      setSaveButton,
      queryClient
    });

  const makeDefault = (id, isDefault) =>
    handleMakeDefault(navigate, {
      id,
      category,
      isDefault,
      data,
      applyFilter,
      resetFilter,
      queryClient
    });

  const deleteFilter = (id) =>
    handleDelete(navigate, {
      id,
      data,
      appliedFilter,
      resetFilter,
      queryClient
    });

  function applyFilter(filter) {
    setFilters((prev) => handleAppliedFilter(prev, filter));
    setAppliedFilter(filter.name);
  }

  const resetFilter = () => {
    setFilters((prev) => {
      const newFilters = { ...prev };
      return objMap(newFilters, (key, f) => {
        const defaultValues = defaultFilters?.[key]?.values || null;
        return { ...f, values: defaultValues };
      });
    });
    setAppliedFilter(null);
  };

  return (
    <Popover
      isFixed
      position={position}
      panelClassName="min-w-[500px] max-w-[800px] !p-0"
      customButton
      customButtonTrigger={({ open }) => (
        <FilterButton
          isPopoverOpen={open}
          device={device}
          btnClassName={btnClassName}
          count={count}
          alwaysShow={alwaysShow}
          textClassName={textClassName}
        />
      )}>
      {({ closePopover }) => (
        <>
          <Header isHeader={isHeader} title={headerTitle} onXClick={closePopover} />
          <Content
            filters={filters}
            setFilter={setFilter}
            menuPortalTarget={menuPortalTarget}
            defaultFilters={defaultFilters}
            numberOfCols={numberOfCols}
          />
          <Footer
            saveButton={saveButton}
            setSaveButton={setSaveButton}
            saveInput={saveInput}
            setSaveInput={setSaveInput}
            save={save}
            appliedFilter={appliedFilter}
            filtersData={data?.filters}
            applyFilter={applyFilter}
            makeDefault={makeDefault}
            deleteFilter={deleteFilter}
            resetFilter={resetFilter}
          />
        </>
      )}
    </Popover>
  );
};

export default Filter;
