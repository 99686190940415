import React from 'react';
import { useNavigate } from 'react-router-dom';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { imagePath } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';

const { formatNumber } = require('../../fax/components/SendFax/lib');

const Form = ({ formik }) => {
  const navigate = useNavigate();

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      maxSize: 3 * 1024 * 1024
    };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };

    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    filestackClient.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      if (handle) {
        formik.setFieldValue('image', JSON.stringify(handle));
      }
    } catch (error) {
      showAlert({ message: "Image couldn't be uploaded", color: 'danger' });
    }
  };

  return (
    <div className="flex flex-col gap-[14px]">
      <div className="flex gap-4">
        <div className="flex flex-1 flex-col  gap-4">
          <Input
            name="first_name"
            label="First name"
            data-qa="contact-first-name"
            placeholder="Enter new contact first name"
            required
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.first_name}
            error={formik.touched.first_name && formik.errors.first_name}
          />
          <Input
            name="last_name"
            label="Last name"
            data-qa="contact-last-name"
            placeholder="Enter new contact last name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.last_name}
            error={formik.touched.last_name && formik.errors.last_name}
          />
        </div>
        <div className="flex flex-1 flex-col">
          <label className="m-0 !pr-4 pb-[6px] text-sm font-500 text-neutral-800">Image</label>
          <div
            onClick={openFilestackPicker}
            className={`flex h-auto flex-1 cursor-pointer flex-col items-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100 ${
              !formik.values.image && 'justify-center'
            }`}>
            <div className="flex flex-col items-center justify-center">
              {formik.values.image ? (
                <img
                  className="object-contain"
                  style={{ maxWidth: '49%' }}
                  src={imagePath(JSON.parse(formik.values.image).jpg)}
                  alt="Product Image"
                />
              ) : (
                <>
                  <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                  <p className="pt-1 text-center text-sm font-400 text-neutral-500">
                    Click here or drag and <span className="block">drop image to upload</span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Input
        name="email"
        label="Email"
        data-qa="contact-email"
        placeholder="Enter new contact email address"
        required
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.touched.email && formik.errors.email}
      />
      <Input
        name="fax_number"
        label="Fax Number"
        required
        data-qa="contact-fax_number"
        placeholder="(500) 234-5689"
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
          const formattedNumber = formatNumber(formik.values.fax_number, 0, () => {}, true);
          formik.setFieldValue('fax_number', formattedNumber);
        }}
        value={formik.values.fax_number}
        error={formik.touched.fax_number && formik.errors.fax_number}
      />
      <Input
        name="phone_number"
        label="Phone Number"
        required
        data-qa="contact-phone_number"
        placeholder="(500) 234-5689"
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);
          const formattedNumber = formatNumber(formik.values.phone_number, 0, () => {}, true);
          formik.setFieldValue('phone_number', formattedNumber);
        }}
        value={formik.values.phone_number}
        error={formik.touched.phone_number && formik.errors.phone_number}
      />
    </div>
  );
};

export default Form;
