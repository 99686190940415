import { iaRa } from 'lib/helpers/utility';

export const initialSelectedInvoice = (selectedRows) => {
  return iaRa(selectedRows).reduce((acc, invoice) => {
    acc[invoice?.id] = {
      internal_invoice_ids: invoice.internal_invoice_ids,
      patient: invoice.patient,
      due_date: new Date(),
      memo: '',
      amount_cents: iaRa(invoice?.procedures).reduce((acc, curr) => {
        const currCharge = curr?.charge || 0;
        acc += currCharge;
        return acc;
      }, 0),
      total_amount_cents: 0,
      discount_amount_cents: 0,
      tax_percentage: 0,
      tax_amount_cents: 0,
      surcharge_amount: 0,
      isDetailed: false
    };
    return acc;
  }, {});
};
