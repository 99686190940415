import { useQuery } from '@tanstack/react-query';
import { getPackages } from 'api/Packages';
import { useNavigate } from 'react-router-dom';

export const usePackages = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['packages', ...dependencies], () => getPackages(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
