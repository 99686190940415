import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Assessment from './Assessment';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const AssessmentSection = ({ components, options, sectionRef }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.assessment?.title}
      id={components?.assessment?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Assessment sectionRef={sectionRef} />
    </Accordion>
  );
};

export default AssessmentSection;
