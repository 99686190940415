import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useNavigate } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { deletePayer } from 'api/Payer';
import PayerModal from './PayerModal';
import useModal from 'lib/hooks/useModal';

const Actions = ({ data }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isOpen, openModal, closeModal } = useModal();

  const handleDeletePayer = async (payerId) => {
    try {
      let res = await deletePayer(navigate, { payerId });
      if (res.code === 0) {
        showAlert({ title: 'Success!', message: 'Payer deleted successfully!', color: 'success' });
        queryClient.invalidateQueries(['getPayers']);
        queryClient.invalidateQueries(['payers']);
      } else {
        showAlert({
          title: 'Delete Payer',
          message: res?.error ?? 'Something went wrong',
          color: 'danger'
        });
      }
    } catch (error) {
      showAlert({
        title: 'Delete Payer',
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }
  };

  const options = [
    {
      label: 'Edit',
      icon: 'new-edit',
      onClick: openModal
    },
    {
      label: 'Delete',
      icon: 'trash',
      onClick: () => handleDeletePayer(data?.id)
    }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {isOpen && <PayerModal payer={data} isOpen={isOpen} handleClose={closeModal} />}
    </>
  );
};

export default withErrorBoundary(Actions);
