import { useMemo } from 'react';

import debounce from 'lodash/debounce';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const FilterRange = ({ data, index, setFilter }) => {
  const debouncedSetFilter = useMemo(
    () => debounce((newData) => setFilter(index, newData), 500),
    [index, setFilter]
  );

  const handleChangeFrom = (fromValue) => {
    debouncedSetFilter({ ...data, values: { ...data.values, from: +fromValue } });
  };

  const handleChangeTo = (toValue) => {
    debouncedSetFilter({ ...data, values: { ...data.values, to: +toValue } });
  };

  return (
    <div className="flex cursor-pointer flex-col">
      <p className="!px-4 font-medium text-sm">{data?.title || 'Search'}</p>
      <div className="px-3">
        <div className="mt-2 flex items-center gap-2">
          <p className="font-medium whitespace-nowrap">Min</p>
          <CurrencyInput
            id="from_value"
            name="from_value"
            value={data.values?.from || 0}
            onValueChange={handleChangeFrom}
          />
          <p className="px-1 font-medium">-</p>
          <CurrencyInput
            id="to_value"
            name="to_value"
            value={data.values?.to || ''}
            onValueChange={handleChangeTo}
          />
          <p className="font-medium whitespace-nowrap">Max</p>
        </div>
      </div>
    </div>
  );
};

export default FilterRange;
