import React, { useState, useEffect } from 'react';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';
import { VERADIGM_USER_TYPES } from '../../constants';

const RoleDetails = () => {
  const { formik, setEnableNextTo } = useNewRoleContext();
  const [roleNameLength, setRoleNameLength] = useState(formik.values.role_name?.length);

  useEffect(() => {
    if (roleNameLength > 0) {
      setEnableNextTo(true);
    } else {
      setEnableNextTo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleNameLength]);

  const onSelectChange = (role) => {
    {
      if (role !== null) {
        formik.setFieldValue('role_type', role.value);
      } else {
        formik.setFieldValue('role_type', null);
      }
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="inline-flex w-full flex-col items-center gap-9 px-9 py-10">
        <div className="flex h-[436px] w-full flex-col items-center gap-9 rounded-md">
          <h2 className="!text-2xl font-medium leading-loose !text-[#13b8ff]">Create New Role</h2>
          <p className="-mt-6 text-center text-base font-medium leading-normal text-primary-900">
            Start by naming your role, to add more context write a description and pick a role type
            for your role.
          </p>

          <div className="w-full">
            <label className="flex w-full pb-1 text-sm font-medium leading-snug text-neutral-800">
              Role name*
            </label>
            <div className="inline-flex h-10 w-full items-center gap-3 rounded-md border border-neutral-200 bg-white">
              <Input
                id="role_name"
                name="role_name"
                placeholder="Enter role name"
                className="w-full text-sm font-normal leading-snug text-primary-900"
                labelClassName="p-0"
                value={formik.values.role_name}
                onChange={(e) => {
                  formik.handleChange(e);
                  setRoleNameLength(e.target.value.length);
                }}
                error={formik.touched.role_name && formik.errors.role_name}
              />
            </div>
          </div>

          <div className="w-full">
            <label className="flex w-full pb-1 text-sm font-medium leading-snug text-neutral-800">
              Role description
            </label>
            <div className="inline-flex w-full items-center gap-3 rounded-md border border-neutral-200 bg-white">
              <Input
                id="role_description"
                name="role_description"
                placeholder="Enter description for the role"
                className="w-full text-sm font-normal leading-snug text-primary-900"
                inputWrapperClassName="!min-h-[98px] !align-top !block"
                value={formik.values.role_description}
                onChange={formik.handleChange}
              />
            </div>
          </div>

          <Select
            name="role_type"
            label="E-Prescribe User Type"
            isClearable={true}
            value={VERADIGM_USER_TYPES.find((role) => role.value === formik.values.role_type)}
            parentClassName="w-full"
            id="serviceChargeTypeSelect"
            placeholder="Pick type of role"
            options={VERADIGM_USER_TYPES}
            onChange={(role) => onSelectChange(role)}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default RoleDetails;
