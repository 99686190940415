import React from 'react';

import ChartDocuments from '../../practice/charts/Documents/Container';

export default function PracticeDocuments() {
  return (
    <div className="h-full">
      <ChartDocuments fromPatient={true} />
    </div>
  );
}
