import cs from 'classnames';
import React from 'react';
import Icon from '../Icon/Icon';

const Breadcrumb = ({
  className,
  breadcrumb,
  hasBackButton,
  activeClassName = 'text-neutral-800'
}) => {
  return (
    <div
      className={cs('flex w-full items-center gap-2 overflow-x-auto overflow-y-hidden', className)}>
      {breadcrumb.steps.length > 0 && (
        <>
          {hasBackButton && breadcrumb.step > 0 && (
            <Icon
              className="cursor-pointer"
              onClick={() => breadcrumb.step > 0 && breadcrumb.setStep((s) => s - 1)}
              icon="new-arrow-left"
              color="neutral"></Icon>
          )}
          {breadcrumb.steps.map((s, index) => (
            <>
              <div
                key={index}
                data-qa={`breadcrumb-step-${index}`}
                className={cs(
                  index === breadcrumb.step ? activeClassName : 'text-neutral-600',
                  'cursor-pointer font-medium'
                )}
                onClick={() => {
                  breadcrumb.setStep(index);
                }}>
                {s?.title}
              </div>
              {index !== breadcrumb.steps.length - 1 && (
                <Icon stroke icon="new-chevron-right" color="neutral" />
              )}
            </>
          ))}
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
