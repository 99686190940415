import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { cols } from './helpers';
import AdminIntelligenceTasksTable from 'components/admin/pages/Intelligence/AiTask/table';
import AdminIntelligenceAiTaskModal from 'components/admin/pages/Intelligence/AiTask/modal';
import Allowed from 'components/shared/Permissions/Allowed';

const AdminIntelligenceAiTask = () => {
  return (
    <Allowed requiredPermissions="ai_tasks.read" showMessage showIllustration>
      <TableContextProvider
        name="admin_intelligence_ai_tasks_table"
        defaultFilters={[]}
        cols={cols}>
        <AdminIntelligenceTasksTable />
      </TableContextProvider>
      <AdminIntelligenceAiTaskModal />
    </Allowed>
  );
};

export default withErrorBoundary(AdminIntelligenceAiTask);
