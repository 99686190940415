import React, { useLayoutEffect, useRef, useState } from 'react';
import { tagColorOptions } from '../../lib/tagColorOptions';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import _ from 'lodash';

const DynamicView = ({ tags = [], parentRef = null, handleRemove = () => {} }) => {
  const containerRef = useRef(null);
  const [visibleTags, setVisibleTags] = useState([]);
  const [overflowTags, setOverflowTags] = useState([]);
  const [overflowCount, setOverflowCount] = useState(0);

  const calculateVisibleTags = () => {
    if (!containerRef.current || !parentRef?.current || tags.length === 0) return;

    const OVERFLOW_COUNTER_WIDTH = 35;
    const GAP_WIDTH = 6;
    const SAFETY_MARGIN = 8;
    const containerWidth = parentRef.current.offsetWidth - OVERFLOW_COUNTER_WIDTH;

    const tempDiv = document.createElement('div');
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.position = 'absolute';
    tempDiv.style.display = 'flex';
    tempDiv.style.gap = '4px';
    document.body.appendChild(tempDiv);

    let totalWidth = 0;
    let visibleCount = 0;

    // First calculate the overflow counter width if we have more than one tag
    if (tags.length > 1) totalWidth += OVERFLOW_COUNTER_WIDTH + SAFETY_MARGIN;

    // Try to fit tags while keeping space for overflow counter
    for (let i = 0; i < tags.length; i++) {
      const tag = tags[i];
      const tagStyle = tagColorOptions.find((option) => option.color === tag?.color);

      const tagElement = document.createElement('div');
      tagElement.className = 'flex items-center gap-[6px] rounded px-1 py-[2px]';

      if (tag.important) {
        const importantIcon = document.createElement('div');
        importantIcon.className = 'icon-important';
        importantIcon.style.width = '16px';
        importantIcon.style.marginRight = '4px';
        tagElement.appendChild(importantIcon);
      }

      const tagSpan = document.createElement('span');
      tagSpan.textContent = tag.name;
      tagSpan.style.backgroundColor = tag.color;
      tagSpan.style.border = `1px solid ${tagStyle?.border || 'transparent'}`;
      tagSpan.style.padding = '2px 4px';
      tagSpan.style.borderRadius = '4px';

      tagElement.appendChild(tagSpan);
      const removeIcon = document.createElement('div');
      removeIcon.className = 'icon-remove';
      removeIcon.style.width = '8px';
      removeIcon.style.marginLeft = '4px';
      tagElement.appendChild(removeIcon);

      tempDiv.appendChild(tagElement);

      const tagWidth = tagElement.offsetWidth;
      const widthWithGap = tagWidth + (i > 0 ? GAP_WIDTH : 0);

      // For the last visible tag, ensure we have enough space without overflow
      if (totalWidth + widthWithGap <= containerWidth) {
        totalWidth += widthWithGap;
        visibleCount++;
      } else break;
    }

    document.body.removeChild(tempDiv);

    // If we have more than one tag but could only fit one, show it with no overflow counter
    if (tags.length > 1 && visibleCount === 1) {
      const singleTagWidth = containerRef.current.children[0]?.offsetWidth || 0;
      if (singleTagWidth > containerWidth) visibleCount = 0;
    }

    const orderedVisibleTags = tags.sort((a, b) => b.important - a.important);
    const newVisibleTags = orderedVisibleTags.slice(0, visibleCount);
    const newOverflowTags = tags.slice(visibleCount);
    setVisibleTags(newVisibleTags);
    setOverflowCount(Math.max(0, tags.length - visibleCount));
    setOverflowTags(newOverflowTags);
  };

  useLayoutEffect(() => {
    calculateVisibleTags();

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleTags();
    });
    if (parentRef?.current) resizeObserver.observe(parentRef.current);

    return () => resizeObserver.disconnect();
  }, [tags, parentRef?.current]);

  return (
    <div ref={containerRef} className="flex grow items-center gap-1 overflow-hidden pl-3">
      {visibleTags.map((tag, index) => {
        const isNew = tagColorOptions.find((option) => option.color === tag?.color);
        const tagStyle = tagColorOptions.find((option) => option.color === tag?.color);
        return (
          <div
            key={index}
            className="flex items-center gap-[6px] rounded px-1 py-[2px]"
            title={tag?.name}
            style={{
              backgroundColor: tag?.color,
              border: `1px solid ${tagStyle?.border || 'transparent'}`
            }}>
            {tag?.important && (
              <Icon icon="new-info-circle" color="neutral" shade={900} size={16} />
            )}
            <span
              className={cs(
                'select-none truncate text-ellipsis text-sm font-400 leading-5',
                isNew ? 'text-neutral-900' : 'text-white'
              )}>
              {tag?.name}
            </span>
            <Icon
              icon="new-close-sm"
              size={8}
              color={isNew ? 'black' : 'white'}
              stroke
              onClick={() => handleRemove(tag)}
            />
          </div>
        );
      })}
      {overflowCount > 0 && (
        <Tippy
          appendTo={document.body}
          className="custom-tooltip !max-w-[150px] !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
          content={
            <div className="flex flex-col gap-[10px] overflow-y-auto overflow-x-hidden truncate !pr-2">
              {overflowTags?.map((tag, index) => {
                const isNew = tagColorOptions.find((option) => option.color === tag?.color);
                let currBorderColor = tagColorOptions.find(
                  (option) => option.color === tag?.color
                )?.border;
                return (
                  <Tippy content={tag?.name} className="tippy-dark text-base" key={index}>
                    <div
                      key={index}
                      className="flex max-w-max items-center gap-1 truncate rounded px-2 py-[2px]"
                      style={{
                        backgroundColor: tag?.color,
                        border: `1px solid ${currBorderColor || 'transparent'}`
                      }}>
                      {tag?.important && (
                        <Icon icon="new-info-circle" color="neutral" shade={900} />
                      )}
                      <span
                        className={cs(
                          'select-none truncate text-sm font-400 leading-5',
                          isNew ? 'text-neutral-900' : 'text-white'
                        )}>
                        {tag?.name}
                      </span>
                      <Icon
                        icon="new-close-sm"
                        size={8}
                        color="black"
                        stroke
                        onClick={() => handleRemove(tag)}
                      />
                    </div>
                  </Tippy>
                );
              })}
            </div>
          }
          placement="right"
          interactive>
          <span className="select-none gap-[6px] rounded border border-solid border-primary-200 bg-primary-100 px-2 py-[2px] text-sm font-400 leading-5 text-neutral-900">
            {`+${overflowCount}`}
          </span>
        </Tippy>
      )}
    </div>
  );
};

export default DynamicView;
