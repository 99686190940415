import moment from 'moment';
import React from 'react';

import { Capitalize, mString } from '../../../../../lib/helpers/utility';

const ExportTable = ({ appointments }) => {
  return (
    <table className="text-left">
      <thead>
        <tr>
          <th className="border border-neutral-800 pl-1">Datetime</th>
          <th className="border border-neutral-800 pl-1">Patient</th>
          <th className="border border-neutral-800 pl-1">Service</th>
          <th className="border border-neutral-800 pl-1">Balance</th>
          <th className="border border-neutral-800 pl-1">Provider</th>
          <th className="border border-neutral-800 pl-1">Status</th>
        </tr>
      </thead>
      <tbody>
        {appointments.map((appointment, i) => {
          const { starts_at, ends_at, patient, practitioner, status, invoice } = appointment || {};

          return (
            <tr key={i}>
              <td className="border border-neutral-800">
                {moment(starts_at).format('ddd MMM D, h:mm A')} - {moment(ends_at).format('h:mm A')}
              </td>
              <td className="border border-neutral-800">{patient?.fullName}</td>
              <td className="border border-neutral-800">
                {invoice?.procedures?.map?.((p) => p?.name).join(', ')}
              </td>
              <td className="border border-neutral-800">{mString(invoice?.balance || 0)}</td>
              <td className="border border-neutral-800">{practitioner?.fullName}</td>
              <td className="border border-neutral-800">
                {Capitalize(status === 'cancelled' ? 'canceled' : status)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ExportTable;
