import { DateRangePicker } from 'react-date-range';

import { endOfDay, startOfDay } from 'date-fns';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';
import Icon from 'components/shared/Icon/Icon';
import Popup from 'components/shared/Popup/Popup';

const FilterDateRange = ({ data, index, setFilter }) => {
  const startDate = data?.values?.startDate;
  const endDate = data?.values?.endDate;
  const currPractice = useRecoilValue(currentPractice);
  const timezone = currPractice?.timezone;

  const handleChangeDate = (e) => {
    const startDate = moment.tz(e.selection.startDate, timezone).format('YYYY-MM-DD');
    const endDate = moment.tz(e.selection.endDate, timezone).format('YYYY-MM-DD');
    setFilter(index, {
      ...data,
      values: {
        startDate,
        endDate
      }
    });
  };

  return (
    <Popup
      width={540}
      position={['left top', 'bottom center', 'top center']}
      trigger={
        <div className="flex cursor-pointer flex-col">
          <p className="text-sm font-medium pb-2">{data?.title || 'Date Range'}</p>
          <div className="mt-2 flex justify-between">
            {startDate && endDate ? (
              <p className="font-medium text-primary-900">
                {moment(startDate).format('MMM DD, YYYY')} -{' '}
                {moment(endDate).format('MMM DD, YYYY')}
              </p>
            ) : (
              <p className="text-sm text-neutral-500">No interval selected</p>
            )}
            <Icon color="primary" shade={900} icon="calendar" />
          </div>
          <div className="mt-1 border !border-t-0 border-solid border-neutral-200"></div>
        </div>
      }>
      <DateRangePicker
        className="!mt-2 w-[540px] overflow-hidden rounded-[10px] bg-white "
        onChange={handleChangeDate}
        ranges={[
          {
            startDate: startDate
              ? startOfDay(moment(data?.values?.startDate).toDate())
              : moment().toDate(),
            endDate: endDate ? endOfDay(moment(data?.values?.endDate).toDate()) : moment().toDate(),
            key: 'selection'
          }
        ]}
      />
    </Popup>
  );
};

export default FilterDateRange;
