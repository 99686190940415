import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ReactToPrint from 'react-to-print';

import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { currentPractice } from 'components/practice/practiceState';
import PrintView from 'components/Patient/quote/components/PrintView';
import InvoiceReadOnly from 'components/Payments/components/InvoiceReadOnly';
import Allowed from 'components/shared/Permissions/Allowed';

const AEOB = ({ invoice, patient, disabled }) => {
  const ref = useRef();
  const practice = useRecoilValue(currentPractice);

  const [isOpen, setIsOpen] = useState(false);

  const HeaderButton = (
    <div className="flex w-full justify-end">
      <ReactToPrint
        trigger={() => (
          <Button
            color=""
            size="small"
            type="link"
            text="Print"
            icon="new-printer-bulk"
            className="text-primary-900"
          />
        )}
        content={() => ref?.current}
      />
    </div>
  );

  return (
    <div>
      <Allowed requiredPermissions="invoicing.read">
        <Button
          outlined
          text="AEOB"
          size="small"
          disabled={disabled}
          iconRight="new-note-tick"
          className="!bg-transparent"
          onClick={() => setIsOpen(true)}
        />
      </Allowed>

      <Modal
        slideFromRight
        isOpen={isOpen}
        title="AEOB"
        bodyClassName="!p-0"
        isLarge
        headButton={HeaderButton}
        handleClose={() => setIsOpen(false)}>
        <div ref={ref}>
          <PrintView
            patient={patient}
            practice={practice}
            createdAt={invoice?.dateofservice}
            content={<InvoiceReadOnly invoices={[invoice]} />}
          />
        </div>
      </Modal>
    </div>
  );
};

export default AEOB;
