import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { requestApi } from '../../../api/Api';
import { ia } from '../../../lib/helpers/utility';
import { AlertContent } from '../../shared/Alert/Alert';
import Switch from '../../shared/Switch/Switch';
import { editingChoices, names } from './constants';
import state from './state';
import { withErrorBoundary } from '../../shared/Error/Boundary';

const PatientCheckinEditingSelector = () => {
  const { checkinButton, updateChoices, navigateToIncompleted } = useOutletContext();
  const [show, setShow] = useState({ error: '' });
  const [_selections, _setSelections] = useRecoilState(state.selectionState);
  const [selections, setSelections] = useState({
    personal_info: false,
    personal_iden: false,
    insurance: false
  });
  const { pathname } = useLocation();
  const userState = useRecoilValue(state.userState);
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname.includes('instant-packet')) {
      // this is an instant packet, and we don't want to check if changes are required
      _setSelections((ps) => ({
        ...ps,
        insurance: true,
        personal_iden: true,
        personal_info: true
      }));
      updateChoices([], [names.personalInfo, names.personalId, names.insurance]);
    } else if (_selections) {
      setSelections(_selections);
    }
  }, []);

  const submitChoices = async () => {
    const onSuccess = () => {
      let stepsToComplete = [];
      let stepsToEdit = [];

      let oneIsYes =
        selections?.personal_info || selections?.personal_iden || selections?.insurance;

      if (!selections?.personal_info) {
        stepsToComplete.push(names.personalInfo);
      } else {
        stepsToEdit.push(names.personalInfo);
      }
      if (!selections?.personal_iden) {
        stepsToComplete.push(names.personalId);
      } else {
        stepsToEdit.push(names.personalId);
      }
      if (!selections?.insurance) {
        stepsToComplete.push(names.insurance);
      } else {
        stepsToEdit.push(names.insurance);
      }

      if (oneIsYes && userState.not_logged_in) {
        localStorage.setItem('myriad_pl', pathname);
        localStorage.setItem('myriad_pl_timestamp', moment().toString());
        // navigate('/login');
      }

      _setSelections(selections);
      updateChoices(stepsToComplete, stepsToEdit);

      // if (stepsToUpdate.length === 0 && stepsToEdit.length === 0) {
      //   updateSteps({
      //     formName: [names.personalInfo, names.personalId, names.insurance],
      //     isCompleted: false
      //   });
      // } else {
      //   updateSteps({ formsCompleted: stepsToUpdate, isCompleted: true, formsToEdit: stepsToEdit });
      // }
    };
    const onError = (error, code, data) => {
      setShow((ps) => ({ ...ps, error: error }));
    };
    await requestApi({
      url: '/api/patient/checkin/record_choices',
      params: { selections: selections },
      navigate,
      onSuccess,
      onError
    });
  };

  return (
    <div className="p-[1rem]">
      {show.error && <AlertContent message={show.error} color="warning" />}
      <p className="mb-[1rem] text-xl font-600">Has anything changed for the sections below?</p>
      <div className="mt-[1rem] flex flex-col">
        {ia(editingChoices) &&
          editingChoices.map((v) => (
            <div
              className="mb-[0.8rem] flex w-[15rem] flex-row items-center justify-between"
              key={`choice-${v.id}`}>
              <div>{v.name}</div>
              <div className="ml-4 mr-4 flex flex-row">
                <Switch
                  checked={selections && !!selections[v.id]}
                  // key={`choice-switch-${v.id}`}
                  onChange={() => setSelections((ps) => ({ ...ps, [v.id]: !ps[v.id] }))}
                />
                <label
                  className={`${
                    selections && selections[v.id] ? 'text-primary-500' : 'text-neutral-600'
                  } ml-4`}>
                  {selections && selections[v.id] ? 'Yes' : 'No'}
                </label>
              </div>
              {selections && selections[`${v.id}_time`] && (
                <div className="italic text-neutral-500">{selections[`${v.id}_time`]}</div>
              )}
            </div>
          ))}
      </div>
      <div className="flow mt-auto">
        In order to insure HIPAA compliance, you will need to authenticate to view or change any
        past information.
      </div>
      <div ref={checkinButton} onClick={submitChoices} hidden></div>
    </div>
  );
};

export default withErrorBoundary(PatientCheckinEditingSelector);
