import { useParams } from 'react-router-dom';

import { useRecoilState } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

import { showAlert } from 'components/shared/Alert/Alert';
import { clinicalNote as clinicalNoteState } from 'components/state';

import useCreateTP from './useCreateTP';
import useUpdateTP from './useUpdateTP';

const useViewActions = ({
  navigate,
  handleClose,
  handlePreview,
  patient,
  loadEPNToCN,
  outsideCN
}) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const { selectedTreatmentPlan, setSelectedTreatmentPlan, setLoading, setLoadedTP, saveNoteRef } =
    useClinicalNoteContext();

  const { id } = useParams();

  const handleLoadClinicalNote = ({ treatmentPlan }) => {
    setLoading(true);

    const modifiedTP = {
      ...treatmentPlan,
      icd_10_codes: treatmentPlan.icd10,
      present_illness: treatmentPlan?.patient_needs,
      other_complaints: treatmentPlan?.problem_manifestation_definition
    };

    delete modifiedTP.id;
    delete modifiedTP.icd10;

    setClinicalNote({
      ...clinicalNote,
      ...modifiedTP,
      treatment_plan_id: treatmentPlan.id,
      user_id: treatmentPlan.patient_id
    });

    showAlert({ title: 'Loaded data successfully!' });
    setLoadedTP(treatmentPlan);

    setTimeout(() => {
      saveNoteRef.current?.onSaveNote();
      setLoading(false);
      handleClose();
    }, 300);
  };

  const { mutate: updateTP } = useUpdateTP({
    navigate,
    selectedTreatmentPlan,
    outsideCN
  });

  const { mutate: createTP, isLoading: createTPIsLoading } = useCreateTP({
    id,
    patient,
    selectedTreatmentPlan,
    setSelectedTreatmentPlan,
    clinicalNote,
    navigate,
    outsideCN
  });

  const defaultViewActions = {
    create: {
      action: () => {
        createTP(selectedTreatmentPlan);
        handlePreview(new Date());
      }
    },
    list: {
      action: ({ treatmentPlan }) => {
        if (treatmentPlan?.id && loadEPNToCN) {
          handleLoadClinicalNote({ treatmentPlan });
        }
      }
    },
    edit: {
      action: () => updateTP(selectedTreatmentPlan)
    },
    update: {
      action: () =>
        updateTP(selectedTreatmentPlan, {
          onSuccess({ code }) {
            if (code === 0)
              navigate(`/portal/charts/${clinicalNote?.user_id || id}/clinical-notes`);
          }
        })
    }
  };

  return {
    defaultViewActions,
    createTPIsLoading
  };
};

export default useViewActions;
