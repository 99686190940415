import { ia } from 'lib/helpers/utility';

import { amountToCents } from './amountToCents';

export const calculateAdjustmentPaid = ({
  procedureAdjustment = {},
  procedureCharge = 0,
  setPaidError = () => {}
}) => {
  if (!ia(procedureAdjustment?.adjustments)) return;
  const basePaid = amountToCents(procedureAdjustment?.paid);

  const totalPaid = procedureAdjustment.adjustments.reduce((acc, adjustment) => {
    const paid = amountToCents(adjustment?.amount);
    return acc + paid;
  }, basePaid);

  setPaidError(totalPaid > procedureCharge);
  return basePaid;
};
