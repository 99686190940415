import React from 'react';
import Modal from '../Modal';
import Icon from '../../Icon/Icon';
import Button from '../../Button/Button';
import Loading from '../../Loading/Loading';
import { AlertContent } from '../../Alert/Alert';
import HipaaForm from '../../Forms/HipaaForm/HipaaForm';

const HipaaFormModal = ({
  showHipaaModal,
  hideHipaaModal,
  hipaaForm,
  appendParentSelector = true
}) => {
  return (
    <Modal
      isLarge
      isOpen={showHipaaModal}
      handleClose={hideHipaaModal}
      title="HIPAA form"
      {...(appendParentSelector && {
        appendParentSelector: document.getElementById('AppointmentPreviewModal') || document
      })}
      slideFromRight
      subModal
      prevButton={
        <Button close onClick={hideHipaaModal}>
          <Icon icon="back-large" />
        </Button>
      }
      footer={<Button onClick={hideHipaaModal}>Close</Button>}>
      {!hipaaForm && <Loading />}
      {hipaaForm !== 'noHIPAA' && <div dangerouslySetInnerHTML={{ __html: hipaaForm }}></div>}
      {hipaaForm === 'noHIPAA' && (
        <div>
          <AlertContent
            className="mb-[1rem] w-full"
            color="warning"
            title="Warning"
            message="HIPAA form was not signed by the patient yet."
          />
          <HipaaForm disabled={true} />
        </div>
      )}
    </Modal>
  );
};

export default HipaaFormModal;
