import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { createPayer, updatePayer } from 'api/Payer';

import { optionify } from 'lib/helpers/utility';
import { usePayerAddress } from 'lib/hooks/queries/payers/usePayerAddress';
import { useStates } from 'lib/hooks/queries/states/useStates';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';
import { userState } from 'components/state';

import { PayerInitialValues, PayerValidationSchema } from '../lib/helper';

import PayerForm from './PayerForm';

const PayerModal = forwardRef(({ payer, isOpen, handleClose }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useRecoilValue(userState);

  const { data: statesData } = useStates({});
  const { data: addressData } = usePayerAddress({
    params: { payerId: payer?.id, withCount: true },
    dependencies: [payer?.id]
  });

  const address = addressData?.address || {};
  const states = optionify(statesData?.states || [], 'name', 'id');

  const formik = useFormik({
    initialValues: PayerInitialValues(payer, address),
    validationSchema: PayerValidationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      const newPayer = {
        address: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        name: values.name,
        phone: values.phone,
        type: values.type
      };

      await payerHandler(newPayer);
      setSubmitting(false);
    }
  });

  const payerHandler = async (newPayer) => {
    try {
      const response = payer
        ? await updatePayer(navigate, { payerId: payer.id, payer: newPayer })
        : await createPayer(navigate, { payer: newPayer, userId: user?.user_id });

      if (response.code === 0) {
        formik.resetForm();
        queryClient.invalidateQueries(['getPayers']);
        queryClient.invalidateQueries(['payers']);
        queryClient.invalidateQueries(['payerAddress']);
        handleClose();
        showAlert({
          title: 'Success!',
          message: 'Payer requested successfully!',
          color: 'success'
        });
      } else if (response.code === 2) {
        handleClose();
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create payer!",
          color: 'danger'
        });
      }
    } catch (error) {
      handleClose();
      showAlert({
        title: 'No permission!',
        message: error ?? "You're not authorized to create payer!",
        color: 'danger'
      });
    }
  };

  return (
    <Modal
      customStyling={{ width: '620px' }}
      bodyClassName="!p-4 !max-h-[unset] !h-full"
      handleOpen={isOpen}
      handleClose={() => handleClose()}
      title="Request New Payer"
      slideFromRight
      key={isOpen}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => handleClose()}
            id="cancelPayerModalBtn"
            data-qa="cancel-payer-btn"
          />
          <Button
            onClick={() => formik.handleSubmit()}
            text={payer ? 'Update' : 'Request payer'}
            id="savePayerModalBtn"
            data-qa="create-payer-btn"
          />
        </div>
      }>
      <PayerForm states={states} formik={formik} />
    </Modal>
  );
});

export default withErrorBoundary(PayerModal);
