import { ia } from 'lib/helpers/utility';

import Skeleton from 'components/shared/Skeleton/Skeleton';

import TestListItem from './TestListItem';

const TestList = ({ filters, testsDataLoading, tests, testsLoading, clickHandler }) => {
  return (
    <div className="order-4 flex flex-col rounded-lg border border-solid border-neutral-100 bg-[#f8fdff] overflow-y-auto min-h-[50vh] max-h-[70vh] xxl:min-h-[65vh] md:mb-2 lg:mb-2">
      <p className="border-0 border-solid border-b-[1px] border-neutral-100 p-3 bg-white  font-500 text-sm rounded-t-lg">
        {filters.searchTerm || filters.orderCodes ? 'Search Results' : 'Preferred Test List'}
      </p>
      <div className="p-1 overflow-y-auto">
        {testsDataLoading ? (
          <Skeleton count={15} height="36px" />
        ) : (
          <>
            {ia(tests)
              ? tests.map((test, index) => (
                  <TestListItem
                    key={test.orderCode}
                    clickHandler={clickHandler}
                    index={index}
                    test={test}
                    loading={testsLoading.includes(test.testID)}
                  />
                ))
              : null}
            {!ia(tests) ? (
              <div className="flex items-center justify-center h-full">
                <p className="!p-2 text-primary-900 text-center">
                  {filters.searchTerm || filters.orderCodes
                    ? 'No tests match your query.'
                    : 'Start searching to view available tests'}
                </p>
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default TestList;
