import React from 'react';
import cs from 'classnames';

const MaximumChars = ({ length, maxLength, disabled }) => {
  if (!maxLength) return null;

  return (
    <span
      className={cs(
        'absolute bottom-2 right-3 z-20 text-xs font-500',
        disabled
          ? 'text-neutral-400'
          : length === maxLength
            ? 'text-danger-600'
            : 'text-neutral-600'
      )}>
      {`${length} / ${maxLength}`}
    </span>
  );
};

export default MaximumChars;
