import React from 'react';
import EmptyTableState from '../../EmptyTableComponent';

const EmptyTreatmentPlan = ({ breadcrumb }) => {
  const onCreate = () => {
    breadcrumb.push({ title: 'New Treatment Plan', name: 'create' });
  };

  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="no-data-found" />
      <EmptyTableState.Text
        title="No Treatment Plan Found!!"
        description="Start creating one now by clicking the button below."
      />
      <EmptyTableState.Button onClick={onCreate} text="New Treatment Plan" />
    </EmptyTableState>
  );
};

export default EmptyTreatmentPlan;
