import React from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { withErrorBoundary } from '../Error/Boundary';

const TagsView = ({
  currTags,
  kind,
  itemsToShow,
  label = 'Tags',
  className,
  appendTo = 'parent'
}) => {
  const { data: loadedTags } = useTags({ params: { kind } });
  const tags = currTags ? currTags : loadedTags?.tags;

  return (
    <div className={cs('overflow-hidden', className)}>
      {label && <label className="mb-1">{label}</label>}
      <div className="item-center flex gap-2">
        <div
          className="grid items-center gap-2"
          style={{ gridTemplateColumns: `repeat(${itemsToShow},minmax(0,auto))` }}>
          {tags?.slice(0, itemsToShow).map((tag, index) =>
            tag.name.length > 13 ? (
              <Tippy content={tag.name} className="tippy-dark text-base" key={index}>
                <div
                  className="flex items-center gap-[6px] overflow-hidden text-ellipsis whitespace-nowrap rounded-[14px] px-2 py-[2px]"
                  style={{ backgroundColor: tag.color }}>
                  <span className="select-none overflow-hidden text-ellipsis text-sm font-400 leading-5 text-white">
                    {tag.name}
                  </span>
                </div>
              </Tippy>
            ) : (
              <div
                key={index}
                className="flex items-center gap-[6px] overflow-hidden text-ellipsis whitespace-nowrap rounded-[14px] px-2 py-[2px]"
                style={{ backgroundColor: tag.color }}>
                <span className="select-none overflow-hidden text-ellipsis text-sm font-400 leading-5 text-white">
                  {tag.name}
                </span>
              </div>
            )
          )}
        </div>
        {tags?.length > itemsToShow && (
          <Tippy
            appendTo={appendTo}
            className="custom-tooltip !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
            content={
              <div className="flex max-h-[350px] flex-col gap-[10px] overflow-y-auto overflow-x-hidden !pr-2">
                {tags?.slice(itemsToShow).map((tag, index) => (
                  <div
                    key={index}
                    className="flex w-max items-center gap-[6px] rounded-[14px] px-2 py-[2px]"
                    style={{ backgroundColor: tag.color }}>
                    <span className="select-none text-sm font-400 leading-5 text-white">
                      {tag.name}
                    </span>
                  </div>
                ))}
              </div>
            }
            placement="right"
            interactive>
            <span className="select-none rounded-[14px] bg-primary-50 px-2 py-[1px] text-sm font-400 leading-5 text-neutral-900">
              +{tags?.length - itemsToShow}
            </span>
          </Tippy>
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(TagsView);
