import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import usePageTitle from 'lib/hooks/usePageTitle';

import SecondarySidebar from 'components/Sidebars/SecondarySidebar/SecondarySidebar';
import { permissions } from 'components/state';

import { generatePracticeIQueueList } from './lib/generatePracticeIQueueList';

const PracticeIQueue = () => {
  usePageTitle('PracticeIQueue');
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { is_admin } = useRecoilValue(permissions);

  const practiceIQueueList = generatePracticeIQueueList(is_admin);

  useEffect(() => {
    if (pathname === '/portal/practice-i-queue')
      navigate('/portal/practice-i-queue/overview', { replace: true });
  }, [pathname]);

  return (
    <div className="flex h-full overflow-hidden bg-neutral-50" data-dd-privacy="allow">
      <SecondarySidebar list={practiceIQueueList} target="practiceIQueue" title="PRACTICEIQUEUE" />
      <div className="flex w-full flex-col">
        <Outlet />
      </div>
    </div>
  );
};

export default PracticeIQueue;
