import React from 'react';

const Default = () => {
  return (
    <div className="mx-auto my-auto flex h-fit w-fit flex-col justify-center overflow-hidden rounded-lg border-none bg-white p-2 transition duration-300">
      <div className="h-60vh w-50vw flex min-h-[20rem] min-w-[20rem] flex-col overflow-auto">
        <span className="py-1 text-xl font-bold">Patient Import</span>
        <span className="py-1">An error has occurred.</span>
      </div>
    </div>
  );
};

export default Default;
