import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';
import CreatableSelect from 'react-select/creatable';

import Header from '../shared/Header';
import Hint from '../shared/Hint';

import SelectWrapper from './components/SelectWrapper';
import { formatImageOption, getCommonSelectProps } from './lib/utils';

/**
 * @param {import('./lib/propTypes').SelectProps} props
 */

const Select = (props) => {
  const {
    isAsync,
    isCreatable,
    isImage,
    onChange,
    loadOptions,
    hint,
    hintIcon,
    error,
    disabled,
    parentClassName,
    isHeader,
    headerClassName,
    label,
    labelClassName,
    transcribing,
    additionalComponents,
    headerIcon,
    tooltip,
    isOptional,
    required,
    value,
    setDisabled,
    name,
    id
  } = props;

  if (isAsync && (!onChange || !loadOptions)) {
    return (
      <div className="grid !rounded border !border-danger-500 !p-2 font-500 text-danger-500">
        <p>Async props are missing!</p>
        <p>{__dirname}</p>
      </div>
    );
  }

  const renderSelect = () => {
    const commonProps = getCommonSelectProps(props);

    if (isCreatable && !isAsync) {
      return <CreatableSelect {...commonProps} />;
    }
    if (isAsync && !isCreatable) {
      return <AsyncSelect {...commonProps} />;
    }
    if (isAsync && isCreatable) {
      return (
        <SelectWrapper {...props}>
          <AsyncCreatable {...commonProps} />
        </SelectWrapper>
      );
    }

    const finalProps = {
      ...commonProps,
      ...(isImage && {
        formatOptionLabel: (props) => formatImageOption({ ...props, disabled })
      })
    };

    return (
      <SelectWrapper {...props}>
        <ReactSelect {...finalProps} />
      </SelectWrapper>
    );
  };

  return (
    <div className={parentClassName}>
      <Header
        isHeader={isHeader}
        headerClassName={headerClassName}
        label={label}
        labelClassName={labelClassName}
        transcribing={transcribing}
        additionalComponents={additionalComponents}
        icon={headerIcon}
        tooltip={tooltip}
        isOptional={isOptional}
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        setDisabled={setDisabled}
        name={name}
        id={id}
      />
      {renderSelect()}
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && <p className="pt-2 text-sm text-danger-500">{error}</p>}
    </div>
  );
};

export default Select;
