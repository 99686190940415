const validator = require('validator')

const validateNewReferral = (payload) => {
  const errors = {}
  let message = ''
  let isFormValid = true
  if (!payload || typeof payload.practice !== 'number') {
    isFormValid = false
    errors.practice = 'Please select a practice.'
  }

  if (!payload || typeof payload.body !== 'string' || payload.body.trim().length === 0) {
    isFormValid = false
    errors.body = 'Please provide a message.'
  }

  if (!isFormValid) {
    message = 'Check the form for errors.'
  }

  return {
    success: isFormValid,
    message,
    errors
  }
}

module.exports = {
  validateNewReferral: validateNewReferral
}
