import React from 'react';
import { Popup as LibPopup } from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

const Popup = ({
  onOpen,
  onClose,
  trigger = null,
  position,
  width,
  children,
  maxHeight,
  on,
  contentStyle,
  overlay = true,
  ...rest
}) => {
  return (
    <LibPopup
      onOpen={onOpen}
      onClose={onClose}
      trigger={trigger}
      position={position}
      overlayStyle={overlay ? { backgroundColor: 'rgba(0, 0, 0, 0.1)' } : null}
      arrowStyle={{ display: 'none' }}
      nested
      on={on}
      closeOnDocumentClick
      contentStyle={{
        width,
        maxWidth: '95%',
        maxHeight: maxHeight ? maxHeight : '95%',
        display: 'flex',
        flexDirection: 'column',
        boxShadow: '0px 2px 16px 0px #004F6B29',
        border: 'none',
        borderRadius: '10px',
        padding: 0,
        overflow: 'hidden',
        ...contentStyle
      }}
      {...rest}>
      {children}
    </LibPopup>
  );
};

export default Popup;
