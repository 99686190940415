import React from 'react';

const Highlight = ({ text, highlight }) => {
  if (!highlight) return text;
  if (!text) return null;

  const escapeRegExp = (string) => string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'gi'));

  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === highlight.toLowerCase() ? (
          <span key={index} className="text-primary-600 underline">
            {part}
          </span>
        ) : (
          part
        )
      )}
    </>
  );
};

export const sortHighlightMatches = (items, highlight) => {
  if (!highlight) return items;

  const lowerHighlight = highlight.toLowerCase();

  return items.sort((a, b) => {
    const aLabelIndex = (a.label || '').toLowerCase().indexOf(lowerHighlight);
    const bLabelIndex = (b.label || '').toLowerCase().indexOf(lowerHighlight);
    const aEmailIndex = (a.email || '').toLowerCase().indexOf(lowerHighlight);
    const bEmailIndex = (b.email || '').toLowerCase().indexOf(lowerHighlight);

    if (aLabelIndex === 0 && bLabelIndex !== 0) return -1;
    if (aLabelIndex !== 0 && bLabelIndex === 0) return 1;
    if (aLabelIndex === 0 && bLabelIndex === 0) {
      if (aEmailIndex === 0 && bEmailIndex !== 0) return -1;
      if (aEmailIndex !== 0 && bEmailIndex === 0) return 1;
    }
    if (aLabelIndex !== -1 && bLabelIndex === -1) return -1;
    if (aLabelIndex === -1 && bLabelIndex !== -1) return 1;
    if (aEmailIndex !== -1 && bEmailIndex === -1) return -1;
    if (aEmailIndex === -1 && bEmailIndex !== -1) return 1;
    if (aLabelIndex !== bLabelIndex) return aLabelIndex - bLabelIndex;

    return aEmailIndex - bEmailIndex;
  });
};

export default Highlight;
