import React from 'react';
import Accordion from '../../../../shared/Accordion';
import PatientNeeds from './PatientNeeds';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const PatientNeedsSection = ({ components, sectionRef, options }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.patientNeeds?.title}
      id={components?.patientNeeds?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <PatientNeeds sectionRef={sectionRef} />
    </Accordion>
  );
};

export default PatientNeedsSection;
