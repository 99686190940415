import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';

import PracticeUsers from './PracticeUsers';
import PortalUsers from './PortalUsers';
import InviteByEmail from './InviteByEmail';
import { showAlert } from 'components/shared/Alert/Alert';
import { createPracticeOwner } from 'api/Practice';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';

const SelectUserType = forwardRef(({ BackAction, setAddOwnerModalVisible }, ref) => {
  const selectedUserRef = useRef();
  const [view, setView] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const navigate = useNavigate();
  const { practice } = useOutletContext();
  const queryClient = useQueryClient();

  useImperativeHandle(ref, () => ({
    convertToOrganization:
      view === 'by-email' ? selectedUserRef.current?.inviteOwner : handleCreateOrganization
  }));

  const handleCreateOrganization = async () => {
    if (!selectedOwner) {
      showAlert({
        color: 'danger',
        message: 'Please select a user to convert to an organization!'
      });
      return;
    }
    const params = { practice_id: practice.id, user_id: selectedOwner?.id };
    const { code } = await createPracticeOwner(navigate, params);
    if (code === 0) {
      showAlert({
        color: 'success',
        message: 'Organization created successfully'
      });
      queryClient.invalidateQueries(['practice']);
      setAddOwnerModalVisible(false);
    }
  };

  return (
    <>
      {view ? (
        <div
          className="cursor-pointer text-primary-900 !ml-6 !mt-3"
          onClick={() => setView(null)}>
          Go Back
        </div>

      ) : (
        <>
          <div className="!p-6">
            <div
              className=" !p-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('practice')}>
              <h4 className="text-primary-600 font-500">Select owner from practice users</h4>
            </div>
            <div
              className=" !p-4 !mt-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('portal')}>
              <h4 className="text-primary-600 font-500">Select owner from all users</h4>
            </div>
            <div
              className=" !p-4 !mt-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('by-email')}>
              <h4 className="text-primary-600 font-500">Invite owner by email</h4>
            </div>
          </div>
        </>
      )}
      {view === 'practice' && <PracticeUsers {...{ setSelectedOwner, selectedOwner }} />}
      {view === 'portal' && <PortalUsers {...{ setSelectedOwner, selectedOwner }} />}
      {view === 'by-email' && <InviteByEmail ref={selectedUserRef} />}
    </>
  );
});

export default SelectUserType;
