import React from 'react';

import { mString } from 'lib/helpers/utility';

const PatientInvoice = ({ invoice, hasClaims, externalInvoice }) => {
  const {
    tax = 0,
    tax_amount_cents = 0,
    discount = 0,
    prevDiscount = 0,
    charge = 0,
    amount_paid = 0,
    ins_payment = 0,
    ins_adjustment = 0,
    surcharge_amount = 0,
    adjustment = 0,
    ptBalance = 0,
    totalAmount = 0
  } = invoice || {};

  const { type, amount_cents, total_amount_cents, aeob: isAEOB } = externalInvoice || {};

  const isKiosk = type === 'kiosk';

  let total =
    charge -
    amount_paid -
    ins_payment -
    ins_adjustment -
    prevDiscount +
    tax_amount_cents +
    tax -
    adjustment;

  if (!isAEOB) total = ptBalance;

  const amountDue = total - discount + surcharge_amount;

  return (
    <div className="mb-4 w-full bg-white px-8 pb-8">
      <div className="mx-3">
        <div className="mb-4">
          {isAEOB ? (
            <>
              <div className="mt-3 flex justify-between">
                <span>Billed</span>
                <span>{mString(charge)}</span>
              </div>

              {hasClaims && (
                <>
                  <div className="mt-3 flex justify-between">
                    <span>Insurance Covered</span>
                    <span>
                      {ins_payment > 0 ? '-' : ''}
                      {mString(ins_payment)}
                    </span>
                  </div>

                  <div className="mt-3 flex justify-between">
                    <span>Insurance Adjusted</span>
                    <span>
                      {ins_adjustment > 0 ? '-' : ''}
                      {mString(ins_adjustment)}
                    </span>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="mt-3 flex justify-between">
              <span>Billed</span>
              <span>{mString(totalAmount)}</span>
            </div>
          )}

          {amount_paid > 0 && (
            <div className="mt-3 flex justify-between">
              <span>You Paid</span>
              <span>-{mString(amount_paid)}</span>
            </div>
          )}

          {prevDiscount > 0 && (
            <div className="mt-3 flex justify-between">
              <span>Discount</span>
              <span>-{mString(prevDiscount)}</span>
            </div>
          )}

          {adjustment > 0 && (
            <div className="mt-3 flex justify-between">
              <span>Adjustment</span>
              <span>-{mString(adjustment)}</span>
            </div>
          )}

          {tax > 0 && (
            <div className="mt-3 flex justify-between">
              <span>Tax</span>
              <span>{mString(tax)}</span>
            </div>
          )}

          <hr className="my-8 h-px border-0 bg-gray-300" />

          {isKiosk && (
            <div className="flex justify-between border-t pt-3">
              <span className="">Subtotal</span>
              <span className="text-lg">{mString(total)}</span>
            </div>
          )}

          <div className="flex justify-between border-t pt-3">
            <span className="font-bold">{isKiosk ? 'Payment amount' : 'Subtotal'}</span>
            <span className="text-lg font-bold">{mString(isKiosk ? amount_cents : total)}</span>
          </div>

          {discount > 0 && (
            <div className="mt-2 flex justify-between">
              <span>Additional Discount</span>
              <span>-{mString(discount)}</span>
            </div>
          )}

          {surcharge_amount > 0 && (
            <div className="flex justify-between border-t pt-3">
              <span>Technology Fee</span>
              <span className="text-lg">{mString(surcharge_amount)}</span>
            </div>
          )}

          <div className="mt-3 flex justify-between border-t pt-3">
            <span className="font-bold">{isKiosk ? 'Total payment amount' : 'Amount Due'}</span>
            <span className="text-xl font-bold">
              {mString(isKiosk ? total_amount_cents : amountDue)}
            </span>
          </div>

          {isKiosk && total - amount_cents > 0 && (
            <div className="flex justify-between border-t pt-3">
              <span>Remaining balance</span>
              <span className="text-lg">{mString(total - amount_cents)}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientInvoice;
