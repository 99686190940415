import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getEobs } from 'api/EOB';

export const useEobs = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['eobs', ...dependencies], () => getEobs(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    ...options
  });
};
