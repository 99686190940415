import React from 'react';
import Button from '../../../shared/Buttons/Button';
import { requestApi } from 'api/Api';
import { useNavigate, useOutletContext } from 'react-router-dom';

const Complete = () => {
  const navigate = useNavigate();

  const { kiosk } = useOutletContext();

  const setKioskIdle = () => {
    requestApi({
      url: '/api/kiosk/command',
      onSuccess: () => {
        navigate('/kiosk/idle');
      },
      params: {
        command: 'idle'
      }
    });
  };

  return (
    <div className="max-w-screen-xl mx-auto flex items-center px-4 py-32 lg:h-screen">
      <div className="mx-auto max-w-3xl rounded-lg bg-neutral-50 !p-4 text-center">
        <p className="text-xl font-medium text-primary-700 sm:block sm:text-2xl">
          You are all set!
        </p>

        <div className="mt-8 flex flex-wrap justify-center gap-4">
          <Button
            data-qa="login"
            text={kiosk ? 'Finish' : 'Login to MyPortal'}
            onClick={() => (kiosk ? setKioskIdle() : navigate('/login'))}
          />
        </div>
      </div>
    </div>
  );
};

export default Complete;
