import React, { useState } from 'react';
import AssessmentSection from '../../../../CurrentNote/TypeNotes/HPNote/components/AssessmentSection';
import CPTCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import MedicationsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/MedicationsSection';
import PlanSection from '../../../../CurrentNote/TypeNotes/HPNote/components/PlanSection';
import VitalsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import OrdersSection from '../../../../Orders/OrdersSection';
import FormsAI from '../FormsAI';
import ComplaintsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ComplaintsSection';
import HPIRevisionsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/HPIRevisionsSection';
import ReviewOfSystems from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/ReviewOfSystems';
import AllergiesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AllergiesSection';
import VaccinesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VaccinesSection';
import WeightLossAdministrationSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/WeightLossAdministrationSection';
import SocialHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/SocialHistorySection';
import FamilyHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/FamilyHistorySection';
import MedicalHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/MedicalHistorySection';
import SurgicalHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/SurgicalHistorySection';
import ExamSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ExamSection';
import TestSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/TestSection';
import NotesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/NotesSection';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import AdvancedHP from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AdvancedHP';

const getFormsList = (advancedHP, cnDisplaySettings) => {
  const formsList = {
    complaints: ComplaintsSection,
    hpi: HPIRevisionsSection,
    ros: ReviewOfSystems,
    allergies: AllergiesSection,
    medicationHistory: MedicationsSection,
    vaccines: VaccinesSection,
    vitals: VitalsSection,
    weightLossAdministration: WeightLossAdministrationSection,
    socialHistory: SocialHistorySection,
    familyHistory: FamilyHistorySection,
    medicalHistory: MedicalHistorySection,
    surgicalHistory: SurgicalHistorySection,
    exam: ExamSection,
    test: TestSection,
    assessment: AssessmentSection,
    plan: PlanSection,
    'cpt-codes': CPTCodesSection,
    'diagnosis-codes': ICDCodesSection,
    notes: NotesSection,
    orders: OrdersSection,
    ...assignAdvTitlesToComponent(advancedHP, AdvancedHP, cnDisplaySettings)
  };
  return formsList;
};
const HPFormsAI = ({ inputName }) => {
  const { advancedHP, cnDisplaySettings } = useClinicalNoteContext();

  const [finalFormsList] = useState(getFormsList(advancedHP, cnDisplaySettings));

  return <FormsAI formsList={finalFormsList} inputName={inputName} />;
};

export default HPFormsAI;
