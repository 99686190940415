import moment from 'moment';

export const formatDates = (obj) => {
  const excludeKeys = [
    'modifiedCoPayment',
    'modifiedDeductible',
    'modifiedCoInsurance',
    'modifiedSelfPay',
    'tax'
  ];

  return Object.keys(obj).reduce((acc, key) => {
    if (!excludeKeys.includes(key) && moment(obj[key], moment.ISO_8601, true).isValid()) {
      acc[key] = moment(obj[key]).format('YYYY-MM-DDTHH:mm');
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
};
