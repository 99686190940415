import Icon from 'components/shared/Icon/Icon';
import { formatDate, isEmpty, stripHtmlTags } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React from 'react';
import { isDataReadOnly } from '../../../lib/aggregatedDataHelper';
import Actions from '../../components/FormActions';

const disableRow = (params, userPermissions) => {
  const isReadOnly = isDataReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: params?.data
  });

  return isReadOnly ? { 'pointer-events': 'none', opacity: '0.4' } : {};
};

export default (props) => [
  {
    field: 'vaccine',
    headerName: 'Vaccine',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      return (
        <div className="flex h-full w-full items-center gap-2">
          <Icon
            icon="new-document-preview"
            shade={600}
            className="cursor-pointer"
            color="neutral"
            onClick={() => {
              props.formik.setValues(params?.data);
              props.setShowFormModal(true);
            }}
          />

          <span className="truncate text-sm font-400 text-neutral-900">
            {params?.data?.vaccine?.label}
          </span>

          {params?.data?.createdBy?.isPatient && (
            <Icon icon="new-user-arrow-right" shade={600} color="neutral" />
          )}
        </div>
      );
    }
  },
  {
    field: 'doses',
    headerName: 'Doses taken',
    valueFormatter: ({ value }) => (!!value ? capitalize(value?.label) : 'N/A')
  },
  {
    field: 'lastDose',
    headerName: 'Date of last dose',
    valueFormatter: ({ data }) =>
      data?.notSure ? 'Not Sure' : formatDate(data?.lastDose, props?.practice?.timezone)
  },
  {
    field: 'note',
    headerName: 'Note',
    valueFormatter: ({ value }) => (isEmpty(stripHtmlTags(value)) ? 'N/A' : stripHtmlTags(value))
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: (params) => <Actions item={params?.data} {...props} />,
    maxWidth: 100,
    pinned: 'right',
    cellStyle: (params) => disableRow(params, props.userPermissions)
  }
];
