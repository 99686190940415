import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { archiveFaxes, deleteFax, unarchiveFaxes } from 'api/Fax';

import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';

import ForwardFaxModal from '../../components/ForwardFaxModal/ForwardFaxModal';
import InfoFaxModal from '../../components/InfoFaxModal/InfoFaxModal';
import RenameFaxModal from '../../components/RenameFaxModal/RenameFaxModal';
import useFaxActions from '../../lib/useFaxActions';

export const FaxActions = ({ data, queryKey, type, setPreviewFax }) => {
  const fax = data || {};

  const [forwardFaxModalVisible, setForwardFaxModalVisible] = useState(false);
  const [infoFaxModalVisible, setInfoFaxModalVisible] = useState(false);
  const [renameFaxModalVisible, setRenameFaxModalVisible] = useState(false);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { handleFaxDownload, handleFaxPrint } = useFaxActions({ fax });

  if (!data) return null;

  const refetchFaxes = () => queryClient.invalidateQueries([queryKey]);

  const handleArchive = () => {
    archiveFaxes(navigate, [fax.id]).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax archived' });
      })
    );
  };

  const handleUnarchive = () => {
    unarchiveFaxes(navigate, { id: fax.id }).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax unarchive' });
      })
    );
  };

  const handlePreview = () => {
    setPreviewFax(fax);
  };

  const handleDelete = () => {
    deleteFax(navigate, fax.id).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax deleted' });
      })
    );
  };

  const handleRename = () => {
    setRenameFaxModalVisible(true);
  };

  const handleArchiving = () => {
    type == 'archived' ? handleUnarchive() : handleArchive();
  };

  const handleForwardResend = () => {
    setForwardFaxModalVisible(true);
  };

  const handleInfo = () => {
    setInfoFaxModalVisible(true);
  };

  const options = [
    { label: 'Preview', onClick: handlePreview, icon: 'play-circle-small' },
    { label: 'Print', onClick: handleFaxPrint, icon: 'new-printer' },
    { label: 'Download', onClick: handleFaxDownload, icon: 'new-import' },
    {
      label: type == 'archived' ? 'Unarchive' : 'Archive',
      onClick: handleArchiving,
      icon: 'archive-small'
    },
    { label: 'Forward/Resend', onClick: handleForwardResend, icon: 'forward' },
    { label: 'Info', onClick: handleInfo, icon: 'new-info' },
    { label: 'Delete', onClick: handleDelete, icon: 'trash' },
    { label: 'Rename', onClick: handleRename, icon: 'new-edit' }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {forwardFaxModalVisible && (
        <ForwardFaxModal
          {...{ forwardFaxModalVisible, setForwardFaxModalVisible, fax, refetchFaxes }}
        />
      )}
      {infoFaxModalVisible && (
        <InfoFaxModal {...{ infoFaxModalVisible, setInfoFaxModalVisible, fax }} />
      )}
      {renameFaxModalVisible && (
        <RenameFaxModal
          {...{
            renameFaxModalVisible,
            setRenameFaxModalVisible,
            id: fax.id,
            fax_name: fax.name,
            refetchFaxes
          }}
        />
      )}
    </>
  );
};

export default FaxActions;
