import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Button from 'components/shared/Buttons/Button';
import useComponentAdd from '../../hooks/useComponentAdd';
import useChatInput from '../../hooks/useChatInput';
import { useMyScribeTemplateContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useRecoilValue } from 'recoil';
import { intelligenceAiState } from 'components/state';
import Transcribe from 'components/shared/Transcribe/Transcribe';

export function ChatFooter() {
  const { inputTags: tags, selectedTableRows, onTagRemove, onTagPreview } = useComponentAdd();
  const { inputValue, disableGenerate, onTextChange } = useChatInput();
  const { setCurrentActivePrompt, setUserPrompt } = useMyScribeTemplateContextApi();
  const { onEnhanceNote } = useChatContextApi();
  const intelligenceAi = useRecoilValue(intelligenceAiState);

  const [inputTags, setInputTags] = useState([]);

  useEffect(() => {
    setInputTags(tags);
  }, [tags]);

  const onGenerate = () => {
    setUserPrompt(inputValue);
    onTextChange({ target: { value: '' } });
    setCurrentActivePrompt((ps) => ({ ...ps, response: '', userPrompt: inputValue }));
    setInputTags([]);
    onEnhanceNote();
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  const onStopGeneration = () => {
    // Stop generation mid-way
  };

  return (
    <>
      <AIChatInput
        isHTML
        className="mb-3 min-h-[124px] border-2 !border-neutral-200 shadow-none outline outline-1 outline-neutral-200"
        leftButtons={[]}
        rightButtons={[
          // <Button
          //   key={1}
          //   onClick={() => {}}
          //   icon="new-microphone"
          //   iconSize={18}
          //   className="z-30 mx-2 !bg-white !px-[15px]"
          //   color="neutral"
          // />,
          <div className="mb-1 mr-2" key={1}>
            <Transcribe
              value={inputValue}
              setValue={(text) =>
                onTextChange({
                  target: {
                    value: text,
                    name
                  }
                })
              }
              disabled={false}
              setDisable={() => {}}
            />
          </div>,
          !intelligenceAi?.loading ? (
            <Button
              key={2}
              icon="sent"
              iconSize={18}
              iconColor="white"
              size="small"
              className={cs('outline-0', disableGenerate ? 'bg-neutral-100' : 'bg-primary-500')}
              onClick={onGenerate}
              disabled={disableGenerate}
              type="secondary"
              color={disableGenerate ? 'neutral' : 'white'}
            />
          ) : (
            <Button
              key={2}
              icon="new-pause"
              iconSize={18}
              iconColor="white"
              size="small"
              className="outline-0"
              outlined
              onClick={onStopGeneration}
              type="secondary"
            />
          )
        ]}
        onTextChange={onTextChange}
        onSubmit={onGenerate}
        placeholder="Type anything.."
        value={inputValue}
        tags={inputTags}
        onTagRemove={onTagRemove}
        onTagPreview={onTagPreview}
        getTagClassName={getTagClassName}
      />
      <p className="text-center text-xs italic text-neutral-700">
        AI can make mistakes. Please review & understand providers are responsible for final
        clinical note.
      </p>
    </>
  );
}
