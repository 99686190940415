import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useInvoices } from 'lib/hooks/queries/billing/useInvoices';

import ButtonActions from 'components/practice/BeyondBilling/ElectronicInvoicing/components/ButtonActions';
import InvoiceStepModal from 'components/practice/BeyondBilling/ElectronicInvoicing/components/InvoiceStepModal';
import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import { DEFAULT_FILTERS, getColDefs } from './configs';

const PatientInvoices = ({ patient_id }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const cols = getColDefs(practiceTimezone);
  const { id } = useParams();
  const patientId = id || patient_id;

  return (
    <AgTablePage
      name="invoices"
      ModalComponent={InvoiceStepModal}
      defaultFilters={DEFAULT_FILTERS}
      useData={useInvoices}
      queryParams={{ user_id: patientId, unpaid: false }}
      defaultColumns={cols}
      actions={ButtonActions}
      rowStyle={{ cursor: 'pointer' }}
      noTitle
      noNewButton
    />
  );
};

export default PatientInvoices;
