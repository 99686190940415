import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import state from './state';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Icon from '../../shared/Icon/Icon';
import Input from '../../shared/Input/Input';
import Button from '../../shared/Buttons/Button';
import { requestApi } from '../../../api/Api';
import getData from './getData';

const UserActivateNewPhone = () => {
  const [user, setUser] = useRecoilState(state.userState);
  const [practice, setPractice] = useRecoilState(state.practiceState);
  const [progress, setProgress] = useRecoilState(state.progressState);
  const [phone, setPhone] = useState('');
  const [show, setShow] = useState({ loading: false });
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getData({ navigate, location, setPractice, setShow, setUser });
  }, []);

  const start = () => {
    const onSuccess = (data, res) => {
      setUser((ps) => ({ ...ps, mfaId: res?.mfaId }));
      navigate(res?.redirect);
    };
    requestApi({
      url: '/api/user/mfa/start',
      params: {
        kind: 'sms',
        data: phone
      },
      onSuccess,
      navigate,
      filePath: `${__dirname}/${__filename}`
    });
  };

  return (
    <div>
      <div className="my-4 w-full text-lg text-primary-500">
        Step {progress.current}/{progress.total}
      </div>
      <div className="w-full text-3xl font-500 text-primary-900">Enter Phone Number</div>
      <p className="mt-10 w-full text-neutral-700">
        We are happy to have you at {practice?.name ? practice?.name : 'our practice'}! Please enter
        a strong password to continue your account registration.
      </p>
      <Input
        type="phone"
        value={phone}
        placeholder={'(***)***-****'}
        onChange={(e) => setPhone(e.target.value)}
      />
      <Button
        text="Send code"
        onClick={start}
        color="primary"
        className={'mt-4 w-full'}
        disabled={phone.length < 10}
      />
    </div>
  );
};

export default UserActivateNewPhone;
