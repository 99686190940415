export const getColDefs = () => [
  {
    field: 'name',
    headerName: 'Form',
    cellClass: 'no-border',
    sort: 'desc',
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'formTypeName',
    headerName: 'Type'
  }
];

export const DEFAULT_COLUMN_DEFS = { flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};
