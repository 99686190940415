import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from 'api/Api';
import { eps } from 'constants';
import state from 'components/state';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { showAlert } from 'components/shared/Alert/Alert';

const LicenseEdit = ({
  license: loadedLicense,
  setLicense: updateHandler,
  onCancel,
  practitionerId
}) => {
  const [show, setShow] = useState({
    errors: [],
    loading: false,
    selectedIssuer: null,
    selectedLicenseKind: null
  });
  const [loading, setLoading] = useState(false);
  const [license, setLicense] = useState({
    practitioner_id: practitionerId,
    license_number: '',
    issuer_id: 0,
    expired_on: moment(),
    kind: '',
    license_id: 0,
    state_id: 0
  });
  const { options: issuers, map: issuersMap } = useRecoilValue(state.issuerState);
  const navigate = useNavigate();

  useEffect(() => {
    if (loadedLicense && loadedLicense.id) {
      // Edit existing license
      setLicense({ ...license, ...loadedLicense });
    } else {
      // Add new license
      setLicense({
        practitioner_id: practitionerId,
        license_number: '',
        issuer_id: 0,
        expired_on: moment(),
        kind: '',
        license_id: 0,
        state_id: 0
      });
      setShow({
        errors: [],
        loading: false,
        selectedIssuer: null,
        selectedLicenseKind: null
      });
    }
  }, [loadedLicense, practitionerId]);

  useEffect(() => {
    if (loadedLicense && loadedLicense.id) {
      let newSelectedIssuer =
        issuers.find((issuer) => issuer.value === loadedLicense.issuer_id) || null;
      let newLicenseKind = eps.licenseTypes.find((type) => type.value === loadedLicense.kind) || {
        value: 'StateLicense',
        label: 'State License'
      };
      setShow({ ...show, selectedIssuer: newSelectedIssuer, selectedLicenseKind: newLicenseKind });
    }
  }, [issuers, loadedLicense]);

  const updateLicense = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const onSuccess = (data) => {
        let updatedLicense = { ...license };
        if (data.licenseId) {
          // A new license was inserted
          updatedLicense['id'] = data.licenseId;
        }
        updateHandler(updatedLicense);
        showAlert({
          title: data.licenseId ? 'License added successfully' : 'License updated successfully',
          color: 'success'
        });
      };
      const onError = (error) => {
        setShow({
          ...show,
          loading: false,
          errors: ['Failed to update, please try again later']
        });
        showAlert({
          title: error || 'Failed to update, please try again later',
          color: 'danger'
        });
      };

      await requestApi({
        url: '/api/practitioner/license/upsert',
        params: {
          practitionerId: practitionerId,
          license: { ...license, practitioner_id: practitionerId }
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      showAlert({
        title: 'There has been an unexpected error. Please try again later.',
        color: 'danger'
      });
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col space-y-4">
      <p>License Issuer</p>
      <Select
        options={issuers}
        value={show.selectedIssuer}
        onChange={(v) => {
          let newLicense = { ...license, issuer_id: v?.value };
          let newShow = { ...show, selectedIssuer: v };
          newLicense['state_id'] = issuersMap[v?.value]?.state_id;
          if (v?.value === 1) {
            newLicense['kind'] = eps?.licenseTypes[0]?.value;
            newLicense['license_id'] = 1;
            newShow['selectedLicenseKind'] = eps?.licenseTypes[0];
          } else {
            newLicense['kind'] = 'StateLicense';
            newLicense['license_id'] = 2;
          }
          setShow(newShow);
          setLicense(newLicense);
        }}
      />

      {license.issuer_id === 1 && (
        <Select
          options={eps.licenseTypes}
          value={show.selectedLicenseKind}
          onChange={(v) => {
            setShow({ ...show, selectedLicenseKind: v });
            setLicense({ ...license, kind: v?.value });
          }}
          className="m-0 w-40"
          placeholder="DEA License Kind"
        />
      )}
      <p>Please omit any special characters.</p>
      <Input
        value={license.license_number}
        onChange={(e) =>
          setLicense({ ...license, license_number: e.target.value.replace(/[^a-z0-9]/gi, '') })
        }
        placeholderText="License Number"
      />
      <p>License Expiration Date</p>
      <ReactDatePicker
        placeholderText="License Expiration Date"
        selected={moment(license.expired_on).toDate()}
        onChange={(v) => setLicense({ ...license, expired_on: moment(v) })}
        dateFormat="MM/dd/yyyy"
        yearDropdownItemNumber={100}
        scrollableYearDropdown={true}
        showYearDropdown
      />
      <div className="flex w-full justify-end">
        <Button text="Cancel" color="neutral" outlined onClick={onCancel} />
        <Button text="Save" loading={show.loading} className="ml-3" onClick={updateLicense} />
      </div>
    </div>
  );
};

export default LicenseEdit;
