import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';
import Popup from 'components/shared/Popup/Popup';

import FilterItem from './FilterItem';

const FiltersList = ({ filtersData, applyFilter, makeDefault, deleteFilter }) => (
  <Popup
    trigger={(open) => (
      <div
        className={cs(
          'flex cursor-pointer justify-between !px-[12px] py-[6px] hover:bg-primary-50',
          open && 'bg-primary-50'
        )}>
        <div className="flex gap-[6px] text-sm text-primary-900">
          <Icon icon="history-filled" className="cursor-pointer" data-qa="saved-filters" />
          Saved filters
        </div>
        <Icon
          icon="new-chevron-right"
          color="neutral"
          stroke
          shade={400}
          className="cursor-pointer"
        />
      </div>
    )}
    width={200}
    contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
    offsetX={-10}
    on={['hover', 'focus']}
    position={['right center']}>
    <div className="flex !w-[200px] flex-col py-[6px]">
      {filtersData?.length > 0 ? (
        filtersData?.map((filter, index) => (
          <FilterItem
            key={index}
            filter={filter}
            index={index}
            applyFilter={applyFilter}
            makeDefault={makeDefault}
            deleteFilter={deleteFilter}
          />
        ))
      ) : (
        <div
          className="flex cursor-pointer !px-3 py-[6px] text-sm text-neutral-500"
          data-qa="no-saved-filters">
          No saved filters
        </div>
      )}
    </div>
  </Popup>
);

export default FiltersList;
