import React from 'react';
import cs from 'classnames';

import { ia } from '../../../../../lib/helpers/utility';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import Skeleton from '../../../../shared/Skeleton/Skeleton';

const FormPacketAppointment = ({ loading, formPackets, setFormPackets }) => {
  const toggleExpand = (index) => {
    setFormPackets((prevData) =>
      prevData.map((obj, i) => {
        if (i === index) {
          return {
            ...obj,
            expanded: !obj.expanded || false
          };
        }
        return obj;
      })
    );
  };

  const updateFormPacket = ({ formPacketId, formId, checkboxValue }) => {
    let updateAllForms = false,
      specificFormPacket = [];
    if (formId === undefined) {
      updateAllForms = true;
      specificFormPacket = formPackets
        .find((fP) => fP.id === formPacketId)
        .form_ids.map((form) => form.value);
    }
    setFormPackets(
      formPackets.map((packet) => {
        const { form_ids } = packet;
        const updatedPacket = {
          ...packet,
          form_ids: form_ids.map((form) => {
            if (form.value === formId) return { ...form, checked: checkboxValue };

            if (updateAllForms) {
              const doesSameIdExistHere = specificFormPacket.includes(form.value);
              return doesSameIdExistHere ? { ...form, checked: checkboxValue } : form;
            }
            return form;
          })
        };

        return updatedPacket;
      })
    );
  };

  if (loading) {
    return <Skeleton count={5} width="100%" height="30px" />;
  }

  return (
    <div>
      {ia(formPackets) ? (
        <div className=" rounded-lg border border-solid border-neutral-100 !p-4 shadow-[0px_0px_8px_0px__rgba(0,79,107,0.08)]">
          <label className="mb-1.5 text-sm font-500 text-neutral-800">Form Packets</label>

          <div className="flex flex-col !gap-3">
            {formPackets.map((formPacket, index) => {
              const { form_ids, expanded } = formPacket;

              return (
                <Checkbox
                  isDropdown
                  className="w-full"
                  inputClassName={cs(
                    'h-10 !py-2 !px-3 rounded !border border-solid border-transparent bg-neutral-100',
                    form_ids.every((form) => form?.checked) && '!border-primary-500 bg-primary-50'
                  )}
                  id={index}
                  key={index}
                  label={formPacket?.name}
                  expanded={expanded}
                  setExpanded={() => toggleExpand(index)}
                  handleClick={(e) =>
                    updateFormPacket({
                      formPacketId: formPacket.id,
                      checkboxValue: e.target.checked
                    })
                  }
                  isChecked={form_ids.every((form) => form?.checked)}>
                  {form_ids.map((form, index) => {
                    return (
                      <Checkbox
                        key={index}
                        label={form?.label}
                        id={index}
                        handleClick={(e) => {
                          updateFormPacket({
                            formPacketId: formPacket.id,
                            formId: form.value,
                            checkboxValue: e.target.checked
                          });
                        }}
                        isChecked={form?.checked}
                        className={cs(
                          form?.checked && '!border-primary-500 bg-primary-50',
                          'h-10 w-full rounded !border border-solid border-transparent bg-neutral-100 !px-3 !py-2 text-sm'
                        )}
                      />
                    );
                  })}
                </Checkbox>
              );
            })}
          </div>
        </div>
      ) : (
        <div>No Form Packets.</div>
      )}
    </div>
  );
};

export default FormPacketAppointment;
