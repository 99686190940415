import { useState } from 'react';

import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';

const FilterMultipleSearch = ({
  data,
  index,
  setFilter,
  placeholder = 'Type for search',
  icon = true,
  menuPortalTarget
}) => {
  const [input, setInput] = useState('');

  const handleInputChange = (value) => setInput(value);

  const handleChange = (selectedOptions) => {
    // Extract only the necessary value (fullName) for display
    const values = selectedOptions.map((option) => ({
      ...option,
      displayLabel: option.label
    }));
    setFilter(index, { ...data, values });
  };

  const formatOptionLabel = (option, { context }) => {
    if (context === 'value') {
      return option.label;
    }
    return option.customLabel || option.label;
  };

  const filterOption = (option, searchInput) =>
    option.label?.toLowerCase().includes(searchInput.toLowerCase());

  return (
    <div className="flex cursor-pointer flex-col">
      <p className="text-sm font-medium pb-2">{data?.title || 'Search'}</p>
      <div className="relative flex justify-between pl-[10px] mr-3">
        <Select
          isAsync={data.loadOptions}
          options={data?.options}
          parentClassName="w-full"
          id={`search-${data.title?.toLowerCase() || 'search'}`}
          inputValue={input}
          noDropdown
          loadOptions={
            data.loadOptions
              ? async (searchTerm, callback) => {
                  setInput(searchTerm);
                  const result = await data.loadOptions(searchTerm);
                  callback(result);
                }
              : null
          }
          value={data.values}
          onInputChange={handleInputChange}
          placeholder={placeholder}
          isClearable={false}
          styles={{
            control: {
              outline: '0',
              border: 'none',
              padding: '0',
              minHeight: '1.5rem',
              boxShadow: '0'
            },
            valueContainer: {
              padding: '0'
            }
          }}
          onChange={handleChange}
          isMulti
          menuPortalTarget={menuPortalTarget}
          formatOptionLabel={formatOptionLabel}
          filterOption={filterOption}
        />
        {icon && (
          <div className="z-100 absolute bottom-0 right-0 top-0 mr-[2px] flex">
            <Icon color="neutral" shade={600} stroke icon="new-search" />
          </div>
        )}
      </div>
      <div className="mt-1 border !border-t-0 border-solid border-neutral-200"></div>
    </div>
  );
};

export default FilterMultipleSearch;
