import React from 'react';

import Confirm from 'components/shared/Modal/Confirm/Confirm';

const AddDuplicateTestConfirm = ({ open, closeHandler, continueHandler, testName }) => {
  return (
    <Confirm
      handleOpen={open}
      title="Confirm Duplicate Test"
      message={`${testName} is a duplicate test and will be added accordingly.`}
      handleClose={closeHandler}
      handleContinue={continueHandler}
    />
  );
};

export default AddDuplicateTestConfirm;
