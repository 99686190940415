import { requestApi } from 'api/Api';
import PrintViewHeader from 'components/Patient/quote/components/PrintViewHeader';
import AppointmentInvoices from 'components/Payments/components/AppointmentInvoices';
import Icon from 'components/shared/Icon/Icon';
import { ia, mString } from 'lib/helpers/utility';
import { orderBy, pick } from 'lodash';
import React, { useEffect, useState } from 'react';

const Invoices = ({ appointment }) => {
  const [amount, setAmount] = useState(0);
  const defaultInvoice = {
    ...appointment?.invoice,
    selected: true,
    expanded: true,
    discountAmount: appointment?.invoice?.discount?.amount_cents || 0,
    appointment: pick(appointment, ['amount', 'practitioner_id', 'starts_at'])
  };

  const hasInvoice = appointment?.status !== 'cancelled' && appointment?.invoice?.id ? true : false;
  const [invoices, setInvoices] = useState(hasInvoice ? [defaultInvoice] : []);

  const [temporarySurchargeDisable, setTemporarySurchargeDisable] = useState(false);
  const [extraCredit, setExtraCredit] = useState(0);

  // const addTotals = () => {
  //   setInvoices((invoice) => {
  //     return invoice.map((i) => ({ ...i, selected: true, expanded: false }));
  //   });
  // };

  const calculateTotalAmount = (list) => {
    let newTotal = list.reduce((total, invoice) => {
      return total + Number(invoice?.balance);
    }, 0);

    setAmount((newTotal || 0) + extraCredit);
  };

  const addTotals = async () => {
    const onSuccess = (data) => {
      const { invoices: loadedInvoices } = data;

      if (ia(loadedInvoices, -1)) {
        const filtered = loadedInvoices
          .filter((i) => i?.appointment_id !== appointment?.id)
          .map((i) => ({
            ...i,
            expanded: false,
            selected: true,
            discountAmount: i?.discount?.amount_cents
          }));

        const orderedData = orderBy(
          filtered,
          [(item) => (item.appointment ? item.appointment.starts_at : item.created_at)],
          'desc'
        );

        const orderedInvoices = [...invoices, ...orderedData];
        setInvoices(orderedInvoices);
        calculateTotalAmount(orderedInvoices);
      }
    };
    const onError = () => {};

    requestApi({
      url: '/api/appointment/invoices/get',
      params: { user_id: appointment?.patient?.id },
      onSuccess,
      onError
      // navigate
    });
  };

  return (
    <div className="border-bottom-0 h-full w-full overflow-hidden rounded-t-3xl border !border-x-0 !border-t-2 !border-primary-600 ">
      <PrintViewHeader practice={appointment.practice} patient={appointment.patient} />
      {/* <div className="flex flex-wrap items-center gap-2 bg-[#F5FCFF] !px-5 !py-3 sm:justify-between">
        <div className="flex gap-2">
          <Icon icon="new-info" color="primary" />
          <p className="text-primary-600">You have unpaid invoices with a total of</p>
        </div>
        <p className="rounded-full bg-primary-50 !px-3 !py-1 font-medium text-primary-600">
          {mString(amount)}
        </p>
        <div
          onClick={() => addTotals()}
          className="cursor-pointer rounded-full border !border-primary-200 bg-white !px-3 py-4 text-sm font-medium text-primary-500 hover:!bg-primary-50 sm:!w-full sm:text-center">
          Add unpaid total to today’s payment
        </div>
      </div> */}

      <AppointmentInvoices
        invoices={invoices}
        patientId={appointment.patient.id}
        hasInvoice={hasInvoice}
        appointment={appointment}
        setInvoices={setInvoices}
        show={true}
        calculateTotalAmount={calculateTotalAmount}
        setTemporarySurchargeDisable={setTemporarySurchargeDisable}
        amountInputDisabled={true}
        showUnpaidButton={false}
      />
    </div>
  );
};

export default Invoices;
