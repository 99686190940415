import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';

import { updateGuarantor as updateGuarantorReq } from 'api/PatientGuarantor';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import GuarantorForms from './components/GuarantorForms';
import { GuarantorValidationSchema } from './lib/helpers';

const EditGuarantor = ({ guarantor, index, hideEditGuarantor, updateGuarantor, states }) => {
  const { device } = useUIContext();
  const editGuarantor = useRef();

  const navigate = useNavigate();

  const isTablet = device === 'tablet';

  const { mutateAsync: updateGuarantorFn, isLoading: loading } = useCustomMutation({
    mutationFn: (updatedObject) =>
      updateGuarantorReq(
        {
          id: guarantor.id,
          updatedObject
        },
        navigate
      ),
    successMessage: 'Successfully updated guarantor!',
    onSuccess: (data) => {
      if (data?.updated) {
        updateGuarantor(data?.updated, index);
      }

      hideEditGuarantor();
    }
  });

  const handleEnterPreventDefault = (event) => {
    if ((event.charCode || event.keyCode) === 13) {
      event.preventDefault();
    }
  };

  const formik = useFormik({
    initialValues: {
      f_name: guarantor.f_name,
      l_name: guarantor.l_name,
      m_name: guarantor.m_name,
      email: guarantor.email,
      gender: guarantor.gender,
      dob: guarantor.dob,
      home_phone: guarantor.home_phone,
      work_phone: guarantor.work_phone,
      relationship: guarantor.relationship,
      address: guarantor?.address,
      guarantorAddress: {
        address_ln_1: guarantor?.guarantorAddress?.address_ln_1,
        address_ln_2: guarantor?.guarantorAddress?.address_ln_2,
        city: guarantor?.guarantorAddress?.city,
        state: guarantor?.guarantorAddress?.state,
        zip: guarantor?.guarantorAddress?.zip
      }
    },
    enableReinitialize: true,
    validationSchema: GuarantorValidationSchema,
    onSubmit: async (updatedGuarantor) => {
      await updateGuarantorFn(updatedGuarantor);
    }
  });

  const handleModalClose = () => {
    hideEditGuarantor();
  };

  return (
    <>
      <Modal
        isOpen={true}
        handleClose={handleModalClose}
        className={isTablet ? 'h-full w-full !rounded-none' : 'w-[612px] !rounded-none'}
        title="Edit Guarantor"
        slideFromRight={!isTablet}
        isFooter={!isTablet}
        headButton={
          isTablet && (
            <div className="ml-auto mr-3 flex gap-2">
              <Button
                data-qa="create-insurance-btn"
                onClick={() => formik.submitForm()}
                forwardedRef={editGuarantor}
                loading={loading}
                size="small"
                text="Update"
              />
            </div>
          )
        }
        footer={
          !isTablet && (
            <div className="flex w-full justify-between">
              {device !== 'mobile' && (
                <Button
                  color="neutral"
                  size="small"
                  outlined
                  onClick={handleModalClose}
                  text="Cancel"
                />
              )}
              <Button
                data-qa="create-insurance-btn"
                onClick={() => formik.submitForm()}
                forwardedRef={editGuarantor}
                loading={loading}
                size="small"
                disabled={!formik.isValid}
                text="Update"
              />
            </div>
          )
        }>
        <form
          onSubmit={formik.handleSubmit}
          onKeyPress={handleEnterPreventDefault}
          className={!isTablet && 'grid gap-4'}>
          <GuarantorForms states={states} formik={formik} isTablet={isTablet} />
        </form>
      </Modal>
    </>
  );
};

export default EditGuarantor;
