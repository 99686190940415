import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import Navbar from '../../navbars/PatientNavbar/PatientNavbar';
import Contact from './contact';
import Submitted from './submitted';
const FormValidators = require('../../validation/validate_contact');
const validateCreation = FormValidators.validateCreation;

const NewContactContainer = () => {
  const [contact, setContact] = useState({
    sender_name: '',
    sender_email: '',
    subject: '',
    body: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    sender_name: {
      success: false,
      message: ''
    },
    sender_email: {
      success: false,
      message: ''
    },
    subject: {
      success: false,
      message: ''
    },
    body: {
      success: false,
      message: ''
    }
  });
  const navigate = useNavigate();

  const handleChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setContact({
      ...contact,
      [field]: value
    });
  };

  const submitContact = async () => {
    try {
      let params = {
        contact: contact
      };
      let res = await interimApi('/api/admin/contact/create', params, navigate);
      if (res.status == 200) {
        setSubmitted(true);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later. `);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let payload = validateCreation(contact);
    if (payload.success) {
      setErrors({
        sender_name: {
          success: false,
          message: ''
        },
        sender_email: {
          success: false,
          message: ''
        },
        subject: {
          success: false,
          message: ''
        },
        body: {
          success: false,
          message: ''
        }
      });
      submitContact(contact);
    } else {
      const errors = payload.errors;
      setErrors({
        sender_name: {
          success: false,
          message: errors.sender_name
        },
        sender_email: {
          success: false,
          message: errors.sender_email
        },
        subject: {
          success: false,
          message: errors.subject
        },
        body: {
          success: false,
          message: errors.body
        }
      });
    }
  };
  return (
    <div>
      <Navbar />
      <div>
        {!submitted ? (
          <div>
            <Contact
              onSubmit={validateForm}
              contact={contact}
              onChange={handleChange}
              errors={errors}
            />
          </div>
        ) : (
          <div>
            <Submitted />
          </div>
        )}
      </div>
    </div>
  );
};

export default NewContactContainer;
