import React, { useEffect, useState } from 'react';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import MyscribeTranscript from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeAI/components/MyScribeTranscript';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { useLocation } from 'react-router-dom';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Button from 'components/shared/Buttons/Button';
import Transcription from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/MyScribeAI/components/Transcription';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';
import { removeBracketsInText, removeHtmlTags } from 'lib/helpers/utility';
import { sanitize } from 'lib/helpers/sanitize';
import { copyTranscript } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';

const TranscriptionView = ({ onGoBack = () => {} }) => {
  const { loading, transcriptData, actionRef } = useAmbientListeningContext();
  const { breadcrumb } = useMyScribeAIContext();
  const { pathname } = useLocation();
  const { breadcrumbs, pushBreadcrumb, popBreadcrumb } = breadcrumb;
  const [isTranscriptionConfirmed, setIsTranscriptionConfirmed] = useState(false);
  const transcriptSummary = removeBracketsInText(transcriptData?.[0]?.summary);

  useEffect(() => {
    if (!breadcrumbs?.length || loading) return;

    pushBreadcrumb({
      label: 'Review & assign',
      name: '/review-and-assign',
      path: pathname
    });

    return () => {
      if (breadcrumbs[breadcrumbs.length - 1]?.name === '/review-and-assign') popBreadcrumb();
    };
  }, [breadcrumbs]);

  if (isTranscriptionConfirmed) {
    const onEditTranscript = () => {
      setIsTranscriptionConfirmed(false);
    };

    const onSaveTranscript = () => {
      actionRef?.current?.upsert();
    };

    const onCopyTranscript = () => {
      copyTranscript(transcriptData);
    };

    const onCopySummary = () => {
      const stripHTMLTags = removeHtmlTags(transcriptData?.[0]?.summary);
      const removeBracketsText = removeBracketsInText(stripHTMLTags);

      navigator.clipboard
        .writeText(removeBracketsText)
        .then(() =>
          showAlert({
            title: 'Copy Summary',
            message: 'Summary copied to clipboard',
            color: 'success'
          })
        )
        .catch(() =>
          showAlert({
            title: 'Copy Summary',
            message: 'Failed to copy Summary',
            color: 'error'
          })
        );
    };
    return (
      <div className="h-full overflow-hidden ">
        <div className="flex h-full ">
          <div className="flex-1 overflow-y-auto  pb-[70px] pl-5 pt-3">
            <Transcription isModal={false} />
          </div>
          <div className="p w-[356px] overflow-y-auto px-5 pb-[70px] pt-5">
            <div className="flex items-center justify-between">
              <p className="text-lg font-medium">Summary</p>
              <Icon
                onClick={onCopySummary}
                size={28}
                icon="new-copy"
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
              />
            </div>
            <div
              className="!mt-3 text-sm font-medium text-primary-900"
              dangerouslySetInnerHTML={sanitize(transcriptSummary)}
            />
          </div>
        </div>

        <AIChatInput
          className="footer-blurred absolute bottom-[20px] left-1/2 mx-auto !w-1/2 -translate-x-1/2 transform items-center pb-2"
          rightButtons={[
            <Button
              key={1}
              icon="new-copy"
              iconSize={22}
              iconColor="white"
              className="!py-2 !pl-4 !pr-4"
              outlined
              onClick={onCopyTranscript}
            />,
            <Button
              key={1}
              icon="new-edit-pen"
              iconSize={18}
              iconColor="white"
              size="small"
              className="ml-2 !py-2 !pl-4 !pr-4"
              outlined
              onClick={onEditTranscript}
            />,
            <Button
              key={2}
              text="Save & Close"
              icon="save-changes"
              iconSize={18}
              iconColor="white"
              size="small"
              className="mx-2 !py-2 !pl-4 !pr-4"
              outlined
              onClick={onSaveTranscript}
            />,
            <SignAndLock
              key={3}
              buttonProps={{
                className: '!py-2 !pl-4 !pr-4 bg-primary-500',
                textClassName: 'text-white',
                transparent: false,
                primary: true
              }}
            />
          ]}
          isInput={false}
          onGoBack={onGoBack}
        />
      </div>
    );
  }

  const onConfirm = () => {
    setIsTranscriptionConfirmed(true);
  };

  return (
    <div className="flex flex-col justify-center p-4 pb-[120px]">
      <MyscribeTranscript />
      <div className="px-auto absolute bottom-[50px] z-50 flex w-1/2 justify-center self-center">
        <AIChatInput
          className="footer-blurred items-center pb-2"
          rightButtons={[
            <Button
              key={2}
              text="Confirm & Generate Transcription"
              icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onConfirm}
            />
          ]}
          onGoBack={onGoBack}
          isInput={false}
        />
      </div>
    </div>
  );
};

export default TranscriptionView;
