const atom = require('recoil').atom;

module.exports = {
  current_admin: atom({
    key: 'current_admin',
    default: {
      user_id: '',
      f_name: '',
      l_name: '',
      email: '',
      state: '',
      role: '',
      logged_in: false
    }
  })
};
