import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { isEqual } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Checkbox from '../../../Checkbox/Checkbox';
import Icon from '../../../Icon/Icon';
import Textarea from '../../../Textarea/Textarea';
import '../Custom.scss';
import { PastMedicalHistory as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView, submitForm } from '../lib/customFormsHelper';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { createPastMedicalHistoryNarrative } from '../lib/pastMedicalHistoryNarrativeHelper';
import Header from '../components/Header';
import Container from '../components/Container';
import Row from '../components/Row';
import SelectableButton from '../components/SelectableButton';
import { useRecoilValue } from 'recoil';
import { intelligenceAiState } from 'components/state';
import { iaRa } from 'lib/helpers/utility';

const PastMedicalHistory = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  formId = null,
  showNarrative = false,
  fromClinicalNote = false,
  onChange,
  setItemModal = () => {},
  options = []
}) => {
  const [pastMedicalHistory, setPastMedicalHistory] = useState(initialValue);
  const [prevPastMedicalHistory, setPrevPastMedicalHistory] = useState(initialValue);
  const chronicNoteRef = useRef(null);
  const surgeriesNoteRef = useRef(null);
  const hospitalizationNoteRef = useRef(null);
  const allergiesNoteRef = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const location = useLocation();
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const fromCheckin = location?.pathname.includes('/checkin');

  const { cnDisplaySettings, setOverviewData, setCurrentHpOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const [syncNarrative, setSyncNarrative] = useState(true);
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.medicalHistory?.advancedForm
      : true;
  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setPastMedicalHistory(JSON.parse(defaultValue));
      } else {
        setPastMedicalHistory(defaultValue);
      }
    } else {
      setPastMedicalHistory(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(pastMedicalHistory);
  }, [pastMedicalHistory]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setPastMedicalHistory;
    }
  }, []);

  useEffect(() => {
    if (
      pastMedicalHistory.narrative !== intelligenceAi.response &&
      intelligenceAi?.path?.includes?.('medicalHistory') &&
      intelligenceAi.response !== ''
    ) {
      setPastMedicalHistory((ps) => ({
        ...ps,
        narrative: intelligenceAi.response ?? ps?.narrative
      }));
    }
  }, [intelligenceAi]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'PastMedicalHistory', type: 'custom' } });
    }
  };

  const syncPastMedicalHistoryAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ pastMedicalHistoryObject: updatedObject });
    } else {
      setPastMedicalHistory(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const handleChange = ({ event, key, parentKey, childKey, isBoolean }) => {
    const name = event?.target?.name;
    const value = event?.target?.value;

    const updatedObject = {
      ...pastMedicalHistory
    };

    if (parentKey && childKey) {
      updatedObject[key] = {
        ...updatedObject[key],
        [name ?? childKey]: name ? (isBoolean ? !JSON.parse(value) : value) : event
      };
    } else {
      updatedObject[name ?? (childKey || key)] = name
        ? isBoolean
          ? !JSON.parse(value)
          : value
        : event;
    }

    syncPastMedicalHistoryAndClinicalNotes(updatedObject);
  };

  const handleNarrative = ({
    pastMedicalHistoryObject = pastMedicalHistory,
    event = '',
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...pastMedicalHistoryObject, narrative: event };

    setPastMedicalHistory(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon
            icon="new-clinical-narrative"
            className="cursor-pointer"
            data-qa="new-clinical-narrative"
          />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    pastMedicalHistoryObject = pastMedicalHistory,
    scrollable = false
  } = {}) => {
    const formattedNarrative = createPastMedicalHistoryNarrative(pastMedicalHistoryObject);

    handleNarrative({
      pastMedicalHistoryObject,
      event: formattedNarrative,
      scrollable
    });
  };

  const mutateDeleteMedicalHistory = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medical History',
          message: error,
          color: 'warning'
        });
        return;
      }
      setShowConfirmModal(false);
      setPastMedicalHistory(initialValue);
      formId &&
        fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type: 'medicalHistory'
        });
      showAlert({
        title: `Medical History deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setItemModal();
    }
  });

  console.log(pastMedicalHistory.noDetails);

  return (
    <div className="flex flex-row">
      <div className="grid w-full">
        <input type="hidden" value={JSON.stringify(pastMedicalHistory)} ref={forwardedRef} />
        {fromCheckin ? (
          <Header
            isAdvancedForm={isAdvancedForm}
            noDetails={pastMedicalHistory.noDetails}
            fromCheckin={fromCheckin}
            handleNoDetails={(isTrue) =>
              handleChange({
                event: isTrue,
                key: 'noDetails',
                parentKey: 'pastMedicalHistory',
                isBoolean: true
              })
            }
            noDetailsLabel="Add details about your Past Medical History"
            title="Medical History">
            <Container
              onDelete={() =>
                !isEqual(pastMedicalHistory, initialValue) && setShowConfirmModal(true)
              }
              fromCheckin={fromCheckin}>
              <div className="flex flex-col gap-1">
                <Row title="Have you ever been diagnosed with any chronic illnesses (such as diabetes, heart disease, etc.)?">
                  <div className="mb-3 !grid !grid-cols-2 gap-2">
                    <SelectableButton
                      data-qa="yes"
                      rounded
                      label="Yes"
                      name="illness"
                      isChecked={pastMedicalHistory?.chronic?.illness === 'yes'}
                      onChange={(event) =>
                        handleChange({
                          event: 'yes',
                          parentKey: 'pastMedicalHistory',
                          key: 'chronic',
                          childKey: 'illness'
                        })
                      }
                    />
                    <SelectableButton
                      data-qa="no"
                      rounded
                      label="No"
                      name="illness"
                      isChecked={pastMedicalHistory?.chronic?.illness === 'no'}
                      onChange={(event) =>
                        handleChange({
                          event: 'no',
                          parentKey: 'pastMedicalHistory',
                          key: 'chronic',
                          childKey: 'illness'
                        })
                      }
                    />
                  </div>

                  {pastMedicalHistory?.chronic?.illness === 'yes' && (
                    <Textarea
                      label="Details"
                      placeholder="Please provide details here"
                      transcribing
                      forwardedRef={chronicNoteRef}
                      value={pastMedicalHistory?.chronic?.note}
                      name="chronicNote"
                      id="chronicNote"
                      className="mb-4"
                      data-qa="chronic-note"
                      onChange={(event) =>
                        handleChange({
                          event: event.target.value,
                          parentKey: 'pastMedicalHistory',
                          key: 'chronic',
                          childKey: 'note'
                        })
                      }
                    />
                  )}
                </Row>
                <div className="dashed-bottom hidden lg:block"></div>

                <Row title=" Have you ever had any major surgeries?">
                  <div className="mb-3 !grid !grid-cols-2 gap-2">
                    <SelectableButton
                      data-qa="yes"
                      rounded
                      label="Yes"
                      name="surgery"
                      isChecked={pastMedicalHistory?.surgeries?.surgery === 'yes'}
                      onChange={(event) =>
                        handleChange({
                          event: 'yes',
                          parentKey: 'pastMedicalHistory',
                          key: 'surgeries',
                          childKey: 'surgery'
                        })
                      }
                    />
                    <SelectableButton
                      data-qa="no"
                      rounded
                      label="No"
                      name="surgery"
                      isChecked={pastMedicalHistory?.surgeries?.surgery === 'no'}
                      onChange={(event) =>
                        handleChange({
                          event: 'no',
                          parentKey: 'pastMedicalHistory',
                          key: 'surgeries',
                          childKey: 'surgery'
                        })
                      }
                    />
                  </div>

                  {pastMedicalHistory?.surgeries?.surgery === 'yes' && (
                    <Textarea
                      label="Details"
                      placeholder="Please provide details here"
                      transcribing
                      forwardedRef={surgeriesNoteRef}
                      value={pastMedicalHistory?.surgeries?.note}
                      name="surgeriesNote"
                      data-qa="surgeries-note"
                      id="surgeriesNote"
                      className="mb-4"
                      onChange={(event) =>
                        handleChange({
                          event: event.target.value,
                          parentKey: 'pastMedicalHistory',
                          key: 'surgeries',
                          childKey: 'note'
                        })
                      }
                    />
                  )}
                </Row>

                <Row title=" Do you have any known allergies (medication, food, environmental)?">
                  <div className="mb-3 !grid !grid-cols-2 gap-2">
                    <SelectableButton
                      data-qa="yes"
                      rounded
                      label="Yes"
                      name="allergy"
                      isChecked={pastMedicalHistory?.allergies?.allergy === 'yes'}
                      onChange={(event) =>
                        handleChange({
                          event: 'yes',
                          parentKey: 'pastMedicalHistory',
                          key: 'allergies',
                          childKey: 'allergy'
                        })
                      }
                    />
                    <SelectableButton
                      data-qa="no"
                      rounded
                      label="No"
                      name="allergy"
                      isChecked={pastMedicalHistory?.allergies?.allergy === 'no'}
                      onChange={(event) =>
                        handleChange({
                          event: 'no',
                          parentKey: 'pastMedicalHistory',
                          key: 'allergies',
                          childKey: 'allergy'
                        })
                      }
                    />
                  </div>

                  {pastMedicalHistory?.allergies?.allergy === 'yes' && (
                    <Textarea
                      label="Details"
                      placeholder="Please provide details here"
                      transcribing
                      forwardedRef={allergiesNoteRef}
                      value={pastMedicalHistory?.allergies?.note}
                      name="allergiesNote"
                      id="allergiesNote"
                      className="mb-4"
                      data-qa="allergies-note"
                      onChange={(event) =>
                        handleChange({
                          event: event.target.value,
                          parentKey: 'pastMedicalHistory',
                          key: 'allergies',
                          childKey: 'note'
                        })
                      }
                    />
                  )}
                </Row>

                <Row title="Hospitalization for illness or injury">
                  <div className="mb-3 !grid !grid-cols-2 gap-2">
                    <SelectableButton
                      data-qa="yes"
                      rounded
                      label="Yes"
                      name="illnessOrInjury"
                      isChecked={pastMedicalHistory?.hospitalization?.illnessOrInjury === 'yes'}
                      onChange={(event) =>
                        handleChange({
                          event: 'yes',
                          parentKey: 'pastMedicalHistory',
                          key: 'hospitalization',
                          childKey: 'illnessOrInjury'
                        })
                      }
                    />
                    <SelectableButton
                      data-qa="no"
                      rounded
                      label="No"
                      name="illnessOrInjury"
                      isChecked={pastMedicalHistory?.hospitalization?.illnessOrInjury === 'no'}
                      onChange={(event) =>
                        handleChange({
                          event: 'no',
                          parentKey: 'pastMedicalHistory',
                          key: 'hospitalization',
                          childKey: 'illnessOrInjury'
                        })
                      }
                    />
                  </div>

                  {pastMedicalHistory?.hospitalization?.illnessOrInjury === 'yes' && (
                    <Textarea
                      label="Details"
                      placeholder="Please provide details here"
                      transcribing
                      forwardedRef={hospitalizationNoteRef}
                      value={pastMedicalHistory?.hospitalization?.note}
                      name="hospitalizationNote"
                      id="hospitalizationNote"
                      data-qa="hospitalization-note"
                      onChange={(event) =>
                        handleChange({
                          event: event.target.value,
                          parentKey: 'pastMedicalHistory',
                          key: 'hospitalization',
                          childKey: 'note'
                        })
                      }
                    />
                  )}
                </Row>
              </div>
            </Container>
          </Header>
        ) : (
          <>
            {isAdvancedForm && (
              <Checkbox
                data-qa="no-notable-medical-history"
                label="No notable medical history"
                name="noDetails"
                isChecked={pastMedicalHistory.noDetails}
                onChange={() =>
                  handleChange({
                    event: !pastMedicalHistory.noDetails,
                    key: 'noDetails',
                    parentKey: 'pastMedicalHistory'
                  })
                }
              />
            )}

            {!pastMedicalHistory.noDetails && isAdvancedForm && (
              <Container
                onDelete={() => {
                  if (!formId) {
                    showAlert({
                      title: 'Medical History',
                      message: 'Please create the form before deleting one.',
                      color: 'warning'
                    });
                    return;
                  }
                  setShowConfirmModal(true);
                }}
                fromCheckin={fromCheckin}>
                <div className="dashed-bottom grid grid-cols-2 gap-x-10 gap-y-6 !pb-4 !pt-4 lg:grid-cols-1 lg:gap-4">
                  <div className="grid gap-4">
                    <p className="font-500 text-neutral-800">
                      Have you ever been diagnosed with any chronic illnesses (such as diabetes,
                      heart disease, etc.)?
                    </p>
                    <div className="flex gap-x-4">
                      <Checkbox
                        data-qa="yes"
                        rounded
                        label="Yes"
                        name="illness"
                        isChecked={pastMedicalHistory?.chronic?.illness === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event: 'yes',
                            parentKey: 'pastMedicalHistory',
                            key: 'chronic',
                            childKey: 'illness'
                          })
                        }
                      />
                      <Checkbox
                        data-qa="no"
                        rounded
                        label="No"
                        name="illness"
                        isChecked={pastMedicalHistory?.chronic?.illness === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event: 'no',
                            parentKey: 'pastMedicalHistory',
                            key: 'chronic',
                            childKey: 'illness'
                          })
                        }
                      />
                    </div>

                    {pastMedicalHistory?.chronic?.illness === 'yes' && (
                      <Textarea
                        label="Details"
                        placeholder="Please provide details here"
                        transcribing
                        forwardedRef={chronicNoteRef}
                        value={pastMedicalHistory?.chronic?.note}
                        name="chronicNote"
                        id="chronicNote"
                        data-qa="chronic-note"
                        onChange={(event) =>
                          handleChange({
                            event: event.target.value,
                            parentKey: 'pastMedicalHistory',
                            key: 'chronic',
                            childKey: 'note'
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="dashed-bottom hidden lg:block"></div>
                  <div className="grid gap-4">
                    <p className="font-500 text-neutral-800">
                      Have you ever had any major surgeries?
                    </p>
                    <div className="flex gap-x-4">
                      <Checkbox
                        data-qa="yes"
                        rounded
                        label="Yes"
                        name="surgery"
                        isChecked={pastMedicalHistory?.surgeries?.surgery === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event: 'yes',
                            parentKey: 'pastMedicalHistory',
                            key: 'surgeries',
                            childKey: 'surgery'
                          })
                        }
                      />
                      <Checkbox
                        data-qa="no"
                        rounded
                        label="No"
                        name="surgery"
                        isChecked={pastMedicalHistory?.surgeries?.surgery === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event: 'no',
                            parentKey: 'pastMedicalHistory',
                            key: 'surgeries',
                            childKey: 'surgery'
                          })
                        }
                      />
                    </div>

                    {pastMedicalHistory?.surgeries?.surgery === 'yes' && (
                      <Textarea
                        label="Details"
                        placeholder="Please provide details here"
                        transcribing
                        forwardedRef={surgeriesNoteRef}
                        value={pastMedicalHistory?.surgeries?.note}
                        name="surgeriesNote"
                        data-qa="surgeries-note"
                        id="surgeriesNote"
                        onChange={(event) =>
                          handleChange({
                            event: event.target.value,
                            parentKey: 'pastMedicalHistory',
                            key: 'surgeries',
                            childKey: 'note'
                          })
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-10 gap-y-6 !pt-4 lg:grid-cols-1 lg:gap-4">
                  <div className="grid gap-4">
                    <p className="font-500 text-neutral-800">
                      Do you have any known allergies (medication, food, environmental)?
                    </p>
                    <div className="flex gap-x-4">
                      <Checkbox
                        data-qa="yes"
                        rounded
                        label="Yes"
                        name="allergy"
                        isChecked={pastMedicalHistory?.allergies?.allergy === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event: 'yes',
                            parentKey: 'pastMedicalHistory',
                            key: 'allergies',
                            childKey: 'allergy'
                          })
                        }
                      />
                      <Checkbox
                        data-qa="no"
                        rounded
                        label="No"
                        name="allergy"
                        isChecked={pastMedicalHistory?.allergies?.allergy === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event: 'no',
                            parentKey: 'pastMedicalHistory',
                            key: 'allergies',
                            childKey: 'allergy'
                          })
                        }
                      />
                    </div>

                    {pastMedicalHistory?.allergies?.allergy === 'yes' && (
                      <Textarea
                        label="Details"
                        placeholder="Please provide details here"
                        transcribing
                        forwardedRef={allergiesNoteRef}
                        value={pastMedicalHistory?.allergies?.note}
                        name="allergiesNote"
                        id="allergiesNote"
                        data-qa="allergies-note"
                        onChange={(event) =>
                          handleChange({
                            event: event.target.value,
                            parentKey: 'pastMedicalHistory',
                            key: 'allergies',
                            childKey: 'note'
                          })
                        }
                      />
                    )}
                  </div>
                  <div className="dashed-bottom hidden lg:block"></div>
                  <div className="grid gap-4">
                    <p className="font-500 text-neutral-800">
                      Hospitalization for illness or injury:
                    </p>
                    <div className="flex gap-x-4">
                      <Checkbox
                        data-qa="yes"
                        rounded
                        label="Yes"
                        name="illnessOrInjury"
                        isChecked={pastMedicalHistory?.hospitalization?.illnessOrInjury === 'yes'}
                        onChange={(event) =>
                          handleChange({
                            event: 'yes',
                            parentKey: 'pastMedicalHistory',
                            key: 'hospitalization',
                            childKey: 'illnessOrInjury'
                          })
                        }
                      />
                      <Checkbox
                        data-qa="no"
                        rounded
                        label="No"
                        name="illnessOrInjury"
                        isChecked={pastMedicalHistory?.hospitalization?.illnessOrInjury === 'no'}
                        onChange={(event) =>
                          handleChange({
                            event: 'no',
                            parentKey: 'pastMedicalHistory',
                            key: 'hospitalization',
                            childKey: 'illnessOrInjury'
                          })
                        }
                      />
                    </div>

                    {pastMedicalHistory?.hospitalization?.illnessOrInjury === 'yes' && (
                      <Textarea
                        label="Details"
                        placeholder="Please provide details here"
                        transcribing
                        forwardedRef={hospitalizationNoteRef}
                        value={pastMedicalHistory?.hospitalization?.note}
                        name="hospitalizationNote"
                        id="hospitalizationNote"
                        data-qa="hospitalization-note"
                        onChange={(event) =>
                          handleChange({
                            event: event.target.value,
                            parentKey: 'pastMedicalHistory',
                            key: 'hospitalization',
                            childKey: 'note'
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              </Container>
            )}
          </>
        )}

        {cnDisplaySettings && showNarrative && (
          <div className="dashed-top !mt-4 !pt-3">
            <NarrativeForm
              syncNarrative={syncNarrative}
              setSyncNarrative={setSyncNarrative}
              onChange={(event) => handleNarrative({ event, sync: false })}
              setCurrentForm={setPastMedicalHistory}
              restData={{
                className: 'w-full',
                label: 'Past Medical History Narrative',
                placeholder: 'Add narrative here',
                id: 'Narrative-medical-history',
                'data-qa': 'narrative-medical-history',
                name: 'Narrative-medical-history',
                value: pastMedicalHistory?.narrative,
                forwardedRef: textAreaRef
              }}
              narrativeOptions={isAdvancedForm ? [...narrativeOptions, ...iaRa(options)] : []}
            />
          </div>
        )}

        <button
          className="hidden"
          ref={hpNoteOverviewRef}
          onClick={() => {
            submitForm({
              ...customFormsParams,
              formId,
              currentFormData: pastMedicalHistory,
              defaultState: prevPastMedicalHistory,
              type: 'medicalHistory'
            });
            setPrevPastMedicalHistory(pastMedicalHistory);
          }}
        />
      </div>
      {/* <div className="my-auto ml-3">
        {!fromCheckin && !isEqual(pastMedicalHistory, initialValue) && (
          <Icon
            icon="trash"
            className="ml-1"
            onClick={() => {
              setShowConfirmModal(true);
            }}
          />
        )}
      </div> */}
      {showConfirmModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Medical History"
          icon="trash"
          message="Are you sure you want to delete this form?"
          handleContinue={() => {
            if (!formId) {
              setShowConfirmModal(false);
              setPastMedicalHistory(initialValue);
              setCurrentFormData && setCurrentFormData(initialValue);
              return;
            }
            mutateDeleteMedicalHistory.mutate();
          }}
          handleOpen={showConfirmModal}
          handleClose={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

export default PastMedicalHistory;
