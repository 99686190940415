import React, { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';

// import { serviceChargeTypes } from '../../../../../../../constants';

import { createServiceCategory, deleteServiceCategory } from 'api/ServiceCategory';

import { iaRa, optionify } from 'lib/helpers/utility';
import { useMember } from 'lib/hooks/queries/member/useMember';
import { useServiceCategories } from 'lib/hooks/queries/services/useServiceCategories';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';

import {
  CATEGORY_CREATED_SUCCESS_MESSAGE,
  CATEGORY_DELETED_ERROR_MESSAGE,
  CATEGORY_DELETED_SUCCESS_MESSAGE
} from '../../lib/constants';
import Wrapper from '../Wrapper/Wrapper';

const CATEGORY_QUERY_KEY = 'serviceCategories';

const DetailsSection = ({ formik }) => {
  const { data: member = [] } = useMember();
  const practitioners = useMemo(
    () =>
      member?.members?.map((practitioner) => ({
        value: practitioner.user_id,
        label: `${practitioner.f_name} ${practitioner.l_name}`
      })) || [],
    [member.members]
  );

  const descriptionRef = useRef();

  const { isOpen: showDeleteCategory, openModal, closeModal } = useModal();

  const navigate = useNavigate();

  const { data } = useServiceCategories();
  const categories = data?.categories || [];

  const { mutateAsync: deleteCategory } = useCustomMutation({
    mutationFn: () => deleteServiceCategory(navigate, { categoryId: showDeleteCategory?.value }),
    invalidateQueryKey: CATEGORY_QUERY_KEY,
    successMessage: CATEGORY_DELETED_SUCCESS_MESSAGE,
    errorMessage: CATEGORY_DELETED_ERROR_MESSAGE,
    onSuccess: () => {
      closeModal();
    }
  });

  const { mutateAsync: createCategory } = useCustomMutation({
    mutationFn: (newCategory) =>
      createServiceCategory(navigate, { category: { name: newCategory } }),
    invalidateQueryKey: CATEGORY_QUERY_KEY,
    successMessage: CATEGORY_CREATED_SUCCESS_MESSAGE,
    onSuccess: () => {
      closeModal();
    }
  });

  const selectedPractitioners = (practitioners) => {
    const result = practitioners?.map((practitioner) => {
      return practitioner?.value;
    });
    formik.setFieldValue('practitioner_ids', iaRa(result));
  };

  const practitionerNames = practitioners
    ?.map((practitioner) => {
      if (formik.values.practitioner_ids?.includes(practitioner.value)) {
        return practitioner;
      }
    })
    .filter(Boolean);

  const Option = useCallback(
    (props) => (
      <components.Option {...props}>
        {props?.data?.__isNew__ ? (
          <div className="flex cursor-pointer items-center gap-3 text-left text-sm text-neutral-800 hover:bg-primary-50">
            <Icon icon="new-add-circle"></Icon>
            {props?.data?.value}
          </div>
        ) : (
          <div className="flex w-full justify-between gap-3">
            {props?.data?.label}
            <button
              className="cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                openModal(props.data);
              }}>
              <Icon className="cursor-pointer" icon="trash" color="danger"></Icon>
            </button>
          </div>
        )}
      </components.Option>
    ),
    [openModal]
  );

  return (
    <Wrapper title="DETAILS" isColumn>
      <Textarea
        label="Description"
        value={formik.values.description}
        onChange={formik.handleChange}
        isOptional
        onBlur={formik.handleBlur}
        name="description"
        id="serviceDescriptionTextarea"
        data-qa="description-input"
        textareaClassName="min-h-[82px]"
        maxLength={200}
        resizeY
        error={formik.touched.description && formik.errors.description}
        forwardedRef={descriptionRef}
      />
      <Checkbox
        label="Display service in patient self scheduling"
        onChange={(event) => formik.setFieldValue('self_scheduling', event.target.checked)}
        checked={formik.values?.self_scheduling}
        id="serviceSelfSchedulingSwitch"
        data-qa="service-self-scheduling-switch"
      />
      {formik.values?.self_scheduling && (
        <div className="flex flex-wrap items-start gap-4">
          <Select
            label="Practitioners Available For this Service"
            placeholder="Available Practitioners"
            parentClassName="flex flex-1 flex-col"
            isMulti
            value={practitionerNames}
            name="gender"
            onChange={selectedPractitioners}
            options={practitioners}
            id="servicePractitionerSelect"
            data-qa="practitioners-available-for-this-service"
            menuPortalTarget={document.body}
          />
          <Select
            label="Category"
            isCreatable={true}
            onChange={(e) => formik.setFieldValue('category_id', e?.value || null)}
            value={
              formik.values.category_id && {
                label: categories?.find((item) => item.id === formik.values.category_id)?.name
              }
            }
            options={optionify(categories, 'name', 'id')}
            placeholder="Type and select a Category"
            noOptionsMessage={() => 'Start typing to create a category.'}
            error={formik.touched.category_id && formik.errors.category_id}
            id="serviceCategory"
            required
            parentClassName="flex flex-1 flex-col"
            onCreateOption={(e) => createCategory(e)}
            isClearable={false}
            components={{ Option, IndicatorSeparator: () => null }}
            menuPortalTarget={document.body}
          />
        </div>
      )}
      {showDeleteCategory && (
        <Confirm
          handleOpen={showDeleteCategory?.value}
          handleClose={closeModal}
          handleContinue={deleteCategory}
          title="Delete category"
          message={`Are you sure you want to delete "${showDeleteCategory?.label}" category?`}
          variant="danger"
        />
      )}
    </Wrapper>
  );
};

export default DetailsSection;
