import React, { useState, useEffect } from 'react';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import ProfilePicture from 'components/Profile/ProfilePicture';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import cs from 'classnames';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';

const AssignUser = ({ member }) => {
  const [isChecked, setIsChecked] = useState(false);
  const { formik, newRoleSelectedUsers, setNewRoleSelectedUsers } = useNewRoleContext();

  useEffect(() => {
    setIsChecked(formik.values.assignedUsers.includes(member.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkClicked = () => {
    if (!isChecked) {
      setIsChecked(true);
      let assignedUsers = formik.values.assignedUsers;
      assignedUsers.push(member.id);
      formik.setFieldValue('assignedUsers', assignedUsers);
      setNewRoleSelectedUsers(newRoleSelectedUsers + 1);
    } else {
      setIsChecked(false);
      let assignedUsers = formik.values.assignedUsers.filter((id) => id !== member.id);
      formik.setFieldValue('assignedUsers', assignedUsers);
      setNewRoleSelectedUsers(newRoleSelectedUsers - 1);
    }
  };

  const roleName = member?.role_name;

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Checkbox
        className={cs(
          'flex h-14 w-full flex-col items-start justify-center gap-4 self-stretch rounded-[10px] !border !border-solid bg-white p-4',
          isChecked ? '!border-primary-500' : '!border-neutral-100'
        )}
        innerLabelClassName="w-full [&>span]:!w-full"
        inputClassName="h-10 !py-2 !px-3 rounded border border-solid border-transparent bg-neutral-100"
        id={member.id}
        key={member.id}
        name="user"
        labelClassName="w-full"
        value={member.id}
        handleClick={checkClicked}
        isChecked={isChecked}
        label={
          <div className="flex w-full items-center justify-between">
            <div className="flex items-center gap-1.5">
              <div className="relative rounded-[14.34px] border border-white bg-white">
                <ProfilePicture
                  firstName={member.f_name}
                  lastName={member.l_name}
                  id={member.id}
                  image={member.profile_photo}
                  size={25}
                  className="rounded-full text-white"
                  fontSize="text-s"
                />
              </div>
              <div className="text-sm font-medium leading-snug text-primary-900">
                {member.f_name + ' ' + member.l_name}
              </div>
            </div>
            {roleName && (
              <div className="w-[250px] text-sm font-500 leading-snug text-neutral-500">
                Current role: {roleName}
              </div>
            )}
          </div>
        }
      />
    </ErrorBoundary>
  );
};

export default AssignUser;
