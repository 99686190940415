import moment from 'moment';

export const getHours = (currPractice) => {
    const locale = 'en';
    const newHours = [];

    moment.locale(locale);

    const startHr = currPractice?.working_hours?.startsAt?.hour || 0;
    const endHr = currPractice?.working_hours?.endsAt?.hour || 24;

    for (let hour = startHr; hour < endHr; hour++) {
        newHours.push(moment({ hour }).format('hh:mm A'));
        for (let minutes = 1; minutes < 12; minutes++) {
            newHours.push(
                moment({
                    hour,
                    minute: minutes * 5
                }).format('hh:mm A')
            );
        }
    }
    return newHours;
};
