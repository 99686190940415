import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import {
  usePatientBalanceContextApi,
  usePatientBalanceContextData
} from 'lib/context/PatientBalanceContext/PatientBalanceContext';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Loading from 'components/shared/Loading/Loading';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import Tabs from 'components/shared/Tabs/Tabs';

import { ia, mString } from '../../../../lib/helpers/utility';

import PatientBalanceFooter from './components/Footer';
import NewAllocatedTable from './components/NewAllocatedTable';
import NewBalanceTable from './components/NewBalanceTable';
import NewUnAllocatedTable from './components/NewUnallocatedTable';

const PatientBalanceModal = ({ patientId, patientBalance, balanceLoading }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const { formData = {}, activeMode, allocatingRow, activeTab } = usePatientBalanceContextData();
  const { setFormData, setActiveMode, setActiveTab } = usePatientBalanceContextApi();

  const selectedData = Object.values(formData || {});

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const resetFormData = () => {
    setFormData(null);
  };

  const handleUnAllocatedAmount = async () => {
    setLoading(true);

    const invoices = selectedData;
    const params = { patientId, invoices };

    await requestApi({
      url: '/api/practice/patient/balance/allocate',
      params,
      navigate,
      onSuccess: async () => {
        showAlert({
          title: 'Amount allocated successfully!',
          color: 'success'
        });
        setActiveMode((prevMode) => ({ ...prevMode, allocate: false }));

        await Promise.all([
          queryClient.refetchQueries({ queryKey: ['patientInvoices'] }),
          queryClient.refetchQueries({ queryKey: ['allocated'] }),
          queryClient.refetchQueries({ queryKey: ['unallocated'] })
        ]);
      }
    });
    resetFormData();
    setLoading(false);
  };

  const handleWriteOff = async () => {
    setLoading(true);

    const invoices = selectedData;
    const params = { patientId, invoices };

    await requestApi({
      url: '/api/transactions/writeoff/add',
      params,
      navigate,
      onSuccess: async () => {
        showAlert({
          title: 'Write off completed successfully!',
          color: 'success'
        });
        setActiveMode((prevMode) => ({ ...prevMode, writeoff: false }));

        await Promise.all([
          queryClient.refetchQueries({ queryKey: ['patientBalance'] }),
          queryClient.refetchQueries({ queryKey: ['patientInvoices'] }),
          queryClient.refetchQueries({ queryKey: ['allocated'] }),
          queryClient.refetchQueries({ queryKey: ['unallocated'] })
        ]);
      }
    });
    resetFormData();
    setLoading(false);
  };

  const PrevButtons = () => {
    return activeMode?.allocate ? (
      <p className="text-sm">
        Unallocated amount:
        <span className="!ml-1 inline font-600">{mString(allocatingRow?.payment)} </span>
      </p>
    ) : (
      <div className="flex items-center gap-3">
        <Allowed requiredPermissions="payments.update">
          <Button
            size="small"
            text="Write Off"
            data-qa="write-off-btn"
            type="secondary"
            active={activeMode?.writeoff ? true : false}
            onClick={() => {
              setActiveTab(0);
              setActiveMode({ writeoff: true, allocate: false });
            }}
          />
        </Allowed>
      </div>
    );
  };

  const tabsData = [
    {
      label: 'Balance',
      content: <NewBalanceTable mode={activeMode} />
    },
    {
      label: 'Unallocated',
      content: <NewUnAllocatedTable mode={activeMode} />
    },
    {
      label: 'Allocated',
      content: <NewAllocatedTable mode={activeMode} />
    }
  ];

  const onClose = () => {
    setShowModal(false);
    setActiveMode({ writeoff: false, allocate: false });
  };

  const isOverlayClickCloseEnabled = !(activeMode?.writeoff || activeMode?.allocate);

  return (
    <>
      <Button
        outlined
        icon="wallet"
        className="h-[34px]"
        loading={balanceLoading}
        data-qa="patient-balance-btn"
        onClick={() => setShowModal(true)}
        text={`Total Balance: ${mString(patientBalance)}`}
      />
      <Modal
        slideFromRight
        handleOpen={showModal}
        title="Patient's Balance"
        isLarge
        bodyClassName="overflow-y-hidden"
        handleClose={onClose}
        shouldCloseOnOverlayClick={isOverlayClickCloseEnabled}
        footer={
          <PatientBalanceFooter
            setShowModal={setShowModal}
            handleWriteOff={handleWriteOff}
            handleUnAllocatedAmount={handleUnAllocatedAmount}
            disabled={!ia(selectedData)}
          />
        }>
        {loading ? (
          <Loading />
        ) : (
          <Tabs
            tabIndex={activeTab}
            tabsData={tabsData}
            setTabIndex={setActiveTab}
            prevButtons={<PrevButtons />}
          />
        )}
      </Modal>
    </>
  );
};

export default PatientBalanceModal;
