import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import Logout from 'components/shared/Logout/Logout';

export default function Sidebar({ match, callingFrom }) {
  const classStyle =
    'mt-4 flex cursor-pointer flex-col mt-1 text-sm font-400 !text-primary-900 items-center justify-center !rounded-lg border border-white px-[14px] py-[10px] first:!mt-0 hover:bg-primary-50';
  const activeClassStyle =
    'mt-4 flex cursor-pointer mt-1 text-sm font-400 flex-col !text-primary-900 items-center justify-center !rounded-lg border !border-primary-500 !bg-primary-50 px-[14px] py-[10px] first:!mt-0 hover:bg-primary-50';

  if (
    match &&
    match.url &&
    (match.url === '/admin/login' || match.url.includes('/admin/password'))
  ) {
    return null;
  }

  return (
    <aside className="flex h-[100vh] flex-col justify-between bg-white !px-2 !pt-5 pb-7 shadow-small">
      <div className="flex flex-col">
        <Link to="/admin" className="flex justify-center">
          <Icon icon="logo-symbol" size={26} />
        </Link>
        <div className="!pt-4">
          <NavLink
            to="/admin"
            end
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="dashboard">
            <Icon icon="new-dark-dashboard" />
            <span className="!mt-1 bg-transparent">Dashboard</span>
          </NavLink>
          <NavLink
            to="/admin/practices"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="practices">
            <Icon icon="new-dark-patients" />
            <span className="!mt-1 bg-transparent">Practices</span>
          </NavLink>
          <NavLink
            to="/admin/practitioners"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="practitioners">
            <Icon icon="new-dark-patients" />
            <span className="!mt-1 bg-transparent">Practitioners</span>
          </NavLink>
          <NavLink
            to="/admin/patients"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="patients">
            <Icon icon="new-dark-patients" />
            <span className="!mt-1 bg-transparent">Patients</span>
          </NavLink>
          <NavLink
            to="/admin/reporting-center"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="reporting-hub">
            <Icon icon="new-document" size={24} color="primary" shade={900} />
            <span className="!mt-1 bg-transparent text-center">Reporting Center</span>
          </NavLink>
          <NavLink
            to="/admin/databank"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="databank">
            <Icon icon="archive" color="primary" shade={900} />
            <span className="!mt-1 bg-transparent">Databank</span>
          </NavLink>
          <NavLink
            to="/admin/access-logs"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="access-logs">
            <Icon icon="new-dropdown-select" size={24} color="primary" shade={900} />
            <span className="!mt-1 bg-transparent text-primary-800">Access Logs</span>
          </NavLink>
          <NavLink
            to="/admin/users"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="users">
            <Icon icon="new-dark-patients" />
            <span className="!mt-1 bg-transparent">Admin Users</span>
          </NavLink>
          <NavLink
            to="/admin/intelligence"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="intelligence">
            <Icon icon="new-myscribe-stars" />
            <span className="!mt-1 bg-transparent">Intelligence</span>
          </NavLink>
          <NavLink
            to="/admin/settings"
            className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
            data-qa="settings">
            <Icon icon="new-settings" color="primary" shade={900} />
            <span className="!mt-1 bg-transparent">Settings</span>
          </NavLink>
        </div>
      </div>
      <Logout adminPortal={true} iconColor="primary" iconShade={900} />
    </aside>
  );
}
