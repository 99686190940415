import { Popover } from '@headlessui/react';
import cs from 'classnames';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Calendar } from 'react-date-range';

import SearchProviders from 'components/Search/SearchProviders/SearchProviders';

import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';

import SelectMedicationTable from './components/SelectMedicationTable';

const CurrentMedicationsRow = ({
  medication,
  sig,
  condition,
  start_date,
  end_date,
  prescriber,
  is_past,
  note,
  id,
  onChange,
  onChangeMulti,
  newDesign,
  className,
  veradigm_id,
  pharmacist_instructions
}) => {
  const noteRef = useRef(null);

  const handleSelectMedication = async (medication) => {
    onChangeMulti(
      {
        medication: capitalize(medication.generic_name || medication.brand),
        dosage_form: medication.dosage_form,
        route: medication.route,
        product_ndc: medication.product_ndc,
        product_ndc_id: medication.product_id
      },
      id
    );
  };
  const disabled = veradigm_id ? true : false;
  return (
    <div>
      {!disabled && (
        <SelectMedicationTable
          newDesign={newDesign}
          onChange={onChange}
          medication={medication}
          id={id}
          handleSelectMedication={handleSelectMedication}
        />
      )}
      <div className={cs('flex items-center gap-x-[10px]', className?.replace('dashed-top', ''))}>
        <div className="grid w-full gap-4">
          <div className={cs(newDesign ? 'grid-cols-1' : 'grid-cols-2', 'grid  gap-4')}>
            <Textarea
              className="sm:col-span-full md:col-span-full"
              data-qa="add-sig"
              label="List the dosage and how often"
              placeholder="e.g. 1 pill every 4 hours"
              name="sig"
              value={sig}
              onChange={(event) => onChange('sig', event.target.value, id)}
              disabled={disabled}
              transcribing
              forwardedRef={noteRef}
              textareaClassName="!min-h-[60px]"
            />

            <div
              className={cs(
                is_past ? 'grid-cols-2' : 'grid-cols-1',
                'grid gap-4 sm:col-span-full sm:grid-cols-[repeat(auto-fit,minmax(130px,1fr))] md:col-span-full'
              )}>
              <Popover className="relative">
                {({ close }) => {
                  return (
                    <>
                      <Popover.Button className="w-full p-0" disabled={disabled}>
                        <Input
                          data-qa="date-started"
                          label="Date started"
                          inputWrapperClassName="!flex"
                          rightIcon="new-calendar-gray"
                          placeholder="Type and select start date"
                          value={start_date ? moment(start_date).format('MMM Do, YYYY') : null}
                        />
                      </Popover.Button>
                      <Popover.Panel className="absolute bottom-full left-0 z-20 w-max !rounded-lg bg-white !pb-3 shadow-medium">
                        <Calendar
                          data-qa="calendar-date_started"
                          date={start_date ? new Date(start_date) : new Date()}
                          onChange={(event) => {
                            onChange('start_date', moment.utc(event).add(12, 'h'), id);
                            close();
                          }}
                        />
                      </Popover.Panel>
                    </>
                  );
                }}
              </Popover>
              {is_past && (
                <Popover className="relative">
                  {({ close }) => {
                    return (
                      <>
                        <Popover.Button className="w-full p-0" disabled={disabled}>
                          <Input
                            data-qa="date-ended"
                            label="Date ended"
                            inputWrapperClassName="!flex"
                            rightIcon="new-calendar-gray"
                            placeholder="Type and select end date"
                            value={end_date ? moment(end_date).format('MMM Do, YYYY') : null}
                          />
                        </Popover.Button>
                        <Popover.Panel className="absolute bottom-full left-0 z-20 w-max !rounded-lg bg-white !pb-3 shadow-medium">
                          <Calendar
                            data-qa="calendar-end_date"
                            date={end_date ? new Date(end_date) : new Date()}
                            onChange={(event) => {
                              onChange('end_date', moment.utc(event).add(12, 'h'), id);
                              close();
                            }}
                          />
                        </Popover.Panel>
                      </>
                    );
                  }}
                </Popover>
              )}
            </div>

            <Select
              inputId="select-condition"
              label="Condition"
              id="condition"
              value={condition}
              name="condition"
              onChange={(event) => onChange('condition', event, id)}
              isCreatable
              isClearable
              isMulti
              placeholder="Type and select condition"
              disabled={disabled}
            />

            <SearchProviders
              label="Prescriber"
              id="prescribingPhysician"
              placeholder="Prescriber"
              disabled={disabled}
              value={prescriber}
              onChange={(event) => onChange('prescriber', event, id)}
            />

            {!!veradigm_id && (
              <div className={cs(newDesign ? 'grid-cols-1' : 'grid-cols-2', 'grid  gap-4')}>
                <Textarea
                  className="sm:col-span-full md:col-span-full"
                  data-qa="add-pharmacist-note"
                  label="Special instructions to pharmacist"
                  placeholder="Add pharmacist note here"
                  name="pharmacist_note"
                  value={pharmacist_instructions}
                  onChange={(event) => onChange('pharmacist_note', event.target.value, id)}
                  disabled={disabled}
                  transcribing
                  forwardedRef={noteRef}
                  textareaClassName="!min-h-[60px]"
                />
              </div>
            )}

            <Textarea
              className="sm:col-span-full md:col-span-full"
              data-qa="add-note"
              label="Notes"
              placeholder="Add note here"
              name="note"
              value={note}
              onChange={(event) => onChange('note', event.target.value, id)}
              disabled={disabled}
              transcribing
              forwardedRef={noteRef}
              textareaClassName="!min-h-[60px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrentMedicationsRow;
