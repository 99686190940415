import React from 'react';
import Confirm from '../../../../../../shared/Modal/Confirm/Confirm';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';

const DeleteMacros = () => {
  const { deleteMacro, isDeletingMacros, deleteMacroId, setDeleteMacroId } = useMacrosContext();

  return (
    <Confirm
      variant="danger"
      primaryBtnTxt="Delete macro"
      secondaryBtnTxt="Close"
      title="Delete macro"
      message="Are you sure you want to delete this macro?"
      icon="new-delete-macro"
      iconSize="24px"
      handleContinue={() => {
        deleteMacro(deleteMacroId);
      }}
      loading={isDeletingMacros}
      handleOpen={!!deleteMacroId}
      handleClose={() => setDeleteMacroId(null)}
    />
  );
};

export default DeleteMacros;
