import React, { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Buttons/Button';
import SignModal from '../../../../../charts/ClinicalNote/shared/Sign/SignModal';
import { showAlert } from '../../../../../../shared/Alert/Alert';
import { useRecoilValue } from 'recoil';
import { permissions as userPermissions, userState } from '../../../../../../state';
import { useQueryClient } from '@tanstack/react-query';
import { ia } from '../../../../../../../lib/helpers/utility';
import PdfEditor from 'components/pdfeditor/PDFEditor';
import cs from 'classnames';

import { useNavigate } from 'react-router-dom';
import { saveDocumentHistory, selectDocumentHistory } from 'api/DocumentHistory';

import applyChangesToFax from '../lib/applyChangesToFax';
import { useDocumentHistory } from 'lib/hooks/queries/useDocumentHistory';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import _ from 'lodash';
import moment from 'moment';

const PreviewPdfModal = ({
  pdfData,
  previewFax,
  previewFaxModalVisible,
  setPreviewFaxModalVisible,
  hasEdit
}) => {
  const [editModal, setEditModal] = useState(null);
  const [scale, setScale] = useState(1.5);
  const navigate = useNavigate();
  const [canvasStates, setCanvasStates] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [document, setDocument] = useState(pdfData);
  const [historyMode, setHistoryMode] = useState(false);
  const [currentVersion, setCurrentVersion] = useState(0);
  const queryClient = useQueryClient();
  const fabricCanvasRef = useRef(null);

  const { data } =
    useDocumentHistory({
      params: { id: previewFax?.id, kind: 'fax' },
      dependencies: [previewFax?.id]
    }) || {};

  const loadDocument = async (data) => {
    const { blob, scale, changes } = await applyChangesToFax(
      pdfData,
      navigate,
      previewFax?.id,
      data || null
    );
    setScale(scale);
    setCanvasStates(changes);
    setDocument(blob ? URL.createObjectURL(blob) : pdfData);
  };

  useEffect(() => {
    if (currentVersion >= 1) {
      const record = data?.records?.find((record) => record.version === currentVersion);
      loadDocument({ record });
      const fabricCanvas = fabricCanvasRef.current;
      fabricCanvas?.renderAll.bind(record.changes[currentPage]);
    }
  }, [currentVersion]);

  useEffect(() => {
    if (data) {
      setCurrentVersion(
        data?.records?.find((record) => record.selected === true)?.version ||
          data?.records?.[0]?.version ||
          0
      );
    }
  }, [data]);

  const saveRecord = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    const canvasJson = fabricCanvas.toJSON();

    await saveDocumentHistory(navigate, {
      id: previewFax?.id,
      kind: 'fax',
      changes: {
        ...canvasStates,
        [currentPage]: canvasJson,
        scale
      }
    });

    queryClient.invalidateQueries(['document-history']);
    loadDocument();
    setCurrentPage(1);
    setEditModal(null);
  };

  const selectRecord = async (record) => {
    await selectDocumentHistory(navigate, {
      id: previewFax?.id,
      kind: 'fax',
      version: record.version
    });

    setCurrentVersion(record.version);
  };

  const records = useMemo(() => _.groupBy(data?.records || [], 'date'), [data]);

  return (
    <Modal
      handleOpen={!!previewFaxModalVisible}
      handleClose={() => setPreviewFaxModalVisible(false)}
      bodyClassName="p-0"
      customHeader={
        <div className="mr-3 flex h-full !w-[100%] items-center justify-between">
          <h3 className="whitespace-nowrap text-lg">{`Fax Preview ${previewFax?.id ? `#${previewFax?.id}` : ''}`}</h3>

          {hasEdit && (
            <div className="flex gap-2">
              <Button
                icon="new-history"
                iconIsStroke
                onClick={() => {
                  setEditModal('history');
                  setHistoryMode(true);
                }}
              />
              <Button
                text="Edit Document"
                color="primary"
                size="small"
                onClick={() => setEditModal('edit')}
                data-qa="edit-btn"
              />
            </div>
          )}
        </div>
      }
      headButton={<RenderHeadButton previewFax={previewFax} />}
      customStyling={{ width: '924px' }}
      slideFromRight
      isFooter={false}>
      <div className="flex justify-center">
        {document ? (
          <iframe
            src={document}
            style={{
              width: '924px', // Set width to match A4 paper width
              height: '11.69in', // Set height to match A4 paper height
              objectFit: 'contain' // Ensure the PDF fits within the iframe without distortion
            }}
          />
        ) : null}
      </div>
      {hasEdit && (
        <Modal
          slideFromRight={true}
          bodyClassName={cs(
            '!p-0 overflow-hidden transition-all',
            historyMode ? 'w-[1080px]' : 'w-[924px]'
          )}
          isFooter={false}
          handleOpen={!!editModal}
          customHeader={
            <div className="mr-3 flex h-full !w-[100%] items-center justify-between">
              <h3 className="whitespace-nowrap text-lg">{`${historyMode ? 'Document Versions' : 'Fax edit'} #${previewFax?.id}`}</h3>
              <div className="flex gap-2">
                {!historyMode && (
                  <>
                    <Button icon="new-history" iconIsStroke onClick={() => setHistoryMode(true)} />
                    <Button
                      text="Save Changes"
                      color="primary"
                      size="small"
                      onClick={saveRecord}
                      data-qa="save-btn"
                    />
                  </>
                )}
              </div>
            </div>
          }
          handleClose={() => {
            if (historyMode) {
              if (editModal === 'history') setEditModal(null);
              return setHistoryMode(false);
            }
            setEditModal(null);
            setCurrentPage(1);
          }}>
          <div className="flex h-full w-full overflow-hidden ">
            {historyMode && (
              <div className="flex flex-1 flex-col gap-2 overflow-y-auto px-5 py-4">
                {Object.keys(records).map((rec) => (
                  <>
                    <p className="mb-1 ml-2 mt-4 text-sm text-primary-900">{formatDate(rec)}</p>
                    {records[rec].map((record) => (
                      <div
                        onClick={() => record.version !== currentVersion && selectRecord(record)}
                        className={cs(
                          'flex justify-between rounded-lg p-4',
                          record.version == currentVersion
                            ? 'bg-primary-900 !text-white'
                            : 'cursor-pointer bg-white text-primary-900 hover:bg-primary-50'
                        )}
                        key={record.id}>
                        <div className="flex-col ">
                          <p className="text-sm font-medium">
                            {record.version == 1 ? (
                              'Original Version'
                            ) : (
                              <>
                                Version{' '}
                                <span
                                  className={
                                    record.version == currentVersion
                                      ? 'text-white'
                                      : 'text-primary-600'
                                  }>
                                  #{record.version}
                                </span>
                              </>
                            )}
                          </p>
                          {record?.version != 1 && (
                            <p className="text-xs opacity-80">
                              {formatDate(record.created_at)}{' '}
                              {moment(record.created_at).format('hh:mm A')}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-col">
                          <p
                            className={cs(
                              'rounded-full  px-2 py-1 text-xs ',
                              record.version == currentVersion
                                ? 'bg-white bg-opacity-20'
                                : 'bg-primary-50'
                            )}>
                            {record?.user?.p_name
                              ? record?.user?.p_name
                              : record?.user?.f_name + ' ' + record?.user?.l_name}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            )}
            <PdfEditor
              canvasStates={canvasStates}
              setCanvasStates={setCanvasStates}
              src={pdfData}
              fabricCanvasRef={fabricCanvasRef}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              scale={scale}
              setScale={setScale}
              historyMode={historyMode}
            />
          </div>
        </Modal>
      )}
    </Modal>
  );
};

const RenderHeadButton = ({ previewFax }) => {
  const user = useRecoilValue(userState);
  const userId = user.id ?? user.user_id;
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasCurrentUserSigned, setHasCurrentUserSigned] = useState(
    previewFax?.document_sign?.find((sign) => sign.user_id === userId)?.status === 'signed'
  );

  const queryClient = useQueryClient();

  const hasFaxAssignedDocuments = ia(previewFax?.document_sign);
  const isCurrentUserAssigned = previewFax?.document_sign?.some((sign) => sign.user_id === userId);

  const onShowSigModal = () => {
    if (!previewFax?.id) {
      return showAlert({
        color: 'warning',
        message: 'Something went wrong. Please try again.'
      });
    }

    setShowConfirmationModal(true);
  };

  const onDocumentSignSuccess = async () => {
    await queryClient.invalidateQueries(['inboundFaxes']);
    setHasCurrentUserSigned(true);
  };

  return (
    <div className="!ml-3">
      {hasFaxAssignedDocuments && isCurrentUserAssigned && !hasCurrentUserSigned && (
        <Button
          text="Sign"
          icon="new-lock-2-opened"
          iconIsStroke
          type="secondary"
          className="!ml-4"
          loading={showConfirmationModal}
          onClick={onShowSigModal}
        />
      )}

      {previewFax?.id && (
        <SignModal
          documentId={previewFax?.id}
          documentTypes={['e_fax_signature']}
          alertUsers={false}
          setShowConfirmationModal={setShowConfirmationModal}
          showConfirmationModal={showConfirmationModal}
          confirmModalProps={{
            title: 'Sign Fax',
            message: 'Are you sure you want to sign this fax',
            primaryBtnTxt: 'Sign fax'
          }}
          onDocumentSignSuccess={onDocumentSignSuccess}
        />
      )}
    </div>
  );
};

export default PreviewPdfModal;
