import { requestApi } from 'api/Api';
import { capitalize, isEqual, set } from 'lodash';
import transformPatientDataForSubmission from './transformPatientDataForSubmission';
import { ia, io } from 'lib/helpers/utility';
import updateAddress from './updateAddress';
import { showAlert } from 'components/shared/Alert/Alert';

const handleSubmitPatientChanges = async ({
  values,
  setErrors,
  states,
  initialValues,
  queryClient,
  navigate
}) => {
  try {
    const {
      patient: updatedPatient,
      address: updatedAddress,
      secondary_address: updatedSecondaryAddress,
      custom_id
    } = transformPatientDataForSubmission(values, states);

    const patientId = updatedPatient.id;

    if (values.custom_id !== initialValues.custom_id) {
      const onSuccess = async () => {
        showAlert({ title: 'Custom Id updated successfully!', color: 'success' });
      };
      const onError = (error) => {
        error
          ? showAlert({ title: error, color: 'danger' })
          : showAlert({ title: 'The custom ID was not updated successfully', color: 'danger' });
      };
      requestApi({
        url: '/api/practice/patient/update',
        params: { user_id: updatedPatient.id, data: { custom_id } },
        navigate,
        onSuccess,
        onError
      });
    }

    if (updatedPatient) {
      const changes = {};

      const values = updatedPatient;

      for (const key in values) {
        if (!isEqual(values[key], initialValues[key])) {
          changes[key] = values[key];
        }
      }
      if (io(changes)) {
        let params = { changes, user_id: updatedPatient.id };

        if (changes?.occupation_id) {
          params.occupation_id = changes?.occupation_id;

          delete changes?.occupation_id;
        }
        const onSuccess = async ({ holds, success }) => {
          let message = '';

          if (ia(holds)) {
            let newErrors = {};
            holds.forEach((v) => {
              set(newErrors, v?.field, capitalize(v?.message));
              showAlert({
                color: 'danger',

                title: capitalize(v?.field),
                message: capitalize(v?.message)
              });
            });
            setErrors(newErrors);
          }

          if (success) {
            showAlert({
              color: 'success',
              title: 'Demographics updated successfully!'
            });
            queryClient.invalidateQueries(['patient', patientId]);
            queryClient.invalidateQueries(['patients']);
          }
          if (message) {
            showAlert({
              color: 'warning',
              title: 'Some entries have not been saved',
              message
            });
          }
        };
        const onError = (error) => {
          showAlert({ message: error, color: 'danger' });
        };

        await requestApi({
          url: '/api/user/update',
          params,
          navigate,
          onSuccess,
          onError
        });
      }
    }

    if (updatedAddress || updatedSecondaryAddress) {
      updateAddress({
        addressType: 'primary',
        updatedAddress,
        initialVal: initialValues,
        patientId,
        queryClient
      });
      updateAddress({
        addressType: 'secondary',
        updatedAddress: updatedSecondaryAddress,
        initialVal: initialValues,
        patientId,
        queryClient
      });
    }
  } catch (error) {
    console.log(error);
  }
};

export default handleSubmitPatientChanges;
