import React from 'react';
import cs from 'classnames';

export const WidgetComponent = (props) => {
  const Widget = props.widgets[props.item.widget];
  return <Widget {...props} />;
};

export default function Widget({
  editMode,
  item,
  setDeleteWidgetModal,
  addedItem,
  widgets,
  ...props
}) {
  return (
    <div
      data-dd-privacy="allow"
      className={cs(
        'flex h-full w-full flex-col gap-2 overflow-hidden !rounded-lg !border-[#EFEFEF] bg-white shadow-[0px_6px_10px_rgba(177,177,177,0.08)]',
        editMode && item.widget !== 'upcoming_appointments'
          ? '!border-dashed'
          : ' border border-solid',
        addedItem === item.i && '!border-primary-500',
        item.widget === 'upcoming_appointments' &&
          item.x === 2 &&
          '-mt-[40px] !h-[calc(100%+40px)]',
        window.innerWidth < 768 && ' sm:mt-0 sm:!h-full'
      )}>
      <WidgetComponent
        item={item}
        widgets={widgets}
        editMode={editMode}
        setDeleteWidgetModal={setDeleteWidgetModal}
        {...props}></WidgetComponent>
    </div>
  );
}
