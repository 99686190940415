import { useQueryClient } from '@tanstack/react-query';
import MedicationsSimpleTable from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/MedicationsSimpleTable';
import { createMedicationNarrative } from 'components/shared/Forms/Custom/lib/medicationNarrativeHelper';
import { MEDICATION_STATUS } from 'components/shared/Forms/Custom/ProviderMedications/lib/constants';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import React from 'react';
import { useParams } from 'react-router-dom';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { filterTodayOnly } from '../../lib/renderedHelper';
import { useRecoilValue } from 'recoil';
import practiceState from 'components/practice/practiceState';

const Medications = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData } = customClinicalNote ?? useClinicalNoteContext();
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const { timezone } = useRecoilValue(practiceState.currentPractice);
  const {
    isNarrativeChecked,
    isTemplateChecked,
    isActiveMedicationsChecked,
    isInactiveMedicationsChecked,
    isTodaysDataOnlyChecked
  } = checkedFormTypes || {};

  const { id: patientId } = useParams();

  const data = overviewData?.medicationHistory;

  const queryClient = useQueryClient();
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'medications',
        patientId: patientId ?? clinicalNote?.user_id
      },
      dependencies: [patientId ?? clinicalNote?.user_id, 'medications'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          queryClient.setQueryData(['patientAggregateNarrative', patientId, 'medications'], () => ({
            data: {
              narrative: {
                narrative: createMedicationNarrative(iaRa(data)),
                synced: true
              }
            }
          }));
        }
      }
    });

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (customClinicalNote && !ia(data)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Medications</h3>

      {isTodaysDataOnlyChecked && (
        <MedicationsSimpleTable
          data={filterTodayOnly(timezone, data)}
          emptyState="No medications added today"
        />
      )}

      {isActiveMedicationsChecked && (
        <MedicationsSimpleTable
          data={data.filter((item) => item.status === MEDICATION_STATUS.ACTIVE)}
          emptyState="No active medications"
        />
      )}

      {isInactiveMedicationsChecked && (
        <MedicationsSimpleTable
          data={data.filter((item) => item.status === MEDICATION_STATUS.INACTIVE)}
          emptyState="No inactive medications"
        />
      )}

      {isTemplateChecked && <MedicationsSimpleTable data={data} />}

      {isNarrativeChecked && !!ia(data) && (
        <div
          className={
            (!isTemplateChecked && '!mt-2', 'text-xs font-500 text-neutral-700 print:text-base')
          }>
          <SanitizeComponent value={patientNarrative?.narrative} />
        </div>
      )}
    </div>
  );
};

export default Medications;
