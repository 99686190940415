import React from 'react';
import Icon from '../../../../../../../shared/Icon/Icon';
import Transcript from './Transcript';

import { useParams } from 'react-router-dom';
import TranscriptModal from './TranscriptModal';
import UnprocessedAudio from './UnprocessedAudio';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const RecordVisitDisclaimer = () => {
  return (
    <div className="inline-flex flex-col items-start justify-start gap-2 !rounded-lg bg-primary-50 !px-6 !py-4">
      <div className="inline-flex items-center justify-between self-stretch">
        <div className="flex items-center justify-start !gap-1">
          <Icon icon="new-info" size="15px" color="primary" />
          <div className="text-sm font-medium leading-snug text-primary-500">
            Patient Disclosure
          </div>
        </div>
        <div className="relative h-[18px] w-[18px]"></div>
      </div>
      <div className="self-stretch text-sm font-normal italic text-primary-900">
        “Please be advised that during appointments, we may use AI voice-to-text technology for
        accurate record-keeping. Your voice recordings are strictly confidential and will only be
        used for medical purposes within our facility. We guarantee that they will not be shared
        publicly. Your privacy is our priority!”
      </div>
      <p className="!mt-3 text-sm text-primary-600">
        Please feel free to verbalize this to patients to create comfort to streamlined, HIPAA.
        Compliant charting tools.
      </p>
    </div>
  );
};

const RecordVisitText = (state) => {
  const stateText = {
    START: (
      <>
        <p className="text-sm text-primary-900">MyScribe AI is actively documenting.</p>

        <p className="!mt-4">
          For optimal performance, please keep the screen open while engaging in real conversations
          with your patients.
        </p>
      </>
    ),
    PAUSE: <p className="text-sm text-primary-900">MyScribe AI is paused...</p>
  };

  const defaultText = (
    <>
      <p className="text-sm text-primary-900">
        Our AI streamlines the documentation process by effortlessly capturing and transcribing
        conversations between healthcare providers and patients in a HIPAA complaint manner.
      </p>

      <p className="!mt-4 text-sm text-primary-900">
        Click below to revolutionize your charting with MyScribe AI!
      </p>
    </>
  );

  return stateText[state] ?? defaultText;
};

const RecordVisitComponent = () => {
  const { currentState } = useAmbientListeningContext();
  const { appointmentId } = useParams();

  return (
    <div className="relative !rounded-lg bg-primary-100">
      <Icon icon="new-myscribe-bg-circle-left" className="absolute -top-4 bottom-0 left-0 z-[0]" />
      <div className="!mt-8 inline-flex flex-col items-start justify-start gap-2 !px-6 !py-9">
        <div className="inline-flex items-center justify-between self-stretch">
          <div className="flex items-center justify-start !gap-2">
            <div className="relative z-20 !ml-9 flex !h-7 !w-7 items-start justify-center self-center rounded-full bg-white">
              <Icon
                icon="new-myscribe-bg-circles"
                className="absolute bottom-1/2 top-1/2 z-10 animate-pulse"
              />
              <Icon
                icon="new-microphone"
                color="primary"
                className="absolute bottom-1/2 top-1/2 z-20"
              />
            </div>
            <div className="relative z-20 select-none">
              <div className="bg-gradient-to-r from-[#13B8FF] to-[#007BB0] bg-clip-text text-lg font-medium leading-snug text-transparent">
                MyScribe AI
              </div>
              <Icon icon="new-star-v2" className="absolute -right-[24px] -top-[4px]" />
            </div>
          </div>
          <div className="relative h-[18px] w-[18px]"></div>
        </div>
        <div className="!my-12 self-stretch text-base font-normal text-primary-900">
          {RecordVisitText(currentState)}
        </div>

        <Transcript
          name="Start Recording"
          className="bg-white !px-[15px] !py-3 text-primary-600"
          customId={appointmentId}
        />
      </div>
      <Icon icon="new-stars-bg" className="absolute left-0 top-0" />
    </div>
  );
};

const RecordVisit = () => {
  const { showTranscriptModal, setShowTranscriptModal } = useAmbientListeningContext();

  return (
    <div className="z-50">
      <UnprocessedAudio />

      <div className="!mt-4">
        <RecordVisitDisclaimer />
      </div>
      <div className="relative !mt-4">
        <RecordVisitComponent />
      </div>

      <TranscriptModal
        showModal={showTranscriptModal}
        hideModal={() => setShowTranscriptModal(false)}
      />
    </div>
  );
};

export default RecordVisit;
