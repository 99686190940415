import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { completeInstantForm } from 'api/CustomForms';

import { imagePath } from 'lib/helpers/utility';

import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';

import CheckinLoginWarning from '../LoginWarning';
import { propertyPathMap } from '../constants';

import PersonalIdUpload from './PersonalIdUpload';

const PersonalID = () => {
  const { updateSteps, checkinButton, user, appointmentId, kiosk } = useOutletContext();

  const [imageEdit, setImageEdit] = useState(null);

  const breadcrumb = useBreadcrumb([
    {
      title: 'Front of ID',
      name: 'front'
    },
    {
      title: 'Back of ID',
      name: 'back'
    }
  ]);

  useEffect(() => {
    if (!!imageEdit && breadcrumb?.currentStep) {
      setImageEdit(breadcrumb.currentStep);
    }
  }, [breadcrumb?.currentStep]);

  const navigate = useNavigate();

  const checkPhotoIdsExist = async () => {
    if (user.personal_id_image?.back && user.personal_id_image?.front) {
      await completeInstantForm(navigate, {
        formId: propertyPathMap.personalIdImageComplete.path()
      });
      updateSteps({
        version: 2,
        newStatus: 'completed',
        formName: propertyPathMap.personalIdImageComplete.name(),
        isCompleted: true
      });
    } else {
      updateSteps({
        formName: propertyPathMap.personalIdImageComplete.name(),
        isCompleted: false
      });
    }
    return true;
  };

  return (
    <>
      <div className="relative rounded-lg !p-8">
        <label className="text-base font-600 text-neutral-800">Identification document</label>
        {!user?.authenticated && !user?.isOnboarding && <CheckinLoginWarning />}
        <p className="pt-1 text-sm text-neutral-700">
          Please provide a valid and clear copy of your identification document. Ensure that all
          personal information is visible and legible, including your full name, date of birth,
          photo, and any relevant identification numbers!
        </p>
        <div className="flex gap-4 !pt-4 sm:flex-col">
          <div className="mb-4 grid w-full grid-cols-2 gap-2 sm:grid-cols-1">
            <div>
              <p className="mb-2 text-sm font-500">Front of ID Card</p>
              <div
                onClick={() => {
                  setImageEdit('front');
                  breadcrumb?.goToStep('front');
                }}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {user?.personal_id_image?.front ? (
                  <img
                    className="aspect-[5/3] w-full  overflow-hidden rounded-xl object-cover"
                    src={imagePath(user?.personal_id_image?.front)}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <p className="mb-2 text-sm font-500">Back of ID Card</p>
              <div
                onClick={() => {
                  setImageEdit('back');
                  breadcrumb?.goToStep('back');
                }}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {user?.personal_id_image?.back ? (
                  <img
                    className="aspect-[5/3] w-full overflow-hidden rounded-xl bg-black object-cover"
                    src={imagePath(user?.personal_id_image?.back)}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div hidden ref={checkinButton} onClick={() => checkPhotoIdsExist()}>
          Continue
        </div>
      </div>
      <PersonalIdUpload
        user={user}
        kiosk={kiosk}
        appointmentId={appointmentId}
        breadcrumb={breadcrumb}
        imageEdit={imageEdit}
        setImageEdit={setImageEdit}
      />
    </>
  );
};

export default withErrorBoundary(PersonalID);
