import React from 'react';

import moment from 'moment';

import {
  camelCaseToReadableFormat,
  cmToFt,
  formatDate,
  formatDateAndTime,
  isEmpty,
  separatePoundsAndOunces
} from 'lib/helpers/utility';

export function formatDataForTable(data = []) {
  return data.reduce((acc, item) => {
    const { type, createdAt, created_at, response, id } = item;
    const date = createdAt || created_at;

    let groupData = [];
    switch (type) {
      case 'medications':
        groupData = item?.medications?.map((med) => ({
          ...med,
          type,
          isSelected: false,
          created_at: med.created_at || date
        }));
        break;
      case 'vitals':
        groupData = item?.vitals?.map((vital) => {
          return {
            ...vital,
            type,
            isSelected: false
          };
        });
        break;
      case 'socialHistory':
        groupData = response?.row
          ? response.row.map((rowItem) => ({
              ...rowItem,
              id,
              type,
              isSelected: false,
              created_at: rowItem.created_at || date
            }))
          : [{ ...response, id, type, isSelected: false, created_at: date }];
        break;
      case 'familyHistory':
      case 'vaccines':
        groupData = response?.row
          ? response.row.map((rowItem) => ({
              ...rowItem,
              type,
              isSelected: false,
              created_at: rowItem.created_at || date
            }))
          : [{ ...response, type, isSelected: false, created_at: date }];
        break;
      case 'allergies':
        groupData = response?.allergies?.map((allergyItem) => ({
          ...allergyItem,
          type,
          isSelected: false,
          created_at: allergyItem.created_at || date
        }));
        break;
      default:
        groupData = [{ ...item, type, isSelected: false, created_at: date }];
        break;
    }

    // Append formatted group data to the final list
    acc.push(...groupData);
    return acc;
  }, []);
}

const getVitalsPreview = (vitals = []) => {
  let vitalMapping = [];
  for (const vital of vitals) {
    const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);
    const bloodPressure =
      vital?.blood_pressure_systolic || vital?.blood_pressure_diastolic
        ? `${vital?.blood_pressure_systolic || ''} / ${vital?.blood_pressure_diastolic || ''}`
        : '';

    const weight = pounds || ounces ? `${pounds || ''} lb ${ounces || ''} oz` : '';

    vitalMapping = [
      ...vitalMapping,
      ...[
        {
          label: (
            <div className="mt-7 text-xl font-semibold text-neutral-800 underline">
              {formatDateAndTime(vital?.created_at)}
            </div>
          ),
          value: '',
          unit: 'mmHg'
        },
        { label: 'Blood pressure', value: bloodPressure, unit: 'mmHg' },
        { label: 'Heart rate', value: vital?.heart_rate, unit: 'bpm' },
        { label: 'Respirations', value: vital?.respirations, unit: 'bpm' },
        { label: 'Weight', value: weight },
        { label: 'Temperature', value: vital?.temperature, unit: '°F' },
        { label: 'Height', value: cmToFt(vital?.height), unit: 'ft' },
        { label: 'Bmi', value: vital?.bmi, unit: 'kgm²' },
        { label: 'Oxygen level', value: vital?.oxygen_level, unit: '%' },
        { label: 'Note', value: vital?.notes, colSpan: true }
      ]
    ];
  }
  return vitalMapping;
};

const getAllergies = (data) => [
  { label: 'Allergy', value: data?.allergy?.value },
  { label: 'Medication Provided', value: data?.medication?.value },
  { label: 'Typical Reaction', value: data?.typicalReaction?.value },
  { label: 'Typical Reaction', value: data?.note }
];

const getVaccines = (data) => [
  { label: 'Vaccine', value: data?.vaccine?.value },
  { label: 'Doses taken', value: data?.doses?.value },
  { label: 'Date of last dose', value: formatDate(data?.lastDose) },
  { label: 'Note', value: data?.note }
];

const getSocialHistory = (data) => {
  const getValue = (value) => {
    return Object.entries(value)?.map(([subKey, subValue]) => {
      if (subValue) {
        let displayValue = subValue;

        if (subKey === 'yes' && subValue === true) {
          displayValue = `${camelCaseToReadableFormat(subKey)}\n`;
        } else if (subKey === 'contracteption' && subValue === true) {
          displayValue = 'contracteption: Yes';
        } else if (typeof subValue === 'string' && moment(subValue, moment.ISO_8601).isValid()) {
          displayValue = `${camelCaseToReadableFormat(subKey)}: ${formatDate(subValue)}`;
        } else if (subKey !== 'yes' && subValue === true) {
          displayValue = camelCaseToReadableFormat(subKey);
        } else {
          displayValue = `${camelCaseToReadableFormat(subKey)}: ${subValue}`;
        }

        return displayValue;
      }
    });
  };
  return Object.entries(data)
    .map(([key, value]) => {
      if (key === 'narrative') {
        return null;
      }
      return {
        label: camelCaseToReadableFormat(key),
        value: getValue(value)
      };
    })
    .filter(Boolean);
};

const getFamilyHistory = (data) => [
  { label: 'Medical condition', value: data?.medicalCondition.value },
  (data.fatherSide || data.motherSide) && {
    label: 'Inheritance',
    value: data?.fatherSide ? 'Father side' : 'Mother side'
  },
  !isEmpty(data?.note) && { label: 'Quantity', value: data?.note }
];

const getMedications = (data) => [
  { label: 'Medication', value: data?.medication },
  { label: 'SIG', value: data?.sig },
  { label: 'Strength', value: data?.strength },
  { label: 'Quantity', value: data?.quantity },
  { label: 'Days Supply', value: data?.days_supply },
  { label: 'Refills', value: data?.refills },
  { label: 'Condition', value: data?.condition }
];

export const getFinalPreviewData = (previewData) => {
  const { type, ...data } = previewData || {};

  let previewDetails;
  switch (type) {
    case 'vitals':
      previewDetails = getVitalsPreview(data.vitals);
      break;
    case 'allergies':
      previewDetails = getAllergies(data);
      break;
    case 'vaccines':
      previewDetails = getVaccines(data);
      break;
    case 'medications':
      previewDetails = getMedications(data);
      break;
    case 'socialHistory':
      previewDetails = getSocialHistory(data);
      break;
    case 'familyHistory':
      previewDetails = getFamilyHistory(data);
      break;
    default:
      break;
  }
  return previewDetails;
};
