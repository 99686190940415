const { requestApi } = require('../../../api/Api');

module.exports = async ({ navigate, setUser, setPractice, location, setShow }) => {
  try {
    const onSuccess = (data, resData) => {
      setUser(data?.user);
      setPractice(data?.practice);
      if (location.pathname !== resData?.redirect) {
        // we are in the current path, no need to redirect, just set the recoil states
        navigate(resData?.redirect);
      }
      setShow((prevState) => ({ ...prevState, loading: false }));
    };
    const onError = (error, code) => {
      switch (code) {
        case 5:
          navigate('/activate/error/nes');
          break;

        default:
          navigate('/activate/error');
          break;
      }
    };
    setShow((prevState) => ({ ...prevState, loading: true }));
    await requestApi({
      url: '/api/user/invitation/getData',
      params: { forceEditPhone: location.pathname?.includes('new-phone') },
      onSuccess,
      onError,
      navigate
    });
  } catch (error) {
    console.error(error);
  }
};
