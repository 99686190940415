import React from 'react';
import Logs from 'components/shared/Logs';
import formatDate from 'lib/helpers/utility';
const AdminPractitionersLogs = () => {
  const defaultLogFilters = () => ({
    order: { label: 'Newest', value: 'desc' },
    date: {
      values: {
        startDate: null,
        endDate: null
      },
      type: 'date-range',
      title: 'Date',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    },
    id: {
      type: 'search',
      values: '',
      title: 'ID'
    },
    url: {
      type: 'search',
      values: '',
      title: 'URL'
    },
    data: {
      type: 'search',
      values: '',
      title: 'Data'
    }
  });

  const filters = defaultLogFilters();

  return <Logs defaultLogsFilters={filters} />;
};

export default AdminPractitionersLogs;
