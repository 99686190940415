import moment from 'moment';

import { formatDate, iaRa, mString } from 'lib/helpers/utility';

import { displayDiagnosis, sumAdjustment } from '../../lib/utils';

export const formatUnmatchedClaims = ({ claims, selectedUnmatchedClaim }) => {
  let rows = [];

  claims.forEach((claim, index) => {
    const isSelected = claim.id === selectedUnmatchedClaim;
    const date = claim?.from_dos || claim.created_at;
    const claimDate = date ? formatDate(date) : '';
    const claimRow = {
      id: claim.id,
      index,
      select: true,
      date: claimDate,
      patientName: claim.user && claim.user?.fullName,
      procedure_code: '',
      pcn: claim.pcn,
      provider: 'N/A',
      diagnosis: displayDiagnosis(claim),
      billed: mString(claim?.total_charge * 100),
      adjustment: mString(sumAdjustment(isSelected ? claim.charge : claim.charge)),
      paid: mString(claim?.total_paid * 100),
      isClaimRow: true,
      charge: claim.charge,
      claimId: index
    };

    rows.push(claimRow);

    const relatedCharges = iaRa(claim?.charge).map((charge) => ({
      id: charge.chgid,
      select: false,
      date: moment(charge.from_dos, 'YYYYMMDD').format('MM/DD/YYYY'),
      patientName: `${claim.pat_name_f} ${claim.pat_name_l}`,
      procedure_code: charge.proc_code,
      provider: `Qty: ${charge.units} · Mods: ${[charge.mod1, charge.mod2, charge.mod3, charge.mod4]
        .filter((f) => f !== null)
        .join(', ')}`,
      diagnosis: '',
      billed: mString(charge.charge * 100),
      adjustment: iaRa(charge.adjustment),
      paid: mString(charge.paid * 100),
      isClaimRow: false,
      claimId: index
    }));

    rows = rows.concat(relatedCharges);
  });

  return rows;
};
