import { setPatientAggregateNarrativeState } from '../../lib/patientAggregateNarrativeStates';

export const handleNoKnown = async ({
  mutateNoKnown,
  queryClient,
  patientId,
  title,
  noKnownState,
  setNoKnownModal,
  setOverviewData
}) => {
  await mutateNoKnown(
    {
      upsertData: {
        user_type: 'provider',
        patient_id: patientId,
        form_type: title,
        narrative: null,
        synced: true,
        no_known: !noKnownState
      }
    },
    {
      onSuccess: async ({ data = {} } = {}) => {
        await setPatientAggregateNarrativeState({
          queryClient,
          keys: [patientId, title],
          payload: {
            id: data?.narrative?.id,
            no_known: data?.narrative?.no_known,
            narrative: null
          }
        });

        if (title === 'medications') {
          await queryClient.invalidateQueries(['medication']);
        } else {
          setOverviewData((prevState) => {
            if (prevState?.[title]) {
              delete prevState[title];
            }

            return { ...prevState };
          });
        }
      }
    }
  );
  setNoKnownModal(false);
};
