import Input from '../components/Input';

export const COLUMN_DEFS = [
  {
    field: 'payer.name',
    headerName: 'Payer',
    flex: 1
  },
  {
    field: 'allowable_amount_cents',
    headerName: 'Expected allowable amount',
    flex: 1,
    cellClass: 'no-border',
    cellStyle: { padding: '0' },
    cellRenderer: (params) => <Input params={params} />
  }
];

export const DEFAULT_COLUMN_DEFS = { minWidth: 100 };

export const ALLOWABLE_AMOUNT_TOOLTIP =
  'If ‘Expected allowable amount’ is edited it will override assumed medicare allowable amount';

export const ALLOWABLE_AMOUNT_TEXT = 'Allowable amounts';
