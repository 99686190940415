import React, { useMemo } from 'react';
import NotFound from '../../components/NotFound';
import AGChart from '../../../../shared/AGChart/AGChart';
import { formatLargeNumber } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';

const Outbound = (props) => {
  const data = props.dashboardData?.count?.communication;
  const { item, layout } = props;
  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const chartData = [
    {
      name: 'Text',
      value: parseInt(data?.outboundText || 0),
      fill: '#1DBF73'
    },
    {
      name: 'Email',
      value: parseInt(data?.outboundEmail || 0),
      fill: '#FF9F0A'
    },
    {
      name: 'e-Fax',
      value: parseInt(data?.outboundFax || 0),
      fill: '#FFE998'
    }
  ];

  const options = {
    data: chartData,
    width: width > 0 ? width / 2 : 0,
    height: height > 0 ? height - 30 - height / proportion : 0,
    series: [
      {
        type: 'bar',
        xName: 'name',
        xKey: 'name',
        yKey: 'value',
        cornerRadius: 12,
        fills: ['#1DBF73', '#FF9F0A', '#FFE998']
      }
    ],
    axes: [
      {
        position: 'bottom',
        type: 'category'
      },
      {
        position: 'left',
        type: 'number'
      }
    ]
  };
  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  if (!data?.totalOutbound > 0)
    return (
      <NotFound
        icon="new-illustration-8"
        className="!pt-6"
        iconClassName="!pb-3"
        title="No communications logs found!"
        description="You can start chatting on chart details right now."
      />
    );

  return (
    <div className="flex flex-wrap gap-3 !p-6 md:!gap-10">
      <div className="flex flex-1 flex-col">
        <p className="text-base font-medium text-neutral-500">Total</p>
        <p className="text-xl font-semibold text-primary-900">
          {formatLargeNumber(data.totalOutbound)}
        </p>
        <div className="mt-3 flex flex-col gap-1">
          {options?.data.map((item, index) => (
            <div className="flex items-center justify-between gap-2" key={index}>
              <div className="flex items-center gap-1">
                <div
                  style={{ backgroundColor: item.fill }}
                  className="!h-3 !w-[4px] rounded-lg bg-red-500"></div>
                <p>{item.name}</p>
              </div>
              <p className="font-medium">{formatLargeNumber(item.value)}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex">
        <AGChart options={options} key={chartKey} />
      </div>
    </div>
  );
};

export default Outbound;
