import React from 'react';
import { formatPhoneNumber } from 'lib/helpers/utility';
import Input from 'components/shared/Input/Input';

const ContactInformation = ({ practice }) => {
  return (
    <div className="w-full p-3">
      <div>
        <label className='font-500'>Contact Information</label>
        <div className="w-full p-3">
          <Input
            label="Twilio Phone"
            type="text"
            disabled
            placeholder="Twilio Number"
            name={'twilio_phone'}
            value={
              formatPhoneNumber(practice?.twilio_phone) ||
              'Practice does not have a Twilio phone at this time.'
            }
          />
        </div>
        <div className="w-full p-3 !py-0">
          <Input
            label="E-fax number"
            type="text"
            disabled
            placeholder="eFax Number"
            name={'fax_number'}
            value={
              formatPhoneNumber('+1' + practice?.eFax?.fax_number) ||
              'Practice does not have a fax number at this time.'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
