import React from 'react';
import { camelCaseToReadableFormat, io, isEmpty } from 'lib/helpers/utility';
import RenderNarrativeValue from './RenderNarrativeValue';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const RiskFactors = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const riskFactors = clinicalNote?.epn?.riskFactors;
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(riskFactors)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Risk factors</h3>
      {isTemplateChecked && (
        <div className="grid gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-3 first-of-type:!border-t-0">
          <div className="grid">
            <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
              <ul>
                {io(riskFactors) &&
                  Object.keys(riskFactors).map((key) => {
                    if (riskFactors[key] === true && key !== 'other') {
                      return (
                        <li className="list-inside list-disc first-letter:capitalize" key={key}>
                          {camelCaseToReadableFormat(key)}
                        </li>
                      );
                    } else if (key === 'otherNote' && riskFactors.other === true) {
                      return (
                        <li className="list-inside list-disc first-letter:capitalize" key={key}>
                          {riskFactors[key]}
                        </li>
                      );
                    }
                    return null;
                  })}
              </ul>
            </p>
          </div>
        </div>
      )}

      {isNarrativeChecked && !isEmpty(riskFactors?.narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={riskFactors?.narrative} />
        </div>
      )}
    </div>
  );
};

export default RiskFactors;
