import { useEraContext } from 'lib/context/EraContext/EraContext';
import { mString } from 'lib/helpers/utility';

import AdjustmentCellRenderer from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA/components/ViewEra/components/AdjustmentCellRenderer';
import RadioButton from 'components/shared/RadioButton/RadioButton';

export const DEFAULT_COLUMN_DEFS = {
  flex: 1,
  sortable: false,
  suppressMovable: false,
  minWidth: 100
};

export const GRID_OPTIONS = {
  groupDefaultExpanded: 1,
  suppressGroupDefaultExpanded: false,
  groupDisplayType: 'custom',
  groupHideOpenParents: true,
  showOpenedGroup: false,
  animateRows: true,
  suppressPropertyNamesCheck: true,
  autoGroupColumnDef: {
    headerName: 'Posted/DoS',
    minWidth: 250,
    cellRendererParams: {
      suppressCount: true,
      checkbox: false,
      innerRenderer: (params) => {
        return params.data?.date || '';
      }
    }
  }
};

export const SELECTED_CLAIM_COLUMN_DEFS = [
  {
    headerName: 'Procedure',
    field: 'proc_code'
  },
  {
    headerName: 'Quantity/Units',
    field: 'units'
  },
  {
    headerName: 'Modifiers',
    field: 'modifiers',
    valueFormatter: ({ data }) => {
      const modifiers = [data.mod1, data.mod2, data.mod3, data.mod4];
      return modifiers.filter(Boolean).join(', ');
    }
  },
  {
    headerName: 'Charge',
    field: 'charge',
    valueFormatter: ({ value }) => {
      return mString(value * 100);
    }
  }
];

export const CLAIMS_COLUMN_DEFS = [
  {
    headerName: '',
    field: 'select',
    maxWidth: 50,
    pinned: 'left',
    cellRenderer: function ActionsCellRenderer({ data }) {
      const { selectedUnmatchedClaim, handleClaimChange } = useEraContext();

      return data?.isClaimRow ? (
        <RadioButton
          id={data?.id}
          value={data?.id}
          isSelected={data?.id === selectedUnmatchedClaim}
          onChange={handleClaimChange}
          wrapperClassName="flex align-center justify-center h-full flex-1"
        />
      ) : null;
    }
  },
  {
    headerName: 'Posted/DoS',
    field: 'date',
    rowGroup: true,
    hide: true,
    valueGetter: (params) => params.data?.groupDate
  },
  {
    headerName: 'Posted/DoS',
    field: 'date',
    cellRenderer: (params) => params.data?.date || ''
  },
  {
    headerName: 'Patient/Service',
    field: 'procedure_code',
    cellRenderer: (params) => {
      if (params.data?.isClaimRow) {
        return params.data.patientName;
      }
      return params.data?.procedure_code || '';
    }
  },
  {
    headerName: 'Provider',
    field: 'provider'
  },
  {
    headerName: 'Diagnosis',
    field: 'diagnosis'
  },
  {
    headerName: 'Billed',
    field: 'billed'
  },
  {
    headerName: 'Adjustment',
    field: 'adjustment',
    autoHeight: true,
    autoWidth: true,
    minWidth: 350,
    cellRenderer: AdjustmentCellRenderer
  },
  {
    headerName: 'Pt. Paid',
    field: 'paid',
    cellRenderer: ({ data }) => {
      if (!data?.isClaimRow) return null;

      return data.paid;
    }
  },
  {
    headerName: 'Note',
    field: 'note',
    maxWidth: 100
  }
];
