import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { getAdminReportingCSV } from 'api/Admin/Reporting';
import { getPractices, getStaffPracticeAdmin } from 'api/Practice';
import { getPractitionersAdmin } from 'api/Practitioner';

import { useBoardContext } from 'lib/context/BoardContext/BoardContext';
import BoardContextProvider from 'lib/context/BoardContext/BoardContextProvider';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { ia, iaRa, mapValues } from 'lib/helpers/utility';
import { useAdminReports } from 'lib/hooks/queries/widgets/useAdminReports';
import usePageTitle from 'lib/hooks/usePageTitle';

import UpcomingAppointments from 'components/practice/Dashboard/widgets/UpcomingAppointments/UpcomingAppointments';
import ExportData from 'components/shared/AGTable/ExportData';
import Board from 'components/shared/CustomizableBoard/Board';
import StickyButton from 'components/shared/CustomizableBoard/StickyButton';
import DateRangePicker from 'components/shared/DateRange/DateRangePicker';
import Filter from 'components/shared/Filters/Filter';

import Header from './components/Header';
import ClaimMDWidget from './widgets/AdminDashboardPanel/ClaimMD/index';
import CommunicationsUsage from './widgets/AdminDashboardPanel/Communications';
import EncounterReports from './widgets/AdminDashboardPanel/EncounterReports/EncounterReports';
import MIDs from './widgets/AdminDashboardPanel/MIDs';
import PatientDashboardPanel from './widgets/AdminDashboardPanel/Panels/PatientDashboardPanel';
import PracticeDashboardPanel from './widgets/AdminDashboardPanel/Panels/PracticeDashboardPanel';
import StaffMembers from './widgets/AdminDashboardPanel/StaffMember';
import TransactionsDashboardPanel from './widgets/AdminDashboardPanel/Transaction/Transactions';
import Veradigm from './widgets/AdminDashboardPanel/Veradigm';
import { colDefs, getReportingCenterParams } from './widgets/lib/helpers';

const widgets = {
  practice_dashboard_panel: PracticeDashboardPanel,
  patient_dashboard_panel: PatientDashboardPanel,
  upcoming_appointments: UpcomingAppointments,
  communications_usage: CommunicationsUsage,
  transaction_dashboard_panel: TransactionsDashboardPanel,
  /*   income: Income, */
  encounter_reports: EncounterReports,
  staff_members: StaffMembers,
  veradigm: Veradigm,
  claim_md: ClaimMDWidget,
  mids: MIDs
};

export default function Dashboard() {
  return (
    <DashboardContextProvider>
      <BoardContextProvider
        layouts={{
          lg: [],
          xmd: [],
          md: [],
          sm: []
        }}
        cols={{ lg: 4, xmd: 12, md: 12, sm: 1 }}
        board="admin_reports">
        <DashboardBoard />
      </BoardContextProvider>
    </DashboardContextProvider>
  );
}

function DashboardBoard() {
  const { ranges, setRanges } = useDashboardContext();
  const navigate = useNavigate();

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    getDefaultFilters();
  }, []);

  useEffect(() => {
    if (!filters?.staff?.values) return;

    if (!filters?.staff?.values?.length) {
      getDefaultFiltersPractice();
      return;
    }

    findPracticesBasedOnStaff(filters.staff.values);
  }, [filters?.staff?.values]);

  useEffect(() => {
    if (!filters?.practice?.values) return;

    if (!filters?.practice?.values?.length) {
      getDefaultFiltersStaff();
      return;
    }

    findStaffBasedOnPractices(filters.practice.values);
  }, [filters?.practice?.values]);

  const getDefaultFilters = async () => {
    const defaultPractices = await searchPractices();
    const defaultStaff = await searchStaff();
    setFilters((prevState) => ({
      ...prevState,
      practice: {
        type: 'search',
        multiple: true,
        values: prevState?.practice?.values || [],
        options: defaultPractices,
        title: 'Practice',
        loadOptions: searchPractices
      },
      staff: {
        type: 'search',
        multiple: true,
        values: prevState?.staff?.values || [],
        options: defaultStaff,
        title: 'Staff',
        loadOptions: searchStaff
      }
    }));
  };

  const getDefaultFiltersStaff = async () => {
    const defaultStaff = await searchStaff();
    setFilters((prevState) => ({
      ...prevState,
      staff: {
        type: 'search',
        multiple: true,
        values: prevState?.staff?.values || [],
        options: defaultStaff,
        title: 'Staff',
        loadOptions: searchStaff
      }
    }));
  };
  const getDefaultFiltersPractice = async () => {
    const defaultPractices = await searchPractices();
    setFilters((prevState) => ({
      ...prevState,
      practice: {
        type: 'search',
        multiple: true,
        values: prevState?.practice?.values || [],
        options: defaultPractices,
        title: 'Practice',
        loadOptions: searchPractices
      }
    }));
  };

  const findPracticesBasedOnStaff = async (staffValues) => {
    const staffIds = iaRa(staffValues).map(({ value }) => value);
    const { practitioner } = await getPractitionersAdmin(navigate, {
      limit: 20,
      page: 1,
      userIds: staffIds
    });
    const practiceOptions = iaRa(practitioner).flatMap((practitionerItem) => {
      return iaRa(practitionerItem?.practices).map((practice) => ({
        label: practice?.name,
        value: practice?.id
      }));
    });
    setFilters((prevState) => ({
      ...prevState,
      practice: {
        ...prevState.practice,
        options: practiceOptions,
        loadOptions: (searchTerm) =>
          practiceOptions.filter((option) => !option.label.includes(searchTerm))
      }
    }));

    return practiceOptions;
  };

  const findStaffBasedOnPractices = async (practiceValues) => {
    const practiceIds = iaRa(practiceValues).map(({ value }) => value);
    const { members } = await getStaffPracticeAdmin(navigate, {
      limit: 20,
      offset: 0,
      practice_id: ia(practiceIds) ? practiceIds : null
    });
    const memberOptions = iaRa(members).map((member) => ({
      label: member?.fullName,
      value: member?.id
    }));
    setFilters((prevState) => ({
      ...prevState,
      staff: {
        ...prevState.staff,
        options: memberOptions,
        loadOptions: (searchTerm) =>
          memberOptions.filter((option) => !option.label.includes(searchTerm))
      }
    }));

    return memberOptions;
  };

  const searchPractices = async (searchTerm) => {
    const { practices } = await getPractices(navigate, {
      limit: 20,
      offset: 0,
      searchTerm
    });
    const practiceOptions = iaRa(practices).map((practice) => ({
      label: practice?.name,
      value: practice?.id
    }));

    return practiceOptions;
  };

  const searchStaff = async (searchTerm) => {
    const { members } = await getStaffPracticeAdmin(navigate, {
      limit: 20,
      offset: 0,
      practice_id: null,
      searchTerm
    });
    const memberOptions = iaRa(members).map((member) => ({
      label: member?.fullName,
      value: member?.id
    }));

    return memberOptions;
  };

  const { data: reportingData } = useAdminReports({
    params: getReportingCenterParams({ reportingFilters: filters, ranges }),
    dependencies: [ranges, mapValues(filters)]
  });
  const { editMode } = useBoardContext();

  usePageTitle('Dashboard');
  const generateCSV = async () => {
    const csvParams = {
      startAt: ranges.startDate,
      endAt: ranges.endDate,
      colDefs,
      category: 'admin_reports'
    };
    const data = await getAdminReportingCSV(navigate, csvParams);
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'admin_reporting.csv';
    a.click();
  };

  return (
    <div className="h-full overflow-y-auto overflow-x-hidden bg-neutral-50 !pb-8">
      <Header />
      <div className="-mt-14">
        <Board
          dashboardData={reportingData}
          breakpoints={{ lg: 1200, xmd: 800, md: 710, sm: 0 }}
          rowHeight={145}
          widgets={widgets}
          margin={[12, 12]}>
          <div className="absolute -top-[74px] right-[14px] z-20 flex items-center gap-2">
            {!editMode && (
              <DateRangePicker
                iconColor="white"
                buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)] sm:!bg-[#55C6EF] !py-0 !px-2 flex min-w-[30px] min-h-[30px]"
                buttonTextColor="text-white"
                customStyling={window.innerWidth < 677 ? { right: '-54px' } : {}}
                ranges={[
                  {
                    startDate: ranges?.startDate,
                    endDate: ranges?.endDate,
                    key: 'selection'
                  }
                ]}
                onChange={(e) => {
                  setRanges((prevState) => ({
                    ...prevState,
                    startDate: e.selection.startDate,
                    endDate: e.selection.endDate
                  }));
                }}
                startDate={ranges?.startDate}
                endDate={ranges?.endDate}
                minDate={-3650}
                maxDate={3650}
              />
            )}
            <Filter
              category="admin_reporting"
              defaultFilters={[]}
              btnClassName="text-white !h-[28px]"
              filters={filters}
              iconColor="white"
              setFilters={setFilters}
              menuPortalTarget={document.body}
            />
            <ExportData
              title="Generate Analytics Report"
              className="!bg-white/15"
              iconColor="white"
              onGenerateClick={generateCSV}
            />
            <StickyButton
              iconColor="white"
              className="!relative !top-auto !m-0 !h-auto"
              buttonClassName="!bg-[rgb(255,255,255,0.15)] !border-[rgb(255,255,255,0.2)]"
            />
          </div>
        </Board>
      </div>
    </div>
  );
}
