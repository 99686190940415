import { TYPE_LABELS } from 'lib/constants/transaction/typeLabels';
import { formatDate, formatDateAndTime, mString } from 'lib/helpers/utility';

import { UnAllocateAction } from '../components/UnAllocateAction';

export const getColDefs = (timezone) => [
  {
    field: 'dos',
    headerName: 'Date',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone),
    filter: 'agDateColumnFilter',
    sort: 'desc'
  },
  {
    field: 'payment',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'type',
    headerName: 'Type',
    valueFormatter: ({ value }) => TYPE_LABELS[value]
  },
  { field: 'cashier', headerName: 'Created by' },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: UnAllocateAction
  }
];

export const GRID_OPTIONS = {
  animateRows: true
};

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true, flex: 1 };

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
