import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getDataBanks } from 'api/DataBank';

export const useDataBanks = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['databanks', ...dependencies], () => getDataBanks(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};

