import React from 'react';

import cs from 'classnames';

import useModal from 'lib/hooks/useModal';

import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Typography from 'components/shared/Typography/Typography';

import { serviceChargeTypes } from '../../../../../../../../constants';
import Wrapper from '../Wrapper/Wrapper';

import NdcCodeModal from './components/NdcCodeModal';

const CodingSection = ({ formik }) => {
  const { isOpen, openModal, closeModal } = useModal();

  const onSave = (ndcCodes, type) => {
    formik.setFieldValue('ndc_codes', ndcCodes);
    formik.setFieldValue('searchType', type);
    closeModal();
  };

  return (
    <Wrapper isColumn>
      <div className="grid grid-cols-2 gap-4">
        <Select
          name="charge_type"
          label="Default charge type"
          isClearable={false}
          parentClassName="flex flex-1 flex-col"
          options={
            formik.values.procedure_code
              ? serviceChargeTypes
              : serviceChargeTypes?.filter((type) => type.value !== 'copay')
          }
          id="serviceChargeTypeSelect"
          value={formik.values.charge_type}
          placeholder="Type and select charge type"
          onChange={(value) => formik.setFieldValue('charge_type', value)}
          error={formik.touched.charge_type && formik.errors.charge_type}
        />
        <div className="grid grid-cols-2 gap-4">
          <Input
            label="Time Length(minutes)"
            type="number"
            name="time_length"
            id="timeLengthInput"
            data-qa="timeLength-input"
            value={formik.values.time_length}
            onChange={(e) => formik.setFieldValue('time_length', e.target.value)}
            onBlur={formik.handleBlur}
            error={formik.errors.time_length}
            required
          />
          <div
            className={cs(
              'flex flex-col flex-1 gap-2 justify-center items-start',
              formik.values.procedure_code ? 'visible' : 'invisible'
            )}>
            <Typography variant="small" weight="font-medium" className="text-neutral-800">
              NDC Codes
            </Typography>
            <Button
              text="Add NDC Code"
              icon="shade-plus"
              type="secondary"
              className="bg-transparent border-none !px-0"
              onClick={openModal}
            />
          </div>
        </div>
      </div>
      {isOpen && (
        <NdcCodeModal
          isOpen={isOpen}
          initialCodes={formik.values.ndc_codes}
          onClose={closeModal}
          onSave={onSave}
          type={formik.values.searchType}
        />
      )}
    </Wrapper>
  );
};

export default CodingSection;
