const handleChangeQuantity = (setFieldValue, currentValues, val, id, quantity, type) => {
  const sales_count = Number(val);

  if ((type === 'products' && quantity < sales_count) || sales_count < 1) return;

  const updatedValues = currentValues.map((s) => {
    if (s.id === id) {
      if (type === 'products') {
        return { ...s, sales_count, total_amount_cents: s.amount_cents_with_tax * sales_count };
      } else {
        return { ...s, quantity: sales_count, pt_balance: s.total_cost_cents * sales_count };
      }
    } else {
      return s;
    }
  });

  setFieldValue(type, updatedValues);
};

export default handleChangeQuantity;
