import { ia } from '../lib/helpers/utility';
import { requestApi } from './Api';

export const getVitals = async (navigate, params) => {
  const result = await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/read',
    params
  });

  const { vitals } = result;
  const lastVitals = ia(vitals) ? vitals[0] : {};
  delete lastVitals.created_by;
  delete lastVitals.created_at;
  delete lastVitals.updated_at;
  delete lastVitals.patient_id;
  delete lastVitals.practice_id;

  const desiredOrder = [
    'id',
    'heart_rate',
    'respirations',
    'blood_pressure_systolic',
    'blood_pressure_diastolic',
    'weight',
    'temperature',
    'height',
    'bmi',
    'oxygen_level',
    'head_circumference',
    'head_circumference_unit',
    'notes',
    'created_at'
  ];

  const orderedVitals = {};
  desiredOrder.forEach((key) => {
    if (lastVitals.hasOwnProperty(key)) {
      orderedVitals[key] = lastVitals[key];
    }
  });

  return orderedVitals;
};

export const upsertVital = async (navigate, params, onError, onSuccess) =>
  await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/create',
    params,
    onError,
    onSuccess
  });

export const deleteVital = async (navigate, params, onSuccess, onError) =>
  await requestApi({
    navigate,
    url: '/api/clinical_note/vitals/delete',
    params,
    onError,
    onSuccess
  });
