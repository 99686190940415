import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import { usePrompts } from 'lib/hooks/queries/myscribeAI/usePrompts';

import {
  clinicalNote as clinicalNoteState,
  myScribeAutoMapPromptListState
} from 'components/state';

import { MyScribeTemplateContextApi, MyScribeTemplateContextData } from './MyScribeTemplateContext';

const conversations = [
  { id: 0, name: 'Basic Greeting', description: 'Start a simple conversation.' },
  { id: 1, name: 'Ask for Assistance', description: 'Request help with a task or problem.' },
  { id: 2, name: 'Provide Information', description: 'Ask for detailed information on a topic.' },
  { id: 4, name: 'Feedback Request', description: 'Submit content and get feedback.' },
  { id: 5, name: 'Problem Solving', description: 'Work together to solve a specific issue.' },
  { id: 6, name: 'Creative Writing', description: 'Get help with writing a story, poem, or idea.' },
  { id: 7, name: 'Coding Assistance', description: 'Ask for help with programming or debugging.' }
];

const getPrompt = (id) => {
  const prompts = {
    0: [
      { id: 0, message: 'Hi, how are you?', agent: 'user' },
      { id: 1, message: "I'm great, thank you! How can I assist you today?", agent: 'ai' },
      { id: 2, message: 'What are the key benefits of using React?', agent: 'user' },
      {
        id: 3,
        message:
          'React offers benefits like component-based architecture, fast rendering with a virtual DOM, and a vast ecosystem of tools and libraries.',
        agent: 'ai'
      },
      { id: 4, message: 'What are the key benefits of using React?', agent: 'user' },
      {
        id: 5,
        message:
          'React offers benefits like component-based architecture, fast rendering with a virtual DOM, and a vast ecosystem of tools and libraries.',
        agent: 'ai'
      },
      { id: 6, message: 'What are the key benefits of using React?', agent: 'user' },
      {
        id: 7,
        message:
          'React offers benefits like component-based architecture, fast rendering with a virtual DOM, and a vast ecosystem of tools and libraries.',
        agent: 'ai'
      }
    ],
    1: [
      { id: 0, message: 'Can you help me find a good book to read?', agent: 'user' },
      { id: 1, message: 'Sure! What genre are you interested in?', agent: 'ai' }
    ],
    2: [
      { id: 0, message: 'What is the capital of France?', agent: 'user' },
      { id: 1, message: 'The capital of France is Paris.', agent: 'ai' }
    ],
    4: [
      { id: 0, message: 'Here’s my resume. Could you review it?', agent: 'user' },
      { id: 1, message: 'Absolutely! Let me take a look and provide feedback.', agent: 'ai' }
    ],
    5: [
      { id: 0, message: "I can't figure out why my laptop won't start.", agent: 'user' },
      {
        id: 1,
        message: 'Let’s troubleshoot. Do you see any lights when you press the power button?',
        agent: 'ai'
      }
    ],
    6: [
      {
        id: 0,
        message: 'Can you help me write a short story about a detective?',
        agent: 'user'
      },
      { id: 1, message: 'Certainly! Do you have a specific setting or plot in mind?', agent: 'ai' }
    ],
    7: [
      {
        id: 0,
        message: 'Why is my JavaScript function not returning the expected value?',
        agent: 'user'
      },
      { id: 1, message: 'Can you share the code snippet so I can take a closer look?', agent: 'ai' }
    ]
  };

  return prompts[id];
};

export const MyScribeTemplateContextProvider = ({ children }) => {
  // const [chatLog, setChatLogs] = useState([]);
  // const [selectedForms, setSelectedForms] = useState([]);
  const [localPrompts, setLocalPrompts] = useRecoilState(myScribeAutoMapPromptListState);

  const { appointmentId } = useParams();

  const clinicalNote = useRecoilValue(clinicalNoteState);

  const {
    data: promptData,
    isFetching,
    isLoading
  } = usePrompts({
    params: {
      clinicalNoteId: clinicalNote?.id,
      appointmentId
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id
    }
  });
  const isGetPromptsLoading = isFetching || isLoading;
  const prompts = promptData?.conversations;

  useEffect(() => {
    setLocalPrompts(prompts || []);
  }, [prompts]);

  const data = useMemo(
    () => ({
      localPrompts,
      conversations,
      isGetPromptsLoading
    }),
    [isGetPromptsLoading, localPrompts]
  );

  const api = useMemo(() => ({ setLocalPrompts, getPrompt }), [setLocalPrompts]);

  return (
    <MyScribeTemplateContextData.Provider value={data}>
      <MyScribeTemplateContextApi.Provider value={api}>
        {children}
      </MyScribeTemplateContextApi.Provider>
    </MyScribeTemplateContextData.Provider>
  );
};
