export default function getPatientFormsStatus(steps = []) {
  const allCompleted = steps.every((step) => step.completed === true);
  const noneCompleted = steps.every((step) => step.completed === false);

  if (allCompleted) {
    return 'success';
  } else if (noneCompleted) {
    return 'danger';
  } else {
    return 'warning';
  }
}
