import React from 'react';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useParams } from 'react-router-dom';
import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS, cols } from './lib/helpers';
import Transactions from 'components/shared/Transactions/transactions';
import Allowed from 'components/shared/Permissions/Allowed';

export default function AdminPatientsTransactions() {
  const { id: patientId } = useParams();
  return (
    <Allowed requiredPermissions="payments.read" showIllustration showMessage>
      <TableContextProvider
        name="patient_transactions_admin"
        defaultFilters={DEFAULT_FILTERS}
        pagination
        cols={cols}>
        <Transactions
          category="patient_transactions_admin"
          patientId={patientId}
          defaultColumnDefs={DEFAULT_COLUMN_DEFS}
          gridOptions={GRID_OPTIONS}
          defaultFilters={DEFAULT_FILTERS}
        />
      </TableContextProvider>
    </Allowed>
  );
}
