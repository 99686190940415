import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';
import useHasScrollbar from 'lib/hooks/useHasScrollbar';
import useScroll from 'lib/hooks/useScroll';
import React from 'react';
import cs from 'classnames';
import { formatPhoneNumber } from 'lib/helpers/utility';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import useCopy from 'lib/hooks/useCopy';
import { showAlert } from 'components/shared/Alert/Alert';

const PatientDetails = ({ patient = {}, showAsTabs = false }) => {
  const { copyToClipboard } = useCopy();
  const [tabRef, hasScrollbar, canScrollLeft, canScrollRight] = useHasScrollbar({
    type: 'horizontal'
  });

  const copyEmail = () => {
    copyToClipboard(patient?.email);
    showAlert({
      title: 'Email copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  return (
    <div className={cs('relative flex overflow-hidden bg-white', showAsTabs && 'pl-10 pr-2')}>
      {canScrollLeft && (
        <Icon
          icon="chevron-left"
          className="absolute left-0 z-10 flex h-full w-12 min-w-[60px] items-center justify-center bg-gradient-to-l from-transparent via-neutral-50 to-neutral-50 !pr-4 duration-100 hover:-translate-x-1"
          onClick={useScroll({ ref: tabRef, direction: 'left', distance: 200 })}
        />
      )}
      <div className={cs('flex w-full gap-3', hasScrollbar && 'overflow-hidden')} ref={tabRef}>
        <div className="flex flex-col gap-[2px]">
          <p className="whitespace-nowrap text-sm font-500 text-primary-800">Chart ID</p>
          <p className="whitespace-nowrap text-xs text-neutral-800">{patient?.id}</p>
        </div>
        <div className="flex flex-col gap-[2px]">
          <p className="whitespace-nowrap text-sm font-500 text-primary-800">Phone</p>
          <p className="whitespace-nowrap text-xs text-neutral-800">
            {formatPhoneNumber(patient?.phone)}
          </p>
        </div>
        <div className={cs('flex flex-col gap-[2px]', !showAsTabs && 'max-w-[280px]')}>
          <p className="text-sm font-500 text-primary-800">Address</p>
          <Tippy
            className="tippy-dark"
            content={patient?.userAddress?.fullAddress}
            placement="bottom">
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
              {patient?.userAddress?.fullAddress}
            </p>
          </Tippy>
        </div>
        <div
          className={cs('flex cursor-pointer flex-col gap-[2px]', !showAsTabs && 'max-w-[264px]')}
          onClick={copyEmail}>
          <p className="text-sm font-500 text-primary-800">Email</p>
          <Tippy className="tippy-dark" content={patient?.email} placement="bottom">
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
              {patient?.email}
            </p>
          </Tippy>
        </div>
      </div>
      {canScrollRight && (
        <Icon
          icon="chevron-right"
          className="absolute right-0 z-10 flex h-full w-12 min-w-[60px] cursor-pointer items-center justify-center bg-gradient-to-r from-transparent via-white to-white !pl-4 duration-100 hover:translate-x-1"
          onClick={useScroll({ ref: tabRef, direction: 'right', distance: 200 })}
        />
      )}
    </div>
  );
};

export default withErrorBoundary(PatientDetails);
