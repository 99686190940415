import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';

const SelfScheduling = ({ practice, isServicesAvailable }) => {
  const navigate = useNavigate();
  const copyToClipboard = async (token) => {
    try {
      navigator.clipboard.writeText(
        `${window.location.protocol}//${window.location.host}/book?token=${token}`
      );
      showAlert({
        message: 'Copied to Clipboard',
        color: 'success'
      });
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div className="w-full p-3">
      <div>
        <label className='font-500'>Self Scheduling</label>
        <div className="w-full p-3">
          {practice?.token && isServicesAvailable ? (
            <label
              onClick={() => copyToClipboard(practice?.token)}
              className="label flex w-max cursor-pointer">
              Self Scheduling Link <Icon className="ml-4" icon="copy" />
            </label>
          ) : (
            <label className="flex w-max cursor-pointer">
              Self scheduling unavailable.&nbsp;
              <span onClick={() => navigate('/portal/settings')} className="underline">
                Click here
              </span>
              &nbsp; to add a service and assign practitioners.
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

export default SelfScheduling;
