import { requestApi } from 'api/Api';
import { createInstantPacket } from 'api/InstantPacket';
import { interimApi } from 'api/InterimApi';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import PreAuthFormModal from 'components/shared/Modal/PreAuthFormModal/PreAuthFormModal';
import Allowed from 'components/shared/Permissions/Allowed';
import { useFormikContext } from 'formik';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useKiosks } from 'lib/hooks/queries/useKiosks';
import { ia } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { CustomSendModal } from './components/CustomSendModal/CustomSendModal';

const CCOnFile = ({ data }) => {
  const [showPreAuthModal, setShowPreAuthModal] = useState(false);
  const [show, setShow] = useState({
    loading: false,
    payment_result: 0,
    completed: false,
    paymentSelected: ''
  });
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [customContactType, setCustomContactType] = useState('email');
  const [formId, setFormId] = useState(null);
  const { values: appointment } = useFormikContext();
  const navigate = useNavigate();
  const { device } = useUIContext();
  const { data: kiosksQuery } = useKiosks({ params: {} });
  const kiosks = kiosksQuery?.kiosks || [];
  const { data: patient } = usePatient({
    params: { id: appointment?.patient?.id },
    dependencies: [appointment?.patient?.id]
  });

  const hidePreAuthModal = () => {
    setShowPreAuthModal(false);
  };

  const sendLink = async (type, formId, custom = null) => {
    const params = { formIds: ['pre_auth'], patientId: appointment.user_id };

    const { token } = await createInstantPacket(navigate, params);
    if (token) {
      const params = { link: token, patientId: appointment.user_id, custom };
      await requestApi({
        navigate,
        url: `/api/form/instant_packet/${type}`,
        params,
        onError: () => {
          showAlert({ title: `Failed to send ${type}`, color: 'danger' });
        },
        onSuccess: () => {
          showAlert({ title: `Successfully sent ${type}` });
        }
      });
    }
  };

  const sendFormToKiosk = async (kioskId) => {
    const packet = await createInstantPacket(navigate, {
      appointmentId: appointment.id,
      kiosk: true,
      patientId: appointment?.patient?.id,
      formIds: ['pre_auth']
    });

    await requestApi({
      url: '/api/kiosk/command',
      params: {
        url: packet?.token,
        command: 'url-change',
        page: 'form',
        kioskId,
        patientId: appointment?.patient?.id
      }
    });

    showAlert({ title: 'Sent to Kiosk', color: 'success' });
  };

  const patientNumbers = [
    { label: patient?.patient?.phone, value: patient?.patient?.phone },
    { label: patient?.patient?.alt_phone, value: patient?.patient?.alt_phone },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const patientEmails = [
    { label: patient?.patient?.email, value: patient?.patient?.email },
    { label: patient?.patient?.alt_email, value: patient?.patient?.alt_email },
    { label: 'Custom', value: 'Custom' }
  ].filter((it) => Boolean(it.label));

  const handleCustomSendModalClose = () => setCustomModalVisible(false);

  const ccCompleted = appointment.timeline.financial['cc-on-file'].color == 'success';

  return (
    <Allowed requiredPermissions="invoicing.read">
      <div className="flex flex-col gap-2">
        {!isEmpty(data) && (
          <>
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm  text-neutral-800 ">Signed</p>
              <p className="text-sm  text-neutral-800 ">{data.signed_on}</p>
            </div>
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm  text-neutral-800 ">Card on File</p>
              <p className="text-sm  text-neutral-800 ">{data.card_on_file}</p>
            </div>
          </>
        )}

        <div className="flex w-full items-center justify-between ">
          <p className="text-sm  text-neutral-800 ">Pre Auth Form</p>
          {!ccCompleted && (
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={() => setShowPreAuthModal(true)}>
              <p className="text-sm  text-primary-600">View</p>
              <Icon icon="new-document-preview" color="primary" />
              <Popover
                isFixed
                panelClassName="z-[200]"
                icon="three-dots"
                iconClassName="rotate-90"
                buttonClassName="!min-h-[20px] !min-w-[20px] !rounded-[3px]"
                options={[
                  {
                    label: 'Send Form to',
                    onClick: () => {},
                    icon: 'new-clinical-narrative',
                    color: 'primary',
                    size: 20,
                    children: [
                      ia(kiosks) && kiosks.length > 1
                        ? {
                            label: 'Kiosk',
                            icon: 'new-kiosk',
                            color: 'primary',
                            children: kiosks.map((kiosk) => ({
                              label: kiosk.name,
                              onClick: () => sendFormToKiosk(kiosk.id),
                              icon: 'kiosk-nav',
                              color: 'primary'
                            }))
                          }
                        : ia(kiosks) && {
                            label: 'Kiosk',
                            icon: 'new-kiosk',
                            color: 'primary',
                            onClick: () => sendFormToKiosk(kiosks[0].id)
                          },
                      {
                        label: 'Email',
                        icon: 'new-email-blue',
                        color: 'primary',
                        children: patientEmails.map((email) => ({
                          label: email.label,
                          onClick: () => {
                            if (email.value === 'Custom') {
                              setCustomModalVisible(true);
                              setCustomContactType('email');
                              setFormId('pre_auth');
                            } else {
                              sendLink('text', 'pre_auth', email.value);
                            }
                          }
                        }))
                      },
                      {
                        label: 'Text Message',
                        icon: 'text-message',
                        color: 'primary',
                        children: patientNumbers.map((number) => ({
                          label: number.label,
                          onClick: () => {
                            if (number.value === 'Custom') {
                              setCustomModalVisible(true);
                              setCustomContactType('text');
                              setFormId('pre_auth');
                            } else {
                              sendLink('text', 'pre_auth', number.value);
                            }
                          }
                        }))
                      }
                    ].filter(Boolean)
                  }
                ]}
                optionIconSize={18}
                rerender={() => {}}
              />
            </div>
          )}
        </div>
      </div>

      <PreAuthFormModal
        showPreAuthModal={showPreAuthModal}
        hidePreAuthModal={hidePreAuthModal}
        appointment={appointment}
        practiceId={appointment?.practice?.id}
        practiceName={appointment?.practice?.name}
        patientId={appointment?.patient?.id}
        patientName={`${appointment?.patient?.f_name} ${appointment?.patient?.l_name}`}
        billingAddress={appointment?.patient?.userAddress}
        type={!appointment?.insurance_benefits ? 'annual' : 'insurance_balance'}
        subModal={false}
        onSuccess={async () => {
          setShow({ ...show, loading: true });
          showAlert({
            message: 'Pre-Authorized Healthcare Form Saved Successfully!',
            position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
          });
          await interimApi('/api/patient/checkin/complete', { id: appointment.id }, navigate);
          // updateSteps({ formName: propertyPathMap.payment.name(), isCompleted: true });
        }}
        showOverrides={{
          cash: false,
          check: false,
          cardOnFile: false,
          careCredit: false,
          cardPresent: false,
          online: true,
          noFooter: false
        }}
      />
      {customModalVisible ? (
        <CustomSendModal
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
          sendLink={sendLink}
          formId={formId}
        />
      ) : null}
    </Allowed>
  );
};

export default CCOnFile;
