import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import { useUIContext } from '../../../lib/context/UIContext/UIContext';
import { usePatient } from '../../../lib/hooks/queries/patients/usePatient';
import usePageTitle from '../../../lib/hooks/usePageTitle';
import ErrorMessage from '../../shared/ErrorMessage/ErrorMessage';
import Skeleton from '../../shared/Skeleton/Skeleton';
import OverviewBlock from './OverviewBlock/OverviewBlock';
import Tabs from '../../shared/Tabs/NewTabs';
import { chartLinks } from '../../../constants';
import useScrollBlock from '../../../lib/hooks/useScrollBlock';
import { NewAppointmentContextProvider } from '../../../lib/context/Appointment/NewAppointmentContextProvider';
import NewAppointment from '../appointment/NewAppointment/NewAppointment';
import { useQuery } from '@tanstack/react-query';
import { reShapePractitioners } from '../../../lib/helpers/utility';
import { requestApi } from '../../../api/Api';
import { useRecoilValue } from 'recoil';
import { userState } from '../../state';
import { usePractitioners } from '../../../lib/hooks/queries/practitioners/usePractitioners';

function PatientChart() {
  const [currentPatient, setCurrentPatient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [medicalHistory, setMedicalHistory] = useState([]);
  const [practicePatient, setPracticePatient] = useState();
  const { id, appointmentId } = useParams();
  const { data, isLoading, isFetching } = usePatient({
    params: { id: id },
    dependencies: [Number(id)]
  });
  const patient = data?.patient;
  const [newAppointmentModal, setNewAppointmentModal] = useState(false);
  const [blockScroll, allowScroll] = useScrollBlock();
  const navigate = useNavigate();
  const { device } = useUIContext();
  const { pathname } = useLocation();
  const currentUser = useRecoilValue(userState);

  const isPageClinicalNotesOld = pathname?.includes(`clinical-notes-old/${appointmentId}`);
  const isClinicalNote = pathname?.includes(`clinical-notes/${appointmentId}`);
  const is3dPage = pathname?.includes(`clinical-notes/${appointmentId}/3d`);

  usePageTitle(patient?.fullName);

  useEffect(() => {
    getPracticePatientData();
    getMedicalHistory();
    setLoading(false);
  }, [id]);

  const getMedicalHistory = async () => {
    try {
      let res = await interimApi(
        '/api/practice/medical_history/get',
        {
          patient_id: id
        },
        navigate
      );
      const { medicalHistory } = res.data;
      if (res.data) {
        setMedicalHistory(medicalHistory);
      } else {
        Honeybadger.notify(`getMedicalHistory patientID: ${id}`);
      }
    } catch (err) {
      Honeybadger.notify(`getMedicalHistory patientID: ${id}, error: ${err}`);
    }
  };

  const getPracticePatientData = async () => {
    try {
      let res = await interimApi(
        '/api/practice/patient/read',
        {
          patient_id: id
        },
        navigate
      );
      const { practicePatientData } = res.data;
      if (res.data && practicePatientData) {
        setPracticePatient(practicePatientData);
      } else {
        Honeybadger.notify(`getPracticePatientData patientID: ${id}`);
      }
    } catch (error) {
      Honeybadger.notify(`getPracticePatientData patientID: ${id}, error: ${error}`);
    }
  };

  const { data: practitionersList } = usePractitioners();
  const practitioners = practitionersList?.practitioners;

  const { data: servicesData } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  const services = servicesData?.services;

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate
    });
    return response;
  }

  const showNewAppointmentModal = () => {
    blockScroll();
    setNewAppointmentModal(true);
    getServices();
  };

  const hideNewAppointmentModal = () => {
    allowScroll();
    setNewAppointmentModal(false);
  };

  if (isClinicalNote)
    return (
      <Outlet
        context={{
          patient: patient,
          setPatient: setCurrentPatient,
          practicePatient,
          setPracticePatient
        }}
      />
    );

  return (
    <div className={cs(device === 'desktop' || is3dPage ? 'flex h-full flex-col' : 'block h-full')}>
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <OverviewBlock
          showNewAppointmentModal={showNewAppointmentModal}
          patient={patient}
          isLoading={isLoading || isFetching}
        />
      </ErrorBoundary>
      <div
        className={cs(
          device == 'desktop' && 'overflow-hidden',
          'flex flex-col',
          isPageClinicalNotesOld ? '!mt-0 bg-transparent shadow-none' : 'h-full shadow-small'
        )}>
        {isLoading || isFetching || !patient ? (
          <Skeleton />
        ) : (
          <>
            <Tabs tabsData={chartLinks} />
            <Outlet
              context={{
                patient: patient,
                setPatient: setCurrentPatient,
                practicePatient,
                setPracticePatient,
                showNewAppointmentModal
              }}
            />
          </>
        )}
      </div>
      {newAppointmentModal && services && (
        <NewAppointmentContextProvider>
          <NewAppointment
            selectedPatient={patient}
            isOpen={newAppointmentModal}
            onAfterOpen={showNewAppointmentModal}
            hideNewAppointment={hideNewAppointmentModal}
            practitioners={reShapePractitioners(practitioners)}
            services={services?.map?.((service) => ({
              ...service,
              value: service.id,
              label: service.name,
              timeLength: service?.time_length
            }))}
          />
        </NewAppointmentContextProvider>
      )}
    </div>
  );
}

export default PatientChart;
