import React, { useState, useEffect } from 'react';
import { NewRoleContext } from './NewRoleContext';
import { useParams, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { requestApi } from 'api/Api';
import { useQueryClient } from '@tanstack/react-query';
import { showAlert } from 'components/shared/Alert/Alert';

const NewRoleValidationSchema = Yup.object().shape({
  role_name: Yup.string().required('Role name is required')
});

export const NewRoleContextProvider = ({ children }) => {
  const [showNewRoleModal, setShowNewRoleModal] = useState(false);
  const [showNavigationButtons, setShowNavigationButtons] = useState(true);
  const [viewFormModal, setViewFormModal] = useState(false);
  const [enableNextTo, setEnableNextTo] = useState(false);
  const [newRoleSelectedUsers, setNewRoleSelectedUsers] = useState(0);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    if (newRoleSelectedUsers !== 0) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [newRoleSelectedUsers]);

  let initialBreadCrumbs = [
    { title: 'Role Details', view: 'role_details', active: true, isFinished: false },
    { title: 'Manage Permissions', view: 'manage_permissions', active: false, isFinished: false },
    { title: 'Assign Role', view: 'assign_role', active: false, isFinished: false }
  ];
  const [breadCrumbs, setBreadCrumbs] = useState(initialBreadCrumbs);

  const formik = useFormik({
    initialValues: {
      role_name: '',
      role_description: '',
      role_type: null,
      permissions: null,
      assignedUsers: []
    },
    validationSchema: NewRoleValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      handleCreate();
      setSubmitting(false);
    }
  });

  const handleClose = () => {
    setShowNewRoleModal(false);
    formik.resetForm();
    setBreadCrumbs(initialBreadCrumbs);
    setEnableNextTo(false);
    setNewRoleSelectedUsers(0);
  };

  const handleCreate = async () => {
    try {
      const res = await requestApi({
        url: `/api/permissions/create_and_assign`,
        params: {
          ...formik.values
        },
        navigate
      });

      setShowNewRoleModal(false);
      formik.resetForm();
      setBreadCrumbs(initialBreadCrumbs);
      setEnableNextTo(false);
      setNewRoleSelectedUsers(0);
      queryClient.invalidateQueries(['getRoleList']);
      queryClient.invalidateQueries(['getRoleDescriptions']);
      queryClient.invalidateQueries(['getRole']);
      const { code } = res;
      if (code === 0) {
        showAlert({
          title: 'Role has been created succesfully and assigned to choosen users.',
          color: 'success'
        });
      } else {
        showAlert({ title: 'Role creation failed. Please try again later', color: 'danger' });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const { id: practiceId } = useParams();
  return (
    <NewRoleContext.Provider
      value={{
        formik,
        breadCrumbs,
        setBreadCrumbs,
        initialBreadCrumbs,
        enableNextTo,
        setEnableNextTo,
        setShowNewRoleModal,
        showNewRoleModal,
        handleClose,
        practiceId,
        showNavigationButtons,
        setShowNavigationButtons,
        viewFormModal,
        setViewFormModal,
        newRoleSelectedUsers,
        setNewRoleSelectedUsers,
        enableSubmit,
        setEnableSubmit
      }}>
      {children}
    </NewRoleContext.Provider>
  );
};
