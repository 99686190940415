export default function getDocumentFilters(practitioners, patient, categories = []) {
  const types = [
    { value: 'image', label: 'Image' },
    { value: 'pdf', label: 'PDF' }
  ];
  return {
    name: {
      type: 'search',
      values: '',
      title: 'Document Name',
      placeholder: 'Search by document name'
    },
    type: {
      type: 'search',
      options: types,
      multiple: true,
      title: 'Type',
      placeholder: 'Select type'
    },
    category: {
      type: 'search',
      options: categories,
      multiple: true,
      title: 'Category',
      placeholder: 'Select category'
    },
    uploaded_by: {
      type: 'search',
      options: [
        { value: patient?.id, label: patient?.fullName },
        ...practitioners.map((p) => ({ value: p.id, label: p.f_name + ' ' + p.l_name }))
      ],
      multiple: true,
      title: 'Uploaded By',
      placeholder: 'Search by staff/patient...'
    },
    created_at: {
      type: 'date-range',
      values: null,
      title: 'Created At',
      placeholder: 'Select start and end date'
    }
  };
}
