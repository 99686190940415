import React from 'react';
import cs from 'classnames';
import './RadioButton.scss';

const RadioButton = ({
  className,
  inputClassName,
  wrapperClassName,
  id,
  name,
  label,
  value,
  isSelected,
  onChange = () => { },
  disabled,
  dataQA = null,
  ...rest
}) => {
  return (
    <div className={cs('RadioButton', wrapperClassName)} tabIndex={1}>
      <input
        className={inputClassName}
        type="radio"
        id={id}
        value={value}
        checked={isSelected}
        onChange={onChange}
        name={name}
        disabled={disabled}
        data-qa={dataQA || id}
        {...rest}
      />
      {typeof label === 'object' ? (
        label
      ) : (
        <label
          className={cs('leading-[26px] text-neutral-800', !label && 'p-0', className)}
          htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};
export default RadioButton;
