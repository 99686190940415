import React, { memo, useState } from 'react';

import _ from 'lodash';
import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { currentPractice } from 'components/practice/practiceState';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

import { getColDefs } from '../configs';

import { Table } from './Table';

import './PatientHistoryTable.scss';

const PatientHistoryTable = ({ breadcrumb }) => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const [previewData, setPreviewData] = useState({});

  return (
    <TableContextProvider
      name="patient_history"
      cols={getColDefs(practiceTimezone, breadcrumb, setPreviewData)}>
      <Table {...{ breadcrumb, previewData, setPreviewData }} />
    </TableContextProvider>
  );
};

const memoPatientHistory = memo(PatientHistoryTable);
export default withErrorBoundary(memoPatientHistory);
