import moment from 'moment-timezone';

import { ia } from 'lib/helpers/utility';

export const formatRangeWithTimezone = (dates, timezone) => {
  if (!dates || !ia(dates) || dates.length !== 2) return null;

  return [
    moment(dates[0]).tz(timezone, false).format(),
    moment(dates[1]).tz(timezone, false).format()
  ];
};

export const formatWithTimezone = (date, timezone) => {
  if (!date) return null;

  return moment(date).tz(timezone, false).format();
};

export const formatWithoutTimezone = (date) => {
  if (!date) return null;

  return moment(date).format('MM/DD/YYYY');
};

export const formatRangeWithoutTimezone = (date) => {
  if (!ia(date, 1)) return null;

  return [moment(date[0]).format('MM/DD/YYYY'), moment(date[1]).format('MM/DD/YYYY')];
};

export const getDateFormat = ({ isTime, isTimeRange, isSeconds }) => {
  if ((isTime || isTimeRange) && isSeconds) return 'MM/dd/y h:mm:ss a';

  if (isTime || isTimeRange) return 'MM/dd/y h:mm a';

  return 'MM/dd/y';
};
