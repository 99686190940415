import {
  getExternalInvoicesCSV,
  getInternalInvoiceByIds,
  getOldExternalsByAppointmentIds
} from 'api/Billing';
import { currentPractice } from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';
import AGTable from 'components/shared/AGTable/AGTable';
import CustomStatusBarAggregationComponent from 'components/shared/AGTable/CustomStatusBarAggregationComponent';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useExternalInvoices } from 'lib/hooks/queries/billing/useExternalInvoices';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactToPrint } from 'react-to-print';
import { useRecoilValue } from 'recoil';
import AltContact from './components/AltContact';
import { InvoiceModal } from './components/InvoiceModal';
import InvoicePrintView from './components/InvoicePrintView';
import NewInvoice from './components/NewInvoice';
import NewInvoiceView from './components/NewInvoiceView';
import ContactButton from './components/contactButton/ContactButton';
import CustomContact from './components/contactButton/CustomContact';
import { CustomSendModal } from './components/contactButton/CustomSendModal';
import {
  getColDefs,
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS
} from './lib/external/configs';
import { getInvoicesFromSelectedRows, onSendEmail, onSendText } from './lib/utils';

export const formatDate = ({ date, format = 'MM/DD/YYYY', tz = null }) => {
  return moment(date, tz).format(format);
};

const ExternalInvoices = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  return (
    <TableContextProvider
      name="external_invoices"
      cols={getColDefs(practiceTimezone)}
      pagination
      defaultFilters={DEFAULT_FILTERS}>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  const category = 'external_invoices';

  const pageToPrintRef = useRef();

  const navigate = useNavigate();

  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const claims = practice?.display_settings?.claims;

  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [invoiceToView, setInvoiceToView] = useState();
  const [finalGroupInvoices, setFinalGroupInvoices] = useState([]);
  const [customContactType, setCustomContactType] = useState('email');

  const { data: providers } = useProviders({ params: {} });
  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.providers.options = providerOptions;

  const { data, isLoading, isFetching } = useExternalInvoices({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)],
    options: { keepPreviousData: true }
  });

  const invoices = data?.invoices;
  const count = data?.count || 0;
  const statusPanelData = data?.statusPanelData;

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: invoices,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        },
        {
          statusPanel: CustomStatusBarAggregationComponent,
          statusPanelParams: {
            statusPanelData
          },
          align: 'center'
        }
      ]
    };
  }, [invoices, count, page, limit, onPageChange, statusPanelData]);

  const getRowId = useCallback((params) => {
    return params.data.id;
  }, []);

  const exportCSV = async () => {
    const data = await getExternalInvoicesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'external_invoices.csv';
    a.click();
  };

  const onRowClicked = async (e) => {
    const clickedInvoice = e.data;
    let finalInvoices = [];

    if (!clickedInvoice?.internal_invoice_ids) {
      const { invoices = [] } = await getOldExternalsByAppointmentIds(navigate, {
        appointment_ids: clickedInvoice?.appointment_ids || []
      });
      finalInvoices = invoices;
    } else {
      const { invoices = [] } = await getInternalInvoiceByIds(navigate, {
        internal_invoice_ids: clickedInvoice?.internal_invoice_ids || []
      });
      finalInvoices = invoices;
    }

    if (!finalInvoices.length) {
      showAlert({
        title: 'Attention!',
        message: "Invoice you're trying to preview does not exist",
        color: 'warning'
      });
      return;
    }

    const finalGroupInvoices = getInvoicesFromSelectedRows(finalInvoices);
    setFinalGroupInvoices(finalGroupInvoices);

    setInvoiceToView(clickedInvoice);
    setInvoiceModalVisible(true);
  };

  const handleInvoiceModalClose = () => {
    setInvoiceModalVisible(false);
  };

  const modalContent = (
    <>
      {finalGroupInvoices.map((finalInvoice, idx) => {
        const invoiceDetails = getProceduresTotal([finalInvoice]);

        const hasMany = ia(finalInvoice.internal_invoice_ids, 1);

        return (
          <div key={idx}>
            <InvoicePrintView
              practice={practice}
              patient={finalInvoice?.patient}
              createdAt={!hasMany ? finalInvoice?.dateofservice : null}
              content={
                <NewInvoice invoice={finalInvoice} isView isAEOB={invoiceToView?.aeob}>
                  <NewInvoiceView
                    hasClaims={claims}
                    invoiceDetails={{
                      ...invoiceDetails,
                      discount: invoiceToView?.discount_amount_cents || 0,
                      tax_percentage: invoiceToView?.tax_percentage || 0,
                      tax_amount_cents: invoiceToView?.tax_amount_cents || 0,
                      surcharge_amount: invoiceToView?.surcharge_amount || 0,
                      memo: invoiceToView?.memo,
                      aeob: invoiceToView?.aeob
                    }}
                  />
                </NewInvoice>
              }
            />
          </div>
        );
      })}
    </>
  );

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  const handleCustomSendModalOpen = (type) => {
    setCustomContactType(type);
    setCustomModalVisible(true);
  };

  let modalFooter = (
    <div className="flex w-full justify-between">
      <Button
        outlined
        text="Cancel"
        color="neutral"
        onClick={handleInvoiceModalClose}
        id="cancelInvoiceModalBtn"
        data-qa="cancel-btn"
      />
      <div className="flex">
        <ContactButton type="text">
          {invoiceToView?.patient?.phone && (
            <div
              onClick={() => onSendText({ ids: [invoiceToView?.id], navigate })}
              className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
              {invoiceToView?.patient?.phone} (primary)
            </div>
          )}
          {invoiceToView?.patient?.alt_phone && (
            <AltContact
              type="phone"
              handleAlt={() =>
                onSendText({
                  ids: [invoiceToView?.id],
                  navigate,
                  toAltPhone: true,
                  isPopover: true
                })
              }>
              {invoiceToView?.patient?.alt_phone} (alternative)
            </AltContact>
          )}
          <CustomContact handleCustom={() => handleCustomSendModalOpen('text')} type="phone" />
        </ContactButton>
        <ContactButton type="email">
          {invoiceToView?.patient?.email && (
            <div
              onClick={() => onSendEmail({ ids: [invoiceToView?.id], navigate })}
              className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
              {invoiceToView?.patient?.email} (primary)
            </div>
          )}
          {invoiceToView?.patient?.alt_email && (
            <AltContact
              type="email"
              handleAlt={() =>
                onSendEmail({
                  ids: [invoiceToView?.id],
                  navigate,
                  toAltEmail: true,
                  isPopover: true
                })
              }>
              {invoiceToView?.patient?.alt_email} (alternative)
            </AltContact>
          )}
          <CustomContact handleCustom={() => handleCustomSendModalOpen('email')} type="email" />
        </ContactButton>
      </div>
    </div>
  );

  const headButton = (
    <div className="flex w-full justify-end">
      <ReactToPrint
        trigger={() => (
          <Button
            color=""
            size="small"
            type="link"
            text="Print"
            icon="new-printer-bulk"
            className="text-primary-900"
          />
        )}
        content={() => pageToPrintRef?.current}
      />
    </div>
  );

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    await onRowClicked(e);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title="Electronic Invoicing" subtitle="External">
        <div className="flex flex-wrap items-center justify-end gap-4">
          <div className="flex gap-2">
            <DisplayButton />
            <Filter
              category={category}
              defaultFilters={DEFAULT_FILTERS}
              filters={filters}
              setFilters={setFilters}
              menuPortalTarget={document.body}
            />
            <ExportData
              title="Generate External Invoice report"
              defaultFilters={DEFAULT_FILTERS}
              filters={filters}
              onGenerateClick={exportCSV}
            />
          </div>
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <AGTable
              onCellClicked={onCellClicked}
              defaultColDef={DEFAULT_COLUMN_DEFS}
              columnDef={getColDefs(practiceTimezone)}
              gridOptions={GRID_OPTIONS}
              animateRows={true}
              getRowId={getRowId}
              data={invoices}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              statusBar={statusBar}
            />
          )}
        </div>
      </div>
      {invoiceModalVisible ? (
        <InvoiceModal
          invoiceModalVisible={invoiceModalVisible}
          onClose={handleInvoiceModalClose}
          onPrev={handleInvoiceModalClose}
          modalContent={<div ref={pageToPrintRef}>{modalContent}</div>}
          headButton={headButton}
          footer={modalFooter}
        />
      ) : null}
      {customModalVisible ? (
        <CustomSendModal
          ids={[invoiceToView?.id]}
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
        />
      ) : null}
    </ErrorBoundary>
  );
}

export default ExternalInvoices;
