import { formatDate } from 'lib/helpers/utility';
import getPlaceOfService from './getPlaceOfService';

export default function getInitialProcedure(practice) {
  return [
    {
      code: '',
      modifiers: [],
      charge: 0,
      diagnosis: [],
      from_date: formatDate(new Date()),
      thru_date: formatDate(new Date()),
      narrative: '',
      units: '',
      place_of_service: getPlaceOfService({}, practice)
    }
  ];
}
