import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import _, { capitalize } from 'lodash';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useAccessLogs } from 'lib/hooks/queries/admin/useAccessLogs';
import { useAdminUsers } from 'lib/hooks/queries/admin/useAdminUsers';
import { useUsers } from 'lib/hooks/queries/user/useUsers';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';

import Filter from '../Filter/Filter';
import TableCounter from '../Table/TableCounter';

import JsonTreeView from './components/JsonTreeView/JsonTreeView';
import extractIds from './lib/extractIds';
import mapLogsData from './lib/mapLogsData';

const columnDefinitions = [
  {
    field: 'id',
    headerName: 'ID',
    minWidth: 160,
    cellRenderer: 'agGroupCellRenderer',
    showRowGroup: true,
    suppressMenu: true
  },
  {
    field: 'description',
    headerName: 'Description'
  },
  {
    field: 'url',
    headerName: 'URL'
  },
  {
    field: 'user_id',
    headerName: 'User',
    minWidth: 150
  },
  {
    field: 'practice_id',
    headerName: 'Practice',
    minWidth: 150,
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'admin_id',
    headerName: 'Admin',
    minWidth: 150
  },
  {
    field: 'ip',
    headerName: 'IP',
    minWidth: 150
  },
  {
    field: 'date',
    headerName: 'Time',
    minWidth: 150
  }
];

export default function AccessLogs({ specificAdmin = false, defaultLogsFilters }) {
  return (
    <TableContextProvider cols={columnDefinitions} name="admin_access_logs" pagination>
      <Table specificAdmin={specificAdmin} defaultLogsFilters={defaultLogsFilters} />
    </TableContextProvider>
  );
}

function Table({ specificAdmin, defaultLogsFilters }) {
  const [accessLogsFilters, setAccessLogsFilters] = useState(defaultLogsFilters);
  const { limit, page, setPage, setTableLoading } = useTableContext();
  const [totalItems, setTotalItems] = useState(0);
  let { id } = useParams();
  let admin_id = null;

  if (specificAdmin) [admin_id, id] = [id, null];

  usePageTitle('Access Logs');

  const {
    data = {},
    isLoading,
    isFetching
  } = useAccessLogs({
    params: getAccessLogsParams({ id, admin_id, accessLogsFilters, limit, page }),
    dependencies: [limit, page, mapValues(accessLogsFilters), id],
    options: {
      onSuccess: (data) => {
        setTotalItems(data.total);
      }
    }
  });

  const logs = mapLogsData(data?.logs, useUsers, useAdminUsers);

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  const defaultColDef = useMemo(() => ({ flex: 1, minWidth: 100 }), []);

  /*     if (id) return <Outlet />; */

  return (
    <>
      <Header title="Logs">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category="log-filters"
            filters={accessLogsFilters}
            defaultFilters={defaultLogsFilters}
            setFilters={setAccessLogsFilters}
            isAdmin={true}
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0 h-full">
          <AGTable
            data={logs}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
            ExpandedComponent={JsonTreeView}
            masterDetail={true}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          <TableCounter page={page} limit={limit} count={totalItems} />
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={totalItems}
            page={page}
          />
        </div>
      </div>
    </>
  );
}

function getAccessLogsParams({ id, admin_id, accessLogsFilters, limit, page }) {
  const getIds = (filter) => (filter?.values?.length ? extractIds(filter.values) : undefined);

  return {
    limit,
    page,
    admins: admin_id ? [admin_id] : getIds(accessLogsFilters?.admins),
    practices: id ? undefined : getIds(accessLogsFilters?.practices),
    staff: id ? [id] : getIds(accessLogsFilters?.staff),
    logId: accessLogsFilters?.id?.values,
    data: accessLogsFilters?.data?.values,
    order: accessLogsFilters?.order?.value,
    startDate: accessLogsFilters?.date?.values?.startDate,
    endDate: accessLogsFilters?.date?.values?.endDate,
    url: accessLogsFilters?.url?.values
  };
}
