import React from 'react';
import SearchPatients from '../../Search/SearchPatients/SearchPatients';
import cs from 'classnames';

const SellPackagePatient = ({ formik }) => {
  const { errors, touched, setFieldValue, values } = formik;

  return (
    <SearchPatients
      isImage
      required
      label="Patient"
      isClearable={false}
      width="504px !important"
      preselectedPatient={!!values?.patient && values?.patient}
      error={touched?.patient && errors.patient}
      handleClick={(e) => setFieldValue('patient', e)}
      className={cs(
        'rounded-md border-neutral-100 bg-white shadow-[0px_2px_4px_0px_rgba(27,33,35,0.04)] focus-within:outline focus-within:!outline-2',
        touched?.patient && errors.patient
          ? '!border-danger-300 focus-within:!outline-danger-100'
          : 'focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]'
      )}
    />
  );
};

export default SellPackagePatient;
