import { requestApi } from 'api/Api';
import { ia, iaRa, spaceToKebabCase } from 'lib/helpers/utility';
import { processCustomFormTypes } from './processCustomNoteTypes';

export const showPreviewNote = async ({
  patientId = null,
  appointmentId = null,
  saveButtonText = null,
  exportNote = false,
  setClinicalNote = () => null,
  setAdvancedHP = () => null,
  setAdvancedSOAP = () => null,
  setCustomFormTypes = () => null,
  setPreviewNoteModal = () => null,
  setIsExport = () => null,
  setLoading = () => null,
  navigate = () => null,
  onSaveNote = () => null
}) => {
  setLoading(true);

  await onSaveNote();

  const response = await requestApi({
    url: '/api/clinical_note/read',
    params: {
      patientId,
      appointmentId,
      exporting: true
    },
    navigate
  });

  setClinicalNote(
    structuredClone({ ...response?.clinicalNote, cpt_codes: iaRa(response?.serviceCPT) })
  );

  if (ia(response?.advancedHP)) {
    setAdvancedHP(
      response.advancedHP.map((item) => ({
        ...item,
        custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
          item?.form_id || item?.id
        }`
      }))
    );
  }

  if (ia(response?.advancedSOAP)) {
    setAdvancedSOAP(
      response.advancedSOAP.map((item) => ({
        ...item,
        custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
          item?.form_id || item?.id
        }`
      }))
    );
  }

  if (response?.customFormTypes) {
    const processedCustomFormTypes = processCustomFormTypes({
      customFormTypes: response?.customFormTypes
    });

    setCustomFormTypes(processedCustomFormTypes);
  }

  setPreviewNoteModal(true);
  setIsExport(exportNote);
  setLoading(false);
};
