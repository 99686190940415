import React from 'react';
import Icon from '../../../../../shared/Icon/Icon';
import Button from '../../../../../shared/Buttons/Button';

const TPEmpty = ({ onClick }) => {
  return (
    <div className="mx-auto flex flex-col items-center justify-center px-24 pt-16 sm:px-0">
      <Icon icon="new-not-found" />
      <div className="grid gap-4 !py-5 text-center">
        <p className="text-lg font-600 leading-7 text-primary-900">
          Patient doesn't have any treatment plan yet!
        </p>
        <p className="text-sm text-neutral-700">
          Create a custom treatment plan to track diagnoses and progress across a patient's visits!
        </p>
      </div>
      <Button
        text="Create new treatment plan"
        onClick={() => onClick('create', 'Create treatment plan')}
        data-qa="create-new-treatment-plan"
      />
    </div>
  );
};

export default TPEmpty;
