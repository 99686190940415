import React from 'react';
import { useRecoilValue } from 'recoil';
import { formatDateAndTimeZ } from '../../../../../../../../lib/helpers/utility';
import { currentPractice } from '../../../../../../practiceState';

const Draw = ({ itemProps }) => {
  const practice = useRecoilValue(currentPractice);

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Draw</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {!!itemProps?.draw_date_time && (
            <div>
              <strong>Draw Date And Time:</strong>{' '}
              {formatDateAndTimeZ(itemProps?.draw_date_time, practice?.timezone)}
            </div>
          )}

          <div>
            <strong>Draw At Service Center:</strong>{' '}
            {itemProps?.draw_at_service_center ? 'Yes' : 'No'}
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Draw;
