import * as Yup from 'yup';

const UpdatePatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().email().required('Email required'),
  alt_email: Yup.string().email().nullable(),
  dob: Yup.string().required('Date of birth required'),
  custom_id: Yup.string()
    .matches(/^[a-zA-Z0-9!@#$%^&*)(]*$/g, 'Special Characters are not allowed')
    .nullable()
});

export default UpdatePatientValidationSchema;
