import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getOutboundFaxes } from '../../../../api/Fax';

export const useOutboundFaxes = ({ dependencies = [], params = {}, options = {} }) => {
  const navigate = useNavigate();
  return useQuery(['outboundFaxes', ...dependencies], () => getOutboundFaxes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
