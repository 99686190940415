import Allowed from 'components/shared/Permissions/Allowed';
import React from 'react';
import { formatDate, ia, isEmpty, stripHtmlTags } from '../../../../../../../lib/helpers/utility';
import SimpleAccordion from '../../../shared/SimpleAccordion';
import CreationInfo from './CreationInfo';
import NoKnown from './NoKnown';

const SurgicalHistoryOverview = ({
  cnSettings = {},
  isOpen = {},
  toggleOne = () => {},
  overviewData = {}
}) => {
  if (!cnSettings?.overview?.surgical_history && !cnSettings?.overview?.surgicalHistory)
    return null;

  return (
    <Allowed requiredPermissions="patients_medical_history.read">
      <SimpleAccordion
        title="Surgical History"
        onToggle={() => toggleOne('surgicalHistory')}
        open={isOpen.surgicalHistory}
        addPermissions="patients_medical_history.update">
        {ia(overviewData?.surgicalHistory) &&
          overviewData?.surgicalHistory?.map((surgicalHistory, index) => {
            if (surgicalHistory?.response?.noDetails) {
              return (
                <NoKnown
                  key={index}
                  form={surgicalHistory}
                  description="No notable surgical history"
                  shouldShowDivider={index !== 0}
                />
              );
            }

            return (
              ia(surgicalHistory?.response?.row) && (
                <>
                  {surgicalHistory?.response?.row?.map(
                    (item, idx) =>
                      item.surgery && (
                        <React.Fragment key={idx}>
                          <div className="!my-[6px] h-[1px] w-full bg-neutral-100 first-of-type:hidden"></div>
                          <div className="grid gap-y-1">
                            {!isEmpty(item.surgery.value) && (
                              <div className="flex justify-between">
                                <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                                  Surgery:
                                </span>
                                <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                                  {item.surgery.value}
                                </span>
                              </div>
                            )}
                            {!isEmpty(item.date) && (
                              <div className="flex justify-between">
                                <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                                  Date of surgery:
                                </span>
                                <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                                  {formatDate(item.date)}
                                </span>
                              </div>
                            )}
                            {!isEmpty(item.surgeon) && (
                              <div className="flex justify-between">
                                <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                                  Surgeon:
                                </span>
                                <span className="text-right text-sm font-400 text-primary-900 first-letter:uppercase">
                                  {item.surgeon.value}
                                </span>
                              </div>
                            )}
                            {!isEmpty(item.note) && (
                              <div className="flex flex-wrap justify-between">
                                <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
                                  Note:
                                </span>
                                <span className="break-all text-sm font-400 text-primary-900 first-letter:uppercase">
                                  {stripHtmlTags(item.note)}
                                </span>
                              </div>
                            )}
                            <CreationInfo
                              createdAt={surgicalHistory?.createdAt}
                              createdBy={surgicalHistory?.createdBy?.fullName}
                            />
                          </div>
                        </React.Fragment>
                      )
                  )}
                </>
              )
            );
          })}
      </SimpleAccordion>
    </Allowed>
  );
};

export default SurgicalHistoryOverview;
