import { useQuery } from '@tanstack/react-query';
import { getPractices } from '../../../../api/Practice';
import { useNavigate } from 'react-router-dom';

export const usePractices = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['practices', ...dependencies], () => getPractices(navigate, params),{
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};

