import React, { useState } from 'react';

import {
  Popover as HeadlessPopover,
  PopoverPanel as HeadlessPopoverPanel
} from '@headlessui/react';

import { iaRa } from 'lib/helpers/utility';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';

import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';

import SavePopupContent from './SavePopupContent';
import { saveFormSettings } from './lib/helpers';

const SaveSettingsPopup = ({ template = 'enhance_note', setSettings = () => {}, forms = {} }) => {
  const { saveDisplaySettings, userState } = useDisplaySettings();
  const [openSavePopup, setOpenSavePopup] = useState(false);
  const savedTemplates = iaRa(userState?.display_settings?.clinicalNote?.myscribe_ai?.[template]);

  const closePopup = () => {
    setOpenSavePopup(false);
  };

  const openPopup = () => {
    setOpenSavePopup(true);
  };

  const saveSettings = (settings) => {
    return saveFormSettings({
      saveDisplaySettings,
      userState,
      settings
    });
  };

  const onSave = (name) => {
    saveSettings({
      [template]: savedTemplates
        .map((item) => ({
          ...item,
          isSelected: false
        }))
        .concat({
          name,
          forms,
          isSelected: true
        })
    }).then(() => {
      showAlert({
        title: 'Form Template Saved',
        message: 'The form template has been successfully saved.',
        color: 'success'
      });
      setSettings(forms);
    });
    setOpenSavePopup(false);
  };

  const applyTemplate = (name) => {
    setSettings(savedTemplates?.find((item) => item?.name === name)?.forms);
  };

  const setAsDefaultTemplate = (name) => {
    saveSettings({
      [template]: savedTemplates.map((item) => ({
        ...item,
        isSelected: false,
        isDefault: item.name === name
      }))
    }).then(() => {
      showAlert({
        title: 'Default Template Set',
        message: 'The form template has been set as the default.',
        color: 'success'
      });
    });
  };

  const removeAsDefaultTemplate = () => {
    saveSettings({
      [template]: savedTemplates.map((item) => ({
        ...item,
        isDefault: false
      }))
    }).then(() => {
      showAlert({
        title: 'Remove Template Set',
        message: 'The form template is no longer the default option.',
        color: 'success'
      });
    });
  };

  const deleteTemplate = (name) => {
    saveSettings({
      [template]: savedTemplates.filter((item) => item.name !== name)
    }).then(() => {
      showAlert({
        title: 'Form Template Deleted',
        message: 'The form template has been successfully deleted.',
        color: 'success'
      });
    });
  };

  const options = [
    {
      label: 'Save',
      onClick: openPopup
    },
    savedTemplates?.length > 0 && {
      label: 'Saved Templates',
      position: 'right',
      children: savedTemplates?.map((item) => ({
        label: item?.name,
        children: [
          { label: 'Apply', onClick: () => applyTemplate(item?.name) },
          {
            label: !!item?.isDefault ? 'Remove as default' : 'Set as default',
            onClick: !!item?.isDefault
              ? () => removeAsDefaultTemplate()
              : () => setAsDefaultTemplate(item?.name)
          },
          { label: 'Delete', onClick: () => deleteTemplate(item?.name) }
        ]
      }))
    }
  ]?.filter(Boolean);

  return (
    <>
      <HeadlessPopover>
        {() => (
          <>
            {openSavePopup && (
              <HeadlessPopoverPanel
                modal={true}
                className="absolute top-[40px] z-50 rounded-md bg-white p-6 shadow-lg"
                static>
                <SavePopupContent onClose={closePopup} onSaveForms={onSave} />
              </HeadlessPopoverPanel>
            )}
          </>
        )}
      </HeadlessPopover>
      <Popover
        className="flex flex-1 justify-center self-center"
        icon="save-changes"
        isFixed
        options={options}
        tooltip="Save selection"
      />
    </>
  );
};

export default SaveSettingsPopup;
