import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useRecoilValue } from 'recoil';

import { interimApi } from 'api/InterimApi';

import { ia } from 'lib/helpers/utility';
import useWebSocket from 'lib/hooks/useMessageWebSocket';

import Message from 'components/shared/Messaging/Message';

import state from '../../state';

// message limit
const LIMIT = 10;

export default function PatientConversation() {
  const navigate = useNavigate();

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState({
    general: true,
    message_sent: false
  });
  const [practice, setPractice] = useState({ name: '', id: 0 });
  const currentPatient = useRecoilValue(state.userState);
  const { myPortalPractice } = useParams();

  const [groupedMessages, setGroupedMessages] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getData();
  }, [page]);

  useEffect(() => {
    readAllMessegas();
  }, []);

  useEffect(() => {
    newGrouped();
  }, [messages]);

  const messageSocketCallback = (event) => {
    const incomingMessage = JSON.parse(event.data);

    if (incomingMessage?.practice_id != practice?.id) return;

    setMessages((prev) => {
      return [...prev, { ...incomingMessage }];
    });
  };

  const { ws } = useWebSocket({
    handleMessage: messageSocketCallback
  });

  const newGrouped = () => {
    const groupedByDate = messages.reduce((acc, curr) => {
      const date = curr.created_at?.split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(curr);
      return acc;
    }, {});

    const messageGroupsByDate = Object.entries(groupedByDate).map(([date, messages]) => {
      const messageGroups = [];

      messages.sort((a, b) => a.created_at - b.created_at);

      let lastMessage = null;
      let shouldShowLabel = true;
      let isFirstOfType = true;
      messages.forEach((message, index) => {
        if (isFirstOfType || (lastMessage && lastMessage.medium !== message.medium)) {
          shouldShowLabel = true;
          isFirstOfType = false;
        } else if (
          (message.sender_id === currentPatient.id &&
            lastMessage &&
            lastMessage.sender_id !== currentPatient.id) ||
          (message.sender_id !== currentPatient.id &&
            lastMessage.sender_id === currentPatient.id) ||
          message.sender_id !== lastMessage.sender_id
        ) {
          shouldShowLabel = true;
        } else {
          shouldShowLabel = false;
        }

        const messageGroup = {
          type: message.medium,
          message,
          shouldShowLabel,
          isFirstMessageOfPerson: false,
          isLastMessageOfPerson: false
        };

        if (message.sender_id !== currentPatient.id) {
          if (index === 0 || shouldShowLabel) {
            messageGroup.isFirstMessageOfPerson = true;
          }
          if (
            index === messages.length - 1 ||
            messages[index + 1].sender_id !== message.sender_id ||
            messages[index + 1].medium !== message.medium
          ) {
            messageGroup.isLastMessageOfPerson = true;
          }
        } else {
          if (index === 0 || shouldShowLabel) {
            messageGroup.isFirstMessageOfPerson = true;
          }
          if (
            index === messages.length - 1 ||
            messages[index + 1].sender_id !== message.sender_id ||
            messages[index + 1].medium !== message.medium
          ) {
            messageGroup.isLastMessageOfPerson = true;
          }
        }

        messageGroups.push(messageGroup);

        lastMessage = message;
      });
      return { date, messageGroups };
    });

    setGroupedMessages([...messageGroupsByDate]);
  };

  const getData = async () => {
    setLoading({ ...loading, general: true });
    const params = {
      practice_id: myPortalPractice,
      offset: page * 10,
      limit: LIMIT,
      kind: 'message'
    };
    try {
      const res = await interimApi('/api/messages/get', params, navigate);
      const { messages: loadedMessages, practice: loadedPractice, code, redirect } = res.data;
      if (code === -1) {
        navigate(redirect);
      }
      if (ia(loadedMessages)) {
        setMessages((prevMessages) => {
          return [...loadedMessages.filter((m) => m.body?.length > 0), ...prevMessages];
        });
      }
      if (practice) {
        setPractice({ ...loadedPractice });
      }
    } catch (error) {
      Honeybadger.notify(
        `file: inbox/PatientConversation, method: getData - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
    setLoading({ ...loading, general: false });
  };

  const readAllMessegas = async () => {
    const params = {
      practiceId: myPortalPractice,
      kind: 'message',
      markRead: true
    };
    try {
      const res = await interimApi('/api/messages/update', params, navigate);
      const { messages: loadedMessages } = res.data;
      if (ia(loadedMessages)) {
        setMessages([...loadedMessages, ...messages]);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: inbox/PatientConversation, method: readAllMessegas - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const [body, setBody] = useState('');

  const handleChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = async () => {
    if (body.trim().length > 0) {
      setLoading({ ...loading, message_sent: true });
      const params = {
        message: {
          practice_id: myPortalPractice,
          body,
          receiver_id: null,
          kind: 'message'
        }
      };
      try {
        const res = await interimApi('/api/messages/send', params, navigate);
        if (res.data.message_id) {
          const new_messages = messages;
          const messageToPush = {
            id: res.data.message_id,
            sender_id: currentPatient.user_id,
            practice_id: myPortalPractice,
            practice_name: practice,
            receiver_id: params.message.receiver_id,
            body: params.message.body,
            sender_name: currentPatient.f_name + ' ' + currentPatient.l_name,
            sender_email: currentPatient.email,
            read: false,
            medium: 'web',
            kind: 'message',
            user: { f_name: currentPatient?.f_name, l_name: currentPatient?.l_name },
            created_at: new Date().toISOString(),
            date: new Date()
          };
          new_messages.push(messageToPush);
          // Send the JSON object as a string on the WebSocket connection
          ws.send(JSON.stringify(messageToPush));
          setMessages([...new_messages]);
          setBody('');
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later`);
        }
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
      setLoading({ ...loading, message_sent: false });
    }
  };

  return (
    <div className="flex h-[calc(100vh-5rem)] flex-col p-12 sm:p-0 md:p-0">
      <div className="mt-4 flex h-full flex-col overflow-auto">
        <p className="text-center text-lg font-500 text-primary-900">{practice?.name}</p>
        {Array.isArray(messages) && (
          <Message
            patient={currentPatient}
            groupedMessages={groupedMessages}
            practice={practice}
            loading={loading}
            onChange={handleChange}
            onClick={handleSubmit}
            body={body}
            setPage={setPage}
            page={page}
            type="patient"
          />
        )}
      </div>
    </div>
  );
}
