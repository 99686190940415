import React from 'react';

import NotFound from 'components/NotFound';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';

const ComponentSelectorModal = ({
  componentToAdd,
  onClose,
  onTagRemove,
  onAddComponent,
  breadcrumb,
  IsComponentInTags,
  isView = false
}) => {
  const Input = componentToAdd?.forms || NotFound;

  return (
    <Modal
      isOpen={!!componentToAdd}
      handleClose={onClose}
      isFooterSticky
      className="h-[896px] w-[1160px] min-w-[480px]"
      bodyClassName="min-h-[400px] bg-primary-10 !p-0"
      customHeader={
        <div className="flex w-full items-center justify-between">
          <Breadcrumb breadcrumb={breadcrumb} key={breadcrumb} />
          {componentToAdd?.headerButton?.({ breadcrumb }) || null}
        </div>
      }
      isFooter={false}>
      {componentToAdd?.content ? (
        <div className="flex h-full flex-col">
          {componentToAdd?.content({ breadcrumb, onAddComponent })}
        </div>
      ) : (
        <div className="mb-[112px] p-2 px-4">
          <Input
            inputName={componentToAdd.id}
            title={componentToAdd?.text}
            type={componentToAdd?.type}
            breadcrumb={breadcrumb}
          />
          {!isView && (
            <ModalFooter
              rightButtons={[
                IsComponentInTags && (
                  <Icon
                    key={1}
                    icon="trash"
                    className="cursor-pointer"
                    onClick={() => onTagRemove(componentToAdd?.id)}
                  />
                ),
                <Button
                  key={2}
                  text={`${IsComponentInTags ? 'Update' : 'Add'} ${componentToAdd?.text}`}
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className="ml-4 !py-2 !pl-4 !pr-4"
                  onClick={onAddComponent}
                />
              ]}
              leftButtons={
                <Button
                  key={1}
                  text="Back"
                  outlined
                  transparent
                  size="small"
                  className="!py-2 !pl-4 !pr-4"
                  onClick={onClose}
                />
              }
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default ComponentSelectorModal;
