import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import React from 'react';

const EnhanceLoading = () => {
  return (
    <div className="flex h-full w-1/2 flex-grow flex-col items-center justify-start gap-10">
      <div className="z-50 mt-[80px]">
        <Icon icon="ai-coding" className="mb-5" />
      </div>
      <div className="z-50 flex items-center text-white">
        <span className="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
          MyScribe AI
        </span>
        <Icon icon="stars-ai" className="ml-1" />
        <span className="ml-2">is enhancing current note</span>
      </div>
      <div className="z-50 h-[40px] w-[40px]">
        <svg className="circle-wrapper" viewBox="0 0 50 50">
          <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
        </svg>
      </div>
      <div className="flex h-full w-full flex-col gap-5">
        {Array(5)
          .fill()
          .map((item) => (
            <div key={item} className="flex gap-5">
              <div className="h-full w-full">
                <Skeleton
                  baseColor="#D3D3D3"
                  highlightColor="#FAFAFA"
                  count={1}
                  height="12px"
                  width="30%"
                />
                <Skeleton
                  baseColor="#D3D3D3"
                  highlightColor="#FAFAFA"
                  count={1}
                  height="40px"
                  width="100%"
                />
              </div>
              <div className="h-full w-full">
                <Skeleton
                  baseColor="#D3D3D3"
                  highlightColor="#FAFAFA"
                  count={1}
                  height="12px"
                  width="30%"
                />
                <Skeleton
                  baseColor="#D3D3D3"
                  highlightColor="#FAFAFA"
                  count={1}
                  height="40px"
                  width="100%"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default EnhanceLoading;
