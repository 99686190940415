import React from 'react';
import './Loading.scss';

export default function Loading() {
  return (
    <div className="my-[50px] flex h-full w-full items-center justify-center">
      <svg className="circle-wrapper" viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
      </svg>
    </div>
  );
}
