const handleChangePackageQuantity = (type, setFieldValue, currentValues, val, id) => {
  const sales_count = Number(val);

  if (sales_count < 1) return;

  setFieldValue(
    type,
    currentValues.map((s) =>
      s.id === id
        ? {
            ...s,
            sales_count,
            total_amount_cents: s.amount_cents * sales_count,
            products: (s.products || []).map((p) => ({
              ...p,
              quantity: (p.quantity / s.sales_count) * sales_count
            })),
            procedures: (s.procedures || []).map((p) => ({
              ...p,
              quantity: (p.quantity / s.sales_count) * sales_count
            }))
          }
        : s
    )
  );
};

export default handleChangePackageQuantity;
