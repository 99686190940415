import { isEmpty } from 'lib/helpers/utility';

export const formatEmptyValues = ({ values = {} }) => {
  const formattedValues = { ...(values || {}) };

  Object.keys(formattedValues).forEach((key) => {
    if (isEmpty(formattedValues[key])) {
      formattedValues[key] = null;
    }
  });

  return formattedValues;
};
