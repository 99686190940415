import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPayerAddress } from '../../../../api/Payer';

export const usePayerAddress = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['payerAddress', ...dependencies], () => getPayerAddress(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
