import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import cs from 'classnames';
import userAvatar from '../../../../user_avatar.png';
import { interimApi } from 'api/InterimApi';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import usePageTitle from 'lib/hooks/usePageTitle';
import Button from 'components/shared/Button/Button';
import Icon from 'components/shared/Icon/Icon';
import Logo from 'components/shared/Logo/Logo';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';
import { ownerPractices, ownerSelectedLocation } from 'components/practice/practiceState';
import './PatientNavbar.scss';

const PATHS = {
  '/myportal': 'Dashboard',
  '/myportal/': 'Dashboard',
  '/myportal/inbox': 'Messages',
  '/myportal/medical-records': 'Medical Records',
  '/myportal/appointments': 'Appointments',
  // '/myportal/questionnaires': 'Questionnaires',
  // '/myportal/medications': 'Medications',
  '/myportal/superbills': 'Superbills',
  '/myportal/documents': 'Documents',
  '/myportal/referrals': 'Referrals',
  '/myportal/invoices': 'Invoices',
  '/myportal/account': 'Account',
  '/myportal/treatment-plans': 'Treatment Plans'
};

const PatientNavbar = () => {
  const [user, setUser] = useRecoilState(state.userState);
  const [showPopover, setShowPopover] = useState(false);
  const setOwnerSelectedLocation = useSetRecoilState(ownerSelectedLocation);
  const setOwnerPractices = useSetRecoilState(ownerPractices);
  const ref = useRef();
  const navigate = useNavigate();
  const { device } = useUIContext();
  const { pathname } = useLocation();

  const logout = async () => {
    try {
      await interimApi('/api/user/logout', {}, navigate);
      sessionStorage.removeItem('myriad-session-reference-id');
      setUser({
        user_id: '',
        f_name: '',
        l_name: '',
        p_name: '',
        email: '',
        state: '',
        kind: '',
        logged_in: false,
        profile_photo: null
      });
      setOwnerPractices([]);
      setOwnerSelectedLocation(null);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const handlePopover = () => {
    setShowPopover(!showPopover);
  };

  useOutsideClick(ref, () => setShowPopover(false));

  const name =
    user.p_name || (user.f_name && user.l_name ? user.f_name + ' ' + user.l_name : 'Welcome');

  usePageTitle(PATHS[pathname]);

  return (
    <nav className="PatientNavbar z-20 bg-neutral-100">
      <Link to="/myportal">
        <Logo className="!w-48 sm:!w-36" />
      </Link>

      {pathname && <div className="text-2xl font-800 text-primary-900">{PATHS[pathname]}</div>}

      {user.email ? (
        <div
          className={cs('PatientNavbar__menu', showPopover && 'active')}
          onClick={handlePopover}
          ref={ref}>
          {device !== 'mobile' && <p className="PatientNavbar__menu__name">{name}</p>}
          {user.profile_photo ? (
            <img
              className="PatientNavbar__menu__avatar"
              src={`https://cdn.filestackcontent.com/${JSON.parse(user.profile_photo).jpg}`}
            />
          ) : (
            <img className="PatientNavbar__menu__avatar" src={userAvatar} />
          )}
          <Icon icon="chevron-down" className={cs(showPopover && 'rotate')} />

          <Popover handleOpen={showPopover}>
            {device === 'mobile' && <p className="PatientNavbar__menu__name">{name}</p>}

            <div className="PatientNavbar__menu__dropdown">
              <Link to="/myportal">
                <Icon icon="dashboard" />
                <span>Dashboard</span>
              </Link>
              <Link to="/myportal/inbox">
                <Icon icon="chat" />
                <span>Messages</span>
              </Link>
              <Link to="/myportal/medical-records">
                <Icon icon="medical-records" />
                <span>Medical Records</span>
              </Link>
              <Link to="/myportal/treatment-plans">
                <Icon icon="new-document-sign" />
                <span>Treatment Plans</span>
              </Link>
              <Link to="/myportal/appointments">
                <Icon icon="appointments" />
                <span>Appointments</span>
              </Link>
              {/* <Link to="/myportal/questionnaires">
                <Icon icon="questionnaries" />
                <span>Questionnaires</span>
              </Link> */}
              {/* <Link to="/myportal/medications">
                <Icon icon="medical-records" />
                <span>Medications</span>
              </Link> */}

              <Link to="/myportal/superbills">
                <Icon icon="new-superbill" />
                <span>Superbills</span>
              </Link>

              <Link to="/myportal/documents">
                <Icon icon="export" />
                <span>Documents</span>
              </Link>

              <Link to="/myportal/referrals">
                <Icon icon="referrals" />
                <span>Referrals</span>
              </Link>

              {/* <Link to="/myportal/invoices">
                <Icon icon="new-billing" />
                <span>Invoices</span>
              </Link> */}

              <Link to="/myportal/account">
                <Icon icon="user-fill" />
                <span>My Account</span>
              </Link>

              <Button onClick={logout} danger>
                Logout
              </Button>
            </div>
          </Popover>
        </div>
      ) : (
        <Button primary onClick={() => navigate('/login')}>
          Login
        </Button>
      )}
    </nav>
  );
};

export default PatientNavbar;
