import React, { useCallback, useState } from 'react';
import JsonView from 'react18-json-view';
import { useNavigate } from 'react-router-dom';

import { recheckTransaction } from 'api/Transaction';

import { iaRa } from 'lib/helpers/utility';
import useLoading from 'lib/hooks/useLoading';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';

const TransactionResponseModal = ({ isOpen, handleClose, item }) => {
  const navigate = useNavigate();
  const [selectedTxn, setSelectedTxn] = useState(item);

  const transactionId = item?.id;

  const { loading, startLoading, stopLoading } = useLoading();

  const transactionCheck = useCallback(async () => {
    if (loading) return;
    startLoading();
    try {
      const newTxn = await recheckTransaction(navigate, { transactionId });
      if (!newTxn?.transaction) {
        throw newTxn;
      }
      setSelectedTxn(newTxn?.transaction);
    } catch (e) {
      showAlert({
        color: 'warning',
        title: 'An error while checking transaction status has occurred',
        message: JSON.stringify(e)
      });
    }
    stopLoading();
  }, [loading, startLoading, stopLoading, navigate, transactionId]);

  return (
    <Modal
      isOpen={isOpen}
      slideFromRight
      className="w-3/4"
      title={`Raw Responses ${transactionId}`}
      handleOpen={typeof transactionId === 'number'}
      handleClose={handleClose}>
      <Button
        iconRight="rotate-ccw"
        iconRightColor="primary"
        iconRightIsStroke
        outlined
        text="Check New Transaction Status"
        onClick={transactionCheck}
      />
      {iaRa(selectedTxn?.raw_response).map((rawResponse, index) => (
        <div key={`transaction-${transactionId}-raw-response-index-${index}`}>
          {index && <hr />}
          <JsonView className="json-tree-view" src={rawResponse} collapsed={false} />
        </div>
      ))}
    </Modal>
  );
};

export default withErrorBoundary(TransactionResponseModal);
