import React from 'react';
import { AlertContent } from 'components/shared/Alert/Alert';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import { pm } from 'lib/helpers/utility';

const ClinicalNoteCurrentNoteTypeNotesMyScribeWarning = () => {
  const permissions = useRecoilValue(state.permissions);

  if (!pm(permissions, ['myscribe.narrative_update', 'myscribe.narrative_read'], 'or')) {
    return null;
  }

  return (
    <AlertContent
      className="mt-2"
      icon="stars-ai"
      iconShade="600"
      width="fit"
      color="secondary"
      message={
        <div>
          <strong>Warning!</strong> MyScribe AI can make mistakes, please confirm the information.
        </div>
      }
    />
  );
};

export default ClinicalNoteCurrentNoteTypeNotesMyScribeWarning;
