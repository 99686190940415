import cs from 'classnames';

import NoteHeader from 'components/shared/Forms/Custom/Provider/components/NoteHeader';

import AddOrderButton from './components/AddOrderButton';
import Orders from './components/Orders';

const OrdersSection = () => {
  return (
    <div className={cs('relative flex h-full flex-col')} tabIndex={0}>
      <div className="h-[calc(100%-55px)] text-sm text-neutral-600 duration-200">
        <div className="h-full">
          <NoteHeader title="Orders" showPreviousFrom={false}>
            <AddOrderButton />
          </NoteHeader>

          <Orders />
        </div>
      </div>
    </div>
  );
};

export default OrdersSection;
