import { requestApi } from './Api';

export const getServices = async (navigate, params, ...options) =>
  requestApi({
    navigate,
    url: '/api/practice/services/get',
    params,
    ...options
  });

export const createServices = async (navigate, params, ...options) =>
  requestApi({
    navigate,
    url: '/api/practice/services/bulk-create',
    params,
    ...options
  });

export const createService = async (navigate, params, ...options) =>
  requestApi({
    navigate,
    url: '/api/practice/services/create',
    params,
    ...options
  });

export const updateService = async (navigate, params, ...options) =>
  requestApi({
    navigate,
    url: '/api/practice/services/update',
    params,
    ...options
  });

export const getService = async (navigate, params, ...options) =>
  requestApi({
    navigate,
    url: '/api/practice/services/get_one',
    params,
    ...options
  });
