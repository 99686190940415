import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { ia } from 'lib/helpers/utility';
import React from 'react';

const CNArrived = ({ data, setAptEndedModalVisible }) => (
  <Allowed requiredPermissions="appointments.read">
    <div className="flex flex-col gap-2">
      {ia(data) ? (
        data.map((patientTimeline, index) => (
          <div key={index}>
            <div className="flex items-center justify-between">
              <p className="text-sm font-500 text-neutral-800">{patientTimeline.title}</p>
            </div>
            <div className="ml-6">
              <div className="flex w-full items-center justify-between">
                <p className="text-sm  text-neutral-800">
                  {patientTimeline.type == 'appointment_ended'
                    ? 'Ended at'
                    : patientTimeline.type === 'kiosk'
                      ? 'Arrived at'
                      : 'Started at'}
                </p>
                <p className="ml-6 max-w-full overflow-hidden text-ellipsis whitespace-nowrap  text-sm text-neutral-800">
                  {patientTimeline.start_time}
                </p>
              </div>
              <div className="flex w-full items-center justify-between gap-1">
                <p className="text-sm  text-neutral-800">{patientTimeline.timeLabel}</p>
                <p className="text-sm  text-neutral-800">{patientTimeline.duration}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div
          className="flex w-full cursor-pointer items-center justify-end gap-1"
          onClick={() => setAptEndedModalVisible(true)}>
          <p className="text-sm  text-primary-600">Mark patient arrived</p>
          <Icon icon="new-checkbox-rec" size={14} />
        </div>
      )}
    </div>
  </Allowed>
);

export default CNArrived;
