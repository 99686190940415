import React from 'react';
import moment from 'moment';
import { useFormikContext } from 'formik';
import Button from 'components/shared/Buttons/Button';
import { checkUnsavedChanges } from '../lib/checkUnsavedChanges';
import FooterAction from './FooterAction';

const Footer = ({ handleClose, appointment }) => {
  const { values, initialValues, handleSubmit } = useFormikContext();
  const isReschedule = !moment(values.starts_at).isSame(moment(initialValues.starts_at));
  const isUnsaved = checkUnsavedChanges(values, initialValues);

  return (
    <div className="flex w-full items-center justify-between gap-4">
      <Button text="Cancel" onClick={handleClose} outlined color="neutral" size="small" />
      <div className="flex items-center ">
        <FooterAction appointment={appointment} handleClose={handleClose} />
        {values.status !== 'cancelled' && (
          <Button
            text={`Update ${isReschedule ? ' & Reschedule' : ''} Appointment`}
            disabled={!isUnsaved}
            onClick={() => {
              setTimeout(() => {
                handleSubmit();
              }, 100);
            }}
            size="small"
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
