export const emptyStateMessages = (isServices) => {
  return {
    title: isServices ? 'No services' : 'No products',
    link: `/portal/settings/${isServices ? 'services' : 'products'}`,
    buttonText: isServices ? 'Create a service' : 'Create a product',
    icon: isServices ? 'new-search-services-illustration' : 'new-no-products-illustration',
    startDesc: isServices ? 'Start adding services now!' : 'Start adding products now!',
    createDesc: isServices ? 'Start creating services now!' : 'Start creating products now!',
    textWrapperClassName: isServices && '!-mt-4'
  };
};
