import { currentPractice } from 'components/practice/practiceState';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { formatTimeRelativeToToday } from 'lib/helpers/utility';
import React from 'react';
import { useRecoilValue } from 'recoil';

const Drafts = ({ draftedForms }) => {
  const { formik, setBreadCrumbs, setEnableNextTo, setShowNavigationButtons } =
    useFormBuilderContext();
  const practice = useRecoilValue(currentPractice);

  const handleDraftClick = (form) => {
    setBreadCrumbs((prevCrumbs) => {
      const currentStepIndex = prevCrumbs.findIndex((crumb) => crumb.view === form.step);
      return prevCrumbs.map((crumb, index) => {
        if (index < currentStepIndex) {
          return { ...crumb, active: false, isFinished: true };
        } else if (index === currentStepIndex) {
          return { ...crumb, active: true, isFinished: false };
        } else {
          return { ...crumb, active: false, isFinished: false };
        }
      });
    });
    setShowNavigationButtons(true);
    if (form.step !== 'name_and_type') {
      setEnableNextTo(true);
    }
    const { packetIds, ...formWithoutPackets } = form;

    formik.setValues({ ...formik.values, form: formWithoutPackets, form_packet_ids: packetIds });
  };
  return (
    <div>
      <div className="grid gap-2 py-3">
        <p className="text-sm font-500 text-primary-900">Drafts</p>
        <p className="text-[12px] font-500 text-neutral-600">
          Finish up the forms that you’ve already started.
        </p>
      </div>
      {draftedForms.map((draft) => (
        <div
          key={draft.id}
          onClick={() => handleDraftClick(draft)}
          className="!my-[6px] flex justify-between rounded-[10px] border-solid border-neutral-200 p-3">
          <p className="text-[12px]">{draft?.name}</p>
          <p className="text-[12px] text-neutral-600">
            {formatTimeRelativeToToday(draft?.updated_at, practice?.timezone)}
          </p>
        </div>
      ))}
    </div>
  );
};

export default Drafts;
