import Button from 'components/shared/Buttons/Button';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';

const TreatmentPlanHeader = ({ breadcrumb }) => {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();

  const { steps, step } = breadcrumb;

  const onShowCreateTreatmentPlan = () => {
    setSelectedTreatmentPlan({});
    breadcrumb.push({
      title: 'New Treatment Plan',
      name: 'create'
    });
  };

  return steps[step]?.name === 'start' ? (
    <Button
      className="mr-5 min-w-[230px]"
      size="small"
      icon="new-add-square"
      onClick={onShowCreateTreatmentPlan}
      text="New Treatment Plan"
      data-qa="new-treatment-btn"
      transparent
    />
  ) : null;
};
export default TreatmentPlanHeader;
