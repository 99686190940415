import React from 'react';
import cs from 'classnames';
import Highlight from 'components/shared/HighlightData/HighlightData';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import { checkFractionString } from './lib/helper';
import Allowed from 'components/shared/Permissions/Allowed';

const PermissionTitle = ({
  title,
  description,
  permissionsActive,
  isSelected,
  setSelected,
  permissionKey,
  highlight,
  actions
}) => {
  const { formik, actionsEnabled, setActionsEnabled } = useEditRoleContext();
  const handleComponentOnClick = () => {
    setSelected(permissionKey);
  };
  const allActionsEnabled = checkFractionString(actionsEnabled[permissionKey]);

  const onClickHandler = () => {
    actions.forEach((action) => {
      const [fieldGroup, fieldAction] = action.field.split('.');
      formik.setFieldValue(`permissions.${fieldGroup}.${fieldAction}`, !allActionsEnabled);
    });

    const b = actionsEnabled[permissionKey].split('/')[1];

    setActionsEnabled({
      ...actionsEnabled,
      [permissionKey]: allActionsEnabled ? `0/${b}` : `${b}/${b}`
    });
  };

  return (
    <div
      className={cs(
        'flex h-fit w-[360px] flex-col items-start justify-start gap-4 self-stretch rounded-md p-4',
        isSelected ? 'bg-primary-50' : 'cursor-pointer'
      )}
      id={permissionKey}
      onClick={handleComponentOnClick}>
      <div className="flex h-fit flex-col items-start justify-start gap-1.5 self-stretch">
        <div className="text-sm font-semibold leading-snug text-neutral-800">
          <Highlight text={title} highlight={highlight} />
        </div>
        <div
          className={
            (cs('text-sm font-normal leading-snug text-neutral-700'),
            isSelected ? '' : 'opacity-50')
          }>
          <Highlight text={description} highlight={highlight} />
        </div>
      </div>
      <div className="inline-flex items-center justify-between self-stretch">
        <div
          className={cs(
            'text-sm font-medium leading-snug text-primary-900',
            isSelected ? '' : 'opacity-50'
          )}>
          {permissionsActive} permissions active
        </div>
        {isSelected && (
          <Allowed requiredPermissions="role.update">
            <div
              className="flex cursor-pointer items-center justify-center gap-1.5 rounded-[34px] border-[1px] border-solid border-primary-200 px-3.5 py-1 text-sm font-medium leading-snug text-[#13b8ff]"
              onClick={onClickHandler}>
              {allActionsEnabled ? 'Disable All' : 'Enable All'}
            </div>
          </Allowed>
        )}
      </div>
    </div>
  );
};

export default PermissionTitle;
