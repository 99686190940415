import React, { createRef, useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import { patientAppointment } from '../patientState';
import Appointment from './appointment';
import Box from 'components/shared/Box/Box';

const Appointments = () => {
  const navigate = useNavigate();

  const [ctaCopy, setCtaCopy] = useState('Onboard');
  const [appointments, setAppointments] = useState([]);
  const [refetchData, setRefetchData] = useState(false);
  const [appointmentState, setAppointmentState] = useRecoilState(patientAppointment);

  const ref = useRef([]);

  const [searchParams] = useSearchParams();
  const queryParam = Number(searchParams.get('id'));

  useEffect(() => {
    getAppointments();
  }, [refetchData]);

  useEffect(() => {
    if (appointments.length > 1) {
      setCtaCopy('Check-in');
    }
  }, [appointments]);

  const getAppointments = async () => {
    try {
      let res = await requestApi({ url: '/api/patient/appointment/read', navigate });
      const { appointments: loadedAppointments } = res;
      if (loadedAppointments) {
        setAppointments(loadedAppointments);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const checkIn = async (appointmentId) => {
    try {
      setAppointmentState({ id: appointmentId });
      localStorage.setItem('appointmentId', appointmentId);
      setTimeout(async () => {
        const data = await requestApi({
          url: `/api/patient/checkin/start`,
          params: { appointmentId },
          navigate
        });
        if (data?.redirectTo) {
          navigate(data.redirectTo);
        }
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };

  // const upcomingIds = appointments.filter?.((a) => a?.upcoming).map?.((a) => a?.id);
  // if (queryParam && upcomingIds && upcomingIds.includes(queryParam)) checkIn(queryParam);

  ref.current = appointments.map((e, i) => ref.current[i] ?? createRef());

  return (
    <div className='flex flex-col p-12'>
      <div className='pb-6'>
        <span className="text-xl font-500 text-neutral-900">Upcoming Appointments</span>
        <div className="grid grid-cols-2 gap-4">
          {appointments.length > 0 &&
            appointments.map((appointment, index) => {
              if (appointment.upcoming) {
                return (
                  <Appointment
                    key={index}
                    index={index}
                    appointment={appointment}
                    callback={() => setRefetchData(!refetchData)}
                    ctaCopy={ctaCopy}
                    exportRef={ref}
                    checkIn={checkIn}
                  />
                );
              }
            })}
        </div>
      </div>
      <div>
        <span className="text-xl font-500 text-neutral-900">Past Appointments</span>
        <div className="grid grid-cols-2 gap-4">
          {appointments.length > 0 &&
            appointments.map((appointment, index) => {
              if (!appointment.upcoming) {
                return (
                  <Appointment
                    key={index}
                    appointment={appointment}
                    callback={() => setRefetchData(!refetchData)}
                  />
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Appointments;
