import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { downloadFile } from 'api/Filestack';

import { getBase64FromUrl } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import applyChangesToImage from 'components/shared/DocumentEditor/lib/applyChangesToImage';
import applyChangesToPDF from 'components/shared/DocumentEditor/lib/applyChangesToPDF';

const useDocumentActions = ({ data, file, renderDocumentOnLoad, patientId, type = 'pdf' }) => {
  const navigate = useNavigate();

  const { myPortalPractice } = useParams();

  const [renderedDocument, setRenderedDocument] = useState(null);

  useEffect(() => {
    const loadDocument = async () => {
      if (renderDocumentOnLoad && file) {
        const doc = await renderDocument();
        setRenderedDocument(doc);
      }
    };

    loadDocument();
  }, [file]);

  const handleDownload = async () => {
    if (!data.mimetype)
      return showAlert({
        message: 'There was a problem downloading the document.',
        color: 'danger'
      });

    if (data.mimetype === 'application/pdf' || data.mimetype === 'pdf') {
      const { blob } = await applyChangesToPDF({
        pdf: file,
        navigate,
        id: data?.id,
        kind: 'patient_documents'
      });

      if (blob) {
        const pdfUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = `${data.name}_document.pdf`;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        return document.body.removeChild(link);
      }
    }

    if (
      data.mimetype === 'image/jpeg' ||
      data.mimetype === 'image/png' ||
      data.mimetype === 'image/jpg'
    ) {
      const { blob } = await applyChangesToImage({
        image: file,
        navigate,
        id: data?.id,
        kind: 'patient_documents',
        myPortalPractice
      });

      if (blob) {
        const imageUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = `${data.name}_document${data.mimetype.replace('image/', '.')}`;
        document.body.appendChild(link);
        link.click();
        return document.body.removeChild(link);
      }
    }

    // Fallback for other file types
    const downloadedFile = await downloadFile(navigate, {
      handle: data.document_handle,
      patientId
    });

    if (!downloadedFile.file)
      return showAlert({
        message: 'There was a problem downloading the document.',
        color: 'danger'
      });

    const url = await getBase64FromUrl(downloadedFile.file);
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = data.name;
    downloadLink.click();
  };

  const renderDocument = async () => {
    if (type === 'pdf') {
      const { blob, url } = await applyChangesToPDF({
        pdf: file,
        navigate,
        id: data.id,
        kind: 'patient_documents'
      });

      if (blob) {
        return URL.createObjectURL(blob);
      } else {
        return url;
      }
    } else if (type === 'image') {
      const { blob, url } = await applyChangesToImage({
        image: file,
        navigate,
        id: data.id,
        kind: 'patient_documents',
        myPortalPractice
      });

      if (blob) {
        return URL.createObjectURL(blob);
      } else {
        return url;
      }
    }
  };

  return { handleDownload, renderDocument, renderedDocument, setRenderedDocument };
};

export default useDocumentActions;
