import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';

import { showAlert } from 'components/shared/Alert/Alert';

import { interimApi } from '../../../api/InterimApi';
import Spinner from '../../shared/spinner/spinner';

const CareCredit = ({
  amount,
  number,
  patientId,
  onSuccess,
  onFail,
  appointmentId,
  surcharge,
  setReceipt,
  invoices
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    submitPayment();
  }, []);

  const addSurchargeToAppointment = async () => {
    try {
      const params = {
        id: appointmentId,
        update_surcharge: true,
        surcharge
      };
      const res = await interimApi('/api/appointment/update', params, navigate);
      if (res.data.affectedRows > 0) {
        setRefetchAppointments();
        hidePreviewAppointment();
        showAlert({ title: 'Appointment Updated!', color: 'success' });
      } else {
        onFail(`There was an error updating this appointment`);
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const submitPayment = async () => {
    try {
      const res = await interimApi(
        '/api/transactions/cash/add',
        {
          invoices,
          patientId,
          appointmentId,
          transaction: {
            surcharge_amount: surcharge,
            amount: { value: amount, currency: 'USD' },
            note: `Outside Financing Number: ${number}`,
            type: 'care_credit'
          }
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          onSuccess();
          setReceipt(res.data.receipt.data);
          showAlert({ title: 'Transaction Finished!', color: 'success' });
          break;
        default:
          if (error) onFail(error || `There's been an unexpected error. Please try again later.`);
          Honeybadger.notify(
            `file: cash/CareCredit, method: submitPayment - try, error: ${
              error ?? 'Theres been an error'
            }`
          );
          onFail(error);
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: cash/CareCredit, method: submitPayment - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
      onFail(`There's been an unexpected error. Please try again later.`);
    }
  };

  return (
    <div className="px-50 pb-50 text-center">
      <Spinner wrapperStyle={{ margin: '0 auto' }} />
      <p>Just a moment...</p>
    </div>
  );
};

export default CareCredit;
