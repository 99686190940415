import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { Disclosure, Transition } from '@headlessui/react';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { useRecoilValue } from 'recoil';
import { userState as user_state } from '../../state';
import Allowed from 'components/shared/Permissions/Allowed';

const SecondarySidebarList = ({ list: loadedList, expanded, target, title }) => {
  const { pathname } = useLocation();
  const userState = useRecoilValue(user_state);
  const { saveDisplaySettings } = useDisplaySettings(userState);
  const [list, setList] = useState(loadedList);
  const [hoveredGroup, setHoveredGroup] = useState(false);

  const handleOpen = (key, value) => {
    const updatedObject = {
      ...list,
      [key]: {
        ...list[key],
        open: !value
      }
    };

    saveDisplaySettings({
      ...userState.display_settings,
      sidebars: {
        ...userState.display_settings?.sidebars,
        [target]: updatedObject
      }
    });
    setList(updatedObject);
  };

  if (expanded) {
    return (
      <div className="h-full">
        <span className="flex pb-2 pl-[10px] text-[10px] uppercase leading-5 text-neutral-500">
          {title}
        </span>
        <div className="h-full overflow-auto !pr-1">
          {Object.values(list).map((group, index) => {
            let isActive = pathname.includes(group.path);
            return group.children ? (
              <Allowed key={index} requiredPermissions={group?.permission}>
                <Disclosure key={index}>
                  <>
                    <Disclosure.Button
                      data-qa={`beyond-billing-${group.name}`}
                      className="item-center group m-0 flex w-full cursor-pointer items-center justify-between !gap-2 gap-x-2 rounded-[4px] !p-2 text-sm"
                      onClick={() => handleOpen(group.path, group.open)}
                      onMouseEnter={() => setHoveredGroup(group.path)}
                      onMouseLeave={() => setHoveredGroup(null)}>
                      <div className="flex items-center gap-x-2">
                        <Icon
                          icon={group.icon}
                          color={isActive || hoveredGroup === group.path ? 'primary' : 'neutral'}
                          size={18}
                        />
                        <span
                          className={cs(
                            'leading-5',
                            isActive || hoveredGroup === group.path
                              ? 'hover:text-primary-60 text-primary-600'
                              : 'text-neutral-800 hover:text-neutral-800'
                          )}>
                          {group.name}
                        </span>
                      </div>
                      <Icon
                        icon="chevron-down"
                        size={11}
                        color={isActive || hoveredGroup === group.path ? 'primary' : 'neutral'}
                        className={cs('duration-100', group.open && 'rotate-180')}
                      />
                    </Disclosure.Button>
                    <Transition
                      show={group.open}
                      enter="transition duration-100 ease-out"
                      enterFrom="transform scale-95 opacity-0"
                      enterTo="transform scale-100 opacity-100"
                      leave="transition duration-75 ease-out"
                      leaveFrom="transform scale-100 opacity-100"
                      leaveTo="transform scale-95 opacity-0">
                      <Disclosure.Panel className="!ml-4 border-0 !border-l !border-solid !border-neutral-200">
                        {group?.children?.map((item, idx) => (
                          <Allowed key={idx} requiredPermissions={item?.permission}>
                            <NavLink
                              key={idx}
                              data-qa={`beyondbilling-list-${item.name}`}
                              end={item.end ? item.end : false}
                              to={item.path}
                              state={item.name}
                              className={({ isActive }) =>
                                cs(
                                  'item-center relative m-0 !ml-2 flex cursor-pointer !gap-2 rounded-[4px] !p-2 text-sm leading-5 before:absolute before:-left-[11px] before:top-1/2 before:h-[5px] before:w-[5px] before:-translate-y-1/2 before:scale-0 before:rounded-full before:bg-primary-500 before:duration-200 before:content-[""]',
                                  isActive
                                    ? 'bg-primary-50 font-600 text-primary-600 before:scale-100 hover:text-primary-600'
                                    : 'text-neutral-800 hover:text-neutral-800'
                                )
                              }>
                              <span>{item.name}</span>
                            </NavLink>
                          </Allowed>
                        ))}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                </Disclosure>
              </Allowed>
            ) : (
              <Allowed key={index} requiredPermissions={group?.permission}>
                <NavLink
                  className="item-center m-0 flex w-full cursor-pointer items-center justify-between !gap-2 gap-x-2 rounded-[4px] !p-2 text-sm"
                  to={group.path}
                  key={group.path}
                  onMouseEnter={() => setHoveredGroup(group.path)}
                  onMouseLeave={() => setHoveredGroup(null)}>
                  <div className="flex items-center gap-x-2" data-qa={`${group.name.toLowerCase()}-click`}>
                    <Icon
                      icon={group.icon}
                      color={isActive || hoveredGroup === group.path ? 'primary' : 'neutral'}
                      size={18}
                    />
                    <span
                      className={cs(
                        'leading-5',
                        isActive || hoveredGroup === group.path
                          ? 'hover:text-primary-60 text-primary-600'
                          : 'text-neutral-800 hover:text-neutral-800'
                      )}>
                      {group.name}
                    </span>
                  </div>
                </NavLink>
              </Allowed>
            );
          })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SecondarySidebarList;
