import { useEffect } from 'react';

import cs from 'classnames';
import { useField, useFormikContext } from 'formik';

import { findRelationName, iaRa } from 'lib/helpers/utility';
import { useGuarantors } from 'lib/hooks/queries/guarantors/useGuarantors';

import Select from 'components/shared/Select/Select';

const optionifyGuarantors = (data) => {
  return data.map((item) => ({
    label: `${item.f_name} ${item.l_name} (${findRelationName(item.relationship)})`,
    value: item.id
  }));
};

const GuarantorSelect = ({ className }) => {
  const {
    values: { patientId }
  } = useFormikContext();

  const [field, meta, { setValue }] = useField({
    name: 'guarantor'
  });

  const {
    data: guarantors,
    isLoading,
    isFetching
  } = useGuarantors({
    params: { userId: patientId },
    options: {
      select: (data) => [
        { label: 'Self', value: 'self' },
        ...optionifyGuarantors(iaRa(data?.guarantors))
      ]
    }
  });
  const loading = isLoading || isFetching;

  useEffect(() => {
    setValue(guarantors?.[0]);
  }, [guarantors]);

  const changeHandler = (option) => {
    setValue(option);
  };

  return (
    <Select
      {...field}
      error={meta.touched && meta.error}
      isClearable={false}
      data-qa="guarantor-select"
      inputId="guarantorSelect"
      label="Guarantor"
      required
      isLoading={loading}
      options={guarantors}
      parentClassName={cs(className)}
      onChange={changeHandler}
    />
  );
};

export default GuarantorSelect;
