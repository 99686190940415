import { memo } from 'react';

import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia } from 'lib/helpers/utility';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Popover from 'components/shared/Popovers/Popover/Popover';
import TableCounter from 'components/shared/Table/TableCounter';
import { permissions } from 'components/state';

import { getDefaultFilters } from '../lib/ordersHelper';

const category = 'orders_table';

const OrdersTableComponent = ({ data, count, loading, showHeader = false, showFooter = false }) => {
  const { limit, page, setPage, filters, setFilters, selectedRows } = useTableContext();

  const { is_admin } = useRecoilValue(permissions);
  const defaultFilters = getDefaultFilters(is_admin);

  const changePageHandler = ({ selected }) => {
    setPage(selected + 1);
  };

  return (
    <>
      {showHeader && (
        <Header title="Orders">
          <div className="flex items-center gap-2">
            {ia(selectedRows) && selectedRows.length > 0 && (
              <Popover
                className="flex flex-1 justify-center self-center"
                buttonClassName="!bg-primary-700"
                position="left"
                isFixed
                options={[]}
              />
            )}
            <DisplayButton />
            <Filter
              category={category}
              defaultFilters={defaultFilters}
              filters={filters}
              setFilters={setFilters}
              menuPortalTarget={document.body}
              btnClassName="!h-[30px]"
            />
          </div>
        </Header>
      )}
      <div className="flex h-full flex-col overflow-hidden">
        <AGTable
          data={data}
          loading={loading}
          defaultFilters={defaultFilters}
          noRowsOverlayComponent={() => <p>No Orders</p>}
        />
        {showFooter && (
          <div className="flex items-center justify-between px-3">
            <TableCounter page={page} limit={limit} count={count} />
            <Pagination
              onPageChange={changePageHandler}
              totalItems={count}
              page={page}
              perPage={limit}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default memo(OrdersTableComponent);
