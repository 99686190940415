import React, { useRef, useState } from 'react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import ReactToPrint from 'react-to-print';
import { Capitalize, formatDate, formatDateAndTime, ia } from 'lib/helpers/utility';
import PaymentAmount from './PaymentAmount';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Receipt from 'components/shared/Receipt/Receipt';
import { currentPractice } from 'components/practice/practiceState';
import NotFound from 'components/practice/NewAppointment/components/NotFound';

const Payments = ({ isPined }) => {
  const receiptRef = useRef();
  const [receipt, setReceipt] = useState({});
  const [show, setShow] = useState({ receipt: false });
  const { values } = useFormikContext();
  const { timezone } = useRecoilValue(currentPractice) || {};
  const { patient, invoice, starts_at, payments = [] } = values || {};

  const handleReceipt = () => {
    setShow({ ...show, receipt: true });
    setReceipt({
      invoice,
      patient,
      transactions: payments,
      transaction: { date_of_service: starts_at }
    });
  };

  if (!ia(payments))
    return (
      <NotFound
        title="No payments yet"
        textWrapperClassName="!mt-1"
        icon="new-no-income-illustration"
        description="You can start making new payments right now."
      />
    );

  return (
    <div className={cs('flex min-h-[232px] flex-col justify-between', !isPined && 'h-[256px]')}>
      <div className="w-full overflow-auto">
        <div className="grid grid-cols-4 gap-x-3 p-4 pb-2 [&_p]:text-[14px] [&_p]:font-500 [&_p]:text-neutral-800">
          <p className="text-left leading-[22px]">Date</p>
          <p className="text-right leading-[22px]">Amount</p>
          <p className="leading-[22px]">Type</p>
          <p className="leading-[22px]">Status</p>
        </div>
        <div className="grid max-h-[200px] gap-[10px] bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] p-4">
          {payments?.map?.((payment, i) => {
            const { amount_paid_self, type, status, created_at, surcharge_amount } = payment || {};

            return (
              <div
                key={i}
                data-public
                data-dd-privacy="allow"
                className="grid grid-cols-4 gap-3 text-sm">
                <p
                  className="whitespace-nowrap leading-[22px]"
                  title={formatDateAndTime(created_at, timezone)}>
                  {formatDate(created_at, timezone)}
                </p>
                <p className="flex items-center justify-end !gap-1 leading-[22px]">
                  <PaymentAmount
                    amountPaidSelf={amount_paid_self}
                    surchargeAmount={surcharge_amount}
                  />
                </p>
                <p className="truncate leading-[22px]">
                  {Capitalize(String(type).replaceAll('_', ' '))}
                </p>
                <p className="leading-[22px]">{Capitalize(status)}</p>
              </div>
            );
          })}
        </div>
      </div>

      <Button
        outlined
        size="small"
        color="primary"
        icon="receipt-text"
        onClick={handleReceipt}
        text="View walkout receipt"
        className="ml-auto mr-4 mt-2 w-max"
      />

      {show.receipt && (
        <Modal
          slideFromRight
          title="Receipt"
          className="w-[1200px]"
          headButton={
            <div className="flex w-full items-center justify-end">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color=""
                    size="small"
                    type="link"
                    text="Print"
                    icon="new-printer-bulk"
                    className="text-primary-900"
                  />
                )}
                content={() => receiptRef.current}
              />
            </div>
          }
          handleOpen={show.receipt}
          handleClose={() => setShow({ ...show, receipt: false })}
          handleClick={() => setShow({ ...show, receipt: false })}
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                outlined
                transparent
                text="Close"
                color="neutral"
                data-qa="close-btn"
                onClick={() => setShow({ ...show, receipt: false })}
              />
            </div>
          }>
          <Receipt receipt={receipt} parentRef={receiptRef} walkout />
        </Modal>
      )}
    </div>
  );
};

export default Payments;
