import React, { useEffect, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';

import { imagePath } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';

const PracticeBox = ({ headerPhoto, title, message, onClick }) => {
  const [logo, setLogo] = useState();

  useEffect(() => {
    if (headerPhoto) {
      try {
        const parsedHeader = JSON.parse(headerPhoto);

        setLogo(parsedHeader?.jpg ? parsedHeader.jpg : parsedHeader?.png);
      } catch {
        setLogo(null);
      }
    }
  }, [headerPhoto]);

  return (
    <div
      onClick={onClick}
      className="bg-neutral-50 flex gap-3 rounded-lg cursor-pointer hover:bg-primary-50 p-4">
      {logo ? (
        <img src={imagePath(logo)} className="w-14 h-14 rounded-md"></img>
      ) : (
        <div className="w-14 h-14 rounded-md bg-primary-50 text-primary-600 text-xl font-800 items-center flex justify-center">
          {' '}
          {title.charAt(0)}{' '}
        </div>
      )}
      <div className="flex flex-col flex-1 mb-1 justify-center">
        <p className="text-lg text-primary-900">{title}</p>
        <p className="text-sm text-neutral-600">{message}</p>
      </div>
      <Icon icon="new-chevron-right" className="mr-2" />
    </div>
  );
};

export default withErrorBoundary(PracticeBox);
