import { iaRa, mString } from 'lib/helpers/utility';

export const formatProcedure = (procedure, claim, index) => {
  const date = procedure?.from_date;
  return {
    id: procedure.id,
    select: false,
    date,
    createdAt: claim.created_at,
    patientName: claim?.superbill?.patient?.fullName || '',
    procedure_code: procedure.procedure_code,
    provider: `Qty: ${procedure?.units || ''} Mods: ${procedure?.modifiers || ''}`,
    diagnosis: '',
    billed: mString(procedure.charge),
    adjustment: iaRa(procedure.adjustment),
    paid: mString(procedure.paid),
    isClaimRow: false,
    claimId: claim.id,
    procedureIndex: index
  };
};
