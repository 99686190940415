import moment from 'moment';
import React from 'react';
import { useRecoilValue } from 'recoil';
import state from 'components/state';
import Button from 'components/shared/Buttons/Button';

const LicenseView = ({ license, onEdit }) => {
  const { map: issuers } = useRecoilValue(state.issuerState);

  return (
    <tr>
      <td>
        {issuers && issuers[license.issuer_id]?.name}
        {license.issuer_id === 1 && <div>{license.kind}</div>}
      </td>
      <td>{license.license_number}</td>
      <td className={`${moment().isAfter(moment(license.expired_on), 'd') && 'text-red-600'}`}>
        {moment(license.expired_on).format('MM/DD/YYYY')}
        {moment().isAfter(moment(license.expired_on), 'd') && ' ⚠️'}
      </td>
      <td>
        <Button text="Edit" onClick={() => onEdit(license)} />
      </td>
    </tr>
  );
};

export default LicenseView;
