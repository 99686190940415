import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { useInfiniteQuery } from '@tanstack/react-query';

import { fetchData } from 'api/FetchData';

export const useInfiniteFetchData = ({
  queryKey,
  url,
  params = {},
  options = {},
  dependencies = [],
  onSuccess = () => {},
  formatData = (data) => data
}) => {
  const navigate = useNavigate();

  if (!queryKey) {
    throw new Error('The "queryKey" parameter is required for useInfiniteFetchData.');
  }

  if (!url) {
    throw new Error('The "url" parameter is required for useInfiniteFetchData.');
  }

  const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
    [queryKey, ...dependencies],
    ({ pageParam = params }) => {
      return fetchData(url, navigate, pageParam);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const count = Number(lastPage?.count || 0);
        const totalFetched = allPages.flatMap((page) => page?.[queryKey]).length;

        return totalFetched < count ? { ...params, page: allPages.length + 1 } : undefined;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess
    }
  );

  const flattenedData = useMemo(
    () => formatData(data?.pages?.flatMap((page) => page?.[queryKey]) || []),
    [data, queryKey, formatData]
  );

  return {
    data: flattenedData,
    fetchNextPage,
    hasNextPage: !!hasNextPage,
    isLoading,
    isFetchingNextPage
  };
};
