import React from 'react';

import Icon from 'components/shared/Icon/Icon';

const Header = ({ isHeader, title, onXClick }) => {
  if (!isHeader) return null;

  return (
    <div className="flex gap-4 justify-between py-2 px-5 bg-white shadow-[0px_2px_4px_rgba(27,33,35,0.04)]">
      <span className="leading-6 text-base font-medium">{title}</span>
      <div
        className="flex items-center justify-center size-[26px] ml-auto cursor-pointer select-none"
        onClick={onXClick}>
        <Icon
          data-qa="new-close-square"
          className="cursor-pointer"
          icon="new-close"
          stroke
          color="neutral"
          shade={600}
          size={10}
          onClick={onXClick}
        />
      </div>
    </div>
  );
};
export default Header;
