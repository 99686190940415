import React from 'react';

import { optionify } from 'lib/helpers/utility';

import Allowed from 'components/shared/Permissions/Allowed';
import Status from 'components/shared/Status/Status';

import Actions from '../components/Actions';

export const getFinalProductObjShape = (values) => ({
  code: values.code,
  name: values.name,
  note: values.note,
  expiration_date: values.expiration_date,
  status: values.status,
  amount_cents: Number(values.amount_cents),
  cost_cents: Number(values.cost_cents),
  amount_cents_with_tax: Math.floor(Number(values.amount_cents_with_tax)),
  state_tax_rate: Number(values.state_tax_rate),
  local_tax_rate: Number(values.local_tax_rate),
  quantity: Number(values.quantity),
  quantity_units: values.quantity_units,
  image: values.image,
  lot: values.lot,
  category_id: values.category_id,
  color: values.color,
  ndc_codes: values.ndc_codes
});

export const statuses = [
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'out of stock',
    label: 'Out Of Stock'
  },
  {
    value: 'inactive',
    label: 'Inactive'
  },
  {
    value: 'discontinued',
    label: 'Discontinued'
  }
];

export const defaultFilters = (categories) => ({
  name: {
    type: 'search',
    values: null,
    title: 'Product Name',
    placeholder: 'Search name'
  },
  category: {
    type: 'search',
    values: [],
    multiple: true,
    options: optionify(categories, 'name', 'id'),
    title: 'Category',
    placeholder: 'Select Category'
  },
  status: {
    type: 'search',
    values: [],
    multiple: true,
    options: statuses,
    title: 'Status',
    placeholder: 'Select Status'
  }
});

export const defaultColumns = () => [
  {
    field: 'code',
    headerName: 'Code',
    cellClass: 'no-border',
    cellRenderer: ({ data }) => {
      return (
        <div className="flex items-center gap-2">
          <div style={{ backgroundColor: data.color }} className="h-6 w-6 rounded-lg"></div>
          <p>{data.code}</p>
        </div>
      );
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'category',
    headerName: 'Category',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return value ? value?.name : '-';
    }
  },
  {
    field: 'quantity',
    headerName: 'Quantity',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    field: 'amount_cents',
    headerName: 'Price',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => (
      <Allowed requiredPermissions="payments.read">
        <span>${value / 100}</span>
      </Allowed>
    )
  },
  {
    field: 'sales_count',
    headerName: 'Total Sales',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    field: 'lot',
    headerName: 'LOT',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return value ? value : '-';
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: ({ value }) => (
      <div className="flex h-full items-center justify-center">
        <Status status={value} className="flex min-w-[80px] items-center justify-center" />
      </div>
    ),
    maxWidth: 200,
    minWidth: 50,
    resizable: true
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
