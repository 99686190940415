import React from 'react';

import Tippy from '@tippyjs/react';

import { ia } from 'lib/helpers/utility';

import Badge from 'components/shared/Badge/Badge';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Button from 'components/shared/Buttons/Button';

import DocumentUpload from './DocumentUpload';
import PrintPopover from './PrintPopover';

const ModalHeader = ({ claim = {}, address = {}, breadcrumb = {}, setClaim }) => {
  const { steps, step } = breadcrumb;

  return (
    <div className="flex w-full items-center justify-between gap-[6px] pr-3">
      <div className="flex items-center gap-3">
        <Breadcrumb breadcrumb={breadcrumb} />
      </div>
      <div className="flex w-full items-center justify-end gap-2">
        {!claim.claim_md_synced && ia(claim?.pendingClaimMdChanges) ? (
          <Tippy
            content="There are changes locally not submitted and updates from the clearing house not yet synced."
            className="tippy-dark"
            placement="bottom">
            <div>
              <Button
                text="Pending Sync & Submission"
                size="small"
                icon="new-refresh-arrows"
                className="!gap-1"
                outlined
                iconIsStroke
                onClick={() => {
                  if (steps[step].name !== 'sync') {
                    breadcrumb.push({ title: 'Sync', name: 'sync' });
                  }
                }}
              />
            </div>
          </Tippy>
        ) : (
          <>
            {!claim.claim_md_synced && claim?.id && (
              <Tippy
                content={
                  <div>
                    <p>Changes made locally have not been submitted to the clearing house.</p>
                  </div>
                }
                className="tippy-dark"
                placement="bottom">
                <div className="flex items-center">
                  <Badge
                    size="medium"
                    color="warning"
                    text="Pending Submission"
                    isRounded={false}
                  />
                </div>
              </Tippy>
            )}
            {ia(claim?.pendingClaimMdChanges) && (
              <Tippy
                content={
                  <div>
                    <p>Updates exist in the clearing house that haven't been synced locally.</p>
                  </div>
                }
                className="tippy-dark"
                placement="bottom">
                <div>
                  <Button
                    text="Sync Required"
                    size="small"
                    icon="new-refresh-arrows"
                    className="!gap-1"
                    outlined
                    iconIsStroke
                    onClick={() => {
                      if (steps[step].name !== 'sync') {
                        breadcrumb.push({ title: 'Sync', name: 'sync' });
                      }
                    }}
                  />
                </div>
              </Tippy>
            )}
          </>
        )}
        <DocumentUpload claim={claim} setClaim={setClaim} />
        {/* Print Popover */}
        <PrintPopover claim={claim} address={address} wrapperClassName="!mr-1" />
      </div>
    </div>
  );
};

export default ModalHeader;
