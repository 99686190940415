import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Footer from '../components/Footer';
import {
  getChanges,
  hasRecurrenceChanged,
  getFormattedValues,
  getEventFromInitialValues
} from './lib';
import { createCalendarEvent, updateCalendarEvent } from '../../../../api/CalendarEvent';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../shared/Alert/Alert';
import { isEmpty } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import HeaderEventForm from './components/HeaderEventForm';
import DateTimeEventForm from './components/DateTimeEventForm';
import RecurrenceEventForm from './components/RecurrenceEventForm';
import FooterEventForm from './components/FooterEventForm';
import FooterEventPreview from './EvenPreview/FooterEventPreview';
import { currentPractice as currPractice } from '../../practiceState';
import { useRecoilValue } from 'recoil';

const EventForm = ({
  event,
  setSelectedTab,
  setFooter,
  hideModal,
  practitioners,
  selectedDateTime
}) => {
  const queryClient = useQueryClient();
  const currentPractice = useRecoilValue(currPractice);
  const [recurrenceChanged, setRecurrenceChanged] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: getEventFromInitialValues({
      event,
      practitioners,
      selectedDateTime,
      timezone: currentPractice?.timezone
    }),
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      until: Yup.date().min(Yup.ref('start_date'), 'Until date must be greater than start date')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const formattedValues = getFormattedValues(values);
        const changes = event ? getChanges(event, formattedValues) : null;
        setSubmitting(true);
        if (event) {
          if (!isEmpty(changes)) {
            await updateCalendarEvent(navigate, { ...changes, id: event.id, type: values.type });
            showAlert({
              color: 'success',
              message: 'Event updated successfully.'
            });
          }
        } else {
          delete formattedValues.type;
          await createCalendarEvent(navigate, formattedValues);
          showAlert({
            color: 'success',
            message: 'Event created successfully.'
          });
        }
        setSubmitting(false);
        await handleInvalidateQuery();
        hideModal();
      } catch (error) {
        console.log({ error });
      }
    }
  });
  const { values, isSubmitting } = formik;
  useEffect(() => {
    !!setFooter &&
      setFooter(
        event ? (
          <FooterEventPreview {...{ event, formik, hideModal, recurrenceChanged }} />
        ) : (
          <Footer
            hideModal={hideModal}
            loading={isSubmitting}
            submitForm={formik.handleSubmit}
            title={event ? 'Update Event' : 'Create Event'}
          />
        )
      );
    !!setSelectedTab && setSelectedTab('event');
  }, [recurrenceChanged]);

  useEffect(() => {
    if (event) {
      const formattedValues = getFormattedValues(values);
      const changes = getChanges(event, formattedValues, true);
      const hasChanged = hasRecurrenceChanged(changes);
      setRecurrenceChanged(hasChanged);
    }
  }, [values]);

  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['appointment-events'] });
  };

  return (
    <div className="flex flex-col gap-5 ">
      <HeaderEventForm formik={formik} practitioners={practitioners} />
      <DateTimeEventForm formik={formik} editing={!!event} />
      {!event?.parent_event_id && <RecurrenceEventForm formik={formik} event={event} />}
      <FooterEventForm event={event} formik={formik} />
    </div>
  );
};
export default EventForm;
