import React from 'react';
import moment from 'moment';
import { addDays } from 'date-fns';
import { DateRangePicker as DateRange } from 'react-date-range';
import Popover from 'components/shared/Popovers/Popover/Popover';
import Icon from 'components/shared/Icon/Icon';

const DateRangePicker = ({ filters, setFilters }) => {
  const handleSelect = (ranges) => {
    setFilters((prev) => ({
      ...prev,
      date: [ranges.selection],
      page: 1
    }));
  };

  const handleClear = (e) => {
    e.stopPropagation();
    setFilters((prev) => ({
      ...prev,
      date: [{ startDate: null, endDate: null, key: 'selection' }],
      page: 1
    }));
  };

  const getDisplayText = () => {
    const { startDate, endDate } = filters.date[0];
    if (!startDate || !endDate) {
      return 'Date • All time';
    }
    return (
      <>
        {`${moment(startDate).format('MM.DD.YYYY')} - ${moment(endDate).format('MM.DD.YYYY')} `}
        <Icon icon="new-close" size={10} className="ml-2 cursor-pointer" onClick={handleClear} />
      </>
    );
  };

  return (
    <Popover
      text={getDisplayText()}
      icon={false}
      buttonClassName="!px-4 h-full border border-solid border-neutral-300"
      buttonTheme="transparent"
      position="left">
      <div className="calendar-wrapper">
        <DateRange
          ranges={[
            {
              startDate: filters.date[0].startDate || new Date(),
              endDate: filters.date[0].endDate || new Date(),
              key: 'selection'
            }
          ]}
          onChange={handleSelect}
          months={1}
          direction="horizontal"
          minDate={addDays(new Date(), -300)}
          maxDate={addDays(new Date(), 900)}
        />
      </div>
    </Popover>
  );
};

export default DateRangePicker;
