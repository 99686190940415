import { getMembers } from 'api/Member';

export const getMembersList = async (navigate) => {
  const membersList = await getMembers(navigate, {});

  return membersList?.members?.map((member) => ({
    label: member?.f_name + ' ' + member?.l_name,
    value: member?.user_id
  }));
};
