import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getContacts } from '../../../../api/Contacts';

export const useContacts = ({ params, options }) => {
  const navigate = useNavigate();
  const { search, page, limit, filters } = params;
  return useQuery(['contacts', search, page, limit, filters], () => getContacts(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    ...options
  });
};
