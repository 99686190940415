import React, { useEffect } from 'react';
import { useVersionCheck } from '../../lib/hooks/useVersionCheck';
import { useRecoilValue } from 'recoil';
import state from '../state';
import { showAlert } from '../shared/Alert/Alert';
import Button from '../shared/Buttons/Button';

const AutoUpdate = () => {
  const hasBeenAuthChecked = useRecoilValue(state.hasBeenAuthChecked);
  const { status, reloadPage, UpdateStatus } = useVersionCheck({
    interval: 5 * 60 * 1000,
    type: 'interval',
    ignoreServerCache: true
  });

  useEffect(() => {
    console.log(`update: currVersion: ${status}`);
    if (!hasBeenAuthChecked && status > UpdateStatus.available) {
      showAlert({
        color: 'primary',
        title: 'Update available, reloading page...'
      });
      setTimeout(() => {
        reloadPage();
      }, 2000);
    }

    switch (status) {
      case UpdateStatus.checking:
        console.log('checking for update');
        break;
      case UpdateStatus.current:
        console.log('checking for update');
        break;
      case UpdateStatus.available:
        console.log('checking for update');
        showAlert({
          color: 'primary',
          title: 'An update is available!',
          message: 'Please update at your earliest convenience for best experience',
          position: 'bottom-center',
          customButtons: [<Button color={'primary'} onClick={reloadPage} text={'Reload Page'} />],
          duration: 9999999
        });
        break;
      case UpdateStatus.major:
        console.log('checking for update');
        showAlert({
          color: 'warning',
          title: 'A major update is available!',
          message: 'Please update as soon as possible',
          position: 'bottom-center',
          customButtons: [<Button color={'warning'} onClick={reloadPage} text={'Reload Page'} />],
          duration: 9999999
        });
        break;
      case UpdateStatus.critical:
        console.log('Major Update has been released');
        showAlert({
          color: 'error',
          title: 'A major update is available!',
          message: 'This update will automatically reload your page.',
          position: 'bottom-center',
          customButtons: [<Button color={'error'} onClick={reloadPage} text={'Reload Page'} />],
          duration: 9999999
        });
        setTimeout(() => {
          reloadPage();
        }, 2000);
        break;
    }
  }, [status]);

  return null;
};

export default AutoUpdate;
