import React, { useEffect, useRef, useState } from 'react';
import PackageTable from './components/PackagesTable';
import 'react-confirm-alert/src/react-confirm-alert.css';
import NewPackage from './components/NewPackage';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Buttons/Button';
import PackageTableRow from './components/PackageTableRow';
import HeaderTable from './components/HeaderTable';
import { requestApi } from '../../../../api/Api';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { showAlert } from '../../../shared/Alert/Alert';

const Packages = () => {
  const [packageToBeUpdated, setPackageToBeUpdated] = useState(null);
  const [newPackageModalVisible, setNewPackageModalVisible] = useState(false);
  const formPackageRef = useRef();

  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10,
    column: 'name',
    sort: 'asc'
  });
  const navigate = useNavigate();

  const { data, isLoading } =
    useQuery({
      queryKey: ['getPackages', filters?.search, filters?.page, filters.sort, filters.column],
      queryFn: getPackages,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: (data) => ({
        ...data,
        packages: data.packages.map((pack) => ({
          ...pack,
          products: pack.products.map((prod) => ({ ...prod, label: prod.name })),
          procedures: pack.procedures.map((procedure) => ({ ...procedure, label: procedure.name }))
        }))
      })
    }) || {};

  const packages = data?.packages;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters?.searchTerm]);

  const hideNewPackage = () => {
    setPackageToBeUpdated(null);
    setNewPackageModalVisible(false);
  };

  async function getPackages() {
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to read packages!",
          color: 'danger'
        });
      }
    };
    const response = await requestApi({
      url: '/api/package/get',
      params: {
        ...filters,
        withCount: true
      },
      navigate,
      onError
    });

    return response;
  }

  return (
    <div>
      <HeaderTable {...{ filters, setFilters, setNewPackageModalVisible }} />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="!p-3">
          <PackageTable
            handleAddClick={() => setNewPackageModalVisible(true)}
            filters={filters}
            setFilters={setFilters}
            packagesCount={data?.packagesCount}>
            {packages?.length ? (
              packages.map((pack, index) => (
                <PackageTableRow
                  key={pack?.id}
                  index={index}
                  pack={pack}
                  setPackageToBeUpdated={setPackageToBeUpdated}
                  lastIndex={index === packages.length - 1}
                  filters={filters}
                />
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="!py-4"> No Packages found</div>
                </td>
              </tr>
            )}
          </PackageTable>
          <Modal
            customStyling={{ width: '620px' }}
            bodyClassName="!p-4 !pr-1 !max-h-[unset] !h-full overflow-hidden"
            handleOpen={newPackageModalVisible || !!packageToBeUpdated}
            handleClose={hideNewPackage}
            title={`${packageToBeUpdated ? 'Edit' : 'Create new'} package`}
            slideFromRight
            key={newPackageModalVisible || packageToBeUpdated}
            footer={
              <div className="flex w-full justify-between">
                <Button
                  outlined
                  text="Cancel"
                  color="neutral"
                  onClick={hideNewPackage}
                  id="cancelPackageModalBtn"
                  data-qa="cancel-btn"
                />
                <Button
                  onClick={() => formPackageRef.current.submitForm()}
                  text={packageToBeUpdated ? 'Update' : 'Create package'}
                  id="savePackageModalBtn"
                  data-qa="create-update-package-btn"
                />
              </div>
            }>
            <NewPackage
              ref={formPackageRef}
              onClose={hideNewPackage}
              pack={packageToBeUpdated}
              filters={filters}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Packages;
