import cs from 'classnames';
import { useRecoilState } from 'recoil';

import { Capitalize, findRelationName, formatDate } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';

const GuarantorContent = ({ guarantor, className }) => {
  const { timezone } = useRecoilState(currentPractice);

  return (
    <div className={cs('grid gap-2', className)}>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Name:</p>
        <p className="text-sm font-500 text-neutral-800">
          {guarantor.f_name + ' ' + guarantor.l_name}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Email:</p>
        <p className="text-sm font-500 text-neutral-800">{guarantor?.email}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Relationship:</p>
        <p className="text-sm font-500 text-neutral-800">
          {findRelationName(guarantor.relationship)}
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Gender: </p>
        <p className="text-sm font-500 text-neutral-800">{Capitalize(guarantor.gender)}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Date of Birth:</p>
        <p className="text-base">{guarantor.dob ? formatDate(guarantor.dob, timezone) : 'N/A'}</p>
      </div>

      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Address:</p>
        <p className="text-base">{guarantor.guarantorAddress?.fullAddress}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">City:</p>
        <p className="text-base">{guarantor.guarantorAddress?.city}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">Zip Code:</p>
        <p className="text-base">{guarantor.guarantorAddress?.zip}</p>
      </div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-sm text-neutral-600">State:</p>
        <p className="text-base">{guarantor.guarantorAddress?.addressState?.name}</p>
      </div>
    </div>
  );
};

export default GuarantorContent;
