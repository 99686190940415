import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { getFaxesCSV } from 'api/Fax';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useArchivedFaxes } from 'lib/hooks/queries/fax/useArchivedFaxes';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import { useTags } from 'lib/hooks/queries/tags/useTags';

import { currentPractice } from 'components/practice/practiceState';
import DocumentModal from 'components/shared/DocumentEditor/DocumentModal';

import NewFaxesTable from '../NewFaxesTable';

import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, GRID_OPTIONS, getColDefs } from './configs';

const NewArchivedFaxes = () => {
  const currPractice = useRecoilValue(currentPractice);
  const timezone = currPractice?.timezone;
  const [previewFax, setPreviewFax] = useState(null);
  return (
    <TableContextProvider
      name="archived_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs({ timezone, setPreviewFax })}
      pagination>
      <ArchivedFaxesTable previewFax={previewFax} setPreviewFax={setPreviewFax} />
    </TableContextProvider>
  );
};

function ArchivedFaxesTable({ previewFax, setPreviewFax }) {
  const category = 'archived_faxes';
  const kind = 'fax';

  const navigate = useNavigate();

  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const { limit, page, sort, filters } = useTableContext();

  const { data, isFetching, isLoading } = useArchivedFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const { data: patientsData } = usePatients({
    params: { practiceId: currPractice.id }
  });
  const patients = patientsData?.patients;

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });

  const patientOptions = useMemo(() => {
    if (patients?.length > 0) {
      return patients.map((patient) => ({
        id: patient.user_id,
        label: patient?.f_name + ' ' + patient?.l_name,
        value: patient?.id
      }));
    }
    return [];
  }, [patients]);
  filters.patients.options = patientOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags.map((t) => ({
        label: t?.name,
        value: t?.id
      }));
    }
    return [];
  }, [tagsList]);
  filters.tags.options = tagOptions;

  const draftFaxes = data?.archivedFaxes || [];
  const count = data?.count || [];

  const onPreviewFax = async (fax) => {
    setPreviewFax(fax);
  };

  const onRowClicked = (clickedFax) => {
    onPreviewFax(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0', 'tags'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'archived'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };

  return (
    <div className="h-full" data-dd-privacy="allow">
      <NewFaxesTable
        count={count}
        onExportCSV={onExportCSV}
        data={draftFaxes}
        category={category}
        name="Archived Faxes"
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
      />

      {!!previewFax && (
        <DocumentModal
          kind="fax"
          id={previewFax?.id}
          handleOpen={!!previewFax}
          handleClose={() => setPreviewFax()}
          isEditable
          title={'Fax #' + previewFax?.id}
        />
      )}
    </div>
  );
}

export default NewArchivedFaxes;
