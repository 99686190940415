import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPracticeMembers } from 'api/Roles';

export const usePracticeMembers = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['getPracticeMembers', ...dependencies],
    () => getPracticeMembers(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options
    }
  );
};
