import React, { useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const NonERXPrescriptionAndFrequency = ({ formik = {} }) => {
  const nonERXPrescriptionAndFrequency = useRef();

  return (
    <Textarea
      isEditor
      id="non-erx-prescription"
      data-qa="non-erx-prescription-textarea"
      label="Non eRX Prescription And Frequency"
      value={formik?.values?.non_erx_prescription_and_frequency}
      forwardedRef={nonERXPrescriptionAndFrequency}
      placeholder="Add non eRX prescription and frequency"
      onChange={(e) => formik.setFieldValue('non_erx_prescription_and_frequency', e)}
    />
  );
};

export default NonERXPrescriptionAndFrequency;
