const useEditorSelections = ({
  fabricCanvasRef,
  setDrawProps,
  setObjectSelected,
  setIsBold,
  setIsItalic,
  setFontFamily
}) => {
  const deleteSelected = () => {
    const fabricCanvas = fabricCanvasRef.current;
    const activeObject = fabricCanvas?.getActiveObject();

    if (activeObject) {
      if (activeObject.type === 'activeSelection') {
        // Multiple objects are selected
        activeObject.forEachObject((obj) => {
          fabricCanvas?.remove(obj);
        });
      } else {
        // Single object is selected
        fabricCanvas?.remove(activeObject);
      }
      fabricCanvas?.discardActiveObject();
      fabricCanvas?.renderAll();
      setObjectSelected(null);
    }
  };

  const setColor = (color) => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas?.getActiveObjects().forEach((obj) => {
      if (obj.stroke) {
        obj.set('stroke', color);
      } else {
        obj.set('fill', color);
      }
    });
    fabricCanvas?.renderAll();
  };

  const toggleBold = () => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas?.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontWeight', obj.fontWeight === 'bold' ? 'normal' : 'bold');
      }
    });
    fabricCanvas?.renderAll();
    setIsBold((prev) => !prev);
  };

  const toggleItalic = () => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas?.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontStyle', obj.fontStyle === 'italic' ? 'normal' : 'italic');
      }
    });
    fabricCanvas?.renderAll();
    setIsItalic((prev) => !prev);
  };

  const setDrawProp = (prop, value) => {
    const fabricCanvas = fabricCanvasRef.current;
    setDrawProps((p) => ({
      ...p,
      [prop]: value
    }));
    if (prop === 'color' && fabricCanvas) {
      fabricCanvas.freeDrawingBrush.color = value;
    } else if (prop === 'width' && fabricCanvas) {
      fabricCanvas.freeDrawingBrush.width = value;
    }
  };

  const changeFontFamily = (newFontFamily) => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas?.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontFamily', newFontFamily);
      }
    });
    fabricCanvas?.renderAll();
    setFontFamily(newFontFamily);
  };

  const fonts = [
    {
      value: 'Times New Roman',
      label: 'Serif'
    },
    {
      value: 'Helvetica',
      label: 'Sans-Serif'
    },
    {
      value: 'Courier',
      label: 'Monospace'
    }
  ];

  return {
    fonts,
    changeFontFamily,
    setDrawProp,
    toggleItalic,
    toggleBold,
    setColor,
    deleteSelected
  };
};

export default useEditorSelections;
