import React from 'react';
import Input from '../../../../shared/Input/Input';
import Allowed from 'components/shared/Permissions/Allowed';
import { pm } from 'lib/helpers/utility';
import { permissions } from 'components/state';
import { useRecoilValue } from 'recoil';

const EmergencyContact = ({ formik, onChange, disabled }) => {
  const userPermissions = useRecoilValue(permissions);
  const canUpdate = pm(userPermissions, 'patients_contact.update');
  return (
    <Allowed requiredPermissions="patients_contact.read">
      <div>
        <label className="text-lg font-600">Emergency Contact</label>

        <div>
          <h4 className="text !my-2 text-primary-700">Primary</h4>

          <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
            <Input
              label="Name"
              onChange={onChange}
              placeholder="Full name"
              id="emergency_contact.name"
              data-qa="emergency-contact-name"
              name="emergency_contact.name"
              value={formik?.values?.emergency_contact?.name}
              error={formik?.errors?.emergency_contact?.name}
              disabled={!canUpdate || disabled}
            />

            <Input
              label="Phone"
              onChange={onChange}
              placeholder="Phone Number"
              id="emergency_contact.phone"
              data-qa="emergency-contact-phone"
              name="emergency_contact.phone"
              value={formik?.values?.emergency_contact?.phone}
              error={formik?.errors?.emergency_contact?.phone}
              disabled={!canUpdate || disabled}
            />

            <Input
              label="Relationship"
              onChange={onChange}
              placeholder="Relation to patient"
              id="emergency_contact.relationship"
              data-qa="emergency-contact-relationship"
              name="emergency_contact.relationship"
              value={formik?.values?.emergency_contact?.relationship}
              error={formik?.errors?.emergency_contact?.relationship}
              disabled={!canUpdate || disabled}
            />
          </div>
        </div>

        <div>
          <h4 className="text !my-2 text-primary-700">Secondary</h4>

          <div className="grid grid-cols-3 !gap-5 sm:!grid-cols-1 md:grid-cols-2">
            <Input
              label="Name"
              onChange={onChange}
              placeholder="Full name"
              id="secondary_emergency_contact.name"
              data-qa="secondary-emergency-contact-name"
              name="secondary_emergency_contact.name"
              value={formik?.values?.secondary_emergency_contact?.name}
              error={formik?.errors?.secondary_emergency_contact?.name}
              disabled={!canUpdate || disabled}
            />

            <Input
              label="Phone"
              onChange={onChange}
              placeholder="Phone Number"
              id="secondary_emergency_contact.phone"
              data-qa="secondary-emergency-contact-phone"
              name="secondary_emergency_contact.phone"
              value={formik?.values?.secondary_emergency_contact?.phone}
              error={formik?.errors?.secondary_emergency_contact?.phone}
              disabled={!canUpdate || disabled}
            />

            <Input
              label="Relationship"
              onChange={onChange}
              placeholder="Relation to patient"
              id="secondary_emergency_contact.relationship"
              data-qa="secondary-emergency-contact-relationship"
              name="secondary_emergency_contact.relationship"
              value={formik?.values?.secondary_emergency_contact?.relationship}
              error={formik?.errors?.secondary_emergency_contact?.relationship}
              disabled={!canUpdate || disabled}
            />
          </div>
        </div>
      </div>
    </Allowed>
  );
};

export default EmergencyContact;
