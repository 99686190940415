import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Checkbox from '../../../../../../shared/Checkbox/Checkbox';
import { getItemStyle } from '../../lib/renderedDragAndDropHelper';

const FormList = ({ forms, keyParent, handleClickDropdown, onDragEndChildren }) => {
  const { clinicalNote } = useClinicalNoteContext() || {};
  return (
    <DragDropContext onDragEnd={onDragEndChildren}>
      {forms.map((form, dropdownIndex) => {
        return (
          <Droppable droppableId={form.id} key={form.id}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                <Draggable
                  key={form.id}
                  draggableId={form.id}
                  index={dropdownIndex}
                  isDragDisabled={true}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      className="flex cursor-pointer items-center gap-x-[2px]">
                      <Checkbox
                        disabled={clinicalNote?.locked}
                        data-qa={`form-type-${form.id}`}
                        label={form.title}
                        isChecked={form.checked}
                        onChange={() => handleClickDropdown(keyParent, form.id)}
                        labelClassName="cursor-pointer"
                        inputClassName="cursor-pointer"
                        parentLabelClassName="px-4 py-[11px]"
                      />
                    </div>
                  )}
                </Draggable>
              </div>
            )}
          </Droppable>
        );
      })}
    </DragDropContext>
  );
};

export default FormList;
