import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getIcd10Codes } from '../../../../api/Icd10';

export const useICD10Codes = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['icd10Codes', ...dependencies], () => getIcd10Codes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
