import { Honeybadger } from '@honeybadger-io/react';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import Input from 'components/shared/Input/Input';

const FormValidators = require('../../lib/validation/validate_datapoint');
const validateDatapoint = FormValidators.validateDatapoint;

const NewDatapoint = forwardRef(({ type, handleClose }, ref) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const [newDatapoint, setNewDatapoint] = useState({
    name: ''
  });

  const [errors, setErrors] = useState({
    name: {
      success: false,
      message: ''
    }
  });

  const handleChange = (event) => {
    setNewDatapoint({ name: event.target.value });
  };

  const validateForm = () => {
    var payload = validateDatapoint(newDatapoint);
    if (payload.success) {
      setErrors({
        name: {
          success: false,
          message: ''
        }
      });
      submitDatapoint(newDatapoint);
    } else {
      const errors = payload.errors;
      setErrors({
        name: {
          success: false,
          message: errors.name
        }
      });
    }
  };

  useImperativeHandle(ref, () => ({
    validateForm: () => validateForm(),
    isLoading: loading
  }));

  const submitDatapoint = async (newDatapoint) => {
    setLoading(true);
    var params = {
      name: newDatapoint.name,
      datapointType: type
    };
    try {
      const resData = await requestApi({
        url: '/api/admin/databank/create',
        params,
        returnRaw: true,
        navigate
      });
      if (resData.status === 200) {
        showAlert({
          title: `${type} created successfully`,
          color: 'primary'
        });
        queryClient.invalidateQueries('databanks');
        handleClose();
        setNewDatapoint({ name: '' });
      } else {
        setErrors({
          name: {
            success: false,
            message: "Wasn't able to add this datapoint"
          }
        });
      }
    } catch (err) {
      Honeybadger.notify(`submitDatapoint: ${err}`);
    }
    setLoading(false);
  };

  return (
    <Input
      label="Name"
      data-qa="datapoint-create-input"
      value={newDatapoint.name}
      onChange={handleChange}
      error={errors.name && errors.name.message}
    />
  );
});

export default NewDatapoint;
