import * as Yup from 'yup';

const UpdateAppointmentValidationSchema = Yup.object().shape({
  practitioner_id: Yup.string().required('Practitioner required'),
  // service_id: Yup.array().min(1, 'Please select at least one procedure'),
  procedures: Yup.array()
    .of(
      Yup.object().shape({
        total_cost_cents: Yup.number().min(0, 'Price >= 0')
      })
    )
    .min(1, 'At least one procedures is required')
});

export default UpdateAppointmentValidationSchema;
