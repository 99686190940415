import moment from 'moment';

import { iaRa, mString } from 'lib/helpers/utility';

import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';

import { formatProcedure } from './formatProcedure';
import { displayDiagnosis, sumAdjustment } from './utils';

export const formatClaimData = ({ claims }) => {
  let rows = [];

  claims.forEach((claim) => {
    const date = claim?.appointment?.starts_at || claim.created_at;
    const claimDate = date ? moment(date).format('MM/DD/YYYY hh:mm a') : '';
    const claimRow = {
      id: claim.id,
      select: true,
      date: claimDate,
      createdAt: claim.created_at,
      patientName: claim?.superbill?.patient?.fullName || '',
      procedure_code: claim?.superbill?.patient?.fullName || '',
      provider: claim?.superbill?.practitioner?.fullName || '',
      diagnosis: displayDiagnosis(claim),
      billed: mString(claim?.total_charge * 100),
      adjustment: mString(sumAdjustment(claim.invoice?.procedures)),
      paid: mString(claim?.invoice?.amount_paid),
      isClaimRow: true,
      claimId: claim.id
    };

    rows.push(claimRow);

    const relatedProcedures = iaRa(claim?.invoice?.procedures)
      .filter(filterSuperbillClaimProcedure)
      .map((procedure, index) => formatProcedure(procedure, claim, index));

    rows = rows.concat(relatedProcedures);
  });

  return rows;
};
