import cs from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { calculateProceduresTotal } from 'components/public/lib/utils';
import numOrDefault from 'lib/helpers/numOrDefault';
import { discountTypes, procedureTypes } from '../../../constants';
import { Capitalize, formatDate, ia, mString } from '../../../lib/helpers/utility';
import { currentPractice } from '../../practice/practiceState';
import CurrencyInput from '../../shared/CurrencyInput/CurrencyInput';
import { permissions } from '../../state';
import { handleDiscountChange } from './Invoices/invoicesHelpers';

const InvoiceReadOnly = ({ invoices, ref, from, setInvoice }) => {
  const p = useRecoilValue(permissions);

  const practice = useRecoilValue(currentPractice);
  const timezone = practice.timezone;
  const claims = practice?.display_settings?.claims;

  return (
    <div className="flex flex-col !gap-2" ref={ref}>
      {ia(invoices) &&
        invoices?.map((i, idx) => {
          const amountPaid = i?.amount_paid;

          const tax = i?.tax || 0;

          const discountAmount = i?.discount?.amount_cents || 0;

          const currentBalance = i?.total_amount - i?.amount_paid - discountAmount + tax;

          const dateOfService = formatDate(i?.dateofservice, timezone, true);

          const insPaid = calculateProceduresTotal(i?.procedures)?.ins_payment;
          const adjustment = numOrDefault(i?.adjustment);
          return (
            <>
              <div key={i} className="!p-4 ">
                <div className="flex gap-3"></div>

                <div className="grid !gap-4">
                  <table className="bg-inherit">
                    <thead className="text-xxs uppercase text-neutral-600">
                      <tr className="border-x-0 !border-b border-t-0 border-solid border-b-neutral-100">
                        <td className="!p-1 text-left !font-500" title="Details">
                          Details
                        </td>
                        <td className="!p-1 text-left !font-500" title="Details">
                          DOS
                        </td>
                        <td className="!p-1 !font-500" title="Type">
                          Type
                        </td>
                        <td className="!p-1 text-center !font-500" title="Quantity">
                          Qty
                        </td>
                        <td className="!p-1 !font-500" title="Charge">
                          Charge
                        </td>
                        {claims ? (
                          <>
                            <td
                              className="w-[105px] !p-1 !font-500"
                              title="Estimated Insurance Payment">
                              Est. Ins. Pay
                            </td>
                            <td
                              className="w-[105px] !p-1 !font-500"
                              title="Estimated Insurance Adjustment">
                              Est. Ins. Adj
                            </td>
                          </>
                        ) : null}
                        <td className="w-[105px] !p-1 !font-600" title="Patient Balance">
                          Pt. bal
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      {/* Services */}
                      {ia(i?.services || i?.procedures) &&
                        (i?.services || i?.procedures || []).map((procedure, index) => {
                          const { name, type, amount, total_cost_cents, pt_balance, quantity } =
                            procedure || {};

                          const {
                            charge = 0,
                            ins_adjustment = 0,
                            ins_payment = 0
                          } = calculateProceduresTotal([procedure]);

                          return (
                            <tr
                              key={name + index}
                              className="border-x-0 !border-b  border-t-0 border-solid border-b-neutral-100 font-500 leading-5 text-neutral-800 hover:bg-transparent">
                              <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs">
                                {name}
                              </td>
                              <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs">
                                {dateOfService}
                              </td>
                              <td className="text-xxs">
                                {procedureTypes?.find((o) => o?.value === type)?.label ||
                                  Capitalize((type || '').replace('_', ' ')) ||
                                  'Service'}
                              </td>
                              <td className="!py-2 text-center text-xxs">{quantity || 1}</td>
                              <td className="!py-2 text-xxs">
                                {mString(
                                  (charge || amount || total_cost_cents || 0) * (quantity || 1)
                                )}
                              </td>
                              {claims ? (
                                <>
                                  <td className="!py-2 text-xxs">
                                    {mString((ins_payment || 0) * (quantity || 1))}
                                  </td>
                                  <td className="!py-2 text-xxs">
                                    {mString((ins_adjustment || 0) * (quantity || 1))}
                                  </td>
                                </>
                              ) : null}
                              <td className="!py-2 text-xs font-600">
                                {mString(
                                  (pt_balance || total_cost_cents || 0) * (quantity || 1) || 0
                                )}
                              </td>
                            </tr>
                          );
                        })}

                      {/* Packages with procedures and products*/}
                      {ia(i?.packages) &&
                        i?.packages.map(
                          (
                            { name, total_amount_cents, procedures, products, status, sales_count },
                            index
                          ) => {
                            const hasProcedures = ia(procedures);
                            const hasProducts = ia(products);

                            return (
                              <>
                                <tr
                                  key={name + index}
                                  className={cs(
                                    'font-500 text-neutral-800  hover:bg-transparent',
                                    status === 'cancelled' && 'line-through',
                                    !hasProcedures &&
                                      'border-x-0 !border-b border-t-0 border-solid border-b-neutral-100'
                                  )}>
                                  <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs">
                                    {name}
                                  </td>
                                  <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs">
                                    {dateOfService}
                                  </td>
                                  <td className="!py-2 text-xxs">Package</td>
                                  <td className="!py-2 text-center text-xxs">{sales_count || 1}</td>
                                  <td className="!py-2 text-xxs">{mString(total_amount_cents)}</td>
                                  {claims ? (
                                    <>
                                      <td className="text-xxs">{mString(0)}</td>
                                      <td className="text-xxs">{mString(0)}</td>
                                    </>
                                  ) : null}
                                  <td className="!py-2 text-xs font-600">
                                    {mString(total_amount_cents)}
                                  </td>
                                </tr>

                                {hasProcedures &&
                                  procedures.map(({ name, quantity }, index) => {
                                    return (
                                      <tr
                                        key={name + index}
                                        className={cs(
                                          'text-xxs text-neutral-800 hover:bg-transparent',
                                          status === 'cancelled' && 'line-through',
                                          procedures?.length - 1 === index &&
                                            !hasProducts &&
                                            'border-x-0 !border-b border-t-0 border-solid border-b-neutral-100'
                                        )}>
                                        <td className="max-w-[240px] !py-1 !pl-3 text-left">
                                          {name}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="!py-1 text-center">{quantity || 1}</td>

                                        <td></td>
                                        {claims ? <td colSpan={2}></td> : null}
                                        <td></td>
                                      </tr>
                                    );
                                  })}

                                {hasProducts &&
                                  products.map(({ name, quantity }, index) => {
                                    return (
                                      <tr
                                        key={name + index}
                                        className={cs(
                                          'text-xxs text-neutral-800 hover:bg-transparent',
                                          status === 'cancelled' && 'line-through',
                                          products?.length - 1 === index &&
                                            'border-x-0 !border-b border-t-0 border-solid border-b-neutral-100'
                                        )}>
                                        <td className="max-w-[240px] !py-1 !pl-3 text-left">
                                          {name}
                                        </td>
                                        <td></td>
                                        <td></td>
                                        <td className="!py-1 text-center">{quantity || 1}</td>
                                        <td></td>
                                        {claims ? <td colSpan={2}></td> : null}
                                        <td></td>
                                      </tr>
                                    );
                                  })}
                              </>
                            );
                          }
                        )}

                      {/* Products */}
                      {ia(i?.products) &&
                        i?.products.map(
                          ({ name, amount, sales_count, total_amount_cents }, index) => {
                            return (
                              <tr
                                key={name + index}
                                className="border-x-0 !border-b border-t-0 border-solid border-b-neutral-100 text-neutral-800 hover:bg-transparent">
                                <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs text-neutral-900">
                                  {name}
                                </td>
                                <td className="max-w-[240px] !py-2 !pl-0 text-left text-xs">
                                  {dateOfService}
                                </td>
                                <td className="!py-2 text-xxs">Product</td>
                                <td className="!py-2 text-center text-xxs">{sales_count || 1}</td>
                                <td className="!py-2 text-xxs">
                                  {mString(amount || total_amount_cents)}
                                </td>
                                {claims ? (
                                  <>
                                    <td className="!py-2 text-xxs">{mString(0)}</td>
                                    <td className="!py-2 text-xxs">{mString(0)}</td>
                                  </>
                                ) : null}
                                <td className="!py-2 text-xs font-600 ">
                                  {mString(total_amount_cents)}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>

                <div className="m-[16px_-17px_-17px_-17px] flex h-10 items-center !gap-2 !p-4 text-xs font-600 uppercase text-primary-900">
                  <div className="w-1/6">Charge</div>
                  {insPaid > 0 && <div className="w-1/6">INS. PAID</div>}
                  {amountPaid > 0 && <div className="w-1/6">PT. PAID</div>}
                  {(discountAmount > 0 || from === 'quote') && (
                    <div className="w-1/6">Discount</div>
                  )}
                  {(tax > 0 || from == 'quote') && <div className="w-1/6">Tax</div>}
                  {adjustment > 0 && <div className="w-1/6">Adjustment</div>}
                  <div className="flex  w-2/6 flex-auto flex-col items-end">
                    <label className="mb-0 text-sm font-600">Balance</label>
                  </div>
                </div>

                <div className="m-[16px_-16px_-16px_-16px] flex min-h-[40px] items-center !gap-2 bg-gradient-to-r from-[#004F6B3D] to-[#004f6b0d] !px-4 uppercase text-primary-900">
                  <div className="col-span-1 w-1/6 font-600">{mString(i?.total_amount)}</div>
                  {insPaid > 0 && (
                    <div className="col-span-1 w-1/6 font-600">{mString(insPaid)}</div>
                  )}
                  {amountPaid > 0 && (
                    <div className="col-span-1 w-1/6 font-600">{mString(amountPaid)}</div>
                  )}

                  {from === 'quote' && (
                    <>
                      <div className="col-span-1 w-1/6 font-600">
                        <CurrencyInput
                          value={i?.discount?.value || i?.discount?.amount_cents || 0}
                          prefix={i?.discount?.type === 'amount' ? '$' : ''}
                          suffix={i?.discount?.type === 'percent' ? '%' : ''}
                          parentClassName="!w-[120px] !py-4"
                          maxValue={
                            i?.discount?.type === 'percent'
                              ? 10000
                              : currentBalance + (i?.discount?.amount_cents || 0)
                          }
                          className="-mx-1 font-600 text-primary-900"
                          onValueChange={(newBalance) =>
                            handleDiscountChange({
                              idx,
                              invoices,
                              newBalance,
                              setInvoice,
                              discountType: i?.discount?.type
                            })
                          }
                          disabled={!p?.billing?.discount}
                          units={discountTypes}
                          currentUnit={
                            discountTypes.find((t) => t?.value === (i?.discount?.type || 'amount'))
                              ?.label
                          }
                          onUnitChange={(value) => {
                            handleDiscountChange({
                              idx,
                              invoices,
                              setInvoice,
                              discountType: value
                            });
                          }}
                        />
                      </div>

                      <div className="col-span-1 w-1/6 font-600">
                        <CurrencyInput
                          value={i?.tax || 0}
                          parentClassName="!w-[120px] !py-4"
                          className="-mx-1 font-600 text-primary-900"
                          onValueChange={(newBalance) =>
                            setInvoice((prev) => ({ ...prev, tax: +newBalance }))
                          }
                        />
                      </div>
                    </>
                  )}

                  {discountAmount > 0 && from !== 'quote' && (
                    <div className="col-span-1 w-1/6 font-600">{mString(discountAmount)}</div>
                  )}

                  {tax > 0 && from !== 'quote' && (
                    <div className="col-span-1 w-1/6 font-600">{mString(tax)}</div>
                  )}

                  {adjustment > 0 && from !== 'quote' && (
                    <div className="col-span-1 w-1/6 pl-2 font-600">{mString(adjustment)}</div>
                  )}

                  <div className="flex w-2/6 flex-auto flex-col items-end">
                    <label className="!text-sm font-600">
                      {mString(currentBalance - adjustment)}
                    </label>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </div>
  );
};

export default InvoiceReadOnly;
