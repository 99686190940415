import React, { useRef } from 'react';
import { Link } from 'react-router-dom';

import Tippy from '@tippyjs/react';

const NavigateToClaimCell = ({ data, node }) => {
  const ref = useRef();

  if (!!node?.group) {
    return;
  }
  const claims = data?.claims || [];

  const [firstClaim, ...restClaims] = claims || [];

  return (
    <div className="flex flex-row">
      {firstClaim && (
        <Link to={`/portal/beyond-billing/claims/${firstClaim?.id}`} target="_blank">
          {firstClaim?.id}
        </Link>
      )}
      {restClaims.length > 0 && (
        <div className="ml-3">
          <div ref={ref}>+{restClaims.length}</div>
          <Tippy
            className="custom-tooltip !rounded-md"
            theme="light"
            reference={ref}
            content={
              <div className="flex-column p-2">
                {restClaims?.map((claim) => (
                  <div key={claim?.id} className="m-1 rounded-md p-1 hover:bg-primary-50">
                    <Link
                      key={claim?.id}
                      to={`/portal/beyond-billing/claims/${claim.id}`}
                      target="_blank">
                      {claim?.id}
                    </Link>
                  </div>
                ))}
              </div>
            }
            allowHTML
            interactive
            appendTo={() => document.body}
            placement="right"
            offset={[0, 10]}
          />
        </div>
      )}
    </div>
  );
};

export default NavigateToClaimCell;
