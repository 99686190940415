import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Tippy from '@tippyjs/react';
import { paymentMethods } from 'constants.js';

import { readPayers } from 'api/Payer';

import { showAlert } from 'components/shared/Alert/Alert';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import DatePicker from 'components/shared/DatePicker/DatePicker';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import FileUploader from 'components/shared/FileUploader/FileUploader';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

const EOBForm = ({ eob, formik, showInfoInRecordings }) => {
  const [selectedPayerId, setSelectedPayerId] = useState({
    value: eob?.payer_id,
    label: eob?.payer_name
  });
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
    value: eob?.payment_method,
    label: eob?.payment_method
  });

  const isVPayment = selectedPaymentMethod.label === 'vPayment';
  const isDisabled = isVPayment && !!eob;

  const handlers = formik?.values?.handle && [formik.values.handle];

  const navigate = useNavigate();

  const saveImage = async (data) => {
    formik.setFieldValue('handle', data.handle);
  };

  const handleDelete = async () => {
    formik.setFieldValue('handle', '');
  };

  const searchPayers = async (searchTerm) => {
    if (searchTerm) {
      try {
        const result = await readPayers(navigate, { searchTerm });

        if (result?.payers) {
          return result.payers.map((payer) => ({ value: payer.id, label: payer.name }));
        }
      } catch (error) {
        showAlert({
          title: 'Error',
          message: 'There was an error getting all payers.',
          color: 'danger'
        });
      }
    }
  };

  const selectPayer = (e) => {
    formik.setFieldValue('payerid', e?.value);
    setSelectedPayerId(e);
  };

  const selectPaymentMethod = (e) => {
    formik.setFieldValue('payment_method', e?.value);
    setSelectedPaymentMethod({
      value: e?.value,
      label: e?.label
    });
  };

  return (
    <form className="grid gap-3" data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
      <Select
        isAsync
        isClearable={!!selectedPayerId?.value}
        onChange={selectPayer}
        value={selectedPayerId}
        loadOptions={searchPayers}
        placeholder="Choose Payer"
        label="Insurance Payer Name/ID"
        error={formik?.errors?.payerid}
        noOptionsMessage={() => 'Start typing the ID or name.'}
      />
      <Input
        label="Payer trace number"
        value={formik.values.check_number}
        name="check_number"
        onChange={formik.handleChange}
        error={formik?.errors?.check_number}
      />
      {!!eob ? (
        <Tippy content="You cannot change the payment method." className="tippy-dark">
          <Input
            label="Payment Method"
            value={formik.values.payment_method}
            name="payment_method"
            disabled={!!eob}
          />
        </Tippy>
      ) : (
        <Select
          isClearable={!!selectedPaymentMethod?.value}
          label="Payment Method"
          placeholder="Choose Payment Method"
          options={paymentMethods}
          onChange={selectPaymentMethod}
          value={selectedPaymentMethod}
          error={formik?.errors?.payment_method}
        />
      )}
      <CurrencyInput
        id="total_paid"
        label={isVPayment ? 'Total' : 'Total paid'}
        disabled={isDisabled}
        value={formik.values.total_paid}
        error={formik?.errors?.total_paid}
        className="!h-10 !rounded-md border border-neutral-100"
        onValueChange={(v) => formik.setFieldValue('total_paid', v)}
      />
      <DatePicker
        label="Check Date"
        iconIsRight
        value={formik.values.paid_date}
        onChange={(v) => formik.setFieldValue('paid_date', v)}
      />
      <FileUploader
        handlers={handlers}
        onUpload={saveImage}
        onDelete={handleDelete}
        className="max-w-[516px]"
      />
    </form>
  );
};

export default withErrorBoundary(EOBForm);
