import { isNull } from 'lodash';
import moment from 'moment';

export default function getFormattedValues(values, timezone) {
  const end_date = values.end_date ? moment.tz(values.end_date, timezone).format() : null;
  const formattedValues = {
    ...values,
    repeat_event: isNull(values.repeat_event?.value) ? null : Number(values.repeat_event?.value),
    weekdays:
      values.repeat_event?.value && !['Month', 'Year'].includes(values?.repeat_event?.name)
        ? JSON.stringify(values.weekdays.map((item) => item?.value))
        : [],
    end_date: values.repeat_event?.value && values.type !== 'only' ? end_date : null,
    repeat_every: values.repeat_event?.value && values.type !== 'only' ? values.repeat_every : null,
    events: values.repeat_event?.value && values.type !== 'only' ? values.events : null,
    ends_type:
      values.repeat_event?.value && values.type !== 'only' ? values.ends_type?.value : null,
    practitioner_ids: values.practitioner_ids?.map((item) => item?.value),
    start_date: moment.tz(values.start_date, timezone).format(),
    until: moment.tz(values.until, timezone).format()
  };
  return formattedValues;
}
