import { useQueryClient } from '@tanstack/react-query';
import { createMedication } from 'api/Medication';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from 'lib/helpers/utility';
import _ from 'lodash';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from '../../../Icon/Icon';
import '../Custom.scss';
import { CurrentMedications as initialValue } from '../CustomFormInitialStates';
import useNewDesign from '../lib/useNewDesign';
import DeleteMedication from './components/DeleteMedication';
import MedicationsForm from './components/MedicationsForm';
import useMedications from './lib/useMedications';

const CurrentMedications = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  showNarrative = false,
  onChange,
  options = []
}) => {
  const { id: patientId, appointmentId } = useParams();

  const [noDetails, setNoDetails] = useState({ current: null, past: null });
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [prevCurrentMedications, setPrevCurrentMedications] = useState(initialValue);
  const [medicationToDelete, setMedicationToDelete] = useState(null);

  const currentAppointmentId = customFormsParams?.appointmentId || appointmentId;
  const currentPatientId = customFormsParams?.id || patientId;

  const queryClient = useQueryClient();

  const {
    currentMedications,
    setCurrentMedications,
    generateNarrative,
    handleNoDetails,
    handleNarrative,
    textAreaRef,
    syncMedicationsAndClinicalNotes,
    validateMedications
  } = useMedications({
    defaultValue,
    setCurrentFormData,
    onChange,
    setConfirmationModal,
    setNoDetails,
    currentAppointmentId,
    currentPatientId,
    setSyncNarrative,
    syncNarrative
  });

  const { cnDisplaySettings } = useClinicalNoteContext() || {};

  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.medications?.advancedForm
      : true;

  const newDesign = useNewDesign();

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon
            icon="new-clinical-narrative"
            className="cursor-pointer"
            data-qa="new-clinical-narrative"
          />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['medications'] });
  };

  return (
    <div className="CurrentMedications CustomForms">
      <input
        type="hidden"
        value={JSON.stringify(validateMedications(currentMedications))}
        ref={forwardedRef}
      />

      {['current', 'past'].map((type) => (
        <MedicationsForm
          noDetails={noDetails}
          setNoDetails={setNoDetails}
          handleNoDetails={handleNoDetails}
          syncMedicationsAndClinicalNotes={syncMedicationsAndClinicalNotes}
          newDesign={newDesign}
          setConfirmationModal={setConfirmationModal}
          setMedicationToDelete={setMedicationToDelete}
          key={type}
          type={type}
          currentMedications={currentMedications}
          setCurrentMedications={setCurrentMedications}
        />
      ))}

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setCurrentMedications}
            restData={{
              className: 'w-full',
              label: 'Current Medications Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-medication',
              'data-qa': 'narrative-medication',
              name: 'Narrative-medication',
              value: currentMedications?.narrative,
              forwardedRef: textAreaRef,
              formName: 'currentMedications'
            }}
            narrativeOptions={isAdvancedForm ? [...narrativeOptions, ...iaRa(options)] : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          if (_.isEqual(currentMedications, prevCurrentMedications)) {
            return;
          }
          const params = {
            appointmentId: currentAppointmentId || null,
            patientId: currentPatientId,
            medication: currentMedications
          };

          createMedication(() => {}, params).then(handleInvalidateQuery);
          setPrevCurrentMedications(currentMedications);
        }}
      />
      <DeleteMedication
        {...{
          currentMedications,
          setCurrentMedications,
          syncMedicationsAndClinicalNotes,
          handleNoDetails,
          setConfirmationModal,
          confirmationModal,
          medicationToDelete,
          setMedicationToDelete
        }}
      />
    </div>
  );
};

export default CurrentMedications;
