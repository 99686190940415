import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPatientPractices } from 'api/PatientPractice';

export const usePatientPractices = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(
    ['patient-practices', ...dependencies],
    () => getPatientPractices(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
