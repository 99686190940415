import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { createDocument } from 'api/Documents';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';
import { useImportantDocuments } from 'lib/hooks/queries/useDocuments';

import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';

import getDocumentFilters from '../lib/defaultFilters';
import formatFilters from '../lib/formatFilters';

const DocumentsHeader = ({
  setViewDocument,
  setShowInstantPacket,
  patient,
  practitioners,
  patientId,
  setDeleteConfirmation
}) => {
  const navigate = useNavigate();

  const { device } = useUIContext();

  const userState = useRecoilValue(state.userState);

  const { filters, setFilters, page, limit, sort, selectedRows } = useTableContext();

  const selectedItems = selectedRows.map((node) => node.data);

  const queryClient = useQueryClient();

  const { data: importantDocuments } = useImportantDocuments({
    params: { patientId },
    dependencies: [patientId]
  });
  const {
    hipaa,
    insurance = { front: null, back: null },
    id = { front: null, back: null }
  } = importantDocuments || {};

  const openFilestackPicker = async () => {
    const filestackClient = await getFilestackClient(navigate);
    let pickerOptions = {
      accept: ['image/*', '.pdf'],
      fromSources: ['local_file_system'],
      maxFiles: 15,
      maxSize: 50 * 1024 * 1024,
      uploadInBackground: false
    };

    pickerOptions.onUploadDone = async (documents) => {
      let params = {
        documents: documents?.filesUploaded,
        patientId
      };

      const { f_name, l_name } = userState || {};

      let data = await createDocument(navigate, params);

      if (!data?.documents) {
        return showAlert({ title: 'Failed to upload files!', color: 'danger' });
      }

      data = data?.documents?.map((file) => ({
        ...file,
        category: 'clinical',
        uploader_name: `${f_name || ''} ${l_name || ''}`
      }));

      queryClient.setQueryData(
        ['documents', formatFilters({ filters, page, limit, sort, patientId }), patientId],
        (old) => ({
          ...old,
          documents: [...data, ...(old?.documents || [])]
        })
      );
    };

    filestackClient.picker(pickerOptions).open();
  };

  const options = [
    {
      label: 'Personal ID',
      onClick: () => setViewDocument({ load: true, handle: id, type: 'id', title: 'Personal ID' }),
      icon: 'new-cards-lg'
    },
    {
      label: 'Insurance Profile',
      onClick: () =>
        setViewDocument({
          load: true,
          handle: insurance,
          type: 'insurance',
          title: 'Insurance Profile'
        }),
      icon: 'new-credit-card'
    },
    {
      label: 'HIPAA Form',
      onClick: () =>
        setViewDocument({ load: true, handle: hipaa, type: 'hipaa', title: 'HIPAA Form' }),
      icon: 'new-shield-tick'
    }
  ];

  return (
    <Header title="Documents" childrenClassName="whitespace-nowrap py-8">
      <div className="flex items-center gap-2">
        {device === 'desktop' || device === 'laptop' ? (
          <>
            <Button
              icon="new-cards-lg"
              iconSize={16}
              size="small"
              text="Personal ID"
              type="secondary"
              className="rounded-md border-0"
              onClick={() =>
                setViewDocument({ load: true, handle: id, type: 'id', title: 'Personal ID' })
              }
            />
            <Button
              icon="new-credit-card"
              iconSize={16}
              size="small"
              text="Insurance Profile"
              type="secondary"
              className="rounded-md border-0"
              onClick={() =>
                setViewDocument({
                  load: true,
                  handle: insurance,
                  type: 'insurance',
                  title: 'Insurance Profile'
                })
              }
            />
            <Button
              icon="new-shield-tick"
              iconSize={16}
              size="small"
              text="HIPAA Form"
              type="secondary"
              className="rounded-md border-0"
              onClick={() =>
                setViewDocument({ load: true, handle: hipaa, type: 'hipaa', title: 'HIPAA Form' })
              }
            />
          </>
        ) : (
          <Popover isFixed options={options} />
        )}

        {ia(selectedItems) && (
          <Button
            onClick={() => setDeleteConfirmation(true)}
            size="small"
            icon="trash"
            iconColor="white"
            text="Delete"
            color="danger"
          />
        )}

        <DisplayButton />
        <Filter
          menuPortalTarget={document.body}
          category="document-filters"
          filters={filters}
          defaultFilters={getDocumentFilters(practitioners, patient)}
          setFilters={setFilters}
          isAdmin={true}
        />
        <Button
          icon="new-add-square"
          iconSize={16}
          iconColor="white"
          size="small"
          type="primary"
          text="Add Document"
          onClick={openFilestackPicker}
        />

        <Button
          data-qa="send-patient-form-btn"
          icon="new-send-bulk"
          iconColor="white"
          size="small"
          type="primary"
          outlined
          text="Send Patient Forms"
          onClick={() => setShowInstantPacket(true)}
        />
      </div>
    </Header>
  );
};

export default DocumentsHeader;
