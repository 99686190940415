import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TableCounter from 'components/shared/Table/TableCounter';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { useServices } from 'lib/hooks/queries/services/useServices';
import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { getPracticeDefaultFilters, GRID_OPTIONS } from '../lib/helper';
import CreateClinicalNoteButton from './CreateClinicalNoteButton';
import { useNavigate } from 'react-router-dom';
import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';
import { MyScribeAIContextProvider } from 'lib/context/MyScribeAI/MyScribeAIContextProvider';

const category = 'patient-clinical-notes';

const ClinicalNotesTable = ({ patientId }) => {
  const practice = useRecoilValue(currentPractice);
  const navigate = useNavigate();

  const { limit, page, setPage, sort, filters, setFilters } = useTableContext();
  const { data: providers = {} } = useProviders({});
  const { data, isLoading, isFetching } = useClinicalNote({
    params: {
      patientId,
      forPractice: true,
      pagination: true,
      withDocumentSign: true,
      withPatient: true,
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });
  const { data: procedures } = useServices({});

  const { data: icdList } = useICD10Codes({ params: { withMostUserSearchClicks: true } });

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.provider.options = providerOptions;

  const serviceOptions = useMemo(() => {
    if (procedures?.services?.length > 0) {
      return procedures.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [procedures]);
  filters.cpt.options = serviceOptions;

  const icdOptions = useMemo(() => {
    if (icdList?.icd10?.length > 0) {
      return icdList?.icd10?.map((i) => ({
        label: i?.name,
        value: i?.code
      }));
    }
    return [];
  }, [icdList]);

  filters.icd.options = icdOptions;

  const clinicalNotes = data?.clinicalNote || [];
  const count = data?.count?.count || 0;

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'actions' || e?.column?.getColId() === 'checkbox') return;
    if (['actions', '0'].includes(e?.column?.colId) || e?.column?.colId === '1') return;
    navigate(
      `/portal/charts/${e?.data?.appointment.user_id}/clinical-notes/${
        e?.data?.appointment.id ?? e?.appointment?.id
      }`
    );
  };

  return (
    <>
      <Header>
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={getPracticeDefaultFilters(practice)}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
          <CreateClinicalNoteButton patientId={patientId} />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <ClinicalNoteContextProvider outsideClinicalNoteContext={true}>
              <Chart3dContextProvider>
                <MyScribeAIContextProvider>
                  <AmbientListeningContextProvider>
                    <AGTable
                      data={clinicalNotes}
                      rowSelection="multiple"
                      onCellClicked={onCellClicked}
                      suppressRowClickSelection={true}
                      customClassName="ag-grid-interactive"
                      loading={isLoading || isFetching}
                      gridOptions={GRID_OPTIONS}
                      autoSizeStrategy={{
                        type: 'fitGridWidth'
                      }}
                    />
                  </AmbientListeningContextProvider>
                </MyScribeAIContextProvider>
              </Chart3dContextProvider>
            </ClinicalNoteContextProvider>
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
    </>
  );
};

export default ClinicalNotesTable;
