import React from 'react';
import { Popover } from '@headlessui/react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import cs from 'classnames';

const ClinicalNotePopover = ({
  lastIndex,
  index,
  setSelectedClinicalNote,
  clinicalNote,
  setShowExportModal
}) => {
  const { patient } = useOutletContext();
  const navigate = useNavigate();

  const onExportClick = ({ e, showOnlyExport = true }) => {
    if (!clinicalNote.id) {
      showAlert({
        title: `Clinical Note ${showOnlyExport ? 'Export' : 'Preview'} Unavailable`,
        message: `Clinical note not initiated; ${showOnlyExport ? 'export' : 'preview'} unavailable.`,
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    setSelectedClinicalNote(clinicalNote);
    setShowExportModal({ open: true, showOnlyExport });
    e?.stopPropagation();
  };

  const onSuperbillClick = (e) => {
    if (!clinicalNote.id || !clinicalNote?.superbill?.id) {
      showAlert({
        title: 'Superbill Unavailable',
        message: 'Clinical note not initiated; superbill unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }
    navigate(`/portal/charts/${patient.id}/billing/superbill/${clinicalNote?.superbill?.id}`);
    e?.stopPropagation();
  };

  const onClaimClick = (e) => {
    if (!clinicalNote.id) {
      showAlert({
        title: 'Claim Unavailable',
        message: 'Clinical note not initiated; claim unavailable.',
        color: 'warning'
      });
      e?.stopPropagation();
      return;
    }

    navigate(
      claimType(clinicalNote?.appointment?.claim?.state, clinicalNote?.appointment?.claim?.id)
    );
    e?.stopPropagation();
  };

  const claimType = (claimType, claimId) => {
    let type = '';
    switch (claimType) {
      case 'complete':
        type = 'accepted';
        break;
      default:
        type = claimType;
    }

    return `/portal/beyond-billing/claims/${type}/${claimId}`;
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <div>
          <Popover.Button
            data-qa={`clinical-note-button-popover-${index}`}
            className={cs(
              'flex h-[34px] w-[34px] items-center justify-center rounded-lg hover:bg-neutral-100',
              open && '!bg-primary-700 transition-all'
            )}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </Popover.Button>

          <Popover.Panel
            className={cs(
              `absolute right-0 z-[999999999999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]`,
              lastIndex ? '!-top-[55px]' : 'top-10'
            )}>
            <div
              data-qa="preview-clinical-note-btn"
              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
              onClick={(e) => {
                onExportClick({ e, showOnlyExport: false });
                close();
              }}>
              <Icon icon="new-eye" color="primary" />
              <div className="ml-1 text-sm text-primary-900">
                {clinicalNote?.state == 'finalized' ? 'View finalized note' : 'View draft'}
              </div>
            </div>
            <div
              data-qa="export-clinical-note-btn"
              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
              onClick={(e) => {
                onExportClick({ e, showOnlyExport: true });
                close();
              }}>
              <Icon icon="new-export-v2" color="primary" />
              <div className="ml-1 text-sm text-primary-900">Export</div>
            </div>
            <div
              data-qa="export-clinical-note-btn"
              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
              onClick={(e) => {
                onSuperbillClick(e);
                close();
              }}>
              <Icon icon="new-superbill" color="primary" />
              <div className="ml-1 text-sm text-primary-900">View Superbill</div>
            </div>
            <div
              data-qa="export-clinical-note-btn"
              className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
              onClick={(e) => {
                onClaimClick(e);
                close();
              }}>
              <Icon icon="new-claim" color="primary" />
              <div className="ml-1 text-sm text-primary-900">View Claim</div>
            </div>
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default ClinicalNotePopover;
