import cs from 'classnames';

import useAutosizeElement from 'lib/hooks/useAutosizeElement';

import TextEditor from '../TextEditor/TextEditor';
import Header from '../shared/Header';
import Hint from '../shared/Hint';
import MaximumChars from '../shared/MaximumChars';

/**
 * @param {import('./lib/propTypes').TextareaProps} props
 */

const Textarea = (props) => {
  const {
    label,
    placeholder,
    value,
    isEditor = false,
    id = label || 'textareaId',
    name = 'textareaName',
    className,
    width,
    disabled = false,
    error,
    hint,
    hintIcon,
    forwardedRef,
    onChange = () => {},
    textareaClassName,
    maxLength,
    resizeX = false,
    resizeY = false,
    ...rest
  } = props;

  useAutosizeElement(forwardedRef, value);

  if (isEditor) return <TextEditor {...props} />;

  return (
    <div className={cs('relative', className)} style={{ width }}>
      <Header {...props} id={id} />
      <div className="relative">
        <textarea
          className={cs(
            'm-0 flex h-10 w-full items-center justify-between gap-x-3 overflow-hidden rounded-md border border-neutral-200 px-3 py-[10px] text-sm leading-5 focus-within:outline focus-within:!outline-2',
            error
              ? '!border-danger-300 caret-danger-500 focus-within:!outline-danger-100'
              : 'caret-primary-500 focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
            disabled
              ? 'cursor-not-allowed bg-neutral-50 text-neutral-400 placeholder:text-neutral-300'
              : 'bg-white text-neutral-900 placeholder:text-neutral-500',
            !resizeX && !resizeY && '!resize-none',
            resizeX && 'resize-x',
            resizeY && 'resize-y',
            textareaClassName
          )}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          name={name}
          disabled={disabled}
          ref={forwardedRef}
          maxLength={maxLength}
          {...rest}
        />
        <MaximumChars length={value?.length} maxLength={maxLength} disabled={disabled} />
      </div>
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && <p className="pt-2 text-sm text-danger-500">{error}</p>}
    </div>
  );
};

export default Textarea;
