import { requestApi } from './Api';

export const enhanceNarrative = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/enhance_narrative',
    params
  });

export const getPatientForms = (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/myscribe_ai/patient/get_forms',
    params
  });
