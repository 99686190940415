import React from 'react';

import Button from 'components/shared/Buttons/Button';

import SOAPFormsAI from '../../components/forms/SOAP/SOAPFormsAI';

import MacrosTable from './components/macros/MacrosTable';
import MacrosHeader from './components/macros/components/MacrosHeader';
import PastNotes from './components/pastNotes/PastNotesTable';
import PatientFormsTable from './components/patientForms/PatientFormsTable';
import PatientHistoryTable from './components/patientHistory/components/PatientHistoryTable';
import TreatmentPlanHeader from './components/treatmentPlan/TreatmentPlanHeader';
import TreatmentPlanTable from './components/treatmentPlan/TreatmentPlanTable';
import VitalsHeader from './components/vitals/VitalsHeader';
import VitalsTable from './components/vitals/VitalsTable';

export const componentButtons = [
  {
    id: 'vitals',
    text: 'Vitals',
    onClick: () => {},
    icon: 'new-heart-vitals',
    activeIcon: 'half-white-heart',
    selected: false,
    forms: SOAPFormsAI,
    partialText: 'Partial vitals added',
    includedInHashtags: false,
    content: ({ breadcrumb, isView = false }) => (
      <VitalsTable breadcrumb={breadcrumb} isView={isView} />
    ),
    headerButton: ({ breadcrumb, isView = false }) => (
      <VitalsHeader breadcrumb={breadcrumb} isView={isView} />
    )
  },
  {
    id: 'cpt-codes',
    text: 'CPT® Codes',
    onClick: () => {},
    icon: 'new-cpt-codes',
    activeIcon: 'new-note-tick',
    selected: false,
    forms: SOAPFormsAI,
    includedInHashtags: false
  },
  {
    id: 'diagnosis-codes',
    text: 'Diagnosis Codes',
    onClick: () => {},
    icon: 'new-diagnosis-codes',
    activeIcon: 'new-note-tick',
    selected: false,
    forms: SOAPFormsAI,
    includedInHashtags: false
  },
  {
    id: 'macros',
    text: 'Macros',
    onClick: () => {},
    partialText: 'Partial macros added',
    icon: 'new-macro',
    activeIcon: 'new-calendar-note',
    content: ({ breadcrumb, isView = false }) => (
      <MacrosTable breadcrumb={breadcrumb} isView={isView} />
    ),
    headerButton: ({ breadcrumb, isView = false }) => (
      <MacrosHeader breadcrumb={breadcrumb} isView={isView} />
    ),
    selected: false,
    includedInHashtags: false
  },
  {
    id: 'past_notes',
    text: 'Past Notes',
    partialText: 'Partial past notes added',
    onClick: () => {},
    icon: 'new-past-notes',
    activeIcon: 'new-note-2',
    selected: false,
    content: ({ breadcrumb, isView = false }) => (
      <PastNotes breadcrumb={breadcrumb} isView={isView} />
    ),
    includedInHashtags: false
  },
  {
    id: 'treatment_plan',
    text: 'Treatment Plan',
    onClick: () => {},
    icon: 'new-treatment-plan',
    activeIcon: 'new-task',
    selected: false,
    content: ({ breadcrumb, isView = false }) => (
      <TreatmentPlanTable breadcrumb={breadcrumb} isView={isView} />
    ),
    includedInHashtags: false,
    headerButton: ({ breadcrumb, isView = false }) => (
      <TreatmentPlanHeader breadcrumb={breadcrumb} isView={isView} />
    )
  },
  {
    id: 'patient_forms',
    text: 'Patient Forms',
    partialText: 'Partial patient forms added',
    onClick: () => {},
    icon: 'new-note-text',
    activeIcon: 'new-note-text',
    selected: false,
    content: ({ breadcrumb, isView = false }) => (
      <PatientFormsTable breadcrumb={breadcrumb} isView={isView} />
    ),
    includedInHashtags: false
  },
  {
    id: 'patient_history',
    text: 'Patient History',
    partialText: 'Partial patient history added',
    onClick: () => {},
    icon: 'new-patient-history',
    activeIcon: 'history-filled',
    selected: false,
    content: ({ breadcrumb, isView = false }) => (
      <PatientHistoryTable breadcrumb={breadcrumb} isView={isView} />
    ),
    includedInHashtags: false
  }
];

export const FADE_LIST = ['Preserve Your Time', 'Protect Your Data', 'Perpetuate Your Practice'];

export const popoverOptions = [{ label: '3D Chart', onClick: () => {}, icon: 'new-calendar-note' }];

export const headerButtons = [
  { id: 1, component: <Button icon="new-notev1" text="New Note" outlined size="small" /> }
];
