import { useField } from 'formik';

import DatePicker from 'components/shared/DatePicker/DatePicker';

const DrawDateTime = ({ className }) => {
  const [field, meta, { setValue }] = useField({
    name: 'drawDateTime',
    id: 'draw-date-time'
  });

  return (
    <DatePicker
      {...field}
      error={meta.touched && meta.error}
      isClearable={false}
      onChange={(date) => setValue(date)}
      parentClassName={className}
      isTime
      required
      iconIsRight
      isHint={false}
      data-qa="draw-date-time"
      label="Draw date and time"
    />
  );
};

export default DrawDateTime;
