import React from 'react';

import cs from 'classnames';

import { formatLargeNumber } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';

const CustomPagination = ({
  page,
  perPage,
  pageCountOverride,
  totalItems,
  onPageChange = (_page) => {}
}) => {
  const totalPages = Math.ceil(pageCountOverride || totalItems / perPage);

  const isDisabled = totalPages === 0 || page === totalPages;
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages;

  const checkIfPageIsLargerThanTotal = () => {
    return page > totalPages ? totalPages : page;
  };

  page = checkIfPageIsLargerThanTotal(); // Found a bug where we can filter if we are based on a page where the items fetched cannot reach that fill out of pages
  // therefore we return the page to it's more extend point i.e TotalPages | CONSULT BEFORE REMOVING!

  const handlePrevious = () => {
    if (page > 1) {
      onPageChange(page - 1);
    }
  };

  const handleNext = () => {
    if (page < totalPages) {
      onPageChange(page + 1);
    }
  };

  const handleFirst = () => {
    onPageChange(1);
  };

  const handleLast = () => {
    onPageChange(totalPages);
  };

  return (
    <div className="flex h-full items-center justify-center gap-3">
      <Icon
        icon="new-first"
        onClick={handleFirst}
        disabled={isFirstPage}
        className={cs({
          'cursor-not-allowed opacity-50': isFirstPage,
          'cursor-pointer': !isFirstPage
        })}
      />
      <Icon
        icon="new-expanded"
        onClick={handlePrevious}
        disabled={isFirstPage}
        className={cs({
          'cursor-not-allowed opacity-50': isFirstPage,
          'cursor-pointer': !isFirstPage
        })}
      />
      <span className="text-xs leading-[14px] text-neutral-900">
        Page <span className="font-semibold">{formatLargeNumber(page)}</span> of{' '}
        <span className="font-semibold">{formatLargeNumber(totalPages)}</span>
      </span>
      <Icon
        icon="new-contracted"
        className={cs({
          'cursor-not-allowed opacity-50': isDisabled,
          'cursor-pointer': !isLastPage
        })}
        onClick={handleNext}
        disabled={isDisabled}
      />
      <Icon
        icon="new-last"
        className={cs({
          'cursor-not-allowed opacity-50': isDisabled,
          'cursor-pointer': !isLastPage
        })}
        onClick={handleLast}
        disabled={isDisabled}
      />
    </div>
  );
};

export default CustomPagination;
