import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import { createEob, updateEob } from 'api/ERA';

import { keyChanges } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import EOBForm from './components/EOBForm';
import EOBPayment from './components/EOBPayment';
import { NewEOBValidationSchema } from './lib/schemas';

const NewEOB = ({ isOpen, eob, showInfoInRecordings = false, handleClose }) => {
  const [step, setStep] = useState(1); // State to track current step

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      payerid: eob?.payer_id ?? '',
      check_number: eob?.check_number ?? '',
      payment_method: eob?.payment_method ?? '',
      total_paid: eob?.total_paid ?? 0,
      paid_date: eob?.paid_date ?? new Date(),
      handle: eob?.handle ?? ''
    },
    validationSchema: NewEOBValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const newEob = {
        payerid: values.payerid,
        check_number: values.check_number,
        payment_method: values.payment_method,
        total_paid: values.total_paid,
        paid_date: values.paid_date,
        handle: values.handle
      };
      if (eob) {
        const updatedEob = keyChanges(eob, newEob);

        const allocated_amount = eob?.total_paid - eob?.unallocated_amount_cents;
        if (values?.total_paid < allocated_amount) {
          showAlert({
            title: 'EOB Update',
            message: 'Total paid cannot be less than the allocated amount.',
            color: 'danger'
          });
          return;
        }

        const unallocated_amount_cents = values?.total_paid - allocated_amount;

        const changes = {
          eob_id: eob?.id,
          fields: {
            ...updatedEob,
            total_paid: values.total_paid,
            unallocated_amount_cents
          }
        };
        mutateUpdateEOB.mutate(changes);
      } else {
        mutateCreateEOB.mutate(newEob);
      }

      resetForm();
    }
  });

  const values = formik?.values;
  const isVPayment = values?.payment_method === 'vPayment';

  const mutateUpdateEOB = useMutation({
    mutationFn: (data) => updateEob(navigate, data),
    onMutate: () => {
      formik.setSubmitting(true);
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
    onSuccess: (data) => {
      handleClose();
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries({ queryKey: ['eobs'], refetchType: 'all' });
          showAlert({
            title: 'EOB updated successfully!'
          });
          break;

        default:
          break;
      }
    }
  });

  const mutateCreateEOB = useMutation({
    mutationFn: (data) => createEob(navigate, data),
    onMutate: () => {
      formik.setSubmitting(true);
    },
    onSettled: () => {
      formik.setSubmitting(false);
    },
    onSuccess: (data) => {
      handleClose(false);
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries({ queryKey: ['eobs'], refetchType: 'all' });
          showAlert({
            title: 'EOB created successfully!',
            buttons: [
              {
                text: 'View EOB',
                onClick: () => navigate(`/portal/beyond-billing/era-eob/eob/${data?.eob?.id}`)
              }
            ]
          });
          break;

        default:
          break;
      }
    }
  });

  const onProceed = () => {
    setStep(2);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title={eob ? 'Update EOB' : 'New EOB'}
      slideFromRight
      footer={
        <div
          className="fixed bottom-0 left-0 right-0 flex w-full justify-between border-x-0 border-b-0 border-t border-solid border-neutral-100 bg-white !p-4"
          // eslint-disable-next-line react/no-unknown-property
          data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
          <Button
            outlined
            text={step === 1 ? 'Cancel' : 'Back'}
            color="neutral"
            onClick={() => {
              if (step === 2) {
                setStep(1);
                return;
              }
              handleClose();
            }}
          />

          {step === 1 && isVPayment && !eob ? (
            <Button text="Proceed to Pay" onClick={onProceed} />
          ) : (
            <Button text={eob ? 'Update' : 'Create'} onClick={() => formik.submitForm()} />
          )}
        </div>
      }>
      {isVPayment && step !== 1 ? (
        <EOBPayment
          formik={formik}
          onProceed={onProceed}
          showInfoInRecordings={showInfoInRecordings}
          handleClose={handleClose}
        />
      ) : (
        <EOBForm eob={eob} formik={formik} showInfoInRecordings={showInfoInRecordings} />
      )}
    </Modal>
  );
};

export default NewEOB;
