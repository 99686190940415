import { useMemo } from 'react';

import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import { ia } from 'lib/helpers/utility';
import { useRoleList } from 'lib/hooks/queries/roles/useRoleList';
import React from 'react';

const AdminForm = ({ formik }) => {
  const { data: rolesData } = useRoleList({
    params: {
      limit: 30,
      offset: 0,
      forAdmin: true
    }
  });

  const roleOptions = useMemo(() => {
    if (ia(rolesData?.role_list)) {
      return rolesData.role_list?.map((role) => ({ label: role.name, value: role.id }));
    }
    return [];
  }, [rolesData]);

  return (
    <form onSubmit={formik.handleSubmit} className="grid gap-y-4">
      <Input
        id="f_name"
        name="f_name"
        label="First Name"
        type="text"
        placeholder="First Name"
        value={formik?.values?.f_name}
        error={formik.touched.f_name && formik?.errors?.f_name}
        onChange={formik?.handleChange}
      />
      <Input
        id="l_name"
        name="l_name"
        label="Last Name"
        type="text"
        placeholder="Last Name"
        value={formik?.values?.l_name}
        error={formik.touched.l_name && formik?.errors?.l_name}
        onChange={formik?.handleChange}
      />
      <Input
        id="phone"
        name="phone"
        label="Phone Number"
        type="text"
        placeholder="Phone e.g (800) 555-0199"
        value={formik?.values?.phone}
        error={formik.touched.phone && formik?.errors?.phone}
        onChange={formik?.handleChange}
      />
      <Input
        id="email"
        name="email"
        label="Email"
        type="email"
        placeholder="Write email"
        value={formik?.values?.email}
        error={formik.touched.email && formik?.errors?.email}
        onChange={formik?.handleChange}
      />
      <Select
        options={roleOptions}
        label="Role"
        placeholder="Type and select role"
        value={formik?.values?.role}
        onChange={(e) => formik.setFieldValue('role', e || null)}
        error={formik.touched.role && formik.errors?.role}
      />
    </form>
  );
};

export default AdminForm;
