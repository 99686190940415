export const HPMacro = [
  'present_illness',
  'other_complaints',
  'hpi',
  'ros',
  'exam',
  'test',
  'assessment',
  'plan',
  'advancedHP',
  'notes'
];

export const SOAPMacro = [
  'subjective',
  'objective',
  'assessment',
  'plan',
  'cpt_codes',
  'icd_10_codes',
  'advancedSOAP',
  'notes'
];

export const defaultMacroFilters = {
  macroType: null,
  searchTerm: ''
};
