import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { deleteRole } from 'api/Roles';

export const useDeleteRole = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useMutation(['deleteRole', ...dependencies], () => deleteRole(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
