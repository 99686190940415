import { useQueryClient } from '@tanstack/react-query';
import { transformData } from 'components/shared/Forms/Custom/lib/aggregatedDataHelper';
import { createVaccineNarrativeV2 } from 'components/shared/Forms/Custom/lib/vaccineNarrativeHelper';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, isEmpty, stripHtmlTags } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import React from 'react';
import { useParams } from 'react-router-dom';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import VaccinesSimpleTable from './VaccinesSimpleTable';

const Vaccines = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData, clinicalNote } = customClinicalNote ?? useClinicalNoteContext();
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  const { id: patientId } = useParams();
  const patient = patientId ?? String(clinicalNote?.user_id);

  const queryClient = useQueryClient();
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'vaccines',
        patientId: patient
      },
      dependencies: [patient, 'vaccines'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          queryClient.setQueryData(['patientAggregateNarrative', patient, 'vaccines'], () => ({
            data: {
              narrative: {
                narrative: createVaccineNarrativeV2(
                  transformData({
                    data: overviewData?.vaccines,
                    shouldMap: false
                  })
                ),
                synced: true
              }
            }
          }));
        }
      }
    });

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (customClinicalNote && !ia(overviewData?.vaccines)) {
    return null;
  }

  const tableData = overviewData?.vaccines?.reduce((prev, curr) => {
    return [...prev, ...(curr?.response?.row || [])];
  }, []);

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Vaccines</h3>

      {isTemplateChecked ? <VaccinesSimpleTable data={tableData} /> : null}
      {isNarrativeChecked && !!ia(overviewData?.vaccines) && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <SanitizeComponent
            value={
              isEmpty(stripHtmlTags(patientNarrative?.narrative).trim())
                ? 'No known vaccines'
                : patientNarrative?.narrative
            }
          />
        </div>
      )}
    </div>
  );
};

export default Vaccines;
