import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import { currentPractice } from 'components/practice/practiceState';

import SuperbillModal from '../../SuperBillPage/SuperbillModal';

import SuperbillTable from './components/SuperbillTable';
import { defaultFilters, superbillTableColumns } from './lib';

export default function Superbills({ patient, superbill_id }) {
  const [superbillId, setSuperbillId] = useState(superbill_id);

  const { superbill } = useParams();
  const practice = useRecoilValue(currentPractice);
  if (superbillId)
    return (
      <SuperbillModal
        patientId={patient?.id}
        superbill_id={superbillId}
        setSuperbillId={setSuperbillId}
        handleClose={() => setSuperbillId(null)}
      />
    );

  if (superbill) return <Outlet />;

  const cols = superbillTableColumns(practice.timezone);

  return (
    <TableContextProvider cols={cols} pagination name="superbill" defaultFilters={defaultFilters}>
      <SuperbillTable patient={patient} setSuperbillId={superbill_id && setSuperbillId} />
    </TableContextProvider>
  );
}
