import React from 'react';
import cs from 'classnames';
import { useFormikContext } from 'formik';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import Input from 'components/shared/Input/Input';
import { ia } from 'lib/helpers/utility';
import { appointmentLocations } from 'constants';
import useCopy from 'lib/hooks/useCopy';
import { currentPractice } from 'components/practice/practiceState';
import { useRecoilValue } from 'recoil';

const Location = ({ practitioners, isPined, className }) => {
  const currPractice = useRecoilValue(currentPractice);
  const { values, setFieldValue, handleChange } = useFormikContext();
  const { copyText, copyToClipboard } = useCopy();
  const isAptDisabled = values.appointment_disabled;

  const handleChangeVirtualLink = () => {
    const patientLink = values.patient?.practicePatientLink?.virtual_link;

    const practitionersLink = practitioners.find(
      (practitioner) => practitioner?.value === values?.practitioner_id
    )?.virtual_link;

    const practice_virtual_link = currPractice?.display_settings?.appointment?.virtual_link;
    setFieldValue('virtual_link', patientLink || practitionersLink || practice_virtual_link);
  };

  return (
    <div className={cs('grid grow', isPined ? 'gap-3' : 'gap-6', className)}>
      <div>
        <label className="text-sm font-500">Location</label>
        <div className="mt-3 flex gap-4">
          {ia(appointmentLocations) &&
            appointmentLocations.map(({ value, label }, i) => {
              return (
                <RadioButton
                  className="w-auto !pl-[32px] !pr-0"
                  wrapperClassName="!w-auto"
                  key={i}
                  id={value}
                  value={value}
                  label={label}
                  isSelected={value === values.location}
                  onChange={() => {
                    setFieldValue('location', value);
                    if (value === 'virtual') {
                      handleChangeVirtualLink();
                    }
                  }}
                  disabled={isAptDisabled}
                />
              );
            })}
        </div>
      </div>
      {values.location == 'virtual' && (
        <Input
          className="grow"
          label="Meeting link"
          value={values.virtual_link}
          onChange={(e) => {
            handleChange(e);
          }}
          name="virtual_link"
          placeholder="Enter meeting link"
          disabled={isAptDisabled}
          actionButtonIcon="new-copy-v2"
          actionButtonText={copyText}
          actionButtonClick={() => copyToClipboard(values.virtual_link)}
        />
      )}
    </div>
  );
};

export default Location;
