export default function procedureAvailability({
  selectedProcedures,
  newItems,
  procedure,
  packageId,
  invoiceId,
  additionalTimes,
  hasId = false,
  oldProcedures
}) {
  const isProcedureSelected = (item) =>
    item.id === procedure.id &&
    item.invoiceId === invoiceId &&
    (item.packageId === packageId || (!item.packageId && !packageId));

  const isChecked = newItems?.find?.(isProcedureSelected);

  const newSalesCount = isChecked?.count - 1 || 0;

  const oldProceduresCount = oldProcedures?.filter(isProcedureSelected)?.length || 0;
  const selectedProceduresCount = selectedProcedures?.filter(isProcedureSelected)?.length || 0;

  const { quantity, sales_count } = procedure || {};

  let availability = hasId
    ? quantity - sales_count - newSalesCount - selectedProceduresCount + oldProceduresCount
    : quantity - sales_count - newSalesCount - selectedProceduresCount;

  availability += isChecked ? -1 : 0;

  if (isChecked && additionalTimes) {
    availability -= additionalTimes.length * (isChecked.count || 0);
  }

  return `Available: ${availability}/${quantity}`;
}
