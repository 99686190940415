import { Honeybadger } from '@honeybadger-io/react';
import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { requestApi } from 'api/Api';
import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { showAlert } from 'components/shared/Alert/Alert';

const NewAndUpdateOccupation = ({ mode, selectedOccupation, hideModal, showModal }) => {
  const NewOccValidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      name: mode === 'update' ? selectedOccupation?.name : '',
    },
    enableReinitialize: true,
    validationSchema: NewOccValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (mode === 'create') {
          await handleCreate(values);
        } else if (mode === 'update') {
          await handleUpdate(values);
        }
        setSubmitting(false);
        hideModal();
      } catch (error) {
        Honeybadger.notify(`Form submission error: ${error}`);
        setSubmitting(false);
      }
    }
  });

  const handleCreate = async () => {

    try {
      const res = await requestApi({
        url: `/api/occupation/create`,
        params: {
          name: formik.values.name
        },
        navigate
      });

      const { code, occupation, error } = res;

      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          hideModal();
          showAlert({ title: 'Occupation added successfully.', color: 'primary' });
          queryClient.invalidateQueries(['occupations']);
          break;

        case 1:
          showAlert({ title: occupation?.error, color: 'danger' });
          Honeybadger.notify(`occupation error: ${occupation?.error}`);
          break;
        case 2:
          toast.error(error);
          break;

        default:
          Honeybadger.notify(`handleCreate: ${error}`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleCreate: ${error}`);
    }
  };

  const handleUpdate = async () => {
    try {
      const data = await requestApi({
        url: `/api/occupation/update`,
        params: {
          name: formik.values.name,
          id: selectedOccupation?.id
        },
        navigate
      });
      const { code, occupation } = data || {};
      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          hideModal();
          showAlert({ title: 'Occupation updated successfully', color: 'success' });
          queryClient.invalidateQueries(['occupations']);
          break;

        case 1:
          toast.error(occupation?.error);
          break;

        default:
          Honeybadger.notify(
            `handleUpdate: There's been an unexpected error, please try again later.`
          );
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleUpdate: ${error}`);
    }
  };

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={`${mode === 'update' ? 'Update' : 'New'} Occupation`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={hideModal} data-qa="update-btn" />

          {mode === 'update' ? (
            <Button text="Update" onClick={handleUpdate} />
          ) : (
            <Button text="Create" onClick={handleCreate} data-qa="create-btn" />
          )}
        </div>
      }>
      <div className="grid gap-y-4">
        <Input
          id="name"
          name="name"
          label="Name"
          value={formik?.values?.name}
          error={formik?.errors?.name}
          onChange={formik && formik?.handleChange}
        />
      </div>
    </Modal>
  );
};

export default NewAndUpdateOccupation;
