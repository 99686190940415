import React from 'react';
import Logs from 'components/shared/Logs';
import formatDate from 'lib/helpers/utility';
import { useNavigate } from 'react-router-dom';
import searchEntity from './lib/searchEntity';

const AdminUserLogs = () => {
  const navigate = useNavigate();
  const defaultLogFilters = () => ({
    order: { label: 'Newest', value: 'desc' },
    date: {
      values: {
        startDate: null,
        endDate: null
      },
      type: 'date-range',
      title: 'Date',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    },
    id: {
      type: 'search',
      values: '',
      title: 'ID'
    },
    url: {
      type: 'search',
      values: '',
      title: 'URL'
    },
    data: {
      type: 'search',
      values: '',
      title: 'Data'
    },
    practices: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'Practices',
      loadOptions: (searchTerm) => searchEntity('practice/get', searchTerm, navigate, 'practices')
    },
    staff: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'Staff members',
      loadOptions: (searchTerm) =>
        searchEntity('practitioner/get_multiple', searchTerm, navigate, 'practitioner')
    }
  });

  const filters = defaultLogFilters();
  return (
    <div className="flex h-full flex-col">
      <Logs specificAdmin={true} defaultLogsFilters={filters} />
    </div>
  );
};

export default AdminUserLogs;
