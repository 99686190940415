import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPatientGuarantors } from 'api/PatientGuarantor';

export const useGuarantors = ({ params, options }) => {
  const navigate = useNavigate();
  const { userId } = params;

  return useQuery(['guarantors', userId], () => getPatientGuarantors(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
