import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { appendPracticeToOrganization, getPracticeOwners } from 'api/Practice';
import { iaRa } from 'lib/helpers/utility';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import Input from 'components/shared/Input/Input';
import Icon from 'components/shared/Icon/Icon';
import UserItem from '../UserItem';
import { useOrganizationContext } from 'lib/context/OrganizationContext/OrganizationContext';
import DataSelector from './DataSelector';

const SelectOwnerModal = forwardRef(({ setAddOwnerModalVisible }, ref) => {
  const [filters, setFilters] = useState({ searchTerm: '', search: '', page: 1 });
  const [organizations, setOrganizations] = useState([]);
  const { practice } = useOutletContext();
  const {
    selectedOrganization,
    setSelectedOrganization,
    selectedOwner,
    setSelectedOwner,
    state,
    selectedLocation,
    importAll
  } = useOrganizationContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const fetchOwners = async () => {
    const data = await getPracticeOwners(navigate, filters);
    setOrganizations(data?.owners);
  };
  useEffect(() => {
    fetchOwners();
  }, [filters?.page, filters?.searchTerm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters({ ...filters, searchTerm: filters?.search });
    }, 500);

    return () => clearTimeout(timer);
  }, [filters?.search]);

  useImperativeHandle(ref, () => ({
    handleAppendPracticeToOrganization
  }));

  const handleAppendPracticeToOrganization = async () => {
    if (!selectedOrganization) {
      showAlert({
        color: 'danger',
        message: 'Please select an owner to append the practice to.'
      });
      return;
    }
    const params = {
      ownerPracticeId: selectedOrganization.id,
      practiceId: practice.id,
      userId: selectedOwner?.user?.id,
      selectedLocation: selectedLocation?.value
    };

    if (importAll) {
      params.importAll = true;
    } else {
      params.importAll = false;
      params.data = state;
    }
    const data = await appendPracticeToOrganization(navigate, params);
    if (data.code === 0) {
      if (data.duplicatePatients) {
        showAlert({
          message:
            'Practice appended to organization successfully, but some patients already exist when importing data.',
          color: 'warning'
        });
      } else if (data?.veradigmError) {
        showAlert({
          message: 'Data could not be imported due to an error with Veradigm',
          color: 'danger'
        });
      } else {
        showAlert({
          color: 'success',
          message: 'Practice appended to organization successfully'
        });
      }
    }
    setAddOwnerModalVisible(false);
    queryClient.invalidateQueries('practice');
  };

  const handleSelectOrganization = (organization) => {
    if (selectedOrganization?.id === organization.id) {
      setSelectedOrganization();
      setSelectedOwner();
      return;
    }
    setSelectedOrganization(organization);
    if (organization.owners.length === 1) {
      setSelectedOwner(organization.owners[0]);
    } else {
      setSelectedOwner(null);
    }
  };

  return (
    <>
      {!selectedOrganization && !selectedOwner ? (
        <div className="flex flex-1 flex-col gap-[14px] overflow-scroll bg-neutral-50 px-[24px] py-4">
          <Input
            id="search"
            type="string"
            placeholder="Search Owner..."
            className="!mt-4"
            value={filters?.search}
            onChange={(e) => setFilters({ ...filters, search: e.target.value, page: 1 })}
            rightText={
              filters?.search && (
                <Icon
                  icon="new-close"
                  onClick={() => setFilters({ ...filters, search: '', searchTerm: '', page: 1 })}
                />
              )
            }
          />
          {iaRa(organizations).map((organization) => {
            const isChecked = organization.id === selectedOrganization?.id;
            const owners = organization.owners;
            return (
              <>
                <UserItem
                  key={organizations.id}
                  {...{
                    isChecked,
                    item: organization,
                    setSelectedUser: handleSelectOrganization,
                    f_name: organization.name
                  }}
                />

                {isChecked && owners.length > 1 && (
                  <div className="mx-10">
                    <label htmlFor="">Select organization owner user</label>
                    {organization.owners.map((owner) => {
                      const isUserSelected = selectedOwner?.user?.id === owner.user.id;

                      return (
                        <UserItem
                          key={owner.user.id}
                          {...{
                            isChecked: isUserSelected,
                            item: owner,
                            setSelectedUser: setSelectedOwner,
                            f_name: owner.user.f_name,
                            l_name: owner.user.l_name
                          }}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            );
          })}
        </div>
      ) : (
        <DataSelector />
      )}
    </>
  );
});

export default SelectOwnerModal;
