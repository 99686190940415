import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getAppointments } from 'api/Appointment';

export const useAppointments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  const { defaultDate, withEvents, practitionerPractice, rangeDate } = params;

  return useQuery(
    ['appointments', defaultDate, withEvents, practitionerPractice, rangeDate, ...dependencies],
    () => getAppointments({ ...params, navigate }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
