import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';

import { ia, optionify } from 'lib/helpers/utility';

import handleNdcSearch from 'components/practice/settings/Services/lib/handleNdcSearch';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';

import { ndcSearchOptions } from '../lib/helpers';

const AddNdcSearchModal = ({
  showCreateConfirm = false,
  setShowCreateConfirm = () => {},
  handleAddNewNdcCodes = () => {}
}) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      searchType: 'package_ndc',
      searchTerm: '',
      newNdcCodes: []
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      handleAddNewNdcCodes(values.newNdcCodes);
      setShowCreateConfirm(false);
    }
  });

  return (
    <Confirm
      handleOpen={showCreateConfirm}
      handleClose={() => setShowCreateConfirm(false)}
      handleContinue={() => formik?.submitForm()}
      disabled={!ia(formik?.values?.newNdcCodes)}
      title="Search NDC Code"
      primaryBtnTxt="Add NDC Code"
      secondaryBtnTxt="Cancel"
      icon="new-search-blue"
      loading={false}>
      <p className="whitespace-pre-line text-sm text-neutral-600">
        You can start by selecting the search type before searching
      </p>
      <Select
        label="Search Type"
        placeholder="Select search type"
        parentClassName="my-5"
        inputId="searchType"
        data-qa="ndc-search-type"
        options={ndcSearchOptions}
        value={
          formik?.values?.searchType
            ? ndcSearchOptions.find((item) => item?.value === formik?.values?.searchType)
            : null
        }
        error={formik?.errors?.searchType}
        isClearable={true}
        menuPortalTarget={document.body}
        onChange={(e) => {
          formik.setFieldValue('searchType', e?.value);
        }}
      />
      <Select
        parentClassName="my-5"
        label="NDC Code"
        value={ia(formik.values?.newNdcCodes) ? optionify(formik.values?.newNdcCodes) : null}
        isAsync
        cacheOptions
        menuPortalTarget={document.body}
        placeholder="Start typing the ndc code or other categories."
        isClearable={false}
        noOptionMessage={() => 'Start typing the ndc code or other categories.'}
        onChange={(e) => {
          formik.setFieldValue(
            'newNdcCodes',
            e?.map((item) => item?.value)
          );
        }}
        loadOptions={(searchTerm) =>
          handleNdcSearch({ searchTerm, navigate, searchType: formik.values?.searchType, formik })
        }
        isMulti={true}
      />
    </Confirm>
  );
};
export default AddNdcSearchModal;
