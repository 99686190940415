import React, { useEffect, createRef } from 'react';
import Icon from '../../../../../../../shared/Icon/Icon';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

import { iaRa } from '../../../../../../../../lib/helpers/utility';

const QuestionsAndAnswers = ({ test, setTests, testIndex }) => {
  const [elRefs, setElRefs] = React.useState([]);

  useEffect(() => {
    setElRefs((elRefs) =>
      Array(test?.qa?.length)
        .fill()
        .map((_, i) => elRefs[i] || [createRef(), createRef()])
    );
  }, [test?.qa]);

  const handleQAChange = ({ qaIndex, key, event }) => {
    setTests((prevState) => {
      return prevState.map((test) => {
        const testId = test?.id;

        if (testId !== testIndex) return test;

        return {
          ...test,
          qa: test.qa.map((qa) => {
            if (qa.id !== qaIndex) return qa;

            return { ...qa, [key]: event.target.value };
          })
        };
      });
    });
  };

  const addQA = () => {
    setTests((prevState) => {
      return prevState.map((test) => {
        const testId = test?.id;

        if (testId !== testIndex) return test;

        const newQA = { id: self.crypto.randomUUID(), question: null, answer: null };

        return { ...test, qa: [newQA, ...test.qa] };
      });
    });
  };

  const removeQA = ({ qaIndex }) => {
    setTests((prevState) => {
      return prevState.map((test) => {
        const testId = test?.id;

        if (testId !== testIndex) return test;

        return { ...test, qa: test.qa.filter((qa) => qa.id !== qaIndex) };
      });
    });
  };

  return (
    <div>
      {iaRa(test?.qa).map((row) => {
        const index = row?.id;

        return (
          <div key={index} className="flex !p-3 bg-white !m-3 rounded-lg">
            <div className="w-full flex flex-col !gap-3">
              <Textarea
                label="Question"
                placeholder="Write question here"
                id={`${index}-question`}
                data-qa={`${index}-question`}
                name={`${index}-question`}
                value={row?.question}
                onChange={(event) =>
                  handleQAChange({
                    key: 'question',
                    event,
                    qaIndex: index
                  })
                }
                transcribing
                forwardedRef={elRefs[index] ? elRefs[index][0] : null}
              />
              <Textarea
                label="Answer"
                placeholder="Write answer here"
                id={`${index}-answer`}
                data-qa={`${index}-answer`}
                name={`${index}-answer`}
                value={row?.answer}
                onChange={(event) =>
                  handleQAChange({
                    key: 'answer',
                    event,
                    qaIndex: index
                  })
                }
                transcribing
                forwardedRef={elRefs[index] ? elRefs[index][1] : null}
              />
            </div>
            <div className="flex flex-col justify-center gap-3">
              <div className="bg-primary-50 flex items-center flex-col justify-center !ml-3 !p-1 rounded-lg border !border-dashed !border-primary-500 !h-9 !w-9">
                <Icon text="Add QA" icon="add-circle" transparent onClick={() => addQA()} />
              </div>
              <div className="bg-primary-50 flex items-center flex-col justify-center !ml-3 !p-1 rounded-lg border !border-dashed !border-primary-500 !h-9 !w-9">
                <Icon
                  icon="trash"
                  disabled={test?.qa?.length === 1}
                  onClick={() => removeQA({ qaIndex: index })}
                />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default QuestionsAndAnswers;
