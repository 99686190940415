import React, { useRef, useState } from 'react';
import cs from 'classnames';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import EmptyProductsPackages from 'components/Patient/sell/EmptyProductsPackages';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import { permissions } from 'components/state';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { formatDateAndTime, ia, mString } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { NewItemCounter, SelectedItems, ServicesFromPackages } from './components';
import { addNewItems, handleAddItems, isProcedureChecked } from './lib';
import { currentPractice } from 'components/practice/practiceState';
import { emptyStateMessages } from './lib/emptyStateMessages';

const AddProceduresProducts = ({ label, list, type, packageList = [], formik }) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};
  const { values, setFieldValue } = formik;
  const selectedItems = [...(values[type] || [])];
  const [newItems, setNewItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const hasPaymentPermission = useRecoilValue(permissions)?.payments?.read;

  const filteredData = list?.filter?.((item) =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  const { timezone, display_settings } = useRecoilValue(currentPractice);

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength,
      dos: display_settings?.claims
        ? formatDateAndTime(values?.starts_at, timezone, false, 'MM/DD/YYYY')
        : null
    });
  };

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  const isServices = type === 'procedures';

  const { title, buttonText, icon, link, createDesc } = emptyStateMessages(isServices);

  if (!ia(list))
    // If practice has no services or products
    return (
      <NotFound
        icon={icon}
        title={title}
        buttonText={buttonText}
        description={createDesc}
        onClick={() => navigate(link)}
        textWrapperClassName={isServices && '!-mt-4'}
        className="!pb-2"
      />
    );

  return (
    <>
      <SelectedItems type={type} list={list} isClinicalNoteLocked={isClinicalNoteLocked} />

      <Popover>
        {({ open, close }) => (
          <div ref={ref}>
            <PopoverButton
              className="absolute -top-7 right-5 cursor-pointer p-0"
              data-qa={`add-btn-type-${type}`}
              onClick={() => {
                open && handleAddItemsFunc();
                !open && setTimeout(() => scrollToElement(), 100);
              }}>
              <div
                className={cs(
                  'flex items-center gap-[6px]',
                  !ia(selectedItems) && '!justify-center pb-3'
                )}>
                <Icon icon="add-circle" size={18} />
                <label className="cursor-pointer text-sm font-500 text-primary-500">
                  {`Add ${label}`}
                </label>
              </div>
            </PopoverButton>
            <PopoverPanel
              className={cs(
                'absolute -bottom-[165px] -right-[1px] z-50 mt-[6px] flex !w-[450px] flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]'
              )}>
              <div className="h-[430px] overflow-y-auto pb-12">
                {isServices && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}

                {ia(list) && (
                  <Input
                    icon="search"
                    rounded="full"
                    value={searchTerm}
                    id={`search-${type}`}
                    data-qa={`search-${label}`}
                    placeholder={`Search ${label}`}
                    rightIcon={searchTerm && 'new-close'}
                    rightIconClick={() => setSearchTerm('')}
                    onChange={(e) => setSearchTerm(e?.target?.value)}
                    className="sticky top-0 z-10 w-full bg-white p-3"
                  />
                )}

                <div className="px-3">
                  <p className="text-xs font-500 leading-5 text-neutral-500">{label}</p>

                  {ia(filteredData) ? (
                    <div className="overflow-x-hidden">
                      {filteredData?.map((item, index) => {
                        const outOfStock = !isServices && item?.quantity === 0;

                        return (
                          <div
                            className={cs(
                              'mr-[2px] mt-[2px] flex !min-h-[34px] items-center justify-between rounded-md p-1 first-of-type:mt-0',
                              !!isProcedureChecked(newItems, item) && 'bg-primary-50'
                            )}
                            key={item?.id}>
                            <Checkbox
                              id={index}
                              disabled={outOfStock}
                              onBlur={formik.handleBlur}
                              label={item?.name + (outOfStock ? ' (Out of stock)' : '')}
                              labelTextColor={cs('leading-4 ', outOfStock && '!text-danger-600')}
                              handleClick={() =>
                                addNewItems({ newItem: item, newItems, setNewItems })
                              }
                              isChecked={
                                isServices
                                  ? !!isProcedureChecked(newItems, item)
                                  : newItems.some((sItem) => sItem.id === item.id)
                              }
                            />

                            <div className="flex items-center !gap-2">
                              <NewItemCounter
                                newItem={item}
                                newItems={newItems}
                                setNewItems={setNewItems}
                              />

                              {type === 'products' && hasPaymentPermission && (
                                <p className="w-14 text-xs text-neutral-500">
                                  {mString(item?.amount_cents)}
                                </p>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ) : type === 'products' ? (
                    <EmptyProductsPackages type="product" />
                  ) : null}
                </div>
              </div>
              <div className="absolute bottom-0 flex w-[calc(100%-6px)] items-center justify-between bg-white p-2 px-3 shadow-[0px_0px_16px_rgba(0,0,0,0.05)]">
                <Button
                  text="Cancel"
                  size="small"
                  outlined
                  color="neutral"
                  onClick={() => {
                    close();
                    ia(newItems) && setNewItems([]);
                  }}
                />
                <Button
                  text="Done"
                  size="small"
                  onClick={() => {
                    close();
                    handleAddItemsFunc();
                  }}
                />
              </div>
            </PopoverPanel>
          </div>
        )}
      </Popover>
    </>
  );
};

export default AddProceduresProducts;
