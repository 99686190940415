import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import React from 'react';

const MedicationsTable = ({ data = [] }) => {
  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={data}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            autoSizeStrategy={{ type: 'fitCellContents' }}
            // onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MedicationsTable;
