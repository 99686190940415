import cs from 'classnames';
import React, { useRef, useState } from 'react';
import { medicalConditions } from '../../../../../constants';
import Checkbox from '../../../Checkbox/Checkbox';

import { permissions } from 'components/state';
import { useRecoilValue } from 'recoil';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import { isDataReadOnly } from '../lib/aggregatedDataHelper';

const FamilyHistoryRow = ({
  medicalCondition,
  motherSide,
  fatherSide,
  sibling,
  note,
  id,
  formik,
  onChange,
  fromCheckin,
  className,
  createdBy = null
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);
  const userPermissions = useRecoilValue(permissions);

  const dataReadOnly = isDataReadOnly({
    data: { id, createdBy },
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents
  });

  return (
    <div className={cs('flex w-full items-center gap-x-[10px]', className)}>
      <div
        className={cs(
          'grid w-full gap-4 ',
          fromCheckin
            ? 'grid-cols-1'
            : 'grid-cols-5 sm:!grid-cols-1 md:!grid-cols-1 xs:!grid-cols-1'
        )}>
        <div
          className={
            fromCheckin ? 'col-span-1' : 'col-span-2 sm:!col-span-1 md:!col-span-1 xs:!col-span-1'
          }>
          <Select
            inputId="medical-condition"
            label="Medical condition"
            options={medicalConditions}
            value={medicalCondition}
            name="medicalCondition"
            error={formik?.errors?.medicalCondition}
            onChange={(event) => onChange('medicalCondition', event, id)}
            isCreatable
            menuPortalTarget={document.body}
            disabled={disabled || dataReadOnly}
          />
        </div>
        <div
          className={
            fromCheckin ? 'col-span-1' : 'col-span-3 sm:!col-span-1 md:!col-span-1 xs:!col-span-1'
          }>
          <label className="!pb-[6px] text-sm text-neutral-800">Inheritance</label>
          <div className="mt-4 flex flex-wrap items-center gap-4 gap-x-6">
            <Checkbox
              data-qa="mothers-side"
              label="Mother's side"
              name="motherSide"
              isChecked={motherSide}
              onChange={() => onChange('motherSide', !motherSide, id)}
              disabled={disabled || dataReadOnly}
            />
            <Checkbox
              data-qa="fathers-side"
              label="Father's side"
              name="fatherSide"
              isChecked={fatherSide}
              onChange={() => onChange('fatherSide', !fatherSide, id)}
              disabled={disabled || dataReadOnly}
            />
            <Checkbox
              data-qa="sibling"
              label="Sibling"
              name="sibling"
              isChecked={sibling}
              onChange={() => onChange('sibling', !sibling, id)}
              disabled={disabled || dataReadOnly}
            />
          </div>
        </div>
        <div
          className={
            fromCheckin ? 'col-span-1' : 'col-span-5 sm:!col-span-1 md:!col-span-1 xs:!col-span-1'
          }>
          <Textarea
            className={cs(!fromCheckin ? '!min-h-[120px]' : '!min-h-[80px]', 'mb-2  text-sm')}
            label="Please provide details"
            placeholder="Add details here"
            isEditor={!fromCheckin}
            textareaClassName="!min-h-[80px]"
            id="note"
            data-qa="note"
            name="note"
            value={note}
            onChange={
              fromCheckin
                ? (event) => onChange('note', event.target.value, id)
                : (value) => onChange('note', value, id)
            }
            disabled={disabled || dataReadOnly}
            transcribing
            forwardedRef={noteRef}
          />
        </div>
      </div>
    </div>
  );
};

export default FamilyHistoryRow;
