import React from 'react';
import cs from 'classnames';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { reShapePractitioners } from 'lib/helpers/utility';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import Section from '../../Section';
import Note from './components/Note';
import Time from './components/Time';
import Duration from './components/Duration';
import Resource from './components/Resource';
import Location from './components/Location';
import Progress from './components/Progress';
import Icon from 'components/shared/Icon/Icon';
import AppointmentDate from './components/Date';
import Practitioner from './components/Practitioner';
import Button from 'components/shared/Buttons/Button';
import TagSelect from 'components/shared/Tags/Select';
import { userState as user_state } from 'components/state';
import ProfilePicture from 'components/Profile/ProfilePicture';
import { currentPractice } from 'components/practice/practiceState';

const Details = ({ appointment }) => {
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext();
  const { device } = useUIContext();
  const currPractice = useRecoilValue(currentPractice);
  const userState = useRecoilValue(user_state);
  const isMobile = device === 'mobile' || device === 'tablet-sm' || device === 'tablet';
  const isPined = isMobile || userState?.display_settings?.appointment?.details?.isPined;
  const isOpen = userState?.display_settings?.appointment?.details?.isOpen ?? false;
  const timezone = currPractice?.timezone;
  const { data: practitioners } = usePractitioners();
  const reshapedPractitioners = reShapePractitioners(practitioners?.practitioners);
  const currentTime = moment.tz(values.starts_at, currPractice?.timezone).format('hh:mm A');

  const handleCN = () =>
    navigate(`/portal/charts/${appointment?.user_id}/clinical-notes/${appointment?.id}`);

  const HeaderChildren = (
    <div className="ml-auto flex gap-4">
      {!isOpen && (
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <ProfilePicture
              size={24}
              fontSize="text-sm"
              firstName={values.practitioner.f_name}
              lastName={values.practitioner.l_name}
              image={values.practitioner.header_photo}
              id={values.practitioner.id}
            />
            <span className="text-sm text-neutral-800">{values.practitioner.fullName}</span>
          </div>
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-calendar" size={20} />
            <span className="text-sm text-neutral-800">
              {moment(values.starts_at).format('MM/DD/YYYY, dddd')}
            </span>
          </div>
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-clock-gray" color="primary" />
            <span className="text-sm text-neutral-800">{currentTime}</span>
          </div>
        </div>
      )}
      <Button text="Clinical Note" iconRight="new-start-note-v2" onClick={handleCN} size="small" />
    </div>
  );

  return (
    <Section
      title="Appointment Details"
      headerChildren={HeaderChildren}
      target="details"
      className={!isPined && 'max-h-[400px]'}
      isMobile={isMobile}>
      <div
        className={cs(
          'grid gap-x-4 gap-y-2',
          isPined ? 'grid-cols-1' : 'grid-cols-[500px,4fr,3fr]'
        )}>
        <div className={cs('flex', isPined ? 'flex-col gap-2' : 'gap-4')}>
          <Practitioner practitioners={reshapedPractitioners} />
          <AppointmentDate timezone={timezone} />
        </div>
        <div className={cs('gap-4', isPined ? 'flex' : 'grid grid-cols-5')}>
          <Time currentPractice={currPractice} />
          <Duration timezone={timezone} />
        </div>
        <Resource />
        <div className="grid gap-2">
          <TagSelect
            kind="appointment"
            currTags={appointment?.tags}
            menuPortalTarget={document.body}
            setTagIds={(ids) => setFieldValue('tag_ids', ids)}
            styles={{
              control: {
                maxHeight: 90,
                overflow: 'auto'
              }
            }}
          />
          <Note />
        </div>
        <div className={cs('flex gap-4', isPined ? 'grid grid-cols-2' : 'col-span-2')}>
          <Location practitioners={reshapedPractitioners} />
          <Progress />
        </div>
      </div>
    </Section>
  );
};

export default Details;
