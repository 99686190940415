import { withErrorBoundary } from 'components/shared/Error/Boundary';
import React from 'react';

const AlertMessage = () => {
  return (
    <div className="flex flex-col gap-2">
      <ul className="pl-6 ">
        <li className="list-inside list-disc marker:text-warning-800">
          <span className="font-600">Review and accept</span> the updates to ensure your claim stays
          up-to-date.
        </li>
        <li className="list-inside list-disc marker:text-warning-800">
          <span className="font-600">Keep your current version</span> if you’re not ready to merge
          the updates yet — just note this may leave your claim outdated.
        </li>
      </ul>
    </div>
  );
};

export default withErrorBoundary(AlertMessage);
