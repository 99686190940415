import React from 'react';
import Button from 'components/shared/Buttons/Button';
import cs from 'classnames';
import useNoteType from '../../hooks/useNoteType';

const NoteTypeButtons = () => {
  const { selectedPromptNoteType, typeOfNotes, onNoteTypeClick } = useNoteType();

  return (
    <div className="z-50 flex justify-center border-0 border-b-[1px] border-solid border-neutral-100 bg-white">
      {Object.values(typeOfNotes)
        ?.slice(0, 4)
        ?.map((noteType) => (
          <Button
            key={noteType?.id}
            onClick={() => onNoteTypeClick(noteType)}
            className={cs(
              'z-30 mx-2 my-2 h-[46px] w-[100px] !rounded-xl bg-white !px-[15px] !py-3 text-primary-500',
              noteType?.id === selectedPromptNoteType?.id ? '!bg-primary-50 outline-2' : ''
            )}
            text={noteType.label}
            iconColor={noteType?.id === selectedPromptNoteType?.id ? 'primary' : 'white'}
            outlined={noteType?.id === selectedPromptNoteType?.id}
            transparent
            color={!noteType?.id === selectedPromptNoteType?.id ? 'neutral' : 'primary'}
            textClassName={
              !noteType?.id === selectedPromptNoteType?.id ? 'text-neutral-400' : 'text-primary-500'
            }
          />
        ))}
    </div>
  );
};

export default NoteTypeButtons;
