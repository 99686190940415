import React from 'react';
import CreateMacro from 'components/practice/charts/ClinicalNote/Sidebar/Macros/Create/CreateMacro';
import Button from 'components/shared/Buttons/Button';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';

const CreateMacroContent = ({ goBack }) => (
  <div className="z-50 mx-auto flex w-2/3 flex-col">
    <CreateMacro
      handleClose={goBack}
      isModal={false}
      footer={({ submit, isSubmitting }) => (
        <ModalFooter
          rightButtons={
            <Button
              loading={isSubmitting}
              disabled={isSubmitting}
              text="Create New Text Macro"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={submit}
            />
          }
          leftButtons={
            <Button
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={goBack}
            />
          }
        />
      )}
      className="h-5/6 w-2/3"
      textAreaClassName="h-52"
      isEditor
      macroType="text_macro"
      macroTitle="Text Macro"
    />
  </div>
);

export default CreateMacroContent;
