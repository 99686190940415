import { Honeybadger } from '@honeybadger-io/react';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PersonalInfo from './personal_info';
import { requestApi } from 'api/Api';
import { useStates } from '/lib/hooks/queries/useStates';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { updateUser } from 'api/User';
import { showAlert } from 'components/shared/Alert/Alert';
const validator = require('validator');

//array of genders to select from
const genders = [
  {
    value: 'male',
    label: 'Male'
  },
  {
    value: 'female',
    label: 'Female'
  },
  {
    value: 'other',
    label: 'Other'
  }
];
const UpdatePractitionerValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name is required'),
  l_name: Yup.string().required('Last name is required'),
  dob: Yup.string().required('Date of birth required'),
  gender: Yup.string().required('Gender is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string().test('phone', '', (value, validationContext) => {
    const { createError } = validationContext;

    if (value && !validator.isMobilePhone(value)) {
      return createError({ message: 'Please provide a correct phone number.' });
    }

    return true;
  })
});
const PersonalInfoContainer = forwardRef(({ practitioner, handleClose = () => {} }, ref) => {
  const { data: statesData, isLoading: isLoadingStates } = useStates();
  const [address, setAddress] = useState(practitioner.userAddress);
  const states = statesData?.states;
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useImperativeHandle(ref, () => ({
    onSubmit: () => handleSubmit(),
    isLoading: isLoading
  }));
  const formRef = useRef();
  const formik = useFormik({
    initialValues: {
      ...practitioner,
      id: practitioner?.id,
      f_name: practitioner?.f_name,
      m_name: practitioner?.m_name,
      l_name: practitioner?.l_name,
      dob: practitioner?.dob,
      gender: practitioner?.gender,
      email: practitioner?.email,
      phone: practitioner?.phone,
      address_id: address?.id,
      address_ln_1: address?.address_ln_1,
      address_ln_2: address?.address_ln_2,
      city: address?.city,
      state: address?.state,
      zip: address?.zip
    },
    enableReinitialize: true,
    validationSchema: UpdatePractitionerValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const practitionerToUpdated = {
        practitioner: {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          dob: values?.dob,
          gender: values?.gender
        },
        address: {
          id: values?.address_id,
          address_ln_1: values?.address_ln_1,
          address_ln_2: values?.address_ln_2,
          city: values?.city,
          state: values?.state,
          zip: values?.zip,
          country: values?.country,
          full_street_address: [
            values.address_ln_1,
            values.address_ln_2,
            values.city,
            values.state,
            values.country,
            values.zip
          ].join(' ')
        }
      };
      if (values.email !== practitioner.email) {
        practitionerToUpdated.practitioner.email = values.email;
      }
      if (values.phone !== practitioner.phone) {
        practitionerToUpdated.practitioner.phone = values.phone;
      }
      setSubmitting(true);
      if (practitionerToUpdated) {
        updatePractitionerObject(practitionerToUpdated.practitioner);
        updateAddressObject(practitionerToUpdated.address);
      }
      setSubmitting(false);
    }
  });

  const { mutate: updatePractitionerObject, isLoading } = useMutation(
    (updatedPractitioner) =>
      updateUser(navigate, {
        user_id: updatedPractitioner.id,
        changes: updatedPractitioner
      }),
    {
      onSuccess: () => {
        showAlert({ title: 'Practitioner updated successfully', color: 'success' });
        handleClose();
        queryClient.invalidateQueries(['practitioner']);
        queryClient.invalidateQueries(['practitionersAdmin']);
      }
    }
  );

  const { mutate: updateAddressObject } = useMutation(
    (updatedAddress) =>
      requestApi({
        url: '/api/address/patch',
        params: { loadedAddress: updatedAddress },
        navigate
      }),
    {
      onSuccess: (data) => {
        setAddress(data.address);
      },
      onError: (error) => {
        Honeybadger.notify(
          `file: admin/practices/edit_container, method: updateAddressObject - onError, error: ${
            error ?? 'An unexpected error has occurred.'
          }`
        );
      }
    }
  );

  const handleSubmit = () => {
    if (formRef) {
      formik.submitForm();
    }
  };

  return (
    <div>
      {states ? (
        <PersonalInfo
          formRef={formRef}
          formikHandle={formik.handleChange}
          formik={formik}
          genderList={genders}
          stateList={states}
        />
      ) : null}
    </div>
  );
});

export default PersonalInfoContainer;
