import Icon from 'components/shared/Icon/Icon';
import React from 'react';

const Consent = ({ formik, practiceName }) => {
  return (
    <div className="flex items-start gap-[6px] rounded-[4px] bg-primary-25 !p-2">
      <Icon icon="new-notev3" color="primary" size={20} />
      <div>
        {['co_insurance', 'co_pay', 'co_pay_and_insurance', 'deductible'].includes(
          formik.values.type
        ) && (
            <p className="text-sm font-400 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
              I,
              <strong className="pl-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
                {formik.values.cardholderName}
              </strong>
              , authorize
              <strong className="px-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
                {practiceName}
              </strong>
              to keep my signature on file to charge my card for any balance of charges not paid by
              insurance within 90 days, not to exceed the full treatment amount for all visits this
              year.
            </p>
          )}
        {['annual', 'all_paid'].includes(formik.values.type) && (
          <p className="text-sm font-400 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
            I,
            <strong className="pl-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
              {formik.values.cardholderName}
            </strong>
            , authorize
            <strong className="px-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
              {practiceName}
            </strong>
            to keep my signature on file and to charge my credit card account for on-going visits up
            to the full treatment amount for one year.
          </p>
        )}
        {formik.values.type === 'insurance_balance' && (
          <p className="text-sm font-400 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
            I,
            <strong className="pl-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
              {formik.values.cardholderName}
            </strong>
            , authorize
            <strong className="px-1 font-600 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
              {practiceName}
            </strong>
            to keep my signature on file to charge my card for any balance of charges not paid by
            insurance within 90 days, not to exceed the full treatment amount for all visits this
            year.
          </p>
        )}
        <p className="text-sm font-400 leading-[22px] text-primary-700 sm:leading-[20px] xs:leading-[16px]">
          I assign my insurance benefits to the provider listed above. I understand that this form
          is valid for one year unless I cancel the authorization through written notice to the
          healthcare provider.
        </p>
      </div>
    </div>
  );
};

export default Consent;
