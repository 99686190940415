export const STEPS = {
  INTRO: 0,
  SELECT: 1,
  REVIEW: 2
};

export const initialBreadcrumbs = [
  { title: 'Introduction to SOF', active: true, view: STEPS.INTRO },
  { title: 'Select Services', view: STEPS.SELECT },
  { title: 'Review & Edit', view: STEPS.REVIEW }
];

export const getButtonProps = ({ currentStep, closeModal, handleBack, handleNext, isDisabled }) => {
  const isFirstStep = currentStep === STEPS.INTRO;

  return {
    backButton: {
      outlined: true,
      size: 'small',
      color: 'neutral',
      text: isFirstStep ? 'Cancel' : 'Back',
      onClick: isFirstStep ? closeModal : handleBack,
      id: isFirstStep ? 'closeServiceModalBtn' : 'backBtn'
    },
    nextButton: {
      size: 'small',
      onClick: handleNext,
      disabled: (currentStep === STEPS.SELECT || currentStep === STEPS.INTRO) && isDisabled,
      text: {
        [STEPS.INTRO]: 'Accept & Continue',
        [STEPS.SELECT]: 'Add Services',
        [STEPS.REVIEW]: 'Save'
      }[currentStep],
      id: {
        [STEPS.INTRO]: 'acceptBtn',
        [STEPS.SELECT]: 'addServicesBtn',
        [STEPS.REVIEW]: 'saveServicesBtn'
      }[currentStep],
      'data-qa': {
        [STEPS.INTRO]: 'accept-btn',
        [STEPS.SELECT]: 'add-services-btn',
        [STEPS.REVIEW]: 'save-services-btn'
      }[currentStep]
    }
  };
};
