import cs from 'classnames';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef } from 'react';
import ResizableDiv from './ResizableDiv';

const ResizableContainer = ({
  topContent = null,
  bottomContent = null,
  initialHeight = 250,
  resizableContainerClassNames = {
    containerClassName: null,
    topContentClassName: null,
    bottomContentClassName: null
  },
  resizableDivClassNames = {
    divClassName: null,
    handleClassName: null
  },
  onResize = () => {},
  isResizable
}) => {
  const topContentRef = useRef(null);

  const debouncedHandleResize = useCallback(
    debounce((newHeight) => {
      onResize(newHeight);
    }, 50),
    [onResize]
  );

  const handleResize = useCallback(
    (newHeight) => {
      if (topContentRef.current) {
        topContentRef.current.style.height = `calc(100% - ${newHeight}px)`;
      }
      debouncedHandleResize(newHeight);
    },
    [debouncedHandleResize]
  );

  useEffect(() => {
    if (topContentRef.current) {
      topContentRef.current.style.height = `calc(100% - ${initialHeight}px)`;
    }
  }, [initialHeight]);

  return (
    <div
      className={cs(
        'relative flex h-full flex-col',
        resizableContainerClassNames?.containerClassName
      )}>
      <div
        ref={topContentRef}
        className={cs('overflow-auto pb-4', resizableContainerClassNames?.topContentClassName)}>
        {topContent}
      </div>
      <div
        className={cs(
          'absolute bottom-0 z-[99] w-full',
          !isResizable && 'bottom-7',
          resizableContainerClassNames?.bottomContentClassName
        )}>
        <ResizableDiv
          isResizable={isResizable}
          initialHeight={initialHeight}
          onResize={handleResize}
          resizableDivClassNames={resizableDivClassNames}>
          {bottomContent}
        </ResizableDiv>
      </div>
    </div>
  );
};

export default ResizableContainer;
