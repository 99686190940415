export default [
  { resultCode: 0, meaning: 'OK' },
  { resultCode: 1000, meaning: 'Fax Pending' },
  { resultCode: 4000, meaning: 'Fax Failed' },
  { resultCode: 5000, meaning: 'Fax Resource Issue' },
  { resultCode: 5100, meaning: 'Fax Request Blocked' },
  { resultCode: 5200, meaning: 'Fax Rendering Issue' },
  { resultCode: 6000, meaning: 'Fax Connect Failed' },
  { resultCode: 6100, meaning: 'Fax Number Busy' },
  { resultCode: 6200, meaning: 'Fax Machine Not Answering' },
  { resultCode: 6300, meaning: 'Fax Detection Failed' },
  { resultCode: 6400, meaning: 'Fax Pickup Failed' },
  { resultCode: 6500, meaning: 'Fax Detection Failed' },
  { resultCode: 6600, meaning: 'Fax Detection Timeout' },
  { resultCode: 6700, meaning: 'Fax Number Blocked' },
  { resultCode: 6800, meaning: 'Fax Negotiation Failed' },
  { resultCode: 7000, meaning: 'Fax Training Failed' },
  { resultCode: 8000, meaning: 'Fax Transmit Failed' },
  { resultCode: 9000, meaning: 'Fax Failed' }
];
