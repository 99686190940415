import { getPatientAggregateNarrative } from 'api/PatientAggregateNarrative';

import { iaRa } from 'lib/helpers/utility';

export const patientAggregateNarratives = async ({
  navigate = () => {},
  patientId = null,
  practice_id = null,
  queryClient = () => {}
}) => {
  if (!patientId || !practice_id) {
    return {};
  }

  const result = await getPatientAggregateNarrative(navigate, {
    patientId,
    practiceId: practice_id,
    user_type: 'provider'
  });

  return iaRa(result?.data?.narrative).reduce((acc, item) => {
    queryClient.setQueryData(['patientAggregateNarrative', patientId, item?.form_type], () => ({
      data: {
        narrative: {
          ...item,
          narrative: item.narrative,
          synced: item.synced
        }
      }
    }));
    acc[item.form_type] = item.narrative;
    return acc;
  }, {});
};
