import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getTags } from 'api/Tags';

export const useTags = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['tags', ...dependencies], () => getTags(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
