import { filterNotHiddenComponents } from '../../shared/lib/helpers';

export function filterCheckedAndSelectedComponents(data) {
  const result = {};

  for (const key in data) {
    const section = data[key];

    // Filter components with checked: true
    const filteredComponents = {};
    if (data?.[key]?.checked) {
      for (const componentKey in section.components) {
        if (
          section?.components?.[componentKey]?.checked &&
          section?.components?.[componentKey]?.selected &&
          !section?.components?.[componentKey]?.hide
        ) {
          filteredComponents[componentKey] = { ...section.components[componentKey] };
        }
      }
    }

    // Copy the section if there are any checked components
    if (Object.keys(filteredComponents).length > 0) {
      result[key] = {
        ...section,
        components: filteredComponents
      };
    }
  }

  return result;
}

export function filterCheckedComponents(data) {
  const result = {};

  for (const key in data) {
    const type = data[key];

    if (!!type?.checked) {
      result[key] = type;
    }
  }
  return result;
}

export const getEnabledSectionComponents = (sectionComponents = {}) => {
  return Object.entries(sectionComponents).reduce((acc, [key, value]) => {
    const keyMaps = {
      chart3d: '3d'
    };
    const finalKey = keyMaps[key] || key;
    if (value?.enabled) {
      acc.push(finalKey);
    }
    return acc;
  }, []);
};

export const getComponents = (filledForms, type) => {
  const visibleComponents = filterNotHiddenComponents(filledForms?.[type]?.components);

  return Object.entries(visibleComponents)?.filter(([, value]) => !!value?.selected);
};
export const filterEnabledComponents = (components = [], enabledSectionComponents) => {
  return components.filter(([key]) => enabledSectionComponents?.includes(key));
};
