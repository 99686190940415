const { updatePatientTags } = require('api/Patients');
const { showAlert } = require('components/shared/Alert/Alert');

export const handleUpdatePatientTags = async ({
  navigate = null,
  queryClient = null,
  patientId = null,
  tags = [],
  mode = '',
  queryKeys = [],
  fromChart = false
}) => {
  try {
    let result = await updatePatientTags(navigate, {
      patientId,
      tags,
      mode
    });
    if (result?.code === 0) {
      if (!fromChart) {
        queryClient.setQueryData(queryKeys, (oldData) => {
          try {
            let newPatientsData = oldData?.patients?.map((p) => {
              if (p?.id !== parseInt(patientId)) {
                return p;
              }
              let newTags = structuredClone(p.tagLinks);

              if (mode === 'remove') {
                newTags = newTags.filter(
                  (tag) => !result.tags.some((r) => r.tag_id === tag?.tag_id)
                );
              }

              if (mode === 'add') {
                let addedTags = result?.tags?.flatMap((t) => t);
                newTags = [...newTags, ...addedTags];
              }

              if (mode === 'update') {
                let addedTags = result?.tags?.flatMap((t) => t);
                let filterOldPositions = newTags?.filter(
                  (tag) => !addedTags.some((addedTag) => addedTag?.tag_id === tag?.tag_id)
                );
                newTags = [...filterOldPositions, ...addedTags];
              }

              return {
                ...p,
                tagLinks: newTags
              };
            });
            return {
              ...oldData,
              patients: newPatientsData
            };
          } catch (error) {
            showAlert({
              title: 'Tags update failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
        });
      } else {
        queryClient.invalidateQueries(['patients']);
      }

      let patientValue = queryClient.getQueryData(['patient', Number(patientId)]);
      if (!patientValue?.patient?.id) return;

      queryClient.setQueryData(['patient', Number(patientId)], (data) => {
        try {
          let newTags = structuredClone(data?.patient?.tags);

          if (mode === 'remove') {
            newTags = newTags.filter((tag) => !result.tags.some((r) => r.tag_id === tag?.id));
          }
          if (mode === 'add') {
            let addedTags = result?.tags?.flatMap((t) => t.tag);
            newTags = [...newTags, ...addedTags];
          }

          if (mode === 'update') {
            let addedTags = result?.tags?.flatMap((t) => t.tag);
            let filterOldPositions = newTags.filter(
              (tag) => !addedTags.some((addedTag) => addedTag.id === tag.id)
            );
            newTags = [...filterOldPositions, ...addedTags];
          }

          return {
            ...data,
            patient: { ...data.patient, tags: newTags }
          };
        } catch (error) {
          showAlert({
            title: 'Tags update failed',
            message: error ?? 'Something went wrong',
            color: 'danger'
          });
        }
      });
    }
  } catch (error) {
    showAlert({
      title: 'Tags update failed',
      message: error ?? 'Something went wrong',
      color: 'danger'
    });
  }
};
