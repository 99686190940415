import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { useNavigate } from 'react-router-dom';
import { withErrorBoundary } from 'react-error-boundary';
import Tippy from '@tippyjs/react';

const ActivityItem = ({ activity, toggleIsOpen }) => {
  const { stringArray, icon, time, dateAndTime } = activity;
  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-start">
        <div className="flex items-start">
          <div className="text-white font-bold rounded-full p-2 flex-shrink-0">
            <Icon icon={icon} size={18} color="primary" shade='900' />
          </div>
          <div className="ml-4 text-sm !pt-1">
            <div>
              {stringArray?.map((string, index) => {
                return string.link ? (
                  <span
                    key={index}
                    className="font-bold text-primary-900 cursor-pointer"
                    onClick={() => {
                      navigate(string.link);
                      toggleIsOpen();
                    }}>
                    {string.value}
                  </span>
                ) : string.hover ? (<Tippy interactive={true} className='tippy-dark' placement='top' content={<div className='flex flex-col'>{string?.hover.map((item) => <span className='cursor-pointer underline' onClick={() => {
                  navigate(item.link);
                  toggleIsOpen();
                }}>{item.value}</span>)}</div>}><span key={index} className="font-bold text-primary-900 cursor-pointer">{string.value}</span></Tippy>) : (
                  <span key={index} className="font-medium">
                    {string.value}
                  </span>
                );
              })}
            </div>
          </div>
        </div>
        <Tippy
          content={dateAndTime}
          className='tippy-dark'
          placement='top'>
          <div className="text-gray-400 text-xs whitespace-nowrap !pt-1">
            {time}
          </div>
        </Tippy>
      </div>
    </div>
  );
};

export default withErrorBoundary(ActivityItem);
