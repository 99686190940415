import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  calculateBMI,
  cmToInch,
  separateFeetAndInches,
  separatePoundsAndOunces
} from 'lib/helpers/utility';
import React from 'react';
import { unitsOptions } from '../lib/helpers';
import VitalForm from './VitalForm';

const VitalRow = ({
  vital,
  index,
  lastVitals,
  isGrowthChartEnabled = false,
  setVitals = () => {},
  currentUnit = '',
  setCurrentUnit = () => {},
  handleOnChange = () => {},
  setConfirmDelete = () => {},
  vitals,
  setChanges,
  fieldToFocus
}) => {
  const vitalId = vital?.id;
  const length = vitals?.vitals?.length;
  const convertToInches = (feet, inches) => {
    const totalInches = feet * 12 + inches;
    const centimeters = totalInches * 2.54;
    return centimeters;
  };

  const context = useClinicalNoteContext();

  const handleFeetAndInch = (event) => {
    let field = event.target.name;
    let value = parseFloat(event.target.value) || 0;

    if (field === 'feet') {
      value = convertToInches(value, inches);
    } else if (field === 'inches') {
      value = convertToInches(feet, value);
    }

    handleHeightValue({ target: { value: value.toString() } });
    return;
  };

  const handleHeightValue = (e) => {
    const { value: centimeters } = e.target;

    setVitals((prevVitals) => {
      const updatedVitals = prevVitals.vitals.map((vital) => {
        if (vital?.id === vitalId) {
          let updatedVital = { ...vital, height: centimeters };

          if (vital?.weight > 0) {
            const bmi = calculateBMI({ weight: vital?.weight, height: centimeters });
            updatedVital = { ...updatedVital, bmi };
          }

          return updatedVital;
        }
        return vital;
      });

      return {
        ...prevVitals,
        vitals: updatedVitals
      };
    });
    setChanges(true);
  };

  const convertoPounds = (pounds, ounces) => {
    return pounds + ounces / 16;
  };

  const handlePoundsAndOz = (event) => {
    let field = event.target.name;
    let value = parseFloat(event.target.value) || 0;

    if (field === 'pounds') {
      value = convertoPounds(value, ounces);
    } else if (field === 'oz') {
      value = convertoPounds(pounds, value);
    }

    handleBMIChange({ target: { name: 'weight', value: value.toString() } });
    return;
  };

  const handleBMIChange = (event) => {
    const key = event.target.name;
    const value = event.target.value?.replace('null', '');

    setVitals((prevVitals) => {
      const updatedVitals = prevVitals.vitals.map((vital) => {
        if (vital?.id === vitalId) {
          let updatedVital = { ...vital, [key]: value };

          if (key === 'weight' && vital?.height > 0) {
            const bmi = calculateBMI({ weight: value, height: vital?.height });
            updatedVital = { ...updatedVital, bmi };
          }

          return updatedVital;
        }
        return vital;
      });

      return {
        ...prevVitals,
        vitals: updatedVitals
      };
    });
    setChanges(true);
  };

  const { pounds: lastPounds, ounces: lastOunces } = separatePoundsAndOunces(lastVitals?.weight);
  const { feet: lastFeet, inches: lastInches } = separateFeetAndInches(
    cmToInch(lastVitals?.height)
  );
  const { pounds, ounces } = separatePoundsAndOunces(vital?.weight);
  const { feet, inches } = separateFeetAndInches(cmToInch(vital?.height));

  const handleUnitChange = (unit) => {
    setCurrentUnit(unit);

    handleOnChange({ target: { value: unit } }, 'head_circumference_unit', vitalId);
  };

  const handleEnhanceNarrative = () => {
    context?.setEnhanceNarrativeParams({
      kind: index,
      name: 'vitals',
      body: sectionRef?.current?.formData?.[formName],
      customFormId: null
    });
  };

  return (
    <VitalForm
      handleOnChange={handleOnChange}
      vitals={vitals}
      vital={vital}
      vitalId={vitalId}
      index={index}
      lastVitals={lastVitals}
      handlePoundsAndOz={handlePoundsAndOz}
      handleFeetAndInch={handleFeetAndInch}
      handleBMIChange={handleBMIChange}
      isGrowthChartEnabled={isGrowthChartEnabled}
      setConfirmDelete={setConfirmDelete}
      pounds={pounds}
      ounces={ounces}
      feet={feet}
      length={length}
      inches={inches}
      lastPounds={lastPounds}
      lastOunces={lastOunces}
      lastFeet={lastFeet}
      lastInches={lastInches}
      unitsOptions={unitsOptions}
      currentUnit={currentUnit}
      handleUnitChange={handleUnitChange}
      handleEnhanceNarrative={handleEnhanceNarrative}
      isFetchingEnhanceNarrative={context?.isFetchingEnhanceNarrative}
      fieldToFocus={fieldToFocus}
    />
  );
};
export default VitalRow;
