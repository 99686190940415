import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import _ from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { requestApi } from '../../../../../../api/Api';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, io } from '../../../../../../lib/helpers/utility';
import Button from '../../../../../shared/Buttons/Button';
import Icon from '../../../../../shared/Icon/Icon';
import Switch from '../../../../../shared/Switch/Switch';
import PreviewNoteModal from '../../PreviewNote/PreviewNoteModal';
import { showPreviewNote } from '../../lib/showPreviewNote';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userState as user_state, clinicalNote as clinicalNoteState } from '../../../../../state';

const SaveAndPreview = () => {
  const userState = useRecoilValue(user_state);
  const [autosave, setAutosave] = useState(
    userState?.display_settings?.clinicalNote?.auto_save?.enabled
  );
  const {
    staticClinicalNote,
    setStaticClinicalNote,
    advancedHPRef,
    advancedSOAPRef,
    advancedFormRef,
    aggregatedDataRef,
    setCnError,
    previewNoteModal,
    setPreviewNoteModal,
    isExport,
    setIsExport,
    saveNoteRef,
    vitalsRef,
    weightLossRef,
    saveButtonText,
    setSaveButtonText,
    updateSaveButton,
    cnDisplaySettings,
    setCNDisplaySettings,
    setAdvancedHP,
    setAdvancedSOAP,
    setCustomFormTypes,
    loading: clinicalNoteLoading
  } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote = () => {}] = useRecoilState(clinicalNoteState);
  const { hpNoteOverviewRefs, patient } = useOutletContext();

  const [stopDocumentCreation, setStopDocumentCreation] = useState(false);
  const [showPreviewNoteLoading, setShowPreviewNoteLoading] = useState(false);

  const { id, appointmentId, type } = useParams();
  const navigate = useNavigate();

  const handleAutoSave = async (event) => {
    setAutosave(event);
    const updatedSettings = {
      ...cnDisplaySettings,
      auto_save: {
        ...cnDisplaySettings.auto_save,
        enabled: event
      }
    };
    setCNDisplaySettings(updatedSettings);
  };

  const clinicalNoteRef = useRef(clinicalNote);
  const staticClinicalNoteRef = useRef(staticClinicalNote);

  useEffect(() => {
    clinicalNoteRef.current = clinicalNote;
    staticClinicalNoteRef.current = staticClinicalNote;
  }, [clinicalNote, staticClinicalNote]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const cnSaving = saveButtonText === 'Saving';
      const cnId = clinicalNoteRef?.current?.id;

      if (!autosave || clinicalNoteLoading || cnSaving || !cnId) {
        return;
      }

      await onSaveNote();
    }, 15000); // 15s

    return () => {
      clearInterval(intervalId);
    };
  }, [autosave, clinicalNoteLoading, saveButtonText]);

  const queryClient = useQueryClient();
  const onSaveNote = async () => {
    try {
      const cn = clinicalNoteRef?.current || {};
      const cnStatic = staticClinicalNoteRef?.current || {};

      if (!io(cn) || cn?.locked) return;

      await Object.values(hpNoteOverviewRefs).forEach((el) => el?.current?.click());
      await aggregatedDataRef?.current?.click();
      await advancedHPRef?.current?.click();
      await advancedSOAPRef?.current?.click();
      await advancedFormRef?.current?.click();
      await vitalsRef?.current?.saveVitals();
      await weightLossRef?.current?.upsertWeightLossGoal();

      if (cn?.id && _.isEqual(cn, cnStatic)) {
        return;
      }

      setSaveButtonText('Saving');

      let params = {
        clinicalNote: {
          ...cn,
          cpt_codes: cn?.cpt_codes?.map(({ ndc_codes, ...rest }) => rest),
          user_id: cn?.user_id || id,
          appointment_id: cn?.appointment_id || appointmentId,
          fromCharts3d: type === '3d'
        },
        ...(stopDocumentCreation === false && {
          documentSignData: {
            procedureData: ia(cn?.cpt_codes)
              ? cn?.cpt_codes?.map((row) => row.code?.value).filter(Boolean)
              : [],
            payerData: ia(patient?.insuranceProfile)
              ? patient?.insuranceProfile?.map((row) => row?.payer_id).filter(Boolean)
              : []
          }
        })
      };

      const res = await requestApi({ url: '/api/clinical_note/upsert', params, navigate });

      const { clinicalNoteId, practitionerId, error = {} } = res || {};
      if (!clinicalNoteId) {
        Honeybadger.notify(error, `Clinical note upsert Failure`, {
          tags: 'clinical-note,upsert'
        });
        setCnError(
          error?.message ||
            '"Error: Unable to save the note. Please try again later or contact support for assistance."'
        );
        setStopDocumentCreation(false);
        setSaveButtonText('Save Draft');
        return;
      } else {
        setCnError(null);
        setStopDocumentCreation(true);
      }

      if (!stopDocumentCreation) {
        queryClient.invalidateQueries(['documentSign']);
      }

      delete params?.clinicalNote?.fromCharts3d;
      params.clinicalNote.id = clinicalNoteId;
      params.clinicalNote.practitioner_id = practitionerId;

      setClinicalNote((prevState) => ({
        ...prevState,
        appointment_id: params.clinicalNote?.appointment_id,
        id: clinicalNoteId,
        user_id: id,
        practitioner_id: practitionerId
      }));

      setStaticClinicalNote(params.clinicalNote);

      updateSaveButton();
    } catch (error) {
      Honeybadger.notify(error, `Clinical note upsert Failure`, {
        tags: 'clinical-note,upsert'
      });
      setCnError(
        error ||
          '"Error: Unable to save the note. Please try again later or contact support for assistance."'
      );
    }
  };

  useImperativeHandle(saveNoteRef, () => ({
    onSaveNote
  }));

  const cnDisabled = saveButtonText === 'Saving' || !clinicalNote?.id;

  return (
    <div className="flex items-center gap-x-3">
      {!clinicalNote?.locked && (
        <div
          className={cs(
            'flex items-center gap-x-1',
            (clinicalNote?.locked || cnDisabled) && '!cursor-not-allowed opacity-50'
          )}>
          <Switch
            onChange={handleAutoSave}
            checked={autosave}
            name="Auto-save"
            onColor="#B0EAFF"
            onHandleColor="#004F6B"
            offColor="#00678C"
            offHandleColor="#0085B5"
            dataQa="auto-save-btn"
            disabled={clinicalNote?.locked || cnDisabled}
          />
          <span className="text-sm text-primary-100">Auto-save</span>
        </div>
      )}
      <Icon
        icon="new-play-circle"
        onClick={() =>
          showPreviewNote({
            patientId: clinicalNote?.user_id || id,
            appointmentId: clinicalNote?.appointment_id || appointmentId,
            saveButtonText,
            setClinicalNote,
            setAdvancedHP,
            setAdvancedSOAP,
            setCustomFormTypes,
            setPreviewNoteModal,
            setIsExport,
            setLoading: setShowPreviewNoteLoading,
            navigate,
            onSaveNote
          })
        }
        className="duration-200 hover:rotate-90"
        color="primary"
        shade={100}
        size="26px"
        disabled={showPreviewNoteLoading || cnDisabled}
      />
      {!clinicalNote?.locked && (
        <Button
          icon={saveButtonText === 'Saved' ? 'new-check' : 'new-diskette'}
          text={saveButtonText}
          onClick={onSaveNote}
          id="clinicalNoteSaveBtn"
          data-qa="clinical-notes-save"
          disabled={saveButtonText === 'Saving' || clinicalNote?.locked}
          shade={800}
          iconIsStroke={saveButtonText === 'Saved'}
          loadingIcon={saveButtonText === 'Saving'}
          className="!w-[120px] !bg-primary-800 !px-[9px] hover:!bg-primary-700"
          size="small"
        />
      )}
      <PreviewNoteModal
        isOpen={previewNoteModal}
        handleClose={() => setPreviewNoteModal(false)}
        title="Clinical Note"
        isExport={isExport}
        setIsExport={setIsExport}
      />
    </div>
  );
};

export default SaveAndPreview;
