const getProgressBarOptions = ({
  isAptDisabled,
  setFieldValue,
  appointmentCompleted,
  setConfirmationModalVisible
}) => [
  isAptDisabled
    ? {
        label: 'Restore',
        value: 'restore',
        property_of: 'Restore Appointment',
        color: 'success',
        icon: 'new-check',
        size: 20,
        stroke: true,
        onClick: () => setFieldValue('status', 'restore')
      }
    : appointmentCompleted
      ? {
          label: 'Incomplete',
          value: 'incomplete',
          property_of: 'Update Appointment status to:',
          color: 'danger',
          icon: 'new-close-v2',
          size: 20,
          stroke: true,
          onClick: () => setConfirmationModalVisible(true)
        }
      : {
          label: 'Complete',
          value: 'completed',
          color: 'success',
          property_of: 'Update Appointment status to:',
          icon: 'new-check',
          stroke: true,
          onClick: () => setConfirmationModalVisible(true)
        }
];

export default getProgressBarOptions;
