import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { downloadFax, previewSavedFax } from 'api/Fax';

import PreviewPdfModal from 'components/practice/comms_center/fax/components/SendFax/components/PreviewPdfModal';
import applyChangesToFax from 'components/practice/comms_center/fax/components/SendFax/lib/applyChangesToFax';
import Popover from 'components/shared/Popovers/Popover/Popover';

const FaxDocsActions = ({ fax, handleConfirmationFile }) => {
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState(null);
  const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);

  const handlePreview = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob, url } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    if (blob) {
      setPdfData(URL.createObjectURL(blob));
    } else {
      setPdfData(url);
    }
    setPreviewFaxModalVisible(true);
  };

  const handleDownload = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, fax.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const pdfUrl = blobUrlFile;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `invoice_${new Date(fax.created_at).getTime()}_document.pdf`; // specify the filename
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const options = [
    {
      label: 'View',
      onClick: handlePreview,
      icon: 'new-eye'
    },
    {
      label: 'Download',
      onClick: handleDownload,
      icon: 'new-chevron-down'
    },

    {
      label: 'Share with patient',
      icon: 'new-arrow-swap-down',
      onClick: () => {
        handleConfirmationFile({ id: fax.id, type: 'fax', mode: 'share' });
      }
    }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {previewFaxModalVisible && (
        <PreviewPdfModal
          {...{
            pdfData,
            previewFaxModalVisible,
            setPreviewFaxModalVisible
          }}
        />
      )}
    </>
  );
};

export default FaxDocsActions;
