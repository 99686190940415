import React from 'react';

import { mString } from 'lib/helpers/utility';

const InvoiceDetailsItem = ({ invoice, hasClaims }) => {
  const {
    hasInsurance,
    charge = 0,
    ins_payment = 0,
    prevDiscount = 0,
    ins_adjustment = 0
  } = invoice || {};

  return (
    <>
      <div className="mb-4 w-full bg-white px-8">
        <div className="mx-3">
          <div className="mb-4">
            <div className="mt-3 flex justify-between">
              <span>Billed</span>
              <span>{mString(charge)}</span>
            </div>

            {hasClaims && hasInsurance && (
              <>
                <div className="mt-3 flex justify-between">
                  <span>Insurance Covered</span>
                  <span>
                    {ins_payment > 0 ? '-' : ''}
                    {mString(ins_payment)}
                  </span>
                </div>

                <div className="mt-3 flex justify-between">
                  <span>Insurance Adjusted</span>
                  <span>
                    {ins_adjustment > 0 ? '-' : ''}
                    {mString(ins_adjustment)}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <hr className="my-8 h-px border-0 bg-gray-300" />
    </>
  );
};

export default InvoiceDetailsItem;
