const { Honeybadger } = require('@honeybadger-io/react');
const { default: Axios } = require('../../../../../configuredAxios');
const { camelToKebabKeys } = require('../../../../../lib/helpers/utility');
const { getVitals } = require('api/Vitals');

exports.getOverview = async ({
  patientId,
  navigate,
  appointmentId,
  isKebab = false,
  outsideCN = true
} = {}) => {
  if (!patientId || !appointmentId) return;

  try {
    let vitals;
    if (outsideCN) {
      const { vitals: vitalsData } = await getVitals(navigate, {
        patientId,
        appointmentId,
        withal: true
      });
      vitals = vitalsData[0];
    }
    const customResponsesRes = await Axios.post('/api/custom_form_response/read', {
      patientId,
      appointmentId,
      type: ['medicalHistory', 'conditions', 'socialHistory']
    });

    let { response } = customResponsesRes.data;

    const kebabResponse = camelToKebabKeys(response);
    return {
      ...(isKebab ? { ...kebabResponse } : { ...response }),
      ...(outsideCN ? { vitals } : {})
    };
  } catch (error) {
    console.error(error);
    Honeybadger.notify(
      `Clinical note get custom responses - patientId: ${patientId}, error: ${error}`
    );
  }
};
