import React from 'react';
import { formatDateAndTimeZ, ia } from '../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../practiceState';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import TPEmpty from '../components/TPEmpty';
import cs from 'classnames';
import Button from '../../../../../shared/Buttons/Button';
import moment from 'moment-timezone';

const TPList = ({ data, onClick }) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);

  const handleSelectedTP = (index) => {
    setSelectedTreatmentPlan(data[index]);
  };

  if (!ia(data)) return <TPEmpty onClick={onClick} />;

  // SORT DATA - DESC
  data?.sort(
    (a, b) =>
      moment(a?.treatment_frequency_date?.startDate) - moment(b?.treatment_frequency_date?.startDate)
  );

  return (
    <div>
      <Button
        text="Create treatment plan"
        color="neutral"
        type="secondary"
        onClick={() => {
          setSelectedTreatmentPlan({});
          onClick({ nextView: 'empty' });
        }}
        className="!mb-4 ml-auto"
        data-qa="create-new-treatment-plan"
      />
      <table className="primary-table responsive-table selectable-children rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
        <thead>
          <tr>
            <th className="!pl-6 w-[200px]">Creation date</th>
            {data?.find((item) =>
              item.treatment_team?.find((item) => item.provider.value !== null)
            ) && <th className="!pl-3">Provider</th>}
            {data?.find((item) => ia(item.icd10)) && <th>ICD codes</th>}
            {data?.find((item) => item.treatment_frequency_date?.startDate) && <th>Start date</th>}
            {data?.find((item) => item.treatment_frequency_date?.endDate) && <th>End date</th>}
          </tr>
        </thead>
        <tbody className="max-h-[calc(100vh-244px)]">
          {data?.map((item, idx) => {
            const endDateMoment = moment(item.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
            const currentDate = moment();
            const isExpired = endDateMoment.isBefore(currentDate, 'day');
            return (
              <tr
                data-qa={`select-treatmen-plan-${idx}`}
                key={idx}
                className={cs(
                  'cursor-pointer hover:!bg-primary-50',
                  selectedTreatmentPlan?.id === item.id && 'selected'
                )}
                onClick={() => handleSelectedTP(idx)}>
                <td className="!pl-4 w-[200px]">
                  {formatDateAndTimeZ(item.created_at, practice.timezone)}
                </td>
                <td
                  className="whitespace-nowrap overflow-hidden text-ellipsis"
                  title={item.treatment_team
                    ?.map((item) => (item.provider ? item.provider?.label : null))
                    .filter((label) => label !== null)
                    .join(', ')}>
                  {item.treatment_team
                    ?.map((item) => (item.provider ? item.provider?.label : null))
                    .filter((label) => label !== null)
                    .join(', ')}
                </td>
                {item?.icd10 ? (
                  <td
                    className="whitespace-nowrap overflow-hidden text-ellipsis"
                    title={item?.icd10?.join(', ')}>
                    {item?.icd10?.join(', ')}
                  </td>
                ) : (
                  <td>N/A</td>
                )}
                <td>{item.treatment_frequency_date?.startDate}</td>
                <td className={cs(isExpired && '!text-danger-500')}>
                  {item.treatment_frequency_date?.endDate}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TPList;
