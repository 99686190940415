import React from 'react';
import { ErrorBoundary as EB } from 'react-error-boundary';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

const ErrorBoundary = ({ children, additionalText, isImage, className }) => {
  if (additionalText || isImage) {
    return (
      <EB
        FallbackComponent={(...p) => (
          <ErrorMessage {...p} additionalText={additionalText} isImage className={className} />
        )}>
        {children}
      </EB>
    );
  }
  return <EB FallbackComponent={ErrorMessage}>{children}</EB>;
};

export default ErrorBoundary;

export function withErrorBoundary(Component, errorBoundaryProps) {
  const Wrapped = React.forwardRef((props, ref) => {
    return (
      <EB FallbackComponent={ErrorMessage} {...errorBoundaryProps}>
        <Component {...props} ref={ref} />
      </EB>
    );
  });

  const name = Component.displayName || Component.name || 'Unknown';
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}
