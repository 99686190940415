import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import AGTable from 'components/shared/AGTable/AGTable';
import { capitalize } from 'lodash';
import Allowed from 'components/shared/Permissions/Allowed';
export default function PractitionersPatients() {
  const { patients } = useOutletContext();
  const cols = [
    {
      field: 'f_name',
      headerName: 'First Name',
      width: 300
    },
    {
      field: 'l_name',
      headerName: 'Last Name',
      width: 300
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 300
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 300,
      valueFormatter: ({ value }) => capitalize(value)
    }
  ];
  return (
    <Allowed requiredPermissions="patients.read" showIllustration showMessage>
      <TableContextProvider name="practitioner_patients_admin" cols={cols}>
        <Table patients={patients} />
      </TableContextProvider>
    </Allowed>
  );
}
function Table({ patients }) {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);
  return (
    <div className="flex h-full flex-col">
      <div className="flex h-full flex-col  !pb-2">
        <div className="!mb-0 h-full">
          <AGTable data={patients || []} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {patients?.length > 0 && (
            <p className="font-light text-primary-900">
              Showing 0 - {patients?.length + ''} practices
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
