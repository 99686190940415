export const currentTitle = (appointmentSettings) => {
  const titleMap = {
    details: 'Appointment Details',
    pricing: 'Appointment Pricing',
    timeline: 'Appointment Timeline'
  };
  const foundTitle = Object.keys(appointmentSettings).find(
    (key) => appointmentSettings[key].isPined
  );
  return titleMap[foundTitle] || foundTitle;
};
