import { ia, iaRa } from 'lib/helpers/utility';
import React, { useState } from 'react';

import Tippy from '@tippyjs/react';
import Button from 'components/shared/Buttons/Button';
import ProvidersModal from './ProvidersModal';

const AddCosigners = ({ documentSignatures = [] }) => {
  const [checked, setChecked] = useState(false);

  const disabledProviders = iaRa(documentSignatures)
    ?.filter((row) => !row?.meta?.add_manual_provider)
    ?.map((row) => row?.user?.id);

  const manuallySelectedProviders = iaRa(documentSignatures)
    ?.filter((row) => row?.meta?.add_manual_provider)
    ?.map((row) => row?.user?.id);

  const providersAssigned = iaRa(documentSignatures)
    ?.map((row) => `${row?.user?.f_name} ${row?.user?.l_name}`)
    ?.join(', ');

  return (
    <div>
      {ia(documentSignatures) && (
        <Tippy
          className="tippy-dark"
          content={`(${providersAssigned} ${ia(documentSignatures, 1) ? 'are' : 'is'} already assigned to this document)`}>
          <div className="flex gap-1">
            <Button
              outlined
              text="Add Co-Signers?"
              color="primary"
              className="!w-full"
              icon="new-info"
              onClick={() => setChecked(true)}
            />
          </div>
        </Tippy>
      )}

      {checked && (
        <ProvidersModal
          openModal={checked}
          closeModal={() => setChecked(false)}
          disabledProviders={disabledProviders}
          manuallySelectedProviders={manuallySelectedProviders}
        />
      )}
    </div>
  );
};

export default AddCosigners;
