import { Honeybadger } from '@honeybadger-io/react';
import { addDays } from 'date-fns';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Select from 'react-select';
import { interimApi } from '../../../../api/InterimApi';
import { documentCategories } from '../../../../constants';
import { ia } from '../../../../lib/helpers/utility';
import useOutsideClick from '../../../../lib/hooks/useOutsideClick';
import Button from '../../../shared/Buttons/Button';
import Icon from '../../../shared/Icon/Icon';
import Input from '../../../shared/Input/Input';
import Modal from '../../../shared/Modal/Modal';
import Popover from '../../../shared/Popovers/Popover/Popover';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import './Documents.scss';
import InstantPacket from './InstantPacket';
import List from './List';
import Upload from './Upload';
import View from './View';
import { getPatientDocuments } from 'api/Documents';
import { useRecoilValue } from 'recoil';
import { userState } from '../../../state';

export default function Documents({ fromPatient }) {
  const navigate = useNavigate();

  // MyPortal user state
  const currentUser = useRecoilValue(userState);

  const ref = useRef();

  const { patient } = useOutletContext() || {};

  const patientId = patient?.id || currentUser?.id;

  const [loading, setLoading] = useState({ important: false, other: false });
  const [practitioners, setPractitioners] = useState([]);

  const [id, setId] = useState({ front: null, back: null });
  const [insurance, setInsurance] = useState({ front: null, back: null });
  const [hipaa, setHipaa] = useState();

  const [documents, setDocuments] = useState([]);
  const [viewDocument, setViewDocument] = useState({
    load: false,
    title: null,
    handle: null,
    type: null,
    action: null,
    category: null
  });
  const [filters, setFilters] = useState({
    patientId,
    page: 1,
    limit: 15,
    sort: { column: 'created_at', sortBy: 'DESC' },
    searchTerm: null,
    category: null,
    type: null,
    staff: null,
    date: [{ startDate: null, endDate: null, key: 'selection' }]
  });
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [showInstantPacket, setShowInstantPacket] = useState(false);

  useEffect(() => {
    if (!fromPatient) {
      importantDocuments();
      getPractitioners();
    }
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      getDocuments();
    }, 400);

    return () => clearTimeout(timer);
  }, [
    filters?.page,
    filters?.sort,
    filters?.searchTerm,
    filters?.category,
    filters?.type,
    filters?.staff,
    filters?.date
  ]);

  const getDocuments = async () => {
    setLoading((prev) => {
      return { ...prev, other: true };
    });

    const { documents = [], totalDocuments } = await getPatientDocuments(navigate, filters);
    setDocuments(documents);
    setTotalDocuments(totalDocuments);

    setLoading((prev) => {
      return { ...prev, other: false };
    });
  };

  const importantDocuments = async () => {
    setLoading((prev) => {
      return { ...prev, important: true };
    });

    try {
      const { data } = await interimApi(
        '/api/practice/charts/documents/get_important',
        { patientId },
        navigate
      );

      const { code, redirect, error, id, insurance, hipaa } = data;

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setId(id);
          setInsurance(insurance);
          setHipaa(hipaa);

          break;
        case 1:
          if (error) {
            toast.error(error);
          } else {
            toast.error(`There's been an error getting documents. Please try again later.`);
          }
          break;

        default:
          Honeybadger.notify(
            `file: /documents/Container, method: importantDocuments - try, error: ${error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }

      setLoading((prev) => {
        return { ...prev, important: false };
      });
    } catch (error) {
      Honeybadger.notify(
        `file: /documents/Container, method: importantDocuments - catch, error: ${error ?? 'An unexpected error has occurred.'
        }`
      );
      console.error(error);
    }
  };

  const getPractitioners = async () => {
    try {
      const { data } = await interimApi('/api/practice/member/get', {}, navigate);

      if (ia(data?.members)) {
        const formattedStaff = data?.members.map((member) => ({
          value: member?.user_id,
          label: `${member?.f_name} ${member?.l_name}`
        }));

        setPractitioners(formattedStaff);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /documents/Container, method: getPractitioners, error: ${error ?? 'An unexpected error has occurred.'
        }`
      );
      console.error('error', error);
    }
  };

  const handleCloseModal = () => {
    setViewDocument({ load: false, document: {} });
    setIsEditOpen(false);
  };

  useOutsideClick(ref, () => setShowDateRangePicker(false));

  const types = [
    { value: 'image', label: 'Image' },
    { value: 'pdf', label: 'PDF' }
  ];

  return (
    <div className="DocumentsWrapper overflow-auto !px-4 !py-3">
      <div className="Header gap-4">
        <div className={fromPatient ? 'w-full grid !gap-4 grid-cols-3 sm:grid-cols-1' : 'flex w-full justify-between gap-4'}>
          <h5>Documents Center</h5>
          <Input
            icon="search"
            data-qa="search-document"
            value={filters.searchTerm}
            placeholder="Search document..."
            rightIcon={filters?.searchTerm && 'new-close'}
            rightIconClick={() => setFilters({ ...filters, searchTerm: '', page: 1 })}
            onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
          />
        </div>

        {!fromPatient && (
          <Button
            outlined
            text="Send Patient Forms"
            data-qa="send-patient-form-btn"
            onClick={() => setShowInstantPacket(true)
            }
            className='shrink-0'
          />
        )}
      </div>

      <div className="Filters">
        <Select
          isMulti
          isClearable
          placeholder="Category"
          inputId="select-category"
          options={documentCategories}
          className={fromPatient && '!w-1/3 flex-1'}
          onChange={(e) => setFilters({ ...filters, category: e, page: 1 })}
        />

        <Select
          isMulti
          isClearable
          options={types}
          placeholder="Type"
          inputId="select-type"
          className={fromPatient && '!w-1/3 flex-1'}
          onChange={(e) => setFilters({ ...filters, type: e, page: 1 })}
        />

        {!fromPatient && (
          <Select
            isMulti
            isClearable
            placeholder="Uploaded by"
            inputId="select-practitioner"
            onChange={(e) => setFilters({ ...filters, staff: e, page: 1 })}
            options={[{ value: patientId, label: patient?.fullName }, ...practitioners]}
          />
        )}

        <div ref={ref} className={fromPatient && '!w-1/3 flex-1'}>
          <Button
            data-qa="date-alltime-btn"
            transparent
            className='w-full border border-solid border-neutral-200 !bg-white'
            textClassName='text-neutral-500'
            onClick={() => {
              setShowDateRangePicker(!showDateRangePicker);
            }}
            text={!filters?.date[0]?.startDate ? (
              'Date • All time'
            ) : (
              <>
                {`${moment(filters?.date[0]?.startDate).format('MM.DD.YYYY')} - 
                ${moment(filters?.date[0]?.endDate).format('MM.DD.YYYY')} `}
                <span
                  style={{ marginLeft: '0.5rem', fontWeight: 'bold' }}
                  onClick={() =>
                    setFilters({
                      ...filters,
                      date: [{ startDate: null, endDate: null, key: 'selection' }],
                      page: 1
                    })
                  }>
                  ✕
                </span>
              </>
            )}
          />

          <Popover handleOpen={showDateRangePicker}>
            <DateRangePicker
              onChange={(item) => {
                setFilters({ ...filters, date: [item.selection] });
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              minDate={addDays(new Date(), -300)}
              maxDate={addDays(new Date(), 900)}
              direction="horizontal"
              scroll={{ enabled: true }}
              ranges={filters.date}
            />
          </Popover>
        </div>
      </div>

      <div className="InnerWrapper">
        <Upload
          fromPatient={fromPatient}
          setDocuments={setDocuments}
          patientId={Number(patientId)}
        />

        {!fromPatient &&
          (loading.important ? (
            <Skeleton height="80px" count={3} />
          ) : (
            <div className="Boxes">
              <div
                onClick={() => setViewDocument({ load: true, handle: id, type: 'id' })}
                className="cursor-pointer">
                <p>Personal ID</p>
                <Icon icon="personal-id" data-qa="personal-id" />
              </div>
              <div
                onClick={() =>
                  setViewDocument({ load: true, handle: insurance, type: 'insurance' })
                }
                className="cursor-pointer">
                <p>Insurance Card</p>
                <Icon icon="insurance-card" data-qa="insurance-card" />
              </div>
              <div
                onClick={() => setViewDocument({ load: true, handle: hipaa, type: 'hipaa' })}
                className="cursor-pointer">
                <p>HIPAA Form</p>
                <Icon icon="hipaa-form" data-qa="hippa-form" />
              </div>
            </div>
          ))}
      </div>

      <List
        patientId={patientId}
        fromPatient={fromPatient}
        loading={loading.other}
        documents={documents}
        setViewDocument={setViewDocument}
        getDocuments={getDocuments}
        totalDocuments={totalDocuments}
        filters={filters}
        setFilters={setFilters}
        isEditOpen={isEditOpen}
        setIsEditOpen={setIsEditOpen}
      />

      <Modal handleOpen={viewDocument.load} handleClose={handleCloseModal} slideFromRight>
        {viewDocument.load && (
          <View
            doc={viewDocument}
            handle={viewDocument.handle}
            type={viewDocument.type}
            title={viewDocument.title}
            patientId={patientId}
            setViewDocument={setViewDocument}
            action={viewDocument.action}
            getDocuments={getDocuments}
            category={viewDocument.category}
            setIsEditOpen={setIsEditOpen}
            setId={setId}
            setInsurance={setInsurance}
          />
        )}
      </Modal>
      {showInstantPacket && <InstantPacket setShowInstantPacket={setShowInstantPacket} />}
    </div>
  );
}
