import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';

import { useRecoilValue } from 'recoil';

import { isEmpty } from 'lib/helpers/utility';

import { formatSections } from '../../../../../../shared/Forms/Custom/lib/generalFormatFormHelperV3';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { userState } from '../../../../../../state';

import RenderNarrativeValue from './RenderNarrativeValue';

import './style.scss';

const AdvancedForm = ({ currentForm = {} }) => {
  const user = useRecoilValue(userState);

  if (!currentForm?.form) return null;

  const selectedFormType = () => {
    const narrativeComponent = currentForm.checkedFormTypes.isNarrativeChecked ? (
      <div className="print-wrapper">
        <h3 className="text-lg font-500 text-[#003A4B]">
          {currentForm?.form?.name || currentForm?.form?.form?.name || 'Narrative'}
        </h3>
        <RenderNarrativeValue
          narrative={
            isEmpty(currentForm?.narrative)
              ? formatSections({
                  fieldData: currentForm?.form?.json?.fields,
                  answerData:
                    currentForm.form &&
                    currentForm?.json?.fields &&
                    JSON.parse(currentForm?.json?.fields),
                  createdBy: { currentUser: user.id, responseCreator: currentForm.created_by }
                })
              : currentForm?.narrative
          }
        />
      </div>
    ) : null;

    const templateComponent = currentForm.checkedFormTypes.isTemplateChecked ? (
      <ReactFormGenerator
        answer_data={currentForm.form && JSON.parse(currentForm?.json?.fields)}
        data={currentForm.form ? currentForm.form?.json?.fields : currentForm?.json?.fields}
        hide_actions
        read_only
      />
    ) : null;

    return (
      <div className="print-wrapper-content">
        {templateComponent}
        {narrativeComponent}
      </div>
    );
  };

  return (
    <div className="pointer-events-none bg-white !px-6 !py-4">
      {!currentForm ? <Skeleton count={3} /> : selectedFormType()}
    </div>
  );
};

export default AdvancedForm;
