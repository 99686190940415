import { useRef } from 'react';

const useScroll = ({ ref, distance = 200 }) => {
  const scrollRef = useRef(null);
  const finalRef = ref || scrollRef;

  const handleScroll = ({ direction = 'down' }) => {
    const currentScrollTop = finalRef.current.scrollTop;
    const currentScrollLeft = finalRef.current.scrollLeft;
    const scrollDistance = direction === 'down' || direction === 'right' ? distance : -distance;
    finalRef.current.scrollTo({
      top: currentScrollTop + (direction === 'down' || direction === 'up' ? scrollDistance : 0),
      left:
        currentScrollLeft + (direction === 'right' || direction === 'left' ? scrollDistance : 0),
      behavior: 'smooth'
    });
  };

  return handleScroll;
};

export default useScroll;
