import React, { useCallback, useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import { formatLargeNumber, ia } from 'lib/helpers/utility';
import { useWindowSize } from 'lib/hooks/useWindowSize';

import Badge from '../Badge/Badge';
import HorizontalScroll from '../HorizontalScroll/HorizontalScroll';
import Icon from '../Icon/Icon';
import Popover from '../Popovers/Popover/Popover';
import Typography from '../Typography/Typography';

const ToggleItem = ({ item, isActive, disabled, onClick, count, loading }) => (
  <div
    key={item.value}
    data-targetid={`toggle-${item.value}`}
    className={cs('flex justify-center items-center gap-1 w-fit h-7 rounded px-4 py-0', {
      'bg-white shadow-md': isActive,
      'bg-transparent': !isActive,
      'cursor-pointer': !disabled,
      'cursor-not-allowed': disabled
    })}
    onClick={() => onClick(item)}
    role="button"
    tabIndex={disabled ? -1 : 0}
    aria-pressed={isActive}
    aria-disabled={disabled}>
    {item.icon && <Icon icon={item.icon} color={isActive ? 'primary' : 'neutral'} />}
    {item.label && (
      <Typography
        color={isActive ? 'neutral-800' : 'neutral-600'}
        disabled={disabled}
        weight="medium"
        className="whitespace-nowrap">
        {item.label}
      </Typography>
    )}
    {(item.count || (isActive && count)) && !loading && (
      <Badge color="neutral" size="small" text={formatLargeNumber(item.count || count)} />
    )}
  </div>
);

/**
 * @param {import('./lib/propTypes').ToggleSwitchProps} props
 */
const ToggleSwitch = ({
  items = [],
  onClick = () => {},
  active,
  disabled = false,
  count,
  horizontalScroll = false,
  loading
}) => {
  const [selected, setSelected] = useState(active);
  const containerRef = useRef(null);
  const [visibleItems, setVisibleItems] = useState(items);
  const [hiddenItems, setHiddenItems] = useState([]);
  const device = useWindowSize();
  const isMobile = device === 'mobile' || device === 'tablet-sm';
  const isTablet = device === 'tablet' || device === 'tablet-lg';

  const activeItem = active ?? selected;

  const calculateVisibleItems = useCallback(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.parentElement.offsetWidth;
    let usedWidth = 0;
    const newVisibleItems = [];
    const newHiddenItems = [];

    items.forEach((item) => {
      const itemWidth = isMobile ? 150 : isTablet ? 120 : 100;
      if (usedWidth + itemWidth <= containerWidth) {
        newVisibleItems.push(item);
        usedWidth += itemWidth;
      } else {
        if (item.value === activeItem) {
          const lastVisibleItem = newVisibleItems.pop();
          newVisibleItems.push(item);
          if (lastVisibleItem) {
            newHiddenItems.push(lastVisibleItem);
          }
        } else {
          newHiddenItems.push(item);
        }
      }
    });

    setVisibleItems(newVisibleItems);
    setHiddenItems(newHiddenItems);
  }, [items, isMobile, isTablet, activeItem]);

  useEffect(() => {
    if (!horizontalScroll) {
      calculateVisibleItems();
      window.addEventListener('resize', calculateVisibleItems);
      return () => window.removeEventListener('resize', calculateVisibleItems);
    }
  }, [horizontalScroll, calculateVisibleItems]);

  const handleClick = (item) => {
    if (disabled) return;

    setSelected(item.value);
    onClick(item);
    item.onClick?.(item);

    const isHiddenItem = hiddenItems.some((hiddenItem) => hiddenItem.value === item.value);
    if (isHiddenItem || !horizontalScroll) {
      const isInVisibleItems = visibleItems.some((visibleItem) => visibleItem.value === item.value);
      if (!isInVisibleItems) {
        setVisibleItems((prevVisible) => {
          if (prevVisible.length === 0) return prevVisible;
          return [...prevVisible.slice(0, -1), item];
        });

        setHiddenItems((prevHidden) => {
          const lastVisibleItem = visibleItems[visibleItems.length - 1];
          return [
            ...prevHidden.filter((hiddenItem) => hiddenItem.value !== item.value),
            lastVisibleItem
          ];
        });
      }
    }
  };

  if (!ia(items)) return null;

  const others = items.map((item) => ({
    ...item,
    rightIcon: item.value === activeItem ? 'checkmark' : undefined,
    onClick: () => handleClick(item)
  }));

  if (horizontalScroll) {
    return (
      <HorizontalScroll
        className={cs(
          'bg-[#F3F4F5] gap-0.5 h-8 p-0.5',
          'rounded-md',
          'shadow-[inset_0px_0px_4px_rgba(55,65,69,0.08)]',
          { 'cursor-not-allowed': disabled }
        )}>
        {visibleItems.map((item) => (
          <ToggleItem
            key={item.value}
            item={item}
            isActive={active === item.value}
            disabled={disabled}
            onClick={handleClick}
            count={count}
            loading={loading}
          />
        ))}
      </HorizontalScroll>
    );
  }

  return (
    <div
      ref={containerRef}
      className={cs(
        'bg-[#F3F4F5] gap-0.5 h-8 p-0.5 w-full max-w-fit',
        'flex items-center rounded-md',
        'shadow-[inset_0px_0px_4px_rgba(55,65,69,0.08)]',
        { 'cursor-not-allowed': disabled }
      )}>
      {visibleItems.map((item) => (
        <ToggleItem
          key={item.value}
          item={item}
          isActive={active === item.value}
          disabled={disabled}
          onClick={handleClick}
          count={count}
          loading={loading}
        />
      ))}
      {hiddenItems.length > 0 && (
        <Popover
          buttonTheme="neutral"
          className="h-7"
          activeClassNames={{
            button: '!bg-neutral-700',
            text: 'text-white',
            icon: 'white'
          }}
          isFixed
          buttonClassName="rounded-md bg-transparent !min-h-7 !h-7"
          textClassName="neutral-600"
          options={others}
          placement="bottom-end"
          text="Other"
          isDropdown
          optionIconSize={16}
          optionIconColor="neutral"
          iconRightColor="neutral-600"
          iconsShade={600}
          icon=""
          menuPortalTarget={document.body}
        />
      )}
    </div>
  );
};

export default ToggleSwitch;
