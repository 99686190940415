import React from 'react';

import Skeleton from 'components/shared/Skeleton/Skeleton';

import Draw from './components/Draw';
import ICD10OrDiagnosis from './components/ICD10OrDiagnosis';
import InsuranceDetails from './components/InsuranceDetails';
import Location from './components/Location';
import OrderDetails from './components/OrderDetails';
import OrderPhysician from './components/OrderPhysician';
import PatientDetails from './components/PatientDetails';
import TestDetails from './components/TestDetails';
import YourSignaturePreview from './components/YourSignaturePreview';

const LabOrderPreview = ({ itemProps, loading }) => {
  const labOrder = itemProps?.labOrder;
  const labOrderTests = itemProps?.labOrderTests;
  const labOrderInsurances = itemProps?.labOrderInsurances;

  return loading ? (
    <Skeleton count={20} />
  ) : (
    <div className="!mx-6">
      <div className="!mt-6">
        <TestDetails tests={labOrderTests} />
      </div>

      <div className="!mt-6">
        <ICD10OrDiagnosis itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <Location itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <Draw itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <OrderDetails itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <InsuranceDetails itemProps={labOrderInsurances} />
      </div>

      <div className="!mt-6">
        <PatientDetails itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <OrderPhysician itemProps={labOrder} />
      </div>

      <div className="!mt-6">
        <YourSignaturePreview itemProps={itemProps} />
      </div>
    </div>
  );
};

export default LabOrderPreview;
