import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getVitals } from '../../../../api/Vitals';

export const useVitals = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['vitals', ...dependencies], () => getVitals(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
