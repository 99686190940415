import cs from 'classnames';

import Icon from '../Icon/Icon';

import { getClasses } from './lib/getClasses';

/**
 * @param {import('./lib/propTypes').BadgeProps} props
 */

const Badge = (props) => {
  const {
    text,
    size = 'large',
    color = 'primary',
    isRounded = true,
    icon,
    iconRight,
    className,
    disabled,
    ...rest
  } = props;

  const iconSize = size === 'large' ? 18 : size === 'medium' ? 16 : 14;

  return (
    <div
      className={cs(getClasses({ text, size, color, disabled, isRounded }), className)}
      {...rest}>
      {icon && <Icon icon={icon} size={iconSize} color={color} disabled={disabled} />}
      {typeof text === 'object'
        ? text
        : text && (
            <span
              className={cs('font-500 capitalize', size === 'small' ? 'text-xs' : 'text-sm')}
              aria-disabled={disabled}>
              {text}
            </span>
          )}
      {iconRight && <Icon icon={iconRight} size={iconSize} color={color} disabled={disabled} />}
    </div>
  );
};

export default Badge;
