import React from 'react';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import RBCalendar from './RBCalendar/RBCalendar';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const Calendar = () => {
  return (
    <DashboardContextProvider>
      <ErrorBoundary
        FallbackComponent={ErrorMessage}
        isImage
        className="flex min-h-[400px] flex-col items-center justify-center p-14">
        <RBCalendar />
      </ErrorBoundary>
    </DashboardContextProvider>
  );
};

export default Calendar;
