import React from 'react';
import { useOutletContext } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import {
  findAppointmentStatusColor,
  findAppointmentStatusLabel,
  formatDateAndTime,
  ia,
  mString
} from 'lib/helpers/utility';

import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';
import Status from 'components/shared/Status/Status';

const AppointmentsRow = ({ type }) => {
  const appointmentStatuses = useRecoilValue(aptStatuses);

  const { practice, appointments, setShowModal, selectAppointment } = useOutletContext();

  if (ia(appointments)) {
    return appointments.map((appointment, i) => (
      <tr
        data-qa={`${type}-appointment-${i}`}
        key={i}
        className="cursor-pointer odd:!bg-[#F8FDFF] even:!bg-white"
        onClick={() => {
          setShowModal(true);
          selectAppointment(appointment?.id);
        }}>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {appointment.practitioner?.fullName}
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {appointment?.invoice?.procedures_label}
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {mString(appointment?.invoice?.balance)}
        </td>
        <td className="font-300 !border-0 !p-2 text-sm text-primary-900">
          <Status
            status={findAppointmentStatusLabel(appointmentStatuses, appointment.status)}
            color={findAppointmentStatusColor(appointmentStatuses, appointment.status)}
            className="w-max"
          />
        </td>
        <td className="font-300 !border-0 !p-4 text-sm text-primary-900">
          {formatDateAndTime(appointment?.starts_at, practice?.timezone)}
        </td>
      </tr>
    ));
  } else {
    return (
      <tr>
        <td colSpan={5} className="text-center">
          No appointments!
        </td>
      </tr>
    );
  }
};

export default AppointmentsRow;
