import React, { useEffect, useState } from 'react';

import cs from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Button from 'components/shared/Buttons/Button';
import Transcribe from 'components/shared/Transcribe/Transcribe';
import {
  aiGeneratingState,
  intelligenceAiState,
  intelligenceChatIdState,
  myScribeAutoMapCurrentActivePromptState
} from 'components/state';

import useChatInput from '../../hooks/useChatInput';
import useComponentAdd from '../../hooks/useComponentAdd';
import useNoteType from '../../hooks/useNoteType';

export function ChatFooter() {
  const { selectedPromptNoteType } = useNoteType();
  const { inputTags: tags, selectedTableRows, onTagRemove, onTagPreview } = useComponentAdd();
  const { inputValue, disableGenerate, onTextChange } = useChatInput();
  const setCurrentActivePrompt = useSetRecoilState(myScribeAutoMapCurrentActivePromptState);
  const { onEnhanceNote } = useChatContextApi();
  const intelligenceAI = useRecoilValue(intelligenceAiState);
  const setisGenerating = useSetRecoilState(aiGeneratingState);

  const [inputTags, setInputTags] = useState([]);
  const intelligenceChatId = useRecoilValue(intelligenceChatIdState);

  useEffect(() => {
    setInputTags(tags);
  }, [tags]);

  const onGenerate = () => {
    setCurrentActivePrompt((prevPrompts = []) => [
      ...(prevPrompts || []),
      { id: self.crypto.randomUUID(), message: inputValue, agent: 'user' }
    ]);

    setisGenerating(true);
    onTextChange({ target: { value: '' } });
    setInputTags([]);

    onEnhanceNote({
      userMessage: {
        message: inputValue,
        tags: inputTags?.map((item) => item.id),
        agent: 'user'
      },
      intelligenceChatId,
      name: selectedPromptNoteType?.type
    });
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  const onStopGeneration = () => {
    // Stop generation mid-way
  };

  return (
    <>
      <AIChatInput
        isHTML
        className="mb-3 min-h-[124px] border-2 !border-neutral-200 shadow-none outline outline-1 outline-neutral-200"
        leftButtons={[]}
        rightButtons={[
          <div className="mb-1 mr-2" key={1}>
            <Transcribe
              value={inputValue}
              setValue={(text) =>
                onTextChange({
                  target: {
                    value: text,
                    name
                  }
                })
              }
              disabled={false}
              setDisable={() => {}}
            />
          </div>,
          !intelligenceAI?.loading ? (
            <Button
              key={2}
              icon="sent"
              iconSize={18}
              iconColor="white"
              size="small"
              className={cs('outline-0', disableGenerate ? 'bg-neutral-100' : 'bg-primary-500')}
              onClick={onGenerate}
              disabled={disableGenerate}
              type="secondary"
              color={disableGenerate ? 'neutral' : 'white'}
            />
          ) : (
            <Button
              key={2}
              icon="new-pause"
              iconSize={18}
              iconColor="white"
              size="small"
              className="outline-0"
              outlined
              onClick={onStopGeneration}
              type="secondary"
            />
          )
        ]}
        onTextChange={onTextChange}
        onSubmit={onGenerate}
        placeholder="Type anything.."
        value={inputValue}
        tags={inputTags}
        onTagRemove={onTagRemove}
        onTagPreview={onTagPreview}
        getTagClassName={getTagClassName}
      />
      <p className="text-center text-xs italic text-neutral-700">
        AI can make mistakes. Please review & understand providers are responsible for final
        clinical note.
      </p>
    </>
  );
}
