import React, { useState } from 'react';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';
import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Buttons/Button';
import Icon from '../../../../../../shared/Icon/Icon';
import Checkbox from '../../../../../../shared/Checkbox/Checkbox';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { formatDateAndTimeZ, ia } from '../../../../../../../lib/helpers/utility';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentPractice } from '../../../../../practiceState';
import cs from 'classnames';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { loadMacro } from '../Create/lib/loadMacroHelpers';
import { showAlert } from '../../../../../../shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { clinicalNote as clinicalNoteState } from 'components/state';

const LoadMacros = () => {
  const { macros, deleteMacro, loadMacrosModal, setLoadMacrosModal, loading } = useMacrosContext();
  const { setAdvancedHP, setAdvancedSOAP, setCustomFormTypes, setLoading, applyForceSave } =
    useClinicalNoteContext();
  const setClinicalNote = useSetRecoilState(clinicalNoteState);
  const [selectedMacroIndex, setSelectedMacroIndex] = useState(null);
  const [showCofirmModal, setShowConfirmModal] = useState(false);

  const practice = useRecoilValue(currentPractice);

  const handleDeleteMacro = async () => {
    const macro = macros[selectedMacroIndex];
    deleteMacro(macro?.id, 'appointment_macro');
    setSelectedMacroIndex(null);
  };

  const handleMacroClick = (index) => {
    setSelectedMacroIndex(index);
  };

  const onSelectMacro = () => {
    loadMacro({
      selectedMacro: macros[selectedMacroIndex],
      setClinicalNote,
      setAdvancedHP,
      setAdvancedSOAP,
      setCustomFormTypes,
      setLoading,
      applyForceSave
    });
    loadMacrosModal && setLoadMacrosModal(false);
    showAlert({ title: 'Macro loaded successfully', color: 'success' });
  };

  return (
    <Modal
      handleOpen={loadMacrosModal}
      handleClose={() => setLoadMacrosModal(false)}
      title="Load From Appointment Macros"
      className="w-[924px]"
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            data-qa="load-appointment-cancel-btn"
            onClick={() => setLoadMacrosModal(false)}
            outlined
            text="Cancel"
            color="neutral"
          />
          <div className="flex items-center justify-between gap-x-2">
            <Button
              data-qa="load-appointment-delete-btn"
              outline
              onClick={handleDeleteMacro}
              color="danger"
              text="Delete"
              icon="new-trash"
              disabled={selectedMacroIndex === null && true}
            />
            <Button
              data-qa="load-appointment-template-btn"
              onClick={() => setShowConfirmModal(true)}
              text="Load appointment macros"
              disabled={selectedMacroIndex === null && true}
            />
          </div>
        </div>
      }>
      <div>
        <div className="flex items-center justify-between">
          <h3 className="text-base font-500 leading-6 text-neutral-800">Appointment Macros</h3>
          <div className="h-10 w-8">{/* Search */}</div>
        </div>
        {loading?.get || loading?.delete ? (
          <Skeleton count={5} />
        ) : (
          <table className="primary-table responsive-table selectable-children rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
            <thead>
              <tr>
                <th className="!pl-16">Label</th>
                <th>Forms</th>
                <th>Creation date</th>
              </tr>
            </thead>
            <tbody className="max-h-[calc(100vh-244px)]">
              {ia(macros) ? (
                macros?.map((macro, idx) => (
                  <tr
                    key={idx}
                    className={cs(
                      'cursor-pointer hover:!bg-primary-50',
                      selectedMacroIndex === idx && 'selected'
                    )}
                    onClick={() => handleMacroClick(idx)}>
                    <td className="flex h-full items-center gap-x-4">
                      <Checkbox
                        radio
                        className="scale-75"
                        isChecked={selectedMacroIndex === idx}
                        data-qa={`load-appoinment-checkbox-${idx}`}
                      />
                      <span className="font-500 first-letter:capitalize ">{macro.name}</span>
                    </td>
                    <td>H&P, SOAP</td>
                    <td>{formatDateAndTimeZ(macro.created_at, practice.timezone)}</td>
                  </tr>
                ))
              ) : (
                <span className="!pb-4 !pt-5 text-center text-sm text-primary-900">
                  No appointment macros found!
                </span>
              )}
            </tbody>
          </table>
        )}
      </div>
      <Icon icon="new-pattern-two-rect" className="absolute bottom-[69px] left-0" />

      <Confirm
        handleOpen={!!showCofirmModal}
        handleClose={() => setShowConfirmModal(false)}
        handleContinue={onSelectMacro}
        title="Macro"
        message="Are you sure you want to apply this macro? This action will replace existing data."
        primaryBtnTxt="Apply macro"
        secondaryBtnTxt="Keep"
        icon="new-info"
        variant="warning"
      />
    </Modal>
  );
};

export default LoadMacros;
