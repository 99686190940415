import { requestApi } from './Api';

export const getMyScribe = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/myscribe/get',
    params
  });

export const upsertMyScribe = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/myscribe/upsert',
    params
  });

export const deleteMyScribe = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/clinical_note/myscribe/delete',
    params
  });

export const checkUnprocessedAudio = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/myscribe/check_unprocessed_audio',
    params
  });

export const processAudio = async (navigate, params) =>
  requestApi({
    navigate,
    url: 'api/clinical_note/myscribe/invoke_lambda',
    params
  });
