import { useImperativeHandle } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { pm } from 'lib/helpers/utility';

import Textarea from 'components/shared/Textarea/Textarea';
import state, { clinicalNote as clinicalNoteState } from 'components/state';

import EnhancementStatus from './EnhancementStatus';

const Plan = ({ planRef, sectionRef = null, showDisclaimer }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const permissions = useRecoilValue(state.permissions);
  const isPermission = pm(
    permissions,
    ['myscribe.narrative_update', 'myscribe.narrative_read'],
    'or'
  );
  useImperativeHandle(sectionRef, () => ({
    formData: { plan: clinicalNote?.plan }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write plan notes here"
      id="plan"
      name="plan"
      className="min-h-[200px]"
      value={clinicalNote?.plan}
      onChange={(e) => handleChange(e, 'plan')}
      transcribing
      forwardedRef={planRef}
      disabled={clinicalNote?.locked}
      info={
        isPermission && showDisclaimer && 'AI can make mistakes, please confirm the information!'
      }
      infoHighlights={['AI', 'mistakes']}
    />
  );
};

export default Plan;
