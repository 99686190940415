import moment from 'moment';
import { reverseGenerateUnit } from './reverseGenerateUnit';
export const handleOnChangeUnit = ({
  procedure,
  isTimeUnit,
  handleProcedureChanges,
  index,
  startTime,
  endTime,
  value
}) => {
  if (isTimeUnit) {
    const newEndTime = moment(startTime, 'hh:mm A').add(value, 'minutes').format('hh:mm A');
    handleProcedureChanges({ target: { value: newEndTime, name: 'end_time' } }, index);
    handleProcedureChanges({ target: { value, name: 'unit_time_length' } }, index);
    const { reversedUnit } = reverseGenerateUnit(procedure, startTime, endTime, value);
    handleProcedureChanges({ target: { value: reversedUnit, name: 'units' } }, index);
  } else {
    const { reversedEndTime } = reverseGenerateUnit(procedure, startTime, endTime, value);

    handleProcedureChanges({ target: { value: reversedEndTime, name: 'end_time' } }, index);

    handleProcedureChanges({ target: { value, name: 'units' } }, index);
  }
};
