import React from 'react';
import Pagination from '../../../shared/Pagination/Pagination';

const ServiceTable = ({ children, changePage, filters, serviceCount }) => {
  return (
    <div className="overflow-x-auto">
      <table className="primary-table">
        <thead>
          <tr>
            <th className="w-[25%]">Name</th>
            <th className="!max-w-[25%]">Description</th>
            <th>Procedure Code</th>
            <th>Time Length</th>
            <th>Total Price</th>
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      <Pagination
        onPageChange={changePage}
        page={filters.page}
        totalItems={serviceCount}
        perPage={10}
      />
    </div>
  );
};

export default ServiceTable;
