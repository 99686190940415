import React, { useEffect } from 'react';
import moment from 'moment-timezone';
import { optionify } from '/lib/helpers/utility';
import Select from 'components/shared/Select/Select';
import Input from 'components/shared/Input/Input';

const NewPractice = ({ practiceTypeList, specialtyTypes, stateList, onChange, formik }) => {
  const timeZoneNames = moment.tz.names();
  useEffect(() => {
    formik.setFieldValue('newPractice', true, false);
  }, []);

  return (
    <div>
      <div className="grid grid-cols-5 gap-4 md:grid-cols-1 lg:grid-cols-3">
        <Input
          label="Practice Name"
          name="practice_name"
          value={formik.values?.practice_name}
          onChange={onChange}
          error={formik.errors?.practice_name}
          data-qa="practice-name"
        />

        <Select
          isClearable={false}
          label="Practice Kind"
          name="practice_kind"
          data-qa="practice-kind"
          options={practiceTypeList}
          placeholder="Practice Kind"
          value={{
            label: practiceTypeList.find((kind) => kind.value === formik.values?.practice_kind)
              ?.label
          }}
          onChange={(e) => formik.setFieldValue('practice_kind', e?.value || null)}
          error={formik.errors?.practice_kind}
        />

        <Select
          isClearable={false}
          label="Practice specialty"
          name="practice_specialty_code"
          data-qa="practice-specialty-code"
          options={optionify(specialtyTypes, 'name', 'code')}
          placeholder="Practice Kind"
          value={{
            label:
              formik.values?.practice_specialty_code &&
              formik.values?.practice_specialty_code +
                ' - ' +
                specialtyTypes.find((item) => item.code == formik.values?.practice_specialty_code)
                  ?.name
          }}
          onChange={(e) => formik.setFieldValue('practice_specialty_code', e?.value || null)}
          error={formik.errors?.practice_specialty_code}
        />

        <Input
          label="Practice Contact Email"
          name="practice_email"
          value={formik.values?.practice_email}
          onChange={onChange}
          error={formik.errors?.practice_email}
          data-qa="practice-email"
        />

        <Input
          label="Practice Contact Phone"
          name="practice_phone"
          value={formik.values?.practice_phone}
          onChange={onChange}
          error={formik.errors?.practice_phone}
          data-qa="practice-phone"
        />
      </div>
      <p className="my-5 text-xl font-600">Address</p>
      <div className="grid  grid-cols-3 gap-4 md:grid-cols-1">
        <Input
          label="Address"
          name="address_ln_1"
          value={formik.values?.address_ln_1}
          onChange={onChange}
          error={formik.errors?.address_ln_1}
          data-qa="practices-address-ln-1"
        />

        <Input
          label="Address 2"
          name="address_ln_2"
          value={formik.values?.address_ln_2}
          onChange={onChange}
          data-qa="practices-address-ln-2"
        />

        <Input
          label="City"
          name="city"
          value={formik.values?.city}
          onChange={onChange}
          error={formik.errors?.city}
          data-qa="practices-city"
        />

        <Select
          isClearable={false}
          label="State"
          name="state"
          options={optionify(stateList, 'name', 'id')}
          placeholder="State"
          value={{
            label: stateList.find((state) => state.id === formik.values?.state)?.name
          }}
          onChange={(e) => formik.setFieldValue('state', e?.value || null)}
          data-qa="practices-state"
          error={formik.errors?.state}
        />

        <Input
          label="Zip Code"
          name="zip"
          value={formik.values?.zip}
          onChange={onChange}
          error={formik.errors?.zip}
          data-qa="zip-code"
        />

        <Select
          isClearable={false}
          label="Timezone"
          value={{ label: formik.values?.practice_timezone }}
          onChange={(e) => {
            formik.setFieldValue('practice_timezone', e.value);
          }}
          options={optionify(timeZoneNames)}
          error={formik.errors?.practice_timezone}
          data-qa="practices-timezone"
        />
      </div>
    </div>
  );
};
export default NewPractice;
