import React from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../../../../shared/Icon/Icon';
import cs from 'classnames';

const SidebarPopover = ({ selectedView, setSelectedView, options }) => (
  <Popover className="relative">
    {({ open, close }) => (
      <div>
        <Popover.Button className="p-0 w-full" data-qa="selected-overview-button">
          <div
            className={cs(
              'flex items-center gap-x-[6px] !px-4 !py-[14px] justify-between',
              open ? 'bg-primary-700' : 'bg-primary-50'
            )}>
            <span
              className={cs(
                'font-500 text-base first-letter:capitalize text-primary-900',
                open && '!text-white'
              )}>
              {selectedView.title}
            </span>
            <Icon
              icon="new-chevron-down"
              color={open ? 'white' : 'primary'}
              {...(!open && { shade: '900' })}
              className={cs('duration-200', open && 'rotate-180 ')}
            />
          </div>
        </Popover.Button>
        <Popover.Panel className="absolute left-0 z-20 min-w-full grid w-max cursor-pointer gap-y-1 rounded-b-lg !border-primary-200 border !border-t-0 bg-primary-50 !py-2 shadow-medium">
          {options.map((item, index) => {
            return (
              <div
                data-qa={`option-${index}`}
                onClick={() => {
                  setSelectedView(item);
                  close();
                }}
                className={cs(
                  'relative flex items-center gap-x-2 !py-2 !px-4 hover:bg-primary-100',
                  item.component === selectedView.component && 'bg-primary-100 font-500'
                )}
                key={index}>
                {item.icon && <Icon icon={item.icon} />}
                <span className={cs('select-none text-base text-primary-900')}>{item.title}</span>
              </div>
            );
          })}
        </Popover.Panel>
      </div>
    )}
  </Popover>
);

export default SidebarPopover;
