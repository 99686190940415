import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia } from 'lib/helpers/utility';

import { userState } from 'components/state';

import { useMyScribeAIContext } from '../MyScribeAIContext';

import { MyTemplateContext } from './MyTemplateContext';
import { getInitialFormsState } from './lib/helpers';

export const MyTemplateContextProvider = ({ children }) => {
  const { typeOfNotes } = useMyScribeAIContext();
  const [selectedChatComponents, setSelectedChatComponents] = useState([]);
  const [chatForms, setChatForms] = useState({});
  const { customFormTypes, cnDisplaySettings } = useClinicalNoteContext();
  const [scrollTo, setScrollTo] = useState(null);

  const currentUser = useRecoilValue(userState);
  const displaySettings = currentUser?.display_settings;
  const myScribeAISettings = displaySettings?.clinicalNote?.myscribe_ai || {};

  const componentRefs = useRef({});

  useEffect(() => {
    const defaultSettings = ia(myScribeAISettings?.manual_template)
      ? myScribeAISettings.manual_template.find((settings) => settings.isDefault)
      : null;

    setForms({
      typeOfNotes,
      cnDisplaySettings,
      customFormTypes,
      settings: defaultSettings?.forms
    });
  }, [myScribeAISettings.manual_template, setForms, typeOfNotes]);

  const setForms = useCallback((obj) => {
    setChatForms(getInitialFormsState(obj));
  }, []);

  const onResetSettings = useCallback(() => {
    setForms({
      typeOfNotes,
      cnDisplaySettings,
      customFormTypes,
      settings: null
    });
  }, [setForms, typeOfNotes, cnDisplaySettings, customFormTypes]);

  const onSetSettings = useCallback(
    (settings) => {
      setForms({
        typeOfNotes,
        cnDisplaySettings,
        customFormTypes,
        settings
      });
    },
    [setForms, typeOfNotes, cnDisplaySettings, customFormTypes]
  );

  return (
    <MyTemplateContext.Provider
      value={{
        selectedChatComponents,
        setSelectedChatComponents,
        componentRefs,
        chatForms,
        setChatForms,
        scrollTo,
        setScrollTo,
        setManualTemplateSettings: onSetSettings,
        resetSettings: onResetSettings
      }}>
      {children}
    </MyTemplateContext.Provider>
  );
};
