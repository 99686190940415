import React from 'react';
import Allowed from 'components/shared/Permissions/Allowed';
import Select from 'components/shared/Select/Select';

const Resources = ({ formik, resources }) => {
  const currentResource =
    resources.find((resource) => resource.value === formik.values.resource_id) ||
    formik.values.resources?.[0];

  return (
    <Allowed requiredPermissions="resources.read">
      <div data-dd-privacy="allow">
        <p className="text-sm font-500">Resources</p>
        <Select
          placeholder="Type and select a resource"
          value={currentResource}
          onChange={(value) => {
            formik.setFieldValue('resource_id', value?.value || null);
          }}
          options={resources}
          inputId="resourceSelect"
          error={formik.errors.resource_id}
          className="!mt-2"
        />
      </div>
    </Allowed>
  );
};

export default Resources;
