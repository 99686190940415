import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';
import { formatDate, iaRa } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import SuperbillActions from '../components/SuperbillActions';

export default function (timezone) {
  return [
    { field: 'id', headerName: 'Superbill ID', maxWidth: 140 },
    {
      field: 'provider_name',
      headerName: 'Rend. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      minWidth: 170,
      flex: 1
    },
    {
      field: 'bill_name',
      headerName: 'Bill. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      flex: 1,
      minWidth: 170
    },
    {
      field: 'payer_name',
      headerName: 'Payer',
      valueFormatter: ({ value }) => capitalize(value),
      minWidth: 170,
      flex: 1
    },
    {
      field: 'dos',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, timezone, true),
      width: 150
    },
    {
      field: 'created_at',
      headerName: 'Superbill date',
      valueFormatter: ({ value }) => formatDate(value, timezone, true),
      width: 150
    },
    {
      field: 'services',
      headerName: 'Services',
      valueGetter({ data }) {
        const procedures = iaRa(data?.encounter?.appointment?.invoice?.procedures)
          .filter(filterSuperbillClaimProcedure)
          ?.map((item) => item.procedure_code)
          ?.join(', ');

        return procedures;
      },
      sortable: false
    },
    {
      field: 'claim_id',
      headerName: 'Claim',
      maxWidth: 150,
      minWidth: 120
    },
    {
      field: 'state',
      headerName: 'Status',
      valueFormatter: ({ value }) => capitalize(value),
      maxWidth: 100
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: SuperbillActions,
      maxWidth: 100
    }
  ];
}
