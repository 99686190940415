const atom = require('recoil').atom

module.exports = {
  global_user: atom({
    key: 'current',
    default: {
      user_id: '',
      f_name: '',
      l_name: '',
      email: '',
      state: '',
      logged_in: false
    }
  }),
  messages: atom({
    key: 'messages',
    default: {
      all_messages: []
    }
  }),
  conversation_practice_id: atom({
    key: 'practice_id',
    default: null
  }),
  patientAppointment: atom({
    key: 'appointment',
    default: {
      id: null
    }
  })
}
