import React from 'react';
import { ia, isEmpty } from '../../../../../../../../lib/helpers/utility';
import { findLabStatus } from '../../../lib/labOrderHelpers';

const TestDetails = ({ tests }) => {
  const checkIfTestHasQA = (qa) => {
    if (!ia(qa)) return false;

    return qa.every((row) => {
      return !isEmpty(row?.question) || !isEmpty(row?.answer);
    });
  };

  if (!ia(tests)) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Test Details</div>

      <ul className="!pl-5">
        {tests.map((test, index) => {
          const staticTestStatus = findLabStatus({ value: test.test_status });

          return (
            <li
              key={index}
              className="list-disc text-sm break-normal whitespace-pre-wrap !mt-3 !list-outside">
              {test.test_name && (
                <div>
                  <strong>Test Name:</strong> {test.test_name}
                </div>
              )}
              {test.test_ref_lab && (
                <div>
                  <strong>Lab Name:</strong> {test.test_ref_lab}
                </div>
              )}
              {!!test.test_status && (
                <div>
                  <strong>Test Status:</strong> {staticTestStatus ?? test?.test_status}
                </div>
              )}
              {test.sample_type && (
                <div>
                  <strong>Sample Type:</strong> {test.sample_type}
                </div>
              )}
              {test.container && (
                <div>
                  <strong>Container:</strong> {test.container}
                </div>
              )}
              {test.draw_req && (
                <div>
                  <strong>Draw Req:</strong> {test.draw_req}
                </div>
              )}
              {test.transport_mode && (
                <div>
                  <strong>Transport Mode:</strong> {test.transport_mode}
                </div>
              )}
              {test.transport_group && (
                <div>
                  <strong>Transport Group:</strong> {test.transport_group}
                </div>
              )}
              {checkIfTestHasQA(test?.qa) && (
                <div className="flex !gap-1">
                  <strong>Q&A:</strong>
                  <div className="flex flex-wrap !gap-3">
                    {test?.qa?.map((row, index) => (
                      <div className="p-1 !mt-1 bg-neutral-100 rounded-lg" key={index}>
                        <div>
                          <strong>Question:</strong> {row?.question}
                        </div>
                        <div>
                          <strong>Answer:</strong> {row?.answer}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {ia(test?.test_cpt) && (
                <div className="flex flex-wrap !gap-1">
                  <strong>Test CPT:</strong>
                  {test?.test_cpt?.map((item) => item?.label).join(', ')}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TestDetails;
