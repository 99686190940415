import React from 'react';
import cs from 'classnames';
import './Sanitize.scss';
import { sanitize } from 'lib/helpers/sanitize';

const SanitizeComponent = ({ value, title, className }) => {
  return (
    <div className={cs('Sanitize grid gap-1', className)}>
      <h3 className="text-base font-500 text-[#003A4B]">{title}</h3>
      <p class="font-500 text-[#003A4B]" dangerouslySetInnerHTML={sanitize(value)} />
    </div>
  );
};

export default SanitizeComponent;
