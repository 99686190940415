import React from 'react';

import Input from '../../../../shared/Input/Input';
import { iaRa } from '../../../../../lib/helpers/utility';
import RadioButton from '../../../../shared/RadioButton/RadioButton';

const AppointmentLocation = ({
  location,
  virtual_link,
  selectedLocation,
  updateVirtualLink,
  appointmentLocations,
  selectAppointmentLocation
}) => {
  const selectedLocationValue = selectedLocation?.value || location?.value;

  return (
    <>
      <div>
        <label className="mb-2 block text-sm font-500 text-neutral-800">Location</label>
        <div className="grid grid-cols-[max-content,max-content] gap-8">
          {iaRa(appointmentLocations).map(({ value, label }, i) => {
            return (
              <RadioButton
                key={i}
                id={value}
                value={value}
                label={label}
                isSelected={value === selectedLocationValue}
                onChange={() => selectAppointmentLocation({ value, label })}
              />
            );
          })}
        </div>
      </div>
      {selectedLocationValue == 'virtual' && (
        <div>
          <Input
            label="Meeting link"
            value={virtual_link}
            onChange={(e) => updateVirtualLink(e.target.value)}
          />
        </div>
      )}
    </>
  );
};

export default AppointmentLocation;
