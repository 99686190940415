import React from 'react';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { sanitize } from 'lib/helpers/sanitize';

const PatientNeeds = () => {
  const { selectedTreatmentPlan, clinicalNote } = useClinicalNoteContext();

  if (!selectedTreatmentPlan?.patient_needs) return null;

  return (
    <div className="grid gap-2">
      <h3 className="text-base font-500 text-neutral-800">Patient needs / presenting issues</h3>
      <p
        className="text-sm font-500 text-neutral-800"
        dangerouslySetInnerHTML={sanitize(
          selectedTreatmentPlan?.patient_needs ?? clinicalNote?.present_illness
        )}
      />
    </div>
  );
};

export default PatientNeeds;
