import React from 'react';
import Accordion from '../../../../shared/Accordion';
import RiskAndCoMorbidities from './RiskAndCoMorbidities';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const RiskAndCoMorbiditiesSection = ({ components, sectionRef, options }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.riskAndCoMorbidities?.title}
      id={components?.riskAndCoMorbidities?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <RiskAndCoMorbidities sectionRef={sectionRef} />
    </Accordion>
  );
};

export default RiskAndCoMorbiditiesSection;
