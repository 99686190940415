import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from 'api/InterimApi';
import { getStates } from 'api/State';

import state from '../../state';
import { pick } from 'lodash';
import { useQueryClient } from '@tanstack/react-query';

export default function useInitUser({
  setAllDataEditable,
  appointmentId,
  patient,
  setPatient,
  setEditableData,
  setAddress,
  setStates,
  setDecodedSSN,
  user,
  practice,
  formik
}) {
  const selections = useRecoilValue(state.selectionState);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  useEffect(() => {
    getStatesMethod();
    queryClient.invalidateQueries(['checkinUser']);
  }, []);

  useEffect(() => {
    if (appointmentId) {
      if (selections?.personal_info !== null) {
        if (!user.authenticated && !user.isOnboarding) {
          localStorage.setItem('myriad_pl', pathname);
          localStorage.setItem('myriad_pl_timestamp', moment().toString());
        }
      }
      getPatient();
    }
  }, [appointmentId]);

  useEffect(() => {
    if (patient?.id) {
      getSecure();
    }
  }, [patient]);

  const getSecure = async () => {
    try {
      let res = await interimApi(
        '/api/user/get_secure',
        {
          id: patient?.id,
          practice_id: practice?.id
        },
        navigate
      );
      const { secure } = res.data;
      if (secure) {
        formik.setValues({ ...formik.values, ssn: secure?.ssn });
        if (!secure?.ssn?.length > 0) {
          setDecodedSSN(true);
        }
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const getPatient = async () => {
    try {
      let res = await interimApi(
        '/api/patient/checkin/get_patient',
        {
          appointmentId
        },
        navigate
      );
      const { patient: loadedPatient, all_editable, show: editableInfoShow } = res.data;
      setEditableData(editableInfoShow);
      setAllDataEditable(all_editable);
      if (loadedPatient) {
        setPatient(
          pick(loadedPatient, [
            'f_name',
            'l_name',
            'm_name',
            'p_name',
            'email',
            'alt_email',
            'alt_email_notify',
            'phone',
            'alt_phone',
            'alt_phone_notify',
            'gender',
            'dob',
            'race',
            'ssn',
            'marital_status',
            'personal_id',
            'id',
            'profile_photo',
            'emergency_contact',
            ''
          ])
        );
        setAddress(loadedPatient.userAddress);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later`);
      }
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/insurance, method: getPatient - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  const getStatesMethod = async () => {
    try {
      let res = await getStates(navigate, { method: 'get', optionify: true });
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `file: checkin/insurance, method: getStates - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  };

  return {
    getSecure
  };
}
