import { showAlert } from '../components/shared/Alert/Alert';
import { requestApi } from './Api';

export const getContacts = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/comms_contacts/get',
    params,
    onError: () =>
      showAlert({
        title: 'Fax Contacts failed to response',
        color: 'danger'
      })
  });

export const createContact = async (navigate, faxObject) =>
  await requestApi({
    navigate,
    url: '/api/comms_contacts/create',
    params: faxObject,
    throwError: true
  });

export const updateContact = async (navigate, updatedFaxObject) =>
  await requestApi({
    url: '/api/comms_contacts/update',
    params: updatedFaxObject,
    navigate
  });

export const deleteContact = async (navigate, id) => {
  return await requestApi({
    url: '/api/comms_contacts/delete',
    params: { id },
    navigate
  });
};
