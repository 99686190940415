import React, { useRef, useState } from 'react';
import { otherProblemsList } from '../../../../../constants';
import Textarea from '../../../Textarea/Textarea';
import Select from '../../../Select/Select';
import Icon from '../../../Icon/Icon';
import cs from 'classnames';

const OtherProblemsRow = ({ note, problems, id, onChange, newDesign }) => {
  const [disabled, setDisabled] = useState(false);

  const noteRef = useRef(null);

  return (
    <div className="flex items-center gap-4">
      <div className={cs(newDesign ? 'grid-cols-1' : 'grid-cols-2', 'grid w-full  gap-4')}>
        <Textarea
          label="Name"
          placeholder="Add note here"
          id="note"
          name="note"
          value={note}
          onChange={(event) => onChange('note', event.target.value, id)}
          disabled={disabled}
          transcribing
          forwardedRef={noteRef}
        />
        <Select
          label="Severity"
          isCreatable
          options={otherProblemsList}
          value={problems}
          disabled={disabled}
          onChange={(event) => onChange('problems', event, id)}
        />
      </div>
    </div>
  );
};

export default OtherProblemsRow;
