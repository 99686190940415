export default {
  nameSearchTerm: {
    type: 'search',
    values: '',
    title: 'Name',
    placeholder: 'Search by name'
  },
  providers: {
    type: 'search',
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', '),
    multiple: true
  }
};
