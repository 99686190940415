import React from 'react';
import { Outlet } from 'react-router-dom';

import usePageTitle from '../../../lib/hooks/usePageTitle';

import CommsCenterSidebar from './components/sidebar/CommsCenterSidebar';

export const CommsCenter = () => {
  usePageTitle('CommsCenter');
  // TODO add fax permissions

  return (
    <div className="flex h-full bg-neutral-50" data-dd-privacy="allow">
      <CommsCenterSidebar />
      <div className="flex w-full flex-col">
        <Outlet />
      </div>
    </div>
  );
};
export default CommsCenter;
