import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getTerminalGroups } from 'api/Admin/Practices/Terminals';

export const useTerminalGroups = ({ params, options, dependencies, name }) => {
  const navigate = useNavigate();
  return useQuery([name, ...dependencies], () => getTerminalGroups(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
