import { requestApi } from './Api';

export const getDataBanks = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/admin/databank/get',
    params: params
  });
  export const deleteDataBank = async (navigate, options) =>
  await requestApi({
    url: '/api/admin/databank/delete',
    params: options,
    navigate
  });