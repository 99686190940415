import React, { useState } from 'react';

import Button from 'components/shared/Buttons/Button';

import LeftInputs from './LeftInputs';
import MiddleInputs from './MiddleInputs';
import RightInputs from './RightInputs';

const AdditionalChargeFields = ({ index, updateProcedures, procedure }) => {
  const [showAdditionalCharges, setShowAdditionalCharges] = useState(false);

  const handleInputChange = (event) => updateProcedures(event, index);

  const handleSelectChange = (option, name) => updateProcedures({ target: option }, index, name);

  const buttonText = showAdditionalCharges
    ? 'Hide additional charge fields'
    : 'Show additional charge fields';

  return (
    <>
      <Button
        onClick={() => setShowAdditionalCharges((prev) => !prev)}
        transparent
        text={buttonText}
      />
      {showAdditionalCharges && (
        <div className="flex gap-4 flex-1 p-2">
          <LeftInputs procedure={procedure} handleInputChange={handleInputChange} />
          <MiddleInputs
            procedure={procedure}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
          />
          <RightInputs procedure={procedure} handleInputChange={handleInputChange} />
        </div>
      )}
    </>
  );
};

export default AdditionalChargeFields;
