import React, { useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { usePayments } from 'lib/hooks/queries/billing/usePayments';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import Allowed from 'components/shared/Permissions/Allowed';

import { currentPractice } from '../../practiceState';

import { COLUMN_DEFS, INITIAL_FILTERS } from './lib/helpers';

const PatientPayments = () => {
  const practice = useRecoilValue(currentPractice);
  const columnDefs = useMemo(() => COLUMN_DEFS(practice), [practice]);
  const { id } = useParams();

  return (
    <Allowed requiredPermissions="payments.read" showMessage={true} showIllustration={true}>
      <AgTablePage
        defaultColumns={columnDefs}
        defaultFilters={INITIAL_FILTERS}
        useData={usePayments}
        name="payments"
      />
      {id && <Outlet />}
    </Allowed>
  );
};
export default PatientPayments;
