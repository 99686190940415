import React from 'react';
import Pagination from '../../../../../shared/Pagination/Pagination';
import RowsPerPage from './RowsPerPage';
import AppointmentSearchProvider from './AppointmentSearchProvider';
import Icon from '../../../../../shared/Icon/Icon';
import AllClinicalNotesDateRange from './AllClinicalNotesDateRange';
import TableCounter from '../../../../../shared/Table/TableCounter';

const AllClinicalNotesTable = ({ filters, setFilters, children, count }) => {
  const changePage = ({ selected }) => {
    setFilters((prevState) => ({ ...prevState, page: selected + 1 }));
  };

  const sortColumn = (sortBy) => {
    setFilters((prev) => {
      if (prev.column === sortBy) {
        if (prev.sort === 'asc') {
          return { ...prev, sort: 'desc' };
        } else {
          return { ...prev, sort: 'asc' };
        }
      }
      return { ...prev, column: sortBy, sort: 'asc' };
    });
  };

  return (
    <>
      <div
        className=" min-h-[550px] overflow-x-hidden border-0 !border-t !border-solid !border-neutral-100 bg-neutral-50"
        data-dd-privacy="allow"
        data-public>
        <div className="flex justify-end gap-3 !p-4">
          {/* <Filters filters={filters} setFilters={setFilters} /> */}
          <RowsPerPage filters={filters} setFilters={setFilters} />
          <AllClinicalNotesDateRange filters={filters} setFilters={setFilters} />
          <AppointmentSearchProvider filters={filters} setFilters={setFilters} />
        </div>

        <div className="!p-4 !pt-0">
          <table className="w-full text-left shadow-small">
            <thead>
              <tr className="relative h-[50px] !p-4 shadow-small">
                <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                  <div className="flex w-max gap-1.5">ID</div>
                </th>
                <th
                  onClick={() => sortColumn('appointment.starts_at')}
                  className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                  <div className="flex w-max cursor-pointer gap-1.5 transition-all hover:bg-primary-50">
                    Appointment Date
                    <Icon icon="new-arrow-swap-down" />
                  </div>
                </th>
                <th
                  onClick={() => sortColumn('practitioner.f_name')}
                  className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                  <div className="flex w-max cursor-pointer gap-1.5 transition-all hover:bg-primary-50">
                    Provider
                    <Icon icon="new-arrow-swap-down" className="cursor-pointer" />
                  </div>
                </th>
                <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Services(CPT)</th>
                <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                  Diagnosis(ICD)
                </th>
                <th
                  onClick={() => sortColumn('medical_history.state')}
                  className="!border-0 !p-4 text-sm font-400 !text-primary-900">
                  <div className="flex w-max cursor-pointer gap-1.5 transition-all hover:bg-primary-50">
                    Status
                    <Icon icon="new-arrow-swap-down" className="cursor-pointer" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>{children}</tbody>
          </table>
          <div className="flex flex-wrap items-center justify-between !pt-2 sm:flex-col sm:justify-center">
            <TableCounter page={filters.page} limit={filters.limit} count={count} />
            <Pagination
              onPageChange={changePage}
              page={filters.page}
              perPage={filters.limit}
              totalItems={count}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AllClinicalNotesTable;
