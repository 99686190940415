import React, { useCallback, useMemo } from 'react';

import cs from 'classnames';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import ExportData from 'components/shared/AGTable/ExportData';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const NewFaxesTable = ({
  count = 0,
  loading,
  data = [],
  category,
  defaultColumnDefs,
  gridOptions,
  defaultFilters,
  name,
  headerButtons = null,
  onCellClicked = () => {},
  onExportCSV = () => {}
}) => {
  const { filters, setFilters, colDefs, setPage, page, limit } = useTableContext();

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [data, count, page, limit, onPageChange]);

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <Header title={name}>
        <div className=" mb-1 flex flex-wrap justify-end gap-2 pt-0">
          {headerButtons}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            contentStyle={{ maxHeight: '80%' }}
          />
          <ExportData
            title="Generate Transaction report"
            defaultFilters={defaultFilters}
            filters={filters}
            onGenerateClick={onExportCSV}
          />
        </div>
      </Header>
      <div className={cs('flex h-full flex-col overflow-hidden !pb-[50px]')}>
        <div className="ag-theme-quartz !mb-0 h-full">
          {loading ? (
            <Skeleton count={25} />
          ) : (
            <AGTable
              defaultColDef={defaultColumnDefs}
              columnDef={colDefs}
              gridOptions={gridOptions}
              animateRows={true}
              data={data}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              isRowSelectable={() => true}
              customClassName="ag-grid-interactive"
              enableRangeSelection={true}
              masterDetail={false}
              statusBar={statusBar}
              onCellClicked={onCellClicked}
              reactiveCustomComponents
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default NewFaxesTable;
