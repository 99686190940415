import React from 'react';

export const tooltipContent = {
  pricing: (
    <div className="flex flex-col gap-4">
      <p>
        <b>Services & Products:</b> The green-highlighted amount represents the expected total
        earnings for the service or product, including patient and insurance payments. Select and
        edit services, CPT codes, charges, and allowables. Updates apply to related CMS-1500 claims.
      </p>
      <p>
        <b>Calculate & Payments:</b> Enter insurance details to estimate patient portions before
        treatment, including copay, deductible, coinsurance and self pay amounts.
      </p>
      <p>
        <b>Discounts & Taxes:</b> Add discounts/taxes, verify allocation, and proceed to "New
        Payment".
      </p>
      <p>
        <i>Note: All future payments are estimates; final amounts are determined by the payer.</i>
      </p>
    </div>
  ),
  timeline: (
    <div className="flex flex-col gap-4">
      <p>
        Our fully automated Encounter Navigator is uniquely organized in a linear flow but remains
        flexible. Use this screen to skip steps or mark them as incomplete/partially complete as
        needed.
      </p>
      <p>
        You retain full control and transparency with a timeline overview and logs for every step.
        Review and act on any stages that need attention, all from this screen.
      </p>
    </div>
  )
};
