import React from 'react';
import { useLocation } from 'react-router-dom';
import cs from 'classnames';
import Icon from '../Icon/Icon';
import Transcribe from '../Transcribe/Transcribe';
import Popover from '../Popovers/Popover/NewPopover';
import { ia } from 'lib/helpers/utility';

const Header = ({
  isHeader,
  headerClassName,
  label,
  transcribing,
  icon,
  options,
  isOptional,
  disabled,
  value,
  onChange,
  setDisabled,
  name,
  id,
  required
}) => {
  const location = useLocation();
  const fromCheckin = location?.pathname.includes('/checkin');

  if (!isHeader) return null;

  return (
    <div className={cs('flex min-h-[28px] items-center justify-between pb-1', headerClassName)}>
      <div className="flex w-full items-center gap-x-1">
        {label && (
          <label
            className={cs(
              'm-0 text-sm font-500',
              disabled ? 'text-neutral-400' : 'text-neutral-800'
            )}
            htmlFor={id}>
            {label}
            {required && <span className="text-danger-400">*</span>}
          </label>
        )}
        {isOptional && (
          <span className={cs('text-sm', disabled ? 'text-neutral-300' : 'text-neutral-600')}>
            (Optional)
          </span>
        )}
      </div>
      <div className="ml-auto flex items-center gap-x-1">
        {transcribing && !fromCheckin && (
          <Transcribe
            value={value}
            setValue={(text) =>
              onChange({
                target: {
                  value: text,
                  name
                }
              })
            }
            setDisable={setDisabled}
            disabled={disabled}
          />
        )}
        {typeof icon === 'object' ? (
          icon
        ) : (
          <Icon
            icon={icon}
            color={disabled && 'neutral'}
            size={16}
            className={disabled ? 'cursor-not-allowed' : 'cursor-pointer'}
          />
        )}
        {ia(options) && (
          <Popover
            options={options}
            icon="new-candle"
            position="left"
            buttonClassName="!min-h-6 !min-w-6 !rounded-[3px]"
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
