import React from 'react';
import { Outlet } from 'react-router-dom';
import './PasswordReset.scss';

const AdminPasswordReset = () => {
  return (
    <div className="PasswordReset--overlay">
      <div className="PasswordReset">
        <Outlet />
      </div>
    </div>
  );
};

export default AdminPasswordReset;
