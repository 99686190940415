import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getAptTimelineDetails } from 'api/Appointment';

export const useAptTimelineDetails = ({ params, options }) => {
  const navigate = useNavigate();

  return useQuery(['apt-timeline-details', params], () => getAptTimelineDetails(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5 * 60 * 1000,
    ...options
  });
};
