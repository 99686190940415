import React from 'react';
import { Link } from 'react-router-dom';
import { ia, mString, snakeToTitleCase } from '../utility';
import { appointment_state, appointmentStatuses } from '../../../constants';
import Icon from 'components/shared/Icon/Icon';

export default function formatAppointment({ appointments, currentView }) {
  if (!ia(appointments)) {
    return [];
  }

  const formatted = appointments.map((appointment, index) => {
    const startsAt = new Date(appointment.starts_at);
    const endsAt = new Date(appointment.ends_at);
    const resource_id = ia(appointment?.resources) ? appointment?.resources[0]?.id : null;
    return appointment.formatted
      ? appointment
      : {
          formatted: true,
          id: appointment.id,
          resourceId: currentView === 'provider' ? appointment.practitioner_id : resource_id,
          appointment,
          title: (
            <div data-qa={`appointment-${index}`}>
              {currentView === 'agenda' ? (
                <div className="relative" data-dd-privacy="allow" data-public>
                  <div className="customAgendaEvent">
                    <div>{appointment.patient?.fullName}</div>
                    <div>{appointment?.invoice?.procedures_label}</div>
                    <div>{mString(appointment?.invoice?.balance || 0)}</div>
                    <div>{appointment?.practitioner?.fullName}</div>
                    <div>
                      {appointment?.status === 'cancelled'
                        ? 'Canceled'
                        : appointment?.status?.includes('checked_in')
                          ? appointmentStatuses.find(
                              (status) => status.value === appointment?.status
                            )?.label
                          : snakeToTitleCase(appointment?.status)}
                    </div>
                    {appointment?.state && <div>{appointment_state[appointment?.state]}</div>}
                  </div>
                  <Link
                    className="customAgendaEvent__user"
                    to={`/portal/charts/${appointment.patient.id}`}
                    onClick={(e) => e.stopPropagation()}
                    target="_blank">
                    <Icon icon="user" className="cursor-pointer" />
                  </Link>
                </div>
              ) : (
                <div data-dd-privacy="allow" data-public>
                  {appointment.patient?.fullName} | {appointment?.invoice?.procedures_label} -{' '}
                  {mString(appointment?.invoice?.balance || 0)} |{' '}
                  {appointment?.practitioner?.fullName}
                  {appointment?.status === 'cancelled'
                    ? ' | CANCELED'
                    : appointment?.confirmed
                      ? ' | CONFIRMED'
                      : ''}
                  {appointment?.onboarding_status === 'completed'
                    ? ' | Checked-In'
                    : ' | Not Checked-In'}
                </div>
              )}
            </div>
          ),
          starts_at: startsAt,
          ends_at: endsAt,
          type: appointment.type,
          eventType: 'appointment'
        };
  });
  return formatted;
}
