import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { ia } from '../../../../lib/helpers/utility';
import useWebSocket from '../../../../lib/hooks/useMessageWebSocket';
import state from '../../../state';
import { communicationFilter, currentPractice } from '../../practiceState';
import CommunicationToolbar from './CommunicationToolbar';

const LIMIT = 10;
export default function Communications() {
  const { patient } = useOutletContext();
  const [body, setBody] = useState('');
  const [messages, setMessages] = useState([]);
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState({
    general: true,
    message_sent: false
  });
  const [permissions, setPermissions] = useState({ practice_id: 0 });
  const user = useRecoilValue(state.userState);
  const [communicationFilters, setCommunicationFilters] = useRecoilState(communicationFilter);
  const practice = useRecoilValue(currentPractice);
  const [groupedMessages, setGroupedMessages] = useState([]);
  const [groupedLogs, setGroupedLogs] = useState([]);
  const { id: patientId } = useParams();
  const patientIdRef = useRef(patientId);
  const navigate = useNavigate();

  useEffect(() => {
    getData({ both: true });
  }, [communicationFilters.apply]);

  useEffect(() => {
    getData({ both: true, isScrollable: false });
    patientIdRef.current = patientId;
  }, [patientId]);

  useEffect(() => {
    getData({ isMessages: true });
  }, [communicationFilters.messageApply]);

  useEffect(() => {
    getData({ isLogs: true });
  }, [communicationFilters.logApply]);

  useEffect(() => {
    groupMessages();
  }, [messages]);

  useEffect(() => {
    groupLogs();
  }, [logs]);

  const messageSocketCallback = (event) => {
    const incomingMessage = JSON.parse(event.data);

    if (incomingMessage?.practice_id != practice?.id) return;

    if (Number(patientIdRef.current) !== incomingMessage.sender_id) return;
    setMessages((prev) => {
      return [...prev, { ...incomingMessage }];
    });
  };

  const { ws } = useWebSocket({ handleMessage: messageSocketCallback });

  const groupMessages = () => {
    if (!ia(messages)) return;

    const groupedByDate = messages.reduce((acc, curr) => {
      const date = curr.created_at.split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(curr);
      return acc;
    }, {});

    const messageGroupsByDate = Object.entries(groupedByDate).map(([date, messages]) => {
      let lastMessageType = null;
      let lastMessageIsOutgoing = null;
      const messageGroups = [];

      messages.sort((a, b) => a.created_at - b.created_at);

      let lastMessage = null;
      let isFirstMessage = true;
      let isLastMessage = false;
      let shouldShowLabel = true;
      let isFirstOfType = true;

      messages.forEach((message, index) => {
        if (isFirstOfType || (lastMessage && lastMessage.medium !== message.medium)) {
          shouldShowLabel = true;
          isFirstOfType = false;
        } else if (
          (message.sender_id === patient.id &&
            lastMessage &&
            lastMessage.sender_id !== patient.id) ||
          (message.sender_id === user.user_id &&
            lastMessage &&
            lastMessage.sender_id !== user.user_id) ||
          message.sender_id !== lastMessage.sender_id
        ) {
          shouldShowLabel = true;
        } else {
          shouldShowLabel = false;
        }

        const messageGroup = {
          type: message.medium,
          message,
          shouldShowLabel,
          isFirstMessage,
          isLastMessage,
          isFirstMessageOfPerson: false,
          isLastMessageOfPerson: false
        };

        if (message.sender_id !== patient.id) {
          if (index === 0 || shouldShowLabel) {
            messageGroup.isFirstMessageOfPerson = true;
          }
          if (
            index === messages.length - 1 ||
            messages[index + 1].sender_id !== message.sender_id ||
            messages[index + 1].medium !== message.medium
          ) {
            messageGroup.isLastMessageOfPerson = true;
          }
        } else {
          if (index === 0 || shouldShowLabel) {
            messageGroup.isFirstMessageOfPerson = true;
          }
          if (
            index === messages.length - 1 ||
            messages[index + 1].sender_id !== message.sender_id ||
            messages[index + 1].medium !== message.medium
          ) {
            messageGroup.isLastMessageOfPerson = true;
          }
        }

        messageGroups.push(messageGroup);

        lastMessage = message;
        lastMessageType = message.medium;
        lastMessageIsOutgoing = message.isOutgoing;
        isFirstMessage = false;
        isLastMessage = index === messages.length - 1;
      });

      return { date, messageGroups };
    });

    setGroupedMessages([...messageGroupsByDate]);
  };

  const groupLogs = () => {
    const groups = logs.reduce((groups, log) => {
      const dateWithTimezone = moment
        .tz(log?.delivered_at ?? log?.created_at, practice.timezone)
        .format();
      const date = dateWithTimezone?.split('T')[0];
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(log);
      return groups;
    }, {});

    const groupArrays = Object.keys(groups)
      .map((date) => {
        return {
          date,
          logs: groups[date].sort(
            (x, y) =>
              new Date(x?.delivered_at ?? x?.created_at) -
              new Date(y?.delivered_at ?? y?.created_at)
          )
        };
      })
      .sort((x, y) => new Date(x?.date) - new Date(y?.date));

    setGroupedLogs([...groupArrays]);
  };

  const getData = async ({
    both = false,
    isMessages = false,
    isLogs = false,
    isScrollable = communicationFilters.scroll
  } = {}) => {
    setLoading({ ...loading, general: true });
    let params = {
      limit: LIMIT,
      kind: 'message',
      patient_id: patientId,
      order: communicationFilters.order.value,
      isLogs,
      isMessages
    };

    if (both) {
      params = { ...params, isLogs: true, isMessages: true };
    }

    if (isScrollable) {
      setCommunicationFilters({ ...communicationFilters, scroll: false });
      if (params.isMessages) {
        params = { ...params, offset: messages.length };
      }
      if (params.isLogs) {
        params = { ...params, offset: logs.length };
      }
    }

    if (params.isMessages && ia(communicationFilters.messageType)) {
      params = {
        ...params,
        messageFilters: communicationFilters.messageType.map((type) => type.value)
      };
    }

    if (params.isLogs && ia(communicationFilters.logType)) {
      params = { ...params, logFilters: communicationFilters.logType.map((type) => type.value) };
    }

    try {
      let res = await interimApi('/api/practice/messages/get', params, navigate);
      const { messages: loadedMessages, logs: loadedLogs, practice } = res.data;

      if (practice) {
        setPermissions({ ...permissions, practice_id: practice.id });
      }

      if (ia(loadedMessages)) {
        setMessages(isScrollable ? [...loadedMessages, ...messages] : [...loadedMessages]);
      }

      if (ia(loadedLogs)) {
        setLogs(isScrollable ? [...loadedLogs, ...logs] : [...loadedLogs]);
      }
    } catch (error) {
      console.error('ERROR: ', error);
    }
    setLoading({ ...loading, general: false });
  };

  const handleChange = (event) => {
    setBody(event.target.value);
  };

  const handleSubmit = async (send_text) => {
    if (body.trim().length > 0) {
      setLoading({ ...loading, message_sent: true });
      let params = {
        message: {
          practice_id: permissions.practice_id || practice.id,
          body,
          receiver_id: patient.id,
          kind: 'message'
        },
        send_text
      };

      try {
        let res = await interimApi('/api/messages/send', params, navigate);
        if (res.data.message_id) {
          let new_messages = messages;
          const messageToPush = {
            id: res.data.message_id,
            sender_id: user.user_id,
            practice_id: practice.id,
            practice_name: practice.name,
            receiver_id: patient.id,
            body: params.message.body,
            sender_name: practice.name,
            sender_email: practice.email,
            medium: send_text ? 'sms' : 'web',
            read: false,
            kind: 'message',
            user: { f_name: user?.f_name, l_name: user?.l_name },
            created_at: new Date().toISOString(),
            date: new Date()
          };
          new_messages.push(messageToPush);
          // Send the JSON object as a string on the WebSocket connection
          ws.send(JSON.stringify(messageToPush));
          setMessages([...new_messages]);
          setBody('');
        } else {
          Honeybadger.notify(`There's been an unexpected error, please try again later`);
        }
      } catch (err) {
        Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
      }
      setLoading({ ...loading, message_sent: false });
    }
  };

  return (
    <>
      <CommunicationToolbar />
      <Outlet
        context={{
          patient,
          practice,
          getData,
          messages,
          groupedMessages,
          groupedLogs,
          handleChange,
          handleSubmit,
          user,
          body,
          loading
        }}
      />
    </>
  );
}
