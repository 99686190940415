import { ia, io } from 'lib/helpers/utility';

export const forms = [
  {
    key: 'allergies-form',
    value: 'allergies',
    label: 'Allergies',
    count: 0
  },
  {
    key: 'family-history-form',
    value: 'familyHistory',
    label: 'Family History',
    count: 0
  },
  {
    key: 'medical-history-form',
    value: 'medicalHistory',
    label: 'Medical History',
    count: 0
  },
  {
    key: 'medication-history-form',
    value: 'medicationHistory',
    label: 'Medication History',
    count: 0
  },
  {
    key: 'social-history-form',
    value: 'socialHistory',
    label: 'Social History',
    count: 0
  },
  {
    key: 'surgical-history-form',
    value: 'surgicalHistory',
    label: 'Surgical History',
    count: 0
  },
  {
    key: 'vaccines-form',
    value: 'vaccines',
    label: 'Vaccines',
    count: 0
  },
  {
    key: 'weight-loss-form',
    value: 'weightLossAdministration',
    label: 'Weight Loss Administration',
    count: 0
  }
];

export const getOptionsCounts = (overviewData, options, counts) => {
  try {
    return io(overviewData)
      ? options.map((opt) => {
          let totalCount = 0;

          forms.map(({ value }) => {
            const check = opt.value === 'patient';

            const count =
              overviewData?.[value]?.reduce((prev, curr) => {
                if (curr.createdBy?.isPatient === check) prev++;
                return prev;
              }, 0) ?? 0;

            if (counts) counts.current[opt.value][value] = count;
            totalCount += count;
          });

          opt.count = totalCount;
          return opt;
        })
      : [];
  } catch (error) {
    console.log({ error });
  }
};

export const getFormCount = (formKey, options, overviewData) => {
  try {
    const form = forms.find((form) => form.value === formKey);
    if (form) {
      return io(overviewData) && ia(options)
        ? options.map((opt) => {
            const check = opt.value === 'patient';

            const count =
              overviewData?.[form.value]?.reduce((prev, curr) => {
                if (curr.createdBy?.isPatient === check) prev++;
                return prev;
              }, 0) ?? 0;

            opt.count = count;
            return opt;
          })
        : [];
    } else {
      return [];
    }
  } catch (error) {
    console.log({ error });
  }
};
