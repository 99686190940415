import React from 'react';
import { formatDateAndTimeZ } from '../../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../../practiceState';
import ManagementPopover from './ManagementPopover';
import moment from 'moment';

const Management = ({ open, appointment }) => {
  const practice = useRecoilValue(currentPractice);

  return (
    <div className="flex items-center gap-x-[6px] px-[10px] py-[6px] border-0 !border-solid !border-b-[1px] !border-neutral-100">
      <ManagementPopover />
      <span className="text-primary-500 font-500 text-sm">
        {open
          ? formatDateAndTimeZ(appointment?.starts_at, practice?.timezone)
          : moment(appointment?.starts_at).format('MMM Do')}
      </span>
    </div>
  );
};

export default Management;
