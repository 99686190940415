import React, { useState } from 'react';

const AmbientListeningSidebar = () => {
  const [activeStep] = useState(1);

  const steps = [
    { id: 1, title: 'Ambient Listening' },
    { id: 2, title: 'Transcription' },
    { id: 3, title: 'Edit & Finalize' }
  ];

  return (
    <div className="ambient-sidebar" style={{ padding: '20px' }}>
      <div className="steps-container">
        {steps.map((step) => (
          <div
            key={step.id}
            className="step-item"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '20px',
              position: 'relative'
            }}>
            <div
              style={{
                width: '4px',
                height: '100%',
                backgroundColor: activeStep === step.id ? '#007bff' : '#e0e0e0',
                position: 'absolute',
                left: 0,
                transition: 'background-color 0.3s'
              }}
            />
            <div style={{ marginLeft: '20px' }}>
              <span
                style={{
                  fontWeight: activeStep === step.id ? 'bold' : 'normal',
                  color: activeStep === step.id ? '#007bff' : '#666'
                }}>
                {step.title}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AmbientListeningSidebar;
