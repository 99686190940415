import { Popover } from '@headlessui/react';
import cs from 'classnames';
import SearchPatients from 'components/Search/SearchPatients/SearchPatients';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';
import moment from 'moment-timezone';
import React from 'react';
import { Calendar } from 'react-date-range';
import { patientReasons } from '../../../../../constants';
import { formatDate } from '../../../../../lib/helpers/utility';

const OptionPopover = ({
  type,
  timezone,
  reason,
  setReason,
  deceaseDate,
  setDeceaseDate,
  isChecked,
  setIsChecked,
  setSelectedDupe,
  patient
}) => {
  const onCalendarPick = (date = moment()) => {
    setDeceaseDate(moment.tz(date, timezone).add('12', 'h'));
  };

  return (
    <div className="!mt-8">
      {type === 'deceased' && (
        <Popover>
          {({ open, close }) => (
            <>
              <label className="mb-[6px] text-sm text-neutral-900">Date of death</label>
              <Popover.Button className="relative flex w-full items-center justify-center rounded-lg border border-solid border-neutral-200 !p-0 focus:!border-primary-500 focus:!shadow-[0px_0px_0px_2px_#CBF0FD]">
                <input
                  className="w-full cursor-pointer rounded-lg border-none !px-3 !py-2 text-sm text-neutral-800 placeholder-neutral-800"
                  placeholder={
                    deceaseDate ? `${formatDate(deceaseDate, timezone)}` : 'Unknown Date'
                  }
                />
                <Icon icon="new-calendar-gray" className="absolute right-3 cursor-pointer" />
              </Popover.Button>
              <div className="!mt-3 text-sm text-neutral-900">
                <Checkbox
                  labelTextColor="!text-neutral-600"
                  label="Date of death is unknown"
                  onChange={({ target: { checked } }) =>
                    checked ? setDeceaseDate(null) : onCalendarPick()
                  }
                  isChecked={deceaseDate === null}
                  color="blue"
                />
              </div>

              {open && (
                <div className="absolute left-0 top-0 h-full w-full bg-black opacity-50"></div>
              )}

              <Popover.Panel className="absolute left-1/2 top-1/2 z-10 flex -translate-x-1/2 -translate-y-1/2 shadow-lg">
                <Calendar
                  onChange={(date) => {
                    onCalendarPick(date);
                    close();
                  }}
                  className="text-base"
                  date={new Date()}
                  showDateDisplay
                />
              </Popover.Panel>
            </>
          )}
        </Popover>
      )}
      {type === 'deleted' && (
        <div className="w-full">
          <label className="mb-[6px] text-sm text-neutral-900 transition-all">Reason</label>
          <Select
            onChange={(e) => setReason(e?.value || null)}
            value={patientReasons.filter((p) => reason === p.value)}
            options={patientReasons}
            className="w-full"
            placeholder="Type and select a reason"
            width="100%"
            backgroundColor="bg-white"
          />
        </div>
      )}
      {type === 'merge' && (
        <div className="w-full">
          <SearchPatients
            isImage
            required
            width="452px"
            label="Select the duplicate patient to merge into the current patient."
            handleClick={(e) => {
              if (e?.value) {
                setSelectedDupe(e?.value);
              } else {
                setSelectedDupe(null);
              }
            }}
            className={cs(
              'rounded-md border-neutral-100 bg-white shadow-[0px_2px_4px_0px_rgba(27,33,35,0.04)] focus-within:outline focus-within:!outline-2',
              'focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]'
            )}
            exclude={[patient?.id]}
          />
          <Checkbox
            label="I acknowledge that this action is irreversible."
            className="pt-6"
            handleClick={setIsChecked}
            isChecked={isChecked}
          />
        </div>
      )}
    </div>
  );
};

export default OptionPopover;
