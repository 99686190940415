import React from 'react';
import { getClassNamesByPercentage, getPercentage } from '../lib/helper';
import cs from 'classnames';
import { types } from '../lib/constants';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const ListView = ({ options, data, total }) => {
  return (
    <div className="h-full px-4 py-3">
      <div className="flex flex-col gap-4">
        {options?.data?.map((item, index) => (
          <div className="flex justify-between rounded-md bg-primary-10 px-4 py-3" key={index}>
            <div className="flex items-center gap-2">
              <p className="font-sm font-medium text-primary-900">{item?.name}</p>
            </div>
            <div className="flex justify-between gap-2">
              {data && total && types && (
                <div
                  className={cs(
                    'flex gap-1 rounded-full px-2',
                    getClassNamesByPercentage(types, item?.name, data, total)
                  )}>
                  <p className="tex-sm font-medium">
                    {getPercentage(types, item?.name, data, total).toFixed()}%
                  </p>
                </div>
              )}
              <p className="text-sm font-400 text-primary-900">{item?.value}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withErrorBoundary(ListView);
