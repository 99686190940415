import React from 'react';
import Button from '../../shared/Buttons/Button';
import { useNavigate } from 'react-router-dom';

const UserActivateSuccess = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="w-full text-3xl font-500 text-primary-900">Success!</div>
      <p className="mt-10 w-full text-neutral-700">Registration completed!</p>
      <Button
        className={'mt-10 w-full'}
        text={'To Account'}
        color="primary"
        onClick={() => navigate('/myportal')}
      />
    </div>
  );
};

export default UserActivateSuccess;
