import React from 'react';

import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';

const ErrorModal = ({ id, errors, onClose, selectedItem }) => {
  const errorMessages = errors[id];

  const goToSuperbillHandler = () => {
    window.open(`/portal/charts/${selectedItem?.patient_id}/billing/superbill/${id}`, '_blank');
  };

  return (
    <Modal
      isOpen={true}
      handleClose={onClose}
      title={
        <div className="flex items-center gap-2">
          <Icon icon="new-info-circle" color="danger" size={24} />
          {`Superbill #${id} Errors`}
        </div>
      }
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={onClose}
            id="closeServiceModalBtn"
          />
          <Button
            onClick={goToSuperbillHandler}
            text="Go to superbill"
            id="createUpdateServiceBtn"
            data-qa="create-update-service-btn"
          />
        </div>
      }>
      <ul className="flex list-disc flex-col gap-2 pl-4">
        {errorMessages.map((error) => (
          <li key={error} className="text-sm text-red-500">
            {error}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default ErrorModal;
