import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import RequestAssistance from 'components/Kiosk/components/RequestAssistance';
import Button from 'components/shared/Buttons/Button';
import PatientCheckinLogin from './Login';
import Sidebar from './Sidebar';
import './style.scss';
import Icon from 'components/shared/Icon/Icon';
import { requestApi } from 'api/Api';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import AutoIdle from 'components/Kiosk/components/AutoIdle';
import { useRecoilValue } from 'recoil';
import { userState } from './state';
import { useCheckinUser } from 'lib/hooks/queries/user/useCheckinUser';

const DEFAULT_CONTINUE_TEXT = 'Save & Continue';
const DEFAULT_CONTINUE_COLOR = 'primary';

const CheckinSteps = ({
  checkinBackgroundPNG,
  loginRequest,
  setLoginRequest,
  device,
  practiceLogo,
  steps,
  currentStepName,
  currentStepNumber,
  updateCompleteStep,
  updateSteps,
  isEverythingCompleted,
  navigateToIncompleted,
  stepObject,
  setSteps,
  checkinButton,
  checkinInsuranceEnabled,
  showButton,
  handleExternalSubmit,
  appointmentId,
  handleBack,
  customOnSuccess,
  practice,
  numCompleted,
  updateChoices,
  isInstantPacket,
  currentStepForm,
  reviewSteps
}) => {
  const [show, setShow] = useState(false);
  const [progress, setProgress] = useState(0);
  const [disableContinue, setDisableContinue] = useState(false);
  const [continueText, setContinueText] = useState(DEFAULT_CONTINUE_TEXT);
  const [continueColor, setContinueColor] = useState(DEFAULT_CONTINUE_COLOR);
  const [kiosk, setKiosk] = useState(false);
  const [fakeModal, setFakeModal] = useState(null);
  const userSt = useRecoilValue(userState);
  const { data } = useCheckinUser({});
  const [hipaaSign, setHipaaSign] = useState();

  const [responses, setResponses] = useState({});

  const [cid, setCid] = useState(appointmentId);
  const { pathname } = useLocation();
  const isPreAuth = pathname.includes('/instant-packet/pre-auth');
  const isHipaa = pathname.includes('/hipaa');
  const isPay = pathname.includes('/pay');

  const defaultContinueText = () => {
    setContinueText(DEFAULT_CONTINUE_TEXT);
  };

  const defaultContinueColor = () => {
    setContinueColor(DEFAULT_CONTINUE_COLOR);
  };

  useEffect(() => {
    requestApi({
      url: '/api/kiosk/token',
      onSuccess: (data) => {
        setKiosk(data.token);
        setCid(data.cid);
      }
    });
  }, []);

  useEffect(() => {
    if (numCompleted) setProgress(window.screen.availWidth * (numCompleted / steps.length));
  }, [window.screen.availWidth, numCompleted, steps]);

  return (
    <div className={cs('h-[100svh] overflow-hidden bg-transparent ', fakeModal && '!m-0 !p-0')}>
      {kiosk && <AutoIdle />}

      {loginRequest && (
        <div className="relative z-20 flex h-full w-full overflow-hidden">
          <PatientCheckinLogin setLoginRequest={setLoginRequest} />
        </div>
      )}
      {!loginRequest && (
        <div className="relative z-20 flex h-full overflow-hidden">
          {(device !== 'mobile' || show) && (
            <div className="h-full max-w-[360px] border-0 !border-l !border-solid border-white bg-transparent">
              <Sidebar
                kiosk={kiosk}
                steps={steps}
                practiceLogo={practiceLogo}
                device={device}
                setShow={setShow}
                disableContinue={disableContinue}
              />
            </div>
          )}
          <div
            className="flex h-full w-full flex-col overflow-hidden bg-neutral-50"
            onClick={() => {
              show && setShow(false);
            }}>
            <div
              className={cs(
                'sticky top-0 z-20 w-full bg-white text-2xl font-600 shadow-[0px_2px_16px_0px_#0000000F]',
                device !== 'mobile' && 'border-0 !border-b border-solid border-neutral-100'
              )}
              onClick={() => {
                setShow((ps) => !ps);
              }}>
              <div className="!m-2 flex flex-row">
                {device === 'mobile' ? (
                  <div className="flex cursor-pointer items-center">
                    <Icon icon="new-hamburger" className="!mr-2 cursor-pointer" />
                    <div className="flex aspect-square h-[2.2rem] w-[2.2rem] flex-row items-center justify-center rounded-full border-2 border-solid border-primary-100 text-center text-xl text-primary-600">{`${
                      currentStepNumber || 1
                    }`}</div>

                    <div className="-mb-[0.1rem] ml-[0.5rem] flex flex-col items-start justify-center">
                      <div className="-mb-[0.3rem] text-xs font-400 text-neutral-600">
                        STEP {currentStepNumber} / {steps.length}
                      </div>

                      <div className="w-fit text-lg font-600 text-primary-500">
                        {currentStepName}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className={cs(
                      ' my-auto ml-4 flex items-center',
                      device === 'tablet-sm' ? 'h-[38px]' : '!h-[40px]'
                    )}>
                    <p className="text-lg !font-400 text-neutral-800">{currentStepName}</p>
                  </div>
                )}

                <div className="ml-auto mr-1 flex items-center gap-2">
                  {kiosk && <RequestAssistance className="!mr-2" page={currentStepName} />}
                  {device && !isPreAuth && !fakeModal && (
                    <>
                      {handleBack && currentStepNumber != 1 && (
                        <Button
                          color="neutral"
                          outlined
                          text="Back"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBack();
                          }}
                          data-qa="wizard-back-btn"
                        />
                      )}

                      <Tippy
                        className="tippy-dark"
                        disabled={!disableContinue}
                        content={
                          isHipaa
                            ? 'Please sign the HIPAA Consent form'
                            : isPay
                              ? 'Payment failed'
                              : ' '
                        }>
                        <div className="flex">
                          <Button
                            text={continueText}
                            color={!disableContinue ? continueColor : 'neutral'}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleExternalSubmit();
                            }}
                            data-qa="continue-primary-btn"
                            disabled={disableContinue}
                          />
                        </div>
                      </Tippy>
                    </>
                  )}

                  {fakeModal && (
                    <Button
                      color="neutral"
                      outlined
                      text="Close"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFakeModal(null);
                      }}
                      data-qa="wizard-back-btn"
                    />
                  )}
                </div>
              </div>
            </div>

            {device === 'mobile' && (
              <div className="flex h-[5px] flex-row overflow-hidden bg-neutral-200">
                <div
                  className={`h-full bg-primary-500 ${
                    (stepObject.numCompleted !== steps.length && 'rounded-r-full') || ''
                  }`}
                  style={{ width: `${progress}px` }}></div>
              </div>
            )}

            <div
              className={cs(
                'checkin-steps__outlet !ml-0 flex w-full justify-center overflow-y-auto bg-[#FAFDFF]',
                '!h-full',
                fakeModal && '!m-0 !p-0'
              )}>
              <div className={cs('w-full', pathname.includes('payment') ? '' : 'max-w-[800px]')}>
                <Outlet
                  context={{
                    kiosk,
                    updateCompleteStep,
                    updateSteps,
                    isEverythingCompleted,
                    navigateToIncompleted,
                    steps,
                    stepObject,
                    setSteps,
                    checkinButton,
                    checkinInsuranceEnabled,
                    setLoginRequest,
                    appointmentId,
                    customOnSuccess,
                    practiceLogo,
                    practice,
                    updateChoices,
                    setDisableContinue,
                    cid,
                    setContinueText,
                    defaultContinueText,
                    setContinueColor,
                    defaultContinueColor,
                    handleBack,
                    fakeModal,
                    setFakeModal,
                    handleExternalSubmit,
                    isInstantPacket,
                    responses,
                    setResponses,
                    reviewSteps,
                    hipaaSign,
                    setHipaaSign,
                    user: { ...userSt, ...(data?.user || {}), address_object: data?.address }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withErrorBoundary(CheckinSteps);
