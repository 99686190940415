import cs from 'classnames';

import { highlightText } from 'lib/helpers/utility';

import Icon from '../Icon/Icon';

const Info = ({
  info,
  infoHighlights = [],
  infoIcon = 'new-myscribe-stars',
  infoIconSize = 24,
  error
}) => {
  if (!info) return null;

  return (
    <div
      className={cs(
        'flex items-start z-20 gap-x-1 px-4 py-2 border-solid border-b-0 border-x-0 border-t leading-[22px] font-400 text-sm',
        error
          ? 'bg-danger-50 border-danger-200 text-danger-600'
          : 'bg-white-to-primary border-primary-200 text-primary-600'
      )}>
      {infoIcon && (
        <Icon
          size={infoIconSize}
          icon={infoIcon}
          color={error ? 'danger' : 'primary'}
          className={cs(infoIcon === 'new-myscribe-stars' && 'animate-pulse')}
        />
      )}
      <div>{highlightText(info, infoHighlights)}</div>
    </div>
  );
};

export default Info;
