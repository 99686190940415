import React, { useState } from 'react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { usePatientSuperbill } from 'lib/hooks/queries/superbill/usePatientSuperbill';
import useModal from 'lib/hooks/useModal';

import ButtonActions from 'components/practice/BeyondBilling/Superbill/components/ButtonActions';
import ErrorModal from 'components/practice/BeyondBilling/Superbill/components/ErrorModal';
import { SUPERBILL_SWITCH_ITEMS } from 'components/practice/BeyondBilling/Superbill/lib/superbillTableColumns';
import { currentPractice } from 'components/practice/practiceState';
import { rowClassRules } from 'components/shared/AGTable/lib/helpers';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import SuperbillModal from '../../SuperBillPage/SuperbillModal';

import { defaultFilters, superbillTableColumns } from './lib';

export default function Superbills({ patient, superbill_id }) {
  const [superbillId, setSuperbillId] = useState(superbill_id);
  const [selectedSuperbill, setSelectedSuperbill] = useState(null);
  const navigate = useNavigate();
  const { isOpen, openModal, closeModal } = useModal();

  const { claimId } = useParams();
  const chartContext = useOutletContext() || {};

  const patientId = chartContext?.patient?.id || patient?.id;

  const { superbill } = useParams();
  const practice = useRecoilValue(currentPractice);

  if (superbillId)
    return (
      <SuperbillModal
        patientId={patient?.id}
        superbill_id={superbillId}
        setSuperbillId={setSuperbillId}
        handleClose={() => setSuperbillId(null)}
      />
    );
  if (superbill) return <Outlet />;

  const cols = superbillTableColumns(practice.timezone);

  const onRowClicked = (event) => {
    if (superbill_id) {
      setSuperbillId(event.data.id);
      return;
    }
    setSelectedSuperbill(event?.data);

    if (event.data.error) {
      openModal();
      return;
    }

    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/portal/charts/${patientId}/billing/superbill/${event.data.id}`);
    }
  };

  return (
    <>
      <AgTablePage
        ModalComponent={({ errors }) => {
          return (
            <ErrorModal
              superbill={selectedSuperbill}
              isOpen={isOpen}
              handleClose={closeModal}
              errors={errors?.[selectedSuperbill?.claim_id]}
            />
          );
        }}
        defaultColumns={cols}
        defaultFilters={defaultFilters}
        useData={usePatientSuperbill}
        name="superbill"
        rowStyle={{ cursor: 'pointer' }}
        actions={ButtonActions}
        switchItems={SUPERBILL_SWITCH_ITEMS}
        onRowClicked={onRowClicked}
        noNewButton
        rowClassRules={rowClassRules}
        queryParams={{ patientId }}
        queryOptions={{ enabled: !!patientId }}
        noTitle
      />
      {claimId && <Outlet />}
    </>
  );
}
