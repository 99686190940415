import { Honeybadger } from '@honeybadger-io/react';
import { userState as user_state } from 'components/state';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../api/Api';

const useDisplaySettings = () => {
  const [userState, setUserState] = useRecoilState(user_state);
  const [loading, setLoading] = useState(false);

  const saveDisplaySettings = async (updatedSettings) => {
    setLoading(true);
    try {
      let params = {
        practitioner_id: userState.id,
        fields: {
          display_settings: { ...updatedSettings }
        }
      };
      setUserState({ ...userState, display_settings: updatedSettings });
      requestApi({ url: '/api/practice/settings/practice_user/update', params });
    } catch (error) {
      Honeybadger.notify(`Clinical note autosave settings - catch error - ${error}`);
    } finally {
      setLoading(false);
    }
  };

  return { userState, saveDisplaySettings, loading };
};

export default useDisplaySettings;
