import React from 'react';

import { ia } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import ErrorRequirements from 'components/shared/Error/Requirements';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import SSOKindNames from 'components/shared/ePrescribe/SSOKindNames';
import { patient as kind } from 'components/shared/ePrescribe/SSOKinds';

const VeradigmMedication = ({
  setShowVeradigmMedication = () => {},
  requirements = [],
  enableEnrollPatient,
  enableEnrollPatientConfig,
  enrollPatient = () => {},
  getSSO = () => {},
  show
}) => {
  const handleCloseModal = () => {
    setShowVeradigmMedication(false);
  };

  const renderContent = () => {
    if (ia(requirements)) {
      return (
        <>
          <Confirm
            handleOpen={true}
            handleClose={handleCloseModal}
            title="ePrescribe Requirements Not Met"
            confirmText="Close"
            icon="new-eprescribe-v2"
            iconColor="danger"
            variant="danger"
            footer={
              <Button
                outlined
                color="neutral"
                className="ml-auto"
                onClick={handleCloseModal}
                text="Close"
              />
            }>
            <ErrorRequirements
              requirements={requirements}
              title="Please fulfill the following requirements to enable prescribing for this patient:"
            />
          </Confirm>
        </>
      );
    } else if (enableEnrollPatient && enableEnrollPatientConfig) {
      return (
        <>
          <Confirm
            handleOpen={true}
            handleClose={handleCloseModal}
            title="ePrescribe Actions"
            icon="new-eprescribe-v2"
            footer={
              <Button
                outlined
                color="neutral"
                className="ml-auto"
                onClick={handleCloseModal}
                text="Close"
              />
            }>
            <div className="flex mt-3 gap-4">
              {enableEnrollPatient && enableEnrollPatientConfig && (
                <Button
                  onClick={enrollPatient}
                  color="success"
                  loading={show?.loading}
                  disabled={show?.loading}
                  text="Enroll Patient"
                />
              )}
              {kind && (
                <Button
                  onClick={getSSO}
                  color="primary"
                  loading={show?.loading}
                  disabled={show?.loading}
                  text={SSOKindNames[kind]}
                />
              )}
            </div>
          </Confirm>
        </>
      );
    }
    return null;
  };

  return <>{renderContent()}</>;
};
export default VeradigmMedication;
