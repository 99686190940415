import { requestApi } from './Api';

const getServiceCategories = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/practice/services/category/get',
    params,
    onError: () => {}
  });

const createServiceCategory = async (navigate, params, onSuccess, onError) =>
  await requestApi({
    navigate,
    url: 'api/practice/services/category/create',
    params,
    onSuccess,
    onError
  });

const updateServiceCategory = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/practice/services/category/update',
    params,
    onError: () => {}
  });

const deleteServiceCategory = async (navigate, params, onSuccess, onError) =>
  await requestApi({
    navigate,
    url: 'api/practice/services/category/delete',
    params,
    onSuccess,
    onError
  });

export {
  getServiceCategories,
  createServiceCategory,
  updateServiceCategory,
  deleteServiceCategory
};
