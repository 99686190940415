import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import { ia, iaRa } from 'lib/helpers/utility';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import debounce from 'lodash.debounce';
import React, { useMemo, useRef } from 'react';

const AddToTodaysNote = ({ type = null, finalNoteOptions = [], onChange = () => null }) => {
  const { userState, saveDisplaySettings } = useDisplaySettings();
  const [selectedValues, setSelectedValues] = React.useState(
    iaRa(userState?.display_settings?.clinicalNote?.todaysNote?.[type]?.map((row) => row?.value))
  );
  const pendingChangesRef = useRef([]);

  const updateDisplaySettings = (options) => {
    saveDisplaySettings({
      ...userState.display_settings,
      clinicalNote: {
        ...userState.display_settings.clinicalNote,
        todaysNote: {
          ...userState.display_settings.clinicalNote?.todaysNote,
          [type]: options
        }
      }
    });
  };

  const debouncedUpdateDisplaySettings = useMemo(
    () =>
      debounce(() => {
        updateDisplaySettings(pendingChangesRef.current);
        pendingChangesRef.current = []; // Clear pending changes
      }, 500),
    []
  );

  const handleSelectOption = (option) => {
    const safeSelectedValues = iaRa(selectedValues);
    const optionValue = option?.value;
    const newSelectedValues = safeSelectedValues.includes(optionValue)
      ? safeSelectedValues.filter((value) => value !== optionValue)
      : [...safeSelectedValues, optionValue];

    setSelectedValues(newSelectedValues);

    const selectedOptions = finalNoteOptions.filter((option) =>
      newSelectedValues.includes(option?.value)
    );

    pendingChangesRef.current = selectedOptions;

    onChange({ type, options: selectedOptions });
    debouncedUpdateDisplaySettings(newSelectedValues);
  };

  if (!ia(finalNoteOptions)) return null;

  return (
    <div className="flex items-center gap-2 border border-l-0 border-r-0 border-solid border-neutral-100 !px-3 !py-[14px]">
      <div className="flex items-center gap-2">
        <Icon icon="new-info" color="neutral" />
        <span className="text-sm font-500 text-neutral-800">Add to today's note:</span>
      </div>

      <div className="flex flex-wrap items-center gap-2">
        {finalNoteOptions.map((option, index) => {
          return (
            <Checkbox
              key={index}
              label={option?.label}
              checked={selectedValues.includes(option?.value)}
              onChange={() => handleSelectOption(option)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default AddToTodaysNote;
