import { useFormikContext } from 'formik';

import Icon from 'components/shared/Icon/Icon';

const RemoveTestCellRenderer = ({ data, node, api }) => {
  const { setFieldValue, values } = useFormikContext();

  const removeHandler = () => {
    node.setExpanded(false);

    const rowData = values.tests;

    const index = rowData.findIndex((item) => item.id === data.id);
    if (index > -1) {
      rowData.splice(index, 1);
      setFieldValue('tests', rowData);
      api.setGridOption('rowData', rowData);
    }
  };

  return (
    <div className="flex items-center h-full">
      <Icon icon="new-close-square" size={24} onClick={removeHandler} />
    </div>
  );
};

export default RemoveTestCellRenderer;
