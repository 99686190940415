import { ia, iaRa } from '../../../../../../../../lib/helpers/utility';
import { HPMacro, SOAPMacro } from './constants';

export const findNoteMacroType = (type) => {
  switch (type) {
    case 'hp':
      return HPMacro;
    case 'soap':
      return SOAPMacro;
    default:
      return [];
  }
};

export const createNoteMacroObject = ({
  name,
  type,
  formType,
  currentMacroFields,
  advancedHP,
  advancedSOAP,
  advancedCustomNote,
  clinicalNote
}) => {
  const clinicalNoteObject = {};
  let advForms = {};

  for (const key of currentMacroFields) {
    clinicalNoteObject[key] = clinicalNote?.[key];
  }

  if (['hp', 'intake'].includes(formType)) {
    advForms = {
      ...advForms,
      ...(ia(advancedHP) && { advancedHP })
    };
  }

  if (['soap', 'progress'].includes(formType)) {
    advForms = {
      ...advForms,
      ...(ia(advancedSOAP) && { advancedSOAP })
    };
  }

  const customNoteTypes = Object.keys(advancedCustomNote || {});
  if (iaRa(customNoteTypes).includes(formType)) {
    const advancedForms = advancedCustomNote?.[formType];
    advForms = {
      ...advForms,
      ...(ia(advancedForms) && { advancedCustomNote: advancedForms }),
      customNoteType: formType
    };
  }

  return {
    name,
    body: JSON.stringify({
      clinicalNote: clinicalNoteObject,
      ...advForms
    }),
    type
  };
};
