import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Switch from 'components/shared/Switch/Switch';
import Highlight from 'components/shared/HighlightData/HighlightData';
import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';
import Dependencies from '../../../EditRole/components/EditPermissions/Dependencies';
import {
  getFieldValue,
  setFieldValue,
  getDependents
} from '../../../EditRole/components/EditPermissions/lib/helper';
import Dependents from '../../../EditRole/components/EditPermissions/Dependents';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const ManagePermission = ({ permission, highlight, isFeature, permissions }) => {
  const { title, description, actions } = permission;
  const [enabledAll, setEnabledAll] = useState(false);
  const [showDisableModal, setShowDisableModal] = useState(false);
  const [actionDependents, setActionDependents] = useState({});
  const [dependents, setDependents] = useState([]);
  const { formik } = useNewRoleContext();

  const enableAll = () => {
    if (formik.values.permissions !== null) {
      actions.forEach((action) => {
        const fieldValue = action.field;
        if (!getFieldValue(formik, fieldValue)) {
          setFieldValue(formik, fieldValue, true);
        }
        const dependencies = action.dependencies;
        if (dependencies?.length > 0) {
          for (const dependency of dependencies) {
            if (!getFieldValue(formik, dependency)) setFieldValue(formik, dependency, true);
          }
        }
      });
      setEnabledAll(true);
    }
  };

  const disableAll = () => {
    let hasDependents = false;
    if (formik.values.permissions !== null) {
      actions.forEach((action) => {
        const fieldValue = action.field;
        const actionDependents = getDependents(permissions, fieldValue);
        if (actionDependents?.length > 0) {
          for (const actionDependent of actionDependents) {
            if (getFieldValue(formik, actionDependent)) {
              hasDependents = true;
              continue;
            }
          }
        }
      });

      if (!hasDependents) {
        actions.forEach((action) => {
          const fieldValue = action.field;
          setFieldValue(formik, fieldValue, false);
        });
        setEnabledAll(false);
      } else {
        let newDependents = [];
        let newActionDependents = {};
        actions.forEach((action) => {
          const fieldValue = action.field;
          const actionDependents = getDependents(permissions, fieldValue);
          if (actionDependents?.length > 0) {
            for (const actionDependant of actionDependents) {
              if (getFieldValue(formik, actionDependant)) {
                newDependents.push(actionDependant);
                if (actionDependents[fieldValue].length === 0) {
                  newActionDependents[fieldValue] = [actionDependant];
                } else {
                  newActionDependents[fieldValue].push(actionDependant);
                }
              }
            }
          }
        });
        setActionDependents(...actionDependents, ...newActionDependents);
        setDependents(newDependents);
        setShowDisableModal(true);
      }
    }
  };

  const turnOffDependents = () => {
    actions.forEach((action) => {
      const fieldValue = action.field;
      setFieldValue(formik, fieldValue, false);
    });
    dependents.forEach((dependent) => {
      setFieldValue(formik, dependent, false);
    });
    setShowDisableModal(false);
    setDependents([]);
    setEnabledAll(false);
  };

  const handleSwitchChange = (action, value) => {
    const fieldValue = action.field;
    const actionDependencies = action.dependencies?.filter(
      (actionDependency) => !getFieldValue(formik, actionDependency)
    );
    if (value) {
      setFieldValue(formik, fieldValue, true);
      if (actionDependencies?.length > 0) {
        for (const actionDependency of actionDependencies) {
          setFieldValue(formik, actionDependency, true);
        }
      }
    } else {
      const newActionDependents = getDependents(permissions, fieldValue).filter((dependents) =>
        getFieldValue(formik, dependents)
      );
      if (newActionDependents?.length > 0) {
        setDependents(newActionDependents);
        setActionDependents({
          ...actionDependents,
          [fieldValue]: newActionDependents
        });
        setShowDisableModal(true);
      } else {
        setFieldValue(formik, fieldValue, false);
      }
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-start gap-4 self-stretch rounded-md bg-white p-4">
        <div className="flex w-full justify-between bg-white">
          <div>
            <p className="flex text-sm font-semibold leading-snug text-neutral-800">
              <Highlight text={title} highlight={highlight} />
              {isFeature && (
                <Tippy
                  placement="top-start"
                  className="tippy-dark"
                  content={
                    <p className="relative px-1 pb-1 text-[12px] font-500 text-white">
                      This is a feature permission.
                      {}
                    </p>
                  }>
                  <div className="ml-1 flex">
                    <Icon icon="new-info-v2" size={18} />
                  </div>
                </Tippy>
              )}
            </p>
            <p className="text-sm font-normal leading-snug text-neutral-700">
              <Highlight text={description} highlight={highlight} />
            </p>
          </div>
          <Button
            text={!enabledAll ? 'Enable all' : 'Disable all'}
            transparent
            onClick={() => {
              if (!enabledAll) {
                enableAll();
              } else {
                disableAll();
              }
            }}
          />
        </div>
        <div className="flex w-full flex-col gap-0.5">
          {actions.map((action, index) => {
            const fieldValue = action.field;
            return (
              <>
                <div
                  key={index}
                  className={`inline-flex items-center justify-between px-4 py-2 ${getFieldValue(formik, fieldValue) ? '' : 'bg-neutral-50'}`}>
                  <div>
                    <p className="text-sm font-medium leading-snug text-primary-900">
                      <Highlight text={action.title} highlight={highlight} />
                    </p>
                    <p className="text-xs font-normal leading-tight text-neutral-500">
                      <Highlight text={action.description} highlight={highlight} />
                    </p>
                  </div>
                  <div className="relative h-[18px] w-[30px] rounded-[15px] bg-neutral-100">
                    <Switch
                      checked={getFieldValue(formik, fieldValue)}
                      onChange={(value) => {
                        handleSwitchChange(action, value);
                      }}
                    />
                  </div>
                </div>
                {action.dependencies?.length > 0 && getFieldValue(formik, fieldValue) && (
                  <Dependencies
                    permissions={permissions}
                    formik={formik}
                    dependencies={action.dependencies}
                  />
                )}
                {actionDependents[fieldValue]?.length > 0 && !getFieldValue(formik, fieldValue) && (
                  <Dependencies
                    permissions={permissions}
                    formik={formik}
                    dependencies={actionDependents[fieldValue]}
                    turnedOn={false}
                  />
                )}
              </>
            );
          })}
        </div>
      </div>
      {showDisableModal && (
        <Confirm
          handleOpen={showDisableModal}
          handleClose={() => setShowDisableModal(false)}
          handleContinue={turnOffDependents}
          icon="new-patients"
          iconColor="danger"
          primaryBtnTxt="Turn off"
          title="Warning"
          message="To continue, you need to turn off all the permissions listed below. The actions that follow depend on the permission being disabled."
          variant="danger">
          <Dependents permissions={permissions} dependents={dependents} />
        </Confirm>
      )}
    </>
  );
};

export default ManagePermission;
