import { withErrorBoundary } from 'components/shared/Error/Boundary';
import moment from 'moment';
import React from 'react';

const SuperbillFooter = () => {
  return (
    <label className="p-4" id="superbill-page-footer">
      &copy; CPT copyright {moment().add(-1, 'y').format('YYYY')} American Medical Association. All
      rights reserved.
    </label>
  );
};

export default withErrorBoundary(SuperbillFooter);
