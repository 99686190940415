import React from 'react';
import { useFormikContext } from 'formik';
import { procedureTypes } from 'constants';
import { permissions } from 'components/state';
import { Capitalize, ia } from 'lib/helpers/utility';
import {
  handleChangePrice,
  handleChangePriceProcedures,
  handleChangeQuantity,
  handleChangeUnitPrice,
  handleProcedureType,
  removeItem
} from '../lib/appointmentHelpers';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import Tippy from '@tippyjs/react';
import { shouldDisableProcedureProduct } from '../lib/shouldDisableProcedureProduct';
import { useRecoilValue } from 'recoil';
import Allowed from 'components/shared/Permissions/Allowed';

const SelectedItems = ({
  selectedItems,
  servicesObject,
  type,
  label,
  values,
  setFieldValue,
  list,
  isClinicalNoteLocked = false
}) => {
  const { initialValues } = useFormikContext();
  const hasPaymentPermission = useRecoilValue(permissions)?.payments?.read;

  const originalProceduresProducts = {
    products: initialValues?.invoice?.products || [],
    procedures: initialValues?.invoice?.procedures || []
  };
  const ERAPosted = selectedItems?.some?.((v) => v?.primary);
  const tooltipMessage = ERAPosted
    ? 'The service cannot be removed because the ERA has already been posted.'
    : `${type ? Capitalize(type) : 'Item'} cannot be removed because the clinical note is locked.`;

  return (
    <div className="mb-4 h-[218px] overflow-auto pb-2 pt-4">
      {ia(selectedItems) && (
        <>
          {selectedItems.map((item, i) => {
            const {
              id,
              name,
              quantity,
              total_cost_cents,
              invoiceId,
              pt_balance,
              procedure_code,
              type: itemType,
              modified
            } = item || {};

            const shouldDeleteBeDisabled = shouldDisableProcedureProduct({
              isClinicalNoteLocked,
              originalProceduresProducts,
              productId: id,
              procedureCode: procedure_code,
              type
            });

            const procedureOptions = (
              procedure_code ? procedureTypes : procedureTypes.filter((t) => t?.value !== 'primary')
            ).map((option) => ({
              ...option,
              onClick: () =>
                handleProcedureType(setFieldValue, selectedItems, i, option.value, type)
            }));

            const currentProcedure = procedureTypes.find(
              (o) => o?.value === (item?.type === 'cash' ? 'self_pay' : item?.type)
            )?.label;

            return (
              <div key={'selected' + i} className="mb-2 flex w-full items-start gap-[10px]">
                <div className="grid grow">
                  {i === 0 && label === 'products' ? (
                    <p className="mb-[6px] text-sm font-500 text-neutral-800">{label}</p>
                  ) : i === 0 && !ia(values.packages) ? (
                    <p className="mb-[6px] text-sm font-500 text-neutral-800">{label}</p>
                  ) : null}
                  <div className="flex h-10 items-center justify-between rounded-md !border border-solid border-neutral-100 !px-3 !py-2">
                    <div className="grid overflow-hidden">
                      <div
                        title={name}
                        className="flex items-center truncate text-sm text-neutral-800">
                        {id &&
                        (type === 'procedures'
                          ? !!JSON.parse(servicesObject[id] || `null`)
                          : servicesObject[id]) ? (
                          <div className="!mr-2 inline items-center justify-center rounded-full">
                            <img
                              src={
                                type === 'procedures'
                                  ? `https://cdn.filestackcontent.com/${
                                      JSON.parse(servicesObject[id] || `{}`)?.jpg
                                    }`
                                  : servicesObject[id]
                              }
                              className="z- h-6 w-6 rounded-md object-cover"
                            />
                          </div>
                        ) : (
                          <Icon icon="new-no-img" className="!mr-2 -mb-1 inline" />
                        )}
                        <span className="truncate">{name}</span>
                      </div>
                    </div>
                    {type === 'procedures' ? (
                      !item?.invoiceId && ia(procedureTypes) ? (
                        <Popover
                          isFixed
                          isDropdown
                          position="left"
                          icon={false}
                          options={procedureOptions}
                          text={currentProcedure}
                          buttonTheme={null}
                          className="border-0 border-b border-solid border-neutral-200"
                          panelClassName="z-[100]"
                          textClassName="text-neutral-600 font-400 text-nowrap"
                          buttonClassName="!min-h-[28px] !rounded-b-none !min-w-[90px] !px-2 !justify-end"
                        />
                      ) : (
                        <label className="text-500 !mb-0 text-sm text-neutral-500">
                          {item.packageId ? 'Package' : 'Service'}
                        </label>
                      )
                    ) : null}
                  </div>
                </div>

                {type === 'procedures' && values?.id && hasPaymentPermission && (
                  <Allowed requiredPermissions="billing.read">
                    <CurrencyInput
                      value={
                        itemType === 'self_pay' || itemType === 'cash'
                          ? modified
                            ? pt_balance
                            : total_cost_cents
                          : pt_balance
                      }
                      wrapperClassName="!px-[8px] w-[104px]"
                      label={i === 0 && 'Est. Pt. Portion'}
                      disabled={invoiceId && true}
                      onValueChange={(v) =>
                        handleChangePriceProcedures(type, setFieldValue, selectedItems, v, i)
                      }
                    />
                  </Allowed>
                )}

                {type === 'products' && (
                  <>
                    {hasPaymentPermission && (
                      <CurrencyInput
                        className="!w-16"
                        wrapperClassName="!px-[8px]"
                        label={i === 0 && 'Unit Price'}
                        value={item?.amount_cents}
                        onValueChange={(v) =>
                          handleChangeUnitPrice(setFieldValue, selectedItems, v, item?.id)
                        }
                        disabled={true}
                      />
                    )}

                    <Input
                      min={1}
                      id={quantity + i}
                      type="number"
                      inputClassName="!w-8"
                      value={item.sales_count}
                      label={i === 0 && 'Qty'}
                      disabled={shouldDeleteBeDisabled}
                      onChange={(e) =>
                        handleChangeQuantity(
                          setFieldValue,
                          selectedItems,
                          e.target.value,
                          item?.id,
                          list
                        )
                      }
                    />

                    {hasPaymentPermission && (
                      <CurrencyInput
                        className="!w-[75px]"
                        wrapperClassName="!px-[8px]"
                        label={i === 0 && 'Price'}
                        id={`total_amount_cents-${i}`}
                        value={item?.total_amount_cents}
                        onValueChange={(v) =>
                          handleChangePrice(setFieldValue, selectedItems, v, item?.id)
                        }
                        disabled={true}
                      />
                    )}
                  </>
                )}
                <Tippy
                  content={tooltipMessage}
                  disabled={!shouldDeleteBeDisabled}
                  className="tippy-dark">
                  <div>
                    <Icon
                      icon="trash"
                      disabled={shouldDeleteBeDisabled || ERAPosted}
                      className={
                        (i === 0 && label === 'products') || (i === 0 && !ia(values.packages))
                          ? 'pr-2 pt-[39px]'
                          : 'pr-2 pt-[12px]'
                      }
                      onClick={() => removeItem(setFieldValue, selectedItems, type, i)}
                      data-qa="remove-procedure-products-icon"
                    />
                  </div>
                </Tippy>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default SelectedItems;
