import { formatDate } from 'lib/helpers/utility';
import { UNIT_OPTIONS } from './constants';

export const createObjectWithKeys = (existingData) => {
  const keys = [
    'id',
    'medication',
    'product_ndc',
    'product_ndc_id',
    'sig',
    'unit',
    'strength',
    'quantity',
    'days_supply',
    'refills',
    'prescriber',
    'condition',
    'start_date',
    'end_date',
    'status',
    'route',
    'note'
  ];

  const newObject = keys.reduce((acc, key) => {
    switch (key) {
      case 'unit':
        acc[key] = existingData?.[key] || UNIT_OPTIONS?.[0]?.value;
        break;
      case 'start_date':
      case 'end_date':
        acc[key] = existingData?.[key] ? formatDate(existingData[key], undefined, true) : null;
        break;
      default:
        acc[key] = existingData?.[key] || null;
    }
    return acc;
  }, {});

  return newObject;
};
