import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { upsertVital } from 'api/Vitals';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';

import { getInitialData } from 'components/practice/charts/ClinicalNote/NewVitals/components/lib/handleFormik';
import { showAlert } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Modal from 'components/shared/Modal/Modal';
import { userState } from 'components/state';

import AssignToClinicalNote from './AssignToCn/AssignToClinicalNote';
import VitalsModaFooter from './ModalFooter/VitalsModalFooter';
import UpsertForm from './UpsertForm/UpsertForm';
import { emptyVital, updateVitalsStatesAfterUpsert } from './lib/helper';

const UpsertVitals = ({
  showModal = false,
  hideModal = () => null,
  disabled = false,
  vital = {},
  fromOverview = false,
  setVital = () => {},
  fieldToFocus = ''
}) => {
  const { setOverviewData, setCurrentHpOverviewData } = useClinicalNoteContext() || {};
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { id: patientId, appointmentId } = useParams();
  const { f_name: userFirstName, l_name: userLastName } = useRecoilValue(userState);

  const formik = useFormik({
    initialValues: getInitialData(vital),
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (!yesCheckbox) values.notes = '';
      const copiedValues = structuredClone(values);
      if (emptyVital({ newVital: copiedValues })) {
        showAlert({
          title: 'Vital',
          message: 'Please fill at least one field to save the vital',
          color: 'warning'
        });
        return;
      }
      await upsertVitalMutation.mutateAsync({
        patientId,
        appointmentId: appointmentId || vital?.appointment_id,
        vitals: {
          vitals: [values]
        }
      });
    }
  });
  const [yesCheckbox, setYesCheckbox] = useState(formik?.values?.notes);

  const upsertVitalMutation = useMutation((data) => upsertVital(navigate, data), {
    onSettled: async (data) => {
      const { code, error = null, newVitals, newPatientVitals } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Vital',
          message: error ?? 'Vital creation failed! Please contact support.',
          color: 'danger'
        });

        hideModal();
        return;
      }

      updateVitalsStatesAfterUpsert({
        newVitals,
        values: formik?.values,
        newPatientVitals,
        setCurrentHpOverviewData,
        setOverviewData,
        appointmentId: parseInt(appointmentId),
        currentUser: { f_name: userFirstName, l_name: userLastName }
      });

      if (fromOverview) await queryClient.invalidateQueries(['vitals']);
      hideModal();
    }
  });

  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      className="max-h-[90dvh] w-[1160px]"
      bodyClassName="bg-primary-10"
      modalCenterAnimation={true}
      isFooter={false}
      customHeader={
        <div className="flex max-h-[20px] w-full items-center justify-between">
          <Breadcrumb
            activeClassName="text-primary-700"
            breadcrumb={{
              setStep: () => {},
              step: 1,
              hasBackButton: false,
              steps: [
                { title: 'Vitals' },
                {
                  title:
                    formik?.values?.id || formik?.values?.cfr_id ? 'Update Vitals' : 'Add Vitals'
                }
              ]
            }}
            key="breadcrumb"
          />
          {fromOverview && (
            <AssignToClinicalNote setVital={setVital} patientId={patientId} vital={vital} />
          )}
        </div>
      }
      isFooterSticky>
      <UpsertForm
        formik={formik}
        disabled={disabled}
        fieldToFocus={fieldToFocus}
        yesCheckbox={yesCheckbox}
        setYesCheckbox={setYesCheckbox}
      />
      <VitalsModaFooter
        disabled={disabled}
        formik={formik}
        hideModal={hideModal}
        loading={upsertVitalMutation?.isLoading}
        fromOverview={fromOverview}
      />
    </Modal>
  );
};
export default UpsertVitals;
