import levenshtein from 'js-levenshtein';
import { ia, random } from 'lib/helpers/utility';
import { showAlert } from 'components/shared/Alert/Alert';

export default async function handleFileLoadedMatrix({
  data,
  fileInfo,
  setFile,
  setHeaders,
  setHeaderValue,
  setColumnHeaderOptions,
  columnHeaderBaseOptions,
  defaultFile,
  show,
  setShow
}) {
  let newShow = Object.assign({}, show);

  try {
    let nf = Object.assign({}, defaultFile); // new file
    let nh = []; // new headers
    let nhv = []; // new header value
    let nhm = []; // new header matches
    // parse the data to find appropriate headers and columns matches
    // set the data
    if (!ia(data)) {
      throw 'No data in file!';
    }
    nh = data.shift();
    let selectedHeaderIndecies = [];
    if (ia(nh)) {
      // create a matrix of levenshtein distances for each header and header option
      // pick smallest numbers for each row (header option) for each column (header value);
      let levenshteinMatrix = [];
      let i = 0,
        j = 0,
        k = 0,
        minLevenshteinValue = 9999,
        lowerCaseHeader = '';
      let dupMap = new Map();
      for (i = 0; i < nh.length; i++) {
        levenshteinMatrix.push([]);
        for (j = 0; j < columnHeaderBaseOptions.length; j++) {
          // create a lower case header string
          // reset repeating values
          minLevenshteinValue = 9999;
          lowerCaseHeader = String(nh[i]).toLowerCase();
          minLevenshteinValue = levenshtein(
            lowerCaseHeader,
            String(columnHeaderBaseOptions[j].label).toLowerCase()
          );
          if (ia(columnHeaderBaseOptions[j].variations)) {
            // if there are variations, get the smallest levenshtein value for them
            for (k = 0; k < columnHeaderBaseOptions[j].variations.length; k++) {
              // get the minimum of the levenshtein values
              let mlv = levenshtein(lowerCaseHeader, columnHeaderBaseOptions[j].variations[k]);
              minLevenshteinValue = Math.min(mlv, minLevenshteinValue);
              if (minLevenshteinValue === 0) {
                break;
              }
            }
          }
          // assign the minimum levenshtein value of this entry in reverse order
          //   so that the rows will become the headers to be matched with
          //   and the columns will become the headers you are matching
          levenshteinMatrix[i][j] = minLevenshteinValue;
        }
      }
      if (ia(levenshteinMatrix) && ia(levenshteinMatrix[0])) {
        // if both of the matrix values have values
        let i = 0,
          j = 0,
          minValue = 999,
          minValueHeader = null;
        for (i = 0; i < levenshteinMatrix.length; i++) {
          minValue = 999;
          minValueHeader = null;
          for (j = 0; j < levenshteinMatrix[i].length; j++) {
            if (levenshteinMatrix[i][j] < minValue) {
              minValue = levenshteinMatrix[i][j];
              minValueHeader = j;
              if (minValue === 0) {
                break;
              }
            }
          }
          if (minValueHeader) {
            let newValue = columnHeaderBaseOptions[minValueHeader]?.value;
            nhv[i] = columnHeaderBaseOptions[minValueHeader];
            nhm[i] = newValue;
            let dupMapValue = dupMap.get(newValue);
            if (dupMapValue) {
              nhv[i]['message'] = `No duplicate matches allowed`;
              nhv[dupMapValue]['message'] = `No duplicate matches allowed`;
              newShow.holds = true;
            } else {
              dupMap.set(newValue, i);
            }
            switch (columnHeaderBaseOptions[minValueHeader]) {
              case 'dob':
                // newShow.holds = true;
                break;
              case 'name':
                newShow.holds = true;
                break;
              default:
                break;
            }
            selectedHeaderIndecies.push(minValueHeader);
          }
        }
      }
    }
    nf = { ...nf, fileName: fileInfo.name };
    if (ia(data)) {
      // add data to the file
      nf.data = data;
      // add number of data entries in the file
      nf['numberOfEntries'] = data.length;

      nf.sampleEntries = [];
      for (let i = 0; i < 3; i++) {
        let newSampleEntry = [];
        for (let j = 0; j < nh.length; j++) {
          newSampleEntry[j] = data[random(0, nf.numberOfEntries - 1)][j];
          if (!newSampleEntry[j]) {
            // if the random entry selected doesn't have a value
            // retry to get a new value for 3 times
            for (let k = 0; k < 20; k++) {
              newSampleEntry[j] = data[random(0, nf.numberOfEntries - 1)][j];
              if (newSampleEntry[j]) {
                break;
              }
            }
          }
        }
        nf.sampleEntries.push(newSampleEntry);
      }
    }
    let nho = [];
    if (ia(selectedHeaderIndecies)) {
      // calculate all of the new options via the new
      nho = columnHeaderBaseOptions.filter(
        (v, i) => v?.value && !selectedHeaderIndecies.includes(i)
      );
    }

    let newMessages = [];
    for (let i = 0; i < nho.length; i++) {
      if (nho[i]?.missingMessage) {
        newMessages.push(nho[i].missingMessage);
      }
    }
    newShow.messages = newMessages;

    setFile(nf);
    setHeaders(nh);
    setHeaderValue(nhv);
    setColumnHeaderOptions(nho);
    newShow.stage = 'generateEmails';
  } catch (error) {
    newShow.stage = 'uploadFile';
    console.error(error);
    showAlert({
      color: 'danger',
      message: typeof error === 'string' ? error : `There's been an error`
    });
  }
  setShow(newShow);
}
