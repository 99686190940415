import React, { useRef, useState } from 'react';

import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';
import { getProceduresTotal } from 'components/public/lib/utils';
import Popover from 'components/shared/Popovers/Popover/Popover';

import { getInvoicesFromSelectedRows } from '../lib/utils';

import { InvoiceModal } from './InvoiceModal';
import InvoicePrintView from './InvoicePrintView';
import NewInvoice from './NewInvoice';
import NewInvoiceView from './NewInvoiceView';

const InternalInvoicesNavigatePopover = ({ data }) => {
  let finalGroupInvoices = [];
  if (data) {
    finalGroupInvoices = getInvoicesFromSelectedRows([data]);
  }

  const pageToPrintRef = useRef();

  const practice = useRecoilValue(currentPractice);

  const claims = practice?.display_settings?.claims;

  const [invoiceModalVisible, setInvoiceModalVisible] = useState(false);
  const { patient_id, appointment_id, eraIds = [] } = data || {};

  const onPreview = () => {
    setInvoiceModalVisible(true);
  };

  const handleInvoiceModalClose = () => {
    setInvoiceModalVisible(false);
  };

  const modalContent = (
    <>
      {finalGroupInvoices.map((finalInvoice, idx) => {
        const invoiceDetails = getProceduresTotal([finalInvoice]);
        return (
          <div key={idx}>
            <InvoicePrintView
              patient={finalInvoice?.patient}
              practice={practice}
              createdAt={finalInvoice?.dateofservice}
              content={
                <NewInvoice invoice={finalInvoice} isView>
                  <NewInvoiceView hasClaims={claims} invoiceDetails={invoiceDetails} />
                </NewInvoice>
              }
            />
          </div>
        );
      })}
    </>
  );

  if (!data) return null;

  const options = [
    { label: 'Preview Invoice', onClick: onPreview, icon: 'new-eye' },
    {
      label: 'View Patient',
      onClick: () => window.open(`/portal/charts/${patient_id}/billing/invoices`, '_blank'),
      icon: 'new-patient-active'
    },
    appointment_id && {
      label: 'View Appointment',
      onClick: () => window.open(`/portal/appointments/${appointment_id}`, '_blank'),
      icon: 'calendar-tick'
    },
    eraIds?.length > 0 && {
      label: 'View Era',
      icon: 'new-claim',
      children: eraIds.map((eraId) => ({
        label: eraId,
        onClick: () => window.open(`/portal/beyond-billing/era-eob/era/${eraId}`, '_blank')
      }))
    }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {invoiceModalVisible ? (
        <InvoiceModal
          // prevButton={prevButton}
          title="Preview Invoice"
          invoiceModalVisible={invoiceModalVisible}
          onClose={handleInvoiceModalClose}
          modalContent={
            <div ref={pageToPrintRef} className="h-full w-auto">
              {modalContent}
            </div>
          }
        />
      ) : null}
    </>
  );
};

export default InternalInvoicesNavigatePopover;
