import React from 'react';
import { NavLink } from 'react-router-dom';
import cs from 'classnames';

const PracticeSecondarySidebar = ({ requests }) => {
  return (
    <div className="w-max !rounded-lg bg-white !p-3">
      <NavLink
        to={`/admin/requests/payers`}
        className={({ isActive }) =>
          cs(
            'flex items-center gap-2 rounded-full p-2 !px-5 !py-2 text-neutral-700 no-underline',
            isActive && 'bg-primary-50 text-primary-700'
          )
        }>
        Payers
        {requests?.payers > 0 && (
          <div className="flex !h-5 !w-5 items-center justify-center rounded-full bg-primary-600 text-xs font-bold text-white">
            {requests?.payers}
          </div>
        )}
      </NavLink>
    </div>
  );
};

export default PracticeSecondarySidebar;
