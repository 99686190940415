export function generateIntakeList(cnDisplaySettings, advancedHPTitles) {
  return {
    patientNeeds: cnDisplaySettings?.sections?.intake?.patientNeeds?.enabled && {
      title: 'Patient Needs / Presenting Issues',
      path: 'patient-needs',
      position: cnDisplaySettings?.sections?.intake?.patientNeeds?.position
    },
    problemManifestationDefinition: cnDisplaySettings?.sections?.intake
      ?.problemManifestationDefinition?.enabled && {
      title: 'Problem Manifestation / Definition',
      path: 'problem-manifestation-definition',
      position: cnDisplaySettings?.sections?.intake?.problemManifestationDefinition?.position
    },
    diagnosisCodes: cnDisplaySettings?.sections?.intake?.diagnosisCodes?.enabled && {
      title: 'Diagnosis Codes',
      path: 'diagnosis-codes',
      position: cnDisplaySettings?.sections?.intake?.diagnosisCodes?.position
    },
    goals: cnDisplaySettings?.sections?.intake?.goals?.enabled && {
      title: 'Goals',
      path: 'goals',
      position: cnDisplaySettings?.sections?.intake?.goals?.position
    },
    timeFrames: cnDisplaySettings?.sections?.intake?.timeFrames?.enabled && {
      title: 'Time Frames',
      path: 'time-frames',
      position: cnDisplaySettings?.sections?.intake?.timeFrames?.position
    },
    riskAndCoMorbidities: cnDisplaySettings?.sections?.intake?.riskAndCoMorbidities?.enabled && {
      title: 'Risk and co-Morbidities',
      path: 'risk-and-co-morbidities',
      position: cnDisplaySettings?.sections?.intake?.riskAndCoMorbidities?.position
    },
    vitals: cnDisplaySettings?.sections?.intake?.vitals?.enabled && {
      title: 'Vitals',
      path: 'vitals',
      position: cnDisplaySettings?.sections?.intake?.vitals?.position
    },
    ...advancedHPTitles,
    cptCodes: cnDisplaySettings?.sections?.intake?.cptCodes?.enabled && {
      title: 'CPT® Codes',
      path: 'cpt-codes',
      position: cnDisplaySettings?.sections?.intake?.cptCodes?.position
    },
    notes: cnDisplaySettings?.sections?.intake?.notes?.enabled && {
      title: 'General Notes',
      path: 'notes',
      position: cnDisplaySettings?.sections?.intake?.notes?.position
    },
    orders: cnDisplaySettings?.sections?.intake?.orders?.enabled && {
      title: 'Orders',
      path: 'orders',
      position: cnDisplaySettings?.sections?.intake?.orders?.position
    }
  };
}
