import { updatePendingChanges } from 'api/Claim';

import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';

export const handleAcceptAll = async (id, navigate, queryClient, breadcrumb) => {
  let result = await updatePendingChanges(navigate, { id });
  if (result.code === 0) {
    const cachedData = queryClient.getQueryData(['claim', `${id}`]);
    queryClient.setQueryData(['claim', `${id}`], (data) => {
      try {
        showAlert({
          title: 'Claim update successfully',
          message: 'Claim updated successfully',
          color: 'success'
        });

        return {
          ...cachedData,
          claim: {
            ...cachedData.claim,
            pendingClaimMdChanges: result?.claim?.pendingClaimMdChanges
          }
        };
      } catch (error) {
        showAlert({
          title: 'Claim update failed',
          message: error ?? 'Something went wrong',
          color: 'danger'
        });
        return { ...data };
      }
    });

    if (!ia(result?.claim?.pendingClaimMdChanges)) {
      queryClient.refetchQueries(['claims']);
      breadcrumb?.goBack();
    }
  }
};

export const handleAccept = async ({
  id = null,
  changeId = null,
  key = '',
  value = '',
  kind = '',
  updatedField = {},
  navigate,
  queryClient,
  breadcrumb
}) => {
  let fieldsToUpdate = kind === 'claim' ? { [key]: value } : updatedField;
  const params = {
    id,
    changeId,
    kind,
    fields: fieldsToUpdate,
    type: 'accept'
  };

  let result = await updatePendingChanges(navigate, params);
  if (result.code === 0) {
    const cachedData = queryClient.getQueryData(['claim', `${id}`]);
    queryClient.setQueryData(['claim', `${id}`], (data) => {
      try {
        let updatedClaimValue;

        const updatedClaim = {
          ...cachedData.claim,
          pendingClaimMdChanges: result?.claim?.pendingClaimMdChanges
        };
        if (kind === 'claim') {
          updatedClaimValue = result?.claim?.[key] ?? cachedData.claim?.[key];
          updatedClaim[key] = updatedClaimValue;
        }
        if (kind === 'invoice') {
          updatedClaimValue =
            result?.claim?.procedures ?? cachedData?.claim?.appointment?.invoice?.procedures;
          updatedClaim.appointment.invoice.procedures = updatedClaimValue;
        }

        if (kind === 'adjustment') {
          let claimAdjustments =
            result?.claim?.claimAdjustments ?? cachedData?.claim?.claimAdjustments;
          let totalPaid = result?.claim?.totalPaid ?? cachedData?.claim?.totalPaid;
          updatedClaim.claimAdjustments = claimAdjustments;
          updatedClaim.totalPaid = totalPaid;
        }
        showAlert({
          title: 'Claim update successfully',
          message: 'Claim updated successfully',
          color: 'success'
        });

        return {
          ...cachedData,
          claim: updatedClaim
        };
      } catch (error) {
        showAlert({
          title: 'Claim update failed',
          message: error ?? 'Something went wrong',
          color: 'danger'
        });
        return { ...data };
      }
    });
    if (!ia(result?.claim?.pendingClaimMdChanges)) {
      queryClient.refetchQueries(['claims']);
      breadcrumb?.goBack();
    }
  } else {
    showAlert({
      title: 'Claim update failed',
      message: result.error ?? 'Something went wrong',
      color: 'danger'
    });
  }
};

export const handleCancel = async ({
  id = null,
  changeId = null,
  key = '',
  value = '',
  kind = '',
  updatedField = {},
  navigate,
  queryClient,
  breadcrumb
}) => {
  let fieldsToUpdate = kind === 'claim' ? { [key]: value } : updatedField;

  const params = {
    id,
    changeId,
    kind,
    fields: fieldsToUpdate,
    type: 'cancel'
  };

  let result = await updatePendingChanges(navigate, params);
  if (result.code === 0) {
    const cachedData = queryClient.getQueryData(['claim', `${id}`]);
    queryClient.setQueryData(['claim', `${id}`], (data) => {
      try {
        showAlert({
          title: 'Claim updated successfully',
          message: 'Claim updated successfully',
          color: 'success'
        });
        return {
          ...cachedData,
          claim: {
            ...cachedData.claim,
            pendingClaimMdChanges: result?.claim?.pendingClaimMdChanges
          }
        };
      } catch (error) {
        showAlert({
          title: 'Claim update failed',
          message: error ?? 'Something went wrong',
          color: 'danger'
        });
        return { ...data };
      }
    });
    if (!ia(result?.claim?.pendingClaimMdChanges)) {
      queryClient.refetchQueries(['claims']);
      breadcrumb?.goBack();
    }
  }
};
