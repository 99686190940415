import { useEraContext } from 'lib/context/EraContext/EraContext';
import { formatDate } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';

import AdjustmentCodes from '../AdjustmentCodes';
import PatientService from '../PatientService';

export const getRowClassRules = (selectedUnmatchedClaim, hoveredId) => ({
  'selected-group-row': (params) => params.data.claimId === selectedUnmatchedClaim,
  'ag-hover-group-row': (params) => params.data.claimId === hoveredId
});

export const UNMATCHED_CLAIMS_COLUMN_DEFS = [
  {
    headerName: 'DoS',
    field: 'date'
  },
  {
    headerName: 'Patient/Service',
    field: 'procedure_code',
    cellRenderer: PatientService
  },
  {
    headerName: 'Provider',
    field: 'provider'
  },
  {
    headerName: 'Billed',
    field: 'billed'
  },
  {
    headerName: 'Adjustment',
    field: 'adjustment',
    autoHeight: true,
    autoWidth: true,
    cellRenderer: ({ data }) => {
      if (data?.isClaimRow) {
        return data.adjustment;
      }

      return <AdjustmentCodes data={data} />;
    }
  },
  {
    headerName: 'Ins. Paid',
    field: 'paid'
  },
  {
    headerName: '',
    field: 'actions',
    maxWidth: 100,
    cellRenderer: function ActionsCellRenderer({ data }) {
      const { era, setSelectedClaim } = useEraContext();

      if (!data?.isClaimRow) return null;

      return (
        <div className="flex h-full items-center justify-center">
          <Button
            text="Post"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedClaim({ claim: data, index: data.index, eraId: era.id });
            }}
          />
        </div>
      );
    }
  }
];

export const MATCHED_CLAIMS_COLUMN_DEFS = (timezone) => [
  {
    headerName: 'Posted/DoS',
    field: 'createdAt',
    cellRenderer: ({ data }) => {
      if (data?.isClaimRow) {
        return formatDate(data.createdAt, timezone);
      }

      return <span className="text-primary-700">{data.date}</span>;
    }
  },
  {
    headerName: 'Patient/Service',
    field: 'procedure_code',
    cellRenderer: PatientService
  },
  {
    headerName: 'Provider/Modifiers',
    field: 'provider',
    cellRenderer: ({ data }) => {
      if (data?.isClaimRow) {
        return data.provider;
      }

      return <span className="text-primary-700">{data.provider}</span>;
    }
  },
  {
    headerName: 'Diagnosis',
    field: 'diagnosis'
  },
  {
    headerName: 'Billed',
    field: 'billed'
  },
  {
    headerName: 'Adjustment',
    field: 'adjustment',
    autoHeight: true,
    autoWidth: true,
    cellRenderer: ({ data }) => {
      if (data?.isClaimRow) {
        return data.adjustment;
      }

      return <AdjustmentCodes data={data} />;
    }
  },
  {
    headerName: 'Total Ins. Paid',
    field: 'total_insurance_payment'
  },
  {
    headerName: 'Pt. Paid',
    field: 'paid',
    cellRenderer: ({ data }) => {
      if (!data?.isClaimRow) return null;

      return data.paid;
    }
  }
];

export const DEFAULT_COLUMN_DEFS = {
  flex: 1,
  sortable: false,
  suppressMovable: false,
  minWidth: 100
};

export const DEFAULT_COLUMN_DEFS_BOLD = {
  ...DEFAULT_COLUMN_DEFS,
  cellStyle: { fontWeight: '600' }
};
