import React, { useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

import Allowed from 'components/shared/Permissions/Allowed';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { arrayToObject, ia, mString, pm } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import EmptyProductsPackages from '../../../../Patient/sell/EmptyProductsPackages';
import Checkbox from '../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../shared/Icon/Icon';
import Input from '../../../../shared/Input/Input';
import { SelectedItems } from './components';
import { addNewItems, handleAddItems, isProcedureChecked } from './lib';
import { permissions } from 'components/state';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import {
  NewItemCounter,
  ServicesFromPackages
} from 'components/practice/NewAppointment/components/Sections/Pricing/components/ProceduresProduct/components';
import { emptyStateMessages } from 'components/practice/NewAppointment/components/Sections/Pricing/components/ProceduresProduct/lib/emptyStateMessages';

const AddProceduresProducts = ({
  label,
  list,
  type,
  packageList = [],
  formik,
  originalProceduresProducts,
  addPermissions = ''
}) => {
  const ref = useRef(null);
  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};
  const userPermissions = useRecoilValue(permissions);

  const { values, errors, touched, setFieldValue } = formik;
  const selectedItems = [...values[type]];
  const [newItems, setNewItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredData = list?.filter?.((item) =>
    item?.name?.toLowerCase().includes(searchTerm?.toLowerCase())
  );
  const servicesObject = useMemo(() => arrayToObject(list, 'id', 'image'), [list]);
  const isServices = type === 'procedures';
  const { title, buttonText, icon, link, createDesc } = emptyStateMessages(isServices);
  const navigate = useNavigate();

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength
    });
  };

  useEffect(() => {
    if (!selectedItems.length) {
      if (!!setSelectedService) setSelectedService(null);

      if (!!setSelectedTimeLength && type === 'procedures') {
        setFieldValue('appointmentLength', null);
        setSelectedTimeLength(null);
      }
    } else {
      const itemWithLargestTime = selectedItems.reduce((prev, current) => {
        const currTimeLength = current?.time_length || current?.timeLength;
        const prevTimeLength = prev?.time_length || prev?.timeLength;
        return currTimeLength > prevTimeLength ? current : prev;
      });

      if (itemWithLargestTime) {
        if (!!setSelectedService) setSelectedService(itemWithLargestTime);

        const timeLength = itemWithLargestTime?.time_length || itemWithLargestTime?.timeLength;

        if (!!setSelectedTimeLength && type === 'procedures') {
          setFieldValue('appointmentLength', timeLength);
          setSelectedTimeLength({ value: timeLength, label: `${timeLength} min` });
        }
      }
    }
  }, [values[type]]);

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  return (
    <div ref={ref}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Allowed requiredPermissions={addPermissions}>
              <Popover.Button
                className="w-full p-0"
                data-qa={`add-btn-type-${type}`}
                onClick={() => {
                  open && handleAddItemsFunc();
                  !values?.id && !open && setTimeout(() => scrollToElement(), 100);
                }}>
                <div
                  className={cs(
                    'flex cursor-pointer items-center justify-between rounded-lg !border border-solid border-transparent border-l-primary-50 border-r-primary-100 bg-gradient-to-r from-primary-50 to-primary-100 !px-4 !py-2',
                    open && '!border-primary-500'
                  )}>
                  <Icon
                    icon="new-dropdown-select"
                    className="grid h-9 w-9 cursor-pointer place-content-center rounded-full bg-white"
                  />

                  <label className="!mb-0 cursor-pointer text-sm font-500 text-primary-500">
                    {`Add ${label}`}
                  </label>

                  <Icon
                    color="primary"
                    icon="new-chevron-down"
                    className={cs(
                      open && 'rotate-180',
                      'grid h-6 w-6 cursor-pointer place-content-center rounded-full bg-primary-200'
                    )}
                  />
                </div>
              </Popover.Button>
            </Allowed>

            <Popover.Panel
              className={cs(
                'absolute z-20 mt-[6px] flex w-full  flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]',
                values?.id && 'top-[-415px]'
              )}>
              <div
                className={cs(
                  'relative h-[300px] overflow-y-auto !p-3 !pr-[6px]',
                  ia(filteredData) && 'h-[400px]'
                )}>
                {type === 'procedures' && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}

                <div>
                  {ia(list) ? (
                    <>
                      <Input
                        icon="search"
                        rounded="full"
                        value={searchTerm}
                        id={`search-${type}`}
                        data-qa={`search-${label}`}
                        placeholder={`Search ${label}`}
                        rightIcon={searchTerm && 'new-close'}
                        rightIconClick={() => setSearchTerm('')}
                        onChange={(e) => setSearchTerm(e?.target?.value)}
                        className="sticky -top-3 z-10 !mb-2 w-full bg-white !p-2"
                      />

                      <p className="text-xs font-500 leading-5 text-neutral-500">{label}</p>
                    </>
                  ) : (
                    <NotFound
                      icon={icon}
                      title={title}
                      buttonText={buttonText}
                      description={createDesc}
                      onClick={() => navigate(link)}
                      textWrapperClassName={isServices && '!-mt-4'}
                      className="!pb-2"
                    />
                  )}

                  {ia(filteredData) ? (
                    filteredData?.map((item, index) => {
                      const outOfStock = !isServices && item?.quantity === 0;

                      return (
                        <div
                          className={cs(
                            'flex !min-h-[34px] items-center justify-between rounded-md !px-2 py-[3px]',
                            !!isProcedureChecked(newItems, item) && 'bg-primary-50'
                          )}
                          key={item?.id}>
                          <Checkbox
                            id={index}
                            disabled={outOfStock}
                            onBlur={formik.handleBlur}
                            label={item?.name + (outOfStock ? ' (Out of stock)' : '')}
                            labelTextColor={cs('leading-4 ', outOfStock && '!text-danger-600')}
                            handleClick={() =>
                              addNewItems({ newItem: item, newItems, setNewItems })
                            }
                            isChecked={
                              type === 'procedures'
                                ? !!isProcedureChecked(newItems, item)
                                : newItems.some((sItem) => sItem.id === item.id)
                            }
                          />

                          <div className="flex items-center !gap-2">
                            <NewItemCounter
                              newItem={item}
                              newItems={newItems}
                              setNewItems={setNewItems}
                            />

                            {type === 'products' && pm(userPermissions, ['payments.read']) && (
                              <p className="w-14 text-xs text-neutral-500">
                                {mString(item?.amount_cents)}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })
                  ) : type === 'products' ? (
                    <EmptyProductsPackages type="product" />
                  ) : null}
                </div>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>

      <SelectedItems
        type={type}
        label={label}
        values={values}
        setFieldValue={setFieldValue}
        selectedItems={selectedItems}
        servicesObject={servicesObject}
        list={type === 'products' ? list : []}
        isClinicalNoteLocked={isClinicalNoteLocked}
        originalProceduresProducts={originalProceduresProducts}
      />

      {type === 'procedures' && touched?.procedures && errors?.procedures && (
        <p className="!pt-2 text-sm text-danger-500">{errors?.procedures}</p>
      )}

      {type === 'products' && ia(additionalTimes) && ia(values?.products) && (
        <p className="text-sm text-warning-500">
          Products are only available for the current appointment.
        </p>
      )}
    </div>
  );
};

export default AddProceduresProducts;
