export const padding = {
  xs: 'p-[1px]',
  sm: 'p-[1px]',
  md: 'p-[1.5px]',
  lg: 'p-[2px]',
  xl: 'p-[2.5px]'
};

export const sizes = {
  xs: 'w-1 h-1',
  sm: 'w-[6px] h-[6px]',
  md: 'w-2 h-2',
  lg: 'w-3 h-3',
  xl: 'w-4 h-4'
};

export const colors = {
  neutral: 'bg-neutral-500',
  primary: 'bg-primary-500',
  secondary: 'bg-secondary-500',
  success: 'bg-success-500',
  warning: 'bg-warning-500',
  danger: 'bg-danger-500'
};
