import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { getBillingAndRenderingProviders } from '../../../../api/Billing';

export const useBillingRenderingProviders = ({ params = {}, options = {} } = {}) => {
  const navigate = useNavigate();

  return useQuery(
    ['billingRenderingProviders'],
    () => getBillingAndRenderingProviders(navigate, params),
    { refetchOnMount: false, refetchOnWindowFocus: false, ...options }
  );
};
