import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';
import { fabric } from 'fabric';
import { capitalize } from 'lodash';
import moment from 'moment-timezone';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useRecoilValue } from 'recoil';

import { useSignatures } from 'lib/hooks/queries/document/useSignatures';

import { currentPractice } from 'components/practice/practiceState';
import Popup from 'components/shared/Filters/Popup';
import Icon from 'components/shared/Icon/Icon';
import Select from 'components/shared/Select/Select';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfEditor = ({
  src,
  canvasStates,
  setCanvasStates,
  fabricCanvasRef,
  currentPage,
  setCurrentPage,
  scale,
  setScale,
  historyMode
}) => {
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [objectSelected, setObjectSelected] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [fontFamily, setFontFamily] = useState('Times New Roman');

  const [inputPage, setInputPage] = useState(1);
  const [selectedAction, setSelectedAction] = useState(null);
  const [pointer, setPointer] = useState({ x: 0, y: 0 });
  const pdf = useRef();
  const practiceState = useRecoilValue(currentPractice);
  const [signature, setSignature] = useState(null);

  const timer = useRef();

  const canvasWidth = 700;

  const signs = useSignatures({});
  const signatures = signs?.data?.signatures || [];

  const getCenterPosition = (pointer, object) => {
    return {
      left: pointer.x - object.width / 2,
      top: pointer.y - object.height / 2
    };
  };

  const saveCurrentCanvasState = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    const canvasJson = fabricCanvas.toJSON();
    await setCanvasStates((prevStates) => ({
      ...prevStates,
      [currentPage]: canvasJson,
      scale
    }));
  };

  useEffect(() => {
    if (inputPage === currentPage) return;
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (inputPage && inputPage > 0 && inputPage <= totalPages) {
        saveCurrentCanvasState();
        setCurrentPage(parseInt(inputPage));
      } else if (inputPage) {
        setInputPage(currentPage);
      }
    }, 300);

    return () => {
      clearTimeout(timer.current);
    };
  }, [inputPage]);

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas('fabric-canvas', {
      selection: true,
      preserveObjectStacking: true
    });

    fabricCanvasRef.current = fabricCanvas;

    fabricCanvas.on('selection:created', () => {
      const activeObject = fabricCanvas.getActiveObject();
      setObjectSelected(activeObject.type);
      setIsBold(activeObject.fontWeight === 'bold');
      setIsItalic(activeObject.fontStyle === 'italic');
      setFontFamily(activeObject.fontFamily || 'Times New Roman');
    });

    fabricCanvas.on('selection:cleared', () => {
      setObjectSelected(null);
      setIsBold(false);
      setIsItalic(false);
      setFontFamily('Times New Roman');
    });

    fabricCanvas.on('selection:updated', () => {
      const activeObject = fabricCanvas.getActiveObject();
      setObjectSelected(activeObject.type);

      setIsBold(activeObject.fontWeight === 'bold');
      setIsItalic(activeObject.fontStyle === 'italic');
      setFontFamily(activeObject.fontFamily || 'Times New Roman');
    });

    fabricCanvas.on('mouse:down', (options) => {
      setSelectedAction((action) => {
        if (action && options.pointer) {
          handleAction(action, options.pointer);
          setPointer(options.pointer);
        }
        return null;
      });
    });

    return () => {
      fabricCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        pdf.current = await pdfjsLib.getDocument(src).promise;
        setTotalPages(pdf.current.numPages);
        await renderPdf(src, 1);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    loadPdf();
  }, []);

  useEffect(() => {
    if (src) {
      renderPdf(src, currentPage);
    }
    setInputPage(currentPage);
  }, [currentPage, canvasStates]);

  const renderPdf = async (url, pageNum) => {
    try {
      const page = await pdf?.current?.getPage(pageNum);
      const newScale = canvasWidth / page?.getViewport({ scale: 1 })?.width;
      const viewport = page?.getViewport({ scale: newScale });
      setScale(newScale);

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport?.height;
      canvas.width = viewport?.width;

      const renderContext = {
        canvasContext: context,
        viewport
      };

      await page?.render(renderContext).promise;

      const fabricCanvas = fabricCanvasRef.current;
      fabricCanvas.setWidth(viewport?.width);
      fabricCanvas.setHeight(viewport?.height);
      fabricCanvas.setBackgroundImage(
        canvas.toDataURL(),
        fabricCanvas.renderAll.bind(fabricCanvas)
      );

      if (canvasStates[pageNum]) {
        fabricCanvas.loadFromJSON(canvasStates[pageNum], fabricCanvas.renderAll.bind(fabricCanvas));
      } else {
        fabricCanvas.clear();
      }

      setIsPdfLoaded(true);
    } catch (error) {
      console.error('Error rendering PDF:', error);
    }
  };

  const handleAction = (action, pointer) => {
    switch (action) {
      case 'text':
        addText('Click to type...', pointer);
        break;
      case 'date':
        addText(
          moment.tz(practiceState.timezone).format('dddd, MMMM Do YYYY, h:mm:ss A z'),
          pointer
        );
        break;
      case 'rectangle':
        addRectangle(pointer);
        break;
      case 'circle':
        addCircle(pointer);
        break;
      case 'checkmark':
        addCheckMark(pointer);
        break;
      case 'signature':
        setSignature((sign) => {
          if (sign) {
            addImage(sign, pointer);
            return null;
          }
        });
        break;
      default:
        break;
    }
  };

  const addText = (txt, pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const text = new fabric.IText(txt, {
      left: pointer.x,
      top: pointer.y,
      fill: 'black',
      editable: true,
      selectable: true,
      fontSize: 24,
      fontFamily,
      fontWeight: isBold ? 'bold' : 'normal',
      fontStyle: isItalic ? 'italic' : 'normal'
    });

    const centerPosition = getCenterPosition(pointer, text);
    text.set(centerPosition);

    fabricCanvas.add(text);
    fabricCanvas.setActiveObject(text);
    fabricCanvas.renderAll();
  };

  const addRectangle = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const rect = new fabric.Rect({
      left: pointer.x,
      top: pointer.y,
      fill: '#FFFFFF',
      width: 100,
      height: 40,
      selectable: true
    });

    const centerPosition = getCenterPosition(pointer, rect);
    rect.set(centerPosition);

    fabricCanvas.add(rect);
    fabricCanvas.setActiveObject(rect);
    fabricCanvas.sendToBack(rect);
    fabricCanvas.renderAll();
  };

  const addCircle = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const circle = new fabric.Circle({
      left: pointer.x,
      top: pointer.y,
      fill: '#000000',
      radius: 20,
      selectable: true
    });

    const centerPosition = getCenterPosition(pointer, circle);
    circle.set(centerPosition);
    fabricCanvas.add(circle);
    fabricCanvas.setActiveObject(circle);
    fabricCanvas.renderAll();
  };

  const addImage = (url, pointer) => {
    fabric.Image.fromURL(
      url,
      (img) => {
        const fabricCanvas = fabricCanvasRef.current;
        img.set({ left: pointer.x, top: pointer.y, selectable: true });

        img.scaleToWidth(120);

        const centerPosition = getCenterPosition(pointer, {
          width: 120,
          height: 120 * (img.height / img.width)
        });
        img.set(centerPosition);

        fabricCanvas.add(img);
        fabricCanvas.setActiveObject(img);
        fabricCanvas.renderAll();
      },
      { crossOrigin: 'anonymous' }
    );
  };

  const addCheckMark = (pointer) => {
    const fabricCanvas = fabricCanvasRef.current;
    const mark = new fabric.Path(
      'M20.5962 0.150419C18.8621 1.1761 16.9023 2.6445 15.0595 4.30046C13.5744 5.63537 11.9904 7.24402 10.5234 8.90674C9.5113 10.0558 8.42012 11.3924 7.43443 12.6918C7.09158 13.1447 6.20479 14.3579 5.92623 14.7584C5.78283 14.9645 5.70371 15.0642 5.68722 15.0591C5.67404 15.0558 4.41967 14.3326 2.90158 13.4539L0.140668 11.8537L0.0780328 11.9263C0.0450666 11.9669 0.00880386 12.0108 0.000562327 12.026C-0.0142724 12.0531 0.219787 12.3336 6.18831 19.4289L6.66797 20H6.7339H6.79983L7.174 19.2345C9.02175 15.4647 10.8365 12.3606 12.8953 9.44746C15.165 6.23862 17.7347 3.28323 20.6258 0.56103C20.8319 0.366708 21 0.202802 21 0.196043C21 0.187594 20.8863 0.0219976 20.8648 3.0783e-05C20.8615 -0.00165897 20.7412 0.0659313 20.5962 0.150419Z',
      {
        left: pointer.x,
        top: pointer.y,
        fill: 'black',
        width: 40,
        height: 40,
        selectable: true
      }
    );
    const centerPosition = getCenterPosition(pointer, mark);
    mark.set(centerPosition);

    fabricCanvas.add(mark);
    fabricCanvas.setActiveObject(mark);
    fabricCanvas.renderAll();
  };

  const deleteSelected = () => {
    const fabricCanvas = fabricCanvasRef.current;
    const activeObject = fabricCanvas.getActiveObject();

    if (activeObject) {
      if (activeObject.type === 'activeSelection') {
        // Multiple objects are selected
        activeObject.forEachObject((obj) => {
          fabricCanvas.remove(obj);
        });
      } else {
        // Single object is selected
        fabricCanvas.remove(activeObject);
      }
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
      setObjectSelected(null);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      saveCurrentCanvasState();
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      saveCurrentCanvasState();
      setCurrentPage(currentPage - 1);
    }
  };

  const info = {
    text: 'Text',
    date: 'Date',
    rectangle: 'Rectangle',
    image: 'Image',
    signature: 'Signature'
  };

  const setColor = (color) => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas.getActiveObjects().forEach((obj) => {
      obj.set('fill', color);
    });
    fabricCanvas.renderAll();
  };

  const toggleBold = () => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontWeight', obj.fontWeight === 'bold' ? 'normal' : 'bold');
      }
    });
    fabricCanvas.renderAll();
    setIsBold((prev) => !prev);
  };

  const toggleItalic = () => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontStyle', obj.fontStyle === 'italic' ? 'normal' : 'italic');
      }
    });
    fabricCanvas.renderAll();
    setIsItalic((prev) => !prev);
  };

  const changeFontFamily = (newFontFamily) => {
    const fabricCanvas = fabricCanvasRef.current;
    fabricCanvas.getActiveObjects().forEach((obj) => {
      if (obj.type === 'i-text') {
        obj.set('fontFamily', newFontFamily);
      }
    });
    fabricCanvas.renderAll();
    setFontFamily(newFontFamily);
  };

  const fonts = [
    {
      value: 'Times New Roman',
      label: 'Serif'
    },
    {
      value: 'Helvetica',
      label: 'Sans-Serif'
    },
    {
      value: 'Courier',
      label: 'Monospace'
    }
  ];

  return (
    <div className={cs('relative', !historyMode && 'w-full')}>
      <div
        className={cs(
          'flex justify-between  ',
          historyMode
            ? 'bg-neutral-50'
            : 'border !border-x-0 !border-t-0 !border-neutral-100 bg-white shadow-[0px_0px_16px_rgba(0,0,0,0.07)]'
        )}>
        {!historyMode ? (
          <div className="flex">
            <div
              onClick={() =>
                selectedAction === 'text' ? setSelectedAction(null) : setSelectedAction('text')
              }
              disabled={!isPdfLoaded}
              className={cs(
                '!cursor-pointer border !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
                selectedAction === 'text' && 'bg-primary-100'
              )}>
              <Icon className="!cursor-pointer" icon="new-text-box" shade={900} color="primary" />
            </div>

            <div
              onClick={() =>
                selectedAction === 'date' ? setSelectedAction(null) : setSelectedAction('date')
              }
              disabled={!isPdfLoaded}
              className={cs(
                '!cursor-pointer border !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
                selectedAction === 'date' && 'bg-primary-100'
              )}>
              <Icon className="!cursor-pointer" icon="new-calendarv2" shade={900} color="primary" />
            </div>

            <Popup
              position={['bottom left']}
              on={['hover', 'focus']}
              trigger={
                <div
                  onClick={() =>
                    selectedAction === 'rectangle'
                      ? setSelectedAction(null)
                      : setSelectedAction('rectangle')
                  }
                  disabled={!isPdfLoaded}
                  className={cs(
                    '!cursor-pointer border !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
                    selectedAction === 'rectangle' && 'bg-primary-100'
                  )}>
                  <Icon
                    className="!cursor-pointer"
                    stroke
                    icon="new-rectangle-box"
                    shade={900}
                    color="primary"
                  />
                </div>
              }>
              {(close) => (
                <div className="flex flex-col py-1">
                  <div
                    onClick={() => {
                      setSelectedAction('rectangle');
                      close();
                    }}
                    className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
                    <Icon
                      className="!cursor-pointer"
                      stroke
                      icon="new-rectangle-box"
                      shade={900}
                      color="primary"
                    />
                    <p className="text-primary-900">White Rectangle</p>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedAction('circle');
                      close();
                    }}
                    className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
                    <Icon
                      className="!cursor-pointer"
                      stroke
                      icon="new-circle"
                      shade={900}
                      color="primary"
                    />
                    <p className="text-primary-900">Circle</p>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedAction('checkmark');
                      close();
                    }}
                    className="flex cursor-pointer gap-2 px-4 py-3 hover:bg-primary-50">
                    <Icon
                      className="!cursor-pointer"
                      stroke
                      icon="new-check"
                      shade={900}
                      color="primary"
                    />
                    <p className="text-primary-900">Checkmark</p>
                  </div>
                </div>
              )}
            </Popup>

            <div
              onClick={() =>
                selectedAction === 'signature_show'
                  ? setSelectedAction(null)
                  : setSelectedAction('signature_show')
              }
              disabled={!isPdfLoaded}
              className={cs(
                '!cursor-pointer  border !border-y-0 !border-l-0 !border-neutral-100 px-4 py-3 hover:bg-primary-50 active:bg-primary-100',
                (selectedAction === 'signature_show' || selectedAction === 'signature') &&
                  'bg-primary-100'
              )}>
              <Icon
                className="!cursor-pointer"
                icon="new-signature-box"
                shade={900}
                color="primary"
              />
            </div>
          </div>
        ) : (
          <div className="h-12 w-full bg-neutral-50"> </div>
        )}
        <div className="flex items-center">
          <i className="mr-1 text-sm text-neutral-300">page </i>
          <button onClick={handlePreviousPage} disabled={currentPage <= 1}>
            <Icon
              className="cursor-pointer"
              icon="new-chevron-left"
              stroke
              shade={currentPage <= 1 ? 300 : 700}
              color="neutral"
            />
          </button>

          <input
            name="page"
            value={inputPage}
            style={{
              WebkitAppearance: 'none',
              appearance: 'none',
              MozAppearance: 'none'
            }}
            onChange={(e) => setInputPage(e.target.value)}
            className="w-6 rounded-lg border border-solid border-neutral-100 text-center text-sm text-neutral-700 outline-none"></input>
          <p className="ml-1">/</p>
          <p className="ml-1">{totalPages}</p>

          <button onClick={handleNextPage} className="ml-1" disabled={currentPage >= totalPages}>
            <Icon
              className="cursor-pointer"
              icon="new-chevron-right"
              stroke
              shade={currentPage >= totalPages ? 300 : 700}
              color="neutral"
            />
          </button>
        </div>
      </div>

      <div className="relative">
        {!!objectSelected && !historyMode && (
          <div className="absolute top-0 z-10 flex w-full justify-between bg-primary-50">
            <div className="flex items-center gap-3 px-4">
              <p className="text-xs text-primary-900">Color:</p>
              {['#1B2123', '#ffffff', '#13B9FF', '#c23522', '#F59E0B', '#22C55E'].map((color) => (
                <div
                  key={color}
                  onClick={() => setColor(color)}
                  className={cs('h-5 w-5 cursor-pointer rounded-full')}
                  style={{ backgroundColor: color }}></div>
              ))}

              {objectSelected === 'i-text' && (
                <div className="ml-4 flex items-center gap-2">
                  <p className="text-xs text-primary-900">Font:</p>
                  <Select
                    value={{
                      value: fontFamily,
                      label: fonts.find((f) => f.value === fontFamily)?.label
                    }}
                    onChange={({ value }) => changeFontFamily(value || 'Times New Roman')}
                    options={fonts}
                    isClearable={false}
                    className="!w-36"
                  />
                  <div
                    onClick={toggleBold}
                    className={cs(
                      'flex aspect-square h-8 cursor-pointer items-center justify-center rounded ',
                      isBold
                        ? 'bg-primary-800 text-white hover:bg-primary-900'
                        : 'bg-primary-100 text-primary-900 hover:bg-primary-200'
                    )}>
                    <b className="text-sm ">B</b>
                  </div>

                  <div
                    onClick={toggleItalic}
                    className={cs(
                      'flex aspect-square h-8 cursor-pointer items-center justify-center rounded ',
                      isItalic
                        ? 'bg-primary-800 text-white hover:bg-primary-900'
                        : 'bg-primary-100 text-primary-900 hover:bg-primary-200'
                    )}>
                    <b className="text-sm ">I</b>
                  </div>
                </div>
              )}
            </div>

            <div
              onClick={deleteSelected}
              disabled={!!objectSelected}
              className={cs(
                'flex !h-[52px] cursor-pointer items-center justify-center bg-red-100 px-5 hover:bg-red-200 active:bg-white'
              )}>
              <Icon className="cursor-pointer" icon="trash" shade={900} color="red" />
            </div>
          </div>
        )}

        {selectedAction == 'signature_show' && !historyMode && (
          <div className="absolute top-0 z-10 flex w-full justify-between bg-primary-50">
            <div className="flex max-h-[380px] w-full gap-3 overflow-y-auto p-4">
              {signatures.map((s, i) => (
                <div key={i}>
                  <p className="mb-2 text-sm">{s?.p_name || s?.f_name + ' ' + s?.l_name}</p>
                  <div
                    onClick={() => {
                      setSelectedAction('signature');
                      setSignature(s?.signature);
                    }}
                    className="h-24 rounded-lg !border border-dashed !border-neutral-100 bg-white px-6 py-2 hover:bg-primary-100">
                    <img className="h-full" src={s?.signature} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="relative flex !h-full items-center justify-center !overflow-y-auto">
          <div
            className={!!selectedAction ? 'h-full !cursor-copy' : 'h-full'}
            style={{ position: 'relative' }}>
            <canvas className={!!selectedAction && '!cursor-copy'} id="fabric-canvas"></canvas>
          </div>
          {selectedAction && (
            <div className="z-100 absolute right-4 top-4 flex gap-2 rounded-lg bg-primary-50 px-3 py-1 text-primary-600">
              <Icon icon="new-info" color="primary" shade={600} />
              <p>Click on the file to add '{capitalize(selectedAction)}'</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PdfEditor;
