import React, { useLayoutEffect, useRef, useState } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import _ from 'lodash';

import Icon from 'components/shared/Icon/Icon';

const DynamicViewNdc = ({ ndcCodes = [], parentRef = null, handleRemove = () => {} }) => {
  const containerRef = useRef(null);
  const [visableNdcs, setVisableNdcs] = useState([]);
  const [overflowNdcs, setOverflowNdcs] = useState([]);
  const [overflowCount, setOverflowCount] = useState(0);

  const calculateVisibleNdcs = () => {
    if (!containerRef.current || !parentRef?.current || ndcCodes.length === 0) return;

    const OVERFLOW_COUNTER_WIDTH = 35;
    const GAP_WIDTH = 6;
    const SAFETY_MARGIN = 8;
    const containerWidth = parentRef.current.offsetWidth - OVERFLOW_COUNTER_WIDTH;

    const tempDiv = document.createElement('div');
    tempDiv.style.visibility = 'hidden';
    tempDiv.style.position = 'absolute';
    tempDiv.style.display = 'flex';
    tempDiv.style.gap = '4px';
    document.body.appendChild(tempDiv);

    let totalWidth = 0;
    let visibleCount = 0;

    if (ndcCodes.length > 1) totalWidth += OVERFLOW_COUNTER_WIDTH + SAFETY_MARGIN;

    for (let i = 0; i < ndcCodes.length; i++) {
      const ndc = ndcCodes[i];

      const ndcElement = document.createElement('div');
      ndcElement.className = 'flex items-center gap-[6px] rounded px-1 py-[2px]';

      const ndcSpan = document.createElement('span');
      ndcSpan.textContent = ndc;
      ndcSpan.style.backgroundColor = '#33333';
      ndcSpan.style.border = '1px solid transparent';
      ndcSpan.style.padding = '2px 4px';
      ndcSpan.style.borderRadius = '4px';

      ndcElement.appendChild(ndcSpan);
      const removeIcon = document.createElement('div');
      removeIcon.className = 'icon-remove';
      removeIcon.style.width = '8px';
      removeIcon.style.marginLeft = '4px';
      ndcElement.appendChild(removeIcon);

      tempDiv.appendChild(ndcElement);

      const ndcWidth = ndcElement.offsetWidth;
      const widthWithGap = ndcWidth + (i > 0 ? GAP_WIDTH : 0);

      if (totalWidth + widthWithGap <= containerWidth) {
        totalWidth += widthWithGap;
        visibleCount++;
      } else break;
    }

    document.body.removeChild(tempDiv);

    if (ndcCodes.length > 1 && visibleCount === 1) {
      const singleNdcWidth = containerRef.current.children[0]?.offsetWidth || 0;
      if (singleNdcWidth > containerWidth) visibleCount = 0;
    }

    const newVisibleNdcs = ndcCodes.slice(0, visibleCount);
    const newOverflowndcs = ndcCodes.slice(visibleCount);
    setVisableNdcs(newVisibleNdcs);
    setOverflowCount(Math.max(0, ndcCodes.length - visibleCount));
    setOverflowNdcs(newOverflowndcs);
  };

  useLayoutEffect(() => {
    calculateVisibleNdcs();

    const resizeObserver = new ResizeObserver(() => {
      calculateVisibleNdcs();
    });
    if (parentRef?.current) resizeObserver.observe(parentRef.current);

    return () => resizeObserver.disconnect();
  }, [ndcCodes, parentRef?.current]);

  return (
    <div ref={containerRef} className="flex grow items-center gap-1 overflow-hidden pl-3">
      {visableNdcs.map((ndc, index) => {
        return (
          <div
            key={index}
            className="flex items-center gap-[6px] rounded px-1 py-[2px]"
            title={ndc}
            style={{
              backgroundColor: '#F0F0F0',
              border: '1px solid #DADFE2'
            }}>
            <span
              className={cs(
                'select-none truncate text-ellipsis text-sm font-400 leading-5',
                'text-neutral-900'
              )}>
              {ndc}
            </span>
            <Icon
              icon="new-close-sm"
              size={8}
              color="black"
              stroke
              onClick={() => handleRemove(ndc)}
            />
          </div>
        );
      })}
      {overflowCount > 0 && (
        <Tippy
          appendTo={document.body}
          className="custom-tooltip !max-w-[150px] !rounded-[5px] !p-3 !pr-2 shadow-[0px_0px_16px_rgba(0,79,107,0.24)]"
          content={
            <div className="flex flex-col gap-[10px] overflow-y-auto overflow-x-hidden truncate !pr-2">
              {overflowNdcs?.map((ndc, index) => {
                return (
                  <Tippy content={ndc} className="tippy-dark text-base" key={index}>
                    <div
                      key={index}
                      className="flex max-w-max items-center gap-1 truncate rounded px-2 py-[2px]"
                      style={{
                        backgroundColor: '#F0F0F0',
                        border: '1px solid #DADFE2'
                      }}>
                      <span
                        className={cs(
                          'select-none truncate text-sm font-400 leading-5',
                          'text-neutral-900'
                        )}>
                        {ndc}
                      </span>
                      <Icon
                        icon="new-close-sm"
                        size={8}
                        color="black"
                        stroke
                        onClick={() => handleRemove(ndc)}
                      />
                    </div>
                  </Tippy>
                );
              })}
            </div>
          }
          placement="right"
          interactive>
          <span className="select-none gap-[6px] rounded border border-solid border-neutral-200 bg-[#F0F0F0] px-2 py-[2px] text-sm font-400 leading-5 text-neutral-900">
            {`+${overflowCount}`}
          </span>
        </Tippy>
      )}
    </div>
  );
};

export default DynamicViewNdc;
