import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const Arrows = ({ changeWeekHandle }) => {
  return (
    <>
      <Icon
        icon="new-chevron-left"
        className="select-none absolute top-1/2 -translate-y-1/2 left-[6px] lg:left-1 sm:left-0"
        onClick={() => changeWeekHandle('prev')}
        data-qa="calendar-prev-btn"
      />
      <Icon
        icon="new-chevron-right"
        className="select-none absolute top-1/2 -translate-y-1/2 right-[6px] lg:right-1 sm:right-0"
        color="neutral"
        stroke
        onClick={() => changeWeekHandle('next')}
        data-qa="calendar-next-btn"
      />
    </>
  );
};

export default Arrows;
