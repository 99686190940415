import React from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import { formatPhoneNumber } from 'lib/helpers/utility';
import useCopy from 'lib/hooks/useCopy';

import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import HorizontalScroll from 'components/shared/HorizontalScroll/HorizontalScroll';

const PatientDetails = ({ patient = {}, showAsTabs = false }) => {
  const { copyToClipboard } = useCopy();

  const copyEmail = () => {
    copyToClipboard(patient?.email);
    showAlert({
      title: 'Email copied to clipboard!',
      color: 'success',
      duration: 2000
    });
  };

  return (
    <div className={cs('relative flex overflow-hidden bg-white', showAsTabs && 'pl-10 pr-2')}>
      <HorizontalScroll>
        <div className="flex w-full gap-3">
          <div className="flex flex-col gap-[2px]">
            <p className="whitespace-nowrap text-sm font-500 text-primary-800">Chart ID</p>
            <p className="whitespace-nowrap text-xs text-neutral-800">{patient?.id}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="whitespace-nowrap text-sm font-500 text-primary-800">Phone</p>
            <p className="whitespace-nowrap text-xs text-neutral-800">
              {formatPhoneNumber(patient?.phone)}
            </p>
          </div>
          <div className={cs('flex flex-col gap-[2px]', !showAsTabs && 'max-w-[280px]')}>
            <p className="text-sm font-500 text-primary-800">Address</p>
            <Tippy
              className="tippy-dark"
              content={patient?.userAddress?.fullAddress}
              placement="bottom">
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
                {patient?.userAddress?.fullAddress}
              </p>
            </Tippy>
          </div>
          <div
            className={cs('flex cursor-pointer flex-col gap-[2px]', !showAsTabs && 'max-w-[264px]')}
            onClick={copyEmail}>
            <p className="text-sm font-500 text-primary-800">Email</p>
            <Tippy className="tippy-dark" content={patient?.email} placement="bottom">
              <p className="overflow-hidden text-ellipsis whitespace-nowrap text-xs text-neutral-800">
                {patient?.email}
              </p>
            </Tippy>
          </div>
        </div>
      </HorizontalScroll>
    </div>
  );
};

export default withErrorBoundary(PatientDetails);
