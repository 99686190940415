import { formatDate } from 'lib/helpers/utility';

export const getColDefs = (timezone) => [
  {
    field: 'id',
    headerName: 'ID',
    cellClass: 'no-border',
    headerCheckboxSelection: true,
    checkboxSelection: true
  },
  {
    field: 'appointment.starts_at',
    headerName: 'Appointment Date',
    sort: 'desc',
    valueFormatter: ({ value }) => formatDate(value, timezone, true)
  },
  {
    field: 'practitioner.fullName',
    headerName: 'Provider'
  },
  {
    field: 'services',
    headerName: 'Services(CPT)'
  },
  {
    field: 'icd_10_codes',
    headerName: 'Diagnosis(ICD)'
  }
];

export const DEFAULT_COLUMN_DEFS = { enableRowGroup: true, flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
