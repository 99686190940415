export const deleteFormOverviewAndHpNote = ({ setOverviewData, setHpNoteData, formId, type }) => {
  setOverviewData((prev) => {
    switch (type) {
      case 'medicationHistory':
        return {
          ...prev,
          [type]: prev[type]?.filter((item) => item.response.medications[0].id !== formId)
        };

      default:
        return {
          ...prev,
          [type]: prev[type]?.filter((item) => item.id !== formId)
        };
    }
  });

  setHpNoteData((prev) => {
    return {
      ...prev,
      [type]: null
    };
  });
};
