import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';

import { ia } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import InvoiceCreate from './InvoiceCreate';
import InvoicePreview from './InvoicePreview';
import InvoiceStepModalFooter from './InvoiceStepModalFooter';
import PatientSearch from './PatientSearch';

const InvoiceStepModal = ({ item: invoice, isOpen, handleClose, selectedRows }) => {
  const invoiceCreateRef = useRef();
  const [step, setStep] = useState(ia(selectedRows) || invoice ? 1 : 0);
  const [groupSelectedRows, setGroupSelectedRows] = useState(
    selectedRows || (invoice ? [invoice] : [])
  );

  const printRef = useRef();

  const isFromSelectedRows = selectedRows?.length > 0 || invoice;

  const [externalInvoices, setExternalInvoices] = useState([]);

  const handleStepChange = (increment) => {
    const newStep = step + (increment ? 1 : -1);
    if (!increment && selectedRows?.length > 0 && newStep === 0) {
      return;
    }
    setStep(newStep);
  };

  const steps = {
    0: <PatientSearch setGroupSelectedRows={setGroupSelectedRows} />,
    1: (
      <InvoiceCreate
        ref={invoiceCreateRef}
        selectedRows={groupSelectedRows}
        setExternalInvoices={setExternalInvoices}
        setStep={setStep}
      />
    ),
    2: (
      <InvoicePreview
        ref={printRef}
        externalInvoices={externalInvoices}
        selectedRows={groupSelectedRows}
      />
    )
  };

  return (
    <Modal
      slideFromRight
      isOpen={isOpen}
      title="Create Invoice"
      isLarge
      handleClose={handleClose}
      headButton={
        step === 2 && (
          <div className="flex w-full justify-end">
            <ReactToPrint
              trigger={() => (
                <Button
                  color="primary-900"
                  size="small"
                  type="link"
                  text="Print"
                  icon="new-printer-bulk"
                  className="text-primary-900"
                />
              )}
              content={() => printRef?.current}
            />
          </div>
        )
      }
      footer={
        <InvoiceStepModalFooter
          step={step}
          handleClose={handleClose}
          handleStepChange={handleStepChange}
          invoiceCreateRef={invoiceCreateRef}
          externalInvoices={externalInvoices}
          finalGroupInvoices={groupSelectedRows}
          isFromSelectedRows={isFromSelectedRows}
        />
      }>
      {steps[step]}
    </Modal>
  );
};

export default InvoiceStepModal;
