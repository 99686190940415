import React, { useMemo } from 'react';

import { useServices } from 'lib/hooks/queries/services/useServices';
import useModal from 'lib/hooks/useModal';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';
import Button from 'components/shared/Buttons/Button';
import Allowed from 'components/shared/Permissions/Allowed';

import ScheduleOfFeesModal from '../../../shared/ScheduleOfFees/ScheduleOfFeesModal';

import ServiceModal from './components/ServiceModal';
import { defaultColumns, defaultFilters } from './lib/helpers';

const Services = () => {
  const { isOpen, openModal, closeModal } = useModal();

  const columnDefs = useMemo(() => defaultColumns(), []);
  const initialFilters = useMemo(() => defaultFilters(), []);

  return (
    <>
      <Allowed requiredPermissions="services.read" showMessage={true} showIllustration={true}>
        <AgTablePage
          ModalComponent={ServiceModal}
          defaultColumns={columnDefs}
          defaultFilters={initialFilters}
          useData={useServices}
          name="services"
          queryParams={{ forPractice: true, getPayers: true }}
          headerActions={
            <Button
              text="Load SOF"
              size="small"
              textClassName="whitespace-nowrap"
              onClick={openModal}
            />
          }
        />
        {isOpen && <ScheduleOfFeesModal isOpen={isOpen} closeModal={closeModal} />}
      </Allowed>
    </>
  );
};

export default Services;
