import cs from 'classnames';
import React from 'react';

import Tippy from '@tippyjs/react';
import { procedureTypes } from '../../../../../../constants';
import { Capitalize, ia, iaRa } from '../../../../../../lib/helpers/utility';
import CurrencyInput from '../../../../../shared/CurrencyInput/CurrencyInput';
import Icon from '../../../../../shared/Icon/Icon';
import Input from '../../../../../shared/Input/Input';
import InputOptions from '../../../../../shared/InputOptions/InputOptions';
import {
  handleChangePrice,
  handleChangePriceProcedures,
  handleChangeQuantity,
  handleChangeUnitPrice,
  handleProcedureType,
  removeItem
} from '../../../appointmentHelpers';
import { shouldDisableProcedureProduct } from '../lib/shouldDisableProcedureProduct';
import { orderBy } from 'lodash';
import Allowed from 'components/shared/Permissions/Allowed';

const SelectedItems = ({
  selectedItems,
  servicesObject,
  type,
  label,
  values,
  setFieldValue,
  list,
  isClinicalNoteLocked = false,
  originalProceduresProducts
}) => {
  selectedItems = orderBy(selectedItems, [(item) => item?.created_at || new Date('9999-12-31')]);

  const deleteHandler = (id, index) => {
    removeItem(setFieldValue, selectedItems, type, index);
  };

  return (
    <div className="!my-4">
      {iaRa(selectedItems)?.map?.((item, i) => {
        const {
          id,
          name,
          quantity,
          total_cost_cents,
          invoiceId,
          pt_balance,
          procedure_code,
          type: itemType,
          modified
        } = item || {};

        const shouldDeleteBeDisabled = shouldDisableProcedureProduct({
          isClinicalNoteLocked,
          originalProceduresProducts,
          productId: id,
          procedureCode: procedure_code,
          type
        });

        // const tippyContent = ERAPosted
        //   ? 'The service cannot be removed because the ERA has already been posted.'
        //   : `${type ? Capitalize(type) : 'Item'} cannot be removed because the clinical note is locked.`;

        const tippyContent = `${type ? Capitalize(type) : 'Item'} cannot be removed because the clinical note is locked.`;

        return (
          <TippyContainer
            content={tippyContent}
            shouldDeleteBeDisabled={shouldDeleteBeDisabled}
            key={'selected' + i}>
            <div className="!mb-2 flex w-full items-start gap-[10px]" key={'selected' + i}>
              <div className={cs('grid w-full', type === 'procedures' && 'min-w-[70%]')}>
                {i === 0 && label === 'products' ? (
                  <p className="mb-[6px] text-sm font-500 text-neutral-800">{label}</p>
                ) : i === 0 && !ia(values.packages) ? (
                  <p className="mb-[6px] text-sm font-500 text-neutral-800">{label}</p>
                ) : null}
                <div
                  className={cs(
                    'flex h-10 items-center justify-between rounded-md !border border-solid border-neutral-100 bg-neutral-50 !px-3 !py-2'
                  )}>
                  <div className="grid overflow-hidden">
                    <p title={name} className="flex items-center truncate text-sm text-neutral-800">
                      {id &&
                      (type === 'procedures'
                        ? !!JSON.parse(servicesObject[id] || `null`)
                        : servicesObject[id]) ? (
                        <div className="!mr-2 inline items-center justify-center rounded-full">
                          <img
                            src={
                              type === 'procedures'
                                ? `https://cdn.filestackcontent.com/${
                                    JSON.parse(servicesObject[id] || `{}`)?.jpg
                                  }`
                                : servicesObject[id]
                            }
                            className="h-6 w-6 rounded-md object-cover"
                          />
                        </div>
                      ) : (
                        <Icon icon="new-no-img" className="!mr-2 -mb-1 inline" />
                      )}
                      {name}
                    </p>
                  </div>

                  {type === 'procedures' ? (
                    !item?.invoiceId && ia(procedureTypes) ? (
                      <InputOptions
                        position="top"
                        options={
                          procedure_code
                            ? procedureTypes
                            : procedureTypes.filter((t) => t?.value !== 'primary')
                        }
                        currentOption={
                          procedureTypes.find(
                            (o) => o?.value === (item?.type === 'cash' ? 'self_pay' : item?.type)
                          )?.label
                        }
                        onClickOption={(option) =>
                          handleProcedureType(setFieldValue, selectedItems, i, option, type)
                        }
                      />
                    ) : (
                      <label className="text-500 !mb-0 text-sm text-neutral-500">
                        {item.invoiceId ? 'Package' : 'Service'}
                      </label>
                    )
                  ) : null}
                </div>
              </div>

              {type === 'procedures' && values?.id && (
                <Allowed requiredPermissions="payments.read">
                  <CurrencyInput
                    // value={
                    //   charge_type === 'copay' && itemType === 'primary'
                    //     ? pt_balance
                    //     : modified
                    //     ? pt_balance
                    //     : total_cost_cents
                    // }
                    value={
                      itemType === 'self_pay' || itemType === 'cash'
                        ? modified
                          ? pt_balance
                          : total_cost_cents
                        : pt_balance
                    }
                    wrapperClassName="!px-[8px]"
                    label={i === 0 && 'Est. Pt. Portion'}
                    disabled={!!invoiceId}
                    onValueChange={(v) =>
                      handleChangePriceProcedures(type, setFieldValue, selectedItems, v, i)
                    }
                  />
                </Allowed>
              )}

              {type === 'products' && (
                <>
                  <Allowed requiredPermissions="payments.read">
                    <CurrencyInput
                      className="!w-16"
                      wrapperClassName="!px-[8px]"
                      label={i === 0 && 'Unit Price'}
                      value={item?.amount_cents}
                      onValueChange={(v) =>
                        handleChangeUnitPrice(setFieldValue, selectedItems, v, item?.id)
                      }
                      disabled={true}
                    />
                  </Allowed>
                  <Input
                    min={1}
                    id={quantity + i}
                    type="number"
                    inputClassName="!w-8"
                    value={item.sales_count}
                    label={i === 0 && 'Qty'}
                    disabled={shouldDeleteBeDisabled}
                    onChange={(e) =>
                      handleChangeQuantity(
                        setFieldValue,
                        selectedItems,
                        e.target.value,
                        item?.id,
                        list
                      )
                    }
                  />
                  <Allowed requiredPermissions="payments.read">
                    <CurrencyInput
                      className="!w-[75px]"
                      wrapperClassName="!px-[8px]"
                      label={i === 0 && 'Price'}
                      id={`total_amount_cents-${i}`}
                      value={item?.total_amount_cents}
                      onValueChange={(v) =>
                        handleChangePrice(setFieldValue, selectedItems, v, item?.id)
                      }
                      disabled={true}
                    />
                  </Allowed>
                </>
              )}

              <Icon
                icon="trash"
                disabled={shouldDeleteBeDisabled}
                className={
                  (i === 0 && label === 'products') || (i === 0 && !ia(values.packages))
                    ? 'pt-[39px]'
                    : 'pt-[12px]'
                }
                onClick={() => deleteHandler(id, i)}
                data-qa="remove-procedure-products-icon"
              />
            </div>
          </TippyContainer>
        );
      })}
    </div>
  );
};

const TippyContainer = ({ shouldDeleteBeDisabled = false, children, content }) => {
  return (
    <Tippy className="tippy-dark" content={content} disabled={!shouldDeleteBeDisabled}>
      <div>{children}</div>
    </Tippy>
  );
};

export default SelectedItems;
