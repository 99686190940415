import React from 'react';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const UnsavedChanges = ({ isUnsaved, setIsUnsaved, handleClose }) => (
  <Confirm
    handleOpen={isUnsaved}
    handleClose={() => setIsUnsaved(false)}
    handleContinue={handleClose}
    title="Unsaved changes"
    message="You have unsaved changes. Do you want to close this modal?"
    primaryBtnTxt="Yes"
    secondaryBtnTxt="No"
    icon="new-calendar-red"
    variant="danger"
  />
);

export default UnsavedChanges;
