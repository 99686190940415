import React, { useState } from 'react';
import cs from 'classnames';
import NotFound from 'components/NotFound';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import Modal from 'components/shared/Modal/Modal';
import useComponentAdd from '../../hooks/useComponentAdd';
import useChatInput from '../../hooks/useChatInput';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import NoteTypeButtons from './NoteTypeButtons';
import DescriptionSection from './DescriptionSection';
import ComponentButtons from './ComponentButtons';
import {
  useMyScribeTemplateContextApi,
  useMyScribeTemplateContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/MyScribeTemplateContext';
import PromptMessages from './PromptMessages';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import useNoteType from '../../hooks/useNoteType';
import { getSelectedForms } from '../../lib/helpers';
import '../../../../MyScribeAI.scss';

const Chat = () => {
  const breadcrumb = useBreadcrumb();
  const { selectedPromptNoteType } = useNoteType();
  const {
    componentToAdd,
    inputTags,
    selectedTableRows,
    onAddComponent,
    onTagRemove,
    onTagPreview,
    setComponentToAdd
  } = useComponentAdd();
  const { inputValue, disableGenerate, onTextChange } = useChatInput();
  const { currentActivePromptId } = useMyScribeTemplateContextData();
  const { setCurrentActivePromptId } = useMyScribeTemplateContextApi();
  const { currentHpOverviewData, clinicalNote, setEnhanceNarrativeParams } =
    useClinicalNoteContext() || {};

  const selectedPromptTagsIds = inputTags.map((tag) => tag.id);
  let selectedForms = getSelectedForms(
    selectedPromptTagsIds,
    clinicalNote,
    selectedTableRows,
    currentHpOverviewData
  );

  const Input = componentToAdd?.forms || NotFound;

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  const onGenerate = () => {
    const finalPrompt = {
      clinical_note_id: clinicalNote?.id,
      prompt: inputValue,
      name: selectedPromptNoteType?.type,
      selectedForms
    };
    setEnhanceNarrativeParams({
      kind: 'myscribe-ai',
      messageKind: 'auto_map',
      body: finalPrompt
    });
    console.log('🚀 ~ Chat ~ finalPrompt:', finalPrompt);
  };

  return (
    <div className="relative flex h-full w-full flex-col pb-10">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-10 z-10 bg-primary-10" />
      <NoteTypeButtons />
      <div className="relative z-20 flex-grow overflow-y-auto">
        <div className="max-width-[760px] mx-auto">
          {currentActivePromptId ? <PromptMessages /> : <DescriptionSection />}
        </div>
      </div>
      <div className="relative bottom-0 z-50 w-full flex-none bg-white">
        <div
          className="absolute top-[-32px] z-50 h-[32px] w-full self-center bg-gradient-to-b from-white/70 to-white backdrop-blur-md"
          style={{ backdropFilter: 'blur(2px)' }}
        />
        <div className="z-50 mx-auto flex max-w-[760px] flex-col">
          {!currentActivePromptId ? (
            <ComponentButtons onTagPreview={onTagPreview} onTagRemove={onTagRemove} />
          ) : (
            <div className="mb-2 flex items-center justify-center gap-2">
              <Button
                onClick={() => setCurrentActivePromptId(null)}
                className="z-30 max-w-[200px]"
                text="Accept & Map Response"
                size="small"
              />
              <Popover
                options={[{ label: 'Test Options', onClick: () => {}, icon: 'new-calendar-note' }]}
                isFixed
                buttonClassName="text-neutral-600 !outline !outline-1 !outline-neutral-200 border-neutral-200 bg-white !min-h-[28px] !min-w-[28px]"
                className="self-center"
                optionIconColor="neutral"
              />
            </div>
          )}
          <div
            className={cs(
              'mb-[25px] flex flex-col items-center px-4',
              currentActivePromptId && 'pb-[50px] md:pb-[100px]'
            )}>
            <AIChatInput
              isHTML
              className="mb-3 min-h-[124px] border-2 !border-neutral-200 shadow-none outline outline-1 outline-neutral-200"
              leftButtons={[]}
              rightButtons={[
                <Button
                  key={1}
                  onClick={() => {}}
                  icon="new-microphone"
                  iconSize={18}
                  className="z-30 mx-2 !bg-white !px-[15px]"
                  color="neutral"
                />,
                <Button
                  key={2}
                  icon="sent"
                  iconSize={18}
                  iconColor="white"
                  size="small"
                  className={cs('outline-0', disableGenerate ? 'bg-neutral-100' : 'bg-primary-500')}
                  onClick={onGenerate}
                  disabled={disableGenerate}
                  type="secondary"
                  color={disableGenerate ? 'neutral' : 'white'}
                />
              ]}
              onTextChange={onTextChange}
              onSubmit={onGenerate}
              placeholder="Type anything.."
              value={inputValue}
              tags={inputTags}
              onTagRemove={onTagRemove}
              onTagPreview={onTagPreview}
              getTagClassName={getTagClassName}
            />
            <p className="text-center text-xs italic text-neutral-700">
              AI can make mistakes. Please review & understand providers are responsible for final
              clinical note.
            </p>
          </div>
        </div>
      </div>
      {componentToAdd && (
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={!!componentToAdd}
          handleClose={() => setComponentToAdd(null)}
          isFooterSticky
          className="h-[896px] w-[1160px] min-w-[480px]"
          bodyClassName="min-h-[400px] bg-primary-10 !p-0"
          customHeader={
            <div className="flex w-full items-center justify-between">
              <Breadcrumb breadcrumb={breadcrumb} key={breadcrumb} />
              {componentToAdd?.headerButton?.({ breadcrumb }) || null}
            </div>
          }
          isFooter={false}>
          {componentToAdd?.content ? (
            <div className="flex h-full flex-col">
              {componentToAdd?.content({ breadcrumb, onAddComponent })}
            </div>
          ) : (
            <div className="mb-[112px] p-2 px-4">
              <Input
                inputName={componentToAdd.id}
                title={componentToAdd?.text}
                type={componentToAdd?.type}
                breadcrumb={breadcrumb}
              />
              <ModalFooter
                rightButtons={[
                  inputTags?.find((tag) => tag?.id === componentToAdd?.id) && (
                    <Icon
                      key={1}
                      icon="trash"
                      className="cursor-pointer"
                      onClick={() => onTagRemove(componentToAdd?.id)}
                    />
                  ),
                  <Button
                    key={2}
                    text={`Add ${componentToAdd?.text}`}
                    iconSize={18}
                    iconColor="white"
                    size="small"
                    className="ml-4 !py-2 !pl-4 !pr-4"
                    onClick={onAddComponent}
                  />
                ]}
                leftButtons={
                  <Button
                    key={1}
                    text="Back"
                    outlined
                    transparent
                    size="small"
                    className="!py-2 !pl-4 !pr-4"
                    onClick={() => setComponentToAdd(null)}
                  />
                }
              />
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};

export default Chat;
