import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const Disclaimer = () => {
  return (
    <div className="mx-auto max-w-[650px] !pt-4">
      <p className="text-center text-sm leading-[22px] text-neutral-600">
        This is a PCI & HIPAA-Compliant, encrypted card storage method with the official card-brand
        approved Pre Authorized Healthcare Form.
      </p>
      <div className="mx-auto flex justify-center gap-2 !pt-4">
        <div className="flex h-[32px] w-[46px] items-center justify-center overflow-hidden !rounded-md border border-[#F3F4F6]">
          <Icon icon="new-payment-visa" />
        </div>
        <div className="flex h-[32px] w-[46px] items-center justify-center overflow-hidden !rounded-md border border-[#F3F4F6]">
          <Icon icon="new-payment-mastercard" />
        </div>
        <div className="flex h-[32px] w-[46px] items-center justify-center overflow-hidden !rounded-md border border-[#F3F4F6]">
          <Icon icon="new-payment-discover" />
        </div>
      </div>
      <p className="!mt-4 text-center text-sm italic leading-[22px] text-neutral-600">
        Amex may be used as an alternate depending on the practice policy
      </p>
    </div>
  );
};

export default Disclaimer;
