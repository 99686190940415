import React, { useEffect, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import _ from 'lodash';

import { getAction, getType } from 'api/Endpoints';
import { getPatients } from 'api/Patients';

import formatDate, { mapValues } from 'lib/helpers/utility';
import { useAuditLogs } from 'lib/hooks/queries/auditLogs/useAuditLogs';

import Filter from 'components/shared/Filter/Filter';
import extractIds from 'components/shared/Logs/lib/extractIds';
import Allowed from 'components/shared/Permissions/Allowed';

import { useTableContext } from '../../../lib/context/TableContext/TableContext';
import { TableContextProvider } from '../../../lib/context/TableContext/TableContextProvider';
import usePageTitle from '../../../lib/hooks/usePageTitle';
import AGTable from '../../shared/AGTable/AGTable';
import DisplayButton from '../../shared/AGTable/DisplayButton';
import Header from '../../shared/Header/Header';
import Pagination from '../../shared/Pagination/Pagination';

import searchEntity from './lib/searchEntity';

export default function AuditLogs() {
  const navigate = useNavigate();
  const columns = [
    {
      field: 'user',
      headerName: 'User'
    },
    { field: 'type', headerName: 'Type' },
    { field: 'action', headerName: 'Action' },
    { field: 'description', headerName: 'Description' },
    {
      field: 'patients',
      headerName: 'Patients'
    },
    {
      field: 'date',
      headerName: 'Date'
    }
  ];

  const searchPatients = async (searchTerm, navigate) => {
    const results = await getPatients(navigate, {
      limit: 20,
      offset: 0,
      searchTerm,
      type: 'patients'
    });

    const patients = results.patients.map((patient) => ({
      label: patient.fullName,
      value: patient.id
    }));
    return patients;
  };

  const searchType = async (searchTerm, navigate) => {
    const results = await getType(navigate, {
      limit: 20,
      offset: 0,
      searchTerm
    });

    const type = results.type.map((type) => ({
      label: type,
      value: type
    }));
    return type;
  };

  const searchAction = async (searchTerm, navigate) => {
    const results = await getAction(navigate, {
      limit: 20,
      offset: 0,
      searchTerm
    });

    const action = results.action.map((action) => ({
      label: action,
      value: action
    }));
    return action;
  };

  const defaultAuditLogFilters = {
    date: {
      values: {
        startDate: null,
        endDate: null
      },
      type: 'date-range',
      title: 'Date',
      preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
    },
    user: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'User',
      loadOptions: (searchTerm) =>
        searchEntity('practice/member/search', searchTerm, navigate, 'members')
    },
    patient: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'Patients',
      loadOptions: (searchTerm) => searchPatients(searchTerm, navigate)
    },
    type: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'Type',
      loadOptions: (searchTerm) => searchType(searchTerm, navigate)
    },
    action: {
      type: 'search',
      multiple: true,
      values: [],
      options: [],
      title: 'Action',
      loadOptions: (searchTerm) => searchAction(searchTerm, navigate)
    }
  };

  return (
    <TableContextProvider
      cols={columns}
      pagination
      name="audit_logs"
      defaultFilters={defaultAuditLogFilters}>
      <Table defaultAuditLogFilters={defaultAuditLogFilters} />
    </TableContextProvider>
  );
}

function Table({ defaultAuditLogFilters }) {
  usePageTitle('Audit Logs');
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const [totalItems, setTotalItems] = useState((page + 1) * limit);

  const [auditLogFilters, setAuditLogFilters] = useState(defaultAuditLogFilters);

  const {
    data = {},
    isLoading,
    isFetching
  } = useAuditLogs({
    params: getAuditLogsParams({ auditLogFilters, limit, page }),
    dependencies: [page, limit, mapValues(auditLogFilters)],
    page,
    sort
  });

  const logs = data.logs;

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    if (data?.total) {
      if (data.total < totalItems) {
        setTotalItems(data.total);
        setPage(Math.ceil(data.total / limit));
      } else {
        setTotalItems((page + 1) * limit);
      }
    }
  }, [data]);

  function onPageChange({ selected }) {
    setPage(selected + 1);
  }

  useEffect(() => {
    setTotalItems((page + 1) * limit);
  }, [limit]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  if (id) return <Outlet />;

  return (
    <>
      <Allowed requiredPermissions="user_events.read" showMessage={true} showIllustration={true}>
        <Header title="Audit Logs">
          <div className="flex items-center gap-2">
            <DisplayButton />
            <Filter
              category="audit_logs"
              filters={auditLogFilters}
              defaultFilters={defaultAuditLogFilters}
              setFilters={setAuditLogFilters}
              menuPortalTarget={document.body}
            />
          </div>
        </Header>
        <div className="flex h-full flex-col  overflow-hidden !p-3 !pb-2">
          <div className="ag-theme-quartz !mb-0  h-full">
            <AGTable
              data={logs}
              defaultColDef={defaultColDef}
              suppressRowClickSelection={true}
              customClassName="ag-grid-interactive"
              loading={isLoading || isFetching}
            />
          </div>
          <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
            {logs?.length > 0 && (
              <p className="font-light text-primary-900">
                Showing {(page - 1) * limit} - {page * limit}
              </p>
            )}
            <Pagination
              containerClassName="flex"
              onPageChange={onPageChange}
              perPage={limit}
              totalItems={totalItems}
              page={page}
            />
          </div>
        </div>
      </Allowed>
    </>
  );
}

function getAuditLogsParams({ auditLogFilters, limit, page }) {
  const getIds = (filter) => (filter?.values?.length ? extractIds(filter.values) : undefined);
  const action = auditLogFilters?.action?.values?.map((item) => item.value);
  const type = auditLogFilters?.type?.values?.map((item) => item.value);

  return {
    limit,
    page,
    user: getIds(auditLogFilters?.user),
    patient: getIds(auditLogFilters?.patient),
    type,
    action,
    startDate: auditLogFilters.date?.values?.startDate,
    endDate: auditLogFilters?.date?.values?.endDate
  };
}
