import { requestApi } from './Api';

export const getUserBoard = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/boards/get',
    params
  });

export const upsertUserBoard = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/boards/upsert',
    params
  });

export const customizeUserBoard = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/user/boards/customize',
    params
  });
