import React, { memo, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import Loader from 'components/shared/Loader/Loader';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const Complaints = ({ sectionRef }) => {
  const intelligenceState = useRecoilValue(intelligenceAiState);
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const { setEnhanceNarrativeParams, isFetchingEnhanceNarrative } = useClinicalNoteContext();
  const complaintsRef = useRef(null);
  const otherComplaintsRef = useRef(null);
  const { type } = useParams();

  const handleChange = (e, key) => {
    const name = e?.target?.name;
    const value = e?.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  useImperativeHandle(sectionRef, () => ({
    formData: {
      present_illness: clinicalNote?.present_illness,
      other_complaints: clinicalNote?.other_complaints
    }
  }));

  const handleEnhanceNarrative = (key) => {
    setEnhanceNarrativeParams({
      kind: type,
      name: key,
      body: clinicalNote?.[key],
      customFormId: null
    });
  };

  return (
    <div className="grid grid-cols-[repeat(auto-fit,minmax(280px,1fr))] gap-4">
      <Textarea
        isEditor
        label={
          <div className="flex flex-row gap-4">
            <div>Chief complaints</div>
            {isFetchingEnhanceNarrative && intelligenceState.path.includes('present_illness') && (
              <Loader outlined color="primary" />
            )}
          </div>
        }
        placeholder="Type chief complaints if you have any"
        transcribing
        value={clinicalNote?.present_illness}
        forwardedRef={complaintsRef}
        className="min-h-[200px]"
        id="present_illness"
        data-qa="present-illness"
        name="present_illness"
        onChange={(e) => handleChange(e, 'present_illness')}
        disabled={clinicalNote?.locked}
        options={[
          {
            title: 'Enhance with MyScribe AI',
            icon: 'new-myscribe-stars',
            onClick: () => handleEnhanceNarrative('present_illness'),
            loading: isFetchingEnhanceNarrative
          }
        ]}
      />
      <Textarea
        isEditor
        label={
          <div className="flex flex-row gap-4">
            <div>Other complaints</div>
            {isFetchingEnhanceNarrative && intelligenceState.path.includes('other_complaints') && (
              <Loader outlined color="primary" />
            )}
          </div>
        }
        placeholder="Type other complaints if you have any"
        transcribing
        className="min-h-[200px]"
        forwardedRef={otherComplaintsRef}
        value={clinicalNote?.other_complaints}
        name="other_complaints"
        id="other_complaints"
        data-qa="other-complaints"
        onChange={(e) => handleChange(e, 'other_complaints')}
        disabled={clinicalNote?.locked}
        options={[
          {
            title: 'Enhance with MyScribe AI',
            icon: 'new-myscribe-stars',
            onClick: () => handleEnhanceNarrative('other_complaints'),
            loading: isFetchingEnhanceNarrative
          }
        ]}
      />
    </div>
  );
};

export default memo(Complaints);
