import {
  appointmentStatuses as aptStatuses,
  currentPractice
} from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TableCounter from 'components/shared/Table/TableCounter';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { ia, mapValues } from 'lib/helpers/utility';
import { useProviders } from 'lib/hooks/queries/billing/useProviders';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';
import { useServices } from 'lib/hooks/queries/services/useServices';
import usePageTitle from 'lib/hooks/usePageTitle';
import React, { useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import ClinicalNotePDF from './components/ClinicalNotePDF';
import ExportModal from './components/ExportModal';
import HeaderActions from './components/HeaderActions';
import { defaultColumns, getPracticeDefaultFilters, GRID_OPTIONS } from './lib/helper';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';
import { MyScribeAIContextProvider } from 'lib/context/MyScribeAI/MyScribeAIContextProvider';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';
import { useNavigate } from 'react-router-dom';

const ClinicalNotes = () => {
  const practice = useRecoilValue(currentPractice);
  const appointmentStatuses = useRecoilValue(aptStatuses);

  return (
    <TableContextProvider
      defaultFilters={getPracticeDefaultFilters(practice)}
      cols={defaultColumns(practice?.timezone, appointmentStatuses)}
      name="clinical-notes"
      pagination>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  usePageTitle('Clinical Notes');
  const category = 'clinical-notes';
  const practice = useRecoilValue(currentPractice);
  const appointmentStatuses = useRecoilValue(aptStatuses);
  const navigate = useNavigate();

  const [exportModal, setExportModal] = useState(false);
  const [documentIds, setDocumentIds] = useState([]);
  const [downloadPDF, setDownloadPDF] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const renderedRef = useRef([]);

  const { limit, page, setPage, sort, filters, setFilters, selectedRows, setSelectedRows } =
    useTableContext();
  const { data: providers = {} } = useProviders({});

  const { data, isLoading, isFetching } = useClinicalNote({
    params: {
      forPractice: true,
      pagination: true,
      withDocumentSign: true,
      withPatient: true,
      limit,
      page,
      sort,
      filters: mapValues(filters)
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const { data: procedures } = useServices({});

  const { data: icdList } = useICD10Codes({ params: { withMostUserSearchClicks: true } });

  const providerOptions = useMemo(() => {
    if (providers?.practitioners?.length > 0) {
      return providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
    }
    return [];
  }, [providers]);

  filters.provider.options = providerOptions;

  const serviceOptions = useMemo(() => {
    if (procedures?.services?.length > 0) {
      return procedures.services.map((s) => ({
        label: s?.name,
        value: s?.id
      }));
    }
    return [];
  }, [procedures]);
  filters.cpt.options = serviceOptions;

  const icdOptions = useMemo(() => {
    if (icdList?.icd10?.length > 0) {
      return icdList?.icd10?.map((i) => ({
        label: i?.name,
        value: i?.code
      }));
    }
    return [];
  }, [icdList]);

  filters.icd.options = icdOptions;

  const clinicalNotes = data?.clinicalNote || [];
  const count = data?.count?.count || 0;

  const onCellClicked = async (e) => {
    if (e?.column?.getColId() === 'actions' || e?.column?.getColId() === 'checkbox') return;
    if (['actions', '0'].includes(e?.column?.colId) || e?.column?.colId === '1') return;
    navigate(
      `/portal/charts/${e?.data?.appointment.user_id}/clinical-notes/${e?.data?.appointment.id ?? e?.appointment?.id}`
    );
  };
  return (
    <>
      <Header title="Clinical Notes">
        <div className="flex items-center gap-2">
          {ia(selectedRows) && selectedRows.length > 0 && (
            <HeaderActions
              setDocumentIds={setDocumentIds}
              setExportModal={setExportModal}
              setButtonLoading={setButtonLoading}
              setDownloadPDF={setDownloadPDF}
              buttonLoading={buttonLoading}
            />
          )}
          <DisplayButton />
          <Filter
            category={category}
            defaultFilters={getPracticeDefaultFilters(practice)}
            filters={filters}
            setFilters={setFilters}
            menuPortalTarget={document.body}
            btnClassName="!h-[30px]"
          />
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? (
            <Skeleton count={limit} />
          ) : (
            <ClinicalNoteContextProvider outsideClinicalNoteContext={true}>
              <Chart3dContextProvider>
                <MyScribeAIContextProvider>
                  <AmbientListeningContextProvider>
                    <AGTable
                      data={clinicalNotes}
                      rowSelection="multiple"
                      onCellClicked={onCellClicked}
                      columnDef={defaultColumns(practice?.timezone, appointmentStatuses)}
                      suppressRowClickSelection={true}
                      customClassName="ag-grid-interactive"
                      loading={isLoading || isFetching}
                      gridOptions={GRID_OPTIONS}
                    />
                  </AmbientListeningContextProvider>
                </MyScribeAIContextProvider>
              </Chart3dContextProvider>
            </ClinicalNoteContextProvider>
          )}
        </div>
        <div className="flex items-center justify-between px-3">
          <TableCounter page={page} limit={limit} count={count && count} />
          <Pagination
            onPageChange={({ selected }) => setPage(selected + 1)}
            totalItems={count ? count : null}
            page={page}
            perPage={limit}
          />
        </div>
      </div>
      {exportModal && (
        <ExportModal
          exportModal={exportModal}
          setExportModal={setExportModal}
          documentIds={documentIds}
        />
      )}
      {downloadPDF && (
        <ClinicalNotePDF
          renderedRef={renderedRef}
          itemProps={documentIds}
          setButtonLoading={setButtonLoading}
          setSelectedRows={setSelectedRows}
          downloadPDF
        />
      )}
    </>
  );
}

export default withErrorBoundary(ClinicalNotes);
