import { useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { clinicalNote as clinicalNoteState } from 'components/state';

import Accordion from '../../../../shared/Accordion';

import Subjective from './Subjective';

const SubjectiveSection = ({
  components,
  sectionRef,
  options,
  withStyle = true,
  showDisclaimer = true
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const subjectiveRef = useRef(null);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      title={components?.subjective?.title}
      id={components?.subjective?.id}
      disabled={clinicalNote?.locked}
      options={options}>
      <Subjective
        sectionRef={sectionRef}
        subjectiveRef={subjectiveRef}
        showDisclaimer={showDisclaimer}
      />
    </Accordion>
  );
};

export default SubjectiveSection;
