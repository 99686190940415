import React, { useEffect, useRef, useState } from 'react';
import Popover from 'components/shared/Popovers/Popover/Popover';
import DropdownPanel from '../DropdownPanel';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { ia } from 'lib/helpers/utility';
import _ from 'lodash';
import TagCreate from '../Create';
import cs from 'classnames';
import DynamicView from '../View/DynamicView';

const NewTagSelect = ({
  currentTags = [],
  kind = 'appointment',
  disabled = false,
  setTagIds = () => {},
  emptyStateText = 'No appointment tags',
  resetTags = false,
  outlined = false
}) => {
  const { data } = useTags({ params: { kind }, dependencies: [kind] });
  const tags = data?.tags || [];
  const { device } = useUIContext();
  const parentRef = useRef(null);
  const [selectedTags, setSelectedTags] = useState(currentTags || []);
  const [isCreateModal, setIsCreateModal] = useState(false);

  const scrollPositionRef = useRef(0);
  const panelRef = useRef(null);

  useEffect(() => {
    if (panelRef.current) {
      panelRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [selectedTags]);

  useEffect(() => {
    if (resetTags) setSelectedTags([]);
  }, [resetTags]);

  useEffect(() => {
    if (ia(currentTags)) setSelectedTags(currentTags);
  }, [currentTags]);

  useEffect(() => {
    const latestTags = getLatestTagUpdates();
    setSelectedTags(latestTags);
  }, [tags]);

  const getLatestTagUpdates = () => {
    if (!ia(tags) || !ia(selectedTags)) return currentTags;
    return selectedTags?.map((tag) => {
      const matchingTag = tags?.find((allTag) => allTag?.id === tag?.id);
      if (matchingTag && !_.isEqual(tag, matchingTag)) {
        return { ...matchingTag };
      } else if (matchingTag) {
        return { ...tag };
      } else {
        return null;
      }
    });
  };

  const handleScroll = () => {
    if (panelRef.current) {
      scrollPositionRef.current = panelRef.current.scrollTop;
    }
  };

  const handleUpdateTags = (tag) => {
    let updatedTags;
    if (selectedTags.some((selectedTag) => selectedTag?.id === tag?.id)) {
      updatedTags = selectedTags.filter((selectedTag) => selectedTag?.id !== tag?.id);
    } else {
      updatedTags = [...selectedTags, tag];
    }
    setSelectedTags(updatedTags);
    const tagIds = updatedTags.map((tag) => tag?.id);
    setTagIds(tagIds);
  };

  const actions = [
    {
      label: 'Create Tag',
      icon: 'new-tag-new',
      onClick: () => setIsCreateModal(true),
      disabled: false
    },
    {
      label: 'Manage Tags',
      icon: 'new-tag-settings',
      onClick: () => window.open('/portal/settings/tags', '_blank'),
      disabled: false
    }
  ];

  return (
    <div
      ref={parentRef}
      className={cs(
        'flex h-[40px] items-center justify-between gap-1',
        disabled && 'pointer-events-none',
        outlined
          ? 'rounded-md border border-solid border-neutral-200 bg-white'
          : 'rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] '
      )}>
      {ia(selectedTags) ? (
        <DynamicView parentRef={parentRef} tags={selectedTags} handleRemove={handleUpdateTags} />
      ) : (
        <p
          className={cs(
            'pl-3 text-sm font-400',
            outlined ? 'text-neutral-500' : 'text-neutral-600'
          )}>
          {emptyStateText}
        </p>
      )}
      <Popover
        isFixed
        isDropdown
        position="right"
        icon="new-tag-new"
        text={device !== 'mobile' && 'Add Tags'}
        iconSize={device !== 'mobile' ? 18 : 16}
        buttonClassName="min-w-[150px] whitespace-nowrap !text-primary-50 !pl-0"
        panelClassName="mt-[6px] !p-0"
        buttonTheme="transparent"
        scrollPositionRef={scrollPositionRef}>
        {({ closePopover }) => (
          <DropdownPanel
            tags={tags}
            selectedTags={selectedTags}
            handleUpdateTags={handleUpdateTags}
            actions={actions}
            panelRef={panelRef}
            onScroll={handleScroll}
            scrollPositionRef={scrollPositionRef}
            closePopover={closePopover}
            tagsKind={kind}
          />
        )}
      </Popover>
      {isCreateModal && (
        <TagCreate
          isOpen={isCreateModal}
          handleClose={() => setIsCreateModal(false)}
          kind={kind}
          addNewTagFromCreated={handleUpdateTags}
        />
      )}
    </div>
  );
};

export default NewTagSelect;
