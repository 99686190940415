import { requestApi } from './Api';

export const getMacros = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/macro/read',
    params
  });

export const createMacro = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/macro/create',
    params
  });

export const updateMacro = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/macro/update',
    params
  });

export const deleteMacro = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/macro/delete',
    params
  }); 