import { Link, useLocation, useNavigate } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { requestApi } from 'api/Api';

import usePageTitle from 'lib/hooks/usePageTitle';

import { ownerPractices, ownerSelectedLocation } from 'components/practice/practiceState';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';

import { patientNavbarOptions } from './lib/constants';

import './PatientNavbar.scss';

const PatientNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useRecoilState(state.userState);
  const setOwnerPractices = useSetRecoilState(ownerPractices);
  const setOwnerSelectedLocation = useSetRecoilState(ownerSelectedLocation);

  const logout = async () => {
    try {
      await requestApi({ url: '/api/user/logout', navigate, params: {} });
      sessionStorage.removeItem('myriad-session-reference-id');
      setUser({
        user_id: '',
        f_name: '',
        l_name: '',
        p_name: '',
        email: '',
        state: '',
        kind: '',
        logged_in: false,
        profile_photo: null
      });
      setOwnerPractices([]);
      setOwnerSelectedLocation(null);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const name =
    user.p_name || (user.f_name && user.l_name ? user.f_name + ' ' + user.l_name : 'Welcome');

  const currentPage = patientNavbarOptions.find((option) => option.path === pathname);
  const pageTitle = currentPage?.label || 'Dashboard';

  usePageTitle(pageTitle);

  const handleClick = (path) => {
    if (path) {
      navigate(path);
    } else {
      logout();
    }
  };

  const options = patientNavbarOptions.map((option) => ({
    ...option,
    onClick: () => handleClick(option.path)
  }));

  return (
    <nav className="PatientNavbar z-20 bg-neutral-100">
      <Link to="/myportal">
        <Icon icon="logo" size={120} />
      </Link>
      <div className="text-2xl font-800 text-primary-900">{pageTitle}</div>
      {user.email ? (
        <Popover
          isDropdown
          options={options}
          icon={
            <Avatar firstName={name} id={user?.user_id} image={user?.profile_photo} size="large" />
          }
          text={name}
          buttonClassName="!p-2 !min-w-[180px]"
          position="left"
          buttonTheme="neutral"
          panelClassName="min-w-[180px]"
        />
      ) : (
        <Button text="Login" onClick={() => navigate('/login')} />
      )}
    </nav>
  );
};

export default PatientNavbar;
