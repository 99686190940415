import { ia } from 'lib/helpers/utility';

export const formatSeconds = ({ seconds }) => {
  if (!seconds) return '00:00';

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};

export const replaceSpeakerWithBrackets = ({ text = null }) => {
  if (!text) return text;

  // Matches instances of "Speaker" followed by numbers (\d+) and optional colon, within brackets or not.
  const regex = /(\[Speaker (\d+):?\])|Speaker (\d+)(:?)(:?)(:?) ?/g;

  return text.replace(regex, (match, brackets, num1, num2) => {
    const number = num1 || num2;

    return `[Speaker ${number}] `;
  });
};

export const mergeTexts = ({ currentText = null, newText = null }) => {
  const mergedText = [currentText ?? '', newText ?? ''].join(' ');

  return replaceSpeakerWithBrackets({ text: mergedText });
};

export const replaceSpeakers = ({ text = null, speakers = [] }) => {
  if (!ia(speakers)) return text;

  const regex = /\[([^[\]]+)\]/g; // Dynamic regex to match anything inside brackets

  return text.replace(regex, (match, label) => {
    const speaker = speakers.find((s) => s.old_name === label);
    if (speaker) {
      return `[${speaker.new_name}]`;
    } else {
      return match; // Keep unchanged if not found in speakers
    }
  });
};

export const processTranscriptData = ({
  prevState = [],
  currentTranscriptDataId = null,
  currentSpeakerId = null,
  newName = null
}) => {
  let updatedTranscriptEnhanced = prevState.map((data) => {
    if ((data?.id || data?.temp_id) === currentTranscriptDataId) {
      const updatedSentences = data.transcript_enhanced.map((obj) => {
        if (obj.speaker_id === currentSpeakerId) {
          return { ...obj, speaker: newName };
        }

        return obj;
      });

      return { ...data, transcript_enhanced: updatedSentences };
    }

    return data;
  });

  const updatedSpeakers = updatedTranscriptEnhanced
    .find((data) => (data?.id || data?.temp_id) === currentTranscriptDataId)
    .speakers.map((obj) => {
      if (obj.id === currentSpeakerId) {
        return { ...obj, new_name: newName, old_name: obj.new_name };
      }

      return obj;
    });

  let updatedSummary = replaceSpeakers({
    speakers: updatedSpeakers,
    text: updatedTranscriptEnhanced?.[0]?.summary
  });

  if (updatedSummary) {
    updatedTranscriptEnhanced[0].summary = updatedSummary;
  }

  return updatedTranscriptEnhanced.map((data) => {
    const dataId = data?.id || data?.temp_id;

    if (dataId === currentTranscriptDataId) {
      return {
        ...data,
        speakers: updatedSpeakers
      };
    }

    return data;
  });
};
