import React from 'react';
import BenefitItemChart from './BenefitItemChart';
import BenefitItem from './BenefitItem';
import { withErrorBoundary } from '../../../../../../shared/Error/Boundary';

const BenefitCategory = ({ benefitArray, item, it }) => {
  const isPercentage = benefitArray.find((ben) => ben.hasOwnProperty('benefit_percent'));

  const isCalendarYear = benefitArray.find(
    (ben) => ben.benefit_period_description === 'Calendar Year'
  );
  const isChart = isCalendarYear && !isPercentage;

  return isChart ? (
    <BenefitItemChart {...{ benefitArray, item, it }} />
  ) : (
    <BenefitItem {...{ benefitArray, item, it }} />
  );
};

export default withErrorBoundary(BenefitCategory);
