import React from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const TerminalBox = ({ icon, label, terminal, selectTerminal, selectedTerminal }) => {
  return (
    <div
      data-qa={`terminal-${terminal?.id}`}
      className={cs(
        'flex cursor-pointer grid-cols-2 items-center !rounded-lg border border-solid border-neutral-300 bg-white !p-4',
        terminal?.id === selectedTerminal?.id && '!border-primary-500 !bg-primary-50'
      )}
      onClick={() => selectTerminal(terminal)}>
      <Icon
        icon={icon || 'new-credit-card-scan'}
        className="!mr-8 cursor-pointer rounded-[4px] border border-solid border-neutral-100 !p-2"
      />

      <label className="cursor-pointer p-0 text-sm text-primary-900">{label}</label>
    </div>
  );
};

export default TerminalBox;
