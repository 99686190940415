import { useEffect, useRef, useState } from 'react';

import { usePatients } from '/lib/hooks/queries/patients/usePatients';
import cs from 'classnames';

import Avatar from 'components/shared/Avatar/Avatar';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import Input from 'components/shared/Input/Input';

const KioskPatientSelect = ({
  selected,
  setSelected,
  setShowForm,
  setShowPayment,
  focusOnMount,
  inputFieldRef,
  showPatients,
  setShowPatients
}) => {
  const [search, setSearch] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const inputRef = useRef(null);

  var timeOutId;
  useEffect(() => {
    clearTimeout(timeOutId);
    timeOutId = setTimeout(() => {
      setSearchTerm(search);
    }, 300);
    return () => clearTimeout(timeOutId);
  }, [search]);

  useEffect(() => {
    if (focusOnMount) {
      inputFieldRef.current?.focus();
    }
  }, [focusOnMount, inputRef]);
  const patientParams = {
    limit: 100,
    searchTerm,
    page: 1
  };

  const { data = {} } = usePatients({
    params: { ...patientParams },
    dependencies: [patientParams]
  });

  return (
    <div>
      <Input
        forwardedRef={inputRef}
        focusOnMount={true}
        inputId="searchPatients"
        placeholder="Search patients"
        icon="new-search"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
      <div className="bg-secondary-10 flex flex-col overflow-hidden">
        <div className="mb-2"></div>
        <div className="max-h-[calc(100vh-195px)] flex-col gap-4 overflow-y-auto">
          {data?.patients?.map((patient) => (
            <div
              key={patient?.id}
              className={cs('flex cursor-pointer items-center gap-3 !p-2 hover:!bg-primary-50')}
              onClick={() => {
                selected === patient?.id ? setSelected(null) : setSelected(patient?.id);

                if (showPatients?.type === 'form') {
                  setShowForm({
                    kiosk: showPatients?.kiosk,
                    patientId: patient?.id,
                    id: patient?.id
                  });
                } else if (showPatients?.type === 'payment') {
                  setShowPayment({
                    kiosk: showPatients?.kiosk,
                    patientId: patient?.id,
                    id: patient?.id
                  });
                }
                setShowPatients(null);
              }}>
              <Avatar fullName={patient?.fullName} id={patient?.id} size="xl" />

              <div className="flex flex-col">
                <h3 className="text-base font-500 text-neutral-900">{patient?.fullName}</h3>
                {patient?.dob && (
                  <span className="text-xs text-neutral-500">{formatDate(patient?.dob)}</span>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KioskPatientSelect;
