import React, { useCallback, useEffect, useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../api/Api';
import { getIcd10Codes } from '../../../api/Icd10';
import { showAlert } from '../../../components/shared/Alert/Alert';
import { useProducts } from '../../hooks/queries/products/useProducts';
import { useServices } from '../../hooks/queries/services/useServices';
import { useClinicalNoteContext } from '../ClinicalNoteContext/ClinicalNoteContext';
import { Chart3dContext } from './Chart3dContext';

import {
  calculateDistance,
  filterItem,
  filterUniqueById,
  getRandomColor,
  getUnits
} from './lib/helpers';

import { iaRa } from 'lib/helpers/utility';
import { sides } from './lib/initials';

import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';

export const Chart3dContextProvider = ({ children }) => {
  const { loading: clinicalNoteLoading } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const [patient, setPatient] = useState({ gender: 'female' });

  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [newItemModalVisible, setNewItemModalVisible] = useState(false);

  const [type, setType] = useState('face');

  const [mode, setMode] = useState('skin');

  const [points, setPoints] = useState([]);

  const [selected, setSelected] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [drawing, setDrawing] = useState(false);

  const [drawHistory, setDrawHistory] = useState([]);

  const [note, setNote] = useState();

  const navigate = useNavigate();

  const selectedItemIndex = () => {
    return items?.findIndex((p) => p.id === selectedItem);
  };

  function handleQuantity(add = true, units = 1) {
    const index = selectedItemIndex();
    if (items[index].quantity <= items[index].sales_count && add) {
      showAlert({ title: 'Not enough products!', color: 'danger' });
      return false;
    } else {
      setItems((prevItems) => {
        const updatedItems = [...prevItems];
        const updatedItem = { ...updatedItems[index] };

        updatedItem.sales_count += add ? units : -units;

        if (!add) updatedItem.invoice_sales_count -= units;

        updatedItem.total_amount_cents = updatedItem.amount_cents * updatedItem.sales_count;

        updatedItems[index] = updatedItem;

        return updatedItems;
      });
    }
    return true;
  }

  const { data: products } = useProducts({
    params: {
      searchTerm: '',
      page: 1,
      limit: 1000,
      column: 'name',
      sort: 'asc'
    }
  });

  const { data: procedures } = useServices({});

  const getLandmarks = async () => {
    const response = requestApi({
      url: 'api/landmarks/get',
      navigate,
      params: {
        merged: true,
        model: mode === 'skin' ? (patient?.gender === 'male' ? 'male' : 'female') : 'muscle'
      }
    });

    return response;
  };

  const { data: landmarks } =
    useQuery({
      queryKey: ['getLandmarks', mode, patient],
      queryFn: getLandmarks,
      refetchOnMount: true,
      refetchOnWindowFocus: false
    }) || {};

  function findNearestLandmark(point) {
    const distances = landmarks?.landmarks?.map((landmark) => ({
      landmark,
      distance: calculateDistance(landmark.position, point)
    }));

    distances?.sort((a, b) => a.distance - b.distance);

    const nearestLandmarks = distances?.slice(0, 2)?.map((entry) => entry.landmark);

    const first = nearestLandmarks?.[0];
    const second = nearestLandmarks?.[1];

    if (first?.mark == second?.mark) {
      return {
        ...first,
        type: 'in'
      };
    } else {
      return {
        ...first,
        type: 'near'
      };
    }
  }

  const handleGetClinicalNote = useCallback(async () => {
    if (!clinicalNote?.appointment_id) return;

    var productItems = [];
    var procedureItems = [];
    var diagnosisItems = [];

    const response = await requestApi({
      navigate,
      url: '/api/appointment/get_one',
      params: {
        id: clinicalNote.appointment_id,
        withal: { procedureModifiers: true },
        createInvoiceIfNotExists: false
      }
    });

    const invoice = response?.appointment?.invoice;

    if (products?.products && invoice && clinicalNote) {
      productItems = invoice?.products
        ? invoice?.products?.map((product) => {
            const updatedProduct = products?.products.find((p) => p.id === product?.id);
            const units = getUnits(product, clinicalNote, type);

            return {
              ...product,
              name: updatedProduct?.name,
              quantity: updatedProduct?.quantity + product.sales_count,
              expiration_date: product?.expiration_date,
              lot: product?.lot,
              color: updatedProduct?.color || getRandomColor(items),
              invoice_sales_count: product.sales_count > units ? product.sales_count : 0,
              sales_count: units,
              visible: true,
              state_tax_rate: updatedProduct?.state_tax_rate,
              local_tax_rate: updatedProduct?.local_tax_rate
            };
          })
        : items.filter((item) => item.item_type === 'product');
    }

    if (procedures?.services && invoice && clinicalNote) {
      procedureItems = invoice?.procedures
        ? filterUniqueById(invoice.procedures)?.map((procedure) => {
            const updatedProcedure = procedures?.services.find((s) => s.id === procedure?.id);
            let units = getUnits(procedure, clinicalNote, type, 'id', true);

            const invoice_sales_count = invoice.procedures.reduce(
              (a, b) =>
                (procedure?.id && b.id == procedure?.id) ||
                (procedure.procedure_code && b.procedure_code == procedure?.procedure_code)
                  ? a + 1
                  : a,
              0
            );

            if (units > invoice_sales_count) {
              units = invoice_sales_count;

              clinicalNote.charts3d[type].points = filterPointsByProduct(
                clinicalNote?.charts3d[type]?.points,
                procedure?.id,
                invoice_sales_count
              );
            }

            return {
              ...procedure,
              procedure_code: updatedProcedure?.procedure_code || procedure?.procedure_code,
              id: procedure?.id || procedure?.procedure_code,
              name: updatedProcedure?.name || procedure?.name,
              color:
                updatedProcedure?.color ||
                clinicalNote?.charts3d?.procedures?.find(
                  (p) => p.id == procedure?.id || procedure?.procedure_code
                )?.color ||
                getRandomColor(items),
              invoice_sales_count: invoice_sales_count || 0,
              sales_count: units,
              charge_type: updatedProcedure?.charge_type || procedure.charge_type,
              charge:
                updatedProcedure?.charge_type === 'copay'
                  ? updatedProcedure?.standard_charge
                  : undefined,
              item_type: 'service',
              visible: true
            };
          })
        : items.filter((item) => item.item_type === 'service');
    }

    if (clinicalNote?.icd_10_codes) {
      const icd10 = await getIcd10Codes(navigate, { ids: clinicalNote.icd_10_codes });

      diagnosisItems = icd10?.icd10
        ? icd10?.icd10?.map((item) => {
            const chartItem = clinicalNote?.charts3d?.icd10?.find((icd) => icd.id === item.code);
            const units = getUnits(item, clinicalNote, type, 'code');

            return {
              ...item,
              id: item?.code,
              name: item.code + ' - ' + item?.name,
              color: chartItem?.color || getRandomColor(items),
              invoice_sales_count: 0,
              sales_count: units || 0,
              item_type: 'icd10',
              visible: true
            };
          })
        : items.filter((item) => item.item_type === 'icd10');
    }

    if (clinicalNote?.charts3d && Object.keys(clinicalNote.charts3d)?.includes(type)) {
      if (invoice?.products && invoice?.procedures) {
        const productsIds = iaRa(invoice?.products)?.map((product) => product?.id);
        const proceduresIds = iaRa(invoice?.procedures)?.map(
          (procedure) => procedure?.id || procedure?.procedure_code
        );
        const diagnosisIds = iaRa(clinicalNote?.icd_10_codes);

        const currentPoints = iaRa(clinicalNote?.charts3d?.[type]?.points).filter((point) =>
          [...productsIds, ...proceduresIds, ...diagnosisIds].includes(point.product)
        );

        setClinicalNote((prevState) => ({
          ...prevState,
          fromCharts3d: true,
          charts3d: {
            ...prevState?.charts3d,
            [type]: {
              ...prevState?.charts3d?.[type],
              points: currentPoints
            }
          }
        }));
        setPoints(currentPoints);
      } else {
        setPoints(clinicalNote?.charts3d[type]?.points);
        productItems = clinicalNote?.charts3d?.products || [];
        procedureItems = clinicalNote?.charts3d?.procedures || [];
      }

      setNote(clinicalNote?.charts3d[type]?.note);
    }

    setItems([...productItems, ...procedureItems, ...diagnosisItems]);
  }, [
    clinicalNote,
    items,
    navigate,
    procedures?.services,
    products?.products,
    setClinicalNote,
    type
  ]);

  function filterPointsByProduct(points, productId, numberOfPoints) {
    let filteredPoints = points.filter((point) => point.product === productId);

    if (filteredPoints.length <= numberOfPoints) {
      return points;
    }

    filteredPoints = filteredPoints.slice(0, numberOfPoints);

    const resultPoints = points
      .filter((point) => point.product !== productId)
      .concat(filteredPoints);

    return resultPoints;
  }

  useEffect(() => {
    if (clinicalNoteLoading) return;

    var duplicatedCPTCodes = [];

    items
      .filter((item) => item?.item_type == 'service' && item?.procedure_code)
      ?.forEach((procedure) => {
        const sales =
          procedure.invoice_sales_count > procedure.sales_count
            ? procedure.invoice_sales_count
            : procedure.sales_count;
        if (sales && sales > 1) {
          let copyClinicalNoteCpts = structuredClone(iaRa(clinicalNote?.cpt_codes));

          for (let i = 0; i < sales; i++) {
            const matchingCPTIndex = copyClinicalNoteCpts.findIndex(
              (row) => row?.procedure_code === procedure?.procedure_code && !row.processed
            );

            let modifiers;
            if (matchingCPTIndex !== -1) {
              modifiers = copyClinicalNoteCpts?.[matchingCPTIndex]?.modifiers;
              copyClinicalNoteCpts[matchingCPTIndex].processed = true;
            }

            const duplicatedProcedure = {
              ...procedure,
              modifiers,
              uuid: self.crypto.randomUUID()
            };

            duplicatedCPTCodes.push(duplicatedProcedure);
          }
        } else {
          duplicatedCPTCodes.push({
            ...procedure,
            uuid: self.crypto.randomUUID()
          });
        }
      });

    setClinicalNote((prev) => {
      return {
        ...prev,
        fromCharts3d: true,
        icd_10_codes: items.filter((item) => item?.item_type == 'icd10')?.map((item) => item.id),
        charts3d: {
          ...prev.charts3d,
          changeNr: prev.charts3d?.changeNr ? prev.charts3d?.changeNr + 1 : 1,
          [type]: {
            points,
            note
          },
          products: items
            .filter((item) => item?.item_type == 'product' || item?.item_type == null)
            ?.map((product) => ({
              ...product,
              sales_count:
                product.sales_count >= product.invoice_sales_count
                  ? product.sales_count
                  : product.invoice_sales_count
            })),
          procedures: items.filter((item) => item?.item_type == 'service'),
          icd10: items.filter((item) => item?.item_type == 'icd10')
        },
        cpt_codes: duplicatedCPTCodes
      };
    });
  }, [items, points, note]);

  const filterPoints = useCallback(() => {
    const visibleIds = items.filter((item) => item.visible).map((item) => item.id);
    return points.filter((point) => visibleIds.includes(point.product));
  }, [items, points]);

  return (
    <Chart3dContext.Provider
      value={{
        items,
        setItems,
        type,
        selectedItem,
        setNewItemModalVisible,
        selected,
        setSelected,
        points,
        setPoints,
        setType,
        mode,
        setMode,
        newItemModalVisible,
        setSelectedItem,
        handleQuantity,
        hovered,
        setHovered,
        filterItem,
        patient,
        setPatient,
        handleGetClinicalNote,
        products,
        sides,
        filterPoints,
        selectedItemIndex,
        findNearestLandmark,
        note,
        setNote,
        drawing,
        setDrawing,
        drawHistory,
        setDrawHistory,
        procedures
      }}>
      {children}
    </Chart3dContext.Provider>
  );
};
