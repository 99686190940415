import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { stripHtmlTags } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import moment from 'moment';
import React from 'react';
import SimpleTable from '../../../../../../shared/Forms/Custom/ProviderMedications/components/SimpleTable';

const cols = [
  {
    field: 'medication.value',
    headerName: 'Medication',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }
      return <span>{params?.data?.medication?.value ?? 'N/A'}</span>;
    }
  },
  {
    field: 'dose',
    headerName: 'Dosage',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }

      return (
        <span>
          {params?.data?.doses}
          {params?.data?.dosesUnit ? `(${params?.data?.dosesUnit})` : 'N/A'}
        </span>
      );
    }
  },
  {
    field: 'location',
    headerName: 'Location',
    valueFormatter: ({ value }) => (!!value ? capitalize(value) : 'N/A')
  },
  {
    field: 'date',
    headerName: 'Date',
    valueFormatter: ({ value }) => (!!value ? moment.utc(value).format('MMM D, YYYY') : 'N/A')
  },
  {
    field: 'weight',
    headerName: 'Weight',
    valueFormatter: ({ value }) => (!!value ? `${value} lbs` : 'N/A')
  },
  {
    field: 'notes',
    headerName: 'Note',
    valueFormatter: ({ value }) => (!!value ? stripHtmlTags(value) : 'N/A')
  }
];

const WeightLossAdministrationSimpleTable = ({
  data,
  emptyState = 'No WeightLoss',
  fromTodaysData = false
}) => {
  if (fromTodaysData) {
    data = data?.map((item) => item?.response?.row).flat();
  }
  return (
    <TableContextProvider name="weightloss_simple_table" defaultFilters={[]} cols={cols}>
      <SimpleTable data={data} emptyState={emptyState} />
    </TableContextProvider>
  );
};

export default WeightLossAdministrationSimpleTable;
