export default [
  {
    name: 'Fax',
    path: 'comms-center/fax',
    children: [
      {
        name: 'Fax',
        path: './fax/main'
      },
      {
        name: 'Inbound Faxes',
        path: './fax/inbound'
      },
      {
        name: 'Outbound Faxes',
        path: './fax/outbound'
      },
      {
        name: 'Drafts',
        path: './fax/drafts'
      },
      {
        name: 'Archived',
        path: './fax/archived'
      }
      // {
      //   name: 'Cover Pages',
      //   path: './fax/cover-pages'
      // },
    ]
  },
  {
    name: 'Contact',
    path: 'comms-center/contacts',
    children: [
      {
        name: 'Contacts',
        path: './contacts'
      }
    ]
  }
];
