const validator = require('validator')

const validateCreation = (payload) => {
  const errors = {}
  let message = ''
  let isFormValid = true

  if (
    !payload ||
    typeof payload.sender_name !== 'string' ||
    payload.sender_name.trim().length === 0
  ) {
    isFormValid = false
    errors.sender_name = 'Please provide a name.'
  }

  if (!payload || typeof payload.subject !== 'string' || payload.subject.trim().length === 0) {
    isFormValid = false
    errors.subject = 'Please provide a subject.'
  }

  if (
    !payload ||
    typeof payload.sender_email !== 'string' ||
    !validator.isEmail(payload.sender_email)
  ) {
    isFormValid = false
    errors.sender_email = 'Please provide a correct email address.'
  }

  if (!payload || typeof payload.body !== 'string' || payload.body.trim().length === 0) {
    isFormValid = false
    errors.body = 'Please provide a message.'
  }

  if (!isFormValid) {
    message = 'Check the form for errors.'
  }

  return {
    success: isFormValid,
    message,
    errors
  }
}

module.exports = {
  validateCreation: validateCreation
}
