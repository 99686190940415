import React from 'react';
import { formatDateAndTimeZ } from '../../../../../../lib/helpers/utility';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../../practiceState';
import moment from 'moment-timezone';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const TPList = ({ data }) => {
  const { setSelectedTreatmentPlan } = useClinicalNoteContext();
  const practice = useRecoilValue(currentPractice);
  data?.sort((a, b) => moment(a.created_at) - moment(b.created_at));

  const handleSelected = (index) => {
    setSelectedTreatmentPlan(data[index]);
  };

  return (
    <table className="primary-table h-full overflow-x-hidden responsive-table selectable-children rounded-t-lg !shadow-[0_6px_16px_0_rgba(37,49,60,0.06)]">
      <thead>
        <tr>
          <th className="!pl-6 w-[200px]">Creation date</th>
          {data?.find((item) =>
            item.treatment_team?.find((item) => item.provider.value !== null)
          ) && <th className="!pl-3 w-[140px]">Provider</th>}
        </tr>
      </thead>
      <tbody className="max-h-[calc(100vh-244px)] !overflow-x-hidden">
        {data?.map((item, idx) => (
          <tr
            data-qa={`select-treatmen-plan-${idx}`}
            key={idx}
            className="cursor-pointer hover:!bg-primary-50"
            onClick={() => handleSelected(idx)}>
            <td className="w-[200px]">{formatDateAndTimeZ(item.created_at, practice.timezone)}</td>
            <td className="whitespace-nowrap overflow-hidden text-ellipsis w-[140px]">
              {item.treatment_team
                ?.map((item) => (item.provider ? item.provider?.label : null))
                .filter((label) => label !== null)
                .join(', ')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TPList;
