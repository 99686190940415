import moment from 'moment';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from '../../../../api/InterimApi';
import { Capitalize, formatDate, ia, mString } from '../../../../lib/helpers/utility';
import Loading from '../../../shared/Loading/Loading';
import practiceState, { currentPractice } from '../../practiceState';

const LIMIT = 200;

export default function TotalReceived({ grandTotalView }) {
  const navigate = useNavigate();

  const [claims, setClaims] = useState([]);
  const [era, setERA] = useState([]);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const filters = useRecoilValue(practiceState.reportingFilters);
  const [order, setOrder] = useState({
    patient: false,
    date: false,
    claimId: false,
    provider: false,
    method: false,
    payer: false,
    check_trace: false,
    paid_amount: false.valueOf,
    adjustment: false,
    createdBy: false,
    note: false,
    adjustmentReason: false
  });
  const practice = useRecoilValue(currentPractice);

  useEffect(() => {
    getERA();
    loadTransactions();
  }, [filters]);

  useEffect(() => {
    if (era) {
      const claim = mapClaims(era);
      setFiltered([...claim, ...transactions]);
    }
  }, [era]);

  const orderBy = (column) => {
    let orderedAll = [];
    switch (column) {
      case 'claimId':
        orderedAll = filtered.sort((a, b) => {
          return order[column] ? a.id - b.id : b.id - a.id;
        });
        setFiltered(orderedAll);
        break;
      case 'patient':
        const fullName = filtered.map((row) => {
          return { ...row, fullName: row?.from_dos ? row?.pcn : row?.patient?.fullName };
        });
        orderedAll = fullName.sort((a, b) => {
          return order[column]
            ? a?.fullName?.localeCompare(b?.fullName, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
            : b?.fullName?.localeCompare(a?.fullName, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        });
        setFiltered(orderedAll);
        break;
      case 'payer':
        const payerName = filtered.map((row) => {
          return { ...row, payerName: row?.payer_name || row?.payer_icn };
        });
        orderedAll = payerName.sort((a, b) => {
          return order[column]
            ? a?.payerName?.localeCompare(b?.payerName, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
            : b?.payerName?.localeCompare(a?.payerName, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        });
        setFiltered(orderedAll);
        break;
      case 'provider':
        orderedAll = filtered.sort((a, b) => {
          return order[column]
            ? a?.superbill?.practitioner?.fullName?.localeCompare(
              b?.superbill?.practitioner?.fullName
            )
            : b?.superbill?.practitioner?.fullName?.localeCompare(
              a?.superbill?.practitioner?.fullName
            );
        });
        setFiltered(orderedAll);
        break;
      case 'date':
        const dob = filtered.map((row) => {
          return { ...row, dob: row?.from_dos ? row?.from_dos : row?.created_at };
        });
        orderedAll = dob.sort((a, b) => {
          if (order[column]) {
            return moment(a.dob).isBefore(moment(b.dob)) ? 1 : -1;
          } else {
            return moment(b.dob).isBefore(moment(a.dob)) ? 1 : -1;
          }
        });
        setFiltered(orderedAll);
        break;
      case 'check_trace':
        break;
      case 'paid_amount':
        const amount = filtered.map((row) => {
          return {
            ...row,
            amount: row?.from_dos
              ? parseFloat(insurancePaidAmount(row)).toFixed(2)
              : row?.amount_paid_self
          };
        });
        orderedAll = amount.sort((a, b) => {
          return order[column] ? a.amount - b.amount : b.amount - a.amount;
        });
        setFiltered(orderedAll);
        break;
      case 'adjustment':
        const adjustment = filtered?.map((row) => {
          return {
            ...row,
            adjustmentTotal: row?.total_charge
              ? Math.abs(row?.total_charge - insurancePaidAmount(row)).toFixed(2)
              : ''
          };
        });
        orderedAll = adjustment.sort((a, b) => {
          return order[column]
            ? a?.adjustmentTotal - b?.adjustmentTotal
            : b?.adjustmentTotal - a?.adjustmentTotal;
        });
        setFiltered(orderedAll);
        break;
      case 'createdBy':
        orderedTransactions = filtered?.sort((a, b) => {
          return order[column]
            ? a?.cashier?.fullName?.toUpperCase() > b?.cashier?.fullName?.toUpperCase()
              ? 1
              : -1
            : b?.cashier?.fullName?.toUpperCase() > a?.cashier?.fullName?.toUpperCase()
              ? 1
              : -1;
        });
        setTransactions(orderedTransactions);
        break;
      case 'method':
        orderedTransactions = filtered?.sort((a, b) => {
          return order[column]
            ? a?.type?.toUpperCase() > b?.type?.toUpperCase()
              ? 1
              : -1
            : b?.type?.toUpperCase() > a?.type?.toUpperCase()
              ? 1
              : -1;
        });
        setTransactions(orderedTransactions);
        break;
      default:
        break;
    }
  };

  const loadTransactions = async () => {
    if (filters) {
      try {
        let params = {
          patientId: filters.patient ? filters?.patient?.id?.toString() : null,
          startDate: moment.utc(filters.selectedRange[0].startDate).startOf('day'),
          endDate: moment.utc(filters.selectedRange[0].endDate).endOf('day'),
          transactionFilter: filters?.transactionFilter
        };

        if (ia(filters?.patient)) {
          params = { ...params, multipleIds: filters?.patient };
        }

        const res = await interimApi(`/api/transactions/list`, params, navigate);
        const { code, redirect, error, transactions: loadedTransactions } = res.data;
        switch (code) {
          case -1:
            navigate(redirect);
            break;

          case 0:
            if (ia(loadedTransactions)) {
              setTransactions(loadedTransactions);
              setFiltered(loadedTransactions);
              return loadedTransactions;
            } else {
              setTransactions([]);
              console.error(`No transactions found.`);
              return [];
            }

          default:
            console.error(error);
            toast.error(
              error || `An unexpected code has been encountered. Please try again later.`
            );
            break;
        }
      } catch (error) {
        console.error(error);
        toast.error(`An unexpected error has occurred. Please try again later.`);
      }
    }
  };

  const getERA = async () => {
    setLoading(true);
    if (filters) {
      try {
        let params = {
          limit: LIMIT,
          offset: 0,
          startDate: filters.selectedRange[0].startDate,
          endDate: filters.selectedRange[0].endDate
        };

        if (ia(filters?.patient)) {
          params = { ...params, multipleIds: filters?.patient };
        }

        let res = await interimApi('/api/practice/billing/claim/get_era', params, navigate);
        if (res?.data?.era) {
          setERA(res?.data?.era);
          setLoading(false);
          return res?.data?.era;
        }
      } catch (error) {
        console.error('Error: ', error);
      }
    }
  };

  const mapClaims = (era) => {
    let claimsArray = [];
    if (filters?.patient?.type == 'payer') {
      era.map((e) => {
        if (
          e?.payerid == filters?.patient?.id &&
          (Number(e.paid_amount) > 0 || Number(e.total_paid) > 0)
        ) {
          ia(e.claims) && claimsArray.push(...e.claims);
          ia(e.unmatched_claims) && claimsArray.push(...e.unmatched_claims);
        }
      });
      setClaims(claimsArray);
      return claimsArray;
    } else {
      era.map((e) => {
        if (Number(e.paid_amount) > 0 || Number(e.total_paid) > 0) {
          ia(e.claims) && claimsArray.push(...e.claims);
          ia(e.unmatched_claims) && claimsArray.push(...e.unmatched_claims);
        }
      });

      claimsArray = claimsArray
        .filter((item) => !filters.patient?.id || parseInt(item.pcn) === filters?.patient?.id)
        .map((item) => item);
      setClaims(claimsArray);
      return claimsArray;
    }
  };

  const insurancePaidAmount = (claim) => {
    let amount = 0;
    if (claim?.insurance_payment?.era) {
      claim.insurance_payment.era.map((e) => {
        if (e.era_id === claim.era) {
          amount = (e.amount_paid / 100).toFixed(2);
        }
      });
    } else if (claim?.insurance_payment?.eob) {
      claim.insurance_payment.eob.map((e) => {
        if (e.eob_id === claim.eob) {
          amount = (e.amount_paid / 100).toFixed(2);
        }
      });
    } else {
      amount = claim?.total_paid;
    }
    return amount;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <table className='primary-table'>
      <thead>
        <tr>
          <th
            onClick={() => {
              setOrder({
                ...order,
                patient: !order.patient
              });
              orderBy('patient');
            }}>
            Patient
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                date: !order.date
              });
              orderBy('date');
            }}>
            Date of Service
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                claimId: !order.claimId
              });
              orderBy('claimId');
            }}>
            Claim/ Transaction ID
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                provider: !order.provider
              });
              orderBy('provider');
            }}>
            Provider
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                method: !order.method
              });
              orderBy('method');
            }}>
            Method
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                payer: !order.payer
              });
              orderBy('payer');
            }}>
            Payer
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                check_trace: !order.check_trace
              });
              orderBy('check_trace');
            }}>
            Check/ Trace #
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                paid_amount: !order.paid_amount
              });
              orderBy('paid_amount');
            }}>
            Paid Amount
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                adjustment: !order.adjustment
              });
              orderBy('adjustment');
            }}>
            Adjustment
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                note: !order.note
              });
              orderBy('note');
            }}>
            Note
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                createdBy: !order.createdBy
              });
              orderBy('createdBy');
            }}>
            Created by
          </th>
          <th
            onClick={() => {
              setOrder({
                ...order,
                adjustmentReason: !order.adjustmentReason
              });
              orderBy('adjustmentReason');
            }}>
            Adjustment Reason
          </th>
        </tr>
      </thead>
      <tbody>
        {filtered?.map((row, index) => {
          const adjustment = Math.abs(row?.total_charge - insurancePaidAmount(row)).toFixed(2);
          return (
            <tr key={index}>
              <td>{row?.patient?.fullName || row?.pcn}</td>
              <td>{formatDate(row?.created_at || row?.from_dos, practice.timezone)}</td>
              <td>{row?.id}</td>
              <td>{row?.superbill?.practitioner?.fullName}</td>
              <td>{Capitalize(row?.type === 'care_credit' ? 'outside financing' : row?.type)}</td>
              <td>{row?.payer_name || row?.payer_icn}</td>
              <td>{row?.check}</td>
              <td>
                {!isNaN(row?.amount_paid_self)
                  ? mString(row?.amount_paid_self)
                  : mString(insurancePaidAmount(row))}
              </td>
              <td>{!isNaN(adjustment) && `$${adjustment}`} </td>
              <td>{row?.notes}</td>
              <td>{row?.cashier?.fullName}</td>
              <td>{row?.adjustment_reason}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
