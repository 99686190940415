import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { camelCase } from 'lodash';

import { noKnownPatientAggregateNarrative } from 'api/PatientAggregateNarrative';

import Confirm from 'components/shared/Modal/Confirm/Confirm';

import { handleNoKnown } from '../../Provider/components/lib/noteHeaderHelpers';

const ProviderNoKnownConfirm = ({
  patientId,
  showNoKnownModal = false,
  setShowNoKnownModal = () => {},
  title = '',
  setOverviewData = () => {},
  noKnownState
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateNoKnown, isLoading: noKnownLoading } = useMutation({
    mutationFn: (data) => noKnownPatientAggregateNarrative(navigate, data)
  });
  return (
    <Confirm
      handleOpen={!!showNoKnownModal}
      handleClose={() => setShowNoKnownModal(false)}
      handleContinue={() =>
        handleNoKnown({
          mutateNoKnown,
          queryClient,
          patientId,
          title: camelCase(title),
          noKnownState,
          setNoKnownModal: setShowNoKnownModal,
          setOverviewData
        })
      }
      title={`No Known ${title}`}
      icon="new-document-remove-red"
      loading={noKnownLoading}
      disabled={noKnownLoading}
      message={
        <div>
          <p>{`Are you sure you want to mark as 'No Known ${title}'?`}</p>
          <em className="text-danger-500">
            {' '}
            This will remove any existing {title?.toLowerCase()} from the patient's chart.
          </em>
        </div>
      }
      variant="danger"
    />
  );
};
export default ProviderNoKnownConfirm;
