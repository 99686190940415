import React, { Fragment, useCallback, useState } from 'react';
import { Tab } from '@headlessui/react';
import cs from 'classnames';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import Skeleton from 'react-loading-skeleton';
import PatientClaims from 'components/practice/charts/billing/claims/PatientClaims';
import TransactionsTable from 'components/practice/charts/billing/transactions/TransactionsTable';
import Superbills from 'components/practice/charts/billing/superbill/Superbill';
import Ledger from 'components/practice/charts/billing/ledger/Ledger';
import PatientInvoices from 'components/practice/charts/billing/Invoices/PatientInvoices';

const BillingPatientModal = ({ patient_id, modalVisible, setModalUnVisible }) => {
  const [activeTab, setActiveTab] = useState(0);

  const { data, isLoading, isFetching } = usePatient({
    params: { id: patient_id },
    dependencies: [patient_id]
  });

  const patient = data?.patient || {};

  const getTabsData = useCallback(
    () => [
      {
        label: 'Transaction',
        content: <TransactionsTable patient_id={patient?.id} />
      },
      {
        label: 'Superbill',
        content: <Superbills patient={patient} />
      },
      {
        label: 'Claims',
        content: <PatientClaims patient={patient} />
      },
      {
        label: 'Ledger',
        content: <Ledger patient={patient} />
      },
      {
        label: 'Invoices',
        content: <PatientInvoices patient_id={patient?.id} />
      }
    ],
    [data?.patient]
  );

  const handleTabChange = (index) => {
    setActiveTab(index); // Update active tab index on click
  };

  return (
    <Modal
      handleOpen={modalVisible}
      handleClose={setModalUnVisible}
      className="w-[1200px]"
      title="Demographics"
      slideFromRight
      subModal
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Close" onClick={setModalUnVisible} outlined color="neutral" />
          <Button text="Update Patient Data" onClick={() => {}} />
        </div>
      }>
      {isLoading || isFetching ? (
        <Skeleton count={5} />
      ) : (
        <Tab.Group selectedIndex={activeTab} onChange={handleTabChange}>
          <Tab.List className="!mb-5 flex border-x-0 border-b border-t-0 border-solid border-neutral-200">
            {getTabsData().map((tab, i) => {
              return (
                <Tab as={Fragment} key={i} data-qa={tab.label} data-intercom-target={tab.label}>
                  {({ selected }) => (
                    <button
                      className={cs(
                        'flex items-center gap-1 border-x-0 border-b-2 border-t-0 border-solid border-transparent !px-4 !py-2 text-sm leading-5',
                        selected
                          ? ' !border-primary-500 font-600 text-primary-500'
                          : 'font-500 text-neutral-800'
                      )}>
                      {tab.label}
                    </button>
                  )}
                </Tab>
              );
            })}
          </Tab.List>
          <Tab.Panels>
            {getTabsData().map((tab, i) => (
              <Tab.Panel key={i}>
                {({ selected }) => {
                  return selected ? tab.content : null;
                }}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </Modal>
  );
};

export default BillingPatientModal;
