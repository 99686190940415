import { useEffect, useState } from 'react';

import { Capitalize, calculateAge, formatPhoneNumber } from '/lib/helpers/utility';
import cs from 'classnames';
import isEqual from 'lodash/isEqual';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { useTags } from 'lib/hooks/queries/tags/useTags';

import { currentPractice } from 'components/practice/practiceState';
import Icon from 'components/shared/Icon/Icon';
import Status from 'components/shared/Status/Status';
import TagsView from 'components/shared/Tags/components/View/View';

const Header = ({ appointment, event, type }) => {
  const kind = 'appointment';
  const { data } = useTags({ params: { kind }, dependencies: [kind] });
  const tags = data?.tags || [];
  const [selectedTags, setSelectedTags] = useState(appointment?.tags || []);

  useEffect(() => {
    const latestTags = getLatestTagUpdates();
    setSelectedTags(latestTags);
  }, [tags]);

  const getLatestTagUpdates = () => {
    if (!tags) return selectedTags;
    return selectedTags?.map((tag) => {
      const matchingTag = tags?.find((allTag) => allTag?.id === tag?.id);
      if (matchingTag && !isEqual(tag, matchingTag)) {
        return { ...matchingTag };
      } else if (matchingTag) {
        return { ...tag };
      } else {
        return null;
      }
    });
  };

  let eventDuration;
  const startDate = new Date(event?.start_date);
  const untilDate = new Date(event?.until);
  const durationInMinutes = (startDate, untilDate) =>
    moment(untilDate).diff(moment(startDate), 'minutes');

  const practice = useRecoilValue(currentPractice);

  if (type === 'event' && durationInMinutes(startDate, untilDate) > 60) {
    const hours = Math.floor(durationInMinutes(startDate, untilDate) / 60);
    const remainingMinutes = durationInMinutes(startDate, untilDate) % 60;
    if (remainingMinutes > 0) {
      eventDuration = `(${hours}h, ${remainingMinutes}min)`;
    } else {
      eventDuration = `(${hours} hour)`;
    }
  } else {
    eventDuration = `(${durationInMinutes(startDate, untilDate)} min)`;
  }

  return (
    <div className="relative z-10 !px-4 py-[10px] shadow-[0px_4px_16px_rgba(0,0,0,0.04)]">
      <div className="flex flex-col gap-[6px]">
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-1">
            <div className="flex items-center gap-2">
              <Icon
                icon={
                  appointment?.status === 'cancelled'
                    ? 'calendar-remove'
                    : type === 'event'
                      ? 'calendar-event'
                      : 'new-calendarv2'
                }
                color={appointment?.status === 'cancelled' ? 'neutral' : 'primary'}
                size={18}
              />
              <span className="text-sm font-500 leading-[22px] text-primary-900">
                {type === 'appointment'
                  ? `${Capitalize(type)} #${appointment?.id}`
                  : Capitalize(type)}
              </span>
            </div>
            {type === 'appointment' && (
              <Icon icon={appointment?.confirmed ? 'tick-circle' : 'new-close-circle'} />
            )}
          </div>
          {type === 'appointment' ? (
            <span
              className="text-sm font-500 leading-[22px]"
              style={{ color: appointment?.practitioner?.practice?.color || '#00ABE8' }}>
              {appointment?.practitioner?.fullName}
            </span>
          ) : (
            <div className="flex gap-2 overflow-hidden">
              {event?.practitioner_ids?.slice(0, 2).map((practitioner, index) => (
                <span
                  key={index}
                  className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-500 leading-[22px]"
                  style={{ color: practitioner?.color ?? '#00ABE8' }}>
                  {practitioner?.name}
                </span>
              ))}
              {event?.practitioner_ids.length > 2 && (
                <span className="text-sm font-500 leading-[22px] text-neutral-700 underline">
                  +{event?.practitioner_ids?.length - 2}
                </span>
              )}
            </div>
          )}
        </div>
        <div
          className={cs(
            'flex justify-between gap-x-4 gap-y-[2px]',
            type === 'event' ? 'flex-col items-start' : 'items-center'
          )}>
          {type === 'appointment' ? (
            <div
              className="flex cursor-pointer items-center gap-1"
              onClick={() => {
                window.open(`/portal/charts/${appointment?.patient?.id}`, '_blank');
              }}>
              <span className="text-lg font-500 leading-7 text-primary-900">
                {appointment?.patient?.fullName}
              </span>
              <Icon
                className="cursor-pointer"
                icon="new-link"
                size={15}
                color="primary"
                shade={900}
                stroke
              />
            </div>
          ) : (
            <span className="text-lg font-500 leading-7 text-primary-900">{event?.title}</span>
          )}
          <div
            className={cs('flex items-center justify-between gap-2', type === 'event' && 'w-full')}>
            <div className="flex items-center gap-1">
              <span className="text-sm font-500 leading-[22px] text-primary-900">
                {`${moment
                  .tz(
                    type === 'appointment' ? appointment?.starts_at : event?.start_date,
                    practice?.timezone
                  )
                  .format('h:mm A')} - ${moment
                  .tz(
                    type === 'appointment' ? appointment?.ends_at : event?.until,
                    practice?.timezone
                  )
                  .format('h:mm A')}
                        `}
              </span>
              <span className="text-sm font-400 leading-[22px] text-neutral-600">
                {type === 'appointment'
                  ? `(${durationInMinutes(
                      new Date(appointment?.starts_at),
                      new Date(appointment?.ends_at)
                    )} min)`
                  : eventDuration}
              </span>
            </div>
            {type === 'event' && event?.repeat_event && (
              <div className="flex items-center gap-1">
                <span className="text-sm font-400 leading-[22px] text-neutral-600">
                  {event?.recurrenceType}
                </span>
                <Icon icon="new-repeat-v2" />
              </div>
            )}
          </div>
        </div>
        {type === 'appointment' && (
          <div className="flex flex-col gap-[6px]">
            <div className="flex items-center justify-between">
              <span className="text-sm font-500 leading-[22px] text-neutral-600">
                {formatPhoneNumber(appointment?.patient?.phone)
                  ? `ID: ${appointment?.patient?.id}, ${appointment?.patient?.phone}`
                  : `ID: ${appointment?.patient?.id}`}
              </span>
              <span className="text-sm font-500 leading-[22px] text-neutral-600">
                {`DOB: ${moment(appointment?.patient?.dob).format(
                  'MM/DD/YYYY'
                )}, (Age: ${calculateAge({
                  dateOfBirth: appointment?.patient?.dob
                })})`}
                {appointment?.patient?.gender && `,${Capitalize(appointment?.patient?.gender)}`}
              </span>
            </div>
            <div className="flex items-center justify-between">
              <Status
                status={appointment?.status === 'cancelled' ? 'canceled' : appointment?.status}
                className="flex !border-0 !bg-transparent !p-0 text-lg"
                textClassName="!text-sm !font-600 text-left"
              />
              <div className="flex items-center gap-2 overflow-hidden">
                {appointment?.no_payment_selected && (
                  <div className="flex h-[24px] items-center gap-[2px] whitespace-nowrap !rounded-full bg-danger-500 !px-2">
                    <Icon icon="new-warning-v3" size={16} />
                    <span className="text-sm font-400 leading-5 text-white">Balance risk</span>
                  </div>
                )}
                <TagsView
                  kind="appointment"
                  currTags={selectedTags}
                  itemsToShow={2}
                  label={false}
                  fromAppointment
                  disableDeviceCheck
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
