import { handleCPTSearch } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';
import { iaRa } from 'lib/helpers/utility';
import React, { useRef } from 'react';
import { routine_options } from '../../../lib/handleFormik';
import { findLabStatus, testDefaultObject } from '../../../lib/labOrderHelpers';
import QuestionsAndAnswers from './QuestionsAndAnswers';

const TestForm = ({ tests, setTests }) => {
  const textAreaRef = useRef();

  const addTestRow = () => {
    setTests((prevState) => {
      const newTests = [...prevState];

      newTests.unshift({ ...testDefaultObject, id: self.crypto.randomUUID() });

      return newTests;
    });
  };

  const removeTestRow = (index) => {
    setTests((prevState) => prevState.filter((test) => test.id !== index));
  };

  const handleTestChange = ({ key, event, index }) => {
    const updatedArray = tests.map((row) => {
      if (row.id === index) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });

    setTests(updatedArray);
  };

  return (
    <div>
      <div className="!mt-6 rounded-lg bg-primary-50 !p-3">
        <div className="!mb-3 flex items-center justify-between">
          <p className="text-md font-500 text-primary-900">TESTS</p>
          <Button text="Add Tests" iconRight="add-circle" transparent onClick={addTestRow} />
        </div>

        {iaRa(tests).map((test) => {
          const index = test?.id;
          const staticTestStatus = findLabStatus({ value: test?.test_status });

          return (
            <div
              key={index}
              className="!my-3 flex justify-between rounded-lg border !border-dashed !border-primary-500 bg-white !p-3">
              <div className="w-full">
                <div className="grid grid-cols-2 !gap-3">
                  <Input
                    id={`tests[${index}].test_name`}
                    name={`tests[${index}].test_name`}
                    label="Test Name"
                    placeholder="Test Name"
                    data-qa="test-name"
                    type="text"
                    value={test?.test_name}
                    onChange={(event) =>
                      handleTestChange({ key: 'test_name', event: event.target.value, index })
                    }
                  />

                  <Input
                    id={`tests[${index}].test_ref_lab`}
                    name={`tests[${index}].test_ref_lab`}
                    label="Lab Name"
                    data-qa="lab-name"
                    placeholder="Lab Name"
                    type="text"
                    value={test?.test_ref_lab}
                    onChange={(event) =>
                      handleTestChange({ key: 'test_ref_lab', event: event.target.value, index })
                    }
                  />

                  <Select
                    inputId="select-priority"
                    label="Priority"
                    placeholder="Priority"
                    name={`tests[${index}].test_status`}
                    id={`tests[${index}].test_status`}
                    isClearable
                    value={{ label: staticTestStatus ?? test?.test_status }}
                    options={routine_options}
                    onChange={(response) =>
                      handleTestChange({ key: 'test_status', event: response?.value, index })
                    }
                    menuPlacement="bottom"
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    isCreatable
                  />

                  <Select
                    inputId="select-test-cpt"
                    label="Test CPT"
                    placeholder="Type and select Test CPT"
                    name={`tests[${index}].test_cpt`}
                    id={`tests[${index}].test_cpt`}
                    isMulti
                    isAsync
                    isClearable
                    value={test?.test_cpt}
                    loadOptions={handleCPTSearch}
                    onChange={(response) =>
                      handleTestChange({ key: 'test_cpt', event: response, index })
                    }
                    menuPlacement="bottom"
                    components={{
                      IndicatorSeparator: () => null
                    }}
                  />
                </div>

                <div className="!mt-3 w-full rounded-lg border !border-dashed !border-primary-500 bg-primary-50">
                  <QuestionsAndAnswers
                    tests={tests}
                    test={test}
                    setTests={setTests}
                    testIndex={index}
                  />
                </div>

                <div className="!mt-3 grid grid-cols-2 !gap-3 rounded-lg border !border-dashed !border-primary-500 bg-primary-50 !p-3">
                  <Input
                    id={`tests[${index}].sample_type`}
                    name={`tests[${index}].sample_type`}
                    label="Sample Type"
                    data-qa="sample-type"
                    placeholder="Sample Type"
                    type="text"
                    value={test?.sample_type}
                    onChange={(event) =>
                      handleTestChange({ key: 'sample_type', event: event.target.value, index })
                    }
                  />

                  <Input
                    id={`tests[${index}].container`}
                    name={`tests[${index}].container`}
                    label="Container"
                    data-qa="container"
                    placeholder="Container"
                    type="text"
                    value={test?.container}
                    onChange={(event) =>
                      handleTestChange({ key: 'container', event: event.target.value, index })
                    }
                  />

                  <Textarea
                    label="Draw Req"
                    placeholder="Write draw req here"
                    id="draw_req"
                    data-qa="draw_req"
                    name="draw_req"
                    value={test?.draw_req}
                    onChange={(response) =>
                      handleTestChange({ key: 'draw_req', event: response?.target?.value, index })
                    }
                    transcribing
                    forwardedRef={textAreaRef}
                  />

                  <Input
                    id={`tests[${index}].transport_mode`}
                    name={`tests[${index}].transport_mode`}
                    label="Transport Mode"
                    data-qa="transport-mode"
                    placeholder="Transport Mode"
                    type="text"
                    value={test?.transport_mode}
                    onChange={(event) =>
                      handleTestChange({ key: 'transport_mode', event: event.target.value, index })
                    }
                  />

                  <Input
                    id={`tests[${index}].transport_group`}
                    name={`tests[${index}].transport_group`}
                    label="Transport Group"
                    placeholder="Transport Group"
                    data-qa="transport-group"
                    type="text"
                    value={test?.transport_group}
                    onChange={(event) =>
                      handleTestChange({ key: 'transport_group', event: event.target.value, index })
                    }
                  />
                </div>
              </div>
              <div className="!ml-3 flex items-center rounded-lg border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                <Icon
                  data-qa="trash-icon"
                  icon="trash"
                  onClick={() => removeTestRow(index)}
                  disabled={tests?.length === 1}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TestForm;
