export default function reshapeBillingProvideData(providers = []) {
  return providers?.map((provider) => {
    return {
      label: provider.bill_name,
      value: provider.id,
      bill_addr_1: provider.bill_addr_1,
      bill_addr_2: provider.bill_addr_2,
      bill_city: provider.bill_city,
      bill_id: provider.bill_id,
      bill_name: provider.bill_name,
      bill_npi: provider.bill_npi,
      bill_phone: provider.bill_phone,
      bill_state: provider.bill_state,
      bill_taxid: provider.bill_taxid,
      bill_taxid_type: provider.bill_taxid_type,
      bill_taxonomy: provider.bill_taxonomy,
      bill_zip: provider.bill_zip,
      id: provider.id,
      practice_id: provider.practice_id,
      practitioner_ids: provider.practitioner_ids
    };
  });
}
