import React from 'react';
import Input from '../../../../shared/Input/Input';
import Textarea from '../../../../shared/Textarea/Textarea';
import Select from '../../../../shared/Select/Select';
import ColorPicker from '../../../../shared/ColorPicker/ColorPicker';
import { capitalize } from 'lodash';

export default function EditKiosk({ formik, practitioners }) {
  const statuses = [
    {
      value: 'active',
      label: 'Active'
    },
    {
      value: 'inactive',
      label: 'Inactive'
    }
  ];

  return (
    <div className="flex flex-col gap-2">
      <p className="text-lg font-medium text-neutral-800">Edit Kiosk</p>
      <div className="flex gap-2">
        <ColorPicker
          name="color"
          color={formik?.values?.color}
          handleSelectColor={(color) => formik.setFieldValue('color', color)}
          label="Color"
          dark
        />
        <Input
          name="name"
          className="flex-1"
          label="Name"
          type="text"
          placeholder="Enter Kiosk name"
          required
          value={formik?.values?.name}
          error={formik?.errors?.name}
          onChange={formik && formik?.handleChange}
        />
        <Select
          name="status"
          label="Status"
          className="flex-1"
          options={statuses}
          type="text"
          placeholder="Type and select Kiosk status"
          required
          value={formik?.values?.status ? { label: capitalize(formik?.values?.status) } : null}
          error={formik?.errors?.status}
          isClearable={false}
          onChange={({ value }) => formik?.setFieldValue('status', value)}
        />
      </div>

      <Textarea
        name="description"
        label="Description"
        placeholder="Enter Kiosk description"
        textareaClassName="!h-24"
        rows={3}
        value={formik?.values?.description}
        onChange={formik && formik?.handleChange}
      />

      <Select
        name="notify_users"
        label="Users that will be notified for this kiosk's requests"
        className="flex-1"
        isMulti
        options={practitioners}
        type="text"
        placeholder="Type and select Members"
        value={formik?.values?.notify_users}
        error={formik?.errors?.notify_users}
        isClearable={false}
        onChange={(value) => formik?.setFieldValue('notify_users', value)}
      />
    </div>
  );
}
