import React from 'react';
import { useRecoilValue } from 'recoil';
import headerPattern from '../../../../../assets/Patterns/header-pattern.svg';
import cs from 'classnames';
import adminState from 'components/admin/lib/adminState';

const Header = () => {
  const admin = useRecoilValue(adminState.current_admin);

  return (
    <div className="sm: relative h-[146px] p-[18px] !pl-6">
      <img src={headerPattern} className="absolute left-0 top-0 h-full w-full object-cover" />
      <div className="relative z-10 !pt-2 sm:!pt-2 md:!pt-0">
        <h3 className="text-2xl font-500 leading-8 text-white sm:text-xl md:text-xl">{`Welcome ${admin?.f_name} ${admin?.l_name}`}</h3>
        <p
          className={cs(
            '!pt-1 text-sm font-400 text-white sm:w-2/5 sm:!pt-3 md:w-2/5',
            window.innerWidth < 768 && 'w-full'
          )}>
          We're delighted to have your expertise here today.
        </p>
      </div>
    </div>
  );
};

export default Header;
