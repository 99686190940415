import { memo, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { iaRa, mapValues } from 'lib/helpers/utility';
import { useOrders } from 'lib/hooks/queries/orders/useOrders';

import { clinicalNote as clinicalNoteState } from 'components/state';

import OrdersTableComponent from './OrdersTableComponent';

const Table = ({
  setOrders,
  appointmentId,
  showHeader,
  showFooter,
  pagination = false,
  setActiveOrder
}) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { limit, page, sort, filters, setPage } = useTableContext();

  const { id: patientId } = useParams();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('id');

  useEffect(() => {
    if (orderId) {
      const order = orders?.find((order) => order.id === Number(orderId));
      if (order) setActiveOrder({ type: order.rawType, id: order.id });
    }
  }, [orderId, orders]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    }
  }, [filters]);

  const { data: ordersData, isFetching } = useOrders({
    dependencies: [
      patientId,
      appointmentId,
      clinicalNote?.id,
      pagination,
      page,
      sort,
      limit,
      mapValues(filters)
    ],
    params: {
      pagination,
      page,
      sort,
      limit,
      filters: mapValues(filters),
      ...(appointmentId ? { appointmentIds: [appointmentId] } : null),
      ...(patientId ? { patientIds: [patientId] } : null),
      withRelations: { provider: true, tests: true, insurances: true, results: true }
    },
    options: {
      staleTime: 10000
    }
  });

  const orders = useMemo(() => iaRa(ordersData?.data), [ordersData]);
  const count = ordersData?.count || 0;

  useEffect(() => {
    if (!isFetching && setOrders) {
      setOrders(orders);
    }
  }, [isFetching, orders, setOrders]);

  return (
    <OrdersTableComponent
      data={orders}
      loading={isFetching}
      showHeader={showHeader}
      showFooter={showFooter}
      count={count}
    />
  );
};

export default memo(Table);
