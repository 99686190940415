import React from 'react';
import { NavLink } from 'react-router-dom';

import { Tab } from '@headlessui/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { pm } from 'lib/helpers/utility';

import { permissions } from '../../state';
import HorizontalScroll from '../HorizontalScroll/HorizontalScroll';
import Icon from '../Icon/Icon';

const Tabs = ({
  tabsData,
  className,
  tabClassName,
  panelsClassName,
  innerPanelClassName,
  hasContent = false,
  iconSize = 18,
  theme = 'primary',
  tabIndex, // only for tabs without a route
  setTabIndex, // only for tabs without a route,
  tabsParentClassName = false
}) => {
  const p = useRecoilValue(permissions);
  const filteredTabsData = tabsData?.filter((tab) => {
    if (tab.permission) {
      return pm(p, tab?.permission);
    }
    return true;
  });
  const hasPathKey = tabsData.every((obj) => Object.prototype.hasOwnProperty.call(obj, 'path'));

  return (
    <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
      <HorizontalScroll
        parentClassName={cs(
          tabsParentClassName,
          theme === 'primary' ? 'bg-neutral-50' : 'bg-white',
          'flex-1 overflow-hidden pr-2'
        )}>
        <Tab.List
          className={cs(
            'flex min-h-[38px] w-full gap-1',
            theme === 'primary' && 'px-4 py-[6px]',
            theme === 'secondary' && 'border-0 border-b border-solid border-neutral-200 px-8',
            className
          )}>
          {hasPathKey
            ? filteredTabsData.map((tab, index) => (
                <NavLink
                  end={tab?.end}
                  state={tab?.path}
                  to={tab?.path}
                  key={index}
                  data-qa={`tab-${tab?.path?.toLowerCase()}`}
                  className={({ isActive }) =>
                    cs(
                      `relative flex items-center gap-x-1 whitespace-nowrap !px-3 !py-2 text-sm`,
                      theme === 'primary'
                        ? '!rounded-lg border border-solid'
                        : "!py-4 before:absolute before:bottom-0 before:left-0 before:w-full before:duration-200 before:ease-linear before:content-['']",
                      isActive ? 'text-primary-500' : '!text-neutral-700 hover:!text-neutral-800',
                      isActive && theme === 'primary'
                        ? '!border-primary-200 bg-primary-50'
                        : '!border-transparent',
                      !isActive && theme === 'primary' && 'hover:bg-neutral-100',
                      isActive && theme === 'secondary'
                        ? 'before:h-[2px] before:bg-primary-500'
                        : 'before:h-0',
                      !isActive &&
                        theme === 'secondary' &&
                        'hover:before:h-[2px] hover:before:!bg-neutral-200',
                      tabClassName
                    )
                  }>
                  {({ isActive }) => (
                    <>
                      {tab?.icon && (
                        <Icon
                          icon={tab?.icon}
                          size={tab?.iconSize ?? iconSize}
                          color={isActive ? 'primary' : 'neutral'}
                          className="cursor-pointer"
                        />
                      )}
                      <span>{tab?.label}</span>

                      {tab.length >= 0 && (
                        <div
                          className={cs(
                            'flex size-[22px] items-center justify-center rounded-[4px] text-sm',
                            isActive ? 'bg-primary-400' : 'bg-neutral-100'
                          )}>
                          <span
                            className={cs(
                              'text-xs font-600',
                              isActive ? 'text-white' : 'text-neutral-600'
                            )}>
                            {tab.length}
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </NavLink>
              ))
            : tabsData.map((tab, index) => (
                <Tab
                  data-qa={`tab-${index}`}
                  key={index}
                  className={cs(
                    "relative flex cursor-pointer items-center gap-[4px] !py-2 px-3 !pb-[6px] text-sm before:absolute before:bottom-0 before:left-0 before:w-full before:duration-200 before:ease-linear before:content-['']",
                    tabIndex === index
                      ? 'before:h-[2px] before:bg-primary-500'
                      : 'before:h-0 before:bg-neutral-200 hover:before:h-[2px]'
                  )}>
                  <span
                    className={cs(
                      'text-sm',
                      tabIndex === index ? 'text-primary-500' : 'text-neutral-700'
                    )}>
                    {tab.label}
                  </span>

                  {tab.length >= 0 && (
                    <div
                      className={cs(
                        'flex size-[22px] items-center justify-center rounded-[4px] text-sm',
                        tabIndex === index ? 'bg-primary-400' : 'bg-neutral-100'
                      )}>
                      <span
                        className={cs(
                          'text-xs font-600',
                          tabIndex === index ? 'text-white' : 'text-neutral-600'
                        )}>
                        {tab.length}
                      </span>
                    </div>
                  )}
                </Tab>
              ))}
        </Tab.List>
      </HorizontalScroll>

      {hasContent && (
        <Tab.Panels className={cs('relative z-50', panelsClassName)}>
          {tabsData.map((tab, index) => (
            <Tab.Panel key={index} className={innerPanelClassName}>
              {tab?.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      )}
    </Tab.Group>
  );
};

export default Tabs;
