import React from 'react';

import cs from 'classnames';

import Icon from '../../Icon/Icon';

const Image = ({ url, icon, loading }) => {
  console.log('url', loading);
  return (
    <div
      className={cs(
        'w-16 h-16 rounded-full flex items-center justify-center',
        loading && 'border border-solid border-neutral-100'
      )}>
      {loading ? (
        <Icon icon="new-loader" className="animate-spin" size={20} />
      ) : url ? (
        <img src={url} alt="image" className="object-cover w-full h-full rounded-full" />
      ) : (
        <Icon icon={icon} size={64} />
      )}
    </div>
  );
};

export default Image;
