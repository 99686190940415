import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getSuperbills } from '../../../../api/Superbill';

export const useSuperbills = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['superbills', [...dependencies]], () => getSuperbills(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
