import React, { useState } from 'react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';
import Tabs from 'components/shared/Tabs/NewTabs';
import Payments from './components/Payments/Payments';
import Calculate from './components/Calculate/Calculate';
import { currentPractice } from 'components/practice/practiceState';

const CalculateAndPayments = ({ isPined }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { display_settings, timezone } = useRecoilValue(currentPractice) || {};

  const tabsData = [
    {
      label: 'Payments',
      content: <Payments isPined={isPined} />,
      permissions: 'products.read'
    }
  ];

  if (display_settings?.claims) {
    tabsData.unshift({
      label: 'Calculate',
      content: <Calculate isPined={isPined} timezone={timezone} />,
      permissions: ['services.read', 'packages.read']
    });
  }

  return (
    <div className="flex flex-col overflow-hidden">
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        hasContent
        className="!pl-4"
        panelsClassName={cs(
          'grid h-full overflow-hidden',
          !isPined && 'border-0 border-l border-solid border-neutral-200'
        )}
        innerPanelClassName="overflow-hidden"
      />
    </div>
  );
};

export default CalculateAndPayments;
