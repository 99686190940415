import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const ActionButtons = ({ selectedRows, setSelectedRows }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState({
    complete: false,
    delete: false
  });
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const taskIds = selectedRows?.map((row) => row?.data?.id);

  const handleAction = async ({ changeStatus = false, markDelete = false }) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        complete: changeStatus ? true : prevState.complete,
        delete: markDelete ? true : prevState.delete
      }));

      const actions = {
        ...(changeStatus && { changeStatus: { status: 'completed' } }),
        markDelete
      };
      const res = await requestApi({
        url: '/api/tasks/update_multiple',
        params: {
          taskIds,
          actions,
          navigate
        }
      });

      if (res.code === 0) {
        queryClient.invalidateQueries({ queryKey: ['tasks'] });
        queryClient.refetchQueries({ queryKey: ['tasks-widget'] });
        showAlert({
          title: `Task ${changeStatus ? 'updated' : 'deleted'} successfully`,
          color: 'success'
        });
        setSelectedRows([]);
      } else {
        showAlert({
          title: `Task ${changeStatus ? 'update' : 'deletion'} failed`,
          message: res?.error ?? 'Something went wrong',
          color: 'danger'
        });
      }
    } catch (error) {
      showAlert({
        title: `Task ${changeStatus ? 'update' : 'deletion'} failed`,
        message: error ?? 'Something went wrong',
        color: 'danger'
      });
    }

    setShowConfirmationModal(false);
    setLoading((prevState) => ({
      ...prevState,
      complete: false,
      delete: false
    }));
  };
  return (
    <>
      {selectedRows.length > 0 &&
        selectedRows.map((item) => item?.data?.status !== 'completed').includes(true) && (
          <Button
            text="Mark complete"
            data-qa="mark-complete-btn"
            type="primary"
            color="success"
            icon="new-check"
            size="small"
            loading={loading.complete}
            onClick={() => handleAction({ changeStatus: true })}
          />
        )}

      <Button
        text="Delete"
        data-qa="delete-btn"
        type="primary"
        color="danger"
        icon="trash"
        iconColor="white"
        size="small"
        loading={loading.delete}
        onClick={() => setShowConfirmationModal(true)}
      />
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title={`Delete ${taskIds?.length > 1 ? 'tasks' : 'task'}`}
          icon="new-document-remove-red"
          message={`Are you sure you want to delete ${
            taskIds?.length > 1 ? 'these tasks' : 'this task'
          } ?`}
          handleOpen={showConfirmationModal}
          handleContinue={() => handleAction({ markDelete: true })}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
    </>
  );
};

export default ActionButtons;
