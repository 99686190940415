import moment from 'moment';

export function reverseGenerateUnit(procedure, startTime, endTime, newValue) {
  const start = moment(startTime, 'hh:mm A');

  const unitDifference = newValue - procedure?.base_unit;

  let reversedEndTime;
  if (unitDifference < 0) {
    reversedEndTime = startTime;
  } else {
    const totalMinutes = unitDifference * 15;
    reversedEndTime = start.add(totalMinutes, 'minutes').format('hh:mm A');
  }

  const timeDifference = newValue;
  const unitTimeDifference = timeDifference / 15;
  const formattedUnit = Math.round(unitTimeDifference.toFixed(2));
  const reversedUnit = formattedUnit + procedure?.base_unit;

  return { reversedEndTime, reversedUnit };
}
