import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/shared/Buttons/Button';

import { onSendEmail, onSendText } from '../lib/utils';

import AltContact from './AltContact';
import ContactButton from './contactButton/ContactButton';
import CustomContact from './contactButton/CustomContact';
import { CustomSendModal } from './contactButton/CustomSendModal';

const SendEmailFooter = ({ handleClose, externalInvoices, finalGroupInvoices }) => {
  const navigate = useNavigate();
  const [customContactType, setCustomContactType] = useState('email');
  const [customModalVisible, setCustomModalVisible] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const finalGroupInvoicesLength = finalGroupInvoices.length > 1;

  const handleCustomSendModalOpen = (type) => {
    setCustomContactType(type);
    setCustomModalVisible(true);
  };

  const handleCustomSendModalClose = () => {
    setCustomModalVisible(false);
  };

  const handleSendText = async ({ alt = {} }) => {
    if (isSending) return;
    setIsSending(true);
    await onSendText({
      ids: externalInvoices.map((invoice) => invoice.id),
      navigate,
      ...alt
    }).finally(() => setIsSending(false));
  };

  const handleSendEmail = async ({ alt = {} }) => {
    if (isSending) return;
    setIsSending(true);
    await onSendEmail({
      ids: externalInvoices.map((invoice) => invoice.id),
      navigate,
      ...alt
    }).finally(() => setIsSending(false));
  };
  const phoneNumber = externalInvoices.find((invoice) => invoice?.patient?.phone)?.patient?.phone;
  const altPhoneNumber = externalInvoices.find((invoice) => invoice?.patient?.alt_phone)?.patient
    ?.alt_phone;
  const email = externalInvoices.find((invoice) => invoice?.patient?.email)?.patient?.email;
  const altEmail = externalInvoices.find((invoice) => invoice?.patient?.alt_email)?.patient
    ?.alt_email;

  return (
    <>
      <div className="flex w-full justify-between">
        <Button
          outlined
          text="Cancel"
          color="neutral"
          onClick={handleClose}
          id="cancelInvoiceModalBtn"
          data-qa="cancel-btn"
        />
        <div className="flex">
          <ContactButton type="text">
            {phoneNumber && (
              <div
                data-qa="send-primary-text"
                onClick={handleSendText}
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                {finalGroupInvoicesLength > 1 ? 'Primary phone' : `${phoneNumber} (primary)`}
              </div>
            )}
            {altPhoneNumber && (
              <AltContact
                type="phone"
                handleAlt={() => handleSendText({ toAltPhone: true, isPopover: true })}>
                {finalGroupInvoicesLength > 1
                  ? 'Alternative phone'
                  : `${altPhoneNumber} (alternative)`}
              </AltContact>
            )}
            <CustomContact handleCustom={() => handleCustomSendModalOpen('text')} type="phone" />
          </ContactButton>

          <ContactButton type="email" disabled={isSending}>
            {email && (
              <div
                data-qa="send-primary-email"
                onClick={handleSendEmail}
                className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
                {finalGroupInvoicesLength > 1 ? 'Primary email' : `${email} (primary)`}
              </div>
            )}
            {altEmail && (
              <AltContact
                type="email"
                handleAlt={() => handleSendEmail({ toAltEmail: true, isPopover: true })}>
                {finalGroupInvoicesLength > 1 ? 'Alternative email' : `${altEmail} (alternative)`}
              </AltContact>
            )}
            <CustomContact
              handleCustom={() => handleCustomSendModalOpen('email')}
              type="email"
              disabled={isSending}
            />
          </ContactButton>
        </div>
      </div>

      {customModalVisible && (
        <CustomSendModal
          ids={externalInvoices.map((invoice) => invoice.id)}
          contactType={customContactType}
          visible={customModalVisible}
          onClose={handleCustomSendModalClose}
          onPrev={handleCustomSendModalClose}
        />
      )}
    </>
  );
};

export default SendEmailFooter;
