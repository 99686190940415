import React, { useEffect, useRef, useState } from 'react';
import { MacrosContext } from './MacrosContext';
import { isEmpty } from '../../helpers/utility';
import { defaultMacroFilters } from '../../../components/practice/charts/ClinicalNote/Sidebar/Macros/Create/lib/constants';
import { createMacro, deleteMacro, updateMacro } from '../../../api/Macro';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMacros } from '../../hooks/queries/macros/useMacros';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../../../components/shared/Alert/Alert';

const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const MacrosContextProvider = ({ children }) => {
  const [deleteMacroId, setDeleteMacroId] = useState(null);
  const [editMacro, setEditMacro] = useState(null);
  const [loadMacrosModal, setLoadMacrosModal] = useState(false);
  const [loading, setLoading] = useState({
    create: false,
    get: false,
    update: false,
    delete: false
  });
  const [filters, setFilters] = useState(defaultMacroFilters);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const debouncedSearch = useDebounce(filters?.searchTerm, 500);

  const formMacroRef = useRef();

  const { data, isFetching } = useMacros({
    options: { enabled: !!filters?.macroType || !!debouncedSearch },
    dependencies: [filters?.macroType, debouncedSearch],
    params: {
      type: !isEmpty(filters.macroType) ? [filters.macroType] : [],
      searchTerm: filters?.searchTerm
    }
  });

  const createMacroMutation = useMutation((data) => createMacro(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['macros']);
      showAlert({ title: 'Macro created successfully', color: 'success' });
    }
  });

  const createNewMacro = async ({ name, type, body }) =>
    await createMacroMutation.mutateAsync({
      name,
      type,
      body
    });

  const updateMacroMutation = useMutation((data) => updateMacro(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['macros']);
      showAlert({ title: 'Macro updated successfully', color: 'success' });
    }
  });

  const updateNewMacro = async (macro) =>
    await updateMacroMutation.mutateAsync({
      macro
    });

  const deleteMacroMutation = useMutation((data) => deleteMacro(navigate, data), {
    onSuccess: () => {
      queryClient.invalidateQueries(['macros']);
      setDeleteMacroId(null);
      showAlert({ title: 'Macro deleted successfully', color: 'success' });
    }
  });

  const deleteNewMacro = async (id, type) =>
    await deleteMacroMutation.mutateAsync({
      ids: [id]
    });

  return (
    <MacrosContext.Provider
      value={{
        macros: data?.macros || [],
        isGettingMacros: isFetching,
        isCreatingMacros: createMacroMutation?.isLoading,
        isUpdatingMacros: updateMacroMutation?.isLoading,
        isDeletingMacros: deleteMacroMutation?.isLoading,
        createMacro: createNewMacro,
        deleteMacro: deleteNewMacro,
        updateMacro: updateNewMacro,
        loadMacrosModal,
        setLoadMacrosModal,
        editMacro,
        setEditMacro,
        deleteMacroId,
        setDeleteMacroId,
        loading,
        setLoading,
        filters,
        setFilters,
        formMacroRef
      }}>
      {children}
    </MacrosContext.Provider>
  );
};
