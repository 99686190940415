import React from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';

const EmptyProductsPackages = ({ type = '', link }) => {
  const navigate = useNavigate();

  return (
    <div className="flex h-[270px] flex-col items-center justify-center gap-[18px] px-10">
      <Icon icon="sell-large" />

      <div className="flex flex-col items-center">
        <p className="text-center text-base font-600 text-primary-900">
          You don’t have any {type}s yet!
        </p>
        <p className="text-center  text-neutral-700">
          Your {type} list is currently empty. Get started by adding your first {type} now!
        </p>
      </div>

      <Button
        text={`Add ${type}`}
        onClick={() => navigate(link ? link : `/portal/settings/${type}s`)}
      />
    </div>
  );
};

export default EmptyProductsPackages;
