import React from 'react';
import DateRangePicker from '../../../../../shared/DateRange/DateRangePicker';

const AllClinicalNotesDateRange = ({ filters, setFilters }) => {
  return (
    <DateRangePicker
      onChange={(e) =>
        setFilters((prevState) => ({
          ...prevState,
          startDate: e.selection.startDate,
          endDate: e.selection.endDate
        }))
      }
      ranges={[
        {
          startDate: filters?.startDate,
          endDate: filters?.endDate,
          key: 'selection'
        }
      ]}
      startDate={filters?.startDate}
      endDate={filters?.endDate}
    />
  );
};

export default AllClinicalNotesDateRange;
