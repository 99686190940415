import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { formatLargeNumber, mString } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';

export const AdminDashboard = () => {
  const navigate = useNavigate();

  const { data } =
    useQuery({
      queryKey: ['getDashboardData'],
      queryFn: getDashboardData,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getDashboardData() {
    const response = await requestApi({
      url: '/api/admin/dashboard',
      params: {
        startDate: null,
        endDate: null
      },
      navigate
    });
    return response;
  }

  return (
    <div className="grid grid-cols-3 grid-rows-3 gap-4 !p-4">
      <Allowed requiredPermissions="practice.read">
        <div
          onClick={() => {
            navigate('/admin/practices');
          }}
          className="flex flex-col gap-4 !rounded-lg border !border-neutral-100 !bg-primary-500 !p-4 px-[14px]  ">
          <div className="flex gap-3">
            <Icon
              size={20}
              color="secondary"
              className="rounded-full bg-white p-1"
              icon="new-profile"
            />
            <span className="font-bold text-white">Practices</span>
          </div>
          <span className="text-sm font-semibold text-white">{`Total: ${formatLargeNumber(data?.practices)}`}</span>
        </div>
      </Allowed>
      <Allowed requiredPermissions="member.read">
        <div
          onClick={() => {
            navigate('/admin/practitioners');
          }}
          className="flex flex-col gap-4 !rounded-lg border  !border-neutral-100  !bg-primary-500 !p-4">
          <div className="flex gap-3">
            <Icon
              size={20}
              color="primary"
              className="rounded-full bg-white p-1"
              icon="new-profile"
            />
            <span className="font-bold text-white">Practitioners</span>
          </div>
          <span className="text-sm font-semibold text-white">{`Total: ${formatLargeNumber(data?.practitioners)}`}</span>
        </div>
      </Allowed>
      <Allowed requiredPermissions="patients.read">
        <div
          onClick={() => {
            navigate('/admin/patients');
          }}
          className="flex flex-col gap-4 !rounded-lg border  !border-neutral-100  !bg-primary-500 !p-4">
          <div className="flex gap-3">
            <Icon
              size={20}
              color="primary"
              className="rounded-full bg-white p-1"
              icon="new-three-people"
            />
            <span className="font-bold text-white">Patients</span>
          </div>
          <span className="text-sm font-semibold text-white">{`Total: ${formatLargeNumber(data?.patients)}`}</span>
        </div>
      </Allowed>
      <Allowed requiredPermissions="billing.read">
        <div className="flex flex-col gap-4 !rounded-lg border !border-neutral-100 bg-purple-500 !p-4">
          <div className="flex gap-3">
            <Icon
              size={20}
              color="primary"
              className="rounded-full bg-white p-1"
              icon="new-claim"
            />
            <span className="font-bold text-white">Claims</span>
          </div>
          <div className="flex flex-col gap-1">
            <span className="text-sm font-semibold text-white">{`Total: ${formatLargeNumber(data?.claims?.total)}`}</span>
            <span className="text-sm font-semibold text-white">{`Draft: ${formatLargeNumber(data?.claims?.draft)}`}</span>
            <span className="text-sm font-semibold text-white">{`Ready: ${formatLargeNumber(data?.claims?.ready)}`}</span>
            <span className="text-sm font-semibold text-white">{`Accepted: ${formatLargeNumber(data?.claims?.accepted)}`}</span>
            <span className="text-sm font-semibold text-white">{`Rejected: ${formatLargeNumber(data?.claims?.rejected)}`}</span>
          </div>
        </div>
      </Allowed>
      <Allowed requiredPermissions="payments.read">
        <div className="flex flex-col gap-4 !rounded-lg border !border-neutral-100 bg-green-500 !p-4">
          <div className="flex gap-3">
            <Icon
              size={20}
              color="primary"
              className="rounded-full bg-white p-1"
              icon="new-dollar-square"
            />
            <span className="font-bold text-white">Processing Volume</span>
          </div>
          <span className="text-sm font-semibold text-white">{`Total: ${mString(data?.processingVolume)}`}</span>
        </div>
      </Allowed>
      <div className="flex flex-col gap-4 !rounded-lg border !border-neutral-100 bg-red-400 !p-4">
        <div className="flex gap-3">
          <Icon
            size={20}
            color="primary"
            className="rounded-full bg-white p-1"
            icon="new-messagesv2"
          />
          <span className="font-bold text-white">Communications</span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm font-semibold text-white">{`Total: ${formatLargeNumber(data?.communications?.email)}`}</span>
          <span className="text-sm font-semibold text-white">{`Draft: ${formatLargeNumber(data?.communications?.sms)}`}</span>
          <span className="text-sm font-semibold text-white">{`Ready: ${formatLargeNumber(data?.communications?.web)}`}</span>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
