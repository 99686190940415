import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { isProcedureChecked, updateItemCount } from '../lib';

const NewItemCounter = ({ newItem, newItems, setNewItems, packageId, invoiceId }) => {
  return (
    !!isProcedureChecked(newItems, newItem, packageId, invoiceId) && (
      <div className="flex items-center gap-2">
        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white">
          <Icon
            stroke
            color="primary"
            icon="new-minus"
            onClick={() =>
              updateItemCount({
                itemId: newItem.id,
                increment: false,
                newItems,
                setNewItems,
                packageId,
                invoiceId
              })
            }
          />
        </div>

        <p className="flex h-6 w-6 select-none items-center justify-center rounded-full bg-primary-500 !text-xs font-500 text-white">
          {isProcedureChecked(newItems, newItem, packageId, invoiceId).count}
        </p>

        <div className="flex h-5 w-5 items-center justify-center rounded-full bg-white">
          <Icon
            color="primary"
            icon="new-plus"
            onClick={() =>
              updateItemCount({ itemId: newItem.id, newItems, setNewItems, packageId, invoiceId })
            }
          />
        </div>
      </div>
    )
  );
};

export default NewItemCounter;
