import cs from 'classnames';
import Input from 'components/shared/Input/Input';
import useHasScrollbar from 'lib/hooks/useHasScrollbar';
import React from 'react';
import Display from './DisplayList';

const SearchList = ({
  title,
  onSearch = () => null,
  onClickAdd = () => null,
  searchData = [],
  loading = false,
  trackSearchDataLoading = {},
  searchTerm = null
}) => {
  const [ref, hasScrollbar] = useHasScrollbar();

  return (
    <div className="flex flex-col overflow-hidden pr-4 pt-3">
      <span className="text-base text-neutral-800">{title}</span>
      <Input
        icon="search"
        className="mt-2"
        placeholder="Search codes"
        id="search-icd-codes"
        onChange={onSearch}
      />
      <div className="overflow-hidden pt-3">
        <div className={cs('h-full overflow-y-auto', hasScrollbar && 'pr-2')} ref={ref}>
          <Display
            loading={loading}
            trackSearchDataLoading={trackSearchDataLoading}
            data={searchData}
            onClickAdd={onClickAdd}
            searchTerm={searchTerm}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchList;
