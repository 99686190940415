import React from 'react';
import SelectableButton from './SelectableButton';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';

const Header = ({
  isAdvancedForm,
  handleNoDetails,
  noDetails,
  children,
  title,
  noDetailsLabel,
  fromCheckin,
  className,
  emptyState,
  addRow
}) => {
  return (
    <div className={cs('flex w-full flex-col items-center justify-center', className)}>
      {fromCheckin &&
        ((noDetails === null || noDetails === undefined || noDetails) &&
        handleNoDetails &&
        emptyState ? (
          <div className="flex w-full max-w-[500px] flex-col items-center justify-center rounded-xl bg-white p-10 py-12 md:max-w-[450px]">
            <Icon icon="new-no-data"></Icon>
            <p className="mb-2 text-center text-base text-primary-900">{emptyState?.title}</p>
            <p className="mb-2 text-center text-sm text-neutral-700">{emptyState?.subtitle}</p>
            <p className="mb-2 mt-3 text-center text-sm font-500 !text-primary-900">
              {emptyState?.label}
            </p>
            <div className={cs(' !grid w-full !grid-cols-2 gap-2', !noDetails ? 'mb-3' : '-mb-3')}>
              <SelectableButton
                data-qa="yes"
                label="Yes"
                isChecked={noDetails == false}
                onChange={() => handleNoDetails(false)}
              />
              <SelectableButton
                data-qa="no"
                label="No"
                isChecked={noDetails == true}
                onChange={() => handleNoDetails(true)}
              />
            </div>
          </div>
        ) : (
          <>
            <p
              className={cs(
                ' text-center text-xl text-primary-700',
                handleNoDetails ? 'mb-1' : 'mb-3'
              )}>
              {title}
            </p>
            {isAdvancedForm && handleNoDetails && (
              <div className="mb-3 flex w-full max-w-[500px] flex-col items-center justify-center !px-4 md:!px-6 lg:!px-6 xl:!px-6">
                <p className="mb-[1rem] mt-2 px-4 text-center text-sm text-neutral-600">
                  {noDetailsLabel}
                </p>

                <div
                  className={cs(' !grid w-full !grid-cols-2 gap-2', !noDetails ? 'mb-3' : '-mb-3')}>
                  <SelectableButton
                    data-qa="yes"
                    label="Yes"
                    isChecked={!noDetails}
                    onChange={() => handleNoDetails(false)}
                  />
                  <SelectableButton
                    data-qa="no"
                    label="No"
                    isChecked={noDetails}
                    onChange={() => handleNoDetails(true)}
                  />
                </div>
              </div>
            )}
          </>
        ))}

      {(noDetails === false && isAdvancedForm) || !handleNoDetails || !fromCheckin
        ? children
        : null}

      {((noDetails === false && isAdvancedForm) || !handleNoDetails) && addRow && fromCheckin && (
        <div className="mb-3 mt-3 flex w-full max-w-[500px] flex-col items-center justify-center !px-4 md:!px-6 lg:!px-6 xl:!px-6">
          <p className="mb-[1rem] mt-2 text-sm text-neutral-600">
            {addRow?.label || 'Do you want to add another row?'}
          </p>

          <div className={cs(' !grid w-full !grid-cols-2 gap-2', '-mb-3')}>
            <SelectableButton
              data-qa="yes"
              label="Yes"
              isChecked={false}
              onChange={addRow.onClick}
            />
            <SelectableButton data-qa="no" label="No" isChecked={true} onChange={() => null} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
