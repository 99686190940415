export const typographyVariants = {
  h1: 'text-[2.5rem] leading-[125%]',
  h2: 'text-4xl leading-[125%]',
  h3: 'text-[2rem] leading-[125%]',
  h4: 'text-[1.75rem] leading-[129%]',
  h5: 'text-2xl leading-[133%]',
  h6: 'text-xl leading-[140%]',
  p: {
    large: 'text-lg leading-[156%]',
    medium: 'text-base leading-[150%]',
    small: 'text-sm leading-[157%]',
    xs: 'text-xs leading-[167%]'
  },
  label: {
    large: 'text-base leading-[112%]',
    medium: 'text-sm leading-[114%]',
    small: 'text-xs leading-[117%]',
    xs: 'text-[0.625rem] leading-[140%]'
  },
  overline: {
    small: 'font-semibold text-xs leading-[167%] tracking-[1px] uppercase',
    medium: 'font-semibold text-base leading-[143%] tracking-[1px] uppercase'
  },
  display: {
    large: 'font-medium text-[3.25rem] leading-[125%]',
    small: 'font-medium text-[2.75rem] leading-[125%]'
  }
};
