import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import { useRecoilState } from 'recoil';

import { clinicalNote as clinicalNoteState } from 'components/state';

import { useClinicalNoteContext } from '../../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import Textarea from '../../../../../../../shared/Textarea/Textarea';

const ProblemManifestationDefinition = ({ isTPlan, sectionRef = null }) => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  const [problemManifestationDefinition, setProblemManifestationDefinition] = useState();
  const problemManifestationDefinitionRef = useRef();

  useEffect(() => {
    setProblemManifestationDefinition(
      isTPlan
        ? selectedTreatmentPlan?.problem_manifestation_definition
        : clinicalNote?.other_complaints
    );
  }, [selectedTreatmentPlan, clinicalNote?.other_complaints]);

  useImperativeHandle(sectionRef, () => ({
    formData: { other_complaints: problemManifestationDefinition }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setProblemManifestationDefinition(value ?? e);

    if (isTPlan) {
      setSelectedTreatmentPlan({
        ...selectedTreatmentPlan,
        problem_manifestation_definition: value ?? e
      });
    } else {
      setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
    }
  };

  return (
    <Textarea
      isEditor
      editorParentClassName="!sticky"
      label="Problem manifestation / definition"
      placeholder="Write problem manifestation here"
      id="other_complaints"
      name="other_complaints"
      className="min-h-[200px]"
      value={problemManifestationDefinition}
      onChange={(e) => handleChange(e, 'other_complaints')}
      transcribing
      forwardedRef={problemManifestationDefinitionRef}
      disabled={!isTPlan && clinicalNote?.locked}
      data-qa="other-complaints"
    />
  );
};

export default ProblemManifestationDefinition;
