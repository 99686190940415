import React from 'react';
import { NavLink } from 'react-router-dom';
import cs from 'classnames';

const classStyle = 'rounded-full !py-2 !px-4 text-sm text-neutral-600 whitespace-nowrap';
const activeClassStyle =
  'rounded-full bg-gradient-to-r from-[#13B8FF] to-primary-200 !py-2 !px-4 text-sm font-600 text-white whitespace-nowrap !text-white';

const Tabs = ({ id }) => {
  return (
    <div className="relative shrink-0 overflow-hidden from-transparent to-white !px-10 !py-2">
      <div data-qa="basic-info" className={cs('flex w-full gap-4 overflow-x-auto')}>
        <NavLink
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/patients/${id}`}
          end>
          Overview
        </NavLink>
        <NavLink
          data-qa="transactions"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/patients/${id}/transactions`}>
          Transactions
        </NavLink>
        <NavLink
          data-qa="logs"
          className={({ isActive }) => (isActive ? activeClassStyle : classStyle)}
          to={`/admin/patients/${id}/logs`}>
          Logs
        </NavLink>
      </div>
    </div>
  );
};

export default Tabs;
