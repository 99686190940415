import React, { useEffect, useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Loader from 'components/shared/Loader/Loader';
import { createPromptTemplate, getPromptTemplates } from 'api/Admin/intelligence/prompts';
import { useFormik } from 'formik';
import { initialValues, validate } from './helpers';
import Button from 'components/shared/Buttons/Button';
import AdminIntelligencePromptForm from 'components/admin/pages/Intelligence/Prompt/form';

const AdminIntelligencePromptDetails = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [prompt, setPrompt] = useState();
  const [promptId, setPromptId] = useState();
  const [show, setShow] = useState({ loading: false });
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initialValues(prompt),
    enableReinitialize: true,
    validationSchema: validate,
    onSubmit: async (values) => {
      console.log('newValues', values);
      const newRevision = {
        prompt: {
          id: values?.id,
          version: parseInt(values?.version, 10) + 1,
          template_name: values?.template_name,
          body: values?.body,
          options: values?.options,
          ai_model_id: values?.ai_model_id
        }
      };

      const res = await createPromptTemplate(navigate, newRevision);

      searchParams.set('promptId', `${res?.promptTemplate?.id}v${res?.promptTemplate?.version}`);
      // setSearchParams((sp) =>
      //   sp.set('promptId', `${res?.promptTemplate?.id}v${res?.promptTemplate?.version}`)
      // );
      getPrompt();
    }
  });

  useEffect(() => {
    if (searchParams.get('promptId')) {
      getPrompt();
    }
  }, [searchParams]);

  const getPrompt = async () => {
    setShow((ps) => ({ ...ps, loading: true }));
    const promptSplit = searchParams?.get('promptId').split('v');
    setPromptId({ id: parseInt(promptSplit[0], 10), version: parseInt(promptSplit[1], 10) });
    const res = await getPromptTemplates(navigate, {
      id: parseInt(promptSplit[0], 10),
      version: parseInt(promptSplit[1], 10)
    });
    setPrompt(res?.result);
    Object.keys(res?.result).map((v) => {
      console.log(v, res?.result?.[v]);
      formik.setValues(v, res?.result?.[v]);
    });
    setShow((ps) => ({ ...ps, loading: false }));
  };

  return (
    <Modal
      slideFromRight
      isOpen={promptId?.id}
      handleClose={() =>
        setSearchParams((sp) => {
          setPromptId(null);
          setPrompt(null);
          sp.delete('promptId');
        })
      }
      title={`Prompt ${prompt?.template_name}:${prompt?.id}:${prompt?.version}`}
      footer={<Button text="Save Revision" color="primary" onClick={formik.submitForm} />}>
      {show.loading && (
        <div className="flex flex-row gap-2">
          <Loader outlined color="primary" />
          <div>Loading...</div>
        </div>
      )}
      {!show.loading && (
        <AdminIntelligencePromptForm
          formik={formik}
          value={prompt}
          onChange={(e) => {
            // console.log(e?.name, e?.value);
            formik.setFieldValue(e?.name, e?.value);
          }}
        />
      )}
    </Modal>
  );
};

export default withErrorBoundary(AdminIntelligencePromptDetails);
