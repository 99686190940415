import '!style-loader!css-loader!react-vertical-timeline-component/style.min.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { interimApi } from '../../../../../../api/InterimApi';
import { formatDateAndTime, isEmpty, Truncate } from '../../../../../../lib/helpers/utility';
import Icon from '../../../../../shared/Icon/Icon';
import Spinner from '../../../../../shared/spinner/spinner';
import '../Style/logs.scss';
import EmailModal from './EmailModal';
import LogStatus from './LogStatus';
import { getIconAndText, textTitle } from './lib/helper';

export default function TimeLineElement({ item, index, timezone, patientId }) {
  const { icon, text, body } = getIconAndText(
    item?.receiver_id,
    item?.medium,
    item?.notification_kind,
    item?.receiver?.p_name || item?.receiver?.f_name
  );
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailHtml, setEmailHtml] = useState(false);
  const [viewFullMessage, setViewFullMessage] = useState(false);

  const textBody = (body, notification_kind) => {
    if (viewFullMessage) {
      return body?.split(',').slice(1).join(',').trim();
    }
    const bodyParts = body?.split(',');

    if (isEmpty(notification_kind)) return Truncate(body, 55);

    if (bodyParts?.length > 1) {
      const textAfterComma = bodyParts.slice(1).join(',').trim();

      if (textAfterComma.length > 0) {
        return Truncate(textAfterComma, 55);
      }
    }
    return Truncate(body, 55);
  };

  const viewEmail = async ({ id }) => {
    try {
      setLoading(true);
      const res = await interimApi('/api/send_grid/view_email', { id }, navigate);
      if (res.data.email) {
        setEmailHtml(res.data.email);
      } else {
        setEmailHtml(false);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  if (emailHtml) {
    return <EmailModal emailHtml={emailHtml} setEmailHtml={setEmailHtml} />;
  }

  return (
    <div key={index}>
      <VerticalTimelineElement
        visible={true}
        className="log vertical-timeline-element--work relative "
        contentStyle={{
          background: '#fff',
          boxShadow: 'none',
          padding: 0
        }}
        dateClassName="DateStyling"
        iconClassName="CustomIcon"
        icon={icon}
        iconStyle={{
          boxShadow: 'none'
        }}>
        <div className="vertical-timeline-element-title">
          <p className="flex flex-wrap gap-x-1 !text-sm text-neutral-700">
            <em className="not-italic text-primary-500">
              {isEmpty(item?.receiver_id)
                ? `${item?.user?.f_name} ${item?.user?.l_name}`
                : isEmpty(item.sender_id)
                  ? 'Myriad Health'
                  : `${item?.user?.f_name} ${item?.user?.l_name}`}
            </em>
            <em className="not-italic">{text}</em>
            <em className="not-italic text-primary-900">
              {item.receiver
                ? `${item.receiver?.f_name} ${item.receiver?.l_name}`
                : item.practice?.name}
            </em>
            <em className="h-1 w-1 self-center rounded-full bg-neutral-500 font-normal not-italic"></em>
            <em className="self-center text-xs not-italic">
              {formatDateAndTime(item?.delivered_at ?? item?.created_at, timezone)}
            </em>
          </p>
        </div>
        <div className="w-[640px]">
          <div className="relative !mt-4 rounded-lg border-[0.4px] border-solid border-neutral-200 !p-4 !pl-6 text-lg text-primary-900 shadow-sm">
            {item.medium === 'email' ? (
              <div className="absolute right-0 top-0" onClick={() => viewEmail({ id: item.id })}>
                {!loading ? (
                  <Icon icon="new-maximize-arrows" className="hover:cursor-pointer" />
                ) : (
                  <Spinner />
                )}
              </div>
            ) : item.medium === 'sms' && !!item.notification_kind ? (
              <div
                className="absolute right-0 top-0"
                onClick={() => setViewFullMessage((prev) => !prev)}>
                {!loading ? (
                  <Icon
                    icon={viewFullMessage ? 'minimize' : 'maximize'}
                    className="hover:cursor-pointer"
                  />
                ) : (
                  <Spinner />
                )}
              </div>
            ) : (
              isEmpty(item.notification_kind) && (
                <div className="absolute right-2 top-[10px]">
                  <button
                    onClick={() =>
                      navigate(`/portal/charts/${patientId}/communications?messageId=${item.id}`)
                    }
                    className="group flex cursor-pointer items-center gap-[6px] rounded-full border !border-primary-200 bg-primary-50 px-[16px] py-[4px] hover:!bg-primary-100">
                    <span className="text-xs font-500 leading-5 text-primary-500 group-hover:text-primary-600">
                      View message
                    </span>
                  </button>
                </div>
              )
            )}
            {item.body?.length > 0 ? (
              <div>
                {isEmpty(item?.receiver_id) || isEmpty(item?.sender_id) ? (
                  <div className="w-[412px]">
                    {item.body?.split(',').length > 1 &&
                      !isEmpty(item.body?.split(',')[1]) &&
                      item.notification_kind && (
                        <h4 className="!mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-base">
                          {textTitle(item.body)}
                        </h4>
                      )}
                    <h6
                      className={`overflow-hidden text-ellipsis ${
                        !viewFullMessage && 'whitespace-nowrap'
                      } text-base`}>
                      {textBody(item.body, item?.notification_kind)}
                    </h6>
                  </div>
                ) : (
                  <>
                    <h6 className="overflow-hidden text-ellipsis whitespace-nowrap text-base">
                      {item.body?.split(',').length > 1 &&
                        !isEmpty(item.body?.split(',')[1]) &&
                        item.notification_kind && (
                          <h4 className="!mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-base">
                            {textTitle(item.body)}
                          </h4>
                        )}
                      <h6
                        className={`overflow-hidden text-ellipsis ${
                          !viewFullMessage && 'whitespace-nowrap'
                        } text-base`}>
                        {textBody(item.body, item?.notification_kind)}
                      </h6>
                    </h6>
                  </>
                )}
              </div>
            ) : (
              <div>
                <h4 className="!mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-base">
                  {textTitle(body)}
                </h4>
                <h6
                  className={`overflow-hidden text-ellipsis ${
                    !viewFullMessage && 'whitespace-nowrap'
                  } text-base`}>
                  {textBody(body, item?.notification_kind)}
                </h6>
              </div>
            )}
          </div>
          {item.medium === 'email' && (
            <div className="relative !mt-2 flex gap-x-1">
              <em className="text-xs not-italic text-neutral-700">Email status:</em>
              <LogStatus
                status={item.status}
                id={item.resource_id}
                type={item.notification_kind}
                medium={item.medium}
                messageId={item.id}
              />
            </div>
          )}
          {item.medium === 'sms' && (
            <div className="relative !mt-2 flex items-center gap-x-1">
              <em className="text-xs not-italic text-neutral-700">Text status:</em>
              <LogStatus
                status={item.status}
                fromPatient={isEmpty(item?.receiver_id)}
                id={item.resource_id}
                type={item.notification_kind}
                medium={item.medium}
              />
            </div>
          )}
        </div>
      </VerticalTimelineElement>
    </div>
  );
}
