import React, { useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';

import { Formik } from 'formik';
import { useRecoilValue } from 'recoil';

import { useUser } from 'lib/hooks/queries/user/useUser';

import { currentPractice } from 'components/practice/practiceState';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { userState } from 'components/state';

import MedicalNecessityConfirm from './components/MedicalNecessityConfirm';
import OrderDetails from './components/OrderDetails/OrderDetails';
import TestsSection from './components/TestsSection/TestsSection';
import SelectedTestsTableWrapper from './components/TestsTable/SelectedTestsTableWrapper';
import UpdateModal from './components/UpdateModal';
import useSubmitOrder from './hooks/useSubmitOrder';
import { initialFormikValues } from './lib/constants';
import { LabSoftOrderValidationSchema } from './lib/handleFormik';

const LabSoftOrderCreate = ({ actionRef, itemProps = {}, onSave }) => {
  const [gridApi, setGridApi] = useState(null);
  const [modalData, setModalData] = useState(null);

  const { appointmentId } = useParams();
  const { patient: outletPatient } = useOutletContext() || {};

  const patient = outletPatient || itemProps.patient;

  const user = useRecoilValue(userState);
  const practice = useRecoilValue(currentPractice);

  const { data: currentUserInfo } = useUser({
    dependencies: [user.id],
    options: {
      enabled: !!user.id,
      select: ({ user }) => user
    }
  });

  const { submit } = useSubmitOrder({ patient, gridApi, setModalData, onSave });

  if (!currentUserInfo?.id) return <Skeleton height="100vh" width="100vw" />;

  const getGridApi = (api) => setGridApi(api);

  const submitHandler = async (values) => {
    const formattedValues = Object.keys(values).reduce((prev, key) => {
      let item = values[key];

      if (key === 'orderLocation' || key === 'orderCollector') {
        item = item.label;
      } else if (key === 'diags') {
        item = item.map((diag) => diag.label);
      } else if (key === 'tests') {
        item = item.map((test) => {
          test.status = test?.status?.value;
          return test;
        });
      } else if (typeof item === 'object' && item?.value) {
        item = item.value;
      }

      return { ...prev, [key]: item };
    }, {});

    await submit(formattedValues);
  };

  return (
    <>
      <Formik
        initialValues={{
          ...initialFormikValues,
          practitionerId: user.id,
          patientId: patient.id,
          appointmentId
        }}
        validateOnBlur
        validationSchema={LabSoftOrderValidationSchema}
        onSubmit={submitHandler}>
        <div className="xl:h-[calc(100vh-155px)] grid gap-4 grid-cols-4">
          <TestsSection gridApi={gridApi} />

          <OrderDetails patient={patient} actionRef={actionRef} />

          <SelectedTestsTableWrapper getGridApi={getGridApi} />

          <MedicalNecessityConfirm actionRef={actionRef} />
        </div>
      </Formik>
      {modalData ? (
        <UpdateModal
          modalData={modalData}
          setModalData={setModalData}
          practitionerId={user.id}
          patientId={patient.id}
          practiceId={practice.id}
        />
      ) : null}
    </>
  );
};

export default LabSoftOrderCreate;
