import cs from 'classnames';
import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import React, { useMemo } from 'react';

const MultiSelectMedicationsTable = ({ data = [], customStyling, onCellClicked = () => {} }) => {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);
  return (
    <ErrorBoundary>
      <div className={cs('my-5 flex h-[90dvh]  flex-col overflow-hidden', customStyling)}>
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={data}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MultiSelectMedicationsTable;
