import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import cs from 'classnames';

import { createMultipleClaims } from 'api/Billing';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia, mapValues } from 'lib/helpers/utility';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { usePatientSuperbill } from 'lib/hooks/queries/superbill/usePatientSuperbill';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import ErrorModal from 'components/practice/BeyondBilling/Superbill/components/ErrorModal';
import {
  READY_ERROR_MESSAGE,
  READY_FOR_CLAIM_MESSAGE,
  READY_VALIDATION_ERROR_MESSAGE
} from 'components/practice/BeyondBilling/Superbill/lib/helpers';
import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { defaultColDef, rowClassRules } from 'components/shared/AGTable/lib/helpers';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Filter from 'components/shared/Filters/Filter';
import Allowed from 'components/shared/Permissions/Allowed';

import { defaultFilters } from '../lib';

const SuperbillTable = ({ patient: patientProp, setSuperbillId }) => {
  const [selectedSuperbills, setSelectedSuperbills] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedSuperbill, setSelectedSuperbill] = useState(null);

  const chartContext = useOutletContext() || {};
  const patient = chartContext?.patient || patientProp;

  const { isOpen, openModal, closeModal } = useModal();

  const { gridApi, limit, page, setPage, sort, filters, setFilters } = useTableContext();

  const navigate = useNavigate();

  const { data } = usePatientSuperbill({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      patientId: patient?.id
    },
    dependencies: [limit, page, sort, mapValues(filters), patient?.id]
  });

  const superbills = useMemo(() => {
    return (data?.superbill || []).map((bill) => ({
      ...bill
    }));
  }, [data]);

  const count = data?.count || 0;

  const { data: providers = {} } = usePractitioners();

  const mutateReadyForClaim = useCustomMutation({
    mutationFn: () => createMultipleClaims(navigate, { superBillIds: selectedSuperbills }),
    invalidateQueryKey: 'patient_superbill',
    successMessage: (data) => {
      const { errors = {}, superbills = [] } = data?.data || {};
      if (Object.keys(errors).length > 0) {
        return READY_VALIDATION_ERROR_MESSAGE(errors);
      }
      return READY_FOR_CLAIM_MESSAGE(superbills);
    },
    errorMessage: READY_ERROR_MESSAGE,
    onSuccess: (data) => {
      setSelectedSuperbills([]);
      const errors = data?.data?.errors;
      setErrors(errors);
    }
  });

  const isReadyForClaimLoading = mutateReadyForClaim.isLoading;
  const isDisabled = selectedSuperbills.length === 0 || isReadyForClaimLoading;

  useEffect(() => {
    if (ia(providers?.practitioners)) {
      const formattedProviders = providers.practitioners.map((p) => ({
        label: p?.f_name + ' ' + p?.l_name,
        value: p?.id
      }));
      setFilters((prev) => ({
        ...prev,
        providers: { ...prev.providers, options: formattedProviders }
      }));
    }
  }, [providers?.practitioners]);

  const onRowClicked = (event) => {
    if (setSuperbillId) {
      setSuperbillId(event.data.id);
    }
    setSelectedSuperbill(event?.data);

    if (event.data.error) {
      openModal();
      return;
    }
    if (setSuperbillId) return;
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/portal/charts/${patient.id}/billing/superbill/${event.data.id}`, {
        replace: false
      });
    }
  };

  const onCellClicked = async (e) => {
    if (['actions', '0'].includes(e?.column?.colId)) return;
    await onRowClicked(e);
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data).map((d) => d.id);

      setSelectedSuperbills(selectedData);
    }
  };

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: superbills,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: superbills,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [superbills, count, page, limit, onPageChange]);

  return (
    <ErrorBoundary>
      <div className="!m-2 flex items-center justify-end gap-2">
        <Allowed requiredPermissions="superbill.update">
          <Button
            size="sm"
            data-qa="complete-selected-superbill"
            onClick={() => mutateReadyForClaim.mutate()}
            text="Ready for claim"
            loading={isReadyForClaimLoading}
            disabled={isDisabled}
          />
        </Allowed>
        <DisplayButton />
        <Filter
          category="superbill"
          defaultFilters={defaultFilters}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
      <div className={cs('flex h-full flex-col overflow-hidden !pb-0')}>
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={superbills}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            customClassName="ag-grid-interactive"
            onCellClicked={onCellClicked}
            onSelectionChanged={onSelectionChanged}
            statusBar={statusBar}
            rowClassRules={rowClassRules}
          />
        </div>
      </div>

      {isOpen && (
        <ErrorModal
          errors={errors}
          id={selectedSuperbill?.claim_id}
          selectedItem={selectedSuperbill}
          onClose={closeModal}
          patient_id={selectedSuperbill?.patient_id}
        />
      )}
    </ErrorBoundary>
  );
};

export default SuperbillTable;
