import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Button/Button';
import Switch from 'components/shared/Switch/Switch';
import { io, snakeToTitleCase } from 'lib/helpers/utility';

export default function SelfSchedulingModal({
  showModal,
  hideModal,
  selfScheduling,
  handleChange
}) {
  return (
    <Modal
      slideFromRight
      handleOpen={showModal}
      handleClose={hideModal}
      title="Self scheduling settings"
      className="max-w-[1100px]"
      footer={
        <>
          <Button primary onClick={hideModal}>
            Close
          </Button>
        </>
      }>
      <div className="flex flex-wrap gap-3">
        {io(selfScheduling) ? (
          Object.keys(selfScheduling).map((key) => {
            return (
              <div
                className="flex flex-col gap-2 rounded-sm border border-neutral-200 border-solid !p-3"
                key={key}>
                <div>{snakeToTitleCase(key)}</div>
                <Switch
                  onChange={(e) =>
                    handleChange('self_scheduling', {
                      ...selfScheduling,
                      [key]: e
                    })
                  }
                  checked={!!selfScheduling[key]}
                />
              </div>
            );
          })
        ) : (
          <p>No self scheduling settings.</p>
        )}
      </div>
    </Modal>
  );
}
