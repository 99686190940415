const atom = require('recoil').atom;

module.exports = {
  userState: atom({
    key: 'activateUserState',
    default: {
      f_name: 'Andrey',
      l_name: 'Shtukenberg',
      email: 'andrey@shtukenberg.com',
      phone: '1234'
    }
  }),
  practiceState: atom({
    key: 'activatePracticeState',
    default: {
      name: 'Medical MD'
    }
  }),
  progressState: atom({
    key: 'activateProgressState',
    default: {
      current: 1,
      total: 2
    }
  })
};
