import React, { useImperativeHandle } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useRecoilValue } from 'recoil';

import { upsertReferralOrder } from '../../../../../../../api/ReferalOrder';
import { useUser } from '../../../../../../../lib/hooks/queries/user/useUser';
import { userState } from '../../../../../../state';
import { OrderReferralValidationSchema, getInitialData } from '../../lib/handleFormik';

import ContactInfo from './components/ContactInfo';
import DemographicsAndSummary from './components/DemographicsAndSummary';
import IncludeClinicalNotes from './components/IncludeClinicalNotes';
import Instructions from './components/Instructions';
import YourSignature from './components/YourSignature';

const currentUserInfoDataMapper = (userData) => {
  return {
    from_name: `${userData?.f_name} ${userData?.l_name}`,
    from_phone: userData?.phone ?? '',
    from_email: userData?.email ?? '',
    from_address: userData?.userAddress?.fullAddress ?? '',
    provider_id: userData?.id || ''
  };
};

const OrderReferralCreate = ({ actionRef, itemProps = {}, setActiveView }) => {
  const user = useRecoilValue(userState);
  const { pathname } = useLocation();
  const { appointmentId } = useParams();
  const navigate = useNavigate();
  const { id: patientId } = useParams();

  const { data: currentUserInfo } = useUser({
    dependencies: [user.id],
    options: {
      select: ({ user }) => user
    }
  });

  const queryClient = useQueryClient();
  const upsertReferralOrderMutation = useMutation((data) => upsertReferralOrder(navigate, data), {
    onSuccess: (data) => {
      const { referrals } = data?.data || {};

      formik.setValues(referrals);

      queryClient.invalidateQueries(['orders']);

      setActiveView((prevState) => ({
        ...prevState,
        loading: false,
        component: 'preview',
        itemProps: referrals
      }));
    },
    onSettled: () => {
      setActiveView((prevState) => ({ ...prevState, loading: false }));
    }
  });

  const formik = useFormik({
    initialValues: getInitialData({
      ...currentUserInfoDataMapper(currentUserInfo),
      defaultCurrentClinicalNote: appointmentId ? [Number(appointmentId)] : null,
      ...itemProps
    }),
    validationSchema: OrderReferralValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setActiveView((prevState) => ({ ...prevState, loading: true }));

      const signatureDataValues = values?.signatureData;

      delete values?.signatureData;

      await upsertReferralOrderMutation.mutateAsync({
        upsertData: { ...values, patient_id: patientId },
        signatureData: { ...signatureDataValues, link: pathname }
      });
    }
  });

  useImperativeHandle(actionRef, () => ({
    onSubmit: formik.submitForm
  }));

  return (
    <div>
      <div className="flex justify-between">
        <ContactInfo providerInfo={currentUserInfo} fromProvider={true} formik={formik} />
        <ContactInfo toProvider={true} formik={formik} />
      </div>

      <div className="!mt-6">
        <Instructions formik={formik} />
      </div>

      <div className="!mt-6">
        <DemographicsAndSummary formik={formik} />
      </div>

      <div className="!mt-6">
        <IncludeClinicalNotes formik={formik} />
      </div>

      <div className="!mt-6">
        <YourSignature formik={formik} />
      </div>
    </div>
  );
};

export default OrderReferralCreate;
