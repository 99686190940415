import { getRecoil, setRecoil } from 'recoil-nexus';

import state from 'components/state';

export default () => {
  const aiTimeout = getRecoil(state.intelligenceAiState);
  clearTimeout(aiTimeout?.timeoutTimeout);
  setRecoil(state.intelligenceAiState, (ps) => ({
    ...ps,
    timeoutTimeout: null
  }));
};
