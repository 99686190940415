import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Capitalize, ia } from 'lib/helpers/utility';
import Loading from 'components/shared/Loading/Loading';
import { usePractitioner } from 'lib/hooks/queries/practitioners/usePractitioner';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Tabs from './Tabs';
import OverviewBlock from './OverviewBlock';
import { usePractitionerPatients } from 'lib/hooks/queries/practitioners/usePractitionerPatients';

export default function PractitionerProfile() {
  const [showModal, setShowModal] = useState(false);
  const [practices, setPractices] = useState([]);
  const [licenses, setLicenses] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const { data, error, isLoading } = usePractitioner({
    params: { practitioner_id: id },
    dependencies: id
  });

  const { data: patientsData } = usePractitionerPatients({
    params: { user_id: id, user_kind: 'practitioner' },
    dependencies: id
  });

  useEffect(() => {
    if (data) {
      const {
        code,
        redirect,
        practitioner: loadedPractitioner,
        practices: loadedPractices,
        licenses: loadedLicenses
      } = data;

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setPractices(loadedPractices || []);
          setLicenses(loadedLicenses || []);
          break;
        case 1:
          toast.error(data.error);
          break;
        default:
          Honeybadger.notify(`Unknown error: ${error ?? 'There has been an error'}`);
          if (error) toast.error(error);
          break;
      }
    }
  }, [data, navigate, error]);

  if (isLoading) {
    return (
      <div className="flex min-h-[300px] items-center bg-white">
        <Loading />
      </div>
    );
  }

  const practitioner = data?.practitioner;
  const patients = patientsData?.patients;

  if (!practitioner) {
    return (
      <div className="flex min-h-[300px] items-center bg-white">
        <Loading />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col">
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <OverviewBlock practitioner={practitioner} />
      </ErrorBoundary>
      <div className="!mt-4 flex h-full flex-col overflow-hidden rounded-2xl bg-white shadow-small">
        <Tabs id={id} />
        {practitioner ? (
          <Outlet
            context={{
              practitioner,
              setPractices,
              practices,
              licenses,
              patients,
              showModal,
              practitionerId: id,
              setShowModal
            }}
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </div>
  );
}