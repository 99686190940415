import React, { useEffect, useRef, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import toast from 'react-hot-toast';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';

import Button from 'components/shared/Buttons/Button';

import { requestApi } from '../../../../api/Api';
import { interimApi } from '../../../../api/InterimApi';
import { reShapeProcedures } from '../../../../lib/helpers/utility';
import Modal from '../../../shared/Modal/Modal';
import Pagination from '../../../shared/Pagination/Pagination';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import Tabs from '../../../shared/Tabs/NewTabs';
import PreviewAppointment from '../../appointment/PreviewAppointment/PreviewAppointment';
import { currentPractice } from '../../practiceState';

export default function Appointments({ props }) {
  const [appointments, setAppointments] = useState([]);
  const [upcomingCounter, setUpcomingCounter] = useState(0);
  const [pastCounter, setPastCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [showAdvancedForm, setShowAdvancedForm] = useState(false);
  const [selectedAdvancedForm, setSelectedAdvancedForm] = useState(null);
  const submitAdvancedSubjective = useRef();
  const [loading, setLoading] = useState({ general: false, table: false });
  const practice = useRecoilValue(currentPractice);
  const [page, setPage] = useState({ upcoming: 1, past: 1 });
  const outletContext = useOutletContext() || {};
  const { patient } = props?.patient ? props : outletContext;
  const { type } = useParams();
  const navigate = useNavigate();
  const [tabsData, setTabsData] = useState([
    {
      label: `Upcoming (${upcomingCounter})`,
      path: ``,
      end: true
    },
    {
      label: `Past (${pastCounter})`,
      path: 'past'
    }
  ]);
  moment.tz.setDefault(practice?.timezone);

  useEffect(() => {
    getAppointments();
  }, [type]);

  const { data: allProviders } = usePractitioners();

  const { data } = useQuery({
    queryKey: ['getServices'],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: null
      }
    });
    return response;
  }

  useEffect(() => {
    const copyArr = [...tabsData];
    copyArr[0] = { ...copyArr[0], label: `Upcoming (${upcomingCounter})` };
    copyArr[1] = { ...copyArr[1], label: `Past (${pastCounter})` };
    setTabsData(copyArr);
  }, [upcomingCounter, pastCounter]);

  const getAppointments = async (props) => {
    const { pageChanged, page: pageProp, type: typeProp } = props || {};

    if (pageChanged) setLoading({ ...loading, table: true });
    else setLoading({ ...loading, general: true });

    try {
      let params = {
        patientId: patient?.id,
        withCount: pageChanged ? undefined : true,
        page: pageProp || page[type],
        type: typeProp || type
      };

      let { data } = await interimApi('/api/practice/charts/appointments', params, navigate);

      const { appointments, upcomingCount, pastCount } = data || {};

      if (appointments) setAppointments(appointments);
      if (upcomingCount) setUpcomingCounter(upcomingCount);
      if (pastCount) setPastCounter(pastCount);
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }

    setLoading({ general: false, table: false });
  };

  const handleFormSubmit = async (e, responseId, formId, formVersion) => {
    submitAdvancedSubjective.current.classList.add('is-loading');
    try {
      let params = {
        responseId,
        formId,
        json: { fields: JSON.stringify(e) },
        appointmentId: selectedAppointment.id,
        patientId: selectedAppointment.patient.id,
        formVersion
      };
      let res = await interimApi('/api/form/upsert_response', params, navigate);
      if (res.data.upsert) {
        toast.success('Changes have been saved!', { duration: 3000 });
        submitAdvancedSubjective.current.classList.remove('is-loading');
        submitAdvancedSubjective.current.innerHTML = 'Saved';
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const hideModal = () => {
    setShowModal(false);
    setSelectedAppointment(null);
    setSelectedAppointmentId(null);
  };

  const changePage = ({ selected }) => {
    getAppointments({ pageChanged: true, page: selected + 1 });
    setPage({ ...page, [type]: selected + 1 });
  };

  const selectAppointment = (id) => {
    setSelectedAppointmentId(id);
    setShowModal(true);
  };

  return (
    <>
      <Tabs tabsData={tabsData} theme="secondary" className="w-full" />
      <div className="overflow-auto">
        <table className="primary-table">
          <thead>
            <tr className="relative h-[50px] !p-4 shadow-[0_0_16px_0_#25313c0a]">
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Practitioner</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Services</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Balance</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Status</th>
              <th className="!border-0 !p-4 text-sm font-400 !text-primary-900">Date</th>
            </tr>
          </thead>
          <tbody>
            {loading.general || loading.table ? (
              <tr>
                <td colSpan={5} className="bg-white">
                  <Skeleton count={13} height={48} />
                </td>
              </tr>
            ) : (
              <Outlet
                context={{
                  practice,
                  appointments,
                  setShowModal,
                  selectAppointment,
                  setSelectedAppointment
                }}
              />
            )}
          </tbody>
        </table>

        {showAdvancedForm && (
          <Modal
            open={showAdvancedForm}
            onClose={() => {
              setShowAdvancedForm(false);
              setSelectedAdvancedForm(null);
            }}
            styling={{ width: '80vw', height: '80vh' }}>
            <ReactFormGenerator
              answer_data={
                selectedAdvancedForm.form && JSON.parse(selectedAdvancedForm.json.fields)
              }
              data={
                selectedAdvancedForm.form
                  ? selectedAdvancedForm.form.json.fields
                  : selectedAdvancedForm.json.fields
              }
              submitButton={
                <div className="block shrink grow basis-0 !p-3 text-center">
                  <Button text="Save" color="success" forwardedRef={submitAdvancedSubjective} />
                </div>
              }
              onSubmit={(e) =>
                handleFormSubmit(
                  e,
                  selectedAdvancedForm.form ? selectedAdvancedForm.id : null,
                  selectedAdvancedForm.form_id || selectedAdvancedForm.id,
                  selectedAdvancedForm.form_version || selectedAdvancedForm.version
                )
              }
            />
          </Modal>
        )}

        {selectedAppointmentId && (
          <PreviewAppointment
            appointment={{ id: selectedAppointmentId }}
            showPreviewAppointment={showModal}
            hidePreviewAppointment={hideModal}
            setAppointment={setSelectedAppointment}
            services={reShapeProcedures(data?.services)}
            setRefetchAppointments={() => getAppointments()}
            practitioners={allProviders?.practitionersOptions}
          />
        )}
      </div>

      <Pagination
        onPageChange={changePage}
        page={type == 'past' ? page?.past : page.upcoming}
        totalItems={type == 'past' ? pastCounter : upcomingCounter}
      />
    </>
  );
}
