import { Outlet, Navigate } from 'react-router-dom';

import React from 'react';
import { useRecoilValue } from 'recoil';
import state from '../components/state';
import practiceState from '../components/practice/practiceState';
import adminState from '../components/admin/lib/adminState';

const PrivateRoutes = ({ type = '' }) => {
  const userState = useRecoilValue(state.userState);
  const practice = useRecoilValue(practiceState.currentPractice);
  const admin_state = useRecoilValue(adminState.current_admin);

  if (type === 'empty') {
    return (
      <Navigate
        to={
          admin_state.logged_in
            ? '/admin'
            : practice.id
              ? '/portal'
              : userState.logged_in
                ? '/myportal'
                : '/login'
        }
      />
    );
  }

  if (type === 'admin') {
    return admin_state.logged_in ? <Outlet /> : <Navigate to="/admin/login" />;
  }

  if (!userState.logged_in) {
    return <Navigate to="/login" />;
  }

  if (type === 'portal') {
    return !!practice.id ? <Outlet /> : <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default PrivateRoutes;
