import React, { useState } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import md5 from 'md5';
import { showAlert } from 'components/shared/Alert/Alert';

const S3Uploader = ({ id, headers }) => {
  const [file_list, setFileList] = useState([]);
  const [upload_status, setUploadStatus] = useState({
    percentage: 0,
    message: 'Ready'
  });
  const onUploadStart = (file, next) => {
    next(file);
  };
  const onUploadProgress = (percentage, message) => {
    setUploadStatus({ percentage, message });
  };
  const onUploadError = () => {
    setUploadStatus({ percentage: 0, message: 'An error has occurred.' });
  };
  const onUploadFinish = (a, b) => {
    let new_file_list = [...file_list, { url: a.fileKey, name: b.name }];
    setFileList(new_file_list);
    setUploadStatus({ percentage: 0, message: 'Ready.' });
    showAlert({
      color: 'success',
      message: `File uploaded!`
    });
  };
  return (
    <div className="flex flex-col ">
      <span className="py-1 text-xl font-bold">Upload to S3</span>
      <ReactS3Uploader
        multiple
        evaporateOptions={{
          aws_key: process.env.REACT_APP_AWS_KEY,
          bucket: process.env.REACT_APP_PATIENT_IMPORT_DUMPS_BUCKET,
          computeContentMd5: true,
          cryptoHexEncodedHash256: async (data) => {
            return crypto.createHash('sha256').update(data).digest('hex');
          },
          cryptoMd5Method: (data) => {
            return md5(data);
          }
        }}
        signingUrl="/api/admin/practice/patients_import/patient_data/sign"
        signerUrl="/api/admin/practice/patients_import/patient_data/sign"
        signingUrlMethod="GET"
        accept="application/xml/"
        s3path={`patient_import_data/${id}/`}
        preprocess={onUploadStart}
        onProgress={onUploadProgress}
        onError={onUploadError}
        onFinish={onUploadFinish}
        signingUrlHeaders={{ additional: headers }}
        // signingUrlQueryParams={{ additional: query - params }}
        signingUrlWithCredentials={true} // in case when need to pass authentication credentials via CORS
        uploadRequestHeaders={{ 'x-amz-acl': 'private' }} // this is the default
        contentDisposition="auto"
        // scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/gi, '')}
        autoUpload={true}
      />
      <p>{`${upload_status.percentage}% - ${upload_status.message}`}</p>
    </div>
  );
};

export default S3Uploader;
