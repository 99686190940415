import React, { useEffect } from 'react';
import Modal from 'components/shared/Modal/Modal';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Input from 'components/shared/Input/Input';
import EditPermissions from './components/EditPermissions';
import Button from 'components/shared/Buttons/Button';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import Select from 'components/shared/Select/Select';
import { VERADIGM_USER_TYPES } from '../constants';
import AssignedUsers from './components/AssignedUsers';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { pm } from 'lib/helpers/utility';

const EditRoleModal = ({ showModal, hideModal, roleId, roleName, roleType, roleDescription }) => {
  const { enableSubmit, formik, handleClose } = useEditRoleContext();
  const permissions = useRecoilValue(state.permissions);
  const canEdit = pm(permissions, 'role.update');

  useEffect(() => {
    formik.setFieldValue('role_name', roleName);
    formik.setFieldValue('role_type', roleType);
    formik.setFieldValue('role_description', roleDescription);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onSelectChange = (role) => {
    {
      if (role !== null) {
        formik.setFieldValue('role_type', role.value);
      } else {
        formik.setFieldValue('role_type', null);
      }
    }
  };

  return (
    <Modal
      handleOpen={showModal}
      headerClassName="z-10"
      handleClose={hideModal}
      title={roleName}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" onClick={handleClose} color="neutral" />
          <Button disabled={!canEdit || !enableSubmit} onClick={handleSubmit} text="Save Changes" />
        </div>
      }>
      <>
        <ErrorBoundary>
          <div className="inline-flex h-[976px] w-[1176px] flex-col items-start justify-start gap-[21px] rounded-lg pb-9">
            <div className="inline-flex min-h-[395px] w-full flex-none flex-col items-start justify-start gap-4 px-6 py-4">
              <div className="inline-flex w-full items-start justify-start gap-5">
                <div className="w-[410px]">
                  <div className="text-sm font-medium leading-snug text-neutral-800">
                    Role Details
                  </div>
                  <p className="text-sm font-normal leading-snug text-neutral-700">
                    In this section, you will define and configure each role within your practice.
                    This includes providing essential details that help categorize and clarify the
                    responsibilities and permissions associated with each role.
                  </p>
                </div>
                <div className="flex flex-1 flex-col items-center justify-start gap-8">
                  <div className="flex w-full flex-col items-start justify-center gap-2">
                    <label className="text-sm font-medium leading-snug text-neutral-800">
                      Name
                    </label>
                    <div className="flex h-10 w-full items-center gap-3 rounded-md border border-neutral-200 bg-white">
                      <Input
                        id="role_name"
                        name="role_name"
                        className="w-full text-sm font-normal leading-snug text-primary-900"
                        labelClassName="p-0"
                        value={formik.values.role_name}
                        onChange={(e) => {
                          formik.setFieldValue('role_name', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-col items-start justify-center gap-2">
                    <div className="flex h-10 w-full items-center gap-3 rounded-md border border-neutral-200 bg-white">
                      <Select
                        name="role_type"
                        label="E-Prescribe User Type"
                        isClearable={true}
                        value={VERADIGM_USER_TYPES.find(
                          (role) => role.value === formik.values.role_type
                        )}
                        parentClassName="w-full"
                        id="serviceChargeTypeSelect"
                        placeholder="Pick type of role"
                        options={VERADIGM_USER_TYPES}
                        onChange={(role) => onSelectChange(role)}
                      />
                    </div>
                  </div>
                  <div className="flex w-full flex-col items-start justify-center gap-2">
                    <label className="text-sm font-medium leading-snug text-neutral-800">
                      Description
                    </label>
                    <div className="flex h-[67px] w-full items-start gap-3 rounded-md border border-neutral-200 bg-white">
                      <Input
                        id="role_description"
                        name="role_description"
                        placeholder="Enter description for the role"
                        className="w-full text-sm font-normal leading-snug text-primary-900"
                        inputWrapperClassName="!h-[67px] !align-top !block"
                        value={formik.values.role_description}
                        onChange={(e) => {
                          formik.setFieldValue('role_description', e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <AssignedUsers roleId={roleId} />
            </div>
            <div className="min-h-px w-full bg-neutral-100" />
            <EditPermissions id={roleId} roleId={roleId} />
          </div>
        </ErrorBoundary>
      </>
    </Modal>
  );
};

export default EditRoleModal;
