import React, { useRef } from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { activePromptConversationsState } from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import Icon from 'components/shared/Icon/Icon';

import useComponentAdd from '../../hooks/useComponentAdd';

import { ChatFooter } from './ChatFooter';
import ComponentSelectorModal from './ComponentSelectorModal';
import DescriptionSection from './DescriptionSection';
import NoteTypeButtons from './NoteTypeButtons';
import PromptMessages from './PromptMessages';

import '../../../../MyScribeAI.scss';

const Chat = () => {
  const breadcrumb = useBreadcrumb();
  const { componentToAdd, inputTags, onAddComponent, onTagRemove, setComponentToAdd } =
    useComponentAdd();
  const IsComponentInTags = inputTags?.find((tag) => tag?.id === componentToAdd?.id);
  const activePromptConversations = useRecoilValue(activePromptConversationsState);

  const scrollContainerRef = useRef(null);

  const onComponentAdd = () => {
    if (IsComponentInTags) {
      setComponentToAdd(null);
      return;
    }
    onAddComponent();
  };

  return (
    <div className="relative flex h-full w-full flex-col pb-10">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 right-0 z-10 bg-primary-10" />
      <NoteTypeButtons />
      <div
        className="relative z-20 flex-grow overflow-y-auto"
        ref={scrollContainerRef}
        style={{ scrollbarWidth: 'thin' }}>
        <div className="max-width-[760px] mx-auto">
          {activePromptConversations?.length > 0 ? (
            <PromptMessages scrollContainerRef={scrollContainerRef} />
          ) : (
            <DescriptionSection />
          )}
        </div>
      </div>
      <div
        className={cs(
          'relative bottom-28 z-50 w-full flex-none bg-white',
          activePromptConversations?.length > 0 && 'bottom-10'
        )}>
        <div
          className="absolute top-[-32px] h-[32px] w-full self-center bg-gradient-to-b from-white/70 to-white backdrop-blur-md"
          style={{ backdropFilter: 'blur(2px)' }}
        />
        <div className="z-50 mx-auto flex max-w-[760px] flex-col pt-2">
          <div
            className={cs(
              'mb-[25px] flex flex-col items-center px-4',
              !activePromptConversations?.length > 0 && 'pb-[10px] md:pb-[100px] lg:pb-[50px]'
            )}>
            <ChatFooter />
          </div>
        </div>
      </div>
      {componentToAdd && (
        <ComponentSelectorModal
          componentToAdd={componentToAdd}
          onClose={() => setComponentToAdd(null)}
          onTagRemove={onTagRemove}
          onAddComponent={onComponentAdd}
          breadcrumb={breadcrumb}
          IsComponentInTags={IsComponentInTags}
        />
      )}
    </div>
  );
};

export default Chat;
