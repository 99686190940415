import { FaxContextProvider } from 'lib/context/FaxContext/FaxContextProvider';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Fax = () => (
  <div className="flex h-full flex-col bg-white" data-dd-privacy="allow">
    <FaxContextProvider>
      <Outlet />
    </FaxContextProvider>
  </div>
);

export default Fax;
