import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Textarea from 'components/shared/Textarea/Textarea';
import { isEmpty, isEqual } from 'lodash';
import React from 'react';
import { doEmptyVitalExist, initialVitals, useVitalDataSetRefs } from '../lib/helpers';

const VitalForm = ({
  handleOnChange,
  vitals,
  vital,
  vitalId,
  index,
  lastVitals,
  handlePoundsAndOz,
  handleFeetAndInch,
  handleBMIChange,
  isGrowthChartEnabled,
  setConfirmDelete,
  pounds,
  ounces,
  feet,
  inches,
  lastPounds,
  lastOunces,
  lastFeet,
  lastInches,
  unitsOptions,
  currentUnit,
  handleUnitChange,
  length,
  handleEnhanceNarrative,
  isFetchingEnhanceNarrative,
  fieldToFocus
}) => {
  const refs = useVitalDataSetRefs();
  return (
    <>
      <div className="dashed-top w-full"></div>
      <div className="ml-auto flex items-center justify-between">
        <p className="ml-3 mt-3 text-right  text-neutral-600">Set {index + 1}</p>
        <div className=" mt-3 flex items-center">
          <p className="w-full p-1 text-right text-sm text-neutral-600">Vitals taken on:</p>
          <DatePopover
            placementY="right-1 top-12"
            isTime={true}
            className="w-[520px]"
            onChange={(date) => handleOnChange({ target: { value: date } }, 'timestamp', vitalId)}
            value={vital?.timestamp}
          />
        </div>
      </div>
      <div className="flex">
        <div className="grid w-full grid-cols-3">
          <div className="m-3 mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="mb-3 flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-heart-blue" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">Heart rate</span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="heart_rate"
                  id="heart_rate"
                  placeholder="Add heart rate"
                  rightText={<span className="text-sm font-500 text-primary-500">bpm</span>}
                  value={vital?.heart_rate}
                  onChange={(event) => handleOnChange(event, 'heart_rate', vitalId)}
                  forwardedRef={refs?.heart_rate}
                  focusOnMount={fieldToFocus === 'Heart Rate'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured heartbeats per minute:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.heart_rate) ? `${lastVitals?.heart_rate} bpm` : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3   mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-lungs" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">
                  Respiratory rate
                </span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="respiratory-rate"
                  id="respiratory_rate"
                  placeholder="Add respiratory rate"
                  rightText={<span className="text-sm font-500 text-primary-500">bpm</span>}
                  value={vital?.respirations}
                  onChange={(event) => handleOnChange(event, 'respirations', vitalId)}
                  forwardedRef={refs?.respirations}
                  focusOnMount={fieldToFocus === 'Respiratory Rate'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.respirations) ? `${lastVitals?.respirations} bpm` : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-blood-pressure-systolic" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">
                  Blood pressure (systolic)
                </span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="blood_pressure_systolic"
                  id="blood_pressure_systolic"
                  placeholder="Add blood pressure"
                  rightText={<span className="text-sm font-500 text-primary-500">mmHg</span>}
                  value={vital?.blood_pressure_systolic}
                  onChange={(event) => handleOnChange(event, 'blood_pressure_systolic', vitalId)}
                  forwardedRef={refs?.blood_pressure_systolic}
                  focusOnMount={fieldToFocus === 'Blood Pressure(Systolic)'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.blood_pressure_systolic)
                  ? `${lastVitals?.blood_pressure_systolic} mmHg`
                  : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-blood-pressure-diastolic" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">
                  Blood pressure (diastolic)
                </span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="blood_pressure_diastolic"
                  id="blood_pressure_diastolic"
                  placeholder="Add blood pressure"
                  rightText={<span className="text-sm font-500 text-primary-500">mmHg</span>}
                  value={vital?.blood_pressure_diastolic}
                  onChange={(event) => handleOnChange(event, 'blood_pressure_diastolic', vitalId)}
                  forwardedRef={refs?.blood_pressure_diastolic}
                  focusOnMount={fieldToFocus === 'Blood Pressure(Diastolic)'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.blood_pressure_diastolic)
                  ? `${lastVitals?.blood_pressure_diastolic} mmHg`
                  : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-weight" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">Weight</span>
                <div className="flex gap-1">
                  <Input
                    hideNumberArrows={true}
                    type="number"
                    data-qa="pounds"
                    id="pounds"
                    placeholder="Add pounds"
                    rightText={
                      <span data-qa="weight-lb" className="text-sm font-500 text-primary-500">
                        lb
                      </span>
                    }
                    name="pounds"
                    value={pounds}
                    onChange={(event) => handlePoundsAndOz(event)}
                    forwardedRef={refs?.weight}
                    focusOnMount={fieldToFocus === 'Weight'}
                  />
                  <Input
                    hideNumberArrows={true}
                    type="number"
                    data-qa="oz"
                    id="oz"
                    placeholder="Add oz"
                    rightText={
                      <span data-qa="weight-oz" className="text-sm font-500 text-primary-500">
                        oz
                      </span>
                    }
                    name="oz"
                    value={ounces}
                    onChange={(event) => handlePoundsAndOz(event)}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.weight) ? `${lastPounds} lb ${lastOunces} oz` : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-temperature" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">Temperature</span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="temperature"
                  id="temperature"
                  placeholder="Add temperature"
                  rightText={<span className="text-sm font-500 text-primary-500">°F</span>}
                  value={vital?.temperature}
                  onChange={(event) => handleOnChange(event, 'temperature', vitalId)}
                  forwardedRef={refs?.temperature}
                  focusOnMount={fieldToFocus === 'Body Temperature'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.temperature) ? `${lastVitals?.temperature} °F` : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3 mt-5  !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-height" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">Height</span>
                <div className="flex gap-1">
                  <Input
                    hideNumberArrows={true}
                    type="number"
                    data-qa="feet"
                    id="feet"
                    placeholder="Add feet"
                    rightText={<span className="text-sm font-500 text-primary-500">ft</span>}
                    name="feet"
                    value={feet}
                    onChange={handleFeetAndInch}
                    forwardedRef={refs?.height}
                    focusOnMount={fieldToFocus === 'Height'}
                  />
                  <Input
                    hideNumberArrows={true}
                    type="number"
                    data-qa="inches"
                    id="inches"
                    rightText={<span className="text-sm font-500 text-primary-500">in</span>}
                    placeholder="Add inches"
                    name="inches"
                    value={inches}
                    onChange={handleFeetAndInch}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.height) ? `${lastFeet}'${lastInches} ft` : 'N/A'}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-body" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">BMI</span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="bmi"
                  id="bmi"
                  placeholder="Add BMI"
                  rightText={
                    <span className="text-sm font-500 text-primary-500">
                      kgm<sup>2</sup>
                    </span>
                  }
                  name="bmi"
                  value={vital?.bmi}
                  onChange={(event) => handleBMIChange(event)}
                  forwardedRef={refs?.bmi}
                  focusOnMount={fieldToFocus === 'BMI'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured body mass index:</p>
              <p className="text-sm text-neutral-600">
                {lastVitals?.bmi !== 'null' || lastVitals?.bmi !== '' ? (
                  <span className="text-sm font-500">
                    {lastVitals?.bmi} kgm<sup>2</sup>
                  </span>
                ) : (
                  'N/A'
                )}
              </p>
            </div>
          </div>
          <div className="m-3  mt-5 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
            <div className="flex gap-x-4 gap-y-2">
              <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                <Icon icon="new-oxygen-level" />
              </div>
              <div className="grid w-full gap-y-2">
                <span className="text-sm font-500 leading-3 text-neutral-800">Oxygen level</span>
                <Input
                  hideNumberArrows={true}
                  type="number"
                  data-qa="oxygen_level"
                  id="oxygen_level"
                  placeholder="Add oxygen level"
                  rightText={<span className="text-sm font-500 text-primary-500">%</span>}
                  value={vital?.oxygen_level}
                  onChange={(event) => handleOnChange(event, 'oxygen_level', vitalId)}
                  forwardedRef={refs?.oxygen_level}
                  focusOnMount={fieldToFocus === 'Oxygen Level'}
                />
              </div>
            </div>
            <div className="flex justify-between gap-x-2 !pt-2">
              <p className="text-sm text-neutral-600">Last measured:</p>
              <p className="text-sm text-neutral-600">
                {!isEmpty(lastVitals?.oxygen_level) ? `${lastVitals?.oxygen_level} %` : 'N/A'}
              </p>
            </div>
          </div>
          {isGrowthChartEnabled && (
            <div className=" m-3 !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
              <div className="flex gap-x-4 gap-y-2">
                <div className="flex h-[60px] w-[60px] shrink-0 items-center justify-center !rounded-lg border !border-primary-200 bg-primary-50">
                  <Icon icon="new-ruler" />
                </div>
                <div className="grid w-full gap-y-2">
                  <span className="text-sm font-500 leading-3 text-neutral-800">
                    Head Circumference
                  </span>
                  <Input
                    hideNumberArrows={true}
                    type="number"
                    data-qa="head_circumference"
                    id="head_circumference"
                    placeholder="Add head circumference"
                    name="head_circumference"
                    value={vital?.head_circumference}
                    onChange={(event) => handleOnChange(event, 'head_circumference', vitalId)}
                    currentUnit={currentUnit}
                    units={unitsOptions}
                    onUnitChange={handleUnitChange}
                    forwardedRef={refs?.head_circumference}
                    focusOnMount={fieldToFocus === 'Head Circumference'}
                  />
                </div>
              </div>
              <div className="flex justify-between gap-x-2 !pt-2">
                <p className="text-sm text-neutral-600">Last measured head circumference:</p>
                <p className="text-sm text-neutral-600">
                  {!isEmpty(lastVitals?.head_circumference)
                    ? `${vital?.head_circumference} ${vital?.head_circumference_unit}`
                    : 'N/A'}
                </p>
              </div>
            </div>
          )}
        </div>
        {(length > 1 ||
          !doEmptyVitalExist({ newVital: initialVitals()?.vitals[0], vitals: vitals?.vitals })) && (
          <Icon
            icon="trash"
            onClick={() => {
              setConfirmDelete(vitalId);
            }}
          />
        )}
      </div>
      <div className="mt-5  w-full !rounded-lg border !border-dashed !border-primary-200 bg-[#f1fbff] !p-6">
        <Textarea
          data-qa="general_notes"
          label="Notes"
          transcribing
          placeholder="Type general notes here"
          value={vital?.notes}
          onChange={(event) => {
            handleOnChange(event, 'notes', vitalId);
          }}
          options={[
            {
              title: 'Enhance with MyScribe AI',
              icon: 'new-myscribe-stars',
              onClick: () => handleEnhanceNarrative(),
              loading: isFetchingEnhanceNarrative
              // permission: {requiredPermissions: 'myscribe.create'}
            }
          ]}
        />
      </div>
    </>
  );
};
export default VitalForm;
