/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { saveDocumentHistory } from 'api/DocumentHistory';

import { useDocumentHistory } from 'lib/hooks/queries/useDocumentHistory';

import Button from 'components/shared/Buttons/Button';
import DocumentEditor from 'components/shared/DocumentEditor/DocumentEditor';
import Modal from 'components/shared/Modal/Modal';

import useEditorRenderer from '../lib/useEditorRenderer';

import DocumentHistory from './DocumentHistory';

const EditorModal = ({
  src,
  id,
  type = 'pdf',
  kind = 'patient_documents',
  loadDocument,
  title,
  previewedFax,
  editModal,
  setScale,
  historyMode,
  setIsLoading,
  canvasStates,
  scale,
  setEditModal,
  setHistoryMode,
  setCanvasStates,
  isLoading
}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);

  const [saveLoading, setSaveLoading] = useState(false);

  const [isPdfLoaded, setIsPdfLoaded] = useState(false);

  const queryClient = useQueryClient();
  const fabricCanvasRef = useRef(null);

  const { myPortalPractice } = useParams();

  const [currentVersion, setCurrentVersion] = useState(0);

  const pdf = useRef();

  const { renderImage } = useEditorRenderer({
    fabricCanvasRef,
    setScale,
    currentPage,
    canvasStates,
    setIsPdfLoaded
  });

  const { data, isLoading: isHistoryLoading } =
    useDocumentHistory({
      params: { id, kind, myPortalPractice },
      dependencies: [id, kind, myPortalPractice]
    }) || {};

  useEffect(() => {
    const changeVersion = async () => {
      setIsLoading(true);
      if (currentVersion >= 1) {
        const record = data?.records?.find((record) => record.version === currentVersion);
        await loadDocument({ record });
        const fabricCanvas = fabricCanvasRef.current;
        fabricCanvas?.renderAll.bind(record.changes[currentPage]);
        type === 'image' && (await renderImage(src));
      } else {
        await loadDocument();
      }
      setIsLoading(false);
    };

    changeVersion();
  }, [currentVersion]);

  useEffect(() => {
    if (data) {
      setCurrentVersion(
        data?.records?.find((record) => record.selected === true)?.version ||
          data?.records?.[0]?.version ||
          0
      );
    }
  }, [data]);

  const saveRecord = async () => {
    setSaveLoading(true);
    const fabricCanvas = fabricCanvasRef.current;
    const canvasJson = fabricCanvas.toJSON();

    await saveDocumentHistory(navigate, {
      id,
      kind,
      changes: {
        ...canvasStates,
        [currentPage]: canvasJson,
        scale
      },
      myPortalPractice
    });

    queryClient.invalidateQueries(['document-history']);
    loadDocument();
    setCurrentPage(1);
    setEditModal(null);
    setSaveLoading(false);
  };

  return (
    <Modal
      slideFromRight
      className="flex flex-col max-w-[1080px] bg-white"
      bodyClassName="p-0 overflow-hidden flex flex-col h-full"
      footerClassName="mt-auto h-max z-10"
      isLarge
      isFooter={false}
      handleOpen={!!editModal}
      customHeader={
        <div className="mr-3 flex h-full !w-[100%] items-center justify-between">
          <h3 className="whitespace-nowrap text-lg max-w-[600px] truncate">{`${historyMode ? 'Document Versions' : 'Markup'} - ${title}`}</h3>
          <div className="flex gap-2">
            {!historyMode && (
              <>
                <Button icon="new-history" iconIsStroke onClick={() => setHistoryMode(true)} />
                <Button
                  text="Save Changes"
                  color="primary"
                  size="small"
                  loading={saveLoading}
                  onClick={saveRecord}
                  data-qa="save-btn"
                />
              </>
            )}
          </div>
        </div>
      }
      handleClose={() => {
        if (historyMode) {
          if (editModal === 'history') setEditModal(null);
          return setHistoryMode(false);
        }
        setEditModal(null);
        setCurrentPage(1);
      }}
      shouldCloseOnEsc={false}>
      <div className="flex h-full w-full overflow-hidden ">
        <DocumentHistory
          {...{
            id,
            kind,
            currentVersion,
            setCurrentVersion,
            data,
            historyMode,
            loading: isHistoryLoading
          }}
        />

        <DocumentEditor
          canvasStates={canvasStates}
          setCanvasStates={setCanvasStates}
          src={src || previewedFax}
          fabricCanvasRef={fabricCanvasRef}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          scale={scale}
          currentVersion={currentVersion}
          fileType={type === 'fax' ? 'pdf' : type}
          setScale={setScale}
          historyMode={historyMode}
          isPdfLoaded={isPdfLoaded}
          setIsPdfLoaded={setIsPdfLoaded}
          pdf={pdf}
          loadingVersion={isLoading}
        />
      </div>
    </Modal>
  );
};

export default EditorModal;
