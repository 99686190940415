import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';
import { getListStyle } from '../../lib/renderedDragAndDropHelper';
import List from './List';

const DragDropContainer = ({
  onDragEnd,
  onDragEndChildren,
  loading,
  toggleExpand,
  handleClick,
  handleClickDropdown
}) => {
  const { selected } = useClinicalNoteContext() || {};

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            {io(selected) &&
              Object.entries(selected).map(([key, item], index) => {
                if (Object.keys(item).length < 2) return null;
                return loading.forms ? (
                  <Skeleton height="40px" count={1} background="#edeff0" highlight="#f8f8f8" />
                ) : (
                  <List
                    key={item.id}
                    item={item}
                    index={index}
                    loading={loading.forms}
                    toggleExpand={toggleExpand}
                    handleClick={handleClick}
                    handleClickDropdown={handleClickDropdown}
                    keyParent={key}
                    onDragEndChildren={onDragEndChildren}
                  />
                );
              })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropContainer;
