import React from 'react';

const fields = {
  from: ['from_name', 'from_phone', 'from_email', 'from_address'],
  to: ['to_name', 'to_phone', 'to_email', 'to_address']
};

const renderFields = ({ prefix = 'form', fieldValues }) => {
  return fields[prefix].map((field, index) => {
    if (!fieldValues?.[field]) return null;

    return (
      <p
        key={index}
        className="font-400 text-neutral-800 text-sm first-of-type:!mt-2.5 mt-1.5"
        style={{ wordBreak: 'break-word' }}>
        {fieldValues?.[field]}
      </p>
    );
  });
};

const ContactPreview = ({ fromProvider = false, itemProps }) => {
  return (
    <div className="flex flex-col w-full">
      <div className="text-neutral-800 text-base font-500">{fromProvider ? 'From' : 'To'}</div>
      <div className="flex flex-col">
        {renderFields({ prefix: fromProvider ? 'from' : 'to', fieldValues: itemProps })}
      </div>
    </div>
  );
};

export default ContactPreview;
