import React, { useCallback, useEffect, useState } from 'react';

import { cloneDeep } from 'lodash';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { ia } from 'lib/helpers/utility';

import NotFound from 'components/practice/Dashboard/components/NotFound';
import UpsertVitals from 'components/practice/charts/ClinicalNote/NewVitals/components/UpsertVitals';
import VitalsPreview from 'components/practice/charts/ClinicalNote/NewVitals/components/VitalsPreview';
import { onNotFoundCreateClick } from 'components/practice/charts/ClinicalNote/NewVitals/components/lib/helper';
import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import ModalFooter from '../../../../../../../../shared/Modal/ModalFooter/ModalFooter';

import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from './configs';

export function Table({ breadcrumb, previewData, setPreviewData }) {
  const { setSteps, setStep, steps, step } = breadcrumb;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows, onRemoveComponent } =
    useChatContextApi();
  const selectedTableNodes = selectedTableRows?.['vitals']?.selectedNodes;

  const [selectedNodes, setSelectedNodes] = useState([]);
  const [showVitalsUpsertModal, setShowVitalsUpsertModal] = useState(false);
  const selectedRowsData = selectedNodes.map((node) => node.data);

  const { selectedRows } = useTableContext();
  const { currentHpOverviewData } = useClinicalNoteContext();
  const vitals = currentHpOverviewData?.vitals?.vitals;
  const [vital, setVital] = useState({});

  const { gridApi } = useTableContext();

  useEffect(() => {
    setSteps([
      {
        title: 'Vitals',
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      setSelectedNodes(selectedTableNodes);
      selectedTableNodes?.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    } else if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        setSelectedNodes(selectedRows);
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  const deletePatientVitalsSelectedTableRows = useCallback(() => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['vitals'];
    setSelectedTableRows(newSelectedTableRows);
  }, [selectedTableRows, setSelectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;

    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  }, [gridApi]);

  const onDeleteVitalsTag = () => {
    deletePatientVitalsSelectedTableRows();
    onRemoveComponent();
    setComponentToAdd(null);
  };

  const onAddTag = () => {
    if (!selectedNodes?.length) {
      deletePatientVitalsSelectedTableRows();
      onRemoveComponent();
    }

    setSelectedTableRows((prev) => ({
      ...prev,
      vitals: {
        selectedNodes,
        data: selectedNodes.map((node) => node.data),
        status: !selectedNodes?.length
          ? 'none'
          : selectedNodes?.length === vitals?.length
            ? 'all'
            : 'partial'
      }
    }));
    onAddComponent();
  };

  let body = (
    <AGTable
      onSelectionChanged={onSelectionChanged}
      defaultColDef={DEFAULT_COLUMN_DEFS}
      columnDef={getColDefs(breadcrumb, practiceTimezone, setPreviewData)}
      gridOptions={GRID_OPTIONS}
      animateRows={true}
      data={vitals}
      rowSelection="multiple"
      suppressRowClickSelection={true}
      isRowSelectable={() => true}
      customClassName="ag-grid-interactive"
      reactiveCustomComponents
      loadingOverlayComponent={() => (
        <div className="h-full w-[1160px] min-w-[480px]">
          <Skeleton count={24} />
        </div>
      )}
    />
  );

  if (!ia(vitals)) {
    return (
      <>
        <NotFound
          title="No vitals added yet"
          description="You can start adding vitals right now."
          icon="new-no-cpt-codes-illustration"
          className="h-96 !p-0"
          createText="Add Vitals"
          onCreate={() => onNotFoundCreateClick({ setShowVitalsUpsertModal, setVital })}
        />
        {showVitalsUpsertModal && (
          <UpsertVitals
            vital={vital}
            // disabled={clinicalNote?.locked}
            showModal={showVitalsUpsertModal}
            hideModal={() => setShowVitalsUpsertModal(false)}
          />
        )}
      </>
    );
  }

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  if (steps[step]?.name === 'create') {
    const vital = previewData;

    body = (
      <UpsertVitals
        withModal={false}
        vital={vital}
        // disabled={clinicalNote?.locked}
        showModal={true}
        hideModal={onGoBack}
      />
    );
  }

  if (steps[step]?.name === 'preview') {
    const vital = previewData;

    body = (
      <div className="pb-[112px]">
        <VitalsPreview
          withActions={false}
          vital={vital}
          setVital={() => {}}
          setShowVitalsUpsertModal={setShowVitalsUpsertModal}
        />
      </div>
    );
  }

  const onBackClick = () => {
    steps[step]?.name === 'preview' ? onGoBack() : setComponentToAdd(null);
  };

  return (
    <div className="ag-theme-quartz forms_table !mb-0 h-full">
      {body}
      {vitals?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon key={1} icon="trash" className="cursor-pointer" onClick={onDeleteVitalsTag} />
            ),
            <Button
              key={2}
              text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Vitals`}
              disabled={!selectedRowsData?.length && !selectedTableNodes?.length}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!ml-4 !py-2 !pl-4 !pr-4"
              onClick={onAddTag}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onBackClick}
            />
          }
        />
      )}
    </div>
  );
}
