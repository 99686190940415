const { stripHtmlTags, ia, isEmpty } = require('lib/helpers/utility');

exports.createMedicationNarrative = (meds) => {
  if (!ia(meds)) return null;

  let narrative = '<p>The patient has been prescribed the following medications:</p>';

  meds?.forEach((med, index) => {
    narrative += `<p>${index + 1}. ${med.medication}`;

    if (med.strength && med.unit) {
      narrative += ` ${med.strength} (${med.unit}).`;
    } else if (med.strength) {
      narrative += ` ${med.strength}.`;
    } else {
      narrative += '.';
    }

    if (med.days_supply) {
      narrative += ` The supply is for ${med.days_supply} day(s).`;
    }

    if (med.quantity) {
      narrative += ` The prescribed quantity is ${med.quantity}.`;
    }

    if (med.refills) {
      narrative += ` The prescription includes ${med.refills} refill(s).`;
    }

    if (med.condition && med.condition.value) {
      narrative += ` This medication is for ${med.condition.value}.`;
    }

    if (med.prescriber && med.prescriber.value) {
      narrative += ` Prescribed by ${med.prescriber.value}.`;
    }

    if (med.start_date) {
      narrative += ` The medication was started on ${med.start_date}.`;
    }

    if (med.end_date && med.end_date !== med.start_date) {
      narrative += ` It is scheduled to end on ${med.end_date}.`;
    }

    narrative += '</p>'; // Close the paragraph after all the sentence-type data

    if (med.dosage_form) {
      narrative += `<p>Dosage form: ${med.dosage_form}.</p>`;
    }

    const route = extractText(med?.route);
    if (route) {
      narrative += `<p>Route of administration: ${route}.</p>`;
    }
    if (med.location) {
      narrative += `<p>Location: ${med.location}.</p>`;
    }
    if (med.weight) {
      narrative += `<p>Weight: ${med.weight} lbs.</p>`;
    }

    if (med.product_ndc) {
      narrative += `<p>Product NDC: ${med.product_ndc}.</p>`;
    }

    // Append SIG if available
    if (!isEmpty(stripHtmlTags(med.sig))) {
      narrative += `<p>SIG: ${med.sig}</p>`;
    }

    // Append Pharmacist Instructions if available
    if (!isEmpty(stripHtmlTags(med.pharmacist_instructions))) {
      narrative += `<p>Pharmacist instructions: ${med.pharmacist_instructions}.</p>`;
    }

    // Append Note if available
    if (!isEmpty(stripHtmlTags(med?.note))) {
      narrative += `<p>Note: ${med.note}</p>`;
    }

    narrative += '<br>'; // Between each medication
  });

  return narrative?.trim();
};

const extractText = (input) => {
  if (!input || typeof input !== 'string') return null;

  const cleaned = input.replace(/[{}]/g, '');

  return cleaned
    ?.split(',')
    ?.map((item) => item?.trim()?.replace(/"/g, ''))
    ?.join(', ');
};
