import React from 'react';
import { Outlet } from 'react-router-dom';
import PatientNavbar from '../navbars/PatientNavbar/PatientNavbar';

const PatientPortal = () => (
  <div className="flex h-screen flex-col bg-white">
    <PatientNavbar />
    <div className="h-full overflow-scroll">
      <Outlet />
    </div>
  </div>
);

export default PatientPortal;
