import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useOutletContext } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import TimeLineElement from './Logs/Component/TimeLineElement';
import { formatDate, ia } from '../../../../lib/helpers/utility';
import { useRecoilState } from 'recoil';
import { communicationFilter } from '../../practiceState';
import Spinner from '../../../shared/spinner/spinner';
import Icon from '../../../shared/Icon/Icon';
import './Logs/Style/logs.scss';
import '!style-loader!css-loader!react-vertical-timeline-component/style.min.css';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';
import cs from 'classnames';

export default function Logs() {
  const {
    practice,
    groupedLogs,
    loading,
    patient: { id }
  } = useOutletContext();
  const [communicationFilters, setCommunicationFilters] = useRecoilState(communicationFilter);
  const [canScrollToEnd, setCanScrollToEnd] = useState(true);

  const [scrollPosition, setScrollPosition] = useState(0);
  const timelineRef = useRef(null);
  const relativeRef = useRef(null);
  const scrollableDivRef = useRef(null);
  const { device } = useUIContext();

  const [currentHeight, setCurrentHeight] = useState(0);

  useEffect(() => {
    scrollToLastMessage();
  }, [timelineRef.current]);

  useEffect(() => {
    const container = scrollableDivRef.current;
    container.addEventListener('scroll', onScrollToTop);
    return () => {
      container.removeEventListener('scroll', onScrollToTop);
    };
  }, []);

  const scrollToLastMessage = () => {
    if (ia(groupedLogs) && timelineRef.current && canScrollToEnd) {
      setTimeout(() => {
        timelineRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        setCanScrollToEnd(false);
      }, 500);
    }
  };

  const onScrollToTop = async () => {
    const container = scrollableDivRef.current;

    if (container.scrollTop === 0) {
      setCommunicationFilters((prevState) => ({
        ...prevState,
        logApply: !prevState.logApply,
        messageApply: !prevState.messageApply,
        scroll: true
      }));

      setCurrentHeight(relativeRef.current.offsetHeight);
    }
  };

  useEffect(() => {
    const logsWrapperHeight = relativeRef.current.offsetHeight;

    scrollableDivRef.current.scrollTo(0, logsWrapperHeight - currentHeight);
  }, [groupedLogs]);

  return (
    <div className={cs('logs', device !== 'desktop' && 'h-[350px]')} ref={scrollableDivRef}>
      <div className="relative min-h-[350px]" ref={relativeRef}>
        {loading.general && (
          <div className="absolute left-1/2 top-8 z-10 -translate-x-1/2">
            <Spinner />
          </div>
        )}
        {ia(groupedLogs) && (
          <>
            <VerticalTimeline
              lineColor="#d9dbe0"
              layout="1-column-left"
              className="bg-white"
              animate={false}>
              {groupedLogs?.map(({ date, logs }, groupIndex) => {
                return (
                  <div className="logs__box" key={groupIndex}>
                    <VerticalTimelineElement
                      className="vertical-timeline-element--work !mb-7"
                      dateClassName="DateStyling"
                      iconClassName="CustomIcon shadow-none"
                      icon={
                        <div className="relative !top-5 bg-white !pt-4">
                          <p className="absolute bottom-5 left-[-20px] !mb-[6px] w-max bg-white text-base text-primary-500">
                            {formatDate(date, practice.timezone)}
                          </p>
                          <Icon icon={'new-dot'} className="!ml-[9px]" />
                        </div>
                      }
                    />
                    {ia(logs) &&
                      logs.map((item, index) => {
                        return (
                          <div key={index} ref={logs.length - 1 === index ? timelineRef : null}>
                            <TimeLineElement
                              index={index}
                              item={item}
                              timezone={practice.timezone}
                              patientId={id}
                            />
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </VerticalTimeline>
          </>
        )}
      </div>
    </div>
  );
}
