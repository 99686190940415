import React from 'react';
import cs from 'classnames';

const TH = ({ align = 'center', label, className }) => {
  const justifyClasses =
    align === 'end' ? 'justify-end' : align === 'start' ? 'justify-start' : 'justify-center';

  return (
    label && (
      <th className="!px-0 !py-2 align-middle">
        <div className={`flex ${justifyClasses}`}>
          <p
            className={cs(
              'bg-primary-50 w-fit text-xxs font-500 text-primary-900 !px-[6px] !py-[3px] rounded-[24px] flex items-center !h-[18px]',
              className
            )}>
            {label}
          </p>
        </div>
      </th>
    )
  );
};

export default TH;
