import React from 'react';
import cs from 'classnames';
import Icon from '../../shared/Icon/Icon';

const Notification = ({ notifications, onNavigate, close }) => {
  return (
    <div>
      {notifications.map((notification, idx) => {
        return (
          <div
            key={idx}
            onClick={() => onNavigate(notification.type, close)}
            className={cs(
              '!mx-1 !mt-2 flex cursor-pointer items-start gap-[14px] rounded-xl bg-primary-50 !p-3 !px-4 first:!mt-0 hover:bg-primary-100'
            )}>
            <div className="flex aspect-square h-10 items-center justify-center rounded-full border !border-primary-100 bg-white">
              <Icon color="primary" icon="new-notifications" size={36} />
            </div>
            <div className="grid w-full items-center gap-[2px]">
              <div className="flex justify-between">
                <div className="flex items-center gap-1">
                  <p className="max-w-[110px] overflow-hidden text-ellipsis whitespace-nowrap text-sm font-600 leading-5 text-primary-900">
                    {`${notification?.title}`}
                  </p>
                </div>
              </div>
              <p className="text-xs text-neutral-600">{notification?.body}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Notification;
