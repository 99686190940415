import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useOutletContext } from 'react-router-dom';

import { useRecoilState, useRecoilValue } from 'recoil';

import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import {
  clinicalNote as clinicalNoteState,
  permissions,
  userState as user_state
} from 'components/state';

const MyScribeAI = () => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [userState] = useRecoilState(user_state);
  const [openSidebar, setOpenSidebar] = useState(
    userState?.display_settings?.clinicalNote?.toggleRSidebar?.enabled
  );
  const { is_admin } = useRecoilValue(permissions);
  const navigate = useNavigate();
  const { breadcrumb, favoriteTemplateId } = useMyScribeAIContext();
  const [pathname, setPathname] = useState();
  const { pushBreadcrumb } = breadcrumb;
  const { patient } = useOutletContext();

  useEffect(() => {
    if (!clinicalNote?.user_id || !clinicalNote?.appointment_id) return;
    setPathname(
      `/portal/charts/${clinicalNote.user_id}/clinical-notes/${
        clinicalNote.appointment_id
      }/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`
    );
  }, [clinicalNote.appointment_id, clinicalNote.user_id, favoriteTemplateId]);

  useEffect(() => {
    if (!pathname) return;

    pushBreadcrumb({
      label: 'MyScribe AI',
      icon: <Icon icon="stars-ai" />,
      name: 'myscribe-ai',
      path: favoriteTemplateId ? pathname.replace(`/${favoriteTemplateId}`, '') : pathname
    });
  }, [pathname]);

  if (!is_admin) {
    navigate(-1);
  }

  return (
    <Outlet
      context={{
        patient,
        openSidebar,
        setOpenSidebar
      }}
    />
  );
};

export default withErrorBoundary(MyScribeAI);
