import React from 'react';
import { useNavigate } from 'react-router-dom';

import { deletePayer } from 'api/Payer';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useModal from 'lib/hooks/useModal';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Popover from 'components/shared/Popovers/Popover/Popover';

import PayerModal from './PayerModal';

const Actions = ({ data }) => {
  const navigate = useNavigate();

  const { isOpen, openModal, closeModal } = useModal();

  const { mutate: handleDeletePayer } = useCustomMutation({
    mutationFn: ({ payerId }) => deletePayer(navigate, { payerId }),
    successMessage: 'Payer deleted successfully!',
    invalidateQueryKey: ['getPayers', 'payers']
  });

  const options = [
    {
      label: 'Edit',
      icon: 'new-edit',
      onClick: openModal
    },
    {
      label: 'Delete',
      icon: 'trash',
      onClick: () => handleDeletePayer({ payerId: data?.id })
    }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {isOpen && <PayerModal payer={data} isOpen={isOpen} handleClose={closeModal} />}
    </>
  );
};

export default withErrorBoundary(Actions);
