import { requestApi } from './Api';

export const createFormPacket = async (navigate, params) =>
  await requestApi({
    navigate,
    params,
    url: '/api/practice/settings/forms/form_packet/upsert'
  });

export const updateFormPacket = async (navigate, params) =>
  await requestApi({
    navigate,
    params,
    url: '/api/practice/settings/forms/form_packet/update'
  });
