import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useReadPatientBalance } from 'lib/hooks/queries/billing/usePatientBalance';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getColDefs,
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS
} from '../configs/unallocatedConfigs';
import { useRecoilValue } from 'recoil';
import { currentPractice } from 'components/practice/practiceState';

const NewUnAllocatedTable = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  return (
    <TableContextProvider
      name="unallocated_transactions"
      cols={getColDefs(practiceTimezone)}
      pagination
      defaultFilters={DEFAULT_FILTERS}>
      <Table />
    </TableContextProvider>
  );
};

function Table() {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;
  const { limit, page, sort } = useTableContext();

  const { id: patientId } = useParams();

  const {
    data,
    isLoading: isGetInvoicesLoading,
    isFetching
  } = useReadPatientBalance({
    params: {
      limit,
      page,
      sort,
      patientId,
      unpaid: false
    },
    dependencies: [limit, page, sort, patientId],
    name: 'unallocated'
  });

  let transactions = data?.unallocated || [];

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      {isGetInvoicesLoading || isFetching ? (
        <Skeleton count={limit} />
      ) : (
        <div className="ag-theme-quartz !mb-0 mt-3 h-[calc(100vh-185px)]">
          <AGTable
            defaultColDef={DEFAULT_COLUMN_DEFS}
            columnDef={getColDefs(practiceTimezone)}
            gridOptions={GRID_OPTIONS}
            animateRows={true}
            data={transactions}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            isRowSelectable={() => true}
            customClassName="ag-grid-interactive"
            reactiveCustomComponents
          />
        </div>
      )}
    </ErrorBoundary>
  );
}

export default NewUnAllocatedTable;
