const isSameItem = (item, newItem) =>
  item.id === newItem.id &&
  item.packageId === newItem.packageId &&
  item.invoiceId === newItem.invoiceId;

export default function addNewItems({ newItem, newItems, setNewItems }) {
  if (newItems.some((item) => isSameItem(item, newItem))) {
    const filtered = newItems.filter((item) => !isSameItem(item, newItem));

    setNewItems(filtered);
  } else {
    setNewItems((prev) => [...prev, { ...newItem, count: 1 }]);
  }
}
