import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import _, { isArray } from 'lodash';

import { deleteCard } from 'api/Cards';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useCards } from 'lib/hooks/queries/cards/useCards';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popup from 'components/shared/Popup/Popup';

export default function CConFile() {
  const [selectedCard, setSelectedCard] = useState(null);
  const [deleteCardModal, setDeleteCardModal] = useState(null);
  const Actions = ({ data }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`flex h-[34px] w-[34px] items-center justify-center rounded 6px ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <div
                data-qa="delete-card-btn"
                className="flex items-center gap-1 !p-2 transition-all hover:bg-primary-50 !px-4 cursor-pointer"
                onClick={() => {
                  close();
                  setSelectedCard(data);
                  setDeleteCardModal(true);
                }}>
                <Icon icon="trash" className="cursor-pointer" color="primary" />
                <div className="ml-1 text-sm text-primary-900">Delete</div>
              </div>
            </div>
          )}
        </Popup>
      </div>
    );
  };

  const columns = [
    {
      field: 'pan',
      headerName: 'Card Number',
      minWidth: 300,
      cellRenderer: (params) => {
        return (
          <div>
            **** **** ****{' '}
            <p className="inline text-primary-900">{params.data.pan?.substring(12, 16)}</p>
          </div>
        );
      }
    },
    {
      field: 'expiration',
      headerName: 'Expiration',
      maxWidth: 250,
      cellRenderer: (params) => {
        return (
          <div>
            <p className="inline text-primary-900">
              {' '}
              ({params.data.expiration?.substring(0, 2)}/{params.data.expiration?.substring(2, 4)})
            </p>
          </div>
        );
      }
    },
    {
      field: 'billing_address',
      headerName: 'Billing Address',
      valueGetter: (params) => {
        return params?.data?.billingAddress?.fullAddress;
      }
    },
    {
      field: 'created_at',
      headerName: 'Added On',
      valueFormatter: ({ value }) => formatDate(value)
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: (params) => <Actions index={params.rowIndex} data={params.data} />,
      maxWidth: 100,
      minWidth: 36,
      resizable: false
    }
  ];

  return (
    <TableContextProvider cols={columns} name="cards">
      <Table
        selectedCard={selectedCard}
        deleteCardModal={deleteCardModal}
        setDeleteCardModal={setDeleteCardModal}
      />
    </TableContextProvider>
  );
}

function Table({ selectedCard, deleteCardModal, setDeleteCardModal }) {
  usePageTitle('Cards');
  const { id } = useParams();
  const { setTableLoading } = useTableContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    data = {},
    isLoading,
    isFetching
  } = useCards({
    params: { patientId: id, withCount: true },
    dependencies: [id]
  });
  const cards = isArray(data.cards) ? data.cards : [];

  const mutateDelete = useMutation({
    mutationFn: () => deleteCard(navigate, { cardId: selectedCard.id, patientId: id }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['cards']);
          setDeleteCardModal(null);
          showAlert({ title: 'Card deleted successfully', color: 'success' });
          break;
        default:
          showAlert({ title: 'Failed to delete card', color: 'danger' });
          break;
      }
    }
  });
  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex flex-col h-full">
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <Header title="Cards">
          <div className="flex gap-2 items-center">
            <DisplayButton />
          </div>
        </Header>
        <div className="!p-3 !pb-2 flex  flex-col h-full">
          <div className="ag-theme-quartz h-full !mb-0">
            <AGTable data={cards} defaultColDef={defaultColDef} />
          </div>
          <div className="flex justify-between !py-2 !px-5 rounded-b-lg bg-white items-center">
            {data?.totalCards > 0 && (
              <p className="text-primary-900 font-light">Showing 0 - {data?.totalCards} cards</p>
            )}
          </div>
          {!!deleteCardModal && (
            <Confirm
              variant="danger"
              primaryBtnTxt="Delete"
              title="Delete Card"
              icon="new-document-remove-red"
              message="Are you sure you want to delete this card?"
              handleContinue={() => {
                mutateDelete.mutate();
                setDeleteCardModal(false);
              }}
              handleOpen={!!deleteCardModal}
              handleClose={() => setDeleteCardModal(false)}
            />
          )}
        </div>
      </ErrorBoundary>
    </div>
  );
}
