import React from 'react';

import useFaxActions from 'components/practice/comms_center/fax/lib/useFaxActions';
import Popover from 'components/shared/Popovers/Popover/Popover';

import SelectInstantAppointment from '../InstantPacket/components/SelectInstantAppointment';

const FaxDocsActions = ({
  fax,
  handleConfirmationFile,
  setSelectedAppointment,
  data,
  patientId,
  practice,
  fromPatient,
  setViewDocument,
  setDownloadLoading
}) => {
  const { handleFaxDownload } = useFaxActions({ fax });

  const handleDownload = async () => {
    setDownloadLoading(true);
    await handleFaxDownload();
    setDownloadLoading(false);
  };

  const options = [
    {
      label: fromPatient ? 'View' : 'View & Markup',
      onClick: () =>
        setViewDocument({
          title: 'Fax #' + fax.id,
          type: 'fax',
          ...fax
        }),
      icon: 'new-eye'
    },
    {
      label: 'Download',
      onClick: handleDownload,
      icon: 'new-chevron-down'
    },

    !fromPatient
      ? fax?.appointment_id
        ? {
            label: 'Linked DOS',
            icon: 'new-link',
            color: 'primary',
            stroke: true,
            children: [
              {
                component: (
                  <SelectInstantAppointment
                    selectedAppointment={fax?.appointment_id}
                    setSelectedAppointment={async (appointment) => {
                      await setSelectedAppointment(appointment, fax);
                    }}
                    patientId={patientId}
                    practice={practice}
                    update
                    buttonClassName="hover:bg-primary-50 w-36"
                    popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-30%]"
                  />
                )
              },
              {
                label: 'Unlink DOS',
                icon: 'new-close-v2',
                color: 'primary',
                stroke: true,
                onClick: async () => {
                  await setSelectedAppointment(null, fax);
                }
              }
            ]
          }
        : {
            component: (
              <SelectInstantAppointment
                selectedAppointment={fax?.appointment_id}
                setSelectedAppointment={async (appointment) => {
                  await setSelectedAppointment(appointment, fax);
                }}
                patientId={patientId}
                practice={practice}
                popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-90%]"
              />
            )
          }
      : null,
    !fromPatient &&
      data?.uploader_id !== patientId && {
        label: fax?.myportal ? 'Unshare document' : 'Share with patient',
        icon: 'new-arrow-swap-down',
        onClick: () => {
          handleConfirmationFile({
            mode: fax?.myportal ? 'unshare' : 'share',
            id: fax?.id,
            type: 'fax'
          });
        }
      }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
    </>
  );
};

export default FaxDocsActions;
