import { numberFormatter } from 'lib/helpers/utility';
import React from 'react';

export const CustomStatusBarCount = ({ data = [], count = 0, page = 1, limit = 25 }) => {
  if (data.length === 0) return null;

  const start = (page - 1) * limit + 1;
  const end = Math.min(page * limit, count);

  return (
    <div className="flex h-full items-center justify-end gap-[3px] p-0">
      {count > 0 && (
        <p className="text-xs text-neutral-900">
          <span className="font-bold">{numberFormatter.format(start)}</span>
          <span className="mx-1">to</span>
          <span className="font-bold">{numberFormatter.format(end)}</span>
          <span className="mx-1">of</span>
          <span className="font-bold">{numberFormatter.format(count)}</span>
        </p>
      )}
    </div>
  );
};
