import React, { useEffect, useRef, useState } from 'react';

import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';

const PDFNav = ({
  src,
  canvasStates,
  setCanvasStates,
  fabricCanvasRef,
  currentPage,
  setCurrentPage,
  scale,
  totalPages,
  renderPdf,
  historyState,
  undo,
  redo,
  historyMode,
  fileType
}) => {
  const [inputPage, setInputPage] = useState(1);

  const timer = useRef();

  const saveCurrentCanvasState = async () => {
    const fabricCanvas = fabricCanvasRef.current;
    const canvasJson = fabricCanvas.toJSON();
    await setCanvasStates((prevStates) => ({
      ...prevStates,
      [currentPage]: canvasJson,
      scale
    }));
  };

  useEffect(() => {
    if (inputPage === currentPage) return;
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (inputPage && inputPage > 0 && inputPage <= totalPages) {
        saveCurrentCanvasState();
        setCurrentPage(parseInt(inputPage));
      } else if (inputPage) {
        setInputPage(currentPage);
      }
    }, 300);

    return () => {
      clearTimeout(timer.current);
    };
  }, [inputPage]);

  useEffect(() => {
    if (src) {
      renderPdf(src, currentPage);
    }
    setInputPage(currentPage);
  }, [currentPage, canvasStates]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      saveCurrentCanvasState();
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      saveCurrentCanvasState();
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div
      className={cs(
        'flex items-center ',
        historyMode && fileType === 'pdf' ? 'justify-end w-full min-h-12' : null
      )}>
      {!historyMode && (
        <div className="mr-3 flex">
          <div
            onClick={() => undo()}
            disabled={historyState.index <= 0}
            className={cs(
              '!cursor-pointer  px-3 py-3 hover:bg-primary-50 active:bg-primary-100',
              historyState.index <= 0 && 'opacity-50'
            )}>
            <Icon size={16} className="!cursor-pointer" icon="undo" shade={900} color="primary" />
          </div>

          <div
            onClick={() => redo()}
            disabled={historyState.index >= historyState.history.length - 1}
            className={cs(
              '!cursor-pointer px-3 py-3 hover:bg-primary-50 active:bg-primary-100',
              historyState.index >= historyState.history.length - 1 && 'opacity-50'
            )}>
            <Icon size={16} className="!cursor-pointer" icon="redo" shade={900} color="primary" />
          </div>
        </div>
      )}
      {fileType === 'pdf' && (
        <>
          <i className="mr-1 ml-4 text-sm text-neutral-300">page </i>
          <button onClick={handlePreviousPage} disabled={currentPage <= 1}>
            <Icon
              className="cursor-pointer"
              icon="new-chevron-left"
              stroke
              shade={currentPage <= 1 ? 300 : 700}
              color="neutral"
            />
          </button>

          <input
            name="page"
            value={inputPage}
            style={{
              WebkitAppearance: 'none',
              appearance: 'none',
              MozAppearance: 'none'
            }}
            onChange={(e) => setInputPage(e.target.value)}
            className=" w-6 rounded-lg border border-neutral-100 text-center text-sm text-neutral-700 outline-none"></input>
          <p className="ml-1">/</p>
          <p className="ml-1">{totalPages}</p>

          <button onClick={handleNextPage} className="ml-1" disabled={currentPage >= totalPages}>
            <Icon
              className="cursor-pointer"
              icon="new-chevron-right"
              stroke
              shade={currentPage >= totalPages ? 300 : 700}
              color="neutral"
            />
          </button>
        </>
      )}
    </div>
  );
};

export default PDFNav;
