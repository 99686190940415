import React, { useRef, useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import SelectOwnerModal from './SelectOwner/SelectOwnerModal';
import SelectUserType from './SelectUserType';
import { showAlert } from 'components/shared/Alert/Alert';
import { OrganizationContextProvider } from 'lib/context/OrganizationContext/OrganizationContextProvider';

const AddOwnerModal = ({ view, setView }) => {
  const [loading, setLoading] = useState(false);
  const ownerRef = useRef();

  const handleContinue = () => {
    if (view === 'convert') {
      setLoading(true);
      ownerRef.current?.convertToOrganization().then(() => setLoading(false));
      return;
    }
    if (view === 'add-to-existing') {
      setLoading(true);
      ownerRef.current?.handleAppendPracticeToOrganization().then(() => setLoading(false));
      return;
    }
    showAlert({
      color: 'warning',
      message: 'Please select an option to continue'
    });
  };

  const BackAction = ({ children }) => (
    <div className="flex items-center ">
      <div className="!ml-6 !mt-3 cursor-pointer text-primary-900" onClick={() => setView(null)}>
        Go back
      </div>
      {children}
    </div>
  );

  return (
    <Modal
      handleOpen={!!view}
      handleClose={() => setView(null)}
      title="Select user to convert to an owner"
      bodyClassName="p-0"
      customStyling={{ width: view == 'convert' ? '540px' : '720px' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setView(null)}
            data-qa="cancel-select-owner-btn"
          />
          {view && (
            <Button
              onClick={handleContinue}
              text={view === 'convert' ? 'Create organization' : 'Add to organization'}
              id="createContactModalBtn"
              data-qa="append-owner-btn"
              disabled={!view}
              loading={loading}
            />
          )}
        </div>
      }>
      <>
        {view ? (
          <div></div>
        ) : (
          <div className="!p-6">
            <div
              className=" flex w-full cursor-pointer items-center justify-center rounded-md bg-white !p-4 shadow-sm"
              onClick={() => setView('convert')}>
              <h4 className="font-500 text-primary-600">Convert to an organization</h4>
            </div>
            <div
              className=" !mt-4 flex w-full cursor-pointer items-center justify-center rounded-md bg-white !p-4 shadow-sm"
              onClick={() => setView('add-to-existing')}>
              <h4 className="font-500 text-primary-600">
                Add practice to an existing organization
              </h4>
            </div>
          </div>
        )}
        {view === 'convert' && (
          <SelectUserType
            ref={ownerRef}
            BackAction={BackAction}
            setAddOwnerModalVisible={setView}
          />
        )}
        {view === 'add-to-existing' && (
          <OrganizationContextProvider>
            <SelectOwnerModal
              ref={ownerRef}
              BackAction={BackAction}
              setAddOwnerModalVisible={setView}
            />
          </OrganizationContextProvider>
        )}
      </>
    </Modal>
  );
};

export default AddOwnerModal;
