import cs from 'classnames';
import { Capitalize } from 'lib/helpers/utility';
import React from 'react';

const HeaderMedicationTable = ({
  title,
  subtitle,
  children,
  className,
  showInfoInRecordings = false,
  titleClassName
}) => (
  <div
    className={cs(
      'relative z-10 flex  gap-4 border-0 !border-b !border-solid !border-neutral-100 bg-white !px-6',
      className
    )}
    data-dd-privacy={showInfoInRecordings ? 'allow' : 'mask'}>
    <div className="!py-[15px]">{children}</div>
    <div
      className={cs(
        'flex items-center gap-1 !py-3 !pr-6',
        (title || subtitle) && 'shadow-[4px_0_16px_0_rgba(0,79,107,0.04)]'
      )}>
      {title && (
        <h3 className={cs('w-max text-lg font-500 leading-7 text-primary-800', titleClassName)}>
          {title}
        </h3>
      )}
      {subtitle && (
        <div className="flex items-center justify-center rounded-full bg-primary-50 !py-1 px-[10px]">
          <span className="w-max text-base font-500 leading-6 text-primary-600">
            {Capitalize(subtitle)}
          </span>
        </div>
      )}
    </div>
  </div>
);

export default HeaderMedicationTable;
