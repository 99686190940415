import { Honeybadger } from '@honeybadger-io/react';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useRecoilState } from 'recoil';
import { interimApi } from '../../../api/InterimApi';
import { ia, keyChanges, optionify, pm } from '../../../lib/helpers/utility';
import adminState from '../../admin/pages/Practices/state';
import { showAlert } from '../../shared/Alert/Alert';
import Button from '../../shared/Buttons/Button';
import Checkbox from '../../shared/Checkbox/Checkbox';
import Input from '../../shared/Input/Input';
import state from '../../state';

const PracticePatientSettings = ({ updatePatient, practicePatientData }) => {
  const navigate = useNavigate();

  const [practicePatientLink, setPracticePatientLink] = useState({
    terminal_group_id: 0,
    sms_consent: false,
    hipaa_consent: false,
    hipaa_path: '',
    hipaa_date: new Date(),
    pre_auth_consent: false,
    custom_id: '',
    virtual_link: ''
  });
  const [orgPracticePatientLink, setOrgPracticePatientLink] = useState({
    terminal_group_id: 0,
    sms_consent: false,
    hipaa_consent: false,
    hipaa_path: '',
    hipaa_date: new Date(),
    pre_auth_consent: false,
    custom_id: '',
    virtual_link: ''
  });

  const [show, setShow] = useState({ loading: false, updateLoading: false });
  const [terminalGroups, setTerminalGroups] = useRecoilState(adminState.terminalGroupState);
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  const { id } = useParams();

  useEffect(() => {
    getTerminalGroups().then(() => {
      getPracticePatientInfo();
    });
    // if (practicePatientData) {
    //   setPracticePatientLink(practicePatientData);
    //   setOrgPracticePatientLink(practicePatientData);
    // } else {
    // }
  }, []);

  const getTerminalGroups = async () => {
    try {
      const res = await interimApi('/api/transactions/group/get', {}, navigate);
      const { code, redirect, error, groups } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setTerminalGroups(optionify(groups));
          break;
        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`getTerminalGroups patientID: ${id}, error: ${error}`);
          } else {
            toast.error(`An unexpected error has occurred. Please try again later.`);
            Honeybadger.notify(`getTerminalGroups patientID: ${id}`);
          }
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
      Honeybadger.notify(`getTerminalGroups patientID: ${id}, error: ${error}`);
    }
  };

  const getPracticePatientInfo = async () => {
    let newShow = Object.assign({}, show);
    try {
      const res = await interimApi(
        '/api/practice/patient/link/get',
        {
          patientId: id
        },
        navigate
      );
      const { code, redirect, error, patientLink } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (patientLink.terminal_group_id) {
            let tg = terminalGroups.find((v) => v.value === patientLink.terminal_group_id);
            patientLink.terminal_group_id = tg;
          }
          setOrgPracticePatientLink(patientLink);
          setPracticePatientLink(patientLink);
          break;
        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`getPracticePatientInfo patientID: ${id}, error: ${error}`);
          } else {
            toast.error(`An unexpected error has occurred. Please try again later.`);
            Honeybadger.notify(`getPracticePatientInfo patientID: ${id}`);
          }
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
      Honeybadger.notify(`getPracticePatientInfo patientID: ${id}, error: ${error}`);
    }
    setShow(newShow);
  };

  const updatePracticePatientLink = async () => {
    if (!pm(permissions, ['patients.update'])) {
      showAlert({ message: `You are not permitted to perform this action.`, color: 'warning' });
      return;
    }
    let newShow = Object.assign({}, show);
    try {
      let changes = keyChanges(orgPracticePatientLink, practicePatientLink);
      if (isEmpty(changes)) {
        showAlert({ message: `No changes detected.`, color: 'warning' });
        return;
      }
      setShow({ ...show, updateLoading: true });
      const res = await interimApi(
        '/api/practice/patient/link/update',
        {
          patientId: id,
          changes: changes
        },
        navigate
      );
      const { code, redirect, error } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (changes) {
            setOrgPracticePatientLink({ ...practicePatientLink, ...changes });
            setPracticePatientLink({ ...practicePatientLink, ...changes });
          } else {
            setOrgPracticePatientLink(practicePatientLink);
          }
          showAlert({ message: `Successfully updated settings!`, color: 'success' });
          break;
        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`updatePracticePatientLink patientID: ${id}, error: ${error}`);
          } else {
            toast.error(`An unexpected error has occurred. Please try again later.`);
            Honeybadger.notify(`updatePracticePatientLink patientID: ${id}`);
          }
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
      Honeybadger.notify(`updatePracticePatientLink patientID: ${id}, error: ${error}`);
    }
    newShow.updateLoading = false;
    setShow(newShow);
  };

  if (!pm(permissions, ['patients.read'])) {
    return (
      <div>
        <div>Practice Patient Settings</div>
        <div>Insufficient permissions to view data.</div>
      </div>
    );
  }

  return (
    <div className=" overflow-auto !p-8">
      <div>Practice Patient Settings</div>
      {pm(permissions, ['billing.read']) && ia(terminalGroups) && (
        <div>
          <div>Terminal Group (optional)</div>
          <ReactSelect
            disabled={!pm(permissions, ['billing.update']) || show.updateLoading}
            options={terminalGroups}
            value={practicePatientLink.terminal_group_id}
            onChange={(e) => {
              setPracticePatientLink({
                ...practicePatientLink,
                terminal_group_id: e.value || null
              });
            }}
          />
        </div>
      )}
      <div className="!mt-4">
        <Checkbox
          color="primary"
          style={{ color: '#00a2ff' }}
          checked={practicePatientLink.sms_consent}
          label={'SMS Consent'}
          onChange={(e) =>
            setPracticePatientLink({ ...practicePatientLink, sms_consent: e.target.checked })
          }
          disabled={!pm(permissions, ['patients.update']) || show.updateLoading}
        />
      </div>
      <div className="!mt-4">
        <Input
          data-qa="patient-virtual-link"
          value={practicePatientLink.virtual_link}
          onChange={(e) =>
            setPracticePatientLink({ ...practicePatientLink, virtual_link: e.target.value })
          }
          placeholder={'https://meet.google.com/abc-123-xyz'}
          disabled={!pm(permissions, ['patients.update']) || show.updateLoading}
          label={'Virtual Link'}
        />
      </div>
      <div className="!mt-4">
        <Button
          data-qa="save-changes-btn"
          text={'Save changes'}
          onClick={updatePracticePatientLink}
          loading={show.updateLoading}
          disabled={!pm(permissions, ['patients.update']) || show.updateLoading}
        />
      </div>

      {/* SMS Consent Here */}
      {/* HIPAA form sing status and time here */}
      {/* {pm(permissions, ['patient.update']) && (
        <div>
          <Button green onClick={() => updatePracticePatientLink()}>
            Save
          </Button>
        </div>
      )} */}
    </div>
  );
};

export default PracticePatientSettings;
