export default class CustomLoadingOverlay {
  init(params) {
    this.eGui = document.createElement('div');
    this.eGui.innerHTML = `<div>No data available...</div>`;
  }

  getGui() {
    return this.eGui;
  }
}
