import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../Patient/checkin/style.scss';
import cs from 'classnames';

import { requestAssistance } from 'api/Kiosk';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const RequestAssistance = ({ page, className }) => {
  const navigate = useNavigate();
  const [confirm, setConfirm] = useState(false);

  const request = async () => {
    const req = await requestAssistance(navigate, { page });
    if (req.request) {
      showAlert({
        title: 'Request Sent',
        message: 'Your request has been sent. Someone will be with you shortly.',
        color: 'success'
      });
    } else {
      showAlert({
        title: 'Request Failed',
        message: 'Your request failed to send. Please try again.',
        color: 'danger'
      });
    }
    setConfirm(false);
  };

  return (
    <>
      <div className={cs('flex gap-x-1 text-sm', className)}>
        <p className="select-none text-sm font-400 text-neutral-600">Need help?</p>
        <p className="cursor-pointer text-sm font-400 text-primary-500" onClick={() => setConfirm(true)}>
          Request assistance
        </p>
      </div>
      {confirm && (
        <Confirm
          variant="warning"
          primaryBtnTxt="Request"
          icon="new-info"
          iconSize={42}
          title="Request Assistance"
          message="Are you sure you want to request assistance?"
          handleContinue={request}
          handleOpen={confirm}
          handleClose={() => setConfirm(false)}
        />
      )}
    </>
  );
};

export default RequestAssistance;
