module.exports = {
  question: {
    title: 'Automate Sending these Forms to Patients? (Optional)',
    description:
      'Tip: Intake or H&P Templates are generally automated by service or tag. Simply choose a new patient service type or tag! For Progress Templates or intake forms meant to be filled per Intake Forms, we recommend you automate by provider!',
    answers: [
      {
        id: self.crypto.randomUUID(),
        title: 'Provider',
        description: 'Will send before each appointment for this provider.',
        value: 'providers'
      },
      {
        id: self.crypto.randomUUID(),
        title: 'Service Rendered',
        description:
          'Will send before each encounter for this service. Intake forms should be assigned to new patient service types.',
        value: 'services'
      },
      {
        id: self.crypto.randomUUID(),
        title: 'Appointment Tags',
        description:
          'Customizable tags are ideal for broader automations. IE: New Patient, Sick Visit, No Insurance.',
        value: 'tags'
      },
      {
        id: self.crypto.randomUUID(),
        title: 'Patient Creation',
        description: 'Will send to every newly created patient. Will not send during mass imports.',
        value: 'patient_creation'
      }
    ]
  }
};
