import React, { useRef, useEffect } from 'react';
import NotFound from 'components/NotFound';
import CustomFormsAI from '../../../components/forms/CUSTOM/CustomFormsAI';

const ChatRow = ({ item, index, value, onInputChange, componentRefs, components }) => {
  const Input = item?.forms || CustomFormsAI || NotFound;
  const rowRef = useRef();

  useEffect(() => {
    componentRefs.current[item.path] = rowRef.current;
  }, [componentRefs, item.path]);

  return (
    <div ref={rowRef} key={`${item.key}-${item.type}`} id={item?.title}>
      <Input
        inputName={item.path}
        type={item?.type || item?.key}
        components={components}
        value={value}
        onChange={(e) => onInputChange(index, e.target.value)}
      />
    </div>
  );
};

export default React.memo(ChatRow);
