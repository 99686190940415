import React from 'react';

import { useRecoilValue } from 'recoil';

import {
  findRelationName,
  formatDateAndTimeZ,
  ia
} from '../../../../../../../../lib/helpers/utility';
import { currentPractice } from '../../../../../../practiceState';

const InsuranceDetails = ({ itemProps: insurances }) => {
  const practice = useRecoilValue(currentPractice);
  if (!ia(insurances)) return null;

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Insurance Details</div>

      {insurances.map((insurance) => (
        <ul key={insurance.id} className="!pl-5 !mt-3">
          <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
            {insurance?.ins_addr && (
              <div>
                <strong>Address:</strong> {insurance?.ins_addr}
              </div>
            )}

            {insurance?.ins_bill_to && (
              <div>
                <strong>Bill To:</strong> {insurance?.ins_bill_to}
              </div>
            )}

            {insurance?.ins_city && (
              <div>
                <strong>City:</strong> {insurance?.ins_city}
              </div>
            )}

            {insurance?.ins_code && (
              <div>
                <strong>Code:</strong> {insurance?.ins_code}
              </div>
            )}

            {insurance?.ins_contact_first_name && (
              <div>
                <strong>Contact First Name:</strong> {insurance?.ins_contact_first_name}
              </div>
            )}

            {insurance?.ins_contact_last_name && (
              <div>
                <strong>Contact Last Name:</strong> {insurance?.ins_contact_last_name}
              </div>
            )}

            {insurance?.ins_contact_phone && (
              <div>
                <strong>Contact Phone:</strong> {insurance?.ins_contact_phone}
              </div>
            )}

            {insurance?.ins_group_name && (
              <div>
                <strong>Group Name:</strong> {insurance?.ins_group_name}
              </div>
            )}

            {insurance?.ins_group_num && (
              <div>
                <strong>Group Number:</strong> {insurance?.ins_group_num}
              </div>
            )}

            {insurance?.ins_name && (
              <div>
                <strong>Name:</strong> {insurance?.ins_name}
              </div>
            )}

            {insurance?.ins_plan_eff_dt && (
              <div>
                <strong>Plan Effective Date:</strong>{' '}
                {formatDateAndTimeZ(insurance?.ins_plan_eff_dt, practice?.timezone)}
              </div>
            )}
            {insurance?.ins_plan_exp_dt && (
              <div>
                <strong>Plan Expiry Date:</strong>{' '}
                {formatDateAndTimeZ(insurance?.ins_plan_exp_dt, practice?.timezone)}
              </div>
            )}

            {insurance?.ins_plan_type && (
              <div>
                <strong>Plan Type:</strong> {insurance?.ins_plan_type}
              </div>
            )}

            {insurance?.ins_policy_num && (
              <div>
                <strong>Policy Number:</strong> {insurance?.ins_policy_num}
              </div>
            )}

            {insurance?.ins_relationship && (
              <div>
                <strong>Relationship:</strong>
                {findRelationName(insurance?.ins_relationship)}
              </div>
            )}
          </li>
        </ul>
      ))}
    </div>
  );
};

export default InsuranceDetails;
