import React from 'react';
import Icon from './Icon/Icon';

const CardProcessor = ({ children, style = {} }) => {
  switch (children) {
    case 'MASTERCARD':
    case 'MC':
      return <Icon icon="new-payment-mastercard" size={44} />;
    case 'VISA':
      return <Icon icon="new-payment-visa" size={44} />;
    case 'DISCOVER':
      return <Icon icon="new-payment-discover" size={44} />;
    case 'AMEX':
      return <Icon icon="new-payment-amex" size={44} />;
    default:
      return <div style={style}>{children}</div>;
  }
};

export default CardProcessor;
