export default function isProcedureChecked(procedures, procedure, packId, invoiceId) {
  const hasProcedure = procedures?.find?.((item) => {
    if (!!item.packageId && !!item.invoiceId) {
      return (
        item.id === procedure.id &&
        item.packageId === packId &&
        item.invoiceId === invoiceId &&
        item.packageId !== undefined
      );
    } else {
      return item.id === procedure.id && item.invoiceId === invoiceId && !packId;
    }
  });

  return hasProcedure;
}
