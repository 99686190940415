import Honeybadger from '@honeybadger-io/js';
import { interimApi } from 'api/InterimApi';
import { ia } from 'lib/helpers/utility';

export default async function getRenderingProviders(superbill) {
  try {
    let res = await interimApi('/api/practice/billing/providers/rendering/get', {}, () => {});
    const providers = res?.data?.providers;
    if (ia(providers)) {
      const index = providers.findIndex((e) =>
        superbill?.claim?.prov_npi
          ? superbill?.claim?.prov_npi === e.billingInfo.npi
          : e.id === superbill?.practitioner?.id
      );
      let provider;
      if (index !== -1) {
        provider = providers[index];
      } else {
        provider = providers[0];
      }
      const renderingProvider = {
        label: `${provider.fullName} - ${provider.billingInfo.npi}`,
        value: `${provider.fullName} - ${provider.billingInfo.npi}`,
        prov_name_l: provider.l_name,
        prov_name_f: provider.f_name,
        prov_name_m: provider.m_name,
        prov_npi: provider.billingInfo.npi,
        prov_id: provider.billingInfo.atypical_id,
        prov_taxonomy: provider.billingInfo.taxonomy,
        prov_taxid: provider.billingInfo.tax_id,
        prov_taxid_type: provider.billingInfo.tax_id_type
      };
      const mappedProviders = providers?.map((provider) => {
        return {
          label: `${provider.fullName} - ${provider.billingInfo.npi}`,
          value: `${provider.fullName} - ${provider.billingInfo.npi}`,
          prov_name_l: provider.l_name,
          prov_name_f: provider.f_name,
          prov_name_m: provider.m_name,
          prov_npi: provider.billingInfo.npi,
          prov_id: provider.billingInfo.atypical_id,
          prov_taxonomy: provider.billingInfo.taxonomy,
          prov_taxid: provider.billingInfo.tax_id,
          prov_taxid_type: provider.billingInfo.tax_id_type
        };
      });
      return { renderingProvider, mappedProviders };
    } else {
      Honeybadger.notify(`There's been an unexpected error, please try again later. `);
    }
  } catch (err) {
    Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
  }
}
