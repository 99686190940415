import _, { capitalize } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useTableContext } from '../../../lib/context/TableContext/TableContext';
import { TableContextProvider } from '../../../lib/context/TableContext/TableContextProvider';
import { formatDate, formatPhoneNumber, ia, iaRa } from '../../../lib/helpers/utility';
import { usePatients } from '../../../lib/hooks/queries/patients/usePatients';
import usePageTitle from '../../../lib/hooks/usePageTitle';

import AGTable from '../../shared/AGTable/AGTable';
import DisplayButton from '../../shared/AGTable/DisplayButton';
import Button from '../../shared/Buttons/Button';
import Header from '../../shared/Header/Header';
import Icon from '../../shared/Icon/Icon';
import Input from '../../shared/Input/Input';
import Pagination from '../../shared/Pagination/Pagination';

import ChartExport from './ChartExport';
import NewPatient from './NewPatient';
import Tippy from '@tippyjs/react';
import Allowed from 'components/shared/Permissions/Allowed';

const isFirstColumn = (params) => {
  var displayedColumns = params.api.getAllDisplayedColumns();
  var thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

const columns = [
  { field: 'id', headerName: 'Chart ID' },
  {
    field: 'f_name',
    headerName: 'First Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  { field: 'l_name', headerName: 'Last Name', valueFormatter: ({ value }) => capitalize(value) },
  {
    field: 'p_name',
    headerName: 'Preferred Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  {
    field: 'dob',
    headerName: 'Date of Birth',
    valueFormatter: ({ value }) => formatDate(value)
  },
  { field: 'email', headerName: 'Email' },

  {
    field: 'phone',
    headerName: 'Phone',
    valueFormatter: ({ value }) => formatPhoneNumber(value)
  },
  {
    field: 'status',
    headerName: 'Status',
    valueGetter: (params) => {
      return params?.data?.practicePatientLink?.status;
    },
    valueFormatter: ({ value }) => capitalize(value)
  }
];

export default function PatientCharts() {
  return (
    <Allowed requiredPermissions="patients.read" showMessage={true} showIllustration={true}>
      <TableContextProvider cols={columns} pagination name="patient_charts">
        <Table />
      </TableContextProvider>
    </Allowed>
  );
}

function Table() {
  usePageTitle('Charts');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [newPatientModal, setNewPatientModal] = useState(false);
  const [showExport, setShowExport] = useState(false);
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading, selectedRows } = useTableContext();
  const navigate = useNavigate();
  const {
    data = {},
    isLoading,
    isFetching
  } = usePatients({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });

  const patients = data.patients;
  const hideNewPatientModal = () => setNewPatientModal(false);

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/portal/charts/${event.data.id}`);
    }
  };

  if (id) return <Outlet />;

  return (
    <>
      <Header title="Charts">
        <div className="flex items-center gap-2" data-dd-privacy="allow">
          <Tippy
            className="tippy-dark"
            content={
              selectedRows.length == 0
                ? 'Select at least one patient row to enable export'
                : 'EHI Export'
            }
            placement="top">
            <div>
              <Button
                icon="new-export-v2"
                outlined
                color="neutral"
                iconSize={16}
                className="!h-[30px] !w-[38px]"
                disabled={selectedRows.length == 0}
                onClick={() => setShowExport(true)}
              />
            </div>
          </Tippy>
          <DisplayButton />
          <Input
            data-qa="searchPatient"
            type="string"
            placeholder="Search patients..."
            inputWrapperClassName="h-[30px]"
            value={searchTerm}
            icon="new-search"
            className={` !rounded-2xl !border-secondary-400 md:w-[200px] `}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
          <Allowed requiredPermissions="patients.create">
            <Button
              text="New Patient"
              icon="user-add"
              size="small"
              onClick={() => setNewPatientModal(true)}
              type="primary"
              data-qa="new-patient-btn"
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex h-full  flex-col overflow-hidden !pb-2" data-dd-privacy="allow">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={patients}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
        </div>
      </div>
      <NewPatient newPatientModal={newPatientModal} hideNewPatientModal={hideNewPatientModal} />
      {showExport && ia(selectedRows) && (
        <ChartExport
          selectedRows={selectedRows.map((r) => r.data.id)}
          show={showExport}
          setShow={setShowExport}
        />
      )}
    </>
  );
}
