import { forwardRef } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';

import Icon from '../Icon/Icon';

import { getVariantClasses } from './lib/helpers';

/**
 * @type {import('react').ForwardRefExoticComponent<import('./lib/propTypes').TypographyProps & import('react').RefAttributes<HTMLElement>>}
 */

const Typography = forwardRef((props, ref) => {
  const {
    variant = 'label',
    size = 'medium',
    className,
    children,
    color = 'neutral-900',
    textDecoration = 'none',
    iconColor = 'primary',
    weight = variant.startsWith('h') ? 'medium' : 'normal',
    icon,
    iconRight,
    tooltip,
    tooltipPlacement = 'bottom',
    tooltipColor = 'dark',
    required = false,
    iconIsStroke = false,
    iconSize,
    disabled = false,
    ...rest
  } = props;

  const baseClasses = getVariantClasses(variant, size);

  const Component = variant === 'display' ? 'h1' : variant;

  return (
    <div className="flex items-center gap-2">
      {icon && (
        <Tippy
          content={tooltip || ''}
          disabled={!tooltip}
          placement={tooltipPlacement}
          className={tooltipColor === 'dark' && 'tippy-dark'}>
          <div className="flex items-center">
            <Icon
              icon={icon}
              color={disabled ? 'neutral' : iconColor}
              iconIsStroke={iconIsStroke}
              iconSize={iconSize}
            />
          </div>
        </Tippy>
      )}
      <div className="flex items-center">
        <Component
          ref={ref}
          className={cs(
            'flex items-center gap-2',
            baseClasses,
            `font-${weight}`,
            textDecoration,
            disabled ? 'text-neutral-400' : `text-${color}`,
            className
          )}
          {...rest}>
          {children}
        </Component>
        {required && <span className="text-danger-400">*</span>}
      </div>
      {iconRight && (
        <Tippy
          content={tooltip || ''}
          disabled={!tooltip}
          placement={tooltipPlacement}
          className={tooltipColor === 'dark' && 'tippy-dark'}>
          <div className="flex items-center">
            <Icon
              icon={iconRight}
              color={disabled ? 'neutral' : iconColor}
              iconIsStroke={iconIsStroke}
              iconSize={iconSize}
            />
          </div>
        </Tippy>
      )}
    </div>
  );
});

Typography.displayName = 'Typography';

export default Typography;
