import { useNavigate } from 'react-router-dom';

import { useField } from 'formik';

import { iaRa } from 'lib/helpers/utility';

import { handleICD10Search } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';
import { trackUserClickForOldUI } from 'components/practice/charts/ClinicalNote/shared/ICD10Code/lib/userTrackSearchClick';
import Select from 'components/shared/Select/Select';

const Diagnosis = ({ menuPlacement = 'top', ...rest }) => {
  const [field, meta, helpers] = useField({
    name: 'diags',
    id: 'diags'
  });

  const navigate = useNavigate();

  const onDiagnosisChange = async (response) => {
    const currentICDValueArray = iaRa(field.value).map((item) => item?.value);
    const valuesOnly = iaRa(response).map((row) => row?.value);

    helpers.setValue(response);

    await trackUserClickForOldUI({
      navigate,
      currentICDValueArray,
      newICDArray: valuesOnly
    });
  };

  return (
    <Select
      {...field}
      error={meta.touched && meta.error}
      inputId="select-diags"
      label="ICD-10/Diagnosis"
      placeholder="Type and select ICD-10/Diagnosis"
      isAsync
      required
      isMulti
      isClearable
      loadOptions={handleICD10Search}
      onChange={onDiagnosisChange}
      menuPlacement={menuPlacement}
      components={{
        IndicatorSeparator: () => null
      }}
      {...rest}
    />
  );
};

export default Diagnosis;
