import React from 'react';
import { useNavigate } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useSetRecoilState } from 'recoil';

import { requestApi } from 'api/Api';

import adminState from 'components/admin/lib/adminState';
import {
  currentPractice,
  ownerPractices,
  ownerSelectedLocation
} from 'components/practice/practiceState';
import { hasBeenAuthChecked, userState } from 'components/state';

import Icon from '../Icon/Icon';

const Logout = ({
  className,
  textClassName,
  iconClassName,
  iconColor,
  iconShade,
  showText = true,
  adminPortal = false,
  ...rest
}) => {
  const setUser = useSetRecoilState(userState);
  const setPractice = useSetRecoilState(currentPractice);
  const setHasBeenAuthChecked = useSetRecoilState(hasBeenAuthChecked);
  const setOwnerSelectedLocation = useSetRecoilState(ownerSelectedLocation);
  const setOwnerPractices = useSetRecoilState(ownerPractices);
  const setAdminState = useSetRecoilState(adminState.current_admin);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const logout = async () => {
    try {
      if (adminPortal) {
        await requestApi({ url: '/api/admin/logout', navigate });
        sessionStorage.removeItem('myriad-session-reference-id');
        queryClient.clear();
        window.localStorage.clear();
        setAdminState({
          user_id: '',
          f_name: '',
          l_name: '',
          email: '',
          state: '',
          role: '',
          logged_in: false
        });
        navigate('/admin/login');
      } else {
        const res = await requestApi({
          url: '/api/user/logout',
          navigate
        });
        sessionStorage.removeItem('myriad-session-reference-id');
        queryClient.clear();
        const { code, redirect } = res;
        if (redirect === '/admin') setHasBeenAuthChecked({ checked: false, isImpersonate: true });

        setUser({
          user_id: '',
          f_name: '',
          l_name: '',
          email: '',
          state: '',
          kind: '',
          logged_in: false
        });
        setPractice({
          id: null,
          name: '',
          email: '',
          timezone: '',
          display_settings: null
        });
        setOwnerPractices([]);
        setOwnerSelectedLocation(null);
        switch (code) {
          case -1:
            navigate(redirect);
            break;
          case 0:
            navigate('/login');
            break;
          case 1:
            navigate(redirect);
            break;

          default:
            navigate('/login');
            break;
        }
      }

      datadogRum.clearUser();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Tippy content="Logout" placement="right">
      <div
        className={cs(
          'flex h-[32px] cursor-pointer items-center justify-center !rounded-md hover:bg-primary-50',
          className,
          !showText && 'w-[32px]'
        )}
        onClick={logout}
        data-qa="logout-btn"
        {...rest}>
        {showText && (
          <span className={cs('px-2 text-xs text-primary-900', textClassName)}>Logout</span>
        )}
        <Icon
          icon="new-logout"
          color={iconColor}
          shade={iconShade}
          className={cs('cursor-pointer', iconClassName)}
        />
      </div>
    </Tippy>
  );
};

export default Logout;
