export function generateHPList(cnDisplaySettings, advancedHPTitles) {
  return {
    complaints: cnDisplaySettings?.sections?.hp?.complaints?.enabled && {
      title: 'Complaints',
      path: 'complaints',
      position: cnDisplaySettings?.sections?.hp?.complaints?.position
    },
    hpi: cnDisplaySettings?.sections?.hp?.hpi?.enabled && {
      title: 'History of Present Illness',
      path: 'hpi',
      position: cnDisplaySettings?.sections?.hp?.hpi?.position
    },
    ros: cnDisplaySettings?.sections?.hp?.ros?.enabled && {
      title: 'Review of Systems',
      path: 'ros',
      position: cnDisplaySettings?.sections?.hp?.ros?.position
    },
    allergies: cnDisplaySettings?.sections?.hp?.allergies?.enabled && {
      title: 'Allergies',
      path: 'allergies',
      position: cnDisplaySettings?.sections?.hp?.allergies?.position
    },
    medications: cnDisplaySettings?.sections?.hp?.medications?.enabled && {
      title: 'Medications',
      path: 'medicationHistory',
      position: cnDisplaySettings?.sections?.hp?.medications?.position
    },
    vaccines: cnDisplaySettings?.sections?.hp?.vaccines?.enabled && {
      title: 'Vaccines',
      path: 'vaccines',
      position: cnDisplaySettings?.sections?.hp?.vaccines?.position
    },
    weightLossAdministration: cnDisplaySettings?.sections?.hp?.weightLossAdministration
      ?.enabled && {
      title: 'Weight Loss Administration',
      path: 'weightLossAdministration',
      position: cnDisplaySettings?.sections?.hp?.weightLossAdministration?.position
    },
    vitals: cnDisplaySettings?.sections?.hp?.vitals?.enabled && {
      title: 'Vitals',
      path: 'vitals',
      position: cnDisplaySettings?.sections?.hp?.vitals?.position
    },
    socialHistory: cnDisplaySettings?.sections?.hp?.socialHistory?.enabled && {
      title: 'Social History',
      path: 'socialHistory',
      position: cnDisplaySettings?.sections?.hp?.socialHistory?.position
    },
    familyHistory: cnDisplaySettings?.sections?.hp?.familyHistory?.enabled && {
      title: 'Family History',
      path: 'familyHistory',
      position: cnDisplaySettings?.sections?.hp?.familyHistory?.position
    },
    medicalHistory: cnDisplaySettings?.sections?.hp?.medicalHistory?.enabled && {
      title: 'Medical History',
      path: 'medicalHistory',
      position: cnDisplaySettings?.sections?.hp?.medicalHistory?.position
    },
    surgicalHistory: cnDisplaySettings?.sections?.hp?.surgicalHistory?.enabled && {
      title: 'Surgical History',
      path: 'surgicalHistory',
      position: cnDisplaySettings?.sections?.hp?.surgicalHistory?.position
    },
    exam: cnDisplaySettings?.sections?.hp?.exam?.enabled && {
      title: 'Exam',
      path: 'exam',
      position: cnDisplaySettings?.sections?.hp?.exam?.position
    },
    test: cnDisplaySettings?.sections?.hp?.test?.enabled && {
      title: 'Test',
      path: 'test',
      position: cnDisplaySettings?.sections?.hp?.test?.position
    },
    assessment: cnDisplaySettings?.sections?.hp?.assessment?.enabled && {
      title: 'Assessment',
      path: 'assessment',
      position: cnDisplaySettings?.sections?.hp?.assessment?.position
    },
    plan: cnDisplaySettings?.sections?.hp?.plan?.enabled && {
      title: 'Plan',
      path: 'plan',
      position: cnDisplaySettings?.sections?.hp?.plan?.position
    },
    ...advancedHPTitles,
    cptCodes: cnDisplaySettings?.sections?.hp?.cptCodes?.enabled && {
      title: 'CPT® Codes',
      path: 'cpt-codes',
      position: cnDisplaySettings?.sections?.hp?.cptCodes?.position
    },
    diagnosisCodes: cnDisplaySettings?.sections?.hp?.diagnosisCodes?.enabled && {
      title: 'Diagnosis Codes',
      path: 'diagnosis-codes',
      position: cnDisplaySettings?.sections?.hp?.diagnosisCodes?.position
    },
    notes: cnDisplaySettings?.sections?.hp?.notes?.enabled && {
      title: 'General Notes',
      path: 'notes',
      position: cnDisplaySettings?.sections?.hp?.notes?.position
    },
    orders: cnDisplaySettings?.sections?.hp?.orders?.enabled && {
      title: 'Orders',
      path: 'orders',
      position: cnDisplaySettings?.sections?.hp?.orders?.position
    }
  };
}
