import { forwardRef } from 'react';

import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';

const FilterButton = forwardRef(
  ({ isPopoverOpen, device, btnClassName, count, alwaysShow, textClassName, ...props }, ref) => (
    <div
      ref={ref}
      data-qa="filter"
      {...props}
      className={cs(
        'relative flex justify-center items-center gap-1.5 bg-white border rounded px-2 py-0 border-solid border-neutral-200 h-[32px]',
        'hover:bg-neutral-50 hover:border-neutral-300 cursor-pointer',
        btnClassName,
        `${isPopoverOpen && '!border-neutral-500'}`
      )}>
      <Icon
        icon="new-sort-v2"
        color={alwaysShow ? 'primary' : 'neutral'}
        stroke={alwaysShow ? 'primary' : 'neutral'}
        shade={600}
        className="cursor-pointer"
      />
      {(device === 'desktop' || device === 'laptop' || alwaysShow) && (
        <span
          className={cs(
            'font-medium text-sm leading-[114%] text-neutral-700 select-none',
            textClassName
          )}>
          Filter
        </span>
      )}
      {count !== 0 && (
        <span className="flex h-[20px] w-[20px] items-center justify-center rounded-[4px] bg-primary-400 text-xs font-700 text-white">
          {count}
        </span>
      )}
    </div>
  )
);

export default FilterButton;
