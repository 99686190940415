import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useOutletContext } from 'react-router-dom';

import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';
import List from './List';
import { TYPE_OF_NOTES } from '../../../../constants';
import { NotesPopover } from './NotesPopover';

const TypeOfNotesSidebar = ({ className }) => {
  const { enabledNotes = [] } = useOutletContext();
  const [typeOfNotes, setTypeOfNotes] = useState([]);

  useEffect(() => {
    setTypeOfNotes(
      enabledNotes?.map((item) => ({
        ...item,
        type: item?.path,
        checked: !!item?.checked
      }))
    );
  }, []);

  const selectedTypeOfNotes = typeOfNotes?.filter((note) => !!note?.checked);

  const onSelectTypeOfNotes = (e, note) => {
    setTypeOfNotes((prev) =>
      prev.map((item) => ({
        ...note,
        ...item,
        checked: note?.type === item.type ? e : item?.checked
      }))
    );
  };

  const headerButtons = [
    // { id: 1, component: <Icon icon="new-reset" />, tooltip: 'Reset selection' },
    // { id: 2, component: <Icon icon="save-changes" />, tooltip: 'Reset selection' },
    {
      id: 3,
      component: (
        <NotesPopover
          buttonContent={<Icon icon="new-add-square" />}
          className="p-0"
          position="bottom"
          isFixed
          options={TYPE_OF_NOTES}
          icon="new-add-square">
          {typeOfNotes.map((option) => (
            <div className="flex justify-between" key={option.type}>
              <div>{option.label}</div>
              <Switch
                onChange={(e) => onSelectTypeOfNotes(e, option)}
                checked={option?.checked}
                data-qa="note-choose-switch"
              />
            </div>
          ))}
        </NotesPopover>
      ),
      tooltip: 'Reset selection'
    }
  ];

  return (
    <div
      className={cs(
        'fixed  z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        className,
        'w-[264px]'
      )}>
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              <Tippy content={button.tooltip} placement="top" className="tippy-dark">
                {button.component}
              </Tippy>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[calc(100vh-3rem)] overflow-y-auto pb-28">
        {selectedTypeOfNotes?.map((selectedNote) => (
          <div key={selectedNote.type}>
            <div
              className="relative flex items-center gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 font-500 text-primary-600 transition-all
        ">
              {selectedNote?.label}
            </div>
            <List open={true} type={selectedNote.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeOfNotesSidebar;
