import { showAlert } from 'components/shared/Alert/Alert';
import { ia, iaRa, spaceToKebabCase } from 'lib/helpers/utility';
import { kebabCase } from 'lodash';

export const shouldNavigateToForm = ({
  macro = null,
  macroType = null,
  patientId,
  appointmentId,
  type,
  cnDisplaySettings = {},
  navigate,
  advancedHP,
  advancedSOAP,
  customFormTypes
}) => {
  if (!macro || macroType !== 'form_macro') return;

  const parsedMacro = typeof macro?.body == 'string' ? JSON.parse(macro?.body) : macro?.body;

  const isAdvancedMacro =
    parsedMacro?.advancedHP ?? parsedMacro?.advancedSOAP ?? parsedMacro?.advancedCustomNote;

  if (isAdvancedMacro) {
    const formName = `${spaceToKebabCase(isAdvancedMacro?.form?.name || isAdvancedMacro?.name)}-${
      isAdvancedMacro?.form_id || isAdvancedMacro?.id
    }`;

    const foundCustomTitle = findCustomTitle({
      stringToCompare: formName,
      arr1: advancedHP,
      arr2: advancedSOAP,
      obj: customFormTypes
    });

    if (!foundCustomTitle) {
      return false;
    }

    navigateToForm({
      patientId,
      appointmentId,
      section: getRedirectPathAdvForms({
        formType: type,
        advancedHP: !!parsedMacro?.advancedHP,
        advancedSOAP: !!parsedMacro?.advancedSOAP,
        advancedCustomNote: parsedMacro?.advancedCustomNote,
        customNoteType: parsedMacro?.customNoteType,
        cnDisplaySettings
      }),
      form: formName,
      navigate
    });

    return true;
  }

  let formName = Object.keys(parsedMacro?.clinicalNote)?.[0];

  const isComplaints = ['present_illness', 'other_complaints'].includes(formName);
  if (isComplaints) {
    formName = 'complaints';
  }

  const isEPN = ['epn'].includes(formName);
  if (isEPN) {
    formName = Object.keys(parsedMacro?.clinicalNote?.epn)?.[0];
  }

  const isMHA = ['mha'].includes(formName);
  if (isMHA) {
    formName = Object.keys(parsedMacro?.clinicalNote?.mha)?.[0];
  }

  if (!formName) return;

  navigateToForm({
    patientId,
    appointmentId,
    section: getRedirectPathSimpleForms({
      formName,
      formType: type,
      cnDisplaySettings
    }),
    form: kebabCase(formName),
    navigate
  });

  return true;
};

const findCustomTitle = ({ stringToCompare, arr1, arr2, obj }) => {
  const searchArray = (arr) => arr.some((item) => item?.custom_title === stringToCompare);

  if (searchArray(arr1) || searchArray(arr2)) {
    return true;
  }

  for (const key in obj) {
    if (ia(obj?.[key]) && searchArray(obj[key])) {
      return true;
    }
  }

  return false;
};

const findEnabledPaths = ({ enabledNotes, paths }) => {
  return paths.filter((path) => iaRa(enabledNotes).find((note) => note.path === path));
};

const getRedirectPathAdvForms = ({
  formType,
  advancedHP,
  advancedSOAP,
  advancedCustomNote,
  customNoteType = null,
  cnDisplaySettings
}) => {
  const enabledNotes = [
    cnDisplaySettings?.hp?.enabled && { path: `hp` },
    cnDisplaySettings?.mha?.enabled && { path: `intake` },
    cnDisplaySettings?.soap?.enabled && { path: `soap` },
    cnDisplaySettings?.epn?.enabled && { path: `progress` },
    cnDisplaySettings?.chart3d?.enabled && { path: `3d` },
    cnDisplaySettings?.attachments?.enabled && { path: `attachments` },
    cnDisplaySettings?.myscribe?.enabled && { path: `myscribe` }
  ].filter(Boolean);

  const advHPEnabled = findEnabledPaths({ enabledNotes, paths: ['hp', 'intake'] });
  const advSOAPEnabled = findEnabledPaths({ enabledNotes, paths: ['soap', 'progress'] });

  if (advancedHP) {
    if (!ia(advHPEnabled)) {
      showAlert({
        title: 'No enabled sections',
        message:
          'Please enable at least one section (HP, INTAKE) in the admin clinical note settings to navigate to advanced form.',
        color: 'warning'
      });

      return;
    }

    if (advHPEnabled.includes(formType)) {
      return formType;
    }

    return advHPEnabled[0];
  }

  if (advancedSOAP) {
    if (!ia(advSOAPEnabled)) {
      showAlert({
        title: 'No enabled sections',
        message:
          'Please enable at least one section (SOAP, PROGRESS) in the admin clinical note settings to navigate to advanced form.',
        color: 'warning'
      });

      return;
    }

    if (advSOAPEnabled.includes(formType)) {
      return formType;
    }

    return advSOAPEnabled[0];
  }

  if (advancedCustomNote) {
    return customNoteType;
  }

  return null;
};

const getRedirectPathSimpleForms = ({ formName, formType, cnDisplaySettings }) => {
  const sectionAvailability = [
    cnDisplaySettings?.hp?.enabled && `hp`,
    cnDisplaySettings?.mha?.enabled && `intake`,
    cnDisplaySettings?.soap?.enabled && `soap`,
    cnDisplaySettings?.epn?.enabled && `progress`,
    cnDisplaySettings?.chart3d?.enabled && `3d`,
    cnDisplaySettings?.attachments?.enabled && `attachments`,
    cnDisplaySettings?.myscribe?.enabled && `myscribe`
  ].filter(Boolean);

  const sections = cnDisplaySettings?.sections;

  const enabledSections = Object.fromEntries(
    Object.entries(sections).filter(([key]) => sectionAvailability.includes(key))
  );

  const orderedSections = {
    ...(formType in enabledSections ? { [formType]: enabledSections[formType] } : {}),
    ...Object.fromEntries(Object.entries(enabledSections).filter(([key]) => key !== formType))
  };

  return findParentSection({ formName, settings: orderedSections });
};

const findParentSection = ({ formName, settings }) => {
  for (const [section, forms] of Object.entries(settings)) {
    for (const [form, details] of Object.entries(forms)) {
      if (kebabCase(form) === kebabCase(formName)) {
        return section;
      }
    }
  }
  return null;
};

const navigateToForm = ({ patientId, appointmentId, section, form, navigate = () => null }) => {
  if (!patientId || !appointmentId || !section || !form) return;

  navigate(`/portal/charts/${patientId}/clinical-notes/${appointmentId}/${section}/${form}`);
};
