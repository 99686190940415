import React, { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useEra } from 'lib/hooks/queries/era/useEra';

import { arrayToObject, iaRa } from '../../helpers/utility';
import { useUnmatchedClaims } from '../../hooks/queries/claims/useUnmatchedClaims';
import { useCodes } from '../../hooks/queries/codes/useCodes';

import { EraContext } from './EraContext';

export const EraContextProvider = ({ children }) => {
  const [amount, setAmount] = useState(0);
  const [procedures, setProcedures] = useState([]);
  const [statusCode, setStatusCode] = useState(null);
  const [selectedClaim, setSelectedClaim] = useState();
  const [allocateEob, setAllocateEob] = useState(false);
  const [selectedUnmatchedClaim, setSelectedUnmatchedClaim] = useState();

  const { id } = useParams();

  const { data, isLoading: isEraLoading } = useEra({
    params: { id },
    dependencies: [id]
  });

  const { data: codesData } = useCodes({
    params: { services: ['adjustment_reason', 'claim_adjustment_reason'] }
  });

  const { data: unmatchedClaimsData, isLoading: isUnmatchedClaimsLoading } = useUnmatchedClaims({
    params: { patientId: selectedClaim?.claim?.pcn },
    dependencies: [selectedClaim?.claim?.pcn],
    options: { enabled: !!selectedClaim?.claim?.pcn }
  });

  const unmatchedClaims = useMemo(
    () =>
      (unmatchedClaimsData?.claims || []).map((claim) => {
        const procedures = (claim?.invoice?.procedures || []).map((procedure) => {
          const adjustment = selectedClaim?.claim?.charge?.find(
            (_charge) => _charge.proc_code === procedure.procedure_code
          )?.adjustment;
          return { ...procedure, adjustment };
        });

        return {
          ...claim,
          invoice: { ...claim.invoice, procedures }
        };
      }),
    [unmatchedClaimsData, selectedClaim]
  );

  const codes = useMemo(
    () => arrayToObject(iaRa(codesData?.codes), 'code', 'description'),
    [codesData?.codes]
  );

  const handleClaimChange = useCallback(
    (event) => {
      const claimId = parseInt(event.target.value);
      setSelectedUnmatchedClaim(claimId);

      const claim = unmatchedClaims.find((c) => c.id === claimId);
      setProcedures(iaRa(claim?.invoice?.procedures));
    },
    [unmatchedClaims, setSelectedUnmatchedClaim, setProcedures]
  );

  return (
    <EraContext.Provider
      value={{
        codes,
        era: data?.era,
        isEraLoading,
        selectedUnmatchedClaim,
        setSelectedUnmatchedClaim,
        isUnmatchedClaimsLoading,
        procedures,
        amount,
        setAmount,
        setProcedures,
        statusCode,
        setStatusCode,
        unmatchedClaims,
        selectedClaim,
        setSelectedClaim,
        allocateEob,
        setAllocateEob,
        handleClaimChange
      }}>
      {children}
    </EraContext.Provider>
  );
};
