import React from 'react';
import { sanitize } from 'lib/helpers/sanitize';
import cs from 'classnames';
import './style.scss';

const RenderNarrativeValue = ({ narrative, className }) => {
  if (!narrative) return null;

  return (
    <pre
      dangerouslySetInnerHTML={sanitize(narrative)}
      className={cs(
        className,
        'print-wrapper render-narrative-text w-full overflow-visible whitespace-pre-wrap break-normal !border-none bg-transparent !p-0 !text-sm font-500 text-[#003A4B] print:text-base'
      )}
    />
  );
};

export default RenderNarrativeValue;
