import { Popover } from '@headlessui/react';
import React from 'react';
import Tippy from '@tippyjs/react';
import moment from 'moment-timezone';
import { Calendar } from 'react-date-range';

import TimeSlots from './TimeSlots';
import AdditionalTime from './AdditionalTimes';
import Icon from '../../../../shared/Icon/Icon';
import Input from '../../../../shared/Input/Input';
import { ia } from '../../../../../lib/helpers/utility';
import { useNewAppointmentContext } from '../../../../../lib/context/Appointment/NewAppointmentContext';
import Select from '../../../../shared/Select/Select';
import { timeFilterOption } from '../../appointmentHelpers';

const AppointmentDateAndTime = ({
  practice,
  dateAndTime,
  handleDateTimeChange,
  onClickAvailableDate,
  addAdditionalTime,
  additionalTimes,
  hours,
  deleteTime,
  onClickAdditionalDate,
  handleLoadMore,
  practiceTimezone,
  selectAppointmentLocation
}) => {
  const { formik } = useNewAppointmentContext();

  function hasEqualQtySales(procedures) {
    for (const procedure of procedures) {
      if (procedure.quantity === procedure.sales_count + 1 + additionalTimes?.length) return true;
    }
    return false;
  }

  return (
    <div className="rounded-lg border border-solid border-neutral-100 !p-4 shadow-[0px_0px_8px_0px__rgba(0,79,107,0.08)]">
      <div className="!mb-4">
        <div className="flex items-end gap-[10px]">
          <Popover className="relative w-8/12">
            {({ close }) => (
              <>
                <Popover.Button className="w-full p-0">
                  <Input
                    data-qa="appointment-date"
                    label="Appointment date"
                    rightIcon="new-calendar-gray"
                    placeholder={moment
                      .tz(dateAndTime.dateTime, practice?.timezone)
                      .format('M/D/YYYY, dddd')}
                    error={formik.errors.startsAt}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute z-10 flex w-full shadow-md">
                  <Calendar
                    showDateDisplay
                    className="w-full"
                    onChange={(date) => {
                      close();
                      onClickAvailableDate({ date });
                    }}
                    date={
                      new Date(moment.tz(dateAndTime?.dateTime, practice?.timezone) || new Date())
                    }
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>

          <Select
            label="Time"
            isClearable={false}
            rightIcon="new-clock-gray"
            filterOption={timeFilterOption}
            parentClassName="relative w-4/12"
            options={hours.map((hour) => ({ value: hour, label: hour }))}
            onChange={(val) => handleDateTimeChange({ newTime: val.value, type: 'time' })}
            value={{
              label: moment.tz(dateAndTime.dateTime, practiceTimezone).format('hh:mm A'),
              value: moment.tz(dateAndTime.dateTime, practiceTimezone).format('hh:mm A')
            }}
          />

          <Tippy
            content={
              hasEqualQtySales(formik.values.procedures)
                ? "Sorry, you can't add that due to missing procedures in your packages."
                : formik.values.procedures.length === 0
                  ? 'Please select a procedure'
                  : 'Repeat appointment'
            }
            className="tippy-dark ">
            <div className="flex h-10">
              <Icon
                icon="new-add-circle"
                onClick={addAdditionalTime}
                data-qa="repeat-appointment-btn"
                disabled={hasEqualQtySales(formik.values.procedures)}
              />
            </div>
          </Tippy>
        </div>

        {formik.errors.startsAt && <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.startsAt}</span>}

        {ia(dateAndTime.locationAvailableSlots) && (
          <TimeSlots
            day={dateAndTime}
            handleLoadMore={handleLoadMore}
            timeZone={practice?.timezone}
            handleClick={handleDateTimeChange}
            type="location"
            selectAppointmentLocation={selectAppointmentLocation}
            appointmentLocation={formik.values.appointmentLocation}
          />
        )}
        {ia(dateAndTime.virtualAvailableSlots) && (
          <TimeSlots
            day={dateAndTime}
            handleLoadMore={handleLoadMore}
            timeZone={practice?.timezone}
            handleClick={handleDateTimeChange}
            selectAppointmentLocation={selectAppointmentLocation}
            type="virtual"
            appointmentLocation={formik.values.appointmentLocation}
          />
        )}
      </div>

      {ia(additionalTimes) &&
        additionalTimes.map((time, index) => {
          return (
            <div className="!mb-4" key={index}>
              <AdditionalTime
                key={index}
                hours={hours}
                times={time}
                index={index}
                deleteTime={deleteTime}
                practice={practice}
                handleDateTimeChange={handleDateTimeChange}
                onClickAdditionalDate={onClickAdditionalDate}
              />
              {ia(time.locationAvailableSlots) && (
                <TimeSlots
                  day={time}
                  index={index}
                  handleLoadMore={handleLoadMore}
                  timeZone={practice?.timezone}
                  handleClick={handleDateTimeChange}
                  type="location"
                  selectAppointmentLocation={selectAppointmentLocation}
                  appointmentLocation={formik.values.appointmentLocation}
                />
              )}
              {ia(time.virtualAvailableSlots) && (
                <TimeSlots
                  day={time}
                  index={index}
                  handleLoadMore={handleLoadMore}
                  timeZone={practice?.timezone}
                  handleClick={handleDateTimeChange}
                  type="virtual"
                  appointmentLocation={formik.values.appointmentLocation}
                  selectAppointmentLocation={selectAppointmentLocation}
                />
              )}
            </div>
          );
        })}
    </div>
  );
};

export default AppointmentDateAndTime;
