export const ALLOWED_PRINT_EXPORT_CATEGORIES = {
  ledgers: { print: true, export: true, withTotals: true, show: true },
  payments: { print: true, export: true, withTotals: true, show: true },
  era: { print: true, export: true, withTotals: false, show: true },
  eob: { print: true, export: true, withTotals: false, show: true },
  claims: { print: true, export: true, withTotals: true, show: true },
  invoices: { print: true, export: true, withTotals: true, show: true },
  services: { print: true, export: true, withTotals: true, show: true },
  superbills: { print: true, export: true, withTotals: true, show: true }
};
