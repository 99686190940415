import endsOptions from './endsOptions';
import weekdays from './weekdays';

export default function (setFieldValue, val) {
  setFieldValue('repeat_event', val);
  if (!val?.value) {
    setFieldValue('repeat_every', 1);
    setFieldValue('weekdays', []);
    setFieldValue('ends_type', null);
    setFieldValue('end_date', null);
  } else {
    setFieldValue('ends_type', endsOptions[0]);
  }

  if (val?.value === 'monthly') {
    setFieldValue('repeat_on', 'day');
  }

  if (val?.label === 'Daily' || val?.label === 'Weekly') {
    setFieldValue('weekdays', weekdays);
  }
}
