import React, { useEffect, useRef, useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import { useRecoilState } from 'recoil';
import { useFormikContext } from 'formik';
import { useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import state from 'components/state';
import Select from 'components/shared/Select/Select';
import Button from 'components/shared/Buttons/Button';
import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import { Capitalize, formatDate, ia, iaRa, io, mString } from 'lib/helpers/utility';

const defaultCostShare = {
  deductible: { checked: false, value: 0 },
  coPayment: { checked: false, value: 0 },
  coInsurance: { checked: false, value: 0 }
};

// TODO: Refactor
const Calculate = ({ isPined, timezone }) => {
  const queryClient = useQueryClient();
  const hasRun = useRef(false);
  const { values, setFieldValue } = useFormikContext();
  const [costShareState, setCostShareState] = useRecoilState(state?.costShareState);
  const [loading, setLoading] = useState(false);
  const [calculateAttention, setCalculateAttention] = useState(false);

  const { data } = usePatient({ params: { id: values?.user_id }, dependencies: [values?.user_id] });
  const insuranceProfiles = iaRa(data?.patient?.insuranceProfile);
  const insProfile = insuranceProfiles.find(
    (item) => item.id === values?.invoice?.last_applied?.ins_profile_id
  );

  const insuranceBenefits = data?.patient?.insurance_benefits || {};

  const currentType = values?.invoice?.last_applied?.type;
  const currentInsuranceType = values?.invoice?.last_applied?.insurance_type;

  const { deductible, co_payment, co_insurance } =
    insuranceBenefits?.[currentInsuranceType]?.cost_share || {};
  const getDeductible = { eligibility: deductible, insurance: insProfile?.deductible };
  const getCoPayment = { eligibility: co_payment, insurance: insProfile?.copay };
  const getCoInsurance = { eligibility: co_insurance, insurance: insProfile?.co_insurance };

  useEffect(() => {
    if (!io(data?.patient) || hasRun.current) return;

    setCostShareState({
      deductible: { checked: false, value: getDeductible[currentType] || 0 },
      coPayment: { checked: false, value: getCoPayment[currentType] || 0 },
      coInsurance: { checked: false, value: getCoInsurance[currentType] || 0 }
    });

    hasRun.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.patient]);

  const eligibleTypes = ['primary', 'secondary', 'tertiary'];
  const hasCoPayType = (values?.invoice.procedures || [])?.some(
    ({ type, procedure_code }) => type === 'primary' && procedure_code
  );

  const isEligibilityValid = insuranceBenefits?.primary?.valid ?? false;

  const insBenefits = Object.entries(insuranceBenefits)
    .filter(([type]) => eligibleTypes.includes(type))
    .reverse();

  let options = insuranceProfiles.map((item) => ({
    value: item.id,
    type: 'insurance',
    label: item.payer_name,
    description: Capitalize(item.type),
    isDisabled: !hasCoPayType
  }));

  if (!ia(insBenefits)) {
    options.unshift({
      type: 'eligibility',
      value: 'eligibility',
      isValid: false,
      isPresent: false,
      isDisabled: true,
      label: 'Live eligibility '
    });
  } else {
    insBenefits.forEach(([type]) => {
      const { valid, retrieved, eligibility_run } = insuranceBenefits?.[type] || {};

      options.unshift({
        type: `eligibility`,
        value: type,
        isValid: valid,
        isPresent: retrieved,
        description: Capitalize(type),
        isDisabled: !hasCoPayType || !valid || !retrieved,
        label: `Live eligibility (${formatDate(eligibility_run, timezone, true)})`
      });
    });
  }

  options.push({ value: 'self_pay', type: 'self_pay', label: 'Self Pay' });

  const formatOption = ({ label, type, description, isDisabled, isValid, isPresent }) => {
    let content = 'Mark at least one of the services as an insurance type.';

    const isEligibility = type === 'eligibility';

    if (isEligibility && !isPresent) {
      content = 'Run Eligibility from Pricing Protocol toolbar for live data';
    } else if (isEligibility && !isValid) {
      content = 'No live eligibility response available';
    }

    const disabled =
      type == 'self_pay' ||
      (type === 'insurance' && hasCoPayType) ||
      (isEligibility && hasCoPayType && isValid);

    return (
      <Tippy content={content} hideOnClick={false} className="tippy-dark" disabled={disabled}>
        <div className={cs('relative w-full px-3 py-2')}>
          <div
            data-qa={`option-${label}`}
            className={cs(
              'relative flex w-full items-center justify-between gap-4',
              isDisabled && ' [&_span]:!text-neutral-400'
            )}>
            <span className="truncate text-sm text-neutral-900">{label}</span>
            <span className="text-sm italic text-neutral-600">{description}</span>
          </div>
        </div>
      </Tippy>
    );
  };

  const handleCheckboxChange = (key) => {
    setCalculateAttention(true);

    setCostShareState((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        checked: !prevState[key].checked
      }
    }));
  };

  const handleOnChangeCalculate = (option) => {
    setFieldValue('calculate', option);
    setCalculateAttention(true);

    if (option?.type === 'insurance') {
      const insuranceProfile = insuranceProfiles.find((item) => item.id === option?.value);

      if (io(insuranceProfile)) {
        const { deductible, copay, co_insurance } = insuranceProfile || {};

        setCostShareState({
          deductible: { checked: true, value: deductible || 0 },
          coPayment: { checked: true, value: copay || 0 },
          coInsurance: { checked: true, value: co_insurance || 0 }
        });
      }
    } else if (option?.type === 'eligibility') {
      const { deductible, co_payment, co_insurance } =
        data?.patient?.insurance_benefits?.[currentInsuranceType]?.cost_share || {};

      setCostShareState({
        deductible: { checked: true, value: deductible || 0 },
        coPayment: { checked: true, value: co_payment || 0 },
        coInsurance: { checked: true, value: co_insurance || 0 }
      });
    } else {
      setCostShareState(defaultCostShare);
    }
  };

  const handleReCalculate = async () => {
    setLoading(true);

    const onSuccess = async () => {
      setLoading(false);

      await queryClient.invalidateQueries(['appointmentv2', values?.id]);
      await queryClient.invalidateQueries(['appointment', values?.id]); // TEMPORARY: this will invalidate the query for old appointment preview

      setTimeout(() => {
        setFieldValue('isPaymentActive', true);
      }, 1000);

      showAlert({ message: 'Re-Calculate successfully.', color: 'success' });

      setCalculateAttention(false);
      setCostShareState((prevState) => ({
        deductible: { ...prevState.deductible, checked: false },
        coPayment: { ...prevState.coPayment, checked: false },
        coInsurance: { ...prevState.coInsurance, checked: false }
      }));
    };

    const onError = (error) => {
      setLoading(false);
      showAlert({ message: error, color: 'danger' });
    };

    await requestApi({
      onError,
      onSuccess,
      url: '/api/appointment/calculate',
      params: {
        costShare: costShareState,
        appointmentId: values?.id,
        type: values?.calculate?.type || values?.calculate?.value || currentType,
        ...(values?.calculate?.type === 'eligibility' && {
          insuranceType: values?.calculate?.value
        }),
        insuranceProfile: insuranceProfiles.find(
          (item) => item.id === values?.calculate?.value || item?.type === 'primary'
        )
      }
    });
  };

  const hasLastApplied = () => {
    const { type, ins_profile_id } = values?.invoice?.last_applied || {};

    return (
      type === 'self_pay' ||
      (type === 'insurance' && ins_profile_id) ||
      (type === 'eligibility' && isEligibilityValid)
    );
  };

  const isSelfPay =
    (!values?.calculate && currentType === 'self_pay') || values?.calculate?.value === 'self_pay';

  const selectedValue = options.find((item) => {
    return (
      item?.value ===
      (values?.calculate?.value ||
        (isSelfPay && 'self_pay') ||
        (hasCoPayType && values?.invoice?.last_applied?.ins_profile_id) ||
        (hasCoPayType && isEligibilityValid && currentInsuranceType)) //check for each type
    );
  });

  if (!ia(data?.patient?.insuranceProfile))
    return (
      <NotFound
        className="px-11"
        title="No insurance profile"
        textWrapperClassName="!mt-1"
        icon="new-no-income-illustration"
        description="Please add an insurance profile to enable live eligibility and patient portion calculation"
      />
    );

  const hasCostShare = Object.values(costShareState).some((i) => i?.checked);
  const checkedEveryCostShare = Object.values(costShareState).every((i) => i?.checked);

  const isActive = (isSelfPay || hasCostShare) && (calculateAttention || checkedEveryCostShare);

  return (
    <div className={cs('flex flex-col justify-between px-4 pt-[10px]', !isPined && 'h-[256px] ')}>
      <div className="flex flex-col gap-3">
        <Select
          options={options}
          isClearable={false}
          isSearchable={false}
          value={selectedValue}
          id="pricing-calculate"
          label="Calculate using"
          menuPortalTarget={document.body}
          formatOptionLabel={formatOption}
          onChange={(value) => handleOnChangeCalculate(value)}
          styles={{ option: { padding: 0 }, singleValue: { margin: '0 -12px !important' } }}
        />
        {(hasLastApplied() || values?.calculate?.value) && !isSelfPay && (
          <div className="mb-2 grid gap-[10px] rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] p-3 pr-[10px]">
            <div className="flex items-center justify-between gap-4">
              <Checkbox
                label="Deductible"
                size={20}
                isChecked={costShareState?.deductible?.checked}
                onChange={() => handleCheckboxChange('deductible')}
              />
              <span className="text-sm font-500 text-neutral-800">
                {mString(costShareState?.deductible?.value)}
              </span>
            </div>

            <div className="flex items-center justify-between gap-4">
              <Checkbox
                label="Co-Payment"
                size={20}
                isChecked={costShareState?.coPayment?.checked}
                onChange={() => handleCheckboxChange('coPayment')}
              />
              <span className="text-sm font-500 text-neutral-800">
                {mString(costShareState?.coPayment?.value)}
              </span>
            </div>

            <div className="flex items-center justify-between gap-4">
              <Checkbox
                label="Co-Insurance"
                size={20}
                isChecked={costShareState?.coInsurance?.checked}
                onChange={() => handleCheckboxChange('coInsurance')}
              />
              <span className="text-sm font-500 text-neutral-800">
                {costShareState?.coInsurance?.value / 100}%
              </span>
            </div>
          </div>
        )}
      </div>

      {(hasLastApplied() || values?.calculate?.value) && (
        <Button
          shade={loading && '700'}
          size="small"
          iconColor="white"
          outlined={!isActive}
          loadingIconRight={loading}
          onClick={handleReCalculate}
          text="Re-Calculate Patient Portion"
          iconRight={!loading && 'new-arrow-right-v2'}
          disabled={loading || (!isSelfPay && !hasCostShare)}
          className={cs(
            'ml-auto h-[32px] gap-[6px] whitespace-nowrap',
            isActive && 'animate-shine bg-gradient-to-r from-[#13b8ff] to-[#098cc1]'
          )}
        />
      )}
    </div>
  );
};

export default Calculate;
