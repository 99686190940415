import { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { iaRa, optionify } from 'lib/helpers/utility';
import { useGuarantors } from 'lib/hooks/queries/guarantors/useGuarantors';
import { useStates } from 'lib/hooks/queries/states/useStates';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import Button from 'components/shared/Buttons/Button';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import EditGuarantor from './EditGuarantor';
import Guarantor from './Guarantor';
import GuarantorMobile from './GuarantorMobile';
import NewGuarantor from './NewGuarantor';

export default function Guarantors({
  patientId,
  selected,
  setSelected,
  practiceId,
  practitionerId
}) {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [showNewGuarantor, setShowNewGuarantor] = useState(false);
  const [showEditGuarantor, setShowEditGuarantor] = useState(null);
  const [isOpen, setIsOpen] = useState(initialIsOpenState);
  const { device } = useUIContext();
  const queryClient = useQueryClient();
  const { data: guarantors } = useGuarantors({
    params: { userId: patientId },
    options: {
      select: (data) => iaRa(data?.guarantors)
    }
  });
  const initialIsOpenState = guarantors?.reduce((acc, curr) => {
    acc[curr.id] = false;
    return acc;
  }, {});

  const { data: statesQuery } = useStates();
  const states = optionify(statesQuery?.states);

  const handleNewGuarantor = () => {
    blockScroll();
    setShowNewGuarantor(true);
  };
  const hideNewGuarantor = () => {
    allowScroll();
    setShowNewGuarantor(false);
  };

  const handleEditGuarantor = (data, index) => {
    blockScroll();
    setShowEditGuarantor({ data, index });
  };
  const hideEditGuarantor = () => {
    allowScroll();
    setShowEditGuarantor(null);
  };

  const addGuarantor = (newGuarantor) => {
    const newGuarantors = [...guarantors, newGuarantor];
    handleModifyGuarantors(newGuarantors);
  };

  const updateGuarantor = (updatedGuarantor, index) => {
    const guarantorsCopy = [...guarantors];
    guarantorsCopy[index] = updatedGuarantor;
    handleModifyGuarantors(guarantorsCopy);
  };

  const deleteGuarantor = (guarantor) => {
    const filteredGuarantor = guarantors.filter((i) => i.id !== guarantor.id);
    handleModifyGuarantors(filteredGuarantor);
  };

  const handleModifyGuarantors = (guarantors) => {
    queryClient.setQueryData(['guarantors', patientId], (prevData) => ({
      ...prevData,
      guarantors
    }));
  };

  const toggleOne = (key) => {
    setIsOpen({ ...isOpen, [key]: isOpen && !isOpen[key] });
  };

  if (!Array.isArray(guarantors)) {
    return (
      <div className="mt-3 w-full">
        <Skeleton count={8} />
      </div>
    );
  }

  return (
    <div className="h-full" data-dd-privacy="allow">
      <>
        <div className="flex justify-between">
          <label className="text-lg font-600">My Guarantors</label>
          {guarantors?.length < 5 && (
            <Button
              size="small"
              type="secondary"
              text="New Guarantor"
              onClick={handleNewGuarantor}
              data-qa="practice-charts-guarantor-NewGuarantorButton"
            />
          )}
        </div>

        <div className={cs('gap-4 !pb-8 grid grid-cols-2 xs:flex xs:flex-col pt-4')}>
          {guarantors?.map((guarantor, index) => {
            return device === 'mobile' ? (
              <GuarantorMobile
                key={index}
                guarantor={guarantor}
                index={index}
                selected={selected}
                setSelected={setSelected}
                handleEditGuarantor={handleEditGuarantor}
                deleteGuarantor={deleteGuarantor}
                isOpen={isOpen}
                toggleOne={toggleOne}
              />
            ) : (
              <Guarantor
                key={index}
                guarantor={guarantor}
                index={index}
                handleEditGuarantor={handleEditGuarantor}
                selected={selected}
                setSelected={setSelected}
                deleteGuarantor={deleteGuarantor}
              />
            );
          })}
        </div>
      </>
      {showNewGuarantor && (
        <NewGuarantor
          addGuarantor={addGuarantor}
          patientId={patientId}
          practitionerId={practitionerId}
          showNewGuarantor={showNewGuarantor}
          hideNewGuarantor={hideNewGuarantor}
          practiceId={practiceId}
          guarantors={guarantors}
          states={states}
        />
      )}

      {!!showEditGuarantor && (
        <EditGuarantor
          updateGuarantor={updateGuarantor}
          index={showEditGuarantor?.index}
          practiceId={practiceId}
          practitionerId={practitionerId}
          guarantor={showEditGuarantor?.data}
          patientId={patientId}
          showEditGuarantor={showEditGuarantor}
          hideEditGuarantor={hideEditGuarantor}
          selected={selected}
          setSelected={setSelected}
          states={states}
        />
      )}
    </div>
  );
}
