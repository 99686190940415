import React from 'react';

const HtmlRenderer = ({ value }) => {
  const modifiedValue = value?.replace(/["\\/]/g, '');
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: modifiedValue
      }}
    />
  );
};

export default HtmlRenderer;
