import { requestApi } from './Api';

export const confirmKioskCode = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/portal/confirm',
    params
  });
};

export const addKiosk = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/portal/add',
    params
  });
};

export const getKiosks = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/portal/list',
    params: params || {}
  });
};

export const deleteKiosk = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/portal/delete',
    params
  });
};

export const getKiosk = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/get',
    params
  });
};

export const requestAssistance = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/requests/request',
    params
  });
};

export const kioskRequests = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/requests/list',
    params
  });
};

export const kioskReadRequest = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/requests/read',
    params
  });
};

export const kioskStartCheckin = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/portal/checkin_start',
    params
  });
};

export const kioskPaymentStop = async (navigate, params = {}) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/payment_stop',
    params
  });
};

export const kioskCommand = async (navigate, params) => {
  return await requestApi({
    navigate,
    url: '/api/kiosk/command',
    params
  });
};

export const enableTerminalPairing = async (navigate, params) => {
  return kioskCommand(navigate, { command: 'pair-terminal', kioskId: params?.kioskId });
};

export const disableTerminalPairing = async (navigate, params) => {
  return kioskCommand(navigate, { command: 'pair-terminal-exit', kioskId: params?.kioskId });
};
