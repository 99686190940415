exports.reorder = (obj, startIndex, endIndex) => {
  const keys = Object.keys(obj);
  const [removedKey] = keys.splice(startIndex, 1);
  keys.splice(endIndex, 0, removedKey);
  const reorderedObj = {};
  keys.forEach((key, index) => {
    reorderedObj[key] = { ...obj[key], position: index };
  });
  return reorderedObj;
};

const findParentObj = (data, idToFind) => {
  for (const key in data) {
    const nestedArray = data[key].formType;
    const foundItem = nestedArray.find((item) => item.id === idToFind);

    if (foundItem) {
      return key;
    }
  }
};

exports.reorderChildren = (obj, startIndex, endIndex, parentKey) => {
  const parentObjKey = findParentObj(obj, parentKey);

  if (!parentObjKey) return obj;

  const formTypeArray = obj[parentObjKey].formType.slice();
  const [removedItem] = formTypeArray.splice(startIndex, 1);
  formTypeArray.splice(endIndex, 0, removedItem);

  const orderedFormType = formTypeArray.map((form, index) => ({
    ...form,
    position: index
  }));

  obj[parentObjKey].formType = orderedFormType;

  return obj;
};

exports.getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
  left: isDragging && 'auto !important'
});

exports.getListStyle = (isDraggingOver) => ({
  background: isDraggingOver && '#f8ffff',
  paddingRight: 8,
  marginRight: 8,
  marginBottom: 6,
  height: 'calc(100vh - 160px)',
  overflow: 'auto'
});

// exports.getListStyleChildren = (isDraggingOver) => ({
//   background: isDraggingOver && '#f8ffff',
//   // padding: '16px 20px 16px 2px',
//   // height: '50px',
//   overflow: 'auto'
// });
