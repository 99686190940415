/* eslint-disable max-lines */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { useFormik } from 'formik';
import { isEqual, pick } from 'lodash';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';

import { requestApi } from 'api/Api';
import { detectDocument, processDocument } from 'api/Document';
import { createTask } from 'api/Tasks';

import { getFilestackClient } from 'lib/clients/filestack/filestackClient';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { imagePath, optionify } from 'lib/helpers/utility';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import { usePayersCheckin } from 'lib/hooks/queries/payers/usePayersCheckin';
import { useStates } from 'lib/hooks/queries/states/useStates';
import useKeyboardStatus from 'lib/hooks/useKeyboardStatus';

import { userState } from 'components/Patient/checkin/state';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';

import Renderer from './components/Renderer';

const NewInsuranceProfile = ({
  patientId,
  showNewInsuranceProfile,
  hideNewInsuranceProfile,
  newProfile,
  practiceId,
  practitionerId,
  insuranceProfiles,
  kiosk,
  fakeModal
}) => {
  const { device } = useUIContext();
  const user = useRecoilValue(userState);
  const createInsuranceProfile = useRef();
  const [loading, setLoading] = useState(false);
  const { id } = useRecoilValue(currentPractice);
  const navigate = useNavigate();
  const [processing, setProcessing] = useState({ open: false });
  const [confirmClose, setConfirmClose] = useState(false);

  const [coords, setCoords] = useState({});

  const [zoomed, setZoomed] = useState(null);
  const [payers, setPayers] = useState([]);

  const isTablet = device === 'tablet';

  const isKeyboardOpen = useKeyboardStatus();

  const breadcrumb = useBreadcrumb({
    title: 'New insurance profile',
    name: 'start'
  });

  const { data } = usePayersCheckin({});

  useEffect(() => {
    setInsuranceType();
  }, []);

  useEffect(() => {
    if (data?.payers) {
      setPayers(data?.payers);
    }
  }, [data]);

  const { data: patientQuery } = usePatient({
    id: patientId
  });

  const patient = patientQuery?.patient;

  const { data: statesQuery } = useStates();
  const states = optionify(statesQuery?.states);

  const getPayerAddress = async (selected) => {
    try {
      if (!selected) {
        formik.setValues({
          ...formik.values,
          ...address,
          payer_phone: '',
          payer_name: '',
          payer_id: ''
        });
        return;
      }
      const payerId = selected ? selected.value : formik?.values?.payer_id;
      if (payerId === 'other') {
        return formik.setValues({
          ...formik.values,
          payer_name: payerId,
          payer_id: payerId
        });
      }

      const res = await requestApi({
        url: '/api/payers/read',
        params: {
          id: selected?.value || formik?.values?.payer_id
        },
        navigate
      });
      const payerName = selected ? selected.payer_name : formik?.values?.payer_name;
      let address = {
        address_id: null,
        address_ln_1: '',
        address_ln_2: '',
        zip: '',
        city: '',
        state: ''
      };
      let phone = '';
      if (res) {
        phone = res.payers.phone;
        const pAddress = res.payers.payerAddress;
        if (pAddress) {
          address = {
            ...address,
            ...pAddress
          };
        }
      }
      formik.setValues({
        ...formik.values,
        ...address,
        payer_phone: phone,
        payer_name: payerName,
        payer_id: payerId
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const NewInsuranceProfileValidationSchema = Yup.object().shape({
    payer_id: Yup.string().required('Insurance company required.'),
    type: Yup.string().required('Type required.'),
    member_id: Yup.string().required('Member id required.'),
    relation: Yup.string().required('Relation required.')
  });

  const formik = useFormik({
    initialValues: {
      payer_id: null,
      payer_name: '',
      type: '',
      member_id: '',
      group_number: '',
      plan_name: '',
      policy_start_date: null,
      policy_end_date: null,
      relation: '',
      ignore_warnings: false,
      insured_first_name: '',
      insured_middle_name: '',
      insured_last_name: '',
      insured_date_of_birth: null,
      other_relation_phone: '',
      card_front: '',
      card_back: '',
      copay: 0,
      deductible: 0,
      co_insurance: 0,
      secondary_copay: 0,
      // other_relation address
      relation_address: null,
      other_relation_address_ln_1: '',
      other_relation_address_ln_2: '',
      other_relation_city: '',
      other_relation_state: null,
      other_relation_zip: '',
      // payer_address
      address_ln_1: '',
      address_ln_2: '',
      city: '',
      state: null,
      zip: '',
      payer_phone: ''
    },
    // enableReinitialize: true,
    validationSchema: NewInsuranceProfileValidationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(true);
      const insurance_profile = {
        payer_id: values.payer_id,
        payer_name: values.payer_name,
        type: values.type,
        member_id: values.member_id,
        group_number: values.group_number,
        plan_name: values.plan_name,
        policy_start_date: moment(values.policy_start_date).isValid()
          ? values.policy_start_date
          : null,
        policy_end_date: moment(values.policy_end_date).isValid() ? values.policy_end_date : null,
        relation: values.relation,
        ignore_warnings: values.ignore_warnings,
        insured_first_name: values.insured_first_name,
        insured_middle_name: values.insured_middle_name,
        insured_last_name: values.insured_last_name,
        insured_date_of_birth: values.insured_date_of_birth,
        other_relation_phone: values.other_relation_phone,
        card_front: values.card_front,
        card_back: values.card_back,
        copay: values.copay,
        deductible: values.deductible,
        co_insurance: values.co_insurance,
        secondary_copay: null,
        relation_address: values.relation_address,
        prior_auth: values.prior_auth,
        address: {
          address_ln_1: values.other_relation_address_ln_1,
          address_ln_2: values.other_relation_address_ln_2,
          city: values.other_relation_city,
          state: values.other_relation_state,
          zip: values.other_relation_zip
        }
      };
      const payer = {
        payerAddress: {
          address_ln_1: values.address_ln_1,
          address_ln_2: values.address_ln_2,
          city: values.city,
          state: values.state,
          zip: values.zip
        },
        phone: values.payer_phone
      };
      await createNewInsuranceProfile(insurance_profile, payer);
      setSubmitting(false);
      resetForm();
    }
  });

  function setInsuranceType() {
    if (insuranceProfiles.length > 0) {
      formik.setFieldValue('type', 'secondary');
    } else {
      formik.setFieldValue('type', 'primary');
    }
  }

  const createNewInsuranceProfile = async (insuranceProfile, payer) => {
    try {
      setLoading(true);
      if (insuranceProfile) {
        if (insuranceProfile.relation === '18') {
          delete insuranceProfile.insured_first_name;
          delete insuranceProfile.insured_middle_name;
          delete insuranceProfile.insured_last_name;
          delete insuranceProfile.insured_date_of_birth;
          delete insuranceProfile.other_relation_phone;
          delete insuranceProfile.address;
        }

        const insuranceObject = {
          ...insuranceProfile,
          payer_id: insuranceProfile?.payer_id === 'other' ? null : insuranceProfile?.payer_id,
          patient_id: patientId,
          practice_id: id || practiceId
        };

        const res = await requestApi({
          url: '/api/insurance_profiles/create',
          params: insuranceObject,
          navigate
        });

        if (res?.created) {
          newProfile(res?.created);
          showAlert({ message: 'Successfully created!' });
        }

        if (!insuranceObject?.payer_id && practitionerId) {
          await createTask(navigate, {
            practiceId,
            task: {
              title: `Review insurance profile for patient ${user?.f_name} (Chart ID: ${user?.id})`,
              category: 'patient_chart',
              status: 'requested',
              due_date: moment().add(1, 'days').toDate(),
              description: `<a href="/portal/charts/${user?.id}/demographics">Go to ${user?.f_name}’s chart</a>`
            },
            users: {
              requester: practitionerId,
              owners: [practitionerId],
              followers: []
            }
          });
        }
      }
      const hasAddress =
        payer.payerAddress?.address_ln_1 && payer.payerAddress?.zip && payer.payerAddress?.state;
      if (hasAddress || payer.phone) {
        await requestApi({
          url: '/api/payers/update',
          params: {
            id: insuranceProfile.payer_id,
            updatedData: payer,
            updateAddress: hasAddress
          },
          navigate
        });
      }
      hideNewInsuranceProfile();
      setLoading(false);
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const handleSave = async (handle, type) => {
    setLoading(true);
    formik.setFieldValue([type], handle);

    const document = await detectDocument(navigate, {
      handle
    });

    setCoords((prevCords) => ({
      ...prevCords,
      [type]: {
        ...document?.coords,
        version: prevCords?.[type]?.version ? prevCords?.[type]?.version + 1 : 1
      }
    }));
    formik.setFieldValue(type, document?.handle);

    setLoading(false);
  };

  const handleProcess = async (image, type) => {
    setProcessing({ open: true, ocr });
    const ocr = !breadcrumb.steps.find((step) => step.name === 'form');

    const filestackClient = await getFilestackClient(navigate);
    const croppedImage = await filestackClient.upload(image);

    if (ocr) {
      const processedDocument = await processDocument(navigate, {
        ocr,
        newHandle: croppedImage?.handle,
        handle: formik.values?.[type]
      });
      const data = processedDocument?.data || {};

      Object.keys(data)?.forEach((key) => {
        const names =
          data?.[key] && typeof data[key] === 'string' ? data[key]?.toLowerCase().split(' ') : [];
        switch (key) {
          case 'payers':
            if (type == 'card_back') setPayers((p) => [...p]);
            return setPayers((p) => [...data[key], ...p]);
          case 'member_name':
            if (
              names.includes(patient?.f_name?.toLowerCase()) &&
              names.includes(patient?.l_name?.toLowerCase())
            )
              formik.setFieldValue('relation', '18');
            break;
          case 'payer_id':
            if (data[key]?.length === 5) {
              formik.setFieldValue('payer_id', data[key]);
              setTimeout(() => formik.setFieldTouched(key, true), 200);
            }
            break;
          default:
            formik.setFieldValue(key, data[key]);
            setTimeout(() => formik.setFieldTouched(key, true), 200);
            break;
        }
      });
      formik.setFieldValue(type, processedDocument?.handle);
    } else {
      formik.setFieldValue(type, croppedImage?.handle);
    }

    setProcessing({ open: false });
  };

  const handleDelete = async (type) => {
    formik.setFieldValue(type, '');
  };

  const handleModalClose = () => {
    const pickFields = [
      'card_front',
      'card_back',
      'payer_id',
      'member_id',
      'relation',
      'group_number'
    ];
    if (isEqual(pick(formik.initialValues, pickFields), pick(formik.values, pickFields))) {
      hideNewInsuranceProfile();
    } else {
      setConfirmClose(true);
    }
  };

  return (
    <>
      {fakeModal ? (
        <div className="h-full w-full" data-dd-privacy="mask">
          <div className="flex justify-between bg-neutral-50 p-4 !py-[12px]">
            <Breadcrumb hasBackButton breadcrumb={breadcrumb} />

            <Icon
              data-qa="new-close-square"
              icon="new-close-square"
              onClick={handleModalClose}
              className="flex h-6 w-6 items-center justify-center"
            />
          </div>
          <div
            className={cs(
              'h-full w-full p-3',
              processing?.open
                ? 'bg-neutral-900'
                : breadcrumb.currentStep === 'card_front' ||
                    breadcrumb.currentStep === 'card_back' ||
                    breadcrumb.currentStep === 'animation'
                  ? 'bg-neutral-800'
                  : 'bg-white'
            )}>
            <form>
              <Renderer
                {...{
                  breadcrumb,
                  formik,
                  handleDelete,
                  handleSave,
                  setZoomed,
                  loading,
                  processing,
                  kiosk,
                  payers,
                  states,
                  isTablet,
                  getPayerAddress,
                  isKeyboardOpen,
                  handleProcess,
                  coords
                }}
              />
            </form>

            {breadcrumb.currentStep === 'form' && (
              <div className="mt-4 flex w-full justify-between">
                <Button color="neutral" outlined onClick={handleModalClose} text="Cancel" />
                <Button
                  data-qa="create-insurance-btn"
                  onClick={() => formik.submitForm()}
                  forwardedRef={createInsuranceProfile}
                  loading={loading}
                  disabled={!formik.isValid}
                  text="Confirm & create profile"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <Modal
          handleOpen={showNewInsuranceProfile}
          handleClose={handleModalClose}
          customHeader={<Breadcrumb hasBackButton breadcrumb={breadcrumb} />}
          className={cs(
            isTablet ? 'h-full w-full !rounded-none' : 'w-[612px] !rounded-none',
            processing?.open
              ? 'bg-neutral-900'
              : breadcrumb.currentStep === 'card_front' ||
                  breadcrumb.currentStep === 'card_back' ||
                  breadcrumb.currentStep === 'animation'
                ? 'bg-neutral-800'
                : 'bg-white'
          )}
          title="Create new insurance profile"
          slideFromRight={!isTablet}
          isFooter={breadcrumb.currentStep === 'form'}
          footer={
            breadcrumb.currentStep === 'form' && (
              // eslint-disable-next-line react/no-unknown-property
              <div className="flex w-full justify-between" data-dd-privacy="mask">
                <Button
                  color="neutral"
                  size="small"
                  outlined
                  onClick={handleModalClose}
                  text="Cancel"
                />
                <Button
                  data-qa="create-insurance-btn"
                  onClick={() => formik.submitForm()}
                  forwardedRef={createInsuranceProfile}
                  loading={loading}
                  size="small"
                  disabled={!formik.isValid}
                  text="Confirm & create profile"
                />
              </div>
            )
          }>
          <form data-dd-privacy="allow">
            <Renderer
              {...{
                breadcrumb,
                formik,
                handleDelete,
                handleSave,
                setZoomed,
                loading,
                processing,
                kiosk,
                payers,
                states,
                isTablet,
                getPayerAddress,
                handleProcess,
                coords
              }}
            />
          </form>
        </Modal>
      )}
      <Modal
        isOpen={!!zoomed}
        handleClose={() => setZoomed(null)}
        className="h-[80%] w-[80%]"
        isFooter={false}>
        <img className="h-full w-full object-contain" src={imagePath(zoomed)} />
      </Modal>
      <Confirm
        isOpen={confirmClose}
        handleClose={() => setConfirmClose(false)}
        handleContinue={() => {
          setConfirmClose(false);
          hideNewInsuranceProfile();
        }}
        title="Discard changes?"
        message="Are you sure you want to discard changes to this profile?"
      />
    </>
  );
};

export default NewInsuranceProfile;
