export default function hasRecurrenceChanged(event_date) {
  const requiredProperties = [
    'start_date',
    'until',
    'repeat_event',
    'repeat_every',
    'ends_type',
    'repeat_on',
    'events',
    'weekdays'
  ];
  for (const prop of requiredProperties) {
    if (event_date.hasOwnProperty(prop) || prop in event_date) {
      return true;
    }
  }

  return false;
}
