import React from 'react';
import { getAllStepsLeft, getCurrentStep } from '../../lib/helpers';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import StepsChart from '../StepsChart/StepsChart';
import PacketsList from './components/PacketsList';
import Input from 'components/shared/Input/Input';
import { ia } from 'lib/helpers/utility';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';

const AssignToPacket = ({ currentBreadcrumb, formPackets }) => {
  const { breadCrumbs, setSearchTerm, selectedLength, formik } = useFormBuilderContext();

  const currentBreadcrumbStep = getCurrentStep(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getAllStepsLeft(breadCrumbs, currentBreadcrumb);
  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  return (
    <div>
      <StepsChart
        chartData={chartData}
        title="Assign to a Packet"
        description="Form Packets are designed to attach to encounters to manage your intake and check-in experience seamlessly. You can add this form to an existing packet, build a new packet or leave it as a stand-alone form at this time."
        innerText={`${currentBreadcrumbStep}/${currentBreadcrumbStep + getBreadcrumbsLeft}`}
      />
      <div className="px-9 pt-10">
        {ia(formPackets?.packets) && (
          <div className="flex items-center justify-between ">
            <p className="text-sm font-500 text-primary-900">
              Add your form to an existing form packet below.
            </p>
            <div className="flex items-center gap-3">
              <p
                className={cs(
                  'text-sm ',
                  selectedLength ? 'text-primary-900' : 'text-neutral-500'
                )}>
                {selectedLength} selected
              </p>
              <Input
                icon="new-search"
                rounded="full"
                placeholder="Search packets"
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
        )}

        <div className="mt-[21px]">
          {ia(formPackets?.packets) ? (
            formPackets?.packets?.map((formPacket) => (
              <PacketsList key={formPacket?.id} formPacket={formPacket} />
            ))
          ) : (
            <div className="flex flex-col items-center justify-center gap-2 text-center">
              <p className=" text-sm font-500 text-primary-900">Don't have any form packets</p>
              <Button
                type="secondary"
                icon="add-square"
                text="Build Without Form Packet"
                data-qa="build-without-form-packet-btn"
                transparent
                className="!border-none !bg-primary-50"
                onClick={() => formik.handleSubmit()}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AssignToPacket;
