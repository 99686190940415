import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import cs from 'classnames';

import { getCurrencySymbol, getNumberFormatter, ia } from 'lib/helpers/utility';

import { showAlert } from '../Alert/Alert';
import InputOptions from '../InputOptions/InputOptions';
import Typography from '../Typography/Typography';
import Hint from '../shared/Hint';

import useUnit from './hooks/useUnit';
import {
  ALERT_DURATION,
  ALLOWED_KEYS,
  DEBOUNCE_TIME,
  MAX_PERCENT_VALUE,
  PERCENT_AMOUNT_DIVISOR,
  PERCENT_DIVISOR
} from './lib/constants';
import { getErrorMessage } from './lib/helpers';
import { currencyInputStyles, currencyLabelStyles, currencyWrapperStyles } from './lib/styles';

/**
 * @param {import('./lib/propTypes').CurrencyInputProps}
 */

const CurrencyInput = ({
  variant = 'primary',
  value = 0,
  label,
  onValueChange = () => {},
  parentClassName,
  wrapperClassName,
  disabled,
  required,
  hint,
  error,
  className,
  minValue,
  maxValue,
  currency = 'USD',
  style = 'currency',
  units,
  optionPosition,
  currentUnit,
  onUnitChange = () => {},
  onBlur = () => {},
  textRight = false,
  weight = 'normal',
  textColor = 'neutral-900',
  hideBorder = false,
  hintIcon,
  validationMessages
}) => {
  const inputRef = useRef(null);
  const timeoutRef = useRef(null);
  const [inputValue, setInputValue] = useState(value);
  const isStylePercent = style === 'percent';

  const { inputUnit, handleUnitChange, currentStyle, isPercent } = useUnit({
    style,
    currentUnit,
    value,
    maxValue,
    onUnitChange,
    setInputValue
  });

  useEffect(() => {
    if (!isPercent) setInputValue(value);
  }, [value, isPercent]);

  const currentUnitLabel = useMemo(
    () => units?.find((unit) => unit.value === inputUnit)?.label,
    [units, inputUnit]
  );

  const CURRENCY_FORMATTER = useMemo(
    () => getNumberFormatter({ style: currentStyle, currency }),
    [currentStyle, currency]
  );

  const symbol = useMemo(
    () => (isPercent ? '%' : getCurrencySymbol({ currency })),
    [currency, isPercent]
  );

  const isValidAmount = useCallback(
    (amount) => {
      const isPositive = amount >= 0;
      const isAboveMin = minValue === undefined || amount >= minValue;
      const isBelowMax = isPercent
        ? amount <= MAX_PERCENT_VALUE
        : maxValue === undefined || amount <= maxValue;
      return isPositive && isAboveMin && isBelowMax;
    },
    [minValue, maxValue, isPercent]
  );

  const updateValue = useCallback(
    (newValue) => {
      if (!isValidAmount(newValue)) {
        showAlert({
          color: 'danger',
          message: getErrorMessage(minValue, maxValue, symbol, validationMessages),
          duration: ALERT_DURATION
        });

        const value = newValue > maxValue ? maxValue : !!minValue ? minValue : 0;
        setInputValue(value);
        onValueChange(value);
        return value;
      }
      return newValue;
    },
    [isValidAmount, minValue, maxValue, symbol, onValueChange, validationMessages]
  );

  const debounceValueChange = useCallback(
    (value) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        updateValue(value);
      }, DEBOUNCE_TIME);
    },
    [updateValue]
  );

  const handleValueChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    const numericValue = value.replace(/[^0-9]/g, '');
    const newValue = parseInt(numericValue, 10);

    if (isNaN(newValue)) return;

    const isBackspaceDelete = e.nativeEvent.inputType === 'deleteContentBackward';
    const shouldHandlePercentDelete = isBackspaceDelete && numericValue.length > 0 && isPercent;

    if (shouldHandlePercentDelete) {
      const truncatedValue = numericValue.slice(0, -1);
      const formattedValue = !isNaN(truncatedValue) ? truncatedValue / PERCENT_DIVISOR : '';

      setInputValue(formattedValue);
      onValueChange(formattedValue);
      debounceValueChange(formattedValue);
      return;
    }

    if (isPercent) {
      const percentageValue = Math.min(newValue, MAX_PERCENT_VALUE) / PERCENT_DIVISOR;
      const calculatedValue = (percentageValue * maxValue) / PERCENT_DIVISOR;
      const newPercentageValue = isStylePercent ? percentageValue : calculatedValue;

      setInputValue(newPercentageValue);
      onValueChange(newPercentageValue);
    } else {
      setInputValue(newValue);
      onValueChange(newValue);
      debounceValueChange(newValue);
    }
  };

  const handleKeyDown = (e) => {
    if (!ALLOWED_KEYS.test(e.key)) {
      e.stopPropagation();
      e.preventDefault();
    }
    if (e.ctrlKey && e.key === 'a' && inputRef.current) {
      inputRef.current.select();
    }
  };

  const handleFocus = (e) => {
    requestAnimationFrame(() => {
      const length = e.target.value.length;
      e.target.setSelectionRange(length, length);
    });
  };

  const handleBlur = () => {
    const newValue = updateValue(inputValue);
    onBlur(newValue);
  };

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const currentValue = CURRENCY_FORMATTER.format(
    isPercent ? inputValue / PERCENT_AMOUNT_DIVISOR : inputValue / PERCENT_DIVISOR
  );

  return (
    <div className={cs(parentClassName, disabled && 'cursor-not-allowed', 'flex flex-col gap-2')}>
      {label && (
        <div className={currencyLabelStyles.label}>
          <Typography required={required}>{label}</Typography>
        </div>
      )}
      <div
        className={cs(
          currencyWrapperStyles[variant]?.wrapper,
          error ? currencyWrapperStyles[variant]?.error : currencyWrapperStyles[variant]?.default,
          disabled && currencyWrapperStyles[variant]?.disabled,
          hideBorder && currencyWrapperStyles[variant]?.hideBorder,
          wrapperClassName
        )}>
        <input
          ref={inputRef}
          className={cs(
            currencyInputStyles[variant]?.input,
            disabled && currencyInputStyles[variant]?.disabled,
            error
              ? currencyInputStyles[variant]?.inputError
              : currencyInputStyles[variant]?.inputDefault,
            textRight && 'text-right',
            weight && `font-${weight}`,
            textColor && `text-${textColor}`,
            className
          )}
          name="currency-input"
          type="text"
          value={currentValue}
          onChange={handleValueChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={`${symbol} 0.00`}
          disabled={disabled}
        />

        {ia(units) && (
          <InputOptions
            options={units}
            disabled={disabled}
            className="!h-5 text-xs"
            position={optionPosition}
            currentOption={currentUnitLabel}
            onClickOption={handleUnitChange}
          />
        )}
      </div>
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && (
        <Typography variant="p" size="xs" color="danger-500">
          {error}
        </Typography>
      )}
    </div>
  );
};

export default CurrencyInput;
