import React, { useImperativeHandle, useState } from 'react';
import Button from '../../../../../../shared/Buttons/Button';
import CurrentMedications from '../../../../../../shared/Forms/Custom/CurrentMedications/CurrentMedications';
import YourSignatureMedical from './components/YourSignatureMedical';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { createMedication } from '../../../../../../../api/Medication';
import { upsertNonERXPrescription } from '../../../../../../../api/NonERXPrescription';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { calculateAge, ia, iaRa } from '../../../../../../../lib/helpers/utility';
import { userState } from '../../../../../../state';
import {
  MedicalPrescriptionlValidationSchema,
  getInitialMedicalPrescriptionData
} from '../../lib/handleFormik';
import NonERXPrescriptionAndFrequency from './components/NonERXPrescriptionAndFrequency';
import PatientInfo from './components/PatientInfo';

const patientMapper = (itemProps) => {
  const { patient: outletPatient } = useOutletContext() || {};

  const patient = outletPatient || itemProps.patient;

  return {
    patient_id: patient?.id,
    patient_f_name: patient?.f_name,
    patient_l_name: patient?.l_name,
    patient_age: calculateAge({ dateOfBirth: patient?.dob })
  };
};

const MedicalPrescriptionCreate = ({ actionRef, itemProps = {}, setActiveView }) => {
  const {
    setOverviewData,
    currentHpOverviewData,
    setCurrentHpOverviewData = () => null,
    setSaveButtonText,
    updateSaveButton
  } = useClinicalNoteContext() || {};

  const [openMedication, setOpenMedication] = useState(ia(itemProps?.medication_ids));
  const [currentMedications, setCurrentMedications] = useState({
    medications: currentHpOverviewData?.medicationHistory?.response?.medications?.filter(
      (medication) => iaRa(itemProps?.medication_ids)?.includes(medication?.id)
    )
  });

  const user = useRecoilValue(userState);

  const { id, appointmentId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const customFormsParams = {
    id,
    appointmentId,
    setOverviewData,
    setCurrentHpOverviewData,
    setSaveButtonText,
    updateSaveButton
  };

  const notCurrentMedicationData = {
    medications: currentHpOverviewData?.medicationHistory?.response?.medications?.filter(
      (medication) => !itemProps?.medication_ids?.includes(medication?.id)
    )
  };

  const queryClient = useQueryClient();
  const upsertNonERXPrescriptionMutation = useMutation(
    (data) => upsertNonERXPrescription(navigate, data),
    {
      onSuccess: (data) => {
        const { nonERXPrescription } = data?.data || {};

        formik.setValues(nonERXPrescription);

        queryClient.invalidateQueries(['nonERXPrescription']);

        setActiveView((prevState) => ({
          ...prevState,
          loading: false,
          component: 'preview',
          itemProps: nonERXPrescription
        }));
      },
      onSettled: () => {
        setActiveView((prevState) => ({ ...prevState, loading: false }));
      }
    }
  );

  const initialFormikValues = {
    provider_id: user?.id,
    appointment_id: appointmentId,
    ...patientMapper(itemProps),
    ...itemProps
  };

  const formik = useFormik({
    initialValues: getInitialMedicalPrescriptionData(initialFormikValues),
    validationSchema: MedicalPrescriptionlValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setActiveView((prevState) => ({ ...prevState, loading: true }));

      const medicationsObj = {
        medications: [
          ...(notCurrentMedicationData?.medications || []),
          ...(currentMedications?.medications || [])
        ]
      };

      const upsertMedications = await createMedication(() => {}, {
        patientId: id,
        appointmentId,
        medication: medicationsObj
      });

      setCurrentHpOverviewData((prevState) => ({
        ...prevState,
        medicationHistory: upsertMedications?.newResponse
      }));

      const filterCurrentMedicationIdTypeNumber = currentMedications?.medications
        ?.map((row) => row.id)
        ?.filter((row) => typeof row === 'number');

      const upsertedMedicationIds = upsertMedications?.newResponse?.response?.medications?.map(
        (medication) => medication?.id
      );

      const notCurrentMedicationDataIds = notCurrentMedicationData?.medications?.map(
        (medication) => medication?.id
      );

      const findNewUpsertedIds = upsertedMedicationIds?.filter(
        (row) => !notCurrentMedicationDataIds?.includes(row)
      );

      const signatureDataValues = values?.signatureData;

      delete values?.signatureData;

      await upsertNonERXPrescriptionMutation.mutateAsync({
        upsertData: {
          ...values,
          medication_ids: [
            ...new Set([
              ...(filterCurrentMedicationIdTypeNumber || []),
              ...(findNewUpsertedIds || [])
            ])
          ]
        },
        signatureData: { ...signatureDataValues, link: pathname }
      });
    }
  });

  useImperativeHandle(actionRef, () => ({
    onSubmit: formik.submitForm
  }));

  return (
    <div>
      <div className="!mt-6 rounded-lg bg-primary-50 !p-3">
        <PatientInfo formik={formik} />
      </div>

      <div className="!mt-6 rounded-lg bg-primary-50 !p-3">
        <NonERXPrescriptionAndFrequency formik={formik} />
      </div>

      <div className="!mt-6 rounded-lg bg-primary-50 !p-3">
        <div className="flex justify-end">
          <Button
            data-qa="open-close-medications-btn"
            onClick={() => setOpenMedication((prevState) => !prevState)}
            text={openMedication ? 'Close Medication' : 'Open Medication'}
            transparent
            iconRight={openMedication ? 'chevrons-up' : 'chevrons-down'}
            className="!m-0 !p-0"
          />
        </div>

        {openMedication && (
          <CurrentMedications
            defaultValue={ia(currentMedications?.medications) ? currentMedications : null}
            customFormsParams={customFormsParams}
            setCurrentFormData={(data) => setCurrentMedications(data)}
          />
        )}
      </div>

      <div className="!mt-6 rounded-lg bg-primary-50 !p-3">
        <YourSignatureMedical formik={formik} />
      </div>
    </div>
  );
};

export default MedicalPrescriptionCreate;
