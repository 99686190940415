import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCustomFormTypes } from '../../../../api/CustomForms';

export const useCustomFormTypes = ({ dependencies = [], params = {}, options } = {}) => {
  const navigate = useNavigate();
  return useQuery(
    ['customFormTypes', ...dependencies],
    () => getCustomFormTypes(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      ...options
    }
  );
};
