import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  archiveFaxes,
  deleteFax,
  downloadFax,
  markFaxesAsRead,
  previewSavedFax,
  unarchiveFaxes
} from 'api/Fax';
import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useNavigate } from 'react-router-dom';
import PreviewPdfModal from '../../components/SendFax/components/PreviewPdfModal';
import ForwardFaxModal from '../../components/ForwardFaxModal/ForwardFaxModal';
import InfoFaxModal from '../../components/InfoFaxModal/InfoFaxModal';
import RenameFaxModal from '../../components/RenameFaxModal/RenameFaxModal';
import { ia } from 'lib/helpers/utility';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import applyChangesToFax from '../../components/SendFax/lib/applyChangesToFax';

export const FaxActions = ({ data, queryKey, type }) => {
  const fax = data || {};
  const [pdfData, setPdfData] = useState(null);
  const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);
  const [forwardFaxModalVisible, setForwardFaxModalVisible] = useState(false);
  const [infoFaxModalVisible, setInfoFaxModalVisible] = useState(false);
  const [renameFaxModalVisible, setRenameFaxModalVisible] = useState(false);
  const [previewFax, setPreviewFax] = useState(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectedRows, setSelectedRows } = useTableContext();
  const selectedRowsData = selectedRows.map((node) => node.data);

  if (!data) return null;

  const refetchFaxes = () => queryClient.invalidateQueries([queryKey]);

  const handleArchive = () => {
    archiveFaxes(navigate, [fax.id]).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax archived' });
      })
    );
  };

  const handleUnarchive = () => {
    unarchiveFaxes(navigate, { id: fax.id }).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax unarchive' });
      })
    );
  };

  const handlePrint = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, fax.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = blobUrlFile;

    iframe.onload = () => {
      iframe.contentWindow.print();
      URL.revokeObjectURL(blobUrlFile);
    };

    document.body.appendChild(iframe);
  };

  const handleDownload = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, fax.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const pdfUrl = blobUrlFile;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `invoice_${new Date(fax.created_at).getTime()}_document.pdf`; // specify the filename
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleMarkAsRead = async (faxId) => {
    await markFaxesAsRead(
      navigate,
      ia(faxId) ? faxId : selectedRowsData?.map((fax) => fax.id),
      onSuccess
    );

    function onSuccess() {
      queryClient.invalidateQueries(['inboundFaxes']);
      queryClient.invalidateQueries(['unreadFaxes']);

      !faxId && showAlert({ message: 'Faxes marked as read', color: 'success' });
      setSelectedRows([]);
    }
  };

  const handlePreview = () => {
    previewSavedFax(navigate, fax.id, type === 'inbound').then(async (data) => {
      // if (type === 'inbound') {
      //   if (!fax?.read) await handleMarkAsRead([fax.id]);
      // }
      setPreviewFax(data.fax);
      setPdfData(data.url);
      setPreviewFaxModalVisible(true);
    });
  };

  const handleDelete = () => {
    deleteFax(navigate, fax.id).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax deleted' });
      })
    );
  };

  const handleRename = () => {
    setRenameFaxModalVisible(true);
  };

  const handleArchiving = () => {
    type == 'archived' ? handleUnarchive() : handleArchive();
  };

  const handleForwardResend = () => {
    setForwardFaxModalVisible(true);
  };

  const handleInfo = () => {
    setInfoFaxModalVisible(true);
  };

  const options = [
    { label: 'Preview', onClick: handlePreview, icon: 'play-circle-small' },
    { label: 'Print', onClick: handlePrint, icon: 'new-printer' },
    { label: 'Download', onClick: handleDownload, icon: 'new-import' },
    {
      label: type == 'archived' ? 'Unarchive' : 'Archive',
      onClick: handleArchiving,
      icon: 'archive-small'
    },
    { label: 'Forward/Resend', onClick: handleForwardResend, icon: 'forward' },
    { label: 'Info', onClick: handleInfo, icon: 'new-info' },
    { label: 'Delete', onClick: handleDelete, icon: 'trash' },
    { label: 'Rename', onClick: handleRename, icon: 'new-edit' }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {previewFaxModalVisible && (
        <PreviewPdfModal
          {...{
            pdfData,
            previewFax,
            previewFaxModalVisible,
            setPreviewFaxModalVisible,
            hasEdit: true
          }}
        />
      )}
      {forwardFaxModalVisible && (
        <ForwardFaxModal
          {...{ forwardFaxModalVisible, setForwardFaxModalVisible, fax, refetchFaxes }}
        />
      )}
      {infoFaxModalVisible && (
        <InfoFaxModal {...{ infoFaxModalVisible, setInfoFaxModalVisible, fax }} />
      )}
      {renameFaxModalVisible && (
        <RenameFaxModal
          {...{
            renameFaxModalVisible,
            setRenameFaxModalVisible,
            id: fax.id,
            fax_name: fax.name,
            refetchFaxes
          }}
        />
      )}
    </>
  );
};

export default FaxActions;
