module.exports = {
  priorities: [
    { value: '0', label: 'High' },
    { value: '1', label: 'Medium' },
    { value: '2', label: 'Low' }
  ],
  statuses: [
    { value: 'requested', label: 'Requested' },
    { value: 'in progress', label: 'In Progress' },
    { value: 'completed', label: 'Completed' }
  ],
  categories: [
    { value: 'appointment', label: 'Appointment' },
    { value: 'patient_chart', label: 'Patient Chart' },
    { value: 'patient_communication', label: 'Patient Communication' },
    { value: 'clinical_documentation', label: 'Clinical documentation' },
    { value: 'insurance_billing', label: 'Insurance Billing' },
    { value: 'office_management', label: 'Office Management' },
    { value: 'eFax', label: 'eFax' },
    { value: 'prescription', label: 'Prescription' },
    { value: 'practice_settings', label: 'Practice Settings' },
    { value: 'patient_payment', label: 'Patient Payment' },
    { value: 'other', label: 'Other' }
  ]
};
