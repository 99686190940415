import { useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import { requestApi } from 'api/Api';
import cs from 'classnames';
import CareCredit from 'components/Payments/cash/CareCredit';
import CashPayment from 'components/Payments/cash/cash';
import CheckPayment from 'components/Payments/cash/check';
import AppointmentInvoices from 'components/Payments/components/AppointmentInvoices';
import CardBox from 'components/Payments/components/CardBox';
import KioskBox from 'components/Payments/components/KioskBox';
import PaymentMethodBox from 'components/Payments/components/PaymentMethodBox';
import TerminalBox from 'components/Payments/components/TerminalBox';
import PaymentsKiosk from 'components/Payments/kiosk';
import LightboxWrapper from 'components/Payments/lightbox/LightboxWrapper';
import SavedCardSale from 'components/Payments/lightbox/SavedCardSale';
import TerminalWrapper from 'components/Payments/terminal/terminalWrapper';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import Receipt from 'components/shared/Receipt/Receipt';
import Switch from 'components/shared/Switch/Switch';
import { ia, iaRa, io, mString, sumBy } from 'lib/helpers/utility';
import _, { pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useRecoilValue } from 'recoil';

const LIMIT = 25;
const TERM_KIND = {
  unselected: 0,
  elavonCardPresent: 9,
  elavonCardPresentTokenize: 1,
  elavonLightbox: 2,
  elavonSavedCard: 3,
  kiosk: 5,
  cash: 6,
  check: 7,
  careCredit: 8
};

const Configurations = ({
  amount: loadedAmount,
  patientId,
  practiceId,
  appointmentId,
  invoiceId,
  onClose,
  configurationRef,
  hideButton = false,
  updateBalance = () => {},
  updateAmount = () => {},
  onSuccess = () => {},
  preAuthForm,
  disabled = false,
  showOverrides,
  amountInputDisabled,
  preAuthFromSelfScheduling = false,
  appointment,
  newInvoice,
  kioskId
}) => {
  const hasInvoice =
    (appointment?.status !== 'cancelled' && appointment?.invoice?.id) || newInvoice ? true : false;

  const [show, setShow] = useState({
    paymentMethod: null,
    saveCard: false,
    complete: false,
    selectedPaymentOption: '',
    error: false,
    checkNumber: '',
    creditCareNumber: '',
    selectTerminalLoading: false,
    selectVirtualTerminalLoading: false,
    cash: true,
    check: true,
    cardOnFile: true,
    care_credit: false,
    showOverrides: {
      cash: true,
      check: true,
      cardOnFile: true,
      careCredit: true,
      cardPresent: true,
      online: true,
      noFooter: false,
      noInvoice: false,
      surchargeDisable: false,
      editingDisable: false
    }
  });
  const navigate = useNavigate();
  const [amount, _setAmount] = useState(0);
  const [terminals, setTerminals] = useState([]);
  const [virtualTerminals, setVirtualTerminals] = useState([]);
  const [selectedTid, setSelectedTid] = useState(null);
  const [selectedTerminal, setSelectedTerminal] = useState(null);
  const [selectedVtid, setSelectedVtid] = useState(null);
  const [selectedVirtualTerminal, setSelectedVirtualTerminal] = useState(null);
  const [cards, setCards] = useState([]);
  const [receipt, setReceipt] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [hasSurcharge, setHasSurcharge] = useState();
  const [surchargeAmount, setSurchargeAmount] = useState(0);
  const [hasCareCredit, setHasCareCredit] = useState(false);
  const [cashDiscountAmount, setCashDiscountAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(hasInvoice && loadedAmount);
  const [temporarySurchargeDisable, setTemporarySurchargeDisable] = useState(false);
  const [extraCredit, setExtraCredit] = useState(0);
  const receiptRef = useRef();
  const addressRef = useRef();
  const [confirmationEmail, setConfirmationEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [termKinds, setTermKinds] = useState({
    virtual: TERM_KIND.elavonLightbox,
    savedCard: TERM_KIND.elavonSavedCard,
    cardPresent: TERM_KIND.elavonCardPresent,
    cardPresentTokenize: TERM_KIND.elavonCardPresentTokenize,
    cash: TERM_KIND.cash,
    check: TERM_KIND.check,
    careCredit: TERM_KIND.careCredit
  });
  const practice = useRecoilValue(currentPractice);
  const [selectedKioskId, setSelectedKioskId] = useState(null);
  const [kiosks, setKiosks] = useState([]);
  const { pathname } = useLocation();

  const setAmount = (newAmount) => {
    if (amountInputDisabled) return;
    _setAmount(newAmount);
  };

  const queryClient = useQueryClient();

  const defaultInvoice = hasInvoice
    ? newInvoice
      ? newInvoice
      : [
          {
            ...appointment?.invoice,
            selected: true,
            expanded: true,
            discountAmount: appointment?.invoice?.discount?.amount_cents || 0,
            appointment: pick(appointment, ['id', 'amount', 'practitioner_id', 'starts_at'])
          }
        ]
    : [];

  const [invoices, setInvoices] = useState(hasInvoice ? defaultInvoice : []);

  const startPayment = (event) => {
    event.preventDefault();

    if (show.selectedPaymentOption === termKinds?.virtual && !selectedCard?.billing_address) {
      const formElement = addressRef.current;
      formElement?.handleSubmit();
    }

    setShow({ ...show, paymentMethod: show.selectedPaymentOption });
  };

  useEffect(() => {
    window.manualCompleteTransaction = manualCompleteTransaction;

    if (showOverrides) {
      setShow({ ...show, showOverrides: { ...show.showOverrides, ...showOverrides } });
    }

    loadTerminals();
    loadSavedCards();
    hasInvoice && setAmount(loadedAmount);

    if (io(practice)) {
      const { care_credit, surcharge, surcharge_enabled, surcharge_percentage } = practice;

      setHasCareCredit(care_credit);
      setHasSurcharge({ surcharge, surcharge_enabled, surcharge_percentage });
    }
  }, []);

  useEffect(() => {
    if (configurationRef) {
      configurationRef.current = {
        startPayment,
        disabled: !show.selectedPaymentOption || disabled
      };
    }
  }, [show]);

  useEffect(() => {
    hasInvoice && setAmount(loadedAmount);
  }, [loadedAmount]);

  useEffect(() => {
    if (kioskId) {
      setSelectedKioskId(kioskId);
    }
  }, [kioskId]);

  useEffect(() => {
    if (hasSurcharge?.surcharge && hasSurcharge?.surcharge_enabled) {
      surchargeFromAmount(hasSurcharge?.surcharge_percentage);
      totalWithSurcharge(hasSurcharge?.surcharge_percentage);
    } else {
      setTotalAmount(amount);
    }

    if (temporarySurchargeDisable) {
      setTotalAmount(amount);
      setSurchargeAmount(0);
    }
  }, [hasSurcharge, temporarySurchargeDisable, amount]);

  useEffect(() => {
    if (!receipt || !invoices[0] || !receipt.transaction) return;

    if (appointment?.id) {
      queryClient.invalidateQueries(['appointment', appointment?.id]); // TEMPORARY: this will invalidate the query for old appointment preview
      queryClient.invalidateQueries(['appointmentv2', appointment?.id]);
    }
  }, [receipt]);

  const manualCompleteTransaction = async (raw_transaction, patient_id) => {
    try {
      const ptd = raw_transaction.data.paymentGatewayCommand.paymentTransactionData;
      // if (ptd.result !== 'APPROVED' && false) {
      //   let error = `There's been an error while processing transaction.`;
      //   if (ia(ptd.errors)) {
      //     error = (
      //       <>
      //         {ptd.errors.length > 1 && (
      //           <div>`Errors have occurred while processing transaction`</div>
      //         )}
      //         {ptd.errors.length > 0 && (
      //           <ul>
      //             {ptd.errors.map((v, i) => (
      //               <li key={`ptd-error-terminal-interface-index-${i}`}>{v}</li>
      //             ))}
      //           </ul>
      //         )}
      //       </>
      //     );
      //   }
      //   return error;
      //
      // }
      let transaction = {
        pan: ptd.maskedPan,
        cardScheme: ptd.cardScheme,
        cardEntryType: ptd.cardEntryType,
        resultMessage: ptd.resultMessage,
        amount: ptd.amount,
        id: ptd.id,
        chanId: raw_transaction.data.paymentGatewayCommand.chanId
      };
      if (ptd.tokenizedCard) {
        transaction['tokenizedCard'] = ptd.tokenizedCard;
      }

      await requestApi({
        url: '/api/transactions/terminal/transaction/complete',
        params: {
          patientId: patient_id,
          transaction,
          appointmentId,
          rawTransaction: raw_transaction
        },
        navigate
      });
    } catch (error) {
      console.error(error);
    }
  };

  const onApproval = (data) => {
    if (!preAuthForm && pathname?.includes('portal')) setShow({ ...show, receipt: true });
    if (preAuthForm) setShow({ ...show, paymentMethod: null });

    onSuccess(data);
  };

  const loadTerminals = async () => {
    try {
      const onSuccess = async ({
        terminalData,
        selectedTid: loadedSelectedTid,
        selectedVtid: loadedSelectedVtid,
        kiosk
      }) => {
        let newShow = {};
        let newTerminals = [];
        let newSelectedTerminal = null;
        let newVirtualTerminals = [];
        let newSelectedVirtualTerminal = null;
        let kiosksPresent = ia(kiosk);

        if (kiosksPresent) {
          setKiosks(kiosk);
          if (!selectedKioskId && kiosk.length >= 1) {
            setSelectedKioskId(kiosk[0]?.id);
          }
        }
        for (let i = 0; i < terminalData.length; i++) {
          // if the terminal is not active, don't show it
          if (!terminalData[i].active) continue;
          let newTerminal = {
            ...terminalData[i],
            label: terminalData[i].name ? terminalData[i].name : terminalData[i].id,
            value: terminalData[i].id
          };
          if (terminalData[i].online) {
            // this is a virtual terminal
            if (newTerminal.id === loadedSelectedVtid) {
              newSelectedVirtualTerminal = newTerminal;
            }
            newVirtualTerminals.push(newTerminal);
          } else {
            // this is a card present terminal
            if (newTerminal.id === loadedSelectedTid || newTerminals.length === 0) {
              // make selected terminal the first terminal or selected TID
              newSelectedTerminal = newTerminal;
            } else if (newTerminals.length > 1 && !loadedSelectedTid) {
              // if there's no selected terminal and more than one terminal
              newSelectedTerminal = null;
            }
            newTerminals.push(newTerminal);
          }
        }
        if (kiosksPresent) {
          setTermKinds((ps) => ({
            ...ps,
            cardPresent: TERM_KIND.kiosk,
            cardPresentTokenize: TERM_KIND.kiosk
          }));
        }
        if (preAuthForm) {
          if ([...virtualTerminals, ...newVirtualTerminals].length === 0) {
            //
          } else if ([...virtualTerminals, ...newVirtualTerminals].length === 1) {
            //
            newShow.paymentMethod = termKinds.virtual;
          } else if ([...virtualTerminals, ...newVirtualTerminals].length > 1) {
            //
          } else {
            newShow.paymentMethod = termKinds.virtual;
          }
          setVirtualTerminals([...virtualTerminals, ...newVirtualTerminals]);
          setSelectedVtid(loadedSelectedVtid);
          setSelectedVirtualTerminal(newSelectedVirtualTerminal);
          // newShow.cash = false;
          // newShow.check = false;
          setShow((prevState) => ({
            ...prevState,
            cash: false,
            check: false,
            cardOnFile: false,
            selectedPaymentOption: termKinds?.virtual
          }));
        } else {
          // Card Present Terminals
          setTerminals([...terminals, ...newTerminals]);
          setSelectedTid(loadedSelectedTid);
          setSelectedTerminal(newSelectedTerminal);
          // Virtual Terminals
          setVirtualTerminals([...virtualTerminals, ...newVirtualTerminals]);
          setSelectedVtid(loadedSelectedVtid);
          setSelectedVirtualTerminal(newSelectedVirtualTerminal);
        }
      };
      const onError = (error) => {
        showAlert({
          message: error || `An unexpected error has occurred. Please try again later.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/transactions/terminal/list',
        params: {
          all: true,
          limit: LIMIT,
          offset: terminals.length + virtualTerminals.length,
          newAppointment: preAuthFromSelfScheduling
        },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      console.error(error);
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        color: 'danger'
      });
    }
  };

  const selectTerminal = async (e) => {
    if (e.value === selectedTid) {
      return;
    }
    let newShow = _.cloneDeep(show); // Object.assign({}, show);
    setShow({ ...newShow, selectTerminalLoading: true });
    try {
      const newTid = parseInt(e.value);

      const onSuccess = async () => {
        setSelectedTerminal(e);
        setSelectedTid(e.value);
      };

      const onError = (error) => {
        showAlert({
          message: error || `An unexpected error has occurred. Please try again later.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/transactions/terminal/select',
        params: { tid: newTid },
        navigate,
        onSuccess,
        onError
      });
      setShow({ ...newShow, selectTerminalLoading: false });
    } catch (error) {
      console.error(error);
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        color: 'danger'
      });
      setShow({ ...newShow, selectTerminalLoading: false });
    }
  };

  const selectVirtualTerminal = async (e) => {
    if (e.value === selectedVirtualTerminal) {
      return;
    }
    let newShow = Object.assign({}, show);
    setShow({ ...newShow, selectVirtualTerminalLoading: true });
    try {
      const newTid = parseInt(e.value);

      const onSuccess = () => {
        setSelectedVirtualTerminal(e);
        setSelectedVtid(e.value);
      };
      const onError = (error) => {
        showAlert({
          message: error || `An unexpected error has occurred. Please try again later.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/transactions/online/select',
        params: { vtid: newTid },
        navigate,
        onSuccess,
        onError
      });

      setShow({ ...newShow, selectVirtualTerminalLoading: false });
    } catch (error) {
      console.error(error);
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        color: 'danger'
      });
      newShow.selectTerminalLoading = false;
      setShow({ ...newShow, selectVirtualTerminalLoading: false });
    }
  };

  const loadSavedCards = async () => {
    try {
      let params = { patientId };

      if (preAuthFromSelfScheduling) params = { newAppointment: true };
      const onSuccess = async ({ cards: loadedCards }) => {
        if (ia(loadedCards)) {
          setCards(loadedCards);
          setSelectedCard(loadedCards[0]);
        }
      };
      const onError = (error) => {
        showAlert({
          message: error || `An unexpected error has occurred. Please try again later.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/transactions/token/list',
        params,
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      showAlert({
        message: `There's been an unexpected error. Please try again later.`,
        color: 'danger'
      });
    }
  };

  const surchargeFromAmount = (surcharge_percentage) => {
    const number = parseFloat(surcharge_percentage);
    if (!isNaN(number)) {
      const percentage = surcharge_percentage / 100;
      const totalAmountFromPercentage = percentage * amount;
      setSurchargeAmount(parseInt(totalAmountFromPercentage.toFixed(2)));
      setCashDiscountAmount(parseInt(totalAmountFromPercentage.toFixed(2)));
    } else {
      setSurchargeAmount(0);
      setCashDiscountAmount(0);
    }
  };

  const totalWithSurcharge = (surcharge_percentage) => {
    const percentage = surcharge_percentage / 100;
    const totalAmountFromPercentage = amount * percentage;
    setTotalAmount(Number(amount) + totalAmountFromPercentage);
  };

  const handleOnFail = (e) => {
    let newErrorMessage = 'Transaction failed, please try again later.';
    if (ia(e)) {
      newErrorMessage = (
        <ul>
          {e.map((v, idx) => (
            <li key={idx}>{v}</li>
          ))}
        </ul>
      );
    } else if (typeof e === 'string') {
      newErrorMessage = e;
    }
    setShow({
      ...show,
      paymentMethod: null,
      error: newErrorMessage
    });
  };

  const sendEmailReceipt = async () => {
    setLoading(true);
    try {
      const onSuccess = () => {
        setConfirmationEmail(false);
        showAlert({ message: 'Receipt email sent!', color: 'success' });
      };
      const onError = (error) => {
        showAlert({
          message: error || `An unexpected error has occurred. Please try again later.`,
          color: 'danger'
        });
      };
      await requestApi({
        url: '/api/transactions/receipt/email',
        params: { transactionId: receipt?.transaction?.id },
        navigate,
        onSuccess,
        onError
      });
    } catch (error) {
      showAlert({
        message: 'There was an error emailing the receipt. Please try again.',
        color: 'danger'
      });
    }
    setLoading(false);
  };

  const calculateTotalAmount = (list) => {
    let newTotal = list.reduce((total, invoice) => {
      if (invoice?.selected) return total + Number(invoice?.balance);
      else return total;
    }, 0);

    setAmount((newTotal || 0) + extraCredit);
  };

  const handleCloseReceiptModal = () => {
    setShow({ ...show, receipt: false });
    onClose();
  };

  if (show.receipt) {
    return (
      <>
        <Modal
          title="Receipt"
          slideFromRight
          isLarge
          handleOpen={show.receipt}
          handleClose={handleCloseReceiptModal}
          handleClick={handleCloseReceiptModal}
          headButton={
            <div className="flex w-full items-center justify-end">
              <ReactToPrint
                trigger={() => (
                  <Button
                    color=""
                    size="small"
                    type="link"
                    text="Print"
                    icon="new-printer-bulk"
                    className="text-primary-900"
                  />
                )}
                content={() => receiptRef?.current}
              />
              <Button
                color=""
                type="link"
                size="small"
                text="Email"
                icon="new-sms-bulk"
                data-qa="email-to-patient"
                className="text-primary-900"
                onClick={() => setConfirmationEmail(true)}
              />
            </div>
          }
          footer={
            <div className="flex w-full justify-between">
              <Button outlined color="neutral" onClick={onClose} text="close" data-qa="close-btn" />
            </div>
          }>
          <Receipt receipt={receipt} parentRef={receiptRef} />
        </Modal>
        {confirmationEmail && (
          <Confirm
            handleOpen={confirmationEmail}
            handleClose={() => setConfirmationEmail(false)}
            handleContinue={sendEmailReceipt}
            title="Send email to patient"
            message="Are you sure you want to send email to patient?"
            primaryBtnTxt="Send email to patient"
            secondaryBtnTxt="Close"
            icon="new-mail"
            loading={loading}
          />
        )}
      </>
    );
  }

  const selectedInvoices = invoices.filter((i) => i.selected);

  const internalDetails = selectedInvoices?.map?.(({ id, balance }) => ({
    id,
    amount_paid: balance
  }));

  const selectedInternalInvoices = [
    {
      amount_cents: Math.round(totalAmount) - (surchargeAmount || 0),
      total_amount_cents: Math.round(totalAmount),
      due_date: new Date(),
      internal_invoice_ids: selectedInvoices.map((i) => i.id),
      memo: '',
      patient: { id: patientId },
      tax_percentage: 0,
      surcharge_amount: temporarySurchargeDisable ? 0 : surchargeAmount,
      aeob: false, // TODO: We need to fix details for kiosk in the future
      type: 'kiosk',
      internal_details: internalDetails
    }
  ];

  switch (show.paymentMethod) {
    case TERM_KIND.elavonLightbox:
      return (
        <LightboxWrapper
          amount={parseInt(totalAmount)}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          appointmentId={appointmentId}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          onApproval={onApproval}
          saveCard={show.saveCard}
          onCancel={handleOnFail}
          onFail={handleOnFail}
          updateBalance={updateBalance}
          updateAmount={updateAmount}
          noInterface={true}
          setReceipt={setReceipt}
          tokenOnly={amount === 0 && (show.saveCard || preAuthForm)}
          preAuthForm={preAuthForm}
          invoices={selectedInvoices}
          selectedVtid={selectedVirtualTerminal?.id}
        />
      );
    case TERM_KIND.elavonCardPresent:
      return (
        <TerminalWrapper
          amount={parseInt(totalAmount)}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          saveCard={show.saveCard}
          appointmentId={appointmentId}
          onClose={onClose}
          updateBalance={updateBalance}
          updateAmount={updateAmount}
          noInterface={true}
          selectedTid={selectedTid}
          onFail={handleOnFail}
          setReceipt={setReceipt}
          onComplete={() => pathname?.includes('portal') && setShow({ ...show, receipt: true })}
          transactionKind="sale"
          preAuthForm={preAuthForm}
          invoices={selectedInvoices}
        />
      );
    case TERM_KIND.elavonCardPresentTokenize:
      return (
        <TerminalWrapper
          amount={parseInt(totalAmount)}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          saveCard={show.saveCard}
          appointmentId={appointmentId}
          onClose={onClose}
          updateBalance={updateBalance}
          updateAmount={updateAmount}
          noInterface={true}
          selectedTid={selectedTid}
          onFail={handleOnFail}
          setReceipt={setReceipt}
          onComplete={() => pathname?.includes('portal') && setShow({ ...show, receipt: true })}
          tokenizedCardId={selectedCard.value}
          transactionKind="tokenizedSale"
          preAuthForm={preAuthForm}
          invoices={selectedInvoices}
        />
      );
    case TERM_KIND.elavonSavedCard:
      return (
        <SavedCardSale
          card={selectedCard}
          amount={parseInt(totalAmount)}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          onSuccess={() => {
            updateAmount && updateAmount(parseInt(amount));
            updateBalance && updateBalance(-parseInt(amount));
            onApproval && onApproval();
            onSuccess && onSuccess();
          }}
          setReceipt={setReceipt}
          onFail={handleOnFail}
          appointmentId={appointmentId}
          invoices={selectedInvoices}
        />
      );
    // case 'save_card':
    //   return (<TerminalWrapper
    //     amount={parseInt(totalAmount)}
    //     surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
    //     patientId={patientId}
    //     practiceId={practiceId}
    //     invoiceId={invoiceId}
    //     saveCard={true}
    //     appointmentId={appointmentId}
    //     onClose={onClose}
    //     updateBalance={updateBalance}
    //     updateAmount={updateAmount}
    //     noInterface={true}
    //     selectedTid={selectedTid}
    //     onFail={handleOnFail}
    //     setReceipt={setReceipt}
    //     onComplete={() => setShow({ ...show, receipt: true })}
    //     transactionKind={'tokenize'}
    //     preAuthForm={preAuthForm}
    //     invoices={selectedInvoices}
    //   />);
    case TERM_KIND.cash:
      return (
        <CashPayment
          amount={parseInt(totalAmount)}
          number={show.checkNumber}
          appointmentId={appointmentId}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          setReceipt={setReceipt}
          onSuccess={() => {
            updateAmount && updateAmount(parseInt(amount));
            updateBalance && updateBalance(-parseInt(amount));
            setShow({ ...show, receipt: true });
            onSuccess && onSuccess();
          }}
          onFail={handleOnFail}
          invoices={selectedInvoices}
        />
      );
    case TERM_KIND.check:
      return (
        <CheckPayment
          amount={parseInt(totalAmount)}
          number={show.checkNumber}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          appointmentId={appointmentId}
          patientId={patientId}
          setReceipt={setReceipt}
          onSuccess={() => {
            setShow({ ...show, receipt: true });
            updateAmount && updateAmount(parseInt(amount));
            updateBalance && updateBalance(-parseInt(amount));
            onSuccess && onSuccess();
          }}
          onFail={handleOnFail}
          invoices={selectedInvoices}
        />
      );
    case TERM_KIND.careCredit:
      return (
        <CareCredit
          amount={parseInt(totalAmount)}
          surcharge={temporarySurchargeDisable ? 0 : surchargeAmount}
          number={show.creditCareNumber}
          appointmentId={appointmentId}
          patientId={patientId}
          practiceId={practiceId}
          invoiceId={invoiceId}
          setReceipt={setReceipt}
          onSuccess={() => {
            setShow({ ...show, receipt: true });
            updateAmount && updateAmount(parseInt(amount));
            updateBalance && updateBalance(-parseInt(amount));
            onSuccess && onSuccess();
          }}
          onFail={handleOnFail}
          invoices={selectedInvoices}
        />
      );
    case TERM_KIND.kiosk:
      return (
        <PaymentsKiosk
          kioskId={selectedKioskId}
          patientId={patientId}
          onSuccess={() => {
            // setShow({ ...show,  });
            onSuccess && onSuccess();
            onClose && onClose();
          }}
          onFail={handleOnFail}
          setReceipt={setReceipt}
          invoices={selectedInvoices}
          selectedInternalInvoices={selectedInternalInvoices}
        />
      );

    default:
      return (
        <>
          <div data-dd-privacy="allow" data-public>
            <div className={cs('flex flex-wrap !pb-4', !preAuthForm && 'h-[calc(100vh-112px)]')}>
              <div
                className={cs(
                  'z-10 !mb-5 h-full w-8/12 md:w-full', // 'z-10 !mb-5 h-fit w-8/12 md:w-full',
                  !preAuthForm && 'overflow-y-scroll !p-4',
                  preAuthForm && 'hidden'
                )}>
                {!preAuthForm && !show.showOverrides.noInvoice && (
                  <>
                    <p className="!mb-4 font-600 text-neutral-800">Select invoices to pay</p>

                    <AppointmentInvoices
                      invoices={invoices}
                      patientId={patientId}
                      hasInvoice={hasInvoice}
                      appointment={appointment}
                      setInvoices={setInvoices}
                      calculateTotalAmount={calculateTotalAmount}
                      setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                      amountInputDisabled={amountInputDisabled}
                    />
                  </>
                )}

                <p className="!my-4 font-600 text-neutral-800">Payment methods</p>

                {ia(cards) && (
                  <div className="!mb-4">
                    <p className="text-sm font-400 text-primary-900">Saved cards</p>

                    <div className="flex flex-wrap !gap-4">
                      {cards.map((c, i) => {
                        return (
                          <CardBox
                            key={i}
                            card={c}
                            show={show}
                            cards={cards}
                            setShow={setShow}
                            setCards={setCards}
                            addressRef={addressRef}
                            selectedCard={selectedCard}
                            setSelectedCard={setSelectedCard}
                          />
                        );
                      })}
                    </div>
                  </div>
                )}

                <label className="text-sm font-400 text-primary-900">
                  {ia(cards) ? 'or choose other payment methods' : 'Choose payment method'}
                </label>

                <div className="grid grid-cols-5 !gap-4 pt-2 md:grid-cols-3 xs:grid-cols-1">
                  {ia(terminals) && show.showOverrides.cardPresent && (
                    <PaymentMethodBox
                      show={show}
                      icon="new-pos"
                      setShow={setShow}
                      label="Card Present"
                      paymentMethod={termKinds.cardPresent}
                      setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                    />
                  )}

                  {ia(virtualTerminals) &&
                    (!preAuthForm || (preAuthForm && virtualTerminals.length > 0)) &&
                    show.showOverrides.online && (
                      <PaymentMethodBox
                        show={show}
                        setShow={setShow}
                        icon="new-credit-card"
                        label="Virtual Terminal"
                        paymentMethod={termKinds.virtual}
                        setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                      />
                    )}

                  {show.cash && show.showOverrides.cash && (
                    <PaymentMethodBox
                      show={show}
                      label="Cash"
                      icon="new-cash"
                      setShow={setShow}
                      paymentMethod={termKinds.cash}
                      setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                    />
                  )}

                  {show.check && show.showOverrides.check && (
                    <PaymentMethodBox
                      show={show}
                      label="Check"
                      icon="new-payment-method-check"
                      setShow={setShow}
                      paymentMethod={termKinds.check}
                      setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                    />
                  )}

                  {hasCareCredit && show.showOverrides.careCredit && (
                    <PaymentMethodBox
                      show={show}
                      setShow={setShow}
                      label="Outside Financing"
                      paymentMethod={termKinds.careCredit}
                      icon="new-credit-card-income"
                      setTemporarySurchargeDisable={setTemporarySurchargeDisable}
                    />
                  )}
                </div>

                {ia(terminals, 1) && show.selectedPaymentOption === termKinds.cardPresent && (
                  <>
                    <label className="!mb-2 !mt-4 text-sm font-400 text-neutral-800">
                      Choose terminal
                    </label>

                    <div className="grid grid-cols-2 !gap-4">
                      {terminals.map((t, index) => {
                        return (
                          <TerminalBox
                            key={index}
                            terminal={t}
                            label={t?.name}
                            selectTerminal={selectTerminal}
                            selectedTerminal={selectedTerminal}
                            // isDisabled={show.selectTerminalLoading} //need to fix
                          />
                        );
                      })}
                    </div>
                  </>
                )}

                {ia(kiosks, 1) && show.selectedPaymentOption === TERM_KIND.kiosk && !kioskId && (
                  <>
                    <label className="!mb-2 !mt-4 text-sm font-400 text-neutral-800">
                      Choose Kiosk
                    </label>

                    <div className="grid grid-cols-2 !gap-4">
                      {kiosks.map((t, index) => {
                        return (
                          <KioskBox
                            key={index}
                            kiosk={t}
                            selectKiosk={setSelectedKioskId}
                            selectedKioskId={selectedKioskId}
                          />
                        );
                      })}
                    </div>
                  </>
                )}

                {ia(virtualTerminals, 1) && show.selectedPaymentOption === termKinds.virtual && (
                  <>
                    <label className="!mb-2 !mt-4 text-sm font-400 text-neutral-800">
                      Choose terminal
                    </label>

                    <div className="grid grid-cols-2 !gap-4 xs:grid-cols-1">
                      {virtualTerminals.map((t, index) => {
                        return (
                          <TerminalBox
                            key={index}
                            terminal={t}
                            label={t?.name}
                            selectTerminal={selectVirtualTerminal}
                            selectedTerminal={selectedVirtualTerminal}
                            // isDisabled={show.selectVirtualTerminalLoading} //need to fix
                          />
                        );
                      })}
                    </div>
                  </>
                )}

                {[termKinds.cardPresent, termKinds.virtual].includes(show.selectedPaymentOption) &&
                  !preAuthForm && (
                    <Checkbox
                      id="save-card"
                      className="!mt-4"
                      isChecked={show.saveCard}
                      label="I want to save this card in the secure vault."
                      handleClick={(v) => setShow({ ...show, saveCard: v.target.checked })}
                    />
                  )}

                {show.selectedPaymentOption === TERM_KIND.check && (
                  <Input
                    className="!mt-4"
                    value={show.checkNumber}
                    label="Please enter the check number:"
                    onChange={(e) => setShow({ ...show, checkNumber: e.target.value })}
                  />
                )}

                {show.selectedPaymentOption === TERM_KIND.careCredit && (
                  <Input
                    className="!mt-4"
                    value={show.creditCareNumber}
                    label="Please enter the outside financing number"
                    onChange={(e) => setShow({ ...show, creditCareNumber: e.target.value })}
                  />
                )}

                {show.error && (
                  <div className="!pt-2 text-left text-sm text-danger-500">{show.error}</div>
                )}
              </div>

              {!preAuthForm && (
                <div className="h-full w-4/12 overflow-y-auto !p-4 md:w-full">
                  <p className="!mb-4 font-600 text-neutral-800">Summary</p>

                  <div className="-ml-4 -mr-4 bg-white">
                    {iaRa(invoices)
                      ?.filter((i) => i?.selected)
                      ?.map(({ id, balance, discount }) => {
                        return (
                          <div
                            key={id}
                            className="flex justify-between border-x-0 border-b border-t-0 border-solid border-b-neutral-100 !p-4">
                            <label className="text-sm font-500 text-primary-900">
                              Invoice #{id}
                            </label>

                            <p className="text-sm font-500 text-primary-900">
                              {mString(balance + (Number(discount?.amount_cents) || 0))}
                            </p>
                          </div>
                        );
                      })}

                    <div className="flex justify-between border-x-0 border-b border-t-0 border-solid border-b-neutral-100 !p-4">
                      <p className=" text-sm text-neutral-600">Subtotal</p>
                      <p className=" text-sm font-500 text-primary-900">
                        {mString(amount + sumBy(selectedInvoices, 'discount.amount_cents'))}
                      </p>
                    </div>

                    {/* Don't remove */}
                    {/* <div className="flex justify-between border-x-0 border-t-0 border-b border-solid border-b-neutral-100 !p-4">
                    <div>
                      <div className="flex items-center !gap-2">
                        <p className="text-sm text-neutral-600">Apply available credit</p>
                        <Switch
                          onChange={() => console.log('Apply available credit')}
                          checked={false}
                        />
                      </div>

                      <p className="text-sm font-500 text-primary-900">{mString(12000)}</p>
                    </div>

                    <p className="text-sm font-500 text-primary-900">{mString(0)}</p>
                  </div> */}

                    {hasSurcharge?.surcharge &&
                      hasSurcharge?.surcharge_enabled &&
                      !show.showOverrides.surchargeDisable && (
                        <div className="flex justify-between border-x-0 border-b border-t-0 border-solid border-b-neutral-100 !p-4">
                          <div className="flex items-center !gap-2">
                            <p className="text-sm text-neutral-600">Technology Fee</p>
                            <Switch
                              onChange={() =>
                                setTemporarySurchargeDisable(!temporarySurchargeDisable)
                              }
                              checked={!temporarySurchargeDisable}
                            />
                          </div>

                          <p
                            className={cs(
                              'text-sm font-500 text-primary-900',
                              temporarySurchargeDisable && 'opacity-50'
                            )}>
                            {mString(surchargeAmount || cashDiscountAmount)}
                          </p>
                        </div>
                      )}

                    <div className="flex justify-between border-x-0 border-b border-t-0 border-solid border-b-neutral-100 !p-4">
                      <p className="text-sm text-neutral-600">Discount Applied</p>
                      <p className="text-sm font-500 text-primary-900">
                        {mString(
                          (!temporarySurchargeDisable ? 0 : cashDiscountAmount * -1) -
                            sumBy(selectedInvoices, 'discount.amount_cents')
                        )}
                      </p>
                    </div>

                    <div className="!p-4">
                      <div className="flex items-center justify-between !gap-2 !rounded-lg bg-gradient-to-r from-primary-50 to-primary-100 !p-4">
                        <p className="text-sm font-500 text-primary-900">Total payment amount</p>

                        <CurrencyInput
                          key={temporarySurchargeDisable}
                          parentClassName="!w-32"
                          id="total-payment-amount"
                          disabled={amountInputDisabled}
                          minValue={sumBy(selectedInvoices, 'balance')}
                          onFocus={() => setTemporarySurchargeDisable(true)}
                          value={temporarySurchargeDisable ? amount : totalAmount}
                          className="text-right text-sm font-600 uppercase text-primary-900"
                          onValueChange={(value) => {
                            const newAmount = Number(value) - (surchargeAmount || 0);

                            if (!amountInputDisabled) {
                              setTemporarySurchargeDisable(true);
                              setExtraCredit(newAmount - sumBy(selectedInvoices, 'balance'));
                              setAmount(newAmount);
                            }
                          }}
                        />
                      </div>

                      {extraCredit > 0 && (
                        <div className="!p-4">
                          <div className="flex items-center  !gap-2 !rounded-lg">
                            <Icon icon="new-info" color="primary" />
                            <div>
                              <p className="text-sm  text-primary-500">
                                Payment will result in a new credit of
                              </p>
                              <p className="text-sm font-600 text-primary-500">
                                {mString(extraCredit)}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {!show.showOverrides.noFooter && preAuthForm && (
            <div className={`z-10  flex w-full justify-center gap-2  ${hideButton && 'opacity-0'}`}>
              <Button
                outlined
                text="Cancel"
                color="neutral"
                onClick={onClose}
                data-qa="cancel-btn"
              />

              <Button
                text="Capture Card"
                onClick={(e) => startPayment(e)}
                disabled={!show.selectedPaymentOption || disabled}
              />
            </div>
          )}
          {!show.showOverrides.noFooter && !preAuthForm && (
            <div className="fixed bottom-0 left-0 right-0 z-10 flex justify-between bg-white p-5 shadow-[0px_-2px_16px_rgba(0,0,0,0.06)]">
              <Button
                onClick={onClose}
                text="Cancel"
                outlined
                color="neutral"
                data-qa="cancel-btn"
              />
              <Tippy
                content="Please choose payment method in order to proceed to payment."
                disabled={show.selectedPaymentOption && true}
                className="tippy-dark">
                <div className="flex h-10">
                  <Button
                    data-qa="payment-btn"
                    text={
                      show.selectedPaymentOption === TERM_KIND.elavonLightbox
                        ? 'Proceed to Payment'
                        : `Pay ${mString(totalAmount)}`
                    }
                    onClick={(e) => startPayment(e)}
                    disabled={!show.selectedPaymentOption || disabled || totalAmount <= 0}
                  />
                </div>
              </Tippy>
            </div>
          )}
        </>
      );
  }
};

export default withErrorBoundary(Configurations);

export const termKind = TERM_KIND;
