import React from 'react';
import { Outlet } from 'react-router-dom';
import UserActivateAdvertisement from './Advertisement';
import Icon from '../../shared/Icon/Icon';
import UserActivateProgress from './Progress';

const UserActivateWrapper = () => {
  return (
    <div className="flex h-screen w-screen flex-row">
      <div className="flex h-screen w-2/5 flex-col justify-between overflow-y-scroll px-[3rem] py-[1.5rem] sm:w-full md:w-1/2 md:px-2 md:py-2">
        <div className="mt-4 flex w-full flex-row justify-start">
          <Icon icon="logo" size={200} />
        </div>
        <Outlet />
        <UserActivateProgress />
      </div>
      <UserActivateAdvertisement wrapperClassName="w-3/5 sm:hidden md:w-1/2" />
    </div>
  );
};

export default UserActivateWrapper;
