import { permissions } from 'components/state';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useUIContext } from '../../../../../lib/context/UIContext/UIContext';
import Icon from '../../../../shared/Icon/Icon';
import ChartLinks from './components/ChartLinks';
import LoadAndExport from './components/LoadAndExport';
import MyScribeAILink from './components/MyScribeAILink';
import NavbarPopover from './components/NavbarPopover';
import SaveAndPreview from './components/SaveAndPreview';
import SignAndLock from './components/SignAndLock';
import TreatmentPlan from './components/TreatmentPlan';

const Navbar = ({ forwardedRef }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { device } = useUIContext();
  const { is_admin } = useRecoilValue(permissions);

  return (
    <div
      className="flex flex-wrap gap-2 bg-primary-900 !px-4 py-[10px] pl-3 pr-6 sm:flex-col md:items-center md:justify-between lg:items-center lg:justify-between xl:items-center xl:justify-between"
      ref={forwardedRef}
      data-dd-privacy="allow">
      <div className="item-center flex gap-x-3">
        <div
          className="mt-[2px] flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-primary-800 hover:!bg-primary-700"
          onClick={() => navigate(`/portal/charts/${id}/clinical-notes`)}>
          <Icon icon="new-arrow-left" size="12px" color="white" stroke className="cursor-pointer" />
        </div>
        <Icon icon="logo-white" color="white" size={97} />
        <ChartLinks />
        {is_admin ? <MyScribeAILink /> : null}
      </div>
      <div className="flex items-center gap-x-4 sm:justify-end">
        <div className="flex items-center gap-x-3">
          {device === 'desktop' || device === 'laptop' ? (
            <>
              <TreatmentPlan />
            </>
          ) : (
            <NavbarPopover />
          )}
          <SignAndLock />
          <SaveAndPreview />
          <LoadAndExport />
        </div>
        <div
          data-qa="close-clinical-note"
          className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-md bg-primary-800 hover:!bg-primary-700"
          onClick={() => navigate(`/portal/charts/${id}/clinical-notes`)}>
          <Icon
            icon="new-close"
            color="primary"
            shade={100}
            size="10px"
            stroke
            className="cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
