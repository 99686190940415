import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { EraContext } from './EraContext';
import { useEras } from '../../hooks/queries/era/useEras';
import { useEra } from '../../hooks/queries/era/useEra';
import { useCodes } from '../../hooks/queries/codes/useCodes';
import { arrayToObject, ia } from '../../helpers/utility';
import { useUnmatchedClaims } from '../../hooks/queries/claims/useUnmatchedClaims';

export const EraContextProvider = ({ children }) => {
  const { type = 'all', id } = useParams();
  const [era, setEra] = useState();
  const [unmatchedClaims, setUnmatchedClaims] = useState();
  const [selectedClaim, setSelectedClaim] = useState();
  const [allocateEob, setAllocateEob] = useState(false);
  const [selectedUnmatchedClaim, setSelectedUnmatchedClaim] = useState();
  const [amount, setAmount] = useState(0);
  const [procedures, setProcedures] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    searchTerm: '',
    searchType: 'Patient',
    page: 1,
    limit: 25,
    search: '',
    column: 'paid_date',
    sort: 'desc',
    state: type,
    withCount: true,
    startDate: moment().subtract(1, 'year').toDate(),
    endDate: moment().endOf('day').toDate()
  });
  const { data, isLoading } = useEras({ params: filters, dependencies: [filters] });
  const eras = data?.eras;
  const eraCount = data?.count;
  const { data: eraData, isLoading: eraLoading } = useEra({ params: { id }, dependencies: [id] });
  const { data: codesData, isLoading: codesLoading } = useCodes({
    params: { services: ['adjustment_reason', 'claim_adjustment_reason'] }
  });
  const { data: unmatchedClaimsData, isLoading: unmatchedClaimsLoading } = useUnmatchedClaims({
    params: { patientId: selectedClaim?.claim?.pcn },
    dependencies: [selectedClaim?.claim?.pcn]
  });
  const [status_code, setStatusCode] = useState(null);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, state: type, page: 1 }));
  }, [type]);

  useEffect(() => {
    if (pathname === '/portal/beyond-billing/era-eob')
      navigate('/portal/beyond-billing/era-eob/all');
  }, [pathname]);

  useEffect(() => {
    if (eraData?.era) {
      setEra(eraData?.era);
    }
  }, [eraData?.era]);

  useEffect(() => {
    setUnmatchedClaims(unmatchedClaimsData?.claims);
  }, [unmatchedClaimsData]);

  return (
    <EraContext.Provider
      value={{
        eras,
        isLoading,
        filters,
        setFilters,
        era,
        setEra,
        eraLoading,
        eraCount,
        type,
        codes: ia(codesData?.codes) ? arrayToObject(codesData?.codes, 'code', 'description') : [],
        selectedClaim,
        setSelectedClaim,
        unmatchedClaims,
        selectedUnmatchedClaim,
        setSelectedUnmatchedClaim,
        allocateEob,
        setAllocateEob,
        amount,
        setAmount,
        procedures,
        setProcedures,

        status_code,
        setStatusCode
      }}>
      {children}
    </EraContext.Provider>
  );
};
