import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { interimApi } from 'api/InterimApi';
import useOutsideClick from 'lib/hooks/useOutsideClick';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { permissions } from 'components/state';
import AvailableTimeComponent from './AvailableTime/AvailableTimeComponent';

export default function PractitionerComponent({ practitioner }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(practitioner.color);
  const [modalState, setModalState] = useState(false);
  const userPermissions = useRecoilValue(permissions);
  const colorPickerRef = useRef();
  const practitionerRef = useRef();
  const navigate = useNavigate();

  const updateColor = async (newColor) => {
    try {
      let params = {
        practitioner_id: practitioner.id,
        fields: { color: newColor }
      };
      await interimApi('/api/practice/settings/practice_user/update', params, navigate);
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
    if (color !== practitioner.color) {
      updateColor(color);
    }
  };

  useOutsideClick(colorPickerRef, () => handleClose());

  const handleClose = () => {
    setDisplayColorPicker(false);
    if (color !== practitioner.color) {
      updateColor(color);
    }
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const showModal = () => {
    setModalState(true);
  };

  const hideModal = () => {
    setModalState(false);
  };

  return (
    <div className="grid grid-cols-3 items-center">
      <div className="text-sm">{practitioner.name}</div>
      <div className="text-sm" ref={colorPickerRef}>
        <div>
          <div
            className="h-[26px] w-[50px] cursor-pointer rounded-sm border border-solid border-neutral-300 !p-1"
            onClick={(e) => handleClick(e)}>
            <div style={{ background: color }} className="h-full w-full" />
          </div>
          {displayColorPicker ? (
            <div className="relative">
              <SketchPicker className="absolute" color={color} onChange={(e) => handleChange(e)} />
            </div>
          ) : null}
        </div>
      </div>
      {userPermissions?.member?.read && (
        <>
          <div className="text-sm">
            <Button primary onClick={() => showModal()} text="Available Time" size="small" />
          </div>
          <Modal
            slideFromRight
            className="!w-[720px] bg-white"
            title={`Practitioner ${practitioner.name}`}
            footer={
              <div className="flex w-full justify-between">
                <Button outlined text="Cancel" color="neutral" onClick={hideModal} />
                <Button
                  text="Save"
                  onClick={() => {
                    practitionerRef?.current?.handleSave();
                  }}
                  disabled={!userPermissions?.member?.update}
                />
              </div>
            }
            handleOpen={modalState}
            handleClose={hideModal}>
            <AvailableTimeComponent
              data={practitioner}
              type={'practitioner'}
              ref={practitionerRef}
            />
          </Modal>
        </>
      )}
    </div>
  );
}
