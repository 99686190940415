import { camelCase } from 'lodash';

import { WORD_MAPPING } from '../constants';

export function replaceStrongWithDiv(htmlString) {
  // Use a regular expression to match <strong> tags and their following content
  const regex = /(<strong>.*?<\/strong>)([^<]*)/g;

  // Replace each match with a <div> containing the <strong> and its value
  const finalHTML = htmlString.replace(regex, '<div>$1$2</div>');
  return finalHTML;
}

const convertToCamelCase = (text) => {
  if (text.match(/^(0[1-9]|1[0-2])[-/](0[1-9]|[12][0-9]|3[01])[-/]\d{4}$/)) {
    return text; // Return as-is if it's a date
  }
  return camelCase(text);
};

export function parseMedicalNote(note) {
  // Remove all instances of ### to handle section headings
  const processedNote = note.replace(/###/g, '');
  const sections = processedNote
    .split(/\n+/)
    .map((s) => s.trim())
    .filter(Boolean);
  const structuredData = {};
  let currentSection = null;

  sections.forEach((section) => {
    let heading = null;
    let content = '';

    // Match bold headings (with **) or regular headings
    const boldMatch = section.match(/^\s*[*\- ]*\*\*([^:*]+):?\*\*\s*(.*)/);
    const colonMatch = !boldMatch && section.match(/^\s*([^:]+):\s*(.*)/);

    if (boldMatch) {
      heading = boldMatch[1].trim();
      content = boldMatch[2].trim();
    } else if (colonMatch) {
      heading = colonMatch[1].trim();
      content = colonMatch[2].trim();
    }

    if (heading) {
      // Find matching key in WORD_MAPPING (case-insensitive)
      const mappedKey = Object.keys(WORD_MAPPING).find((key) => {
        if (key === 'assessment') {
          // Exact match for assessment (case-insensitive)
          return WORD_MAPPING[key].some((item) => item.toLowerCase() === heading.toLowerCase());
        }
        return WORD_MAPPING[key].some(
          (item) =>
            heading.toLowerCase().includes(item.toLowerCase()) ||
            item.toLowerCase().includes(heading.toLowerCase())
        );
      });

      currentSection = convertToCamelCase(mappedKey || heading);
      structuredData[currentSection] = content;
    } else if (currentSection) {
      structuredData[currentSection] += ` ${section}`;
    }
  });

  // Remove empty properties
  Object.keys(structuredData).forEach((key) => {
    if (structuredData[key].trim() === '') {
      delete structuredData[key];
    }
  });

  delete structuredData['wordsToIgnore'];
  return JSON.stringify(structuredData, null, 2);
}

export function extractOnlyClinicalNoteKeys(inputObject) {
  const allowedKeys = [
    'id',
    'user_id',
    'practitioner_id',
    'created_at',
    'updated_at',
    'practice_id',
    'notes',
    'present_illness',
    'state',
    'appointment_id',
    'subjective',
    'objective',
    'assessment',
    'plan',
    'cpt_codes',
    'icd_10_codes',
    'other_complaints',
    'pdf_selection',
    'locked',
    'exam',
    'test',
    'epn',
    'goals',
    'mha',
    'lock_history',
    'goals_narrative',
    'charts3d',
    'no_known_medications',
    'components_order',
    'previous_state',
    'treatment_plan_id'
  ];

  return Object.keys(inputObject || {})
    .filter((key) => allowedKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = inputObject[key];
      return obj;
    }, {});
}
