import React, { memo, useEffect } from 'react';
import Chat from './components/Chat';
import PatientOverview from '../../../CurrentNote/PatientOverview/PatientOverview';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { MyTemplateContextProvider } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContextProvider';
import Breadcrumbs from '../../components/Breadcrumbs';
import { useLocation } from 'react-router-dom';
import TypeOfNotesSidebar from './components/TypeNotesSidebar/TypeOfNotesSideBar';

const MyOwnTemplate = () => {
  const { pathname } = useLocation();
  const { breadcrumb } = useMyScribeAIContext();
  const { breadcrumbs, navigateToBreadcrumb, pushBreadcrumb } = breadcrumb;

  useEffect(() => {
    if (!breadcrumbs?.length || breadcrumbs?.length > 1) return;

    pushBreadcrumb({
      label: 'Map My Own Templates',
      name: '/map-my-own-template',
      path: pathname
    });
  }, [breadcrumbs]);

  return (
    <MyTemplateContextProvider>
      <div className="relative border-b border-gray-300 p-2 shadow-sm">
        <Breadcrumbs breadcrumbs={breadcrumbs} onClickBreadcrumb={navigateToBreadcrumb} />
      </div>
      <div className="flex h-screen">
        <div className="top-[52px] z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200">
          <TypeOfNotesSidebar className="relative h-full bg-white" />
        </div>
        <div className="flex h-[calc(100vh-50px)] w-full flex-col">
          <PatientOverview />
          <Chat />
        </div>
      </div>
    </MyTemplateContextProvider>
  );
};

export default memo(MyOwnTemplate);
