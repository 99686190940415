import React, { useEffect, useRef, useState } from 'react';
import Textarea from '../../../../../../shared/Textarea/Textarea';
import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const AdditionalComments = () => {
  const { selectedTreatmentPlan, setSelectedTreatmentPlan } = useClinicalNoteContext();
  const [note, setNote] = useState('');
  const ref = useRef();

  useEffect(() => {
    if (selectedTreatmentPlan?.additional_comments) {
      setNote(selectedTreatmentPlan?.additional_comments);
    }
  }, [selectedTreatmentPlan]);

  const onChange = (value) => {
    setNote(value);
    setSelectedTreatmentPlan({ ...selectedTreatmentPlan, additional_comments: value });
  };

  return (
    <Textarea
      label="Additional comments"
      placeholder="Leave comments here"
      name="treatment-plan-additional-comments"
      data-qa="treatment-plan-additional-comments"
      value={note}
      onChange={(event) => onChange(event.target.value)}
      transcribing
      forwardedRef={ref}
    />
  );
};

export default AdditionalComments;
