import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { cloneDeep } from 'lodash';
import { useRecoilValue } from 'recoil';

import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { clinicalNote as clinicalNoteState } from 'components/state';

import ModalFooter from '../../../../../../../../shared/Modal/ModalFooter/ModalFooter';

import EmptyPastNotes from './components/EmptyPastNotes';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from './configs';

export function Table({ breadcrumb }) {
  const { setSteps, setStep } = breadcrumb;
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const { id } = useParams();

  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows, onRemoveComponent } =
    useChatContextApi();
  const { gridApi } = useTableContext();
  const selectedTableNodes = selectedTableRows?.['past_notes']?.selectedNodes;

  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedRowsData = selectedNodes.map((node) => node.data);

  const currentNote = useRecoilValue(clinicalNoteState);

  const { data } = useClinicalNote({
    params: { patientId: id, withDocumentSign: true },
    dependencies: [id],
    options: {
      select: (data) => ({
        clinicalNote: data?.clinicalNote.filter(
          (clinicalNote) => clinicalNote?.id && clinicalNote?.id !== currentNote.id
        )
      })
    }
  });

  const clinicalNotes = data?.clinicalNote;

  useEffect(() => {
    setSteps([
      {
        title: 'Past Notes',
        name: 'start'
      }
    ]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      setSelectedNodes(selectedTableNodes);
      selectedTableNodes?.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  const deletePastNotesFormSelectedTableRows = useCallback(() => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['past_notes'];
    setSelectedTableRows(newSelectedTableRows);
  }, [selectedTableRows, setSelectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;

    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  }, [gridApi]);

  const onDeleteMacroTag = () => {
    deletePastNotesFormSelectedTableRows();
    onRemoveComponent();
    setComponentToAdd(null);
  };

  const onAddTag = () => {
    if (!selectedNodes?.length) {
      deletePastNotesFormSelectedTableRows();
      onRemoveComponent();
    }

    setSelectedTableRows((prev) => ({
      ...prev,
      past_notes: {
        selectedNodes,
        data: selectedNodes.map((node) => node.data),
        status: !selectedNodes?.length
          ? 'none'
          : selectedNodes?.length === clinicalNotes?.length
            ? 'all'
            : 'partial'
      }
    }));
    onAddComponent();
  };

  return (
    <div className="ag-theme-quartz past_notes_table !mb-0 h-full">
      <AGTable
        onSelectionChanged={onSelectionChanged}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDef={getColDefs(practiceTimezone)}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={clinicalNotes}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        reactiveCustomComponents
        noRowsOverlayComponent={EmptyPastNotes}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px] bg-white">
            <Skeleton count={24} />
          </div>
        )}
      />
      {clinicalNotes?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon key={1} icon="trash" className="cursor-pointer" onClick={onDeleteMacroTag} />
            ),
            <Button
              key={2}
              text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Past Notes`}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!ml-4 !py-2 !pl-4 !pr-4"
              onClick={onAddTag}
              disabled={!selectedRowsData?.length && !selectedTableNodes?.length}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}
