import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useNavigate, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import moment from 'moment-timezone';
import { useRecoilState, useRecoilValue } from 'recoil';

import { updateAppointmentV2 } from 'api/Appointment';

import { NewAppointmentContextProvider } from 'lib/context/Appointment/NewAppointmentContextProvider';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';
import { DashboardContextProvider } from 'lib/context/Dashboard/DashboardContextProvider';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia, iaRa, mapValues, orderById, reShapeProcedures } from 'lib/helpers/utility';
import { useAppointmentEvents } from 'lib/hooks/queries/appointment/useAppointmentEvents';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import { useServices } from 'lib/hooks/queries/services/useServices';
import usePageTitle from 'lib/hooks/usePageTitle';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import { showAlert } from 'components/shared/Alert/Alert';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import EventPreview from '../../appointment/EventForm/EvenPreview/EventPreview';
import { isColorDark } from '../../appointment/EventForm/lib';
import NewAppointment from '../../appointment/NewAppointment/NewAppointment';
import PreviewAppointment from '../../appointment/PreviewAppointment/PreviewAppointment';
import practiceState, { currentPractice } from '../../practiceState';

import NewRbcToolbar from './RBCToolbar/NewRbcToolbar';
import { getRangeDateBasedOnView, views } from './lib';

import './style/RBCalendar.scss';
import './style/react-big-calendar.scss';

const DnDCalendar = withDragAndDrop(Calendar);

const RBCalendar = (props) => {
  const {
    selectedView = null,

    className,
    position
  } = props;
  const [appointment, setAppointment] = useState(null);
  const [event, setEvent] = useState(null);
  const [showPreviewAppointment, setShowPreviewAppointment] = useState(false);
  const [showNewAppointment, setShowNewAppointment] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState({ appointments: false, appointment: false });
  const [defaultDate, setDefaultDate] = useState(new Date());
  const { working_hours, display_settings } = useRecoilValue(currentPractice);
  const [currentView, setCurrentView] = useState(
    display_settings?.calendar?.defaultView?.value.toLowerCase() || 'day'
  );
  const practice = useRecoilValue(currentPractice);
  const [sortedProviders, setSortedProviders] = useState(null);
  const [rangeDate, setRange] = useState(getRangeDateBasedOnView(currentView, defaultDate));
  const [blockScroll, allowScroll] = useScrollBlock();
  const [selectedPatient, setSelectedPatient] = useRecoilState(practiceState.selectedPatient);
  const [selectedDateTime, setSelectedDateTime] = useState({ start: null, end: null });
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const { device } = useUIContext();
  const [defaultTimeIncrement, setDefaultTimeIncrement] = useState(15);
  const [resourceId, setResourceId] = useState(null);
  const { filters } = useDashboardContext();
  const queryClient = useQueryClient();
  const { data: servicesData } = useServices();
  const services = servicesData?.services || [];

  const param = {
    currentView,
    getDates,
    navigate,
    rangeDate,
    selectedView,
    withEvents: true,
    setEvent
  };
  const { data, isLoading } = useAppointmentEvents({
    ...param,
    filters: mapValues({
      ...filters,
      practitioners: filters?.practitioners?.values?.map((item) => item.value),
      serviceType: filters?.serviceType?.values?.map((item) => item.value),
      appointmentType: filters?.appointmentType?.values?.map((item) => item.value),
      appointmentTags: filters?.appointmentTags?.values?.map((item) => item.id),
      resources: filters?.resources?.values?.map((item) => item.value),
      arrivalStatus: filters?.arrivalStatus?.values?.map((item) => item.value)
    })
  });
  const events = iaRa(data?.events);
  const pendingCount = data?.pendingCount || 0;
  usePageTitle('Calendar');
  const { id } = useParams();

  const { data: resourceData } = useResourcesAsOptions();
  const resources = resourceData?.resources || [];
  const { data: allPractitioners } = usePractitioners();
  const practitioners = iaRa(allPractitioners?.practitioners);

  useEffect(() => {
    if (selectedView) {
      setCurrentView(selectedView);
      return;
    }
    if (display_settings?.calendar?.defaultView?.value) {
      setCurrentView(display_settings?.calendar?.defaultView?.value.toLowerCase());
    } else {
      setCurrentView('day');
    }
    if (display_settings?.calendar?.defaultTimeIncrement?.value) {
      setDefaultTimeIncrement(display_settings?.calendar?.defaultTimeIncrement?.value);
    }
  }, [display_settings]);

  const providersOrderAndId = display_settings?.calendar?.providerView?.selectedProviders;
  useEffect(() => {
    if (ia(providersOrderAndId)) {
      const filtertProviders = practitioners?.filter((p) =>
        providersOrderAndId.find((fp) => fp.id === p.id)
      );
      const sortProviders = orderById(filtertProviders, providersOrderAndId);
      setSortedProviders(sortProviders);
    }
  }, [display_settings, practitioners]);

  const onAppointmentDrop = (event) => {
    if (event.event.eventType === 'event') {
      showAlert({
        title: 'Cannot reschedule events from the calendar!',
        color: 'warning'
      });
      return;
    }

    setShowConfirmationModal(true);
    setCurrentEvent(event);
  };

  const rescheduleAppointment = async (updatedAppointment) => {
    setLoading({ ...loading, reschedule: true });
    try {
      const appointment = updatedAppointment.event.appointment;

      const params = {
        id: appointment.id,
        starts_at: updatedAppointment.start,
        ends_at: updatedAppointment.end,
        practitioner_id:
          currentView === 'provider' && updatedAppointment.resourceId
            ? updatedAppointment.resourceId
            : appointment.practitioner_id,
        resource_id:
          currentView === 'resource' && updatedAppointment.resourceId
            ? updatedAppointment.resourceId
            : updatedAppointment.resource_id || undefined
      };

      // const res = await interimApi('/api/appointment/update', params, navigate);

      const { affectedRows } = await updateAppointmentV2(navigate, params);

      queryClient.invalidateQueries(['appointment-events']);
      if (affectedRows > 0) {
        showAlert({ message: 'Appointment was updated successfully!' });
        setShowConfirmationModal(false);
      } else {
        showAlert({ message: 'There was an error updating this appointment', color: 'danger' });
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }

    setLoading({ ...loading, reschedule: false });
  };

  const eventStyleGetter = (event) => {
    let styleClass = '';
    if (event.eventType === 'event') {
      const practitionerColor = event.color;
      const isDark = isColorDark(practitionerColor);
      const fontColor = isDark ? 'white' : 'black';
      const serviceColor = 'white';
      const isCancelled = false;

      styleClass = {
        style: {
          borderLeft: `10px solid ${serviceColor}`,
          background: practitionerColor,
          color: fontColor,
          cl: 10,
          textDecoration: isCancelled && currentView !== 'agenda' ? 'line-through red' : 'none'
        }
      };

      if (isCancelled && currentView === 'agenda') {
        styleClass = {
          ...styleClass,
          className: 'isCancelledContentRBC'
        };
      }

      if (data?.length < 36) {
        styleClass = {
          ...styleClass,
          className: `${styleClass.className} slotInlineContentRBC`
        };
      }
    } else {
      const { appointment } = event;
      const { service_id } = appointment;

      let practitionerColor = 'transparent';
      let serviceColor = 'white';
      const fontColor = 'black';
      const isCancelled = appointment?.status === 'cancelled' ? true : false;

      if (appointment?.practitioner?.practice?.color)
        practitionerColor = appointment?.practitioner?.practice?.color;

      const appointmentFirstServiceId = ia(service_id) && service_id[0];
      const appointmentService = services.find(
        (service) => service.id == appointmentFirstServiceId
      );

      if (appointmentService) serviceColor = appointmentService.color;

      styleClass = {
        style: {
          borderLeft: `10px solid ${serviceColor}`,
          background: practitionerColor,
          color: fontColor,
          cl: 10,
          textDecoration: isCancelled && currentView !== 'agenda' ? 'line-through red' : 'none'
        }
      };

      if (isCancelled && currentView === 'agenda') {
        styleClass = {
          ...styleClass,
          className: 'isCancelledContentRBC'
        };
      }

      if (appointmentService?.time_length < 30) {
        styleClass = {
          ...styleClass,
          className: `${styleClass.className} slotInlineContentRBC`
        };
      }
    }

    return styleClass;
  };

  //preview appointment modal
  const handleShowPreviewAppointment = () => {
    blockScroll();
    setShowPreviewAppointment(true);
  };
  const hidePreviewAppointment = () => {
    allowScroll();
    setShowPreviewAppointment(false);
    setSelectedPatient({ ...selectedPatient, id: null });
    setTimeout(() => {
      setAppointment(null);
    }, 500);

    if (id) navigate('/portal/appointments', { replace: true });
  };

  function getDates(date, view) {
    const { startDate, endDate } = getRangeDateBasedOnView(view, date, practice?.timezone);

    setSelectedDateTime({ start: startDate, end: endDate });

    setRange({ startDate, endDate });
    setDefaultDate(date);
    setCurrentView(view);
  }
  const dateWithTimeZone = (timeZone, hour, minute, second) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const date = new Date(Date.UTC(year, month, day, hour, minute, second));
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    const offset = utcDate.getTime() - tzDate.getTime();

    date.setTime(date.getTime() + offset);
    return date;
  };

  const onSelectSlot = ({ start, end, resourceId }) => {
    const startDateTime = moment.tz(start, practice?.timezone);
    const endDateTime = moment.tz(end, practice?.timezone);
    setSelectedDateTime({ start: startDateTime, endDateTime });
    setShowNewAppointment(true);

    if (currentView === 'resource' && resourceId) {
      setResourceId(resourceId);
    } else {
      setResourceId(null);
    }

    if (currentView === 'provider' && resourceId) {
      const practitioner = practitioners?.find((item) => item.id === resourceId);
      setSelectedPractitioner(practitioner);
    }
  };

  let minTime = dateWithTimeZone(practice?.timezone, 8, 0, 0);
  let maxTime = dateWithTimeZone(practice?.timezone, 20, 0, 0);

  if (working_hours?.startsAt && working_hours?.endsAt) {
    minTime = dateWithTimeZone(
      practice?.timezone,
      working_hours?.startsAt.hour,
      working_hours?.startsAt.minute,
      0
    );
    maxTime = dateWithTimeZone(
      practice?.timezone,
      working_hours?.endsAt.hour,
      working_hours?.endsAt.minute,
      0
    );
  }
  moment.tz.setDefault(practice?.timezone);
  moment.locale('ko', {
    week: {
      dow: 1,
      doy: 1
    }
  });

  const localizer = momentLocalizer(moment);
  const formats = {
    agendaHeaderFormat: (date, culture, localizer) => {
      return localizer.format(date?.start, 'dddd MMM Do', culture);
    }
  };

  moment.tz.setDefault(practice?.timezone);
  moment.locale('ko', {
    week: {
      dow: 1,
      doy: 1
    }
  });

  useEffect(() => {
    if (id) {
      setAppointment({ id });
    }
  }, [id]);

  const CustomShowMore = ({ slotDate }) => {
    const onClickSeeMore = () => {
      getDates(slotDate, 'day');
      setTimeout(() => {
        setShowNewAppointment(false);
      }, 1);
    };
    return (
      <div onClick={onClickSeeMore} className="cursor-pointer px-2 z-50 text-sm font-600">
        See all
      </div>
    );
  };

  return (
    <>
      <div
        className={cs(
          'RBCalendar',
          currentView === 'day' && 'RBCalendar--day',
          currentView === 'week' && 'RBCalendar--week',
          loading?.appointments && 'loading',
          className
        )}
        style={{ gridArea: position }}
        data-dd-privacy="allow">
        <NewRbcToolbar
          {...{
            currentView,
            handleNewAppointment: () => setShowNewAppointment(true),
            rangeDate,
            practice,
            getDates,
            defaultDate,
            pendingCount
          }}
        />
        {isLoading ? (
          <Skeleton height="90px" count={4} />
        ) : (
          ia(practitioners) && (
            <ErrorBoundary
              FallbackComponent={ErrorMessage}
              isImage
              className="flex min-h-[400px] flex-col items-center justify-center p-14">
              <DnDCalendar
                key={currentView + events.length}
                defaultDate={defaultDate}
                localizer={localizer}
                events={events}
                startAccessor="starts_at"
                endAccessor="ends_at"
                selectable={true}
                resizable={true}
                length={1}
                event
                messages={{
                  event:
                    device === 'desktop' ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div class="customAgendaTitle">
                        <p>Patient</p>
                        <p>Service</p>
                        <p>Balance</p>
                        <p>Provider</p>
                        <p>Status</p>
                      </div></div>`
                        }}></div>
                    ) : (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: `<div class="customAgendaTitle">
                        <p>Appointment details</p>`
                        }}></div>
                    )
                }}
                step={defaultTimeIncrement / 2}
                min={minTime}
                max={maxTime}
                onEventDrop={onAppointmentDrop}
                eventPropGetter={eventStyleGetter}
                onSelectEvent={(event) => {
                  if (event.eventType === 'event') {
                    delete event.title;
                    setEvent(event);
                  } else {
                    handleShowPreviewAppointment();
                    setAppointment(event.appointment);
                  }
                }}
                onSelectSlot={onSelectSlot}
                defaultView={currentView}
                views={views}
                resources={
                  currentView === 'resource'
                    ? resources.map((r) => ({ id: r.value, name: r.label }))
                    : currentView === 'provider' && ia(sortedProviders)
                      ? sortedProviders
                      : currentView === 'provider'
                        ? practitioners
                        : null
                }
                resourceIdAccessor="id"
                resourceTitleAccessor={
                  currentView === 'provider'
                    ? (practitioner) => `${practitioner.f_name} ${practitioner.l_name}`
                    : (resource) => `${resource.name}`
                }
                components={{
                  showMore: CustomShowMore,
                  toolbar: () => null,
                  practitioner: {
                    header: () => null
                  }
                }}
                formats={formats}
              />
            </ErrorBoundary>
          )
        )}

        {appointment && (
          <PreviewAppointment
            appointment={appointment}
            loading={loading?.appointment}
            setAppointment={setAppointment}
            services={reShapeProcedures(services)}
            showPreviewAppointment={showPreviewAppointment}
            hidePreviewAppointment={hidePreviewAppointment}
            setRefetchAppointments={() => {}}
            practitioners={allPractitioners?.practitionersOptions}
          />
        )}
        {event && (
          <EventPreview
            {...{
              event,
              isOpen: !!event,
              setEvent,
              practitioners: allPractitioners?.practitionersOptions
            }}
          />
        )}
        {ia(services) && showNewAppointment && (
          <NewAppointmentContextProvider>
            <NewAppointment
              selectedDateTime={selectedDateTime}
              services={reShapeProcedures(services)}
              isOpen={showNewAppointment}
              hideNewAppointment={() => setShowNewAppointment(false)}
              selectedPractitioner={selectedPractitioner}
              practitioners={allPractitioners?.practitionersOptions}
              selectedResourceId={resourceId}
            />
          </NewAppointmentContextProvider>
        )}
        <Confirm
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
          handleContinue={() => rescheduleAppointment(currentEvent)}
          primaryBtnTxt="Reschedule appointment"
          secondaryBtnTxt="Keep"
          title="Confirm reschedule"
          message={`Are you sure you want to reschedule this appointment to ${moment(
            currentEvent?.start
          )}`}
          icon="new-calendar-edit"
          loading={loading?.reschedule}
        />
      </div>
    </>
  );
};

const RBCalendarWrapper = (props) => {
  return (
    <DashboardContextProvider>
      <RBCalendar {...props} />
    </DashboardContextProvider>
  );
};

export default RBCalendarWrapper;
