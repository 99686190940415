import { calculateUnitTimeLength } from './calculateUnitTimeLength';

export const handleUnitSwitch = ({
  procedure,
  isTimeUnit,
  handleProcedureChanges,
  index,
  startTime,
  endTime
}) => {
  if (isTimeUnit) {
    const time_unit = calculateUnitTimeLength(
      procedure?.start_time || startTime,
      procedure?.end_time || endTime
    );
    handleProcedureChanges({ target: { value: time_unit, name: 'unit_time_length' } }, index);
  }
  handleProcedureChanges({ target: { value: isTimeUnit, name: 'isTimeUnit' } }, index);
};
