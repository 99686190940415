import React, { useCallback, useEffect, useState } from 'react';

import { cloneDeep } from 'lodash';

import {
  useChatContextApi,
  useChatContextData
} from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useMacros } from 'lib/hooks/queries/macros/useMacros';

import AGTable from 'components/shared/AGTable/AGTable';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import PreviewView from '../PreviewView';

import CreateMacroContent from './components/CreateMacroContent';
import EditMacrosContent from './components/EditMacroContent';
import EmptyMacros from './components/EmptyMacros';
import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS } from './configs';
import { useBreadcrumbFunctions } from './hooks/useBreadcrumbFunctions';

export function Table({ breadcrumb }) {
  const { steps, setSteps, step, setStep, push, goBack } = useBreadcrumbFunctions(breadcrumb);
  const { gridApi } = useTableContext();
  const { selectedTableRows } = useChatContextData();
  const { setComponentToAdd, onAddComponent, setSelectedTableRows, onRemoveComponent } =
    useChatContextApi();
  const selectedTableNodes = selectedTableRows?.['macros']?.selectedNodes;

  const [selectedNodes, setSelectedNodes] = useState([]);
  const selectedRowsData = selectedNodes.map((node) => node.data);
  const { selectedRows } = useTableContext();

  const { data = {} } = useMacros({ params: { type: ['text_macro'] } });

  const macros = data?.macros;

  useEffect(() => {
    setSteps([{ title: 'Select Text Macros', name: 'start' }]);
    setStep(0);
  }, []);

  useEffect(() => {
    if (selectedTableNodes?.length > 0) {
      setSelectedNodes(selectedTableNodes);
      selectedTableNodes.forEach((row) => {
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    } else if (selectedRows.length > 0) {
      selectedRows.forEach((row) => {
        setSelectedNodes(selectedRows);
        const node = gridApi?.getRowNode(row.id);
        if (node) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi, selectedTableNodes]);

  const deleteMacrosFormSelectedTableRows = useCallback(() => {
    const newSelectedTableRows = cloneDeep(selectedTableRows);
    delete newSelectedTableRows?.['macros'];
    setSelectedTableRows(newSelectedTableRows);
  }, [selectedTableRows, setSelectedTableRows]);

  const onSelectionChanged = useCallback(() => {
    if (!gridApi) return;

    const selectedNodes = gridApi.getSelectedNodes();
    setSelectedNodes(selectedNodes);
  }, [gridApi]);

  const onPreviewMacros = () => {
    if (!selectedNodes?.length) {
      deleteMacrosFormSelectedTableRows();
      onRemoveComponent();
      setComponentToAdd(null);
      return;
    }
    push({
      title: 'Preview Macros',
      name: 'preview'
    });
  };

  const onGoBack = () => {
    setSteps((prev) => prev.slice(0, step));
    setStep((prev) => prev - 1);
  };

  if (steps[step]?.name === 'create')
    return (
      <div className="mt-3">
        <CreateMacroContent goBack={goBack} />
      </div>
    );

  if (steps[step]?.name === 'edit')
    return (
      <EditMacrosContent
        goBack={goBack}
        step={step}
        setSteps={setSteps}
        selectedRowsData={selectedRowsData}
      />
    );

  if (steps[step]?.name === 'preview') {
    const onEditMacros = () => {
      setSteps((prev) => [
        ...prev.slice(0, step),
        {
          title: 'Edit Macros',
          name: 'edit'
        }
      ]);
    };

    const onAddTag = () => {
      if (!selectedNodes?.length) {
        deleteMacrosFormSelectedTableRows();
        onRemoveComponent();
      }

      setSelectedTableRows((prev) => ({
        ...prev,
        macros: {
          selectedNodes,
          data: selectedNodes.map((node) => node.data),
          status: !selectedNodes.length
            ? 'none'
            : selectedNodes?.length === macros?.length
              ? 'all'
              : 'partial'
        }
      }));
      onAddComponent();
    };

    return (
      <div className="flex justify-center pb-[112px]">
        <PreviewView
          isHtml
          previewData={selectedRowsData?.map((item) => ({ label: item?.name, value: item?.body }))}
        />
        <ModalFooter
          key={selectedRowsData}
          className="z-50"
          rightButtons={[
            <Button
              key={1}
              text="Edit"
              icon="save-changes"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!mr-2 !py-2 !pl-4 !pr-4"
              onClick={onEditMacros}
              outlined
            />,
            <Button
              key={1}
              text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Macros`}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onAddTag}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={onGoBack}
            />
          }
        />
      </div>
    );
  }

  const onDeleteMacroTag = () => {
    deleteMacrosFormSelectedTableRows();
    onRemoveComponent();
    setComponentToAdd(null);
  };

  return (
    <div className="ag-theme-quartz macros_table !mb-0 h-full">
      <AGTable
        onSelectionChanged={onSelectionChanged}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        animateRows={true}
        data={macros}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        noRowsOverlayComponent={EmptyMacros}
        noRowsOverlayComponentParams={{ breadcrumb }}
        loadingOverlayComponent={() => (
          <div className="h-full w-[1160px] min-w-[480px] bg-white">
            <Skeleton count={24} />
          </div>
        )}
        reactiveCustomComponents
      />
      {macros?.length > 0 && (
        <ModalFooter
          rightButtons={[
            selectedTableNodes?.length > 0 && (
              <Icon key={1} icon="trash" className="cursor-pointer" onClick={onDeleteMacroTag} />
            ),
            <Button
              key={2}
              text={`${selectedTableNodes?.length > 0 ? 'Update' : 'Add'} ${selectedRowsData?.length > 0 ? `(${selectedRowsData?.length})` : ''} Text Macro`}
              disabled={!selectedRowsData?.length && !selectedTableNodes?.length}
              // icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!ml-4 !py-2 !pl-4 !pr-4"
              onClick={onPreviewMacros}
            />
          ]}
          leftButtons={
            <Button
              key={1}
              text="Back"
              outlined
              transparent
              size="small"
              className="!py-2 !pl-4 !pr-4"
              onClick={() => setComponentToAdd(null)}
            />
          }
        />
      )}
    </div>
  );
}
