import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getCptCodes, getWithSpecialties } from 'api/CptCodes';

import { useInfiniteFetchData } from 'lib/hooks/useInfiniteFetchData';

export const useCPTCodes = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['cptCodes', ...dependencies], () => getCptCodes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const useInfiniteCPTCodes = ({ params = {}, options = {}, dependencies = [] }) => {
  return useInfiniteFetchData({
    queryKey: 'infiniteCptCodes',
    url: '/api/codes/cpt/get',
    params,
    dependencies,
    options
  });
};

export const usePracticeCPTCodes = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(
    ['practiceCptCodes', ...dependencies],
    () => getWithSpecialties(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
