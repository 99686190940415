import React from 'react';

import { TYPE_LABELS } from 'lib/constants/transaction/typeLabels';

import { Capitalize, formatDateAndTime, ia, mString } from '../../../../lib/helpers/utility';

import TH from './table/TH';

const ReceiptTransactions = ({ transactions, timezone }) => {
  if (!ia(transactions)) return null;

  return (
    <div className="flex flex-col overflow-hidden rounded-[12px] !border-[0.6px] border-solid border-neutral-200 !px-5 shadow-[0px_1px_3px_0px_rgba(25,33,61,0.05),0px_2px_8px_0px_rgba(25,33,61,0.04)]">
      <p className="!pb-1 pt-[14px] text-sm font-700 text-primary-900">Payments history</p>

      <table>
        <thead>
          <tr className="border-[0] border-b-[0.6px] border-solid border-neutral-200 hover:!bg-transparent">
            <TH label="Date" align="start" />
            <TH label="Type" align="center" />
            <TH label="Status" align="center" />
            <TH label="Amount" align="center" />
            <TH label="Technology fee" align="center" />
            <TH label="Total" align="end" />
          </tr>
        </thead>

        <tbody>
          {transactions.map?.((v, i) => {
            return (
              <tr
                className="border-[0] border-b-[0.6px] border-solid border-neutral-200 last:!border-0 hover:!bg-transparent"
                key={i}>
                <td className="!py-2 px-0 text-left text-xs font-600 leading-[14px] text-neutral-900">
                  {formatDateAndTime(v?.created_at, timezone)}
                </td>
                <td className="!py-2 text-center text-xs leading-[14px] text-neutral-700">
                  {TYPE_LABELS[v?.type]}
                </td>
                <td className="!py-2 text-center text-xs leading-[14px] text-neutral-700">
                  {Capitalize(String(v?.status).replaceAll('_', ' '))}
                </td>
                <td className="!py-2 text-center text-xs leading-[14px] text-neutral-700">
                  {mString(v?.amount_paid_self - (v?.surcharge_amount || 0))}
                </td>
                <td className="!py-2 text-center text-xs leading-[14px] text-neutral-700">
                  {mString(v?.surcharge_amount || 0)}
                </td>
                <td className="!py-2 px-0 text-right text-xs font-600 leading-[14px] text-neutral-900">
                  {mString(v?.amount_paid_self)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ReceiptTransactions;
