import React, { useMemo } from 'react';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { useRoleList } from 'lib/hooks/queries/roles/useRoleList';

import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Filter from 'components/shared/Filter/Filter';
import Header from 'components/shared/Header/Header';

import { defaultFilters } from '../lib/helpers';

const category = 'staff';

const HeaderTable = () => {
  const { filters, setFilters } = useTableContext();

  const { data } = useRoleList({ params: {} });

  const defaultFilterOptions = useMemo(() => {
    const roles = data?.role_list || [];

    const newDefaultFilters = defaultFilters({ roles });
    setFilters(newDefaultFilters);
    return newDefaultFilters;
  }, [data, setFilters]);

  return (
    <Header title="Staff">
      <div className="flex items-center gap-2">
        <DisplayButton />
        <Filter
          category={category}
          defaultFilters={defaultFilterOptions}
          filters={filters}
          setFilters={setFilters}
          menuPortalTarget={document.body}
          btnClassName="!h-[30px]"
        />
      </div>
    </Header>
  );
};

export default HeaderTable;
