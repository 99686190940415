import React, { useState } from 'react';
import { useThree } from '@react-three/fiber';
import { Line } from '@react-three/drei';
import { useChart3dContext } from '../../../../../../../../lib/context/Chart3dContext/Chart3dContext';
import { showAlert } from '../../../../../../../shared/Alert/Alert';
import { FaceMale } from './models/FaceMale';
import { FaceFemale } from './models/FaceFemale';
import { FaceMuscles } from './models/FaceMuscles';

function euclideanDistance(a, b) {
  return Math.sqrt(Math.pow(a?.x - b?.x, 2) + Math.pow(a?.y - b?.y, 2) + Math.pow(a?.z - b?.z, 2));
}

export default function Model({ preview }) {
  const {
    hovered,
    setHovered,
    mode,
    handleQuantity,
    selectedItem,
    setNewItemModalVisible,
    items,
    setPoints,
    patient,
    selected,
    setSelected,
    drawing,
    selectedItemIndex,
    findNearestLandmark,
    setDrawHistory
  } = useChart3dContext();

  const modelType = mode === 'skin' ? (patient?.gender == 'male' ? 'male' : 'female') : 'muscle';

  const { controls } = useThree();

  function addPoint(point) {
    if (drawing) return;
    const index = selectedItemIndex();
    if (selectedItem === null) return setNewItemModalVisible(true);

    if (items[index].item_type == 'product' && items[index].quantity <= items[index].sales_count)
      return showAlert({ title: 'Not enough products!', color: 'danger' });

    const position = [point?.x, point?.y, point?.z];

    const nearestLandmark = findNearestLandmark(position);

    if (items[index]?.visible) {
      setPoints((prev) => {
        setHovered(prev.length);
        return [
          ...prev,
          {
            position,
            product: selectedItem,
            units: 1,
            area: nearestLandmark?.mark,
            type: nearestLandmark?.type
          }
        ];
      });
      handleQuantity();
    }
  }

  const { camera } = useThree();

  const [startRotation, setStartRotation] = useState();

  const [isDrawing, setIsDrawing] = useState();
  const [drawPoints, setDrawPoints] = useState([]);

  const handleClickDown = (event) => {
    event.stopPropagation();

    if (!hovered && hovered !== 0 && (selected || selected === 0)) {
      setStartRotation({ x: 4, y: 4, z: 4 });
      return setSelected(null);
    }

    setStartRotation({ x: camera?.rotation?.x, y: camera?.rotation?.y, z: camera?.rotation?.z });
  };

  const handleClickUp = (event) => {
    if (hovered || hovered === 0) return;
    event.stopPropagation();
    const distance = euclideanDistance(startRotation, {
      x: camera?.rotation?.x,
      y: camera?.rotation?.y,
      z: camera?.rotation?.z
    });

    if (distance < 0.02) {
      addPoint(event.point);
    }
  };

  const drawingDown = (event) => {
    event.stopPropagation();
    setIsDrawing(true);

    controls.enabled = false;
  };

  const drawingUp = (event) => {
    event.stopPropagation();
    setIsDrawing(false);
    setPoints((points) => {
      const newPoints = [...points];
      if (newPoints[selected]?.draw != null) {
        newPoints[selected].draw = [...newPoints[selected]?.draw, drawPoints];
      } else {
        newPoints[selected].draw = [drawPoints];
      }
      setDrawHistory([...newPoints[selected].draw]);
      return newPoints;
    });

    controls.enabled = true;
  };

  const drawingMove = (event) => {
    event.stopPropagation();
    if (!isDrawing) {
      return drawPoints.length > 0 && setDrawPoints([]);
    }
    setDrawPoints([...drawPoints, [event.point.x, event.point.y, event.point.z]]);
  };

  const model = (props) => {
    switch (modelType) {
      case 'male':
        return <FaceMale {...props}></FaceMale>;
      case 'female':
        return <FaceFemale {...props}></FaceFemale>;
      case 'muscle':
        return <FaceMuscles {...props}></FaceMuscles>;
    }
  };

  return preview ? (
    model({ scale: 0.7 })
  ) : (
    <>
      {model({
        scale: 0.7,
        onPointerDown: drawing ? drawingDown : handleClickDown,
        onPointerUp: drawing && isDrawing ? drawingUp : handleClickUp,
        onPointerMove: drawing ? drawingMove : null,
        onPointerLeave: drawing && isDrawing ? drawingUp : null
      })}
      {drawPoints?.length > 0 && <Line points={drawPoints} lineWidth={2} color="white"></Line>}
    </>
  );
}
