import React from 'react';

import { useAppointmentv2 } from 'lib/hooks/queries/appointment/useAppointmentv2';
import { useEvent } from 'lib/hooks/queries/event/useEvent';

import Skeleton from 'components/shared/Skeleton/Skeleton';

import Body from './components/Body';
import Footer from './components/Footer/Footer';
import Header from './components/Header';

const Content = ({ id, type, handleHideHover = () => {} }) => {
  const params = {
    id,
    withal: {
      medicalHistory: true,
      payments: true,
      patient: true,
      practitioner: true,
      allergies: true,
      practitionerPractice: true,
      tag: true,
      resources: true
    },
    type
  };
  const { data: appointmentData, isFetching: fetchingAppointment } = useAppointmentv2({
    params,
    options: { enabled: type === 'appointment' }
  });
  const { data: eventData, isFetching: fetchingEvent } = useEvent({
    params: { id },
    options: { enabled: type === 'event' }
  });

  const Placeholder = () => (
    <div className="flex flex-col justify-between gap-4 !p-4">
      <div className="flex flex-col gap-4">
        <Skeleton height="124px" />
        <Skeleton height="300px" />
      </div>
      <Skeleton height="30px" />
    </div>
  );

  if (fetchingAppointment || fetchingEvent) return <Placeholder />;

  const props = {
    appointment: appointmentData?.appointment,
    event: eventData ?? {},
    type,
    id
  };

  return (
    <div className="flex flex-col">
      <Header {...props} />
      <Body {...props} />
      <Footer {...props} handleHideHover={handleHideHover} />
    </div>
  );
};

export default Content;
