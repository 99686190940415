import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

import React from 'react';
import { deleteCustomFormCheck } from '../lib/deleteCustomFormCheck';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { useNavigate } from 'react-router-dom';
import useNewDesign from '../lib/useNewDesign';

const DeleteFormModal = ({
  itemToDelete,
  setItemToDelete,
  formId,
  setItems,
  setPrevItems,
  setOverviewData,
  setCurrentHpOverviewData,
  setModal,
  syncItems,
  items,
  rows,
  alertTitle,
  initialValue,
  type = rows,
  handleSave
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const newDesign = useNewDesign();
  

  const mutateDeleteItems = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: formId
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          alertTitle,
          message: error,
          color: 'warning'
        });
        return;
      }

      setItems(initialValue);
      setPrevItems && setPrevItems(initialValue);

      formId &&
        setOverviewData &&
        setCurrentHpOverviewData &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId,
          type
        });
      showAlert({
        title: `${alertTitle} deleted successfully`
      });
      queryClient.invalidateQueries(['customFormResponse']);
      setModal && setModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!formId && !newDesign) {
      showAlert({
        alertTitle,
        message: 'Please create the form before deleting one.',
        color: 'warning'
      });
      return;
    }
    const itemsRow = items?.[rows] || [];
    if (itemsRow.length === 1 && newDesign) {
      itemToDelete && setItemToDelete(null);

      setItems(initialValue);

      syncItems(initialValue);
      return;
    }

    const updatedObject = {
      ...items,
      [rows]: items?.[rows].filter((row) => row.id !== id)
    };

    itemToDelete && setItemToDelete(null);

    setItems(updatedObject);
    syncItems(updatedObject);
    handleSave && handleSave(updatedObject);
  };

  return (
    !!itemToDelete && (
      <Confirm
        variant="danger"
        primaryBtnTxt="Delete"
        title={`Delete ${alertTitle}`}
        icon="trash"
        message={
          deleteCustomFormCheck(itemToDelete, items?.[rows])
            ? 'Are you sure you want to delete this item?'
            : 'Are you sure you want to delete this form?'
        }
        handleContinue={() => {
          if (!formId && !newDesign) {
            const updatedItems = items?.[rows].filter((row) => row.id !== itemToDelete);
            const updatedObject = {
              ...items,
              narrative: '',
              [rows]: deleteCustomFormCheck(itemToDelete, items?.[rows])
                ? updatedItems
                : initialValue[rows]
            };

            setItems(initialValue);
            syncItems(updatedObject);
            return;
          }

          if (deleteCustomFormCheck(itemToDelete, items?.[rows]) || newDesign) {
            handleDeleteRow(itemToDelete);
          } else {
            mutateDeleteItems.mutate();
          }
        }}
        handleOpen={!!itemToDelete}
        handleClose={() => setItemToDelete(null)}
      />
    )
  );
};

export default DeleteFormModal;
