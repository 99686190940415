const validator = require('validator')

const validateUpdate = (payload) => {
  const errors = {}
  let message = ''
  let isFormValid = true

  if (!payload || typeof payload.name !== 'string' || payload.name.trim().length === 0) {
    isFormValid = false
    errors.name = 'Please provide a first name.'
  }

  if (!payload || typeof payload.type !== 'number') {
    isFormValid = false
    errors.type = 'Please provide a form type.'
  }

  if (!isFormValid) {
    message = 'Check the form for errors.'
  }

  return {
    success: isFormValid,
    message,
    errors
  }
}

module.exports = {
  validateUpdate: validateUpdate
}
