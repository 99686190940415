import EnhancementStatus from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/EnhancementStatus';
import Accordion from 'components/practice/charts/ClinicalNote/shared/Accordion';
import Textarea from 'components/shared/Textarea/Textarea';

const AutoMapTextArea = ({ textRef, inputName, disabled = false, components }) => {
  const handleChange = (value) => {
    textRef.current[inputName] = value;
  };

  return (
    <Accordion
      withStyle={false}
      withHeader={false}
      sectionRef={textRef}
      id={components?.[inputName]?.path}
      disabled={disabled}>
      <Textarea
        isEditor
        label={<EnhancementStatus>{components?.[inputName]?.title}</EnhancementStatus>}
        placeholder="Write subjective notes here"
        id={inputName}
        data-qa={inputName}
        name={inputName}
        className="h-[202px]"
        value={textRef?.current?.[inputName]}
        onChange={handleChange}
        transcribing
        forwardedRef={textRef}
        disabled={disabled}
      />
    </Accordion>
  );
};

export default AutoMapTextArea;
