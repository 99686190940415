import { getClaim } from 'api/Claim';
import Popup from 'components/shared/Filters/Popup';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuperbillActions = ({ data }) => {
  const navigate = useNavigate();

  let claim = data.claim;

  const onClaimClick = async (e) => {
    if (!claim) {
      const res = await getClaim(navigate, { id: data.claim_id });
      claim = res?.claim;
    }
    navigate(claimType(claim?.state, claim?.id));
    e?.stopPropagation();
  };

  const claimType = (claimType, claimId) => {
    let type = '';
    switch (claimType) {
      case 'complete':
        type = 'accepted';
        break;
      default:
        type = claimType;
    }
    return `/portal/beyond-billing/claims/${type}/${claimId}`;
  };

  return (
    <div className="flex h-full flex-col justify-center">
      <Popup
        trigger={(open) => (
          <div
            className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
              open && '!bg-primary-700 transition-all'
            }`}>
            <Icon
              icon="three-dots"
              className="flex cursor-pointer"
              color={open ? 'white' : 'black'}
            />
          </div>
        )}
        width={180}
        on={['hover', 'focus']}
        contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
        position={['bottom right', 'top center']}>
        {() => (
          <div className=" !py-[6px]">
            <div
              data-qa="send-email-btn"
              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
              onClick={onClaimClick}>
              <div className="ml-1 text-sm text-primary-900">View claim</div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};

export default SuperbillActions;
