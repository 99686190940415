export const borderClassesOnFocus = {
  success: 'focus:!border-success-400',
  warning: 'focus:!border-warning-400',
  danger: 'focus:!border-danger-400',
  neutral: 'focus:!border-neutral-400'
};

export const bgClassesOnFocus = {
  success: 'group-focus:bg-success-50',
  warning: 'group-focus:bg-warning-50',
  danger: 'group-focus:bg-danger-50',
  neutral: 'group-focus:bg-neutral-50'
};

export const stripeClasses = {
  success: 'before:bg-success-500',
  warning: 'before:bg-warning-500',
  danger: 'before:bg-danger-500',
  neutral: 'before:bg-neutral-500'
};
