import React, { useMemo } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { ComponentParamsProvider } from 'lib/context/ComponentParamsContext/ComponentParamsProvider';

import Typography from 'components/shared/Typography/Typography';

import {
  ALLOWABLE_AMOUNT_TEXT,
  ALLOWABLE_AMOUNT_TOOLTIP,
  COLUMN_DEFS,
  DEFAULT_COLUMN_DEFS
} from './lib/helpers';

const PayerTable = ({ formik }) => {
  const payers = useMemo(() => formik.values.paySchedule || [], [formik.values.paySchedule]);

  return (
    <div className="flex flex-col gap-4">
      <Typography
        iconRight="new-info-circle"
        color="gray-800"
        iconColor="text-gray-600"
        weight="medium"
        tooltip={ALLOWABLE_AMOUNT_TOOLTIP}>
        {ALLOWABLE_AMOUNT_TEXT}
      </Typography>
      <ComponentParamsProvider formik={formik}>
        <div className="ag-theme-quartz  shadow-none h-fit">
          <AgGridReact
            rowData={payers}
            columnDefs={COLUMN_DEFS}
            defaultColDef={DEFAULT_COLUMN_DEFS}
            domLayout="autoHeight"
          />
        </div>
      </ComponentParamsProvider>
    </div>
  );
};

export default PayerTable;
