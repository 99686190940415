import { useEffect, useState } from 'react';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

import Avatar from 'components/shared/Avatar/Avatar';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import { userState } from 'components/state';

import { componentButtons } from '../../constants';
import useComponentAdd from '../../hooks/useComponentAdd';

import ComponentSelectorModal from './ComponentSelectorModal';

const getFinalTags = (tagIds) => {
  const finalTags = new Map();

  for (const item of tagIds) {
    const parts = item.split(':');
    const category = parts[0];
    const id =
      category === 'patient_history' ? `${parts[1]}${parts[2] ? `:${parts[2]}` : ''}` : parts[1]; // Format for patient_history

    const tag = componentButtons.find((componentButton) => componentButton?.id === category);

    if (tag) {
      if (finalTags.has(category)) {
        finalTags.get(category).selectedIds.push(id);
      } else {
        finalTags.set(category, { ...tag, selectedIds: [id] });
      }
    }
  }

  return Array.from(finalTags.values());
};

const UserPrompt = ({ message, tags }) => {
  const breadcrumb = useBreadcrumb();
  const [finalMessage, setFinalMessage] = useState(message);
  const patient = useRecoilValue(userState);
  const { setUserPrompt } = useChatContextApi();
  const { inputTags, selectedTableRows, setInputTags, setSelectedTableRows } = useComponentAdd();
  const [promptTags, setPromptTags] = useState(inputTags || []);
  const [componentToAdd, setComponentToAdd] = useState(null);

  useEffect(() => {
    if (!tags?.length) {
      setPromptTags(inputTags);
      setInputTags([]);
    }
  }, []);

  useEffect(() => {
    if (!tags?.length) return;
    const finalTags = getFinalTags(tags);

    setSelectedTableRows(() => {
      const obj = {};
      finalTags.map((tag) => {
        obj[tag?.id] = {
          selectedTags: tag?.selectedIds
        };
      });
      return obj;
    });
    setPromptTags(finalTags);
  }, [tags]);

  useEffect(() => {
    setFinalMessage(message);
    setUserPrompt(message);
  }, [message]);

  const onTagPreview = () => {
    // const clickedComponent = promptTags.find((tag) => tag.id === id);
    // setComponentToAdd(clickedComponent);
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial' && !tags?.length
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  return (
    <div className="flex flex-col gap-3 items-end">
      <div className="flex items-center gap-2">
        <div className="text-base font-600 text-neutral-800">
          {patient?.f_name} {patient?.l_name}
        </div>
        <Avatar
          firstName={patient?.f_name}
          lastName={patient?.l_name}
          id={patient?.id}
          image={patient?.header_photo}
          size="large"
          className="rounded-full"
          background="linear-gradient(98deg, rgba(19, 184, 255, 1) 0%, rgba(0, 103, 140, 1) 100%)"
          autoBackground={false}
        />
      </div>

      <div className="flex flex-grow flex-col mx-[40px]">
        <div className="w-full rounded-lg bg-primary-50">
          <div className="py-2 px-4">{finalMessage}</div>
          <div className="flex flex-wrap items-center">
            {promptTags.map((tag) => (
              <span
                onClick={() => onTagPreview(tag.id)}
                key={tag.id}
                className={cs(
                  'relative my-1 ml-1 cursor-pointer rounded bg-primary-50 p-1 px-2 text-sm text-primary-700',
                  getTagClassName(tag.id)
                )}>
                #{tag?.text}
              </span>
            ))}
          </div>
        </div>
      </div>
      {componentToAdd && (
        <ComponentSelectorModal
          isView
          componentToAdd={componentToAdd}
          onClose={() => setComponentToAdd(null)}
          breadcrumb={breadcrumb}
        />
      )}
    </div>
  );
};

export default UserPrompt;
