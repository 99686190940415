import React from 'react';
import Popup from 'components/shared/Filters/Popup';
import cs from 'classnames';
import ContactButton from './contactButton/ContactButton';

const AltContact = ({ type, handleAlt, children }) => {
  return (
    <div
      onClick={handleAlt}
      className="flex cursor-pointer select-none gap-[6px] !px-3 py-[6px] text-sm text-primary-900 hover:bg-primary-50">
      {children}
    </div>
  );
};

export default AltContact;
