import React from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../../../../shared/Icon/Icon';
import { useRecoilValue } from 'recoil';
import { currentPractice } from '../../../practiceState';
import cs from 'classnames';
import Allowed from 'components/shared/Permissions/Allowed';

const CreatePopover = ({ showNewAppointmentModal, setSellModalVisible, setShowPaymentModal }) => {
  const practice = useRecoilValue(currentPractice);
  const { packages, products } = practice?.display_settings;

  return (
    <>
      <Popover className="relative !mr-2">
        {({ open }) => (
          <>
            <Popover.Button
              className={cs(
                'group flex cursor-pointer items-center gap-x-[6px] rounded-full bg-primary-50 !px-[14px]  !py-[6px]',
                open && 'bg-primary-700'
              )}
              as="div"
              data-qa="new-appointment-btn">
              <Icon
                icon="add-circle"
                className="cursor-pointer"
                size={18}
                color="primary"
                shade={open && '100'}
              />
              <span
                className={cs(
                  'text-xs',
                  open ? 'text-primary-100' : 'text-primary-500 group-hover:text-primary-700'
                )}>
                Create
              </span>
              <Icon
                icon="new-chevron-down"
                className="cursor-pointer"
                color="primary"
                shade={open ? '100' : '500'}
              />
            </Popover.Button>
            <Popover.Panel className="absolute right-0 z-30 mt-[10px] w-max !rounded-lg bg-white shadow-medium">
              <div className="grid">
                <div className="!px-2">
                  <div className="h-[1px] w-full bg-neutral-100"></div>
                </div>
                <Allowed requiredPermissions={['appointments.create', 'services.read']}>
                  <div
                    data-qa="patient-overview-new-appointment"
                    className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                    onClick={showNewAppointmentModal}>
                    <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                      <Icon icon="new-calendar" />
                    </div>
                    <div className="grid">
                      <span className="text-sm font-500 text-primary-900">Appointment</span>
                      <span className="text-xs text-neutral-600">New appointment</span>
                    </div>
                  </div>
                </Allowed>
                <div className="!px-2">
                  <div className="h-[1px] w-full bg-neutral-100"></div>
                </div>
                <Allowed requiredPermissions={['products.read', 'packages.read']} isAny={true}>
                  {packages || products ? (
                    <div
                      data-qa="patient-overview-sell-package-product"
                      className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                      onClick={() => setSellModalVisible(true)}>
                      <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                        <Icon icon="new-receipt-textv2" size={24} />
                      </div>
                      <div className="grid">
                        <span className="text-sm font-500 text-primary-900">Transaction</span>
                        <span className="text-xs text-neutral-600">Sell Products & Packages</span>
                      </div>
                    </div>
                  ) : null}
                </Allowed>

                <Allowed requiredPermissions="payments.create">
                  <div
                    data-qa="patient-overview-quote"
                    className="group flex cursor-pointer items-center !rounded-lg !px-4 py-3 hover:bg-primary-50"
                    onClick={() => setShowPaymentModal(true)}>
                    <div className="mr-[14px] flex h-[38px] w-[38px] items-center justify-center rounded-lg bg-primary-50 p-2 group-hover:bg-white">
                      <Icon icon="money-send" size={24} />
                    </div>
                    <div className="grid">
                      <span className="text-sm font-500 text-primary-900">Payment</span>
                      <span className="text-xs text-neutral-600">Pay balance</span>
                    </div>
                  </div>
                </Allowed>
              </div>
            </Popover.Panel>
          </>
        )}
      </Popover>
    </>
  );
};

export default CreatePopover;
