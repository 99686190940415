import cs from 'classnames';
import moment from 'moment-timezone';
import React, { useRef } from 'react';
import { Calendar } from 'react-date-range';
import { surgeriesList } from '../../../../../constants';
import SearchProviders from '../../../../Search/SearchProviders/SearchProviders';

import { permissions } from 'components/state';
import { useRecoilValue } from 'recoil';
import Input from '../../../Input/Input';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import CustomFormPopover from '../components/CustomFormPopover';
import { isDataReadOnly } from '../lib/aggregatedDataHelper';

const SurgicalHistoryRow = ({
  surgery,
  surgeon,
  date,
  note,
  id,
  forwardedRef,
  onChange,
  newDesign,
  formik,
  className,
  createdBy = null
}) => {
  const noteRef = useRef(null);
  const userPermissions = useRecoilValue(permissions);
  const dataReadOnly = isDataReadOnly({
    data: { id, createdBy },
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents
  });

  return (
    <div className={cs('flex w-full items-center gap-x-[10px]', className)}>
      <div
        className={cs(
          !newDesign ? ' grid w-full grid-cols-3 gap-4' : 'flex w-full flex-col gap-4'
        )}>
        <Select
          inputId="select-surgery"
          label="Surgery"
          placeholder="Type and select surgery"
          options={surgeriesList}
          value={surgery}
          error={formik?.errors?.surgery}
          name="surgery"
          isCreatable
          onChange={(event) => onChange('surgery', event, id)}
          disabled={dataReadOnly}
        />

        <div className="relative w-full" ref={forwardedRef}>
          <CustomFormPopover
            buttonClassName="!px-0 !w-full"
            popoverClassName="!w-full"
            button={
              <div className="flex flex-col">
                <label
                  className="m-0 pb-[6px] text-start text-sm text-neutral-800"
                  htmlFor="surgeriesDate">
                  Date of surgery
                </label>
                <Input
                  inputWrapperClassName="!flex"
                  data-qa="new-calendar-gray"
                  rightIcon="new-calendar-gray"
                  value={moment.utc(date).format('MMM Do, YYYY')}
                  readOnly
                  disabled={dataReadOnly}
                />
              </div>
            }
            panel={
              <Calendar
                data-qa="new-date"
                date={new Date(date)}
                onChange={(event) =>
                  onChange('date', moment.utc(event).add('12', 'h').format('YYYY-MM-DD'), id)
                }
              />
            }
          />
        </div>

        <SearchProviders
          data-qa="type-surgeon"
          label="Surgeon"
          placeholder="Type surgeon"
          value={surgeon}
          onChange={(event) => onChange('surgeon', event, id)}
          disabled={dataReadOnly}
        />

        <div className={!newDesign && 'col-span-3'}>
          <Textarea
            label="Please notate here if unsure"
            placeholder="Add note here"
            id="note"
            className={cs(!newDesign ? '!min-h-[120px]' : '!min-h-[80px]', 'mb-2  text-sm')}
            isEditor={!newDesign}
            textareaClassName="!min-h-[80px]"
            data-qa="add-note"
            name="note"
            value={note}
            onChange={
              newDesign
                ? (event) => onChange('note', event.target.value, id)
                : (value) => onChange('note', value, id)
            }
            transcribing
            forwardedRef={noteRef}
            disabled={dataReadOnly}
          />
        </div>
      </div>
    </div>
  );
};

export default SurgicalHistoryRow;
