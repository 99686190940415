import React, { useEffect, useState } from 'react';

import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

import {
  activePromptConversationsState,
  intelligenceChatIdState,
  isAIGeneratingState,
  myScribeAILoadingState
} from 'components/practice/charts/ClinicalNote/MyScribeAI/state';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Transcribe from 'components/shared/Transcribe/Transcribe';

import useChatInput from '../../hooks/useChatInput';
import useComponentAdd from '../../hooks/useComponentAdd';
import useNoteType from '../../hooks/useNoteType';
import { stopResponseGeneration } from '../../lib/helpers';

import ComponentButtons from './ComponentButtons';

export function ChatFooter() {
  const { selectedPromptNoteType } = useNoteType();
  const { inputTags: tags, selectedTableRows, onTagRemove, onTagPreview } = useComponentAdd();
  const { inputValue, disableGenerate, onTextChange } = useChatInput();
  const setActivePromptConversations = useSetRecoilState(activePromptConversationsState);
  const { onEnhanceNote } = useChatContextApi();
  const isGenerating = useRecoilValue(isAIGeneratingState);
  const setIsLoading = useSetRecoilState(myScribeAILoadingState);
  const [showConfirm, setShowConfirm] = useState(false);
  const [inputTags, setInputTags] = useState([]);
  const intelligenceChatId = useRecoilValue(intelligenceChatIdState);

  useEffect(() => {
    setInputTags(tags);
  }, [tags]);

  useEffect(() => {
    setInputTags(tags);
  }, [tags]);

  const countWords = (text) => text.trim().split(/\s+/).filter(Boolean).length;
  const wordCount = countWords(inputValue);

  const isWordCountLow = wordCount < 50;

  const handleGenerate = () => {
    if (isWordCountLow && !intelligenceChatId) {
      setShowConfirm(true);
      return;
    }
    onGenerate();
  };

  const onGenerate = () => {
    setActivePromptConversations((prevPrompts = []) => [
      ...(prevPrompts || []),
      { id: self.crypto.randomUUID(), message: inputValue, agent: 'user' }
    ]);
    setIsLoading(true);
    onTextChange({ target: { value: '' } });
    setInputTags([]);
    onEnhanceNote({
      userMessage: {
        message: inputValue,
        tags: inputTags?.map((item) => item.id),
        agent: 'user'
      },
      intelligenceChatId,
      noteType: selectedPromptNoteType?.type
    });
  };

  const getTagClassName = (id) => {
    return cs(
      'text-primary-700 !bg-primary-50 hover:!bg-primary-100',
      selectedTableRows?.[id]?.status === 'partial'
        ? '!bg-warning-100 hover:!bg-warning-200 !text-warning-500'
        : ''
    );
  };

  const onStopGeneration = () => {
    stopResponseGeneration({ intelligenceChatId });
  };

  return (
    <>
      <AIChatInput
        isHTML
        className="max-w-[756px] w-auto xl:w-[768px] px-0 mb-3 border-2 !border-neutral-200 shadow-none outline outline-1 outline-neutral-200"
        leftButtons={[]}
        rightButtons={[]}
        onTextChange={onTextChange}
        onSubmit={onGenerate}
        placeholder={
          intelligenceChatId
            ? 'Chat with MyScribe to make edits or ask relevant questions!'
            : 'Patient presents with…'
        }
        value={inputValue}
        tags={inputTags}
        onTagRemove={onTagRemove}
        onTagPreview={onTagPreview}
        getTagClassName={getTagClassName}
        inputClassName="max-h-[192px] min-h-[32px]">
        <div className="flex justify-between items-center">
          <ComponentButtons onTagPreview={onTagPreview} onTagRemove={onTagRemove} />
          <div className="flex">
            <div className="w-[32px] h-[32px] mr-1.5 flex justify-center items-center" key={1}>
              <Transcribe
                withLabel={false}
                value={inputValue}
                iconSize={22}
                setValue={(text) =>
                  onTextChange({
                    target: {
                      value: text,
                      name
                    }
                  })
                }
                disabled={false}
                setDisable={() => {}}
              />
            </div>
            {!isGenerating ? (
              <Button
                key={2}
                icon="sent"
                iconSize={18}
                iconColor="white"
                className={cs(
                  'mb-1 border-none w-[32px] h-[32px]',
                  disableGenerate ? '!bg-neutral-100' : 'bg-primary-500'
                )}
                onClick={handleGenerate}
                disabled={disableGenerate}
                type="secondary"
                color={disableGenerate ? 'neutral' : 'white'}
              />
            ) : (
              <Tippy content="Stop response" className="tippy-dark" placement="bottom">
                <div className="w-[32px] h-[32px] flex justify-center items-center">
                  <Icon
                    key={2}
                    icon="stop-circle-filled"
                    size={32}
                    color="primary"
                    onClick={onStopGeneration}
                  />
                </div>
              </Tippy>
            )}
          </div>
        </div>
      </AIChatInput>
      <p className="text-center text-xs italic text-neutral-700">
        AI may make mistakes. Providers are responsible for the accuracy of all clinical
        documentation.
      </p>
      {showConfirm && (
        <Confirm
          handleOpen={() => setShowConfirm(true)}
          handleClose={() => setShowConfirm(false)}
          handleContinue={() => {
            setShowConfirm(false);
            onGenerate();
          }}
          title="Warning!"
          message="Heads up! You’ve entered fewer than 50 words. MyScribe is a trained medical scribe—working solely off of the information you provide. For the most accurate results, please add more detail and include outside context using the tools below your chat box."
          primaryBtnTxt="Yes"
          secondaryBtnTxt="No"
          icon="mysc-ai-triple-stars"
          variant="danger"
          footer={
            <div className="mt-4 flex items-center justify-end gap-2">
              <Button
                text="Proceed anyway"
                color="red"
                shade="400"
                onClick={() => {
                  setShowConfirm(false);
                  onGenerate();
                }}
              />
              <Button
                text="Add more info"
                color="neutral"
                outlined
                onClick={() => setShowConfirm(false)}
              />
            </div>
          }
        />
      )}
    </>
  );
}
