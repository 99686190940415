
export const initialValues = (product) => ({
  code: product?.code || '',
  name: product?.name || '',
  note: product?.note || '',
  expiration_date: product?.expiration_date || '',
  amount_cents: product?.amount_cents || 0,
  cost_cents: product?.cost_cents || 0,
  amount_cents_with_tax: product?.amount_cents_with_tax || '',
  state_tax_rate: product?.state_tax_rate || '',
  local_tax_rate: product?.local_tax_rate || '',
  quantity: product?.quantity || '',
  quantity_units: product?.quantity_units || '',
  status: product?.status || '',
  image: product?.image || '',
  lot: product?.lot || '',
  category_id: product?.category_id || null,
  color: product?.color || "#000000",
});
