import React from 'react';
import { useNavigate } from 'react-router-dom';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import { createSettingsPayer } from 'api/Payer';

import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const HeaderButton = ({ searchPayers, openModal, selectInputRef }) => {
  const navigate = useNavigate();

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        {selectInputRef.current?.inputRef?.value ? (
          <div className="flex items-center justify-center gap-4 py-4">
            <span className="text-neutral-700">No payer found!</span>

            <Button
              type="primary"
              text="Request a new payer"
              id="newPayerBtn"
              data-qa="new-payer-btn"
              onClick={() => {
                openModal();
                selectInputRef.current.blur();
                selectInputRef.current.value = selectInputRef.current?.inputRef?.value || '';
              }}
            />
          </div>
        ) : (
          <span className="text-neutral-500">Start typing the payer name.</span>
        )}
      </components.NoOptionsMessage>
    );
  };

  const Control = ({ children, ...props }) => {
    return <components.Control {...props}>{children}</components.Control>;
  };

  const { mutate: createPayerMutation } = useCustomMutation({
    mutationFn: ({ payerId }) => createSettingsPayer(navigate, { payerId }),
    successMessage: 'Payer added successfully!',
    errorMessage: 'Could not add payer!',
    invalidateQueryKey: ['getPayers', 'payers']
  });

  return (
    <AsyncSelect
      loadOptions={searchPayers}
      placeholder="Add Payer..."
      onChange={(e) => createPayerMutation({ payerId: e.value })}
      ref={selectInputRef}
      value=""
      className="w-96 h-[48px]"
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: state.isFocused ? '#54D2FF' : '#dee2e6',
          padding: '3px 5px',
          borderRadius: '6px',
          boxShadow: state.isFocused ? '0px 0px 0px 2px #CBF0FD' : '0px 2px 4px 0px #1018280A'
        })
      }}
      components={{ Control, NoOptionsMessage }}
    />
  );
};

export default withErrorBoundary(HeaderButton);
