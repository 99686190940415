import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import React from 'react';

const Superbill = ({ data = {}, setBillingModalState }) => {
  const handleOpenSuperbill = () => {
    setBillingModalState({
      open: true,
      selectedTab: 'Superbills',
      id: data.superbillId
    });
  };

  const handleOpenClaim = () => {
    setBillingModalState({
      open: true,
      selectedTab: 'Claims',
      id: data.claimId
    });
  };

  return (
    <Allowed requiredPermissions="superbill.read" showMessage>
      <div>
        {data.superbillId ? (
          <div className="flex flex-col gap-1">
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm font-500 text-neutral-800 ">{`Superbill #${data.superbillId}`}</p>
              <div className="flex cursor-pointer items-center gap-2" onClick={handleOpenSuperbill}>
                <p className="text-sm  text-primary-600">View</p>
                <Icon icon="new-document-preview" color="primary" />
              </div>
            </div>
            {data.claimId && (
              <div className="flex w-full items-center justify-between ">
                <p className="text-sm font-500 text-neutral-800 ">{`Claim #${data.claimId}`}</p>
                <div className="flex cursor-pointer items-center gap-2" onClick={handleOpenClaim}>
                  <p className="text-sm  text-primary-600">View</p>
                  <Icon icon="new-document-preview" color="primary" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-sm  text-neutral-800">
            Superbill will be created when the clinical note starts.
          </p>
        )}
      </div>
    </Allowed>
  );
};

export default Superbill;
