import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getExternalInvoices } from 'api/Billing';

export const useExternalInvoices = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['external_invoices', ...dependencies],
    () => getExternalInvoices(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
