import React, { useState } from 'react';
import { TagContext } from './TagContext';
import _ from 'lodash';
import { ia } from 'lib/helpers/utility';

export const TagContextProvider = ({ children }) => {
  const [importantTags, setImportantTags] = useState([]);
  const [regularTags, setRegularTags] = useState([]);
  const [formattedTags, setFormattedTags] = useState([]);

  const updateColors = (sortedTags, allTags) => {
    if (!ia(allTags)) return sortedTags;
    return sortedTags
      ?.map((tag) => {
        const matchingTag = allTags?.find((allTag) => allTag.id === tag.id);

        if (
          matchingTag &&
          (matchingTag.color !== tag.color ||
            matchingTag.name !== tag.name ||
            matchingTag.type !== tag.type ||
            matchingTag.public !== tag.public ||
            matchingTag.important !== tag.important)
        ) {
          return {
            ...tag,
            name: matchingTag?.name,
            color: matchingTag?.color,
            type: matchingTag?.type,
            public: matchingTag?.public,
            important: matchingTag?.important
          };
        } else if (matchingTag) {
          return { ...tag };
        } else {
          return null;
        }
      })
      .filter(Boolean);
  };

  const updateTags = (newTags, allTags) => {
    const important = newTags.filter((tag) => tag.important);
    const sortedImportant = _.orderBy(important, (tag) => tag.tagLinks[0]?.position, 'asc');

    const regular = newTags.filter((tag) => !tag.important);
    const sortedRegular = _.orderBy(regular, (tag) => tag.tagLinks[0]?.position, 'asc');
    const updatedImportantTags = updateColors(sortedImportant, allTags);
    const updatedRegularTags = updateColors(sortedRegular, allTags);

    setImportantTags(updatedImportantTags);
    setRegularTags(updatedRegularTags);

    setFormattedTags([...updatedImportantTags, ...updatedRegularTags]);
  };

  const updateTagsForTables = (newTags) => {
    const onlyImportantTags = newTags.filter((tagLink) => tagLink?.tag?.important);
    const sortedImportant = _.orderBy(onlyImportantTags, (tag) => tag?.position, 'asc');
    const currentImportantTags = sortedImportant?.map((t) => t?.tag).filter(Boolean);
    setImportantTags(currentImportantTags);

    const regular = newTags.filter((tagLink) => !tagLink?.tag?.important);
    const sortedRegular = _.orderBy(regular, (tag) => tag?.position, 'asc');
    const currentRegularTags = sortedRegular?.map((t) => t?.tag).filter(Boolean);
    setRegularTags(currentRegularTags);

    setFormattedTags([...currentImportantTags, ...currentRegularTags]);
  };

  return (
    <TagContext.Provider
      value={{
        importantTags,
        setImportantTags,
        regularTags,
        setRegularTags,
        formattedTags,
        setFormattedTags,
        updateTags,
        updateTagsForTables
      }}>
      {children}
    </TagContext.Provider>
  );
};
