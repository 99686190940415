import Button from '../../../../Buttons/Button';

const Reset = ({ onClick }) => (
  <Button
    text="Reset filter"
    onClick={onClick}
    outlined
    color="neutral"
    data-qa="reset-filter-btn"
    size="small"
    textClassName="text-neutral-700"
  />
);

export default Reset;
