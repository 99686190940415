import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { confirmOrDeclineAppointment } from 'api/Appointment';

import { showAlert } from 'components/shared/Alert/Alert';

import { ia } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Buttons/Button';
import Modal from '../../../shared/Modal/Modal';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { calendarFilters, currentPractice } from '../../practiceState';

import './style/RBCalendar.scss';

const PendingAppointments = ({
  view,
  loadingPending,
  pendingAppointments,
  setPendingAppointments,
  showPendingAppointments,
  hidePendingAppointments
}) => {
  const practice = useRecoilValue(currentPractice);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setFilters = useSetRecoilState(calendarFilters);
  const [loading, setLoading] = useState({});

  const updateAppointmentStatus = async (appointment, status, index) => {
    try {
      setLoading((prevState) => ({ ...prevState, [index]: true }));
      const params = { appointment_id: appointment.id, update_appointment: true, status };
      const { code } = await confirmOrDeclineAppointment(navigate, params);

      if (code == 0) {
        setPendingAppointments((prevState) => prevState?.filter((p) => p.id !== appointment.id));
        setFilters((prevState) => ({ ...prevState, selectedView: view }));
        showAlert({
          color: 'success',
          message: `Appointment was updated successfully`
        });
        queryClient.invalidateQueries(['appointment-events']);
      } else {
        showAlert({
          color: 'danger',
          message: 'There was an error updating this appointment'
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prevState) => ({ ...prevState, [index]: false }));
    }
  };

  return (
    <Modal
      handleOpen={showPendingAppointments}
      handleClose={hidePendingAppointments}
      title="Pending Appointments"
      slideFromRight>
      {loadingPending ? (
        <Skeleton className="!mb-4 !rounded-md" height="250px" count={3} />
      ) : ia(pendingAppointments) ? (
        pendingAppointments.map((appointment, idx) => {
          const date = moment.tz(appointment.starts_at, practice?.timezone).format('M/D/YYYY');
          const startsAt = moment.tz(appointment.starts_at, practice?.timezone).format('h:mm A z');
          const endsAt = moment.tz(appointment.ends_at, practice?.timezone).format('h:mm A z');
          return (
            <div className="mb-4 flex flex-col gap-2 rounded-md bg-primary-50 p-6 shadow" key={idx}>
              <div className="justify-between2 flex items-center gap-3">
                <p>Patient:</p>
                <p className="font-600 text-primary-900">{appointment.patient.fullName}</p>
              </div>
              <div className="justify-between2 flex items-center gap-3">
                <p>Practitioner:</p>
                <p className="font-600 text-primary-900">{appointment.practitioner.fullName}</p>
              </div>
              <div className="justify-between2 flex items-center gap-3">
                <p>Service:</p>
                <p className="font-600 text-primary-900">{appointment?.services?.[0]?.name}</p>
              </div>
              <div className="justify-between2 flex items-center gap-3">
                <p>Time:</p>
                <p className="font-600 text-primary-900">
                  {date} - {startsAt} to {endsAt}
                </p>
              </div>
              <div className="mt-2 flex items-center gap-2">
                <Button
                  text="Reject"
                  color="danger"
                  onClick={() => updateAppointmentStatus(appointment, 'cancelled', idx)}
                  className="w-[100px]"
                  disabled={loading[idx]}
                />
                <Button
                  text="Approve"
                  onClick={() => updateAppointmentStatus(appointment, 'unconfirmed', idx)}
                  className="w-[100px]"
                  disabled={loading[idx]}
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="font-600" data-dd-privacy="allow">
          No Pending Appointments.
        </div>
      )}
    </Modal>
  );
};

export default PendingAppointments;
