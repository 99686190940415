import React from 'react';
import Icon from 'components/shared/Icon/Icon';

const outboundIcon = {
  success: 'outbound-fax-success',
  processing: 'outbound-fax-processing',
  transmitting: 'outbound-fax-processing',
  failed: 'outbound-fax-failed'
};

export const NameCell = (params) => {
  const fax = params?.data;

  if (!fax) return;

  return (
    <div className="flex flex-row">
      <Icon icon={outboundIcon[fax.status]} />
      <p className="ml-2">{fax?.name || 'Unnamed'}</p>
    </div>
  );
};
