import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useQuery, useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';

import { formatDateAndTime } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

export default function AdminStaff() {
  const navigate = useNavigate();

  const [payerConfirmation, setPayerConfirmation] = useState(null);
  const queryClient = useQueryClient();

  const [rejectInput, setRejectInput] = useState();

  const { data } =
    useQuery({
      queryKey: ['getPayerRequests'],
      queryFn: getPayerRequests,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  async function getPayerRequests() {
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to read payers!",
          color: 'danger'
        });
      }
    };
    const response = await requestApi({
      url: '/api/payers/requests',
      params: {
        withCount: true
      },
      navigate,
      onError
    });

    return response;
  }

  const approvePayer = async (payerId, approved) => {
    const onSuccess = () => {
      showAlert({
        title: 'Success!',
        message: `Payer ${approved ? 'approved' : 'rejected'} successfully!`,
        color: 'success'
      });
      setPayerConfirmation(null);
      queryClient.invalidateQueries(['getPayerRequests']);
      queryClient.invalidateQueries(['getRequestsCount']);
    };

    const onError = () => {
      showAlert({
        title: 'Something went wrong!',
        message: `Could not ${approved ? 'approve' : 'reject'} payer!`,
        color: 'danger'
      });
      setPayerConfirmation(null);
    };

    requestApi({
      url: '/api/payers/approve',
      params: { payerId, approved, rejectReason: rejectInput },
      onSuccess,
      onError,
      navigate
    });

    setRejectInput();
  };

  return (
    <div className="flex flex-col">
      <table className="primary-table">
        <thead>
          <tr>
            <th className="w-[250px]">Name</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Requested by (User)</th>
            <th>Practice</th>
            <th>Requested At</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {data?.payers.map((payer, index) => (
            <tr key={'payer-' + index}>
              <td>{payer.name}</td>
              <td>{payer.phone}</td>
              <td>{payer.payerAddress?.fullAddress}</td>
              <td>
                <Link to={`/admin/practitioners/${payer.user?.id}`}>{payer.user?.fullName}</Link>
              </td>
              <td>
                <Link to={`/admin/practices/${payer.payerPractice?.id}`}>
                  {payer.payerPractice?.name}
                </Link>
              </td>
              <td>{formatDateAndTime(payer.created_at)}</td>
              <td className="mt-[6px] flex justify-end gap-2 pr-3">
                <Button
                  onClick={() => setPayerConfirmation({ payer, approve: true })}
                  type="secondary"
                  icon="checkmark-circle"
                  color="success"
                  text="Approve"
                  className="m-0"
                />

                <Button
                  type="secondary"
                  icon="close-circle"
                  color="danger"
                  text="Reject"
                  className="m-0"
                  onClick={() => setPayerConfirmation({ payer, approve: false })}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {payerConfirmation &&
        (payerConfirmation?.approve ? (
          <Confirm
            variant="success"
            primaryBtnTxt="Approve"
            title={`Approve Payer: ${payerConfirmation.payer?.name}`}
            icon="checkmark-circle"
            message="Are you sure you want to approve this payer?"
            loading={false}
            handleContinue={() => approvePayer(payerConfirmation.payer?.id, true)}
            handleOpen={payerConfirmation != null}
            handleClose={() => setPayerConfirmation(null)}
          />
        ) : (
          <Confirm
            variant="danger"
            title={`Reject Payer: ${payerConfirmation.payer?.name}`}
            primaryBtnTxt="Reject"
            icon="close-circle"
            message="Provide a reason why you want to reject this payer:"
            loading={false}
            handleContinue={() => approvePayer(payerConfirmation.payer?.id, false)}
            handleOpen={payerConfirmation != null}
            handleClose={() => setPayerConfirmation(null)}>
            <Input
              type="text"
              className="!mt-2 -mr-3"
              onChange={(e) => setRejectInput(e.target.value)}
            />
          </Confirm>
        ))}
    </div>
  );
}
