import { capitalize } from 'lodash';

import { formatDate, iaRa } from 'lib/helpers/utility';

import SuperbillActions from 'components/practice/charts/billing/superbill/components/SuperbillActions';

import { filterSuperbillClaimProcedure } from '../../lib/procedureUtils';

export default function (timezone) {
  return [
    { field: 'id', headerName: 'Superbill ID', maxWidth: 140 },
    {
      field: 'f_name',
      headerName: 'First Name',
      valueFormatter: ({ value }) => value.substring(0, 10)
    },
    {
      field: 'l_name',
      headerName: 'Last Name',
      valueFormatter: ({ value }) => value.substring(0, 10)
    },
    {
      field: 'provider_name',
      headerName: 'Rend. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      flex: 1,
      minWidth: 170
    },
    {
      field: 'bill_name',
      headerName: 'Bill. Prov.',
      valueFormatter: ({ value }) => capitalize(value),
      minWidth: 170,
      flex: 1
    },
    {
      field: 'payer_name',
      headerName: 'Payer',
      valueFormatter: ({ value }) => capitalize(value),
      minWidth: 170,
      flex: 1
    },
    {
      field: 'starts_at',
      headerName: 'DOS',
      valueFormatter: ({ value }) => formatDate(value, timezone, true),
      width: 150
    },
    {
      field: 'services',
      headerName: 'Services',
      valueGetter(params) {
        const services = iaRa(params.data?.invoice_procedures)
          .filter(filterSuperbillClaimProcedure)
          .map((item) => item.procedure_code)
          ?.join(', ');

        return services || null;
      },
      sortable: false
    },
    {
      field: 'claim_id',
      headerName: 'Claim',
      valueFormatter: ({ value }) => capitalize(value),
      maxWidth: 150,
      minWidth: 120
    },
    {
      field: 'state',
      headerName: 'Status',
      valueFormatter: ({ value }) => capitalize(value),
      maxWidth: 100
    },
    {
      field: 'actions',
      headerName: '',
      cellRenderer: SuperbillActions,
      maxWidth: 100
    }
  ];
}
