import React, { useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import usePageTitle from '../../../lib/hooks/usePageTitle';
import CommsCenterSidebar from './components/sidebar/CommsCenterSidebar';
import { useRecoilState } from 'recoil';
import state from '../../state';

export const CommsCenter = () => {
  usePageTitle('CommsCenter');
  // TODO add fax permissions
  const [permissions, setPermissions] = useRecoilState(state.permissions);

  return (
    <div className="flex bg-neutral-50 h-full">
      <CommsCenterSidebar />
      <div className="w-full overflow-hidden !rounded-lg">
        <Outlet />
      </div>
    </div>
  );
};
export default CommsCenter;
