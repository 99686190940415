import React, { useReducer, useState, useEffect } from 'react';
import { OrganizationContext } from './OrganizationContext';

const initialState = {
  customForms: {
    data: [],
    selectAll: false,
    excludedData: []
  },
  patients: {
    data: [],
    selectAll: false,
    excludedData: []
  },
  services: {
    data: [],
    selectAll: false,
    excludedData: []
  },
  packages: {
    data: [],
    selectAll: false,
    excludedData: []
  },
  products: {
    data: [],
    selectAll: false,
    excludedData: []
  }
};

const formReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        [action.payload.table]: { ...state[action.payload.table], data: action.payload.data }
      };
    case 'SELECT_ALL':
      return {
        ...state,
        [action.payload.table]: { data: [], selectAll: true, excludedData: [] }
      };
    case 'EXCLUDE_DATA':
      return {
        ...state,
        [action.payload.table]: {
          ...state[action.payload.table],
          excludedData: action.payload.data
        }
      };
    case 'RESET_DATA':
      return { ...state, [action.payload.table]: { data: [], selectAll: false, excludedData: [] } };

    case 'RESET_All_DATA':
      return initialState;
    default:
      return state;
  }
};

export const OrganizationContextProvider = ({ children }) => {
  const [selectedOrganization, setSelectedOrganization] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const [selectedLocation, setSelectedLocation] = useState();
  const [importAll, setImportAll] = useState(false);
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [filter, setFilter] = useState({
    customForms: { search: '', enabled: true },
    patients: { search: '', enabled: true },
    services: { search: '', enabled: true },
    packages: { search: '', enabled: true },
    products: { search: '', enabled: true }
  });
  const getTablesData = () => {};

  useEffect(() => {
    const tables = Object.keys(filter);
    const newFilter = {};
    tables.forEach((table) => {
      newFilter[table] = { search: '', enabled: false };
    });
    setFilter(newFilter);
  }, []);

  return (
    <OrganizationContext.Provider
      value={{
        selectedOrganization,
        setSelectedOrganization,
        selectedOwner,
        setSelectedOwner,
        state,
        dispatch,
        selectedLocation,
        setSelectedLocation,
        importAll,
        setImportAll
      }}>
      {children}
    </OrganizationContext.Provider>
  );
};
