import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getInboundFaxes } from '../../../../api/Fax';

export const useInboundFaxes = ({ dependencies = [], params = {}, options = {} }) => {
  const navigate = useNavigate();
  return useQuery(['inboundFaxes', ...dependencies], () => getInboundFaxes(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
