import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { Truncate } from 'lib/helpers/utility';

export const getIconAndText = (receiver, medium, notificationKind, patientName) => {
  let icon = null;
  let text = null;
  let body = null;

  if (!notificationKind) {
    switch (medium) {
      case 'web':
        icon = <Icon icon={'new-message'} />;
        text = 'sent a message to';
        break;
      case 'sms':
        icon = <Icon icon={'new-text-message'} />;
        text = 'sent a text message to';
        break;
      default:
        icon = 'unknown';
        text = 'unknown log type';
        break;
    }
  } else {
    switch (medium) {
      case 'sms':
        let { title: titleSms } = tempBodyTextForEmails({ type: notificationKind, patientName });
        icon = <Icon icon={'new-text-message'} />;
        text = titleSms;
        break;
      case 'email':
        let { title, message } = tempBodyTextForEmails({
          type: notificationKind,
          patientName,
          isEmail: true
        });
        icon = <Icon icon={'new-email'} />;
        text = title;
        body = message;
        break;
      case 'encounter':
        icon = <Icon icon={'new-encounter'} />;
        text = 'added an encounter';
        break;
      case 'phone_calls':
        icon = <Icon icon={'new-phone'} />;
        text = 'made a phone call and encounter';
        break;
      default:
        icon = 'unknown';
        text = 'unknown log type';
        break;
    }
  }

  return { icon, text, body };
};

export const tempBodyTextForEmails = ({ type, patientName, isEmail }) => {
  const kind = type?.replace('_', ' ');
  let title = `sent an ${kind} email to`;
  let message = null;
  switch (type) {
    case 'appointment_create':
      title = `${isEmail ? 'emailed' : 'texted'} a new appointment notification to`;
      message = `Hi ${patientName}, We're looking forward to see you on...`;
      break;
    case 'appointment_reject':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment reject to`;
      message = `Hi ${patientName}, We regret to inform you that your appointment request for...`;
      break;
    case 'appointment_pending':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment pending to`;
      message = `Hi ${patientName}, Thank you for requesting an appointment with our practice...`;
      break;
    case 'appointment_remind':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment reminder to`;
      message = `Hi ${patientName}, This is a friendly reminder about your upcoming appointment...`;
      break;
    case 'appointment_approved':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment approve to`;
      message = `Hi ${patientName}, Your appointment request has been approved and is scheduled for...`;
      break;
    case 'receipt':
      title = `${isEmail ? 'emailed' : 'texted'} a payment receipt to`;
      message = `Hi ${patientName}, Thank you for your payment...`;
      break;
    case 'invoicing':
      title = `${isEmail ? 'emailed' : 'texted'} an invoice to`;
      message = `Hi ${patientName}, New invoice available for payment - invoice...`;
      break;
    case 'patient_invitation':
      title = `${isEmail ? 'emailed' : 'texted'} a new patient invitation to`;
      message = `Hi ${patientName}, We are happy to have you at...`;
      break;
    case 'self_scheduling_register':
      title = `${isEmail ? 'emailed' : 'texted'} a new patient registration to`;
      message = `Hi ${patientName}, We are happy to have you at...`;
      break;
    case 'appointment_cancel':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment cancelation notice to`;
      message = `Hi ${patientName}, We have canceled your appointment with...`;
      break;
    case 'appointment_confirm':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment confirm to`;
      message = `Hi ${patientName}, Thank you for confirming your upcoming appointment with...`;
      break;
    case 'superbill':
      title = `${isEmail ? 'emailed' : 'texted'} a superbill to`;
      message = `Hi ${patientName}, A new file has been posted to your...`;
      break;
    case 'medical_history':
      title = `${isEmail ? 'emailed' : 'texted'} a medical history to`;
      message = `Hi ${patientName}, A new medical record has been posted to your...`;
      break;
    case 'treatment_plan':
      title = `${isEmail ? 'emailed' : 'texted'} a treatment plan to`;
      message = `Hi ${patientName}, A new treatment plan has been posted to your...`;
      break;
    case 'important_paperwork':
      title = `${isEmail ? 'emailed' : 'texted'} an important paperwork to`;
      message = `Hi ${patientName}, Please click on the button below to access...`;
      break;
    case 'appointment_reschedule':
      title = `${isEmail ? 'emailed' : 'texted'} an appointment reschedule to`;
      message = `Hi ${patientName}, We would like to inform you that your appointment has been rescheduled...`;
      break;
    case 'confirm_response':
      title = `${isEmail ? 'emailed' : 'texted'} a confirmation acknowledgment to`;
      message = '';
      break;
    case 'confirm_success':
      title = `${isEmail ? 'emailed' : 'texted'} a confirmed appointment to`;
      message = '';
      break;
    case 'confirm_failed':
      title = `${isEmail ? 'emailed' : 'texted'} a confirm failed to`;
      message = '';
      break;
    case 'instant_packet_create':
      title = `${isEmail ? 'emailed' : 'texted'} an instant packet to`;
      message = `Hello ${patientName}, You have new forms to fill out...`;
      break;
    case 'share_document_with_pt':
      title = `${isEmail ? 'emailed' : 'texted'} a document to`;
      message = `Hi ${patientName}, A new document has been posted to your...`;
      break;
  }

  return isEmail ? { title, message } : { title };
};

export const textTitle = (body) => {
  let text = null;
  const bodyPart = body?.split(',');
  if (bodyPart?.length > 1) {
    text = Truncate(bodyPart[0], 55);
  }

  return text;
};
