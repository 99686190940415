import { requestApi } from './Api';

export const getOccupations = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/occupation/get',
    params,
    onError: () => {}
  });

  export const deleteOccupation = async (navigate, params) => {
    return await requestApi({
      navigate,
      url: '/api/occupation/delete',
      params
    });
  };
