import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { useSuperbills } from 'lib/hooks/queries/superbill/useSuperbills';
import useModal from 'lib/hooks/useModal';

import { currentPractice } from 'components/practice/practiceState';
import { rowClassRules } from 'components/shared/AGTable/lib/helpers';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ButtonActions from './components/ButtonActions';
import ErrorModal from './components/ErrorModal';
import { defaultFilters, superbillTableColumns } from './lib';
import { SUPERBILL_SWITCH_ITEMS } from './lib/superbillTableColumns';

const Superbills = () => {
  const { claimId } = useParams();
  const practice = useRecoilValue(currentPractice);
  const cols = superbillTableColumns(practice.timezone);
  const [selectedSuperbill, setSelectedSuperbill] = useState(null);
  const { isOpen, openModal, closeModal } = useModal();

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      const { id, patient_id, error } = event?.data || {};
      if (error) {
        setSelectedSuperbill(event?.data);
        openModal();
        return;
      }
      const link = `/portal/charts/${patient_id}/billing/superbill/${id}`;
      window.open(link, '_blank');
    }
  };

  return (
    <>
      <AgTablePage
        ModalComponent={({ errors }) => {
          return (
            <ErrorModal
              superbill={selectedSuperbill}
              isOpen={isOpen}
              handleClose={closeModal}
              errors={errors?.[selectedSuperbill?.claim_id]}
            />
          );
        }}
        defaultColumns={cols}
        defaultFilters={defaultFilters}
        useData={useSuperbills}
        name="superbills"
        rowStyle={{ cursor: 'pointer' }}
        actions={ButtonActions}
        switchItems={SUPERBILL_SWITCH_ITEMS}
        onRowClicked={onRowClicked}
        noNewButton
        rowClassRules={rowClassRules}
      />
      {claimId && <Outlet />}
    </>
  );
};

export default Superbills;
