import { useQuery } from '@tanstack/react-query';
import { getPractices } from '../../../../api/Practice';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '../../../../api/Api';

export const usePractice = (options) => {
  const navigate = useNavigate();
  return useQuery(['practice', options?.practice_id], () => getPractices(navigate, options), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};

export const updatePractice = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/update',
    params: params,
    throwError: true
  });
