import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getUserBoard } from '../../../../api/Boards';

export const useUserBoard = (params) => {
  const navigate = useNavigate();
  return useQuery(['userBoard', params], () => getUserBoard(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false
  });
};
