import { requestApi } from 'api/Api';
import Honeybadger from '@honeybadger-io/js';
import { showAlert } from 'components/shared/Alert/Alert';

export const impersonate = async (navigate, params) => {
  await requestApi({
    navigate,
    params,
    url: '/api/admin/practice/impersonate',
    onError: (_, response) => {
      const { redirect, error } = response;
      Honeybadger.notify(
        `file: practitioners/PractitionersBasicInfo, method: impersonateUser - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
      if (error) showAlert({ title: error, color: 'danger' });
      navigate(redirect);
    },
    onSuccess: (_, response) => {
      const { redirect } = response;
      window.open(redirect, '_blank');
    }
  });
};
