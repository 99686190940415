import React, { useEffect, useState } from 'react';

import cs from 'classnames';
import { fabric } from 'fabric';
import { capitalize } from 'lodash';

import Icon from 'components/shared/Icon/Icon';

import Skeleton from '../Skeleton/Skeleton';

import EditorNav from './components/EditorNav';
import EditorSelections from './components/EditorSelections';
import EditorToolbar from './components/EditorToolbar';
import { dispatchFabricHandlers, initFabricHandlers } from './lib/fabricHandlers';
import useEditorHistory from './lib/useEditorHistory';
import useEditorRenderer from './lib/useEditorRenderer';
import useEditorTools from './lib/useEditorTools';

const DocumentEditor = ({
  src,
  fileType = 'pdf',
  canvasStates,
  setCanvasStates,
  fabricCanvasRef,
  currentPage,
  setCurrentPage,
  scale,
  setScale,
  historyMode,
  isPdfLoaded,
  setIsPdfLoaded,
  pdf,
  loadingVersion
}) => {
  const [totalPages, setTotalPages] = useState(0);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [fontFamily, setFontFamily] = useState('Times New Roman');
  const [objectSelected, setObjectSelected] = useState(false);
  const [selectedAction, setSelectedAction] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  const loading = loadingVersion || isLoading;

  const [_, setSignature] = useState(null);

  useEffect(() => {
    if (historyMode && selectedAction && fabricCanvasRef?.current) {
      setSelectedAction(null);
      const fabricCanvas = fabricCanvasRef.current;
      fabricCanvas.isDrawingMode = false;
      fabricCanvas.discardActiveObject();
      fabricCanvas.renderAll();
    }
  }, [historyMode]);

  const { handleAction } = useEditorTools({
    fabricCanvasRef,
    isBold,
    isItalic,
    fontFamily,
    setSignature
  });

  const { undo, redo, pushToHistory, historyState } = useEditorHistory({
    fabricCanvasRef
  });

  const { loadFile, renderPdf } = useEditorRenderer({
    fabricCanvasRef,
    setScale,
    setTotalPages,
    canvasStates,
    setIsLoading,
    setIsPdfLoaded,
    currentPage,
    fileType,
    pdf,
    historyState,
    pushToHistory
  });

  useEffect(() => {
    const fabricCanvas = new fabric.Canvas('fabric-canvas', {
      selection: true,
      preserveObjectStacking: true,
      isDrawingMode: selectedAction === 'draw'
    });

    fabricCanvasRef.current = fabricCanvas;

    initFabricHandlers({
      fabricCanvas,
      setObjectSelected,
      setIsBold,
      setIsItalic,
      setFontFamily,
      setSelectedAction,
      handleAction,
      pushToHistory
    });

    return () => {
      dispatchFabricHandlers(fabricCanvas);
      fabricCanvas.dispose();
    };
  }, []);

  useEffect(() => {
    if (src) {
      loadFile(src);
    }
  }, [src, fileType]);

  return (
    <div className={cs('relative', !historyMode && 'w-full')}>
      <div
        className={cs(
          'flex justify-between',
          historyMode
            ? 'bg-neutral-50'
            : 'border !border-x-0 !border-t-0 border-solid !border-neutral-100 bg-white shadow-[0px_0px_16px_rgba(0,0,0,0.07)]'
        )}>
        {!historyMode ? (
          <EditorToolbar
            {...{
              fabricCanvasRef,
              isPdfLoaded,
              selectedAction,
              setSelectedAction
            }}
          />
        ) : null}

        <EditorNav
          {...{
            src,
            canvasStates,
            setCanvasStates,
            fabricCanvasRef,
            currentPage,
            setCurrentPage,
            scale,
            totalPages,
            renderPdf,
            historyState,
            historyMode,
            undo,
            redo,
            fileType
          }}
        />
      </div>

      <div className="relative h-full">
        {!historyMode && (
          <EditorSelections
            {...{
              fabricCanvasRef,
              historyMode,
              isBold,
              setIsBold,
              isItalic,
              setIsItalic,
              fontFamily,
              setFontFamily,
              selectedAction,
              setSelectedAction,
              setSignature,
              objectSelected,
              setObjectSelected,
              isPdfLoaded
            }}
          />
        )}

        <div
          className={cs(
            'relative flex !h-full items-center justify-center min-w-[700px] !overflow-y-auto',
            (!(isPdfLoaded && fabricCanvasRef) || loading) && 'hidden'
          )}>
          <div
            className={!!selectedAction ? 'h-full !cursor-copy' : 'h-full'}
            style={{ position: 'relative' }}>
            <canvas className={!!selectedAction && '!cursor-copy'} id="fabric-canvas"></canvas>
          </div>
          {selectedAction && !historyMode && (
            <div className="z-100 absolute right-4 top-4 flex gap-2 rounded-lg bg-primary-50 px-3 py-1 text-primary-600">
              <Icon icon="new-info" color="primary" shade={600} />
              <p>Click on the file to add '{capitalize(selectedAction)}'</p>
            </div>
          )}
        </div>

        {loading && (
          <Skeleton
            height="100%"
            count={1}
            {...(historyMode && { width: '700px' })}
            containerStyle={{ height: '100%', minHeight: '500px' }}
          />
        )}
      </div>
    </div>
  );
};

export default DocumentEditor;
