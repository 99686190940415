import Tippy from '@tippyjs/react';

import Icon from 'components/shared/Icon/Icon';

export const getTemplatesPopoverOptions = ({
  navigate,
  userId,
  appointmentId,
  withTitle = false
}) => {
  const CARDS = [
    {
      id: 'map-template-with-myscribe',
      title: 'Chat-Style Charting',
      description:
        'Type a prompt summarizing the encounter, including patient forms, past visits and more. With one click, generate and map narratives to your desired note type.',
      icon: 'chat-style-charting',
      link: 'map-template-with-myscribe'
    },
    {
      id: 'ambient-listening',
      title: 'Ambient Listening (Coming soon)',
      disabled: true,
      description:
        'Transcribe patient encounters in real-time. MyScribe AI captures, structures, and maps narratives seamlessly, ensuring accurate charting as you speak.',
      icon: 'ambient-listening',
      link: 'ambient-listening'
    },
    {
      id: 'enhance-current-note',
      title: 'Enhance Note (Coming soon)',
      disabled: true,
      description:
        'Write or transcribe my note in separate templates to map my note typing category',
      icon: 'enhance-current-note',
      link: 'enhance-current-note'
    }
  ];
  const onCardClick = (card) => {
    navigate(`/portal/charts/${userId}/clinical-notes/${appointmentId}/myscribe-ai/${card.link}`);
  };
  const popoverOptions = CARDS?.map((card) => ({
    component: (
      <Tippy content={card.description} className="tippy-dark" placement="right">
        <div className="flex gap-2 justify-between items-center">
          <Icon icon={card.icon} />
          <div className="grow select-none whitespace-nowrap text-sm font-400 text-primary-900">
            {card.title}
          </div>
        </div>
      </Tippy>
    ),
    onClick: () => onCardClick(card),
    id: card.id,
    ...(withTitle && { title: card.title }),
    link: card.link,
    disabled: card.disabled
  }));
  return popoverOptions;
};
