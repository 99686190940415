import React, { Fragment, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';

import { Transition } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import { assign } from 'lodash';
import { useRecoilValue } from 'recoil';

import { getLabOrder } from 'api/LabOrder';
import { getNonERXPrescription } from 'api/NonERXPrescription';
import { getReferralOrder } from 'api/ReferalOrder';

import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useAttachments } from 'lib/hooks/queries/useAttachments';

import Axios from '../../../../../../configuredAxios';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa, io } from '../../../../../../lib/helpers/utility';
import { showAlert as Alert, AlertContent } from '../../../../../shared/Alert/Alert';
import Checkbox from '../../../../../shared/Checkbox/Checkbox';
import Icon from '../../../../../shared/Icon/Icon';
import { clinicalNote as clinicalNoteState, userState } from '../../../../../state';
import { selectedInitialState } from '../lib/initials';
import { reorder, reorderChildren } from '../lib/renderedDragAndDropHelper';
import {
  checkIfAllFormTypesFalse,
  prepareSelection,
  processAdvancedCustomForms,
  processAdvancedHPForms,
  processAdvancedSOAPForms
} from '../lib/selectedHelper';

import DragDropContainer from './components/DragDropContainer';
import { filterAndApplyPositions } from './lib/filterAndApplyPositions';
import { patientAggregateNarratives } from './lib/patientAggregateNarratives';

const Selected = ({ patientId }) => {
  const [isCheckAll, setIsCheckAll] = useState(true);
  const {
    currentHpOverviewData,
    vitals,
    cnDisplaySettings,
    advancedHP,
    advancedSOAP,
    customFormTypes,
    selected,
    setSelected,
    selectedCustomFormTypes = {},
    overviewData
  } = useClinicalNoteContext() || {};
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { id = patientId } = useParams();
  const { currentMyScribe } = useAmbientListeningContext();
  const user = useRecoilValue(userState);
  const [loading, setLoading] = useState({
    forms: true
  });
  const [showAlert, setShowAlert] = useState(true);
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const appointment = appointmentId ?? clinicalNote?.appointment_id;

  const { data } = useAttachments({
    params: {
      patientId: id,
      appointmentId: appointment
    },
    dependencies: [appointment],
    options: {
      enabled: !!appointment && !!id
    }
  });

  const attachments = useMemo(() => {
    return data?.documents || [];
  }, [data]);

  const toggleExpand = (key) => {
    setSelected((prevData) => ({
      ...prevData,
      [key]: { ...prevData[key], expanded: !prevData[key].expanded || false }
    }));
  };

  const getAdvancedHP = processAdvancedHPForms(advancedHP, cnDisplaySettings);
  const getAdvancedSOAP = processAdvancedSOAPForms(advancedSOAP, cnDisplaySettings);
  const getAdvancedFroms = Object.entries(customFormTypes || {}).map(([, object]) => {
    return processAdvancedCustomForms(object, cnDisplaySettings);
  });

  useEffect(() => {
    getSelected();
  }, [cnDisplaySettings, currentHpOverviewData, overviewData, currentMyScribe, attachments]);

  const queryClient = useQueryClient();

  const getOrders = async () => {
    const promises = await Promise.all([
      await getReferralOrder(navigate, {
        clinicalNoteIds: [appointment]
      }),
      await getNonERXPrescription(navigate, {
        appointmentIds: [appointment]
      }),
      await getLabOrder(navigate, {
        appointmentIds: [appointment],
        withRelations: { provider: true, tests: true, insurances: true }
      }),
      await patientAggregateNarratives({
        navigate,
        patientId: String(clinicalNote?.user_id),
        practice_id: clinicalNote?.practice_id,
        queryClient
      })
    ]);

    return {
      referralsData: iaRa(promises?.[0]?.data?.referrals),
      nonERXPrescription: iaRa(promises?.[1]?.data?.nonERXPrescription),
      labOrder: iaRa(promises?.[2]?.data?.labOrder),
      patientAggregateNarrative: promises?.[3]
    };
  };

  const prepareSelectedData = async (useDefaultSelection = true) => {
    setLoading((prevState) => ({ ...prevState, forms: true }));
    try {
      const { referralsData, nonERXPrescription, labOrder, patientAggregateNarrative } =
        await getOrders();
      const processedAdvancedForms = assign({}, ...getAdvancedFroms);

      const selectedObj = {
        ...selectedInitialState(
          clinicalNote,
          cnDisplaySettings,
          currentHpOverviewData,
          vitals,
          currentMyScribe,
          referralsData,
          nonERXPrescription,
          labOrder,
          overviewData,
          patientAggregateNarrative
        ),
        ...getAdvancedHP,
        ...getAdvancedSOAP,
        ...processedAdvancedForms
      };

      let componentsOrder = null;
      if (clinicalNote?.locked && io(clinicalNote?.components_order)) {
        componentsOrder = clinicalNote?.components_order;

        const checkIFNewVersion = Object.keys(componentsOrder).every((key) => {
          return !!componentsOrder[key]?.id || !!componentsOrder[key]?.title;
        });

        if (!checkIFNewVersion) {
          componentsOrder = filterAndApplyPositions(selectedObj, componentsOrder);
        }
      }

      let selection = null;
      if (useDefaultSelection) {
        const res = await Axios.post('/api/practice/medical_history/get_default_selection', {
          practitionerId: user?.user_id
        });
        selection = res.data.selection;
      }

      const result = prepareSelection({
        savedSelection: selection ? { ...selectedObj, ...selection } : null,
        initialSelection: selectedObj,
        componentsOrder,
        selectedCustomFormTypes,
        attachments
      });

      setSelected(result);
      return result;
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    } finally {
      setLoading((prevState) => ({ ...prevState, forms: false }));
    }
  };

  const getSelected = async () => {
    await prepareSelectedData(true);
  };

  const resetSelected = async () => {
    setLoading((prevState) => ({ ...prevState, saveSelected: true }));
    const result = await prepareSelectedData(false);
    await saveSelected(result, true);
  };

  const saveSelected = async (selectedData = selected, isReset = false) => {
    setLoading((prevState) => ({ ...prevState, saveSelected: true }));
    try {
      if (!selectedData) {
        return Alert({
          title: 'Final Note',
          message: `There are no forms selected to ${isReset ? 'reset' : 'save'}! If this keeps happening, please refresh and try again.`,
          color: 'warning',
          duration: 3000
        });
      }

      const res = await Axios.post('/api/practice/medical_history/save_default_selection', {
        practitionerId: user?.user_id,
        selection: selectedData
      });
      if (res.data) {
        Alert({
          title: 'Final Note',
          message: `The final note selections have been ${isReset ? 'reset' : 'saved'}!`,
          color: 'success',
          duration: 3000
        });
      }
    } catch (error) {
      toast.error('There was an error while saving!', { duration: 3000 });
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    } finally {
      setLoading((prevState) => ({ ...prevState, saveSelected: false }));
    }
  };

  const handleSelectAll = () => {
    setIsCheckAll((prevState) => !prevState);
    const updateSelected = { ...selected };

    Object.keys(updateSelected)
      .filter((key) => io(updateSelected[key]))
      .forEach((key) => {
        updateSelected[key] = {
          ...updateSelected[key],
          checked: !isCheckAll,
          ...(ia(updateSelected[key].formType) && {
            formType: updateSelected[key].formType.map((f) => ({
              ...f,
              checked: f.title === 'Narrative' ? !isCheckAll : false
            }))
          })
        };
      });

    setSelected(updateSelected);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const updateSelected = reorder(selected, result.source.index, result.destination.index);
    setSelected(updateSelected);
  };

  const onDragEndChildren = (result) => {
    if (!result.destination) return;

    const updateSelected = reorderChildren(
      selected,
      result.source.index,
      result.destination.index,
      result.destination.droppableId
    );

    setSelected({ ...updateSelected });
  };

  const handleClick = (key) => {
    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        checked: !selected[key].checked
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'PARENT',
      key
    });

    setSelected(processedObject);
  };

  const handleClickDropdown = (key, dropdownId) => {
    const updatedFormTypes = selected?.[key].formType.map((type) => {
      if (type?.id === dropdownId) return { ...type, checked: !type.checked };

      return type;
    });

    if (!ia(updatedFormTypes)) return;

    const currentObj = {
      ...selected,
      [key]: {
        ...selected[key],
        formType: updatedFormTypes
      }
    };

    const processedObject = checkIfAllFormTypesFalse({
      selection: currentObj,
      processType: 'CHILDREN',
      key
    });

    setSelected(processedObject);
  };

  return (
    <div className="flex h-full flex-col bg-primary-10">
      {!clinicalNote?.locked && (
        <Transition
          as={Fragment}
          show={showAlert}
          enter="transition-opacity ease-all duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-all duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="px-3 pb-1 pt-3">
            <AlertContent
              width="full"
              title="If you save your current selection, it will be automatically loaded next time you visit this screen."
              handleClose={() => setShowAlert(false)}
            />
          </div>
        </Transition>
      )}
      <div className="flex items-center justify-between gap-x-2 !px-4 !pt-2">
        <p className="text-base text-neutral-800">Export forms</p>
        <div className="flex items-center gap-x-[4px]">
          <Tippy content="Save selection" className="tippy-dark no-arrow">
            <div className="flex">
              <Icon
                icon="new-diskette"
                color="primary"
                shade={700}
                disabled={clinicalNote?.locked || loading?.forms || loading?.saveSelected}
                onClick={() => saveSelected()}
                className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
              />
            </div>
          </Tippy>
          <Tippy content="Reset selection" className="tippy-dark no-arrow">
            <div className="flex">
              <Icon
                icon="new-reset-neutral"
                color="primary"
                shade={700}
                stroke={true}
                disabled={clinicalNote?.locked || loading?.forms || loading?.saveSelected}
                onClick={resetSelected}
                className="flex h-6 w-6 items-center justify-center rounded-[4px] hover:bg-neutral-100"
              />
            </div>
          </Tippy>
        </div>
      </div>

      <Checkbox
        label="Select all forms"
        onChange={handleSelectAll}
        isChecked={isCheckAll}
        disabled={clinicalNote?.locked}
        className="ml-[38px] flex w-full items-center rounded-[4px] pb-0"
        inputClassName="border-neutral-400"
        parentLabelClassName="px-4 py-[11px]"
        id="allForms"
      />

      <DragDropContainer
        onDragEnd={onDragEnd}
        onDragEndChildren={onDragEndChildren}
        loading={loading}
        toggleExpand={toggleExpand}
        handleClick={handleClick}
        handleClickDropdown={handleClickDropdown}
      />
    </div>
  );
};

export default Selected;
