import NotFound from 'components/NotFound';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';
import Icon from 'components/shared/Icon/Icon';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';
import React, { useState } from 'react';
import { getSelectedForms, groupComponentsByType } from '../lib/helpers';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Buttons/Button';
import SignAndLock from 'components/practice/charts/ClinicalNote/Navbar/components/SignAndLock';

const Chat = () => {
  const navigate = useNavigate();
  const [disableGenerate, setDisableGenerate] = useState(true);

  const { selectedChatComponents, componentRefs } = useMyTemplateContext();
  const {
    currentHpOverviewData,
    clinicalNote,
    advancedHP,
    advancedSOAP,
    setEnhanceNarrativeParams,
    customFormTypes
  } = useClinicalNoteContext() || {};

  const customAdvancedForms = Object.values(customFormTypes || {})?.flat();
  const advancedForms = [...advancedHP, ...advancedSOAP, ...customAdvancedForms];
  const clinicalNoteForms = { ...clinicalNote, ...currentHpOverviewData };

  const selectedChatComponentTitles = selectedChatComponents?.map((item) => item?.title);
  const selectedForms = getSelectedForms(
    selectedChatComponentTitles,
    clinicalNoteForms,
    advancedForms
  );

  const groupedComponents = groupComponentsByType(selectedChatComponents);

  const components = Object.entries(groupedComponents || {}).map(([key, value]) => {
    return (
      <div key={key}>
        <div className="text-xl text-primary-500">{key}</div>
        {value
          ?.map((component) => {
            const Input = component?.forms || NotFound;

            const componentRef = (el) => {
              componentRefs.current[component.title] = el;
            };

            return (
              <div ref={componentRef} className="py-2" key={component.title} id={component?.title}>
                <Input inputName={component.title} type={component?.type} />
              </div>
            );
          })
          .filter(Boolean)}
      </div>
    );
  });

  const onGenerate = (text) => {
    console.log('🚀 ~ onGenerate ~ text:', text);
    const finalPrompt = {
      clinical_note_id: clinicalNote?.id,
      selectedForms
    };
    console.log({ finalPrompt });
    setEnhanceNarrativeParams({
      kind: 'myscribe-ai',
      messageKind: 'manual_map',
      body: selectedForms
    });
  };

  const onGoBack = () => {
    navigate(-1);
  };

  return (
    <div id="my-template-chat" className="overflow-y-none flex h-full flex-col pb-24">
      <Icon icon="chat-bg-pattern" className="!absolute left-0 top-0 z-[-1]" />
      {components?.length > 0 ? (
        <div className="flex-grow overflow-y-scroll px-4 pb-[150px]">
          <div className="mx-auto flex w-2/3 flex-col px-10">{components}</div>
        </div>
      ) : (
        <div className="flex flex-grow flex-col items-center justify-center overflow-y-auto">
          <Icon icon="choose-notes" />
          <h2 className="mb-4 text-xl text-[#004F6B]">Select type of note and choose forms</h2>
          <h4 className="mb-4 text-base text-[#6D828B]">
            You can start creating new clinical notes on chart details.
          </h4>
        </div>
      )}
      <div className="px-auto absolute bottom-[20px] z-50 flex w-1/2 self-center bg-transparent">
        <AIChatInput
          className="footer-blurred items-center pb-3"
          rightButtons={[
            <SignAndLock
              key={1}
              buttonProps={{
                className: '!py-2 !pl-4 !pr-4 mr-2 border-[1.5px] border-solid',
                outlined: true,
                textClassName: 'text-primary-300',
                iconColor: 'black',
                disabled: components?.length === 0
              }}
            />,
            <Button
              key={2}
              text="Enhance"
              icon="stars-ai"
              iconSize={18}
              iconColor="white"
              size="small"
              className="!py-2 !pl-4 !pr-4"
              disabled={components?.length === 0}
              onClick={onGenerate}
            />
          ]}
          onSubmit={onGenerate}
          onGoBack={onGoBack}
          isInput={false}
        />
      </div>
    </div>
  );
};

export default Chat;
