import React, { useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import { useQueryClient } from '@tanstack/react-query';

const PatientBasicInfo = () => {
  const { patient } = useOutletContext();
  const [password, setPassword] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const updatePassword = async () => {
    setLoading(true);
    if (
      password === confirmPassword &&
      typeof password === 'string' &&
      password.trim().length !== 0
    ) {
      try {
        let res = await requestApi({
          url: '/api/user/update',
          params: {
            user_id: id,
            updatedPassword: password
          },
          navigate
        });
        if (res.success && res.code === 0) {
          showAlert({ title: 'Password updated successfully' });
          queryClient.invalidateQueries(['patient']);
        }
      } catch (error) {
        console.error(error);
        Honeybadger.notify(
          `file: patient/PatientBasicInfo, method: updatePassword - catch, error: ${
            error ?? 'Theres been an error'
          }`
        );

        showAlert({ title: 'There was an error updating the password', color: 'danger' });
      }
    } else {
      showAlert({ title: 'Incorrect password', color: 'danger' });
    }
    setLoading(false);
  };

  if (!patient)
    return (
      <div className="!p-4">
        <Skeleton height="500px" />
      </div>
    );

  return (
    <div className="flex gap-4 !p-4">
      <Box className="flex flex-col gap-4">
        <Input
          label="Password"
          data-qa="password"
          value={password}
          type="password"
          onChange={(p) => setPassword(p.target.value)}
        />
        <Input
          label="Confirm Password"
          data-qa="confirm-password"
          type="password"
          value={confirmPassword}
          onChange={(p) => setConfirmPassword(p.target.value)}
        />
        <div className="flex flex-col gap-1">
          <p className="!mb-4 text-xs leading-4 text-primary-900">
            Patient will receive an email about their updated password
          </p>
          <Button text="Update password" loading={loading} onClick={updatePassword} data-qa="update-password-btn" />
        </div>
      </Box>
    </div>
  );
};

export default withErrorBoundary(PatientBasicInfo);
