import React from 'react';
import moment from 'moment';
import CheckinStepsStep from './Step';

const CheckinStepsPersonalInfo = ({ step, appointment }) => {
  return (
    <CheckinStepsStep
      step={step}
      addInfo={`updated: ${moment(appointment?.updated_at).format('MM/DD/YYYY')}`}
    />
  );
};

export default CheckinStepsPersonalInfo;
