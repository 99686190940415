export const INVOICE_SWITCH_ITEMS = [
  {
    label: 'Internal',
    value: 'internal',
    navigate: '/portal/beyond-billing/electronic-invoicing'
  },
  {
    label: 'External',
    value: 'external',
    navigate: '/portal/beyond-billing/electronic-invoicing/external'
  }
];
