import { showAlert } from 'components/shared/Alert/Alert';
import { getInitials, ia } from '../../../../../../lib/helpers/utility';

export const updateDocSignatures = async ({
  updateDocumentSignMutation,
  documentSignatures,
  userState
}) => {
  const currentUser = documentSignatures?.find((row) => row.user_id == userState.id);

  if (!currentUser?.id) {
    showAlert({
      title: "You're not assigned to sign this document!",
      color: 'danger'
    });
    return;
  }

  const result = await updateDocumentSignMutation.mutateAsync({
    documentId: currentUser?.document_id,
    currentUser: userState?.id,
    overrideStatus: 'ready'
  });

  return result?.data?.updated;
};

export const everyoneSigned = ({ documentSignatures, userState }) =>
  documentSignatures.every((row) => {
    if (row.user_id == (userState?.id ?? userState?.user_id)) return true;

    return row.status === 'signed';
  });

export const checkUserLevel = (userLevel, providerUser) => {
  if (userLevel === 1 && ia(providerUser)) {
    return 'Provider';
  } else if (userLevel === 1 && !ia(providerUser)) {
    return 'Patient';
  } else if (userLevel >= 2 && ia(providerUser)) {
    return 'Supervisor Provider';
  }
};

export const processData = (response) => {
  if (!ia(response.data)) return [];

  return response.data
    .map((row) => {
      if (row.status !== 'signed') return null;
      return {
        role: checkUserLevel(row.level, row.user?.practiceRole),
        signature: row?.signature,
        signedBy: `Signed by ${row.user?.fullName}`,
        initials: getInitials(row.user?.fullName),
        date: row?.updated_at
      };
    })
    .filter(Boolean);
};

export const manuallyAddDocumentSign = async ({
  clinicalNote = {},
  manuallySelectedProviders = [],
  selectedProviders = [],
  enhancedCloseModal = () => null,
  pathname = null,
  addDocumentSignProvidersMutation = () => null,
  practice = {}
}) => {
  const providersToDelete = manuallySelectedProviders?.filter(
    (provider) => !selectedProviders.includes(provider)
  );

  const providersToCreate = selectedProviders?.filter(
    (provider) => !manuallySelectedProviders.includes(provider)
  );

  if (!ia(providersToCreate) && !ia(providersToDelete)) {
    enhancedCloseModal();
    return;
  }

  try {
    const practiceId = practice?.id ?? clinicalNote?.practice_id;

    const documentSignsToCreate = {
      documentId: clinicalNote?.id,
      documentType: 'clinical_note',
      practiceId,
      userIds: providersToCreate,
      withoutDynamicLevels: true,
      link: pathname,
      meta: { add_manual_provider: true }
    };

    const documentSignsToDelete = {
      documentId: clinicalNote?.id,
      documentType: 'clinical_note',
      userIds: providersToDelete,
      practiceId
    };

    await addDocumentSignProvidersMutation.mutateAsync({
      documentSignsToCreate,
      documentSignsToDelete,
      practiceId
    });
  } catch (error) {
    console.log({ error });
  }
};
