import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { currentPractice } from 'components/practice/practiceState';
import AddProceduresProducts from './AddProceduresProducts';
import Tabs from 'components/shared/Tabs/NewTabs';

const ProceduresProducts = ({
  services = [],
  products = [],
  packages = [],
  formik: formikProp
}) => {
  const { formik: formikContext } = useNewAppointmentContext() || {};
  const practice = useRecoilValue(currentPractice);
  const { packages: packagesPermission = false, products: productsPermission = false } =
    practice.display_settings;
  const formik = formikProp || formikContext;
  const { values } = formik || {};
  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = [
    {
      label: 'Services',
      length: values?.procedures?.length,
      permissions: ['services.read', 'packages.read'],
      content: (
        <AddProceduresProducts
          type="procedures"
          label="Services"
          list={services}
          packageList={packagesPermission ? packages : []}
          formik={formik}
        />
      ),
      hasPermission: true
    },
    {
      label: 'Products',
      length: values?.products?.length,
      permissions: ['products.read'],
      content: (
        <AddProceduresProducts type="products" label="Products" list={products} formik={formik} />
      ),
      hasPermission: productsPermission
    }
  ];

  return (
    <Tabs
      tabsData={tabsData}
      theme="secondary"
      tabIndex={tabIndex}
      setTabIndex={setTabIndex}
      hasContent
      className="!pl-4"
      panelsClassName="z-[60]"
    />
  );
};

export default ProceduresProducts;
