import { ia, search } from 'lib/helpers/utility';
import moment from 'moment';
import getDiagnosesWhenNoClaim from './getDiagnosesWhenNoClaim';

export default function getClaimInfoFromSuperbill({
  claim,
  superbill,
  facilityProvider,
  billingProvider,
  referringProvider,
  states
}) {
  return {
    ...getDiagnosesWhenNoClaim(superbill),
    payerid: superbill.patient.insurance ? superbill.patient.insurance?.payer_id : '',
    payer_name: superbill.patient.insurance ? superbill.patientInsurance?.name : '',
    payer_addr_1: null,
    payer_addr_2: null,
    payer_city: null,
    payer_state: null,
    payer_zip: null,
    payer_order: null,
    payer_office_number: null,
    pcn: superbill.patient.id,
    pat_name_l: superbill.patient.l_name,
    pat_name_f: superbill.patient.f_name,
    pat_name_m: superbill.patient.m_name,
    pat_addr_1: superbill.patient?.userAddress?.address_ln_1,
    pat_addr_2: superbill.patient?.userAddress?.address_ln_2,
    pat_city: superbill.patient?.userAddress?.city,
    pat_state: search(states, superbill.patient?.userAddress?.state, 'id'),
    pat_zip: superbill.patient?.userAddress?.zip,
    pat_phone: superbill.patient.phone,
    pat_dob: moment(superbill.patient.dob).format('YYYY-MM-DD'),
    pat_sex: !superbill.patient.gender ? 'other' : superbill.patient.gender,
    pat_rel:
      claim?.pat_rel || ia(superbill.insuranceProfile)
        ? superbill.insuranceProfile[0].relation
        : null,
    pat_marital: null,
    pat_employment: null,
    ins_number: superbill.patient.insurance && superbill.patient.insurance?.member_id,
    ins_name_l: superbill.patient.l_name,
    ins_name_f: superbill.patient.f_name,
    ins_name_m: superbill.patient.m_name,
    ins_addr_1: superbill.patient?.userAddress?.address_ln_1,
    ins_addr_2: superbill.patient?.userAddress?.address_ln_2,
    ins_city: superbill.patient?.userAddress?.city,
    ins_state: search(states, superbill.patient?.userAddress?.state, 'id'),
    ins_zip: superbill.patient?.userAddress?.zip,
    ins_country: null,
    ins_phone: superbill.patient.phone,
    ins_group: null,
    ins_plan: null,
    ins_dob: null,
    ins_sex: null,
    ins_employer: null,
    other_ins_name_l: null,
    other_ins_name_f: null,
    other_ins_name_m: null,
    other_ins_number: null,
    other_payer_name: null,
    other_claimfilingcode: null,
    other_payerid: null,
    other_payer_addr_1: null,
    other_payer_addr_2: null,
    other_payer_city: null,
    other_payer_state: null,
    other_payer_zip: null,
    other_ins_dob: null,
    other_ins_sex: null,
    other_pat_rel: null,
    other_ins_payment_date: null,
    other_ins_medicare_code: null,
    employment_related: null,
    auto_accident: null,
    auto_accident_state: null,
    other_accident: null,
    ref_name_l: referringProvider?.l_name || null,
    ref_name_f: referringProvider?.f_name || null,
    ref_name_m: referringProvider?.m_name || null,
    ref_id: null,
    ref_npi: referringProvider?.npi || null,
    cond_date: referringProvider?.cond_date || null,
    onset_date: null,
    lastseen_date: null,
    nowork_date: null,
    nowork_from_date: null,
    nowork_to_date: null,
    hosp_from_date: null,
    hosp_thru_date: null,
    chiro_manifest_date: null,
    info_release: null,
    special_identifier: null,
    prior_auth: null,
    clia_number: null,
    referral_number: null,
    clinical_trial_number: null,
    accept_assign: 'Y',
    facility_name: facilityProvider?.facility_name,
    facility_addr_1: facilityProvider?.facility_addr_1,
    facility_addr_2: facilityProvider?.facility_addr_2,
    facility_city: facilityProvider?.facility_city,
    facility_state: search(states, facilityProvider?.facility_state, 'id'),
    facility_zip: facilityProvider?.facility_zip,
    facility_npi: facilityProvider?.facility_npi,
    facility_id: facilityProvider?.facility_id,
    bill_name: billingProvider?.bill_name,
    bill_addr_1: billingProvider?.bill_addr_1,
    bill_addr_2: billingProvider?.bill_addr_2,
    bill_city: billingProvider?.bill_city,
    bill_state: search(states, billingProvider?.bill_state, 'id'),
    bill_zip: billingProvider?.bill_zip,
    bill_npi: billingProvider?.bill_npi,
    bill_id: billingProvider?.bill_id,
    bill_phone: billingProvider?.bill_phone,
    bill_taxid: billingProvider?.bill_taxid,
    bill_taxid_type: billingProvider?.bill_taxid_type,
    bill_taxonomy: billingProvider?.bill_taxonomy,
    prov_name_l: superbill.practitioner.l_name,
    prov_name_m: superbill.practitioner.m_name,
    prov_name_f: superbill.practitioner.f_name,
    prov_npi:
      superbill.practitioner.practitionerClaimMdInfo &&
      superbill.practitioner.practitionerClaimMdInfo.npi,
    prov_id:
      superbill.practitioner.practitionerClaimMdInfo &&
      superbill.practitioner.practitionerClaimMdInfo.atypical_id,
    prov_taxid:
      superbill.practitioner.practitionerClaimMdInfo &&
      (superbill.practitioner.practitionerClaimMdInfo.tax_id || superbill.practice.tax_id),
    prov_taxid_type:
      superbill.practitioner.practitionerClaimMdInfo &&
      (superbill.practitioner.practitionerClaimMdInfo.tax_id_type ||
        superbill.practice.tax_id_type),
    prov_taxonomy:
      superbill.practitioner.practitionerClaimMdInfo &&
      (superbill.practitioner.practitionerClaimMdInfo.taxonomy || superbill.practice.taxonomy),
    ord_name_l: null,
    ord_name_f: null,
    ord_name_m: null,
    ord_npi: null,
    remote_fileid: null,
    remote_batchid: null,
    remote_claimid: null,
    chiro_condition: null,
    chiro_xray_available: null,
    chiro_xray_date: null,
    initial_treatment_date: null,
    narrative: null,
    homebound: null,
    mrn: null,
    cond_code_1: null,
    cond_code_2: null,
    cond_code_3: null,
    icn_dcn_1: null,
    total_non_covered: null,
    amb_purpose_of_rt: null,
    amb_purpose_of_str: null,
    ambulance: null,
    amb_patient_weight: null,
    amb_to_for: null,
    amb_stretcher: null,
    amb_bed_confined_before: null,
    amb_bed_confined_after: null,
    amb_shock: null,
    amb_emergency: null,
    amb_vis_hemor: null,
    amb_patient_admitted: null,
    amb_miles: null,
    pickup_addr_1: null,
    pickup_addr_2: null,
    pickup_city: null,
    pickup_state: null,
    pickup_zip: null,
    dropoff_addr_1: null,
    dropoff_addr_2: null,
    dropoff_city: null,
    dropoff_state: null,
    dropoff_zip: null,
    pay_name: null,
    pay_addr_1: null,
    pay_addr_2: null,
    pay_city: null,
    pay_state: null,
    pay_zip: null,
    total_charge: 0,
    amount_paid: 0,
    procedures: [],
    appointment_id: superbill?.encounter?.appointment?.id
  };
}
