import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { requestApi } from 'api/Api';
import { appointmentTypes } from 'constants';
import { useNavigate } from 'react-router-dom';
import Select from 'components/shared/Select/Select';

const AppointmentTypes = ({ practice, setPractice }) => {
  const [inputValue, setInputValue] = useState('');
  const [types, setTypes] = useState(practice?.appointment_types);
  const navigate = useNavigate();

  const createOption = (label) => ({
    value: String(label).toLowerCase().replaceAll(' ', '_'),
    label
  });

  const handleKeyDown = (e) => {
    if (!inputValue) return;

    switch (e.key) {
      case 'Enter':
      case 'Tab':
        setTypes((prev) => [...prev, createOption(inputValue)]);
        setInputValue('');
    }
  };

  const updateTypes = async (newValue) => {
    setTypes(newValue);

    try {
      const data = await requestApi({
        url: '/api/practice/update',
        params: {
          fields: { appointment_types: JSON.stringify(newValue) }
        },
        navigate
      });

      if (data?.updatedItems > 0) {
        toast.success('Types was updated successfully');

        setPractice({ ...practice, appointment_types: newValue });
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  return (
    <div>
      <p className="!pb-2 text-base font-500">Appointment Types</p>
      <Select
        isCreatable
        isMulti
        isClearable={false}
        inputValue={inputValue}
        options={appointmentTypes}
        onChange={(newValue) => updateTypes(newValue)}
        onInputChange={(newValue) => setInputValue(newValue)}
        onKeyDown={handleKeyDown}
        value={types}
      />
      <p className="pt-1 text-xs tracking-wide text-neutral-500">
        Select an appointment type, or start typing to create a new one.
      </p>
    </div>
  );
};

export default AppointmentTypes;
