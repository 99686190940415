import React, { useState } from 'react';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import cs from 'classnames';
import { useAppointments } from 'lib/hooks/queries/widgets/useOverview';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import { Appointment } from 'components/practice/charts/Overview/widgets/Appointments';
import moment from 'moment';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import Tabs from 'components/shared/Tabs/NewTabs';

export default function SelectInstantAppointment({
  patientId,
  id,
  selectedAppointment,
  setSelectedAppointment,
  practice
}) {
  const { data: patientAppointments } = useAppointments({
    params: { patientId: patientId || id },
    dependencies: [patientId || id]
  });

  const [tabIndex, setTabIndex] = useState(0);

  const tabsData = (close) => [
    {
      label: 'Upcoming',
      length: patientAppointments?.upcoming?.length,
      permissions: ['appointments.read'],
      content: (
        <List
          renderEmptyComponent={() => (
            <div className="py-8 text-center text-neutral-600">No upcoming appointments</div>
          )}
          items={patientAppointments?.upcoming || []}
          renderItemComponent={(item, index) => (
            <Appointment
              showAppointment={() => {
                setSelectedAppointment(item);
                close();
              }}
              selected={selectedAppointment?.id === item.id}
              item={item}
              past={true}
              index={index}
            />
          )}></List>
      ),
      hasPermission: true
    },
    {
      label: 'Past',
      length: patientAppointments?.past?.length,
      permissions: 'appointments.read',
      content: (
        <List
          renderEmptyComponent={() => (
            <div className="py-8 text-center text-neutral-600">No past appointments</div>
          )}
          items={patientAppointments?.past || []}
          renderItemComponent={(item, index) => (
            <Appointment
              showAppointment={() => {
                setSelectedAppointment(item);
                close();
              }}
              selected={selectedAppointment?.id === item.id}
              item={item}
              past={true}
              index={index}
            />
          )}></List>
      ),
      hasPermission: true
    }
  ];

  return (
    <Popover className="flex justify-end">
      {({ close }) => (
        <>
          <PopoverButton data-qa="link-appointment-btn">
            <div className={cs('flex items-center gap-[6px]')}>
              <Icon icon={!!selectedAppointment ? 'calendar-reminder' : 'add-circle'} size={18} />
              <label className="cursor-pointer text-sm font-500 text-primary-500">
                {!!selectedAppointment
                  ? `${formatDate(selectedAppointment?.starts_at)} at
                                          ${moment.tz(selectedAppointment?.starts_at, practice?.timezone).format('h:mm A')}`
                  : 'Link to Appointment'}
              </label>
            </div>
          </PopoverButton>
          <PopoverPanel
            className={cs(
              'absolute right-4 top-12 z-[999] mt-[6px] flex  !w-[450px] flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]'
            )}>
            <div>
              <div className="flex items-center justify-between p-2 px-3 shadow-[0px_0px_16px_rgba(0,0,0,0.05)]">
                <label className="cursor-pointer text-sm font-500 text-primary-900">
                  Select Appointment
                </label>
                <Icon icon="new-chevron-down-sm" className="cursor-pointer" onClick={close} />
              </div>
              <div className="flex max-h-[500px] flex-col gap-2 overflow-y-auto">
                <Tabs
                  tabsData={tabsData(close)}
                  theme="secondary"
                  tabIndex={tabIndex}
                  setTabIndex={setTabIndex}
                  hasContent
                  className="!pl-4"
                  panelsClassName="z-[60]"
                />
              </div>
              <div className="flex items-center justify-between p-2 px-3 shadow-[0px_0px_16px_rgba(0,0,0,0.05)]">
                <Button
                  text={selectedAppointment ? 'Clear' : 'Close'}
                  size="small"
                  outlined
                  color="neutral"
                  onClick={() => {
                    close();
                    setSelectedAppointment(null);
                  }}
                />
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
}
