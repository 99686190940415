import React from 'react';
import cs from 'classnames';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { reShapePractitioners } from 'lib/helpers/utility';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import Section from '../../Section';
import Note from './components/Note';
import Time from './components/Time';
import Duration from './components/Duration';
import Resource from './components/Resource';
import Location from './components/Location';
import Progress from './components/Progress';
import Icon from 'components/shared/Icon/Icon';
import AppointmentDate from './components/Date';
import Practitioner from './components/Practitioner';
import Button from 'components/shared/Buttons/Button';
import { userState as user_state } from 'components/state';
import ProfilePicture from 'components/Profile/ProfilePicture';
import { currentPractice } from 'components/practice/practiceState';
import './Details.scss';
import NewTagSelect from 'components/shared/Tags/components/Select/NewSelect';

const Details = ({ appointment }) => {
  const navigate = useNavigate();
  const { values, setFieldValue } = useFormikContext();
  const { device } = useUIContext();
  const currPractice = useRecoilValue(currentPractice);
  const userState = useRecoilValue(user_state);
  const isMobile =
    device === 'mobile' || device === 'tablet-sm' || device === 'tablet' || device === 'tablet-lg';
  const isPined = isMobile || userState?.display_settings?.appointment?.details?.isPined;
  const isOpen = userState?.display_settings?.appointment?.details?.isOpen ?? false;
  const timezone = currPractice?.timezone;
  const { data: practitioners } = usePractitioners();
  const reshapedPractitioners = reShapePractitioners(practitioners?.practitioners);
  const currentTime = moment.tz(values.starts_at, currPractice?.timezone).format('hh:mm A');
  const isAptDisabled = values.appointment_disabled;

  const handleCN = () =>
    navigate(`/portal/charts/${appointment?.user_id}/clinical-notes/${appointment?.id}`);

  const HeaderChildren = (
    <div className="ml-auto flex gap-4">
      {!isOpen && (
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <ProfilePicture
              size={24}
              fontSize="text-sm"
              firstName={values.practitioner.f_name}
              lastName={values.practitioner.l_name}
              image={values.practitioner.header_photo}
              id={values.practitioner.id}
            />
            <span className="text-sm text-neutral-800">{values.practitioner.fullName}</span>
          </div>
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-calendar" size={20} />
            <span className="text-sm text-neutral-800">
              {moment(values.starts_at).format('MM/DD/YYYY, dddd')}
            </span>
          </div>
          <div className="flex items-center gap-[6px]">
            <Icon icon="new-clock-gray" color="primary" />
            <span className="text-sm text-neutral-800">{currentTime}</span>
          </div>
        </div>
      )}
      <Button
        text="Clinical Note"
        icon="new-start-note-v2"
        onClick={handleCN}
        size="small"
        disabled={isAptDisabled}
      />
    </div>
  );
  return (
    <Section
      title="Appointment Details"
      headerChildren={HeaderChildren}
      target="details"
      className={isPined && isOpen ? 'pb-3 pt-2' : '!p-0'}
      isMobile={isMobile}>
      <div className={cs('details relative', isPined && 'details--pinned')}>
        {isAptDisabled && (
          <div className="pointer-events-none absolute inset-0 z-[100] bg-white opacity-40"></div>
        )}
        <Practitioner practitioners={reshapedPractitioners} className="details__practitioner" />
        <AppointmentDate timezone={timezone} className="details__date" />
        <div className={cs('details__tags flex flex-col gap-y-1')}>
          <p className="text-sm font-500">Appointment tags</p>
          <NewTagSelect
            currentTags={appointment?.tags}
            kind="appointment"
            setTagIds={(ids) => setFieldValue('tag_ids', ids)}
            disabled={isAptDisabled}
          />
        </div>
        <Note className="details__note" />
        <Time currentPractice={currPractice} className="details__time" />
        <Duration timezone={timezone} className="details__duration" />
        <Location
          practitioners={reshapedPractitioners}
          className="details__location"
          isPined={isPined}
        />
        <Resource className="details__resource" />
        <Progress className="details__progress" />
      </div>
    </Section>
  );
};

export default Details;
