const validator = require('validator');

const validateUpdate = (payload) => {
  const errors = {};
  let message = '';
  let isFormValid = true;

  if (!payload || typeof payload.f_name !== 'string' || payload.f_name.trim().length === 0) {
    isFormValid = false;
    errors.f_name = 'Please provide a first name.';
  }

  if (!payload || typeof payload.l_name !== 'string' || payload.l_name.trim().length === 0) {
    isFormValid = false;
    errors.l_name = 'Please provide a last name.';
  }

  if (!payload || typeof payload.email !== 'string' || !validator.isEmail(payload.email)) {
    isFormValid = false;
    errors.email = 'Please provide a correct email address.';
  }

  if (!isFormValid) {
    message = 'Check the form for errors.';
  }

  return {
    success: isFormValid,
    message,
    errors
  };
};

module.exports = {
  validateUpdate: validateUpdate
};
