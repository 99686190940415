import React from 'react';
import cs from 'classnames';
import Icon from '../Icon/Icon';
import Loader from '../Loader/Loader';
import './Button.scss';

const Button = ({
  text,
  name,
  id,
  type = 'primary',
  color = 'primary',
  shade = '500',
  size = 'medium',
  outlined = false,
  transparent = false,
  icon = false,
  iconColor = null,
  iconSize,
  iconRight = false,
  iconRightClick,
  iconRightSize,
  iconRightClassName,
  iconIsStroke = false,
  iconRightIsStroke = false,
  loading = false,
  loadingIcon = false,
  loadingIconRight = false,
  disabled = false,
  active = false,
  onClick,
  buttonType = 'button',
  forwardedRef,
  className,
  textClassName,
  ...rest
}) => {
  return (
    <button
      data-dd-privacy="allow"
      className={cs(
        'relative flex cursor-pointer items-center justify-center text-sm outline-none',
        type === 'primary' && !outlined && !transparent && `button-${type}`,
        type === 'primary' &&
          `!rounded-md border border-solid border-transparent text-white bg-${color}-${text ? shade : 50}`,
        type === 'secondary' &&
          `rounded-full border border-solid border-${color}-200 bg-${color}-50 text-${color}-500 hover:text-${color}-600 hover:border-${color}-400 disabled:border-${color}-100 disabled:text-${color}-300`,
        type === 'primary' &&
          outlined &&
          `bg-white text-${color}-500 hover:text-${color}-600 border !border-solid border-${color}-200`,
        type === 'secondary' && outlined && 'bg-white',
        {
          'px-4 py-[8px]': size === 'medium',
          'px-[14px] py-[4px]': size === 'small',
          'px-4 py-[12px]': size === 'large',
          '!cursor-not-allowed opacity-60': disabled,
          [`button-transparent border-transparent bg-transparent text-${color}-500 hover:text-${color}-600 !pl-2 !pr-2`]:
            transparent,
          [`border-${color}-700 text-${color}-700`]: type !== 'primary' && active
        },
        !text && '!px-[6px] !py-[6px]',
        className
      )}
      disabled={disabled}
      onClick={loading || loadingIcon ? () => {} : onClick}
      data-qa={`btn-${name}`}
      type={buttonType}
      ref={forwardedRef}
      name={name}
      id={id}
      {...rest}>
      {loading && (
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          <Loader
            color={color}
            shade={shade}
            type={type}
            outlined={outlined}
            transparent={transparent}
          />
        </div>
      )}

      {loadingIcon ? (
        <Loader
          color={color}
          shade={shade}
          type={type}
          className="mr-[6px]"
          outlined={outlined}
          transparent={transparent}
        />
      ) : typeof icon === 'object' ? (
        <div className="mr-1">{icon}</div>
      ) : (
        icon && (
          <Icon
            icon={icon}
            shade={shade}
            stroke={iconIsStroke}
            size={iconSize}
            disabled={disabled}
            color={
              type !== 'primary'
                ? color
                : outlined
                  ? color
                  : transparent
                    ? color
                    : !text
                      ? color
                      : iconColor === 'white'
                        ? 'white'
                        : iconColor === 'black'
                          ? 'black'
                          : ''
            }
            className={cs(
              'cursor-pointer',
              text && 'mr-1',
              loading && 'opacity-0',
              active && type !== 'primary' && `Icon--${color}-700`
            )}
          />
        )
      )}
      {text && (
        <span
          className={cs(
            'font-500 leading-5 first-letter:capitalize',
            textClassName,
            loading && 'opacity-0'
          )}>
          {text}
        </span>
      )}
      {loadingIconRight ? (
        <Loader
          color={color}
          shade={shade}
          type={type}
          className="ml-[6px]"
          outlined={outlined}
          transparent={transparent}
        />
      ) : (
        iconRight && (
          <Icon
            icon={iconRight}
            color={
              type !== 'primary'
                ? color
                : outlined
                  ? color
                  : transparent
                    ? color
                    : !text
                      ? color
                      : iconColor === 'white'
                        ? 'white'
                        : iconColor === 'black'
                          ? 'black'
                          : ''
            }
            shade={shade}
            disabled={disabled}
            stroke={iconRightIsStroke}
            size={iconRightSize}
            className={cs(
              'cursor-pointer',
              text && 'ml-1',
              loading && 'opacity-0',
              active && type !== 'primary' && `Icon--${color}-700`,
              iconRightClassName
            )}
            onClick={iconRightClick}
          />
        )
      )}
    </button>
  );
};

export default Button;
