import axios from 'axios';
import cs from 'classnames';
import Loader from 'components/shared/Loader/Loader';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { ia } from '../../../../../../lib/helpers/utility';
import Icon from '../../../../Icon/Icon';
import Input from '../../../../Input/Input';

const SelectMedicationTable = ({
  handleSelectMedication = () => {},
  medication,
  tableClassName = null,
  required = false,
  errorInput = null,
  disabled = false,
  id
}) => {
  const [medications, setMedications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [filters, setFilters] = useState({
    search: '',
    limit: 6
  });

  useEffect(() => {
    if (!show) return;
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: medication }));
    }, 500);

    return () => clearTimeout(timer);
  }, [medication]);

  useEffect(() => {
    if (!show) return;
    if (filters.search) {
      handleGetMedications();
    } else {
      setError(null);
      setMedications([]);
    }
  }, [filters.search, filters.limit]);

  const handleGetMedications = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `https://api.fda.gov/drug/ndc.json?search=generic_name:*${filters.search}*&limit=${filters.limit}`
      );

      setMedications(data.results);
      setIsLoading(false);
    } catch (error) {
      setMedications([]);
      setIsLoading(false);
      setError(error.response.data.error.message);
    }
  };

  const selectMed = (selectedMedication) => {
    handleSelectMedication(selectedMedication);
    // setMedications([]);
    setFilters({ ...filters, search: '' });
    setShow(false);
  };

  const changeHandler = (value) => {
    setShow(true);
    handleSelectMedication({ generic_name: value });
    setFilters({ ...filters, search: value, page: 1 });
  };

  const onCloseTable = (removeFilters = true) => {
    setShow(false);
    setMedications([]);
    removeFilters && setFilters((prevState) => ({ ...prevState, search: '' }));
  };

  return (
    <div className=" flex flex-col gap-6">
      <div className="flex w-full items-center gap-2 ">
        <Input
          label="Medication"
          data-qa="searchMedications"
          type="string"
          placeholder="Start typing to search..."
          value={medication}
          id={id}
          // icon="new-search"
          className="w-full !rounded-2xl !border-secondary-400"
          onChange={(e) => changeHandler(e.target.value)}
          rightText={
            isLoading ? (
              <Loader outlined size="small" />
            ) : !show && medication !== null ? (
              <Icon icon="new-chevron-down" onClick={() => changeHandler(medication)} />
            ) : null
          }
          disabled={disabled}
          required={required}
          error={errorInput}
        />
      </div>

      {show ? (
        <div
          className={cs('absolute left-0 right-0 top-28 z-[999] overflow-hidden', tableClassName)}>
          <div className="shadow-[0px_1px_2px_0px_rgba(16, 24, 40, 0.05)] flex max-h-[407px] w-full flex-col rounded-md border border-solid border-neutral-200">
            {/* Added flex-grow and moved overflow here */}
            <div className="flex-grow overflow-y-auto rounded-md">
              <table className="primary-table w-full border-spacing-0">
                <thead className="!border-x-0 !border-t-0 border-b border-solid border-neutral-200">
                  <tr className="h-[44px]">
                    <th className="w-[1.5rem]"></th>
                    <th className="my-auto w-[60%]">
                      <p className="!mb-0 text-xs font-600 text-neutral-800">Drug Name</p>
                    </th>
                    <th className=" my-auto ">
                      <p className="!mb-0 text-xs font-600 text-neutral-800">Dosage Form</p>
                    </th>
                    <th className=" my-auto ">
                      <p className="!mb-0 text-xs font-600 text-neutral-800">Route</p>
                    </th>
                    <th className="!ml-0 w-0 !pl-0 !pr-2 ">
                      <Icon icon="new-close-square" onClick={onCloseTable} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {ia(medications) ? (
                    medications.map((medication, index) => (
                      <tr
                        key={medication?.product_id}
                        className={cs(
                          '!h-0 w-full cursor-pointer',
                          !(index === medications?.length - 1) &&
                            '!border-x-0 !border-t-0 border-b border-solid border-neutral-100'
                        )}
                        onClick={() => selectMed(medication)}>
                        <td className="my-auto !py-2">
                          <Icon icon="new-add-square" onClick={() => selectMed(medication)} />
                        </td>
                        <td className="!py-2">
                          {capitalize(medication.generic_name || medication.brand_name)}
                        </td>
                        <td className="!py-2">{capitalize(medication.dosage_form)}</td>
                        <td className="!py-2">
                          {medication.route?.map((r, index) => {
                            r = capitalize(r);
                            return index === medication.route.length - 1 ? r : r + '/';
                          })}
                        </td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        {isLoading ? (
                          <span className="flex justify-center">
                            <Loader outlined />
                          </span>
                        ) : (
                          error
                        )}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="sticky bottom-0 w-full rounded-md bg-white">
              <div
                onClick={() => onCloseTable(false)}
                className="flex cursor-pointer items-center gap-4 rounded-b-md !border-x-0 !border-b-0 border-t border-solid border-neutral-200 bg-white !px-2 !py-2 hover:bg-primary-50">
                <Icon icon="new-add-square" onClick={() => onCloseTable(false)} />

                <p className="!mb-0 text-[0.875rem] text-primary-900">
                  Add '{capitalize(medication)}'
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SelectMedicationTable;
