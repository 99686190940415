import React from 'react';
import Tippy from '@tippyjs/react';
import { mString } from 'lib/helpers/utility';

const PaymentAmount = ({ amountPaidSelf, surchargeAmount }) => {
  return (
    <div className="flex items-center justify-end !gap-1">
      <Tippy
        className="tippy-dark"
        content={
          <>
            <p className="text-xs">Charge: {mString(amountPaidSelf - (surchargeAmount || 0))}</p>
            {surchargeAmount > 0 && (
              <p className="text-xs">Technology fee: {mString(surchargeAmount)}</p>
            )}
          </>
        }>
        <div className="flex">{mString(amountPaidSelf)}</div>
      </Tippy>
    </div>
  );
};

export default PaymentAmount;
