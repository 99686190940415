import React from 'react';
import { useRecoilValue } from 'recoil';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { defaultFilters, resourceTableCols } from './lib';
import ResourcesTable from './components/ResourcesTable';

export default function Superbills() {
  return (
    <TableContextProvider
      cols={resourceTableCols}
      pagination
      name="resources"
      defaultFilters={defaultFilters}>
      <ResourcesTable />
    </TableContextProvider>
  );
}
