import { handleICD10Search } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';
import { trackUserClickForOldUI } from 'components/practice/charts/ClinicalNote/shared/ICD10Code/lib/userTrackSearchClick';
import Select from 'components/shared/Select/Select';
import { iaRa } from 'lib/helpers/utility';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Diagnosis = ({ formik = {} }) => {
  const navigate = useNavigate();

  const onDiagnosisChange = async (response) => {
    const currentICDValueArray = iaRa(formik.values?.diags).map((item) => item?.value);
    const valuesOnly = iaRa(response).map((row) => row?.value);

    formik.setValues({
      ...formik.values,
      diags: response,
      diags_ids: valuesOnly,
      diag: response,
      diag_ids: valuesOnly
    });

    await trackUserClickForOldUI({
      navigate,
      currentICDValueArray,
      newICDArray: valuesOnly
    });
  };

  return (
    <Select
      inputId="select-icd-codes"
      label="ICD-10/Diagnosis"
      placeholder="Type and select ICD-10/Diagnosis"
      name="icd_10_codes"
      id="icd_10_codes"
      isAsync
      isMulti
      isClearable
      value={formik?.values?.diags}
      loadOptions={handleICD10Search}
      onChange={onDiagnosisChange}
      menuPlacement="top"
      components={{
        IndicatorSeparator: () => null
      }}
    />
  );
};

export default Diagnosis;
