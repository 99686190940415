import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate } from 'react-router-dom';
import Axios from '../../../../../configuredAxios';
import { growthOptionChart, growthOptionGender } from '../../../../../constants';
import Filters from '../../../../admin/pages/Databank/GrowthData/Filters';
import GrowthPatientBlock from './GrowthPatientBlock';
import {
  chartOptions,
  convertData,
  defaultGender,
  findClosestPercentile,
  formatChart,
  typeOfUnits,
  vitalChart
} from '../lib/growthChartHelpers';
import { io } from '../../../../../lib/helpers/utility';
import cs from 'classnames';

const GrowthChart = ({ isModal = false, patient = null, vitals = null }) => {
  const navigate = useNavigate();
  const [growthData, setGrowthData] = useState([]);
  const [filters, setFilters] = useState({
    chartType: ['weight_for_age'],
    gender: defaultGender({ gender: patient.gender }),
    applyFilters: false
  });
  const [patientDetails, setPatientDetails] = useState({});
  const [measurementType, setMeasurementType] = useState();
  const [chartType, setChartType] = useState();

  const currentUnits = typeOfUnits({
    unit: filters?.chartType[0]
  });

  useEffect(() => {
    getGrowthData();
  }, [filters?.page, filters?.searchTerm, filters?.applyFilters, vitals]);

  const getGrowthData = async () => {
    try {
      let { data } = await Axios.post('/api/admin/growth_data/get', {
        ...filters
      });

      const { code, redirect, growthData } = data;

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setMeasurementType(growthData[0]?.measurement_type?.toUpperCase());
          setChartType(growthData[0]?.chart_type?.split('_')?.[0]?.toUpperCase());

          const convertedDataToImperial = convertData(growthData, filters.chartType[0]);

          if (io(vitals)) {
            const vitalCharts = vitalChart({ chartType: filters?.chartType[0], vitals, patient });
            setPatientDetails(
              findClosestPercentile({ ...vitalCharts, data: convertedDataToImperial })
            );
          }

          setGrowthData(convertedDataToImperial);
          break;
        default:
          Honeybadger.notify(
            `file: GrowthChart, method: getGrowthData - try, error: 'An unexpected error has occurred.`
          );
          break;
      }
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: GrowthChart, method: getGrowthData - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const chartData = {
    datasets: formatChart({ growthData, patientDetails })
  };

  const options = chartOptions({
    measurementType,
    chartType,
    patient,
    currentUnits,
    minMaxValues: growthData.map((g) => g.measurement_value),
    vitals
  });

  return (
    <div>
      {isModal && (
        <div className={'flex !gap-3'}>
          <div className={'rounded-md bg-primary-50 !p-2 shadow-sm'}>
            <Filters
              filters={filters}
              setFilters={setFilters}
              type="chartType"
              filterOptions={growthOptionChart}
              singleSelect={true}
            />
          </div>

          <div className={'rounded-md bg-primary-50 !p-2 shadow-sm'}>
            <Filters
              filters={filters}
              setFilters={setFilters}
              type="gender"
              filterOptions={growthOptionGender}
            />
          </div>
        </div>
      )}
      <div
        className={cs(
          isModal &&
            '!mt-3 flex w-[800px] items-center justify-center self-center rounded-md bg-primary-50 !p-2 shadow-sm'
        )}>
        <Line className="w-full" data={chartData} options={options} />
      </div>
      {isModal && io(patientDetails) && (
        <GrowthPatientBlock
          fullName={patient.fullName}
          measurementType={measurementType}
          chartType={chartType}
          currentUnits={currentUnits}
          patientDetails={patientDetails}
          vitals={vitals}
        />
      )}
    </div>
  );
};

export default GrowthChart;
