export const scrollToSelectedCPT = ({ selectedListRef = null, icdValue = null }) => {
  if (!selectedListRef || !icdValue) return;

  setTimeout(() => {
    const scrollToRef = selectedListRef?.current?.[icdValue];

    scrollToRef?.scrollIntoView({ behavior: 'smooth' });

    const classNames = ['!bg-primary-100', '!border', '!border-solid', '!border-primary-200'];

    classNames.forEach((cls) => {
      scrollToRef?.classList?.add(cls);
    });

    setTimeout(() => {
      classNames.forEach((cls) => {
        scrollToRef?.classList?.remove(cls);
      });
    }, 1500);
  }, 0);
};
