import React from 'react';
import Input from '../../../../../../shared/Input/Input';
import Icon from '../../../../../../shared/Icon/Icon';

const MacrosSearch = ({ filters, setFilters }) => {
  return (
    <div className="!mt-2">
      <Input
        id="macro-search"
        data-qa="searchMacro"
        type="text"
        placeholder="Search macros..."
        autoComplete="off"
        icon="new-search"
        inputClassName="!min-w-full"
        value={filters?.searchTerm}
        onChange={(e) => setFilters((prevState) => ({ ...prevState, searchTerm: e.target.value }))}
        rightText={
          filters?.searchTerm && (
            <Icon
              icon="new-close"
              onClick={() => setFilters((prevState) => ({ ...prevState, searchTerm: '' }))}
            />
          )
        }
      />
    </div>
  );
};

export default MacrosSearch;
