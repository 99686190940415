import React, { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';
import Button from 'components/shared/Buttons/Button';
import Textarea from 'components/shared/Textarea/Textarea';
import { appointmentLocations } from 'constants';
import { requestApi } from 'api/Api';

const AppointmentLocation = ({ practice, setPractice }) => {
  const navigate = useNavigate();

  const instructionsRef = useRef();

  const { default_location, virtual_link, instructions } =
    practice?.display_settings?.appointment || {};

  const [location, setLocation] = useState(default_location);
  const [virtualOptions, setVirtualOptions] = useState({
    virtual_link: virtual_link || null,
    instructions: instructions || null
  });

  const updateLocation = async (e) => {
    try {
      setLocation(e.value);

      const display_settings = {
        ...practice?.display_settings,
        appointment: {
          ...practice?.display_settings?.appointment,
          default_location: e.value
        }
      };

      const data = await requestApi({
        url: '/api/practice/update',
        params: { fields: { display_settings } },
        navigate
      });

      if (data?.updatedItems > 0) {
        toast.success('Location was updated successfully');

        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  const updateVirtualOptions = async (e) => {
    try {
      const display_settings = {
        ...practice?.display_settings,
        appointment: {
          ...practice?.display_settings?.appointment,
          ...virtualOptions
        }
      };

      const data = await requestApi({
        url: '/api/practice/update',
        params: { fields: { display_settings } },
        navigate
      });

      if (data?.updatedItems > 0) {
        toast.success('Virtual options updated successfully');

        setPractice({ ...practice, display_settings });
      }
    } catch (error) {
      console.error(error);
      toast.error(`An unexpected error has occurred. Please try again later.`);
    }
  };

  return (
    <>
      <div>
        <p className="!pb-2 text-base font-500">Select Default Location</p>
        <Select
          label="Location"
          placeholder="Type and select"
          options={appointmentLocations}
          onChange={(value) => updateLocation(value)}
          value={appointmentLocations.find(
            (l) => l?.value === practice?.display_settings?.appointment?.default_location
          )}
        />
      </div>

      <div className="!pt-4">
        <p className="!pb-2 text-base font-500">Virtual Options</p>
        <div className="flex flex-col !gap-4">
          <Input
            type="text"
            label="Link"
            name="virtual_link"
            className="w-full"
            data-qa="practice-virtual-link"
            value={virtualOptions?.virtual_link}
            onChange={(e) => setVirtualOptions({ ...virtualOptions, virtual_link: e.target.value })}
          />
          <Textarea
            isEditor
            id="instructions"
            label="Instructions"
            name="virtual_instructions"
            textareaClassName="w-full"
            forwardedRef={instructionsRef}
            className="min-h-[200px] w-full"
            value={virtualOptions?.instructions}
            placeholder="Write instructions here"
            data-qa="practice-virtual-instructions"
            onChange={(e) => setVirtualOptions({ ...virtualOptions, instructions: e })}
          />
          <Button onClick={updateVirtualOptions} data-qa="save-btn" className="w-fit" text="Save" />
        </div>
      </div>
    </>
  );
};

export default AppointmentLocation;
