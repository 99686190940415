export default function initialValues(id) {
  return {
    payerid: '',
    payer_name: '',
    payer_addr_1: '',
    payer_addr_2: '',
    payer_city: '',
    payer_state: '',
    payer_zip: '',
    payer_order: '',
    payer_office_number: '',
    pcn: id,
    pat_name_l: '',
    pat_name_f: '',
    pat_name_m: '',
    pat_addr_1: '',
    pat_addr_2: '',
    pat_city: '',
    pat_state: '',
    pat_zip: '',
    pat_country: '',
    pat_phone: '',
    pat_dob: '',
    pat_sex: '',
    pat_rel: null,
    pat_marital: '',
    pat_employment: '',
    ins_number: '',
    ins_name_l: '',
    ins_name_f: '',
    ins_name_m: '',
    ins_addr_1: '',
    ins_addr_2: '',
    ins_city: '',
    ins_state: '',
    ins_zip: '',
    ins_country: '',
    ins_phone: '',
    ins_group: '',
    ins_plan: '',
    ins_dob: '',
    ins_sex: '',
    ins_employer: '',
    other_ins_name_l: '',
    other_ins_name_f: '',
    other_ins_name_m: '',
    other_ins_number: '',
    other_payer_name: '',
    other_claimfilingcode: '',
    other_payerid: '',
    other_payer_addr_1: '',
    other_payer_addr_2: '',
    other_payer_city: '',
    other_payer_state: '',
    other_payer_zip: '',
    other_ins_dob: '',
    other_ins_sex: '',
    other_pat_rel: '',
    other_ins_payment_date: '',
    other_ins_medicare_code: '',
    employment_related: '',
    auto_accident: '',
    auto_accident_state: '',
    other_accident: '',
    ref_name_l: '',
    ref_name_f: '',
    ref_name_m: '',
    ref_id: '',
    ref_npi: '',
    cond_date: '',
    onset_date: '',
    lastseen_date: '',
    nowork_date: '',
    nowork_from_date: '',
    nowork_to_date: '',
    hosp_from_date: '',
    hosp_thru_date: '',
    chiro_manifest_date: '',
    info_release: '',
    special_identifier: '',
    prior_auth: '',
    clia_number: '',
    referral_number: '',
    clinical_trial_number: '',
    accept_assign: '',
    total_charge: '',
    amount_paid: '',
    diag_1: '',
    diag_2: '',
    diag_3: '',
    diag_4: '',
    diag_5: '',
    diag_6: '',
    diag_7: '',
    diag_8: '',
    diag_9: '',
    diag_10: '',
    diag_11: '',
    diag_12: '',
    facility_name: '',
    facility_addr_1: '',
    facility_addr_2: '',
    facility_city: '',
    facility_state: '',
    facility_zip: '',
    facility_npi: '',
    facility_id: '',
    bill_name: '',
    bill_addr_1: '',
    bill_addr_2: '',
    bill_city: '',
    bill_state: '',
    bill_zip: '',
    bill_npi: '',
    bill_id: '',
    bill_phone: '',
    bill_taxid: '',
    bill_taxid_type: '',
    bill_taxonomy: '',
    prov_name_l: '',
    prov_name_f: '',
    prov_name_m: '',
    prov_npi: '',
    prov_id: '',
    prov_taxonomy: '',
    prov_taxid: '',
    prov_taxid_type: '',
    ord_name_l: '',
    ord_name_f: '',
    ord_name_m: '',
    ord_npi: '',
    remote_fileid: '',
    remote_batchid: '',
    remote_claimid: '',
    chiro_condition: '',
    chiro_xray_available: '',
    chiro_xray_date: '',
    initial_treatment_date: '',
    narrative: '',
    homebound: '',
    mrn: '',
    cond_code_1: '',
    cond_code_2: '',
    cond_code_3: '',
    icn_dcn_1: '',
    total_non_covered: '',
    amb_purpose_of_rt: '',
    amb_purpose_of_str: '',
    ambulance: '',
    amb_patient_weight: '',
    amb_to_for: '',
    amb_stretcher: '',
    amb_bed_confined_before: '',
    amb_bed_confined_after: '',
    amb_shock: '',
    amb_emergency: '',
    amb_vis_hemor: '',
    amb_patient_admitted: '',
    amb_miles: '',
    pickup_addr_1: '',
    pickup_addr_2: '',
    pickup_city: '',
    pickup_state: '',
    pickup_zip: '',
    dropoff_addr_1: '',
    dropoff_addr_2: '',
    dropoff_city: '',
    dropoff_state: '',
    dropoff_zip: '',
    pay_name: '',
    pay_addr_1: '',
    pay_addr_2: '',
    pay_city: '',
    pay_state: '',
    pay_zip: '',
    custom_id: ''
  };
}
