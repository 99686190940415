import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPatientsAdmin } from '../../../../api/Patients';

export const usePatientsAdmin = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['patientsAdmin', ...dependencies], () => getPatientsAdmin(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
