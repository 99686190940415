import { useNavigate } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import usePageTitle from 'lib/hooks/usePageTitle';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import OrdersTable from 'components/shared/Forms/Custom/Orders/Orders';
import { permissions } from 'components/state';

const Orders = () => {
  usePageTitle('Orders');

  const navigate = useNavigate();

  const { is_admin } = useRecoilValue(permissions);

  if (!is_admin) {
    navigate(-1);
  }

  return <OrdersTable showHeader showFooter pagination />;
};

export default withErrorBoundary(Orders);
