import React from 'react';
import EmptyTableState from '../../EmptyTableComponent';

const EmptyPastNotes = () => {
  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="no-data-found" />
      <EmptyTableState.Text
        title="No Past Notes Found!!"
        description="You will see the list of notes once you have at least one clinical note created."
      />
    </EmptyTableState>
  );
};

export default EmptyPastNotes;
