import React from 'react';

import { useEraContext } from 'lib/context/EraContext/EraContext';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const ManualPayment = ({ data, node }) => {
  const { selectedUnmatchedClaim, setProcedures } = useEraContext();

  if (data?.isClaimRow) return data.total_insurance_payment;
  if (data?.isStatusRow) return null;

  const isSelected = selectedUnmatchedClaim === data?.claimId;
  const isFirstRow = data?.procedureIndex === 0;

  const onValueChange = (value) => {
    setProcedures((prev) => {
      const updated = [...prev];
      updated[data.procedureIndex] = {
        ...updated[data.procedureIndex],
        paid: value
      };

      node.setData({
        ...data,
        total_insurance_payment: value
      });
      return updated;
    });
  };

  return (
    <div className={`flex flex-col gap-1${!isFirstRow ? ' mt-1' : ''}`}>
      {isSelected && (
        <CurrencyInput
          label={isFirstRow && 'Paid'}
          value={data.total_insurance_payment}
          onValueChange={() => {}}
          onBlur={onValueChange}
        />
      )}
    </div>
  );
};

export default ManualPayment;
