import React from 'react';
import HPIRevisions from '../../../../../../../shared/Forms/Custom/HPIRevisions/HPIRevisions';
import Accordion from '../../../../shared/Accordion';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const HPIRevisionsSection = ({ components, sectionRef, options = [], withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{ name: 'HPI', parentKey: 'hp', childKey: 'hpi' }}
      title={components?.hpi?.title}
      id={components?.hpi?.id}
      disabled={clinicalNote?.locked}>
      <HPIRevisions
        isClinicalNote
        defaultValue={clinicalNote?.hpi}
        sectionRef={sectionRef}
        showNarrative
        options={options}
      />
    </Accordion>
  );
};

export default HPIRevisionsSection;
