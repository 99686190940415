import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import { isEmpty } from 'lib/helpers/utility';
import React from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import AddCosigners from '../AddCosigners';

const SignPadAndLockPad = ({
  clearSignature,
  sigPad,
  signature,
  updateSignature,
  setUpdateSignature,
  signPadClassName = null,
  documentSignaturesData = [],
  showAddCoSigners = false
}) => {
  return (
    <div className={cs('mt-3', signPadClassName)}>
      <div className="grid grid-cols-[65%_35%] gap-2 md:grid-cols-1">
        <div>
          {isEmpty(signature) || !signature?.includes('base64') ? (
            <ReactSignatureCanvas
              penColor="black"
              canvasProps={{
                className: 'SignatureCanvas !w-full !h-[200px]'
              }}
              ref={sigPad}
            />
          ) : (
            <img
              src={signature}
              className="h-[200px] !w-full rounded-[10px] border-2 border-dashed border-neutral-100 bg-[#f8fdff] object-contain"
              data-qa="user-signature"
            />
          )}
        </div>

        <div className="flex flex-col gap-3">
          {showAddCoSigners && <AddCosigners documentSignatures={documentSignaturesData} />}
          <Button
            text="Clear"
            iconColor="primary"
            icon="new-reset"
            className="w-full"
            outlined
            iconIsStroke={true}
            onClick={clearSignature}
          />
          {!signature && (
            <Checkbox
              id="update-signature-on-file"
              label="Update Signature On File"
              color="blue"
              className="!m-0 !p-0"
              inputClassName="!border !border-solid !border-primary-200 !m-0 !p-0"
              innerLabelClassName="!m-0 !p-0"
              isChecked={updateSignature}
              onChange={(v) => setUpdateSignature(v.target.checked)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignPadAndLockPad;
