import React, { Fragment } from 'react';

import moment from 'moment';

import { useEraContext } from 'lib/context/EraContext/EraContext';
import { formatDate, getKeysByType, ia, iaRa, mString } from 'lib/helpers/utility';

import RadioButton from 'components//shared/RadioButton/RadioButton';
import {
  displayDiagnosis,
  handleAdjustmentCodes,
  sumAdjustment
} from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA&EOBHelpers';
import { filterSuperbillClaimProcedure } from 'components/practice/BeyondBilling/lib/procedureUtils';

import AllocateAdjustment from './EOB/components/AllocateAdjustment';

export default function AssignUnmatchedClaim() {
  const {
    unmatchedClaims,
    selectedClaim,
    selectedUnmatchedClaim,
    setSelectedUnmatchedClaim,
    codes,
    procedures,
    setProcedures
  } = useEraContext();

  const handleClaimChange = (event) => {
    const claimId = parseInt(event.target.value);
    setSelectedUnmatchedClaim(claimId);

    const claim = unmatchedClaims.find((c) => c.id === claimId);
    setProcedures(claim?.invoice?.procedures || []);
  };

  return (
    <div className="overflow-x-scroll h-full">
      <div className="!mb-4 flex flex-wrap gap-9">
        <div className="flex flex-col gap-[2px]">
          <p className="text-sm font-500 text-primary-800">Patient</p>
          <p className="text-xs text-neutral-800">
            {selectedClaim?.claim?.user
              ? selectedClaim?.claim?.user?.fullName
              : `User ID: ${selectedClaim?.claim?.pcn}`}
          </p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <p className="text-sm font-500 text-primary-800">Date of service</p>
          <p className="text-xs text-neutral-800">{formatDate(selectedClaim?.claim?.from_dos)}</p>
        </div>

        <div className="flex flex-col gap-[2px]">
          <p className="text-sm font-500 text-primary-800">Billed</p>
          <p className="text-xs text-neutral-800">${selectedClaim?.claim?.total_charge}</p>
        </div>
      </div>

      <table className="primary-table">
        <thead>
          <tr>
            <th>Procedure</th>
            <th>Quantity/Units</th>
            <th>Modifiers</th>
            <th>Charge</th>
          </tr>
        </thead>
        <tbody>
          {ia(selectedClaim?.claim?.charge) &&
            selectedClaim?.claim.charge.map((charge, index) => {
              return (
                <tr key={index}>
                  <td>{charge.proc_code}</td>
                  <td>{charge.units}</td>
                  <td>
                    {[charge.mod1, charge.mod2, charge.mod3, charge.mod4]
                      .filter((f) => f !== null)
                      .join(', ')}
                  </td>
                  <td>{mString(charge?.charge * 100)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <p className="!my-4 text-lg">Claims</p>
      <table className="primary-table">
        <thead>
          <tr>
            <th></th>
            <th>Posted/DoS</th>
            <th>Patient/Service</th>
            <th>Provider</th>
            <th>Diagnosis</th>
            <th>Billed</th>
            <th>Adjustment</th>
            <th>Pt. Paid</th>
            <th>Note</th>
          </tr>
        </thead>

        <tbody>
          {ia(unmatchedClaims) &&
            unmatchedClaims.map((claim, i) => {
              const trClassName =
                claim.id === selectedClaim
                  ? '!border border-solid border-primary-500 !bg-primary-50 font-600'
                  : '';

              return (
                <Fragment key={i}>
                  <tr className={trClassName}>
                    <th className="relative left-3 top-3 flex h-7 items-center ">
                      <RadioButton
                        id={claim.id}
                        value={claim.id}
                        isSelected={claim.id === selectedUnmatchedClaim}
                        onChange={handleClaimChange}
                      />
                    </th>

                    <th>
                      {moment(claim?.appointment?.starts_at).format('MM/DD/YYYY hh:mm a') ||
                        moment(claim.created_at).format('MM/DD/YYYY hh:mm a')}
                    </th>
                    <th>{claim?.superbill?.patient?.fullName}</th>
                    <th>{claim?.superbill?.practitioner?.fullName}</th>
                    <th>{displayDiagnosis(claim)}</th>
                    <th>${claim?.total_charge}</th>
                    <th>
                      {mString(
                        sumAdjustment(
                          claim.id === selectedUnmatchedClaim ? procedures : claim.procedures
                        )
                      )}
                    </th>
                    <th>{mString(claim?.invoice?.amount_paid)}</th>
                    <th></th>
                  </tr>

                  {/* {ia(claim.procedures) &&
                    claim.procedures.map((procedure, index) => {
                      return (
                        <tr key={index} className={trClassName}>
                          <td></td>
                          <td>{formatDate(procedure.from_date)}</td>
                          <td>{procedure.code}</td>
                          <td>
                            Qty: {procedure.units} Mods: {procedure.modifiers}
                          </td>
                          <td></td>
                          <td>{mString(procedure.charge * 100)}</td>
                          <td colSpan={2}></td>
                        </tr>
                      );
                    })} */}

                  {iaRa(
                    claim.id === selectedUnmatchedClaim ? procedures : claim?.invoice?.procedures
                  )
                    .filter((p) => filterSuperbillClaimProcedure(p))
                    .map((procedure, index) => {
                      return (
                        <tr key={index} className={trClassName}>
                          <td></td>
                          <td>{formatDate(procedure.from_date)}</td>
                          <td>{procedure.procedure_code}</td>
                          <td>
                            Qty: {procedure.units} Mods: {procedure.modifiers}
                          </td>
                          <td></td>
                          <td>{mString(procedure.charge)}</td>
                          <td className="!py-1">
                            {claim.id === selectedUnmatchedClaim ? (
                              <AllocateAdjustment
                                index={index}
                                setProcedures={setProcedures}
                                procedureCode={procedure?.code}
                                adjustments={procedure?.adjustment}
                                codeList={getKeysByType(codes, 'number')}
                                groupList={getKeysByType(codes, 'letter')}
                              />
                            ) : (
                              handleAdjustmentCodes(procedure?.adjustment, codes)
                            )}
                          </td>{' '}
                          <td colSpan={2}></td>
                        </tr>
                      );
                    })}
                </Fragment>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}
