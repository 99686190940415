module.exports = {
  VERADIGM_USER_TYPES: [
    { value: 'Provider', label: 'Doctor or Physician' },
    { value: 'PA', label: 'Midlevel Without Supervision' },
    { value: 'PASupervised', label: 'Midlevel with Supervision Required' },
    { value: 'POBNoReview', label: 'Prescribe on Behalf of (No Review Required)' },
    { value: 'POBSomeReview', label: 'Prescribe on Behalf of (Some Review Required)' },
    { value: 'POBAllReview', label: 'Prescribe on Behalf of (All Review Required)' },
    { value: 'StaffUser', label: 'Staff' }
  ]
};
