import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import checkinBackgroundPNG from '../../assets/Images/checkin-background.png';
import Icon from '../shared/Icon/Icon';
import Advertisement from '../user/Activate/Advertisement';
import './PasswordReset.scss';

const PasswordReset = () => {
  return (
    <div className="h-[100vh] overflow-hidden bg-transparent p-[1.2rem] sm:p-0">
      <img
        src={checkinBackgroundPNG}
        className="absolute left-0 top-0 z-10 h-full w-full object-cover"
      />
      <div className="relative z-20 flex h-full overflow-hidden rounded-xl border-[0.75px] border-solid border-white bg-neutral-50 sm:rounded-none">
        <div
          className="flex h-full w-2/5 flex-col justify-between overflow-y-scroll bg-neutral-50 !p-4 !pt-[1.5rem] sm:w-screen md:w-screen"
          data-qa="test">
          <Icon icon="logo" size={200} />
          <div>
            <Outlet />
          </div>
          <div className="flex flex-col items-center justify-center">
            <Link to="/Login" className="mb-4">
              To Login
            </Link>
            <Link to="/terms" data-qa="tos-and-pp">
              Terms of Service and Privacy Policy
            </Link>
          </div>
        </div>
        <Advertisement wrapperClassName="!bg-transparent z-20 sm:hidden md:w-1/2 w-3/5" />
      </div>
    </div>
  );
};

export default PasswordReset;
