import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../api/InterimApi';
import Medication from './medication';

export default function Medications() {
  const [activeMedications, setActiveMedications] = useState([]);
  const [inactiveMedications, setInactiveMedications] = useState([]);
  const [refetchData, setRefetchData] = useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    getData();
  }, [refetchData]);

  const getData = async () => {
    try {
      let res = await interimApi('/api/patient/medications/read', {}, navigate);
      const {
        activeMedications: loadedActiveMedications,
        inactiveMedications: loadedInactiveMedications
      } = res.data;
      if (res.data.activeMedications || res.data.inactiveMedications) {
        setActiveMedications(loadedActiveMedications);
        setInactiveMedications(loadedInactiveMedications);
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const removeMedication = async (medicationId, patientId) => {
    try {
      let params = {
        medicationId: medicationId,
        patientId: patientId
      };
      let res = await interimApi('/api/patient/medications/update', params, navigate);
      if (res.data.medication) {
        setRefetchData(!refetchData);
        toast.success('Medication was removed successfully');
      } else {
        toast.error('Failed removing medication');
      }
    } catch (err) {
      console.error(' ', err);
    }
  };

  const handleRemove = (medicationId, patientId) => {
    removeMedication(medicationId, patientId);
  };

  const handleRefill = (id) => { };

  return (
    <div>
      <div className="flex-wrap -ml-3 -mr-3 -mt-3">
        <div className='block py-3 px-0'>
          <div className="p-12">
            {activeMedications.length > 0 && (
              <div>
                <h3>Active</h3>
                <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                  {activeMedications.map((medication, index) => {
                    return (
                      <div key={index} className="block p-3 w-1/2 mb-2">
                        <Medication
                          medication={medication}
                          handleRemove={handleRemove}
                          handleRefill={handleRefill}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {inactiveMedications.length > 0 && (
              <div>
                <h3>Inactive</h3>
                <div className="flex-wrap -ml-3 -mr-3 -mt-3">
                  {inactiveMedications.map((medication, index) => {
                    return (
                      <div key={index} className="block p-3 w-1/2 mb-2">
                        <Medication
                          medication={medication}
                          handleRemove={handleRemove}
                          handleRefill={handleRefill}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
