import React from 'react';
import moment from 'moment-timezone';
import { initials } from '../../../../lib/helpers/utility';
import cs from 'classnames';

export default function MessageReceiverBox({
  name,
  photo,
  gradientColor,
  message,
  timezone,
  form,
  shouldShowLabel
}) {
  let isTextMessage = message.medium === 'sms';
  return (
    <div className="!my-2">
      <div className="flex gap-1 text-sm text-neutral-900">
        {shouldShowLabel && (
          <>
            {name}
            {isTextMessage ? (
              <span className="text-primary-600">(Text Message)</span>
            ) : (
              <span className="text-neutral-600">(Chat Message)</span>
            )}
          </>
        )}
      </div>
      <div className="!my-2 flex w-full max-w-full space-x-3">
        <div className="rounded-smooth flex h-12 min-w-[48px] items-center justify-center overflow-hidden">
          {shouldShowLabel && (
            <>
              {photo ? (
                <img
                  className="h-12 w-12 object-cover"
                  src={`https://cdn.filestackcontent.com/${JSON.parse(photo).jpg}`}
                />
              ) : (
                <div
                  className="rounded-smooth flex h-12 min-w-full items-center justify-center overflow-hidden"
                  style={{ background: gradientColor }}>
                  <span className="text-2xl font-600 uppercase text-white">
                    {initials({ fullName: name })}
                  </span>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex flex-wrap gap-1">
          <div
            className={cs(
              'min-w-fit !px-4 !py-[15px] text-neutral-900',
              form,
              isTextMessage ? 'bg-primary-50' : 'bg-neutral-100'
            )}>
            <p className="text-sm">{message.body}</p>
          </div>
          <span className="min-w-fit self-center text-xs leading-none text-neutral-700">
            {moment.tz(message.created_at, timezone).format('hh:mm A')}
          </span>
        </div>
      </div>
    </div>
  );
}
