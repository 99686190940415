import { useRef, useState } from 'react';

import Tippy from '@tippyjs/react';
import { useRecoilValue } from 'recoil';

import { pm } from 'lib/helpers/utility';

import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import state from 'components/state';

export function ModalCreateFooter({
  loading,
  handleInvoiceModalClose = () => {},
  formik,
  invoiceIds
}) {
  const ref = useRef();
  const permissions = useRecoilValue(state.permissions);

  const [check, setCheck] = useState(true);

  const handleSubmit = () => {
    formik.handleSubmit();
  };

  const onCheckAEOB = ({ target }) => {
    invoiceIds.map((id) => {
      formik.setFieldValue(`${id}.aeob`, target.checked);
      setCheck(target.checked);
    });
  };
  return (
    <div className="flex w-full justify-between">
      <Button
        outlined
        text="Cancel"
        color="neutral"
        onClick={handleInvoiceModalClose}
        id="cancelInvoiceModalBtn"
        data-qa="cancel-btn"
      />
      <div ref={ref} className="flex items-center justify-center">
        <Tippy
          className="tippy-dark"
          placement="top"
          content={
            <div className="p-2">
              <div className="pb-2 text-sm">Include a full breakdown of the invoice.</div>
              <div className="pb-1 text-sm">Charge</div>
              <div className="pb-1 text-sm">Adjustment</div>
              <div className="pb-1 text-sm">Ins. Payment</div>
            </div>
          }>
          <div className="relative">
            <Checkbox
              name="details"
              data-qa="advanced-aeob-checkbox"
              className="cursor-pointer pr-5"
              isChecked={check}
              handleClick={onCheckAEOB}
              label="Advanced EOB"
            />
          </div>
        </Tippy>
        <Button
          onClick={handleSubmit}
          text="Create"
          id="savePackageModalBtn"
          data-qa="create-update-invoice-btn"
          loading={loading}
          disabled={!pm(permissions, 'invoicing.create')}
        />
      </div>
    </div>
  );
}
