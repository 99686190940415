import { Honeybadger } from '@honeybadger-io/react';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import ImagePicker from 'components/shared/Picker/ImagePicker';

import { detectDocument, processDocument } from 'api/Document';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import CheckinLoginWarning from './LoginWarning';
import { propertyPathMap } from './constants';
import { useQueryClient } from '@tanstack/react-query';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';

const PersonalID = () => {
  const { updateSteps, checkinButton, appointmentId, kiosk, user } = useOutletContext();;
  const navigate = useNavigate();
  const [imageEdit, setImageEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState({
    front: user?.personal_id_image && user?.personal_id_image?.front,
    back: user?.personal_id_image && user?.personal_id_image?.back
  });

  const queryClient = useQueryClient();

  const { device } = useUIContext();

  const breadcrumb = useBreadcrumb([
    {
      title: 'Front of ID',
      name: 'front'
    },
    {
      title: 'Back of ID',
      name: 'back'
    }
  ]);

  useEffect(() => {
    if (!!imageEdit && breadcrumb?.currentStep) {
      setImageEdit(breadcrumb.currentStep);
    }
  }, [breadcrumb?.currentStep]);

  const checkPhotoIdsExist = () => {
    user.personal_id_image?.back && user.personal_id_image?.front
      ? updateSteps({ formName: propertyPathMap.personalIdImageComplete.name() })
      : updateSteps({
          formName: propertyPathMap.personalIdImageComplete.name(),
          isCompleted: false
        });
  };

  const handleContinue = async (handle) => {
    setLoading(true);

    let res = await interimApi(
      `/api/filestack/personal_id_${imageEdit}`,
      {
        handle,
        userId: user.id,
        appointmentId
      },
      navigate
    );
    if (res.data[imageEdit] > 0) {
      queryClient.setQueryData(['checkinUser'], (oldData) => {
        if (!oldData) return oldData;
        return {
          ...oldData,
          user: {
            ...oldData.user,
            personal_id_image: handle
          }
        };
      });
    } else {
      Honeybadger.notify(`There was an error uploading the image.`);
      showAlert({
        message: 'Uploading image failed. Please try again',
        color: 'danger',
        position: device === 'laptop' || device === 'desktop' ? 'top-right' : 'bottom-right'
      });
    }
    if (breadcrumb.currentStep === 'front') {
      breadcrumb?.goToStep('back');
    } else {
      setImageEdit(null);
    }
    setLoading(false);
  };

  const handleDelete = async (type) => {
    await requestApi({
      url: `/api/filestack/personal_id_${type}`,
      params: {
        handle: { ...user?.personal_id_image, [type]: null },
        userId: user?.id
      },
      navigate
    });

    queryClient.setQueryData(['checkinUser'], (oldData) => {
      if (!oldData) return oldData;
      return {
        ...oldData,
        user: {
          ...oldData.user,
          personal_id_image: {
            ...oldData.user?.personal_id_image,
            [type]: null
          }
        }
      };
    });
  };

  const [coords, setCoords] = useState();
  const [processing, setProcessing] = useState({ open: false });

  const handleSave = async (handle, type) => {
    setLoading(true);

    let newHandle = {
      front: user?.personal_id_image && user?.personal_id_image?.front,
      back: user?.personal_id_image && user?.personal_id_image?.back
    };

    newHandle[type] = handle;

    setHandle(newHandle);

    const document = await detectDocument(navigate, {
      handle
    });

    setCoords({ ...coords, [type]: document?.coords });

    setLoading(false);
  };

  const handleProcess = async (image, type) => {
    const formData = new FormData();

    formData.append('image', image);
    formData.append('ocr', false);
    formData.append('handle', handle?.[type]);

    setProcessing({ open: true });

    const processedDocument = await processDocument(navigate, formData);

    setProcessing({ open: false });

    let newHandle = {
      front: user?.personal_id_image && user?.personal_id_image?.front,
      back: user?.personal_id_image && user?.personal_id_image?.back
    };

    newHandle[type] = processedDocument?.handle;

    handleContinue(newHandle);
    setHandle(newHandle);
  };

  return (
    <>
      <div className="relative rounded-lg !p-8">
        <label className="text-base font-600 text-neutral-800">Identification document</label>
        {!user?.authenticated && !user?.isOnboarding && <CheckinLoginWarning />}
        <p className="pt-1 text-sm text-neutral-700">
          Please provide a valid and clear copy of your identification document. Ensure that all
          personal information is visible and legible, including your full name, date of birth,
          photo, and any relevant identification numbers!
        </p>
        <div className="flex gap-4 !pt-4 sm:flex-col">
          <div className="mb-4 grid w-full grid-cols-2 gap-2 sm:grid-cols-1">
            <div>
              <p className="mb-2 text-sm font-500">Front of ID Card</p>
              <div
                onClick={() => {
                  setImageEdit('front');
                  breadcrumb?.goToStep('front');
                }}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {user?.personal_id_image?.front ? (
                  <img
                    className="aspect-[5/3] w-full  overflow-hidden rounded-xl object-cover"
                    src={`https://cdn.filestackcontent.com/${user?.personal_id_image?.front}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <p className="mb-2 text-sm font-500">Back of ID Card</p>
              <div
                onClick={() => {
                  setImageEdit('back');
                  breadcrumb?.goToStep('back');
                }}
                className="flex aspect-[5/3] cursor-pointer flex-col items-center justify-center rounded-[17px] border !border-dashed !border-primary-500 bg-primary-50 !p-1">
                {user?.personal_id_image?.back ? (
                  <img
                    className="aspect-[5/3] w-full overflow-hidden rounded-xl bg-black object-cover"
                    src={`https://cdn.filestackcontent.com/${user?.personal_id_image?.back}`}></img>
                ) : (
                  <>
                    <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-white">
                      <Icon icon="new-file-upload" />
                    </div>
                    <div className="mt-2 text-xs font-500 text-primary-800">
                      Click here to upload.
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div hidden ref={checkinButton} onClick={() => checkPhotoIdsExist()}>
          Continue
        </div>
      </div>
      <Modal
        handleOpen={!!imageEdit}
        customHeader={<Breadcrumb hasBackButton breadcrumb={breadcrumb} />}
        handleClose={() => setImageEdit(null)}
        className="h-full w-full !rounded-none bg-neutral-800"
        title="Add Image"
        isFooter={false}>
        <div className="my-4 flex flex-col items-center text-center">
          <p className="text-lg font-medium text-white">
            {user?.personal_id_image?.[imageEdit] && !loading
              ? 'Looks good?'
              : `Upload the ${imageEdit} of your ID`}
          </p>
          <p className="mt-2 px-16 text-white">
            {user?.personal_id_image?.[imageEdit] && !loading
              ? 'Make sure the picture is clear'
              : 'Place your card on a flat surface and position all 4 corners of the card clearly in the frame and take a picture.'}
          </p>

          <ImagePicker
            key={imageEdit}
            label={imageEdit === 'front' ? 'Front of card' : 'Back of card'}
            handle={handle?.[imageEdit]}
            handleDelete={handleDelete}
            name={imageEdit}
            kiosk={kiosk}
            coords={coords?.[imageEdit]}
            processing={processing?.open}
            loading={loading}
            resetHandle={() => setHandle({ ...handle, [imageEdit]: null })}
            handleContinue={async (image, skip) => {
              if (skip) {
                if (breadcrumb.currentStep === 'front') {
                  breadcrumb?.goToStep('back');
                } else {
                  setImageEdit(null);
                }
              } else {
                await handleProcess(image, imageEdit);
              }
            }}
            onCapture={handleSave}
          />
        </div>
      </Modal>
    </>
  );
};

export default withErrorBoundary(PersonalID);
