import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import { notificationIconType } from '../lib/helper';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { snakeToTitleCase } from 'lib/helpers/utility';

const Type = ({ data }) => {
  return (
    <div className="flex gap-2">
      <Icon color="primary" icon={notificationIconType(data?.type)} />
      <p> {snakeToTitleCase(data?.type)}</p>
    </div>
  );
};

export default withErrorBoundary(Type);
