import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const ReviewPendingChanges = ({
  type = 'all',
  claimIdsWithPendingChanges = [],
  showSubmitConfirmation = false,
  setShowSubmitConfirmation = () => {},
  handleContinue = () => {}
}) => {
  const navigate = useNavigate();

  const PendingClaimsTable = () => {
    return (
      <>
        <div className="max-h-[250px] overflow-y-scroll">
          <table className="w-full text-left">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2">Claim ID</th>
                <th className="px-4 py-2"></th>
              </tr>
            </thead>
            <tbody>
              {claimIdsWithPendingChanges?.map((row, index) => (
                <tr key={index} className="odd:bg-white even:bg-gray-50">
                  <td className="px-4 py-2">{row}</td>
                  <td className="px-4 py-2">
                    <Button
                      transparent
                      text="Review"
                      onClick={() => {
                        navigate(`${type}/${row}`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex flex-col gap-1">
          <p>
            {claimIdsWithPendingChanges.length > 1 ? 'These claims have' : 'This claim has'} updates
            from the clearing house that are not yet synced with your current{' '}
            {claimIdsWithPendingChanges.length > 1 ? 'claims' : 'claim'}.
          </p>
          <p>If you continue, your local changes will override those updates.</p>
          <p>Are you sure you want to continue?</p>
        </div>
      </>
    );
  };

  return (
    showSubmitConfirmation && (
      <Confirm
        primaryBtnTxt={
          claimIdsWithPendingChanges?.length > 1 ? 'Yes, submit claims' : 'Yes, submit claim'
        }
        title={claimIdsWithPendingChanges?.length > 1 ? 'Submit claims' : 'Submit claim'}
        icon="new-claim"
        message={<PendingClaimsTable />}
        handleOpen={showSubmitConfirmation}
        handleClose={() => setShowSubmitConfirmation(false)}
        handleContinue={handleContinue}
        customClassName="max-h-[500px]"
      />
    )
  );
};

export default withErrorBoundary(ReviewPendingChanges);
