import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { requestApi } from 'api/Api';
import { upsertLabSoftOrder } from 'api/LabSoftOrder';

import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';

const getTitle = (entity) => `${entity} data missing or incorrect`;
const getBtnTitle = (entity) => `Update ${entity} Data`;

const getMessage = (errorDetails, index) => {
  const message = ia(errorDetails?.[index]) ? (
    errorDetails[index].map((detail, index) => (
      <p key={`error-detail-${index}`}>{detail.message}</p>
    ))
  ) : (
    <p key={`error-detail-${index}`}>{errorDetails.message}</p>
  );

  return message;
};

const useSubmitOrder = ({ setModalData, patient, gridApi, onSave }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const generateAndPrintPDF = async (data) => {
    try {
      var blobURL = URL.createObjectURL(data);

      const iframe = document.createElement('iframe');
      document.body.appendChild(iframe);

      iframe.style.display = 'none';
      iframe.src = blobURL;
      iframe.onload = function () {
        setTimeout(function () {
          iframe.focus();
          iframe.contentWindow.print();
        }, 1);
      };
    } catch (error) {
      console.error('Error generating PDF:', error);
      showAlert({
        title: 'Something went wrong!',
        message: 'Could not generate order reports. Please contact support for help.',
        color: 'danger'
      });
    }
  };

  const addPayer = async (payerId, cb) => {
    const onSuccess = () => {
      showAlert({ title: 'Success!', message: 'Payer added successfully!', color: 'success' });
      queryClient.invalidateQueries(['getPayers']);
      queryClient.invalidateQueries(['payers']);
      cb && cb();
    };

    const onError = () => {
      showAlert({
        title: 'Something went wrong!',
        message: 'Could not add payer!',
        color: 'danger'
      });
    };

    requestApi({
      url: '/api/practice/settings/payers/create',
      params: { payerId },
      onSuccess,
      onError,
      navigate
    });
  };

  const onError = (error, code) => {
    let title = '';
    let btnTitle = '';
    let message = '';
    let clickHandler = () => {};

    if (code === 7) {
      switch (error.code) {
        // practitioner data error
        case 1:
          title = getTitle('Provider');
          message = error?.message;
          // btnTitle = getBtnTitle('Provider');
          // clickHandler = () => {
          //   setModalData({ view: 'provider', data: error.data });
          // };
          break;
        // insurance profile data error
        case 2:
          title = getTitle('Insurance Profile');

          if (ia(error.data)) {
            btnTitle = getBtnTitle('Insurance Profile');

            error.data.map((item, index) => {
              const message = getMessage(error?.details, index);
              if (!message) return;

              clickHandler = () => setModalData({ view: 'insuranceProfile', data: [item] });

              showAlert({
                title: `${title} - ${item.payer_name}`,
                message,
                color: 'danger',
                buttons: [{ text: btnTitle, onClick: clickHandler }]
              });
            });
            return;
          } else {
            clickHandler = () => setModalData({ view: 'insuranceProfile', data: [] });
            btnTitle = 'Create New Insurance Profile';
            message = error?.message;
            break;
          }
        // payer data error
        case 3:
          title = getTitle('Payer');
          if (ia(error.data)) {
            error.data.map((item, index) => {
              const message = getMessage(error?.details, index);
              if (!message) return;

              if (ia(item.practicePayers)) {
                clickHandler = () => setModalData({ view: 'payer', data: [item] });
                btnTitle = getBtnTitle('Payer');
              } else {
                clickHandler = () =>
                  addPayer(item.id, () => setModalData({ view: 'payer', data: [item] }));
                btnTitle = 'Add to payer list';
              }

              showAlert({
                title: `${title} - ${item.name}`,
                message,
                color: 'danger',
                buttons: [{ text: btnTitle, onClick: clickHandler }]
              });
            });
            return;
          } else {
            message = error?.message;
            break;
          }
        // patient data error
        case 4:
          title = getTitle('Patient');
          btnTitle = getBtnTitle('Patient');

          clickHandler = () => {
            window.open(`${window.location.origin}/portal/charts/${patient.id}/demographics`);
          };
          break;
      }
    } else {
      title = error;
    }

    const alertConfig = {
      title,
      message: ia(error?.details)
        ? error.details.map((detail, index) => (
            <p key={`error-detail-${index}`}>{detail.message}</p>
          ))
        : message,
      color: 'danger',
      buttons: [2, 4].includes(error?.code) ? [{ text: btnTitle, onClick: clickHandler }] : []
    };

    showAlert(alertConfig);
  };

  const onSuccess = async (data) => {
    await queryClient.invalidateQueries(['orders']);

    gridApi?.setGridOption('rowData', []);

    onSave();

    showAlert({
      title: 'Order Created',
      color: 'success'
    });

    generateAndPrintPDF(data);
  };

  const { mutateAsync: submit } = useMutation(async (data) =>
    upsertLabSoftOrder(navigate, { params: data, onError, onSuccess })
  );

  return { submit };
};

export default useSubmitOrder;
