import * as Yup from 'yup';

export default () =>
  Yup.object().shape({
    name: Yup.string().min(3).required('Product name is required.'),
    code: Yup.string().min(3).required('Code is required.'),
    amount_cents: Yup.number()
      .required('Price is required')
      .min(0, 'Price cannot be negative'),
    status: Yup.string().required('Status is required.'),
    cost_cents: Yup.number().when('amount_cents', (amount_cents, schema) => {
      return amount_cents
        ? schema
            .max(amount_cents, 'Cost cannot be greater than price.')
            .min(0, 'Cost cannot be negative.')
        : schema;
    }),
    local_tax_rate: Yup.number()
      .max(10000, 'Tax cannot be grater than 100%')
      .min(0, 'Tax cannot be negative.'),
    state_tax_rate: Yup.number()
      .max(10000, 'Tax cannot be grater than 100%')
      .min(0, 'Tax cannot be negative.'),
    expiration_date: Yup.date().when('status', {
      is: 'active',
      then: () =>
        Yup.date().min(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          'Expiration date should be in the future when the status is Active.'
        )
    }),
    quantity: Yup.number()
      .min(0, 'Quantity cannot be negative.')
      .test('quantity', 'Invalid quantity value.', function (value) {
        const status = this.resolve(Yup.ref('status'));
        if (status === 'active' && value <= 0) {
          return this.createError({
            message: 'Quantity should be greater than 0 when status is Active.',
            path: 'quantity'
          });
        }
        if (status === 'out of stock' && value !== 0) {
          return this.createError({
            message: 'Quantity should be 0 when status is Out of stock.',
            path: 'quantity'
          });
        }
        return true;
      })
      .required('Quantity is required.')
  });
