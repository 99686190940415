import React, { useImperativeHandle, useRef } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { useRecoilState, useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState, intelligenceAiState } from 'components/state';

const Test = ({ sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const testRef = useRef();

  useImperativeHandle(sectionRef, () => ({
    formData: { test: clinicalNote?.test }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };
  return (
    <Textarea
      isEditor
      label="Note"
      placeholder="Write test notes here"
      id="test"
      name="test"
      className="min-h-[200px]"
      value={clinicalNote?.test}
      onChange={(e) => handleChange(e, 'test')}
      transcribing
      forwardedRef={testRef}
      disabled={clinicalNote?.locked || intelligenceAi?.loading}
    />
  );
};

export default Test;
