import React, { useState } from 'react';
import AssessmentSection from '../../../../CurrentNote/TypeNotes/HPNote/components/AssessmentSection';
import CPTCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/CPTCodesSection';
import ICDCodesSection from '../../../../CurrentNote/TypeNotes/HPNote/components/ICDCodesSection';
import MedicationsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/MedicationsSection';
import PlanSection from '../../../../CurrentNote/TypeNotes/HPNote/components/PlanSection';
import VitalsSection from '../../../../CurrentNote/TypeNotes/HPNote/components/VitalsSection';
import OrdersSection from '../../../../Orders/OrdersSection';
import FormsAI from '../FormsAI';
import ComplaintsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ComplaintsSection';
import HPIRevisionsSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/HPIRevisionsSection';
import AllergiesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AllergiesSection';
import VaccinesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/VaccinesSection';
import WeightLossAdministrationSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/WeightLossAdministrationSection';
import SocialHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/SocialHistorySection';
import FamilyHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/FamilyHistorySection';
import MedicalHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/MedicalHistorySection';
import SurgicalHistorySection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/SurgicalHistorySection';
import ExamSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ExamSection';
import TestSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/TestSection';
import NotesSection from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/NotesSection';
import { assignAdvTitlesToComponent } from 'lib/context/MyScribeAI/lib/getEnabledComponents';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import AdvancedHP from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/AdvancedHP';
import ReviewOfSystems from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/HPNote/components/ReviewOfSystems';

const getFormsList = (advancedHP, cnDisplaySettings) => {
  const formsList = {
    complaints: (props) => <ComplaintsSection {...props} withStyle={false} />,
    hpi: (props) => <HPIRevisionsSection {...props} withStyle={false} />,
    ros: (props) => <ReviewOfSystems {...props} withStyle={false} />,
    allergies: (props) => (
      <div className="relative mb-5 flex h-[600px] flex-col">
        <AllergiesSection {...props} isResizable={false} />
      </div>
    ),
    medicationHistory: (props) => (
      <div className="relative flex h-[600px] flex-col">
        <MedicationsSection {...props} isResizable={false} />
      </div>
    ),
    vaccines: (props) => (
      <div className="relative flex h-[600px] flex-col">
        <VaccinesSection {...props} isResizable={false} />
      </div>
    ),
    vitals: (props) => <VitalsSection {...props} withStyle={false} />,
    weightLossAdministration: (props) => (
      <div className="relative flex h-[700px] flex-col">
        <WeightLossAdministrationSection {...props} isResizable={false} />
      </div>
    ),
    socialHistory: (props) => <SocialHistorySection {...props} withStyle={false} />,
    familyHistory: (props) => (
      <div className="relative flex h-[600px] flex-col">
        <FamilyHistorySection {...props} isResizable={false} />
      </div>
    ),
    medicalHistory: (props) => <MedicalHistorySection {...props} withStyle={false} />,
    surgicalHistory: (props) => (
      <div className="relative flex h-[500px] flex-col">
        <SurgicalHistorySection {...props} isResizable={false} />
      </div>
    ),
    exam: (props) => <ExamSection {...props} withStyle={false} />,
    test: (props) => <TestSection {...props} withStyle={false} />,
    assessment: (props) => <AssessmentSection {...props} withStyle={false} />,
    plan: (props) => <PlanSection {...props} withStyle={false} />,
    'cpt-codes': (props) => <CPTCodesSection {...props} withStyle={false} />,
    'diagnosis-codes': (props) => (
      <ICDCodesSection {...props} withStyle={false} withScroll={false} />
    ),
    notes: (props) => <NotesSection {...props} withStyle={false} />,
    orders: (props) => <OrdersSection {...props} withStyle={false} />,
    ...assignAdvTitlesToComponent(
      advancedHP,
      (props) => <AdvancedHP {...props} withStyle={false} />,
      cnDisplaySettings
    )
  };
  return formsList;
};

const HPFormsAI = ({ inputName, components }) => {
  const { advancedHP, cnDisplaySettings } = useClinicalNoteContext();

  const [finalFormsList] = useState(getFormsList(advancedHP, cnDisplaySettings));

  return <FormsAI formsList={finalFormsList} inputName={inputName} components={components} />;
};

export default HPFormsAI;
