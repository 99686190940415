import { ia } from 'lib/helpers/utility';

export const calculateAdjustmentPaid = ({
  procedureAdjustment = {},
  procedureCharge = 0,
  setPaidError = () => {}
}) => {
  if (!ia(procedureAdjustment?.adjustments)) return;
  const basePaid = Number(procedureAdjustment?.paid || 0);

  const totalPaid = procedureAdjustment.adjustments.reduce((acc, adjustment) => {
    const paid = Number(adjustment?.amount || 0);
    return acc + paid;
  }, basePaid);

  setPaidError(totalPaid > procedureCharge);
  return basePaid;
};
