import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useSuperbills } from 'lib/hooks/queries/superbill/useSuperbills';
import useModal from 'lib/hooks/useModal';

import { currentPractice } from 'components/practice/practiceState';
import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { defaultColDef, rowClassRules } from 'components/shared/AGTable/lib/helpers';
import Filter from 'components/shared/Filters/Filter';
import Header from 'components/shared/Header/Header';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import ErrorModal from './components/ErrorModal';
import HeaderButtons from './components/HeaderButtons';
import { defaultFilters, superbillTableColumns } from './lib';

export default function Superbills() {
  const { state = 'draft' } = useParams();
  const practice = useRecoilValue(currentPractice);
  const cols = superbillTableColumns(practice.timezone);
  return (
    <TableContextProvider
      name="practice_superbills"
      cols={cols}
      pagination
      defaultFilters={defaultFilters}>
      <SuperbillsContent key={state} />
    </TableContextProvider>
  );
}

function SuperbillsContent() {
  const [selectedSuperbills, setSelectedSuperbills] = useState([]);
  const [errors, setErrors] = useState(false);
  const { limit, page, setPage, sort, gridApi, filters, setFilters } = useTableContext();
  const { state = 'draft' } = useParams();

  const { isOpen, openModal, closeModal } = useModal();
  const [selectedSuperbill, setSelectedSuperbill] = useState(null);

  const { data, isLoading, isFetching } = useSuperbills({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      state
    },
    dependencies: [
      limit,
      page,
      sort,
      state,
      moment(filters.date?.values?.endDate).format('YYYY-MM-DD'),
      moment(filters.date?.values?.startDate).format('YYYY-MM-DD'),
      filters.billing_provider_searchTerm?.values,
      filters.patient_searchTerm?.values,
      filters.payer_searchTerm?.values,
      filters.provider_searchTerm?.values,
      filters.superbill_id?.values
    ]
  });

  const count = data?.count?.[state] || null;

  const superbills = useMemo(() => {
    return (data?.superbills || []).map((superbill) => ({
      ...superbill,
      error: errors[superbill.claim_id]
    }));
  }, [data, errors]);

  useEffect(() => {
    setFilters((prev) => ({ ...prev, state, page: 1 }));
    setSelectedSuperbills([]);
  }, [state, setFilters]);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      const { id, patient_id, error } = event?.data || {};
      setSelectedSuperbill(event?.data);
      if (error) {
        openModal();
        return;
      }
      const link = `/portal/charts/${patient_id}/billing/superbill/${id}`;
      window.open(link, '_blank');
    }
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data).map((d) => d.id);

      setSelectedSuperbills(selectedData);
    }
  };

  const onPageChange = useCallback(({ selected }) => setPage(selected + 1), [setPage]);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: superbills,
            count,
            page,
            limit
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: superbills,
            count,
            page,
            limit,
            onPageChange
          },
          align: 'right'
        }
      ]
    };
  }, [superbills, count, page, limit, onPageChange]);

  return (
    <>
      <Header title="Superbills" subtitle={state} showInfoInRecordings={true}>
        <div className="flex flex-wrap items-center gap-4" data-dd-privacy="allow">
          <HeaderButtons
            errors={errors}
            setErrors={setErrors}
            selectedSuperbills={selectedSuperbills}
            setSelectedSuperbills={setSelectedSuperbills}
          />
          <DisplayButton />
          <Filter
            category="practice_superbills"
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </Header>
      {isLoading || isFetching ? (
        <Skeleton count={filters?.limit} />
      ) : (
        <>
          <AGTable
            data={superbills}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            defaultColDef={defaultColDef}
            customClassName="ag-grid-interactive"
            onRowClicked={onRowClicked}
            onSelectionChanged={onSelectionChanged}
            rowClassRules={rowClassRules}
            statusBar={statusBar}
          />
        </>
      )}

      {isOpen && (
        <ErrorModal
          errors={errors}
          id={selectedSuperbill?.claim_id}
          selectedItem={selectedSuperbill}
          onClose={closeModal}
        />
      )}
    </>
  );
}
