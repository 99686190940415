import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import React, { useEffect } from 'react';

const SearchInput = ({
  inputValue,
  inputFieldRef,
  handleInputChange,
  clearSearch,
  setShowSearchModal,
  focusOnMount
}) => {
  const handleClose = () => {
    clearSearch();
    setShowSearchModal(false);
  };

  useEffect(() => {
    if (focusOnMount) {
      inputFieldRef.current?.focus();
    }"flex w-full items-center justify-between"
  }, [focusOnMount, inputFieldRef]);

  return (
    <div className="flex w-full justify-between">
      <Input
        id="search"
        type="text"
        forwardedRef={inputFieldRef}
        focusOnMount={true}
        placeholder="Search..."
        value={inputValue}
        icon="new-search"
        className="!mr-5 w-full p-4 pb-0 pl-6"
        inputWrapperClassName=" border-t-0 border-l-0 border-r-0 rounded-none !border-b-2 !border-neutral-400 focus-within:!outline-[0px]"
        onChange={(e) => handleInputChange(e.target.value)}
        rightText={
          inputValue && (
            <span
              onClick={() => clearSearch()}
              className="cursor-pointer text-xs hover:text-primary-900">
              Clear
            </span>
          )
        }
      />

      <Icon
        data-qa="new-close-square"
        icon="new-close"
        onClick={handleClose}
        className="m-5 mb-0 flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg border-transparent !outline-none hover:bg-neutral-100"
      />
    </div>
  );
};

export default SearchInput;
