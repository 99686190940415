import ResizableContainer from 'components/practice/charts/ClinicalNote/shared/VerticallyResizableDiv/ResizableContainer';
import { currentPractice } from 'components/practice/practiceState';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import { permissions } from 'components/state';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { iaRa, io } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import AddToTodaysNote from '../Provider/components/AddToTodaysNote';
import NarrativeForm from '../Provider/components/NarrativeForm';
import NoteHeader from '../Provider/components/NoteHeader';
import MedicationSelectionModal from './components/MedicationSelectionModal';
import MedicationsTable from './components/MedicationsTable';
import UpsertProviderMedication from './components/UpsertMedication';
import { FINAL_NOTE_OPTIONS } from './lib/constants';
import providerMedicationsTableCols from './lib/providerMedicationsTableCols';

const ProviderMedications = ({ options }) => {
  const { setSelectedCustomFormTypes, medications, setMedications } = useClinicalNoteContext();
  const [showMedicationModal, setShowMedicationModal] = useState(false);
  const [showMedicationSelectionModal, setShowMedicationSelectionModal] = useState(false);
  const [resize, setResize] = useState(250);
  const [gridApi, setGridApi] = useState(null);
  const { id: patientId } = useParams();
  const userPermissions = useRecoilValue(permissions);
  const practice = useRecoilValue(currentPractice);

  const { isLoading: isMedicationLoading } = useMedication({
    params: {
      patientId
    },
    dependencies: [patientId],
    options: {
      enabled: !!patientId,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications),
      onSuccess: (medicationData) => {
        setMedications(medicationData);
      }
    }
  });

  return (
    <TableContextProvider
      cols={() =>
        providerMedicationsTableCols({
          showMedicationModal,
          setShowMedicationModal,
          userPermissions,
          practice
        })
      }
      name="Medications"
      defaultFilters={[]}>
      <div className="h-full">
        <AddToTodaysNote
          type="medications"
          finalNoteOptions={FINAL_NOTE_OPTIONS}
          onChange={({ type, options }) =>
            setSelectedCustomFormTypes((prevState) => ({
              ...prevState,
              [type]: options
            }))
          }
        />

        <NoteHeader title="Medications">
          <DisplayButton />
          <Button
            text="Add Medication"
            icon="new-add-square"
            iconColor="white"
            size="small"
            onClick={() => setShowMedicationSelectionModal(true)}
          />
        </NoteHeader>

        {showMedicationSelectionModal && (
          <MedicationSelectionModal
            isOpen={showMedicationSelectionModal}
            handleClose={() => setShowMedicationSelectionModal(false)}
            onPastMedicationSelect={() => {
              setShowMedicationModal({ open: true });
              setShowMedicationSelectionModal(false);
            }}
          />
        )}

        {io(showMedicationModal) && showMedicationModal?.open && (
          <UpsertProviderMedication
            showModal={showMedicationModal}
            hideModal={() => setShowMedicationModal({ open: false })}
          />
        )}

        <ResizableContainer
          onResize={(height) => setResize(height)}
          topContent={<MedicationsTable data={medications} getGridApi={(api) => setGridApi(api)} />}
          bottomContent={
            <NarrativeForm
              key={`medications-narrative-${patientId}`}
              type="medications"
              loading={isMedicationLoading}
              gridApi={gridApi}
              resize={resize}
              items={medications}
              options={options}
            />
          }
          resizableDivClassNames={{
            contentClassName: '!mt-0 !pt-0'
          }}
        />
      </div>
    </TableContextProvider>
  );
};

export default ProviderMedications;
