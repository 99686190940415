import { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';

import DocumentModal from 'components/practice/PracticeIQueue/Orders/components/DocumentModal';
import OrdersModal from 'components/practice/charts/ClinicalNote/Orders/OrdersModal';
import DeleteOrderConfirm from 'components/practice/charts/ClinicalNote/Orders/components/DeleteOrderConfirm';
import useReferralOrderQueryParamHandler from 'components/practice/charts/ClinicalNote/Orders/components/ReferralOrderOpenByQueryParams';
import { currentPractice } from 'components/practice/practiceState';
import { permissions } from 'components/state';

import { getCols, getDefaultFilters } from '../lib/ordersHelper';

import OrdersTable from './OrdersTable';

const deleteModalDefaultProps = {
  id: null,
  type: null,
  open: false,
  loading: false
};

const Orders = (props) => {
  const [deleteModalProps, setDeleteModalProps] = useState(deleteModalDefaultProps);
  const [navigateToParams, setNavigateToParams] = useState(null);
  const [activeDocument, setActiveDocument] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const practice = useRecoilValue(currentPractice);
  const { is_admin } = useRecoilValue(permissions);

  const [searchParams, setSearchParams] = useSearchParams();

  useReferralOrderQueryParamHandler({ setNavigateToParams, handleOpenPreview });

  const handleOpenEdit = useCallback((data) => {
    setNavigateToParams({ data, component: 'create' });
    setModalOpen(true);
  }, []);

  const handleOpenPreview = useCallback((data) => {
    setNavigateToParams({ data });
    setModalOpen(true);
  }, []);

  const handleClosePreview = useCallback(() => {
    setModalOpen(false);
    if (searchParams.has('id')) {
      searchParams.delete('id');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleOpenDelete = useCallback(({ orderId = null, type = null }) => {
    setDeleteModalProps((prevState) => ({
      ...prevState,
      loading: false,
      open: true,
      type,
      id: orderId
    }));
  }, []);

  return (
    <TableContextProvider
      defaultFilters={getDefaultFilters(is_admin)}
      cols={getCols({
        timezone: practice.timezone,
        openEditModal: handleOpenEdit,
        openPreviewModal: handleOpenPreview,
        openDeleteModal: handleOpenDelete,
        setActiveDocument
      })}
      pagination
      name="orders_table">
      <OrdersTable
        appointmentId={props?.appointmentId}
        setOrders={props?.setOrders}
        showHeader={props?.showHeader}
        showFooter={props?.showFooter}
        pagination={props?.pagination}
        setActiveOrder={handleOpenPreview}
      />

      {!!modalOpen && (
        <OrdersModal
          modalOpen={modalOpen && !activeDocument}
          handleClose={handleClosePreview}
          navigateToParams={navigateToParams}
          setNavigateToParams={setNavigateToParams}
          openResultsModal={(item) =>
            setActiveDocument({ url: item.report_url, title: item.report_title })
          }
        />
      )}

      <DocumentModal
        title={activeDocument?.title}
        url={activeDocument?.url}
        open={!!activeDocument}
        closeHandler={() => {
          setActiveDocument(null);
        }}
      />

      <DeleteOrderConfirm
        deleteModalProps={deleteModalProps}
        setDeleteModalProps={setDeleteModalProps}
      />
    </TableContextProvider>
  );
};

export default Orders;
