const moment = require('moment-timezone');

const formatDate = (date) => {
  if (date instanceof Date && !isNaN(date)) {
    return moment.utc(moment().format('L') + ' ' + date);
  } else if (!isNaN(date)) {
    return moment.utc(
      moment().format('L') + ' ' + `${moment.utc(date * 3600 * 1000).format('HH:mm')}`
    );
  } else {
    return moment.utc(moment().format('L') + ' ' + date);
  }
};

exports.findOpenAndCloseClinicHours = (available_times) => {
  let earliestTime = moment('23:59', 'HH:mm');
  let latestTime = moment('00:00', 'HH:mm');
  for (const day of available_times) {
    let endsAtLocation =
      day?.location?.ends_at !== null ? formatDate(day?.location?.ends_at) : null;
    let startsAtLocation =
      day?.location?.starts_at !== null ? formatDate(day?.location?.starts_at) : null;

    if (startsAtLocation !== null) {
      let result = moment.min(moment.utc(startsAtLocation), moment.utc(earliestTime));
      earliestTime = result;
    }
    if (endsAtLocation !== null) {
      let result = moment.max(moment.utc(endsAtLocation), moment.utc(latestTime));
      latestTime = result;
    }
    let endsAtVirtual = day?.virtual?.ends_at !== null ? formatDate(day?.virtual?.ends_at) : null;
    let startsAtVirtual =
      day?.virtual?.starts_at !== null ? formatDate(day?.virtual?.starts_at) : null;

    if (startsAtVirtual !== null) {
      let result = moment.min(moment.utc(startsAtVirtual), moment.utc(earliestTime));
      earliestTime = result;
    }
    if (endsAtVirtual !== null) {
      let result = moment.max(moment.utc(endsAtVirtual), moment.utc(latestTime));
      latestTime = result;
    }
  }
  //we do this in case there's no available_times, just to not crash the calendar view
  if (
    earliestTime.isSame(moment('23:59', 'HH:mm')) &&
    latestTime.isSame(moment('00:00', 'HH:mm'))
  ) {
    return {
      startsAt: {
        hour: 8,
        minute: 0
      },
      endsAt: {
        hour: 17,
        minute: 0
      }
    };
  }
  return {
    startsAt: {
      hour: earliestTime.hour(),
      minute: earliestTime.minute()
    },
    endsAt: {
      hour: latestTime.hour(),
      minute: latestTime.minute()
    }
  };
};
