import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { interimApi } from 'api/InterimApi';

import { iaRa, io } from 'lib/helpers/utility';
import { useMedication } from 'lib/hooks/queries/medication/useMedication';
import { useVitals } from 'lib/hooks/queries/vitals/useVitals';

import { getOverview } from 'components/practice/charts/ClinicalNote/lib/getOverview';
import { getPatientHistory } from 'components/practice/charts/ClinicalNote/lib/getPatientHistory';
import { showAlert } from 'components/shared/Alert/Alert';

import {
  clinicalNote as clinicalNoteState,
  intelligenceAiState,
  socketState,
  userState
} from '../../../components/state';

import { ClinicalNoteContext } from './ClinicalNoteContext';

const SAVE_BUTTON_TEXT = 'Save Draft';

export const ClinicalNoteContextProvider = ({ children, outsideClinicalNoteContext = false }) => {
  const [tab, setTab] = useState(null);
  const socket = useRecoilValue(socketState);
  const [currentUser, setCurrentUser] = useRecoilState(userState);
  const setClinicalNote = useSetRecoilState(clinicalNoteState);
  const [staticClinicalNote, setStaticClinicalNote] = useState({});
  const [components, setComponents] = useState({});
  const [advancedHP, setAdvancedHP] = useState([]);
  const advancedHPRef = useRef();
  const advancedFormNarrativeRef = useRef();
  const advancedSOAPRef = useRef();
  const advancedFormRef = useRef();
  const aggregatedDataRef = useRef();
  const [advancedHPElRefs, setAdvancedHPElRefs] = useState([]);
  const [advancedSOAP, setAdvancedSOAP] = useState([]);
  const [customFormTypes, setCustomFormTypes] = useState([]);
  const [advancedSOAPElRefs, setAdvancedSOAPElRefs] = useState([]);
  const [serviceCPT, setServiceCPT] = useState();
  const [loading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState({});
  const [currentHpOverviewData, setCurrentHpOverviewData] = useState({});
  const [vitals, setVitals] = useState([]);
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState({});
  const [cnError, setCnError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [cnDisplaySettings, setCNDisplaySettings] = useState(
    currentUser?.display_settings.clinicalNote
  );
  const [cnDisplaySettingsLoading, setCNDisplaySettingsLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [showPrevNoteModal, setShowPrevNoteModal] = useState(false);
  const [previewNoteModal, setPreviewNoteModal] = useState(false);
  const [isExport, setIsExport] = useState(true);
  const [patientResponseSidebar, setPatientResponseSidebar] = useState(false);
  const navigate = useNavigate();
  const [confirmSignLock, showConfirmSignLock] = useState(false);
  const saveNoteRef = useRef(null);
  const vitalsRef = useRef();
  const weightLossRef = useRef();
  const [saveButtonText, setSaveButtonText] = useState(SAVE_BUTTON_TEXT);
  const [forceSave, setForceSave] = useState({
    advancedForm: false
  });
  const [documentSignInfo, setDocumentSignInfo] = useState({});
  const [appointment, setAppointment] = useState(null);
  const [selectedCustomFormTypes, setSelectedCustomFormTypes] = useState({});
  const [medications, setMedications] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const [aiState, setAiState] = useRecoilState(intelligenceAiState);
  const { id: patientId, appointmentId, type: noteKind, formName: noteName } = useParams();
  const { patient } = useOutletContext() || {};
  const [currentHpOverviewLoading, setCurrentHpOverviewLoading] = useState(false);
  const [patientHistoryLoading, setPatientHistoryLoading] = useState(false);
  const [activePreviousFormResponses, setActivePreviousFormResponses] = useState({
    option: null,
    form: null
  });
  const [loadedTP, setLoadedTP] = useState(null);
  const isQueryEnabled = !!(!!patientId && (appointmentId ?? appointment?.id));

  /**
   * @param name {String}
   * @param kind {String}
   * @param messageKind {String}
   * @param body {String}
   * @param customFormId {Number}
   */

  const setEnhanceNarrativeParams = ({
    name,
    messageKind = 'narrative',
    body,
    customFormId,
    ...rest
  }) => {
    socket.emit('user-message', {
      kind: 'myscribe-ai',
      message: {
        noteKind,
        noteName: name ?? noteName,
        clinicalNoteId: parseInt(appointmentId, 10),
        body,
        customFormId,
        ...rest,
        kind: messageKind
      }
    });

    const timeoutTimeout = setTimeout(() => {
      showAlert({
        color: 'warning',
        title: 'MyScribe AI Enhancement Error',
        message: 'MyScribe AI is currently not available.'
      });
      setAiState((ps) => ({ ...ps, loading: false, timeoutTimeout: null }));
    }, 5000);
    setAiState((ps) => ({ ...ps, loading: true, timeoutTimeout }));
  };

  useEffect(() => {
    if (outsideClinicalNoteContext) return;

    if (io(cnDisplaySettings?.todaysNote)) {
      setSelectedCustomFormTypes((prevState) => ({
        ...prevState,
        ...cnDisplaySettings?.todaysNote
      }));
    }

    updateUserSettings();
  }, [cnDisplaySettings]);

  useEffect(() => {
    socket.emit('auth-conf');
    return () => {
      setClinicalNote({});
    };
  }, []);

  useEffect(() => {
    if (patient?.id && (appointmentId ?? appointment?.id)) {
      getPatientHistory({
        patientId: patient.id,
        setPatientHistoryLoading,
        setOverviewData
      });
    }
  }, [patient?.id]);

  useEffect(() => {
    if (!patient?.id || !(appointmentId ?? appointment?.id)) return;

    (async () => {
      setCurrentHpOverviewLoading(true);

      const getCurrentHpOverviewData = await getOverview({
        patientId: patient?.id,
        appointmentId: appointmentId ?? appointment?.id,
        navigate,
        isKebab: false,
        outsideCN: false
      });
      setCurrentHpOverviewData((prev) => ({
        ...prev,
        ...getCurrentHpOverviewData
      }));

      setCurrentHpOverviewLoading(false);
    })();
  }, [patient, appointment]);

  const updateSaveButton = async () => {
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText('Saved');
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setSaveButtonText(SAVE_BUTTON_TEXT);
  };

  const updateUserSettings = async () => {
    await handleSave();
  };

  const handleSave = async () => {
    setCNDisplaySettingsLoading(true);

    try {
      const params = {
        practitioner_id: currentUser.id,
        fields: {
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        }
      };
      const res = await interimApi('/api/practice/settings/practice_user/update', params, navigate);
      if (res.data) {
        setCurrentUser({
          ...currentUser,
          display_settings: {
            ...currentUser?.display_settings,
            clinicalNote: cnDisplaySettings
          }
        });
      }
    } catch (error) {
      Honeybadger.notify(`cnDisplaySettings save - catch error - ${error}`);
    }

    setCNDisplaySettingsLoading(false);
  };

  const applyForceSave = ({ type = null, value = false }) => {
    if (!type) return;

    setForceSave((prevState) => ({ ...prevState, [type]: value }));
  };

  useMedication({
    params: {
      patientId,
      v2: true
    },
    dependencies: [patientId],
    options: {
      refetchOnMount: true,
      enabled: isQueryEnabled,
      select: (response) => iaRa(response?.medicationHistory?.response?.medications),
      onSuccess: (medicationData) => {
        const data = iaRa(medicationData).map((med) => {
          return {
            ...med,
            createdBy: {
              ...med?.createdBy,
              isPatient: med?.createdBy?.id === Number(patientId)
            }
          };
        });

        setMedications(data); // NEED TO ONLY USE OVERVIEW_DATA NOW (PROVIDER_MEDICATIONS)
        setOverviewData((prevState) => ({
          ...prevState,
          medicationHistory: data
        }));
      }
    }
  });

  useVitals({
    params: { patientId, withal: true },
    options: {
      refetchOnMount: true,
      enabled: isQueryEnabled,
      select: (data) => data?.vitals,
      onSuccess: (vitalsData) => {
        setVitals(vitalsData);
        setOverviewData((prevState) => ({
          ...prevState,
          vitals: vitalsData
        }));
        const filteredAppointmentVitals = vitalsData?.filter(
          (vital) => vital?.appointment_id == appointmentId
        );
        setCurrentHpOverviewData((prevState) => ({
          ...prevState,
          vitals: filteredAppointmentVitals?.[0]
        }));
      }
    },
    dependencies: [patientId]
  });

  return (
    <ClinicalNoteContext.Provider
      value={{
        staticClinicalNote,
        setStaticClinicalNote,
        components,
        setComponents,
        advancedHP,
        setAdvancedHP,
        advancedSOAP,
        setAdvancedSOAP,
        customFormTypes,
        setCustomFormTypes,
        advancedHPRef,
        advancedFormNarrativeRef,
        advancedSOAPRef,
        advancedFormRef,
        advancedHPElRefs,
        setAdvancedHPElRefs,
        advancedSOAPElRefs,
        setAdvancedSOAPElRefs,
        loading,
        setLoading,
        serviceCPT,
        setServiceCPT,
        overviewData,
        setOverviewData,
        vitals,
        setVitals,
        selectedTreatmentPlan,
        setSelectedTreatmentPlan,
        currentHpOverviewData,
        setCurrentHpOverviewData,
        cnError,
        setCnError,
        tab,
        setTab,
        alert,
        setAlert,
        cnDisplaySettings,
        setCNDisplaySettings,
        cnDisplaySettingsLoading,
        selected,
        setSelected,
        showPrevNoteModal,
        setShowPrevNoteModal,
        previewNoteModal,
        setPreviewNoteModal,
        isExport,
        setIsExport,
        saveNoteRef,
        vitalsRef,
        weightLossRef,
        confirmSignLock,
        showConfirmSignLock,
        patientResponseSidebar,
        setPatientResponseSidebar,
        saveButtonText,
        setSaveButtonText,
        updateSaveButton,
        forceSave,
        setForceSave,
        applyForceSave,
        documentSignInfo,
        setDocumentSignInfo,
        outsideClinicalNoteContext,
        setEnhanceNarrativeParams,
        selectedCustomFormTypes,
        setSelectedCustomFormTypes,
        isLoadingEnhanceNarrative: aiState?.loading,
        isFetchingEnhanceNarrative: aiState?.loading,
        appointment,
        setAppointment,
        currentHpOverviewLoading,
        setCurrentHpOverviewLoading,
        medications,
        setMedications,
        isPrinting,
        setIsPrinting,
        patientHistoryLoading,
        setPatientHistoryLoading,
        aggregatedDataRef,
        activePreviousFormResponses,
        setActivePreviousFormResponses,
        loadedTP,
        setLoadedTP
      }}>
      {children}
    </ClinicalNoteContext.Provider>
  );
};
