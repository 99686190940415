import React from 'react';
import { Tab } from '@headlessui/react';
import cs from 'classnames';
import { ia } from 'lib/helpers/utility';

const Tabs = ({
  tabsData,
  prevButtons,
  tabIndex,
  setTabIndex,
  className,
  background = 'bg-neutral-100',
  hasContent = true
}) => {
  return (
    <Tab.Group selectedIndex={tabIndex} onChange={setTabIndex}>
      <div className="flex items-center justify-between gap-3">
        {prevButtons && prevButtons}
        <Tab.List
          className={cs(
            'grid grid-flow-col rounded-full p-1 text-center text-gray-500',
            background
          )}>
          {ia(tabsData) &&
            tabsData.map((tab, i) => {
              return (
                <Tab
                  data-qa={`tab-${i}`}
                  data-qa-2={`tab-${tab?.label?.toLowerCase()}`}
                  className={cs(
                    'h-full min-h-[32px] min-w-[90px] rounded-full p-1 !px-4 text-xs focus:outline-none',
                    tabIndex === i ? 'bg-white text-primary-500' : 'text-neutral-500',
                    className
                  )}
                  key={i}>
                  {tab.label}
                </Tab>
              );
            })}
        </Tab.List>
      </div>
      {hasContent && (
        <Tab.Panels className="h-full">
          {ia(tabsData) &&
            tabsData.map((tab, i) => (
              <Tab.Panel className="-mx-4 h-[calc(100%-80px)] w-auto" key={i}>
                {tab.content}
              </Tab.Panel>
            ))}
        </Tab.Panels>
      )}
    </Tab.Group>
  );
};

export default Tabs;
