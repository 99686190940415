import { createContext, useContext } from 'react';

export const PatientBalanceContext = createContext();

export const PatientBalanceContextData = createContext();

export const PatientBalanceContextApi = createContext();

export const usePatientBalanceContextData = () => useContext(PatientBalanceContextData);
export const usePatientBalanceContextApi = () => useContext(PatientBalanceContextApi);
