import React from 'react';

import { useComponentParamsContext } from 'lib/context/ComponentParamsContext/ComponentParamsContext';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const Input = ({ params }) => {
  const { formik } = useComponentParamsContext();

  const onBlur = (value) => {
    const paySchedule = [...formik.values.paySchedule];

    const eventValue = typeof value === 'number' ? (value / 100).toFixed(2) : value || '0';

    const newValue = eventValue.replace('$', '').trim();

    const allowable_amount_cents = !isNaN(newValue) ? Math.round(+newValue * 100) : null;
    const service_id = params.data.service_id;
    const payer_id = params.data.payer_id;

    const payScheduleIndex = paySchedule.findIndex(
      (schedule) => schedule.service_id === service_id && schedule.payer_id === payer_id
    );
    const payerData = { service_id, payer_id, allowable_amount_cents };

    if (payScheduleIndex === -1) {
      paySchedule.push(payerData);
    } else {
      paySchedule[payScheduleIndex] = {
        ...paySchedule[payScheduleIndex],
        ...payerData
      };
    }

    formik.setFieldValue('paySchedule', paySchedule);
  };

  return <CurrencyInput value={params.value} variant="secondary" onBlur={onBlur} hideBorder />;
};

export default Input;
