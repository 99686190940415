import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Popover } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteContact } from 'api/Contacts';

import { ia } from 'lib/helpers/utility';
import { useContacts } from 'lib/hooks/queries/contacts/useContacts';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import EmptyFaxTable from '../../fax/components/EmptyFaxTable';

import NewContactModal from './NewContactModal';

const Table = ({
  fullTable = false,
  selectedContacts = [],
  setSelectedContacts,
  selectOne = false
}) => {
  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 6,
    withCount: true
  });
  const [contactsCount, setFaxContactsCount] = useState(0);
  const [newContactModalVisible, setNewContactModalVisible] = useState(false);
  const [contactToBeUpdated, setContactToBeUpdated] = useState(null);
  const [contactToBeDeletedId, setContactToBeDeletedId] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data = {}, isLoading } = useContacts({
    params: { ...filters, filters: { name: filters.searchTerm } }
  });
  const { contacts } = data;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters.searchTerm]);
  useEffect(() => {
    if (data.count) {
      setFaxContactsCount(data.count);
    }
  }, [data]);
  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };

  const handleAddOrRemoveContact = (contact) => {
    let contactArray = [];
    if (selectedContacts.some((cont) => cont.id === contact.id)) {
      contactArray = [...selectedContacts.filter((item) => item.id !== contact.id)];
    } else if (selectOne) {
      contactArray = [contact];
    } else {
      contactArray = [...selectedContacts, contact];
    }
    setSelectedContacts(contactArray);
  };

  const mutateDeleteContact = useMutation({
    mutationFn: (id) => {
      setDeleting(true);
      return deleteContact(navigate, id);
    },
    onSuccess: () => {
      const { search, page, limit } = filters;
      queryClient.invalidateQueries(['contacts', search, page, limit]);
      showAlert({ title: 'Successfully deleted contact', color: 'primary' });
      setContactToBeDeletedId(null);
      setDeleting(false);
    }
  });

  return (
    <>
      <div className=" flex flex-col gap-6">
        <div className="flex w-full items-center justify-end gap-2 ">
          <Popover className="relative">
            {({ close, open }) => (
              <>
                <Popover.Button className="relative flex h-[40px] w-[82px] cursor-pointer items-center justify-center gap-[6px] rounded-md bg-white shadow-md">
                  <label htmlFor="" className="!m-0 cursor-pointer text-neutral-500 ">
                    {open ? 'Hide' : 'Show'}
                  </label>

                  <label htmlFor="" className="!m-0 cursor-pointer font-500 text-primary-900">
                    {filters.limit}
                  </label>
                  <Icon
                    icon="new-chevron-down-sm"
                    className={`cursor-pointer ${open && 'rotate-180'}`}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute right-0 z-10 mt-1 flex shadow-md">
                  <div className="rounded-md bg-white py-[14px] shadow-md">
                    {[6, 12, 24, 32, 48, 72].map((item) => (
                      <div
                        key={item}
                        className="flex h-[30px] w-[50px] cursor-pointer items-center justify-center text-primary-900 hover:bg-primary-50"
                        onClick={() => {
                          setFilters({ ...filters, limit: item });
                          close();
                        }}>
                        {item}
                      </div>
                    ))}
                  </div>
                </Popover.Panel>
              </>
            )}
          </Popover>

          <Input
            data-qa="searchContacts"
            type="string"
            placeholder="Search contacts..."
            value={filters.searchTerm}
            icon="new-search"
            className={` !rounded-2xl !border-secondary-400 md:w-[200px] `}
            onChange={(e) => setFilters({ ...filters, searchTerm: e.target.value, page: 1 })}
            rightText={
              filters.searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setFilters({ ...filters, search: '', page: 1 });
                  }}
                />
              )
            }
          />
          {fullTable && (
            <Button
              data-qa="new-contact-btn"
              text="New contact"
              icon="user-add"
              onClick={() => setNewContactModalVisible(true)}
              type="primary"
              outlined={true}
            />
          )}
        </div>
        {isLoading ? (
          <Skeleton count={1} height={500} />
        ) : !isLoading && !ia(contacts) ? (
          <EmptyFaxTable title="contact">
            <Button
              data-qa="new-contact-btn"
              text="New contact"
              icon="user-add"
              onClick={() => setNewContactModalVisible(true)}
              type="primary"
              outlined={true}
            />
          </EmptyFaxTable>
        ) : (
          <div>
            <table className="primary-table ">
              <thead>
                <tr>
                  {!fullTable && <th className="w-7"></th>}
                  <th className="w-7"></th>
                  <th>Name</th>
                  <th>Fax number</th>
                  <th>Email</th>
                  <th>Phone number</th>
                </tr>
              </thead>
              <tbody>
                {contacts?.map((contact, index) => {
                  const isChecked = selectedContacts.some((item) => item.id === contact.id);
                  return (
                    <tr key={contact.id} className={`${isChecked && '!bg-primary-50'}`}>
                      {!fullTable && (
                        <td className="!font-500">
                          <Checkbox
                            data-qa={`contact-${index}`}
                            isChecked={isChecked}
                            handleClick={() => handleAddOrRemoveContact(contact)}
                          />
                        </td>
                      )}
                      <td>
                        <Icon icon="user-square-medium" />
                      </td>
                      <td>{contact.first_name + ' ' + contact.last_name}</td>
                      <td>{contact.fax_number}</td>
                      <td>{contact.email}</td>
                      <td>{contact.phone_number}</td>
                      {fullTable && (
                        <td className="relative w-[50px]">
                          <Popover className="relative">
                            {({ open, close }) => (
                              <div>
                                <Popover.Button
                                  data-qa={`product-row-${index}`}
                                  className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                                    !open && 'hover:!bg-white'
                                  } ${open && '!bg-primary-700 transition-all'}`}>
                                  <Icon
                                    icon="three-dots"
                                    className="flex cursor-pointer"
                                    color={open ? 'white' : 'black'}
                                  />
                                </Popover.Button>

                                <Popover.Panel className="absolute right-0  z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
                                  <div
                                    data-qa="edit-product-btn"
                                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                    onClick={() => {
                                      setContactToBeUpdated(contact);
                                      setNewContactModalVisible(true);
                                      close();
                                    }}>
                                    <Icon icon="new-edit" color="primary" />
                                    <div className="ml-1 text-sm text-primary-900">Edit</div>
                                  </div>
                                  <div
                                    data-qa="delete-product-btn"
                                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                                    onClick={() => {
                                      setContactToBeDeletedId(contact.id);
                                      close();
                                    }}>
                                    <Icon icon="trash" color="primary" />
                                    <div className="ml-1 text-sm text-primary-900">Delete</div>
                                  </div>
                                </Popover.Panel>
                              </div>
                            )}
                          </Popover>
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="mt-[14px] flex items-center justify-between">
              <label className="text-xs text-primary-900">
                Showing {filters.limit * filters.page - filters.limit + 1}-
                {filters.limit * filters.page > contactsCount
                  ? contactsCount
                  : filters.limit * filters.page}{' '}
                out of {contactsCount}
              </label>

              <Pagination
                onPageChange={changePage}
                page={filters.page}
                totalItems={contactsCount}
                perPage={filters.limit}
              />
            </div>
          </div>
        )}
      </div>

      {newContactModalVisible && (
        <NewContactModal
          {...{
            newContactModalVisible,
            setNewContactModalVisible,
            filters,
            contact: contactToBeUpdated
          }}
        />
      )}
      {contactToBeDeletedId && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete contact"
          icon="new-document-remove-red"
          message="Are you sure you want to delete contact?"
          loading={deleting}
          handleContinue={() => mutateDeleteContact.mutate(contactToBeDeletedId)}
          handleOpen={!!contactToBeDeletedId}
          handleClose={() => setContactToBeDeletedId(null)}
        />
      )}
    </>
  );
};

export default Table;
