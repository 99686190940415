export default function limitItems(arr, limit, params) {
  let count = 0;
  let size = 0;
  let items = arr ? [...arr] : [];
  let hasMore = false;
  items = items
    ?.map((item) => {
      let newItem = { ...item };
      const listLength = newItem?.list?.length;
      count += listLength;

      if (size > limit) {
        hasMore = true;
        return null;
      }
      size += params.group || 0.3;
      newItem.list = newItem?.list
        ?.map((i) => {
          size += params.item || 0.3;
          Object.keys(params).map((key) => {
            if (key.includes(',')) {
              const keys = key.split(',');
              if (!keys.some((k) => !i[k])) size += params[key];
            } else {
              if (i[key]) size += params[key];
            }
          });
          if (size > limit) {
            hasMore = true;
            return null;
          }
          return i;
        })
        .filter((item) => item != null);

      return newItem;
    })
    .filter((item) => item != null);

  return { items: items.length > 0 ? items : null, count, hasMore };
}
