import React from 'react';

const Location = ({ itemProps }) => {
  if (!itemProps?.location_name) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Location</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {itemProps?.location_name}
        </li>
      </ul>
    </div>
  );
};

export default Location;
