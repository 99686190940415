import React, { useEffect } from 'react';

import {
  usePatientBalanceContextApi,
  usePatientBalanceContextData
} from 'lib/context/PatientBalanceContext/PatientBalanceContext';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import numOrDefault from 'lib/helpers/numOrDefault';

import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';

export const CustomMasterDetailCheckbox = (params) => {
  const transaction = params.data;
  const { allocatingRow, formData = {}, activeMode: mode } = usePatientBalanceContextData();
  const { setAllocatingRow, setFormData, setIsSubmitButtonDisabled } =
    usePatientBalanceContextApi();
  const { selectedRows } = useTableContext();
  const isSelected = selectedRows.findIndex((node) => node.id === params.node.id) > -1;

  const handleChange = (e) => {
    let finalFormData = { ...formData };
    const checked = e.target.checked;

    let payment = 0;
    const isExpanded = !params.node.expanded;

    const updateFinalFormData = (amount) => {
      finalFormData = {
        ...formData,
        [transaction.id]: {
          ...transaction,
          ...(formData?.[transaction.id] || {}),
          parent: allocatingRow,
          amount,
          transaction: allocatingRow
        }
      };
    };

    if (mode?.writeoff) {
      finalFormData = {
        ...formData,
        [transaction.id]: {
          ...transaction,
          ...(formData?.[transaction.id] || {}),
          amount: numOrDefault(transaction?.balance)
        }
      };
    } else {
      if (checked && allocatingRow?.payment == 0) {
        showAlert({
          title: 'Unallocated amount is zero',
          color: 'danger'
        });
        return;
      }
      if (allocatingRow?.payment < transaction?.balance) {
        updateFinalFormData(checked ? allocatingRow?.payment : 0);
        payment = checked ? 0 : allocatingRow?.patientBalance * -1;
      } else {
        updateFinalFormData(checked ? transaction?.balance : 0);
        payment = checked
          ? allocatingRow?.payment - transaction?.balance
          : allocatingRow?.payment + transaction?.balance;
      }

      setAllocatingRow({ ...allocatingRow, payment });
    }

    setFormData(finalFormData);

    params.node.setExpanded(isExpanded);
    params.api.getRowNode(params.node.id).setSelected(isExpanded);
  };

  useEffect(() => {
    setIsSubmitButtonDisabled(selectedRows.length === 0);
  }, [selectedRows]);

  return (
    <div className="flex h-full items-center justify-center">
      <Checkbox name="no_email" data-qa="no-email" isChecked={isSelected} onChange={handleChange} />
    </div>
  );
};
