import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import ErrorBoundary, { withErrorBoundary } from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import Overview from './Overview';
import Tabs from './Tabs';
const PatientProfile = () => {
  const { id } = useParams();

  const { data } = usePatient({
    params: { id },
    dependencies: id
  });
  const patient = data?.patient;

  return (
    <div className="flex h-full flex-col">
      <ErrorBoundary FallbackComponent={ErrorMessage}>
        <Overview patient={patient} />
      </ErrorBoundary>
      <div className="!mt-4 flex h-full flex-col overflow-hidden rounded-2xl bg-white shadow-small">
        <Tabs id={id} />
        {patient ? (
          <Outlet
            context={{
              patient
            }}
          />
        ) : (
          <Skeleton />
        )}
      </div>
    </div>
  );
};

export default withErrorBoundary(PatientProfile);
