export const LONGITUDINAL_ENCOUNTER_TYPES = {
  Medications: 'longitudinal',
  Allergies: 'longitudinal',
  Vaccines: 'longitudinal',
  WeightLossAdministration: 'longitudinal',
  Vitals: 'encounter',
  FamilyHistory: 'longitudinal',
  SurgicalHistory: 'longitudinal'
};

export const LONGITUDINAL_ENCOUNTER_MESSAGES = (title) => {
  const type = LONGITUDINAL_ENCOUNTER_TYPES[title?.trim()?.replace(/\s+/g, '')];
  return type === 'longitudinal'
    ? "Longitudinal forms display aggregated data across visits in a distinct table. Choose to include this cumulative data in today's note, with preferences saved for future encounters"
    : "Encounter-based forms focus on data specific to today's visit, providing an updated snapshot that reflects the current encounter without cumulative details.";
};
