import React from 'react';
import Icon from '../../../shared/Icon/Icon';
import Button from '../../../shared/Buttons/Button';
import cs from 'classnames';

const NotFound = ({
  icon = 'new-illustration-1',
  iconSize,
  iconClassName,
  title = '',
  titleClassName,
  description = '',
  createText = '',
  onCreate = () => {},
  className
}) => (
  <div className={cs('mx-auto flex flex-col items-center justify-center pt-14 sm:pt-8', className)}>
    {icon && <Icon icon={icon} size={iconSize} className={iconClassName} />}
    {title && (
      <p
        className={cs(
          '!px-4 !pb-1 text-center text-base font-600 text-primary-900',
          titleClassName
        )}>
        {title}
      </p>
    )}
    {description && (
      <p className="!px-4 text-center text-sm leading-6 text-neutral-600">{description}</p>
    )}
    {createText && (
      <Button
        text={createText}
        onClick={onCreate}
        icon="new-add-square"
        iconSize={18}
        className="!mt-3"
        transparent
      />
    )}
  </div>
);

export default NotFound;
