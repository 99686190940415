import React, { useState } from 'react';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import cs from 'classnames';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import { showAlert } from 'components/shared/Alert/Alert';
import { useNavigate } from 'react-router-dom';
import { deleteCalendarEvent } from 'api/CalendarEvent';
import { useQueryClient } from '@tanstack/react-query';
import { Popover } from '@headlessui/react';
import { isNull } from 'lodash';

const FooterEventPreview = ({ recurrenceChanged, formik, hideModal, event }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState('');
  const [deleting, setDeleting] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { device } = useUIContext();
  const { setFieldValue, handleSubmit, values, isSubmitting } = formik;

  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['appointment-events'] });
  };

  const handleSetType = async (type, submitType) => {
    if (submitType === 'delete') {
      setDeleting(true);
      await deleteCalendarEvent(navigate, { id: event.id, type, start_date: values.start_date });
      setShowConfirmationModal(false);
      setDeleting(false);
      await handleInvalidateQuery();
      showAlert({
        color: 'success',
        message: 'Event deleted successfully.'
      });
      hideModal();
      return;
    }
    setFieldValue('type', type).then(handleSubmit);
  };

  const showOnlyThis = !recurrenceChanged || !!event.parent_event_id || !event.repeat_event;
  const showFollowingAndAll = !event.parent_event_id && !isNull(event.repeat_event);

  return (
    <div className="flex w-full justify-between">
      {device !== 'mobile' && (
        <Button outlined color="neutral" onClick={hideModal} text="Cancel" data-qa="cancel-btn" />
      )}
      <div className="flex item-center">
        {showOnlyThis && !showFollowingAndAll ? (
          <div
            className="h-[40px] w-[40px] !mr-2 rounded-md border hover:!border-danger-500 flex items-center justify-center curs bg-red-100"
            onClick={() => setShowConfirmationModal('only')}>
            <Icon icon="calendar-cross" />
          </div>
        ) : (
          <Popover className="relative">
            {({ close }) => (
              <div>
                <Popover.Button
                  data-qa="delete-event-btn"
                  className={`flex h-[40px]  items-center justify-center  bg-white shadow-small`}>
                  <div className="h-[40px] w-[40px] rounded-md border hover:!border-danger-500 flex items-center justify-center bg-red-100">
                    <Icon icon="calendar-cross" />
                  </div>
                </Popover.Button>

                <Popover.Panel
                  className={cs(
                    'absolute right-1 z-[99999] min-w-[264px] cursor-pointer rounded-lg   bg-white  !py-[6px] shadow-lg bottom-[50px]'
                  )}>
                  {showOnlyThis && (
                    <div
                      className="flex gap-1 rounded-lg !p-2 transition-all items-center hover:bg-danger-50"
                      onClick={() => {
                        setShowConfirmationModal('only');
                        // setShowConfirmationModal('only', 'delete');
                        close();
                      }}>
                      <Icon icon="calendar-event" color="danger" />
                      <div className="text-sm text-danger-900">Delete only this event</div>
                    </div>
                  )}
                  {showFollowingAndAll && (
                    <>
                      <div
                        className="flex gap-1  !p-2 transition-all items-center hover:bg-danger-50"
                        onClick={() => {
                          setShowConfirmationModal('following');
                          close();
                        }}>
                        <Icon icon="new-calendarv2" color="danger" />
                        <div className="text-sm text-danger-900">
                          Delete this and following events
                        </div>
                      </div>
                      <div
                        className="flex gap-1  !p-2 transition-all items-center hover:bg-danger-50"
                        onClick={() => {
                          setShowConfirmationModal('all');
                          close();
                        }}>
                        <Icon icon="calendar-dates" color="danger" />
                        <div className="text-sm text-danger-900" data-qa="delete-all-events-in-the-series">
                          Delete all events in the series
                        </div>
                      </div>
                    </>
                  )}
                </Popover.Panel>
              </div>
            )}
          </Popover>
        )}
        {showOnlyThis && !showFollowingAndAll ? (
          <Button
            data-qa="update-event-btn"
            loadingIcon={isSubmitting}
            text={'Update event'}
            onClick={() => handleSetType(event.repeat_event ? 'only' : 'all', 'update')}
            loading={isSubmitting}
            className="-mt-[0px]"
          />
        ) : (
          <Popover className="relative">
            {({ close }) => (
              <div>
                <Popover.Button
                  className={`flex h-[40px]  items-center justify-center  bg-white shadow-small`}>
                  <Button
                    data-qa="update-event-btn"
                    loadingIcon={isSubmitting}
                    text={'Update event'}
                    onClick={() => {}}
                    loading={isSubmitting}
                  />
                </Popover.Button>

                <Popover.Panel
                  className={cs(
                    'absolute right-1 z-[99999] min-w-[264px] cursor-pointer rounded-lg   bg-white  !py-[6px] shadow-lg bottom-[50px]'
                  )}>
                  {showOnlyThis && (
                    <div
                      className="flex gap-1  !p-2 transition-all items-center hover:bg-primary-50"
                      onClick={() => {
                        handleSetType(event.repeat_event ? 'only' : 'all', 'update');
                        close();
                      }}>
                      <Icon icon="calendar-event" color="primary" />
                      <div className="text-sm text-primary-900">Update only this event</div>
                    </div>
                  )}
                  {showFollowingAndAll && (
                    <>
                      <div
                        className="flex gap-1  !p-2 transition-all items-center hover:bg-primary-50"
                        onClick={() => {
                          handleSetType('following', 'update');
                          close();
                        }}>
                        <Icon icon="new-calendarv2" color="primary" />
                        <div className="text-sm text-primary-900">
                          Update this and following events
                        </div>
                      </div>

                      <div
                        className="flex gap-1  !p-2 transition-all items-center hover:bg-primary-50"
                        onClick={() => {
                          handleSetType('all', 'update');
                          close();
                        }}>
                        <Icon icon="calendar-dates" color="primary" />
                        <div className="text-sm text-primary-900" data-qa="update-all-events-in-the-series">
                          Update all events in the series
                        </div>
                      </div>
                    </>
                  )}
                </Popover.Panel>
              </div>
            )}
          </Popover>
        )}
      </div>
      {showConfirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title={`Delete ${event.title}`}
          icon="new-document-remove-red"
          message={`Are you sure you want to delete ${event.title} event?`}
          loading={deleting}
          handleContinue={() => handleSetType(showConfirmationModal, 'delete')}
          handleOpen={showConfirmationModal}
          handleClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default FooterEventPreview;
