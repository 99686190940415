import capitalize from 'lodash/capitalize';

import { getProviders } from 'api/Member';
import { getPatients } from 'api/Patients';
import { getPayers } from 'api/Payer';
import { getProducts } from 'api/Products';
import { getServices } from 'api/Service';

import { TYPE_LABELS } from 'lib/constants/transaction/typeLabels';
import { formatDate, formatDateAndTime, mString } from 'lib/helpers/utility';

import PaymentsActions from '../components/PaymentsActions';

export const INITIAL_FILTERS = {
  date: {
    values: null,
    type: 'date-range',
    title: 'Date of Payment',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  type: {
    type: 'checkbox',
    values: null,
    options: [
      { value: 'era', label: 'ERA' },
      { value: 'eob', label: 'EOB' },
      { value: 'Patient Payment', label: 'Patient Payment' }
    ],
    title: 'Payment Type',
    preview: (values) => values.map((v) => v)
  },
  card_number: {
    type: 'search',
    values: '',
    title: 'Card Number',
    placeholder: 'Search by last 4 digits'
  },
  check_number: {
    values: '',
    type: 'search',
    title: 'Check Number',
    placeholder: 'Search by check number'
  },
  amount: {
    min: 0,
    max: 1000,
    values: 0,
    type: 'range',
    title: 'Amount',
    preview: (values) => mString(values)
  },
  patients: {
    type: 'infinite-scroll',
    values: [],
    queryFn: getPatients,
    queryKey: 'patients',
    multiple: true,
    title: 'Patient',
    placeholder: 'Search patient',
    formatData: (data) => data.map((patient) => ({ label: patient?.fullName, value: patient?.id }))
  },
  providers: {
    type: 'infinite-scroll',
    multiple: true,
    queryFn: getProviders,
    queryKey: 'practitioners',
    values: [],
    title: 'Provider',
    placeholder: 'Search provider',
    formatData: (data) =>
      data.map((practitioner) => ({
        label: `${practitioner?.f_name} ${practitioner?.l_name}`,
        value: practitioner?.id
      }))
  },
  payers: {
    type: 'infinite-scroll',
    queryFn: getPayers,
    queryKey: 'payers',
    multiple: true,
    values: [],
    title: 'Payer',
    placeholder: 'Search payer',
    formatData: (data) => data.map((payer) => ({ label: payer?.name, value: payer?.id }))
  },
  services: {
    type: 'infinite-scroll',
    queryFn: getServices,
    queryKey: 'services',
    multiple: true,
    values: [],
    title: 'Services',
    placeholder: 'Search service',
    formatData: (data) => data.map((service) => ({ label: service?.name, value: service?.id }))
  },
  products: {
    type: 'infinite-scroll',
    queryFn: getProducts,
    queryKey: 'products',
    multiple: true,
    values: [],
    title: 'Products',
    placeholder: 'Search product',
    formatData: (data) => data.map((product) => ({ label: product?.name, value: product?.id })),
    queryParams: {
      forUsers: true
    }
  },
  transaction_type: {
    type: 'checkbox',
    values: null,
    options: [
      { label: 'Credit Card', value: 'credit_card' },
      { label: 'Cash', value: 'cash' },
      { label: 'Check', value: 'check' },
      { label: 'Outside Financing', value: 'care_credit' },
      { label: 'Insurance Payments', value: 'era_eob' }
    ],
    title: 'Transaction Type',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  },
  status: {
    type: 'checkbox',
    values: null,
    options: [
      'completed',
      'voided',
      'refunded',
      'partially_refunded',
      'cancelled',
      'pending',
      'failed',
      'finalized'
    ],
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  }
};

export const COLUMN_DEFS = (practice) => [
  { field: 'id', headerName: 'ID' },
  {
    field: 'type',
    headerName: 'Payment Type',
    valueFormatter: ({ value }) => capitalize(value)
  },
  { field: 'patient', headerName: 'Patient' },
  { field: 'payer', headerName: 'Payer' },

  {
    field: 'created_at',
    headerName: 'Date of Payment',
    valueFormatter: ({ value }) => formatDateAndTime(value, practice.timezone)
  },

  { field: 'practitioner', headerName: 'Provider' },
  {
    field: 'check_type',
    headerName: 'Type/Check/Trace',
    valueFormatter: ({ value }) => TYPE_LABELS[value]
  },
  { field: 'cashier', headerName: 'Created by' },
  { field: 'status', headerName: 'Status', valueFormatter: ({ value }) => capitalize(value) },
  {
    field: 'amount',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value)
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: PaymentsActions,
    maxWidth: 70,
    cellClass: 'no-border',
    pinned: 'right'
  }
];
