import moment from 'moment';
export const getHours = (currPractice, appointmentStart, appointmentEnd) => {
  const locale = 'en';
  const newHours = [];
  moment.locale(locale);

  const startHr = currPractice?.working_hours?.startsAt?.hour || 0;
  const endHr = currPractice?.working_hours?.endsAt?.hour || 24;

  const appointmentStartHr = moment(appointmentStart, 'h:mm A').hour();
  const appointmentEndHr = moment(appointmentEnd, 'h:mm A').hour();

  const adjustedStartHr = appointmentStartHr < startHr ? appointmentStartHr : startHr;
  const adjustedEndHr = appointmentEndHr > endHr ? appointmentEndHr : endHr;

  for (let hour = adjustedStartHr; hour < adjustedEndHr; hour++) {
    newHours.push(moment({ hour }).format('hh:mm A'));
    for (let minutes = 1; minutes < 60; minutes++) {
      newHours.push(
        moment({
          hour,
          minute: minutes
        }).format('hh:mm A')
      );
    }
  }
  return newHours;
};
