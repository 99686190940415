import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { ia } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import FormDataItem from './FormDataItem';

const FormData = ({ data, type, activeOption }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);

    return () => setOpacity(0);
  }, []);

  return (
    <div
      className={cs(
        `flex h-full w-full flex-col divide-y-2 divide-gray-200 break-words px-2 pt-3 transition-all duration-500 opacity-${opacity}`
      )}>
      {ia(data) ? (
        data?.map((item, index) => (
          <FormDataItem
            key={item.id}
            item={item}
            index={index}
            type={type}
            activeOption={activeOption}
          />
        ))
      ) : (
        <div className="mb-[100px] flex h-full flex-grow flex-col items-center overflow-y-auto px-4 pt-[150px] text-center">
          <Icon icon="no-data-found" />
          <h2 className="mb-4 text-xl text-[#004F6B]">No data found</h2>
        </div>
      )}
    </div>
  );
};

export default FormData;
