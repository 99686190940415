import React, { useRef } from 'react';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import ReactToPrint from 'react-to-print';
import Receipt from 'components/shared/Receipt/Receipt';
import { useFormikContext } from 'formik';

const PaymentModal = ({ modalVisible, setModalUnVisible }) => {
  const { values } = useFormikContext();
  const receiptRef = useRef();

  const { patient, invoice, starts_at, payments } = values;
  const receipt = {
    invoice,
    patient,
    transactions: payments,
    transaction: { date_of_service: starts_at }
  };
  return (
    <Modal
      slideFromRight
      title="Receipt"
      isLarge
      headButton={
        <div className="flex w-full items-center justify-end">
          <ReactToPrint
            trigger={() => (
              <Button
                color=""
                size="small"
                type="link"
                text="Print"
                icon="new-printer-bulk"
                className="text-primary-900"
              />
            )}
            content={() => receiptRef.current}
          />
        </div>
      }
      handleOpen={modalVisible}
      handleClose={setModalUnVisible}
      handleClick={setModalUnVisible}
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            outlined
            transparent
            text="Close"
            color="neutral"
            data-qa="close-btn"
            onClick={setModalUnVisible}
          />
        </div>
      }>
      <Receipt receipt={receipt} parentRef={receiptRef} walkout />
    </Modal>
  );
};

export default PaymentModal;
