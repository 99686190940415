import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from 'api/Api';
import state from './state';
import '../Patient/checkin/style.scss';
import checkinBackgroundPNG from '../../assets/Images/checkin-background.png';
import { getPracticeInfo } from './methods';
import { socket } from 'api/Socket';
import { KioskContextProvider } from 'lib/context/KioskContext/KioskContextProvider';
import { showAlert } from 'components/shared/Alert/Alert';
import AutoIdle from './components/AutoIdle';

const Kiosk = ({ mobile }) => {
  const navigate = useNavigate();

  const [practice, setPractice] = useRecoilState(state.practiceState);
  const [status, setStatus] = useState({
    loading: false,
    assigned: false,
    token: '',
    state: 'init',
    code: ''
  });
  const { pathname } = useLocation();

  useEffect(() => {
    // announce kiosk to the server
    if (!mobile) {
      initializeKiosk();
      // kiosk self registers with cookie
      // kiosk enters idle state based on practice settings

      // socket.on('kiosk-command', onCommand);

      // return () => {
      //   socket.off('kiosk-command');
      //   if (socket.connected) {
      //     socket.disconnect();
      //   }
      // };
    }
  }, []);

  // const onCommand = (data) => {
  //   switch (data?.kind) {
  //     case 'state_change':
  //       // status is changing, abandon everything, go to status specified
  //       navigate(data?.command);
  //       break;
  //
  //     default:
  //       break;
  //   }
  // };

  const initializeKiosk = async () => {
    if (pathname.includes('/kiosk/activate')) {
      return;
    }
    const onSuccess = async (data) => {
      // gets data from the server of the cookie that'll be
      //   permanently assigned to this kiosk
      if (data?.confirmationCode) {
        setStatus((prevState) => ({ ...prevState, code: data.confirmationCode }));
      }
      if (data?.token) {
        socket.connect();
        socket.emit('kiosk-auth', { token: data.token });

        await getPracticeInfo({
          navigate,
          setPractice,
          filePath: `${__dirname}/${__filename}`,
          pathname
        });
      }
      if (data?.state) {
        // navigate(`${data.state}`);
      }
    };

    await requestApi({
      url: '/api/kiosk/init',
      onSuccess,
      navigate,
      filePath: `${__dirname}/${__filename}`
    });
  };

  return (
    <KioskContextProvider mobile={mobile}>
      <AutoIdle />
      <div className="h-[100svh] overflow-hidden bg-transparent">
        <img
          src={checkinBackgroundPNG}
          className="absolute left-0 top-0 z-10 h-full w-full object-cover"
        />
        <Outlet context={{ status }} />
      </div>
    </KioskContextProvider>
  );
};

export default Kiosk;
