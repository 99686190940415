const BORDER_CLASS = 'border border-solid border-neutral-200 rounded-full';

export const SIZES = {
  small: {
    label: 'text-[10px]',
    labelMargin: 'ml-[4px]',
    size: 16,
    itemFontSize: 'text-[8px]'
  },
  medium: {
    label: 'text-[12px]',
    labelMargin: 'ml-[6px]',
    size: 24,
    itemFontSize: 'text-[10px]'
  },
  large: {
    label: 'text-[16px]',
    labelMargin: 'ml-[8px]',
    size: 32,
    itemFontSize: 'text-[12px]'
  },
  xl: {
    label: 'text-[18px]',
    labelMargin: 'ml-[10px]',
    size: 40,
    itemFontSize: 'text-[14px]'
  }
};

export const getClasses = (size, isBorder = false, isCompact = false) => {
  const classes = { ...SIZES[size] };
  classes.wrapper = isBorder && isCompact ? BORDER_CLASS : '';
  return classes;
};
