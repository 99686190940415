import React from 'react';

import { capitalize } from 'lodash';
import moment from 'moment';

import { optionify } from 'lib/helpers/utility';

import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';

export default function PersonalInfo({ formRef, formik, formikHandle, genderList, stateList }) {
  const findStateName = () => {
    const state = stateList?.find((state) => {
      if (state?.id == formik?.values?.state) {
        return state;
      }
    });

    return state && state?.name;
  };

  return (
    <form onSubmit={formRef} className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <span className="text-lg font-500">Personal Information</span>
        <div className="grid grid-cols-3 gap-4 md:grid-cols-1">
          <Input
            placeholder="Enter first name"
            label="First Name"
            name="f_name"
            value={formik.values.f_name}
            onChange={formikHandle}
            error={formik.touched.f_name && formik.errors.f_name}
            data-qa="first-name"
          />
          <Input
            placeholder="Enter middle name"
            label="Middle Name"
            name="m_name"
            value={formik.values.m_name}
            onChange={formikHandle}
            error={formik.touched.m_name && formik.errors.m_name}
          />
          <Input
            placeholder="Enter last name"
            label="Last Name"
            name="l_name"
            value={formik.values.l_name}
            onChange={formikHandle}
            error={formik.touched.l_name && formik.errors.l_name}
          />
          <div className="flex flex-col">
            <DatePopover
              required={true}
              label="Date of Birth"
              name="dob"
              value={moment(formik.values.dob).format('YYYY-MM-DD')}
              onChange={(formattedDate) => formik.setFieldValue('dob', formattedDate)}
            />
            {formik.touched.dob && formik.errors.dob ? (
              <span className="pt-1 text-sm text-danger-500">{formik.errors.dob}</span>
            ) : null}
          </div>
          <Select
            isClearable={false}
            className="!w-full"
            inputId="gender"
            options={genderList}
            label="Gender"
            placeholder="Type and select Gender"
            value={{ value: formik.values?.gender, label: capitalize(formik.values?.gender) }}
            onChange={(option) => formik.setFieldValue('gender', option?.value)}
            error={formik.errors?.gender}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-lg font-500">Contact Information</span>
        <div className="grid grid-cols-2 gap-4 md:grid-cols-1">
          <Input
            placeholder="Enter email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formikHandle}
            error={formik.touched.email && formik.errors.email}
          />
          <Input
            placeholder="Enter phone number"
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            onChange={formikHandle}
            error={formik.touched.phone && formik.errors.phone}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <span className="text-lg font-500">Address</span>
        <div className="grid grid-cols-3 gap-4 md:grid-cols-1">
          <Input
            label="Address"
            data-qa="practice-address-1"
            type="text"
            placeholder="Address"
            name="address_ln_1"
            value={formik.values.address_ln_1}
            onChange={formikHandle}
          />
          <Input
            label="Address 2"
            data-qa="practice-address-2"
            type="text"
            placeholder="Address 2"
            name="address_ln_2"
            value={formik.values.address_ln_2}
            onChange={formikHandle}
          />
          <Input
            label="City"
            data-qa="practice-city"
            type="text"
            placeholder="City"
            name="city"
            value={formik.values.city}
            onChange={formikHandle}
          />
          <Select
            label="State"
            inputId="state-select"
            placeholder="Type and select"
            name="state"
            value={{ label: findStateName() }}
            onChange={(value) => formik.setFieldValue('state', value.value)}
            options={optionify(stateList, 'name', 'id')}
            error={formik.errors.state}
            menuPlacement="top"
          />
          <Input
            label="Zip"
            data-qa="practice-zip"
            type="text"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formikHandle}
          />
        </div>
      </div>
    </form>
  );
}
