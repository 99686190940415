import { useQuery } from '@tanstack/react-query';
import { getPractitioners } from '../../../../api/Practitioner';
import { useNavigate } from 'react-router-dom';

export const usePractitioners = (params = {}, options, dependencies = []) => {
  const navigate = useNavigate();
  return useQuery(['practitioners', ...dependencies], () => getPractitioners(params, navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
