import React, { useState } from 'react';
import Summary from '../../CurrentNote/TypeNotes/MyScribeAI/components/Summary/Summary';
import SummaryModal from '../../CurrentNote/TypeNotes/MyScribeAI/components/Summary/SummaryModal';

import { showAlert } from '../../../../../shared/Alert/Alert';
import { removeBracketsInText } from 'lib/helpers/utility';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const MyScribeAISummary = () => {
  const { transcriptData } = useAmbientListeningContext();

  const [showModal, setShowModal] = useState(false);

  const removeHtmlTags = (html) => {
    return html?.replace(/<[^>]*>/g, '');
  };

  const copyTranscript = () => {
    const stripHTMLTags = removeHtmlTags(transcriptData?.[0]?.summary);
    const removeBracketsText = removeBracketsInText(stripHTMLTags);

    navigator.clipboard
      .writeText(removeBracketsText)
      .then(() =>
        showAlert({
          title: 'Copy Summary',
          message: 'Summary copied to clipboard',
          color: 'success'
        })
      )
      .catch(() =>
        showAlert({
          title: 'Copy Summary',
          message: 'Failed to copy Summary',
          color: 'error'
        })
      );
  };

  return (
    <div>
      <Summary setShowModal={setShowModal} copyTranscript={copyTranscript} />

      <SummaryModal showModal={showModal} hideModal={() => setShowModal(false)} />
    </div>
  );
};

export default MyScribeAISummary;
