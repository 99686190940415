import { useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';

import { getTest } from 'api/LabSoftOrder';

const useFetchTestDetails = () => {
  const navigate = useNavigate();

  const { mutate, mutateAsync, isLoading } = useMutation({
    mutationKey: ['fetchTestDetails'],
    mutationFn: ({ testId }) => getTest(navigate, { testId, details: true })
  });

  return {
    mutate,
    mutateAsync,
    isLoading
  };
};

export default useFetchTestDetails;
