import { columnHeaderBaseOptions } from './constants';

export default async function selectedHeaders({
  e,
  index,
  headerValue,
  show,
  setHeaderValue,
  setColumnHeaderOptions,
  setShow,
  dobFormatRef,
  nameFormatRef,
  policyStartDateFormatRef,
  policyEndDateFormatRef,
  providerNameFormatRef,
  transactionAmountFormatRef,
  transactionAmountSelfFormatRef
}) {
  let newShow = Object.assign({}, show);
  let newHeaderValue = Object.assign([], headerValue);
  newShow.holds = false;
  // get old value
  let oldHeaderValue = newHeaderValue[index];
  if (oldHeaderValue) {
    // this was selected before
    // write new header value
    newHeaderValue[index] = e;
    // return the header to the pool of header options
  } else {
    newHeaderValue[index] = e;
  }
  // remove the selected element from the header options pool
  let newColumnHeaderOptions = columnHeaderBaseOptions.filter((v) => {
    if (!v.value) {
      return false;
    }
    let isInSet = false;
    newHeaderValue.forEach((w) => {
      isInSet = isInSet || w?.value === v?.value;
    });
    return v?.value && !isInSet;
  });
  let dupMap = new Map();
  for (let i = 0; i < newHeaderValue.length; i++) {
    let headerValue = newHeaderValue[i]?.value;
    let dupMapValue = dupMap.get(headerValue);
    if (newHeaderValue[i]) {
      newHeaderValue[i]['message'] = undefined;
      if (dupMapValue) {
        newHeaderValue[i]['message'] = `No duplicate matches allowed`;
        newHeaderValue[dupMapValue]['message'] = `No duplicate matches allowed`;
        newShow.holds = true;
      } else {
        dupMap.set(newHeaderValue[i]?.value, i);
      }
    }
    switch (headerValue) {
      case 'dob':
        if (!dobFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'name':
        if (!nameFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'insurance_policy_start_date':
        if (!policyStartDateFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'insurance_policy_end_date':
        if (!policyEndDateFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'appointment_provider':
        if (!providerNameFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'transaction_amount':
        if (!transactionAmountFormatRef.current) {
          newShow.holds = true;
        }
        break;
      case 'transaction_amount_paid_self':
        if (!transactionAmountSelfFormatRef.current) {
          newShow.holds = true;
        }
        break;
      default:
        break;
    }
  }
  let newMessages = [];
  for (let i = 0; i < newColumnHeaderOptions.length; i++) {
    if (newColumnHeaderOptions[i]?.missingMessage) {
      newMessages.push(newColumnHeaderOptions[i].missingMessage);
      if (newColumnHeaderOptions[i]?.missingMessage?.className === 'error') {
        newShow.holds = true;
      }
    }
  }
  newShow.messages = newMessages;
  setHeaderValue(newHeaderValue);
  setColumnHeaderOptions(newColumnHeaderOptions);
  setShow(newShow);
}
