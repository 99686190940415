import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getBillingOverview } from '../../../../api/Widgets';

export const useBillingOverview = ({ params = {}, options = {}, dependencies = [] } ) => {
  const navigate = useNavigate();
  return useQuery(['billing-overview', ...dependencies], () => getBillingOverview(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};
