import React, { useState } from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

export default function NewFacilityProvider({
  formik,
  isLoading,
  open,
  handleClose,
  handleSubmit,
  states,
  loadedMembers,
  handleDeletion
}) {
  const [selectedState, setSelectedState] = useState(null);
  const [practitioners, setPractitioners] = useState(loadedMembers);
  const [selectedPractitioners, setSelectedPractitioners] = useState();

  const selectPractitioners = (e, v) => {
    setSelectedPractitioners(e);
    let newArr = [...formik.values.practitioner_ids];
    if (v.action === 'remove-value') {
      newArr = practitioners?.filter((w) => v.removedValue.value !== w);
    } else if (v.action === 'select-option') {
      newArr.push(v.option.value);
    } else if (v.action === 'clear') {
      newArr = [];
    }
    formik.setFieldValue('practitioner_ids', newArr);
  };

  const handleSelectState = (state) => {
    setSelectedState(state);
    formik.setFieldValue('facility_state', state?.value);
  };

  return (
    <Modal
      slideFromRight
      handleOpen={open}
      handleClose={handleClose}
      title="Create new facility provider"
      footer={
        <>
          <Button text="Cancel" color="neutral" outlined onClick={handleClose} />
          {formik.values.id && (
            <Button text="Delete" color="danger" onClick={() => handleDeletion(formik.values.id)} />
          )}
          <Button text="Create Facility Provider" loading={isLoading} onClick={handleSubmit} />
        </>
      }>
      <form>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Facility information</h3>
          <div className="groupColumnsFour">
            <div>
              <label className="label" htmlFor="name">
                Name
              </label>
              <Input
                type="text"
                placeholder="Name"
                name="facility_name"
                value={formik.values.facility_name}
                onChange={formik.handleChange}
                id="name"
              />
              {formik.errors.facility_name && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_name}</span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="npi">
                Facility NPI
              </label>
              <Input
                type="text"
                placeholder="Facility NPI"
                name="facility_npi"
                value={formik.values.facility_npi}
                onChange={formik.handleChange}
                id="npi"
              />
              {formik.errors.facility_npi && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_npi}</span>
              )}
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <div className="groupColumnsFour">
            <div>
              <label className="label" htmlFor="id">
                Facility Atypical ID
              </label>
              <Input
                type="text"
                placeholder="Facility Atypical ID"
                name="facility_id"
                value={formik.values.facility_id}
                onChange={formik.handleChange}
                id="id"
              />
              {formik.errors.facility_id && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_id}</span>
              )}
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Facility Address information</h3>
          <div className="groupColumnsFour">
            <div className="addressFirst">
              <label className="label" htmlFor="addressFirst">
                Address
              </label>
              <Input
                type="text"
                placeholder="Address"
                name="facility_addr_1"
                value={formik.values.facility_addr_1}
                onChange={formik.handleChange}
                id="addressFirst"
              />
              {formik.errors.facility_addr_1 && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_addr_1}</span>
              )}
            </div>
            <div className="addressSecond">
              <label className="label" htmlFor="addressSecond">
                Address 2
              </label>
              <Input
                type="text"
                placeholder="Address 2"
                name="facility_addr_2"
                value={formik.values.facility_addr_2}
                onChange={formik.handleChange}
                id="addressSecond"
              />
              {formik.errors.facility_addr_2 && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_addr_2}</span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="city">
                City
              </label>
              <Input
                type="text"
                placeholder="City"
                name="facility_city"
                value={formik.values.facility_city}
                onChange={formik.handleChange}
                id="facility_city"
              />
              {formik.errors.facility_city && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_city}</span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="state">
                State
              </label>
              <Select
                placeholder="Type and select state"
                value={selectedState}
                onChange={(value) => handleSelectState(value)}
                options={states}
                inputId="state"
              />
              {formik.errors.facility_state && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_state}</span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="zipCode">
                Zip code
              </label>
              <Input
                type="text"
                placeholder="Zip"
                name="facility_zip"
                value={formik.values.facility_zip}
                onChange={formik.handleChange}
                id="zipCode"
              />
              {formik.errors.facility_zip && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.facility_zip}</span>
              )}
            </div>
          </div>
        </div>
        {/* <div className="groupColumns">
          <div className="groupColumnsTwo">
            <div>
              <h3 className="groupColumns__title">Practitioners</h3>
              <label className="label" htmlFor="practitioner">
                Practitioners
              </label>
              <Select
                placeholder="Type and select practitioners"
                value={selectedPractitioners}
                onChange={(e, v) => selectPractitioners(e, v)}
                options={practitioners}
                styles={customSelectStyle}
                inputId="practitioner"
                menuPlacement="top"
                isMulti={true}
              />
              {formik.errors.practitioner_ids && (
                <span className="!pt-2 text-left text-sm text-danger-500">{formik.errors.practitioner_ids}</span>
              )}
            </div>
          </div>
        </div> */}
      </form>
    </Modal>
  );
}
