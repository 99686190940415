import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getCheckinUser } from '../../../../api/User';

export const useCheckinUser = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['checkinUser', ...dependencies], () => getCheckinUser(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
