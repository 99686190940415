import React from 'react';

import ChartDocuments from '../../practice/charts/Documents/Container';

export default function Documents() {
  return (
    <div className="px-[60px] !py-5">
      <ChartDocuments fromPatient={true} />
    </div>
  );
}
