import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse, upsertCustomFormResponse } from 'api/CustomForms';
import { addMedicationToWeightLoss, createMedication, deleteMedication } from 'api/Medication';
import { showAlert } from 'components/shared/Alert/Alert';
import { translateMedicationToWeightLossData } from 'components/shared/Forms/Custom/Provider/WeightLoss/lib/helpers';
import {
  CONFIRMATION_MODAL,
  MEDICATION_ACTION_TYPES,
  MEDICATION_STATUS
} from 'components/shared/Forms/Custom/ProviderMedications/lib/constants';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa } from 'lib/helpers/utility';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const Actions = ({ data, customProps }) => {
  const [confirmationModal, setConfirmationModal] = useState(CONFIRMATION_MODAL);
  const { setOverviewData } = useClinicalNoteContext();
  const { id: patientId, appointmentId } = useParams();
  const navigate = useNavigate();

  const handleStatusLabel = data?.status === MEDICATION_STATUS.ACTIVE ? 'Inactive' : 'Active';
  const location = useLocation();
  const options = [
    {
      icon: 'status',
      color: data?.status === MEDICATION_STATUS.ACTIVE ? 'danger' : 'primary',
      stroke: true,
      label: `Mark ${handleStatusLabel}`,
      onClick: () =>
        setConfirmationModal({ open: true, type: MEDICATION_ACTION_TYPES.UPDATE_STATUS })
    },
    {
      icon: 'new-edit-pen',
      label: 'Edit',
      onClick: () => onHandleEdit()
    },
    {
      icon: 'trash',
      label: 'Delete',
      onClick: () => setConfirmationModal({ open: true, type: MEDICATION_ACTION_TYPES.DELETE })
    }
  ];
  if (!data.weight_loss_medication_id) {
    options.push({
      icon: 'new-add-square',
      label: 'Add to Weight Loss Medications',
      onClick: () =>
        setConfirmationModal({ open: true, type: MEDICATION_ACTION_TYPES.ADD_TO_WEIGHT_LOSS })
    });
  }
  const fromClinicalNote = () => {
    if (location.pathname.includes('clinical-note')) {
      return true;
    }
    return false;
  };

  const onHandleDelete = async () => {
    if (data?.medication_id || (data?.id && !customProps.fromWeightLoss)) {
      await mutateDeleteMedication.mutateAsync();
    }
    if (data?.weight_loss_medication_id || data?.cfr_id) {
      await mutatateDeleteCustomFormResponse.mutateAsync();
    }
    setConfirmationModal(CONFIRMATION_MODAL);
  };

  const onHandleEdit = () => {
    customProps?.setShowMedicationModal({ open: true, data });
  };

  const onHandleUpdateStatus = async () => {
    await upsertFormFunctionMutation.mutateAsync({
      patientId,
      appointmentId,
      medication: {
        medications: [
          {
            ...data,
            status:
              data?.status === MEDICATION_STATUS.ACTIVE
                ? MEDICATION_STATUS.INACTIVE
                : MEDICATION_STATUS.ACTIVE
          }
        ]
      }
    });
    const formattedWhlData = translateMedicationToWeightLossData({
      medication: data,
      medication_id: data?.id,
      weight: data?.weight
    });

    if (data?.weight_loss_medication_id) {
      await upsertWeightLossMutation.mutateAsync({
        patientId,
        appointmentId,
        response: {
          row: [
            {
              ...formattedWhlData,
              status:
                formattedWhlData?.status === MEDICATION_STATUS.ACTIVE
                  ? MEDICATION_STATUS.INACTIVE
                  : MEDICATION_STATUS.ACTIVE
            }
          ]
        },
        type: 'weightLossAdministration',
        formId: data?.weight_loss_medication_id
      });
    }

    setConfirmationModal(CONFIRMATION_MODAL);
  };

  const queryClient = useQueryClient();
  const mutateDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMedication(navigate, {
        medicationId: data?.id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }

      showAlert({
        title: `Medication`,
        message: 'Medication deleted successfully',
        color: 'success'
      });

      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });
  const mutatateDeleteCustomFormResponse = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: data?.weight_loss_medication_id || data?.cfr_id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Delete Weight Loss Medication',
          message: error,
          color: 'warning'
        });
        return;
      }

      const idToDelete = data?.weight_loss_medication_id || data?.cfr_id;
      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: prev?.weightLossAdministration?.filter(
          (weightLoss) => weightLoss?.id !== idToDelete
        )
      }));
      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  const upsertFormFunctionMutation = useMutation((data) => createMedication(navigate, data), {
    onSettled: (upsertData) => {
      const { code, error = null } = upsertData || {};

      if (code !== 0) {
        showAlert({
          title: 'Medication',
          message: error ?? 'Medication creation failed! Please contact support.',
          color: 'danger'
        });

        return;
      }

      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    }
  });
  const upsertWeightLossMutation = useMutation((data) => upsertCustomFormResponse(navigate, data), {
    onSettled: (data) => {
      const { code, error = null, newResponse } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Syncing Medication to Weight Loss Administration',
          message: error ?? 'Syncing status failed! Please contact support.',
          color: 'danger'
        });

        return;
      }

      if (fromClinicalNote() && data?.weight_loss_medication_id) {
        setOverviewData((prev) => ({
          ...prev,
          weightLossAdministration: prev?.weightLossAdministration?.map((weightLoss) => {
            if (weightLoss?.id === data?.weight_loss_medication_id) {
              return newResponse;
            }
            return weightLoss;
          })
        }));
      }

      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    }
  });
  const addMedicationToWeightLossAdministration = async () => {
    const translatedData = translateMedicationToWeightLossData({
      medication: data,
      medication_id: data?.id
    });
    let result = await addToWeightLossMutation.mutateAsync({
      patientId,
      appointmentId,
      response: { row: [translatedData] },
      type: 'weightLossAdministration',
      medicationId: data?.id
    });
    if (fromClinicalNote()) {
      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: [result.newResponse, ...iaRa(prev?.weightLossAdministration)]
      }));
    }

    setConfirmationModal(CONFIRMATION_MODAL);
  };

  const addToWeightLossMutation = useMutation({
    mutationFn: (data) => addMedicationToWeightLoss(navigate, data),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Add Medication to Weight Loss Administration',
          message: error,
          color: 'warning'
        });
        return;
      }

      showAlert({
        title: 'Medication',
        message: `Medication was successfully transferred to the Weight Loss Administration`,
        color: 'success'
      });

      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Popover
        options={options}
        optionIconColor="primary"
        position="left"
        isFixed
        icon="new-context-menu-dots"
      />

      {confirmationModal?.open && confirmationModal?.type === MEDICATION_ACTION_TYPES.DELETE && (
        <Confirm
          icon="new-info"
          iconColor="danger"
          iconSize={42}
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete Medication"
          message="Are you sure you want to delete this medication?"
          handleContinue={onHandleDelete}
          handleOpen={!!confirmationModal?.open}
          handleClose={() => setConfirmationModal(CONFIRMATION_MODAL)}
          disabled={
            mutateDeleteMedication?.isLoading || mutatateDeleteCustomFormResponse?.isLoading
          }
          loading={mutateDeleteMedication?.isLoading || mutatateDeleteCustomFormResponse?.isLoading}
        />
      )}

      {confirmationModal?.open &&
        confirmationModal?.type === MEDICATION_ACTION_TYPES.UPDATE_STATUS && (
          <Confirm
            icon="new-info"
            iconSize={42}
            variant="warning"
            primaryBtnTxt={`Mark ${handleStatusLabel}`}
            title={`Mark ${handleStatusLabel} Medication`}
            message={`Are you sure you want to mark this medication as ${handleStatusLabel}?`}
            handleContinue={onHandleUpdateStatus}
            handleOpen={!!confirmationModal?.open}
            handleClose={() => setConfirmationModal(CONFIRMATION_MODAL)}
            disabled={upsertFormFunctionMutation?.isLoading}
            loading={upsertFormFunctionMutation?.isLoading}
          />
        )}
      {confirmationModal?.open &&
        confirmationModal?.type === MEDICATION_ACTION_TYPES.ADD_TO_WEIGHT_LOSS && (
          <Confirm
            icon="new-info"
            iconColor="primary"
            iconSize={42}
            primaryBtnTxt="Add"
            title="Add Medication to Weight Loss Administration"
            message="Are you sure you want to add this medication to Weight Loss Administration?"
            handleContinue={addMedicationToWeightLossAdministration}
            handleOpen={!!confirmationModal?.open}
            handleClose={() => setConfirmationModal(CONFIRMATION_MODAL)}
            disabled={addToWeightLossMutation?.isLoading}
            loading={addToWeightLossMutation?.isLoading}
          />
        )}
    </div>
  );
};

export default Actions;
