import Button from 'components/shared/Buttons/Button';
import React from 'react';

export const FooterButtons = ({ handleButtons, handle, mode, tabIndex, text, disabled }) => {
  return (
    <div className="flex w-full justify-between">
      <Button
        data-qa="cancel-btn"
        outlined
        text="Cancel"
        color="neutral"
        onClick={() => handleButtons(mode, tabIndex)}
      />
      <Button
        text={text}
        color="primary"
        disabled={disabled}
        onClick={handle}
        data-qa="action-footer-btn"
      />
    </div>
  );
};
