import React from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';

import { ia, mString } from 'lib/helpers/utility';
import Icon from 'components/shared/Icon/Icon';
import { handleTaxItems } from 'components/practice/appointment/PreviewAppointment/Financial/lib/handleTaxItems';

const TaxDetails = ({ tax, items, formik, actions = true }) => {
  const oldItems = [
    ...(formik?.values?.invoice?.procedures || []),
    ...(formik?.values?.invoice?.products || [])
  ];

  const totalTaxAmount = Math.round(
    (ia(oldItems) ? oldItems : items).reduce((acc, item) => {
      if (item?.tax_excluded == true) return acc;

      const amount = item?.total_amount_cents || (item?.pt_balance ?? item?.total_cost_cents) || 0;

      const stateTax = ((item?.state_tax_rate || 0) / 10000) * amount;
      const localTax = ((item?.local_tax_rate || 0) / 10000) * amount;

      if (stateTax > 0) acc += stateTax;

      if (localTax > 0) acc += localTax;

      return acc;
    }, 0)
  );

  return totalTaxAmount === tax ? (
    <table className="w-full bg-transparent">
      <thead className="uppercase text-neutral-600">
        <tr className="border-x-0 !border-b border-t-0 border-solid border-b-neutral-200 text-left text-xs tracking-wide">
          <td className="max-w-9 truncate !py-2 px-1 !font-400">Name</td>
          <td className="!py-2 px-1 !font-400">State</td>
          <td className="!py-2 px-1 !font-400">Local</td>
          <td className="!py-2 px-1 !font-400" title="Charge"></td>
        </tr>
      </thead>

      <tbody>
        {items
          .filter((p) => p?.state_tax_rate > 0 || p?.local_tax_rate > 0)
          .map((item, i) => {
            const total_amount_cents =
              item?.total_amount_cents || item?.pt_balance || item?.total_cost_cents || 0;

            const id = item?.uuid || item?.id;
            const taxExcluded = item?.tax_excluded;

            const type = item?.type ? 'procedures' : 'products';

            return (
              <tr
                key={item?.id + i}
                className={cs(
                  taxExcluded && 'opacity-60',
                  '!h-9 border-x-0 !border-b border-t-0 border-solid border-b-neutral-100 text-left last:border-none hover:bg-transparent'
                )}>
                <td
                  className="max-w-[100px] truncate !py-2 text-left text-xs text-primary-900 "
                  title={item?.name}>
                  {item.name}
                </td>
                <td className="!py-2 text-xs text-primary-900">
                  {item?.state_tax_rate / 100 > 0 && (
                    <>
                      {item.state_tax_rate / 100}% (
                      {mString((item.state_tax_rate / 10000) * total_amount_cents)})
                    </>
                  )}
                </td>
                <td className="!py-2 text-xs text-primary-900">
                  {item?.local_tax_rate / 100 > 0 && (
                    <>
                      {item.local_tax_rate / 100}% (
                      {mString((item.local_tax_rate / 10000) * total_amount_cents)})
                    </>
                  )}
                </td>
                {actions && (
                  <td className="!py-2 text-xs text-primary-900">
                    <Tippy content={taxExcluded ? 'Include' : 'Exclude'} className="tippy-dark">
                      <div className="flex  w-6 justify-center" data-dd-privacy="allow">
                        {taxExcluded ? (
                          <Icon
                            stroke
                            size="14px"
                            icon="plus"
                            color="neutral"
                            onClick={() => handleTaxItems({ formik, type, id, action: 'add' })}
                          />
                        ) : (
                          <Icon
                            size="14px"
                            icon="trash"
                            onClick={() => handleTaxItems({ formik, type, id, action: 'remove' })}
                          />
                        )}
                      </div>
                    </Tippy>
                  </td>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  ) : (
    <p className="py-1 text-xs text-neutral-600">Tax has been manually adjusted.</p>
  );
};

export default TaxDetails;
