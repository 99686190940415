// Function to hide common components across selected forms but keep one visible
export const updateHiddenComponents = (forms, isEnhanceNote) => {
  const componentLocations = {};

  // Collect component locations in checked forms
  for (const [formType, form] of Object.entries(forms || {})) {
    if (!form.checked) continue;

    for (const [componentKey, component] of Object.entries(form.components || {})) {
      if (!componentLocations[componentKey]) {
        componentLocations[componentKey] = [];
      }

      // Include only selected components if isEnhanceNote is true
      if (!isEnhanceNote || component.selected) {
        componentLocations[componentKey].push(formType);
      }
    }
  }

  // Update `hide` property for duplicate components
  const updatedForms = { ...forms };

  for (const [componentKey, formTypes] of Object.entries(componentLocations)) {
    const [firstForm, ...restForms] = formTypes;

    // Ensure the first form's component is visible
    if (firstForm) {
      updatedForms[firstForm].components[componentKey] = {
        ...updatedForms[firstForm].components[componentKey],
        hide: false
      };
    }

    // Hide the component in other forms
    for (const formType of restForms) {
      updatedForms[formType].components[componentKey] = {
        ...updatedForms[formType].components[componentKey],
        hide: true
      };
    }
  }

  return updatedForms;
};

export function filterNotHiddenComponents(components) {
  // Filter components with hide: true
  const filteredComponents = {};
  for (const componentKey in components) {
    if (!components[componentKey].hide) {
      filteredComponents[componentKey] = { ...components[componentKey] };
    }
  }

  return filteredComponents;
}

export function transformData(data) {
  const result = [];
  data.forEach((item) => {
    const { label: typeLabel, components, forms, type } = item;
    Object.keys(components).forEach((key) => {
      const component = components[key];
      result.push({
        title: component.title,
        path: component.path,
        position: component.position || null,
        checked: component.checked,
        selected: component.selected,
        typeLabel,
        forms,
        key,
        type
      });
    });
  });
  return result;
}

export function getSelectedForms(selectedForms, clinicalNoteForms, advancedForms) {
  const finalSelectedForms = {};
  for (const sectionKey in selectedForms) {
    const section = selectedForms[sectionKey];

    for (const componentKey in section.components) {
      switch (componentKey) {
        case 'complaints':
          finalSelectedForms[componentKey] = {
            other_complaints: clinicalNoteForms?.['other_complaints'],
            present_illness: clinicalNoteForms?.['present_illness']
          };
          break;
        case 'patient-needs':
          finalSelectedForms[componentKey] = clinicalNoteForms?.['present_illness'];
          break;
        case 'cpt-codes':
          finalSelectedForms[componentKey] = clinicalNoteForms?.['cpt_codes'];
          break;
        case 'diagnosis-codes':
          finalSelectedForms[componentKey] = clinicalNoteForms?.['icd_10_codes'];
          break;
        default:
          if (clinicalNoteForms?.[componentKey]) {
            finalSelectedForms[componentKey] = clinicalNoteForms[componentKey];
          }
          const currentForm = advancedForms?.find((form) => form?.custom_title === componentKey);
          if (currentForm) {
            finalSelectedForms[componentKey] = { ...currentForm, isAdvanceForm: true };
          }
      }
    }
  }

  return finalSelectedForms;
}
