import React, { useState, useEffect } from 'react';
import Button from 'components/shared/Buttons/Button';
import Select from 'components/shared/Select/Select';
import { optionify } from 'lib/helpers/utility';
import { useNavigate } from 'react-router-dom';
import Honeybadger from '@honeybadger-io/js';
import { getStates as getStatesAPI } from 'api/State';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import Input from 'components/shared/Input/Input';

const AccountAddress = ({ address, setAddress, practice }) => {
  const [states, setStates] = useState([]);

  const navigate = useNavigate();

  const onAddressChange = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setAddress({
      ...address,
      [field]: value,
      full_street_address: `${address?.address_ln_1}, ${address?.address_ln_2}, ${address?.city}, ${address?.state}, ${address.country}, ${address.zip}`
    });
  };

  const findStateName = (value) => {
    const state = states?.find((state) => {
      if (state?.id == value?.state) {
        return state;
      }
    });

    return state && state?.name;
  };

  const updateAddress = async () => {
    try {
      if (address) {
        try {
          let res = await requestApi({
            url: '/api/address/patch',
            params: {
              loadedAddress: address
            },
            navigate
          });
          if (res.code === 0) {
            showAlert({
              message: 'Address updated successfully!',
              color: 'success'
            });
            setAddress(address);
          } else {
            showAlert({
              message: 'An error has occurred. Please try again later.',
              color: 'danger'
            });
            Honeybadger.notify(
              `practice: ${practice.id}, file: /practice/settings/account/index.js, method: updateAddress, error`
            );
          }
        } catch (error) {
          Honeybadger.notify(
            `practice: ${practice.id}, file: /practice/settings/account/index.js, method: updateAddress, error: ${error}`
          );
        }
      }
    } catch (error) {
      Honeybadger.notify(
        `practice: ${practice.id}, file: /practice/settings/account/index.js, method: updateAddress, error: ${error}`
      );
    }
  };

  const getStates = async () => {
    try {
      let res = await getStatesAPI(navigate);
      setStates(res);
    } catch (error) {
      Honeybadger.notify(
        `practice: ${practice.id}, file: /practice/settings/account/index.js, method: getStates, error:${error}`
      );
    }
  };
  useEffect(() => {
    getStates();
  }, []);

  return (
    <div className="w-full p-3">
      {states && (
        <div>
          <label className='font-500'>Practice Address</label>
          <div>
            <div className="w-full p-3">
              <div className="pt-3">
                <Input
                  label="Address"
                  type="text"
                  placeholder="Address"
                  name="address_ln_1"
                  value={address?.address_ln_1}
                  onChange={(e) => onAddressChange(e)}
                />
              </div>
              <div className="pt-3">
                <Input
                  label="Address 2"
                  type="text"
                  placeholder="Address 2"
                  name="address_ln_2"
                  value={address?.address_ln_2}
                  onChange={(e) => onAddressChange(e)}
                />
              </div>
              <div className="pt-3">
                <Input
                  label="City"
                  type="text"
                  placeholder="City"
                  name="city"
                  value={address?.city}
                  onChange={(e) => onAddressChange(e)}
                />
              </div>
              <div className="pt-3">
                <Select
                  label='State'
                  inputId="state-select"
                  placeholder="Type and select"
                  name="state"
                  value={{ label: findStateName(address) }}
                  onChange={(e) => onAddressChange({ ...e, name: 'state' })}
                  options={optionify(states, 'name', 'id')}
                />
              </div>
              <div className="pt-3">
                <Input
                  label="Zip code"
                  type="text"
                  placeholder="Zip"
                  name="zip"
                  value={address?.zip}
                  onChange={(e) => onAddressChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="p-3 pt-2 ">
            <Button text="Save" onClick={() => updateAddress()} primary />
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountAddress;
