import React, { useRef, useState } from 'react';
import Icon from '../../../../shared/Icon/Icon';
import Button from '../../../../shared/Buttons/Button';
import SendFax from '../components/SendFax/SendFax';
import Loader from '../../../../shared/Loader/Loader';

export default function Main() {
  const sendFaxRef = useRef(null);
  const [loading, setLoading] = useState({ draft: false, send: false, preview: false });
  return (
    <div className="flex flex-col bg-neutral-50 h-full">
      <div className="mb-[1px] flex h-[68px] w-full items-center justify-between bg-white !px-6">
        <h3 className="relative text-primary-800 text-lg font-500">Send eFax</h3>
        <div className="flex items-center">
          <Icon icon="hipaa" />
          <h3 className="text-[10px] font-700 text-neutral-500">
            HIPAA compliant electronic faxing
          </h3>
        </div>
        <div className="flex h-full items-center gap-4  min-w-[240px]">
          {loading.draft ? (
            <div className="w-[85px] flex item-center justify-center">
              <Loader outlined={true} className="bg-primary-500 text-white " />
            </div>
          ) : (
            <Button
              data-qa="save-draft-btn"
              transparent
              type="primary"
              color="neutral"
              text="Save draft"
              onClick={() => {
                sendFaxRef?.current?.submitForm('draft');
              }}
              size="small"
              className="!p-0"
              loadingIcon={loading.draft}
              disabled={loading.draft || loading.send || loading.preview}
            />
          )}

          {loading.preview ? (
            <div className="w-[38px] flex item-center justify-center">
              <Loader outlined={true} className="bg-primary-500 text-white " />
            </div>
          ) : (
            <Icon
              icon="play-circle"
              onClick={() => sendFaxRef?.current?.submitForm('preview')}
              disabled={loading.draft || loading.send || loading.preview}
            />
          )}
          <Button
            data-qa="send-fax-btn"
            text="Send fax"
            onClick={() => {
              sendFaxRef?.current?.submitForm('send');
            }}
            loading={loading.send}
            disabled={loading.draft || loading.send || loading.preview}
          />
        </div>
      </div>
      <SendFax ref={sendFaxRef} setLoading={setLoading} />
    </div>
  );
}
