import React from 'react';
import { Capitalize, formatDate } from 'lib/helpers/utility';
import TagColor from '../components/TagColor';
import { tagColorOptions } from 'components/shared/Tags/lib/tagColorOptions';
import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';
import * as Yup from 'yup';
import Actions from '../components/Actions';

const tagPrivacy = [
  {
    value: true,
    label: 'Public'
  },
  {
    value: false,
    label: 'Private'
  }
];

export const tagType = [
  {
    value: 'chart',
    label: 'Chart'
  },
  {
    value: 'fax',
    label: 'Fax'
  },
  {
    value: 'appointment',
    label: 'Appointment'
  }
];

export const TagValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  kind: Yup.string().required('Type is required'),
  isPublic: Yup.boolean().required('Privacy is required'),
  color: Yup.string().required('Color is required')
});

export const tagInitialValues = (tag) => ({
  name: tag?.name || '',
  kind: tag?.kind || '',
  color: tag?.color || '',
  isPublic: tag?.public || false,
  isImportant: tag?.important || false
});

export const defaultColumns = (timezone) => [
  {
    field: 'name',
    headerName: 'Tag',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ data }) => {
      return (
        <div className="flex items-center gap-2">
          <Tippy content={data?.name} className="tippy-dark" placement="top">
            <p className="truncate font-500">{data?.name}</p>
          </Tippy>
          {data?.important && (
            <Tippy
              content="This tag has been market as important!"
              className="tippy-dark"
              placement="top">
              <div>
                <Icon icon="new-info-circle" color="neutral" shade={900} />
              </div>
            </Tippy>
          )}
        </div>
      );
    }
  },
  {
    field: 'kind',
    headerName: 'Type',
    cellClass: 'no-border',
    flex: 1,
    valueFormatter: ({ value }) => (value ? Capitalize(value) : '-')
  },
  {
    field: 'public',
    headerName: 'Privacy',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: ({ value }) => {
      return value ? 'Public' : 'Private';
    }
  },
  {
    field: 'user',
    headerName: 'Created By',
    cellClass: 'no-border',
    flex: 1,
    valueFormatter: ({ value }) => (value ? value?.f_name + ' ' + value?.l_name : '-')
  },
  {
    field: 'created_at',
    headerName: 'Created Date',
    cellClass: 'no-border',
    flex: 1,
    valueFormatter: ({ value }) => (value ? formatDate(value, timezone) : '-')
  },
  {
    field: 'color',
    headerName: 'Color',
    cellClass: 'no-border',
    sortable: false,
    flex: 1,
    cellRenderer: ({ value }) => <TagColor color={value} options={tagColorOptions} />
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const defaultFilters = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Created Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  name: {
    type: 'search',
    values: null,
    title: 'Tag Name',
    placeholder: 'Search name'
  },
  provider: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  type: {
    type: 'checkbox',
    values: [],
    options: tagType,
    title: 'Tag Type'
  },
  privacy: {
    type: 'checkbox',
    values: [],
    options: tagPrivacy,
    title: 'Tag Privacy'
  }
};

export const getChangedTagFields = (initialTag, shapedTagObject) => {
  const changedFields = {};
  Object.keys(shapedTagObject).forEach((key) => {
    let mappedKey = key;
    if (key === 'isPublic') {
      mappedKey = 'public';
    } else if (key === 'isImportant') {
      mappedKey = 'important';
    }

    if (initialTag[key] !== shapedTagObject[key]) {
      changedFields[mappedKey] = shapedTagObject[key];
    }
  });
  return changedFields;
};
