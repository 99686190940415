import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';

import { getTemplatesPopoverOptions } from '../lib/helpers';

const MyScribeAILink = () => {
  const { pathname } = useLocation();
  const { id: userId, appointmentId } = useParams();
  const navigate = useNavigate();

  const onNavigate = () => {
    if (pathname.includes('myscribe-ai/')) {
      navigate(`/portal/charts/${userId}/clinical-notes/${appointmentId}`);
    }
  };

  if (pathname.includes('myscribe-ai')) {
    return (
      <div className="flex cursor-pointer select-none items-center" onClick={onNavigate}>
        <Icon icon="chevron-left" color="primary" />
        <div className="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text !text-base pl-3 font-medium leading-snug text-transparent">
          Go back to Clinical Templates
        </div>
      </div>
    );
  }

  return (
    <Popover
      isDropdown
      text="Go to MyScribe AI (Beta)"
      textClassName="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text !text-base font-medium leading-snug text-transparent"
      buttonTheme="transparent"
      icon="new-myscribe-triple-stars"
      options={getTemplatesPopoverOptions({ navigate, userId, appointmentId })}
    />
  );
};

export default MyScribeAILink;
