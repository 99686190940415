import { useQueryClient } from '@tanstack/react-query';
import MedicationsSimpleTable from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/MedicationsSimpleTable';
import { createMedicationNarrative } from 'components/shared/Forms/Custom/lib/medicationNarrativeHelper';
import { MEDICATION_STATUS } from 'components/shared/Forms/Custom/ProviderMedications/lib/constants';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import React from 'react';
import { useParams } from 'react-router-dom';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';

const Medications = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData, clinicalNote } = customClinicalNote ?? useClinicalNoteContext();
  const {
    isNarrativeChecked,
    isTemplateChecked,
    isActiveMedicationsChecked,
    isInactiveMedicationsChecked
  } = checkedFormTypes || {};

  const { id: patientId } = useParams();

  const queryClient = useQueryClient();
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'medications',
        patientId: patientId ?? clinicalNote?.user_id
      },
      dependencies: [patientId ?? clinicalNote?.user_id, 'medications'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          queryClient.setQueryData(['patientAggregateNarrative', patientId, 'medications'], () => ({
            data: {
              narrative: {
                narrative: createMedicationNarrative(iaRa(overviewData?.medicationHistory)),
                synced: true
              }
            }
          }));
        }
      }
    });

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (customClinicalNote && !ia(overviewData?.medicationHistory)) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">Medications</h3>

      {isActiveMedicationsChecked && (
        <MedicationsSimpleTable
          data={overviewData?.medicationHistory.filter(
            (item) => item.status === MEDICATION_STATUS.ACTIVE
          )}
          emptyState="No active medications"
        />
      )}

      {isInactiveMedicationsChecked && (
        <MedicationsSimpleTable
          data={overviewData?.medicationHistory.filter(
            (item) => item.status === MEDICATION_STATUS.INACTIVE
          )}
          emptyState="No inactive medications"
        />
      )}

      {isTemplateChecked && <MedicationsSimpleTable data={overviewData?.medicationHistory} />}

      {/* {isTemplateChecked &&
        medications?.map((item, idx) => (
          <div
            className="grid grid-cols-4 gap-4 gap-y-4 border-0 !border-t-[1px] !border-solid !border-neutral-200 !py-4 first-of-type:!border-t-0"
            key={idx}>
            {item?.medication && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Medication</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.medication}
                </p>
              </div>
            )}

            {item?.sig && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">SIG</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.sig}
                </p>
              </div>
            )}

            {item?.strength && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Strength</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.strength}
                </p>
              </div>
            )}

            {item?.quantity && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Quantity</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.quantity}
                </p>
              </div>
            )}

            {item?.days_supply && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Days supply</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.days_supply}
                </p>
              </div>
            )}

            {item?.refills && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Refills</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.refills}
                </p>
              </div>
            )}

            {ia(item?.condition) && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Condition</p>
                <ul>
                  {item?.condition?.map((condition, index) => (
                    <li
                      key={index}
                      className="list-inside list-disc text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                      {condition?.value}
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {item?.prescriber && (
              <div className="col-start-1 col-end-4 grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">
                  Prescribing physician
                </p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.prescriber?.value}
                </p>
              </div>
            )}

            {item?.start_date && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Date started</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {moment(item?.dateStarted).format('MMM Do, YYYY')}
                </p>
              </div>
            )}

            {item?.end_date && (
              <div className="grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Date ended</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {moment(item?.dateEnded).format('MMM Do, YYYY')}
                </p>
              </div>
            )}

            {item?.note && (
              <div className="col-start-1 col-end-4 grid">
                <p className="text-xs font-500 text-neutral-700 print:text-base">Note</p>
                <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize print:text-base">
                  {item?.note}
                </p>
              </div>
            )}
          </div>
        ))} */}

      {isNarrativeChecked && !!ia(overviewData?.medicationHistory) && (
        <div
          className={
            (!isTemplateChecked && '!mt-2', 'text-xs font-500 text-neutral-700 print:text-base')
          }>
          {/* {createMedicationNarrative(overviewData?.medicationHistory)} */}
          <SanitizeComponent value={patientNarrative?.narrative} />
        </div>
      )}
    </div>
  );
};

export default Medications;
