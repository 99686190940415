import React from 'react';

import { isEmpty } from '../../../../../../../../lib/helpers/utility';

const OrderDetails = ({ itemProps }) => {
  if (
    isEmpty(itemProps?.order_accno) &&
    isEmpty(itemProps?.order_collector) &&
    isEmpty(itemProps?.order_rcv_dt) &&
    isEmpty(itemProps?.order_location)
  ) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Order Details</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {!isEmpty(itemProps?.order_accno) && (
            <div>
              <strong>Order Account Number:</strong> {itemProps?.order_accno}
            </div>
          )}

          {!isEmpty(itemProps?.order_collector) && (
            <div>
              <strong>Order Collector:</strong> {itemProps?.order_collector}
            </div>
          )}

          {!isEmpty(itemProps?.order_rcv_dt) && (
            <div>
              <strong>Order Receive Date:</strong> {itemProps?.order_rcv_dt}
            </div>
          )}

          {!isEmpty(itemProps?.order_location) && (
            <div>
              <strong>Order Location:</strong> {itemProps?.order_location}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default OrderDetails;
