import NotFound from 'components/practice/Dashboard/components/NotFound';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { ia } from 'lib/helpers/utility';
import React from 'react';
import TruncatedLabel from '../../Codes/components/TruncatedLabel';

const ListICD10Codes = ({
  data = [],
  selectedListRef = null,
  onClickDelete = () => null,
  isFetching = null
}) => {
  if (isFetching) {
    return <Skeleton count={10} height="54px" />;
  }

  if (!ia(data)) {
    return (
      <NotFound
        title="No Diagnosis Codes added"
        description="Search and add from the left panel"
        icon="new-no-cpt-codes-illustration"
        className="h-full !p-0"
      />
    );
  }

  return data.map((row, index) => (
    <div
      key={index}
      ref={(el) => (selectedListRef.current[row?.value] = el)}
      className="mt-4 flex items-center justify-between gap-2 rounded-md border border-solid border-neutral-100 bg-white !py-4 px-3 text-sm text-neutral-900 transition-all first-of-type:!mt-0 hover:!border-primary-200">
      <span className="max-w-fit overflow-hidden text-ellipsis whitespace-nowrap">
        <TruncatedLabel label={row?.label} />
      </span>

      <div data-qa={`delete-icd-${index}`} onClick={() => onClickDelete(row)}>
        <Icon icon="new-trash-patient" color="neutral" className="cursor-pointer" />
      </div>
    </div>
  ));
};

export default ListICD10Codes;
