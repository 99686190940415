import React from 'react';
import { useParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, isEmpty, stripHtmlTags } from 'lib/helpers/utility';
import { usePatientAggregateNarrative } from 'lib/hooks/queries/patientAggregateNarrative/usePatientAggregateNarrative';
import { usePatientWeightLossGoal } from 'lib/hooks/queries/patients/usePatientWeightLossGoal';

import practiceState from 'components/practice/practiceState';
import { weightLossItemFormatter } from 'components/shared/Forms/Custom/Provider/WeightLoss/lib/narrativeHelper';
import { transformData } from 'components/shared/Forms/Custom/lib/aggregatedDataHelper';
import { createWeightLossNarrative } from 'components/shared/Forms/Custom/lib/weightLossAdministrationNarrativeHelper';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { clinicalNote as clinicalNoteState } from 'components/state';

import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { filterTodayOnly } from '../../lib/renderedHelper';

import WeightLossAdministrationSimpleTable from './WeightLossAdministrationSimpleTable';

const WeightLossAdministration = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  const { overviewData } = customClinicalNote ?? useClinicalNoteContext();
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const { isNarrativeChecked, isTemplateChecked, isTodaysDataOnlyChecked } = checkedFormTypes;
  const { id: patientId } = useParams();
  const { timezone } = useRecoilValue(practiceState.currentPractice);
  const patient = patientId ?? String(clinicalNote?.user_id);
  const queryClient = useQueryClient();

  const { data } = usePatientWeightLossGoal({
    params: {
      patientId
    },
    dependencies: [patientId]
  });

  const patientWeightLossGoal = data?.patientWeightLossGoal;
  const { data: patientNarrative, isFetching: patientNarrativeFetching } =
    usePatientAggregateNarrative({
      params: {
        user_type: 'provider',
        form_type: 'weightLossAdministration',
        patientId: patient
      },
      dependencies: [patient, 'weightLossAdministration'],
      options: {
        enabled: isNarrativeChecked,
        select: (response) => response?.data?.narrative,
        onSettled: (narrativeObj) => {
          if (narrativeObj?.id) {
            return;
          }

          const weightLossData = transformData({
            data: overviewData?.weightLossAdministration,
            formType: 'weightLossAdministration',
            validation: 'medication'
          });
          const formattedData = weightLossItemFormatter(weightLossData, patientWeightLossGoal);
          queryClient.setQueryData(
            ['patientAggregateNarrative', patient, 'weightLossAdministration'],
            () => ({
              data: {
                narrative: {
                  narrative: createWeightLossNarrative(formattedData),
                  synced: true
                }
              }
            })
          );
        }
      }
    });

  const isPatientNarrativeEmpty = isEmpty(stripHtmlTags(patientNarrative?.narrative)?.trim());

  if (patientNarrativeFetching) {
    return <Skeleton />;
  }

  if (
    customClinicalNote &&
    !ia(overviewData?.weightLossAdministration) &&
    isPatientNarrativeEmpty
  ) {
    return null;
  }
  const tableData = overviewData?.weightLossAdministration?.reduce((prev, curr) => {
    return [...prev, ...(curr?.response?.row || [])];
  }, []);

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B] print:text-xl">
        Weight loss administration
      </h3>
      {isTodaysDataOnlyChecked && (
        <WeightLossAdministrationSimpleTable
          data={filterTodayOnly(timezone, overviewData?.weightLossAdministration, 'createdAt')}
          emptyState="No medications added today"
          fromTodaysData={true}
        />
      )}
      {isTemplateChecked && <WeightLossAdministrationSimpleTable data={tableData} />}

      {isNarrativeChecked && !isPatientNarrativeEmpty && (
        <div className={!isTemplateChecked && '!mt-2'}>
          <SanitizeComponent value={patientNarrative?.narrative} />
        </div>
      )}
    </div>
  );
};

export default WeightLossAdministration;
