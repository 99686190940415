import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteCustomFormResponse } from 'api/CustomForms';
import { createMedication, deleteMedication, upsertWeightLossSync } from 'api/Medication';
import SearchProviders from 'components/Search/SearchProviders/SearchProviders';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Modal from 'components/shared/Modal/Modal';
import ModalFooter from 'components/shared/Modal/ModalFooter/ModalFooter';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState, permissions } from 'components/state';
import { useFormik } from 'formik';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { formatDate, iaRa } from 'lib/helpers/utility';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SelectMedicationTable from '../../CurrentMedications/components/SelectMedicationTable';
import { isMedicationReadOnly } from '../../lib/aggregatedDataHelper';
import { setPatientAggregateNarrativeState } from '../../Provider/lib/patientAggregateNarrativeStates';
import { translateMedicationToWeightLossDatav2 } from '../../Provider/WeightLoss/lib/helpers';
import { MEDICATION_STATUS, UNIT_OPTIONS } from '../lib/constants';
import {
  createObjectWithKeys,
  formatMedicationDataForUpsert,
  injectionRoutes,
  medicationRouteOptions
} from '../lib/helpers';
import { ProviderMedicationSchema } from '../lib/providerMedicationSchema';

const UpsertProviderMedication = ({
  showModal = false,
  hideModal = () => null,
  disabled = false,
  fromWeightLoss = false,
  showPreviewMedicationFromWhl = false,
  setShowPreviewMedicationFromWhl = () => {}
}) => {
  const { id: patientId, appointmentId } = useParams();
  const { setOverviewData } = useClinicalNoteContext();
  const navigate = useNavigate();
  const userPermissions = useRecoilValue(permissions);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const [showAdditionalFields, setShowAdditionalFields] = useState(false);
  const queryClient = useQueryClient();
  const medicationReadOnly = isMedicationReadOnly({
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents,
    data: showModal?.data,
    noteLocked: clinicalNote?.locked
  });

  useEffect(() => {
    if (showModal?.data?.route) {
      setShowAdditionalFields(checkIfWeNeedTheAdditionalFields(showModal?.data?.route));
    }
  }, []);

  const formik = useFormik({
    initialValues: createObjectWithKeys(
      showModal?.data,
      fromWeightLoss,
      showPreviewMedicationFromWhl
    ),
    enableReinitialize: true,
    validationSchema: disabled || medicationReadOnly ? null : ProviderMedicationSchema,
    onSubmit: async (values) => {
      const copiedValues = structuredClone(values);

      const formattedMedData = formatMedicationDataForUpsert(copiedValues, fromWeightLoss);
      if (fromWeightLoss || formattedMedData?.weight_loss_medication_id) {
        const formattedWhlData = translateMedicationToWeightLossDatav2(formattedMedData);
        await upsertWeightLossSyncMutation.mutateAsync({
          patientId,
          appointmentId,
          medication: { medications: [formattedMedData] },
          response: { row: [formattedWhlData] },
          type: 'weightLossAdministration',
          formId: formattedMedData?.weight_loss_medication_id || formik?.values?.cfr_id
        });
      } else {
        await upsertFormFunctionMutation.mutateAsync({
          patientId,
          appointmentId,
          medication: { medications: [formattedMedData] }
        });
      }
    }
  });
  const handleCurrentUnitChange = (unit) => {
    formik.setFieldValue('unit', unit);
  };

  const upsertWeightLossSyncMutation = useMutation((data) => upsertWeightLossSync(navigate, data), {
    onSettled: async (data) => {
      const { code, error = null, newResponse } = data || {};
      if (code !== 0) {
        showAlert({
          title: 'Medication',
          message: error ?? 'Medication creation failed! Please contact support.',
          color: 'danger'
        });

        hideModal();
        return;
      }
      if (formik.values.weight_loss_medication_id || formik.values.cfr_id) {
        setOverviewData((prev) => ({
          ...prev,
          weightLossAdministration: prev?.weightLossAdministration?.map((weightLoss) => {
            if (weightLoss?.id === newResponse?.id) {
              return newResponse;
            }
            return weightLoss;
          })
        }));
      } else {
        setOverviewData((prev) => ({
          ...prev,
          weightLossAdministration: [newResponse, ...iaRa(prev?.weightLossAdministration)]
        }));
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['medications'] }),
        queryClient.invalidateQueries({ queryKey: ['medication'] })
      ]);
      hideModal();
    }
  });
  const upsertFormFunctionMutation = useMutation((data) => createMedication(navigate, data), {
    onSettled: async (data) => {
      const { code, error = null } = data || {};

      if (code !== 0) {
        showAlert({
          title: 'Medication',
          message: error ?? 'Medication creation failed! Please contact support.',
          color: 'danger'
        });

        hideModal();
        return;
      }

      const noKnown = await queryClient.getQueryData([
        'patientAggregateNarrative',
        patientId,
        'medications'
      ])?.data?.narrative?.no_known;

      if (patientId && noKnown) {
        await setPatientAggregateNarrativeState({
          queryClient,
          keys: [patientId, 'medications'],
          payload: {
            synced: true,
            no_known: false
          }
        });
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ['medications'] }),
        queryClient.invalidateQueries({ queryKey: ['medication'] })
      ]);
      hideModal();
    }
  });

  const mutateDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMedication(navigate, {
        medicationId: fromWeightLoss ? showModal?.data?.medication_id : showModal?.data?.id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }

      showAlert({
        title: 'Medication',
        message: 'Medication deleted successfully',
        color: 'success'
      });

      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });
  const mutatateDeleteCustomFormResponse = useMutation({
    mutationFn: () =>
      deleteCustomFormResponse(navigate, {
        customFormId: showModal?.data?.weight_loss_medication_id || showModal?.data?.cfr_id
      }),
    onSuccess: async ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Delete Medication',
          message: error,
          color: 'warning'
        });
        return;
      }

      const idToDelete = showModal?.data?.weight_loss_medication_id || showModal?.data?.cfr_id;
      setOverviewData((prev) => ({
        ...prev,
        weightLossAdministration: prev?.weightLossAdministration?.filter(
          (weightLoss) => weightLoss?.id !== idToDelete
        )
      }));
      queryClient.invalidateQueries(['medications']);
      queryClient.invalidateQueries(['medication']);
    }
  });

  const onHandleDelete = async () => {
    if (showModal?.data?.medication_id || (showModal?.data?.id && !fromWeightLoss)) {
      await mutateDeleteMedication.mutateAsync();
    }
    if (showModal?.data?.weight_loss_medication_id || showModal?.data?.cfr_id) {
      await mutatateDeleteCustomFormResponse.mutateAsync();
    }

    hideModal();
    setConfirmOpen(false);
  };
  const handleCloseFromMedicationPreviewFromWeightLoss = () => {
    hideModal();
    setShowPreviewMedicationFromWhl(false);
  };

  const handleSelectMedication = async (medication) => {
    formik.setFieldValue('medication', capitalize(medication?.generic_name));
    if (medication?.product_ndc) {
      formik.setFieldValue('product_ndc', medication?.product_ndc);
    }
    if (medication?.product_id) {
      formik.setFieldValue('product_ndc_id', medication?.product_id);
    }
    if (medication?.route) {
      formik.setFieldValue('route', medication?.route[0]);
    }
  };
  const checkIfWeNeedTheAdditionalFields = (selectedRoute) => {
    return injectionRoutes.includes(selectedRoute);
  };
  return (
    <Modal
      handleOpen={showModal?.open}
      handleClose={
        showPreviewMedicationFromWhl ? handleCloseFromMedicationPreviewFromWeightLoss : hideModal
      }
      className="max-h-[90dvh] w-[1160px]"
      bodyClassName="bg-primary-10"
      modalCenterAnimation={true}
      isFooter={false}
      customHeader={
        <div className="flex w-full items-center justify-between">
          <Breadcrumb
            activeClassName="text-primary-700"
            breadcrumb={{
              setStep: () => {},
              step: 1,
              hasBackButton: false,
              steps: [
                { title: 'Medication' },
                {
                  title:
                    formik?.values?.id || formik?.values?.cfr_id
                      ? 'Update Medication'
                      : 'Create Medication'
                }
              ]
            }}
            key="breadcrumb"
          />
        </div>
      }
      isFooterSticky>
      <form className="lg:px-[230px] xl:px-[230px]">
        {medicationReadOnly && (
          <AlertContent
            title="Restricted: Read-Only Data Access"
            message="Editing this area is currently restricted. Contact your internal systems admin if needed."
            color="warning"
            width="full"
            className="!mb-4"
          />
        )}

        <div>
          <label className="text-sm font-500 text-neutral-800">
            Is this medication currently active or inactive?
          </label>
          <span className="text-danger-500">*</span>

          <div className="!mt-3 flex gap-4">
            <Checkbox
              label="Active medication"
              rounded={true}
              handleClick={() => formik.setFieldValue('status', MEDICATION_STATUS.ACTIVE)}
              isChecked={formik.values?.status === MEDICATION_STATUS.ACTIVE}
              disabled={disabled || medicationReadOnly}
            />
            <Checkbox
              label="Inactive medication"
              rounded={true}
              handleClick={() => formik.setFieldValue('status', MEDICATION_STATUS.INACTIVE)}
              isChecked={formik.values?.status === MEDICATION_STATUS.INACTIVE}
              disabled={disabled || medicationReadOnly}
            />
          </div>

          {formik.errors?.status && (
            <p className="!pt-2 text-left text-sm text-danger-500">{formik.errors?.status}</p>
          )}
        </div>
        <div className="relative !mt-4 grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
          <SelectMedicationTable
            medication={formik?.values?.medication}
            handleSelectMedication={handleSelectMedication}
            tableClassName="top-[76px]"
            required
            errorInput={formik?.errors?.medication}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="NDC"
            name="product_ndc"
            value={formik?.values?.product_ndc}
            onChange={formik.handleChange}
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="mt-4">
          <Textarea
            isEditor
            label="SIG"
            name="sig"
            value={formik?.values?.sig}
            onChange={(event) => formik.setFieldValue('sig', event)}
            className="min-h-[150px]"
            disabled={disabled || medicationReadOnly}
          />
        </div>

        <div className="mt-4 grid grid-cols-5 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <div className="col-span-2 sm:col-span-1 md:col-span-1">
            <Input
              label="Unit"
              name="strength"
              value={formik?.values?.strength}
              onChange={(event) => formik.setFieldValue(`strength`, event?.target?.value)}
              units={UNIT_OPTIONS}
              currentUnit={formik?.values?.unit}
              onUnitChange={handleCurrentUnitChange}
              unitPanelClassName="left-0 !z-[9999] h-[300px] overflow-y-scroll"
              error={formik?.errors?.strength || formik?.errors?.unit}
              disabled={disabled || medicationReadOnly}
            />
          </div>
          <Input
            label="Quantity"
            value={formik?.values?.quantity}
            type="number"
            onChange={(event) =>
              formik.setFieldValue(
                'quantity',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            error={formik?.errors?.quantity}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="Days Supply"
            value={formik?.values?.days_supply}
            type="number"
            onChange={(event) =>
              formik.setFieldValue(
                'days_supply',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            error={formik?.errors?.days_supply}
            disabled={disabled || medicationReadOnly}
          />
          <Input
            label="Refills"
            value={formik?.values?.refills}
            type="number"
            onChange={(event) =>
              formik.setFieldValue(
                'refills',
                event?.target?.value ? Number(event?.target?.value) : null
              )
            }
            error={formik?.errors?.refills}
            disabled={disabled || medicationReadOnly}
          />
        </div>
        <div className="!mt-4 grid grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-4">
          <Select
            label="Route"
            isCreatable={true}
            parentClassName="!mt-4"
            onChange={(e) => {
              const selectedRoute = e?.value;
              formik.setFieldValue('route', selectedRoute);
              const shouldShowAdditionalFields = checkIfWeNeedTheAdditionalFields(selectedRoute);
              setShowAdditionalFields(shouldShowAdditionalFields);
            }}
            value={{
              value: formik?.values?.route,
              label: formik?.values?.route
            }}
            options={medicationRouteOptions.map((route) => {
              return {
                value: route,
                label: route
              };
            })}
            placeholder='Select "Route'
            disabled={disabled || medicationReadOnly}
            isClearable={false}
          />
          {showAdditionalFields && (
            <Input
              className="!mt-4"
              label="Location"
              placeholder="Add location"
              value={formik?.values?.location}
              onChange={(event) => formik.setFieldValue('location', event.target.value)}
              disabled={disabled}
            />
          )}
          {fromWeightLoss && (
            <Input
              label="Weight"
              className="!mt-4"
              placeholder="Add weight"
              value={formik?.values?.weight}
              onChange={(event) => formik.setFieldValue('weight', event.target.value)}
              disabled={disabled}
              rightText="lbs"
            />
          )}

          <Select
            inputId="select-condition"
            label="Condition"
            parentClassName="!mt-4"
            id="condition"
            value={formik?.values?.condition}
            name="condition"
            onChange={(event) => formik?.setFieldValue('condition', event)}
            isCreatable
            isClearable
            isMulti
            components={{ DropdownIndicator: null }}
            placeholder="Type and select condition"
            disabled={disabled || medicationReadOnly}
          />
          <SearchProviders
            label="Prescriber"
            id="prescribingPhysician"
            parentClassName="!mt-4"
            placeholder="Prescriber"
            value={formik?.values?.prescriber}
            onChange={(event) => formik?.setFieldValue('prescriber', event)}
            disabled={disabled || medicationReadOnly}
          />

          <DatePopover
            label="Start Date"
            name="start_date"
            className="!mt-4"
            onChange={(date) =>
              formik.setFieldValue('start_date', formatDate(date, undefined, true))
            }
            value={formik.values?.start_date}
            placementY="bottom-[120%] z-[999] right-0 border border-solid border-neutral-100"
            error={formik?.errors?.start_date}
            disabled={disabled || medicationReadOnly}
          />
          {formik.values?.status === MEDICATION_STATUS.INACTIVE && (
            <DatePopover
              label="End Date"
              name="end_date"
              className="!mt-4"
              onChange={(date) =>
                formik.setFieldValue('end_date', formatDate(date, undefined, true))
              }
              value={formik.values?.end_date}
              placementY="bottom-[120%] z-[999] right-0 border border-solid border-neutral-100"
              error={formik?.errors?.end_date}
              disabled={disabled || medicationReadOnly}
            />
          )}
        </div>

        <div className="mt-4">
          <Textarea
            isEditor
            label={
              <div className="flex gap-1">
                <span className="text-sm font-500 text-neutral-800">Note</span>
                <span className="test-sm text-neutral-500">(Optional)</span>
              </div>
            }
            name="note"
            value={formik?.values?.note}
            onChange={(event) => formik.setFieldValue('note', event)}
            className="min-h-[150px]"
            disabled={disabled || medicationReadOnly}
          />
        </div>
      </form>
      <ModalFooter
        className="z-50"
        leftButtons={
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={
              showPreviewMedicationFromWhl
                ? handleCloseFromMedicationPreviewFromWeightLoss
                : hideModal
            }
          />
        }
        rightButtons={
          <div className="flex gap-2">
            {(formik?.values?.id || formik?.values?.cfr_id) && (
              <>
                <Button
                  text="Delete"
                  disabled={disabled || mutateDeleteMedication?.isLoading || medicationReadOnly}
                  color="danger"
                  onClick={() => {
                    setItemToDelete(
                      fromWeightLoss
                        ? formik?.values?.medication_id || formik?.values?.cfr_id
                        : formik?.values?.id
                    );
                    setConfirmOpen(true);
                  }}
                />
                {itemToDelete && (
                  <Confirm
                    icon="new-info"
                    iconColor="danger"
                    iconSize={42}
                    variant="danger"
                    primaryBtnTxt="Delete"
                    title="Delete Medication"
                    message={
                      fromWeightLoss
                        ? 'Are you sure you want to delete this medication?. Deleting it here will also remove it from the patient chart.'
                        : 'Are you sure you want to delete this medication?'
                    }
                    handleContinue={onHandleDelete}
                    handleOpen={confirmOpen}
                    handleClose={() => setConfirmOpen(false)}
                    disabled={
                      mutateDeleteMedication?.isLoading ||
                      mutatateDeleteCustomFormResponse?.isLoading
                    }
                    loading={
                      mutateDeleteMedication?.isLoading ||
                      mutatateDeleteCustomFormResponse?.isLoading
                    }
                  />
                )}
              </>
            )}
            <Button
              text={formik?.values?.id || formik?.values?.cfr_id ? 'Update' : 'Create'}
              data-qa="upsert-btn"
              onClick={() => formik?.submitForm()}
              loadingIcon={
                upsertFormFunctionMutation?.isLoading || upsertWeightLossSyncMutation?.isLoading
              }
              disabled={
                upsertFormFunctionMutation?.isLoading ||
                upsertWeightLossSyncMutation?.isLoading ||
                disabled ||
                medicationReadOnly
              }
            />
          </div>
        }
      />
    </Modal>
  );
};

export default UpsertProviderMedication;
