import React, { useCallback, useEffect, useState } from 'react';
import JsonView from 'react18-json-view';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { recheckTransaction } from 'api/Transaction';

import { ia, iaRa } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Modal from 'components/shared/Modal/Modal';

const TransactionsRawResponsesModal = ({ transactions }) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [txnId, setTxnId] = useState(null);
  const [selectedTxn, setSelectedTxn] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const txnResponseId = parseInt(searchParams.get('txnResponseId'));
    if (!isNaN(txnResponseId)) {
      setTxnId(txnResponseId);
      if (ia(transactions)) {
        setSelectedTxn(iaRa(transactions).find((t) => t.id === txnResponseId));
      }
    } else {
      setTxnId(null);
    }
  }, [searchParams, transactions]);

  const transactionCheck = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    try {
      const newTxn = await recheckTransaction(navigate, { transactionId: txnId });
      if (!newTxn?.transaction) {
        throw newTxn;
      }
      setSelectedTxn(newTxn?.transaction);
    } catch (e) {
      showAlert({
        color: 'warning',
        title: 'An error while checking transaction status has occurred',
        message: JSON.stringify(e)
      });
    }
    setLoading(false);
  }, [txnId, loading]);

  return (
    <Modal
      slideFromRight
      className="w-3/4"
      title={`Raw Responses ${txnId}`}
      handleOpen={typeof txnId === 'number'}
      handleClose={() => setSearchParams((ps) => ps.delete('txnResponseId'))}>
      <Button
        iconRight="rotate-ccw"
        iconRightColor="primary"
        iconRightIsStroke
        outlined
        text="Check New Transaction Status"
        onClick={transactionCheck}
      />
      {iaRa(selectedTxn?.raw_response).map((rawResponse, index) => (
        <div key={`transaction-${txnId}-raw-response-index-${index}`}>
          {index && <hr />}
          <JsonView className="json-tree-view" src={rawResponse} collapsed={false} />
        </div>
      ))}
    </Modal>
  );
};

export default withErrorBoundary(TransactionsRawResponsesModal);
