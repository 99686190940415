import { currentPractice } from 'components/practice/practiceState';
import Button from 'components/shared/Buttons/Button';
import Select from 'components/shared/Select/Select';
import { formatDateAndTimeZ, iaRa } from 'lib/helpers/utility';
import { useClinicalNote } from 'lib/hooks/queries/clinicalNotes/useClinicalNotes';
import { usePatient } from 'lib/hooks/queries/patients/usePatient';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { clinicalNoteFilters } from '../lib/helpers';
const VitalOverviewHeader = ({
  patientId,
  assignToCn,
  fromOverview,
  setAssignToCn,
  setVitals,
  vitals
}) => {
  const handleChangeCn = (appointmentId) => {
    setVitals((prevVitals) => ({
      ...prevVitals,
      appointment_id: appointmentId
    }));
  };
  const practice = useRecoilValue(currentPractice);
  const { data } = usePatient({
    params: { patientId },
    dependencies: [Number(patientId)]
  });
  const patient = data?.patient;
  const filters = clinicalNoteFilters(patient);

  const { data: clinicalNotes } = useClinicalNote({
    params: { ...filters, patientId },
    dependencies: [patientId, filters]
  });
  const clincal_notes = clinicalNotes?.clinicalNote;
  const findAssociatedClincalNote = (appointmentId) => {
    const option = iaRa(clincal_notes).find((note) => note?.appointment?.id === appointmentId);
    return {
      label: formatDateAndTimeZ(option?.appointment.starts_at, practice?.timezone),
      value: option?.appointment?.id
    };
  };
  return (
    <>
      {!assignToCn && fromOverview && (
        <Button
          onClick={() => setAssignToCn(true)}
          text="Assign to a Clinical Note"
          className="ml-5 mr-auto"
          data-qa="assign-to-cn"
        />
      )}

      {assignToCn && fromOverview && (
        <Select
          inputId="appointment_id"
          parentClassName="mt-3"
          options={iaRa(clincal_notes).map((note) => ({
            label: formatDateAndTimeZ(note?.appointment.starts_at, practice?.timezone),
            value: note?.appointment?.id
          }))}
          label="Select Clincal Note to assign"
          value={vitals?.appointment_id ? findAssociatedClincalNote(vitals?.appointment_id) : null}
          onChange={(option) => {
            handleChangeCn(option ? option?.value : null);
          }}
        />
      )}
    </>
  );
};
export default VitalOverviewHeader;
