import React from 'react';
import { Calendar } from 'react-date-range';
import { useLocation, useNavigate } from 'react-router-dom';

import { Popover } from '@headlessui/react';
import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import { insurancePatientRelation, insurance_type } from 'constants';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';

import { Capitalize, findRelationName, formatDate, isEmpty } from 'lib/helpers/utility';

import SearchPatients from 'components/Search/SearchPatients/SearchPatients';
import { currentPractice } from 'components/practice/practiceState';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import DatePicker from 'components/shared/DatePicker/DatePicker';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';

import { searchPayers } from '../lib/searchPayers';

const InsuranceForms = ({ states, formik, getPayerAddress, payers, isTablet, kiosk }) => {
  const { pathname } = useLocation();
  const practice = useRecoilValue(currentPractice);
  const isCheckinSteps = pathname?.includes('checkin/insurance');
  const navigate = useNavigate();

  const findStateName = (value) => {
    const stateName = states?.find((state) => {
      if (state?.value == value) {
        return state;
      }
    });

    return stateName && stateName?.label;
  };

  const getData = async (id) => {
    try {
      const res = await requestApi({
        url: '/api/patient/get/one',
        params: {
          id
        },
        navigate
      });
      const { patient: loadedPatient } = res;
      if (res) {
        if (loadedPatient.userAddress) {
          const address = loadedPatient.userAddress;
          formik.setValues({
            ...formik.values,
            insured_first_name: loadedPatient?.f_name,
            insured_middle_name: loadedPatient?.m_name,
            insured_last_name: loadedPatient?.l_name,
            other_relation_phone: loadedPatient?.phone,
            insured_date_of_birth: loadedPatient?.dob || moment(),
            relation_address: address.id,
            other_relation_address_ln_1: address.address_ln_1 || '',
            other_relation_address_ln_2: address.address_ln_2 || '',
            other_relation_zip: address.zip || '',
            other_relation_city: address.city || '',
            other_relation_state: address.state || ''
          });
        } else {
          formik.setValues({
            ...formik.values,
            insured_first_name: loadedPatient?.f_name,
            insured_middle_name: loadedPatient?.m_name,
            insured_last_name: loadedPatient?.l_name,
            other_relation_phone: loadedPatient?.phone,
            insured_date_of_birth: loadedPatient?.dob || moment(),
            relation_address: null,
            other_relation_address_ln_1: '',
            other_relation_address_ln_2: '',
            other_relation_zip: '',
            other_relation_city: '',
            other_relation_state: ''
          });
        }
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later. `);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  return (
    <div className={cs('grid gap-4', isTablet && 'grid-cols-2')}>
      <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-col">
        <Select
          label="Insurance type"
          placeholder="Type and select an insurance type"
          value={
            formik.values.type
              ? { value: formik.values.type, label: Capitalize(formik.values.type) }
              : null
          }
          onChange={(v) => {
            formik.setFieldValue('type', v ? v?.value : '');
          }}
          options={insurance_type}
          inputId="insuranceType"
          error={formik.errors.type}
        />
        <Select
          isAsync
          label="Insurance company"
          placeholder="Type and select an insurance company"
          value={
            isEmpty(formik.values.payer_name)
              ? null
              : {
                  label:
                    formik.values.payer_id === 'other' || !formik.values.payer_id
                      ? 'Not Shown'
                      : `${formik.values.payer_id} - ${formik.values.payer_name}`
                }
          }
          loadOptions={searchPayers}
          defaultOptions={[
            {
              label: `Not Shown`,
              value: 'other',
              payer_name: 'other'
            },
            ...(payers?.map((e) => {
              return {
                label: `Payer ID: ${e.id} - Payer Name: ${e.name}`,
                value: e.id,
                payer_name: e.name
              };
            }) || [])
          ]}
          onChange={(v) => getPayerAddress(v)}
          inputId="insuranceCompany"
          error={formik.errors.payer_id}
        />
      </div>
      <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-col">
        {isTablet && (
          <Select
            label="Relation"
            className="select-full-width"
            placeholder="Type and select a relation"
            value={
              formik.values.relation
                ? { value: formik.values.relation, label: findRelationName(formik.values.relation) }
                : null
            }
            onChange={(v) => formik.setFieldValue('relation', v ? v.value : '')}
            options={insurancePatientRelation}
            inputId="relationSelect"
            error={formik.errors.relation}
          />
        )}
        <Input
          label="Insurance member id"
          tooltip="Usually found on the front of the card"
          placeholder="Member id"
          name="member_id"
          value={formik.values.member_id}
          onChange={formik.handleChange}
          id="member_id"
          data-qa="insurance-member"
          error={formik.errors.member_id}
        />
        {!isTablet && (
          <Input
            label="Insurance group number"
            tooltip="Enter only if listed on the card"
            placeholder="Group Number"
            name="group_number"
            value={formik.values.group_number}
            onChange={formik.handleChange}
            id="group_number"
            data-qa="insurance-group-number"
          />
        )}
      </div>
      {!isTablet && (
        <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          {/* <Input
            label="Prior authorization number"
            tooltip="Box 23 on CMS-1500"
            placeholder="Prior authorization number"
            name="prior_auth"
            value={formik.values.prior_auth}
            onChange={formik.handleChange}
            id="prior_auth"
            data-qa="prior-auth-number"
          /> */}

          <Select
            label="Relation"
            className="select-full-width"
            placeholder="Type and select a relation"
            value={
              formik.values.relation
                ? {
                    value: formik.values.relation,
                    label: findRelationName(formik.values.relation)
                  }
                : null
            }
            onChange={(v) => formik.setFieldValue('relation', v ? v.value : '')}
            options={insurancePatientRelation}
            inputId="relationSelect"
            error={formik.errors.relation}
          />
        </div>
      )}

      {formik.values.relation !== '' && formik.values.relation !== '18' && (
        <div
          className={cs(
            'grid gap-y-4 rounded-lg bg-neutral-100 p-3',
            isTablet && 'col-span-2 grid-cols-5 gap-4'
          )}>
          <SearchPatients
            width="100%"
            label="Search existing patient"
            isClearable={false}
            handleClick={(e) => getData(e.value)}
          />
          <Input
            label="Insured first name"
            placeholder="Insured first name"
            name="insured_first_name"
            value={formik.values.insured_first_name}
            onChange={formik.handleChange}
            id="insured_first_name"
            data-qa="insured_first_name"
          />
          <Input
            label="Insured middle name"
            placeholder="Insured middle name"
            name="insured_middle_name"
            value={formik.values.insured_middle_name}
            onChange={formik.handleChange}
            id="insured_middle_name"
            data-qa="insured_middle_name"
          />
          <Input
            label="Insured last name"
            placeholder="Insured last name"
            name="insured_last_name"
            value={formik.values.insured_last_name}
            onChange={formik.handleChange}
            id="insured_last_name"
            data-qa="insured_last_name"
          />
          <Popover>
            {({ close }) => (
              <div className="relative">
                <Popover.Button className="w-full !p-0">
                  <Input
                    label="Insured Date of Birth"
                    rightIcon="new-calendar-gray"
                    value={
                      formik.values.insured_date_of_birth
                        ? formatDate(formik.values.insured_date_of_birth)
                        : ''
                    }
                  />
                </Popover.Button>
                <Popover.Panel className="absolute left-0 top-[110%] z-10 flex w-max bg-white p-3 shadow-md">
                  <Calendar
                    onChange={(date) => {
                      formik.setFieldValue('insured_date_of_birth', moment.utc(date).add(12, 'h'));
                      close();
                    }}
                    // className="ReactDateRange"
                    showDateDisplay
                  />
                </Popover.Panel>
              </div>
            )}
          </Popover>
          <Input
            label="Insured phone"
            placeholder="Relation phone"
            name="other_relation_phone"
            value={formik.values.other_relation_phone}
            onChange={formik.handleChange}
            id="other_relation_phone"
            data-qa="relation-phone"
          />
          <Input
            label="Address 1"
            placeholder="Address"
            name="other_relation_address_ln_1"
            value={formik.values.other_relation_address_ln_1}
            onChange={formik.handleChange}
            id="other_relation_address_ln_1"
            data-qa="other-relation-address-ln-1"
            error={formik.errors.other_relation_address_ln_1}
          />
          <Input
            label="Address 2"
            placeholder="Address 2"
            name="other_relation_address_ln_2"
            value={formik.values.other_relation_address_ln_2}
            onChange={formik.handleChange}
            id="other_relation_address_ln_2"
            data-qa="other-relation-address-ln-2"
          />
          <Input
            label="City"
            placeholder="City"
            value={formik.values.other_relation_city}
            name="other_relation_city"
            onChange={formik.handleChange}
            id="other_relation_city"
            data-qa="other-relation-city"
            error={formik.errors.other_relation_city}
          />
          <Input
            label="Zip"
            placeholder="Zip"
            value={formik.values.other_relation_zip}
            name="other_relation_zip"
            onChange={formik.handleChange}
            id="other_relation_zip"
            data-qa="other-relation-zip"
            error={formik.errors.other_relation_zip}
          />
          <Select
            label="State"
            placeholder="Type and select a state"
            value={
              formik?.values?.other_relation_state
                ? {
                    label: findStateName(formik?.values?.other_relation_state),
                    value: findStateName(formik?.values?.other_relation_state)
                  }
                : ''
            }
            onChange={(v) => {
              formik.setFieldValue('other_relation_state', v?.value);
            }}
            options={states}
            inputId="countryAddress"
            error={formik.errors.other_relation_state}
          />
        </div>
      )}

      {isTablet && (
        <div className="grid grid-cols-2 gap-4 sm:flex sm:flex-col">
          <Input
            label="Insurance group number"
            tooltip="Enter only if listed on the card"
            placeholder="Group Number"
            name="group_number"
            value={formik.values.group_number}
            onChange={formik.handleChange}
            id="group_number"
            data-qa="insurance-group-number"
          />
          {/* 
          <Input
            label="Prior authorization number"
            tooltip="Box 23 on CMS-1500"
            placeholder="Prior authorization number"
            name="prior_auth"
            value={formik.values.prior_auth}
            onChange={formik.handleChange}
            id="prior_auth"
            data-qa="prior-auth-number"
          /> */}
        </div>
      )}
      {!isCheckinSteps && (
        <div className="flex !gap-4">
          <CurrencyInput
            label="Deductible"
            data-qa="deductible"
            parentClassName="w-1/3"
            value={formik.values.deductible}
            onValueChange={(v) => formik.setFieldValue('deductible', Number(v))}
            disabled={isCheckinSteps}
          />

          <CurrencyInput
            label="Copay"
            data-qa="copay"
            parentClassName="w-1/3"
            value={+formik.values.copay}
            onValueChange={(v) => formik.setFieldValue('copay', +v)}
            disabled={isCheckinSteps}
          />

          <CurrencyInput
            prefix=""
            suffix="%"
            label="Co-Insurance"
            data-qa="co_insurance"
            parentClassName="w-1/3"
            value={formik.values.co_insurance}
            onValueChange={(v) => formik.setFieldValue('co_insurance', Number(v))}
            disabled={isCheckinSteps}
          />
        </div>
      )}
      {!kiosk && (
        <div className="sm:flex-cols grid grid-cols-2 gap-4 sm:flex">
          <DatePopover
            label="Insurance policy start date"
            placeholder="MM/DD/YYYY"
            name="policy_start_date"
            placementY="top-[-200%]"
            value={formik.values.policy_start_date}
            onChange={(date) => {
              formik.setFieldValue('policy_start_date', moment.utc(date).add(12, 'h').format());
            }}
            formik={formik}
            focusNextFieldOnEnter
          />

          <DatePopover
            label="Insurance policy end date"
            placeholder="MM/DD/YYYY"
            name="policy_end_date"
            placementY="top-[-200%]"
            placementX="right-0"
            value={formik.values.policy_end_date}
            onChange={(date) => {
              formik.setFieldValue('policy_end_date', moment.utc(date).add(12, 'h').format());
            }}
            formik={formik}
            focusNextFieldOnEnter
          />
        </div>
      )}

      <Input
        label="Prior-Auth Number"
        placeholder="Enter prior auth number"
        name="prior_auth.number"
        value={formik.values.prior_auth?.number}
        onChange={formik.handleChange}
      />
      <div className="sm:flex-cols grid grid-cols-2 gap-4 sm:flex">
        <DatePicker
          label="Prior-Auth Start Date"
          value={formik.values.prior_auth?.start_date}
          onChange={(date) => {
            formik.setFieldValue('prior_auth.start_date', date);
          }}
        />
        <DatePicker
          label="Prior-Auth End Date"
          value={formik.values.prior_auth?.end_date}
          onChange={(date) => {
            formik.setFieldValue('prior_auth.end_date', date);
          }}
        />
      </div>
    </div>
  );
};

export default InsuranceForms;
