import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { impersonate } from 'api/Admin/Practices/Members';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { formatDate, ia } from 'lib/helpers/utility';
import { useAdminMostClickedSearches } from 'lib/hooks/queries/admin/useAdminMostClickedSearches';
import useDebounce from 'lib/hooks/useDebounce';
import _ from 'lodash';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../style.scss';
import { sortHighlightMatches } from './HighlightData';

import { adminTrackClick } from 'api/Admin/Search';

const useSearch = (scrollPositionRef) => {
  const [show, setShow] = useState({ loading: false, confirmImpersonate: false });
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [confirmImpersonate, setConfirmImpersonate] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [activeTab, setActiveTab] = useState('Practices');
  const [inputValue, setInputValue] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const queryClient = useQueryClient();

  const [filters, setFilters] = useState({
    page: { Practices: 1, Practitioners: 1, Patients: 1, Forms: 1 },
    limit: 25,
    activeTab: 'Practices'
  });
  const [count, setCount] = useState({
    Practices: 0,
    Practitioners: 0,
    Patients: 0,
    Forms: 0
  });
  const [data, setData] = useState({
    Practices: [],
    Practitioners: [],
    Patients: [],
    Forms: []
  });
  const [mostClickedData, setMostClickedData] = useState({
    Practices: [],
    Practitioners: [],
    Patients: [],
    Forms: []
  });

  const navigate = useNavigate();

  const confirmButtonRef = useRef();
  const inputFieldRef = useRef();
  const divRef = useRef(null);

  const debouncedInputValue = useDebounce(inputValue, 400);

  const { isFetching: isUserMostClickedSearchesFetching, refetch } = useAdminMostClickedSearches({
    params: {
      searchType: activeTab,
      showOnlyTopSearchesClicked: true
    },
    options: {
      refetchOnMount: true,
      onSettled: (data) => {
        const searches = data?.searches?.[activeTab] || [];
        setMostClickedData((d) => ({ ...d, [activeTab]: searches }));
        if (!inputValue || inputValue === '') {
          setData((d) => ({ ...d, [activeTab]: searches }));
          setCount((c) => ({ ...c, [activeTab]: searches.length }));
        }
      },
      enabled: showSearchModal && !ia(data[activeTab])
    },
    dependencies: [activeTab]
  });

  useEffect(() => {
    if (inputValue) {
      search(debouncedInputValue);
    } else {
      setData({ ...mostClickedData });
      setCount({
        Practices: 0,
        Practitioners: 0,
        Patients: 0,
        Forms: 0,
        [activeTab]: mostClickedData[activeTab].length
      });
    }
  }, [debouncedInputValue, filters]);

  const onTabChange = useCallback((tab) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      page: { Practices: 1, Practitioners: 1, Patients: 1, Forms: 1 },
      activeTab: tab
    }));
  }, []);

  const impersonateUser = useCallback(async () => {
    try {
      await impersonate(navigate, { targetId: selectedPractitioner.value });
      const onSuccess = () => {
        refetch();
      };
      await adminTrackClick({
        navigate,
        params: { searchType: activeTab, searchId: selectedPractitioner.value },
        onSuccess
      });
      setConfirmImpersonate(false);
      setShow({ ...show, loading: false, confirmImpersonate: false });
    } catch (error) {
      console.error(error);
      Honeybadger.notify(
        `file: practitioners/PractitionersBasicInfo, method: impersonateUser - try, error: ${
          error ?? 'Theres been an error'
        }`
      );
    }
  }, [selectedPractitioner, navigate, show]);

  const handleScroll = useCallback(
    debounce(() => {
      const div = divRef.current;
      if (div && !show?.loading) {
        scrollPositionRef.current = div.scrollTop;
        if (div.scrollTop + div.clientHeight >= div.scrollHeight - 50) {
          if (data?.[activeTab].length < count[activeTab]) {
            setFilters((prev) => ({
              ...prev,
              page: { ...prev.page, [activeTab]: prev.page[activeTab] + 1 }
            }));
          } else {
            setShow({ ...show, loading: false });
          }
        }
      }
    }, 500),
    [scrollPositionRef, show, data, activeTab, count]
  );

  const handleInputChange = useCallback(
    (value) => {
      setInputValue(value);
      if (inputValue === '' || inputValue === null) {
        setShowMenu(false);
      }
    },
    [inputValue]
  );

  const clearSearch = useCallback(() => {
    setInputValue('');
    setShowMenu(false);
    setData({ ...mostClickedData });
    setCount({
      Practices: 0,
      Practitioners: 0,
      Patients: 0,
      Forms: 0,
      [activeTab]: mostClickedData[activeTab].length
    });
    setFilters({ page: { Practices: 1, Practitioners: 1, Patients: 1, Forms: 1 }, limit: 25 });
  }, []);

  const handleOnClick = useCallback(
    async ({ url, id }) => {
      scrollPositionRef.current = divRef.current.scrollTop;
      navigate(url);
      setInputValue('');
      setShowMenu(false);
      setShowSearchModal(false);
      const onSuccess = () => {
        refetch();
      };
      await adminTrackClick({
        navigate,
        params: { searchType: activeTab, searchId: id },
        onSuccess
      });
    },
    [navigate, scrollPositionRef, divRef, activeTab]
  );

  const search = useCallback(
    async (searchTerm) => {
      if (!searchTerm) {
        clearSearch();
        setShow({ ...show, loading: false });
        setFilters({ page: { Practices: 1, Practitioners: 1, Patients: 1, Forms: 1 }, limit: 25 });
      } else {
        setShowMenu(true);
        setInputValue(searchTerm);
        setShow({ ...show, loading: true });
        try {
          const res = await requestApi({
            url: '/api/admin/search/get',
            navigate: () => {},
            params: { search: searchTerm, ...filters }
          });

          const { data: loadedData, code, error, redirect } = res;

          switch (code) {
            case -1:
              navigate(redirect);
              break;

            case 0:
              const formatData = (data, category) =>
                data?.map((item) => ({
                  label: item.fullName || item.name,
                  value: item.id,
                  phone: item.phone,
                  email: item.email,
                  category
                })) || [];

              const patients = formatData(loadedData?.patients, 'patients');
              const practitioners = formatData(loadedData?.practitioners, 'practitioners');
              const practices = formatData(loadedData?.practices, 'practices');
              const forms =
                loadedData?.forms?.map((form) => ({
                  label: form.fullName || form.name,
                  value: form.id,
                  practiceId: form.practice_id,
                  practiceName: form.practice.name,
                  created_at: formatDate(form.created_at),
                  version: form.version,
                  category: 'forms'
                })) || [];
              const mergeData = (newData, existingData, key) => {
                if (!newData || !Array.isArray(newData)) return existingData || [];
                const existingIds = new Set(existingData.map((item) => item[key]));
                return [...existingData, ...newData.filter((item) => !existingIds.has(item[key]))];
              };

              let updatedAllData = { Practices: [], Practitioners: [], Patients: [], Forms: [] };
              if (!_.isEqual(data, mostClickedData)) {
                updatedAllData = { ...data };
              }

              switch (activeTab) {
                case 'Practices':
                  updatedAllData.Practices =
                    filters?.page?.Practices === 1
                      ? practices
                      : mergeData(practices, data.Practices, 'value');
                  break;
                case 'Practitioners':
                  updatedAllData.Practitioners =
                    filters?.page?.Practitioners === 1
                      ? practitioners
                      : mergeData(practitioners, data.Practitioners, 'value');
                  break;
                case 'Patients':
                  updatedAllData.Patients =
                    filters?.page?.Patients === 1
                      ? patients
                      : mergeData(patients, data.Patients, 'value');
                  break;
                case 'Forms':
                  updatedAllData.Forms =
                    filters?.page?.Forms === 1 ? forms : mergeData(forms, data.Forms, 'value');
                  break;
                default:
                  break;
              }

              updatedAllData[activeTab] = sortHighlightMatches(
                updatedAllData[activeTab],
                searchTerm
              );
              setData(updatedAllData);

              setCount({
                Practices: loadedData?.allCount?.practices || 0,
                Practitioners: loadedData?.allCount?.practitioners || 0,
                Patients: loadedData?.allCount?.patients || 0,
                Forms: loadedData?.allCount?.forms || 0
              });

              break;

            default:
              showAlert({
                title: error ? error : `Unknown error occurred while searching`,
                color: 'danger'
              });
              break;
          }
        } catch (err) {
          console.error('error', err);
        }
      }
      setShow({ ...show, loading: false });
    },
    [filters, data, mostClickedData, activeTab, show, navigate]
  );

  return {
    inputValue,
    setInputValue,
    showMenu,
    setShowMenu,
    navigate,
    confirmImpersonate,
    setConfirmImpersonate,
    show,
    setShow,
    selectedPractitioner,
    setSelectedPractitioner,
    filters,
    setFilters,
    showSearchModal,
    setShowSearchModal,
    confirmButtonRef,
    inputFieldRef,
    count,
    setCount,
    data,
    setData,
    activeTab,
    setActiveTab,
    divRef,
    debouncedInputValue,
    isUserMostClickedSearchesFetching,
    onTabChange,
    impersonateUser,
    handleScroll,
    handleInputChange,
    clearSearch,
    handleOnClick,
    search
  };
};

export default useSearch;
