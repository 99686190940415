import { useMutation, useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { medications } from 'constants';
import { ia, iaRa } from 'lib/helpers/utility';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { createMedication, deleteMedication, getMedications } from '../../../../../api/Medication';
import { socket } from '../../../../../api/Socket';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { intelligenceAiState } from '../../../../state';
import Icon from '../../../Icon/Icon';
import '../Custom.scss';
import { CurrentMedications as initialValue } from '../CustomFormInitialStates';
import ProviderMedications from '../ProviderMedications/ProviderMedications';
import Container from '../components/Container';
import Header from '../components/Header';
import SelectableButton from '../components/SelectableButton';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { deleteCustomFormCheck } from '../lib/deleteCustomFormCheck';
import { deleteFormOverviewAndHpNote } from '../lib/deleteCustomForms';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import formatMedicationsNarrative from '../lib/formatMedicationsNarrative';
import CurrentMedicationsRow from './CurrentMedicationsRow';
import { areMedicationsFilled, medicationInitialValue } from './lib';

const CurrentMedications = ({
  forwardedRef,
  defaultValue,
  setCurrentFormData,
  hpNoteOverviewRef,
  customFormsParams,
  showNarrative = false,
  onChange,
  hideNoKnownMedications = false,
  fromClinicalNote = false,
  fromOverview = false,
  setItemModal = () => {},
  options = []
}) => {
  const newMedication = { ...medicationInitialValue, id: self.crypto.randomUUID() };
  const { cnDisplaySettings, setCurrentHpOverviewData, setOverviewData, advancedFormNarrativeRef } =
    useClinicalNoteContext() || {};
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const queryClient = useQueryClient();
  const [currentMedications, setCurrentMedications] = useState(initialValue);
  const [prevCurrentMedications, setPrevCurrentMedications] = useState(initialValue);
  const [syncNarrative, setSyncNarrative] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModal2, setConfirmationModal2] = useState(false);
  const navigate = useNavigate();
  const isAdvancedForm =
    cnDisplaySettings && showNarrative
      ? cnDisplaySettings?.sections?.hp?.medications?.advancedForm
      : true;
  const textAreaRef = useRef();
  const practice = useRecoilValue(currentPractice);
  const location = useLocation();
  const fromCheckin = location?.pathname?.includes('/checkin');
  const [medicationToDelete, setMedicationToDelete] = useState(null);
  const [noDetails, setNoDetails] = useState({ current: true, past: true });

  const updateNoDetails = (type, value) => {
    const other = type === 'current' ? 'past' : 'current';
    setNoDetails({ ...noDetails, [type]: value });

    if (value === false && noDetails[other] === false) {
      handleNoDetails(true);
    } else {
      if (currentMedications?.noDetails) {
        handleNoDetails(false);
      }
      const updatedObject = {
        ...currentMedications,
        noDetails: false,
        medications: [
          ...currentMedications.medications.filter((med) => med.is_past === (type !== 'past')),
          { ...medicationInitialValue, is_past: type === 'past', id: self.crypto.randomUUID() }
        ]
      };
      setCurrentMedications(updatedObject);
      syncAllergiesAndClinicalNotes(updatedObject);
    }
  };

  const { id: patientId, appointmentId } = useParams();

  const currentAppointmentId = customFormsParams?.appointmentId || appointmentId;
  const currentPatientId = customFormsParams?.id || patientId;
  let idToDeleteClincalNoteCase;

  useEffect(() => {
    let jsonData = defaultValue;
    if (typeof defaultValue == 'string') {
      jsonData = JSON.parse(defaultValue);
    }

    if ((jsonData && ia(jsonData?.medications)) || jsonData?.noDetails) {
      setCurrentMedications(jsonData);
      setNoDetails({
        current: jsonData?.noDetails === null ? null : !jsonData.noDetails,
        past: jsonData?.noDetails === null ? null : !jsonData.noDetails
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(currentMedications);
  }, [currentMedications]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      advancedFormNarrativeRef.current = setCurrentMedications;
    }
  }, []);

  useEffect(() => {
    if (
      currentMedications.narrative !== intelligenceAi.response &&
      intelligenceAi?.path?.includes?.('medicationHistory') &&
      intelligenceAi.response !== ''
    ) {
      setCurrentMedications((ps) => ({
        ...ps,
        narrative: intelligenceAi.response ?? ps?.narrative
      }));
    }
  }, [intelligenceAi]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'CurrentMedications', type: 'custom' } });
    }
  };

  const handleAddRow = (event, isPast) => {
    event?.preventDefault();

    const newMedication = {
      ...medicationInitialValue,
      is_past: isPast,
      id: self.crypto.randomUUID()
    };

    const shouldStopProcess = doEmptyFormExist({
      newForm: newMedication,
      forms: currentMedications?.medications
    });

    if (shouldStopProcess) {
      showAlert({
        title: 'Medications',
        message: 'Empty medication exists. Please fill in the details.',
        color: 'warning'
      });
      return;
    }

    const updatedObject = {
      ...currentMedications,
      noDetails: false,
      medications: [...currentMedications.medications, newMedication]
    };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const syncAllergiesAndClinicalNotes = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ currentMedicationsObject: updatedObject });
    } else {
      setCurrentMedications(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };
  const mutateDeleteMedication = useMutation({
    mutationFn: () =>
      deleteMedication(navigate, {
        medicationId: currentMedications?.medications[0]?.id
      }),
    onSuccess: ({ code, error }) => {
      if (code !== 0) {
        showAlert({
          title: 'Medications',
          message: error,
          color: 'warning'
        });
        return;
      }
      setConfirmationModal(false);
      setCurrentMedications(initialValue);
      fromClinicalNote &&
        deleteFormOverviewAndHpNote({
          setOverviewData,
          setHpNoteData: setCurrentHpOverviewData,
          formId: currentMedications?.medications[0]?.id,
          type: 'medicationHistory'
        });
      showAlert({
        title: `Medication deleted successfully`
      });
      queryClient.invalidateQueries(['medications']);
      setItemModal();
    }
  });
  const handleDeleteRow = (id) => {
    if (!currentMedications?.medications[0]?.id && !id) {
      showAlert({
        title: 'Medications',
        message: 'Please create medication before deleting one.',
        color: 'warning'
      });
      setMedicationToDelete(null);
      setConfirmationModal(false);
      setCurrentMedications(initialValue);
      return;
    }
    const medicationRows = currentMedications?.medications || [];
    const checkIfNumber = typeof currentMedications?.medications[0]?.id === 'number';
    if ((medicationRows.length === 1 && fromOverview) || (fromClinicalNote && checkIfNumber)) {
      setConfirmationModal(true);
    } else if (medicationRows.length === 1 && fromClinicalNote) {
      setConfirmationModal2(true);
      idToDeleteClincalNoteCase = id;
    } else {
      const is_past = currentMedications?.medications?.find(
        (row) => row.id === medicationToDelete
      )?.is_past;

      let updatedObject = {
        ...currentMedications,
        medications: currentMedications.medications.filter((row) => row.id !== id)
      };

      if (currentMedications?.medications?.filter((row) => row.is_past === is_past).length === 1) {
        updatedObject = {
          ...currentMedications,
          medications: [
            ...currentMedications.medications.filter((row) => row.is_past !== is_past),
            { ...medicationInitialValue, is_past, id: self.crypto.randomUUID() }
          ]
        };
      }

      setConfirmationModal(false);
      medicationToDelete && setMedicationToDelete(null);
      syncAllergiesAndClinicalNotes(updatedObject);
    }
  };
  const fromClincalNoteDeleteCase = () => {
    setConfirmationModal2(false);
    const updatedObject = {
      ...currentMedications,
      medications: [initialValue]
    };
    setCurrentMedications(updatedObject);
    deleteFormOverviewAndHpNote({
      setOverviewData,
      setHpNoteData: setCurrentHpOverviewData,
      formId: idToDeleteClincalNoteCase,
      type: 'medicationHistory'
    });
    showAlert({
      title: `Medication deleted successfully`
    });
  };

  const handleOnChange = (key, event, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleOnChangeMulti = (values, id) => {
    const updatedArray = currentMedications?.medications?.map((row) => {
      if (row.id === id) {
        return { ...row, ...values };
      } else {
        return row;
      }
    });
    const updatedObject = { ...currentMedications, medications: updatedArray };
    syncAllergiesAndClinicalNotes(updatedObject);
  };

  const handleNoDetails = (skipConfirmation = false) => {
    if (
      areMedicationsFilled(currentMedications?.medications) &&
      !currentMedications.noDetails &&
      !skipConfirmation &&
      !fromCheckin
    ) {
      setConfirmationModal(true);
      return;
    }

    const updatedObject = {
      ...currentMedications,
      noDetails: fromCheckin ? skipConfirmation : !currentMedications.noDetails,
      medications: (fromCheckin ? !skipConfirmation : currentMedications.noDetails)
        ? [
            medicationInitialValue,
            { ...medicationInitialValue, is_past: true, id: self.crypto.randomUUID() }
          ]
        : []
    };
    setCurrentMedications(
      currentMedications.noDetails
        ? [
            medicationInitialValue,
            { ...medicationInitialValue, is_past: true, id: self.crypto.randomUUID() }
          ]
        : []
    );
    syncAllergiesAndClinicalNotes(updatedObject);
    setConfirmationModal(false);
  };

  const handleNarrative = ({
    currentMedicationsObject = currentMedications,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);
    const updatedObject = { ...currentMedicationsObject, narrative: event };

    setCurrentMedications(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon
            icon="new-clinical-narrative"
            className="cursor-pointer"
            data-qa="new-clinical-narrative"
          />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () => generateNarrative({ scrollable: true })
    }
  ];

  const generateNarrative = ({
    currentMedicationsObject = currentMedications,
    scrollable = false
  } = {}) => {
    const formattedNarrative = formatMedicationsNarrative(currentMedicationsObject.medications);
    handleNarrative({
      currentMedicationsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  const handleRefetchMedications = async () => {
    const params = {
      appointmentId: currentAppointmentId || null,
      patientId: currentPatientId
    };
    const data = await getMedications(null, params);
    setCurrentHpOverviewData &&
      setCurrentHpOverviewData((prev) => ({ ...prev, medicationHistory: data.medicationHistory }));
  };

  useEffect(() => {
    socket.on('veradigm-prescribe', () => {
      handleRefetchMedications();
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  const handleInvalidateQuery = async () => {
    queryClient.invalidateQueries({ queryKey: ['medications'] });
  };

  const currentMeds = useMemo(
    () => currentMedications?.medications?.filter((m) => !m?.is_past),
    [currentMedications?.medications]
  );

  const pastMeds = useMemo(
    () => currentMedications?.medications?.filter((m) => m?.is_past),
    [currentMedications?.medications]
  );

  return (
    <div className="CurrentMedications CustomForms">
      <input type="hidden" value={JSON.stringify(currentMedications)} ref={forwardedRef} />
      <Header
        title="Medications"
        noDetailsLabel="Are you taking any medications?"
        noDetails={noDetails?.current === null ? null : !noDetails?.current}
        isAdvancedForm
        emptyState={{
          title: 'No medications added',
          subtitle:
            'Medications include any medications you’re taking or have taken. Please share this to help us manage your care.',
          label: 'Do you have any medications not reported to our practice?'
        }}
        addRow={{
          label: 'Do you have any other medications to add?',
          onClick: (event) => handleAddRow(event, false)
        }}
        fromCheckin={fromCheckin}
        handleNoDetails={(checked) => updateNoDetails('current', !checked)}>
        {!fromCheckin && (
          <div className="ml-24 flex w-full items-start">
            <p className="mt-4 text-left text-lg text-neutral-800 ">Current medications</p>
          </div>
        )}
        <div className="flex w-full flex-col items-center gap-y-4">
          {noDetails.current &&
            isAdvancedForm &&
            currentMeds?.map((item, idx) => (
              <Container
                number={idx + 1}
                key={item.id}
                onDelete={
                  currentMedications?.medications?.filter((m) => !m?.is_past)?.length > 1 ||
                  !doEmptyFormExist({
                    newForm: newMedication,
                    forms: currentMedications?.medications
                  })
                    ? () => {
                        setMedicationToDelete(item?.id);
                        setConfirmationModal(true);
                      }
                    : null
                }
                fromCheckin={fromCheckin}>
                <CurrentMedicationsRow
                  className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                  key={item.id}
                  onChange={handleOnChange}
                  fromCheckin={fromCheckin}
                  medicationInitialValue={medicationInitialValue}
                  onChangeMulti={handleOnChangeMulti}
                  length={currentMedications?.medications?.filter((m) => !m?.is_past).length}
                  {...item}
                />
              </Container>
            ))}
        </div>
      </Header>

      <Header
        noDetailsLabel="Have you used any medications in the past that you’re no longer taking?"
        noDetails={noDetails?.past === null ? null : !noDetails?.past}
        isAdvancedForm
        className="!mt-4"
        fromCheckin={fromCheckin}
        emptyState={{
          title: 'No past medications added',
          subtitle:
            'Please let us know if you are currently taking any medications or have taken any in the past. This helps us ensure we have a complete picture of your health.',
          label: 'Have you used any medications in the past that you’re no longer taking?'
        }}
        addRow={{
          label: 'Do you have any other medications to add?',
          onClick: (event) => handleAddRow(event, true)
        }}
        handleNoDetails={(checked) => updateNoDetails('past', !checked)}>
        {!fromCheckin && (
          <div className="ml-24 flex w-full items-start">
            <p className="mt-4 text-left text-lg text-neutral-800 ">Past medications</p>
          </div>
        )}
        <div className="flex w-full flex-col items-center gap-y-4">
          {pastMeds?.map((item, idx) => (
            <Container
              number={idx + 1}
              key={item.id}
              onDelete={
                currentMedications?.medications?.filter((m) => m?.is_past)?.length > 1 ||
                !doEmptyFormExist({
                  newForm: newMedication,
                  forms: currentMedications?.medications
                })
                  ? () => {
                      setMedicationToDelete(item?.id);
                      setConfirmationModal(true);
                    }
                  : null
              }
              fromCheckin={fromCheckin}>
              <CurrentMedicationsRow
                className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
                key={item.id}
                onChange={handleOnChange}
                fromCheckin={fromCheckin}
                medicationInitialValue={{
                  ...medicationInitialValue,
                  is_past: true,
                  id: self.crypto.randomUUID()
                }}
                onChangeMulti={handleOnChangeMulti}
                length={currentMedications?.medications?.filter((m) => m?.is_past).length}
                {...item}
              />
            </Container>
          ))}
        </div>
      </Header>

      {cnDisplaySettings && showNarrative && (
        <div className="dashed-top !mt-4 !pt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setCurrentMedications}
            restData={{
              className: 'w-full',
              label: 'Current Medications Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-medication',
              'data-qa': 'narrative-medication',
              name: 'Narrative-medication',
              value: currentMedications?.narrative,
              forwardedRef: textAreaRef,
              formName: 'currentMedications'
            }}
            narrativeOptions={isAdvancedForm ? [...narrativeOptions, ...iaRa(options)] : []}
          />
        </div>
      )}

      <button
        className="hidden"
        ref={hpNoteOverviewRef}
        onClick={() => {
          if (_.isEqual(currentMedications, prevCurrentMedications)) {
            return;
          }
          const params = {
            appointmentId: currentAppointmentId || null,
            patientId: currentPatientId,
            medication: currentMedications
          };

          createMedication(() => {}, params).then(handleInvalidateQuery);
          setPrevCurrentMedications(currentMedications);
        }}
      />
      {confirmationModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete medications"
          icon="trash"
          message={
            deleteCustomFormCheck(medicationToDelete, currentMedications?.medications)
              ? 'Are you sure you want to delete this medication?'
              : 'Are you sure you want to delete this form?'
          }
          handleContinue={() => {
            if (currentMedications?.noDetails) {
              handleNoDetails(true);
            } else if (deleteCustomFormCheck(medicationToDelete, currentMedications?.medications)) {
              handleDeleteRow(medicationToDelete);
            } else {
              if (!fromCheckin && typeof medicationToDelete !== 'number') {
                const updatedObjet = {
                  ...initialValue,
                  medications: [newMedication]
                };
                setCurrentMedications(updatedObjet);
                setConfirmationModal(false);
                return;
              }
              mutateDeleteMedication.mutate();
            }
          }}
          handleOpen={confirmationModal}
          handleClose={() => setConfirmationModal(false)}
        />
      )}
      {confirmationModal2 && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Delete"
          title="Delete medication"
          icon="trash"
          message="Are you sure you want to delete medication"
          handleContinue={() => {
            fromClincalNoteDeleteCase();
          }}
          handleOpen={confirmationModal2}
          handleClose={() => setConfirmationModal2(false)}
        />
      )}
    </div>
  );
};

export default CurrentMedications;
