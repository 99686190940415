import { requestApi } from './Api';

export const createMedication = async (navigate, params, onError, onSuccess) =>
  await requestApi({
    navigate,
    url: '/api/medications/create',
    params,
    onError,
    onSuccess
  });

export const getMedications = async (navigate = () => {}, params) =>
  await requestApi({
    navigate,
    url: '/api/medications/get',
    params
  });

  export const deleteMedication = async (navigate = () => {}, params) =>
    await requestApi({
      navigate,
      url: '/api/medications/delete',
      params
    });
