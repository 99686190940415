import React, { useState } from 'react';
import { ia, verifyPassword } from '../../../lib/helpers/utility';
import { withErrorBoundary } from '../Error/Boundary';
import cs from 'classnames';
import Input from '../Input/Input';
import { AlertContent } from '../Alert/Alert';

const STRENGTH_COLOR_CONST = {
  weak: 'text-danger-600',
  medium: 'text-warning-600',
  strong: 'text-success-700'
};

const NewPassword = ({ setPasswordOk, setPassword }) => {
  const [show, setShow] = useState({
    password_reset: false,
    passwords_match: 0,
    password_ok: false,
    strength: '',
    messages: [],
    error: '',
    loading: false
  });
  const [passwordA, setPasswordA] = useState('');
  const [passwordB, setPasswordB] = useState('');

  const handleUpdate = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'passwordA':
        const passwordResetMessagesA = verifyPassword(value);
        setPasswordA(value);
        const pwdAok = value === passwordB && passwordResetMessagesA.password_ok;
        setShow((ps) => ({
          ...ps,
          ...passwordResetMessagesA,
          password_ok: pwdAok
        }));
        setPasswordOk(pwdAok);
        setPassword(value);
        break;
      case 'passwordB':
        const passwordResetMessagesB = verifyPassword(value);
        let p_m = show.messages;
        if (passwordA !== value) {
          let warning_index = p_m.findIndex((v) => v.id === 1);
          if (warning_index === -1) {
            p_m.push({ type: 'medium', id: 1, message: 'Passwords must match' });
          }
        } else {
          p_m = p_m.filter((elem) => elem.id !== 1);
        }
        setPasswordB(value);
        const pwdBok = passwordA === value && passwordResetMessagesB.password_ok;
        setShow((ps) => ({
          ...ps,
          messages: p_m,
          password_ok: pwdBok
        }));
        setPasswordOk(pwdBok);
        break;
      default:
        this.setState({
          [name]: value
        });
        break;
    }
  };

  return (
    <div className="my-[1rem]">
      <div className="">
        <div className="grid gap-y-2">
          <Input
            label={<span className="text-primary-900">Password</span>}
            name="passwordA"
            value={passwordA}
            onChange={handleUpdate}
            placeholder="Please enter new password"
            type={show.pwd ? 'text' : 'password'}
            rightIcon={show.pwd ? 'new-eye' : 'new-eye-slash'}
            rightIconClick={() => setShow((prevState) => ({ ...prevState, pwd: !prevState.pwd }))}
            headerIcon={
              show.strength && (
                <span
                  className={cs(
                    'text-sm font-500 capitalize',
                    STRENGTH_COLOR_CONST[show.strength]
                  )}>
                  {show.strength}
                </span>
              )
            }
            data-qa="password-a"
          />
          <Input
            label={<span className="text-primary-900">Confirm Password</span>}
            name="passwordB"
            value={passwordB}
            onChange={handleUpdate}
            placeholder="Please confirm your password"
            type={show.confirmPwd ? 'text' : 'password'}
            rightIcon={show.confirmPwd ? 'new-eye' : 'new-eye-slash'}
            rightIconClick={() =>
              setShow((prevState) => ({ ...prevState, confirmPwd: !prevState.confirmPwd }))
            }
            data-qa="password-b"
          />
        </div>
      </div>
      {ia(show.messages) && (
        <AlertContent
          className="mt-[1rem]"
          color={show.password_ok ? 'success' : 'warning'}
          data-dd-privacy="allow"
          data-public
          message={
            <ul className="flex flex-col">
              {ia(show.messages) &&
                show.messages.map((v, i) => (
                  <li
                    className={cs('text-sm', STRENGTH_COLOR_CONST[v.type])}
                    key={`password-reset-messages-${i}`}>
                    {v.message}
                  </li>
                ))}
            </ul>
          }
        />
      )}
    </div>
  );
};

export default withErrorBoundary(NewPassword);
