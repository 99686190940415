import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Icon from 'components/shared/Icon/Icon';
import { useUIContext } from 'lib/context/UIContext/UIContext';
import useNewDesign from '../lib/useNewDesign';

const Container = ({ children, number, onDelete, className, newDesign }) => {
  const { device } = useUIContext();
  const _newDesign = useNewDesign();

  const patientDesign = newDesign || _newDesign;
  return (
    <div
      className={cs(
        'relative flex w-full flex-row justify-center gap-2',
        'items-center',
        device === 'mobile' || device === 'tablet-sm' ? '-mr-3' : '',
        className
      )}>
      {!patientDesign && number && (
        <p
          className={cs(
            'mr-3 flex h-6 w-6 items-center justify-center rounded-[0.5rem] bg-neutral-200 p-2 text-sm font-semibold text-neutral-700'
          )}>
          {number}
        </p>
      )}

      <div
        className={cs(
          'w-full ',
          !patientDesign
            ? 'mt-4 rounded-xl bg-white p-4 '
            : 'max-w-[500px] rounded-2xl border-[1px] border-solid border-primary-100 bg-gradient-to-r from-[#DFF5FF] to-[#B6E9FF]  !px-4 !py-7 md:max-w-[450px] md:!px-6 lg:!px-6 xl:!px-6'
        )}>
        {patientDesign && number && (
          <div className="relative  max-w-[500px] md:max-w-[450px]">
            <p
              className={cs(
                'absolute  -top-3 flex h-6 w-6 items-center justify-center rounded-[0.5rem] border-[1px] border-solid !border-[#8AE0FF] bg-primary-50 p-2 text-sm font-semibold text-primary-500',
                device === 'mobile' || device === 'tablet-sm' ? ' right-0' : '-right-3'
              )}>
              {number}
            </p>
          </div>
        )}

        {children}
      </div>

      {onDelete && (
        <div
          onClick={onDelete}
          className={cs(
            'flex h-full w-6 cursor-pointer flex-col items-center justify-center pb-1 text-sm font-semibold',
            device === 'mobile' || device === 'tablet-sm' || !patientDesign
              ? ''
              : 'absolute translate-x-[280px] md:translate-x-[250px]'
          )}>
          <Icon icon="trash" color="neutral" className="m-4 cursor-pointer" shade={700} size={16} />
        </div>
      )}
    </div>
  );
};

export default Container;
