import React from 'react';

import EmptyTableState from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyScribeTemplate/components/EmptyTableComponent';

const EmptyTestsTableState = () => {
  return (
    <EmptyTableState>
      <EmptyTableState.Icon icon="empty_table" />
      <EmptyTableState.Text
        title="No Tests Added"
        description="Start adding by clicking one on the test list"
      />
    </EmptyTableState>
  );
};

export default EmptyTestsTableState;
