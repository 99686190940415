import React from 'react';
import Accordion from '../../../../shared/Accordion';
import Session from './Session';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const SessionSection = ({ components, sectionRef }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      sectionRef={sectionRef}
      updateFormType={{ name: 'Session', parentKey: 'progress', childKey: 'session' }}
      title={components?.session?.title}
      id={components?.session?.id}
      disabled={clinicalNote?.locked}>
      <Session sectionRef={sectionRef} />
    </Accordion>
  );
};

export default SessionSection;
