import cs from 'classnames';
import { AmbientListeningContextProvider } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContextProvider';
import _ from 'lodash';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { getClinicalNote as getClinicalNoteRead } from '../../../../api/ClinicalNote';
import { Chart3dContextProvider } from '../../../../lib/context/Chart3dContext/Chart3dContextProvider';
import { useClinicalNoteContext } from '../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ClinicalNoteContextProvider } from '../../../../lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { MacrosContextProvider } from '../../../../lib/context/MacrosContext/MacrosContextProvider';
import { MyScribeAIContextProvider } from '../../../../lib/context/MyScribeAI/MyScribeAIContextProvider';
import { iaRa, spaceToKebabCase } from '../../../../lib/helpers/utility';
import { showAlert } from '../../../shared/Alert/Alert';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { clinicalNote as clinicalNoteState, userState as user_state } from '../../../state';
import './ClinicalNote.scss';
import Navbar from './Navbar/Navbar';
import Sidebar from './Sidebar/Sidebar';
import { generateEnabledNoteTypes } from './lib/generateNoteTypes';
import {
  generateAdvancedSectionFormMap,
  processCustomFormTypes
} from './lib/processCustomNoteTypes';
import PatientArrivalCOnfirmation from './Navbar/components/PatientArrivalCOnfirmation';

const ClinicalNote = memo(() => {
  const {
    setStaticClinicalNote,
    setLoading,
    setAdvancedHP,
    setAdvancedSOAP,
    setServiceCPT,
    customFormTypes,
    setCustomFormTypes,
    cnDisplaySettings
  } = useClinicalNoteContext();
  const setClinicalNote = useSetRecoilState(clinicalNoteState);

  const { id, appointmentId, type } = useParams();
  const { pathname } = useLocation();
  const { patient, hpNoteOverviewRefs } = useOutletContext();
  const navigate = useNavigate();
  const [userState, setUserState] = useRecoilState(user_state);
  const [open, setOpen] = useState(
    userState?.display_settings?.clinicalNote?.toggleLSidebar?.enabled
  ); // left sidebar
  const [openSidebar, setOpenSidebar] = useState(
    userState?.display_settings?.clinicalNote?.toggleRSidebar?.enabled
  ); // right sidebar
  const navbarRef = useRef();
  useEffect(() => {
    getClinicalNote();
  }, []);

  const getClinicalNote = async () => {
    setLoading(true);
    try {
      const res = await getClinicalNoteRead(navigate, {
        patientId: id,
        appointmentId,
        exporting: false,
        forPractice: true
      });
      const {
        clinicalNote,
        advancedHP,
        advancedSOAP,
        serviceCPT,
        redirect,
        returningPatient,
        customFormTypes
      } = res;

      let newCN = {};

      if (redirect) navigate(redirect);

      if (clinicalNote) {
        newCN = clinicalNote;
      } else {
        newCN = {
          user_id: patient?.id,
          appointment_id: appointmentId
        };
      }

      newCN.cpt_codes = iaRa(serviceCPT);

      setClinicalNote(_.cloneDeep(newCN));
      setStaticClinicalNote(_.cloneDeep(newCN));

      if (advancedHP)
        setAdvancedHP(
          advancedHP.map((item) => ({
            ...item,
            custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
              item?.form_id || item?.id
            }`
          }))
        );
      if (advancedSOAP)
        setAdvancedSOAP(
          advancedSOAP.map((item) => ({
            ...item,
            custom_title: `${spaceToKebabCase(item?.form?.name || item?.name)}-${
              item?.form_id || item?.id
            }`
          }))
        );

      if (customFormTypes) {
        const processedCustomFormTypes = processCustomFormTypes({ customFormTypes });

        setCustomFormTypes(processedCustomFormTypes);
      }

      if (serviceCPT) setServiceCPT(serviceCPT);

      if (!type && !pathname.includes('myscribe-ai')) {
        const sectionMap = {
          hp: cnDisplaySettings?.hp?.enabled,
          intake: cnDisplaySettings?.mha?.enabled,
          soap: cnDisplaySettings?.soap?.enabled,
          progress: cnDisplaySettings?.epn?.enabled,
          '3d': cnDisplaySettings?.chart3d?.enabled,
          ...generateAdvancedSectionFormMap({ cnDisplaySettings, customFormTypes })
        };

        const returnBack = `/portal/charts/${id}/clinical-notes`;
        const enabledSection = Object.entries(sectionMap).find(([, isEnabled]) => isEnabled)?.[0];
        const sectionToNavigate =
          returningPatient && cnDisplaySettings?.soap.enabled
            ? 'soap'
            : (enabledSection ?? returnBack);

        if (!enabledSection) {
          showAlert(
            {
              title: 'No enabled sections',
              message: 'Please enable at least one section in the admin clinical note settings',
              color: 'warning'
            },
            { replace: true }
          );
        }

        navigate(sectionToNavigate);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  if (pathname.includes('myscribe-ai')) {
    return (
      <div className="fixed flex h-full w-full flex-col">
        <Navbar forwardedRef={navbarRef} />
        <Outlet
          context={{
            patient,
            hpNoteOverviewRefs,
            open,
            setOpen,
            openSidebar,
            setOpenSidebar,
            setUserState,
            navbarRef,
            enabledNotes: generateEnabledNoteTypes({ cnDisplaySettings, customFormTypes })
          }}
        />
      </div>
    );
  }

  if (!type) return <Skeleton height="850px" />;

  return (
    <div className="flex flex-col">
      <Navbar forwardedRef={navbarRef} />
      <div
        className={cs(
          'flex h-[calc(100vh-117px)] grow  overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-139px)] md:h-[calc(100vh-139px)]',
          type === '3d' ? 'overflow-y-hidden' : 'overflow-y-auto'
        )}>
        <Outlet
          context={{
            patient,
            hpNoteOverviewRefs,
            open,
            setOpen,
            openSidebar,
            setOpenSidebar,
            setUserState,
            navbarRef,
            enabledNotes: generateEnabledNoteTypes({ cnDisplaySettings, customFormTypes })
          }}
        />
        <Sidebar handleSidebar={{ openSidebar, setOpenSidebar }} />
      </div>
      <PatientArrivalCOnfirmation />
    </div>
  );
});

const ClinicalNoteWrapper = () => {
  return (
    <ClinicalNoteContextProvider>
      <MacrosContextProvider>
        <Chart3dContextProvider>
          <MyScribeAIContextProvider>
            <AmbientListeningContextProvider>
              <ClinicalNote />
            </AmbientListeningContextProvider>
          </MyScribeAIContextProvider>
        </Chart3dContextProvider>
      </MacrosContextProvider>
    </ClinicalNoteContextProvider>
  );
};

export default ClinicalNoteWrapper;
