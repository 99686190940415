import React, { useState } from 'react';
import { Transition } from '@headlessui/react';
import { AlertContent } from '../../../../../shared/Alert/Alert';

const Errors = ({ claim }) => {
  const [showAlerts, setShowAlerts] = useState(Array(claim?.errors?.length).fill(true));

  const handleCloseAlert = (index) => {
    setShowAlerts((prevState) => {
      const newState = [...prevState];
      newState[index] = false;
      return newState;
    });
  };

  return (
    <div className="grid gap-2 !pb-5">
      {claim?.errors?.map((error, index) => (
        <Transition
          key={index}
          show={showAlerts[index] ? showAlerts[index] : false}
          enter="transition-opacity ease-all duration-200"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-all duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <AlertContent
            width="full"
            title={error.message}
            handleClose={() => handleCloseAlert(index)}
            color="danger"
          />
        </Transition>
      ))}
    </div>
  );
};

export default Errors;
