import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useClinicalNoteContext } from '../../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { io } from '../../../../../../../lib/helpers/utility';
import Skeleton from '../../../../../../shared/Skeleton/Skeleton';

import Attachments from './Attachments';

const AttachmentsContainer = ({ context }) => {
  const { components, setComponents } = useClinicalNoteContext();
  const { formName } = useParams();
  const formsList = {
    attachments: Attachments
  };

  useEffect(() => {
    setComponents({
      face: {
        title: 'Attachments',
        path: 'attachments'
      }
    });
  }, []);

  const CurrentForm = formsList && formsList?.[formName ?? Object.values(components)?.[0]?.path];

  if (!io(components) || !CurrentForm) return <Skeleton height="calc(100vh - 214px)" />;

  return <CurrentForm context={context} key={formName} components={components} />;
};

export default AttachmentsContainer;
