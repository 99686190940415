import React, { useMemo, useRef } from 'react';
import cs from 'classnames';

import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(process.env.REACT_AG_GRID_KEY);

import { AgGridReact } from 'ag-grid-react';
import './Table.scss';

import { useTableContext } from '../../../lib/context/TableContext/TableContext';
import CustomLoadingOverlay from './customLoadingOverlay';
import CustomNoRowsOverlay from './customNoRowsOverlay';
import Icon from '../Icon/Icon';

export default function AGTable({ data, customClassName, ExpandedComponent, ...props }) {
  const {
    onGridReady,
    onColumnMoved,
    colDefs,
    onSortChanged,
    onSelectionChanged,
    pagination,
    gridRef
  } = useTableContext();

  const defaultColDef = {
    valueFormatter: ({ value }) => (value == null || value == '' || value == ' ' ? '-' : value)
  };

  const components = useMemo(
    () => ({
      agLoadingOverlay: CustomLoadingOverlay,
      agNoRowsOverlay: CustomNoRowsOverlay
    }),
    []
  );

  const onCellClicked = (event) => {
    event.api.clearFocusedCell();
  };

  const detailGridOptions = {
    onFirstDataRendered: (params) => {
      params.api.forEachNode((node) => {
        if (node.expanded) {
          node.setExpanded(false);
          params.api.clearFocusedCell();
        }
      });
    }
  };

  const detailCellRendererParams = {
    detailGridOptions,
    suppressRowClickSelection: true
  };

  const CustomDetailCellRenderer = (props) => {
    const { api, node, data } = props;
    const containerRef = useRef(null);

    const handleHeightChange = (newHeight) => {
      node.setRowHeight(newHeight);
      api.onRowHeightChanged();
    };

    return (
      <div ref={containerRef}>
        <ExpandedComponent
          {...props}
          ref={containerRef}
          src={data?.details}
          onHeightChange={handleHeightChange}
        />
      </div>
    );
  };

  const EmptyData = () => (
    <div className="flex flex-col items-center">
      <Icon icon="empty_table" />
      <h1 className="text-base font-500 text-primary-900">No Data on table</h1>
    </div>
  );

  return (
    <div className={cs('ag-theme-quartz h-full', pagination && 'ag-pagination', customClassName)}>
      <AgGridReact
        ref={gridRef}
        onColumnRowGroupChanged={onColumnMoved}
        onColumnMoved={onColumnMoved}
        onColumnPinned={onColumnMoved}
        onColumnVisible={onColumnMoved}
        onGridReady={onGridReady}
        onSortChanged={onSortChanged}
        rowData={data}
        columnDefs={colDefs}
        // enableRangeSelection={true}
        defaultColDef={defaultColDef}
        components={components}
        onCellClicked={onCellClicked}
        onSelectionChanged={onSelectionChanged}
        detailCellRenderer={CustomDetailCellRenderer}
        detailCellRendererParams={detailCellRendererParams}
        noRowsOverlayComponent={EmptyData}
        {...props}
      />
    </div>
  );
}
