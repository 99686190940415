import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import _, { capitalize } from 'lodash';

import usePageTitle from '/lib/hooks/usePageTitle';
import { usePatientsAdmin } from '/lib/hooks/queries/patients/usePatientsAdmin';
import { formatPhoneNumber } from '/lib/helpers/utility';
import { useTableContext } from '/lib/context/TableContext/TableContext';
import { TableContextProvider } from '/lib/context/TableContext/TableContextProvider';

import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Header from 'components/shared/Header/Header';
import AGTable from 'components/shared/AGTable/AGTable';
import Pagination from 'components/shared/Pagination/Pagination';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import Button from 'components/shared/Buttons/Button';
import NewPatientAdmin from '../Practices/NewPatientAdminContainer';
import Allowed from 'components/shared/Permissions/Allowed';
export default function AdminPatients() {
  const [showModal, setShowModal] = useState(false);
  const columns = [
    { field: 'id', headerName: 'ID', maxWidth: 100 },
    {
      field: 'f_name',
      headerName: 'First Name',
      minWidth: 150,
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'l_name',
      headerName: 'Last Name',
      minWidth: 150,
      valueFormatter: ({ value }) => capitalize(value)
    },
    {
      field: 'phone',
      headerName: 'Phone',
      valueFormatter: ({ value }) => formatPhoneNumber(value)
    },
    { field: 'email', headerName: 'Email', minWidth: 200 },
    {
      colId: 'status',
      headerName: 'Status',
      valueGetter: (params) => {
        return params.data.status;
      },
      valueFormatter: ({ value }) => capitalize(value)
    }
  ];

  return (
    <Allowed requiredPermissions="patients.read" showIllustration showMessage>
      <TableContextProvider cols={columns} pagination name="admin_patients">
        <Table setShowModal={setShowModal} />
      </TableContextProvider>
      {!!showModal && (
        <NewPatientAdmin
          noPractice={true}
          hideModal={() => setShowModal(false)}
          showModal={!!showModal}
        />
      )}
    </Allowed>
  );
}

function Table({ setShowModal }) {
  usePageTitle('Patients');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const { id } = useParams();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const navigate = useNavigate();
  const {
    data = {},
    isLoading,
    isFetching
  } = usePatientsAdmin({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit, withCount: true },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });

  const patients = data.user;

  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      navigate(`/admin/patients/${event.data.id}`);
    }
  };

  if (id) return <Outlet />;

  return (
    <>
      <Header title="Patients">
        <div className="flex items-center gap-2">
          <DisplayButton />
          <Input
            data-qa="searchPatient"
            type="string"
            inputWrapperClassName="h-[30px]"
            placeholder="Search patients..."
            value={searchTerm}
            icon="new-search"
            className="md:w-[200px]"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
          <Allowed requiredPermissions="patients.create">
            <Button
              data-qa="new-cpt-code-btn"
              size="small"
              type="primary"
              text="New Patient"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex h-full flex-col overflow-hidden !pb-2">
        <div className="ag-theme-quartz !mb-0  h-full">
          <AGTable
            data={patients}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            onRowClicked={onRowClicked}
            customClassName="ag-grid-interactive"
            loading={isLoading || isFetching}
          />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.count > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.count > page * limit ? page * limit : data?.count} out of {data?.count}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.count}
            page={page}
          />
        </div>
      </div>
    </>
  );
}
