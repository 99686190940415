import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';
import { downloadFax, previewSavedFax } from 'api/Fax';
import Allowed from 'components/shared/Permissions/Allowed';
import applyChangesToFax from 'components/practice/comms_center/fax/components/SendFax/lib/applyChangesToFax';
import PreviewPdfModal from 'components/practice/comms_center/fax/components/SendFax/components/PreviewPdfModal';

const FaxDocsActions = ({ fax, fromPatient, handleConfirmationFile }) => {
  const navigate = useNavigate();
  const [pdfData, setPdfData] = useState(null);
  const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);

  const handlePreview = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob, url } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    if (blob) {
      setPdfData(URL.createObjectURL(blob));
    } else {
      setPdfData(url);
    }
    setPreviewFaxModalVisible(true);
  };

  const handleDownload = async () => {
    const pdfData = await previewSavedFax(navigate, fax.id);
    const { blob } = await applyChangesToFax(pdfData.url, navigate, fax.id);

    let blobUrlFile;

    if (blob) {
      blobUrlFile = URL.createObjectURL(blob);
    } else {
      const { data } = downloadFax(navigate, fax.id);
      const uint8Array = new Uint8Array(data?.buffer_file?.data);
      const blobFile = new Blob([uint8Array], {
        type: 'application/pdf'
      });
      blobUrlFile = URL.createObjectURL(blobFile);
    }

    const pdfUrl = blobUrlFile;
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = `invoice_${new Date(fax.created_at).getTime()}_document.pdf`; // specify the filename
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <td>
        <Popover className="relative">
          {({ open, close }) => (
            <div>
              <Popover.Button
                className={`flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300  hover:border ${
                  !open && 'hover:!bg-white'
                } ${open && '!bg-primary-700 transition-all'}`}>
                <Icon
                  icon="three-dots"
                  className="flex cursor-pointer"
                  color={open ? 'white' : 'black'}
                />
              </Popover.Button>

              <Popover.Panel className="absolute bottom-10  right-0 z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]">
                <Allowed requiredPermissions={fromPatient ? '' : 'patient_documents_center.read'}>
                  <div
                    data-qa="view-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      handlePreview();
                      close();
                    }}>
                    <Icon icon="new-eye" color="primary" />
                    <div className="ml-1 text-sm text-primary-900">View</div>
                  </div>
                </Allowed>

                <Allowed requiredPermissions={fromPatient ? '' : 'patient_documents_center.read'}>
                  <div
                    data-qa="download-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      handleDownload();
                      close();
                    }}>
                    <Icon icon="new-chevron-down" color="primary" />
                    <div className="ml-1 text-sm text-primary-900">Download</div>
                  </div>
                </Allowed>

                {!fromPatient && (
                  <Allowed requiredPermissions="patient_communication.create">
                    <div
                      data-qa="delete-btn"
                      className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                      onClick={() => {
                        close();
                        handleConfirmationFile({ id: fax.id, type: 'fax', mode: 'share' });
                      }}>
                      <Icon icon="new-arrow-swap-down" color="primary" />
                      <div className="ml-1 text-sm text-primary-900">Share with patient</div>
                    </div>
                  </Allowed>
                )}
              </Popover.Panel>
            </div>
          )}
        </Popover>
      </td>
      {previewFaxModalVisible && (
        <PreviewPdfModal
          {...{
            pdfData,
            previewFaxModalVisible,
            setPreviewFaxModalVisible
          }}
        />
      )}
    </>
  );
};

export default FaxDocsActions;
