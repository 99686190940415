import React, { useState, useRef } from 'react';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';

const useTimer = () => {
  const { currentState, setCurrentState } = useAmbientListeningContext();
  const [currentTime, setCurrentTime] = useState(0);
  const intervalRef = useRef();

  const startTimer = () => {
    if (currentState === 'START') return;

    setCurrentState('START');

    intervalRef.current = setInterval(() => {
      setCurrentTime((currentTime) => currentTime + 1);
    }, 1000);
  };

  const stopTimer = () => {
    if (currentState === 'STOP') return;

    setCurrentTime(0);
    setCurrentState('STOP');

    clearInterval(intervalRef.current);
  };

  const pauseTimer = () => {
    if (currentState === 'PAUSE') return;

    setCurrentState('PAUSE');

    clearInterval(intervalRef.current);
  };

  const sec = currentTime;
  const min = Math.floor(sec / 60);
  const hour = Math.floor(min / 60);
  const seconds = (sec % 60).toString().padStart(2, '0');
  const minutes = (min % 60).toString().padStart(2, '0');
  const hours = (hour % 24).toString().padStart(2, '0');

  return {
    seconds,
    startTimer,
    stopTimer,
    pauseTimer,
    currentState,
    currentTime,
    setCurrentTime,
    timer: (
      <div className="w-[100px] rounded-lg border border-solid !border-primary-300 bg-primary-200 font-700 text-primary-600">
        <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
    )
  };
};

export default useTimer;
