import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Custom hook to manage navigation with support for "return-to" functionality.
 * Allows you to navigate to a new page while storing the current location, and return to the previous page later.
 *
 * @param {string} [defaultPath='/'] - The default fallback path if no return location is stored.
 * @returns {{
 *   navigate: function,
 *   locationState: Object | undefined,
 *   previousPath: string | undefined,
 *   currentPath: string | undefined,
 *   navigateTo: function(string, Object=): void,
 *   navigateBack: function(string=): void
 * }}
 *   An object containing the following properties:
 *   - `navigate` function: navigate function returned from useNavigate(react-router-dom) hook.
 *   - `locationState` (Object | undefined): The current state object from the location.
 *   - `currentPath` (string | undefined): The `pathname` of the current location hook is being called.
 *   - `previousPath` (string | undefined): The `pathname` of the previously stored location, if available.
 *   - `navigateTo(path: string, state?: Object): void` - Navigate to a new page and store the current location as `returnTo`.
 *   - `navigateBack(fallbackPath?: string): void` - Return to the previously stored location or a default fallback path.
 */
export const useSmartNavigate = (defaultPath = '/') => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentPath = location?.pathname;
  const locationState = location?.state;

  /**
   * Navigate to a new page and store the current location in the navigation state.
   *
   * @param {string} path - The path to navigate to.
   * @param {Object} [state={}] - Additional state to pass to the target location.
   */
  const navigateTo = (path, state = {}, savePreviousState = true) => {
    navigate(path, {
      state: {
        ...(savePreviousState && state),
        ...(savePreviousState && locationState),
        ...(currentPath !== '/login' || currentPath !== '/admin/login'
          ? { returnTo: location }
          : {})
      }
    });
  };

  /**
   * Return to the previously stored location or navigate to the default fallback path if none exists.
   *
   * @param {string} [fallbackPath=defaultPath] - The fallback path to navigate to if no return location is stored.
   */
  const navigateBack = (fallbackPath = defaultPath) => {
    if (location.state?.returnTo) {
      navigate(location.state.returnTo?.pathname + location.state.returnTo?.search, {
        state: location.state.returnTo.state
      });
    } else {
      navigate(fallbackPath);
    }
  };

  return {
    navigate,
    currentPath,
    locationState,
    previousPath: location.state?.returnTo?.pathname,
    navigateTo,
    navigateBack
  };
};
