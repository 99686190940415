import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Honeybadger from '@honeybadger-io/js';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';

import Button from 'components/shared/Buttons/Button';
import ColorPicker from 'components/shared/ColorPicker/ColorPicker';
import Modal from 'components/shared/Modal/Modal';
import { permissions } from 'components/state';

import AvailableTimeComponent from './AvailableTime/AvailableTimeComponent';

const Practitioners = ({ practitioner }) => {
  const [color, setColor] = useState(practitioner.color);
  const [modalState, setModalState] = useState(false);
  const userPermissions = useRecoilValue(permissions);
  const practitionerRef = useRef();
  const navigate = useNavigate();

  const updateColor = async (color) => {
    try {
      const params = {
        practitioner_id: practitioner.id,
        fields: { color }
      };
      await requestApi({
        url: '/api/practice/settings/practice_user/update',
        params,
        navigate
      });
    } catch (error) {
      Honeybadger.notify(`Error updating color: ${error}`);
      console.error(error);
    }
  };

  const handleChange = (color) => {
    setColor(color);
    updateColor(color);
  };

  const showModal = () => setModalState(true);

  const hideModal = () => setModalState(false);

  return (
    <div className="grid grid-cols-3 items-center">
      <div className="text-sm">{practitioner.name}</div>
      <ColorPicker color={color} onChange={handleChange} label={false} />

      {userPermissions?.member?.read && (
        <>
          <div className="text-sm">
            <Button primary onClick={() => showModal()} text="Available Time" size="small" />
          </div>
          <Modal
            isOpen={modalState}
            handleClose={hideModal}
            slideFromRight
            title={`Practitioner ${practitioner.name}`}
            footer={
              <div className="flex w-full justify-between">
                <Button outlined text="Cancel" color="neutral" onClick={hideModal} />
                <Button
                  text="Save"
                  onClick={() => {
                    practitionerRef?.current?.handleSave();
                  }}
                  disabled={!userPermissions?.member?.update}
                />
              </div>
            }>
            <AvailableTimeComponent data={practitioner} type="practitioner" ref={practitionerRef} />
          </Modal>
        </>
      )}
    </div>
  );
};

export default Practitioners;
