import React, { useEffect } from 'react';
import { requestApi } from '../../../api/Api';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../shared/Loading/Loading';

const UserActivateInitiate = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    requestApi({ url: '/api/user/invitation/view', params: { token: token }, navigate: navigate });
  }, []);

  return <Loading />;
};

export default UserActivateInitiate;
