import { useQueryClient } from '@tanstack/react-query';
import { deleteFax } from 'api/Fax';
import { showAlert } from 'components/shared/Alert/Alert';
import Popover from 'components/shared/Popovers/Popover/Popover';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RenameFaxModal from '../../components/RenameFaxModal/RenameFaxModal';
import SendFaxModal from '../../components/SendFaxModal/SendFaxModal';

export const DraftActions = ({ data }) => {
  const fax = data || {};
  const [renameFaxModalVisible, setRenameFaxModalVisible] = useState(false);
  const [draftFax, setDraftFax] = useState(null);
  const [sendFaxModalVisible, setSendFaxModalVisible] = useState(false);

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const refetchFaxes = () => queryClient.invalidateQueries(['draftFaxes']);

  const handleDelete = () => {
    deleteFax(navigate, fax.id).then(() =>
      refetchFaxes().then(() => {
        showAlert({ color: 'success', message: 'Fax deleted' });
      })
    );
  };

  const handleRename = () => {
    setRenameFaxModalVisible(true);
    setDraftFax(fax);
  };

  const handleOpen = () => {
    setSendFaxModalVisible(true);
    setDraftFax(fax);
  };

  const options = [
    { label: 'Open', onClick: handleOpen, icon: 'new-edit' },
    { label: 'Delete', onClick: handleDelete, icon: 'trash' },
    { label: 'Rename', onClick: handleRename, icon: 'new-edit' }
  ];

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {sendFaxModalVisible && (
        <SendFaxModal
          {...{ sendFaxModalVisible, setSendFaxModalVisible, faxData: draftFax, isDraft: true }}
        />
      )}
      {renameFaxModalVisible && draftFax && (
        <RenameFaxModal
          {...{
            renameFaxModalVisible,
            setRenameFaxModalVisible,
            id: draftFax.id,
            fax_name: draftFax.name,
            refetchFaxes
          }}
        />
      )}
    </>
  );
};

export default DraftActions;
