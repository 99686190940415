import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { calculateTotalCharge } from 'components/practice/charts/SuperBillPage/lib';
import { conditionalFormatTimeZ } from 'components/practice/charts/SuperBillPage/lib/conditionTimeFormat';
import { generateBaseUnit } from 'components/practice/charts/SuperBillPage/lib/generateBaseUnit';
import { getHours } from 'components/practice/charts/SuperBillPage/lib/getHours';
import { handleOnChangeUnit } from 'components/practice/charts/SuperBillPage/lib/handleOnChangeUnit';
import { handleUnitSwitch } from 'components/practice/charts/SuperBillPage/lib/handleUnitSwitch';
import { handleUnitValue } from 'components/practice/charts/SuperBillPage/lib/handleUnitValue';
import { formatProcedureModifiers } from 'components/practice/charts/SuperBillPage/lib/reshapeProcedures';
import practiceState from 'components/practice/practiceState';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import Switch from 'components/shared/Switch/Switch';
import { yesAndNoOptions } from 'constants';
import { Capitalize, ia, iaRa, mString } from 'lib/helpers/utility';
import { useNdcCodesOptions } from 'lib/hooks/queries/ndc/useNdcCodesOptions';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getLabelForMeasureValue, getNdcMeasureOptions } from '../lib/helpers';
import { shouldDisableDelete } from '../lib/shouldDisableDelete';
export const Procedure = ({
  procedure,
  handleCPTSearch,
  selectCPT,
  hasBaseUnit,
  hasNdcOptions,
  index,
  claim,
  setHasNdcOptions = () => {},
  setClaim = () => {}
}) => {
  const currPractice = useRecoilValue(practiceState.currentPractice);
  const [manualChange, setManualChange] = useState(false);
  const { data } = useNdcCodesOptions({
    params: { procedure_code: procedure?.procedure_code },
    dependencies: [procedure?.procedure_code]
  });
  const ndcOptions = iaRa(data?.ndcOptions);
  const updateProcedures = (event, index, key) => {
    const field = event?.target?.name;
    let value = event?.target?.value;

    setClaim((prevClaim = { procedures: [] }) => {
      const updatedProcedures = [...prevClaim.procedures];
      updatedProcedures[index] = {
        ...updatedProcedures[index],
        [field ?? key]: value ?? (event?.label || event)
      };

      return {
        ...prevClaim,
        procedures: formatProcedureModifiers({ procedures: updatedProcedures }),
        total_charge: calculateTotalCharge(updatedProcedures, true)
      };
    });
  };
  useEffect(() => {
    if (ia(ndcOptions)) {
      setHasNdcOptions(true);
      const ndc = ndcOptions.find((ndc) => ndc?.package_ndc === procedure?.ndc_code);
      if (!ndc) updateProcedures({ target: { value: '', name: 'ndc_code' } }, index);
    }
  }, [ndcOptions]);

  if (procedure?.type !== 'primary') return null;

  const claimProcedures = claim?.procedures || [];
  const isDeleteDisabled = shouldDisableDelete({ claim, procedure });
  const adjustments = claim?.claimAdjustments?.adjustments[procedure?.procedure_code];

  const handleDeletion = (index) => {
    let procedures = [...claimProcedures];
    procedures.splice(index, 1);

    setClaim({ ...claim, procedures, total_charge: calculateTotalCharge(procedures, true) });
  };

  const formattedStartTime = conditionalFormatTimeZ(
    procedure?.appointment_start,
    currPractice?.timezone
  );
  const formattedEndTime = conditionalFormatTimeZ(
    procedure?.appointment_end,
    currPractice?.timezone
  );

  const hours = getHours(currPractice, formattedStartTime, formattedEndTime);

  let timeOptions = iaRa(hours).map((time) => ({
    value: time,
    label: time
  }));

  return (
    <>
      <tr>
        <td className="!p-1 align-bottom">
          <div className="grid grid-cols-2 items-end gap-[6px]">
            <DatePopover
              className="mt-8"
              placeholder="From"
              rightIcon={false}
              name="from_date"
              value={procedure?.from_date}
              onChange={(event) => updateProcedures(event, index, 'from_date')}
            />
            <DatePopover
              className="mt-8"
              placeholder="To"
              rightIcon={false}
              name="thru_date"
              value={procedure?.thru_date}
              onChange={(event) => updateProcedures(event, index, 'thru_date')}
            />
          </div>
        </td>
        <td className="!p-1 align-bottom">
          <Input
            data-qa="ndc"
            id="NDC"
            placeholder="NDC Narrative"
            className="mt-8"
            value={procedure.narrative}
            name="narrative"
            onChange={(e) => updateProcedures(e, index)}
          />
        </td>
        <td className="!p-1 align-bottom">
          <Input
            data-qa="place-of-service"
            className="mt-8"
            id="Place"
            placeholder="Place of service"
            value={procedure.place_of_service}
            name="place_of_service"
            onChange={(e) => updateProcedures(e, index)}
          />
        </td>
        <td className="!p-1 align-bottom">
          <Select
            parentClassName="min-w-[144px] mt-8"
            inputId="emg-select"
            placeholder="Emergency"
            options={yesAndNoOptions}
            value={
              procedure?.amb_emergency
                ? { value: procedure?.amb_emergency, label: procedure?.amb_emergency }
                : null
            }
            name="amb_emergency"
            onChange={(event) => updateProcedures(event, index, 'amb_emergency')}
          />
        </td>
        <TippyContainer isDisabled={isDeleteDisabled}>
          <td className="!p-1 align-bottom">
            <Select
              data-qa="pro-code"
              parentClassName="min-w-[90px] mt-8"
              isAsync
              cacheOptions
              data-qa="pro-code"
              inputId="cptCodes"
              parentClassName="mt-8 w-[100px]"
              loadOptions={handleCPTSearch}
              noOptionsMessage={() => 'Start typing the code or description.'}
              value={{ label: procedure.procedure_code }}
              menuPortalTarget={document.body}
              onChange={(e) => selectCPT(e, index)}
              isClearable={false}
              disabled={isDeleteDisabled}
            />
          </td>
        </TippyContainer>
        <td className="!p-1 pt-3 align-bottom">
          <Input
            data-qa="pro-modifiers"
            className="mt-8"
            value={procedure.modifiers}
            id="Modifiers"
            placeholder="Modifiers"
            name="modifiers"
            onChange={(e) => updateProcedures(e, index)}
          />
        </td>
        <td className="!p-1 align-bottom">
          <Input
            className="mt-8"
            data-qa="pro-diagnosis"
            value={procedure.diagnosis}
            id="Diagnosis"
            placeholder="Diagnosis"
            name="diagnosis"
            onChange={(e) => updateProcedures(e, index)}
          />
        </td>
        <td className="!p-1 align-bottom">
          <CurrencyInput
            id="Charge"
            name="charge"
            data-qa="pro-charge"
            placeholder="Charge"
            value={procedure.charge}
            onValueChange={(value) =>
              updateProcedures({ target: { value: +value, name: 'charge' } }, index)
            }
          />
        </td>
        <td className="!p-1 align-bottom">
          <div className="flex items-center justify-center">
            <Input
              data-qa="pro-units"
              headerIcon={
                procedure?.base_unit && (
                  <>
                    <Switch
                      checked={procedure?.isTimeUnit}
                      onChange={(checked) => {
                        procedure.isTimeUnit = checked;
                        handleUnitSwitch({
                          procedure,
                          isTimeUnit: procedure.isTimeUnit,
                          handleProcedureChanges: updateProcedures,
                          index,
                          startTime: formattedStartTime,
                          endTime: formattedEndTime
                        });
                      }}
                    />
                    <p>Min</p>
                  </>
                )
              }
              value={handleUnitValue(
                procedure,
                procedure?.isTimeUnit,
                procedure?.start_time || formattedStartTime,
                procedure?.end_time || formattedEndTime,
                updateProcedures,
                index,
                manualChange
              )}
              id="Units"
              min={1}
              placeholder="Units"
              name="unit_time_length"
              onChange={(e) => {
                setManualChange(true);
                handleOnChangeUnit({
                  procedure,
                  isTimeUnit: procedure?.isTimeUnit,
                  handleProcedureChanges: updateProcedures,
                  index,
                  startTime: formattedStartTime,
                  endTime: formattedEndTime,
                  value: e.target.value
                });
              }}
            />
          </div>
        </td>
        {(ia(ndcOptions) || procedure.ndc_code) && (
          <>
            <td className="!p-1 align-bottom">
              <Select
                parentClassName="w-32 mt-8"
                placeholder="Select NDC code"
                isClearable={false}
                name="ndc_code"
                value={{ value: procedure.ndc_code, label: procedure.ndc_code }}
                options={
                  ia(ndcOptions)
                    ? ndcOptions.map((item) => ({
                        value: item?.package_ndc,
                        label: `[ ${Capitalize(item?.generic_name)} ]\n ${item?.packaging_description}`
                      }))
                    : []
                }
                onChange={(e) =>
                  updateProcedures({ target: { value: e.value, name: 'ndc_code' } }, index)
                }
              />
            </td>

            <td className="!p-1 align-bottom">
              <Input
                className="mt-8"
                placeholder="NDC Dosage"
                name="ndc_dosage"
                value={procedure.ndc_dosage}
                onChange={(e) => updateProcedures(e, index)}
              />
            </td>

            <td className="!p-1 align-bottom">
              <Select
                parentClassName="w-32 mt-8"
                placeholder="NDC Measure"
                isClearable={false}
                name="ndc_measure"
                value={{
                  value: procedure.ndc_measure,
                  label: getLabelForMeasureValue(procedure.ndc_measure)
                }}
                options={getNdcMeasureOptions()}
                onChange={(e) =>
                  updateProcedures({ target: { value: e.value, name: 'ndc_measure' } }, index)
                }
              />
            </td>
          </>
        )}
        {!ia(ndcOptions) && hasNdcOptions && !procedure.ndc_code && (
          <>
            <td className="!p-1 !pr-[10px] align-bottom"></td>
            <td className="!p-1 !pr-[10px] align-bottom"></td>
            <td className="!p-1 !pr-[10px] align-bottom"></td>
          </>
        )}
        {procedure?.base_unit && (
          <>
            <td className="!p-1 align-bottom">
              <Select
                parentClassName="mt-8 mb-1"
                placeholder="Start Time"
                isClearable={false}
                className="!-mt-[2px] !w-32"
                rightIcon="new-clock-gray"
                options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
                onChange={(val) => {
                  updateProcedures({ target: { value: val.value, name: 'start_time' } }, index);
                  generateBaseUnit({
                    procedure,
                    formattedStartTime,
                    formattedEndTime,
                    index,
                    updateProcedures
                  });
                }}
                value={
                  timeOptions.find((option) => option.value === procedure.start_time) ||
                  timeOptions.find((option) => option.value == formattedStartTime)
                }
                name="start_time"
              />
            </td>
            <td className="!p-1 align-bottom">
              <Select
                placeholder="End Time"
                parentClassName="mt-8 mb-1"
                isClearable={false}
                className="!-mt-[2px] !w-32"
                rightIcon="new-clock-gray"
                options={timeOptions.map((hour) => ({ value: hour.value, label: hour.label }))}
                onChange={(val) => {
                  updateProcedures({ target: { value: val.value, name: 'end_time' } }, index);
                  generateBaseUnit({
                    procedure,
                    formattedStartTime,
                    formattedEndTime,
                    index,
                    updateProcedures
                  });
                }}
                value={
                  timeOptions.find((option) => option.value === procedure.end_time) ||
                  timeOptions.find((option) => option.value == formattedEndTime)
                }
                name="end_time"
              />
            </td>
          </>
        )}
        {hasBaseUnit && !procedure.base_unit && (
          <>
            <td className="!p-1 !pr-[10px] align-bottom"></td>
            <td className="!p-1 !pr-[10px] align-bottom"></td>
          </>
        )}
        <TippyContainer isDisabled={isDeleteDisabled}>
          <td className="!p-1 !pr-[10px]">
            <Icon
              icon="trash"
              onClick={() => handleDeletion(index)}
              data-qa="click-delete-icon"
              disabled={isDeleteDisabled}
            />
          </td>
        </TippyContainer>
      </tr>
      {claim?.payer_order === 'secondary' && (
        <tr>
          <td colSpan="10">
            <div className="flex flex-col">
              <div>Primary Payer Adjustments (ERA/EOP)</div>
              <div className="flex flex-row gap-2">
                {iaRa(adjustments?.adjustments).map((adjustment, index) => (
                  <div key={index} className="flex gap-2">
                    <Input
                      label="Code"
                      disabled
                      value={`${adjustment?.group} ${adjustment?.code}`}
                    />
                    <Input label="Amount" disabled value={mString(adjustment.amount * 100)} />
                  </div>
                ))}
                <Input label="Remit Date" disabled value={adjustments?.paid_date} />
                <Input label="Paid" disabled value={mString(adjustments?.paid * 100)} />
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

const TippyContainer = ({ isDisabled = false, children }) => {
  return (
    <Tippy
      className="tippy-dark"
      disabled={!isDisabled}
      content="Service cannot be removed because the clinical note is locked.">
      {children}
    </Tippy>
  );
};
