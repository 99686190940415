import React, { useRef, useState } from 'react';
import AsyncSelect from 'react-select/async';
import CurrencyInput from '../../../shared/CurrencyInput/CurrencyInput';
import Payer from './payer';
import Switch from '../../../shared/Switch/Switch';
import { ia, optionify } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Button/Button';
import { serviceChargeTypes } from '../../../../constants';
import Input from '../../../shared/Input/Input';
import Icon from '../../../shared/Icon/Icon';
import * as filestack from 'filestack-js';
import Textarea from '../../../shared/Textarea/Textarea';
import Select from '../../../shared/Select/Select';
import ColorPickerRgb from '../../../shared/ColorPickerRgb/ColorPickerRgb';
import Tippy from '@tippyjs/react';
import { showAlert } from '../../../shared/Alert/Alert';
import { useQueryClient } from '@tanstack/react-query';
import { useServiceCategories } from '../../../../lib/hooks/queries/services/useServiceCategories';
import { createServiceCategory, deleteServiceCategory } from '../../../../api/ServiceCategory';
import { components } from 'react-select';
import { useNavigate } from 'react-router-dom';
import Confirm from '../../../shared/Modal/Confirm/Confirm';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

export default function ServiceEntryComponent({
  handleCPTSearch,
  selectCPT,
  updatePaySchedule,
  practitioners,
  formik
}) {
  const selectInputRef = useRef();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [deleteCategoryModal, setDeleteCategoryModal] = useState();
  const { data } = useServiceCategories();
  const categories = data?.categories || [];

  const selectedPractitioners = (practitioners) => {
    const result = practitioners?.map((practitioner) => {
      return practitioner?.value;
    });
    formik.setFieldValue('practitioner_ids', ia(result) ? result : []);
  };

  const findPractitionerNames = () => {
    const result = practitioners?.map((practitioner) => {
      if (formik.values.practitioner_ids?.includes(practitioner.value)) {
        return practitioner && practitioner;
      }
    });

    return result;
  };
  const openFilestackPicker = () => {
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      maxSize: 3 * 1024 * 1024
    };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };

    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      if (handle) {
        formik.setFieldValue('image', JSON.stringify(handle));
      }
    } catch (error) {}
  };

  const createCategory = async (newCategory) => {
    const onError = (error, code) => {
      if (code === 4) formik.setFieldError('code', error);
    };

    const onSuccess = (data) => {
      queryClient.invalidateQueries(['serviceCategories'], (prevData) => {
        return {
          ...prevData,
          categories: [...prevData.categories, data.category]
        };
      });
      showAlert({
        title: 'New category added',
        color: 'success'
      });

      formik.setFieldValue('category_id', data.category.id);
    };

    createServiceCategory(navigate, { category: { name: newCategory } }, onSuccess, onError);
  };

  const Option = (props) => (
    <components.Option {...props}>
      {props.data.__isNew__ ? (
        <div className="flex cursor-pointer items-center gap-3 text-left text-sm text-neutral-800 hover:bg-primary-50">
          <Icon icon="new-add-circle"></Icon>
          {props.data.value}
        </div>
      ) : (
        <div className="flex w-full justify-between gap-3">
          {props.data.label}
          <button
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteCategoryModal(props.data);
            }}>
            <Icon className="cursor-pointer" icon="trash" color="danger"></Icon>
          </button>
        </div>
      )}
    </components.Option>
  );

  const deleteCategory = async () => {
    const onSuccess = (data) => {
      setDeleteCategoryModal({ label: deleteCategoryModal?.label });
      queryClient.invalidateQueries(['serviceCategories']);
    };
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete categories!",
          color: 'danger'
        });
      }

      setDeleteCategoryModal({ label: deleteCategoryModal?.label });
    };
    await deleteServiceCategory(
      navigate,
      {
        categoryId: deleteCategoryModal?.value
      },
      onSuccess,
      onError
    );
  };

  return (
    <div className="grid !gap-5">
      <div className="grid grid-cols-2 !gap-5">
        <div className="grid gap-4">
          <div data-qa="procedure-code">
            <label className="block pb-2 font-500">Procedure Code:</label>
            <AsyncSelect
              loadOptions={handleCPTSearch}
              onChange={selectCPT}
              value={{ value: formik.values?.procedure_code, label: formik.values?.procedure_code }}
              cacheOptions
              noOptionsMessage={() => 'Start typing the code or description.'}
              id="procedureCodeInput"
            />
          </div>

          <div className="flex w-full items-start gap-2">
            <Input
              label="Name"
              value={formik.values.name}
              name="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && formik.errors.name}
              className="flex flex-1 flex-col"
              id="serviceNameInput"
              data-qa="name-input"
            />
            <ColorPickerRgb
              className="flex min-h-[68px] items-end"
              color={formik.values.color}
              onChange={(color) => formik.setFieldValue('color', color)}></ColorPickerRgb>
          </div>
          <div>
            <div className="flex items-center gap-1">
              <label className="font-500">Category</label>
              <Tippy content="(Start typing to create a category)" className="tippy-dark ">
                <div className="flex h-10">
                  <Icon icon="new-info" color="neutral" data-qa="click-new-category" />
                </div>
              </Tippy>
            </div>
            <Select
              isCreatable={true}
              onChange={(e) => formik.setFieldValue('category_id', e?.value || null)}
              value={
                formik.values.category_id && {
                  label: categories?.find((item) => item.id === formik.values.category_id)?.name
                }
              }
              options={optionify(categories, 'name', 'id')}
              placeholder="Type and select a Category"
              noOptionsMessage={() => 'Start typing to create a category.'}
              error={formik.touched.category_id && formik.errors.category_id}
              id="serviceCategory"
              required
              onCreateOption={(e) => createCategory(e)}
              isClearable={false}
              forwardedRef={selectInputRef}
              components={{ Option }}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label className="m-0 !pr-4 pb-[6px] text-sm font-500 text-neutral-800">Image</label>
          <div
            id="serviceImageBtn"
            onClick={openFilestackPicker}
            className="flex h-auto flex-1 cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100">
            <div className="flex flex-col items-center justify-center">
              {formik.values.image ? (
                <img
                  className="object-contain"
                  style={{ maxWidth: '38%' }}
                  src={`https://cdn.filestackcontent.com/${JSON.parse(formik.values.image).jpg}`}
                  alt="Product Image"
                />
              ) : (
                <>
                  <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                  <p className="text-center text-sm text-neutral-500 ">
                    Click here or drag and <span>drop image to upload</span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Textarea
        label="Description"
        value={formik.values.description}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        name="description"
        id="serviceDescriptionTextarea"
        data-qa="description-input"
      />
      <div className="grid grid-cols-2 !gap-5">
        <Input
          label="Time length (minutes)"
          value={formik.values.time_length}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          name="time_length"
          id="serviceTimeLengthInput"
          data-qa="time-length-input"
          error={formik.touched.time_length && formik.errors.time_length}
        />
        <div>
          <label className="block pb-[10px] font-500">Display In Patient Self Scheduling:</label>
          <Switch
            onChange={(v) => formik.setFieldValue('self_scheduling', v)}
            checked={formik.values?.self_scheduling}
            id="serviceSelfSchedulingSwitch"
            data-qa="service-self-scheduling-switch"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 !gap-5">
        <div data-qa="total-cash-price">
          <CurrencyInput
            key={formik.values.procedure_code}
            label="Total Cash Price:"
            value={formik.values.total_cost_cents}
            onValueChange={(v) => formik.setFieldValue('total_cost_cents', v)}
            id="serviceTotalPriceInput"
            onBlur={() => formik.setFieldTouched('total_cost_cents', true)}
            error={formik.touched.total_cost_cents && formik.errors.total_cost_cents}
          />
        </div>
        <CurrencyInput
          key={formik.values.procedure_code}
          label="Standard Charge:"
          value={formik.values.standard_charge}
          onValueChange={(v) => formik.setFieldValue('standard_charge', v)}
          onChange={formik.handleChange}
          name="standard_charge"
          id="serviceStandardChargeInput"
        />
      </div>

      <div className="grid grid-cols-2 !gap-5">
        <Select
          label="Practitioners Available For this Service"
          placeholder="Available Practitioners"
          isMulti
          value={findPractitionerNames() || []}
          name="gender"
          onChange={(value) => {
            selectedPractitioners(value);
          }}
          options={practitioners}
          id="servicePractitionerSelect"
          data-qa="practitioners-available-for-this-service"
        />
        <Select
          name="charge_type"
          label="Charge type"
          isClearable={false}
          options={
            formik.values.procedure_code
              ? serviceChargeTypes
              : serviceChargeTypes?.filter((type) => type.value !== 'copay')
          }
          id="serviceChargeTypeSelect"
          value={formik.values.charge_type}
          placeholder="Type and select charge type"
          onChange={(value) => formik.setFieldValue('charge_type', value)}
          error={formik.touched.charge_type && formik.errors.charge_type}
        />
      </div>
      {formik.values.cpt && (
        <CurrencyInput
          label="Assumed Medicare Allowable Amount:"
          disabled
          value={formik.values?.cpt?.par_amount}
        />
      )}

      <div className="rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100 !p-4">
        <div className="flex w-full !gap-3">
          <CurrencyInput
            label="State Tax"
            id="productStateTax"
            rightIcon="new-percentage"
            parentClassName="!w-[20%]"
            error={formik?.errors.state_tax_rate}
            value={formik?.values?.state_tax_rate}
            onValueChange={(val) => formik?.setFieldValue('state_tax_rate', +val)}
          />

          <CurrencyInput
            label="Local Tax"
            id="productLocalTax"
            parentClassName="!w-[20%]"
            rightIcon="new-percentage"
            error={formik?.errors.local_tax_rate}
            value={formik?.values?.local_tax_rate}
            onValueChange={(val) => formik?.setFieldValue('local_tax_rate', +val)}
          />
        </div>
      </div>

      {formik.values.paySchedule.length ? (
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-2 gap-4">
            <div className="text-sm font-500">Payer</div>
            <div className="flex flex-col gap-1">
              <p className="text-sm">Expected Actual Allowable Amount:</p>
              <p className="w-max text-sm">Will override assumed medicare allowable amount</p>
            </div>
          </div>
          {formik.values.paySchedule.map((payer, index) => {
            return (
              <Payer
                key={index}
                serviceId={formik.values.serviceId}
                payer={payer.payer}
                allowAmount={payer.allowable_amount_cents}
                customAmount={payer.custom_medicare_allowable_amount_cents}
                updatePaySchedule={updatePaySchedule}
              />
            );
          })}
        </div>
      ) : null}

      <Confirm
        handleOpen={deleteCategoryModal?.value}
        handleClose={() => setDeleteCategoryModal({ label: deleteCategoryModal?.label })}
        handleContinue={deleteCategory}
        title="Delete category"
        message={`Are you sure you want to delete "${deleteCategoryModal?.label}" category?`}
        variant="danger"
      />
    </div>
  );
}
