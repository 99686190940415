import cs from 'classnames';
import NarrativeForm from 'components/practice/charts/ClinicalNote/shared/NarrativeForm';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { permissions } from '../../../../state';
import Icon from '../../../Icon/Icon';
import { ReviewSystems as initialValue } from '../CustomFormInitialStates';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { formatSections } from '../lib/reviewSystemsHelper';
import './ReviewSystems.scss';
import RSPatientBlock from './RSPatientBlock';
import RSPractitionerBlock from './RSPractitionerBlock';
import Header from '../components/Header';
import { clinicalNote as clinicalNoteState } from 'components/state';

const ReviewSystems = ({
  forwardedRef,
  defaultValue,
  isClinicalNote,
  showNarrative = false,
  sectionRef,
  onChange,
  options = []
}) => {
  const [reviewSystems, setReviewSystems] = useState({
    practitioner: [...initialValue],
    patient: [...initialValue],
    noDetails: false,
    narrative: ''
  });
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const {
    // clinicalNote,
    // setClinicalNote = () => {},
    cnDisplaySettings
  } = useClinicalNoteContext() || {};
  const [timer, setTimer] = useState(null);
  const { practice_id } = useRecoilValue(permissions);
  const role = practice_id ? 'practitioner' : 'patient';

  const [syncNarrative, setSyncNarrative] = useState(true);
  const advancedForm = cnDisplaySettings
    ? cnDisplaySettings?.sections?.hp?.ros?.advancedForm
    : true;

  const textAreaRef = useRef();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setReviewSystems(JSON.parse(defaultValue));
      } else {
        setReviewSystems(defaultValue);
      }
    } else {
      setReviewSystems({
        practitioner: [...initialValue],
        patient: [...initialValue]
      });
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(reviewSystems);
  }, [reviewSystems]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'ReviewSystems', type: 'custom' } });
    }
  };

  useImperativeHandle(sectionRef, () => ({
    formData: { ros: reviewSystems }
  }));

  const handleOnChange = (key, value, blockIdx, rowIdx) => {
    const updatedObject = {
      ...reviewSystems,
      [role]: reviewSystems[role].map((block, idx) => {
        if (idx === blockIdx && rowIdx === false) {
          return {
            ...block,
            noBlock: !reviewSystems[role][idx].noBlock
          };
        } else if (idx === blockIdx && rowIdx >= 0) {
          return {
            ...block,
            row: block.row.map((row, idx) => {
              if (idx === rowIdx) {
                return { ...row, [key]: value };
              } else {
                return row;
              }
            })
          };
        } else {
          return block;
        }
      })
    };

    if (syncNarrative) {
      handleNarrative({
        rosObject: updatedObject,
        event: formatSections(updatedObject).trim()
      });
    } else {
      setReviewSystems(updatedObject);
      saveClinicalNote(updatedObject);
    }
  };

  const handleNoDetails = (isTrue) => {
    const updatedObject = { ...reviewSystems, noDetails: isTrue };

    handleNarrative({
      rosObject: updatedObject,
      event: formatSections(updatedObject).trim()
    });
  };

  const handleNarrative = ({
    rosObject = reviewSystems,
    event,
    scrollable = false,
    sync = true
  }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...rosObject, narrative: event };

    setReviewSystems(updatedObject);
    saveClinicalNote(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const saveClinicalNote = (updatedObject) => {
    setClinicalNote &&
      setClinicalNote((prevState) => ({
        ...prevState,
        ros: updatedObject
      }));
  };

  const narrativeOptions = [
    {
      title: (
        <div className="flex justify-between gap-x-[9px] transition-all hover:bg-primary-50">
          <Icon icon="new-clinical-narrative" className="cursor-pointer" />
          <p>Update narrative from template</p>
        </div>
      ),
      onClick: () =>
        handleNarrative({
          event: formatSections(reviewSystems)?.trim(),
          scrollable: true
        })
    }
  ];

  const fromCheckin = location?.pathname.includes('/checkin');

  return (
    <div>
      {/* <Checkbox
        label="No known issues"
        id="non-issues"
        name="noDetails"
        isChecked={reviewSystems.noDetails}
        onChange={handleNoDetails}
        disabled={isClinicalNote && clinicalNote?.locked}
      /> */}
      <input type="hidden" value={JSON.stringify(reviewSystems)} ref={forwardedRef} />
      <Header
        isAdvancedForm={advancedForm}
        // handleNoDetails={handleNoDetails}
        title="Review Systems"
        // emptyState={{
        //   title: 'No symptoms reported',
        //   subtitle:
        //     'Review of Systems is a checklist of symptoms across different parts of your body. Please let us know if you’re experiencing any symptoms.',
        //   label: 'Do you have any symptoms to report?'
        // }}
        // noDetails={reviewSystems.noDetails}
        // fromCheckin={fromCheckin}
        // noDetailsLabel="Do you have any known issues?"
      >
        <div className={cs('ReviewSystems', 'CustomForms', role === 'patient' && 'isPatient')}>
          {role === 'practitioner' && (
            <div className="ReviewSystems__body">
              <div className="ReviewSystems__patient">
                <p className="ReviewSystems__title">Patient response</p>
                {reviewSystems?.patient?.map((block, idx) => (
                  <RSPractitionerBlock locked={true} key={idx} blockIdx={idx} {...block} disabled />
                ))}
              </div>
              <div className="ReviewSystems__practitioner">
                <p className="ReviewSystems__title">Practitioner response</p>
                {reviewSystems?.practitioner?.map((block, idx) => (
                  <RSPractitionerBlock
                    key={idx}
                    blockIdx={idx}
                    onChange={handleOnChange}
                    {...block}
                    locked={isClinicalNote && clinicalNote?.locked}
                  />
                ))}
              </div>
            </div>
          )}
          {role === 'patient' && (
            <div className="flex flex-col !gap-4">
              {reviewSystems?.patient?.map((block, idx) => (
                <RSPatientBlock
                  key={idx}
                  blockIdx={idx}
                  onChange={handleOnChange}
                  {...block}
                  locked={isClinicalNote && clinicalNote?.locked}
                />
              ))}
            </div>
          )}
        </div>
      </Header>
      {cnDisplaySettings && showNarrative && (
        <div className="!mt-3">
          <NarrativeForm
            syncNarrative={syncNarrative}
            setSyncNarrative={setSyncNarrative}
            onChange={(event) => handleNarrative({ event, sync: false })}
            setCurrentForm={setReviewSystems}
            restData={{
              className: 'w-full',
              label: 'Review Of Systems Narrative',
              placeholder: 'Add narrative here',
              id: 'Narrative-ros',
              'data-qa': 'narrative-ros',
              name: 'Narrative-ros',
              value: reviewSystems?.narrative,
              forwardedRef: textAreaRef,
              formName: 'ros',
              customFormId: ''
            }}
            narrativeOptions={advancedForm ? [...narrativeOptions, ...options] : []}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewSystems;
