import React, { useRef } from 'react';
import Select from 'components/shared/Select/Select';
import Textarea from 'components/shared/Textarea/Textarea';

export default function NewReferral({ onSubmit, onChange, errors, newReferral, relatedPractices }) {
  const textareaRef = useRef();

  const optionify = (inputArray) => {
    return inputArray.map((item) => {
      const { id, name } = item.practice;
      return { value: id, label: name };
    });
  };

  return (
    <form onSubmit={onSubmit} className="flex flex-col gap-4">
      <Select
        inputId="practice-select"
        placeholder="Practice"
        name={'practice'}
        label={'Practice'}
        value={{
          label: optionify(relatedPractices)?.find(
            (practice) => practice.value == newReferral.practice
          )?.label
        }}
        onChange={(e) => onChange({ ...e, name: 'practice' })}
        options={optionify(relatedPractices)}
        error={errors.practice.success && errors.practice.message}
      />
      <Textarea
        forwardedRef={textareaRef}
        label="Write a detailed message about the referral you are requesting"
        placeholder={'Explain what referral you need.'}
        name={'body'}
        value={newReferral.body}
        onChange={(e) => onChange({ value: e.target.value, name: 'body' })}
        error={errors.body.success && errors.body.message}
      />
    </form>
  );
}
