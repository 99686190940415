import NewAppointmentModal from 'components/practice/Dashboard/components/NewAppointmentModal';
import { currentPractice } from 'components/practice/practiceState';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, ia, io, snakeToTitleCase } from 'lib/helpers/utility';
import useChartSize from 'lib/hooks/useChartSize';
import moment from 'moment-timezone';
import React, { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import NotFound from '../../../components/NotFound';

const Appointments = (props) => {
  const { dashboardData, item, layout } = props;

  const widgetName = item?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const practice = useRecoilValue(currentPractice);
  let totalAppointments = 0;
  const [showModal, setShowModal] = useState(false);

  dashboardData?.count?.appointments?.forEach((appointment) => {
    totalAppointments += parseInt(appointment.count);
  });

  const formattedData = dashboardData?.appointmentGraphData
    ? Object.entries(dashboardData?.appointmentGraphData).map(([timestamp, data]) => {
        const momentTime = moment.unix(parseInt(timestamp, 10));
        const offsetMinutes = momentTime.tz(practice.timezone).utcOffset();
        const localDate = new Date(momentTime.valueOf() + offsetMinutes * 60000);

        return {
          timestamp: localDate,
          rescheduled: parseFloat(data.rescheduled),
          approved: parseFloat(data.approved),
          canceled: parseFloat(data.cancelled),
          scheduled: parseFloat(data.scheduled),
          checked_in: parseFloat(data.checked_in)
        };
      })
    : [];

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24,
      preventHidingAll: true
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${formatLargeNumber(datum[yKey].toFixed(0))}` };
              }
            }
          }
        }
      }
    },
    series:
      dashboardData?.count?.appointments?.map?.((item) => ({
        type: 'line',
        xKey: 'timestamp',
        yKey: item.status === 'cancelled' ? 'canceled' : item.status,
        yName: `${snakeToTitleCase(item.status === 'cancelled' ? 'canceled' : item.status)} (${
          item.count
        })`,
        stroke: getColorForStatus(item.status === 'cancelled' ? 'canceled' : item.status),
        marker: {
          size: 6,
          fill: getColorForStatus(item.status === 'cancelled' ? 'canceled' : item.status),
          stroke: getColorForStatus(item.status === 'cancelled' ? 'canceled' : item.status)
        }
      })) || [],
    axes: [
      {
        position: 'left',
        type: 'number',
        nice: true
      },
      {
        type: 'time',
        position: 'bottom',
        nice: true
      }
    ]
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  function getColorForStatus(status) {
    switch (status) {
      case 'approved':
        return '#00ABE8';
      case 'canceled':
        return '#DC2626';
      case 'checked_in':
        return '#8191E8';
      case 'rescheduled':
        return '#F59E0B';
      case 'scheduled':
        return '#16A34A';
      default:
        return '#000000';
    }
  }

  if (!io(dashboardData?.appointmentGraphData) || !ia(dashboardData?.count.appointments))
    return (
      <>
        <NotFound
          icon="new-illustration-3"
          iconClassName="!pb-5"
          title="No appointments report yet!"
          description="You can start scheduling new appointments right now."
          createText="Schedule new appointment"
          onCreate={() => setShowModal(true)}
        />
        <NewAppointmentModal showModal={showModal} setShowModal={setShowModal} />
      </>
    );

  return (
    <div>
      <span className="pb-3 text-lg font-500 leading-6 text-primary-900">
        {`${totalAppointments} Appointments`}
      </span>
      <AGChart options={options} key={chartKey} />
    </div>
  );
};

export default Appointments;
