import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';
import { snakeToTitleCase } from 'lib/helpers/utility';
import Popover from 'components/shared/Popovers/Popover/NewPopover';
import { appointmentStatuses as aptStatuses } from 'components/practice/practiceState';

const Progress = () => {
  const { values, setFieldValue } = useFormikContext();
  const appointmentStatuses = useRecoilValue(aptStatuses);

  let currStatusGroup =
    appointmentStatuses.find((item) => item.value === values.status)?.property_of || values.status;

  if (currStatusGroup === 'checkin') {
    currStatusGroup = 'Check-In';
  } else {
    currStatusGroup = snakeToTitleCase(currStatusGroup);
  }

  const appointmentStatus =
    appointmentStatuses.find((item) => item.value === values.status)?.label || values.status;

  const options = appointmentStatuses.map((status) => ({
    ...status,
    onClick: () => setFieldValue('status', status.value)
  }));

  return (
    <div className="flex min-w-[280px] flex-col gap-[6px]">
      <span className="text-sm font-500 text-neutral-800">Appointment progress</span>
      <div className="flex h-[122px] flex-col justify-between gap-5 rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] p-4 pt-5">
        <div className="flex items-center justify-between gap-5 sm:gap-2">
          <span className="whitespace-nowrap text-sm font-500 leading-[22px] text-primary-600">
            Current Stage:
          </span>
          <span
            className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap text-lg font-500 leading-7 text-primary-900"
            title={snakeToTitleCase(currStatusGroup)}>
            {currStatusGroup}
          </span>
        </div>
        <div className="flex items-center justify-between gap-5">
          <span className="text-sm font-500 leading-[22px] text-primary-600">Status: </span>
          <Popover
            className="rounded-md bg-white"
            buttonClassName="max-w-[180px]"
            textClassName="text-ellipsis overflow-hidden whitespace-nowrap"
            panelClassName="max-h-[300px] xl:max-h-[500px] overflow-y-auto z-[200]"
            icon={false}
            text={appointmentStatus}
            buttonTheme={options.find((option) => option.value === values.status)?.color}
            isFixed
            isDropdown
            position="left"
            options={options}
            optionsGroupBy="property_of"
          />
        </div>
      </div>
    </div>
  );
};

export default Progress;
