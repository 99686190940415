import { getTags } from 'api/Tags';

import { Capitalize, formatDate, formatDateAndTime, iaRa } from 'lib/helpers/utility';

import DateHeader from '../DateHeader';
import FaxActions from '../InboundFaxes/components/FaxActions';
import { normalizePhone } from '../components/SendFax/lib';
import TagsCell from '../components/TagsCell';

import { NameCell } from './NameCell';

export const getColDefs = ({ timezone, setPreviewFax }) => [
  {
    field: 'id',
    headerName: 'ID',
    maxWidth: 120
  },
  {
    field: 'name',
    headerName: 'Name',
    cellRenderer: NameCell
  },
  {
    field: 'to',
    headerName: 'To',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const fax = params?.data;

      return normalizePhone(fax?.fax_caller_id, true);
    }
  },
  {
    field: 'created_at',
    headerName: 'Date',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const created_at = params?.data?.created_at;
      return formatDateAndTime(created_at, timezone);
    },
    headerComponent: DateHeader
  },
  {
    field: 'patient_name',
    headerName: 'Patient',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const name = params?.data?.assignedTo?.fullName || 'Not assigned';
      return name;
    }
  },
  {
    field: 'document_sign',
    headerName: 'Assigned to',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const fax = params?.data;
      return (
        iaRa(fax?.document_sign)
          ?.map((sign) => `${sign.user.f_name} ${sign.user.l_name}`)
          .join(', ') || 'Not assigned'
      );
    }
  },
  {
    field: 'pages',
    headerName: 'Pages',
    valueGetter: (params) => {
      if (!!params?.node?.group) {
        return '';
      }
      const fax = params?.data;
      const pages = fax?.num_of_pages || 'N/A';
      return pages;
    }
  },
  {
    field: 'state',
    headerName: 'Status',
    valueFormatter: ({ value }) => Capitalize(value)
  },
  {
    field: 'tags',
    headerName: 'Tags',
    cellRenderer: TagsCell,
    minWidth: 300
  },
  {
    field: 'actions',
    headerName: '',
    cellRenderer: ({ data, queryKey, type }) => (
      <FaxActions setPreviewFax={setPreviewFax} data={data} queryKey={queryKey} type={type} />
    ),
    cellRendererParams: {
      queryKey: 'archivedFaxes',
      type: 'archived'
    },
    maxWidth: 70,
    cellClass: 'no-border',
    pinned: 'right'
  }
];

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: true
};

export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'always',
  enableRangeSelection: true,
  showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};

export const DEFAULT_FILTERS = {
  date: {
    values: null,
    type: 'date-range',
    title: 'Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  id: {
    values: null,
    type: 'search',
    title: 'ID',
    placeholder: 'Search ID',
    preview: (values) => values
  },
  name: {
    values: null,
    type: 'search',
    title: 'Name',
    placeholder: 'Search Name',
    preview: (values) => values
  },
  to: {
    values: null,
    type: 'search',
    title: 'To',
    placeholder: 'Search To',
    preview: (values) => values
  },
  patients: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Patients',
    placeholder: 'Search Patient',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  tags: {
    type: 'select',
    multiple: true,
    values: [],
    title: 'Tags',
    placeholder: 'Select fax tags',
    icon: false,
    queryKey: 'tags',
    queryFn: getTags,
    params: { kind: 'fax' }
  },
  state: {
    type: 'checkbox',
    options: ['archived', 'success', 'failed'],
    values: null,
    title: 'Status',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
