import React, { useCallback, useEffect, useMemo } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { mapValues } from 'lib/helpers/utility';
import { useAiPromptTemplateDescription } from 'lib/hooks/queries/admin/intelligence/useAiPromptTemplateDescription';

import {
  DEFAULT_COLUMN_DEFS,
  DEFAULT_FILTERS,
  GRID_OPTIONS
} from 'components/admin/pages/Intelligence/Prompt/helpers';
import AGTable from 'components/shared/AGTable/AGTable';
import { CustomStatusBarCount } from 'components/shared/AGTable/CustomStatusBarCount';
import { CustomStatusBarPagination } from 'components/shared/AGTable/CustomStatusBarPagination';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const AdminIntelligencePromptDescriptionTable = () => {
  const { pageSize, page, setPage, sort, filters, setFilters, colDefs } = useTableContext();
  const { isLoading, isFetching, data } = useAiPromptTemplateDescription({
    params: {
      pageSize,
      page,
      sort,
      filters: mapValues(filters)
    },
    options: {},
    dependencies: [pageSize, page, mapValues(filters)],
    // dependencies: [limit, page],
    name: 'ai_prompt_template_descriptions'
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLoading } = useOutletContext();

  useEffect(() => {
    setLoading(isLoading || isFetching);
  }, [isLoading, isFetching]);

  const onPageChange = useCallback((currentPage) => setPage(currentPage), [setPage]);

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        {
          statusPanel: CustomStatusBarCount,
          statusPanelParams: {
            data: data?.results,
            page,
            pageSize
          },
          align: 'left'
        },
        {
          statusPanel: CustomStatusBarPagination,
          statusPanelParams: {
            data: data?.results,
            page,
            pageSize,
            onPageChange,
            pageCountOverride: Math.ceil((data?.results?.length || 0) / pageSize) + 1
          },
          align: 'right'
        }
      ]
    }),
    [data?.results, page, pageSize, onPageChange]
  );

  return (
    <div className="flex h-full flex-col overflow-hidden !pb-0">
      <AGTable
        loading={isLoading || isFetching}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        animateRows={true}
        data={data?.results?.results}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        isRowSelectable={() => true}
        customClassName="ag-grid-interactive"
        enableRangeSelection={true}
        statusBar={statusBar}
        reactiveCustomComponents
        onRowClicked={(e) => {
          setSearchParams({ promptId: e?.data?.id });
        }}
      />
    </div>
  );
};

export default withErrorBoundary(AdminIntelligencePromptDescriptionTable);
