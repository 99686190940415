import { mergeGoalNarratives } from 'components/shared/Forms/Custom/lib/goalsFormatFormHelper';
import { ia, isEmpty } from 'lib/helpers/utility';
import React, { useEffect, useState } from 'react';
import RenderNarrativeValue from './RenderNarrativeValue';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const Goals = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const goals = clinicalNote?.goals || [];
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;
  const [narrative, setNarrative] = useState('');

  if (customClinicalNote && !ia(goals)) {
    return null;
  }

  const initializeNarrative = () => {
    const { narrative } = mergeGoalNarratives(goals);
    setNarrative(narrative.trim());
  };

  useEffect(() => {
    initializeNarrative();
  }, [goals]);

  return (
    <div className="bg-white !px-6 !py-4">
      <h3 className="text-sm font-500 text-[#003A4B] print:text-xl">Goals</h3>
      {isTemplateChecked && (
        <div className="grid grid-cols-[repeat(auto-fill,minmax(200px,1fr))] !gap-5 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-2 first-of-type:!border-t-0">
          {goals.map((goal, goalIndex) => (
            <div key={goalIndex}>
              <p className="text-xs text-neutral-700 first-letter:capitalize">{goal.goal}</p>
              <ul className="!pl-6">
                {goal.objectives.map((objective, objectiveIndex) => (
                  <li className="list-disc !pt-2 first-letter:capitalize" key={objectiveIndex}>
                    <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                      {objective.objective}
                    </p>
                    <ul className="!pl-5">
                      {objective.treatments.map((treatment, treatmentIndex) => (
                        <li
                          className="first-letter:capitalize"
                          key={treatmentIndex}
                          style={{ listStyle: 'circle' }}>
                          <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                            {treatment.treatment}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}

      {isNarrativeChecked && !isEmpty(narrative) && (
        <div className="!mt-2">
          <RenderNarrativeValue narrative={narrative} />
        </div>
      )}
    </div>
  );
};

export default Goals;
