import { Capitalize, formatDate, formatDateAndTime } from 'lib/helpers/utility';

const getFinalValue = (value) => {
  if (['allergies', 'vaccines', 'conditions', 'vitals', 'medications'].includes(value))
    return Capitalize(value);
  return (
    {
      medicalHistory: 'Medical History',
      familyHistory: 'Family History',
      socialHistory: 'Social History',
      surgicalHistory: 'Surgical History',
      weightLossAdministration: 'Weight Loss Administration'
    }?.[value] || ''
  );
};

export const getColDefs = (timezone) => [
  {
    field: 'type',
    headerName: 'Form',
    cellClass: 'no-border h-[20px]',
    sort: 'desc',
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'agGroupCellRenderer',
    suppressRowClickSelection: true,
    valueFormatter: ({ value }) => getFinalValue(value)
  },
  {
    field: 'createdAt',
    cellClass: 'no-border h-[20px]',
    headerName: 'Last Updated',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone),
    maxWidth: 150
  }
];

export const DEFAULT_COLUMN_DEFS = { flex: 1 };

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true,
  masterDetail: true,
  suppressRowClickSelection: true,
  detailCellRendererParams: {
    detailGridOptions: {
      columnDefs: [
        { field: 'created_at', headerName: 'Date' },
        { field: 'preview', headerName: 'Preview' }
      ]
    },
    getDetailRowData: (params) => {
      params.successCallback(params.data.detailGridData);
    }
  }
  // onRowSelected: (params) => {
  //   if (params?.node?.selected) {
  //     params.node.setExpanded(true);
  //     params.node.setSelected(true);
  //   }
  // }
};

export const DEFAULT_FILTERS = {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Service',
    preview: (values) =>
      formatDate(values.startDate, null, false) + ' - ' + formatDate(values.endDate, null, false)
  },
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: [],
    values: [],
    title: 'Patients',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  providers: {
    type: 'search',
    multiple: true,
    options: [],
    values: [],
    title: 'Providers',
    preview: (values) => values.map((v) => v.label).join(', ')
  }
};
