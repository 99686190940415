import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Popover, PopoverPanel } from '@headlessui/react';
import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';
import { arrayToObject, ia } from 'lib/helpers/utility';
import useOutsideClick from 'lib/hooks/useOutsideClick';

import EmptyProductsPackages from 'components/Patient/sell/EmptyProductsPackages';
import NotFound from 'components/practice/NewAppointment/components/NotFound';
import { ServicesFromPackages } from 'components/practice/NewAppointment/components/Sections/Pricing/components/ProceduresProduct/components';
import InfiniteScroll from 'components/shared/InfiniteScroll/InfiniteScroll';
import Allowed from 'components/shared/Permissions/Allowed';
import { permissions } from 'components/state';

import Icon from '../../../../shared/Icon/Icon';

import { SelectedItems } from './components';
import ListItem from './components/ListItem';
import { handleAddItems, isProcedureChecked } from './lib';

const AddProceduresProducts = ({
  label,
  endpoint,
  queryKey,
  type,
  packageList = [],
  formik,
  originalProceduresProducts,
  addPermissions = '',
  formatData
}) => {
  const ref = useRef(null);
  const infiniteScrollRef = useRef(null);
  const { selectedService, setSelectedService, setSelectedTimeLength, additionalTimes } =
    useNewAppointmentContext() || {};
  const userPermissions = useRecoilValue(permissions);

  const [list, setList] = useState([]);

  const servicesObject = useMemo(() => arrayToObject(list, 'id', 'image'), [list]);

  const { values, errors, touched, setFieldValue } = formik;
  const selectedItems = [...values[type]];
  const [newItems, setNewItems] = useState([]);
  const isServices = type === 'procedures';

  const scrollToElement = () => {
    if (ref?.current) ref?.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleAddItemsFunc = () => {
    handleAddItems({
      type,
      list,
      newItems,
      setNewItems,
      setFieldValue,
      selectedItems,
      selectedService,
      additionalTimes,
      apptId: values?.id,
      setSelectedService,
      setSelectedTimeLength
    });
  };

  useOutsideClick(ref, () => ia(newItems) && handleAddItemsFunc());

  useEffect(() => {
    if (!selectedItems.length) {
      if (!!setSelectedService) setSelectedService(null);

      if (!!setSelectedTimeLength && type === 'procedures') {
        setFieldValue('appointmentLength', null);
        setSelectedTimeLength(null);
      }
    } else {
      const itemWithLargestTime = selectedItems.reduce((prev, current) => {
        const currTimeLength = current?.time_length || current?.timeLength;
        const prevTimeLength = prev?.time_length || prev?.timeLength;
        return currTimeLength > prevTimeLength ? current : prev;
      });

      if (itemWithLargestTime) {
        if (!!setSelectedService) setSelectedService(itemWithLargestTime);

        const timeLength = itemWithLargestTime?.time_length || itemWithLargestTime?.timeLength;

        if (!!setSelectedTimeLength && type === 'procedures') {
          setFieldValue('appointmentLength', timeLength);
          setSelectedTimeLength({ value: timeLength, label: `${timeLength} min` });
        }
      }
    }
  }, [values[type]]);

  const isClinicalNoteLocked =
    values?.medicalHistory?.state === 'finalized' && values?.medicalHistory?.locked;

  const handleScroll = () => {
    if (infiniteScrollRef?.current) {
      infiniteScrollRef?.current?.handleScroll();
    }
  };

  return (
    <div ref={ref}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Allowed requiredPermissions={addPermissions}>
              <Popover.Button
                className="w-full p-0"
                data-qa={`add-btn-type-${type}`}
                onClick={() => {
                  open && handleAddItemsFunc();
                  !values?.id && !open && setTimeout(() => scrollToElement(), 100);
                }}>
                <div
                  className={cs(
                    'flex cursor-pointer items-center justify-between rounded-lg !border border-solid border-transparent border-l-primary-50 border-r-primary-100 bg-gradient-to-r from-primary-50 to-primary-100 !px-4 !py-2',
                    open && '!border-primary-500'
                  )}>
                  <Icon
                    icon="new-dropdown-select"
                    className="grid h-9 w-9 cursor-pointer place-content-center rounded-full bg-white"
                  />

                  <label className="!mb-0 cursor-pointer text-sm font-500 text-primary-500">
                    {`Add ${label}`}
                  </label>

                  <Icon
                    color="primary"
                    icon="new-chevron-down"
                    className={cs(
                      open && 'rotate-180',
                      'grid h-6 w-6 cursor-pointer place-content-center rounded-full bg-primary-200'
                    )}
                  />
                </div>
              </Popover.Button>
            </Allowed>

            <PopoverPanel
              className={cs(
                'absolute z-20 mt-[6px] flex w-full  flex-col overflow-hidden rounded-md border border-solid border-neutral-300 bg-white shadow-[0px_1px_2px_0px__rgba(16,24,40,0.05)]',
                values?.id && 'top-[-415px]'
              )}>
              <div
                ref={infiniteScrollRef}
                onScroll={handleScroll}
                className="relative flex h-[430px] flex-col gap-2 overflow-auto border-b border-neutral-300 px-2 pb-2">
                {isServices && ia(packageList) && (
                  <ServicesFromPackages
                    newItems={newItems}
                    values={formik?.values}
                    packageList={packageList}
                    setNewItems={setNewItems}
                    additionalTimes={additionalTimes}
                  />
                )}
                <InfiniteScroll
                  ref={infiniteScrollRef}
                  EmptyComponent={() =>
                    type === 'products' ? <EmptyProductsPackages type="product" /> : <NotFound />
                  }
                  endpoint={endpoint}
                  queryKey={queryKey}
                  setData={setList}
                  formatData={formatData}
                  data={list}
                  withSearch>
                  {({ item, index }) => (
                    <ListItem
                      item={item}
                      index={index}
                      formik={formik}
                      type={type}
                      newItems={newItems}
                      permissions={userPermissions}
                      setNewItems={setNewItems}
                      isChecked={
                        type === 'procedures'
                          ? !!isProcedureChecked(newItems, item)
                          : newItems.some((sItem) => sItem.id === item.id)
                      }
                    />
                  )}
                </InfiniteScroll>
              </div>
            </PopoverPanel>
          </>
        )}
      </Popover>

      <SelectedItems
        type={type}
        label={label}
        values={values}
        setFieldValue={setFieldValue}
        selectedItems={selectedItems}
        servicesObject={servicesObject}
        list={type === 'products' ? list : []}
        isClinicalNoteLocked={isClinicalNoteLocked}
        originalProceduresProducts={originalProceduresProducts}
      />

      {type === 'procedures' && touched?.procedures && errors?.procedures && (
        <p className="!pt-2 text-sm text-danger-500">{errors?.procedures}</p>
      )}

      {type === 'products' && ia(additionalTimes) && ia(values?.products) && (
        <p className="text-sm text-warning-500">
          Products are only available for the current appointment.
        </p>
      )}
    </div>
  );
};

export default AddProceduresProducts;
