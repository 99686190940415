import { checkPatientArrival, markPatientArrival } from 'api/Appointment';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const PatientArrivalCOnfirmation = () => {
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const { appointmentId } = useParams();
  const navigate = useNavigate();

  const checkPatientArrivalLog = async () => {
    const { appointmentToday, logExists } = await checkPatientArrival(navigate, {
      appointment_id: appointmentId
    });

    if (appointmentToday && !logExists) {
      setConfirmationModalVisible(true);
    }
  };

  useEffect(() => {
    checkPatientArrivalLog();
  }, []);

  const handleMarkPatientArrival = async () => {
    await markPatientArrival(navigate, { appointment_id: appointmentId });
    setConfirmationModalVisible(false);
    setLoading(false);
  };

  return (
    <>
      <Confirm
        handleOpen={!!confirmationModalVisible}
        handleClose={() => setConfirmationModalVisible(false)}
        handleContinue={handleMarkPatientArrival}
        title="Are you with the patient?"
        icon="calendar-tick"
        message="Please confirm that you are with the patient before proceeding."
        secondaryBtnTxt="No"
        primaryBtnTxt="Yes"
        variant="primary"
        loading={loading}
      />
    </>
  );
};

export default PatientArrivalCOnfirmation;
