import React, { useMemo, useState } from 'react';

import { ia } from 'lib/helpers/utility';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import DatePicker from 'components/shared/DatePicker/DatePicker';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Input from 'components/shared/Input/Input';

import { amountToCents } from '../lib/amountToCents';
import { calculateAdjustmentPaid } from '../lib/calculateAdjustmentPaid';

const Adjustments = ({
  procedureAdjustment = {},
  setClaim = () => {},
  procedureIndex = '',
  procedureCharge = 0
}) => {
  const [paidError, setPaidError] = useState(false);

  const updateAdjustment = ({ index, key, value }) => {
    const idx = Number(procedureIndex);

    setClaim((prevClaim) => {
      const updatedClaim = { ...prevClaim };
      if (index) {
        const procedureAdjustments =
          (updatedClaim?.claimAdjustments?.adjustments &&
            updatedClaim?.claimAdjustments?.adjustments[idx]) ||
          [];

        const nestedAdjustments = ia(procedureAdjustments.adjustments)
          ? [...procedureAdjustments.adjustments]
          : [];

        if (key === 'code') {
          const group = (value?.slice(0, 2) || '')?.trim();
          const code = (value?.slice(2) || '')?.trim();

          nestedAdjustments[index - 1] = {
            ...nestedAdjustments[index - 1],
            group,
            code
          };
        } else {
          nestedAdjustments[index - 1] = {
            ...nestedAdjustments[index - 1],
            [key]: value
          };
        }

        calculateAdjustmentPaid({
          procedureAdjustment: updatedClaim.claimAdjustments.adjustments[idx],
          procedureCharge,
          setPaidError
        });

        updatedClaim.claimAdjustments.adjustments[idx] = {
          ...procedureAdjustments,
          adjustments: nestedAdjustments
        };

        return updatedClaim;
      } else {
        const procedureAdjustments = updatedClaim.claimAdjustments.adjustments[idx] || {};

        updatedClaim.claimAdjustments.adjustments[idx] = {
          ...procedureAdjustments,
          [key]: value
        };

        calculateAdjustmentPaid({
          procedureAdjustment: updatedClaim.claimAdjustments.adjustments[idx],
          procedureCharge,
          setPaidError
        });

        return updatedClaim;
      }
    });
  };

  const computedPaid = useMemo(() => {
    return (
      calculateAdjustmentPaid({
        procedureAdjustment,
        procedureCharge,
        setPaidError
      }) || 0
    );
  }, [procedureAdjustment, procedureCharge]);

  return (
    <tr>
      <td colSpan="10">
        <div className="flex flex-col">
          <div>Primary Payer Adjustments (ERA/EOP)</div>
          <div className="flex flex-row gap-2">
            {Array.from({ length: 4 }).map((_, adjIndex) => {
              const adjustment = procedureAdjustment?.adjustments?.[adjIndex] || {};
              const groupCode = `${adjustment?.group || ''}${adjustment?.code || ''}`;

              return (
                <div key={adjIndex} className="flex gap-2">
                  <Input
                    label="Code"
                    value={groupCode}
                    onChange={(e) =>
                      updateAdjustment({ index: adjIndex + 1, key: 'code', value: e.target.value })
                    }
                  />
                  <CurrencyInput
                    label="Amount"
                    id="amount"
                    placeholder="Amount"
                    value={amountToCents(adjustment?.amount)}
                    onValueChange={(value) =>
                      updateAdjustment({ index: adjIndex + 1, key: 'amount', value })
                    }
                  />
                </div>
              );
            })}
            <DatePicker
              label="Remit Date"
              iconIsRight
              value={procedureAdjustment?.paid_date}
              onChange={(value) => updateAdjustment({ key: 'paid_date', value })}
            />
            <CurrencyInput
              label="Paid"
              id="paid"
              placeholder="Paid"
              error={paidError && 'Adjustment paid cannot exceed procedure charge'}
              value={amountToCents(computedPaid)}
              onValueChange={(value) => updateAdjustment({ key: 'paid', value })}
            />
          </div>
        </div>
      </td>
    </tr>
  );
};

export default withErrorBoundary(Adjustments);
