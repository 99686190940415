import RenderNarrativeValue from 'components/practice/charts/ClinicalNote/PreviewNote/Rendered/components/RenderNarrativeValue';
import { createAllergiesNarrativeHelper } from 'components/shared/Forms/Custom/lib/allergiesNarrativeHelper';
import { createFamilyHistoryNarrative } from 'components/shared/Forms/Custom/lib/familyHistoryNarrativeHelper';
// import { formatSections } from 'components/shared/Forms/Custom/lib/generalFormatFormHelperV1';
import { createMedicationNarrative } from 'components/shared/Forms/Custom/lib/medicationNarrativeHelper';
import { createPastMedicalHistoryNarrative } from 'components/shared/Forms/Custom/lib/pastMedicalHistoryNarrativeHelper';
import { createSocialHistoryNarrative } from 'components/shared/Forms/Custom/lib/socialHistoryNarrativeHelper';
import { createSurgicalHistoryNarrative } from 'components/shared/Forms/Custom/lib/surgicalHistoryNarrativeHelper';
import { createVaccineNarrative } from 'components/shared/Forms/Custom/lib/vaccineNarrativeHelper';
import { createWeightLossNarrative } from 'components/shared/Forms/Custom/lib/weightLossAdministrationNarrativeHelper';
import React from 'react';

const typeTitles = {
  medicalHistory: (data) => createPastMedicalHistoryNarrative(data),
  surgicalHistory: (data) => createSurgicalHistoryNarrative(data),
  socialHistory: (data) => createSocialHistoryNarrative(data),
  familyHistory: (data) => createFamilyHistoryNarrative(data),
  medicationHistory: (data) => createMedicationNarrative(data),
  vaccines: (data) => createVaccineNarrative(data),
  weightLossAdministration: (data) => createWeightLossNarrative(data?.row),
  allergies: (data) => createAllergiesNarrativeHelper(data)
};

const FormDataItemTitle = ({ type, data, activeOption }) => {
  let title = typeTitles[type](data, activeOption);

  return <RenderNarrativeValue narrative={title} className="!m-0" />;
};

export default FormDataItemTitle;
