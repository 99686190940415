import React from 'react';
import Icon from 'components/shared/Icon/Icon';
import Button from 'components/shared/Buttons/Button';

const EmptyTableState = ({ children, className = '' }) => {
  return (
    <div className={`flex h-[50vh] w-full flex-col items-center gap-5 ${className}`}>
      {children}
    </div>
  );
};

EmptyTableState.Icon = ({ icon = 'no-data-found', customIcon }) => {
  return customIcon ? customIcon : <Icon icon={icon} />;
};

EmptyTableState.Text = ({
  title = 'No Data Available',
  description = 'There is no data to display.'
}) => {
  return (
    <div>
      <h3 className="text-base text-primary-900">{title}</h3>
      <p className="text-neutral-600">{description}</p>
    </div>
  );
};

EmptyTableState.Button = ({ text = 'Create New', icon = 'new-task-square', onClick }) => {
  return (
    <Button
      className="pointer-events-auto"
      onClick={onClick}
      text={text}
      icon={icon}
      iconColor="white"
    />
  );
};

export default EmptyTableState;
