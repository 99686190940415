import React, { useMemo, useRef, useState } from 'react';

import { AgGridReact } from 'ag-grid-react';

import { useEraContext } from 'lib/context/EraContext/EraContext';

import { formatUnmatchedClaims } from './lib/formatUnmatchedClaims';
import {
  DEFAULT_COLUMN_DEFS_BOLD,
  UNMATCHED_CLAIMS_COLUMN_DEFS,
  getRowClassRules
} from './lib/helpers';

const UnMatchedClaims = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const gridRef = useRef(null);

  const { era } = useEraContext();

  const onCellMouseOver = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(event.data?.claimId);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const onCellMouseOut = (event) => {
    if (event?.data?.claimId === hoveredId) return;
    setHoveredId(null);
    gridRef.current?.api?.refreshCells({ force: true });
  };

  const rowData = useMemo(
    () => formatUnmatchedClaims({ claims: era?.unmatched_claims }),
    [era?.unmatched_claims]
  );
  const columnDefs = useMemo(() => UNMATCHED_CLAIMS_COLUMN_DEFS, []);

  return (
    <div className="ag-theme-quartz shadow-none">
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={DEFAULT_COLUMN_DEFS_BOLD}
        rowData={rowData}
        domLayout="autoHeight"
        onCellMouseOver={onCellMouseOver}
        onCellMouseOut={onCellMouseOut}
        rowClassRules={getRowClassRules('', hoveredId)}
        suppressRowHoverHighlight
      />
    </div>
  );
};

export default UnMatchedClaims;
