import React, { useState } from 'react';
import { getCurrentStep, getStepsLeft } from '../../lib/helpers';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import { ReactFormBuilder } from 'react-form-builder2';
import { FormBuilderToolbar } from 'components/shared/Forms/Custom/Register';
import Header from './components/Header';
import Preview from './components/Preview';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';

const Builder = ({ currentBreadcrumb, formTypes }) => {
  const [previewMode, setPreviewMode] = useState(false);
  const [disabledPreview, setDisabledPreview] = useState(true);
  const { breadCrumbs, formChange, formik } = useFormBuilderContext();
  const currentBreadcrumbStep = getCurrentStep(breadCrumbs, currentBreadcrumb);
  const getBreadcrumbsLeft = getStepsLeft(breadCrumbs, currentBreadcrumb);
  const chartData = [
    { name: 'completed', value: currentBreadcrumbStep, fills: '#4ADE80' },
    { name: 'not-completed', value: getBreadcrumbsLeft, fills: '#fff' }
  ];

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex h-full flex-col">
        {previewMode ? (
          <Preview
            setPreviewMode={setPreviewMode}
            disabledPreview={disabledPreview}
            setDisabledPreview={setDisabledPreview}
          />
        ) : (
          <>
            <Header
              chartData={chartData}
              currentBreadcrumbStep={currentBreadcrumbStep}
              getBreadcrumbsLeft={getBreadcrumbsLeft}
              setPreviewMode={setPreviewMode}
              disabledPreview={!formik?.values?.form?.json?.fields}
              setDisabledPreview={setDisabledPreview}
              typeOptions={formTypes}
            />
            <ReactFormBuilder
              onPost={(e) => formChange(e.task_data)}
              data={formik?.values?.form?.json?.fields ? formik?.values?.form?.json?.fields : null}
              toolbarItems={FormBuilderToolbar}
            />
          </>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Builder;
