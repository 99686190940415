import { formatDate, formatDateAndTime } from 'lib/helpers/utility';

export const getColDefs = (timezone) => [
  {
    field: 'created_at',
    headerName: 'Creation Date',
    valueFormatter: ({ value }) => formatDateAndTime(value, timezone),
    cellClass: 'no-border',
    sort: 'desc'
    // headerCheckboxSelection: true,
    // checkboxSelection: true
  },
  {
    field: 'treatment_team',
    headerName: 'Provider',
    valueGetter: (params) =>
      params.data.treatment_team
        ?.map((item) => (item.provider ? item.provider?.label : null))
        .filter((label) => label !== null)
        .join(', ')
  },
  {
    field: 'icd10',
    headerName: 'ICD Codes'
  },
  {
    field: 'starts_at',
    headerName: 'Start Date',
    valueFormatter: ({ value }) => formatDate(value, timezone),
    cellClass: 'no-border'
  },
  {
    field: 'ends_at',
    headerName: 'End Date',
    valueFormatter: ({ value }) => formatDate(value, timezone),
    cellClass: 'no-border'
  }
];

export const DEFAULT_COLUMN_DEFS = {};

export const GRID_OPTIONS = {
  animateRows: true,
  groupSelectsChildren: true
};
