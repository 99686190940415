import { requestApi } from './Api';

export const getCalendarEvents = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/calendar_event/get',
    params
  });

export const getCalendarEvent = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/calendar_event/get_one',
    params
  });

export const createCalendarEvent = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/calendar_event/create',
    params
  });

export const updateCalendarEvent = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/calendar_event/update',
    params
  });

export const deleteCalendarEvent = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/calendar_event/delete',
    params
  });
