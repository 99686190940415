import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-big-calendar';
import TimeGrid from 'react-big-calendar/lib/TimeGrid';

export default function ResourceView(props) {
  const {
    date,
    localizer,
    max = localizer.endOf(new Date(), 'day'),
    min = localizer.startOf(new Date(), 'day'),
    scrollToTime = localizer.startOf(new Date(), 'day'),
    events,
    startAccessor,
    endAccessor
  } = props;

  const currRange = useMemo(() => ResourceView.range(date, { localizer }), [date, localizer]);

  return (
    <TimeGrid
      {...props}
      startAccessor={startAccessor}
      endAccessor={endAccessor}
      date={date}
      eventOffset={15}
      events={events}
      localizer={localizer}
      max={max}
      min={min}
      range={currRange}
      scrollToTime={scrollToTime}
    />
  );
}

ResourceView.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.object,
  max: PropTypes.instanceOf(Date),
  min: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date)
};

ResourceView.range = function (date, { localizer }) {
  return [localizer.startOf(date, 'day')];
};

ResourceView.navigate = (date, action, { localizer }) => {
  switch (action) {
    case Navigate.PREVIOUS:
      return localizer.add(date, -1, 'day');
    case Navigate.NEXT:
      return localizer.add(date, 1, 'day');
    default:
      return date;
  }
};

ResourceView.title = function (date, { localizer }) {
  return localizer.format(date, 'dayHeaderFormat');
};
