import { isEmpty, pick } from 'lodash';
import { formatDates } from './formatDates';
import { getDirtyValues } from 'lib/helpers/utility';

export const checkUnsavedChanges = (values, initialValues) => {
  const fields = [
    // appointment
    'starts_at',
    'ends_at',
    'resources',
    'status',
    'location',
    'virtual_link',
    'tag_ids',
    'practice_note',
    'practitioner_id',
    'form_ids',
    //invoices
    'tax',
    'discount',
    'products',
    'procedures',
    'modifiedCopayment',
    'modifiedDeductible',
    'modifiedCoinsurance',
    // timeline
    'checkin',
    'timeline_status'
  ];

  const { products, procedures, last_applied, tax, discount } = initialValues?.invoice || {};

  const otherFields = {
    products: products || [],
    procedures: procedures || [],
    modifiedCopayment: last_applied?.co_payment.value ?? null,
    modifiedDeductible: last_applied?.deductible.value ?? null,
    modifiedCoinsurance: last_applied?.co_insurance.value ?? null,
    tax,
    discount
  };

  const oldAppt = { ...pick(initialValues, fields), ...otherFields };
  const newAppt = { ...oldAppt, ...pick(values, fields) };

  // use formatDates because on date change the date is converted from UTC to local time
  const formattedValues = formatDates(oldAppt);
  const formattedInitialValues = formatDates(newAppt);

  const dirtyValues = getDirtyValues(formattedInitialValues, formattedValues);
  return !isEmpty(dirtyValues);
};
