import { useFormik } from 'formik';
import * as Yup from 'yup';

const validator = require('validator');

const UpdatePatientValidationSchema = (editableData) =>
  Yup.object().shape({
    ...(editableData?.emailEdit && { email: Yup.string().email() }),
    ...(editableData?.phoneEdit && {
      phone: Yup.string().test('phone', '', (value, validationContext) => {
        const { createError } = validationContext;

        if (value === undefined || value === null || value === '') {
          return createError({ message: 'Phone number required' });
        }

        if (!validator.isMobilePhone(value)) {
          return createError({ message: 'Please provide a correct phone number' });
        }

        return true;
      })
    }),
    dob: Yup.date().required('Date of Birth required'),
    gender: Yup.string().required('Gender required'),
    state: Yup.string().required('State required'),
    address_ln_1: Yup.string().required('Address required'),
    city: Yup.string().required('City required'),
    zip: Yup.string().required('Zip code required')
  });

export default function useUserFormik({ patient, address, user, submitUpdate, editableData }) {
  const formik = useFormik({
    initialValues: {
      id: patient?.id,
      f_name: patient?.f_name || user?.f_name || '',
      m_name: patient?.m_name || user?.m_name || '',
      l_name: patient?.l_name || user?.l_name || '',
      dob: patient?.dob || user?.dob || '',
      gender: patient?.gender || user?.gender || '',
      email: patient?.email || user?.email || '',
      alt_email: patient?.alt_email || user?.alt_email || '',
      alt_email_notify: patient?.alt_email_notify || user?.alt_email_notify || false,
      phone: patient?.phone || user?.phone || '',
      alt_phone: patient?.alt_phone || user?.alt_phone || '',
      alt_phone_notify: patient?.alt_phone_notify || user?.alt_phone_notify || false,
      race: patient?.race || user?.race || '',
      ssn: patient?.ssn || '',
      marital_status: patient?.marital_status || user?.marital_status || '',
      personal_id: patient?.personal_id || user?.personal_id || '',
      emergency_contact: {
        name: patient?.emergency_contact?.name || user?.emergency_contact?.name || '',
        phone: patient?.emergency_contact?.phone || user?.emergency_contact?.phone || '',
        relationship:
          patient?.emergency_contact?.relationship || user?.emergency_contact?.relationship || ''
      },
      profile_photo: patient?.profile_photo || user?.profile_photo || '',
      address_ln_1: address?.address_ln_1 || user?.address_object?.address_ln_1 || '',
      address_ln_2: address?.address_ln_2 || user?.address_object?.address_ln_2 || '',
      city: address?.city || user?.address_object?.city || '',
      state: address?.state || user?.address_object?.state || '',
      zip: address?.zip || user?.address_object?.zip || ''
    },
    enableReinitialize: true,
    validationSchema: UpdatePatientValidationSchema(editableData),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newPatient = {
          id: values?.id,
          f_name: values?.f_name,
          m_name: values?.m_name,
          l_name: values?.l_name,
          dob: values?.dob,
          gender: values?.gender,
          email: values?.email,
          alt_email: values?.alt_email,
          alt_email_notify: values?.alt_email_notify,
          ssn: values?.ssn,
          marital_status: values?.marital_status,
          phone: values?.phone,
          alt_phone: values?.alt_phone,
          alt_phone_notify: values?.alt_phone_notify,
          race: values?.race,
          personal_id: values?.personal_id,
          emergency_contact: {
            name: values?.emergency_contact?.name,
            phone: values?.emergency_contact?.phone,
            relationship: values?.emergency_contact?.relationship
          }
        };
        const newAddress = {
          address_ln_1: values?.address_ln_1,
          address_ln_2: values?.address_ln_2,
          city: values?.city,
          state: values?.state,
          zip: values?.zip
        };

        if (values?.profile_photo !== patient?.profile_photo) {
          let profilePicture = values?.profile_photo;
          try {
            const parsedProfile =
              typeof values?.profile_photo === 'string'
                ? JSON.parse(profilePicture)
                : profilePicture;
            profilePicture = parsedProfile?.jpg?.length > 0 ? parsedProfile?.jpg : profilePicture;
          } catch (e) {
            profilePicture = values?.profile_photo;
          }

          newPatient['profile_photo'] = { jpg: profilePicture };
        }

        setSubmitting(true);
        await submitUpdate(newPatient, newAddress);

        setSubmitting(false);
      } catch (e) {
        console.error(e);
      }
    }
  });

  return formik;
}
