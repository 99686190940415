import React from 'react';
import { ia } from '../utility';

export default function formatEvents({ calendarEvents = [], currentView }) {
  let formattedEvents = [];
  if (currentView === 'provider') {
    formattedEvents = calendarEvents
      .filter((ev) => ia(ev.practitioner_ids))
      .map((event, eventIndex) => {
        let endsAt = new Date(event.end_date);
        let startsAt = new Date(event.start_date);

        return event.practitioner_ids.map((practitioner_id, practitionerIndex) => ({
          formatted: true,
          id: event.id,
          starts_at: startsAt,
          ends_at: endsAt,
          resourceId: practitioner_id,
          eventType: 'event',
          color: event.color,
          practitioner_ids: event.practitioner_ids,
          event_title: event.title,
          title: (
            <div onClick={() => {}} data-qa={`provder-view-${eventIndex}-${practitionerIndex}`}>
              {event.all_day && 'All day '}
              {event.title}
            </div>
          )
        }));
      })
      .flat();
  } else {
    formattedEvents = calendarEvents.map((event, index) => {
      let startsAt = new Date(event.start_date);
      let endsAt = new Date(event.end_date);
      return event.formatted
        ? event
        : {
            formatted: true,
            id: event.id,
            starts_at: startsAt,
            ends_at: endsAt,
            eventType: 'event',
            color: event.color,
            practitioner_ids: event.practitioner_ids,
            event_title: event.title,
            title: (
              <div onClick={() => {}} data-qa={`event-${index}`}>
                {event.all_day && 'All day '}
                {event.title}
              </div>
            )
          };
    });
  }

  return formattedEvents;
}
