import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { formatDate, formatPhoneNumber } from 'lib/helpers/utility';

import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';

import EditPracticeContainer from './EditPracticeContainer';

const OverviewBlock = ({ practice }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const childComponentRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  //  check periodically if the isLoading state in the child component has changed
  useEffect(() => {
    const interval = setInterval(() => {
      if (childComponentRef.current?.isLoading !== isLoading) {
        setIsLoading(childComponentRef.current?.isLoading);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [isLoading]);

  const handleOnSave = (e) => {
    e.preventDefault();
    childComponentRef.current.onSubmit();
  };
  const ModalFooter = () => (
    <div className="flex w-full items-center justify-between">
      <Button onClick={() => setShowModal(false)} text="Close" color="neutral" outlined />
      <Button
        onClick={handleOnSave}
        data-qa="save-btn"
        text="Save"
        className="ml-auto"
        loading={isLoading}
      />
    </div>
  );

  return (
    <div className="shadow-default relative flex flex-wrap items-center justify-between gap-20 !rounded-xl bg-white !py-2 !pl-14 !pr-4">
      <div
        className="absolute -left-3 top-1/2 ml-3 flex h-8 w-8 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full border border-solid border-neutral-300 bg-white shadow-small hover:!bg-neutral-50"
        onClick={() => navigate('/admin/practices')}>
        <Icon icon="new-arrow-left" className="flex cursor-pointer" />
      </div>
      <div className="flex flex-wrap items-center gap-x-20 gap-y-4">
        <div className="flex items-center !gap-4">
          <div>
            <Avatar
              fullName={practice?.name}
              id={practice?.id}
              image={practice?.header_photo}
              size="3xl"
            />
          </div>
          <div className="flex flex-col">
            <p
              className="w-max text-lg font-500 text-primary-900"
              data-dd-privacy="allow"
              data-public>
              {practice?.name}
            </p>
            <div className="flex gap-1">
              <p className="text-xs text-neutral-800">Phone:</p>
              <p className="text-xs text-neutral-800"> {formatPhoneNumber(practice?.phone)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-9">
          <div className="flex flex-col gap-[2px]" data-dd-privacy="allow" data-public>
            <p className="text-sm font-500 text-primary-800">ID</p>
            <p className="text-xs text-neutral-800">{practice?.id}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Address</p>
            <p className="text-xs text-neutral-800">{practice?.practiceAddress?.fullAddress}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Email</p>
            <p className="text-xs text-neutral-800">{practice?.email}</p>
          </div>
          <div className="flex flex-col gap-[2px]">
            <p className="text-sm font-500 text-primary-800">Joined</p>
            <p className="text-xs text-neutral-800">{formatDate(practice?.creation_date)}</p>
          </div>
        </div>
      </div>
      <Allowed requiredPermissions="practice.update">
        <div className="flex items-center gap-2">
          <Button
            data-qa="edit-profile-btn"
            text="Edit profile"
            type="secondary"
            size="small"
            icon="new-edit"
            onClick={() => setShowModal(true)}
          />
        </div>
      </Allowed>
      <Modal
        slideFromRight
        handleOpen={showModal}
        handleClose={() => setShowModal(false)}
        className="w-[700px]"
        title="Practice information"
        footer={<ModalFooter />}>
        <EditPracticeContainer
          ref={childComponentRef}
          practice={practice}
          handleClose={() => setShowModal(false)}
        />
      </Modal>
    </div>
  );
};

export default OverviewBlock;
