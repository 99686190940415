import React from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../Icon/Icon';
import cs from 'classnames';

export const Colors = [
  '#ECEFF0',
  '#CDD5D8',
  '#8FA0A7',
  '#526268',
  '#1B2123',
  '#FEE2E2',
  '#FCA5A5',
  '#EF4444',
  '#B91C1C',
  '#7F1D1D',
  '#B0EAFF',
  '#54D2FF',
  '#13B9FF',
  '#0085B5',
  '#004F6B',
  '#E0E3F9',
  '#B5BFF2',
  '#8191E8',
  '#1F35AC',
  '#0A1239',
  '#DCFCE7',
  '#86EFAC',
  '#22C55E',
  '#15803D',
  '#14532D',
  '#FEF3C7',
  '#FCD34D',
  '#F59E0B',
  '#B45309',
  '#78350F'
];

export const DarkColors = [
  '#8FA0A7',
  '#526268',
  '#1B2123',
  '#EF4444',
  '#B91C1C',
  '#7F1D1D',
  '#13B9FF',
  '#0085B5',
  '#004F6B',
  '#8191E8',
  '#1F35AC',
  '#0A1239',
  '#22C55E',
  '#15803D',
  '#14532D',
  '#F59E0B',
  '#B45309',
  '#78350F'
];

const ColorPicker = ({
  color = Colors[0],
  handleSelectColor = () => {},
  label,
  dark,
  buttonClassName,
  colorCircleClassName
}) => {
  return (
    <Popover className="relative z-50">
      <label className="block pb-1 font-500 leading-6">{label}</label>
      <Popover.Button
        className={cs(
          'flex h-10 w-[70px] items-center gap-[10px] rounded-md bg-white px-[10px] py-2 shadow-sm',
          buttonClassName
        )}>
        {({ open }) => (
          <>
            <div
              className={cs('bg-${color} h-6 w-6 rounded-full ', colorCircleClassName)}
              style={{ background: color }}
              data-qa="color-select"
            />
            <Icon
              icon="chevron-up"
              className={` ${!open ? 'rotate-180 transform' : ''} transition-transform`}
            />
          </>
        )}
      </Popover.Button>

      <Popover.Panel
        className={cs(
          'z-100 absolute !mt-2 flex rounded-md bg-white shadow-md',
          dark ? 'w-[120px]' : 'w-[180px]'
        )}>
        {({ close }) => (
          <div
            className={cs('grid w-full gap-[8px] p-[10px]', dark ? 'grid-cols-3' : 'grid-cols-5')}>
            {[...(dark ? DarkColors : Colors)].map((col, i) => (
              <div
                data-qa={`color-select-${i}`}
                key={i}
                className="z-50 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full"
                style={{ background: col }}
                onClick={() => {
                  handleSelectColor(col);
                  close();
                }}>
                {col === color && <Icon icon="checkmark" color="white" size="20px" />}
              </div>
            ))}
          </div>
        )}
      </Popover.Panel>
    </Popover>
  );
};

export default ColorPicker;
