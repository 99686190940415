import cs from 'classnames';

import Icon from 'components/shared/Icon/Icon';
import Popup from 'components/shared/Popup/Popup';

const FilterItem = ({ filter, index, applyFilter, makeDefault, deleteFilter }) => (
  <Popup
    trigger={(open) => (
      <div
        data-qa={`saved-filter-${index}`}
        className={cs(
          'flex cursor-pointer justify-between gap-[6px] !px-[12px] py-[6px] text-sm hover:bg-primary-50',
          open && 'bg-primary-50'
        )}>
        <span className="text-sm text-primary-900 first-letter:uppercase">{filter.name}</span>
        <Icon
          icon="new-chevron-right"
          className="cursor-pointer"
          stroke
          shade={400}
          color="neutral"
        />
      </div>
    )}
    width={160}
    contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
    offsetX={-10}
    on={['hover', 'focus']}
    position={['right center']}>
    <div className="flex !w-[160px] flex-col py-[6px]">
      <div
        data-qa="apply"
        onClick={() => applyFilter(filter)}
        className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
        Apply
      </div>
      <div
        data-qa="set-remove-as-default"
        onClick={() => makeDefault(filter.id, !filter.is_default)}
        className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
        {!filter.is_default ? 'Set as default' : 'Remove default'}
      </div>
      <div
        data-qa="delete"
        onClick={() => deleteFilter(filter.id)}
        className="flex cursor-pointer !px-[12px] py-[6px] text-sm text-primary-900 hover:bg-primary-50">
        Delete
      </div>
    </div>
  </Popup>
);

export default FilterItem;
