import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getTablePreferences } from 'api/Preferences';

export const useTableLimit = () => {
  const navigate = useNavigate();
  return useQuery(['table-limit'], () => getTablePreferences(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    select: (data) => data.preferences
  });
};
