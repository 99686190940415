const { formatDate, mString } = require('lib/helpers/utility');

export const columnDefs = (timezone) => [
  {
    field: 'id',
    headerName: 'Invoice ID',
    maxWidth: 150,
    headerCheckboxSelection: true,
    checkboxSelection: true,
    cellRenderer: 'agGroupCellRenderer'
  },
  {
    field: 'created_at',
    headerName: 'Invoice Date',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    maxWidth: 150
  },
  {
    field: 'appointment.starts_at',
    headerName: 'DOS',
    valueFormatter: ({ value }) => formatDate(value, timezone, true),
    maxWidth: 130
  },
  {
    field: 'patient.fullName',
    headerName: 'Patient'
  },
  {
    field: 'total_amount',
    headerName: 'Amount',
    valueFormatter: ({ value }) => mString(value || 0),
    aggFunc: 'sum'
  },
  {
    field: 'invoice_balance',
    headerName: 'Balance',
    aggFunc: 'sum',
    valueFormatter: ({ value }) => mString(value || 0)
  }
];

export const DEFAULT_COL_DEFS = { enableRowGroup: true, resizable: true };

export const GRID_OPTIONS = {
  rowGroupPanelShow: 'always',
  animateRows: true
};
