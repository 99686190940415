import React from 'react';
import CSVReader from 'react-csv-reader';
import Button from 'components/shared/Buttons/Button';
import S3Uploader from './S3Uploader';

const UploadFile = ({ show, setShow, parserOptions, handleFileLoaded, file, id, headers }) => {
  return (
    <div className="mx-auto my-auto flex h-fit w-fit flex-col justify-center overflow-hidden rounded-lg border-none bg-white p-2 transition duration-300">
      <span className="py-1 text-xl font-bold">Patient Import</span>
      <span className="text-l py-1 font-bold">Step 1/4</span>
      <div className="h-60vh w-50vw flex min-h-[20rem] min-w-[20rem] flex-col overflow-auto">
        <CSVReader
          label="Please select CSV Patient file."
          parserOptions={parserOptions}
          onFileLoaded={handleFileLoaded}
        />
        <div className="my-30">
          <S3Uploader id={id} headers={headers} />
        </div>
      </div>
      <div className="flex h-14 flex-row justify-between p-1">
        <Button
          text="View Past Imports"
          loading={show?.loading}
          onClick={() => setShow({ ...show, stage: 'pastImports' })}
          color="neutral"
          shade={400}
        />

        <Button
          text="Next"
          loading={show?.loading}
          onClick={() => setShow({ ...show, stage: 'generateEmails' })}
          color="success"
          disabled={!file.fileName}
        />
      </div>
    </div>
  );
};

export default UploadFile;
