/* eslint-disable max-lines */
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { uniqueId } from 'filestack-js';
import { useFormik } from 'formik';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { draftFax, getFaxUser, previewFax, sendFax } from 'api/Fax';

import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import DocumentModal from 'components/shared/DocumentEditor/DocumentModal';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import NewTagSelect from 'components/shared/Tags/components/Select/NewSelect';
import Textarea from 'components/shared/Textarea/Textarea';
import state from 'components/state';

import ContactsModal from '../../../Contacts/components/ContactsModal';
import CoverPages from '../../CoverPages/CoverPages';

import DocsModal from './components/DocsModal/DocsModal';
import DragAndDropFiles from './components/DragAndDropFiles';
import { formatFaxData, formatNumber } from './lib';

const SendFax = forwardRef(
  ({ fax, setLoading = () => {}, setSendFaxModalVisible = () => {} }, ref) => {
    const [contactsModalVisible, setContactsModalVisible] = useState(false);
    const [docsModalVisible, setDocsModalVisible] = useState(false);
    const [previewFaxModalVisible, setPreviewFaxModalVisible] = useState(false);
    const [fax_user, setFax_user] = useState(null);
    const user = useRecoilValue(state.userState);
    const [pdfData, setPdfData] = useState(null);
    const [resetTags, setResetTags] = useState(false);
    const [error, setError] = useState({ number: null, file: null });
    const fileInputRef = useRef(null);
    const navigate = useNavigate;

    const formik = useFormik({
      initialValues: {
        subject: fax?.subject || '',
        notes: fax?.notes || '',
        deliver_to: fax?.deliver_to || '',
        fax_numbers: fax?.fax_numbers
          ? fax?.fax_numbers.map((number) => ({ fax_number: number }))
          : [{ fax_number: '' }],
        phone_number: '',
        contacts: fax?.fax_contacts || [],
        files: fax?.files || [],
        existing_files: [],
        type: '',
        tag_ids: fax?.tag_ids || []
      },
      enableReinitialize: true,
      onSubmit: async (values, { setSubmitting, resetForm }) => {
        setSubmitting(true);
        const coverPageFile = await getCoverPage();
        setLoading((prev) => ({ prev, [values.type]: true }));

        switch (values.type) {
          case 'send':
            sendFax(
              navigate,
              formatFaxData({ ...values, files: [coverPageFile, ...values.files] })
            ).then((data) => {
              if (data.code === 0) {
                showAlert({ message: 'Fax sent successfully!', color: 'success' });
                setResetTags(true);
                resetForm();
                setSendFaxModalVisible(false);
              }
              if (data.code !== 0) {
                if (data.error_message) {
                  showAlert({
                    title: data.error_message.name,
                    message: data.error_message.message,
                    color: 'danger'
                  });
                } else if (data.error) {
                  showAlert({
                    title: data.error,
                    color: 'danger'
                  });
                } else {
                  showAlert({ message: 'Fax failed to send', color: 'danger' });
                }
              }

              setLoading({ draft: false, send: false, preview: false });
            });

            break;
          case 'draft':
            draftFax(navigate, formatFaxData(values)).then((data) => {
              if (data.code === 0) {
                showAlert({ message: 'Fax saved successfully', color: 'success' });
                setResetTags(true);
                resetForm();
              } else {
                showAlert({ message: 'Fax failed to save as draft', color: 'danger' });
              }
              setLoading({ draft: false, send: false, preview: false });
            });
            break;
          case 'preview':
            previewFax(
              navigate,
              formatFaxData({ ...values, files: [coverPageFile, ...values.files] })
            ).then((data) => {
              const uint8Array = new Uint8Array(data?.buffer?.data);
              const blobFile = new Blob([uint8Array], { type: 'application/pdf' });
              const blobUrlFile = URL.createObjectURL(blobFile);
              setPdfData(blobUrlFile);
              setPreviewFaxModalVisible(true);
              setLoading({ draft: false, send: false, preview: false });
            });
            break;
          default:
        }
      }
    });
    const { setFieldValue, values, handleChange } = formik;

    useImperativeHandle(ref, () => ({
      submitForm: (type) => {
        if (formik.isSubmitting) {
          return;
        }
        setFieldValue('type', type).then(() => {
          const shouldSend =
            values.fax_numbers.some((item) => item.fax_number !== '') ||
            (ia(values.contacts) && ia(values.files));

          if (type === 'send' && shouldSend) {
            formik.submitForm();
            return;
          }

          if (type === 'draft') {
            formik.submitForm();
            return;
          }

          if (
            (values.fax_numbers.some((item) => item.fax_number !== '') ||
              ia(values.contacts) ||
              type === 'preview') &&
            ia(values.files)
          ) {
            setFieldValue('type', type).then(() => {
              formik.submitForm();
            });
            return;
          }

          setError((prev) => ({
            ...prev,
            [!ia(values.files) ? 'file' : 'number']: `At least one ${
              !ia(values.files) ? 'file' : 'number'
            } is required`
          }));
        });
      }
    }));

    const getCurrentFaxUser = async () => {
      const { fax_user } = await getFaxUser(navigate);
      setFax_user(fax_user);
    };
    useEffect(() => {
      getCurrentFaxUser();
    }, []);

    const handleDivClick = () => {
      fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
      let filesArray = Array.from(e.target.files);
      filesArray = filesArray.map((file) => {
        file['id'] = file.size;
        file['draggableId'] = uniqueId(12);
        return file;
      });
      setFieldValue('files', [...values.files, ...filesArray]);
      setError((prev) => ({ ...prev, file: null }));
      e.target.value = null;
    };

    const handleSelectContacts = (contacts) => {
      setError((prev) => ({ ...prev, number: null }));
      setFieldValue('contacts', contacts);
      setContactsModalVisible(false);
    };

    const handleInsertFiles = (files) => {
      setError((prev) => ({ ...prev, file: null }));

      setFieldValue('files', [...values.files, ...files]);
      setDocsModalVisible(false);
    };

    const handleChangeFaxNumber = (e, index) => {
      setError((prev) => ({ ...prev, number: null }));
      setFieldValue(`fax_numbers[${index}].fax_number`, e.target.value);
    };

    const handleClearContact = (contact) => {
      setFieldValue(
        'contacts',
        values.contacts.filter((item) => item.id !== contact.id)
      );
    };

    const getCoverPage = async () => {
      const input = document.getElementById('cover-page');
      const canvas = await html2canvas(input, { scale: 1.5, useCORS: true });
      const imgData = canvas.toDataURL('image/jpeg', 0.8);
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        // eslint-disable-next-line max-lines
        format: 'a4',
        compress: true
      });

      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      const pdfBuffer = pdf.output('arraybuffer');

      const file = {
        buffer: pdfBuffer,
        name: 'cover-page' + '.pdf',
        originalname: 'cover-page' + '.pdf',
        mimetype: 'application/pdf',
        size: pdfBuffer.byteLength,
        fieldname: 'files'
      };

      function createFileFromBuffer(buffer, fileName, mimeType) {
        const blob = new Blob([buffer], { type: mimeType });
        const file = new File([blob], fileName, { type: mimeType });
        file['id'] = file.size;
        return file;
      }

      const buffer = new Uint8Array(file.buffer).buffer;
      const createdFile = createFileFromBuffer(buffer, file.name, file.mimetype);
      return createdFile;
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    const handleDrop = (e) => {
      e.preventDefault();
      let droppedFiles = e.dataTransfer.files;
      droppedFiles = Array.from(droppedFiles).map((file) => {
        file['id'] = file.size;
        file['draggableId'] = uniqueId(12);
        return file;
      });
      setFieldValue('files', [...values.files, ...droppedFiles]);
      setError((prev) => ({ ...prev, file: null }));
    };

    return (
      <div className="h-full flex-1">
        <div className="flex h-full md:flex-col">
          <div className="flex-1 !p-6">
            <div className="flex items-center justify-between">
              <label htmlFor="">Recipient number</label>
              <label
                htmlFor=""
                className="cursor-pointer text-xs text-neutral-600"
                onClick={() => setContactsModalVisible(true)}>
                Search from contacts{' '}
                <span className="inline">
                  <Icon
                    icon="user-square"
                    className="inline cursor-pointer"
                    data-qa="search-from-contacts"
                  />
                </span>
              </label>
            </div>
            <div className="flex w-full flex-col gap-2">
              {values.contacts.map((contact) => (
                <div
                  key={contact.id}
                  className="mt-2 flex min-h-[40px] items-center justify-between rounded-md border border-solid border-neutral-200 bg-white !px-3 !py-2">
                  <p>{contact.first_name + ' ' + contact.last_name}</p>
                  <Icon icon="cross" onClick={() => handleClearContact(contact)} className="mt-1" />
                </div>
              ))}
            </div>
            {!ia(formik.values.contacts) &&
              formik.values.fax_numbers.map(({ fax_number }, index) => (
                <Input
                  data-qa="fax-number"
                  key={index}
                  required
                  placeholder="(500) 234-5689"
                  hideNumberArrows
                  onChange={(e) => handleChangeFaxNumber(e, index)}
                  name="fax_number"
                  value={fax_number}
                  onBlur={() => {
                    formatNumber(fax_number, index, setFieldValue);
                  }}
                  className="!mt-2"
                  //   value={phoneNumber}
                  //   onChange={(e) => setPhoneNumber(e.target.value)}
                />
              ))}

            {/* 
         //! This is for adding additional fax numbers 
          <label
            htmlFor=""
            className="cursor-pointer text-xs text-neutral-600"
            onClick={() =>
              setFieldValue('fax_numbers', [...values.fax_numbers, { fax_number: '' }])
            }>
            <span className="inline">
              <Icon icon="add-circle-small" className="inline cursor-pointer" />{' '}
            </span>
            Add aditional recipient
          </label> */}
            {error.number && <p className="!pt-2 text-sm text-danger-500">{error.number}</p>}

            <div className="!mt-6 flex flex-col gap-4">
              {/* <Select label="Cover page" options={[]} /> */}

              <Input
                name="deliver_to"
                label="Deliver to"
                onChange={handleChange}
                data-qa="deliver-to"
                value={values?.deliver_to}
              />
              <Input
                data-qa="enter-subject"
                placeholder="Enter subject"
                name="subject"
                label="Subject"
                onChange={handleChange}
                value={values?.subject}
              />
              <div className="flex flex-col gap-y-1">
                <p className="text-sm font-500">Tags</p>
                <NewTagSelect
                  kind="fax"
                  menuPortalTarget={document.body}
                  currTags={formik?.values?.tag_ids || []}
                  setTagIds={(ids) => formik.setFieldValue('tag_ids', ids)}
                  resetTags={resetTags}
                  emptyStateText="Select tags"
                  outlined
                />
              </div>

              <Textarea
                data-qa="enter-efax-note"
                placeholder="Enter eFax note"
                label="Note"
                name="notes"
                onChange={handleChange}
                textareaClassName="!min-h-[120px]"
                value={values?.notes}
                maxLength={255}
              />
            </div>
          </div>
          <div className=" flex-1 gap-2 bg-white !p-6">
            <div className="flex items-center justify-between">
              <label className=" pb-1" htmlFor="">
                Attachments <span className="inline text-red-600">*</span>
              </label>
              <label
                htmlFor=""
                className="flex cursor-pointer"
                onClick={() => setDocsModalVisible(true)}>
                Search from existing documents
                <Icon
                  icon="new-attach-square"
                  className="!ml-1 inline cursor-pointer"
                  data-qa="search-from-existing-documents"
                />
              </label>
            </div>
            <input
              type="file"
              onChange={handleFileChange}
              ref={fileInputRef}
              data-qa="input-file"
              style={{ display: 'none' }}
              accept="image/*,.pdf,.txt"
              multiple
            />
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onClick={handleDivClick}
              data-qa="upload-attachment"
              className="flex h-[176px]  cursor-pointer flex-col items-center justify-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100">
              <div className="flex flex-col items-center justify-center">
                <>
                  <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                  <p className="pt-1 text-center text-sm font-400 text-neutral-500">
                    Click here or drag and <span>drop files to upload</span>
                  </p>
                </>
              </div>
            </div>

            {error.file && <p className="!pt-2 text-sm text-danger-500">{error.file}</p>}
            <div className="!mt-4">
              <DragAndDropFiles
                {...{
                  selectedFiles: values.files,
                  setFieldValue
                }}
              />
            </div>
          </div>
        </div>
        {contactsModalVisible && (
          <ContactsModal
            {...{
              contactsModalVisible,
              setContactsModalVisible,
              handleSelectContacts,
              selectedContacts: values.contacts,
              selectOne: true
            }}
          />
        )}
        {docsModalVisible && (
          <DocsModal
            {...{
              docsModalVisible,
              setDocsModalVisible,
              handleInsertFiles
            }}
          />
        )}

        {previewFaxModalVisible && (
          <DocumentModal
            src={pdfData}
            kind="fax"
            handleOpen={previewFaxModalVisible}
            handleClose={() => setPreviewFaxModalVisible(false)}
            title="Preview Fax"
          />
        )}
        <div style={{ position: 'absolute', left: '-9999px', top: '-9999px' }}>
          <CoverPages
            {...{
              date: moment(new Date()).format('MMMM D, YYYY, h:mm A'),
              senderFax: fax_user?.fax_number,
              senderName: user?.professionalFullName,
              subject: values.subject,
              recipientFax: values.fax_numbers[0]?.fax_number || values.contacts[0]?.fax_number,
              recipientPhone: values?.contacts[0]?.phone_number || null,
              recipientName: values?.contacts[0]
                ? values.contacts[0]?.first_name + ' ' + values.contacts[0]?.last_name
                : values.deliver_to
                  ? values.deliver_to
                  : null,
              note: values.notes,
              values
            }}
          />
        </div>
      </div>
    );
  }
);

SendFax.displayName = 'SendFax';
export default SendFax;
