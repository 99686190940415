import cs from 'classnames';
import moment from 'moment';

import { ia } from 'lib/helpers/utility';

import Popover from 'components/shared/Popovers/Popover/Popover';

import TreatmentPlanStatus from '../TreatmentPlanStatus';

export const treatmentPlanCols = ({
  setTreatmentPlanToDelete = () => {},
  setShowTPModal = () => {},
  setSelectedTreatmentPlan = () => {}
}) => [
  {
    field: 'treatment_frequency_date.startDate',
    headerName: 'Start Date'
  },
  {
    field: 'treatment_frequency_date.endDate',
    headerName: 'End Date (Review Date)',
    cellRenderer: ({ value, data }) => {
      const endDateMoment = moment(data.treatment_frequency_date?.endDate, 'MM/DD/YYYY');
      const currentDate = moment();
      const isExpired = endDateMoment.isBefore(currentDate, 'day');

      return <p className={cs(isExpired && '!text-danger-500')}> {value}</p> || 'N/A';
    }
  },
  {
    field: 'icd10',
    headerName: 'ICD Codes',
    valueFormatter: ({ value }) => (!!value ? value.join(', ') : 'N/A')
  },
  {
    field: 'cpt_codes',
    headerName: 'CPT Codes',
    valueFormatter: ({ value }) =>
      ia(value) ? value?.map((i) => i?.procedure_code).join(', ') : 'N/A'
  },
  {
    field: 'createdBy.fullName',
    headerName: 'Created By',
    valueFormatter: ({ value }) => (!!value ? value : 'N/A')
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'no-border',
    cellRenderer: (params) => {
      if (params.node.group || !params?.data) {
        return null;
      }
      return (
        <div className="flex h-full items-center justify-center">
          <TreatmentPlanStatus
            signers={params?.data?.document_sign}
            classNames="flex min-w-[80px] justify-center items-center"
          />
        </div>
      );
    },
    resizable: false
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellRenderer: (params) => {
      return (
        <div
          className="flex h-full w-full items-center justify-center actions-column"
          onClick={(e) => e.stopPropagation()}>
          <Popover
            options={[
              {
                icon: 'new-document-preview',
                label: 'Preview',
                onClick: () => {
                  setSelectedTreatmentPlan(params?.data);
                  setShowTPModal({ open: true, loadView: 'preview' });
                }
              },
              {
                icon: 'new-edit',
                label: 'Edit',
                onClick: () => {
                  setSelectedTreatmentPlan(params?.data);
                  setShowTPModal({ open: true, loadView: 'edit' });
                }
              },
              {
                icon: 'trash',
                label: 'Delete',
                onClick: () => {
                  setTreatmentPlanToDelete(params?.data?.id);
                }
              }
            ]}
            optionIconColor="primary"
            position="left"
            isFixed
            icon="new-context-menu-dots"
          />
        </div>
      );
    },
    maxWidth: 100,
    pinned: 'right'
  }
];
