import React from 'react';
import Accordion from '../../../../shared/Accordion';
import FunctionalImpairment from './FunctionalImpairment';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const FunctionalImpairmentSection = ({ components, sectionRef, withStyle = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);

  return (
    <Accordion
      withStyle={withStyle}
      sectionRef={sectionRef}
      updateFormType={{
        name: 'Functional Impairment',
        parentKey: 'progress',
        childKey: 'functionalImpairment'
      }}
      title={components?.functionalImpairment?.title}
      id={components?.functionalImpairment?.id}
      disabled={clinicalNote?.locked}>
      <FunctionalImpairment sectionRef={sectionRef} />
    </Accordion>
  );
};

export default FunctionalImpairmentSection;
