import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { formatEmptyValues } from '../lib/formatEmptyValues';

const NewAndUpdateCPT = ({ mode, selectedCPTCode, hideModal, showModal }) => {
  const NewCodeValidationSchema = Yup.object().shape({
    code: mode === 'create' ? Yup.string().required('Code is required') : Yup.string().nullable()
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const formik = useFormik({
    initialValues: {
      code: mode === 'update' ? selectedCPTCode?.code : '',
      name: mode === 'update' ? selectedCPTCode?.name : '',
      concept_id: mode === 'update' ? selectedCPTCode?.concept_id : null,
      base_unit: mode === 'update' ? selectedCPTCode?.base_unit : null,
      limiting_charge: mode === 'update' ? selectedCPTCode?.limiting_charge : null,
      par_amount: mode === 'update' ? selectedCPTCode?.par_amount : null,
      non_par_amount: mode === 'update' ? selectedCPTCode?.non_par_amount : null
    },
    enableReinitialize: true,
    validationSchema: NewCodeValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      try {
        if (mode === 'create') {
          await handleCreate(values);
        } else if (mode === 'update') {
          await handleUpdate(values);
        }
        setSubmitting(false);
        hideModal();
      } catch (error) {
        Honeybadger.notify(`Form submission error: ${error}`);
        setSubmitting(false);
      }
    }
  });
  const handleCreate = async () => {
    try {
      const resData = await requestApi({
        url: `/api/codes/cpt/create`,
        params: {
          newCode: formatEmptyValues({ values: formik.values })
        },
        navigate
      });
      const { code, cpt, redirect } = resData || {};

      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          hideModal();
          showAlert({ title: 'New CPT Code added.', color: 'primary' });
          queryClient.invalidateQueries(['cptCodes']);
          break;

        case 1:
          showAlert({ title: cpt?.error, color: 'danger' });
          Honeybadger.notify(`cpt error: ${cpt?.error}`);
          break;

        default:
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleCreate: ${error}`);
    }
  };

  const handleUpdate = async () => {
    try {
      const resData = await requestApi({
        url: `/api/codes/cpt/update`,
        params: {
          fields: formatEmptyValues({ values: formik.values })
        },
        navigate
      });

      const { code, cpt, redirect } = resData || {};

      switch (code) {
        case -1:
          navigate(redirect);
          break;

        case 0:
          hideModal();
          showAlert({ title: 'CPT Code updated successfully', color: 'primary' });
          queryClient.invalidateQueries(['cptCodes']);
          break;

        default:
          Honeybadger.notify(`There's been an unexpected error, please try again later.`);
          break;
      }
    } catch (error) {
      Honeybadger.notify(`handleUpdate newAndUpdateCpt: ${error}`);
    }
  };
  return (
    <Modal
      handleOpen={showModal}
      handleClose={hideModal}
      title={`${mode === 'update' ? 'Update' : 'New'} CPT Code`}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={hideModal} />
          {mode === 'update' ? (
            <Button text="Update" onClick={handleUpdate} data-qa="update-btn" />
          ) : (
            <Button text="Create" onClick={handleCreate} data-qa="create-btn" />
          )}
        </div>
      }>
      <div className="grid gap-y-4">
        {mode === 'create' && (
          <Input
            data-qa="code"
            id="code"
            name="code"
            label="Code"
            error={formik?.errors?.code}
            value={formik?.values?.code}
            onChange={formik?.handleChange}
          />
        )}

        <Input
          data-qa="name"
          id="name"
          name="name"
          label="Name"
          value={formik?.values?.name}
          error={formik?.errors?.name}
          onChange={formik && formik?.handleChange}
        />

        <Input
          data-qa="conecpt-id"
          id="concept_id"
          name="concept_id"
          label="Concept ID"
          value={formik?.values?.concept_id}
          error={formik?.errors?.concept_id}
          onChange={formik && formik?.handleChange}
        />
        <Input
          data-qa="base_unit"
          id="base_unit"
          name="base_unit"
          label="Base Unit"
          value={formik?.values?.base_unit}
          error={formik?.errors?.base_unit}
          onChange={formik && formik?.handleChange}
        />

        <CurrencyInput
          prefix="$"
          allowDecimals={true}
          decimalSeparator="."
          id="limiting_charge"
          label="Limiting Charge"
          value={formik?.values?.limiting_charge}
          className="!h-10 !rounded-md border border-neutral-100"
          onValueChange={(v) => formik.setFieldValue('limiting_charge', v)}
        />

        <CurrencyInput
          prefix="$"
          id="par_amount"
          label="Par Amount"
          allowDecimals={true}
          decimalSeparator="."
          value={formik?.values?.par_amount}
          className="!h-10 !rounded-md border border-neutral-100"
          onValueChange={(v) => formik.setFieldValue('par_amount', v)}
        />

        <CurrencyInput
          prefix="$"
          id="non_par_amount"
          allowDecimals={true}
          decimalSeparator="."
          label="Non Par Amount"
          value={formik?.values?.non_par_amount}
          className="!h-10 !rounded-md border border-neutral-100"
          onValueChange={(v) => formik.setFieldValue('non_par_amount', v)}
        />
      </div>
    </Modal>
  );
};

export default NewAndUpdateCPT;
