import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { useInsuranceProfiles } from 'lib/hooks/queries/insuranceProfiles/useInsuranceProfiles';
import useScrollBlock from 'lib/hooks/useScrollBlock';

import EligibilityWrapper from 'components/practice/Eligibility/EligibilityWrapper';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import EditInsuranceProfile from './EditInsuranceProfile';
import InsuranceProfile from './InsuranceProfile';
import InsuranceProfileMobile from './InsuranceProfileMobile';
import NewInsuranceProfile from './NewInsuranceProfile';

export default function InsuranceProfiles({
  patientId,
  selected,
  setSelected,
  practiceId,
  noInsurancePress,
  kiosk,
  practitionerId,
  fakeModal,
  setFakeModal,
  getCheckinStatus,
  patient
}) {
  const [blockScroll, allowScroll] = useScrollBlock();
  const [showNewInsuranceProfile, setShowNewInsuranceProfile] = useState(false);
  const [showEditInsuranceProfile, setShowEditInsuranceProfile] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [isOpen, setIsOpen] = useState(initialIsOpenState);
  const { device } = useUIContext();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  const { data } = useInsuranceProfiles({ params: { userId: patientId } });
  const insuranceProfiles = data?.insuranceProfile || [];
  const fromCheckin = pathname.includes('/checkin') || pathname.includes('/instant-packet');
  const initialIsOpenState = insuranceProfiles?.reduce((acc, curr) => {
    acc[curr.id] = false;
    return acc;
  }, {});

  //new insurance profile modal
  const handleNewInsuranceProfile = () => {
    !kiosk && blockScroll();
    !kiosk && setShowNewInsuranceProfile(true);
    kiosk && setFakeModal({ type: 'new' });
  };
  const hideNewInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowNewInsuranceProfile(false);
    kiosk && setFakeModal(null);
  };

  const handleEditInsuranceProfile = (data, index) => {
    !kiosk && blockScroll();
    !kiosk && setShowEditInsuranceProfile({ data, index });
    kiosk && setFakeModal({ type: 'edit', data, index });
  };
  const hideEditInsuranceProfile = () => {
    !kiosk && allowScroll();
    !kiosk && setShowEditInsuranceProfile(null);
    kiosk && setFakeModal(null);
  };

  const newProfile = (newProfile) => {
    const insuranceProfile = [...insuranceProfiles, newProfile];
    handleModifyInsurances(insuranceProfile);
  };

  const updateProfile = (updatedProfile, index) => {
    const profilesCopy = [...insuranceProfiles];
    profilesCopy[index] = updatedProfile;
    handleModifyInsurances(profilesCopy);
  };

  const deleteProfile = (insuranceProfile) => {
    const filteredInsuranceProfile = insuranceProfiles.filter((i) => i.id !== insuranceProfile.id);
    handleModifyInsurances(filteredInsuranceProfile);
  };

  const handleModifyInsurances = (insuranceProfile) => {
    queryClient.setQueryData(['insurance-profiles', patientId], (prevData) => ({
      ...prevData,
      insuranceProfile
    }));
  };

  const toggleOne = (key) => {
    setIsOpen({ ...isOpen, [key]: isOpen && !isOpen[key] });
  };

  if (!Array.isArray(insuranceProfiles)) {
    return (
      <div className="mt-3 w-full">
        <Skeleton count={8} />
      </div>
    );
  }

  return (
    <div className="h-full" data-dd-privacy="allow">
      {!fakeModal && (
        <>
          <div className="flex items-center justify-between">
            {(insuranceProfiles?.length > 0 || !fromCheckin) && (
              <p className="mb-3 text-lg font-600 leading-10 text-neutral-800">
                My insurance {insuranceProfiles?.length > 1 ? 'profiles' : 'profile'}
              </p>
            )}
            {!fromCheckin && (
              <div className="flex items-center ">
                <EligibilityWrapper patient={patient} insProfiles={insuranceProfiles} />
              </div>
            )}
          </div>
          <div
            className={cs(
              ' gap-4 !pb-8 ',
              fromCheckin ? 'flex flex-col' : 'grid grid-cols-2 xs:flex xs:flex-col'
            )}>
            {insuranceProfiles?.length === 0 && !fromCheckin ? (
              <div
                data-qa="practice-charts-insuranceProfile-NoInsuranceButton"
                onClick={noInsurancePress}
                className="flex min-h-[350px] flex-col items-center justify-center !rounded-lg border-2 !border-primary-100 bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)] sm:min-h-[210px] xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4">
                <Icon
                  icon={
                    device === 'mobile' ? 'new-frame-heart-search-sm' : 'new-frame-heart-search'
                  }
                  className="animate-pulse"
                />
                <p className="!pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm">
                  I don't have insurance profile
                </p>
              </div>
            ) : (
              insuranceProfiles?.length === 0 && (
                <div className="flex flex-col items-center justify-center px-6 py-12">
                  <Icon icon="new-no-data" />
                  <p className="mt-3 text-base font-500 text-primary-900">
                    No Insurance Information
                  </p>
                  <p className="text-sm font-400 text-neutral-700">
                    Add insurance information by clicking the button below.
                  </p>
                  <div
                    onClick={handleNewInsuranceProfile}
                    className="mt-5 flex cursor-pointer gap-2">
                    <Icon icon="new-add-square" className="cursor-pointer" color="primary" />
                    <p className="text-center text-sm !text-primary-500">
                      {' '}
                      Add Insurance Information
                    </p>
                  </div>

                  <Button
                    // icon="new-credit-card"
                    iconSize={16}
                    text="I don't have insurance"
                    className="mt-5"
                    onClick={() => getCheckinStatus(true)}
                    color="neutral"
                    outlined
                  />
                </div>
              )
            )}

            {insuranceProfiles?.map((insuranceProfile, index) => {
              return device === 'mobile' || fromCheckin ? (
                <InsuranceProfileMobile
                  key={index}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  selected={selected}
                  setSelected={setSelected}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                  isOpen={isOpen}
                  kiosk={kiosk}
                  toggleOne={toggleOne}
                />
              ) : (
                <InsuranceProfile
                  key={index}
                  fakeModal={fakeModal}
                  setFakeModal={setFakeModal}
                  insuranceProfile={insuranceProfile}
                  index={index}
                  handleEditInsuranceProfile={handleEditInsuranceProfile}
                  hideEditInsuranceProfile={hideEditInsuranceProfile}
                  selected={selected}
                  kiosk={kiosk}
                  setSelected={setSelected}
                  updateProfile={updateProfile}
                  deleteProfile={deleteProfile}
                  practiceId={practiceId}
                />
              );
            })}

            {insuranceProfiles?.length < 5 && (insuranceProfiles.length > 0 || !fromCheckin) && (
              <div
                data-qa="practice-charts-insuranceProfile-NewInsuranceButton"
                onClick={handleNewInsuranceProfile}
                className={cs(
                  'group col-end-3 flex min-h-[350px] cursor-pointer flex-col items-center justify-center !rounded-lg border-2 !border-dotted border-neutral-400 py-6 shadow-[0px_4px_16px_rgba(0,0,0,0.04)] hover:!border-primary-100 hover:bg-primary-50 sm:min-h-[210px] md:col-end-2 lg:py-0 xs:min-h-fit xs:flex-row xs:!justify-start xs:gap-x-[6px] xs:!py-3 xs:px-4',
                  // { 'order-2': device === 'tablet' },
                  fromCheckin && '!h-16 !min-h-0 !flex-row items-center !justify-start gap-2 px-4'
                )}
                style={{
                  gridRowEnd:
                    insuranceProfiles.length >= 3
                      ? insuranceProfiles.length
                      : insuranceProfiles.length + 1
                }}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}>
                <div
                  className={cs(
                    fromCheckin && '!h-[32px] !w-[32px] !rounded-xl !bg-[#D9F4FF]',
                    'flex h-[54px] w-[54px] items-center justify-center rounded-2xl bg-neutral-100 group-hover:bg-[#9EE2FF] xs:h-[32px] xs:w-[32px] xs:!rounded-lg'
                  )}>
                  <Icon
                    icon="new-plus"
                    color={isHover || fromCheckin ? 'primary' : 'neutral'}
                    className="cursor-pointer"
                  />
                </div>
                <p
                  className={cs(
                    'pt-2 text-base text-neutral-800 xs:!pt-0 xs:text-sm',
                    fromCheckin && '!pt-0'
                  )}>
                  Create new insurance profile
                </p>
              </div>
            )}
          </div>
        </>
      )}
      {(showNewInsuranceProfile || fakeModal?.type === 'new') && (
        <NewInsuranceProfile
          fakeModal={fakeModal}
          newProfile={newProfile}
          patientId={patientId}
          practitionerId={practitionerId}
          kiosk={kiosk}
          showNewInsuranceProfile={showNewInsuranceProfile}
          hideNewInsuranceProfile={hideNewInsuranceProfile}
          practiceId={practiceId}
          insuranceProfiles={insuranceProfiles}
        />
      )}

      {(!!showEditInsuranceProfile || fakeModal?.type === 'edit') && (
        <EditInsuranceProfile
          updateProfile={updateProfile}
          index={showEditInsuranceProfile ? showEditInsuranceProfile?.index : fakeModal?.index}
          kiosk={kiosk}
          practiceId={practiceId}
          practitionerId={practitionerId}
          insuranceProfile={
            showEditInsuranceProfile ? showEditInsuranceProfile?.data : fakeModal?.data
          }
          patientId={patientId}
          showEditInsuranceProfile={showEditInsuranceProfile}
          hideEditInsuranceProfile={hideEditInsuranceProfile}
          fakeModal={fakeModal}
          setFakeModal={setFakeModal}
          selected={selected}
          setSelected={setSelected}
        />
      )}
    </div>
  );
}
