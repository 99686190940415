import React, { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { ChatContextProvider } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContextProvider';

import {
  activePromptConversationsState,
  myScribeAutoMapActivePromptIdState
} from 'components/practice/charts/ClinicalNote/MyScribeAI/state';

import Chat from './components/chat/Chat';

const MyScribeTemplateMainView = () => {
  const setActivePromptConversations = useSetRecoilState(activePromptConversationsState);
  const setActivePromptId = useSetRecoilState(myScribeAutoMapActivePromptIdState);

  useEffect(() => {
    return () => {
      setActivePromptConversations(null);
      setActivePromptId(null);
    };
  }, []);

  return (
    <div className="flex h-screen w-full">
      <ChatContextProvider>
        <Chat />
      </ChatContextProvider>
    </div>
  );
};

const MyScribeTemplate = () => {
  return <MyScribeTemplateMainView />;
};

export default MyScribeTemplate;
