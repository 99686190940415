const { stripHtmlTags, ia, isEmpty } = require('lib/helpers/utility');
const initialValues = () => {
  return {
    initial_weight: null,
    goal_weight: null
  };
};
const hasOnlyInitialValues = (med) => {
  const medKeys = Object.keys(med);
  const initialKeys = Object.keys(initialValues());
  return medKeys.length === initialKeys.length && medKeys.every((key) => initialKeys.includes(key));
};
exports.createWeightLossNarrative = (whls) => {
  if (!ia(whls)) return null;
  let narrative = '';
  if (whls[0]?.initial_weight) {
    narrative += ` The initial weight is ${whls[0].initial_weight} lbs.`;
  }
  if (whls[0]?.goal_weight) {
    narrative += ` The goal weight is ${whls[0].goal_weight} lbs.`;
  }
  if (whls[0]?.initial_weight || whls[0]?.goal_weight) {
    narrative += '<br><br>';
  }

  if (hasOnlyInitialValues(whls[0])) {
    return narrative;
  }

  narrative += '<p>The patient has been prescribed the following medications:</p><br>';

  whls?.forEach((whl, index) => {
    narrative += `<p>${index + 1}. ${whl?.medication?.value}`;

    if (whl.doses && whl.dosesUnit) {
      narrative += ` ${whl.doses} (${whl.dosesUnit}).`;
    } else if (whl.doses) {
      narrative += ` ${whl.doses}.`;
    } else {
      narrative += '.';
    }

    if (whl.days_supply) {
      narrative += ` The supply is for ${whl.days_supply} day(s).`;
    }

    if (whl.quantity) {
      narrative += ` The prescribed quantity is ${whl.quantity}.`;
    }

    if (whl.refills) {
      narrative += ` The prescription includes ${whl.refills} refill(s).`;
    }

    if (whl.condition && whl.condition.value) {
      narrative += ` This medication is for ${whl.condition.value}.`;
    }

    if (whl.prescriber && whl.prescriber.value) {
      narrative += ` Prescribed by ${whl.prescriber.value}.`;
    }

    if (whl.date) {
      narrative += ` The medication was started on ${whl.date}.`;
    }
    if (whl.end_date && whl.end_date !== whl.date) {
      narrative += ` It is scheduled to end on ${whl.end_date}.`;
    }

    narrative += '</p>'; // Close the paragraph after all the sentence-type data

    const route = extractText(whl?.route);
    if (route) {
      narrative += `<p>Route of administration: ${route}.</p>`;
    }
    if (whl.location) {
      narrative += `<p>Location: ${whl.location}.</p>`;
    }
    if (whl.weight) {
      narrative += `<p>Weight: ${whl.weight} lbs.</p>`;
    }

    if (whl.product_ndc) {
      narrative += `<p>Product NDC: ${whl.product_ndc}.</p>`;
    }

    // Append SIG if available
    if (!isEmpty(stripHtmlTags(whl.sig))) {
      narrative += `<p>SIG: ${whl.sig}</p>`;
    }

    // Append Pharmacist Instructions if available
    if (!isEmpty(stripHtmlTags(whl.pharmacist_instructions))) {
      narrative += `<p>Pharmacist instructions: ${whl.pharmacist_instructions}.</p>`;
    }

    // Append Note if available
    if (!isEmpty(stripHtmlTags(whl?.note))) {
      narrative += `<p>Note: ${whl.note}</p>`;
    }

    narrative += '<br>'; // Between each medication
  });

  return narrative?.trim();
};

const extractText = (input) => {
  if (!input || typeof input !== 'string') return null;

  const cleaned = input.replace(/[{}]/g, '');

  return cleaned
    ?.split(',')
    ?.map((item) => item?.trim()?.replace(/"/g, ''))
    ?.join(', ');
};
