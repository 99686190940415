import React from 'react';

import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';

const TreatmentPlanTable = ({ data = [], onRowClicked = () => {} }) => {
  return (
    <ErrorBoundary>
      <div className="flex h-full flex-col overflow-hidden !pb-0">
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={data}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            defaultColDef={{ flex: 1 }}
            onRowClicked={onRowClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default TreatmentPlanTable;
