import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { diagnoses } from 'constants';

import { getIcd10Codes } from 'api/Icd10';

import { useICD10Codes } from 'lib/hooks/queries/icd10Codes/useICD10Codes';

import { showAlert } from 'components/shared/Alert/Alert';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Select from 'components/shared/Select/Select';

const ICDRow = ({ claim = {}, updateDiagnosis = () => {}, showTitle = false }) => {
  const selectRefs = useRef({});
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleICD10Search = async (searchTerm) => {
    if (searchTerm) {
      try {
        const params = {
          searchTerm,
          limit: 25,
          withMostUserSearchClicks: true
        };

        let { code, icd10 } = await getIcd10Codes(navigate, params);
        if (code === 0) {
          return icd10?.map((item) => {
            return { value: item.code, label: `${item.code} - ${item.name}` };
          });
        } else {
          showAlert({ message: 'There was an error getting ICD10 codes.', color: 'danger' });
        }
      } catch (error) {
        showAlert({ message: 'There was an error getting ICD10 codes.', color: 'danger' });
      }
    }
  };

  const CustomValueContainer = ({ children, ...props }) => {
    const selectedValue = props.getValue()[0];
    const [tooltipContent, setTooltipContent] = useState('Loading...');
    const [queryEnabled, setQueryEnabled] = useState(false);

    const cachedData = queryClient.getQueryData(['icd10Codes', selectedValue?.value]);

    const { isLoading } = useICD10Codes({
      params: { searchTerm: selectedValue?.value, limit: 1 },
      dependencies: [selectedValue?.value],
      options: {
        enabled: queryEnabled && !!selectedValue?.value && !cachedData,
        staleTime: 10000,
        onSuccess: ({ code, icd10 }) => {
          if (code === 0) {
            const label = icd10?.[0]?.name || selectedValue?.value || 'No data found';
            setTooltipContent(label);
          }
        }
      }
    });

    const handleHover = () => {
      if (!cachedData) {
        setQueryEnabled(true);
      } else {
        const label = cachedData?.icd10?.[0]?.name || selectedValue?.value || 'No data found';
        setTooltipContent(label);
      }
    };

    let defaultStyles = props?.getStyles('valueContainer', props) || {};

    return (
      <>
        {selectedValue?.value ? (
          <Tippy
            content={isLoading ? 'Loading...' : tooltipContent}
            placement="top"
            className="tippy-dark"
            menuPortalTarget={document.body}
            onShow={handleHover}>
            <div
              className={cs(
                props.className,
                'flex justify-between items-center py-[2px] px-[10px]'
              )}>
              {children}
            </div>
          </Tippy>
        ) : (
          <div style={defaultStyles}>{children}</div>
        )}
      </>
    );
  };

  const isDisabled = (currentId) => {
    if (currentId === 1) return false;
    const previousFieldName = `diag_${currentId - 1}`;
    return !claim?.[previousFieldName];
  };

  const isClearable = (currentId) => {
    const previousFieldName = `diag_${currentId + 1}`;
    return !claim?.[previousFieldName];
  };

  const handleSelectChange = (value, fieldName) => {
    updateDiagnosis(value, fieldName);

    if (selectRefs.current[fieldName]) {
      selectRefs.current[fieldName].blur();
    }
  };

  return (
    <div className="flex sm:flex-col md:items-center">
      <div className="block !pt-4">
        {showTitle && <p className="!pb-3">Diagnosis (ICD10 CODES)</p>}
        <div className="col-start-1 col-end-3">
          <div
            className={cs(
              'grid grid-cols-4 gap-4',
              showTitle && 'sm:grid-cols-2 xxs:sm:grid-cols-1'
            )}>
            {diagnoses.map((diagnosis) => (
              <Select
                key={diagnosis.name}
                forwardedRef={(ref) => (selectRefs.current[diagnosis.name] = ref)}
                disabled={isDisabled(diagnosis.id)}
                isClearable={isClearable(diagnosis.id)}
                isAsync
                parentClassName={cs(
                  'flex items-center',
                  diagnosis?.id > 8 && diagnosis?.id === 9
                    ? 'gap-3'
                    : diagnosis?.id > 9
                      ? 'gap-[10px]'
                      : 'gap-2'
                )}
                label={diagnosis.label}
                className="min-w-[200px] max-w-[200px]"
                labelClassName="pt-[6px]"
                placeholder="Diagnose"
                components={{
                  ValueContainer: CustomValueContainer,
                  IndicatorSeparator: () => null
                }}
                name={diagnosis.name}
                value={
                  claim?.[diagnosis.name]
                    ? { value: claim[diagnosis.name], label: claim[diagnosis.name] }
                    : ''
                }
                loadOptions={(event) => handleICD10Search(event)}
                noOptionsMessage={() => 'Start typing the code or name.'}
                onChange={(e) => handleSelectChange(e, diagnosis.name)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(ICDRow);
