import { showAlert } from 'components/shared/Alert/Alert';

export const addItem = (formik, type) => {
  const newItem = {};

  const updatedItems = [...formik.values[type], newItem];

  formik.setFieldValue(type, updatedItems);
};

export const addItems = (setFieldValue, currentValues, newValues, type) => {
  const updatedItems = [...currentValues, ...newValues];

  setFieldValue(type, updatedItems);
};

export const removeItem = (setFieldValue, items, type, i) => {
  const updatedItems = items;

  updatedItems.splice(i, 1);

  setFieldValue(type, updatedItems);
};

export const handleChangeQuantity = (setFieldValue, currentValues, val, id, productsList = []) => {
  const sales_count = Number(val);

  const product = productsList?.find(({ id: pId }) => pId === id); //current product

  if (product?.status !== 'active') {
    showAlert({ message: 'This product is not anymore active.', color: 'danger' });
    return;
  }

  if (product?.quantity < sales_count || sales_count < 0) {
    sales_count > 0 &&
      showAlert({
        color: 'danger',
        message: `You currently have ${product?.quantity} products available in stock.`
      });
    return;
  }

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id) {
        return { ...s, sales_count, total_amount_cents: s.amount_cents * sales_count };
      }
      return s;
    })
  );
};

export const handleChangePackageQuantity = (type, setFieldValue, currentValues, val, id) => {
  const sales_count = Number(val);
  setFieldValue(
    type,
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, sales_count, total_amount_cents: s.amount_cents * sales_count };
      return s;
    })
  );
};

export const handleChangePrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, total_amount_cents: price, amount_cents: price / s?.quantity };
      return s;
    })
  );
};

export const handleChangePriceProcedures = (type, setFieldValue, currentValues, val, i) => {
  const price = Number(val);

  setFieldValue(
    type,
    currentValues.map((procedure, index) => {
      // if (procedure.uuid === uuid) {
      if (index === i) {
        const { type: procedureType, pt_balance, total_cost_cents, ...rest } = procedure;

        const balance = procedureType === 'primary' ? pt_balance : total_cost_cents;
        const modifiedProcedure = { ...procedure };

        if (price !== balance) {
          modifiedProcedure.pt_balance = price;
          modifiedProcedure.modified = true;
        }

        return modifiedProcedure;
      }

      return procedure;
    })
  );
};

export const handleChangeUnitPrice = (setFieldValue, currentValues, val, id) => {
  const price = Number(val);

  setFieldValue(
    'products',
    currentValues.map((s) => {
      if (s.id === id)
        return { ...s, amount_cents: price, total_amount_cents: price * s?.quantity };
      return s;
    })
  );
};

export const reShapeProducts = (data) => {
  return (
    data?.products?.map((p) => ({
      id: p?.id,
      quantity: 1,
      label: p.name,
      amount_cents: p.amount_cents,
      total_amount_cents: p.amount_cents,
      ...(p?.image && {
        image: `https://cdn.filestackcontent.com/${JSON.parse(p?.image || `{}`)?.jpg}`
      })
    })) || []
  );
};

export const handleProcedureType = (setFieldValue, currentValues, index, typeItem, type) => {
  setFieldValue(
    type,
    currentValues.map((s, i) => {
      if (i === index) return { ...s, type: typeItem };

      return s;
    })
  );
};

export const timeFilterOption = (candidate, input) => {
  const normalizedLabel = candidate.label.toLowerCase();
  const normalizedInput = input.toLowerCase().trim();

  const regex = /^(\d{1,2})(\s?(a|p)m?)?$/;
  const match = normalizedInput.match(regex);

  if (match) {
    const [_, inputHour, , inputMeridiem] = match;

    const formattedInputHour = inputHour.padStart(2, '');

    if (!inputMeridiem) {
      return normalizedLabel.startsWith(`${formattedInputHour}:`);
    }

    const fullInputMeridiem =
      inputMeridiem.trim() === 'p'
        ? 'pm'
        : inputMeridiem.trim() === 'a'
          ? 'am'
          : inputMeridiem.trim();

    return (
      normalizedLabel.startsWith(`${formattedInputHour}:`) &&
      normalizedLabel.endsWith(fullInputMeridiem)
    );
  }

  return normalizedLabel.startsWith(normalizedInput);
};
