import { useEffect } from 'react';

import { useField } from 'formik';

import Select from 'components/shared/Select/Select';

import { billToOptions } from '../../lib/constants';

const BillToSelect = ({ className }) => {
  const [field, meta, { setValue }] = useField({
    name: 'billTo'
  });

  useEffect(() => {
    if (!field.value) {
      setValue(billToOptions[0]);
    }
  }, []);

  return (
    <Select
      {...field}
      isClearable={false}
      error={meta.touched && meta.error}
      label="Bill to"
      required
      options={billToOptions}
      inputId="billToSelect"
      data-qa="bill-to-select"
      className="border-neutral-100"
      parentClassName={className}
      placeholder="Select party to bill to"
      onChange={(option) => {
        setValue(option);
      }}
    />
  );
};

export default BillToSelect;
