import React, { useEffect, useState } from 'react';
import Button from '../../../Buttons/Button';
import { OtherProblems as initialValue } from '../CustomFormInitialStates';
import Container from '../components/Container';
import Header from '../components/Header';
import OtherProblemsRow from './OtherProblemsRow';

import cs from 'classnames';
import { ia, iaRa } from 'lib/helpers/utility';
import useNewDesign from '../lib/useNewDesign';

const OtherProblems = ({ forwardedRef, defaultValue, onChange }) => {
  const [otherProblems, setOtherProblems] = useState(initialValue);
  const newDesign = useNewDesign();

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        try {
          setOtherProblems(JSON.parse(defaultValue));
        } catch (error) {
          console.error('Not a valid JSON', error);
        }
      } else {
        setOtherProblems(defaultValue);
      }
    } else {
      setOtherProblems(initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(otherProblems);
  }, [otherProblems]);

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: 'OtherProblems', type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event.preventDefault();
    setOtherProblems([
      {
        note: '',
        problems: null,
        id: self.crypto.randomUUID()
      },
      ...otherProblems
    ]);
  };

  const handleDeleteRow = (id) => {
    setOtherProblems(otherProblems?.filter((row) => row.id !== id));
  };

  const handleOnChange = (key, event, id) => {
    setOtherProblems((p) => {
      if (p && ia(p)) {
        return p?.map((row) => {
          if (row.id === id) {
            return { ...row, [key]: event };
          } else {
            return row;
          }
        });
      } else {
        return p;
      }
    });
  };

  return (
    <div className="OtherProblems CustomForms">
      <Header title="Other Problems" addRow={{ onClick: handleAddRow }}>
        {!newDesign && (
          <div
            className={cs(
              otherProblems?.length > 1 && 'pr-8',

              'flex w-full flex-row justify-end'
            )}>
            <Button
              text="Add row"
              iconRight="add-circle"
              transparent
              onClick={handleAddRow}
              data-qa="add-allergy-btn"
              textClassName="text-primary-500 mr-1 text-sm"
            />
          </div>
        )}

        <input type="hidden" value={JSON.stringify(otherProblems)} ref={forwardedRef} />
        <div className={cs(newDesign && 'mt-4', ' flex w-full flex-col gap-4')}>
          {iaRa(otherProblems)?.map((item, idx) => (
            <Container
              onDelete={otherProblems?.length > 1 ? () => handleDeleteRow(item.id) : null}
              key={item.id}
              number={idx + 1}>
              <OtherProblemsRow
                key={item.id}
                newDesign={newDesign}
                onChange={handleOnChange}
                {...item}
              />
            </Container>
          ))}
        </div>
      </Header>
    </div>
  );
};

export default OtherProblems;
