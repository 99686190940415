import React, { useEffect, useRef, useState } from 'react';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import NewReferralContainer from './new_referral_container';
import PreviewReferral from './preview_referral';
import ReferralRow from './show';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';

export default function Referrals() {
  const [referrals, setReferrals] = useState({
    all_referrals: []
  });
  const [currentReferral, setCurrentReferral] = useState({
    body: '',
    created_at: '',
    practice: {
      name: ''
    }
  });
  const [modalState, setModalState] = useState(false);
  const [detailedModalShow, setDetailedModal] = useState(false);
  const [confirmation, setConfirmationModal] = useState(false);
  const navigate = useNavigate();
  const formRef = useRef();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      let res = await interimApi('/api/messages/referrals', {}, navigate);
      const { redirect, referrals: loadedReferrals } = res.data;
      if (res.data) {
        setReferrals({
          all_referrals: loadedReferrals
        });
      } else {
        navigate(redirect);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  };

  const showModal = () => {
    setModalState(true);
  };

  const hideModal = () => {
    setModalState(false);
  };

  const showDetailedModal = () => {
    setDetailedModal(true);
  };

  const hideDetailedModal = () => {
    setDetailedModal(false);
  };

  const showConfirmation = () => {
    showAlert({ message: 'Your referral request has been submitted!', color: 'success' });
  };

  const handleSubmit = () => {
    formRef.current.validateForm();
  };

  return (
    <div className="!p-4">
      <Button icon="new-plus" iconColor="white" text="Request new Referral" onClick={showModal} />
      <div className="grid grid-cols-3 gap-4 border-0 !border-b !border-solid !border-neutral-300 px-2 !pt-4 pb-2">
        <span className="text-base font-500">Practice</span>
        <span className="text-base font-500">Date</span>
        <span className="text-base font-500">Message</span>
      </div>
      <div className="grid gap-2 pt-2">
        {referrals.all_referrals &&
          referrals.all_referrals.map((referral, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setCurrentReferral(referral);
                  showDetailedModal();
                }}>
                <ReferralRow referral={referral} />
              </div>
            );
          })}
      </div>

      <Modal
        isOpen={modalState}
        handleClose={hideModal}
        title="New Referral"
        footer={
          <div className="flex w-full items-center justify-between">
            <Button
              onClick={hideModal}
              data-qa="close-new-referral"
              text="Cancel"
              color="neutral"
              outlined
            />
            <Button onClick={handleSubmit} data-qa="new-referral-submit" text="Save" />
          </div>
        }>
        <NewReferralContainer
          handleClose={hideModal}
          showConfirmation={showConfirmation}
          ref={formRef}
        />
      </Modal>
      <Modal isOpen={detailedModalShow} handleClose={hideDetailedModal}>
        {currentReferral && <PreviewReferral referral={currentReferral} />}
      </Modal>
    </div>
  );
}
