const { ia, iaRa, formatDate, io } = require('lib/helpers/utility');

exports.createSocialHistoryNarrative = (data) => {
  let narrative = '';

  function addToNarrative(question, answer, isArray = false) {
    if (answer !== null && answer !== undefined && answer !== false && answer !== '') {
      if (isArray && ia(answer)) {
        narrative += `<strong>${question}:</strong> ${answer.join(', ')}. `;
      } else if (!isArray) {
        narrative += `<strong>${question}:</strong> ${answer}. `;
      }
      narrative += '\n';
    }
  }

  if (!io(data) || data?.noDetails) {
    return 'No notable social history';
  }

  // Cigarettes
  const cigarettes = data?.cigarettes;
  if (cigarettes?.never) {
    addToNarrative('Smoking status', 'Never smoked');
  } else if (cigarettes?.yes) {
    addToNarrative('Smoking status', `Currently smokes ${cigarettes?.packsDay} packs per day`);
  } else if (cigarettes?.quit) {
    addToNarrative('Smoking status', `Quit smoking`);
    addToNarrative('Date quit', formatDate(cigarettes?.dateQuit, undefined, true));
    addToNarrative('Years smoked', cigarettes?.yearsSmoked);
  }

  // Vaping
  addToNarrative('Vaping', data?.vape?.yes ? 'Uses vape products' : 'Does not use vape products');

  // Alcohol
  const alcohol = data?.alcohol;
  if (alcohol?.never) {
    addToNarrative('Alcohol consumption', 'Never drinks alcohol');
  } else if (alcohol?.yes) {
    addToNarrative(
      'Alcohol consumption',
      `Drinks ${alcohol?.drinksWeek} alcoholic beverages per week`
    );
  }

  // Drugs
  const drugs = data?.drugs;
  if (drugs?.never) {
    addToNarrative('Drug use', 'Never uses drugs');
  } else if (drugs?.rarely) {
    addToNarrative(
      'Drug use',
      `Rarely uses drugs. ${data?.drugs?.timesMonth && `${drugs?.timesMonth} times per month`} `
    );
  } else if (drugs?.timesMonth) {
    addToNarrative('Drug use', `Uses drugs ${drugs?.timesMonth} times per month`);
  }

  const usedDrugs = [];
  if (drugs?.marijuana) usedDrugs.push('marijuana');
  if (drugs?.cocaine) usedDrugs.push('cocaine');
  if (drugs?.opioid) usedDrugs.push('opioids');
  if (drugs?.other) usedDrugs.push(drugs?.otherText);
  if (ia(usedDrugs)) {
    addToNarrative('Drugs used', usedDrugs, true);
  }

  // Education
  const education = data?.education;
  const educationLevels = [];
  if (education?.highSchool) educationLevels.push('high school');
  if (education?.tradeSchool) educationLevels.push('trade school');
  if (education?.college) educationLevels.push('college');
  if (education?.postGraduateDegree) educationLevels.push('post-graduate degree');
  addToNarrative('Education', educationLevels, true);

  // Employment
  const employed = data?.employed;
  if (employed?.yes) {
    addToNarrative('Employment status', 'Employed');
    addToNarrative('Type of work', employed?.typeOfWork);
  } else if (employed?.retired) {
    addToNarrative('Employment status', 'Retired');
  } else {
    addToNarrative('Employment status', 'Not employed');
  }

  // Exercise
  const exercise = data?.exercise;
  if (exercise?.yes) {
    addToNarrative('Exercise', 'Yes');
    addToNarrative('Type of exercise', exercise?.type);
    addToNarrative('Frequency of exercise', exercise?.howOften);
    addToNarrative('Duration of activity', exercise?.howLongActivity);
  } else {
    addToNarrative('Exercise', 'Does not exercise regularly');
  }

  // Marital Status
  const maritalStatus = Object.keys(data?.martialStatus || {}).find(
    (key) => data?.martialStatus?.[key]
  );
  if (maritalStatus) {
    addToNarrative('Marital status', maritalStatus);
  }

  // Sexual Activity
  const sexualActivity = data?.sexualActivity;
  if (sexualActivity?.yes) {
    addToNarrative('Sexually active', 'Yes');
    addToNarrative('Number of sexual partners', sexualActivity?.numberOfSexualPartners);

    const partners = [];
    if (sexualActivity?.men) partners.push('men');
    if (sexualActivity?.women) partners.push('women');
    addToNarrative('Sexual partners', partners, true);

    if (sexualActivity?.contracteption) {
      addToNarrative('Contraception use', 'Yes');
      addToNarrative('Method of contraception', sexualActivity?.methodContraception);
    } else if (sexualActivity?.noContracteption) {
      addToNarrative('Contraception use', 'No');
    }
  } else if (sexualActivity?.no) {
    addToNarrative('Sexually active', 'No');
  }

  // Children
  const children = data?.children;
  if (children?.yes) {
    addToNarrative('Has children', 'Yes');
    addToNarrative('Number of children', children?.numberOfChildren);

    const childrenAges = iaRa(data?.children?.ages).filter((n) => {
      if (typeof n === 'string') return n;

      if (n?.age) return true;
    });

    if (ia(childrenAges)) {
      addToNarrative("Children's ages", childrenAges, true);
    }
  } else if (children?.no) {
    addToNarrative('Has children', 'No');
  }

  return narrative?.trim();
};
