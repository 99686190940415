export function generateBeyondBillingList() {
  return {
    overview: {
      name: 'Overview',
      path: 'overview',
      icon: 'new-graph'
    },
    'income-i-queue': {
      name: 'IncomeIQueue',
      path: 'income-i-queue',
      icon: 'new-note-text',
      permission: 'is_admin'
    },
    payments: {
      name: 'Payments',
      path: 'payments',
      icon: 'new-billing',
      permission: 'payments.read'
    },
    'patient-ledger': {
      name: 'Patient Ledger',
      path: 'patient-ledger',
      icon: 'new-macro-type',
      permission: 'payments.read'
    },
    // 'financial-reporting': {
    //   name: 'Financial Reporting',
    //   path: 'financial-reporting',
    //   icon: 'receipt-text',
    //   open:
    //     userState?.display_settings?.sidebars?.beyondBilling?.['financial-reporting']?.open ?? true,
    //   children: [
    //     {
    //       name: 'Grand total',
    //       path: 'financial-reporting',
    //       end: true
    //     },
    //     {
    //       name: 'Insurance Claims',
    //       path: 'financial-reporting/insurance-claims'
    //     }
    //   ]
    // },
    'dynamic-ar': {
      name: 'Dynamic AR',
      path: 'dynamic-ar',
      icon: 'new-notev3'
    },
    era: {
      name: 'ERA',
      path: 'era-eob/era',
      icon: 'new-receipt-item'
    },
    eob: {
      name: 'EOB',
      path: 'era-eob/eob',
      icon: 'new-receipt-3'
    },
    claims: {
      name: 'Claims',
      path: 'claims',
      icon: 'wallet'
    },
    superbills: {
      name: 'Superbills',
      path: 'superbills',
      icon: 'new-bill',
      permission: 'superbill.read'
    },
    'electronic-invoicing': {
      name: 'Electronic Invoicing',
      path: 'electronic-invoicing',
      icon: 'new-file-dollar',
      permission: 'invoicing.read'
    }
  };
}
