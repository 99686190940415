// import { iaRa } from 'lib/helpers/utility';
import * as Yup from 'yup';
// import { MEDICATION_STATUS, UNIT_OPTIONS } from './constants';

export const ProviderMedicationSchema = Yup.object().shape({
  medication: Yup.string().required('Medication is required'),
  // unit: Yup.string()
  //   .oneOf(
  //     iaRa(UNIT_OPTIONS).map((row) => row?.value),
  //     'Invalid unit type'
  //   )
  //   .required('Unit type is required'),
  // strength: Yup.number()
  //   .required('Unit is required')
  //   .typeError('Unit must be a number')
  //   .positive('Unit must be a positive number'),
  // strength: Yup.string().required('Unit is required'),
  // quantity: Yup.number()
  //   .required('Quantity is required')
  //   .typeError('Must be a number')
  //   .positive('Must be a positive number'),
  // days_supply: Yup.number()
  //   .required('Days supply is required')
  //   .typeError('Must be a number')
  //   .positive('Must be a positive number'),
  // refills: Yup.number()
  //   .required('Refills is required')
  //   .typeError('Must be a number')
  //   .positive('Must be a positive number'),
  status: Yup.string().required('Status is required')
  // start_date: Yup.string().required('Start date is required'),
  // end_date: Yup.string().when('status', {
  //   is: (status) => status == MEDICATION_STATUS.INACTIVE,
  //   then: (schema) => schema.required('End date is required when status is active'),
  //   otherwise: (schema) => schema.notRequired()
  // })
});
