export const customSelectStyle = {
  control: () => ({
    height: 40,
    display: 'flex',
    padding: '0px 6px',
    fontSize: 16,
    backgroundColor: '#ffffff',
    border: '1px solid #dcdcdc',
    borderRadius: '0.5rem'
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: '#afafaf'
    };
  },
  singleValue: (provided) => {
    return {
      ...provided,
      fontWeight: 500
    };
  },
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
};
