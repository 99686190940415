import { useField } from 'formik';

import Textarea from 'components/shared/Textarea/Textarea';

const Comments = ({ className }) => {
  const [field] = useField({
    name: 'orderComments'
  });

  return (
    <Textarea
      {...field}
      label="Comments"
      textareaClassName="min-h-[80px] xl:min-h-[124px] overflow-y-scroll"
      className={className}
      maxLength={80}
    />
  );
};

export default Comments;
