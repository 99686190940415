import React, { forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import FormBuilder from './FormBuilder';
import Form from './form';
import { useCustomFormTypes } from 'lib/hooks/queries/customForms/useCustomFormTypes';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createCustomForm, updateCustomForm } from 'api/CustomForms';
import { showAlert } from 'components/shared/Alert/Alert';

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('Name is required.'),
  type: Yup.number().required('Type is required')
});

const EditFormBuilderContainer = forwardRef(
  ({ form: loadedForm, formFunctions, hideFormModal }, ref) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const formik = useFormik({
      initialValues: loadedForm
        ? { ...loadedForm }
        : {
            id: '',
            name: '',
            type: '',
            json: ''
          },
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting }) => {
        setSubmitting(true);
        const valuesCopy = {
          form: { ...values },
          practice_id: id
        };
        loadedForm ? mutateUpdateForm.mutate(valuesCopy) : mutateCreateForm.mutate(valuesCopy);
      }
    });

    useImperativeHandle(ref, () => ({
      submitForm: formik.submitForm
    }));

    const queryClient = useQueryClient();

    const mutateCreateForm = useMutation({
      mutationFn: (data) => createCustomForm(navigate, data),
      onSuccess: () => {
        hideFormModal();
        formik.setSubmitting(false);
        queryClient.invalidateQueries(['customForm']);
        showAlert({ title: 'Form created successfully!', color: 'primary' });
      },
      onError: () => {
        formik.setSubmitting(false);
        showAlert({ title: 'Form creation failed!', color: 'danger' });
      }
    });

    const mutateUpdateForm = useMutation({
      mutationFn: (data) => updateCustomForm(navigate, data),
      onSuccess: () => {
        hideFormModal();
        formik.setSubmitting(false);
        queryClient.invalidateQueries(['customForm']);
        showAlert({ title: 'Form updated successfully!', color: 'primary' });
      },
      onError: () => {
        formik.setSubmitting(false);
        showAlert({ title: 'Form update failed!', color: 'danger' });
      }
    });

    const onFormChange = (event) => {
      formik.setFieldValue('json', { fields: event });
    };

    return (
      <div className="relative">
        <div className="!mb-8 flex items-center !gap-6">
          <Form formik={formik} formFunctions={formFunctions} />
        </div>
        <FormBuilder
          practice_id={id}
          onFormChange={onFormChange}
          formik={formik}
          isEdit={loadedForm ? true : false}
        />
      </div>
    );
  }
);

export default EditFormBuilderContainer;
