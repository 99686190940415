import { useState } from 'react';

const useChatInput = () => {
  const [inputValue, setInputValue] = useState('');
  const [disableGenerate, setDisableGenerate] = useState(true);

  const onTextChange = (e) => {
    setDisableGenerate(!e.target.value?.length);
    setInputValue(e.target.value);
  };

  return {
    inputValue,
    disableGenerate,
    onTextChange
  };
};

export default useChatInput;
