import React from 'react';
import { Popover } from '@headlessui/react';
import cs from 'classnames';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import { useDashboardContext } from 'lib/context/Dashboard/DashboardContext';

const DeleteEventPopover = ({ event, handleHideHover }) => {
  const { setConfirmModal } = useDashboardContext();
  const eventRepeats = event.repeat_event;

  const handleConfirmModal = (kind) => {
    setConfirmModal((prevState) => ({
      ...prevState,
      type: 'event',
      id: event.id,
      show: true,
      eventKind: kind,
      title: event.title
    }));
    handleHideHover();
  };

  return (
    <>
      {eventRepeats ? (
        <Popover className="relative">
          {({ close }) => (
            <div>
              <Popover.Button className="flex !p-0">
                <Button
                  icon="calendar-cross"
                  size="small"
                  iconSize={14}
                  outlined
                  className="!py-[7px] !px-[7px]"
                />
              </Popover.Button>
              <Popover.Panel
                className={cs(
                  'absolute right-1 z-[99999] min-w-[264px] cursor-pointer rounded-lg   bg-white  !py-[6px] shadow-lg -bottom-[120px]'
                )}>
                <>
                  <div
                    className="flex gap-1 rounded-lg !p-2 transition-all items-center hover:bg-danger-50"
                    onClick={() => {
                      handleConfirmModal('only');
                      close();
                    }}>
                    <Icon icon="calendar-event" color="danger" />
                    <div className="text-sm text-danger-900">Delete only this event</div>
                  </div>
                  <div
                    className="flex gap-1  !p-2 transition-all items-center hover:bg-danger-50"
                    onClick={() => {
                      handleConfirmModal('following');
                      close();
                    }}>
                    <Icon icon="new-calendarv2" color="danger" />
                    <div className="text-sm text-danger-900">Delete this and following events</div>
                  </div>
                  <div
                    className="flex gap-1  !p-2 transition-all items-center hover:bg-danger-50"
                    onClick={() => {
                      handleConfirmModal('all');
                      close();
                    }}>
                    <Icon icon="calendar-dates" color="danger" />
                    <div className="text-sm text-danger-900">Delete all events in the series</div>
                  </div>
                </>
              </Popover.Panel>
            </div>
          )}
        </Popover>
      ) : (
        <Button
          icon="calendar-cross"
          size="small"
          iconSize={14}
          outlined
          className="!py-[7px] !px-[7px]"
          onClick={() => handleConfirmModal('only')}
        />
      )}
    </>
  );
};

export default DeleteEventPopover;
