import React from 'react';
import cs from 'classnames';

const statusSOAP = (status) => {
  switch (status) {
    case 'draft':
      return 'started';
    case 'finalized':
      return 'finalized';
    default:
      return 'No Clinical Note';
  }
};
const PatientStatus = ({
  appointment = {},
  label = '',
  bulletPoints = false,
  wrapperClassName = '',
  listClassName = ''
}) => (
  <div className={wrapperClassName} data-dd-privacy="allow">
    {label && <p className="text-sm font-500">{label}</p>}
    <div className={cs('flex flex-col gap-2', listClassName)} data-dd-privacy="allow">
      <div className="flex gap-1 items-center justify-between" data-dd-privacy="allow">
        <div className="flex items-baseline gap-3" data-dd-privacy="allow">
          {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
          <span className="text-xs text-neutral-600 font-500 leading-5">Clinical Note</span>
        </div>
        <span className="text-xs font-600 capitalize text-warning-700 bg-warning-100 !rounded-lg !px-[6px] leading-[22px]">
          {statusSOAP(appointment?.medicalHistory?.state)}
        </span>
      </div>
      <div className="flex gap-1 items-center justify-between" data-dd-privacy="allow">
        <div className="flex items-baseline gap-3" data-dd-privacy="allow">
          {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
          <span className="text-xs text-neutral-600 font-500 leading-5">Superbill</span>
        </div>
        <span className="text-xs font-600 capitalize text-success-700 bg-success-100 !rounded-lg !px-[6px] leading-[22px]">
          {appointment?.superbill ? 'created' : 'No Superbill'}
        </span>
      </div>
      <div className="flex gap-1 items-center justify-between" data-dd-privacy="allow">
        <div className="flex items-baseline gap-3" data-dd-privacy="allow">
          {bulletPoints && <span className="text-[15px] text-neutral-300">•</span>}
          <span className="text-xs text-neutral-600 font-500 leading-5">Claim</span>
        </div>
        <span className="text-xs font-600 capitalize text-primary-600 bg-primary-50 !rounded-lg !px-[6px] leading-[22px]">
          {!appointment?.claim_status || appointment?.claim_status === 'draft'
            ? 'No Claim'
            : appointment?.claim_status}
        </span>
      </div>
    </div>
  </div>
);

export default PatientStatus;
