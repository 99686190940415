import React, { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import BaseDatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import { withErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker';
import cs from 'classnames';
import moment from 'moment';
import { useRecoilValue } from 'recoil';

import { useUIContext } from 'lib/context/UIContext/UIContext';
import { ia } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';

import Icon from '../Icon/Icon';
import { default as Header } from '../shared/Header';

import ClearIcon from './components/ClearIcon';
import Hint from './components/Hint';
import { useCalendarPosition } from './hooks/useCalendarPosition';
import { useStaticRanges } from './hooks/useStaticRanges';
import { useYearChange } from './hooks/useYearChange';
import {
  formatRangeWithTimezone,
  formatRangeWithoutTimezone,
  formatWithTimezone,
  formatWithoutTimezone,
  getDateFormat
} from './lib/formatDates';
import { validateDate } from './lib/validateDate';

import './lib/DatePicker.scss';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import '@wojtekmaj/react-datetimerange-picker/dist/DateTimeRangePicker.css';

/**
 * @param {import('./lib/propTypes').DatePickerProps} props
 */

const DatePicker = (props) => {
  const {
    value,
    onChange = () => {},
    isTime = false,
    isRange = false,
    isTimeRange = false,
    isTimezone = isTime || isTimeRange || false,
    isSeconds = false,
    minDate = moment().subtract(150, 'years').toDate(),
    maxDate = moment().add(150, 'years').toDate(),
    disabled = false,
    buttonClassName,
    parentClassName,
    isButtonTextWhite,
    isClearable = true,
    isIcon = true,
    iconColor = 'primary',
    iconIsRight = false,
    isHint = true,
    error = null,
    ...headerProps
  } = props;

  const calendarRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const isValue = ia(value) ? Boolean(value?.[0]) : Boolean(value);
  const [activeStartDate, setActiveStartDate] = useState(
    isValue ? (ia(value) ? value[0] : value) : new Date()
  );
  const [activeView, setActiveView] = useState('month');
  const { device } = useUIContext();
  const { pathname } = useLocation();
  const { timezone } = useRecoilValue(currentPractice);

  if ((isTime || isTimeRange) && !isTimezone)
    throw new Error('isTimezone must be true when isTime or isTimeRange is true.');

  if ((isTime && isTimeRange) || (isRange && isTimeRange) || (isTime && isRange))
    throw new Error('isTime, isRange or isTimeRange cannot be true at the same time.');

  const DatePickerWrapper = isTime
    ? DateTimePicker
    : isRange
      ? DateRangePicker
      : isTimeRange
        ? DateTimeRangePicker
        : BaseDatePicker;

  useEffect(() => cleanup(), []);

  useCalendarPosition({ calendarRef, isOpen });

  useYearChange({
    value,
    isRange,
    isOpen,
    calendarRef
  });

  const handleRangeSelect = (range) => {
    onChange(range);
    setActiveStartDate(range[0]);
    setIsOpen(false);
  };

  const cleanup = useStaticRanges({
    calendarRef,
    isOpen,
    device,
    isRange,
    isTimeRange,
    value,
    handleRangeSelect
  });

  const onCalendarClose = () => {
    setIsOpen(false);
    setActiveStartDate(value ? validateDate(value) : new Date());
    setActiveView('month');
  };

  const onResetDate = () => {
    onChange(null);
    setActiveStartDate(new Date());
    setActiveView('month');
  };

  const handleChange = (date) => {
    if (!date) {
      onChange(null);
      setActiveStartDate(new Date());
      return;
    }

    if (isRange || isTimeRange) {
      if (ia(date)) {
        const validDates = date.map((d) => validateDate(d));
        const rangeWithTimezone = formatRangeWithTimezone(validDates, timezone);
        const rangeWithoutTimezone = formatRangeWithoutTimezone(validDates);

        onChange(isTimezone ? rangeWithTimezone : rangeWithoutTimezone);
        setActiveStartDate(validDates[0]);
      }
    } else {
      const validDate = validateDate(date);
      const timezoneDate = formatWithTimezone(validDate, timezone);
      const withoutTimezoneDate = formatWithoutTimezone(validDate);

      onChange(isTimezone ? timezoneDate : withoutTimezoneDate);
      setActiveStartDate(validDate);
    }
  };

  return (
    <div className={cs(parentClassName, disabled && 'cursor-not-allowed select-none')}>
      <Header {...headerProps} />
      <DatePickerWrapper
        isOpen={isOpen}
        isCalendarOpen={isOpen}
        onCalendarOpen={() => setIsOpen(true)}
        onCalendarClose={onCalendarClose}
        calendarProps={{
          inputRef: calendarRef,
          activeStartDate: validateDate(activeStartDate),
          onActiveStartDateChange: ({ activeStartDate }) => setActiveStartDate(activeStartDate),
          view: activeView,
          onViewChange: ({ view }) => setActiveView(view)
        }}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        className={cs(
          isValue && 'filled',
          !iconIsRight && 'iconIsRight',
          isTimeRange && !isOpen && 'closed',
          pathname === '/iframe.html' && 'isDocumentation',
          buttonClassName,
          isButtonTextWhite && 'white-text'
        )}
        format={getDateFormat({ isTime, isTimeRange, isSeconds })}
        calendarIcon={
          isIcon && <Icon icon="new-calendar" color={cs(disabled ? 'neutral' : iconColor)} />
        }
        clearIcon={
          <ClearIcon
            isClearable={isClearable ? isValue : false}
            value={value}
            onResetDate={onResetDate}
            color={isButtonTextWhite ? 'white' : 'neutral'}
          />
        }
        disableClock
        dayPlaceholder="dd"
        monthPlaceholder="mm"
        yearPlaceholder="yyyy"
        locale="en-US"
        {...props}
        onChange={(date) => handleChange(date)}
        value={value}
      />
      <Hint
        isHint={isHint}
        isValue={isValue}
        value={value}
        timezone={timezone}
        isTime={isTime}
        isTimeRange={isTimeRange}
        isTimezone={isTimezone}
        isSeconds={isSeconds}
        disabled={disabled}
        error={error}
      />
    </div>
  );
};

export default withErrorBoundary(DatePicker);
