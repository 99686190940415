import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ia } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';

const DEFAULT_ERROR_TITLE = 'Action failed';
const DEFAULT_SUCCESS_TITLE = 'Action completed successfully!';
const DEFAULT_ERROR_MESSAGE = 'You don’t have permission to perform this action.';
const DANGER_COLOR = 'danger';

export const useCustomMutation = ({
  mutationFn,
  successMessage,
  errorMessage,
  invalidateQueryKey,
  onSuccess = () => {}
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn,
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          {
            onSuccess(data);
            const message =
              typeof successMessage === 'function'
                ? successMessage(data)
                : successMessage || DEFAULT_SUCCESS_TITLE;

            showAlert({ title: message, color: data?.data?.color });
            if (invalidateQueryKey) {
              if (ia(invalidateQueryKey)) {
                invalidateQueryKey.forEach((key) => {
                  queryClient.invalidateQueries(key);
                });
                break;
              }

              queryClient.invalidateQueries([invalidateQueryKey]);
            }
          }
          break;
        case 2:
          showAlert({
            title: DEFAULT_ERROR_MESSAGE,
            message: DEFAULT_ERROR_MESSAGE,
            icon: 'new-close-circle',
            color: DANGER_COLOR
          });
          break;
        default:
          showAlert({
            title: errorMessage || data?.error || DEFAULT_ERROR_TITLE,
            icon: 'new-close-circle',
            color: DANGER_COLOR
          });
          break;
      }
    },
    onError: (data) => {
      showAlert({
        message: data?.error || errorMessage,
        color: DANGER_COLOR
      });
    }
  });
};
