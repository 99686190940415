import React, { useState } from 'react';
import ProfilePicture from 'components/Profile/ProfilePicture';
import Button from 'components/shared/Buttons/Button';
import AIChatInput from 'components/shared/AIChatInput/AIChatInput';

const UserPrompt = ({ message, onMessageChange }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editableMessage, setEditableMessage] = useState(message);
  const [finalMessage, setFinalMessage] = useState(message);

  const handleInputChange = (e) => {
    setEditableMessage(e.target.value);
  };

  const onEditOpen = () => {
    setIsEdit(true);
  };

  const onEditClose = () => {
    setIsEdit(false);
    setEditableMessage(finalMessage);
  };

  const onPromptUpdate = () => {
    setFinalMessage(editableMessage);
    setIsEdit(false);
    if (onMessageChange) {
      onMessageChange(editableMessage);
    }
  };

  const onGenerate = () => {};

  return (
    <div className="flex flex-row items-start">
      <ProfilePicture firstName="Dibran" lastName="Hoxha" id={1} size={36} className="mr-2" />

      <div className="flex flex-grow flex-col">
        <div className="w-full rounded-lg bg-white">
          {isEdit ? (
            <AIChatInput
              isHTML
              className="mb-3 w-full border-2 border-primary-300 shadow-primary-50 outline outline-1 outline-primary-300 ring-4 ring-[#CBF0FD]"
              leftButtons={[]}
              rightButtons={[]}
              onTextChange={handleInputChange}
              onSubmit={onGenerate}
              placeholder="Type anything.."
              value={editableMessage}
            />
          ) : (
            <div className="p-3">{finalMessage}</div>
          )}
        </div>
        <div className="flex items-center justify-end">
          {!isEdit ? (
            <Button
              size="small"
              text="Edit"
              onClick={onEditOpen}
              icon="new-edit-v3"
              iconSize={18}
              outlined
            />
          ) : (
            <div className="flex flex-row gap-1">
              <Button text="Cancel" onClick={onEditClose} iconSize={18} transparent size="small" />
              <Button size="small" text="Update" onClick={onPromptUpdate} iconSize={18} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserPrompt;
