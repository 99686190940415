import { pluralize } from 'lib/helpers/utility';

export const READY_SUCCESS_MESSAGE = 'Superbills marked ready for claim successfully!';
export const READY_VALIDATION_ERROR_MESSAGE = (errors) =>
  ` There ${Object.keys(errors)?.length === 1 ? 'is' : 'are'} ${Object.keys(errors)?.length} ${pluralize('superbill', Object.keys(errors)?.length)} with errors.`;
export const READY_FOR_CLAIM_MESSAGE = (superbills) =>
  superbills?.length > 0 ? ` ${superbills?.length} successfully marked as ready for claim!` : '';
export const READY_ERROR_MESSAGE = 'Failed to mark superbills ready for claim';

export const COMPLETE_SUCCESS_MESSAGE = 'Superbills marked complete successfully!';
export const COMPLETE_ERROR_MESSAGE = 'Failed to mark superbills complete';

export const DELETE_SUCCESS_MESSAGE = 'Superbills deleted successfully!';

export const DELETE_ERROR_MESSAGE = 'Failed to delete superbills';
