import React, { useEffect, useMemo, useState } from 'react';
import { MyScribeTemplateContextApi, MyScribeTemplateContextData } from './MyScribeTemplateContext';
import { usePrompts } from 'lib/hooks/queries/myscribeAI/usePrompts';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

export const MyScribeTemplateContextProvider = ({ children }) => {
  const [currentActivePrompt, setCurrentActivePrompt] = useState(null);
  const [userPrompt, setUserPrompt] = useState('');
  const [chatLog, setChatLogs] = useState([]);
  const [selectedForms, setSelectedForms] = useState([]);
  const [localPrompts, setLocalPrompts] = useState([]);

  const clinicalNote = useRecoilValue(clinicalNoteState);

  const {
    data: promptData,
    isFetching,
    isLoading
  } = usePrompts({
    params: {
      clinicalNoteId: clinicalNote?.id
    },
    dependencies: [clinicalNote?.id],
    options: {
      enabled: !!clinicalNote?.id
    }
  });
  const isGetPromptsLoading = isFetching || isLoading;
  const prompts = promptData?.prompts;

  useEffect(() => {
    setLocalPrompts(prompts || []);
  }, [prompts]);

  const data = useMemo(
    () => ({ currentActivePrompt, localPrompts, isGetPromptsLoading, userPrompt }),
    [currentActivePrompt, isGetPromptsLoading, localPrompts, userPrompt]
  );

  const api = useMemo(() => ({ setCurrentActivePrompt, setLocalPrompts, setUserPrompt }), []);

  return (
    <MyScribeTemplateContextData.Provider value={data}>
      <MyScribeTemplateContextApi.Provider value={api}>
        {children}
      </MyScribeTemplateContextApi.Provider>
    </MyScribeTemplateContextData.Provider>
  );
};
