import React, { useEffect, useMemo } from 'react';
// import './MedicationsTable.scss';
import { useLocation } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import cs from 'classnames';

import { socket } from 'api/Socket';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';

import { NK_PREFIXES } from '../../Provider/lib/helpers';

const FullWidthCellRenderer = (params) => {
  if (params.data?.isNKMWarning) {
    return (
      <div className="flex items-center gap-2 p-3 bg-warning-50 border-solid  border-neutral-100 w-full">
        <Icon icon="new-help" color="warning" />
        <span className="text-warning-600">{NK_PREFIXES[params?.data?.title]}</span>
      </div>
    );
  }
  return null;
};

const MedicationsTable = ({
  data = [],
  getGridApi = () => null,
  customStyling,
  onCellClicked = () => {},
  getRowId = () => {},
  showNKWarning = false,
  title = ''
}) => {
  const { gridApi } = useTableContext();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    socket.on('veradigm-prescribe', () => {
      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      getGridApi(gridApi);
    }
  }, [gridApi]);

  const defaultColDef = {
    flex: 1
  };

  const tableData = useMemo(() => {
    if (showNKWarning) {
      return [
        {
          title,
          isFullWidth: true,
          isNKMWarning: true
        }
      ];
    }
    return data;
  }, [data, showNKWarning, title]);

  const isFullWidthRow = (params) => {
    return params?.rowNode?.data?.isFullWidth;
  };

  return (
    <ErrorBoundary>
      <div
        className={cs(
          'flex h-full flex-col overflow-hidden !pb-0',
          pathname.includes('myscribe-ai') && 'h-[350px]',
          customStyling
        )}>
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={tableData}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            defaultColDef={defaultColDef}
            onCellClicked={onCellClicked}
            getRowId={getRowId}
            isFullWidthRow={isFullWidthRow}
            fullWidthCellRenderer={FullWidthCellRenderer}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MedicationsTable;
