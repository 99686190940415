export default function getReShapedPersonalInfo(patient) {
  const address = patient?.userAddress;
  const secondaryAddress = patient?.secondaryUserAddress;
  return {
    id: patient?.id,
    f_name: patient?.f_name,
    m_name: patient?.m_name,
    l_name: patient?.l_name,
    p_name: patient?.p_name,
    insurance_benefits: patient?.insurance_benefits,
    eligibility_limit_reached: patient?.eligibilityRequestReached,
    phone: patient?.phone,
    alt_phone: patient?.alt_phone,
    alt_phone_notify: patient?.alt_phone_notify,
    email: patient?.email,
    alt_email: patient?.alt_email,
    alt_email_notify: patient?.alt_email_notify,
    dob: patient?.dob,
    gender: patient?.gender,
    race: patient?.race,
    ssn: patient?.ssn,
    custom_id: patient?.practicePatient?.custom_id,
    personal_id: patient?.personal_id,
    marital_status: patient?.marital_status,
    address: {
      id: address?.id,
      zip: address?.zip,
      city: address?.city,
      state: address?.state,
      country: address?.country,
      address_ln_1: address?.address_ln_1,
      address_ln_2: address?.address_ln_2
    },
    secondary_address: {
      id: secondaryAddress?.id,
      zip: secondaryAddress?.zip,
      city: secondaryAddress?.city,
      state: secondaryAddress?.state,
      country: secondaryAddress?.country,
      address_ln_1: secondaryAddress?.address_ln_1,
      address_ln_2: secondaryAddress?.address_ln_2
    },
    emergency_contact: {
      name: patient?.emergency_contact?.name,
      phone: patient?.emergency_contact?.phone,
      relationship: patient?.emergency_contact?.relationship
    },
    secondary_emergency_contact: {
      name: patient?.secondary_emergency_contact?.name,
      phone: patient?.secondary_emergency_contact?.phone,
      relationship: patient?.secondary_emergency_contact?.relationship
    },
    occupation_id: patient?.occupation_id || patient?.occupation[0]?.id
  };
}
