import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import React from 'react';
import { showAlert } from 'components/shared/Alert/Alert';
import { copyOnClipboard, removeHtmlTags } from 'lib/helpers/utility';
import { useChatContextApi } from 'lib/context/MyScribeAI/MyScribeTemplateContext/ChatContext/ChatContext';

const AIPrompt = ({ message }) => {
  const { onEnhanceNote } = useChatContextApi();

  const onCopyMessage = () => {
    copyOnClipboard({
      text: removeHtmlTags(message),
      onSuccess: () => {
        showAlert({
          title: 'Copy AI Response',
          message: 'AI response copied to clipboard',
          color: 'success'
        });
      },
      onError: () => {
        showAlert({
          title: 'Copy AI Response',
          message: 'Failed to copy AI response',
          color: 'error'
        });
      }
    });
  };

  const onRegenerate = () => {
    onEnhanceNote();
  };

  return (
    <div className="mb-10 mt-2 flex flex-col">
      <div className="flex">
        <div
          className="mr-2 flex items-center justify-center rounded-[7px] border-solid border-primary-100 bg-white"
          style={{
            width: 36,
            height: 36,
            minWidth: 36,
            minHeight: 36
          }}>
          <Icon icon="stars-ai" />
        </div>
        {message?.length ? (
          <div className="flex-1 pb-10 pr-2">
            <div className="w-full rounded-lg bg-white p-3 text-gray-900">
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </div>
            <div className="flex items-center justify-end">
              <div className="mt-1 flex flex-row gap-1">
                <Button
                  size="small"
                  text="Copy"
                  onClick={onCopyMessage}
                  iconSize={18}
                  outlined
                  color="neutral"
                />
                <Button
                  size="small"
                  text="Regenerate"
                  onClick={onRegenerate}
                  iconSize={18}
                  outlined
                  color="neutral"
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AIPrompt;
