import cs from 'classnames';

export const getButtonClasses = ({
  open,
  buttonTheme,
  text,
  isSeparator,
  isBorder,
  buttonClassName,
  activeClassNames,
  disabled,
  buttonThemeType = 'secondary' // 'primary' or 'secondary'
}) => {
  return cs(
    'popover-button flex min-w-[34px] min-h-[34px] cursor-pointer items-center justify-center rounded-md font-500',
    // Base layout classes
    text && !isSeparator && 'px-4',
    isSeparator && 'grid grid-flow-col pl-[14px] pr-[8px]',
    // Border
    isBorder && buttonTheme && buttonThemeType === 'primary'
      ? `border border-solid border-${buttonTheme}-700 focus-within:!border-${buttonTheme}-200 disable-hold-active`
      : isBorder &&
          buttonTheme &&
          `border border-solid border-${buttonTheme}-500 disable-hold-active`,
    // Theme classes
    getButtonThemeClasses({ open, buttonTheme, buttonThemeType }),
    // Additional classes
    buttonClassName,
    activeClassNames && open && activeClassNames?.button,
    disabled && '!cursor-not-allowed opacity-60',
    buttonThemeType === 'primary' && 'popover-button-primary',
    buttonThemeType === 'secondary' && 'popover-button-secondary'
  );
};

export const getButtonThemeClasses = ({ open, buttonTheme, buttonThemeType }) => {
  // Primary theme type
  if (buttonThemeType === 'primary') {
    return cs(
      open
        ? `bg-${buttonTheme}-700 text-white`
        : `bg-${buttonTheme}-500 text-white hover:bg-${buttonTheme}-600`
    );
  }

  // Secondary theme type (default)
  return cs(
    (open && !buttonTheme) || (open && buttonTheme === 'white')
      ? 'bg-primary-700 text-white'
      : `bg-${buttonTheme}-100 text-${buttonTheme}-600`,
    !open && !buttonTheme && 'bg-transparent text-neutral-800 hover:!bg-neutral-50',
    !open &&
      buttonTheme !== 'white' &&
      buttonTheme !== 'transparent' &&
      `text-${buttonTheme}-500 bg-${buttonTheme}-50`,
    !open && buttonTheme === 'white' && 'bg-white'
  );
};

export const getTextClasses = ({ open, buttonTheme, textClassName, activeClassNames }) => {
  return cs(
    'text-sm first-letter:capitalize',
    open && !buttonTheme && 'text-white',
    !open && buttonTheme === 'white' && 'text-primary-600',
    !open && buttonTheme === 'transparent' && 'text-neutral-600',
    open && buttonTheme === 'transparent' && 'text-primary-500',
    textClassName,
    activeClassNames && open && activeClassNames?.text
  );
};

export const getIconColor = ({ disabled, open, buttonTheme, buttonThemeType }) => {
  if (disabled) return 'neutral';
  if (buttonThemeType === 'primary') return 'white';
  if (open && !buttonTheme) return 'white';
  if (open && buttonTheme === 'transparent') return 'primary';
  if (!open && buttonTheme === 'white') return 'primary';
  return buttonTheme ?? (open ? 'white' : '');
};

export const getDropdownIconColor = ({
  open,
  buttonTheme,
  buttonThemeType,
  iconRightColor,
  activeClassNames
}) => {
  if (activeClassNames?.icon && open) return activeClassNames.icon;
  if (iconRightColor) return iconRightColor;
  if (buttonThemeType === 'primary') return 'white';
  if (!open && buttonTheme === 'white') return 'primary';
  if (open && buttonTheme === 'transparent') return 'primary';
  return buttonTheme ?? (open ? 'white' : '');
};

export const getSeparatorClasses = ({ buttonTheme, buttonThemeType }) => {
  return cs(
    `block h-[calc(100%+2px)] w-[1px] border-none ml-[14px] mr-[2px] bg-${buttonTheme}-500`,
    buttonThemeType === 'primary' ? `bg-${buttonTheme}-700` : `bg-${buttonTheme}-500`
  );
};
