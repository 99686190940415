import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getTasks } from 'api/Tasks';

export const useTasks = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['tasks', ...dependencies], () => getTasks(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
