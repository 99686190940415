import cs from 'classnames';
import { permissions } from 'components/state';
import React, { useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { allergies, typicalReactions } from '../../../../../constants';
import Select from '../../../Select/Select';
import Textarea from '../../../Textarea/Textarea';
import { isDataReadOnly } from '../lib/aggregatedDataHelper';

const AllergiesRow = ({
  allergy,
  typicalReaction,
  note,
  id,
  onChange,
  className,
  formik,
  fromCheckin = false,
  createdBy = null
}) => {
  const [disabled, setDisabled] = useState(false);
  const noteRef = useRef(null);
  const userPermissions = useRecoilValue(permissions);

  const dataReadOnly = isDataReadOnly({
    data: { id, createdBy },
    canUserUpdatePatientDocuments: userPermissions?.form?.update_patient_documents
  });

  return (
    <div className={cs('flex w-full items-center gap-x-[10px]', className)}>
      <div
        className={cs(
          fromCheckin ? 'grid-cols-1' : 'grid-cols-2',
          'grid w-full  gap-4 sm:flex sm:flex-col'
        )}>
        <Select
          inputId="select-allergy"
          label="Allergy"
          placeholder="Type and select allergy"
          options={allergies}
          value={allergy}
          name="allergy"
          isCreatable
          error={formik?.errors?.allergy}
          onChange={(event) => onChange('allergy', event, id)}
          disabled={disabled || dataReadOnly}
        />
        <Select
          inputId="select-reaction"
          label="Typical reaction"
          placeholder="Type and select reaction"
          options={typicalReactions}
          value={typicalReaction}
          name="typicalReaction"
          isCreatable
          onChange={(event) => onChange('typicalReaction', event, id)}
          disabled={disabled || dataReadOnly}
        />
        <div className={cs(!fromCheckin && 'col-span-2 ', 'w-full')}>
          <Textarea
            label="Notes"
            isEditor={!fromCheckin}
            className={cs(!fromCheckin ? '!min-h-[120px]' : '!min-h-[80px]', 'mb-2  text-sm')}
            placeholder="Add additional notes here"
            textareaClassName="!min-h-[80px]"
            id="note"
            data-qa="notes"
            name="note"
            value={note}
            onChange={
              fromCheckin
                ? (event) => onChange('note', event.target.value, id)
                : (value) => onChange('note', value, id)
            }
            disabled={disabled || dataReadOnly}
            transcribing
            forwardedRef={noteRef}
          />
        </div>
      </div>
    </div>
  );
};

export default AllergiesRow;
