import React from 'react';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom';
import { datapoints_object } from './constants';
import { ia } from 'lib/helpers/utility';

export default function Databank() {
  const { type } = useParams();
  const { pathname } = useLocation();
  const datapoints = Object.keys(datapoints_object);

  const cptPath = pathname?.includes('cpt-codes');
  const icd10Path = pathname?.includes('icd10-codes');
  const occupationPath = pathname?.includes('occupations');
  const growthData = pathname?.includes('growth_data');
  const landmarks = pathname?.includes('landmarks');

  const titleCase = (s) =>
    s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

  if (type || cptPath || occupationPath || growthData || landmarks || icd10Path) return <Outlet />;

  return (
    <div className="grid grid-cols-3 gap-4 p-4">
      {datapoints.map((element, index) => {
        return (
          <Link
            key={index}
            data-qa={`data-point-${element}`}
            to={`/admin/databank/${element}`}
            className="rounded-md border !p-4 text-neutral-800 border-solid border-neutral-300 hover:border-primary-500">
            <div>{titleCase(element)}</div>
          </Link>
        );
      })}
    </div>
  );
}
