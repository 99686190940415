import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Icon from 'components/shared/Icon/Icon';
import { ia } from 'lib/helpers/utility';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import cs from 'classnames';
import { tagColorOptions } from '../../lib/tagColorOptions';
import NotFound from 'components/practice/Dashboard/components/NotFound';

const Reorder = ({
  importantTags = [],
  regularTags = [],
  onDragEnd = () => {},
  handleRemove = () => {},
  fromPatientChart = false
}) => {
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    // Pass the correct list based on the source droppableId
    const sourceList = result.source.droppableId === 'importantTags' ? importantTags : regularTags;
    onDragEnd(
      result,
      sourceList,
      result.source.droppableId === 'importantTags' ? 'importantTags' : 'regular'
    );
  };

  return (
    <>
      {ia(importantTags) || ia(regularTags) ? (
        <DragDropContext onDragEnd={handleDragEnd}>
          <div className="flex max-h-[240px] flex-col gap-3 overflow-y-auto">
            {ia(importantTags) && (
              <div className="flex flex-col gap-1">
                <p className="text-sm text-neutral-800">Important tags</p>
                <Droppable droppableId="importantTags">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={cs(
                        'flex min-h-[40px] flex-col gap-2 rounded-md py-2',
                        snapshot.isDraggingOver && 'bg-primary-50',
                        !importantTags.length &&
                          'items-center justify-center border border-dashed border-neutral-300'
                      )}>
                      {importantTags.length > 0 ? (
                        importantTags?.map((tag, index) => {
                          const isNew = tagColorOptions.find(
                            (option) => option.color === tag?.color
                          );
                          let currBorderColor = tagColorOptions.find(
                            (option) => option.color === tag?.color
                          )?.border;
                          return (
                            <Draggable key={tag?.id} draggableId={String(tag?.id)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    opacity: snapshot.isDragging ? 0.8 : 1
                                  }}
                                  className="flex items-center gap-1 truncate">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="cursor-grab p-1 active:cursor-grabbing">
                                    <Icon
                                      icon="new-drag-drop"
                                      className="cursor-grab active:cursor-grabbing"
                                    />
                                  </div>
                                  <div
                                    className="flex max-h-[25px] items-center gap-[6px] truncate rounded px-2 py-[2px]"
                                    style={{
                                      backgroundColor: tag?.color,
                                      border: `1px solid ${currBorderColor || 'transparent'}`
                                    }}>
                                    <Icon icon="new-info-circle" color="neutral" shade={900} />
                                    <span
                                      className={cs(
                                        'select-none truncate text-sm font-400 leading-5',
                                        isNew ? 'text-neutral-900' : 'text-white'
                                      )}>
                                      {tag?.name}
                                    </span>
                                    {fromPatientChart && (
                                      <Icon
                                        icon="new-close-sm"
                                        size={8}
                                        color="black"
                                        stroke
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemove({ tag, isSelected: true, fromView: true });
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })
                      ) : (
                        <span className="text-sm text-neutral-500">
                          Drag tags here to mark as important
                        </span>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )}

            {ia(regularTags) && (
              <div className="flex flex-col gap-1">
                <p className="text-sm text-neutral-800">
                  {ia(importantTags) ? 'All other tags on this chart' : 'All tags on this chart'}
                </p>
                <Droppable droppableId="regularTags">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={cs(
                        'flex flex-col rounded-md py-2',
                        snapshot.isDraggingOver && 'bg-primary-50',
                        !regularTags.length &&
                          'items-center justify-center border border-dashed border-neutral-300'
                      )}>
                      {regularTags.length > 0 &&
                        regularTags?.map((tag, index) => {
                          const isNew = tagColorOptions.find(
                            (option) => option.color === tag?.color
                          );
                          let currBorderColor = tagColorOptions.find(
                            (option) => option.color === tag?.color
                          )?.border;
                          return (
                            <Draggable key={tag?.id} draggableId={String(tag?.id)} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={{
                                    ...provided.draggableProps.style,
                                    opacity: snapshot.isDragging ? 0.8 : 1
                                  }}
                                  className="mt-2 flex items-center gap-1 truncate first-of-type:mt-0">
                                  <div
                                    {...provided.dragHandleProps}
                                    className="cursor-grab p-1 active:cursor-grabbing">
                                    <Icon
                                      icon="new-drag-drop"
                                      className="cursor-grab active:cursor-grabbing"
                                    />
                                  </div>
                                  <div
                                    className="flex max-h-[25px] items-center gap-[6px] truncate rounded px-2 py-[2px]"
                                    style={{
                                      backgroundColor: tag?.color,
                                      border: `1px solid ${currBorderColor || 'transparent'}`
                                    }}>
                                    <span
                                      className={cs(
                                        'select-none truncate text-sm font-400 leading-5',
                                        isNew ? 'text-neutral-900' : 'text-white'
                                      )}>
                                      {tag?.name}
                                    </span>
                                    {fromPatientChart && (
                                      <Icon
                                        icon="new-close-sm"
                                        size={8}
                                        color="black"
                                        stroke
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleRemove({ tag, isSelected: true, fromView: true });
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </div>
        </DragDropContext>
      ) : (
        <NotFound
          icon="new-no-cpt-codes-illustration"
          title="No tag for this patient"
          description="Add new tags by clicking the add tags button"
          titleClassName="text-md"
        />
      )}
    </>
  );
};

export default withErrorBoundary(Reorder);
