import { requestApi } from './Api';

export const getCards = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/transactions/token/list',
    params
  });

export const deleteCard = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/patient/card/remove',
    params
  });