import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import _ from 'lodash';

import { previewSavedFax } from 'api/Fax';

import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import Skeleton from '../Skeleton/Skeleton';

import EditorModal from './components/EditorModal';
import applyChangesToImage from './lib/applyChangesToImage';
import applyChangesToPDF from './lib/applyChangesToPDF';

const DocumentModal = ({
  src,
  id,
  type = 'pdf',
  kind = 'patient_documents',
  handleOpen,
  handleClose,
  isEditable,
  title,
  subheaderComponent,
  ...rest
}) => {
  const [editModal, setEditModal] = useState(null);
  const [scale, setScale] = useState(1.5);
  const navigate = useNavigate();
  const [canvasStates, setCanvasStates] = useState({});
  const [document, setDocument] = useState(src);
  const [isLoading, setIsLoading] = useState(true);
  const [historyMode, setHistoryMode] = useState(false);
  const { myPortalPractice } = useParams();

  useEffect(() => {
    if (!isEditable) {
      setIsLoading(false);
    }
  }, [isEditable]);

  const [previewedFax, setPreviewedFax] = useState(src);

  const loadDocument = async (data) => {
    let faxPDF = src;
    if (kind === 'fax' && !src) {
      const previewedFax = await previewSavedFax(navigate, id);
      faxPDF = previewedFax?.url;
    }
    if (data) {
      const { blob, scale, changes } =
        type === 'image'
          ? await applyChangesToImage({
              image: src || faxPDF,
              navigate,
              id,
              historyData: data || null,
              kind,
              myPortalPractice
            })
          : await applyChangesToPDF({
              pdf: src || faxPDF,
              navigate,
              id,
              historyData: data || null,
              kind,
              myPortalPractice
            });
      setScale(scale);
      setCanvasStates(changes);
      setDocument(blob ? URL.createObjectURL(blob) : src);
    }
    setPreviewedFax(faxPDF);
  };

  const calcHeight = () => {
    let height = 56;
    if (subheaderComponent) height += 54;
    if (rest.isFooter) height += 65;
    return `calc(100vh - ${height}px)`;
  };

  return (
    <Modal
      handleOpen={handleOpen}
      handleClose={handleClose}
      className="flex flex-col max-w-[1080px] bg-white"
      bodyClassName="p-0 overflow-hidden flex flex-col h-full"
      footerClassName="mt-auto h-max z-10"
      isLarge
      customHeader={
        <div className="mr-3 flex h-full !w-[100%] items-center justify-between">
          <h3 className="whitespace-nowrap text-lg max-w-[600px] truncate">{title}</h3>
          {isEditable && (
            <div className="flex gap-2">
              <Button
                icon="new-history"
                iconIsStroke
                disabled={isLoading}
                onClick={() => {
                  setEditModal('history');
                  setHistoryMode(true);
                }}
              />
              <Button
                text="Markup"
                icon="new-edit"
                iconColor="white"
                color="primary"
                disabled={isLoading}
                size="small"
                onClick={() => setEditModal('edit')}
                data-qa="edit-btn"
              />
            </div>
          )}
        </div>
      }
      slideFromRight
      shouldCloseOnEsc={false}
      isFooter={false}
      {...rest}>
      <div className="flex flex-col">
        {subheaderComponent && (
          <div className="flex items-center h-[54px] bg-primary-50">{subheaderComponent}</div>
        )}
        <div className="flex justify-center flex-1 h-full">
          {isLoading ? (
            <div className="!w-full !h-full">
              <Skeleton
                height={calcHeight()}
                count={1}
                containerStyle={{ minHeight: '500px', width: '100%' }}
              />
            </div>
          ) : document || src || previewedFax ? (
            type === 'image' ? (
              <img
                className="max-w-full"
                style={{ maxHeight: calcHeight() }}
                src={document || src}
              />
            ) : (
              <iframe
                src={document || src || previewedFax}
                style={{
                  width: '924px', // Set width to match A4 paper width
                  height: '11.69in', // Set height to match A4 paper height
                  objectFit: 'contain', // Ensure the PDF fits within the iframe without distortion
                  maxHeight: calcHeight()
                }}
              />
            )
          ) : (
            <div className="flex flex-1 w-full h-full items-center justify-center">
              <p className="text-center text-sm text-neutral-500 p-8">No document found</p>
            </div>
          )}
        </div>
      </div>

      {isEditable && (
        <EditorModal
          {...{
            src,
            id,
            type,
            kind,
            loadDocument,
            title,
            previewedFax,
            editModal,
            setScale,
            historyMode,
            setIsLoading,
            canvasStates,
            scale,
            setEditModal,
            setHistoryMode,
            setCanvasStates,
            isLoading
          }}
        />
      )}
    </Modal>
  );
};

export default DocumentModal;
