import { withErrorBoundary } from 'components/shared/Error/Boundary';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import React, { memo } from 'react';
import cs from 'classnames';
import './SimpleTable.scss';
import { ia } from 'lib/helpers/utility';

const SimpleTable = ({ data, emptyState = 'No data' }) => {
  const { name, colDefs } = useTableContext();

  if (!ia(colDefs)) {
    return null;
  }

  return (
    <table key={name} className="primary-table mb-4">
      <thead>
        <tr>
          {colDefs?.map((col) => {
            return (
              <th key={`col-${col.field ?? col.headerName}-header`} className={cs(col?.className)}>
                {col.headerName}
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody>
        {ia(data) ? (
          React.Children.toArray(
            data?.map((item) => {
              return (
                <tr>
                  {colDefs.map((col) => {
                    let cellContent = item?.[col.field];

                    if (col.cellRenderer) {
                      cellContent = col.cellRenderer({
                        value: item?.[col.field],
                        data: item,
                        node: { group: false }
                      });
                    }

                    if (col.valueFormatter) {
                      cellContent = col.valueFormatter({ value: item?.[col.field], data: item });
                    }

                    return (
                      <td key={`${col.field ?? col.headerName}-${item.id}-cell`}>{cellContent}</td>
                    );
                  })}
                </tr>
              );
            })
          )
        ) : (
          <tr>
            <td colSpan={colDefs.length} className="p-[20px] text-center">
              <p className="py-10 font-medium text-neutral-500 ">{emptyState}</p>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default withErrorBoundary(memo(SimpleTable));
