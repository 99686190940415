import React from 'react';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import Input from 'components/shared/Input/Input';

const AppointmentDate = ({ timezone, className }) => {
  const { values, setFieldValue } = useFormikContext();
  const isAptDisabled = values.appointment_disabled;

  const formatDateForInput = (date, timezone) =>
    moment.tz(date, timezone).format('MM/DD/YYYY, dddd');

  const handleDateChange = (date) => {
    const starts_at = moment(values?.starts_at).format('YYYY-MM-DDTHH:mm');
    const ends_at = moment(values?.ends_at).format('YYYY-MM-DDTHH:mm');
    const diffMin = moment(ends_at).diff(moment(starts_at), 'minutes');

    const dt = moment.tz(moment(date).add(12, 'h'), timezone).format('L');
    const time = moment.tz(values.starts_at, timezone).format('h:mm A') || '';
    let newTime = moment.tz(dt + ' ' + time, 'M/D/YYYY h:mm A z', timezone);

    setFieldValue('starts_at', moment.tz(newTime, timezone).format());
    setFieldValue('ends_at', moment.tz(newTime, timezone).add(diffMin, 'm').format());
  };

  return (
    <Popover className={className}>
      {({ close }) => (
        <div className="relative">
          <Popover.Button className="w-full !p-0" disabled={isAptDisabled}>
            <Input
              label="Appointment date"
              placeholder="Please select date"
              rightIcon="new-calendar-gray"
              value={formatDateForInput(values.starts_at, timezone)}
              data-qa="appointment-date-input"
            />
          </Popover.Button>

          <Popover.Panel className="absolute right-0 top-[110%] z-[100] flex w-max bg-white shadow-md">
            <Calendar
              date={new Date(values.starts_at)}
              onChange={(date) => handleDateChange(date, close)}
              data-qa="appointment-date-calendar"
            />
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default AppointmentDate;
