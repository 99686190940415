import React, { Fragment, useEffect, useRef, useState } from 'react';

import { ia, mString } from 'lib/helpers/utility';

const sumGroupValues = (api, groupNode, column) => {
  let sum = 0;
  groupNode.allLeafChildren.forEach((childNode) => {
    const columnDef = column.getColDef();
    const cellValue = columnDef.valueGetter
      ? columnDef.valueGetter({ data: childNode.data, colDef: columnDef })
      : childNode.data[columnDef.field];

    if (typeof cellValue === 'number') {
      sum += cellValue;
    }
  });
  return sum;
};

const getValuesFromSelectedCells = (api, range) => {
  const values = {};

  if (range && range.columns && range.startRow && range.endRow) {
    range.columns.forEach((column) => {
      const columnId = column.getColId();
      if (!values[columnId]) values[columnId] = [];

      const startRowIndex = range.startRow.rowIndex;
      const endRowIndex = range.endRow.rowIndex;

      const minRowIndex = Math.min(startRowIndex, endRowIndex);
      const maxRowIndex = Math.max(startRowIndex, endRowIndex);

      if (startRowIndex > endRowIndex) {
        for (let rowIndex = maxRowIndex; rowIndex >= minRowIndex; rowIndex--) {
          const rowNode = api.getDisplayedRowAtIndex(rowIndex);

          if (rowNode) {
            const columnDef = column.getColDef();
            const cellValue = rowNode.group
              ? sumGroupValues(api, rowNode, column)
              : columnDef.valueGetter
                ? columnDef.valueGetter({ data: rowNode.data, colDef: columnDef })
                : rowNode.data[columnDef.field];

            if (typeof cellValue === 'number') {
              values[columnId].push(cellValue);
            }
          }
        }
        return;
      }

      for (let rowIndex = minRowIndex; rowIndex <= maxRowIndex; rowIndex++) {
        const rowNode = api.getDisplayedRowAtIndex(rowIndex);

        if (rowNode) {
          const columnDef = column.getColDef();

          const cellValue = rowNode.group
            ? sumGroupValues(api, rowNode, column)
            : columnDef.valueGetter
              ? columnDef.valueGetter({ data: rowNode.data, colDef: columnDef })
              : rowNode.data[columnDef.field];

          if (typeof cellValue === 'number') {
            values[columnId].push(cellValue);
          }
        }
      }
    });
  }

  return values;
};

const CustomStatusBarAggregationComponent = (props) => {
  const { statusPanelData = [], api, columnApi } = props;
  const [finalStatusPanelData, setFinalStatusPanelData] = useState(statusPanelData);

  const eventListenerRef = useRef(false);

  useEffect(() => {
    let columnSums = {};

    const updateMetrics = () => {
      columnSums = {};
      const cellRanges = api.getCellRanges();

      if (cellRanges && cellRanges.length > 0) {
        if (
          cellRanges[0].startRow.rowIndex === cellRanges[0].endRow.rowIndex &&
          cellRanges[0].columns.length === 1
        ) {
          setFinalStatusPanelData(statusPanelData);
          return;
        }
        cellRanges.forEach((range) => {
          const rangeValues = getValuesFromSelectedCells(api, range);

          Object.entries(rangeValues).forEach(([columnId, values]) => {
            const formattedColumnId = columnId.toLowerCase();
            if (!columnSums[formattedColumnId]) {
              columnSums[formattedColumnId] = 0;
            }

            columnSums[formattedColumnId] += values.reduce((acc, curr) => acc + curr, 0);
          });
        });
      } else {
        setFinalStatusPanelData(statusPanelData);
      }

      const updatedStatusPanelData = statusPanelData.map(({ field, label }) => {
        const newValue = columnSums[field.toLowerCase()] ?? null;
        return { field, label, value: newValue };
      });

      setFinalStatusPanelData(updatedStatusPanelData);
    };

    if (!eventListenerRef.current) {
      api.addEventListener('rangeSelectionChanged', updateMetrics);
      eventListenerRef.current = true;
    }

    return () => {
      api.removeEventListener('rangeSelectionChanged', updateMetrics);
    };
  }, [api, columnApi, statusPanelData]);

  return (
    <div className="flex flex-wrap items-center space-x-3 -mt-1 text-sm">
      {ia(finalStatusPanelData) &&
        finalStatusPanelData.map(({ field, label, value }) =>
          value >= 0 ? (
            <Fragment key={field}>
              <span className="font-semibold">{label}:</span>
              <span>{mString(value)}</span>
            </Fragment>
          ) : null
        )}
    </div>
  );
};

export default CustomStatusBarAggregationComponent;
