import React, { forwardRef } from 'react';

import { useGetAiTask } from 'lib/hooks/queries/admin/intelligence/useAiTasks';

import { withErrorBoundary } from 'components/shared/Error/Boundary';
import Loader from 'components/shared/Loader/Loader';

const AdminIntelligenceAiTaskEntry = forwardRef(({ src }, ref) => {
  const { data, isLoading, isFetching } = useGetAiTask({
    params: { aiTaskId: src },
    name: `ai_task_${src}`,
    dependencies: [src]
  });

  return (
    <div ref={ref}>
      {(isLoading || isFetching) && (
        <div className="flex flex-row gap-2">
          <Loader />
          <div>Loading...</div>
        </div>
      )}
      <div className="flex flex-row gap-2">
        {data?.data?.request?.includes('<') ? (
          <div className="flex flex-col" dangerouslySetInnerHTML={data?.data?.request}></div>
        ) : (
          <div className="flex flex-col">{data?.data?.request}</div>
        )}
        {data?.data?.response.includes('<') ? (
          <div className="flex flex-col" dangerouslySetInnerHTML={data?.data?.response}></div>
        ) : (
          <div className="flex flex-col">{data?.data?.response}</div>
        )}
      </div>
    </div>
  );
});

export default withErrorBoundary(AdminIntelligenceAiTaskEntry);
