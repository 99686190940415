import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import SimpleAccordion from '../ClinicalNote/shared/SimpleAccordion';
import InsuranceProfileContent from './components/InsuranceProfileContent';
import { Capitalize } from 'lib/helpers/utility';
import { showAlert } from 'components/shared/Alert/Alert';

export default function InsuranceProfileMobile({
  insuranceProfile,
  index,
  deleteProfile,
  selected,
  setSelected,
  isOpen,
  toggleOne,
  handleEditInsuranceProfile
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const navigate = useNavigate();
  //edit insurance profile modal

  const deleteInsuranceProfile = async () => {
    try {
      const res = await interimApi(
        '/api/insurance_profiles/delete',
        { id: insuranceProfile.id },
        navigate
      );
      if (res?.data?.deleted) {
        deleteProfile(insuranceProfile);
        setShowConfirmationModal(false);
        showAlert({ title: 'Successfully deleted insurance profile', color: 'success' });
      }
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const sameId = selected && selected?.id === insuranceProfile?.id;
  return (
    <>
      <SimpleAccordion
        key={insuranceProfile.id}
        title={
          <div className="flex items-center gap-[6px] p-2 sm:p-0 xs:p-0">
            <Icon icon="new-heart-plus" />
            <span className="text-sm font-500 text-neutral-800">{`Insurance profile ${
              index + 1
            }`}</span>
            <i className="text-sm font-400 text-neutral-600">
              {Capitalize(insuranceProfile?.type)}
            </i>
          </div>
        }
        titleHighlight={false}
        open={isOpen && isOpen[insuranceProfile?.id]}
        onToggle={() => toggleOne(insuranceProfile.id)}
        onClick={() => setSelected && setSelected(insuranceProfile)}
        className={cs(
          'cursor-pointer !border-2',
          !sameId
            ? '!border-transparent bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.04)]'
            : '!border-primary-100 !bg-primary-50 shadow-[0px_4px_16px_rgba(0,171,232,0.24)]'
        )}
        edit={
          <>
            <div className="flex flex-wrap items-center gap-1">
              <Icon
                icon="new-edit-gray"
                data-qa="edit-btn-insuranceprofile"
                onClick={() => handleEditInsuranceProfile(insuranceProfile, index)}
                color="neutral"
              />
              <Icon
                icon="trash"
                data-qa="delete-btn-insuranceprofile"
                onClick={() => setShowConfirmationModal(true)}
              />
            </div>
          </>
        }>
        <InsuranceProfileContent
          className="p-2 sm:p-0 xs:p-0"
          insuranceProfile={insuranceProfile}
        />
      </SimpleAccordion>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={() => deleteInsuranceProfile()}
        title="Delete insurance profile"
        message="Are you sure you want to delete this insurance profile?"
        variant="danger"
      />
    </>
  );
}
