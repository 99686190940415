import { capitalize } from 'lodash';

export const adminsStatuses = [
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'active',
    label: 'Active'
  },
  {
    value: 'inactive',
    label: 'Inactive'
  }
];

export const adminRoles = [
  {
    value: 'owner',
    label: 'Owner'
  },
  {
    value: 'test',
    label: 'Test'
  }
];

export const defaultColumns = [
  { field: 'id', headerName: 'ID' },
  {
    field: 'f_name',
    headerName: 'First Name',
    valueFormatter: ({ value }) => capitalize(value)
  },
  { 
    field: 'l_name', 
    headerName: 'Last Name', 
    valueFormatter: ({ value }) => capitalize(value) 
  },
  { 
    field: 'email', 
    headerName: 'Email' 
  },
  { 
    field: 'role', 
    headerName: 'Role',
    valueFormatter: ({ value }) => capitalize(value)
  },
];

export const defaultFilters = {
  searchTerm: {
    type: 'search',
    values: null,
    title: 'Admins',
    placeholder: 'Search admins'
  },
  status: {
    type: 'checkbox',
    values: null,
    options: adminsStatuses,
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  },
  role: {
    type: 'search',
    multiple: true,
    values: [],
    options: adminRoles,
    title: 'Role',
    loadOptions: () => adminRoles
  },
};
