import { requestApi } from './Api';

export const getFilters = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filters/get',
    params
  });
};

export const getDefaultFilters = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filters/get_default',
    params
  });
};

export const createFilter = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filters/create',
    params
  });
};

export const makeDefaultFilter = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filters/makeDefault',
    params
  });
};

export const deleteFilter = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/filters/delete',
    params
  });
};
