import Icon from 'components/shared/Icon/Icon';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import React from 'react';

const getRecordVisitText = (state) => {
  const stateText = {
    START: (
      <>
        <p className="text-sm text-white">MyScribe AI is actively documenting.</p>

        <p className="!mt-4 text-white">
          For optimal performance, please keep the screen open while engaging in real conversations
          with your patients.
        </p>
      </>
    ),
    PAUSE: <p className="text-sm text-white">MyScribe AI is paused...</p>
  };

  const defaultText = (
    <>
      <p className="text-sm text-white">
        Our AI streamlines the documentation process by effortlessly capturing and transcribing
        conversations between healthcare providers and patients in a HIPAA complaint manner.
      </p>

      <p className="!mt-4 text-sm text-white">
        Click below to revolutionize your charting with MyScribe AI!
      </p>
    </>
  );

  return stateText[state] ?? defaultText;
};

const RecordingView = () => {
  const { currentState } = useAmbientListeningContext();

  return (
    <div className="mb-[50px] flex flex-col items-center gap-10 text-center">
      <div className="flex items-center justify-start">
        <div className="z-50">
          <Icon icon="ai-circles-wave" className="animate-pulse" />
        </div>
      </div>
      <Icon icon="ai-bg-eclipse" className="absolute top-0 z-50" />
      <div className="flex">
        <div className="mb-5 bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
          Ambient Listening
        </div>
        <Icon icon="stars-ai" color="primary" className="ml-1 items-start" />
      </div>
      <div className="max-w-[700px]">{getRecordVisitText(currentState)}</div>
    </div>
  );
};

export default RecordingView;
