import React from 'react';
import { useMacrosContext } from '../../../../../../../lib/context/MacrosContext/MacrosContext';
import Modal from '../../../../../../shared/Modal/Modal';
import Button from '../../../../../../shared/Buttons/Button';
import Input from '../../../../../../shared/Input/Input';
import Textarea from '../../../../../../shared/Textarea/Textarea';

const EditMacro = () => {
  const { updateMacro, isUpdatingMacros, editMacro, setEditMacro, filters } = useMacrosContext();
  const { macroType } = filters;

  const handleUpdate = () => {
    const updatedMacroObj = { ...editMacro, body: JSON.stringify(editMacro.body) };
    updateMacro(updatedMacroObj);
    clearInputsAndClose();
  };

  const clearInputsAndClose = () => setEditMacro(null);

  const updateEditMacroObj = (event) => {
    const field = event.target.name;
    const value = event.target.value;

    setEditMacro((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <Modal
      isOpen={!!editMacro}
      handleClose={clearInputsAndClose}
      title={`Update Macro`}
      slideFromRight
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            onClick={clearInputsAndClose}
            outlined
            text="Cancel"
            color="neutral"
            data-qa="cancel-cnat-btn"
          />
          <Button
            loading={isUpdatingMacros}
            onClick={handleUpdate}
            text={`Update Macro`}
            data-qa="update-macro"
          />
        </div>
      }>
      <Input
        id={`macro-input-name`}
        label={
          <p className="flex">
            Macro Label<span className="text-danger-500">*</span>
          </p>
        }
        data-qa="macro-input-name"
        placeholder={`Enter macro label`}
        autoComplete="off"
        name="name"
        value={editMacro?.name}
        onChange={updateEditMacroObj}
      />

      {macroType === 'text_macro' && (
        <div className="!mt-3">
          <Textarea
            id={`macro-textarea`}
            label={
              <p className="flex">
                Macro Area<span className="text-danger-500">*</span>
              </p>
            }
            data-qa={`enter-macro-label`}
            placeholder={`Enter macro area`}
            name="body"
            value={editMacro?.body}
            onChange={updateEditMacroObj}
          />
        </div>
      )}
    </Modal>
  );
};

export default EditMacro;
