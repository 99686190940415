import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import cs from 'classnames';
import { useRecoilValue } from 'recoil';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { ia, iaRa, pm } from 'lib/helpers/utility';

import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Loader from 'components/shared/Loader/Loader';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Switch from 'components/shared/Switch/Switch';
import Textarea from 'components/shared/Textarea/Textarea';
import {
  clinicalNote as clinicalNoteState,
  intelligenceAiState,
  permissions,
  userState
} from 'components/state';

const NarrativeForm = ({
  restData = { customFormId: undefined },
  setCurrentForm = () => null,
  narrativeOptions = [],
  syncNarrative,
  setSyncNarrative = () => null,
  onChange = () => null,
  narrativeClassName = null,
  textAreaClassName = null,
  isLoading = false
}) => {
  const userPermissions = useRecoilValue(permissions);
  const isPermission = pm(
    userPermissions,
    ['myscribe.narrative_update', 'myscribe.narrative_read'],
    'or'
  );

  const { setEnhanceNarrativeParams, isFetchingEnhanceNarrative } = useClinicalNoteContext();
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const intelligenceAi = useRecoilValue(intelligenceAiState);
  const user = useRecoilValue(userState);

  const [confirmationModal, setConfirmationModal] = useState({
    show: false,
    doNotShowAgainCheckbox: false
  });
  const { formName, type: formType } = useParams();

  const clearNarrative = () => setCurrentForm((prevState) => ({ ...prevState, narrative: null }));

  const shouldHideConfirmationModalNextTime = () => {
    const narrative_sync_checkbox = localStorage.getItem('narrative_sync_checkbox');

    const parsedNarrativeSyncCheckbox =
      typeof narrative_sync_checkbox === 'string' ? JSON.parse(narrative_sync_checkbox) : {};

    return (
      parsedNarrativeSyncCheckbox?.doNotShowConfirmModal &&
      parsedNarrativeSyncCheckbox?.userId === user?.id
    );
  };

  const switchSyncNarrative = () => {
    const hasUserCheckedDoNotShowAgain = shouldHideConfirmationModalNextTime();

    if (!syncNarrative && !hasUserCheckedDoNotShowAgain) {
      setConfirmationModal((prevState) => ({ ...prevState, show: true }));
      return;
    }

    setSyncNarrative((prev) => !prev);
  };

  const handleContinue = () => {
    setSyncNarrative(true);

    if (confirmationModal?.doNotShowAgainCheckbox) onCheckboxChange();

    setConfirmationModal((prevState) => ({
      ...prevState,
      show: false,
      doNotShowAgainCheckbox: false
    }));
  };

  const handleEnhanceNarrative = () => {
    setEnhanceNarrativeParams({
      kind: restData?.noteType || formType,
      name: restData?.formName || formName,
      body: restData?.value,
      customFormId: restData?.customFormId == undefined ? formName : restData?.customFormId
    });
  };

  useEffect(() => {
    if (
      intelligenceAi?.path?.includes?.(formName) &&
      intelligenceAi?.path?.includes?.(formType) &&
      restData?.value !== intelligenceAi.response
    ) {
      onChange(intelligenceAi.response);
    }
  }, [intelligenceAi]);

  const newOptions = [
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Switch onChange={() => null} checked={syncNarrative} />
          <p>{syncNarrative ? 'Unsync' : 'Sync'} narrative</p>
        </div>
      ),
      onClick: () => switchSyncNarrative()
    },
    {
      title: (
        <div className="flex gap-1.5 transition-all hover:bg-primary-50">
          <Icon icon="new-delete-macro" color="primary" size={19} className="cursor-pointer" />
          <p>Clear Narrative</p>
        </div>
      ),
      onClick: () => clearNarrative()
    }
  ];
  newOptions.push(...iaRa(narrativeOptions));
  // if (
  //   userPermissions?.is_admin &&
  //   !newOptions.find((v) => v?.id === 'myscribe-enhance-narrative')
  // ) {
  //   newOptions.push({
  //     title: (
  //       <div className="flex gap-1.5 transition-all hover:bg-primary-50">
  //         {isFetchingEnhanceNarrative ? (
  //           <Loader outlined className="text-primary-500" />
  //         ) : (
  //           <Icon icon="new-myscribe-stars" color="primary" size={19} className="cursor-pointer" />
  //         )}
  //         <p>Enhance with MyScribe AI</p>
  //       </div>
  //     ),
  //     onClick: () => !isFetchingEnhanceNarrative && handleEnhanceNarrative(),
  //     id: 'myscribe-enhance-narrative-self'
  //   });
  // }

  const onCheckboxChange = () => {
    const objectToInsert = JSON.stringify({
      doNotShowConfirmModal: event,
      userId: user?.id
    });

    localStorage.setItem('narrative_sync_checkbox', objectToInsert);
  };

  const colorBasedOnSync = syncNarrative ? 'text-primary-600' : 'text-danger-600';

  const handleQuillChange = (content, delta, source) => {
    if (ia(delta?.ops) && source === 'user') {
      onChange(content);
      return;
    }
    if (!delta && !source) {
      onChange(content);
      return;
    }
  };

  const NarrativeLabelWithSwitch = () => {
    return (
      <div className="flex items-center gap-1.5 transition-all hover:bg-primary-50">
        <p>
          Narrative -{' '}
          <span className={colorBasedOnSync}>(Sync {syncNarrative ? 'ON' : 'OFF'})</span>
        </p>
        <div className="flex">
          <Switch
            onChange={switchSyncNarrative}
            checked={syncNarrative}
            disabled={isFetchingEnhanceNarrative || isLoading}
          />
        </div>
        {(isFetchingEnhanceNarrative || isLoading) && (
          <Loader outlined={true} className="bg-primary-500 text-white" />
        )}
      </div>
    );
  };

  return (
    <div className={cs('!mt-3', narrativeClassName)}>
      <Textarea
        {...restData}
        isEditor
        label={<NarrativeLabelWithSwitch />}
        placeholder="Type narrative"
        transcribing
        onChange={handleQuillChange}
        options={newOptions}
        disabled={clinicalNote?.locked || isFetchingEnhanceNarrative}
        className={cs('min-h-[200px]', textAreaClassName)}
        info={isPermission && 'AI can make mistakes, please confirm the information!'}
        infoHighlights={['AI', 'mistakes']}
      />

      {confirmationModal?.show && (
        <Confirm
          primaryBtnTxt="Continue"
          title="Sync Narrative"
          variant="danger"
          icon="new-warning-red"
          message={
            <div>
              <p>
                Enabling Sync Narrative will automatically generate the narrative as you interact
                with the form,{' '}
                <i className="text-danger-600">
                  causing any manually entered text in the narrative box to be lost.
                </i>
              </p>
              <div className="!mt-3">
                <Checkbox
                  id="confirm-narrative-checkbox"
                  label={<p className="text-sm">Do not show this message again</p>}
                  color="blue"
                  isChecked={confirmationModal?.doNotShowAgainCheckbox}
                  onChange={(event) =>
                    setConfirmationModal((prevState) => ({
                      ...prevState,
                      doNotShowAgainCheckbox: event.target.checked
                    }))
                  }
                />
              </div>
            </div>
          }
          handleContinue={handleContinue}
          handleOpen={confirmationModal?.show}
          handleClose={() => setConfirmationModal((prevState) => ({ ...prevState, show: false }))}
        />
      )}
    </div>
  );
};

export default NarrativeForm;
