import { formatDate } from 'lib/helpers/utility';
import { capitalize } from 'lodash';

export default {
  date: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'Date of Payment',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  providers: {
    type: 'search',
    options: [],
    values: [],
    title: 'Rendering providers',
    preview: (values) => values.map((v) => v.label).join(', '),
    multiple: true
  },
  superbill_id: {
    type: 'search',
    values: '',
    title: 'Superbill ID',
    placeholder: 'Search by superbill ID'
  },
  billing_provider_searchTerm: {
    type: 'search',
    values: '',
    title: 'Billing Provider',
    placeholder: 'Search by billing provider'
  },
  payer_searchTerm: {
    type: 'search',
    values: '',
    title: 'Payer',
    placeholder: 'Search by payer name'
  },
  status: {
    type: 'checkbox',
    values: null,
    options: ['complete', 'draft'],
    title: 'Status',
    preview: (values) => values.map((v) => capitalize(v)).join(', ')
  }
};
