import React from 'react';
import moment from 'moment-timezone';
import { Popover } from '@headlessui/react';
import { Calendar } from 'react-date-range';
import { formatDate } from '/lib/helpers/utility';
import Input from 'components/shared/Input/Input';
import Switch from 'components/shared/Switch/Switch';
import Button from 'components/shared/Buttons/Button';

const NewReferringProviderForm = ({
  formik = null,
  mode,
  formatRP,
  selectedProvider,
  setSelectedProvider,
  stateList
}) => {
  const { npi, f_name, m_name, l_name, cond_date, address } = mode?.edit
    ? selectedProvider || {}
    : formatRP(selectedProvider) || {};

  return (
    <div className="grid gap-y-4">
      <Input
        label="National Provider Identifier"
        type="number"
        placeholder="NPI"
        name="number"
        data-qa="npi"
        value={formik?.values?.number || npi}
        onChange={formik && formik?.handleChange}
        id="number"
        disabled={mode?.read}
        error={formik?.errors.number}
      />

      <Input
        label="First Name"
        placeholder="First Name"
        name="first_name"
        data-qa="first-name-rp"
        value={formik?.values?.first_name || f_name}
        onChange={formik && formik?.handleChange}
        id="first_name"
        disabled={mode?.read}
        error={formik?.errors.first_name}
      />

      {mode?.read && (
        <Input
          label="Middle Name"
          placeholder="Middle Name"
          name="middle_name"
          data-qa="middle-name-rp"
          value={formik?.values?.middle_name || m_name}
          onChange={formik && formik?.handleChange}
          id="middle_name"
          disabled={mode?.read}
          error={formik?.errors.middle_name}
        />
      )}

      <Input
        label="Last Name"
        type="text"
        placeholder="Last Name"
        name="last_name"
        data-qa="last-name-rp"
        value={formik?.values?.last_name || l_name}
        onChange={formik && formik?.handleChange}
        id="last_name"
        disabled={mode?.read}
        error={formik?.errors.last_name}
      />

      {mode?.read && (
        <>
          <Input
            label="Address"
            id="address.address_1"
            name="address.address_1"
            placeholder="Address"
            disabled={mode?.read}
            value={address?.address_1}
          />

          <Input
            label="Address 2"
            name="address.address_2"
            id="address.address_2"
            placeholder="Address 2"
            disabled={mode?.read}
            value={address?.address_2}
          />

          <Input
            label="City"
            id="address.city"
            name="address.city"
            placeholder="City"
            disabled={mode?.read}
            value={address?.city}
          />

          <Input
            label="State"
            id="address.state"
            name="address.state"
            placeholder="State"
            disabled={mode?.read}
            value={stateList.find((l) => l?.abbreviation === address?.state)?.name}
          />

          <Input
            label="Zip code"
            id="address.zip"
            name="address.zip"
            placeholder="Zip code"
            disabled={mode?.read}
            value={address?.postal_code}
          />
        </>
      )}

      {mode?.read && (
        <>
          <Popover className="relative -mt-[3px]">
            {({ close }) => (
              <>
                <Popover.Button className="w-full p-0">
                  <Input
                    label="Last date of service"
                    rightIcon="new-calendar-gray"
                    placeholder={formatDate(moment.utc(cond_date).add(12, 'h'))}
                  />
                </Popover.Button>

                <Popover.Panel className="absolute z-10 flex shadow-md">
                  <Calendar
                    date={cond_date ? new Date(cond_date) : null}
                    onChange={(date) => {
                      setSelectedProvider({
                        ...selectedProvider,
                        cond_date: moment.utc(date).add(12, 'h')
                      });
                      close();
                    }}
                  />
                </Popover.Panel>
              </>
            )}
          </Popover>
          <Switch
            label="Autofill CMS1500"
            checked={selectedProvider?.autofillCMS1500}
            onChange={() =>
              setSelectedProvider({
                ...selectedProvider,
                autofillCMS1500: !selectedProvider?.autofillCMS1500
              })
            }
          />
        </>
      )}

      {!mode?.read && !mode?.edit && (
        <Button
          onClick={() => formik?.submitForm()}
          text="Search"
          data-qa="search-btn"
          className="mt-4 w-max px-5"
        />
      )}
    </div>
  );
};

export default NewReferringProviderForm;
