import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useFormik } from 'formik';

import { optionify } from 'lib/helpers/utility';
import { useCurrentPractice } from 'lib/hooks/queries/practice/useCurrentPractice';
import { updatePractice } from 'lib/hooks/queries/practice/usePractice';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';
import useLoading from 'lib/hooks/useLoading';

import { getCurrentOption } from 'components/Staff/lib/helpers';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';
import Select from 'components/shared/Select/Select';

const ManageSpecialtyModal = ({ isOpen, onClose }) => {
  const queryClient = useQueryClient();
  const { data } = useCurrentPractice();
  const navigate = useNavigate();

  const { loading, startLoading, stopLoading } = useLoading();

  const practice = data?.practice || {};

  const formik = useFormik({
    initialValues: {
      profession_group_id: practice?.profession_group_id || null
    }
  });

  const updatePracticeMutation = useCustomMutation({
    mutationFn: (updatedPractice) =>
      updatePractice(navigate, {
        practice_id: practice.id,
        changes: updatedPractice
      }),
    successMessage: 'Practice updated successfully',
    errorMessage: 'Failed to update practice',
    onSuccess: () => {
      queryClient.invalidateQueries(['currentPractice']);
      queryClient.invalidateQueries(['practiceCptCodes']);
      onClose();
    }
  });

  const { data: professionGroupOptions } = useProfessionGroups();

  const professions = optionify(professionGroupOptions?.professions, 'name', 'id');

  const handleOnSavePractice = async (e) => {
    e.preventDefault();
    startLoading();
    await updatePracticeMutation.mutateAsync(formik.values);
    stopLoading();
  };

  return (
    <Modal
      handleOpen={isOpen}
      handleClose={onClose}
      className="w-[700px] h-[300px]"
      title="Manage Specialty"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button onClick={onClose} text="Close" color="neutral" outlined />
          <Button
            onClick={handleOnSavePractice}
            data-qa="save-btn"
            text={loading ? 'Saving...' : 'Save'}
            className="ml-auto"
            loadingIcon={loading}
          />
        </div>
      }>
      <Select
        parentClassName="col-span-3"
        label="Specialty"
        inputId="profession_group_id"
        options={professions}
        placeholder="Specialties"
        onChange={(codes) => {
          formik.setFieldValue('profession_group_id', codes?.value || null);
        }}
        value={getCurrentOption(professions, Number(formik?.values?.profession_group_id))}
        isMulti={false}
        menuPortalTarget={document.body}
      />
    </Modal>
  );
};

export default ManageSpecialtyModal;
