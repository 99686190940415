import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

import { useRecoilState, useSetRecoilState } from 'recoil';

import { requestApi } from 'api/Api';

import { ia } from 'lib/helpers/utility';
import { usePatientPractices } from 'lib/hooks/queries/patients/usePatientPractices';
import usePageTitle from 'lib/hooks/usePageTitle';

import { ownerPractices, ownerSelectedLocation } from 'components/practice/practiceState';
import Avatar from 'components/shared/Avatar/Avatar';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';
import Popover from 'components/shared/Popovers/Popover/Popover';
import state from 'components/state';

import { patientNavbarOptions } from './lib/constants';

import './PatientNavbar.scss';

const PatientNavbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useRecoilState(state.userState);
  const setOwnerPractices = useSetRecoilState(ownerPractices);
  const setOwnerSelectedLocation = useSetRecoilState(ownerSelectedLocation);

  const { myPortalPractice } = useParams();

  const { data } = usePatientPractices({});
  const practice =
    myPortalPractice &&
    data?.practices?.find(({ practice }) => practice?.id == myPortalPractice)?.practice;

  const logout = async () => {
    try {
      await requestApi({ url: '/api/user/logout', navigate, params: {} });
      sessionStorage.removeItem('myriad-session-reference-id');
      setUser({
        user_id: '',
        f_name: '',
        l_name: '',
        p_name: '',
        email: '',
        state: '',
        kind: '',
        logged_in: false,
        profile_photo: null
      });
      setOwnerPractices([]);
      setOwnerSelectedLocation(null);
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  };

  const name =
    user.p_name || (user.f_name && user.l_name ? user.f_name + ' ' + user.l_name : 'Welcome');

  const currentPage = patientNavbarOptions.find(
    (option) => pathname.includes(option.path) && option.path !== '/myportal'
  );
  const pageTitle = currentPage?.label || 'Dashboard';

  usePageTitle(pageTitle);

  const handleClick = (path) => {
    if (path) {
      navigate(path);
    } else {
      logout();
    }
  };

  const options = patientNavbarOptions.map((option) => ({
    ...option,
    onClick: () => handleClick(option.path)
  }));

  return (
    <nav className="gap-4 p-4 xs:grid sm:grid xs:grid-cols-2 sm:grid-cols-2  flex items-center justify-between z-20 bg-neutral-100">
      <Link to="/myportal">
        <Icon icon="logo" size={120} />
      </Link>
      {practice && ia(data?.practices, 1) ? (
        <div className="flex items-center justify-center gap-2 xs:order-last sm:order-last  xs:col-span-2 sm:col-span-2  ">
          <Button
            iconIsStroke
            icon="new-chevron-left"
            color="neutral"
            outlined
            theme="neutral"
            onClick={() => {
              if (window.history.length > 1) {
                navigate(-1);
              } else {
                navigate('../');
              }
            }}
          />
          <div className="text-xl font-500 text-primary-900 truncate w-full">{`${pageTitle} - ${practice.name}`}</div>
        </div>
      ) : (
        <div className="text-xl justify-center items-center font-500 text-primary-900 flex gap-2 xs:order-last sm:order-last  xs:col-span-2 sm:col-span-2  ">
          {pageTitle}
        </div>
      )}
      {user.email ? (
        <div className="flex justify-end">
          <Popover
            isDropdown
            options={options}
            icon={
              <Avatar
                firstName={name}
                id={user?.user_id}
                image={user?.profile_photo}
                size="large"
              />
            }
            text={name}
            buttonClassName="!p-2 !min-w-[180px]"
            position="left"
            buttonTheme="neutral"
            panelClassName="min-w-[180px]"
          />
        </div>
      ) : (
        <Button text="Login" onClick={() => navigate('/login')} />
      )}
    </nav>
  );
};

export default PatientNavbar;
