import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Input from 'components/shared/Input/Input';
import Pagination from 'components/shared/Pagination/Pagination';
import { useOrganizationContext } from 'lib/context/OrganizationContext/OrganizationContext';
import { useLocationImportData } from 'lib/hooks/queries/admin/useLocationImportData';
import { capitalize } from 'lodash';
import React, { useState } from 'react';

const TableSelection = ({ table }) => {
  const { state, dispatch, selectedLocation } = useOrganizationContext();
  const { data = [], excludedData = [], selectAll = false } = state[table];

  const [page, setPage] = useState({
    customForms: 1,
    patients: 1,
    services: 1,
    packages: 1,
    products: 1
  });
  const [search, setSearch] = useState({
    customForms: '',
    patients: '',
    services: '',
    packages: '',
    products: ''
  });

  const params = {
    table,
    search: search[table],
    practice_id: selectedLocation.value,
    page: page[table]
  };
  const { data: resData } = useLocationImportData({
    params,
    dependencies: [search[table], table, page[table], selectedLocation],
    options: { staleTime: 12000 }
  });
  const tableData = resData?.tableData || [];
  const handleSelectAll = () => {
    dispatch({ type: 'SELECT_ALL', payload: { table, data: tableData.map((it) => it.id) } });
  };

  const handleReset = () => {
    dispatch({ type: 'RESET_DATA', payload: { table } });
  };

  const handleSelectItem = (item) => {
    const id = item.id;
    if (selectAll) {
      const updatedData = excludedData?.includes(id)
        ? excludedData.filter((i) => i !== id)
        : [...excludedData, id];
      dispatch({ type: 'EXCLUDE_DATA', payload: { table, data: updatedData } });
    } else {
      const updatedData = data?.includes(id) ? data.filter((i) => i !== id) : [...data, id];
      dispatch({ type: 'SET_DATA', payload: { table, data: updatedData } });
    }
  };

  const handleChangeSearch = (e) => {
    setSearch({ ...search, [table]: e.target.value });
  };

  const changePage = ({ selected }) => {
    setPage({ ...page, [table]: selected + 1 });
  };

  return (
    <div className="h-full ">
      <div className="!mt-4 flex items-center justify-between  gap-2">
        <div className=" flex items-center  gap-2">
          <Button text="Select All" onClick={handleSelectAll} outlined />
          <Button text="Reset" onClick={handleReset} outlined color="secondary" />
        </div>
        <Input placeholder="Search" onChange={handleChangeSearch} value={search[table]} />
      </div>
      <h4 className="!mb-2 !mt-4 font-500 text-primary-800">List of {capitalize(table)} items</h4>
      <ul className="flex flex-col gap-2">
        {tableData.map((item) => {
          const isChecked =
            data?.includes(item.id) || (selectAll && !excludedData?.includes(item.id));
          return (
            <div
              key={item.id}
              className={`flex cursor-pointer items-center gap-3 rounded-md p-1 ${
                isChecked ? 'bg-primary-50' : ''
              }`}
              onClick={() => handleSelectItem(item)}>
              <Checkbox
                radio
                isChecked={isChecked}
                onChange={() => handleSelectItem(item)}
                id={item.id}
              />
              <h3 className="text-base font-500 text-neutral-900">{item?.name}</h3>
            </div>
          );
        })}
      </ul>

      <Pagination
        onPageChange={changePage}
        page={page[table]}
        totalItems={resData?.count}
        perPage={10}
      />
    </div>
  );
};

export default TableSelection;
