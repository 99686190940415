import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getPracticeIQueueOverview } from '../../../../api/Widgets';

export const usePracticeIQueueOverview = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['practice-i-queue-overview', ...dependencies],
    () => getPracticeIQueueOverview(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
