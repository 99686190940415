import React, { useMemo } from 'react';
import NotFound from '../../../components/NotFound';
import AGChart from 'components/shared/AGChart/AGChart';
import { io, mString } from 'lib/helpers/utility';
import { format } from 'date-fns';
import useChartSize from 'lib/hooks/useChartSize';

const Financials = (props) => {
  const { dashboardData } = props;

  const formattedData = dashboardData?.financialGraphData
    ? Object.entries(dashboardData?.financialGraphData).map(([timestamp, data]) => {
        return {
          timestamp: new Date(parseInt(timestamp, 10) * 1000),
          patientPayments: parseFloat(data.patientPayments),
          insurancePayments: parseFloat(data.insurancePayments),
          accountReceivable: parseFloat(data.accountReceivable)
        };
      })
    : [];

  const container = document.getElementsByClassName('react-grid-item')[0];

  const { height, width, proportion } = useChartSize(container);

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    legend: {
      enabled: true,
      position: 'top',
      spacing: 24
    },
    theme: {
      overrides: {
        line: {
          series: {
            lineDash: [12, 3],
            marker: {
              enabled: false
            },
            tooltip: {
              renderer: ({ datum, xKey, yKey }) => {
                const date = Intl.DateTimeFormat('en-US', {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                }).format(datum[xKey]);
                return { content: `${date}: ${mString(datum[yKey].toFixed(0), false)}` };
              }
            }
          }
        }
      }
    },
    series: [
      {
        type: 'line',
        xKey: 'timestamp',
        yKey: 'patientPayments',
        yName: 'Patients Payments',
        marker: { size: 6, fill: '#22C55E', stroke: '#22C55E' }
      },
      {
        type: 'line',
        xKey: 'timestamp',
        yKey: 'insurancePayments',
        yName: 'Insurance Payments',
        stroke: '#13B9FF',
        marker: { size: 6, fill: '#13B9FF', stroke: '#13B9FF' }
      },
      {
        type: 'line',
        xKey: 'timestamp',
        yKey: 'accountReceivable',
        yName: 'Account Receivable',
        stroke: '#8191E8',
        marker: { size: 6, fill: '#8191E8', stroke: '#8191E8' }
      }
    ],
    axes: [
      {
        type: 'time',
        position: 'bottom',
        nice: true,
        tick: {
          formatter: (timestamp) => {
            const updatedTimestamp = timestamp * 1000;
            const formattedDate = format(new Date(updatedTimestamp), 'MMM dd');
            return formattedDate;
          }
        }
      },
      {
        position: 'left',
        type: 'number',
        label: {
          formatter(params) {
            const isWholeNumber = params.value % 1 === 0;
            return `$${parseFloat(params.value).toLocaleString('en-US', {
              minimumFractionDigits: isWholeNumber ? 0 : 2,
              maximumFractionDigits: isWholeNumber ? 0 : 2
            })}`;
          }
        }
      }
    ]
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  if (!io(dashboardData?.financialGraphData)) {
    return (
      <NotFound
        icon="new-illustration-2"
        title="No financial reports yet!"
        description="You can start scheduling new appointments right now."
      />
    );
  }

  return (
    <div>
      <div className="grid grid-cols-[repeat(auto-fit,minmax(116px,auto))] justify-evenly gap-4">
        <div className="grid">
          <span className="text-xl font-600 text-primary-900">
            {mString(dashboardData?.count?.financial.patientPayments, false)}
          </span>
          <span className="text-sm font-500 text-primary-900">Patient Payments</span>
        </div>
        <div className="grid">
          <span className="text-xl font-600 text-primary-900">
            {mString(dashboardData?.count?.financial.insurancePayments, false)}
          </span>
          <span className="text-sm font-500 text-primary-900">Insurance Payments</span>
        </div>
        <div className="grid">
          <span className="text-xl font-600 text-primary-900">
            {mString(dashboardData?.count?.financial.accountReceivable, false)}
          </span>
          <span className="text-sm font-500 text-primary-900">Account Receivable</span>
        </div>
      </div>
      <AGChart options={options} key={chartKey} />
    </div>
  );
};

export default Financials;
