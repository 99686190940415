import * as Yup from 'yup';

export const UpdateAppointmentValidationSchema = Yup.object().shape({
    practitioner_id: Yup.string().required('Practitioner required'),
    procedures: Yup.array()
        .of(
            Yup.object().shape({
                total_cost_cents: Yup.number().min(0, 'Price >= 0')
            })
        )
        .min(1, 'At least one procedures is required')
});
