import { showAlert } from 'components/shared/Alert/Alert';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { userState } from '../../../../state';
import '../Custom.scss';
import { scrollIntoNarrativeView } from '../lib/customFormsHelper';
import { doEmptyFormExist } from '../lib/emptyFormsExist';
import { formatSections } from '../lib/generalFormatFormHelperV1';
import _ from 'lodash';

const useCustomForm = ({
  setItemToDelete,
  setItems,
  items,
  rows,
  title,
  alertTitle,
  initialValue,
  defaultValue,
  textAreaRef,
  onChange,
  prevItems,
  setPrevItems,
  setCurrentFormData,
  setShowFormModal,
  narrativeName = rows,
  fromClinicalNote = false,
  formatNarrative = null
}) => {
  const initialObject = () => ({
    ...initialValue?.[rows][0],
    id: self.crypto.randomUUID()
  });

  const checkIfEmpty = (updatedItems) => {
    if (updatedItems) return false;
    return doEmptyFormExist({
      newForm: initialObject(),
      forms: [items?.[rows]?.[items?.[rows]?.length - 1]]
    });
  };

  const { advancedFormNarrativeRef } = useClinicalNoteContext() || {};

  const user = useRecoilValue(userState);

  const [syncNarrative, setSyncNarrative] = useState(true);

  const length = useMemo(() => {
    return items?.[rows]?.length;
  }, [items, rows]);

  useEffect(() => {
    if (defaultValue) {
      if (typeof defaultValue === 'string') {
        setItems(JSON.parse(defaultValue));
      } else {
        setItems(
          // fromClinicalNote
          //   ? {
          //       ...defaultValue,
          //       [rows]: defaultValue[rows].filter(
          //         (row) =>
          //           !doEmptyFormExist({
          //             newForm: initialObject(),
          //             forms: [row]
          //           })
          //       )
          //     }
          //   : defaultValue
          defaultValue
        );
      }
    } else {
      setItems(fromClinicalNote ? { ...initialValue, [rows]: null } : initialValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    notifyChange(items);
  }, [items]);

  useEffect(() => {
    if (advancedFormNarrativeRef?.current) {
      console.log(advancedFormNarrativeRef);
      advancedFormNarrativeRef.current = setItems;
    }
  }, []);

  const syncItems = (updatedObject) => {
    if (syncNarrative) {
      generateNarrative({ itemsObject: updatedObject });
    } else {
      setItems(updatedObject);
      setCurrentFormData && setCurrentFormData(updatedObject);
    }
  };

  const notifyChange = (updatedValue) => {
    if (onChange) {
      onChange({ target: { value: updatedValue, name: title, type: 'custom' } });
    }
  };

  const handleAddRow = (event) => {
    event && event.preventDefault();

    const newItem = initialObject();

    const shouldStopProcess = doEmptyFormExist({
      newForm: newItem,
      forms: items?.[rows]
    });

    if (shouldStopProcess) {
      showAlert({
        title: alertTitle,
        message: 'Empty item exists. Please fill in the details.',
        color: 'warning'
      });
      return;
    }

    console.log(rows, items, newItem);

    const updatedObject = {
      noDetails: false,
      [rows]: [...(items[rows] || []), newItem]
    };

    syncItems(updatedObject);

    return newItem?.id;
  };

  const getItemById = (id) => {
    return items?.[rows]?.find((row) => row.id === id);
  };

  const handleOnChange = (key, event, id) => {
    const updatedArray = items?.[rows]?.map((row) => {
      if (row.id === id) {
        return { ...row, [key]: event };
      } else {
        return row;
      }
    });
    const updatedObject = { ...items, [rows]: updatedArray };
    syncItems(updatedObject);
  };

  const handleNoDetails = (isTrue) => {
    const updatedObject = { ...items, noDetails: isTrue };
    syncItems(updatedObject);
  };

  const handleNarrative = ({ itemsObject = items, event, scrollable = false, sync = true }) => {
    setSyncNarrative(sync);

    const updatedObject = { ...itemsObject, narrative: event };

    setItems(updatedObject);
    setCurrentFormData && setCurrentFormData(updatedObject);

    scrollable && scrollIntoNarrativeView({ ref: textAreaRef });
  };

  const generateNarrative = ({ itemsObject = items, scrollable = false } = {}) => {
    if (formatNarrative) {
      const formattedNarrative = formatNarrative(itemsObject);
      handleNarrative({
        itemsObject,
        event: formattedNarrative,
        scrollable
      });
      return;
    }

    const narrativeOptions = {
      data: itemsObject,
      sectionData: narrativeName,
      sectionName: alertTitle,
      isProvider: user?.kind === 'practitioner'
    };

    const formattedNarrative = formatSections(narrativeOptions)?.trim();

    handleNarrative({
      itemsObject,
      event: formattedNarrative,
      scrollable
    });
  };

  const handleTemp = () => {
    setItems((i) => {
      if (_.isEqual(i, prevItems)) {
        return i;
      } else {
        return { ...prevItems };
      }
    });
  };

  const onDelete = (id, fromClinical) => {
    if (
      items?.[rows]?.length > 1 ||
      !doEmptyFormExist({ newForm: initialObject(), forms: items?.[rows] }) ||
      fromClinical
    ) {
      setItemToDelete(id);
    }
  };

  const openModal = (id, type, data = null) => {
    setItems((p) => {
      setPrevItems(p);
      return p;
    });
    setShowFormModal({ open: true, id: id === 'create' ? handleAddRow() : id, type, data });
  };

  return {
    handleAddRow,
    handleOnChange,
    handleNoDetails,
    onDelete,
    syncItems,
    getItemById,
    handleTemp,
    length,
    openModal,
    checkIfEmpty,
    syncNarrative,
    handleNarrative,
    setSyncNarrative
  };
};

export default useCustomForm;
