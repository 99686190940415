import React from 'react';

const fields = ['fullName', 'phone', 'email', 'address'];

const renderFields = ({ fieldValues }) => {
  return fields.map((field) => {
    if (!fieldValues[field]) return null;

    return (
      <p className="font-400 text-neutral-800 text-sm first-of-type:!mt-2.5 mt-1.5">
        {fieldValues[field]}
      </p>
    );
  });
};

const ContactPreview = ({ providerInfo }) => {
  const { f_name = '', l_name = '' } = providerInfo;

  return (
    <div className="flex flex-col w-full">
      <div className="text-neutral-800 text-base font-500">Provider</div>
      <div className="flex flex-col">
        {renderFields({ fieldValues: { fullName: `${f_name} ${l_name}`, ...providerInfo } })}
      </div>
    </div>
  );
};

export default ContactPreview;
