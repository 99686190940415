import { iaRa } from 'lib/helpers/utility';
import { usePatientMessages } from 'lib/hooks/queries/patients/usePatientMessages';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import patientState from '../patientState';
import ConversationBox from './ConversationBox';

export default function Inbox() {
  const [practice_id, setPracticeId] = useRecoilState(patientState.conversation_practice_id);
  const navigate = useNavigate();

  const { data } = usePatientMessages({
    dependencies: [practice_id]
  });
  const messages = data?.messages;

  const handleClick = (practice_id) => {
    setPracticeId(practice_id);
    navigate(`/myportal/inbox/${practice_id}`);
  };

  return (
    <div className="grid grid-cols-3 gap-4 p-12 sm:grid-cols-2 xs:grid-cols-1">
      {iaRa(messages).map((message, index) => {
        return (
          <ConversationBox
            key={index}
            message={message}
            onclick={() => handleClick(message.practice_id)}
          />
        );
      })}
    </div>
  );
}
