import React from 'react';
import { camelCaseToReadableFormat, io, isEmpty } from 'lib/helpers/utility';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const HPIRevisions = ({ checkedFormTypes = {}, customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const hpi = clinicalNote?.hpi || {};
  const { isNarrativeChecked, isTemplateChecked } = checkedFormTypes;

  if (customClinicalNote && !io(clinicalNote?.hpi)) {
    return null;
  }

  const trueValues = Object.entries(hpi)?.reduce((acc, [key, value]) => {
    if (
      key !== 'id' &&
      key !== 'type' &&
      key !== 'modifyingFactors' &&
      key !== 'narrative' &&
      value
    ) {
      acc[key] = value;
    }
    return acc;
  }, {});

  const hasModifyingFactors = Object.values(hpi?.modifyingFactors || {}).some((value) => value);

  return (
    <div className="grid gap-2 bg-white !px-6 !py-4">
      <h3 className="text-base font-500 text-[#003A4B]">History of present illness</h3>
      {isTemplateChecked && (
        <div className="grid grid-cols-2 !gap-5 border-0 !border-t-[1px] !border-solid !border-neutral-200 !pt-2 first-of-type:!border-t-0">
          {Object.entries(trueValues).map(([key, value], index) => (
            <div key={index} className="grid">
              <p className="text-xs text-neutral-700 first-letter:capitalize">
                {camelCaseToReadableFormat(key)}
              </p>
              <p className="text-xs font-500 text-[#003A4B] first-letter:capitalize">
                {typeof value === 'object' ? value.value : value}
              </p>
            </div>
          ))}
          {hasModifyingFactors && (
            <div className="col-start-1 col-end-3 grid auto-rows-min">
              <p className="text-xs text-neutral-700 first-letter:capitalize">Modifying Factors</p>
              <ul className="columns-3">
                {Object.entries(hpi?.modifyingFactors).map(([subKey, subValue], subIdx) => {
                  if (subKey === 'noModifyingFactors' || subValue === '') return null;
                  return (
                    <li
                      key={subIdx}
                      className="float-left !mr-4 list-inside list-disc !pt-2 text-xs font-500 text-[#003A4B] first-letter:capitalize first-of-type:ml-0">
                      {camelCaseToReadableFormat(subKey)}
                      <ul>
                        <li className="pl-[17px] text-xs font-500 text-neutral-700 first-letter:capitalize">
                          {subValue}
                        </li>
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}

      {isNarrativeChecked && !isEmpty(hpi?.narrative) && (
        <SanitizeComponent value={hpi?.narrative} />
      )}
    </div>
  );
};

export default HPIRevisions;
