import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { useFormikContext } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Superbill = ({ data = {} }) => {
  const navigate = useNavigate();
  const { values } = useFormikContext();

  const handleNavigateToSuperbill = () => {
    navigate(`/portal/charts/${values?.user_id}/billing/superbill/${data.superbillId}`, {
      replace: false
    });
  };

  const handleNavigateToClaim = () => {
    navigate(`/portal/beyond-billing/claims/ready/${data?.claimId}`, {
      state: { claimID: data?.claimId }
    });
  };

  return (
    <Allowed requiredPermissions="superbill.read">
      <div>
        {data.superbillId ? (
          <div className="flex flex-col gap-1">
            <div className="flex w-full items-center justify-between ">
              <p className="text-sm font-500 text-neutral-800 ">{`Superbill #${data.superbillId}`}</p>
              <div
                className="flex cursor-pointer items-center gap-2"
                onClick={handleNavigateToSuperbill}>
                <p className="text-sm  text-primary-600">View</p>
                <Icon icon="new-document-preview" color="primary" />
              </div>
            </div>
            {data.claimId && (
              <div className="flex w-full items-center justify-between ">
                <p className="text-sm font-500 text-neutral-800 ">{`Claim #${data.claimId}`}</p>
                <div
                  className="flex cursor-pointer items-center gap-2"
                  onClick={handleNavigateToClaim}>
                  <p className="text-sm  text-primary-600">View</p>
                  <Icon icon="new-document-preview" color="primary" />
                </div>
              </div>
            )}
          </div>
        ) : (
          <p className="text-sm  text-neutral-800">
            Superbill will be created when the clinical note starts.
          </p>
        )}
      </div>
    </Allowed>
  );
};

export default Superbill;
