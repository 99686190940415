import React from 'react';
import { useParams } from 'react-router-dom';

import Documents from 'components/practice/charts/Documents/Container';

const Attachments = () => {
  const { appointmentId } = useParams();
  return <Documents fromPatient={true} appointmentId={appointmentId} />;
};

export default Attachments;
