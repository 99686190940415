import React, { useRef } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useReactToPrint } from 'react-to-print';

import { useRecoilValue } from 'recoil';

import { currentPractice } from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/Popover';

import SelectInstantAppointment from '../InstantPacket/components/SelectInstantAppointment';

const DocumentActionsPopover = ({
  setViewDocument,
  isEditOpen,
  setIsEditOpen,
  patientId,
  cat,
  mimetype,
  form,
  data,
  document,
  handleDownload,
  setSelectedAppointment,
  handleConfirmationFile
}) => {
  const exportRef = useRef([]);

  const practice = useRecoilValue(currentPractice);

  const print = useReactToPrint({
    content: () => exportRef.current,
    documentTitle: `${data?.name}-${data.created_at}.pdf`
  });

  const options = [
    {
      label: 'View',
      onClick: () => {
        setViewDocument({
          load: true,
          title: data?.name,
          handle: data?.document_handle || form,
          type: mimetype || 'form'
        });
      },
      icon: 'new-eye'
    },
    {
      label: 'Edit',
      onClick: () => {
        data?.form_id && setIsEditOpen(true);

        setViewDocument({
          load: true,
          title: data?.name,
          handle: data?.document_handle || form,
          type: mimetype || 'form',
          action: 'Edit',
          category: cat
        });
      },
      icon: 'new-edit'
    },
    {
      label: 'Delete',
      onClick: () => {
        handleConfirmationFile({
          mode: 'delete',
          id: data?.document_handle || document?.id,
          type: data?.form_id ? 'form' : 'filestack'
        });
      },
      icon: 'trash'
    },

    data?.form_id && !isEditOpen
      ? {
          label: 'Download',
          onClick: () => {
            print();
          },
          icon: 'new-chevron-down'
        }
      : {
          label: 'Download',
          onClick: () => {
            handleDownload({
              title: data?.name,
              handle: data?.document_handle || form,
              type: mimetype || 'form'
            });
          },
          icon: 'new-chevron-down'
        },

    data?.form_id
      ? data?.appointment_id
        ? {
            label: 'Linked DOS',
            icon: 'new-link',
            color: 'primary',
            stroke: true,
            children: [
              {
                component: (
                  <SelectInstantAppointment
                    selectedAppointment={document?.appointment_id}
                    setSelectedAppointment={async (appointment) => {
                      await setSelectedAppointment(appointment, document);
                    }}
                    patientId={patientId}
                    practice={practice}
                    update
                    buttonClassName="hover:bg-primary-50 w-36"
                    popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-30%]"
                  />
                )
              },
              {
                label: 'Unlink DOS',
                icon: 'new-close-v2',
                color: 'primary',
                stroke: true,
                onClick: async () => {
                  await setSelectedAppointment(null, document);
                }
              }
            ]
          }
        : {
            component: (
              <SelectInstantAppointment
                selectedAppointment={data?.appointment_id}
                setSelectedAppointment={async (appointment) => {
                  await setSelectedAppointment(appointment, document);
                }}
                patientId={patientId}
                practice={practice}
                popoverClassName="left-0 top-0 translate-x-[-101%] translate-y-[-90%]"
              />
            )
          }
      : null,
    {
      label: 'Share with patient',
      icon: 'new-arrow-swap-down',
      onClick: () => {
        handleConfirmationFile({
          mode: 'share',
          id: data?.id,
          type: data?.form_id ? 'form' : 'document'
        });
      }
    }
  ].filter(Boolean);

  return (
    <>
      <Popover
        className="mt-2 flex flex-1 justify-center self-center"
        position="left"
        isFixed
        options={options}
      />
      {data?.form_id && !isEditOpen && (
        <div className="PrintForm print:!bg-white" ref={exportRef} style={{ display: 'none' }}>
          <ReactFormGenerator
            answer_data={form?.json?.fields ? JSON.parse(form?.json?.fields) : {}}
            data={form?.form_json?.fields}
            submitButton={<div />}
            read_only={true}
          />
        </div>
      )}
    </>
  );
};

export default DocumentActionsPopover;
