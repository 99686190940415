import React from 'react';
import Modal from '../../../../shared/Modal/Modal';

const NewAppointmentModal = ({ isOpen, onAfterOpen, footer, handleClose, title, children }) => {
  return (
    <Modal
      slideFromRight
      bodyClassName="!p-4"
      id="NewAppointmentModal"
      className="w-[620px]"
      title={`Create new  ${title}`}
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      handleClose={handleClose}
      footer={footer}>
      {children}
    </Modal>
  );
};

export default NewAppointmentModal;
