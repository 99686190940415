import React, { useEffect, useState } from 'react';
import Select from 'components/shared/Select/Select';
import Tabs from 'components/shared/Tabs/NewTabs';
import NewAddressExistingPractice from './address/NewAddress';
import SameAsPractice from './address/SameAddress';

const ExistingPractice = ({ practiceList, onChange, formik, stateList }) => {
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    formik.setFieldValue('newPractice', false, false);
  }, []);

  const tabsData = [
    {
      label: 'New address',
      content: (
        <NewAddressExistingPractice onChange={onChange} stateList={stateList} formik={formik} />
      )
    },
    {
      label: 'Same as practice',
      content: <SameAsPractice formik={formik} />
    }
  ];

  return (
    <div className="grid gap-4">
      <div className="grid grid-cols-4 gap-4 md:grid-cols-1">
        <Select
          isClearable={false}
          label="Practice"
          name="practice_list"
          options={practiceList}
          data-qa="practice-list"
          value={{
            label: practiceList.find((practice) => practice.value === formik.values?.practice_id)
              ?.label
          }}
          onChange={(e) => formik.setFieldValue('practice_id', e?.value || null)}
        />
      </div>
      <p className="text-xl font-600">Practitioner's Address</p>
      <Tabs
        tabsData={tabsData}
        theme="secondary"
        hasContent
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        className="!pl-0 !pr-0"
      />
    </div>
  );
};
export default ExistingPractice;
