import React, { useEffect, useState } from 'react';

import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import Select from 'components/shared/Select/Select';

import { tax_id_types } from '../../../../../constants';

export default function NewBillingProvider({
  formik,
  isLoading,
  open,
  type,
  handleClose,
  handleSubmit,
  states,
  loadedMembers,
  handleDeletion
}) {
  const currentState = states.find((state) => state.value == formik.values.bill_state);
  const currentBillTaxType = tax_id_types.find(
    (type) => type.value == formik.values.bill_taxid_type
  );
  const [selectedState, setSelectedState] = useState(currentState);
  const [practitioners, _] = useState(loadedMembers);
  const [selectedPractitioners, setSelectedPractitioners] = useState();
  useEffect(() => {
    if (practitioners.length == 1) {
      const newArr = [];
      newArr.push(practitioners[0].value);
      setSelectedPractitioners(practitioners[0]);
      formik.setFieldValue('practitioner_ids', newArr);
    }
    if (formik.values.practitioner_ids.length > 0) {
      const newArray = [];
      for (const f_practitioner of formik.values.practitioner_ids) {
        const foundPractitioner = practitioners.find((p) => p.value == f_practitioner);
        newArray.push(foundPractitioner);
      }
      setSelectedPractitioners(newArray);
    }
    if (formik.values.bill_taxid_type) {
      formik.setFieldValue('bill_taxid_type', currentBillTaxType);
    }
  }, []);
  const selectPractitioners = (e, v) => {
    setSelectedPractitioners(e);
    let newArr = [...formik.values.practitioner_ids];
    if (v.action === 'remove-value') {
      newArr = e.map((w) => w.value);
    } else if (v.action === 'select-option') {
      newArr.push(v.option.value);
    } else if (v.action === 'clear') {
      newArr = [];
    }
    formik.setFieldValue('practitioner_ids', newArr);
  };

  const customSelectStyle = {
    control: () => ({
      height: 40,
      display: 'flex',
      padding: '0px 6px',
      fontSize: 14,
      backgroundColor: '#f8f8f8',
      border: '1px solid #dcdcdc',
      borderRadius: '0.5rem'
    }),
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#afafaf'
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        fontWeight: 500
      };
    },
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
  };

  const handleSelectState = (state) => {
    setSelectedState(state);
    formik.setFieldValue('bill_state', state?.value);
  };

  const customModalTitle = (type) => {
    if (type == 'create') {
      return 'Create new billing provider';
    }
    if (type == 'update') {
      return 'Update billing provider';
    }
  };

  return (
    <Modal
      isLarge
      slideFromRight
      handleOpen={open}
      handleClose={handleClose}
      title={customModalTitle(type)}
      footer={
        <>
          <Button
            className="mr-auto"
            text="Cancel"
            outlined
            color="neutral"
            onClick={handleClose}
          />
          {formik.values.id && (
            <Allowed requiredPermissions="billing.delete">
              <Button
                text="Delete"
                color="danger"
                onClick={() => handleDeletion(formik.values.id)}
              />
            </Allowed>
          )}
          <Allowed requiredPermissions="billing.create">
            <Button
              text={customModalTitle(type)}
              primary
              onClick={handleSubmit}
              loading={isLoading}
            />
          </Allowed>
        </>
      }>
      <form>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Billing information</h3>

          <div className="groupColumnsFour">
            <Input
              id="name"
              type="text"
              label="Name"
              name="bill_name"
              placeholder="Name"
              onChange={formik.handleChange}
              value={formik.values.bill_name}
              error={formik.touched.bill_name && formik.errors.bill_name}
            />

            <Input
              id="npi"
              type="text"
              name="bill_npi"
              label="Billing NPI"
              placeholder="Billing NPI"
              value={formik.values.bill_npi}
              onChange={formik.handleChange}
              error={formik.touched.bill_npi && formik.errors.bill_npi}
            />

            <Input
              id="taxid"
              type="text"
              name="bill_taxid"
              label="Billing Tax ID"
              placeholder="Billing Tax ID"
              onChange={formik.handleChange}
              value={formik.values.bill_taxid}
              error={formik.touched.bill_taxid && formik.errors.bill_taxid}
            />

            <Select
              inputId="taxidType"
              options={tax_id_types}
              styles={customSelectStyle}
              label="Billing Tax ID Type"
              placeholder="Billing Tax ID Type"
              value={formik.values.bill_taxid_type}
              onChange={(value) => formik.setFieldValue('bill_taxid_type', value)}
              error={formik.touched.bill_taxid_type && formik.errors.bill_taxid_type}
            />
          </div>
        </div>

        <div className="groupColumns">
          <div className="groupColumnsFour">
            <Input
              type="text"
              id="taxonomy"
              name="bill_taxonomy"
              label="Billing Taxonomy"
              placeholder="Billing Taxonomy"
              onChange={formik.handleChange}
              value={formik.values.bill_taxonomy}
            />

            <div>
              <label className="label" htmlFor="id">
                Billing Atypical ID
              </label>
              <Input
                type="text"
                placeholder="Billing Atypical ID"
                name="bill_id"
                value={formik.values.bill_id}
                onChange={formik.handleChange}
                id="id"
              />
              {formik.errors.bill_id && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_id}
                </span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="phoneNumber">
                Phone number
              </label>
              <Input
                type="text"
                placeholder="+1(123)4567890"
                name="bill_phone"
                value={formik.values.bill_phone}
                onChange={formik.handleChange}
                id="phoneNumber"
              />
              {formik.errors.bill_phone && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_phone}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <h3 className="groupColumns__title">Billing Address information</h3>
          <div className="groupColumnsFour">
            <div className="addressFirst">
              <label className="label" htmlFor="addressFirst">
                Address
              </label>
              <Input
                type="text"
                placeholder="Address"
                name="bill_addr_1"
                value={formik.values.bill_addr_1}
                onChange={formik.handleChange}
                id="addressFirst"
              />
              {formik.errors.bill_addr_1 && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_addr_1}
                </span>
              )}
            </div>
            <div className="addressSecond">
              <label className="label" htmlFor="addressSecond">
                Address 2
              </label>
              <Input
                type="text"
                placeholder="Address 2"
                name="bill_addr_2"
                value={formik.values.bill_addr_2}
                onChange={formik.handleChange}
                id="addressSecond"
              />
              {formik.errors.bill_addr_2 && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_addr_2}
                </span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="city">
                City
              </label>
              <Input
                type="text"
                placeholder="City"
                name="bill_city"
                value={formik.values.bill_city}
                onChange={formik.handleChange}
                id="bill_city"
              />
              {formik.errors.bill_city && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_city}
                </span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="state">
                State
              </label>
              <Select
                placeholder="Type and select state"
                value={selectedState}
                onChange={(value) => handleSelectState(value)}
                options={states}
                styles={customSelectStyle}
                inputId="state"
              />
              {formik.errors.bill_state && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_state}
                </span>
              )}
            </div>
            <div>
              <label className="label" htmlFor="zipCode">
                Zip code
              </label>
              <Input
                type="text"
                placeholder="Zip"
                name="bill_zip"
                value={formik.values.bill_zip}
                onChange={formik.handleChange}
                id="zipCode"
              />
              {formik.errors.bill_zip && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.bill_zip}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="groupColumns">
          <div className="grid grid-cols-2">
            <div>
              <h3 className="groupColumns__title">Practitioners</h3>
              <label className="label" htmlFor="practitioner">
                Practitioners
              </label>
              <Select
                placeholder="Type and select practitioners"
                value={selectedPractitioners}
                onChange={(e, v) => selectPractitioners(e, v)}
                options={practitioners}
                styles={customSelectStyle}
                inputId="practitioner"
                menuPlacement="top"
                isMulti={true}
              />
              {formik.errors.practitioner_ids && (
                <span className="!pt-2 text-left text-sm text-danger-500">
                  {formik.errors.practitioner_ids}
                </span>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
