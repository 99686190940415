import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getMacros } from '../../../../api/Macro';

export const useMacros = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();

  return useQuery(['macros', ...dependencies], () => getMacros(navigate, params), {
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...options
  });
};