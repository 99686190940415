export default {
  medication: null,
  strength: '',
  unit: '',
  days_supply: null,
  quantity: null,
  refills: null,
  sig: '',
  condition: null,
  start_date: null,
  end_date: null,
  prescriber: '',
  note: '',
  is_past: false,
  id: self.crypto.randomUUID()
};
