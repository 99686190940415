import React from 'react';

import { useFormikContext } from 'formik';
import { useRecoilValue } from 'recoil';

import NotFound from 'components/practice/NewAppointment/components/NotFound';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';
import { permissions } from 'components/state';

import { emptyStateMessages } from '../lib/emptyStateMessages';

import { DEFAULT_COLUMN_DEFS, GRID_OPTIONS, getColDefs } from './lib/config';

const SelectedItems = ({ type, list, isClinicalNoteLocked = false }) => {
  const { initialValues, values, setFieldValue } = useFormikContext();
  const userPermissions = useRecoilValue(permissions);
  const hasPaymentPermission = userPermissions?.payments?.read;
  const hasBillingPermission = userPermissions?.billing?.read;

  const isServices = type === 'procedures';
  const selectedItems = [...(values[type] || [])];

  const originalProceduresProducts = {
    products: initialValues?.invoice?.products || [],
    procedures: initialValues?.invoice?.procedures || []
  };

  const EmptyData = () => {
    const { title, startDesc, icon, textWrapperClassName } = emptyStateMessages(isServices);
    return (
      <NotFound
        icon={icon}
        title={title}
        description={startDesc}
        textWrapperClassName={textWrapperClassName}
      />
    );
  };

  return (
    <div className="h-[266px] overflow-auto">
      <AGBasicTable
        rowData={selectedItems}
        gridOptions={GRID_OPTIONS}
        noRowsOverlayComponent={EmptyData}
        defaultColDef={DEFAULT_COLUMN_DEFS}
        columnDefs={getColDefs(type, hasPaymentPermission, hasBillingPermission)}
        context={{
          type,
          list,
          setFieldValue,
          selectedItems,
          isClinicalNoteLocked,
          originalProceduresProducts
        }}
      />
    </div>
  );
};

export default SelectedItems;
