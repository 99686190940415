import React from 'react';
import Tippy from '@tippyjs/react';
import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';
import { useNewAppointmentContext } from 'lib/context/Appointment/NewAppointmentContext';

const SendAppNotification = () => {
  const { formik } = useNewAppointmentContext();
  const { values, setFieldValue } = formik;

  return (
    <div className="flex items-center gap-2">
      <Switch
        onChange={() => setFieldValue('enable_notifications', !values.enable_notifications)}
        checked={values.enable_notifications}
      />
      <label htmlFor="">Send appointment notification</label>

      <Tippy
        content={
          <span
            dangerouslySetInnerHTML={{
              __html: `Notification types (Text/Email):<br/><br/>
                      Appointment confirmation<br/>
                      Appointment reminders`
            }}
          />
        }
        className="tippy-dark">
        <div>
          <Icon icon="new-info" color="neutral" />
        </div>
      </Tippy>
    </div>
  );
};

export default SendAppNotification;
