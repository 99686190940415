import fontkit from '@pdf-lib/fontkit';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

import { getDocumentHistory } from 'api/DocumentHistory';

import { hexToRGBObject } from 'lib/helpers/utility';

const applyChangesToPDF = async ({ pdf, navigate, id, kind, historyData, myPortalPractice }) => {
  let blob = null;
  let scale = 1;
  let changes = {};

  try {
    const response = await fetch(pdf);

    if (!response.ok) {
      throw new Error('Failed to fetch PDF file');
    }
    const pdfBytes = await response.arrayBuffer();

    const data =
      historyData ||
      (await getDocumentHistory(navigate, {
        kind,
        id,
        myPortalPractice
      }));

    scale = data?.record?.changes?.scale || 1.5;

    const pdfDoc = await PDFDocument.load(pdfBytes);
    pdfDoc.registerFontkit(fontkit);
    const pages = pdfDoc.getPages();

    const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);
    const timesBoldFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBold);
    const timesItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanItalic);
    const timesBoldItalicFont = await pdfDoc.embedFont(StandardFonts.TimesRomanBoldItalic);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBoldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
    const helveticaItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaOblique);
    const helveticaBoldItalicFont = await pdfDoc.embedFont(StandardFonts.HelveticaBoldOblique);
    const courierFont = await pdfDoc.embedFont(StandardFonts.Courier);
    const courierBoldFont = await pdfDoc.embedFont(StandardFonts.CourierBold);
    const courierItalicFont = await pdfDoc.embedFont(StandardFonts.CourierOblique);
    const courierBoldItalicFont = await pdfDoc.embedFont(StandardFonts.CourierBoldOblique);

    for (let pageNum = 1; pageNum <= pdfDoc.getPages().length; pageNum++) {
      const canvasJson = data?.record?.changes?.[pageNum];
      if (!canvasJson) continue;

      const page = pages[pageNum - 1];

      for (const obj of canvasJson.objects) {
        const {
          left,
          top,
          width,
          height,
          scaleX,
          scaleY,
          radius,
          text,
          src,
          fontWeight,
          fontStyle,
          fontFamily
        } = obj;
        const { r, g, b } = (obj.fill && hexToRGBObject(obj.fill)) ||
          (obj.stroke && hexToRGBObject(obj.stroke)) || { r: 0, g: 0, b: 0 };

        let font = timesRomanFont;
        if (fontFamily === 'Helvetica') {
          if (fontWeight === 'bold' && fontStyle === 'italic') {
            font = helveticaBoldItalicFont;
          } else if (fontWeight === 'bold') {
            font = helveticaBoldFont;
          } else if (fontStyle === 'italic') {
            font = helveticaItalicFont;
          } else {
            font = helveticaFont;
          }
        } else if (fontFamily === 'Courier') {
          if (fontWeight === 'bold' && fontStyle === 'italic') {
            font = courierBoldItalicFont;
          } else if (fontWeight === 'bold') {
            font = courierBoldFont;
          } else if (fontStyle === 'italic') {
            font = courierItalicFont;
          } else {
            font = courierFont;
          }
        } else {
          if (fontWeight === 'bold' && fontStyle === 'italic') {
            font = timesBoldItalicFont;
          } else if (fontWeight === 'bold') {
            font = timesBoldFont;
          } else if (fontStyle === 'italic') {
            font = timesItalicFont;
          }
        }

        if (obj.type === 'image') {
          const img = await pdfDoc.embedPng(src);
          page.drawImage(img, {
            x: left / scale,
            y: page.getHeight() - top / scale - (height * scaleY) / scale,
            width: (width * scaleX) / scale,
            height: (height * scaleY) / scale
          });
        }

        if (obj.type === 'i-text') {
          const lines = text.split('\n');
          let y = page.getHeight() - top / scale;
          for (const line of lines) {
            y -= ((obj.fontSize || 12) * scaleY) / scale;
            page.drawText(line, {
              x: left / scale,
              y,
              size: ((obj.fontSize || 12) * scaleX) / scale,
              font,
              color: rgb(r, g, b)
            });
          }
        } else if (obj.type === 'rect') {
          page.drawRectangle({
            x: left / scale,
            y: page.getHeight() - top / scale - (height * scaleY) / scale,
            width: (width * scaleX) / scale,
            height: (height * scaleY) / scale,
            color: rgb(r, g, b)
          });
        } else if (obj.type === 'circle') {
          page.drawEllipse({
            x: (left + radius * scaleX) / scale,
            y: page.getHeight() - (top + radius * scaleY) / scale,
            xScale: (radius * scaleX) / scale,
            yScale: (radius * scaleY) / scale,
            color: rgb(r, g, b)
          });
        } else if (obj.type === 'path') {
          const svgPath = obj.path
            .map((command) => {
              const type = command[0];
              const coords = command.slice(1).map((c, i) => {
                // Apply appropriate scaling based on coordinate index
                if (i % 2 === 0) {
                  return c / scale - left / scale;
                } else {
                  return c / scale - top / scale;
                }
              });
              return `${type} ${coords.join(' ')}`;
            })
            .join(' ');

          page.drawSvgPath(svgPath, {
            x: left / scale,
            y: page.getHeight() - top / scale,
            scale: Math.min(scaleX, scaleY),
            ...(obj.stroke
              ? {
                  borderColor: rgb(r, g, b),
                  borderWidth: obj.strokeWidth || 1
                }
              : {
                  color: rgb(r, g, b)
                })
          });
        }
      }
    }
    const pdfBytesNew = await pdfDoc.save();
    blob = new Blob([pdfBytesNew], { type: 'application/pdf' });
    changes = data?.record?.changes;
  } catch (error) {
    console.error('Error applying changes to PDF:', error);
  }

  return { blob, scale, changes, pdf };
};

export default applyChangesToPDF;
