import React, { useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { interimApi } from 'api/InterimApi';
import useOutsideClick from 'lib/hooks/useOutsideClick';

export default function ServiceComponent({ service }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [color, setColor] = useState(service.color);
  const colorPickerRef = useRef();
  const navigate = useNavigate();

  const updateColor = async (newColor) => {
    try {
      let params = {
        service_id: service.id,
        fields: { color: newColor }
      };
      const res = await interimApi('/api/practice/services/update', params, navigate);
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
    if (color !== service.color) {
      updateColor(color);
    }
  };

  useOutsideClick(colorPickerRef, () => handleClose());

  const handleClose = () => {
    setDisplayColorPicker(false);
    if (color !== service.color) {
      updateColor(color);
    }
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  return (
    <div className="grid grid-cols-3 items-center">
      <div className="text-sm">{service.name}</div>
      <div className="text-sm" ref={colorPickerRef}>
        <div>
          <div
            className="h-[26px] w-[50px] cursor-pointer rounded-sm border border-solid border-neutral-300 !p-1"
            onClick={(e) => handleClick(e)}>
            <div style={{ background: color }} className="h-full w-full" />
          </div>
          {displayColorPicker ? (
            <div className="relative">
              <SketchPicker className="absolute" color={color} onChange={(e) => handleChange(e)} />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
