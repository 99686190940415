import {
  appointmentBalanceState,
  appointmentCCState,
  appointmentCheckinState,
  appointmentConfirmState,
  appointmentDataTypeState,
  claimState,
  clinicalNoteStatus,
  superbillStatus
} from 'constants';

export const apptDefaultFilters = ({
  searchPatients,
  practitionerOptions,
  loadResources,
  searchServices,
  arrivalStatuses,
  tags
}) => ({
  patients: {
    type: 'search',
    multiple: true,
    loadOptions: (e) => searchPatients(e),
    values: [],
    title: 'Patients',
    placeholder: 'Search patients'
  },
  practitioners: {
    type: 'search',
    multiple: true,
    options: practitionerOptions,
    values: [],
    title: 'Providers',
    placeholder: 'Select providers',
    icon: false
  },
  resources: {
    type: 'search',
    multiple: true,
    options: loadResources,
    values: [],
    title: 'Resources',
    placeholder: 'Select resources',
    icon: false
  },
  serviceType: {
    type: 'search',
    multiple: true,
    loadOptions: (e) => searchServices(e),
    values: [],
    title: 'Service',
    placeholder: 'Search services'
  },
  appointmentTags: {
    type: 'search',
    multiple: true,
    options: tags,
    values: [],
    title: 'Tags',
    placeholder: 'Select appointment tags',
    icon: false
  },
  arrivalStatus: {
    type: 'search',
    multiple: true,
    values: [],
    options: arrivalStatuses,
    title: 'Arrival status',
    placeholder: 'Select arrival statuses'
  },
  appointmentDataType: {
    type: 'checkbox',
    values: [],
    options: appointmentDataTypeState,
    title: 'Select Data Type'
  },
  confirmState: {
    type: 'checkbox',
    values: null,
    options: appointmentConfirmState,
    title: 'Confirmation'
  },
  checkinState: {
    type: 'checkbox',
    values: null,
    options: appointmentCheckinState,
    title: 'Checkin'
  },
  balanceState: {
    type: 'checkbox',
    values: null,
    options: appointmentBalanceState,
    title: 'Balance'
  },
  CCState: {
    type: 'checkbox',
    values: null,
    options: appointmentCCState,
    title: 'Card on file'
  },
  clinicalNoteStatus: {
    type: 'checkbox',
    values: null,
    options: clinicalNoteStatus,
    title: 'Clinical note status'
  },
  superbillStatus: {
    type: 'checkbox',
    values: null,
    options: superbillStatus,
    title: 'Superbill'
  },
  claimState: {
    type: 'checkbox',
    values: null,
    options: claimState,
    title: 'Claim'
  }
});

export default apptDefaultFilters;
