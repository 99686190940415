import { ia } from '../../../../lib/helpers/utility';

export const processData = (data) => {
  if (!ia(data)) return data;

  const payers =
    (ia(data[0]?.payer) &&
      data[0]?.payer.map((rowPayer) => {
        const payer = rowPayer.payer;
        return payer ? { value: payer.id, label: payer.name } : null;
      })) ||
    [];

  const procedures =
    (ia(data[0]?.procedure) &&
      data[0].procedure.map((rowProcedure) => {
        const procedure = rowProcedure.procedure;
        return procedure ? { value: procedure.id, label: procedure.name } : null;
      })) ||
    [];

  const users = data.map((row) => {
    const user = row?.user;
    return user ? { value: row.user_id, label: `${row?.user?.f_name} ${row?.user?.l_name}` } : null;
  });

  let newFormatData = { supervisor: data[0]?.supervisor, payers, procedures, users };

  return newFormatData;
};

const keys = ['users', 'procedures', 'payers'];

export const extractIds = (data, key) => {
  if (Array.isArray(data)) {
    return data.map((row) => row.value) || [];
  }

  return keys.includes(key) ? [] : data;
};

export const getChanges = (formik, values) => {
  const changes = { supervisorId: values.supervisorId };

  const objectToSubmit = values;
  const initialValues = formik.initialValues;

  for (const key in objectToSubmit) {
    if (objectToSubmit[key] !== initialValues[key]) {
      changes[key] = extractIds(objectToSubmit[key], key);
    }
  }

  return changes;
};

export const extractNewObjectIds = (data) => {
  const extractedValues = {};
  for (const key in data) {
    extractedValues[key] = extractIds(data[key]);
  }

  return extractedValues;
};

export const getCurrentOption = (options, optionId) => {
  if (!optionId) return null;
  if (!ia(options, 0)) return null;
  return options?.find((role) => role.value === optionId);
};
