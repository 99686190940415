import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getMyScribePatientForms } from 'api/MyScribeAi';

export const usePatientForms = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['myscribe-patient-forms', ...dependencies],
    () => getMyScribePatientForms(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
