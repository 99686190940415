import React from 'react';
import { sanitize } from '../../../../../../../../lib/helpers/sanitize';

const NonERXPrescriptionAndFrequency = ({ itemProps }) => {
  return (
    <div>
      <div className="text-neutral-800 text-base font-500">Non eRX Prescription And Frequency</div>

      <ul className="!pl-5 !mt-3">
        {!!itemProps?.non_erx_prescription_and_frequency ? (
          <li
            dangerouslySetInnerHTML={sanitize(itemProps?.non_erx_prescription_and_frequency)}
            className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside"></li>
        ) : (
          'N/A'
        )}
      </ul>
    </div>
  );
};

export default NonERXPrescriptionAndFrequency;
