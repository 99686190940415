import React from 'react';
import { useRecoilValue } from 'recoil';
import { useFormikContext } from 'formik';

import { discountTypes } from 'constants.js';
import { permissions } from 'components/state';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';

const Discount = () => {
  const p = useRecoilValue(permissions);

  const { values, setFieldValue } = useFormikContext();

  const { tax, total_amount, amount_paid } = values?.invoice || {};
  const discount = values?.discount || values?.invoice.discount || {};

  const isPercent = discount?.type === 'percent';
  const currentBalance = total_amount - (amount_paid ?? 0) + (tax ?? 0);
  const calcPercentage = Math.round((discount?.amount_cents / total_amount) * 10000);

  const handleDiscountChange = ({ newBalance, discountType, setFieldValue }) => {
    const discountValue = +(newBalance ?? discount?.value ?? discount?.amount_cents ?? 0);

    let newDiscountType = discountType || 'amount';
    let discountObj = { amount_cents: discountValue, type: newDiscountType };

    if (newDiscountType === 'percent') {
      discountObj.value = newBalance;
      discountObj.amount_cents = Math.round((currentBalance * newBalance) / 100 / 100);

      if (!newBalance) {
        discountObj.amount_cents = Math.round(discountValue);
        discountObj.value = ((discountValue * 100) / currentBalance) * 100;
      }
    } else if (newDiscountType === 'amount') {
      discountObj.value = newBalance;

      if (!newBalance) {
        const amountCents = (currentBalance * discountValue) / 100 / 100;

        discountObj.value = amountCents;
        discountObj.amount_cents = Math.round(amountCents);
      }
    }

    if ((values?.invoice.discount === null && +discountObj.value > 0) || newBalance === undefined) {
      setFieldValue('discount', discountObj);
    }
  };

  const handleDiscountChangeWrapper = ({ type, newBalance, value }) => {
    if (type === 'value') {
      handleDiscountChange({ newBalance, setFieldValue, discountType: discount?.type });
    } else if (type === 'unit' && value !== discount?.type) {
      handleDiscountChange({ setFieldValue, discountType: value });
    }
  };

  const discountValue =
    !discount?.value && isPercent ? calcPercentage : (discount?.value ?? discount?.amount_cents);

  return (
    <CurrencyInput
      units={discountTypes}
      value={discountValue}
      className="text-right"
      suffix={isPercent ? '%' : ''}
      prefix={!isPercent ? '$' : ''}
      maxValue={isPercent ? 10000 : currentBalance}
      disabled={!p?.billing?.discount || values?.invoice?.balance + tax <= 0}
      onUnitChange={(value) => handleDiscountChangeWrapper({ type: 'unit', value })}
      currentUnit={discountTypes.find((t) => t?.value === (discount?.type || 'amount'))?.label}
      onValueChange={(newBalance) => handleDiscountChangeWrapper({ type: 'value', newBalance })}
      wrapperClassName="!pl-1 !pr-0 w-[130px] h-[26px] !px-[4px] !bg-transparent border-none border-bottom [border-bottom-style:solid] rounded-none focus-within:!outline-[0px] !outline-0"
    />
  );
};

export default Discount;
