import NotFound from 'components/practice/Dashboard/components/NotFound';
import Loader from 'components/shared/Loader/Loader';
import React from 'react';
import PreviewNote from '../../PreviewNote/PreviewNote';

const DisplayContent = ({
  clinicalNote,
  showExportModal,
  printButtonRef,
  sendEmailToPatientButtonRef,
  selectedClinicalNote,
  isFetching,
  advancedHP,
  advancedSOAP,
  customFormTypes
}) => {
  if (isFetching) {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center">
        <div>
          <Loader className="!h-16 !w-16" outlined color="success" />
        </div>

        <p>Loading, please wait...</p>
        <p>
          Your content is being prepared. This might take a moment. Thank you for your patience!
        </p>
      </div>
    );
  }

  if (!clinicalNote && !advancedHP && !advancedSOAP && !customFormTypes) {
    return (
      <NotFound
        title="Clinical Note Not Found"
        description="Clinical note not found. Please refresh and try again. Contact support if the issue persists."
      />
    );
  }

  return (
    <PreviewNote
      patientId={selectedClinicalNote?.appointment?.user_id}
      isExport={showExportModal?.showOnlyExport}
      printButtonRef={printButtonRef}
      sendEmailToPatientButtonRef={sendEmailToPatientButtonRef}
      outsideAppointmentId={selectedClinicalNote?.appointment?.id}
    />
  );
};

export default DisplayContent;
