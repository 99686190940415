import React from 'react';

import { formatDate, iaRa } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Checkbox from 'components/shared/Checkbox/Checkbox';

import ClaimsSkeleton from './ClaimsSkeleton';

const ClaimsData = ({ claims, selectedClaims, setSelectedClaims, loading }) => {
  if (iaRa(claims).length === 0) return null;

  const handleCheckboxChange = (claimId) => {
    setSelectedClaims((prev) => {
      if (prev.includes(claimId)) {
        const newSelected = prev.filter((id) => id !== claimId);
        if (newSelected.length === 0) {
          showAlert({
            title: 'Please select at least one claim',
            color: 'danger'
          });
        }
        return newSelected;
      }
      return [...prev, claimId];
    });
  };

  return (
    <div className="flex flex-col gap-[10px] rounded-lg bg-gradient-to-r from-[rgba(19,185,255,0.08)] to-[rgba(19,185,255,0.22)] mt-4">
      {loading ? (
        <ClaimsSkeleton />
      ) : (
        <>
          <div className="flex items-center justify-between gap-4 px-3 pt-3">
            <div className="flex items-center gap-2">
              <Checkbox
                size={20}
                labelTextColor="hidden"
                isChecked={selectedClaims.length === claims.length}
                onChange={() => {
                  if (selectedClaims.length === claims.length) {
                    setSelectedClaims([]);
                  } else {
                    setSelectedClaims(claims.map((claim) => claim.id));
                  }
                }}
              />
              <span className="text-sm font-500 text-neutral-600">Patient Name</span>
            </div>
            <span className="text-sm font-500 text-neutral-600">DOS</span>
          </div>
          <div className="flex flex-col gap-[10px] max-h-[30vh] overflow-y-auto px-3 pb-3">
            {iaRa(claims).map((claim) => {
              const date = claim?.from_dos || claim.created_at;
              const claimDate = date ? formatDate(date) : '';
              return (
                <div key={claim?.id} className="flex items-center justify-between gap-4">
                  <Checkbox
                    label={`${claim?.pat_name_f} ${claim?.pat_name_l}`}
                    size={20}
                    isChecked={selectedClaims.includes(claim?.id)}
                    onChange={() => handleCheckboxChange(claim?.id)}
                  />
                  <span className="text-sm font-500 text-neutral-800">{claimDate}</span>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default ClaimsData;
