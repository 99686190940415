import Tippy from '@tippyjs/react';
import cs from 'classnames';
import { concat, map } from 'lodash';
import React from 'react';

import { formatDate, iaRa, mString } from '../../../../../../lib/helpers/utility';
import Button from '../../../../../shared/Buttons/Button';
import { calcAdjustment, handleAdjustmentCodes } from '../ERA&EOBHelpers';

const UnMatchedClaimsRows = ({ era, timezone, codes, setSelectedClaim }) => {
  return (
    <table className="primary-table responsive-table">
      <tr className="!background-transparent !w-[100%]">
        <td className="">
          <table className="success">
            <thead>
              <tr>
                <th>
                  <span className="!font-semibold !text-primary-900">DoS</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Patient</span>/
                  <span className="!font-medium !text-primary-700">Service</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Provider</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Billed</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Adjmt</span>
                </th>
                <th>
                  <span className="!font-semibold !text-primary-900">Ins. Paid</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody className="overflow-hidden">
              {iaRa(era?.unmatched_claims).map((claim, i) => {
                return (
                  <tbody key={i} className="border-bottom">
                    <tr>
                      <th className="!font-semibold !text-primary-900">
                        {claim.from_dos && formatDate(claim.from_dos, timezone)}
                      </th>
                      <th
                        className="cursor-pointer !font-semibold !text-primary-900 hover:underline"
                        onClick={() =>
                          claim.user
                            ? window.open(`/portal/charts/${claim.pcn}/billing`, '_blank')
                            : null
                        }>
                        <Tippy
                          content={claim.user ? 'View Chart' : null}
                          placement="right"
                          className="tippy-dark"
                          key={i}>
                          <div className="flex">
                            <span
                              className={cs(
                                'flex w-full cursor-pointer select-none items-center justify-center !rounded-lg p-1 text-center text-sm'
                              )}>
                              {claim.user ? claim.user.fullName : `User ID: ${claim.pcn}`}
                            </span>
                          </div>
                        </Tippy>
                      </th>
                      <th className="!font-semibold !text-primary-900">N/A</th>
                      <th className="!font-semibold !text-primary-900">
                        {mString(claim?.total_charge * 100)}
                      </th>
                      <th className="!font-semibold !text-primary-900">
                        {mString(calcAdjustment(concat(...map(claim.charge, 'adjustment'))))}
                      </th>
                      <th className="!font-semibold !text-primary-900">
                        {mString(claim.total_paid * 100)}
                      </th>
                      <th className="height-inherit flex justify-center !font-semibold !text-primary-900">
                        <Button
                          text="Post"
                          onClick={(e) => {
                            e.stopPropagation();
                            setSelectedClaim({ claim, index: i, eraId: era.id });
                          }}
                        />
                      </th>
                    </tr>

                    {iaRa(claim?.charge).map((charge, index) => {
                      return (
                        <tr key={index} className="!font-semibold !text-primary-900">
                          {/* TEMPORARY SOLUTION BEOFRE WE FIX IT COMPLETELY */}
                          <td>
                            {charge?.from_dos && charge?.from_dos?.includes('-')
                              ? formatDate(charge.from_dos, timezone)
                              : formatDate(charge.from_dos)}
                          </td>
                          {/* TEMPORARY SOLUTION BEOFRE WE FIX IT COMPLETELY */}

                          {/* <td>{charge?.from_dos && formatDate(charge.from_dos, timezone)}</td> */}
                          <td className="!font-medium !text-primary-700">{charge.proc_code}</td>
                          <td>
                            {`Qty: ${charge.units ? charge.units : ''} ·
                        Mods: ${[charge.mod1, charge.mod2, charge.mod3, charge.mod4]
                          .filter((f) => f !== null)
                          .join(', ')}
                        `}
                          </td>
                          <td>{mString(charge?.charge * 100)}</td>
                          <td>{handleAdjustmentCodes(charge?.adjustment, codes)}</td>
                          <td>{mString(charge?.paid * 100)}</td>
                          <td colSpan={1}></td>
                        </tr>
                      );
                    })}
                  </tbody>
                );
              })}
            </tbody>
          </table>
        </td>
      </tr>
    </table>
  );
};

export default UnMatchedClaimsRows;
