import React from 'react';
import { snakeToTitleCase } from '../../../../../../../lib/helpers/utility';
import Icon from '../../../../../../shared/Icon/Icon';

const MacrosTypes = ({ MACRO_TYPES, hasMacroType, filters, setFilters }) => {
  return (
    <>
      {!hasMacroType && !filters?.searchTerm && (
        <div className="!mt-3 flex-col !gap-3">
          {MACRO_TYPES.map((row, index) => {
            return (
              <div
                key={index}
                data-qa={`macro-type-${index}`}
                className="flex justify-between text-sm text-primary-900 transition-all !mt-1 !p-2 rounded-lg hover:bg-primary-50 cursor-pointer"
                onClick={() => setFilters((prevState) => ({ ...prevState, macroType: row }))}>
                <div className="flex gap-1.5">
                  <Icon icon="new-macro-type" />
                  {snakeToTitleCase(row)}
                </div>
                <Icon icon="next" color="primary" />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default MacrosTypes;
