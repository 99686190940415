import moment from 'moment';
import toast from 'react-hot-toast';
import { Honeybadger } from '@honeybadger-io/react';

import { requestApi } from '/api/Api';
import { showAlert } from 'components/shared/Alert/Alert';
import { getUpdatedAppointmentShape } from './previewAptHelpers';

export const submitAppointment = async ({
  updatedAppointment,
  formik,
  setLoading,
  loading,
  appointment,
  procedures,
  isReschedule,
  setRefetchAppointments,
  setAppointment,
  setIsReschedule,
  setRefetchCustomForms,
  setOldAppointment,
  refetchCustomForms,
  navigate,
  queryClient
}) => {
  setLoading({ ...loading, update: true });

  const params = getUpdatedAppointmentShape({ updatedAppointment, appointment, procedures });

  if (isReschedule && appointment?.medicalHistory?.state === 'finalized') {
    toast.error('Clinical note has already been finalized and cannot be rescheduled at this time');
    setLoading({ ...loading, update: false });
    return;
  }

  const onSuccess = (data) => {
    if (data.affectedRows > 0) {
      setRefetchAppointments();

      if (data?.updatedAppointment) {
        setIsReschedule(false);
        setRefetchCustomForms(!refetchCustomForms);

        setOldAppointment(data?.updatedAppointment);
        setAppointment({ ...appointment, ...data?.updatedAppointment });
        formik.setFieldValue('balance', data?.updatedAppointment?.balance);
        formik.setFieldValue('resource_id', data?.updatedAppointment?.resource_id);

        if (appointment?.id !== data?.updatedAppointment?.id) {
          queryClient.setQueryData(['appointment', appointment.id], {
            ...appointment,
            ...data?.updatedAppointment
          }); // TEMPORARY: this will invalidate the query for old appointment preview

          queryClient.setQueryData(['appointmentv2', appointment.id], {
            ...appointment,
            ...data?.updatedAppointment
          });

          formik.setValues({ ...formik.initialValues, ...data?.updatedAppointment });
        } else {
          queryClient.invalidateQueries(['appointment', appointment.id]); // TEMPORARY: this will invalidate the query for old appointment preview
          queryClient.invalidateQueries(['appointmentv2', appointment.id]);
        }
      }

      showAlert({ message: 'Appointment was updated successfully', color: 'success' });
    } else {
      showAlert({ message: 'There was an error updating this appointment', color: 'danger' });
      Honeybadger.notify(`Appointment update ID: ${updatedAppointment.id}`);
    }
  };

  await requestApi({ url: '/api/appointment/update', navigate, onSuccess, params });

  setLoading({ ...loading, update: false });
};

export const runEligibility = async ({
  formik, // for old appt preview
  params,
  setPractice,
  queryClient = null,
  navigate = () => {},
  patientId = null,
  onSuccess = () => {},
  setRunningEligibility = () => {}
}) => {
  setRunningEligibility(true);

  const handleOnSuccess = (data) => {
    const { patient, type } = data;

    const insuranceBenefits = patient?.insurance_benefits;
    const currentBenefit = insuranceBenefits?.[type || 'primary'];
    const isCurrentBenefitValid = currentBenefit?.valid;

    if (isCurrentBenefitValid) {
      onSuccess && onSuccess({ ...currentBenefit, type });
      setPractice((prev) => {
        return { ...prev, eligibility_counter: prev?.eligibility_counter + 1 };
      });
    }

    // formik for old appt preview
    if (!!formik) {
      formik.setFieldValue('patient.insurance_benefits', insuranceBenefits);
    } else {
      queryClient.setQueryData(['patient', patient?.id], (prev) => {
        return { patient: { ...prev.patient, insurance_benefits: insuranceBenefits } };
      });
    }

    if (isCurrentBenefitValid) {
      showAlert({ message: 'Eligibility ran successfully', color: 'success' });
    } else {
      showAlert({ message: 'Eligibility ran with errors', color: 'warning' });
    }
  };

  const onError = (error) => {
    setRunningEligibility(false);
    showAlert({ message: error, color: 'danger' });
  };

  await requestApi({
    onError,
    navigate,
    onSuccess: handleOnSuccess,
    params: { ...params, patientId },
    url: '/api/patient/insurance/check_benefits'
  });

  setRunningEligibility(false);
};

export const handleDateTimeChange = ({
  newTime,
  type,
  dateAndTime,
  practiceTimezone,
  setDateAndTime,
  formik,
  diffMin,
  setIsReschedule,
  appointment
}) => {
  if (type == 'date') newTime = moment(newTime).add(12, 'h');
  if (dateAndTime && type == 'date') {
    const date = moment.tz(newTime, practiceTimezone).format('L');
    const time = moment.tz(dateAndTime, practiceTimezone).format('h:mm A') || '';
    newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', practiceTimezone);
  } else if (dateAndTime && type == 'time') {
    const date =
      moment.tz(dateAndTime, practiceTimezone).format('L') || moment().add(12, 'h').format('L');
    const time = newTime;
    newTime = moment.tz(date + ' ' + time, 'M/D/YYYY h:mm A z', practiceTimezone);
  }
  if (!dateAndTime && type == 'time') {
    newTime = moment.tz(
      moment().add(12, 'h').format('L') + ' ' + newTime,
      'M/D/YYYY h:mm A z',
      practiceTimezone
    );
  }

  setDateAndTime(moment.tz(newTime, practiceTimezone).format());
  formik.setFieldValue('starts_at', moment.tz(newTime, practiceTimezone).format());
  formik.setFieldValue('ends_at', moment.tz(newTime, practiceTimezone).add(diffMin, 'm').format());

  const oldTime = moment.tz(appointment?.starts_at, practiceTimezone).seconds(0).format();

  moment(oldTime).isSame(moment(newTime).format()) ? setIsReschedule(false) : setIsReschedule(true);
};
