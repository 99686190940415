import React, { useState, useMemo, memo } from 'react';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import AGTable from 'components/shared/AGTable/AGTable';
import { useRoleList } from 'lib/hooks/queries/roles/useRoleList';
import usePageTitle from 'lib/hooks/usePageTitle';
import { defaultColumns } from './lib/helper';
import { useEditRoleContext } from 'lib/context/Role/EditRoleContext/EditRoleContext';
import EditRoleModal from './components/EditRole/EditRoleModal';
import state from 'components/state';
import { useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { currentPractice } from 'components/practice/practiceState';

const Table = ({ isAdmin, forAdmin }) => {
  usePageTitle('Roles & Permissions');
  const [rowData, setRowData] = useState({});
  const { showEditRoleModal, setShowEditRoleModal, formik } = useEditRoleContext();
  const permissions = useRecoilValue(state.permissions);
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice.timezone;

  const { id } = useParams();

  const { data, isLoading, isFetching } = useRoleList({
    params: {
      practiceId: id,
      limit: 30,
      offset: 0,
      forAdmin
    },
    dependencies: [id]
  });

  const roleList = data?.role_list;

  const onCellClicked = (e) => {
    if (e?.column?.getColId() === 'actions') return;
    formik.resetForm();
    setShowEditRoleModal(true);
    setRowData(e?.data);
  };

  const MemoizedAGTable = useMemo(
    () => (
      <AGTable
        data={roleList}
        rowSelection="multiple"
        onCellClicked={onCellClicked}
        columnDef={() => defaultColumns(permissions, practiceTimezone, isAdmin, forAdmin)}
        suppressRowClickSelection={true}
        customClassName="ag-grid-interactive"
        loading={isLoading || isFetching}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [roleList, isLoading, isFetching]
  );

  const MemoizedEditRoleModal = useMemo(
    () => (
      <EditRoleModal
        showModal={showEditRoleModal}
        hideModal={() => setShowEditRoleModal(false)}
        roleName={rowData?.name}
        roleType={rowData?.type}
        roleDescription={rowData?.description}
        roleId={rowData?.id}
        isAdmin={isAdmin}
        forAdmin={forAdmin}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showEditRoleModal, rowData]
  );

  return (
    <>
      <div className="flex h-full flex-col overflow-hidden">
        <div className="ag-theme-quartz !mb-0 h-full">
          {isLoading || isFetching ? <Skeleton count={30} /> : MemoizedAGTable}
        </div>
      </div>
      {showEditRoleModal && MemoizedEditRoleModal}
    </>
  );
};

export default memo(Table);
