import { isUndefined } from 'lodash';

function generateUniqueId() {
  return Math.floor(Math.random() * 1000) + 1;
}

export const initialValues = (pack) => ({
  name: pack?.name || '',
  note: pack?.note || '',
  amount_cents: pack?.amount_cents || '',
  image: pack?.image || '',
  validity_time_unit: pack?.validity_time_unit ? pack.validity_time_unit : 'day',
  validity_time_length: pack?.validity_time_length || 0,
  no_expiration: !isUndefined(pack?.no_expiration) ? pack?.no_expiration : true,
  procedures: pack?.procedures.length ? pack.procedures : [emptyProductObject],
  products: pack?.products.length ? pack.products : [emptyProceduresObject]
});

export const emptyProductObject = {
  value: '',
  label: '',
  amount_cents: '',
  total_amount_cents: '',
  quantity: '',
  inventory: '',
  id: generateUniqueId()
};

export const emptyProceduresObject = {
  value: '',
  label: '',
  timeLength: '',
  amount_cents: '',
  total_amount_cents: '',
  quantity: '',
  id: generateUniqueId()
};

export const timeUnitArray = [
  { value: 'day', label: 'day(s)' },
  { value: 'week', label: 'week(s)' },
  { value: 'month', label: 'month(s)' },
  { value: 'year', label: 'year(s)' }
];
