import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { useInternalInvoices } from 'lib/hooks/queries/billing/useInternalInvoices';

import { currentPractice } from 'components/practice/practiceState';
import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import ButtonActions from './components/ButtonActions';
import InvoiceStepModal from './components/InvoiceStepModal';
import { INVOICE_SWITCH_ITEMS } from './lib/helpers';
import { DEFAULT_FILTERS, getColDefs } from './lib/internal/configs';

const InternalInvoices = () => {
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const initialColumnDefs = useMemo(() => getColDefs(practiceTimezone), [practiceTimezone]);

  return (
    <AgTablePage
      ModalComponent={InvoiceStepModal}
      defaultColumns={initialColumnDefs}
      defaultFilters={DEFAULT_FILTERS}
      useData={useInternalInvoices}
      name="invoices"
      title="Internal Invoices"
      actions={ButtonActions}
      rowStyle={{ cursor: 'pointer' }}
      switchItems={INVOICE_SWITCH_ITEMS}
    />
  );
};

export default InternalInvoices;
