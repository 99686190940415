import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { withErrorBoundary } from '../../shared/Error/Boundary';
import HipaaForm from '../../shared/Forms/HipaaForm/HipaaForm';

const HipaaFormContainer = () => {
  const { updateSteps, appointmentId } = useOutletContext();
  const [practice, setPractice] = useState('');
  const [patient, setPatient] = useState('');
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const getCheckinStatus = async () => {
    try {
      updateSteps({
        version: 2,
        newStatus: 'completed',
        meta: 'a2'
      });
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  return (
    <HipaaForm
      appointmentId={appointmentId}
      practice={practice}
      patient={patient}
      callback={() => getCheckinStatus()}
    />
  );
};

export default withErrorBoundary(HipaaFormContainer);
