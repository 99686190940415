import React from 'react';
import { Popover } from '@headlessui/react';
import Icon from '../../../../../shared/Icon/Icon';
import cs from 'classnames';
import { chartLinks } from '../../../../../../constants';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import PermissionAllowed from '../../../../../shared/Permissions/Allowed';
import { lowerCase } from 'lodash';

const ChartLinks = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  return (
    <Popover className="relative">
      {({ open }) => (
        <div>
          <Popover.Button className="p-0" data-qa="clinical-notes-menu">
            <div
              className={cs(
                'flex items-center gap-x-[6px] min-w-[100px] rounded-md px-[14px] !py-1',
                open ? 'bg-primary-700' : 'bg-primary-800'
              )}>
              {chartLinks.map((item, index) => {
                const activeLink =
                  pathname?.includes(item?.path) && item.path !== '' ? item.label : '';
                if (activeLink)
                  return (
                    <span
                      className="font-500 text-sm first-letter:capitalize text-white"
                      key={index}>
                      {activeLink}
                    </span>
                  );
              })}
              <Icon
                icon="new-chevron-down"
                color="white"
                className={cs('duration-200', open && 'rotate-180 ')}
              />
            </div>
          </Popover.Button>
          <Popover.Panel className="absolute left-0 z-40 mt-[6px] min-w-full grid w-max cursor-pointer gap-y-1 rounded-[4px] bg-white !py-2 shadow-medium">
            {chartLinks.map((item, index) => (
              <PermissionAllowed requiredPermissions={item?.permission} key={index}>
                <NavLink
                  state={item.path ?? ''}
                  to={item.path ? `/portal/charts/${id}/${item.path}` : `/portal/charts/${id}/`}
                  className={({ isActive }) =>
                    `relative flex items-center gap-x-2 !py-2 !px-4 transition-all hover:bg-primary-50 ${
                      isActive && 'bg-primary-50'
                    }`
                  }
                  key={index}
                  data-qa={item.path || lowerCase(item.label)}>
                  <Icon icon={item.icon} size={18} />
                  <span
                    className="select-none text-sm font-400 text-primary-900"
                    data-qa={`chart-link-${index}`}>
                    {item.label}
                  </span>
                </NavLink>
              </PermissionAllowed>
            ))}
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default ChartLinks;
