import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getMedications } from '../../../../api/Medication';

export const useMedication = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['medication', ...dependencies], () => getMedications(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
