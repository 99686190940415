import Face from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/3DNote/Face';
import React, { useState } from 'react';
import FormsAI from '../FormsAI';

const CH3DFormsAI = ({ inputName, components }) => {
  const [formsList] = useState({
    face: Face
  });

  return <FormsAI formsList={formsList} inputName={inputName} components={components} />;
};

export default CH3DFormsAI;
