import { iaRa } from 'lib/helpers/utility';

export default function calculateTotalCharge(procedures = [], onlyPrimary = false) {
  let totalCharge = 0.0;
  const filteredProcedures = onlyPrimary
    ? iaRa(procedures).filter((procedure) => procedure?.type === 'primary')
    : iaRa(procedures);

  filteredProcedures?.map((procedure) => {
    totalCharge +=
      parseFloat(procedure?.charge || 0) * parseFloat(procedure?.units || 1).toFixed(2);
  });

  return isNaN(totalCharge) ? 0 : totalCharge?.toFixed(2) / 100;
}
