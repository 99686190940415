const NAMES = {
  personalInfo: 'Personal Info',
  personalId: 'Personal ID',
  insurance: 'Insurance'
};

module.exports = {
  names: NAMES,
  propertyPathMap: {
    hipaaFormsComplete: {
      name: () => 'HIPAA Consent',
      path: () => 'hipaa',
      order: 0
    },
    checkinForms: {
      name: (form) => form.name,
      path: (form, index) => `form/${index}`,
      order: 1
    },
    // choices: {
    //   name: () => 'Make Changes',
    //   path: () => 'choices',
    //   order: 2
    // },
    personalInfoComplete: {
      name: () => NAMES.personalInfo,
      path: () => 'personal-info',
      order: 7
    },
    insuranceIdComplete: {
      name: () => NAMES.insurance,
      path: () => 'insurance',
      order: 5
    },
    personalIdImageComplete: {
      name: () => NAMES.personalId,
      path: () => 'personal-id',
      order: 8
    },
    payment: {
      name: () => 'Secure Payment',
      path: () => 'payment',
      order: 6
    },
    preAuth: {
      name: () => 'Pre-Authorized Healthcare Form (CC on file)',
      path: () => 'pre_auth',
      order: 6
    }
  },
  checkinV2Map: {
    hipaa: {
      name: () => 'HIPAA Consent',
      path: () => 'hipaa',
      order: 0
    },
    form: {
      name: (form) => form.name,
      path: (form, index) => `form/${index}`,
      order: 1
    },
    // choices: {
    //   name: () => 'Make Changes',
    //   path: () => 'choices',
    //   order: 2
    // },
    'personal-info': {
      name: () => NAMES.personalInfo,
      path: () => 'personal-info',
      order: 7
    },
    insurance: {
      name: () => NAMES.insurance,
      path: () => 'insurance',
      order: 5
    },
    'personal-id': {
      name: () => NAMES.personalId,
      path: () => 'personal-id',
      order: 8
    },
    payment: {
      name: () => 'Secure Payment',
      subTitle: () => 'Payment arrangements are requested prior to receiving treatment',
      path: () => 'payment',
      order: 6
    },
    'pre-auth': {
      name: () => 'Pre-Authorized Healthcare Form (CC on file)',
      path: () => 'pre_auth',
      order: 6
    }
  },
  instantPacketPathMap: {
    preAuth: {
      name: () => 'Pre-Authorized Healthcare Form (CC on file)',
      path: () => 'pre_auth',
      order: 0
    },
    personalInfoComplete: {
      name: () => NAMES.personalInfo,
      path: () => 'personal-info',
      order: 1
    },
    hipaaFormsComplete: {
      name: () => 'HIPAA Consent',
      path: () => 'hipaa',
      order: 2
    },
    checkinForms: {
      name: (form) => form.name,
      path: (form, index) => `form/${index}`,
      order: 3
    },
    insuranceIdComplete: {
      name: () => NAMES.insurance,
      path: () => 'insurance',
      order: 4
    },
    personalIdImageComplete: {
      name: () => NAMES.personalId,
      path: () => 'personal-id',
      order: 5
    }
  },
  editingChoices: [
    {
      name: NAMES.personalInfo,
      id: 'personal_info'
    },
    {
      name: NAMES.personalId,
      id: 'personal_iden'
    },
    {
      name: NAMES.insurance,
      id: 'insurance'
    }
  ]
};
