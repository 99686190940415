import React from 'react';
import ConsentForm from './ConsentForm';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';

export default function ConsentModal({
  showTechnologyModal,
  hideTechnologyModal,
  updateSurcharge,
  setPractice,
  practice
}) {
  return (
    <Modal
      handleOpen={showTechnologyModal}
      handleClose={hideTechnologyModal}
      title="Technology Consent"
      slideFromRight
      subModal
      prevButton={
        <Button close onClick={hideTechnologyModal} transparent icon="back-large"></Button>
      }
      footer={
        <Button onClick={hideTechnologyModal} danger text="Close" color="danger">
          Close
        </Button>
      }>
      <ConsentForm
        hideTechnologyModal={hideTechnologyModal}
        setPractice={setPractice}
        practice={practice}
        updateSurcharge={updateSurcharge}
      />
    </Modal>
  );
}
