import { useQuery } from '@tanstack/react-query';
import { getPermissions } from 'api/Permissions';
import { useNavigate } from 'react-router-dom';

export const useGetPermissions = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(['permissions', ...dependencies], () => getPermissions(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
