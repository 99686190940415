import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import { deleteLabOrder } from 'api/LabOrder';
import { deleteNonERXPrescription } from 'api/NonERXPrescription';
import { deleteReferralOrder } from 'api/ReferalOrder';

import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const deleteModalDefaultProps = {
  id: null,
  type: null,
  open: false,
  loading: false
};

const DeleteOrderConfirm = ({ deleteModalProps, setDeleteModalProps }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleOnCloseDelete = () => setDeleteModalProps(deleteModalDefaultProps);

  const onSuccess = () => {
    showAlert({ color: 'success', message: 'Order Deleted Successfully' });
    queryClient.invalidateQueries(['orders']);
    handleOnCloseDelete();
  };

  const deleteReferralOrderMutation = useMutation((data) => deleteReferralOrder(navigate, data), {
    onSuccess,
    onSettled: () => {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
    }
  });

  const deleteNonERXPrescriptionMutation = useMutation(
    (data) => deleteNonERXPrescription(navigate, data),
    {
      onSuccess,
      onSettled: () => {
        setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
      }
    }
  );

  const deleteLabOrderMutation = useMutation((data) => deleteLabOrder(navigate, data), {
    onSuccess,
    onSettled: () => {
      setDeleteModalProps((prevState) => ({ ...prevState, loading: false }));
    }
  });

  const handleDeleteOrder = async () => {
    setDeleteModalProps((prevState) => ({ ...prevState, loading: true }));
    switch (deleteModalProps?.type) {
      case 'referral_order':
        await deleteReferralOrderMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
      case 'non_erx_prescription':
        await deleteNonERXPrescriptionMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
      case 'lab_order':
      case 'elab_order':
        await deleteLabOrderMutation.mutateAsync({
          idToDelete: deleteModalProps.id
        });
        break;
    }
  };

  return (
    <Confirm
      variant="danger"
      primaryBtnTxt="Delete"
      title="Delete This Order"
      icon="new-document-remove-red"
      message="Are you sure you want to delete this order?"
      loading={deleteModalProps?.loading}
      handleContinue={handleDeleteOrder}
      handleOpen={deleteModalProps?.open}
      handleClose={() => handleOnCloseDelete()}
    />
  );
};

export default DeleteOrderConfirm;
