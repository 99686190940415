import Button from 'components/shared/Buttons/Button';
import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment-timezone';
import { useRecoilValue } from 'recoil';
import { userState } from 'components/state';
import { currentPractice } from 'components/practice/practiceState';
import { Honeybadger } from '@honeybadger-io/react';
import { useNavigate } from 'react-router-dom';
import { filePath, iaRa, reShapePractitioners } from 'lib/helpers/utility';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';
import { usePractitioners } from 'lib/hooks/queries/practitioners/usePractitioners';
import Allowed from 'components/shared/Permissions/Allowed';

const CreateClinicalNoteButton = ({ patientId }) => {
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [provider, setProvider] = useState(null);
  const navigate = useNavigate();
  const currentUser = useRecoilValue(userState);
  const currPractice = useRecoilValue(currentPractice);

  const { data: practitionersList } = usePractitioners(
    {},
    {
      select: (data) => iaRa(data.practitioners),
      enabled: currentUser.kind !== 'practitioner'
    }
  );

  const practitioners = useMemo(() => {
    return reShapePractitioners(iaRa(practitionersList));
  }, [practitionersList]);

  useEffect(() => {
    if (!provider) {
      const currentProvider = practitioners.find((p) => p.value === currentUser.user_id);
      if (currentProvider) setProvider(currentProvider);
    }
  }, [showConfirm]);

  const onSuccess = async (data) => {
    if (data?.appointment_id)
      navigate(`/portal/charts/${patientId}/clinical-notes/${data.appointment_id}`);
  };

  const onError = (error, code) => {
    if (code === 4) {
      error.map((errorMessage) => showAlert({ message: errorMessage, color: 'danger' }));
      return;
    }

    showAlert({ message: error, color: 'danger' });
  };

  const createNote = async () => {
    setLoading(true);

    try {
      const appointmentToBeCreated = {
        user_id: patientId,
        practitioner_id: provider.value,
        resource_id: null,
        practice_note: null,
        type: null,
        starts_at: moment().tz(currPractice?.timezone).format(),
        ends_at: moment().tz(currPractice?.timezone).add(60, 'minutes').format(),
        location: null,
        form_ids: [],
        procedures: [],
        products: [],
        virtual_link: null,
        enable_notifications: false,
        tag_ids: null
      };

      await requestApi({
        url: '/api/appointment/create',
        params: {
          appointment: appointmentToBeCreated,
          additionalTimes: null,
          appointmentLength: 60,
          checkForExistingAppt: false
        },
        onSuccess,
        onError,
        navigate,
        filePath: `${__dirname}/${__filename}`
      });
    } catch (err) {
      showAlert({ message: 'Appointment creation failed', color: 'danger' });
      Honeybadger.notify(`${filePath(import.meta.url)}, createNote(), err: ${err}`);
    }

    setShowConfirm(false);
    setLoading(false);
  };

  return (
    <Allowed requiredPermissions="clinical_note.create">
      <Button
        text="Create Clinical Note"
        icon="new-add-square"
        iconSize={20}
        iconColor="white"
        size="small"
        onClick={() => setShowConfirm(true)}
      />
      <Confirm
        handleOpen={showConfirm}
        handleClose={() => setShowConfirm(false)}
        handleContinue={() => {
          createNote();
        }}
        disabled={!provider || loading}
        title="Confirm Clinical Note Creation"
        primaryBtnTxt="Yes, Create Clinical Note"
        secondaryBtnTxt="Cancel"
        icon="new-notev2"
        loading={loading}>
        <p className="whitespace-pre-line text-sm text-neutral-600">
          Creating a clinical note will automatically generate an appointment in your calendar based
          on the selected time and provider. This ensures compliance and seamless integration within
          your Myriad Health interface.
        </p>
        <Select
          isImage
          label="Provider"
          placeholder="Type and select a provider"
          parentClassName="mt-2 mb-3"
          inputId="provider"
          data-qa="appointment-practitioner-select"
          options={practitioners}
          value={provider}
          onChange={(e) => {
            setProvider(e);
          }}
        />

        <p className="whitespace-pre-line text-sm font-semibold text-neutral-600">
          Are you sure you want to proceed?
        </p>
      </Confirm>
    </Allowed>
  );
};

export default CreateClinicalNoteButton;
