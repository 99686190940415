import { requestApi } from 'api/Api';

export default async function searchEntity(url, searchTerm, navigate, fieldName) {
  try {
    const res = await requestApi({
      url: `/api/${url}`,
      navigate,
      params: { limit: 25, searchTerm, page: 1 }
    });

    const items = res?.[fieldName];

    if (items) {
      return items.map((item) => {
        if (url.includes('admin')) {
          return { value: item.id, label: `${item.f_name} ${item.l_name}` };
        } else if (url.includes('practice')) {
          return { value: item.id, label: item.name };
        } else {
          return { value: item.id, label: `${item.f_name} ${item.l_name}` };
        }
      });
    }
  } catch (error) {
    console.error('Error searching entity:', error);
    return [];
  }
}
