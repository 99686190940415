import React, { useState, useEffect } from 'react';
import Modal from '../../shared/Modal/Modal';
import Button from '../../shared/Buttons/Button';
import SellPackagePatient from './SellPackagePatient';
import { ia } from '../../../lib/helpers/utility';
import { useLocation, useNavigate } from 'react-router-dom';
import ProceduresProducts from './ProceduresProducts';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { requestApi } from '../../../api/Api';
import Configurations from '../../Payments/Configurations';
import { showAlert } from 'components/shared/Alert/Alert';
import Allowed from 'components/shared/Permissions/Allowed';

const SellValidationSchema = Yup.object().shape({
  patient: Yup.object().required('Patient is required.')
});

const getUniqueProdIds = (data) => {
  const idSums = {};

  data.forEach((item) => {
    const id = item.id;
    const quantity = item.sales_count;

    if (idSums[id]) {
      idSums[id] += quantity;
    } else {
      idSums[id] = quantity;
    }
  });

  return idSums;
};

const SellPackageProductModal = ({ sellModalVisible, setSellModalVisible }) => {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [packages, setPackages] = useState([]);
  const [openPayments, setOpenPayments] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstMount, setFirstMount] = useState(true);
  const location = useLocation();
  const formik = useFormik({
    initialValues: { patient: null, packages: [], products: [] },
    validationSchema: SellValidationSchema,
    onSubmit: (val) => {
      const productsFromPackToBeChecked = val.packages
        .map((pack) => pack.products.map((prod) => ({ id: prod.id, sales_count: prod.quantity })))
        .flat();

      const productsToBeChecked = val.products.map(({ id, sales_count }) => ({
        id,
        sales_count
      }));

      const allProducts = [...productsToBeChecked, ...productsFromPackToBeChecked];
      const procedures = val.packages.map((pack) => pack.procedures.map(({ id }) => id)).flat();

      checkProductAvailability(getUniqueProdIds(allProducts), procedures);
    }
  });

  useEffect(() => {
    getPackages();
    getProducts();
    setFirstMount(false);
  }, []);

  useEffect(() => {
    if (
      !firstMount &&
      (location.pathname === '/portal/settings/packages' ||
        location.pathname === '/portal/settings/products')
    ) {
      setSellModalVisible(false);
    }
  }, [location.pathname]);

  const checkProductAvailability = (products, procedures) => {
    const onSuccess = (data) => {
      setOpenPayments(true);

      const { invoice } = data || {};

      const newInvoice = {
        ...invoice,
        selected: true,
        expanded: true,
        packages: JSON.parse(invoice?.packages),
        products: JSON.parse(invoice?.products),
        balance: invoice?.total_amount
      };

      setInvoice([newInvoice]);

      setLoading(false);
    };

    const onError = (error, code) => {
      if (code == 4) {
        error.map((errorMessage) => showAlert({ color: 'danger', title: errorMessage }));
      }

      setLoading(false);
    };

    setLoading(true);

    const invoice = {
      patient_id: formik?.values?.patient?.id,
      packages: formik?.values?.packages,
      products: formik?.values?.products
    };

    requestApi({
      url: '/api/product/check_availability',
      params: {
        invoice,
        products: { ...products },
        procedures,
        fromSell: true
      },
      onSuccess,
      navigate,
      onError
    });
  };

  const hideSellModal = () => setSellModalVisible(false);

  const continueToInvoice = () => {
    formik.submitForm();
  };

  const getPackages = async () => {
    const onSuccess = (data) => {
      const { packages: loaded_packages } = data;
      if (loaded_packages) {
        setPackages(
          loaded_packages.map((item) => ({
            ...item,
            sales_count: 1,
            total_amount_cents: item.amount_cents
          }))
        );
      }
    };
    requestApi({
      url: '/api/package/get',
      params: { forUsers: true },
      onSuccess,
      navigate
    });
  };

  const getProducts = async () => {
    const onSuccess = (data) => {
      if (ia(data.products)) {
        setProducts(
          data.products.map((prod) => ({
            ...prod,
            sales_count: 1,
            total_amount_cents: prod.amount_cents_with_tax
          }))
        );
      }
    };
    requestApi({ onSuccess, url: '/api/product/get', navigate, params: { forUsers: true } });
  };

  return (
    <Modal
      isLarge={openPayments}
      slideFromRight
      className="w-[600px] bg-white"
      handleClose={hideSellModal}
      handleOpen={sellModalVisible}
      bodyClassName={`!max-h-[unset] !h-full ${openPayments ? '!p-0 ' : '!p-4'}`}
      title="Sell packages & products"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button
            transparent
            onClick={hideSellModal}
            text="Cancel"
            color="neutral"
            outlined
            data-qa="cancel-btn"
          />
          <Button
            text="Continue"
            loadingIcon={loading}
            onClick={continueToInvoice}
            data-qa="continue-from-sell-to-invoice"
            disabled={!ia(formik?.values?.packages) && !ia(formik?.values?.products) && true}
          />
        </div>
      }>
      <Allowed requiredPermissions={['products.read', 'packages.read']} isAny={true}>
        <div className="flex  flex-col !gap-5 bg-white">
          {invoice ? (
            <Configurations
              newInvoice={invoice}
              patientId={formik?.values?.patient?.id}
              onClose={() => setSellModalVisible(false)}
              amount={invoice[0]?.total_amount}
            />
          ) : (
            <>
              <SellPackagePatient {...{ formik }} />

              <ProceduresProducts
                key={products.length + packages.length}
                {...{ products, packages, formik }}
              />
            </>
          )}
        </div>
      </Allowed>
    </Modal>
  );
};

export default SellPackageProductModal;
