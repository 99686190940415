import React, { useMemo, useState } from 'react';
import NotFound from '../components/NotFound';
import WidgetHeader from '../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import AGChart from 'components/shared/AGChart/AGChart';
import { formatLargeNumber, io } from 'lib/helpers/utility';
import NewPatient from 'components/practice/charts/PatientCharts/components/NewPatient';
import useChartSize from 'lib/hooks/useChartSize';

const NewPatients = (props) => {
  const item = { name: 'New Patients' };
  const { dashboardData, layout, item: widgetItem } = props;
  const [showModal, setShowModal] = useState(false);

  const widgetName = widgetItem?.widget;
  const widgetIndex = layout.findIndex((item) => item.widget === widgetName);
  const container = document.getElementsByClassName('react-grid-item')[widgetIndex];

  const { height, width, proportion } = useChartSize(container);

  const formattedData =
    dashboardData?.newPatientGraphData?.map((dataPoint) => {
      const date = new Date(dataPoint.date);
      const startOfWeek = new Date(date);
      startOfWeek.setDate(startOfWeek.getDate() - date.getDay() + 1);
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);
      const startDateString = startOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const endDateString = endOfWeek.toLocaleDateString('en-US', { day: 'numeric' });
      const weekLabel = `${startDateString}-${endDateString}`;
      return {
        ...dataPoint,
        date: weekLabel
      };
    }) ?? [];

  const options = {
    height: height > 0 ? height - proportion * 5 - height / proportion : 0,
    width,
    data: formattedData,
    series: [
      {
        type: 'bar',
        xKey: 'date',
        yKey: 'newPatients',
        fill: '#13B9FF',
        stroke: '#004F6B',
        cornerRadius: 15
      }
    ],
    axes: [
      {
        type: 'cateasdgory',
        nice: true,
        position: 'bottom'
      },
      {
        position: 'left'
      }
    ],
    theme: 'ag-material'
  };

  const chartKey = useMemo(() => `${height}-${width}`, [height, width]);

  return (
    <div>
      <WidgetHeader icon="new-user-circle-add" item={item} hasPlus={false} {...props} />
      {io(dashboardData?.newPatientGraphData) ? (
        <div>
          <div className="!px-5 pt-[14px]">
            <div className="text-md text-neutral-500">Total </div>
            <div className="text-2xl font-bold text-primary-900">
              {formatLargeNumber(dashboardData?.count?.newPatients)}
            </div>
          </div>
          <AGChart options={options} key={chartKey} />
        </div>
      ) : (
        <>
          <NotFound
            icon="new-illustration-6"
            title="You don't have any patients yet"
            description="You can start creating new patients right now."
            createText="Create new patient"
            onCreate={() => setShowModal(true)}
          />
          <NewPatient newPatientModal={showModal} hideNewPatientModal={() => setShowModal(false)} />
        </>
      )}
    </div>
  );
};

export default NewPatients;
