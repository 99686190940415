import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { createPracticeOwner } from 'api/Practice';

import { showAlert } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';

import InviteByEmail from './InviteByEmail';
import PortalUsers from './PortalUsers';
import PracticeUsers from './PracticeUsers';

const SelectUserType = forwardRef(({ setAddOwnerModalVisible }, ref) => {
  const selectedUserRef = useRef();
  const [view, setView] = useState();
  const [selectedOwner, setSelectedOwner] = useState();
  const navigate = useNavigate();
  const { practice } = useOutletContext();
  const queryClient = useQueryClient();

  useImperativeHandle(ref, () => ({
    convertToOrganization:
      view === 'by-email' ? selectedUserRef.current?.inviteOwner : handleCreateOrganization
  }));

  const handleCreateOrganization = async () => {
    if (!selectedOwner) {
      showAlert({
        color: 'danger',
        message: 'Please select a user to convert to an organization!'
      });
      return;
    }
    const params = { practice_id: practice.id, user_id: selectedOwner?.id };
    const { code } = await createPracticeOwner(navigate, params);
    if (code === 0) {
      showAlert({
        color: 'success',
        message: 'Organization created successfully'
      });
      queryClient.invalidateQueries(['practice']);
      setAddOwnerModalVisible(false);
    }
  };

  return (
    <>
      {view ? (
        <div
          className="cursor-pointer flex items-center gap-2 ml-6 mt-3"
          onClick={() => setView(null)}>
          <Icon icon="new-arrow-left" shade={900} color="primary" stroke />
          <span className="font-500 text-primary-900">Go Back</span>
        </div>
      ) : (
        <>
          <div className="p-4 flex flex-col gap-4">
            <div
              className="hover:bg-primary-50 p-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('practice')}>
              <p className="text-primary-600 font-500">Select owner from practice users</p>
            </div>
            <div
              className="hover:bg-primary-50 p-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('portal')}>
              <p className="text-primary-600 font-500">Select owner from all users</p>
            </div>
            <div
              className="hover:bg-primary-50 p-4 rounded-md bg-white cursor-pointer shadow-sm flex justify-center items-center w-full"
              onClick={() => setView('by-email')}>
              <p className="text-primary-600 font-500">Invite owner by email</p>
            </div>
          </div>
        </>
      )}
      {view === 'practice' && <PracticeUsers {...{ setSelectedOwner, selectedOwner }} />}
      {view === 'portal' && <PortalUsers {...{ setSelectedOwner, selectedOwner }} />}
      {view === 'by-email' && <InviteByEmail ref={selectedUserRef} />}
    </>
  );
});

export default SelectUserType;
