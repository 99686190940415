import { formatDate } from 'lib/helpers/utility';

export const cols = [
  { field: 'id', headerName: 'ID', maxWidth: 100 },
  { field: 'name', headerName: 'Name', minWidth: 100 },
  { field: 'label', headerName: 'Label' },
  { field: 'Created At', headerName: 'Created At' }
];

export const DEFAULT_FILTERS = {
  created_at: {
    values: {
      startDate: null,
      endDate: null
    },
    type: 'date-range',
    title: 'AI Task Date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  }
};

export const DEFAULT_COLUMN_DEFS = {
  enableRowGroup: false
};
export const GRID_OPTIONS = {
  animateRows: true,
  rowGroupPanelShow: 'never',
  enableRangeSelection: true,
  // showOpenedGroup: true,
  suppressAggFuncInHeader: true,
  autoGroupColumnDef: {
    pinned: 'left',
    sortable: false
  }
};
