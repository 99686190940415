import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { renameFax } from 'api/Fax';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import { showAlert } from 'components/shared/Alert/Alert';

const RenameFaxModal = ({
  renameFaxModalVisible,
  setRenameFaxModalVisible,
  id,
  fax_name,
  refetchFaxes
}) => {
  const [name, setName] = useState(fax_name);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleRenameFax = async () => {
    setLoading(true);
    const { code } = await renameFax(navigate, { id, name });
    if (code == 0) {
      await refetchFaxes();
      setRenameFaxModalVisible(false);
      showAlert({ color: 'success', message: 'Fax renamed successfully' });
    } else {
      showAlert({ color: 'error', message: 'Error renaming fax' });
    }
    setLoading(false);
  };

  return (
    <Modal
      handleOpen={!!renameFaxModalVisible}
      handleClose={() => setRenameFaxModalVisible(false)}
      title="Fax name"
      bodyClassName={'!p-0'}
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setRenameFaxModalVisible(false)}
          />
          <Button
            data-qa="send-fax-btn"
            text="Rename"
            onClick={handleRenameFax}
            loading={loading}
          />
        </div>
      }>
      <div className="!p-4">
        <Input
          name="name"
          onChange={(e) => setName(e.target.value)}
          value={name}
          placeholder="Enter fax name"
          label="Fax name"
        />
      </div>
    </Modal>
  );
};

export default RenameFaxModal;
