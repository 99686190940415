import React, { useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { capitalize } from 'lodash';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';

import { Capitalize, ia, optionify } from 'lib/helpers/utility';

import { getCurrentOption } from 'components/Staff/lib/helpers';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Select from 'components/shared/Select/Select';
import state from 'components/state';

export default function EditPractice({
  kind,
  enroll,
  setEnrroll,
  practiceId,
  setAllowedToUpdate,
  buttonDisabled,
  setButtonDisabled,
  status,
  stateList,
  formRef,
  formik,
  formikHandle,
  specialtyTypes
}) {
  const [releaseEFaxNumber, setReleaseEFaxNumberModal] = useState(false);
  const professionGroupOptions = useRecoilValue(state.professionGroupOptions);
  const queryClient = useQueryClient();

  const specialtyCodeLabels =
    ia(formik.values.specialty_code) &&
    formik.values.specialty_code.map((code) => {
      return {
        label: Capitalize(specialtyTypes.find((s) => s.value === code)?.label),
        value: code
      };
    });

  const findStateName = () => {
    const state = stateList?.find((state) => {
      if (state?.id == formik?.values?.state) {
        return state;
      }
    });

    return state && state?.name;
  };
  const enrrollEFaxNumber = async () => {
    try {
      const enrollEFaxNumber = await requestApi({
        url: '/api/faxUser/enroll',
        params: {
          practiceId
        }
      });
      if (enrollEFaxNumber.code == 0) {
        setEnrroll(false);
        setAllowedToUpdate(false);
        queryClient.invalidateQueries(['practice']);
        formik.setFieldValue('e_fax', formik.values.e_fax);
      } else {
        console.error('Failed to enroll e-fax number');
      }
    } catch (error) {
      console.error('Error enrolling e-fax number:', error);
    }
  };
  const handleReleaseEFax = async () => {
    try {
      const removeEFaxNumber = await requestApi({
        url: '/api/faxUser/release',
        params: {
          practiceId,
          faxNumber: formik.values.e_fax
        }
      });
      if (removeEFaxNumber.code == 0) {
        setButtonDisabled(true);
        queryClient.invalidateQueries(['practice']);
        formik.setFieldValue('e_fax', '');
      } else if (removeEFaxNumber.code == 1) {
        showAlert({
          title: "Couldn't find e-fax number to release.Try by updating it directly",
          color: 'danger'
        });
        setAllowedToUpdate(true);
      } else {
        console.error('Failed to release e-fax number');
      }
    } catch (error) {
      console.error('Error releasing e-fax number:', error);
    }
  };
  return (
    <form ref={formRef} className="flex flex-col !gap-5">
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
        <Input
          data-qa="practice-name"
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formikHandle}
          error={formik.touched.ame && formik.errors.name}
        />
        <Select
          inputId="type"
          label="Type"
          options={kind}
          placeholder="Kind"
          onChange={(v) => formik.setFieldValue('kind', v?.value)}
          value={formik.values.kind ? { label: Capitalize(formik.values.kind) } : null}
          isMulti={false}
        />
        <Select
          label="Status"
          inputId="status"
          options={status}
          placeholder="Status"
          onChange={(v) => formik.setFieldValue('status', v?.value)}
          value={formik.values.status ? { label: Capitalize(formik.values.status) } : null}
          isMulti={false}
        />
        <Select
          parentClassName="col-span-3"
          label="Billing Specialties"
          inputId="specialties"
          options={specialtyTypes}
          placeholder="Specialties"
          onChange={(codes) => {
            formik.setFieldValue(
              'specialty_code',
              codes.map((code) => code?.value || code)
            );
          }}
          value={specialtyCodeLabels}
          isMulti={true}
        />
        <Select
          parentClassName="col-span-3"
          label="Specialty"
          inputId="profession_group_id"
          options={professionGroupOptions}
          placeholder="Specialties"
          onChange={(codes) => {
            formik.setFieldValue('profession_group_id', codes?.value || null);
          }}
          value={getCurrentOption(
            professionGroupOptions,
            Number(formik?.values?.profession_group_id)
          )}
          isMulti={false}
        />
      </div>

      <div>
        <span className="!mb-3 block text-lg font-500">ClaimMD</span>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <Input
            data-qa="practice-npi"
            label="Group NPI"
            name="npi"
            value={formik.values.npi}
            onChange={formikHandle}
            error={formik.touched.npi && formik.errors.npi}
          />

          <Input
            data-qa="practice-taxonomy"
            label="Taxonomy"
            name="taxonomy"
            value={formik.values.taxonomy}
            onChange={formikHandle}
            error={formik.touched.taxonomy && formik.errors.taxonomy}
          />

          <Input
            data-qa="practice-tax-id"
            label="Tax ID"
            name="tax_id"
            value={formik.values.tax_id}
            onChange={formikHandle}
            error={formik.touched.tax_id && formik.errors.tax_id}
          />

          <Input
            data-qa="practice-tax-id-type"
            label="Tax ID Type (E or S)"
            name="tax_id_type"
            value={formik.values.tax_id_type}
            onChange={formikHandle}
            error={formik.touched.tax_id_type && formik.errors.tax_id_type}
          />

          <Input
            data-qa="pracice-api-key"
            label="ClaimMD API Key"
            name="claim_md_account_key"
            value={formik.values.claim_md_account_key}
            onChange={formikHandle}
            error={formik.touched.claim_md_account_key && formik.errors.claim_md_account_key}
          />
        </div>
      </div>

      <div>
        <span className="!mb-3 block text-lg font-500">LabSoft</span>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <Input
            data-qa="practice-labsoft-cust-id"
            label="Customer ID"
            name="labsoft_cust_id"
            value={formik?.values?.labsoft_cust_id}
            onChange={formikHandle}
            error={formik.touched.labsoft_cust_id && formik.errors.labsoft_cust_id}
          />
        </div>
      </div>

      <div>
        <span className="!mb-3 block text-lg font-500">Contact Information</span>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <Input
            data-qa="practice-email"
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formikHandle}
            error={formik.touched.email && formik.errors.email}
          />

          <Input
            data-qa="practice-phone-number"
            label="Phone Number"
            name="phone"
            value={formik.values.phone}
            onChange={formikHandle}
            error={formik.touched.phone && formik.errors.phone}
          />

          <Input
            data-qa="practice-twilio-number"
            label="Twilio Number"
            name="twilio_phone"
            value={formik.values.twilio_phone}
            onChange={formikHandle}
            error={formik.touched.twilio_phone && formik.errors.twilio_phone}
          />

          <Input
            data-qa="practice-fax-number"
            label="Fax Number"
            name="fax"
            value={formik.values.fax}
            onChange={formikHandle}
            error={formik.touched.fax && formik.errors.fax}
          />
          <Input
            data-qa="practice-e-fax-number"
            label="E-fax Number"
            name="e_fax"
            value={formik.values.e_fax}
            onChange={formikHandle}
            error={formik.touched.e_fax && formik.errors.e_fax}
          />

          <Button
            className="mt-[26px] h-10"
            color="primary"
            disabled={buttonDisabled}
            onClick={() => {
              enroll ? enrrollEFaxNumber() : setReleaseEFaxNumberModal(true);
            }}
            text={enroll ? 'Enroll to e-fax' : 'Release e-fax Number'}
          />
        </div>
      </div>

      <div>
        <span className="!mb-3 block text-lg font-500">Limits</span>
        <table className="primary-table">
          <thead>
            <tr>
              <th></th>
              <th colSpan="2" className="text-center">
                Current Month
              </th>
              <th colSpan="2"></th>
            </tr>
            <tr>
              <th></th>
              <th className="text-center">Limit</th>
              <th className="text-center">Usage</th>
              <th className="text-center">Default</th>
            </tr>
          </thead>
          <tbody>
            {formik.values.limits_usage?.map((limit, index) => (
              <tr key={index}>
                <td>{capitalize(limit.serviceKind)}:</td>
                <td>
                  <Input
                    name={`limits_usage[${index}].usage_limit`}
                    value={limit.usage_limit}
                    onChange={formik.handleChange}
                  />
                </td>
                <td>
                  <Input
                    name={`limits_usage[${index}].usage_counter`}
                    value={limit.usage_counter}
                  />
                </td>
                <td>
                  <Input
                    name={`limits_usage[${index}].limit`}
                    value={limit.limit}
                    onChange={formik.handleChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <span className="!mb-3 block text-lg font-500">Address</span>
        <div className="grid grid-cols-3 gap-4 sm:grid-cols-1 md:grid-cols-2">
          <Input
            label="Address"
            data-qa="practice-address-1"
            type="text"
            placeholder="Address"
            name="address_ln_1"
            value={formik.values.address_ln_1}
            onChange={formikHandle}
          />

          <Input
            label="Address 2"
            data-qa="practice-address-2"
            type="text"
            placeholder="Address 2"
            name="address_ln_2"
            value={formik.values.address_ln_2}
            onChange={formikHandle}
          />
          <Input
            label="City"
            data-qa="practice-city"
            type="text"
            placeholder="City"
            name="city"
            value={formik.values.city}
            onChange={formikHandle}
          />
          <Select
            label="State"
            inputId="state-select"
            placeholder="Type and select"
            name="state"
            onChange={(value) => formik.setFieldValue('state', value?.value)}
            value={formik?.values?.state ? { label: findStateName() } : null}
            options={optionify(stateList, 'name', 'id')}
            error={formik.errors.state}
            menuPlacement="top"
          />
          <Input
            label="Zip"
            data-qa="practice-zip"
            type="text"
            placeholder="Zip"
            name="zip"
            value={formik.values.zip}
            onChange={formikHandle}
          />
          <Input
            label="License ID"
            data-qa="practice-veradigm-license"
            type="text"
            placeholder="Veradigm License ID"
            name="veradigm_license_id"
            value={formik.values.veradigm_license_id}
            onChange={formikHandle}
          />
          <Input
            label="Office ID"
            data-qa="practice-veradigm-office"
            type="text"
            placeholder="Veradigm Office ID"
            name="veradigm_office_id"
            value={formik.values.veradigm_office_id}
            onChange={formikHandle}
          />
        </div>
      </div>

      {!!releaseEFaxNumber && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Release"
          title="Are you sure you want to release this e-fax number?"
          icon="new-document-remove-red"
          message="Releasing this number will be lost permanently?"
          handleContinue={() => {
            handleReleaseEFax();
            setReleaseEFaxNumberModal(false);
          }}
          handleOpen={!!releaseEFaxNumber}
          handleClose={() => setReleaseEFaxNumberModal(false)}
        />
      )}
    </form>
  );
}
