import { getFaxesCSV, updateFax } from 'api/Fax';
import { currentPractice } from 'components/practice/practiceState';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { mapValues } from 'lib/helpers/utility';
import { useDraftFaxes } from 'lib/hooks/queries/fax/useDraftFaxes';
import { usePatients } from 'lib/hooks/queries/patients/usePatients';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import SendFaxModal from '../components/SendFaxModal/SendFaxModal';
import NewFaxesTable from '../NewFaxesTable';
import { DEFAULT_COLUMN_DEFS, DEFAULT_FILTERS, getColDefs, GRID_OPTIONS } from './configs';
import { useTags } from 'lib/hooks/queries/tags/useTags';
import { isEqual } from 'lodash';
import { showAlert } from 'components/shared/Alert/Alert';
import AddTag from '../components/AddTag';
import { useFaxContext } from 'lib/context/FaxContext/FaxContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const NewDraftFaxes = () => {
  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;
  return (
    <TableContextProvider
      name="drafts_faxes"
      defaultFilters={DEFAULT_FILTERS}
      cols={getColDefs(practiceTimezone)}
      pagination>
      <DraftFaxesTable />
    </TableContextProvider>
  );
};

function DraftFaxesTable() {
  const category = 'draft_faxes';
  const kind = 'fax';

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const currPractice = useRecoilValue(currentPractice);
  const practiceTimezone = currPractice?.timezone;

  const [draftFax, setDraftFax] = useState(null);
  const [sendFaxModalVisible, setSendFaxModalVisible] = useState(false);

  const { limit, page, sort, filters } = useTableContext();
  const {
    openTagModal,
    setOpenTagModal,
    faxTags,
    faxId,
    prevTags,
    setDisableAdd,
    setFaxTags,
    setPrevTags
  } = useFaxContext();

  const { data, isFetching, isLoading } = useDraftFaxes({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      category
    },
    dependencies: [limit, page, sort, mapValues(filters)]
  });

  const draftFaxes = data?.draftFaxes || [];
  const count = data?.count || 0;

  const practice = useRecoilValue(currentPractice);
  const { data: patientsData } = usePatients({
    params: { practiceId: practice.id }
  });
  const patients = patientsData?.patients;

  const { data: tagsList } = useTags({ params: { kind }, dependencies: [kind] });

  const patientOptions = useMemo(() => {
    if (patients?.length > 0) {
      return patients.map((patient) => ({
        id: patient.user_id,
        label: patient?.f_name + ' ' + patient?.l_name,
        value: patient?.id
      }));
    }
    return [];
  }, [patients]);
  filters.patients.options = patientOptions;

  const tagOptions = useMemo(() => {
    if (tagsList?.tags?.length > 0) {
      return tagsList?.tags.map((t) => ({
        label: t?.name,
        value: t?.id
      }));
    }
    return [];
  }, [tagsList]);
  filters.tags.options = tagOptions;

  const onOpen = (fax) => {
    setSendFaxModalVisible(true);
    setDraftFax({ ...fax, tag_ids: fax?.tags });
  };

  const onRowClicked = (clickedFax) => {
    onOpen(clickedFax);
  };

  const onCellClicked = (e) => {
    if (['actions', '0', 'tags'].includes(e?.column?.colId)) return;
    onRowClicked(e.data);
  };

  const onExportCSV = async () => {
    const data = await getFaxesCSV(navigate, {
      filters: mapValues(filters),
      colDefs: getColDefs(practiceTimezone),
      sort,
      category,
      type: 'drafts'
    });
    const a = document.createElement('a');
    a.href = data?.url;
    a.download = 'faxes.csv';
    a.click();
  };

  const { mutateAsync: mutateUpdateFax } = useMutation({
    mutationFn: (data) => updateFax(navigate, data)
  });

  const handleAddTag = async () => {
    if (isEqual(prevTags, faxTags)) return;
    setDisableAdd(false);
    await mutateUpdateFax(
      { faxId, fields: { tag_ids: faxTags } },
      {
        onSuccess: ({ code, error }) => {
          if (code === 0) {
            queryClient.invalidateQueries(['draftFaxes']);
            showAlert({
              title: 'Fax updated successfully',
              color: 'success'
            });
          } else {
            showAlert({
              title: 'Fax update failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
          }
        }
      }
    );
    setFaxTags([]);
    setPrevTags([]);
    setOpenTagModal(false);
  };

  return (
    <div className="h-full" data-dd-privacy="allow">
      <NewFaxesTable
        onExportCSV={onExportCSV}
        data={draftFaxes}
        category={category}
        name="Draft Faxes"
        defaultColumnDefs={DEFAULT_COLUMN_DEFS}
        gridOptions={GRID_OPTIONS}
        defaultFilters={DEFAULT_FILTERS}
        loading={isFetching || isLoading}
        onCellClicked={onCellClicked}
        count={count}
      />
      {sendFaxModalVisible && (
        <SendFaxModal
          {...{ sendFaxModalVisible, setSendFaxModalVisible, faxData: draftFax, isDraft: true }}
        />
      )}
      {openTagModal && (
        <AddTag
          openTagModal={openTagModal}
          setOpenTagModal={setOpenTagModal}
          handleAddTag={handleAddTag}
        />
      )}
    </div>
  );
}

export default NewDraftFaxes;
