import { Popover } from '@headlessui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { noKnownPatientAggregateNarrative } from 'api/PatientAggregateNarrative';
import cs from 'classnames';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { handleNoKnown } from './lib/noteHeaderHelpers';

const NoteHeader = ({ title, children }) => {
  const { setOverviewData } = useClinicalNoteContext();
  const [noKnownModal, setNoKnownModal] = useState(false);

  const { id: patientId } = useParams();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const noKnownState = queryClient.getQueryData([
    'patientAggregateNarrative',
    patientId,
    camelCase(title)
  ])?.data?.narrative?.no_known;

  // const filledFormOptions = [
  //   {
  //     icon: 'new-deceased-patient',
  //     label: 'View patient submissions',
  //     onClick: () => {}
  //   },
  //   {
  //     icon: 'user-square',
  //     label: 'View provider submissions',
  //     onClick: () => {}
  //   }
  // ];

  const { mutateAsync: mutateNoKnown, isLoading: noKnownLoading } = useMutation({
    mutationFn: (data) => noKnownPatientAggregateNarrative(navigate, data)
  });

  return (
    <div className="flex items-center border border-l-0 border-r-0 border-t-0 border-solid border-neutral-100">
      <div
        className="w-[300px] bg-white px-6 py-3.5 shadow-[4px_0px_16px_0px_#004F6B0A]"
        // style={{ boxShadow: '4px 0px 16px 0px #004F6B0A' }}
      >
        <span className="px-5 text-base font-500 text-neutral-800">{title}</span>
      </div>
      <div className="flex w-full items-center justify-between !px-4">
        <div></div>
        {/* <Popover className="relative">
          {({ open, close }) => (
            <div className="w-full">
              <Popover.Button className="w-full cursor-pointer p-0">
                <div className="flex items-center gap-2 py-4">
                  <Icon icon="new-user-arrow-right" shade={700} color="primary" />
                  <span className="text-sm font-500 text-primary-700">Previously filled form</span>
                  <Icon
                    icon="new-chevron-down"
                    className={cs(
                      'transition-all',
                      open ? 'rotate-180 transform' : 'rotate-0 transform'
                    )}
                    shade={700}
                    color="primary"
                  />
                </div>
              </Popover.Button>

              <Popover.Panel className="absolute left-[-12px] z-10 flex w-max rounded bg-white shadow-md">
                <div className="my-2">
                  {filledFormOptions.map(({ icon = '', label = '', onClick = () => null }, key) => {
                    return (
                      <div
                        onClick={onClick}
                        key={key}
                        className="flex items-center justify-between gap-3 px-4 py-[6px] transition-all hover:bg-primary-50">
                        <div className="flex gap-2">
                          <Icon icon={icon} shade={500} color="primary" />
                          <span className="text-sm font-400 text-primary-900">{label}</span>
                        </div>
                        <div className="flex min-h-5 min-w-5 items-center justify-center rounded border border-solid border-primary-200 bg-primary-50 text-xs text-primary-500">
                          3
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Popover.Panel>
            </div>
          )}
        </Popover> */}

        <div className="flex items-center gap-2">
          <Checkbox
            isChecked={noKnownState}
            disabled={noKnownLoading}
            label={`No Known ${title}`}
            handleClick={() => setNoKnownModal(true)}
          />
          {children}
        </div>
      </div>

      <Confirm
        handleOpen={!!noKnownModal}
        handleClose={() => setNoKnownModal(false)}
        handleContinue={() =>
          handleNoKnown({
            mutateNoKnown,
            queryClient,
            patientId,
            title: camelCase(title),
            noKnownState,
            setNoKnownModal,
            setOverviewData
          })
        }
        title={`No Known ${title}`}
        icon={noKnownState ? 'new-document' : 'new-document-remove-red'}
        loading={noKnownLoading}
        disabled={noKnownLoading}
        message={
          noKnownState ? (
            <div>
              Are you sure you want to unselect "No Known"? This will remove the "No Known" status
              from the patient's chart.
            </div>
          ) : (
            <div>
              Are you sure you want to mark as 'No Known'?{' '}
              <em className="text-danger-500">
                {' '}
                This will remove any existing {title?.toLowerCase()} from the patient's chart.
              </em>
            </div>
          )
        }
        variant={noKnownState ? 'primary' : 'danger'}
      />
    </div>
  );
};

export default NoteHeader;
