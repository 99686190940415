import { ia } from 'lib/helpers/utility';
import describeUrl from './describeUrl';

function getNameById(array, id) {
  if (ia(array) && array.length > 0) {
    let user = array?.find((item) => item?.id === id);
    return user ? `${user.f_name} ${user.l_name}` : '';
  }
}

export default function mapLogsData(logs, useUsers, useAdminUsers) {
  const userIds = [...new Set(logs?.map((log) => log.user_id).filter(Boolean))];
  const adminIds = [...new Set(logs?.map((log) => log.admin_id).filter(Boolean))];

  const { data: userData } = useUsers({ params: { userIds }, dependencies: [userIds] });
  const { data: adminUserData } = useAdminUsers({
    params: { userIds: adminIds },
    dependencies: [adminIds]
  });

  return logs?.map((item) => ({
    id: item.id,
    description: describeUrl(item.url),
    user_id: getNameById(userData?.users, item.user_id),
    admin_id: getNameById(adminUserData?.users, item.admin_id),
    practice_id: item.practice_id,
    url: item.url,
    ip: item.ip,
    date: item.date,
    details: item.id
  }));
}
