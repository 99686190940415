import { getProviders } from 'api/Member';
import { getPatients } from 'api/Patients';
import { getPayers } from 'api/Payer';

import { formatDate, mString } from 'lib/helpers/utility';

import Badge from 'components/shared/Badge/Badge';

import Actions from '../components/Actions';

export const COLUMN_DEFS = [
  {
    field: 'paid_date',
    headerName: 'Payment Date',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'check_number',
    headerName: 'Check/Trace #',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'payer_name',
    headerName: 'Payer',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'claims_length',
    headerName: 'Claims',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1
  },
  {
    field: 'unmatched_claims_length',
    headerName: 'Unmatched Claims',
    cellClass: 'no-border',
    aggFunc: 'sum',
    flex: 1
  },
  {
    field: 'paid_amount',
    headerName: 'Amount Paid',
    cellClass: 'no-border',
    valueFormatter: (params) => {
      return mString(params.value);
    },
    aggFunc: 'sum',
    flex: 1
  },
  {
    field: 'payment_method',
    headerName: 'Payment Method',
    cellClass: 'no-border',
    flex: 1
  },
  {
    field: 'finalized',
    headerName: 'Status',
    cellClass: 'no-border',
    flex: 1,
    cellRenderer: (params) => {
      const isGrouped = params.node.group;
      const groupedColumns = params.columnApi.getRowGroupColumns();
      const isFinalizedGrouped = groupedColumns.some((col) => col.getColId() === 'finalized');

      if (!isGrouped || isFinalizedGrouped) {
        return (
          <div className="flex items-center h-full">
            <Badge
              text={params.value ? 'Finalized' : 'Pending'}
              color={params.value ? 'success' : 'warning'}
            />
          </div>
        );
      }

      return null;
    }
  },
  {
    field: 'actions',
    headerName: '',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];

export const INITIAL_FILTERS = {
  created_at: {
    values: null,
    type: 'date-range',
    title: 'Created Date',
    placeholder: 'Select start and end date',
    preview: (values) => `${formatDate(values.startDate)} - ${formatDate(values.endDate)}`
  },
  patient: {
    type: 'infinite-scroll',
    values: [],
    queryFn: getPatients,
    queryKey: 'patients',
    multiple: true,
    title: 'Patient',
    placeholder: 'Search patient',
    formatData: (data) => data.map((patient) => ({ label: patient?.fullName, value: patient?.id }))
  },
  status: {
    type: 'search',
    multiple: true,
    options: [
      {
        label: 'Pending',
        value: 'pending'
      },
      {
        label: 'Finalized',
        value: 'finalized'
      }
    ],
    values: null,
    title: 'Status',
    placeholder: 'Select status',
    preview: (values) => values.map((v) => v.label).join(', ')
  },
  era: {
    type: 'search',
    values: null,
    title: 'ERA',
    placeholder: 'Search era number'
  },
  check_number: {
    type: 'search',
    values: null,
    title: 'Check/Track number',
    placeholder: 'Search check/track number'
  },
  claim: {
    type: 'search',
    values: null,
    title: 'Claim',
    placeholder: 'Search claim or claim md number'
  },
  npi: {
    type: 'search',
    values: null,
    title: 'Prov/Bill NPI',
    placeholder: 'Search provider/bill npi'
  },
  payer: {
    type: 'infinite-scroll',
    queryFn: getPayers,
    queryKey: 'payers',
    multiple: true,
    values: [],
    title: 'Payer',
    placeholder: 'Search payer',
    formatData: (data) => data.map((payer) => ({ label: payer?.name, value: payer?.id }))
  },
  provider: {
    type: 'infinite-scroll',
    multiple: true,
    queryFn: getProviders,
    queryKey: 'practitioners',
    values: [],
    title: 'Provider',
    placeholder: 'Search provider',
    formatData: (data) =>
      data.map((practitioner) => ({
        label: `${practitioner?.f_name} ${practitioner?.l_name}`,
        value: practitioner?.id
      }))
  }
};

export const ERA_SWITCH_ITEMS = [
  {
    label: 'All',
    value: null
  },
  {
    label: 'Finalized',
    value: 'finalized'
  },
  {
    label: 'Pending',
    value: 'pending'
  }
];
