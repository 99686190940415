import React from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import { useEobs } from 'lib/hooks/queries/eob/useEobs';

import AgTablePage from 'components/shared/AgTablePage/AgTablePage';

import NewEOB from './components/NewEOB/NewEOB';
import { COLUMN_DEFS, EOB_SWITCH_ITEMS, INITIAL_FILTERS } from './lib/helpers';

const Eob = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <>
      <AgTablePage
        ModalComponent={NewEOB}
        defaultColumns={COLUMN_DEFS}
        defaultFilters={INITIAL_FILTERS}
        useData={useEobs}
        rowStyle={{ cursor: 'pointer' }}
        onSelectRow={(eob) => navigate(`${eob?.id}`)}
        name="eob"
        switchItems={EOB_SWITCH_ITEMS}
      />
      {id && <Outlet />}
    </>
  );
};

export default Eob;
