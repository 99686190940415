export function generateProgressList(cnDisplaySettings, advancedSoapTitles) {
  return {
    goals: cnDisplaySettings?.sections?.progress?.goals?.enabled && {
      title: 'Goals',
      path: 'goals',
      position: cnDisplaySettings?.sections?.progress?.goals?.position
    },
    session: cnDisplaySettings?.sections?.progress?.session?.enabled && {
      title: 'Session',
      path: 'session',
      position: cnDisplaySettings?.sections?.progress?.session?.position
    },
    riskFactors: cnDisplaySettings?.sections?.progress?.riskFactors?.enabled && {
      title: 'Risk Factors: SI, HI, Other',
      path: 'risk-factors',
      position: cnDisplaySettings?.sections?.progress?.riskFactors?.position
    },
    sessionQuotesAndContents: cnDisplaySettings?.sections?.progress?.sessionQuotesAndContents
      ?.enabled && {
      title: 'Session Quotes and Contents',
      path: 'session-quotes-and-contents',
      position: cnDisplaySettings?.sections?.progress?.sessionQuotesAndContents?.position
    },
    activeSymptoms: cnDisplaySettings?.sections?.progress?.activeSymptoms?.enabled && {
      title: 'Active Symptoms with necessity for continued treatment',
      path: 'active-symptoms',
      position: cnDisplaySettings?.sections?.progress?.activeSymptoms?.position
    },
    functionalImpairment: cnDisplaySettings?.sections?.progress?.functionalImpairment?.enabled && {
      title: 'Functional Impairment',
      path: 'functional-impairment',
      position: cnDisplaySettings?.sections?.progress?.functionalImpairment?.position
    },
    vitals: cnDisplaySettings?.sections?.progress?.vitals?.enabled && {
      title: 'Vitals',
      path: 'vitals',
      position: cnDisplaySettings?.sections?.progress?.vitals?.position
    },
    interventions: cnDisplaySettings?.sections?.progress?.interventions?.enabled && {
      title: 'Interventions',
      path: 'interventions',
      position: cnDisplaySettings?.sections?.progress?.interventions?.position
    },
    progress: cnDisplaySettings?.sections?.progress?.progress?.enabled && {
      title: 'Progress',
      path: 'progress',
      position: cnDisplaySettings?.sections?.progress?.progress?.position
    },
    ...advancedSoapTitles,
    cptCodes: cnDisplaySettings?.sections?.progress?.cptCodes?.enabled && {
      title: 'CPT® Codes',
      path: 'cpt-codes',
      position: cnDisplaySettings?.sections?.progress?.cptCodes?.position
    },
    diagnosisCodes: cnDisplaySettings?.sections?.progress?.diagnosisCodes?.enabled && {
      title: 'Diagnosis Codes',
      path: 'diagnosis-codes',
      position: cnDisplaySettings?.sections?.progress?.diagnosisCodes?.position
    },
    notes: cnDisplaySettings?.sections?.progress?.notes?.enabled && {
      title: 'General Notes',
      path: 'notes',
      position: cnDisplaySettings?.sections?.progress?.notes?.position
    },
    orders: cnDisplaySettings?.sections?.progress?.orders?.enabled && {
      title: 'Orders',
      path: 'orders',
      position: cnDisplaySettings?.sections?.progress?.orders?.position
    }
  };
}
