import React from 'react';
import { ReactFormBuilder } from 'react-form-builder2';
import { FormBuilderToolbar } from 'components/shared/Forms/Custom/Register';

export default function NewFormBuilder({ onSubmit, onFormChange }) {
  return (
    <div className="block">
      <ReactFormBuilder
        onPost={(e) => onFormChange(e.task_data)}
        toolbarItems={FormBuilderToolbar}
      />
    </div>
  );
}
