import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getNotifications } from '../../../../api/Notifications';

export const useNotifications = ({
  queryKey = 'notifications',
  params = {},
  options = {},
  dependencies = []
}) => {
  const navigate = useNavigate();

  return useQuery([queryKey, ...dependencies], () => getNotifications(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    staleTime: 5000,
    ...options
  });
};
