import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { optionify } from '../../../../lib/helpers/utility';
import Button from '../../../shared/Buttons/Button';
import Loading from '../../../shared/Loading/Loading';
import Modal from '../../../shared/Modal/Modal';
import state from '../../../state';
import HeaderTable from './HeaderTable';
import NewService from './NewService';
import ServiceEntry from './ServiceEntry';
import ServiceTable from './ServiceTable';

const LIMIT = 500;

const Services = () => {
  const [serviceToBeUpdated, setServiceToBeUpdated] = useState(null);
  const [procedure_code_options, setProcedureCodeOptions] = useRecoilState(
    state.procedureCodeOptionsState
  );
  const [filters, setFilters] = useState({
    searchTerm: '',
    page: 1,
    limit: 10,
    column: 'name',
    sort: 'asc'
  });
  const [procedure_code_map, setProcedureCodeMap] = useRecoilState(state.procedureCodeMapState);
  const [showNewService, setShowNewService] = useState(false);
  const serviceFormRef = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ['getServices', filters.searchTerm, filters.page, filters.limit],
    queryFn: getServices,
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });
  const services = data?.services;
  const serviceCount = data?.serviceCount;

  useEffect(() => {
    getProcedures();
  }, []);

  const hideNewService = () => {
    setServiceToBeUpdated(null);
    setShowNewService(false);
  };

  async function getServices() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      navigate,
      params: {
        offset: 0,
        limit: LIMIT,
        getPayers: true,
        filters,
        withCount: true,
        forPractice: true
      }
    });
    return response;
  }

  const getProcedures = async () => {
    const onSuccess = (data) => {
      if (Array.isArray(data.procedure_codes)) {
        let local_procedure_map = new Map();
        let local_procedure_code_options = optionify(data.procedure_codes, 'name', 'code');
        setProcedureCodeOptions(local_procedure_code_options);
        local_procedure_code_options.forEach((v) => local_procedure_map.set(v.value, v.label));
        setProcedureCodeMap(local_procedure_map);
      }
    };
    requestApi({
      url: '/api/admin/codes/get',
      onSuccess,
      navigate,
      params: {
        service: 'benefit_type_code',
        limit: 9000,
        offset: 0
      }
    });
  };

  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };

  return (
    <div>
      <HeaderTable
        setFilters={setFilters}
        filters={filters}
        setShowNewService={setShowNewService}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <div key={serviceCount} className="!p-3">
          <ServiceTable
            key={services?.length}
            handleAddClick={() => setShowNewService(true)}
            setFilters={setFilters}
            filters={filters}
            serviceCount={serviceCount}
            changePage={changePage}>
            {services?.length > 0 ? (
              services.map((service, index) => (
                <ServiceEntry
                  key={index}
                  index={index}
                  procedure_code_map={procedure_code_map}
                  service={service}
                  getServices={getServices}
                  setServiceToBeUpdated={setServiceToBeUpdated}
                  filters={filters}
                  lastIndex={index === services.length - 1}
                />
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="!py-4"> No Services found</div>
                </td>
              </tr>
            )}
          </ServiceTable>
          <Modal
            customStyling={{ width: '700px' }}
            handleOpen={showNewService || !!serviceToBeUpdated}
            bodyClassName="!p-4 !h-[calc(100vh-115px)]"
            handleClose={hideNewService}
            title={`${serviceToBeUpdated ? 'Update' : 'New'} service`}
            slideFromRight
            footer={
              <div className="flex w-full justify-between">
                <Button
                  outlined
                  text="Cancel"
                  color="neutral"
                  onClick={hideNewService}
                  id="closeServiceModalBtn"
                />
                <Button
                  onClick={() => serviceFormRef.current.submitForm()}
                  text={serviceToBeUpdated ? 'Update' : 'Create service'}
                  id="createUpdateServiceBtn"
                  loading={loading}
                  data-qa="create-update-service-btn"
                />
              </div>
            }>
            <NewService
              filters={filters}
              ref={serviceFormRef}
              setLoading={setLoading}
              onClose={hideNewService}
              procedure_code_options={procedure_code_options}
              getServices={getServices}
              service={serviceToBeUpdated}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Services;
