import React from 'react';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'react-error-boundary';
import Box from 'components/shared/Box/Box';

const ConversationBox = (props) => (
  <Box className="!bg-neutral-50">
    <div className="pb-2 text-xl font-500">Conversation with: {props.message.name}</div>
    <hr className="my-1" />
    {parseInt(props.message.unread) > 0 && (
      <p className='text-danger-500 !py-3'>You have {props.message.unread} new messages</p>
    )}
    <Button text="See full conversation" className="w-full" onClick={props.onclick} />
  </Box>
);

export default withErrorBoundary(ConversationBox);
