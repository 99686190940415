import React from 'react';

import { formatDate, formatDateAndTimeZ, snakeToTitleCase } from 'lib/helpers/utility';

import DocActions from '../components/DocumentActions';

export default (props) => {
  const columns = [
    {
      field: 'name',
      headerName: 'Document Name',
      minWidth: 250,
      flex: 1,
      headerCheckboxSelection: !props?.fromPatient,
      checkboxSelection: !props?.fromPatient ? (params) => !params.node.group : null
    },
    {
      field: 'date_of_service',
      headerName: 'Date of Service',
      flex: 1,
      valueFormatter: ({ value }) =>
        value ? formatDateAndTimeZ(value, props?.practice?.timezone) : '-'
    },
    {
      field: 'category',
      headerName: 'Document Category',
      flex: 1,
      valueFormatter: ({ value }) =>
        props?.categories?.find((cat) => cat?.value?.toString() == value?.toString())
          ? props?.categories?.find((cat) => cat?.value?.toString() == value?.toString())?.label
          : snakeToTitleCase(value)
    },
    {
      field: 'uploader_name',
      headerName: 'Uploaded by',
      flex: 1
    },
    {
      field: 'created_at',
      headerName: 'Document Date',
      flex: 1,
      valueFormatter: ({ value }) => (value ? formatDate(value, props?.practice?.timezone) : '-')
    },
    {
      field: 'actions',
      headerName: '',
      pinned: 'right',
      cellClass: 'no-border',
      sortable: false,
      maxWidth: 70,
      flex: 1,
      cellRenderer: ({ data, index }) => (
        <DocActions
          {...{
            setViewDocument: props?.setViewDocument,
            isEditOpen: props?.isEditOpen,
            setIsEditOpen: props?.setIsEditOpen,
            fromPatient: props?.fromPatient,
            patientId: props?.patientId,
            appointmentId: props?.appointmentId,
            cat: props?.categories?.find(
              (cat) => cat?.value.toString() == data?.category?.toString()
            ),
            mimetype: data?.mimetype,
            form: {
              response_id: data?.id,
              appointment_id: data?.appointment_id,
              form_id: data?.form_id,
              json: data?.json,
              form_json: data?.form_json,
              form_version: data?.form_version
            },
            index,
            data
          }}
        />
      )
    }
  ];
  return columns;
};
