import React, { useState } from 'react';
import { Popover } from '@headlessui/react';
import Icon from 'components/shared/Icon/Icon';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import { requestApi } from 'api/Api';
import { formatValidity } from '../lib/helpers';
import cs from 'classnames';

const PackageTableRow = ({ pack, index, setPackageToBeUpdated, filters, lastIndex }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const deletePackage = async () => {
    const onSuccess = () => {
      queryClient.setQueryData(
        ['getPackages', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            packages: prevData.packages.filter((item) => item.id !== pack.id)
          };
        }
      );
      showAlert({ title: 'Package deleted successfully', color: 'success' });
    };

    const newPack = { ...pack };
    delete newPack.procedures;
    delete newPack.products;

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/update',
      params: { pack: { ...newPack, status: 'deleted' } },
      onSuccess,
      navigate,
      onError
    });
    setShowConfirmationModal(false);
  };

  return (
    <>
      <tr key={`service-entry-index-${index}-view`}>
        <td>{pack.name}</td>
        <td>{pack.procedures.length}</td>
        <td>{pack.products.length}</td>
        <td>
          {formatValidity(
            pack.validity_time_length,
            pack.validity_time_unit,
            pack.no_expiration,
            pack
          )}
        </td>
        <td>${pack.amount_cents / 100}</td>
        <td className="relative">
          <Popover className="relative">
            {({ open, close }) => (
              <div>
                <Popover.Button
                  data-qa={`package-row-${pack.name}`}
                  className={cs(
                    'flex h-[34px] w-[34px] items-center justify-center rounded-full border-solid  border-neutral-300 hover:border',
                    open ? '!bg-primary-700 transition-all' : 'hover:!bg-white'
                  )}>
                  <Icon
                    icon="three-dots"
                    className="flex cursor-pointer"
                    color={open ? 'white' : 'black'}
                  />
                </Popover.Button>

                <Popover.Panel
                  className={cs(
                    'absolute right-0 z-[99999] w-max cursor-pointer rounded-lg border border-solid border-neutral-200 bg-white !px-2 !py-[6px] shadow-[0px_0px_16px_rgba(0,0,0,0.07)]',
                    lastIndex ? '-top-[90px]' : 'top-10'
                  )}>
                  <div
                    data-qa="edit-package-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      setPackageToBeUpdated(pack);
                      close();
                    }}>
                    <Icon icon={'new-edit'} color={'primary'} />
                    <div className="ml-1 text-sm text-primary-900">Edit</div>
                  </div>
                  <div
                    data-qa="delete-package-btn"
                    className="flex items-center gap-1 rounded-lg !p-2 transition-all hover:bg-primary-50"
                    onClick={() => {
                      setShowConfirmationModal(true);
                      close();
                    }}>
                    <Icon icon="trash" color="primary" />
                    <div className="ml-1 text-sm text-primary-900">Delete</div>
                  </div>
                </Popover.Panel>
              </div>
            )}
          </Popover>
        </td>
      </tr>
      <Confirm
        handleOpen={showConfirmationModal}
        handleClose={() => setShowConfirmationModal(false)}
        handleContinue={deletePackage}
        title="Delete package"
        message="Are you sure you want to delete this package?"
        variant="danger"
      />
    </>
  );
};

export default PackageTableRow;
