import React from 'react';

import { useRecoilValue } from 'recoil';

import { useDocumentSign } from '../../../lib/hooks/queries/documentSign/useDocumentSign';
import TreatmentPlan from '../../practice/charts/ClinicalNote/TreatmentPlan/TreatmentPlan';
import Button from '../../shared/Buttons/Button';
import Modal from '../../shared/Modal/Modal';
import { userState } from '../../state';

const PatientTreatmentPlanModal = ({
  isOpen,
  handleClose,
  patientSideTreatmentPlan,
  setShowConfirmationModal
}) => {
  const user = useRecoilValue(userState);
  const { data: hasUserSigned } = useDocumentSign({
    params: {
      documentIds: [patientSideTreatmentPlan?.id],
      userIds: [user?.id]
    },
    dependencies: [patientSideTreatmentPlan?.id],
    options: {
      select: (response) => response.data.find((row) => row.user_id == user.id)?.status === 'signed'
    }
  });

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      title="Treatment plan"
      slideFromRight
      className="w-[720px] sm:w-full"
      headButton={
        !hasUserSigned ? (
          <Button
            text="Sign"
            icon="new-lock-2-opened"
            iconIsStroke
            type="secondary"
            className="!ml-4"
            onClick={() => setShowConfirmationModal(true)}
          />
        ) : null
      }>
      <TreatmentPlan patientSideTreatmentPlan={patientSideTreatmentPlan} patientSide={true} />
    </Modal>
  );
};

export default PatientTreatmentPlanModal;
