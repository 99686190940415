export default {
  css: 'file-css',
  csv: 'file-csv',
  docx: 'file-docx',
  js: 'file-js',
  json: 'file-json',
  jpg: 'file-jpg',
  pdf: 'file-pdf',
  png: 'file-png',
  svg: 'file-svg',
  txt: 'file-txt',
  xls: 'file-xls',
  xlsx: 'file-xlsx',
  xml: 'file-xml',
  zip: 'file-zip',
  unknown: 'file-unknown'
};
