import React, { useEffect, useMemo, useRef } from 'react';

import cs from 'classnames';

import { useFileStack } from 'lib/hooks/useFileStack';
import useLoading from 'lib/hooks/useLoading';

import { showAlert } from '../Alert/Alert';
import Button from '../Buttons/Button';
import { getAcceptAttribute, isFileAllowed } from '../FileUploader/lib/helpers';
import Typography from '../Typography/Typography';

import Image from './components/Image';
import { IMAGE_UPLOADER_SUBTITLE, IMAGE_UPLOADER_TITLE } from './lib/constants';

const FORMATS = '.jpg, .jpeg, .png';

/**
 * @param {import('./lib/propTypes').ImageUploaderProps} props
 */

const ImageUploader = ({
  handlers = [],
  onUpload = (_file) => {},
  onDelete = (_file) => {},
  fileStackPicker = false,
  className = '',
  type = 'avatar'
}) => {
  const fileInputRef = useRef(null);
  const {
    files,
    addFile,
    readFile,
    deleteFile,
    openFilePicker,
    loading: fileStackLoading
  } = useFileStack();

  const { loading, startLoading, stopLoading } = useLoading();

  const file = files[files.length - 1];
  const isLoading = loading || fileStackLoading;

  const isAvatar = type === 'avatar';

  const handleOpenPicker = () => {
    if (fileStackPicker) {
      openFilePicker(onUpload);
      return;
    }

    fileInputRef.current.click();
  };
  const handleDelete = async () => deleteFile(files, onDelete);

  const handleDownload = async () => {
    if (handlers && handlers.length > 0) {
      startLoading();
      await Promise.all(handlers.map(async (handle) => !!handle && readFile(handle))).finally(
        () => {
          stopLoading();
        }
      );
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (isFileAllowed(file, FORMATS)) {
        startLoading();
        const newFile = await addFile(file, onUpload);
        await readFile(newFile.handle);
        stopLoading();
      } else {
        showAlert({
          color: 'danger',
          message: 'Invalid file format. Please upload files in the following formats: ' + FORMATS
        });
      }
    }
  };

  useEffect(() => {
    handleDownload();
  }, [handlers]);

  const allowedFormats = useMemo(() => getAcceptAttribute(FORMATS), []);

  return (
    <div className={cs('flex gap-5 flex-1', className)}>
      <Image
        url={file?.url}
        icon={isAvatar ? 'new-avatar' : 'new-no-image-rounded'}
        loading={isLoading}
      />
      <div className="flex flex-col gap-3">
        <Typography color="text-neutral-800" weight="font-medium">
          {IMAGE_UPLOADER_TITLE}
        </Typography>
        <Typography variant="small" color="text-neutral-700">
          {IMAGE_UPLOADER_SUBTITLE}
        </Typography>
        <div className="flex items-center gap-[7px]">
          {file ? (
            <>
              <Button
                outlined
                text="Remove"
                size="small"
                color="danger"
                textClassName="text-danger-500"
                onClick={handleDelete}
                disabled={isLoading}
              />
              <Button
                outlined
                text="Change"
                size="small"
                color="neutral"
                textClassName="text-neutral-700"
                onClick={handleOpenPicker}
                disabled={isLoading}
              />
            </>
          ) : (
            <Button
              outlined
              text="Upload"
              color="neutral"
              size="small"
              textClassName="text-neutral-700"
              onClick={handleOpenPicker}
              disabled={isLoading}
            />
          )}
        </div>
      </div>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleUpload}
        accept={allowedFormats}
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </div>
  );
};

export default ImageUploader;
