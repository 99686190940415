import Input from 'components/shared/Input/Input';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Select from 'components/shared/Select/Select';
import { capitalize } from 'lodash';
import Moment from 'moment';
import React from 'react';

const PersonalInfo = ({ onSubmit, genderList, formik, formRef, formikHandle }) => {
  return (
    <form ref={formRef} onSubmit={onSubmit} className="grid grid-cols-2 gap-4 md:grid-cols-1">
      <Input
        data-qa="patient-first-name"
        label="First Name"
        name="f_name"
        value={formik.values.f_name && formik.values.f_name}
        onChange={formikHandle}
        error={formik.touched.f_name && formik.errors.f_name}
      />
      <Input
        data-qa="patient-m-name"
        label="Middle Name"
        name="m_name"
        value={formik.values.m_name && formik.values.m_name}
        onChange={formikHandle}
        error={formik.touched.m_name && formik.errors.m_name}
      />
      <Input
        data-qa="patient-last-name"
        label="Last Name"
        name="l_name"
        value={formik.values.l_name && formik.values.l_name}
        onChange={formikHandle}
        error={formik.touched.l_name && formik.errors.l_name}
      />
      <DatePopover
        data-qa="patient-dob"
        label="Date of Birth"
        name="dob"
        value={formik.values.dob && Moment(formik.values.dob).format('YYYY-MM-DD')}
        onChange={(v) => formik.setFieldValue('dob', v)}
        type="date"
      />
      {formik.touched.dob && formik.errors.dob ? (
        <span className="pt-1 text-sm text-danger-500">{formik.errors.dob}</span>
      ) : null}
      <Select
        isClearable={false}
        className="!w-full"
        inputId="gender"
        options={genderList}
        label="Gender"
        placeholder="Type and select Gender"
        value={{ value: formik?.values?.gender, label: capitalize(formik.values?.gender) }}
        onChange={(e) => formik.setFieldValue('gender', e?.value || null)}
        error={formik.touched.gender && formik?.errors?.gender}
      />
      <Input
        data-qa="patient-email"
        label="Email"
        name="email"
        value={formik.values.email && formik.values.email}
        onChange={formikHandle}
        error={formik.touched.email && formik.errors.email}
      />
      <Input
        data-qa="patient-phone-number"
        label="Phone Number"
        name="phone"
        value={formik.values?.phone && formik.values?.phone}
        onChange={formikHandle}
        error={formik.touched.phone && formik.errors.phone}
      />
    </form>
  );
};

export default PersonalInfo;
