import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useFormikContext } from 'formik';
import { isEmpty } from 'lodash';
import { useRecoilValue } from 'recoil';

import { confirmNoShowFee } from 'api/Appointment';

import { currentPractice } from 'components/practice/practiceState';
import { showAlert } from 'components/shared/Alert/Alert';
import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Confirm from 'components/shared/Modal/Confirm/Confirm';

const NoShowConfirmation = () => {
  const practice = useRecoilValue(currentPractice);
  const [noShowFee, setNoShowFee] = useState(practice?.no_show_fee || 0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const { values, initialValues, setFieldValue } = useFormikContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  useEffect(() => {
    const noShowStatus =
      values.timeline_status?.arrived?.value === 'no_show' || values.status == 'no_show';
    if (noShowStatus && initialValues.status !== 'no_show') {
      setShowConfirmationModal(true);
    }
  }, [values.status, values.timeline_status]);

  const handleCloseCancelConfirmation = () => {
    setShowConfirmationModal(false);
    setFieldValue('status', initialValues.status);

    if (!isEmpty(values.timeline_status)) {
      const timeline_status = { ...values.timeline_status };
      delete timeline_status.arrived;
      setFieldValue('timeline_status', timeline_status);
    }
  };

  const handleMarkNoShowFee = async () => {
    setLoading(true);
    const params = {
      id: values.id,
      noShowFee
    };
    const { code, updatedAppointment } = await confirmNoShowFee(navigate, params);

    if (code == 0) {
      showAlert({
        color: 'success',
        message: 'No show fee applied!'
      });

      queryClient.setQueriesData({ queryKey: ['appointment-events'] }, (oldData) => ({
        ...oldData,
        appointment: oldData.appointment.map((apt) =>
          apt.id === values.id ? { ...values, ...updatedAppointment } : apt
        )
      }));

      queryClient.setQueryData(['appointmentv2', values?.id], (oldData) => {
        return {
          ...oldData,
          appointment: { ...values, ...updatedAppointment }
        };
      });
      queryClient.invalidateQueries(['apt-timeline-details']);

      setShowConfirmationModal(false);
    }
    setLoading(false);
  };

  const handleChangeFee = (value) => setNoShowFee(value);

  return (
    <>
      {showConfirmationModal && (
        <Confirm
          isOpen={showConfirmationModal}
          handleClose={handleCloseCancelConfirmation}
          handleContinue={handleMarkNoShowFee}
          title="Apply No-Show Fee"
          message="You're about to apply a fee for this no-show appointment. Review and confirm the penalty amount to proceed."
          primaryButtonText="Confirm and Apply"
          secondaryButtonText="Cancel"
          icon="new-calendar-red"
          variant="danger"
          loading={loading}>
          <CurrencyInput value={noShowFee} onValueChange={handleChangeFee} parentClassName="mt-2" />
        </Confirm>
      )}
    </>
  );
};

export default NoShowConfirmation;
