import React, { useEffect, useState } from 'react';
import { appointmentStatuses as appointmentStatusesState } from 'components/practice/practiceState';
import Icon from 'components/shared/Icon/Icon';
import Allowed from 'components/shared/Permissions/Allowed';
import { userState } from 'components/state';
import { useFormikContext } from 'formik';
import { ia, iaRa, mString } from 'lib/helpers/utility';
import moment from 'moment';
import { useRecoilValue } from 'recoil';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Button from 'components/shared/Buttons/Button';

const CNArrived = ({ data }) => {
  const [arrivedData, setArrivedData] = useState(data || []);
  const [timeAttendance, setTimeAttendance] = useState(null);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const { setFieldValue, values } = useFormikContext();
  const user = useRecoilValue(userState);
  const appointmentStatuses = useRecoilValue(appointmentStatusesState);

  useEffect(() => {
    const activeArriving = iaRa(arrivedData).find((item) => item.duration === 'active');
    if (!activeArriving) return;

    const startDateTime = new Date(activeArriving.plain_start_time);

    const updateTimer = () => {
      const difference = new Date() - startDateTime;
      const hours = Math.floor(difference / (1000 * 60 * 60));
      const minutes = String(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))).padStart(
        2,
        '0'
      );
      const seconds = String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(2, '0');

      setTimeAttendance(`${hours}h ${minutes}m ${seconds}s`);
    };

    updateTimer();

    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval);
  }, [arrivedData]);

  useEffect(() => {
    if (ia(data)) {
      setArrivedData(data);
    } else if (values.arrived_data) {
      setArrivedData([values.arrived_data]);
    }
  }, [data, values.arrived_data]);

  const handleMarkPatientArrived = (arrivedWaiting) => {
    let localData = {};
    if (arrivedWaiting) {
      localData = {
        title: 'Arrived - Waiting',
        timeLabel: 'Time - Waiting',
        duration: 'active',
        start_time: moment(new Date()).format('hh:mm:ss A z'),
        plain_start_time: new Date()
      };
    } else {
      localData = {
        title: 'Arrived with ' + user.role,
        timeLabel: `Time with ${user.role}`,
        duration: 'active',
        start_time: moment(new Date()).format('hh:mm:ss A z'),
        plain_start_time: new Date()
      };
    }

    setFieldValue('arrived_data', localData);
    setFieldValue('arrivalUpdate', {
      time: new Date(),
      markWaiting: arrivedWaiting
    });

    const aptStatus = appointmentStatuses.find(
      (it) => it.value === 'arrived_with_' + String(user.role).toLocaleLowerCase()
    );

    setFieldValue('timeline_status.arrived', {
      status: aptStatus.color,
      name: aptStatus.label,
      value: aptStatus.value
    });

    const updatedTimeline = {
      ...structuredClone(values.timeline),
      clinical: {
        ...values.timeline.clinical,
        arrived: {
          value: aptStatus.value,
          name: aptStatus.label,
          color: aptStatus.color
        }
      }
    };

    const updatedTimelineBar = values.timeline_bar.map((it) =>
      it.name === 'arrived' ? { ...it, label: aptStatus.value, color: aptStatus.color } : it
    );

    setFieldValue('timeline', updatedTimeline);
    setFieldValue('timeline_bar', updatedTimelineBar);

    setConfirmationModalVisible(false);
  };
  const isAptDisabled = values.appointment_disabled;
  const noShowStatus = values.timeline.clinical.arrived.name == 'No Show';

  if (noShowStatus && values.status == 'no_show') {
    return (
      <Allowed requiredPermissions="appointments.read">
        <div className="flex items-center justify-between">
          <p className="text-sm text-neutral-800">No show fee</p>
          <p className="text-sm text-neutral-800">{mString(values.invoice?.total_amount)}</p>
        </div>
      </Allowed>
    );
  }

  return (
    <Allowed requiredPermissions="appointments.read">
      <div className="flex flex-col gap-2" id="arrive-timeline-content">
        {ia(arrivedData) ? (
          arrivedData.map((patientTimeline, index) => (
            <div key={index}>
              <div className="flex items-center justify-between">
                <p className="text-sm font-500 text-neutral-800">{patientTimeline.title}</p>
              </div>
              <div className="ml-4">
                <div className="flex w-full items-center justify-between">
                  <p className="text-sm  text-neutral-800">
                    {patientTimeline.type == 'appointment_ended'
                      ? 'Ended at'
                      : patientTimeline.type === 'kiosk'
                        ? 'Arrived at'
                        : 'Started at'}
                  </p>
                  <p className="ml-6 max-w-full overflow-hidden text-ellipsis whitespace-nowrap  text-sm text-neutral-800">
                    {patientTimeline.start_time}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-1">
                  <p className="text-sm  text-neutral-800">{patientTimeline.timeLabel}</p>
                  <p className="text-sm  text-neutral-800">
                    {patientTimeline.duration == 'active'
                      ? timeAttendance
                      : patientTimeline.duration}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div
            className={`flex w-full items-center justify-end gap-1 ${
              !isAptDisabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'
            }`}
            onClick={!isAptDisabled ? () => setConfirmationModalVisible(true) : null}>
            <p className="text-sm text-primary-600">Mark patient arrived</p>
            <Icon icon="new-checkbox-rec" size={14} />
          </div>
        )}
      </div>
      {confirmationModalVisible && (
        <Confirm
          handleOpen={!!confirmationModalVisible}
          handleClose={() => setConfirmationModalVisible(false)}
          title="Mark Arrived"
          icon="calendar-tick"
          message="Please select whether you want to mark the patient’s arrival or indicate that the patient is with you."
          variant="primary"
          slideFromRight={false}
          overlayClassName="bg-transparent top-[calc(100%+4px)] absolute   right-0 w-max h-max"
          parentSelector={() => document.getElementById('arrive-timeline-content')}
          customClassName="!transition-none"
          showFooter={false}>
          <div className="mt-4 flex items-center gap-2">
            <Button
              text="Mark - Arrived Waiting"
              type="secondary"
              onClick={() => handleMarkPatientArrived(true)}
            />
            <Button
              text={`Mark - Arrived with ${user.role}`}
              type="secondary"
              onClick={() => handleMarkPatientArrived(false)}
            />
          </div>
        </Confirm>
      )}
    </Allowed>
  );
};

export default CNArrived;
