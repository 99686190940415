import React from 'react';
import ProfilePicture from 'components/Profile/ProfilePicture';
import { useNavigate } from 'react-router-dom';

const UserTile = ({ member }) => {
  const navigate = useNavigate();

  return (
    <div
      className="flex cursor-pointer items-center gap-1.5 rounded-md bg-primary-50 px-2.5 py-[5px]"
      onClick={() => {
        navigate('/portal/settings/staff', {
          state: {
            id: member.id
          }
        });
      }}>
      <div className="relative h-4 w-4 rounded-[10.43px] border bg-white">
        <div className="relative rounded-[14.34px] border border-white bg-white">
          <ProfilePicture
            firstName={member.f_name}
            lastName={member.l_name}
            id={member.id}
            image={member.profile_photo}
            size={16}
            className="rounded-full text-white"
            fontSize="text-xs"
          />
        </div>
      </div>
      <span className="whitespace-nowrap text-xs font-medium text-primary-900">
        {member?.f_name + ' ' + member?.l_name[0] + '.'}
      </span>
    </div>
  );
};

export default UserTile;
