import { useState } from 'react';

const useCopy = ({ isText = true } = {}) => {
  const [copyText, setCopyText] = useState(isText ? 'Copy' : '');

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText('Copied!');
        setTimeout(() => setCopyText(isText ? 'Copy' : ''), 2000);
      })
      .catch(() => {
        setCopyText('Failed to copy!');
      });
  };

  return { copyText, copyToClipboard };
};

export default useCopy;
