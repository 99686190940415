import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { socket } from 'api/Socket';

import practiceState from 'components/practice/practiceState';
import OrdersTable from 'components/shared/Forms/Custom/Orders/Orders';
import SSOAction from 'components/shared/ePrescribe/SSOAction';
import { patient } from 'components/shared/ePrescribe/SSOKinds';
import state from 'components/state';

const EPrescribe = ({ appointmentId }) => {
  const userState = useRecoilValue(state.userState);
  const practice = useRecoilValue(practiceState.currentPractice);
  const { id } = useParams();

  const handleStartVeradigmSessionSocket = () => {
    const data = {
      appointmentId,
      patientId: id,
      user_id: userState.id,
      practice_id: practice.id
    };
    socket.emit('veradigm-prescribe', { ...data });
  };

  return (
    <SSOAction
      enableEnrollPatient
      patientId={id}
      kind={patient}
      handleStartVeradigmSessionSocket={handleStartVeradigmSessionSocket}
      errorClassName="m-3"
    />
  );
};

const Orders = () => {
  const { appointmentId } = useParams();

  return (
    <>
      <EPrescribe appointmentId={appointmentId} />

      <div className="h-full">
        <OrdersTable appointmentId={appointmentId} pagination showFooter />
      </div>
    </>
  );
};

export default Orders;
