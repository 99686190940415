import { useQuery } from '@tanstack/react-query';
import { getAttachments } from 'api/Attachments';
import { useNavigate } from 'react-router-dom';

export const useAttachments = ({ params = {}, options = {}, dependencies = [] }) => {
  const navigate = useNavigate();
  return useQuery(
    ['patient-attachments', ...dependencies],
    () => getAttachments(navigate, params),
    {
      refetchOnMount: true,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
