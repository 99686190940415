import { updateClaims } from 'api/Claim';
import { isValidDate } from 'lib/helpers/utility';
import getUpdatedValues from './getUpdatesValues';

export default function handleUpdateClaim(navigate, claim, initialClaim) {
  const claimToUpdate = {
    ...claim,
    pat_dob: isValidDate(claim?.pat_dob),
    ins_dob: isValidDate(claim?.ins_dob),
    other_ins_dob: isValidDate(claim?.other_ins_dob),
    other_ins_payment_date: isValidDate(claim?.other_ins_payment_date),
    cond_date: isValidDate(claim?.cond_date),
    hosp_from_date: isValidDate(claim?.hosp_from_date),
    hosp_thru_date: isValidDate(claim?.hosp_thru_date)
  };
  const requiredFields = {
    id: claim.id,
    procedures: claim.procedures,
    practice_id: claim.practice_id,
    appointment_id: claim.appointment_id,
    pcn: claim.pcn
  };

  const valuesToBeUpdated = getUpdatedValues(initialClaim, claimToUpdate);

  return updateClaims(navigate, { ...requiredFields, ...valuesToBeUpdated });
}
