import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { requestApi } from '../../../../api/Api';
import { useClinicalNoteContext } from '../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import RenderedContent from '../../../practice/charts/ClinicalNote/PreviewNote/Rendered/RenderedContent';
import {
  mapRenderedInitialState,
  processSelectedForms
} from '../../../practice/charts/ClinicalNote/PreviewNote/lib/renderedHelper';
import Modal from '../../../shared/Modal/Modal';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import state from '../../../state';

const MedicalRecordModal = ({ showMedicalRecordModal, hideMedicalRecordModal, medicalRecord }) => {
  const { setClinicalNote, setAdvancedHP, setAdvancedSOAP } = useClinicalNoteContext();
  const [loading, setLoading] = useState(false);
  const currentUser = useRecoilValue(state.userState);
  const { practice, practitioner, user, appointment, pdf_selection } = medicalRecord;
  const renderedRef = useRef();
  const navigate = useNavigate();
  const rendered = mapRenderedInitialState(pdf_selection);
  const { checkedRendered, hpForms, soapForms } = processSelectedForms({ pdf_selection, rendered });

  useEffect(() => {
    getClinicalNote();
  }, []);

  const getClinicalNote = async () => {
    setLoading(true);
    const response = await requestApi({
      url: '/api/clinical_note/read',
      params: {
        patientId: currentUser?.user_id,
        appointmentId: appointment?.id,
        loadedPracticeId: practice?.id,
        exporting: true
      },
      navigate
    });
    setClinicalNote(response.clinicalNote);

    setAdvancedHP(response.advancedHP);
    setAdvancedSOAP(response.advancedSOAP);
    setLoading(false);
  };

  return (
    <Modal
      handleOpen={showMedicalRecordModal}
      handleClose={hideMedicalRecordModal}
      title="Clinical note"
      className="w-[660px]"
      slideFromRight>
      {loading ? (
        <Skeleton count={1} height="400px" />
      ) : (
        <RenderedContent
          isExport
          isPatient
          renderedRef={renderedRef}
          practice={practice}
          provider={practitioner}
          patient={user}
          appointmentDate={appointment?.starts_at}
          checkedRendered={checkedRendered}
          hpForms={hpForms}
          soapForms={soapForms}
          loading={loading}
        />
      )}
    </Modal>
  );
};

export default MedicalRecordModal;
