import React from 'react';

import CurrencyInput from 'components/shared/CurrencyInput/CurrencyInput';
import Input from 'components/shared/Input/Input';

import {
  handleChangePrice,
  handleChangeQuantity,
  handleChangeUnitPrice
} from '../../lib/appointmentHelpers';

const inputClass =
  'h-[41px] !px-4 !bg-transparent border-none !border-b-2 [border-bottom-style:solid] [border-bottom-color:transparent] focus-within:!border-b-2 rounded-none border-white focus-within:!outline-[0px] !outline-0 focus-within:shadow-medium';

export const unitPriceCell = (event) => {
  const {
    data: { id, amount_cents },
    context: { setFieldValue, selectedItems }
  } = event || {};

  return (
    <CurrencyInput
      variant="secondary"
      disabled={true}
      value={amount_cents}
      onValueChange={(v) => handleChangeUnitPrice(setFieldValue, selectedItems, v, id)}
      hideBorder
    />
  );
};

export const qtyCell = (event) => {
  const {
    data: { id, sales_count },
    context: { setFieldValue, selectedItems }
  } = event || {};

  return (
    <Input
      min={1}
      type="number"
      value={sales_count}
      inputWrapperClassName={inputClass}
      onChange={(e) => handleChangeQuantity(setFieldValue, selectedItems, e.target.value, id)}
    />
  );
};

export const priceCell = (event) => {
  const {
    data: { id, total_amount_cents },
    context: { setFieldValue, selectedItems }
  } = event || {};

  return (
    <CurrencyInput
      variant="secondary"
      disabled={true}
      value={total_amount_cents}
      onValueChange={(v) => handleChangePrice(setFieldValue, selectedItems, v, id)}
      hideBorder
    />
  );
};
