import { ia } from 'lib/helpers/utility';

export const isFirstColumn = (params) => {
  const displayedColumns = params.api.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
};

export const defaultColDef = {
  minWidth: 100,
  headerCheckboxSelection: isFirstColumn,
  checkboxSelection: isFirstColumn
};

export const rowClassRules = {
  'ag-row-error': (params) => ia(params.data.error)
};
