import { requestApi } from './Api';

export const getTerminals = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/transactions/terminal/list',
    onError: () => {},
    params
  });
};

export const addTerminal = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/transactions/terminal/add',
    onError: () => {},
    params
  });
};

export const updateTerminal = async (navigate, params) => {
  return requestApi({
    navigate,
    url: '/api/transactions/terminal/update',
    onError: () => {},
    params
  });
};
