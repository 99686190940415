import React from 'react';

import { ia } from '../../../../../../../../lib/helpers/utility';

const ICD10OrDiagnosis = ({ itemProps }) => {
  if (!ia(itemProps?.diags)) {
    return null;
  }

  return (
    <div>
      <div className="text-neutral-800 text-base font-500">ICD-10/Diagnosis</div>

      <ul className="!pl-5 !mt-3">
        <li className="list-disc text-sm break-normal whitespace-pre-wrap !list-outside">
          {itemProps?.diags?.map((row) => row?.label).join(', ')}
        </li>
      </ul>
    </div>
  );
};

export default ICD10OrDiagnosis;
