import { Honeybadger } from '@honeybadger-io/react';
import React, { useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useNavigate } from 'react-router-dom';
import { interimApi } from '../../../../api/InterimApi';
import Button from '../../Buttons/Button';
import Modal from '../Modal';
import Icon from '../../Icon/Icon';
import cs from 'classnames';
import Popup from 'components/shared/Filters/Popup';
import Allowed from 'components/shared/Permissions/Allowed';
import { showAlert } from 'components/shared/Alert/Alert';
import { ia } from 'lib/helpers/utility';

const CustomForms = ({
  showCustomForms,
  hideCustomForms,
  selectedForm,
  appointment,
  sendFormsToKiosk,
  kiosks,
  appendParentSelector = true,
  invalidateQuery = () => {},
  className = '',
  isAptDisabled = false
}) => {
  const [loading, setLoading] = useState(false);
  const answerData = selectedForm.response ? JSON.parse(selectedForm.response.json.fields) : null;
  const navigate = useNavigate();

  const handleFormSubmit = async (e, responseId, formId, formVersion) => {
    setLoading(true);
    try {
      let params = {
        responseId,
        formId,
        json: { fields: JSON.stringify(e) },
        appointmentId: appointment.id,
        patientId: appointment.patient.id,
        formVersion
      };
      let res = await interimApi('/api/form/upsert_response', params, navigate);
      if (res.data.upsert) {
        showAlert({
          title: 'Form Saved',
          color: 'success'
        });
        invalidateQuery();
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
    setLoading(false);
  };

  const showActions = ia(kiosks) && !isAptDisabled;

  return (
    <Modal
      handleOpen={showCustomForms}
      handleClose={hideCustomForms}
      className={className}
      title={selectedForm.name}
      {...(appendParentSelector && {
        appendParentSelector: document.getElementById('AppointmentPreviewModal') || document
      })}
      slideFromRight
      subModal
      prevButton={
        <Button
          outlined
          color="neutral"
          icon="back-large"
          onClick={hideCustomForms}
          className="!mr-3"
        />
      }
      footer={
        <div className="flex w-full justify-between">
          <Button text="Cancel" color="neutral" outlined onClick={hideCustomForms} />
          <div>
            <Allowed requiredPermissions="kiosk.read">
              {showActions && (
                <Popup
                  trigger={(open) => (
                    <div
                      className={`flex h-[40px] w-[40px] items-center justify-center rounded-xl   bg-gray-100  ${
                        !open && 'hover:!bg-white'
                      } ${open && 'border-none !bg-primary-900 transition-all'}`}>
                      <Icon
                        icon="three-dots"
                        className="flex cursor-pointer"
                        color={open ? 'white' : 'black'}
                      />
                    </div>
                  )}
                  width={200}
                  on={['hover', 'focus']}
                  contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
                  position={['top right', 'top center']}>
                  <div className=" !py-[6px]">
                    <Popup
                      trigger={(open) => (
                        <div
                          data-qa="delete-product-btn"
                          className={cs(
                            'flex cursor-pointer items-center justify-between gap-1 !p-2 !px-4 transition-all hover:bg-primary-50',
                            open && 'bg-primary-50'
                          )}>
                          <div className="flex items-center gap-1">
                            <Icon
                              size={18}
                              icon="kiosk-nav"
                              color="primary"
                              className="cursor-pointer"
                            />
                            <div className="ml-1 text-sm text-primary-900">Send to Kiosk</div>
                          </div>
                          <Icon icon="new-chevron-right"></Icon>
                        </div>
                      )}
                      width={200}
                      contentStyle={{
                        borderRadius: '4px',
                        boxShadow: '0px 2px 16px 0px #004F6B33'
                      }}
                      position={['left center']}
                      on={['hover', 'focus']}>
                      {(close) => (
                        <div className=" !py-[6px]">
                          {kiosks?.map((kiosk) => (
                            <div
                              key={kiosk.id}
                              data-qa="edit-product-btn"
                              className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                              onClick={() => {
                                sendFormsToKiosk([selectedForm.id], kiosk.id);
                                close();
                              }}>
                              <Icon
                                icon="kiosk"
                                size={18}
                                customColor={kiosk.color}
                                className="cursor-pointer"
                              />
                              <div className="ml-1 text-sm text-primary-900">{kiosk.name}</div>
                            </div>
                          ))}
                        </div>
                      )}
                    </Popup>
                  </div>
                </Popup>
              )}
            </Allowed>
          </div>
        </div>
      }>
      <div className="new-forms">
        <ReactFormGenerator
          answer_data={answerData}
          read_only={isAptDisabled}
          data={selectedForm?.json?.fields}
          submitButton={
            <div className="flex w-full justify-center pb-2 pt-3">
              <Button text="Save" buttonType="submit" loading={loading} disabled={isAptDisabled} />
            </div>
          }
          onSubmit={(e) =>
            handleFormSubmit(
              e,
              selectedForm.response ? selectedForm.response.id : null,
              selectedForm.id,
              selectedForm.version
            )
          }
        />
      </div>
    </Modal>
  );
};

export default CustomForms;
