import React from 'react';
import Modal from 'components/shared/Modal/Modal';
import Button from 'components/shared/Buttons/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Input from 'components/shared/Input/Input';
import { createContact, updateContact } from 'api/Contacts';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Icon from 'components/shared/Icon/Icon';
import * as filestack from 'filestack-js';
import { formatNumber } from '../../fax/components/SendFax/lib';
import { showAlert } from 'components/shared/Alert/Alert';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const validationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, 'First name should be at least 2 characters')
    .required('Name is required.'),
  email: Yup.string().email('Invalid email'),
  fax_number: Yup.string()
    .min(10, 'Fax number is too short.')
    .test('fax-or-phone', 'Either phone or fax number is required', function (value) {
      const { phone_number } = this.parent;
      return !!value || !!phone_number;
    }),
  phone_number: Yup.string()
    .min(10, 'Phone number is too short.')
    .test('phone-or-fax', 'Either phone or fax number is required', function (value) {
      const { fax_number } = this.parent;
      return !!value || !!fax_number;
    })
});

const NewContactModal = ({
  newContactModalVisible,
  setNewContactModalVisible,
  contact = null,
  filters
}) => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      first_name: contact?.first_name || '',
      last_name: contact?.last_name || '',
      email: contact?.email || '',
      fax_number: contact?.fax_number || '',
      phone_number: contact?.phone_number || '',
      image: contact?.image || ''
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      const valuesCopy = {
        ...values,
        fax_number: values.fax_number.replace(/\D/g, '')
      };
      contact
        ? mutateUpdateContact.mutate({ ...valuesCopy, id: contact?.id })
        : mutateCreateContact.mutate(valuesCopy);
    }
  });

  const queryClient = useQueryClient();

  const mutateCreateContact = useMutation({
    mutationFn: (data) => createContact(navigate, data),
    onSuccess: () => {
      setNewContactModalVisible(false);
      formik.setSubmitting(false);
      const { search, page, limit } = filters;
      queryClient.invalidateQueries(['contacts', search, page, limit]);
      showAlert({ title: 'Successfully created contact', color: 'primary' });
    },
    onError: (error) => {
      formik.setSubmitting(false);

      error.code === 4 && formik.setFieldError('email', error.error);
    }
  });

  const mutateUpdateContact = useMutation({
    mutationFn: (data) => updateContact(navigate, data),
    onSuccess: () => {
      setNewContactModalVisible(false);
      formik.setSubmitting(false);
      const { search, page, limit } = filters;
      queryClient.invalidateQueries(['contacts', search, page, limit]);
      showAlert({ title: 'Successfully updated contact', color: 'primary' });
    },
    onError: (error) => {
      formik.setSubmitting(false);

      error.code === 4 && formik.setFieldError('email', error.error);
    }
  });

  const openFilestackPicker = () => {
    let pickerOptions = {
      accept: ['image/*'],
      maxFiles: 1,
      uploadInBackground: false,
      maxSize: 3 * 1024 * 1024
    };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };

    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };
  const saveImage = async (data) => {
    try {
      const handle = {
        jpg: data.filesUploaded[0].handle
      };
      if (handle) {
        formik.setFieldValue('image', JSON.stringify(handle));
      }
    } catch (error) {}
  };

  return (
    <Modal
      handleOpen={!!newContactModalVisible}
      handleClose={() => setNewContactModalVisible(false)}
      title="Create new contact"
      customStyling={{ width: '540px' }}
      slideFromRight
      footer={
        <div className="flex w-full justify-between">
          <Button
            outlined
            text="Cancel"
            color="neutral"
            onClick={() => setNewContactModalVisible(false)}
            id="cancelProductModalBtn"
          />
          <Button
            onClick={formik.submitForm}
            text={contact ? 'Update contact' : 'Create contact'}
            id="createContactModalBtn"
            data-qa="create-update-contact-btn"
            // disabled={formik.isSubmitting}
          />
        </div>
      }>
      <div className="flex flex-col gap-[14px]">
        <div className="flex gap-4">
          <div className="flex flex-1 flex-col  gap-4">
            <Input
              name="first_name"
              label="First name"
              data-qa="contact-first-name"
              placeholder="Enter new contact first name"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
              error={formik.touched.first_name && formik.errors.first_name}
            />
            <Input
              name="last_name"
              label="Last name"
              data-qa="contact-last-name"
              placeholder="Enter new contact last name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
              error={formik.touched.last_name && formik.errors.last_name}
            />
          </div>
          <div className="flex flex-1 flex-col">
            <label className="m-0 !pr-4 pb-[6px] text-sm font-500 text-neutral-800">Image</label>
            <div
              onClick={openFilestackPicker}
              className={`flex h-auto flex-1 cursor-pointer flex-col items-center rounded-lg border-[1px] border-dashed border-primary-400 bg-gradient-to-r from-primary-50 to-primary-100 ${
                !formik.values.image && 'justify-center'
              }`}>
              <div className="flex flex-col items-center justify-center">
                {formik.values.image ? (
                  <img
                    className="object-contain"
                    style={{ maxWidth: '49%' }}
                    src={`https://cdn.filestackcontent.com/${JSON.parse(formik.values.image).jpg}`}
                    alt="Product Image"
                  />
                ) : (
                  <>
                    <Icon icon="new-file-upload" className="cursor-pointer rounded-full bg-white" />
                    <p className="pt-1 text-center text-sm font-400 text-neutral-500">
                      Click here or drag and <span className="block">drop image to upload</span>
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <Input
          name="email"
          label="Email"
          data-qa="contact-email"
          placeholder="Enter new contact email address"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && formik.errors.email}
        />
        <Input
          name="fax_number"
          label="Fax Number"
          data-qa="contact-fax_number"
          placeholder="(500) 234-5689"
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            const formattedNumber = formatNumber(formik.values.fax_number, 0, () => {}, true);
            formik.setFieldValue('fax_number', formattedNumber);
          }}
          value={formik.values.fax_number}
          error={formik.touched.fax_number && formik.errors.fax_number}
        />
        <Input
          name="phone_number"
          label="Phone Number"
          data-qa="contact-phone_number"
          placeholder="(500) 234-5689"
          onChange={formik.handleChange}
          onBlur={(e) => {
            formik.handleBlur(e);
            const formattedNumber = formatNumber(formik.values.phone_number, 0, () => {}, true);
            formik.setFieldValue('phone_number', formattedNumber);
          }}
          value={formik.values.phone_number}
          error={formik.touched.phone_number && formik.errors.phone_number}
        />
      </div>
    </Modal>
  );
};

export default NewContactModal;
