import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { useRecoilValue } from 'recoil';

import { socket } from 'api/Socket';

import { ia } from 'lib/helpers/utility';

import { currentPractice } from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/Popover';
import SSOAction from 'components/shared/ePrescribe/SSOAction';
import { patient as kind } from 'components/shared/ePrescribe/SSOKinds';
import { userState } from 'components/state';

import { getPopoverOptions } from '../../Provider/lib/helpers';

import VeradigmMedication from './VeradigmMedication';

const ProviderActions = ({
  disabled = false,
  noKnownState,
  setShowMedicationModal = () => {},
  setShowNoKnownModal = () => {},
  setShowFormModal = () => {},
  title = ''
}) => {
  const { id: patientId, appointmentId } = useParams();
  const practice = useRecoilValue(currentPractice);
  const user = useRecoilValue(userState);
  const [showVeradigmMedication, setShowVeradigmMedication] = useState(false);

  const handleStartVeradigmSessionSocket = () => {
    if (title !== 'Medications') return;
    const data = {
      appointmentId: appointmentId || null,
      patientId,
      practice_id: practice?.id,
      user_id: user?.id
    };

    socket.emit('veradigm-prescribe', { ...data });
  };

  const ssoProps =
    title !== 'Medications'
      ? {}
      : {
          enableEnrollPatient: true,
          patientId,
          kind,
          handleStartVeradigmSessionSocket,
          isButton: true,
          fromVeradigmMedication: true
        };

  const {
    show,
    requirements,
    enableEnrollPatient,
    getSSO,
    enrollPatient,
    enableEnrollPatientConfig
  } = SSOAction(ssoProps) || {};

  const handleSSO = () => {
    if (
      title === 'Medications' &&
      typeof getSSO === 'function' &&
      !show?.loading &&
      !ia(requirements) &&
      !enableEnrollPatient &&
      enableEnrollPatientConfig
    ) {
      getSSO({ target: { name: kind } });
    } else {
      setShowVeradigmMedication(true);
    }
  };

  return (
    <>
      <Popover
        className="rounded-md bg-white"
        buttonClassName="max-w-[280px] bg-primary-500"
        textClassName="text-ellipsis overflow-hidden whitespace-nowrap text-white"
        panelClassName="max-h-[300px] xl:max-h-[500px] overflow-y-auto"
        icon={false}
        disabled={disabled}
        loading={show?.loading}
        text={title}
        buttonTheme="primary"
        isFixed
        isDropdown
        iconRightColor="white"
        position="left"
        options={getPopoverOptions({
          noKnownState,
          isLoading: show?.loading,
          setShowMedicationModal,
          setShowNoKnownModal,
          setShowFormModal,
          handleSSO,
          title,
          getSSO
        })}
      />
      {showVeradigmMedication && (
        <VeradigmMedication
          getSSO={getSSO}
          show={show}
          requirements={requirements}
          enrollPatient={enrollPatient}
          enableEnrollPatient={enableEnrollPatient}
          enableEnrollPatientConfig={enableEnrollPatientConfig}
          setShowVeradigmMedication={setShowVeradigmMedication}
        />
      )}
    </>
  );
};
export default ProviderActions;
