import React from 'react';
import * as Yup from 'yup';
import Actions from '../components/Actions';
import PayerAddress from '../components/PayerAddress';

export const defaultColumns = [
  {
    field: 'id',
    headerName: 'ID',
    cellClass: 'no-border',
    cellRenderer: 'agGroupCellRenderer',
    minWidth: 100,
    resizable: false,
    showRowGroup: true,
    suppressMenu: true,
    flex: 2
  },
  {
    field: 'name',
    headerName: 'Name',
    cellClass: 'no-border',
    valueFormatter: ({ value }) => (value ? value : '-'),
    flex: 2
  },
  {
    field: 'practicePayers',
    headerName: 'Address',
    cellClass: 'no-border',
    cellRenderer: (params) => <PayerAddress payer={params.data} />,
    flex: 2
  },
  {
    field: '',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: Actions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right',
    flex: 1
  }
];

export const DEFAULT_FILTERS = {
  name: {
    type: 'search',
    values: null,
    title: 'Payer Name',
    placeholder: 'Search Name'
  }
};

export const PayerInitialValues = (payer, address) => ({
  name: payer?.name || '',
  address_ln_1: address?.address_ln_1 || '',
  address_ln_2: address?.address_ln_2 || '',
  city: address?.city || '',
  state: address?.state || '',
  zip: address?.zip || ''
});

export const PayerValidationSchema = Yup.object().shape({
  name: Yup.string().min(3).required('Payer name is required.')
});
