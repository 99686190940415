import React, { useCallback, useMemo, useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { getInternalInvoices } from 'api/Billing';

import { useInternalInvoices } from 'lib/hooks/queries/billing/useInternalInvoices';

import { currentPractice } from 'components/practice/practiceState';
import AGBasicTable from 'components/shared/AGBasicTable/AGBasicTable';

import { DEFAULT_COL_DEFS, GRID_OPTIONS, columnDefs } from '../lib/modalColumnDefs';

const NewInvoiceTable = ({ patients = [], setGroupSelectedRows = () => {} }) => {
  const gridRef = useRef();
  const practice = useRecoilValue(currentPractice);
  const practiceTimezone = practice.timezone;

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    setGroupSelectedRows(selectedRows);
  }, [setGroupSelectedRows]);

  const onRowDataUpdated = useCallback((params) => {
    const nodesToSelect = [];
    params.api.forEachNode((node) => {
      nodesToSelect.push(node);
    });
    params.api.setNodesSelected({ nodes: nodesToSelect, newValue: true });
  }, []);

  const { data } = useInternalInvoices({
    queryKey: ['invoices', ...patients],
    fetchData: getInternalInvoices,
    params: { filters: { patients } },
    dependencies: [...patients],
    options: {
      enabled: !!patients.length
    }
  });

  const invoices = data?.invoices;

  const colDefs = useMemo(() => columnDefs(practiceTimezone), [practiceTimezone]);

  return (
    <div className="flex h-full w-full flex-col overflow-hidden">
      <AGBasicTable
        gridRef={gridRef}
        columnDefs={colDefs}
        defaultColDef={DEFAULT_COL_DEFS}
        animateRows={true}
        rowData={invoices}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        customClassName="ag-grid-interactive"
        gridOptions={GRID_OPTIONS}
        onSelectionChanged={onSelectionChanged}
        onRowDataUpdated={onRowDataUpdated}
      />
    </div>
  );
};

export default NewInvoiceTable;
