import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Counter } from 'components/shared/Forms/Custom/Provider/components/PreviousFormDropdown';

const FormList = ({ activeOption, setActiveForm, forms, setStep }) => {
  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);

    return () => setOpacity(0);
  }, []);

  return (
    <div
      className={cs(
        `h-full w-full break-words pt-3 transition-all duration-500 opacity-${opacity}`
      )}>
      {activeOption ? (
        <>
          {forms?.map(({ value, label, count }) => {
            return (
              <div
                key={`${value}-form`}
                className="flex cursor-pointer items-center justify-between px-4 py-3 hover:!bg-neutral-50"
                role="button"
                onClick={() => {
                  setActiveForm(value);
                  setStep(2);
                }}>
                <p>{label}</p>
                <Counter count={count} />
              </div>
            );
          })}
        </>
      ) : null}
    </div>
  );
};

export default FormList;
