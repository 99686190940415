import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import { Honeybadger } from '@honeybadger-io/react';
import moment from 'moment-timezone';
import { useRecoilState } from 'recoil';

import { interimApi } from 'api/InterimApi';

import { useSmartNavigate } from 'lib/hooks/useSmartNavigate';

import Button from 'components/shared/Button/Button';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';

import { appointmentData } from '../schedulingState';

import './css/Confirmation.scss';

export default function Confirmation() {
  const [appData, setAppData] = useRecoilState(appointmentData);
  const [responseMessage, setResponseMessage] = useState(false);
  const [loading, setLoading] = useState(true);
  const { navigate } = useSmartNavigate();

  useEffect(() => {
    if (appData.service_id) {
      if (appData?.practice_require_card && !appData?.hasCards) {
        navigate('/book/card-on-file');
        return;
      }

      createPatientLinks();
    } else navigate('/myportal');
  }, []);

  const getStatus = async () => {
    try {
      await interimApi(
        '/api/patient/appointment/self_schedule',
        { type: 'deleteCookie' },
        navigate
      );
      localStorage.removeItem('stepsCompleted');
    } catch (error) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const createPatientLinks = async () => {
    try {
      const res = await interimApi('/api/practice/patient/link/create', {}, navigate);
      if (res.data.practicePatientLink && res.data.practitionerPatientLink) {
        submitAppointment();
      } else {
        setLoading(false);
        setResponseMessage(false);
      }
    } catch (error) {
      setResponseMessage(false);
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${error}`);
    }
  };

  const submitAppointment = async () => {
    setLoading(true);
    const appointmentToBeCreated = {
      practitioner_id: appData.practitioner_userId,
      service_id: appData.service_id,
      starts_at: appData.starts_at,
      ends_at: appData.ends_at,
      location: appData.appointmentLocation,
      bookedBy: 'patient'
    };

    try {
      if (appData.service_id != null) {
        const res = await interimApi(
          '/api/appointment/create',
          {
            appointment: appointmentToBeCreated,
            checkForExistingAppt: false
          },
          navigate
        );
        if (res.data) {
          setAppData({ ...appData, service_id: null });
          setLoading(false);
          setResponseMessage(true);
          toast.success('Appointment created successfully', { duration: 5000 });
          getStatus();
        }
      }
    } catch (error) {
      setResponseMessage(false);
      console.error(error);
      Honeybadger.notify(
        `file: self_scheduling/steps/confirmation, method: submitAppointment - catch, error: ${
          error ?? 'Theres been an error'
        }`
      );
      toast.error('Appointment creation failed');
    }
  };

  return (
    <div className="w-full">
      {!loading ? (
        <>
          {responseMessage ? (
            <div className="ConfirmationContainer">
              <div
                className={
                  appData.practice_instant_booking ? 'Giant_checkmark' : 'Pending_giant_checkmark'
                }>
                <Icon icon="new-check" size={30} color="white" />
              </div>
              <div className="ConfirmationContentContainer">
                <div className="ContactTitle">
                  {appData.practice_instant_booking
                    ? 'You successfully created an appointment!'
                    : 'You successfully requested an appointment!'}
                </div>
                <div className="InnerConfirmationContentContainer">
                  <div className="ContentStyle">
                    <h3>Date</h3>
                    <p>
                      {moment.tz(appData.starts_at, appData.practice_timezone).format('M/D/YYYY')}
                    </p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Time</h3>
                    <p>
                      {moment.tz(appData.starts_at, appData.practice_timezone).format('h:mm A')} -
                      {moment.tz(appData.ends_at, appData.practice_timezone).format('h:mm A z')}
                    </p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Practice</h3>
                    <p>{appData.practice_name}</p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Practitioner</h3>
                    <p>{appData.practitioner_name}</p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Service</h3>
                    <p>{appData.service_name}</p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Service Length</h3>
                    <p>{appData.service_length} min</p>
                  </div>
                  <div className="ContentStyle">
                    <h3>Status</h3>
                    <p>{appData.practice_instant_booking ? 'Approved' : 'Pending Approval'}</p>
                  </div>
                </div>
                <div className="InnerConfirmationContentContainer">
                  <div className="ContentStyle">
                    <Button primary onClick={() => navigate('/myportal')}>
                      Go to my portal
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="ConfirmationContainer">
              <div className="Giant_cross">
                <Icon icon="new-check" size={30} color="white" />
              </div>
              <div className="ConfirmationContentContainer">
                <div className="ContactTitle">Something has gone wrong, please try again!</div>
              </div>
            </div>
          )}
        </>
      ) : (
        <Skeleton count={3} height={80} width={240} />
      )}
    </div>
  );
}
