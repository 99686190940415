import { useQueryClient } from '@tanstack/react-query';
import { socket } from 'api/Socket';
import cs from 'classnames';
import AGTable from 'components/shared/AGTable/AGTable';
import ErrorBoundary from 'components/shared/Error/Boundary';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import React, { useEffect } from 'react';
// import './MedicationsTable.scss';
import { useLocation } from 'react-router-dom';

const MedicationsTable = ({ data = [], getGridApi = () => null, customStyling }) => {
  const { gridApi } = useTableContext();

  const queryClient = useQueryClient();
  const { pathname } = useLocation();

  useEffect(() => {
    socket.on('veradigm-prescribe', () => {
      queryClient.invalidateQueries({ queryKey: ['medications'] });
      queryClient.invalidateQueries({ queryKey: ['medication'] });
    });

    return () => {
      socket.emit('veradigm-prescribe-off', {});
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      getGridApi(gridApi);
    }
  }, [gridApi]);

  const defaultColDef = {
    flex: 1
  };

  return (
    <ErrorBoundary>
      <div
        className={cs(
          'flex h-full flex-col overflow-hidden !pb-0',
          pathname.includes('myscribe-ai') && 'h-[350px]',
          customStyling
        )}>
        <div className="ag-theme-quartz flex-grow">
          <AGTable
            data={data}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            customClassName="ag-grid-interactive"
            defaultColDef={defaultColDef}
            // onCellClicked={onCellClicked}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default MedicationsTable;
