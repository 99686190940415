import React, { useState } from 'react';
import cs from 'classnames';
import moment from 'moment-timezone';
import { Popover } from '@headlessui/react';
import Icon from 'components/shared/Icon/Icon';

const Months = ({ currentMonth, changeMonthHandle }) => {
  const dateFormat = 'MMMM';
  const formattedMonth = moment(currentMonth).format(dateFormat);
  const months = moment.months();
  const [isHover, setIsHover] = useState(false);

  const handleMonthSelect = (selectedMonth) => {
    const newDate = moment(currentMonth).month(selectedMonth);
    changeMonthHandle(newDate);
  };

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <div>
          <Popover.Button
            className={cs(
              'group flex min-w-[86px] cursor-pointer select-none items-center gap-[6px] rounded-md bg-white px-[10px] py-[5px] xs:min-w-[70px]',
              open
                ? '!border-primary-300 !bg-primary-700 outline outline-2 outline-primary-50'
                : '!border-transparent'
            )}
            data-qa="calendar-month"
            as="div"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            <span
              className={cs(
                'text-sm font-500',
                open ? 'text-white' : 'text-primary-900 group-hover:text-primary-700'
              )}>
              {formattedMonth}
            </span>
            <Icon
              icon="chevron-down"
              className={cs('ml-auto cursor-pointer duration-150', open && 'rotate-180 transform')}
              size={11}
              shade={isHover ? 700 : 900}
              color={open ? 'white' : 'primary'}
            />
          </Popover.Button>
          <Popover.Panel className="absolute right-0 z-40 mt-[6px] grid w-[136px] cursor-pointer gap-y-1 rounded-lg bg-white !py-2 shadow-medium">
            {months.map((option, index) => (
              <div
                key={index}
                className={cs(
                  'flex gap-x-2 py-[5px] !pl-4 pr-[14px] transition-all hover:bg-primary-50',
                  formattedMonth === option && 'bg-primary-50'
                )}
                onClick={() => {
                  handleMonthSelect(option);
                  close();
                }}>
                <span className="text-sm font-400 text-primary-900">{option}</span>
              </div>
            ))}
          </Popover.Panel>
        </div>
      )}
    </Popover>
  );
};

export default Months;
