export default function getDiagnosesWhenClaim(superbill = {}) {
  return {
    diag_1: superbill?.encounter?.icd_10_codes?.[0] || superbill?.claim?.diag_1 || '',
    diag_2: superbill?.encounter?.icd_10_codes?.[1] || superbill?.claim?.diag_2 || '',
    diag_3: superbill?.encounter?.icd_10_codes?.[2] || superbill?.claim?.diag_3 || '',
    diag_4: superbill?.encounter?.icd_10_codes?.[3] || superbill?.claim?.diag_4 || '',
    diag_5: superbill?.encounter?.icd_10_codes?.[4] || superbill?.claim?.diag_5 || '',
    diag_6: superbill?.encounter?.icd_10_codes?.[5] || superbill?.claim?.diag_6 || '',
    diag_7: superbill?.encounter?.icd_10_codes?.[6] || superbill?.claim?.diag_7 || '',
    diag_8: superbill?.encounter?.icd_10_codes?.[7] || superbill?.claim?.diag_8 || '',
    diag_9: superbill?.encounter?.icd_10_codes?.[8] || superbill?.claim?.diag_9 || '',
    diag_10: superbill?.encounter?.icd_10_codes?.[9] || superbill?.claim?.diag_10 || '',
    diag_11: superbill?.encounter?.icd_10_codes?.[10] || superbill?.claim?.diag_11 || '',
    diag_12: superbill?.encounter?.icd_10_codes?.[11] || superbill?.claim?.diag_12 || ''
  };
}
