import { Honeybadger } from '@honeybadger-io/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { ia, optionify } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import state from '../state';
import NewTerminal from './newTerminal';
import TerminalEntry from './terminalEntry';
import TerminalTable from './terminalTable';
import { requestApi } from 'api/Api';
import Allowed from 'components/shared/Permissions/Allowed';

const LIMIT = 25;

const TerminalManagement = () => {
  const [terminals, setTerminals] = useState([]);
  const [show, setShow] = useState({ newTerminal: false, loadMore: true, loading: false });
  const [terminalGroups, setTerminalGroups] = useRecoilState(state.terminalGroupState);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getTerminals();
  }, []);

  const getTerminals = async () => {
    if (!show.loadMore) return;
    let newShow = Object.assign({}, show);
    newShow.loading = true;
    try {
      const res = await requestApi({
        url: '/api/transactions/terminal/list',
        params: {
          practiceId: id,
          offset: terminals.length,
          limit: LIMIT
        },
        navigate
      });
      const { code, redirect, error, terminalData } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setTerminals([...terminalData]);
          if (terminalData.length < LIMIT) {
            show.loadMore = false;
          }
          break;

        default:
          Honeybadger.notify(
            `file: /terminals/index, method: getTerminals - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/index, method: getTerminals - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
    newShow.loading = false;
    setShow(newShow);
  };

  const getTerminalGroups = async () => {
    try {
      const res = await requestApi({
        url: '/api/transactions/group/get',
        params: { practiceId: id },
        navigate
      });
      const { code, redirect, error, groups } = res;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          setTerminalGroups(optionify(groups));
          break;
        default:
          Honeybadger.notify(
            `file: /terminals/index, method: getTerminalGroups - try, error: ${
              error ?? 'An unexpected error has occurred.'
            }`
          );
          if (error) toast.error(error);
          break;
      }
    } catch (error) {
      Honeybadger.notify(
        `file: /terminals/index, method: getTerminalGroups - catch, error: ${
          error ?? 'An unexpected error has occurred.'
        }`
      );
    }
  };

  const handleSuccess = (e) => {
    setTerminals([...terminals, e]);
    setShow({ ...show, newTerminal: false });
  };

  return (
    <Allowed requiredPermissions="terminal.read" showMessage showIllustration>
      <div className="flex h-fit min-h-[30vh] flex-col p-[1rem]">
        <div className="!mb-5 flex justify-between">
          <h6>Terminal Management</h6>
          <Allowed requiredPermissions="terminal.create">
            <Button
              text="+ Add new"
              size="small"
              onClick={() => setShow({ ...show, newTerminal: true })}
            />
          </Allowed>
        </div>
        <TerminalTable>
          {ia(terminals) && terminals.map((v) => <TerminalEntry practiceId={id} terminal={v} />)}
        </TerminalTable>
        <NewTerminal
          handleOpen={show.newTerminal}
          handleClose={() => setShow({ ...show, newTerminal: false })}
          practiceId={id}
          handleSuccess={handleSuccess}
          onClose={() => setShow({ ...show, newTerminal: false })}
        />
      </div>
    </Allowed>
  );
};

export default TerminalManagement;
