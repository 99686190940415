import React from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { documentCategories } from 'constants';

import { upsertResponse } from 'api/CustomForms';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

import formatFilters from '../lib/formatFilters';

const EditDocument = ({
  edit,
  setEdit,
  handle,
  type,
  action,
  loading,
  patientId,
  submitFormButton,
  handleCloseModal
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { filters, page, limit, sort } = useTableContext();

  const handleFormSubmit = async (e, responseId, appointmentId, formId, formVersion) => {
    const updatedResponses = e?.map((response) => {
      return response.name
        ? { ...response }
        : { ...response, name: 'no_name', custom_name: 'no_custom_name' };
    });

    let params = {
      responseId,
      appointmentId,
      patientId,
      formId,
      json: { fields: JSON.stringify(updatedResponses) },
      formVersion
    };

    const res = await upsertResponse(navigate, params);

    if (res?.upsert) {
      queryClient.setQueryData(
        ['documents', formatFilters({ filters, page, limit, sort, patientId }), patientId],
        (old) => {
          return {
            ...old,
            documents: old?.documents?.map((item) => {
              if (item?.id === responseId) {
                return {
                  ...item,
                  json: res?.upsert?.json
                };
              } else {
                return item;
              }
            })
          };
        }
      );

      showAlert({ title: 'Document edited successfully', color: 'success' });
      handleCloseModal();
    } else {
      showAlert({
        title: 'Necessary permissions not present or error encountered',
        color: 'danger'
      });
    }
  };

  if (!loading.filestack && action === 'Edit') {
    if (type === 'form') {
      return (
        <div className="">
          <ReactFormGenerator
            answer_data={handle?.json?.fields && JSON.parse(handle?.json?.fields)}
            data={handle?.form_json?.fields}
            submitButton={
              <div className="flex w-full justify-center gap-2 px-4">
                <Button
                  text="Update"
                  className="hidden"
                  color="primary"
                  buttonType="submit"
                  forwardedRef={submitFormButton}
                />
              </div>
            }
            onSubmit={(e) =>
              handleFormSubmit(
                e,
                handle?.response_id,
                handle?.appointment_id,
                handle?.form_id,
                handle?.form_version
              )
            }
          />
        </div>
      );
    } else {
      return (
        <div className="mt-6 flex flex-col gap-2">
          <h3 className="text-lg font-400 text-primary-900">Edit</h3>

          <Input
            label="Document name"
            placeholder="Filename"
            value={edit.name}
            onChange={(e) => setEdit((p) => ({ ...p, name: e.target.value }))}
          />

          <Select
            isClearable
            label="Document Category"
            options={documentCategories}
            value={edit.category}
            className="Select"
            placeholder="Category"
            onChange={(e) => setEdit((p) => ({ ...p, category: e }))}
            menuPortalTarget={document.body}
          />
        </div>
      );
    }
  } else {
    return null;
  }
};

export default EditDocument;
