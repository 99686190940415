import _ from 'lodash';

const {
  generateAdvancedSectionFormMap
} = require('components/practice/charts/ClinicalNote/lib/processCustomNoteTypes');
const {
  updateHiddenComponents
} = require('components/practice/charts/ClinicalNote/MyScribeAI/Templates/shared/lib/helpers');

const getInitialTypes = (initialTypeOfNotes, cnDisplaySettings, customFormTypes) => {
  const sectionMap = {
    hp: cnDisplaySettings?.hp?.enabled,
    intake: cnDisplaySettings?.mha?.enabled,
    soap: cnDisplaySettings?.soap?.enabled,
    progress: cnDisplaySettings?.epn?.enabled,
    '3d': cnDisplaySettings?.chart3d?.enabled,
    ...generateAdvancedSectionFormMap({ cnDisplaySettings, customFormTypes })
  };

  const enabledSection = Object.entries(sectionMap).find(([, isEnabled]) => isEnabled)?.[0];
  const initialType = cnDisplaySettings?.soap.enabled ? 'soap' : enabledSection;

  const newChatForms = {
    ...initialTypeOfNotes,
    [initialType]: {
      ...initialTypeOfNotes?.[initialType],
      checked: true
    }
  };

  const updatedFormsWithHiddenComponents = updateHiddenComponents(newChatForms);

  return updatedFormsWithHiddenComponents;
};

export const getInitialFormsState = ({
  typeOfNotes,
  cnDisplaySettings,
  customFormTypes,
  settings
}) => {
  let initialForms = getInitialTypes(typeOfNotes, cnDisplaySettings, customFormTypes);

  if (settings) {
    const enabledForms = {};
    for (const key in typeOfNotes) {
      const type = settings[key] || {};
      enabledForms[key] = { ...initialForms[key], checked: !!type?.enabled };

      const forms = type?.forms || {};
      for (const formKey in forms) {
        if (enabledForms?.[key]?.['components']?.[formKey]) {
          enabledForms[key]['components'][formKey] = {
            ...initialForms[key]?.components?.[formKey],
            checked: !!type?.forms?.[formKey]?.checked
          };
        }
      }
    }
    initialForms = enabledForms;
  }
  return initialForms;
};
