import React from 'react';
import SanitizeComponent from '../../Sanitize/SanitizeComponent';
import { useRecoilValue } from 'recoil';
import { clinicalNote as clinicalNoteState } from 'components/state';

const SessionQuotesAndContents = ({ customClinicalNote = null }) => {
  let clinicalNote = useRecoilValue(clinicalNoteState);
  if (customClinicalNote) {
    clinicalNote = customClinicalNote?.clinicalNote;
  }
  const sessionQuotesAndContents = clinicalNote?.epn?.sessionQuotesAndContents;

  if (customClinicalNote && !sessionQuotesAndContents) {
    return null;
  }

  return (
    <div className="bg-white !px-6 !py-4">
      <SanitizeComponent title="Session quotes and contents" value={sessionQuotesAndContents} />
    </div>
  );
};

export default SessionQuotesAndContents;
