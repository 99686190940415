import { requestApi } from './Api';

export const getFormPackets = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/forms/form_packet/get_all',
    params
  });

export const createForm = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/forms/upsert',
    params
  });

export const getDraftedForms = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/forms/drafts',
    params
  });

export const getForms = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/forms/get_all',
    params
  });

export const deleteForm = async (navigate, params) =>
  await requestApi({
    navigate,
    url: '/api/practice/settings/forms/delete',
    params
  });
