import '!style-loader!css-loader!react-vertical-timeline-component/style.min.css';
import Icon from 'components/shared/Icon/Icon';
import Textarea from 'components/shared/Textarea/Textarea';
import { isEmpty } from 'lib/helpers/utility';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import Button from '../../shared/Buttons/Button';
import Loading from '../../shared/Loading/Loading';
import Allowed from 'components/shared/Permissions/Allowed';
import './History.scss';

export default function History({
  notes,
  note,
  setNote,
  saveNote,
  setConfirmDelete,
  setNoteToDelete,
  forwardedRef,
  saveLoading,
  loading,
  setShowNotes
}) {
  const bottomOfComponent = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      bottomOfComponent.current?.scrollIntoView({ behavior: 'smooth' });
    }, 1000);
  }, [notes, loading, bottomOfComponent]);

  const TimeLineElement = ({ item, key }) => {
    return (
      <div className="mb-12 flex">
        <div className="w-full">
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{
              background: '#fff',
              color: '#4a4a4a'
            }}
            contentArrowStyle={{
              borderRight: '7px solid  rgb(239,250,244)'
            }}
            date={moment(item?.timestamp).format('MMM DD, YYYY hh:mm A')}
            dateClassName="DateStyling"
            iconClassName="CustomIcon"
            iconStyle={{
              background: '#d9dbe0',
              color: '#fff'
            }}>
            <h5
              className="vertical-timeline-element-title"
              style={{
                color: '#4a4a4a'
              }}>
              {item?.user?.fullName}
            </h5>
            <h6
              style={{ marginTop: '8px' }}
              ref={key === notes.length - 1 ? bottomOfComponent : null}>
              {item?.note}
            </h6>
          </VerticalTimelineElement>
        </div>
        <div className="my-auto ml-3">
          <Allowed requiredPermissions="patient_sticky_notes.delete">
            <Icon
              icon="trash"
              data-qa="remove-btn"
              onClick={() => {
                setNoteToDelete({ id: key });
                setConfirmDelete(true);
              }}
            />
          </Allowed>
        </div>
      </div>
    );
  };

  return (
    <div className="History" ref={forwardedRef}>
      <div className="VTimelineWrapper">
        {loading ? (
          <div className="LoadingWrapper">
            <Loading />
          </div>
        ) : (
          <>
            <VerticalTimeline lineColor="#d9dbe0" layout="1-column-left" className="VTimeline">
              {notes?.map((item, index) => {
                return TimeLineElement({ key: index, item });
              })}
            </VerticalTimeline>
            <div ref={bottomOfComponent} />
          </>
        )}
      </div>
      <Allowed requiredPermissions="patient_sticky_notes.create">
        <Textarea
          data-qa="stickyNotes-write-note"
          placeholder="Write note here..."
          textareaClassName="min-h-[100px] resize-y"
          name="note"
          forwardedRef={textareaRef}
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
      </Allowed>
      <div className="!mt-4 flex items-center justify-between">
        <Button
          onClick={() => setShowNotes(false)}
          data-qa="stickyNotes-close-btn"
          text="Close"
          color="neutral"
          outlined
        />
        <Allowed requiredPermissions="patient_sticky_notes.create">
          <Button
            onClick={saveNote}
            disabled={isEmpty(note) || saveLoading}
            data-qa="stickyNotes-save-btn"
            loading={saveLoading}
            text="Save"
          />
        </Allowed>
      </div>
    </div>
  );
}
