import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { useFormik } from 'formik';
import DatePopover from 'components/shared/Popovers/Date/DatePopover';
import Icon from 'components/shared/Icon/Icon';
import moment from 'moment';
import Button from 'components/shared/Buttons/Button';
import Input from 'components/shared/Input/Input';
import checkinBackgroundPNG from 'assets/Images/checkin-background.png';
import { requestApi } from 'api/Api';
import { showAlert } from 'components/shared/Alert/Alert';

const InstantAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { token } = useParams();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      date: null
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      date: Yup.date().required('Date of birth is required')
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const onSuccess = async () => {};
      const onError = (error, code) => {
        showAlert({
          title: code == 2 ? 'Wrong credentials' : 'Something went wrong',
          color: 'danger'
        });
      };
      setSubmitting(true);
      await requestApi({
        url: '/api/link/auth',
        onSuccess,
        onError,
        params: { token, ...values },
        navigate,
        filePath: `${__dirname}/${__filename}`
      });
      setSubmitting(false);
    }
  });

  return (
    <div className="h-[100svh] overflow-hidden bg-transparent">
      <img
        src={checkinBackgroundPNG}
        className="absolute left-0 top-0 z-10 h-full w-full object-cover"
      />
      <div className="relative z-20 !mt-3 flex h-full w-full flex-col justify-between !px-10 !pb-4 !pt-0 xs:!p-4">
        <div className="flex flex-col !gap-5">
          <div className="flex flex-wrap items-center justify-between gap-x-4 gap-y-2">
            <div className="w-full pb-3 pt-5 text-center sm:!pt-3">
              <p className="text-2xl font-600 leading-8 text-white">{`Welcome to ${'Myriad health'}`}</p>
              <p className="text-base font-400 leading-6 text-white">
                Please take a moment to sign-in below upon arrival.
              </p>
            </div>
          </div>
          <div className="mx-auto w-full max-w-[976px] rounded-2xl bg-white">
            <div className="rounded-t-2xl bg-primary-50 !px-8 !py-3 xs:!px-4">
              <p className="text-lg font-600 leading-7 text-primary-900">Patient Sign-in</p>
              <p className="font-regular mt-0 text-base text-neutral-600">
                We request all patients sign-in upon arrival
              </p>
            </div>
            <div className="!px-8 !py-4 xs:!px-4 xs:!pb-3 xs:!pt-2">
              <div className="grid grid-cols-[repeat(auto-fit,minmax(140px,1fr))] gap-4 xs:flex xs:flex-col xs:gap-3">
                <Input
                  label="First name"
                  name="firstName"
                  inputWrapperClassName="!h-[46px] sm:!h-[40px]"
                  labelClassName="text-primary-900"
                  placeholder="Enter your first name"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={formik.errors.firstName}
                  formik={formik}
                  validateName
                  focusNextFieldOnEnter
                />
                <Input
                  label="Last name"
                  name="lastName"
                  placeholder="Enter your last name"
                  inputWrapperClassName="!h-[46px] sm:!h-[40px]"
                  labelClassName="text-primary-900"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={formik.errors.lastName}
                  formik={formik}
                  validateName
                  focusNextFieldOnEnter
                />
                <DatePopover
                  label="Date of birth"
                  placeholder="mm/dd/yyyy"
                  name="date"
                  inputClassName="!h-[46px] sm:!h-[40px]"
                  labelClassName="text-primary-900"
                  placementX="right-0"
                  value={
                    formik.values.date
                      ? new Date(moment.utc(new Date(formik.values.date)) || null)
                      : null
                  }
                  onChange={(date) => {
                    formik.setFieldValue('date', date);
                  }}
                  formik={formik}
                  focusNextFieldOnEnter
                />
              </div>
              <Button
                size="large"
                text="Sign-in"
                className="!mt-4 w-full"
                onClick={() => formik.submitForm()}
              />
              <div className="!mt-3 flex select-none flex-wrap items-center justify-between gap-x-6 gap-y-2">
                <div className="ml-auto flex items-center gap-x-[6px]">
                  <p className="text-xs font-400 text-primary-500">Powered by</p>
                  <Icon icon="logo-without-symbol" size={76} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          className="!mb-4 ml-auto cursor-pointer select-none text-sm font-400 text-white"
          onClick={() => {
            localStorage.setItem('myriad-kiosk-prev-url', pathname);
            navigate('/terms');
          }}>
          Terms & Conditions
        </p>
      </div>
    </div>
  );
};

export default InstantAuth;
