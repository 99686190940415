import React from 'react';

import Skeleton from 'components/shared/Skeleton/Skeleton';
import Header from 'components/shared/shared/Header';

const DetailRow = ({ label, value }) => {
  return (
    <>
      <Header label={label} />
      <p className="col-span-2 font-500">{value || '-'}</p>
    </>
  );
};

const SampleDetails = ({ details, loading }) => {
  return (
    <div className="grid grid-cols-3">
      <Header label="Category" labelClassName="font-600" />
      <Header label="Description" headerClassName="col-span-2" labelClassName="font-600  " />

      {loading ? (
        <>
          <div className="pr-2">
            <Skeleton count={6} height="28px" />
          </div>
          <div>
            <Skeleton count={6} height="28px" />
          </div>
        </>
      ) : (
        <>
          <DetailRow label="Sample Type" value={details?.sampleType} />
          <DetailRow label="Container" value={details?.container} />
          <DetailRow label="Draw Req" value={details?.specimenRequirements} />
          <DetailRow label="Transport Mode" value={details?.transport} />
          <DetailRow label="Transport Group" value={details?.sampleState} />
        </>
      )}
    </div>
  );
};

export default SampleDetails;
