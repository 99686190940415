export function generateSettingsList(userState) {
  return {
    services: {
      path: 'services',
      name: 'Services',
      permission: 'services.read',
      icon: 'new-star'
    },
    products: {
      path: 'products',
      name: 'Products',
      permission: 'products.read',
      icon: 'new-pill'
    },
    packages: {
      path: 'packages',
      name: 'Packages',
      permission: 'packages.read',
      icon: 'new-packages'
    },
    calendar: {
      path: 'calendar',
      name: 'Calendar',
      icon: 'new-calendarv2'
    },
    forms: {
      path: 'forms',
      name: 'Forms',
      icon: 'new-calendar-note',
      permission: 'form.read',
      open: userState?.display_settings?.sidebars?.settings?.['forms']?.open ?? true,
      children: [
        {
          path: 'forms',
          name: 'My Forms',
          end: true
        },
        {
          path: 'forms/form-packets',
          permission: 'form_packet.read',
          name: 'Form Packets',
          end: true
        }
      ]
    },
    payers: {
      path: 'payers',
      name: 'Payers',
      icon: 'new-payers'
    },
    account: {
      path: 'account',
      name: 'Account',
      icon: 'user-square'
    },
    staff: {
      path: 'staff',
      name: 'Staff',
      permission: 'member.read',
      icon: 'new-three-people'
    },
    roles: {
      path: 'roles',
      name: 'Roles & Permissions',
      permission: 'role.read',
      icon: 'new-patients'
    },
    eprescribe: {
      path: 'eprescribe',
      name: 'ePrescribe',
      permission: 'prescribe.read',
      icon: 'new-eprescribe-v2'
    },
    billing: {
      path: 'billing',
      name: 'Billing',
      permission: 'billing.read',
      icon: 'wallet'
    },
    kiosk: {
      path: 'kiosk',
      name: 'Kiosk',
      permission: 'kiosk.read',
      icon: 'new-kiosk'
    },
    resources: {
      path: 'resources',
      name: 'Resources',
      icon: 'new-menu-board',
      permission: 'resources.read'
    },
    tags: {
      path: 'tags',
      name: 'Tags',
      icon: 'new-tag',
      permission: 'tags.read'
    }
  };
}
