import { showAlert } from '../components/shared/Alert/Alert';
import { requestApi } from './Api';

export const getNotifications = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/notifications/get',
    params
  });

export const updateNotifications = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/notifications/update',
    params,
    onError: () => {
      showAlert({
        title: 'Something went wrong!',
        message: 'If this error occurs please try again later',
        color: 'danger'
      });
    }
  });

export const updateMultipleNotifications = async (navigate, params) =>
  await requestApi({
    navigate,
    url: 'api/notifications/update_multiple',
    params
  });
