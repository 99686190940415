import React from 'react';
import CreationInfo from './CreationInfo';

const NoKnown = ({ form = null, description = 'No known details', shouldShowDivider = true }) => {
  if (!form) return null;

  return (
    <div>
      {shouldShowDivider && <div className="!my-[6px] h-[1px] w-full bg-neutral-100"></div>}

      <span className="text-sm font-400 text-neutral-600 first-letter:capitalize">
        {description}
      </span>

      <CreationInfo createdAt={form?.createdAt} createdBy={form?.createdBy?.fullName} />
    </div>
  );
};

export default NoKnown;
