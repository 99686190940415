import _ from 'lodash';
import { getRecoil, setRecoil } from 'recoil-nexus';

import state from 'components/state';

const updateClinicalNote = (data) => {
  try {
    const clinicalNote = getRecoil(state.clinicalNote);
    // const updateNarrative = (key, value) => {
    //   setRecoil(state.clinicalNote, {
    //     ...clinicalNote,
    //     [key]:
    //       typeof clinicalNote[key] === 'object' ? { ...clinicalNote[key], narrative: value } : value
    //   });
    // };

    if (typeof data?.destId === 'number' && state?.clinicalNote?.id !== data?.destId) {
      return;
    }

    const { vertical, form_name, form_type, response } = data;

    if (['soap', 'hp', 'clinical_narrative'].includes(vertical)) {
      if (
        ['present_illness', 'other_complaints', 'ros', 'exam'].includes(form_type) ||
        (['soap'].includes(form_name) && !['medicalHistory'].includes(form_type))
      ) {
        _.set(clinicalNote, form_type, response);

        setRecoil(state.clinicalNote, (ps) => ({ ...ps, [form_type]: response }));
      } else if (
        typeof _.get(clinicalNote, `${form_name}.${form_type}`) === 'string' ||
        !_.get(clinicalNote, `${form_name}.${form_type}`)
      ) {
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_name}.${form_type}`, response)
        );
      } else if (
        typeof _.get(clinicalNote, `${form_type}`) === 'string' ||
        !_.get(clinicalNote, `${form_type}`)
      ) {
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_type}`, response)
        );
      } else if (
        typeof _.get(clinicalNote, `${form_name}`) === 'string' ||
        !_.get(clinicalNote, `${form_name}`)
      ) {
        setRecoil(state.clinicalNote, (clinicalNote) =>
          _.set(clinicalNote, `${form_name}`, response)
        );
      }
    }

    const newAiClinicalNoteState = getRecoil(state.intelligenceAiState);
    if (newAiClinicalNoteState?.timeoutTimeout)
      clearTimeout(newAiClinicalNoteState?.timeoutTimeout);
    setRecoil(state.intelligenceAiState, (ps) => ({
      ...ps,
      loading: !!!data?.done,
      response: data?.response,
      path: `${vertical}.${data?.form_name}.${data?.form_type}`
    }));
  } catch (e) {
    console.log(e);
  }
};

export default updateClinicalNote;
