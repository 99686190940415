import { ia } from 'lib/helpers/utility';

export const addAdjustment = (setProcedures, code, index) => {
  const newAdjustmentObj = { amount: '', group: '', code: '' };
  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure, idx) => {
      if (idx === index) {
        if (procedure.adjustment) {
          return { ...procedure, adjustment: [...procedure.adjustment, newAdjustmentObj] };
        }

        return { ...procedure, adjustment: [newAdjustmentObj] };
      }
      return procedure;
    });
  });
};

export const deleteAdjustment = (setProcedures, adjustmentIndex, index) => {
  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure, idx) => {
      if (idx === index && ia(procedure.adjustment)) {
        const newAdjustment = procedure.adjustment.slice();
        newAdjustment.splice(adjustmentIndex, 1);

        return { ...procedure, adjustment: newAdjustment };
      }
      return procedure;
    });
  });
};

export const updateAdjustmentProperty = (
  setProcedures,
  adjustmentIndex,
  propertyName,
  newValue,
  index
) => {
  setProcedures((prevProcedures) => {
    return prevProcedures.map((procedure, idx) => {
      if (adjustmentIndex == null && idx === index) {
        return { ...procedure, [propertyName]: newValue };
      }

      if (idx === index) {
        const newAdjustment = procedure.adjustment.slice();

        if (newAdjustment[adjustmentIndex]) {
          newAdjustment[adjustmentIndex] = {
            ...newAdjustment[adjustmentIndex],
            [propertyName]: newValue
          };

          return { ...procedure, adjustment: newAdjustment };
        }
      }

      return procedure;
    });
  });
};
