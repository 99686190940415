import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSuperbills } from '../../../../lib/hooks/queries/superbill/useSuperbills';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { currentPractice } from '../../practiceState';
import Button from '../../../shared/Buttons/Button';
import { completeSuperbill, deleteSuperbill } from '../../../../api/Superbill';
import { showAlert } from '../../../shared/Alert/Alert';
import { ia, mapValues } from '../../../../lib/helpers/utility';
import Confirm from '../../../shared/Modal/Confirm/Confirm';
import Header from '../../../shared/Header/Header';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { defaultFilters, superbillTableColumns } from './lib';
import AGTable from 'components/shared/AGTable/AGTable';
import TableCounter from 'components/shared/Table/TableCounter';
import Pagination from 'components/shared/Pagination/Pagination';
import Filter from 'components/shared/Filters/Filter';
import { useTableContext } from 'lib/context/TableContext/TableContext';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import moment from 'moment';
import Allowed from 'components/shared/Permissions/Allowed';

export default function Superbills() {
  const { state = 'draft' } = useParams();
  const practice = useRecoilValue(currentPractice);
  const cols = superbillTableColumns(practice.timezone);
  return (
    <TableContextProvider
      name="practice_superbills"
      cols={cols}
      pagination
      defaultFilters={defaultFilters}>
      <SuperbillsContent key={state} />
    </TableContextProvider>
  );
}

function SuperbillsContent() {
  const [selectedSuperbills, setSelectedSuperbills] = useState([]);
  const [loading, setLoading] = useState({ delete: false, complete: false });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const {
    limit,

    page,
    setPage,
    sort,
    gridApi,
    filters,
    setFilters
  } = useTableContext();
  const { state = 'draft' } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isLoading, isFetching } = useSuperbills({
    params: {
      limit,
      page,
      sort,
      filters: mapValues(filters),
      state
    },
    dependencies: [
      limit,
      page,
      sort,
      state,
      moment(filters.date?.values?.endDate).format('YYYY-MM-DD'),
      moment(filters.date?.values?.startDate).format('YYYY-MM-DD'),
      filters.billing_provider_searchTerm?.values,
      filters.patient_searchTerm?.values,
      filters.payer_searchTerm?.values,
      filters.provider_searchTerm?.values,
      filters.superbill_id?.values
    ]
  });

  const superbills = data?.superbills || [];
  const count = data?.count || null;

  useEffect(() => {
    setFilters((prev) => ({ ...prev, state, page: 1 }));
    setSelectedSuperbills([]);
  }, [state]);

  const mutateDelete = useMutation({
    mutationFn: () => deleteSuperbill(navigate, { selectedSuperbills }),
    onMutate: () => {
      setLoading((prev) => ({ ...prev, delete: true }));
    },
    onSettled: () => {
      setLoading((prev) => ({ ...prev, delete: false }));
    },
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['superbills']);
          setSelectedSuperbills([]);
          showAlert({
            title: `Superbills deleted successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to delete superbills`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to delete superbills`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const mutateComplete = useMutation({
    mutationFn: () => completeSuperbill(navigate, { selectedSuperbills }),
    onMutate: () => {
      setLoading((prev) => ({ ...prev, complete: true }));
    },
    onSettled: () => {
      setLoading((prev) => ({ ...prev, complete: false }));
    },
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['superbills']);
          setSelectedSuperbills([]);
          showAlert({
            title: `Superbills marked complete successfully!`
          });
          break;
        case 2:
          showAlert({
            title: `Failed to mark superbills complete`,
            message: 'You don’t have permission to perform this action.',
            icon: 'new-close-circle',
            color: 'danger'
          });
          break;

        default:
          showAlert({
            title: `Failed to mark superbills complete`,
            icon: 'new-close',
            color: 'danger'
          });
          break;
      }
    }
  });

  const isFirstColumn = (params) => {
    var displayedColumns = params.api.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  };

  const defaultColDef = useMemo(() => {
    return {
      minWidth: 100,
      headerCheckboxSelection: isFirstColumn,
      checkboxSelection: isFirstColumn
    };
  }, []);

  const onRowClicked = (event) => {
    if (!event.event.target.closest('.ag-selection-checkbox')) {
      const { id, patient_id } = event?.data || {};
      const link = `/portal/charts/${patient_id}/billing/superbill/${id}`;
      window.open(link, '_blank');
    }
  };

  const onSelectionChanged = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data).map((d) => d.id);

      setSelectedSuperbills(selectedData);
    }
  };

  return (
    <>
      <Header title="Superbills" subtitle={state} showInfoInRecordings={true}>
        <div className="flex flex-wrap items-center gap-4" data-dd-privacy="allow">
          <div className="flex items-center gap-2">
            <Allowed requiredPermissions="superbill.delete">
              <Button
                data-qa="delete-selected-superbill"
                onClick={() => setShowConfirmation(true)}
                text="Delete"
                loading={loading.delete}
                disabled={
                  selectedSuperbills.length === 0 ||
                  loading.scrub ||
                  loading.delete ||
                  loading.complete
                }
                color="danger"
              />
            </Allowed>
            <Allowed requiredPermissions="superbill.update">
              <Button
                data-qa="complete-selected-superbill"
                onClick={() => mutateComplete.mutate()}
                text="Mark Complete"
                loading={loading.complete}
                disabled={
                  selectedSuperbills.length === 0 ||
                  loading.scrub ||
                  loading.delete ||
                  loading.complete
                }
                color="success"
              />
            </Allowed>
          </div>

          <DisplayButton />
          <Filter
            category="practice_superbills"
            defaultFilters={defaultFilters}
            filters={filters}
            setFilters={setFilters}
          />
        </div>
      </Header>
      {isLoading || isFetching ? (
        <Skeleton count={filters?.limit} />
      ) : (
        <>
          <AGTable
            data={superbills}
            rowSelection="multiple"
            suppressRowClickSelection={true}
            defaultColDef={defaultColDef}
            customClassName="ag-grid-interactive"
            onRowClicked={onRowClicked}
            onSelectionChanged={onSelectionChanged}
          />
          <div className="flex items-center justify-between">
            <TableCounter page={page} limit={limit} count={count && count[state]} />
            <Pagination
              onPageChange={({ selected }) => setPage(selected + 1)}
              totalItems={count ? count[state] : null}
              page={page}
              perPage={limit}
            />
          </div>
        </>
      )}
      {showConfirmation && (
        <Confirm
          handleOpen={showConfirmation}
          handleClose={() => setShowConfirmation(false)}
          handleContinue={() => {
            mutateDelete.mutate();
            setShowConfirmation(false);
          }}
          title={`Delete superbill${ia(selectedSuperbills, 1) ? 's' : ''}`}
          message={`Are you sure you want to delete the selected superbill${
            ia(selectedSuperbills, 1) ? 's' : ''
          }?`}
          primaryBtnTxt={`Delete superbill${ia(selectedSuperbills, 1) ? 's' : ''}`}
          secondaryBtnTxt="Close"
          icon="new-delete-patient"
          variant="danger"
        />
      )}
    </>
  );
}
