import React, { useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import cs from 'classnames';

import { ia } from 'lib/helpers/utility';
import { useUserActivity } from 'lib/hooks/queries/activity/useUserActivity';

import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import Loading from 'components/shared/Loading/Loading';
import Allowed from 'components/shared/Permissions/Allowed';

import ActionButton from '../shared/Button/ActionButton/ActionButton';

import ActivityGroup from './components/ActivityGroup';
import ActivityModal from './components/ActivityModal';

const UserActivity = ({ className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const [limit, setLimit] = useState(30);
  const {
    data = {},
    isLoading,
    isFetching
  } = useUserActivity({
    params: {
      limit
    },
    dependencies: [limit],
    options: { enabled: isOpen }
  });
  const activityLogs = data?.logs;

  const total = data?.total;
  const returned = data?.returned;

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight === scrollHeight) {
      setLimit(limit + 15);
    }
  };

  const toggleIsOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <Allowed requiredPermissions="user_events.read">
      <div ref={popoverRef} className={cs('flex items-center', className)}>
        <div className="relative !ml-2">
          <ActionButton className="relative p-0" icon="new-activity-log" onClick={toggleIsOpen} />
        </div>
        <ErrorBoundary FallbackComponent={ErrorMessage}>
          <ActivityModal onScroll={handleScroll} isOpen={isOpen} handleClose={toggleIsOpen}>
            {isLoading ? (
              <Skeleton width="100%" height="100%" />
            ) : (
              <div className="space-y-4">
                {ia(activityLogs) &&
                  activityLogs.map((group, index) => (
                    <ActivityGroup
                      key={index}
                      date={group.date}
                      limit={limit}
                      setLimit={setLimit}
                      activities={group.logActivity}
                      toggleIsOpen={toggleIsOpen}
                    />
                  ))}
                {total !== returned && isFetching && <Loading />}
              </div>
            )}
          </ActivityModal>
        </ErrorBoundary>
      </div>
    </Allowed>
  );
};

export default UserActivity;
