import { useQuery } from '@tanstack/react-query';
import { getPatientAggregateNarrative } from 'api/PatientAggregateNarrative';
import { useNavigate } from 'react-router-dom';

export const usePatientAggregateNarrative = ({
  params = {},
  options = {},
  dependencies = []
} = {}) => {
  const navigate = useNavigate();
  return useQuery(
    ['patientAggregateNarrative', ...dependencies],
    () => getPatientAggregateNarrative(navigate, params),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options
    }
  );
};
