/* eslint-disable max-lines */
import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import { useTableContext } from 'lib/context/TableContext/TableContext';

import FaxDocsActions from 'components/practice/charts/Documents/components/FaxDocsActions';
import { currentPractice } from 'components/practice/practiceState';
import Loader from 'components/shared/Loader/Loader';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import { clinicalNote } from 'components/state';

import useDocumentHandler from '../lib/useDocumentHandler';

import DocumentActionsPopover from './DocumentActionsPopover';

const initConfirmation = { delete: false, share: false };

const DocActions = ({
  setViewDocument,
  isEditOpen,
  setIsEditOpen,
  fromPatient,
  patientId,
  cat,
  form,
  index,
  data,
  appointmentId
}) => {
  const { locked: isLocked } = useRecoilValue(clinicalNote);

  const [showConfirmation, setShowConfirmation] = useState(initConfirmation);
  const [currentFile, setCurrentFile] = useState(null);

  const table = useTableContext();

  const {
    shareLoading,
    deleteLoading,
    handleShareWithPatient,
    handleDeletion,
    setSelectedAppointment
  } = useDocumentHandler({
    patientId,
    showConfirmation,
    appointmentId,
    setShowConfirmation,
    table,
    currentFile,
    setCurrentFile
  });

  const [downloadLoading, setDownloadLoading] = useState(false);

  const practice = useRecoilValue(currentPractice);

  const handleConfirmationFile = (props) => {
    setCurrentFile(props);
    setShowConfirmation({ ...initConfirmation, [props?.mode]: true });
  };

  const handleClose = () => {
    setCurrentFile(null);
    setShowConfirmation(initConfirmation);
  };

  if (downloadLoading)
    return (
      <div className="flex items-center justify-center h-full mr-1">
        <Loader outlined />
      </div>
    );

  return (
    <>
      <div onClick={(e) => e.stopPropagation()}>
        {cat?.label === 'eFax' ? (
          <FaxDocsActions
            fax={{ ...data, type: 'fax' }}
            fromPatient={fromPatient}
            currentFile={currentFile}
            handleConfirmationFile={handleConfirmationFile}
            setSelectedAppointment={setSelectedAppointment}
            patientId={patientId}
            practice={practice}
            setViewDocument={setViewDocument}
            setDownloadLoading={setDownloadLoading}
            isLocked={isLocked}
          />
        ) : (
          <DocumentActionsPopover
            {...{
              setViewDocument,
              isEditOpen,
              setIsEditOpen,
              handleConfirmationFile,
              patientId,
              cat,
              fromPatient,
              practice,
              form,
              index,
              data,
              setDownloadLoading,
              setSelectedAppointment,
              appointmentId,
              isLocked
            }}
          />
        )}
      </div>

      <Confirm
        variant="danger"
        secondaryBtnTxt="Keep"
        title="Delete document"
        handleClose={handleClose}
        icon="new-document-remove-red"
        primaryBtnTxt="Delete document"
        handleOpen={showConfirmation?.delete}
        handleContinue={() => handleDeletion(currentFile)}
        message={`Are you sure you want to ${currentFile?.deleteInstances || !appointmentId ? 'delete' : 'remove from note'} '${currentFile?.name}'?`}
        loading={deleteLoading}
      />

      <Confirm
        title={showConfirmation.unshare ? 'Unshare document' : 'Share document'}
        secondaryBtnTxt="Cancel"
        handleClose={handleClose}
        icon="new-arrow-swap-down"
        loading={shareLoading}
        primaryBtnTxt={showConfirmation.unshare ? 'Unshare document' : 'Share document'}
        handleOpen={showConfirmation?.share || showConfirmation?.unshare}
        handleContinue={() => handleShareWithPatient(currentFile)}
        message={`Are you sure you want to ${showConfirmation.unshare ? 'un' : ''}share the document with patient?`}
      />
    </>
  );
};

export default DocActions;
