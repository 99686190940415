export const ALLOWED_KEYS =
  /[0-9]|Backspace|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Delete|Tab|Enter|a/;

export const CURRENCY_UNITS = ['amount', 'currency'];

export const CURRENCY_VARIANTS = {
  percent: 'percent',
  currency: 'currency',
  decimal: 'decimal'
};

export const STYLE_VARIANTS = {
  percent: 'percent',
  currency: 'amount',
  decimal: 'decimal'
};

export const MAX_PERCENT_VALUE = 10000;
export const PERCENT_DIVISOR = 100;
export const PERCENT_AMOUNT_DIVISOR = 10000;
export const DEFAULT_DIVISOR = 1;
export const DEBOUNCE_TIME = 500;
export const ALERT_DURATION = 3000;
