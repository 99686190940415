import { isArray } from 'lodash';
import moment from 'moment';

export default function (event, formattedValues, forRecurrence = false) {
  //convert date to new Date
  event = {
    ...event,
    start_date: new Date(event.start_date),
    until: new Date(event.until),
    end_date: event.end_date ? new Date(event.end_date) : null
  };
  formattedValues = {
    ...formattedValues,
    start_date: new Date(formattedValues.start_date),
    until: new Date(formattedValues.until),
    end_date: formattedValues.end_date ? new Date(formattedValues.end_date) : null
  };

  try {
    return compareObjects(
      {
        ...event,
        weekdays: isArray(event.weekdays) ? JSON.stringify(event.weekdays) : [],
        start_date: forRecurrence
          ? moment(event.start_date).format('YYYY/MM/DD')
          : event.start_date,
        until: forRecurrence ? moment(event.until).format('YYYY/MM/DD') : event.until
      },
      {
        ...formattedValues,
        end_date: formattedValues.end_date,
        start_date: forRecurrence
          ? moment(formattedValues.start_date).format('YYYY/MM/DD')
          : formattedValues.start_date,
        until: forRecurrence
          ? moment(formattedValues.until).format('YYYY/MM/DD')
          : formattedValues.until
      }
    );

    function compareObjects(obj1 = {}, obj2 = {}) {
      const changes = {};

      if (formattedValues.type === 'following') {
        return formattedValues;
      }

      for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
          if (obj2.hasOwnProperty(key)) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
              changes[key] = obj2[key];
            }
          }
        }
      }
      if (formattedValues.type === 'only') {
        changes.start_date = formattedValues.start_date;
        changes.until = formattedValues.until;
        changes.all_day = formattedValues.all_day;
        changes.title = formattedValues.title;
      }
      return changes;
    }
  } catch (error) {
    console.log('error', error);
    return {};
  }
}
