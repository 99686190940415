import React, { useRef } from 'react';
import Accordion from '../../../../shared/Accordion';
import CPTCodes from '../../SOAPNote/components/CPTCodes/CPTCodes';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilValue } from 'recoil';

const CPTCodesSection = ({ components }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const sectionRef = useRef();
  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.cptCodes?.title}
      id={components?.cptCodes?.id}
      disabled={clinicalNote?.locked}>
      <CPTCodes sectionRef={sectionRef} />
    </Accordion>
  );
};

export default CPTCodesSection;
