import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import 'react-confirm-alert/src/react-confirm-alert.css';
import ProductTable from './components/Products';
import ProductTableRow from './components/ProductRow';
import NewProduct from './components/NewProduct';
import Modal from '../../../shared/Modal/Modal';
import Button from '../../../shared/Buttons/Button';
import { requestApi } from '../../../../api/Api';
import HeaderTable from './components/Header';
import Skeleton from '../../../shared/Skeleton/Skeleton';
import { showAlert } from '../../../shared/Alert/Alert';

const Products = () => {
  const [show, setShow] = useState({ loading: false, show_more: true });
  const [productToBeUpdated, setProductToBeUpdated] = useState(null);
  const [newProductModalVisible, setNewProductModalVisible] = useState(false);
  const [filters, setFilters] = useState({
    searchTerm: '',
    search: '',
    page: 1,
    limit: 10,
    column: 'name',
    sort: 'asc'
  });

  const productFormRef = useRef();

  const navigate = useNavigate();

  const getProducts = async () => {
    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to read products!",
          color: 'danger'
        });
      }
      setShow({ ...show, loading: false });
    };
    const onSuccess = () => {};

    const response = requestApi({
      url: 'api/product/get',
      navigate,
      onError,
      onSuccess,
      params: { ...filters, withCount: true }
    });

    return response;
  };

  const { data, isLoading } =
    useQuery({
      queryKey: ['getProducts', filters?.search, filters?.page, filters.sort, filters.column],
      queryFn: getProducts,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }) || {};

  const products = data?.products;

  useEffect(() => {
    const timer = setTimeout(() => {
      setFilters((prev) => ({ ...prev, search: filters.searchTerm }));
    }, 500);

    return () => clearTimeout(timer);
  }, [filters?.searchTerm]);

  const hideNewProduct = () => {
    setProductToBeUpdated(null);
    setNewProductModalVisible(false);
  };

  return (
    <div>
      <HeaderTable
        setFilters={setFilters}
        filters={filters}
        setNewProductModalVisible={setNewProductModalVisible}
      />
      {isLoading ? (
        <Skeleton count={1} height={500} />
      ) : (
        <div className="!p-3">
          <ProductTable
            handleAddClick={() => setNewProductModalVisible(true)}
            filters={filters}
            setFilters={setFilters}
            productCount={data?.productCount}>
            {products?.length ? (
              products?.map((product, index) => (
                <ProductTableRow
                  key={index}
                  product={product}
                  getProducts={getProducts}
                  setProductToBeUpdated={setProductToBeUpdated}
                  filters={filters}
                  lastIndex={index === products.length - 1}
                />
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className="!py-4"> No Products found</div>
                </td>
              </tr>
            )}
          </ProductTable>
          <Modal
            customStyling={{ width: '620px' }}
            bodyClassName="!p-4 !max-h-[unset] !h-full"
            handleOpen={newProductModalVisible || !!productToBeUpdated}
            handleClose={hideNewProduct}
            title={`${productToBeUpdated ? 'Edit' : 'Create new'} product`}
            slideFromRight
            key={newProductModalVisible || productToBeUpdated}
            footer={
              <div className="flex w-full justify-between">
                <Button
                  outlined
                  text="Cancel"
                  color="neutral"
                  onClick={hideNewProduct}
                  id="cancelProductModalBtn"
                />
                <Button
                  onClick={() => productFormRef.current.submitForm()}
                  text={productToBeUpdated ? 'Update' : 'Create product'}
                  id="createProductModalBtn"
                  data-qa="create-update-products-btn"
                />
              </div>
            }>
            <NewProduct
              ref={productFormRef}
              onClose={hideNewProduct}
              product={productToBeUpdated}
              filters={filters}
            />
          </Modal>
        </div>
      )}
    </div>
  );
};

export default Products;
