export const colorClasses = {
  neutral: {
    checkedBg:
      'bg-neutral-100 hover:bg-neutral-200 focus:bg-neutral-200 focus:!outline focus:!outline-2 focus:!outline-neutral-100',
    disabledBg: 'bg-neutral-50',
    disabledDotBg: 'bg-neutral-100'
  },
  primary: {
    checkedBg:
      'bg-primary-100 hover:bg-primary-200 focus:bg-primary-200 focus:!outline focus:!outline-2 focus:!outline-[#CBF0FD]',
    disabledBg: 'bg-primary-50',
    disabledDotBg: 'bg-primary-100'
  },
  secondary: {
    checkedBg:
      'bg-secondary-100 hover:bg-secondary-200 focus:bg-secondary-200 focus:!outline focus:!outline-2 focus:!outline-secondary-100',
    disabledBg: 'bg-secondary-50',
    disabledDotBg: 'bg-secondary-100'
  },
  success: {
    checkedBg:
      'bg-success-100 hover:bg-success-200 focus:bg-success-200 focus:!outline focus:!outline-2 focus:!outline-success-100',
    disabledBg: 'bg-success-50',
    disabledDotBg: 'bg-success-100'
  },
  warning: {
    checkedBg:
      'bg-warning-100 hover:bg-warning-200 focus:bg-warning-200 focus:!outline focus:!outline-2 focus:!outline-warning-100',
    disabledBg: 'bg-warning-50',
    disabledDotBg: 'bg-warning-100'
  },
  danger: {
    checkedBg:
      'bg-danger-100 hover:bg-danger-200 focus:bg-danger-200 focus:!outline focus:!outline-2 focus:!outline-danger-100',
    disabledBg: 'bg-danger-50',
    disabledDotBg: 'bg-danger-100'
  }
};
