import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';

import { requestApi } from 'api/Api';

import { pm } from 'lib/helpers/utility';

import { showAlert } from 'components/shared/Alert/Alert';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Popover from 'components/shared/Popovers/Popover/Popover';
import { permissions } from 'components/state';

import ServiceModal from './ServiceModal';

const Actions = ({ data }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const userPermissions = useRecoilValue(permissions);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let options = [
    ...(pm(userPermissions, 'services.update')
      ? [{ label: 'Edit', onClick: () => setShowEditModal(true), icon: 'new-edit' }]
      : []),
    ...(pm(userPermissions, 'services.delete')
      ? [{ label: 'Delete', onClick: () => setShowDeleteModal(true), icon: 'new-trash-patient' }]
      : [])
  ];

  const handleDelete = async (service) => {
    const onSuccess = () => {
      queryClient.invalidateQueries({
        predicate: (query) => {
          return query.queryKey[0] === 'services';
        },
        refetchType: 'all'
      });

      showAlert({
        title: 'Service deletion',
        message: 'Service deleted successfully',
        color: 'success'
      });

      setShowDeleteModal(false);
    };

    const onError = (error, code) => {
      if (code === 2)
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to delete services!",
          color: 'danger'
        });
    };

    await requestApi({
      url: '/api/practice/services/delete',
      params: {
        serviceId: service.id
      },
      navigate,
      onError,
      onSuccess
    });
  };

  const onEditModalToggle = () => {
    setShowEditModal((prev) => !prev);
  };

  return (
    <div className="flex h-full items-center justify-center">
      <Popover
        isFixed
        options={options}
        position="left"
        icon="new-context-menu-horizontal"
        className="flex flex-1 justify-center self-center"
      />
      {showEditModal && (
        <ServiceModal item={data} isOpen={showEditModal} handleClose={onEditModalToggle} />
      )}
      {showDeleteModal && (
        <Confirm
          variant="danger"
          primaryBtnTxt="Yes, delete service"
          title="Delete Service"
          icon="new-calendar-red"
          message="Are you sure you want to delete this service?"
          handleOpen={showDeleteModal}
          handleContinue={() => handleDelete(data)}
          handleClose={() => setShowDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Actions;
