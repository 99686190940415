import React, { useState } from 'react';
import Icon from '../../../../../../../shared/Icon/Icon';
import ViewTranscript from './Transcript/ViewTranscript';
import TranscriptModal from './TranscriptModal';

import { permissions, userState, clinicalNote as clinicalNoteState } from 'components/state';
import { useAmbientListeningContext } from 'lib/context/MyScribeAI/AmbientListeningContext/AmbientListeningContext';
import { useRecoilValue } from 'recoil';
import { ia } from '../../../../../../../../lib/helpers/utility';
import { copyTranscript } from 'components/practice/charts/ClinicalNote/MyScribeAI/lib/helpers';

const Transcription = ({ isModal = true }) => {
  const clinicalNote = useRecoilValue(clinicalNoteState);
  const { transcriptData, showTranscriptModal, setShowTranscriptModal } =
    useAmbientListeningContext() || {};

  const user = useRecoilValue(userState);
  const permission = useRecoilValue(permissions);

  const myScribeAuthor = transcriptData?.[0]?.provider_id ?? null;
  const isCurrentUserSameAsAuthor = myScribeAuthor == (user?.id ?? user?.user_id);

  const hasPermissionToUpdate =
    permission?.myscribe?.transcription_update || isCurrentUserSameAsAuthor;
  const hasPermissionToRead = permission?.myscribe?.transcription_read || isCurrentUserSameAsAuthor;

  const [speakerClassNames, setSpeakerClassNames] = useState({});

  if (!isModal) {
    return (
      <>
        <p className="mt-2 text-lg font-500">Transcript</p>

        <div className="!mt-2 !bg-transparent !p-3">
          {ia(transcriptData) && !hasPermissionToRead ? (
            <p className="text-center text-base italic text-primary-900">
              You don't have the permission to view the MyScribe AI transcript.
            </p>
          ) : ia(transcriptData) ? (
            transcriptData?.map((data, index) => (
              <ViewTranscript
                part={ia(transcriptData, 1) && index + 1}
                key={index}
                transcriptData={data}
                speakerClassNames={speakerClassNames}
                setSpeakerClassNames={setSpeakerClassNames}
              />
            ))
          ) : (
            <p className="text-center text-base italic text-primary-900">
              You currently have no transcript data. You can start one by clicking on 'record
              visit'.
            </p>
          )}
        </div>
      </>
    );
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <p className="text-lg font-500 text-primary-700">Transcript</p>
        {ia(transcriptData) && hasPermissionToUpdate && (
          <div className="flex">
            <div onClick={() => copyTranscript(transcriptData)}>
              <Icon
                size={28}
                icon="new-copy"
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
              />
            </div>

            <div>
              <Icon
                size={28}
                icon="new-edit"
                disabled={clinicalNote?.locked}
                className="cursor-pointer !p-1 transition-all hover:bg-primary-50"
                onClick={() => setShowTranscriptModal(true)}
              />
            </div>
          </div>
        )}
      </div>

      <div className="!mt-2 !bg-transparent !p-3">
        {ia(transcriptData) && !hasPermissionToRead ? (
          <p className="text-center text-base italic text-primary-900">
            You don't have the permission to view the MyScribe AI transcript.
          </p>
        ) : ia(transcriptData) ? (
          transcriptData?.map((data, index) => (
            <ViewTranscript
              part={ia(transcriptData, 1) && index + 1}
              key={index}
              transcriptData={data}
              speakerClassNames={speakerClassNames}
              setSpeakerClassNames={setSpeakerClassNames}
            />
          ))
        ) : (
          <p className="text-center text-base italic text-primary-900">
            You currently have no transcript data. You can start one by clicking on 'record visit'.
          </p>
        )}
      </div>

      <TranscriptModal
        showModal={showTranscriptModal}
        hideModal={() => setShowTranscriptModal(false)}
      />
    </div>
  );
};

export default Transcription;
