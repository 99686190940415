import React, { memo } from 'react';

import { useEnhanceNoteContextData } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContext';
import { EnhanceNoteContextProvider } from 'lib/context/MyScribeAI/EnhanceNoteContext/EnhanceNoteContextProvider';

import Icon from 'components/shared/Icon/Icon';

import PatientOverview from '../../../CurrentNote/PatientOverview/PatientOverview';

import EnhanceLoading from './components/EnhanceLoading';
import EnhanceNoteView from './components/EnhanceNoteView/EnhanceNoteView';

const EnhanceNoteMain = memo(() => {
  const { enhanceLoading } = useEnhanceNoteContextData();

  if (enhanceLoading) {
    return (
      <div className="relative flex h-full flex-grow flex-col items-center justify-start bg-primary-900">
        <Icon icon="ai-bg-pattern" className="absolute left-0 top-0" />
        <Icon icon="ai-stars-pattern" className="absolute left-0 top-0 z-50" />
        <div className="pointer-events-none absolute inset-0 z-30 bg-primary-900 opacity-90" />
        <EnhanceLoading />
        <div className="relative top-10 z-50 h-full">
          <Icon icon="hippa-secure" className="z-50" />
        </div>
      </div>
    );
  }
  return (
    <div className="flex h-screen">
      <div className="flex h-[calc(100vh-50px)] w-full flex-col">
        <PatientOverview />
        <EnhanceNoteView />
      </div>
    </div>
  );
});

const EnhanceNote = () => {
  return (
    <div className="h-screen">
      <EnhanceNoteContextProvider>
        <EnhanceNoteMain />
      </EnhanceNoteContextProvider>
    </div>
  );
};

export default memo(EnhanceNote);
