import moment from 'moment';

export default function (setFieldValue, val, start_date) {
  setFieldValue('ends_type', val);

  if (val.value === 'never') {
    setFieldValue('end_date', null);
  } else if (val.value === 'on_date') {
    setFieldValue('end_date', moment(start_date).add(1, 'days').toDate());
  } else {
    setFieldValue('end_date', null);
  }
}
