import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import PackageForm from './PackageForm';
import { requestApi } from '../../../../../api/Api';
import {
  checkIfProcedureOrProduct,
  getFinalPackageObjShape,
  reShapeProcedures,
  reShapeProducts
} from '../lib/helpers';
import { initialValues } from '../lib/initials';
import validationSchema from '../lib/validationSchema';
import { showAlert } from '../../../../shared/Alert/Alert';

// eslint-disable-next-line react/display-name
const NewPackage = forwardRef(({ onClose, pack, filters }, ref) => {
  const navigate = useNavigate();
  const formRef = useRef();
  const queryClient = useQueryClient();

  const { data: products } =
    useQuery({
      queryKey: ['getProductsForUsers'],
      queryFn: getProducts,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: reShapeProducts
    }) || {};

  const { data: procedures } =
    useQuery({
      queryKey: ['getProcedures'],
      queryFn: getProcedures,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      select: reShapeProcedures
    }) || {};

  useImperativeHandle(ref, () => ({
    submitForm: formik.submitForm
  }));

  const formik = useFormik({
    initialValues: initialValues(pack),
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const packageToBeCreated = getFinalPackageObjShape(values);
      if (checkIfProcedureOrProduct(values.procedures, values.products)) {
        showAlert({
          message: 'At least one procedure or product is required',
          color: 'danger',
          title: 'Form error!'
        });
        return;
      }

      setSubmitting(true);
      pack
        ? await updatePackage(packageToBeCreated, resetForm)
        : await createPackage(packageToBeCreated, resetForm);
      setSubmitting(false);
    }
  });

  const createPackage = async (newPackage, resetForm) => {
    newPackage = {
      ...newPackage,
      procedures: JSON.stringify(newPackage.procedures),
      products: JSON.stringify(newPackage.products)
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getPackages', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            packages: [...prevData.packages, data.pack]
          };
        }
      );
      resetForm();
      onClose();
      toast.success('New package added');
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to create package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/create',
      params: { pack: newPackage },
      onSuccess,
      navigate,
      onError
    });
  };

  const updatePackage = async (productToBeUpdated, resetForm) => {
    productToBeUpdated = {
      ...productToBeUpdated,
      procedures: JSON.stringify(productToBeUpdated.procedures),
      products: JSON.stringify(productToBeUpdated.products)
    };

    const onSuccess = (data) => {
      queryClient.setQueryData(
        ['getPackages', filters?.search, filters?.page, filters.sort, filters.column],
        (prevData) => {
          return {
            ...prevData,
            packages: prevData.packages.map((prod) => (prod.id === data.pack.id ? data.pack : prod))
          };
        }
      );
      toast.success('Package updated');
      resetForm();
      onClose();
    };

    const onError = (error, code) => {
      if (code === 2) {
        showAlert({
          title: 'No permission!',
          message: "You're not authorized to update package!",
          color: 'danger'
        });
      }
    };

    requestApi({
      url: '/api/package/update',
      params: { pack: { ...productToBeUpdated, id: pack.id } },
      onSuccess,
      navigate,
      onError
    });
  };

  async function getProcedures() {
    const response = await requestApi({
      url: '/api/practice/services/get',
      params: { offset: 0, limit: null },
      navigate
    });

    return response;
  }

  async function getProducts() {
    const response = requestApi({
      url: 'api/product/get',
      params: { forUsers: true },
      navigate
    });

    return response;
  }

  return (
    <PackageForm
      formik={formik}
      formRef={formRef}
      updating={!!pack}
      procedures={procedures}
      products={products}
    />
  );
});

export default NewPackage;
