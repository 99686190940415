import React from 'react';
import { Link } from 'react-router-dom';
import CheckinPracticeLogo from 'components/Patient/checkin/PracticeLogo';
import Button from 'components/shared/Buttons/Button';
import Icon from 'components/shared/Icon/Icon';

const instantActionsConfirmAppointment = ({ success, loading, setAndAction, resubmit, data }) => {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-between">
      <div className="h-fit w-full bg-white">
        <CheckinPracticeLogo practiceLogo={data?.practiceLogo} />
      </div>
      <div className="flex w-full flex-col items-center justify-center p-[2rem] sm:p-4">
        <Icon icon="appointment-pending" />
        <div className="flex w-full flex-col text-center">
          <div className="my-[1rem] text-3xl font-500 text-primary-900 sm:my-[0.5rem]">
            Upcoming appointment
          </div>
          <p className="my-[1rem] text-lg text-primary-900 sm:my-[0.5rem]">
            Please confirm the appointment below.
          </p>
          <div className="my-[1.5rem] flex w-full flex-col rounded-xl border-[1px] border-solid border-primary-100 bg-primary-50 px-[1.2rem] py-[0.7rem] sm:py-[0.3rem]">
            {data?.name && (
              <>
                <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.5rem]">
                  <span>Practice</span>
                  <span>{data?.name}</span>
                </div>
                <hr className="mb-[0.5rem] border-[1.5px] border-dashed border-primary-100 sm:mb-[0.3rem]" />
              </>
            )}
            {data?.location && (
              <>
                <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.3rem]">
                  <span>Location</span>
                  <span>{data?.location}</span>
                </div>
                <hr className="mb-[0.5rem] border-[1.5px] border-dashed border-primary-100 sm:mb-[0.3rem]" />
              </>
            )}
            {data?.provider && (
              <>
                <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.3rem]">
                  <span>Provider</span>
                  <span>{data?.provider}</span>
                </div>
                <hr className="mb-[0.5rem] border-[1.5px] border-dashed border-primary-100 sm:mb-[0.3rem]" />
              </>
            )}
            {data?.date && (
              <>
                <div className="mt-[0.5rem] flex flex-row justify-between text-primary-900 sm:mt-[0.3rem]">
                  <span>Date</span>
                  <span>{data?.date}</span>
                </div>
                {data?.phone && (
                  <hr className="mb-[0.5rem] border-[1.5px] border-dashed border-primary-100 sm:mb-[0.3rem]" />
                )}
              </>
            )}
            {data?.phone && (
              <>
                <div className="my-[0.5rem] flex flex-row justify-between text-primary-900 sm:my-[0.5rem]">
                  <span>Phone</span>
                  <span>{data?.phone}</span>
                </div>
              </>
            )}
          </div>
          {success && (
            <Button
              text="Confirm Appointment"
              loading={loading}
              onClick={() => {
                setAndAction(true);
                resubmit();
              }}
              className="my-[0.6rem] w-full"
            />
          )}
        </div>
      </div>
      <div className="w-full">
        <label className="!mx-10 !mb-5 flex flex-col text-center text-neutral-500">
          <Link to="/login">Login</Link>
          <Link to="/terms">Terms of Service and Privacy Policy</Link>
        </label>
        {data?.practiceLogo && (
          <div className="flex h-[44px] w-full items-center justify-between bg-primary-50 px-40">
            <h4 className="text-sm text-neutral-600">Powered By</h4>
            <Icon icon="logo" size={90} />
          </div>
        )}
      </div>
    </div>
  );
};

export default instantActionsConfirmAppointment;
