import React from 'react';
import { useNavigate } from 'react-router-dom';

// import cs from 'classnames'
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { createAdminUser, updateAdminUser } from 'api/Admin/Users';

import useAdminFormik from 'components/admin/lib/formik/useAdminFormik';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Modal from 'components/shared/Modal/Modal';

import AdminForm from '../AdminForm';

const AddEditAdminModal = ({ adminData, isModalOpen, handleClose }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    await handleSubmit(values);
  };

  const formik = useAdminFormik(adminData ?? {}, onSubmit);

  const editMutation = useMutation({
    mutationFn: (data) => {
      return updateAdminUser(navigate, {
        userId: data?.userId,
        changes: {
          ...data,
          role_id: data.role.value
        }
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admin edited Successfully', color: 'success' });
        formik.resetForm();
        handleClose();
      } else {
        showAlert({ title: data?.error ?? 'Something went wrong editing admin', color: 'danger' });
      }
    }
  });

  const createMutation = useMutation({
    mutationFn: (data) => {
      return createAdminUser(navigate, {
        ...data,
        role_id: data.role.value
      });
    },
    onSuccess: async (data) => {
      if (data.code === 0) {
        await queryClient.invalidateQueries(['getAdminUsersList']);
        showAlert({ title: 'Admin created successfully', color: 'success' });
        formik.resetForm();
        handleClose();
      } else {
        showAlert({ title: data?.error ?? 'Something went wrong creating admin', color: 'danger' });
      }
    }
  });

  const handleSubmit = async (values) => {
    if (!adminData) {
      return await createMutation.mutateAsync(values);
    } else {
      if (adminData.email === values.email) {
        //if email is not changed dont send to update
        const { email, ...rawChanges } = values;
        return await editMutation.mutateAsync(rawChanges);
      } else {
        return await editMutation.mutateAsync(values);
      }
    }
  };

  const onModalClose = () => {
    formik.resetForm();
    handleClose();
  };

  return (
    <Modal
      handleOpen={isModalOpen}
      handleClose={onModalClose}
      title={adminData ? `Update Admin ${adminData?.f_name}` : `Create Admin`}
      slideFromRight
      footer={
        <div className="flex w-full gap-4 justify-end">
          <Button outlined color="neutral" text="Close" onClick={handleClose} />
          <Button outlined color="primary" text="Submit" onClick={formik.handleSubmit} />
        </div>
      }>
      <AdminForm formik={formik} data-dd-privacy="allow" />
    </Modal>
  );
};

export default AddEditAdminModal;
