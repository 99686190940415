import React from 'react';
import Icon from '../Icon/Icon';
import cs from 'classnames';
import './Card.scss';
import Skeleton from '../Skeleton/Skeleton';

const Card = (props) => {
  const {
    theme,
    headLine,
    title,
    icon,
    chart,
    shortData,
    data,
    dataLayoutHorizontal,
    onClick,
    position,
    loadingBasedOn
  } = props;

  const dataList = [];
  for (const key in data) {
    dataList.push(
      <div className="Card__body__data__item" key={key}>
        <span>{`${key.replace(/_/g, ' ')}: ${data[key]}`}</span>
      </div>
    );
  }

  if (!loadingBasedOn) return <Skeleton count={1} height="210px" />;

  return (
    <div className={cs('Card', `Card--${theme}`)} onClick={onClick} style={{ gridArea: position }}>
      <div className="Card__head">
        {headLine && <div className="Card__head__line"></div>}
        <div className="Card__head__text">
          <h3 className="Title">{title}</h3>
        </div>
        <div className="Card__head__symbol">
          {icon && <Icon icon={icon} />}
          {chart}
        </div>
      </div>
      <div className="Card__body">
        <span>{shortData}</span>
        {shortData && <div className="Card__divider"></div>}
        <div
          className={cs(
            'Card__body__data',
            dataLayoutHorizontal && 'Card__body__data--horizontal'
          )}>
          {dataList}
        </div>
      </div>
    </div>
  );
};

export default Card;
