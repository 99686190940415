import React from 'react';
import { useNavigate } from 'react-router-dom';

import { createService, updateService } from 'api/Service';

import { ia, io } from 'lib/helpers/utility';
import { useCustomMutation } from 'lib/hooks/queries/useCustomMutation';

import { showAlert } from 'components/shared/Alert/Alert';

import { getServiceChanges } from '../lib/helpers';

import NewServiceModal from './NewServiceModal/NewServiceModal';
import {
  SERVICE_CREATED_ERROR_MESSAGE,
  SERVICE_CREATED_SUCCESS_MESSAGE,
  SERVICE_QUERY_KEY,
  SERVICE_UPDATED_ERROR_MESSAGE,
  SERVICE_UPDATED_SUCCESS_MESSAGE
} from './NewServiceModal/lib/constants';

const ServiceModal = ({ item: service, isOpen, handleClose }) => {
  const navigate = useNavigate();

  const { mutateAsync: createServiceMutation, isLoading: createServiceLoading } = useCustomMutation(
    {
      mutationFn: (params) => createService(navigate, params),
      invalidateQueryKey: SERVICE_QUERY_KEY,
      successMessage: SERVICE_CREATED_SUCCESS_MESSAGE,
      errorMessage: SERVICE_CREATED_ERROR_MESSAGE,
      onSuccess: () => {
        handleClose();
      }
    }
  );

  const { mutateAsync: updateServiceMutation, isLoading: updateServiceLoading } = useCustomMutation(
    {
      mutationFn: (params) => updateService(navigate, params),
      invalidateQueryKey: SERVICE_QUERY_KEY,
      successMessage: SERVICE_UPDATED_SUCCESS_MESSAGE,
      errorMessage: SERVICE_UPDATED_ERROR_MESSAGE,
      onSuccess: () => {
        handleClose();
      }
    }
  );

  const createServiceHandler = async (newService) => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    const color = '#' + randomColor;

    const newServiceObj = { ...newService, color };

    await createServiceMutation({ service: newServiceObj });
  };

  const updateServiceHandler = async (updatedService, payScheduleChanges) => {
    const serviceChanges = getServiceChanges(updatedService, service);

    if (!io(serviceChanges) && !ia(payScheduleChanges)) {
      showAlert({ title: 'Service Update', message: 'No changes detected', color: 'warning' });
      return;
    }

    const updatedServiceObj = {
      service_id: service.id,
      ...(io(serviceChanges) && { fields: serviceChanges }),
      ...(ia(payScheduleChanges) && { paySchedule: payScheduleChanges })
    };

    updateServiceMutation(updatedServiceObj);
  };

  const onSave = async (values, paySchedule) => {
    service ? await updateServiceHandler(values, paySchedule) : await createServiceHandler(values);
  };

  return (
    <NewServiceModal
      service={service}
      isOpen={isOpen}
      isLoading={createServiceLoading || updateServiceLoading}
      onClose={handleClose}
      onSave={onSave}
    />
  );
};

export default ServiceModal;
