module.exports = {
  practiceKeys: [
    'name',
    'kind',
    'status',
    'npi',
    'taxonomy',
    'tax_id',
    'tax_id_type',
    'claim_md_account_key',
    'eligibility_counter',
    'email',
    'phone',
    'fax',
    'twilio_phone',
    'veradigm_license_id',
    'veradigm_office_id',
    'specialty_code',
    'profession_group_id'
  ],
  practiceAddressKeys: ['address_ln_1', 'address_ln_2', 'city', 'state', 'zip', 'country']
};
