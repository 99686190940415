import React from 'react';

import Tippy from '@tippyjs/react';

import Button from 'components/shared/Buttons/Button';
import Popover from 'components/shared/Popovers/Popover/Popover';

import { componentButtons } from '../../constants';
import useComponentAdd from '../../hooks/useComponentAdd';

const ComponentButtons = ({ onTagPreview = () => {}, onTagRemove = () => {} }) => {
  const { inputTags, selectedTableRows, onTemplateClick } = useComponentAdd();

  const getPopoverOptions = (id) => [
    { label: 'Edit', icon: 'new-edit', onClick: () => onTagPreview(id) },
    { label: 'Remove', icon: 'trash', onClick: () => onTagRemove(id) }
  ];

  return (
    <div className="flex items-start justify-start gap-2 max-h-[32px]">
      {Object.values(componentButtons)?.map((componentButton) => {
        const isActive = inputTags.some((tag) => tag.text === componentButton.text);

        return (
          <>
            {!isActive ? (
              <Tippy
                placement="top"
                content={
                  componentButton?.includedInHashtags
                    ? componentButton.text
                    : `Add ${componentButton.text}`
                }
                className="tippy-dark">
                <div>
                  <Button
                    key={componentButton.id}
                    onClick={() => onTemplateClick(componentButton)}
                    icon={componentButton.icon}
                    className="z-30 text-neutral-600 outline-0 !pl-1.5 !pr-1.5"
                    textClassName="text-neutral-700 font-thin"
                    transparent
                    color="neutral"
                    size="small"
                  />
                </div>
              </Tippy>
            ) : (
              <Popover
                isFixed
                tooltip={
                  componentButton?.includedInHashtags
                    ? componentButton.text
                    : selectedTableRows?.[componentButton?.id]?.status === 'partial'
                      ? componentButton?.partialText
                      : `Add ${componentButton.text}`
                }
                tooltipPlacement="top"
                buttonTheme={
                  selectedTableRows?.[componentButton?.id]?.status === 'partial'
                    ? 'warning'
                    : 'primary'
                }
                buttonThemeType="primary"
                buttonClassName="!min-h-[30px] !p-0"
                icon={componentButton.icon}
                position="right"
                options={getPopoverOptions(componentButton?.id)}
              />
            )}
          </>
        );
      })}
    </div>
  );
};

export default ComponentButtons;
