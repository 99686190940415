import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';

import { deleteOccupation } from 'api/Occupations';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { TableContextProvider } from 'lib/context/TableContext/TableContextProvider';
import { useOccupations } from 'lib/hooks/queries/occupations/useOccupations';
import usePageTitle from 'lib/hooks/usePageTitle';

import AGTable from 'components/shared/AGTable/AGTable';
import DisplayButton from 'components/shared/AGTable/DisplayButton';
import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Popup from 'components/shared/Filters/Popup';
import Header from 'components/shared/Header/Header';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import Pagination from 'components/shared/Pagination/Pagination';
import Allowed from 'components/shared/Permissions/Allowed';

import NewAndUpdateOccupation from './NewAndUpdate';

export default function Occupations() {
  const [deleteOccupationsModal, setDeleteOccupationsModal] = useState(null);
  const [selectedOccupation, setSelectedOccupation] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [mode, setMode] = useState('create');

  const Actions = ({ data }) => {
    return (
      <div className="flex h-full flex-col justify-center">
        <Popup
          trigger={(open) => (
            <div
              className={`6px flex h-[34px] w-[34px] items-center justify-center rounded ${
                open && '!bg-primary-700 transition-all'
              }`}>
              <Icon
                icon="three-dots"
                className="flex cursor-pointer"
                color={open ? 'white' : 'black'}
              />
            </div>
          )}
          width={180}
          on={['hover', 'focus']}
          contentStyle={{ borderRadius: '4px', boxShadow: '0px 2px 16px 0px #004F6B33' }}
          position={['bottom right', 'top center']}>
          {(close) => (
            <div className=" !py-[6px]">
              <Allowed requiredPermissions="occupations.update">
                <div
                  data-qa="update"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedOccupation(data);
                    setShowModal(true);
                    setMode('update');
                  }}>
                  <Icon icon="new-mask" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Update</div>
                </div>
              </Allowed>
              <Allowed requiredPermissions="occupations.delete">
                <div
                  data-qa="delete"
                  className="flex cursor-pointer items-center gap-1 !p-2 !px-4 transition-all hover:bg-primary-50"
                  onClick={() => {
                    close();
                    setSelectedOccupation(data);
                    setDeleteOccupationsModal(true);
                  }}>
                  <Icon icon="trash" className="cursor-pointer" color="primary" />
                  <div className="ml-1 text-sm text-primary-900">Delete</div>
                </div>
              </Allowed>
            </div>
          )}
        </Popup>
      </div>
    );
  };
  const cols = [
    { field: 'name', headerName: 'Name', width: 300 },
    {
      field: 'actions',
      headerName: '',
      width: 100,
      cellRenderer: Actions,
      maxWidth: 100,
      minWidth: 36,
      resizable: false
    }
  ];

  return (
    <Allowed requiredPermissions="occupations.read" showMessage showIllustration>
      <TableContextProvider name="occupations" pagination cols={cols}>
        <Table
          setShowModal={setShowModal}
          setMode={setMode}
          selectedOccupation={selectedOccupation}
          deleteOccupationsModal={deleteOccupationsModal}
          setDeleteOccupationsModal={setDeleteOccupationsModal}
        />
      </TableContextProvider>

      {!!showModal && (
        <NewAndUpdateOccupation
          mode={mode}
          selectedOccupation={selectedOccupation}
          hideModal={() => setShowModal(false)}
          showModal={!!showModal}
        />
      )}
    </Allowed>
  );
}

function Table({
  selectedOccupation,
  setMode,
  setShowModal,
  deleteOccupationsModal,
  setDeleteOccupationsModal
}) {
  usePageTitle('Occupations');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const queryClient = useQueryClient();
  const { limit, page, setPage, sort, setTableLoading } = useTableContext();
  const navigate = useNavigate();

  const {
    data = {},
    isLoading,
    isFetching
  } = useOccupations({
    params: { searchTerm: debouncedSearchTerm, page, sort, limit, withCount: true },
    dependencies: [debouncedSearchTerm, page, sort, limit],
    page,
    sort
  });
  const occupation = data?.occupations || [];
  const mutateDelete = useMutation({
    mutationFn: () => deleteOccupation(navigate, { id: selectedOccupation.id }),
    onSuccess: (data) => {
      switch (data?.code) {
        case 0:
          queryClient.invalidateQueries(['occupations']);
          setDeleteOccupationsModal(null);
          showAlert({ title: 'Occupation deleted successfully!', color: 'success' });
          break;
        default:
          showAlert({ title: 'Failed to delete Occupation.', color: 'danger' });
          break;
      }
    }
  });
  useEffect(() => {
    setTableLoading(isLoading || isFetching);
  }, [isLoading, isFetching, setTableLoading]);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 100
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <Header title="Occupations">
        <div className="!mt-2 flex items-center gap-2">
          <DisplayButton />
          <Input
            data-qa="search-occupations"
            type="string"
            placeholder="Search occupations"
            value={searchTerm}
            icon="new-search"
            inputWrapperClassName="h-[30px]"
            className="md:w-[200px]"
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setPage(1);
            }}
            rightText={
              searchTerm && (
                <Icon
                  icon="new-close"
                  onClick={() => {
                    setSearchTerm('');
                    setPage(1);
                  }}
                />
              )
            }
          />
          <Allowed requiredPermissions="occupations.create">
            <Button
              data-qa="new-occupation-btn"
              size="small"
              type="primary"
              text="New Occupation Code"
              onClick={() => {
                setShowModal(true);
                setMode('create');
              }}
            />
          </Allowed>
        </div>
      </Header>
      <div className="flex h-full flex-col  !p-3 !pb-2">
        <div className="ag-theme-quartz !mb-0 h-full">
          <AGTable data={occupation} defaultColDef={defaultColDef} />
        </div>
        <div className="flex items-center justify-between rounded-b-lg bg-white !px-5 !py-2">
          {data?.occupationCount > 0 && (
            <p className="font-light text-primary-900">
              Showing {(page - 1) * limit} -{' '}
              {data?.occupationCount > page * limit ? page * limit : data?.occupationCount} out of{' '}
              {data?.occupationCount}
            </p>
          )}
          <Pagination
            containerClassName="flex"
            onPageChange={({ selected }) => setPage(selected + 1)}
            perPage={limit}
            totalItems={data?.occupationCount}
            page={page}
          />
          {!!deleteOccupationsModal && (
            <Confirm
              variant="danger"
              primaryBtnTxt="Delete"
              title="Delete Occupation"
              icon="new-document-remove-red"
              message="Are you sure you want to delete this Occupation?"
              handleContinue={() => {
                mutateDelete.mutate();
                setDeleteOccupationsModal(false);
              }}
              handleOpen={!!deleteOccupationsModal}
              handleClose={() => setDeleteOccupationsModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
