import React from 'react';

import cs from 'classnames';

import { useTableContext } from 'lib/context/TableContext/TableContext';
import { pluralize } from 'lib/helpers/utility';
import { useWindowSize } from 'lib/hooks/useWindowSize';

import Icon from 'components/shared/Icon/Icon';
import Typography from 'components/shared/Typography/Typography';

export const RowSelectionActions = ({ actions }) => {
  const { selectedRows, setSelectedRows, gridApi, ...rest } = useTableContext();

  const totalSelected = selectedRows?.length;

  const device = useWindowSize();
  const isSmallDevice = device === 'tablet' || device === 'tablet-sm' || device === 'mobile';

  if (!actions) return null;

  const clearSelection = () => {
    gridApi.deselectAll();
    setSelectedRows([]);
  };

  return (
    <div
      className={cs(
        'max-w-[760px] w-full min-w-fit left-1/2 -translate-x-1/2 bottom-10',
        'flex justify-between items-center gap-2 pl-4 pr-3 py-3',
        'border border-solid border-neutral-200 rounded-lg z-40',
        'bg-white shadow-[0px_4px_40px_rgba(55,65,69,0.32)]',
        'transition-transform duration-300 ease-out',
        isSmallDevice ? 'fixed w-[calc(100%-120px)] -translate-x-1/2' : 'absolute',
        totalSelected > 0 ? 'translate-y-0' : 'translate-y-[200%]'
      )}>
      <div className="flex items-center gap-2">
        <Icon
          icon="new-close-v2"
          color="neutral"
          shade={700}
          size={18}
          onClick={clearSelection}
          className="cursor-pointer hover:opacity-70 rounded"
        />

        <Typography color="neutral-700" weight="medium">
          {totalSelected} {pluralize('item', totalSelected)} selected
        </Typography>
      </div>

      <div
        className={cs(
          'flex items-center gap-2 origin-center opacity-100',
          'transition-transform duration-300 ease-out will-change-transform',
          {
            'scale-0 delay-300': totalSelected === 0,
            'scale-100': totalSelected > 0
          }
        )}>
        {typeof actions === 'function'
          ? actions({ selectedRows, setSelectedRows, gridApi, ...rest })
          : actions}
      </div>
    </div>
  );
};
