import React, { useCallback } from 'react';
import cs from 'classnames';
import useAutosizeElement from 'lib/hooks/useAutosizeElement';
import Hint from '../shared/Hint';
import Header from '../shared/Header';
import MaximumChars from '../shared/MaximumChars';
import TextEditor from '../TextEditor/TextEditor';

const Textarea = ({
  label,
  labelClassName,
  icon,
  transcribing,
  options,
  placeholder,
  value,
  isEditor = false,
  editorParentClassName,
  id = label || 'textareaId',
  name = 'textareaName',
  className,
  width,
  disabled = false,
  setDisabled = () => {},
  media = { toolbar: [], formats: [] },
  error,
  hint,
  hintIcon,
  forwardedRef,
  onChange = () => {},
  textareaClassName,
  maxLength,
  required = false,
  isOptional = false,
  resizeX = false,
  resizeY = false,
  isHeader = true,
  headerClassName,
  customStyle = null,
  ...rest
}) => {
  useAutosizeElement(forwardedRef, value);

  const getTextLength = (text) => useCallback(text?.length, [text]);

  if (isEditor)
    return (
      <TextEditor
        onChange={onChange}
        value={value}
        defaultValue={value}
        error={error}
        hint={hint}
        label={label}
        transcribing={transcribing}
        options={options}
        media={media}
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        icon={icon}
        name={name}
        customStyle={customStyle}
        id={id}
        editorParentClassName={editorParentClassName}
        maxLength={maxLength}
        isOptional={isOptional}
        required={required}
        resizeX={resizeX}
        resizeY={resizeY}
      />
    );

  return (
    <div className={cs('relative', className)} style={{ width }}>
      <Header
        isHeader={isHeader}
        headerClassName={headerClassName}
        label={label}
        labelClassName={labelClassName}
        transcribing={transcribing}
        icon={icon}
        options={options}
        isOptional={isOptional}
        disabled={disabled}
        required={required}
        value={value}
        onChange={onChange}
        setDisabled={setDisabled}
        name={name}
        id={id}
      />
      <div className="relative">
        <textarea
          className={cs(
            'm-0 flex h-10 w-full items-center justify-between gap-x-3 overflow-hidden rounded-md border border-neutral-200 px-3 py-[10px] text-sm leading-5 focus-within:outline focus-within:!outline-2',
            error
              ? '!border-danger-300 caret-danger-500 focus-within:!outline-danger-100'
              : 'caret-primary-500 focus-within:!border-primary-300 focus-within:!outline-[#CBF0FD]',
            disabled
              ? 'cursor-not-allowed bg-neutral-50 text-neutral-400 placeholder:text-neutral-300'
              : 'bg-white text-neutral-900 placeholder:text-neutral-500',
            !resizeX && !resizeY && '!resize-none',
            resizeX && 'resize-x',
            resizeY && 'resize-y',
            textareaClassName
          )}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          id={id}
          name={name}
          disabled={disabled}
          ref={forwardedRef}
          maxLength={maxLength}
          {...rest}
        />
        <MaximumChars length={getTextLength(value)} maxLength={maxLength} disabled={disabled} />
      </div>
      <Hint hint={hint} icon={hintIcon} disabled={disabled} error={error} />
      {error && <p className="pt-2 text-sm text-danger-500">{error}</p>}
    </div>
  );
};

export default Textarea;
