import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getWidgetTasks } from 'api/Tasks';

export const useWidgetTasks = (options = {}, dependencies = []) => {
  const navigate = useNavigate();

  return useQuery(['tasks-widget', ...dependencies], () => getWidgetTasks(navigate), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
