import { Honeybadger } from '@honeybadger-io/react';
import * as filestack from 'filestack-js';
import { useFormik } from 'formik';
import { capitalize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import * as Yup from 'yup';
import { interimApi } from '../../../../api/InterimApi';
import { getStates as getStatesAPI } from '../../../../api/State';
import { ia, io, pm } from '../../../../lib/helpers/utility';
import Loading from '../../../shared/Loading/Loading';
import { permissions } from '../../../state';
import { currentPractice } from '../../practiceState';
import Overview from './Overview';
import { useUIContext } from '../../../../lib/context/UIContext/UIContext';

const client = filestack.init(process.env.REACT_APP_FILESTACK);

const UpdatePatientValidationSchema = Yup.object().shape({
  f_name: Yup.string().required('First name required'),
  l_name: Yup.string().required('Last name required'),
  email: Yup.string().required('Email required'),
  dob: Yup.string().required('Date of birth required'),
  address_ln_1: Yup.string().required('Address required'),
  city: Yup.string().required('City required'),
  state: Yup.string().required('State required'),
  custom_id: Yup.string().matches(/^[a-zA-Z0-9!@#$%^&*)(]*$/g, 'Special Characters are not allowed')
});

const OverviewContainer = () => {
  const navigate = useNavigate();

  const p = useRecoilValue(permissions);

  const { patient, setPatient, practicePatient, showNewAppointmentModal } = useOutletContext();
  // const [address, setAddress] = useState(patient.userAddress);
  const [modalState, setModalState] = useState(false);
  const [states, setStates] = useState();
  const [medicalHistory, setMedicalHistory] = useState();
  const [customChartId, setCustomChartId] = useState(practicePatient?.custom_id);
  const [customAlerts, setCustomAlerts] = useState(practicePatient?.alerts);
  const [patientBalance, setPatientBalance] = useState(0);
  const [groupedConditions, setGroupedConditions] = useState([]);
  const practice = useRecoilValue(currentPractice);
  const formRef = useRef();
  const { id } = useParams();

  useEffect(() => {
    getStates();
    getPracticePatientData();
    getMedicalHistory();

    if (pm(p, 'billing.read')) getPatientBalance();
  }, []);

  const showModal = () => {
    setModalState(true);
  };

  const hideModal = () => {
    setModalState(false);
  };

  const formik = useFormik({
    initialValues: {
      ...patient,
      id: patient?.id,
      f_name: patient?.f_name,
      m_name: patient?.m_name,
      l_name: patient?.l_name,
      phone: patient?.phone,
      email: patient?.email,
      dob: patient?.dob,
      gender: patient?.gender,
      race: patient?.race,
      address_ln_1: patient?.userAddress?.address_ln_1,
      address_ln_2: patient?.userAddress?.address_ln_2,
      city: patient?.userAddress?.city,
      state: patient?.userAddress?.state,
      country: patient?.userAddress?.country,
      zip: patient?.userAddress?.zip,
      full_street_address: patient?.userAddress?.full_street_address,
      custom_id: customChartId,
      custom_alerts: customAlerts,
      occupation: ia(patient.occupation)
        ? { value: patient?.occupation[0]?.id, label: patient?.occupation[0]?.name }
        : { value: null, label: null }
    },
    validationSchema: UpdatePatientValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      const patientToBeUpdated = {
        patient: {
          id: values.id,
          f_name: values.f_name,
          m_name: values.m_name,
          l_name: values.l_name,
          phone: values.phone,
          email: values.email,
          dob: values.dob,
          gender: values.gender,
          race: values.race
        },
        occupation: values.occupation,
        address: {
          address_ln_1: values?.address_ln_1,
          address_ln_2: values?.address_ln_2,
          city: values?.city,
          state: values?.state,
          zip: values?.zip,
          country: values?.country,
          fullAddress: [values?.address_ln_1, values?.address_ln_2, values?.city, values?.zip].join(
            ' '
          ),
          full_street_address: [
            values?.address_ln_1,
            values?.address_ln_2,
            values?.city,
            values?.state,
            values?.country,
            values?.zip
          ].join(' ')
        },
        practicePatientData: {
          custom_id: values.custom_id,
          custom_alerts: values.custom_alerts
        }
      };

      setSubmitting(true);
      await submitChanges(
        patientToBeUpdated.patient,
        patientToBeUpdated.address,
        patientToBeUpdated.practicePatientData,
        patientToBeUpdated.occupation
      );
      setSubmitting(false);
    }
  });

  const getPatientBalance = async () => {
    try {
      const res = await interimApi(
        '/api/patient/transaction/balance',
        {
          patientId: patient.id
        },
        navigate
      );
      const { code, redirect, error, patientBalance: loadedPatientBalance } = res.data;
      switch (code) {
        case -1:
          navigate(redirect);
          break;
        case 0:
          if (ia(loadedPatientBalance)) setPatientBalance(loadedPatientBalance[0].amount);

          break;

        default:
          if (error) {
            toast.error(error);
            Honeybadger.notify(`getPatientBalance patientID: ${patient.id}, error: ${error}`);
          } else {
            toast.error(`There's been an unexpected error. Please try again later.`);
            Honeybadger.notify(`getPatientBalance patientID: ${patient.id}`);
          }
          break;
      }
    } catch (error) {
      console.error(error);
      toast.error(`There's been an unexpected error. Please try again later.`);
      Honeybadger.notify(`getPatientBalance - patientID: ${patient.id}, error: ${error}`);
    }
  };

  const getStates = async () => {
    try {
      let res = await getStatesAPI(navigate);
      setStates(res);
    } catch (err) {
      Honeybadger.notify(`getStates - patientID: ${patient.id}, error: ${err}`);
    }
  };

  const mapMedicalHistoryConditions = (conditions) => {
    setGroupedConditions({
      breast: conditions?.breast,
      cardiovascular: conditions?.cardiovascular,
      eyes_nose_ear: conditions?.eyes_nose_ear,
      gastrointestinal: conditions?.gastrointestinal,
      genitourinary: conditions?.genitourinary,
      mouth_throat: conditions?.mouth_throat,
      musculoskeletal: conditions?.musculoskeletal,
      neurological: conditions?.neurological,
      respiratory: conditions?.respiratory,
      skin: conditions?.skin
    });
  };

  const getPracticePatientData = async () => {
    try {
      let res = await interimApi(
        '/api/practice/patient/read',
        {
          patient_id: patient.id
        },
        navigate
      );

      const { practicePatientData } = res.data;
      if (res.data && practicePatientData) {
        setCustomChartId(practicePatientData?.custom_id);
        setCustomAlerts(practicePatientData?.alerts);
      } else {
        Honeybadger.notify(`getPracticePatientData - patientID: ${patient.id}`);
      }
    } catch (error) {
      Honeybadger.notify(`getPracticePatientData - patientID: ${patient.id}, error: ${error}`);
    }
  };

  const getMedicalHistory = async () => {
    try {
      let res = await interimApi(
        '/api/practice/medical_history/get',
        {
          patient_id: patient.id
        },
        navigate
      );
      const { medicalHistory, pastMedicalHistory } = res.data;
      if (res.data) {
        if (medicalHistory) {
          setMedicalHistory({ ...medicalHistory });
          mapMedicalHistoryConditions({ ...medicalHistory });
        } else if (ia(pastMedicalHistory)) {
          setMedicalHistory({ ...pastMedicalHistory[0] });
          mapMedicalHistoryConditions({ ...pastMedicalHistory[0] });
        }
        // setMedicalHistory(loadedMedicalHistory);
      } else {
        Honeybadger.notify(`getMedicalHistory - patientID: ${patient.id}`);
      }
    } catch (err) {
      Honeybadger.notify(`getMedicalHistory - patientID: ${patient.id}, error: ${err}`);
    }
  };

  const submitChanges = async (updatedPatient, updatedAddress, practicePatientData, occupation) => {
    if (practicePatientData) {
      let res = await interimApi(
        'api/practice/patient/update',
        {
          user_id: patient.id,
          data: {
            custom_id: practicePatientData?.custom_id,
            alerts: practicePatientData?.custom_alerts
          }
        },
        navigate
      );
      if (res.status === 200) {
        setCustomChartId(practicePatientData?.custom_id);
        setCustomAlerts(practicePatientData?.custom_alerts);
      } else {
        console.error(res.data.message);
        toast.error(`An error has occurred. Please try again later.`);
        Honeybadger.notify(
          `submitChanges (practicePatientData) - patientID: ${patient.id}, error: ${res.data.message}`
        );
      }
    }
    if (updatedPatient) {
      try {
        const changes = {};

        const values = updatedPatient;
        const initialValues = formik.initialValues;

        for (const key in values) {
          if (values[key] !== initialValues[key]) changes[key] = values[key];
        }

        if (io(changes)) {
          let { data } = await interimApi(
            '/api/user/update',
            {
              changes,
              user_id: patient.id,
              occupation_id: occupation.value
            },
            navigate
          );

          const { code, error } = data || {};

          switch (code) {
            case 0:
              hideModal();
              toast.success('Patient updated successfully!');
              setPatient((prevPatient) => ({ ...prevPatient, ...updatedPatient }));
              break;

            case 1:
              toast.error(error);

            default:
              Honeybadger.notify(
                `submitChanges (updatedPatient) - try block - pID: ${updatedPatient.id}, error: ${error}`
              );
              break;
          }
        }
      } catch (err) {
        Honeybadger.notify(
          `submitChanges (updatedPatient) - catch block - pID: ${patient.id}, error: ${err}`
        );
      }
    }
    if (updatedAddress) {
      try {
        let res = await interimApi(
          '/api/address/patch',
          {
            loadedAddress: updatedAddress,
            userId: patient.id
          },
          navigate
        );
        if (res.status === 200) {
          setPatient((prevPatient) => {
            return {
              ...prevPatient,
              userAddress: updatedAddress,
              occupation: !!occupation ? [{ id: occupation.value, name: occupation.label }] : []
            };
          });
          hideModal();
        } else {
          console.error(res.data.message);
          toast.error(`An error has occurred. Please try again later.`);
          Honeybadger.notify(
            `submitChanges (updatedAddress) - patientID: ${patient.id}, error: ${res.data.message}`
          );
        }
      } catch (err) {
        Honeybadger.notify(
          `submitChanges (updatedAddress) - patientID: ${patient.id}, error: ${err}`
        );
      }
    }
  };

  const openFilestackPicker = () => {
    let pickerOptions = { accept: ['image/*'], maxFiles: 1, uploadInBackground: false };
    pickerOptions.exposeOriginalFile = true;
    pickerOptions.transformations = {
      crop: {
        aspectRatio: 1,
        force: true
      }
    };
    pickerOptions.imageMin = [600, 600];
    pickerOptions.onUploadDone = saveImage;
    client.picker(pickerOptions).open();
  };

  const saveImage = async (data) => {
    const handle = {
      jpg: data.filesUploaded[0].handle
    };
    let res = await interimApi(
      '/api/filestack/profile_photo',
      {
        handle,
        userId: patient.id
      },
      navigate
    );
    if (res.data.profilePhoto > 0) {
      setPatient((prevPatient) => {
        return { ...prevPatient, profile_photo: JSON.stringify(handle) };
      });
    } else {
      console.error('There was an error uploading this image. Please try again.');
      toast.error('Uploading image failed. Please try again');
      Honeybadger.notify(`saveImage - patientID: ${patient.id}`);
    }
  };

  const handleSubmit = () => {
    if (formRef.current) {
      formik.submitForm();
    }
  };

  const { device } = useUIContext();

  return patient ? (
    <div className="overflow-auto bg-gray-50">
      <Overview
        gender={patient.gender && capitalize(patient?.gender)}
        race={patient.race}
        fallRisk={medicalHistory?.fall_risk && capitalize(medicalHistory?.fall_risk)}
        conditions={groupedConditions}
        allergies={medicalHistory?.allergies}
        balanceDue={patientBalance}
        custom_id={customChartId}
        alerts={customAlerts}
        height={medicalHistory?.height}
        weight={medicalHistory?.weight}
        bmi={medicalHistory?.bmi}
        heartRate={medicalHistory?.heart_rate}
        bloodPressureSystolic={medicalHistory?.blood_pressure_systolic}
        bloodPressureDiastolic={medicalHistory?.blood_pressure_diastolic}
        showModal={showModal}
        occupation={ia(patient.occupation) && patient?.occupation[0]?.name}
        status={patient?.status}
        permissions={p}
        patient={patient}
        showNewAppointmentModal={showNewAppointmentModal}
      />
    </div>
  ) : (
    <Loading />
  );
};

export default OverviewContainer;
