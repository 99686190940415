import React from 'react';
import Pagination from '../../../../shared/Pagination/Pagination';

const ProductTable = ({ children, filters = {}, setFilters, productCount }) => {
  const changePage = ({ selected }) => {
    setFilters({ ...filters, page: selected + 1, withCount: false });
  };

  const sortColumn = (sortBy) => {
    setFilters((prev) => {
      if (prev.column === sortBy) {
        if (prev.sort === 'asc') {
          return { ...prev, sort: 'desc' };
        } else {
          return { ...prev, sort: 'asc' };
        }
      }
      return { ...prev, column: sortBy, sort: 'asc' };
    });
  };

  return (
    <>
      <table className={'primary-table'}>
        <thead>
          <tr>
            <th onClick={() => sortColumn('code')} className="w-[25%] cursor-pointer">
              Code
            </th>
            <th onClick={() => sortColumn('name')} className="w-[25%] cursor-pointer">
              Name
            </th>
            <th>Category</th>
            <th onClick={() => sortColumn('quantity')} className="cursor-pointer">
              Quantity
            </th>
            <th onClick={() => sortColumn('amount_cents')} className="cursor-pointer">
              Price
            </th>
            <th onClick={() => sortColumn('sales_count')} className="cursor-pointer">
              Total Sales
            </th>
            <th onClick={() => sortColumn('lot')} className="cursor-pointer">
              LOT
            </th>
            <th onClick={() => sortColumn('status')} className="cursor-pointer">
              Status
            </th>
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
      <Pagination onPageChange={changePage} page={filters.page} totalItems={productCount} />
    </>
  );
};

export default ProductTable;
