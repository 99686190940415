import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import cs from 'classnames';
import _ from 'lodash';
import moment from 'moment';

import { selectDocumentHistory } from 'api/DocumentHistory';

import { ia } from 'lib/helpers/utility';

import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import Icon from 'components/shared/Icon/Icon';
import Skeleton from 'components/shared/Skeleton/Skeleton';

const DocumentHistory = ({
  id,
  kind,
  data,
  historyMode,
  currentVersion,
  setCurrentVersion,
  loading
}) => {
  const navigate = useNavigate();

  const selectRecord = async (record) => {
    setCurrentVersion(record.version);
    await selectDocumentHistory(navigate, {
      id,
      kind,
      version: record.version
    });
  };

  const records = useMemo(() => _.groupBy(data?.records || [], 'date'), [data]);

  if (!historyMode) return null;

  return loading ? (
    <div className="flex flex-1 flex-col gap-2 overflow-y-auto px-5 py-4">
      <Skeleton
        width="120px"
        height="20px"
        style={{ borderRadius: '8px' }}
        containerClassName="flex flex-col"
      />
      <Skeleton
        className="h-full"
        height="80px"
        count={2}
        style={{ borderRadius: '8px' }}
        containerClassName="flex flex-col"
      />

      <Skeleton
        width="120px"
        height="20px"
        style={{ borderRadius: '8px' }}
        containerClassName="flex flex-col"
      />
      <Skeleton
        className="h-full"
        height="80px"
        count={3}
        style={{ borderRadius: '8px' }}
        containerClassName="flex flex-col"
      />
    </div>
  ) : (
    <div className="flex flex-1 flex-col gap-2 overflow-y-auto px-5 py-4">
      {ia(Object.keys(records)) ? (
        Object.keys(records).map((rec) => (
          <>
            <p className="mb-1 ml-2 mt-4 text-sm text-primary-900">{formatDate(rec)}</p>
            {records[rec].map((record) => (
              <div
                onClick={() => record.version !== currentVersion && selectRecord(record)}
                className={cs(
                  'flex justify-between rounded-lg p-4',
                  record.version == currentVersion
                    ? 'bg-primary-900 !text-white'
                    : 'cursor-pointer bg-white text-primary-900 hover:bg-primary-50'
                )}
                key={record.id}>
                <div className="flex-col ">
                  <p className="text-sm font-medium">
                    {record.version == 1 ? (
                      'Original Version'
                    ) : (
                      <>
                        Version{' '}
                        <span
                          className={
                            record.version == currentVersion ? 'text-white' : 'text-primary-600'
                          }>
                          #{record.version}
                        </span>
                      </>
                    )}
                  </p>
                  {record?.version != 1 && (
                    <p className="text-xs opacity-80">
                      {formatDate(record.created_at)} {moment(record.created_at).format('hh:mm A')}
                    </p>
                  )}
                </div>
                <div className="flex flex-col">
                  <p
                    className={cs(
                      'rounded-full  px-2 py-1 text-xs ',
                      record.version == currentVersion ? 'bg-white bg-opacity-20' : 'bg-primary-50'
                    )}>
                    {record?.user?.p_name
                      ? record?.user?.p_name
                      : record?.user?.f_name + ' ' + record?.user?.l_name}
                  </p>
                </div>
              </div>
            ))}
          </>
        ))
      ) : (
        <div className="flex bg-white m-4 rounded-xl justify-center flex-col gap-2 h-full items-center">
          <Icon icon="new-history" />
          <p className="text-sm text-neutral-600"> No saved History </p>
        </div>
      )}
    </div>
  );
};

export default DocumentHistory;
