import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getMembers } from '../../../../api/Member';

export const useMember = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['member', ...dependencies], () => getMembers(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
