import React, { useEffect, useState } from 'react';
import {
  hideStickyNotes,
  scrollToElement,
  showStickyNotes,
  filterBySearchTerm,
  groupByBenefitCode
} from './eligibilityHelpers';
import EligibilityInfo from './components/EligibilityInfo';
import Benefits from './components/benefits/Benefits';
import EligibilitySideBar from './components/EligibilitySideBar';
import { withErrorBoundary } from '../../../../shared/Error/Boundary';
import EligibilityErrorComponent from './components/EligibilityErrorComponent';
import EligibilityErrorInfo from './components/EligibilityErrorInfo';
import { ia } from 'lib/helpers/utility';

const EligibilityPreview = ({ eligibility = {}, insuranceProfilesList, closeModal }) => {
  const isError = eligibility.hasOwnProperty('error_code');
  const eligibilityStructured = groupByBenefitCode(eligibility?.benefit) || [];

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedBenefit, setSelectedBenefit] = useState(eligibilityStructured[0]?.groupName);

  const handleScrollToSection = (id) => {
    setSelectedBenefit(id);
    scrollToElement(id);
  };

  const filteredArrays = filterBySearchTerm(eligibilityStructured, searchTerm);

  useEffect(() => {
    hideStickyNotes();

    return () => showStickyNotes();
  }, []);

  if (isError) {
    return <EligibilityErrorComponent eligibility={eligibility} closeModal={closeModal} />;
  }

  return (
    <div className="bg-neutral-50">
      <div className="flex h-[calc(100vh-155px)]">
        <EligibilitySideBar
          {...{ filteredArrays, handleScrollToSection, searchTerm, selectedBenefit, setSearchTerm }}
        />

        <div className="flex w-[70%] flex-col !gap-4 overflow-auto !py-4 !pl-4 !pr-2">
          <EligibilityInfo
            eligibility={eligibility}
            companyName={insuranceProfilesList[0]?.payer_name}
          />

          {ia(eligibility?.error) && <EligibilityErrorInfo eligibility={eligibility} />}

          <Benefits eligibilityStructured={eligibilityStructured} />
        </div>
      </div>
    </div>
  );
};

export default withErrorBoundary(EligibilityPreview);
