import React, { useRef } from 'react';
import Accordion from '../shared/Accordion';
import Orders from './components/Orders';
import { useClinicalNoteContext } from '../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';

const OrdersSection = ({ components }) => {
  const { clinicalNote } = useClinicalNoteContext() || {};
  const sectionRef = useRef();

  return (
    <Accordion
      sectionRef={sectionRef}
      title={components?.orders?.title}
      id={components?.orders?.id}
      disabled={clinicalNote?.locked}>
      <Orders />
    </Accordion>
  );
};

export default OrdersSection;
