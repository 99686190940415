import isObject from 'lodash/isObject';
import moment from 'moment';

import { ia } from 'lib/helpers/utility';

const hasValues = (value) => {
  if (ia(value)) return value.length > 0;

  if (isObject(value)) {
    if (moment.isMoment(value.endDate) && moment.isMoment(value.startDate)) {
      return true;
    }
    return Object.keys(value).length > 0;
  }

  return !!value;
};

export default function getFilterValues(filters) {
  const result = [];

  for (const key in filters) {
    const values = filters[key].values;

    if (isObject(values) && !ia(values)) {
      if (moment.isMoment(values.endDate) && moment.isMoment(values.startDate)) {
        result.push(moment(values.endDate).format('YYYY-MM-DD'));
        result.push(moment(values.startDate).format('YYYY-MM-DD'));
      } else if (hasValues(values)) {
        result.push(values);
      }
    } else if (hasValues(values)) {
      result.push(values);
    }
  }

  return result;
}
