import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getFileStackUrl } from 'api/Filestack';

import { useFileStack } from 'lib/hooks/useFileStack';

import Icon from 'components/shared/Icon/Icon';
import Loading from 'components/shared/Loading/Loading';
import Modal from 'components/shared/Modal/Modal';

const PreviewContent = ({ type, handle }) => {
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: ['preview', handle],
    queryFn: () => getFileStackUrl(navigate, { handle }),
    refetchOnMount: false,
    refetchOnWindowFocus: false
  });

  if (isLoading) return <Loading />;

  if (!data?.file)
    return (
      <div className="flex flex-col items-center">
        <Icon icon="new-no-data" />
        <h1 className="text-base font-500 text-primary-900">No attachment available</h1>
      </div>
    );

  const url = data?.file;

  if (type.startsWith('image/')) {
    return <img src={url} alt="Preview" className="max-h-full max-w-full" />;
  }

  if (type === 'application/pdf') {
    return <iframe src={url} title="PDF Preview" className="h-[90vh] w-[80vw] border-0" />;
  }

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <Icon icon="file-alt" className="mb-4 text-4xl text-gray-500" />
      <p className="text-gray-600">Preview is not available for this file type.</p>
      <a href={url} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
        Download File
      </a>
    </div>
  );
};

const PreviewFile = ({ isOpen, onClose, title, handle, slideFromRight, isLarge }) => {
  const { readFile } = useFileStack();

  const { data, isLoading } = useQuery({
    queryKey: ['previewFile', handle],
    queryFn: () => readFile(handle),
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!handle
  });

  if (!isOpen || !handle) return null;

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      isLarge={isLarge}
      handleClose={onClose}
      slideFromRight={slideFromRight}
      className="h-full">
      <div className="flex h-full items-center justify-center p-4">
        {isLoading ? <Loading /> : <PreviewContent handle={handle} type={data.type} />}
      </div>
    </Modal>
  );
};

export default PreviewFile;
