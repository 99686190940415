import React, { useState, useEffect } from 'react';
import { FormBuilderContext } from './FormBuilderContext';
import { useFormik } from 'formik';
import {
  FormInitialValues,
  FormValidationSchema
} from 'components/practice/settings/forms/FormBuilder/lib/helpers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createForm } from 'api/Forms';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showAlert } from 'components/shared/Alert/Alert';
import _, { isEqual } from 'lodash';
import useDisplaySettings from 'lib/hooks/useDisplaySettings';
import { useRecoilValue } from 'recoil';
import { permissions, userState as user_state } from 'components/state';

export const FormBuilderContextProvider = ({ children }) => {
  const userState = useRecoilValue(user_state);
  const [form, setForm] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { is_admin } = useRecoilValue(permissions);
  const [autoSave, setAutoSave] = useState(
    userState?.display_settings?.formBuilder?.auto_save?.enabled
  );
  const [saveButtonText, setSaveButtonText] = useState('Save Progress');
  const [searchTerm, setSearchTerm] = useState('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);
  const [isEdit, setIsEdit] = useState(false);
  const [showNavigationButtons, setShowNavigationButtons] = useState(false);
  const [viewFormModal, setViewFormModal] = useState(false);
  const [packetId, setPacketId] = useState();
  const [isSaveAndExit, setIsSaveAndExit] = useState(false);

  const [selectedLength, setSelectedLength] = useState(0);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const location = useLocation();

  let initialBreadCrumbs = [
    { title: 'Name&Type', view: 'name_and_type', active: true, isFinished: false },
    { title: 'Builder', view: 'builder', active: false, isFinished: false },
    { title: 'Review', view: 'review', active: false, isFinished: false },
    {
      title: 'AssignToPacket',
      view: 'assign_to_packet',
      active: false,
      isFinished: false,
      hidden: true
    }
  ];

  const [breadCrumbs, setBreadCrumbs] = useState(initialBreadCrumbs);
  const [enableNextTo, setEnableNextTo] = useState(false);
  const getActiveBreadCrumb = breadCrumbs.find((breadCrumb) => breadCrumb.active === true);

  const { mutateAsync: mutateCreateForm, isLoading: isFormSavingLoading } = useMutation({
    mutationFn: (data) => createForm(navigate, data)
  });

  const formik = useFormik({
    initialValues: FormInitialValues(),
    validationSchema: FormValidationSchema,
    onSubmit: async (values) => {
      let formPacketSubmit = false;
      if (getActiveBreadCrumb.view === 'assign_to_packet' || isEdit || isSaveAndExit) {
        formPacketSubmit = true;
      }
      await mutateCreateForm(
        {
          ...values,
          formPacketSubmit,
          isEdit,
          step: getActiveBreadCrumb?.view,
          practice_id: practiceId
        },
        {
          onSuccess: ({ code, form, packetIds, error }) => {
            if (code === 0) {
              queryClient.invalidateQueries(['forms']);
              queryClient.refetchQueries(['customForm']);
              queryClient.refetchQueries(['form_packets']);
              queryClient.refetchQueries(['form_drafts']);
              showAlert({
                title: isEdit
                  ? 'Form updated successfully'
                  : isSaveAndExit
                    ? 'Form saved successfully'
                    : 'Form created successfully',
                color: 'success'
              });
            } else {
              showAlert({
                title: isEdit ? 'Form update failed' : 'Form creation failed',
                message: error ?? 'Something went wrong',
                color: 'danger'
              });
            }
            formik.setValues({ ...formik.values, form, form_packet_ids: packetIds });
            setPrevFormValues(form);
            setPrevPacketIds(packetIds);
            handleClose();
          }
        }
      );
    }
  });
  const { saveDisplaySettings } = useDisplaySettings(userState);

  const [prevFormValues, setPrevFormValues] = useState(formik?.values);
  const [prevPacketIds, setPrevPacketIds] = useState(formik.values?.form_packet_ids);

  useEffect(() => {
    const handler = _.debounce(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 500);

    handler();

    return () => {
      handler.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!openModal) return;
      if (!autoSave || isFormSavingLoading) return;
      if (isEqual(prevFormValues, formik.values.form)) return;
      if (getActiveBreadCrumb.view === 'assign_to_packet') {
        if (isEqual(prevPacketIds, formik.values.form_packet_ids)) return;
      }
      let formPacketSubmit = isEdit;

      await mutateCreateForm(
        {
          ...formik.values,
          step: getActiveBreadCrumb?.view,
          practice_id: practiceId,
          formPacketSubmit,
          isEdit
        },
        {
          onSuccess: ({ code, form, packetIds, error }) => {
            queryClient.refetchQueries(['forms']);
            queryClient.refetchQueries(['form_drafts']);
            if (code !== 0) {
              showAlert({
                title: 'Form creation failed',
                message: error ?? 'Something went wrong',
                color: 'danger'
              });
              setAutoSave(false);
            }
            formik.setValues({ ...formik.values, form, form_packet_ids: packetIds });
            setPrevFormValues(form);
            setPrevPacketIds(packetIds);
          }
        }
      );
    }, 30000); // 30s

    return () => {
      clearInterval(intervalId);
    };
  }, [autoSave, formik.values]);

  useEffect(() => {
    if (location.state?.openModal) {
      setOpenModal(true);
    }
    if (location.state?.packet_id) {
      setPacketId(location.state?.packet_id);
    }
  }, [location.state, setOpenModal]);

  useEffect(() => {
    if (is_admin) {
      setAutoSave(true);
    }
  }, [is_admin]);

  const handleClose = () => {
    setOpenModal(false);
    setBreadCrumbs(initialBreadCrumbs);
    formik.resetForm();
    setEnableNextTo(false);
    setSaveButtonText('Save Progress');
    setIsEdit(false);
    setShowNavigationButtons(false);
    setIsSaveAndExit(false);
  };

  const formChange = (event) => {
    formik.setFieldValue('form.json', { fields: event });
  };

  const handleSave = async () => {
    let formPacketSubmit = false;
    if (isEdit || isSaveAndExit) {
      formPacketSubmit = true;
    }
    await mutateCreateForm(
      {
        ...formik.values,
        step: getActiveBreadCrumb?.view,
        practice_id: practiceId,
        formPacketSubmit,
        isEdit
      },
      {
        onSuccess: ({ code, form, packetIds, error }) => {
          queryClient.refetchQueries(['forms']);
          queryClient.refetchQueries(['form_drafts']);
          if (code !== 0) {
            showAlert({
              title: 'Form creation failed',
              message: error ?? 'Something went wrong',
              color: 'danger'
            });
            setAutoSave(false);
          }
          formik.setValues({ ...formik.values, form, form_packet_ids: packetIds });
          setPrevFormValues(form);
          setPrevPacketIds(packetIds);
        }
      }
    );
  };

  const handleAutoSave = (event) => {
    setAutoSave(event);
    saveDisplaySettings({
      ...userState.display_settings,
      formBuilder: {
        auto_save: {
          enabled: event
        }
      }
    });
  };

  const { id: practiceId } = useParams();
  return (
    <FormBuilderContext.Provider
      value={{
        autoSave,
        setAutoSave,
        saveButtonText,
        setSaveButtonText,
        breadCrumbs,
        setBreadCrumbs,
        initialBreadCrumbs,
        formik,
        enableNextTo,
        setEnableNextTo,
        setOpenModal,
        openModal,
        handleClose,
        formChange,
        form,
        setForm,
        searchTerm,
        setSearchTerm,
        debouncedSearchTerm,
        setDebouncedSearchTerm,
        isFormSavingLoading,
        handleSave,
        practiceId,
        isEdit,
        setIsEdit,
        showNavigationButtons,
        setShowNavigationButtons,
        viewFormModal,
        setViewFormModal,
        handleAutoSave,
        packetId,
        setPacketId,
        selectedLength,
        setSelectedLength,
        isSaveAndExit,
        setIsSaveAndExit
      }}>
      {children}
    </FormBuilderContext.Provider>
  );
};
