import React, { useMemo, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import List from 'components/shared/CustomizableBoard/widgets/components/List';
import WidgetHeader from 'components/shared/CustomizableBoard/widgets/components/WidgetHeader';
import formatDate from 'components/shared/CustomizableBoard/widgets/helpers/formatDate';
import limitItems from 'components/shared/CustomizableBoard/widgets/helpers/limitItems';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import Axios from 'configuredAxios';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import FooterWrapper from 'components/shared/CustomizableBoard/widgets/components/FooterWrapper';
import { createSocialHistoryNarrative } from 'components/shared/Forms/Custom/lib/socialHistoryNarrativeHelper';
import SocialHistoryForm from 'components/shared/Forms/Custom/SocialHistory/ClinicalSocialHistory';
import Allowed from 'components/shared/Permissions/Allowed';
import { stripHtmlTags } from 'lib/helpers/utility';

const SocialHistoryRow = ({ item, openItemModal }) => {
  const isNoDetailsTrue = !!item?.original?.noDetails;

  if (isNoDetailsTrue) {
    return (
      <div
        onClick={() => openItemModal(item)}
        className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
        <div className="flex items-center justify-between gap-2">
          <p className="font-medium text-neutral-800">No notable social history</p>
          <Icon className="cursor-pointer" icon="new-chevron-right" />
        </div>
        <p className="mt-2 text-neutral-600">
          {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
        </p>
      </div>
    );
  }

  return !!item?.list?.length ? (
    <div
      onClick={() => openItemModal(item)}
      className="flex cursor-pointer flex-col gap-[5px] px-[20px] py-3 hover:bg-primary-25">
      <List
        items={item?.list}
        dividerType="none"
        renderItemComponent={(listItem, index) => {
          return (
            <>
              <div className="flex flex-col gap-[4px]">
                <div className="flex items-center justify-between gap-2">
                  <p className="font-medium text-neutral-800">Social History</p>

                  {index == 0 && <Icon className="cursor-pointer" icon="new-chevron-right"></Icon>}
                </div>
                <p className="line-clamp-2 text-neutral-600">
                  {stripHtmlTags(createSocialHistoryNarrative(listItem))}
                </p>
              </div>
            </>
          );
        }}
      />
      <p className="mt-2 text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  ) : null;
};

export default function SocialHistory(props) {
  const { customData, layout, patientId } = props;
  const socialHistory = customData?.socialHistory ? customData?.socialHistory : null;

  const defaultSize = useMemo(
    () => layout.find((l) => l.i === props.item.i)?.h,
    [socialHistory, layout]
  );

  const [show, setShow] = useState(false);

  const { count, items, hasMore } = useMemo(
    () =>
      limitItems(socialHistory, defaultSize, {
        description: 0.15,
        createdAt: 0.2,
        group: 0.11,
        item: 0.3
      }),
    [socialHistory, defaultSize]
  );

  const [currentFormData, setCurrentFormData] = useState();
  const [itemModal, setItemModal] = useState(null);

  const queryClient = useQueryClient();

  const openItemModal = (item) => {
    const formItem = customData?.originalSocialHistory.find((i) => i.id === item?.id);
    setItemModal(formItem);
    setCurrentFormData(formItem?.response);
  };

  const submitForm = async () => {
    try {
      const result = await Axios.post('/api/custom_form_response/create', {
        formId: itemModal?.id,
        patientId,
        response: currentFormData,
        type: 'socialHistory'
      });

      const { newResponse } = result.data;

      if (newResponse) {
        showAlert({ title: 'Successfully saved' });
        setItemModal();
        setCurrentFormData();
        queryClient.invalidateQueries(['customFormResponse']);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        onPlusClick={() => setItemModal({})}
        icon="history"
        addPermissions="patients_medical_history.update"
        {...props}></WidgetHeader>
      <Allowed
        requiredPermissions="patients_medical_history.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col gap-2 overflow-y-auto">
          <FooterWrapper
            hasMore={hasMore}
            onClick={() => setShow(true)}
            text={`Show all Social History (${count})`}>
            {items ? (
              <List
                items={items}
                renderItemComponent={(item) => (
                  <SocialHistoryRow openItemModal={openItemModal} item={item} />
                )}
              />
            ) : (
              <div className="p-[20px] py-3 text-center">
                <p className="font-medium text-neutral-500">No Social History</p>
              </div>
            )}
          </FooterWrapper>
        </div>

        <Modal
          handleOpen={show}
          title="Social History"
          slideFromRight
          className="w-[1020px] bg-white"
          handleClose={() => setShow(false)}>
          {socialHistory ? (
            <List
              items={socialHistory}
              renderItemComponent={(item) => (
                <SocialHistoryRow openItemModal={openItemModal} item={item} />
              )}
            />
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Social History</p>
            </div>
          )}
        </Modal>

        <Modal
          className="w-[1020px]"
          footer={
            <div className="flex w-full items-center justify-between">
              <Button
                data-qa="close-btn"
                text="Close"
                transparent
                outlined
                onClick={() => {
                  setItemModal();
                }}
              />
              <Button text="Save" onClick={() => submitForm()} data-qa="save-btn" />
            </div>
          }
          handleOpen={!!itemModal}
          title="Social History"
          slideFromRight
          handleClose={() => setItemModal()}>
          <SocialHistoryForm
            setItemModal={setItemModal}
            formId={itemModal?.id}
            defaultValue={itemModal?.response}
            setCurrentFormData={setCurrentFormData}
          />
        </Modal>
      </Allowed>
    </div>
  );
}
