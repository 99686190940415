import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPayers } from '../../../../api/Payer';

export const usePayers = ({ params = {}, options = {}, dependencies = [] } = {}) => {
  const navigate = useNavigate();
  return useQuery(['payers', ...dependencies], () => getPayers(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
