import { Honeybadger } from '@honeybadger-io/react';
import cs from 'classnames';
import Confirm from 'components/shared/Modal/Confirm/Confirm';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { useRecoilState } from 'recoil';
import Axios from '../../../../../../../../../configuredAxios';
import { ia, iaRa } from '../../../../../../../../../lib/helpers/utility';
import Button from '../../../../../../../../shared/Buttons/Button';
import CPTCodesRow from './CPTCodesRow';
import { clinicalNote as clinicalNoteState } from 'components/state';

export const handleCPTSearch = async (searchTerm, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await Axios.post('/api/codes/cpt/get', params);
      if (res.data.cpt) {
        return res.data.cpt.map((item) => {
          const label = item.name ? `${item.code} - ${item.name}` : `${item.code}`;
          return {
            value: item.code,
            label,
            charge: item.par_amount,
            meta: item,
            base_unit: item.base_unit
          };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later. `);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};

export const handleModifierSearch = async (searchTerm, codes = []) => {
  if (searchTerm) {
    try {
      const params = {
        searchTerm,
        limit: 100
      };
      let res = await Axios.post('/api/codes/modifiers/get', params);
      if (res.data.modifiers) {
        return res.data.modifiers.map((item) => {
          return { value: item.code, label: `${item.code} - ${item.name}` };
        });
      } else {
        Honeybadger.notify(`There's been an unexpected error, please try again later.`);
      }
    } catch (err) {
      Honeybadger.notify(`There's been an unexpected error, please try again later. ${err}`);
    }
  } else if (codes.length > 0) {
    return codes.map((item) => {
      return { value: item, label: item };
    });
  }
};

const CPTCodes = ({ sectionRef = null }) => {
  const initialValue = [
    {
      procedure_code: null,
      modifiers: [],
      uuid: self.crypto.randomUUID()
    }
  ];

  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);
  const [cptCodes, setCptCodes] = useState([]);
  const [pendingDeleteCPT, setPendingDeleteCPT] = useState(null);

  useEffect(() => {
    setCptCodes(
      ia(clinicalNote?.cpt_codes) ? [...clinicalNote.cpt_codes]?.reverse() : initialValue
    );
  }, [clinicalNote?.cpt_codes]);

  useImperativeHandle(sectionRef, () => ({
    formData: { cpt_codes: cptCodes }
  }));

  const handleAddRow = (event) => {
    event?.preventDefault();
    setCptCodes([
      {
        uuid: self.crypto.randomUUID(),
        procedure_code: null,
        name: null,
        modifiers: []
      },
      ...cptCodes
    ]);
  };

  const handleDeleteRow = (uuidToDelete) => {
    const findRowToDelete = cptCodes.find((row) => row.uuid === uuidToDelete);

    if (findRowToDelete?.id) {
      const chart3DFacePoints = iaRa(clinicalNote?.charts3d?.face?.points);

      const hasPointsInFace = chart3DFacePoints.some((row) => findRowToDelete?.id == row?.product);

      if (hasPointsInFace) {
        setPendingDeleteCPT(uuidToDelete);
        return;
      }
    }

    deleteCPT(uuidToDelete);
  };

  const deleteCPT = (uuidToDelete) => {
    if (!uuidToDelete) return;

    const filteredCpt = cptCodes.filter((row) => row.uuid !== uuidToDelete);

    setCptCodes(filteredCpt);
    setClinicalNote({ ...clinicalNote, cpt_codes: [...filteredCpt].reverse() });
  };

  const handleContinue = () => {
    deleteCPT(pendingDeleteCPT);
    setPendingDeleteCPT(null);
  };

  const handleOnChange = ({ key, event, uuid }) => {
    const updatedArray = iaRa(cptCodes)
      .map((row) => {
        if (row.uuid !== uuid) {
          return row;
        }

        const newProcedureCode = event?.meta?.code;

        if (event?.meta) {
          delete event?.meta?.code;
          delete event?.meta?.created_at;
          delete event?.meta?.updated_at;
        }

        if (key === 'code') {
          return {
            uuid: self.crypto.randomUUID(),
            procedure_code: newProcedureCode,
            name: event?.label,
            modifiers: [],
            charge: null,
            ...event?.meta
          };
        }

        return { ...row, [key]: event };
      })
      .filter((row) => row?.procedure_code);

    setCptCodes(updatedArray);
    setClinicalNote({
      ...clinicalNote,
      cpt_codes: [...updatedArray]?.reverse()
    });
  };

  return (
    <div>
      <div className="dashed-top flex justify-end !pb-2">
        <Button
          text="Add CPT® code"
          iconRight="add-circle"
          transparent
          onClick={handleAddRow}
          data-qa="add-cpt-code"
        />
      </div>
      <div className="grid gap-y-4">
        {cptCodes.map((item, idx) => (
          <CPTCodesRow
            key={idx}
            className={cs('!pt-4 first-of-type:!pt-0', idx !== 0 && 'dashed-top')}
            onChange={handleOnChange}
            onDelete={handleDeleteRow}
            length={cptCodes.length}
            {...item}
          />
        ))}
      </div>

      <Confirm
        variant="danger"
        primaryBtnTxt="Delete Anyway"
        title="Delete CPT Code Used on Face 3D Chart"
        icon="new-delete-macro"
        message={
          <div>
            Are you sure you want to delete this CPT Code?{' '}
            <em className="text-warning-900">
              This may currently be used as a point on the Face 3D Chart. If you want to be sure,
              please check the Face 3D Chart before proceeding.
            </em>
          </div>
        }
        handleContinue={handleContinue}
        handleOpen={!!pendingDeleteCPT}
        handleClose={() => setPendingDeleteCPT(null)}
      />
    </div>
  );
};

export default CPTCodes;
