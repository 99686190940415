import { Fragment } from 'react';

import { Switch as HeadlessSwitch } from '@headlessui/react';
import cs from 'classnames';

import { colorClasses } from './lib/getClasses';

/**
 * @param {import('./lib/propTypes').SwitchProps} props
 */

const Switch = ({
  onChange = () => {},
  checked = false,
  color = 'primary', // our themes
  onColor = '', //custom colors
  offColor = '', //custom colors
  onHandleColor = '', //custom colors
  offHandleColor = '', //custom colors
  width = 30,
  height = 18,
  label = '',
  labelClassName = '',
  dataQa = 'switch',
  disabled = false,
  ...rest
}) => {
  const baseRatio = 0.67;
  const dotSize = height * baseRatio; // the dot size will be calculated based on height
  const translateChecked = width - dotSize - 6;
  const translateUnchecked = 0;

  return (
    <div>
      {label && (
        <label
          className={cs(
            'block pb-1 text-sm font-500',
            disabled ? 'text-neutral-400' : 'text-neutral-800',
            labelClassName
          )}>
          {label}
        </label>
      )}
      <HeadlessSwitch
        checked={checked}
        onChange={onChange}
        as={Fragment}
        disabled={disabled}
        data-qa={dataQa}
        {...rest}>
        {({ checked }) => (
          <button
            className={cs(
              'flex cursor-pointer items-center rounded-full px-[3px] !outline-transparent duration-200',
              `w-[${width}px]`,
              `min-w-[${width}px]`,
              `h-[${height}px]`,
              !checked &&
                !disabled &&
                !onColor &&
                'bg-neutral-100 hover:bg-neutral-200 focus:bg-neutral-200 focus:!outline focus:!outline-2 focus:!outline-neutral-100',
              !checked && disabled && '!cursor-not-allowed bg-neutral-50',
              checked && disabled && `!cursor-not-allowed ${colorClasses[color]?.['disabledBg']}`,
              checked && !disabled && !onColor && `${colorClasses[color]?.['checkedBg']}`
            )}
            // the style above is just for custom colors
            style={{
              backgroundColor: checked && onColor && offColor ? onColor : offColor,
              opacity: disabled && '0.5'
            }}>
            <span
              className={cs(
                'rounded-full duration-200',
                !checked && !disabled && 'bg-neutral-500',
                !checked && disabled && 'bg-neutral-200',
                checked && !disabled && `bg-${color}-500`,
                checked && disabled && `${colorClasses[color]?.['disabledDotBg']}`
              )}
              style={{
                width: dotSize,
                minWidth: dotSize,
                height: dotSize,
                backgroundColor:
                  checked && onHandleColor && offHandleColor ? onHandleColor : offHandleColor,
                transform: `translateX(${checked ? translateChecked : translateUnchecked}px)`
              }}
            />
          </button>
        )}
      </HeadlessSwitch>
    </div>
  );
};

export default Switch;
