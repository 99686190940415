import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Honeybadger } from '@honeybadger-io/react';
import { useQueryClient } from '@tanstack/react-query';
import { pick } from 'lodash';

import { requestApi } from 'api/Api';

import { optionify } from 'lib/helpers/utility';
import { useProfessionGroups } from 'lib/hooks/queries/profession/useProfessionGroups';
import { useRoleList } from 'lib/hooks/queries/roles/useRoleList';

import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import ColorPicker from 'components/shared/ColorPicker/ColorPicker';
import Modal from 'components/shared/Modal/Modal';
import Allowed from 'components/shared/Permissions/Allowed';
import Select from 'components/shared/Select/Select';
import Switch from 'components/shared/Switch/Switch';

import { getCurrentOption } from '../lib/helpers';
import { initialMember } from '../lib/initials';

const StaffMember = ({ member: loadedMember, onClose, handleOpen, practiceId }) => {
  const [member, setMember] = useState(loadedMember || initialMember);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);

  const { data } = useRoleList({ params: { practiceId } });
  const { data: professions } = useProfessionGroups({
    params: {}
  });

  const professionGroupOptions = optionify(professions?.professions || []);

  const roles = optionify(data?.role_list) || [];

  const handleSave = async () => {
    const onError = (error) => {
      showAlert({ title: error, color: 'danger' });
      Honeybadger.notify(`handleSave staffMember: ${error}`);
      setLoading(false);
    };

    const onSuccess = () => {
      queryClient.invalidateQueries({ queryKey: ['member'] });
      queryClient.invalidateQueries(['staffPracticeAdmin']);
      queryClient.invalidateQueries(['getRoleList']);

      onClose();
      setMember(initialMember);
      setLoading(false);
      showAlert({ title: 'Staff member updated successfully', color: 'success' });
    };

    setLoading(true);
    const changes = pick(member, ['role_id', 'profession_group_id', 'status', 'color']);

    requestApi({
      url: 'api/practice/member/update',
      navigate,
      onError,
      onSuccess,
      params: {
        newMemberSettings: changes,
        userId: member.user_id,
        practiceId: member.practice_id || practiceId
      }
    });
  };

  const setSelectedRole = (e) => {
    setMember({ ...member, role_id: e?.value });
  };

  const setSelectedProfessionGroup = (e) => {
    setMember({ ...member, profession_group_id: e?.value ?? null });
  };

  return (
    <Modal
      slideFromRight={true}
      isOpen={handleOpen}
      handleClose={onClose}
      title="Edit Staff Member"
      footer={
        <div className="flex w-full justify-between">
          <Button outlined text="Cancel" color="neutral" onClick={onClose} />
          <Button
            text="Save"
            onClick={handleSave}
            loading={loading}
            green
            data-qa="staff-save-btn"
          />
        </div>
      }>
      <div className="flex flex-col gap-4">
        <Allowed requiredPermissions="role.update">
          <Select
            inputId="select-role"
            label="Select Role"
            isClearable={false}
            menuPlacement="auto"
            menuContainerStyle={{ zIndex: 99999 }}
            options={roles}
            value={getCurrentOption(roles, Number(member.role_id))}
            onChange={setSelectedRole}
            style={{ width: '15rem' }}
          />
          <Select
            inputId="select-profession-group"
            label="Select Profession Group"
            isClearable={true}
            menuPlacement="auto"
            menuContainerStyle={{ zIndex: 99999 }}
            options={professionGroupOptions}
            value={getCurrentOption(professionGroupOptions, Number(member.profession_group_id))}
            onChange={setSelectedProfessionGroup}
            style={{ width: '15rem' }}
          />
        </Allowed>
        <Switch
          label="Member Active"
          checked={member.status === 'active'}
          onChange={(e) => setMember({ ...member, status: e ? 'active' : 'inactive' })}
        />
        <ColorPicker color={member.color} onChange={(e) => setMember({ ...member, color: e })} />
      </div>
    </Modal>
  );
};

export default StaffMember;
