import cs from 'classnames';

import Avatar from 'components/shared/Avatar/Avatar';
import Checkbox from 'components/shared/Checkbox/Checkbox';

const UserItem = ({ item, setSelectedUser = () => {}, isChecked, f_name = '', l_name = '' }) => (
  <div
    className={cs(
      'flex cursor-pointer items-center gap-3 p-1 rounded-md',
      isChecked ? 'bg-primary-50' : ''
    )}
    onClick={() => setSelectedUser(item)}>
    <Checkbox radio isChecked={isChecked} onChange={() => setSelectedUser(item)} id={item.id} />
    <Avatar firstName={f_name} lastName={l_name} id={item?.user_id || item?.id} size="large" />
    <p className="font-500 text-neutral-900">{f_name + ' ' + l_name}</p>
  </div>
);

export default UserItem;
