import Button from 'components/shared/Buttons/Button';
import { useFormBuilderContext } from 'lib/context/FormBuilder/FormBuilderContext';
import React from 'react';

const FooterButtons = ({
  buttonText,
  nextTo,
  backTo,
  showSaveAndExit = false,
  isLastStep,
  setBreadCrumbs,
  showSkip
}) => {
  const {
    enableNextTo,
    formik,
    handleClose,
    showNavigationButtons,
    isEdit,
    setIsSaveAndExit,
    selectedLength
  } = useFormBuilderContext();
  const navigateViews = ({ componentToView = '', isNext = false }) => {
    setBreadCrumbs((prevCrumbs) =>
      prevCrumbs.map((crumb) => {
        if (crumb.view === componentToView) {
          return { ...crumb, active: true };
        } else if (isNext && crumb.active && !crumb.isFinished) {
          return { ...crumb, isFinished: true, active: false };
        } else {
          return { ...crumb, active: false };
        }
      })
    );
  };
  const handleSubmit = (hasSaveAndExit) => {
    if (hasSaveAndExit) {
      setIsSaveAndExit(true);
    }
    formik.handleSubmit();
  };
  return (
    <div className="flex  w-full items-center justify-between">
      {showNavigationButtons ? (
        <Button
          disabled={!backTo}
          outlined
          color="neutral"
          text="Back"
          onClick={() => navigateViews({ componentToView: backTo })}
          data-qa="back-btn"
        />
      ) : (
        <Button color="neutral" text="Close" outlined onClick={handleClose} data-qa="close-btn" />
      )}
      <div className="flex-end flex gap-4">
        {showSaveAndExit && (
          <Button
            text="Save & Exit"
            onClick={() => handleSubmit(true)}
            outlined
            data-qa="save-exit-btn"
          />
        )}

        {showNavigationButtons && nextTo && (
          <Button
            disabled={!enableNextTo}
            text={buttonText}
            onClick={() => navigateViews({ componentToView: nextTo, isNext: true })}
            type="primary"
            data-qa={`${buttonText}-btn`}
          />
        )}
        {showSkip && <Button text="Skip" onClick={() => handleSubmit()} type="primary" outlined />}
        {isLastStep && (
          <Button
            text={isEdit ? 'Update' : 'Add'}
            disabled={isEdit ? false : !selectedLength}
            onClick={handleSubmit}
            type="primary"
            data-qa="submit-btn"
          />
        )}
      </div>
    </div>
  );
};

export default FooterButtons;
