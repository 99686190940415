export function getSelectedForms(
  selectedPromptTagsIds,
  clinicalNote,
  selectedTableRows,
  currentHpOverviewData
) {
  let selectedForms = {};
  selectedPromptTagsIds.map((key) => {
    if (key === 'cpt-codes') {
      selectedForms[key] = clinicalNote?.['cpt_codes'];
    }
    if (key === 'diagnosis-codes') {
      selectedForms[key] = clinicalNote?.['icd_10_codes'];
    }
    if (key === 'treatment-plan') {
      selectedForms[key] = selectedTableRows?.['treatment_plan']?.data;
    }
    if (currentHpOverviewData?.[key] || clinicalNote?.[key]) {
      selectedForms[key] = currentHpOverviewData?.[key] || clinicalNote?.[key];
    }
    if (selectedTableRows?.[key]) {
      selectedForms[key] = selectedTableRows?.[key]?.data;
    }
  });
  return selectedForms;
}
