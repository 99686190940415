import debounce from 'lodash/debounce';
import React, { useMemo } from 'react';
import CurrencyInput from '../CurrencyInput/CurrencyInput';

const RangeFilter = ({ data, index, setFilter }) => {
  const debouncedSetFilter = useMemo(
    () => debounce((newData) => setFilter(index, newData), 500),
    [index, setFilter]
  );

  const handleChangeFrom = (fromValue) => {
    debouncedSetFilter({ ...data, values: { ...data.values, from: +fromValue } });
  };

  const handleChangeTo = (toValue) => {
    debouncedSetFilter({ ...data, values: { ...data.values, to: +toValue } });
  };

  return (
    <div className="flex cursor-pointer flex-col !py-2">
      <p className="!px-4 font-medium text-primary-700 text-sm">{data?.title || 'Search'}</p>
      <div className="px-3">
        <div className="mt-2 flex items-center gap-2">
          <p className="font-medium">Min</p>
          <CurrencyInput
            id="from_value"
            prefix="$"
            allowDecimals={true}
            decimalSeparator="."
            name="from_value"
            value={data.values?.from || 0}
            onValueChange={handleChangeFrom}
            placeholder="From"
          />
          <p className="px-1 font-medium">-</p>
          <CurrencyInput
            id="to_value"
            prefix="$"
            allowDecimals={true}
            decimalSeparator="."
            name="to_value"
            value={data.values?.to || ''}
            onValueChange={handleChangeTo}
            placeholder="To"
          />
          <p className="font-medium">Max</p>
        </div>
      </div>
    </div>
  );
};

export default RangeFilter;
