/* eslint-disable max-lines */
import React from 'react';
import { useRoutes } from 'react-router-dom';

import Documentation from 'components/Documentation/Documentation';
import InstantAuth from 'components/InstantActions/components/InstantAuth';
import Mobile from 'components/Kiosk/Mobile';
import PaySuccess from 'components/Patient/checkin/payment/paySuccess';
import Staff from 'components/Staff/Staff';
//Admin
import ICD10Codes from 'components/admin/pages/Databank/ICD10';
// import TermsAndConditions from '../components/public/TermsAndConditions/TermsAndConditions';
// import PrivacyPolicy from '../components/public/PrivacyPolicy/PrivacyPolicy';
import Intelligence from 'components/admin/pages/Intelligence';
import AdminIntelligenceAiTask from 'components/admin/pages/Intelligence/AiTask';
import AdminIntelligenceModel from 'components/admin/pages/Intelligence/Model';
import AdminIntelligencePrompts from 'components/admin/pages/Intelligence/Prompt';
// Admin User Management
import AdminPatientBasicInfo from 'components/admin/pages/Patients/PatientBasicInfo';
import AdminFormBuilder from 'components/admin/pages/Practices/FormBuilder/AdminFormBuilder';
import ReportingDashboard from 'components/admin/pages/ReportingCenter/ReportingCenter';
import AdminUsersTable from 'components/admin/pages/Users/AdminUsersTable';
import AuditLogs from 'components/audit/logs/AuditLogs';
import Eob from 'components/practice/BeyondBilling/Claims/ERA&EOB/EOB/Eob';
import Era from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA/Era';
import ViewEra from 'components/practice/BeyondBilling/Claims/ERA&EOB/ERA/components/ViewEra/ViewEra';
import NewClaims from 'components/practice/BeyondBilling/Claims/NewClaims';
import ElectronicInvoicing from 'components/practice/BeyondBilling/ElectronicInvoicing/ElectronicInvoicing';
import ExternalInvoices from 'components/practice/BeyondBilling/ElectronicInvoicing/ExternalInvoices';
import InternalInvoices from 'components/practice/BeyondBilling/ElectronicInvoicing/InternalInvoices';
import IncomeIQueue from 'components/practice/BeyondBilling/IncomeIQueue/IncomeIQueue';
import PatientLedger from 'components/practice/BeyondBilling/PatientLedger/PatientLedger';
import Calendar from 'components/practice/Calendar/Calendar';
// Portal
import Dashboard from 'components/practice/Dashboard/Dashboard';
import AppointmentsQueue from 'components/practice/PracticeIQueue/Appointments/AppointmentsQueue';
import ClinicalNotes from 'components/practice/PracticeIQueue/ClinicalNotes/ClinicalNotes';
import FormPacket from 'components/practice/PracticeIQueue/FormPacket/FormPacket';
import Notifications from 'components/practice/PracticeIQueue/Notifications/Notifications';
import Orders from 'components/practice/PracticeIQueue/Orders/Orders';
import Overview from 'components/practice/PracticeIQueue/Overview/Overview';
import Tasks from 'components/practice/PracticeIQueue/Tasks/Tasks';
import TreatmentPlanIQueue from 'components/practice/PracticeIQueue/TreatmentPlan';
import PracticeIQueue from 'components/practice/PracticeIQueue/index';
import CConFile from 'components/practice/charts/Account/tabs/CConFile/CConFileTable';
import MyScribeAI from 'components/practice/charts/ClinicalNote/MyScribeAI/MyScribeAI';
import AmbientListening from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/AmbientListening/AmbientListening';
import EnhanceNote from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/EnhanceNote/EnhanceNote';
import MyOwnTemplate from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyOwnTemplate/MyOwnTemplate';
import MyScribeTemplate from 'components/practice/charts/ClinicalNote/MyScribeAI/Templates/MyScribeTemplate/MyScribeTemplate';
import BillingInvoices from 'components/practice/charts/billing/Invoices/PatientInvoices';
import PatientClaims from 'components/practice/charts/billing/claims/PatientClaims';
import Ledger from 'components/practice/charts/billing/ledger/Ledger';
import Transactions from 'components/practice/charts/billing/transactions/Transactions';
import ContactsPage from 'components/practice/comms_center/Contacts/ContactsPage';
import NewArchivedFaxes from 'components/practice/comms_center/fax/ArchivedFaxes/NewArchivedFaxes';
import NewDraftFaxes from 'components/practice/comms_center/fax/Drafts/NewDraftFaxes';
import NewInboundFaxes from 'components/practice/comms_center/fax/InboundFaxes/NewInboundFaxes';
import NewOutboundFaxes from 'components/practice/comms_center/fax/OutboundFaxes/NewOutboundFaxes';
import Tags from 'components/practice/settings/Tags/Tags';
import FormBuilder from 'components/practice/settings/forms/FormBuilder/FormBuilder';
import FormPackets from 'components/practice/settings/forms/FormPackets/FormPackets';
import NewInvoice from 'components/public/NewInvoice';
import RWTPlan from 'components/public/RWTPlan/RWTPlan';

import PasswordReset from '../components/Auth';
import PasswordRequest from '../components/Auth/request';
import PasswordSet from '../components/Auth/set';
import Complete from '../components/InstantActions/components/Complete';
import CustomForms from '../components/InstantActions/components/CustomForms';
import InstantPacket from '../components/InstantActions/components/InstantPacket';
// import Roles from '../components/shared/roles';
import InstantActionPage from '../components/InstantActions/wrapper';
// Kiosks
import Kiosk from '../components/Kiosk/Kiosk';
import KioskActivate from '../components/Kiosk/views/Activate';
import KioskIdle from '../components/Kiosk/views/Idle';
import KioskPhone from '../components/Kiosk/views/Phone';
import Login from '../components/Login/Login';
// Other Routes
import MFADescription from '../components/MFADescription';
import NotFound from '../components/NotFound';
// Myportal
import PatientPortal from '../components/Patient';
import PatientDashboard from '../components/Patient/Dashboard/index';
import PatientInvoicesContainer from '../components/Patient/Invoices/Container';
import PatientMedicalRecords from '../components/Patient/MedicalRecords';
import PatientTreatmentPlans from '../components/Patient/TreatmentPlans/PatientTreatmentPlans';
import AccountContainer from '../components/Patient/account/indexContainer';
import PatientAppointments from '../components/Patient/appointments';
import CheckinComplete from '../components/Patient/checkin/CheckinComplete';
import CheckinSteps from '../components/Patient/checkin/CheckinSteps';
import PatientCheckinEditingSelector from '../components/Patient/checkin/EditingSelector';
import PatientCheckinLogin from '../components/Patient/checkin/Login';
import PastForms from '../components/Patient/checkin/PastForms/PastForms';
// Check-in steps
import PersonalID from '../components/Patient/checkin/PersonalId/PersonalId';
import PersonalInformationContainer from '../components/Patient/checkin/PersonalInfo/PersonalInfo';
import PreAuth2 from '../components/Patient/checkin/PreAuth';
import ReturnCheck from '../components/Patient/checkin/ReturnCheck';
import Review from '../components/Patient/checkin/Review/Review';
import CheckinForms from '../components/Patient/checkin/checkin_forms';
import HipaaFormContainer from '../components/Patient/checkin/hipaa_form_container';
import Insurance from '../components/Patient/checkin/insurance';
import Payment from '../components/Patient/checkin/payment';
import PayPrompt from '../components/Patient/checkin/payment/payPrompt';
import PreAuth from '../components/Patient/checkin/payment/preAuth';
import PatientDocumentsForPractice from '../components/Patient/documents/PracticeDocuments';
import PatientDocuments from '../components/Patient/documents/documents';
import PatientConversation from '../components/Patient/inbox/PatientConversation';
import PatientInbox from '../components/Patient/inbox/inbox';
import { default as PatientMedications } from '../components/Patient/medications';
import PatientPayments from '../components/Patient/payments';
import PatientQuestionnaires from '../components/Patient/questionnaires';
import PatientReferrals from '../components/Patient/referrals';
// Self scheduling
import ScheduleAppointment from '../components/Patient/self_scheduling';
import CardOnFile from '../components/Patient/self_scheduling/steps/CardOnFile';
import Confirmation from '../components/Patient/self_scheduling/steps/Confirmation';
import ContactInformation from '../components/Patient/self_scheduling/steps/ContactInformation';
import DateAndTimeStep from '../components/Patient/self_scheduling/steps/DateAndTimeStep';
import PracticeStep from '../components/Patient/self_scheduling/steps/PracticeStep';
import ServiceStep from '../components/Patient/self_scheduling/steps/ServiceStep';
import PatientSuperbills from '../components/Patient/superbills';
import Terms from '../components/Terms';
import Unsubscribe from '../components/Unsubscribe/Unsubscribe';
import Admin from '../components/admin';
import AccessLogs from '../components/admin/pages/AccessLogs/AccessLogs';
import AdminDashboard from '../components/admin/pages/Dashboard/Dashboard';
import Databank from '../components/admin/pages/Databank';
import CPTCodes from '../components/admin/pages/Databank/CPTCodes';
import GrowthData from '../components/admin/pages/Databank/GrowthData/GrowthData';
import Landmarks from '../components/admin/pages/Databank/Landmarks/Landmarks';
import Occupations from '../components/admin/pages/Databank/Occupations';
import BaseDatapointPage from '../components/admin/pages/Databank/baseDatapointPage';
import IconsPage from '../components/admin/pages/IconsPage/IconsPage';
import AdminPatients from '../components/admin/pages/Patients';
import AdminPatientsLogs from '../components/admin/pages/Patients/Logs';
import AdminPatientsTransactions from '../components/admin/pages/Patients/Transaction/Transactions';
import PatientProfile from '../components/admin/pages/Patients/profile';
import Practices from '../components/admin/pages/Practices';
import AdminSettings from '../components/admin/pages/Practices/AdminSettings';
import AdminBilling from '../components/admin/pages/Practices/Billing/AdminBilling';
// Admin Intelligence
import FacilityProvider from '../components/admin/pages/Practices/Facility/FacilityProvider';
import AdminPracticesOrganization from '../components/admin/pages/Practices/Organization/Organization';
import PracticeOverviewContainer from '../components/admin/pages/Practices/OverviewContainer';
import PatientImport from '../components/admin/pages/Practices/PatientImport';
import PracticePatients from '../components/admin/pages/Practices/Patients';
import TerminalManagement from '../components/admin/pages/Practices/Terminals/TerminalManagement';
import AdminPracticesTransactions from '../components/admin/pages/Practices/Transaction/Transactions';
import AdminForms from '../components/admin/pages/Practices/forms';
import EditFormBuilderContainer from '../components/admin/pages/Practices/forms/FormEditContainer';
import PreferredLocation from '../components/admin/pages/Practices/forms/PreferredLocation';
import NewFormBuilderContainer from '../components/admin/pages/Practices/forms/new_container';
import PracticeProfile from '../components/admin/pages/Practices/profile';
import AdminStaff from '../components/admin/pages/Practices/staff';
import Practitioners from '../components/admin/pages/Practitioners';
import AdminPractitionersLogs from '../components/admin/pages/Practitioners/Logs';
import AdminPractitionersPractices from '../components/admin/pages/Practitioners/Practices';
import PractitionersBasicInfo from '../components/admin/pages/Practitioners/PractitionersBasicInfo';
import PractitionersPatients from '../components/admin/pages/Practitioners/PractitionersPatients';
import PrescriptionManagement from '../components/admin/pages/Practitioners/PrescriptionManagement';
import AdminPractitionersSettings from '../components/admin/pages/Practitioners/Settings';
import NewPractitionerContainer from '../components/admin/pages/Practitioners/new_practitioner/indexContainer';
import PractitionerProfile from '../components/admin/pages/Practitioners/profile';
import PayerRequests from '../components/admin/pages/Requests/Payers/PayerRequests';
import Requests from '../components/admin/pages/Requests/Requests';
import AdminPasswordReset from '../components/admin/pages/ResetPassword';
import AdminPasswordRequest from '../components/admin/pages/ResetPassword/request';
import AdminPasswordSet from '../components/admin/pages/ResetPassword/set';
import SettingsContainer from '../components/admin/pages/Settings/indexContainer';
import AdminUserLogs from '../components/admin/pages/Users/Logs';
import AdminUserOverview from '../components/admin/pages/Users/Overview';
import AdminUserSessions from '../components/admin/pages/Users/Sessions';
import AdminUserUser from '../components/admin/pages/Users/User';
import PracticePortal from '../components/practice';
import BeyondBilling from '../components/practice/BeyondBilling';
import CMS1500 from '../components/practice/BeyondBilling/Claims/CMS1500/CMS1500';
import DynamicAR from '../components/practice/BeyondBilling/DynamicAR/DynamicAR';
import BeyondBillingOverview from '../components/practice/BeyondBilling/Overview/Overview';
import Payments from '../components/practice/BeyondBilling/Payments/Payments';
import Superbills from '../components/practice/BeyondBilling/Superbill/Superbills';
import ProfileContainer from '../components/practice/Profile/ProfileContainer';
import PatientAccount from '../components/practice/charts/Account/Account';
import PurchasedPackages from '../components/practice/charts/Account/tabs/PurchasedPackages/PurchasedPackages';
import Quotes from '../components/practice/charts/Account/tabs/Quotes/Quotes';
import Actions from '../components/practice/charts/Actions/Actions';
import Appointments from '../components/practice/charts/Appointments/Appointments';
import AppointmentsRow from '../components/practice/charts/Appointments/AppointmentsRow';
// new clinical note
import AllClinicalNotes from '../components/practice/charts/ClinicalNote/AllClinicalNotes/AllClinicalNote';
import ClinicalNote from '../components/practice/charts/ClinicalNote/ClinicalNote';
import CurrentNote from '../components/practice/charts/ClinicalNote/CurrentNote/CurrentNote';
import CommunicationLogs from '../components/practice/charts/Communications/CommunicationLogs';
import CommunicationMessages from '../components/practice/charts/Communications/CommunicationMessages';
import Communications from '../components/practice/charts/Communications/Communications';
import Demographics from '../components/practice/charts/Demographics/Demographics';
import Documents from '../components/practice/charts/Documents/Container';
import OverviewContainer from '../components/practice/charts/Overview/OverviewContainer';
import PatientChart from '../components/practice/charts/PatientChart/PatientChart';
import PatientCharts from '../components/practice/charts/PatientCharts/PatientCharts';
import PracticePatientSettings from '../components/practice/charts/PracticePatientSettings';
import SuperbillPage from '../components/practice/charts/SuperBillPage/SuperBillPage';
import Billing from '../components/practice/charts/billing/Billing';
import BillingSuperbill from '../components/practice/charts/billing/superbill/Superbill';
import CommsCenter from '../components/practice/comms_center/CommsCenter';
import CoverPages from '../components/practice/comms_center/fax/CoverPages/CoverPages';
import Fax from '../components/practice/comms_center/fax/Fax/Fax';
import Main from '../components/practice/comms_center/fax/Main/Main';
import Account from '../components/practice/settings/Account';
import Resources from '../components/practice/settings/Resources/Resources';
import Services from '../components/practice/settings/Services/Service';
import Settings from '../components/practice/settings/Settings';
import BillingSettings from '../components/practice/settings/billing';
import CalendarSettings from '../components/practice/settings/calendar';
import EPrescribeSettings from '../components/practice/settings/ePrescribe';
import Forms from '../components/practice/settings/forms/Forms';
import KioskSettings from '../components/practice/settings/kiosks/KioskSettings';
import Packages from '../components/practice/settings/packages/Packages';
import Payers from '../components/practice/settings/payers/Payers';
import Products from '../components/practice/settings/products/Products';
import Logout from '../components/shared/Logout/Logout';
import Roles from '../components/shared/Roles-new/Roles';
import NewContactContainer from '../components/shared/contact/contact_container';
// User Activation
import UserActivateError from '../components/user/Activate/Error';
import UserActivateInitiate from '../components/user/Activate/Initiate';
import UserActivateNewPhone from '../components/user/Activate/NewPhone';
import UserActivateStart from '../components/user/Activate/Start';
import UserActivateSuccess from '../components/user/Activate/Success';
import UserActivateVerifyPhone from '../components/user/Activate/VerifyPhone';
import UserActivateWrapper from '../components/user/Activate/Wrapper';

import PrivateRoutes from './PrivateRoutes';

const CHECKIN_ROUTES = {
  path: 'checkin',
  element: <CheckinSteps />,
  children: [
    { path: 'choices', element: <PatientCheckinEditingSelector /> },
    {
      path: 'personal-info',
      element: <PersonalInformationContainer />
    },
    {
      path: 'form/:id',
      element: <CheckinForms />
    },
    {
      path: 'hipaa',
      element: <HipaaFormContainer />
    },
    {
      path: 'personal-id',
      element: <PersonalID />
    },
    {
      path: 'insurance',
      element: <Insurance />
    },
    {
      path: 'login',
      element: <PatientCheckinLogin isCheckin={true} />
    },
    {
      path: 'payment',
      element: <Payment />
    },
    {
      path: 'pre-auth',
      element: <PreAuth />
    },
    {
      path: 'pay',
      element: <PayPrompt />
    },
    {
      path: 'pay-success',
      element: <PaySuccess />
    },
    {
      path: 'complete',
      element: <CheckinComplete />
    },
    {
      path: 'review',
      element: <Review />
    },
    {
      path: 'past',
      element: <PastForms />
    },
    {
      path: 'return-check',
      element: <ReturnCheck />
    },
    {
      path: '*',
      element: <NotFound portal="checkin" />
    }
  ]
};

const appRoutes = [
  {
    path: '',
    element: <PrivateRoutes type="empty" />,
    children: [
      {
        path: '/',
        element: <Login loginType="user" />
      }
    ]
  },
  {
    path: 'login',
    element: <Login loginType="user" />
  },
  {
    path: 'logout',
    element: <Logout />
  },
  {
    path: 'terms',
    element: <Terms />
  },
  {
    path: 'rwt-plan',
    element: <RWTPlan />
  },
  {
    path: 'unsubscribe',
    element: <Unsubscribe />
  },
  {
    path: 'mfa-description',
    element: <MFADescription />
  },
  {
    path: 'documentation',
    element: <Documentation />
  },
  {
    path: 'password/*',
    element: <PasswordReset />,
    children: [
      {
        path: 'request',
        element: <PasswordRequest />
      },
      {
        path: 'reset/:token',
        element: <PasswordSet />
      },
      {
        path: '*',
        element: <NotFound portal="password/request" />
      }
    ]
  },
  CHECKIN_ROUTES,
  {
    path: 'admin/login',
    element: <Login />
  },
  {
    path: 'admin/password/*',
    element: <AdminPasswordReset />,
    children: [
      {
        path: 'request',
        element: <AdminPasswordRequest />
      },
      {
        path: 'reset/:token',
        element: <AdminPasswordSet />
      },
      {
        path: '*',
        element: <NotFound portal="admin" />
      }
    ]
  },
  {
    path: 'ia/:token',
    element: <InstantActionPage />
  },
  {
    path: 'ia/auth/:token',
    element: <InstantAuth />
  },
  {
    path: 'invoice/:token',
    element: <NewInvoice />
  },
  {
    path: 'instant-packet',
    element: <InstantPacket />,
    children: [
      { path: 'personal-info', element: <PersonalInformationContainer /> },
      { path: 'form/:id', element: <CustomForms /> },
      { path: 'hipaa', element: <HipaaFormContainer /> },
      { path: 'personal-id', element: <PersonalID /> },
      { path: 'insurance', element: <Insurance /> },
      { path: 'pre-auth', element: <PreAuth2 /> },
      { path: 'complete', element: <Complete /> },
      { path: 'return-check', element: <ReturnCheck /> },
      { path: '*', element: <NotFound /> }
    ]
  },
  {
    path: 'contact',
    element: <NewContactContainer />
  },
  {
    path: 'terms',
    // element: <TermsAndConditions />
    element: <Terms />
  },
  {
    path: 'mfa-description',
    element: <MFADescription />
  },
  {
    path: 'privacy',
    // element: <PrivacyPolicy />
    element: <Terms />
  },
  {
    path: 'activate',
    element: <UserActivateWrapper />,
    children: [
      {
        path: 'initiate/:token',
        element: <UserActivateInitiate />
      },
      {
        path: 'start',
        element: <UserActivateStart />
      },
      {
        path: 'error',
        element: <UserActivateError />,
        children: [
          {
            path: ':kind',
            element: <UserActivateError />
          },
          {
            path: '*',
            element: <UserActivateError />
          }
        ]
      },
      {
        path: 'confirm-code',
        element: <UserActivateVerifyPhone />
      },
      {
        path: 'new-phone',
        element: <UserActivateNewPhone />
      },
      {
        path: 'success',
        element: <UserActivateSuccess />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: 'book',
    element: <ScheduleAppointment />,
    children: [
      {
        path: 'practice',
        element: <PracticeStep />
      },
      {
        path: 'service',
        element: <ServiceStep />
      },
      {
        path: 'date-and-time',
        element: <DateAndTimeStep />
      },
      {
        path: 'contact-info',
        element: <ContactInformation />
      },
      {
        path: 'card-on-file',
        element: <CardOnFile />
      },
      {
        path: 'confirmation',
        element: <Confirmation />
      },
      {
        path: '*',
        element: <NotFound portal="book" />
      }
    ]
  },
  {
    path: 'kiosk',
    element: <Kiosk />,
    children: [
      { path: '', element: <KioskIdle /> },
      { path: 'idle', element: <KioskIdle /> },
      { path: 'phone', element: <KioskPhone /> },
      { path: 'activate', element: <KioskActivate /> },
      CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound portal="kiosk" />
      }
    ]
  },

  {
    path: 'mobile',
    element: <Mobile />,
    children: [
      { path: 'idle', element: <KioskIdle /> },
      { path: 'phone', element: <KioskPhone /> },
      { path: 'activate', element: <KioskActivate /> },
      CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound portal="kiosk" />
      }
    ]
  },
  {
    path: 'select-practice',
    element: <PreferredLocation />
  },
  {
    path: '',
    element: <PrivateRoutes type="portal" />,
    children: [
      {
        path: 'portal',
        element: <PracticePortal />,
        children: [
          {
            path: '',
            element: <Dashboard />
          },
          {
            path: 'audit',
            children: [{ path: 'logs', element: <AuditLogs /> }]
          },
          {
            path: 'profile',
            element: <ProfileContainer />
          },
          {
            path: 'appointments',
            element: <Calendar />,
            children: [
              {
                path: ':id',
                element: <Calendar />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'charts',
            element: <PatientCharts />,
            children: [
              {
                path: ':id',
                element: <PatientChart />,
                children: [
                  {
                    path: '',
                    element: <OverviewContainer />
                  },
                  {
                    path: 'demographics',
                    element: <Demographics />
                  },
                  {
                    path: 'appointments/*',
                    element: <Appointments />,
                    children: [
                      {
                        path: '',
                        element: <AppointmentsRow type="upcoming" />
                      },
                      {
                        path: ':type',
                        element: <AppointmentsRow type="past" />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'eprescribe',
                    element: <Actions />
                  },
                  {
                    path: 'billing/*',
                    element: <Billing />,
                    children: [
                      {
                        path: 'ledger',
                        element: <Ledger />
                      },
                      {
                        path: 'claims',
                        element: <PatientClaims />,
                        children: [
                          {
                            path: ':claimId',
                            element: <CMS1500 />
                          }
                        ]
                      },
                      {
                        path: 'invoices',
                        element: <BillingInvoices />
                      },
                      {
                        path: '',
                        element: <Transactions />
                      },
                      {
                        path: 'superbill',
                        element: <BillingSuperbill />,
                        children: [
                          {
                            path: ':superbill',
                            element: <SuperbillPage />
                          },
                          {
                            path: 'claim/:claimId',
                            element: <CMS1500 />
                          }
                        ]
                      },

                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'documents',
                    element: <Documents />
                  },
                  {
                    path: 'communications',
                    element: <Communications />,
                    children: [
                      {
                        path: '',
                        element: <CommunicationMessages />
                      },
                      {
                        path: ':logs',
                        element: <CommunicationLogs />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'settings',
                    element: <PracticePatientSettings />
                  },
                  {
                    path: 'clinical-notes',
                    element: <AllClinicalNotes />,
                    children: [
                      {
                        path: ':appointmentId',
                        element: <ClinicalNote />,
                        children: [
                          {
                            element: <CurrentNote />,
                            children: [
                              {
                                path: ':type',
                                element: <React.Component />,
                                children: [
                                  {
                                    path: ':formName',
                                    element: <React.Component />
                                  }
                                ]
                              }
                            ]
                          },
                          {
                            path: 'myscribe-ai',
                            element: <MyScribeAI />,
                            children: [
                              {
                                path: 'map-my-own-template',
                                element: <MyOwnTemplate />
                              },
                              {
                                path: 'map-template-with-myscribe',
                                element: <MyScribeTemplate />
                              },
                              {
                                path: 'ambient-listening',
                                element: <AmbientListening />
                              },
                              {
                                path: 'enhance-current-note',
                                element: <EnhanceNote />
                              },
                              {
                                path: '*',
                                element: <NotFound />
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  },
                  {
                    path: 'account',
                    element: <PatientAccount />,
                    children: [
                      {
                        path: '',
                        element: <PurchasedPackages />
                      },
                      {
                        path: 'purchased-packages',
                        element: <PurchasedPackages />
                      },
                      {
                        path: 'quotes',
                        element: <Quotes />
                      },
                      {
                        path: 'settings',
                        element: <PracticePatientSettings />
                      },
                      {
                        path: 'cards',
                        element: <CConFile />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'beyond-billing',
            element: <BeyondBilling />,
            children: [
              {
                path: 'overview',
                element: <BeyondBillingOverview />
              },
              {
                path: 'income-i-queue',
                element: <IncomeIQueue />
              },
              {
                path: 'payments',
                element: <Payments />,
                children: [
                  {
                    path: 'era/:id',
                    element: <ViewEra />
                  }
                ]
              },
              {
                path: 'patient-ledger',
                element: <PatientLedger name="patient_ledger" />
              },
              {
                path: 'dynamic-ar',
                element: <DynamicAR />,
                children: [
                  {
                    path: ':type',
                    element: <DynamicAR />
                  }
                ]
              },
              {
                path: 'era-eob',
                children: [
                  {
                    path: 'era',
                    element: <Era />,
                    children: [
                      {
                        path: ':id',
                        element: <ViewEra />
                      }
                    ]
                  },
                  {
                    path: 'eob',
                    element: <Eob />,
                    children: [
                      {
                        path: ':id',
                        element: <ViewEra />
                      }
                    ]
                  }
                ]
              },
              {
                path: 'claims',
                element: <NewClaims />,
                children: [
                  {
                    path: ':id',
                    element: <CMS1500 />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'superbills',
                element: <Superbills />,
                children: [
                  {
                    path: 'claim/:claimId',
                    element: <CMS1500 />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'electronic-invoicing',
                element: <ElectronicInvoicing />,
                children: [
                  {
                    path: '',
                    element: <InternalInvoices />
                  },
                  {
                    path: 'external',
                    element: <ExternalInvoices />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'comms-center',
            element: <CommsCenter />,
            children: [
              {
                path: 'fax',
                element: <Fax />,
                children: [
                  { path: 'main', element: <Main /> },
                  { path: 'inbound', element: <NewInboundFaxes /> },
                  { path: 'outbound', element: <NewOutboundFaxes /> },
                  { path: 'drafts', element: <NewDraftFaxes /> },
                  { path: 'Archived', element: <NewArchivedFaxes /> },
                  { path: 'cover-pages', element: <CoverPages /> }
                ]
              },
              {
                path: 'contacts',
                element: <ContactsPage />
              }
            ]
          },
          {
            path: 'practice-i-queue',
            element: <PracticeIQueue />,
            children: [
              {
                path: 'overview',
                element: <Overview />
              },
              {
                path: 'tasks',
                element: <Tasks />,
                children: [
                  {
                    path: ':id',
                    element: <Tasks />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'clinical-notes',
                element: <ClinicalNotes />
              },
              {
                path: 'treatment-plans',
                element: <TreatmentPlanIQueue />
              },
              {
                path: 'notifications',
                element: <Notifications />
              },
              {
                path: 'appointments',
                element: <AppointmentsQueue />
              },
              {
                path: 'forms',
                element: <FormPacket />
              },
              {
                path: 'orders',
                element: <Orders />
              }
            ]
          },
          {
            path: 'settings',
            element: <Settings />,
            children: [
              {
                path: 'services',
                element: <Services />
              },
              {
                path: 'products',
                element: <Products />
              },
              {
                path: 'packages',
                element: <Packages />
              },
              {
                path: 'calendar',
                element: <CalendarSettings />
              },
              {
                path: 'forms',
                element: <Forms />,
                children: [
                  { path: '', element: <FormBuilder /> },
                  { path: 'form-packets', element: <FormPackets /> }
                ]
              },
              {
                path: 'payers',
                element: <Payers />
              },
              {
                path: 'account',
                element: <Account />
              },
              {
                path: 'staff',
                element: <Staff />
                // children: [
                //   {
                //     path: ':id',
                //     element: <EditStaffMember />
                //   }
                // ]
              },
              {
                path: 'roles',
                element: <Roles />
              },
              {
                path: 'eprescribe',
                element: <EPrescribeSettings />
              },
              {
                path: 'billing',
                element: <BillingSettings />
              },
              {
                path: 'kiosk',
                element: <KioskSettings />
              },
              {
                path: 'resources',
                element: <Resources />
              },
              {
                path: 'tags',
                element: <Tags />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: '*',
            element: <NotFound portal="portal" />
          }
        ]
      }
    ]
  },
  {
    path: 'icons',
    element: <IconsPage />
  },
  {
    path: '',
    element: <PrivateRoutes type="admin" />,
    children: [
      {
        path: 'admin',
        element: <Admin />,
        children: [
          {
            path: '',
            element: <AdminDashboard />
          },
          {
            path: 'practices',
            element: <Practices />,
            children: [
              {
                path: ':id',
                element: <PracticeProfile />,
                children: [
                  {
                    path: '',
                    element: <PracticeOverviewContainer />
                  },
                  {
                    path: 'staff',
                    element: <AdminStaff />
                  },
                  {
                    path: 'patients',
                    element: <PracticePatients />
                  },
                  {
                    path: 'forms',
                    element: <AdminForms />,
                    children: [
                      {
                        path: 'create',
                        element: <NewFormBuilderContainer />
                      },
                      {
                        path: 'edit/:formId',
                        element: <EditFormBuilderContainer />
                      },
                      {
                        path: '*',
                        element: <NotFound />
                      }
                    ]
                  },
                  {
                    path: 'settings',
                    element: <AdminSettings />
                  },
                  {
                    path: 'terminals',
                    element: <TerminalManagement />
                  },
                  {
                    path: 'roles',
                    element: <Roles isAdmin />
                  },
                  {
                    path: 'staff-management',
                    element: <Staff />
                  },
                  {
                    path: 'kiosk',
                    element: <KioskSettings />
                  },
                  {
                    path: 'patient-import',
                    element: <PatientImport />
                  },
                  {
                    path: 'billing',
                    element: <AdminBilling />
                  },
                  {
                    path: 'facility',
                    element: <FacilityProvider />
                  },
                  {
                    path: 'transactions',
                    // element: <AdminBilling />
                    element: <AdminPracticesTransactions />
                  },
                  {
                    path: 'organization',
                    // element: <AdminBilling />
                    element: <AdminPracticesOrganization />
                  },
                  {
                    path: 'form-builder',
                    element: <AdminFormBuilder />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'practitioners',
            element: <Practitioners />,
            children: [
              {
                path: ':id',
                element: <PractitionerProfile />,
                children: [
                  {
                    path: '',
                    element: <PractitionersBasicInfo />
                  },
                  {
                    path: 'patients',
                    element: <PractitionersPatients />
                  },
                  {
                    path: 'eprescribe',
                    element: <PrescriptionManagement />
                  },
                  {
                    path: 'practices',
                    element: <AdminPractitionersPractices />
                  },
                  {
                    path: 'settings',
                    element: <AdminPractitionersSettings />
                  },
                  {
                    path: 'logs',
                    element: <AdminPractitionersLogs />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: 'new',
                element: <NewPractitionerContainer />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'patients',
            element: <AdminPatients />,
            children: [
              {
                path: ':id',
                element: <PatientProfile />,
                children: [
                  {
                    path: '',
                    element: <AdminPatientBasicInfo />
                  },
                  {
                    path: 'logs',
                    element: <AdminPatientsLogs />
                  },
                  {
                    path: 'transactions',
                    element: <AdminPatientsTransactions />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'users',
            element: <AdminUsersTable />,
            children: [
              {
                path: ':id',
                element: <AdminUserUser />,
                children: [
                  {
                    path: '',
                    element: <AdminUserOverview />
                  },
                  {
                    path: 'logs',
                    element: <AdminUserLogs />
                  },
                  {
                    path: 'sessions',
                    element: <AdminUserSessions />
                  },
                  {
                    path: '*',
                    element: <NotFound />
                  }
                ]
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'reporting-center',
            element: <ReportingDashboard />
          },
          {
            path: 'databank',
            element: <Databank />,
            children: [
              {
                path: ':type',
                element: <BaseDatapointPage />
              },
              {
                path: 'cpt-codes',
                element: <CPTCodes />
              },
              {
                path: 'icd10-codes',
                element: <ICD10Codes />
              },
              {
                path: 'occupations',
                element: <Occupations />
              },
              {
                path: 'growth_data',
                element: <GrowthData />
              },
              {
                path: 'landmarks',
                element: <Landmarks />
              },
              {
                path: '*',
                element: <NotFound />
              }
            ]
          },
          {
            path: 'access-logs',
            element: <AccessLogs />
          },
          {
            path: 'requests',
            element: <Requests />,
            children: [
              {
                path: 'payers',
                element: <PayerRequests />
              }
            ]
          },
          {
            path: 'settings',
            element: <SettingsContainer />
          },
          {
            path: 'intelligence',
            element: <Intelligence />,
            children: [
              {
                path: 'ai_task',
                element: <AdminIntelligenceAiTask />
              },
              {
                path: 'model',
                element: <AdminIntelligenceModel />
              },
              {
                path: 'prompts',
                element: <AdminIntelligencePrompts />
              }
            ]
          },
          {
            path: 'roles',
            element: <Roles isAdmin forAdmin />
          },
          {
            path: '*',
            element: <NotFound portal="admin" />
          }
        ]
      }
    ]
  },
  {
    path: '',
    element: <PrivateRoutes type="" />,
    children: [
      {
        path: 'myportal',
        element: <PatientPortal />,
        children: [
          {
            path: '',
            element: <PatientDashboard />
          },
          {
            path: 'inbox',
            element: <PatientInbox />
          },
          {
            path: 'inbox/:myPortalPractice',
            element: <PatientConversation />
          },
          {
            path: 'medical-records',
            element: <PatientMedicalRecords />
          },
          {
            path: 'treatment-plans',
            element: <PatientTreatmentPlans />
          },
          {
            path: 'appointments',
            element: <PatientAppointments />
          },
          {
            path: 'payments',
            element: <PatientPayments />
          },
          {
            path: 'questionnaires',
            element: <PatientQuestionnaires />
          },
          {
            path: 'medications',
            element: <PatientMedications />
          },
          {
            path: 'referrals',
            element: <PatientReferrals />
          },
          {
            path: 'superbills',
            element: <PatientSuperbills />
          },
          {
            path: 'documents',
            element: <PatientDocuments />
          },
          {
            path: 'documents/:myPortalPractice',
            element: <PatientDocumentsForPractice />
          },
          {
            path: 'account',
            element: <AccountContainer />
          },
          {
            path: 'invoices',
            element: <PatientInvoicesContainer />
          },
          {
            path: '*',
            element: <NotFound portal="myportal" />
          }
        ]
      },
      // CHECKIN_ROUTES,
      {
        path: '*',
        element: <NotFound />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export const Routes = () => {
  const routes = useRoutes(appRoutes);

  return routes;
};
