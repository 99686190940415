import Tippy from '@tippyjs/react';
import { AlertContent } from 'components/shared/Alert/Alert';
import Icon from 'components/shared/Icon/Icon';
import Input from 'components/shared/Input/Input';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import Select from 'components/shared/Select/Select';
import { tagColorOptions } from 'components/shared/Tags/lib/tagColorOptions';
import Transition from 'components/shared/Transition/Transition';
import React, { useRef, useState } from 'react';
import { tagType } from '../lib/helper';
import Switch from 'components/shared/Switch/Switch';

const TagForm = ({ formik = {}, handleChange = () => {} }) => {
  const formRef = useRef();
  const [selectedColor, setSelectedColor] = useState(formik?.values?.color || null);

  const handleSelectColor = (color) => {
    setSelectedColor(color);
    formik.setFieldValue('color', color);
  };

  const handleKindSelect = (e) => formik.setFieldValue('kind', e?.value);

  return (
    <form ref={formRef}>
      <div className="z-50 flex flex-col gap-4">
        <div className="flex justify-between gap-1">
          <Input
            label="Name"
            placeholder="Enter tag name"
            name="name"
            id="name"
            value={formik?.values?.name}
            onChange={(event) => handleChange('name', event.target.value)}
            error={formik.errors?.name}
            className="!z-50 w-[342px] max-w-[342px] grow"
            required
          />
          <Select
            label="Type"
            placeholder="Select tag type"
            value={tagType.find((t) => t.value === formik?.values.kind)}
            name="kind"
            onChange={handleKindSelect}
            options={tagType}
            id="kind"
            error={formik.errors?.kind}
            menuPortalTarget={document.body}
            required
            width="342px"
            minWidth="342px"
          />
        </div>
        <div className="flex flex-col">
          <div>
            <span className="text-sm font-500 leading-[22px] text-neutral-800">Privacy</span>
            <span className="text-danger-500">*</span>
          </div>
          <div className="flex gap-3">
            <RadioButton
              wrapperClassName="max-w-max"
              label="Public"
              name="public"
              data-qa="tag-radio-public"
              id="public"
              value={formik.values.isPublic}
              isSelected={formik.values.isPublic}
              onChange={() => handleChange('isPublic', true)}
            />
            <RadioButton
              wrapperClassName="max-w-max"
              label="Private"
              name="private"
              id="private"
              data-qa="tag-radio-private"
              value={!formik.values.isPublic}
              isSelected={!formik.values.isPublic}
              onChange={() => handleChange('isPublic', false)}
            />
          </div>
        </div>
        <div className="flex flex-col gap-2 !pb-1">
          <div>
            <span className="text-sm font-500 leading-[22px] text-neutral-800">Color</span>
            <span className="text-danger-500">*</span>
          </div>
          <div className="flex max-w-[448px] flex-wrap gap-x-[14px] gap-y-3">
            {tagColorOptions.map((option, i) => (
              <div
                data-qa={`tag-color-${i}`}
                key={i}
                className="relative z-10 flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full"
                style={{
                  backgroundColor: option.color,
                  border: `1px solid ${option.border}`
                }}
                onClick={() => handleSelectColor(option.color)}>
                <Transition isOpen={selectedColor === option.color} type="scale">
                  <div>
                    <Icon icon="checkmark" color="white" size={18} className="mt-[3px]" />
                    <div
                      className="absolute left-[-3px] top-[-3px] -z-10 h-[calc(100%+6px)] w-[calc(100%+6px)] rounded-full opacity-30"
                      style={{
                        backgroundColor: option.color
                      }}
                    />
                  </div>
                </Transition>
              </div>
            ))}
          </div>
          {formik.errors.color && <p className="text-danger-500">{formik.errors.color}</p>}
        </div>
        <div className="flex flex-col gap-2 pb-1">
          <span className="text-sm font-500 leading-[22px] text-neutral-800">Importance</span>
          <div className="flex gap-2">
            <Switch
              onChange={(e) => handleChange('isImportant', e)}
              checked={formik.values.isImportant}
            />
            <span className="text-neutral 800 text-sm font-400">Mark as important</span>
            <Tippy
              content="Use this to communicate urgency of this tag to your team."
              className="tippy-dark"
              placement="right">
              <div className="flex items-center ">
                <Icon icon="new-info-circle" />
              </div>
            </Tippy>
          </div>
        </div>
        <div className="overflow-hidden rounded-md border border-solid !border-primary-100">
          <AlertContent
            className="!w-full !border-0"
            icon="new-info"
            iconShade={200}
            title={
              formik.values.isPublic === true
                ? 'Public tags can be viewed by anyone.'
                : 'Private tags are only viewable and editable by you.'
            }
          />
        </div>
      </div>
    </form>
  );
};

export default TagForm;
