import Icon from 'components/shared/Icon/Icon';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const MyScribeAILink = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clinicalNote } = useClinicalNoteContext();

  const pathnameParts = pathname.split('/');

  const { favoriteTemplateId } = useMyScribeAIContext();

  return (
    <>
      {pathname.includes('myscribe-ai') ? (
        <div
          className="flex cursor-pointer select-none items-center"
          onClick={() =>
            navigate(
              favoriteTemplateId
                ? `/portal/charts/${clinicalNote.user_id}/clinical-notes/${
                    clinicalNote.appointment_id
                  }`
                : -1
            )
          }>
          <Icon icon="chevron-left" color="primary" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#C1EFFF] bg-clip-text pl-1 text-lg font-medium leading-snug text-transparent">
            {pathnameParts[pathnameParts.length - 1] === 'myscribe-ai'
              ? 'Go Back Clinical Note'
              : 'Go Back Clinical Templates'}
          </div>
        </div>
      ) : (
        <Link
          className="flex cursor-pointer select-none items-center"
          to={`/portal/charts/${clinicalNote.user_id}/clinical-notes/${
            clinicalNote.appointment_id
          }/myscribe-ai${favoriteTemplateId ? `/${favoriteTemplateId}` : ''}`}>
          <Icon icon="stars-ai" />
          <div className="bg-gradient-to-r from-[#C1EFFF] to-[#13B9FF] bg-clip-text text-lg font-medium leading-snug text-transparent">
            Go To MyScribe AI
          </div>
        </Link>
      )}
    </>
  );
};

export default MyScribeAILink;
