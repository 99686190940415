import moment from 'moment';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { interimApi } from '../../../../../api/InterimApi';
import { getHours, ia, iaRa } from '../../../../../lib/helpers/utility';
import { findOpenAndCloseClinicHours } from '../../../../../lib/helpers/workingHours';
import { permissions } from '../../../../state';
import practiceState from '../../../practiceState';
import { capitalize } from 'lodash';
import { AlertContent, showAlert } from 'components/shared/Alert/Alert';
import { availableTimes, validationChecks } from './lib';
import Select from 'components/shared/Select/Select';
import Icon from 'components/shared/Icon/Icon';
import Switch from 'components/shared/Switch/Switch';
import Button from 'components/shared/Buttons/Button';

const AvailableTimeComponent = forwardRef(({ data, type }, ref) => {
  const [errors, setErrors] = useState({});
  const [hours, setHours] = useState([]);
  const [practice, setPractice] = useState(data);
  const [loading, setLoading] = useState(false);
  const userPermissions = useRecoilValue(permissions);
  const [permissionForSelectSwitch, setPermissionForSelectSwitch] = useState(
    !userPermissions?.member?.update ? true : false
  );
  const [permissionUpdateMethod, setPermissionUpdateMethod] = useState(
    userPermissions?.member?.update
  );
  const [currentPractice, setCurrentPractice] = useRecoilState(practiceState.currentPractice);
  const navigate = useNavigate();

  useEffect(() => {
    setHours(getHours());
    checkPermissions();
    if (!practice?.available_times) setPractice({ ...practice, available_times: availableTimes });
  }, []);

  useImperativeHandle(ref, () => ({
    handleSave: updateAvailableTimes
  }));

  const checkPermissions = () => {
    if (type != 'practitioner') {
      setPermissionForSelectSwitch(!userPermissions?.practice?.update);
      setPermissionUpdateMethod(userPermissions?.practice?.update);
    }
  };

  const formatDate = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      return moment(date).format('h:mm A');
    } else if (!isNaN(date)) {
      return moment.utc(date * 3600 * 1000).format('h:mm A');
    } else {
      return date;
    }
  };

  const updateTimes = (date, id, field, typeKey) => {
    validationErrors(`${id} - ${typeKey}`, '');

    const updatedTimes = practice.available_times.map((item) => {
      if (item.day === id) {
        if (field === 'both' || field === 'starts_at') {
          item[typeKey]['starts_at'] = date;
        }
        if (field === 'both' || field === 'ends_at') {
          item[typeKey]['ends_at'] = date;
        }
      }
      return item;
    });

    setPractice({
      ...practice,
      available_times: updatedTimes
    });
  };

  const upsertBreak = (date, key, id, field, type) => {
    validationErrors(`${key} - break`, '');
    const breakTime = {
      id: Math.floor(Math.random() * 99999),
      starts_at: 1,
      ends_at: 1
    };
    if (type == 'add') {
      setPractice({
        ...practice,
        available_times: practice.available_times.map((item) => {
          if (item.day === key) {
            if (ia(item.break)) {
              item.break = [...item.break, breakTime];
            } else {
              item.break = [breakTime];
            }
          }
          return item;
        })
      });
    } else if (type == 'update') {
      setPractice({
        ...practice,
        available_times: practice.available_times.map((item) => {
          if (item.day === key) {
            if (ia(item.break)) {
              item.break = item.break.map((item) => {
                if (field == 'starts_at' && item.id == id) {
                  item.starts_at = date;
                } else if (field == 'ends_at' && item.id == id) {
                  item.ends_at = date;
                }
                return item;
              });
            }
          }
          return item;
        })
      });
    }
  };

  const removeBreaks = (key, id) => {
    setPractice((prev) => ({
      ...practice,
      available_times: prev.available_times.map((item) => {
        if (item.day === key) {
          const filteredBreaks = iaRa(item.break).filter((item) => item.id != id);
          const newItem = { ...item, break: filteredBreaks };
          if (filteredBreaks.length == 0) {
            delete newItem.break;
          }
          return newItem;
        }
        return item;
      })
    }));
  };

  const removeValues = (key, typeKey) => {
    setPractice({
      ...practice,
      available_times: practice.available_times.map((item) => {
        if (item.day === key) {
          item[typeKey].starts_at = null;
          item[typeKey].ends_at = null;
          delete item.break;
        }
        return item;
      })
    });
  };

  async function updateAvailableTimes() {
    try {
      setLoading(true);
      let res = null;

      if (availableTimeValidation()) {
        if (type == 'practitioner') {
          res = await interimApi(
            'api/practice/member/update',
            {
              available_times: JSON.stringify(practice.available_times),
              userId: practice.id,
              practiceId: userPermissions?.practice_id
            },
            navigate
          );
        } else {
          res = await interimApi(
            '/api/practice/update',
            {
              fields: { available_times: JSON.stringify(practice.available_times) }
            },
            navigate
          );
        }
      }
      if (res?.data?.updatedItems > 0 || res?.data?.code == 0) {
        const workingHours = findOpenAndCloseClinicHours(practice.available_times);
        setCurrentPractice({ ...currentPractice, working_hours: workingHours });
        showAlert({
          color: 'success',
          message: 'Practice available times updated successfully.'
        });
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  const validationErrors = (key, message) => {
    setErrors((prev) => {
      return { ...prev, [key]: message };
    });
  };

  const availableTimeValidation = () => {
    let hasErrors = [];
    practice.available_times.map((item) => {
      const hasError = validationChecks(item, validationErrors);
      hasError && hasErrors.push(hasError);
    });
    if (hasErrors.length > 0) {
      showAlert({
        color: 'danger',
        title: 'Validation Error',
        message: 'Please scroll down to see the errors'
      });
    }
    return !ia(hasErrors);
  };

  const openClose = (key, state, typeKey) => {
    if (state) {
      updateTimes(formatDate(9), key, 'both', typeKey);
    } else {
      removeValues(key, typeKey);
      validationErrors(`${key} - ${typeKey}`, '');
    }
  };

  return (
    <>
      {type != 'practitioner' && (
        <div className="flex w-full justify-center !py-2">
          <p className="!pb-2 text-base font-500">Practice available times</p>
        </div>
      )}
      <table>
        <tbody>
          {ia(practice?.available_times) &&
            practice.available_times.map((day, idx) => {
              let entries = { ...day };
              let key = entries.day;
              delete entries.day;
              return (
                <div key={idx} className="!mb-2 rounded-lg bg-primary-50">
                  <div className="w-full py-2 text-center text-lg font-600 text-primary-900">
                    {key}
                  </div>
                  {Object.entries(entries)?.map(([typeKey, typeValue], idx) => (
                    <>
                      {!ia(typeValue) ? (
                        <tr key={idx} className="h-[50px]">
                          <td className="w-[200px] font-500 text-primary-700">
                            {typeKey === 'location' ? 'Office' : capitalize(typeKey)}
                          </td>
                          <td className="w-[130px]">
                            <div className="flex !gap-3">
                              <Switch
                                disabled={permissionForSelectSwitch}
                                checked={typeValue.starts_at != null && typeValue.ends_at != null}
                                onChange={(state) => openClose(key, state, typeKey)}
                              />

                              <p>
                                {typeValue.starts_at != null && typeValue.ends_at != null
                                  ? 'Open'
                                  : 'Closed'}
                              </p>
                            </div>
                          </td>
                          {typeValue.starts_at != null && typeValue.ends_at != null && (
                            <>
                              <td>
                                <Select
                                  options={hours}
                                  disabled={permissionForSelectSwitch}
                                  isClearable={false}
                                  value={
                                    typeValue.starts_at && {
                                      label: formatDate(typeValue.starts_at)
                                    }
                                  }
                                  onChange={({ value }) =>
                                    updateTimes(formatDate(value), key, 'starts_at', typeKey)
                                  }
                                  width={150}
                                />
                              </td>
                              <td>
                                <p>TO</p>
                              </td>
                              <td>
                                <Select
                                  options={hours}
                                  disabled={permissionForSelectSwitch}
                                  isClearable={false}
                                  value={
                                    typeValue.ends_at && { label: formatDate(typeValue.ends_at) }
                                  }
                                  onChange={({ value }) => {
                                    updateTimes(formatDate(value), key, 'ends_at', typeKey);
                                  }}
                                  width={150}
                                />
                              </td>
                              <td
                                onClick={() =>
                                  permissionUpdateMethod &&
                                  upsertBreak(null, key, null, null, 'add')
                                }>
                                <Icon
                                  icon="new-add-circle"
                                  onClick={() =>
                                    permissionUpdateMethod &&
                                    upsertBreak(null, key, null, null, 'add')
                                  }
                                />
                              </td>
                            </>
                          )}
                        </tr>
                      ) : (
                        typeValue?.map((row, idx) => (
                          <tr key={idx}>
                            <td></td>
                            <td>
                              <p>Break</p>
                            </td>
                            <td>
                              <Select
                                isClearable={false}
                                options={hours}
                                isDisabled={permissionForSelectSwitch}
                                value={row.starts_at && { label: formatDate(row.starts_at) }}
                                onChange={({ value }) => {
                                  upsertBreak(
                                    formatDate(value),
                                    key,
                                    row.id,
                                    'starts_at',
                                    'update'
                                  );
                                }}
                                width={150}
                              />
                            </td>
                            <td>
                              <p>TO</p>
                            </td>
                            <td>
                              <Select
                                isClearable={false}
                                options={hours}
                                isDisabled={permissionForSelectSwitch}
                                value={row.ends_at && { label: formatDate(row.ends_at) }}
                                onChange={({ value }) => {
                                  upsertBreak(formatDate(value), key, row.id, 'ends_at', 'update');
                                }}
                                width={150}
                              />
                            </td>
                            <td onClick={() => permissionUpdateMethod && removeBreaks(key, row.id)}>
                              <Icon
                                icon="trash"
                                onClick={() => permissionUpdateMethod && removeBreaks(key, row.id)}
                              />
                            </td>
                          </tr>
                        ))
                      )}
                    </>
                  ))}
                </div>
              );
            })}
        </tbody>
      </table>
      {Object.keys(errors).length > 0 &&
        iaRa(Object.keys(errors))?.map(
          (key, idx) =>
            errors[key] && (
              <div className="!py-2" key={idx}>
                <AlertContent className="w-full" key={idx} color="danger" message={errors[key]} />
              </div>
            )
        )}
      {type != 'practitioner' && (
        <div className="flex w-full justify-end">
          <Button
            success
            disabled={permissionForSelectSwitch || loading}
            onClick={() => updateAvailableTimes()}
            data-qa="save-btn-click"
            loading={loading}
            text="Save"
          />
        </div>
      )}
    </>
  );
});

export default AvailableTimeComponent;
