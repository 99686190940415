import React from 'react';
import { useGetPermissions } from 'lib/hooks/queries/permissions/usePermissions';
import { useRecoilState } from 'recoil';
import { pm } from '../../../lib/helpers/utility';
import state from '../../state';
import { withErrorBoundary } from '../Error/Boundary';
import Icon from '../Icon/Icon';

const PermissionsAllowed = ({
  requiredPermissions = '',
  isAny = false,
  showMessage = false,
  showIllustration = false,
  customMessage = 'Necessary permissions not present',
  messageClassName = '',
  children
}) => {
  const [permissions, setPermissions] = useRecoilState(state.permissions);
  useGetPermissions({
    options: {
      enabled: !permissions,
      onSuccess: (data) => {
        const newPermissions = data?.permissions;
        setPermissions({ ...newPermissions, loaded: true });
      }
    }
  });

  if (!requiredPermissions) {
    return children;
  }

  if (isAny && pm(permissions, requiredPermissions, 'or')) {
    return children;
  }

  if (pm(permissions, requiredPermissions)) {
    return children;
  }

  if (showMessage) {
    return (
      <>
        <div className="flex h-full flex-col items-center justify-center text-center">
          {showIllustration && <Icon icon="no-permissions-illustration" />}
          <p className={messageClassName}>{customMessage}</p>
        </div>
      </>
    );
  }

  return null;
};

export default withErrorBoundary(PermissionsAllowed);
