import React, { useState } from 'react';

import { ClinicalNoteContextProvider } from '../../../lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { formatDate, ia, io } from '../../../lib/helpers/utility';
import { useTreatmentPlan } from '../../../lib/hooks/queries/treatmentPlan/useTreatmentPlan';
import SignModal from '../../practice/charts/ClinicalNote/shared/Sign/SignModal';
import Button from '../../shared/Buttons/Button';

import PatientTreatmentPlanModal from './PatientTreatmentModal';

const TreatmentPlansTable = ({ children }) => {
  return (
    <>
      <table className="primary-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Practice</th>
            <th>Providers</th>
            <th>Date Of Creation</th>
            <th>Last Date Of Change </th>
            <th />
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </>
  );
};

const PatientTreatmentPlans = ({}) => {
  const [selectedTreatmentPlan, setSelectedTreatmentPlan] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const { data: treatmentPlans } = useTreatmentPlan(
    { asPatient: true, patientPortal: true, withRelations: { practice: true } },
    { refetchOnMount: true, select: (response) => response.data }
  );

  return (
    <div className="!pt-32 !pr-16 !pb-24 !pl-16">
      <TreatmentPlansTable>
        {ia(treatmentPlans) &&
          treatmentPlans.map((row, index) => {
            const providers = row.treatment_team.map((row) => row.provider.label).join(', ');
            return (
              <tr key={`treatment-plan-${index}`}>
                <td>{row.id}</td>
                <td>{row.practice?.name}</td>
                <td>{providers}</td>
                <td>{formatDate(row.created_at)}</td>
                <td>{formatDate(row.updated_at)}</td>
                <td>
                  <Button text="Show" onClick={() => setSelectedTreatmentPlan(row)} />
                </td>
              </tr>
            );
          })}
      </TreatmentPlansTable>

      <ClinicalNoteContextProvider>
        {selectedTreatmentPlan?.id && (
          <SignModal
            setShowConfirmationModal={setShowConfirmationModal}
            showConfirmationModal={showConfirmationModal}
            documentId={selectedTreatmentPlan.id}
            documentTypes={['treatment_plan']}
            asPatient={true}
          />
        )}
        <PatientTreatmentPlanModal
          isOpen={io(selectedTreatmentPlan)}
          handleClose={() => setSelectedTreatmentPlan(null)}
          patientSideTreatmentPlan={selectedTreatmentPlan}
          setShowConfirmationModal={setShowConfirmationModal}
        />
      </ClinicalNoteContextProvider>
    </div>
  );
};

export default PatientTreatmentPlans;
