import React from 'react';
import { useMemo } from 'react';
import { useWindowSize } from '../../hooks/useWindowSize';
import { UIContext } from './UIContext';

export const UIContextProvider = ({ children }) => {
  const device = useWindowSize();
  const context = useMemo(() => ({ device }), [device]);

  return <UIContext.Provider value={context}>{children}</UIContext.Provider>;
};
