import React from 'react';
import { useFormikContext } from 'formik';
import RadioButton from 'components/shared/RadioButton/RadioButton';
import Input from 'components/shared/Input/Input';
import { ia, reShapePractitioners } from 'lib/helpers/utility';
import { appointmentLocations } from 'constants';
import useCopy from 'lib/hooks/useCopy';

const Location = ({ currentPractice, practitioners }) => {
  const { values, setFieldValue, handleChange } = useFormikContext();
  const { copySuccess, copyToClipboard } = useCopy();

  const handleChangeVirtualLink = () => {
    const patientLink = values.patient?.practicePatientLink?.virtual_link;
    const reshapedPractitioners = reShapePractitioners(practitioners?.practitioners);

    const practitionersLink = reshapedPractitioners.find(
      (practitioner) => practitioner?.value === values?.practitioner_id
    )?.virtual_link;

    const practice_virtual_link = currentPractice?.display_settings?.appointment?.virtual_link;
    setFieldValue('virtual_link', patientLink || practitionersLink || practice_virtual_link);
  };

  return (
    <div className="grid grow gap-4">
      <div>
        <label className="text-sm font-500">Location</label>
        <div className="mt-3 flex gap-4">
          {ia(appointmentLocations) &&
            appointmentLocations.map(({ value, label }, i) => {
              return (
                <RadioButton
                  className="w-auto !pl-8 !pr-0"
                  wrapperClassName="!w-auto"
                  key={i}
                  id={value}
                  value={value}
                  label={label}
                  isSelected={value === values.location}
                  onChange={() => {
                    setFieldValue('location', value);
                    if (value === 'virtual') {
                      handleChangeVirtualLink();
                    }
                  }}
                />
              );
            })}
        </div>
      </div>
      {values.location == 'virtual' && (
        <Input
          rightIcon="new-copy-v2"
          rightText={copySuccess}
          rightIconClick={() => copyToClipboard(values.virtual_link)}
          rightTextClassName="text-xs"
          className="grow"
          label="Meeting link"
          value={values.virtual_link}
          onChange={(e) => {
            handleChange(e);
          }}
          name="virtual_link"
          placeholder="Enter meeting link"
        />
      )}
    </div>
  );
};

export default Location;
