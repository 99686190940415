import { showAlert } from 'components/shared/Alert/Alert';
import Button from 'components/shared/Buttons/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Icon from 'components/shared/Icon/Icon';
import Modal from 'components/shared/Modal/Modal';
import React, { useEffect, useState } from 'react';
import { ReactFormGenerator } from 'react-form-builder2';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { requestApi } from '/api/Api';
import { createInstantPacket } from '/api/InstantPacket';
import { ia, io } from '/lib/helpers/utility';
import { useAllCustomForms } from '/lib/hooks/queries/customForms/useAllCustomForms';
import { useCustomForm } from '/lib/hooks/queries/customForms/useCustomForm';

export default function KioskSendForm({ showForm, setShowForm }) {
  const [selectedFormIds, setSelectedFormIds] = useState([]);
  const [selectedForm, setSelectedForm] = useState(false);
  const [previewFormId, setPreviewFormId] = useState(null);
  const { data, isLoading } = useAllCustomForms();
  const navigate = useNavigate();

  const id = showForm?.id;

  const otherForms = [
    { name: 'HIPAA Agreement', id: 'hipaa', hardcoded: true },
    { name: 'Pre-Authorized Healthcare Form (CC on file)', id: 'pre-auth', hardcoded: true },
    { name: 'Insurance', id: 'insurance', hardcoded: true },
    { name: 'Personal ID', id: 'personal-id', hardcoded: true },
    { name: 'Demographics', id: 'personal-info', hardcoded: true }
  ];

  const { data: previewFormData } = useCustomForm({
    params: { form_id: previewFormId },
    dependencies: [previewFormId]
  });

  useEffect(() => {
    if (
      previewFormId &&
      previewFormData &&
      !ia(previewFormData?.forms) &&
      io(previewFormData?.forms, 1)
    ) {
      setSelectedForm(previewFormData?.forms);
    }
  }, [previewFormData, previewFormId]);

  const isFormSelected = (id) => selectedFormIds.includes(id);

  const handleClick = (formId) => {
    if (isFormSelected(formId)) {
      setSelectedFormIds((prevIds) => prevIds.filter((id) => id !== formId));
    } else {
      setSelectedFormIds((prevIds) => [...prevIds, formId]);
    }
  };

  const previewForm = (id) => {
    if (isNaN(parseInt(id))) {
      return;
    }
    setPreviewFormId(id);
  };

  const closeModal = () => {
    setShowForm(null);
    setSelectedFormIds([]);
    setSelectedForm(null);
  };

  const handleCreate = async () => {
    const params = { formIds: selectedFormIds, patientId: id, kiosk: true };
    const { token } = await createInstantPacket(navigate, params);
    if (token) {
      // showAlert({ title: 'Instant packet created successfully!' });

      await requestApi({
        url: '/api/kiosk/command',
        params: {
          url: token,
          command: 'url-change',
          page: 'form',
          kioskId: showForm.kiosk?.id,
          patientId: showForm?.patientId
        },
        navigate
      });

      showAlert({ title: `Sent to ${showForm?.kiosk?.name}`, color: 'success' });
      closeModal();
    }
  };

  return (
    <Modal
      bodyClassName="bg-primary-10"
      slideFromRight
      boxShadow="none"
      isOpen={!!showForm}
      title={
        <div className="flex flex-col">
          <div>Send Patient Forms</div>
          <div className="sticky top-0 flex items-center space-x-4 bg-white">
            {selectedForm && (
              <Icon
                icon="back-large"
                onClick={() => {
                  setPreviewFormId(null);
                  setSelectedForm(null);
                }}
              />
            )}
          </div>
        </div>
      }
      className="w-[720px]"
      footer={
        <div className="flex w-full items-center justify-between">
          <Button text="Cancel" outlined color="neutral" onClick={() => closeModal()} />
          <Button
            text="Send to Kiosk"
            disabled={selectedFormIds.length === 0}
            onClick={() => {
              if (selectedFormIds.length > 0) {
                handleCreate();
              }
            }}
          />
        </div>
      }
      handleClose={() => closeModal()}>
      {isLoading ? (
        <Skeleton />
      ) : (
        <>
          {selectedForm ? (
            <div className="rounded-lg bg-primary-10 bg-white bg-opacity-100 p-[0.5rem] opacity-100">
              <ReactFormGenerator data={selectedForm?.json?.fields} read_only />
            </div>
          ) : (
            <table className="primary-table responsive-table selectable-children rounded-t-lg !bg-primary-10 bg-primary-10 !shadow-none">
              <tbody className="overflow-y-auto">
                {ia(data) &&
                  ia([...otherForms, ...data]) &&
                  [...otherForms, ...data]?.map((form, idx) => (
                    <tr
                      key={idx}
                      className="cursor-pointer hover:!bg-primary-50"
                      onClick={() => handleClick(form.id)}>
                      <td className="flex h-full w-full items-center justify-between gap-x-2">
                        <div className="flex items-center gap-x-2">
                          <Checkbox
                            radio
                            className="scale-75"
                            isChecked={isFormSelected(form.id)}
                            data-qa={`load-form-checkbox-${idx}`}
                          />
                          <span className="font-500 first-letter:capitalize">{form.name}</span>
                        </div>
                        {!form.hardcoded && (
                          <Icon
                            icon="new-document-preview"
                            onClick={(e) => {
                              e.stopPropagation();
                              previewForm(form.id);
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </>
      )}
    </Modal>
  );
}
