import React, { useEffect, useState } from 'react';
import OrdersModal from 'components/practice/charts/ClinicalNote/Orders/OrdersModal';
import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { useReferralOrder } from 'lib/hooks/queries/referralOrder/useReferralOrder';
import { ia } from 'lib/helpers/utility';
import { useParams } from 'react-router-dom';
import { useNonERXPrescription } from 'lib/hooks/queries/nonERXPrescription/useNonERXPrescription';
import { useLabOrder } from 'lib/hooks/queries/labOrder/useLabOrder';
import LabOrder from 'components/practice/charts/ClinicalNote/Orders/shared/LabOrder';
import ReferralOrder from 'components/practice/charts/ClinicalNote/Orders/shared/ReferallData';
import NonERXPrescription from 'components/practice/charts/ClinicalNote/Orders/shared/NonERXPrescription';
import { ClinicalNoteContextProvider } from 'lib/context/ClinicalNoteContext/ClinicalNoteContextProvider';
import { Chart3dContextProvider } from 'lib/context/Chart3dContext/Chart3dContextProvider';

const OrdersBody = ({ setOrders }) => {
  const { clinicalNote } = useClinicalNoteContext() || {};
  const [navigateToParams, setNavigateToParams] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { id: patientId } = useParams();
  const { data: referralsData, isFetching } = useReferralOrder({
    dependencies: [patientId, clinicalNote?.id],
    params: {
      patientId: Number(patientId)
    },
    options: {
      staleTime: 10000,
      select: ({ data }) => data?.referrals
    }
  });

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const { data: nonERXPrescription, isFetching: nonERXPrescriptionFetching } =
    useNonERXPrescription({
      dependencies: [patientId, clinicalNote?.id],
      params: {
        patientIds: [patientId],
        withRelations: { provider: true }
      },
      options: {
        staleTime: 10000,
        select: ({ data }) => data?.nonERXPrescription
      }
    });

  const { data: labOrder, isFetching: labOrderFetching } = useLabOrder({
    dependencies: [patientId, clinicalNote?.id],
    params: {
      patientIds: [patientId],
      withRelations: { provider: true, tests: true }
    },
    options: {
      staleTime: 10000,
      select: ({ data }) => data?.labOrder
    }
  });
  useEffect(() => {
    if (!isFetching && !nonERXPrescriptionFetching && !labOrderFetching) {
      const allOrders = [
        ...(referralsData || []),
        ...(nonERXPrescription || []),
        ...(labOrder || [])
      ];

      setOrders(allOrders);
    }
  }, [
    referralsData,
    patientId,
    nonERXPrescription,
    labOrder,
    isFetching,
    nonERXPrescriptionFetching,
    labOrderFetching,
    setOrders
  ]);
  return (
    <div className="mb-3">
      <ReferralOrder
        referralsData={referralsData}
        isFetching={isFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        ableToDelete={false}
      />

      <NonERXPrescription
        nonERXPrescription={nonERXPrescription}
        nonERXPrescriptionFetching={nonERXPrescriptionFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        ableToDelete={false}
      />

      <LabOrder
        labOrder={labOrder}
        labOrderFetching={labOrderFetching}
        setNavigateToParams={setNavigateToParams}
        handleOpen={handleOpen}
        ableToDelete={false}
      />

      {!ia(nonERXPrescription) && !ia(referralsData) && !ia(labOrder) && (
        <div className="p-[20px] py-3 text-center">
          <p className="font-medium text-neutral-500">No Orders</p>
        </div>
      )}

      {!!modalOpen && (
        <ClinicalNoteContextProvider>
          <Chart3dContextProvider>
            <OrdersModal
              modalOpen={!!modalOpen}
              handleClose={handleClose}
              navigateToParams={navigateToParams}
              setNavigateToParams={setNavigateToParams}
            />
          </Chart3dContextProvider>
        </ClinicalNoteContextProvider>
      )}
    </div>
  );
};

export default OrdersBody;
