import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { requestApi } from 'api/Api';
import { ia } from 'lib/helpers/utility';
import Button from 'components/shared/Buttons/Button';
import { withErrorBoundary } from 'components/shared/Error/Boundary';
import AdminUserEntry from './Entry';
import AdminUserTable from './Table';

const AdminUser = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getAdminUsers();
  }, []);

  const getAdminUsers = async () => {
    setLoading(true);
    const res = await requestApi({
      url: '/api/admin/users/list',
      navigate,
      params: { limit: 30, offset: ia(users) ? users?.length : 0 }
    });

    setUsers((ps) =>
      _.uniqBy([...ps, ...res?.users] || [], (e) => e?.id).sort((a, b) => a.id - b.id)
    );
    setLoading(false);
  };

  if (id) {
    return <Outlet />;
  }

  return (
    <div className="rounded-xl bg-white p-[1rem]">
      <div>Admin Users</div>
      <AdminUserTable>
        {ia(users) && users.map((v, i) => <AdminUserEntry user={v} index={i} />)}
        <tr>
          <td colSpan={6}>
            <Button color="primary" loading={loading} onClick={getAdminUsers} text="Load More" />
          </td>
        </tr>
      </AdminUserTable>
    </div>
  );
};

export default withErrorBoundary(AdminUser);
