import React, { useState } from 'react';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { upsertUserBoard } from '../../../api/Boards';
import { useNavigate } from 'react-router-dom';
import Button from '../Buttons/Button';
import Icon from '../Icon/Icon';
import cs from 'classnames';
import { useBoardContext } from 'lib/context/BoardContext/BoardContext';
import Popover from '../Popovers/Popover/Popover';

export default function StickyButton({ className, buttonClassName, iconColor = 'primary' }) {
  const {
    initialize,
    addItem,
    setChangedSizes,
    breakpoint,
    setDeleteWidgetModal,
    data,
    editMode,
    setEditMode,
    layout,
    setLayout,
    setSelectedFeature,
    board,
    defaultBoard
  } = useBoardContext();

  const [prevLayout, setPrevLayout] = useState();
  const [hoverCustomize, setHoverCustomize] = useState();
  const navigate = useNavigate();

  const applyEdit = async () => {
    const res = await upsertUserBoard(navigate, { board, layout });
    setChangedSizes(
      layout[breakpoint]?.filter((item) => {
        const prevItem = prevLayout[breakpoint].find((prev) => prev.i === item.i);
        return item.w != prevItem?.w || item.h != prevItem?.h;
      })
    );
    if (res) {
      setEditMode(false);
    }
  };

  const enterEdit = () => {
    setEditMode(true);
    setPrevLayout(layout);
    setHoverCustomize(false);
  };

  const cancelEdit = () => {
    setEditMode(false);
    setLayout(prevLayout);
  };

  const resetEdit = () => {
    initialize(data?.default);
  };

  const options =
    defaultBoard?.map((feature) => {
      const item = layout.lg.find((layoutItem) => layoutItem.widget === feature.widget);
      const canAdd = feature.multiple || !item;
      return {
        label: (
          <div
            className={cs('flex !pr-5  font-400 text-primary-900', canAdd && 'cursor-grab')}
            draggable={canAdd}
            // unselectable="on"
            onDragStart={(e) => {
              if (canAdd) {
                e.dataTransfer.setData('text/plain', '');
                setSelectedFeature(feature.name);
              }
            }}>
            {canAdd && <Icon icon="new-sort" className="mr-1 cursor-grab" color="primary"></Icon>}
            <p>{feature.name}</p>
          </div>
        ),
        component: (
          <div
            onClick={() => (canAdd ? addItem(feature.name) : setDeleteWidgetModal(item.i))}
            className={`cursor-pointer rounded-full bg-gray-50 p-1 transition-all ${
              !canAdd && 'rotate-45'
            }`}>
            <Icon
              icon="new-plus"
              className="cursor-pointer text-sm"
              data-qa={`add-delete-widget-${feature.name.toLowerCase()}`}
            />
          </div>
        )
      };
    }) ?? [];

  return (
    <div
      data-dd-privacy="allow"
      className={cs(
        'pointer-events-none sticky !top-16 z-20 mr-4 flex h-0 justify-end',
        className
      )}>
      {!editMode ? (
        <div
          data-dd-privacy="allow"
          onPointerEnter={() => setHoverCustomize(true)}
          onPointerLeave={() => setHoverCustomize(false)}
          onClick={enterEdit}
          className={cs(
            'pointer-events-auto flex min-h-[30px] min-w-[30px] cursor-pointer items-center justify-center rounded-md border border-primary-100 bg-white shadow-[0px_2px_16px_0px_#004F6B33] hover:!bg-primary-50',
            buttonClassName,
            hoverCustomize && '!px-2'
          )}>
          <Icon
            data-qa="edit-dashboard"
            icon="edit-dashboard"
            className="cursor-pointer"
            size={18}
            color={hoverCustomize ? 'primary' : iconColor}
          />
          <p
            className={cs(
              '  text-sm text-primary-900 opacity-100 transition-all',
              !hoverCustomize ? '!m-0 !w-0 !text-[0px] opacity-0' : '!ml-2'
            )}>
            {hoverCustomize ? 'Customize View' : ''}
          </p>
        </div>
      ) : (
        <div
          className="pointer-events-auto flex h-[46px] cursor-pointer items-center gap-2 rounded-full border !border-primary-100 !bg-white !px-[10px] !py-[6px] shadow-[0px_2px_16px_0px_#004F6B33]"
          data-dd-privacy="allow">
          <span
            data-qa="cancel"
            onClick={cancelEdit}
            className="flex cursor-pointer gap-2 !pl-2 text-sm font-medium text-primary-500">
            Cancel
          </span>
          <Button
            data-qa="reset-to-default"
            type="secondary"
            className="!bg-white !px-2 !py-[5px] !font-medium"
            text="Reset to default"
            icon="new-diskette"
            onClick={resetEdit}
          />

          <Popover
            options={options}
            isDropdown
            text="Add Widgets"
            position="left"
            icon="add-widget"
            isFixed
            buttonTheme="white"
            buttonClassName="!rounded-full border border-solid border-primary-200 !px-[10px] !py-[5px] !min-h-[32px]"
            panelClassName="z-[9999] max-h-[50vh] overflow-y-scroll rounded-lg !hover:bg-none"
          />

          <Button
            data-qa="save-changes"
            type="secondary"
            className="!bg-white !px-[12px] !py-[5px] !font-medium"
            text="Save changes"
            icon="new-diskette"
            onClick={applyEdit}
          />
        </div>
      )}
    </div>
  );
}
