import React from 'react';
import Input from 'components/shared/Input/Input';
import Button from 'components/shared/Buttons/Button';

export default function Settings({ onSubmit, onChange, errors, admin, buttonCopy, loading }) {
  return (
    <div className="flex items-center justify-center bg-neutral-50 !pt-10">
      <form className="flex w-[400px] flex-col gap-4 !rounded-lg bg-white !p-4">
        <Input
          label="First Name"
          name="f_name"
          data-qa="first-name"
          value={admin.f_name}
          onChange={({ target }) => onChange({ name: 'f_name', value: target.value })}
          error={errors.f_name.message}
          required
        />
        <Input
          label="Last Name"
          name="l_name"
          data-qa="last-name"
          value={admin.l_name}
          onChange={({ target }) => onChange({ name: 'l_name', value: target.value })}
          error={errors.l_name.message}
          required
        />
        <Input
          label="Email"
          name="email"
          data-qa="email"
          value={admin.email}
          onChange={({ target }) => onChange({ name: 'email', value: target.value })}
          error={errors.email.message}
          required
        />
        <Button text={buttonCopy} onClick={onSubmit} loading={loading} data-qa="update-btn" />
      </form>
    </div>
  );
}
