import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import Tippy from '@tippyjs/react';
import List from './List';
import { useMyScribeAIContext } from 'lib/context/MyScribeAI/MyScribeAIContext';
import { useMyTemplateContext } from 'lib/context/MyScribeAI/MyTemplateContext/MyTemplateContext';

import { getHeaderButtons } from '../../lib/helpers';
import { updateHiddenComponents } from '../../../shared/lib/helpers';

const TypeOfNotesSidebar = ({ className }) => {
  const [typeOfNotes, setTypeOfNotes] = useState([]);
  const { typeOfNotes: ctxTypeOfNotes } = useMyScribeAIContext();
  const { chatForms, setChatForms } = useMyTemplateContext();

  useEffect(() => {
    setTypeOfNotes(Object.values(ctxTypeOfNotes || {}));
  }, [ctxTypeOfNotes]);

  const selectedTypeOfNotes = Object.values(chatForms || {})
    ?.flat()
    ?.filter((item) => item.checked);

  const onSelectTypeOfNotes = (checked, note) => {
    const newChatForms = {
      ...chatForms,
      [note?.type]: checked
        ? {
            ...chatForms?.[note?.type],
            checked
          }
        : ctxTypeOfNotes[note?.type]
    };

    const updatedFormsWithHiddenComponents = updateHiddenComponents(newChatForms);

    setChatForms(updatedFormsWithHiddenComponents);
  };

  const headerButtons = getHeaderButtons(typeOfNotes, onSelectTypeOfNotes, chatForms);

  return (
    <div
      className={cs(
        'fixed  z-30 h-full shrink-0 border-0 !border-r-[1px] !border-solid !border-neutral-100 shadow-[4px_0px_16px_0_rgba(0,79,107,0.06)] duration-200',
        className,
        'w-[264px]'
      )}>
      <div className="flex items-center justify-between">
        <div className="p-2">Type of Notes</div>
        <div className="flex items-center">
          {headerButtons.map((button) => (
            <div className="p-1" key={button.id}>
              <Tippy content={button.tooltip} placement="top" className="tippy-dark">
                {button.component}
              </Tippy>
            </div>
          ))}
        </div>
      </div>
      <div className="h-[calc(100vh-3rem)] overflow-y-auto pb-28">
        {selectedTypeOfNotes?.map((selectedNote) => (
          <div key={selectedNote.type}>
            <div
              className="relative flex items-center gap-x-2 bg-primary-25 !py-2 !pl-8 !pr-4 font-500 text-primary-600 transition-all
        ">
              {selectedNote?.label}
            </div>
            <List open={true} type={selectedNote.type} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default TypeOfNotesSidebar;
