import { formatDate } from 'lib/helpers/utility';
import React from 'react';
import FormDataItemTitle from './FormDataItemTitle';

const FormDataItem = ({ item, index, type, activeOption }) => {
  const data = type === 'medicationHistory' ? [item] : item?.response;

  return (
    <div
      key={item.id}
      className="flex flex-col px-2 py-3"
      style={{ borderTopStyle: index !== 0 ? 'solid' : 'none' }}>
      <FormDataItemTitle data={data} type={type} activeOption={activeOption} />

      <p className="mt-2 text-sm text-neutral-600">
        {formatDate(item?.createdAt)} <span className="px-1">•</span> {item?.createdBy?.fullName}
      </p>
    </div>
  );
};

export default FormDataItem;
