import React from 'react';
import { withErrorBoundary } from 'components/shared/Error/Boundary';

const AdminUserSessions = () => {
  return (
    <div>
      <div className="font-700 text-lg text-primary-900">Overview</div>
      <p className="text-2xl">🤷🏼‍♂️👀</p>
    </div>
  );
};

export default withErrorBoundary(AdminUserSessions);
