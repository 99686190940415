import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useSearchParams } from 'react-router-dom';
import Loading from '../../shared/Loading/Loading';
import { AlertContent } from '../../shared/Alert/Alert';
import Button from '../../shared/Buttons/Button';
import { getPracticeInfo } from '../methods';
import { useSetRecoilState } from 'recoil';
import state from '../state';
import { requestApi } from '../../../api/Api';

const KioskActivate = () => {
  const { status, setStatus } = useOutletContext();
  const [show, setShow] = useState({ loading: false, message: '' });
  const setPractice = useSetRecoilState(state.practiceState);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  useEffect(() => {
    activateWeb();
    // socket.on('kiosk-new-code', (data) => {
    //   setStatus(data.code);
    // });
    //
    // return () => {
    //   socket.off('kiosk-new-code');
    // };
  }, []);

  const activateWeb = async () => {
    try {
      const code = searchParams.get('code');
      const res = await requestApi({
        url: '/api/kiosk/activate',
        params: { code },
        navigate
      });
      switch (res?.code) {
        case 0:
          setShow((ps) => ({ ...ps, message: 'success' }));
          await getPracticeInfo({
            navigate,
            setPractice,
            filePath: `${__dirname}/${__filename}`,
            pathname
          });
          navigate('/kiosk/idle');
          break;
        default:
          setShow((ps) => ({ ...ps, message: 'fail' }));
          break;
      }
    } catch (e) {}
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-neutral-50 text-2xl">
      <div className="mb-6 text-xl font-700 text-primary-500">Activate Kiosk</div>
      {show.loading && <p className="text-neutral-700">Activating...</p>}
      {/*<div className="mt-6 rounded-md bg-primary-100 py-4 px-4 text-lg font-700 tracking-widest text-primary-900">*/}
      {/*  {status?.code || JSON.stringify(status)}*/}
      {/*</div>*/}
      {show.loading && <Loading />}
      {show.message === 'success' && (
        <AlertContent
          color="success"
          className="w-full"
          title="Success!"
          message={
            <div>
              <p>Pairing successful!</p>
              <Button className="w-full" color="success" onClick={() => navigate('/kiosk/idle')} />
            </div>
          }
        />
      )}
      {show.message === 'fail' && (
        <AlertContent
          color="error"
          className="w-full"
          title="Error!"
          message="Pairing failed. Please close and open the app in order to restart the process."
        />
      )}
    </div>
  );
};

export default KioskActivate;
