import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useParams } from 'react-router-dom';

import cs from 'classnames';

import { useClinicalNoteContext } from 'lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { iaRa, imagePath } from 'lib/helpers/utility';
import { useAttachments } from 'lib/hooks/queries/useAttachments';

import PreviewDocument from 'components/practice/charts/Documents/components/PreviewDocument';
import useFaxActions from 'components/practice/comms_center/fax/lib/useFaxActions';
import getEditableDocumentType from 'components/shared/DocumentEditor/lib/getEditableDocumentType';
import useDocumentActions from 'components/shared/DocumentEditor/lib/useDocumentActions';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const PreviewFax = ({ fax }) => {
  const { renderedFax } = useFaxActions({ fax, renderFaxOnLoad: true });

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="flex justify-center w-full">
      {renderedFax ? (
        <Document file={renderedFax} onLoadSuccess={onDocumentLoadSuccess}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page
              key={`page_${index + 1}`}
              className={cs(
                'border-1 border border-neutral-200 border-solid',
                index > 0 && '!border-t-0'
              )}
              pageNumber={index + 1}
              width={600}
            />
          ))}
        </Document>
      ) : null}
    </div>
  );
};

const PreviewMarkupDocument = ({ data, type }) => {
  const { renderedDocument } = useDocumentActions({
    data: { document_handle: data?.handle, ...data },
    file: imagePath(data?.handle),
    renderDocumentOnLoad: true,
    type
  });

  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className="flex justify-center w-full">
      {renderedDocument ? (
        type === 'pdf' ? (
          <Document file={renderedDocument} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                className={cs(
                  'border-1 border border-neutral-200 border-solid',
                  index > 0 && '!border-t-0'
                )}
                pageNumber={index + 1}
                width={600}
              />
            ))}
          </Document>
        ) : (
          <img className="w-[600px]" src={renderedDocument} />
        )
      ) : null}
    </div>
  );
};

const Attachments = ({ patientId, appointmentId, customClinicalNote }) => {
  const { selected } = useClinicalNoteContext() || {};
  const {
    id = customClinicalNote?.patient?.id || patientId,
    appointmentId: appointment = customClinicalNote?.clinicalNote?.appointment_id || appointmentId
  } = useParams();

  const selectedAttachments = useMemo(
    () =>
      selected?.attachments?.formType?.filter((item) => item?.checked).map((item) => item.id) || [],
    [selected]
  );

  const { data } = useAttachments({
    params: {
      patientId: id,
      appointmentId: appointment
    },
    dependencies: [appointment],
    options: {
      enabled: !!appointment && !!id
    }
  });

  const attachments = useMemo(
    () =>
      data?.documents
        ?.filter(
          (item) => selectedAttachments.includes(item?.id?.toString()) || !!customClinicalNote
        )
        .map((item) => {
          return {
            ...item,
            handle: item?.document_handle || item?.handle,
            type: item?.type === 'fax' ? 'fax' : item?.form_id ? 'form' : item.mimetype
          };
        }),
    [data, selectedAttachments]
  );

  return (
    <div className="flex flex-col bg-white !px-6 !py-4">
      <h3 className="mb-3 text-sm  font-500 text-[#003A4B]">Attachments</h3>
      {iaRa(attachments).map((item) => (
        <div key={item?.id} className="my-4 w-full flex flex-col gap-1 ">
          <p className="text-sm text-neutral-800 mb-4 text-center">{item?.name}</p>
          <div className="w-full flex justify-center">
            {item?.type === 'fax' ? (
              <PreviewFax fax={item} />
            ) : getEditableDocumentType(item?.type) ? (
              <PreviewMarkupDocument type={getEditableDocumentType(item?.type)} data={item} />
            ) : (
              <PreviewDocument
                handle={item?.handle}
                type={item?.type}
                patientId={id}
                url={imagePath(item?.handle)}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Attachments;
