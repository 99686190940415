import React from 'react';

import { PAYER_KINDS } from 'lib/constants/payer/payerKinds';
import { PAYER_TYPES } from 'lib/constants/payer/payerTypes';

import { handleModifierSearch } from 'components/practice/charts/ClinicalNote/lib/cptAndIcdHelpers';
import { AlertContent } from 'components/shared/Alert/Alert';
import Input from 'components/shared/Input/Input';
import Select from 'components/shared/Select/Select';

import {
  LIMITED_ELECTRONIC_SERVICES_MESSAGE,
  LIMITED_ELECTRONIC_SERVICES_TITLE
} from './constants';
import { customSelectStyle } from './lib/styles';

const findPropertyByValue = (array, value) => {
  const foundItem = array?.find((item) => item?.value === value);
  return foundItem;
};

const PayerForm = ({ formik, states, isRequested = false }) => {
  const { setFieldValue, values, errors, handleChange } = formik;

  return (
    <div className="grid gap-y-3">
      {isRequested && (
        <AlertContent
          width="full"
          color="warning"
          title={LIMITED_ELECTRONIC_SERVICES_TITLE}
          message={LIMITED_ELECTRONIC_SERVICES_MESSAGE}
        />
      )}

      <Input
        label="Name"
        placeholder="Name"
        name="name"
        value={values.name}
        onChange={handleChange}
        id="name"
        error={errors.name}
      />
      <Input
        label="Phone number"
        placeholder="+1(123)4567890"
        name="phone"
        value={values.phone}
        onChange={handleChange}
        id="phoneNumber"
        error={errors.phone}
      />
      <Input
        label="Address 1"
        placeholder="Address"
        name="address_ln_1"
        value={values.address_ln_1}
        onChange={handleChange}
        id="address_ln_1"
        error={errors.address_ln_1}
      />
      <Input
        label="Address 2"
        type="text"
        placeholder="Address 2"
        name="address_ln_2"
        value={values.address_ln_2}
        onChange={handleChange}
        id="address_ln_2"
      />
      <Input
        label="City"
        type="text"
        placeholder="City"
        name="city"
        value={values.city}
        onChange={handleChange}
        id="city"
      />
      <Input
        label="Zip"
        placeholder="Zip"
        name="zip"
        value={values.zip}
        onChange={handleChange}
        id="zip"
        error={errors.zip}
      />
      <Select
        label="State"
        className="select-full-width w-full"
        placeholder="Type and select a state"
        value={findPropertyByValue(states, formik?.values?.state)}
        options={states}
        onChange={(v) => {
          setFieldValue('state', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        inputId="countryAddress"
        error={errors.state || null}
        menuPortalTarget={document.body}
      />
      <Select
        label="Type"
        className="select-full-width w-full"
        placeholder="Type and select a type"
        value={findPropertyByValue(PAYER_TYPES, formik?.values?.type)}
        options={PAYER_TYPES}
        onChange={(v) => {
          setFieldValue('type', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        error={errors.type || null}
        menuPortalTarget={document.body}
      />
      <Select
        label="Virtual Modifiers"
        placeholder="Type and select Modifiers"
        isAsync
        isMulti
        isClearable
        value={values.virtual_modifiers?.map((modifier) => ({
          label: modifier,
          value: modifier
        }))}
        loadOptions={handleModifierSearch}
        menuPortalTarget={document.body}
        error={errors.virtual_modifiers || null}
        onChange={(selectedOptions) => {
          setFieldValue(
            'virtual_modifiers',
            selectedOptions ? selectedOptions.map((option) => option.value) : []
          );
        }}
      />
      <Select
        label="Kind"
        className="select-full-width w-full"
        placeholder="Type and select a kind"
        value={findPropertyByValue(PAYER_KINDS, formik?.values?.kind)}
        options={PAYER_KINDS}
        onChange={(v) => {
          setFieldValue('kind', v.value);
        }}
        styles={customSelectStyle}
        isClearable={false}
        error={errors.kind || null}
        menuPortalTarget={document.body}
      />
    </div>
  );
};

export default PayerForm;
