import React, { useRef, useState } from 'react';

import { debounce } from 'lodash';

import { getPatients } from 'api/Patients';

import { ia } from 'lib/helpers/utility';
import { useInfiniteFetchData } from 'lib/hooks/useInfiniteFetchData';

import Select from 'components/shared/Select/Select';
import Typography from 'components/shared/Typography/Typography';

import NewInvoiceTable from './NewInvoiceTable';

const PatientSearch = ({ setGroupSelectedRows }) => {
  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPatients, setSelectedPatients] = useState([]);

  const {
    data: options,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage
  } = useInfiniteFetchData({
    queryKey: 'patients',
    queryFn: getPatients,
    formatData: (data) =>
      data.map((patient) => ({
        label: patient.fullName,
        value: patient.id
      })),
    params: { searchTerm, page: 1 },
    dependencies: [searchTerm]
  });

  const debouncedSetSearchTerm = useRef(debounce((value) => setSearchTerm(value), 300)).current;

  const handleInputChange = (value) => {
    setLocalSearchTerm(value);
    if (!!value) {
      debouncedSetSearchTerm(value);
    }
  };

  const handleChange = (value) => {
    setSelectedPatients(value);
  };

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex flex-col gap-2">
        <Typography color="primary-700" weight="medium">
          Search patient
        </Typography>
        <div className="relative flex justify-between">
          <Select
            value={selectedPatients}
            options={options}
            parentClassName="w-[300px]"
            inputValue={localSearchTerm}
            noDropdown
            onInputChange={handleInputChange}
            placeholder="Search patient"
            icon="new-search"
            isClearable={false}
            isMulti={true}
            onChange={handleChange}
            menuPortalTarget={document.body}
            onMenuScrollToBottom={() => {
              if (hasNextPage && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
            isLoading={isFetchingNextPage}
          />
        </div>
      </div>
      {ia(selectedPatients) && (
        <NewInvoiceTable patients={selectedPatients} setGroupSelectedRows={setGroupSelectedRows} />
      )}
    </div>
  );
};

export default PatientSearch;
