import React from 'react';
import Button from 'components/shared/Buttons/Button';
import ErrorBoundary from 'components/shared/Error/Boundary';
import ErrorMessage from 'components/shared/ErrorMessage/ErrorMessage';
import { useNewRoleContext } from 'lib/context/Role/NewRoleContext/NewRoleContext';

const FooterButtons = ({ buttonText, nextTo, backTo, isLastStep, setBreadCrumbs, firstStep }) => {
  const {
    enableNextTo,
    formik,
    showNavigationButtons,
    handleClose,
    isEdit,
    enableSubmit,
    setIsSaveAndExit
  } = useNewRoleContext();

  const navigateViews = ({ componentToView = '', isNext = false }) => {
    setBreadCrumbs((prevCrumbs) =>
      prevCrumbs.map((crumb) => {
        if (crumb.view === componentToView) {
          return { ...crumb, active: true };
        } else if (isNext && crumb.active && !crumb.isFinished) {
          return { ...crumb, isFinished: true, active: false };
        } else {
          return { ...crumb, active: false };
        }
      })
    );
  };

  const handleSubmit = (hasSaveAndExit) => {
    if (hasSaveAndExit) {
      setIsSaveAndExit(true);
    }
    formik.handleSubmit();
  };

  const handleOnClick = () => {
    if (!firstStep) {
      navigateViews({ componentToView: backTo });
    } else {
      handleClose();
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorMessage}>
      <div className="flex  w-full items-center justify-between">
        {showNavigationButtons ? (
          <Button
            disabled={firstStep ? false : !backTo}
            outlined
            color="neutral"
            text={firstStep ? 'Close' : 'Back'}
            data-qa="back-btn"
            onClick={() => handleOnClick()}
          />
        ) : (
          <Button color="neutral" text="Close" outlined onClick={handleClose} data-qa="close-btn" />
        )}
        <div className="flex-end flex gap-4">
          {showNavigationButtons && nextTo && (
            <Button
              disabled={!enableNextTo}
              text={buttonText}
              onClick={() => navigateViews({ componentToView: nextTo, isNext: true })}
              type="primary"
              data-qa={`${buttonText}-btn`}
            />
          )}
          {isLastStep && (
            <Button
              text={buttonText ? buttonText : isEdit ? 'Update' : 'Add'}
              onClick={() => handleSubmit()}
              disabled={!enableSubmit}
              type="primary"
              data-qa="submit-btn"
            />
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default FooterButtons;
