const { ia, iaRa } = require('../../../../../lib/helpers/utility');
const { textBoldAndUnderline, textItalic } = require('./markdownHelpers');

exports.formatSections = ({ data = [], isProvider = true }) => {
  if (!ia(data)) return;

  const updatedData = data.map((item) => {
    let formattedString = '';
    if (item?.goal) {
      formattedString += ` ${textBoldAndUnderline({ text: 'Goal:' })} ${item.goal}\n`;
    }

    item.objectives.forEach((objective) => {
      if (objective?.objective !== null) {
        formattedString += `  ${textBoldAndUnderline({ text: 'Objective:' })} ${
          objective?.objective
        }\n`;

        objective.treatments.forEach((treatment) => {
          if (treatment.treatment !== null) {
            formattedString += `    ${textBoldAndUnderline({ text: 'Treatment:' })} ${
              treatment?.treatment
            }\n`;
          }
        });
      }
    });

    return { ...item, narrative: formattedString };
  });

  return this.mergeGoalNarratives(updatedData, isProvider);
};

exports.mergeGoalNarratives = (updatedData, isProvider = undefined) => {
  let formattedString = '';

  if (isProvider !== undefined)
    formattedString += isProvider
      ? textItalic({ text: '' })
      : textItalic({ text: 'Patient reports: \n\n' });

  iaRa(updatedData)
    ?.filter((u) => u.narrative)
    ?.map(({ narrative }) => {
      formattedString += `${narrative} \n`;
    });

  return { narrative: formattedString, updatedGoals: updatedData };
};
