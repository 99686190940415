import React, { useImperativeHandle } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';
import EnhancementStatus from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/EnhancementStatus';

const Assessment = ({ assessmentRef, sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  useImperativeHandle(sectionRef, () => ({
    formData: { assessment: clinicalNote?.assessment }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write assessment notes here"
      id="assessment"
      name="assessment"
      className="min-h-[200px]"
      value={clinicalNote?.assessment}
      onChange={(e) => handleChange(e, 'assessment')}
      transcribing
      forwardedRef={assessmentRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Assessment;
