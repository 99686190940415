import React from 'react';
import { useFormikContext } from 'formik';
import { useResourcesAsOptions } from 'lib/hooks/queries/resoruces/useResourcesAsOptions';
import Select from 'components/shared/Select/Select';
import { ia } from 'lib/helpers/utility';

const Resource = () => {
  const { values, setFieldValue } = useFormikContext();
  const { data } = useResourcesAsOptions();
  const resources = data?.resources;

  const handleChange = (value) => setFieldValue('resources', value ? [value] : []);

  return (
    <Select
      label="Resource"
      parentClassName="min-w-[280px]"
      placeholder="Type and select"
      value={ia(values.resources) ? values.resources[0] : null}
      inputId="resourceSelect"
      options={resources}
      onChange={handleChange}
    />
  );
};

export default Resource;
