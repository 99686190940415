export const addDateFields = [
  {
    label: 'Date last seen',
    value: 'lastseen_date'
  },
  {
    label: 'Acute manifest date',
    value: 'chiro_manifest_date'
  },
  {
    label: 'Accident date',
    value: 'accident_date'
  },
  {
    label: 'Initial treatment date',
    value: 'initial_treatment_date'
  },
  {
    label: 'Last menstrual period',
    value: 'last_menstrual_date'
  },
  {
    label: 'Last x-ray',
    value: 'chiro_xray_date'
  },
  {
    label: 'Assumed care date',
    value: 'assumed_care_date'
  },
  {
    label: 'Relinquished care date',
    value: 'relinquished_care_date'
  },
  {
    label: 'Last worked date',
    value: 'lastworked_date'
  },
  {
    label: 'Disability from date',
    value: 'disability_from_date'
  },
  {
    label: 'Disability thru date',
    value: 'disability_thru_date'
  },
  {
    label: 'Hearing and vision prescription date',
    value: 'hearingvision_prescription_date'
  },
  {
    label: 'Authorized return to work date',
    value: 'returntowork_date'
  },
  {
    label: 'First contact date',
    value: 'firstcontact_date'
  },
  {
    label: 'Patient death date',
    value: 'pat_death_date'
  }
];
