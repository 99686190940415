import React, { useEffect, useState } from 'react';

import WidgetHeader from '../../../../shared/CustomizableBoard/widgets/components/WidgetHeader';
import Button from '../../../../shared/Buttons/Button';
import Icon from '../../../../shared/Icon/Icon';
import List from '../../../../shared/CustomizableBoard/widgets/components/List';
import FooterWrapper from '../../../../shared/CustomizableBoard/widgets/components/FooterWrapper';

import formatDate from '../../../../shared/CustomizableBoard/widgets/helpers/formatDate';
import { useQueryClient } from '@tanstack/react-query';

import { useNavigate } from 'react-router-dom';
import { useClaims } from '../../../../../lib/hooks/queries/claims/useClaims';
import { getClaims } from '../../../../../api/Claim';
import { capitalize, take } from 'lodash';
import { mString } from '../../../../../lib/helpers/utility';
import Allowed from 'components/shared/Permissions/Allowed';

const stateColors = {
  ready: 'success',
  acknowledged: 'primary',
  denied: 'error',
  paid: 'success',
  archived: 'neutral',
  pending: 'warning',
  rejected: 'error'
};

const Claim = ({ item }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`./billing/claims/all/${item?.id}`)}
      className="flex !cursor-pointer flex-col gap-3 px-[20px] py-3 hover:bg-primary-25">
      <div className="flex !cursor-pointer items-center justify-between gap-2">
        <p className="font-semibold text-neutral-800">Claim ID #{item?.id}</p>

        <div className="flex gap-2">
          <p
            className={`font-medium text-primary-800 text-${
              stateColors[item?.state] || 'neutral'
            }`}>
            {capitalize(item?.state)}
          </p>
          <Icon
            icon="new-chevron-right"
            className="cursor-pointer"
            stroke
            color="primary"
            shade={700}
          />
        </div>
      </div>

      <div className="flex gap-3 ">
        <div className="flex flex-col">
          <p className="text-xs text-neutral-800">Date</p>
          <p className="font-medium text-primary-600">{formatDate(item?.created_at)}</p>
        </div>
        <div className="mx-1 border !border-l-0 border-r !border-neutral-100" />

        {/* <div className="flex flex-col">
          <p className="text-xs text-neutral-800">Practitioner</p>
          <p className="text-primary-600 font-medium">{formatDate(item?.createdAt)}</p>
        </div>
        <div className="!border-neutral-100 border-r border !border-l-0 mx-1" /> */}

        <div className="flex flex-col">
          <p className="text-xs text-neutral-800">Procedures</p>
          <p className="font-medium text-primary-600">
            {take(item.procedures, 3).map(
              (p, index) => p.code && p.code + (index !== item.procedures.length - 1 ? ', ' : '')
            )}
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-[4px]">
        <div className="flex justify-between gap-2 !text-sm">
          <p>Payer:</p>
          <p className="font-medium">{item?.payer_name}</p>
        </div>
        <div className="flex justify-between gap-2 !text-sm">
          <p className="font-semibold">Amount:</p>
          <p className="font-semibold">${item?.total_charge}</p>
        </div>
        <div className="flex justify-between gap-2 !text-sm">
          <p className="font-semibold">Amount Paid:</p>
          <p className="font-semibold">{mString(item?.insurance_payment?.total)}</p>
        </div>
      </div>

      {/* {item?.presentIllness && (
        <p className="text-neutral-600 line-clamp-3 text-sm">{item?.presentIllness}</p>
      )} */}
    </div>
  );
};

export default function Claims(props) {
  const { patientId, changedSizes, layout } = props;

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const updateSize = async () => {
    const size = changedSizes.find((size) => size.i === props.item.i)?.h - 1;

    if (size) {
      const newData = await getClaims(navigate, { limit: size, patientId });
      queryClient.setQueryData(['claims', patientId], newData);
    }
  };

  useEffect(() => {
    updateSize();
  }, [changedSizes]);

  const { data } = useClaims({
    params: {
      limit: layout.find((l) => l.i === props.item.i)?.h - 1,
      patientId,
      overview: true
    },
    dependencies: [layout, patientId]
  });

  return (
    <div className="flex flex-1 flex-col overflow-y-auto">
      <WidgetHeader
        hasPlus={false}
        icon="wallet-move"
        addPermissions="billing.create"
        {...props}></WidgetHeader>
      <Allowed
        requiredPermissions="billing.read"
        showMessage={true}
        showIllustration={true}
        messageClassName="font-medium text-neutral-500">
        <div className="flex flex-1 flex-col overflow-y-auto">
          {data?.claims?.length > 0 ? (
            <FooterWrapper
              link
              onClick={() => navigate(`./billing/claims`)}
              text={`Claims (${data?.count})`}>
              <List
                items={data?.claims}
                renderItemComponent={(item, index) => <Claim item={item} />}></List>
            </FooterWrapper>
          ) : (
            <div className="p-[20px] py-3 text-center">
              <p className="font-medium text-neutral-500">No Claims</p>
            </div>
          )}
        </div>
      </Allowed>
    </div>
  );
}
