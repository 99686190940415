import Select from 'components/shared/Select/Select';
import React, { useState } from 'react';
import { searchPatients } from '../lib/searchPatients';
import { useNavigate } from 'react-router-dom';
import Icon from 'components/shared/Icon/Icon';

const PatientsMultiSearch = ({ onSelect }) => {
  const navigate = useNavigate();

  const [input, setInput] = useState();
  const [values, setValues] = useState([]);

  const data = {
    type: 'search',
    multiple: true,
    // values: patient,
    title: 'Patients'
  };

  const handleInputChange = (value) => {
    setInput(value);
  };

  const handleValuesChange = (values) => {
    setValues(values);
    onSelect(values);
  };
  return (
    <div className="flex w-fit cursor-pointer flex-col !py-2">
      <p className="!px-4 font-medium text-primary-700 text-sm">Search Patients</p>
      <div className="relative !mx-4 mt-1 flex justify-between">
        <Select
          inputId="search-patients"
          isAsync={(e) => searchPatients(e, navigate)}
          options={data?.options}
          inputValue={input}
          noDropdown
          loadOptions={async (searchTerm, callback) => {
            setInput(searchTerm);
            const result = await searchPatients(searchTerm, navigate);
            callback(result);
          }}
          value={values}
          onInputChange={handleInputChange}
          placeholder="Search"
          isClearable={false}
          styles={{
            control: {
              outline: '0',
              border: 'none',
              padding: '0',
              minHeight: '1.5rem',
              boxShadow: '0'
            },
            valueContainer: {
              padding: '0'
            }
          }}
          width={418}
          onChange={handleValuesChange}
          isMulti
        />
        <div className="z-100 absolute bottom-0 right-0 top-0 mr-[2px] flex">
          <Icon color="primary" shade={900} stroke icon="new-search" />
        </div>
      </div>
      <div className="!mx-4 mt-1 border !border-t-0 border-neutral-200 border-solid"></div>
    </div>
  );
};

export default PatientsMultiSearch;
