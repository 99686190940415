import ContactActions from '../components/Actions';

export const defaultFilters = {
  name: {
    type: 'search',
    values: null,
    title: 'Name',
    placeholder: 'Search contacts'
  },
  email: {
    type: 'search',
    values: null,
    title: 'Email',
    placeholder: 'Search contacts'
  },
  fax_number: {
    type: 'search',
    values: null,
    title: 'Fax',
    placeholder: 'Search contacts'
  }
};

export const defaultColumns = [
  {
    field: 'first_name',
    headerName: 'First Name',
    flex: 1
  },
  {
    field: 'last_name',
    headerName: 'Last Name',
    flex: 1
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1
  },
  {
    field: 'fax_number',
    headerName: 'Fax Number',
    flex: 1
  },
  {
    field: 'phone_number',
    headerName: 'Phone Number',
    flex: 1
  },
  {
    field: 'actions',
    headerName: 'Actions',
    cellClass: 'no-border',
    cellRenderer: ContactActions,
    maxWidth: 100,
    minWidth: 36,
    resizable: false,
    sortable: false,
    pinned: 'right'
  }
];
