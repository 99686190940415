import { useRecoilValue } from 'recoil';

import { formatDate, ia } from 'lib/helpers/utility';

import practiceState from 'components/practice/practiceState';
import Popover from 'components/shared/Popovers/Popover/Popover';

const OrdersActionsCellRenderer = ({
  data,
  openEditModal,
  openPreviewModal,
  openDeleteModal,
  setActiveDocument
}) => {
  const currentPractice = useRecoilValue(practiceState.currentPractice);

  const options = [
    {
      icon: 'new-eye',
      label: 'Preview',
      onClick: () => {
        openPreviewModal({ type: data.rawType, id: data.id });
      }
    },
    {
      icon: 'new-trash-patient',
      label: 'Delete',
      onClick: () => {
        openDeleteModal({ type: data.rawType, orderId: data.id });
      }
    }
  ];

  if (data?.rawType !== 'elab_order') {
    options.splice(1, 0, {
      icon: 'new-edit',
      label: 'Edit',
      onClick: () => {
        openEditModal({ type: data.rawType, id: data.id });
      }
    });
  }

  if (ia(data?.results)) {
    options.splice(1, 0, {
      icon: 'status',
      color: 'primary',
      stroke: true,
      label: 'View Result',
      children: data?.results.map((item) => ({
        icon: 'status',
        label: item?.report_status || formatDate(item.created_at, currentPractice.timezone),
        onClick: () => setActiveDocument({ url: item.report_url, title: item.report_title })
      }))
    });
  }

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Popover
        options={options}
        optionIconColor="primary"
        position="left"
        isFixed
        icon="new-context-menu-dots"
      />
    </div>
  );
};

export default OrdersActionsCellRenderer;
