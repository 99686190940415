import React, { Fragment } from 'react';
import { Switch as HeadlessSwitch } from '@headlessui/react';
import cs from 'classnames';

const Switch = ({
  onChange = () => {},
  checked = Boolean,
  color = 'primary', // our themes
  onColor = '', //custom colors
  offColor = '', //custom colors
  onHandleColor = '', //custom colors
  offHandleColor = '', //custom colors
  width = 30,
  height = 18,
  className = '',
  label = '',
  labelClassName = '',
  dataQa = 'switch',
  disabled = false,
  ...rest
}) => {
  const baseRatio = 0.67;
  const dotSize = height * baseRatio; // the dot size will be calculated based on height
  const translateChecked = width - dotSize - 6;
  const translateUnchecked = 0;

  const colorClasses = {
    neutral: {
      checkedBg:
        'bg-neutral-100 hover:bg-neutral-200 focus:bg-neutral-200 focus:!outline focus:!outline-2 focus:!outline-neutral-100',
      disabledBg: 'bg-neutral-50',
      disabledDotBg: 'bg-neutral-100'
    },
    primary: {
      checkedBg:
        'bg-primary-100 hover:bg-primary-200 focus:bg-primary-200 focus:!outline focus:!outline-2 focus:!outline-[#CBF0FD]',
      disabledBg: 'bg-primary-50',
      disabledDotBg: 'bg-primary-100'
    },
    secondary: {
      checkedBg:
        'bg-secondary-100 hover:bg-secondary-200 focus:bg-secondary-200 focus:!outline focus:!outline-2 focus:!outline-secondary-100',
      disabledBg: 'bg-secondary-50',
      disabledDotBg: 'bg-secondary-100'
    },
    success: {
      checkedBg:
        'bg-success-100 hover:bg-success-200 focus:bg-success-200 focus:!outline focus:!outline-2 focus:!outline-success-100',
      disabledBg: 'bg-success-50',
      disabledDotBg: 'bg-success-100'
    },
    warning: {
      checkedBg:
        'bg-warning-100 hover:bg-warning-200 focus:bg-warning-200 focus:!outline focus:!outline-2 focus:!outline-warning-100',
      disabledBg: 'bg-warning-50',
      disabledDotBg: 'bg-warning-100'
    },
    danger: {
      checkedBg:
        'bg-danger-100 hover:bg-danger-200 focus:bg-danger-200 focus:!outline focus:!outline-2 focus:!outline-danger-100',
      disabledBg: 'bg-danger-50',
      disabledDotBg: 'bg-danger-100'
    }
  };

  return (
    <div>
      {label && (
        <label
          className={cs(
            'block pb-1 text-sm font-500',
            disabled ? 'text-neutral-400' : 'text-neutral-800',
            labelClassName
          )}>
          {label}
        </label>
      )}
      <HeadlessSwitch
        checked={checked}
        onChange={onChange}
        as={Fragment}
        disabled={disabled}
        data-qa={dataQa}
        {...rest}>
        {({ checked }) => (
          <button
            className={cs(
              'flex cursor-pointer items-center rounded-full px-[3px] !outline-transparent duration-200',
              `w-[${width}px]`,
              `min-w-[${width}px]`,
              `h-[${height}px]`,
              !checked &&
                !disabled &&
                !onColor &&
                'bg-neutral-100 hover:bg-neutral-200 focus:bg-neutral-200 focus:!outline focus:!outline-2 focus:!outline-neutral-100',
              !checked && disabled && '!cursor-not-allowed bg-neutral-50',
              checked && disabled && `!cursor-not-allowed ${colorClasses[color]?.['disabledBg']}`,
              checked && !disabled && !onColor && `${colorClasses[color]?.['checkedBg']}`
            )}
            // the style above is just for custom colors
            style={{
              backgroundColor: checked && onColor && offColor ? onColor : offColor,
              opacity: disabled && '0.5'
            }}>
            <span
              className={cs(
                'rounded-full duration-200',
                !checked && !disabled && 'bg-neutral-500',
                !checked && disabled && 'bg-neutral-200',
                checked && !disabled && `bg-${color}-500`,
                checked && disabled && `${colorClasses[color]?.['disabledDotBg']}`
              )}
              style={{
                width: dotSize,
                minWidth: dotSize,
                height: dotSize,
                backgroundColor:
                  checked && onHandleColor && offHandleColor ? onHandleColor : offHandleColor,
                transform: `translateX(${checked ? translateChecked : translateUnchecked}px)`
              }}
            />
          </button>
        )}
      </HeadlessSwitch>
    </div>
  );
};

export default Switch;
