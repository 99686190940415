import { useNavigate } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { getPreferences } from '../../../api/Preferences';

export const usePreferences = ({ params, dependencies, options }) => {
  const navigate = useNavigate();
  return useQuery(['preferences', ...dependencies], () => getPreferences(navigate, params), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    ...options
  });
};
