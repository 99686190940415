import React, { useImperativeHandle } from 'react';
import Textarea from '../../../../../../../shared/Textarea/Textarea';
import { clinicalNote as clinicalNoteState } from 'components/state';
import { useRecoilState } from 'recoil';
import EnhancementStatus from 'components/practice/charts/ClinicalNote/CurrentNote/TypeNotes/SOAPNote/components/EnhancementStatus';

const Objective = ({ objectiveRef, sectionRef = null }) => {
  const [clinicalNote, setClinicalNote] = useRecoilState(clinicalNoteState);

  useImperativeHandle(sectionRef, () => ({
    formData: { objective: clinicalNote?.objective }
  }));

  const handleChange = (e, key) => {
    const name = e.target?.name;
    const value = e.target?.value;

    setClinicalNote((prevState) => ({ ...prevState, [name ?? key]: value ?? e }));
  };

  return (
    <Textarea
      isEditor
      label={<EnhancementStatus>Note</EnhancementStatus>}
      placeholder="Write objective notes here"
      id="objective"
      data-qa="objective"
      name="objective"
      className="min-h-[200px]"
      value={clinicalNote?.objective}
      onChange={(e) => handleChange(e, 'objective')}
      transcribing
      forwardedRef={objectiveRef}
      disabled={clinicalNote?.locked}
    />
  );
};

export default Objective;
