import React from 'react';

import { ia } from 'lib/helpers/utility';

import Icon from 'components/shared/Icon/Icon';
import Typography from 'components/shared/Typography/Typography';

const NoClaims = ({ data }) => {
  if (ia(data)) return null;

  return (
    <div className="flex flex-col gap-2 items-center justify-center flex-1">
      <Icon icon="new-no-data" />
      <Typography variant="h4" color="text-neutral-400">
        No Claims.
      </Typography>
    </div>
  );
};

export default NoClaims;
