import React, { useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import { useClinicalNoteContext } from '../../../../../../lib/context/ClinicalNoteContext/ClinicalNoteContext';
import { Counter } from 'components/shared/Forms/Custom/Provider/components/PreviousFormDropdown';
import Icon from 'components/shared/Icon/Icon';
import FormList from './lib/components/FormList';
import { camelToSpaceCase, io } from 'lib/helpers/utility';
import FormData from './lib/components/FormData';
import Breadcrumb from 'components/shared/Breadcrumb/Breadcrumb';
import useBreadcrumb from 'components/shared/Breadcrumb/useBreadcrumb';
import { forms, getOptionsCounts } from './lib/previousFormHelpers';

let options = [
  {
    key: 'patient-submissions',
    value: 'patient',
    icon: 'new-user-2',
    label: 'Patient submissions',
    count: 0
  },
  {
    key: 'provider-submissions',
    value: 'provider',
    icon: 'user-square',
    label: 'Provider submissions',
    count: 0
  }
];

const PreviousFormResponses = () => {
  const { overviewData, activePreviousFormResponses } = useClinicalNoteContext();
  const [activeOption, setActiveOption] = useState(activePreviousFormResponses.option);
  const [activeForm, setActiveForm] = useState(activePreviousFormResponses.form);

  const counts = useRef({
    patient: {},
    provider: {}
  });

  options = useMemo(() => {
    return getOptionsCounts(overviewData, options, counts);
  }, [overviewData]);

  const formsWithCount = useMemo(() => {
    return forms.map((form) => {
      form.count = counts.current?.[activeOption]?.[form.value];

      return form;
    });
  }, [overviewData, activeOption]);

  const formData =
    io(overviewData) && activeOption && activeForm
      ? overviewData?.[activeForm]?.filter((item) => {
          const isPatient = activeOption === 'patient';
          return item.createdBy?.isPatient === isPatient;
        })
      : [];

  const { setStep, step } = useBreadcrumb();

  const steps = [
    {
      title: activeOption ? (
        <Icon
          icon="new-home"
          size={14}
          color={step === 0 ? 'primary' : ''}
          shade={700}
          className="hover:cursor-pointer"
        />
      ) : (
        ''
      )
    },
    activeOption && { title: camelToSpaceCase(activeOption) },
    activeForm && { title: camelToSpaceCase(activeForm) }
  ].filter(Boolean);

  useEffect(() => {
    setStep(steps.length - 1);
  }, []);

  useEffect(() => {
    if (step === 0) {
      setActiveOption(null);
      setActiveForm(null);
    } else if (step === 1) {
      setActiveForm(null);
    }
  }, [step]);

  useEffect(() => {
    if (activePreviousFormResponses.option) {
      setStep(1);
      setActiveOption(activePreviousFormResponses.option);
    }
    if (activePreviousFormResponses.form) {
      setStep(2);
      setActiveForm(activePreviousFormResponses.form);
    }
  }, [activePreviousFormResponses]);
  return (
    <div className="h-full w-full overflow-y-scroll !py-2">
      <Breadcrumb
        className={cs('px-4', !activeOption ? 'h-0' : 'min-h-[20px]')}
        activeClassName="text-primary-700 truncate"
        hasBackButton={true}
        breadcrumb={{
          setStep,
          step,
          steps
        }}
        key="breadcrumb"
      />

      <div>
        {!activeOption ? (
          <div className={cs('transition-all duration-500')}>
            {options.map((option) => {
              return (
                <div
                  key={option.key}
                  className={cs(
                    'flex items-center justify-between px-4 py-3 hover:!bg-neutral-50',
                    !activeOption && 'cursor-pointer'
                  )}
                  role="button"
                  onClick={() => {
                    setActiveOption(option.value);
                    setStep(1);
                  }}>
                  <div className="flex items-center gap-2">
                    <Icon icon={option.icon} size={18} />
                    <p>{option.label}</p>
                  </div>
                  <Counter count={option?.count} />
                </div>
              );
            })}
          </div>
        ) : null}

        {activeOption && !activeForm ? (
          <FormList
            forms={formsWithCount}
            activeOption={activeOption}
            setActiveForm={setActiveForm}
            setActiveOption={setActiveOption}
            setStep={setStep}
          />
        ) : null}

        {activeForm ? (
          <FormData data={formData} type={activeForm} activeOption={activeOption} />
        ) : null}
      </div>
    </div>
  );
};

export default PreviousFormResponses;
