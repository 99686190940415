import { requestApi } from './Api';

export const getAttachments = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/attachments/get',
    params
  });

export const createAttachment = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/attachments/create',
    params
  });

export const deleteAttachment = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/attachments/delete',
    params
  });

export const attachDocument = async (navigate, params) =>
  requestApi({
    navigate,
    url: '/api/clinical_note/attachments/attach',
    params
  });
